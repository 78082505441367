import { AbstractControl, ValidatorFn } from "@angular/forms";
import * as moment from 'moment';

export class CustomDateValidators {
    static fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
        return (group: AbstractControl): { [key: string]: boolean } | null => {

            const fromDate      = moment(group.get(fromDateField)?.value).format('YYYY-MM-DD')
            const toDate        = moment(group.get(toDateField)?.value).format('YYYY-MM-DD')
            const ConvertedFrom = new Date(fromDate);
            const ConvertedTo   = new Date(toDate);

            if ((fromDate !== null && toDate !== null) &&  (ConvertedFrom.getTime() > ConvertedTo.getTime())) {
              
                return {[errorName]: true};
            }
            return null;


        };
    }

    static checkinDate(checkin: string, original_checkin: string, errorName: string = 'checkin'): ValidatorFn {
      return (group: AbstractControl): { [key: string]: boolean } | null => {

          const fromDate      = moment(group.get(checkin)?.value).format('YYYY-MM-DD')
          const toDate        = moment(group.get(original_checkin)?.value).format('YYYY-MM-DD')

          if ((fromDate !== null && toDate !== null) &&  (fromDate != toDate)) {

              return {[errorName]: true};
          }
          return null;


      };
    }

    static checkoutDate(checkout: string, original_checkin: string, original_checkout: string, errorName: string = 'fromToDate1'): ValidatorFn {
      return (group: AbstractControl): { [key: string]: boolean } | null => {

          const fromDate      = moment(group.get(checkout)?.value).format('YYYY-MM-DD')
          const toDate        = moment(group.get(original_checkin)?.value).format('YYYY-MM-DD')
          const toDate2       = moment(group.get(original_checkout)?.value).format('YYYY-MM-DD')


          if ((fromDate !== null && toDate !== null  && toDate2 !== null) &&  (fromDate != toDate && fromDate != toDate2)) {

              return {[errorName]: true};
          }
          return null;


      };
    }

}




