<form class="side-panel-container" [formGroup]="leaveConfigForm" autocomplete="off">
  <ng-container formArrayName="leaveRequestLimit" *ngFor="let config of leaveRequestLimitForm.controls; let i = index">
      <ng-container [formGroupName]="i">
<ng-container *ngIf="leaveTypeCode==leaveRequestLimitForm.controls[i].get('leave_code')?.value">
<div class="container-fluid  py-24 px-16">
    <div class="row ">
        <p class="text-uppercase fw-500">LEAVE REQUEST LIMITS </p>
      </div>
      <div class="row row-16">
        <div class="col-12 ">
          <div class="form-row">
            <label for=" " class="form-label ">Maximum no. of requests employee can apply in a tenure </label>
            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If you do not want to set any limit for leave request for an employee, then select the option “no limit”. If you want to set a limit for leave request for an employee select the option “set limit” and enter the maximum limit for number of leave request an employee can raise in his tenure." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
         <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio"
              formControlName="max_req_apply_tenure_status" [value]=false (ngModelChange)="addValidator()">
            <label class="form-check-label">
              No Limit
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"
              formControlName="max_req_apply_tenure_status" [value]=true (ngModelChange)="addValidator()">

            <label class="form-check-label">
              Set Limit
            </label>
          </div>
        </div>
       <ng-container *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_tenure_status')?.value">
        <div class="col-12 mt-8">
          <input type="text" class="form-control" formControlName="max_req_apply_tenure" placeholder="Enter Value" [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.touched || leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.dirty) &&  leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.errors }">
          <div *ngIf="(saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.touched || leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.dirty) && leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.errors" class="invalid-feedback">
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.errors.pattern">
                {{this.messageService.validationDisplay('pattern')}}</div>
          <!-- <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.hasError('min') && !(leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.errors?.pattern)">
                  {{this.messageService.fieldlengthvalidation('minvalue',0.1)}}
          </div> -->
          <div *ngIf="!(leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.errors.pattern) && leaveRequestLimitForm.controls[i].get('max_req_apply_tenure')?.errors.maxlength">
            {{this.messageService.fieldlengthvalidation('num','10')}}
          </div>
          </div>
        </div>
      </ng-container>
        <div class="col-12 ">
          <div class="form-row">
            <label for=" " class="form-label ">Maximum no. of requests employee can apply in a year </label>
            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select 'No Limit' if there is no restriction on the number of leave requests an employee can submit in a year; select 'Set Limit' to specify a maximum number of leave requests allowed per year." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"
            formControlName="max_req_apply_year_status" [value]=false (ngModelChange)="addValidator()">
            <label class="form-check-label">
              No Limit
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"
            formControlName="max_req_apply_year_status" [value]=true (ngModelChange)="addValidator()">
            <label class="form-check-label">
              Set Limit
            </label>
          </div>
        </div>
        <ng-container *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_year_status')?.value">
        <div class="col-12 mt-8">
          <input type="text" class="form-control " formControlName="max_req_apply_year" placeholder="Enter Value" [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.touched || leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.dirty) &&  leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.errors }"
          >
          <div *ngIf="(saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.touched || leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.dirty) && leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.errors" class="invalid-feedback">
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.errors.pattern">
                {{this.messageService.validationDisplay('pattern')}}</div>
          <div *ngIf="!(leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.errors.pattern) && leaveRequestLimitForm.controls[i].get('max_req_apply_year')?.errors.maxlength">
            {{this.messageService.fieldlengthvalidation('num','10')}}</div>
          </div>

        </div>
      </ng-container>
        <div class="col-12 ">
          <div class="form-row">
            <label for=" " class="form-label ">Maximum no. of requests employee can apply in a month  </label>
            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select 'No Limit' if there is no restriction on the number of leave requests an employee can submit in a month; select 'Set Limit' to specify a maximum number of leave requests allowed per month." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"
            formControlName="max_req_apply_month_status" [value]=false (ngModelChange)="addValidator()">
            <label class="form-check-label">
              No Limit
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"
            formControlName="max_req_apply_month_status" [value]=true (ngModelChange)="addValidator()">
            <label class="form-check-label">
              Set Limit
            </label>
          </div>
        </div>
        <ng-container *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_month_status')?.value">
        <div class="col-12 mt-8">
          <input type="text" class="form-control " formControlName="max_req_apply_month" placeholder="Enter Value" [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.touched || leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.dirty) &&  leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.errors }"
          >
          <div *ngIf="(saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.touched || leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.dirty) && leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.errors"
          class="invalid-feedback">
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
              <!-- <div *ngIf="leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.hasError('min') && !(leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.errors?.pattern)">
                    {{this.messageService.fieldlengthvalidation('minvalue',0.1)}}
              </div> -->
              <div *ngIf="!(leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.errors.pattern) && leaveRequestLimitForm.controls[i].get('max_req_apply_month')?.errors.maxlength">
                {{this.messageService.fieldlengthvalidation('num','10')}}
              </div>

          </div>
        </div>
        </ng-container>
        <div class="col-12 ">
          <div class="form-row">
            <label for=" " class="form-label ">Maximum no. of leaves an employee can apply in a month  </label>
            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select 'No Limit' if there is no restriction on how many leave days an employee can request in a month; select 'Set Limit' to define a maximum number of leave days allowed per month." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"
            formControlName="max_leave_apply_month_status" [value]=false (ngModelChange)="addValidator()">
            <label class="form-check-label">
              No Limit
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="max_leave_apply_month_status" type="radio" [value]=true (ngModelChange)="addValidator()">
            <label class="form-check-label">
              Set Limit
            </label>
          </div>
        </div>
        <ng-container *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_apply_month_status')?.value">
        <div class="col-12 mt-8">
          <input type="text" class="form-control " formControlName="max_leave_apply_month" placeholder="Enter Value" [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.touched || leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.dirty) &&  leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.errors }"
          >
          <div *ngIf="(saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.touched || leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.dirty) && leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.errors"
          class="invalid-feedback">
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
             <div *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.hasError('min') && !(leaveRequestLimitForm.controls[i].get('max_leave_apply_month')?.errors?.pattern)">
                    {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
                      </div>
          </div>
        </div>
      </ng-container>
        <div class="col-12 ">
          <div class="form-row">
            <label for=" " class="form-label ">Minimum no. of leaves required to request  </label>
            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Set the limit for minimum number of days for which a leave request can be raised. For example- Employee should apply min 0.5 days of leave." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="min_leave_per_req_status" type="radio" [value]=false (ngModelChange)="addValidator()">
            <label class="form-check-label">
              No Limit
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="min_leave_per_req_status" [value]=true (ngModelChange)="addValidator()">
            <label class="form-check-label">
              Set Limit
            </label>
          </div>
        </div>
        <ng-container *ngIf="leaveRequestLimitForm.controls[i].get('min_leave_per_req_status')?.value">
        <div class="col-12 mt-8">
          <input type="text" class="form-control " formControlName="min_leave_per_req" placeholder="Enter Value" [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.touched || leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.dirty) &&  leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.errors }" (keyup)="setMinMaxValidator(i)"
          >
          <div *ngIf="(saveBtnClicked || leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.touched || leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.dirty) && leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.errors"
          class="invalid-feedback">
          <div *ngIf="leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.hasError('min') && !(leaveRequestLimitForm.controls[i].get('min_leave_per_req')?.errors?.pattern)">
                    {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
              </div>
          </div>
        </div>
      </ng-container>
        <div class="col-12 ">
          <div class="form-row">
            <label for=" " class="form-label ">Maximum no. of leaves required to request </label>
            <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Enter the Maximum number of leaves that can be raised in a request. " popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="max_leave_per_req_status" type="radio" [value]=false (ngModelChange)="addValidator()">
            <label class="form-check-label">
              No Limit
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="max_leave_per_req_status" [value]=true (ngModelChange)="addValidator()" >
            <label class="form-check-label">
              Set Limit
            </label>
          </div>
        </div>
        <ng-container *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_per_req_status')?.value">
        <div class="col-12 mt-8">
          <input type="text" class="form-control " formControlName="max_leave_per_req" placeholder="Enter Value" [ngClass]="{ 'is-invalid': (saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.touched || leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.dirty) &&  leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.errors }" (keyup)="setMinMaxValidator(i)"
          >
          <div *ngIf="(saveBtnClicked || leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.touched || leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.dirty) && leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.errors"
          class="invalid-feedback">
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.errors?.required">
              {{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.errors?.maxlimit">
                    {{this.messageService.greaterThanMessage('Maximum no. of leaves required to request','Minimum no.')}}</div>
          <div *ngIf="leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.hasError('min') && !(leaveRequestLimitForm.controls[i].get('max_leave_per_req')?.errors?.pattern)">
            {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
          </div>

          </div>
        </div>
      </ng-container>

      </div>
</div>
<!-- </div> -->
</ng-container>
</ng-container>
</ng-container>
</form>
