import { Component, OnInit, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Router, ActivatedRoute } from '@angular/router';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import * as moment from 'moment';
@Component({
  selector: 'app-challan-define-details',
  templateUrl: './challan-define-details.component.html',
  styleUrls: ['./challan-define-details.component.scss']
})

export class ChallanDefineDetailsComponent implements OnInit {

  submitted                                = false;
  isrequired                               = true;
  minor_head_code:string                   = "minor_head_code";
  maxValue:any;
  minValue:any;

  @Input() editFlag: any;
  @Input() currency:any;
  @Input() financialyear:any;
  @Output() submitmethod                   = new EventEmitter();
  @Output() empList                        = new EventEmitter();
  @Output()validStepper  = new EventEmitter();


  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public fb:FormBuilder,
    public appservice:AppService,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    
    if (this.formGp.get('payroll_month')?.value == 'January' || this.formGp.get('payroll_month')?.value == 'February' || this.formGp.get('payroll_month')?.value == 'March') {
      ++this.financialyear;
    }

    this.minValue = new Date(this.financialyear, this.appservice.getMonthNumberFromName(this.formGp.get('payroll_month')?.value), 1);
    this.maxValue = new Date(this.financialyear, Number(this.appservice.getMonthNumberFromName(this.formGp.get('payroll_month')?.value)) + 1, 0);
    this.minValue = moment(this.minValue).format('YYYY-MM-DD');
    this.maxValue = moment(this.maxValue).format('YYYY-MM-DD');
  }

  @Input()formGp : FormGroup = this.fb.group({

    challan_runpayroll: [''],
    payroll_month     : [''],
    voucher_no        : [''],
    amount            : [''],
    voucher_date      : [''],
    branch_code       : [''],
    branch_details    : [''],
    tds               : [''],
    surcharge         : [''],
    education_cess    : [''],
    intrest           : [''],
    others            : [''],
    cheque_dd_no      : [''],
    payment_date      : [''],
    deduction_date    : [''],
    ack_no            : [''],
    minor_head_code   : [null,],
    late_pay_fee      : [''],
    challan_mapped_amt : [''],
    challan_excess_amount : ['']
  });


  get f() {return  this.formGp.controls; }

  /********* SUBMIT FUNCTION ****************/
  validateSchedule() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }


  amountLimit(){
    let total_amt = Number(this.formGp.value.tds) + Number(this.formGp.value.surcharge) + Number(this.formGp.value.education_cess) + Number(this.formGp.value.intrest) + Number(this.formGp.value.others) + Number(this.formGp.value.late_pay_fee);

    this.formGp.get('amount')?.setValidators([Validators.required,digitdecimalValidator()]);
    this.formGp.get('amount')?.updateValueAndValidity();
    if(this.formGp?.value?.amount < this.formGp?.value?.challan_mapped_amt){

     this.validStepper.emit(true);
      this.formGp?.get('amount')?.setErrors({
        'greater' : true
      })
    }else{
     this.validStepper.emit(false);

    }
    if(this.formGp?.value?.amount != total_amt){
      this.formGp?.get('amount')?.setErrors({
        'equal' : true
      })
    }
    var pattern = /^[0-9]\d*(\.\d{1,2})?$/;
    let amountPatternValid = pattern.test(this.formGp?.value?.amount)

    if(!this.formGp.get('amount')?.errors){
      this.empList.emit()
    }
  }

  challanFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  disableDate(){
    return false;
  }

}
