import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SalaryRevisionService } from 'src/app/@core/services/salary-revision';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { FormBuilder,Validators} from '@angular/forms';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AmountCompareValidator } from 'src/app/@shared/validators/amountComparison.validators';
@Component({
  selector: 'app-salary-revision',
  templateUrl: './salary-revision.component.html',
  styleUrls: ['./salary-revision.component.scss']
})
export class SalaryRevisionComponent implements OnInit {
  showFilter = false;
  salaryRevisionHistory = false;
  salaryRevisionDetailsView = false;

  offset = 0;
  defaultlimit:any = 20;
  permissions:any = [];
  itemsList:any[] = [];
  searchKeyword  = "";
  listlength = 0;
  items:any[] = [];
  infinityloader  = false;
  nodata = false;
  loader =  true;
  revDropdown:any[] = [];
  @ViewChild(SearchbarComponent) child:any;
  direction = '';
  empid= "";
  a= 2;
  b=4;
  searchlistdata:any         = [] ;
  applicableList:any         = [];
  selectedItem               = [];
  list:any                   = [];
  viewloader                 = false;

  from                       = 'salary-revision';

  filterForm                = this.formBuilder.group({
                                applicable_to   : [null],
                                applicable_list : [''],
                                filterData      : [''],
                                total_revision  : ['',[NumberpatternValidator(),Validators.maxLength(10)]],
                                last_revision   : [''],
                                min_ctc         : ['',[digitdecimalValidator(),Validators.maxLength(10)]],
                                max_ctc         : ['',[digitdecimalValidator(),Validators.maxLength(10)]],
                                sortby          : ['all'],
                                genderMale      : [''],
                                genderFemale    : [''],
                                genderOther   : [''],

                            },
                            { validators: AmountCompareValidator.minMax('min_ctc', 'max_ctc') }
                            );
 selected: any;
 ranges: any = {
                'Today'       : [moment(), moment()],
                'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
              }

filterData = this.salaryRevisionService.FILTERDATA
isInvalidDate = (m: moment.Moment) =>  {

}
  constructor(private salaryRevisionService:SalaryRevisionService,
    public breakpointObserver: BreakpointObserver,
    public appService:AppService,
    private formBuilder: FormBuilder,
    private ams:ApprovalMatrixService,
    public messageService : MessageService
    )
    {
      this.salaryRevisionService.revision_realtime.subscribe(result =>{

        for(let i=0; i<this.items?.length; i++){

          if(this.items[i]['id'] == result.event['id']){
            this.items[i]['status']  = result.event['status'];

          }
        }

      })

    }

  ngOnInit(): void {
    this.getPermission();
    this.offset = 0;
    let arrFilter: any = []
    let arrList = []
    let arrData = []
    this.filterData = this.filterData?this.filterData:"ordering=-created_at"
    if(this.filterData){
      arrData = this.filterData.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })

   this.resetFilter(arrFilter)
    }
    this.getList();
    this.changeApplicableTo(1);
    this.salaryRevisionService.requestSocket();

  }
  resetFilter(arrFilter:any){
    let last_revision
     if(arrFilter['created_at']){
      last_revision = JSON.parse(arrFilter['created_at'])
     }
    let appto:any = arrFilter['employee__company__in'] && arrFilter['employee__company__in']!=''?"Company":
          arrFilter['employee__businessunit__in'] && arrFilter['employee__businessunit__in']!='' ?"BU":
          arrFilter['employee__department__in'] && arrFilter['employee__department__in']!='' ?"Department":
          arrFilter['employee__designation__in'] && arrFilter['employee__designation__in']!='' ?"Designation":
          arrFilter['employee__grade__in'] && arrFilter['employee__grade__in']!='' ?"Grade":
          arrFilter['employee__in'] && arrFilter['employee__in']!='' ?"Employee":
          null
    let applist:any = arrFilter['employee__company__in'] && arrFilter['employee__company__in']!=''? arrFilter['employee__company__in']?.split(','):
          arrFilter['employee__businessunit__in'] && arrFilter['employee__businessunit__in']!='' ? arrFilter['employee__businessunit__in']?.split(','):
          arrFilter['employee__department__in'] && arrFilter['employee__department__in']!='' ? arrFilter['employee__department__in']?.split(','):
          arrFilter['employee__designation__in'] && arrFilter['employee__designation__in']!='' ? arrFilter['employee__designation__in']?.split(','):
          arrFilter['employee__grade__in'] && arrFilter['employee__grade__in']!='' ? arrFilter['employee__grade__in']?.split(','):
          arrFilter['employee__in'] && arrFilter['employee__in']!='' ? arrFilter['employee__in']?.split(','):
          null
    let ordering:any = []

    ordering['employee__user__first_name']='name_asc'
    ordering['-employee__user__first_name'] = 'name_desc'
    ordering['-created_at'] = 'latest'
    ordering['-modified_at'] = 'all'

    let gender =  arrFilter['employee__gender__in']!='' ? arrFilter['employee__gender__in']?.split(',') : []
    this.filterForm.reset({
      applicable_to   : appto,
      applicable_list : applist,
      total_revision  :  arrFilter['total_revision'] && arrFilter['total_revision']!='' ?arrFilter['total_revision']:'',
      last_revision   : last_revision?.length>0?{'startDate':moment(last_revision[0]),'endDate':moment(last_revision[1])}:'',
      min_ctc         : arrFilter['min_ctc'] && arrFilter['min_ctc']!='' ?arrFilter['min_ctc']:'',
      max_ctc         : arrFilter['max_ctc'] && arrFilter['max_ctc']!='' ?arrFilter['max_ctc']:'',
      sortby          : arrFilter['ordering']!='' ? ordering[arrFilter['ordering']] : '',
      genderMale      : gender?.indexOf('Male') > -1 ? true:false,
      genderFemale    : gender?.indexOf('Female') > -1 ? true:false,
      genderOther    : gender?.indexOf('Other') > -1 ? true:false,

    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  res:any
  res1:any
  getList(){
    this.res?.unsubscribe()
    this.res=this.salaryRevisionService.getRevisionList(this.defaultlimit,this.offset,this.searchKeyword,this.filterData).subscribe((result: any) => {
      this.sLoading = false
      let res = result.body.results;
      this.dataFormating(res);
	   });

  }


  filterRule(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.items = [];
      this.getList();
    }else{
      this.searchHistory(data);
    }
  }

  /********* STATUS FILTER **************/
  selectItemList(value:any){
    this.loader = true;
    this.offset = 0;
    this.items = [];
    this.child.clearBar();
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.items.push(this.itemsList[i]);
    }
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.defaultlimit;
    if(this.listlength){
      this.getList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  sLoading = false
  searchHistory(searchKeyword:any){
    this.sLoading = true
    this.nodata = false
    this.loader = true;
    this.offset = 0;
    this.items  = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getList();

    }
  }

  changeApplicableTo(init:any){
    if(this.filterForm.get('applicable_to')?.value!=null && this.filterForm.get('applicable_to')?.value!=''){

      if(init==0)
      this.filterForm.get('applicable_list')?.setValidators([Validators.required])
      else
      this.filterForm.get('applicable_list')?.clearValidators();
      this.filterForm.controls['applicable_list'].updateValueAndValidity();
    }
    else
      this.filterForm.get('applicable_list')?.clearValidators();

    if(init != 1){
      if(init!=1){
        this.filterForm.patchValue({
          applicable_list: []
        });
      }

    }
    let keyvalue = this.filterForm['controls'].applicable_to.value;
    if(keyvalue != null){
    this.salaryRevisionService.applicableList(keyvalue).subscribe((res: any) => {
      this.list = [];
      if(this.filterForm['controls'].applicable_to.value == "Employee"){
        for(let key of res?.body){
          key.name = key?.first_name+" ";
          if(key.middle_name !=null && key.middle_name!='')
            key.name +=key.middle_name+" ";
          if(key.last_name !=null)
            key.name +=key.last_name;
            key.name +=" ("+key.employee_code+")";
          this.list.push(key);
        }
      } else if(this.filterForm['controls'].applicable_to.value == "Company"){
        for(let key of res?.body){
          key.name = key.company_name;
          this.list.push(key);
        }
      }
      else
        this.list = res?.body;
        if (this.list?.length != 0 && this.filterForm['controls'].applicable_to.value != "Employee") {
          this.selectedItem = this.list[0].id;
          this.selectAllForDropdownItems(this.list);
        }
    });
  }
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  applyFilter(){

    let last_revison     = this.filterForm.value.last_revision;
    let selectedDate:any;
    let genderFemale;
    let genderMale;
    let genderOther;
    let genderParam = '';
    let ordering='';
    this.child.clearBar();
    this.res?.unsubscribe()
    this.res1?.unsubscribe()
    if(this.filterForm.value.sortby=='name_asc')
      ordering = '&ordering=employee__user__first_name'
    else if(this.filterForm.value.sortby=='name_desc')
      ordering = '&ordering=-employee__user__first_name'
    else if(this.filterForm.value.sortby=='latest' )
      ordering = '&ordering=-created_at'
    else if( this.filterForm.value.sortby=='all')
     ordering = '&ordering=-modified_at'


    genderFemale  = (this.filterForm.value.genderFemale==true?'Female':'');
    genderMale    = (this.filterForm.value.genderMale==true?'Male':'');
    genderOther    = (this.filterForm.value.genderOther==true?'Other':'');
    if(genderFemale!='' && genderMale!='')
      genderParam = genderFemale + ',' + genderMale;
    else if(genderFemale!='' && genderMale=='')
      genderParam = genderFemale;
    else if(genderFemale=='' && genderMale!='')
      genderParam = genderMale;
    if(genderOther!='' && genderParam!='')
      genderParam = genderParam+','+genderOther
    else if(genderOther!='')
      genderParam = genderOther

    if(last_revison.startDate!=null && last_revison.startDate!=''){

      let start         = moment(last_revison.startDate._d).format('YYYY-MM-DD');
      let end           = moment(last_revison.endDate._d).format('YYYY-MM-DD');
      selectedDate      = [start,end];
    }
    let compParam:any              = '';
    let desigParam:any             = '';
    let empParam:any               = '';
    let gradeParam:any             = '';
    let buParam:any                = '';
    let deptParam:any              = '';
    // this.empParam=this.buParam=this.deptParam=this.desigParam=this.gradeParam=this.compParam='';
    if(this.filterForm['controls'].applicable_to.value == "Employee"){

      let data = this.filterForm['controls'].applicable_list.value.join(",");
      empParam = data;

    }
    else if(this.filterForm['controls'].applicable_to.value == "BU"){

      let data = this.filterForm['controls'].applicable_list.value.join(",");
      buParam = data;
    }
    else if(this.filterForm['controls'].applicable_to.value == "Department"){

      let data = this.filterForm['controls'].applicable_list.value.join(",");
      deptParam = data;
    }
    else if(this.filterForm['controls'].applicable_to.value == "Designation"){

      let data = this.filterForm['controls'].applicable_list.value.join(",");
      desigParam = data;
    }
    else if(this.filterForm['controls'].applicable_to.value == "Grade"){

      let data = this.filterForm['controls'].applicable_list.value.join(",");
      gradeParam = data;
    }
    else if(this.filterForm['controls'].applicable_to.value == "Company"){

      let data = this.filterForm['controls'].applicable_list.value.join(",");
      compParam = data;
    }


    this.filterData = 'total_revision='+(this.filterForm.value.total_revision!=null?this.filterForm.value.total_revision:'')+'&min_ctc='+(this.filterForm.value.min_ctc!=null?this.filterForm.value.min_ctc:'')+'&max_ctc='+(this.filterForm.value.max_ctc!=null?this.filterForm.value.max_ctc:'')+'&created_at='+(selectedDate!=null && selectedDate!="" ?JSON.stringify(selectedDate):JSON.stringify([]))+'&employee__company__in='+compParam+'&employee__businessunit__in='+buParam+'&employee__department__in='+deptParam+'&employee__designation__in='+desigParam+'&employee__grade__in='+gradeParam+'&employee__in='+empParam+'&employee__gender__in='+genderParam+ordering;
    this.loader = true;
    this.salaryRevisionService.setFilterData(this.filterData )
    this.items = [];
    this.getList();

  }
  get f() {return  this.filterForm.controls; }
  resetForm(){
    this.child.clearBar();
    this.filterForm.reset();
    this.filterForm.get('sortby')?.setValue('all');
    this.applyFilter();

  }
  dataFormating(data:any){
    let res = data;
    this.listlength = res.length;
    if (this.listlength == 0) {
      this.nodata = this.offset == 0 ? true :false;

    }else {
      this.nodata       = false;
      this.itemsList    = res;
    }
    if(this.offset ==0){
      this.items = [];
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }
  clear(index:number){
    let data = this.filterForm.value.applicable_list;
    data.splice(index, 1);
    this.filterForm.patchValue({
      applicable_list: data
    });
  }
  viewsalarydata(){
    if(this.permissions){

      this.salaryRevisionHistory = true;
      this.viewloader            = true
    }

  }

}
