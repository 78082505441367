import { Component, OnInit,Input,Output,EventEmitter, Inject} from '@angular/core';
import { ProfessionalsService } from 'src/app/@core/services/professionals.service';
import { FormBuilder,Validators ,FormArray,FormGroup,ValidatorFn,AbstractControl ,ValidationErrors} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MessageService } from 'src/app/message.global';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
@Component({
  selector: 'app-professionals',
  templateUrl: './professionals.component.html',
  styleUrls: ['./professionals.component.scss']
})
export class ProfessionalsComponent implements OnInit {
  currentYear:number        = 0;
  showSidePanel             = false;
  deletedId :number         = 0;
  loader                    = true;
  submitted                 = false;
  profSubmitted             = false;
  deleteClicked = false
  isError                   = false;
  showSidePanelProf         = false;
  deleteToggle              = false;
  loader1                   = false;
  fromDateConvert: Date     = new Date();
  toDateConvert: Date       = new Date();
  isDateConvert             = false;
  permissions:any           = [];
  deleteIndex               :any;
  professionalForm          = this.formBuilder.group({
                                  professional:this.formBuilder.array([])
                              });

  @Input() loginAs :any;
  @Input() routingUrl:any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;
  heading = 'Add'
  Editstatus = false;

  @Input() professionalData:any;
  @Input() id:any;
  @Input() is_employeeRequest:any;
  @Output() editProfessional    = new EventEmitter;
  @Output() createProfessional  = new EventEmitter;
  @Output() deleteProfessional  = new EventEmitter;
  @Output() loadData            = new EventEmitter();

  errorMessage :any;
  showInfo       = false;
  infoMsg:any;
  currentProfIndex:any

  constructor( @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,public messageService : MessageService,public appservice:AppService,private ProfessionalsService:ProfessionalsService,private formBuilder: FormBuilder,private datePipe:DatePipe) { }

  ngOnInit(): void {
    this.getPermission();
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.loader = false;
    this.currentYear = new Date().getFullYear();
    this.loadData.emit();
  }

  getPermission(){
    this.permissions = this.appservice.getPermissions(this.routingUrl);
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  professional() {
    return this.professionalForm.get('professional') as FormArray;
  }


  createProfession():FormGroup{
    return this.formBuilder.group({
                            previous_company      :[null,[Validators.required,Validators.maxLength(100),UnamepatternValidator()]],
                            previous_designation  :[null,[Validators.required,Validators.maxLength(100),UnamepatternValidator()]],
                            from_date             :[null,Validators.required],
                            to_date               :[null,[Validators.required]],
                            job_description       :['',Validators.maxLength(500)],
                            id                    :[null],
                            employee              :[this.id,[Validators.required]]
                          },
                            { validators: CustomDateValidators.fromToDate('from_date', 'to_date') }
  )
}

  addproffesional() {
    this.professional().push(this.createProfession());
  }

  disableDate(){
    return false;
  }
  getFirstChar(name:string,num:number){
    if(name.split(' ').length == 1){
      return name.substring(0, num);
    }else
    {
      return JSON.stringify(name.match(/\b(\w)/g)).replace(/[^A-Za-z]/g, '').substring(0, num);

    }
  }
  editProfession(){
    this.professionalForm.markAsUntouched()
    this.heading = 'Edit'
    if(this.is_employeeRequest?.is_professional_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo = false;
      this.loader1       = true;
      this.submitted     = false;
      this.isDateConvert = false;
      this.showSidePanelProf = true
      let professionalarr = []

      for(let key=0;key<this.professionalData.length;key++){
        this.professional().removeAt(key);
        this.addproffesional()
        professionalarr.push({
         "previous_company":this.professionalData[key].previous_company,
         "previous_designation":this.professionalData[key].previous_designation,
         "job_description":this.professionalData[key].job_description,
         "id":this.professionalData[key].id,
         "employee":this.professionalData[key].employee,
         "from_date":this.professionalData[key].from_date,
         "to_date":this.professionalData[key].to_date,
    });
    }

    this.professionalForm.reset({
      professional : professionalarr
    })
  }
  }

  addNewProf(){
    this.professionalForm.reset();
    this.professionalForm.markAsUntouched()
    if(this.is_employeeRequest?.is_professional_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo = false;
      this.profSubmitted      = false;
      this.isDateConvert      = false;
      this.showSidePanelProf  = true;
      if(this.professional().length == 0){
        this.addproffesional()
      }
    }
  }

  onSubmit(){
   if(this.professional().invalid){
      for(let i in this.professional().controls)
        this.professional().controls[i].markAsTouched();
      return;
    }else{
      this.submitted  = true;
      this.isDateConvert = true;
    }
    this.createProfessional.emit(this.professional().value);
    this.showSidePanelProf  = false;
  }

  emailDomain(todate:string,fromdate:string){
    return(control:AbstractControl):{[key:string]:any} | null=>{
      const email:string = control.value;
      return{'emaildomain':true};
    };
  }
  config = { format: "YYYY-MM-DD"};

  deleteProfession(index:number,id:any){
    if(id!==null && id!==''){

      this.deleteToggle     = true;
      this.deletedId        = this.professionalData[index].id;
      this.currentProfIndex = index
    }
    else
    this.professional().removeAt(index);

  }
  deleteProfessionConfirm(){
    this.deleteClicked  = true
    this.deleteToggle   = false;
    this.professional().removeAt(this.currentProfIndex);
    this.deleteProfessional.emit(this.deletedId);

  }

  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }
}

