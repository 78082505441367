import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function NumberpatternValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      // const NumberPattern = /^[0-9]*$/.test(value);

      const NumberPattern = /^[1-9][0-9]*$/.test(value);

      return !NumberPattern ? {pattern:true}: null;
  }
}

