import { Component, OnInit, Input,Output, EventEmitter,Inject, ChangeDetectorRef } from '@angular/core';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { Observable } from 'rxjs/internal/Observable';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-ctc-revision',
  templateUrl: './ctc-revision.component.html',
  styleUrls: ['./ctc-revision.component.scss']
})
export class CtcRevisionComponent implements OnInit {

  // Data from Parent
  @Input() companyDD:any
  @Input() buDD:any
  @Input() disabled :any
  @Input() BranchDD:any
  @Input() DepDD:any
  @Input() DesignationDD:any
  @Input() gradeDD:any
  @Input() employeeDropdown:any
  @Input() empstatusDD:any
  @Input() emptypeDD:any
  // Above needs to be cleared
  @Input() ctcRevisionForm:any
  @Input() viewDetail:any
  @Input() resDataFilter:any;
  @Input() submitted: any;
  @Input() payGroupDD:any
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() salFilter:any
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() ouFilterForm: any

  // Data to Parent
  @Output() filterPanel = new EventEmitter();
  @Output()  resetFilter = new EventEmitter();
  @Output() callMonthlyReport = new EventEmitter();
  @Output() loader = new EventEmitter();
  @Output() submitDone  = new EventEmitter();

  @Input() Years : any;
  year_placeholder  = 'Select year'
  month_placeholder = 'Select month'
  dojValue : any
  dolValue:any
  effectiveValue:any;
  payoutValue:any;
  selectedYear = new Date().getFullYear();
  attendListOfMonth:string  = "listOfMonth";
  payLoader         : boolean = false

  // Form
  get f()  { return this.ctcRevisionForm.controls; }

  constructor(
    public cd: ChangeDetectorRef,
    private reportService:ReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getPayGroupList()
  }

  getPayGroupList() {
    this.payLoader = true
    this.reportService.getPayGroupList().subscribe((res:any)=>{
      this.payGroupDD = res;
      this.selectAllForDropdownItems(this.payGroupDD);
      this.payLoader = false
    });
  }

   // Close Filter Panel
   close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Close the selected items on filter panel
  clearcommonForm(item:any,index:any){
   if(item == "pay_group"){
      let daata = this.ctcRevisionForm.value.pay_group;
      daata.splice(index, 1);
      this.ctcRevisionForm.patchValue({
        pay_group: daata
      });
    }
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  // Filter Reset
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.ctcRevisionForm.get('date_of_joining')?.reset('');
      this.ctcRevisionForm.get('date_of_leaving')?.reset('');
      this.ctcRevisionForm.get('pay_group')?.reset('');
      this.ctcRevisionForm.get('effective_month')?.reset('');
      this.ctcRevisionForm.get('payout_month')?.reset('');
      this.ctcRevisionForm.get('doj_specific_month')?.reset(null);
      this.ctcRevisionForm.get('doj_specific_year')?.reset(null);
      this.ctcRevisionForm.get('dol_specific_month')?.reset(null);
      this.ctcRevisionForm.get('dol_specific_year')?.reset(null);
      this.ctcRevisionForm.get('effective_specific_month')?.reset(null);
      this.ctcRevisionForm.get('effective_specific_year')?.reset(null);
      this.ctcRevisionForm.get('payout_specific_month')?.reset(null);
      this.ctcRevisionForm.get('payout_specific_year')?.reset(null);
    }
    this.callMonthlyReport.emit({
      reportName : 'CTC Revision'
    })
  }

    applyBtn : boolean = false

    filterFormData(use:any){
      this.ctcRevisionForm.controls.date_of_joining.value == true ? this.ctcRevisionForm.get('date_of_joining')?.setValue(this.ctcRevisionForm.controls.doj_specific_year.value+'-'+this.dojValue) : this.ctcRevisionForm.get('date_of_joining')?.setValue(this.ctcRevisionForm.controls.date_of_joining.value);
      this.ctcRevisionForm.controls.date_of_leaving.value == true ? this.ctcRevisionForm.get('date_of_leaving')?.setValue(this.ctcRevisionForm.controls.dol_specific_year.value+'-'+this.dolValue) : this.ctcRevisionForm.get('date_of_leaving')?.setValue(this.ctcRevisionForm.controls.date_of_leaving.value);
      this.ctcRevisionForm.controls.effective_month.value == true ? this.ctcRevisionForm.get('effective_month')?.setValue(this.ctcRevisionForm.controls.effective_specific_year.value+'-'+this.effectiveValue) : this.ctcRevisionForm.get('effective_month')?.setValue(this.ctcRevisionForm.controls.effective_month.value);
      this.ctcRevisionForm.controls.payout_month.value == true ? this.ctcRevisionForm.get('payout_month')?.setValue(this.ctcRevisionForm.controls.payout_specific_year.value+'-'+this.payoutValue) : this.ctcRevisionForm.get('payout_month')?.setValue(this.ctcRevisionForm.controls.payout_month.value);
      let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
      let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
      let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
      let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
      let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
      let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
      let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
      let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
      let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
      let pay_group = this.ctcRevisionForm.controls.pay_group.value==''?JSON.stringify([]):JSON.stringify(this.ctcRevisionForm.controls.pay_group.value);
      let date_of_joining = (this.ctcRevisionForm.controls.date_of_joining.value == true && (this.ctcRevisionForm.controls.doj_specific_month.value == '' || this.ctcRevisionForm.controls.doj_specific_month.value == null)) ? JSON.stringify([]) : this.ctcRevisionForm.controls.date_of_joining.value
      let date_of_leaving = (this.ctcRevisionForm.controls.date_of_leaving.value == true && (this.ctcRevisionForm.controls.dol_specific_month.value == '' || this.ctcRevisionForm.controls.dol_specific_month.value == null)) ? JSON.stringify([]) : this.ctcRevisionForm.controls.date_of_leaving.value
      if(use == 'filter'){
        return `date_of_joining=${date_of_joining}&date_of_leaving=${date_of_leaving}&effective_month=${this.ctcRevisionForm.controls.effective_month.value}&payout_month=${this.ctcRevisionForm.controls.payout_month.value}&pay_group=${pay_group}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
      }else{
        return JSON.stringify({'date_of_joining':this.ctcRevisionForm.controls.date_of_joining.value == null ? [] : this.ctcRevisionForm.controls.date_of_joining.value ,'date_of_leaving':this.ctcRevisionForm.controls.date_of_leaving.value ==  null ? [] : this.ctcRevisionForm.controls.date_of_leaving.value,'pay_group':this.ctcRevisionForm.controls.pay_group.value,'effective_month':this.ctcRevisionForm.controls.effective_month.value == null ? [] : this.ctcRevisionForm.controls.effective_month.value,'payout_month':this.ctcRevisionForm.controls.payout_month.value == null ? [] : this.ctcRevisionForm.controls.payout_month.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
      }
    }

  // Filter Apply
  submitCTCRevision(){
    this.submitted      = true;
    if (this.ctcRevisionForm.invalid) {
      return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail      = false;
    this.applyBtn = false
  }

  // Emiting the value of radio to parent
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthType:any,yearType:any){
    if(e.target.value == 'on' ){
      this.ctcRevisionForm?.get(monthType)?.setValue(null);
      this.ctcRevisionForm?.get(yearType)?.setValue(null);
      this.ctcRevisionForm?.get(contrlName)?.setValue(true);
     }
     else{
      this.ctcRevisionForm?.get(contrlName)?.setValue(val);

     }
     this.setRequired(contrlName)
     this.cd.detectChanges()
  }

   // Tags closing
   applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }
    if(this.closeControl == 'date_of_joining' || this.closeControl == 'date_of_leaving' || this.closeControl == 'effective_month' || this.closeControl == 'payout_month' || this.closeControl == 'pay_group'){
      this.ctcRevisionForm.get(this.closeControl)?.setValue('');
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('');
    }
    this.submitCTCRevision();
  }

    resetPage(){
      this.applyBtn = true
      this.reportService.calculateOffset(0)
      this.cd.detectChanges()
    }

    getData(){
      const data = this.filterFormData('')
      const emptyKeys = Object.keys(data)
      .filter((key:any) => this.isValueEmpty(data[key]));
      return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
    }

    isValueEmpty(value:any) {
      return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
    }

    stringifyCont(control: any): string {
      return control === '' ? JSON.stringify([]) : JSON.stringify(control);
    }

    constructDate(event:any,from:any){
      var date      = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
      if(from=='doj'){
        this.dojValue = String(date.getMonth() + 1).padStart(2, '0');
      }
      else if(from=='dol'){
        this.dolValue = String(date.getMonth() + 1).padStart(2, '0');
      }
      else if(from=='effective'){
        this.effectiveValue = String(date.getMonth() + 1).padStart(2, '0');
      }
      else
      this.payoutValue = String(date.getMonth() + 1).padStart(2, '0');
    }
    setRequired(type:any){
      let arr:any = [];
      if(type=='date_of_joining')
      arr = ['doj_specific_month','doj_specific_year']
      else if(type=='date_of_leaving')
      arr = ['dol_specific_month','dol_specific_year']
      else if(type=='effective_month')
      arr = ['effective_specific_month','effective_specific_year']
      else
      arr = ['payout_specific_month','payout_specific_year']
      let val = this.ctcRevisionForm.get(type).value;
      if(val == true){
        arr.forEach((item:any)=>{
          this.ctcRevisionForm.get(item)?.setValidators([Validators.required]);
          this.ctcRevisionForm.get(item)?.markAsTouched();
          this.ctcRevisionForm.get(item)?.updateValueAndValidity();
        })
      }else{
        arr.forEach((item:any)=>{
          this.ctcRevisionForm.get(item)?.clearValidators()
          this.ctcRevisionForm.get(item)?.updateValueAndValidity();
        })
      }
    }

}
