import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Output() selectUploadType=  new EventEmitter();
  @Output() changeTab=  new EventEmitter();
  @Input() datatype:any = [];
  @Input() selectedIndex:any = -1;
  selectedDataType:any = [];

  constructor() { }

  ngOnInit(): void {
  }
  makeActive(index:number){
    this.selectUploadType.emit(index);
  }
  goNext(tab:number){
    this.changeTab.emit(tab);
  }

}
