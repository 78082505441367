import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { PayGroupService } from 'src/app/@core/services/pay-group.service';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-define',
  templateUrl: './pg-define.component.html',
  styleUrls: ['./pg-define.component.scss']
})
export class PgDefineComponent implements OnInit {

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public messageService : MessageService,
    public service:PayGroupService,
    public existDataService :  ExistDatasService,
    public route: ActivatedRoute
  ) { }
  paysliplist:any;
  taxSlip:any;
  taxPdfFile:any;
  salPdfFile:any;
  taxPdfName:any;
  salPdfName:any;
  modalToggle = false;
  currentDocName:any;
  fileName:any;
  payGroupUnit : any = []
  validated : boolean = false
  value:any
  private subjectKeyUp = new Subject<any>();
  id:any
  revisionlist : any = []
  appointmentlist : any = []

  @Input()formGp : FormGroup = this.formBuilder.group({
    pay_group_name: ['', [Validators.required]],
    pay_slip_id: [null, [Validators.required]],
    description: ['', [Validators.required]],
    appointment_id: [null, [Validators.required]],
    revision_id: [null, [Validators.required]]
  });
  @Input() disabled=false;
  @Input() editflag:any
  @Output()
  submitmethod = new EventEmitter();

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(600)).subscribe((d:any)=>{
      this.existService();
    })

    this.id = this.route.snapshot.params['id']

    this.service.getTemplate('TaxSlip').subscribe((res:any)=>{
      this.taxSlip = res;
      if(this.formGp.value.pay_slip_id)
      this.setTemplatePreview(this.formGp.value.tax_slip_id,'tax')
    })
    this.service.getTemplate('SalarySlip').subscribe((res:any)=>{
      this.paysliplist = res;
      if(this.formGp.value.pay_slip_id)
      this.setTemplatePreview(this.formGp.value.pay_slip_id,'salary')
    })
    this.service.getAppointmentLetters().subscribe((res:any)=>{
      this.appointmentlist = res
      if(this.formGp.value.appointment_id){
        this.setPreview(this.formGp.value.appointment_id,'appointment')
      }
    })
    this.service.getRevisiontLetters().subscribe((res:any)=>{
      this.revisionlist = res
      if(this.formGp.value.revision_id){
        this.setPreview(this.formGp.value.revision_id,'revision')
      }
    })
  }

  validateConfigureFlow() {
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(this.formGp.getRawValue);
  }
  setTemplatePreview(event:any,formid:any){
    if(formid=='salary'){

      var payslipPdf =  this.paysliplist.filter(function(payslip:any) {

        return payslip.id == event;
      });

      if(payslipPdf!=null && payslipPdf!=undefined && payslipPdf[0].hasOwnProperty('pdf')){

        this.salPdfFile = payslipPdf[0]['pdf'];
        this.salPdfName = payslipPdf[0]['template_name'];

      }
    }
    else{
      var taxSlipPdf =  this.taxSlip.filter(function(taxtSlip:any) {

        return taxtSlip.id == event;
      });
      if(taxSlipPdf!=null && taxSlipPdf!=undefined && taxSlipPdf[0].hasOwnProperty('pdf')){

        this.taxPdfFile = taxSlipPdf[0]['pdf'];
        this.taxPdfName = taxSlipPdf[0]['template_name'];
      }
    }

  }
  // convenience getter for easy access to form fields
  get f() { return this.formGp.controls; }

  // Simplified function to use for validation
  existService(){

    if(!this.editflag){
      this.value ={
        "page": "PayGroup",
        "field":"name",
        "value": this.f.pay_group_name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"PayGroup",
        "field":"name",
        "value": this.f.pay_group_name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.payGroupUnit = res.body

      if(this.payGroupUnit.data ==='invalid'){
        this.f.pay_group_name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)

  keyUp(){
    this.subjectKeyUp.next(this.f.pay_group_name.value)
  }

  appointmentFile :any
  appointmentName :any
  revisionFile : any
  revisionName : any
  // Preview setup for Letter
  setPreview(event:any,type:any){
    if(type == 'appointment'){
      const pdf = this.appointmentlist.find((letter: any) => {
        return letter?.id === event;
      });
      if(Object.keys(pdf)?.length != 0 && pdf.hasOwnProperty('pdf_url') && pdf?.pdf_url !=null && pdf?.pdf_url != undefined){
        this.appointmentFile = pdf['pdf_url']
        this.appointmentName =  pdf['file_name']
      }
    }else{
      const pdf = this.revisionlist.find((letter: any) => {
        return letter?.id === event;
      });
      if(Object.keys(pdf)?.length != 0 && pdf.hasOwnProperty('pdf_url') && pdf?.pdf_url !=null && pdf?.pdf_url != undefined){
        this.revisionFile = pdf['pdf_url']
        this.revisionName =  pdf['file_name']
      }
    }
  }
}
