import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent implements OnInit {
  // activeTab = 1;
  menuData:any = [];
  menuList:any;
  constructor(
    public route:ActivatedRoute,
    private appService: AppService,public router: Router
  ) { }

  ngOnInit(): void {
    // this.route.params.subscribe((params: Params) => {
    //   if( !isNaN(params['tab'])){
    //     this.activeTab = +params['tab'];
    //   }
    // })
    this.getPermission()
  }

  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
      
    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/holiday-rule')
      this.router.navigate([this.menuData[0]?.link]);

  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/holiday-rule');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }

}
