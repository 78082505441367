import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { ExpenseReceiptService } from 'src/app/@core/services/expense-receipt.service';
import { AppService } from 'src/app/app.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';


@Component({
  selector: 'app-receipt-landing-table',
  templateUrl: './receipt-landing-table.component.html',
  styleUrls: ['./receipt-landing-table.component.scss']
})
export class ReceiptLandingTableComponent implements OnInit {

  @Input() status:any
  @Input() sLoading:any;
  @Input() permissions:any
  @Input() currency:any
  @Input() clearBar:any;
  @Output() searchout       = new EventEmitter();
  @Output() clearSearch     = new EventEmitter();


  landingScreenData:any   = [];
  loader                  = false
  page                    = 1;
  pageSize                = 20;
  searchString:any        = '';
  searchlistdata:any      = [];
  viewReciept             = false;
  viewRecieptLoader       = false;
  viewRecieptData:any     = [];
  isdelete                = false;
  deleteToggle            = false;
  deleteClicked           = false;
  alertToggle             = false
  receiptMsg              = "";
  searchKeyword           = ""
  filter:any              = "";
  statusList:any          = {
    'Accepted'    : 'badge-success',
    'Submitted'   : 'badge-primary',
    'Unreported'  : 'badge-warning',
    'Expired'     : 'badge-danger1',
  }


  viewDetail:any;
  deleteId:any

  constructor(
   public receiptService: ExpenseReceiptService,
   public appService: AppService,
   public reportService: ExpenseReportRequestService

  ) { }

  ngOnInit() {

    this.getLandingPageData();
  }
  getLandingPageData(){
    this.loader  = true;
    this.receiptService.receiptLandingdata(this.status,this.searchKeyword,this.filter).subscribe((res:any)=>{
      this.landingScreenData  = res;
      this.loader  = false;
      this.sLoading = false
      this.searchout.emit(this.sLoading);
  })
    // this.receiptService.receiptDropdown(this.status,this.filter).subscribe((res:any)=>{
    //   this.searchlistdata     = res;
    //   for(let i=0;i<this.searchlistdata.length;i++){
    //     this.searchlistdata [i]['name'] = this.searchlistdata[i]['number']
    //   }
    // this.searchout.emit(this.searchlistdata);
    // })
  }
  getSigleReceiptView(id:any){
    this.viewRecieptLoader  = true;
    this.viewReciept        = true;
    this.viewRecieptData    = []
    this.reportService.getSingleReceiptData(id).subscribe((res:any)=>{
      this.viewRecieptData    = res;
      this.viewRecieptLoader  = false;
    })
  }
  deletConfirm(id:any,status:any){
    if(status!='Unreported' && status!='Expired'){

      this.alertToggle  = true;
      this.receiptMsg   =  'Receipt in the '+status+' status cannot be deleted.'
    }
    else{

      this.deleteId     = id;
      this.deleteToggle = true;
    }
  }
  closeInfo(status:any){
    this.alertToggle  = false;

  }
  deleteReceipt(){
    this.deleteClicked = true;

    this.receiptService.receiptDelete(this.deleteId,{"is_active": false}).subscribe((res:any)=>{
      this.deleteClicked = false;
      this.deleteToggle  = false;
      this.getLandingPageData();

    })
  }
  searchReceipt(searchKeyword:any){
    this.sLoading = true
    this.searchKeyword      = searchKeyword;
    if(searchKeyword != ""){
      this.getLandingPageData();

    }
  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.getLandingPageData();
    }else{
        this.reportService.getSingleReceiptData(data).subscribe((res: any) => {
          this.searchKeyword      = res.name
          this.landingScreenData  = [res];
          this.loader             = false;
        });
    }
  }
  applyFilter(data:any){
    this.filter = data;
    this.getLandingPageData();
  }
  resetFilter()
  {
    this.filter = '';
    this.getLandingPageData();


  }
  closeView(){
    this.viewReciept  = false;
  }
}
