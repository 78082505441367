    <div class="container-fluid p-32 form-container" [formGroup]="formGp" >
        <div class="row ">
            <div class="col-12 d-flex justify-content-between flex-wrap">
                <div>
                    <p class="text-uppercase fw-500 mb-0">Salary structure</p>
                    <p class="text-helper mb-0">Add complete details of Employee salary structure </p>
                </div>
            </div>
        </div>
        <div class="col-12 mt-16" *ngIf="Isalert && statutoryLockedinfo!=''">
          <div role="alert" class="alert alert-warning mb-0 show">
              <p class="mb-0 fs-14">{{statutoryLockedinfo}}</p>
              <div class="btn-close pointer" (click)="Isalert=false;"></div>
          </div>
        </div>
        <div class="col-12 mt-16" *ngIf="ctcalert && (empSalaryData.message!='' && empSalaryData.message!=null && empSalaryData.message!=undefined)">
          <div role="alert" class="alert alert-warning mb-0 show">
              <p class="mb-0 fs-14">{{empSalaryData.message}}</p>
              <div class="btn-close pointer" (click)="ctcalert=false;"></div>
          </div>
        </div>
        <div class="row mt-24">
            <div class="col">
                <div class="card card-c2  px-16 ">
                    <div class="card-body px-0 py-16 d-flex align-items-center ">
                        <div class=" me-8 ">
                            <img *ngIf="employeeData.profile_image !=  null && employeeData.profile_image !=  ''" class="avatar-circle sq-40  " src="{{employeeData.profile_image}}">
                            <div *ngIf="employeeData.profile_image ==  '' || employeeData.profile_image ==  null" class="avatar-circle sq-40 {{employeeData.color_code}} avatar-border" >{{employeeData.nameShort}}</div>
                        </div>
                        <h6 class="fw-600 mb-0">{{employeeData.name+" ("+employeeData.employee_code+")"}}</h6>

                    </div>
                    <div class="card-body px-0 py-24 bt" [formGroup]="formGp">
                        <div class="row row-16">
                            <div class="col-lg-6 form-row">
                                <label for="" class="form-label">Pay group</label>
                                <input type="text" formControlName="pay_group_name" autocomplete="off" name="pay_group_name" id="pay_group_name" class="form-control" >
                                <!-- <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Pay Group')}}" formControlName="pay_group" class="form-ngselect"  [ngClass]="{ 'is-invalid': (submitted || f.pay_group.touched || f.pay_group.dirty) &&  f.pay_group.errors?.required}">
                                    <ng-option *ngFor="let pay_group of payGroupDD" [value]="pay_group.id">{{pay_group.name}}</ng-option>
                                </ng-select> -->
                                <div *ngIf="(submitted || f.pay_group.touched) && f.pay_group.errors" class="invalid-feedback"><div *ngIf="f.pay_group.errors.required">{{this.messageService.validationDisplay('required')}}
                                </div></div>
                            </div>
                            <div class="col-lg-6 form-row">
                                <label for="" class="form-label required">CTC </label>
                                <div class="form-icon icon-right">
                                    <input type="text" formControlName="ctc" autocomplete="off" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('CTC')}}"
                                        class="form-control" >
                                    <span>{{empSalaryData.currency}}</span>

                                    <div *ngIf="(submitted || f.ctc.touched) && f.ctc.errors" class="invalid-feedback">
                                        <div *ngIf="f.ctc.errors?.required">{{this.messageService.validationDisplay('required')}}
                                        </div>
                                        <div *ngIf="f.ctc.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!(f.ctc.errors?.pattern) && f.ctc.errors?.max">{{this.messageService.fieldlengthvalidation('num','10')}}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <app-table-loader *ngIf="componentLoader"></app-table-loader>

                            <ng-container *ngIf="!componentLoader">

                              <div class="col-12" *ngIf="gross_benefits?.length>0">
                                  <div class="fw-500">Gross benefits</div>
                              </div>
                              <div class="col-12" *ngIf="gross_benefits?.length>0">
                                  <div class="card card-c2 ">
                                      <div class="table-responsive">
                                          <table class="table table-striped form-table  table-sm " formArrayName="gross_month">
                                              <thead>
                                                  <tr>
                                                      <th scope="col">Component name</th>
                                                      <th scope="col">Nature </th>
                                                      <th scope="col">Formula</th>
                                                      <th scope="col">Monthly Pay</th>
                                                      <th scope="col">Yearly Pay</th>
                                                      <th scope="col">Months</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <ng-container *ngFor="let gross_month of month().controls; let i=index;" [formGroupName]="i">
                                                    <tr *ngIf="!gross_benefits[i].removed" class="{{gross_benefits[i]?.error==true?'td-alert':''}}">
                                                        <input type="hidden" formControlName="comp_id" >
                                                        <td>{{gross_benefits[i]?.name}}</td>
                                                        <td>{{gross_benefits[i]?.nature==true?'Fixed':'Variable'}}</td>
                                                        <td>{{gross_benefits[i]?.formula}}</td>
                                                        <td style="max-width: 12.5rem;" >
                                                          <ng-container *ngIf="(gross_benefits[i]?.component_type_name
                                                          !='other allowance' && gross_benefits[i]?.error==false && empSalaryData?.processed==false && gross_benefits[i]?.is_runtime_tag==false);else otherallowance " >

                                                        <div class="form-icon {{gross_benefits[i].fbp_amount>0?'icon-right':''}}" [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('monthly_renum')?.touched || month().controls[i]?.get('monthly_renum')?.dirty) &&  month().controls[i]?.get('monthly_renum')?.errors}">
                                                            <input  type="text" class="form-control" formControlName="monthly_renum"
                                                            [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('monthly_renum')?.touched || month().controls[i]?.get('monthly_renum')?.dirty) &&  month().controls[i]?.get('monthly_renum')?.errors}" (keyup)="monthlyValidator(i,'gross_benefits')"
                                                            >
                                                            <ng-container *ngIf="gross_benefits[i].fbp_amount>0">

                                                              <ng-template #popContent>
                                                                <div class="d-flex flex-column flex-center" style="">
                                                                    <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                                    <span class="fs-12">
                                                                        <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                        <span class="fw-500"> {{gross_benefits[i].fbp_amount | currency: empSalaryData.currency}}</span>
                                                                    </span>
                                                                </div>
                                                               </ng-template>
                                                             <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-info" container="body" triggers="hover"></i>
                                                            </ng-container>
                                                        </div>
                                                            <div *ngIf="(month().controls[i]?.get('monthly_renum')?.touched || submitted || month().controls[i]?.get('monthly_renum')?.dirty) && month().controls[i]?.get('monthly_renum')?.errors" class="invalid-feedback">

                                                              <div *ngIf="month().controls[i]?.get('monthly_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                              <div *ngIf="month().controls[i]?.get('monthly_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                              <div *ngIf="(!(month().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  month().controls[i]?.get('monthly_renum')?.errors?.max)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                              <div *ngIf="(!(month().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('monthly_renum')?.errors?.max) && !(month().controls[i]?.get('monthly_renum')?.errors?.required) && month().controls[i]?.get('monthly_renum')?.errors?.max )">{{this.messageService.monthlyAmountValidation()}}</div>
                                                              <div *ngIf="(!(month().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('monthly_renum')?.errors?.max) && !(month().controls[i]?.get('monthly_renum')?.errors?.required) && !(month().controls[i]?.get('monthly_renum')?.errors?.max) && month().controls[i]?.get('monthly_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                            </div>

                                                          </ng-container>
                                                          <ng-template #otherallowance>

                                                            <span class=" ms-8" *ngIf="gross_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span> {{gross_benefits[i]?.monthly_renum | currency: empSalaryData.currency}}
                                                          </ng-template>

                                                        </td>
                                                        <!-- <td>
                                                            <span class=" ms-8" *ngIf="gross_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                            {{gross_benefits[i]?.year_renum | currency: empSalaryData.
                                                                currency}}
                                                        </td> -->


                                                        <td style="max-width: 12.5rem;" >
                                                          <ng-container *ngIf="(gross_benefits[i]?.component_type_name
                                                          !='other allowance' && gross_benefits[i]?.error==false && empSalaryData?.processed==false && gross_benefits[i]?.is_runtime_tag==false);else otherallowanceYear " >

                                                        <div class="form-icon" [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('year_renum')?.touched || month().controls[i]?.get('year_renum')?.dirty) &&  month().controls[i]?.get('year_renum')?.errors}">
                                                            <input  type="text" class="form-control" formControlName="year_renum"
                                                            [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('year_renum')?.touched || month().controls[i]?.get('year_renum')?.dirty) &&  month().controls[i]?.get('year_renum')?.errors}" (keyup)="yearlyValidator(i,'gross_benefits')"
                                                            >
                                                            <!-- <ng-container *ngIf="gross_benefits[i].fbp_amount>0">

                                                              <ng-template #popContent>
                                                                <div class="d-flex flex-column flex-center" style="">
                                                                    <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                                    <span class="fs-12">
                                                                        <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                        <span class="fw-500"> {{gross_benefits[i].fbp_amount | currency: empSalaryData.currency}}</span>
                                                                    </span>
                                                                </div>
                                                               </ng-template>
                                                             <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-info" container="body" triggers="hover"></i>
                                                            </ng-container> -->
                                                        </div>
                                                            <div *ngIf="(month().controls[i]?.get('year_renum')?.touched || submitted || month().controls[i]?.get('year_renum')?.dirty) && month().controls[i]?.get('year_renum')?.errors" class="invalid-feedback">

                                                              <div *ngIf="month().controls[i]?.get('year_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                              <div *ngIf="month().controls[i]?.get('year_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                              <div *ngIf="(!(month().controls[i]?.get('year_renum')?.errors?.pattern) &&  month().controls[i]?.get('year_renum')?.errors?.max)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                              <div *ngIf="(!(month().controls[i]?.get('year_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('year_renum')?.errors?.max) && !(month().controls[i]?.get('year_renum')?.errors?.required) && month().controls[i]?.get('year_renum')?.errors?.max )">{{this.messageService.monthlyAmountValidation()}}</div>
                                                              <div *ngIf="(!(month().controls[i]?.get('year_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('year_renum')?.errors?.max) && !(month().controls[i]?.get('year_renum')?.errors?.required) && !(month().controls[i]?.get('year_renum')?.errors?.max) && month().controls[i]?.get('year_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                            </div>

                                                          </ng-container>
                                                          <ng-template #otherallowanceYear>

                                                            <span class=" ms-8" *ngIf="gross_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span> {{gross_benefits[i]?.year_renum | currency: empSalaryData.currency}}
                                                          </ng-template>

                                                        </td>


                                                        <td class="form-cell" >


                                                            <ng-select style="width: 8.75rem;" appendTo="body" placeholder="{{this.messageService.selectplaceholddisplay('Month')}}" formControlName="gross_month" [items]="moduleDropdown"[multiple]="true" bindLabel="value" [closeOnSelect]="false" bindValue="id" class="multiselect" *ngIf="gross_benefits[i]?.nature==false" #gross [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('gross_month')?.touched) &&  month().controls[i]?.get('gross_month')?.errors}" (change)="CalculateMonthlyPay(i,'gross_month')">
                                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                                    <div class="ng-value mx-1" *ngIf="month().controls[i].value.gross_month.length ">
                                                                        <span class="ng-value-label mx-1">{{month().controls[i].value.gross_month.length}} Months selected</span>
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                                {{ item.value }}
                                                                </ng-template>
                                                            </ng-select>
                                                            <ul class="selected-values  mt-2">

                                                                <li class="ng-value" *ngFor="let item of month().controls[i].value.gross_month, let m =index">
                                                                    <ng-container *ngFor="let insideitem of moduleDropdown, let k =index">
                                                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                                        {{ (insideitem['value']?.length>15)? (insideitem['value'] | slice:0:15)+'..':(insideitem['value'])  }}</span>
                                                                    </ng-container>
                                                                    <span class="ng-value-icon right" aria-hidden="true" (click)="RemoveData(m,'gross_month',i,'')"><i class="icon-close fs-16"></i></span>
                                                                </li>
                                                            </ul>
                                                            <div *ngIf="(month().controls[i]?.get('gross_month')?.touched || submitted) && month().controls[i]?.get('gross_month')?.errors" class="invalid-feedback">

                                                              <div *ngIf="month().controls[i]?.get('gross_month')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                                              </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                  </ng-container>

                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12" *ngIf="other_benefits?.length>0">
                                  <div class="fw-500">Other benefits</div>
                              </div>
                              <div class="col-12" *ngIf="other_benefits?.length>0">
                                  <div class="card card-c2 ">
                                      <div class="table-responsive">
                                          <table class="table table-striped form-table table-sm " formArrayName="other_month">
                                              <thead>
                                                  <tr>
                                                      <th scope="col">Component name</th>
                                                      <th scope="col">Nature </th>
                                                      <th scope="col">Formula</th>
                                                      <th scope="col">Monthly Pay</th>
                                                      <th scope="col">Yearly Pay</th>
                                                      <th scope="col">Months
                                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the month if the paycomponent is a variable pay." popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                                                      </th>
                                                  </tr>
                                              </thead>
                                              <tbody >
                                                  <ng-container *ngFor="let other_month of otherMonth().controls; let i=index;" [formGroupName]="i">

                                                    <tr class="{{other_benefits[i]?.error==true?'td-alert':''}}">

                                                        <input type="hidden" formControlName="comp_id" >

                                                        <td>{{other_benefits[i]?.name}}</td>
                                                        <td>{{other_benefits[i]?.nature==true?'Fixed':'Variable'}}</td>
                                                        <td>{{other_benefits[i]?.formula}} </td>
                                                        <td   style="max-width: 12.5rem;" >
                                                            <ng-container *ngIf="other_benefits[i]?.error==false;else invalid_section">
                                                                <div class="form-icon{{other_benefits[i].fbp_amount>0?'icon-right':''}} " [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('monthly_renum')?.touched || otherMonth().controls[i]?.get('monthly_renum')?.dirty || submitted) &&  otherMonth().controls[i]?.get('monthly_renum')?.errors}">
                                                                <input type="text"class="form-control" formControlName="monthly_renum" [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('monthly_renum')?.touched || otherMonth().controls[i]?.get('monthly_renum')?.dirty || submitted) &&  otherMonth().controls[i]?.get('monthly_renum')?.errors}" (keyup)="monthlyValidator(i,'other_benefits')">

                                                                <ng-container *ngIf="other_benefits[i].fbp_amount>0">
                                                                  <ng-template #popContent>
                                                                    <div class="d-flex flex-column flex-center" >
                                                                        <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                                        <span class="fs-12">
                                                                            <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                            <span class="fw-500"> {{other_benefits[i].fbp_amount | currency: empSalaryData.currency}}</span>
                                                                        </span>
                                                                    </div>
                                                                    </ng-template>
                                                                  <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                                                                </ng-container>
                                                            </div>
                                                                <div *ngIf="(submitted || otherMonth().controls[i]?.get('monthly_renum')?.touched) || otherMonth().controls[i]?.get('monthly_renum')?.dirty || submitted&& otherMonth().controls[i]?.get('monthly_renum')?.errors" class="invalid-feedback" >
                                                                  <div *ngIf="otherMonth().controls[i]?.get('monthly_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                                  <div *ngIf="otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                                  <div *ngIf="(!(otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  otherMonth().controls[i]?.get('monthly_renum')?.errors?.max)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                                  <div *ngIf="(!(otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.max) && otherMonth().controls[i]?.get('monthly_renum')?.errors?.max)">{{this.messageService.monthlyAmountValidation()}}</div>
                                                                  <div *ngIf="(!(otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.max) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.max) && otherMonth().controls[i]?.get('monthly_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                                </div>

                                                            </ng-container>
                                                            <ng-template #invalid_section>
                                                              <span class=" ms-8" *ngIf="other_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                                {{other_benefits[i]?.monthly_renum  | currency: empSalaryData.currency}}
                                                            </ng-template>

                                                        </td>
                                                        <td  style="max-width: 12.5rem;">
                                                          <ng-container *ngIf="other_benefits[i]?.error==false;else invalid_section_year">
                                                            <div class="form-icon" [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('year_renum')?.touched || otherMonth().controls[i]?.get('year_renum')?.dirty || submitted) &&  otherMonth().controls[i]?.get('year_renum')?.errors}">
                                                            <input type="text"class="form-control" formControlName="year_renum" [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('year_renum')?.touched || otherMonth().controls[i]?.get('year_renum')?.dirty || submitted) &&  otherMonth().controls[i]?.get('year_renum')?.errors}" (keyup)="yearlyValidator(i,'other_benefits')">

                                                            <!-- <ng-container *ngIf="other_benefits[i].fbp_amount>0">
                                                              <ng-template #popContent>
                                                                <div class="d-flex flex-column flex-center" >
                                                                    <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                                    <span class="fs-12">
                                                                        <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                        <span class="fw-500"> {{other_benefits[i].fbp_amount | currency: empSalaryData.currency}}</span>
                                                                    </span>
                                                                </div>
                                                                </ng-template>
                                                              <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                                                            </ng-container> -->
                                                        </div>
                                                            <div *ngIf="(submitted || otherMonth().controls[i]?.get('year_renum')?.touched) || otherMonth().controls[i]?.get('year_renum')?.dirty || submitted&& otherMonth().controls[i]?.get('year_renum')?.errors" class="invalid-feedback" >
                                                              <div *ngIf="otherMonth().controls[i]?.get('year_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                              <div *ngIf="otherMonth().controls[i]?.get('year_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                              <div *ngIf="(!(otherMonth().controls[i]?.get('year_renum')?.errors?.pattern) &&  otherMonth().controls[i]?.get('year_renum')?.errors?.max)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                              <div *ngIf="(!(otherMonth().controls[i]?.get('year_renum')?.errors?.pattern) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.max) && otherMonth().controls[i]?.get('year_renum')?.errors?.max)">{{this.messageService.monthlyAmountValidation()}}</div>
                                                              <div *ngIf="(!(otherMonth().controls[i]?.get('year_renum')?.errors?.pattern) &&  !(otherMonth().controls[i]?.get('year_renum')?.errors?.max) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.max) && otherMonth().controls[i]?.get('year_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                            </div>

                                                        </ng-container>
                                                        <ng-template #invalid_section_year>
                                                          <span class=" ms-8" *ngIf="other_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                            {{other_benefits[i]?.year_renum  | currency: empSalaryData.currency}}
                                                        </ng-template>
                                                        </td>
                                                        <td class="form-cell">
                                                            <ng-select #ngSelecter style="width: 8.75rem;" appendTo="body"  placeholder="{{this.messageService.selectplaceholddisplay('Month')}}" formControlName="other_month" [items]="moduleDropdown"[multiple]="true" bindLabel="value" [closeOnSelect]="false" bindValue="id" class="multiselect " *ngIf="other_benefits[i]?.nature==false" #other [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('other_month')?.touched || submitted) &&  otherMonth().controls[i]?.get('other_month')?.errors}" (change)="CalculateMonthlyPay(i,'other_month')">
                                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                                    <div class="ng-value mx-1" *ngIf="otherMonth().controls[i].value.other_month.length ">
                                                                        <span class="ng-value-label mx-1">{{otherMonth().controls[i].value.other_month.length}} Months selected</span>
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                                {{ item.value }}
                                                                </ng-template>
                                                            </ng-select>
                                                            <div *ngIf="(submitted || otherMonth().controls[i]?.get('other_month')?.touched) && otherMonth().controls[i]?.get('other_month')?.errors" class="invalid-feedback">
                                                              <div *ngIf="otherMonth().controls[i]?.get('other_month')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                                              </div>
                                                            </div>
                                                            <ul class="selected-values mt-2">
                                                                <li class="ng-value" *ngFor="let item of otherMonth().controls[i].value.other_month, let m =index">
                                                                    <ng-container *ngFor="let insideitem of moduleDropdown, let k =index">
                                                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                                        {{ (insideitem['value']?.length>15)? (insideitem['value'] | slice:0:15)+'..':(insideitem['value'])  }}</span>
                                                                    </ng-container>
                                                                    <span class="ng-value-icon right" aria-hidden="true" (click)="RemoveData(m,'other_month',i,'')"><i class="icon-close fs-16"></i></span>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                  </ng-container>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12" *ngIf="contributions?.length>0">
                                  <div class="fw-500">Contribution/Retirals</div>
                              </div>
                              <div class="col-12" *ngIf="contributions?.length>0">
                                  <div class="card card-c2 ">
                                      <div class="table-responsive">
                                          <table class="table table-striped  table-sm ">
                                              <thead>
                                                  <tr>
                                                      <th scope="col">Component name</th>

                                                      <th scope="col">Monthly remuneration</th>
                                                      <th scope="col">Yearly remuneration</th>

                                                  </tr>
                                              </thead>
                                              <tbody >
                                                  <ng-container *ngFor="let contribution of contributions">

                                                    <tr class="{{contribution?.error==true?'td-alert':''}}">

                                                        <td>{{contribution?.name}}</td>
                                                        <td>
                                                            <span class=" ms-8" *ngIf="contribution?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                            {{contribution?.monthly_renum | currency: empSalaryData.currency}}</td>
                                                        <td><span class=" ms-8" *ngIf="contribution?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                            {{contribution?.year_renum | currency: empSalaryData.currency}}</td>
                                                    </tr>
                                                  </ng-container>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12" *ngIf="recurring_deductions?.length>0">
                                  <div class="fw-500">Recurring deductions</div>
                              </div>
                              <div class="col-12" *ngIf="recurring_deductions?.length>0">
                                  <div class="card card-c2 ">
                                      <div class="table-responsive">
                                          <table class="table table-striped form-table table-sm " formArrayName="recurring_month">
                                              <thead>
                                                  <tr>
                                                      <th scope="col">Component name</th>
                                                      <th scope="col">Nature</th>
                                                      <th scope="col">Formula</th>
                                                      <th scope="col">Monthly Pay</th>
                                                      <th scope="col">Yearly Pay</th>
                                                      <th scope="col">Months</th>
                                                  </tr>
                                              </thead>

                                              <tbody >
                                                  <ng-container  *ngFor="let recurring_month of recurringMonth().controls; let i=index; " [formGroupName]="i">

                                                    <tr class="{{recurring_deductions[i]?.error==true?'td-alert':''}}">

                                                        <td>{{recurring_deductions[i]?.name}} </td>
                                                        <td>{{recurring_deductions[i]?.nature==true?'Fixed':recurring_deductions[i]?.nature==false?'Variable':''}}</td>
                                                        <td>{{recurring_deductions[i]?.formula}}</td>
                                                        <td>
                                                            <span class=" ms-8" *ngIf="recurring_deductions[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                            <ng-container *ngIf="recurring_deductions[i]?.contribution==null && empSalaryData?.processed==false" >
                                                                <div  [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('monthly_renum')?.touched || recurringMonth().controls[i]?.get('monthly_renum')?.dirty) &&  recurringMonth().controls[i]?.get('monthly_renum')?.errors}">
                                                                    <input  type="text" class="form-control" formControlName="monthly_renum"
                                                                    [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('monthly_renum')?.touched || recurringMonth().controls[i]?.get('monthly_renum')?.dirty) &&  recurringMonth().controls[i]?.get('monthly_renum')?.errors}"  (keyup)="monthlyRDValidator(i)">                                                           
                                                                </div>
                                                                <div *ngIf="(recurringMonth().controls[i]?.get('monthly_renum')?.touched || submitted || recurringMonth().controls[i]?.get('monthly_renum')?.dirty) && recurringMonth().controls[i]?.get('monthly_renum')?.errors" class="invalid-feedback">
                                                                      <div *ngIf="recurringMonth().controls[i]?.get('monthly_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                                      <div *ngIf="recurringMonth().controls[i]?.get('monthly_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                                      <div *ngIf="(!(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  recurringMonth().controls[i]?.get('monthly_renum')?.errors?.max)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                                      <div *ngIf="(!(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) && !(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.required) && !(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.max) && recurringMonth().controls[i]?.get('monthly_renum')?.errors?.max)">{{this.messageService.deductionAmountValidation()}}</div>
                                                                </div>
                                                              </ng-container>
                                                              <ng-container *ngIf="recurring_deductions[i]?.contribution!=null" >
                                                            {{recurring_deductions[i]?.monthly_renum | currency: empSalaryData.currency}}
                                                        </ng-container></td>
                                                        <td>
                                                            <span class=" ms-8" *ngIf="recurring_deductions[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                            <ng-container *ngIf="recurring_deductions[i]?.contribution==null " >
                                                                <div  [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('year_renum')?.touched || recurringMonth().controls[i]?.get('year_renum')?.dirty) &&  recurringMonth().controls[i]?.get('year_renum')?.errors}">
                                                                    <input  type="text" class="form-control" formControlName="year_renum"
                                                                    [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('year_renum')?.touched || recurringMonth().controls[i]?.get('year_renum')?.dirty) &&  recurringMonth().controls[i]?.get('year_renum')?.errors}"  (keyup)="yearlyRDValidator(i)">                                                           
                                                                </div>
                                                                <div *ngIf="(recurringMonth().controls[i]?.get('year_renum')?.touched || submitted || recurringMonth().controls[i]?.get('year_renum')?.dirty) && recurringMonth().controls[i]?.get('year_renum')?.errors" class="invalid-feedback">
                                                                      <div *ngIf="recurringMonth().controls[i]?.get('year_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                                      <div *ngIf="recurringMonth().controls[i]?.get('year_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                                      <div *ngIf="(!(recurringMonth().controls[i]?.get('year_renum')?.errors?.pattern) &&  recurringMonth().controls[i]?.get('year_renum')?.errors?.max)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                                      <div *ngIf="(!(recurringMonth().controls[i]?.get('year_renum')?.errors?.pattern) && !(recurringMonth().controls[i]?.get('year_renum')?.errors?.required) && !(recurringMonth().controls[i]?.get('year_renum')?.errors?.max) && recurringMonth().controls[i]?.get('year_renum')?.errors?.max)">{{this.messageService.deductionAmountValidation()}}</div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="recurring_deductions[i]?.contribution!=null " >
                                                            {{recurring_deductions[i]?.year_renum | currency: empSalaryData.currency}}
                                                            </ng-container>
                                                        </td>
                                                        <td class="form-cell">

                                                            <ng-select  style="width: 8.75rem;" appendTo="body" placeholder="{{this.messageService.selectplaceholddisplay('Month')}}" formControlName="recurring_month" [items]="moduleDropdown" [multiple]="true" bindLabel="value" [closeOnSelect]="false" bindValue="id" class="multiselect" *ngIf="recurring_deductions[i]?.nature==false" #recurring [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('recurring_month')?.touched) &&  recurringMonth().controls[i]?.get('recurring_month')?.errors}" (change)="CalculateMonthlyPay(i,'recurring_month')">
                                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                                    <div class="ng-value mx-1" *ngIf="recurringMonth().controls[i].value.recurring_month.length ">
                                                                        <span class="ng-value-label mx-1">{{recurringMonth().controls[i].value.recurring_month.length}} Months selected</span>
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                                {{ item.value }}
                                                                </ng-template>
                                                            </ng-select>
                                                            <ul class="selected-values mt-2">

                                                                <li class="ng-value" *ngFor="let item of recurringMonth().controls[i].value.recurring_month, let m =index">
                                                                    <ng-container *ngFor="let insideitem of moduleDropdown, let k =index">
                                                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                                        {{ (insideitem['value']?.length>15)? (insideitem['value'] | slice:0:15)+'..':(insideitem['value'])  }}</span>
                                                                    </ng-container>
                                                                    <span class="ng-value-icon right" aria-hidden="true" (click)="RemoveData(m,'recurring_month',i,'')"><i class="icon-close fs-16"></i></span>
                                                                </li>
                                                            </ul>
                                                            <div *ngIf="(recurringMonth().controls[i]?.get('recurring_month')?.touched || submitted) && recurringMonth().controls[i]?.get('recurring_month')?.errors" class="invalid-feedback">
                                                              <div *ngIf="recurringMonth().controls[i]?.get('recurring_month')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                                              </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                  </ng-container>

                                              </tbody>

                                          </table>
                                      </div>
                                  </div>
                              </div>


                              <ng-container *ngIf="calculatedAmount.length>0">

                                <div class="col-12 d-flex fw-500"> <span class="">Gross pay</span><span class="ms-auto">{{calculatedAmount[0] | currency: empSalaryData.currency}}</span> </div>
                                <div class="col-12 d-flex fw-500"> <span class="">Net take home (excluding TDS)</span><span class="ms-auto">{{calculatedAmount[1] | currency: empSalaryData.currency}}</span> </div>
                              </ng-container>
                              <!-- <div class="col-12">
                                  <div class="fw-500">Gross</div>
                              </div>
                              <div class="col-12 form-row">
                                  <label for="" class="form-label required">Net take home (excluding TDS) </label>
                                  <input  type="text" autocomplete="off" formControlName="gross_amount" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('Net take home')}}"
                                      class="form-control">
                              </div> -->
                            </ng-container>
                            <div class="col-12" *ngIf="variableComponent!=''">
                              <div  role="alert" class="alert alert-info mb-0 show">
                                  <p class="mb-0 "><span class="fs-14rem fw-500">There are components that are not part of CTC but added in salary structure which are  </span><span class="fw-600 text-accent2">{{ variableComponent}}</span> </p>
                                  <!-- <div class="btn-close" ></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer" *ngIf="!componentLoader">
        <button class="btn btn-primary btn-sm ms-auto" (click)="Submit()"  [disabled]="completed || formGp.invalid || isRecalculated">Save</button>
    </footer>
