<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row ">
      <p class="text-uppercase fw-500"></p>
    </div>
    <app-table-loader *ngIf="loader1"></app-table-loader>
    <div class="row row-16">
      <div class="col-lg-9 mx-auto">
        <pdf-viewer [src]="pdfURL" [render-text]="false" [original-size]="false" (on-progress)="showGif($event)" style="height: calc(100vh - 13.5rem) !important;">
        </pdf-viewer>
        <!-- <markdown class="variable-binding" [data]="pdfURL"></markdown> -->
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="submitted || pdfURL == ''" (click)="validateLetterTemplate()">SAVE</button>
  </footer>
</form>
