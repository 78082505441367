<div class="container-fluid p-24">
	<div class="row row-8 mb-24" *ngIf="from != 'myteam'">
		<div class="col-md-4 col-lg-6">
			<h3>Organization Hierarchy</h3>
			<p class="text-helper mb-md-0">Organizational hierarchy gives a quick overview of an employee’s reporting
				structure.</p>
		</div>
		<div class="col-md-8 col-lg-6">
			<div class="d-flex justify-content-end  gap-16">
				<div style="width:22.6875rem;">
					<app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey"
						(searchEmitter)="searchresults($event)" (closesearchEmitter)="onCleared($event)"></app-searchbar>
					<!-- [isLoading]="sLoading" (keywordsearchEmitter)="searchemp($event)" -->
				</div>
			</div>
		</div>
	</div>
	<div class="row row-24   ">

		<div class="col-12 d-flex {{dnonecls}}" >

			<button class="btn btn-white" (click)="expandallfn();" type="button" *ngIf="expandtn">
				<i class="icon-arrows-fullscreen fs-12 me-8"></i> Expand All</button>
			<button class="btn btn-white" (click)="collapsefn();" type="button" *ngIf="expandtn==false">
				<!-- <img class=" me-8" src="/assets/icons/svg/ico-arrows-fullscreen-exit.svg" alt="" srcset=""> -->
				<i class="icon-arrows-minimize fs-12 me-8"></i>
				Collapse All</button>
				<!-- <button id="demo">helo</button> -->
			<div class="btn-group ms-auto" role="group" aria-label="Basic example">

				<button id="zoomOut" type="button" class="btn btn-white"><i
						class="bi text-dark-500 bi-dash"></i></button>
				<button  id="fittoscreen" type="button" class="btn btn-white" >Fit to screen
				</button>
				<button id="zoomIn" type="button" class="btn btn-white"><i
						class="bi text-dark-500 bi-plus"></i></button>
			</div>
		</div>

    <div *ngIf="nodata;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg56.png'" [noDataText]="'Hierarchy Details Not Available Yet'"
        [noDataPara]="'Explore your reporting structure here once it’s set up.'" [hasPermission]="false"> </app-nodata-view>
    </div>

		<!-- <div class="wave-loader" *ngIf="loader" style="min-height:calc(100vh - 17rem) ;">
			<div class="wave" *ngFor="let i of [1,2,3,4,5,6]"></div>
		</div> -->

    <!-- Loader updations -->
    <app-edit-loader *ngIf="loader"></app-edit-loader>

    <ng-template #all_data>

		<ng-container *ngIf="loader==false">
			<div class="col-12">
				<organization-chart id="organization-chart" [groupScale]="1" chartClass="custom-chart"
					containerClass="custom-orgchart-container" [datasource]="orghierarchydata"
					[nodeTemplate]="myNodeTemplate" [pan]="true" [zoom]="true">
				</organization-chart>


				<ng-template #myNodeTemplate let-nodeData="datasource">
					<!-- <div class="card card-c2" (click)="selectNode(nodeData)">
				<div class="position">{{nodeData.title}}</div>
				<div class="fullname">{{nodeData.name}}</div>
				<div class="card card-c2">helo</div>
			</div> -->
					<div class="org-card {{nodeData.color_code}} id{{nodeData?.id}} {{activecl}} {{nodeData.child != ''?'haschild':''}}"
						id="id{{nodeData?.id}}" name="displaycls" >
						<img class="org-avatar"
							*ngIf="(nodeData?.profile_image !='') && (nodeData?.profile_image != null)"
							src="{{nodeData.profile_image}}" alt="">
						<div class="org-avatar avatar-circle fs-14 fw-500 sq-40 {{nodeData?.color_code}} avatar-border"
							*ngIf="(nodeData?.profile_image =='') || (nodeData?.profile_image == null)">
							{{this.appservice.getFirstChar(nodeData?.employee_name,2)}}</div>
						<div class="org-detail">
							<span class="org-title">{{nodeData.employee_name}}</span>
							<span class="org-desc">{{nodeData.designation}}</span>
							<span class="org-desc">EMP CODE : {{nodeData.employee_code}}</span>

						</div>
						<div class="org-user-count" *ngIf="nodeData.child != ''" (click)="expandnode(nodeData);">
							<i class="bi bi-person lh-1"></i>
							<span>{{nodeData.child}}</span>
						</div>

					</div>
				</ng-template>
			</div>
		</ng-container>
   </ng-template>
	</div>
</div>
