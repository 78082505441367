<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Confirmation policy</h3>
            <p class="text-helper mb-md-0">Configure the confirmation policy as per your organization's needs.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
              <div class=" " style="width:22.6875rem;">
                <app-searchbar #child [searchlist]='searchlistData' [searchplaceholder]="this.msgServ.searchdisplay('Confirmation Policy')"
                  (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchPolicy($event)" [isLoading]="isLoading"></app-searchbar>
              </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end">
                      <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
                      (click)="statusBtn='All';statusfilter('')">All</button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
                        (click)="statusBtn='Active';statusfilter(true)">Active </button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
                        (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
                    </div>
                </div>
                <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a"
                    routerLink="/confirmation-policy/confirmation-policy-setup"><i class="icon-plus"></i>ADD POLICY</button>
            </div>
        </div>
    </div>
    <div class="row row-16 mb-16" *ngIf="loader">
      <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <div *ngIf="noData && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg28.png'" [noDataText]="'Define Your Confirmation Policy Now'"
        [noDataPara]="'It seems Your confirmation policy is not set up yet. Click \'Add Confirmation Policy\' to manage your company\'s confirmation process'"
        [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
    </div>
    <ng-template #all_data>
      <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let data of policyData;let i = index">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex view-section" (click)="viewSidePanel(data?.id)">
                        <div class="logo-img {{data?.color_code}}">{{this.appService.getFirstChar(data.name,2)}}</div>
                        <div class="overflow-hidden">
                            <h6 class="mb-1 tips">{{data.name}}</h6>
                            <span class="tips-helper">{{data.name}}</span>
                            <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="data.is_active">ACTIVE</span>
                            <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="data.is_active == false">DELETED</span>
                        </div>
                    </div>
                    <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="data.is_active==true && (permissions?.e || permissions?.d)">
                        <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="icon-three-dots-vertical "></i>
                        </button>
                        <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                          <button ngbDropdownItem (click)="editPolicy(data.id)" *ngIf="permissions?.e">
                                <i class="icon-pencil-square me-1"></i> Edit
                            </button>
                            <button class="text-danger" ngbDropdownItem (click)="deleteAlert = true;deleteId = data?.id" *ngIf="permissions?.d">
                                <i class="icon-trash  me-1"></i> Delete
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body flex-column gap-8">
                    <div class="d-flex justify-content-between w-100 gap-8 text-uppercase fs-10">
                        <span class="text-light-400 text-nowrap">APplicable on</span>
                        <span class=" text-trim">{{data?.['confirmation_policy_applicablity'][0]?.applicable}}</span>
                    </div>
                </div>
            </div>
          </div>
          <ng-container *ngIf="infinityLoader && !loader">
            <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
              *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
          </ng-container>
      </div>
    </ng-template>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]=' confirmationPolicyView  === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details </h5>
            <a class="toggle-panel" (click)=" confirmationPolicyView  = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <app-view-loader *ngIf="vLoader"></app-view-loader>
              <ng-container *ngIf="!vLoader">
                  <div class="row row-16">
                    <div class="col-12 d-flex">
                        <p class="text-uppercase fw-500 mb-0">{{vData?.name}}</p>
                        <a class="link-primary fs-14 ms-auto text-nowrap" *ngIf="(permissions?.e && vData?.is_active)" (click)="editPolicy(vData?.id)"><i
                                class="icon-pencil-square me-1"></i>
                            Edit</a>
                    </div>
                    <div class="col-12">
                        <div class="card details-card   px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 align-items-center d-flex">
                                    <p class="title mb-8">POLICY SETTINGS</p>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Policy name</div>
                                    <div class="detail-desc">{{vData?.name}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Policy description</div>
                                    <div class="detail-desc">{{vData?.description == "" ? '-' : vData?.description}}</div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Probation period (in days)</div>
                                    <div class="detail-desc">{{vData?.probation_period}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Do you want to allow the approver to terminate the employee during the confirmation?</div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400"></i>{{vData?.is_terminate == true ? 'Yes' : 'No'}}</div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Do you want to allow the approver to extend the probation period of the employee?</div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400"></i>{{vData?.is_extend == true ? 'Yes' : 'No'}}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="detail-title">Extension period (in days) </div>
                                    <div class="detail-desc">{{vData?.extension_period == null ? '-' : vData?.extension_period}}</div>
                                </div>
                                <div class="col-md-6">
                                  <div class="detail-title">No.of times extension can be done</div>
                                  <div class="detail-desc">{{vData?.max_extension == null ? '-' : vData?.max_extension}}</div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Do you want to allow approver to edit extension period?
                                    </div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400"></i>{{vData?.is_edit_extend == true ? 'Yes' : 'No'}}
                                    </div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Would you like to initiate the letter generation task for the task owner?</div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400"></i>{{vData?.is_send_letter == true ? 'Yes' : 'No'}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="detail-title">Confirmation template</div>
                                    <div class="detail-desc">{{vData?.confirmation_letter?.name == "" ? '-' : vData?.confirmation_letter?.name}}</div>
                                </div>
                                <div class="col-4">
                                    <div class="detail-title">Extension template</div>
                                    <div class="detail-desc">{{vData?.extension_letter?.name == "" ? '-' : vData?.extension_letter?.name}}</div>
                                </div>
                                <div class="col-4">
                                  <div class="detail-title">Termination template</div>
                                  <div class="detail-desc">{{vData?.termination_letter?.name == "" ? '-' : vData?.termination_letter?.name}}</div>
                                </div>
                                <!-- Mentioned in Confluence as Future setup -->
                                <!-- <div class="col-12">
                                    <div class="detail-title">Would you like to take feedback from approver during
                                        confirmation?</div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400">Yes</i>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Feedback</div>
                                    <div class="detail-desc">Type 1</div>
                                </div> -->
                                <div class="col-12">
                                    <div class="detail-title">How many days before the confirmation date should the confirmation request be initiated?</div>
                                    <div class="detail-desc">{{vData?.confirmation_request_days}}</div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="col-12">
                          <div class="card details-card shadow-0 p-16">
                              <div class="row row-16">
                                  <div class="col-12 align-items-center d-flex">
                                      <p class="title mb-8">APPLICABILITY</p>
                                  </div>
                                  <div class=" col-12">
                                      <div class="detail-title">Applicable to</div>
                                      <div class="detail-desc">{{vData?.applicable}}</div>
                                  </div>
                                  <div class=" col-12">
                                      <div class="detail-title">{{vData?.applicable}}(s)</div>
                                      <div class="detail-desc">
                                        <div class="selected-values gap-8 mt-1">
                                          <ng-container *ngIf="vData?.applicable == 'Employee'">
                                            <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                              <div class="ng-value-label" title="{{rights.name}}">
                                                <span class="d-inline-flex align-items-center">
                                                  <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                                    *ngIf="rights.img !=  '' && rights.img !=  null">
                                                  <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                                    *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,2)}}</span>
                                                  <span> {{rights.name}}</span>
                                                </span>
                                              </div>
                                            </li>
                                          </ng-container>
                                          <ng-container *ngIf="vData?.applicable == 'Company'">
                                            <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                              <div class="ng-value-label" title="{{rights.name}}">
                                                <span class="d-inline-flex align-items-center">
                                                  <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                                    *ngIf="rights.img !=  '' && rights.img !=  null">
                                                  <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                                    *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                                  <span> {{rights.name}}</span>
                                                </span>
                                              </div>
                                            </li>
                                          </ng-container>
                                          <ng-container
                                            *ngIf="(vData?.applicable != 'Employee') && (vData?.applicable != 'Company')">
                                            <div class="d-flex flex-wrap mt-8 gap-8">
                                              <ng-container *ngFor="let rights of assignees, let i=index;">
                                                <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                                  slice:0:25)+'..':(rights) }}
                                                </span>
                                              </ng-container>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </ng-container>
            </div>
        </div>
    </div>

  <div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="icon-trash text-danger"></i>
          <h4 class="modal-title">Delete Confirmation Policy?</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.msgServ.Deletemsgdisplay('Confirmation Policy')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase fw-500"
            (click)="deleteAlert = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase fw-500" (click)="deletePolicy(deleteId)"
            [disabled]="deleteClicked">Delete</button>
        </div>
      </div>
    </div>
  </div>
