import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input,ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {  AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-arrear-days',
  templateUrl: './arrear-days.component.html',
  styleUrls: ['./arrear-days.component.scss']
})
export class ArrearDaysComponent implements OnInit {


  constructor(public attendanceReportService:AttendanceReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService,public changeDetectorRef: ChangeDetectorRef) { }

    @Input() companyDD: any;
    @Input() gradeDD: any;
    @Input() BranchDD: any;
    @Input() buDD: any;
    @Input() DepDD: any;
    @Input() DesignationDD: any;
    @Input() checkListElements: any;
    @Input() disabled: any;
    @Input() empstatusDD: any;
    @Input() emptypeDD: any;
    @Input() employeeDropdown: any;
    @Input() callemp : any;
    // Above needs to be cleared
    @Input() arrearDays : any;
    @Input() salFilter: any;
    @Input() viewDetail: any;
    @Input() closeControl: any;
    @Input() empstatArr: any;
    @Input() tempArr: any;
    @Input() submitted: any;
    @Input() Years : any;
    @Input() pageNumber : any
    @Input() lastPage :any
    @Input() ordering : any
    @Input() ouFilterForm: any
    @Input() resDataFilter:any;

    attendListOfMonth:string  = "listOfMonth";
    arrearMonthValue : any
    attendMonthValue :  any
    selectedYear = new Date().getFullYear();
    year_placeholder = 'Select year'
    month_placeholder = 'Select month'

    get f()  { return this.arrearDays.controls; }

    @ViewChild('scrollElement') scrollElement!: ElementRef;

    @Output() filterPanel                   = new EventEmitter();
    @Output() callMonthlyReport             = new EventEmitter();
    @Output() submitDone                    = new EventEmitter();
    @Output() resetFilter                   = new EventEmitter();


  ngOnInit(): void {
  }

  filterFormData(use:any){
    if(this.arrearDays?.controls?.arrear_month?.value == true){
      this.arrearDays.get('arrear_month')?.setValue(this.arrearDays?.controls?.arrear_specific_year?.value+'-'+this.arrearMonthValue)
    }
    else{
      this.arrearDays.get('arrear_month')?.setValue(this.arrearDays?.controls?.arrear_month?.value)
    }

    if(this.arrearDays?.controls?.attendance_month?.value == true){
      this.arrearDays.get('attendance_month')?.setValue(this.arrearDays.controls.attend_specific_year.value+'-'+this.attendMonthValue)
    }
    else{
      this.arrearDays.get('attendance_month')?.setValue(this.arrearDays.controls.attendance_month.value)
    }
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let arrear_month    = this.arrearDays.controls.arrear_month.value==null?'':this.arrearDays.controls.arrear_month.value;
    let attendance_month    = this.arrearDays.controls.attendance_month.value==null?'':this.arrearDays.controls.attendance_month.value;

    if(use == 'filter'){
      return `arrear_month=${arrear_month}&attendance_month=${attendance_month}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'arrear_month':arrear_month,'attendance_month':attendance_month,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

   // Emiting the value of radio to parent
   radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthtype:any,yeartype:any){
    this.changeDetectorRef.detectChanges();
    if(e.target.value == 'on' ){
     this.arrearDays?.get(contrlName)?.setValue(true);
    }
    else{
     this.arrearDays?.get(contrlName)?.setValue(val);
     this.arrearDays?.get(monthtype)?.setValue(null);
     this.arrearDays?.get(yeartype)?.setValue(null);

    }
  }

   //function for get month no from month name
   attendancemonthChangeFn(event:any,controls:any){
    var date = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    if(controls == 'arrear_specific_month'){
      this.arrearMonthValue = String(date.getMonth() + 1).padStart(2, '0');
    }else{
      this.attendMonthValue = String(date.getMonth() + 1).padStart(2, '0');
    }
  }

  applyBtn : boolean = false

  submitarrearDays(){
    this.submitted      = true;
    if (this.arrearDays.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 :this.attendanceReportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail           = false;
    this.applyBtn = false
  }


  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
      let allSelect = (items: any[]) => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
  }

    resetFilterFunction(){
      if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.resetFilter.emit(true)
        }else{
          this.resetFilterControllers('reset');
        }
      }
      this.setRequired(false)
    }

    resetFilterControllers(data:any){
      this.viewDetail = false;
      this.filterPanel.emit(this.viewDetail)
      if(data == 'reset'){
        this.ouFilterForm.get('company')?.reset('');
        this.ouFilterForm.get('department')?.reset('');
        this.ouFilterForm.get('bu')?.reset('');
        this.ouFilterForm.get('designation')?.reset('');
        this.ouFilterForm.get('grade')?.reset('');
        this.ouFilterForm.get('branch')?.reset('');
        this.ouFilterForm.get('employee_list')?.reset('');
        this.ouFilterForm.get('employment_status')?.reset('');
        this.ouFilterForm.get('employee_type')?.reset('');
        this.arrearDays.get('arrear_month')?.reset('this_month');
        this.arrearDays.get('arrear_specific_month')?.reset(null);
        this.arrearDays.get('arrear_specific_year')?.reset(null);
        this.arrearDays.get('attendance_month')?.reset('this_month');
        this.arrearDays.get('attend_specific_month')?.reset(null);
        this.arrearDays.get('attend_specific_year')?.reset(null);
      }
      this.callMonthlyReport.emit({
        reportName : 'Arrear Days'
      })
    }

  // Tags closing
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }
    if(this.closeControl == 'attendance_month'){
      this.arrearDays.get(this.closeControl)?.setValue('');
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('');
    }
    this.submitarrearDays();
  }


  setRequired(val:any){
    var arr = ['arrear_specific_year','arrear_specific_month']
    if(val){
      arr.forEach((item:any)=>{
        this.arrearDays.get(item)?.setValidators([Validators.required]);
        this.arrearDays.get(item)?.updateValueAndValidity();
      })
    }else{
      arr.forEach((item:any)=>{
        this.arrearDays.get(item)?.clearValidators()
        this.arrearDays.get(item)?.updateValueAndValidity();
      })
    }

  }

    setAttRequired(val:any){
      var arr = ['attend_specific_year','attend_specific_month']
      if(val){
        arr.forEach((item:any)=>{
          this.arrearDays.get(item)?.setValidators([Validators.required]);
          this.arrearDays.get(item)?.updateValueAndValidity();
        })
      }else{
        arr.forEach((item:any)=>{
          this.arrearDays.get(item)?.clearValidators()
          this.arrearDays.get(item)?.updateValueAndValidity();
        })
      }

    }

    resetPage(){
      this.applyBtn = true
      this.attendanceReportService.calculateOffset(0)
      this.changeDetectorRef.detectChanges()
    }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'arrear_month'  && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
