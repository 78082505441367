import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  FILTERDATA:any;
  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) {this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      if(!event.url.includes('assign-geo-location')){
        this.setFilterData(null)
      }
    }
  }) }

  getlocationList(status:any,limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}geolocation/api/v1/locations-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  getOnelocation(id:number){
    return this.http.get(`${this.API_PATH}geolocation/api/v1/locations-detail/${id}/`, this.httpOption)
  }

  createlocation(body:any){
    return this.http.post(`${this.API_PATH}geolocation/api/v1/locations-list/`,body, this.httpOption)
  }

  locationDelete(id: number, body:any ){
    return this.http.patch(`${this.API_PATH}geolocation/api/v1/locations-detail/${id}/`,body , this.httpOption)
  }

  locationupdate(id: number, body:any){
    return this.http.put(`${this.API_PATH}geolocation/api/v1/locations-detail/${id}/`,body, this.httpOption)
  }

  // mappedempdelete(id:number){
  //   return this.http.post(`${this.API_PATH}geolocation/api/v1/delete-employee/${id}/`, this.httpOption)
  // }

  locationListData(status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/geofencing-location-dropdown-list/?is_active=${status}&ordering=location_name`, this.httpOption)
  }

  uploadImportData(body:any){
    return this.http.post(`${this.API_PATH}geolocation/api/v1/upload-locations/`,body, this.httpOption)
  }

  locationListData2(id:any,search:any){
    return this.http.get(`${this.API_PATH}geolocation/api/v1/geolocation-employee-search/${id}/?search=${search}`, this.httpOption)
  }
  hrLocationDropdown(){
    return this.http.get(`${this.API_PATH}geolocation/api/v1/locations-list/?is_active=True`, this.httpOption)
  }
  getEmployeeAssignedLocation(empid:any,from:any){
    if(from=='hr')
      return this.http.get(`${this.API_PATH}geolocation/api/v1/hr-locations-assigned-detail/${empid}/`, this.httpOption)
    else if(from == 'myteam')
      return this.http.get(`${this.API_PATH}geolocation/api/v1/team-locations-assigned-detail/${empid}/`, this.httpOption)
    else
      return this.http.get(`${this.API_PATH}geolocation/api/v1/profile-locations-assigned-detail/${empid}/`, this.httpOption)
  }
  assignEmployeeLocation(body:any){
    return this.http.post(`${this.API_PATH}geolocation/api/v1/locations-assigned-profile-create/`,body, this.httpOption)

  }
  deleteAssignedLocation(id:any){
    return this.http.post(`${this.API_PATH}geolocation/api/v1/delete-employee/${id}/`, this.httpOption)
  }

  getlocationassignList(status:any,limit:any,offset:any,search:any,filter:any){
    if(filter != '' && filter != undefined){
      return this.http.get(`${this.API_PATH}geolocation/api/v1/employee-list/?limit=${limit}&offset=${offset}&search=${search}&${filter}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}geolocation/api/v1/employee-list/?limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
    }
  }

  employeeListData(status:any,filter:any){
    if(filter != '' && filter != undefined)
      { return this.http.get(`${this.API_PATH}geolocation/api/v1/employee-list-dropdown/?is_active=${status}&${filter}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}geolocation/api/v1/employee-list-dropdown/?is_active=${status}&ordering=user__first_name`, this.httpOption)
    }
  }

  getemplocation(id:number){
    return this.http.get(`${this.API_PATH}geolocation/api/v1/hr-locations-assigned-detail/${id}/`, this.httpOption)
  }

  EmpassignCreate(body:any){
    return this.http.post(`${this.API_PATH}geolocation/api/v1/locations-assigned-list/`,body, this.httpOption)
  }

  Empassignupdate(id: number, body:any){
    return this.http.put(`${this.API_PATH}geolocation/api/v1/locations-assigned-detail/${id}/`,body, this.httpOption)
  }

  uploadempImportData(body:any){
    return this.http.post(`${this.API_PATH}geolocation/api/v1/upload-locations-assigned/`,body, this.httpOption)
  }
  deleteAllEmpLocation(id:any){
    return this.http.post(`${this.API_PATH}geolocation/api/v1/delete-employee-all/${id}/`, this.httpOption)
  }
  setFilterData(val:any){
    this.FILTERDATA = val
  }

}

