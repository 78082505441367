<form action="" class=""  [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">WEEK OFF</p>
        </div>
        <div class="row row-16">
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label required">How do you want to define weekoff for this shift </label>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input formControlName="week_off_define"  class="form-check-input"  type="radio" [value]=false (click)="policybasedshift()">
                        As per organization policy
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select this option, if week offs are common for all employees." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                      <input formControlName="week_off_define"  class="form-check-input"  type="radio" [value]=true>
                        Shift based
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select this option, if  employees have different days week offs specific to the shift." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                </div>
            </div>
            <div class="col-12" *ngIf="f.week_off_define.value">
              <app-week-off-table [formGp]="formGp"></app-week-off-table>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid|| saveBtnClicked" (click)="validateForm()">Save</button>
    </footer>
</form>
