<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row row-16">
    <div class="col " style="max-width: 20.625rem;">
      <div class="card card-c2 p-0 sticky-profile-card">

        <div class="card-body px-0 py-24 overflow-auto" style="max-height: calc(100vh - 8.6rem);">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class=" vetical-tab tab-scroll">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="tab-link ">Snapshot</a>
              <ng-template ngbNavContent>

                <app-field-permission-table [section]="'snapshot'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="tab-link">Professionals</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'professional'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink class="tab-link">Education</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'education'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink class="tab-link">Personal</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'personal'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="5">
              <a ngbNavLink class="tab-link">Family</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'family'"
                  [permissions]="permissions"></app-field-permission-table>
              </ng-template>
            </li>
            <li [ngbNavItem]="6">
              <a ngbNavLink class="tab-link">Health Insurance</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'health_insurance'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="7">
              <a ngbNavLink class="tab-link">Previous Employer</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'previous_employer'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="8">
              <a ngbNavLink class="tab-link">Bank Details</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'bank_detail'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="9">
              <a ngbNavLink class="tab-link">Documents</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'documents'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
            <li [ngbNavItem]="10">
              <a ngbNavLink class="tab-link">Geo Locations</a>
              <ng-template ngbNavContent>
                <app-field-permission-table [section]="'geo_locations'"
                  [permissions]="permissions"></app-field-permission-table>

              </ng-template>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div class="col ">
      <div [ngbNavOutlet]="nav"></div>
    </div>
    <!-- <div class="col-12">
            <div class="card card-c2">
                <div class="card card-c2 overflow-hidden">
                    <div class="table-responsive">
                        <table class="table table-striped td-10 th-10 table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Profile tabs</th>
                                    <th scope="col">VISIBLE for employee</th>
                                    <th scope="col">visible for manager</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Snapshot </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Professional </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox">
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Education </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Personal </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Family </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Health Insurance </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Previous Employer </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Bank Details </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Documents </td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                    <td class="form-cell">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</div>