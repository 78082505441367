import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit {
  activeTab = 1;
  viewDetail = false;
  constructor() { }

  ngOnInit(): void {
  }

}
