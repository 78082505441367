
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='uploadeAttachments === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Attachment</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16 ">
                    <div class="col-12">
                        <p class="text-uppercase fw-500 mb-0">UPLOAD DOCUMENTS</p>
                    </div>

                    <div class="col-12">
                        <label class="form-label  ">Add attachments </label>
                        <div class="card card-c2 px-16">
                            <div class="card-body px-0 py-16">
                                <label class="upload-btn " [ngClass]="{'is-invalid': (fileError == true)}">
                                    <div class="upload-text">Upload documents <span class="text-primary">here
                                      <app-file-upload [crop]="false" [round]="false" [id]="'document'" [width]="504"
                                (dataURL)="getURL($event)" (fullfile)="getFilename($event)" (loader)="getloader($event)"
                                [FileType]="'.png,.jpeg,.jpg,.docx,.doc,.dotx,.pdf'" (fileformat)="getfileformat($event)"
                                (validsize)="getvalidsize($event)"></app-file-upload>
                                        </span>
                                        <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC, IMG</div>
                                    </div>
                                    <input type="file" class="form-control" placeholder="" hidden>
                                </label>
                                <div *ngIf="(fileError == true)" class="invalid-feedback">
                                  <div>{{fileErrorMsg}}</div>
                              </div>
                            </div>
                            <div *ngIf="uploadedFile?.length > 0" class="card-body px-0 py-16">
                                <div class="row row-16">
                                    <div class="col-md-6" *ngFor="let item of uploadedFile let i = index">

                                      <div class="card card-c2  bg-secondary">
                                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                          <ng-container *ngIf="uploadedFile[i].url!=''">
                                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(uploadedFile[i].url)}}</span>
                                            <span class="tips-helper ">{{this.appservice.getDocName(uploadedFile[i].url)}}</span>
                                            <div class="d-flex gap-8">
                                              <div class="link-primary" (click)="viewDocument(uploadedFile[i].url)">
                                                <i class="bi bi-eye me-1"></i>View
                                              </div>
                                              <div class="link-primary" (click)="this.appservice.downloadFile(uploadedFile[i].url,this.appservice.getDocName(uploadedFile[i].url))">
                                                <i class="bi bi-download me-1"></i>Download
                                              </div>
                                            </div>
                                          </div>
                                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteAttachment(i)"></i>
                                         </ng-container>
                                         <div class="  flex-center text-center"  *ngIf="uploadedFile[i].loader">
                                          <div class="fs-12 text-muted">Loading </div>
                                          <div class="ms-4 dot-pulse"></div>

                                         </div>
                                        </div>
                                      </div>
                                        <!-- <div class="card card-c2 bg-secondary">
                                            <div class="d-flex p-24">
                                                <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                                <ng-container *ngIf="uploadedFile[i].url!=''">
                                                <span class="text-trim fw-500">{{this.appservice.getDocName(uploadedFile[i].url)}}</span>
                                                <span class="ms-auto text-accent1 ">{{uploadedFile[i].size}}</span>
                                                <span class="link-danger fw-500 flex-shrink-0"><i
                                                        class="icon-trash ms-8 " (click)="deleteAttachment(i)"></i></span>
                                                </ng-container>
                                                <div class="flex-center text-center" *ngIf="uploadedFile[i].loader">
                                                  <div class="fs-12 text-muted">Loading </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="uploadedFile?.length == 0"  (click)="saveAttachments()">
                Save
            </button>
        </footer>
    </div>
</div>

<!-- un supported view format alert -->
<app-info-popup *ngIf="alertToggle" (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
