import { Component, OnInit,Input } from '@angular/core';
import { EmployeeDirectoryService } from 'src/app/@core/services/employee-directory.service';

@Component({
  selector: 'app-field-permission-table',
  templateUrl: './field-permission-table.component.html',
  styleUrls: ['./field-permission-table.component.scss']
})
export class FieldPermissionTableComponent implements OnInit {

  @Input() section:any
  @Input() permissions:any
  sectionData:any
  loader  = false;
  constructor(
    public edService:EmployeeDirectoryService
  ) { }

  ngOnInit(): void {
    this.getFieldpermision();
  }
  getFieldpermision(){
    this.loader= true
    this.edService.getFieldsPermissionList(this.section).subscribe((res:any)=>{
     
      this.sectionData  = res;
      this.loader       = false
    })
  }
  savePermission(index:any){
   let data = {
    "directory_visible" : this.sectionData[index].directory_visible,
    "employee_visible"  : this.sectionData[index].employee_visible,
    "manager_visible"   : this.sectionData[index].manager_visible,
    }
    this.edService.updateFieldsPermission(this.sectionData[index].id,data).subscribe((res:any)=>{
      this.getFieldpermision();
    })

  }

}
