import { Component, Input, OnInit, Output,EventEmitter, } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
@Component({
  selector: 'app-shift-timing',
  templateUrl: './shift-timing.component.html',
  styleUrls: ['./shift-timing.component.scss']
})
export class ShiftTimingComponent implements OnInit {
  constructor(
    public messageService : MessageService,public appservice:AppService) { }
  @Input() formGp:any  = [];
  validated : boolean = false;
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Output() submitmethod1        = new EventEmitter();

  @Input()componentError:any;
  @Input()submitted:boolean=false;
  @Input()disabled = false;
  beforeshiftmaxvalue ='04:00:00';
  timeminvalue ='00:01:00';
  shifthours:any;
  diffValidation : boolean =  false
  minhr_shifthr_difference:any;

  ngOnInit(): void {
    if(this.editflag==true){
      this.shifthrs();
      if(this.formGp.controls.before_shift_start_enable.value){
        this.setRequired(true,'before_shift_start_time');
      }
      if(this.formGp.controls.grace_time_late_checkin_enable.value){
        this.setRequired(true,'grace_time_late_checkin_time')
      }
      if(this.formGp.controls.grace_time_early_checkout_enable.value){
        this.setRequired(true,'grace_time_early_checkout_time');
      }
      if(this.formGp.controls.mark_half_day_absent_status.value){
        this.setRequired(true,'mark_half_day_absent')
      }
      if(this.formGp.controls.mark_full_day_absent_status.value){
        this.setRequired(true,'mark_full_day_absent')
      }
    }
  }
  ngAfterViewInit() {
    this.addValidation('shift_start_time','shift_end_time')
  }

  get f() {return  this.formGp.controls; }
  shifthrs(){
    var tim1=moment(this.formGp.controls['shift_start_time']?.value,'HH:mm:ss').format('HH:mm:ss');
    var tim2=moment(this.formGp.controls['shift_end_time']?.value,'HH:mm:ss').format('HH:mm:ss');
    this.shifthours = this.appservice.Timedifference(tim1,tim2);
    if(this.formGp.controls['min_work_hours_for_fullday']?.value!=null){
      this.minworkhr();
    }
  }
  minworkhr(){
    var minhrfull = moment(this.formGp.controls['min_work_hours_for_fullday']?.value,'HH:mm:ss').format('HH:mm:ss');
    if(moment(minhrfull,'HH:mm:ss').isAfter(moment(this.shifthours,'HH:mm:ss'))){
      this.minhr_shifthr_difference = this.appservice.Timedifference(this.shifthours,minhrfull);
    }else{
      this.minhr_shifthr_difference = this.appservice.Timedifference(minhrfull,this.shifthours );
    }
    this.submitmethod1.emit(this.minhr_shifthr_difference);
  }
  validateForm() {
    this.submitted = true;
    this.shifthrs();
    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit(this.minhr_shifthr_difference);
  }
  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }
  setRequired(value: boolean,item:any) {
    if(value) {
      this.formGp.get(item).setValidators([Validators.required])
    } else {
      this.formGp.get(item).clearValidators()
      this.formGp.get(item).setValue(null);
    }
      this.formGp.get(item).updateValueAndValidity();
  }

  addValidation(field1:any,field2:any){
    this.validateFirstHalfSecondHalf()
    this.validateShiftStartEnd()
    this.validateminWorkHrs()
    // this.cdr.detectChanges();
  }

  validateShiftStartEnd(){
    const sStart = this.formGp.controls['shift_start_time']?.value
    const sEnd = this.formGp.controls['shift_end_time']?.value
    if(sEnd != null && sStart!= null){
      if (moment(sStart,'HH:mm:ss').isSame(moment(sEnd,'HH:mm:ss'))) {
        this.formGp.get('shift_end_time')?.setErrors({
          'same': true,
        })
      }else{
        this.formGp.get('shift_end_time')?.setErrors(null)
      }
    }
  }

  validateFirstHalfSecondHalf(){
    const value1 = this.formGp.get('first_half_end')?.value;
    const value2 = this.formGp.get('second_half_start')?.value;
    const sStart = this.formGp.controls['shift_start_time']?.value
    const sEnd = this.formGp.controls['shift_end_time']?.value;
    const halfday =this.formGp.controls['min_work_hours_for_halfday']?.value;
    const fullday = this.formGp.controls['min_work_hours_for_fullday']?.value; 
    const startTimeMoment = moment(sStart, 'HH:mm:ss');
    const halfdayTimeMoment = moment(halfday, 'HH:mm:ss');
    const totalDuration = moment.duration(startTimeMoment.diff(moment().startOf('day'))).add(moment.duration(halfdayTimeMoment.diff(moment().startOf('day'))));
    const formattedTotalDuration = moment.utc(totalDuration.asMilliseconds()).format('HH:mm:ss');
    const endTimeMoment = moment(sEnd, 'HH:mm:ss');
    const fulldayTimeMoment = moment(fullday, 'HH:mm:ss');
    const totalDuration1 = moment.duration(endTimeMoment.diff(moment().startOf('day'))).add(moment.duration(fulldayTimeMoment.diff(moment().startOf('day'))));
    const formattedTotalDuration1 = moment.utc(totalDuration1.asMilliseconds()).format('HH:mm:ss');
    if(sStart!= null && value1 != null && halfday!= null){      
      if (moment(value1,'HH:mm:ss').isBefore(moment(formattedTotalDuration, 'HH:mm:ss'))){
        this.formGp.get('first_half_end')?.setErrors({'beforeminhr': true})
        this.formGp.get('first_half_end')?.markAsTouched();
      }  else {
        this.formGp.get('first_half_end')?.setErrors(null)
        this.formGp.get('first_half_end')?.markAsUntouched();
      }
    }

    if(sEnd != null && sStart!= null && value1 != null && value2!= null){
      if (moment(sStart,'HH:mm:ss').isBefore(moment(sEnd,'HH:mm:ss'))) {
        if (moment(value1,'HH:mm:ss').isBefore(moment(sStart,'HH:mm:ss'))) {
          this.formGp.get('first_half_end')?.setErrors({'beforeShift': true})
          this.formGp.get('first_half_end')?.markAsTouched();
        } else if (moment(value1,'HH:mm:ss').isAfter(moment(sEnd,'HH:mm:ss'))) {
          this.formGp.get('second_half_start')?.setErrors({'afterShift': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        }  else if (moment(value1,'HH:mm:ss').isBefore(moment(formattedTotalDuration, 'HH:mm:ss'))){
          this.formGp.get('first_half_end')?.setErrors({'beforeminhr': true})
          this.formGp.get('first_half_end')?.markAsTouched();
        }  else {
          this.formGp.get('first_half_end')?.setErrors(null)
          this.formGp.get('first_half_end')?.markAsUntouched();
        }
        if (moment(value1,'HH:mm:ss').isAfter(moment(value2,'HH:mm:ss'))) {
          this.formGp.get('second_half_start')?.setErrors({'min': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        } else if (moment(value2,'HH:mm:ss').isAfter(moment(sEnd,'HH:mm:ss'))) {
          this.formGp.get('second_half_start')?.setErrors({'afterShift': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        } else if (moment(value2,'HH:mm:ss').isBefore(moment(sStart,'HH:mm:ss'))) {
          this.formGp.get('second_half_start')?.setErrors({'beforeShift': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        } 
        // else if (moment(value2,'HH:mm:ss').isAfter(moment(formattedTotalDuration1, 'HH:mm:ss'))){
        //   this.formGp.get('second_half_start')?.setErrors({'beforeminhr': true})
        //   this.formGp.get('second_half_start')?.markAsTouched();
        // } 
        else{
          this.formGp.get('second_half_start')?.setErrors(null)
          this.formGp.get('second_half_start')?.markAsUntouched();
        }
      } else {
        if (moment(value1,'HH:mm:ss').isBetween(moment(sEnd,'HH:mm:ss'),moment(sStart,'HH:mm:ss'))) {
          this.formGp.get('first_half_end')?.setErrors({'beforeShift': true})
          this.formGp.get('first_half_end')?.markAsTouched();
        }else {
          this.formGp.get('first_half_end')?.setErrors(null)
          this.formGp.get('first_half_end')?.markAsUntouched();
        }
        if (moment(value2,'HH:mm:ss').isBetween(moment(sEnd,'HH:mm:ss'),moment(sStart,'HH:mm:ss'))) {
          this.formGp.get('second_half_start')?.setErrors({'afterShift': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        } else if (
          (moment(value1,'HH:mm:ss').isAfter(moment(value2,'HH:mm:ss'))) &&
          !moment(value1,'HH:mm:ss').isSame(moment(value2,'HH:mm:ss')) &&
          (moment(value1,'HH:mm:ss').isAfter(moment(sStart,'HH:mm:ss')))&&
          (moment(value2,'HH:mm:ss').isAfter(moment(sEnd,'HH:mm:ss')))
          ) {
          this.formGp.get('second_half_start')?.setErrors({'min': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        }else if (
          (moment(value1,'HH:mm:ss').isAfter(moment(value2,'HH:mm:ss'))) &&
          !moment(value1,'HH:mm:ss').isSame(moment(value2,'HH:mm:ss')) &&
          (moment(value1,'HH:mm:ss').isBefore(moment(sEnd,'HH:mm:ss')))
          ) {
          this.formGp.get('second_half_start')?.setErrors({'min': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        }
        else if (
          (moment(value1,'HH:mm:ss').isBefore(moment(value2,'HH:mm:ss'))) &&
          !moment(value1,'HH:mm:ss').isSame(moment(value2,'HH:mm:ss')) &&
          (moment(value1,'HH:mm:ss').isBefore(moment(sStart,'HH:mm:ss')))&&
          (moment(value2,'HH:mm:ss').isAfter(moment(sStart,'HH:mm:ss')))
          ) {
          this.formGp.get('second_half_start')?.setErrors({'min': true})
          this.formGp.get('second_half_start')?.markAsTouched();
        }
        else {
        this.formGp.get('second_half_start')?.setErrors(null)
        this.formGp.get('second_half_start')?.markAsUntouched();
      }
      }
      // this.formGp.updateValueAndValidity();
    }
  }

  validateminWorkHrs(){
    const value1 = this.formGp.get('min_work_hours_for_halfday')?.value;
    const value2 = this.formGp.get('min_work_hours_for_fullday')?.value;
    this.shifthrs()
    if(value1 != null && value2!= null){
      if (moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
        this.formGp.get('min_work_hours_for_fullday')?.setErrors({'min': true})
        this.formGp.get('min_work_hours_for_fullday')?.markAsTouched();
      }else if (moment(value2,'HH:mm:ss').isAfter(moment( this.shifthours,'HH:mm:ss'))) {
        this.formGp.get('min_work_hours_for_fullday')?.setErrors({'exeeds': true, })
        this.formGp.get('min_work_hours_for_fullday')?.markAsTouched();
      }else{
        this.formGp.get('min_work_hours_for_fullday')?.setErrors(null)
        this.formGp.get('min_work_hours_for_fullday')?.markAsUntouched();
      }
      if (moment(value1,'HH:mm:ss').isAfter(moment( this.shifthours,'HH:mm:ss'))) {
        this.formGp.get('min_work_hours_for_halfday')?.setErrors({'exeeds': true, })
        this.formGp.get('min_work_hours_for_halfday')?.markAsTouched();
      }else{
        this.formGp.get('min_work_hours_for_halfday')?.setErrors(null)
        this.formGp.get('min_work_hours_for_halfday')?.markAsUntouched();
      }
    }
  }

  resetHourDependfield(field:any){
    if(field=='mark_full_day_absent'){
      this.formGp.get('mark_full_day_absent').setValue(null);
    }else{
    this.formGp.get('mark_half_day_absent').setValue(null);
    this.formGp.get('grace_time_late_checkin_time').setValue(null);
    this.formGp.get('grace_time_early_checkout_time').setValue(null);
    }
  }
  keydownfn(){
    return false;
  }
}
