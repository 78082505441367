import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NewpasswordService {
  API_PATH: string = environment.apiPath;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  resetPassword(user: any) {
    return this.http.post<any>(`${this.API_PATH}reset_password/`, user)
  }
}
