<div class="container-fluid p-24">
    <div class="row row-16">
      <div class="col-12 d-flex align-items-start">
          <div>
            <div class="d-flex align-items-center gap-8">
                <p class="text-uppercase fw-500 mb-0">PRIOR PAYROLL</p>
                <span *ngIf="!landingLoader" class="badge  badge-custom {{summaryData?.completeflag?'badge-success':'badge-warning'}} text-uppercase">{{summaryData.completeflag==true?'Completed':'Pending'}}</span>

            </div>
              <p class="text-uppercase fs-10 fw-500 mb-0">{{summaryData?.priordatarange}}</p>
          </div>
          <a *ngIf="summaryData?.edit_flag" class="link-primary fs-14 d-flex align-items-center ms-auto" routerLink="/prior-payroll-setup/{{year}}/{{month}}/{{company}}">
              <i class="icon-pencil-square me-1"></i> Edit</a>
      </div>
      <app-c1-loader *ngIf="landingLoader==true"></app-c1-loader>
      <ng-container *ngIf="!landingLoader">

        <div class="col-12 " *ngIf="landingLoader==false">
          <div class="card card-c2 card-highlight highlight-primary">
            <div class="card-body px-24 py-16 ">
                <div class="d-flex gap-64 flex-wrap">
                    <div class="">
                        <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                            total payroll cost</div>
                        <div class="fs-24 mb-6 lh-1">{{summaryData?.total_gross_salary| currency:summaryData?.currency}}</div>

                    </div>
                    <div class="my-auto">=</div>
                    <div class="">
                        <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                            Employee Net Pay</div>
                        <div class="fs-24 mb-6 lh-1">{{summaryData?.total_net_pay| currency:summaryData?.currency }}</div>

                    </div>
                    <div class="my-auto">+</div>
                    <div class="">
                        <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6"> Total
                            deductions</div>
                        <div class="fs-24 mb-6 lh-1">{{summaryData?.total_total_deductions| currency:summaryData?.currency }}</div>

                    </div>
                    <div class="my-auto">+</div>
                    <div class="">
                        <div class="fs-10 fw-500 text-uppercase text-light-400 mb-1">
                            Total contributions</div>
                        <div class="fs-24 mb-1 lh-1">
                            {{summaryData?.total_employer_contributions| currency:summaryData?.currency}}</div>

                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="col-12">
            <div class="card card-c2">
                <div class="card-body py-0 px-24 d-flex align-items-end">
                    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab1" class="nav radius-4 nav-custom shadow-0 p-0"
                        style="min-height: unset;">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink class="nav-link h-100 py-16">Salary Summary</a>
                            <ng-template ngbNavContent>
                              <ng-container *ngIf="landingLoader==false">
                                <app-prior-summary [issalsumm]="true"
                                    [salarysummaryList]="summaryData?.salary_summary
                                    "
                                    [searchString]="searchString"
                                    [page]="page" [pageSize]="pageSize" [currency]="summaryData?.currency">
                                  </app-prior-summary>
                              </ng-container>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink class="nav-link h-100 py-16">Employee Salary Summary</a>
                            <ng-template ngbNavContent>
                              <ng-container *ngIf="landingLoader==false">

                                <app-prior-summary [isempsalsumm]="true"
                                        [empsalarysummaryList]="summaryData?.employee_salary_summary"
                                        [searchString]="searchString"
                                        [page]="page" [pageSize]="pageSize" [currency]="summaryData?.currency"></app-prior-summary>
                              </ng-container>
                            </ng-template>
                        </li>

                    </nav>
                    <div class="d-flex align-items-center gap-16 ms-auto py-12">
                      <div class="form-icon icon-left">
                          <input type="text" class="form-control fs-14"
                              placeholder="{{this.messageService.searchdisplay('novalue')}}   "
                              name="searchString" [(ngModel)]="searchString" (keyup)="page=1">
                          <i class="icon-search"></i>
                      </div>
                      <button class="btn-square btn btn-secondary" (click)="filter  = true">
                          <i class="icon-filter-left fs-13"></i>
                      </button>
                    </div>
                </div>
                <div class="card-body p-24">
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
      </ng-container>
    </div>
</div>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
(resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
