<ng-container *ngIf="viewMapPanel == true">
    <div
      class="side-panel"
      style="--sidepanel-width: 45rem"
      [class.side-pane-active]="viewMapPanel === true"
    >
      <div class="side-panel-container">
        <header class="side-panel-head">
          <h5>View</h5>
          <a class="toggle-panel" (click)="this.closeEmitter.emit();"
            ><i class="icon-close-lg"></i
          ></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
          <div class="container-fluid px-32 py-24">
            <div class="col-12">
              <div class="mb-0 fw-500">LOCATION MAP</div>
            </div>
            <div class="col-12 mt-2">
              <agm-map  [styles]="styles"
                style="height: 450px"
                [latitude]="request.latitude"
                [longitude]="request.longitude"
                [zoom]="zoom"
              >
              <agm-overlay [latitude]="request.latitude" [longitude]="request.longitude" [markerDraggable]="false" 
               (markerClick)="openWindow()">
                   <div class="avatar-marker">
                       <div *ngIf="profile?.profile_image == '' || profile?.profile_image == null"
                           class="avatar-circle sq-36 fw-500 {{profile?.color_code}}">
                           {{this.appService.getFirstChar(profile?.first_name+" "+profile?.last_name, 2)}}
                       </div>
                       <img *ngIf="(profile?.profile_image != null && profile?.profile_image!='')" class="avatar-circle sq-36"
                           src="{{profile?.profile_image}}" alt="">
                   </div>

                   <agm-info-window  [isOpen]="openedWindow">
                       <div class=" hstack align-items-start gap-16 overflow-hidden">
                           <div *ngIf="profile?.profile_image == '' || profile?.profile_image == null"
                               class="avatar-circle sq-64 fs-18 fw-400 {{profile?.color_code}}">
                               {{this.appService.getFirstChar(profile?.first_name+" "+profile?.last_name, 2)}}
                           </div>
                           <img *ngIf="(profile?.profile_image != null && profile?.profile_image!='')" class="avatar-circle sq-64 "
                               src="{{profile?.profile_image}}" alt="">
                           <div class="vstack gap-5 " style="max-width: 20rem;">
                               <p
                                   class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                   <i class="icon-person1 text-accent1 me-8"></i>
                                   <span class="text-trim">{{profile?.first_name}} {{profile?.last_name}}</span>
                                   <span class="px-1">|</span>
                                   <span class="text-trim">{{profile?.employee_code}}</span>
                               </p>
                               <p
                                   class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                   <i class="bi bi-geo-alt text-accent1 me-8"></i>
                                   {{request?.location}}
                               </p>
                               <p *ngIf="appService.isValidDateTime(request?.punch_date+' '+request?.punch_time)"
                                   class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                   <i class="icon-calendar text-accent1 me-8"></i>
                                   {{request?.punch_date+" "+request?.punch_time !=
                                     ''?appService.datetimeconvert(request?.punch_date+" "+request?.punch_time):'--'}}
                               </p>
                           </div>
                       </div>
                   </agm-info-window>
           </agm-overlay>
              </agm-map>
            </div>
            <div class="col-12 mt-15" *ngIf="request.selfie_image != null && request.selfie_image != ''">
              <div class="card card-c2 p-16 details-card">
                <div class="row row-16">
                  <div class="col-12">
                    <span class="title">
                      Check in CAPTURE
                    </span>
                  </div>                 
                  <div class="col flex-grow-0" >
                    <div class="detail-title">Photo</div>
                    <div class="detail-desc"> <a class="ms-1"  (click)="viewProfileModal = true;viewProfileImage=request.selfie_image"><img class="sq-80 radius-8 object-fit-contain" [src]="request.selfie_image"></a></div>
                  </div>                       
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="modal {{viewProfileModal ? 'show':''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width:30.875rem">
      <div class="modal-content radius-24">
        <div class="modal-header px-24 ">
          <h5 class="modal-title ">Attendance image</h5>
          <button type="button" (click)="viewProfileModal = false" class="btn-close shadow-0" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body pb-32 px-24  mx-auto">
          <div class="row row-16">
            <div class="col-12">
              <img [src]="viewProfileImage" class="img-fluid radius-24 ">
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>