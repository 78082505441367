import { Component, OnInit } from '@angular/core';
import { ChallanService } from 'src/app/@core/services/challan.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss']
})

export class ReturnsComponent implements OnInit {

  tdsList:any              = [];
  loader                   = false;
  nodata                   = false;
  company:any              = "";
  companyList:any          = [];
  permissions:any          = [];
  currentYear              = new Date().getFullYear();
  fiscalyear:any           = "";
  alertToggle              = false;
  // enableBtn                = true;
  // downloadEnable           = false;
  selectedYear:any;

  constructor(
    public challanService:ChallanService,
    public messageService : MessageService,
    public appservice:AppService
  ) { }

  ngOnInit(): void {
    this.selectedYear = this.appservice.getFinancialYear();
    this.getCompanyDropdown();
    this.getPermission();
  }

  // test start
  /********* GET COMPANY DROP DOWN DATA FUNCTION ****************/
  getCompanyDropdown(){
    this.challanService.companyDropDownData()
    .subscribe((res: any) => {
      this.companyList = res;
      if (res.length > 0) {
        // this.companyList = res;
        if(this.company==''){
          this.company =  this.company==''?res[0]['id']:this.company;
        }
        this.getTdsList();
      }
    })
  }

  /********* FILTER CHANGE FUNCTION ****************/
  filterChange(val:any,filterType:any){
    if(filterType == 'company')
      this.company      = Number(val);
      this.getTdsList();
  }

  /********* TDS LIST FUNCTION ****************/
  getTdsList(){
    if(this.company != '' && this.company != null){
      this.loader         = true;
      this.tdsList        = [];
      this.challanService.tdsList(this.company,this.selectedYear)
      .subscribe((res: any) => {
        this.nodata       = res?.length == 0 ? true : false;
        this.tdsList      = res;
        this.loader       = false;

      })
    }
  }

  /********* FILTER CHANGE FUNCTION ****************/
  getCurrentFinancialYear() {
    var today       = new Date();

    if( Number(this.selectedYear) == today.getFullYear()){
      if ((today.getMonth() + 1) <= 3) {
        this.fiscalyear = ( Number(this.selectedYear) - 1) + "-" +  Number(this.selectedYear);
      } else {
        this.fiscalyear =  Number(this.selectedYear) + "-" + ( Number(this.selectedYear) + 1);
      }
    }else{
      this.fiscalyear =  Number(this.selectedYear) + "-" + ( Number(this.selectedYear) + 1);
    }

    return this.fiscalyear;
  }

  /********* YEAR CHANGE FUNCTION ****************/
  yearChange(year:any){
    this.selectedYear = Number(year);
    if(this.companyList?.length > 0)
      this.getTdsList();
    else
      this.nodata = true;
    // this.getCurrentFinancialYear();
    {{this.appservice.getFinancialYearRange(this.selectedYear)}}
  }

  /********* EXCEL DOWNLOAD FUNCTIONs ****************/
  downloadChallan(id:any,period:any){
    this.challanService.tdsDownload(id).subscribe(fileData => {
      this.downloadFile(fileData,period);
    });
  }

  downloadFile(fileData:any,period:any) {
    const blob: any = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      var tempName = "TDS Return"+"_"+this.selectedYear+"_"+period+"_"+Date.now();
      link.setAttribute("download", tempName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  /********* GET PAGE PERMISSION FUNCTION ****************/
  getPermission(){
    this.permissions = this.appservice.getPermissions('/challan/generate-return');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  /********* GENERATE FILE FUNCTION ****************/
  genarateFile(id:any){
    this.alertToggle = false;
    // this.enableBtn        = false;
    this.challanService.tdsGenarate(id).subscribe((res: any) => {
      // this.downloadEnable = true;
      if(res.is_tax_deductor_configured)
        this.getTdsList();
      else
        this.alertToggle = true;
    })
  }

}
