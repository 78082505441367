<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="expenseMainForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-16 mb-16 ">
                  <div class="col-12 form-row">
                      <label for="" class="form-label ">Expense policy</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('expense policy')}}"
                          formControlName="expense_policy" [items]="expensePolicyDropdown" [multiple]="true"
                          groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                          bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="expenseLoading">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!expenseLoading">
                              <div class="ng-value" *ngIf="expenseMainForm.value.expense_policy?.length ">
                                  <span class="ng-value-label">{{expenseMainForm.value.expense_policy?.length }} expense policy selected</span>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline-flex align-items-center">
                                  <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                                      [ngModelOptions]="{ standalone : true }" />

                                  <span class="">{{ (item.name?.length>15)? (item.name |
                                      slice:0:15)+'..':(item.name) }}</span>
                              </div>
                          </ng-template>
                          <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="expenseLoading">
                            <div class="loading-text">Loading...</div>
                          </ng-template>
                      </ng-select>
                      <ul class="selected-values mt-16" *ngIf="expenseMainForm.value.expense_policy?.length != 0 && !expenseLoading">
                          <li class="ng-value"
                              *ngFor="let item of expenseMainForm.value.expense_policy,let ki =index">
                              <ng-container *ngFor="let insideitem of expensePolicyDropdown, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                      <div class="d-inline-flex align-items-center">
                                          <span class=""> {{ (insideitem['name'].length>25)?
                                              (insideitem['name'] |
                                              slice:0:25)+'..':(insideitem['name']) }}</span>
                                      </div>
                                  </span>
                              </ng-container>
                              <span class="ng-value-icon right"
                                  (click)="clearcommonForm('expense_policy',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                  </div>
                  <ng-container *ngIf="reportName == 'Expense Advance' || reportName == 'Outstanding Advance Report'">
                    <div class="col-12 form-row">
                      <label for="" class="form-label ">Advance policy</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('advance policy')}}"
                          formControlName="advance_policy" [items]="advancePolicyDropdown" [multiple]="true"
                          groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                          bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="advanceLoading">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!advanceLoading">
                              <div class="ng-value" *ngIf="expenseMainForm.value.advance_policy?.length ">
                                  <span class="ng-value-label">{{expenseMainForm.value.advance_policy?.length }} advance policy selected</span>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline-flex align-items-center">
                                  <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                                      [ngModelOptions]="{ standalone : true }" />

                                  <span class="">{{ (item.name?.length>15)? (item.name |
                                      slice:0:15)+'..':(item.name) }}</span>
                              </div>
                          </ng-template>
                          <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="advanceLoading">
                            <div class="loading-text">Loading...</div>
                          </ng-template>
                      </ng-select>
                      <ul class="selected-values mt-16" *ngIf="expenseMainForm.value.advance_policy?.length != 0 && !advanceLoading">
                          <li class="ng-value"
                              *ngFor="let item of expenseMainForm.value.advance_policy,let ki =index">
                              <ng-container *ngFor="let insideitem of advancePolicyDropdown, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                      <div class="d-inline-flex align-items-center">
                                          <span class=""> {{ (insideitem['name'].length>25)?
                                              (insideitem['name'] |
                                              slice:0:25)+'..':(insideitem['name']) }}</span>
                                      </div>
                                  </span>
                              </ng-container>
                              <span class="ng-value-icon right"
                                  (click)="clearcommonForm('advance_policy',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="reportName == 'Expense Payment Register'">
                    <div class="col-12 form-row">
                      <label for="" class="form-label ">Reimbursement type</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('reimbursement type')}}"
                          formControlName="reimb_type" [items]="reimbTypeDD" [multiple]="true"
                          groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                          bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngIf="expenseMainForm.value.reimb_type?.length ">
                                  <span class="ng-value-label">{{expenseMainForm.value.reimb_type?.length }} expense policy selected</span>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline-flex align-items-center">
                                  <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                                      [ngModelOptions]="{ standalone : true }" />

                                  <span class="">{{ (item.name?.length>15)? (item.name |
                                      slice:0:15)+'..':(item.name) }}</span>
                              </div>
                          </ng-template>
                      </ng-select>
                      <ul class="selected-values mt-16" *ngIf="expenseMainForm.value.reimb_type?.length != 0">
                          <li class="ng-value"
                              *ngFor="let item of expenseMainForm.value.reimb_type,let ki =index">
                              <ng-container *ngFor="let insideitem of reimbTypeDD, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                      <div class="d-inline-flex align-items-center">
                                          <span class=""> {{ (insideitem['name'].length>25)?
                                              (insideitem['name'] |
                                              slice:0:25)+'..':(insideitem['name']) }}</span>
                                      </div>
                                  </span>
                              </ng-container>
                              <span class="ng-value-icon right"
                                  (click)="clearcommonForm('reimb_type',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                  </div>
                  </ng-container>
                <app-ou-filters #childOuFilter [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
              </div>
          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage('reset');resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
              (click)="resetPage('apply');submitFilterComponent()">
              Apply
          </button>
      </footer>
  </form>
</div>
