<ng-container *ngIf="permissions">
  <ul ngbNav #nav="ngbNav" class="nav nav-custom sticky" [(activeId)]="activeTab" >
      <li [ngbNavItem]="1">
          <a ngbNavLink class="nav-link ">Provident Fund</a>
          <ng-template ngbNavContent>
              <app-challan-provident-fund></app-challan-provident-fund>
          </ng-template>
      <li [ngbNavItem]="2">
          <a ngbNavLink class="nav-link ">Employee State Insurance
          </a>
          <ng-template ngbNavContent>
              <app-challan-employee-state-insurance></app-challan-employee-state-insurance>
          </ng-template>
      </li>
      <!-- <li [ngbNavItem]="3">
          <a ngbNavLink class="nav-link ">Labour Welfare Fund</a>
          <ng-template ngbNavContent>
          </ng-template>
      </li>
      <li [ngbNavItem]="4">
          <a ngbNavLink class="nav-link ">Professional Tax</a>
          <ng-template ngbNavContent>
          </ng-template>
      </li> -->

  </ul>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>
