import { Component, OnInit,ViewChild } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-my-asset',
  templateUrl: './my-asset.component.html',
  styleUrls: ['./my-asset.component.scss']
})
export class MyAssetComponent implements OnInit {
  viewDetail          = false;
  offset                = 0;
  listlength            = 0;
  direction             = '';
  defaultlimit:any      = 20;
  DetailsList:any       = [];
  infinityloader        = false;
  viewLoader            = true;
  loader                = true
  searchlistdata:any    = [] ;
  searchKeyword         = ""
  datafilter            = ""
  landingData:any       = [];
  nodatafound           = false
  viewData:any          = []
  filterpanel           = false
  searchPlaceholder     = this.messageService.searchdisplay('asset');

  filterForm            = this.fb.group({
    status                        : [null],
    asset__name                   : [null],
    asset__code                   : [null],
    asset__serial_number          : [null],
    asset__asset_sub_category_id  : [null],
    asset__asset_category_id      : [null],
    assigned_by                   : [null],
    assigned_on                   : '',
    returned_on                   : '',
  })
  @ViewChild(SearchbarComponent) child:any;
  sLoading = false;

  constructor(
    public messageService:MessageService,
    public appService:AppService,
    public assetService:AssetSettingsService,
    public fb:FormBuilder,



  ) { }

  ngOnInit(): void {
    this.getLandingScreenData()
  }

  getLandingScreenData(){

    this.loader       = true;
    this.assetService.getEmployeeAssetData(this.defaultlimit,this.offset,this.searchKeyword,this.datafilter).subscribe((res:any)=>{

      this.listlength = res.body.results.length;
      if(this.offset ==0){
        this.landingData = [];
      }
      if (this.listlength == 0) {
        this.nodatafound = this.offset == 0 ? true :false;
      }else {
        this.nodatafound = false;
        this.DetailsList = res.body.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
      this.sLoading = false
    })
    // this.assetService.getMyassetDropdown(this.datafilter).subscribe((res:any)=>{
    //   this.searchlistdata = res.body;

    // })
  }
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.landingData.push(this.DetailsList[i]);
    }
  }
  searchAsset(searchKeyword:any){
    this.sLoading       = true;
    this.loader         = true;
    this.offset         = 0;
    this.landingData    = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getLandingScreenData();
    }
  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset         = 0;
      this.landingData    = [];
      this.getLandingScreenData();
    }
    else{
        this.loader = true;

        this.assetService.getSingleEmployeeAsset(data).subscribe((res: any) => {

          this.landingData    = [res.body];
          this.loader         = false;
        });
    }
  }
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getLandingScreenData();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  viewDetails(id:any){
    this.viewLoader = true;
    this.viewDetail = true;
    this.assetService.getSingleEmployeeAsset(id).subscribe((res:any)=>{

      this.viewData   = res.body
      this.viewLoader = false;

    })
  }
  applyFilter(data:any){
    this.datafilter = data;
    this.loader       = true;
    this.DetailsList  = []
    this.landingData  = [];
    this.offset       = 0;
    // this.getLandingScreenData();
    this.child.clearBar();

    this.closeFilter()
  }
  closeFilter(){
    this.filterpanel = false;
  }
  resetfilter(){
    this.loader       = true;
    this.DetailsList  = []
    this.landingData  = [];
    this.offset       = 0;
    this.datafilter = "";
    // this.getLandingScreenData();
    this.child.clearBar();

    this.closeFilter()
  }
}
