<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="this.close.emit(false);"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <nav class="nav nav-custom sticky" *ngIf="from == 'loanInbox' && !loader">
      <a class="nav-link" *ngFor="let nav of navData;let i=index;" [ngClass]="{ active: activeToggle == nav?.loan_code }" (click)="selectItemList(nav?.loan_code)">
        {{nav?.loan_code}}
      </a>
    </nav>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader"></app-view-loader>
        <div class="row row-16"  *ngIf="!loader">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">{{loanData?.loan_code}}</p>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12  ">
                  <p class="title mb-0">loan details</p>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Loan amount</div>
                  <div class="detail-desc">{{loanData?.loan_amount | currency : loanData?.currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Total interest</div>
                  <div class="detail-desc">{{loanData?.total_interest | currency : loanData?.currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Paid</div>
                  <div class="detail-desc">{{loanData?.total_paid | currency : loanData?.currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">To pay</div>
                  <div class="detail-desc">{{loanData?.to_be_recovered | currency : loanData?.currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Loan type</div>
                  <div class="detail-desc">{{loanData?.loan_type}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Date of request</div>
                  <div class="detail-desc">{{loanData?.requested_date != null ? this.appServ.dateFormatDisplay(loanData?.requested_date) : '-'}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Date of dispense</div>
                  <div class="detail-desc">{{loanData?.dispensed_date != null ? this.appServ.dateFormatDisplay(loanData?.dispensed_date) : '-'}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Approved by</div>
                  <div class="detail-desc">{{loanData?.approved_by != null ? loanData?.approved_by : '-'}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="table-responsive radius-4">
                <table
                  class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table "
                  [ngClass]="{'sticky-last-col': from == 'hr'}">
                  <thead>
                    <tr>
                      <th class="fw-600 text-uppercase" scope="col">month</th>
                      <th class="fw-600 text-uppercase" scope="col">PRINCIPLE</th>
                      <th class="fw-600 text-uppercase" scope="col">INTEREST</th>
                      <th class="fw-600 text-uppercase" scope="col">EMI</th>
                      <th class="fw-600 text-uppercase" scope="col">Status</th>
                      <th class="fw-600 text-uppercase" scope="col">Amount to be paid</th>
                      <th class="fw-600 text-uppercase" scope="col">AMOUNT DEDUCTED</th>
                      <th class="fw-600 text-uppercase" scope="col">Outstanding Balance</th>
                      <th class="fw-600 text-uppercase" scope="col">PAYMENT SHORTFALL</th>
                      <th class="fw-600 text-uppercase" scope="col">INTEREST PENALTY</th>
                      <th *ngIf="from == 'hr'" class="fw-600 text-uppercase" scope="col">ADJUSTMENT</th>
                    </tr>
                  </thead>
                  <tbody class="cell-16">
                    <tr *ngIf="loanData?.emi_loan?.length == 0">
                      <td colspan="11" class="bg-white">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngFor="let loan of $any(loanData?.emi_loan | slice : (page-1) * pageSize : (page-1) * pageSize + pageSize);let i = index">
                      <td>{{monthConversion(loan?.pay_month)}}/{{loan?.pay_year}}</td>
                      <td>{{loan?.principle_amount}}</td>
                      <td>{{loan?.interest_amount}}</td>
                      <td>{{loan?.emi_amount}}</td>
                      <td>
                        <span class="badge py-1 px-3" [ngClass]="{
                            'badge-danger': loan?.status === 'Unpaid',
                            'badge-primary': loan?.status === 'Restructured',
                            'badge-c6': loan?.status === 'Settled',
                            'badge-success': loan?.status === 'Paid'
                          }">
                          {{loan?.status}}
                        </span>
                      </td>
                      <td>{{loan?.amount_to_be_paid}}</td>
                      <td>{{loan?.amount_deducted}}</td>
                      <td>{{loan?.outstanding_balance}}</td>
                      <td>{{loan?.payment_shortfall}}</td>
                      <td>{{loan?.interest_penalty}}</td>
                      <td *ngIf="from == 'hr'">
                        <a *ngIf="loan?.is_adjusted" class="link-success" (click)="viewAdjustFn(loan)">Adjusted</a>
                        <a *ngIf="loan?.is_adjusted == false" class="link-primary1" (click)="setForms(loan)">Adjust </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="this.appServ.filteredcount(loanData?.emi_loan,'')>pageSize">
            <ngb-pagination class="d-flex justify-content-end" [(page)]="page"
              [collectionSize]="this.appServ.filteredcount(loanData?.emi_loan,'')" [maxSize]="3" [rotate]="true"
              [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="from=='hr'" class="side-panel side-pane-active" style="--sidepanel-width:45rem;" [class.side-pane-active]='editAdjust===true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Adjust</h5>
      <a class="toggle-panel" (click)="editAdjust=false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">{{loanData?.loan_code}}</p>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Select one of the options</label>
          </div>
          <div class="col-12 mt-8 form-row">
            <div class="form-check form-check-inline">
              <input name="radio-name" type="radio" id="radio1" class="form-check-input" value="Reduce"
                [checked]='status=="Reduce"' (click)="setReduceForm() ">
              <label for="radio1" class="form-check-label">Reduce this month’s EMI </label>
            </div>
            <div class="form-check form-check-inline">
              <input name="radio-name" type="radio" id="radio2" class="form-check-input" value="Settled"
                [checked]='status=="Settled"' (click)="setsettleForm()">
              <label for="radio2" class="form-check-label">Settle loan out of system </label>
            </div>
            <div class="form-check form-check-inline">
              <input name="radio-name" type="radio" id="radio3" class="form-check-input" value="Restructure"
                [checked]='status=="Restructure"' (click)="setrestructureForm()">
              <label for="radio3" class="form-check-label">Restructure loan </label>
            </div>
          </div>
          <ng-container *ngIf="status=='Reduce'" [formGroup]="reduceForm">
            <div class="col-12">
              <div class="hstack p-16 bg8 fw-500 radius-6">
                <span>EMI</span>
                <span class="ms-auto">{{adjustLoanData?.emi_amount}}</span>
              </div>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Reduced EMI </label>
              <input type="text" formControlName="reducing_emi_amount" class="form-control" placeholder="{{this.messageService.placeholderdisp('reduced EMI')}}"
              [ngClass]="{ 'is-invalid': (submitted || r.reducing_emi_amount.touched || r.reducing_emi_amount.dirty) &&  r.reducing_emi_amount.errors}" autocomplete="off">

              <div *ngIf="(submitted || r.reducing_emi_amount.touched || r.reducing_emi_amount.dirty) &&  r.reducing_emi_amount.errors" class="invalid-feedback">
                <div *ngIf="r.reducing_emi_amount.errors?.required"> {{this.messageService.validationDisplay('required')}} </div>
                <div *ngIf="r.reducing_emi_amount.errors?.pattern"> {{this.messageService.validationDisplay('pattern')}} </div>
                <div *ngIf="r.reducing_emi_amount.errors?.max"> {{this.messageService.fieldlengthvalidation('amount',adjustLoanData?.emi_amount)}} </div>
                <div *ngIf="r.reducing_emi_amount.errors?.min"> {{this.messageService.fieldlengthvalidation('minamount',0)}} </div>
              </div>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">How should reduced amount of this month's EMI be recovered?
              </label>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check hstack align-items-start">
                <label for="radio5" class="form-check-label">Add the reduced amount of this month's EMI in next month's EMI
                <input  type="radio" id="radio5" class="form-check-input" [value]="false" formControlName="emi_reduce_type">
                 </label>
              </div>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check hstack align-items-start">
                <input type="radio" id="radio4" class="form-check-input" [value]="true" formControlName="emi_reduce_type">
                <label for="radio4" class="form-check-label">Equally distribute the reduced amount of this month's EMI in the remaining EMI months. </label>
              </div>
            </div>
            <div class=" col-12 form-row">
              <label for="" class="form-label required">Comments </label>
              <textarea type="text" class="form-control " rows="4" placeholder="Write Comments here..." formControlName="comment" autocomplete="off"
              [ngClass]="{ 'is-invalid': (submitted || r.comment.touched || r.comment.dirty) &&  r.comment.errors}"></textarea>
              <div *ngIf="(submitted || r.comment.touched || r.comment.dirty) &&  r.comment.errors" class="invalid-feedback">
                <div *ngIf="r.comment.errors?.required"> {{this.messageService.validationDisplay('required')}} </div>
                <div *ngIf="r.comment.errors?.pattern"> {{this.messageService.validationDisplay('pattern')}} </div>
                <div *ngIf="r.comment?.errors?.maxlength"> {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="status=='Settled'" [formGroup]="SettledForm">
            <div class="col-12">
              <div class="hstack p-16 bg8 fw-500 radius-6">
                <span>Total Outstanding Loan Amount</span>
                <span class="ms-auto">{{loanData?.to_be_recovered}}</span>
              </div>
            </div>
            <div class=" col-12 form-row">
              <label for="" class="form-label required">Comments </label>
              <textarea type="text" class="form-control " rows="4" placeholder="Write Comments here..." formControlName="comment" autocomplete="off"
              [ngClass]="{ 'is-invalid': (submitted || s.comment.touched || s.comment.dirty) &&  s.comment.errors}"></textarea>
              <div *ngIf="(submitted || s.comment.touched || s.comment.dirty) &&  s.comment.errors" class="invalid-feedback">
                <div *ngIf="s.comment.errors?.required"> {{this.messageService.validationDisplay('required')}} </div>
                <div *ngIf="s.comment.errors?.pattern"> {{this.messageService.validationDisplay('pattern')}} </div>
                <div *ngIf="s.comment?.errors?.maxlength"> {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="status=='Restructure'" [formGroup]="RestructureForm">
            <div class="col-12">
              <div class="hstack p-16 bg8 fw-500 radius-6">
                <span>Balance Loan Amount </span>
                <span class="ms-auto">{{loanData?.to_be_recovered}} </span>
              </div>
            </div>
            <div class="col-12">
              <div class="hstack p-16 bg8 fw-500 radius-6">
                <span>Number of EMI’s Left </span>
                <span class="ms-auto"> {{loanData?.pending_emis}}</span>
              </div>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Amount to be calculated out of system </label>
              <input type="text"  formControlName="amount_out_system" class="form-control" autocomplete="off"
              placeholder="{{this.messageService.placeholderdisp('amount to be calculated out of system ')}}"
              [ngClass]="{ 'is-invalid': (submitted || f.amount_out_system.touched || f.amount_out_system.dirty) &&  f.amount_out_system.errors}"
              (keyup)="calcNewLoan()"
              >
              <div *ngIf="(submitted || f.amount_out_system.touched || f.amount_out_system.dirty) &&  f.amount_out_system.errors" class="invalid-feedback">
                <div *ngIf="f.amount_out_system.errors?.required"> {{this.messageService.validationDisplay('required')}} </div>
                <div *ngIf="f.amount_out_system.errors?.pattern"> {{this.messageService.validationDisplay('pattern')}} </div>
                <div *ngIf="f.amount_out_system.errors?.max"> {{this.messageService.lessThanMessage(loanData?.to_be_recovered,'New loan amount')}} </div>
                <div *ngIf="f.amount_out_system.errors?.min"> {{this.messageService.greaterThanMessage('0','New loan amount')}} </div>
              </div>
            </div>
            <div class="col-12">
              <div class="hstack p-16 bg8 fw-500 radius-6">
                <span>New Loan Amount </span>
                <span class="ms-auto">{{newLoanAmt}} </span>
              </div>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">New number of EMI’s </label>
              <input type="text" name="new_number_emis" formControlName="new_number_emis" pattern="^[0-9]*$" class="form-control" autocomplete="off"
              placeholder="{{this.messageService.placeholderdisp('number of emi')}}"
              [ngClass]="{ 'is-invalid': (f.new_number_emis.touched|| f.new_number_emis.dirty) &&  f.new_number_emis.errors}">
              <div *ngIf="(f.new_number_emis.touched || f.new_number_emis.dirty) && f.new_number_emis.errors" class="invalid-feedback">
                <div *ngIf="f.new_number_emis.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.new_number_emis.hasError('min') && !(f.new_number_emis.errors?.pattern)">{{this.messageService.fieldlengthvalidation('minvalue',1)}}</div>
                <div *ngIf="f.new_number_emis.hasError('max') && !(f.new_number_emis.errors?.pattern)">{{this.messageService.fieldlengthvalidation('value',this.loanTypeDetails?.max_number_emis)}}</div>
                <div *ngIf=" f.new_number_emis.errors?.pattern "> {{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
            <div class="col-12" [formGroup]="monthForm">
              <div class="row row-16">
                <div class="col-12">
                  <label for="" class="form-label required">EMI start month for new loan </label>
                </div>
                <div class="col-6 mt-0">
                  <ng-select formControlName="year" class="form-ngselect  fs-14"
                    placeholder="{{this.messageService.selectplaceholddisplay('year')}}"
                    [ngClass]="{ 'is-invalid': (m.year.touched|| m.year.dirty) &&  m.year.errors}" (change)="updatedate()">
                    <ng-option *ngFor="let yData of yearList" [value]="yData">{{yData}}</ng-option>
                  </ng-select>
                  <div *ngIf="(submitted || m.year.touched || m.year.dirty) &&  m.year.errors" class="invalid-feedback">
                    <div *ngIf="m.year.errors?.required"> {{this.messageService.validationDisplay('required')}} </div>
                  </div>
                </div>
                <div class="col-6 mt-0">
                  <ng-select formControlName="month" class="form-ngselect  fs-14"
                    placeholder="{{this.messageService.selectplaceholddisplay('month')}} "
                    [ngClass]="{ 'is-invalid': (m.month.touched|| m.month.dirty) && m.month.errors}" (change)="updatedate()">
                    <ng-option *ngFor="let yData of listOfMonth" [value]="yData.id">{{yData.value}}</ng-option>
                  </ng-select>
                  <div *ngIf="(submitted || m.month.touched || m.month.dirty) &&  m.month.errors" class="invalid-feedback">
                    <div *ngIf="m.month.errors?.required"> {{this.messageService.validationDisplay('required')}} </div>
                    <div *ngIf="m.month.errors?.invalidDate"> {{this.messageService.greaterThanMessage("current pay month",'Selected
                      month')}} </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" col-12 form-row">
              <label for="" class="form-label required">Comments </label>
              <textarea type="text" class="form-control " rows="4" placeholder="Write Comments here..." formControlName="comment" autocomplete="off"
              [ngClass]="{ 'is-invalid': (submitted || f.comment.touched || f.comment.dirty) &&  f.comment.errors}"></textarea>
              <div *ngIf="(submitted || f.comment.touched || f.comment.dirty) &&  f.comment.errors" class="invalid-feedback">
                <div *ngIf="f.comment.errors?.required"> {{this.messageService.validationDisplay('required')}} </div>
                <div *ngIf="f.comment.errors?.pattern"> {{this.messageService.validationDisplay('pattern')}} </div>
                <div *ngIf="f.comment?.errors?.maxlength"> {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm"  (click)="editAdjust=false">Cancel</button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" *ngIf="status=='Reduce'"  [disabled]="!reduceForm.valid " (click)="reduce()">ADJUST</button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" *ngIf="status=='Settled'"  [disabled]="!SettledForm.valid " (click)="settle()">ADJUST</button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" *ngIf="status=='Restructure'"  [disabled]="!RestructureForm.valid " (click)="restructure()">ADJUST</button>
    </footer>
  </div>
</div>

<div  *ngIf="from=='hr'" class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewAdjust === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Adjust details </h5>
      <a class="toggle-panel" (click)="viewAdjust = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="adjustLoader"></app-view-loader>
        <div class="row row-16" *ngIf="!adjustLoader">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">{{loanData?.loan_code}}</p>
          </div>
          <div class="col-12" *ngIf="adjustData?.status == 'Reduce'">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">REDUCEd emi details</p>
                  <a class="link-primary fs-14 ms-auto text-nowrap" *ngIf="!adjustData?.is_processed" (click)="editAdjusted()"><i class="icon-pencil-square me-1"></i>
                    Edit</a>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">EMI</div>
                  <div class="detail-desc">{{adjustData?.emi | currency : currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Reduced EMI</div>
                  <div class="detail-desc">{{adjustData?.reduced_emi | currency : currency}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">How should reduced amount of this months’s EMI be recovered?</div>
                  <div class="detail-desc" *ngIf="!adjustData?.emi_reduce_type">Add the reduced amount of this month’s EMI in next month’s EMI</div>
                  <div class="detail-desc" *ngIf="adjustData?.emi_reduce_type">Equally distribute the reduced amount of this month's EMI in the remaining EMI months.
                    </div>

                </div>
                <div class="col-12">
                  <div class="detail-title">Comments</div>
                  <div class="detail-desc">{{adjustData?.comments}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="adjustData?.status == 'Settled'">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">SETTLE LOAN OUT OF SYSTEM details</p>
                  <!-- <a class="link-primary fs-14 ms-auto text-nowrap"><i class="icon-pencil-square me-1"></i>Edit</a> -->
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Total Outstanding Loan Amount</div>
                  <div class="detail-desc">{{adjustData?.outstanding_amount | currency : currency}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Comments</div>
                  <div class="detail-desc">{{adjustData?.comments}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="adjustData?.status == 'Restructure'">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">RESTRUCTURE LOAN details</p>
                  <!-- <a class="link-primary fs-14 ms-auto text-nowrap"><i class="icon-pencil-square me-1"></i>Edit</a> -->
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Balance Loan Amount</div>
                  <div class="detail-desc">{{adjustData.balance_loan_amount | currency : currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Number of EMI’s left</div>
                  <div class="detail-desc">{{adjustData?.pending_emis}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">Amount to be calculated out of system</div>
                  <div class="detail-desc">{{adjustData?.system_calculated_amount | currency : currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">New loan amount</div>
                  <div class="detail-desc">{{adjustData?.new_loan_amount | currency : currency}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">New Number of EMI’s</div>
                  <div class="detail-desc">{{adjustData?.new_number_emis}}</div>
                </div>
                <div class="col-sm-4 col-md-3 col-12">
                  <div class="detail-title">EMI start month for new loan</div>
                  <div class="detail-desc">{{this.appServ.dateFormatDisplay(adjustData?.new_emi_start_month)}}</div>
                </div>
                <div class=" col-12">
                  <div class="detail-title">Comments</div>
                  <div class="detail-desc">{{adjustData?.comments}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
