<form [formGroup]="myForm">
  <div class=" config-layout">
    <header class=" config-header">
      <h5 class="m-0"> {{heading}} Approval Matrix</h5>
      <button class="btn-reset text-light-600" routerLink="/approval-matrix"><i
          class="icon-close-lg fs-16"></i></button>
    </header>
    <!-- <app-loader *ngIf="loader"></app-loader>
     -->
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <div *ngIf="!loader">
      <aside class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="step"
              [class]="{'success':myForm['controls'].configure.valid || myForm['controls'].configure.disabled}">
              <div class="count">1</div>
              <div class="content">
                <span>Step 1</span>
                <h6>Configure Approval Flow</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-am-configure-flow [formGp]="configureForm" [editflag]="editflag" [disabled]="disabled"
                [submitted]="iseditForm" (submitmethod)="submitOne()"
                [roleDropdown]="roleDropdown"></app-am-configure-flow>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="step"
              [class]="{'success':myForm['controls'].allocate.valid|| myForm['controls'].allocate.disabled}"
              (submitmethod)="submitTwo()">
              <div class="count">2</div>
              <div class="content">
                <span>Step 2</span>
                <h6>Allocate Workflow</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-am-allocate-flow [workflowDropdown]="workflowDropdown" [disabled]="disabled" [submitted]="iseditForm"
                [formGp]="allocateForm" (changeModule)="changeModule()" (submitmethod)="submitTwo()"
                [moduleDropdown]="moduleDropdown"></app-am-allocate-flow>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink class="step"
              [class]="{'success':myForm['controls'].assign.valid|| myForm['controls'].assign.disabled}">
              <div class="count">3</div>
              <div class="content">
                <span>Step 3</span>
                <h6>Assign Rights</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-common-applicability [heading]="'Assign Rights'" [disabled]="disabled" [isEditForm]="iseditForm" [formGp]="assignForm" (submitMethod)="submitForm()"></app-common-applicability>
            </ng-template>
          </li>
        </ul>


      </aside>
      <main class=" config-container">
        <div [ngbNavOutlet]="nav"></div>
        <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
      </main>
    </div>
  </div>
</form>
