import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/@core/services/company.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { ConfigurationService } from 'src/app/@core/services/configuration.service';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';

@Component({
  selector: 'app-asset-settings-setup',
  templateUrl: './asset-settings-setup.component.html',
  styleUrls: ['./asset-settings-setup.component.scss']
})
export class AssetSettingsSetupComponent implements OnInit {
  checkboxes: any[]  = [];
  activeTab          = 1;
  companyList:any[]  = [];
  id: number         = 0;
  loader             = false;
  iseditForm         = false;
  tab2touched        = false
  compArr: any       = [];
  selectedComp: any  = [];
  editCompId:any     = 0;
  title:any          = "Add";


  comp_id:any;

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigurationService,
    private assetService:AssetSettingsService

  ) { }
  settingsForm: FormGroup = this.fb.group({
    company                                  : this.fb.array([]),
    setting_data                             : this.fb.array([
                                              this.fb.group({
                                                assets_given_after_days                  : [null,[Validators.required,NumberpatternValidator(),Validators.maxLength(2)]],
                                               assets_taken_before_days                  : [null,[Validators.required,NumberpatternValidator(),Validators.maxLength(2)]],
                                                // warranty_notification_days               : [null,[Validators.required,NumberpatternValidator(),Validators.maxLength(2)]],
                                              })
    ])


  })
  ngOnInit(): void {
    this.loader = true;

    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
      else
        this.getCompanyList();

    })
  }
  editForm(){
    let result:any;
    this.title  = 'Edit'
    this.assetService.getSingleAssetData(this.id).subscribe((res:any)=>{
      result            = res.body;
      this.editCompId   = result.company;
     let compArray:any  = [];
      compArray.push(String(result.company));
      this.formArray.push(new FormControl(String(result.company)));
      Object.entries(result).forEach(([key, value]) => {
        this.SettingsArray.controls[0].get(key)?.setValue(value);
      })

      this.getCurrentCompany(result.company);
      this.buildSelectedData();
    })
  }
  getCompanyList(){
    this.assetService.getExcludedCompList(true,'','',0,'').subscribe((res: any)=>{
      this.companyList = res.body;
      this.createCheckBoxArray();
      this.loader      = false;

    })
  }
  get formArray(){
    return this.settingsForm.controls['company'] as FormArray;
  }
  get SettingsArray(){
    return this.settingsForm.controls['setting_data'] as FormArray;

  }
  createCheckBoxArray(){
    for(let i=0;i<this.companyList.length;i++)
    {
      this.comp_id    = this.companyList[i].id;
      var dict:any    = {};
      dict['id']      = this.comp_id;
      dict['value']   = false;
      this.checkboxes.push(dict);

    }

  }
  changeTab(tabIndex:any){
    this.activeTab = tabIndex;
  }
  getCurrentCompany(id:any){
    this.companyService.companyDataGet(id).subscribe((res:any)=>{
      this.companyList = [res.body];

    })
  }
  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {

      if (this.settingsForm.value.company.length==0) {
        changeEvent.preventDefault();
      } else
      this.tab2touched =true
      this.buildSelectedData()
    }
  }
  buildSelectedData(){

    this.compArr = [];
    for(let selectedId of this.settingsForm.value.company){
      var selectedComp =  this.companyList.filter(function(compId:any) {

        return compId.id == selectedId;
      });
      this.compArr.push(selectedComp[0]);

    }
    this.selectedComp = this.compArr;
    setTimeout(() => {
      this.loader = false;
    }, 1000);

  }
  submitForm(){
    let data;
    if(this.id==0){

      data = {
        "company_ids"   : this.settingsForm.get('company')?.value,
        "setting_data"  : this.settingsForm.get('setting_data')?.value[0],
      }
    }
    else
    data = this.settingsForm.get('setting_data')?.value[0];
    this.assetService.saveOrUpdateCompanyConfig(data,this.id).subscribe((res:any)=>{
      this.router.navigate(['asset-settings']);

    })
  }
submitMethod(activeTab:any){
  this.buildSelectedData()
  this.activeTab = activeTab;
   if (activeTab === 2) {
    this.tab2touched =true
  }

}
activeCheckbox(data:any){
  this.checkboxes = [];
  this.checkboxes = data;
}

}
