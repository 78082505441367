<div class="container-fluid p-24">
  <div class="row mb-24">
    <div class="col-12">
      <h3>Permissions</h3>
      <p class="text-helper mb-md-0">The fields that will appear in the employee directory are managed on this page</p>
    </div>
  </div>
  <app-table-loader *ngIf="loader"></app-table-loader>
  <div class="row row-16" *ngIf="!loader">
    <div class="col-12">
      <div class="card card-c2">
        <div class="card card-c2 overflow-hidden">
          <div class="table-responsive">
            <table class="table table-striped td-10 th-10 table-sm">
              <thead>
                <tr>
                  <th scope="col">Label</th>
                  <th scope="col">Data type</th>
                  <!-- <th scope="col">VISIBLE IN DIRECTORY</th> -->
                  <th scope="col">VISIBLE IN DIRECTORY</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of filtered">
                  <td>{{removeUnderscoreAddUppercase(item.label)}}</td>
                  <td>{{removeUnderscoreAddUppercase(item.data_type)}}</td>
                  <!-- <td class="form-cell">
                                <input class="form-check-input m-0 checkbox-16" type="checkbox" [checked]="item.visible" value="item.visible" (change)="selectdata(item.id,$event)" />
                              </td> -->
                  <td class="form-cell">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" [checked]="item.visible" value="item.visible"
                        (change)="selectdata(item.id,$event)" [disabled]="!permissions?.e">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>