<div class="full-page">
  <app-login-loader *ngIf="loader"></app-login-loader>
  <ng-container *ngIf="!loader">

    <div class="bg-section text-center" style="background-image: url({{currentlogodata.background_image}});"
      *ngIf="currentlogodata.background_image != '' && currentlogodata.background_image != null">
      <div class="mx-auto" style="max-width: 31.25rem;" *ngIf="defaultcls">
        <div class="fs-44 pb-21 fw-700">Sit back and relax</div>
        <div class="fs-28 fw-600">We simplify, streamline and optimize your HR processes</div>
      </div>
    </div>
    <div class="bg-section text-center" style="background-image: url(/assets/images/bg/bg-2.svg);"
      *ngIf="currentlogodata.background_image == '' || currentlogodata.background_image == null">
      <div class="mx-auto" style="max-width: 31.25rem;">
        <div class="fs-44 pb-21 fw-700">Sit back and relax</div>
        <div class="fs-28 fw-600">We simplify, streamline and optimize your HR processes</div>

      </div>
    </div>
  </ng-container>
  <div class="form-section">
    <div class="card p-32 mx-auto form-card">
      <a routerLink="/" class="text-center d-block">
        <img class="img-fluid org-logo" src="{{currentlogodata.logo}}" alt=""
          *ngIf="currentlogodata.logo != '' && currentlogodata.logo != null">
        <img class="img-fluid org-logo" src="/assets/images/SkizzleHR-Logo/SkizzleHR-HorizontalLogo-Color.svg" alt=""
          *ngIf="currentlogodata.logo == '' || currentlogodata.logo == null">
      </a>
      <h3 class="fw-600 text-center pt-32 pb-24" *ngIf="currentlogodata != '' && currentlogodata != null">
        {{currentlogodata?.welcome_message}}</h3>
      <h3 class="fw-600 text-center pt-40 pb-32" *ngIf="currentlogodata == '' || currentlogodata == null">Login to
        SkizzleHR
      </h3>
      <!-- <ng-container *ngIf="inactiveUser">
        <div class="alert alert-danger show" role="alert">
          {{inactiveMsg}}
        </div>
      </ng-container> -->
      <form [formGroup]="loginForm" (ngSubmit)="doLogin()">

        <div class="mb-12">
          <label for="username" class="form-label">Email or Employee code</label>
          <input type="text" (keydown)="onKeydownUser()" class="form-control" aria-describedby="helpId" formControlName="username" placeholder="" autofocus [ngClass]="{ 'is-invalid': (submitted || f.username.dirty || f.username.touched)  &&  (f.username.errors )}">
          <div style="height: 1.25rem;" class="invalid-feedback d-block">
            <ng-container *ngIf="(submitted || f.username.dirty || f.username.touched) && (f.username.errors )">
              <div class="lh-1" *ngIf="f.username.errors?.required"> {{this.messageService.validationDisplay('required')}}</div>
              <div class="lh-1" *ngIf="f.username.errors?.pattern">{{this.messageService.validationDisplay('pattern')}} </div>
              <div class="" *ngIf="f.username.errors && f.username.errors?.invalidUser">{{errorUser}}</div>

            </ng-container>
          </div>


        </div>
        <div class="mb-8">
          <label for="" class="form-label">Password</label>
          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.password.dirty || f.password.touched)  &&  (f.password.errors )}">
            <input  (keydown)="onKeydownPass()" [type]="show ? 'text' : 'password'" class="form-control" placeholder="" formControlName="password" [ngClass]="{ 'is-invalid': (submitted || f.password.dirty || f.password.touched)  &&  (f.password.errors )}">
            <i *ngIf="f.password.value" class="pointer {{viewicon}}" (click)="getpassword()"></i>
          </div>
          <div style="height: 1.25rem;" class="invalid-feedback d-block">
            <ng-container *ngIf="(submitted || f.password.dirty || f.password.touched) && (f.password.errors )">
              <div class="lh-1" *ngIf="f.password.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
              <div  *ngIf="(f.password.errors) && f.password.errors?.invalidPass">{{errorPass}}</div>
              <div class="lh-1" *ngIf="f.password.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
              </div>
            </ng-container>


          </div>
        </div>
        <div class="d-block text-end fs-14 link "><span class="link-primary" routerLink="../forgot-password">Forgot
            Password?</span></div>
        <div class="d-grid gap-2 pt-32">
          <button type="submit" class="btn btn-primary">Login</button>
        </div>
      </form>
    </div>
    <p class="text-center mb-0 text-light-400 pt-24 pb-16">
      <small><a href="https://www.skizzlehr.tech/pages/privacy-policy.php" class="link-secondary"
          target="_blank">Privacy
          policy</a> | <a href="https://www.skizzlehr.tech/pages/terms-and-condition.php" class="link-secondary"
          target="_blank">Terms and conditions</a> | {{this.appservice.version}}</small>
    </p>
    <div class="d-flex justify-content-center gap-16">
      <a href="https://apps.apple.com/us/app/skizzlehr/id6464050073" target="_blank">
        <img class="img-fluid " src="/assets/images/bg/appstore.png" style="height: 2.375rem;" alt="Apple Store">
      </a>
      <div class="vr h-100"></div>
      <a href="https://play.google.com/store/apps/details?id=com.skizzlehr" target="_blank">
        <img class="img-fluid " src="/assets/images/bg/playstore.png" style="height: 2.375rem;" alt="Play Store">
      </a>
    </div>
  </div>
</div>
