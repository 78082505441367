import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/@core/services/template.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  public searchFilter: any= '';
  showSidePanel = false;
  deleteAlert = false;
  defaultlimit=40;
  offset=0;
  status:boolean=true;
  res: any;

  constructor(
      private template:TemplateService,
      private router: Router,
      private appService: AppService,
      public breakpointObserver: BreakpointObserver,
      public messageService : MessageService,
      ) { }
  getdata:any;
  selectedid:any;
  singletemplatedetail:any;
  subject:any;
  description:any;
  id:any;
  getsingledata:any;
  viewid:any;
  is_active : boolean = true;
  datedata:any;
  searchString =this.messageService.searchdisplay('template name');
  matrixDropdown:any[] = [];
  searchFilterName='';
  searchlistdata:any = [];
  infinityloader = false;
  listlength = 0;
  loader = false;
  loader1 = false;
  direction = '';
  permissions:any = [];
  templateDetails: any = [];
  templateDetailsList:any  = [];
  public template_list_flag: boolean = true;
  inputFocusClass = false;
  @ViewChild(SearchbarComponent) child:any;
  searchkey= this.messageService.searchdisplay('Template')
  searchKeyword="";
  statusBtn:any = "Active";


  ngOnInit(): void {
    this.getTemplatedetails();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;

      }
    });
    this.getPermission();
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.defaultlimit;
    if(this.listlength){
      this.getdata();
      this.loader       = false;
      this.direction    = "down";
    }
  }

  selectItemList(value:any){
    this.status = value;
    this.offset = 0;
    this.getTemplatedetails();
    // this.child.clearBar();
  }

  getTemplatedetails()
  {
    this.loader = true;
    let data:any;
    this.template.gettemplatedetails(this.defaultlimit,this.offset,this.status,this.searchKeyword).subscribe((res:any)=>{
      // console.log(res);
      this.templateDetails=res.body.results;
      this.loader = false;
    })
    this.template.templateDropDownList(this.defaultlimit, this.offset,this.status,'template_name')
      .subscribe((res: any) => {
        if (res.status == 200) {
          for(let i=0; i< res.body.results.length; i++){
            res.body.results[i]['name'] = res.body.results[i]['template_name']
            // res.body[i]['image'] = res.body[i]['logo']
          }
          this.searchlistdata = res.body.results;
        }
      })

  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.offset = 0;
      this.templateDetails = [];
      this.templateList(this.status);
    }else{
      this.template.getsingletemplatedetail(data).subscribe((res: any) => {
        this.templateDetails = [res.body];
        this.loader = false;
      });
    }
  }
  searchtemplate(searchKeyword:any){
    this.loader = true;
    this.offset           = 0;
    this.templateDetails= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.templateList(this.status);
    }
  }

  templateList(status: any) {
    this.loader = true;
    this.status = status;
    this.template.gettemplatedetails(status,this.defaultlimit, this.offset,this.searchKeyword)
    .subscribe((res: any) => {
      this.listlength = res.results.length;
      if (res.results.length != 0) {
        this.templateDetailsList = res.results;
      }
      this.template_list_flag = (this.templateDetailsList.length==0)?false:true;
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
    })

    this.template.templateDropDownList(this.defaultlimit, this.offset,status,'template_name')
    .subscribe((res: any) => {
      if (res.status == 200) {
        for(let i=0; i< res.body.results.length; i++){
          res.body.results[i]['name'] = res.body.results[i]['template_name']
        }
        this.searchlistdata = res.body.results;
      }
    })

  }
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.templateDetails.push(this.templateDetailsList[i]);
    }
  }


  editHeaderContent(id:number){
    this.id=id;
    this.router.navigate(['/template/template-setup/'+id]);
  }

  deleteclick(id:number)
  {
    this.selectedid=id;
  }

  deletetemplate()
  {
    let data = { "is_active": false};
    this.template.deleteTemplateDetail(this.selectedid,data).subscribe((res: any) => {
      this.deleteAlert = false;
      this.getTemplatedetails();
    });
  }

  viewtemplate(data:any)
  {
    this.subject=data.subject;
    this.description=data.description;
  }

  viewsingledata(id:any){
    if(this.permissions.v){
      this.showSidePanel = true;
      this.loader1 = true;
      this.template.getsingletemplatedetail(id).subscribe((res: any) => {
        this.getsingledata = res.body;
        this.subject=this.getsingledata.subject;
        this.description=this.getsingledata.description;
        this.viewid=this.getsingledata.id;
        this.loader1 = false;
        this.is_active = this.getsingledata.is_active;
      })
    }

  }


  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }

  }


}
