

<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Process Attendance - {{month}} {{year}}</h5>
        <button class="btn-reset text-light-600" ><i class="icon-close-lg fs-16" (click)="backClicked()" ></i></button>
        <!-- routerLink="/run-payroll/{{year}}/{{month}}/{{company}}" -->
    </header>
    <app-c1-loader *ngIf="loader"></app-c1-loader>
    <ng-container *ngIf="!loader">
    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky " style="top:3.625rem;">
        <!-- <li [ngbNavItem]="1" *ngIf="tabAtt">
            <a ngbNavLink class="nav-link">Attendance - old</a>
            <ng-template ngbNavContent>
              <app-attendance></app-attendance>
            </ng-template>
        </li> -->
        <ng-container *ngIf="tabvalue== true">
        <li [ngbNavItem]="2"  *ngIf="tabLop">
            <a ngbNavLink class="nav-link">LOP  </a>
            <ng-template ngbNavContent>
                <app-lop></app-lop>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink class="nav-link">LOP  reversal</a>
            <ng-template ngbNavContent>
                <app-lop-reversal></app-lop-reversal>
            </ng-template>
        </li>
    </ng-container>
    <ng-container *ngIf="tabvalue== false">

        <li [ngbNavItem]="4">
            <a ngbNavLink class="nav-link">Arrears</a>
            <ng-template ngbNavContent>
                <app-arrear-process></app-arrear-process>
            </ng-template>
        </li>
        <li [ngbNavItem]="5">
            <a ngbNavLink class="nav-link">Attendance</a>
            <ng-template ngbNavContent>
                <app-attendance-process></app-attendance-process>
            </ng-template>
        </li>
        </ng-container>
    <!-- <li [ngbNavItem]="6">
                <a ngbNavLink class="nav-link">LOP reversal</a>
                <ng-template ngbNavContent>
                    <app-arrears></app-arrears>
                </ng-template>
            </li> -->
    </nav>


    <main [ngbNavOutlet]="nav"  class="config-container tabs-container">

    </main></ng-container>
</div>
