<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Acknowledge </h5>
    <button class="btn-reset text-light-600" routerLink="/document-repository"><i
        class="icon-close-lg fs-16"></i></button>
  </header>

  <main class=" config-container">
    <div class="container-fluid p-32 form-container">
      <app-table-loader *ngIf="loader1 || loading"></app-table-loader>

      <div class="row row-24 mb-24" *ngIf="!loader1">
        <div class="col-12 d-flex" *ngIf="empDocData.is_download && !loading">
          <a class="link-primary1 me-24 ms-auto"  (click)="this.appService.downloadFile(empDocData?.doc_file,currentDocName)"><i class="icon-download me-1"></i> Download </a>
        </div>
        <div class="col-md-8 mx-auto">
          <!-- pdf-here -->
          <div class="pdf-container" *ngIf="currentExtension=='pdf'">
            <pdf-viewer [src]="fileName" [render-text]="false"  [original-size]="false" style="height: calc(100% - 22px)!important;" (page-rendered)="pageRendered($event)" [autoresize]="true" [fit-to-page]="true">
            </pdf-viewer>
          </div>
          <div class="pdf-container" *ngIf="currentExtension=='doc' || currentExtension=='docx'">

            <ngx-doc-viewer [url]="fileName" viewer="google" style="height: calc(100% - 22px)!important;">
            </ngx-doc-viewer>

          </div>

        </div>
      </div>
    </div>
    <footer class="submit-footer gap-16" *ngIf="empDocData.is_acknowledge && !loader1 && empDocData.acknowledge_status=='Pending' && !loading" >
      <label for="check" class="form-check p-0 d-flex  me-auto">
        <input type="checkbox" name="check" id="check " class="form-check-input ms-0" (change)="fieldsChange($event)">
        <label for="check " class="form-check-label fs-16"> Acknowledgement required from employee</label>
      </label>
      <button class="btn btn-primary btn-sm text-uppercase" (click)="submitAck()" [disabled]="!isChecked" >SUBMIT </button>
    </footer>
  </main>
</div>

