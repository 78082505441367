import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-loader',
  templateUrl: './calendar-loader.component.html',
styles: []
})
export class CalendarLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
