<div class="col-12">
  <div class="card details-card p-16">
    <div class="row row-16">
      <ng-container *ngIf="from == 'component'">
        <div class="col-sm-3 col-12">
          <div class="detail-title">Employee name</div>
          <div class="detail-desc">{{requestitem?.employee_name}}</div>
        </div>
        <div class="col-sm-3 col-12">
          <div class="detail-title">Employee code</div>
          <div class="detail-desc">{{requestitem?.employee_code}}</div>
        </div>
      </ng-container>
      <div class="col-sm-3 col-12">
        <div class="detail-title">Report name</div>
        <div class="detail-desc">{{requestitem?.name}}</div>
      </div>
      <div class=" col-12">
        <div class="detail-title">Business purpose</div>
        <div class="detail-desc">{{requestitem?.business_purpose!=""?requestitem?.business_purpose:"--"}}</div>
      </div>
      <div class="col-sm-3 col-12">
        <div class="detail-title">From date</div>
        <div class="detail-desc">{{appservice.dateFormatDisplay(requestitem?.from_date)}}</div>
      </div>
      <div class="col-sm-3 col-12">
        <div class="detail-title">To date</div>
        <div class="detail-desc">{{appservice.dateFormatDisplay(requestitem?.to_date)}}</div>
      </div>
      <div class="col-sm-3 col-12">
        <div class="detail-title">Amount </div>
        <div class="detail-desc">{{requestitem?.total_amount| currency :
          currency}}</div>
      </div>
      <div class="col-sm-3 col-12">
        <div class="detail-title">Amount to be reimbursed</div>
        <div class="detail-desc">{{requestitem?.to_reimbursed| currency :
          currency}}</div>
      </div>
      <!-- <div class="col-sm-3 col-12">
        <div class="detail-title">Trip</div>
        <div class="detail-desc">Trip 1</div>
      </div> -->
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card details-card ">
    <div class="card-body pb-0 pt-8">
      <nav class="nav nav-custom   px-0 shadow-0">
        <a class="nav-link {{activeMenu=='Expense'?'active':''}}" (click)="activeMenu='Expense';page=1">Expense</a>
        <a class="nav-link {{activeMenu=='Advances'?'active':''}}" (click)="activeMenu='Advances';page=1">Advances & Refunds</a>
      </nav>
    </div>
    <div class="card-body ">
      <div class="row row-16">
        <div class="col-12">
          <div class="card card-c2">
            <div class="table-responsive radius-4">
              <table *ngIf="activeMenu=='Expense'" class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th class="fw-600 text-uppercase" scope="col">Receipt number</th>
                    <th class="fw-600 text-uppercase" scope="col">Expense date</th>
                    <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                    <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                    <th class="fw-600 text-uppercase" scope="col">Report name</th>
                    <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                  </tr>
                </thead>
                <tbody class="cell-16 ">
                  <ng-container *ngIf="receipts?.length == 0">
                    <tr>
                      <td colspan="6">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngFor="let rec of $any(receipts | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                    <tr>
                      <td><a class="link-primary1" (click)="detailedReceiptView(rec?.id)">{{rec?.number}}</a></td>
                      <td><span class="text-nowrap">{{appservice.dateFormatDisplay(rec?.expense_date)}}</span></td>
                      <td>{{from == 'component' ? rec?.category?.name : rec?.category_name}}</td>
                      <td>{{rec?.amount| currency : currency}}</td>
                      <td>{{requestitem?.name}}</td>
                      <td><span class="link-primary"><i class="icon-eye fs-18 " (click)="viewAttachmentFunction(ind,rec?.id)"></i></span></td>
                    </tr>

                  </ng-container>
                </tbody>
              </table>
              <table *ngIf="activeMenu=='Advances'" class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th class="fw-600 text-uppercase" scope="col">Advance number</th>
                    <th class="fw-600 text-uppercase" scope="col">Date</th>
                    <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                    <th class="fw-600 text-uppercase" scope="col">DISPENSED AMOUNT</th>
                  </tr>
                </thead>
                <tbody class="cell-16 ">

                  <ng-container *ngIf="advances?.length == 0">
                    <tr>
                      <td colspan="4">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngFor="let rec of $any(advances | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                    <tr>
                      <td><a class="link-primary1"  (click)="viewAdvanceFunction(rec?.id)">{{rec?.number}}</a></td>
                      <td><span class="text-nowrap">{{appservice.dateFormatDisplay(rec?.expense_date)}}</span></td>
                      <td>{{rec?.amount| currency : currency}}</td>
                      <td>{{rec?.dispansed_amount | currency : currency}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ngb-pagination *ngIf="receipts?.length>pageSize" class="d-flex justify-content-end"
          [collectionSize]="receipts?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
          [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>

          <ngb-pagination *ngIf="advances?.length>pageSize" class="d-flex justify-content-end"
            [collectionSize]="advances?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
            [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
      </div>
    </div>
  </div>
</div>

<app-view-expense-receipt *ngIf="viewReciept" [from]="'inbox'" [viewReciept]="viewReciept" [viewRecieptLoader]="viewRecieptLoader" [viewRecieptData]="viewRecieptData" [currency]="currency" (closeView)="viewReciept  = false"></app-view-expense-receipt>

<app-emp-advance-details-view *ngIf="viewAdvance" [viewAdvance]="viewAdvance" [viewdata]="viewdata" [advanceid]="advanceid" [viewLoader]="viewLoader" [currency]="currency" [from]="'inbox'"  (panelclose)="viewAdvance=false"> </app-emp-advance-details-view>

<app-view-multiple-attachments *ngIf="viewAttchments" [from]="'expenseReportRequestView'" [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="receiptId" (closeViewAttachment)="viewAttchments = false" ></app-view-multiple-attachments>

<app-view-file *ngIf="modalToggle== true" (modal)="this.modalToggle = $event" [modalToggle]="modalToggle"
    [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName">
</app-view-file>
