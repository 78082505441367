import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  /*const MultipartHeaders: HttpHeaders = new HttpHeaders({
    Accept: 'application/json'
  });*/



  // public Config: any = {
  //   api: {
  //     baseURL: 'http://11.98.155.150/back/ws', //obviously fake
  //     multiContentOptions: { headers : new HttpHeaders({
  //       'Accept': 'application/json',
  //     }) },
  //   }
  // };


  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  getcompanyList(status: any,id :any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }

  companyCreate(data: any, id: number) {
    if(id == 0) {
      return this.http.post<any>(`${this.API_PATH}organization/api/v1/company-create/`, data);
    } else {
      return this.http.put<any>(`${this.API_PATH}organization/api/v1/company-detail/${id}/`, data);
    }
  }

  getUserList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/user-list/?ordering=first_name`, this.httpOption);
  }
  getEmployeeList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name`, this.httpOption);
  }

  getIndustryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/industry-list/?ordering=name`, this.httpOption);
  }

  getCountryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/country-list/`, this.httpOption);
  }

  getRegionList(country: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/region-list/?country=${country}`, this.httpOption);
  }

  getCityList(state: any, status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?state=${state}&is_active=${status}&ordering=name`, this.httpOption);
  }

  companyListData(){
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/`, this.httpOption)
  }

  companyDropDownData(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=${status}&ordering=company_name`, this.httpOption)
  }

  companyGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/company-detail/${id}/`, this.httpOption)
  }

  // Added due to permission issue api
  companyNewGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/company-data/${id}/`, this.httpOption)
  }

  companyDataGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/company-data/${id}/`, this.httpOption)
  }

  companyDelete(id: number) {
    return this.http.patch(`${this.API_PATH}organization/api/v1/company-delete/${id}/`,  { is_active: false },this.httpOption)
  }
  getCountryCode(){
    return this.http.get(`${this.API_PATH}organization/api/v1/telephonecode-list/`, this.httpOption)
  }

  companyDeleteCheck(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/company-delete-check/?id=${id}`, this.httpOption)
}

}
