import { Component, OnInit } from '@angular/core';
import { ChallanService } from 'src/app/@core/services/challan.service';
import { MessageService } from 'src/app/message.global';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-challan',
  templateUrl: './challan.component.html',
  styleUrls: ['./challan.component.scss']
})

export class ChallanComponent implements OnInit {

  challanList:any          = [];
  loader                   = false;
  nodata                   = false;
  company:any              = "";
  companyList:any          = [];
  permissions:any          = [];
  currentYear              = new Date().getFullYear();
  fiscalyear:any           = "";
  selectedYear:any;

  constructor(
    public challanService:ChallanService,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public appservice:AppService
    ) { }

  ngOnInit(): void {

    this.getPermission();

    this.selectedYear = this.appservice.getFinancialYear();

    this.getCompanyDropdown();

    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    });
  }

  /********* GET COMPANY DROP DOWN DATA FUNCTION ****************/
  getCompanyDropdown(){
    this.challanService.companyDropDownData()
    .subscribe((res: any) => {
      this.companyList = res;
      if (res?.length > 0) {
        // this.companyList = res;
        if(this.company==''){
          this.company =  this.company==''?res[0]['id']:this.company;
        }
        this.getChallanList();
      }else{
        this.nodata = true;
      }
    })
  }

  /********* FILTER CHANGE FUNCTION ****************/
  filterChange(val:any,filterType:any){
    if(filterType == 'company')
      this.company      = Number(val);
    this.getChallanList();
  }

  /********* GET CHALLAN LIST FUNCTION ****************/
  getChallanList(){
    if(this.company != '' && this.company != null){
      this.loader         = true;
      this.challanList    = [];
      this.challanService.challanList(this.company,this.selectedYear)
      .subscribe((res: any) => {
        this.nodata       = res?.length == 0 ? true : false;
        this.challanList  = res;
        this.loader       = false;

      })
    }
  }

  /********* GET CURRENT FINANCIAL YEAR RANGE FUNCTION ****************/
  getCurrentFinancialYear() {
    var today       = new Date();
    if(this.selectedYear == today.getFullYear()){
      if ((today.getMonth() + 1) <= 3) {
        this.fiscalyear = (Number(this.selectedYear) - 1) + "-" + Number(this.selectedYear);
      } else {
        this.fiscalyear = Number(this.selectedYear) + "-" + (Number(this.selectedYear) + 1);
      }
    }else{
      this.fiscalyear = Number(this.selectedYear) + "-" + (Number(this.selectedYear) + 1);
    }

    return this.fiscalyear;
  }

  /********* GET PAGE PERMISSION FUNCTION ****************/
  getPermission(){
    this.permissions = this.appservice.getPermissions('/challan/add-challan');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  /********* YEAR CHANGE FUNCTION ****************/
  yearChange(year:any){
    this.selectedYear = Number(year);
    if(this.companyList?.length > 0)
      this.getChallanList();
    else
      this.nodata = true;
    // this.getCurrentFinancialYear();
    this.appservice.getFinancialYearRange(this.selectedYear);
  }

}
