import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { TaxslipService } from 'src/app/@core/services/taxslip.service';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-taxslip',
  templateUrl: './taxslip.component.html',
  styleUrls: ['./taxslip.component.scss']
})
export class TaxslipComponent implements OnInit {

  loader                = false;
  tasks:any[]           = [];
  nodata                = false;
  yearList : any        = [];
  currentYear           = new Date().getFullYear();
  selectedYear :any;

  pdfUrl:any = '';
  modalToggle = false
  loader1 = false
  currentDocName = ''
  currentExtension = 'pdf'

  /********INFINITY SCROLL VARIABLES  ****/
  offset                  = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  taskDetailsList:any     = [];
  infinityloader          = false;
  permissions:any         = [];

  constructor(
    public messageService : MessageService,
    private taxSlipService: TaxslipService,
    public appservice: AppService,

    ) { }

  ngOnInit(): void {

    this.selectedYear = Number(this.appservice.getFinancialYear())
    this.yearListCreate();
    this.getMyTaxslipData();
  }

    // test start
    getMyTaxslipData(){

      this.loader = true;
      this.taxSlipService.getMyTaxslipData(this.selectedYear,this.defaultlimit,this.offset).subscribe((res: any = []) => {
        this.taskDetailsList = [];
        if (res.results.length != 0) {
          this.taskDetailsList =  res.results;
        }
        this.listlength = res.results.length;
        if(this.offset == 0){
          this.tasks    = [];
        }
        this.infinityloader = false;
        this.addItems(this.listlength);
        this.nodata = this.taskDetailsList.length==0?true:false;
        this.loader = false;

      });
    }

    /********* INIFNITY SCROLL DATA CREATION ****************/
    addItems(itemlist:any) {
      for (let i = 0; i < itemlist; ++i) {
        this.tasks.push(this.taskDetailsList[i]);
      }
    }

     /********* INIFNITY SCROLL FUNCTION *******************/
     onScrollDown() {
      this.infinityloader = true;
      this.offset        += this.defaultlimit;
      if(this.listlength){
        this.getMyTaxslipData();
        this.loader       = false;
        this.direction    = "down";
      }else{
        this.infinityloader = false;
      }
    }

    getThreeChar(name:any){
      if(name != undefined && name != null){
        return name.substring(0,3);
      }
    }

    /********* YEAR LIST CREAT FUNCTION ****************/
    yearListCreate(){
      this.yearList=[];
      var i:number;
      for(i = this.currentYear; i >= 2020 ; i--){
        this.yearList.push(String(i))
      }
    }

    yearChange(val:any){
      this.selectedYear = Number(val);
      this.getMyTaxslipData();
    }

    // View PDF
    viewPDF(url:any){
      if(url != null){
        this.modalToggle = true;
        this.currentDocName = this.appservice.getPayTaxName(url,'Taxslip')
        this.pdfUrl = url+"?"+Date.now()
      }
    }

    // Download
    downLoad(url:any){
      if(url != null){
        let main_url = url+"?"+Date.now()
        let doc_name = this.appservice.getPayTaxName(url,'Taxslip')
        this.appservice.downloadFile(main_url,doc_name);
      }
    }

    // Loader
    showGif(event: {loaded: number, total: number}) {
      this.loader1 = event.loaded < event.total;
    }

    // Modal Close
    close(bool:any){
      this.modalToggle = bool;
    }
}
