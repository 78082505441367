<div class="container-fluid p-32">


    <div class="d-flex mb-16" *ngIf="viewBreakUpDetails?.total_earnings[0].payslip_url!=null && viewBreakUpDetails?.total_earnings[0].payslip_url!=''">
        <p class="text-uppercase fw-500 mb-0 text-break me-12">SALARY BREAKUP</p>
        <div class="d-flex align-items-center ms-auto gap-16 fs-14">
            <a class="link-primary" (click)="viewDocument(viewBreakUpDetails?.total_earnings[0].payslip_url)">
                View Payslip
            </a>
            <span class="text-light-400">|</span>
            <a class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="downLoad()">
                <i class="icon-download me-1"></i>Download Payslip
            </a>

        </div>
    </div>
    <app-table-loader *ngIf="componentLoader"></app-table-loader>
    <ng-container *ngIf="!componentLoader">
        <div class="row row-24 ">
            <div class="col-12">
                <div class="card card-c2 px-24 py-16">
                    <div class="row row-24 ">
                        <ng-container *ngIf=" viewBreakUpDetails.gross_benefits.length > 0|| earningData.length>0">
                            <div class="col-12">
                                <div class="title">Earnings</div>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-0 overflow-hidden ">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-sm td-12">
                                            <thead>
                                                <tr>
                                                    <th class="fw-600" scope="col">Component name</th>
                                                    <th class="fw-600" scope="col">AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody class="cell-16">
                                                <ng-container *ngFor="let item of earningData">
                                                  <ng-container *ngIf="item.is_display==true && item.show_salary_slip==true">
                                                    <tr>
                                                        <td>{{((item.component_name!=null && item.component_name!='')?item.component_name:item.contribution)}}</td>
                                                        <td>{{(item.amount) | currency: currency}} </td>
                                                    </tr>

                                                  </ng-container>
                                                </ng-container>
                                                <tr *ngIf="viewBreakUpDetails.total_earnings[0].gross_benefits">
                                                    <td class="fw-600">Total Earnings</td>
                                                    <td class="fw-600">{{(viewBreakUpDetails.total_earnings[0]?.total_earnings) | currency: currency}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                        <ng-container *ngIf=" viewBreakUpDetails.recurring_deductions.length > 0">
                            <div class="col-12">
                                <div class="title">DEDUCTIONS</div>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-0 overflow-hidden ">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-sm td-12">
                                            <thead>
                                                <tr>
                                                    <th class="fw-600" scope="col">Component name</th>
                                                    <th class="fw-600" scope="col">AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody class="cell-16">
                                                <ng-container *ngFor="let item of viewBreakUpDetails.recurring_deductions">
                                                  <ng-container *ngIf="item.is_display==true && item.show_salary_slip==true">

                                                    <tr>
                                                      <td>{{(item.component_name!=null && item.component_name!='')?item.component_name:item.contribution}}</td>

                                                        <td>{{(item.amount) | currency: currency}}</td>
                                                    </tr>
                                                  </ng-container>
                                                </ng-container>
                                                <tr *ngIf="viewBreakUpDetails.total_earnings[0].recurring_deductions">
                                                    <td class="fw-600">Total Deductions</td>
                                                    <td class="fw-600">{{(viewBreakUpDetails.total_earnings[0].recurring_deductions) | currency: currency}} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </ng-container>


                        <ng-container *ngIf=" viewBreakUpDetails.contributions.length > 0">
                            <div class="col-12">
                                <div class="title">CONTRIBUTIONS</div>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-0 overflow-hidden ">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-sm td-12">
                                            <thead>
                                                <tr>
                                                    <th class="fw-600" scope="col">Component name</th>
                                                    <th class="fw-600" scope="col">AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody class="cell-16">
                                                <ng-container *ngFor="let item of viewBreakUpDetails.contributions">
                                                  <ng-container *ngIf="item.is_display==true && item.show_salary_slip==true">

                                                    <tr>
                                                      <td>{{(item.component_name!=null && item.component_name!='')?item.component_name:item.contribution}}</td>

                                                        <td>{{(item.amount) | currency: currency}}</td>
                                                    </tr>
                                                  </ng-container>
                                                </ng-container>
                                                <tr *ngIf="viewBreakUpDetails.total_earnings[0].contribution_total">
                                                    <td class="fw-600">Total contributions</td>
                                                    <td class="fw-600">{{(viewBreakUpDetails.total_earnings[0].contribution_total) | currency: currency}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </ng-container>

                        <ng-container *ngIf=" viewBreakUpDetails.total_earnings[0].expense_reimbursement > 0">
                            <div class="col-12">
                                <div class="title">EXPENSE REIMBURSEMENTS</div>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-0 overflow-hidden ">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-sm td-12">
                                            <thead>
                                                <tr>
                                                    <th class="fw-600" scope="col">Component name</th>
                                                    <th class="fw-600" scope="col">AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody class="cell-16">

                                                <tr>
                                                  <td>Expense Reimbursements</td>
                                                    <td>{{(viewBreakUpDetails.total_earnings[0].expense_reimbursement) | currency: currency}}</td>
                                                </tr>

                                                <tr *ngIf="viewBreakUpDetails.total_earnings[0].expense_reimbursement">
                                                  <td class="fw-600">Total Expense Reimbursements</td>
                                                  <td class="fw-600">{{(viewBreakUpDetails.total_earnings[0].expense_reimbursement) | currency: currency}}</td>
                                              </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </ng-container>


                        <div class="col-12">
                            <div class="title">NET PAY</div>
                        </div>
                        <div class="col-12">
                            <div class="card card-c2 p-0 overflow-hidden ">
                                <div class="table-responsive">
                                    <table class="table table-striped table-sm td-12">
                                        <thead>
                                            <tr>
                                                <th class="fw-600" scope="col">Component name</th>
                                                <th class="fw-600" scope="col">AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody class="cell-16">

                                            <tr *ngIf="viewBreakUpDetails.total_earnings[0].gross_benefits >0" >
                                                <td>Total Earnings</td>
                                                <td>{{(viewBreakUpDetails.total_earnings[0]?.total_earnings) | currency: currency}}</td>
                                            </tr>
                                            <tr *ngIf="viewBreakUpDetails.total_earnings[0].recurring_deductions >0">
                                                <td>Total Deduction</td>
                                                <td> <span class="text-danger">(-) {{(viewBreakUpDetails.total_earnings[0].recurring_deductions) | currency: currency}}</span></td>
                                            </tr>
                                            <tr *ngIf="viewBreakUpDetails.total_earnings[0].expense_reimbursement >0">
                                                <td>Total Reimbursements</td>
                                                <td>{{(viewBreakUpDetails.total_earnings[0].expense_reimbursement) | currency: currency}} </td>
                                            </tr>
                                            <!-- <tr *ngIf="viewBreakUpDetails.total_earnings[0].contribution_total >0">
                                                <td>Other Total Benefits</td>
                                                <td>{{roundUp(viewBreakUpDetails.total_earnings[0].other_benefits) | currency: currency}} </td>
                                            </tr> -->
                                            <tr>
                                                <td class="fw-600">Total Net Pay</td>
                                                <td class="fw-600">{{(viewBreakUpDetails.net_pay) | currency: currency}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </ng-container>


</div>


  <ng-container *ngIf="modalToggle== true">
    <app-view-file (modal)="closeInfo($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="pdfUrl"></app-view-file>
  </ng-container>
