<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="loader==false && permissions">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h5 class="mb-0">Personal</h5>
      <ng-container *ngIf="addEditPermission">
        <a class="link-primary fs-14 d-flex align-items-center" *ngIf="personalData.length!=0 && permissions?.e"
          (click)="editPersonal('Edit')"><i class="icon-pencil-square me-1"></i> Edit</a>
      </ng-container>
    </div>
  </div>
  <div class="col-12" *ngIf="personalData.length!=0">
    <div class="card details-card shadow-0 px-24 py-16">
      <div class="row row-24 ">
        <div class="col-12 "><span class="title">Identification Details</span></div>
        <div *ngIf="getFieldPermission('pan_number')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">PAN no.</div>
          <div class="detail-desc">{{personalData?.pan_number}}</div>
        </div>
        <div *ngIf="getFieldPermission('aadhar_number')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Aadhar no.</div>
          <div class="detail-desc">{{personalData?.aadhar_number!=null?personalData?.aadhar_number:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('passport_number')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Passport no.</div>
          <div class="detail-desc">{{(personalData?.passport_number!=null && personalData?.passport_number!='')?personalData?.passport_number:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('passport_expiry_date')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Passport expiry date</div>
          <div class="detail-desc">
            {{(this.personalData?.passport_expiry_date!=null && this.personalData?.passport_expiry_date!='')?this.appservice.dateFormatDisplay(this.personalData?.passport_expiry_date):'-'}}
          </div>
        </div>
        <div *ngIf="getFieldPermission('personal_email')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Personal email</div>
          <div class="detail-desc">{{(personalData?.personal_email!=null && personalData?.personal_email!='')?personalData?.personal_email:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('emergency_contact_name')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Emergency contact name</div>
          <div class="detail-desc">
            {{(personalData?.emergency_contact_name!=null && personalData?.emergency_contact_name!='')?personalData?.emergency_contact_name:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('emergency_contact_number')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Emergency contact number</div>
          <div class="detail-desc">
            {{personalData.phone_code!='' && personalData.phone_code!=null ?("+"+personalData?.phone_code+" "):''}}
            {{ personalData.emergency_contact_number!='' && personalData.emergency_contact_number!=null
            ?personalData?.emergency_contact_number:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('relation')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Relation</div>
          <div class="detail-desc">{{(personalData?.relation!=null && personalData?.relation!='')?personalData?.relation:'-'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="personalData.length!=0">
    <div class="card details-card shadow-0 px-24 py-16">
      <div class="row row-24 ">
        <div class="col-12"><span class="title">Address Details</span></div>
        <div *ngIf="getFieldPermission('current_address')" class="col-12">
          <div class="detail-title">Current address</div>
          <div class="detail-desc">{{(personalData.current_address != null && personalData.current_address != '') ? personalData.current_address : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('permanent_address')" class="col-12">
          <div class="detail-title">Permanent address</div>
          <div class="detail-desc">{{(personalData.permanent_address != null && personalData.permanent_address != '') ? personalData.permanent_address : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('current_country')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Current country</div>
          <div class="detail-desc" >{{personalData.current_country != null ? personalData.current_country.name : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('current_state')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Current state</div>
          <div class="detail-desc">{{personalData.current_state != null ? personalData.current_state.name : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('current_city')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Current city</div>
          <div class="detail-desc">{{personalData.current_city != null ? personalData.current_city.name : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('current_pin')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Current pin</div>
          <div class="detail-desc">{{(personalData.current_pin != null && personalData.current_pin != '') ? personalData.current_pin : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('permanent_country')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Permanent country</div>
          <div class="detail-desc">{{personalData.permanent_country != null ? personalData.permanent_country.name : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('permanent_state')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Permanent state</div>
          <div class="detail-desc">{{personalData.permanent_state != null ? personalData.permanent_state.name : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('permanent_city')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Permanent city</div>
          <div class="detail-desc">{{personalData.permanent_city != null ? personalData.permanent_city.name : '-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('permanent_pin')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Permanent pin</div>
          <div class="detail-desc">{{(personalData.permanent_pin != null && personalData.permanent_pin != '') ? personalData.permanent_pin : '-'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- showing when no personal information available -->
<div class="row row-16" *ngIf="personalData.length==0 ">
  <div class="col-12">
    <div class="row row-24 py-16">
      <div class="col-12 ">
        <div class="card details-card shadow-0 px-24 py-16">
          <p class="title mb-24">Personal</p>
          <div *ngIf="addEditPermission" class="col-12 d-flex">
            <a *ngIf="permissions?.a" class="btn btn-outline-primary px-64" (click)="editPersonal('Add')">Add Personal
              Data</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Slider edit start-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" [formGroup]="personalForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <header class="side-panel-head">
      <h5> {{header}} Identification Details</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- Edit loader start -->
        <app-edit-loader *ngIf="loader1"></app-edit-loader>
        <!-- Edit loader end -->
        <ng-container *ngIf="!loader1">
          <p class="text-uppercase fw-500 fs-16">IDENTIFICATION DETAILS</p>
          <div class="row row-24 ">
            <input type="text" value="" formControlName="id" id="id" hidden>
            <input type="text" value="" formControlName="employee" id="employee" hidden>
            <div class="col-sm-6 form-row">
              <label for="pan_number" class="form-label required ">PAN no. </label>
              <input type="text" class=" form-control" name="" id="pan_number"
                placeholder="{{this.messageService.placeholderdisp('PAN no')}}" formControlName="pan_number"
                [ngClass]="{ 'is-invalid': (submitted || f.pan_number.dirty || f.pan_number.touched) &&  f.pan_number.errors}"
                >
              <div
                *ngIf="(submitted || f.pan_number.dirty || f.pan_number.touched) && f.pan_number.errors"
                class="invalid-feedback">
                <div *ngIf="f.pan_number.errors?.required">{{this.messageService.validationDisplay('required')}}</div>

              </div>
              <div *ngIf="(submitted || f.pan_number.dirty) && f.pan_number.errors" class="invalid-feedback">
                <div *ngIf="f.pan_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="aadhar_number" class="form-label required ">Aadhar no. </label>
              <input type="text" class=" form-control" name="" id="aadhar_number"
                placeholder="{{this.messageService.placeholderdisp('aadhar no')}}" formControlName="aadhar_number"
                [ngClass]="{ 'is-invalid': (submitted || f.aadhar_number.dirty || f.aadhar_number.touched) &&  f.aadhar_number.errors} ">
              <div
                *ngIf="(submitted || f.aadhar_number.dirty || f.aadhar_number.touched) && f.aadhar_number.errors"
                class="invalid-feedback">
                <div *ngIf="f.aadhar_number.errors?.required">{{this.messageService.validationDisplay('required')}}
                </div>

              </div>
              <div *ngIf="(submitted || f.aadhar_number.dirty) && f.aadhar_number.errors" class="invalid-feedback">
                <div *ngIf="f.aadhar_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="passport_number" class="form-label  ">Passport no. </label>
              <input type="text" class=" form-control" name="" id="passport_number"
                placeholder="{{this.messageService.placeholderdisp('passport no')}}" formControlName="passport_number"
                [ngClass]="{ 'is-invalid': (submitted || f.passport_number.dirty) &&  f.passport_number.errors} || f.passport_number.errors?.exist"
                (keyup)="keyUp('passport_number',f.passport_number.value)">
              <div
                *ngIf="(submitted || f.passport_number.dirty) && f.passport_number.errors || f.passport_number.errors?.exist"
                class="invalid-feedback">
                <div *ngIf="f.passport_number.errors?.pattern ">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="f.passport_number.hasError('exist')">
                  {{this.messageService.validationDisplay(f.passport_number.value)}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="passport_expiry_date" class="form-label">Passport expiry date</label>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.passport_expiry_date.dirty || f.passport_expiry_date.touched) &&  f.passport_expiry_date.errors}">
                <input (click)="datePicker.open()" class="form-control"
                  placeholder="{{this.messageService.selectplaceholddisplay('passport expiry date')}}" matInput
                  formControlName="passport_expiry_date" [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker"
                  (keydown)="disableDate()">
                <i class="icon-calendar pointer" (click)="datePicker.open()"></i>
                <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
              </div>
              <div
                *ngIf="(submitted || f.passport_expiry_date.dirty || f.passport_expiry_date.touched) &&  f.passport_expiry_date.errors"
                class="invalid-feedback">
                <div
                  *ngIf="(submitted || f.passport_expiry_date.dirty || f.passport_expiry_date.touched) &&  f.passport_expiry_date.errors">
                  {{this.messageService.validationDisplay('pattern')}}</div>

              </div>


            </div>
            <div class="col-sm-6 form-row">
              <div class="textarea-content">
                <label for="personal_email" class="form-label">Personal email</label>
                <input type="text" class=" form-control" name="" id="personal_email"
                  placeholder="{{this.messageService.placeholderdisp('personal email')}}"
                  formControlName="personal_email"
                  [ngClass]="{ 'is-invalid': (submitted || f.personal_email.dirty || f.personal_email.touched) &&  f.personal_email.errors} || f.personal_email.errors?.exist"
                  (keyup)="keyUp('personal_email',f.personal_email.value)">
                <div
                  *ngIf="(submitted || f.personal_email.dirty || f.personal_email.touched) && f.personal_email.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.personal_email.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>

                  <div *ngIf="f.personal_email.hasError('exist')">
                    {{this.messageService.validationDisplay(f.personal_email.value)}}</div>
                </div>
              </div>

            </div>
            <div class="col-sm-6 form-row">
              <label for="emergency_contact_name" class="form-label  ">Emergency contact name</label>
              <input type="text" class=" form-control" name="" id="emergency_contact_name"
                placeholder="{{this.messageService.placeholderdisp('emergency contact name')}}"
                formControlName="emergency_contact_name"
                [ngClass]="{ 'is-invalid': (submitted || f.emergency_contact_name.dirty) &&  f.emergency_contact_name.errors}">
              <div *ngIf="(submitted || f.emergency_contact_name.dirty) && f.emergency_contact_name.errors"
                class="invalid-feedback">
                <div *ngIf="f.emergency_contact_name.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.emergency_contact_name.errors.pattern) && f.emergency_contact_name.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="emergency_contact_number" class="form-label  ">Emergency contact no.</label>
              <div class="d-flex form-ngselect-input"
                [ngClass]="{ 'is-invalid': hasEmergencyContactError() }">
                <app-master-select
                        [ngClass]="{ 'is-invalid': hasEmergencyContactError() }"
                        [invalidClass]="{ 'is-invalid': hasEmergencyContactError() }"
                        [isrequired]='"true"' [placeholder]="''" id="phone_code" [submitted]="submitted" (selectedValue)="phnNumSelected($event)"
                        [selectedItem]="f.phone_code.value" formControlName="phone_code" [type]='"telephone_code"'></app-master-select>
                <input type="text" class=" form-control" name=""
                  id="emergency_contact_number"
                  placeholder="{{this.messageService.placeholderdisp('emergency contact no')}}"
                  formControlName="emergency_contact_number"
                  [ngClass]="{ 'is-invalid': hasEmergencyContactError() }">

              </div>
              <div *ngIf="(submitted ) && f.phone_code.errors" class="invalid-feedback">
                <div *ngIf="f.phone_code.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
              <div *ngIf="hasEmergencyContactError()"
                class="invalid-feedback">
                <div *ngIf="f.emergency_contact_number.errors && (f.emergency_contact_number?.errors?.pattern || f.emergency_contact_number?.errors?.minlength ||  f.emergency_contact_number?.errors?.maxlength)">
                  {{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="relation" class="form-label">Relation</label>
              <input type="text" class=" form-control" name="" id="relation"
                placeholder="{{this.messageService.placeholderdisp('relation')}}" formControlName="relation"
                [ngClass]="{ 'is-invalid': (submitted || f.relation.dirty) &&  f.relation.errors}">
              <div *ngIf="(submitted || f.relation.dirty) && f.relation.errors" class="invalid-feedback">
                <div *ngIf="f.relation.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.relation.errors.pattern) && f.relation.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
          </div>
          <p class="text-uppercase fw-500 fs-16 mt-32">Permanent Address</p>
          <div class="row row-24 ">
            <div class="col-12 form-row ">
              <label for="permanent_address" class="form-label">Permanent address</label>
              <textarea rows="4" type="text" class=" form-control " name="" id="permanent_address"
                formControlName="permanent_address"
                placeholder="{{this.messageService.placeholderdisp('Permanent Address')}}"
                [ngClass]="{ 'is-invalid': (submitted || f.permanent_address.dirty || f.permanent_address.touched) &&  f.permanent_address.errors}">
                  </textarea>
              <div
                *ngIf="(submitted || f.permanent_address.dirty || f.permanent_address.touched) && f.permanent_address.errors"
                class="invalid-feedback">

                <div *ngIf="!(f.permanent_address.errors?.pattern) && f.permanent_address.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}</div>

              </div>
            </div>


            <div class="col-sm-6 form-row">
              <label for="permanent_country" class="form-label">Permanent country </label>
              <ng-select class="form-ngselect"
                placeholder="{{this.messageService.selectplaceholddisplay('Permanent Country')}}"
                (change)="permenentStateList()" formControlName="permanent_country"
                [ngClass]="{ 'is-invalid': (submitted || f.permanent_country.touched) && f.permanent_country.errors }">
                <ng-option *ngFor="let li of country_list" [value]="li.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(submitted || f.permanent_country.touched) && f.permanent_country.errors"
                class="invalid-feedback">

              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="permanent_state" class="form-label">Permanent state </label>
              <ng-select class="form-ngselect" (change)="permenentCity()"
                placeholder="{{this.messageService.selectplaceholddisplay('Permanent State')}}"
                formControlName="permanent_state" name="" id="permanent_state"
                [ngClass]="{ 'is-invalid': (submitted || f.permanent_state.touched) && f.permanent_state.errors }">
                <ng-option *ngFor="let li of permenent_state_list" [value]="li.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(submitted || f.permanent_state.touched) && f.permanent_state.errors"
                class="invalid-feedback">

              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="permanent_city" class="form-label">Permanent city </label>
              <ng-select class="form-ngselect" formControlName="permanent_city" (change)="currentValueChange()" name=""
                placeholder="{{this.messageService.selectplaceholddisplay('Permanent City')}}" id="permanent_city"
                [ngClass]="{ 'is-invalid': (submitted || f.permanent_city.touched) && f.permanent_city.errors }">
                <ng-option *ngFor="let li of permanent_city" [value]="li.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(submitted || f.permanent_city.touched) && f.permanent_city.errors" class="invalid-feedback">

              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="permanent_pin" class="form-label">Permanent pincode </label>
              <input type="text" class=" form-control" name="" id="permanent_pin"
                placeholder="{{this.messageService.placeholderdisp('Permanent Pincode')}}"
                formControlName="permanent_pin"
                [ngClass]="{ 'is-invalid': (submitted || f.permanent_pin.dirty || f.permanent_pin.touched) &&  f.permanent_pin.errors}"
                (ngModelChange)="currentValueChange()">
              <div *ngIf="(submitted || f.permanent_pin.dirty || f.permanent_pin.touched) && f.permanent_pin.errors"
                class="invalid-feedback">

              </div>
              <div *ngIf="(submitted || f.permanent_pin.dirty || f.permanent_pin.touched) && f.permanent_pin.errors"
                class="invalid-feedback">
                <div *ngIf="(f.permanent_pin.errors.pattern || f.permanent_pin.errors.maxlength)">
                  {{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="" id="address_current_same"
                  formControlName="address_current_same" (change)="checkValue($event)">
                <label for="address_current_same" class="form-check-label ">Current address is same as permanent
                  address</label>

              </div>
            </div>
            <div class="col-12 form-row ">
              <label for="current_address" class="form-label">Current address</label>
              <textarea rows="4" type="text" class=" form-control" name="" id="current_address"
                placeholder="{{this.messageService.placeholderdisp('Current Address')}}"
                formControlName="current_address"
                [ngClass]="{ 'is-invalid': (checkedbox || submitted || f.current_address.dirty || f.current_address.touched) &&  f.current_address.errors}">
                  </textarea>
              <div
                *ngIf="(checkedbox || submitted || f.current_address.dirty || f.current_address.touched) && f.current_address.errors"
                class="invalid-feedback">

                <div *ngIf="!(f.current_address.errors?.pattern) && f.current_address.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}</div>

              </div>
            </div>

            <div class="col-sm-6 form-row">
              <label for="current_country" class="form-label">Current country </label>
              <ng-select class="form-ngselect" (change)="currentStateList()" formControlName="current_country"
                placeholder="{{this.messageService.selectplaceholddisplay('Current Country')}}"
                [ngClass]="{ 'is-invalid': (checkedbox || submitted || f.current_country.touched) && f.current_country.errors }">
                <ng-option *ngFor="let li of country_list" [value]="li.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(checkedbox || submitted || f.current_country.touched) && f.current_country.errors"
                class="invalid-feedback">

              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="current_state" class="form-label">Current state</label>
              <ng-select class="form-ngselect" (change)="currentCity()" formControlName="current_state"
                placeholder="{{this.messageService.selectplaceholddisplay('Current State')}}" name="" id="current_state"
                [ngClass]="{ 'is-invalid': (checkedbox || submitted || f.current_state.touched) && f.current_state.errors }">
                <ng-option *ngFor="let li of current_state_list" [value]="li.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(checkedbox || submitted || f.current_state.touched) && f.current_state.errors"
                class="invalid-feedback">

              </div>
            </div>
            <div class="col-sm-6 form-row">
              <label for="current_city" class="form-label">Current city </label>
              <ng-select class="form-ngselect" formControlName="current_city" name="" id="current_city"
                placeholder="{{this.messageService.selectplaceholddisplay('Current City')}}"
                [ngClass]="{ 'is-invalid': (checkedbox || submitted || f.current_city.touched) && f.current_city.errors }">
                <ng-option *ngFor="let li of city_list" [value]="li.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(checkedbox || submitted || f.current_city.touched) && f.current_city.errors"
                class="invalid-feedback">

              </div>
            </div>

            <div class="col-sm-6 form-row">
              <label for="current_pin" class="form-label">Current pincode </label>
              <input type="text" class=" form-control" name="" id="current_pin"
                placeholder="{{this.messageService.placeholderdisp('Current Pincode')}}" formControlName="current_pin"
                [ngClass]="{ 'is-invalid': (checkedbox || submitted || f.current_pin.dirty || f.current_pin.touched) &&  f.current_pin.errors}">
              <div
                *ngIf="(checkedbox || submitted || f.current_pin.dirty || f.current_pin.touched) && f.current_pin.errors"
                class="invalid-feedback">

              </div>
              <div
                *ngIf="(checkedbox || submitted || f.current_pin.dirty || f.current_pin.touched) && f.current_pin.errors"
                class="invalid-feedback">
                <div *ngIf="(f.current_pin.errors.pattern || f.current_pin.errors.maxlength)">
                  {{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer gap-32" *ngIf="!loader1">
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="saveClicked || personalForm.invalid">Save</button>
    </footer>
  </form>
</div>
<!-- slider section end -->

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- InfoPopup end -->
