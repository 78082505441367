import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { HolidayPolicyService } from 'src/app/@core/services/holiday-policy.service';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-hp-mapping',
  templateUrl: './hp-mapping.component.html',
  styleUrls: ['./hp-mapping.component.scss']
})

export class HpMappingComponent implements OnInit {

   holidayDropdown:any=[];
  @Input()submitted:boolean=false;
  @Input()disabled = false;
  @Output()submitmethod = new EventEmitter();
  @Input()formGp : any;
  @Input()previousYear : any;
  show_floating = false;
  @Output() validFn = new EventEmitter();

  constructor(
    public fb:FormBuilder,
    private hps: HolidayPolicyService,
    public messageService : MessageService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  
   if(this.formGp.controls.policydetails.value.is_floating_holiday ==false){
    this.show_floating = false
   }
   else{
    this.show_floating = true
   }

  this.hps.holidayList(this.formGp.controls.policydetails.getRawValue().year,this.formGp.controls.policydetails.getRawValue().is_floating_holiday).subscribe((res: any) => {
    this.holidayDropdown = res;
    if (this.holidayDropdown.length != 0) {
      this.selectAllForDropdownItems(this.holidayDropdown);
    }
  });
  }

 get f() { return this.formGp.controls; }

  validateHolidayPolicy() {
    this.submitted = true;
    if (!this.formGp?.value?.holiday_policy) {
      return;
    }
    if(this.formGp?.value?.holiday_policy?.length >= this.formGp?.controls?.policydetails?.getRawValue()?.max_floating_request){
    this.submitmethod.emit();
    this.validFn.emit(false)
    }
    else{
      this.notificationService.handleErrorNotification("The number of Floating holidays allocated should be equal to or greater than the Maximum floating request count.","Error");
      this.validFn.emit(true)
    }
  }

  clear(index:number){
    let daata = this.formGp?.value?.holiday_policy;
    daata.splice(index, 1);
    this.formGp.patchValue({
      holiday_policy: daata
    });
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
