import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-asset-settings-setup-configuration',
  templateUrl: './asset-settings-setup-configuration.component.html',
  styleUrls: ['./asset-settings-setup-configuration.component.scss']
})
export class AssetSettingsSetupConfigurationComponent implements OnInit {
  @Output() submitmethod  = new EventEmitter();
  @Input() settingsForm: any;
  @Input() selectedComp: any;
  @Input() editCompId: any;
  @Input() SettingsArray:any
  saveClicked   = false;
  constructor(
    public messageService: MessageService,
    public appService: AppService,
  ) { }

  ngOnInit(): void {
    console.log("SettingsArray",this.SettingsArray)
    console.log("SettingsArray control",this.SettingsArray.controls)
  }

  submitMethod() {

    if (this.settingsForm.invalid) {
      return;
    }
    this.saveClicked = true
    this.submitmethod.emit();
  }
}
