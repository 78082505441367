import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { ConfirmationPolicyService } from 'src/app/@core/services/confirmation-policy.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-confirmation-policy',
  templateUrl: './confirmation-policy.component.html',
  styleUrls: ['./confirmation-policy.component.scss']
})
export class ConfirmationPolicyComponent implements OnInit {

  confirmationPolicyView : boolean = false;
  permissions            : any
  policyDataList         : any     = []
  policyData             : any     = []
  searchlistData         : any     = []
  statusBtn              : any     = 'Active';
  status                 : boolean = true;
  loader                 : boolean = true
  noData                 : boolean = false
  infinityLoader         : boolean = false;
  deleteAlert            : boolean = false;
  deleteClicked          : boolean = false;
  vLoader                : boolean = false;
  vData                  : any
  isLoading = false
  searchData : Subject<void> = new Subject();

  searchKeyword  = ''
  listlength     = 0;
  direction      = '';
  defaultLimit   = 20;
  offset         = 0
  deleteId       = 0;

  @ViewChild(SearchbarComponent) child:any;

  constructor(
    public appService  : AppService,
    public confirmServ : ConfirmationPolicyService,
    private router     : Router,
    public msgServ     : MessageService
  ) { }

  ngOnInit(): void {
    // Permission
    this.getPermission();
    // Landing Screen Data
    this.getLandingScreenData()
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.confirmServ.getPolicyList(this.status,this.defaultLimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  // Landing Screen API
  getLandingScreenData(){
    this.confirmServ.getPolicyList(this.status,this.defaultLimit,this.offset,this.searchKeyword).subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res?.results?.length;
    if(this.offset ==0){
      this.policyData = [];
    }
    if (res?.results?.length == 0) {
      this.noData = this.offset == 0 ? true :false;
    }else {
      this.noData = false;
      this.policyDataList = res.results;
    }
    this.infinityLoader = false;
    this.addItems();
    this.loader = false;
  }

  addItems() {
    for (let i = 0; i < this.listlength; ++i) {
      this.policyData.push(this.policyDataList[i]);
    }
  }

  // Search
  searchResults(data:any){
    this.loader = true
    this.offset = 0
    this.policyData = []
    if(data == ''){
      this.searchKeyword = ""
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchPolicy(data:any){
    this.loader = true
    this.isLoading = true
    this.offset = 0
    this.policyData = []
    this.searchKeyword = data
    if(this.searchKeyword != ''){
      this.searchData.next()
    }
  }

  // Status Filter
  statusfilter(active_status:any){
    this.status = active_status;
    this.policyData  = [];
    this.offset = 0;
    this.child.clearBar();
  }

  // No Data Screen ADD button
  addNewdata(){
    this.router.navigate(['/confirmation-policy/confirmation-policy-setup']);
  }

  // Scroll Down
  onScrollDown() {
    this.infinityLoader = true;
    this.offset += this.defaultLimit;
    if(this.listlength){
      this.getLandingScreenData();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityLoader = false;
    }
  }

  assignees : any = []
  viewSidePanel(id:any){
    this.confirmationPolicyView = true;
    this.vLoader = true
    let appkey1 = []
    this.assignees = []
    this.confirmServ.viewPolicy(id).subscribe((res:any)=>{
      this.vData = res
      this.vData.applicable = this.vData?.confirmation_policy_applicablity[0]?.applicable
      this.vData.applicableTo = []
      for(let key of res.confirmation_policy_applicablity){
        let appkey = key.applicable.toLowerCase();
        if(appkey == "bu"){
          appkey = "businessunit";
          key.applicable ="BU"
        }
        appkey1 = appkey.toString();
        if(appkey=="employee"){
          let empName = key[appkey1]['first_name']+(key[appkey1]['middle_name']!=null && key[appkey1]['middle_name']!=""?" " +key[appkey1]['middle_name']:"")+" "+key[appkey1]['last_name'] +" ("+key[appkey1]['employee_code']+")";
          let empImg  = key[appkey1]['profile_image'];
          let color_code  = key[appkey1]['color_code'];
          this.assignees.push({'name': empName,'img': empImg, 'color_code':color_code})
        }else if(appkey=="company"){
          let compName = key.company;
          let compImg  = key.company_logo;
          this.assignees.push({'name': compName,'img': compImg})
        }else{
          this.assignees.push(key[appkey1]);
        }
      }
      this.vLoader = false
      });
  }

  editPolicy(id:any){
    this.router.navigate(['/confirmation-policy/confirmation-policy-setup/'+id]);
  }

  deletePolicy(id:number){
    this.deleteClicked =true
    let data = { "is_active": false};
    this.confirmServ.deletePolicy(id,data).subscribe((res: any) => {
      this.deleteAlert      = false;
      this.loader           = true;
      this.offset           = 0;
      this.policyData  = [];
      this.getLandingScreenData();
      this.deleteClicked =false
    },
    (error:any)=>{
      this.deleteClicked = false
      this.deleteAlert   = false;
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/confirmation-policy');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
