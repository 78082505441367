import { Component, OnInit,Output,EventEmitter,ChangeDetectorRef} from '@angular/core';
import { AppService } from 'src/app/app.global';
import * as XLSX from 'xlsx';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MessageService } from 'src/app/message.global';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { FileUploadService } from 'src/app/@core/services/file-upload.service';

@Component({
  selector: 'app-prior-payroll-details',
  templateUrl: './prior-payroll-details.component.html',
  styleUrls: ['./prior-payroll-details.component.scss']
})
export class PriorPayrollDetailsComponent implements OnInit {
  activeTab2          = 1;
  page                = 1;
  pageSize            = 20;
  showSidePanel       = false;
  fileName: string    = '';
  filter              = false;
  fileSize: string    = '';
  selectMonth: string = '';
  selectYear: string  = '';
  invalidFormat       = false;
  ws:any              = null;
  excelData:any       = [];
  errorToggle         = false;
  errorMsg            = "";
  importData:any      = [];
  importTemp:any      = [];
  MonthlyData:any     = [];
  failedData:any      = [];
  header:any          = [];
  downloadMsg         = false;
  searchString:any    = "";
  successDataCount    = 0;
  isProgressStart     = false;
  chunkLen            = 10;
  percentage:any      = 0;
  isDataUpload        = 0;
  iscompleted         = false;
  hasData             = false;
  monthLoader         = true;
  payComponent:any    = [] ;
  isDisable           = false;
  deleteHead          = '';
  deleteClicked:any   = false;
  confirmMsg          = '';
  buttonText          = '';
  source              = '';
  companyList:any     = [];
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  nodataFound         = false;
  loader              = false;
  detailedPanel       = false;
  isClosed            = false;
  errorMsgs = ''
  successMsg = ''
  exportData:any      = [];
  detailedView:any;
  excelMonth : any;
  month:any;
  year:any;
  selectedmonth:any;
  selectedyear:any;
  company:any;
  monthlySumm:any;
  headerRow:any;
  currentMonth:any;
  data_url:any;
  currentDocName:any
  currentEvent:any
  excelTemp:any       = [];
  successEmployee:any = [];




  @Output() nextPage = new EventEmitter();
  @Output() stepper  = new EventEmitter();

  constructor(
    public appService: AppService,
    public salaryService: SalaryDashboardService,
    public route:ActivatedRoute,
    private notificationService: NotificationService,
    public messageService : MessageService,
    public payComponentService:PayComponentService,
    public router:Router,
    private _cdr: ChangeDetectorRef,
    private upload: FileUploadService,


  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month        = params['month'];
        this.currentMonth = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      this.getMonthlyData();
    })
    this.getPaycomponenet();
    this.getCompanyDropdown();

  }
  getCompanyDropdown(){
    this.salaryService.companyDropDownData()
    .subscribe((res: any) => {
      if (res.status == 200) {
        if(res.body.length == 0){
          this.nodataFound = true;
          this.loader      = false;
          this._cdr.detectChanges();
        } else {
          this.companyList = res.body;
          if(this.company==''){
            this.company =  this.company==''?res.body[0]['id']:this.company;

          }
        }
      }
    })
  }
  getMonthlyData(){
    this.hasData  = false;
    this.salaryService.getPriorMonthlyData(this.company,this.year).subscribe((res:any)=>{
      this.MonthlyData      = res.body.data;

      for(let i=0;i<this.MonthlyData.length;i++){
        this.MonthlyData[i]['formattedName']  = "";
        if(this.MonthlyData[i]['fileurl']!=null && this.MonthlyData[i]['fileurl']!=''){
          this.MonthlyData[i]['formattedName'] = this.getDocName(this.MonthlyData[i]['fileurl']);
        }

        if(this.MonthlyData[i]['status']=='UPLOADED'){
          this.hasData  = true;
          break;
        }
      }
      this.stepper.emit(this.hasData);
      this.monthLoader  = false;

    })
  }
  getPaycomponenet(){

    this.payComponentService.getPayComponentList(true,false).subscribe((res:any)=>{
      for(let i=0;i<res.length;i++){
        this.payComponent.push(res[i]['name'])
      }

    })
  }
  onFileChange(evt: any) {
    this.currentEvent = evt;
    this.excelData  = [];
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      const file = evt.target.files[0];
      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string    = wb.SheetNames[0];
        this.ws                 = wb.Sheets[wsname];
        var ws                  = wb.Sheets[wsname];
        this.fileName           = target.files[0].name;
        this.fileSize           = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData          = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }
  uploadAzure(event:any){
    const file = event.target.files[0];
    const reader = new FileReader();
    const target: DataTransfer = <DataTransfer>(event.target);
    const extension     = target.files[0].name.split('.').pop();

    reader.readAsDataURL(file);

    if(extension=='xlsx' || extension=='csv'){

      reader.onload = event => {
        this.upload.fileUpload({name:target.files[0].name, dataURL:reader.result}).subscribe((res: any) => {

          if(res.status == 200) {
            this.data_url = res.body.data_url;

          }
        })
      };
    }
    else{
      this.invalidFormat = true;
    }
  }
  processData(){
    if(this.excelData.length <=1){
      this.errorToggle = true;
      this.errorMsg    = "No data found in the uploaded file.";
      this.clearData();

    } else {
      let fixedHeader:any ;
      var headerRow      = this.excelData[0];
      var allComp        = this.payComponent;
      let index:any      = 0;
      var compHeader:any = [];
      fixedHeader        = ['Employee code','Employee name','Salary month','Salary year','Total workdays','Paid days','LOP reversal Days','Revision arrear days','Income Tax','Surcharge','Cess','Professional Tax','PF wage','Employee PF Contribution','EPF Contribution employer','EPS Contribution Employer','ESI gross','ESI','ESI Employer','Labour Welfare Fund','Labour Welfare Fund Employer','Gross pay']

      let success        = fixedHeader.every(function(val:any) {
      if(val=='Income Tax'){
        index  = headerRow.indexOf(val);
        for(let i=8;i<index;i++){
          compHeader.push(headerRow[i].trim());
        }
      }

      return headerRow.indexOf(val) !== -1;
      });


      let compValid    = compHeader.every(function(val:any) {
        let splittedString:any;
        if(allComp.indexOf(val)==-1){
          if(val.includes('Arrear')){
            splittedString = val.split("Arrear", 1);
            splittedString = splittedString[0].trim();
            return allComp.indexOf(splittedString) !== -1;

          }
          else if(val.includes('Reversal')){
            splittedString = val.split("Reversal", 1);
            splittedString = splittedString[0].trim();
            return allComp.indexOf(splittedString) !== -1;

          }
          else if(val.includes('Exemption')){
            splittedString = val.split("Exemption", 1);
            splittedString = splittedString[0].trim();
            return allComp.indexOf(splittedString) !== -1;

          }
          else if(val.includes('Approved')){
            splittedString = val.split("Approved", 1);
            splittedString = splittedString[0].trim();
            return allComp.indexOf(splittedString) !== -1;

          }
          else if(val.includes('Recovery')){
            splittedString = val.split("Recovery", 1);
            splittedString = splittedString[0].trim();
            return allComp.indexOf(splittedString) !== -1;

          }
          if(val.includes('Regular')){
            splittedString = val.split("Regular", 1);
            splittedString = splittedString[0].trim();
            return allComp.indexOf(splittedString) !== -1;

          }
        }
        return allComp.indexOf(val) !== -1;
      });

      if(success == false || compValid==false){
        this.errorToggle = true;

        this.errorMsg    = success == false?this.messageService.invalidExcelFormat():"Trying to import pay component which does not exist in the system";
        this.clearData();
      }

      else
      this.prepareUploadData();
    }
  }
  prepareUploadData(){
    this.importData = [];
    this.importTemp = [];
    this.header     = [];
    this.headerRow  = '';
    this.chunkLen   = 10;
    this.percentage = 0;

    this.headerRow  = this.excelData[0];
    for(let i=0;i<this.headerRow.length;i++){
      switch(this.headerRow[i]){
        case 'Employee code':{
          this.header.push('employee');
          break;
        }
        case 'Employee name':{
          this.header.push('emp_name');
          break;
        }
        case 'Salary month':{
          this.header.push('pay_month');
          break;
        }
        case 'Salary year':{
          this.header.push('pay_year');
          break;
        }
        case 'Total workdays':{
          this.header.push('work_day');
          break;
        }case 'Paid days':{
          this.header.push('paid_day');
          break;
        }case 'Salary year':{
          this.header.push('pay_year');
          break;
        }case 'LOP reversal Days':{
          this.header.push('lop_reversal_days');
          break;
        }case 'Revision arrear days':{
          this.header.push('revision_arrear_days');
          break;
        }case 'Income Tax':{
          this.header.push('incometax');
          break;
        }
        case 'Surcharge':{
          this.header.push('surcharge');
          break;
        }case 'Cess':{
          this.header.push('cess');
          break;
        }case 'Professional Tax':{
          this.header.push('PT');
          break;
        }case 'PF wage':{
          this.header.push('PF_wage');
          break;
        }case 'EPS Contribution Employer':{
          this.header.push('employer_EPScontibution');
          break;
        }case 'EPF Contribution employer':{
          this.header.push('employer_EPFcontibution');
          break;
        }
        case 'Employee PF Contribution':{
          this.header.push('employee_PF');
          break;

        }
        case 'ESI gross':{
          this.header.push('ESI_gross');
          break;
        }case 'ESI':{
          this.header.push('ESI');
          break;
        }case 'ESI Employer':{
          this.header.push('ESI_employer');
          break;
        }
        case 'Labour Welfare Fund':{
          this.header.push('LWF');
          break;
        }case 'Labour Welfare Fund Employer':{
          this.header.push('LWF_employer');
          break;
        }
        case 'Gross pay':{
          this.header.push('salary');
          break;
        }
        // case 'Total Deduction':{
        //   this.header.push('tot_deduct');
        //   break;
        // }
        // case 'Net pay':{
        //   this.header.push('net_pay');
        //   break;
        // }
        default:{
          this.header.push(this.headerRow[i]);
          break;
        }
      }

    }

    this.excelData.forEach((item:any, key:any) => {

      if(key != 0){
        let k = item[0];

        this.excelMonth = item[2]
        if(item[2]!=undefined){
        // }else{

          //  = this.toMonthName(item[2])

          let convertedMonth      = this.month.replace(/\s/g, '').toUpperCase();
          this.excelMonth         = this.excelMonth.replace(/\s/g, '').toUpperCase();

          if(convertedMonth === this.excelMonth && this.year == item[3]){
            var dict:any = {};
            for(let i=0;i<this.header.length;i++){
              dict[this.header[i]]= item[i];
            }

            this.importTemp.push({
              dict
            })


          }
        }
      }

    })
    for(let j=0;j<this.importTemp.length;j++){
      this.importData.push(this.importTemp[j]['dict'])
    }

    if(this.importData.length <=0){
      this.errorToggle = true;
      this.errorMsg = "The uploaded file does not contain any data for "+this.month+" "+this.year;
      this.clearData();

    }

  }
  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
    this.iscompleted    = false;
    this.deleteHead     = '';
    this.buttonText     = '';
    this.source         = '';
    this.deleteClicked  = false;
    this.isDisable      = false;
    this.confirmMsg     = '';

  }
  uploadPrior(){
    this.isDataUpload     = 1;
    this.isProgressStart  = true
    this.failedData       = []
    var l                 = this.importData.length;
    var cLoop             = Math.ceil(l/this.chunkLen)
    var p                 = 100/cLoop
    this.successDataCount = 0
    this.uploadasChunks(0,p);

  }
  uploadasChunks(k:any,p:any){
    var l           = this.importData.length;
    var x           = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any   = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.importData[i])
    }

    const file = this.currentEvent.target.files[0];
    const reader = new FileReader();
    const target: DataTransfer = <DataTransfer>(this.currentEvent.target);
    const extension     = target.files[0].name.split('.').pop();

    reader.readAsDataURL(file);

    if(extension=='xlsx' || extension=='csv'){

      reader.onload = event => {

        this.upload.fileUpload({name:target.files[0].name, dataURL:reader.result}).subscribe((res: any) => {

          if(res.status == 200) {
            this.data_url = res.body.data_url;
            let  data = [{
              "filename"  :this.fileName,
              "pay_year"  :this.year,
              "pay_month" :this.month,
              "company"   :this.company,
              "data"      :saveDataArray,
              "fileurl"   :this.data_url

            }]
              this.salaryService.uploadPriorPayroll({"datas" :  data}).subscribe((res:any)=>{


                let response = res.body.message[0];
                if(response.failed_data != undefined && response.failed_data.length != 0){
                  response.failed_data.forEach((i:any, k:any) => {
                        this.failedData.push(i)
                    });
                }
                for(let k=0;k<response.employee_codes_array.length;k++){
                  this.successEmployee.push(response.employee_codes_array[k])
                }


                this.percentage +=((p/4)*3);
                if(x<l)
                    this.uploadasChunks(k+1,p);
                else{
                  this.iscompleted      = true
                  this.isProgressStart  = false
                  const uniqueData      = new Set(this.successEmployee);
                  this.successDataCount = uniqueData.size

                  if(this.failedData.length == 0){
                    this.percentage = 0
                    this.notificationService.handleSuccessNotification("Prior payroll data for "+ this.successDataCount +" employee(s) was successfully uploaded.","Success");
                    this.clearData();
                    this.getMonthlyData();
                    this.showSidePanel = false;

                  } else {
                    this.exportData = [];
                    let excelTemp      = [];

                    for (let i = 0; i < this.failedData.length; i++) {
                      var dict:any = {};

                      for(let j=0;j<this.header.length;j++){

                        let failVal               = this.header[j]
                        dict[this.headerRow[j]]   = this.failedData[i]['data'][failVal];
                        if(j==this.header.length-1)
                        dict['Error description']  = this.failedData[i]['error'];
                      }

                      excelTemp.push({
                        dict
                      })
                    }

                    for(let j=0;j<excelTemp.length;j++){
                      this.exportData.push(excelTemp[j]['dict'])
                    }
                    this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                    this.errorMsg   = this.failedData.length+' employee(s) have error data'
                    this.getMonthlyData();
                    this.downloadMsg = true;
                  }

                }
              },
              (error:any)=>{
                this.isProgressStart = false
                this.notificationService.handleErrorNotification('Something went wrong','Error')
              }
              )
          }
        })
      };
    }

  }
  downloadError(){

    let exportData:any = [];
    let excelTemp      = [];

    for (let i = 0; i < this.failedData.length; i++) {
      var dict:any = {};

      for(let j=0;j<this.header.length;j++){

        let failVal               = this.header[j]
        dict[this.headerRow[j]]   = this.failedData[i]['data'][failVal];
        if(j==this.header.length-1)
        dict['Error description']  = this.failedData[i]['error'];
      }

      excelTemp.push({
        dict
      })
    }

    for(let j=0;j<excelTemp.length;j++){
      exportData.push(excelTemp[j]['dict'])
    }
    this.appService.exportExcel(exportData,'Prior payroll error');
    this.downloadMsg = false;
    this.clearData();
    this.getMonthlyData();
    this.showSidePanel = false;


  }
  toMonthName(monthNumber:any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }
  nextpage(){
    this.nextPage.emit();
  }
  getMonthlySummary(month:any,year:any,status:any,filter:any){
    if(filter==false)
      this.resetFilter();
    // if(status=='UPLOADED' || status=='COMPLETED'){
      this.detailedView   = false;
      this.detailedPanel  = true;
      this.selectedmonth = month;
      this.selectedyear  = year;
      this.salaryService.getPriorSummary(this.company,year,month,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{
        if(res.body.length != 0)
        this.monthlySumm  = res?.body?.data[0];
        this.detailedView = true;
      })
    // }
  }
  deletePrior(){
    this.deleteClicked = true;
    if(this.source=='disable'){
      this.salaryService.disablePriorPayroll(this.company,this.year).subscribe((res:any)=>{
        if(res.status==1)
        this.notificationService.handleSuccessNotification("Disabled prior payroll Successfully","Success");
        this.router.navigateByUrl("/run-payroll/"+this.year+"/"+this.currentMonth+"/"+this.company)

      })
    }
    else{
      this.salaryService.deleteUploadedPayroll(this.company,this.year,this.source).subscribe((res:any)=>{

        if(res.status==1)
        this.notificationService.handleSuccessNotification("Deleted Successfully","Success");
        this.getMonthlyData();
        this.clearData();


      })
    }

  }
  confirmDelete(type:any){

    if(type=='all'){
      this.deleteHead = 'Disable Prior payroll?'
      this.buttonText = 'Disable';
      this.source     = 'disable';
      this.confirmMsg = "This will disable the prior payroll from processing.To disable the prior payroll processing, click Disable.";

    }
    else{
      this.deleteHead = 'Delete Prior payroll?'
      this.buttonText = 'Delete';
      this.source     = type;
      this.confirmMsg = "Deleting the prior payroll will permanently remove all payroll data for that month. You will not be able to process the salary until the prior payroll is completed."
    }
    this.isDisable = true;
  }

  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList       = []

  }
  filterReset(){
    this.resetFilter();
    this.getMonthlySummary(this.selectedmonth,this.selectedyear,'UPLOADED',true);
    this.filter           = false
  }
  submitForm(data:any){
    this.filter           = false
    this.buList           = data?.buList.join(',')
    this.DepList          = data?.DepList.join(',')
    this.DesignationList  = data?.DesignationList.join(',')
    this.gradeList        = data?.gradeList.join(',')
    this.BranchList       = data?.BranchList.join(',')
    this.getMonthlySummary(this.selectedmonth,this.selectedyear,'UPLOADED',true);

  }
  closeFilter(){
    this.filter  = false
  }
  getDocName(name:any){
    var currentDocName : any    = name.split('_').pop();
    this.currentDocName         = currentDocName.split('.').slice(0, -1);
    this.currentDocName         = decodeURIComponent(this.currentDocName);
    return this.currentDocName;
  }

  reloadPage(){
    this.detailedPanel  = false;
    this.getMonthlyData();

  }

  exportMonthlyPrior(){
    this.salaryService.exportMonthlyPrior(this.selectedmonth,this.selectedyear,this.company).subscribe((res:any)=>{

      let header          = res.body.headers;
      let body            = res.body.data;
      this.excelTemp      = [];
      this.exportData     = [];

        for(let i=0;i<body.length;i++){
          var dict:any = {};
          for(let j=0;j<header.length;j++){
            dict[header[j]] = body[i][header[j]];

          }
          this.excelTemp.push({
            dict
          })

        }

      for(let j=0;j<this.excelTemp.length;j++){
        this.exportData.push(this.excelTemp[j]['dict'])
      }
      this.appService.exportExcel(this.exportData,'Prior Payroll '+this.selectedmonth +" "+this.selectedyear);

    })
  }

}

