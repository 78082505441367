<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='addExpense === true'>
    <form [formGroup]="receiptForm"   class="side-panel-container" autocomplete="off">

        <header class="side-panel-head">
            <h5>{{modelLabel}} Expense</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <app-edit-loader *ngIf="loader" ></app-edit-loader>
              <ng-container *ngIf="!loader" >

                <div class="row row-16 ">
                    <div class="col-12">
                        <p class="text-uppercase fw-500 mb-0">Expense receipt</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check form-check-inline">
                            <input name="receipt-type" type="radio" id="amount" class="form-check-input"
                            [value]="false" formControlName="expense_type" (ngModelChange)="changeExpenseType()" name="expense_type">
                            <label for="amount" class="form-check-label">Amount</label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Select this option, if you want to directly add the claim amount for your expense." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>

                        </div>
                        <div class="form-check form-check-inline">
                            <input name="receipt-type" type="radio" id="mileage" class="form-check-input"
                            [value]="true" formControlName="expense_type" (ngModelChange)="changeExpenseType()"  name="expense_type">
                            <label for="mileage" class="form-check-label">Mileage</label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Select this option if you need to claim the expense based on the number of kilometers traveled." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                        </div>
                    </div>
                    <div class="col-12">
                        <label for="" class="form-label  ">Report</label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Choose the relevant expense report to which you want to map this claim." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                        <ng-select  class="form-ngselect" formControlName="report" name="report"  id="report" placeholder="{{this.messageService.selectplaceholddisplay('report')}}" (ngModelChange)="validateExpenseDate('report')">
                          <ng-option *ngFor="let li of reportDD" [value]="li.id">{{li.name}}</ng-option>
                        </ng-select>

                    </div>
                    <div class="col-12">
                        <label for="" class="form-label required">Expense date </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the date when the expense was incurred." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                        <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': ( f.expense_date.touched) && f.expense_date.errors || f.expense_date.errors?.exceed}">
                          <input (click)="datePicker.open()" class="form-control" placeholder="{{this.messageService.selectplaceholddisplay('Expense Date')}}" matInput formControlName="expense_date"  [matDatepicker]="datePicker" [ngClass]="{ 'is-invalid': ( f.expense_date.touched) && f.expense_date.errors || f.expense_date.errors?.exceed}" (ngModelChange)="validateExpenseDate('datefilter')" [min]="minDate">
                          <i class="icon-calendar pointer" (click)="datePicker.open()" ></i>
                          <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
                        </div>
                        <div *ngIf="(f.expense_date?.touched) && f.expense_date?.errors || f.expense_date.errors?.exceed" class="invalid-feedback">
                          <div *ngIf="(f.expense_date?.touched) &&f.expense_date?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="f.expense_date.hasError('exceed')">{{this.messageService.reportRangeValidation()}}</div>

                        </div>
                    </div>
                    <!-- </div> -->
                    <div class="col-12">
                        <label for="" class="form-label required">Category </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Select the expense category for which you are raising the claim request eg: flight, hotel,accomodation etc." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                        <ng-select  class="form-ngselect" formControlName="category" name="category"  id="category" placeholder="{{this.messageService.selectplaceholddisplay('category')}}" [ngClass]="{ 'is-invalid': (f.category.touched) && f.category.errors || f.category.errors?.exceed }" (change)="changeCategory()">
                          <ng-option *ngFor="let li of categoryDD" [value]="li.id">{{li.name}}</ng-option>
                        </ng-select>
                        <div *ngIf="(f.category.touched) && f.category.errors || f.category.errors?.exceed" class="invalid-feedback">
                          <div *ngIf="f.category.errors?.required">{{this.messageService.validationDisplay('required')}}</div>                                                   
                          <div *ngIf="f.category.hasError('limit')">{{this.messageService.availableFrequency(singleCategory?.claim_limit_period)}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                      <label for="" class="form-label required">{{f.expense_type.value==true?'From location':'Location'}} </label>
                      <span class=" ms-8" *ngIf="f.expense_type.value==true"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the starting location for your trip." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      <span class=" ms-8" *ngIf="f.expense_type.value==false"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the location of your trip" popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      <ng-select [readonly]="!categoryselect"  class="form-ngselect" formControlName="from_location" name="from_location"  id="from_location" placeholder="{{this.messageService.selectplaceholddisplay(f.expense_type.value==true?'From Location':'Location')}}" [ngClass]="{ 'is-invalid': (f.from_location.touched) && f.from_location.errors }" (change)="locationsame()">
                        <ng-option *ngFor="let li of locationDD" [value]="li.id">{{li.name}}</ng-option>
                      </ng-select>
                      <div *ngIf="(f.from_location.touched) && f.from_location.errors" class="invalid-feedback">
                        <div *ngIf="f.from_location.errors.required">{{this.messageService.validationDisplay('required')}}</div>                       
                      </div>
                    </div>
                    <ng-container *ngIf="f.expense_type.value==false">

                        <div class="col-12">
                            <label for="" class="form-label required">Amount </label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the total claim amount" popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                            <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('amount')}}" id="" formControlName="amount" [ngClass]="{ 'is-invalid': ( f.amount.dirty || f.amount.touched)  &&  f.amount.errors}" (keyup)="validateAttachment()">
                            <div *ngIf="( f.amount.touched || f.amount.dirty) && f.amount.errors" class="invalid-feedback">
                              <div *ngIf="f.amount.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.amount.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                              <div *ngIf="!(f.amount.errors?.pattern) && f.amount.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                              <div *ngIf="!(f.amount.errors?.pattern) && (f.amount.errors?.max || f.amount.hasError('exceed')) && singleCategory?.amount_limit_type =='location_dependent_limit'">{{this.messageService.LimitExceededlocation()}}</div>
                              <div *ngIf="!(f.amount.errors?.pattern) && (f.amount.errors?.max || f.amount.hasError('exceed')) && singleCategory?.amount_limit_type =='limit_for_all_location'">{{this.messageService.LimitExceeded()}}</div>
                              <div *ngIf="!(f.amount.errors?.pattern) && f.amount.errors?.min">{{this.messageService.fieldlengthvalidation('minvalue',1)}}</div>                                                         
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="f.expense_type.value==true">
                        <!-- <div class="col-12">
                            <label for="" class="form-label required">From Location </label>
                            <input type="text" name="" class="form-control" placeholder="Enter from location " id="">
                        </div> -->
                        <div class="col-12">
                            <label for="" class="form-label required">To location </label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the ending location or point for your trip." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                            <ng-select  class="form-ngselect" formControlName="to_location" name="to_location"  id="to_location" placeholder="{{this.messageService.selectplaceholddisplay('to location')}}" [ngClass]="{ 'is-invalid': (f.to_location.touched) && f.to_location.errors }" [readonly]="!categoryselect" (change)="locationsame()">
                              <ng-option *ngFor="let li of locationDD" [value]="li.id">{{li.name}}</ng-option>
                            </ng-select>
                            <div *ngIf="(f.to_location.touched) && f.to_location.errors" class="invalid-feedback">
                              <div *ngIf="f.to_location.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.to_location.errors.same">{{this.messageService.sameasvalidation('From location','To location')}}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <label for="" class="form-label required">Distance </label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the distance in kilometer." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                            <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('distance')}}" id="" formControlName="distance" [ngClass]="{ 'is-invalid': ( f.distance.dirty || f.distance.touched)  &&  f.distance.errors}" (keyup)="calculateAmount()">
                            <div *ngIf="( f.distance?.touched || f.distance?.dirty) && f.distance.errors" class="invalid-feedback">
                              <div *ngIf="f.distance.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.distance.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                              <div *ngIf="!(f.distance.errors?.pattern) && f.distance.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                              <div *ngIf="!(f.distance.errors?.pattern) && f.distance.errors?.max">{{this.messageService.availableLimitdistance(singleCategory?.limit_period)}}</div>
                              <div *ngIf="!(f.distance.errors?.pattern) && f.distance.errors?.min">{{this.messageService.fieldlengthvalidation('minvalue',1)}}</div>


                            </div>
                            <span class="fs-12">Rate: {{singleCategory?.rate_per_km}} per km</span>
                        </div>
                        <div class="col-12">
                            <div class="card card-c2 px-24 py-8 gap-8 hstack bg28 border-0">
                                <p class="fs-12 fw-500 mb-0 ">Amount</p>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Based on the kilometers entered the amount is automatically caluclated by the system." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                                <p class="fs-14 fw-600 mb-0  ms-auto text-accent2"> {{f.amount.value | currency : currency}} </p>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-12 form-row">
                        <label for="check" class="form-check p-0 d-flex ">
                            <input type="checkbox" name="check" id="check " class="form-check-input ms-0" formControlName="is_reimbursement">
                            <label for="check " class="form-check-label fs-16">Claim Reimbursement </label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enable this option if you want to claim reimbursement for the expense incurred. By default it is enabled. You can disable it if required." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                        </label>
                    </div>
                    <div class=" col-12 form-row">
                        <label for="" class="form-label {{singleCategory?.is_description?'required':''}}">Description </label>
                        <textarea  [ngClass]="{ 'is-invalid': (f.desicription?.dirty || f.desicription?.touched) &&  f.desicription?.errors}" rows="4" type="text" formControlName="desicription" class=" form-control " name="" id="" placeholder="{{this.messageService.descriptiontext('desc')}}">
                        </textarea>
                        <div *ngIf="(f.desicription?.dirty || f.desicription?.touched) && f.desicription?.errors" class="invalid-feedback">
                          <div *ngIf="f.desicription.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="f.desicription.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>

                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label  {{attachmentRequired?'required':''}}">Add attachments </label>
                        <div class="card card-c2 px-16">
                            <div class="card-body px-0 py-16">
                                <label class="upload-btn " [ngClass]="{ 'is-invalid': (fileError == true)  }">
                                    <div class="upload-text">Upload documents
                                      <span class="text-primary">here
                                        <app-file-upload [crop]="false" [round]="false" [id]="'document'" [width]="504"
                                        (dataURL)="getURL($event)" (fullfile)="getFilename($event)" (loader)="getloader($event)"
                                        [FileType]="'.png,.jpeg,.jpg,.docx,.doc,.dotx,.pdf'" (fileformat)="getfileformat($event)"
                                        (validsize)="getvalidsize($event)"></app-file-upload>
                                      </span>
                                        <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC, IMG</div>
                                    </div>
                                    <input type="file" class="form-control" placeholder="" hidden>
                                </label>
                                <div *ngIf="(fileError == true)" class="invalid-feedback">
                                  <div>{{fileErrorMsg}}</div>
                                </div>
                            </div>
                            <div class="card-body px-0 py-16">
                                <div class="row row-16">
                                    <div class="col-md-6" *ngFor="let item of uploadedFile let i = index">
                                      <div class="card card-c2  bg-secondary">
                                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                          <ng-container *ngIf="uploadedFile[i].url!=''">
                                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appService.getDocName(uploadedFile[i].url)}}</span>
                                            <span class="tips-helper ">{{this.appService.getDocName(uploadedFile[i].url)}}</span>
                                            <div class="d-flex gap-8">
                                              <div class="link-primary" (click)="viewDocument(uploadedFile[i].url)">
                                                <i class="bi bi-eye me-1"></i>View
                                              </div>
                                              <div class="link-primary" (click)="this.appService.downloadFile(uploadedFile[i].url,this.appService.getDocName(uploadedFile[i].url))">
                                                <i class="bi bi-download me-1"></i>Download
                                              </div>
                                            </div>
                                          </div>
                                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="removeSelectedFile(i)"></i>
                                         </ng-container>
                                         <div class="  flex-center text-center"  *ngIf="uploadedFile[i].loader">
                                          <div class="fs-12 text-muted">Loading </div>
                                          <div class="ms-4 dot-pulse"></div>

                                         </div>
                                        </div>
                                      </div>

                                      <!-- <div class="card card-c2 bg-secondary">
                                            <div class="d-flex p-24">
                                                <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                                <ng-container *ngIf="uploadedFile[i].url!=''">

                                                <span class="text-trim fw-500">{{this.appService.getDocName(uploadedFile[i].url)}}</span>
                                                <span class="ms-auto text-accent1 ">{{uploadedFile[i].size}}</span>
                                                <span class="link-danger fw-500 flex-shrink-0" (click)="removeSelectedFile(i)">
                                                  <i class="icon-trash ms-8 "></i></span>


                                                </ng-container>


                                                <div class="  flex-center text-center"  *ngIf="uploadedFile[i].loader">
                                                  <div class="fs-12 text-muted">Loading </div>
                                                  <div class="ms-4 dot-pulse"></div>

                                              </div>
                                            </div>
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
        <footer class="side-panel-footer" *ngIf="!loader">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="saveExpense()" [disabled]="receiptForm.invalid || disablebtn">
                Save
            </button>
        </footer>
      </form>
</div>

<!-- un supported view format alert -->
<app-info-popup *ngIf="alertToggle" (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
