
<div class="container-fluid p-32 form-container" [formGroup]="formGp">
    <p class="text-uppercase fw-500">DEFINE DATA ACCESS</p>
    <div class="row row-16">
        <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">Group name</label>
            <input type="text" autocomplete="off" class="form-control fs-16" formControlName="name" id="name" placeholder="{{this.messageService.placeholderdisp('group name')}}" [ngClass]="{ 'is-invalid': (f.name.dirty || f.name.touched) &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
            <div *ngIf="(f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}
                </div>
            </div>
        </div>
    </div>
    <div class="row row-16 pt-16">
        <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">Module</label>
            <!--Using items input-->
            <ng-select
                    [ngClass]="{ 'is-invalid': f.module.touched && f.module.errors}"
                    placeholder="{{this.messageService.selectplaceholddisplay('modules')}}"
                    formControlName="module"
                    [items]="moduleDropdown"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                          {{ item.name }}
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="formGp.value.module.length">
                              <span class="ng-value-label">{{formGp.value.module.length}} Module selected.</span>
                          </div>
                      </ng-template>
                      <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index">
                      {{ item.name }}
                      </ng-template>
            </ng-select>
            <div *ngIf=" f.module.touched && f.module.errors" class="invalid-feedback">
                <div *ngIf="f.module.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
        </div>
        <div class="col-12">
            <ul class="selected-values " *ngIf="formGp.value.module.length !=0">
                <li class="ng-value" *ngFor="let item of formGp.value.module, let m =index">
                    <ng-container *ngFor="let insideitem of moduleDropdown, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                    </ng-container>
                    <span class="ng-value-icon right" aria-hidden="true"  (click)="clear('module',m)"><i class="icon-close"></i></span>
               </li>
            </ul>
        </div>

    </div>

    <div class="row row-16 pt-16">
      <div class=" col-lg-6 form-row">
          <label for="" class="form-label required">Employees</label>
          <ng-select
              [ngClass]="{ 'is-invalid': (f.employee.touched || f.employee.errors?.relievedError) && f.employee.errors}"
              placeholder="{{this.messageService.selectplaceholddisplay('employees')}}"
              formControlName="employee"
              [items]="employeeDropdown"
              [multiple]="true"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              [selectableGroupAsModel]="false"
              bindLabel="name"
              [closeOnSelect]="false"
              bindValue="id"
              class="multiselect"
              (change)="ngChange($event)"
              >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="formGp.value.employee.length">
                        <span class="ng-value-label">{{formGp.value.employee.length}} Employee selected.</span>
                    </div>
                </ng-template>
              <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index">
              <div class="d-inline-flex align-items-center"> <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                <img class="avatar-sm sq-32 img-fluid rounded-circle"  *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
               <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appService.getFirstChar(item.first_name+' '+item.last_name,2)}}</span></span>

               <span class="mt-1 ms-1">{{ (item.name.length>30)? (item.name | slice:0:30)+'..':(item.name)  }}</span>
              </div>
          </ng-template>
          </ng-select>
          <div *ngIf="(f.employee.touched || f.employee.errors?.relievedError) && f.employee.errors" class="invalid-feedback">
              <div *ngIf="f.employee.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="f.employee.errors?.relievedError">{{this.messageService.inactiveStatus()}}</div>
          </div>
        </div>
        <div class="col-12">
          <ul class="selected-values" *ngIf="formGp.value.employee.length !=0">
              <li class="ng-value" [ngClass]="{'ng-value-danger': isInsideItemIdFive(item)}" *ngFor="let item of formGp.value.employee, let m =index">
                  <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                  <div class="d-inline-flex align-items-center">
                      <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null" src="{{insideitem.profile_image}}">
                      <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''" class="avatar-sm sq-24 rounded-circle  avatar-circle {{insideitem.color_code}}" >
                          <span>
                          {{this.appService.getFirstChar(insideitem.first_name+' '+insideitem.last_name,2)}}</span>
                      </span>
                      <span class=""> {{ (insideitem['name'].length>25)? (insideitem['name'] | slice:0:25)+'..':(insideitem['name'])  }}</span>
                      <span *ngIf="insideitem?.employment_status == 'Relieved' || insideitem?.employment_status == 'Settled' || insideitem?.employment_status == 'Terminated' || insideitem?.employment_status == 'Retired'" class="lh-1 ms-8"><i class="bi bi-info-circle fs-14 text-danger tooltip-icon" ngbPopover="Employee status : Inactive" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                   </div>
                   </span>
                  </ng-container>
                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('employee',m)"><i class="icon-close"></i></span>
             </li>
          </ul>
      </div>

    </div>
</div>
<footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid" (click)="validateConfigureFlow()">NEXT</button>
</footer>
