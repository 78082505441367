import { Component, OnInit,Input } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payroll-checklist',
  templateUrl: './payroll-checklist.component.html',
  styleUrls: ['./payroll-checklist.component.scss']
})
export class PayrollChecklistComponent implements OnInit {

  @Input() config:any;
  @Input() progress:any;
  @Input() payroll_month:any;
  @Input() company:any;

  constructor(public appservice:AppService,public router:Router) { }

  ngOnInit(): void {
  }
  movePrior(){
    if(this.config.is_payroll_configured==false && this.config.is_statutory_configured==false)
    this.router.navigateByUrl("/prior-payroll-setup/"+this.appservice.getFinancialYear()+"/"+this.payroll_month+"/"+this.company)

  }
}
