
import { Component, OnInit, ViewChild } from '@angular/core';
import { PortalAccessService } from 'src/app/@core/services/portal-access.service';
import { AppService } from 'src/app/app.global';
import { FormBuilder,Validators } from '@angular/forms';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-portal-access',
  templateUrl: './portal-access.component.html',
  styleUrls: ['./portal-access.component.scss']
})
export class PortalAccessComponent implements OnInit {
  enablePortalAccess = false;
  disablePortalAccess = false;
  status = true
  list:any[] = []
  empList:any[] = []
  permissions  : any
  companyList:any = [];
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  filter  = false;
  filterData : any = ''

  page = 1;
  pageSize:any = 20;
  searchString:any = "";
  enableEmp:any = []
  disableEmp:any = []
  loader = false
  loader1 = false

  @ViewChild(SearchbarComponent) search! :SearchbarComponent;
  constructor(private pas:PortalAccessService, public appService:AppService, private formBuilder:FormBuilder,public notificationService:NotificationService,public messageService : MessageService) { }

  ngOnInit(): void {
    this.getPermission();
    this.getList()
    this.getEmpList()
  }
  getList(){
    this.loader = true
    this.pas.getPortalAccessList(this.filterData).subscribe((result: any) => {
      this.list = result.data
      this.loader = false
    });
  }
  getEmpList(){
    this.loader1 = true
    this.pas.employeeList().subscribe((result: any) => {
      for(let i=0; i< result.length; i++){
        if(result[i]['employee_name'] != undefined)
        result[i]['name']   = result[i]['employee_name'];
        result[i]['name']   +=" ("+result[i]['employee_code']+")";
        result[i]['image']   = result[i]['profile_image'];
      }
      this.empList = result
      this.loader1 = false
    });
  }
    /********* GET TABLE FILTER COUNT FUNCTION ****************/
    filteredcount(){
      return this.filterIt().length;
    }
  
    /********* TABLE FILTER FUNCTION ****************/
    filterIt() {
      return this.list.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey     = obj[key].toString().toLowerCase();
            const tempSearch  = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
  
  closeFilter(){
    this.filter = false;
  }

  submitForm(data:any){
    this.filter  = false
    this.searchString = ""
    this.companyList = data.companyList
    this.buList = data.buList
    this.DepList = data.DepList
    this.DesignationList = data.DesignationList
    this.gradeList = data.gradeList
    this.BranchList = data.BranchList
    this.filterData = `company=[${this.companyList}]&bu=[${this.buList}]&department=[${this.DepList}]&designation=[${this.DesignationList}]&branch=[${this.BranchList}]&grade=[${this.gradeList}]`
    this.getList()
  }

  resetFilter(){
    this.searchString = ""
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.filterData = ''
    this.getList()
    this.filter  = false
  }

  setDisableEmployee(value:any){
    if(!isNaN(value)){
      this.disableEmp = this.empList.filter((item:any)=>{
        return item.id == value
      })
      if(this.disableEmp.length>0 && this.disableEmp[0]?.id)
        this.disablePortalAccessFm.reset({ employee: this.disableEmp[0].id})
      else
        this.disablePortalAccessFm.reset();
    } else {
      this.disablePortalAccessFm.reset();
    }
  }

  disablePortalAccessFm = this.formBuilder.group({
    employee: 0,
    reason_for_disabled:  ['',[Validators.required, Validators.maxLength(500)]]
  });
  saveDisableAccess(){
    this.pas.disableEmployee(this.disablePortalAccessFm.value).subscribe((result: any) => {
      this.searchString = ""
      this.getList()
      this.getEmpList()
      this.closeDisableSidebar()
      this.notificationService.handleSuccessNotification("Employee portal access has been disabled for "+result?.employee_name,"Success")
    },(error:any)=>{
      this.closeDisableSidebar()
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    })
  }
  closeDisableSidebar(){
    this.disablePortalAccess= false; this.disableEmp=[]; this.disablePortalAccessFm.reset(); this.search.clearBar();
  }


  enablePortalAccessFm = this.formBuilder.group({
    reason_for_enabled:  ['',[Validators.required, Validators.maxLength(500)]]
  });

  saveEnableAccess(){
    this.pas.enabeleEmployee( this.enablePortalAccessFm.value,this.enableEmp.employee).subscribe((result: any) => {
      this.searchString = ""
      this.getList()
      this.getEmpList()
      this.closeEnableSidebar()
      this.notificationService.handleSuccessNotification("Employee portal access has been enabled for "+result?.employee_name,"Success")
    },(error:any)=>{
      this.closeEnableSidebar()
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    })
  }

  closeEnableSidebar(){
    this.enablePortalAccess= false; this.enableEmp=[]; this.enablePortalAccessFm.reset(); 
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/disable-portal-access');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
