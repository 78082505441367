import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };


  getConfigList(status: any, limit: any, offset: any, search:any){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/payroll-config-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`,this.httpOption)
  }
  createConfig(body: any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/payroll-config-create/`, body,this.httpOption)
  }
  updateConfig(body: any,id:number){
    return this.http.put(`${this.API_PATH}general_settings/api/v1/payroll-config-detail/${id}/`, body, this.httpOption)
  }
  deleteConfig(body: any,id:number){
    return this.http.patch(`${this.API_PATH}general_settings/api/v1/payroll-config-detail/${id}/`, body,this.httpOption)
  }
  getConfigDetail(id:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/payroll-config-detail/${id}/`,this.httpOption)
  }
  getcompanyList(status: any,id :any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/payroll-config-list/?is_active=${status}&id=${id}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }
  companyDropDownData(status: any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/payroll-config-dropdown/?is_active=${status}&ordering=company__company_name`, this.httpOption)
  }

  getExcludedCompList(status: any,id :any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/payroll-config-company-list/?is_active=${status}&id=${id}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }
  getExcludedCompDropdown(status: any,id :any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/payroll-config-company-list/?is_active=${status}&id=${id}&limit=${limit}&offset=${offset}&search=${search}&ordering=company_name`, this.httpOption);
  }
}
