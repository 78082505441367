import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayslipService {

  constructor(
    private http: HttpClient,
    public router: Router
    ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getMyPayslipData(year:any,limit:any, offset:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/payslip-list/?pay_year=${year}&limit=${limit}&offset=${offset}`, this.httpOption)
  }

  downloadPaySlip(id:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/slip-download/${id}/?slip_name=pay_slip`, this.httpOption)
  }

}

