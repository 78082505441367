import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trips-setup',
  templateUrl: './trips-setup.component.html',
  styleUrls: ['./trips-setup.component.scss']
})
export class TripsSetupComponent implements OnInit {
  activeTab = 1;
  constructor() { }

  ngOnInit(): void {
  }

}
