import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { EmployeeLetterHrService } from 'src/app/@core/services/employee-letter-hr.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { EmailinputValidator } from '../../validators/emailinput.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-generate-letter',
  templateUrl: './app-generate-letter.component.html',
  styleUrls: ['./app-generate-letter.component.scss']
})
export class AppGenerateLetterComponent implements OnInit {
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  changeletter=false;
  changeletterkey:any='';
  templatelist:any=[];
  templatedata:any=[];
  sendemail =false;
  disablebtn=false;

  @Input() Viewdata: any;
  @Input() reqtype: any;
  @Input() from: any;
  @Input() regenerate: any;
  @Input() loader1: any;
  @Output() panelclose= new EventEmitter();
  @Output() regenerateop= new EventEmitter();

  form = this.fb.group({
    letter_template: ['', ],
    is_send_letter: ['', ],
    email           : [null],
    remarks        : ['', [Validators.maxLength(500)]],
  })
  formchange = this.fb.group({
    templatechange        : [null]
  })
  constructor(
    private fb: FormBuilder,
    private EmployeeLetterHrService : EmployeeLetterHrService,
    public appService: AppService,
    public messageService : MessageService,
    public notificationService : NotificationService,
  ) { }

  ngOnInit(): void {
    this.form.get('email')?.setValue(this.Viewdata?.letter?.email);
  }

  ngOnChanges(change: SimpleChanges){
    if(change?.Viewdata.currentValue != undefined){
      this.getlettertemplatelist();
    }
  }

  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    // var currentDocName : any    = this.fileName.split('/').pop();
    // this.currentDocName         = currentDocName.split('.').slice(0, -1);
    // this.currentDocName         = decodeURIComponent(this.currentDocName);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  closefn(){
    this.regenerate = false;
    this.panelclose.emit(this.regenerate);
  }

  CheckedOrNot(value:any){
    this.sendemail = value.target.checked;
    if(value.target.checked==true){
      this.form.get('email')?.setValidators([Validators.required,EmailinputValidator()]);
      this.form.get('email')?.setValue(this.Viewdata?.letter?.email);
    }else{
      this.form.get('email')?.clearValidators();
      this.form.get('email')?.reset();
    }
    this.form.get('email')?.updateValueAndValidity();
  }

  regeneratefn(id:any,key:any){
    this.disablebtn = true;
    this.EmployeeLetterHrService.empunpublish(id,{
      'letter_template' :this.changeletterkey?.id,
      'is_send_letter' :this.sendemail,
      'email' :this.form.controls['email'].value,
      'remarks':this.form.controls['remarks'].value,
      'status': 'Published'
    },this.from).subscribe((res: any) => {
      this.closefn;
      if(this.from != 'inbox'){
      this.notificationService.handleSuccessNotification("Letter " +key+ " successfully.","Success");
      }
      this.regenerateop.emit(this.form.controls['remarks'].value);
    });
  }

  changeletterevent(event:any){
    this.changeletterkey = event;
  }

  cancelletter(){
    this.changeletter = false;
    this.formchange.reset();
  }

  changeletterfn(){
    this.form.get('letter_template')?.setValue(this.changeletterkey?.name);
    this.loader1=true;
    this.getlettertemplatdetail(this.changeletterkey?.id);
    this.changeletter = false;
    this.formchange.reset();
  }

  get f() {return  this.form.controls; }

  getlettertemplatelist(){
    this.loader1=true;
    let key:any;
    if(this.from=='inbox'){
      key=''
    }else{
      key='hr';
    }
    this.EmployeeLetterHrService.lettertemplatelist('inbox',this.Viewdata?.letter_type).subscribe((res: any) => {
      this.templatelist = res.body;
      this.form.get('letter_template')?.setValue(this.Viewdata?.letter?.letter_template?.name);
      this.getlettertemplatdetail(this.Viewdata?.letter?.letter_template?.id);
    });
  }

  getlettertemplatdetail(id:any){
    if(this.from=='inbox'){
      this.EmployeeLetterHrService.letterpreviewinbox(id).subscribe((res: any) => {
        this.currentDocName= res.body.file_name.split('.').slice(0, -1);
        this.getletterpdffn(res.body);
        this.loader1=false;
      });    
    }else{
      this.EmployeeLetterHrService.letterpreview(id).subscribe((res: any) => {
        this.currentDocName= res.body.file_name.split('.').slice(0, -1);
        this.getletterpdffn(res.body);
        this.loader1=false;
      });    
    }    
  }

  close(ev:any){
    this.modalToggle = ev;
  }

  getletterpdffn(res:any){
    if(this.from=='inbox'){
      this.EmployeeLetterHrService.getletterpdfinbox({
        'template_html':res.template,
        'employee': this.Viewdata?.employee?.id,
        'letter_tag':res.letter_tag
      }).subscribe((res: any) => {
         this.templatedata=res.body;
      });
    }else{
      this.EmployeeLetterHrService.getletterpdf({
        'template_html':res.template,
        'employee': this.Viewdata?.employee?.id,
        'letter_tag':res.letter_tag
      }).subscribe((res: any) => {
         this.templatedata=res.body;
      });
    }    
  }

}
