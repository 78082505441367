import { Component, OnInit,Input,Output,ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ExpenseReportProfileService } from 'src/app/@core/services/expense-report-profile.service';
import { ExpenseReceiptService } from 'src/app/@core/services/expense-receipt.service';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { AdvancePolicyService } from 'src/app/@core/services/advancepolicy.service';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { FormBuilder} from '@angular/forms';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-expense-reports',
  templateUrl: './expense-reports.component.html',
  styleUrls: ['./expense-reports.component.scss']
})
export class ExpenseReportsComponent implements OnInit {
  editReport = false;
  editReciept = false;
  selectedOption = 'amount';


  activeToggle:any        = '';
  landingScreenData:any   = [];
  loader                  = false
  page                    = 1;
  pageSize                = 20;
  searchString:any        = '';
  searchlistdata:any      = [];
  isdelete                = false;
  deleteToggle            = false;
  deleteClicked           = false;
  deleteLabel             = ''
  searchKeyword           = ""
  filter:any              = "";
  permissions:any         = [];
  searchCompleteList:any  = [];
  completeData:any        = []
  unReportedReceiptList   = []
  newReport               = false;
  addExpense              = false;
  unreportedExpense       = false;
  unReportReceiptLoader   = false;
  applyAdvance            = false;
  addAdvanceLoader        = false
  applyAdvanceData:any    = []
  viewReciept             = false;
  viewRecieptLoader       = false;
  viewRecieptData:any     = [];
  singleReport:any        = [];
  viewReportLoader        = false
  viewToggle              = 'expense';
  viewReport              = false;
  editId                  = 0;
  uploadeAttachments      = false;
  updateAttachmentIndex   = 0;
  receiptId               = 0;
  reportId                = 0;
  viewAttchments          = false;
  uploadedAttachments:any = [];
  viewAttachmentIndex     = 0;
  isAttachmentRequired    = false;
  reportReqMsg:any        = "";
  alertToggle             = false
  dataFilter:any          = ''
  finalReceiptAttachment:any = [];
  sideaddexp = false;

  attachmentReceipt:any
  currency:any;
  deleteId:any;
  selected: any;
  @ViewChild(SearchbarComponent) child:any;

  datemin:any = new Date();
  reportPlaceholder:any  = this.messageService.searchdisplay('expense report');
  statusList:any = {
    'Accepted'    : 'badge-success',
    'Draft'       : 'badge-secondary',
    'Reimbursed'  : 'badge-primary',
    'Rejected'    : 'badge-danger',
    'Pending'     : 'badge-warning',

  }
  filterForm             = this.formBuilder.group({
    from_date            : [''],
    to_date              : ['']
  },
  { validators: CustomDateValidators.fromToDate('from_date', 'to_date') }
  );

  modalToggle     = false
  fileName:any
  currentDocName  = ""
  currentExtension= ""
  sLoading = false;
  
  constructor(
    public appService: AppService,
    public messageService : MessageService,
    public reportService:ExpenseReportProfileService,
    public receiptService: ExpenseReceiptService,
    public requestService:ExpenseReportRequestService,
    public advanceService:AdvancePolicyService,
    public formBuilder:FormBuilder,
    public investmentService:InvestmentDeclerationService,
    public router: Router,
    private notificationService: NotificationService

  ) { }

  ngOnInit(): void {
    let userData        = this.appService.getEmpData();
    this.currency       = userData.currency;
    this.getLandingScreenData('');
    this.getPermission();
    this.datemin.setFullYear(1);
  }
  get f() {return  this.filterForm.controls; }
  getLandingScreenData(status:any){
    

      this.activeToggle = status;
      this.loader       = true;
      this.reportService.reportLandingdata(this.activeToggle,this.dataFilter).subscribe((res:any)=>{

        this.landingScreenData  = res;
        this.completeData       = res.slice();
        this.loader             = false;
        this.sLoading           = false
      })
      // if(this.dataFilter==''){

      //   this.reportService.getReportDropdown(status).subscribe((res:any)=>{
      //     this.searchlistdata     = res;
      //     this.searchCompleteList = this.searchlistdata.slice();
      //   })
      // }
      // else{
      //   this.searchlistdata = this.searchCompleteList.filter((el:any) => {
      //     return this.landingScreenData.some((f:any) => {
      //       return f.id === el.id
      //     });
      //   });
      // }
   
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  searchReport(searchKeyword:any){
    this.sLoading           = true;
    this.loader             = true;
    this.searchKeyword      = searchKeyword;
    if(searchKeyword != ""){

      this.landingScreenData  = this.landingScreenData.filter(function(element:any) {

        return element?.name?.toLowerCase().includes(searchKeyword.toLowerCase());
      });
      this.loader           = false;
      this.sLoading         = false;
    }
  }
  searchresults(data:any){

    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      // if(this.dataFilter=='')
      this.landingScreenData  = this.completeData;
      // else
      // this.applyFilter()

      this.loader         = false;

    }else{

        this.landingScreenData = this.landingScreenData.filter(function(element:any) {
          return element.id == data;
        });
        this.loader           = false;
    }
  }
  deletConfirm(id:any,from:any,status:any){

    if(from=='report' && status!='Draft'){
      this.alertToggle  = true;
      if(status== 'Pending'){
        this.reportReqMsg =  'Expense report cannot be deleted as it is '+status+' for approval.'
      }else{
        this.reportReqMsg =  'Expense report cannot be deleted as it is '+status+' already.'
      }
    }
    else{
      this.deleteLabel  = from
      this.deleteId     = id;
      this.deleteToggle = true;
    }
  }
  deleteData(){
    this.deleteClicked = true;
    if(this.deleteLabel == 'report')
      this.deleteReport()
    else if(this.deleteLabel== 'receipt')
    {
      let mappedData:any = []
      for(let i=0;i<this.singleReport.receipts.length;i++){
        if(this.singleReport.receipts[i]['id']!=this.deleteId)
        mappedData.push(this.singleReport.receipts[i]['id'])
      }
      this.mapExpenseAdvance({receipts:mappedData});
    }
    else
    {
      let mappedData:any = []
      for(let i=0;i<this.singleReport.advances.length;i++){
        if(this.singleReport.advances[i]['id']!=this.deleteId)
        mappedData.push(this.singleReport.advances[i]['id'])
      }
      this.mapExpenseAdvance({advances:mappedData});
    }
  }

  submitreport(){
    this.newReport = false;
    this.getLandingScreenData(this.activeToggle)
  }

  closereport(){
    this.newReport = false;
  }
  saveReceipt(form:any){
    if(this.sideaddexp==true){
      this.viewReportLoader = true;
    }
    let formValue = form?.form_value;
    formValue['expense_date'] = this.appService.dateFormatConvert(formValue['expense_date'])
    this.receiptService.saveOrUpdateReceipt(form?.editId,formValue).subscribe((res:any)=>{
      if(form?.editId !=''){
        this.notificationService.handleSuccessNotification("Updated successfully.","Success");        
      }
      this.getLandingScreenData(this.activeToggle);
      this.addExpense = false;
      this.sideaddexp = false;
      this.getSingleReportView(this.reportId)
    })

  }
  closeAddExpense(){
    this.addExpense = false
  }
  getUnreportedExpense(){
    this.unreportedExpense         = true
    this.unReportReceiptLoader     = true;
    let expense_date:any = [];
    expense_date?.push(this.appService.dateFormatConvert(this.singleReport?.from_date))
    expense_date?.push(this.appService.dateFormatConvert(this.singleReport?.to_date))
    this.unReportedReceiptList = [];
    this.requestService.receiptList(JSON.stringify(expense_date)).subscribe((res:any)=>{
      this.unReportedReceiptList          = res;
      this.unReportReceiptLoader          = false;

    })
  }
  mapUnreportedExpense(data:any){
    let mappedData:any = []
    mappedData         = data.addedreceipt;
    for(let i=0;i<this.singleReport.receipts.length;i++){
      mappedData.push(this.singleReport.receipts[i]['id'])
    }
    this.mapExpenseAdvance({receipts:mappedData});
    // this.closeReceipt()
  }
  closeReceipt(){
    this.unreportedExpense = false;
  }
  getAdvanceList(){
    this.applyAdvance      = true;
    this.addAdvanceLoader  = true;
    this.applyAdvanceData  = [];
    this.requestService.advanceList().subscribe((res:any)=>{
      this.applyAdvanceData         = res;
      this.addAdvanceLoader         = false;

    })
  }
  addadvance(data:any){
    let mappedData:any = []
    mappedData         = data.addedadvance;
    for(let i=0;i<this.singleReport.advances.length;i++){
      mappedData.push(this.singleReport.advances[i]['id'])
    }
    this.mapExpenseAdvance({advances:mappedData});
    this.closeadvance()
  }
  mapExpenseAdvance(data:any){
    this.requestService.updateExpenseReport(this.reportId,data).subscribe((res:any)=>{
      this.getSingleReportView(this.reportId);
      this.deleteToggle  = false;
      this.closeReceipt();
    },(error: any) => {
    if(this.deleteLabel != 'advance'){
      this.unreportedExpense = true;
    }
      this.deleteToggle  = false;
    });
    this.deleteClicked = false;
  }
  closeadvance(){
    this.applyAdvance = false;
  }
  getSigleReceiptView(id:any){
    this.viewRecieptLoader  = true;
    this.viewReciept        = true;
    this.receiptId          = id;
    this.viewRecieptData    = []
    this.requestService.getSingleReceiptData(id).subscribe((res:any)=>{
      this.viewRecieptData    = res;
      this.viewRecieptLoader  = false;
    })
  }
  closeView(){
    this.viewReciept = false
  }
  getSingleReportView(id:any){
    this.reportId         = id;
    this.viewReport       = true;
    this.viewReportLoader = true;
    this.reportService.getSingleReportData(id).subscribe((res:any)=>{
      this.singleReport     = res;
      this.viewReportLoader = false
    })
  }
  deleteReport(){
    this.reportService.reportDelete(this.deleteId,{"is_active": false}).subscribe((res:any)=>{
      this.deleteClicked = false;
      this.deleteToggle  = false;
      this.getLandingScreenData(this.activeToggle)

    })
  }
  addAttachment(index:any,id:any){
    this.finalReceiptAttachment = [];
    this.uploadeAttachments     = true;
    this.updateAttachmentIndex  = index;
    this.receiptId              = id;

  }
  saveAttachment(data:any){

    this.mapAttachment(data);
    this.uploadeAttachments = false;

  }
  closeAttachment(){
    this.uploadeAttachments = false;
  }
  mapAttachment(data:any){
    this.receiptService.saveOrUpdateReceipt(this.receiptId,data).subscribe((res:any)=>{
        this.notificationService.handleSuccessNotification("Updated successfully.","Success");        
      this.getSingleReportView(this.reportId);
    })
  }


  viewAttachment(id:any,index:any){
    let receiptData           = this.singleReport['receipts'][index]
    this.uploadedAttachments  = [];
    this.viewAttchments       = true;
    this.viewAttachmentIndex  = index;
    this.receiptId            = id;
    this.uploadedAttachments  = receiptData?.attachments;
    if(receiptData?.category?.is_attachment){
        this.isAttachmentRequired = true;
        if(receiptData?.category?.is_attachment_minimum_amount){
          if(Number(receiptData?.amount) >= Number(receiptData?.category?.attachment_minimum_amount_limit) ){
            this.isAttachmentRequired = true;
          }else{
            this.isAttachmentRequired = false;
          }
        }
      }
  }
  closeViewAttachment(){
    this.viewAttchments = false;
  }
  saveViewAttachment(data:any){

    let attachmentData:any = []
    for(let i=0;i<data?.attachments.length;i++){
      attachmentData.push(data?.attachments[i]['attachment'])
    }
    this.mapAttachment({'attachments':attachmentData})
  }
  editReportData(id:any){

    this.editId     = id;
    this.newReport  = true
    this.viewReport = false;

  }
  closefn(){
    this.filter = false;
    this.filterForm.reset();
  }
  applyFilter(){
    let fromDate      = this.filterForm.value.from_date!='' && this.filterForm.value.from_date!=null?this.appService.dateFormatConvert(this.filterForm.value.from_date):''
    let toDate        = this.filterForm.value.to_date!='' && this.filterForm.value.to_date!=null?this.appService.dateFormatConvert(this.filterForm.value.to_date):''
    this.dataFilter   = 'from_date='+fromDate+'&to_date='+toDate
    this.getLandingScreenData(this.activeToggle);
    this.child.clearBar();
    this.filter = false;

  }
  
  resetFilter(){
    this.filterForm.reset('');
    this.dataFilter = '';
    this.filter = false;
    this.child.clearBar();
    this.getLandingScreenData(this.activeToggle);

  }
  addExpenseReportRequest(){
    this.investmentService.requestDetails().subscribe((res:any)=>{
      if(!res?.count_info?.expense_policy_assigned){
        this.alertToggle  = true;
        this.reportReqMsg = 'Expense report request cannot be raised as expense policy is not configured yet.';
      }else if(!res?.count_info?.advance_policy_assigned){
        this.alertToggle  = true;
        this.reportReqMsg = 'Expense report request cannot be raised as advance policy is not configured yet.';
      }else if(!res?.count_info?.expense_settings == null){
        this.alertToggle  = true;
        this.reportReqMsg = 'Expense report request cannot be raised as expense settings has not been configured yet.';
      }else if(!res?.count_info?.expense_settings?.submission_window){
        this.alertToggle  = true;
        this.reportReqMsg = 'The expense report submission window has closed and will reopen at the beginning of the next pay cycle.';
      }
      else{
        this.router.navigate(['/request/expense-reports-setup']);

      }
    })
  }
  closeInfo(data:any){
    this.alertToggle = false;

  }
  statusFilter(status:any){
    this.child.clearBar();
    this.getLandingScreenData(status)

  }

  yearRestrictFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1), new Date()]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  viewDocument(filename:any,docType:any){

    this.modalToggle      = true;
    this.fileName         = filename;
    this.currentDocName   = docType;
    this.currentExtension = this.fileName?.split('.').pop();
  }

  close(bool:any){
    this.modalToggle = bool;
  }

}
