import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as XLSX from 'xlsx';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  searchString:any = "";
  status = 'PENDING';
  year:any;
  month:any;
  company:any;
  data:any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = '';
  fileSize: string = '';
  dataType: string = '';
  ws:any= null;
  success = false;
  deleteToggle = false;
  deleteMsg = "";
  inputData:any =[];
  failedData:any =[];
  successData:any =[];
  successDataCount=0;
  downloadMsg = false;
  permissions:any = [];
  preparedData:any = [];
  page = 1;
  pageSize =20;
  keyArray:any = [];
  errorArray:any = [];
  showSidePanel       = false;
  submiited           = false;
  invalidFormat = false;
  historyData:any = []
  loader = true
  inputdata :any;
  statusFlag = false;
  empalertMsg = "The selected employee(s) payroll has already been Processed/Freezed/Held/Published. Kindly undo the respective action to add/remove attendance";
  alertToggle = false;
  confirmMsg  = false;
  saveComplete  = false;
  buttonText    ="";
  confirmMsgText = "";
  saveData:any = []
  isDelete      = false;
  deleteClicked = false;
  rowData:any;
  from:any  = '';
  delArray :any = [];
  isProgressStart = false
  chunkLen = 10
  percentage:any = 0;
  iscompleted = false;
  isDataUpload = 0;
  errorMsg = ''
  successMsg = ''
  body = '';
  exportData:any      = [];
  constructor( public appService: AppService,public route:ActivatedRoute,public sds:SalaryDashboardService,private notificationService: NotificationService, public messageService : MessageService, public previousRouteService :PreviousRouteService) { }

  ngOnInit(): void {
    this.getPermission();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      this.historyList()
    })
  }

  getPermission(){
  if(this.previousRouteService.getPreviousUrl() == '/attendance-dashboard'){
    this.permissions = this.appService.getPermissions('/attendance-dashboard');
  }else{
    this.permissions = this.appService.getPermissions('/run-payroll');
  }
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].type.split('/');
    if(extension[1] =='xlsx' || extension[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: false,cellDates:true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        this.ws = wb.Sheets[wsname];

        var ws = wb.Sheets[wsname];
        this.fileName = target.files[0].name;
        this.fileSize = this.appService.formatSizeUnits(target.files[0].size)
        this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: false,rawNumbers: false, dateNF:'MMMM YYYY',blankrows:false}));

        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }

  processData(){
    if(this.data.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.deleteFile();
    } else {
      var headerRow = this.data[0];

      let Heading =['Employee Code', 'Full name', 'Attendance month', 'Total days', 'Present days(paid)', 'Weekly offs (paid)', 'Leave(paid)', 'Holiday(paid)', 'Absent(unpaid)', 'Leave(unpaid)']
      let success = Heading.every(function(val:any) {
        return headerRow.indexOf(val) !== -1;
      });
      success =  JSON.stringify(Heading)==JSON.stringify(headerRow)
      if(success == false){
        this.deleteToggle = true;
        this.deleteMsg = this.messageService.invalidExcelFormat();
        this.deleteFile();
      }
      else
      // this.prepareUploadData();
      this.validateData()
    }
  }
  validateData(){
    const validateDat:any = [];

    this.data.forEach((item:any, key:any) => {
      if(key != 0 && item[2]!= undefined){
        const myArray = item[2].split(" ");
        if(myArray[0] == this.month && myArray[1] == this.year){


          validateDat.push({
            "employee": item[0]?item[0]:"",
            "emp_name": item[1]?item[1]:"",
            "attend_month": myArray[0]?myArray[0]:"",
            "attend_year": myArray[1]?myArray[1]:"",
            "total_days": item[3]?item[3]:0,
            // "actual_paid_days": item[11]?item[11]:0,
            "prasent_days": item[4]?item[4]:0,
            "weekly_offs": item[5]?item[5]:0,
            "leave_paid": item[6]?item[6]:0,
            "holiday_paid": item[7]?item[7]:0,
            "absent_unpaid": item[8]?item[8]:0,
            "leave_unpaid":item[9]?item[9]:0,
          })
        }
      }
    });
    if(validateDat.length >0){
      this.sds.validateAttendance({ "company_id" : this.company,"data":validateDat}).subscribe((res: any) => {
        if(res.body.status == 1){
          this.errorArray = res.body.error_info;

          this.prepareUploadData()
        } else {
          this.notificationService.handleErrorNotification(res.body.error,"Error");
        }
      });
    } else {
      this.deleteToggle = true;
      this.deleteMsg = "The uploaded file do not contain any data for "+this.month+" "+this.year;
      this.deleteFile();
    }

  }

  prepareUploadData(){

    this.data.forEach((item:any, key:any) => {
      if(key != 0 && item[2]!= undefined){
        this.preparedData[key-1] = item;
        let k = item[0]+item[2].replaceAll(' ','')
        this.keyArray.push({k : key-1,v:k});

        const myArray = item[2].split(" ");
        if(myArray[0] == this.month && myArray[1] == this.year){
          this.inputData.push({
            "index":key-1,
            "checked":false,
            "employee": item[0]?item[0]:"",
            "employeeName": item[1]?item[1]:"",
            "attend_month": myArray[0]?myArray[0]:"",
            "attend_year": myArray[1]?myArray[1]:"",
            "total_days": item[3]?item[3]:0,
            // "actual_paid_days": item[11]?item[11]:0,
            "prasent_days": item[4]?item[4]:0,
            "weekly_offs": item[5]?item[5]:0,
            "leave_paid": item[6]?item[6]:0,
            "holiday_paid": item[7]?item[7]:0,
            "absent_unpaid": item[8]?item[8]:0,
            "leave_unpaid":item[9]?item[9]:0,
          })
        }
      }
    });

    // this.uploadData();
  }
  // uploadData(){


  //   if(this.saveData.length>0){
  //     this.saveComplete = true;
  //     this.loader       = true;
  //     this.sds.saveAttendance({ company_id : this.company,"data":this.saveData}).subscribe((res: any) => {
  //       this.confirmMsg = false;
  //       if(res.body.status == 1){
  //         if(res.body.failed_data != undefined)
  //           this.failedData = res.body.failed_data;
  //           this.successDataCount = res.body.succesd_data_count;
  //           // if(this.successDataCount>0)
  //           //   this.processFailedData()
  //         if(this.failedData.length == 0){
  //             this.notificationService.handleSuccessNotification(this.successDataCount+" attendance data uploaded successfully.","Success");
  //             if(this.inputData.length == this.saveData.length)
  //               this.deleteFile()
  //             else
  //               this.bulkDelete()
  //         } else {
  //           this.downloadMsg = true;
  //         }
  //         this.historyList()
  //       } else {
  //         this.notificationService.handleErrorNotification(res.body.error,"Error");
  //       }
  //     });
  //   }
  //   else
  //   this.loader       = false
  //   this.alertToggle  = false;


  // }

  uploadData(){
    this.isDataUpload = 1;
    this.percentage   = 0;
    this.iscompleted = false
    if(this.saveData.length>0){
      this.isProgressStart = true
        this.failedData = []
        var l = this.saveData.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.successDataCount = 0
        this.processUpload(0,p);
    } else
    this.loader       = false
    this.alertToggle  = false;
}

processUpload(k:any,p:any){
    var l = this.saveData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveData[i])
    }

    this.sds.saveAttendance({ company_id : this.company,"data":saveDataArray}).subscribe((res: any) => {
        this.confirmMsg = false;
        if(res.body.status == 1){
            if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
              res.body.failed_data.forEach((i:any, k:any) => {
                    this.failedData.push(i)
                });
            }
            this.successDataCount += res.body.succesd_data_count;
            this.percentage +=((p/4)*3);
            if(x<l)
                this.processUpload(k+1,p);
            else{
              this.iscompleted = true
              this.isProgressStart = false
              if(this.failedData.length == 0){
                  this.notificationService.handleSuccessNotification(this.successDataCount+" attendance data uploaded successfully.","Success");
                  if(this.inputData.length == this.saveData.length)
                      this.deleteFile()
                  else
                      this.bulkDelete()
              } else {
                this.exportData    = [];
                for (let i = 0; i < this.failedData.length; i++) {

                  this.exportData.push({'Employee Code':this.failedData[i]['data']['employee'],'Full name':this.failedData[i]['data']['emp_name'],'Attendance month':this.failedData[i]['data']['attend_month'], 'Total days':this.failedData[i]['data']['total_days'], 'Present days(paid)':this.failedData[i]['data']['prasent_days'], 'Weekly offs (paid)':this.failedData[i]['data']['weekly_offs'], 'Leave(paid)':this.failedData[i]['data']['leave_paid'], 'Holiday(paid)':this.failedData[i]['data']['holiday_paid'], 'Absent(unpaid)':this.failedData[i]['data']['absent_unpaid'], 'Leave(unpaid)':this.failedData[i]['data']['leave_unpaid'],'Error description':this.failedData[i]['error']});
                }
                  this.downloadMsg = true;
                  this.successMsg = this.successDataCount+" out of "+l+" attendance data uploaded successfully"
                  this.errorMsg = this.failedData.length+' attendance data have error data'
              }
              this.body = x+' out of '+l+" attendance data uploaded successfully"
              this.historyList()
            }
        } else {
        //   this.notificationService.handleErrorNotification(res.body.error,"Error");
        }
      },
      (error:any)=>{
        this.isProgressStart = false
        this.notificationService.handleErrorNotification('Something went wrong','Error')
      }
      );
}

  deleteFile(){
    this.fileName = '';
    this.data = [];
    this.fileSize = '';
    this.inputData = [];
    this.successData = [];
    this.keyArray = [];
    this.preparedData = []
    this.page = 1
  }
  downloadError(){

    let dData = [];
    for (let i = 0; i < this.failedData.length; i++) {

      dData.push({'Employee Code':this.failedData[i]['data']['employee'],'Full name':this.failedData[i]['data']['emp_name'],'Attendance month':this.failedData[i]['data']['attend_month'], 'Total days':this.failedData[i]['data']['total_days'], 'Present days(paid)':this.failedData[i]['data']['prasent_days'], 'Weekly offs (paid)':this.failedData[i]['data']['weekly_offs'], 'Leave(paid)':this.failedData[i]['data']['leave_paid'], 'Holiday(paid)':this.failedData[i]['data']['holiday_paid'], 'Absent(unpaid)':this.failedData[i]['data']['absent_unpaid'], 'Leave(unpaid)':this.failedData[i]['data']['leave_unpaid'],'Error description':this.failedData[i]['error']});
    }
    this.appService.exportExcel(dData,'Attendance Error');
    this.downloadMsg = false;
    this.bulkDelete();
  }
  filterIt() {
    if(this.status == 'PENDING'){
      return this.inputData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !==undefined && obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    } else {
      return this.historyData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !==undefined &&obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
  }
  filteredcount(){
    return this.filterIt().length;
  }

  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item: { checked: any; }) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }

  deleteRow(index:any){
    // this.loader = true;
    if(this.status == 'PENDING'){
        this.preparedData.splice(index, 1);
        this.inputData.splice(index, 1);
        this.data.splice(index, 1);
        this.errorArray.splice(index, 1);
        this.loader = false
    } else if( this.historyData[index]?.payroll_status=='Unprocessed'){
      this.rowData = {
        "section": "attendance",
        "delete_id_list": [ this.historyData[index].id]
        }
      this.confirmRowDel('single');
    }
    else{
      this.loader     = false;
      this.alertToggle = true;
    }


  }
  confirmRowDel(source:any){
    this.isDelete = true;
    this.from     = source;
  }
  deleteAttenance(){
    this.loader         = true;
    this.deleteClicked  = false;
    if(this.from=='single'){

      this.deleteHistory(this.rowData)
      this.historyList()
    }
    else{
      let data = {
        "section": "attendance",
        "delete_id_list": this.delArray
        }
        this.deleteHistory(data);
        this.historyList()
    }
  }

  bulkDelete(){
    // this.loader   = true
    this.delArray = [];
    if(this.status == 'PENDING'){
      this.inputData.forEach((item:any, value:any) => {
        this.delArray.push(value)

      });

      if(this.delArray.length>0){

        var delArrayRev = this.delArray.reverse()
        delArrayRev.forEach((item:any, value:any) =>{
          this.deleteRow(item);
        })
      }

      // this.loader = false
    } else {
      this.historyData.forEach((item:any, value:any) => {

        if(item?.checked == true && item?.payroll_status=='Unprocessed')

        this.delArray.push(this.historyData[value].id)
        else if(item?.checked == true && item?.payroll_status!='Unprocessed')
          this.statusFlag = true;


      });

      if(this.statusFlag==true)
      {

          this.alertToggle = true;
          if(this.delArray.length==0)
          this.loader = false;
      }
      if(this.delArray.length){

        this.confirmRowDel('bulk');
      }
    }


  }

  historyList(){
    this.sds.attendanceHistory(this.year,this.month,this.company,"attendance").subscribe((res:any)=>{
      this.historyData = res.body.data;
      this.loader = false
     });
  }
  deleteHistory(data:any){
    this.sds.deleteHistory(data).subscribe((res:any)=>{
      this.isDelete = false;
      this.historyList()
     });
  }
  countSelected(){
    var delArray:any = [];
    if(this.status == 'PENDING'){
      this.inputData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
    } else {
      this.historyData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(this.historyData[value].id)
      });
    }
    return delArray.length
  }

  selectAll(){
    this.inputdata = this.appService.selectAll(this.inputData)

  }
  unselectAll(){

    this.inputdata = this.appService.unselectAll(this.inputData)
  }
  closeInfo(bool:any){
    this.alertToggle = bool;
    this.loader      = false;

  }
  confirmation(){
    this.saveData     = []
    this.saveComplete = false;

    this.inputData.forEach((item:any, key:any) => {
      if(item?.checked == true && (this.errorArray[item.index]?.payroll_status=='Unprocessed' || this.errorArray[item.index]?.payroll_status=='' || this.errorArray[item.index]?.payroll_status==null)){
        this.saveData.push({
          "employee": item.employee?item.employee:"",
          "emp_name": item.employeeName?item.employeeName:"",
          "attend_month": item.attend_month?item.attend_month:0,
          "attend_year": item.attend_year?item.attend_year:0,
          "total_days": item.total_days?parseInt(item.total_days):0,
          "actual_paid_days": item.actual_paid_days?parseInt(item.actual_paid_days):0,
          "prasent_days": item.prasent_days?parseInt(item.prasent_days):0,
          "weekly_offs": item.weekly_offs?parseInt(item.weekly_offs):0,
          "leave_paid": item.leave_paid?parseInt(item.leave_paid):0,
          "holiday_paid": item.holiday_paid?parseInt(item.holiday_paid):0,
          "absent_unpaid": item.absent_unpaid?parseInt(item.absent_unpaid):0,
          "leave_unpaid":item.leave_unpaid?parseInt(item.leave_unpaid):0,
        })
      }
      else if(item?.checked == true && this.errorArray[item.index]?.payroll_status!='Unprocessed')
        this.statusFlag = true;
    });

    if(this.saveData.length>0){
      this.confirmMsgText ="Click the Save button, If you want to add the attendance for the selected employees.";
      this.confirmMsg     = true;
    }
    if(this.statusFlag==true)
      this.alertToggle = true;

  }


}
