<app-edit-loader *ngIf="loader"></app-edit-loader>
<ng-container *ngIf="!loader">
  <div class="flex-center flex-column full-page bg-nonlogin">

    <div [style.display]="CardVisible ? 'block' :'none'" class="card form-card mx-auto">
      <div class="card-body  p-32">
        <a (click)="logout()" class="text-center d-block">
          <img class="img-fluid org-logo" *ngIf="currentlogodata.logo != '' && currentlogodata.logo != null"
            src="{{currentlogodata.logo}}" alt="">

        </a>
        <h3 class="fw-600 text-center py-40">Reset password</h3>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" autocomplete="off">
          <div class="card-body p-0">
            <div class="text-center">
              <img *ngIf="profileImage !=  null && profileImage !=  ''" alt=""
                class="img-fluid avatar-border avatar-circle sq-57 mt-6 mb-16 mx-auto" src="{{profileImage}}" />
              <div *ngIf="profileImage ==  '' || profileImage ==  null"
                class="avatar-circle sq-57 mt-6 mb-16 mx-auto fw-500 bg-white avatar-border {{colourCode}}">
                {{this.appservice.getFirstChar(fullname,2)}}</div>
              <p class=" fs-18 fw-500 mb-24">{{fullname}}</p>
            </div>
            <div>

              <div class="mb-3 form-group">
                <label for="password" class="control-label form-label">New Password</label>
                <div class="form-icon icon-right"
                  [ngClass]="{ 'is-invalid': (f.password.dirty || f.password.touched || submitted)  &&  (f.password.errors || inValidInput)}">

                  <input popoverClass="error-popover" placement="right" [ngbPopover]="popContent" required
                    [type]="show_pass? 'text' : 'password'" class="form-control" name="" id="password" placeholder=""
                    formControlName="password"
                    [ngClass]="{ 'is-invalid': (f.password.dirty || f.password.touched || submitted)  &&  (f.password.errors || inValidInput)}" (keyup)="inValidInput = false">
                    <!-- Added keyup to clear same pass msg from backend -->
                  <i *ngIf="f.password.value" class="pointer  {{viewicon_password}}" (click)="getpassword('pass')"></i>

                </div>
                <div
                  *ngIf="(f.password.dirty || f.password.touched || submitted) && (f.password.errors || inValidInput)"
                  class="invalid-feedback">
                  <div *ngIf="f.password.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.password.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="inValidInput && !(f.password.errors)">{{errorMsg}}</div>

                </div>

                <ng-template #popContent>
                  <ul class="error-list">
                    <li class=""
                      [ngClass]="(f.password.errors?.uppercase || f.password.errors?.required ) ? 'invalid' : 'valid'">
                      Uppercase letters</li>
                    <li class=""
                      [ngClass]="(f.password.errors?.lowercase || f.password.errors?.required ) ? 'invalid' : 'valid'">
                      Lowercase letters</li>
                    <li class=""
                      [ngClass]="(f.password.errors?.numberPattern || f.password.errors?.required ) ? 'invalid' : 'valid'">
                      Numbers</li>
                    <li class=""
                      [ngClass]="(f.password.errors?.special || f.password.errors?.required ) ? 'invalid' : 'valid'">
                      Special characters</li>
                    <li class=""
                      [ngClass]="(f.password.errors?.minlength || f.password.errors?.required ) ? 'invalid' : 'valid'">
                      Password must be at least 8 characters’ long</li>
                    <li class=""
                      [ngClass]="(f.password.errors?.invalidformat || f.password.errors?.required ) ? 'invalid' : 'valid'">
                      Password should not contain your first name, last name and company name</li>
                  </ul>
                </ng-template>

              </div>
              <div class="mb-3 form-group">
                <label for="confirmPassword" class="form-label">Re-enter Password</label>
                <div class="form-icon icon-right"
                  [ngClass]="{ 'is-invalid': (f.confirmPassword.dirty || f.confirmPassword.touched || submitted)  &&  (f.confirmPassword.errors || resetPasswordForm.errors)}">
                  <input required [type]="show_confirm ? 'text' : 'password'" class="form-control" name=""
                    id="confirmPassword" placeholder="" formControlName="confirmPassword"
                    [ngClass]="{ 'is-invalid': (f.confirmPassword.dirty || f.confirmPassword.touched || submitted)  &&  (f.confirmPassword.errors || resetPasswordForm.errors)}">
                  <i *ngIf="f.confirmPassword.value" class="pointer  {{viewicon_confirm}}"
                    (click)="getpassword('confirm')"></i>

                </div>
                <div
                  *ngIf="(f.confirmPassword.dirty || f.confirmPassword.touched) && (f.confirmPassword.errors || resetPasswordForm.errors || submitted)"
                  class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors?.required">{{this.messageService.validationDisplay('required')}}
                  </div>
                  <div *ngIf="(resetPasswordForm.errors)">Password & Confirm Password should be
                    same.</div>
                </div>
              </div>

            </div>
            <div class="d-grid gap-2 pt-4">

              <button type="submit" name="" id="" class="btn btn-primary">Reset
                Password</button>
            </div>
          </div>

        </form>
      </div>
    </div>
    <!-- success message  -->
    <div [style.display]="CardVisible ? 'none' :'block'" class="card form-card mx-auto px-32 pt-32 pb-40 mx-3">
      <div class="card-body p-0">
        <h3 class="pb-4 text-center fw-600">Password reset successful!</h3>
        <div class="green-icon rounded-circle mx-auto">
          <i class=" text-white  icon-double-check-circle-fill text-success"></i>
        </div>
        <div>
          <p class="text-center mb-0 pt-5">Your password has been changed</p>
        </div>
        <div class="d-grid gap-2 pt-5">
          <button  (click)="logout()" type="button" name="" id="" class="btn btn-primary">Go back to
            Login</button>
        </div>
      </div>
    </div>
    <p class="text-center mb-0 py-24"><small><a href="https://www.skizzlehr.tech/pages/privacy-policy.php"
          class="link-secondary" target="_blank">Privacy
          policy</a> | <a href="https://www.skizzlehr.tech/pages/terms-and-condition.php" class="link-secondary"
          target="_blank">Terms and conditions</a> |
        {{this.appservice.version}}</small>
    </p>
  </div>
</ng-container>
