import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';


@Component({
  selector: 'app-fnf-view-expense-report',
  templateUrl: './fnf-view-expense-report.component.html',
  styleUrls: ['./fnf-view-expense-report.component.scss']
})
export class FnfViewExpenseReportComponent implements OnInit {

  @Input() requestitem:any;
  @Input() receipts:any;
  @Input() advances:any;
  @Input() from:any;
  @Input() currency:any;

  activeMenu                = "Expense";
  pageSize                  = 20;
  page                      = 1;

  viewReciept               = false;
  viewRecieptLoader         = false;
  viewRecieptData:any       = [];

  viewAttchments            = false;
  receiptId                 = 0;
  uploadedAttachments:any   = [];

  viewAdvance               = false;
  advanceid                 = 0;
  viewLoader                = false;
  viewdata:any              = [];

  modalToggle               = false;
  currentDocName            = "";
  currentExtension          = "";
  allowedExtension          = "";
  fileName:any;
  alertMsg:any              = '';
  alertToggle               = false;

  constructor(
    public appservice: AppService,
    private ExpenseReportRequestService:ExpenseReportRequestService
  ) { }

  ngOnInit(): void {
  }

  detailedReceiptView(id:any){
    this.viewRecieptLoader = true;
    this.viewReciept       = true;
    this.getSigleReceiptView(id);
  }

  getSigleReceiptView(id:any){
    this.viewRecieptData      = [];
    this.ExpenseReportRequestService.getSingleReceiptData(id).subscribe((res:any)=>{
      this.viewRecieptData    = res;
      this.viewRecieptLoader  = false;
    })
  }

  viewAttachmentFunction(index:any,id:any){
    this.viewAttchments      = true;
    this.receiptId           = id;
    this.uploadedAttachments = this.receipts[index].attachments;
  }

  viewAdvanceFunction(id:any){
    this.viewLoader  = true;
    this.viewAdvance = true;
    this.advanceid   = id;
    this.viewdata    = [];
    this.ExpenseReportRequestService.getAdvanceDetailedView(this.advanceid).subscribe((res: any) => {
      this.viewdata   = res;
      this.viewLoader = false;
   });
  }

  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

}
