import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Router } from '@angular/router';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';
import { Subject } from 'rxjs'
import { delay, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-asset-settings',
  templateUrl: './asset-settings.component.html',
  styleUrls: ['./asset-settings.component.scss']
})
export class AssetSettingsComponent implements OnInit {
  viewDetails = false;
  viewConfiguration   = false;
  permissions: any    = [];
  searchKeyword       = "";
  listlength          = 0;
  items:any[]         = [];
  loader              = true;
  offset              = 0;
  direction           = '';
  defaultlimit:any    = 20;
  status:any          = true;
  statusBtn:any       = "Active";
  infinityloader      = false;
  nodata              = false;
  itemsList:any[]     = [];
  searchlistdata      = [];
  deleteToggle        = false;
  deleteClicked       = false;
  viewLoader          = false;
  alertInfo           = true;
  viewData:any;
  deleteId : any;
  configList: any;
  configDetailsList: any;
  isLoading = false
  searchData : Subject<void> = new Subject();
  searchString = this.messageService.searchdisplay('company');
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    public appService:AppService,
    public messageService: MessageService,
    public breakpointObserver: BreakpointObserver,
    private router:Router,
    public assetService:AssetSettingsService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.offset = 0;
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.getConfigurationList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.assetService.getAssetList(this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getConfigurationList(){
    this.assetService.getAssetList(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((result: any) => {
      this.arrangeApiData(result)
    });
  }

  arrangeApiData(result:any){
    this.isLoading = false
    let res = result.body;
    this.itemsList = res.results;
    this.listlength = res.results.length;
    if(this.offset ==0){
      this.items = [];
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
    this.nodata = res.count==0?true:false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.items.push(this.itemsList[i]);
    }

  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.defaultlimit;
    if(this.listlength){
      this.getConfigurationList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  searchresult(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.items = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchconfig(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.items = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  selectItemList(status:any){
    this.loader = true;
    this.status = status;
    this.offset = 0;
    this.items  = [];
    this.child.clearBar();
  }

  addNewdata(){
    this.router.navigate(['/asset-settings/asset-settings-setup']);
  }

  detailedView(id:any){
    this.viewLoader = true;
    this.assetService.getSingleAssetData(id).subscribe((res:any)=>{
       this.viewData   = res.body;
       this.viewLoader = false;
    })
    this.viewDetails = true;
  }
}
