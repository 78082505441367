import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { AttendanceCapturePolicyService } from 'src/app/@core/services/attendance-capture-policy.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-attendance-capture-policy-setup',
  templateUrl: './attendance-capture-policy-setup.component.html',
  styleUrls: ['./attendance-capture-policy-setup.component.scss']
})
export class AttendanceCapturePolicySetupComponent implements OnInit {
  activeTab = 1;
  actiontitle = 'Add'
  id = 0;
  editflag       :boolean= false
  disabled = false;


  constructor(
    private fb: FormBuilder,
    public router: Router,
    public route:ActivatedRoute,
    private attendanceCaptureService: AttendanceCapturePolicyService,
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
  })
}

attendanceCaptureForm: FormGroup = this.fb.group({
  id: 0,
  policydetails: this.fb.group({
    name:  [null, [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    attend_capture_desc: [null, [Validators.maxLength(500)]],
    web_checkin : [false],
    is_ip_restricted : [false],
    attendance_capture_iprange:this.fb.array([]),

    on_duty : [false],
    is_allow_backdated_onduty : [false],
    max_on_duty_back_days : [null],
    backday_day : [null],
    backday_month : [null],
    backdated_onduty_daytype:[true],
    max_on_duty_day_type : [false],
    max_on_duty_request_days : [null],
    is_future_on_duty: [true],
    is_prior_notice_for_on_duty: [false],
    prior_day_for_on_duty: [null],

    is_wfh_allowed : [false],
    is_backdated_wfh_allowed : [false],
    max_wfh_back_days : [null],
    backdated_wfh_daytype:[true],
    wfh_day:[null],
    wfh_month:[null],
    max_wfh_request_day_type : [false],
    max_wfh_request_days : [null],
    is_future_wfh: [true],
    is_prior_notice_for_wfh: [false],
    prior_day_for_wfh: [null],
    request_raising_restricted : [null],

    is_attend_regular_allowed : [false],
    is_backdated_attend_regular_allowed : [false],
    max_attend_regular_back_days : [null],
    regular_day:[null],
    regular_month:[null],
    backdated_attend_regular_daytype:[true],
    max_attend_regular_day_type : [false],
    max_attend_regular_request_days : [null],
    is_future_attend_regular_allowed: [false],

    mobile_checkin          :[false],
    location_captured       :[false],
    enable_geo_fencing      :[false],
    enable_realtime_tracking:[false],
    // track_intravel          :[15],
    idle_interval_threshold          :[15],
    location_request_approves:[false],
    // live_location_notify:[false],
    

    is_selfie_enabled:[false],
    is_save_photo: [false],
    save_photo_checkin_from_mobile: [false],
    save_photo_checkin_from_web: [false],
    is_photo_need_verification: [false],
    verify_photo_checkin_from_mobile: [false],
    verify_photo_checkin_from_web: [false],
  }),
  assign: this.fb.group({
    applicableto: [null, [Validators.required]],
    assignees: [null, [Validators.required]]
  })
})

get policy_details(){
  return this.attendanceCaptureForm.get('policydetails') as FormGroup;
}
get assignForm() {
  return this.attendanceCaptureForm.get('assign') as FormGroup;
}

attendance_capture_iprange() : FormArray {
  return this.policy_details.get("attendance_capture_iprange") as FormArray
}

// Nav Change
onNavChange(changeEvent : NgbNavChangeEvent){
  if(changeEvent.nextId === 2){
    if(this.attendanceCaptureForm['controls'].policydetails.invalid){
      changeEvent.preventDefault();
    }
  }
}

 // Next Step
 submitMethod(){
  this.activeTab = 2;
 }

submitForm(){
  var applicable = this.assignForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.assignForm.controls.assignees.value){
      arr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }

    var ipArr = []
    if(this.policy_details.controls.web_checkin.value == true && this.policy_details.controls.is_ip_restricted.value){
    for(let key of this.attendance_capture_iprange().value){
      ipArr.push({
        "from_ip_range" : key.from_ip_range,
        "to_ip_range"  : key.to_ip_range
      })
    }
  }
  else{
      ipArr = []
    }

    var dataArray:any;
    dataArray = {
      "name": this.policy_details.controls.name.value,
      "attend_capture_desc":this.policy_details.controls.attend_capture_desc.value,
      "web_checkin":this.policy_details.controls.web_checkin.value,
      "is_ip_restricted":this.policy_details.controls.is_ip_restricted.value,
      "attendance_capture_iprange": ipArr,

      "on_duty":this.policy_details.controls.on_duty.value,
      "is_allow_backdated_onduty":this.policy_details.controls.is_allow_backdated_onduty.value,
      "backdated_onduty_daytype":this.policy_details.controls.backdated_onduty_daytype.value,
      "max_on_duty_back_days":this.policy_details.controls.backdated_onduty_daytype.value==true?this.policy_details.controls.backday_month.value:this.policy_details.controls.backday_day.value,
      "max_on_duty_day_type":this.policy_details.controls.max_on_duty_day_type.value,
      "is_future_on_duty":this.policy_details.controls.is_future_on_duty.value,
      "is_prior_notice_for_on_duty":this.policy_details.controls.is_prior_notice_for_on_duty.value,

      "is_wfh_allowed":this.policy_details.controls.is_wfh_allowed.value,
      "is_backdated_wfh_allowed":this.policy_details.controls.is_backdated_wfh_allowed.value,
      "max_wfh_request_day_type":this.policy_details.controls.max_wfh_request_day_type.value,
      "is_future_wfh":this.policy_details.controls.is_future_wfh.value,
      "is_prior_notice_for_wfh":this.policy_details.controls.is_prior_notice_for_wfh.value,
      "backdated_wfh_daytype":this.policy_details.controls.backdated_wfh_daytype.value,


      "is_attend_regular_allowed":this.policy_details.controls.is_attend_regular_allowed.value,
      "is_backdated_attend_regular_allowed":this.policy_details.controls.is_backdated_attend_regular_allowed.value,
      "max_attend_regular_day_type":this.policy_details.controls.max_attend_regular_day_type.value,
      "is_future_attend_regular_allowed":this.policy_details.controls.is_future_attend_regular_allowed.value,
      "backdated_attend_regular_daytype":this.policy_details.controls.backdated_attend_regular_daytype.value,

      "attendance_capture_assignright":arr,

      "mobile_checkin"            :this.policy_details.controls.mobile_checkin.value,
      "location_captured"         :this.policy_details.controls.location_captured.value,
      "enable_geo_fencing"        :this.policy_details.controls.enable_geo_fencing.value,
      "enable_realtime_tracking"  :this.policy_details.controls.enable_realtime_tracking.value,
      // "track_intravel"            :this.policy_details.controls.track_intravel.value,
      "idle_interval_threshold"            :this.policy_details.controls.idle_interval_threshold.value,
      "location_request_approves" :this.policy_details.controls.location_request_approves.value,
      // "live_location_notify"      :this.policy_details.controls.enable_realtime_tracking.value == false ? false : this.policy_details.controls.live_location_notify.value,


      "is_selfie_enabled" : this.policy_details.controls.is_selfie_enabled.value,
      "is_save_photo" : this.policy_details.controls.is_save_photo.value,
      "save_photo_checkin_from_mobile" : this.policy_details.controls.save_photo_checkin_from_mobile.value,
      "save_photo_checkin_from_web" : this.policy_details.controls.save_photo_checkin_from_web.value,
      "is_photo_need_verification" : this.policy_details.controls.is_photo_need_verification.value,
      "verify_photo_checkin_from_mobile" : this.policy_details.controls.verify_photo_checkin_from_mobile.value,
      "verify_photo_checkin_from_web" : this.policy_details.controls.verify_photo_checkin_from_web.value,

    }
    if(this.policy_details.controls.request_raising_restricted.value == 'Holidays & Weekends'){
      dataArray.request_raising_restricted = 'Holiday & Weekend'

    }
    else if(this.policy_details.controls.request_raising_restricted.value == 'Holidays'){
      dataArray.request_raising_restricted = 'Holiday'

    }
    else if(this.policy_details.controls.request_raising_restricted.value == 'Weekends' || this.policy_details.controls.request_raising_restricted.value == 'Weekend'){
      dataArray.request_raising_restricted = 'Weekend'

    }
    else{
      dataArray.request_raising_restricted = 'None'

    }
    if(this.policy_details.controls.on_duty.value == true){
      dataArray.max_on_duty_request_days = this.policy_details.controls.max_on_duty_request_days.value
    }
    else{
      dataArray.max_on_duty_request_days = 0
    }


    if(this.policy_details.controls.is_prior_notice_for_on_duty.value == true){
      dataArray.prior_day_for_on_duty = this.policy_details.controls.prior_day_for_on_duty.value
    }
    else{
      dataArray.prior_day_for_on_duty = 0
    }

    if(this.policy_details.controls.backdated_wfh_daytype.value == true){
      dataArray.max_wfh_back_days = this.policy_details.controls.wfh_month.value
    }
    else{
      dataArray.max_wfh_back_days = this.policy_details.controls.wfh_day.value
    }



    if(this.policy_details.controls.is_wfh_allowed.value == true){
      dataArray.max_wfh_request_days =  this.policy_details.controls.max_wfh_request_days.value
    }
    else{
      dataArray.max_wfh_request_days = 0
    }

    if(this.policy_details.controls.is_prior_notice_for_wfh.value == true){
      dataArray.prior_day_for_wfh = this.policy_details.controls.prior_day_for_wfh.value
    }
    else{
      dataArray.prior_day_for_wfh = 0
    }

    if(this.policy_details.controls.backdated_attend_regular_daytype.value == true){
      dataArray.max_attend_regular_back_days = this.policy_details.controls.regular_month.value
    }
    else{
      dataArray.max_attend_regular_back_days = this.policy_details.controls.regular_day.value
    }

    if(this.policy_details.controls.is_attend_regular_allowed.value == true){
      dataArray.max_attend_regular_request_days = this.policy_details.controls.max_attend_regular_request_days.value
    }
    else{
      dataArray.max_attend_regular_request_days = 0
    }

      if(this.id == 0){
        this.attendanceCaptureService.attendanceCaptureCreate(dataArray).subscribe((res: any) => {
          this.router.navigate(['attendance-capture-policy']);
        });
      } else {
        this.attendanceCaptureService.attendanceCaptureUpdate(dataArray,this.id).subscribe((res: any) => {
          this.router.navigate(['attendance-capture-policy']);
        },
        (error) => {

        });
      }
    }

    backClicked(){
    this.router.navigate(['attendance-capture-policy'])
  }

  newIPranges(): FormGroup {
    return this.fb.group({
      "from_ip_range": [null,[Validators.required, Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
      "to_ip_range": [null,[Validators.required, Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
    })
  }

  addIps() {
    this.attendance_capture_iprange().push(this.newIPranges());
  }

  editForm(){
    this.attendanceCaptureService.attendanceCapturegetDetail(this.id).subscribe((res: any) => {
      this.setEditForm(res);
      this.actiontitle ="Edit"
      this.editflag = true
    },(error)=>{
      this.router.navigate(['attendance-capture-policy']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.attendanceCaptureForm.disable();
      this.disabled = true;
    }
    let assigneesArr = [];
    let applicableto = "";
    let appkey ="";

    for(let key of res.attendance_capture_assignright){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
        assigneesArr.push(key[appkey]);
      applicableto = key.applicable
    }
    let ipsArr = []
    for(let key of res.attendance_capture_iprange){
      this.addIps();
      ipsArr.push({
         "from_ip_range":key.from_ip_range,
         "to_ip_range":key.to_ip_range,
      });
    }

    let request_raising_restricted = res.request_raising_restricted == 'Holiday & Weekend' ? 'Holidays & Weekends' : res.request_raising_restricted == 'Holiday' ? 'Holidays' : res.request_raising_restricted == 'Weekend' ? 'Weekends' : 'None';

    this.attendanceCaptureForm.reset(
      {
        id:this.id,
        policydetails: {
          name:res.name,
          attend_capture_desc:res.attend_capture_desc,
          web_checkin:res.web_checkin,
          is_ip_restricted:res.is_ip_restricted,
          attendance_capture_iprange: ipsArr,
          on_duty:res.on_duty,
          is_allow_backdated_onduty:res.is_allow_backdated_onduty,
          max_on_duty_back_days:res.max_on_duty_back_days,
          max_on_duty_day_type:res.max_on_duty_day_type,
          max_on_duty_request_days:res?.max_on_duty_request_days == 0 ? null : res?.max_on_duty_request_days,
          is_future_on_duty:res.is_future_on_duty,
          is_prior_notice_for_on_duty:res.is_prior_notice_for_on_duty,
          prior_day_for_on_duty:res.is_prior_notice_for_on_duty==true?res.prior_day_for_on_duty:null,
          backdated_onduty_daytype:res.backdated_onduty_daytype,


          is_wfh_allowed:res.is_wfh_allowed,
          is_backdated_wfh_allowed:res.is_backdated_wfh_allowed,
          max_wfh_back_days:res.max_wfh_back_days,
          max_wfh_request_day_type:res.max_wfh_request_day_type,
          max_wfh_request_days:res?.max_wfh_request_days == 0 ? null : res?.max_wfh_request_days,
          is_future_wfh:res.is_future_wfh,
          is_prior_notice_for_wfh:res.is_prior_notice_for_wfh,
          prior_day_for_wfh:res.is_prior_notice_for_wfh==true?res.prior_day_for_wfh:null,
          request_raising_restricted: request_raising_restricted,
          backdated_wfh_daytype:res.backdated_wfh_daytype,


          is_attend_regular_allowed:res.is_attend_regular_allowed,
          is_backdated_attend_regular_allowed:res.is_backdated_attend_regular_allowed,
          max_attend_regular_back_days:res.max_attend_regular_back_days,
          max_attend_regular_day_type:res.max_attend_regular_day_type,
          max_attend_regular_request_days:res?.max_attend_regular_request_days == 0 ? null : res?.max_attend_regular_request_days,
          is_future_attend_regular_allowed:res.is_future_attend_regular_allowed,
          backdated_attend_regular_daytype:res.backdated_attend_regular_daytype,

          mobile_checkin          :res.mobile_checkin,
          location_captured       :res.location_captured,
          enable_geo_fencing      :res.enable_geo_fencing,
          enable_realtime_tracking:res.enable_realtime_tracking,
          idle_interval_threshold          :res.idle_interval_threshold,
          // track_intravel          :res.track_intravel,
          location_request_approves : res.location_request_approves,
          // live_location_notify      : res.live_location_notify,

          is_selfie_enabled : res.is_selfie_enabled,
          is_save_photo : res.is_save_photo,
          save_photo_checkin_from_mobile : res.save_photo_checkin_from_mobile,
          save_photo_checkin_from_web : res.save_photo_checkin_from_web,
          is_photo_need_verification : res.is_photo_need_verification,
          verify_photo_checkin_from_mobile : res.verify_photo_checkin_from_mobile,
          verify_photo_checkin_from_web : res.verify_photo_checkin_from_web,

        },
        assign :{
          applicableto: applicableto,
          assignees: assigneesArr
        }
    })

    if(res.backdated_onduty_daytype==true){
      this.policy_details.get('backday_month')?.setValue(res.max_on_duty_back_days)
    }
    else
    this.policy_details.get('backday_day')?.setValue(res.max_on_duty_back_days)

    if(res.backdated_wfh_daytype==true)
    this.policy_details.get('wfh_month')?.setValue(res.max_wfh_back_days)
    else
    this.policy_details.get('wfh_day')?.setValue(res.max_wfh_back_days)

    if(res.backdated_attend_regular_daytype==true)
    this.policy_details.get('regular_month')?.setValue(res.max_attend_regular_back_days)
    else
    this.policy_details.get('regular_day')?.setValue(res.max_attend_regular_back_days)

    this.addValidators();
    this.addBackdated()
    }
    addBackdated(){
      this.backDatedRestriction('is_backdated_attend_regular_allowed','backdated_attend_regular_daytype');
      this.backDatedRestriction('is_allow_backdated_onduty','backdated_onduty_daytype');
      this.backDatedRestriction('is_backdated_wfh_allowed','backdated_wfh_daytype');
    }
    backDatedValidator(parent:any,requiredcontrol:any,notrequiredcontrol:any){

      this.policy_details.get(notrequiredcontrol)?.clearValidators()
      this.policy_details.get(notrequiredcontrol)?.setValue(null)
      if(this.policy_details.get(parent)?.value==false){
        this.policy_details.get(requiredcontrol)?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      }
      else
      this.policy_details.get(requiredcontrol)?.setValidators([Validators.required])
      this.policy_details.get(requiredcontrol)?.updateValueAndValidity()
      this.policy_details.get(notrequiredcontrol)?.updateValueAndValidity()

    }
    backDatedRestriction(parent:any,child:any){

      if(parent=='is_allow_backdated_onduty'){
        if(this.policy_details.get(parent)?.value==true){
          if(this.policy_details.get(child)?.value==true){
            this.backDatedValidator(child,'backday_month','backday_day')
          }
          else{

            this.backDatedValidator(child,'backday_day','backday_month')

          }
        }
        else{

         this.clearBackdatedValidator('backday_month')
         this.clearBackdatedValidator('backday_day')
         this.policy_details.get(child)?.setValue(true)


        }
      }
      else if(parent=='is_backdated_wfh_allowed'){
        if(this.policy_details.get(parent)?.value==true){
          if(this.policy_details.get(child)?.value==true){
            this.backDatedValidator(child,'wfh_month','wfh_day')
          }
          else{
            this.backDatedValidator(child,'wfh_day','wfh_month')

          }
        }
        else{
         this.clearBackdatedValidator('wfh_month')
         this.clearBackdatedValidator('wfh_day')
         this.policy_details.get(child)?.setValue(true)


        }
      }
      else{
        if(this.policy_details.get(parent)?.value==true){
          if(this.policy_details.get(child)?.value==true){
            this.backDatedValidator(child,'regular_month','regular_day')
          }
          else{
            this.backDatedValidator(child,'regular_day','regular_month')

          }
        }
        else{
         this.clearBackdatedValidator('regular_month')
         this.clearBackdatedValidator('regular_day')
         this.policy_details.get(child)?.setValue(true)

        }
      }
    }
    clearBackdatedValidator(control:any){
      this.policy_details.get(control)?.clearValidators()
      this.policy_details.get(control)?.setValue(null)
      this.policy_details.get(control)?.updateValueAndValidity()

    }
    addValidators(){

      // this.setValidator('is_wfh_allowed','max_wfh_request_days')

      // this.setValidator('is_attend_regular_allowed','max_attend_regular_day_type')
      this.setValidator('is_prior_notice_for_on_duty','prior_day_for_on_duty')
      this.setValidator('is_prior_notice_for_wfh','prior_day_for_wfh')
      // this.setValidator('is_attend_regular_allowed','max_attend_regular_request_days')

      this.setValidatorMaxDays('is_wfh_allowed','max_wfh_request_day_type','max_wfh_request_days');
      this.setValidatorMaxDays('is_attend_regular_allowed','max_attend_regular_day_type','max_attend_regular_request_days');
      this.setValidatorMaxDays('is_attend_regular_allowed','max_attend_regular_day_type','max_attend_regular_request_days');
      this.setValidatorMaxDays('on_duty','max_on_duty_day_type','max_on_duty_request_days');

    }
    setValidator(parent:any,control:any){

      if((this.policy_details.get(parent)?.value==true && (parent!='is_prior_notice_for_on_duty' && parent!='is_prior_notice_for_wfh')))
      {

        this.policy_details.get(control)?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(2),Validators.min(1)])
      }
      else if(this.policy_details.get(parent)?.value==true){

        this.policy_details.get(control)?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(3),Validators.min(1)]);
      }
      this.policy_details.get(control)?.updateValueAndValidity();

    }


    setValidatorMaxDays(parent:any,control1:any,control2:any){
      if(this.policy_details.get(parent)?.value==true){
        if(this.policy_details.get(control1)?.value==true){
          this.policy_details.get(control2)?.setValidators([Validators.required,NumberpatternValidator(),Validators.max(31),Validators.min(1)])
        }else{
          this.policy_details.get(control2)?.setValidators([Validators.required,NumberpatternValidator(),Validators.max(7),Validators.min(1)])
        }
        this.policy_details.get(control2)?.updateValueAndValidity();
      }
    }
  }
