<div class="container-fluid p-24">
  <div class="row row-16">
    <div class="col-12 d-flex">
      <p class="text-uppercase fw-500 mb-0">Prior payroll</p>
    </div>
    <app-loader *ngIf="landingLoader"></app-loader>
    <ng-container *ngIf="!landingLoader">
      <ng-container *ngFor="let data of MonthlyData let i = index">
        <div class="col-lg-4 col-md-6 col-sm-12 ">
          <div class="card card-custom1 card-hover">
            <div class="card-body d-flex align-items-start p-0">
              <div class="d-flex  align-items-start p-16 w-100">
                <div class="logo-img bg{{i%5}}">{{this.appService.getFirstChar(data.month,2)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-0 mb-1 tips">{{data.month}} {{data.year}}</h6>
                  <span class="tips-helper">{{data.month}} {{data.year}}</span>
                  <span
                    class="fs-10 {{data.status=='PENDING'?'text-danger':data.status=='UPLOADED'?'text-warning':'text-success'}} text-uppercase fw-500 ">{{data.status}}</span>
                </div>
                <!-- <span class="link-primary1 d-flex align-items-center fs-12 ms-auto text-nowrap" (click)="getDetailedView(data.month,data.year)">
                        <i class="icon-eye me-1"></i> View
                      </span> -->
              </div>
            </div>
            <div class="card-body align-items-center px-16 pt-12 gap-5 fs-12 pb-16  ">
              <span class="text-warning fw-600">{{data?.pending}} </span>
              <span class="fw-500">Pending </span>
              <span class="fw-500">| </span>
              <span class="text-success fw-600">{{data?.completed}} </span>
              <span class="fw-500"> Uploaded</span>
              <!-- <span class="fw-500 fs-12"><i  class="icon-person text-accent2 me-8"></i>{{data.no_emp}} Employees uploaded</span> -->
              <span class="link-primary1 d-flex align-items-center fs-12 ms-auto text-nowrap"
                (click)="getDetailedView(data.month,data.year)" *ngIf="data?.completed>0">
                <i class="icon-eye me-1"></i> View details
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='detailedPanel === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>{{month}} {{year}}</h5>
      <a class="toggle-panel" (click)="detailedPanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-24">
        <app-c1-loader *ngIf="detailedView==true"></app-c1-loader>
        <ng-container *ngIf="detailedView==false">
          <div class="row row-16">
            <div class="col-12 d-flex">
              <div>
                <p class="mb-0 fw-500 text-uppercase">PRIOR PAYROLL</p>
                <span class="fs-10 text-light-400 fw-500 text-uppercase">{{month}} {{year}}</span>
              </div>
              <button type="button" class="btn ms-auto btn-outline-primary fs-10 fw-700 btn-sm text-uppercase"
                (click)="exportMonthlyPrior()">Export</button>
            </div>
            <div class="col-12 ">
              <app-financial-calc-view [payrollMonthlyCost]="monthlySummary?.total_cost"
                [payroll_month]="monthlySummary?.total_cost.pay_month"
                [payroll_year]="monthlySummary?.total_cost.pay_month" [currency]="monthlySummary?.currency"
                [from]="'slider'">
              </app-financial-calc-view>
            </div>
            <div class="col-12">
              <div class="card card-c2">
                <div class="card-body py-0 px-24 d-flex align-items-end">
                  <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab1" class="nav radius-4 nav-custom shadow-0 p-0"
                    style="min-height: unset;">
                    <li [ngbNavItem]="1">
                      <a ngbNavLink class="nav-link h-100 py-16">Salary Summary</a>
                      <ng-template ngbNavContent>
                        <app-monthly-salary-view [issalsumm]="true" [salarysummaryList]="monthlySummary?.salary_summary"
                          [month]="monthlySummary?.total_cost.pay_month" [year]="monthlySummary?.total_cost.pay_year"
                          [searchString]="searchString" [page]="page" [pageSize]="pageSize">
                        </app-monthly-salary-view>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink class="nav-link h-100 py-16">Employee Salary Summary</a>
                      <ng-template ngbNavContent>
                        <app-monthly-salary-view [isempsalsumm]="true"
                          [empsalarysummaryList]="monthlySummary?.employee_salary_summary"
                          [month]="monthlySummary?.total_cost.pay_month" [year]="monthlySummary?.total_cost.pay_year"
                          [searchString]="searchString" [page]="page" [pageSize]="pageSize" (reloadpage)="reloadPage()"
                          [isRunParoll]="monthlySummary.edit_flag">
                        </app-monthly-salary-view>
                      </ng-template>
                    </li>
                  </nav>
                  <div class="d-flex align-items-center gap-16 ms-auto py-12">
                    <div class="form-icon icon-left">
                      <input type="text" class="form-control fs-14"
                        placeholder="{{this.messageService.searchdisplay('novalue')}}   " name="searchString"
                        [(ngModel)]="searchString" (keyup)="page=1">
                      <i class="icon-search"></i>
                    </div>
                    <button class="btn-square btn btn-secondary" (click)="filter  = true">
                      <i class="icon-filter-left fs-13"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body p-24">
                  <div [ngbNavOutlet]="nav"></div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
  (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
