<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="encashmentForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-16 mb-16 ">
                <div class="col-12 form-row">
                  <label for="" class="form-label required">Period </label>
                <div class="form-check" [ngClass]="{ 'is-invalid': (submitted) && f.period.errors}">
                  <input name="period" type="radio" id="per" class="form-check-input"
                  formControlName="period" [value]="true" [ngClass]="{ 'is-invalid': (submitted) && f.period.errors}">
                  <label for="per" class="form-check-label">till current year</label>
                </div>
                <div *ngIf="(submitted) && f.period.errors" class="invalid-feedback">
                  <div *ngIf="f.period.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              </div>
            </div>
            <app-ou-filters [ouFilterForm]="ouFilterForm" [type]="'leave'" [viewDetail]="viewDetail" [isPolicyRequired]="true" [validationControls]="['leave_policy']"></app-ou-filters>
          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
              (click)="resetPage('reset');resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
              (click)="resetPage('apply');submitEncashmentReport()">
              Apply
          </button>
      </footer>
  </form>
</div>
