import { Component, OnInit, ViewChild } from '@angular/core';
import { GradeService } from 'src/app/@core/services/grade.service';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { FormBuilder,Validators  } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.scss']
})
export class GradeComponent implements OnInit {
  grade_is_active:any;
  gradeView:any;
  gradeid:any;
  showSidePanel1        = false;
  header:any;
  addEditBtn:any;
  gradeGetRes:any;
  gradeGetResId:any;
  gradesearch:string    = this.messageService.searchdisplay('grade');
  searchlistdata:any    = [];
  gradelist:any         = [];
  deleteToggle          = false;
  activeToggle:any      = true;
  nodata                = false;
  validationmsg         = true;
  showSidePanel         = false;
  inputFocusClass       = false;
  deleteClass:any       = true;
  alertclass:any;
  msg:any               = '';
  isactive:any          = true;
  loading               = false;
  color:any;
  notifiaction_danger   = false;
  dataId :any           = "";
  notification_warning  = false;
  loader                = true;
  validationCount       = false;
  disable               = false;
  // unamePattern:RegExp = new RegExp( "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$");
  loader1               = false;
  pattern               = false;
  submitted             = false;
  deleteClicked         = false
  @ViewChild(SearchbarComponent) child:any;

  validated : boolean = false
  gradeUnit : any =[]
  routeComponent : any
  editflag : boolean = false
  value:any
  private subjectKeyUp = new Subject<any>();


  /********INFINITY SCROLL VARIABLES  ****/
  offset                 = 0;
  listlength             = 0;
  direction              = '';
  defaultlimit:any       = 20;
  gradeDetailsList:any   = [];
  infinityloader         = false;
  permissions:any        = [];
  searchKeyword          = "";
  statusBtn:any          = "Active";
  gradeForm              = this.formBuilder.group({
    id: 0,
    name: ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
  })

  isLoading = false
  searchData : Subject<void> = new Subject();

  nameChanged(arg:any) {
    if(arg.length > 100  )
        this.validationCount  = true;
    else
      this.validationCount    = false;

    this.validationmsg        = (arg.length == 0)? true:false;
  }
  constructor(private gradeService:GradeService,public breakpointObserver: BreakpointObserver,public appService: AppService,public messageService : MessageService,private formBuilder: FormBuilder,
    public existDataService :  ExistDatasService,
    private route: ActivatedRoute) { }
  /* active or deleted filter data */
  searchGrade(searchKeyword:any){
    this.loader         = true;
    this.isLoading = true
    this.offset         = 0;
    this.gradelist      = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      // this.showDataItems();
      this.searchData.next()
    }
  }

  showDataItems(){
    this.loader = true;
    this.gradeService.gradeList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res.results.length;
    if(this.offset ==0){
      this.gradelist = [];
    }

    if (res.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata = false;
      this.gradeDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  get f() { return this.gradeForm.controls; }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.gradelist.push(this.gradeDetailsList[i]);
    }
  }
  ngOnInit(): void {
    this.route.url.subscribe((res:any)=>{
      this.routeComponent = res[0].path
    })

    this.subjectKeyUp.pipe(debounceTime(600)).subscribe((d:any)=>{
      this.existService();
    })

    this.getPermission()
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.showDataItems();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.gradeService.gradeList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
   // Save and Edit Grade
   onSubmit() {

    this.submitted = true;
    if (this.gradeForm.invalid ) {
          return;
    }
    this.disable = true;

    this.dataId = this.gradeForm.value.id;
    if(this.dataId == 0){
      this.gradeService.gradeSave({'name':this.gradeForm.value.name,}).subscribe((res: any) => {
        this.offset = 0;
        this.gradelist = [];
        this.showDataItems()
        this.showSidePanel    = false;

        this.msg          = "created";
        this.gradeForm.reset();
      },(err: any) => {this.disable = false});

      this.submitted        = false;

    }else{
      this.gradeService.gradeUpdate(this.dataId, {'name':this.gradeForm.value.name}).subscribe((res: any) => {
        this.offset = 0;
        this.gradelist = [];
        this.showDataItems();
        this.msg          = "updated";
        this.showSidePanel    = false;
      },(err: any) => {this.disable = false});

    }
  }
  // To add slider panel
  addheadercontent(header: any){
    this.dataId           = 0;
    this.gradeGetRes      = '';
    this.gradeGetResId    = '';
    this.header           = "Add";
    this.editflag = false;
    this.deleteClass      = false;
    this.showSidePanel    = true;
    this.addEditBtn       = "Save";
    this.isactive         = false;
    this.validationCount  = false;
    this.gradeForm.enable();
    this.disable          = false;
    this.submitted        = false;
    this.gradeForm.reset({ id: 0, name:''});
    }
  // To edit slider panel
  editheadercontent(id:any, is_active:any){
    this.showSidePanel1 = false;
    this.dataId         = id;
    this.editflag = true
    this.loader1        = true;
    this.disable        = false;
    this.gradeService.gradeGet(id).subscribe((res:any)=>{
      this.gradeForm.reset({ id: res.id, name:res.name});
      this.loader       = false;
      this.loader1      = false;
      if(is_active==true){
        this.gradeForm.enable();
        this.header     = "Edit";
      }
      else{
        this.gradeForm.disable();
        this.header     = "View";
      }
  })
    this.isactive         = true;
    this.showSidePanel    = true;
    this.addEditBtn       = "Save";
    this.validationmsg    = false;
    this.validationCount  = false;
  }
  deletegradefn(id:any){
    // this.gradeGetResId  = id;
    this.deleteToggle   = true;
    this.gradeForm.value.id = id;

  }
  // Delete Grade
  deleteGrade(){
    this.deleteClicked = true
    this.gradeService.gradeDelete(this.gradeForm.value.id,{'is_active' : false}).subscribe((res: any) => {
      this.msg            = "deleted";
      this.showSidePanel  = false;
      this.offset         = 0;
      this.gradelist      = [];
      this.showDataItems();
      this.deleteToggle   = false;
      this.deleteClicked  = false
    },
    (err: any) => {
      this.deleteToggle   = false
      this.showSidePanel  = false
      this.deleteClicked  = false
    });
  }

  // Search
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset     = 0;
      this.gradelist  = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  // Filter
  filterItemList(value:any){
    this.loader       = true;
    this.activeToggle = value;
    this.gradelist    = [];
    this.offset       = 0;
    this.child.clearBar();
  }

  viewgrade(id:any){
    if(this.permissions.v){
      this.showSidePanel1     = true;
      this.loader1            = true;
      this.gradeService.gradeGet(id).subscribe((res: any) => {
        this.gradeView        = res.name;
        this.gradeid          = res.id;
        this.grade_is_active  = res.is_active;
        this.loader1          = false;
      })
    }
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.dataId,
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.gradeUnit = res.body
      this.loader = false
      if(this.gradeUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
