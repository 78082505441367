import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-reimbursement-view',
  templateUrl: './reimbursement-view.component.html',
  styleUrls: ['./reimbursement-view.component.scss']
})
export class ReimbursementViewComponent implements OnInit {
  currency: any

  constructor(private appService:AppService) { }

  ngOnInit(): void {
    let userData  = this.appService.getEmpData();
    this.currency = userData.currency;
  }

}
