import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeSettingQuicklinkService {

  API_PATH: string = environment.apiPath;
  public httpOption : any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  }
  constructor(
    private http: HttpClient
  ) { }

  // List API
  getListAPI(){
    return this.http.get(`${this.API_PATH}home/api/v1/notification-quick-link-list/`,this.httpOption)
  }

  // Update API
  updateList(id:any,body:any){
    return this.http.patch(`${this.API_PATH}home/api/v1/notification-quick-link/update/${id}/`,body,this.httpOption)
  }

}
