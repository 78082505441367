import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styles: [
  ]
})
export class AuditLogComponent implements OnInit {
  filterpanel = false;
  detailsView = false;
  constructor() { }

  ngOnInit(): void {
  }

}
