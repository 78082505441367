import { Component, OnInit, ViewChild } from '@angular/core';
import { StatutoryService } from 'src/app/@core/services/statutory.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { CompanyService } from 'src/app/@core/services/company.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-statutory',
  templateUrl: './statutory.component.html',
  styleUrls: ['./statutory.component.scss']
})


export class StatutoryComponent implements OnInit {

  permissions:any = [];
  companyList : any = []
  companyDetailsList : any = []
  selectedYear :any;
  limit = 20;
  offset = 0;
  currentYear = new Date().getFullYear();
  listLength = 0;
  infinityloader = false;
  direction = '';
  public company_list_flag: boolean = true;
  loader = true;
  status_filter = true;
  statusBtn:any = "Active";
  statuatory = false;
  searchlistdata : any = [];
  companysearch : string = this.messageService.searchdisplay('company');
  searchKeyword="";
  companyId:any = '';
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    public messageService : MessageService,
    public appService:AppService,
    private statutoryService :StatutoryService,
    public breakpointObserver: BreakpointObserver
    ) { }

  ngOnInit(): void {
    this.selectedYear = this.appService.getFinancialYear();
    this.getPermission();
    this.getStatutoryCompanyList();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
      }
    });
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.statutoryService.getStatuatoryCompanyList('',this.companyId,this.selectedYear,this.offset,this.limit,this.status_filter,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getStatutoryCompanyList(){
    this.loader = true;
    this.statutoryService.getStatuatoryCompanyList('',this.companyId,this.selectedYear,this.offset,this.limit,this.status_filter,this.searchKeyword).subscribe((res:any) =>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    let result = res.body.results;
    if(result.length == 0){
      this.company_list_flag = (this.offset == 0) ? false :true;
      this.listLength = 0;
    }else{
      this.company_list_flag = true;
      if(this.offset == 0){
        this.companyList = [];
      }
      this.listLength = result.length;
      this.companyDetailsList = result;
    }
    if(result.length == 0)
      this.infinityloader = false;
    this.addItems(result.length);
    this.loader = false;
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.limit;
    if(this.listLength){
      this.getStatutoryCompanyList();
      this.loader       = false;
      this.direction    = "down";
    }
    else{
      this.infinityloader = false;
    }
  }

  addItems(itemLength:number){
    for(let i = 0; i < itemLength;i++){
      this.companyList.push(this.companyDetailsList[i]);
    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined || this.permissions.v == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  listFilter(value:any){
    this.offset            = 0;
    this.limit = 20;
    this.companyList = [];
    this.status_filter = value
    this.child.clearBar();
  }

  yearChange(value:any){
    this.selectedYear  = Number(value)
    this.offset = 0;
    this.limit = 20;
    this.getStatutoryCompanyList();
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.companyList = [];
      this.companyId = '';
      this.searchData.next()
    }else{
      this.companyId=data;
      this.searchData.next()
    }
  }

  searchCompany(searchKeyword:any){
    this.loader = true;
    this.isLoading = true;
    this.offset           = 0;
    this.companyList = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }
}
