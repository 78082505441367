import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c1-loader',
  template: `
     <div class="row row-16">
        <div class="col-12 mt-25" >
          <ng-container *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
            <div class="mt-15">
              <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '74px','width': '100%' }"></ngx-skeleton-loader>
            </div>
          </ng-container>
        </div>
  `,
  styles: [
  ]
})
export class C1LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
