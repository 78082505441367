<div class="container-fluid p-24" *ngIf="permissions">
  <div class=" row row-16">
    <div class="col-lg-2 col-md-3">
      <div class="card card-c2 overflow-auto" style="height: calc(100vh - 6rem);">
        <app-side-menu-loader *ngIf="loaderMenu"></app-side-menu-loader>
        <ng-container *ngIf="!loaderMenu">
          <div class="card-body py-24 px-0 ">
            <div class="text-uppercase fs-12 fw-500 px-24 text-light-500 pb-8">Menu</div>
            <ul class="navbar-nav nav-vertical nav ms-auto">
              <li class="nav-item" *ngFor="let menu of listData; let i = index">
                <a class="nav-link" (click)="menuclick(menu)" [class.active]="currentmenu == menu.menu">
                  <span> {{menu.menu}}</span>
                </a>
              </li>              
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-lg-10 col-md-9">
      <div class="card card-c2 p-24 pt-16 overflow-auto" style="height: calc(100vh - 6rem);">
        <app-table-loader *ngIf="loader"></app-table-loader>
        <ng-container *ngIf="!loader">
        <div class="row row-24">
          <div class="col-12">
            <p class="title mb-0">{{currentmenu}} CONFIGURATION</p>
          </div>
          <div class="col-12">
            <div class="card card-c2 overflow-hidden">
              <div class="table-responsive" style="max-height: calc(100vh - 12.2rem);">
                <table class="table  td-12 sticky-header th-12 table-striped table-sm">
                  <thead>
                    <tr>
                      <th class="fw-600" scope="col">QUICK LINKS NAME</th>
                      <th class="fw-600" scope="col">ENABLE / DISABLE </th>
                    </tr>
                  </thead>
                  <tbody class="cell-16">

                    <tr *ngFor="let links of menudata; let i = index">
                      <td>{{links.name}}</td>
                      <td class="form-cell">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" value=""
                          [checked]="links.button_enable" (click)="linkchecked($event,i,links)">
                        </div>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>