<div class="container-fluid p-32 form-container" [formGroup]="formGp">
    <div class="row row-16">
        <div class="col-12">
            <div class="card details-card indicater1 px-16 py-12">
                <div class="row">
                    <div class="col-5">
                        <div class="fw-600 fs-14">Chapter VI A - deductions</div>
                    </div>
                    <div class="col">
                        <div class="detail-title">Total amount declared</div>
                        <div class="detail-desc">{{totalDeclaredFunction() | currency:currency}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
                <p class="mb-0">Select the checkbox below to fill details or skip the step by clicking on skip button
                </p>
                <div class="btn-close" (click)="alertShow='hide'"></div>
            </div>
        </div>
        <div class="col-12">
            <div class="card card-c2">
                <div class="card-body p-24">
                    <div class="row row-12">

                        <!-- NEW FIELDS -->

                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="health_insurance_selfspouse80d" id=""
                                    class="form-check-input " formControlName="health_insurance_selfspouse80d"
                                    (change)="checkBoxFunctionTest($event,'health_insurance_selfspouse80d_amount','health_insurance_selfspouse80d_document','','','health_insurance_selfspouse80d_document_loader')">
                                <label for="" class="form-check-label">80D : Health insurance premium for self / spouse
                                    or dependent children  </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover=" Employees are eligible to claim a tax deduction under Section 80D for self, spouse, children, and parents on premium paid towards medical insurance.
                      For Self / Spouse or Dependent Children: If age < 60 max limit ₹ 25000, If age > 60 max limit is ₹ 50000."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.health_insurance_selfspouse80d">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="health_insurance_selfspouse80d_age" type="radio" id="deduction1"
                                        class="form-check-input" formControlName="health_insurance_selfspouse80d_age"
                                        [value]="false"
                                        (change)="radioBtnChange($event,'health_insurance_selfspouse80d','health_insurance_selfspouse80d_amount','health_insurance_selfspouse80d_document')">
                                    <label for="deduction1" class="form-check-label">Age : less than 60</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="health_insurance_selfspouse80d_age" type="radio" id="deduction2"
                                        class="form-check-input " formControlName="health_insurance_selfspouse80d_age"
                                        [value]="true"
                                        (change)="radioBtnChange($event,'health_insurance_selfspouse80d','health_insurance_selfspouse80d_amount','health_insurance_selfspouse80d_document')">
                                    <label for="deduction2" class="form-check-label">Age : greater than 60</label>
                                </div>
                            </div>
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.health_insurance_selfspouse80d_amount.dirty || f.health_insurance_selfspouse80d_amount.touched) &&  (f.health_insurance_selfspouse80d_amount.errors || experianceValidator1)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="health_insurance_selfspouse80d_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="health_insurance_selfspouse80d_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.health_insurance_selfspouse80d_amount.dirty || f.health_insurance_selfspouse80d_amount.touched) &&  (f.health_insurance_selfspouse80d_amount.errors || experianceValidator1)}"
                                        (keyup)="validHandicappedAmt()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{!formGp.value.health_insurance_selfspouse80d_age ?
                                        limitData.self_insurance80d_agebelow60 :
                                        limitData.self_insurance80d_ageabove60}}</span></div>
                                <div *ngIf="(submitted || f.health_insurance_selfspouse80d_amount.dirty || f.health_insurance_selfspouse80d_amount.touched) && f.health_insurance_selfspouse80d_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.health_insurance_selfspouse80d_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.health_insurance_selfspouse80d_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                        <div
                                          *ngIf="!(f.health_insurance_selfspouse80d_amount.errors?.pattern) && f.health_insurance_selfspouse80d_amount.errors?.maxlength">
                                          {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="health_insurance_selfspouse80d_document"
                                class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(health_insurance_selfspouse80d_document_loader==false) && (formGp.value.health_insurance_selfspouse80d_document == '' || formGp.value.health_insurance_selfspouse80d_document == null)" (click)="currentId(14)"
                                [ngClass]="{ 'is-invalid': (filesizeError.health_insurance_selfspouse80d_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.health_insurance_selfspouse80d_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>

                            </div>
                            <app-file-upload [crop]="false" [round]="false"
                                [id]="'health_insurance_selfspouse80d_document'" [width]="504"
                                (dataURL)="getURL($event)" (loader)="getloader($event)" (fullfile)="getFilename($event)"
                                [FileType]="fileType" (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.health_insurance_selfspouse80d_document != '' && filesizeError.health_insurance_selfspouse80d_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!health_insurance_selfspouse80d_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.health_insurance_selfspouse80d_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.health_insurance_selfspouse80d_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.health_insurance_selfspouse80d_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.health_insurance_selfspouse80d_document,this.appservice.getDocName(formGp.value.health_insurance_selfspouse80d_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(14)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="health_insurance_selfspouse80d_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="health_insurance_selfspouse80d_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.health_insurance_selfspouse80d_document != '' && filesizeError.health_insurance_selfspouse80d_document != true">
                                <div class="col-12 mt-0" *ngIf="!health_insurance_selfspouse80d_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{health_insurance_selfspouse80d_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(14)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center"
                                *ngIf="health_insurance_selfspouse80d_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>

                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="health_insurance_parents80d" id=""
                                    class="form-check-input " formControlName="health_insurance_parents80d"
                                    (change)="checkBoxFunctionTest($event,'health_insurance_parents80d_amount','health_insurance_parents80d_document','','','health_insurance_parents80d_document_loader')">
                                <label for="" class="form-check-label">80D : Health insurance premium for dependent
                                    parents </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Employees can claim a tax deduction for their dependent parents' health insurance premiums up to ₹ 25,000 under Section 80D. If both or either of them is a senior citizen, the maximum amount claimed is ₹ 50,000."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.health_insurance_parents80d">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="health_insurance_parents80d_age" type="radio" id="deduction1"
                                        class="form-check-input" formControlName="health_insurance_parents80d_age"
                                        [value]="false"
                                        (change)="radioBtnChange($event,'health_insurance_parents80d','health_insurance_parents80d_amount','health_insurance_parents80d_document')">
                                    <label for="deduction1" class="form-check-label">Age : less than 60</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="health_insurance_parents80d_age" type="radio" id="deduction2"
                                        class="form-check-input " formControlName="health_insurance_parents80d_age"
                                        [value]="true"
                                        (change)="radioBtnChange($event,'health_insurance_parents80d','health_insurance_parents80d_amount','health_insurance_parents80d_document')">
                                    <label for="deduction2" class="form-check-label">Age : greater than 60</label>
                                </div>
                            </div>
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.health_insurance_parents80d_amount.dirty || f.health_insurance_parents80d_amount.touched) &&  (f.health_insurance_parents80d_amount.errors || experianceValidator1)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="health_insurance_parents80d_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="health_insurance_parents80d_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.health_insurance_parents80d_amount.dirty || f.health_insurance_parents80d_amount.touched) &&  (f.health_insurance_parents80d_amount.errors || experianceValidator1)}"
                                        (keyup)="validHandicappedAmt()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{!formGp.value.health_insurance_parents80d_age ?
                                        limitData.parent_insurance80d_agebelow60 :
                                        limitData.parent_insurance80d_ageabove60}}</span></div>
                                <div *ngIf="(submitted || f.health_insurance_parents80d_amount.dirty || f.health_insurance_parents80d_amount.touched) && f.health_insurance_parents80d_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.health_insurance_parents80d_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.health_insurance_parents80d_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                        <div
                                        *ngIf="!(f.health_insurance_parents80d_amount.errors?.pattern) && f.health_insurance_parents80d_amount.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>
                            </div>

                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="health_insurance_parents80d_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(health_insurance_parents80d_document_loader==false) && (formGp.value.health_insurance_parents80d_document == '' || formGp.value.health_insurance_parents80d_document == null)" (click)="currentId(15)"
                                [ngClass]="{ 'is-invalid': (filesizeError.health_insurance_parents80d_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.health_insurance_parents80d_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>

                            </div>
                            <app-file-upload [crop]="false" [round]="false"
                                [id]="'health_insurance_parents80d_document'" [width]="504" (dataURL)="getURL($event)"
                                (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.health_insurance_parents80d_document != '' && filesizeError.health_insurance_parents80d_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!health_insurance_parents80d_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.health_insurance_parents80d_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.health_insurance_parents80d_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.health_insurance_parents80d_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.health_insurance_parents80d_document,this.appservice.getDocName(formGp.value.health_insurance_parents80d_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(15)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="health_insurance_parents80d_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="health_insurance_parents80d_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.health_insurance_parents80d_document != '' && filesizeError.health_insurance_parents80d_document != true">
                                <div class="col-12 mt-0" *ngIf="!health_insurance_parents80d_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{health_insurance_parents80d_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(15)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="health_insurance_parents80d_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>

                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="health_checkup_selfspouse80d" id=""
                                    class="form-check-input " formControlName="health_checkup_selfspouse80d"
                                    (change)="checkBoxFunctionTest($event,'health_checkup_selfspouse80d_amount','health_checkup_selfspouse80d_document','','','health_checkup_selfspouse80d_document_loader')">
                                <label for="" class="form-check-label">80D : Preventive health checkup for self / spouse
                                    or dependent children </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Employee can avail tax deduction on the payment made towards preventive health checkup for self-spouse or dependent children upto 5,000 per financial year."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.health_checkup_selfspouse80d">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.health_checkup_selfspouse80d_amount.dirty || f.health_checkup_selfspouse80d_amount.touched) &&  (f.health_checkup_selfspouse80d_amount.errors || experianceValidator1)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="health_checkup_selfspouse80d_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="health_checkup_selfspouse80d_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.health_checkup_selfspouse80d_amount.dirty || f.health_checkup_selfspouse80d_amount.touched) &&  (f.health_checkup_selfspouse80d_amount.errors || experianceValidator1)}"
                                        (keyup)="validHandicappedAmt()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{limitData.self_checkup80d}}</span></div>
                                <div *ngIf="(submitted || f.health_checkup_selfspouse80d_amount.dirty || f.health_checkup_selfspouse80d_amount.touched) && f.health_checkup_selfspouse80d_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.health_checkup_selfspouse80d_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.health_checkup_selfspouse80d_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                      <div
                                        *ngIf="!(f.health_checkup_selfspouse80d_amount.errors?.pattern) && f.health_checkup_selfspouse80d_amount.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>
                            </div>

                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="health_checkup_selfspouse80d_document"
                                class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(health_checkup_selfspouse80d_document_loader==false) && (formGp.value.health_checkup_selfspouse80d_document == '') || (formGp.value.health_checkup_selfspouse80d_document == null)" (click)="currentId(16)"
                                [ngClass]="{ 'is-invalid': (filesizeError.health_checkup_selfspouse80d_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.health_checkup_selfspouse80d_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>

                            </div>
                            <app-file-upload [crop]="false" [round]="false"
                                [id]="'health_checkup_selfspouse80d_document'" [width]="504" (dataURL)="getURL($event)"
                                (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.health_checkup_selfspouse80d_document != '' && filesizeError.health_checkup_selfspouse80d_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!health_checkup_selfspouse80d_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.health_checkup_selfspouse80d_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.health_checkup_selfspouse80d_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.health_checkup_selfspouse80d_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.health_checkup_selfspouse80d_document,this.appservice.getDocName(formGp.value.health_checkup_selfspouse80d_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(16)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="health_checkup_selfspouse80d_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="health_checkup_selfspouse80d_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.health_checkup_selfspouse80d_document != '' && filesizeError.health_checkup_selfspouse80d_document != true">
                                <div class="col-12 mt-0" *ngIf="!health_checkup_selfspouse80d_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{health_checkup_selfspouse80d_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(16)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="health_checkup_selfspouse80d_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>

                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="health_checkup_parents80d" id="" class="form-check-input "
                                    formControlName="health_checkup_parents80d"
                                    (change)="checkBoxFunctionTest($event,'health_checkup_parents80d_amount','health_checkup_parents80d_document','','','health_checkup_parents80d_document_loader')">
                                <label for="" class="form-check-label">80D : Preventive health checkup for dependent
                                    parents </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Employee can avail tax deduction on the payment made towards preventive health checkup for dependent parents upto 5,000 per financial year."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.health_checkup_parents80d">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.health_checkup_parents80d_amount.dirty || f.health_checkup_parents80d_amount.touched) &&  (f.health_checkup_parents80d_amount.errors || experianceValidator1)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="health_checkup_parents80d_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="health_checkup_parents80d_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.health_checkup_parents80d_amount.dirty || f.health_checkup_parents80d_amount.touched) &&  (f.health_checkup_parents80d_amount.errors || experianceValidator1)}"
                                        (keyup)="validHandicappedAmt()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{limitData.parent_checkup80d}}</span></div>
                                <div *ngIf="(submitted || f.health_checkup_parents80d_amount.dirty || f.health_checkup_parents80d_amount.touched) && f.health_checkup_parents80d_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.health_checkup_parents80d_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.health_checkup_parents80d_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                        <div
                                          *ngIf="!(f.health_checkup_parents80d_amount.errors?.pattern) && f.health_checkup_parents80d_amount.errors?.maxlength">
                                          {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="health_checkup_parents80d_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(health_checkup_parents80d_document_loader==false) && (formGp.value.health_checkup_parents80d_document == '' || formGp.value.health_checkup_parents80d_document == null)" (click)="currentId(17)"
                                [ngClass]="{ 'is-invalid': (filesizeError.health_checkup_parents80d_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.health_checkup_parents80d_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>

                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'health_checkup_parents80d_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.health_checkup_parents80d_document != '' && filesizeError.health_checkup_parents80d_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!health_checkup_parents80d_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.health_checkup_parents80d_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.health_checkup_parents80d_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.health_checkup_parents80d_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.health_checkup_parents80d_document,this.appservice.getDocName(formGp.value.health_checkup_parents80d_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(17)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="health_checkup_parents80d_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="health_checkup_parents80d_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.health_checkup_parents80d_document != '' && filesizeError.health_checkup_parents80d_document != true">
                                <div class="col-12 mt-0" *ngIf="!health_checkup_parents80d_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{health_checkup_parents80d_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(17)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="health_checkup_parents80d_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>

                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="medicalbill_senior80d" id="" class="form-check-input "
                                    formControlName="medicalbill_senior80d"
                                    (change)="checkBoxFunctionTest($event,'medicalbill_senior80d_amount','medicalbill_senior80d_document','','','medicalbill_senior80d_document_loader')">
                                <label for="" class="form-check-label">Medical bills for super senior citizen </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Under section 80 D, a maximum deduction of up to 50,000 can be claimed in a fiscal year for medical expenses incurred on the healthcare of senior citizens (eligible parents) aged 60 and above."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.medicalbill_senior80d">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.medicalbill_senior80d_amount.dirty || f.medicalbill_senior80d_amount.touched) &&  (f.medicalbill_senior80d_amount.errors || experianceValidator1)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="medicalbill_senior80d_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="medicalbill_senior80d_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.medicalbill_senior80d_amount.dirty || f.medicalbill_senior80d_amount.touched) &&  (f.medicalbill_senior80d_amount.errors || experianceValidator1)}"
                                        (keyup)="validHandicappedAmt()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{limitData.medicalbill_senior80d}}</span></div>
                                <div *ngIf="(submitted || f.medicalbill_senior80d_amount.dirty || f.medicalbill_senior80d_amount.touched) && f.medicalbill_senior80d_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.medicalbill_senior80d_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.medicalbill_senior80d_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                      *ngIf="!(f.medicalbill_senior80d_amount.errors?.pattern) && f.medicalbill_senior80d_amount.errors?.maxlength">
                                      {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="medicalbill_senior80d_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(medicalbill_senior80d_document_loader==false) && (formGp.value.medicalbill_senior80d_document == '' || formGp.value.medicalbill_senior80d_document == null)" (click)="currentId(18)"
                                [ngClass]="{ 'is-invalid': (filesizeError.medicalbill_senior80d_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.medicalbill_senior80d_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>

                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'medicalbill_senior80d_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.medicalbill_senior80d_document != '' && filesizeError.medicalbill_senior80d_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!medicalbill_senior80d_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.medicalbill_senior80d_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.medicalbill_senior80d_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.medicalbill_senior80d_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.medicalbill_senior80d_document,this.appservice.getDocName(formGp.value.medicalbill_senior80d_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(18)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="medicalbill_senior80d_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="medicalbill_senior80d_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.medicalbill_senior80d_document != '' && filesizeError.medicalbill_senior80d_document != true">
                                <div class="col-12 mt-0" *ngIf="!medicalbill_senior80d_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{medicalbill_senior80d_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(18)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="medicalbill_senior80d_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <!-- END -->


                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="employee_additional_contribution" id=""
                                    class="form-check-input " formControlName="employee_additional_contribution"
                                    (change)="checkBoxFunctionTest($event,'employee_contribution_amount','employee_contribution_document','','','employee_contribution_document_loader')">
                                <label for="" class="form-check-label">80CCD1(B): Employee’s additional contribution to
                                    NPS</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Individuals who contribute to their pension accounts are eligible for a tax deduction under Section 80CCD1 (B). The maximum deduction allowed for an employee is 50,000."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.employee_additional_contribution">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.employee_contribution_amount.dirty || f.employee_contribution_amount.touched) &&  f.employee_contribution_amount.errors}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="employee_contribution_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="employee_contribution_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.employee_contribution_amount.dirty || f.employee_contribution_amount.touched) &&  f.employee_contribution_amount.errors}">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{limitData.nps_employee}}</span></div>
                                <div *ngIf="(submitted || f.employee_contribution_amount.dirty || f.employee_contribution_amount.touched) && f.employee_contribution_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.employee_contribution_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.employee_contribution_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                        <div
                                          *ngIf="!(f.employee_contribution_amount.errors?.pattern) && f.employee_contribution_amount.errors?.maxlength">
                                          {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                                    <div
                                        *ngIf="!(f.employee_contribution_amount.errors.pattern) && !(f.employee_contribution_amount.errors?.maxlength) && f.employee_contribution_amount.value > 50000">
                                        {{this.messageService.fieldlengthvalidation('amount','50000')}}
                                    </div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="employee_contribution_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(employee_contribution_document_loader==false) && (formGp.value.employee_contribution_document == '' || formGp.value.employee_contribution_document == null)" (click)="currentId(2)"
                                [ngClass]="{ 'is-invalid': (filesizeError.employee_contribution_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.employee_contribution_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'employee_contribution_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.employee_contribution_document != '' && filesizeError.employee_contribution_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!employee_contribution_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.employee_contribution_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.employee_contribution_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.employee_contribution_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.employee_contribution_document,this.appservice.getDocName(formGp.value.employee_contribution_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(2)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="employee_contribution_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="employee_contribution_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.employee_contribution_document != '' && filesizeError.employee_contribution_document != true">
                                <div class="col-12 mt-0" *ngIf="!employee_contribution_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{employee_contribution_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(2)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="employee_contribution_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="employer_contribution" id="" class="form-check-input "
                                    formControlName="employer_contribution"
                                    (change)="checkBoxFunctionTest($event,'employer_contribution_amount','employer_contribution_document','','','employer_contribution_document_loader')">
                                <label for="" class="form-check-label">80CCD(2): Employer’s contribution to NPS account,
                                    deducted from salary</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Under Section 80CCD(2), employees can claim a deduction for employer contributions to their NPS account, with a maximum of 10% of (Basic + DA) in the old tax regime and 14% of (Basic + DA) in the new tax regime."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <div class=" col-md-6 form-row">
                            <label for="" class="form-label">Amount</label>
                            <div class="form-icon icon-right"
                                [ngClass]="{ 'is-invalid': (submitted || f.employer_contribution_amount.dirty || f.employer_contribution_amount.touched) &&  f.employer_contribution_amount.errors}">
                                <input type="text" class="form-control" autocomplete="off"
                                    name="employer_contribution_amount" id="name"
                                    placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                    formControlName="employer_contribution_amount"
                                    [ngClass]="{ 'is-invalid': (submitted || f.employer_contribution_amount.dirty || f.employer_contribution_amount.touched) &&  f.employer_contribution_amount.errors}">
                                <span>{{currency}}</span>
                            </div>
                            <!-- <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                    class="fw-500">{{empSalary}}</span></div> -->
                            <div *ngIf="(submitted || f.employer_contribution_amount.dirty || f.employer_contribution_amount.touched) && f.employer_contribution_amount.errors"
                                class="invalid-feedback">
                                <div *ngIf="f.employer_contribution_amount.errors.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="f.employer_contribution_amount.errors.pattern">
                                    {{this.messageService.validationDisplay('amount')}}</div>
                                <div
                                    *ngIf="!(f.employer_contribution_amount.errors.pattern) && f.employer_contribution_amount.errors.maxlength">
                                    {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                            </div>
                        </div>

                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="tta_ttb" id="" class="form-check-input "
                                    formControlName="tta_ttb"
                                    (change)="checkBoxFunctionTest($event,'tta_ttb_amount','tta_tta_document','','','tta_tta_document_loader')">
                                <label for="" class="form-check-label">80TTA: Exemption of interest from banks, post
                                    office, etc. <span class=" ms-8"><i
                                            class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="Section 80TTA provides deduction on the interest earned on your savings account with a bank, cooperative society or post office. The maximum deduction that can be claimed is upto ₹10,000"
                                            popoverClass="popover-default popover-info"
                                            triggers="mouseenter:mouseleave"></i></span> (or) 80TTB: Exemption of
                                    interest from banks, post office, etc. <span class=" ms-8"><i
                                            class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="This option is applicable only for senior citizen. You can claim a deduction of up to 10,000/- or 50,000/- on interest income from a savings bank account. This deduction is not available on interest income from fixed deposits, recurring deposits, or interest income from corporate bonds."
                                            popoverClass="popover-default popover-info"
                                            triggers="mouseenter:mouseleave"></i></span></label>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.tta_ttb">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.tta_ttb_amount.dirty || f.tta_ttb_amount.touched) &&  f.tta_ttb_amount.errors}">
                                    <input type="text" class="form-control" autocomplete="off" name="tta_ttb_amount"
                                        id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="tta_ttb_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.tta_ttb_amount.dirty || f.tta_ttb_amount.touched) &&  f.tta_ttb_amount.errors}">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{employeeAge <= 60 ? limitData.exemption_80tta_agebelow60 :
                                            limitData.exemption_80ttb_ageabove60}}</span>
                                </div>
                                <div *ngIf="(submitted || f.tta_ttb_amount.dirty || f.tta_ttb_amount.touched) && f.tta_ttb_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.tta_ttb_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.tta_ttb_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div *ngIf="!(f.tta_ttb_amount.errors.pattern) && f.tta_ttb_amount.errors.max">
                                        {{employeeAge <= 60 ?
                                            this.messageService.fieldlengthvalidation('amount','10,000') :
                                            this.messageService.fieldlengthvalidation('amount', '50,000' ) }}</div>
                                    <div
                                    *ngIf="!(f.tta_ttb_amount.errors?.pattern) && f.tta_ttb_amount.errors?.maxlength">
                                    {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                    </div>
                                </div>
                                <!-- upload doc -->
                                <div class="col-12">
                                <label for="tta_tta_document" class="form-label upload-doc fs-16 fw-500"
                                    *ngIf="(tta_tta_document_loader==false) && (formGp.value.tta_tta_document == '' || formGp.value.tta_tta_document == null)" (click)="currentId(4)"
                                    [ngClass]="{ 'is-invalid': (filesizeError.tta_tta_document == true)  }">
                                    <i class="bi bi-cloud-upload m-1"></i>Upload document
                                </label>
                                <div *ngIf="(filesizeError.tta_tta_document == true)" class="invalid-feedback">
                                    <div>{{fileError}}</div>
                                </div>
                                <app-file-upload [crop]="false" [round]="false" [id]="'tta_tta_document'" [width]="504"
                                    (dataURL)="getURL($event)" (loader)="getloader($event)"
                                    (fullfile)="getFilename($event)" [FileType]="fileType"
                                    (validsize)="getvalidsize($event)"></app-file-upload>

                                    <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.tta_tta_document != '' && filesizeError.tta_tta_document != true">
                                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                        <ng-container *ngIf="!tta_tta_document_loader">
                                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                          <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.tta_tta_document)}}</span>
                                          <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.tta_tta_document)}}</span>
                                          <div class="d-flex gap-8">
                                            <div class="link-primary" (click)="viewDocument(formGp.value.tta_tta_document)">
                                              <i class="bi bi-eye me-1"></i>View
                                            </div>
                                            <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.tta_tta_document,this.appservice.getDocName(formGp.value.tta_tta_document))">
                                              <i class="bi bi-download me-1"></i>Download
                                            </div>
                                          </div>
                                        </div>
                                        <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(4)"></i>
                                      </ng-container>

                                      </div>
                                    </div>

                                    <div *ngIf="tta_tta_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                        <div class="  flex-center text-center"  *ngIf="tta_tta_document_loader">
                                          <div class="fs-12 text-muted">Loading </div>
                                          <div class="ms-4 dot-pulse"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <!-- <ng-container
                                    *ngIf="formGp.value.tta_tta_document != '' && filesizeError.tta_tta_document != true">
                                    <div class="col-12 mt-0" *ngIf="!tta_tta_document_loader">
                                        <ul class="selected-values ">
                                            <li class="ng-value">
                                                <span class="">{{tta_tta_document}}</span>
                                                <span aria-hidden="true" class=" right">
                                                    <i class="icon-close fs-16 flex-center ms-1"
                                                        (click)="deleteDoc(4)"></i>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-container>
                                <div class="  flex-center text-center" *ngIf="tta_tta_document_loader">
                                    <div class="fs-12 text-muted">Loading </div>
                                    <div class="ms-4 dot-pulse"></div>
                                </div> -->
                                <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="interest_education_loan" id="" class="form-check-input "
                                    formControlName="interest_education_loan"
                                    (change)="checkBoxFunctionTest($event,'interest_education_amount','interest_education_document','','','interest_education_document_loader')">
                                <label for="" class="form-check-label">80E: Interest on education loan</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="If you have taken an education loan and are repaying the same, then the interest paid on that education loan is allowed as a deduction from the total income under Section 80E. The deduction is available for a maximum of 8 years. The deduction amount is unlimited."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.interest_education_loan">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.interest_education_amount.dirty || f.interest_education_amount.touched) &&  f.interest_education_amount.errors}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="interest_education_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="interest_education_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.interest_education_amount.dirty || f.interest_education_amount.touched) &&  f.interest_education_amount.errors}">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{limitData.interest_educationloan_80e}}</span></div>
                                <div *ngIf="(submitted || f.interest_education_amount.dirty || f.interest_education_amount.touched) && f.interest_education_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.interest_education_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.interest_education_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                        *ngIf="!(f.interest_education_amount.errors.pattern) && f.interest_education_amount.errors.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}
                                    </div>

                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="interest_education_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(interest_education_document_loader==false) && (formGp.value.interest_education_document == '' || formGp.value.interest_education_document == null)" (click)="currentId(5)"
                                [ngClass]="{ 'is-invalid': (filesizeError.interest_education_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.interest_education_document == true)" class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'interest_education_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.interest_education_document != '' && filesizeError.interest_education_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!interest_education_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.interest_education_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.interest_education_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.interest_education_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.interest_education_document,this.appservice.getDocName(formGp.value.interest_education_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(5)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="interest_education_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="interest_education_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.interest_education_document != '' && filesizeError.interest_education_document != true">
                                <div class="col-12 mt-0" *ngIf="!interest_education_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{interest_education_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(5)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="interest_education_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="interest_home_loan_80ee" id="" class="form-check-input "
                                    formControlName="interest_home_loan_80ee"
                                    (change)="checkBoxFunctionTest($event,'interest_home_loan_amount80ee','','','','')">
                                <label for="" class="form-check-label">80EE: Additional interest on home loan for
                                    first-time homeowners borrowed between 1st april 2016 to 31st march 2017
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="If you are a first time home buyer, you can claim income tax benefit under this section. The value of the property purchased must be less than ₹50, 00,000/- and the home loan must be less than ₹35, 00,000/- . Through this section, an additional deduction of ₹ 50,000 can be claimed on home loan interest. The loan must be taken from a financial institution and must have been sanctioned (between 01st April 2016 to 31st March 2017)."
                                            popoverClass="popover-default popover-info"
                                            triggers="mouseenter:mouseleave"></i></span>
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.interest_home_loan_80ee">
                            <div class="col-12 form-row">
                                <p>I hereby declare that:</p>
                            </div>
                            <div class="col-12 form-row mt-0">
                                <div class="form-check fs-14">
                                    <input type="checkbox" name="homeloan80ee_option1" id="" class="form-check-input "
                                        formControlName="homeloan80ee_option1">
                                    <label for="" class="form-check-label">1. First residential property</label>
                                </div>
                            </div>
                            <div class="col-12 form-row">
                                <div class="form-check fs-14">
                                    <input type="checkbox" name="homeloan80ee_option2" id="" class="form-check-input "
                                        formControlName="homeloan80ee_option2">
                                    <label for="" class="form-check-label">2. Loan amount should be less than 35
                                        lac.</label>
                                </div>
                            </div>
                            <div class="col-12 form-row">
                                <div class="form-check fs-14">
                                    <input type="checkbox" name="homeloan80ee_option3" id="" class="form-check-input "
                                        formControlName="homeloan80ee_option3">
                                    <label for="" class="form-check-label">3. The property value should be less than 50
                                        lac.</label>
                                </div>
                            </div>
                            <div class="col-12 form-row">
                                <div class="form-check fs-14">
                                    <input type="checkbox" name="homeloan80ee_option4" id="" class="form-check-input "
                                        formControlName="homeloan80ee_option4">
                                    <label for="" class="form-check-label">4. The loan should be sanctioned between
                                        <b>1st april 2016 to 31st march 2017</b></label>
                                </div>
                            </div>
                            <ng-container
                                *ngIf="formGp.value.homeloan80ee_option1 && formGp.value.homeloan80ee_option2 && formGp.value.homeloan80ee_option3 && formGp.value.homeloan80ee_option4">
                                <div class=" col-md-6 form-row">
                                    <label for="" class="form-label required">Amount</label>
                                    <div class="form-icon icon-right"
                                        [ngClass]="{ 'is-invalid': (submitted || f.interest_home_loan_amount80ee.dirty || f.interest_home_loan_amount80ee.touched) &&  f.interest_home_loan_amount80ee.errors}">
                                        <input type="text" class="form-control" autocomplete="off"
                                            name="interest_home_loan_amount80ee" id="name"
                                            placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                            formControlName="interest_home_loan_amount80ee"
                                            [ngClass]="{ 'is-invalid': (submitted || f.interest_home_loan_amount80ee.dirty || f.interest_home_loan_amount80ee.touched) &&  f.interest_home_loan_amount80ee.errors}">
                                        <span>{{currency}}</span>
                                    </div>
                                    <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                            class="fw-500">{{limitData.interest_homeloan_80ee}}</span></div>
                                    <div *ngIf="(submitted || f.interest_home_loan_amount80ee.dirty || f.interest_home_loan_amount80ee.touched) && f.interest_home_loan_amount80ee.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.interest_home_loan_amount80ee.errors.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                        <div *ngIf="f.interest_home_loan_amount80ee.errors.pattern">
                                            {{this.messageService.validationDisplay('amount')}}</div>

                                        <div
                                            *ngIf="!(f.interest_home_loan_amount80ee.errors.pattern) && f.interest_home_loan_amount80ee.errors.max">
                                            {{this.messageService.fieldlengthvalidation('amount','50,000')}}</div>
                                            <div
                                              *ngIf="!(f.interest_home_loan_amount80ee.errors?.pattern) && f.interest_home_loan_amount80ee.errors?.maxlength">
                                              {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                    </div>
                                </div>

                                <!-- upload doc -->
                                <div class="col-12">
                                <label for="interest_home_loan_document80ee" class="form-label upload-doc fs-16 fw-500"
                                    *ngIf="(interest_home_loan_document80ee_loader==false) && (formGp.value.interest_home_loan_document80ee == '' || formGp.value.interest_home_loan_document80ee == null)" (click)="currentId(13)"
                                    [ngClass]="{ 'is-invalid': (filesizeError.interest_home_loan_document80ee == true)  }">
                                    <i class="bi bi-cloud-upload m-1"></i>Upload document
                                </label>
                                <div *ngIf="(filesizeError.interest_home_loan_document80ee == true)"
                                    class="invalid-feedback">
                                    <div>{{fileError}}</div>
                                </div>
                                <app-file-upload [crop]="false" [round]="false" [id]="'interest_home_loan_document80ee'"
                                    [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                    (fullfile)="getFilename($event)" [FileType]="fileType"
                                    (validsize)="getvalidsize($event)"></app-file-upload>

                                    <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.interest_home_loan_document80ee != '' && filesizeError.interest_home_loan_document80ee != true">
                                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                        <ng-container *ngIf="!interest_home_loan_document80ee_loader">
                                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                          <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.interest_home_loan_document80ee)}}</span>
                                          <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.interest_home_loan_document80ee)}}</span>
                                          <div class="d-flex gap-8">
                                            <div class="link-primary" (click)="viewDocument(formGp.value.interest_home_loan_document80ee)">
                                              <i class="bi bi-eye me-1"></i>View
                                            </div>
                                            <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.interest_home_loan_document80ee,this.appservice.getDocName(formGp.value.interest_home_loan_document80ee))">
                                              <i class="bi bi-download me-1"></i>Download
                                            </div>
                                          </div>
                                        </div>
                                        <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(13)"></i>
                                      </ng-container>

                                      </div>
                                    </div>

                                    <div *ngIf="interest_home_loan_document80ee_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                        <div class="  flex-center text-center"  *ngIf="interest_home_loan_document80ee_loader">
                                          <div class="fs-12 text-muted">Loading </div>
                                          <div class="ms-4 dot-pulse"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <!-- <ng-container
                                    *ngIf="formGp.value.interest_home_loan_document80ee != '' && filesizeError.interest_home_loan_document80ee != true">
                                    <div class="col-12 mt-0" *ngIf="!interest_home_loan_document80ee_loader">
                                        <ul class="selected-values ">
                                            <li class="ng-value">
                                                <span class="">{{interest_home_loan_document80ee}}</span>
                                                <span aria-hidden="true" class=" right">
                                                    <i class="icon-close fs-16 flex-center ms-1"
                                                        (click)="deleteDoc(13)"></i>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-container>
                                <div class="  flex-center text-center" *ngIf="interest_home_loan_document80ee_loader">
                                    <div class="fs-12 text-muted">Loading </div>
                                    <div class="ms-4 dot-pulse"></div>
                                </div> -->
                            </ng-container>

                        </ng-container>

                        <!-- NEW ITEM -->
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="interest_home_loan_80eea" id="" class="form-check-input "
                                    formControlName="interest_home_loan_80eea"
                                    (change)="checkBoxFunctionTest($event,'interest_home_loan_amount80eea','interest_home_loan_document80eea','','','interest_home_loan_document80eea_loader')">
                                <label for="" class="form-check-label">80EEA: Additional interest on home loan for
                                    first-time homeowners borrowed between 1st april 2019 to 31st march 2022
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="To avail the benefits under the Section 80EEA, the home loan must be sanctioned during the period April 1 2019 to March 31 2022, the value of the house property should be less than ₹ 45, 00,000/-.It should be a first residential property."
                                            popoverClass="popover-default popover-info"
                                            triggers="mouseenter:mouseleave"></i></span>
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.interest_home_loan_80eea">
                            <div class="col-12 form-row">
                                <p>I hereby declare that:</p>
                            </div>
                            <div class="col-12 form-row mt-0">
                                <div class="form-check fs-14">
                                    <input type="checkbox" name="homeloan80eea_option1" id="" class="form-check-input "
                                        formControlName="homeloan80eea_option1">
                                    <label for="" class="form-check-label">1. First residential property</label>
                                </div>
                            </div>
                            <div class="col-12 form-row">
                                <div class="form-check fs-14">
                                    <input type="checkbox" name="homeloan80eea_option2" id="" class="form-check-input "
                                        formControlName="homeloan80eea_option2">
                                    <label for="" class="form-check-label">2. Loan amount should be less than 45
                                        lac.</label>
                                </div>
                            </div>
                            <div class="col-12 form-row">
                                <div class="form-check fs-14">
                                    <input type="checkbox" name="homeloan80eea_option3" id="" class="form-check-input "
                                        formControlName="homeloan80eea_option3">
                                    <label for="" class="form-check-label">3. The loan should be sanctioned between
                                        <b>1st april 2019 to 31st march 2022</b></label>
                                </div>
                            </div>
                            <ng-container
                                *ngIf="formGp.value.homeloan80eea_option1 && formGp.value.homeloan80eea_option2 && formGp.value.homeloan80eea_option3">

                                <div class=" col-md-6 form-row">
                                    <label for="" class="form-label required">Amount</label>
                                    <div class="form-icon icon-right"
                                        [ngClass]="{ 'is-invalid': (submitted || f.interest_home_loan_amount80eea.dirty || f.interest_home_loan_amount80eea.touched) &&  f.interest_home_loan_amount80eea.errors}">
                                        <input type="text" class="form-control" autocomplete="off"
                                            name="interest_home_loan_amount80eea" id="name"
                                            placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                            formControlName="interest_home_loan_amount80eea"
                                            [ngClass]="{ 'is-invalid': (submitted || f.interest_home_loan_amount80eea.dirty || f.interest_home_loan_amount80eea.touched) &&  f.interest_home_loan_amount80eea.errors}">
                                        <span>{{currency}}</span>
                                    </div>
                                    <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                            class="fw-500">{{limitData.interest_homeloan_80eea}}</span></div>
                                    <div *ngIf="(submitted || f.interest_home_loan_amount80eea.dirty || f.interest_home_loan_amount80eea.touched) && f.interest_home_loan_amount80eea.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.interest_home_loan_amount80eea.errors.pattern">
                                            {{this.messageService.validationDisplay('amount')}}</div>
                                        <div *ngIf="f.interest_home_loan_amount80eea.errors.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                        <div
                                          *ngIf="!(f.interest_home_loan_amount80eea.errors?.pattern) && f.interest_home_loan_amount80eea.errors?.maxlength">
                                          {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                                    </div>
                                </div>
                                <!-- upload doc -->
                                <div class="col-12">
                                <label for="interest_home_loan_document80eea" class="form-label upload-doc fs-16 fw-500"
                                    *ngIf="(interest_home_loan_document80eea_loader==false) && (formGp.value.interest_home_loan_document80eea == '' || formGp.value.interest_home_loan_document80eea == null)" (click)="currentId(19)"
                                    [ngClass]="{ 'is-invalid': (filesizeError.interest_home_loan_document80eea == true)  }">
                                    <i class="bi bi-cloud-upload m-1"></i>Upload document
                                </label>
                                <div *ngIf="(filesizeError.interest_home_loan_document80eea == true)"
                                    class="invalid-feedback">
                                    <div>{{fileError}}</div>

                                </div>
                                <app-file-upload [crop]="false" [round]="false"
                                    [id]="'interest_home_loan_document80eea'" [width]="504" (dataURL)="getURL($event)"
                                    (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType"
                                    (validsize)="getvalidsize($event)"></app-file-upload>

                                    <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.interest_home_loan_document80eea != '' && filesizeError.interest_home_loan_document80eea != true">
                                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                        <ng-container *ngIf="!interest_home_loan_document80eea_loader">
                                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                          <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.interest_home_loan_document80eea)}}</span>
                                          <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.interest_home_loan_document80eea)}}</span>
                                          <div class="d-flex gap-8">
                                            <div class="link-primary" (click)="viewDocument(formGp.value.interest_home_loan_document80eea)">
                                              <i class="bi bi-eye me-1"></i>View
                                            </div>
                                            <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.interest_home_loan_document80eea,this.appservice.getDocName(formGp.value.interest_home_loan_document80eea))">
                                              <i class="bi bi-download me-1"></i>Download
                                            </div>
                                          </div>
                                        </div>
                                        <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(19)"></i>
                                      </ng-container>

                                      </div>
                                    </div>

                                    <div *ngIf="interest_home_loan_document80eea_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                        <div class="  flex-center text-center"  *ngIf="interest_home_loan_document80eea_loader">
                                          <div class="fs-12 text-muted">Loading </div>
                                          <div class="ms-4 dot-pulse"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <!-- <ng-container
                                    *ngIf="formGp.value.interest_home_loan_document80eea != '' && filesizeError.interest_home_loan_document80eea != true">
                                    <div class="col-12 mt-0" *ngIf="!interest_home_loan_document80eea_loader">
                                        <ul class="selected-values ">
                                            <li class="ng-value">
                                                <span class="">{{interest_home_loan_document80eea}}</span>
                                                <span aria-hidden="true" class=" right">
                                                    <i class="icon-close fs-16 flex-center ms-1"
                                                        (click)="deleteDoc(19)"></i>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-container>
                                <div class="  flex-center text-center" *ngIf="interest_home_loan_document80eea_loader">
                                    <div class="fs-12 text-muted">Loading </div>
                                    <div class="ms-4 dot-pulse"></div>
                                </div> -->
                                <!-- upload doc end-->
                            </ng-container>
                        </ng-container>

                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="interest_electrical_vehicle_loan_80eeb" id=""
                                    class="form-check-input " formControlName="interest_electrical_vehicle_loan_80eeb"
                                    (change)="checkBoxFunctionTest($event,'interest_electrical_vehicle_loan_80eeb','interest_electrical_vehicle_loan_document','','','interest_electrical_vehicle_loan_document_loader')">
                                <label for="" class="form-check-label">80EEB: Interest on electrical vehicle loan
                                    borrowed between 1st april 2019 to 31st march 2023 </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Under Section 80EEB, employee can claim the interest paid on the vehicle loan. Maximum deduction up to ₹ 1, 50,000/- can be claimed."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.interest_electrical_vehicle_loan_80eeb">

                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.interest_electrical_vehicle_loan_amount.dirty || f.interest_electrical_vehicle_loan_amount.touched) &&  f.interest_electrical_vehicle_loan_amount.errors}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="interest_electrical_vehicle_loan_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="interest_electrical_vehicle_loan_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.interest_electrical_vehicle_loan_amount.dirty || f.interest_electrical_vehicle_loan_amount.touched) &&  f.interest_electrical_vehicle_loan_amount.errors}">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{limitData.interest_electricvehicleloan_80eeb}}</span></div>
                                <div *ngIf="(submitted || f.interest_electrical_vehicle_loan_amount.dirty || f.interest_electrical_vehicle_loan_amount.touched) && f.interest_electrical_vehicle_loan_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.interest_electrical_vehicle_loan_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div *ngIf="f.interest_electrical_vehicle_loan_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div
                                      *ngIf="!(f.interest_electrical_vehicle_loan_amount.errors?.pattern) && f.interest_electrical_vehicle_loan_amount.errors?.maxlength">
                                      {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="interest_electrical_vehicle_loan_document"
                                class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(interest_electrical_vehicle_loan_document_loader==false) && (formGp.value.interest_electrical_vehicle_loan_document == '' || formGp.value.interest_electrical_vehicle_loan_document == null)"
                                (click)="currentId(20)"
                                [ngClass]="{ 'is-invalid': (filesizeError.interest_electrical_vehicle_loan_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.interest_electrical_vehicle_loan_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>

                            </div>
                            <app-file-upload [crop]="false" [round]="false"
                                [id]="'interest_electrical_vehicle_loan_document'" [width]="504"
                                (dataURL)="getURL($event)" (loader)="getloader($event)" (fullfile)="getFilename($event)"
                                [FileType]="fileType" (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.interest_electrical_vehicle_loan_document != '' && filesizeError.interest_electrical_vehicle_loan_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!interest_electrical_vehicle_loan_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.interest_electrical_vehicle_loan_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.interest_electrical_vehicle_loan_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.interest_electrical_vehicle_loan_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.interest_electrical_vehicle_loan_document,this.appservice.getDocName(formGp.value.interest_electrical_vehicle_loan_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(20)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="interest_electrical_vehicle_loan_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="interest_electrical_vehicle_loan_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.interest_electrical_vehicle_loan_document != '' && filesizeError.interest_electrical_vehicle_loan_document != true">
                                <div class="col-12 mt-0" *ngIf="!interest_electrical_vehicle_loan_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{interest_electrical_vehicle_loan_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(20)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center"
                                *ngIf="interest_electrical_vehicle_loan_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <!-- END -->
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="medical_treatment_handicapped" id=""
                                    class="form-check-input " formControlName="medical_treatment_handicapped"
                                    (change)="checkBoxFunctionTest($event,'handicapped_dependent_amount','handicapped_dependent_document','disabled_severely','','handicapped_dependent_document_loader')">
                                <label for="" class="form-check-label">80 DD: Medical treatment for handicapped
                                    dependent </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Individuals who have a disabled dependent including parents, spouse, siblings or children, or an HUF with a disabled family member can claim deductions under Section 80DD. You can claim a deduction up to ₹1.25 lakh."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.medical_treatment_handicapped">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="disabled_severely" type="radio" id="deduction1"
                                        class="form-check-input" formControlName="disabled_severely" [value]="false"
                                        (change)="radioBtnChange($event,'medical_treatment_handicapped','handicapped_dependent_amount','handicapped_dependent_document')">
                                    <label for="deduction1" class="form-check-label">Disabled</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="disabled_severely" type="radio" id="deduction2"
                                        class="form-check-input " formControlName="disabled_severely" [value]="true"
                                        (change)="radioBtnChange($event,'medical_treatment_handicapped','handicapped_dependent_amount','handicapped_dependent_document')">
                                    <label for="deduction2" class="form-check-label">Severely disabled</label>
                                </div>
                            </div>
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.handicapped_dependent_amount.dirty || f.handicapped_dependent_amount.touched) &&  (f.handicapped_dependent_amount.errors || experianceValidator1)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="handicapped_dependent_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="handicapped_dependent_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.handicapped_dependent_amount.dirty || f.handicapped_dependent_amount.touched) &&  (f.handicapped_dependent_amount.errors || experianceValidator1)}"
                                        (keyup)="validHandicappedAmt()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{formGp.value.disabled_severely ?
                                        limitData.handicapped_80dd_severely :
                                        limitData.handicapped_80dd_disabed}}</span></div>
                                <div *ngIf="(submitted || f.handicapped_dependent_amount.dirty || f.handicapped_dependent_amount.touched) && f.handicapped_dependent_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.handicapped_dependent_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.handicapped_dependent_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                      *ngIf="!(f.handicapped_dependent_amount.errors?.pattern) && f.handicapped_dependent_amount.errors?.maxlength">
                                      {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>
                                <div *ngIf="(submitted || f.handicapped_dependent_amount.dirty || f.handicapped_dependent_amount.touched) && experianceValidator1==true"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="!(f.handicapped_dependent_amount?.errors?.pattern) && experianceValidator1==true">
                                        {{amountMsg1}}</div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="handicapped_dependent_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(handicapped_dependent_document_loader==false) && (formGp.value.handicapped_dependent_document == '' || formGp.value.handicapped_dependent_document == null)" (click)="currentId(6)"
                                [ngClass]="{ 'is-invalid': (filesizeError.handicapped_dependent_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.handicapped_dependent_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'handicapped_dependent_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.handicapped_dependent_document != '' && filesizeError.handicapped_dependent_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!handicapped_dependent_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.handicapped_dependent_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.handicapped_dependent_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.handicapped_dependent_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.handicapped_dependent_document,this.appservice.getDocName(formGp.value.handicapped_dependent_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(6)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="handicapped_dependent_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="handicapped_dependent_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.handicapped_dependent_document != '' && filesizeError.handicapped_dependent_document != true">
                                <div class="col-12 mt-0" *ngIf="!handicapped_dependent_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{handicapped_dependent_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(6)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="handicapped_dependent_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="medical_expenditure" id="" class="form-check-input "
                                    formControlName="medical_expenditure"
                                    (change)="checkBoxFunctionTest($event,'medical_expenditure_amount','medical_expenditure_document','year_60_more','issue','medical_expenditure_document_loader')">
                                <label for="" class="form-check-label">80DDB: Medical expenditure as per rule
                                    11DD</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="The deduction under this section is available towards the amount actually paid for the medical treatment of the specified disease. This deduction is applicable to a resident individual or a HUF. The deduction that can be claimed under this section is ₹ 40,000/- or ₹1, 00,000 /-depending on the age."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.medical_expenditure">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="year_60_more" type="radio" id="deduction1" class="form-check-input  "
                                        formControlName="year_60_more" [value]="false"
                                        (change)="radioBtnChange($event,'medical_expenditure','medical_expenditure_amount','medical_expenditure_document')">
                                    <label for="deduction1" class="form-check-label">Upto 60 years</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="year_60_more" type="radio" id="deduction2" class="form-check-input "
                                        formControlName="year_60_more" [value]="true"
                                        (change)="radioBtnChange($event,'medical_expenditure','medical_expenditure_amount','medical_expenditure_document')">
                                    <label for="deduction2" class="form-check-label">More than 60 years</label>
                                </div>
                            </div>
                            <div class=" col-md-6  form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.medical_expenditure_amount.dirty || f.medical_expenditure_amount.touched) &&  (f.medical_expenditure_amount.errors || experianceValidator2)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="medical_expenditure_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="medical_expenditure_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.medical_expenditure_amount.dirty || f.medical_expenditure_amount.touched) &&  (f.medical_expenditure_amount.errors || experianceValidator2)}"
                                        (keyup)="validExpenditure()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{formGp.value.year_60_more ?
                                        limitData.medicalexpenditure_80ddb_ageabove60 :
                                        limitData.medicalexpenditure_80ddb_ageupto60}}</span></div>
                                <div *ngIf="(submitted || f.medical_expenditure_amount.dirty || f.medical_expenditure_amount.touched) && f.medical_expenditure_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.medical_expenditure_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.medical_expenditure_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                      *ngIf="!(f.medical_expenditure_amount.errors?.pattern) && f.medical_expenditure_amount.errors?.maxlength">
                                      {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                                </div>
                                <div *ngIf="(submitted || f.medical_expenditure_amount.dirty || f.medical_expenditure_amount.touched) && experianceValidator2==true"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="!(f.medical_expenditure_amount?.errors?.pattern) && experianceValidator2==true">
                                        {{amountMsg2}}</div>
                                </div>
                            </div><br>
                            <div class="col-md-6 form-row">
                                <label for="" class="form-label required">Issue </label>
                                <app-master-select class="config-app-master" [placeholder]="issueDropdownPlaceHolder"
                                    id="issue" [submitted]="submitted" [isrequired]="isrequired"
                                    [selectedItem]="f.issue.value" formControlName="issue" [type]="issues"
                                    [ngClass]="{ 'is-invalid': (submitted || f.issue.dirty || f.issue.touched) &&  (f.issue.errors)}"></app-master-select>
                                <div *ngIf="(submitted || f.issue.dirty || f.issue.touched) && f.issue.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.issue.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="medical_expenditure_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(medical_expenditure_document_loader==false) && (formGp.value.medical_expenditure_document == '' || formGp.value.medical_expenditure_document == null)" (click)="currentId(7)"
                                [ngClass]="{ 'is-invalid': (filesizeError.medical_expenditure_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.medical_expenditure_document == true)" class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'medical_expenditure_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.medical_expenditure_document != '' && filesizeError.medical_expenditure_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!medical_expenditure_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.medical_expenditure_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.medical_expenditure_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.medical_expenditure_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.medical_expenditure_document,this.appservice.getDocName(formGp.value.medical_expenditure_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(7)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="medical_expenditure_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="medical_expenditure_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.medical_expenditure_document != '' && filesizeError.medical_expenditure_document != true">
                                <div class="col-12 mt-0" *ngIf="!medical_expenditure_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{medical_expenditure_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(7)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="medical_expenditure_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->

                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="self_suffering_disability" id="" class="form-check-input "
                                    formControlName="self_suffering_disability"
                                    (change)="checkBoxFunctionTest($event,'self_suffering_amount','self_suffering_document','self_disabled_severely','','self_suffering_document_loader')">
                                <label for="" class="form-check-label">80U: Self-suffering from disability</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Under this section, A deduction of ₹75,000/- is available to a resident individual who suffers from a physical disability (including blindness) or mental retardation. In case of severe disability, deduction of ₹1, 25,000/- can be claimed."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.self_suffering_disability">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="self_disabled_severely" type="radio" id="deduction1"
                                        class="form-check-input  " formControlName="self_disabled_severely"
                                        [value]="false"
                                        (change)="radioBtnChange($event,'self_suffering_disability','self_suffering_amount','self_suffering_document')">
                                    <label for="deduction1" class="form-check-label">Disabled</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="self_disabled_severely" type="radio" id="deduction2"
                                        class="form-check-input " formControlName="self_disabled_severely"
                                        [value]="true"
                                        (change)="radioBtnChange($event,'self_suffering_disability','self_suffering_amount','self_suffering_document')">
                                    <label for="deduction2" class="form-check-label">Severely disabled</label>
                                </div>
                            </div>
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.self_suffering_amount.dirty || f.self_suffering_amount.touched) &&  (f.self_suffering_amount.errors || experianceValidator3)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="self_suffering_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="self_suffering_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.self_suffering_amount.dirty || f.self_suffering_amount.touched) &&  (f.self_suffering_amount.errors || experianceValidator3)}"
                                        (keyup)="validSelfsuffering()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{formGp.value.self_disabled_severely ?
                                        limitData.selfsuffering_80u_severely :
                                        limitData.selfsuffering_80u_disabled}}</span></div>
                                <div *ngIf="(submitted || f.self_suffering_amount.dirty || f.self_suffering_amount.touched) && f.self_suffering_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.self_suffering_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.self_suffering_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                        *ngIf="!(f.self_suffering_amount.errors?.pattern) && f.self_suffering_amount.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                                </div>
                                <div *ngIf="(submitted || f.self_suffering_amount.dirty ) && experianceValidator3==true"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="!(f.self_suffering_amount?.errors?.pattern) && experianceValidator3==true">
                                        {{amountMsg3}}</div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="self_suffering_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(self_suffering_document_loader==false) && (formGp.value.self_suffering_document == '' || formGp.value.self_suffering_document == null)" (click)="currentId(8)"
                                [ngClass]="{ 'is-invalid': (filesizeError.self_suffering_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.self_suffering_document == true)" class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'self_suffering_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.self_suffering_document != '' && filesizeError.self_suffering_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!self_suffering_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.self_suffering_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.self_suffering_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.self_suffering_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.self_suffering_document,this.appservice.getDocName(formGp.value.self_suffering_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(8)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="self_suffering_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="self_suffering_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>

                            <!-- <ng-container
                                *ngIf="formGp.value.self_suffering_document != '' && filesizeError.self_suffering_document != true">
                                <div class="col-12 mt-0" *ngIf="!self_suffering_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{self_suffering_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(8)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="self_suffering_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="donate_social_cause100" id="" class="form-check-input "
                                    formControlName="donate_social_cause100"
                                    (change)="checkBoxFunctionTest($event,'','donate_social_cause100_amount','cash_other100','fund_type100','transaction_document100')">
                                <label for="" class="form-check-label">80G: Deduction for donations towards social
                                    causes (100% exemption)</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Donations towards social causes are eligible for a tax deduction under Section 80G. From the fiscal year 2017-18, any cash donations over ₹ 2,000 would not be accepted as a deduction. Donations in excess of ₹ 2000 must be made in any mode other than cash in order to qualify for a tax deduction under Section 80G."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.donate_social_cause100">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="cash_other100" type="radio" id="deduction1" class="form-check-input  "
                                        formControlName="cash_other100" [value]="false"
                                        (change)="radioBtnChange($event,'donate_social_cause100','donate_social_cause100_amount','transaction_document100')">
                                    <label for="deduction1" class="form-check-label">Cash</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="cash_other100" type="radio" id="deduction2" class="form-check-input "
                                        formControlName="cash_other100" [value]="true"
                                        (change)="radioBtnChange($event,'donate_social_cause100','donate_social_cause100_amount','transaction_document100')">
                                    <label for="deduction2" class="form-check-label">Other</label>
                                </div>
                            </div>
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.donate_social_cause100_amount.dirty || f.donate_social_cause100_amount.touched) &&  (f.donate_social_cause100_amount.errors || experianceValidator5)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        id="donate_social_cause100_amount"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="donate_social_cause100_amount"
                                        name="donate_social_cause100_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.donate_social_cause100_amount.dirty || f.donate_social_cause100_amount.touched) &&  (f.donate_social_cause100_amount.errors || experianceValidator5)}"
                                        (keyup)="validateDeductionAmt()">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12" *ngIf="!formGp.value.cash_other100"><span class="text-light-500">Max
                                        limit :</span><span class="fw-500">{{limitData.deduction_donation_80g}}</span>
                                </div>
                                <div *ngIf="(submitted || f.donate_social_cause100_amount.dirty || f.donate_social_cause100_amount.touched) && (f.donate_social_cause100_amount.errors)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.donate_social_cause100_amount.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.donate_social_cause100_amount.errors?.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                        *ngIf="!(f.donate_social_cause100_amount.errors?.pattern) && f.donate_social_cause100_amount.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                                </div>
                                <div *ngIf="(submitted || f.donate_social_cause100_amount.dirty) && experianceValidator5==true"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="!(f.donate_social_cause100_amount?.errors?.maxlength) && experianceValidator5==true">
                                        {{amountMsg5}}</div>
                                </div>
                            </div><br>
                            <ng-container *ngIf="formGp.value.cash_other100">
                                <div class="col-md-6 form-row">
                                    <label for="" class="form-label required">Fund type </label>

                                    <!-- New start -->
                                    <ng-select class="form-ngselect" [placeholder]="fundDropdownPlaceHolder"
                                        id="fund_type100" formControlName="fund_type100" name="fund_type100"
                                        [ngClass]="{ 'is-invalid': (submitted || f.fund_type100.dirty || f.fund_type100.touched) &&  (f.fund_type100.errors)}"
                                        [items]="donation100DropDownData" bindValue="id" bindLabel="name">
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="text-trim" title="{{item.name}}">{{item.name}}</div>
                                        </ng-template>
                                    </ng-select>
                                    <!-- New end -->
                                    <div *ngIf="(submitted || f.fund_type100.dirty || f.fund_type100.touched) && (f.fund_type100.errors)"
                                        class="invalid-feedback">
                                        <div *ngIf="f.fund_type100.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                    </div>
                                </div><br>
                                <ng-container formArrayName="donate_80g100"
                                    *ngFor="let item of donate_80gs100().controls; let i=index; ">
                                    <ng-container [formGroupName]="i">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class=" fw-500 mb-0">Transaction details</p>
                                            <a *ngIf="i!=0" class="btn-reset" (click)="removeDonate_80gs100(i)"><i
                                                    class="icon-trash"></i></a>
                                        </div>
                                        <div class=" col-md-6 form-row">
                                            <label for="" class="form-label required">Name </label>
                                            <input type="text" class="form-control" autocomplete="off" name="name"
                                                id="name" placeholder="{{this.messageService.placeholderdisp('Name')}}"
                                                formControlName="name"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs100().controls[i].get('name')?.dirty || donate_80gs100().controls[i].get('name')?.touched) &&  donate_80gs100().controls[i].get('name')?.errors}">
                                            <div *ngIf="(submitted || donate_80gs100().controls[i].get('name')?.dirty || donate_80gs100().controls[i].get('name')?.touched) && donate_80gs100().controls[i].get('name')?.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="donate_80gs100().controls[i].get('name')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="!(donate_80gs100().controls[i].get('name')?.errors?.pattern) && donate_80gs100().controls[i].get('name')?.errors?.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                                <div *ngIf="donate_80gs100().controls[i].get('name')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">PAN</label>
                                            <input type="text" class="form-control" autocomplete="off" name="pan"
                                                id="name" placeholder="{{this.messageService.placeholderdisp('PAN')}}"
                                                formControlName="pan"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs100().controls[i].get('pan')?.dirty || donate_80gs100().controls[i].get('pan')?.touched) &&   donate_80gs100().controls[i].get('pan')?.errors}">
                                            <div *ngIf="(submitted ||  donate_80gs100().controls[i].get('pan')?.dirty ||  donate_80gs100().controls[i].get('pan')?.touched) && donate_80gs100().controls[i].get('pan')?.errors"
                                                class="invalid-feedback">
                                                <div *ngIf=" donate_80gs100().controls[i].get('pan')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div *ngIf=" donate_80gs100().controls[i].get('pan')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                            </div>
                                        </div>
                                        <div class=" col-md-12 form-row">
                                            <label for="" class="form-label required">Address</label>
                                            <textarea type="text" rows="1" class="form-control" autocomplete="off"
                                                name="address" id="address"
                                                placeholder="{{this.messageService.placeholderdisp('Address')}}"
                                                formControlName="address"
                                                [ngClass]="{ 'is-invalid': (submitted ||  donate_80gs100().controls[i].get('address')?.dirty ||  donate_80gs100().controls[i].get('address')?.touched) &&  donate_80gs100().controls[i].get('address')?.errors}"></textarea>
                                            <div *ngIf="(submitted || donate_80gs100().controls[i].get('address')?.dirty || donate_80gs100().controls[i].get('address')?.touched) && donate_80gs100().controls[i].get('address')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs100().controls[i].get('address')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="!(donate_80gs100().controls[i].get('address')?.errors?.pattern) && donate_80gs100().controls[i].get('address')?.errors?.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','500')}}</div>

                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Payment mode </label>
                                            <app-master-select class="config-app-master"
                                                [placeholder]="paymentDropdownPlaceHolder" id="payment_mode"
                                                [submitted]="submitted" [isrequired]="isrequired"
                                                [selectedItem]="donate_80gs100().controls[i].get('payment_mode')?.value"
                                                formControlName="payment_mode" [type]="nocash_payment_mode"
                                                [ngClass]="{ 'is-invalid': (submitted ||  donate_80gs100().controls[i].get('payment_mode')?.dirty ||  donate_80gs100().controls[i].get('payment_mode')?.touched) &&  donate_80gs100().controls[i].get('payment_mode')?.errors}"></app-master-select>
                                            <div *ngIf="(submitted || donate_80gs100().controls[i].get('payment_mode')?.dirty || donate_80gs100().controls[i].get('payment_mode')?.touched) && donate_80gs100().controls[i].get('payment_mode')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs100().controls[i].get('payment_mode')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Cheque no./transaction id </label>
                                            <input type="text" class="form-control" autocomplete="off"
                                                name="check_no_transaction_id" id="check_no_transaction_id"
                                                placeholder="{{this.messageService.placeholderdisp('Cheque No./Transaction ID')}}"
                                                formControlName="check_no_transaction_id"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs100().controls[i].get('check_no_transaction_id')?.dirty || donate_80gs100().controls[i].get('check_no_transaction_id')?.touched) &&  donate_80gs100().controls[i].get('check_no_transaction_id')?.errors}">
                                            <div *ngIf="(submitted || donate_80gs100().controls[i].get('check_no_transaction_id')?.dirty || donate_80gs100().controls[i].get('check_no_transaction_id')?.touched) && donate_80gs100().controls[i].get('check_no_transaction_id')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs100().controls[i].get('check_no_transaction_id')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="donate_80gs100().controls[i].get('check_no_transaction_id')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                                <div
                                                    *ngIf="!(donate_80gs100().controls[i].get('check_no_transaction_id')?.errors?.pattern) && donate_80gs100().controls[i].get('check_no_transaction_id')?.errors?.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','18')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Payment date </label>
                                            <div class="input-group form-icon icon-right"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs100().controls[i].get('payment_date')?.dirty || donate_80gs100().controls[i].get('payment_date')?.touched) &&  donate_80gs100().controls[i].get('payment_date')?.errors}">

                                                <input (click)="datePicker2.open()" class="form-control"
                                                    placeholder="{{this.messageService.placeholderdisp('Date')}}"
                                                    matInput formControlName="payment_date" autocomplete="off"
                                                    [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker2"
                                                    [ngClass]="{ 'is-invalid': (submitted || donate_80gs100().controls[i].get('payment_date')?.dirty || donate_80gs100().controls[i].get('payment_date')?.touched) &&  donate_80gs100().controls[i].get('payment_date')?.errors}">
                                                <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                                            </div>
                                            <div *ngIf="(submitted || donate_80gs100().controls[i].get('payment_date')?.dirty || donate_80gs100().controls[i].get('payment_date')?.touched) && donate_80gs100().controls[i].get('payment_date')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs100().controls[i].get('payment_date')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </ng-container>
                                <div class="col-md-12">
                                    <button class="btn btn-outline-primary btn-sm fw-400"
                                        (click)="addDonate_80g100()">Add More</button>
                                </div>
                            </ng-container>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="transaction_document100" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(transaction_document100_loader==false) && (formGp.value.transaction_document100 == '' || formGp.value.transaction_document100 == null)" (click)="currentId(9)"
                                [ngClass]="{ 'is-invalid': (filesizeError.transaction_document100 == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.transaction_document100 == true)" class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'transaction_document100'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.transaction_document100 != '' && filesizeError.transaction_document100 != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!transaction_document100_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.transaction_document100)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.transaction_document100)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.transaction_document100)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.transaction_document100,this.appservice.getDocName(formGp.value.transaction_document100))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(9)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="transaction_document100_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="transaction_document100_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.transaction_document100 != '' && filesizeError.transaction_document100 != true">
                                <div class="col-12 mt-0" *ngIf="!transaction_document100_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{transaction_document100}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(9)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="transaction_document100_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>


                        <!-- NEW -->
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="donate_social_cause_50" id="" class="form-check-input "
                                    formControlName="donate_social_cause_50"
                                    (change)="checkBoxFunctionTest($event,'','donate_social_cause50_amount','cash_other50','fund_type50','transaction_document50')">
                                <label for="" class="form-check-label">80G: Deduction for donations towards social
                                    causes (50% exemption)</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Donations towards social causes are eligible for a tax deduction under Section 80G. Donations made towards trusts like Prime Minister's Drought Relief Fund, National Children's Fund, Indira Gandhi Memorial Fund, etc. qualify for 50% tax deduction on donated amount."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.donate_social_cause_50">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="cash_other50" type="radio" id="deduction1" class="form-check-input  "
                                        formControlName="cash_other50" [value]="false"
                                        (change)="radioBtnChange($event,'donate_social_cause_50','donate_social_cause50_amount','transaction_document50')">
                                    <label for="deduction1" class="form-check-label">Cash</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="cash_other50" type="radio" id="deduction2" class="form-check-input "
                                        formControlName="cash_other50" [value]="true"
                                        (change)="radioBtnChange($event,'donate_social_cause_50','donate_social_cause50_amount','transaction_document50')">
                                    <label for="deduction2" class="form-check-label">Other</label>
                                </div>
                            </div>
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.donate_social_cause50_amount.dirty || f.donate_social_cause50_amount.touched) &&  (f.donate_social_cause50_amount.errors || experianceValidator5)}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        id="donate_social_cause50_amount"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="donate_social_cause50_amount"
                                        name="donate_social_cause50_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.donate_social_cause50_amount.dirty || f.donate_social_cause50_amount.touched) &&  (f.donate_social_cause50_amount.errors || experianceValidator5)}"
                                        (keyup)="validateDeductionAmt()">
                                    <span>{{currency}}</span>

                                </div>
                                <div class="fs-12" *ngIf="!formGp.value.cash_other50"><span class="text-light-500">Max
                                        Limit :</span><span class="fw-500">{{limitData.deduction_donation_80g}}</span>
                                </div>
                                <div *ngIf="(submitted || f.donate_social_cause50_amount.dirty || f.donate_social_cause50_amount.touched) && (f.donate_social_cause50_amount.errors)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.donate_social_cause50_amount.errors?.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.donate_social_cause50_amount.errors?.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                        *ngIf="!(f.donate_social_cause50_amount.errors?.pattern) && f.donate_social_cause50_amount.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>

                            </div><br>
                            <ng-container *ngIf="formGp.value.cash_other50">

                                <div class="col-md-6 form-row">
                                    <label for="" class="form-label required">Fund type </label>

                                    <!-- New start -->
                                    <ng-select class="form-ngselect" [placeholder]="fundDropdownPlaceHolder"
                                        id="fund_type50" formControlName="fund_type50" name="fund_type50"
                                        [ngClass]="{ 'is-invalid': (submitted || f.fund_type50.dirty || f.fund_type50.touched) &&  (f.fund_type50.errors)}"
                                        [items]="donation50DropDownData" bindValue="id" bindLabel="name">
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="text-trim" title="{{item.name}}">{{item.name}}</div>
                                        </ng-template>
                                    </ng-select>
                                    <!-- New end -->
                                    <div *ngIf="(submitted || f.fund_type50.dirty || f.fund_type50.touched) && (f.fund_type50.errors)"
                                        class="invalid-feedback">
                                        <div *ngIf="f.fund_type50.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                    </div>
                                </div><br>
                                <ng-container formArrayName="donate_80g50"
                                    *ngFor="let item of donate_80gs50().controls; let i=index; ">
                                    <ng-container [formGroupName]="i">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class=" fw-500 mb-0">Transaction details</p>
                                            <a *ngIf="i!=0" class="btn-reset text-light-600"
                                                (click)="removeDonate_80gs50(i)"><i class="icon-trash"></i></a>
                                        </div>
                                        <div class=" col-md-6 form-row">
                                            <label for="" class="form-label required">Name </label>
                                            <input type="text" class="form-control" autocomplete="off" name="name"
                                                id="name" placeholder="{{this.messageService.placeholderdisp('Name')}}"
                                                formControlName="name"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs50().controls[i].get('name')?.dirty || donate_80gs50().controls[i].get('name')?.touched) &&  donate_80gs50().controls[i].get('name')?.errors}">
                                            <div *ngIf="(submitted || donate_80gs50().controls[i].get('name')?.dirty || donate_80gs50().controls[i].get('name')?.touched) && donate_80gs50().controls[i].get('name')?.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="donate_80gs50().controls[i].get('name')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="!(donate_80gs50().controls[i].get('name')?.errors?.pattern) && donate_80gs50().controls[i].get('name')?.errors?.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                                <div *ngIf="donate_80gs50().controls[i].get('name')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">PAN</label>
                                            <input type="text" class="form-control" autocomplete="off" name="pan"
                                                id="name" placeholder="{{this.messageService.placeholderdisp('PAN')}}"
                                                formControlName="pan"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs50().controls[i].get('pan')?.dirty || donate_80gs50().controls[i].get('pan')?.touched) &&   donate_80gs50().controls[i].get('pan')?.errors}">
                                            <div *ngIf="(submitted ||  donate_80gs50().controls[i].get('pan')?.dirty ||  donate_80gs50().controls[i].get('pan')?.touched) && donate_80gs50().controls[i].get('pan')?.errors"
                                                class="invalid-feedback">
                                                <div *ngIf=" donate_80gs50().controls[i].get('pan')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div *ngIf=" donate_80gs50().controls[i].get('pan')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                            </div>
                                        </div>
                                        <div class=" col-md-12 form-row">
                                            <label for="" class="form-label required">Address</label>
                                            <textarea type="text" rows="1" class="form-control" autocomplete="off"
                                                name="address" id="address"
                                                placeholder="{{this.messageService.placeholderdisp('Address')}}"
                                                formControlName="address"
                                                [ngClass]="{ 'is-invalid': (submitted ||  donate_80gs50().controls[i].get('address')?.dirty ||  donate_80gs50().controls[i].get('address')?.touched) &&  donate_80gs50().controls[i].get('address')?.errors}"></textarea>
                                            <div *ngIf="(submitted || donate_80gs50().controls[i].get('address')?.dirty || donate_80gs50().controls[i].get('address')?.touched) && donate_80gs50().controls[i].get('address')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs50().controls[i].get('address')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="!(donate_80gs50().controls[i].get('address')?.errors?.pattern) && donate_80gs50().controls[i].get('address')?.errors?.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','500')}}</div>

                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Payment mode </label>
                                            <app-master-select class="config-app-master"
                                                [placeholder]="paymentDropdownPlaceHolder" id="payment_mode"
                                                [submitted]="submitted" [isrequired]="isrequired"
                                                [selectedItem]="donate_80gs50().controls[i].get('payment_mode')?.value"
                                                formControlName="payment_mode" [type]="nocash_payment_mode"
                                                [ngClass]="{ 'is-invalid': (submitted ||  donate_80gs50().controls[i].get('payment_mode')?.dirty ||  donate_80gs50().controls[i].get('payment_mode')?.touched) &&  donate_80gs50().controls[i].get('payment_mode')?.errors}"></app-master-select>
                                            <div *ngIf="(submitted || donate_80gs50().controls[i].get('payment_mode')?.dirty || donate_80gs50().controls[i].get('payment_mode')?.touched) && donate_80gs50().controls[i].get('payment_mode')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs50().controls[i].get('payment_mode')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Cheque no./transaction id </label>
                                            <input type="text" class="form-control" autocomplete="off"
                                                name="check_no_transaction_id" id="check_no_transaction_id"
                                                placeholder="{{this.messageService.placeholderdisp('Cheque No./Transaction ID')}}"
                                                formControlName="check_no_transaction_id"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs50().controls[i].get('check_no_transaction_id')?.dirty || donate_80gs50().controls[i].get('check_no_transaction_id')?.touched) &&  donate_80gs50().controls[i].get('check_no_transaction_id')?.errors}">
                                            <div *ngIf="(submitted || donate_80gs50().controls[i].get('check_no_transaction_id')?.dirty || donate_80gs50().controls[i].get('check_no_transaction_id')?.touched) && donate_80gs50().controls[i].get('check_no_transaction_id')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs50().controls[i].get('check_no_transaction_id')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="donate_80gs50().controls[i].get('check_no_transaction_id')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                                <div
                                                    *ngIf="!(donate_80gs50().controls[i].get('check_no_transaction_id')?.errors?.pattern) && donate_80gs50().controls[i].get('check_no_transaction_id')?.errors?.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','18')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Payment date </label>
                                            <div class="input-group form-icon icon-right"
                                                [ngClass]="{ 'is-invalid': (submitted || donate_80gs50().controls[i].get('payment_date')?.dirty || donate_80gs50().controls[i].get('payment_date')?.touched) &&  donate_80gs50().controls[i].get('payment_date')?.errors}">
                                                <input (click)="datePicker2.open()" class="form-control"
                                                    placeholder="{{this.messageService.placeholderdisp('Date')}}"
                                                    matInput formControlName="payment_date"
                                                    [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker2"
                                                    autocomplete="off"
                                                    [ngClass]="{ 'is-invalid': (submitted || donate_80gs50().controls[i].get('payment_date')?.dirty || donate_80gs50().controls[i].get('payment_date')?.touched) &&  donate_80gs50().controls[i].get('payment_date')?.errors}"><i
                                                    class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                                            </div>
                                            <div *ngIf="(submitted || donate_80gs50().controls[i].get('payment_date')?.dirty || donate_80gs50().controls[i].get('payment_date')?.touched) && donate_80gs50().controls[i].get('payment_date')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="donate_80gs50().controls[i].get('payment_date')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </ng-container>
                                <div class="col-md-12">
                                    <button class="btn btn-outline-primary btn-sm fw-400"
                                        (click)="addDonate_80g50()">Add More</button>
                                </div>
                            </ng-container>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="transaction_document50" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(transaction_document50_loader==false) && (formGp.value.transaction_document50 == '' || formGp.value.transaction_document50 == null)" (click)="currentId(21)"
                                [ngClass]="{ 'is-invalid': (filesizeError.transaction_document50 == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.transaction_document50 == true)" class="invalid-feedback">
                                <div>{{fileError}}</div>

                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'transaction_document50'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.transaction_document50 != '' && filesizeError.transaction_document50 != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!transaction_document50_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.transaction_document50)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.transaction_document50)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.transaction_document50)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.transaction_document50,this.appservice.getDocName(formGp.value.transaction_document50))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(21)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="transaction_document50_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="transaction_document50_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.transaction_document50 != '' && filesizeError.transaction_document50 != true">
                                <div class="col-12 mt-0" *ngIf="!transaction_document50_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{transaction_document50}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(21)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="transaction_document50_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <!-- END -->

                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="royalty_patent" id="" class="form-check-input "
                                    formControlName="royalty_patent"
                                    (change)="checkBoxFunctionTest($event,'royalty_patent_amount','royalty_patent_document','','','royalty_patent_document_loader')">
                                <label for="" class="form-check-label">80RRB: Deductions on income by way of royalty of
                                    a patent</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Section 80RRB is applicable the individuals whose source of income is royalty on their works of art, inventions, patents, etc. The total income of such an individual can be from royalty plus additional sources. They can claim deductions upto ₹ 3 lakh on such income. In case of the income being lower than₹ 3 lakh, they can claim deductions on the actual income received as royalty."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.royalty_patent">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.royalty_patent_amount.dirty || f.royalty_patent_amount.touched) &&  f.royalty_patent_amount.errors}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="royalty_patent_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="royalty_patent_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.royalty_patent_amount.dirty || f.royalty_patent_amount.touched) &&  f.royalty_patent_amount.errors}">
                                    <span>{{currency}}</span>
                                </div>
                                <div class="fs-12"><span class="text-light-500">Max limit :</span><span
                                        class="fw-500">{{limitData.deduction_income_royalpatent80rrg}}</span></div>
                                <div *ngIf="(submitted || f.royalty_patent_amount.dirty || f.royalty_patent_amount.touched) && f.royalty_patent_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.royalty_patent_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.royalty_patent_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>

                                    <div
                                        *ngIf="!(f.royalty_patent_amount.errors.pattern) && !(f.royalty_patent_amount.errors?.maxlength) && f.royalty_patent_amount.value > 300000">
                                        {{this.messageService.fieldlengthvalidation('amount','300000')}}
                                    </div>
                                    <div
                                    *ngIf="!(f.royalty_patent_amount.errors?.pattern) && f.royalty_patent_amount.errors?.maxlength">
                                    {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="royalty_patent_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(royalty_patent_document_loader==false) && (formGp.value.royalty_patent_document == '' || formGp.value.royalty_patent_document == null)" (click)="currentId(10)"
                                [ngClass]="{ 'is-invalid': (filesizeError.royalty_patent_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.royalty_patent_document == true)" class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'royalty_patent_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.royalty_patent_document != '' && filesizeError.royalty_patent_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!royalty_patent_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.royalty_patent_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.royalty_patent_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.royalty_patent_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.royalty_patent_document,this.appservice.getDocName(formGp.value.royalty_patent_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(10)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="royalty_patent_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="royalty_patent_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.royalty_patent_document != '' && filesizeError.royalty_patent_document != true">
                                <div class="col-12 mt-0" *ngIf="!royalty_patent_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{royalty_patent_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(10)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="royalty_patent_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check fs-14">
                                <input type="checkbox" name="contribution_individual_policalparties" id=""
                                    class="form-check-input " formControlName="contribution_individual_policalparties"
                                    (change)="checkBoxFunctionTest($event,'contribution_policalparties_amount','contribution_policalparties_document','','','contribution_policalparties_document_loader')">
                                <label for="" class="form-check-label">80GGC: Contribution by individuals to political
                                    parties</label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="The deduction can be claimed under this section by the individual for the contributions made towards electoral trust or political party."
                                        popoverClass="popover-default popover-info"
                                        triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.contribution_individual_policalparties">
                            <div class=" col-md-6 form-row">
                                <label for="" class="form-label required">Amount</label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.contribution_policalparties_amount.dirty || f.contribution_policalparties_amount.touched) &&  f.contribution_policalparties_amount.errors}">
                                    <input type="text" class="form-control" autocomplete="off"
                                        name="contribution_policalparties_amount" id="name"
                                        placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        formControlName="contribution_policalparties_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.contribution_policalparties_amount.dirty || f.contribution_policalparties_amount.touched) &&  f.contribution_policalparties_amount.errors}">
                                    <span>{{currency}}</span>
                                </div>
                                <div *ngIf="(submitted || f.contribution_policalparties_amount.dirty || f.contribution_policalparties_amount.touched) && f.contribution_policalparties_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.contribution_policalparties_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.contribution_policalparties_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div
                                        *ngIf="!(f.contribution_policalparties_amount.errors.pattern) && f.contribution_policalparties_amount.errors.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}

                                    </div>
                                </div>
                            </div>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="contribution_policalparties_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(contribution_policalparties_document_loader==false) && (formGp.value.contribution_policalparties_document == '' || formGp.value.contribution_policalparties_document == null)" (click)="currentId(11)"
                                [ngClass]="{ 'is-invalid': (filesizeError.contribution_policalparties_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.contribution_policalparties_document == true)"
                                class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false"
                                [id]="'contribution_policalparties_document'" [width]="504" (dataURL)="getURL($event)"
                                (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.contribution_policalparties_document != '' && filesizeError.contribution_policalparties_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!contribution_policalparties_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.contribution_policalparties_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.contribution_policalparties_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.contribution_policalparties_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.contribution_policalparties_document,this.appservice.getDocName(formGp.value.contribution_policalparties_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(11)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="contribution_policalparties_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="contribution_policalparties_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.contribution_policalparties_document != '' && filesizeError.contribution_policalparties_document != true">
                                <div class="col-12 mt-0" *ngIf="!contribution_policalparties_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{contribution_policalparties_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(11)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="contribution_policalparties_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>
                        <div class="col-12">
                            <hr class="hr-1 px-16">
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check d-flex">
                                <input type="checkbox" name="gga80" id="" class="form-check-input"
                                    formControlName="gga80"
                                    (change)="checkBoxFunctionTest($event,'total_amount','transaction_80gga_document','cash_other_80gga','','transaction_80gga_document_loader')">
                                <label for="" class="form-check-label fs-14 ">80GGA: Deduction in respect of donations
                                    to certain funds, charitable institutions, scientific research, and rural
                                    development etc.
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="Deduction in respect of donations to certain funds, charitable institutions, scientific research, and rural development etc. is available under this section. Choose the mode of payment. For amounts up to ₹10,000/- the mode of payment will be cash. For an amount greater than ₹10,000/-, the mode of payment will be others with the transaction details."
                                            popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                                            container="body"></i></span>
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="formGp.value.gga80">
                            <div class="col-md-12 form-row ">
                                <div class="form-check form-check-inline">
                                    <input name="cash_other_80gga" type="radio" id="deduction1"
                                        class="form-check-input  " formControlName="cash_other_80gga" [value]="true"
                                        (change)="dedutionRadio($event)">
                                    <label for="deduction1" class="form-check-label">Cash</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="cash_other_80gga" type="radio" id="deduction2"
                                        class="form-check-input " formControlName="cash_other_80gga" [value]="false"
                                        (change)="dedutionRadio($event)">
                                    <label for="deduction2" class="form-check-label">Other</label>
                                </div>
                            </div>
                            <div class=" col-md-12 form-row">
                                <label for="" class="form-label required">Total amount </label>
                                <div class="form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (submitted || f.total_amount.dirty) &&  (f.total_amount.errors || experianceValidator4==true)}">
                                    <input type="text" class="form-control" autocomplete="off" name="total_amount"
                                        id="name" placeholder="{{this.messageService.placeholderdisp('Total amount')}}"
                                        formControlName="total_amount"
                                        [ngClass]="{ 'is-invalid': (submitted || f.total_amount.dirty) &&  (f.total_amount.errors || experianceValidator4==true)}"
                                        (keyup)="validDonation()">
                                    <span>{{currency}}</span>
                                </div>
                                <div *ngIf="(submitted || f.total_amount.dirty || f.total_amount.touched) && f.total_amount.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.total_amount.errors.pattern">
                                        {{this.messageService.validationDisplay('amount')}}</div>
                                    <div *ngIf="f.total_amount.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="!(f.total_amount.errors.maxlength) && f.total_amount.errors.max">
                                        {{this.messageService.fieldlengthvalidation('amount','10000')}}
                                    </div>
                                    <div *ngIf="!(f.total_amount.errors.pattern) && f.total_amount.errors.maxlength">
                                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>

                                </div>

                            </div>
                            <ng-container *ngIf="!formGp.value.cash_other_80gga">
                                <ng-container formArrayName="deduction_80gga"
                                    *ngFor="let item of deduction_80ggas().controls; let i=index; ">
                                    <ng-container [formGroupName]="i">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class=" fw-500 mb-0">Transaction details {{(i+1)}}</p>
                                            <a *ngIf="i!=0" class="btn-reset" (click)="removeDeduction_80ggas(i)"><i
                                                    class="icon-trash"></i></a>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Cheque no./transaction id </label>
                                            <input type="text" class="form-control" autocomplete="off"
                                                name="check_no_transaction_id" id="check_no_transaction_id"
                                                placeholder="{{this.messageService.placeholderdisp('Cheque No./Transaction ID')}}"
                                                formControlName="check_no_transaction_id"
                                                [ngClass]="{ 'is-invalid': (submitted || deduction_80ggas().controls[i].get('check_no_transaction_id')?.dirty || deduction_80ggas().controls[i].get('check_no_transaction_id')?.touched) &&  deduction_80ggas().controls[i].get('check_no_transaction_id')?.errors}">
                                            <div *ngIf="(submitted || deduction_80ggas().controls[i].get('check_no_transaction_id')?.dirty || deduction_80ggas().controls[i].get('check_no_transaction_id')?.touched) && deduction_80ggas().controls[i].get('check_no_transaction_id')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="deduction_80ggas().controls[i].get('check_no_transaction_id')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="deduction_80ggas().controls[i].get('check_no_transaction_id')?.errors?.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                                <div
                                                    *ngIf="!(deduction_80ggas().controls[i].get('check_no_transaction_id')?.errors?.pattern) && deduction_80ggas().controls[i].get('check_no_transaction_id')?.errors?.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','18')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-row">
                                            <label for="" class="form-label required">Date </label>
                                            <div class="input-group form-icon icon-right"
                                                [ngClass]="{ 'is-invalid': (submitted || deduction_80ggas().controls[i].get('date')?.dirty || deduction_80ggas().controls[i].get('date')?.touched) &&  deduction_80ggas().controls[i].get('date')?.errors}">
                                                <input (click)="datePicker1.open()" class="form-control"
                                                    placeholder="{{this.messageService.placeholderdisp('Date')}}"
                                                    matInput formControlName="date" [matDatepickerFilter]="dateFilter"
                                                    [matDatepicker]="datePicker1"
                                                    [ngClass]="{ 'is-invalid': (submitted || deduction_80ggas().controls[i].get('date')?.dirty || deduction_80ggas().controls[i].get('date')?.touched) &&  deduction_80ggas().controls[i].get('date')?.errors}"><i
                                                    class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                                            </div>
                                            <div *ngIf="(submitted || deduction_80ggas().controls[i].get('date')?.dirty || deduction_80ggas().controls[i].get('date')?.touched) && deduction_80ggas().controls[i].get('date')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="deduction_80ggas().controls[i].get('date')?.errors?.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </ng-container>
                                <div class="col-md-6">
                                    <button class="btn btn-outline-primary btn-sm fw-400"
                                        (click)="addDeduction_80gga()">Add More</button>

                                </div>
                            </ng-container>
                            <!-- upload doc -->
                            <div class="col-12">
                            <label for="transaction_80gga_document" class="form-label upload-doc fs-16 fw-500"
                                *ngIf="(transaction_80gga_document_loader==false) && (formGp.value.transaction_80gga_document == '' || formGp.value.transaction_80gga_document == null)" (click)="currentId(12)"
                                [ngClass]="{ 'is-invalid': (filesizeError.transaction_80gga_document == true)  }">
                                <i class="bi bi-cloud-upload m-1"></i>Upload document
                            </label>
                            <div *ngIf="(filesizeError.transaction_80gga_document == true)" class="invalid-feedback">
                                <div>{{fileError}}</div>
                            </div>
                            <app-file-upload [crop]="false" [round]="false" [id]="'transaction_80gga_document'"
                                [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                                (fullfile)="getFilename($event)" [FileType]="fileType"
                                (validsize)="getvalidsize($event)"></app-file-upload>

                                <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.transaction_80gga_document != '' && filesizeError.transaction_80gga_document != true">
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <ng-container *ngIf="!transaction_80gga_document_loader">
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.transaction_80gga_document)}}</span>
                                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.transaction_80gga_document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value.transaction_80gga_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.transaction_80gga_document,this.appservice.getDocName(formGp.value.transaction_80gga_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                    <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(12)"></i>
                                  </ng-container>

                                  </div>
                                </div>

                                <div *ngIf="transaction_80gga_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                    <div class="  flex-center text-center"  *ngIf="transaction_80gga_document_loader">
                                      <div class="fs-12 text-muted">Loading </div>
                                      <div class="ms-4 dot-pulse"></div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                            <!-- <ng-container
                                *ngIf="formGp.value.transaction_80gga_document != '' && filesizeError.transaction_80gga_document != true">
                                <div class="col-12 mt-0" *ngIf="!transaction_80gga_document_loader">
                                    <ul class="selected-values ">
                                        <li class="ng-value">
                                            <span class="">{{transaction_80gga_document}}</span>
                                            <span aria-hidden="true" class=" right">
                                                <i class="icon-close fs-16 flex-center ms-1"
                                                    (click)="deleteDoc(12)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="  flex-center text-center" *ngIf="transaction_80gga_document_loader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                            </div> -->
                            <!-- upload doc end-->
                        </ng-container>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<footer class="submit-footer gap-16">
    <button class="btn btn-outline-primary btn-sm ms-auto text-uppercase"
        (click)="validateDeductions('skip')">SKIP</button>
    <button class="btn btn-primary btn-sm text-uppercase" [disabled]="!formGp.valid"
        (click)="validateDeductions('next')">NEXT</button>
</footer>

<!-- un supported view format alert -->
<app-info-popup *ngIf="alertToggle" (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
