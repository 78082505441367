import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaveReportService {
  header_filter : any = [];
  data_filter   : any = {};

  constructor(
    private http  : HttpClient,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(  !event.url.includes('leave-report-configure')){
          this.setDataFilter({})
          this.setHeaderFilter([])
        }
      }
    })
   }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  // Landing Screen API
  reportTypeList(type:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?report_type=${type}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  // Employee List
  getEmpList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name`,this.httpOption)
  }
  // Single Report
  getSingleLeaveReport(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?id=${id}`, this.httpOption)
  }

  // Dropdown
  leaveReportDropdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list-dropdown/?ordering=name&report_type=Leave`, this.httpOption)
  }

  reportAPI(url:any,limit:any,offset:any,ordering:any,formData:any){
    if(formData == ''){
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Leave balance on a particular day
  balanceParticularDay(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-balance-single-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-balance-single-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Leave Carry Forward
  carryForwardReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-carryforward-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-carryforward-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Availed Report
  availedReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-availed-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-availed-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Leave Credit Report
  creditReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-credit-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-credit-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Leave Encashment Report
  encashmentReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-encashment-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-encashment-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Lapsed Leaves Report
  lapsedReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/lapsed-leave-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/lapsed-leave-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Negative Balance Report
  negativeBalanceReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/negative-balance-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/negative-balance-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // LOP Report
  lopReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-lop-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-lop-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Leave Balance Summary
  balanceReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-balance-summary-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-balance-summary-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Leave Ledger
  ledgerReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-ledger-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-ledger-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Leave Request
  requestReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-request-status-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/leave-request-status-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  setDataFilter(val:any){
    this.data_filter = val
  }

  setHeaderFilter(val:any){
    this.header_filter = val
  }
}


