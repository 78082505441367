import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DocumentManagementService } from 'src/app/@core/services/document-management.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { urlValidator } from 'src/app/@shared/validators/url.validators';
import { spaceValidator } from 'src/app/@shared/validators/space.validators';


@Component({
  selector: 'app-manage-documents-setup',
  templateUrl: './manage-documents-setup.component.html',
  styleUrls: ['./manage-documents-setup.component.scss']
})
export class ManageDocumentsSetupComponent implements OnInit {

  activeTab                   = 1;
  categoryList:any            = [];
  companyDD      : any        = [];
  buDD           : any        = [];
  DepDD          : any        = [];
  DesignationDD  : any        = [];
  BranchDD       : any        = [];
  gradeDD        : any        = [];
  employeeDropdown:any        = [];
  id                          = 0;
  loader                      = false;
  actionLabel:any;

  policyForm: FormGroup = this.fb.group({
      details: this.fb.group({
        subject                 :  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
        description             : [null, [Validators.required,Validators.maxLength(500),spaceValidator()]],
        priority                : ['Low',[Validators.required]],
        category                : [null,[Validators.required]],
        doc_policy_attachment   : ['',[Validators.required]],
        url                     : [null,[urlValidator()]],
        is_download             : [false],
        is_acknowledge          : [false]

      }),
      policy_assignright : this.fb.group({

                          doc_policy_assignright       : this.fb.group({
                                                employee: [[],[Validators.required]],
                                                company: [[],[Validators.required]],
                                                businessunit: [[],[Validators.required]],
                                                department: [[],[Validators.required]],
                                                designation: [[],[Validators.required]],
                                                grade: [[],[Validators.required]],
                                                city: [[],[Validators.required]],
                                                branch: [[],[Validators.required]],
                                                is_organisation      : [true,[Validators.required]],

                                          })
      })

  })
  constructor(
    public fb:FormBuilder,
    public docService:DocumentManagementService,
    private rs      : RoleService,
    public router   : Router,
    private route: ActivatedRoute,




  ) { }
  ngOnInit(): void {
    this.getcategoryData();
    this.getCompanyList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    this.getEmployeeList()
    this.route.params.subscribe((params: Params) => {
      this.actionLabel = "Add";
      if( !isNaN(params['id'])){
        this.actionLabel = "Edit";
        this.id = +params['id'];
        this.editForm();
      }
    })
  }
  get detailsForm(){
    return this.policyForm.get('details') as FormGroup
  }

  get assignForm(){
    return this.policyForm.get('policy_assignright') as FormGroup
  }
  get policyRightForm(){
    return this.assignForm.get('doc_policy_assignright') as FormGroup
  }
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(this.detailsForm.invalid){
        changeEvent.preventDefault();
      }
    }
  }
  getcategoryData(){
    this.docService.categoryListData(true).subscribe((res:any)=>{
      this.categoryList = res;

    })
  }

  // Company List
  getCompanyList(){
    this.rs.getCompanyList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.companyDD = res.body;
      }
    });
  }

  // Bu List
  getBUList(data:any){
    this.rs.getBuList(data).subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
        this.buDD = res.body
      }
    });
  }

  // Dept List
  getDepList(){
    this.rs.getDepartmentList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DepDD = res.body;
      }
    });
  }

  // Designation List
  getDesignationList(){
    this.rs.getDesignationList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DesignationDD = res.body;
      }
    });
  }

  // Branch List
  getBranchList(){
    this.rs.getBranchList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.BranchDD = res.body;
      }
    });
  }

  // Grade List
  getGradeList(){
    this.rs.getGradeList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.gradeDD = res.body;
      }
    });
  }

  // Employee List
  getEmployeeList(){
    this.rs.getempList(true).subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name']  = "";
            if(res.body[i]['first_name'] != null)
              res.body[i]['name'] += res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
              res.body[i]['name'] += res.body[i]['last_name'];
              res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";
          }
          this.employeeDropdown = res.body;
        }
      }
    });
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  submitMethod(activeTab: any){
    this.activeTab = activeTab;

  }
  submitForm(){

    let doc_policy_assignright = {'doc_policy_assignright':[this.assignForm.get('doc_policy_assignright')?.value]}
    let mergedData             = { ...this.policyForm.getRawValue().details, ...doc_policy_assignright};
    this.docService.saveOrUpdatePolicy(mergedData,this.id).subscribe((res:any)=>{
      this.router.navigate(['document/policy-documents'])
    })

  }
  editForm(){
    this.loader = true;
    this.docService.getSinglePolicyEdit(this.id).subscribe((res:any)=>{

      Object.entries(res).forEach(([key, value]) => {
        this.detailsForm.get(key)?.setValue(value);

        if(key=='doc_policy_assignright'){
          let doc_policy_assignright:any = value;
          Object.entries(doc_policy_assignright[0]).forEach(([key, value]) => {
            this.policyRightForm.get(key)?.setValue(value)

          })
        }
      })
      this.loader = false;
      if(this.policyRightForm.get('company')?.value!=null && this.policyRightForm.get('company')?.value!=''){
        this.getBUList(this.policyRightForm.get('company')?.value)
      }

    })
  }
  checkValid(){
    var result = true
    if(this.policyRightForm.get('is_organisation')?.value == true){
      if(this.policyRightForm?.get('company')?.valid == false && this.policyRightForm?.get('businessunit')?.valid ==false && this.policyRightForm?.get('department')?.valid ==false && this.policyRightForm?.get('designation')?.valid ==false && this.policyRightForm?.get('grade')?.valid ==false && this.policyRightForm?.get('branch')?.valid ==false ){
        result = false;
      } else{
        result = true;
      }
    }else{
      if(this.policyRightForm?.get('employee')?.valid ==false){
        result = false
      }else{
        result = true
      }
    }
    return result
  }
}
