import { Component, OnInit, Inject } from '@angular/core';
import {Location} from '@angular/common';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder,Validators ,FormGroup,FormArray} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { WorkfromhomeService } from 'src/app/@core/services/workfromhome.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import * as moment from 'moment';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
import { ActivatedRoute, Router,Params } from '@angular/router';

@Component({
  selector: 'app-work-from-home-setup',
  templateUrl: './work-from-home-setup.component.html',
  styleUrls: ['./work-from-home-setup.component.scss']
})
export class WorkFromHomeSetupComponent implements OnInit {

  activeTab              = 1 ;
  validationData: any    = [];
  wfhdayss:any           =[];
  temparrr:any           =[];
  error: any             = false;
  restricted             = false;
  from:any;
  employee:any
  requestFor:any = ''
  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private _location: Location,
    private formBuilder:FormBuilder,
    private WorkfromhomeService:WorkfromhomeService,
    public router: Router,
    public route:ActivatedRoute,
    private appservice:AppService,
    private authService:AuthService,
    private investmentService:InvestmentDeclerationService,
    private activityService:ManageActivityService,
  ) { }

  backClicked() {
    this.investmentService.backClicked('/work-from-home-setup');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
       this.employee = params['id']
       this.from  = 'behalf'
       this.activityService.employeeBasicData(this.employee).subscribe((res:any)=>{
        this.requestFor = "";
        if(res['first_name'] != null)
        this.requestFor+= res['first_name']+" ";
        if(res['middle_name'] != null)
        this.requestFor+= res['middle_name']+" ";
        if(res['last_name'] != null)
        this.requestFor+= res['last_name'];
        this.requestFor+=" ("+res['employee_code']+")"

      })
      }
      else
        this.employee = this.authService.isEmployeeId

    })
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
  }

  WFHrequestForm: FormGroup = this.formBuilder.group({
    WFHDetails  : this.formBuilder.group({
        from          : [null,[Validators.required]],
        to            : [null,[Validators.required]],
        reason        : ['',[Validators.required, Validators.maxLength(500)]],
        wfh_request   : this.formBuilder.array([]),
    },
    { validators: CustomDateValidators.fromToDate('from', 'to') }
    ),

  });

  get WFHDetialsForm(){
    return this.WFHrequestForm.get('WFHDetails') as FormGroup;
  }
  wfh_request() : FormArray {
    return this.WFHDetialsForm.get("wfh_request") as FormArray
  }
  submitOne(data: any){
    if(this.WFHDetialsForm.controls.wfh_request.value.length != 0){
    this.activeTab = 2;
    }
  }
  submitmethod2(data: any){
    if(this.WFHDetialsForm.controls.wfh_request.value.length != 0){
    this.wfhdayss = data.data1;
    this.temparrr = data.data2;
    }
  }
  submitForm(data:any){
    let employee   = this.authService.isEmployeeId;
    for(let i = 0 ; i<this.WFHDetialsForm.controls.wfh_request.value.length;i++){
        if(this.WFHDetialsForm.controls.wfh_request.value[i].day_portion == ''){
          this.WFHDetialsForm.controls.wfh_request.value.splice(i, 1);
        }
    }
    let wfhdata:any = {
        "start_date" : moment(this.WFHDetialsForm.controls.from.value).format('YYYY-MM-DD'),
        "end_date"   : moment(this.WFHDetialsForm.controls.to.value).format('YYYY-MM-DD'),
        "reason"     : this.WFHDetialsForm.controls.reason.value,
        "wfh_request": this.WFHDetialsForm.controls.wfh_request.value,
    };
    if(this.from=='behalf'){
      wfhdata['employee_id'] = this.employee
      this.activityService.wfhRequest(wfhdata).subscribe((res:any)=>{
        this.router.navigate(['manage-activities']);

      })
    }
    else{

      this.WorkfromhomeService.wfhCreate(wfhdata).subscribe((res: any) => {
          this.router.navigate(['request']);
        });
    }
  }

  validationResult(data:any){
    this.validationData = data.ValidationResult;
  }

  validationError(data:any){
    this.error  = data;
  }

  isrestricted(data:any){
    this.restricted = data;
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
     if (this.WFHrequestForm['controls'].WFHDetails.invalid || this.WFHDetialsForm.controls.wfh_request.value.length == 0 || this.restricted) {
        changeEvent.preventDefault();
     }else if(this.error){
        changeEvent.preventDefault();
     }
   }
 }

}
