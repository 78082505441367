import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExpenseHRReportsService {

  header_filter : any = [];
  data_filter   : any = {};

  constructor(
    private http  : HttpClient,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(  !event.url.includes('expense-report-configure')){
          this.setDataFilter({})
          this.setHeaderFilter([])
        }
      }
    })
  }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  // Landing Screen API
  reportTypeList(type:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?report_type=${type}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  // Dropdown
  expenseReportDropdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list-dropdown/?ordering=name&report_type=Expense`, this.httpOption)
  }

  // Single Report
  getSingleLeaveReport(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?id=${id}`, this.httpOption)
  }

  // Expense Policy Dropdown
  getExpensePolicyDropdown(){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-policy-dropdown/`, this.httpOption)
  }

  // Advance Policy Dropdown
  getAdvancePolicyDropdown(){
    return this.http.get(`${this.API_PATH}expense/api/v1/advance-policy-dropdown/`, this.httpOption)
  }

  reportAPI(url:any,limit:any,offset:any,ordering:any,formData:any){
    if(formData == ''){
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Expense Report - Amount
  expenseReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-amount-report-list/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-amount-report-list/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Expense Report - Mileage
  expenseMileageReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-mileage-report-list/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-mileage-report-list/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Expense Payment Register
  expensePaymentReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-payment-register-report-list/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-payment-register-report-list/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Expense Receipt Details
  expenseReceiptReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-receipts-list/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-receipts-list/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Expense Outstanding Report
  expenseOutstandingReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-advance-outstanding-report-list/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-advance-outstanding-report-list/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Expense Advance Report
  expenseAdvanceReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-advance-report-list/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-advance-report-list/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Expense Report Details
  expenseDetailsReport(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-reports-list/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/expense-reports-list/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  setDataFilter(val:any){
    this.data_filter = val
  }

  setHeaderFilter(val:any){
    this.header_filter = val
  }
}
