<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Short Leave Policy</h3>
            <p class="text-helper mb-md-0">Define and configure the settings of short leave policy and map it to the respective OU.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end gap-16">
              <div class="" style="width: 363px">
                <app-searchbar #search [searchlist]='policyDropdown' [searchplaceholder]='searchString'
                  (searchEmitter)="singleDataSearch($event)" (keywordsearchEmitter)="fullDataSearch($event)" [isLoading]="isLoading"></app-searchbar>
              </div>
              <div ngbDropdown class="d-inline-block">
                <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                  <i class="icon-filter-left fs-13"></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-end">
                  <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
                    (click)="statusBtn='All';selectItemList('')">All</button>
                  <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
                    (click)="statusBtn='Active';selectItemList(true)">Active </button>
                  <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
                    (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
                </div>
              </div>
              <button *ngIf="permissions?.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" routerLink="/short-leave/short-leave-policy-setup"><i
              class="icon-plus"></i>Add short leave policy</button>
            </div>
        </div>
    </div>
    <div *ngIf="noData && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg6.png'" [noDataText]="'Set Up Your Short Leave Policy'"
        [noDataPara]="'Define the limits and rules for short leave to manage employee absences efficiently. Click ‘Add Short Leave Policy’ to begin.'"
        [hasPermission]="permissions?.a" (addData)="addNewdata()">
      </app-nodata-view>
    </div>
    <div class="row row-16">
      <ng-container *ngIf="loader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
    <ng-template #all_data>
      <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let policy of policyData; let i = index;">
            <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                    <div class="d-flex view-section" (click)="viewPolicy(policy?.id)">
                        <div class="logo-img uniquebg {{policy?.color_code}}">
                          {{this.appService.getFirstChar(policy?.name,2)}}
                        </div>
                        <div class="overflow-hidden">
                            <h6 class="mb-1 tips">{{policy?.name}}</h6>
                            <span class="tips-helper">{{policy?.name}}</span>
                            <div class="fs-10 text-uppercase fw-500 {{(policy?.is_active == true) ? 'text-success' : 'text-danger' }}">{{policy?.is_active
                              == true ? 'Active':'Deleted'}}</div>
                        </div>
                    </div>
                    <div *ngIf="policy?.is_active == true && (permissions?.e || permissions?.d)" ngbDropdown class="d-inline-block dropdown-section">
                        <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="icon-three-dots-vertical"></i>
                        </button>
                        <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                            <button *ngIf="permissions?.e" ngbDropdownItem (click)="editPolicy(policy?.id)">
                                <i class="icon-pencil-square me-1"></i> Edit
                            </button>
                            <button *ngIf="permissions?.d" class="text-danger" ngbDropdownItem (click)="deleteAlert = true;deleteId = policy?.id">
                                <i class="icon-trash  me-1"></i> Delete
                              </button>
                          </div>
                    </div>
                </div>
              </div>
          </div>
          <ng-container *ngIf="infinityloader && !loader">
            <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
          </ng-container>
      </div>
    </ng-template>
</div>

<div *ngIf="permissions" class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details </h5>
            <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <app-view-loader *ngIf="vLoader"></app-view-loader>
              <ng-container *ngIf="!vLoader">
                <div class="row row-16">
                  <div class="col-12 d-flex">
                      <p class="text-uppercase fw-500 mb-0">Short Leave Policy</p>
                      <a *ngIf="permissions?.e && viewData?.is_active
                      == true" class="link-primary fs-14 ms-auto text-nowrap" routerLink="/short-leave/short-leave-policy-setup/{{viewId}}"><i
                              class="icon-pencil-square me-1"></i>
                          Edit</a>
                  </div>
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                          <div class="row row-16 ">
                              <div class="col-12  ">
                                  <p class="title mb-8">Policy details</p>
                              </div>
                              <div class="col-sm-6 col-12">
                                  <div class="detail-title">policy name</div>
                                  <div class="detail-desc">{{viewData?.name}}</div>
                              </div>
                              <div class="col-sm-6 col-12">
                                  <div class="detail-title">Short leave limit</div>
                                  <div class="detail-desc">{{viewData?.short_leave_limit_type}}</div>
                              </div>
                              <div class="col-sm-6 col-12">
                                  <div class="detail-title">No of time short leave can applied</div>
                                  <div class="detail-desc">{{viewData?.max_no_leave_apply_times}}</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                          <div class="row row-16 ">
                              <div class="col-12  ">
                                  <p class="title mb-8">Short leave duration</p>
                              </div>
                              <div class="col-sm-6 col-12">
                                  <div class="detail-title">Minimum short leave hours in one request ?</div>
                                  <div class="detail-desc">{{viewData?.min_short_leave_hours_in_request != null ? this.appService.formatTime(viewData?.min_short_leave_hours_in_request) : '-'}}</div>
                              </div>
                              <div class="col-sm-6 col-12">
                                  <div class="detail-title">Maximum short leave hours in one request ?</div>
                                  <div class="detail-desc">{{viewData?.max_short_leave_hours_in_request != null ? this.appService.formatTime(viewData?.max_short_leave_hours_in_request) : '-'}}</div>
                              </div>
                              <div class="col-sm-6 col-12">
                                <div class="detail-title">Total short leave hours </div>
                                <div class="detail-desc">{{viewData?.total_short_leave_hours != null ? this.appService.formatTime(viewData?.total_short_leave_hours) : '-'}}</div>
                              </div>
                              <div class="col-12">
                                  <div class="detail-title">Backdated short leave allowed</div>
                                  <div class="detail-desc"><i
                                          class="bi-record-circle-fill me-8 text-light-400"></i>{{viewData?.allow_backdated_request == true? 'Yes' : 'No'}}
                                  </div>
                              </div>
                              <div class="col-12" *ngIf="viewData?.allow_backdated_request == true">
                                  <div class="detail-title">Backdated short leave application</div>
                                  <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i> {{viewData?.backdated_type == false ? viewData?.backdated_days : ''}} {{viewData?.backdated_type == true ? 'As per the reversal':'Day'}}
                                  </div>
                              </div>
                              <div class="col-12">
                                <div class="detail-title">Can employee mark more than on short leave in a calendar
                                    day</div>
                                <div class="detail-desc"><i
                                        class="bi-record-circle-fill me-8 text-light-400"></i>{{viewData?.allow_more_than_one_leave_day == true ? 'Yes' : 'No'}}
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                          <div class="row row-16 ">
                              <div class="col-12 ">
                                  <p class="title mb-8">APPLICABILITY</p>
                              </div>
                              <div class="col-12">
                                <div class="detail-title">Applicable to</div>
                                <div class="detail-desc">{{viewData?.applicability?.applicable}}</div>
                            </div>
                            <div class="col-12">
                                <div class="detail-title">{{viewData?.applicability?.applicable}}(s) </div>
                                  <div class="detail-desc">
                                    <div class="selected-values mt-1" >
                                     <ng-container *ngIf="viewData?.applicability?.applicable == 'Employee'">
                                      <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                        <div class="ng-value-label" title="{{rights['first_name']+rights['middle_name']+rights['last_name'] + ' (' + rights['employee_code'] +' )'}}">
                                          <span class="d-inline-flex align-items-center">
                                            <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}" *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                                            <span class="avatar sq-24 avatar-circle {{rights.color_code}}" *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appService.getFirstChar(rights.first_name,2)}}</span>
                                              <span> {{rights['first_name']+(rights['middle_name']!=null && rights['middle_name']!=""?" " +rights['middle_name']:"")+" "+rights['last_name'] +" ("+rights['employee_code']+")"}}</span>
                                           </span>
                                        </div>
                                      </li>
                                     </ng-container>
                                     <ng-container *ngIf="viewData?.applicability?.applicable == 'Company'">
                                      <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                        <div class="ng-value-label" title="{{rights.company_name}}">
                                          <span class="d-inline-flex align-items-center">
                                            <img class="img-fluid rounded-circle sq-24"  src="{{rights.logo}}" *ngIf="rights.logo !=  '' && rights.logo !=  null">
                                            <span class="avatar sq-24 avatar-circle {{rights.color_code}}"  *ngIf="rights.logo ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                              <span> {{rights.company_name}}</span>
                                           </span>
                                        </div>
                                      </li>
                                     </ng-container>
                                     <ng-container *ngIf="(viewData?.applicability?.applicable != 'Employee') && (viewData?.applicability?.applicable != 'Company')">
                                        <div class="d-flex flex-wrap mt-8 gap-8" >
                                          <ng-container *ngFor="let rights of assignees, let i=index;">
                                            <span class="badge3"  title="{{rights.name}}"> {{ (rights.length>25)? (rights.name | slice:0:25)+'..':(rights.name)  }}
                                            </span>
                                          </ng-container>
                                        </div>
                                     </ng-container>
                                  </div>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Delete  component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Short Leave Policy?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.msgService.Deletemsgdisplay('Short Leave Policy')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deletePolicy(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
