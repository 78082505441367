
<div *ngIf="issalsumm" class="row row-16rem">
  <div class="col-12">
      <div class="card card-c2 overflow-hidden">
          <div class="table-responsive">
              <table class="table table-striped form-table  table-sm " >
                  <thead>
                      <tr >
                        <ng-container *ngFor="let item of salaryHeader">
                          <th class="fw-600" scope="col" >{{item}}</th>
                        </ng-container>

                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngIf="salarysummaryList.length<=0">
                          <td colspan="4">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                                </div>
                          </td>
                      </tr>

                        <ng-container *ngFor="let item of $any(salarysummaryList |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                          <tr>
                            <ng-container *ngFor="let head of salaryHeader">
                              <td *ngIf="head=='COMPONENT'">{{item[head]}} </td>
                              <td *ngIf="head!=='COMPONENT'">{{item[head]| currency:currency}} </td>
                            </ng-container>
                          </tr>
                        </ng-container>


                  </tbody>
              </table>

          </div>

      </div>
  </div>
  <div class="col-12">
      <ng-container *ngIf="this.appservice.filteredcount(salarysummaryList,searchString)>pageSize">
          <ngb-pagination class="d-flex justify-content-end"  [collectionSize]="this.appservice.filteredcount(salarysummaryList,searchString)" [(page)]="page"  [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
      </ng-container>
  </div>
</div>


 <div class="row" *ngIf="isempsalsumm">
  <div class="col-12">
      <div class="card card-c2 overflow-hidden">
          <div class="table-responsive">
              <table class="table table-striped form-table  table-sm " >
                  <thead>
                      <tr >
                        <ng-container *ngFor="let item of empHeader">
                          <th class="fw-600" scope="col" >{{item}}</th>
                        </ng-container>

                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngIf="empsalarysummaryList.length<=0">
                          <td colspan="5">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                                </div>
                          </td>
                      </tr>
                      <ng-container *ngFor="let item of $any(empsalarysummaryList |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                        <tr>

                          <ng-container *ngFor="let head of empHeader">
                            <td *ngIf="head=='employee_code' || head=='employee_name'">{{item[head]}} </td>
                            <td *ngIf="head!=='employee_code' && head!=='employee_name'">{{item[head]| currency:currency}} </td>
                          </ng-container>
                        </tr>
                      </ng-container>
                  </tbody>
              </table>
          </div>

      </div>
  </div>
  <div class="col-12">
      <ng-container *ngIf="this.appservice.filteredcount(empsalarysummaryList,searchString)>pageSize">
          <ngb-pagination  class="d-flex justify-content-end"  [collectionSize]="this.appservice.filteredcount(empsalarysummaryList,searchString)" [(page)]="page"  [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
      </ng-container>
  </div>
</div>
