
<div class="container-fluid p-32 form-container" [formGroup]="formGp">
    <p class="text-uppercase fw-500">FLOW DETAILS</p>
    <div class="row row-16">
        <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">Flow name</label>
            <input type="text" formControlName="name" class="form-control" placeholder="{{this.messageService.placeholderdisp('name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.touched || f.name.dirty) &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
            <div *ngIf="(submitted || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
              <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
              <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
            </div>
        </div>
    </div>
    <div class="row row-16 pt-16">
        <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">Add flow description </label>
            <input type="text" formControlName="description" class="form-control" placeholder="{{this.messageService.placeholderdisp('description')}}" [ngClass]="{ 'is-invalid': (submitted ||f.description.touched || f.description.dirty) &&  f.description.errors}">
            <div *ngIf="(submitted ||f.description.touched || f.description.dirty) && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!f.description.errors.pattern && f.description.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                </div>

            </div>
        </div>
    </div>
    <p class="text-uppercase fw-500 mt-32">approval flow</p>
    <div class="row row-16">
        <div class="col-12">
            <div class="card card-c2 overflow-hidden">
                <div class="table-responsive">
                    <table class="table table-striped form-table" formArrayName="roles">
                        <thead>
                            <tr>
                                <th scope="col" width="10%">level</th>
                                <th scope="col" width="25%" >
                                    <label class="form-label required mb-0">select role</label>
                                </th>
                                <th scope="col "  width="10%" class="text-center" >Intimation only</th>
                                <th scope="col" width="25%" >
                                    <label class="form-label required mb-0"> Tat</label>
                                </th>
                                <th scope="col" width="25%" >
                                    <label class="form-label mb-0"> Due days</label>
                                </th>
                                <th scope="col"  width="5%">&nbsp;&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let role of roles().controls; let i=index; "  [formGroupName]="i">

                                <td>{{showLevel(i)}}<input formControlName="level" type="hidden" class="form-control" ></td>
                                <td class="form-cell">
                                    <ng-select appendTo="body"  class="form-ngselect" #ngSelector
                                            formControlName="role"
                                            placeholder="{{this.messageService.selectplaceholddisplay('role')}}"
                                            [ngClass]="{ 'is-invalid': (submitted || roles().controls[i].get('role')?.touched) &&  roles().controls[i].get('role')?.errors?.required}">
                                        <ng-option *ngFor="let roled of roleDropdown"   [value]="roled.id">{{roled.role_name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="(submitted || roles().controls[i].get('role')?.touched) && roles().controls[i].get('role')?.errors?.required" class="invalid-feedback"><div *ngIf="roles().controls[i].get('role')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div></div>
                                </td>
                                <td class="form-cell">
                                    <label for="1">
                                        <input [attr.disabled]="i==0? true : null" formControlName="intimation" class="form-check-input" type="checkbox" value="" id="1">
                                    </label>
                                </td>
                                <td class="form-cell">
                                    <input *ngIf="roles().controls[i].value.intimation == false" formControlName="days" type="text" class="form-control" value="0"
                                    [ngClass]="{ 'is-invalid': (submitted || roles().controls[i].get('days')?.touched || roles().controls[i].get('days')?.dirty)
                                    &&  (roles().controls[i].get('days')?.errors?.required
                                    ||roles().controls[i].get('days')?.errors?.pattern
                                    ||roles().controls[i].get('days')?.errors?.maxlength )}">
                                    <div *ngIf=" (roles().controls[i].get('days')?.errors?.required
                                    ||roles().controls[i].get('days')?.errors?.pattern
                                    ||roles().controls[i].get('days')?.errors?.maxlength) && (submitted || roles().controls[i].get('days')?.touched || roles().controls[i].get('days')?.dirty)" class="invalid-feedback">
                                    <div *ngIf="roles().controls[i].get('days')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="roles().controls[i].get('days')?.errors?.pattern">Enter TAT in digits.</div>
                                    <div *ngIf="!(roles().controls[i].get('days')?.errors?.pattern) && roles().controls[i].get('days')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','3')}}

                                    </div>

                                </div>
                                </td>
                                <td class="form-cell">
                                    <input formControlName="due_days" type="text" class="form-control" value="0"
                                    [ngClass]="{ 'is-invalid': (submitted || roles().controls[i].get('due_days')?.touched || roles().controls[i].get('due_days')?.dirty)
                                    &&  (roles().controls[i].get('due_days')?.errors?.pattern
                                    || roles().controls[i].get('due_days')?.errors?.maxlength )}">
                                    <div *ngIf="(roles().controls[i].get('due_days')?.errors?.pattern
                                    ||roles().controls[i].get('due_days')?.errors?.maxlength) && (submitted || roles().controls[i].get('due_days')?.touched || roles().controls[i].get('due_days')?.dirty)" class="invalid-feedback">
                                    <!-- <div *ngIf="roles().controls[i].get('due_days')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div> -->
                                    <div *ngIf="roles().controls[i].get('due_days')?.errors?.pattern">Enter due days in digit</div>
                                    <div *ngIf="!(roles().controls[i].get('due_days')?.errors?.pattern) && roles().controls[i].get('due_days')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','3')}}

                                    </div>

                                </div>
                                </td>

                                <td>
                                    <button *ngIf="i!=0 && disabled==false" class="btn-reset" (click)="removeRole(i)">
                                        <i class="icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex">
            <a *ngIf="disabled==false" class="btn btn-outline-primary h-40 px-64 btn-sm radius-2" (click)="addRoles()">Add </a>
        </div>
    </div>
    <!-- <pre>Form Values: {{f.description.errors| json}}</pre> -->
</div>
<footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid " (click)="validateConfigureFlow()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateConfigureFlow()">NEXT</button>

</footer>
