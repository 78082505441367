// import { Component, OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-salary-dashboard',
  templateUrl: './salary-dashboard.component.html',
  styleUrls: ['./salary-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SalaryDashboardComponent  implements OnInit,AfterViewInit{
  multi:any =[]
  filter  = false;
  activeTab1 = 1;
  graphloader: boolean = false;
  xAxisLabel:any = [];
  yAxisLabel: string = '';
  movetoPoint = 0;
  activeItem:any = [];
  payrollCount:any = [];
  payrollMonthlyCost:any = [];
  graph:any = [];
  year:any=false;
  graphyear:any = ""
  month:any=false;

  loader = true
  company:any = ""
  companyList:any = [];
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  currentYear = new Date().getFullYear();
  currency = ""
  config:any = [];
  progress = 0;
  access = true;
  nodataFound = false;
  nographdata = false
  bannerSidebar = false;
  banneropen = true;

  clickCarousel(item:any) {
    if(this.year != "" && this.month != "" )
      this.router.navigateByUrl("/salary-dashboard/"+item.payroll_year+"/"+item.payroll_month+"/"+this.company)
    else {
      this.loader =true;
      this.carouselTileItems[item['key']]['status'] = "active";
      this.carouselTileItems[this.activeItem['key']]['status'] = this.carouselTileItems[this.activeItem['key']]['state'];
      this.activeItem = item;
      this.getPayrollDetails()
      this.getStatutoryCheckData();
    }
   }

  public carouselTileItems: Array<any> = [];
  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  @ViewChild('myCarousel') myCarousel!: NguCarousel<any>;
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 5, lg: 6, all: 0 },
    slide: 1,
    speed: 250,
    point: {
      visible: true
    },
    loop:false,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  range:any =[]
  constructor(private _cdr: ChangeDetectorRef,public sds:SalaryDashboardService,public route:ActivatedRoute,public router:Router,private NotificationService:NotificationService,private rs:RoleService,public messageService : MessageService,public appService: AppService) {
  }

//   ngAfterContentChecked() {
//     this.createDragElement();
//   }

//   private createDragElement() {
//     const elmnt = document.getElementById('draggableElement');
//     let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
//     const dragMouseDown = (e:any) => {
//       e = e || window.event;
//       // get the mouse cursor position at startup:
//       pos3 = e.clientX;
//       pos4 = e.clientY;
//       document.onmouseup = closeDragElement;
//       // call a function whenever the cursor moves:
//       document.onmousemove = elementDrag;
//     };

//     const elementDrag = (e:any) => {
//       e = e || window.event;
//       // calculate the new cursor position:
//       pos1 = pos3 - e.clientX;
//       pos2 = pos4 - e.clientY;
//       pos3 = e.clientX;
//       pos4 = e.clientY;
//       // set the element's new position:
//       if(elmnt){
//         elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
//         elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
//       }
//     };

//     const closeDragElement = () => {
//       /* stop moving when mouse button is released:*/
//       document.onmouseup = null;
//       document.onmousemove = null;
//     };
//     if(elmnt){
//         elmnt.onmousedown = dragMouseDown;
//   }
// }
  selectedCityName = 154
  ngOnInit() {

    this.getCompanyDropdown();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
        this.companySelected()
      }
    })

  }
  companySelected(){
    if(this.company!='' && this.company!=null){
      this.loader =true;
      this.companyChange()
      this.createCaroselItem();
      this.createYearArray();
      this.DepList = [];
    }
  }

  ngAfterViewInit() {
    // this.myCarousel.moveTo(this.movetoPoint/2-1,false);
    // this._cdr.detectChanges();
  }
  getCompanyDropdown(){
    this.sds.companyDropDownData()
    .subscribe((res: any) => {
      if (res.status == 200) {
        if(res.body.length == 0){
          this.nodataFound = true;
          this.loader   = false;
          this._cdr.detectChanges();
        } else {
          this.companyList = res.body;
          if(this.company==''){
            this.company =  this.company==''?res.body[0]['id']:this.company;
            this.companySelected()
          }
        }
      }
    })


  }
  getPayrollDetails(){
    if(this.company!=null && this.company!==''){

      this.loader =true
      this.nodataFound = false;
      if(this.activeItem.payroll_month!= undefined){
        this.sds.getPayrollMonthData(this.activeItem.payroll_year,this.activeItem.payroll_month.charAt(0).toUpperCase()+ this.activeItem.payroll_month.slice(1),this.company).subscribe((res:any)=>{
          if(res.body?.error ){
            this.nodataFound = true;
            this.NotificationService.handleErrorNotification("Something went to wrong.","Error");
            // this.loader =false
          } else {
            this.payrollCount = res.body.data?.payroll_count;
            this.currency = res.body.data?.currency
            this.payrollMonthlyCost = res.body.data?.payroll_monthly_cost;
            if(this.payrollCount?.calender_days?.error)
              this.NotificationService.handleErrorNotification(this.payrollCount?.calender_days?.error,"Error")
            // this.loader =false
          }
          this._cdr.detectChanges();
        });
        this.getListData()
      }
    }
    else
    this.loader = false

  }
  getStatutoryCheckData(){
    if(this.company!=null && this.company!==''){

      if(this.activeItem.payroll_month!= undefined){

        this.progress = 0;

        this.sds.getStatutoryCheckData(this.activeItem.payroll_year,this.activeItem.payroll_month?.charAt(0).toUpperCase()+ this.activeItem.payroll_month.slice(1),this.company).subscribe((res:any)=>{

          this.config.is_statutory_configured = res?.body?.data?.is_statutory_configured;

          let increment = this.config.prior_payroll_status==true?33:50;

          if(this.config?.prior_payroll_status && this.config?.complete_priorpayroll)
            this.progress += increment;

          if(this.config?.is_payroll_configured == false){
            this.progress += increment;
          }

          if(this.config?.is_statutory_configured == false){
            this.progress += increment;
          }
            this.loader = false

          this._cdr.detectChanges();
        });
      }
    }
  }

 createCaroselItem(){
  var state = "";
  this.carouselTileItems = [];
  this.activeItem = []
  this.progress = 0;
  this.sds.getDateRangeSlider(this.company).subscribe((res:any)=>{
    this.config = res.body.configure_dict;
    this.access = res.body.data_access;
    let increment = this.config.prior_payroll_status==true?33:50;
    if(this.config?.prior_payroll_status && this.config?.complete_priorpayroll)
    this.progress += increment;
    if(this.config?.is_payroll_configured == false){
      this.progress += increment;
    }
    if(this.config?.is_statutory_configured == false){
      this.progress += increment;
    }

    if(res.status == 200){

      if(res.body.data.length){
          res.body.data.forEach( (value:any, key:any)=>{
              state = value.payroll_status;
              this.carouselTileItems.push({
                state:value.payroll_status,
                status:state,
                // month : value.payroll_month.substring(0, 3)+" "+value.year,
                 month : value.payroll_month+" "+value.year,
                range : value.start_month.substring(0, 3)+" "+value.start_day+" - "+value.end_month.substring(0, 3)+" "+value.end_day,
                payroll_year :value.year,
                payroll_month : value.payroll_month,
                key:key
              });
              var currentTime = new Date()
              var vyear = currentTime.getFullYear()
              var cMonth = currentTime.getMonth()
              var months = ["January", "February", "March", "April", "May","June", "July", "August", "September", "October", "November", "December"];
              // if(value.payroll_status == "current" && this.carouselTileItems[key-1]['state'] == 'completed' && !this.year && !this.month ){
              if(value.payroll_status == "current"  && !this.year && !this.month ){
                  this.carouselTileItems[key]['status'] = "active";
                  this.activeItem = this.carouselTileItems[key];
                  this.movetoPoint = key
              }else if(this.year  && this.month  && this.year == value.year && this.month == value.payroll_month){
                this.activeItem = this.carouselTileItems[key];
                this.carouselTileItems[key]['status'] = "active";
                this.movetoPoint = key
              } else if(this.activeItem.length == 0 && vyear == value.year && months[cMonth] == value.payroll_month  && !this.year && !this.month){
                this.activeItem = this.carouselTileItems[key];
                this.carouselTileItems[key]['status'] = "active";
                this.movetoPoint = key
              }

          });
          if(this.activeItem.length == 0){
              this.carouselTileItems[0]['status'] = "active";
              this.activeItem = this.carouselTileItems[0];
              this.movetoPoint = 0
          }
          this.nodataFound = false;
          this.getPayrollDetails()
          this.getStatutoryCheckData();
          this._cdr.detectChanges();
      } else {
        this.nodataFound = true;
        this.loader =false;
      }

      this.myCarousel?.moveTo(this.movetoPoint-3,true);
      // this.myCarousel.moveTo(this.movetoPoint/2-1,false);
      // this.loader =false;
      this._cdr.detectChanges();
    }
  });
 }
  createYearArray(){
    this.range = []
    var yearr =  Number(this.appService.getFinancialYear());
    this.graphloader = true
    for (var i = yearr+1; i >=2020; i--) {
        this.range.push({k:String(i),v:String(i)+" - "+String(i+1)});
    }
    this.graphyear = "FY "+String(yearr)+" - "+String(yearr+1);
    this.sds.getPayrollGraph(yearr,this.company).subscribe((res:any)=>{
      if(res.body.status == 0){
        this.graphdummyData()
      } else {
        // this.graph = res.body.output_dict
        this.createGraphData(res.body.output_dict);
      }
    });

  }
  changeGraph(event:any){
    var year = event != undefined?Number(event):Number(this.appService.getFinancialYear());
    this.graphyear = "FY "+String(year)+" - "+String(year+1);
    this.graphloader = true
    this.nographdata=false;
    this.sds.getPayrollGraph(year,this.company).subscribe((res:any)=>{
      this.graphyear = "FY "+String(year)+" - "+String(year+1);
      if(res.body.status == 0){
        this.graphdummyData()
      } else {
        // this.graph = res.body.output_dict
        this.createGraphData(res.body.output_dict);
      }
    });

  }
  graphdummyData(){
    this.nographdata=true;this._cdr.detectChanges();
  }
  createGraphData(graph:any){
    this.graphloader = false
    this.multi = []
    this.xAxisLabel =  ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']
    let net:any         = [
                            graph.April.net ? graph.April.net : 0,
                            graph.May.net ? graph.May.net : 0,
                            graph.June.net ? graph.June.net : 0,
                            graph.July.net ? graph.July.net : 0,
                            graph.August.net ? graph.August.net : 0,
                            graph.September.net ? graph.September.net : 0,
                            graph.October.net ? graph.October.net : 0,
                            graph.November.net ? graph.November.net : 0,
                            graph.December.net ? graph.December.net : 0,
                            graph.January.net ? graph.January.net : 0,
                            graph.February.net ? graph.February.net : 0,
                            graph.March.net ? graph.March.net : 0];
    let benefit:any     = [
                            graph.April.benefit ? graph.April.benefit : 0,
                            graph.May.benefit ? graph.May.benefit : 0,
                            graph.June.benefit ? graph.June.benefit : 0,
                            graph.July.benefit ? graph.July.benefit : 0,
                            graph.August.benefit ? graph.August.benefit : 0,
                            graph.September.benefit ? graph.September.benefit : 0,
                            graph.October.benefit ? graph.October.benefit : 0,
                            graph.November.benefit ? graph.November.benefit : 0,
                            graph.December.benefit ? graph.December.benefit : 0,
                            graph.January.benefit ? graph.January.benefit : 0,
                            graph.February.benefit ? graph.February.benefit : 0,
                            graph.March.benefit ? graph.March.benefit : 0];
    let tax:any         = [
                            graph.April.tax ? graph.April.tax : 0,
                            graph.May.tax ? graph.May.tax : 0,
                            graph.June.tax ? graph.June.tax : 0,
                            graph.July.tax ? graph.July.tax : 0,
                            graph.August.tax ? graph.August.tax : 0,
                            graph.September.tax ? graph.September.tax : 0,
                            graph.October.tax ? graph.October.tax : 0,
                            graph.November.tax ? graph.November.tax : 0,
                            graph.December.tax ? graph.December.tax : 0,
                            graph.January.tax ? graph.January.tax : 0,
                            graph.February.tax ? graph.February.tax : 0,
                            graph.March.tax ? graph.March.tax : 0];
    let deduction:any   = [
                            graph.April.deduction ? graph.April.deduction : 0,
                            graph.May.deduction ? graph.May.deduction : 0,
                            graph.June.deduction ? graph.June.deduction : 0,
                            graph.July.deduction ? graph.July.deduction : 0,
                            graph.August.deduction ? graph.August.deduction : 0,
                            graph.September.deduction ? graph.September.deduction : 0,
                            graph.October.deduction ? graph.October.deduction : 0,
                            graph.November.deduction ? graph.November.deduction : 0,
                            graph.December.deduction ? graph.December.deduction : 0,
                            graph.January.deduction ? graph.January.deduction : 0,
                            graph.February.deduction ? graph.February.deduction : 0,
                            graph.March.deduction ? graph.March.deduction : 0];
      this.multi.push(
        {borderSkipped:true,label: "Net pay", data: net, backgroundColor: "#0066DD", barThickness: 10 },
        {borderSkipped:true,label: "Taxes", data: tax, backgroundColor: "#FFC107", barThickness: 10 },
        {borderSkipped:true,label: "Contributions", data: benefit, backgroundColor: "#28A745", barThickness: 10 },
        {borderSkipped:true,label: "Deductions", data: deduction, backgroundColor: "#DC3545", barThickness: 10 }
      )



    this._cdr.detectChanges();
  }
  getAbs(val:any){
    return Math.abs(val)
  }
  getClass(val:any){
    return (val>0)?"graph-increase text-success":(val==0)?"":"graph-decrease text-danger"
  }
  getPreMonth(month:any,year:any){
    const current = new Date(month+' 1'+year);
    current.setMonth(current.getMonth()-1);
    return  current.toLocaleString('default', { month: 'short' });
  }


  ///reconcilation
  page = 1;
  pageSize =20;
  salarysummaryList = []
  empsalarysummaryList = []
  previousMonth = '';
  searchString:any = "";
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  loader1 = true
  loader2 = true

  getListData(){
    if(this.company!=null && this.company!==''){

      this.sds.getSalarySummary(Number(this.activeItem.payroll_year),this.months[this.activeItem.payroll_month],this.company,this.buList,this.DepList,this.DesignationList,this.gradeList,this.BranchList).subscribe((res:any)=>{
        if(res.body.currency === 'INR'){
          this.yAxisLabel = 'INR'
        }else{
          this.yAxisLabel = 'USD'
        }

        this.salarysummaryList = res.body.salary_summary
        this.loader1 = false
        this._cdr.detectChanges();
      });
      this.sds.getEmployeeSalarySummary(Number(this.activeItem.payroll_year),this.months[this.activeItem.payroll_month],this.company,this.buList,this.DepList,this.DesignationList,this.gradeList,this.BranchList).subscribe((res:any)=>{
        this.empsalarysummaryList = res.body.salary_summary
        this.loader2 = false
        this._cdr.detectChanges();
      });
    }
  }

  onNavChange(e:any){
    this.searchString = ''
  }

  companyChange(){
    this.filtercomp?.getBUList(this.company)
    // this.buDD = [];
    // this.getBUList(this.company);
  }

  resetFilter(){
    this.searchString = ''
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.getListData()
    this.filter  = false
  }
  submitForm(data:any){
    this.filter  = false
    this.filter           = false
    this.buList          = data.buList!=""?data.buList.join(','):[]
    this.DepList         = data.DepList!=""?data.DepList.join(','):[]
    this.DesignationList = data.DesignationList!=""?data.DesignationList.join(','):[]
    this.gradeList       = data.gradeList!=""?data.gradeList.join(','):[]
    this.BranchList      = data.BranchList!=""?data.BranchList.join(','):[]
    this.searchString    = ''
    this.getListData()
  }
  closeFilter(){
    this.filter  = false
  }
  movePrior(){
    if(this.config.is_payroll_configured==false && this.config.is_statutory_configured==false)
    this.router.navigateByUrl("/prior-payroll-setup/"+this.appService.getFinancialYear()+"/"+this.activeItem.payroll_month+"/"+this.company)

  }
  closebanner(key:any){
    this.bannerSidebar=key;
  }
  bannerCloseBtn(event: Event): void {
    this.banneropen = false;
    event.stopPropagation();
  }
}
