<div class="container-fluid p-24" *ngIf="employee_id == undefined && routingDomain; else data">
  <div class="row">
    <div class="col-10 mx-auto">
      <div class="flex-column text-center d-flex flex-center text-light-500 " style="min-height:calc(100vh - 96px);">
        <img style="height:60vh;max-height: 240px;" src="assets\images\bg\welcome.svg" alt=""
          class="img-fluid d-flex mx-auto pb-32">
        <div class="fw-600 fs-14 pb-12 text-uppercase">Simplify, streamline and optimize
          your HR processes</div>
        <div class="fs-12 text-light-500">
          Start exploring the system now by clicking on the menu’s
        </div>
      </div>
    </div>
  </div>
</div>
<!-- If employee exist -->
<ng-template #data>
  <div class="container-fluid p-24">
    <div class="row row-16">
      <div class="col-md-4">
        <app-home-loaders [loader]="'loader_employee'" *ngIf="loader_employee; else employee_div"></app-home-loaders>
        <ng-template #employee_div>
          <div class="card card-c2 p-24 highlight-top h-100 justify-content-between overflow-hidden">
            <h3 class="mb-16 fs-28">Welcome !</h3>
            <div class="d-flex gap-16 mb-24 align-items-center">

              <img class="img-fluid avatar-circle sq-64 " *ngIf="(imageUrl!=null && imageUrl != '')" [src]="imageUrl"
                alt="">
              <div *ngIf="imageUrl ==  null || imageUrl == ''"
                class="avatar-circle  sq-64  profile-pic fs-18 bg-white avatar-border {{employee_data?.color_code}}">
                {{this.appservice.getFirstChar(userDataSession.first_name+"
                "+(userDataSession.middle_name? userDataSession.middle_name+' ' :
                '')+userDataSession.last_name,2)}}</div>

              <div class="d-flex flex-column gap-1">
                <p class="mb-0 fw-500 fs-18 text-trim ">{{employee_data?.employee_name}}
                  <span class="fs-12 fw-500 text-uppercase text-light-400">{{'
                    ('+employee_data?.employee_code+')'}}</span>
                </p>
                <p class="mb-0 fw-500 fs-14 text-uppercase text-trim text-light-400">
                  {{employee_data?.designation}}</p>
              </div>
            </div>
            <div class="col-12" *ngIf="noemployeedata && !loader_employee; else all_employee_data">
              <div role="alert" class="alert alert-warning mb-16 mt-40 py-10 show" id="empShow">
                <p class="mb-0 fs-14 fw-500"> Salary structure has not been assigned yet</p>
                <div class="btn-close pointer" (click)="closeEmp()"></div>
              </div>
              <p class="fw-500 fs-12 text-center">For more details Contact HR</p>
            </div>
            <ng-template #all_employee_data>
              <div class="card card-c2 p-16 bg-secondary justify-content-between
                                flex-row flex-wrap gap-24 mb-8 overflow-hidden" *ngIf="!noemployeedata">
                <div>
                  <p class="mb-0 fs-12 text-light-500 lh-base"> Annual CTC</p>
                  <p class="mb-0 fs-14 fw-500">{{employee_data?.annual_ctc | currency :
                    currency}}</p>
                </div>
                <div>
                  <p class="mb-0 fs-12 text-light-500 lh-base">Monthly CTC</p>
                  <p class="mb-0 fs-14 fw-500">{{employee_data?.monthly_ctc | currency :
                    currency}}</p>
                </div>
                <div>
                  <p class="mb-0 fs-12 text-light-500 lh-base">Monthly gross</p>
                  <p class="mb-0 fs-14 fw-500">{{employee_data?.monthly_gross | currency :
                    currency}}</p>
                </div>
              </div>
              <div class="d-flex fs-14 fw-500 ">
                <a class="link-primary1 me-24" routerLink="/my-salary-structure"> Salary
                  structure</a>
                <a class="link-primary1 ms-auto" [routerLink]="['/my-salary-structure']" [queryParams]="{home:true}">
                  Salary revision history</a>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4 ">

        <div class="card card-c2 p-24 h-100 overflow-hidden overflow-hidden">
          <div class="row row-16">
            <app-home-loaders [loader]="'loader_salary'" *ngIf="loader_salary; else salary_div"></app-home-loaders>
            <ng-template #salary_div>
              <div class="col-12 d-flex align-items-center flex-column gap-10">
                <!-- Div not in design (implemented for checking no data) also added flex-column on abv code -->
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <div class="fw-500 mb-0">Salary summary</div>
                  <div class="ms-auto">
                    <div ngbDropdown class="input-dropdown four-dropdown " container="body">
                      <button type="button" class="card card-c2 h-36 fs-14" ngbDropdownToggle>
                        <span class="">{{currentMonth}} {{currentYear}}</span>
                      </button>
                      <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                        <div class="row">
                          <div class="col-5">
                            <label for="" class="form-label">Year </label>
                            <ng-select class="form-ngselect h-36 fs-14" [(ngModel)]="currentYear" [clearable]="false"
                              (change)="changeCarousel($event,'year')">
                              <ng-option *ngFor="let yData of salaryYears" [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                          <div class="col-7">
                            <label for="" class="form-label">Month </label>
                            <ng-select class="form-ngselect h-36 fs-14" [(ngModel)]="currentMonth" [clearable]="false"
                              (change)="changeCarousel($event,'month')">
                              <ng-option *ngFor="let yData of listOfMonth" [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 " *ngIf="nosalarydata && !loader_salary; else all_salary_data">
                  <div class="flex-center d-flex flex-column gap-20" style="height: 12rem;">
                    <img src="assets\images\bg\line-art1.svg" class="img-fluid">

                    <p class="fs-14 fw-500">No salary summary found</p>
                  </div>
                </div>
              </div>
              <ng-template #all_salary_data>
                <div class="col-12" *ngIf="!nosalarydata">

                  <!-- <ngx-charts-advanced-pie-chart id="salarysummary"
                                                [scheme]="salary_summary_colorScheme" [results]="salarySummary"
                                                [label]="'Gross'" [gradient]="gradient" (select)="onSelect($event)"
                                                [valueFormatting]="format" [animations]="false">
                                            </ngx-charts-advanced-pie-chart> -->
                  <app-doughnut-chart [labeltype]="2" [radius]="50" [color]="salary_summary_colorScheme"
                    [data]="salarySummary" [labels]="salaryLabels" [id]="0" [showRupee]="true" [currency]="currency"
                    [total]="'Gross'">
                  </app-doughnut-chart>
                  <!-- <div class="label">
                                          {{currentMonth | uppercase}}&nbsp;{{currentYear}}
                                        </div> -->

                </div>
                <div class="col-12">
                  <div class="card card-c2 flex-row fw-500 fs-14 px-16 py-8 bg-secondary ">
                    <a class="link-primary1 me-24" (click)="downLoad(salaryData.payslip_url)"><i
                        class="icon-download me-1"></i> Download
                      payslip</a>
                    <a class="link-primary1 ms-auto" routerLink="/payslip">Salary
                      details</a>
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-4 ">
        <div class="card card-c2 p-24 overflow-hidden">
          <div class="row row-16">
            <app-home-loaders *ngIf="loader_tax; else tax_div" [loader]="'loader_tax'"></app-home-loaders>
            <ng-template #tax_div>
              <div class="col-12 d-flex align-items-center">
                <div class="fw-500 mb-0">Tax summary till date</div>
                <div class="ms-auto" style="width:9rem">
                  <ng-select class="form-ngselect h-36 fs-14 w-100" [clearable]="false" (change)="yearChange($event)"
                    [(ngModel)]="placeholderData">
                    <ng-option class="fs-12" *ngFor="let year of nextYears" [value]="year">
                      {{year}} </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-12 align-items-center" style="height: 14.375rem;"
                *ngIf="notaxdata && !loader_tax; else all_tax_data">
                <div class="flex-center d-flex flex-column gap-20" style="height: 14rem;">
                  <img src="assets\images\bg\line-art1.svg" class="img-fluid">
                  <p class="fs-14 fw-500">No tax summary found</p>
                </div>
              </div>
              <ng-template #all_tax_data>
                <div class="col-12" *ngIf="!notaxdata">

                  <!-- <ngx-charts-advanced-pie-chart [scheme]="colorScheme"
                                                [results]="taxSummary" id="taxsummary" [label]="'Total Tax'"
                                                [gradient]="gradient" (select)="onSelect($event)"
                                                [valueFormatting]="format" [animations]="false">
                                            </ngx-charts-advanced-pie-chart> -->
                  <app-doughnut-chart [radius]="60" [color]="colorScheme" [data]="taxSummary" [labels]="taxLabels"
                    [id]="1" [showRupee]="true" [currency]="currency" [total]="'Total Tax'"> </app-doughnut-chart>

                </div>
                <div class="col-12">
                  <div class="card card-c2 p-20 bg-secondary flex-row gap-8">
                    <i class="bi bi-info-circle"></i>
                    <div class="d-flex flex-column gap-8">
                      <span class="fs-14 mb-0">You have opted for <span
                          class="fw-600">{{taxData?.tax_regime}}</span></span>
                      <span class="fs-14 mb-0">The remaining tax to be paid monthly till
                        the end of financial year ({{taxData?.financial_end}}) is <span
                          class="fw-600">{{taxData?.monthly_amount | currency:
                          currency}}</span></span>
                      <a href="" class="link-primary1 fs-14 fw-500" routerLink="/taxslip">View Tax Details</a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card card-c2 p-24 overflow-hidden">
          <div class="row row-16">

            <app-home-loaders *ngIf="loader_financial; else financial_div"
              [loader]="'loader_financial'"></app-home-loaders>
            <ng-template #financial_div>
              <div class="col-12 d-flex align-items-center">
                <div class="fw-500 mb-0">Financial year {{withoutFY}}</div>
                <!-- Added one more div to wrap financial year dropdown and pay dropdown -->
                <div class="input-group1 ms-auto fs-14">
                  <ng-select style="width:144px" class="form-ngselect h-36  w-100" [clearable]="false"
                    (change)="yearChangeFinancial($event)" [(ngModel)]="placeFinancial">
                    <ng-option class="fs-12" *ngFor="let year of nextYears" [value]="year">
                      {{year}} </ng-option>
                  </ng-select>
                  <ng-select style="width:144px" class="form-ngselect h-36 w-100" [clearable]="false"
                    (change)="typeChange($event)" [(ngModel)]="typeSelect">
                    <ng-option class="fs-12" value="gross_pay"> Gross Pay </ng-option>
                    <ng-option class="fs-12" value="net_pay"> Net Pay </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-12 " *ngIf="nofinancialdata && !loader_financial; else financial_data">
                <div class="flex-center d-flex flex-column gap-20" style="height: 240px;">
                  <img src="assets\images\bg\line-art3.svg" class="img-fluid">
                  <p class="fs-14 fw-500">No financial year statistic found</p>
                </div>
              </div>


              <ng-template #financial_data>
                <div class="col-12">
                  <app-line-chart [pin]="false" [labels]="financialLabels" [data]="financialData" [fill]="'origin'"
                    [type]="selectedTypeVal" [bgColor]="'rgba(0, 120, 206, 0.20)'" [ratio]="5"></app-line-chart>

                  <!-- <div class="custom-linearea-chart no-x-axis" style="height:300px" *ngIf="!nofinancialdata">
                    <ngx-charts-area-chart [scheme]="colorScheme2" [gradient]="gradient" [results]="financialSummary"
                      (select)="onSelect($event)" [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="false"
                      [xAxis]="true" [yAxis]="true" [yAxisLabel]="'Value'" [timeline]="true" [autoScale]="true">
                      <ng-template #seriesTooltipTemplate let-model="model">
                        <ng-container *ngFor="let data of model">
                          <span class="d-flex flex-center"> <span class="chart-stamp"
                              [style.background-color]="data.color"></span>
                            {{selectedTypeVal}}
                            : {{ data.value}}</span>
                        </ng-container>
                      </ng-template>
                    </ngx-charts-area-chart>
                  </div> -->
                </div>
              </ng-template>

            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card card-c2 p-24 overflow-hidden">
          <div class="row row-12">
            <app-home-loaders *ngIf="loader_reimbursement; else reimburse_div"
              [loader]="'loader_reimbursement'"></app-home-loaders>
            <ng-template #reimburse_div>
              <div class="col-12 d-flex gap-16 align-items-center justify-content-between">
                <div class="fw-500 mb-0">Reimbursement summary till date</div>
                <div class="" style="width:9rem">
                  <ng-select class="form-ngselect fs-14 h-36" (change)="yearReimburseChange($event)" [clearable]="false"
                    [(ngModel)]="placeholderReimburse">
                    <ng-option class="fs-12" *ngFor="let year of nextYears" [value]="year">{{year}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-12 " *ngIf="noremibursedata && !loader_reimbursement; else all_reimburse_data"
                style="height : 140px">
                <div class="flex-center d-flex flex-column gap-20">
                  <img src="assets\images\bg\line-art1.svg" class="img-fluid">
                  <p class="fs-14 fw-500">No reimbursement data found</p>
                </div>
              </div>
              <ng-template #all_reimburse_data>
                <div class="col-12 mt-24">
                  <div class="0 d-flex align-items-baseline">
                    <span class="fs-14 text-light-500">Reimbursement
                      {{placeholderReimburse}}</span>
                    <span class="fs-14 text-light-500 ms-auto me-1">Total eligible</span>
                    <span class="fs-18 fw-500">{{reimburseData?.eligible | currency :
                      currency}}</span>
                  </div>
                  <div class="custom-progress my-1 radius-4" style="height:2rem">
                    <div class="progress-bar bg-accent1" style="--percentage:{{reimburseData?.percentage}}%;"></div>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="fs-12 text-light-400 status1 status-primary">Claimed :
                    </span>
                    <span class="fs-14 fw-500 me-auto ms-1">{{reimburseData?.claimed |
                      currency : currency}}</span>
                    <span class="fs-12 text-light-400 status1 status-secondary">Unclaimed :
                    </span>
                    <span class="fs-14 fw-500 ms-1">{{reimburseData?.unclaimed | currency :
                      currency}}</span>
                  </div>
                  <div class="mt-2" style="text-align: right;">
                    <a class="link-primary1 ms-auto fw-500 fs-14" routerLink="/reimbursement"
                      *ngIf="!noremibursedata">View
                      reimbursement details</a>
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-8">
        <div class="row row-16">



        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="row row-16">
          <div class="col-12" *ngIf="routingDomain">
            <div class="card card-c2 overflow-hidden">
              <div class="row row-8 px-24 pt-24">
                <div class="col-12">
                  <div role="alert" class="alert alert-warning mb-0 show pe-16">
                    <p class="mb-0 fs-14 me-16"> <span class="fw-500">{{inboxItems}} pending items
                      </span> in your inbox requires your action</p>
                    <div class="btn btn-warning fw-500 fs-12 text-white text-nowrap p-6 ms-auto"
                      style="min-height:unset;" (click)="inboxNavigate()"> Go to Inbox</div>
                  </div>
                </div>
              </div>
              <app-home-loaders *ngIf="loader_announce; else announce_div"
                [loader]="'loader_announce'"></app-home-loaders>
              <ng-template #announce_div>
                <div class="col-12 " *ngIf="noannounce && !loader_announce; else all_announce_data">
                  <div class="flex-center d-flex flex-column " style="height: 244px;">
                    <img src="assets\images\bg\line-art2.svg" class="img-fluid">
                    <p class="fs-14 fw-500 mt-20">There are no announcements</p>
                    <span style="width:200px" class="fs-10 text-center">You are all caught up, Check
                      back later
                      for new notification's</span>
                  </div>
                </div>
                <ng-template #all_announce_data>
                  <p class="mb-0 fw-500 px-24 pt-16">Announcements</p>
                  <div class="card card-c2 border-0 h-100 px-24 " style="max-height:calc(103.2px *4); overflow-y: auto;"
                    infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]="false" [infiniteScrollThrottle]="50"
                    [fromRoot]="true" (scrolled)="onScrollDown()">
                    <ng-container *ngFor="let item of announceData; let i = index">
                      <div class="card-body d-flex px-0 py-12">
                        <div class="d-flex flex-column align-items-start gap-2">
                          <div class="badge badge-danger fs-8 fw-500 py-1 px-2">WINDOW CLOSES
                            SOON</div>
                          <p class="mb-0 fs-14 fw-500 text-trim  lh-base">{{item?.name}}</p>
                          <span class="text-light-500 text-trim  fs-12">Window open till
                            <span class="fw-500">{{this.appservice.dateFormatDisplay(item?.value)}}</span>
                          </span>
                          <a href="" class="link-primary1 fs-12 fw-500" routerLink="/request">Declare Now</a>
                        </div>
                        <div class="sq-42 flex-center bg-alert ms-auto fs-20 avatar-circle {{item?.icon_bg}}">
                          <i class="{{item?.icon}}"></i>
                        </div>
                      </div>
                    </ng-container>
                    <app-home-loaders *ngIf="loader_announce_scroll;" [loader]="'loader_announce'"></app-home-loaders>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </div>

        </div>
      </div> -->
      <!-- <div class="col-lg-4">
                <div class="row row-16">
                    <div class="col-12">
                        <div class="card card-c2 overflow-hidden">
                            <div class="row row-8 px-24 pt-24">
                                <div class="col-12">
                                    <div role="alert" class="alert alert-warning mb-0 show pe-16">
                                        <p class="mb-0 fs-14 me-16"> <span class="fw-500">{{inboxItems}} pending items </span> in your inbox requires your action</p>
                                        <div class="btn btn-warning fw-500 fs-12 text-white text-nowrap p-6 ms-auto" style="min-height:unset;" (click)="inboxNavigate()"> Go to Inbox</div>
                                    </div>
                                </div>
                            </div>
                            <app-home-loaders *ngIf="loader_announce; else announce_div" [loader]="'loader_announce'"></app-home-loaders>
                            <ng-template #announce_div>
                                <div class="col-12 " *ngIf="noannounce && !loader_announce; else all_announce_data">
                                    <div class="flex-center d-flex flex-column " style="height: 244px;">
                                        <img src="assets\images\bg\line-art2.svg" class="img-fluid">
                                        <p class="fs-14 fw-500 mt-20">There are no announcements</p>
                                        <span style="width:200px" class="fs-10 text-center">You are all caught up, Check back later
                                            for new notification's</span>
                                    </div>
                                </div>
                                <ng-template #all_announce_data>
                                    <p class="mb-0 fw-500 px-24 pt-16">Announcements</p>
                                        <div class="card card-c2 border-0 h-100 px-24 " style="max-height:calc(103.2px *3); overflow-y: auto;" infiniteScroll [infiniteScrollDistance]="2"   [scrollWindow]="false" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
                                            <ng-container *ngFor="let item of announceData; let i = index">
                                                <div class="card-body d-flex px-0 py-12">
                                                    <div class="d-flex flex-column align-items-start gap-2">
                                                        <div class="badge badge-danger fs-8 fw-500 py-1 px-2">WINDOW CLOSES SOON</div>
                                                        <p class="mb-0 fs-14 fw-500 text-trim  lh-base">{{item?.name}}</p>
                                                        <span class="text-light-500 text-trim  fs-12">Window open till
                                                            <span class="fw-500">{{item?.value}}</span>
                                                        </span>
                                                        <a href="" class="link-primary1 fs-12 fw-500" routerLink="/request">Declare Now</a>
                                                    </div>
                                                    <div class="sq-42 flex-center bg-alert ms-auto avatar-circle  bg30 text19">
                                                        <i class="{{item?.icon}}"></i>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                </ng-template>
                                <div class="loader text-center" [style.display]="infinityloader" >
                                    <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-12 ">
                        <div class="card card-c2 p-24 overflow-hidden">
                            <div class="row row-16">
                                <app-home-loaders *ngIf="loader_tax; else tax_div" [loader]="'loader_tax'"></app-home-loaders>
                                <ng-template #tax_div>
                                    <div class="col-12 d-flex align-items-center">
                                        <div class="fw-500 mb-0">Tax Summary Till Date</div>
                                        <div class="ms-auto" style="width:144px">
                                            <ng-select class="form-ngselect fs-14 w-100" (change)="yearChange($event)" [(ngModel)]="placeholderData">
                                                <ng-option class="fs-12" *ngFor="let year of nextYears" [value]="year"> {{year}} </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-12 align-items-center" style="height: 230px;"  *ngIf="notaxdata && !loader_tax; else all_tax_data">
                                        <div class="flex-center d-flex flex-column gap-20" style="height: 224px;">
                                            <img src="assets\images\bg\line-art4.svg" class="img-fluid">
                                            <p class="fs-14 fw-500">No Tax summary found</p>
                                        </div>
                                     <ng-template #all_tax_data>
                                        <div class="col-12" *ngIf="!notaxdata">
                                            <div class="custom-doughnut  chart-doughnut3 hide-total" style="min-height:100px;">
                                                <ngx-charts-advanced-pie-chart [scheme]="colorScheme" [results]="taxSummary"
                                                    [gradient]="gradient" (select)="onSelect($event)" [valueFormatting]="format" [animations]="false">
                                                </ngx-charts-advanced-pie-chart>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="card card-c2 p-20 bg-secondary flex-row gap-8">
                                                <i class="bi bi-info-circle"></i>
                                                <div class="d-flex flex-column gap-8">
                                                    <span class="fs-14 mb-0">You have opted for <span class="fw-600">{{taxData?.tax_regime}}</span></span>
                                                    <span class="fs-14 mb-0">The remaining tax to be paid monthly till the end of financial year ({{taxData?.financial_end}}) is <span class="fw-600">{{taxData?.monthly_amount | currency: currency}}</span></span>
                                                    <a href="" class="link-primary1 fs-14 fw-500" routerLink="/taxslip" >View Tax Details</a>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template></div>

                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
  <!-- </div> -->
</ng-template>