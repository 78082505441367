import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ExpenseManagementService } from 'src/app/@core/services/expense-management.service';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-expense-management',
  templateUrl: './expense-management.component.html',
  styleUrls: ['./expense-management.component.scss']
})
export class ExpenseManagementComponent implements OnInit {
  activeTab = 1;

  listDataArr:any = []
  searchString:any = ""
  page = 1
  loader = true
  filterPanel: boolean = false
  filter:any =""
  constructor(public messageService : MessageService,private EMS:ExpenseManagementService,public formBuilder: FormBuilder) { }
  filterForm = this.formBuilder.group({
    company: '',
    bu: '',
    department: '',
    designation: '',
    grade: '',
    emp_status: '',
    emp_type: '',
    branch: '',
    repomanager: '',
    funmanager: '',
    sortby: ['All'],
    expense_date_from:'',
    expense_date_to:''
  });
  ngOnInit(): void {
    this.loadData()
  }

  listData(){
    this.page = 1; 
    let status = null
    if(this.activeTab == 1)
      status = null
    else if(this.activeTab == 2)
      status = "Accepted"
    else if(this.activeTab == 3)
      status = "Reimbursed"
    else if(this.activeTab == 4)
      status = "Pending for reimbursement via payroll"
    this.EMS.listreports(status,this.filter).subscribe((res:any)=>{
      this.listDataArr = res
      setTimeout(() => {
          this.loader = false;
      }, 500);
    });
  }
  loadData(){
    this.loader = true
    this.listData()
  }

  closeFilter(daata: any) {
    this.filterPanel = false;
    // this.filter = daata;
  }
  submitForm(data:any){
    this.page = 1; 
    this.searchString = ''
    this.loader = true;
    this.filter = data;
    this.loadData()
    this.filterPanel = false;
  }
  resetFilter(){
    this.searchString=''
    this.filter = ""
    this.loadData()
    this.filterPanel = false;
  }


}
