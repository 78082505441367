import { Component, Input, OnInit, Output, EventEmitter,  ViewChild  } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-manage-asset-inbox',
  templateUrl: './manage-asset-inbox.component.html',
  styleUrls: ['./manage-asset-inbox.component.scss']
})
export class ManageAssetInboxComponent implements OnInit {
  assignAssets      = false;

  @Input()requestitem:any;
  @Input()from:any
  assignAssetsData:any = [];
  assignAssetsLoader   = false;
  newOrOldEmployee     = 'Old';
  assignClicked        = false;
  assignAssetsIds:any  = [];

  page                 = 1;
  pageSize             = 20;
  searchString: any    = "";

  viewInventory        = false;
  currentId:any        = "";

  alertToggle          = false;
  alertMsg:any         = '';
  alertOrDelete        = 'Alert';
  deleteClicked        = false;

  unReturnedStatusArray: any = ['Missing'];
  pointerEventArray:any = [];

  finalAction:any     = '';

  searchlistdata:any  = [] ;
  searchkey           = this.messageService.searchdisplay('asset code/asset name');
  searchKeyword       = "";
  categoryId          = 0;
  subCategoryId       = 0;
  employeeId          = 0
  specificId:any;

  submitted           = false;


  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  // test end

  constructor(
    private inboxservice: InboxService,
    private fb: FormBuilder,
    public messageService: MessageService,
    public appservice: AppService,
    private notificationService: NotificationService
  ) { }

  form = this.fb.group({
    read_status      : [true,Validators.required],
    approved_status  : [false,Validators.required],
    comment          : ['', Validators.maxLength(500)],
  })

  @ViewChild(SearchbarComponent) child:any;

  ngOnInit(): void {
  setTimeout(() => {
    if(this.requestitem?.requests?.return_assets_inbox?.length > 0){
      for(let i=0;i<this.requestitem?.requests?.return_assets_inbox?.length;i++){

        this.pointerEventArray?.push({['loader'+i]: false});
        if(this.requestitem?.requests?.return_assets_inbox[i]['status'] == 'Unreturned'){
          this.addAssetArray(this.requestitem?.requests?.return_assets_inbox[i]['id']);
          (<FormArray>this.unReturnedForm.get("assetArray"))?.controls?.[i]?.get('status')?.reset(this.requestitem?.requests?.return_assets_inbox[i]['unreturn_status']);
        }
      }
    }
  }, 1000);
  }

  get f() {return  this.form.controls; }

  refreshInbox(event: boolean){
    if(event){
      this.successEmitter.emit(event);
    }
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let dataArray: any = [];
    if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
        if (res) {
          Object.entries(dataArray).forEach(([key, value]) => {
            if (this.requestitem['id'] == id) {
              this.requestitem[key] = value;
            }
          })
        }
      })
    }
  }

  closeAssignAsset(){
    this.child.clearBar();
    this.searchKeyword       = '';
    this.assignAssets        = false;
  }

  getAssets(empId:any,catId:any,subId:any){

    this.assignAssetsIds     = [];
    this.assignAssetsLoader  = true;
    this.employeeId          = empId;
    this.categoryId          = catId;
    this.subCategoryId       = subId;

    this.assignAssets        = true;
    // this.searchKeyword       = '';
    this.assignAssetsData    = [];
     this.inboxservice.getAsset(this.employeeId,this.categoryId,this.subCategoryId,this.searchKeyword).subscribe((res: any = []) =>{
      this.assignAssetsData  = res?.data;
     });

     // Search
     this.inboxservice.assetSearchDropDown(this.employeeId,this.categoryId,this.subCategoryId).subscribe((res:any) => {
      if(res?.data?.length > 0){
        for(let i=0; i< res?.data?.length; i++){
          res.data[i]['name']   +=" ("+res?.data[i]['code']+")";
        }
      }
      this.searchlistdata = res?.data;
      this.assignAssetsLoader = false;
    });
  }

  searchresults(data:any){
    this.assignAssetsLoader = true;
    if(data == ""){
      this.searchKeyword     = "";
      this.assignAssetsData  = [];
      this.getAssets(this.employeeId,this.categoryId,this.subCategoryId);
    }else{
      this.inboxservice.getInventoryDetails(data).subscribe((res: any) => {
        this.searchKeyword  = res.name
        this.assignAssetsData = [res];
        this.assignAssetsLoader    = false;
      });
    }
  }

  searchAsset(searchKeyword:any){
    this.assignAssetsLoader    = true;
    this.assignAssetsData      = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.getAssets(this.employeeId,this.categoryId,this.subCategoryId);
    }
  }

  assignAssetToExistingEmployee(assetId:any){
    this.assignAssetsIds = [];
    this.assignAssetsIds?.push(assetId);
  }

  assignAssetToEmployee(){

    if(this.newOrOldEmployee == 'New'){
      this.assignAssetsIds = [];
      this.assignAssetsData?.forEach((item:any, value:any) => {
        if(item?.checked == true )
          this.assignAssetsIds?.push(item?.id)
      });
    }

    this.assignClicked    = true;
    this.inboxservice.assignAsset(this.requestitem?.id,{'assets': this.assignAssetsIds,'specific_id':this.specificId}).subscribe((res: any) => {
      this.assignClicked  = false;
      this.assignAssets   = false;
      this.getInboxData();
    },
    (err: any) =>{
      this.assignClicked  = false;
    });
  }

  checkall(event:any){
    var newList  = this.assignAssetsData;
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkAllSelected(){
    var newList  = this.assignAssetsData
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
          return item.checked;
      });
    }else{
      return false;
    }
  }

  assignAssetbuttonDiableFunction(){
    if(this.newOrOldEmployee == 'Old'){
      if (this.assignAssetsIds?.length == 0)
        return false;
      else
        return true;
    }else {
      if(this.assignAssetsData?.filter((obj :any) => obj.checked === true)?.length == 0)
        return false;
      else
        return true;
    }
  }

  undoAsset(id:any,status:any,remark:any,index:any,from:any){

    this.submitted = false;

    if(this.pointerEventArray > 0 && from == 'return'){
      this.pointerEventArray[index]['loader'+index] = true;
    }
    this.inboxservice.assetStatusUpdate(id,{'status': status,'remark': remark}).subscribe((res:any)=>{

      if(from == 'return' || this.alertOrDelete == 'Alert')
        this.notificationService.handleSuccessNotification("Updated successfully","Success");
      else if(this.alertOrDelete == 'Delete')
        this.notificationService.handleSuccessNotification("Deleted successfully","Success");

      this.getInboxData();
      this.alertToggle   = false;
      this.deleteClicked = false;
      if(this.pointerEventArray > 0 && from == 'return'){
        this.pointerEventArray[index]['loader'+index] = false;
      }
    })
  }

  getInboxData(){
    this.inboxservice.getSingleInboxData(this.requestitem['id']).subscribe((res:any)=>{
      this.requestitem['requests']= res['asset_inbox'][0];
    })
  }

  confirm(status:any){

    this.alertOrDelete = 'Alert';
    this.finalAction = status;
    var type         = 'Asset Request';
    var returnFlag   = false;
    var newFlag      = false;

    if (this.unReturnedForm.invalid) {
      this.submitted = true;
      return;
    }

    if(this.requestitem?.requests?.return_assets_inbox?.length > 0){
      for(let i = 0;i<this.requestitem?.requests?.return_assets_inbox?.length ;i++){
        if(this.requestitem?.requests?.return_assets_inbox[i].status == 'Pending'){
          returnFlag = true;
          this.notificationService.handleErrorNotification(type+" cannot be submitted in pending state.","Pending "+type);
          return;
        }
      }
    }

    if(this.requestitem?.requests?.new_assets_inbox?.length > 0 && this.finalAction =='Approved'){
      for(let i = 0;i<this.requestitem?.requests?.new_assets_inbox?.length ;i++){
        if(this.requestitem?.requests?.new_assets_inbox[i].status == 'Pending' ){
         newFlag = true;
         this.alertToggle = true;
         this.alertMsg = 'Are you sure you do not want to take any action on the pending asset?';
         return;
        }
      }
    }

    if(this.requestitem?.requests?.employee_type == 'NEW JOINEE' && this.requestitem?.requests?.new_assets_inbox?.length == 0 && this.finalAction =='Approved'){

      this.alertToggle = true;
      this.alertMsg = 'Are you sure you do not want to take any action on the pending asset?';
      return;
    }

    if((this.requestitem?.requests?.return_assets_inbox?.length > 0 && !returnFlag) || (this.requestitem?.requests?.new_assets_inbox?.length > 0) || (this.finalAction =='Rejected')){
      this.finalActionFunction();
    }
  }

  finalActionFunction(){

    if(this.alertOrDelete == 'Delete'){
      this.undoAsset(this.specificId,'Undo','','','new')
    }else{
      if(this.finalAction =='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }

      if(this.form['controls'].comment.valid){
        if(this.from=='behalf'){
          let data                = this.form.value
          data['request_id']      = this.requestitem?.request_data?.id
          this.successEmitter.emit(data);

        }
        else{
        this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(true);
            this.deleteClicked = false;
          }
        })
        }
      }
    }
  }

  // status section
  unReturnedForm : FormGroup = this.fb.group({
    assetArray  : this.fb.array([])
  });

  assetArrays() : FormArray {

    return this.unReturnedForm.get("assetArray") as FormArray
  }

  newAssetArray(id:any): FormGroup {

    return this.fb.group({
      "status"     : [null, [Validators.required]],
      "id" : id
    })
  }

  addAssetArray(id:any) {
    this.assetArrays().push(this.newAssetArray(id));
  }

  removeAssetArrays(id:any) {
    if(this.unReturnedForm.value.assetArray?.length > 0){
      for(let i=0;i<this.unReturnedForm.value.assetArray?.length;i++){
        if(Number((<FormArray>this.unReturnedForm.get("assetArray")).at(i).get('id')?.value) == Number(id) ){
          this.assetArrays().removeAt(i);
          return;
        }
      }
    }
  }

}
