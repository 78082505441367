import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-week-off-table',
  templateUrl: './week-off-table.component.html',
  styleUrls: ['./week-off-table.component.scss']
})
export class WeekOffTableComponent implements OnInit {

  @Input() formGp : any

  weekoffs:any = [
    {name:"Full Day",    value:"Full Day"},
    {name:"First Half",  value:"First Half"},
    {name:"Second Half", value:"Second Half"},
    {name:"None",        value: null },
  ];

  constructor(
    public messageService :  MessageService
  ) { }

  ngOnInit(): void {
  }

  get weekoff_mapping() {
    return this.formGp.get('weekoffdata') as FormArray;
  }

  validateForm(){

  }
}
