import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-invite-employee',
  templateUrl: './invite-employee.component.html',
  styleUrls: ['./invite-employee.component.scss']
})
export class InviteEmployeeComponent implements OnInit {

  menuData   : any = []
  menuList   : any

  constructor(
    public appService     : AppService,
    private router        : Router,
  ) { }

  ngOnInit(): void {
    this.getPermission()
  }

  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/invite-employees')
      this.router.navigate([this.menuData[0].link]);
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/invite-employees');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }

}

