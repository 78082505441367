import { Component, OnInit, ViewChild } from '@angular/core';
import { AssetPolicyService } from 'src/app/@core/services/asset-policy.service';
import { AppService } from 'src/app/app.global';
import { FormBuilder,Validators  } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { debounceTime, delay, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
@Component({
  selector: 'app-asset-category',
  templateUrl: './asset-category.component.html',
  styleUrls: ['./asset-category.component.scss']
})
export class AssetCategoryComponent implements OnInit {

  viewDetails                = false;
  editDetails                = false;

  activeToggle               = true;
  searchKeyword              = "";
  loader                     = false;
  assetCategoryList:any      = [];
  nodata                     = false;
  submitted                  = false;
  disable                    = false;
  header:any                 = '';
  loader1                    = false;
  assetCategoryViewData:any  = [];

  searchlistdata:any         = [];
  categorySearch:string      = this.messageService.searchdisplay('category');

  deleteToggle               = false;
  deleteClicked              = false;

  statusBtn:any              = "Active";

  isApi2                     = false;
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;

  private subjectKeyUp   = new Subject<any>();

  offset                 = 0;
  listlength             = 0;
  direction              = '';
  defaultlimit:any       = 20;
  categoryDetailList:any = [];
  infinityloader         = false;
  permissions:any        = [];

  constructor(
    private assetPolicyService: AssetPolicyService,
    public appService: AppService,
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    public existDataService:  ExistDatasService,
    public breakpointObserver: BreakpointObserver,
  ) { }

  assetCategoryForm = this.formBuilder.group({
      id   : 0,
      name : ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
  })

  ngOnInit(): void {

    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.showDataItems();

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.assetPolicyService.assetCategoryList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  get f() { return this.assetCategoryForm.controls; }

  showDataItems(){
    this.loader = true;
    this.assetPolicyService.assetCategoryList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res?.results?.length;
    if(this.offset == 0){
      this.assetCategoryList = [];
    }
    if (res?.results?.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata = false;
      this.categoryDetailList = res?.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.assetCategoryList?.push(this.categoryDetailList[i]);
    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  onSubmit(){
    this.submitted = true;
    if (this.assetCategoryForm.invalid ) {
          return;
    }
    this.disable = true;

    this.assetPolicyService.assetCategortCreateAndUpdate(Number(this.assetCategoryForm.value.id),{'name':this.assetCategoryForm.value.name,}).subscribe((res: any) => {
      this.offset         = 0;
      this.assetCategoryList      = [];
      this.showDataItems();
      this.editDetails    = false;

      this.assetCategoryForm.reset();
    },(err: any) => {this.disable = false});

    this.submitted        = false;
  }

  viewAssetCategory(id:any){
    if(this.permissions?.v){
      this.viewDetails           = true;
      this.loader1               = true;
      this.assetCategoryViewData = [];
      this.assetPolicyService.getSingleAssetCategory(id).subscribe((res: any) => {
      this.assetCategoryViewData = res;
        this.editDetails         = false;
        this.loader1             = false;
      })
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset         = 0;
      this.assetCategoryList      = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchCategory(searchKeyword:any){
    this.loader                 = true;
    this.isLoading              = true
    this.offset                 = 0;
    this.assetCategoryList      = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  editCategory(id:any){
    this.loader1        = true;
    this.viewDetails    = false;
    this.editDetails    = true;
    this.disable        = false;
    this.header         = 'Edit'
    this.assetPolicyService.getSingleAssetCategory(id).subscribe((res:any)=>{
      this.assetCategoryForm.reset({ id: res.id, name:res.name});
      this.loader1      = false;
    })
  }

  deleteAssetCategoryfn(id:any){
    this.deleteToggle       = true;
    this.assetCategoryForm.value.id = id;
  }

  deleteAssetCategory(){
    this.deleteClicked = true
    this.assetPolicyService.deleteAssetCategory(this.assetCategoryForm.value.id,{'is_active' : false}).subscribe((res: any) => {
      this.editDetails    = false;
      this.offset         = 0;
      this.assetCategoryList  = [];
      this.showDataItems();
      this.deleteToggle   = false;
      this.deleteClicked  = false
    },
    (err: any) => {
      this.deleteToggle   = false
      this.editDetails    = false
      this.deleteClicked  = false
    });
  }

  filterItemList(value:any){
    this.loader       = true;
    this.activeToggle = value;
    this.assetCategoryList    = [];
    this.offset       = 0;
    this.child.clearBar();
  }

  addAssetCategory(){
    this.editDetails = true;
    this.disable     = false;
    this.header      = 'Add';
    this.assetCategoryForm.reset({ id: 0, name:''});
    this.child.clearBar();
  }

  existService(){
    this.isApi2 = true;
    let value;
    if(this.header == 'Add'){
      value ={
        "page":"assetcategory",
        "field":"name",
        "value": this.f.name?.value
      }
    }else{
      value ={
        "id": this.assetCategoryForm.value?.id,
        "page":"assetcategory",
        "field":"name",
        "value": this.f.name?.value
      }
    }

    this.existDataService.getSpecificName(value).subscribe((res:any)=>{

      this.loader = false

      if(res?.body?.data ==='invalid'){
        this.f.name?.setErrors({
          'exist' : true
        })
      }

      this.isApi2 = false;
    },
    (error: any) => {
      this.isApi2 = false;
    })


  }

  addheadercontent(){
    this.header      = 'Add';
    this.editDetails = true;
    this.child.clearBar();
  }

}
