import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { PayGroupService } from 'src/app/@core/services/pay-group.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-pay-group-setup',
  templateUrl: './pay-group-setup.component.html',
  styleUrls: ['./pay-group-setup.component.scss']
})
export class PayGroupSetupComponent implements OnInit,AfterViewChecked {
  activeTab           = 1;
  paycomponentlist    = [];
  appointmentlist     = []
  revisionlist        = []
  Action:any          = 'Add';
  id: number          = 0;
  disabled            = false;
  loader              = false;
  editflag : boolean = false
  myForm: FormGroup = this.fb.group({
    id: 0,
    define: this.fb.group({
      pay_group_name:  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      pay_slip_id: [null, [Validators.required]],
      tax_slip_id: [null, [Validators.required]],
      description: ['', [Validators.required,Validators.maxLength(500)]],
      appointment_id: [null,[Validators.required]],
      revision_id: [null,[Validators.required]]
    }),
    mapcomponent:this.fb.group({
      paycomponents: this.fb.array([],[Validators.required]),
      paycomponentsname:this.fb.array([],[Validators.required]),
    }),
    formula:this.fb.group({
      componentformula: this.fb.array([],[Validators.required]),
    }),
    assignrights:this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })
  });
  loaderPC = false
 editFormulaArray:any = []
  constructor(
    public fb:FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private pcs:PayComponentService,
    private pgs:PayGroupService,
    private cdRef:ChangeDetectorRef

  ) { }
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.Action = "Add";
      if( !isNaN(params['id'])){
        this.Action = "Edit";
        this.editflag = true;
        this.id = +params['id'];
        this.editForm();
      }
    })
    this.getPayComponents();
  }

  editForm(){
    this.loader = true;
    this.pgs.getPayGroup(this.id).subscribe((res: any) => {

      this.setEditForm(res);
      return
    });

  }

  setEditForm(res:any){

    let paycomponentId = [];
    let paycomponentName = [];
    let setformulaarray: { componentid: any; componentname: any; formulavalue: string; formulaArray: any; fixedvalue: string; condition: any; }[] = [];
    let formulastring = "";
    let fixedstring = "";
    var resultlength = res.paycomponent.length - 2;
    this.loader = true;
    for(let key of res.paycomponent){
        if(key.pay_component != null){
          paycomponentId.push(key.pay_component.toString());
          paycomponentName.push(key.pay_component_name);

          const formArray: FormArray = this.mapcomponentForm.get('paycomponents') as FormArray;
          const formArray1: FormArray = this.mapcomponentForm.get('paycomponentsname') as FormArray;
          formArray.push(new FormControl(key.pay_component));
          formArray1.push(new FormControl(key.pay_component_name))
          // this.pcs.getSinglePayComponent(key.pay_component).subscribe((res1: any) => {
            if(key.formula_type == true){
              formulastring = key.formula;
              fixedstring = ""
            }else{
              fixedstring = key.formula;
              formulastring = ""
            }
            // formArray1.push(new FormControl(res1.name))
            this.formula().push(this.newformula(key.pay_component,key.pay_component_name));
            // this.formula().push(this.fb.group({
            //   componentid:key.pay_component,
            //   componentname: key.pay_component_name,
            //   formulavalue: formulastring,
            //   formulaArray:[JSON.parse(key.formula_array)],
            //   fixedvalue: [fixedstring, [Validators.maxLength(10),digitdecimalValidator(),]],
            //   condition: [key.formula_type, [Validators.required]],
            // }));
            this.editFormulaArray.push(key.formula_array)
            setformulaarray.push({
              "componentid":key.pay_component,
              "componentname": key.pay_component_name,
              "formulavalue": formulastring,
              "formulaArray":JSON.parse(key.formula_array),
              "fixedvalue": fixedstring,
              "condition": key.formula_type,
            })
          // });
        }else{
          setTimeout(()=>{                           // <<<---using ()=> syntax
            if(key.formula_type == true){
              formulastring = key.formula;
              fixedstring = ""
            }else{
              fixedstring = key.formula;
              formulastring = ""
            }
            this.formula().push(this.fb.group({
              componentid:resultlength++,
              componentname: key.contribution,
              formulavalue: formulastring,
              fixedvalue: [fixedstring, [Validators.maxLength(10),digitdecimalValidator(),]],
              condition: [key.formula_type, [Validators.required]],
            }));
            // setformulaarray.push({
            //   "componentid":resultlength++,
            //   "componentname": key.contribution,
            //   "formulavalue": formulastring,
            //   "fixedvalue": [fixedstring, [Validators.maxLength(10),digitdecimalValidator(),]],
            //   "condition": [key.formula_type, [Validators.required]],
            // }
            // )
        }, 1000);

        }
    }
    let assignees:any = [];
    let applicableto;
    for(let key of res.assignments){
      let appkey = key.unit_type.toLowerCase();
      if(appkey == "business unit"){
        appkey = "businessunit";
        key.unit_type ="BU"
      }
      assignees.push(key[appkey.toString()]);
      applicableto = key.unit_type
    }

    this.myForm.reset(
      {
        id: res.id,
        define: {
          pay_group_name:  res.name,
          pay_slip_id: Number(res.pay_slip),
          tax_slip_id: Number(res.tax_slip),
          description: res.description,
          appointment_id: res.appointment_letter?.letter_template,
          revision_id: res.salary_revision_letter?.letter_template
        },
        mapcomponent:{
          paycomponents:paycomponentId,
          paycomponentsname:paycomponentName
        },
        formula:{
          componentformula:setformulaarray
        },
        assignrights:{
          applicableto:applicableto,
          assignees:assignees
        }
    });
    if(res.is_active == false){
      this.Action = "View";
      // this.myForm.disable();
      this.disabled = true;
    }
    this.loader = false;
  }

  getPayComponents(){
    this.loaderPC = true
    this.pcs.getPayComponentList(true,true).subscribe((res: any) => {
        if (res.length > 0) {
          this.paycomponentlist = res;
        }
        this.loaderPC = false
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].define.invalid) {
        changeEvent.preventDefault();
      }
    }
    if (changeEvent.nextId === 3) {
      if (this.myForm['controls'].define.invalid || this.myForm['controls'].mapcomponent.invalid) {
        changeEvent.preventDefault();
      }
    }
    if (changeEvent.nextId === 4) {
      if (this.myForm['controls'].define.invalid || this.myForm['controls'].mapcomponent.invalid || this.checkcreateFormulaValid() || this.mapcomponentForm.value.paycomponents.length !=this.formulaForm.value.componentformula.length) {
        changeEvent.preventDefault();
      }
    }
  }

  submitOne(){
    this.activeTab = 2;
  }

  submitTwo(){
    this.activeTab = 3;
  }

  submitThree(){
    this.activeTab = 4;
  }

  get defineForm() {
    return this.myForm.get('define') as FormGroup;
  }

  get mapcomponentForm(){
    return this.myForm.get('mapcomponent') as FormGroup;
  }

  get formulaForm(){
    return this.myForm.get('formula') as FormGroup;
  }

  get assignrightsForm(){
    return this.myForm.get('assignrights') as FormGroup;
  }

  submitForm(){
    let dataArray:any = [];
    var paycomponentarray:any = [];
     
    this.formulaForm.value.componentformula.forEach((key:any, index:any) => {
    
    // for(const [index, key]  of this.formulaForm.value.componentformula){
      let contribution = null;
      let fromulatypevalue ="";
      if(key.condition == true){
        if(key.formulavalue.constructor === Array) {
          fromulatypevalue = JSON.stringify(key.formulavalue);
        }else
        fromulatypevalue = key.formulavalue;

      }else if(key.condition == false){
        fromulatypevalue = key.fixedvalue;
      }

      // if(key.componentname == "PF"){
      //   contribution = key.componentname;
      //   key.componentid = "";
      // }
      // if(key.componentname == "ESI"){
      //   contribution = key.componentname;
      //   key.componentid = "";
      // }

      paycomponentarray.push({
          "formula_type":key.condition,
          "formula":fromulatypevalue,
          "formula_array":key.formulaArray?JSON.stringify(key.formulaArray):this.editFormulaArray[index] ,
          "pay_component":(key.componentid!="")?key.componentid:"",
          "contribution":contribution
      });
    })
    let assignmentarray = [];
    for(let key of this.assignrightsForm.value.assignees){
      var applicable = this.assignrightsForm.controls.applicableto.value;
      var applicableStr = applicable;
      var applicableStrkey = applicable;

      if(applicable == "BU"){
        applicableStr = "Business Unit";
        applicableStrkey = "businessunit";
      }

        assignmentarray.push({
          "unit_type":applicableStr,
          [applicableStrkey.toLowerCase()] :key,
      });
    }
    dataArray = {
      "name": this.defineForm.controls.pay_group_name.value,
      "pay_slip": this.defineForm.controls.pay_slip_id.value,
      "tax_slip" : this.defineForm.controls.tax_slip_id.value,
      "description": this.defineForm.controls.description.value,
      "appointment_letter": this.defineForm.controls.appointment_id.value,
      "salary_revision_letter": this.defineForm.controls.revision_id.value,
      "paycomponent": paycomponentarray,
      "assignments": assignmentarray
   };
    if(this.id == 0){
      this.pgs.createPayGroup(dataArray).subscribe((res: any) => {
          this.router.navigate(['pay-group']);
    });
    } else {
      dataArray['id']=this.id;
      this.pgs.updatePayGroup(this.id,dataArray).subscribe((res: any) => {
          this.router.navigate(['pay-group']);
      });
    }
  }

  formula() : FormArray {
    return this.formulaForm.get("componentformula") as FormArray
  }

  newformula(id:any,name:any): FormGroup {
    return this.fb.group({
      componentid:id,
      componentname: name,
      formulavalue: [''],
      fixedvalue: ["", [Validators.maxLength(10),digitdecimalValidator(),]],
      condition: [true, [Validators.required]],
    })
  }


  checkcreateFormulaValid(){
    let flag = 0;
    for(let k=0;k<this.formulaForm.value.componentformula.length;k++){
      if(this.formulaForm.value.componentformula[k].condition == true && (this.formulaForm.value.componentformula[k].formulavalue == "" || this.formulaForm.value.componentformula[k].formulavalue == " ")){
        flag++;
      }else if(this.formulaForm.value.componentformula[k].condition == false && this.formulaForm.value.componentformula[k].fixedvalue == "" ){
        flag++;
      }
      else if(this.formulaForm.value.componentformula[k].condition == false && this.formulaForm.value.componentformula[k].fixedvalue != "" ){
        if(this.formulaForm.invalid){
          flag++;
        }
      }
    }
    if(flag != 0){
      return true;
    }else
      return false;
  }

}
