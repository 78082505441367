<div class=" config-layout" *ngIf="permissions">
    <header class=" config-header">
        <h5 class="m-0">Financial year {{getCurrentFinancialYear()}} ( {{challanPeriod == 'Q1'? '1st Quarter':
            challanPeriod == 'Q2'? '2nd Quarter' : challanPeriod == 'Q3'? '3rd Quarter': '4th Quarter'}} )</h5>
        <button class="btn-reset text-light-600" routerLink="/challan/add-challan/{{company}}/{{financialyear}}"><i
                class="icon-close-lg fs-16"></i></button>
    </header>
    <main class=" config-container">
        <form class="">
            <div class="container-fluid px-32 py-24 form-container">
                <div class="row row-24">
                    <div class="col-12">
                        <p class="text-uppercase fw-500 mb-0">CHALLAN DETAILS</p>
                    </div>
                    <app-table-loader *ngIf="loader"></app-table-loader>
                    <div class="col-12" *ngIf="!loader">
                        <div class="card card-c2 overflow-hidden">
                            <div class="table-responsive">
                                <table class="table table-striped form-table table-sm">
                                    <thead>
                                        <tr>

                                            <th class="fw-600 col-3" scope="col">MONTH</th>
                                            <th class="fw-600 col-2" scope="col">SALARY STATUS</th>
                                            <th class="fw-600 col-2" scope="col">TAX DEDUCTED</th>
                                            <th class="fw-600 col-2" scope="col">CHALLAN DUE</th>
                                            <th class="fw-600 col-2" scope="col">CHALLAN PAYMENTS</th>
                                            <th class="fw-600 col-2" scope="col">PREVIOUS CHALLAN ADJUSTMENTS</th>


                                            <th class="fw-600 col-2" scope="col">EMP’S MAPPED </th>
                                            <th class="fw-600 col-2" scope="col">BALANCE DUE </th>
                                            <th class="fw-600 col-2" scope="col"> ACTIONS</th>

                                        </tr>
                                    </thead>
                                    <tbody class="cell-16">
                                        <tr *ngFor="let challan of challanDetails let i = index">
                                            <td class=" col-3" scope="col">{{challan?.pay_month}}</td>
                                            <td class=" col-2" scope="col">
                                                <span
                                                    class="badge py-1 px-3 {{challan?.run_payroll_status == 'Processed' ? 'badge-success' : 'badge-danger'}}">
                                                    {{challan?.run_payroll_status}}
                                                </span>
                                            </td>
                                            <td class=" col-2" scope="col">{{challan?.tax_deduct_amount == '' ? '-' : challan?.tax_deduct_amount | currency:
                                                currency}}</td>
                                            <td class=" col-2" scope="col">{{challan?.challan_due == '' ? '-' : challan?.challan_due | currency: currency}}
                                            </td>
                                            <td class=" col-2" scope="col">{{challan?.challan_payment == '' ? '-' : challan?.challan_payment | currency:
                                                currency}}</td>
                                            <td class=" col-2" scope="col">{{challan?.challan_adjustment == '' ? '-' : challan?.challan_adjustment | currency:
                                                currency}}</td>

                                            <td class="col-2" scope="col">
                                                {{challan?.mapped_challan_emp}}/{{challan?.employee_count}}</td>
                                            <td class="col-2" scope="col">{{challan?.balance_due == '' ? '-' : challan?.balance_due | currency: currency}}
                                            </td>
                                            <td class="col-2" scope="col">
                                                <!-- <span *ngIf="challan?.run_payroll_status == 'Processed'"
                                                class="link-primary1 d-flex align-items-center text-nowrap" routerLink="/challan-update">Manage</span> -->
                                                <ng-container *ngIf="challan?.run_payroll_status == 'Processed'">
                                                    <span *ngIf="challan?.challan_payment == 0 && permissions?.a && challan?.challan_adjustment == 0"
                                                        class="link-primary1 d-flex align-items-center text-nowrap"
                                                        routerLink="/challan/challan-setup/{{challan?.id}}/{{challan?.pay_month}}/{{company}}/{{currency}}/{{financialyear}}">Manage</span>

                                                    <span *ngIf="challan?.challan_payment == 0 && !permissions?.a"
                                                        class="link-primary1 d-flex align-items-center text-nowrap">Manage</span>

                                                    <span *ngIf="challan?.challan_payment > 0 || challan?.challan_adjustment > 0"
                                                        class="link-primary1 d-flex align-items-center text-nowrap"
                                                        routerLink="/challan/challan-update/{{challan?.id}}/{{challan?.pay_month}}/{{company}}/{{currency}}/{{financialyear}}">Manage</span>
                                                </ng-container>
                                            </td>

                                        </tr>
                                        <!-- <tr>
                                            <td class=" col-3" scope="col">February</td>
                                            <td class=" col-2" scope="col">
                                                <span
                                                    class="badge badge-success py-1 px-3">
                                                    Processed
                                                </span>
                                            </td>
                                            <td class=" col-2" scope="col" >25,000</td>
                                            <td class=" col-2" scope="col">23,000</td>
                                            <td class=" col-2" scope="col">12,000</td>

                                            <td class="col-2" scope="col">10/100</td>
                                            <td class="col-2" scope="col">13000</td>
                                            <td class="col-2" scope="col">
                                                <span
                                                class="link-primary1 d-flex align-items-center text-nowrap" routerLink="/challan-update">Manage</span>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td class=" col-3" scope="col">March</td>
                                            <td class=" col-2" scope="col">
                                                <span
                                                    class="badge badge-danger py-1 px-3">
                                                    Unprocessed
                                                </span>
                                            </td>
                                            <td class=" col-2" scope="col" >25,000</td>
                                            <td class=" col-2" scope="col">23,000</td>
                                            <td class=" col-2" scope="col">12,000</td>

                                            <td class="col-2" scope="col">10/100</td>
                                            <td class="col-2" scope="col">13000</td>
                                            <td class="col-2" scope="col">
                                                <span
                                                class="link-primary1 d-flex align-items-center text-nowrap" routerLink="/challan-update">Manage</span>
                                            </td>

                                        </tr> -->


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="excessAmountList?.length!=0">
                    <div class="col-12">
                        <p class="text-uppercase fw-500 mb-0">ADJUST EXCESS AMOUNT
                        </p>
                    </div>
                    <app-table-loader *ngIf="loader"></app-table-loader>
                    <div class="col-12" *ngIf="!loader">
                        <div class="card card-c2 overflow-hidden">
                            <div class="table-responsive">
                                <table class="table table-striped form-table table-sm">
                                    <thead>
                                        <tr>

                                            <th class="fw-600 col-3" scope="col">CHALLAN NO.</th>
                                            <th class="fw-600 col-2" scope="col">CHALLAN DATE</th>
                                            <th class="fw-600 col-2" scope="col">CHALLAN AMOUNT</th>
                                            <th class="fw-600 col-2" scope="col">INCOME TAX ALLOCATED</th>
                                            <th class="fw-600 col-2" scope="col">EXCESS AMOUNT</th>
                                            <th class="fw-600 col-2" scope="col"> ACTIONS</th>

                                        </tr>
                                    </thead>
                                    <tbody class="cell-16">
                                        <!-- <tr *ngIf="excessAmountList?.length<=0">
                                            <td colspan="8">
                                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                    <span class="fw-500 fs-14">No data found</span>
                                                  </div>
                                            </td>
                                          </tr> -->
                                        <tr *ngFor="let item of excessAmountList; let i = index">
                                            <td class=" col-2" scope="col">{{item?.challn_number}}</td>
                                            <td class=" col-2" scope="col">
                                                {{this.appservice.dateFormatDisplay(item?.challn_date)}}
                                            </td>
                                            <td class=" col-2" scope="col">{{item?.challan_amount | currency:
                                                currency}}</td>
                                            <td class=" col-2" scope="col">{{item?.income_tax_allocated | currency:
                                                currency}}</td>
                                            <td class=" col-2" scope="col">{{item?.excess_amount | currency:
                                                currency}}</td>
                                            <td class="col-2" scope="col">
                                                <span class="link-primary1 d-flex align-items-center text-nowrap"
                                                    (click)="challanDueFlag=true;getChallanDueList(item?.id,item?.excess_amount)">Adjust
                                                    Excess Amount</span>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-container>
                </div>

            </div>

        </form>
    </main>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:58.0625rem;"
    [class.side-pane-active]='challanDueFlag === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Adjust Excess Amount</h5>
            <a class="toggle-panel" (click)="challanDueFlag = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;" >

            <!-- <ng-container *ngFor="let items of challanDueList let i=index"> -->

            <div class="container-fluid p-32">
            <app-c1-loader *ngIf="challanDueLoader"></app-c1-loader>

              <div class="row pt-5 flex-center text-center" *ngIf="challanDueList?.length == 0 && !challanDueLoader">
                  <app-nodata-view
                    [noDataText]="'NO DATA'"
                    [hasPermission]="false"
                  >
                  </app-nodata-view>
              </div>

            <ng-container *ngIf="challanDueList?.length > 0 && !challanDueLoader">
            <ng-container *ngFor="let items of challanDueList let i=index">
                <div class="row row-24 mb-64">
                    <div class="col-12">
                        <div class="card card-c2 py-16 px-24">
                            <div class="row row-24">
                                <div class="col-12">
                                    <div class="title">TAX LIABILITY FOR {{items?.pay_month}} {{financialyear}}</div>
                                </div>
                                <div class="col-12">
                                    <div class="card card-c2">

                                        <div class="table-responsive radius-4">
                                            <table
                                                class="table sticky-header td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-sm form-table">
                                                <thead>
                                                    <tr>
                                                        <th class="fw-600 text-uppercase" scope="col">TAX DEDUCTED</th>
                                                        <th class="fw-600 text-uppercase" scope="col">CHALLAN DUE</th>
                                                        <th class="fw-600 text-uppercase" scope="col">CHALLAN PAYMENTS
                                                        </th>
                                                        <th class="fw-600 text-uppercase" scope="col">PREVIOUS CHALLAN
                                                            ADJUSTMENT
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody class="cell-16 ">
                                                    <tr>
                                                        <td>{{items?.tax_deduct_amount | currency:
                                                            currency}}</td>
                                                        <td>{{items?.challan_due | currency:
                                                            currency}}</td>
                                                        <td>{{items?.challan_payment | currency:
                                                            currency}}</td>
                                                        <td>{{items?.challan_adjustment | currency:
                                                            currency}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary ms-auto btn-sm" (click)="submitExcessAmount(items?.id,items?.pay_month,items?.tax_deduct_amount
                                    )">ADJUST EXCESS AMOUNT</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>
            </ng-container>

            </div>
        <!-- </ng-container> -->
        </div>
    </div>
</div>
