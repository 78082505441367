import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {  AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-work-from-home-report',
  templateUrl: './work-from-home-report.component.html',
  styleUrls: ['./work-from-home-report.component.scss']
})
export class WorkFromHomeReportComponent implements OnInit {

  constructor(public attendanceReportService:AttendanceReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService,public changeDetectorRef: ChangeDetectorRef) { }

    @Input() companyDD: any;
    @Input() gradeDD: any;
    @Input() BranchDD: any;
    @Input() buDD: any;
    @Input() DepDD: any;
    @Input() DesignationDD: any;
    @Input() checkListElements: any;
    @Input() disabled: any;
    @Input() empstatusDD: any;
    @Input() emptypeDD: any;
    @Input() employeeDropdown: any;
    @Input() callemp : any;
    // Above needs to be cleared
    @Input() workFromHomeForm : any;
    @Input() salFilter: any;
    @Input() viewDetail: any;
    @Input() closeControl: any;
    @Input() empstatArr: any;
    @Input() tempArr: any;
    @Input() submitted: any;
    @Input()requestStatus:any;
    @Input()Years : any;
    @Input() pageNumber : any
    @Input() lastPage :any
    @Input() ordering :any
    @Input() ouFilterForm: any

    @ViewChild('scrollElement') scrollElement!: ElementRef;

    attendListOfMonth:string  = "listOfMonth";
    monthValue : any
    selectedYear = new Date().getFullYear();
    year_placeholder = 'Select year'
    month_placeholder = 'Select month'

    @Input() resDataFilter:any;

    get f()  { return this.workFromHomeForm.controls; }

    @Output() filterPanel                   = new EventEmitter();
    @Output() callMonthlyReport             = new EventEmitter();
    @Output() submitDone                    = new EventEmitter();
    @Output() resetFilter                   = new EventEmitter();

  ngOnInit(): void {
  }

  filterFormData(use:any){
    if(this.workFromHomeForm.controls.attendance_month.value == true){
      this.workFromHomeForm.get('attendance_month')?.setValue(this.workFromHomeForm.controls.specific_year.value+'-'+this.monthValue)
    }
    else{
      this.workFromHomeForm.get('attendance_month')?.setValue(this.workFromHomeForm.controls.attendance_month.value)
    }
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let attendance_month    = this.workFromHomeForm.controls.attendance_month.value==null?'':this.workFromHomeForm.controls.attendance_month.value;
    let request_status    = this.workFromHomeForm.controls.request_status.value==''?JSON.stringify([]):JSON.stringify(this.workFromHomeForm.controls.request_status.value);
    if(use == 'filter'){
      return `attendance_month=${attendance_month}&request_status=${request_status}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'attendance_month':this.workFromHomeForm.controls.attendance_month.value,'request_status':this.workFromHomeForm.controls.request_status.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

   // Emiting the value of radio to parent
   radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthtype:any,yeartype:any){
    this.changeDetectorRef.detectChanges();
    if(e.target.value == 'on' ){
     this.workFromHomeForm?.get(contrlName)?.setValue(true);
    }
    else{
     this.workFromHomeForm?.get(contrlName)?.setValue(val);
     this.workFromHomeForm?.get(monthtype)?.setValue(null);
     this.workFromHomeForm?.get(yeartype)?.setValue(null);

    }
  }

  //function for get month no from month name
  attendancemonthChangeFn(event:any){
    // this.monthValue = (new Date(Date.parse(event +" 1, "+this.selectedYear+"")).getMonth()+1);
    var date = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    this.monthValue = String(date.getMonth() + 1).padStart(2, '0');
  }

  applyBtn : boolean = false

  submitworkFromHomeForm(){
    this.submitted      = true;
    if (this.workFromHomeForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }

    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.attendanceReportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail           = false;
    this.applyBtn = false
  }


  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
      let allSelect = (items: any[]) => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
  }

  clearcommonForm(item:any,index:any){
   if(item == "requestStat"){
      let daata = this.workFromHomeForm.value.request_status;
      daata.splice(index, 1);
      this.workFromHomeForm.patchValue({
        request_status: daata
      });
    }
  }

    resetFilterFunction(){
      if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.resetFilter.emit(true)
        }else{
          this.resetFilterControllers('reset');
        }
      }
      this.setRequired(false)
    }

    resetFilterControllers(data:any){
      this.viewDetail = false;
      this.filterPanel.emit(this.viewDetail)
      if(data == 'reset'){
        this.ouFilterForm.get('company')?.reset('');
        this.ouFilterForm.get('department')?.reset('');
        this.ouFilterForm.get('bu')?.reset('');
        this.ouFilterForm.get('designation')?.reset('');
        this.ouFilterForm.get('grade')?.reset('');
        this.ouFilterForm.get('branch')?.reset('');
        this.ouFilterForm.get('employee_list')?.reset('');
        this.ouFilterForm.get('employment_status')?.reset('');
        this.ouFilterForm.get('employee_type')?.reset('');
        this.workFromHomeForm.get('attendance_month')?.reset('this_month');
        this.workFromHomeForm.get('request_status')?.reset('');
        this.workFromHomeForm.get('specific_month')?.reset(null);
        this.workFromHomeForm.get('specific_year')?.reset(null);
      }
      this.callMonthlyReport.emit({
        reportName : 'Work From Home Request'
      })
    }

  // Tags closing
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }
    this.ouFilterForm.get(this.closeControl)?.setValue('');
    this.submitworkFromHomeForm();
  }

  setRequired(val:any){
    var arr = ['specific_month','specific_year']
    if(val){
      arr.forEach((item:any)=>{
          this.workFromHomeForm.get(item)?.setValidators([Validators.required]);
          this.workFromHomeForm.get(item)?.updateValueAndValidity();
        })
      }else{
        arr.forEach((item:any)=>{
          this.workFromHomeForm.get(item)?.clearValidators()
          this.workFromHomeForm.get(item)?.updateValueAndValidity();
        })
    }
  }

  resetPage(){
    this.applyBtn = true
    this.attendanceReportService.calculateOffset(0)
    this.changeDetectorRef.detectChanges()
  }

    getData(){
      const data = this.filterFormData('')
      const emptyKeys = Object.keys(data)
      .filter((key:any) => (key !== 'attendance_month' && key !== 'request_status') && this.isValueEmpty(data[key]));
      return emptyKeys?.length ==  Object.keys(data)?.length-2 ? "" : data
    }

    isValueEmpty(value:any) {
      return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
    }

    stringifyCont(control: any): string {
      return control === '' ? JSON.stringify([]) : JSON.stringify(control);
    }

    scrollToFirstChild() {
      const scrollEl = this.scrollElement.nativeElement;
      const firstElement = scrollEl.querySelector('.is-invalid');
      if (firstElement) {
        const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
        scrollEl.scrollTop = scrollPosition;
      }
    }
}
