import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateService } from 'src/app/@core/services/template.service';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit {

  constructor(
    private appService: AppService
  ) { }
  @Input() previewall:any;
  @Output() confirmbuttonclick = new EventEmitter();
  ngOnInit(): void {
    
  }
  permissions: any=[]
  postdata(){
   this.confirmbuttonclick.emit(this.previewall);
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
