import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataacessService } from 'src/app/@core/services/dataaccess.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-data-access',
  templateUrl: './data-access.component.html',
  styleUrls: ['./data-access.component.scss']
})
export class DataAccessComponent implements OnInit {
  loader                        = true;
  activeToggle                  = true;
  dataaccessList:any            = [];
  nodata                        = false;
  searchlistdata:any            = [];
  dataaccesssearch:string       = this.messageService.searchdisplay('data access');
  viewaccesssData:any = [];
  showSidePanel =false;
  loader1                       = false;
  /********INFINITY SCROLL VARIABLES  ****/
  offset                        = 0;
  listlength                    = 0;
  direction                     = '';
  defaultlimit:any              = 20;
  dataaccessDetailsList         = [];
  infinityloader                = false;
  searchKeyword="";
  deleteToggle                  = false;
  dataAccessResId:any;
  permissions:any = [];
  statusBtn:any = "Active";
  companyData:any;
  isLoading = false
  searchData : Subject<void> = new Subject();

  @ViewChild(SearchbarComponent) child:any;
  constructor( private dataaccessService:DataacessService,public appService:AppService,public messageService : MessageService,
    public breakpointObserver: BreakpointObserver,public router: Router) { }

  ngOnInit(): void {
    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.showDataaccessItems();

    this.searchData.pipe(delay(1000),
    switchMap(()=> this.dataaccessService.getDataaccessList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  showDataaccessItems(){
    this.loader = true;
    this.dataaccessService.getDataaccessList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
      this.arrangeApiData(res)
    });
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res.body.results.length;
    if(this.offset ==0){
      this.dataaccessList = [];
    }
    this.infinityloader = false;
    if (res.body.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata = false;
      this.dataaccessDetailsList = res.body.results;
    }
    this.addItems(this.listlength);
    this.loader = false;
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.dataaccessList.push(this.dataaccessDetailsList[i]);
    }
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataaccessItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /************ All, Active or Delete filter *****************/
  selectItemList(value:any){
    this.loader = true;
    this.activeToggle = value;
    this.offset = 0;
    this.dataaccessList = [];
    this.child.clearBar();
  }
  /************ Search Dropdown *****************/
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.dataaccessList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }


  deleteDataAccess(daid:any){
    this.dataAccessResId  = daid;
    this.deleteToggle  = true;
  }

  deleteContent(dataAccessId:any){
    this.dataaccessService.dataAccessDelete(dataAccessId).subscribe((res: any) => {
      this.dataaccessList = [];
      this.offset = 0;
      this.showDataaccessItems();
      this.deleteToggle = false;
    });
  }

  editHeaderContent(id:number){
    this.router.navigate(['/data-access/setup-data-access/'+id]);
  }
  viewdataaccess(id:any){
    if(this.permissions.v){
      this.showSidePanel = true;
      this.loader1       = true;
      this.dataaccessService.getdataview(id).subscribe((res: any) => {
        this.viewaccesssData = res.body;
        this.companyData = this.viewaccesssData.employee_rights;
        this.loader1         = false;
        // console.log(this.viewaccesssData);
      })
    }
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  searchdataaccess(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.dataaccessList = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/data-access/setup-data-access/']);
  }
}
