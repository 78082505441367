import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InboxService } from 'src/app/@core/services/inbox.service';

@Component({
  selector: 'app-fnf-comp-off-approval',
  templateUrl: './fnf-comp-off-approval.component.html',
  styleUrls: ['./fnf-comp-off-approval.component.scss']
})
export class FnfCompOffApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() CompOff:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  showTrend = false;
  pageSize = 20;
  page = 1
  viewDetails = false;
  viewData:any = [];
  today =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  btndisable = false
  delArray : any = []
  timeOffTrend = false;

  barchart:any               = [];
  barChartXAxisLabels:any    = [];
  barChartYAxisLabel:any;
  barchartLoader             = false;

  // For year dropdown in comp off request trend
  currentYear =  new Date().getFullYear();
  selectedYearDisp = String(this.currentYear)
  selectedYear = this.currentYear
  yearList: any = [];
  multi: any = [];
  
 

  onSelect(event:any) {
  }

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  constructor(public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,public appservice: AppService,private datePipe:DatePipe,private notificationService: NotificationService,private inboxservice: InboxService) { }

  ngOnInit(): void {
    
  }

  timegraph(){
    this.yearList = []
    this.yearListCreate();
    this.getCompOffTrendDetails(this.currentYear);
  }

  
  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })


  get f() {return  this.form.controls; }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }

  getCompOffTrendDetails(event:any){
    this.selectedYearDisp = String(event)
    this.selectedYear     = event
    this.barchartLoader   = true;
    if(this.empId != null && this.empId != '' && this.empId != undefined){
      this.inboxservice.getCompOffTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.multi = res?.data;
          if(this.multi?.length > 0)
            this.barChartDataConstruction(this.multi);
        }
      })
    }
  }

  // Bar chart
  barChartDataConstruction(data:any){
    let approved:any         = [];
    let pending:any          = [];
    let declined:any         = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Days';
    this.barchart            = [];
    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Approved"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else{
            declined.push(item1?.value)
          }
      });
    });

    this.barchart.push({label: "Approved", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Declined", data: declined, backgroundColor: '#DC3545', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }
  // End

  confirm(status:any){

    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }

      this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
        if(res){
          this.refreshInbox(false);
          this.form.get('comment')?.reset('')

        }
      })
    }
  }

   // test start
   refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

// test end

}
