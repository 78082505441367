import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DocumentManagementService } from 'src/app/@core/services/document-management.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-acknowledge-file',
  templateUrl: './acknowledge-file.component.html',
  styleUrls: ['./acknowledge-file.component.scss']
})
export class AcknowledgeFileComponent implements OnInit {

  loader1: boolean  = false;
  empDocData:any    = [];
  isChecked         = false
  loading           = true;
  currentExtension:any;
  currentDocName:any;
  fileName:any
  id:any;

  constructor(

    public router   : Router,
    private route: ActivatedRoute,
    public docService:DocumentManagementService,
    public appService: AppService,



  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.getAckFileData();

    })

  }
  getAckFileData(){
    this.loader1          = true;

    this.docService.getSingleAckData(this.id).subscribe((res:any)=>{

      this.empDocData       = res;
      this.fileName         = this.empDocData?.doc_file
      this.currentDocName   = this.appService.getDocName(this.empDocData['doc_file']);
      this.currentExtension = this.currentDocName?.split('.').pop();
      this.loading          = this.currentExtension=='pdf'?true:false;
      this.loader1          = false;

    })
  }
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
   }
   pageRendered(e: CustomEvent) {
    this.loading = false;
  }

   submitAck(){
      this.docService.updateAcknowledgement(this.empDocData.id,{'status':'Completed'}).subscribe((res:any)=>{
      this.router.navigate(['/document-repository'])

      })
   }
   fieldsChange(event:any){
    this.isChecked = event.target.checked;

   }

}
