
<div class="side-panel-body" style="overflow-y: auto;">
  <div class="container-fluid p-32 ">
      <div class="col-12">
        <h5>DETAILS</h5>
      </div>
      <div class="card card-c2 p-32">
          <div class="row  row-16">

              <div class="col-12 d-flex ">
                  <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
              </div>
              <div class="col-12 mt-8">
                  <h5 class="mb-8">Flexible Benefit Plan Approval</h5>
                  <span class=" badge-custom {{data?.request_type_color_code}}">FBP Request</span>
              </div>

              <app-view-fbp-declaration [fbpReqViewData]="fbp?.fbp_request_category" [currency]="fbp?.currency" [from]="'fnf'"></app-view-fbp-declaration>

              <ng-container *ngIf="reqStatus == 'Pending'" [formGroup]="form">
                <div class="col-12">
                    <textarea name="" id="" rows="3" placeholder="Comment here..." formControlName="comment" class="form-control my-12 textarea-comment" [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                    <div *ngIf="f.comment.errors" class="invalid-feedback">
                      <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                    </div>
                </div>
                <div *ngIf="reqStatus == 'Pending'" class="col-12 d-flex gap-16" >
                  <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400" (click)="confirm('Rejected')">Decline</button>
                  <button class=" btn btn-primary text-uppercase btn-sm fw-400" (click)="confirm('Approved')">Confirm</button>
                </div>
              </ng-container>
          </div>
      </div>
  </div>
</div>
