<div class="config-layout">
  <header class="config-header">
    <h5 class="m-0">{{actiontitle}} Challan Group</h5>
    <button class="btn-reset text-light-600" routerLink="/challan-settings"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader || !isEmpDropdown"></app-fw-edit-loader>
  <ng-container *ngIf="!loader && isEmpDropdown">
    <aside class="config-sidebar">
      <ul ngbNav #nav="ngbNav" class="steps gap-24" [(activeId)]="activeTab" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success': challanForm['controls'].define.valid}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Challan Group Details</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-challan-settings-group-details [formGp]="defineForm" [employeeDropdown]="employeeDropdown"
              [from]="from" (submitmethod)="submitMethod()"></app-challan-settings-group-details>
          </ng-template>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step" [class]="{'success': challanForm['controls'].applicable.valid}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Applicability</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
              <app-common-applicability [heading]="'Applicability'" [disabled]="disabled" [isEditForm]="editFlag" [from]="'challan'" [formGp]="applicableForm" (submitMethod)="submitForm()"></app-common-applicability>
          </ng-template>
        </li>

      </ul>
    </aside>
    <main class="config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
