<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">APPLICABILITY</p>
        </div>
        <div class="row row-16">
            <div class="col-12 form-row">
                <label for="applicable" class="form-label required">Applicable to </label>
              </div>
              <div class="col-12 form-row">
                <div class="form-check  form-check-inline">
                    <input  type="radio" id="organisation" class="form-check-input  " formControlName="applicable" (click)="changeApplicable()"
                          [value]="false">
                    <label for="organisation" class="form-check-label">Organisation<i
                      class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                      ngbPopover="Assign shift to a specific OU"
                      popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></label>
                </div>
                <div class="form-check   form-check-inline">
                    <input  type="radio" id="employee" class="form-check-input " formControlName="applicable" (click)="changeApplicable()"
                        [value]="true">
                    <label for="employee" class="form-check-label">Employee<i
                      class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                      ngbPopover="Assign shift to specific employees"
                      popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></label>
                </div>
            </div>
            <div class="col-12 form-row">

            </div>
            <ng-container *ngIf="f.applicable.value == false" formGroupName="assignees">
              <div class=" col-lg-6">
                <label for="company" class="form-label">Company </label>
                <ng-select
                placeholder="{{this.messageService.selectplaceholddisplay('company')}}"
                formControlName="company"
                [items]="companyDD"
                [multiple]="true"
                 groupBy="selectedAllGroup"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                bindLabel="company_name"
                [closeOnSelect]="false"
                bindValue="id"
                class="multiselect"
                (change)="companyChangeFunction(formGp.value.assignees.company)">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                 </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="formGp.value.assignees.company.length ">
                          <span class="ng-value-label">{{formGp.value.assignees.company.length }} company selected</span>
                      </div>
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" (change)="clear('company',index)"/>
                        <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="item.logo !=  null && item.logo !=  ''"src="{{item.logo}}">
                        <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.company_name,1)}}</span></span>

                        <span class="mt-1 ms-1">{{ (item.company_name.length>15)? (item.company_name | slice:0:15)+'..':(item.company_name)  }}</span>
                      </div>
                  </ng-template>
                </ng-select>
                <ul class="selected-values my-16" *ngIf="formGp.value.assignees.company.length != 0">
                    <li class="ng-value" *ngFor="let item of formGp.value.assignees.company,let ki =index">
                        <ng-container *ngFor="let insideitem of companyDD, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                <!-- {{ (insideitem['company_name'].length>15)? (insideitem['company_name'] | slice:0:15)+'..':(insideitem['company_name'])  }} -->
                                <div class="d-inline-flex align-items-center">
                                  <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                                  <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{k%5}} me-1" >
                                      <span>
                                      {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                                  </span>
                                  <span class=""> {{ (insideitem['company_name'].length>15)? (insideitem['company_name'] | slice:0:15)+'..':(insideitem['company_name'])  }} </span>
                              </div>
                              </span>

                        </ng-container>
                        <span  class="ng-value-icon right" (click)="clear('company',ki)" aria-hidden="true"><i class="icon-close"></i></span>

                    </li>
                </ul>
              </div>
              <div class=" col-lg-6">
                  <label for="bu" class="form-label">Business unit </label>
                  <ng-select [readonly]="formGp.value.assignees.company?.length == 0 ? true : false"
                  placeholder="{{this.messageService.selectplaceholddisplay('business unit')}}"
                  formControlName="businessunit"
                  [items]="buDD"
                  [multiple]="true"
                  groupBy="selectedAllGroup"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="false"
                  bindLabel="name"
                  [closeOnSelect]="false"
                  bindValue="id"
                  class="multiselect"
                  >
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        {{ item.name }}
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="formGp.value.assignees.businessunit?.length">
                            <span class="ng-value-label">
                              {{formGp.value.assignees.businessunit.length}} business unit selected</span>
                        </div>
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index">
                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                    </ng-template>
              </ng-select>
              <ul class="selected-values my-16" *ngIf="formGp.value.assignees.businessunit?.length != 0">
                  <li class="ng-value" *ngFor="let item of formGp.value.assignees.businessunit,let ki =index">
                      <ng-container *ngFor="let insideitem of buDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                          </span>

                      </ng-container>
                      <span  class="ng-value-icon right" (click)="clear('businessunit',ki)" aria-hidden="true">×</span>

                  </li>
              </ul>
              </div>
              <div class=" col-lg-6">
                  <label for="dept" class="form-label">Department  </label>
                  <ng-select
                    placeholder="{{this.messageService.selectplaceholddisplay('department')}}"
                    formControlName="department"
                    [items]="DepDD"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="multiselect"
                    >

                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        {{ item.name }}
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="formGp.value.assignees.department.length">
                            <span class="ng-value-label">{{formGp.value.assignees.department.length}} department selected</span>
                        </div>
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index">
                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                    </ng-template>
                </ng-select>
                <ul class="selected-values my-16" *ngIf="formGp.value.assignees.department.length != 0">
                    <li class="ng-value" *ngFor="let item of formGp.value.assignees.department,let ki =index">
                        <ng-container *ngFor="let insideitem of DepDD, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                            </span>

                        </ng-container>
                        <span  class="ng-value-icon right" (click)="clear('department',ki)" aria-hidden="true">×</span>
                    </li>
                </ul>
              </div>
              <div class="col-lg-6">
                <label for="desig" class="form-label">Designation</label>
                <ng-select
                    placeholder="{{this.messageService.selectplaceholddisplay('designation')}}"
                    formControlName="designation"
                    [items]="DesignationDD"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="multiselect"
                    >
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                          {{ item.name }}
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                          <div class="ng-value" *ngIf="formGp.value.assignees.designation.length">
                              <span class="ng-value-label">{{formGp.value.assignees.designation.length}} designation selected</span>
                          </div>
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index">
                          {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                      </ng-template>
                </ng-select>
                <ul class="selected-values my-16" *ngIf="formGp.value.assignees.designation.length != 0">
                    <li class="ng-value" *ngFor="let item of formGp.value.assignees.designation,let ki =index">
                        <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                            </span>
                        </ng-container>
                        <span  class="ng-value-icon right" (click)="clear('designation',ki)" aria-hidden="true">×</span>
                    </li>
                </ul>
              </div>
              <div class=" col-lg-6">
                  <label for="branch" class="form-label">Branch </label>
                  <ng-select
                    placeholder="{{this.messageService.selectplaceholddisplay('branch')}}"
                    formControlName="branch"
                    [items]="BranchDD"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="multiselect"
                    >
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                          {{ item.name }}
                      </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngIf="formGp.value.assignees.branch.length ">
                                <span class="ng-value-label">{{formGp.value.assignees.branch.length}} branch selected</span>
                            </div>
                        </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index">
                          {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                      </ng-template>
                  </ng-select>
                  <ul class="selected-values my-16" *ngIf="formGp.value.assignees.branch.length != 0">
                      <li class="ng-value" *ngFor="let item of formGp.value.assignees.branch,let ki =index">
                          <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                              </span>
                          </ng-container>
                          <span class="ng-value-icon right" (click)="clear('branch',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>
              </div>

              <div class=" col-lg-6">
                  <label for="grade" class="form-label">Grade  </label>
                  <ng-select
                    placeholder="{{this.messageService.selectplaceholddisplay('grade')}}"
                    formControlName="grade"
                    [items]="gradeDD"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="multiselect"
                    >
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                          {{ item.name }}
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                          <div class="ng-value" *ngIf="formGp.value.assignees.grade.length">
                              <span class="ng-value-label">{{formGp.value.assignees.grade.length }} grade selected</span>
                          </div>
                      </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index">
                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values my-16" *ngIf="formGp.value.assignees.grade.length != 0">
                      <li class="ng-value" *ngFor="let item of formGp.value.assignees.grade,let ki =index">
                          <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                              </span>

                          </ng-container>
                          <span class="ng-value-icon right" (click)="clear('grade',ki)" aria-hidden="true">×</span>

                      </li>
                  </ul>
              </div>
            </ng-container>
            <ng-container *ngIf="f.applicable.value == true" formGroupName="assignees">
              <div class=" col-lg-6">
                <label for="employees" class="form-label required">Add Employees</label>
                <ng-select #ngInput
                  [ngClass]="{ 'is-invalid': (f.assignees.get('employee')?.touched || f.assignees.get('employee')?.errors?.relievedError) && f.assignees.get('employee')?.errors}"
                    placeholder="{{this.messageService.selectplaceholddisplay('employee')}}"
                  formControlName="employee"
                  [items]="employeeDropdown"
                  [multiple]="true"
                  groupBy="selectedAllGroup"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="false"
                  bindLabel="name"
                  [closeOnSelect]="false"
                  bindValue="id"
                  class="multiselect"
                  (change)="ngChange($event)"
                  >

                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="formGp.value.assignees.employee.length">
                          <span class="ng-value-label">{{formGp.value.assignees.employee.length}} employee selected.</span>
                      </div>
                  </ng-template>
                  <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index">
                  <div class="d-inline-flex align-items-center"><input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img class="avatar-sm sq-24 img-fluid rounded-circle"   *ngIf="item.profile_image !=  null && item.profile_image !=  ''"src="{{item.profile_image}}">
                   <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>

                   <span class="ms-1">{{ (item.name.length>30)? (item.name | slice:0:30)+'..':(item.name)  }}</span>
                  </div>
              </ng-template>
              </ng-select>
              <div *ngIf="(f.assignees.get('employee')?.touched || f.assignees.get('employee')?.errors?.relievedError) && f.assignees.get('employee')?.errors" class="invalid-feedback">
                  <div *ngIf="f.assignees.get('employee')?.errors?.required">Please select atleast one employee.</div>
                  <div *ngIf="f.assignees.get('employee')?.errors?.relievedError">{{this.messageService.inactiveStatus()}}</div>
              </div>
            </div>
            <div class="col-12">
              <ul class="selected-values" *ngIf="formGp.value.assignees.employee.length !=0">
                  <li class="ng-value" [ngClass]="{'ng-value-danger': isInsideItemIdFive(item)}" *ngFor="let item of formGp.value.assignees.employee, let m =index">
                      <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                      <span class="ng-value-label" *ngIf="insideitem['id']==item">
                      <div class="d-inline-flex align-items-center">
                          <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null" src="{{insideitem.profile_image}}"   >
                          <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''" class="avatar-sm sq-24 rounded-circle {{insideitem.color_code}} me-1"    >
                              <span>
                              {{this.appService.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                          </span>
                          <span class=""> {{ (insideitem['name'].length>25)? (insideitem['name'] | slice:0:25)+'..':(insideitem['name'])  }}</span>
                          <span *ngIf="insideitem?.employment_status == 'Relieved' || insideitem?.employment_status == 'Settled' || insideitem?.employment_status == 'Terminated' || insideitem?.employment_status == 'Retired'" class="lh-1 ms-8"><i class="bi bi-info-circle fs-14 text-danger tooltip-icon" ngbPopover="Employee status : Inactive" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                       </div>
                       </span>
                      </ng-container>
                      <span  class="ng-value-icon right" aria-hidden="true" (click)="clear('employee',m)"><i class="icon-close"></i></span>
                 </li>
              </ul>
          </div>
            </ng-container>
        </div>
    </div>
    <footer class="submit-footer">
        <button type="submit" class="btn btn-primary btn-sm ms-auto" [disabled]="checkcommonFormValid() || submitted" (click)="submitForm()">SAVE</button>
    </footer>
</form>

