import {  Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-challan-summary',
  templateUrl: './challan-summary.component.html',
  styleUrls: ['./challan-summary.component.scss']
})

export class ChallanSummaryComponent implements OnInit {

  @Input()formGp: any;
  @Input()currency: any;
  @Input()selectedEmpData: any;

  @Output() submitmethod  = new EventEmitter();

  saveClicked             = false;

  constructor(
    public appService:AppService,
  ) { }

  ngOnInit(): void {
  }

  /********* SAVE CHALLAN ****************/
  saveChallan(){
    this.saveClicked  = true;
    this.submitmethod.emit();
  }

}
