import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-work-from-home-summary',
  templateUrl: './work-from-home-summary.component.html',
  styleUrls: ['./work-from-home-summary.component.scss']
})
export class WorkFromHomeSummaryComponent implements OnInit {
  @Input()formGp:any;
  saveClicked                     = false;
  @Output() submitmethod          = new EventEmitter();
  to_day:any;
  from_day:any;
  fulldayarr:any=[];
  halfdayarr:any=[];
  totday = 0;
  constructor(public appService: AppService ) { }

  ngOnInit(): void {
    this.getdatafn();
  }
  getdatafn(){
    this.from_day = this.appService.dateFormatDisplay(this.formGp.get('from').value);
    this.to_day = this.appService.dateFormatDisplay(this.formGp.get('to').value);
    for(let i=0;i<this.formGp.get('wfh_request').value.length;i++){
      if(this.formGp.get('wfh_request').value[i].day_portion== 'Full Day'){
        this.fulldayarr.push(this.appService.dateFormatDisplay(this.formGp.get('wfh_request').value[i].wfh_date));
        this.totday = this.totday+1;
      }else if(this.formGp.get('wfh_request').value[i].day_portion== 'First Half Day' || this.formGp.get('wfh_request').value[i].day_portion== 'Second Half Day'){
        this.halfdayarr.push((this.appService.dateFormatDisplay(this.formGp.get('wfh_request').value[i].wfh_date))+' ('+this.formGp.get('wfh_request').value[i].day_portion+')');
        this.totday = this.totday+0.5;
      }
    }
  }
  validateSummary(){
    this.saveClicked  = true;
    this.submitmethod.emit();
  }

  createarrayDisplay(array:any){
    let string:any =[];
    array.forEach((item:any) => {
      string.push(item);
    })
    return string.join(", ");
  }
}
