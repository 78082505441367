
<div class="container-fluid p-32 form-container"  [formGroup]="formGp">
    <div class="row ">
        <div class="col-12 d-flex justify-content-between flex-wrap">
            <div>
                <p class="text-uppercase fw-500 mb-0">Salary structure</p>
                <p class="text-helper mb-0">Add Employee salary structure</p>
            </div>
        </div>
    </div>
    <div class="col-12 mt-16" *ngIf="Isalert && (empSalaryData.message!='' && empSalaryData.message!=null)">
      <div role="alert" class="alert alert-warning mb-0 show">
          <p class="mb-0 fs-14">{{empSalaryData.message}}</p>
          <div class="btn-close pointer" (click)="Isalert=false;"></div>
      </div>
    </div>
    <div class="row mt-24">
        <div class="col">
            <div class="card card-c2  px-16 ">
                <div class="card-body px-0 py-16 d-flex align-items-center ">
                    <div class=" me-8 ">
                        <img *ngIf="employeeData.profile_image !=  null && employeeData.profile_image !=  ''" class="avatar-circle sq-40  " src="{{employeeData.profile_image}}">
                        <div *ngIf="employeeData.profile_image ==  '' || employeeData.profile_image ==  null" class="avatar-circle sq-40  {{employeeData.color_code}} avatar-border" >{{employeeData.nameShort}}</div>
                    </div>
                    <h6 class="fw-600 mb-0">{{employeeData.name+" ("+employeeData.employee_code+")"}}</h6>

                </div>

                <div class="card-body px-0 py-24 bt">
                    <div class="row row-16">

                        <div class="col-lg-6 form-row">
                            <label for="" class="form-label required">Pay group</label>
                            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Pay Group')}}" formControlName="pay_group" class="form-ngselect"  [ngClass]="{ 'is-invalid': (submitted || f.pay_group.touched || f.pay_group.dirty) &&  f.pay_group.errors?.required}" (change)="validateEdit('pay_group')">
                                <ng-option *ngFor="let pay_group of payGroupDD" [value]="pay_group.id">{{pay_group.name}}</ng-option>
                            </ng-select>
                            <div *ngIf="(submitted || f.pay_group.touched || f.pay_group.dirty) && f.pay_group.errors?.required" class="invalid-feedback">
                              <div *ngIf="f.pay_group.errors.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                            <!-- <div *ngIf="f.pay_group.errors?.exist">{{this.messageService.ctcPaygrpRestriction()}}</div> -->
                          </div>
                        </div>
                        <div class="col-lg-6 form-row">
                            <label for="" class="form-label required">CTC </label>
                            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.ctc.touched || f.ctc.dirty) &&  (f.ctc.errors)}">
                                <input type="text" formControlName="ctc" autocomplete="off" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('CTC')}}"
                                    class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.ctc.touched || f.ctc.dirty) &&  (f.ctc.errors?.required || f.ctc.errors?.pattern || f.ctc.errors?.max)}"  (keyup)="validateEdit('ctc')">
                                <span>{{empSalaryData.currency}}</span>
                            </div>
                            <div *ngIf="(submitted || f.ctc.touched || f.ctc.dirty) && (f.ctc.errors?.required || f.ctc.errors?.pattern || f.ctc.errors?.max)" class="invalid-feedback">
                                <div *ngIf="f.ctc.errors.required">{{this.messageService.validationDisplay('required')}}
                                </div>
                                <div *ngIf="f.ctc.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                <div *ngIf="(f.ctc.errors?.max && !(f.ctc.errors.pattern))"> {{this.messageService.fieldlengthvalidation('num','10')}}

                                </div>
                                <!-- <div *ngIf="f.ctc.errors?.exist">{{this.messageService.ctcPaygrpRestriction()}}</div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="validateStructure()" [disabled]="(f.ctc.errors || f.pay_group.errors)|| !isValid">Next</button>

</footer>
