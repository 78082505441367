import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationPolicyService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  // Landing Data
  getPolicyList(status:any,limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/confirmation-policy-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  // Policy Dropdown
  getPolicyDropdown(is_active:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/confirmation-policy-dropdown/?is_active=${is_active}&ordering=name`, this.httpOption)
  }

  // Create API
  createPolicy(body:any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/confirmation-policy-create/`, body, this.httpOption)
  }

  // Update API
  updatePolicy(id:any,body:any){
    return this.http.put(`${this.API_PATH}general_settings/api/v1/confirmation-policy-detail/${id}/`, body, this.httpOption)
  }

  // Update View
  updateViewPolicy(id:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/confirmation-policy-detail/${id}/`, this.httpOption)
  }

  // View API
  viewPolicy(id:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/confirmation-policy-view/${id}/`, this.httpOption)
  }

  // Delete API
  deletePolicy(id:any,data:any){
    return this.http.patch(`${this.API_PATH}general_settings/api/v1/confirmation-policy-delete/${id}/`,data,this.httpOption)
  }

  // Confirmation Letter Template
  getConfirmationLetters(){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/?is_active=true&letter_type=Confirmation Letter`, this.httpOption)
  }

  // Termination Letter Template
  getTerminationLetters(){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/?is_active=true&letter_type=Termination Letter`, this.httpOption)
  }

  // Extension Letter Template
  getExtensionLetters(){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/?is_active=true&letter_type=Extension Letter`, this.httpOption)
  }
}
