import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-fbp-declaration-setup',
  templateUrl: './fbp-declaration-setup.component.html',
  styleUrls: ['./fbp-declaration-setup.component.scss']
})

export class FbpDeclarationSetupComponent implements OnInit {

  @Input()fbdeclarationFlag:any;
  @Input()from:any;
  @Input()empId:any;
  @Input()requestFor:any;
  @Output()fbpReqClose      = new EventEmitter();
  @Output()fbpReqSave       = new EventEmitter();

  fbpListData:any           = [];
  fbpSummaryData:any        = [];
  currentYear: number       = new Date().getFullYear();
  nextYear: number          = (this.currentYear+1);
  checkedArray :any         = [];
  fbp_request_category:any  = [];
  nodata                    = false;
  loader                    = false;
  searchlistdata:any        = [];
  categorysearch:string     = this.messageService.searchdisplay('category');
  searchKeyword             = "";
  alertShow                 = 'show';
  is_FBP_requested          = false;
  saveClicked               = false;
  currency:any;

  constructor(
    private investmentService:InvestmentDeclerationService,
    private authService:AuthService,
    public fb:FormBuilder,
    public messageService : MessageService,
    public router: Router,
    public appservice:AppService,
    public activityService:ManageActivityService
  ) { }

  formGp : FormGroup = this.fb.group({
    fbpArray         : this.fb.array([])
  });

  ngOnInit(): void {
    this.empId = this.from=='behalf'?this.empId:this.authService.isEmployeeId
    this.requestDetails();
    if(this.from=='behalf')
    this.getBehalfEmpCurrency()
    else
    this.getEmployeeCurrency();
  }

  /********* FBP DECLARED CHECKING FUNCTION ****************/
  requestDetails(){
    this.loader             = true;
    if(this.from=='behalf'){
      this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
        this.fbpRequest(res)
      })
    }
    else{

      this.investmentService.requestDetails().subscribe((res:any)=>{
        this.fbpRequest(res)

      });
    }
  }
  fbpRequest(res:any){
    if(!res.count_info?.is_FBP_requested){
      this.getFBPLists();
      this.is_FBP_requested = false;
    }else{
      this.is_FBP_requested = true;
      this.getFBPSummary();
    }

  }
  /********* FBP LIST FUNCTION ****************/
  getFBPLists(){
    // this.loader             = true;
    this.nodata             = false;
    // this.empId              = this.authService.isEmployeeId;
    this.investmentService.getFBPCategoryList(this.empId,this.searchKeyword).subscribe((res: any) => {
      this.fbpListData      = res;
      this.getFBPData();

      // Search API
      this.investmentService.fbpDeclarationSearch(this.empId).subscribe((res: any) => {
        this.searchlistdata = res;
        this.loader         = false;
      });

    });
  }

  /********* FBP TOP TILES FUNCTION ****************/
  getFBPSummary(){
    this.loader            = true;
    // this.empId             = this.authService.isEmployeeId;
    this.investmentService.getFBPCategorySummary(this.empId).subscribe((res: any) => {
      this.fbpSummaryData  = res;
      this.loader          = false;
    });
  }

  /********* EMPLOYEE CURRENCY FUNCTION ****************/
  getEmployeeCurrency(){
    let userData  = this.appservice.getEmpData();
    this.currency = userData.currency;
  }

  /********* CREATING & REMOVING ARRAY FUNCTION ****************/
  fbpArrays() : FormArray {

    return this.formGp.get("fbpArray") as FormArray
  }

  newFbpArray(): FormGroup {

    return this.fb.group({
      "pay_component__id"     : [null],
      "pay_component__name"   : [null],
      "fbp_amount"            : [null],
      "pay_component"         : [null],
      "annually_amount"       : [null],
      "monthly_amount"        : [null],
      "restric_fbp_amount"    : [null],
      "year_renum"            : [null]
    })
  }

  addFbpArray() {
    this.fbpArrays().push(this.newFbpArray());
  }

  removeFbpArrays(i:number) {
    this.fbpArrays().removeAt(i);
  }

  removeAllFbpArrays(){
    if(this.formGp.value.fbpArray.length > 0){
      while(this.formGp.value.fbpArray.length !== 0){
        this.fbpArrays().removeAt(0);
      }
    }
  }

  removeCheckedArray(){
    if(this.checkedArray.length > 0){
      while(this.checkedArray.length !== 0){
        this.checkedArray.splice(0);
      }
    }
  }

  get f() { return  this.formGp.controls; }

  /********* CHECK BOX CHECK FUNCTION ****************/
  fbpCheckboxFunction(e:any,index:any){

    let maxValue = Number((<FormArray>this.formGp.get("fbpArray")).at(index).get('fbp_amount')?.value);
    (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.disable();
    this.isRestrictAmount(index);

    if(e.target.checked == true){
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.max(maxValue)]);
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.setValidators([Validators.required,digitdecimalValidator()]);
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('pay_component')?.setValue((<FormArray>this.formGp.get("fbpArray")).at(index).get('pay_component__id')?.value);

      this.checkedArray[index]['checked'+index] = true;
    }
    else{
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.clearValidators();
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.clearValidators();
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('pay_component')?.setValue(null);
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.setValue(null);
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.setValue(null);

      this.checkedArray[index]['checked'+index] = false;
    }
    (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.updateValueAndValidity();
    (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.updateValueAndValidity();
  }

  /********* SUBMIT BUTTON DISABLED FUNCTION ****************/
  disabledFunction(){

    let count = 0;

    if(this.checkedArray.length > 0){
      for(let i=0;i<this.checkedArray.length;i++){
        if(this.checkedArray[i]['checked'+i] == false ){
          count = count+1;
        }
      }
    }
    if(count == this.checkedArray.length){
      return true;
    }
    else{
      return false;
    }
  }

  /********* SUBMIT FUNCTION ****************/
  requestSubmitFunction(){
    this.saveClicked  = true;
   this.fbp_request_category  = [];

    if(this.formGp.value.fbpArray.length > 0){

      for(let i=0;i<this.formGp.value.fbpArray.length;i++){

        if(((<FormArray>this.formGp.get("fbpArray")).at(i).get('pay_component')?.value != null) && ((<FormArray>this.formGp.get("fbpArray")).at(i).get('pay_component')?.value != "") ){

          this.fbp_request_category.push({"pay_component": (<FormArray>this.formGp.get("fbpArray")).at(i).get('pay_component')?.value, "annually_amount": (<FormArray>this.formGp.get("fbpArray")).at(i).get('annually_amount')?.value, "monthly_amount": (<FormArray>this.formGp.get("fbpArray")).at(i).get('monthly_amount')?.value })

        }

      }
      if(this.from=='behalf')
      this.fbpReqSave.emit({'employee_id':this.empId,
      'fbp_request_category' : this.fbp_request_category});
      else
      this.fbpReqSave.emit({fbp_request_category : this.fbp_request_category});
    }
  }

  /********* CALCULATE MONTHLY AMOUNT FUNCTION ****************/
  calculateMonthlyAmt(index:any){
    let annualAmt      = (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.value;
    if(annualAmt != '' && annualAmt != null && annualAmt != undefined){
      let monthlyAmt:any = Number(annualAmt)/12;
      monthlyAmt         = parseFloat(monthlyAmt).toFixed(2);
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.setValue(Number(monthlyAmt));
    }else{
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.setValue('');
    }
  }

  /********* CALCULATE ANUALLY AMOUNT FUNCTION ****************/
  calculateAnuallyAmt(index:any){

    let monthlyAmt      = (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.value;
    let annualAmt:any   = Number(monthlyAmt)*12;
    annualAmt           = parseFloat(annualAmt).toFixed(2);
    (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.setValue(Number(annualAmt));
  }

  /********* SEARCH FUNCTIONS ****************/
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword   = "";
      this.getFBPLists();
    }else{
      this.investmentService.getFBPCategorySingleList(this.empId,data).subscribe((res: any) => {
        this.fbpListData   = res;
        this.getFBPData();
        this.loader        = false;
      });
    }
  }

  searchFBPCategory(searchKeyword:any){
    this.loader         = true;
    this.removeAllFbpArrays();
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.getFBPLists();
    }
  }

  /********* COMMON FBP LIST FUNCTION ****************/
  getFBPData(){
    this.removeCheckedArray();
    this.removeAllFbpArrays();

    if(this.fbpListData.length > 0){
      for(let i=0;i<this.fbpListData.length;i++){
        this.addFbpArray();
        this.checkedArray.push({['checked'+this.checkedArray.length]:false});
      }
      (<FormArray>this.formGp.get("fbpArray")).reset(this.fbpListData);
      this.getFBPSummary();
    }
    else{
      this.nodata = true;
    }
  }

  /********* CHECKING FBP AMOUNT IS RESTRICTED OR NOT FUNCTION ****************/
  isRestrictAmount(index:any){

    if((<FormArray>this.formGp.get("fbpArray")).at(index).get('restric_fbp_amount')?.value){

      let maxAmt          = (<FormArray>this.formGp.get("fbpArray")).at(index).get('fbp_amount')?.value;
      let monthlyAmt:any  = Number(maxAmt)/12;
      monthlyAmt          = parseFloat(monthlyAmt).toFixed(2);

      (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.disable();
      // (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.disable();
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.setValue(maxAmt);
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.setValue(Number(monthlyAmt));
    }
    if((<FormArray>this.formGp.get("fbpArray")).at(index).get('year_renum')?.value > 0){

      let maxAmt          = (<FormArray>this.formGp.get("fbpArray")).at(index).get('year_renum')?.value;
      let monthlyAmt:any  = Number(maxAmt)/12;
      monthlyAmt          = parseFloat(monthlyAmt).toFixed(2);

      (<FormArray>this.formGp.get("fbpArray")).at(index).get('annually_amount')?.setValue(maxAmt);
      (<FormArray>this.formGp.get("fbpArray")).at(index).get('monthly_amount')?.setValue(Number(monthlyAmt));
    }
  }

  /********* CLOSE FUNCTION ****************/
  closeFunction(){
    this.fbpReqClose.emit(false);
  }
  getBehalfEmpCurrency(){
    this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
      this.currency = res?.currency
    })
  }

}
