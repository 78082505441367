<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Expense Reimbursement - {{month}} {{year}}</h5>
    <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <div class="container-fluid p-32 form-container">
      <div class="row mb-24">
        <div class="col-md-4">
          <p class="text-uppercase fw-500 mb-0">{{status}} EXPENSE </p>
        </div>
        <div class="col-md-8">
          <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
            <div style="min-width:22.688rem;">
              <div class="form-icon2">
                <i class="icon-search icon-left fs-14"></i>
                <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
                  (click)="searchString=''"></div>
                <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " autocomplete="off"
                  name="searchString" [(ngModel)]="searchString" (keyup)="page=1" />
              </div>
            </div>
            <button class="btn-square btn btn-secondary" (click)="filter  = true">
              <i class="icon-filter-left fs-13"></i>
            </button>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" class="btn-check" id="111" name="sh" [checked]='status=="Pending"' (click)="getLandingScreenData('Pending')"/>
              <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="111">PENDING</label>
              <input class="btn-check" name="sh" type="radio" id="222" [checked]='status=="Reimbursed"' (click)="getLandingScreenData('Reimbursed')"/>
              <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="222">REIMBURSED</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-24">
        <div class="col-12">
          <div class="card card-c2 overflow-hidden">
            <app-table-loader *ngIf="loader"></app-table-loader>
            <div class="table-responsive"  *ngIf="!loader" >
              <table class="table  td-12 th-12 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th scope="col" class="form-cell">
                      <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)" [checked]="checkallSelected()">
                    </th>
                    <th class="fw-600" scope="col">Employee code</th>
                    <th class="fw-600" scope="col">Employee name</th>
                    <th class="fw-600" scope="col">Amount to be paid</th>
                    <th class="fw-600" scope="col">STATUS</th>
                    <th class="fw-600" scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody class="cell-16">
                  <tr *ngIf="tableData.length<=0">
                    <td colspan="6">
                      <div class="card card-c2 flex-center p-16 bg-dark-500">
                        <span class="fw-500 fs-14">No data found</span>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let item of $any(tableData |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">

                      <td class="form-cell">
                        <input [ngClass]="'page'+page" class="form-check-input m-0" type="checkbox"
                        [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" value="{{item.id}}" >
                    </td>

                    <td>{{item?.employee_code}}</td>
                    <td>{{item?.full_name}}</td>
                    <td>{{item?.total_amount | currency:item?.currency}}</td>
                    <td>
                      <span class="badge {{item?.status=='Reimbursed'?'badge-success':'badge-warning'}} py-1">{{item?.status}}</span>
                    </td>
                    <td><span class="link-primary1 d-flex align-items-center" (click)="getEmployeeReimbursement(item?.id)"> <i
                          class="icon-eye me-1"></i> View
                        Details</span> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="tableData.length>0">
          <div class="col-12" *ngIf=" filteredcount()>pageSize">
            <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize" [collectionSize]="filteredcount()"
              [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="submit-footer gap-16">
      <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="tableData.length>0">
        <span class="">{{countSelected()}} of {{tableData?.length}} selected </span>
        <span *ngIf="tableData.length != countSelected()" class="link-primary fw-500" (click)="selectAll();">Select all
          {{tableData?.length}} employees</span>
        <span *ngIf="tableData.length == countSelected()" class="link-primary fw-500" (click)="unselectAll();">Unselect all
          {{tableData?.length}} employees</span>
      </p>
      <button class="btn btn-primary btn-sm text-uppercase" [disabled]="countSelected()==0" (click)="confirmReimbursement()">{{status=='Pending'?'REIMBURSE':'Undo Reimbursement'}}</button>
    </footer>
  </main>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:720px;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="d-flex mb-16" *ngIf="viewData.length>0">
          <p class="text-uppercase fw-500 mb-0">{{viewData[0]['employee_name']}} ({{viewData[0]['employee_code']}})</p>
        </div>
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">REPORT DETAILS</p>
                  <span class="badge {{status=='Pending'?'badge-warning':'badge-success'}} ms-auto py-1 px-3">{{status==''?'Pending':status}}</span>
                </div>
                <div class="col-12">
                  <div class="card card-c2">
                    <app-table-loader *ngIf="viewLoader"></app-table-loader>

                    <div class="table-responsive radius-4" *ngIf="!viewLoader">
                      <table
                        class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                        <thead>
                          <tr>
                            <th class="fw-600 text-uppercase" scope="col">Report name</th>
                            <th class="fw-600 text-uppercase" scope="col">From date</th>
                            <th class="fw-600 text-uppercase" scope="col">to date</th>
                            <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                            <th class="fw-600 text-uppercase" scope="col">Amount to be reimbursed</th>
                          </tr>
                        </thead>
                        <tbody class="cell-16 ">
                          <tr *ngFor="let item of $any(viewData | slice: (viewPage-1) * viewPageSize : viewPage * viewPageSize),let ind = index">
                              <td>{{item?.name}}</td>
                              <td>{{this.appService.dateFormatDisplay(item?.from_date)}}</td>
                              <td>{{this.appService.dateFormatDisplay(item?.to_date)}}</td>
                              <td>{{item?.total_amount | currency:item.currency}}</td>
                              <td>{{item?.total_reimburseable_amount | currency:item.currency}}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="viewData.length>0">
                  <div class="col-12" *ngIf=" filteredcount()>viewPageSize">
                    <ngb-pagination class="d-flex justify-content-end" [pageSize]="viewPageSize" [collectionSize]="filteredcount()"
                      [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <footer class="side-panel-footer justify-content-end gap-16">
      <button type="submit" class="btn btn-primary text-uppercase btn-sm">REIMBURSE </button>
    </footer> -->
  </div>
</div>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>

<div class="modal  modal-alert {{saveConfirm == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title">{{countSelected()}} Employee(s) selected</h4>
          <button type="button" class="btn-close ms-auto my-0" (click)="saveConfirm = false" ></button>
          </div>
          <div class="modal-body">
              <p class="mb-0"> {{message}}</p>
          </div>
          <div class="modal-footer">
          <button [disabled]="saveComplete" type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="saveConfirm = false;" >Cancel</button>
          <button [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase" (click)="updateReimbursement()">{{processText}}</button>

          </div>
      </div>
  </div>
</div>
<app-error-download [fileName]="downloadFile" [successDataCount]="successDataCount"
  [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="modelHead"
  (closePanel)="downloadMsg = false;" [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>
