<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='showSidePanel === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <!-- <h5>Import Override LWF - {{month}} {{year}}</h5> -->
            <h5>{{title}}</h5>
            <a class="toggle-panel" (click)="deleteUploadedFile();closeSidePanel();"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-24 mb-24">
                    <div class="col-12">
                        <div class="card card-c2 p-24">
                            <div class="title mb-24">{{heading}}</div>
                            <div class="row"  *ngIf="fileName!=''"> 
                                <div class="col-md-6">
                                    <ol class="fs-16  mb-24 li-16 list-type-none ">
                                        <li  class="d-flex ">
                                            <span class="text-trim fw-500">{{fileName}}</span>
                                            <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                                            <span class="link-danger fw-500 flex-shrink-0" (click)="deleteUploadedFile()" ><i class="bi bi-trash ms-8 "></i></span>
                                        </li>  
                                    </ol>
                                </div>
                            </div>
                            <label for="upload"  [class.disabled]="fileName != ''" style="max-width: 18.375rem;" class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}"> <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null" (change)="onFileChange($event)" id="upload" hidden>{{btnText}}</label>
                            <span class="invalid-feedback" *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
    
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 p-24">
                            <div class="title mb-24">Instructions</div>
                            <ol class="fs-14 ps-16 mb-0 li-24">
                                <ng-content></ng-content>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer gap-32 pt-16">
          <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || submiited" type="button" (click)="closeSidePanel();validateData.emit()">Upload</button>
        </footer>
    </div>

</div>