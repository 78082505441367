<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Generate Form 16</h5>
    <button class="btn-reset text-light-600" routerLink="/form-16"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="oldDataLoaderCount<4"></app-fw-edit-loader>
  <ng-container *ngIf="oldDataLoaderCount >=4">
    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step"
            [class]="{'success':form16A_Data!='' && (form16A_Data.data.length!=0 || form16A_Data.error.length!=0)}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Upload Part A</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-upload-part-a (saveForm16A)="submitForm16A($event)" (Form16A_ZipFile)="Form16A_ZipFile($event)"
              [fileName]="fileNameA" [fileSize]="fileSizeA" [progress]="percentDone" [iscompleted]="iscompleted"
              [isProgressStart]="isProgressStart" (deleteUploadedFile)="deleteUploadedFile($event)"
              [deleteToggle]="deleteToggle" [uploadedEmployee]="uploadedEmployee_A" [deletepopup_A]="deletepopup_A"
              (Form16A_FileData)="Form16A_FileData($event)" [financialyear]="financialyear"></app-upload-part-a>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step"
            [class]="{'success':form16A_Data!='' && (form16A_Data.data.length!=0 || form16A_Data.error.length!=0)}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Verify Part A</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-verify-part-a [form16A_Data]="form16A_Data" (changeTab)="changeTab($event)"
              [form16A_File]="form16A_File"></app-verify-part-a>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="step"
            [class]="{'success':form16B_Data!='' && (form16B_Data.data.length!=0 || form16B_Data.error.length!=0)}">
            <div class="count">3</div>
            <div class="content">
              <span>Step 3</span>
              <h6>Upload Part B</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-upload-part-b (saveForm16B)="submitForm16B($event)" (Form16B_ZipFile)="Form16B_ZipFile($event)"
              [fileName]="fileNameB" [fileSize]="fileSizeB" [progress]="percentDone" [iscompleted]="iscompleted"
              [isProgressStart]="isProgressStart" (deleteUploadedFile)="deleteUploadedFile($event)"
              [deleteToggle]="deleteToggle" [uploadedEmployee]="uploadedEmployee_B" [deletepopup_B]="deletepopup_B"
              (Form16B_FileData)="Form16B_FileData($event)" [financialyear]="financialyear"></app-upload-part-b>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink class="step"
            [class]="{'success':form16B_Data!='' && (form16B_Data.data.length!=0 || form16B_Data.error.length!=0)}">
            <div class="count">4</div>
            <div class="content">
              <span>Step 4</span>
              <h6>Verify Part B</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-verify-part-b [form16B_Data]="form16B_Data" (changeTab)="changeTab($event)"
              [form16B_File]="form16B_File" (generateForm)="generateForm16()" [progress]="percentDone"
              [iscompleted]="iscompleted" [isProgressStart]="isProgressStart"></app-verify-part-b>
          </ng-template>
        </li>
        <li [ngbNavItem]="5">
          <a ngbNavLink class="step"
            [class]="{'success':(generatedForm!='') && (generatedForm.data.length!=0 || generatedForm.error_list.length!=0)}">
            <div class="count">5</div>
            <div class="content">
              <span>Step 5</span>
              <h6>Generate Form 16</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-generate-form16 [generatedData]="generatedForm" (publishForm)="publishForm16()"></app-generate-form16>
          </ng-template>
        </li>
        <li [ngbNavItem]="6">
          <a ngbNavLink class="step" [class]="{'success':publishedForm != ''}">
            <div class="count">6</div>
            <div class="content">
              <span>Step 6</span>
              <h6>Publish</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-publish-form16 [publishedData]="publishedForm" (publishSaveForm)="publishSaveForm16()"
              [loader]="generateLoader"></app-publish-form16>
          </ng-template>
        </li>

      </ul>

    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>

    </main>
  </ng-container>
</div>
