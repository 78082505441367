import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.scss']
})
export class LeaveComponent implements OnInit {
  menuData:any = [];
  menuList:any;
  constructor(
    public route:ActivatedRoute,
    private appService: AppService,public router: Router
  ) { }

  ngOnInit(): void {
   this.getPermission()
  }

  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/leave-rule')
      this.router.navigate([this.menuData[0].link]);
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/leave-rule');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }
}
