import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { FormArray,FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-shift-week-off',
  templateUrl: './shift-week-off.component.html',
  styleUrls: ['./shift-week-off.component.scss']
})
export class ShiftWeekOffComponent implements OnInit {

  constructor(public messageService : MessageService,public appservice:AppService,private fb:FormBuilder) { }
  @Input()formGp : FormGroup    = this.fb.group({
    week_off_define : [''],
    weekoffdata     : [],
  });
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Input()componentError:any;
  @Input()submitted:boolean=false;
  @Input()disabled = false;
  saveBtnClicked = false;

  get weekoffdata() {
    return this.formGp.get('weekoffdata') as FormArray;
  }

  ngOnInit(): void {
  }
  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    this.saveBtnClicked=true;
    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit();
  }
  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }

  formArr() : FormArray {
    return this.formGp.get('weekoffdata') as FormArray
  }

  policybasedshift(){
    let data = this.formGp.get('weekoffdata')?.value;
    data?.forEach((item:any, value:any) => {
    this.formArr().at(value).get('monday')?.setValue(null)
    this.formArr().at(value).get('tuesday')?.setValue(null)
    this.formArr().at(value).get('wednesday')?.setValue(null)
    this.formArr().at(value).get('thursday')?.setValue(null)
    this.formArr().at(value).get('friday')?.setValue(null)
    this.formArr().at(value).get('saturday')?.setValue(null)
    this.formArr().at(value).get('sunday')?.setValue(null)
    })    
  }
}
