import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LocationGroupService } from 'src/app/@core/services/location-group.service';
import { jsDocComment } from '@angular/compiler';

@Component({
  selector: 'app-location-group-add',
  templateUrl: './location-group-add.component.html',
  styleUrls: ['./location-group-add.component.scss']
})
export class LocationGroupAddComponent implements OnInit {
  policyDefineUnit     :any =[];
  value                :any;
  id                   :any

  @Input()formGp       :any=[];
  validated : boolean  = false;
  @Input() editflag     :any
  @Output() submitmethod  = new EventEmitter();
  @Output() dataemit  = new EventEmitter();
  @Input()componentError  :any;
  @Input()disabled        = false;
  private subjectKeyUp    = new Subject<any>();
  @Input()submitted:boolean=false;
  StateDropdown:any       =[];
  CityDropdown:any        =[];
  summerydata:any         =[];

  constructor(
    private fb:FormBuilder,
    public messageService : MessageService,
    public route : ActivatedRoute,
    public existDataService :  ExistDatasService,
    public LocationGroupService:LocationGroupService
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    });   
    this.LocationGroupService.stateList().subscribe((res: any) => { 
      this.StateDropdown = res; 
      this.selectAllForDropdownItems(this.StateDropdown);
    });       
    if(this.formGp.controls.states?.value !='' && this.formGp.controls.states?.value != null){
      this.cityfunction();
    }
  }

  clear(index:number,key:any){
    if(key=='state'){
        let daata = this.formGp.value.states;
        daata.splice(index, 1);
        this.formGp.patchValue({
          states: daata,
          cities: []
        });
    }else{
      let daata = this.formGp.value.cities;
      daata.splice(index, 1);
      this.formGp.patchValue({
        cities: daata
      });
    }
  }

  validateGROUP() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.datafn();
    this.submitmethod.emit(this.summerydata);
  }

  datafn(){
    this.summerydata=[]
    let cityarray:any =[];
    let statearray:any =[];
    for(let i=0;i<this.formGp.controls.states?.value.length;i++){
      for(let j=0;j<this.StateDropdown.length;j++){
        if(this.StateDropdown[j].id==this.formGp.controls.states?.value[i]){
          statearray.push({
            'statename': this.StateDropdown[j].name,
        });
        }
      }
    }
    for(let i=0;i<this.formGp.controls.cities?.value.length;i++){
      for(let j=0;j<this.CityDropdown.length;j++){
        if(this.CityDropdown[j].id==this.formGp.controls.cities?.value[i]){
          cityarray.push({
            'cityname': this.CityDropdown[j].name,
        });
        }
      }
    }
    this.summerydata.push({
      'statearray': statearray,
      'cityarray': cityarray,
      'name': this.formGp.controls.name?.value
    })
    this.dataemit.emit(this.summerydata);
  }

  existService(){
    if(!this.editflag){
      this.value ={
        "page": "LocationGroup",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"LocationGroup",
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.policyDefineUnit = res.body

      if(this.policyDefineUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  get f() { return this.formGp.controls; }

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  cityfunction(){
    this.LocationGroupService.cityList(this.formGp.controls.states?.value).subscribe((res: any) => { 
      this.CityDropdown = res; 
      // this.selectAllForDropdownItems(this.CityDropdown);
      this.datafn();
    });
  }

  clearcity(){
    this.formGp.patchValue({cities: []});
  }
}
