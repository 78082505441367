import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyTeamAttendanceService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;

  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  // test start

  getMyTeam(month:any,year:any) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-attendance-grid/?month=${month}&year=${year}`, this.httpOption)
  }
  getMyTeamAttendanceData(month:any,year:any,limit:any,offset:any,search:any,filterData:any,empId:any=''){
    if(empId != '' && empId != 0)
      return this.http.get(`${this.API_PATH}attendance/api/v1/team-hr-attendance-dashboard/?month=${month}&year=${year}&limit=1&offset=1&emp_id=${empId}&reporting_manager_status=true`,this.httpOption)
    else if(filterData == '')
      return this.http.get(`${this.API_PATH}attendance/api/v1/team-hr-attendance-dashboard/?month=${month}&year=${year}&limit=${limit}&offset=${offset}&search=${search}&ordering=user__first_name&reporting_manager_status=true`, this.httpOption)
    else{
      return this.http.get(`${this.API_PATH}attendance/api/v1/team-hr-attendance-dashboard/?month=${month}&year=${year}&${filterData}&limit=${limit}&offset=${offset}&search=${search}&reporting_manager_status=true`,this.httpOption)
    }
  }


  getSearchedData(month:any,year:any,id:number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-hr-attendance-dashboard/?month=${month}&year=${year}&limit=1&offset=1&emp_id=${id}&reporting_manager_status=true`,this.httpOption)
  }
  empDDList(isactive:any,ordering:any){
    return  this.http.get(`${this.API_PATH}attendance/api/v1/reporting-employee-dropdown-list/?is_active=${isactive}&ordering=${ordering}`, this.httpOption);
  }
}
