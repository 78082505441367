import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LetterTemplateSettingsService } from 'src/app/@core/services/letter-template-settings.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-letter-template',
  templateUrl: './letter-template.component.html',
  styleUrls: ['./letter-template.component.scss']
})
export class LetterTemplateComponent implements OnInit {

  viewDetails     : boolean = false;
  statusBtn       : any     = "Active";
  lettersDropdown : any     = []
  searchString    : any     = ''
  searchKeyword   : any     = ''
  mainAPIData     : any     = []
  mainAPIList     : any     = []
  loader          : boolean = true
  infinityloader  : boolean = false;
  listLength                = 0
  noData          : boolean = false
  direction                 = '';
  status          : boolean = true
  deleteAlert     : boolean = false
  deleteId                  = 0
  vLoader         : boolean = false
  vData           : any
  modalToggle     : boolean = false;
  alertToggle     : boolean = false;
  alertMsg        : any     = '';
  deleteClicked   : boolean = false

  currentExtension: any     = ''
  currentDocName  : any
  fileName        : any
  isLoading = false
  searchData : Subject<void> = new Subject();

  // Pagination
  limit           = 25
  offset          = 0

  // Permission
  permissions     : any     = [];

  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private letterServ : LetterTemplateSettingsService,
    public msgServ     : MessageService,
    public appServ     : AppService,
    public router      : Router
  ) { }

  ngOnInit(): void {
    // Initial API Call
    this.getLandingData();
    // Search Placeholder
    this.searchString = this.msgServ.searchdisplay('letter template')
    // Permission
    this.getPermission()
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.letterServ.getLandingScreenAPI(this.limit,this.offset,this.searchKeyword,this.status)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  // Search
  searchResults(res:any){
    this.loader = true;
    this.mainAPIData = [];
    if(res == ""){
      this.searchKeyword = "";
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchKeywordResults(key:any){
    this.loader = true;
    this.isLoading = true
    this.offset = 0
    this.mainAPIData = [];
    this.searchKeyword = key;
    if(key != ""){
      this.searchData.next()
    }
  }

  // Landing Screen
  getLandingData(){
    this.letterServ.getLandingScreenAPI(this.limit,this.offset,this.searchKeyword,this.status).subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.mainAPIList = res?.body?.results
    this.listLength = res?.body?.results?.length
    if(this.offset == 0){
      this.mainAPIData = []
    }
    this.infinityloader = false
    this.addItems(this.listLength)
    this.loader = false;
    this.noData = res?.body.count == 0 ? true : false
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.mainAPIData.push(this.mainAPIList[i]);
    }
  }

  // Infinity Scroll
  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.limit;
    if(this.listLength){
      this.getLandingData();
      this.loader = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  // Filter
  selectItemList(value:any){
    this.loader = true;
    this.status = value;
    this.offset = 0;
    this.mainAPIData = [];
    this.child.clearBar();
  }

  // No Data
  addNewdata(){
    this.router.navigate(['/letter-template/letter-template-setup']);
  }

  // View Component
  viewComponent(id:any){
    this.vLoader = true
    this.letterServ.getSingleData(id).subscribe((res:any)=>{
      this.vData = res?.body
      this.vLoader = false
    })
  }

  // View Document
  viewDocument(fileName:any){
    this.fileName = fileName
    this.currentDocName = this.vData?.file_name
    this.modalToggle      = true;
    this.currentExtension = fileName?.split('.').pop()
    if(this.currentExtension == 'doc' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'csv'){
      this.modalToggle    = false;
      this.alertToggle    = true;
      this.alertMsg = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }else if(this.currentExtension == 'pdf'){
      this.modalToggle    = true;
      this.alertToggle    = false;
    }
  }

  closeModal(bool:any){
    this.modalToggle = bool;
  }

  closeInfo(bool:any){
    this.alertToggle = false;
  }

  deleteLetter(id:number){
    this.deleteClicked =true
    let data = { "is_active": false};
    this.letterServ.updateLetter(data,id,'del').subscribe((res: any) => {
      this.deleteAlert = false;
      this.loader = true;
      this.mainAPIData = [];
      this.child.clearBar()
      this.offset = 0
      this.getLandingData();
      this.deleteClicked =false
    },(error:any)=>{
      this.deleteClicked =false
      this.deleteAlert = false;
    });
  }

  // Edit Letter
  editLetter(id:any){
    this.router.navigate([`/letter-template/letter-template-setup/${id}`])
  }

  // Permission
  getPermission(){
    this.permissions = this.appServ.getPermissions('/letter-template');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // Remove Underscore
  removeUnderScore(val:any){
    if(val?.includes('_')){
      return val?.split('_').join(' ')
    }else{
      return val
    }
  }
}
