<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>

    <form class="side-panel-container" [formGroup]="disabledForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- {{disabledForm.value|json}} -->
                <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.disabled_on.errors}">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0 required">Disabled on  </p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="disabled_on" type="radio" id="disabled_on1"
                                class="form-check-input  " formControlName="disabled_on" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'disabled_on','last_month','specific_month','specific_year');setRequired(false)">
                            <label for="disabled_on1" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="disabled_on" type="radio" id="disabled_on2"
                                class="form-check-input " formControlName="disabled_on" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'disabled_on','this_month','specific_month','specific_year');setRequired(false)">
                            <label for="disabled_on2" class="form-check-label">Current month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="disabled_on" type="radio" id="disabled_on3"
                                class="form-check-input " formControlName="disabled_on" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'disabled_on',true,'specific_month','specific_year');setRequired(true)">
                            <label for="disabled_on3" class="form-check-label">Specific month</label>
                        </div>
                    </div>
                    <ng-container *ngIf="disabledForm.value.disabled_on==true">
                        <div class="col-6">
                            <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                                <label for="" class="form-label">Month </label>
                                <app-master-select class="config-app-master" style="width:9rem"
                                [placeholder]="'Select month'"
                                        id="month" [isrequired]="false" [selectedItem]="disabledForm.value.specific_month"
                                    [type]="'listOfMonth'" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                        (ngModelChange)="monthChangeFn($event)"
                                        [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                            </div>
                            <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                                <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                <label for="" class="form-label">Year </label>
                                <ng-select class="form-ngselect h-36 fs-16"
                                formControlName="specific_year" [placeholder]="'Select year'" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                    <ng-option *ngFor="let yData of Years"
                                        [value]="yData">{{yData}}</ng-option>
                                </ng-select>
                            </div>
                            <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                                <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="(submitted) && f.disabled_on.errors" class="invalid-feedback">
                    <div *ngIf="f.disabled_on.errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                </div>
               <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>

               <div class="row row-16 mb-16">
                <div class="col-12 form-row ">
                    <label for="" class="form-label">Disabled By</label>
                    <ng-select [readonly]="disabled"
                        placeholder="{{this.messageService.selectplaceholddisplay('Disabled By')}}"
                        formControlName="disabled_by" [items]="userDropdown" [multiple]="true"
                        groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                        bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="empLoader">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                [ngModelOptions]="{standalone: true}" /> Select All
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!empLoader">
                            <div class="ng-value" *ngIf="disabledForm.value.disabled_by?.length">
                                <span class="ng-value-label">{{disabledForm.value.disabled_by?.length}}
                                    user selected.</span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                                    [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                                    class="avatar-sm sq-24 img-fluid rounded-circle"
                                    *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                    src="{{item.profile_image}}">
                                <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                    class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>
                                <span class="mt-1 ms-1">{{ (item.name?.length>15)? (item.name |
                                    slice:0:30)+'..':(item.name) }}</span>
                            </div>
                        </ng-template>
                        <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="empLoader">
                          <div class="loading-text">Loading...</div>
                        </ng-template>
                    </ng-select>
                    <ul class="selected-values mt-16"
                        *ngIf="disabledForm.value.disabled_by?.length !=0 && !empLoader">
                        <li class="ng-value"
                            *ngFor="let item of disabledForm.value.disabled_by, let m =index">
                            <ng-container *ngFor="let insideitem of userDropdown, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                    <div class="d-inline-flex">
                                        <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                            *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                            src="{{insideitem.profile_image}}">
                                        <span
                                            *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                            class="avatar-sm sq-32 rounded-circle bg{{k%5}}">
                                            <span>
                                                {{this.appservice.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                                        </span>
                                        <span class="mt-2 ms-1">
                                            {{insideitem.name}}
                                        </span>
                                    </div>
                                </span>
                            </ng-container>
                            <span class="ng-value-icon right" aria-hidden="true"
                                (click)="clearcommonForm('employeerm',m)"><i class="icon-close"></i></span>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
                (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage();submitdisabledForm()">
                Apply
            </button>
        </footer>
    </form>
</div>

