import { Component, OnInit, ViewChild } from '@angular/core';
import { ChallanService } from 'src/app/@core/services/challan.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Location } from '@angular/common';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-challan-update',
  templateUrl: './challan-update.component.html',
  styleUrls: ['./challan-update.component.scss']
})

export class ChallanUpdateComponent implements OnInit {

  company:any               = 0;
  challanId:any             = 0;
  month:any                 = '';
  index:any                 = 0;
  deleteChallanId:any       = 0;
  challanUpdateList:any     = [];
  challanUpdateEmpData:any  = [];
  searchString:any          = "";
  page                      = 1;
  pageSize                  = 20;
  tableData:any             = [];
  buList:any                = [];
  DepList:any               = [];
  DesignationList:any       = [];
  BranchList:any            = [];
  cityList:any              = [];
  gradeList:any             = [];
  permissions:any           = [];
  filter                    = false;
  loader                    = false;
  deleteToggle              = false;
  deleteClicked             = false;
  loader1                   = true;
  financialyear:any         = '';
  currency:any;

  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;


  constructor(
    public challanService:ChallanService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    private _location: Location,
    private previousRouteService: PreviousRouteService,
    private notificationService: NotificationService


  ) { }

  ngOnInit(): void {

    this.getPermission();

    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.challanId = params['id'];
      }
      if (isNaN(params['month'])) {
        this.month = params['month'];
      }
      if (!isNaN(params['company'])) {
        this.company = params['company'];
      }
      if (isNaN(params['currency'])) {
        this.currency = params['currency'];
      }
      if( !isNaN(params['financialyear'])){
        this.financialyear = params['financialyear'];
      }
    });

    this.getChallanUpadteList();
  }

  // test start
  getChallanUpadteList(){
    this.loader               = true;
    this.challanUpdateList    = [];
    this.challanUpdateEmpData = [];
    this.challanService.challanUpdateList(this.challanId)
    .subscribe((res: any) => {
      this.challanUpdateEmpData = res;
      this.challanUpdateList    = res.data;
      this.index                = 0;
      this.createTableData(this.index);
      this.loader               = false;
    })
  }

  setIndex(index:any){
   this.index = index;
   this.createTableData(this.index);
  }

  createTableData(index:any){
    this.tableData = this.challanUpdateList?.[index]?.['challan_map'];
  }

  deleteChallanClick(deleteId:any){
    this.deleteChallanId = deleteId;
    this.deleteToggle   = true;
  }

  deleteChallan(){
    this.deleteClicked = true
    this.challanService.challanDelete(this.deleteChallanId,{'is_active' : false}).subscribe((res: any) => {
      this.challanUpdateList = [];
      this.getChallanUpadteList();
      this.deleteToggle     = false;
      this.deleteClicked    = false;
      this.backClicked();
    },
    (err: any) => {
      this.deleteToggle     = false;
      this.deleteClicked    = false;
    });
  }

  backClicked() {
    if(this.previousRouteService.getPreviousUrl() == '/home' || this.previousRouteService.getPreviousUrl() == '/login')
    this.router.navigate(['challan/add-challan']);
    else
    this._location.back();
  }

  submitForm(data:any){
    this.searchString     = '';
    this.filter           = false
    this.filtercomp.getBUList(this.company);
    this.buList           = data.buList;
    this.DepList          = data.DepList;
    this.DesignationList  = data.DesignationList;
    this.gradeList        = data.gradeList;
    this.BranchList       = data.BranchList;
    this.getUpdatedEmpMapList();
  }

  resetFilter(){
    this.searchString     = '';
    this.buList           = [];
    this.DepList          = [];
    this.DesignationList  = [];
    this.gradeList        = [];
    this.BranchList       = [];
    this.getUpdatedEmpMapList();
    this.filter           = false;
  }

  closeFilter(){
    this.filter   = false;
  }

  getUpdatedEmpMapList(){
    this.tableData = [];
    this.challanService.challanUpdatedEmpMapListFilter(this.challanUpdateList[this.index]?.['id'],this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res: any) => {
      this.tableData = res;
    });
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/challan/add-challan');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // for routing to edit sceen
  updateAmount(index:any){
    if(this.challanUpdateList[index]?.duplicate == true || this.challanUpdateList[index]?.is_adjusted == true){
      this.notificationService.handleErrorNotification("Challan cannot be edited as there is a dependant challan.","Error");
    }else{
    this.router.navigate(['/challan/challan-setup/edit/'+this.challanUpdateList[index]?.id+'/'+this.month+'/'+this.company+'/'+this.currency+'/'+this.financialyear])
    }
  }

}
