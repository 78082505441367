import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';
import { FormBuilder} from '@angular/forms';
import { AssetPolicyService } from 'src/app/@core/services/asset-policy.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { OrganizationService } from 'src/app/@core/services/organization.service';

@Component({
  selector: 'app-asset-filter',
  templateUrl: './asset-filter.component.html',
  styleUrls: ['./asset-filter.component.scss']
})
export class AssetFilterComponent implements OnInit {

  assetName_list:any          = []
  assetCode_list:any          = []
  assetSerial_list:any        = [];
  assetCategory_list:any      = [];
  assetSubCategory_list:any   = [];
  assetAssigned_list:any      = [];
  empDD:any                   = [];
  categoryDD:any              = []
  subCategoryDD:any           = []
  disabled                    = false
  minDate                     = new Date(2020, 0, 1);
  employeeId:any
  @Input() dataFilter:any     = "";
  @Input() filterpanel        = false
  @Input() from:any
  @Output() filterData        = new EventEmitter();
  @Output() close             = new EventEmitter();
  @Output() reset             = new EventEmitter();


  status_list:any               = [
                                    {id:"In use",name:"In use"},
                                    {id:"In Stock",name:"In Stock"},
                                    {id:"In Repair",name:"In Repair"},
                                    {id:"Missing",name:"Missing"},
                                    {id:"Retired",name:"Retired"},
                                    {id:"Damaged",name:"Damaged"},
                                  ];
  @Input() filterForm :any      = []
  constructor(
    public fb:FormBuilder,
    public assetService:AssetSettingsService,
    private employeeService:EmployeeService,
    public assetPolicyService:AssetPolicyService,
    public messageService:MessageService,
    public appService:AppService,
    private authService:AuthService,
    public OrganizationService:OrganizationService


  ) { }

  ngOnInit(): void {
    this.employeeId         = this.from=='my_asset'?this.authService.isEmployeeId:'';
    this.getCategoryList();
    if(this.from=='my_asset')
    this.getAllEmpList()
    else
    this.getEmployeeList()
    this.selectAllForDropdownItems(this.status_list);
    this.getAssetName();
    this.getAssetCode();
    this.getSerialNumber();
    this.getSubCategoryList();


  }
  getEmployeeList(){
    // this.employeeService.empDDList(true,'user__first_name').subscribe((res:any)=>{
    //   this.constructData(res);
    // })

    this.assetService.empDDList(true,'user__first_name').subscribe((res:any)=>{
      this.constructData(res.body);
    })
  }
  getAllEmpList(){
    // this.OrganizationService.empDDList('active','user__first_name').subscribe((res: any) => {
    //   this.constructData(res);
    // });

    this.assetService.getAssetProfileDropdown('assigned_by','user__first_name').subscribe((res:any)=>{
      this.constructData(res.body);
    })
  }
  constructData(res:any){
    this.empDD = res;
    for(let i=0; i< res.length; i++){
      if(this.empDD[i]['first_name'] != null)
      this.empDD[i]['name'] = this.empDD[i]['first_name']+" ";
      if(this.empDD[i]['middle_name'] != null)
       this.empDD[i]['name'] += this.empDD[i]['middle_name']+" ";
      if(this.empDD[i]['last_name'] != null)
       this.empDD[i]['name'] += this.empDD[i]['last_name'];
       this.empDD[i]['name']   +=" ("+this.empDD[i]['employee_code']+")";

    }
    // this.selectAllForDropdownItems(this.empDD);
  }
  applyfilter(){

    let name        = this.filterForm.controls.asset__name.value!=null?this.filterForm.controls.asset__name.value:'';
    let code        = this.filterForm.controls.asset__code.value!=null?this.filterForm.controls.asset__code.value:'';
    let serial_no   = this.filterForm.controls.asset__serial_number.value!=null?this.filterForm.controls.asset__serial_number.value:'';
    let subCategory = this.filterForm.controls.asset__asset_sub_category_id.value!=null?this.filterForm.controls.asset__asset_sub_category_id.value:'';
    let category    = this.filterForm.controls.asset__asset_category_id.value!=null?this.filterForm.controls.asset__asset_category_id.value:'';
    let assigned_by = this.filterForm.controls.assigned_by.value!=null?this.filterForm.controls.assigned_by.value:'';
    let assigned_on = this.filterForm.controls.assigned_on.value?this.appService.dateFormatConvert(this.filterForm.value.assigned_on):''
    let returned_on = this.filterForm.controls.returned_on.value?this.appService.dateFormatConvert(this.filterForm.value.returned_on):''
    // let assetData = [...name, ...code, ...serial_no];
    // assetData = assetData.filter((item:any) => item);

    let filteredName     = this.assetName_list.filter((item:any)=> name.includes(item.name)).map((item:any) => item.id);
    let filteredCode     = this.assetCode_list.filter((item:any)=> code.includes(item.code)).map((item:any) => item.id);
    let filteredSerialNo = this.assetCode_list.filter((item:any)=> serial_no.includes(item.serial_number)).map((item:any) => item.id);

    let assetData = [...filteredName, ...filteredCode, ...filteredSerialNo];
    assetData = assetData.filter((item:any) => item);


    if(this.from=='asset')
    this.dataFilter = 'asset_category_id__in='+category+'&asset_sub_category_id__in='+subCategory+'&assigned_by__in='+assigned_by+'&name__in='+name+'&code__in='+code+'&serial_number__in='+serial_no;
    else
    this.dataFilter = 'asset__asset_category_id__in='+category+'&asset__asset_sub_category_id__in='+subCategory+'&assigned_by__in='+assigned_by+'&assigned_on='+assigned_on+'&asset__name__in='+name+'&asset__code__in='+code+'&asset__serial_number__in='+serial_no;
    this.filterData.emit(this.dataFilter);

  }
  clearFilter(){
    this.filterForm.reset();

  }
  resetfilter(){
    this.dataFilter = ""
    this.filterForm.reset();
    this.reset.emit()
  }
  closeFilter(){
    this.filterpanel  = false
    this.close.emit();
  }
  clearcommonForm(control:any,index:any){
    let daata = this.filterForm.value[control];
    daata.splice(index, 1);
    this.filterForm.get(control)?.setValue(daata)
  }
  getAssetName(){

    if(this.from=='my_asset'){
      this.assetService.getAssetProfileDropdown('asset','name').subscribe((res:any)=>{
        this.assetName_list = res.body
        this.selectAllForDropdownItems(this.assetName_list);

       })
    }else{
      this.assetService.getInventoryDropdown('','','name',this.employeeId ).subscribe((res:any)=>{
        this.assetName_list = res.body
        this.selectAllForDropdownItems(this.assetName_list);

       })
    }

   }
   getAssetCode(){
    if(this.from=='my_asset'){
     this.assetService.getAssetProfileDropdown('asset','code').subscribe((res:any)=>{
       this.assetCode_list = res.body
       this.selectAllForDropdownItems(this.assetCode_list);

      })
    }else{
      this.assetService.getInventoryDropdown('','','code',this.employeeId ).subscribe((res:any)=>{
        this.assetCode_list = res.body
        this.selectAllForDropdownItems(this.assetCode_list);

       })
    }
   }
   getSerialNumber(){
    if(this.from=='my_asset'){
     this.assetService.getAssetProfileDropdown('asset','serial_number').subscribe((res:any)=>{
       this.assetSerial_list = res.body
       this.selectAllForDropdownItems(this.assetSerial_list);
      })
    }else{
      this.assetService.getInventoryDropdown('','','serial_number',this.employeeId ).subscribe((res:any)=>{
        this.assetSerial_list = res.body
        this.selectAllForDropdownItems(this.assetSerial_list);


       })
    }
   }
   getCategoryList(){
    if(this.from=='my_asset'){
      this.assetService.getAssetProfileDropdown('category','name').subscribe((res:any)=>{
        this.categoryDD = res.body;
        this.selectAllForDropdownItems(this.categoryDD);

      })
    }else{
      this.assetPolicyService.assetCategoryDropDown(true).subscribe((res:any)=>{
        this.categoryDD = res;
        this.selectAllForDropdownItems(this.categoryDD);

      })
   }
  }
  getSubCategoryList(){
    if(this.from=='my_asset'){
      this.assetService.getAssetProfileDropdown('subcategory','name').subscribe((res: any) => {
        this.subCategoryDD  = res.body;
        this.selectAllForDropdownItems(this.subCategoryDD);

      })
    }else{
      this.assetPolicyService.assetSubCategoryDropDown(true).subscribe((res: any) => {
        this.subCategoryDD  = res
        this.selectAllForDropdownItems(this.subCategoryDD);

      })
    }

  }
   selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  dateFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 20, 0, 1),
    new Date(new Date().getFullYear() + 20, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }
}
