import { Component, OnInit, ViewChild } from '@angular/core';
import { DepartmentService } from 'src/app/@core/services/department.service';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { FormBuilder,Validators  } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'
@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {
  Dept_is_active:any;
  deptView:any;
  Deptid:any;
  showSidePanel1=false;
  start: number = 0;
  inputFocusClass = false;
  showSidePanel =false;
  header = "Add";
  deleteClass = false;
  addEditBtn = "Save";
  deleteToggle = false;
  activeToggle = true;
  deplist:any = [];
  validationmsg = true;
  validationCount = false;
  nodata = false;
  updateClass = true;
  depGetRes:any;
  depGetResId:any ='';
  notifiaction_danger = false;
  // notification_success = false;
  notification_warning = false;
  deleteClicked = false
  dataId :any                    = "";

  departments:any = [];
  showdata:any = [];
  searchlistdata:any = [];
  alertclass:any;
  msg:any = '';
  departmentsearch:string =this.messageService.searchdisplay('department');
  loader = true;
  // public hiddenvalue:any = false;
  loader1 = false;
  submitted                      = false;
  searchKeyword="";
  statusBtn:any          = "Active";

  departUnit : any =[]
  routeComponent : any
  editflag :boolean = false
  value:any
  private subjectKeyUp = new Subject<any>();

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  deparmentlist:any       = [];
  infinityloader          = false;
  permissions:any = [];
  // unamePattern:RegExp = new RegExp( "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$");
  pattern = false;
  @ViewChild(SearchbarComponent) child:any;
  deptForm                = this.formBuilder.group({
    id: 0,
    name: ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
  })

  isLoading = false
  searchData : Subject<void> = new Subject();

  constructor(private departmentService: DepartmentService,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService : MessageService,
    public router: Router,
    private formBuilder: FormBuilder,
    public existDataService :  ExistDatasService,
    private route: ActivatedRoute
    ) {
  }
  randomClass(min:number, max:number) {
    var ints = Math.floor(Math.random() * (max - min + 1)) + min;
    return "bg"+ints;
  }
  nameChanged(arg:any) {
    if(arg.length > 100)
        this.validationCount = true;
    else
      this.validationCount = false;

    this.validationmsg = (arg.length == 0)? true:false;
  }
  /* searchbar result return */
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.departments = [];
      // this.getDepartment();
      this.searchData.next()
    }else{
      // this.departmentService.departmentGet(data).subscribe((res: any) => {
      //   this.searchKeyword  = res.name
      //   this.departments = [res];
      //   this.loader = false;
      // });
      this.searchData.next()
    }
  }
  // API for save and Edit Grade
  onSubmit() {

    this.submitted = true;
    if (this.deptForm.invalid ) {
          return;
    }
    this.updateClass = false;
    this.dataId = this.deptForm.value.id;
    if(this.dataId == 0){
      this.departmentService.departmentSave({'name':this.deptForm.value.name,}).subscribe((res: any) => {
        this.offset = 0;
        this.departments = [];
        this.getDepartment()
        this.showSidePanel    = false;

        this.msg          = "created";
        this.deptForm.reset();
      },
      (err: any) => {
        this.updateClass = true;
      });

      this.submitted        = false;

    }else{
      this.departmentService.departmentUpdate(this.dataId, {'name':this.deptForm.value.name}).subscribe((res: any) => {
        this.offset = 0;
        this.departments = [];
        this.getDepartment();
        this.msg          = "updated";
        this.showSidePanel    = false;
      },
      (err: any) => {
        this.updateClass = true;
      });

    }
  }

  ngOnInit() {
    this.route.url.subscribe((res:any)=>{
      this.routeComponent = res[0].path
    })

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.getDepartment();
    this.infinityloader = false;
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.departmentService.getdepartmentdata(this.defaultlimit,this.offset,this.activeToggle,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  searchDept(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.departments      = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  getDepartment() {
    this.loader = true;
    this.departmentService.getdepartmentdata(this.defaultlimit, this.offset,this.activeToggle,this.searchKeyword).subscribe((response: any) => {
      this.arrangeApiData(response)
    })
  }

  arrangeApiData(response:any){
    this.isLoading = false
    this.deparmentlist = response.results;
    if(this.offset ==0){
      this.departments = [];
    }
    this.listlength = response.results.length;
    if(this.listlength<=0){
      this.infinityloader =false;

      this.nodata = this.offset == 0 ? true :false;
    }
    else{
      this.nodata= false;
    }
    this.addItems(this.start, this.listlength);
    this.loader = false;

  }

  addItems(index:any, itemlist:any) {
    for (let i = index; i < itemlist; ++i) {
      this.departments.push(this.deparmentlist[i]);
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.defaultlimit;
    if(this.listlength) {
      this.getDepartment();
      this.loader = false;
      this.direction = "down";
    }else{
      this.infinityloader = false;
    }
  }
  get f() { return this.deptForm.controls; }


  /* add popup call */
  addheadercontent(header:any){
    // this.validationmsg = false;
    this.dataId           = 0;
    this.validationCount = false;
    this.updateClass = true;
    this.depGetRes = '';
    this.msg = '';
    this.depGetResId = '';
    this.header = "Add";
    this.editflag = false
    this.deleteClass = false;
    this.showSidePanel = true;
    this.addEditBtn = "Save";
    this.deptForm.enable();
    this.submitted        = false;
    this.deptForm.reset({ id: 0, name:''});
    this.child.clearBar();
  }
   deletedept(id:any){
    // this.deleteContent(id);
    // this.Deptid = id;
    this.deleteToggle = true;
    this.deptForm.value.id = id;

  }
  deleteContent(){
    this.deleteClicked = true
    this.departmentService.departmentDelete(this.deptForm.value.id).subscribe((res: any) => {
      this.msg = "deleted";
      this.alertclass ="alert-danger show";
      this.departments = [];
      this.offset = 0;
      this.getDepartment();
      this.showSidePanel = false;
      this.deleteToggle = false;
      this.deleteClicked = false
    },
    (err: any) => {
      this.deleteToggle = false
      this.showSidePanel = false
      this.deleteClicked = false
    });
  }

  /* edit popup call */
  editheadercontent(depid:any,is_active:any){
    this.showSidePanel1 =false;
    this.validationmsg = false;
    this.validationCount = false;
    this.Deptid = depid;
    this.editflag = true
    this.loader1 = true;
    this.dataId           = depid;
    this.departmentService.departmentGet(depid).subscribe((res:any)=>{
      this.deptForm.reset({ id: res.id, name:res.name});
      this.loader = false;
      this.loader1 = false;
      if(is_active==true){
        this.deptForm.enable();
        this.header  = "Edit";
        this.updateClass = is_active;
      }
      else{
        this.deptForm.disable();
        this.header  = "View";
        this.updateClass = false;
      }
    })
    this.msg = '';
    this.deleteClass = true;
    this.showSidePanel =true;
    this.addEditBtn = "Save";
  }
  /* active or delete filter */
  selectItemList(value:any){
    this.activeToggle = value;
    this.offset = 0;
    this.departments = [];
    // this.getDepartment();
    this.child.clearBar();
  }
  viewDepartment(id:any){
    if(this.permissions.v){
      this.showSidePanel1 = true;
      this.loader1 = true;
      this.departmentService.departmentGet(id).subscribe((res: any) => {
        this.deptView = res.name;
        this.Deptid = res.id;
        this.Dept_is_active = res.is_active;
        this.loader1 = false;

      })
    }
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.Deptid,
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.departUnit = res.body

      this.loader = false

      if(this.departUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
