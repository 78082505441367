import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-expense-settings-configuration',
  templateUrl: './expense-settings-configuration.component.html',
  styleUrls: ['./expense-settings-configuration.component.scss']
})
export class ExpenseSettingsConfigurationComponent implements OnInit {
  @Input()formGp        : any;
  @Input()submitted     : boolean=false;
  @Output() submitmethod= new EventEmitter();
  @Input()disabled      = false;
  @Input() editflag     : any;
  saveBtnClicked = false;

  based_on:any =[
    'Days',
    'Months'
  ];

  constructor(
    public fb:FormBuilder,
    public messageService : MessageService,
    public appService:AppService,

  ) { }

  ngOnInit(): void {
    if(this.editflag){
      if(this.formGp.value?.is_expiry_limit==true){
        this.setRequired(true,'expiry_period');    
      }
      if(this.formGp.value?.is_report_submission==true){
        this.setRequired(true,'is_report_submission');    
      }
    }
  }

  validatefn(){
    if (this.formGp.invalid) {
      return;
    }
    this.saveBtnClicked =true;
    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  setRequired(value: boolean,item:any) {
    if(value) {
        if(item=='expiry_period'){
          this.formGp.get('based_on')?.setValidators([Validators.required]);    
          this.formGp.get('expiry_period')?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(3),Validators.min(1)]);    
        }else if( item=='is_report_submission'){
          this.formGp.get('from_date')?.setValidators([Validators.required]);    
          this.formGp.get('to_date')?.setValidators([Validators.required]);       
        }
    } else {
        if(item=='expiry_period'){
        this.formGp.get('based_on')?.clearValidators();      
        this.formGp.get('expiry_period')?.clearValidators(); 
        this.formGp.get('based_on')?.reset(); 
        this.formGp.get('expiry_period')?.reset(); 
        }else if( item=='is_report_submission'){
          this.formGp.get('from_date')?.clearValidators(); 
          this.formGp.get('to_date')?.clearValidators(); 
          this.formGp.get('from_date')?.reset(); 
          this.formGp.get('to_date')?.reset(); 
        }
    }
    this.formGp.get('based_on')?.updateValueAndValidity();    
    this.formGp.get('expiry_period')?.updateValueAndValidity();    
    this.formGp.get('from_date')?.updateValueAndValidity();    
    this.formGp.get('to_date')?.updateValueAndValidity();    
  }

}
