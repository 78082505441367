<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" routerLink="/shift/shift-rotation"><i
        class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].shiftdetails.valid }">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Shift Rotation Details</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-shift-rotation-details [disabled]="disabled" [editflag]="editflag" [formGp]="shiftdetailsForm"
              (submitmethod)="submitMethodtime($event)" b></app-shift-rotation-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].shiftconfigure.valid}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Configure Rotation</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-shift-rotation-configure [disabled]="disabled" [editflag]="editflag"
              [shiftschedule]="this.shiftdetailsForm.controls.shift_schedule.value" [formGp]="shiftconfigureForm"
              (submitmethod)="submitMethod(3)"></app-shift-rotation-configure>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].applicable.valid}">
            <div class="count">3</div>
            <div class="content">
              <span>Step 3</span>
              <h6>Applicability</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
              <app-common-applicability [hint]='hint' [heading]="'Applicability'" [disabled]="disabled" [isEditForm]="editflag" [formGp]="applicableForm" (submitMethod)="submitForm()"></app-common-applicability>
          </ng-template>
        </li>

      </ul>
    </aside>
    <main class=" config-container">
      <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
