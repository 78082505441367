
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500 mb-24">Upload</p>
        </div>
        <div class="row row-24">
            <div class="col-12" >
                <div class="card card-c2 ">
                    <div class="card-body p-24 ">
                        <p class="mb-24 text-uppercase fw-500">Upload file</p>
                        <div class="row">
                            <div class="col-md-6">

                              <div class="card card-c2  bg-secondary mb-24" style="width:16.375rem;" *ngIf="fileName!=''">
                                <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                  <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                  <ng-container *ngIf="fileName !=''">
                                  <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                                    <span class="tips-helper ">{{fileName}}</span>

                                  </div>
                                  <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteFile()"></i>
                                 </ng-container>

                                </div>
                              </div>
                                <!-- <div class="card card-c2 bg-secondary  mb-24" *ngIf="fileName!=''">
                                    <div class="d-flex p-24 ">
                                        <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                        <span class="text-trim fw-500">{{fileName}}</span>
                                        <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                                        <span class="link-danger fw-500 flex-shrink-0" (click)="deleteFile()" ><i class="bi bi-trash ms-8 "></i></span>
                                    </div>
                                  </div> -->
                                <!-- <ol class="fs-16  mb-24 li-16 list-type-none " *ngIf="fileName != ''">
                                    <li  class="d-flex ">
                                        <span class="link-danger fw-500 flex-shrink-0"><i class="icon-trash me-8 " (click)="deleteFile()"></i></span>
                                        <span class="text-trim fw-500">  {{fileName}}</span>
                                        <span class="ms-auto text-accent1 fw-400">{{fileSize}}</span>
                                    </li>
                                </ol> -->
                            </div>
                        </div>
                        <label for="upload" class="btn btn-outline-primary radius-2 d-inline-flex px-5" [class.disabled]="fileName != ''">
                            <input #inputFile [disabled]="fileName != ''" type="file" name=""  (change)="onFileChange($event)" id="upload" hidden>Upload
                        </label>
                        
                    </div>
                </div>
            </div>
            <div class="col-12" >
                <div class="card card-c2 ">
                    <div class="card-body p-24 ">
                        <p class="mb-24 text-uppercase fw-500">Instructions</p>
                        <ol class="fs-14 ps-16 mb-0 li-24">
                            <li> Add/ update the relevant details in the excel template downloaded.</li>
                            <li>Save the excel Template</li>
                            <li>Upload the updated template.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto " [disabled]="fileName == ''" (click)="goNext(5)">NEXT</button>
    </footer>
    <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
                <div class="modal-header gap-16">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">{{deleteMsg}}</h4>
                </div>
                <div class="modal-body">
                        <p class="mb-0"> Please verify the template and Add/Update the data appropriately.</p>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false">Cancel</button>
                </div>
            </div>
        </div>
    </div>
