import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fw-edit-loader',
  template: `
     <div class=" config-sidebar  " >
            <ul class="steps gap-24  nav overflow-hidden">
                <li *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
                    <a ngbNavLink class="step">
                        <div class="count">
                          <ngx-skeleton-loader appearance="circle"  [theme]="{'background-color': '#EAEDEF','height': '2.5rem','width': '2.5rem'}"></ngx-skeleton-loader>
                        </div>
                        <div class="content">
                            <span>
                              <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader>
                            </span>
                            <h6>
                              <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '127px', 'width': '100%' }"></ngx-skeleton-loader>
                            </h6>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <main class="config-container p-32">
          <app-edit-loader  ></app-edit-loader>
        </main>
  `,
  styles: [
  ]
})
export class FwEditLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
