import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { MessageService } from 'src/app/message.global';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';

@Component({
  selector: 'app-confirmed-it-other-income',
  templateUrl: './confirmed-it-other-income.component.html',
  styleUrls: ['./confirmed-it-other-income.component.scss']
})
export class ConfirmedItOtherIncomeComponent implements OnInit {

  @Input()submitted:boolean                   = false;
  @Input()currency:any;

  @Output() submitmethod                      = new EventEmitter();
  @Output() onOtherincome                     = new EventEmitter<any>();

  currentDocId                                = 0;
  houseproperty_document_loader               = false;
  othersource_document_loader                 = false;
  sb_account_document_loader                  = false;
  source_80TTA_80TTB_document_loader          = false;
  lossfrom_houseproperty_document_loader      = false;
  intrestpaid_houseloan_document_loader       = false;
  pre_acquisition_document_loader             = false;
  intrest_earned_document_loader              = false;
  houseproperty_document: any                 = "";
  othersource_document: any                   = "";
  sb_account_document: any                    = "";
  source_80TTA_80TTB_document: any            = "";
  lossfrom_houseproperty_document: any        = "";
  intrestpaid_houseloan_document: any         = "";
  pre_acquisition_document: any               = "";
  intrest_earned_document: any                = "";
  currentYear:number                          = 0;
  latestProposedData:any                      = [];
  fileType: string                            = ".png,.jpeg,.jpg,.zip,.docx,.xlsx,.doc,.dotx,.pdf";
  alertShow                                   = 'show';
  totalDeclaredAmount:number                  = 0;
  filesizeError :any                          = [
                                                  {
                                                      'houseproperty_document':false,
                                                      'POTD_documothersource_documentent':false,
                                                      'sb_account_document': false,
                                                      'source_80TTA_80TTB_document':false,
                                                      'intrest_earned_document': false
                                                    }
                                                ]
  fileError:any                               = "";

  // File Upload
  modalToggle                                 = false;
  allowedExtension                            = "";
  alertMsg:any                                = '';
  alertToggle                                 = false;
  fileName                                    = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;

  constructor(
    public fb:FormBuilder,public appservice:AppService,public messageService : MessageService,private datePipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this.houseproperty_document           = window.sessionStorage.getItem('this.houseproperty_document');
    this.othersource_document             = window.sessionStorage.getItem('this.othersource_document');
    this.sb_account_document              = window.sessionStorage.getItem('this.sb_account_document');
    this.source_80TTA_80TTB_document      = window.sessionStorage.getItem('this.source_80TTA_80TTB_document');
    this.lossfrom_houseproperty_document  = window.sessionStorage.getItem('this.lossfrom_houseproperty_document');
    this.intrestpaid_houseloan_document   = window.sessionStorage.getItem('this.intrestpaid_houseloan_document');
    this.pre_acquisition_document         = window.sessionStorage.getItem('this.pre_acquisition_document');
  }

  @Input()formGp : FormGroup = this.fb.group({

    incomefrom_othersource            : [''],
    othersource_amount                : [''],
    othersource_document              : [''],
    interest_earned_sbaccount         : [''],
    sb_account_amount                 : [''],
    sb_account_document               : [''],
    source_80TTA_80TTB                : [''],
    source_80TTA_80TTB_amount         : [''],
    source_80TTA_80TTB_document       : [''],
    intrest_earned                    : [''],
    intrest_earned_amount             : [''],
    intrest_earned_document           : [''],

  });

  @Input() taxRegima: any;

  lenders() : FormArray {
    return this.formGp.get("lender") as FormArray
  }

  newLender(): FormGroup {
    if(this.formGp.value.pre_acquisition_interest == true && this.taxRegima == false){
      return this.fb.group({
        "name"              : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "pan"               : [null,[Validators.required,PanfieldValidator()]],
        "property_address"  : [null,[Validators.required,Validators.maxLength(500)]],
        "possession_date"   : [null,[Validators.required]],
        "owner_name"        : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]]
      })
    }else{
      return this.fb.group({
        "name"              : [null],
        "pan"               : [null],
        "property_address"  : [null],
        "possession_date"   : null,
        "owner_name"        : [null]
      })
    }
  }

  addLenders() {
    this.lenders().push(this.newLender());
  }

  removeLender(i:number) {
    this.lenders().removeAt(i);
  }

  removeAllLender(){
    if(this.formGp.value.lender.length > 0){
      for(let i=0; i< this.formGp.value.lender.length; i++){
        this.removeLender(i);
      }
    }
  }

  // File upload start
  getvalidsize(event:any){
    this.fileError = event;
    if(this.currentDocId == 1){
      this.errorMsgGenerator('houseproperty_document');
      this.houseproperty_document_loader = false;
    }
    if(this.currentDocId == 2){
      this.errorMsgGenerator('othersource_document');
      this.othersource_document_loader = false;
    }
    if(this.currentDocId == 3){
      this.errorMsgGenerator('sb_account_document');
      this.sb_account_document_loader = false;
    }
    if(this.currentDocId == 3){
      this.errorMsgGenerator('sb_account_document');
      this.sb_account_document_loader = false;
    }
    if(this.currentDocId == 4){
      this.errorMsgGenerator('source_80TTA_80TTB_document');
      this.source_80TTA_80TTB_document_loader = false;
    }

    if(this.currentDocId == 8){
      this.errorMsgGenerator('intrest_earned_document');
      this.intrest_earned_document_loader = false;
    }
  }
  getURL(event: any) {
    if(this.currentDocId == 1){

      this.removeErrorMsg('houseproperty_document')
      this.formGp.get('houseproperty_document')?.setValue(event);
      this.houseproperty_document_loader = false;
    }
    if(this.currentDocId == 2){

      this.removeErrorMsg('othersource_document')
      this.formGp.get('othersource_document')?.setValue(event);
      this.othersource_document_loader = false;
    }
    if(this.currentDocId == 3){

      this.removeErrorMsg('sb_account_document')
      this.formGp.get('sb_account_document')?.setValue(event);
      this.sb_account_document_loader = false;
    }
    if(this.currentDocId == 4){

      this.removeErrorMsg('source_80TTA_80TTB_document')
      this.formGp.get('source_80TTA_80TTB_document')?.setValue(event);
      this.source_80TTA_80TTB_document_loader = false;
    }

    if(this.currentDocId == 8){

      this.removeErrorMsg('intrest_earned_document')
      this.formGp.get('intrest_earned_document')?.setValue(event);
      this.intrest_earned_document_loader = false;
    }
  }

  deleteDoc(id: number) {
    if(id == 1)
      this.formGp.get('houseproperty_document')?.setValue('');
    if(id == 2)
      this.formGp.get('othersource_document')?.setValue('');
    if(id == 3)
      this.formGp.get('sb_account_document')?.setValue('');
    if(id == 4)
      this.formGp.get('source_80TTA_80TTB_document')?.setValue('');

    if(id == 8)
      this.formGp.get('intrest_earned_document')?.setValue('');
  }

  currentId(id:number){
    this.currentDocId = id;
  }

  getloader(event:any){

    if(this.currentDocId == 1){
      this.houseproperty_document_loader = event;}
    if(this.currentDocId == 2){
      this.othersource_document_loader = event;}
    if(this.currentDocId == 3){
      this.sb_account_document_loader = event;}
    if(this.currentDocId == 4){
      this.source_80TTA_80TTB_document_loader = event;}

    if(this.currentDocId == 8){
      this.intrest_earned_document_loader = event;}
  }

  getFilename(event:any){
    if(this.currentDocId == 1){
      this.houseproperty_document = event.name;
      window.sessionStorage.setItem('this.houseproperty_document',event.name );}
    if(this.currentDocId == 2){
      this.othersource_document = event.name;
      window.sessionStorage.setItem('this.othersource_document',event.name );}
    if(this.currentDocId == 3){
      this.sb_account_document = event.name;
      window.sessionStorage.setItem('this.sb_account_document',event.name );}
    if(this.currentDocId == 4){
      this.source_80TTA_80TTB_document = event.name;
      window.sessionStorage.setItem('this.source_80TTA_80TTB_document',event.name );}

    if(this.currentDocId == 8){
      this.intrest_earned_document = event.name;
      window.sessionStorage.setItem('this.intrest_earned_document',event.name );}
  }

  // File upload end

  get f() {
    return this.formGp.controls;
  }

  validateOtherIncome(btnText:any){
    if(btnText == 'skip'){
      this.skipOtherIncomeFunction();
    }else{
      this.submitted = true;
      if (this.formGp.invalid) {
        return;
      }
    }

    this.submitmethod.emit();
  }

   checkBoxFunctionTest(e:any, amountName:any, docName:any, loaderName:any) {
    var checkControlName  = e.target.name;
    var amountControlName = amountName;
    var docControlName    = docName;
    if(e.target.checked){

        this.formGp.get(checkControlName)?.setValue(true);
        this.onOtherincome.emit({check:true,checkCn:checkControlName,amountCn:amountControlName,docCn:docControlName});

        if(e.target.name == 'pre_acquisition_interest' && this.formGp.value.lender.length == 0 && !this.taxRegima)
          this.addLenders();

    }else{

      this.formGp.get(checkControlName)?.setValue(false);
      this.formGp.get(amountControlName)?.setValue('');
      this.formGp.get(docName)?.setValue('');

      if(loaderName == 'houseproperty_document_loader')
        this.houseproperty_document_loader=false;
      if(loaderName == 'othersource_document_loader')
        this.othersource_document_loader=false;
      if(loaderName == 'sb_account_document_loader')
        this.sb_account_document_loader=false;
      if(loaderName == 'source_80TTA_80TTB_document_loader')
        this.source_80TTA_80TTB_document_loader=false;

      if(loaderName == 'intrest_earned_document_loader')
        this.intrest_earned_document_loader=false;

      this.onOtherincome.emit({check:false,checkCn:checkControlName,amountCn:amountControlName,docCn:docControlName});

    }
  }

  /********* ERROR GENERATE FUNCTION ****************/
  errorMsgGenerator(control:any){
    Object.entries(this.filesizeError[0]).forEach(([key, value]) => {
      if(key == control){
      this.formGp.get(control)?.setValue('');
      this.filesizeError[key] = true;}
    })
  }

  /********* ERROR REMOVE FUNCTION ****************/
  removeErrorMsg(control: any){
    Object.entries(this.filesizeError[0]).forEach(([key, value]) => {
      if(key == control){
      this.filesizeError[key] = false;}
    })
  }

  dataClearFunction(e:any,amountControlName:any){
    this.formGp.get(amountControlName)?.setValue('');
  }

  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  /********* SKIP FUNCTION ****************/
  skipOtherIncomeFunction(){
    Object.keys(this.formGp.controls).forEach(key => {
      if(key == 'incomefrom_othersource' || key == 'interest_earned_sbaccount' || key == 'source_80TTA_80TTB' || key == 'intrest_earned'){
        this.formGp.controls[key].setValue(false);
      }
      else{
        this.formGp.controls[key].reset('');
      }
      this.clearValidationFunction(key);
    });
  }

  clearValidationFunction(key:any){
    this.formGp.get(key)?.clearValidators();
    this.formGp.get(key)?.updateValueAndValidity();
  }

  /********* TOTAL DECLARED AMOUNT FUNCTION ****************/
  totalDeclaredFunction(){
    this.totalDeclaredAmount = Number(this.formGp.value.othersource_amount) + Number(this.formGp.value.sb_account_amount) + Number(this.formGp.value.source_80TTA_80TTB_amount) + Number(this.formGp.value.intrest_earned_amount);
    return this.totalDeclaredAmount;
  }

   /********* VIEW DOCUMENT FUNCTION ****************/
   viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

}

