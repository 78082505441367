import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Form16Service {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;

  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };


  createForm16( data: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/employee-pan-list/`, data , this.httpOption);
  }

  generateForm16(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/employeeform16-generate/`, data , this.httpOption);

  }
  publishForm16(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/employeeform16-generate-publish-save/`, data , this.httpOption);

  }
  searchDrpdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/form16-dropdown/`, this.httpOption)
  }
  filterForm16(year: any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/employeeform16-landing-page/?year=${year}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }
  getMyfinanceForm16(data:any,search:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/employeeform16-download/?&search=${search}`, data , this.httpOption);

  }
  myFinanceSearchDrpdown(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/form16-dropdown/?id=${id}`, this.httpOption)
  }
  getPreviousUploadedFiledata(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/employeefomr16-uploadfile-detail/`, data , this.httpOption);
  }
  deleteUploadedData(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/employeefomr16-delete/`, data , this.httpOption);
  }
}
