<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="requestForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16 mb-16"
                    [ngClass]="{ 'is-invalid': (submitted || f.leave_period.touched || f.leave_period.dirty) &&  f.leave_period.errors}">
                    <div class="col-12 form-row">
                        <label for="" class="form-label required">Leave Period</label>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="leave_period" type="radio" id="last" class="form-check-input"
                                formControlName="leave_period" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'','last_month','sm_specific_month');setRequired(false)">
                            <label for="last" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="leave_period" type="radio" id="this" class="form-check-input"
                                formControlName="leave_period" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'','this_month','sm_specific_month');setRequired(false)">
                            <label for="this" class="form-check-label">Current month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="leave_period" type="radio" id="specific" class="form-check-input "
                                formControlName="leave_period" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'',true,'sm_specific_month');setRequired(true)">
                            <label for="specific" class="form-check-label">Specific period</label>
                        </div>

                    </div>
                    <ng-container *ngIf="requestForm.value.leave_period==true">
                      <div class="input-group form-icon icon-right"
                      [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}">
                          <input (click)="datePicker1.open()" class="form-control" (dateInput)="fromDateVal($event)" placeholder="From date" matInput
                              formControlName="from_date" [matDatepicker]="datePicker1" [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}"
                              [matDatepickerFilter]="dojFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                          <mat-datepicker  touchUi="false" #datePicker1>

                          </mat-datepicker>
                      </div>
                      <div *ngIf="(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors" class="invalid-feedback">
                        <div *ngIf="f?.from_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                    <div class="input-group form-icon icon-right"
                      [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}">
                          <input (click)="datePicker2.open()" class="form-control" placeholder="End date" matInput
                              formControlName="to_date" [matDatepicker]="datePicker2" [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}"
                              [matDatepickerFilter]="dojFilter" [min]="f?.from_date?.value"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                          <mat-datepicker  touchUi="false" #datePicker2>

                          </mat-datepicker>
                      </div>
                      <div *ngIf="(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors" class="invalid-feedback">
                        <div *ngIf="f?.to_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                    </ng-container>
                </div>
                <div *ngIf="(submitted || f.leave_period.touched || f.leave_period.dirty) && f.leave_period.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.leave_period.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                </div>
                  <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                      <label for="" class="form-label ">Request Types </label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('request types')}}"
                          formControlName="request_type" [items]="requestType" [multiple]="true"
                          groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                          bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngIf="requestForm.value.request_type?.length ">
                                  <span class="ng-value-label">{{requestForm.value.request_type?.length }} request
                                      types
                                      selected</span>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline-flex align-items-center">
                                  <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                                      [ngModelOptions]="{ standalone : true }" />

                                  <span class="">{{ (item.name?.length>15)? (item.name |
                                      slice:0:15)+'..':(item.name) }}</span>
                              </div>
                          </ng-template>
                      </ng-select>
                      <ul class="selected-values mt-16" *ngIf="requestForm.value.request_type?.length != 0">
                          <li class="ng-value" *ngFor="let item of requestForm.value.request_type,let ki =index">
                              <ng-container *ngFor="let insideitem of requestType, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['name']==item">
                                      {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                      slice:0:15)+'..':(insideitem['name']) }}</span>
                              </ng-container>
                              <span *ngIf="disabled==false" class="ng-value-icon right"
                                  (click)="clearcommonForm('requestType',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                  </div>

                  <div class="col-12 form-row">
                      <label for="" class="form-label required">Status</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('status')}}"
                          [ngClass]="{ 'is-invalid': (submitted || f.status.touched || f.status.dirty) &&  f.status.errors}"
                          formControlName="status" [items]="status" [multiple]="true" groupBy="selectedAllGroup"
                          [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                          [closeOnSelect]="false" bindValue="name" class="multiselect">
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{standalone: true}" /> Select All
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngIf="requestForm.value.status?.length ">
                                  <span class="ng-value-label">{{requestForm.value.status?.length }} status
                                      selected</span>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline-flex align-items-center">
                                  <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                                      [ngModelOptions]="{ standalone : true }" />

                                  <span class="">{{ (item.name?.length>15)? (item.name |
                                      slice:0:15)+'..':(item.name) }}</span>
                              </div>
                          </ng-template>
                      </ng-select>
                      <div *ngIf="(submitted || f.status.touched || f.status.dirty) && f.status.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.status.errors.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                      </div>
                      <ul class="selected-values mt-16" *ngIf="requestForm.value.status?.length != 0">
                          <li class="ng-value" *ngFor="let item of requestForm.value.status,let ki =index">
                              <ng-container *ngFor="let insideitem of status, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['name']==item">
                                      {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                      slice:0:15)+'..':(insideitem['name']) }}</span>
                              </ng-container>
                              <span *ngIf="disabled==false" class="ng-value-icon right"
                                  (click)="clearcommonForm('status',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                  </div>
                  </div>
                  <app-ou-filters [ouFilterForm]="ouFilterForm" [type]="'leave'" [viewDetail]="viewDetail" [isTypeRequired]="true" [validationControls]="['leave_type']"></app-ou-filters>
                </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage('reset');resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage('apply');submitRequestForm()">
                Apply
            </button>
        </footer>
    </form>
</div>
