import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadAdapterService {

  dataURL: any;

  constructor() { }

  getURL(){
    return this.dataURL;
  }

  setURL(url: any){
    this.dataURL = url;
  }

}
