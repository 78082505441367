<form [formGroup]="formGp">
  <div class="container-fluid p-24  form-container">
    <div class="row" style=" min-height: calc(100vh - (3.625rem + 4.5rem  + 3rem));">
      <div class="col-lg-3 col-md-4 col-sm-5 col-12">
        <div class="card card-c2 p-0 shadow-0 radius-4  stick-block overflow-auto">
          <div class=" card-body px-0 py-24 ">
            <ul ngbNav #nav="ngbNav"  (navChange)="onNavChange($event)"   [(activeId)]="activeTab" class=" vetical-tab tab-scroll"  formArrayName="componentformula" >
              <li [ngbNavItem]="i+1" *ngFor="let item of formGpSecond.value.paycomponents,let i = index">
                <a ngbNavLink class="tab-link " [class]="{'success':validArray[i]}"  (click)="setId(i)">
                   <span class="text-trim">{{formGpSecond.value.paycomponentsname[i]}}</span>
                </a>
                <ng-template ngbNavContent class="h-100" [formGroupName]="i">
                    <div class="card card-c2 position-relative h-100" >
                      <div class="stick-block overflow-auto px-24 pt-24 " style="padding-bottom: 4.5rem;">
                        <div class="row row-12 mb-16">
                            <div class="col-12" *ngIf="closedHint==false">
                                <div class="alert alert-warning show mb-0 py-12">

                                <p class="mb-0 fs-14">
                                    <!-- <span class="text-warning"></span> -->
                                    The amount specified using formula/fixed will be the maximum limit for FBP components
                                </p>
                                <div class="btn-close pointer" (click)="closedHint=true"></div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="fw-500 text-uppercase">Create Formula</div>
                            </div>
                            <div class="col-12">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" [attr.disabled]="disabled? true : null" name="condition" type="radio" id="formula" formControlName="condition" [value]="true" (ngModelChange)="setConditionalValue(i)" >
                                    <label for="formula" class="form-check-label">Formula</label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="You can define custom formula to calculate the yearly value of the pay component. Use conditions, payroll tags, and components to create formula. " popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" [attr.disabled]="disabled? true : null" name="condition" type="radio" id="fixed"  formControlName="condition" [value]="false" (ngModelChange)="setConditionalValue(i)">
                                    <label for="fixed" class="form-check-label"> Fixed</label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="A fixed amount can be added for a pay component instead of  creating custom formula. The amount entered here will be used as the yearly value of the pay component. " popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row row-16" *ngIf="formGp.value.componentformula[i].condition == true">
                            <div class="col-12">
                                <div #formuladiv  [class]="{ 'is-invalid': messageArray[i] != ''&& messageArray.length}" class="card card-c2 px-24 py-12 fs-14 formula-editor" id="{{i}}" (keydown)="onKeydown($event)">
                                    <ng-container *ngFor="let item of formulaarray[i],let j= index">
                                        <span *ngIf="item['string']!='if'" class="{{item['condition']}}">{{item['string']}} <i class="icon-close" (click)="deleteTag(j,item['string'],i)"></i></span>
                                        <span *ngIf="item['string']=='if'" class="condition">{{item['string']}} <i class="icon-close" (click)="deleteTag(j,item['string'],i)"></i></span>
                                    </ng-container>
                                    <span class="edit-curser">|</span>

                                </div>
                                <div class="invalid-feedback" *ngIf="messageArray[i] != ''">
                                    <div>{{messageArray[i]}}</div>
                                </div>
                            </div>
                            <!-- <div class="col-12" *ngIf="!closeAlert">
                                <div class="alert alert-warning show h-auto mb-0">
                                <p class="mb-0">
                                    <span class="text-warning"></span>
                                    Please use the keyboard to enter numeric values

                                </p>
                                <div class="btn-close pointer" (click)="closeAlert=true"></div>
                                </div>
                            </div> -->
                            <div class="col-8">
                                <div class="card card-c2">
                                    <div class="card-body p-0 h-40" >
                                    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab1" class="nav radius-4 nav-custom sticky top-48 shadow-0 p-0">
                                    <li [ngbNavItem]="1" >
                                        <a ngbNavLink class="nav-link">Condition</a>
                                        <ng-template ngbNavContent>
                                            <div class="d-flex flex-column gap-8 ">
                                                <div class="btn-editor btn-condition"  (click)="formulaCreation(i,'if',1)">If (Expressions, True, False)</div>
                                                <div class="btn-editor btn-condition"  (click)="formulaCreation(i,'&&',2)">And</div>
                                                <div class="btn-editor btn-condition"  (click)="formulaCreation(i,'==',2)">Equal</div>
                                                <div class="btn-editor btn-condition"  (click)="formulaCreation(i,'<',2)">Less Than</div>
                                                <div class="btn-editor btn-condition"  (click)="formulaCreation(i,'>',2)">Greater Than</div>
                                                <div class="btn-editor btn-condition"  (click)="formulaCreation(i,'>=',2)">Greater Than or Equal To</div>
                                                <div class="btn-editor btn-condition"  (click)="formulaCreation(i,'<=',2)">Less Than or Equal To</div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink class="nav-link">Payroll Tags</a>
                                        <ng-template ngbNavContent>
                                            <div class="row row-16">
                                                <div class="col-12">
                                                    <div class="d-flex flex-wrap gap-8 ">
                                                        <ng-container *ngFor="let itemtag of tagnewList,let m = index" >
                                                            <div *ngIf="itemtag.key !='GROSS' || this.paycomponentlist[formGp.value.componentformula[i].componentid].set_component_ctc == false" class="btn-editor btn-tags "  ngbPopover="{{itemtag.value}}" popoverClass="popover-default popover-info" (click)="formulaCreation(i,itemtag.key)" container="body" triggers="hover" >
                                                                {{itemtag.key}}
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <p class="mb-0 fs-10 tetx-light-500">Runtime Tags <i
                                                            class="bi bi-info-circle text-accent4 fs-12 ms-8 tooltip-icon"
                                                            ngbPopover="Run time tags :  If you are creating a formula with a runtime tag, the default amount for that pay component in the salary structure will be zero, and the actual amount of that pay component will be calculated during salary processing." popoverClass="popover-default popover-info"
                                                            triggers="hover" container="body"></i>
                                                        </p>
                                                </div>
                                                <div class="col-12">
                                                    <div class="d-flex flex-wrap gap-8 ">
                                                        <div  *ngFor="let itemtag of tagarrayList,let m = index" class="btn-editor btn-tags "  ngbPopover="{{itemtag.value}}" popoverClass="popover-default popover-info" (click)="formulaCreation(i,itemtag.key)" container="body" triggers="hover" >{{itemtag.key}}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-template>
                                    </li>
                                    <!-- <li [ngbNavItem]="2" >
                                        <a ngbNavLink class="nav-link">Payroll Tags</a>
                                        <ng-template ngbNavContent>
                                            <div class="d-flex flex-wrap gap-8 ">
                                                <div  *ngFor="let itemtag of tagarrayList,let m = index" class="btn-editor btn-tags "  ngbPopover="{{itemtag.value}}" popoverClass="popover-default popover-info" (click)="formulaCreation(i,itemtag.key)" container="body" triggers="hover" >{{itemtag.key}}</div>
                                            </div>
                                        </ng-template>
                                    </li> -->
                                    <li [ngbNavItem]="3" >
                                        <a ngbNavLink class="nav-link">Components <i class="bi bi-info-circle text-accent4 ms-2 fs-12 tooltip-icon"  ngbPopover="Annual values are considered for each components." popoverClass="popover-default popover-info" container="body" triggers="hover" ></i></a>
                                        <ng-template ngbNavContent>
                                        <div class="d-flex flex-wrap gap-8 ">
                                            <ng-container *ngFor="let item of paycomponentslist,let j= index">
                                                <div *ngIf="j != i" class="btn-editor btn-tags"  (click)="formulaCreation(i,paycomponentslistname[j],3)">{{paycomponentslistname[j]}}</div>
                                            </ng-container>
                                        </div>
                                        </ng-template>
                                    </li>
                                </nav>
                                </div>
                                <div class="card-body py-16 px-24"  >
                                    <div [ngbNavOutlet]="nav" ></div>
                                </div>
                                </div>
                            </div>
                            <div class="col-4 ">
                                <div class="card card-c2 p-16 ">
                                    <div class="fs-12">Operators</div>
                                    <div class="d-flex flex-wrap gap-8 mt-16">
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'(',2)">(</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,')',2)">)</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'+',2)">+</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'-',2)">-</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'*',2)">*</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'/',2)">/</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'<',2)">&lt;</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'>',2)">&gt;</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'%',2)">%</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'==',2)">==</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'&&',2)">&&</div>
                                        <div class="btn-editor btn-operator" (click)="formulaCreation(i,'||',2)">||</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-16" *ngIf="formGp.value.componentformula[i].condition == false">
                            <div class="col-12 form-row">
                                <label for="" class="form-label required">Fixed value </label>
                                <input type="text" class="form-control" [attr.disabled]="disabled? true : null" formControlName="fixedvalue" placeholder="{{this.messageService.placeholderdisp('Fixed value')}}"
                                [ngClass]="{ 'is-invalid':(formula().controls[i].get('fixedvalue')?.dirty || formula().controls[i].get('fixedvalue')?.touched) && formula().controls[i].get('fixedvalue')?.errors}">
                                <div *ngIf="(formula().controls[i].get('fixedvalue')?.dirty || formula().controls[i].get('fixedvalue')?.touched) && formula().controls[i].get('fixedvalue')?.errors" class="invalid-feedback">
                                    <div *ngIf="formula().controls[i].get('fixedvalue')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="!(formula().controls[i].get('fixedvalue')?.errors?.pattern) && formula().controls[i].get('fixedvalue')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                                </div>
                            </div>
                        </div>
                      </div>
                    <div class=" d-flex gap-12 py-16 px-24 w-100 bg-white" style="position:absolute;bottom:0;" *ngIf="formGp.value.componentformula[i].condition == true">
                        <input type="hidden" class="form-control"  formControlName="formulavalue" placeholder="{{this.messageService.placeholderdisp('Formula')}}">
                        <button class="btn btn-outline-primary btn-sm ms-auto text-uppercase" (click)="clearFormula()">Clear ALL</button>
                        <button class="btn btn-primary btn-sm btn-validate text-uppercase" [class]="{'btn-validated':validArray[i] }" (click)="validate()">Validate</button>
                        <!-- <button class="btn btn-primary btn-sm btn-validate btn-validated text-uppercase">Validate</button> -->
                    </div>

                    </div>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-12">
        <div [ngbNavOutlet]="nav" class="h-100"></div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="checkAllValid()"  (click)="validateConfigureFlow()">NEXT</button>
  </footer>
</form>
<!-- style="height: calc( 100vh - 472px);overflow-y: auto;" -->
