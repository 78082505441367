import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-pg-map-pay',
  templateUrl: './pg-map-pay.component.html',
  styleUrls: ['./pg-map-pay.component.scss']
})
export class PgMapPayComponent implements OnInit {
  showSidePanel                 = false;
  componenttypelist             = [];
  checkarray:any                = []
  checkBoxError:any             = false;
  checkBoxError1:any            = false;
  sort_order                    = false;
  istaxable_limit               = false;
  isrequired                    = true;
  taxSubmit                     = false;
  sectionSubmit                 = false;
  taxConfig:string              = "tax_config";
  incomeSection:string          = "income_section";
  taxConfigPlaceHolder          = this.messageService.selectplaceholddisplay('tax configuration');
  incomePlaceHolder             = this.messageService.selectplaceholddisplay('section');
  selectTax:string              = 'Tax Configuration';
  selectSection:string          = 'Section';
  singlecomponentid             = 0;
  iscomputation                 = false;
  alertShow                     = 'show'
  activeIncome                  = true;
  sectionIncomeDisable          = false;
  activeLimit                   = true;
  isuserDefined                 = false;
  isMapItem:any                 = false;
  taxLimit:any                  = false;
  sortError                     = 0;
  nameError                     = 0;
  component_value:any           = null;
  componentError                = false;
  isNPS                         = false
  userDefinedAdd                = true;
  isNotice                      = false;
  saveClicked                   = false;
  searchKeyword                 = ""
  loader                        = false;

  defaultCompName:any;

  validated : boolean = false
  payMapUnit : any =[];
  paymentPlaceholder:string      = this.messageService.searchdisplay('pay component');

  constructor(public formBuilder: FormBuilder,
    public router: Router,
    private pcs:PayComponentService,
    public messageService : MessageService,
    public existDataService :  ExistDatasService
    ) { }

  tax_config_disable          = false;


  @Input()formGp : FormGroup = this.formBuilder.group({
    paycomponents: [],
    paycomponentsname:[]
  });
  @Input()paycomponentlist:any = [];
  @Input() disabled=false;
  @Output()
  submitmethod = new EventEmitter();
  searchlistdata:any             = [] ;


  namePattern = "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*[A-Za-z0-9_@/#&+-\,\;\:\'\"]$";

  ngOnInit(): void {
    this.pcs.getDropdownComponent(true).subscribe((res:any)=>{
      this.componenttypelist = res;
    })
    this.searchlistdata = this.paycomponentlist;

    this.pcs.getPayComponentList(true,true).subscribe((res1:any)=>{
      let parentid;
      for(let m=0;m<this.formGp.getRawValue().paycomponents.length;m++){
        this.pcs.getSinglePayComponent(this.formGp.getRawValue().paycomponents[m]).subscribe((res: any) => {
          parentid = res['pre_component']
          if(res['pre_component'] != 0 && res['pay_group'] == true){
            for(let p=0;p<res1.length;p++){
              if(res1[p]['id'] == parentid){
                this.pcs.getSinglePayComponent(parentid).subscribe((res2: any) => {
                  res1[p]['id'] = res['id'];
                  res1[p]['name'] = res['name'];
                  res1[p]['is_active'] = res['is_active'];
                })
              }
            }
          }
        })
      }
      this.paycomponentlist = res1;

    })

  }
  userDefinedData = [{
    'set_component_ctc'       : false,
    'set_component_gross'     : false,
    'pay_component_salary'    : true,
    'set_reimbursable'        : false,
    'set_fbp'                 : false,
    'restric_fbp_amount'      : false,
    'nature_payment'          : true,
    'prorate_per_day'         : true,
    'arrear_applicable'       : true,
    'process_join_month'      : true,
    'process_settlement_month': true,
    'show_salary_slip'        : true,
    'show_salary_register'    : true,
    'salary_register_order'   : '',
    'tax_configuration'       : 'Non-Taxable',
    'roundoff'                : 'Nearest',
    'pf_contribution'         : false,
    'pt_contribution'         : false,
    'esi_contribution'        : false,
    'esi_limit'               : false,
    'pf_always'               : false,


}]
  previousResetData =[{
      'nature_payment'            : false,
      'prorate_per_day'           : false,
      'arrear_applicable'         : true,
      'process_join_month'        : false,
      'process_settlement_month'  : false,
      'pay_type'                  : false,
      'set_component_ctc'         : false,
      'set_component_gross'       : false,
      'pay_component_salary'      : false,
      'set_reimbursable'          : false,
      'set_fbp'                   : false,
      'restric_fbp_amount'        : false,
      'show_salary_slip'          : false,
      'show_salary_register'      : false,
      'tax_computation'           : false
  }]
  disableEnableData =[{
      'set_component_ctc'     : false,
      'set_component_gross'   : false,
      'pay_component_salary'  : false,
      'set_reimbursable'      : false,
      'set_fbp'               : false,
      'restric_fbp_amount'    : false,
      'tax_configuration'     : false,
      'pf_contribution'       : false,
      'pt_contribution'       : false,
      'esi_contribution'      : false,
      'esi_limit'             : false,
      'pf_always'             : false,

  }]
  NPSData = [{
    'set_component_ctc'       : false,
    'set_component_gross'     : false,
    'pay_component_salary'    : true,
    'set_reimbursable'        : false,
    'set_fbp'                 : false,
    'restric_fbp_amount'      : false,
    'nature_payment'          : false,
    'prorate_per_day'         : true,
    'arrear_applicable'       : false,
    'process_join_month'      : true,
    'process_settlement_month': true,
    'show_salary_slip'        : true,
    'show_salary_register'    : true,
    'salary_register_order'   : '',
    'tax_configuration'       : 'Non-Taxable',
    'roundoff'                : 'Nearest',
    'under_section_income'    : null,
  }]
  disableNPSData =[{
    'set_component_ctc'     : false,
    'set_component_gross'   : false,
    'pay_component_salary'  : false,
    'set_reimbursable'      : false,
    'set_fbp'               : false,
    'restric_fbp_amount'    : false,
    'arrear_applicable'     : false,
    'pf_contribution'       : false,
    'pt_contribution'       : false,
    'esi_contribution'      : false,
    'esi_limit'             : false,
    'pf_always'             : false,


  }]
  NoticeAmountData =[{

    'pay_component_salary'  : false,
    'tax_configuration'     : 'Non-Taxable',
    'under_section_income'  : null,
    'show_salary_slip'      : true,
    'show_salary_register'  : true,

    }]
  disableNoticeAmount =[{
    // 'pay_component_salary'  : true,
    'pf_contribution'       : false,
    'pt_contribution'       : false,
    'esi_contribution'      : false,
    'esi_limit'             : false,
    'pf_always'             : false,
    'tax_configuration'     : false

  }]
  taxrestricted         = false;
  taxRestrictedData     = ['GST','Salary Recovery','Other Allowance','80D: Medical Insurance Premium','Clearance Recovery']
  prorateRestrictData   = ['Reimbursement','LTA','Perquisite'];
  limitRestriction      = ['Child Education Allowance','Child Hostel Allowance'];


  SingleForm : FormGroup = this.formBuilder.group({
      name                      : ['',[Validators.required,Validators.maxLength(100)]],
      user_defined              : [''],
      pay_type                  : ['',Validators.required],
      perquisite_type           : [null],
      set_component_ctc         : [''],
      set_component_gross       : [''],
      pay_component_salary      : [''],
      set_reimbursable          : [''],
      set_fbp                   : [''],
      restric_fbp_amount        : [''],
      nature_payment            : ['',Validators.required],
      prorate_per_day           : ['',Validators.required],
      arrear_applicable         : ['',Validators.required],
      process_join_month        : ['',Validators.required],
      process_settlement_month  : ['',Validators.required],
      component_type            : [,Validators.required],
      possible_checkbox         : ['',Validators.required],
      show_salary_slip          : ['',Validators.required],
      show_salary_register      : ['',Validators.required],
      roundoff                  : [,Validators.required],
      pf_contribution           : [false],
      pt_contribution           : [false],
      esi_contribution          : [false],
      esi_limit                 : [false],
      pf_always                 : [true],
      salary_register_order     : ['',[NumberpatternValidator(),Validators.required,Validators.maxLength(4)]],
      applicable_checkbox       : ['',Validators.required],
      tax_configuration         : [,[Validators.required]],
      non_taxable_limit         : [null,[NumberpatternValidator(),Validators.maxLength(10)]],
      tax_computation           : ['',Validators.required],
      under_section_income      : []
    });

  validateConfigureFlow() {
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(this.formGp.getRawValue);
  }

  onCheckChange(event:any,name:any) {
    const formArray: FormArray = this.formGp.get('paycomponents') as FormArray;
    const formArray1: FormArray = this.formGp.get('paycomponentsname') as FormArray;

    if(event.target.checked){
      formArray.push(new FormControl(event.target.value));
      formArray1.push(new FormControl(name))
    }
    else{
      let i: number = 0;
      formArray.controls.forEach((ctrl: any) => {
        if(ctrl.value == event.target.value) {
          formArray.removeAt(i);
          formArray1.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  get f() { return this.SingleForm.controls; }

  getPayComponentDetails(componentid:any){
    this.singlecomponentid = componentid;
    let currentYear = new Date().getFullYear();
      this.pcs.getSinglePayComponent(componentid).subscribe((res: any) => {
        Object.entries(res).forEach(([key, value]) => {
          if(value== null && (key=='component_type')){
            this.SingleForm.get(key)?.setValue(0);
          }
          else if(value== null && (key=='salary_register_order')){
            this.SingleForm.get(key)?.setValue(0);
          }
          // else if(value== null && ( key=='non_taxable_limit' )){
          //   this.SingleForm.get(key)?.setValue(0);
          // }
          else if(value == null && key!='non_taxable_limit') {
            this.SingleForm.get(key)?.setValue('');
          } else {
            this.SingleForm.get(key)?.setValue(value);
          }
          this.SingleForm.get('possible_checkbox')?.setValue(0);
          this.SingleForm.get('applicable_checkbox')?.setValue(0);
          if(key=='component_type_name')
          this.defaultCompName = value;

          if(this.defaultCompName!='Reimbursement'){

            if((this.SingleForm.getRawValue().tax_configuration=='Taxable') && this.SingleForm.getRawValue().tax_configuration!=null)
            {
              this.iscomputation    = true;
              this.istaxable_limit  = false;
            }
            else if((this.SingleForm.getRawValue().tax_configuration=='Non-Taxable') && this.SingleForm.getRawValue().tax_configuration!=null){
              this.iscomputation    = false;
              this.istaxable_limit  = false;

            }
            else if((this.SingleForm.getRawValue().tax_configuration=='Partially Taxable') && this.SingleForm.getRawValue().tax_configuration!=null){

              if(this.SingleForm.getRawValue().non_taxable_limit==0)
                this.SingleForm.get('non_taxable_limit')?.setValue(null);
                this.iscomputation  = this.userDefinedAdd

              this.istaxable_limit  = this.userDefinedAdd?true:false;

            }
          }
          else{

            this.iscomputation    = true;
            this.istaxable_limit  = true;
            this.activeLimit      = true;

          }
          if(this.SingleForm.getRawValue().show_salary_register==true)
            this.sort_order = true;
          else
            this.sort_order = false;


          this.resetComponentForm(key,value);
        })
      if(res.is_active == false){
        this.SingleForm.disable();
        this.disabled   = true;
      }
      if(res.is_active == true){
        this.pcs.getSinglePayComponent(res.component_type).subscribe((res:any)=>{
        this.disableFormControl(res.predefined_component);
        let pay_type    = res.pay_type;
        if(this.taxRestrictedData.includes(res.name))
            this.taxrestricted = true;
        if(res.name=='User Defined'){
          this.isuserDefined = true;
          if(pay_type==false){
            this.disableUserDefined(this.disableEnableData[0]);
            this.userDefinedAdd = false;
          }
          else
              this.userDefinedAdd = true;
          if(this.SingleForm.getRawValue().nature_payment==false){

            this.SingleForm.get('arrear_applicable')?.setValue(false);
            this.SingleForm.get('arrear_applicable')?.disable();
          }
        }
        else if(res.name=='NPS-Salary'){
          this.isNPS = true;
          if(pay_type==false){

            this.disableUserDefined(this.disableNPSData[0]);

          }
          else
            this.isuserDefined = true;

        }
        else if(res.name=='Notice Amount'){
          this.isNotice = true;
          if(pay_type==false){

            this.disableUserDefined(this.disableNoticeAmount[0]);
          }
        }
        if(res.name=='Notice Amount' || res.name=='User Defined'){
          this.isMapItem = true;
        }

        if(res.name=='NPS-Salary' || res.name=='Gratuity'){

          this.taxLimit = true;

        }
        // if(res.name=='Child Hostel Allowance' || res.name=='Child Education Allowance'){

        //   this.pcs.getGenaralSetting(currentYear).subscribe((allowance:any)=>{

        //     if(res.name=='Child Hostel Allowance'){
        //         this.component_value = allowance[0].max_hostle_fees;
        //     }
        //     else{
        //         this.component_value = allowance[0].max_tuition_fees;
        //     }
        //   })

        // }

        if(this.isMapItem==true){
          this.taxDataSettings();

        }
        if(this.taxLimit==true){
            this.SingleForm.get('non_taxable_limit')?.setValue(null);

        }
        if(this.SingleForm.controls['under_section_income'].disabled){
          // this.activeIncome = false;
          this.sectionIncomeDisable = true;

        }
        else{
          // this.activeIncome = true;/
        }
        if(this.SingleForm.controls['non_taxable_limit'].disabled || this.limitRestriction.includes(this.defaultCompName)){
          this.activeLimit = false;
        }
        else{
          this.activeLimit = true;
        }
        this.SingleForm.get('name')?.disable();
        })
      }
    });
  }

  disableUserDefined(data:any){
    Object.entries(data).forEach(([key, value]) => {
      if(value==false){
        this.SingleForm.get(key)?.disable();
        this.tax_config_disable = true;
      }
      else{
        this.SingleForm.get(key)?.enable();
      }
    })
    this.SingleForm.get('show_salary_register')?.disable()
    this.SingleForm.get('salary_register_order')?.disable()

  }
  changeSection(){
    this.SingleForm.get('tax_configuration')?.setValue('Taxable');
    let perqType          = this.SingleForm.get('perquisite_type')?.value;
    this.taxrestricted    = false;

    if(perqType!='Stock options (non- qualified options)' && perqType!='Tax paid by employer')
    {
      this.SingleForm.get('under_section_income')?.setValue('Section 17(2)');

    }
    else if(perqType=='Stock options (non- qualified options)')
    {
      this.SingleForm.get('under_section_income')?.setValue('Section 17(3)');

    }
    else{
      this.SingleForm.get('tax_configuration')?.setValue('Non-Taxable');
      this.SingleForm.get('under_section_income')?.setValue(null);
      this.taxrestricted    = true;


    }
  }

  taxDataSettings(){
    if(this.SingleForm.value.pay_type==true){
      // this.SingleForm.get('under_section_income')?.enable();
      if(this.SingleForm.get('under_section_income')?.value=='')
      this.SingleForm.get('under_section_income')?.setValue('Section 17(1)');
    }
    else{
      this.SingleForm.get('under_section_income')?.setValue('');
    //   this.SingleForm.get('under_section_income')?.disable();
    }
  }

  checkBoxCheck(event:any,index:number){
    if(event.target.checked){
        this.checkarray.push('true');
    }
    else{
      if (this.checkarray.length == 1)
       this.checkarray=[];
      else
      this.checkarray.splice(true,1)
      if(index==5){

      }
      this.SingleForm.get('restric_fbp_amount')?.setValue(false);


    }
    this.formGp.get('possible_checkbox')?.setValue(this.checkarray.length>0?this.checkarray.length:'')
    if(this.checkarray.length <= 0)
      this.checkBoxError  = true;
    else
      this.checkBoxError  = false;

  }

  handleChange(status:any){
    this.sort_order = status;
    if(status==false)
    this.SingleForm.get('salary_register_order')?.setValue(0);
    else
    this.SingleForm.get('salary_register_order')?.setValue('');

  }

  handleChange1(status:any){
    if(this.SingleForm.getRawValue().pay_type==false && this.isuserDefined==true){
      this.resetDefaultValue(this.userDefinedData[0]);
      this.disableUserDefined(this.disableEnableData[0]);
      this.userDefinedAdd = false;
      this.taxrestricted  = false;

    }
    else if(this.SingleForm.getRawValue().pay_type==true && this.isuserDefined==true){
      this.makeDefaultSettings();
      this.userDefinedAdd = true;
      this. makeDefaultSettings();
      this.taxrestricted  = true;
    }
    if(this.SingleForm.getRawValue().pay_type==false && this.isNPS==true){

      this.resetDefaultValue(this.NPSData[0]);
      this.disableUserDefined(this.disableNPSData[0]);
      this.taxrestricted  = true;
    }
    else if(this.SingleForm.getRawValue().pay_type==true && this.isNPS==true){

      this. makeDefaultSettings();
      this.taxrestricted  = false;
      this.userDefinedAdd = false;
    }
    if(this.SingleForm.getRawValue().pay_type==false && this.isNotice==true){

      this.resetDefaultValue(this.NoticeAmountData[0]);
      this.disableUserDefined(this.disableNoticeAmount[0]);
      this.taxrestricted  = true;

    }
    else if(this.SingleForm.getRawValue().pay_type==true && this.isNotice==true){

      this. makeDefaultSettings();
      this.taxrestricted  = false;

    }
    if(this.isMapItem==true){
      this.taxDataSettings();

    }

    if(this.taxLimit==true){
      this.SingleForm.get('non_taxable_limit')?.setValue(null);

    }

    if(this.SingleForm.controls['under_section_income'].disabled){
      // this.activeIncome = false;
      this.sectionIncomeDisable = true;

    }
    // else{
    //   this.activeIncome = true;
    // }
    if(this.SingleForm.controls['non_taxable_limit'].disabled  || this.limitRestriction.includes(this.defaultCompName)){
      this.activeLimit = false;
    }
    else{
      this.activeLimit = true;
    }

    if(this.defaultCompName!='Reimbursement'  && this.defaultCompName!='LTA' && this.defaultCompName!='Leave Encashment'){

      if((this.SingleForm.getRawValue().tax_configuration=='Taxable') && this.SingleForm.value.tax_configuration!=null)
      {
        this.iscomputation    = true;
        this.istaxable_limit  = false;
      }

      else if((this.SingleForm.getRawValue().tax_configuration=='Non-Taxable') && this.SingleForm.value.tax_configuration!=null){
        this.iscomputation    = false;
        this.istaxable_limit  = false;

      }
      else if((this.SingleForm.getRawValue().tax_configuration=='Partially Taxable') && this.SingleForm.value.tax_configuration!=null){

        if(this.SingleForm.getRawValue().non_taxable_limit==0)
          this.SingleForm.get('non_taxable_limit')?.setValue(null);
        this.iscomputation    = this.userDefinedAdd
        this.istaxable_limit  = this.userDefinedAdd?true:false;

      }
    }
    else{

      this.iscomputation    = true;
      this.istaxable_limit  = true;
      this.activeLimit      = true;
      this.SingleForm.get('non_taxable_limit')?.setValue(0);
    }

    if(this.SingleForm.getRawValue().show_salary_register==true)
    this.sort_order = true;
    else
      this.sort_order = false;

    }

  checkBoxCheck1(event:any){
    if(event.target.checked){
      this.checkarray.push('true');
    }
    else{

      if (this.checkarray.length == 1)
      this.checkarray=[];
      else
      this.checkarray.splice(true,1)

    }
    this.formGp.get('applicable_checkbox')?.setValue(this.checkarray.length>0?this.checkarray.length:'')
    if(this.checkarray.length <= 0)
      this.checkBoxError  = true;
    else
      this.checkBoxError  = false;
  }

  sectionChange(){
    this.sectionSubmit = false;
    if(this.f.under_section_income.value ==null){
      this.sectionSubmit = true;
    }

  }
  /*For reimbursement when nature of paypent is variable prorate should de no and disabled */
  prorateRestrict(){

    if(this.SingleForm.getRawValue().nature_payment==true && this.prorateRestrictData.includes(this.defaultCompName)){

      this.SingleForm.get('prorate_per_day')?.setValue(false);
      this.SingleForm.get('arrear_applicable')?.setValue(false);
      this.SingleForm.get('prorate_per_day')?.enable();
      this.SingleForm.get('arrear_applicable')?.enable();

    }
    else if(this.SingleForm.getRawValue().nature_payment==false && this.prorateRestrictData.includes(this.defaultCompName)){
      this.makeDefaultSettings();
    }
    if(this.defaultCompName=='User Defined' && this.SingleForm.getRawValue().nature_payment==false){

      this.SingleForm.get('arrear_applicable')?.setValue(false);
      this.SingleForm.get('arrear_applicable')?.disable();
    }
    else if(this.defaultCompName=='User Defined' && this.SingleForm.getRawValue().nature_payment==true){

      this.SingleForm.get('arrear_applicable')?.setValue(true);
      this.SingleForm.get('arrear_applicable')?.enable();
    }
  }
  makeDefaultSettings(){
    let currentYear = new Date().getFullYear();
    this.iscomputation         = false;
    this.activeIncome          = true;
    this.sectionIncomeDisable  = false;
    this.isuserDefined         = false;
    this.istaxable_limit       = false;
    this.activeLimit           = true;
    this.userDefinedAdd        = false;
    let component_id = this.SingleForm.getRawValue().component_type;

    if(component_id!=0){

      this.pcs.getComponentDataById(component_id).subscribe((res:any)=>{
        this.isMapItem      = false;
        this.componentError = false;


        Object.entries(res).forEach(([key, value]) => {

          if(value == null && key!='component_type') {
              this.resetComponentForm(key,value);

          }
          else if(key!='name' && key!='component_type'){
            this.resetComponentForm(key,value);
          }

          if(key=='name' && (value=='Notice Amount' || value=='User Defined')){
            this.isMapItem = true;

          }
          if(key=='name' && value=='User Defined'){
            this.isuserDefined = true;
            this.userDefinedAdd = true;
          }
          else if(key=='name' && value=='NPS-Salary'){
            this.isNPS          = true;
            this.userDefinedAdd = true;

          }
          else if(key=='name' && (value=='Notice Amount'))
              this.isNotice = true;
          if(key=='name' && (value=='NPS-Salary' || value=='Gratuity')){

            this.taxLimit = true;

          }
          if(key=='name'){

            this.defaultCompName = value;
            if(this.taxRestrictedData.includes(this.defaultCompName))
            this.taxrestricted = true;
          }

          if(value=='Child Hostel Allowance' || value=='Child Education Allowance'){
            this.pcs.getGenaralSetting(currentYear).subscribe((allowance:any)=>{

              if(value=='Child Hostel Allowance'){
                this.SingleForm.get('non_taxable_limit')?.setValue(allowance[0].max_hostle_fees);
                  this.component_value = allowance[0].max_hostle_fees;

              }
              else{
                this.SingleForm.get('non_taxable_limit')?.setValue(allowance[0].max_tuition_fees);
                  this.component_value = allowance[0].max_tuition_fees;

              }
            })

          }
          else
            this.component_value = null;

        })
        this.disableFormControl(res.predefined_component);
        if(this.isMapItem==true){
          this.taxDataSettings();
        }
        if(this.taxLimit==true){
            this.SingleForm.get('non_taxable_limit')?.setValue(null);
        }
        if(this.SingleForm.controls['under_section_income'].disabled){
          // this.activeIncome = false;
        this.sectionIncomeDisable = true;

        }
        // else{
        //   this.activeIncome = true;
        // }
        if(this.SingleForm.controls['non_taxable_limit'].disabled  || this.limitRestriction.includes(this.defaultCompName)){
          this.activeLimit = false;
        }
        else{
          this.activeLimit = true;
        }
        if(this.defaultCompName!='Reimbursement'){

          if((this.SingleForm.value.tax_configuration=='Taxable') && this.SingleForm.value.tax_configuration!=null)
          {
            this.iscomputation    = true;
            this.istaxable_limit  = false;
          }
          else if((this.SingleForm.value.tax_configuration=='Non-Taxable') && this.SingleForm.value.tax_configuration!=null){
            this.iscomputation    = false;
            this.istaxable_limit  = false;
          }
          else if((this.SingleForm.value.tax_configuration=='Partially Taxable') && this.SingleForm.value.tax_configuration!=null ){
            // if(this.SingleForm.value.non_taxable_limit==0)
            //   this.SingleForm.get('non_taxable_limit')?.setValue(null);

            this.iscomputation    = this.userDefinedAdd
            this.istaxable_limit  = this.userDefinedAdd?true:false;

          }
        }
        else{

          this.iscomputation    = true;
          this.istaxable_limit  = true;
          this.activeLimit      = true;
          this.SingleForm.get('non_taxable_limit')?.setValue(0);

        }
        if(this.defaultCompName=='Perquisite'){
          this.SingleForm.get('perquisite_type')?.setValidators([Validators.required]);
        }
        else{
          this.SingleForm.get('perquisite_type')?.clearValidators();
        }
        this.SingleForm.get('perquisite_type')?.setValue(null);
        this.SingleForm.get('perquisite_type')?.updateValueAndValidity();
        if(this.SingleForm.getRawValue().show_salary_register==true)
        this.sort_order = true;
        else
          this.sort_order = false;


      this.setValidator();
      this.setValidatorIncome();
      },
      (err: any) => {
        this.componentError = true;

      }

      )
    }
    else{

      this.SingleForm.reset();
      this.SingleForm.enable();
      this.SingleForm.get('component_type')?.setValue(this.SingleForm.value.component_type);

      this.resetDefaultValue(this.previousResetData[0]);
    }


  }

  resetDefaultValue(data:any){

    Object.entries(data).forEach(([key, value]) => {
      this.SingleForm.get(key)?.setValue(value);
    })

    if(this.SingleForm.getRawValue().show_salary_register==false)
    this.SingleForm.get('salary_register_order')?.setValue(0);
    else
    this.SingleForm.get('salary_register_order')?.setValue('');


  }

  resetPreviousChanges(){

    this.SingleForm.get('nature_payment')?.setValue(false);
    this.SingleForm.get('prorate_per_day')?.setValue(false);
    this.SingleForm.get('arrear_applicable')?.setValue(false);
    this.SingleForm.get('process_join_month')?.setValue(false);
    this.SingleForm.get('process_settlement_month')?.setValue(false);
    this.SingleForm.get('pay_type')?.setValue(false);
    this.SingleForm.get('set_component_ctc')?.setValue(false);
    this.SingleForm.get('set_component_gross')?.setValue(false);
    this.SingleForm.get('pay_component_salary')?.setValue(false);
    this.SingleForm.get('set_reimbursable')?.setValue(false);
    this.SingleForm.get('set_fbp')?.setValue(false);
    this.SingleForm.get('restric_fbp_amount')?.setValue(false);

    this.SingleForm.get('show_salary_slip')?.setValue(false);
    this.SingleForm.get('show_salary_register')?.setValue(false);
    this.SingleForm.get('salary_register_order')?.setValue(0);

    this.SingleForm.get('tax_computation')?.setValue(false);

  }

  disableFormControl(data:any){

    this.SingleForm.enable();
    this.tax_config_disable = false;

    Object.entries(data).forEach(([key, value]) => {
      let final_key = key.split("_status");

      if(value==false){
        if(final_key[0]=='tax_configuration')
          this.tax_config_disable = true;

        this.SingleForm.get(final_key[0])?.disable();
      }
    })
    this.SingleForm.get('show_salary_register')?.disable()
    this.SingleForm.get('salary_register_order')?.disable()
  }

  // reset form control value
  resetComponentForm(key:any,value:any){


    if(value== null && (key=='component_type')){
      this.SingleForm.get(key)?.setValue(0);
    }
    else if(value== null && (key=='salary_register_order' && this.SingleForm.getRawValue().show_salary_register==false)){
      this.SingleForm.get(key)?.setValue(0);
    }
    // else if(value== null && ( key=='non_taxable_limit' )){
    //   this.SingleForm.get(key)?.setValue(0);
    // }
    // else if(key=='roundoff'){
    //   this.SingleForm.get(key)?.setValue(value==true?'Ceil':'None');

    // }

    else if(value == null &&  key!='non_taxable_limit') {
      this.SingleForm.get(key)?.setValue('');
    } else {
      this.SingleForm.get(key)?.setValue(value);
    }

  }

  setTaxableLimit(){
    if(this.defaultCompName!='Reimbursement'){

      if((this.SingleForm.getRawValue().tax_configuration=='Taxable'))
      {
        this.iscomputation    = true;
        this.istaxable_limit  = false;
        // this.SingleForm.get('non_taxable_limit')?.setValue(0);

      }

      else if((this.SingleForm.getRawValue().tax_configuration=='Non-Taxable')){
        this.iscomputation    = false;
        this.istaxable_limit  = false;
        // this.SingleForm.get('non_taxable_limit')?.setValue(0);

      }
      else if((this.SingleForm.getRawValue().tax_configuration=='Partially Taxable')){
        this.iscomputation    = this.userDefinedAdd
        this.istaxable_limit  = this.userDefinedAdd?true:false;
        this.SingleForm.get('non_taxable_limit')?.setValue(null);

      }
      else if(this.f.tax_configuration.value ==null){
        this.taxSubmit = true;
      }
    }
    else
    this.iscomputation    = true;

    this.setValidator();
    this.setValidatorIncome();

  }

  // paycomponent save happening in paygroup mapping
  singlePayComponentSave(){

    let resultarray:any;
    let parentcomponentres:any = [] ;
    let editcomponentres:any = [] ;
    let flag = 0;


    if(this.SingleForm.getRawValue().component_type==0)
      this.SingleForm.get('component_type')?.setValue(null);
    if(this.SingleForm.getRawValue().salary_register_order==0)
      this.SingleForm.get('salary_register_order')?.setValue(null);
    // if(this.SingleForm.getRawValue().non_taxable_limit==0)
    //   this.SingleForm.get('non_taxable_limit')?.setValue(null);

    // this.SingleForm.get('roundoff')?.setValue(this.SingleForm.getRawValue().roundoff==true?true:false);

    this.pcs.getSinglePayComponent(this.singlecomponentid).subscribe((res:any)=>{
      parentcomponentres = res;
      this.saveClicked   = true;
      let childarray = this.SingleForm.getRawValue()
      resultarray = {
                        "id":this.singlecomponentid,
                        "name":childarray['name'],
                        "component_type":childarray['component_type'],
                        "pay_type":childarray['pay_type'],
                        "perquisite_type":childarray['perquisite_type'],
                        "set_component_ctc":childarray['set_component_ctc'],
                        "set_component_gross":childarray['set_component_gross'],
                        "pay_component_salary":childarray['pay_component_salary'],
                        "set_reimbursable":childarray['set_reimbursable'],
                        "set_fbp":childarray['set_fbp'],
                        "restric_fbp_amount":childarray['restric_fbp_amount'],
                        "nature_payment":childarray['nature_payment'],
                        "prorate_per_day":childarray['prorate_per_day'],
                        "arrear_applicable":childarray['arrear_applicable'],
                        "process_join_month":childarray['process_join_month'],
                        "process_settlement_month":childarray['process_settlement_month'],
                        "show_salary_slip":childarray['show_salary_slip'],
                        "show_salary_register":childarray['show_salary_register'],
                        "salary_register_order":childarray['salary_register_order'],
                        "roundoff":childarray['roundoff'],
                        "tax_configuration":childarray['tax_configuration'],
                        "non_taxable_limit":childarray['non_taxable_limit'],
                        "tax_computation":childarray['tax_computation'],
                        "under_section_income":childarray['under_section_income'],
                        "pf_contribution"           : childarray['pf_contribution'],
                        "pt_contribution"           : childarray['pt_contribution'],
                        "esi_contribution"          : childarray['esi_contribution'],
                        "esi_limit"                 : childarray['esi_limit'],
                        "pf_always"                 : childarray['pf_always'],
                        };
      Object.entries(parentcomponentres).forEach(([key, val]) => {
        if(key != 'pre_component' &&
         key != 'pay_group'
          && key != 'created_by'
           && key != 'modified_by' && key !=
        'is_active' &&
        key != "component_type_name"){
          if(resultarray[key] != val){
            flag ++;
          }
        }
        });
        if(flag != 0){
          if(res['pay_group'] == false){
            resultarray['pay_group'] = true;
            resultarray['pre_component'] = this.singlecomponentid;

            this.pcs.createPaycomponent(resultarray).subscribe((result:any)=>{
              this.showSidePanel = false;
              const formArray: FormArray = this.formGp.get('paycomponents') as FormArray;
              const formArray1: FormArray = this.formGp.get('paycomponentsname') as FormArray;

              this.pcs.getPayComponentList(true,true).subscribe((res1:any)=>{
                for(let p=0;p<this.paycomponentlist.length;p++){
                  for(let m=0;m<this.formGp.getRawValue().paycomponents.length;m++){
                    if(this.formGp.getRawValue().paycomponents[m] == res1[p]['id'].toString() &&
                    this.paycomponentlist[p]['id'] == resultarray['pre_component']){
                      formArray.removeAt(m)
                      formArray1.removeAt(m)
                      formArray.push(new FormControl(result['id'].toString()));
                      formArray1.push(new FormControl(result['name']))
                      this.paycomponentlist[p]['id'] = result['id'];
                      this.paycomponentlist[p]['name'] = result['name'];
                      return;
                    }
                  }
                }
              })
            },
            (error)=>{
              this.resetValueForError();
              this.saveClicked = false;
            });
          }else{
            resultarray['pay_group'] = true;
            this.pcs.updatePaycomponent(this.singlecomponentid,resultarray).subscribe((res:any)=>{
              this.showSidePanel = false;
            },
            (error)=>{
              this.resetValueForError();
            });
          }

        }else{
          this.showSidePanel = false;
        }
      })
  }

  resetValueForError(){
    if(this.SingleForm.getRawValue().component_type==null)
      this.SingleForm.get('component_type')?.setValue(0);
    if(this.SingleForm.getRawValue().salary_register_order==null)
      this.SingleForm.get('salary_register_order')?.setValue(0);
    // if(this.SingleForm.getRawValue().non_taxable_limit==null)
    //   this.SingleForm.get('non_taxable_limit')?.setValue(0);
  }

  setValidator(){

    if(this.istaxable_limit==true && this.activeLimit == true){
      this.SingleForm.get('non_taxable_limit')?.setValidators([NumberpatternValidator(),Validators.required,Validators.maxLength(10)])

    }
    else {
      this.SingleForm.get('non_taxable_limit')?.clearValidators();
    }
    this.SingleForm.controls['non_taxable_limit'].updateValueAndValidity();
  }
  setValidatorIncome(){
    if(this.SingleForm.get('under_section_income')?.value!='' && this.SingleForm.get('under_section_income')?.value!=null){
      this.SingleForm.get('under_section_income')?.setValidators([Validators.required])
    }
    else {
      this.SingleForm.get('under_section_income')?.clearValidators();
      this.SingleForm.get('under_section_income')?.setValue('');
    }
    this.SingleForm.controls['under_section_income'].updateValueAndValidity();
  }

   // For validation of focusout(name)

   offKey(){
    this.validated = true
    this.existDataService.getSpecificName({"page":"paymap","field":"name","value": this.f.name.value}).subscribe((res:any)=>{
      this.payMapUnit = res.body

      if(this.payMapUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }else{
        // this.f.name.setErrors(null)
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){

    if(this.validated){

      this.existDataService.getSpecificName({"page":"paymap","field":"name","value": this.f.name.value}).subscribe((res:any)=>{
        this.payMapUnit = res.body


        if(this.payMapUnit.data ==='invalid'){
          this.f.name.setErrors({
            'exist' : true
          })
        }else{
          // this.f.name.setErrors(null)
        }
      })
    }
  }

  /* searchbar result return */
  searchresults(data:any){
    if(data == ""){
      this.searchKeyword  = "";
      this.paycomponentlist = [];
      this.getPayComponents();

    }
    else{
      this.loader           = true;
      this.pcs.getPayComponentList(true,true,this.searchKeyword).subscribe((res: any) => {
        if (res.length > 0) {
          for(let i = 0;i<res.length;i++){
            if(res[i]['id'] == data){
              this.paycomponentlist = [res[i]];
              this.loader           = false;

            }
          }
        }
    });


    }
  }
  searchpaygroup(searchKeyword:any){
    this.paycomponentlist = [];
      this.searchKeyword = searchKeyword;
      if(searchKeyword != ""){
        this.getPayComponents();
      }

  }
  getPayComponents(){
    this.loader  = true;
    this.pcs.getPayComponentList(true,true,this.searchKeyword).subscribe((res: any) => {
        // if (res.length > 0) {
          this.paycomponentlist = res;
          this.searchlistdata   = res;
          this.loader           = false;


        // }
    });
  }
  pfContribution(event:any){
    if(!(event.target.checked)){
      this.SingleForm.get('pf_always')?.setValue(false);
    }
    else{
      this.SingleForm.get('pf_always')?.setValue(true);

    }
  }
}
