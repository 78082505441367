<form [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
      <app-edit-loader *ngIf="loader1" ></app-edit-loader>
      <ng-container *ngIf="!loader1" >
        <div class="row ">
            <div class="col-12 d-flex justify-content-between flex-wrap">
                <div>
                    <p class="text-uppercase fw-500 mb-0">Assign rights</p>
                    <p class="text-helper">Assign rights to selected employees</p>
                </div>
                <a class="btn btn-outline-primary h-40 px-32 radius-2" (click)="showSidePanel = true;">Assign same rights to everyone</a>
            </div>
        </div>
        <div class="row mt-24" formArrayName="assignrights" >
            <div class="card shadow-0 border px-16 mb-16" *ngFor="let indexed of employeelistarray,let i = index" [formGroupName]="i">
                <div class="card-body px-0 py-16 d-flex align-items-center ">
                    <img *ngIf="indexed['profile_image'] !=  '' && indexed['profile_image'] !=  null" class="avatar sq-40 rounded-circle img-fluid me-8"
                        src="{{indexed['profile_image']}}">
                    <div *ngIf="indexed['profile_image'] ==  '' || indexed['profile_image'] ==  null" class="avatar sq-40 rounded-circle img-fluid me-8 {{indexed['color_code']}}" ><span>{{this.appService.getFirstChar(indexed.first_name+' '+indexed.last_name,2)}}</span></div>

                    <h6 class="fw-600 mb-0">{{indexed['first_name'] +' '+ indexed['last_name'] +" ("+indexed['employee_code']+")"}}</h6>
                    <button class="btn-reset ms-auto text-light-600" (click)="removeAssignRight(i)"><i class="icon-trash"></i></button>
                </div>
                <input formControlName="employee" type="hidden" class="form-control" value="">
                <div class="card-body px-0 py-24 bt">
                    <div class="row row-24">
                        <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label">Company</label>
                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('company')}}"
                                    formControlName="company"
                                    [items]="companylist"
                                    [multiple]="true"
                                    groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    (change)="companyChangeFunction(formGp.value.assignrights[i].company,i)"
                                    >
                                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                      </ng-template>

                                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                          <div class="ng-value" *ngIf="formGp.value.assignrights[i].company.length">
                                              <span class="ng-value-label">{{formGp.value.assignrights[i].company.length}} Company selected.</span>
                                          </div>
                                      </ng-template>
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-item$="item$"
                                            let-index="index">
                                            <div class="d-inline-flex align-items-center"><input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img class="avatar-sm img-fluid rounded-circle sq-24" *ngIf="item.logo !=  null && item.logo !=  ''"src="{{item.logo}}">
                                              <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>

                                              <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}</span>
                                              </div>

                                        </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.assignrights[i].company.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.assignrights[i].company, let m =index">
                                        <ng-container *ngFor="let insideitem of companylist, let k =index">
                                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                             <!-- {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }} -->
                                             <div class="d-inline-flex align-items-center">
                                              <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}"   >
                                              <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{k%5}} me-1" >
                                                  <span>
                                                  {{this.appService.getFirstChar(insideitem.name,1)}}</span>
                                              </span>
                                              <span class=""> {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }} </span>
                                           </div>
                                          </span>
                                        </ng-container>
                                        <span class="ng-value-icon right" aria-hidden="true" (click)="clear('company',m,i)"><i class="icon-close"></i></span>
                                   </li>
                                </ul>
                        </div>


                        <div class="col-12 col-md-6 form-row">
                            <label for="" class="form-label">Business unit</label>
                            <ng-select [readonly]="formGp.value.assignrights[i].company?.length == 0 ? true : false"
                                placeholder="{{this.messageService.selectplaceholddisplay('business unit')}}"
                                formControlName="businessunit"
                                [items]="buArray[i]"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="id"
                                class="multiselect"
                                >
                                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }}
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                      <div class="ng-value" *ngIf="formGp.value.assignrights[i].businessunit.length">
                                          <span class="ng-value-label">{{formGp.value.assignrights[i].businessunit.length}} Business Unit selected.</span>
                                      </div>
                                  </ng-template>

                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                  </ng-template>
                            </ng-select>
                            <ul class="selected-values my-16" *ngIf="formGp.value.assignrights[i].businessunit?.length != 0">
                                <li class="ng-value" *ngFor="let item of formGp.value.assignrights[i].businessunit, let m =index">
                                    <ng-container *ngFor="let insideitem of buArray[i], let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                    </ng-container>
                                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('businessunit',m,i)"><i class="icon-close"></i></span>
                               </li>
                            </ul>
                        </div>


                        <div class="col-12 col-md-6 form-row">
                            <label for="" class="form-label">Department</label>
                            <ng-select
                                placeholder="{{this.messageService.selectplaceholddisplay('department')}}"
                                formControlName="department"
                                [items]="departmentlist"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="id"
                                class="multiselect"
                                >
                                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }}
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                      <div class="ng-value" *ngIf="formGp.value.assignrights[i].department.length">
                                          <span class="ng-value-label">{{formGp.value.assignrights[i].department.length}} Department selected.</span>
                                      </div>
                                  </ng-template>

                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                </ng-template>
                            </ng-select>
                            <ul class="selected-values my-16" *ngIf="formGp.value.assignrights[i].department.length != 0">
                                <li class="ng-value" *ngFor="let item of formGp.value.assignrights[i].department, let m =index">
                                    <ng-container *ngFor="let insideitem of departmentlist, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                    </ng-container>
                                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('department',m,i)"><i class="icon-close"></i></span>
                               </li>
                            </ul>
                        </div>



                        <div class="col-12 col-md-6 form-row">
                            <label for="" class="form-label">Designation</label>
                            <ng-select
                                placeholder="{{this.messageService.selectplaceholddisplay('designation')}}"
                                formControlName="designation"
                                [items]="designationlist"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="id"
                                class="multiselect"
                                >
                                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }}
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                      <div class="ng-value" *ngIf="formGp.value.assignrights[i].designation.length">
                                          <span class="ng-value-label">{{formGp.value.assignrights[i].designation.length}} Designation selected.</span>
                                      </div>
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                  </ng-template>
                            </ng-select>
                            <ul class="selected-values my-16" *ngIf="formGp.value.assignrights[i].designation !=  0">
                                <li class="ng-value" *ngFor="let item of formGp.value.assignrights[i].designation, let m =index">
                                    <ng-container *ngFor="let insideitem of designationlist, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                    </ng-container>
                                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('designation',m,i)"><i class="icon-close"></i></span>
                               </li>
                            </ul>
                        </div>

                        <div class="col-12 col-md-6 form-row">
                            <label for="" class="form-label">Branch</label>
                            <ng-select
                                placeholder="{{this.messageService.selectplaceholddisplay('branch')}}"
                                formControlName="branch"
                                [items]="branchlist"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="id"
                                class="multiselect"
                                >
                                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }}
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                      <div class="ng-value" *ngIf="formGp.value.assignrights[i].branch.length">
                                          <span class="ng-value-label">{{formGp.value.assignrights[i].branch.length}} Branch selected.</span>
                                      </div>
                                  </ng-template>

                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                  </ng-template>
                            </ng-select>
                            <ul class="selected-values my-16" *ngIf="formGp.value.assignrights[i].branch.length != 0">
                                <li class="ng-value" *ngFor="let item of formGp.value.assignrights[i].branch, let m =index">
                                    <ng-container *ngFor="let insideitem of branchlist, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                    </ng-container>
                                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('branch',m,i)"><i class="icon-close"></i></span>
                               </li>
                            </ul>
                        </div>


                        <div class="col-12 col-md-6 form-row">
                            <label for="" class="form-label">City</label>
                            <ng-select
                                placeholder="{{this.messageService.selectplaceholddisplay('city')}}"
                                formControlName="city"
                                [items]="citylist"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="id"
                                class="multiselect"
                                >
                                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }}
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                      <div class="ng-value" *ngIf="formGp.value.assignrights[i].city.length">
                                          <span class="ng-value-label">{{formGp.value.assignrights[i].city.length}} City selected.</span>
                                      </div>
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                  </ng-template>
                            </ng-select>
                            <ul class="selected-values my-16" *ngIf="formGp.value.assignrights[i].city.length != 0">
                                <li class="ng-value" *ngFor="let item of formGp.value.assignrights[i].city, let m =index">
                                    <ng-container *ngFor="let insideitem of citylist, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                    </ng-container>
                                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('city',m,i)"><i class="icon-close"></i></span>
                               </li>
                            </ul>
                        </div>


                        <div class="col-12 col-md-6 form-row">
                            <label for="" class="form-label">Grade</label>
                            <ng-select
                                placeholder="{{this.messageService.selectplaceholddisplay('grade')}}"
                                formControlName="grade"
                                [items]="gradelist"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="id"
                                class="multiselect"
                                >
                                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }}
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                      <div class="ng-value" *ngIf="formGp.value.assignrights[i].grade.length">
                                          <span class="ng-value-label">{{formGp.value.assignrights[i].grade.length}} Grade selected.</span>
                                      </div>
                                  </ng-template>

                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                  </ng-template>
                            </ng-select>
                            <ul class="selected-values my-16" *ngIf="formGp.value.assignrights[i].grade.length != 0">
                                <li class="ng-value" *ngFor="let item of formGp.value.assignrights[i].grade , let m =index">
                                    <ng-container *ngFor="let insideitem of gradelist, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                    </ng-container>
                                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('grade',m,i)"><i class="icon-close"></i></span>
                               </li>
                            </ul>
                        </div>

                    </div>

                </div>
            </div>
        </div>
      </ng-container>
    </div>
    <footer class="submit-footer">
        <button type="submit" class="btn btn-primary btn-sm ms-auto" [disabled]="checkformvalid() || saveClicked" (click)="validateAssignRightFlow()" >SAVE</button>
    </footer>
  </form>
  <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>

        <form  class="side-panel-container" [formGroup]="myAllForm">
        <header class="side-panel-head">
            <h5>Assign same rights to everyone</h5>
            <a class="toggle-panel" (click)="showSidePanel = false;"><i class="icon-close"></i></a>
        </header>
            <div class="side-panel-body" style="overflow-y: auto;">
                <div class="container-fluid p-32">
                    <div  role="alert" class="alert alert-warning show"><p class="mb-0"><span class="text-warning">Note:</span> By selecting the rights here, you are going to give same rights to everyone below </p></div>

                    <p class="text-uppercase fw-500">Selected Employees</p>
                    <div class="row row-24">
                        <div class="col-12">

                            <ul class="selected-values ">

                                <li class="ng-value" *ngFor="let item of employeelistarray,let j = index">
                                    <span class="ng-value-label">
                                        <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null" class="avatar sq-24 rounded-circle me-8 {{item.color_code}} " ><span>{{this.appService.getFirstChar(item.fullname,1)}}</span></div>
                                        <img class="avatar sq-24 rounded-circle img-fluid me-8" *ngIf="item.profile_image !=  '' && item.profile_image !=  null" [src]="item.profile_image">
                                        {{item.first_name}} {{item.middle_name == null ? '' : item.middle_name == "" ? '' : item.middle_name}} {{item.last_name}} ({{item.employee_code}})</span>
                                </li>
                            </ul>
                        </div>

                   </div>
                   <p class="text-uppercase fw-500 mt-24">Rights</p>
                   <div class="row row-24">
                    <div class="col-12 col-md-6 form-row">
                            <label for="" class="form-label">Company</label>
                            <ng-select
                                placeholder="{{this.messageService.selectplaceholddisplay('company')}}"
                                formControlName="companys"
                                [items]="companylist"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="name"
                                [closeOnSelect]="false"
                                bindValue="id"
                                class="multiselect"
                                (change)="companyChangeFunction(myAllForm.value.companys,'commonForm')"
                                >
                                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <!-- <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }} -->
                                      <div class="d-inline-flex align-items-center"><input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                        <img class="avatar-sm img-fluid rounded-circle sq-24"  *ngIf="item.logo !=  null && item.logo !=  ''"src="{{item.logo}}">
                                        <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>

                                        <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}</span>
                                        </div>
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                      <div class="ng-value" *ngIf="myAllForm.value.companys.length">
                                          <span class="ng-value-label">{{myAllForm.value.companys.length}} Company selected.</span>
                                      </div>
                                  </ng-template>
                                 <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <ng-container *ngFor="let item of items, let j =index">
                                        <div class="ng-value" *ngIf="j < 2">
                                            <span class="ng-value-label"> {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                        </div>
                                    </ng-container>
                                    <div class="ng-value" *ngIf="items.length > 2">
                                        <span class="ng-value-label">{{items.length - 2}} more...</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                </ng-template>
                            </ng-select>
                            <ul class="selected-values my-16" *ngIf="myAllForm.value.companys.length != 0">
                                <li class="ng-value" *ngFor="let item of myAllForm.value.companys, let m =index">
                                    <ng-container *ngFor="let insideitem of companylist, let k =index">

                                      <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                        <div class="d-inline-flex align-items-center">
                                          <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                                          <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{k%5}} me-1" >
                                              <span>
                                              {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                                          </span>
                                          <span class=""> {{ (insideitem['company_name'].length>15)? (insideitem['company_name'] | slice:0:15)+'..':(insideitem['company_name'])  }} </span>
                                       </div>
                                      </span>
                                    <!-- <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                         {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span> -->
                                        </ng-container>
                                    <span class="ng-value-icon right" aria-hidden="true" (click)="clear('companys',m,0)"><i class="icon-close"></i></span>

                               </li>
                            </ul>
                    </div>

                    <div class="col-12 col-md-6 form-row">
                        <label for="" class="form-label">Business unit</label>
                        <ng-select [readonly]="myAllForm.value.companys?.length == 0 ? true : false"
                            placeholder="{{this.messageService.selectplaceholddisplay('business unit')}}"
                            formControlName="businessunits"
                            [items]="bulist"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            bindValue="id"
                            class="multiselect"
                            >
                              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                              </ng-template>
                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                  {{ item.name }}
                              </ng-template>
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                  <div class="ng-value" *ngIf="myAllForm.value.businessunits.length">
                                      <span class="ng-value-label">{{myAllForm.value.businessunits.length}} Business Unit selected.</span>
                                  </div>
                              </ng-template>

                            <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-item$="item$"
                                let-index="index">
                                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values my-16" *ngIf="myAllForm.value.businessunits?.length != 0">
                            <li class="ng-value" *ngFor="let item of myAllForm.value.businessunits, let m =index">
                                <ng-container *ngFor="let insideitem of bulist, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                     {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true" (click)="clear('businessunits',m,0)"><i class="icon-close"></i></span>

                           </li>
                        </ul>
                    </div>

                    <div class="col-12 col-md-6 form-row">
                        <label for="" class="form-label">Department</label>
                        <ng-select
                            placeholder="{{this.messageService.selectplaceholddisplay('department')}}"
                            formControlName="departments"
                            [items]="departmentlist"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            bindValue="id"
                            class="multiselect"
                            >
                              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                              </ng-template>
                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                  {{ item.name }}
                              </ng-template>
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                  <div class="ng-value" *ngIf="myAllForm.value.departments.length">
                                      <span class="ng-value-label">{{myAllForm.value.departments.length}} Department selected.</span>
                                  </div>
                              </ng-template>

                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                              </ng-template>
                        </ng-select>
                        <ul class="selected-values my-16" *ngIf="myAllForm.value.departments.length != 0">
                            <li class="ng-value" *ngFor="let item of myAllForm.value.departments, let m =index">
                                <ng-container *ngFor="let insideitem of departmentlist, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                     {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true" (click)="clear('departments',m,0)"><i class="icon-close"></i></span>

                           </li>
                        </ul>
                    </div>

                    <div class="col-12 col-md-6 form-row">
                        <label for="" class="form-label">Designation</label>
                        <ng-select
                            placeholder="{{this.messageService.selectplaceholddisplay('designation')}}"
                            formControlName="designations"
                            [items]="designationlist"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            bindValue="id"
                            class="multiselect"
                            >
                              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                              </ng-template>
                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                  {{ item.name }}
                              </ng-template>
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                  <div class="ng-value" *ngIf="myAllForm.value.designations.length">
                                      <span class="ng-value-label">{{myAllForm.value.designations.length}} Designation selected.</span>
                                  </div>
                              </ng-template>

                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                              </ng-template>
                        </ng-select>
                        <ul class="selected-values my-16" *ngIf="myAllForm.value.designations.length != 0">
                            <li class="ng-value" *ngFor="let item of myAllForm.value.designations , let m =index">
                                <ng-container *ngFor="let insideitem of designationlist, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                     {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true" (click)="clear('designations',m,0)"><i class="icon-close"></i></span>

                           </li>
                        </ul>
                    </div>

                    <div class="col-12 col-md-6 form-row">
                        <label for="" class="form-label">Branch</label>
                        <ng-select
                            placeholder="{{this.messageService.selectplaceholddisplay('branch')}}"
                            formControlName="branchs"
                            [items]="branchlist"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            bindValue="id"
                            class="multiselect"
                            >
                              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                              </ng-template>
                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                  {{ item.name }}
                              </ng-template>
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                  <div class="ng-value" *ngIf="myAllForm.value.branchs.length">
                                      <span class="ng-value-label">{{myAllForm.value.branchs.length}} Branch selected.</span>
                                  </div>
                              </ng-template>

                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                              </ng-template>
                        </ng-select>
                        <ul class="selected-values my-16" *ngIf="myAllForm.value.branchs.length != 0">
                            <li class="ng-value" *ngFor="let item of myAllForm.value.branchs, let m =index">
                                <ng-container *ngFor="let insideitem of branchlist, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                     {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true" (click)="clear('branchs',m,0)"><i class="icon-close"></i></span>

                           </li>
                        </ul>
                    </div>

                    <div class="col-12 col-md-6 form-row">
                        <label for="" class="form-label">City</label>
                        <ng-select
                            placeholder="{{this.messageService.selectplaceholddisplay('city')}}"
                            formControlName="citys"
                            [items]="citylist"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            bindValue="id"
                            class="multiselect"
                            >
                              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                              </ng-template>
                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                  {{ item.name }}
                              </ng-template>
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                  <div class="ng-value" *ngIf="myAllForm.value.citys.length">
                                      <span class="ng-value-label">{{myAllForm.value.citys.length}} City selected.</span>
                                  </div>
                              </ng-template>

                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                              </ng-template>
                          </ng-select>
                        <ul class="selected-values my-16" *ngIf="myAllForm.value.citys.length != 0">
                            <li class="ng-value" *ngFor="let item of myAllForm.value.citys, let m =index">
                                <ng-container *ngFor="let insideitem of citylist, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                     {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true" (click)="clear('citys',m,0)"><i class="icon-close"></i></span>

                           </li>
                        </ul>
                    </div>

                    <div class="col-12 col-md-6 form-row">
                        <label for="" class="form-label">Grade</label>
                        <ng-select
                            placeholder="{{this.messageService.selectplaceholddisplay('grade')}}"
                            formControlName="grades"
                            [items]="gradelist"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            bindValue="id"
                            class="multiselect">
                              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                              </ng-template>
                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                  {{ item.name }}
                              </ng-template>
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                  <div class="ng-value" *ngIf="myAllForm.value.grades.length">
                                      <span class="ng-value-label">{{myAllForm.value.grades.length}} Grade selected.</span>
                                  </div>
                              </ng-template>

                              <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                              </ng-template>
                        </ng-select>
                        <ul class="selected-values my-16" *ngIf="myAllForm.value.grades.length != 0">
                            <li class="ng-value" *ngFor="let item of myAllForm.value.grades, let m =index">
                                <ng-container *ngFor="let insideitem of gradelist, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                     {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true" (click)="clear('grades',m,0)"><i class="icon-close"></i></span>

                           </li>
                        </ul>
                    </div>
                </div>

                </div>
            </div>
            <footer class="side-panel-footer">
                <button type="submit" [disabled]="checkcommonFormValid()" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="validateAllAssignRightFlow()">Done</button>
            </footer>
        </form>

  </div>
