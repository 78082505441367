
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { MessageService } from 'src/app/message.global';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pg-create-formula',
  templateUrl: './pg-create-formula.component.html',
  styleUrls: ['./pg-create-formula.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PgCreateFormulaComponent implements OnInit{

  closedHint=false
  showfixedtab                    = false;
  activeTab                       = 1;
  isChecked: boolean              = true;
  paycomponentslist:any           = [];
  paycomponentslistname:any       = [];
  paycomponentslistnamedup:any       = [];
  formulaarray:any                = [];
  formulaarraylist:any            = [];
  paycomponentlist:any            = [];
  defaultpaycomponentlist:any            = [];
  activeTab1                       = 1;
  currentid:any ='';
  listForm:any;
  conditionarray:any              = ['if','||','&&','==','<','>','<=','>=']
  tagarray:any                    = ['CTC','DOBM','DOBY','DOCCY','DOCM','DOJ','DOJCM','DOJCY','LOPM','PDM', 'GROSS','NHM','OTM','OTPM','PLM','PRDM','ULM','WDM','WOM','TWH','TDM'
//'SAF','SAR','MINWAG'
]
tagnewList:any=[ {key:'DOBM',value:'Date of birth month'},{key:'DOCM',value:'Date of confirmation month'}, {key:'DOJ',value:'Date of joining'},{key:'CTC',value:'Cost to Company'},{key:'GROSS',value:'Gross salary'},]
  tagarrayList:any  = [

                      {key:'DOBY',value:'Date of birth year (Age)'},
                      {key:'DOCCY',value:'Completed years since date of confirmation'},
                      {key:'DOJCM',value:'Completed months since date of joining'},
                      {key:'DOJCY',value:'Completed years since date of joining'},
                      // {key:'MINWAG',value:'Minimum wages'},
                      {key:'LOPM',value:'Loss of pay days for the month(absent days)'},
                      {key:'NHM',value:'National Holidays of the month'},
                      {key:'OTM',value:'Overtime hours for the Month'},
                      {key:'OTPM',value:'Overtime hours for previous month'},
                      {key:'PDM',value:'Paid days for the month'},
                      {key:'PLM',value:'Approved paid leaves availed in the month'},
                      {key:'PRDM',value:'Present days for the month'},
                      // {key:'SAF',value:'Shift allowance factor for the month'},
                      // {key:'SAR',value:'Shift allowance rate for the month'},
                      {key:'ULM',value:'Approved unpaid leaves availed in the month'},
                      {key:'WDM',value:'Working days for the month'},
                      {key:'WOM',value:'Week offs entitled for the month'},
                      {key:'TWH',value:'Total Working Hours'},
                      {key:'TDM',value:'Total days for the month'}

                    ]
  operationarray:any              = ['(',')','+','-','*','/',',','|','&','=','<','>','%','>'];
  combinationarray:any            = [];
  paygroupid:number               = 0;
  checkvalue:number               = 0;
  validArray:any= [];
  messageArray:any= [];
  closeAlert = false
  constructor(public formBuilder: FormBuilder,
    public router: Router,
    private pcs:PayComponentService,
    private route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    public messageService : MessageService,
    ) { }

  @Input()formGp : FormGroup = this.formBuilder.group({
    componentformula: []
  });
  @Input()formGpSecond : FormGroup = this.formBuilder.group({
    paycomponents: [],
    paycomponentsname:[]
  });
  @Input() disabled=false;
  @Output()
  submitmethod = new EventEmitter();

  get f() { return this.formGp.controls; }

  getPayComponentList(){
    // this.pcs.getPayComponentList(true,true).subscribe((res: any) => {
    //   if (res.length > 0) {
    //     this.paycomponentlist = res;
    //   }
    // });
    this.formGpSecond.value.paycomponents.forEach((value:any, key:any)=>{
      this.pcs.getComponentDetailCTC(value).subscribe((res: any) => {
            this.paycomponentlist[value] = res;
      });
    })

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.paygroupid = +params['id'];
      }
    })

    this.getPayComponentList()
    this.setFormulas();
    this.checkcommonFormValid();
    this.currentid = 0;
  }

  setFormulas(){
    this.currentid = 0;
    this.paycomponentslist = this.formGpSecond.value.paycomponents;
    this.paycomponentslistname = this.formGpSecond.value.paycomponentsname;
    for(let j=0;j<this.formGp.value.componentformula.length;j++){
      if(this.paycomponentslist.indexOf(this.formGp.value.componentformula[j]['componentid'].toString()) == -1
      && (this.formGp.value.componentformula[j].hasOwnProperty('componentname'))
      && (this.formGp.value.componentformula[j].hasOwnProperty('componentname'))){
        this.formula().removeAt(j)
      }
    }
    for(var i=0;i<this.paycomponentslist.length;i++){
      let flag  = true;
      for(let j=0;j<this.formGp.value.componentformula.length;j++){
        if(this.formGp.value.componentformula[j]['componentid'] == this.paycomponentslist[i]){
          flag = false;
          break;
        }
      }
      this.formulaarray[i]    = [];
      if(flag){
        this.formula().push(this.newformula(this.paycomponentslist[i],this.paycomponentslistname[i]));
      }

    }

    this.paycomponentslist = [];
    this.paycomponentslistname = [];
    for(let j=0;j<this.formGp.value.componentformula.length;j++){
      this.paycomponentslist.push(j);
      if(this.formGp.value.componentformula[j].hasOwnProperty('componentname')){

        this.paycomponentslistname.push(this.formGp.value.componentformula[j]['componentname']);
        this.paycomponentslistnamedup.push(this.formGp.value.componentformula[j]['componentname']?.replace(/\s/g, ""));
      }

    }
    this.combinationarray            = [...this.conditionarray,...this.tagarray,...this.paycomponentslistnamedup];
    for(let j=0;j<this.formGp.value.componentformula.length;j++){
      if(this.formGp.value.componentformula[j]['condition'] == true){
      var temp:any ="";
      let valuestemp = [];
      if(this.formGp.value.componentformula[j]['formulavalue'].constructor === Array) {
        temp = this.formGp.value.componentformula[j]['formulavalue'];
      }else{
        temp = (this.formGp.value.componentformula[j]['formulavalue'] != "")?JSON.parse(this.formGp.value.componentformula[j]['formulavalue']):[];
      }
      for(let y=0;y<temp.length;y++){
        if(this.conditionarray.indexOf(temp[y])!= -1){
          valuestemp.push({'string':temp[y],'condition':'tags'})
        }else{

          for(var k=0;k<temp[y].length;k++){
            var checkstring = temp[y][k];
            if(checkstring.match("^[.0-9]*$")){
              if(valuestemp[valuestemp.length-1] != undefined && valuestemp[valuestemp.length-1].string.match("^[.0-9]*$"))
                valuestemp[valuestemp.length-1].string += checkstring
              else
                valuestemp.push({'string':checkstring,'condition':'number'})
            }else if(this.operationarray.indexOf(checkstring) != -1){
              if(valuestemp[valuestemp.length-1] != undefined && valuestemp[valuestemp.length-1].string == "&" && checkstring == '&')
                valuestemp[valuestemp.length-1].string += checkstring
              else  if(valuestemp[valuestemp.length-1] != undefined && valuestemp[valuestemp.length-1].string == ">" && checkstring == '=')
                valuestemp[valuestemp.length-1].string += checkstring
              else  if(valuestemp[valuestemp.length-1] != undefined && valuestemp[valuestemp.length-1].string == "<" && checkstring == '=')
                valuestemp[valuestemp.length-1].string += checkstring
              else  if(valuestemp[valuestemp.length-1] != undefined && valuestemp[valuestemp.length-1].string == "=" && checkstring == '=')
                valuestemp[valuestemp.length-1].string += checkstring
              else  if(valuestemp[valuestemp.length-1] != undefined && valuestemp[valuestemp.length-1].string == "|" && checkstring == '|')
                valuestemp[valuestemp.length-1].string += checkstring
              else
                valuestemp.push({'string':checkstring,'condition':'operator'})
            }else if(this.paycomponentslistname.indexOf(checkstring) != -1){
              valuestemp.push({'string':checkstring,'condition':'component'})
            }else{
              for(var f=(k+1);f<temp[y].length;f++){
                checkstring = checkstring.concat(temp[y][f])
                var next = ((temp[y].length-1) == f)?f:(f+1);
                if(this.operationarray.indexOf(temp[y][next]) != -1 ||
                (temp[y].length-1) == f){
                  for(let m of this.combinationarray){
                    if(checkstring.includes(m)){
                      if(this.paycomponentslistnamedup.indexOf(checkstring) != -1)
                        valuestemp.push({'string':checkstring?.replace(/\s/g, ""),'condition':'component'})
                      else
                        valuestemp.push({'string':checkstring?.replace(/\s/g, ""),'condition':'tags'})
                      k = f;
                      f = temp[y].length;
                      break;
                    }
                  }
                }

              }
            }
          }
        }
      }
        this.formulaarray[j] = valuestemp;

      }else{
        this.formulaarray[j] = [];
      }
    }

  }

  setId(id:any){
    this.validate();
    if(this.validArray[this.currentid] == true)
      this.currentid = id;
  }
  onNavChange(changeEvent: NgbNavChangeEvent){
    this.validate();
    this.messageArray[changeEvent.activeId] = ""

    if(this.validArray[this.currentid] == false)
        changeEvent.preventDefault();
  }
  formula() : FormArray {
    return this.formGp.get("componentformula") as FormArray
  }

  newformula(id:any,name:any): FormGroup {
    return this.formBuilder.group({
      componentid:id,
      componentname: name,
      formulavalue: "",
      fixedvalue: ['', [digitdecimalValidator(),Validators.maxLength(10),]],
      condition: [true, [Validators.required]],
    })
  }

  formulaCreation(id:any,formulastring:any,condition:any = 0){
    this.validArray[id] = false
    if(this.disabled == false){
      const myForm = (<FormArray>this.formGp.get("componentformula"));
      let cond = 'tags'
      if(condition == 2)
        cond = "operator"
      else if(condition == 3)
        cond = "component"
      this.listForm = myForm.at(id);
      this.formulaarray[id].push({'string':formulastring.replace(/\s/g, ""),'condition':cond})
      this.setFormulaValue(this.currentid)
    }
  }

  validateConfigureFlow() {
    this.setFormulaArray()
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(this.formGp.value);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event:any) {
    this.validArray[this.currentid] = false
    if(this.disabled == false){
      let Pattern = "^[.0-9]*?$";
      let stringPattern = "^[a-zA-Z]+$"
      if(this.formGp.value.componentformula[this.currentid].condition == true){
        if(event.key=="Backspace"){
          this.messageArray[this.currentid] = "";
          if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1]
            && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string.match(Pattern)){//If number
            this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string = this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string.substring(0, this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string.length - 1);
            if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string == '')
              this.formulaarray[this.currentid].splice(this.formulaarray[this.currentid].length-1,1)
          } else {
            this.formulaarray[this.currentid].splice(this.formulaarray[this.currentid].length-1,1)
          }
        } else if(event.key.match(Pattern)) {
            if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1] && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string.match(Pattern)){
              this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string += event.key
          } else
            this.formulaarray[this.currentid].push({'string':event.key,'condition':'number'})
        } else if((event.keyCode >= 65 && event.keyCode <= 90) ){
            if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1] && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string.match(stringPattern)){
                this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string += event.key
            } else
                this.formulaarray[this.currentid].push({'string':event.key,'condition':'tags'})
        } else if(this.operationarray.indexOf(event.key) != -1){
          if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1] != undefined && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string == "&" && event.key == '&')
                this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string += event.key
              else  if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1] != undefined && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string == ">" && event.key == '=')
                this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string += event.key
              else  if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1] != undefined && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string == "<" && event.key == '=')
                this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string += event.key
              else  if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1] != undefined && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string == "=" && event.key == '=')
                this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string += event.key
              else  if(this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1] != undefined && this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string == "|" && event.key == '|')
                this.formulaarray[this.currentid][this.formulaarray[this.currentid].length-1].string += event.key
              else
            this.formulaarray[this.currentid].push({'string':event.key,'condition':'operator'})
        }

      }
    }
    this.setFormulaValue(this.currentid)
  }
  setFormulaValue(k:any){
    if(this.disabled ==false){
      var inputstringarray:any = [];
      if(this.formGp.value.componentformula[k].condition == true && this.formulaarray[k].length != 0){
        let valuearray = this.formulaarray[k];
        for(let m = 0;m<this.formulaarray[k].length;m++){
          if(valuearray[m]['string']!=''){
            if(m == 0){
              inputstringarray.push(valuearray[m]['string'])
            } else{
              inputstringarray[inputstringarray.length-1] += valuearray[m]['string']
            }
          }
        }
      }
      (<FormArray>this.formGp.get("componentformula")).at(k).get('formulavalue')?.setValue(inputstringarray)
    }
  }

  checkcommonFormValid(){

    let flag = 0;
    const myForm =(<FormArray>this.formGp.get("componentformula"))
    for(let k=0;k<this.formGp.value.componentformula.length;k++){
      this.listForm = myForm.at(k);
      this.setFormulaValue(k)
      if(this.formGp.value.componentformula[k].condition == true && (this.formGp.value.componentformula[k].formulavalue == "" || this.formGp.value.componentformula[k].formulavalue == " " && !this.validArray[this.currentid])){
        flag++;
      }else if(this.formGp.value.componentformula[k].condition == false && this.formGp.value.componentformula[k].fixedvalue == "" ){
        flag++;
        this.validArray[k] = false;
      }
      else if(this.formGp.value.componentformula[k].condition == false && this.formGp.value.componentformula[k].fixedvalue != "" ){
        this.validArray[k] = true;
        if(this.formGp.invalid){
          flag++;
        }
      } if(this.messageArray[k] != undefined && this.messageArray[k]!="" ){
        flag++;
      }
      this.currentid =k;
      this.validate()
    }

    if(flag != 0){
      return true;
    }else
      return false;
  }
  deleteTag(index:any,value:any,i:any){
      this.formulaarray[i].splice(index,1)
      this.setFormulaValue(this.currentid)
  }
  setConditionalValue(index:any){
    this.validArray[index] = false
    this.formulaarray[index] = [];
    (<FormArray>this.formGp.get("componentformula")).at(index).get('fixedvalue')?.setValue("");
    this.setFormulaValue(index)
    this.messageArray[index] = ""
  }
  message = "";

    validate() {
      let formula  = this.formGp.value.componentformula[this.currentid].formulavalue[0];

      if(formula!= undefined){
        if(!this.areBracketsBalanced()){
            this.message = "Brackets are not balanced"
        }else if(!this.evaluvateTags()){

        } else if(formula.includes("if") && this.evaluvateIf()==false){

        }
         else if(!formula.includes("if") && !this.evaluvateFunction()){
            this.message = "Formula is not valid"
        } else {
            this.message = "";
            this.validArray[this.currentid] = true;
            this.messageArray[this.currentid] = "";
        }

        if(this.message !=""){
          this.validArray[this.currentid] = false;
          this.messageArray[this.currentid] = this.message;
        }
      } else {
        this.message = "This field is required."
        this.validArray[this.currentid] = false;
        this.messageArray[this.currentid] = this.message;
      }
      // this.formGp.value.componentformula[this.currentid].formulaArray = this.formulaarray[this.currentid];
      this.setFormulaArray()
    }
    setFormulaArray(){
      this.formGp.value.componentformula[0].formulaArray = this.formulaarray[0];
      this.formGp.value.componentformula[this.currentid].formulaArray = this.formulaarray[this.currentid];

      this._cdr.detectChanges();
    }
    findClosingParen(text:any, openPos:number) {
        let closePos = openPos;
        let counter = 1;
        while (counter > 0) {
            let c = text[++closePos].string;
            if (c == '(') {
                counter++;
            }
            else if (c == ')') {
                counter--;
            }
        }
        return closePos;
    }
    evaluvateIfExp(string:any):boolean{
        let ret = true;
        let counter = string.length;
        let arr = []
        let pos = 0;
        while (pos <= string.length-1) {
            if(string[pos].string == 'if'){
                arr.push({'string':"x",'condition':'tags'})
                if(string[pos+1] && string[pos+1]?.string == '('){

                    let closingPos = this.findClosingParen(string,pos+1);
                    let subString = string.slice(pos+2,closingPos);

                    if(JSON.stringify(subString).includes("if")){
                        ret = this.evaluvateIfExp(subString)
                    } else if(this.checkIfParams(subString) == false){
                       ret = false;
                    }
                    pos = closingPos;
                } else {
                    this.message ="Immediately after 'if' an '(' is needed."
                    ret = false;
                }
            } else(
                arr.push(string[pos])
            )
            pos++;
        }
        if(JSON.stringify(arr).includes('"string":","') && this.checkIfParams(arr) == false){
            ret = false;
        } else{
            let formula = ""
            arr.forEach( (value:any, key:any)=>{
                if(value.condition == 'tags'|| value.condition == 'component'){
                    formula+='x';
                } else
                    formula+=value.string
            })
            if(this.checkIfValidFunction(formula) == false){
                ret = false;
                this.message = "Formula is not valid";
            }
        }
        return ret;
    }
    checkIfParams(string:any){
        let commaCnt = 0;
        let arr:any = []
        string.forEach((value:any, key:any)=>{
            if(key == 0){
                if(value.condition=='tags'|| value.condition == 'component'){
                    arr.push("x");
                } else {
                    arr.push(value.string);
                }
            }else if(value.string == ',' && key !=0){
                commaCnt++
                arr.push("");
            } else {
                if(value.condition=='tags'|| value.condition == 'component'){
                    arr[arr.length-1]+="x";
                } else {
                    arr[arr.length-1]+=value.string
                }
            }

        })

        let ret=true;
        if(commaCnt!=2){
            this.message = "Number of parameters for function `IF` should be 3";
            ret = false;
        } else if(arr.length != 3){
            this.message = "Number of parameters for function `IF` should be 3 ";
            ret = false;
        } else {
            arr.forEach((v:any,k:any)=>{
                if(this.checkIfValidFunction(v) == false){
                    ret=false;
                    this.message = "One or more condition inside if is not Valid";
                }
            })
        }
        return  ret
    }
    evaluvateIf(){
       return  this.evaluvateIfExp(this.formulaarray[this.currentid])
    }
    evaluvateTags(){
        let ret = true
        this.formulaarray[this.currentid].forEach( (value:any, key:any)=>{
            if(value.condition == 'tags' || value.condition == 'component'){
                if(this.combinationarray.indexOf(value.string) == -1){
                    this.message = value.string +" is not a valid tag"
                    ret = false;
                }
            }
        })
        return ret;
    }
    evaluvateFunction(){
        let formulaString  = this.formGp.value.componentformula[this.currentid].formulavalue[0];
        let formula = '';
        let ret:any = true;
        this.formulaarray[this.currentid].forEach( (value:any, key:any)=>{
            if(value.condition == 'tags'|| value.condition == 'component'){
                formula+='x';
            } else if((this.operationarray.includes(value.string) || this.conditionarray.includes(value.string)) && !['+','-','*','/','(',')','%'].includes(value.string) && !formulaString.includes("if")){
                ret = false;
            } else
                formula+=value.string
        })

        if(ret == false)
            return false;
        else
            return this.checkIfValidFunction(formula)

    }
    checkIfValidFunction(formula:any){
        var x= 5;
        try {
            var ret = eval(formula);
            return true;
        } catch(e) {
            return false;
        }
    }
    areBracketsBalanced(){
        let expr = this.formGp.value.componentformula[this.currentid].formulavalue[0];
        return this.checkBrackets(expr);
    }
    checkBrackets(expr:any){
        let stack = [];
        if(expr.length > 0){
          for(let i = 0; i < expr.length; i++)
          {
              let x = expr[i];
              if (x == '(' )
              {
                  // Push the element in the stack
                  stack.push(x);
                  continue;
              }  else if(x == ')'){
                  let check;
                  if(stack.length>0)
                      check = stack.pop();
                  else
                      return false
              }
          }
        }
        return (stack.length == 0);
    }
    clearFormula(){
      this.formulaarray[this.currentid] = []
      this.validArray[this.currentid] = false
      this.messageArray[this.currentid] = "";
      this.setFormulaValue(this.currentid)
    }
    checkAllValid(){
      let ret = false;
      for(let k=0;k<this.formGp.value.componentformula.length;k++){
        if(this.formGp.value.componentformula[k].condition == false && (this.formGp.value.componentformula[k].fixedvalue == "" ||this.formula().controls[k].get('fixedvalue')?.errors) ){
          this.validArray[k] = false;
        }
        else if(this.formGp.value.componentformula[k].condition == false && this.formGp.value.componentformula[k].fixedvalue != "" ){
          this.validArray[k] = true;
        }
        if(!this.validArray[k])
          ret = true
      }
      return ret
    }

}
