import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infinity-loader',
  templateUrl: './infinity-loader.component.html',
  styleUrls: ['./infinity-loader.component.scss']
})
export class InfinityLoaderComponent implements OnInit {
  infinityloader = true;
  constructor() { }

  ngOnInit(): void {
  }

}
