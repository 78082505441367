<div class="side-panel" style="--sidepanel-width: 27rem" [class.side-pane-active]="filterPanel === true" [formGroup]="filterForm">
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="closeFilter()">
        <i class="icon-close-lg fs-16"></i>
      </a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <label for="name" class="form-label text-uppercase fw-500 ">Loan type</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan type')}}" formControlName="loan_type" class="form-ngselect">
              <ng-option *ngFor="let type of loan_type_list" [value]="type?.loan_type">{{type?.loan_type}}</ng-option>
            </ng-select>
          </div>
          <div class="col-12">
            <label for="name" class="form-label text-uppercase fw-500 ">Loan code</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan code')}}" formControlName="loan_code" class="form-ngselect">
              <ng-option *ngFor="let type of loan_code_list" [value]="type?.loan_code">{{type?.loan_code}}</ng-option>
            </ng-select>
          </div>
          <div class="col-12">
            <label for="name" class="form-label text-uppercase fw-500 ">Loan status</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan status')}}" formControlName="status" class="form-ngselect">
              <ng-option *ngFor="let type of status" [value]="type?.id">{{type?.name}}</ng-option>
            </ng-select>
          </div>
          <ng-container *ngIf="from == 'loanProfile'">
            <div class="col-12">
              <label for="name" class="form-label text-uppercase fw-500">Requested on</label>
              <div class="input-group form-icon icon-right">
                  <input (click)="datePicker1.open()" class="form-control" placeholder="From date" matInput
                      formControlName="requested_from_date" [matDatepicker]="datePicker1"
                      [matDatepickerFilter]="dojFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                  <mat-datepicker  touchUi="false" #datePicker1>
                  </mat-datepicker>
              </div>
              <div class="input-group form-icon icon-right mt-2">
                  <input (click)="datePicker2.open()" class="form-control" placeholder="End date" matInput [min]="f?.requested_from_date.value"
                      formControlName="requested_to_date" [matDatepicker]="datePicker2"
                      [matDatepickerFilter]="dojFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker  touchUi="false" #datePicker2>
                  </mat-datepicker>
              </div>
            </div>
            <div class="col-12">
              <label for="name" class="form-label text-uppercase fw-500">Closed on</label>
              <div class="input-group form-icon icon-right">
                  <input (click)="datePicker3.open()" class="form-control" placeholder="From date" matInput
                      formControlName="closed_from_date" [matDatepicker]="datePicker3"
                      [matDatepickerFilter]="dojFilter"><i class="icon-calendar pointer" (click)="datePicker3.open()"></i>
                  <mat-datepicker  touchUi="false" #datePicker3>
                  </mat-datepicker>
              </div>
              <div class="input-group form-icon icon-right mt-2">
                  <input (click)="datePicker4.open()" class="form-control" placeholder="End date" matInput [min]="f?.closed_from_date.value"
                      formControlName="closed_to_date" [matDatepicker]="datePicker4"
                      [matDatepickerFilter]="dojFilter"><i class="icon-calendar pointer" (click)="datePicker4.open()"></i>
                  <mat-datepicker  touchUi="false" #datePicker4>
                  </mat-datepicker>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="from == 'loanHR'">
            <app-ou-filters [ouFilterForm]="OuFilterForm" [viewDetail]="filterPanel"></app-ou-filters>
          </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetFilter()">
        Reset
      </button>
      <button type="submit" (click)="applyFilter()" class="ms-auto btn btn-primary text-uppercase btn-sm">
        Apply
      </button>
    </footer>
  </div>
</div>
