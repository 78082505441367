import { Component, OnInit ,ViewChild} from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { MessageService } from 'src/app/message.global';
import { Router,ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { FormBuilder } from '@angular/forms';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-final-settlement',
  templateUrl: './final-settlement.component.html',
  styleUrls: ['./final-settlement.component.scss']
})
export class FinalSettlementComponent implements OnInit {

  settlementList:any          = [];
  loader                      = false;
  nodata                      = false;
  showFinalSettlementView     = false;
  activeToggle                = '';
  searchlistdata:any          = [] ;
  filter                      = false;
  from                        = 'FandF';
  searchKeyword               = "";
  permissions:any             = [];
  currentStatus:any;

  // filterdata:any;
  filterdata:any              =  this.finalSettlementService.FILTERDATA;

  filterForm                  = this.formBuilder.group({
                                  company               : '',
                                  bu                    : '',
                                  department            : '',
                                  designation           : '',
                                  grade                 : '',
                                  emp_status            : '',
                                  emp_type              : '',
                                  branch                : '',
                                  repomanager           :'',
                                  funmanager            :'',
                                  sortby                : ['All'],
                                  exit_date_type        : null,
                                  from_date             : '',
                                  to_date               : ''
                                },
                                { validators: CustomDateValidators.fromToDate('from_date', 'to_date') }
                                );

  searchkey = this.messageService.searchdisplay('Employee');


   /********INFINITY SCROLL VARIABLES  ****/
   offset                     = 0;
   listlength                 = 0;
   direction                  = '';
   defaultlimit:any           = 20;
   settlementDetailList:any   = [];
   infinityloader             = false;
   employeeId:any;
   @ViewChild(SearchbarComponent) child:any;

  constructor(
    public finalSettlementService: FinalSettlementService,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public appservice:AppService,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {

    let arrFilter: any = [];
    let arrList        = [];
    let arrData        = [];
    if(this.filterdata){
      arrData = this.filterdata.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })

      let ordering:any = this.appservice.memOrdering1();
      this.filterForm.reset(this.appservice.memFilterArray4(arrFilter,ordering));
    }

    this.finalSettlementList(this.activeToggle);
    this.getPermission();

  }
  getPermission(){
    this.permissions = this.appservice.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  finalSettlementList(status:any){
    this.activeToggle    = status;
    this.loader          = true;

    this.finalSettlementService.finalSettlementList(status,this.defaultlimit,this.offset,this.searchKeyword,this.filterdata)
    .subscribe((res: any) => {
      this.sLoading = false
      this.listlength = res?.results?.length;
      if(this.offset == 0){
        this.settlementList = [];
      }

      if (res?.results?.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.settlementDetailList = res?.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
    })
    this.filter = false;

}

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.finalSettlementList(this.activeToggle);
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.settlementList.push(this.settlementDetailList[i]);
    }
  }

  searchresults(event:any){
    this.loader = true;
    if(event == ""){
      this.searchKeyword   = "";
      this.offset          = 0;
      this.settlementList  = [];
      this.finalSettlementList(this.activeToggle);
    }else{
      this.finalSettlementService.getsingleEmployee(event).subscribe((res: any) => {
        this.settlementList = res;
        this.loader         = false;
      });
    }
  }
  selectItemList(value:any){

    this.loader             = true;
    this.activeToggle       = value;
    this.offset             = 0;
    this.settlementList     = [];
    this.child.clearBar();
  }
  sLoading = false
  searchemp(searchKeyword:any){
    this.sLoading = true
    this.loader         = true;
    this.offset         = 0;
    this.settlementList      = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.finalSettlementList(this.activeToggle);
    }
  }


  showFinalSettlement(empId:any,status:any){
    this.currentStatus            = status;
    this.employeeId               = empId;
    if((status=='Completed' || status=='Pending') && this.permissions.v)
    this.showFinalSettlementView  = true
    else{

      if(this.permissions.a || this.permissions.e)
      this.router.navigate(['/final-settlement/final-settlement-setup/'+empId]);
    }
  }

  closeExpansion(){
    this.showFinalSettlementView  = false;

  }

  filterclick(){
    this.filter = true;
  }
  closefn(daata:any){
    this.filter = daata;
  }
  resultfilterfn(data:any){
    this.filterdata  = data;
    this.finalSettlementService.setFilterData(data)
    this.offset     = 0;
    this.child.clearBar();
  }
}
