<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Generate challan</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success': challanForm['controls'].define.valid}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Define Challan Details</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-challan-define-details [formGp]="defineForm" [currency]="currency" (submitmethod)="submitOne()"
              [editFlag]="editFlag" [financialyear]="financialyear" (empList)="emplistFunction()"
              (validStepper)="validStepper($event)"></app-challan-define-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step"
            [class]="{'success': selectedList1?.length > 0 && (challanForm['controls'].define.valid)}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Map Employees</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-challan-map-employees [formGp]="defineForm" [empMapList]="empMapList" [company]="company"
              [currency]="currency" [selectedList1]="selectedList1" (submitmethod)="submitTwo($event)" [items]="items"
              (filterMethod)="filterApply($event)" [loader1]="loader1" [excessFlag]="excessFlag"
              [excessAmount]="excessAmount" (countSelect)="countSelect()" [monthId]="monthId" [updateFlag]="updateFlag"
              [updateId]="editId"></app-challan-map-employees>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="step"
            [class]="{'success': (challanForm['controls'].define.valid) && (selectedList1?.length > 0) && (amountExceed)}">
            <div class="count">3</div>
            <div class="content">
              <span>Step 3</span>
              <h6>Summary</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-challan-summary [formGp]="defineForm" [selectedEmpData]="selectedEmpData" [currency]="currency"
              (submitmethod)="submitForm()"></app-challan-summary>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
