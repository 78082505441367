<div class="container-fluid p-32 flex-center" style="min-height:calc(100vh - 3rem)">
    <div class="card card-c2" style="width:26.25rem">
        <div class="card-body p-32">
            <img src="assets\images\bg\change-password.png" class="img-fluid d-flex mx-auto pb-32" alt="">
            <h3 class="fw-600 mb-24">Change password</h3>
            <p class="fs-12 mb-24 text-light-400">Set a password of at least 8 characters with letters and
                numbers</p>
            <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" autocomplete="off">


                <div class="row row-24">
                    <div class="col-12">
                        <div class=" form-row">
                            <label for="currentPass" class="form-label required">Current Password </label>
                            <div class="form-icon icon-right"
                                [ngClass]="{ 'is-invalid': (f.currentPass.dirty || f.currentPass.touched || submitted)  &&  (f.currentPass.errors || inValidInput)}">

                                <input class="form-control " [type]="show_current ? 'text' : 'password'" name=""
                                    id="currentPass" placeholder="{{this.messageService.placeholderdisp('Current Password')}}" autocomplete="off"
                                    formControlName="currentPass"
                                    [ngClass]="{ 'is-invalid': (f.currentPass.dirty || f.currentPass.touched || submitted)  &&  (f.currentPass.errors || inValidInput)}">
                                <i *ngIf="f.currentPass.value" class="pointer  {{viewicon_current}}" (click)="getpassword('current')"></i>

                            </div>
                            <div *ngIf="(f.currentPass.dirty || f.currentPass.touched || submitted) && (f.currentPass.errors || inValidInput)"
                                class="invalid-feedback">
                                <div *ngIf="f.currentPass.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="inValidInput">{{this.messageService.wrongLoginData('wrong_password')}}</div>

                            </div>

                        </div>
                    </div>
                    <div class="col-12">
                        <div class=" form-row">
                            <label for="password" class="form-label required">Enter New Password </label>
                            <div class="form-icon icon-right"
                                [ngClass]="{ 'is-invalid': (f.password.dirty || f.password.touched || submitted)  &&  (f.password.errors || lastPassword==1)}">
                                <input  popoverClass="error-popover" placement="right" [ngbPopover]="popContent" class="form-control" [type]="show_pass? 'text' : 'password'" name=""
                                    id="password" placeholder="{{this.messageService.placeholderdisp('New Password')}}" autocomplete="off"
                                    formControlName="password"
                                    [ngClass]="{ 'is-invalid': (f.password.dirty || f.password.touched || submitted)  &&  (f.password.errors || lastPassword==1)}">
                                <i *ngIf="f.password.value" class="pointer  {{viewicon_password}}" (click)="getpassword('pass')"></i>

                            </div>
                            <div *ngIf="(f.password.dirty || f.password.touched || submitted) && (f.password.errors || lastPassword==1)"
                                class="invalid-feedback">
                                <div *ngIf="f.password.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="lastPassword==1">{{errorMsg}}</div>

                            </div>

                        </div>
                        <ng-template #popContent >
                            <ul class="error-list">
                                <li class=""  [ngClass]="(f.password.errors?.uppercase || f.password.errors?.required ) ? 'invalid' : 'valid'">Uppercase letters</li>
                                <li class="" [ngClass]="(f.password.errors?.lowercase || f.password.errors?.required ) ? 'invalid' : 'valid'">Lowercase letters</li>
                                <li class=""  [ngClass]="(f.password.errors?.numberPattern || f.password.errors?.required ) ? 'invalid' : 'valid'">Numbers</li>
                                <li class=""  [ngClass]="(f.password.errors?.special || f.password.errors?.required ) ? 'invalid' : 'valid'">Special characters</li>
                                <li class=""  [ngClass]="(f.password.errors?.minlength || f.password.errors?.required ) ? 'invalid' : 'valid'">Password must be at least 8 characters’ long</li>
                                <li class=""  [ngClass]="(f.password.errors?.invalidformat || f.password.errors?.required ) ? 'invalid' : 'valid'">Password should not contain your first name, last name and company name</li>
                            </ul>
                        </ng-template>

                    </div>
                    <div class="col-12">
                        <div class=" form-row">
                            <label for="confirmPassword" class="form-label required ">Re-enter New Password
                            </label>
                            <div class="form-icon icon-right"
                                [ngClass]="{ 'is-invalid': (f.confirmPassword.dirty || f.confirmPassword.touched || submitted)  &&  (f.confirmPassword.errors || changePasswordForm.errors)}">

                                <input class="form-control" [type]="show_confirm? 'text' : 'password'" name=""
                                    id="confirmPassword" placeholder="Re-enter password"
                                    formControlName="confirmPassword"
                                    [ngClass]="{ 'is-invalid': (f.confirmPassword.dirty || f.confirmPassword.touched || submitted)  &&  (f.confirmPassword.errors || changePasswordForm.errors)}">
                                <i *ngIf="f.confirmPassword.value" class="pointer  {{viewicon_confirm}}" (click)="getpassword('confirm')"></i>

                            </div>
                            <div *ngIf="(f.confirmPassword.dirty || f.confirmPassword.touched) && (f.confirmPassword.errors || changePasswordForm.errors || submitted)"
                                class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors?.required">{{this.messageService.validationDisplay('required')}}
                                </div>
                                <div *ngIf="(changePasswordForm.errors)">Password & Confirm Password should be
                                    same.</div>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 mt-40">
                        <button class="btn btn-primary w-100" [disabled]="changePasswordForm.invalid || completed">Change Password</button>
                    </div>
                </div>


            </form>
        </div>
    </div>
    <!-- <div class="row row-24">
        <div class="col-lg-4 col-md-4 mx-auto">

        </div>
    </div> -->
</div>
