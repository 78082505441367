import { Component, OnInit, ViewChild } from '@angular/core';
import { BranchService } from 'src/app/@core/services/branch.service';
import { FormBuilder, Validators  } from '@angular/forms';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout'
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  filterpanel = false;
  branchid:any;
  branchname:any;
  country_name:any;
  branchView:any;
  branchStatus:any;
  state_name:any;
  showSidePanel1=false;
  deleteClicked = false
  branches:any[]=[];
  searchlistdata:any = [];
  countrylistdata:any = [];
  statelistdata:any = [];
  showSidePanel =false;
  deleteButtonEnable = false;
  editId = 0;
  submitted = false;
  deleteToggle = false;
  activeToggle = true;
  notifiaction_danger = false;
  notification_warning = false;
  updateClass = true;
  nodata = false;
  alertclass:any;
  msg = '';
  header = '';
  addEditBtn = "Add";
  departmentsearch:string = this.messageService.searchdisplay('branch');
  checkoutForm = this.formBuilder.group({
      id: 0,
      name: ['', [Validators.required,UnamepatternValidator(), Validators.maxLength(100)]],
      country: ['', Validators.required],
      state: ['', Validators.required]
  });

  branchFilter = this.formBuilder.group({

    status:  [null],
    country: [""],
    state: [""]
  });

  countryval ="";
  stateval =""
  loader =  true;
  CountrySelect = false;
  loader1 = false;
  statusBtn:any = "Active";

  validated : boolean = false
  branchUnit : any
  editflag :boolean = false
  value:any
  private subjectKeyUp = new Subject<any>();
  routeComponent : any

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  branchDetailsList:any     = [];
  infinityloader          = false;
  permissions:any = [];
  searchKeyword="";
  // unamePattern =  "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";
  @ViewChild(SearchbarComponent) child:any;
  isLoading = false
  searchData : Subject<void> = new Subject();
  constructor(
    private branchService:BranchService,
    private formBuilder: FormBuilder,
    public appService: AppService,
    public breakpointObserver: BreakpointObserver,
    public messageService : MessageService,
    private route: ActivatedRoute,
    public existDataService :  ExistDatasService
  ) { }
  randomClass(min:number, max:number) {
    var ints = Math.floor(Math.random() * (max - min + 1)) + min;
    return "bg"+ints;
  }
  searchBranch(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.branches = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      // this.showDataItems();
      this.searchData.next()
    }
  }

  showDataItems(){
    this.loader = true;
    this.branchService.branchList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.countryval,this.stateval).subscribe((res: any) => {
      this.arrangeApiData(res)
    });
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.branchDetailsList = [];
    if(this.offset ==0){
      this.branches = [];
    }

    for(let key of res.results){
      key.color = this.randomClass(0,5);
      this.branchDetailsList.push(key);
    }
    this.listlength = res.results.length;
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.nodata = this.branches.length==0?true:false;
    this.loader = false;
  }

  ngOnInit(): void {
    this.route.url.subscribe((res:any)=>{
      this.routeComponent = res[0].path
    })

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.showDataItems();

    this.branchService.countryData().subscribe((res: any) => {
       this.countrylistdata = res;
    });

    this.searchData.pipe(delay(1000),
    switchMap(()=> this.branchService.branchList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.countryval,this.stateval)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.branches.push(this.branchDetailsList[i]);
    }
  }


  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.branches = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  addBranch(header:any){
    this.checkoutForm.reset();
    this.CountrySelect = false;
    this.checkoutForm.enable();
    this.editId = 0;
    this.updateClass = true;
    this.header = this.addEditBtn = "Add";
    this.deleteButtonEnable = false;
    this.showSidePanel = true;
    this.checkoutForm.reset({ id: 0});
    this.checkoutForm.enable();
    this.submitted =  false;
    this.child.clearBar();
    this.editflag = false
  }

  editBranch(id: any,is_active:any){
    this.showSidePanel1 =false;
    this.branchid=id;
    this.editflag= true
    this.loader1 = true;
    this.submitted = false;

    this.editId = id;
    if(is_active == false){
      this.header = "View";
      this.checkoutForm.disable();
    }else{
      this.header = "Edit";
      this.checkoutForm.enable();
      this.CountrySelect = true;
    }

    this.branchService.branchGet(this.editId).subscribe((res: any) => {
      this.branchService.stateData(res.country).subscribe((res1: any) => {
        this.statelistdata = res1;
        this.checkoutForm.reset({ id: res.id, name: res.name, country: res.country, state: res.state });
      });
      this.loader = false;
      this.loader1 = false;
    });
    this.msg = '';
    this.deleteButtonEnable = true;
    this.updateClass = is_active;
    this.showSidePanel =true;
    this.showSidePanel = true;
    this.addEditBtn = "Save";
  }
  deletebranchfn(id:any){
    this.checkoutForm.value.id = id;
    this.deleteToggle = true;
  }
  deleteBranch(){
    this.deleteClicked = true
    this.branchService.branchDelete(this.checkoutForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.msg = "deleted";
      this.alertclass ="alert-danger show";
      this.offset = 0;
      this.branches = [];
      this.showDataItems();
      this.showSidePanel = false;
      this.deleteToggle = false;
      this.deleteClicked = false
    },
    (err: any) => {
      this.deleteToggle = false
      this.showSidePanel = false
      this.deleteClicked = false
    });
  }

  changeCountryFun(){
    this.checkoutForm.get('state')?.reset();
    this.statelistdata  = [];
    this.changeCountry();
  }

  changeCountry() {
    this.CountrySelect = true;
    this.branchService.stateData(this.checkoutForm.value.country).subscribe((res: any) => {
      this.statelistdata = res;
    });
  }

  onSubmit(): void {

    this.submitted = true;
    // stop here if form is invalid
    if (this.checkoutForm.invalid) {
          return;
    }
    this.updateClass = false;
    this.editId = this.checkoutForm.value.id;
    this.alertclass ="alert-success show";
    if(this.editId == 0){
      this.msg = "added";
      this.branchService.branchSave({ 'name': this.checkoutForm.value.name, 'country': this.checkoutForm.value.country, 'state' : this.checkoutForm.value.state, 'is_active' : true }).subscribe((res: any) => {
        this.offset = 0;
        this.branches = [];
        this.showDataItems();
        this.checkoutForm.reset();
        this.submitted = false;
        this.showSidePanel = false;

      },(err: any) => {this.updateClass = true});
    }else{
      this.msg = "updated";
      this.branchService.branchUpdate(this.editId, { 'name': this.checkoutForm.value.name, 'country': this.checkoutForm.value.country, 'state' : this.checkoutForm.value.state, 'is_active' : true }).subscribe((res: any) => {
        this.offset = 0;
        this.branches = [];
        this.showDataItems();
        this.showSidePanel = false;
      },(err: any) => {this.updateClass = true});
    }
  }

  get f() {
     return this.checkoutForm.controls;
  }

  /* active or delete filter */
  selectItemList(value:any){
    this.loader = true;
    this.activeToggle = value;
    this.offset = 0;
    this.branches = [];
    this.child.clearBar();
  }
  viewbranch(id:any){
    if(this.permissions.v){
      this.showSidePanel1 = true;
      this.loader1 = true;
      this.branchService.branchGet(id).subscribe((res: any) => {
        this.branchView = res;
        this.branchname = res.name;
        this.branchid = res.id;
        this.state_name = res.state_name;
        this.country_name = res.country_name;
        this.branchStatus = res.is_active;
        this.loader1 = false;
      })
    }
  }

  // Simplified function to use for validation
  existService(){

    if(!this.editflag){
      this.value ={
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.branchid,
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.branchUnit = res.body

      this.loader = false

      if(this.branchUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  filterDone(val:any){
    this.activeToggle = val.stat;
    this.countryval = val.country;
    this.stateval= val.state;
    this.offset = 0
    this.defaultlimit = 20
    this.showDataItems()
    this.filterpanel = false
  }

  panel(value:any){
    this.filterpanel = value;
  }

  resetFilter(){
    this.activeToggle = true;
    this.countryval = ''
    this.stateval = ''
    this.showDataItems()
  }
}
