import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as assert from 'assert';

@Injectable({
  providedIn: 'root'
})
export class ExpenseManagementService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }
  listreports(status:any,filter:any){
    let api = `${this.API_PATH}expense/api/v1/expense-hr-report-list/`
    if(status != null || filter!="")
      api = `${api}?`
    if(status != null && filter=="")
      api = `${api}status=${status}`
    if(status != null && filter!="")
      api = `${api}status=${status}&`
    if(filter!="")
      api = `${api}${filter}`

      return this.http.get(api, this.httpOption);
  }

  reportDetails(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/${id}/`, this.httpOption);
  }
  reimbursementDetails(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-hr-reimbursement-detail/${id}/`, this.httpOption);
  }
  createReimbursement(data:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-hr-reimbursement/`, data, this.httpOption)
  }
}