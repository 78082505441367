import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WeekoffService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  getWeekOffList(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/weeklyoff/`, this.httpOption)
  }

  getWeekList(status:any,limit:any,offset:any,search:any,order:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/weekly-off-list-dropdown/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=''`, this.httpOption)
  }

  getOneWeekfOff(id:number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/weeklyoff-view/${id}/`, this.httpOption)
  }

  createWeekOff(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/weeklyoff-create/`,body, this.httpOption)
  }

  updateWeekOff(body:any,id:number){
    return this.http.put(`${this.API_PATH}attendance/api/v1/weeklyoff-update/${id}/`,body, this.httpOption)
  }

  deleteWeekOff(id:number,data:any){
    return this.http.patch(`${this.API_PATH}attendance/api/v1/weeklyoff-delete/${id}/`,data, this.httpOption)
  }

  getWeekDropdown(is_active:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/weekly-off-list-dropdown/?is_active=${is_active}&ordering=policy_name`, this.httpOption)
  }
}
