<form action="" class=""  [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Expense POLICY</p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Expense policy name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter a name for the Expense policy." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                <input type="text" formControlName="name" class="form-control" placeholder="{{this.messageService.placeholderdisp('policy name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name?.touched || f.name?.dirty) &&  f.name?.errors || f.name?.errors?.exist}" (keyup)="keyUp()">
              <div *ngIf="(submitted || f.name?.touched || f.name?.dirty) && f.name?.errors || f.name?.errors?.exist" class="invalid-feedback">
                <div *ngIf="f.name?.hasError('exist')">{{this.messageService.validationDisplay(f.name?.value)}}</div>
                <div *ngIf="f.name?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="!f.name?.errors?.pattern && f.name?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf="f.name?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              </div>

            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Description </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Provide few lines description about the policy." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <textarea type="text" formControlName="description" class="form-control" placeholder="{{this.messageService.placeholderdisp('description')}}" [ngClass]="{ 'is-invalid': (submitted ||f.description?.touched || f.description?.dirty) &&  f.description?.errors}">
              </textarea>

                <div *ngIf="(submitted ||f.description?.touched || f.description?.dirty) && f.description?.errors" class="invalid-feedback">
                    <div *ngIf="f.description?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.description?.errors?.pattern && f.description?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                    </div>
                    <div *ngIf="f.description?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid || componentError)" (click)="validatepolicysetting()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validatepolicysetting()">NEXT</button>
    </footer>
</form>