import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-organizatin-structure',
  templateUrl: './organizatin-structure.component.html',
  styleUrls: ['./organizatin-structure.component.scss']
})
export class OrganizatinStructureComponent implements OnInit {
  menuData:any = [];
  menuList:any;
  constructor(private appService: AppService,public router: Router) { }

  ngOnInit(): void {
    this.getPermission();
    // this.menuList = this.appService.getPermissions('/org_structure');
    // if(this.menuList == undefined){
    //     this.router.navigateByUrl("/");
    // } else {
    // this.createMenuList();
    // }
  //   this.menuData=[{
  //     name:"Company",
  //     link:'/org_structure/company'
  //   },
  //   {
  //     name:"BU",
  //     link:'/org_structure/bu'
  //   },
  //   {
  //     name:"Department",
  //     link:'/org_structure/department'
  //   },
  //   {
  //     name:"Designation",
  //     link:'/org_structure/designation'
  //   },
  //   {
  //     name:"Grade",
  //     link:'/org_structure/grade'
  //   },
  //   {
  //     name:"City",
  //     link:'/org_structure/city'
  //   }
  //   ,
  //   {
  //     name:"Branch",
  //     link:'/org_structure/branch'
  //   }
  // ]

  }
  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/org_structure')
      this.router.navigate([this.menuData[0].link]);
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/org_structure');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }

}
