import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-proposed-chapt2',
  templateUrl: './proposed-chapt2.component.html',
  styleUrls: ['./proposed-chapt2.component.scss']
})

export class ProposedChapt2Component implements OnInit {

  @Input()submitted:boolean          = false;
  @Input()currency:any;
  @Input()tot_amount:any;
  @Input()alertShow:any;
  @Input()limitData:any;
  @Input()from:any;
  @Input()empId:any;

  @Output() submitmethod             = new EventEmitter();
  @Output() onChap2Validation        = new EventEmitter<any>();

  amountExceedMsg:string             = '';
  totalDeclaredAmount:number         = 0;
  amountBoxName:any;

  constructor(
    private investmentService:InvestmentDeclerationService,
    public messageService : MessageService,
    public fb:FormBuilder,
    private activityService:ManageActivityService
  ) { }

  ngOnInit(): void {
    this.validDeduction();
    this.formGp.controls.employee_contribute_pf.disable();
    this.formGp.controls.contribution_vpf.disable();
    this.formGp.controls.previous_employer_pf.disable();
    this.formGp.controls.employee_contribute_pf_amount.disable();
    this.formGp.controls.contribution_vpf_amount.disable();
    this.formGp.controls.previous_employer_pf_amount.disable();
    if(this.from=='behalf'){
      this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
      this.setAmount(res)

      })
    }
    else{

      this.investmentService.requestDetails().subscribe((res:any)=>{
        this.setAmount(res)
      });
    }

    if(this.tot_amount == true){
      this.amountExceedMsg  = this.messageService.fieldlengthvalidation('amount','1,50,000');
      this.alertShow        = 'show';
      this.scrollToTop();
    }else{
      this.amountExceedMsg  = '';
    }
  }
  setAmount(res:any){
    if(res.count_info.hasOwnProperty('assigned_salary_data')){
      if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){

        this.formGp.get('employee_contribute_pf')?.setValue(res.count_info.assigned_salary_data.provident_fund);
        this.formGp.get('employee_contribute_pf_amount')?.setValue(res.count_info.assigned_salary_data.pf_amount);

        this.formGp.get('contribution_vpf')?.setValue(res.count_info.assigned_salary_data.volentary_provident_fund);
        this.formGp.get('contribution_vpf_amount')?.setValue(res.count_info.assigned_salary_data.amount);

        this.formGp.get('previous_employer_pf')?.setValue(res.count_info.assigned_salary_data.is_prev_emplyer_pf);
        this.formGp.get('previous_employer_pf_amount')?.setValue(res.count_info.assigned_salary_data.prev_emplyer_pf);
      }
    }
      if(res.count_info.assigned_salary_data.pf_amount>0){
        this.formGp.get('employee_contribute_pf')?.setValue(true);
      }else{
        this.setAmoutValue('employee_contribute_pf','employee_contribute_pf_amount');
      }
    this.setAmoutValue('contribution_vpf','contribution_vpf_amount');
    this.setAmoutValue('previous_employer_pf','previous_employer_pf_amount');
  }

  @Input()formGp : FormGroup = this.fb.group({
    employee_contribute_pf              : [''],
    employee_contribute_pf_amount       : [''],
    contribution_vpf                    : [''],
    contribution_vpf_amount             : [''],
    contribution_15y_ppf                : [''],
    ppf_amount                          : [''],
    ppf_document                        : [''],
    previous_employer_pf                : [''],
    previous_employer_pf_amount         : [''],
    five_year_POTD                      : [''],
    POTD_amount                         : [''],
    POTD_document                       : [''],
    subscribe_national_saving           : [''],
    nsc_amount                          : [''],
    nsc_document                        : [''],
    deduction_contribute_NPS            : [''],
    nps_amount                          : [''],
    nps_document                        : [''],
    equity_linked_saving                : [''],
    ELSS_amount                         : [''],
    ELSS_document                       : [''],
    ULIP                                : [''],
    ULIP_amount                         : [''],
    ULIP_document                       : [''],
    interest_NSC                        : [''],
    interest_NSC_amount                 : [''],
    interest_nsc_document               : [''],
    child_education_tuition_fees        : [''],
    tuition_fees                        : [''],
    tuition_fees_document               : [''],
    repay_house_principal               : [''],
    repay_house_amount                  : [''],
    repay_house_document                : [''],
    stampduty_purchase_house            : [''],
    stampduty_amount                    : [''],
    stampduty_document                  : [''],
    saukanya_samridhi_yojana            : [''],
    ssy_amount                          : [''],
    ssy_document                        : [''],
    nabard_rural_bond                   : [''],
    nabard_rural_bond_amount            : [''],
    nabard_rural_bond_document          : [''],
    lic                                 : [''],
    lic_amount                          : [''],
    lic_document                        : [''],
    annuity                             : [''],
    annuity_amount                      : [''],
    annuity_document                    : ['']
  });

  get f() {
    return this.formGp.controls;
  }

  /********* SUBMIT FUNCTION ****************/
  validateChap2(btnText:any){
    if(btnText=='skip'){
      this.skipChapt2Function();
    }
    else{
      this.submitted = true;

      if (this.formGp.invalid) {
        return;
      }
    }

    this.submitmethod.emit();

  }

  /********* VALIDATION FUNCTION ****************/
   checkBoxFunctionTest(e:any, amountName:any) {
    var checkControlName  = e.target.name;
    var amountControlName = amountName;
    var checkVal          = false;
    if(e.target.checked){
      this.formGp.get(checkControlName)?.setValue(true);
      checkVal = true;
    }else{
      this.formGp.get(checkControlName)?.setValue(false);
      this.formGp.get(amountControlName)?.setValue('');
      checkVal = false;
    }

    this.onChap2Validation.emit({check:checkVal,checkCn:checkControlName,amountCn:amountControlName,maxLimit:false,minLimit:false});
  }

  /********* TOTAL FORM AMOUNT VALIDATION FUNCTION ****************/
  validDeduction() {
    this.amountExceedMsg  = '';
    this.totalDeclaredAmount = Number(this.formGp.value.ppf_amount) + Number(this.formGp.value.POTD_amount) + Number(this.formGp.value.nsc_amount) + Number(this.formGp.value.nps_amount) + Number(this.formGp.value.ELSS_amount) + Number(this.formGp.value.ULIP_amount) + Number(this.formGp.value.interest_NSC_amount) + Number(this.formGp.value.tuition_fees) + Number(this.formGp.value.repay_house_amount) + Number(this.formGp.value.stampduty_amount) + Number(this.formGp.value.ssy_amount) + Number(this.formGp.getRawValue().employee_contribute_pf_amount) + Number(this.formGp.getRawValue().contribution_vpf_amount) + Number(this.formGp.getRawValue().previous_employer_pf_amount)
    + Number(this.formGp.getRawValue().nabard_rural_bond_amount) + Number(this.formGp.getRawValue().lic_amount) + Number(this.formGp.getRawValue().annuity_amount);

    return this.totalDeclaredAmount;

  }

  /********* SET VALUE FUNCTION ****************/
  setAmoutValue(chekBox: any, amount: any){
    if( this.formGp.get(chekBox)?.value == false){
      this.formGp.get(amount)?.setValue(0);
    }else{
      this.formGp.get(amount)?.setValue(this.formGp.get(amount)?.value);
    }

  }

  scrollToTop() {
    document.getElementsByClassName('config-layout')[0].scrollTo(0, 0);
  }

  /********* SKIP BUTTON FUNCTION ****************/
  skipChapt2Function(){
    Object.keys(this.formGp.controls).forEach(key => {
      if(key != 'employee_contribute_pf' && key != 'employee_contribute_pf_amount' && key != 'contribution_vpf' && key != 'contribution_vpf_amount'&& key != 'previous_employer_pf' && key != 'previous_employer_pf_amount'){
        if(key == 'contribution_15y_ppf' || key == 'five_year_POTD' || key == 'subscribe_national_saving' || key == 'deduction_contribute_NPS' || key == 'equity_linked_saving' || key == 'ULIP' || key == 'interest_NSC' || key == 'child_education_tuition_fees' || key == 'repay_house_principal' || key == 'stampduty_purchase_house' || key == 'saukanya_samridhi_yojana' || key == 'nabard_rural_bond' || key == 'lic' || key == 'annuity'){

          this.formGp.controls[key].setValue(false);

        }
        else{
          this.formGp.controls[key].reset('');
        }
      }
      this.clearValidationFunction(key)
    });
  }

  clearValidationFunction(key:any){
    this.formGp.get(key)?.clearValidators();
    this.formGp.get(key)?.updateValueAndValidity();
  }

}
