<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="lateEarly" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <div class="row row-16 mb-16">
                    <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.attendance_date.errors}">
                        <div class="col-12 form-row">
                            <p class="text-uppercase fw-500 mb-0 required">Attendance Day</p>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_date" type="radio" id="attendance_date1"
                                    class="form-check-input  " formControlName="attendance_date" value="last_month"
                                    (change)="radioBtnChangeFunctions($event,'attendance_date','last_month','from_date','to_date');setRequired(false)">
                                <label for="attendance_date1" class="form-check-label">Last month</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_date" type="radio" id="attendance_date2"
                                    class="form-check-input " formControlName="attendance_date" value="this_month"
                                    (change)="radioBtnChangeFunctions($event,'attendance_date','this_month','from_date','to_date');setRequired(false)">
                                <label for="attendance_date2" class="form-check-label">Current month</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_date" type="radio" id="attendance_date3"
                                    class="form-check-input " formControlName="attendance_date" value="yesterday"
                                    (change)="radioBtnChangeFunctions($event,'attendance_date','yesterday','from_date','to_date');setRequired(false)">
                                <label for="attendance_date3" class="form-check-label">Yesterday</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_date" type="radio" id="attendance_date4"
                                    class="form-check-input " formControlName="attendance_date" [value]="true"
                                    (change)="radioBtnChangeFunctions($event,'attendance_date',true,'from_date','to_date');setRequired(true)">
                                <label for="attendance_date4" class="form-check-label">Specific Period</label>
                            </div>
                        </div>
                        <ng-container *ngIf="lateEarly.value.attendance_date==true">
                            <!-- <div class="col-12 form-row">
                                <label for="fromdate" class="form-check-label">From date</label>
                            </div> -->
                            <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f.from_date.errors}">

                                <input (click)="datePicker1.open()" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f.from_date.errors}"
                                    placeholder="{{this.messageService.selectplaceholddisplay('From date')}}" matInput
                                    formControlName="from_date" [matDatepicker]="datePicker1" (dateInput)="toDateChange()" [matDatepickerFilter]="salFilter">

                                <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                            </div>
                            <div *ngIf="(submitted || f?.from_date?.touched || f?.from_date?.dirty)&& f?.from_date?.errors" class="invalid-feedback">
                              <div *ngIf="f?.from_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                            <!-- <div class="col-12 form-row">
                                <label for="fromdate" class="form-check-label">To date</label>
                            </div> -->
                            <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f.to_date.errors}">

                                <input (click)="datePicker2.open()" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f.to_date.errors}"
                                    placeholder="{{this.messageService.selectplaceholddisplay('To date')}}" matInput [matDatepickerFilter]="salFilter"
                                    formControlName="to_date" [matDatepicker]="datePicker2" [min]="f?.from_date?.value">

                                <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                            </div>
                            <div *ngIf="(submitted || f?.to_date?.touched || f?.to_date?.dirty)&& f?.to_date?.errors" class="invalid-feedback">
                              <div *ngIf="f?.to_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                        </ng-container>
                    </div>
                    <div *ngIf="(submitted) && f.attendance_date.errors" class="invalid-feedback">
                        <div *ngIf="f.attendance_date.errors.required">
                            {{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                </div>
                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitlateEarly()">
                Apply
            </button>
        </footer>
    </form>
</div>
