<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="negativeForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16 mb-16 ">
                    <div class="col-12 form-row">
                        <label for="" class="form-label required">Date </label>
                        <div class="input-group form-icon icon-right"
                            [ngClass]="{ 'is-invalid': (submitted || f.selected_date.touched || f.selected_date.dirty) &&  f.selected_date.errors}">
                            <input (click)="datePicker1.open()" class="form-control"
                                placeholder="{{this.messageService.selectplaceholddisplay('date')}}" matInput
                                formControlName="selected_date" [matDatepicker]="datePicker1"
                                [ngClass]="{ 'is-invalid': (submitted || f.selected_date.touched || f.selected_date.dirty) &&  f.selected_date.errors}"
                                [matDatepickerFilter]="salFilter">
                            <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                            <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                        </div>
                        <div *ngIf="(submitted || f.selected_date.touched || f.selected_date.dirty) && f.selected_date.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.selected_date.errors.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                        </div>
                    </div>
                </div>
              <app-ou-filters [ouFilterForm]="ouFilterForm" [type]="'leave'" [viewDetail]="viewDetail"></app-ou-filters>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage('reset');resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage('apply');submitNegativeReport()">
                Apply
            </button>
        </footer>
    </form>
</div>
