<ng-container [formGroup]="nFormGp(index)">
  <div class="col-12 form-row my-10" *ngIf="secondLvlChild().length == 0">
    <div class="form-check">
      <input id="{{f?.name?.value}}" class="form-check-input" type="checkbox"  value="" [checked]="(f?.add?.value || f?.edit?.value || f?.delete?.value || f?.view?.value)" [disabled]="f?.view?.disabled || f?.add?.disabled || f?.edit?.disabled || f?.delete?.disabled" (click)="setCheckChildren(f?.menu?.value, $event)">
      <label class="form-check-label" for="{{f?.name?.value}}">
      {{f?.name?.value}}
      </label>
      <div class="mt-16 d-flex gap-80 flex-wrap" *ngIf="(f?.delete?.value|| f?.add?.value || f?.edit?.value|| f?.view?.value) == true">
        <div class="form-check" *ngIf="f?.view?.value != null">
          <input class="form-check-input" formControlName="view" type="checkbox" [id]="'view'+index" [checked]="f?.view?.value" [attr.disabled]="(f?.add?.value == true || f?.edit?.value == true || f?.delete?.value == true) ? true : null">
          <label class="form-check-label" [for]="'view'+index">View</label>
        </div>
          <div class="form-check" *ngIf="f?.add?.value != null">
            <input class="form-check-input" formControlName="add" type="checkbox" [id]="'add'+index"  [checked]="f?.add?.value">
            <label class="form-check-label" [for]="'add'+index">Add</label>
          </div>
          <div class="form-check" *ngIf="f?.edit?.value != null">
            <input class="form-check-input" formControlName="edit" type="checkbox" [id]="'edit'+index" [checked]="f?.edit?.value">
            <label class="form-check-label" [for]="'edit'+index">Edit</label>
          </div>
          <div class="form-check" *ngIf="f?.delete?.value != null">
            <input class="form-check-input" type="checkbox" formControlName="delete" [id]="'delete'+index" [checked]="f?.delete?.value">
            <label class="form-check-label" [for]="'delete'+index">Delete</label>
          </div>
      </div>
    </div>
  </div>
</ng-container>


