<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">SUMMARY</p>
            </div>
            <div class="col-12">
                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                    <div class="row row-16 ">
                        <div class="col-12 ">
                            <p class="title mb-8">SHORT Leave SUMMARY</p>
                        </div>
                        <div class=" col-12">
                            <div class="detail-title">Reason</div>
                            <div class="detail-desc">{{formGp.value?.reason}}</div>
                        </div>
                        <div class=" col-12">
                            <div class="detail-title">Document </div>
                            <div class="detail-desc" *ngIf="formGp.value?.document == ''">-</div>
                            <div class="detail-desc" *ngIf="formGp.value?.document != ''">
                                <div class="card card-c2  bg-secondary">
                                  <div class="p-12 bg-secondary d-flex align-item-center">
                                    <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase fs- fw-700 text-trim">{{this.appServ.getDocName(formGp.value?.document)}}</span>
                                      <span class="tips-helper ">{{this.appServ.getDocName(formGp.value?.document)}}</span>
                                      <div class="d-flex gap-8">
                                        <div class="link-primary" (click)="viewDocument(formGp.value?.document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary"
                                          (click)="downLoad(formGp.value?.document)">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="detail-title">Date</div>
                            <div class="detail-desc">{{this.appServ.dateFormatDisplay(formGp.value?.date)}}</div>
                        </div>
                        <!-- <div class=" col-8">
                            <div class="detail-title">Total Duration</div>
                            <div class="detail-desc">{{totalDuration}} Hrs</div>
                        </div> -->
                        <ng-container *ngIf="formGp.value?.short_leave_portion?.length">
                          <ng-container *ngFor="let data of formGp.value?.short_leave_portion">
                            <div class="col-sm-4 col-12">
                              <div class="detail-title">From time</div>
                              <div class="detail-desc">{{this.appServ.formatTimeDate(data?.from_time)}}</div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="detail-title">To time</div>
                                <div class="detail-desc">{{this.appServ.formatTimeDate(data?.to_time)}}</div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="detail-title">Duration</div>
                                <div class="detail-desc">{{this.appServ.formatTime(data?.duration)}}</div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <div *ngIf="formGp.value?.short_leave_portion?.length == 0">-</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="saveClicked" (click)="validateLeaveSummary()">SAVE</button>
    </footer>
</form>

<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="investalertMsg"></app-alert-toggle>
<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
  [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
