import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild  } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/@core/services/chat.service';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { AppService } from 'src/app/app.global';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  permissions:any = [];
  message = '';
  messages: any[] = this.messageService.history?this.messageService.history:[{
    "role": "system",
    "content": "Answer the question based on the context below, and if the question can't be answered based on the context, say \"I don't know\"\n\n"
  }]
  loader = false
  scrolltop: number = 0;
  disableSend =false
  subscription:Subscription
  @ViewChild('textarea') textarea: ElementRef | undefined;
  @ViewChild('scrollMe') scrollMe: ElementRef| undefined;
  constructor(private messageService: ChatService, public snapshotService: SnapshotService, public appService: AppService,
    private cdref: ChangeDetectorRef
    ) {
      this.subscription =this.messageService.chat_item.subscribe(result => {

      if(this.messages[this.messages.length-1]?.role == 'assistant' && result.event.content=='')
        this.disableSend = false
      else if(this.messages[this.messages.length-1]?.role == 'user' && result.event.content=='')
        this.messages.push(result.event);
      else
        this.messages[this.messages.length-1].content += result.event.content
      
      this.loader = false
      this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
        this.cdref.detectChanges();
    })
  }

  ngOnInit(): void {

    this.messageService.chatSocket();
    this.messageService.messages$.subscribe((message: any) => {
      console.log(1)
      this.messages.push({
        "role": "user",
        "content": message
      });
      this.adjustTextareaHeight();
    });
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  sendMessage(): void {
    if (this.message.trim() !== '') {
      setTimeout(() => {
        this.loader=true
        this.disableSend = true
      }, 1000);
      // this.messageService.sendChat(this.message,"").subscribe((res: any) => {
        // this.messages.push({
        //   "role": "assistant",
        //   "content": res.Answer
        // });
        
      //   this.disableSend = false
      //   this.loader = false
      //   this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
      //   this.cdref.detectChanges();
      // })
      this.messageService.sendMessage(this.message);
        this.loader = false
        this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
        this.cdref.detectChanges();
      this.message = '';
      this.adjustTextareaHeight();
    }
  }
  adjustTextareaHeight(): void {
    if (this.textarea) {
      const nativeElement: HTMLTextAreaElement = this.textarea.nativeElement;
      nativeElement.style.overflow = 'hidden';

      // Set minimum and maximum height in rem units
      const minHeightRem = 3.4; // 49px in rem
      const maxHeightRem = 12.5; // 200px in rem

      // Convert rem to pixels for calculations
      const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize || '16px');
      const minHeight = minHeightRem * baseFontSize;
      const maxHeight = maxHeightRem * baseFontSize;

      nativeElement.style.height = 'auto';

      // Calculate the new height in rem
      const newHeightRem = Math.min(maxHeight, Math.max(minHeight, nativeElement.scrollHeight)) / baseFontSize;
      nativeElement.style.height = newHeightRem + 'rem';

      // Check if the textarea is empty and set height to minHeight
      if (nativeElement.value.trim() === '') {
        nativeElement.style.height = minHeightRem + 'rem';
      }
      
      this.cdref.detectChanges();
    }
   
  }
  clickMessage(message: any) {
    this.message = message
    this.sendMessage()
  }
  prevententer(event:any){
    event.preventDefault();
    if(!this.disableSend)
      this.sendMessage()
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
}
}
