<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="loader==false && permissions">
  <div class="col-12">
      <div class="d-flex justify-content-between">
          <h5 class="mb-0">Previous Employer Income</h5>
          <ng-container *ngIf="addEditPermission">
           <a class="link-primary fs-14 d-flex align-items-center" *ngIf="employerData.length!=0 && permissions?.e" (click)="editEmployerData('Edit')"><i class="icon-pencil-square me-1"></i> Edit</a>
        </ng-container>
      </div>
  </div>
  <div class="col-12" *ngIf="employerData.length!=0">
    <div class="card details-card shadow-0 px-24 py-16">
        <div class="row row-24 ">
            <!-- <div *ngIf="getFieldPermission('taxable_salary')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Taxable salary</div>
                <div class="detail-desc">{{employerData.taxable_salary | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('tax_deducted_by')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Tax deducted</div>
                <div class="detail-desc">{{employerData.tax_deducted_by | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('exempted_gratuity')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Exempted gratuity</div>
                <div class="detail-desc">{{employerData.exempted_gratuity | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('exempted_encashed_leave')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Exempted encashed leave</div>
                <div class="detail-desc">{{employerData.exempted_encashed_leave | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('pf_amount')" class="col-lg-3  col-sm-6 col-12">
              <div class="detail-title">PF amount</div>
              <div class="detail-desc">{{employerData.pf_amount | currency: currency}}</div>
          </div> -->
            <div *ngIf="getFieldPermission('income_after_exemption')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Income after exemption</div>
                <div class="detail-desc">{{employerData.income_after_exemption | currency: currency}}</div>
            </div>            
            <div *ngIf="getFieldPermission('exempted_gratuity')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Exempted gratuity amount</div>
                <div class="detail-desc">{{employerData.exempted_gratuity | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('exempted_encashed_leave')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Exempted leave encashment amount</div>
                <div class="detail-desc">{{employerData.exempted_encashed_leave | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('pf_amount')" class="col-lg-3  col-sm-6 col-12">
              <div class="detail-title">PF amount deducted</div>
              <div class="detail-desc">{{employerData.pf_amount | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('pt_amount')" class="col-lg-3  col-sm-6 col-12">
              <div class="detail-title">Professional tax amount deducted</div>
              <div class="detail-desc">{{employerData.pt_amount | currency: currency}}</div>
            </div>
            <div *ngIf="getFieldPermission('nps_deduction')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Employer NPS deduction 80CCD(2)</div>
                <div class="detail-desc">{{employerData.nps_deduction | currency: currency}}</div>
            </div>
        </div>
    </div>
  </div>
  <div class="col-12" *ngIf="employerData.length!=0">
        <div class="card details-card shadow-0 px-24 py-16">
            <div class="row row-24 ">
                <div class="col-12">
                <p class="mb-0 title">Income details</p>
                </div>
                <div *ngIf="getFieldPermission('income_tax')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Income tax</div>
                    <div class="detail-desc">{{employerData.income_tax | currency: currency}}</div>
                </div>            
                <div *ngIf="getFieldPermission('surcharge')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Surcharge</div>
                    <div class="detail-desc">{{employerData.surcharge | currency: currency}}</div>
                </div>
                <div *ngIf="getFieldPermission('cess')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Cess</div>
                    <div class="detail-desc">{{employerData.cess | currency: currency}}</div>
                </div>
                <div *ngIf="getFieldPermission('total_tds')" class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Total TDS deducted by previous employer</div>
                <div class="detail-desc">{{employerData.total_tds | currency: currency}}</div>
                </div>
                
            </div>
        </div>
  </div>
</div>
<!-- showing when no employer information available -->
<div class="row row-16" *ngIf="employerData.length==0">
    <div class="col-12" >
        <div class="row row-24 py-16" >
            <div class="col-12 ">
                <div class="card details-card shadow-0 px-24 py-16">
                    <p class="title mb-24">Previous Employer</p>
                    <div *ngIf="addEditPermission" class="col-12 d-flex">
                        <a *ngIf="permissions?.a" class="btn btn-outline-primary px-64" (click)="editEmployerData('Add')">Add Previous Employer</a>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
<!-- End -->


<!-- Slider edit start-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel == true'>
    <form class="side-panel-container" [formGroup]="previousEmpForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <header class="side-panel-head">
          <h5> {{header}} Previous Employer Income</h5>
          <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <!-- <p class="text-uppercase fw-500 "></p> -->
              <!-- Edit loader start -->
              <app-edit-loader *ngIf="loader1" ></app-edit-loader>
              <!-- Edit loader end -->
              <ng-container *ngIf="!loader1" >

                <div class="row row-24 mb-32">
                    <div class="col-12">
                        <p class="text-uppercase fw-500 mb-0 fs-16">Previous Employer Income</p>
                    </div>
                  <input type="hidden" class="form-control" name="" id="prof_id" placeholder= "" formControlName="id">
                  <input type="hidden" class="form-control" name="" id="employee_id" placeholder= "" formControlName="employee">
                    <div class="col-sm-6 form-row ">
                      <label for="income_after_exemption" class="form-label required ">Income after exemption</label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                        ngbPopover="Enter your total income from your previous employer, excluding any exemptions (like HRA, gratuity, and leave encashment). For example, Taxable Salary = Total Income from Previous Employer - Exemptions. If your income was 2,88,000 and exemptions were 10,000, enter 2,78,000 as your taxable salary.
                        "
                        popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                        container="body"></i></span>
                      <input type="text" class="form-control " name="" id="income_after_exemption" placeholder="{{this.messageService.placeholderdisp('income after exemption')}}" formControlName="income_after_exemption" [ngClass]="{ 'is-invalid': (submitted || f.income_after_exemption.dirty || f.income_after_exemption.touched) &&  f.income_after_exemption.errors}">
                      <div *ngIf="(submitted || f.income_after_exemption.dirty || f.income_after_exemption.touched) && f.income_after_exemption.errors" class="invalid-feedback">
                          <div *ngIf="f.income_after_exemption.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                      </div>
                      <div *ngIf="(submitted || f.income_after_exemption.dirty) && f.income_after_exemption.errors" class="invalid-feedback">
                          <div *ngIf="f.income_after_exemption.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                          <div *ngIf="!(f.income_after_exemption.errors.pattern) && f.income_after_exemption.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                      </div>
                    </div>                   
                    <div class="col-sm-6 form-row ">
                      <label for="exempted_gratuity" class="form-label required ">Exempted gratuity amount</label><span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                        ngbPopover="Enter the amount of gratuity that was exempted in your previous organization."
                        popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                        container="body"></i>
                      </span>
                      <input type="text" class="form-control " name="" id="exempted_gratuity" placeholder="{{this.messageService.placeholderdisp('exempted gratuity amount')}}" formControlName="exempted_gratuity" [ngClass]="{ 'is-invalid': (submitted || f.exempted_gratuity.dirty || f.exempted_gratuity.touched) &&  f.exempted_gratuity.errors}">
                      <div *ngIf="(submitted || f.exempted_gratuity.dirty || f.exempted_gratuity.touched) && f.exempted_gratuity.errors" class="invalid-feedback">
                          <div *ngIf="f.exempted_gratuity.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="f.exempted_gratuity.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                          <div *ngIf="!(f.exempted_gratuity.errors.pattern) && f.exempted_gratuity.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>


                      </div>

                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="exempted_encashed_leave" class="form-label required ">Exempted leave encashment amount</label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                        ngbPopover="Enter the amount of leave encashment that was exempted by your previous organization."
                        popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                        container="body"></i>
                      </span>
                      <input type="text" class="form-control " name="" id="exempted_encashed_leave" placeholder="{{this.messageService.placeholderdisp('exempted leave encashment amount')}}" formControlName="exempted_encashed_leave" [ngClass]="{ 'is-invalid': (submitted || f.exempted_encashed_leave.dirty || f.exempted_encashed_leave.touched) &&  f.exempted_encashed_leave.errors}">
                      <div *ngIf="(submitted || f.exempted_encashed_leave.dirty || f.exempted_encashed_leave.touched) && f.exempted_encashed_leave.errors" class="invalid-feedback">
                          <div *ngIf="f.exempted_encashed_leave.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="f.exempted_encashed_leave.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                          <div *ngIf="!(f.exempted_encashed_leave.errors.pattern) && f.exempted_encashed_leave.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="pf_amount" class="form-label required ">PF amount deducted</label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                        ngbPopover="Enter the PF amount that was exempted by your previous organization during the financial year. For example, if the financial year is 2022-23 and you left on July 31, 2022, include the PF amounts deducted from April 1, 2022, to July 31, 2022. If the monthly PF deduction was ₹3,000, the total amount for this period would be ₹12,000 (₹3,000 × 4 months)."
                        popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                        container="body"></i>
                      </span>
                      <input type="text" class="form-control " name="" id="pf_amount" placeholder="{{this.messageService.placeholderdisp('pf amount deducted')}}" formControlName="pf_amount" [ngClass]="{ 'is-invalid': (submitted || f.pf_amount.dirty || f.pf_amount.touched) &&  f.pf_amount.errors}">
                      <div *ngIf="(submitted || f.pf_amount.dirty || f.pf_amount.touched) && f.pf_amount.errors" class="invalid-feedback">
                          <div *ngIf="f.pf_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="f.pf_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                          <div *ngIf="!(f.pf_amount.errors.pattern) && f.pf_amount.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                        <label for="pt_amount" class="form-label required ">Professional tax amount deducted</label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                            ngbPopover="Enter the professional tax deducted by your previous organization. Note that even if more than ₹2,500 was deducted in a financial year, the maximum exemption allowed as per government norms is ₹2,500. For example, if your previous employer deducted ₹1,250 and your current employer deducted ₹2,500, the total deduction would be ₹3,750, but only ₹2,500 is eligible for exemption.
                            "
                            popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                            container="body"></i>
                          </span>
                        <input type="text" class="form-control " name="" id="pt_amount" placeholder="{{this.messageService.placeholderdisp('professional tax amount deducted')}}" formControlName="pt_amount" [ngClass]="{ 'is-invalid': (submitted || f.pt_amount.dirty || f.pt_amount.touched) &&  f.pt_amount.errors}">
                        <div *ngIf="(submitted || f.pt_amount.dirty || f.pt_amount.touched) && f.pt_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.pt_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.pt_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                            <div *ngIf="!(f.pt_amount.errors.pattern) && f.pt_amount.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                        </div>  
                      </div>
                      <div class="col-sm-6 form-row ">
                        <label for="nps_deduction" class="form-label required ">Employer NPS deduction 80CCD(2)</label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                            ngbPopover="Enter the amount of NPS (National Pension System) contribution that was deducted by your previous employer from your salary under section 80CCD(2).
                            "
                            popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                            container="body"></i>
                          </span>
                        <input type="text" class="form-control " name="" id="nps_deduction" placeholder="{{this.messageService.placeholderdisp('employer nps deduction 80CCD(2)')}}" formControlName="nps_deduction" [ngClass]="{ 'is-invalid': (submitted || f.nps_deduction.dirty || f.nps_deduction.touched) &&  f.nps_deduction.errors}">
                        <div *ngIf="(submitted || f.nps_deduction.dirty || f.nps_deduction.touched) && f.nps_deduction.errors" class="invalid-feedback">
                            <div *ngIf="f.nps_deduction.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.nps_deduction.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                            <div *ngIf="!(f.nps_deduction.errors.pattern) && f.nps_deduction.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                        </div>  
                      </div>
                   <div class="col-12">
                    <div class="card card-c2 p-16">
                        <div class="row row-16">
                            <div class="col-12">
                                <p class="mb-0 title">
                                    Income Tax Details
                                </p>
                            </div>
                            <div class="col-12 form-row ">
                                <label for="income_tax" class="form-label required ">Income tax</label>
                                <input type="text" class="form-control " name="" id="income_tax" placeholder="{{this.messageService.placeholderdisp('income tax')}}" (keyup)="totaltdsFunction()" formControlName="income_tax" [ngClass]="{ 'is-invalid': (submitted || f.income_tax.dirty || f.income_tax.touched) &&  f.income_tax.errors}">
                                <div *ngIf="(submitted || f.income_tax.dirty || f.income_tax.touched) && f.income_tax.errors" class="invalid-feedback">
                                    <div *ngIf="f.income_tax.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.income_tax.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                                    <div *ngIf="!(f.income_tax.errors.pattern) && f.income_tax.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>    
                                </div>      
                            </div>
                            <div class="col-12 form-row ">
                                <label for="surcharge" class="form-label required ">Surcharge</label>
                                <input type="text" class="form-control " name="" id="surcharge" placeholder="{{this.messageService.placeholderdisp('surcharge')}}"  (keyup)="totaltdsFunction()" formControlName="surcharge" [ngClass]="{ 'is-invalid': (submitted || f.surcharge.dirty || f.surcharge.touched) &&  f.surcharge.errors}">
                                <div *ngIf="(submitted || f.surcharge.dirty || f.surcharge.touched) && f.surcharge.errors" class="invalid-feedback">
                                    <div *ngIf="f.surcharge.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.surcharge.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                                    <div *ngIf="!(f.surcharge.errors.pattern) && f.surcharge.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>    
                                </div>      
                            </div>
                            <div class="col-12 form-row ">
                                <label for="cess" class="form-label required ">Cess</label>
                                <input type="text" class="form-control " name="" id="cess" placeholder="{{this.messageService.placeholderdisp('cess')}}" (keyup)="totaltdsFunction()" formControlName="cess" [ngClass]="{ 'is-invalid': (submitted || f.cess.dirty || f.cess.touched) &&  f.cess.errors}">
                                <div *ngIf="(submitted || f.cess.dirty || f.cess.touched) && f.cess.errors" class="invalid-feedback">
                                    <div *ngIf="f.cess.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="f.cess.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                                    <div *ngIf="!(f.cess.errors.pattern) && f.cess.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>    
                                </div>      
                            </div>
                            <div class="col-12 ">
                                <div class="box-1 gap-8">Total TDS deducted by previous employer  
                                    <span><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Enter the income tax, surcharge, and cess amounts deducted by your previous employer during the financial year. The system will auto-calculate the Total TDS based on these entries.
                                       Example: If your date of leaving is July 31, 2022, you should enter the TDS amount deducted from April 1, 2022, to July 31, 2022.
                                       " popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                                    <div class="ms-auto fw-500">{{total_tds_amount | currency: currency}}</div>
                                </div>
                            </div>                            
                        </div>                        
                    </div>
                   </div>   
                </div>
                
            </ng-container>
          </div>
      </div>
      <footer class="side-panel-footer gap-32" *ngIf="!loader1">
          <button class="ms-auto btn btn-primary text-uppercase btn-sm"  [disabled]="submitted || previousEmpForm.invalid">Save</button>
      </footer>
    </form>
</div>
<!-- slider section end -->

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg" ></app-info-popup>
</ng-container>
<!-- InfoPopup end -->
