import { Component, OnInit ,ViewChild,ChangeDetectorRef,Input,Output,EventEmitter} from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AttendanceDashboardService } from 'src/app/@core/services/attendance-dashboard.service';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import * as XLSX from 'xlsx';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { DatePipe } from '@angular/common';
import {  Router } from '@angular/router';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-import-employee-punch-override',
  templateUrl: './import-employee-punch-override.component.html',
  styleUrls: ['./import-employee-punch-override.component.scss']
})
export class ImportEmployeePunchOverrideComponent implements OnInit {

  fileName: string     = '';
  fileSize: string     = '';
  invalidFormat        = false;
  iscompleted          = false;
  ws:any               = null;
  excelData:any        = [];
  errorToggle          = false;
  errorMsg             = "";
  importData:any       = [];
  isProgressStart      = false
  chunkLen             = 10
  failedData:any       = [];
  successDataCount     = 0;
  percentage:any       = 0;
  exportData:any       = [];
  downloadMsg          = false;
  isDataUpload         = 0;
  successMsg           = ''
  body                 = '';
  minDate = new Date(2020, 0, 1);
  date = new Date();
  maxDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  radiobtnvalue = true;

    // override 
    maxValue: any;
    bodyoverride = '';
    errorMsgoverride = '';
    successMsgoverride = '';
    downloadMsgoverride = false;
    invalidoverride = false
    currentYr = new Date().getFullYear()+1
    templateForm            = this.fb.group({
      fromdate      : [null,[Validators.required]],
      todate        : [null,[Validators.required]],
    })
    employeeDataoverride :any   = [];
    exportDataoverride:any      = [];
    fileNameoverride: string    = '';
    fileSizeoverride: string    = '';
    invalidFormatoverride       = false;
    excelDataoverride:any       = [];
    wsoverride:any              = null;
    deleteToggleoverride        = false;
    deleteMsgoverride           = "";
    importTempoverride:any      = [];
    importDataoverride:any      = [];
    iscompletedoverride         = false;
    successDataCountoverride    = 0;
    isProgressStartoverride     = false;
    chunkLenoverride           = 10;
    percentageoverride:any      = 0;
    isDataUploadoverride        = 0;
    failedDataoverride:any      = [];
    saveDataArrayoverride:any = []
    statusalert = false;
    btnclicked  = false;
  @Input() showSidePanel = false;
  @Input() from:any;
  @Output() uploadeddata = new EventEmitter;
  @Output() closePanel1 = new EventEmitter;

  constructor(
    public appService                 : AppService,
    public messageService             : MessageService,
    public fb                         : FormBuilder,
    public attendanceService          : AttendanceDashboardService,
    public empServ                    : EmployeeService,
    private notificationService       : NotificationService,
    public datePipe                   : DatePipe,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.clearData();
    this.clearDataoverride();
    this.radiobtnvalue=true;
  }
 
  clearData(){
      this.fileName       = '';
      this.fileSize       = '';
      this.importData     = [];
      this.iscompleted    = false;
  }

  onFileChange(evt: any) {
    this.excelData  = [];
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files?.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      const file = evt.target.files[0];
      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates:true , cellNF:false});
        const wsname: string    = wb.SheetNames[0];
        this.ws                 = wb.Sheets[wsname];
        var ws                  = wb.Sheets[wsname];
        this.fileName           = target.files[0].name;
        this.fileSize           = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData          = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,dateNF: 'dd-mm-yyyy',rawNumbers: false,blankrows:false }));
        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }

  }

  processData()
  {
    if(this.excelData?.length <=1){
      this.errorToggle = true;
      this.errorMsg    = "No data found in the uploaded file.";
      this.clearData()

    } else {
      var headerRow = this.excelData[0];
      let Heading:any = ['Employee Code','Employee Name','Punch Date','Punch Time','Remarks'];

      let success =  JSON.stringify(Heading)==JSON.stringify(headerRow)
      // let success = Heading.every(function(val:any) {

      //   return headerRow.indexOf(val) !== -1;
      // });

      if(success == false){
        this.errorToggle  = true;
        this.errorMsg     = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
      this.prepareUploadData();
    }
  }

  uploadPunches(){
    this.isProgressStart  = true;
    this.isDataUpload     = 1;
    this.failedData       = []
    var l                 = this.importData?.length;
    var cLoop             = Math.ceil(l/this.chunkLen)
    var p                 = 100/cLoop
    this.successDataCount = 0
    this.uploadasChunks(0,p);
  }

  uploadasChunks(k:any,p:any)
  {
    this.percentage = 0
    var l = this.importData?.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    this.body = x+' out of '+l+" employee data uploaded successfully";

    this.percentage +=(p/4);
    var saveDataArray:any = [];

    for(var i= k*this.chunkLen; i<x; i++){
        saveDataArray.push(this.importData[i])
    }

    this.attendanceService.uploadPunches({'data':saveDataArray},this.from).subscribe((response:any)=>{
      let res  = response.body;

      this.successDataCount += res.succesd_data_count;
      if(res.failed_data?.length != 0){
      res.failed_data.forEach((i:any, k:any) => {
          this.failedData.push(i)
      });
      }
      this.percentage +=((p/4)*3);
      if(x<l)
      this.uploadasChunks(k+1,p);
      else{
        this.percentage       = 0;
        this.iscompleted      = true
        this.isProgressStart  = false
        if( this.failedData?.length == 0){
            this.notificationService.handleSuccessNotification(this.successDataCount+" punch data uploaded successfully.","Success");
            this.clearData()
            this.showSidePanel = false
            this.uploadeddata.emit();


        } else {
            this.exportData = []
              for (let i = 0; i < this.failedData?.length; i++) {
                this.exportData.push({'Employee Code':this.failedData[i]['data']['employee'],'Employee Name':this.failedData[i]['data']['employee_name'], 'Punch Date':this.failedData[i]['data']['punch_date'],'Punch Time':this.failedData[i]['data']['punch_time'],'Remarks':this.failedData[i]['data']['remark'],'Error Description':this.failedData[i]['error']});

            }

            this.downloadMsg    = true;
            this.successMsg     = this.successDataCount+" out of "+l+" data uploaded successfully"
            this.errorMsg       = this.failedData?.length+' employee(s) have error data'
            this.showSidePanel  = false;

        }
      }
    },
    (error:any)=>{
      this.isProgressStart = false
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    }
    )

  }
  
  getPunchData(data:any,format:any) {
    if (!data) return "";
    const momentTime = moment(data,format, true);
    if (momentTime.isValid()) {
      if(format == 'DD-MM-YYYY'){
        let punch_date      = new Date(data)
        punch_date = new Date(punch_date.setHours(punch_date.getHours() + 2, 0, 0, 0));
        return this.appService.dateFormatConvert(punch_date)
      }else{
        return momentTime.format(format);
      }
    } else {
      return data;
    }
  }

  prepareUploadData(){
    this.importData = [];
    this.chunkLen   = 10;
    this.percentage = 0;
    this.excelData.forEach((item:any, key:any) => {
      if(key != 0){
        this.importData.push({
          "employee"            : item[0]?String(item[0]):"",
          "employee_name"       : item[1]?item[1]:"",
          "punch_date"          : this.getPunchData(item[2],'DD-MM-YYYY'),
          "punch_time"          : this.getPunchData(item[3],'HH:mm'),
          "remark"              : item[4]?item[4]:""
        })
      }
    })
  }

  isValidDateFormat(dateString:any, format:any) {
    const momentDate = moment(dateString, format, true);
    return momentDate.isValid();
  }
 
  // override
  statusuploadclick(){
    this.templateForm.reset();
    this.clearData();
    this.clearDataoverride();
  }

  get f(){ return this.templateForm.controls }

  disableDate(){
    return false;
  }

  fromDateoverride(value:any){
    let from      = this.f.fromdate.value;    
    // if(moment(this.f.fromdate.value).isSameOrAfter(moment(this.f.todate.value))){
      this.templateForm.get('todate')?.setValue('');      
    // }
    this.maxValue = moment(from).add(30, 'days')
  }

  generateTemplate(){
    let dates = '?fromdate='+this.appService.dateFormatConvert(this.templateForm.value.fromdate)+'&todate='+this.appService.dateFormatConvert(this.templateForm.value.todate);

    this.attendanceService.generatedates(dates).subscribe((res:any)=>{
      let header = ['Employee Name','Employee Code'];
      for (let i = 0; i < res.body.length; i++) {
        header.push(res.body[i].date);
      }
      let excelTemp      = [];
      this.exportDataoverride = [];

      for (let i = 0; i < header.length; i++) {
        var dict:any = {};
        for(let j=0;j<header.length;j++){
          if(header[j]=='Employee Name')
          dict[header[j]] = 'Nimmy'
          else if(header[j]=='Employee Code')
          dict[header[j]] = 'EMPP001'
          else
          dict[header[j]] = 'P|A';
        }        
      }
      excelTemp.push({
        dict
      })
      for(let j=0;j<excelTemp.length;j++){
        this.exportDataoverride.push(excelTemp[j]['dict'])
      }
      this.appService.exportExcel(this.exportDataoverride,'Attendance status upload');
    })
  }

  clearDataoverride(){
    this.fileNameoverride       = '';
    this.fileSizeoverride       = '';
    this.importDataoverride     = [];
    this.iscompletedoverride    = false;
  }

  onFileChangeoverride(evt: any) {
    this.excelDataoverride  = [];
    this.importDataoverride = []
    this.iscompletedoverride = false
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){

      this.invalidFormatoverride = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true ,cellDates:true});
        const wsname: string = wb.SheetNames[0];
        this.wsoverride   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];
        this.fileNameoverride   = target.files[0].name;
        this.fileSizeoverride   = this.appService.formatSizeUnits(target.files[0].size)

        const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: true, rawNumbers: false, blankrows: false });

        // Remove whitespaces from cells and filter out rows with all empty cells
        this.excelDataoverride = jsonData
        .map((row: any) => row.map((cell:any) => (typeof cell === 'string' ? cell.trim() : cell)))
         .filter(row => row.some((cell:any) => cell !== ''));
        this.processDataoverride();

      };
      reader.readAsBinaryString(target.files[0]);

    }
    else{
      this.invalidFormatoverride = true;
    }
  }

  processDataoverride(){
    if(this.excelDataoverride.length <=1){
      this.deleteToggleoverride = true;
      this.deleteMsgoverride    = "No data found in the uploaded file.";
      this.clearDataoverride()
    } else {
      var headerRow = this.excelDataoverride[0];
      let Heading:any ;

      Heading = ['Employee Code','Employee Name'];
      let success = Heading.every(function(val:any) {
        return headerRow.indexOf(val) !== -1;
      });
      let dateArr =[]
      dateArr.push(headerRow.slice(2))
      var date_regex = /^\d{4}[- /.]\d{2}[- /.]\d{2}$/ ;
      var times= 0
      for(let key  in dateArr[0]){
        ++times;
        let dateTostr = dateArr[0][key]
        this.invalidoverride = date_regex.test(dateTostr);
        if(!this.invalidoverride){
          break;
        }
      }
      if(times !== dateArr[0]?.length || !headerRow.slice(0,2).every((ele:any) =>Heading.includes(ele))){
        this.invalidoverride = false
      }
      if(success == false || !this.invalidoverride){
        this.deleteToggleoverride = true;
        this.deleteMsgoverride    = this.messageService.invalidExcelFormat();
        this.clearDataoverride();
      }
      else{
        this.prepareUploadDataoverride();

      }

    }
  }

  prepareUploadDataoverride(){

    let headerRow   = this.excelDataoverride[0];
    let header:any  = [];
    this.importTempoverride = [];
    this.importDataoverride = []

    for(let i=0;i<headerRow.length;i++){
      switch(headerRow[i]){
        case 'Employee Code':{
          header.push('employee_code');
          break;
        }
        case 'Employee Name':{
          header.push('employee_name');
          break;
        }
        default:{
          header.push(headerRow[i]);
          break;
        }
      }
    }
    this.excelDataoverride.forEach((item:any, key:any) => {
      var dict:any = {};
      for(let i=0;i<header.length;i++){
        dict[header[i]]= item[i] == undefined ? '' : item[i];
      }
      this.importTempoverride.push({
        dict
      })
    })
    for(let j=0;j<this.importTempoverride.length;j++){
      if(j>0){
        this.importDataoverride.push(this.importTempoverride[j]['dict'])
      }
    }

  }

  uploadsatatus(){
    this.btnclicked=true;
    this.statusalert = false;
    this.isDataUploadoverride     = 1;
    if(this.importDataoverride.length>0){
    this.isProgressStartoverride  = true
    this.failedDataoverride       = []
    this.saveDataArrayoverride = []
    var l                 =(this.importDataoverride.length);
    var cLoop             = Math.ceil(l/this.chunkLenoverride)
    var p                 = 100/cLoop
    this.successDataCountoverride = 0
    this.uploadasChunksoverride(0,p);
    }
  }

  uploadasChunksoverride(k:any,p:any){
    var l    = (this.importDataoverride.length);
    var x    = k*this.chunkLenoverride+this.chunkLenoverride <= l? k*this.chunkLenoverride+this.chunkLenoverride: l;
    this.bodyoverride = x+' out of '+l+" data uploaded successfully";
    this.saveDataArrayoverride = [];
    this.percentageoverride +=(p/4);

    for(var i= k*this.chunkLenoverride; i<x; i++){      
      this.saveDataArrayoverride.push(this.importDataoverride[i])
    }

    this.attendanceService.uploadoverride({'data':this.saveDataArrayoverride},this.from).subscribe((res:any)=>{

      this.successDataCountoverride += res.body.succesd_data_count;
      if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
        res.body.failed_data.forEach((i:any, k:any) => {
              this.failedDataoverride.push(i)
        })
      }

     this.percentageoverride +=((p/4)*3);

        if(x<l){
        this.uploadasChunksoverride(k+1,p);
        }else{
        this.iscompletedoverride = true
        this.percentageoverride = 0;
        this.isProgressStartoverride = false
        if(this.failedDataoverride.length == 0){
              this.notificationService.handleSuccessNotification(this.successDataCountoverride+" Employee(s) attendance status has been uploaded successfully.","Success");
              this.CompleteUpload();
              this.uploadeddata.emit();
          }else {
            this.exportDataoverride    = [];
            for (let i = 0; i < this.failedDataoverride.length; i++) {
              const orderedData : any = {};
              orderedData['Employee Name'] = this.failedDataoverride[i]['data']['employee_name']
              orderedData['Employee Code'] = this.failedDataoverride[i]['data']['employee_code']
              for (const key in this.failedDataoverride[i]['data']) {
                if (key !== 'employee_name' && key !== 'employee_code' && key !== 'error') {
                  orderedData[key] = this.failedDataoverride[i]['data'][key];
                }
              }
              orderedData['Error description'] = this.failedDataoverride[i]['error'];
              this.failedDataoverride[i]['data'] = orderedData;
              this.exportDataoverride.push(this.failedDataoverride[i].data);
            }
              this.downloadMsgoverride = true;
              this.successMsgoverride = this.successDataCountoverride+" out of "+(this.importDataoverride.length)+" data uploaded successfully ";
              this.errorMsgoverride = this.failedDataoverride.length+' employee(s) have error data';
              this.showSidePanel = false
              this.iscompletedoverride = true
            }
        }        
    },
    (error:any)=>{
      this.isProgressStartoverride = false
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    })

}

CompleteUpload(){
  this.fileNameoverride      = '';
  this.excelDataoverride          = [];
  this.fileSizeoverride      = '';
  this.employeeDataoverride   = [];
  this.exportDataoverride  = [];
  this.failedDataoverride = [];
  this.importTempoverride    = [];
  this.importDataoverride   = [];
}


dobFilter(d:any){
  const currentDate = new Date();
  const dateRange = [
    new Date(2020, 0, 1),
    currentDate
  ];
  return (d >= dateRange[0]);
}

closepanel(){
  this.showSidePanel = false
  this.closePanel1.emit(false);
}

closepunch(){
  this.downloadMsg = false;
  this.clearData()
    this.uploadeddata.emit();
  }

closepunchoverride(){
  this.downloadMsgoverride = false;
  this.clearDataoverride()
     this.uploadeddata.emit();  
}

}
