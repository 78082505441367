<div class="side-panel" style="--sidepanel-width:45rem;" >
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>View</h5>
            <!-- <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a> -->
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16">
                    <div class="col-12">
                        <div class="mb-0 fw-500 text-uppercase">CLaim Information</div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 p-24">
                            <div class="row row-16">
                                <div class="col-12"><span class="title">Reimbursement Category</span></div>
                                <div class="col-12">
                                    <div class="card details-card indicater1 indicater-bg2 bg-secondary px-16 py-12">
                                      <div class="row">
                                        <div class="col-5">
                                          <div class="fw-600 fs-14">TRAVEL ALLOWANCE</div>
                                          <div class="fs-10 text-success fw-500 mt-1">AS OF APRIL 2022</div>
                                        </div>

                                        <div class="col">
                                          <div class="detail-title">Entitled</div>
                                          <div class="detail-desc">{{50000 |currency: currency}}</div>
                                        </div>
                                        <div class="col">
                                            <div class="detail-title">Balance</div>
                                            <div class="detail-desc">{{2500 |currency: currency}}</div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16">
                                <div class="col-12"><span class="title">Bill 1</span></div>

                                <div class="col-12">
                                    <div class="card card-c2 px-16 pt-16 pb-12">
                                            <div class="dropdown_progres">
                                                <ul class="progress_drp progress_vertical">

                                                      <li class="progress__item progress__item--accepted"   >
                                                        <div class="progress__tile text-center ">
                                                          <div class="fs-12">
                                                            <div class="">Approved by </div>
                                                            <div class="fw-600">John Carry</div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                      <li class="progress__item progress__item--rejected" >
                                                        <div class="progress__tile text-center " >
                                                          <div class="fs-12">
                                                            <div class="">Rejected by </div>
                                                            <div class="fw-600">William Richard</div>
                                                          </div>
                                                        </div>
                                                      </li>

                                                      <li class="progress__item progress__item--pending" >
                                                        <div class="progress__tile text-center ">
                                                          <div class="fs-12">
                                                            <div class="">Pending with </div>
                                                            <div class="fw-600">You</div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                      <li class="progress__item progress__item--intimation"  >
                                                        <div class="progress__tile text-center " >
                                                          <div class="fs-12">
                                                            <div class="">Intimation sent to </div>
                                                            <div class="fw-600">Johny</div>
                                                          </div>
                                                        </div>
                                                      </li>

                                                 </ul>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Claim Date</div>
                                    <div class="detail-desc">15 Mar 2022</div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Bill Date</div>
                                    <div class="detail-desc">15 Mar 2022</div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Status</div>
                                    <div class="detail-desc">
                                        <span class="badge px-16 mt-1 fs-14 badge-success
                                        py-1">Accepted</span>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Bill No</div>
                                    <div class="detail-desc">GHREYR43645</div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Bill Amount</div>
                                    <div class="detail-desc">{{100 |currency: currency}}</div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Description</div>
                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt.</div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title mb-2">Document</div>
                                    <div style="width:12.9375rem;">



                                        <div class="card card-c2  bg-secondary" >
                                            <div class="p-12 bg-secondary d-flex align-item-center">
                                                <!-- <img src="\assets\images\fileIcon.png" class="me-24 img-fluid" style="width: 2.0625rem; height: 2.625rem;"> -->
                                                <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                                <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                                    <span class="fw-600 text-trim" >DOCUMENT NAME</span>

                                                    <span class="link-primary" ><i
                                                            class="bi bi-eye me-8"></i>View </span>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16">
                                <div class="col-12"><span class="title">Bill 2</span></div>

                                <div class="col-12">
                                    <div class="card card-c2 px-16 pt-16 pb-12">
                                            <div class="dropdown_progres">
                                                <ul class="progress_drp progress_vertical">

                                                      <li class="progress__item progress__item--accepted"   >
                                                        <div class="progress__tile text-center ">
                                                          <div class="fs-12">
                                                            <div class="">Approved by </div>
                                                            <div class="fw-600">John Carry</div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                      <li class="progress__item progress__item--rejected" >
                                                        <div class="progress__tile text-center " >
                                                          <div class="fs-12">
                                                            <div class="">Rejected by </div>
                                                            <div class="fw-600">William Richard</div>
                                                          </div>
                                                        </div>
                                                      </li>

                                                      <li class="progress__item progress__item--pending" >
                                                        <div class="progress__tile text-center ">
                                                          <div class="fs-12">
                                                            <div class="">Pending with </div>
                                                            <div class="fw-600">You</div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                      <li class="progress__item progress__item--intimation"  >
                                                        <div class="progress__tile text-center " >
                                                          <div class="fs-12">
                                                            <div class="">Intimation sent to </div>
                                                            <div class="fw-600">Johny</div>
                                                          </div>
                                                        </div>
                                                      </li>

                                                 </ul>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Claim Date</div>
                                    <div class="detail-desc">15 Mar 2022</div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Bill Date</div>
                                    <div class="detail-desc">15 Mar 2022</div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Status</div>
                                    <div class="detail-desc">
                                        <span class="badge px-16 mt-1 fs-14 badge-success
                                        py-1">Accepted</span>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Bill No</div>
                                    <div class="detail-desc">GHREYR43645</div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="detail-title">Bill Amount</div>
                                    <div class="detail-desc">{{100 |currency: currency}}</div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Description</div>
                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt.</div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title mb-2">Document</div>
                                    <div style="width:12.9375rem;">



                                        <div class="card card-c2  bg-secondary" >
                                            <div class="p-12 bg-secondary d-flex align-item-center">
                                                <!-- <img src="\assets\images\fileIcon.png" class="me-24 img-fluid" style="width: 2.0625rem; height: 2.625rem;"> -->
                                                <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                                <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                                    <span class="fw-600 text-trim" >DOCUMENT NAME</span>

                                                    <span class="link-primary" ><i
                                                            class="bi bi-eye me-8"></i>View </span>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
