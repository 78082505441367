<div class="container-fluid p-32 form-container" [formGroup]="formGp">
  <div class="row row-16">
    <div class="col-12">
      <div class="card details-card indicater1 px-16 py-12">
          <div class="row">
              <div class="col-5"> <div class="fw-600 fs-14">Income from other sources</div> </div>
              <div class="col">
                  <div class="detail-title">Total amount declared</div>
                  <div class="detail-desc">{{totalDeclaredFunction()| currency:currency}}</div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-12">
      <div  role="alert" class="alert alert-warning mb-0 {{alertShow}}">
        <p class="mb-0">Select the checkbox below to fill details or skip the step by clicking on skip button
        </p>
        <div class="btn-close" (click)="alertShow='hide'"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-c2" >
          <div class="card-body p-24">
              <div class="row row-12">
                  <div class="col-12 form-row">
                      <div class="form-check fs-14">
                          <input type="checkbox" name="incomefrom_othersource" id=""  class="form-check-input " formControlName="incomefrom_othersource" (change)="checkBoxFunctionTest($event,'othersource_amount','othersource_document','othersource_document_loader')">
                          <label  for="" class="form-check-label">Income from other sources</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Income from Other Sources is the income received by the employee from other sources like dividends, interest on loans, gifts, rental income, agricultural income and so on and not from the salary." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.incomefrom_othersource">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.othersource_amount.dirty || f.othersource_amount.touched) &&  f.othersource_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="othersource_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="othersource_amount" [ngClass]="{ 'is-invalid': (submitted || f.othersource_amount.dirty || f.othersource_amount.touched) &&  f.othersource_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.othersource_amount.dirty || f.othersource_amount.touched) && f.othersource_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.othersource_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.othersource_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.othersource_amount.errors.pattern) && f.othersource_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                      <!-- upload doc -->
                      <div class="col-12">
                      <label for="othersource_document" class="form-label upload-doc fs-16 fw-500" *ngIf="(othersource_document_loader==false) && (formGp.value.othersource_document == '' || formGp.value.othersource_document == null)" (click)="currentId(2)" [ngClass]="{ 'is-invalid': (filesizeError.othersource_document == true)  }">
                          <i class="bi bi-cloud-upload m-1"></i>Upload document
                      </label>
                      <div *ngIf="(filesizeError.othersource_document == true)" class="invalid-feedback">
                        <div>{{fileError}}</div>
                      </div>
                      <app-file-upload [crop]="false" [round]="false" [id]="'othersource_document'" [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType" (validsize)="getvalidsize($event)"></app-file-upload>

                      <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.othersource_document != '' && filesizeError.othersource_document != true">
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <ng-container *ngIf="!othersource_document_loader">
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.othersource_document)}}</span>
                            <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.othersource_document)}}</span>
                            <div class="d-flex gap-8">
                              <div class="link-primary" (click)="viewDocument(formGp.value.othersource_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.othersource_document,this.appservice.getDocName(formGp.value.othersource_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(2)"></i>
                        </ng-container>

                        </div>
                      </div>

                      <div *ngIf="othersource_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <div class="  flex-center text-center"  *ngIf="othersource_document_loader">
                            <div class="fs-12 text-muted">Loading </div>
                            <div class="ms-4 dot-pulse"></div>
                          </div>
                        </div>
                      </div>
                      </div>

                      <!-- <ng-container *ngIf="formGp.value.othersource_document != '' && filesizeError.othersource_document != true">
                          <div class="col-12 mt-0" *ngIf="!othersource_document_loader">
                              <ul class="selected-values ">
                                  <li class="ng-value">
                                  <span class="">{{othersource_document}}</span>
                                  <span aria-hidden="true" class=" right">
                                      <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(2)"></i>
                                  </span>
                                  </li>
                              </ul>
                          </div>
                      </ng-container>
                      <div class="  flex-center text-center"
                      *ngIf="othersource_document_loader" >
                          <div class="fs-12 text-muted">Loading </div>
                          <div class="ms-4 dot-pulse"></div>
                      </div> -->
                      <!-- upload doc end-->
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check fs-14">
                          <input type="checkbox" name="interest_earned_sbaccount" id=""  class="form-check-input " formControlName="interest_earned_sbaccount" (change)="checkBoxFunctionTest($event,'sb_account_amount','sb_account_document','sb_account_document_loader')">
                          <label  for="" class="form-check-label">Interest earned through saving bank account</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Interest earned on a savings bank account is tax-free up to a limit of ₹10,000/-. If the interest received from these sources exceeds ₹10,000/- the excess interest earned on the savings account will be taxed." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.interest_earned_sbaccount">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.sb_account_amount.dirty || f.sb_account_amount.touched) &&  f.sb_account_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="sb_account_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="sb_account_amount" [ngClass]="{ 'is-invalid': (submitted || f.sb_account_amount.dirty || f.sb_account_amount.touched) &&  f.sb_account_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.sb_account_amount.dirty || f.sb_account_amount.touched) && f.sb_account_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.sb_account_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.sb_account_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.sb_account_amount.errors.pattern) && f.sb_account_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                      <!-- upload doc -->
                      <div class="col-12">
                      <label for="sb_account_document" class="form-label upload-doc fs-16 fw-500" *ngIf="(sb_account_document_loader==false) && (formGp.value.sb_account_document == '' || formGp.value.sb_account_document == null)" (click)="currentId(3)" [ngClass]="{ 'is-invalid': (filesizeError.sb_account_document == true)  }">
                          <i class="bi bi-cloud-upload m-1"></i>Upload document
                      </label>
                      <div *ngIf="(filesizeError.sb_account_document == true)" class="invalid-feedback">
                        <div>{{fileError}}</div>
                      </div>
                      <app-file-upload [crop]="false" [round]="false" [id]="'sb_account_document'" [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType" (validsize)="getvalidsize($event)"></app-file-upload>

                      <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.sb_account_document != '' && filesizeError.sb_account_document != true">
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <ng-container *ngIf="!sb_account_document_loader">
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.sb_account_document)}}</span>
                            <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.sb_account_document)}}</span>
                            <div class="d-flex gap-8">
                              <div class="link-primary" (click)="viewDocument(formGp.value.sb_account_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.sb_account_document,this.appservice.getDocName(formGp.value.sb_account_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(3)"></i>
                        </ng-container>

                        </div>
                      </div>

                      <div *ngIf="sb_account_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <div class="  flex-center text-center"  *ngIf="sb_account_document_loader">
                            <div class="fs-12 text-muted">Loading </div>
                            <div class="ms-4 dot-pulse"></div>
                          </div>
                        </div>
                      </div>
                      </div>

                      <!-- <ng-container *ngIf="formGp.value.sb_account_document != '' && filesizeError.sb_account_document != true">
                          <div class="col-12 mt-0" *ngIf="!sb_account_document_loader">
                              <ul class="selected-values ">
                                  <li class="ng-value">
                                  <span class="">{{sb_account_document}}</span>
                                  <span aria-hidden="true" class=" right">
                                      <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(3)"></i>
                                  </span>
                                  </li>
                              </ul>
                          </div>
                      </ng-container>
                      <div class="  flex-center text-center"
                      *ngIf="sb_account_document_loader" >
                          <div class="fs-12 text-muted">Loading </div>
                          <div class="ms-4 dot-pulse"></div>
                      </div> -->
                      <!-- upload doc end-->
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check fs-14">
                          <input type="checkbox" name="source_80TTA_80TTB" id=""  class="form-check-input " formControlName="source_80TTA_80TTB" (change)="checkBoxFunctionTest($event,'source_80TTA_80TTB_amount','source_80TTA_80TTB_document','source_80TTA_80TTB_document_loader')">
                          <label  for="" class="form-check-label">80TTA/ 80TTB</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="80TTA is applicable for individuals or HUF invested only in saving accounts can claim upto ₹10,000 as deduction. 80TTB is applicable only for senior citizens invested in saving accounts and fixed deposit accounts can claim upto ₹ 50,000 as deduction." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.source_80TTA_80TTB">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.source_80TTA_80TTB_amount.dirty || f.source_80TTA_80TTB_amount.touched) &&  f.source_80TTA_80TTB_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="source_80TTA_80TTB_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="source_80TTA_80TTB_amount" [ngClass]="{ 'is-invalid': (submitted || f.source_80TTA_80TTB_amount.dirty || f.source_80TTA_80TTB_amount.touched) &&  f.source_80TTA_80TTB_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.source_80TTA_80TTB_amount.dirty || f.source_80TTA_80TTB_amount.touched) && f.source_80TTA_80TTB_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.source_80TTA_80TTB_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.source_80TTA_80TTB_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.source_80TTA_80TTB_amount.errors.pattern) && f.source_80TTA_80TTB_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                      <!-- upload doc -->
                      <div class="col-12">
                      <label for="source_80TTA_80TTB_document" class="form-label upload-doc fs-16 fw-500" *ngIf="(source_80TTA_80TTB_document_loader==false) && (formGp.value.source_80TTA_80TTB_document == '' || formGp.value.source_80TTA_80TTB_document == null)" (click)="currentId(4)" [ngClass]="{ 'is-invalid': (filesizeError.source_80TTA_80TTB_document == true)  }">
                          <i class="bi bi-cloud-upload m-1"></i>Upload document
                      </label>
                      <div *ngIf="(filesizeError.source_80TTA_80TTB_document == true)" class="invalid-feedback">
                        <div>{{fileError}}</div>
                      </div>
                      <app-file-upload [crop]="false" [round]="false" [id]="'source_80TTA_80TTB_document'" [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType" (validsize)="getvalidsize($event)"></app-file-upload>

                      <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.source_80TTA_80TTB_document != '' && filesizeError.source_80TTA_80TTB_document != true">
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <ng-container *ngIf="!source_80TTA_80TTB_document_loader">
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.source_80TTA_80TTB_document)}}</span>
                            <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.source_80TTA_80TTB_document)}}</span>
                            <div class="d-flex gap-8">
                              <div class="link-primary" (click)="viewDocument(formGp.value.source_80TTA_80TTB_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.source_80TTA_80TTB_document,this.appservice.getDocName(formGp.value.source_80TTA_80TTB_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(4)"></i>
                        </ng-container>

                        </div>
                      </div>

                      <div *ngIf="source_80TTA_80TTB_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <div class="  flex-center text-center"  *ngIf="source_80TTA_80TTB_document_loader">
                            <div class="fs-12 text-muted">Loading </div>
                            <div class="ms-4 dot-pulse"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                      <!-- <ng-container *ngIf="formGp.value.source_80TTA_80TTB_document != '' &&filesizeError.source_80TTA_80TTB_document != true">
                          <div class="col-12 mt-0" *ngIf="!source_80TTA_80TTB_document_loader">
                              <ul class="selected-values ">
                                  <li class="ng-value">
                                  <span class="">{{source_80TTA_80TTB_document}}</span>
                                  <span aria-hidden="true" class=" right">
                                      <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(4)"></i>
                                  </span>
                                  </li>
                              </ul>
                          </div>
                      </ng-container>
                      <div class="  flex-center text-center"
                      *ngIf="source_80TTA_80TTB_document_loader" >
                          <div class="fs-12 text-muted">Loading </div>
                          <div class="ms-4 dot-pulse"></div>
                      </div> -->
                      <!-- upload doc end-->
                  </ng-container>

                  <!-- NEW ITEM -->
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check fs-14">
                          <input type="checkbox" name="intrest_earned" id=""  class="form-check-input " formControlName="intrest_earned" (change)="checkBoxFunctionTest($event,'intrest_earned_amount','intrest_earned_document','intrest_earned_document_loader')">
                          <label  for="" class="form-check-label">Interest earned from national savings certificate</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Employees can claim a deduction for the investments made in the National Savings Certificate up to ₹1.5 lakhs per annum under Section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.intrest_earned">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.intrest_earned_amount.dirty || f.intrest_earned_amount.touched) &&  f.intrest_earned_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="intrest_earned_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="intrest_earned_amount" [ngClass]="{ 'is-invalid': (submitted || f.intrest_earned_amount.dirty || f.intrest_earned_amount.touched) &&  f.intrest_earned_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.intrest_earned_amount.dirty || f.intrest_earned_amount.touched) && f.intrest_earned_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.intrest_earned_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.intrest_earned_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.intrest_earned_amount.errors.pattern) && f.intrest_earned_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                      <!-- upload doc -->
                      <div class="col-12">
                      <label for="intrest_earned_document" class="form-label upload-doc fs-16 fw-500" *ngIf="(intrest_earned_document_loader==false) && (formGp.value.intrest_earned_document == '' || formGp.value.intrest_earned_document == null)" (click)="currentId(8)" [ngClass]="{ 'is-invalid': (filesizeError.intrest_earned_document == true)  }">
                          <i class="bi bi-cloud-upload m-1"></i>Upload document
                      </label>
                      <div *ngIf="(filesizeError.intrest_earned_document == true)" class="invalid-feedback">
                        <div>{{fileError}}</div>
                      </div>
                      <app-file-upload [crop]="false" [round]="false" [id]="'intrest_earned_document'" [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)" (fullfile)="getFilename($event)" [FileType]="fileType" (validsize)="getvalidsize($event)"></app-file-upload>

                      <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.intrest_earned_document != '' && filesizeError.intrest_earned_document != true">
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <ng-container *ngIf="!intrest_earned_document_loader">
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.intrest_earned_document)}}</span>
                            <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.intrest_earned_document)}}</span>
                            <div class="d-flex gap-8">
                              <div class="link-primary" (click)="viewDocument(formGp.value.intrest_earned_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.intrest_earned_document,this.appservice.getDocName(formGp.value.intrest_earned_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteDoc(8)"></i>
                        </ng-container>

                        </div>
                      </div>

                      <div *ngIf="intrest_earned_document_loader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                        <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                          <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                          <div class="  flex-center text-center"  *ngIf="intrest_earned_document_loader">
                            <div class="fs-12 text-muted">Loading </div>
                            <div class="ms-4 dot-pulse"></div>
                          </div>
                        </div>
                      </div>
                      </div>
                      <!-- <ng-container *ngIf="formGp.value.intrest_earned_document != '' &&filesizeError.intrest_earned_document != true">
                          <div class="col-12 mt-0" *ngIf="!intrest_earned_document_loader">
                              <ul class="selected-values ">
                                  <li class="ng-value">
                                  <span class="">{{intrest_earned_document}}</span>
                                  <span aria-hidden="true" class=" right">
                                      <i class="icon-close fs-16 flex-center ms-1" (click)="deleteDoc(8)"></i>
                                  </span>
                                  </li>
                              </ul>
                          </div>
                      </ng-container>
                      <div class="  flex-center text-center"
                      *ngIf="intrest_earned_document_loader" >
                          <div class="fs-12 text-muted">Loading </div>
                          <div class="ms-4 dot-pulse"></div>
                      </div> -->
                      <!-- upload doc end-->
                  </ng-container>
                  <!-- END -->
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
<!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
<footer class="submit-footer gap-16">
  <button class="btn btn-outline-primary btn-sm ms-auto text-uppercase" (click)="validateOtherIncome('skip')">SKIP</button>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="!formGp.valid" (click)="validateOtherIncome('next')">NEXT</button>
</footer>

<!-- un supported view format alert -->
<app-info-popup *ngIf="alertToggle" (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
