import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Validators,FormArray,FormBuilder,FormGroup } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import * as moment from 'moment';


@Component({
  selector: 'app-ar-overtime',
  templateUrl: './ar-overtime.component.html',
  styleUrls: ['./ar-overtime.component.scss']
})
export class ArOvertimeComponent implements OnInit {
  constructor(public messageService : MessageService,public appservice:AppService,private fb:FormBuilder) { }
  @Input() formGp:any  = []
  @Input()disabled = false;
  @Input() compoOffStatus:any;
  @Input() editId:any;

  overtimeType:any
  alertNote:any = true;
  isconfirmed:any = false;
  isClicked = false
  overtimeControl:any = ['hours_from','hours_to','rate']
  activeOvertime = false
  @Output() submitmethod = new EventEmitter();

  submitted = false;
  disData     : any = ['min_hour_required_consider_overtime_day']
  disNoData   : any = ['min_hour_required_consider_overtime_day','max_overtime_hour_allow__working_day','max_overtime_hour_allow__weekoff','max_overtime_hour_allow__holiday','max_overtime_hour_allow__nationalholiday','overtime_eligibility_fullday','overtime_eligibility_halfday','max_overtime_hour_allow_halfday_working']
  rateData : any = []
  ngOnInit(): void {
    if(this.editId!=0)
    this.activeOvertime = true;
    else{

      if(this.compoOffStatus==false)
      this.activeOvertime = true;
      else
      this.activeOvertime = false;

    }

    if(this.formGp.controls.enable_overtime.value == true){
      this.setRequired(true,this.disData,"init")
      if(this.formGp.controls.set_overtime_workday.value == true){
        this.setRequired(true,['overtime_eligibility_fullday','max_overtime_hour_allow__working_day'],"init")
      }
      if(this.formGp.controls.set_overtime_weekoff.value == true){
        this.setRequired(true,['max_overtime_hour_allow__weekoff'],"init")
      }
      if(this.formGp.controls.set_overtime_holiday.value == true){
        this.setRequired(true,['max_overtime_hour_allow__holiday'],"init")
      }
      if(this.formGp.controls.set_overtime_nationalholiday.value == true){
        this.setRequired(true,['max_overtime_hour_allow__nationalholiday'],"init")
      }
      if(this.formGp.controls.set_overtime_halfday_work.value == true){
        this.setRequired(true,['overtime_eligibility_halfday','max_overtime_hour_allow_halfday_working'],"init")
      }
      this.validateTimePattern()
    }
   }

  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    this.validateTimePattern();
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(5);
  }
  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
}

setRequired(value: boolean,data:any,from:any="screen") {
  if(value) {
  data.forEach((item:any)=>{
    this.formGp.get(item).setValidators([Validators.required])
    if(this.rateData.includes(item)){
      this.formGp.get(item).setValidators([Validators.max(50),Validators.required,Validators.min(0.01)])}
  })
  } else {
    data.forEach((item:any)=>{
      this.formGp.get(item).clearValidators()
    })
    let key = this.getOvertimeType(data)
    this.removeOvertime(key)
  }
  data.forEach((item:any)=>{
    if(from != "init")
      this.formGp.get(item)?.setValue(null);
    this.formGp.get(item).updateValueAndValidity()
  })
}

clearFormArray(data:any){
  let mainData = [...data,'half_day_overtime','holiday_day_overtime','min_hour_required_consider_overtime_day','national_day_overtime','weekoff_overtime']
  mainData.forEach((item:any)=>{
    const control = this.formGp.get(item);
    control.clearValidators();
    if (control instanceof FormArray) {
      control.clear();
    }
  })
}

getOvertimeType(input: string[]): string {
  const mappings: { [key: string]: string } = {
    'max_overtime_hour_allow__nationalholiday': 'National Holiday',
    'max_overtime_hour_allow__holiday': 'Holiday',
    'max_overtime_hour_allow__weekoff': 'Week Off',
    'max_overtime_hour_allow__working_day': 'Working Day',
    'max_overtime_hour_allow_halfday_working': 'Half Day',
  };
  for (const item of input) {
    if (mappings[item]) {
      return mappings[item];
    }
  }
  return '';
}


setCompOff(){
  if(this.compoOffStatus==true){
    this.activeOvertime = false
    this.isconfirmed    = true
  }
  this.formGp.controls.set_overtime_workday.value = false
  this.formGp.controls.set_overtime_weekoff.value = false
  this.formGp.controls.set_overtime_holiday.value = false
  this.formGp.controls.set_overtime_nationalholiday.value = false
  this.formGp.controls.set_overtime_halfday_work.value = false
}

notanumber(val:any){
  if (typeof +val === "number" && !isNaN(+val)) return null;
  else return true;
}
workingDayarray() : FormArray {
  return this.formGp.get("working_day_overtime") as FormArray
}
weekoffarray() : FormArray {
  return this.formGp.get("weekoff_overtime") as FormArray
}
holidayarray() : FormArray {
  return this.formGp.get("holiday_day_overtime") as FormArray
}
nationalHolidayarray() : FormArray {
  return this.formGp.get("national_day_overtime") as FormArray
}
halfDayarray() : FormArray {
  return this.formGp.get("half_day_overtime") as FormArray
}
newlocationarray(): FormGroup {
  return this.fb.group({
    "overtime_type" : [this.overtimeType],
    "hours_from"    : [null,Validators.required],
    "hours_to"      : [null,[Validators.required]],
    "rate"          : [null,[Validators.required,Validators.max(50),Validators.required,Validators.min(0.01),digitdecimalValidator()]]
  })
}
setFromValueWithMinValue(){
  let data = [
    {key:'Working Day',value:'max_overtime_hour_allow__working_day'},
    {key:'Week Off',value:'max_overtime_hour_allow__weekoff'},
    {key:'Holiday',value:'max_overtime_hour_allow__holiday'},
    {key:'National Holiday',value:'max_overtime_hour_allow__nationalholiday'},
    {key:'Halfday',value:'max_overtime_hour_allow_halfday_working'},
  ]
  for(let i=0;i<data.length;i++){
    this.setCompensationCriteria(data[i]['key'],data[i]['value'],'min')
  }
}
setCompensationCriteria(type:any,control:any,from:any){
  let value1:any
  let value2:any
  this.validateMinAndMaxValue(control);
  switch(type) {
    case 'Working Day': {

      if(this.workingDayarray().length>=1){
        value1 = this.workingDayarray().controls[0].get('hours_from')?.value
        value2 = this.workingDayarray().controls[0].get('hours_to')?.value
        if(value1==null && value2==null){
          this.workingDayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)
          this.workingDayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__working_day').value)
        }
        else{
          if(from=='max')
          this.workingDayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__working_day').value)
          else
          this.workingDayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)

        }
      }
       break;
    }
    case 'Week Off': {

      if(this.weekoffarray().length>=1){

        value1 = this.weekoffarray().controls[0].get('hours_from')?.value
        value2 = this.weekoffarray().controls[0].get('hours_to')?.value
        if(value1==null && value2==null){
          this.weekoffarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)
          this.weekoffarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__weekoff').value)
        }
        else{
          if(from=='max')
          this.weekoffarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__weekoff').value)
          else
          this.weekoffarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)

        }
      }
       break;
    }
    case 'Holiday': {

      if(this.holidayarray().length>=1){
        value1 = this.holidayarray().controls[0].get('hours_from')?.value
        value2 = this.holidayarray().controls[0].get('hours_to')?.value
        if(value1==null && value2==null){
          this.holidayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)
          this.holidayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__holiday').value)
        }
        else{
          if(from=='max')
          this.holidayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__holiday').value)
          else
          this.holidayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)

        }
      }
       break;
    }
    case 'National Holiday': {

      if(this.nationalHolidayarray().length==1){
        value1 = this.nationalHolidayarray().controls[0].get('hours_from')?.value
        value2 = this.nationalHolidayarray().controls[0].get('hours_to')?.value
        if(value1==null && value2==null){
          this.nationalHolidayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)
          this.nationalHolidayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__nationalholiday').value)
        }
        else{
          if(from=='max')
          this.nationalHolidayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow__nationalholiday').value)
          else
          this.nationalHolidayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)

        }
      }
       break;
    }
    default: {
      if(this.halfDayarray().length==1){
        value1 = this.halfDayarray().controls[0].get('hours_from')?.value
        value2 = this.halfDayarray().controls[0].get('hours_to')?.value
        if(value1==null && value2==null){
          this.halfDayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)
          this.halfDayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow_halfday_working').value)
        }
        else
        {
          if(from=='max')
          this.halfDayarray().controls[0].get('hours_to')?.setValue(this.formGp.get('max_overtime_hour_allow_halfday_working').value)
          else
          this.halfDayarray().controls[0].get('hours_from')?.setValue(this.formGp.get('min_hour_required_consider_overtime_day').value)
        }

      }
       break;
    }
  }
}
addOverTime(type:any) {
  this.overtimeType = type;

  switch(type) {
    case 'Working Day': {
      this.workingDayarray().push(this.newlocationarray());
      if(this.workingDayarray().length>1){
        let index = this.workingDayarray().length -1
        let start_time = moment(this.workingDayarray().controls[index-1].get('hours_to')?.value).add(1,'minute').format('HH:mm')
        this.workingDayarray().controls[index].get('hours_from')?.setValue(start_time)
        for(let i=0;i<this.workingDayarray().length-1;i++){
          for(let j=0;j<this.overtimeControl.length;j++){
            this.workingDayarray().controls[i].get(this.overtimeControl[j])?.markAsTouched()
          }
        }
      }
      break;
    }
    case 'Week Off': {
      this.weekoffarray().push(this.newlocationarray());
      if(this.weekoffarray().length>1){
        let index = this.weekoffarray().length -1
        let start_time = moment(this.weekoffarray().controls[index-1].get('hours_to')?.value).add(1,'minute').format('HH:mm')
        this.weekoffarray().controls[index].get('hours_from')?.setValue(start_time)
        for(let i=0;i<this.weekoffarray().length-1;i++){
          for(let j=0;j<this.overtimeControl.length;j++){
            this.weekoffarray().controls[i].get(this.overtimeControl[j])?.markAsTouched()
          }
        }
      }
       break;
    }
    case 'Holiday': {
      this.holidayarray().push(this.newlocationarray());
      if(this.holidayarray().length>1){
        let index = this.holidayarray().length -1
        let start_time = moment(this.holidayarray().controls[index-1].get('hours_to')?.value).add(1,'minute').format('HH:mm')
        this.holidayarray().controls[index].get('hours_from')?.setValue(start_time)
        for(let i=0;i<this.holidayarray().length-1;i++){
          for(let j=0;j<this.overtimeControl.length;j++){
            this.holidayarray().controls[i].get(this.overtimeControl[j])?.markAsTouched()
          }
        }
      }

       break;
    }
    case 'National Holiday': {
      this.nationalHolidayarray().push(this.newlocationarray());
      if(this.nationalHolidayarray().length>1){
        let index = this.nationalHolidayarray().length -1
        let start_time = moment(this.nationalHolidayarray().controls[index-1].get('hours_to')?.value).add(1,'minute').format('HH:mm')
        this.nationalHolidayarray().controls[index].get('hours_from')?.setValue(start_time)
        for(let i=0;i<this.nationalHolidayarray().length-1;i++){
          for(let j=0;j<this.overtimeControl.length;j++){
            this.nationalHolidayarray().controls[i].get(this.overtimeControl[j])?.markAsTouched()
          }
        }
      }
       break;
    }
    default: {
      this.halfDayarray().push(this.newlocationarray());
      if(this.halfDayarray().length>1){
        let index = this.halfDayarray().length -1
        let start_time = moment(this.halfDayarray().controls[index-1].get('hours_to')?.value).add(1,'minute').format('HH:mm')
        this.halfDayarray().controls[index].get('hours_from')?.setValue(start_time)
        for(let i=0;i<this.halfDayarray().length-1;i++){
          for(let j=0;j<this.overtimeControl.length;j++){
            this.halfDayarray().controls[i].get(this.overtimeControl[j])?.markAsTouched()
          }
        }
      }
       break;
    }
  }
}
// clearValidator(){
//   this.removeOvertime('Working Day');
//   this.removeOvertime('Week Off');
//   this.removeOvertime('Holiday');
//   this.removeOvertime('National Holiday');
//   this.removeOvertime('Half Day');
// }
removeOvertime(type:any){
  switch(type) {
    case 'Working Day': {
      this.workingDayarray().clear();
       break;
    }
    case 'Week Off': {
      this.weekoffarray().clear();
       break;
    }
    case 'Holiday': {
      this.holidayarray().clear();
       break;
    }
    case 'National Holiday': {
      this.nationalHolidayarray().clear();
       break;
    }
    default: {
      this.halfDayarray().clear();
       break;
    }
  }
}
removeOvertimeIndex(type:any,index:any){
  switch(type) {
    case 'Working Day': {
      this.workingDayarray().removeAt(index)
       break;
    }
    case 'Week Off': {
      this.weekoffarray().removeAt(index)
       break;
    }
    case 'Holiday': {
      this.holidayarray().removeAt(index)
       break;
    }
    case 'National Holiday': {
      this.nationalHolidayarray().removeAt(index)
       break;
    }
    default: {
      this.halfDayarray().removeAt(index)
       break;
    }
  }
}
validateMinAndMaxValue(control:any){
  let minValue = this.formGp.get('min_hour_required_consider_overtime_day').value;
  let maxValue = this.formGp.get(control).value;
  if(minValue!=null && maxValue!=null){

    if (moment(maxValue,'HH:mm:ss').isSameOrBefore(moment(minValue,'HH:mm:ss'))) {
     this.formGp.get(control)?.setErrors({'min': true})
     this.formGp.get(control)?.markAsTouched();
    }
    else{
     this.formGp.get(control)?.setErrors(null)

    }
  }
}

checkForDuplicates(formArray:any,index:any) {
  const controls = formArray.controls;
  for (let i = 0; i < controls.length; i++) {
    const currentControl = controls[i];
    const currentHoursFrom = moment(currentControl.get('hours_from')?.value,'ddd MMM DD YYYY HH:mm:ss ZZ').format('HH:mm');
    const currentHoursTo = moment(currentControl.get('hours_to')?.value,'ddd MMM DD YYYY HH:mm:ss ZZ').format('HH:mm');
    for (let j = i + 1; j < controls.length; j++) {
      const nextControl = controls[j];
      let nextHoursFrom = moment(nextControl.get('hours_from')?.value,'HH:mm').format('HH:mm')
      const nextHoursTo = moment(nextControl.get('hours_to')?.value,'ddd MMM DD YYYY HH:mm:ss ZZ').format('HH:mm')
      // moment(nextHoursFrom,'HH:mm').isBetween(moment(currentHoursFrom,'HH:mm'),moment(currentHoursTo,'HH:mm')) || moment(nextHoursTo,'HH:mm').isBetween(moment(currentHoursFrom,'HH:mm'),moment(currentHoursTo,'HH:mm'))
      if ( (nextHoursFrom != null && nextHoursTo != null) &&
         (moment(nextHoursFrom,'HH:mm').isSameOrAfter(moment(currentHoursFrom,'HH:mm')) && moment(nextHoursFrom,'HH:mm').isSameOrBefore(moment(currentHoursTo,'HH:mm'))) || (moment(nextHoursTo,'HH:mm').isSameOrAfter(moment(currentHoursFrom,'HH:mm')) && moment(nextHoursTo,'HH:mm').isSameOrBefore(moment(currentHoursTo,'HH:mm')))
        ) {
        formArray.controls[index].get('hours_to')?.setErrors({'duplicate': true})
        formArray.controls[index].get('hours_to')?.markAsTouched();
      }else {
        formArray.controls[index].get('hours_to')?.setErrors(null)
      }
    }
  }
}

validateFromTotime(type:any,index:any,control:any){
  let value1:any;
  let value2:any
  let controlValue:any
  let maxValue:any
  let minValue = this.formGp.get('min_hour_required_consider_overtime_day').value;


  switch(type) {

    case 'Working Day': {

       value1       = this.workingDayarray().controls[index].get('hours_from')?.value
       value2       = this.workingDayarray().controls[index].get('hours_to')?.value
       controlValue = this.workingDayarray().controls[index].get(control)?.value
       maxValue     = this.formGp.get('max_overtime_hour_allow__working_day')?.value

      if(value1 != null && value2!= null){

        if (moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {

          this.workingDayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.workingDayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{

          if(!(this.workingDayarray().controls[index].get('hours_to')?.errors?.beforeTime) && !(this.workingDayarray().controls[index].get('hours_to')?.errors?.afterTime))
          this.workingDayarray().controls[index].get('hours_to')?.setErrors(null)

        }
      }
      if(controlValue != null && maxValue!= null && minValue!=null){

        if (moment(controlValue,'HH:mm:ss').isBefore(moment(minValue,'HH:mm:ss'))) {

          this.workingDayarray().controls[index].get(control)?.setErrors({'beforeTime': true})
          this.workingDayarray().controls[index].get(control)?.markAsTouched();
        }
        else if (moment(controlValue,'HH:mm:ss').isAfter(moment(maxValue,'HH:mm:ss'))) {

          this.workingDayarray().controls[index].get(control)?.setErrors({'afterTime': true})
          this.workingDayarray().controls[index].get(control)?.markAsTouched();
        }
        else if (value1 != null && value2!= null && moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {

          this.workingDayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.workingDayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(this.workingDayarray().controls.length>1){
            let errorCount = 0;
            for(let i=0;i<this.workingDayarray().controls.length;i++){
              if(i!=this.workingDayarray().controls.length-1 && i!=index){
                let control1:any;
                control1 = this.workingDayarray().controls[i].get(control)?.value;
                if (moment(control1,'HH:mm:ss').isSame(moment(controlValue,'HH:mm:ss'))) {
                  this.workingDayarray().controls[index].get(control)?.setErrors({'same': true})
                  this.workingDayarray().controls[index].get(control)?.markAsTouched();
                  errorCount++;
                }

              }
            }
            if(errorCount==0)
            this.workingDayarray().controls[index].get(control)?.setErrors(null)

          }
          else
          this.workingDayarray().controls[index].get(control)?.setErrors(null)
        }
       }
       if(value1 !=null && value2 != null && this.workingDayarray().controls[index].get(control)?.valid){
        this.checkForDuplicates(this.workingDayarray(),index)
       }
      break;
    }
    case 'Week Off': {
      value1        = this.weekoffarray().controls[index].get('hours_from')?.value
      value2        = this.weekoffarray().controls[index].get('hours_to')?.value
      controlValue  = this.weekoffarray().controls[index].get(control)?.value
      maxValue      = this.formGp.get('max_overtime_hour_allow__weekoff')?.value
       if(value1 != null && value2!= null){
        if (moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.weekoffarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.weekoffarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(!(this.weekoffarray().controls[index].get('hours_to')?.errors?.beforeTime) && !(this.weekoffarray().controls[index].get('hours_to')?.errors?.afterTime))
          this.weekoffarray().controls[index].get('hours_to')?.setErrors(null)
        }
      }
      if(controlValue != null && maxValue!= null && minValue!=null){

        if (moment(controlValue,'HH:mm:ss').isBefore(moment(minValue,'HH:mm:ss'))) {

          this.weekoffarray().controls[index].get(control)?.setErrors({'beforeTime': true})
          this.weekoffarray().controls[index].get(control)?.markAsTouched();
        }
        else if (moment(controlValue,'HH:mm:ss').isAfter(moment(maxValue,'HH:mm:ss'))) {

          this.weekoffarray().controls[index].get(control)?.setErrors({'afterTime': true})
          this.weekoffarray().controls[index].get(control)?.markAsTouched();
        }
        else if (value1 != null && value2!= null && moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.weekoffarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.weekoffarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(this.weekoffarray().controls.length>1){

            let errorCount = 0;
            for(let i=0;i<this.weekoffarray().controls.length;i++){
              if(i!=this.weekoffarray().controls.length-1 && i!=index){
                let control1:any;
                control1 = this.weekoffarray().controls[i].get(control)?.value;
                if (moment(control1,'HH:mm:ss').isSame(moment(controlValue,'HH:mm:ss'))) {

                  this.weekoffarray().controls[index].get(control)?.setErrors({'same': true})
                  this.weekoffarray().controls[index].get(control)?.markAsTouched();
                  errorCount++;
                }

              }
            }
            if(errorCount==0)
            this.weekoffarray().controls[index].get(control)?.setErrors(null)

          }
          else
          this.weekoffarray().controls[index].get(control)?.setErrors(null)
        }
      }

      if(value1 !=null && value2 != null && this.weekoffarray().controls[index].get(control)?.valid){
        this.checkForDuplicates(this.weekoffarray(),index)
      }
      break;
    }
    case 'Holiday': {
      value1        = this.holidayarray().controls[index].get('hours_from')?.value
      value2        = this.holidayarray().controls[index].get('hours_to')?.value
      controlValue  = this.holidayarray().controls[index].get(control)?.value
      maxValue      = this.formGp.get('max_overtime_hour_allow__holiday')?.value
      if(value1 != null && value2!= null){
        if (moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.holidayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.holidayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(!(this.holidayarray().controls[index].get('hours_to')?.errors?.beforeTime) && !(this.holidayarray().controls[index].get('hours_to')?.errors?.afterTime))
          this.holidayarray().controls[index].get('hours_to')?.setErrors(null)
        }
      }
      if(controlValue != null && maxValue!= null && minValue!=null){

        if (moment(controlValue,'HH:mm:ss').isBefore(moment(minValue,'HH:mm:ss'))) {

          this.holidayarray().controls[index].get(control)?.setErrors({'beforeTime': true})
          this.holidayarray().controls[index].get(control)?.markAsTouched();
        }
        else if (moment(controlValue,'HH:mm:ss').isAfter(moment(maxValue,'HH:mm:ss'))) {

          this.holidayarray().controls[index].get(control)?.setErrors({'afterTime': true})
          this.holidayarray().controls[index].get(control)?.markAsTouched();
        }
        else if (value1 != null && value2!= null && moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.holidayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.holidayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(this.holidayarray().controls.length>1){
            let errorCount = 0;
            for(let i=0;i<this.holidayarray().controls.length;i++){
              if(i!=this.holidayarray().controls.length-1 && i!=index){
                let control1:any;
                control1 = this.holidayarray().controls[i].get(control)?.value;
                if (moment(control1,'HH:mm:ss').isSame(moment(controlValue,'HH:mm:ss'))) {
                  this.holidayarray().controls[index].get(control)?.setErrors({'same': true})
                  this.holidayarray().controls[index].get(control)?.markAsTouched();
                  errorCount++;
                }

              }
            }
            if(errorCount==0)
            this.holidayarray().controls[index].get(control)?.setErrors(null)

          }
          else
          this.holidayarray().controls[index].get(control)?.setErrors(null)
        }
      }
      if(value1 !=null && value2 != null && this.holidayarray().controls[index].get(control)?.valid){
        this.checkForDuplicates(this.holidayarray(),index)
      }
      break;
    }
    case 'National Holiday': {
      value1        = this.nationalHolidayarray().controls[index].get('hours_from')?.value
      value2        = this.nationalHolidayarray().controls[index].get('hours_to')?.value
      controlValue  = this.nationalHolidayarray().controls[index].get(control)?.value
      maxValue      = this.formGp.get('max_overtime_hour_allow__nationalholiday')?.value
      if(value1 != null && value2!= null){
        if (moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.nationalHolidayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.nationalHolidayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(!(this.nationalHolidayarray().controls[index].get('hours_to')?.errors?.beforeTime) && !(this.nationalHolidayarray().controls[index].get('hours_to')?.errors?.afterTime))
          this.nationalHolidayarray().controls[index].get('hours_to')?.setErrors(null)
        }
      }
      if(controlValue != null && maxValue!= null && minValue!=null){

        if (moment(controlValue,'HH:mm:ss').isBefore(moment(minValue,'HH:mm:ss'))) {

          this.nationalHolidayarray().controls[index].get(control)?.setErrors({'beforeTime': true})
          this.nationalHolidayarray().controls[index].get(control)?.markAsTouched();
        }
        else if (moment(controlValue,'HH:mm:ss').isAfter(moment(maxValue,'HH:mm:ss'))) {

          this.nationalHolidayarray().controls[index].get(control)?.setErrors({'afterTime': true})
          this.nationalHolidayarray().controls[index].get(control)?.markAsTouched();
        }
        else if (value1 != null && value2!= null && moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.nationalHolidayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.nationalHolidayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(this.nationalHolidayarray().controls.length>1){
            let errorCount = 0;
            for(let i=0;i<this.nationalHolidayarray().controls.length;i++){
              if(i!=this.nationalHolidayarray().controls.length-1 && i!=index){
                let control1:any;
                control1 = this.nationalHolidayarray().controls[i].get(control)?.value;
                if (moment(control1,'HH:mm:ss').isSame(moment(controlValue,'HH:mm:ss'))) {
                  this.nationalHolidayarray().controls[index].get(control)?.setErrors({'same': true})
                  this.nationalHolidayarray().controls[index].get(control)?.markAsTouched();
                  errorCount++;
                }

              }
            }
            if(errorCount==0)
            this.nationalHolidayarray().controls[index].get(control)?.setErrors(null)

          }
          else
          this.nationalHolidayarray().controls[index].get(control)?.setErrors(null)
        }
      }
      if(value1 !=null && value2 != null && this.nationalHolidayarray().controls[index].get(control)?.valid){
        this.checkForDuplicates(this.nationalHolidayarray(),index)
      }
      break;
    }
    default: {
      value1        = this.halfDayarray().controls[index].get('hours_from')?.value
      value2        = this.halfDayarray().controls[index].get('hours_to')?.value
      controlValue  = this.halfDayarray().controls[index].get(control)?.value
      maxValue      = this.formGp.get('max_overtime_hour_allow_halfday_working')?.value
       if(value1 != null && value2!= null){
        if (moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.halfDayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.halfDayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(!(this.halfDayarray().controls[index].get('hours_to')?.errors?.beforeTime) && !(this.halfDayarray().controls[index].get('hours_to')?.errors?.afterTime))
          this.halfDayarray().controls[index].get('hours_to')?.setErrors(null)
        }
      }
      if(controlValue != null && maxValue!= null && minValue!=null){

        if (moment(controlValue,'HH:mm:ss').isBefore(moment(minValue,'HH:mm:ss'))) {

          this.halfDayarray().controls[index].get(control)?.setErrors({'beforeTime': true})
          this.halfDayarray().controls[index].get(control)?.markAsTouched();
        }
        else if (moment(controlValue,'HH:mm:ss').isAfter(moment(maxValue,'HH:mm:ss'))) {

          this.halfDayarray().controls[index].get(control)?.setErrors({'afterTime': true})
          this.halfDayarray().controls[index].get(control)?.markAsTouched();
        }
        else if(value1 != null && value2!= null && moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
          this.halfDayarray().controls[index].get('hours_to')?.setErrors({'min': true})
          this.halfDayarray().controls[index].get('hours_to')?.markAsTouched();
        }
        else{
          if(this.halfDayarray().controls.length>1){
            let errorCount = 0;
            for(let i=0;i<this.halfDayarray().controls.length;i++){
              if(i!=this.halfDayarray().controls.length-1 && i!=index){
                let control1:any;
                control1 = this.halfDayarray().controls[i].get(control)?.value;
                if (moment(control1,'HH:mm:ss').isSame(moment(controlValue,'HH:mm:ss'))) {
                  this.halfDayarray().controls[index].get(control)?.setErrors({'same': true})
                  this.halfDayarray().controls[index].get(control)?.markAsTouched();
                  errorCount++;
                }

              }
            }
            if(errorCount==0)
            this.halfDayarray().controls[index].get(control)?.setErrors(null)

          }
          else
          this.halfDayarray().controls[index].get(control)?.setErrors(null)
        }
      }
      if(value1 !=null && value2 != null && this.halfDayarray().controls[index].get(control)?.valid){
        this.checkForDuplicates(this.halfDayarray(),index)
      }
      break;
    }
  }

}
validateTimePattern(){

  for(let i=0;i<this.workingDayarray().length;i++){
    let hasError = false;
    let errorIndex = 0
    for(let j=i+1;j<this.workingDayarray().length;j++){
      let value1  = this.workingDayarray().controls[i].get('hours_to')?.value
      let value2  = this.workingDayarray().controls[j].get('hours_from')?.value
      if (moment(value2,'HH:mm:ss').diff(moment(value1,'HH:mm:ss'),'minutes') === 1) {
        hasError    = false;
        errorIndex  = j
        break;
      }
      else{
        errorIndex = j;
        hasError = true;
      }
    }

    if(hasError==true){
      this.workingDayarray().controls[i+1].get('hours_from')?.setErrors({'nomatch': true})
      this.workingDayarray().controls[i+1].get('hours_from')?.markAsTouched();

    }

  }
  for(let i=0;i<this.weekoffarray().length;i++){
    let hasError = false;
    let errorIndex = 0
    for(let j=i+1;j<this.weekoffarray().length;j++){
      let value1  = this.weekoffarray().controls[i].get('hours_to')?.value
      let value2  = this.weekoffarray().controls[j].get('hours_from')?.value
      if (moment(value2,'HH:mm:ss').diff(moment(value1,'HH:mm:ss'),'minutes') === 1) {
        hasError    = false;
        errorIndex  = j
        break;
      }
      else{
        errorIndex = j;
        hasError = true;
      }
    }

    if(hasError==true){
      this.weekoffarray().controls[i+1].get('hours_from')?.setErrors({'nomatch': true})
      this.weekoffarray().controls[i+1].get('hours_from')?.markAsTouched();
    }
    // else{

    //   let index = i!=this.weekoffarray().length-1?i+1:i
    //   this.weekoffarray().controls[index].get('hours_from')?.setErrors(null)
    // }

  }

  for(let i=0;i<this.holidayarray().length;i++){
    let hasError = false;
    let errorIndex = 0
    for(let j=i+1;j<this.holidayarray().length;j++){
      let value1  = this.holidayarray().controls[i].get('hours_to')?.value
      let value2  = this.holidayarray().controls[j].get('hours_from')?.value
      if (moment(value2,'HH:mm:ss').diff(moment(value1,'HH:mm:ss'),'minutes') === 1) {
        hasError    = false;
        errorIndex  = j
        break;
      }
      else{
        errorIndex = j;
        hasError = true;
      }
    }

    if(hasError==true){
      this.holidayarray().controls[i+1].get('hours_from')?.setErrors({'nomatch': true})
      this.holidayarray().controls[i+1].get('hours_from')?.markAsTouched();
    }
    // else{

    //   let index = i!=this.holidayarray().length-1?i+1:i

    //   this.holidayarray().controls[index].get('hours_from')?.setErrors(null)
    // }

  }

  for(let i=0;i<this.nationalHolidayarray().length;i++){
    let hasError = false;
    let errorIndex = 0
    for(let j=i+1;j<this.nationalHolidayarray().length;j++){
      let value1  = this.nationalHolidayarray().controls[i].get('hours_to')?.value
      let value2  = this.nationalHolidayarray().controls[j].get('hours_from')?.value
      if (moment(value2,'HH:mm:ss').diff(moment(value1,'HH:mm:ss'),'minutes') === 1) {
        hasError    = false;
        errorIndex  = j
        break;
      }
      else{
        errorIndex = j;
        hasError = true;
      }
    }

    if(hasError==true){
      this.nationalHolidayarray().controls[i].get('hours_from')?.setErrors({'nomatch': true})
      this.nationalHolidayarray().controls[i].get('hours_from')?.markAsTouched();
    }
    // else{

    //   let index = i!=this.nationalHolidayarray().length-1?i+1:i

    //   this.nationalHolidayarray().controls[index].get('hours_from')?.setErrors(null)
    // }

  }
  for(let i=0;i<this.halfDayarray().length;i++){
    let hasError = false;
    let errorIndex = 0
    for(let j=i+1;j<this.halfDayarray().length;j++){
      let value1  = this.halfDayarray().controls[i].get('hours_to')?.value
      let value2  = this.halfDayarray().controls[j].get('hours_from')?.value
      if (moment(value2,'HH:mm:ss').diff(moment(value1,'HH:mm:ss'),'minutes') === 1) {
        hasError    = false;
        errorIndex  = j
        break;
      }
      else{
        errorIndex = j;
        hasError = true;
      }
    }

    if(hasError==true){
      this.halfDayarray().controls[i+1].get('hours_from')?.setErrors({'nomatch': true})
      this.halfDayarray().controls[i+1].get('hours_from')?.markAsTouched();
    }
    // else{
    //   let index = i!=this.halfDayarray().length-1?i+1:i
    //   this.halfDayarray().controls[index].get('hours_from')?.setErrors(null)
    // }

  }

}
activateOvertime(status:any){
  this.activeOvertime = false
  if(status==false){
    this.formGp.get('enable_overtime').setValue(false)
    this.setRequired(false,this.disNoData)
  }
  else{
    this.activeOvertime = true
  }
  this.isconfirmed = false
}

}
