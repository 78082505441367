<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step"
          [class]="{'success': assignForm['controls'].shift_from_to_date.valid || assignForm['controls'].shift_from_to_date.disabled}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Assign Shift</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-sm-assign-shift  [formGp]="shift_from_to_Form"
            (submitmethod)="submitMethod(2)" (createArray)="createArray($event)"></app-sm-assign-shift>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step"
          [class]="{'success': (checkValid() && getCheckedData().length>0) || assignForm['controls'].assignshift_right.disabled}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Applicability</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-sm-applicability  [formGp]="shift_assign_Form" 
            [companyDD]="companyDD" [buDD]="buDD" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
            [gradeDD]="gradeDD" [employeeDropdown]="employeeDropdown" (submitmethod)="submitForm()"
            [successMsg]="successMsg" [errorMsg]="errorMsg"  [exportData]="exportData"
            [errorLog]="errorLog" ></app-sm-applicability>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>

<app-upload-progress-bar  *ngIf="showProgress && !this.empShift.BGPROGRESS" [progress]="progPercentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Assign Shift'" [timeLeft]="timeLeft" [showClose]="isProgressStart" [body]="body" (closePanel)="closeProgress()" [processingText]='"Proccessing...."' ></app-upload-progress-bar>

<app-error-download  *ngIf="errorLog && !this.empShift.RUNBG" [fileName]="'Assign Shift Error'" [successDataCount]="successDataCount" [downloadMsg]="errorLog" [failedData]="exportData" [modalHeading]="'Assign Shift'" (closePanel)="errorLog =false;backClicked()" [successMsg]="successMsg" [errorMsg]="errorMsgA">  </app-error-download>