<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" routerLink="/separation"><i class="icon-close-lg fs-16"></i></button>
  </header>

  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [activeId]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1" (click)="activeTab=1;">
        <a ngbNavLink class="step" [class]="{'success':myForm['controls'].setting.valid }">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Policy settings</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-seperation-policy-settings [formGp]="settingForm" [editflag]="editflag" [disabled]="disabled"
            [submitted]="iseditForm" (submitmethod)="submitOne()"></app-seperation-policy-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" (click)="tab2click()">
        <a ngbNavLink class="step" [class]="{'success':myForm['controls'].applicable.valid }">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Applicability</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
            <app-common-applicability [heading]="'Applicability'" [disabled]="disabled" [isEditForm]="iseditForm" [formGp]="applicableForm" (submitMethod)="submitForm()"></app-common-applicability>
        </ng-template>
      </li>
    </ul>
  </aside>

  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>

</div>
