import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayComponentService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }


  getuserDefinedPayComponent(active_status:any,limit:any,offset:any,order:any,search:any) {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-component-list/?is_active=${active_status}&limit=${limit}&offset=${offset}&ordering=${order}&search=${search}`, this.httpOption)
  }
  getSinglePayComponent(id: number) {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-component-detail/${id}/`, this.httpOption)
  }
  getComponentDataById(id:number){
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-component-check/${id}/`, this.httpOption)
  }
  getPayComponentList(active_status:any,is_exclude:any,search:any='') {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-component-list/?is_active=${active_status}&ordering=name&other_comp_exclude=${is_exclude}&search=${search}`, this.httpOption)
  }
  deletePayComponent(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}payroll/api/v1/pay-component-detail/${id}/`,data, this.httpOption)
  }
  createPaycomponent(data:any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/pay-component/`,data, this.httpOption)
  }
  updatePaycomponent(id:number,data:any){
    return this.http.put(`${this.API_PATH}payroll/api/v1/pay-component-detail/${id}/`,data, this.httpOption)
  }
  getDropdownComponent(allowance_restriction:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-component-dropdown/?ordering=name&other_comp_exclude=${allowance_restriction}`, this.httpOption)

  }
  getGenaralSetting(year:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/tax-settings-list/?year=${year}`, this.httpOption)
  }
  getInvesmentDeclaration(year:any){
    return this.http.get(`${this.API_PATH}request/api/v1/investment_declaration-list/?year=${year}`, this.httpOption)
  }
  getLeaveDropDownList(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-dropdown/?is_active=true&ordering=leave_name&leave_unpaid_paid=true&exclude_leave_list=["Compensatory off"]`, this.httpOption)
  }
  getComponentDetailCTC(id:number){
    return this.http.get(`${this.API_PATH}payroll/api/v1/pay-component-detail-ctc/${id}/`, this.httpOption)
  }


}
