import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { MessageService } from 'src/app/message.global';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';

@Component({
  selector: 'app-proposed-other-income',
  templateUrl: './proposed-other-income.component.html',
  styleUrls: ['./proposed-other-income.component.scss']
})

export class ProposedOtherIncomeComponent implements OnInit {

  // unamePattern                           = "^(?! )+[A-Za-z0-9 -._@/#&+]*(?<! )$";

  @Input()submitted:boolean              = false;
  @Input()currency:any;
  @Output() submitmethod                 = new EventEmitter();
  currentYear                            = 0;
  @Output() onOtherincome                = new EventEmitter<any>();
  saveClicked                            = false;
  alertShow                              = 'show';
  totalDeclaredAmount:number             = 0;

  constructor(
    public fb:FormBuilder,
    private appservice:AppService,
    public messageService : MessageService,
    private datePipe:DatePipe
  ) { }

  ngOnInit(): void {

    this.currentYear = new Date().getFullYear();

  }

  @Input()formGp : FormGroup = this.fb.group({

    incomefrom_othersource              : [''],
    othersource_amount                  : [''],
    othersource_document                : [''],
    interest_earned_sbaccount           : [''],
    sb_account_amount                   : [''],
    sb_account_document                 : [''],
    source_80TTA_80TTB                  : [''],
    source_80TTA_80TTB_amount           : [''],
    source_80TTA_80TTB_document         : [''],
    intrest_earned                      : [''],
    intrest_earned_amount               : [''],
    intrest_earned_document             : [''],

  });

  @Input() taxRegima: any;

  lenders() : FormArray {
    return this.formGp.get("lender") as FormArray
  }

  newLender(): FormGroup {
    if(this.formGp.value.pre_acquisition_interest == true && this.taxRegima == false){
      return this.fb.group({
        "name"              : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "pan"               : [null,[Validators.required,PanfieldValidator()]],
        "property_address"  : [null,[Validators.required,Validators.maxLength(500)]],
        "possession_date"   : [null,[Validators.required]],
        "owner_name"        : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]]
      })
    }else{
      return this.fb.group({
        "name"              : [null],
        "pan"               : [null],
        "property_address"  : [null],
        "possession_date"   : null,
        "owner_name"        : [null]
      })
    }
  }

  addLenders() {
    this.lenders().push(this.newLender());
  }

  removeLender(i:number) {
    this.lenders().removeAt(i);
  }

  removeAllLender(){
    if(this.formGp.value.lender.length > 0){
      for(let i=0; i< this.formGp.value.lender.length; i++){
        this.removeLender(i);
      }
    }
  }

  get f() {
    return this.formGp.controls;
  }

  validateOtherIncome(btnText:any){
    if(btnText == 'skip'){
      this.skipOtherIncomeFunction();
    }else{
      this.submitted = true;
      if (this.formGp.invalid) {
        return;
      }
      this.saveClicked = true;
    }
    this.submitmethod.emit();
  }

  checkBoxFunctionTest(e:any, amountName:any) {
    var checkControlName  = e.target.name;
    var amountControlName = amountName;
    if(e.target.checked){

        this.formGp.get(checkControlName)?.setValue(true);
        this.onOtherincome.emit({check:true,checkCn:checkControlName,amountCn:amountControlName});

        if(e.target.name == 'pre_acquisition_interest' && this.formGp.value.lender.length == 0 && !this.taxRegima)
          this.addLenders();

    }else{

      this.formGp.get(checkControlName)?.setValue(false);
      this.formGp.get(amountControlName)?.setValue('');
      this.onOtherincome.emit({check:false,checkCn:checkControlName,amountCn:amountControlName});

      if(amountName == 'pre_acquisition_amount'){
        this.removeAllLender();
      }
    }
  }

  dataClearFunction(e:any,amountControlName:any){
    this.formGp.get(amountControlName)?.setValue('');
  }
  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  // Skip Functionality & Total Amount Functionality Start
  skipOtherIncomeFunction(){
    Object.keys(this.formGp.controls).forEach(key => {
      if(key == 'incomefrom_othersource' || key == 'interest_earned_sbaccount' || key == 'source_80TTA_80TTB' || key == 'intrest_earned'){
        this.formGp.controls[key].setValue(false);
      }
      else{
        this.formGp.controls[key].reset('');
      }
      this.clearValidationFunction(key);
    });
  }

  clearValidationFunction(key:any){
    this.formGp.get(key)?.clearValidators();
    this.formGp.get(key)?.updateValueAndValidity();
  }

  totalDeclaredFunction(){
    this.totalDeclaredAmount = Number(this.formGp.value.othersource_amount) + Number(this.formGp.value.sb_account_amount) + Number(this.formGp.value.source_80TTA_80TTB_amount) + Number(this.formGp.value.intrest_earned_amount);
    return this.totalDeclaredAmount;
  }
  // Skip Functionality & Total Amount Functionality end

}
