import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-ar-shiftmapping',
  templateUrl: './ar-shiftmapping.component.html',
  styleUrls: ['./ar-shiftmapping.component.scss']
})
export class ArShiftmappingComponent implements OnInit {
  constructor(public messageService : MessageService) { }
  @Input() formGp:any  = []
  @Input() shiftList:any  = []
  submitted = false;
  @Input()disabled = false;

  @Output() submitmethod = new EventEmitter();
  ngOnInit(): void {
    if (this.shiftList.length != 0) {
      this.selectAllForDropdownItems(this.shiftList);
    }
  }
  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(7);
  }
  clear(index:number){
    let daata = this.formGp.value.shift;
    daata.splice(index, 1);
    this.formGp.patchValue({
      shift: daata
    });
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

}
