import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AttendanceProcessDashboardService } from 'src/app/@core/services/attendance-process-dashboard.service';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-attendance-alert',
  templateUrl: './attendance-alert.component.html',
  styleUrls: ['./attendance-alert.component.scss']
})
export class AttendanceAlertComponent implements OnInit {
  showSidePanel = false;
  selectedList:any = [];
  selectedName="";
  reminders:any = []
  company:any;
  alertClose = false;
  year:any;
  month:any;
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  loader =true;
  constructor(public route:ActivatedRoute,private ads:AttendanceProcessDashboardService,public appservice:AppService) { }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })

    this.ads.getattendanceSummary( Number(this.year),this.company,this.months[this.month]).subscribe((res:any)=>{
      this.createRemiderArray( res.body?.data?.alert_reminders?.alert_data)
    });
  }
  createRemiderArray(data:any){    
   
    if(data.attendance_policy_not_mapped_count != undefined){
      this.reminders.push({
        reminder:"Attendance policy not mapped",
        list:data?.attendance_policy_not_mapped_emp_data,
        listcount:data?.attendance_policy_not_mapped_count,
        class:this.getRandomClass()

      })
    }
    if(data.prev_month_unprocessed_employee_count != undefined){
      this.reminders.push({
        reminder:"Attendance unprocessed last month",
        list:data?.prev_month_unprocessed_employee_emp_data,
        listcount:data?.prev_month_unprocessed_employee_count,
        class:this.getRandomClass()

      })
    }

    this.loader = false;
  }

  getRandomClass(){
    const classes = ["highlight1", "highlight2", "highlight3", "highlight-primary"];
    const random = Math.floor(Math.random() * classes.length);
    return classes[random];
  }

  closePanel(val:any){
    this.showSidePanel= val
  }

}
