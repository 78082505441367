import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-loaders',
  templateUrl: './home-loaders.component.html',
  styleUrls: ['./home-loaders.component.scss']
})
export class HomeLoadersComponent implements OnInit {

  // Loader
  @Input() loader:any

  constructor() { }

  ngOnInit(): void {}

}
