import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetRequestService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  // test start
  employeeAssetList() {
    return this.http.get(`${this.API_PATH}request/api/v1/employee-asset-list/`, this.httpOption)
  }

  assetRequestCreate(body: any){
    return this.http.post(`${this.API_PATH}request/api/v1/asset-request-list/`, body, this.httpOption);
  }

  assetRequestView(id:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=Asset&request_id=${id}`, this.httpOption)
  }

  assetCategoryDropDown(status:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/request-category-list/?is_active=${status}&ordering=name`, this.httpOption)
  }

  assetSubCategoryDropDown(status:any,id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-subcategory-dropdown-list/?is_active=${status}&ordering=name&category=${id}`, this.httpOption)
  }

  assetViewStatus(empId:any,specific_id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${empId}&request_section=Asset&specific_id=${specific_id}`, this.httpOption)

  }
  // test end
}
