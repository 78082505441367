<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row mb-16">
      <div class="col-md-4 col-lg-6">
        <p class="text-uppercase fw-500 mb-0">Select Companies</p>
      </div>
      <div class="col-md-8 col-lg-6" *ngIf="!isEdit">
        <div class="d-flex justify-content-end  gap-16">
            <div class="" style="width:22.6875rem;">
              <div class="">
                <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='"Search company"'
                    (searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchCompany($event)"></app-searchbar>
              </div>
            </div>
        </div>
    </div>
    </div>
    <app-loader *ngIf="loader"></app-loader>


    <div *ngIf="!loader" class="row row-16" [formGroup]="settingsForm">

      <div class="col-12">
        <p class="fs-14 mb-0">Companies selected : {{formArray.controls.length}}</p>
      </div>
      <ng-container  formArrayName="company" *ngFor="let company of companies; let i=index" >

        <div class="col-lg-4 col-sm-6 ">
          <label class="card card-custom1 card-hover {{editCompId!=0?'label-disabled':''}} " for="company{{i}}" >
              <div class="card-body p-16 d-flex align-items-center"  >
                  <input class="form-check-input" name="check" type="checkbox" [value]="company.id" (change)="onCheckChange($event,i)"
                    [checked]="editCompId == company.id?true:isChecked(i,company.id)" id="company{{i}}">
                  <div *ngIf="company.logo == '' || company.logo == null" class="bg{{(i+1)%5}} sq-48 avatar-box">{{this.appService.getFirstChar(company.company_name,2)}}</div>
                  <ng-container  *ngIf="company.logo != '' && company.logo != null">
                      <img src="{{company.logo}}" class="logo-img img-fluid sq-48">
                  </ng-container>

                  <div class="overflow-hidden ms-8">
                      <div class="fs-14 fw-500 text-trim">{{company.company_name}}</div>
                          <span class="fs-10 text-uppercase fw-500 text-success">Active</span>
                  </div>
              </div>
          </label>
      </div>
      </ng-container>

    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="formArray.controls.length==0" (click)="submitMethod()">NEXT</button>
</footer>
</form>
