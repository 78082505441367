import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-expense-apply-advance',
  templateUrl: './expense-apply-advance.component.html',
  styleUrls: ['./expense-apply-advance.component.scss']
})
export class ExpenseApplyAdvanceComponent implements OnInit {

  // test start
  @Input() from:any
  @Input() applyAdvance:any;
  @Input() applyAdvanceData:any;
  @Input() currency:any;
  @Input() loader:any;

  @Output() addadvance = new EventEmitter();
  @Output() closeadvance  = new EventEmitter();


  page = 1;
  pageSize = 20;
  searchString: any = "";

  selectedAdvanceData:any = [];
  // test end

  constructor(
    public appservice: AppService,
  ) { }

  ngOnInit(): void {
  }

  // test start

  checkall(event:any){
    this.selectedAdvanceData = [];
    var newList  = this.filterIt();
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      if(event.target.checked == true){
      item.checked = event.target.checked;
      this.selectedAdvanceData?.push(item);
      }else{
        item.checked = false;
        this.selectedAdvanceData = [];
      }
    })
  }

  checkallSelected(){
    var newList  = this.filterIt();
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
          return item.checked;
      });
    } else {
      return false;
    }
  }

  filterIt() {
    return this.applyAdvanceData.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey     = obj[key].toString().toLowerCase();
          const tempSearch  = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  closeAdvance(){
    this.closeadvance.emit();
  }

  addAdvance(){
    this.selectedAdvanceData = [];
    this.applyAdvanceData.forEach((item:any, key:any) => {
      if(item.checked == true){
        // if(this.from!='profile')
        //   this.selectedAdvanceData?.push(item);
        // else
          this.selectedAdvanceData?.push(item['id']);
      }
    })
    this.addadvance.emit({'addedadvance':this.selectedAdvanceData})
  }

  countSelected(){
    return this.applyAdvanceData.filter((obj :any) => obj.checked === true)?.length;
  }

  // test end

}
