import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { InfoWindowManager, MarkerManager } from '@agm/core';
import {
  ClusterIconStyle,
  MarkerClustererOptions,
} from '@google/markerclustererplus';
import { Calculator } from '@google/markerclustererplus/dist/markerclusterer';
import { ClusterManager } from '@agm/markerclusterer';
import MarkerClusterer from '@google/markerclustererplus';
import { Cluster } from '@google/markerclustererplus/dist/cluster';

@Directive({
  selector: 'marker-cluster',
  providers: [
    ClusterManager,
    { provide: MarkerManager, useExisting: ClusterManager },
    InfoWindowManager,
  ],
})
export class MarkerCluster implements OnDestroy,  OnInit, MarkerClustererOptions{

  @Input() imagePath: string='';
  @Input() imageExtension: string='';

  @Output() clusterClick: EventEmitter<Cluster> = new EventEmitter<Cluster>();

  private _observableSubscriptions: Subscription[] = [];

  constructor(
    private _clusterManager: ClusterManager
    ) {}

  ngOnDestroy() {
    this._clusterManager.clearMarkers();
    this._observableSubscriptions.forEach((s) => s.unsubscribe());
  }


  ngOnInit() {
    this._clusterManager.init({
      imageExtension: this.imageExtension,
      imagePath: this.imagePath,
    });

    this._clusterManager
      .getClustererInstance()
      .then((markerClusterer: MarkerClusterer) => {
       
        google.maps.event.addListener(
          markerClusterer,
          'clusterclick',
          (cluster) => {
            cluster.getMarkers()
            this.clusterClick.emit(cluster);
          }
        );
      });
  }

}
