<div class="container-fluid p-24" *ngIf="employee_id == 0; else data">
  <div class="row">
    <div class="col-10 mx-auto">
      <div class="flex-column text-center d-flex flex-center text-light-500" style="min-height: calc(100vh - 96px)">
        <img style="height: 60vh; max-height: 240px" src="assets\images\bg\welcome.svg" alt=""
          class="img-fluid d-flex mx-auto pb-32" />
        <div class="fw-600 fs-14 pb-12 text-uppercase">
          Simplify, streamline and optimize your HR processes
        </div>
        <div class="fs-12 text-light-500">
          Start exploring the system now by clicking on the menu’s
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #data>
  <div class="section-layout section-rt" style="--section-sideabar-width:28rem;" >
    <div class="section-main p-24">
      <div class="row row-16">
        <div class="col-12">
          <app-home-loaders [loader]="'dashboard_employee'" *ngIf="dashboard_employee_loader;"></app-home-loaders>
          <div *ngIf="!dashboard_employee_loader;"
            class="card card-c2 px-24 py-16 radius-8 border-0 hstack gap-16 align-items-center bg-welcome-card flex-wrap text-white "
            style="min-height:5.5rem">
            <div class="vstack justify-content-center gap-8 text-center text-lg-start">
              <p class="fs-20 fw-500 mb-0">
                Hello, {{userDataSession?.employee_name}}
              </p>
              <p class="fs-12 mb-0">Employee code : {{userDataSession?.employee_code}}</p>
            </div>
            <div class="card card-c2 p-8 gap-8 mx-auto bg-glass hstack radius-8 ms-lg-auto" *ngIf="webCheckIn">
              <div class="row row-8">
                <div class="col-lg col-md-12">
                  <div class="vstack me-8 text-center text-lg-start text-white">
                    <span class="fs-14 fw-500 w-18ch text-trim" *ngIf="!employee_shift?.shift_data?.is_shift_error">{{employee_shift?.shift_data?.shift_name+' ('+employee_shift?.shift_data?.shift_code+')'}}</span>
                    <span class="fs-14 fw-500 w-18ch text-center text-wrap text-trim" *ngIf="employee_shift?.shift_data?.is_shift_error">Shift not Assigned</span>
                    <span
                      class="fs-12 text-nowrap"  *ngIf="!employee_shift?.shift_data?.is_shift_error">{{this.appService.timeFormatDisplay(employee_shift?.shift_data?.shift_start_time)+'-'+this.appService.timeFormatDisplay(employee_shift?.shift_data?.shift_end_time)}}</span>
                  </div>
                </div>
                <!-- <app-home-loaders class="col" [loader]="'checkinLoader'"
                                    *ngIf="checkinLoader;"></app-home-loaders> -->
                <!-- <ng-container *ngIf="!checkinLoader;">
                                </ng-container> -->
                <div class="col flex-shrik-0 text-white">
                  <div class="timer-display">{{hh}}</div>
                </div>
                <div class="col">
                  <button class="fs-16 text-nowrap btn"
                    class="{{(checkinFlag == true) ? 'btn-white-accent1' : 'btn-warning-2'}}"
                    style="min-width: 7.6875rem;" (click)="checkSelfie()">
                    <ng-container *ngIf="!checkinLoader">
                      {{(checkinFlag==true)?'Check-in':'Check-out'}}
                    </ng-container>
                    <span class="animate-rotate" *ngIf="checkinLoader;">
                      <i class="icon-loader d-flex sq-16"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 test"
          *ngIf="(ownBday.length>0 && !removebday) || (ownAnniversary.length>0 && !removeAnniversary)">
          <div class="row row-16">
            <div class="col" *ngIf="ownBday.length>0 && removebday==false">
              <div class="card card-c2 bg-birthday border-accent1 radius-8 p-24 position-relative overflow-hidden">
                <div class="btn-close position-absolute top-0 end-0 p-16 pointer"
                  (click)="removebday=true;removeBdayAnniversary('bday')"></div>
                <div class="  align-items-center gap-24 py-16 hstack">
                  <div class="bg51 avatar-circle p-16 sq-72 flex-center">
                    <img class="img-fluid  " src="/assets/images/helper/cake-helper.png" />
                  </div>

                  <div class="vstack justify-content-center">
                    <p class="fs-12 fw-500 mb-0 text-accent1">Happy Birthday</p>
                    <div class="mb-0 fs-28 text-trim">{{ownBday[0].first_name+"
                      "+(ownBday[0].middle_name!=null?ownBday[0].middle_name+" ":" ")+ownBday[0].last_name}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="loaderAnniversary; else anniversary">
              <div class="card card-c2">

                <app-home-loaders [loader]="'loader_quick'"></app-home-loaders>
              </div>
            </div>
            <ng-template #anniversary>

              <div class="col" *ngIf="ownAnniversary.length>0 && !removeAnniversary">
                <div
                  class="card card-c2 bg-anniversary border-accent1 radius-8 p-24  position-relative overflow-hidden">

                  <div class="btn-close position-absolute top-0 end-0 p-16 pointer"
                    (click)="removeAnniversary=true;removeBdayAnniversary('anniversary')"></div>
                  <div class="  align-items-center gap-24 py-16 hstack">
                    <div class="bg51 avatar-circle p-16 sq-72 flex-center">
                      <img class="img-fluid " src="/assets/images/helper/badge-helper.png" />
                    </div>
                    <div class="vstack justify-content-center">
                      <p class="fs-12 fw-500 mb-0 text-accent1">Happy {{ownAnniversary[0]?.anniversary_count}} Year At
                        {{ownAnniversary[0]['company']}}</p>
                      <div class="mb-0 fs-28 text-trim">{{ownAnniversary[0].first_name+"
                        "+(ownAnniversary[0].middle_name!=null?ownAnniversary[0].middle_name+" ":"
                        ")+ownAnniversary[0].last_name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-12" *ngIf="carouselTileItems.length>0">
          <div class="card card-c2 p-24 ">
            <app-home-loaders *ngIf="loader_quick; else links" [loader]="'loader_quick'"></app-home-loaders>
            <ng-template #links>
              <div class="row  row-16">
                <div class="col-12 d-flex align-items-center">
                  <p class="mb-0 fw-500">Quick links</p>
                  <!-- <span class="link-primary ms-auto text-uppercase fw-600 fs-12"
                                (click)="manageQuickLink = true">Manage quick links</span> -->
                </div>
                <div class="col-12">
                  <div class="carousel-c1">
                    <button id="carousel-left" class="btn btn-white btnLeft rounded-circle sq-32 p-0" (click)="scrollLeft()"
                      style="display:none">
                      <i class="icon-chevron-left-lg"></i>
                    </button>
                    <button id="carousel-right" class="btn btn-white btnRight rounded-circle sq-32 p-0"
                      (click)="scrollRight()">
                      <i class="icon-chevron-right-lg"></i>
                    </button>
                    <div class="carousel-items" (scroll)="onScroll()" #carouselItems>
                      <div *ngFor="let item of carouselTileItems"
                        class="card card-c2 flex-shrink-0 card-hover p-16 hstack gap-16"
                        ngbPopover="{{(item?.name).toUpperCase()}}" popoverClass="popover-default popover-info"
                        container="body" triggers="hover" (click)="redirectRoute(item.name)">
                        <i class="{{item.icon}} {{item.class}} fs-20"></i>
                        <p class="mb-0 fs-14 text-center text-trim text-uppercase">{{item.name}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12">
                                    <ngu-carousel class="custom-carousel" #myCarousel [inputs]="quickLinkTiles"
                                        [dataSource]="carouselTileItems">
                                        <ngu-tile *nguCarouselDef="let item; let i = index">
                                            <div class="card card-c2 card-hover1 p-16 h-100 hstack gap-16 "
                                                ngbPopover="{{(item?.name).toUpperCase()}}"
                                                popoverClass="popover-default popover-info" container="body"
                                                triggers="hover" (click)="redirectRoute(item.name)">
                                                <i class="{{item.icon}} {{item.class}} fs-20"></i>
                                                <p class="mb-0 fs-14 text-center text-trim text-uppercase">{{item.name}}
                                                </p>
                                            </div>
                                        </ngu-tile>
                                        <button NguCarouselPrev class="leftRs"
                                            [style.opacity]="myCarousel.isFirst ? 0:1">
                                            <i class="icon-chevron-left-lg"></i>
                                        </button>
                                        <button NguCarouselNext class="rightRs"
                                            [style.opacity]="myCarousel.isLast ? 0:1">
                                            <i class="icon-chevron-right-lg"></i>
                                        </button>
                                    </ngu-carousel>
                                </div> -->
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-12">
          <div class="card card-c2  px-24 pt-24 pb-8  ">
            <div class="row ">
              <div class="col-12 d-flex" *ngIf="!dashboard_balance_loader">
                <p class="mb-0 fw-500">Leave Balance</p>
                <a href="" class="link-primary ms-auto text-uppercase fw-600 fs-12"
                  [routerLink]="'/leave-request-setup'">Apply leave</a>
              </div>
              <app-home-loaders *ngIf="dashboard_balance_loader; else leave_balance"
                [loader]="'loader_quick'"></app-home-loaders>
              <ng-template #leave_balance>
                <div class="col-lg-4 col-sm-6 mx-auto" *ngIf="noLeave && !dashboard_balance_loader; else all_leave">
                  <div class="flex-center d-flex align-items-center py-30 gap-20">
                    <img src="assets\images\bg\leave_dashboard.svg" class="img-fluid">
                    <p class="fs-14 fw-500 mb-0">No data to show for your leave balance</p>
                  </div>
                </div>
                <ng-template #all_leave>
                  <div class="col-12">
                    <ngu-carousel class="custom-carousel" #myCarousel [inputs]="leaveBalanceTiles"
                      [dataSource]="balanceData">
                      <ngu-tile *nguCarouselDef="let item; let i = index">
                        <div class="bg-dark-300 card card-c2 pb-10 pt-16 px-16">
                          <div class="row row-8">
                            <div class="col-12 d-flex">
                              <span class=" fw-500 fs-10 text-uppercase text-trim" ngbPopover="{{item?.leave_name}}"
                                popoverClass="popover-input popover-info" container="body"
                                triggers="hover">{{item?.leave_name}}</span>
                            </div>
                            <div class="align-items-baseline col-12 d-flex gap-8">

                              <span class="fs-28 fw-500">{{item?.available_leave}}</span>
                              <span class="fs-10 text-light-500">Remaining</span>

                            </div>
                            <!-- <div class="col-12">
                              <ngb-progressbar class="progress-type4" type="info"
                                [value]="getBalancePercent(item?.available_leave,item?.total)"></ngb-progressbar>
                              <div class="fs-10 text-light-500">
                                {{item?.available_leave}}/{{item?.total}}
                                remaining</div>
                            </div> -->
                          </div>
                        </div>
                      </ngu-tile>
                      <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0:1">
                        <i class="icon-chevron-left-lg"></i>
                      </button>
                      <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0:1">
                        <i class="icon-chevron-right-lg"></i>
                      </button>
                    </ngu-carousel>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card card-c2 p-24 ">
            <app-home-loaders *ngIf="dashboard_attendance_loader; else summary"
              [loader]="'loader_quick'"></app-home-loaders>
            <ng-template #summary>
              <div class="row row-16">
                <div class="col-12 d-flex gap-16 align-items-center">
                  <p class="mb-0 fw-500">Attendance summary (from 1st
                    {{currentMonth.slice(0,3).toUpperCase()}} to {{summaryTitle}})</p>
                  <div class="ms-auto">
                    <div ngbDropdown class="input-dropdown four-dropdown " container="body">
                      <button type="button" class="card card-c2 h-36 fs-14" ngbDropdownToggle>
                        <span class="">{{currentMonth}} {{currentYear}}</span>
                      </button>
                      <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                        <div class="row">
                          <div class="col-5">
                            <label for="" class="form-label">Year </label>
                            <ng-select class="form-ngselect h-36 fs-14" [clearable]="false" [(ngModel)]="currentYear"
                              (change)="changeCarousel($event,'year')">
                              <ng-option *ngFor="let yData of salaryYears" [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                          <div class="col-7">
                            <label for="" class="form-label">Month </label>
                            <ng-select class="form-ngselect h-36 fs-14" [clearable]="false" [(ngModel)]="currentMonth"
                              (change)="changeCarousel($event,'month')">
                              <ng-option *ngFor="let yData of listOfMonth" [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mx-auto"
                  *ngIf="!noSummary && !dashboard_attendance_loader; else all_summary">
                  <div class="flex-center d-flex align-items-center py-30 gap-20">
                    <img src="assets\images\bg\attendance_dashboard.svg" class="img-fluid">
                    <p class="fs-14 mb-0 fw-500">No data to show for your attendance summary</p>
                  </div>
                </div>
                <ng-template #all_summary>
                  <div class="col-12">
                    <div class="row row-8">
                      <div class="col-xl  col-lg-3 col-md-6 col-12">
                        <div class="bg41 p-8 radius-4 w-100 h-100">
                          <p class="mb-0 fs-20 text-accent2 lh-28 fw-500">
                            {{summaryData?.total_paid_days}}</p>
                          <span class="text-uppercase fs-10">total paid days</span>
                        </div>
                      </div>
                      <div class="col-xl  col-lg-3 col-md-6 col-12">
                        <div class="bg42  p-8 radius-4 w-100 h-100">
                          <p class="mb-0 fs-20 text-accent1 lh-28 fw-500">
                            {{summaryData?.present_days}}</p>
                          <span class="text-uppercase fs-10">Present days</span>
                        </div>
                      </div>
                      <div class="col-xl  col-lg-3 col-md-6 col-12">
                        <div class="bg43 p-8 radius-4 w-100 h-100">
                          <p class="mb-0 fs-20 text7 lh-28 fw-500">{{summaryData?.paid_leave}}
                          </p>
                          <span class="text-uppercase fs-10">Paid leaves </span>
                        </div>
                      </div>
                      <div class="col-xl  col-lg-3 col-md-6 col-12">
                        <div class="bg41 p-8 radius-4 w-100 h-100">
                          <p class="mb-0 fs-20 text-accent2 lh-28 fw-500">
                            {{summaryData?.week_off_days}}</p>
                          <span class="text-uppercase fs-10">Week-off</span>
                        </div>
                      </div>
                      <div class="col-xl  col-lg-3 col-md-6 col-12">
                        <div class="bg44 p-8 radius-4 w-100 h-100">
                          <p class="mb-0 fs-20 text-success lh-28  fw-500">
                            {{summaryData?.holidays_days}}</p>
                          <span class="text-uppercase fs-10">Holidays</span>
                        </div>
                      </div>
                      <div class="col-xl  col-lg-3 col-md-6 col-12">
                        <div class="bg45 p-8 radius-4 w-100 h-100">
                          <p class="mb-0 fs-20 text-warning lh-28 fw-500">
                            {{summaryData?.absent_days}}</p>
                          <span class="text-uppercase fs-10">Absent </span>
                        </div>
                      </div>
                      <div class="col-xl  col-lg-3 col-md-6 col-12">
                        <div class="bg46 p-8 radius-4 w-100 h-100">
                          <p class="mb-0 fs-20 text8 lh-28 fw-500">
                            {{summaryData?.unpaid_leave}}
                          </p>
                          <span class="text-uppercase fs-10">unPaid leaves </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="section-sidebar py-26 px-16" >
      <div class="row row-16">
        <div class="col-12 d-flex gap-8 align-items-center" *ngIf="!loader_announce">
          <div class="sq-24 avatar-circle cbg-3">
            <i class="icon-graph-square"></i>
          </div>
          <p class="mb-0 fs-14 fw-500">Check your financial dashboard</p>
          <a href="" class="link-primary ms-auto text-uppercase fw-600 fs-12 text-end"
            [routerLink]="'/dashboard'">Financial Dashboard</a>
        </div>
        <div class="col-12">
          <div class="{{!loader_announce ? 'card card-c2' : ''}} p-16 ">
            <app-home-loaders [loader]="'loader_attendance'"
              *ngIf="loader_announce; else inbox_item"></app-home-loaders>
            <ng-template #inbox_item>
              <div class="row row-12">
                <div class="col-12">
                  <p class="mb-0 fw-500 ">Inbox</p>
                </div>
                <div class="col">
                  <div class="w-100 hstack gap-18 bg-warning px-16 radius-6 py-8 card-hover1" [routerLink]="'/inbox'">
                    <i class="icon-stack text-warning fs-24"></i>
                    <div class="vstack">
                      <span class="fs-28 lh-sm fw-500" [countUp]="inboxPending">0</span>
                      <span class="fs-10 text-uppercase text-light-400">Pending</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="w-100 hstack gap-18 bg-warning bg21 px-16 radius-6 py-8 card-hover1"
                    [routerLink]="'/inbox'">
                    <i class="icon-chat-new text-accent2 fs-24"></i>
                    <div class="vstack">
                      <span class="fs-28 lh-sm fw-500" [countUp]="inboxUnread">0</span>
                      <span class="fs-10 text-uppercase text-light-400">Unread</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-12">
          <div class="{{!loader_announce ? 'card card-c2' : ''}} p-16 ">
            <app-home-loaders [loader]="'loader_attendance'"
              *ngIf="loader_announce; else request_item"></app-home-loaders>
            <ng-template #request_item>
            <div class="row row-12">
              <div class="col-12">
                <p class="mb-0 fw-500 ">Request</p>
              </div>
              <div class="col">
                <div class="w-100 hstack gap-18 bg-warning px-16 radius-6 py-8 card-hover1" [routerLink]="'/request'">
                  <i class="icon-stack text-warning fs-24"></i>
                  <div class="vstack">
                    <span class="fs-28 lh-sm fw-500" [countUp]="requestPending">0</span>
                    <span class="fs-10 text-uppercase text-light-400">Pending</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          </div>
        </div>
        <div class="col-12">
          <div class="{{!loader_announce && !loaderBday && !loaderAnniversary ? 'card card-c2' : ''}} p-16">
            <div class="row row-16">
              <div class="col-12">
                <p class="mb-0 fw-500" *ngIf="!loader_announce;">Events & Updates</p>
              </div>
              <app-home-loaders [loader]="'loader_attendance'" *ngIf="loader_announce && loaderBday && loaderAnniversary; else announce"></app-home-loaders>
              <ng-template #announce>
                <div class="col-md-6">
                  <div class="card card-c2 p-16 gap-4 card-hover" (click)="viewDetails('announce')">
                    <div class="hstack">
                      <span class="fs-28">{{announce_length}}</span>
                      <img class="sq-35 img-fluid ms-auto" src="/assets/images/helper/volume-high-helper.png"
                        alt="Announcement" srcset="">
                    </div>
                    <div class="fs-12 text-light-500">Announcements</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card card-c2 p-16 gap-4 card-hover" (click)="viewDetails('new_joiners')">
                    <div class="hstack">
                      <span class="fs-28">{{empJoinee?.length}}</span>
                      <img class="sq-35 img-fluid ms-auto" src="/assets/images/helper/user-hexogon-helper.png"
                        alt="New Joiner" srcset="">
                    </div>
                    <div class="fs-12 text-light-500">New joiners this week</div>
                  </div>
                </div>
              </ng-template>
              <app-home-loaders [loader]="'loader_attendance'" *ngIf="loaderBday; else bdayItem"></app-home-loaders>
              <ng-template #bdayItem>
                <div class="col-md-6">
                  <div class="card card-c2 p-16 bg-birthday h-100  card-hover" (click)="viewDetails('bday')">
                    <div class="row h-100">
                      <div class="col-12">
                        <span class="fs-12 fw-600">Birthdays</span>
                      </div>
                      <div *ngIf="empBday?.length == 0" class="col-12 ">
                        <div class="flex-center gap-8  py-16 h-100">
                          <img class="sq-35 img-fluid" src="/assets/images/helper/cake-helper.png" alt="" srcset="">
                          <p class="mb-0 fs-12 lh-base">
                            No birthdays today
                          </p>
                        </div>
                      </div>
                      <ng-container *ngIf="empBday?.length > 0">
                        <div class="col-12 mt-8">


                          <div class="avatar-overlap avatar-overlap-left" container="main-content">

                            <ng-container *ngFor="let aacpt of empBday,let i = index">
                              <img class="avatar"
                                *ngIf="i < 3 && aacpt.profile_image !=  '' && aacpt.profile_image !=  null"
                                src="{{aacpt.profile_image}}" [ngbPopover]="popContentSingle"
                                popoverClass="user-list single-user" triggers="hover" container="body">
                              <div class="avatar {{aacpt.color_code}}"
                                *ngIf="i < 3 && (aacpt.profile_image ==  ''|| aacpt.profile_image ==  null)"
                                [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                                container="body"><span>{{this.appService.getFirstChar(aacpt.first_name+'
                                  '+aacpt.last_name,2)}}</span></div>
                              <ng-template #popContentSingle>
                                <div class="user-details">
                                  <span class="user-label">{{aacpt.first_name+"
                                    "+(aacpt.middle_name!=null?aacpt.middle_name+"
                                    ":" ")+aacpt.last_name}}</span>
                                  <span class="user-code">EMP CODE: {{aacpt?.employee_code}} </span>
                                </div>
                              </ng-template>
                              <div *ngIf="i==3" class="avatar" [ngbPopover]="popContentMultilpe"
                                popoverClass="user-list multi-user" triggers="click" container="body">
                                <span>+{{this.appService.subEmp(empBday.length)}}</span>
                              </div>
                            </ng-container>
                            <ng-template #popContentMultilpe>
                              <ul>
                                <ng-container *ngFor="let re of empBday,let i = index">
                                  <ng-container *ngIf="i >= 3">
                                    <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                                      <img class="user-avatar" src="{{re.profile_image}}">
                                      <div class="user-details">
                                        <span class="user-label">{{re.first_name+"
                                          "+(re.middle_name!=null?re.middle_name+"
                                          ":" ")+re.last_name}}</span>
                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                      </div>
                                    </li>
                                    <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                                      <span
                                        class="user-avatar {{re.color_code}}">{{this.appService.getFirstChar(re.first_name+'
                                        '+re.last_name,2)}}</span>
                                      <div class="user-details">
                                        <span class="user-label">{{re.first_name+"
                                          "+(re.middle_name!=null?re.middle_name+"
                                          ":" ")+re.last_name}}</span>
                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                      </div>
                                    </li>
                                  </ng-container>
                                </ng-container>
                              </ul>
                            </ng-template>
                          </div>
                        </div>
                        <div class="col-12 mt-1">
                          <div class="fs-12 text-light-400 ">{{empBday.length}} Employees</div>
                        </div>
                      </ng-container>

                    </div>
                  </div>
                </div>
              </ng-template>

              <app-home-loaders [loader]="'loader_attendance'"
                *ngIf="loaderAnniversary; else anniversaryItem"></app-home-loaders>
              <ng-template #anniversaryItem>

                <div class="col-md-6">
                  <div class="card card-c2 p-16 h-100 bg-anniversary card-hover" (click)="viewDetails('anniversary')">
                    <div class="row h-100">
                      <div class="col-12">
                        <span class="fs-12 fw-600">Work Anniversaries</span>
                      </div>
                      <div *ngIf="empAnniversary?.length == 0" class="col-12 ">
                        <div class="flex-center gap-8  py-16 h-100">
                          <img class="sq-35 img-fluid" src="/assets/images/helper/badge-helper.png" alt="" srcset="">
                          <p class="mb-0 fs-12 lh-base">
                            No anniversaries today
                          </p>
                        </div>
                      </div>
                      <ng-container *ngIf="empAnniversary?.length > 0">

                        <div class="col-12 mt-8">
                          <div class="avatar-overlap avatar-overlap-left" container="main-content">

                            <ng-container *ngFor="let aacpt of empAnniversary,let i = index">
                              <img class="avatar"
                                *ngIf="i < 3 && aacpt.profile_image !=  '' && aacpt.profile_image !=  null"
                                src="{{aacpt.profile_image}}" [ngbPopover]="popContentSingle"
                                popoverClass="user-list single-user" triggers="hover" container="body">
                              <div class="avatar {{aacpt.color_code}}"
                                *ngIf="i < 3 && (aacpt.profile_image ==  ''|| aacpt.profile_image ==  null)"
                                [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                                container="body"><span>{{this.appService.getFirstChar(aacpt.first_name+'
                                  '+aacpt.last_name,2)}}</span></div>
                              <ng-template #popContentSingle>
                                <div class="user-details">
                                  <span class="user-label">{{aacpt.first_name+"
                                    "+(aacpt.middle_name!=null?aacpt.middle_name+"
                                    ":" ")+aacpt.last_name}}</span>
                                  <span class="user-code">EMP CODE: {{aacpt?.employee_code}} </span>
                                </div>
                              </ng-template>
                              <div *ngIf="i==3" class="avatar" [ngbPopover]="popContentMultilpe2"
                                popoverClass="user-list multi-user" triggers="click" container="body">
                                <span>+{{this.appService.subEmp(empAnniversary.length)}}</span>
                              </div>
                            </ng-container>
                            <ng-template #popContentMultilpe2>
                              <ul>
                                <ng-container *ngFor="let re of empBday,let i = index">
                                  <ng-container *ngIf="i >= 3">
                                    <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                                      <img class="user-avatar" src="{{re.profile_image}}">
                                      <div class="user-details">
                                        <span class="user-label">{{re.first_name+"
                                          "+(re.middle_name!=null?re.middle_name+"
                                          ":" ")+re.last_name}}</span>
                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                      </div>
                                    </li>
                                    <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                                      <span
                                        class="user-avatar {{re.color_code}}">{{this.appService.getFirstChar(re.first_name+'
                                        '+re.last_name,2)}}</span>
                                      <div class="user-details">
                                        <span class="user-label">{{re.first_name+"
                                          "+(re.middle_name!=null?re.middle_name+"
                                          ":" ")+re.last_name}}</span>
                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                      </div>
                                    </li>
                                  </ng-container>
                                </ng-container>
                              </ul>
                            </ng-template>
                          </div>
                        </div>
                        <div class="col-12 mt-1">
                          <div class="fs-12 text-light-400 ">{{empAnniversary.length}} Employees</div>
                        </div>
                      </ng-container>

                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>


        <div class="col-12">
          <div class="{{!loader_announce ? 'card card-c2' : ''}} p-16">
            <div class="row row-16">
              <app-home-loaders [loader]="'loader_attendance'"
                *ngIf="loader_announce; else yesterday_item"></app-home-loaders>
              <ng-template #yesterday_item>
                <div class="col-12">
                  <p class="mb-0 fw-500 ">Yesterday’s working hrs</p>
                </div>
                <div class="col-lg-10 mx-auto" *ngIf="noYesterDay && !loader_announce; else all_yesterday">
                  <div class="d-flex align-items-center gap-20 py-30">
                    <img src="assets\images\bg\yesterday_dashboard.svg" class="img-fluid">
                    <p class="fs-14 mb-0 fw-500">No data to show for your yesterday’s working
                      hrs
                    </p>
                  </div>
                </div>
                <ng-template #all_yesterday>
                  <div class="col-12">
                    <div class="hstack gap-8">
                      <div class="bg8 p-8 radius-4 w-50">
                        <p class="mb-0 text-accent2 fw-500">{{yesterDayData?.work_hour ==
                          null ?
                          '-'
                          :this.appService.timeFormatAttConvert(yesterDayData?.work_hour)}}
                        </p>
                        <span class="text-uppercase fs-10 text-nowrap">Actual work HRS</span>
                      </div>
                      <div class="card card-c2 w-100 flex-row border-0 bg9 p-8  ">
                        <div class="w-100">
                          <p class="mb-0 text7 fw-500">{{yesterDayData?.first_punch ==
                            null ?
                            '-' :
                            this.appService.timeFormatDisplay(yesterDayData?.first_punch)}}
                          </p>
                          <span class="text-uppercase fs-10">In time</span>
                        </div>
                        <div class="w-100">
                          <p class="mb-0 text7 fw-500">{{yesterDayData?.last_punch == null
                            ?
                            '-' :
                            this.appService.timeFormatDisplay(yesterDayData?.last_punch)}}
                          </p>
                          <span class="text-uppercase fs-10">out time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>
<!-- Info popup start -->
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>
</ng-container>
<!-- FBP -->
<ng-container *ngIf="!loader_quick && fbdeclarationFlag">
  <app-fbp-declaration-setup [fbdeclarationFlag]="fbdeclarationFlag" (fbpReqSave)="fbpReqSaveFunction($event)"
    (fbpReqClose)="fbpReqClose($event)"></app-fbp-declaration-setup>
</ng-container>

<ng-container *ngIf="notificationPanel">
  <app-non-mapped-empp [showSidePanel]="notificationPanel" [selectedList]="selectedList" [selectedName]="selectedName"
    (closePanel)="closePanel($event)" [searchNeeded]="'false'"></app-non-mapped-empp>
</ng-container>
<!-- Vertically centered modal -->

<app-checkin-verification *ngIf="verificationModal" [verificationModal]="verificationModal" [failedmodal]="failedmodal"
  [WebCamDataUrl]="WebCamDataUrl" [VerifyText]="VerifyText" [retake]="retake" [VerifyClass]="VerifyClass"
  (close)="verificationModal=false" (retakeFn)="retakeFn()"></app-checkin-verification>

<app-checkin-capture *ngIf="captureModal" [captureModal]="captureModal"
  [captureInfoModal]="this.employee_shift.selfie_login" (pictureTaken)="pictureTaken($event)"
  (close)="captureModal=false"></app-checkin-capture>
<ng-container *ngIf="notificationPanel">
  <app-non-mapped-empp [showSidePanel]="notificationPanel" [selectedList]="selectedList" [selectedName]="selectedName"
    (closePanel)="closePanel($event)" [searchNeeded]="'false'"></app-non-mapped-empp>
</ng-container>
