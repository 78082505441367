import { Component, OnInit, NgModule } from '@angular/core';
import { Router,Event, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { HomeService } from 'src/app/@core/services/home.service';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // Variables General
  employee_id!    : number;
  employee_data   :any
  currency        = 'INR'
  imageUrl        :any
  userDataSession :any
  limit           = 5
  offset          = 0
  yearOfCurrent   : any
  yearRange       :any
  routingDomain   : boolean = false

  // Loaders
  loader_employee      = true
  loader_salary        = true
  loader_financial     = true
  loader_reimbursement = true
  loader_tax           = true
  loader_announce      = true
  loader_announce_scroll= false

  // Employee Summary
  noemployeedata      : boolean = false

  // Tax Summary
  taxData         : any
  taxSummary      : any
  changedYear     : any
  placeholderData : any
  notaxdata       : boolean = false

  // Salary Summary
  salarySummary   :any
  salaryData      : any
  currentMonth    = new Date().toLocaleString('en-us', { month: 'long' })
  currentYear     = Number(new Date().getFullYear())
  listOfMonth= ["January","February","March","April","May","June","July",
  "August","September","October","November","December"];
  nosalarydata    : boolean = false
  initial_check   : boolean = true
  salaryYears     : any

  // Financial Year
  nextYears       : any
  selectedYear    : any
  typeSelect      = 'gross_pay'
  selectedTypeVal = 'Gross Pay'
  financialData    : any = []
  financialLabels  : any = []
  nofinancialdata  : boolean = false
  placeFinancial   : any
  withoutFY        : any

  // Reimbursement
  placeholderReimburse : any
  yearSelect           : any
  reimburseData        : any
  noremibursedata      : boolean = false
  reimburseSummary     : any

  // Announcement
  noannounce       : boolean = false
  announceData     : any
  inboxItems       = 0
  announce_length  = 0
  announcementList : any = []
  // infinityloader   = false

  constructor(
    private homeService : HomeService,
    public appservice : AppService,
    public messageService : MessageService,
    private router : Router,
    public userData : AuthService,
    public inboxService :  InboxService
  ) {
    this.inboxLiveUpdate();
   }

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Month';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Rupees';
  animations: boolean = true;
  legendPosition: any = 'below';
  legendTitle: string = '';
  barPadding: number = 40;
  movetoPoint = 0;
  activeItem: any = [];
  year: any = true;
  month: any = true;
  salaryLabels : any = []
  taxLabels : any = []
  colorScheme: any = ['#0078CE', '#B8D0EC','#EEE']
  salary_summary_colorScheme: any = ['#6166DB', '#4CBBBB','#E4E8EA']
  colorScheme2: any = {
    domain: ['#0078CE']
  };

  onSelect(event: any) {

  }
  ngOnInit(): void {
    const subdomain = window.location.origin
      if(subdomain.endsWith('.staging-skizzlehr.in')){
        this.routingDomain = true
      }else{
        this.routingDomain = false
      }

    if(document.getElementsByTagName('body')[0]){
      document.getElementsByTagName('body')[0].classList.remove('no-notifications')
    }
    // Get Financial Year
    this.yearOfCurrent = Number(this.appservice.getFinancialYear());

    // Year Range
    this.yearRange = this.appservice.getFinancialYearRange(this.yearOfCurrent)

    // Placeholder for NGSELECT of Financial Year
    this.placeFinancial = "FY "+ this.yearRange
    this.withoutFY = this.placeFinancial.slice(3)

    // Placeholder for NGSELECT of Tax
    this.placeholderData = "FY "+ this.yearRange

    // Placeholder for NGSELECT of Reimbursement
    this.placeholderReimburse = "FY "+ this.yearRange

    // Employee data from session
    let userData  = JSON.parse(this.userData.getUserData());

    this.employee_id = userData.employee_id
    this.currency = userData.currency
    this.imageUrl = userData.profile_image
    this.userDataSession = userData

    if(this.employee_id != undefined){
      // Employee Data API
      this.getEmployeeData(this.employee_id);
       // Salary Summary API
      this.getSalarySummary(this.employee_id,this.currentMonth,this.currentYear,this.initial_check)
      // Tax Data API
      this.getTaxSummary(this.employee_id,this.yearOfCurrent);
      // Financial Year API
      this.getFinancialSummary(this.employee_id,this.typeSelect,this.yearOfCurrent)
      // Reimbursement Summary API
      this.getReimbursementSummary(this.employee_id,this.yearOfCurrent)
      // Announcement Summary API
      this.getAnnouncementSummary(this.employee_id)
      // Inbox Service for Count
      this.inboxService.InboxSocket();
    }

    // Current and Coming Years
    this.nextYears = this.appservice.getFinancialYearDropdown()
    // Years for Salary Summary
    this.salaryYears = this.appservice.generateArrayOfYears();
  }

  // INBOX
  inboxLiveUpdate(){

    this.inboxService.inbox_item.subscribe(result =>{
      if(result.event.approved_status == 'Pending'){
        ++this.inboxItems
      }else{
        --this.inboxItems
      }
    })

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          // Hide loading indicator
          if(event.url !== '/home'){
           this.inboxService.closeSocket();
          }
      }
    });
  }

  // Employee Data API
  getEmployeeData(id:any){
    this.loader_employee = true
    this.homeService.getPersonalSummary(id).subscribe((res:any) =>{
      this.employee_data = res.data
      this.loader_employee = false

      // No data
      if(this.employee_data.salary_structure == null || this.employee_data.salary_structure === false){
        this.noemployeedata = true
      }else{
        this.noemployeedata = false
      }
    })
  }

  // Close Employee
  closeEmp(){
    const element = <HTMLElement> document.getElementById("empShow")
    if(element){
      element.style.display = 'none'
    }
  }

  // Salary Summary
  getSalarySummary(id:any,month:any,year:any,initial_check:any){
    this.loader_salary = true
    this.salaryLabels = ['Net Pay','Deductions']
    this.homeService.getSalarySummary(id,month,year,initial_check).subscribe((res:any)=>{
      this.salaryData = res.data
      let summary = res.summary
      this.salarySummary = summary.map((a:any)=> a.value)
      this.loader_salary = false
      if(res.data.month){
        this.currentMonth = res.data.month
        this.currentYear = res.data.year
      }
      // No data
      if(this.salarySummary?.length == 0){
        this.nosalarydata = true
      }else{
        this.nosalarydata = false
      }
    })
  }

  // Download of Salary Slip
  downLoad(url:any){
    if(url != null){
      this.appservice.downloadFile(url,'Payslip')
    }
  }

  // Change in Month and Year for Salary Summary (Inside)
  changeCarousel(key:any,field:any){
    if(field == 'month'){
      this.currentMonth = key
    }else{
      this.currentYear = key
    }
    this.initial_check = false
    this.getSalarySummary(this.employee_id,this.currentMonth,this.currentYear,this.initial_check)
  }

  // Inbox Naviagtion
  inboxNavigate(){
    this.router.navigateByUrl('/inbox');
  }

  // Tax Summary
  getTaxSummary(id:any,year:any){
    this.loader_tax = true
    this.taxLabels = ['Paid','Remaining']
    this.homeService.getTaxSummary(id,year).subscribe((res:any)=>{
      this.taxData = res.data
      let summary = res.summary
      this.taxSummary = summary.map((a:any)=> a.value)
      this.loader_tax = false

      // No data
      if(this.taxSummary?.length == 0){
        this.notaxdata = true
      }else{
        this.notaxdata = false
      }
    })
  }

  // Financial Year Change for Tax
  yearChange(value:any){
    this.changedYear = Number(value.toString().substr(0,4))
    this.placeholderData = ''
    this.placeholderData = "FY "+value
    this.getTaxSummary(this.employee_id,this.changedYear)
  }

  // Financial Summary
  getFinancialSummary(id:any,type:any,year:any){
    this.loader_financial = true
    this.homeService.getFinancialSummary(id,type,year).subscribe((res:any)=>{
      this.loader_financial = false
      this.financialData = []
      this.financialLabels = []
      if(res.summary?.length != 0){
        res.summary.forEach((item:any)=>{
          const first = item?.name.slice(0, 3)
          const second  = item?.name.slice(-4)
          this.financialData.push(first+" "+second)
          this.financialLabels.push(item?.value)
        })
        this.nofinancialdata = false
      }else{
        this.nofinancialdata = true
      }
    })
  }

  // Financial Year Type Change
  typeChange(value:any){
    this.selectedYear = this.placeFinancial.substring(3,7)
    this.typeSelect = value
    this.selectedTypeVal = value == 'gross_pay' ? 'Gross Pay' : 'Net Pay'
    this.getFinancialSummary(this.employee_id,this.typeSelect,this.selectedYear)
  }

  // Financial Year Change in Financial Summary
  yearChangeFinancial(value:any){
    this.selectedYear  = Number(value.toString().substr(0,4));
    this.placeFinancial = ''
    this.placeFinancial = "FY "+ value
    this.withoutFY = value
    this.getFinancialSummary(this.employee_id,this.typeSelect,this.selectedYear)
  }

  // Reimbursement Summary
  getReimbursementSummary(id:number,year:any){
    this.loader_reimbursement = true
    this.homeService.getReimbursementSummary(id,year).subscribe((res:any)=>{
      this.loader_reimbursement = false
      this.reimburseData = res.data
      this.reimburseSummary = res.summary
      // No data
      if(this.reimburseSummary?.length == 0){
        this.noremibursedata = true
      }else{
        this.noremibursedata = false
      }
    })
  }

  // Reimbursement Year Change
  yearReimburseChange(value:any){
    this.yearSelect = Number(value.toString().substr(0,4))
    this.placeholderReimburse = "FY "+value
    this.getReimbursementSummary(this.employee_id,this.yearSelect)
  }

  // Announcement
  getAnnouncementSummary(id:number){
    if(this.offset == 0){
      this.loader_announce = true
    }
    this.homeService.getAnnouncementSummary(id,this.limit,this.offset).subscribe((res:any)=>{
      this.announcementList = [];
      if(this.offset ==0){
        this.announceData = [];
      }
      for(let item of res.results){
        this.announcementList.push(item)
      }

      this.announce_length = res.results.length
      this.addItems(this.announce_length)

      this.inboxItems = res.unread_count
      this.loader_announce = false
      this.loader_announce_scroll = false

      if(this.announceData?.length === 0){
        this.noannounce = true
      }
      else{
        this.noannounce = false
      }

    })
  }

  addItems(itemlist:any) {
    this.loader_announce = false
    for (let i = 0; i < itemlist; ++i) {
      this.announceData.push(this.announcementList[i]);
    }
  }

  onScrollDown(){
    this.loader_announce_scroll = true

      this.offset += this.limit;
      if(this.announce_length){
        this.getAnnouncementSummary(this.employee_id)
      }
  }

  format = ()=>{
    const currencyFormatter = new Intl.NumberFormat('en-IN', { style: 'currency', currency: this.currency });
    const formatToParts = currencyFormatter.formatToParts(0);
    const currencySymbolPart = formatToParts.find(part => part.type === 'currency');
    const currencySymbol = currencySymbolPart ? currencySymbolPart.value : this.currency;
    return currencySymbol;
  }

}
