<div class="side-panel-body" style="overflow-y: auto;">
    <div class="container-fluid p-32 ">
        <div class="col-12">
            <h5>DETAILS</h5>
        </div>
        <div class="card card-c2 p-32">
            <div class="row  row-16">

                <div class="col-12 d-flex ">
                    <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
                </div>
                <div class="col-12 mt-8">
                    <h5 class="mb-8">Regularization<span *ngIf="data?.is_cancel ==true">&nbsp;Cancellation</span>&nbsp;Request
                        Approval</h5>
                    <span class=" badge-custom {{data?.request_type_color_code}}">Regularization<span
                            *ngIf="data?.is_cancel ==true">&nbsp;Cancellation</span>&nbsp;Request</span>
                </div>
                <div class="col-12">
                    <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                        <div class="row row-24" *ngIf="data?.is_cancel==false">
                            <div class="col-12">
                                <div class="d-flex">
                                    <span class="title">ATTENDANCE DETAILS</span>
                                    <span (click)="timegraph();showTrend = true"
                                        class="text-accent2 pointer ms-auto fs-12 fw-500"><i
                                            class="bi bi-graph-up me-1"></i>Regularization request Trend </span>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-0">
                                    <div class="table-responsive radius-4">
                                        <table
                                            class="table td-12 sticky-header td-white-space td-fs-14
                                            tr-fs-14  sticky-first-col sticky-last-col table-striped table-sm form-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        <input class="form-check-input checkbox-16 m-0" type="checkbox"
                                                            value="" (click)="checkall($event)"
                                                            [checked]="checkAllSelected()">
                                                    </th>
                                                    <th class="fw-600" scope="col">Date</th>
                                                    <th class="fw-600" scope="col">Check-in</th>
                                                    <th class="fw-600" scope="col">Check-out</th>
                                                    <th class="fw-600" scope="col">Status</th>
                                                    <th class="fw-600" scope="col">View</th>
                                                </tr>
                                            </thead>
                                            <tbody class="cell-16 height-full">
                                                <tr
                                                    *ngFor="let request of $any(regularization?.requests | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                                    <td class="text-center">
                                                        <input *ngIf="request?.status != 'Cancelled'"
                                                            class="form-check-input mt-1 checkbox-16"
                                                            [(ngModel)]="request.checked" type="checkbox">
                                                    </td>
                                                    <td>{{this.appService.dateFormatDisplay(request?.regulized_date)}}
                                                    </td>
                                                    <td>{{this.appService.timeFormatDisplay(request?.regulized_checkin_time )}}</td>
                                                    <td>{{this.appService.timeFormatDisplay(request?.regulized_checkout_time)}}</td>
                                                    <td>
                                                        <span *ngIf="request?.status == 'Pending'"
                                                            class="badge badge-warning fs-14 px-16 py-1">Pending</span>
                                                        <span *ngIf="request?.status == 'Accepted'"
                                                            class="badge badge-success fs-14 px-16 py-1">Accepted</span>
                                                        <span *ngIf="request?.status == 'Rejected'"
                                                            class="badge badge-danger fs-14 px-16 py-1">Rejected</span>
                                                        <span *ngIf="request?.status == 'Cancelled'"
                                                            class="badge badge-secondary fs-14 px-16 py-1">Cancelled</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <i (click)="viewData=request;viewDetails = true"
                                                            class="bi bi-eye text-accent1 fs-20"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <ngb-pagination *ngIf="regularization?.requests?.length>pageSize"
                                class="d-flex justify-content-end" [collectionSize]="regularization?.requests?.length"
                                [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                                [pageSize]="pageSize"></ngb-pagination>
                        </div>
                        <div class="row row-16" *ngIf="data?.is_cancel==true">
                            <div class="col-12">
                                <div class="d-flex">
                                    <span class="title">ATTENDANCE DETAILS</span>
                                    <span (click)="timegraph();showTrend = true"
                                        class="text-accent2 pointer ms-auto fs-12 fw-500"><i
                                            class="bi bi-graph-up me-1"></i>Regularization request Trend </span>
                                </div>
                            </div>
                            <div class="col-12" *ngFor="let item of regularization?.requests">
                                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                    <div class="row row-16">
                                        <div class="col-12"><span class="title">Original Details</span></div>
                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Shift</div>
                                            <div class="detail-desc">{{item?.original_data == null ?    '-':item?.original_data?.shift_name+'('+item?.original_data?.shift_code+')'}}</div>
                                        </div>
                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Hours</div>
                                            <div class="detail-desc">{{item?.original_data == null? '-' :
                                                this.appService.hhmmTimeFormat(item?.original_data?.work_hour)}}</div>
                                        </div>
                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Check-in</div>
                                            <div class="detail-desc">{{(item?.original_data == null ? '-' :
                                                this.appService.timeFormatDisplay(item?.original_data?.first_punch))}}
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Check-out</div>
                                            <div class="detail-desc">{{(item?.original_data == null ? '-' :
                                                this.appService.timeFormatDisplay(item?.original_data?.last_punch))}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" *ngFor="let item of regularization?.requests">
                                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                                    <div class="row row-16">
                                        <div class="col-12"><span class="title">Regularization request</span>
                                        </div>
                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Check-in</div>
                                            <div class="detail-desc">
                                                {{(this.appService.timeFormatDisplay(item?.check_in))}}</div>
                                        </div>
                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Check-out</div>
                                            <div class="detail-desc">
                                                {{(this.appService.timeFormatDisplay(item?.check_out))}}</div>
                                        </div>
                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Hours</div>
                                            <div class="detail-desc">{{this.appService.hhmmTimeFormat(item?.hours)}}
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-12">
                                            <div class="detail-title">Shift</div>
                                            <div class="detail-desc">{{item?.shift_name+' ('+item?.shift_code+')'}}
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="detail-title">Reason</div>
                                            <div class="detail-desc">{{item?.reason}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="reqStatus == 'Pending'" class="col-12 " [formGroup]="form">
                    <textarea name="" id="" rows="3" placeholder="{{this.messageService.descriptiontext('comment')}}"
                        formControlName="comment" [ngClass]="{ 'is-invalid': f.comment.errors}"
                        class="form-control textarea-comment"></textarea>
                    <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
                        {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                </div>
                <div class="col-12 d-flex gap-16 " *ngIf="data?.is_cancel==true && reqStatus == 'Pending'">
                    <button class="ms-auto btn btn-outline-danger text-uppercase btn-sm fw-400"
                        (click)="confirmCancel('Rejected')">Decline</button>
                    <button class=" btn btn-primary text-uppercase btn-sm fw-400"
                        (click)="confirmCancel('Accepted')">Confirm</button>
                </div>
                <div class="col-12 d-flex gap-16 sticky-footer py-16" *ngIf="data?.is_cancel==false && reqStatus == 'Pending'">
                    <div class="d-flex gap-16">
                        <p class="mb-0 d-flex align-items-center gap-6">
                            <span class="fw-600">{{countSelected()}}</span>
                            <span class="fs-14">Requests selected</span>
                        </p>
                        <button class="btn btn-outline-danger btn-icon fs-14 radius-2" [disabled]="btndisable"
                            (click)="approveReject('Reject')"> <i class="icon-close-circle"></i>
                            Reject</button>
                        <button class="btn btn-outline-success btn-icon fs-14 radius-2" [disabled]="btndisable"
                            (click)="approveReject('Accept')"> <i class="icon-check-circle"></i>
                            Approve</button>
                    </div>
                    <button class=" btn btn-primary text-uppercase ms-auto btn-sm fw-400"
                        (click)="confirm()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="side-panel" style="--sidepanel-width:40.625rem;" [class.side-pane-active]='viewDetails === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>View</h5>
            <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16">
                    <div class="col-12">
                        <div class="mb-0 fw-500">REGULARIZATION REQUEST DETAILS</div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16">
                                <div class="col-12 d-flex">
                                    <span
                                        class="title">{{this.appService.dateFormatDisplay(viewData?.regulized_date)}}</span>
                                </div>
                                <div class="col-12">
                                    <div class="card card-c2 p-16">
                                        <div class="row row-24">
                                            <div class="col-sm-12 col-12">
                                                <div class="detail-title">ORIGINAL DETAILS</div>
                                            </div>
                                            <ng-container *ngIf="viewData?.original_data ==null">
                                                <div class="col-sm-6 col-12">
                                                    <div class="detail-title">Check-in date</div>
                                                    <div class="detail-desc">
                                                        {{(viewData?.original_checkin_date
                                                        == null ||
                                                        viewData?.original_checkin_date ==
                                                        '') ? '-' :
                                                        this.appService.dateFormatDisplay(viewData?.original_checkin_date)}}
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="detail-title">Check-in time</div>
                                                    <div class="detail-desc">
                                                        {{(viewData?.original_checkin_time
                                                        == null ||
                                                        viewData?.original_checkin_time ==
                                                        '') ? '-' :
                                                        this.appService.timeFormatDisplay(viewData?.original_checkin_time)}}
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="detail-title">Check-out date</div>
                                                    <div class="detail-desc">
                                                        {{(viewData?.original_checkout_date
                                                        == null ||
                                                        viewData?.original_checkout_date ==
                                                        '') ? '-'
                                                        :this.appService.dateFormatDisplay(viewData?.original_checkout_date)}}
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="detail-title">Check-out time</div>
                                                    <div class="detail-desc">
                                                        {{(viewData?.original_checkout_time
                                                        == null ||
                                                        viewData?.original_checkout_time ==
                                                        '') ? '-' :
                                                        this.appService.timeFormatDisplay(viewData?.original_checkout_time)}}
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="detail-title">Shift</div>
                                                    <div class="detail-desc">
                                                        {{(viewData?.shift_name == null ||
                                                        viewData?.shift_name == '') ? '-' :
                                                        viewData?.shift_name+'
                                                        ('+viewData?.shift_code+')'}}
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="detail-title">Hours</div>
                                                    <div class="detail-desc">
                                                        {{(viewData?.original_hour == null
                                                        || viewData?.original_hour == '') ?
                                                        '-' :
                                                        this.appService.hhmmTimeFormat(viewData?.original_hour)}}
                                                    </div>
                                                </div>
                                            </ng-container>


                                            <div class="col-sm-12 col-12">
                                                <div class="detail-title">REGULARIZATION REQUEST</div>

                                            </div>
                                            <div class="col-sm-6 col-12">
                                                <div class="detail-title">Check-in date</div>
                                                <div class="detail-desc">
                                                    {{this.appService.dateFormatDisplay(viewData?.regulized_checkin_date)}}</div>
                                            </div>
                                            <div class="col-sm-6 col-12">
                                                <div class="detail-title">Check-in time</div>
                                                <div class="detail-desc">
                                                    {{this.appService.timeFormatDisplay(viewData?.regulized_checkin_time)}}</div>
                                            </div>
                                            <div class="col-sm-6 col-12">
                                                <div class="detail-title">Check-out date</div>
                                                <div class="detail-desc">
                                                    {{this.appService.dateFormatDisplay(viewData?.regulized_checkout_date)}}</div>
                                            </div>
                                            <div class="col-sm-6 col-12">
                                                <div class="detail-title">Check-out time</div>
                                                <div class="detail-desc">
                                                    {{this.appService.timeFormatDisplay(viewData?.regulized_checkout_time)}}</div>
                                            </div>
                                            <div class="col-sm-6 col-12">
                                                <div class="detail-title">Hours</div>
                                                <div class="detail-desc">
                                                    {{this.appService.hhmmTimeFormat(viewData?.hours)}}</div>
                                            </div>
                                            <div class="col-sm-6 col-12">
                                                <div class="detail-title">Status</div>
                                                <span *ngIf="viewData?.status == 'Pending'"
                                                    class=" fs-14 px-16 py-1 badge badge-warning">Pending</span>
                                                <span *ngIf="viewData?.status == 'Accepted'"
                                                    class=" fs-14 px-16 py-1 badge badge-success">Accepted</span>
                                                <span *ngIf="viewData?.status == 'Rejected'"
                                                    class=" fs-14 px-16 py-1 badge badge-danger">Rejected</span>
                                                <span *ngIf="viewData?.status == 'Cancelled'"
                                                    class=" fs-14 px-16 py-1 badge badge-secondary">Cancelled</span>

                                            </div>
                                            <div class="col-12">
                                                <div class="detail-title">Reason</div>
                                                <div class="detail-desc">{{viewData?.reason}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showTrend === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Attendence Regularization requests</h5>
            <a class="toggle-panel" (click)="showTrend = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-24">
                    <div class="col-12 ">
                        <div class="card card-c2 p-24">
                            <div class="d-flex mb-16">
                                <div class=" fs-24">Attendence Regularization requests</div>
                                <ng-select class="form-ngselect ms-auto fs-14"
                                    placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                                    (change)="getRegularizationTrendDetails($event)" [(ngModel)]="selectedYearDisp"
                                    [ngModelOptions]="{standalone: true}">
                                    <ng-option class="fs-12" *ngFor="let year of yearList"
                                        [value]="year.k">{{year.k}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-12 " *ngIf="!graphLoader">
                                <app-bar-chart [data]="multi" [labels]="xAxisLabel"
                                    [yText]="yAxisLabel" [id]="5"></app-bar-chart>
                            </div>
                            <div class="col-12 " *ngIf="graphLoader">

                                <app-graph-loader></app-graph-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>