<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Assign Salary Structure</h5>
        <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <ng-container  *ngIf="!loader">
    <aside class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24" >
            <li [ngbNavItem]="1">
          <a ngbNavLink class="step"
            [class]="{'success':salaryForm['controls'].statutory.valid || salaryForm['controls'].statutory.disabled}">
                    <div class="count">1</div>
                    <div class="content">
                        <span>Step 1</span>
                        <h6>Employee statutory</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
            <app-ss-emp-statutory [employeeData]="employeeData" [empSalaryData]="empSalaryData"
              (changeTab)="changeTab($event)" [formGp]="statutoryForm"
              (StatutoryError)="StatutoryError($event)"></app-ss-emp-statutory>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
          <a ngbNavLink class="step"
            [class]="{'success':(this.salaryForm.getRawValue().structure.ctc!='' && this.salaryForm.getRawValue().structure.pay_group)}">
                    <div class="count">2</div>
                    <div class="content">
                        <span>Step 2</span>
                        <h6>Salary structure</h6>
                    </div>
                </a>
              <ng-template ngbNavContent>
               <app-ss-salary-structure [employeeData]="employeeData" [empSalaryData]="empSalaryData" [payGroupDD]="payGroupDD" [formGp]="structureForm" (changeTab)="changeTab($event)" (defaultData)="makeDefaultSettings()" [currentCTC]="currentCTC" [currentPayGp]="currentPayGp" ></app-ss-salary-structure>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
          <a ngbNavLink class="step"
            [class]="{'success':(salaryForm['controls'].structure.valid || salaryForm['controls'].structure.disabled) && exceedCTC==false}">
                    <div class="count">3</div>
                    <div class="content">
                        <span>Step 3</span>
                        <h6>Complete salary details</h6>
                    </div>
                </a>
              <ng-template ngbNavContent>

            <app-ss-salary-details [employeeData]="employeeData" [empSalaryData]="empSalaryData"
              [formGp]="structureForm" [payGroupDD]="payGroupDD" [calculationData]="calculationData"
              [gross_benefits]="gross_benefits" [other_benefits]="other_benefits" [contributions]="contributions"
              [recurring_deductions]="recurring_deductions" (submitmethod)="submitForm()"
              [componentLoader]="componentLoader" [statutoryLockedinfo]="statutoryLockedinfo"
              [calculatedAmount]="calculatedAmount" [variableComponent]="variableComponent" [salaryForm]="salaryForm"
              [empid]="empid" (recalculateComponent)="recalculateComponent($event)" [fbpError]="fbpError"
              [fbpYearError]="fbpYearError" [monthlyError]="monthlyError"
              [yearlyError]="yearlyError"></app-ss-salary-details>
              </ng-template>
            </li>

        </ul>

    </aside>
    <main class=" config-container">
        <div [ngbNavOutlet]="nav" ></div>

    </main>
    </ng-container>
</div>
