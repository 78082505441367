<form class="">
  <div class="container-fluid p-32 form-container">
    <app-view-loader *ngIf="loader1"></app-view-loader>
    <div class="row row-16 mb-16">
      <ng-container *ngIf="nodata && !loader1">

        <div class="col-12 d-flex">
          <p class="text-uppercase fw-500 mb-0"> INVESTMENT DECLARATION</p>
        </div>
        <div class="col-10 mx-auto">
          <div class="flex-column text-center d-flex flex-center text-light-500 "
            style="min-height:calc(100vh - 15rem);">
            <img style="height:60vh;max-height: 15rem;" src="assets\images\bg\1 Working remotely.png" alt=""
              class="img-fluid d-flex mx-auto pb-32">
            <div class="fw-600 fs-14 btn-primary btn"><a (click)="addITdeclaration('add')">ADD INVESTMENT
                DECLARATION</a></div>

          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!nodata && !loader1">
        <div class="col-12 d-flex">
          <p class="text-uppercase fw-500 mb-0"> CONFIRMED INVESTMENT DECLARATION</p>
          <a class="ms-auto link-primary fs-14 d-flex align-items-center" (click)="addITdeclaration('edit')"><i
              class="icon-pencil-square me-1"></i> Edit</a>
        </div>
        <div class="col-12">
          <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
            <p class="mb-0">If employees have not submitted proofs for declaration, kindly ensure to get the proof
              before F&F process.
              Please note that the updated investment declarations will be considered for tax calculation during the
              F&F.
            </p>
            <div class="btn-close" (click)="alertShow='hide'"></div>
          </div>
        </div>
        <app-view-investment-declaration [from]="from" [investmentSingleData]="investmentSingleData" [viewHeader]=""
          [hra80ggSingleData]="hra80ggSingleData" [cahpt80CSingleData]="cahpt80CSingleData"
          [otherSourceSingleData]="otherSourceSingleData" [deductionSingleData]="diductionSingleData"
          [incomeLossSingleData]="incomeLossSingleData" [hra80ggFlag]="hra80ggFlag" [exeption_50]="exeption_50"
          [cityName]="" [requestitem]="" [investmentcard1]="true" [declareStatus]="'Active'" [taxData]="taxData"
          [hra80gg]="hra80gg" [loader1]="loader1" [currency]="currency"
          (investmentcardClose)="closeSidePanel($event)"></app-view-investment-declaration>
      </ng-container>
    </div>
  </div>

  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="submitItDeclaration()">NEXT</button>
  </footer>
</form>

<!-- test start -->
<ng-container *ngIf="showComponent">
  <div class="side-panel nesteded-config" style="--sidepanel-width:95rem;"
    [class.side-pane-active]='showComponent === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <a class="toggle-panel" (click)="showComponent = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <app-confirmed-it-declaration-setup [empId]="empId" [reqType]="reqType" [reqId]="investmentSingleData?.id" [currency]="currency"
        (close)="close()"></app-confirmed-it-declaration-setup>
    </div>
  </div>
</ng-container>
<!-- test end -->