<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <app-c1-loader *ngIf="resigloader"></app-c1-loader>
        <div class="row row-16 mb-16" *ngIf="!resigloader">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">RESIGNATION DETAILS</p>
            </div>
            <div class="col-12" *ngIf="!allowSave">
                <div role="alert" class="alert alert-warning mb-0 show">
                    <p class="mb-0"> {{errorMsg}}
                    </p>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2 details-card  px-24 py-16 ">
                    <div class="row row-24">
                        <div class="col-12">
                            <span class="title">SUMMARY</span>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Employee name</div>
                            <div class="detail-desc">{{resignationData?.full_name}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Employee code</div>
                            <div class="detail-desc">{{resignationData?.employee_code}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Date of joining</div>
                            <div class="detail-desc">
                                {{this.appService.dateFormatDisplay(resignationData?.date_of_joined)}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Department</div>
                            <div class="detail-desc">{{resignationData?.department}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Designation</div>
                            <div class="detail-desc">{{resignationData?.designation}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Employee status at resignation</div>
                            <div class="detail-desc">{{resignationData?.employment_status}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Resignation date</div>
                            <div class="detail-desc">
                                {{this.appService.dateFormatDisplay(resignationData?.resignation_date)}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Notice period</div>
                            <div class="detail-desc">{{resignationData?.notice_period}} day(s)</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Leaving date</div>
                            <div class="detail-desc">
                                {{this.appService.dateFormatDisplay(resignationData?.leaving_date)}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Last salary processed on</div>
                            <div class="detail-desc">{{resignationData?.last_salary_processed_on == null ? '-':
                                resignationData?.last_salary_processed_on == '' ? '-' :
                                this.appService.dateFormatDisplay(resignationData?.last_salary_processed_on)}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Salary hold months</div>
                            <div class="detail-desc">{{salary_hold == '' ? '-' : salary_hold.join(', ')}}</div>
                        </div>
                        <div class="col-md-3 col-sm-4 col-6">
                            <div class="detail-title">Salary hold amount</div>
                            <div class="detail-desc">{{resignationData?.salary_hold_amount == null ? '-':
                                resignationData?.salary_hold_amount == '' ? '-' : resignationData?.salary_hold_amount |
                                currency : resignationData?.currency}} </div>
                        </div>

                    </div>
                </div>
            </div>
           <div class=" col-lg-12 form-row" >
              <label for="" class="form-label required">Settlement month</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                  ngbPopover="Attendance month is the settlement month" popoverClass="popover-default popover-info"
                  triggers="mouseenter:mouseleave" container="body"></i></span>
              <!-- <input [readonly]="true" type="text" class="form-control " placeholder="" placeholder=" " formControlName="pay_month"> -->
              <div class=" col ">
                <input type="hidden" class="form-control" formControlName="pay_year"
                  [(ngModel)]="formGp.value.pay_year" >
                <input type="hidden" class="form-control"  [(ngModel)]="formGp.value.pay_month" formControlName="pay_month">
               
        </div>
                <div ngbDropdown class="input-dropdown four-dropdown " container="body">
                  <button type="button" class="card card-c2  fs-14" ngbDropdownToggle>
                    <span class="">{{formGp.value.pay_month}} {{formGp.value.pay_year}} </span>
                  </button>
                  <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                    <div class="row">
                      <div class="col-5">
                        <label for="" class="form-label">Year </label>
                        <ng-select class="form-ngselect fs-14" placeholder="Select year"
                           [(ngModel)]="formGp.value.pay_year"
                          (change)="changeAttendanceMonth($event,'year')"    [ngClass]="{ 'is-invalid': (saveBtnClicked || f.pay_month?.touched || f.pay_month?.dirty) &&  f.pay_month?.errors }" [ngModelOptions]="{standalone: true}">
                          <ng-option *ngFor="let yData of yearList" [value]="yData">{{yData}}</ng-option>
                        </ng-select>
                        <div *ngIf="(saveBtnClicked || f.pay_month?.touched || f.pay_month?.dirty) && f.pay_month?.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.pay_month?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                            </div>
                      </div>
                      <div class="col-7">
                        <label for="" class="form-label">Month </label>
                        <ng-select class="form-ngselect  fs-14" placeholder="Select month" [(ngModel)]="formGp.value.pay_month"

                          (change)="changeAttendanceMonth($event,'month')" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.pay_month?.touched || f.pay_month?.dirty) &&  f.pay_month?.errors  }" [ngModelOptions]="{standalone: true}">
                          <ng-option *ngFor="let yData of listOfMonth" [value]="yData.value">{{yData.value}}</ng-option>
                        </ng-select>
                        <div *ngIf="(saveBtnClicked || f.pay_month?.touched || f.pay_month?.dirty) && f.pay_month?.errors  "
                        class="invalid-feedback">
                        <div *ngIf="f.pay_month?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                            <!-- <div *ngIf="f.pay_month.errors?.monthValidOne">Settlement month should be the last working month or a future month.
                            </div>
                            <div *ngIf="f.pay_month.errors?.monthValidTwo">Selected month salary is already processed.
                            </div> -->

                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    <!-- </div> -->
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="formGp.invalid || !allowSave"
            (click)="submitResignationDetails()">NEXT</button>
    </footer>
</form>