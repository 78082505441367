<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row  mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Page Access</h3>
            <p class="text-helper mb-md-0">Set page access rights and actions for specific pages based on the user's role.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class=" " style="width:22.6875rem;">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='groupsearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchpageaccess($event)" [isLoading]="isLoading"></app-searchbar>
                </div>

                <button *ngIf="permissions.a" routerLink="/page-access/setup-page-access"
                    class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add
                    Page Access</button>
            </div>
        </div>
    </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16rem" *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card3'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <div *ngIf="nodata == false" class="row row-16 " infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    (scrolled)="onScrollDown()">
      <ng-container  *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 "  *ngFor="let item of pageaccessDetails,let i = index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body d-flex align-items-start p-0">
                      <div class="d-flex p-16 {{(item.is_active == true) ? 'view-section' : 'w-100' }} " (click)="viewpage(item.id)">
                        <div class="logo-img {{item.color_code}}"> {{ this.appService.getFirstChar(item.name,2) }}</div>
                        <div class="overflow-hidden">
                            <h6 class="mb-0 tips">{{item.name }}</h6>
                            <span class="tips-helper">{{item.name }}</span>
                            <span class="fs-10 text-uppercase fw-500 {{(item['is_active'] == true) ? 'text-success' : 'text-danger'}}">{{(item['is_active']
                              == true) ? 'Active' : 'Deleted'}}</span>
                        </div>
                      </div>
                      <div  *ngIf="item.is_active==true && (permissions.e || permissions.d)  && item.default == false" ngbDropdown class="pe-16 py-16 dropdown-section d-inline-block " >
                        <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                            ngbDropdownToggle>
                            <i class="icon-three-dots-vertical fs-16"></i>
                        </button>
                        <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                            <button *ngIf="permissions.e" ngbDropdownItem (click)="editHeaderContent(item.id)">
                                <i   class="icon-pencil-square me-1"></i> Edit
                            </button>
                            <button  *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deleteDataAccess(item.id)">
                                <i class="icon-trash  me-1"></i> Delete
                            </button>
                        </div>
                    </div>
                        <!-- <div class="ms-auto d-flex gap-4 fs-14">
                            <button  *ngIf="item['default'] == false" class="btn-reset link-primary" (click)="editHeaderContent(item['id'])">
                                <i class="icon-pencil-square"></i>
                            </button>
                            <button *ngIf="item['default']" class="btn-reset link-primary" (click)="editHeaderContent(item['id'])">
                                <i  class="bi bi-eye"></i>
                            </button>
                            <button *ngIf="item['default'] != true" class="btn-reset " (click)="deleteDataAccess(item['id'])">
                                <i class="icon-trash"></i>
                            </button>

                        </div> -->
                    </div>
                    <div class="card-body align-items-center px-16 pt-8 pb-16 justify-content-between">
                        <div class="d-flex align-items-center overlap-avatar overlap-left">
                            <ng-container *ngFor="let rights of item['employees'],let j = index">
                                <img  class="avatar-sm sq-32 img-fluid rounded-circle"  *ngIf="j < 3 && (rights.profile_image !=  '' && rights.profile_image !=  null)"  src="{{rights.profile_image}}"  [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body">
                                <div class="avatar-sm sq-32 rounded-circle {{rights.color_code}}" *ngIf="j < 3 && (rights.profile_image ==  '' || rights.profile_image ==  null)"  [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body" ><span>{{this.appService.getFirstChar(rights.first_name+' '+rights.last_name,2)}}</span></div>
                                <ng-template #popContentSingle>
                                      <div class="user-details">
                                          <span class="user-label">{{rights.first_name+' '+(rights.middle_name!=null?rights.middle_name:'')+' '+rights.last_name}}</span>
                                          <span class="user-code">EMP CODE: {{rights.employee_code}} </span>
                                      </div>
                              </ng-template>
                                <div *ngIf="j==3" class="avatar-sm sq-32 rounded-circle" [ngbPopover]="popContentMultilpe"  popoverClass="user-list multi-user" triggers="click" container="body"><span>+{{item['employees'].length - 3}}</span></div>
                            </ng-container>
                            <ng-template #popContentMultilpe>
                              <ul>
                                <ng-container *ngFor="let re of item['employees'],let i = index">
                                  <ng-container *ngIf="i >= 3">
                                    <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                                        <img  class="user-avatar {{re.color_code}}" src="{{re.profile_image}}">
                                        <div class="user-details">
                                          <span class="user-label">{{re.first_name+' '+(re.middle_name!=null?re.middle_name:'')+' '+re.last_name}}</span>
                                          <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                        </div>
                                    </li>
                                    <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                                        <span class="user-avatar {{re.color_code}}">{{this.appService.getFirstChar(re.first_name+' '+re.last_name,2)}}</span>
                                        <div class="user-details">
                                            <span class="user-label">{{re.first_name+' '+(re.middle_name!=null?re.middle_name:'')+' '+re.last_name}}</span>
                                            <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                        </div>
                                    </li>
                                  </ng-container>
                                </ng-container>
                              </ul>
                            </ng-template>
                        </div>
                        <div class="fs-12 fw-500 text-light-400">
                            <span *ngIf="item['employees']?.length <= 1" class="text-nowrap">{{item['employees']?.length}} Employee</span>
                            <span *ngIf="item['employees']?.length > 1" class="text-nowrap">{{item['employees']?.length}} Employees</span>

                        </div>
                    </div>
                </div>
            </div>
      </ng-container>

       <!-- Loader updations -->
       <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card3'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
    <div *ngIf="nodata == true && !loader" class="row " style="height: calc(100vh - 11rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg23.png'" [noDataText]="'Control Access!'"
        [noDataPara]="'There are no page access permissions in place at the moment. Configure page access settings to manage page visibility and editing.'"
        [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
      </div>
</div>
<div *ngIf="permissions" class="side-panel" style="--sidepanel-width:44.6875rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Details</h5>
          <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid px-32 py-24">
            <!-- View loader start -->
            <app-view-loader *ngIf="loader1"></app-view-loader>
            <!-- View loader end -->
            <ng-container *ngIf="!loader1">
              <div class="d-flex mb-16">
                  <p class="text-uppercase fw-500 mb-0">PAGE ACCESS</p>
                  <a  *ngIf="permissions.e && viewpageData.default == false"  class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="editHeaderContent(viewpageData.id)">
                      <i class="icon-pencil-square me-1" ></i> Edit
                  </a>
              </div>
              <div class="row row-16 ">
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 py-16">
                          <div class="row row-24 ">
                              <div class="col-12 "><span class="title">Page Access details</span></div>
                              <div class="col-6">
                                  <div class="detail-title">Group name</div>
                                  <div class="detail-desc">{{viewpageData.name}}</div>
                              </div>
                              <div class="col-6">
                                <div class="detail-title">Status</div>
                                <div class="detail-desc">
                                  <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewpageData.is_active">ACTIVE</span>
                                  <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="viewpageData.is_active==false">DELETED</span>
                                </div>
                             </div>
                              <div class="col-12">
                                  <div class="detail-title">Employees</div>
                                  <div class="detail-desc">
                                      <div class="d-flex flex-wrap mt-8 gap-8" >
                                          <span class="badge2 w-50ch" *ngFor="let pageemp of viewpageData.employees_data, let i = index">
                                            <div class="avatar-circle sq-24 fs-10 {{pageemp.color_code}}"  alt="" *ngIf="pageemp.profile_image ==null || pageemp.profile_image ==''">{{this.appService.getFirstChar(pageemp.first_name+" "+pageemp.last_name,1)}}</div>
                                            <img class="avatar-circle sq-24 {{pageemp.color_code}}"  src="{{pageemp.profile_image}}" alt="" *ngIf="pageemp.profile_image !=null && pageemp.profile_image !=''">{{pageemp.first_name}} {{pageemp.middle_name}} {{pageemp.last_name}} {{" ("+pageemp.employee_code+")"}}
                                          </span>

                                          <!-- <span class="badge2"> <div class="avatar-circle sq-24 fs-10 bg1"  alt="">LR</div> Lindsey Donin</span> -->
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                          <div class="row row-24 ">
                              <div class="col-12 "><span class="title">rights</span></div>

                              <div class="row ">
                                <div class="col-12 mt-24">
                                    <ul class="tabs tab-pill">
                                        <!-- <li *ngFor="let m of viewpageData.page_access,let i = index"><a class="pill ">{{m.name}}</a></li> -->
                                        <li *ngFor="let m of viewpageData.page_access,let i = index"><a (click)="setIndex(i,m);" class="pill " [class.active]='selectedmenu === i' [class.success]='m.status === 1'>{{m.name}}</a></li>
                                    </ul>
                                </div>
                              </div>
                              <!-- <div class="col-12 " *ngIf="noChild!=''"><span class="fs-14 fw-600">{{noChild}}</span></div> -->

                              <ng-container *ngIf="noChild==''">
                                <div class="col-12 " ><span class="fs-14 fw-600">Setup</span></div>
                                <ng-container *ngFor="let m of assignees,let i = index">
                                        <ng-container *ngIf="m.nosubmenu.length > 0">
                                            <div class="col-12">
                                                <div class="card card-c2 overflow-hidden">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped  table-sm ">
                                                            <thead >
                                                                <tr>
                                                                <th scope="col fw-500">{{m['name']}}</th>
                                                                <th class="col-1" scope="col fw-400">View</th>
                                                                <th class="col-1" scope="col fw-400">Add</th>
                                                                <th class="col-1" scope="col fw-400">Edit</th>
                                                                <th class="col-1" scope="col fw-400">Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let c of m.nosubmenu,let k = index">
                                                                    <td> {{c.name}}</td>
                                                                    <td class="text-center"><ng-container *ngIf="c.v != null"><i *ngIf="c.view" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                    <td class="text-center"><ng-container *ngIf="c.a != null"><i *ngIf="c.add" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                    <td class="text-center"><ng-container *ngIf="c.e != null"><i *ngIf="c.edit" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                    <td class="text-center"><ng-container *ngIf="c.d != null"><i *ngIf="c.delete" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngFor="let d of m.submenu,let j = index">
                                            <ng-container *ngIf="d.nosubmenu.length > 0">
                                                <div class="col-12">
                                                    <div class="card card-c2 overflow-hidden">
                                                        <div class="table-responsive">
                                                            <table class="table table-striped  table-sm ">
                                                                <thead >
                                                                    <tr>
                                                                    <th scope="col fw-500">{{d['name']}}</th>
                                                                    <th class="col-1" scope="col fw-400">View</th>
                                                                    <th class="col-1" scope="col fw-400">Add</th>
                                                                    <th class="col-1" scope="col fw-400">Edit</th>
                                                                    <th class="col-1" scope="col fw-400">Delete</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let e of d.nosubmenu,let k = index">
                                                                        <td> {{e.name}}</td>
                                                                        <td class="text-center"><ng-container *ngIf="e.v != null"><i *ngIf="e.view" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                        <td class="text-center"><ng-container *ngIf="e.a != null"><i *ngIf="e.add" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                        <td class="text-center"><ng-container *ngIf="e.e != null"><i *ngIf="e.edit" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                        <td class="text-center"><ng-container *ngIf="e.d != null"><i *ngIf="e.delete" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngFor="let f of d.submenu,let j = index">
                                                <ng-container *ngIf="f.nosubmenu.length > 0">
                                                    <div class="col-12">
                                                        <div class="card card-c2 overflow-hidden">
                                                            <div class="table-responsive">
                                                                <table class="table table-striped  table-sm ">
                                                                    <thead >
                                                                        <tr>
                                                                        <th scope="col fw-500">{{f['name']}}</th>
                                                                        <th class="col-1" scope="col fw-400">View</th>
                                                                        <th class="col-1" scope="col fw-400">Add</th>
                                                                        <th class="col-1" scope="col fw-400">Edit</th>
                                                                        <th class="col-1" scope="col fw-400">Delete</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let g of f.nosubmenu,let k = index">
                                                                            <td> {{g.name}}</td>
                                                                            <td class="text-center"><ng-container *ngIf="g.v != null"><i *ngIf="g.view" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                            <td class="text-center"><ng-container *ngIf="g.a != null"><i *ngIf="g.add" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                            <td class="text-center"><ng-container *ngIf="g.e != null"><i *ngIf="g.edit" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                            <td class="text-center"><ng-container *ngIf="g.d != null"><i *ngIf="g.delete" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngFor="let h of f.submenu,let j = index">
                                                    <div class="col-12">
                                                        <div class="card card-c2 overflow-hidden">
                                                            <div class="table-responsive">
                                                                <table class="table table-striped  table-sm ">
                                                                    <thead>
                                                                        <tr>
                                                                        <th scope="col fw-500">{{f['name']}}</th>
                                                                        <th class="col-1" scope="col fw-400">View</th>
                                                                        <th class="col-1" scope="col fw-400">Add</th>
                                                                        <th class="col-1" scope="col fw-400">Edit</th>
                                                                        <th class="col-1" scope="col fw-400">Delete</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                            <tr>
                                                                            <td> {{h.name}}</td>
                                                                            <td class="text-center"><ng-container *ngIf="h.v != null"><i  *ngIf="h.view" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                            <td class="text-center"><ng-container *ngIf="h.a != null"><i *ngIf="h.add" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                            <td class="text-center"><ng-container *ngIf="h.e != null"><i *ngIf="h.edit" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                            <td class="text-center"><ng-container *ngIf="h.d != null"><i *ngIf="h.delete" class="bi bi-check-lg text-accent1 fs-16"></i></ng-container></td>
                                                                            </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                </ng-container>
                            </ng-container>
                          </div>
                      </div>
                  </div>
                </div>
            </ng-container>
          </div>
      </div>
  </div>
</div>

<div  *ngIf="deleteToggle" class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Page Access?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Page Access')}}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked" (click)="deleteContent(dataAccessResId)">Delete</button>
        </div>
      </div>
    </div>
  </div>
