import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { GeolocationService } from 'src/app/@core/services/geo-location.service';
import { latitudeValidator, longitudeValidator } from 'src/app/@shared/validators/latitude.validator';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import { UnamepatternValidator } from '../../validators/unamepattern.validators';
// import { PlacePredictionService } from 'src/app/@core/services/place-prediction.service';

@Component({
  selector: 'app-geo-location-add',
  templateUrl: './geo-location-add.component.html',
  styleUrls: ['./geo-location-add.component.scss']
})
export class GeoLocationAddComponent implements OnInit {
  value: any;
  id: any;
  locationkey: any = [];
  submitted = false;
  loader = false;
  header = 'Add';
  isOpen = true;
  zoom: number = 15;

  // initial center position for the map
  // lat: any
  // lng: any
  lat: number = 8.548760691386658
  lng: number = 76.94078824171619
  address: string | undefined;
  // private geoCoder: any;

  @ViewChild('locsearch')
  public searchElementRef: any;

  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#0c0c0c"
      }
    ]
  }]

  @Input() dataId: any;
  @Input() addeditpanel: any;
  @Input() from: any;
  @Output() panelclose = new EventEmitter();
  @Output() locationdetailout = new EventEmitter();

  private subjectKeyUp = new Subject<any>();
  geolocationForm = this.formBuilder.group({
    id: 0,
    location_name: ['', [Validators.required, UnamepatternValidator(), Validators.maxLength(100)]],
    latitude: ['', [Validators.required, latitudeValidator(), Validators.maxLength(20)]],
    longitude: ['', [Validators.required, longitudeValidator(), Validators.maxLength(20)]],
    radius: ['', [Validators.required, NumberpatternValidator(), Validators.max(1000),Validators.min(50)]]
  })

  constructor(
    private GeolocationService: GeolocationService,
    public formBuilder: FormBuilder,
    public appService: AppService,
    public messageService: MessageService,
    public existDataService: ExistDatasService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d: any) => {
      this.existService();
    })
    if (this.dataId == 0) {
      this.geolocationForm.reset();
      this.header = 'Add';
    } else {
      this.header = 'Edit';
      this.loader = true;
      this.GeolocationService.getOnelocation(this.dataId).subscribe((res: any) => {
        let locdata = res.body;

        this.lat = parseFloat(locdata.latitude)
        this.lng = parseFloat(locdata.longitude),
          this.geolocationForm.reset({
            id: res.id,
            location_name: locdata.location_name,
            latitude: locdata.latitude,
            longitude: locdata.longitude,
            radius: locdata.radius,
          });
        this.loader = false;
      })
    }

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      // this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.geolocationForm.controls['longitude'].setValue(place.geometry.location.lng().toFixed(6));
          this.geolocationForm.controls['latitude'].setValue(place.geometry.location.lat().toFixed(6));
          this.zoom = 15;
        });
      });
    });

    this.geolocationForm.controls['latitude'].valueChanges.subscribe(
      () => {
        this.lat = parseFloat(this.geolocationForm?.get('latitude')?.value)
      }
    );

    this.geolocationForm.controls['longitude'].valueChanges.subscribe(
      () => {
        this.lng = parseFloat(this.geolocationForm?.get('longitude')?.value)
      }
    );
  }

  // Save
  onSubmit() {
    if (this.geolocationForm.invalid) {
      return;
    }
    let myform = {
      'location_name': this.geolocationForm.value.location_name,
      'latitude': this.geolocationForm.value.latitude,
      'longitude': this.geolocationForm.value.longitude,
      'radius': this.geolocationForm.value.radius,
    }
    if (this.dataId == 0) {
      this.GeolocationService.createlocation(myform).subscribe((res: any) => {
        this.locationdetailout.emit(true);
        this.panelclose.emit('false');
        this.geolocationForm.reset();
      }, (err: any) => {
      });

    } else {
      this.GeolocationService.locationupdate(this.dataId, myform).subscribe((res: any) => {
        this.locationdetailout.emit(true);
        this.panelclose.emit('false');
      }, (err: any) => {
      });
    }
  }

  closefn() {
    this.addeditpanel = false;
    this.panelclose.emit(this.addeditpanel);
  }
  existService() {
    if (this.dataId == 0) {
      this.value = {
        "page": "GeofencingLocation",
        "field": "location_name",
        "value": this.f.location_name.value
      }
    } else {
      this.value = {
        "id": this.id,
        "page": "GeofencingLocation",
        "field": "location_name",
        "value": this.f.location_name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res: any) => {
      this.locationkey = res.body

      if (this.locationkey.data === 'invalid') {
        this.f.location_name.setErrors({
          'exist': true
        })
      }
    })
  }

  keyUp() {
    this.subjectKeyUp.next(this.f.location_name.value)
  }

  get f() { return this.geolocationForm.controls; }


  markerDragEnd($event: any) {
    this.lat = $event.coords?.lat
    this.lng = $event.coords?.lng
    this.geolocationForm.controls['longitude'].setValue($event.coords?.lng.toFixed(6));
    this.geolocationForm.controls['latitude'].setValue($event.coords?.lat.toFixed(6));
  }

  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.lat = position.coords.latitude;
  //       this.lng = position.coords.longitude;
  //       this.zoom = 15;
  //     });
  //   }
  // }

  @HostListener('window:resize', ['$event'])
  sizeChange(event: any | null) {
    const pacContainer = document.querySelector('.pac-container') as HTMLElement;

    if (pacContainer != undefined)
      (document.querySelector('.pac-container') as HTMLElement).style.display = 'none';
  }
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any | null) {
    const pacContainer = document.querySelector('.pac-container') as HTMLElement;

    if (pacContainer != undefined)
      (document.querySelector('.pac-container') as HTMLElement).style.display = 'none';
  }
}








