import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InboxService } from 'src/app/@core/services/inbox.service';

@Component({
  selector: 'app-fnf-onduty-approval',
  templateUrl: './fnf-onduty-approval.component.html',
  styleUrls: ['./fnf-onduty-approval.component.scss']
})
export class FnfOndutyApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() onDuty:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  showTrend = false;
  pageSize = 20;
  page = 1
  viewDetails = false;
  viewData:any = [];
  today =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  btndisable = false
  delArray : any = []

  // For year dropdown in on duty request trend
  currentYear =  new Date().getFullYear();
  selectedYearDisp = String(this.currentYear)
  selectedYear = this.currentYear
  yearList: any = [];
  multi: any = [];
  employee_Id : any;
  graphLoader             = false;
  xAxisLabel:any = [];
  year:any=false;
  month:any=false;
  yAxisLabel: string = 'Days';

  onSelect(event:any) {
  }


  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  constructor(    public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,public appService: AppService,private datePipe:DatePipe,private notificationService: NotificationService,private inboxservice: InboxService) { }

    form = this.fb.group({
      read_status: [true,Validators.required],
      approved_status: [false,Validators.required],
      comment: ['', Validators.maxLength(500)],
    })
  
    get f() {return  this.form.controls; }

  ngOnInit(): void {
  
  }

  timegraph(){
    this.yearList = []
    this.yearListCreate();
    this.getOndutyTrendDetails(this.currentYear);
    
  }

  checkall(event:any){
  var newList = this.onDuty?.requests
  var begin = (this.page-1) * this.pageSize ;
  var end = this.page* this.pageSize;
  var subArray = newList?.slice(begin,end);
  subArray.forEach((item:any, key:any) => {
    if(item.status != 'Cancelled')
    item.checked = event.target.checked
  })
}

checkAllSelected(){
  var newList = this.onDuty?.requests
  var begin = (this.page-1) * this.pageSize ;
  var end = this.page* this.pageSize;
  var subArray = newList?.slice(begin,end);
  if(subArray?.length){
    return subArray.every((item: { checked: any,status:any }) => {
      if(item.status != 'Cancelled')
        return item.checked;
      else 
        return true
    });
  } else {
    return false;
  }
}

countSelected(){
  this.btndisable = false
  var delArray:any = [];
  this.onDuty?.requests?.forEach((item:any, value:any) => {

    if(item?.checked == true )
      delArray.push(value)
  });
  return delArray.length
}

approveReject(val:any){
  if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
    this.unprocessWarningPopup.emit();
  }else{
    this.btndisable = true;
    if(this.countSelected().length == 0)
      return;
    if(val == 'Accepted'){
      this.action('Accepted');
    }else{
      this.action('Rejected');
    }
  }
}

action(value:any){
if(this.countSelected().length == 0){
  this.notificationService.handleErrorNotification("Please select data to accept or reject","Select data");
  return;
}
this.onDuty?.requests.forEach((item:any) => {
  if(value == 'Accepted' && item.checked == true ){
      this.updateOnduty(item.id,'Accepted');
    }else if(value == 'Rejected' && item.checked == true ){
      this.updateOnduty(item.id,'Rejected');
    }
});
this.notificationService.handleSuccessNotification("Updated Successfully","Success");
}

updateOnduty(id: any,val:any){
  this.finalSettlementService.updateFnFOndutyItem(id,`{"status":"${val}"}`).subscribe((res:any)=>{
    if(res){
      for(let i = 0;i<this.onDuty.requests.length;i++){
        if(this.onDuty.requests[i].id == id){
            this.onDuty.requests[i].status = val;
        }
      }
   
    this.updateCheckBoxes();
    }
  });
}

updateCheckBoxes(){
  this.onDuty?.requests.forEach((item:any) => {
   item.checked = false;
  });
}

confirm(){

  if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
  }else{
      var status: any;
      var flag = true;
      for(let i = 0;i<this.onDuty?.requests?.length;i++){

        if(this.onDuty?.requests[i].status == 'Pending'){
          this.notificationService.handleErrorNotification("On duty requests cannot be submitted in pending state.","Pending On Duty Requests");
          return;
        }

        if(flag == true && this.onDuty.requests[i].status == 'Accepted'){
          status = 'Accepted';
          flag = false;
        }
        if(flag == true && this.onDuty.requests[i].status == 'Rejected'){
          status = 'Rejected';
        }
      }
      if(status=='Accepted'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }

      if(this.form.controls.comment.valid){
        this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
            if(res){
              this.refreshInbox(false);
              this.form.get('comment')?.reset('')

            }
          })
        }
      }
  }

  yearListCreate(){
  var i:number;
  for(i = this.currentYear; i >= 2020 ; i--){
    this.yearList.push({k:String(i)})
  }
}
getOndutyTrendDetails(event:any){
  this.selectedYearDisp = String(event)
  this.selectedYear = event
  this.graphLoader = true;
  if(this.empId != null && this.empId != '' && this.empId != undefined){
  this.inboxservice.getOndutyTrend(event,this.empId).subscribe((res:any)=>{
    if(res){
      this.prepareTrendData(res.data)
    }
    })
  }
}

prepareTrendData(data:any){
  let approved:any         = [];
  let pending:any          = [];
  let rejected:any         = [];
  this.xAxisLabel = [];

  this.multi           = [];
  data.forEach((item:any) => {
    this.xAxisLabel?.push(item?.name)
    item?.series.forEach((item1:any) => {
        if(item1?.name == "Approved"){
          approved.push(item1?.value)
        }else if(item1?.name == "Pending"){
          pending.push(item1?.value)
        }else{
          rejected.push(item1?.value)
        }
    });
  });
  this.multi.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 })  
  this.graphLoader = false;

}

confirmCancel(status:any){
  if(status=='Accepted'){
    this.form.controls['approved_status'].setValue('Approved');
  }else{
    this.form.controls['approved_status'].setValue('Rejected');
  }
  this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
    if(res){
      this.refreshInbox(false);
      this.form.get('comment')?.reset('')

    }
  })
}

   // test start

   refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

// test end

}
