import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentsService } from 'src/app/@core/services/documents.service';
import { FormBuilder,Validators  } from '@angular/forms';

import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  doc_name:any;
  doctype:any;
  state_view:any;
  state_edit:any;
  docstatus:any;
  showSidePanel1=false;
  docid:any;
  @ViewChild(SearchbarComponent) child:any;
  showSidePanel 	         	     =false;
  documentsList:any[]	           = [];
  status                         = true;
  buttonTitle                    = "";
  isdelete                       = false;
  dataId :any                    = "";
  submitted                      = false;
  documentsForm                = this.formBuilder.group({
                                    id: 0,
                                    name: ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                                    type: ['', [Validators.required]],
                                    view: ['', []],
                                    edit: ['', []],
  } );
  searchlistdata:any             = [] ;
  documentsPlaceholder:string  = this.messageService.searchdisplay('documents');
  inputFocusClass                = false;
  nodatafound                    = false;
  searchKeyword="";
  isAlert                         = false;
  msgText                         = "";
  deleteToggle                   = false;
  panelheader                    = "";
  header= 'Add';
  deleteClass = false;
  loader = true;
  isChecked = false;
  loader1 = false;
  deleteClicked = false
  statusBtn:any = "Active";

  validated : boolean = false
  documentUnit : any =[]
  editflag :boolean = false
  value:any
  isLoading = false
  searchData : Subject<void> = new Subject();
  private subjectKeyUp = new Subject<any>();

  // unamePattern = "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";
/********INFINITY SCROLL VARIABLES  ****/
  offset                      = 0;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  documentsDetailsList:any  = [];
  infinityloader              = false;
  permissions:any = [];
  /*For Add new documents*/
  adddocuments(header:any){
    this.showSidePanel    = true;
    this.panelheader      = "Add";
    this.buttonTitle      = "ADD";
    this.isdelete         = false;
    this.dataId           = 0;
    // this.isSuccess        = false;
    this.status           = true;
    this.submitted        = false;
    this.documentsForm.enable();
    this.documentsForm.reset({ id: 0});
  }

  /*Edit an existing doc*/
  editDocuments(id:any){
    this.showSidePanel1 = false;
    this.editflag = true
    this.showSidePanel    = true;
    this.buttonTitle      = "SAVE";
    this.isdelete         = true;
    this.dataId           = id;
    this.loader1           = true;
    // this.isSuccess        = false;
    this.submitted        = false;
    this.documentsService.documentsGet(id).subscribe((res:any)=>{
        this.documentsForm.reset({ id: res.id, name:res.name, 'type': res.type, edit: res.edit,view:res.view});
        this.status = res.is_active;
        this.loader = false;
        this.loader1 = false;
        if(this.status==true){
          this.documentsForm.enable();
          this.panelheader  = "Edit";
          if(this.documentsForm.value.edit==true){
            this.isChecked = true;
            if(this.documentsForm.value.view == '')
              this.documentsForm.get('view')?.setValue(true);
            this.documentsForm.controls.view.disable();
          }else{
            this.isChecked = false;
            this.documentsForm.controls.view.enable();
          }
        }
        else{
          this.documentsForm.disable();
          this.panelheader  = "View";
        }
    })
  }
  constructor(public appService:AppService,public existDataService :  ExistDatasService, public messageService : MessageService,private documentsService:DocumentsService,private formBuilder: FormBuilder,public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.documentsListData();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.documentsService.documentsList(this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
    onScrollDown() {
      this.infinityloader = true;
      this.offset            += this.defaultlimit;
      if(this.listlength){
        this.documentsListData();
        this.loader       = false;
        this.direction    = "down";
      }else{
        this.infinityloader = false;
      }
    }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.documentsList.push(this.documentsDetailsList[i]);
    }
    }
//To load data
documentsListData(){
  this.loader = true;
  this.documentsService.documentsList(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
    this.arrangeApiData(res)
  })
}

arrangeApiData(res:any){
  this.isLoading = false
  this.listlength = res.results.length;
  if (res.results.length == 0) {
    this.nodatafound = this.offset == 0 ? true :false;
  }else {
    this.nodatafound = false;
    this.documentsDetailsList = res.results;
  }
  this.infinityloader = false;
  this.addItems(this.listlength);
  this.loader = false;
}

 /*Save(dataId=0)/update(dataId=!0)*/
 onSubmit(): void {
  this.submitted = true;
  if (this.documentsForm.invalid ) {
        return;
  }
  this.status = false;
  var viewCheck = this.documentsForm.value.edit==true?this.documentsForm.value.view=true:false;

  this.dataId = this.documentsForm.value.id;
  if(this.dataId == 0){
    this.documentsService.documentsSave({'name':this.documentsForm.value.name,'type':this.documentsForm.value.type!=''?this.documentsForm.value.type:'','view':this.documentsForm.value.view!=null?this.documentsForm.value.view:viewCheck,'edit':this.documentsForm.value.edit!=null?this.documentsForm.value.edit:false}).subscribe((res: any) => {
      this.offset = 0;
      this.status = true
      this.documentsList = [];
      this.documentsListData()
      this.showSidePanel    = false;

      this.msgText          = "created";
      this.documentsForm.reset();
    },(err: any) => {this.status = true});

    this.submitted        = false;

  }else{
    this.documentsService.documentsUpdate(this.dataId, {'name':this.documentsForm.value.name,'type':this.documentsForm.value.type!=''?this.documentsForm.value.type:'','view':this.documentsForm.value.view!=null?this.documentsForm.value.view:viewCheck,'edit':this.documentsForm.value.edit!=null?this.documentsForm.value.edit:false}).subscribe((res: any) => {
      this.offset = 0;
      this.documentsList = [];
      this.status = true
      this.documentsListData();
      this.msgText          = "updated";
      this.showSidePanel    = false;

    },(err: any) => {this.status = true});

  }
}
deleteDoc(id:any){
  this.documentsForm.value.id = id;
  this.deleteClicked = false
  this.deleteToggle = true;
}
  /*Delete an existing doc*/
  deleteDocuments(){
    this.deleteClicked = true
    this.documentsService.documentsDelete(this.documentsForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.showSidePanel  = false;
      this.offset = 0;
      this.documentsList = [];
      this.documentsListData();
      this.msgText         = "deleted";
      this.deleteToggle=false;
    });
  }
// filter
  statusfilter(status:any){
    this.loader = true;
    this.status = status;
    this.documentsList = [];
    this.offset            = 0;
    // this.documentsListData();
    this.child.clearBar();
  }

 // search doc
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.documentsList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }
// convenience getter for easy access to form fields
get f() { return this.documentsForm.controls; }


//Edit selection start
editCheckFun(e:any) {
  if(e.target.checked){
    this.isChecked = true;
    if(this.documentsForm.value.view == '')
      this.documentsForm.get('view')?.setValue(true);
    this.documentsForm.controls.view.disable();
  }else{
    this.isChecked = false;
    this.documentsForm.controls.view.enable();
  }
}
// Edit selection end
viewdoc(id:any){
  if(this.permissions.v){
    this.showSidePanel1 = true;
    this.loader1        = true;
    this.documentsService.documentsGet(id).subscribe((res: any) => {
      this.doc_name = res.name;
      this.doctype = res.type;
      this.state_view= res.view;
      this.state_edit = res.edit;
      this.docstatus =res.is_active;
      this.docid=res.id;
      this.loader1       = false;
    })
  }
}
searchdoc(searchKeyword:any){
  this.loader = true;
  this.isLoading = true
  this.offset            = 0;
  this.documentsList = [];
  this.searchKeyword = searchKeyword;
  if(searchKeyword != ""){
    // this.documentsListData();
    this.searchData.next()
  }
}

// Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":"document",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.dataId,
        "page":"document",
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.documentUnit = res.body

      this.loader = false

      if(this.documentUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
