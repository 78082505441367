import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { EmployeeDirectoryComponent } from './employee-directory/employee-directory.component';

const routes: Routes = [ LayoutService.childRoutes([
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'employee-directory', component: EmployeeDirectoryComponent}
])];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeDirectoryRoutingModule { }
