import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TaskOwnerService } from 'src/app/@core/services/task-owner.service';
import { FormBuilder, Validators  } from '@angular/forms';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout'
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-task-owner',
  templateUrl: './task-owner.component.html',
  styleUrls: ['./task-owner.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskOwnerComponent implements OnInit {
  showSidePanel1        = false;
  taskname:any;
  taskid:any;
  task_module:any;
  task_role:any;
  task_due :any;
  task_is_active :any;
  showSidePanel         = false;
  loader                = false;
  activeToggle          = true;
  tasks:any[]           = [];
  nodata                = false;
  multiDrop             = false;
  moduleDetailsList:any = [];
  roleDetailsList:any   = [];
  submitted             = false;
  editId                = 0;
  header                = '';
  addEditBtn            = "Add";
  updateClass           = true;
  deleteButtonEnable    = false;
  deleteToggle          = false;
  searchlistdata:any    = [];
  tasksearch:string     = this.messageService.searchdisplay('tasks');
  loader1               = false;
  @ViewChild(SearchbarComponent) child:any;
  single_array:any      = [] ;
  main_array:any        = [];
  process_array:any     = [];
  searchKeyword         = "";
  deleteClicked         = false;
  statusBtn:any         = "Active";
  edit                  : boolean = false
  value                 : any
  taskUnit              : any = []
  isLoading = false
  searchData : Subject<void> = new Subject();


  checkoutForm = this.formBuilder.group({
    id: 0,
    module: ['', [Validators.required]],
    process: ['', [Validators.required]],
    role: ['', Validators.required],
    due_days: ['', [Validators.required,NumberpatternValidator(),Validators.maxLength(3)]]
  });

  processDropdown:any=[];
  /********INFINITY SCROLL VARIABLES  ****/
  offset                  = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  taskDetailsList:any     = [];
  infinityloader          = false;
  permissions:any         = [];

  constructor(
    private taskOwnerService:TaskOwnerService,
    private formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public messageService : MessageService,
    public appService:AppService,
    // private changeDetectorRef: ChangeDetectorRef

    ) { }

  randomClass(min:number, max:number) {
    var ints = Math.floor(Math.random() * (max - min + 1)) + min;
    return "bg"+ints;
  }

  showDataItems(){
    this.loader   = true;
    this.taskOwnerService.taskOwnerList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
      this.arrangeApiData(res)
    });
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.taskDetailsList = [];
    for(let key of res.results){
      key.color = this.randomClass(0,5);
      this.taskDetailsList.push(key);
    }
    this.listlength = res.results.length;
    if(this.offset == 0){
      this.tasks    = [];
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.nodata = this.taskDetailsList.length==0?true:false;
    this.loader = false;
  }

  ngOnInit(): void {
    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });

    this.showDataItems();
    this.moduleList();
    this.roleList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.taskOwnerService.taskOwnerList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.tasks.push(this.taskDetailsList[i]);
    }
    this.loader = false;
    // this.changeDetectorRef.detectChanges();

  }

  /********* ADD TASKS ****************/
  addTasks(header:any){
    this.checkoutForm.reset();
    this.processDropdown    = [];
    this.checkoutForm.enable();
    this.editId             = 0;
    this.updateClass        = true;
    this.header             = "Add";
    this.addEditBtn         = "Save";
    this.deleteButtonEnable = false;
    this.showSidePanel      = true;
    this.checkoutForm.reset({ id: 0});
    this.checkoutForm.enable();
    this.submitted          =  false;
    this.multiDrop          = true;
  }
  /********* EDIT TASKS ****************/
  editTask(id: any,is_active:any){
    this.showSidePanel1 = false;
    this.loader1        = true;
    this.submitted      = false;
    this.editId         = id;
    if(is_active == false){
      this.header = "View";
      this.checkoutForm.disable();
    }else{
      this.header = "Edit";
      this.checkoutForm.enable();
    }
    this.taskOwnerService.taskOwnerGet(this.editId).subscribe((res: any = []) => {
      res['process_id'] = res.process;
      this.processDropdown = [];
        this.checkoutForm.reset({ id: res.id,  module: res.module, process: res.process_id, role: res.role, due_days: res.due_days });
        this.taskOwnerService.taskProcessDropDown(res.module,this.editId).subscribe((res1: any = []) => {
          this.processDropdown = Object.values(res1);
        });
        this.loader = false;
        this.loader1 = false;
    });
    this.deleteButtonEnable = true;
    this.updateClass        = is_active;
    this.showSidePanel      = true;
    this.addEditBtn         = "Save";
    this.multiDrop          = false;
  }
  deletetaskowner(id:any){
    this.checkoutForm.value.id  = id;
    this.deleteToggle           = true;
  }
  /********* DELETE TASKS ****************/
  deleteTask(){
    this.deleteClicked      = true
    this.submitted          = true;
    this.taskOwnerService.taskOwnerDelete(this.checkoutForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.offset           = 0;
      this.tasks            = [];
      this.taskDetailsList  = [];
      this.showDataItems();
      this.showSidePanel    = false;
      this.deleteToggle     = false;
      this.deleteClicked    = false
    });
  }
  /**************** METHOD FOR GETTING MODULE LIST *********************/
  moduleList(){
    this.taskOwnerService.moduleDropDown().subscribe((res: any) => {
      this.moduleDetailsList = res;
    });
  }
  /**************** MODULE SELECT *********************/
  moduleSelectFun(){
    this.processDropdown=[];
    this.checkoutForm.patchValue({process:[]})
    this.processList();
  }
  /**************** METHOD FOR GETTING PROCESS LIST *********************/
  processList(){
    this.taskOwnerService.processDropDown(this.checkoutForm.value.module).subscribe((res: any) => {
      if (res.length != 0) {
        this.processDropdown = res;
        this.selectAllForDropdownItems(this.processDropdown);

      }
    });
  }
  /**************** METHOD FOR GETTING ROLE LIST *********************/
  roleList(){
    this.taskOwnerService.roleDropDown().subscribe((res: any) => {
      this.roleDetailsList = res;
    });
  }
  get f() {
    return this.checkoutForm.controls;
  }
  /**************** METHOD FOR SAVE AND EDIT *********************/
  onSubmit(): void {
    this.submitted = true;
    if (this.checkoutForm.invalid) {
      return;
    }
    this.editId = this.checkoutForm.value.id;
    this.updateClass = false
   if(this.editId == 0){
    this.createTasks();
   }else{
    this.updateTask();
   }
  }
  /**************** METHOD FOR CREATE *********************/
  createTasks(){
    this.process_array=this.checkoutForm.value.process;
    var  main_tasks_array       = [];
    for(let k=0;k<this.process_array.length;k++){
        var single_array : any  = {};
        single_array['id']      = this.checkoutForm.value.id;
        single_array['module']  = this.checkoutForm.value.module;
        single_array['process'] = this.process_array[k];
        single_array['role']    = this.checkoutForm.value.role;
        single_array['due_days']= this.checkoutForm.value.due_days;
        main_tasks_array[k]     = single_array;
    }
    this.main_array             = main_tasks_array;
    this.taskOwnerService.taskOwnerCreate(this.main_array).subscribe((res: any) => {
      this.offset               = 0;
      this.tasks                = [];
      this.taskDetailsList      = [];
      this.showDataItems();
      this.checkoutForm.reset();
      this.submitted            = false;
      this.updateClass          = true
      this.showSidePanel        = false;
    },(err: any) => {this.updateClass = true});
  }
  /**************** METHOD FOR UPDATE *********************/
  updateTask(){
    this.taskOwnerService.taskOwnerUpdate(this.editId, { 'due_days': this.checkoutForm.value.due_days, 'module': this.checkoutForm.value.module, 'process' : this.checkoutForm.value.process, 'role' : this.checkoutForm.value.role}).subscribe((res: any) => {
      this.offset           = 0;
      this.updateClass      = true
      this.tasks            = [];
      this.taskDetailsList  = [];
      this.showDataItems();
      this.submitted        = false;
      this.showSidePanel    = false;
  },(err: any) => {this.updateClass = true});
  }
  /**************** METHOD FOR FILTER *********************/
  selectItemList(value:any){
    this.loader           = true;
    this.activeToggle     = value;
    this.offset           = 0;
    this.tasks            = [];
    this.taskDetailsList  = [];
    // this.showDataItems();
    this.child.clearBar();
  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword    = "";
      this.offset           = 0;
      this.tasks            = [];
      this.taskDetailsList  = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }
  /**************** METHOD FOR CLEAR MULTI SELECTED DATA *********************/
  clear(item:any,index:number){
    if(item == "process"){
      let daata = this.checkoutForm.value.process;
      daata.splice(index, 1);
      this.checkoutForm.patchValue({
        process: daata
      });
    }
  }
  viewtask(id:any){
    if(this.permissions.v){
      this.showSidePanel1   = true;
		  this.loader1          = true;
      this.taskOwnerService.taskOwnerGet(id).subscribe((res: any) => {
        this.taskname       = res.process_name;
        this.taskid         = res.id;
        this.task_module    = res.module_name;
        this.task_role      = res.role_name;
        this.task_due       = res.due_days;
        this.task_is_active = res.is_active;
		    this.loader1        = false;
      })
    }
  }
  searchtaskowner(searchKeyword:any){
    this.loader         = true;
    this.isLoading      = true
    this.offset         = 0;
    this.tasks          = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
