<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Scheduled Reports</h3>
      <p class="text-helper mb-md-0">Scheduled reports are reports that start automatically at a predefined time.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='schedulesearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchschedule($event)"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Inactive' }"
              (click)="statusBtn='Inactive';selectItemList(false)">Inactive</button>
          </div>
        </div>
        <button routerLink="/scheduled-reports/reports-setup" class="btn flex-shrink-0 btn-primary btn-icon btn-add"
          *ngIf="permissions.a"><i class="icon-plus"></i>SCHEDULE NEW REPORT </button>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
  <div *ngIf="nodata && !loader;else all_data" class="row " style="height: calc(100vh - 15rem);">
    <!-- <div class="col-12 text-center">
            <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
                style="width: 27.375rem;">
            <p class="text-uppercase text-light-400 fw-700 fs-14">NO SCHEDULED REPORTS ADDED YET</p>
        </div> -->
    <app-nodata-view class="vstack" [noDataImg]="'bg2.png'"    [noDataText]="'No scheduled report added yet'" [hasPermission]="false"> </app-nodata-view>


  </div>
  <ng-template #all_data>
    <div class="" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <div class="row row-16 ">
        <div class="col-12" *ngIf="activeCnt > 0">
          <p class="mb-0 fw-500 text-uppercase">Active Reports</p>
        </div>
        <div class="  col-md-3 col-sm-6  " *ngFor="let task of activeSchedules,let i = index">
          <ng-container *ngIf="task.is_active">
            <div class="card card-c2 card-hover">
              <div class="card-body indicater1 p-0">
                <div class="d-flex">
                  <div class="overflow-hidden view-section" (click)="viewSchedule(task.id);loader1= true;">
                    <h6 class="mb-0 fw-500 text-trim tips">{{task.scheduler_name}}</h6>
                    <p class="mb-0  tips-helper">{{task.scheduler_name}}</p>
                    <span class="fs-10 text-uppercase fw-500 text-success">Active</span>
                  </div>
                  <div ngbDropdown class="dropdown-section  d-inline-block " style="width: 1.625rem;"
                    *ngIf="permissions.e || permissions.d">
                    <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                      <i class="icon-three-dots-vertical fs-16"></i>
                    </button>
                    <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                      <button ngbDropdownItem (click)="editScheduleReport(task.id,task.is_filter)"
                        *ngIf="permissions.e">
                        <i class="icon-pencil-square me-1"></i> Edit
                      </button>
                      <button class="text-danger" ngbDropdownItem (click)="inactiveScheduleReport(task.id, 1)"
                        (click)="deleteAlert = true" *ngIf="permissions.d">
                        <i class="icon-close-lg fs-16-circle  me-1"></i> Inactive
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-16" (click)="viewSchedule(task.id)">
                <div class="row  row-16 fs-12">
                  <div class="col-lg-6 ">
                    <div class="text-light-400 fs-10 text-uppercase">Scheduled</div>
                    <div class="fw-500 ">{{task.schedule}}</div>
                  </div>
                  <div class="col-lg-6 overflow-hidden">
                    <div class="text-light-400 fs-10 text-uppercase">Start Date</div>
                    <div class="fw-500 text-trim ">{{this.appservice.dateFormatDisplay(task.schedule_start_date)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-12" *ngIf="inactiveCnt > 0">
          <p class="mb-0 fw-500 text-uppercase">INActive Reports</p>
        </div>
        <div class="  col-md-3 col-sm-6  " *ngFor="let task of inactiveSchedules,let i = index">
          <ng-container *ngIf="!task.is_active">
            <div class="card card-c2 card-hover" (click)="reSheduleReport(task.id)">
              <div class="card-body indicater1 p-0">
                <div class="d-flex">
                  <div class="overflow-hidden p-16 w-100 ">
                    <h6 class="mb-0 fw-500 text-trim tips">{{task.scheduler_name}}</h6>
                    <p class="mb-0  tips-helper">{{task.scheduler_name}}</p>
                    <span class="fs-10 text-uppercase fw-500 text-danger">inactive </span>
                  </div>
                </div>
              </div>
              <div class="card-body p-16">
                <div class="row  row-16 fs-12">
                  <div class="col-lg-6 ">
                    <div class="text-light-400 fs-10 text-uppercase">Scheduled</div>
                    <div class="fw-500 ">{{task.schedule}}</div>
                  </div>
                  <div class="col-lg-6 overflow-hidden">
                    <div class="text-light-400 fs-10 text-uppercase">Start Date</div>
                    <div class="fw-500 text-trim ">{{this.appservice.dateFormatDisplay(task.schedule_start_date)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='showSidePanel === true'
  *ngIf="permissions">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Employee Personal Details</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="d-flex mb-16">
          <p class="text-uppercase fw-500 mb-0">Scheduled report</p>
          <a class="link-primary fs-14 d-flex align-items-center ms-auto"
            (click)="editScheduleReport(viewScheduleData.id,viewScheduleData.is_filter)" *ngIf="permissions.e">
            <i class="icon-pencil-square me-1"></i> Edit
          </a>
        </div>
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <app-view-loader *ngIf="loader1"></app-view-loader>
              <div class="row row-24 " *ngIf="!loader1">
                <div class="col-12 "><span class="title">Schedular Details</span></div>
                <div class="col-12">
                  <div class="detail-title">Scheduler name</div>
                  <div class="detail-desc">{{viewScheduleData.scheduler_name}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Reports</div>
                  <!-- <div class="detail-desc">{{viewScheduleData.report_name}}</div> -->
                  <div class="detail-desc">
                    <div class="d-flex flex-wrap mt-8 gap-8">
                      <span class="badge2" *ngFor="let rptname of viewScheduleData.report_name, let i = index">
                        <div class="fs-10" alt="">{{rptname}}</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12">
                  <div class="detail-title">Schedule</div>
                  <div class="detail-desc">{{viewScheduleData.schedule}} </div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12"
                  *ngIf="viewScheduleData.schedule == 'Monthly' || viewScheduleData.schedule == 'Yearly'">
                  <div class="detail-title">Schedule Date</div>
                  <div class="detail-desc">{{viewScheduleData.report_scheduler_date == null ? '-' :
                    viewScheduleData.report_scheduler_date}}</div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12" *ngIf="viewScheduleData.schedule == 'Weekly'">
                  <div class="detail-title">Schedule Day</div>
                  <div class="detail-desc">{{viewScheduleData.report_scheduler_day == null ? '-' :
                    viewScheduleData.report_scheduler_day}}</div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12" *ngIf="viewScheduleData.schedule == 'Yearly'">
                  <div class="detail-title">Schedule Month</div>
                  <div class="detail-desc">{{viewScheduleData.report_scheduler_month == null ? '-' :
                    viewScheduleData.report_scheduler_month}}</div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc">
                    <span class="fs-12 fw-500 text-success text-uppercase">{{viewScheduleData.is_active == true ?
                      "ACTIVE" : "DELETED"}}</span>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12">
                  <div class="detail-title">Report scheduler time</div>
                  <div class="detail-desc">{{viewScheduleData.report_scheduler_time}}</div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12">
                  <div class="detail-title">Schedule start date</div>
                  <div class="detail-desc">{{this.appservice.dateFormatDisplay(viewScheduleData.schedule_start_date)}}
                  </div>
                </div>
                <div class="col-lg-4  col-sm-6 col-12">
                  <div class="detail-title">Schedule end date</div>
                  <div class="detail-desc">{{this.appservice.dateFormatDisplay(viewScheduleData.schedule_end_date)}}
                  </div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Description</div>
                  <div class="detail-desc">{{viewScheduleData.description}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24 " *ngFor="let emprights of viewScheduleData.scheduled_report_right, let i = index">
                <div class="col-12 "><span class="title">Report details</span></div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Company</div>
                  <div class="detail-desc">
                    <div class="selected-values mt-1" *ngIf="emprights.company_name?.length != 0;else noData">
                      <li class="ng-value" *ngFor="let rights of emprights.company_name, let i=index;">
                        <div class="ng-value-label" title="{{rights.company_name}}">
                          <span class="d-inline-flex align-items-center">
                            <img class="img-fluid rounded-circle sq-24" src="{{rights.logo}}"
                              *ngIf="rights.logo !=  '' && rights.logo !=  null">
                            <span class="avatar sq-24 avatar-circle bg1"
                              *ngIf="rights.logo ==  null || rights.logo ==  ''">{{this.appservice.getFirstChar(rights.company_name,1)}}</span>
                            <span> {{rights.company_name == '' ? '-' : rights.company_name}}</span>
                          </span>
                        </div>
                      </li>
                    </div>
                    <ng-template #noData>-</ng-template>
                  </div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Business unit</div>
                  <div class="detail-desc">{{emprights.businessunit_name == '' ? '-' : emprights.businessunit_name}}
                  </div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Designation</div>
                  <div class="detail-desc">{{emprights.designation_name == '' ? '-' : emprights.designation_name}}</div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Grade </div>
                  <div class="detail-desc">{{emprights.grade_name == '' ? '-' : emprights.grade_name}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Department</div>
                  <div class="detail-desc">{{emprights.department_name == '' ? '-' : emprights.department_name}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Branch</div>
                  <div class="detail-desc">{{emprights.branch_name == '' ? '-' : emprights.branch_name}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Employee</div>
                  <div class="detail-desc">
                    <div class="selected-values mt-1">
                      <li class="ng-value" *ngFor="let rights of emprights.employee_name, let i=index;">
                        <div class="ng-value-label" title="{{rights.employee_name}}">
                          <span class="d-inline-flex align-items-center">
                            <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}"
                              *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                            <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                              *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appservice.getFirstChar(rights.first_name,1)}}</span>
                            <span> {{emprights.employee_name.length==0 ? '-' :
                              (rights.first_name+(rights.middle_name!=null &&
                              rights.middle_name!=""?rights.middle_name:"")+" "+rights.last_name+"
                              ("+rights.employee_code+")")}}</span>
                          </span>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="viewScheduleData.is_filter">
            <ng-container *ngFor="let filter of viewScheduleData.report_filter_saved, let i = index">
              <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                  <div class="row row-24 ">
                    <div class="col-12 "><span class="title">{{filter.report__name}} DATA</span></div>
                    <div class="col-12">
                      <div class="detail-title">Column's</div>
                      <div class="detail-desc">
                        <div class="d-flex flex-wrap mt-8 gap-8">
                          <span class="badge2" *ngFor="let hederdata of filter.header_filter, let i = index">
                            <div class="fs-10" alt="">{{hederdata}}</div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="detail-title">Filter's applied</div>
                      <div class="d-flex flex-wrap mt-8 gap-8">
                        <ng-container *ngFor="let item of filter.data_filter | keyvalue">
                          <ng-container
                            *ngIf="item.value != '' && item.key != 'pay_group' && item.key != 'branch' && item.key != 'city' && item.key != 'state' && item.key != 'country'  && item.key != 'grade' && item.key != 'designation' && item.key != 'department' && item.key != 'bu' && item.key != 'company' && item.key != 'employee_list' && item.key != 'emp_status' && item.key != 'emp_type' && item.key != 'company_name'">
                            <span class="badge2">
                              <div class="fs-10" alt="">{{removeUnderscoreAddUpperCase(item.key)}} : </div>
                              <ng-container
                                *ngIf="item.key == 'salary_month' || item.key == 'date_of_joining' || item.key == 'requested_on' ;else all_data">
                                <ng-container
                                  *ngIf="item.value != 'this_month' && item.value != 'last_month' ;else all_data1">
                                  <div class="fs-10" alt=""> <b> {{this.appservice.dateFormatDisplay(item.value)}}</b>
                                  </div>
                                </ng-container>
                                <ng-template #all_data1>
                                  <div class="fs-10" alt=""> <b> {{removeUnderscoreAddUpperCase(item.value)}}</b></div>
                                </ng-template>
                              </ng-container>
                              <ng-template #all_data>
                                <ng-container
                                  *ngIf="item.key === 'pay_group_name' || item.key === 'branch_name' || item.key === 'bu_name' || item.key === 'city_name' || item.key === 'country_name' || item.key === 'department_name' || item.key === 'designation_name' || item.key === 'grade_name' || item.key === 'employee_list_name' || item.key === 'state_name'; else singleData">
                                  <div class="fs-10" alt=""> <b>
                                      {{(item.key!='employee_list_name')?(item.value):createEmployeeDisplay(filter.data_filter.employee_list_name)}}</b>
                                  </div>
                                </ng-container>
                                <ng-template #singleData>
                                  <div class="fs-10" alt=""> <b> {{removeUnderscoreAddUpperCase(item.value)}}</b></div>
                                </ng-template>
                              </ng-template>
                            </span>
                          </ng-container>
                          <!-- Company Logo Integration Start -->
                          <ng-container *ngIf="item.key == 'company_name'">
                            <span class="badge2">
                              <div class="fs-10" alt="">Company : </div>
                              <ng-container *ngFor="let companyData of filter.data_filter.company_name, let i = index">

                                <div class="fs-10" alt=""> <img class="img-fluid rounded-circle sq-24 "
                                    src="{{companyData.logo}}">
                                  <b> {{companyData.company_name}},</b>
                                </div>

                              </ng-container>
                            </span>
                          </ng-container>
                          <!-- Company Logo Integration End -->
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TO INACTIVATE SCHEDULE REPORT -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="icon-close-lg fs-16-circle text-danger"></i>
        <h4 class="modal-title">Inactive Report?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">This will disable the Report, Are you sure you want inactivate this Report?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="inactiveScheduleReport(scheduleId,2)">Inactive</button>
      </div>
    </div>
  </div>
</div>

<!-- TO ACTIVATE SCHEDULE REPORT -->
<div class="modal  modal-alert {{rescheduleAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="reScheduleReportForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="icon-calendar pointer text-success"></i>
          <h4 class="modal-title">Activate schedular</h4>
        </div>
        <div class="modal-body">
          <p class="mb-16">Enter a new start date and end date to activate the schedular</p>
          <div class="row">
            <div class="col-6">
              <label for="from_date" class="form-label required ">Schedule Start Date </label>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.schedule_start_date.touched) &&  f.schedule_start_date.errors}">
                <input (click)="datePicker1.open()" class="form-control"
                  placeholder="{{this.messageService.selectplaceholddisplay('Date')}}"
                  [matDatepickerFilter]="dateFilter" matInput formControlName="schedule_start_date"
                  [matDatepicker]="datePicker1"
                  [ngClass]="{ 'is-invalid': (submitted || f.schedule_start_date.touched) &&  f.schedule_start_date.errors}"><i
                  class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.schedule_start_date.touched) && f.schedule_start_date.errors"
                class="invalid-feedback">
                <div *ngIf="f.schedule_start_date.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <label for="from_date" class="form-label required ">Schedule End Date </label>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.schedule_end_date.touched) &&  (f.schedule_end_date.errors || reScheduleReportForm.errors!=null)}">
                <input (click)="datePicker2.open()" class="form-control"
                  placeholder="{{this.messageService.selectplaceholddisplay('Date')}}"
                  [matDatepickerFilter]="dateFilter" matInput formControlName="schedule_end_date"
                  [matDatepicker]="datePicker2"
                  [ngClass]="{ 'is-invalid': (submitted || f.schedule_end_date.touched) &&  (f.schedule_end_date.errors || reScheduleReportForm.errors!=null)}">
                <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
              </div>
              <div
                *ngIf="(submitted || f.schedule_end_date.touched) && (f.schedule_end_date.errors || reScheduleReportForm.errors!=null)"
                class="invalid-feedback">
                <div *ngIf="f.schedule_end_date.errors?.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="reScheduleReportForm.errors!=null">Schedule End Date should be greater than schedule start
                  date.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
            (click)="rescheduleAlert = false">Cancel</button>
          <button type="button" class="btn btn-primary btn-sm text-uppercase" [disabled]="saveClicked"
            (click)="submitReshedule()">save</button>
        </div>
      </div>
    </form>
  </div>
</div>
