import { Component, OnInit, ViewChild } from '@angular/core';
import { SeparationPolicyService } from 'src/app/@core/services/separation-policy.service';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-reason-list',
  templateUrl: './reason-list.component.html',
  styleUrls: ['./reason-list.component.scss']
})
export class ReasonListComponent implements OnInit {
detailsView               = false;
editReason                = false;
loader                    = false;
reasonlist                :any =[];
reasons                   :any =[];
reasonsForm               :any;
offset                     = 0;
listlength                 = 0;
defaultlimit:any           = 20;
infinityloader             = false;
searchKeyword              = "";
nodata                     = false;
status                     = true;
searchlistdata:any         = [] ;
dataId :any                = "";
disabled                  = false;
addEditBtn                  = '';
submitted                 = false;
deleteClass                =false;
header                    = '';
editflag: boolean         = false;
viewdata:any              = [];
deleteClicked             = false;
deleteToggle              = false;
statusBtn:any             = 'Active';
separationPlaceholder:string  = this.messageService.searchdisplay('Reason');
viewLoader                 = false;
direction                  = '';
permissions:any            = [];
loader1                    = false;
value:any;
id:any
@ViewChild(SearchbarComponent) child:any;
private subjectKeyUp = new Subject<any>();
reasonUnit:any             =[];
isLoading = false
searchData : Subject<void> = new Subject();

  constructor(
    public separationpolicyservice:SeparationPolicyService,
    public messageService : MessageService,
    public formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService:AppService,
    public existDataService :  ExistDatasService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    setTimeout(()=>{
      this.loader = false;
    }, 3000);
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.reasonsForm = this.formBuilder.group({
       id: 0,
       name: ['', [Validators.required, UnamepatternValidator(), Validators.maxLength(100)]]
    });
    this.getreasondata();
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.separationpolicyservice.getreasonData(this.status,this.defaultlimit, this.offset,this.searchKeyword,'')
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/separation/reasons-list');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getreasondata() {
    this.loader = true;
    this.separationpolicyservice.getreasonData(this.status,this.defaultlimit, this.offset,this.searchKeyword,'').subscribe((res: any) => {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.reasonlist = res.results;
    if(this.offset ==0){
      this.reasons = [];
    }
    this.listlength = res.results.length;
    if(this.listlength<=0){
      this.nodata = this.offset == 0 ? true :false;
    }
    else{
      this.nodata= false;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.reasons.push(this.reasonlist[i]);
    }
  }

  get f() { return this.reasonsForm.controls; }

  // add form
  addreason(){
    this.editflag = false;
    this.detailsView = false;
    this.editReason = true;
    this.header = "Add";
    this.addEditBtn = "Add";
    this.reasonsForm.reset({ id: 0});
    this.reasonsForm.enable();
    this.submitted= false;
    this.disabled = false;
    this.child.clearBar();
    this.deleteClass = false;
  }

  // edit form
  editreason(id:any){
    this.editflag = true;
    this.detailsView = false;
    this.editReason = true;
    this.header = "Edit";
    this.addEditBtn = "Save"
    this.loader1           = true;
    this.deleteClass = true;
    this.separationpolicyservice.getviewreason(id).subscribe((res: any) => {
      this.reasonsForm.reset({
          id: res.id,
          name: res.name
        });
       this.loader1           = false;
    });

  }
  savereason(){
    this.submitted = true;
      // return if validation fails
      if(this.reasonsForm.invalid){
        return
      }

      this.disabled = true;
      let myFormData ={
        'name' : this.reasonsForm.value.name
      }
      if(this.addEditBtn === "Add"){
        // add api
        this.separationpolicyservice.createreason(myFormData).subscribe((res: any) => {
          this.offset = 0;
          this.reasons = [];
          this.getreasondata();
          this.editReason = false;
        },
        (err: any) => {
          this.disabled = false;
        });
      }else{
        //  API for update
        this.separationpolicyservice.updatereason(this.reasonsForm.value.id,myFormData).subscribe((res: any) => {
          this.offset = 0;
          this.reasons = [];
          this.getreasondata();
          this.editReason = false;
        },
        (err: any) => {
          this.disabled = false;
        });
      }
  }

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value);
  }

  viewreason(id:any){
    if(this.permissions.v){

      this.detailsView = true;
      this.viewLoader=true;
      this.separationpolicyservice.getviewreason(id).subscribe((res: any) => {
        this.viewdata = res;
        this.viewLoader=false;
      });
    }
  }

  deletereason(id:any){
    this.reasonsForm.value.id = id;
    this.deleteToggle = true;
  }

  confirmDelete(){
    this.deleteClicked = true;
    this.editReason = false;
    this.separationpolicyservice.deletereason(this.reasonsForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.deleteToggle = false;
      this.deleteClicked = false;
      this.loader           = true;
      this.offset = 0;
      this.reasons = [];
      this.getreasondata();
    },
    (err: any) => {
      this.deleteClicked = false;
      this.deleteToggle = false
    });
  }

  statusfilter(active_status:any){
    this.status           = active_status;
    this.reasons = [];
    this.offset            = 0;
    this.child.clearBar();
  }

  searchresults(data:any){
    this.loader           = true;
    this.reasons = [];
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchreason(searchKeyword:any){
    this.loader = true;
    this.isLoading = true;
    this.offset            = 0;
    this.reasons = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
     this.searchData.next()
    }
  }

  onScrollDown() {
    this.infinityloader     = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getreasondata();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page": "Reason",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"Reason",
        "field":"name",
        "value": this.f.name.value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.reasonUnit = res.body

      if(this.reasonUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }
}
