import { Component, OnInit } from '@angular/core';

@Component({
  selector: ' app-view-loader',
template: `
                  <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '150px' }"></ngx-skeleton-loader></p>
                    <a class="link-primary fs-14 d-flex align-items-center ms-auto">
                       <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '22px','width': '22px' }"></ngx-skeleton-loader>
                    </a>
                  </div>
                  <div class="row row-24 mb-24" *ngFor="let dummy of ' '.repeat(2).split(''), let x = index">
                    <div class="col-12">
                      <div class="card details-card shadow-0 px-24 py-16">
                        <div class="row row-24 " >
                          <div class="col-12 "><span><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '80px' }"></ngx-skeleton-loader></span></div>
                          <ng-container *ngFor="let dummy of ' '.repeat(2).split(''), let x = index">
                            <div class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader></div>
                                <div class="detail-desc"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '127px', 'width': '100%' }"></ngx-skeleton-loader></div>
                            </div>
                            <div class="col-lg-3  col-sm-6 col-12">
                              <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader></div>
                              <div class="detail-desc">
                                <span class="fs-12 fw-500 text-success text-uppercase"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '127px', 'width': '100%' }"></ngx-skeleton-loader></span>
                              </div>
                            </div>
                            <div class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader></div>
                                <div class="detail-desc"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '127px', 'width': '100%' }"></ngx-skeleton-loader></div>
                            </div>
                            <div class="col-lg-3  col-sm-6 col-12">
                              <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader></div>
                              <div class="detail-desc"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '127px', 'width': '100%' }"></ngx-skeleton-loader></div>
                            </div>
                          </ng-container>

                        </div>
                      </div>
                    </div>
                  </div>
  `,
  styles: [
  ]
})
export class ViewLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
