import { Component, OnInit, Input,ViewChild, Inject } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ExpenseAdvnaceProfileService } from 'src/app/@core/services/expense-advance-profile.service';
import { MessageService } from 'src/app/message.global';
import { MapsAPILoader } from '@agm/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.scss']
})
export class AdvanceComponent implements OnInit {
  addadvance = false;
  viewAdvance = false;
  permissions: any
  page = 1;
  listlength = 0;
  pageSize: any = 20;
  advancedatalist: any = [];
  searchKeyword = "";
  activeToggle: any = '';
  loader1 = false;
  loader = false;
  nodata = false;
  searchlistdata:any  = [] ;
  temparr:any         = [] ;
  advancePlaceholder:string  = this.messageService.searchdisplay('Advance');
  filterform:FormGroup= this.formBuilder.group({
    status                     : ['All'],   
    expense_date__range        : [null],    
  })
  filter =false;
  submitted =false;
  dateFormat          :any
  statuslist:any =[
    'All',
    'Accepted',
    'Pending',
    'Dispensed',
    'Rejected',
    'Carry forward',
  ];
 
  outstandingamount:any;
  viewdetailsout =false;
  advanceid:any;
  Isalert =true;

  @ViewChild(SearchbarComponent) child:any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  expense_date__range:any='';
  userId:any
  validationdata:any=[];
  alertToggle       = false;
  alertMsg:any      = '';

  viewLoader   = false;
  viewdata:any = [];
  currency:any;
  sLoading = false
  
  constructor(
    public appService: AppService,
    public formBuilder: FormBuilder,
    private ExpenseAdvnaceProfileService: ExpenseAdvnaceProfileService,
    public messageService: MessageService,
    private authService:AuthService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,

  ) { }

  ngOnInit(): void {
    let userData        = this.appService.getEmpData();;
    this.currency       = userData.currency;
    this.getDateFormat()
    this.getPermission();
    this.showDataItems();   
  }
  showDataItems() {
    this.loader = true;
    this.ExpenseAdvnaceProfileService.getlistData(this.activeToggle, this.searchKeyword,this.expense_date__range).subscribe((res: any) => {
      this.advancedatalist = [];
      this.listlength = res?.length;​
        if (res?.length == 0) {
          this.nodata = true;
        } else {
          this.nodata = false;
        } 
        for (let i = 0; i < this.listlength; ++i) {
        this.advancedatalist.push(res[i]);
        }   
        this.temparr  = this.advancedatalist;  
        this.sLoading = false;
        this.loader     = false;
    })  
    // this.ExpenseAdvnaceProfileService.getSearchList('',this.activeToggle).subscribe((res: any) => {
    //   for (let i = 0; i < res.length; i++) {        
    //     if (res[i]['number'] != null)
    //       res[i]['name'] = res[i]['number'] ;
    //   }  ​
    //   this.searchlistdata = res;
    //   this.loader = false;
    // });
  }

  addNewdata(){
    this.userId = this.authService?.isEmployeeId;
    this.ExpenseAdvnaceProfileService.getvaliddata(this.userId).subscribe((res: any) => {
      this.validationdata=res;  
      if(this.validationdata.data?.advance_policy==true){
        if(this.validationdata.data?.pending_advance_requests==true) {
          this.alertToggle = true;
          this.alertMsg = 'This request cannot be raised, as there is a pending advance request.';
        }else if(this.validationdata.data?.pending_dispense_requests==true){
          this.alertToggle =true;
          this.alertMsg = 'Advance request cannot be raised, as there is a pending advance dispense';
        }else{
        this.addadvance=true;
        }
      }else{
        this.alertToggle =true;
        this.alertMsg = 'Advance request cannot be raised as advance policy is not configured yet.';
      }      
   });

  }

  closefn(daata:any){
    this.addadvance = daata;
  }

  closefnview(daata:any){
    this.viewAdvance = daata.viewAdvance;
    if(daata.mapped=='mapped'){
    this.showDataItems();   
    }
  }

  closeoutstandview(daata:any){
    this.viewdetailsout = daata;
  }

  detailout(event:any){
    if(event == true){
      this.showDataItems();
    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/expense-advance');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  searchresults(data:any){
    this.loader           = true;
    this.advancedatalist = [];
    this.page = 1;
    if(data == ""){
      this.searchKeyword  = "";
      this.showDataItems();
    }else{
      for (let i = 0; i < this.temparr.length; i++) {        
        if (this.temparr[i]['id'] == data)
        this.advancedatalist.push(this.temparr[i]);
      }  
      this.loader           = false;
    }

  }
  searchadvance(searchKeyword:any){
    this.sLoading = true;
    this.loader = true;
    this.advancedatalist = [];
    this.searchKeyword = searchKeyword;
    this.page = 1;
    if(searchKeyword != ""){
      this.showDataItems();
    }
  }

  selectItemList(value:any){
      this.loader        = true;
      this.activeToggle     = value;
      this.page             = 1;
      // this.requests           = [];
      this.advancedatalist = [];      
      // this.showDataItems();    
      this.child.clearBar();
  }  
 
  newRequestSidePanelFunction(){
    this.addadvance=true;
  }

  get f() { return this.filterform.controls; }

  getDateFormat(){
    this.dateFormat = this.appService.getdatepickerformat();
    if(this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  applyfilter(){
    if(this.filterform.value?.status ==  'All' || this.filterform.value?.status == null){
      this.activeToggle='';
    }else{
      this.activeToggle=this.filterform.value?.status;
    }

    if(this.filterform.controls.expense_date__range?.value?.startDate != null && this.filterform.controls.expense_date__range?.value?.endDate != ''){
      let start1         = moment(this.filterform.controls.expense_date__range?.value?.startDate,this.dateFormat).format('YYYY-MM-DD');
      let end1           = moment(this.filterform.controls.expense_date__range?.value?.endDate,this.dateFormat).format('YYYY-MM-DD');
      this.expense_date__range   = start1 +','+ end1;
    }else{
      this.expense_date__range='';
    }   
    this.filter=false;
    this.showDataItems();
    this.searchKeyword = '';
    this.child.clearBar();
  }

  resetfn(){
    this.activeToggle='';
    this.filterform.controls?.expense_date__range?.reset();
    this.expense_date__range='';
    this.filterform.controls?.status?.reset();
    this.filter=false;
    this.showDataItems();
    this.searchKeyword = '';
    this.child.clearBar();
  }
  
  closed(bool:any){
    this.alertToggle = bool
  }

  viewAdvanceDetails(id:any){
    this.viewLoader = true;
    this.viewAdvance = true;
    this.advanceid  = id;
    this.ExpenseAdvnaceProfileService.getadvance(this.advanceid).subscribe((res: any) => {
      this.viewdata = res;
      this.viewLoader = false;
   });
  }

}
