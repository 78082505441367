import { Component, OnInit,Output,EventEmitter, Input} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-reimbursement-category',
  templateUrl: './reimbursement-category.component.html',
  styles: [
  ]
})
export class ReimbursementCATEGORYComponent implements OnInit {

  @Input()formGp : FormGroup = this.fb.group({

  })
  @Input()categoryData:any;
  @Input() currency:any;
  @Output() submitmethod        = new EventEmitter();
  currentData:any;
  currentYear               = new Date().getFullYear();
  currentMonth              = new Date().toLocaleString('en-us', { month: 'long' }).toUpperCase();
  constructor(public fb:FormBuilder,
    private notificationService:NotificationService,
    public appService: AppService
    ) { }

  ngOnInit(): void {
  }
  setpaycomponent(id:any){

    var reimbursement         = this.categoryData.filter(function(category:any) {
      return category.pay_component_id == id;
    });
    this.currentData  = reimbursement;
    if(this.currentData[0]['balance']>0)
      this.formGp.get('paycomponent')?.setValue(id);
    else{
      this.formGp.get('paycomponent')?.setValue('');

      this.notificationService.handleErrorNotification("The reimbursement amount is not entitled or bills for the entitled amount have already been submitted." ,"Error");
    }


  }
  changeTab(number:any){
  if(this.formGp.invalid)
    return
  this.submitmethod.emit(number);

  }
  get f() {return  this.formGp.controls; }


}
