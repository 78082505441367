<div class="card card-c2 card-highlight highlight-primary">
  <div class="card-body px-24 py-12 ">
      <div class="d-flex gap-35 flex-wrap">
          <div class="">
              <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                  total payroll cost</div>
              <div class="fs-22 mb-6 lh-1">{{payrollMonthlyCost?.month_pay_salary|
                  currency:currency}}</div>
              <div class="fs-10 fw-500 text-uppercase ">
                  <span class="text-light-400 ">{{getPreMonth(payroll_month,payroll_year)}}
                  </span>
                  <span class=" ms-8 {{getClass(payrollMonthlyCost?.compare_pay_salary)}}">{{getAbs(payrollMonthlyCost?.compare_pay_salary)}}</span>
              </div>
          </div>
          <div class="my-auto">=</div>
          <div class="">
              <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                  Employee Net Pay</div>
              <div class="fs-22 mb-6 lh-1">{{payrollMonthlyCost?.month_net_pay|
                  currency:currency }}</div>
              <div class="fs-10 fw-500 text-uppercase "><span
                      class="text-light-400 ">{{getPreMonth(payroll_month,payroll_year)}}
                  </span> <span
                      class=" ms-8 {{getClass(payrollMonthlyCost?.compare_net_pay)}}">{{getAbs(payrollMonthlyCost?.compare_net_pay)}}</span>
              </div>
          </div>
          <div class="my-auto">+</div>
          <div class="">
              <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6"> Total
                  deductions</div>
              <div class="fs-22 mb-6 lh-1">{{payrollMonthlyCost?.month_total_deductions|
                  currency:currency }}</div>
              <div class="fs-10 fw-500 text-uppercase "><span
                      class="text-light-400 ">{{getPreMonth(payroll_month,payroll_year)}}
                  </span> <span
                      class=" ms-8 {{getClass(payrollMonthlyCost?.compare_total_deductions)}}">{{getAbs(payrollMonthlyCost?.compare_total_deductions)}}</span>
              </div>
          </div>
          <div class="my-auto">+</div>
          <div class="">
              <div class="fs-10 fw-500 text-uppercase text-light-400 mb-1">
                  Total contributions</div>
              <div class="fs-22 mb-1 lh-1">
                  {{payrollMonthlyCost?.month_employer_contributions|
                  currency:currency}}</div>

          </div>
      </div>
  </div>
</div>
