import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-loader',
  template: `
      <div style="width:50vw;height:100vh;">
        <p class="text-uppercase fw-500">
          <span></span>
          <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '100vh','width': '50vw' }"></ngx-skeleton-loader></p>
      </div>

  `,
  styleUrls: []
})
export class LoginLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
