
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output,OnChanges,SimpleChanges  } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-override',
  templateUrl: './override.component.html',
  styleUrls: ['./override.component.scss']
})
export class OverrideComponent implements OnInit {
  ngOnChanges(changes: SimpleChanges ) {

    // console.log(changes)
    // if (changes.data && changes.data.currentValue) {
    //   console.log(changes.data.currentValue);
    // }
  }
  status = 'PENDING';
  showSidePanel = false;

  inputData = [];
  successData = [];
  keyArray = [];
  preparedData = []
  page = 1
  submiited = false;
  searchString:any = "";
  filter=false
  buttonText="Remove";
  confirmMsg = false
  alertToggle = false;
  empalertMsg:any="";

  @Input() month:any
  @Input() year:any
  @Input() company:any
  @Input() permissions:any = [];
  @Input() fileName = '';
  @Input() data:any = [];
  @Input() fileSize = '';
  @Input() invalidFormat = false;
  @Input() loader = false;
  @Input() pageSize:any;
  @Input() componentType:any;
  @Input() sampleFile:any;
  @Input() navLoader:any;


  @Input() employeeList:any = [];
  @Input() HistoryData:any = [];
  @Input() selctedData:any = [];

  @Output() fileChange =  new EventEmitter<{event: any}>();
  @Output() deleteFile =  new EventEmitter;
  @Output() validateData = new EventEmitter;
  @Output() changeSelectedEmployee = new EventEmitter<{empId: any,index:any,page:any}>();
  @Output() addEmployee = new EventEmitter;
  @Output() checkall = new EventEmitter<{searchString: any,page:any,checked:any,status:any}>();
  @Output() deleteSingleRow = new EventEmitter<{searchString: any,page:any,index:any}>();
  @Output() save = new EventEmitter;
  @Output() deleteCompleted = new EventEmitter<{data: any}>();
  @Output() resetFilterEmit = new EventEmitter;
  @Output() submitFilter = new EventEmitter<{data: any}>();
  @Output() updateValidatedData = new EventEmitter<{index: any,field:any,value:any}>();


  constructor(public messageService : MessageService,public appService: AppService ,
    // public changeDetectorRef: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {
  }

  deleteUploadedFile(){
    this.showSidePanel = false;
    this.deleteFile.emit()
  }

  onFileChange(evt: any) {
    this.fileChange.emit({event:evt.event});
  }
  validatedata(){
    this.showSidePanel = false;
    this.validateData.emit()
  }

  addEmployeeData(){
    this.addEmployee.emit()
    let lastpage = Math.ceil(this.data.length / this.pageSize);
    if(this.page<lastpage){
      this.page = lastpage>0?lastpage:1
    }
  }

  checkAllSelected(){
    if(this.status == 'PENDING'){
      var newList = this.appService.filterIt(this.data,this.searchString);
      var begin = (this.page-1) * this.pageSize ;
      var end = this.page* this.pageSize;
      var subArray = newList.slice(begin,end);
      if(subArray.length){
        return subArray.every((item: { checked: any; }) => {
          return item.checked;
        });
      } else {
        return false;
      }
    } else {
      var newList = this.appService.filterIt(this.HistoryData,this.searchString);
      var begin = (this.page-1) * this.pageSize ;
      var end = this.page* this.pageSize;
      var subArray = newList.slice(begin,end);
      if(subArray.length){
        return subArray.every((item: { checked: any; }) => {
          return item.checked;
        });
      } else {
        return false;
      }
    }
  }
  deleteRow(index:any){
    this.deleteSingleRow.emit({searchString:this.searchString,page:this.page,index:index});
    let lastpage = Math.ceil(this.data.length / this.pageSize);
    if(this.page>lastpage){
      this.page = lastpage>0?lastpage:1
    }
  }

  showinfo(){
    this.alertToggle = false;
    this.confirmMsg = false;
    let flag:any = false
    let confirmflag:any = false
    this.HistoryData.forEach((item:any, value:any) => {
      if(item.checked){
        if(item.salary_status == 'Freeze' || item.salary_status == 'Hold' ||  item.salary_status == 'Publish'){
          this.alertToggle = true;
        } else {
          this.confirmMsg=true
        }
      }
    })

    this.empalertMsg = 'The selected employee(s) payroll has already been Freezed/Held/Published. Kindly undo the respective action to map/unmap overrided '+this.componentType+' data';
    // this.empalertMsg = 'Salary has already freezed for the selected employees. Kindly unfreeze to make any changes in Override values'
  }

  bulkDelete(){
    this.loader = true
    var delArray:any = [];
    if(this.status == 'PENDING'){
      this.data.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
      var delArrayRev = delArray.reverse()
      delArrayRev.forEach((item:any, value:any) =>{
        this.deleteRow(item);
      })
      this.loader = false
    } else {
      let delData:any = []
      this.HistoryData.forEach((item:any, value:any) => {
        if(item.salary_status != 'Freeze' && item.salary_status != 'Hold' &&  item.salary_status != 'Publish' && item?.checked == true){
          delData.push({id:item.id,employee:item?.employee_id})
        }

      });
      this.deleteCompleted.emit({data:delData})
      this.confirmMsg = false
    }

  }
  deletePossibleCount(){
    let delData:any = []
    this.HistoryData.forEach((item:any, value:any) => {
      if(item.salary_status != 'Freeze' && item.salary_status != 'Hold' &&  item.salary_status != 'Publish' && item?.checked == true){
        delData.push({id:item.id,employee:item?.employee_id})
      }
    });
    return delData.length
  }
  countSelected(){
    var delArray:any = [];
    if(this.status == 'PENDING'){
      this.data.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
    } else {
      this.HistoryData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(this.HistoryData[value].id)
      });
    }
    return delArray.length
  }
  countSelectedWithoutError(){
    var delArray:any = [];

    if(this.status == 'PENDING'){
      this.data.forEach((item:any, value:any) => {

        var rowvalid = true
        if(item.inputerror!=undefined){
          if(item.inputerror?.employee_overide != undefined && !item.inputerror?.employee_overide?.is_valid)
              rowvalid = false
          if(item.inputerror?.employer_overide != undefined && !item.inputerror?.employer_overide?.is_valid)
              rowvalid = false
          if(item.inputerror?.cess_overide != undefined && !item.inputerror?.cess_overide?.is_valid)
              rowvalid = false
          if(item.inputerror?.comment != undefined && !item.inputerror?.comment?.is_valid)
              rowvalid = false
        }

        if(item?.checked == true && item?.error == false && rowvalid)
          delArray.push(value)
      });
    }

    return delArray.length
  }

  selectAll(){
    if(this.status == 'PENDING'){
      this.appService.selectAll(this.data)
    } else {
      this.appService.selectAll(this.HistoryData)
    }
  }
  unselectAll(){
    if(this.status == 'PENDING'){
      this.appService.unselectAll(this.data)
    } else {
      this.appService.unselectAll(this.HistoryData)
    }
  }
  saveData(){
    // this.loader = true
    this.save.emit()
  }
  resetFilter(){
    this.resetFilterEmit.emit()
    this.filter  = false
  }
  submitForm(data:any){
    this.submitFilter.emit({data:data})
    this.filter  = false
  }
  closeFilter(){
    this.filter  = false
  }
  validateField(event:any){

    let field:any = event.field
    let index                   =  event.i
    this.validateFieldRow(index,field)


  }
  validateFieldRow(index:any,field:any){

    let currentValue            = this.data[index][field]
    const digitdecimalpattern   = /^[0-9]\d*(\.\d{1,2})?$/.test(currentValue);
    if(this.data[index]['inputerror'] == undefined)
      this.data[index]['inputerror'] = []
    if(this.data[index]['inputerror'][field] == undefined)
      this.data[index]['inputerror'][field] =[]
    this.data[index]['inputerror'][field].is_valid  = true;
    this.data[index]['inputerror'][field].error     = null;
    if(field=='comment'){
      const unamePattern = /^(?! )+[A-Za-z0-9 @#&_+\-\;':"\\,\/]*[A-Za-z0-9@#&_+\-\;':"\\,\/]$/.test(currentValue);
      if(unamePattern){
        if(currentValue?.length>100){

          this.data[index]['inputerror']['comment'].is_valid = false;
          this.data[index]['inputerror']['comment'].error    = this.messageService.fieldlengthvalidation('word',500);

        }  else if(currentValue=='' || currentValue==null){
          this.data[index]['inputerror']['comment'].is_valid = false;
          this.data[index]['inputerror']['comment'].error    = this.messageService.validationDisplay('required');
        }
         else
         this.data[index]['inputerror']['comment'].is_valid = true;
      }
      else{
        this.data[index]['inputerror']['comment'].is_valid = false;
        this.data[index]['inputerror']['comment'].error = this.messageService.validationDisplay('pattern');;

      }

    } else {

      if(currentValue=='' || currentValue==null){

      }
      else{
        if(digitdecimalpattern){
          if(currentValue.length>10){
            this.data[index]['inputerror'][field].is_valid  = false;
            this.data[index]['inputerror'][field].error     = this.messageService.fieldlengthvalidation('value','10');
          } else if(this.componentType == 'PF' && field == 'employer_overide' && currentValue>1250){
            this.data[index]['inputerror'][field].is_valid  = false;
            this.data[index]['inputerror'][field].error     = "EPS amount should not exceed 1250/- per month.";
          }
        }
        else{
          this.data[index]['inputerror'][field].is_valid  = false;
          this.data[index]['inputerror'][field].error     = this.messageService.validationDisplay('pattern');
        }
      }
    }


  }
  focusoutfield(event:any){
    let i:any = event.i
    let field:any = event.field
    let index                   = event.i;
    this.updateValidatedData.emit({index: index,field:field,value:this.data[index][field]});
  }
  closeInfo(bool:any){
    this.alertToggle = bool;
  }
}
