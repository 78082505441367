import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InboxService } from 'src/app/@core/services/inbox.service';
import * as moment from 'moment';



@Component({
  selector: 'app-fnf-overtime-approval',
  templateUrl: './fnf-overtime-approval.component.html',
  styleUrls: ['./fnf-overtime-approval.component.scss']
})
export class FnfOvertimeApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() overTime:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  showTrend = false;
  pageSize = 20;
  page = 1
  viewDetails = false;
  viewData:any = [];
  today =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  btndisable = false
  delArray : any = []
  timeOffTrend = false;

  barchart:any               = [];
  barChartXAxisLabels:any    = [];
  barChartYAxisLabel:any;
  barchartLoader             = false;
  multi:any                 = [];


  colorScheme :any = {
    domain: [ '#0078CE']
  };

  // For year dropdown in on duty request trend
  currentYear =  new Date().getFullYear();
  selectedYearDisp = String(this.currentYear)
  selectedYear = this.currentYear
  yearList: any = [];
 
  editFinalOverTime = false
  

  onSelect(event:any) {
  }

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();


  constructor( public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,public appservice: AppService,private datePipe:DatePipe,private notificationService: NotificationService,private inboxservice: InboxService) { }

    form = this.fb.group({
      read_status: [true,Validators.required],
      approved_status: [false,Validators.required],
      comment: ['', Validators.maxLength(500)],
    })

    form1 = this.fb.group({
      final_overtime_hours : ['',Validators.required],
    })
  
    get f() {return  this.form.controls; }

    get f1() {return  this.form1.controls; }


  ngOnInit(): void {
    
  }

  timegraph(){
    this.yearList = []
    this.yearListCreate();
    this.getOvertimeTrendDetails(this.currentYear);
  }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }

  getOvertimeTrendDetails(event:any){
    this.selectedYearDisp = String(event)
    this.selectedYear = event
    this.barchartLoader   = true;
    if(this.empId != null && this.empId != '' && this.empId != undefined){
      this.inboxservice.getOvertimeTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.multi = res?.data;
          if(this.multi?.length > 0)
           this.barChartDataConstruction(this.multi);
        }
      })
    }
  }

  // Bar chart
  barChartDataConstruction(data:any){

    let hours:any            = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Hours';
    this.barchart            = [];
    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
        hours.push(item1?.value)
      });
    });

    this.barchart.push({label: "Hours", data: hours, backgroundColor: '#0078CE', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }
  // End

  confirm(status:any){

    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }
      this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
        if(res){
          this.refreshInbox(false);
          this.form.get('comment')?.reset('')

        }
      })
    }
  }

  editFinalOverTimeFunction(from:any){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      this.editFinalOverTime = true;
      if(from == 'confirm'){
        this.inboxservice.editFinalOvertimeHours(this.overTime?.id,{"attendance_date": this.appservice.dateFormatConvert(this.overTime?.attendance_date), "employee": this.overTime?.from_person?.id, "extra_hours": moment(this.form1.value.final_overtime_hours).format("HH:mm")} ).subscribe((res:any)=>{
          if(res){
            this.overTime.final_hours = moment(this.form1.value.final_overtime_hours).format("HH:mm");
            this.editFinalOverTime = false;
          }
        })
      }
    }
  }

  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }
  // test start
  refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

// test end


}
