import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'app-financial-calc-view',
  templateUrl: './financial-calc-view.component.html',
  styleUrls: ['./financial-calc-view.component.scss']
})
export class FinancialCalcViewComponent implements OnInit {

  constructor() { }
  @Input() payrollMonthlyCost:any;
  @Input() payroll_month:any;
  @Input() payroll_year:any;
  @Input() currency:any;
  @Input() from:any;
  ngOnInit(): void {
   
  }
  getPreMonth(month:any,year:any){
    const current = new Date(month+' 1'+year);
    current.setMonth(current.getMonth()-1);
    return  current.toLocaleString('default', { month: 'short' });
  }
  getAbs(val:any){
    return Math.abs(val)
  }
  getClass(val:any){
    return (val>0)?"graph-increase text-success":(val==0)?"":"graph-decrease text-danger"
  }
}
