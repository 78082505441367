<div class="row mb-16">
  <div class="col-md-4 col-lg-6">
    <p class="text-uppercase fw-500 mb-0">EXPENSE - {{viewData?.name}}</p>
  </div>
</div>
<div class="row row-16">
  <div class="col-12">
    <div class="card details-card p-16">
      <div class="row row-16">
        <div class="col-12 col-12 align-items-center d-flex">
          <p class="title mb-8">REport Deatails</p>

          <div class="d-flex align-items-center gap-8 ms-auto">
            <span class=" fs-14 ms-auto px-2 fw-500  {{viewData?.status == 'Pending' ? 'badge-warning' : viewData?.status == 'Accepted' ? 'badge-success' : viewData?.status == 'Rejected' ? 'badge-danger' : ''}} badge3">
              {{viewData?.status}}
            </span>
          </div>
        </div>
        <div class=" col-md-3">
          <div class="detail-title">Report Name</div>
          <div class="detail-desc">{{viewData?.name}}</div>
        </div>

        <div class="col-sm-3 col-12">
          <div class="detail-title">Report Date</div>
          <div class="detail-desc"> {{this.appService.dateFormatDisplay(viewData?.from_date)}} - {{this.appService.dateFormatDisplay(viewData?.to_date)}}</div>
        </div>

        <div class="col-sm-3 col-12">
          <div class="detail-title">Total </div>
          <div class="detail-desc">{{viewData?.total_amount | currency : currency}}</div>
        </div>
        <div class="col-sm-3 col-12">
          <div class="detail-title">To be Reimbursed</div>
          <div class="detail-desc">{{viewData?.to_reimbursed | currency : currency}}</div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card details-card p-16">
      <div class="row row-16">
        <div class="col-12">
          <div class="title">EXPENSE RECEIPT</div>
        </div>
        <div class="col-12">
          <div class="card card-c2">
            <div class="table-responsive radius-4">
              <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th class="fw-600 text-uppercase" scope="col">Receipt number</th>
                    <th class="fw-600 text-uppercase" scope="col">EXPENSE Date</th>
                    <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                    <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                    <th class="fw-600 text-uppercase" scope="col">Report name</th>
                    <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                  </tr>
                </thead>
                <tbody class="cell-16 ">

                  <ng-container *ngIf="viewData?.expense_receipts?.length == 0">
                    <tr>
                      <td colspan="6">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container *ngFor='let receipt of $any(viewData?.expense_receipts|filter:searchString1| slice: (page1-1) * pageSize1 : (page1-1) * pageSize1 + pageSize1);let i=index;'>
                    <tr [class]="receipt?.is_expired ? 'td-alert' : ''">
                      <td>
                        <a class="link-primary1" (click)="getSigleReceiptView(receipt?.id)">{{receipt?.number}}</a>

                        <i *ngIf="receipt?.is_expired" class="fs-14 ms-8 icon-info-circle text-danger" ngbPopover="Expired" popoverClass="popover-default popover-info " container="body" triggers="hover"></i>

                      </td>
                      <td><span class="text-nowrap">{{(receipt?.expense_date == null || receipt?.expense_date == '') ? '--' : this.appService.dateFormatDisplay(receipt?.expense_date)}}</span></td>
                      <td>{{(receipt?.category_name == null || receipt?.category_name == '') ? '--' : receipt?.category_name}}</td>
                      <td>{{receipt?.amount | currency : currency}}</td>
                      <td>{{(receipt?.report_name == null || receipt?.report_name == '') ? '--' : receipt?.report_name}}</td>
                      <td *ngIf="(receipt?.attachments == null || receipt?.attachments?.length == 0)">---</td>
                      <td *ngIf="(receipt?.attachments != null && receipt?.attachments?.length > 0)"><span class="link-primary"><i class="icon-eye fs-18 " (click)="viewAttachmentFunction(i,receipt?.id)"></i></span></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-12" *ngIf="this.appService.filteredcount(viewData?.expense_receipts,searchString1)>pageSize1">
          <ngb-pagination class="d-flex justify-content-end"
            [collectionSize]="this.appService.filteredcount(viewData?.expense_receipts,searchString1)" [(page)]="page1" [maxSize]="3"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card details-card p-16">
      <div class="row row-16">
        <div class="col-12">
          <div class="title">ADVANCE DETAILS</div>
        </div>
        <div class="col-12">
          <div class="card card-c2">
            <div class="table-responsive radius-4">
              <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th class="fw-600 text-uppercase" scope="col">Advance number</th>
                    <th class="fw-600 text-uppercase" scope="col">Date</th>
                    <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                    <th class="fw-600 text-uppercase" scope="col">DISPENSED AMOUNT</th>
                  </tr>
                </thead>
                <tbody class="cell-16 ">

                  <ng-container *ngIf="viewData?.expense_advance?.length == 0">
                    <tr>
                      <td colspan="4">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container *ngFor='let advance of $any(viewData?.expense_advance|filter:searchString2| slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2);let j=index;'>
                    <tr>
                      <td><a class="link-primary1" (click)="viewAdvanceFunction(advance?.id)">{{advance?.number}}</a></td>
                      <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(advance?.expense_date)}}</span></td>
                      <td>{{advance?.amount | currency : currency}}</td>
                      <td>{{advance?.dispansed_amount | currency : currency}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="this.appService.filteredcount(viewData?.expense_advance,searchString2)>pageSize2">
    <ngb-pagination class="d-flex justify-content-end"
      [collectionSize]="this.appService.filteredcount(viewData?.expense_advance,searchString2)" [(page)]="page2" [maxSize]="3"
      [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
  </div>
</div>

<!-- Other components -->
<app-view-multiple-attachments *ngIf="viewAttchments" [from]="'expenseReportRequestView'" [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="receiptId" (closeViewAttachment)="closeViewAttachment()" (saveViewAttachment)="saveViewAttachment($event)"></app-view-multiple-attachments>

<app-view-expense-receipt *ngIf="viewReciept" [from]="'expenseReportRequest'" [viewReciept]="viewReciept" [viewRecieptLoader]="viewRecieptLoader" [viewRecieptData]="viewRecieptData" [currency]="currency" (closeView)="viewReciept=false"></app-view-expense-receipt>

<app-emp-advance-details-view *ngIf="viewAdvance" [viewAdvance]="viewAdvance" [viewdata]="viewdata" [advanceid]="advanceid" [viewLoader]="viewLoader" [currency]="currency" [from]="'expenseAdvanceRequestView'"  (panelclose)="closefnview($event)"> </app-emp-advance-details-view>
<!-- End -->
