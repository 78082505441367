import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-verify-part-b',
  templateUrl: './verify-part-b.component.html',
  styleUrls: ['./verify-part-b.component.scss']
})
export class VerifyPartBComponent implements OnInit {
  page              = 1;
  pageSize          = 20;
  items:any         = [];
  errorData:any     = [];
  sucessData:any    = [];
  exportData: any   = [];
  downloadMsg       = false;
  modalToggle       = false;
  errorToggle       = false;
  searchString:any  = "";
  fileName:any;
  currentDocName = 'Form 16 Part B'
  currentExtension = 'pdf'
  loader1: boolean = false

  @Input() form16B_Data   :any;
  @Input() form16B_File   :any;
  @Input() progress       :any;
  @Input() iscompleted    :any;
  @Input() isProgressStart:any;
  @Output() changeTab     = new EventEmitter();
  @Output() generateForm  = new EventEmitter();

  constructor(public messageService : MessageService,public appservice:AppService) {

  }
  ngOnInit(): void {
    let data = this.form16B_Data.data;
    for(let i=0;i< data.length;i++){


          data[i]['name']  = "";
          if(data[i]['first_name'] != null && data[i]['first_name'] != undefined && data[i]['first_name'] != "")
          data[i]['name'] += data[i]['first_name']+" ";
          if(data[i]['middle_name'] != null && data[i]['middle_name'] !=undefined && data[i]['middle_name'] != "")
          data[i]['name'] += data[i]['middle_name']+" ";
          if(data[i]['last_name'] != null && data[i]['last_name'] != undefined && data[i]['last_name'] != "")
          data[i]['name'] += data[i]['last_name'];
          this.sucessData.push(data[i])

    }
    for (let j=0;j<this.form16B_Data.error.length;j++){
      this.errorData.push(this.form16B_Data.error[j])

    }
    for (let i = 0; i < this.errorData.length; i++) {

      this.exportData.push({'Employee name': this.errorData[i].first_name!=''?this.errorData[i].first_name+(this.errorData[i].middle_name!=null?this.errorData[i].middle_name:" ")+ " "+this.errorData[i].last_name:'', 'Employee code':this.errorData[i].employee_code,
      'Error description': this.errorData[i].document_error});
    }

  }
  viewDocument(filename:any){

    this.modalToggle      = true;
    this.fileName         = filename;

  }
  // downloadDoc(filename:any,docType:any){
  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(filename,docType+'.pdf');
  // }
  // downloadAll(url:any){
  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(url,'PartB.zip');
  // }
  goNext(){

    this.generateForm.emit();

  }
  export() {
    this.appservice.exportExcel(this.exportData, 'PartB Error');

  }

  

  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }
}
