import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AppService } from 'src/app/app.global';


@Injectable({
  providedIn: 'root'
})
export class SalaryProcessService {
  processPercentage: EventEmitter<any> = new EventEmitter();
  userdata:any = [];
  constructor(private http: HttpClient,public router: Router,private websocket: WebSocket,private authService: AuthService,public appservice:AppService) { }

  API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public requestSocket(){

    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}progressbar/${this.appservice.getSubDomain()}_live_update_${this.authService.isUserId}/`);
    this.websocket.onopen = (event:any) => {
        // console.log('Open progressbar: ', event);
      }
    this.websocket.onmessage = (event:any) => {
        // console.log('progressbar Message: ', event);
      let data = JSON.parse(event.data);
      // console.log(data)
      this.processPercentage.emit(data);
    }
    this.websocket.onclose = (event:any) => {
        // console.log('close progressbar: ', event);
      }
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
            if(event.url == '/login'){
             this.closeSocket();
            }
        }
      });
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
    }
  }
  getProcessData() {
    return this.http.get(`${this.API_PATH}hr/api/v1/pdflive-get/?condition=PAYROLL`, this.httpOption)
  }

}
