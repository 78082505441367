<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" routerLink="/shift"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step"
          [class]="{'success':myForm['controls'].shiftdetails.valid || myForm['controls'].shiftdetails.disabled}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Shift Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-shift-details [disabled]="disabled" [editflag]="editflag" [formGp]="shiftdetailsForm"
            (submitmethod)="submitMethod(2)"></app-shift-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step"
          [class]="{'success':myForm['controls'].shifttiming.valid || myForm['controls'].shifttiming.disabled && (isEditForm || tab2touched)}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Shift Timing</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-shift-timing [disabled]="disabled" [editflag]="editflag" [formGp]="shifttimingForm"
            (submitmethod)="submitMethodtime($event)" (submitmethod1)="submitMethod1($event)"></app-shift-timing>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="step"
          [class]="{'success':(myForm['controls'].shiftbreak.valid || myForm['controls'].shiftbreak.disabled) && (tab3touched || isEditForm) }">
          <div class="count">3</div>
          <div class="content">
            <span>Step 3</span>
            <h6>Break schedule</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-shift-break-schedule [disabled]="disabled" [editflag]="editflag" [shift]="shift"
            [shifttimingForm]="shifttimingForm" [formGp]="shiftbreakForm" (submitmethod)="submitMethod(4)"
            (breakexceeded)="breakexceeded($event)"></app-shift-break-schedule>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink class="step"
          [class]="{'success':(myForm['controls'].weekoff.valid || myForm['controls'].weekoff.disabled) && tab4touched }">
          <div class="count">4</div>
          <div class="content">
            <span>Step 4</span>
            <h6>Week off</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-shift-week-off [disabled]="disabled" [editflag]="editflag" [formGp]="weekoffForm"
            (submitmethod)="submitForm()"></app-shift-week-off>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
    <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
  </main>
</div>
