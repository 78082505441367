import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeparationPolicyService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }

  getseparationtypeList(){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/seperation-policy-list/?is_active=1&ordering=separation_name`, this.httpOption);
  }
  createSeparationPolicy(data:any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/seperation-policy-create/`, data, this.httpOption)
  }
  getSeparationPolicyData(status:any,limit:any,offset:any,search:any,order:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/seperation-policy-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`, this.httpOption);
  }
  getSearchList(status:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/seperation-policy-list/?is_active=${status}&ordering=name`, this.httpOption);

  }
  getSingleSeparationPolicyData(id:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/seperation-policy-detail/${id}`, this.httpOption);
  }
  getviewSeparationPolicyData(id:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/seperation-policy-view/${id}`, this.httpOption);
  }
  deleteSeparationpolicy(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}general_settings/api/v1/seperation-policy-delete/${id}/`,data, this.httpOption)
  }
  updateSeparationPolicy(id:number,data:any){
    return this.http.put(`${this.API_PATH}general_settings/api/v1/seperation-policy-detail/${id}/`,data, this.httpOption)
  }
  createreason(data:any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/reason-list/`, data, this.httpOption)
  }
  updatereason(id:number,data:any){
    return this.http.put(`${this.API_PATH}general_settings/api/v1/reason-detail/${id}/`,data, this.httpOption)
  }
  deletereason(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}general_settings/api/v1/reason-detail/${id}/`,data, this.httpOption)
  }
  getviewreason(id:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/reason-detail/${id}`, this.httpOption);
  }
  getreasonData(status:any,limit:any,offset:any,search:any,order:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/reason-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`, this.httpOption);
  }
  getSearchreasonList(status:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/reason-list/?is_active=${status}&ordering=name`, this.httpOption);
  }
}
