<div class="container-fluid p-24">
  <div class="row  mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Audit log</h3>
      <p class="text-helper mb-md-0">The audit log provides you with a chronological of actions performed by the
        user in Skizzle HR</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon icon-left" style="width:20rem;">
          <input class="form-control fs-14 ps-40" type="text" ngxDaterangepickerMd [closeOnAutoApply]="true"
            [showCustomRangeLabel]="true" opens="left" [alwaysShowCalendars]="false" [linkedCalendars]="true"
            [locale]="{applyLabel: 'ok'}" [showClearButton]="true" placeholder="Select date">
          <i class="icon-calendar pointer-event fs-14"></i>
        </div>
        <button class="btn-square btn btn-secondary" placement="bottom-left">
          <i class="icon-plus fs-13"></i>
        </button>
        <button class="btn-square btn btn-secondary" placement="bottom-left" (click)="filterpanel=true">
          <i class="icon-filter-left fs-13"></i>
        </button>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add">Export</button>
      </div>
    </div>
  </div>
  <div class="row row-16">
    <div class="col-12">
      <div class="card card-c2 p-0">
        <div class="table-responsive  scrollbar-10 radius-4">
          <table class="table sticky-header table-striped table-sm td-white-space td-width-15ch sticky-last-col
                  ">
            <thead>
              <tr>
                <th class="fw-600" scope="col">date</th>
                <th class="fw-600" scope="col">module</th>
                <th class="fw-600" scope="col">activity</th>
                <th class="fw-600" scope="col">user</th>
                <th class="fw-600" scope="col">Employee code</th>
                <th class="fw-600" scope="col">time</th>
                <th class="fw-600" scope="col" style="width:4.375rem;">view</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
                <td>19-Nov-2022</td>
                <td>Payroll</td>
                <td ngbPopover="Delete any information in employee PF detail and Delete any information in employee
                                PF detail and Delete
                                any information in employee PF detail and Delete any information in employee PF
                                detail and" popoverClass="popover-input popover-info" container="body"
                  triggers="hover">Delete any information in employee PF detail and Delete any
                  information in employee
                  PF detail and Delete
                  any information in employee PF detail and Delete any information in employee PF
                  detail and</td>
                <td>Aneesh M </td>
                <td>EMP40003</td>
                <td>10:31 AM</td>
                <td class="text-center">
                  <i class="bi bi-eye link-primary lh-1 fs-20" (click)="detailsView=true"></i>
                </td>
              </tr>
              <tr>
                <td>19-Nov-2022</td>
                <td>Payroll</td>
                <td ngbPopover="Delete any information in employee PF detail and Delete any information in employee
                                PF detail and Delete
                                any information in employee PF detail and Delete any information in employee PF
                                detail and" popoverClass="popover-input popover-info" container="body"
                  triggers="hover">Delete any information in employee PF detail and Delete any
                  information in employee
                  PF detail and Delete
                  any information in employee PF detail and Delete any information in employee PF
                  detail and</td>
                <td>Aneesh M </td>
                <td>EMP40003</td>
                <td>10:31 AM</td>
                <td class="text-center">
                  <i class="bi bi-eye link-primary lh-1 fs-20" (click)="detailsView=true"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- filter -->
<div class="side-panel " style="--sidepanel-width:27rem;" [class.side-pane-active]='filterpanel === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="filterpanel = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 ">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">COMPANY</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="Select Company" class="form-ngselect"></ng-select>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">BUSINESS UNIT </p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="Select Business unit" class="form-ngselect"></ng-select>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">DEPARTMENT</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="Select Department" class="form-ngselect"></ng-select>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">BRANCH</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="Select Branch" class="form-ngselect"></ng-select>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Module</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="Select Module" class="form-ngselect"></ng-select>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">USER</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="Select User" class="form-ngselect"></ng-select>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm">
        Reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm">
        Apply
      </button>
    </footer>
  </div>
</div>
<!-- filter -->
<div class="side-panel " style="--sidepanel-width:45rem;" [class.side-pane-active]='detailsView === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="detailsView = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 ">
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0">Payroll processed</p>
          </div>
          <div class="col-12 ">
            <div class="card card-c2 p-24">
              <div class="card card-c2">
                <div class="table-responsive  scrollbar-10 radius-4">
                  <table class="table sticky-header table-striped table-sm ">
                    <thead>
                      <tr>
                        <th class="fw-600" scope="col">SL.No</th>
                        <th class="fw-600" scope="col">Employee name</th>
                        <th class="fw-600" scope="col">Employee code</th>
                        <th class="fw-600" scope="col">Payroll processed on</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]">
                        <td>{{i}}</td>
                        <td>Ajay</td>
                        <td>EMP01</td>
                        <td>04-Nov-2022 02:32 PM</td>

                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>