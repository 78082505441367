import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetPolicyService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  assetCategoryList(status:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}asset/api/v1/category-landing/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  assetCategortCreateAndUpdate(id:any,body: any){
    if(id == 0)
      return this.http.post(`${this.API_PATH}asset/api/v1/category-landing/`, body, this.httpOption);
    else
      return this.http.put(`${this.API_PATH}asset/api/v1/category-detail/${id}/`, body, this.httpOption)
  }

  getSingleAssetCategory(id: number) {
    return this.http.get(`${this.API_PATH}asset/api/v1/category-detail/${id}/`, this.httpOption)
  }

  assetCategoryDropDown(status:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/category-list/?is_active=${status}&ordering=name`, this.httpOption)
  }

  deleteAssetCategory(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}asset/api/v1/category-detail/${id}/`, body, this.httpOption)
  }


  // Asset Subcategory
  assetSubCategoryList(status:any,limit:any,offset:any,search:any,filterdata:any) {
    return this.http.get(`${this.API_PATH}asset/api/v1/sub-category-landing/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&${filterdata}`, this.httpOption)
  }

  assetSubCategortCreateAndUpdate(id:any,body: any){
    if(id == 0)
      return this.http.post(`${this.API_PATH}asset/api/v1/sub-category-landing/`, body, this.httpOption);
    else
      return this.http.put(`${this.API_PATH}asset/api/v1/sub-category-detail/${id}/`, body, this.httpOption)
  }

  getSingleSubAssetCategory(id: number) {
    return this.http.get(`${this.API_PATH}asset/api/v1/sub-category-detail/${id}/`, this.httpOption)
  }

  assetSubCategoryDropDown(status:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/sub-category-list/?is_active=${status}&ordering=name`, this.httpOption)
  }

  deleteSubAssetCategory(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}asset/api/v1/sub-category-detail/${id}/`, body, this.httpOption)
  }

  assetCategoryFilterDropDown(status:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/category-list/?ordering=name&is_active=${status}`, this.httpOption)
  }

  assetSubCategoryFilterDropDown(){
    return this.http.get(`${this.API_PATH}asset/api/v1/sub-category-list/?ordering=name`, this.httpOption)
  }
  // End
}
