import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-short-leave-summary',
  templateUrl: './short-leave-summary.component.html',
  styleUrls: ['./short-leave-summary.component.scss']
})
export class ShortLeaveSummaryComponent implements OnInit {

  // Var Initialization
  saveClicked             : boolean = false
  modalToggle                       = false;
  currentDocName                    = "";
  currentExtension                  = "";
  allowedExtension                  = "";
  fileName                : any;
  investalertMsg          :any      = '';
  alertToggle                       = false;

  // Inputs
  @Input() validationData : any;
  @Input() totalLeaveDays : any;
  @Input() validationPortionData: any;
  @Input() formGp         : any;
  @Input() totalDuration  : any;

  // Output Emitters
  @Output() submitMethod          = new EventEmitter();

  constructor(
    public appServ        : AppService
  ) { }

  ngOnInit(): void {
  }

  // Submit
  validateLeaveSummary(){
    this.saveClicked  = true;
    this.submitMethod.emit();
  }

  // View Doc
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appServ.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool:any){
   this.alertToggle = bool
  }

  // Download File
  downLoad(val:any){
    let url = this.appServ.getDocName(val).split('.')[0]
    this.appServ.downloadFile(val,url)
  }
}
