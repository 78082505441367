import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function websiteValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const fieldPattern = /^(http:\/\/|https:\/\/)[a-zA-Z0-9_\-]+\.[a-zA-Z0-9_\-]+\.[a-zA-Z0-9_\-]+\.[a-zA-Z0-9_\-]+$/.test(value);     
      const fieldPattern1 = /^(http:\/\/|https:\/\/)[a-zA-Z0-9_\-]+\.[a-zA-Z0-9_\-]+\.[a-zA-Z0-9_\-]+$/.test(value);      
      // eg :  http://www.google.com // http://chinese.freecodecamp.org.ha 
      return (!fieldPattern && !fieldPattern1) ? {pattern:true}: null;
  }
}

