import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataacessService } from 'src/app/@core/services/dataaccess.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-da-rights',
  templateUrl: './da-rights.component.html',
  styleUrls: ['./da-rights.component.scss']
})
export class DaRightsComponent implements OnInit {
  showSidePanel               = false;
  employeelistarray:any       = [];
  empassignlist:any           = [];
  formdata:any                = [];
  assign_rights:any           = [];
  valuesdata:any              = [];
  saveClicked                 = false;
  loader1                     = true;
  @Input()dataacceessid:any;
  @Input()companylist:any     = [];
  @Input()departmentlist:any  = [];
  @Input()citylist:any        = [];
  @Input()branchlist:any      = [];
  @Input()bulist:any          = [];
  @Input()gradelist:any       = [];
  @Input()designationlist:any = [];
  @Input()editdata            = [];
  @Input()formGp : FormGroup  = this.fb.group({
  });
  @Input()formGpEmloyee : FormGroup = this.fb.group({
  });
  @Input()buArray:any = [{
    id: 0,
    name: ''
  }];

  @Output() submitmethod  = new EventEmitter();
  @Output() changemethod  = new EventEmitter();
  @Output() companyChange = new EventEmitter();

  //common rights to all selected employees form
  myAllForm : FormGroup = this.fb.group({
    companys: [''],
    departments: [''],
    businessunits: [''],
    grades: [''],
    branchs:[''],
    designations: [''],
    citys: ['']
  });
  constructor(private ds:DataacessService,public fb:FormBuilder,public messageService : MessageService,public appService:AppService) { }

  ngOnInit(): void {
    this.saveClicked = false
    this.employeelistarray = [];
    this.empassignlist =this.formGpEmloyee.value['employee'];
    if(this.formGp.value.assignrights.length == 0){
      for(let j = 0;j<this.empassignlist.length;j++){
        this.addAssignRights(this.empassignlist[j]);
      }
    }
    for(let k=this.formGp.value.assignrights.length-1;k>=0;k--){
      if(this.empassignlist.indexOf(this.formGp.value.assignrights[k]['employee']) == -1){
        this.assignright().removeAt(k);
      }
    }

    for(let j = 0;j<this.empassignlist.length;j++){
      this.ds.getEmpList(true).subscribe((res: any) => {
        for(let i=0;i<res.body.length;i++){
          if(res.body[i]['id'] == this.empassignlist[j]){
            this.employeelistarray.push(res.body[i])
          }
        }
        let len = this.employeelistarray.length;
        for (let i = 0; i < len; i++) {
            this.employeelistarray[i]['fullname']  = "";
            if(this.employeelistarray[i]['first_name'] != null)
             this.employeelistarray[i]['fullname'] += this.employeelistarray[i]['first_name']+" ";
            if(this.employeelistarray[i]['middle_name'] != null)
             this.employeelistarray[i]['fullname'] += this.employeelistarray[i]['middle_name']+" ";
            if(this.employeelistarray[i]['last_name'] != null)
             this.employeelistarray[i]['fullname'] += this.employeelistarray[i]['last_name'];

          }

          if(j == (this.empassignlist.length - 1))
           this.loader1 = false;


      });
    }



    for(let j = 0;j<this.empassignlist.length;j++){
      let flag = 0;
      for(let i=0;i<this.formGp.value.assignrights.length;i++){
        if(this.formGp.value.assignrights[i]['employee'] == this.empassignlist[j]){
          flag++;
        }
      }
      if(flag == 0){
        this.addAssignRights(this.empassignlist[j]);
      }
    }
    if (this.companylist.length != 0) {
      this.selectAllForDropdownItems(this.companylist);
    }
    if (this.departmentlist.length != 0) {
      this.selectAllForDropdownItems(this.departmentlist);
    }
    if (this.branchlist.length != 0) {
      this.selectAllForDropdownItems(this.branchlist);
    }
    if (this.designationlist.length != 0) {
      this.selectAllForDropdownItems(this.designationlist);
    }
    if (this.gradelist.length != 0) {
      this.selectAllForDropdownItems(this.gradelist);
    }

  }

  assignright() : FormArray {
    return this.formGp.get("assignrights") as FormArray
  }

  addAssignRights(list:any){
      this.assignright().push(this.newAssignRight(list));
  }
  // remove assign rights div of each employee
  removeAssignRight(i:number) {

    this.employeelistarray.splice(i, 1);
    this.empassignlist.splice(i, 1);

    this.formGpEmloyee.reset(
      {
        name:this.formGpEmloyee.value.name,
        module:this.formGpEmloyee.value.module,
        employee:this.empassignlist
    });
    this.assignright().removeAt(i);
    for(let i=0;i<this.assignright().length;i++){
      this.companyChangeFunction(this.formGp.value.assignrights[i]?.company,i)
    }
  }

  newAssignRight(emploeedetail:any): FormGroup {
    return this.fb.group({
      employee:emploeedetail,
      company: [''],
      businessunit: [''],
      department: [''],
      designation: [''],
      grade: [''],
      city: [''],
      branch: ['']
    })
  }

  validateAssignRightFlow(){
    if (this.formGp.invalid) {
      return;
    }
    this.saveClicked = true
    this.submitmethod.emit();
  }



  // assignrights value of common form setting in each employee assign rights form field
  validateAllAssignRightFlow(){
    this.showSidePanel = false;
    const myForm = (<FormArray>this.formGp.get("assignrights"));

    for(let j=0;j<this.empassignlist.length;j++){
      for(let k = 0; k < myForm.length; k++ ){
        if(myForm.value[k].employee == this.empassignlist[j]){
          const listForm = (<FormArray>this.formGp.get("assignrights")).at(k);
          var allcompanyarray:any = [];
          this.myAllForm.value.companys.forEach((element: any) => {
            allcompanyarray.push(element);
          });
          var allbusinessunitarray:any = [];
          if(this.myAllForm.value.businessunits != ""){
            this.myAllForm.value.businessunits.forEach((element: any) => {
              allbusinessunitarray.push(element);
            });
          }

          var alldepartmentarray:any = [];
          if(this.myAllForm.value.departments != ""){
            this.myAllForm.value.departments.forEach((element: any) => {
              alldepartmentarray.push(element);
            });
          }
          var alldesignationarray:any = [];
          if(this.myAllForm.value.designations != ""){
            this.myAllForm.value.designations.forEach((element: any) => {
              alldesignationarray.push(element);
            });
          }
          var allgradearray:any = [];
          if(this.myAllForm.value.grades != ""){
            this.myAllForm.value.grades.forEach((element: any) => {
              allgradearray.push(element);
            });
          }
          var allcityarray:any = [];
          if(this.myAllForm.value.citys != ""){
            this.myAllForm.value.citys.forEach((element: any) => {
              allcityarray.push(element);
            });
          }
          var allbrancharray:any = [];
          if(this.myAllForm.value.branchs != ""){
            this.myAllForm.value.branchs.forEach((element: any) => {
              allbrancharray.push(element);
            });
          }
          listForm.patchValue({
            'employee':this.empassignlist[j],
            'company': allcompanyarray,
            'department':alldepartmentarray,
            // 'businessunit':allbusinessunitarray,
            'grade':allgradearray,
            'branch':allbrancharray,
            'designation':alldesignationarray,
            'city': allcityarray
          });
        }
      }
    }
    this.updateBuGp(this.myAllForm.value.companys,this.myAllForm.value.businessunits);

  }

  updateBuGp(comp:any,bu:any){
    const myForm = (<FormArray>this.formGp.get("assignrights"));
      for(let k = 0; k < myForm?.length; k++ ){
        this.companyChange.emit({compData : comp, index: k});
        const listForm = myForm.at(k);
        listForm.patchValue({
          businessunit: bu,
        });
      }
  }

  // removing the items from the selected array
  clear(item:any,index:number,i:number){
    if(item == "company"){
      let daata = this.formGp.value.assignrights[i].company;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("assignrights")).at(i);
      myForm.patchValue({
        company: daata,
        businessunit: ''
      });
      this.companyChangeFunction(this.formGp.value.assignrights[i]?.company,i);
    } else if(item == "businessunit"){
      let daata = this.formGp.value.assignrights[i].businessunit;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("assignrights")).at(i);
      myForm.patchValue({
        businessunit: daata
      });
    } else if(item == "department"){
      let daata = this.formGp.value.assignrights[i].department;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("assignrights")).at(i);
      myForm.patchValue({
        department: daata
      });
    } else if(item == "city"){
      let daata = this.formGp.value.assignrights[i].city;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("assignrights")).at(i);
      myForm.patchValue({
        city: daata
      });
    } else if(item == "designation"){
      let daata = this.formGp.value.assignrights[i].designation;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("assignrights")).at(i);
      myForm.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.formGp.value.assignrights[i].grade;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("assignrights")).at(i);
      myForm.patchValue({
        grade: daata
      });
    }
    else if(item == "branch"){
      let daata = this.formGp.value.assignrights[i].branch;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("assignrights")).at(i);
      myForm.patchValue({
        branch: daata
      });
    }
    else if(item == "companys"){
      let daata = this.myAllForm.value.companys;
      daata.splice(index, 1);
      this.myAllForm.patchValue({
        companys: daata,
        businessunits: []
      });
      this.companyChangeFunction(this.myAllForm.value.companys,'commonForm');
    } else if(item == "businessunits"){
      let daata = this.myAllForm.value.businessunits;
      daata.splice(index, 1);
      this.myAllForm.patchValue({
        businessunits: daata
      });
    } else if(item == "departments"){
      let daata = this.myAllForm.value.departments;
      daata.splice(index, 1);
      this.myAllForm.patchValue({
        departments: daata
      });
    } else if(item == "citys"){
      let daata = this.myAllForm.value.citys;
      daata.splice(index, 1);
      this.myAllForm.patchValue({
        citys: daata
      });
    } else if(item == "designations"){
      let daata = this.myAllForm.value.designations;
      daata.splice(index, 1);
      this.myAllForm.patchValue({
        designations: daata
      });
    } else if(item == "grades"){
      let daata = this.myAllForm.value.grades;
      daata.splice(index, 1);
      this.myAllForm.patchValue({
        grades: daata
      });
    }
    else if(item == "branchs"){
      let daata = this.myAllForm.value.branchs;
      daata.splice(index, 1);
      this.myAllForm.patchValue({
        branchs: daata
      });
    }
  }

  // form validation when apply assign rights to each selected employee div
  checkformvalid() {
    var retResult = false;
    const myForm = (<FormArray>this.formGp.get("assignrights"));
    let listForm:any;
    if(myForm.length == 0){
      retResult = true
    }
    for(let k = 0; k < myForm.length; k++ ){
      listForm = myForm.at(k);
      if(listForm.value.company.length == 0 &&
         listForm.value.businessunit.length == 0 &&
         listForm.value.department.length == 0 &&
         listForm.value.designation.length == 0 &&
         listForm.value.grade.length == 0 &&
         listForm.value.city.length == 0 &&
         listForm.value.branch.length == 0) {
        retResult = true;
      }
    }
    return retResult;
  }

  //common form validation when apply assign rights to every selected employee
  checkcommonFormValid(){
    if(this.myAllForm.value.companys.length != 0 ||
       this.myAllForm.value.businessunits.length != 0 ||
        this.myAllForm.value.departments.length != 0 ||
         this.myAllForm.value.designations.length != 0 ||
          this.myAllForm.value.grades.length != 0 ||
           this.myAllForm.value.citys.length != 0 ||
            this.myAllForm.value.branchs.length != 0 ){
      return false;
    } else{
      return true;
    }

  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  companyChangeFunction(data:any,index:any){
    this.companyChange.emit({compData : data, index: index});
    if(this.formGp.value?.assignrights[index]?.company?.length == 0){
      const myForm = (<FormArray>this.formGp.get("assignrights"))?.at(index);
      myForm.patchValue({
        businessunit: []
      });
    }
  }
}
