import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EsichellangenerateService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }


  generateesiChallan(data:any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/esi-challan-generate/`, data, this.httpOption)
  }
  getesichellanData(month:any,year:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/esi-challans/?month=${month}&year=${year}`, this.httpOption);    
  }
  getSingleesichellanData(id:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/esi-challan-regenerate/${id}`, this.httpOption);
  } 
  regenerateesiChallan(data:any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/esi-challan-regenerate/`, data, this.httpOption)
  }
  getesichellangeneratedData(month:any,year:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/esi-challan-generated-list/?month=${month}&year=${year}`, this.httpOption);    
  }
  getregeneratechellanData(id:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/esi-challan-regenerate-view-detail/${id}`, this.httpOption);
  } 
}