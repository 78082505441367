<form class="">
  <app-table-loader *ngIf="loader"></app-table-loader>
    <div class="container-fluid p-32 form-container" *ngIf="!loader">
        <div class="row ">
            <p class="text-uppercase fw-500 mb-16">Select date
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Click on the dates for which you want to apply regularization."
                    popoverClass="popover-input popover-info" placement="bottom"
                    triggers="hover"></i></span>
            </p>

        </div>
        <div class="row row-16">
            <div class=" col-12 ">
                <div class="fs-14 fw-500 pb-16">Days selected : <span>{{dayCount}}</span></div>
                <!-- For OT validation -->
                <div role="alert" class="alert alert-warning show" *ngIf="alertToggle">
                  <p class="mb-0 fs-12
                  ">{{alertMsg}}</p>
                  <div class="btn-close pointer" (click)="alertToggle = false"></div>
                </div>
                <div class="card card-c2 " >
                    <div class="card-body p-16 ">
                        <full-calendar #calendar class="fullcalendar-reset fullcalendar-c1 fullcalendar-fulloption" [options]="calendarOptions"></full-calendar>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" *ngIf="disabled == true" [disabled]="formGrp.value==0 || alertToggle" (click)="submitResignationDetails()">Next</button>
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" *ngIf="disabled == false" (click)="submitResignationDetails()">Next</button>
    </footer>
</form>

<!-- Alert -->
<app-alert-toggle (modals)="closed($event)" [alertToggle]="alertToggleModal" [alertMsg]="alertMsgModal"></app-alert-toggle>

