<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Location group</p>
        </div>
        <div class="row row-16">
            <div class="col-12">
                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                    <div class="row row-16 ">
                        <div class="col-12 ">
                            <p class="title mb-8">LOCATION GROUP </p>
                        </div>
                        <div class="col-12">
                            <div class="detail-title">Location group name</div>
                            <div class="detail-desc">{{summerydata[0]?.name}}</div>
                        </div>
                        <div class="col-12 ">
                            <div class="detail-title">States</div>
                            <div class="detail-desc">
                                <div class="d-flex gap-8 flex-wrap mt-8 ">
                                    <ng-container *ngFor="let states of summerydata[0]?.statearray;let i=index">
                                        <span class="badge3 py-2">{{states.statename}}</span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 ">
                            <div class="detail-title">Cities</div>
                            <div class="detail-desc">
                                <div class="d-flex gap-8 flex-wrap mt-8 ">
                                    <ng-container *ngFor="let city of summerydata[0]?.cityarray;let i=index">
                                        <span class="badge3 py-2">{{city.cityname}}</span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveBtnClicked" (click)="validatefn()">Save</button>
    </footer>
</form>