import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  expandClass = '';
  public location = '';
  
  checkSidebarExpanded(selectedIndex: any) {
    if (selectedIndex != -1) {
      this.expandClass = 'expand';
    }
    else {
      this.expandClass = '';
    }
  }
  constructor(){}
  // constructor(private _router: Router) {
    // this.location = (_router.url).split('/')[1];
    // console.log(this.location);
  // }
  ngOnInit(): void {
  }
}
