import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceReportsService {
  private offset: number = 0;
  private limit: number = 20;
  header_filter : any = [];
  data_filter   : any = {};

  constructor(private http: HttpClient,
    public router: Router) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if(  !event.url.includes('attendance-report-configure')){
            this.setDataFilter({})
            this.setHeaderFilter([])
          }
        }
      })
     }

    API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

  attendanceReportTypeList(type:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?report_type=${type}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
    }

  getSingleAttendanceReport(id: number) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?id=${id}`, this.httpOption)
    }

  attendanceReportDropdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list-dropdown/?ordering=name&report_type=Attendance`, this.httpOption)
  }

  reportAPI(url:any,limit:any,offset:any,ordering:any,formData:any){
    if(formData == ''){
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  punchTimeReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/punch-time-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/punch-time-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  attendanceRegularizeReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/attendance-regularization-request-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
    return this.http.get(`${this.API_PATH}hr/api/v1/attendance-regularization-request-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  arrearDaysReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/arrear-days-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/arrear-days-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  lateEarlyReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/late-and-early-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/late-and-early-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  dailyAttendanceReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/daily-attendance-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/daily-attendance-report/?filter_applied=form&${formData}`, this.httpOption)
    }
  }

  absentDetailsReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/absent-details-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/absent-details-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  monthlyWorkingHourReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-working-hours-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-working-hours-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  overTimerequestReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/overtime-request-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/overtime-request-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  restrictedHolidayRequestReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/restricted-holiday-request-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
     return this.http.get(`${this.API_PATH}hr/api/v1/restricted-holiday-request-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  onDutyRequestReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
    return this.http.get(`${this.API_PATH}hr/api/v1/onduty-request-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/onduty-request-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  monthlyOvertimeReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-overtime-detail-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-overtime-detail-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  compOffReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/compensatory-off-request-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/compensatory-off-request-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  monthlyShiftRoasterReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-shift-roaster-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-shift-roaster-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  attendanceRegisterReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/attendance-register-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/attendance-register-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  monthlyPaidReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-paid-day-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/monthly-paid-day-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  musterRollFormReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/muster-roll-form-25-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/muster-roll-form-25-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  workFromHomeReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/work_from_home_report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/work_from_home_report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  checkInrequestReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/check_in_request_report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/check_in_request_report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  getBuList(compid:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name&company_id=${compid}`, this.httpOption);
  }

  getBranchList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getCityList(stateid: number){
    return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?is_active=true&ordering=name&state=${stateid}`, this.httpOption);
  }
  getempList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name`,this.httpOption)
  }
  getCountryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/country-list/`, this.httpOption);
  }
  getStateList(countryid: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/region-list/?country=${countryid}`, this.httpOption);
  }

  filterMemorize(body: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/report-filter-save/`, body, this.httpOption)
  }

  yearListDropdown() {
    return this.http.get(`${this.API_PATH}hr/api/v1/get-organization-created-year/`, this.httpOption)
  }

   // Remote Location
   remoteLocation(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/remote-punch-location-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/remote-punch-location-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }

  // Location
  liveLocation(limit:any,offset:any,ordering:any,formData:any,filter:any){
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/location-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/location-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }


  calculateOffset(pageNumber: number): number {
    this.offset = pageNumber * this.limit;
    return this.offset;
  }

  setDataFilter(val:any){
    this.data_filter = val
  }

  setHeaderFilter(val:any){
    this.header_filter = val
  }


}
