import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-am-allocate-flow',
  templateUrl: './am-allocate-flow.component.html',
  styleUrls: ['./am-allocate-flow.component.scss']
})
export class AmAllocateFlowComponent  {
  @Input()formGp : FormGroup = this.fb.group({
    module: [null ,[Validators.required]],
    workflow: [null, [Validators.required]],
    is_cancel :[null]
  });
  @Input() moduleDropdown:any=[];
  @Input()submitted:boolean=false;
  @Input()disabled = false;

  @Output() submitmethod = new EventEmitter();
  @Output() changeModule = new EventEmitter();

  @Input() workflowDropdown:any = [];
  constructor(public fb:FormBuilder,public messageService : MessageService,) { }

  ngOnInit(): void {

  }
  validateAllocateFlow() {
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }
  getWorkflowList(){
    if(this.formGp?.value.module == null){
      this.formGp?.get('module')?.markAsTouched()
    }else{
      this.changeModule.emit();
    }
    this.formGp.patchValue({
      workflow: []
    });
  }

  get f() { return this.formGp.controls; }
  clear(index:number){
    let daata = this.formGp.value.workflow;
    daata.splice(index, 1);
    this.formGp.patchValue({
      workflow: daata
    });
  }
}
