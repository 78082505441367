import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function AadharnumValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const Aadharnumpattern = /^[0-9]{12}$/.test(value);

      return !Aadharnumpattern ? {pattern:true}: null;
  }
}

