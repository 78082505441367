<form class="side-panel-container" [formGroup]="leaveConfigForm" autocomplete="off">
    <ng-container formArrayName="leaveEligibilty" *ngFor="let config of leaveEligibiltyForm.controls; let i = index">
        <ng-container [formGroupName]="i">
<ng-container *ngIf="leaveTypeCode==leaveEligibiltyForm.controls[i].get('leave_code')?.value">
<div class="container-fluid  py-24 px-16">
    <div class="row ">
        <p class="text-uppercase fw-500">ELIGIBILITY</p>
    </div>
    <div class="row row-16">

        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Is this leave applicable to only specific employment type?</label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If yes is selected, you can choose employment type for which leave is applicable." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                  formControlName="leave_applicable_emp_type" [value]=true (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                formControlName="leave_applicable_emp_type" [value]=false (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>


        <ng-container *ngIf="leaveEligibiltyForm.controls[i].get('leave_applicable_emp_type')?.value">
          <div class="col-12">
              <label for="name" class="form-label required">Employment Type </label>
              
              <ng-select
              [ngClass]="{ 'is-invalid': (submitted || leaveEligibiltyForm.controls[i].get('employment_type')?.touched) &&  leaveEligibiltyForm.controls[i].get('employment_type')?.errors}"
              placeholder="{{this.messageService.selectplaceholddisplay('employment type')}}"
              formControlName="employment_type" [items]="empstatusDD" [multiple]="true"
              groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
              bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                      [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex align-items-center">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                      {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                  </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngIf="leaveEligibiltyForm.controls[i].get('employment_type')?.value.length ">
                      <span class="ng-value-label">{{leaveEligibiltyForm.controls[i].get('employment_type')?.value.length }}
                        employment type
                          selected.</span>
                  </div>
              </ng-template>
          </ng-select>
          <div *ngIf="(leaveEligibiltyForm.controls[i].get('employment_type')?.touched) && leaveEligibiltyForm.controls[i].get('employment_type')?.errors" class="invalid-feedback"><div *ngIf="leaveEligibiltyForm.controls[i].get('employment_type')?.errors?.required">{{this.messageService.validationDisplay('required')}}
          </div></div>
          <ng-container *ngIf="leaveEligibiltyForm.controls[i].get('employment_type')?.value?.length != 0">
              <ul class="selected-values mt-16">
                  <li class="ng-value" *ngFor="let item of leaveEligibiltyForm.controls[i].get('employment_type')?.value,let ki =index">
                      <ng-container *ngFor="let insideitem of empstatusDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['name']==item">
                              {{ (insideitem['name'].length>15)? (insideitem['name'] |
                              slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span class="ng-value-icon right"
                          (click)="clearcommonForm('emp_status',ki,i)" aria-hidden="true">×</span>
                  </li>
              </ul>
          </ng-container>
          </div>
          </ng-container>

        <div class="col-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Is this leave applicable to only specific  gender?</label>
                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If yes is selected, you can select for which gender the leave is applicable." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                  formControlName="leave_applicable_gender" [value]=true (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                formControlName="leave_applicable_gender" [value]=false (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>
        <ng-container *ngIf="leaveEligibiltyForm.controls[i].get('leave_applicable_gender')?.value">
        <div class="col-12">
            <label for="name" class="form-label ">Gender </label>
            <ng-select
            [ngClass]="{ 'is-invalid': (submitted || leaveEligibiltyForm.controls[i].get('gender')?.touched) &&  leaveEligibiltyForm.controls[i].get('gender')?.errors}"
            placeholder="{{this.messageService.selectplaceholddisplay('gender')}}"
            formControlName="gender" [items]="gender" [multiple]="true"
            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
            bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{ standalone : true }" class="me-8" />
                    {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                </div>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="leaveEligibiltyForm.controls[i].get('gender')?.value.length ">
                    <span class="ng-value-label">{{leaveEligibiltyForm.controls[i].get('gender')?.value.length }}
                       gender
                        selected.</span>
                </div>
            </ng-template>
            </ng-select>
            <div *ngIf="(leaveEligibiltyForm.controls[i].get('gender')?.touched) && leaveEligibiltyForm.controls[i].get('gender')?.errors" class="invalid-feedback"><div *ngIf="leaveEligibiltyForm.controls[i].get('gender')?.errors?.required">{{this.messageService.validationDisplay('required')}}
            </div></div>
        <ng-container *ngIf="leaveEligibiltyForm.controls[i].get('gender')?.value?.length != 0">
            <ul class="selected-values mt-16">
                <li class="ng-value" *ngFor="let item of leaveEligibiltyForm.controls[i].get('gender')?.value,let ki =index">
                    <ng-container *ngFor="let insideitem of gender, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['name']==item">
                            {{ (insideitem['name'].length>15)? (insideitem['name'] |
                            slice:0:15)+'..':(insideitem['name']) }}</span>
                    </ng-container>
                    <span class="ng-value-icon right"
                        (click)="clearcommonForm('gender',ki,i)" aria-hidden="true">×</span>
                </li>
            </ul>
        </ng-container>
        </div>
        </ng-container>

        <!-- new fields foe leave waiting -->
        <div class="col-12 " *ngIf="leaveEligibiltyForm.controls[i].get('leave_default')?.value==false">
            <div class="form-row">
                <label for=" " class="form-label ">Is there any waiting period for the employee to be eligible for this leave type?</label>
                <!-- <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If yes is selected, you can select for which gender the leave is applicable." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span> -->
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                  formControlName="leave_waiting_status" [value]=true (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                formControlName="leave_waiting_status" [value]=false (ngModelChange)="addValidator()">
                <label class="form-check-label" >
                    No
                </label>
            </div>
        </div>
        <ng-container *ngIf="leaveEligibiltyForm.controls[i].get('leave_waiting_status')?.value &&(leaveEligibiltyForm.controls[i].get('leave_default')?.value==false)">
            <div class="col-12 ">
                <div class="form-row">
                    <label for=" " class="form-label ">Waiting Period starts from</label>
                    <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  [ngbPopover]="popContent" popoverClass="popover-md popover-info" placement="bottom" triggers="hover"></i>
                        <ng-template #popContent>
                            Select the appropriate options for the waiting period before an employee can apply for this leave type.:
                            <ul><li> DOJ: The waiting period of a certain number of days starts from the DOJ (date of joining) of the employee to apply for this particular leave type.</li>
                            <li>DOC: The waiting period of a certain number of days starts from the DOC(date of confirmation) of the employee to apply for this particular leave type. </li></ul></ng-template></span>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                      formControlName="waiting_period_start" [value]=false>
                    <label class="form-check-label" >
                        Date of  Joining
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                    formControlName="waiting_period_start" [value]=true>
                    <label class="form-check-label" >
                        Date of Confirmation
                    </label>
                </div>
            </div>
            <div class="col-12 ">
                <div class="form-row">
                    <label for=" " class="form-label ">Waiting Period will be effective for</label>
                    <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  [ngbPopover]="popContent2" popoverClass="popover-md  popover-info" placement="top" triggers="hover"></i><ng-template #popContent2>
                            Select for which below options waiting period will be effective for:
                            <ul><li>Calendar days: Leave eligibility begins after completing the specified calendar days from the start date of (DOJ/DOC). This includes all days (working and non-working) in the waiting period calculation.</li>
                            <li> Paid days: Leave eligibility begins after completing the specified paid days from the start date of (DOJ/DOC). This includes weekly off, holidays, or approved leaves.</li>
                            <li> Present days:  Leave eligibility begins after completing the specified workdays from the start date of (DOJ/DOC), excluding weekends and holidays. This includes the actual days worked. </li></ul></ng-template></span>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                      formControlName="waiting_period_effective" [value]="'Calender days'">
                    <label class="form-check-label" >
                        Calendar Days
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                    formControlName="waiting_period_effective" [value]="'Paid days'">
                    <label class="form-check-label" >
                        Paid Days
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                    formControlName="waiting_period_effective" [value]="'Present days'">
                    <label class="form-check-label" >
                        Present Days
                    </label>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label class="form-check-label required" >
                    No. of Days
                </label>
                <input type="text" class="form-control " formControlName="waiting_period_days"
                    placeholder="Enter days"
                    [ngClass]="{ 'is-invalid': ( leaveEligibiltyForm.controls[i].get('waiting_period_days')?.touched || leaveEligibiltyForm.controls[i].get('waiting_period_days')?.dirty) &&  leaveEligibiltyForm.controls[i].get('waiting_period_days')?.errors}">
                <div *ngIf="(leaveEligibiltyForm.controls[i].get('waiting_period_days')?.touched || leaveEligibiltyForm.controls[i].get('waiting_period_days')?.dirty) && leaveEligibiltyForm.controls[i].get('waiting_period_days')?.errors"
                    class="invalid-feedback">
                    <div *ngIf="leaveEligibiltyForm.controls[i].get('waiting_period_days')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="leaveEligibiltyForm.controls[i].get('waiting_period_days')?.hasError('min') && !(leaveEligibiltyForm.controls[i].get('waiting_period_days')?.errors?.pattern)">
                      {{this.messageService.fieldlengthvalidation('minvalue',1)}}
                        </div>
                    <div *ngIf="leaveEligibiltyForm.controls[i].get('waiting_period_days')?.errors?.pattern ||leaveEligibiltyForm.controls[i].get('waiting_period_days')?.errors?.maxlength">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>
        </ng-container>

    </div>
</div>
</ng-container>
</ng-container>
</ng-container>
</form>
