<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{alertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="close()">Ok</button>
      </div>
    </div>
  </div>
</div>
