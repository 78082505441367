import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeavePolicyService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }

  getleavetypeList(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-dropdown/?is_active=1&ordering=leave_name`, this.httpOption);
  }
  createLeavePolicy(data:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/leave-policy/`, data, this.httpOption)
  }
  getLeavePolicyData(status:any,limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-policy/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }
  getSearchList(status:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-policy-dropdown/?is_active=${status}&ordering=name`, this.httpOption);

  }
  getSingleLeavePolicyData(id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-policy-detail/${id}/`, this.httpOption);
  }
  deleteLeavepolicy(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}attendance/api/v1/leave-policy-delete/${id}/`,data, this.httpOption)
  }
  updateLeavePolicy(id:number,data:any){
    return this.http.put(`${this.API_PATH}attendance/api/v1/leave-policy-detail/${id}/`,data, this.httpOption)
  }
}
