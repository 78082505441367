<div class="container-fluid p-32 form-container">
  <app-edit-loader *ngIf="loader1"></app-edit-loader>
  <div class="row row-16" *ngIf="!loader1">
    <div class="col-12">
      <div  role="alert" class="alert alert-info mb-0 {{alertShow1}}"><p class="mb-0 fs-14"> As per the notification from Income tax department, Regime can be selected only once in the financial year. If you have business income, then regime selection will be restricted even for the next financial year.  </p>
        <div class="btn-close" (click)="alertShow1='hide'"></div>
      </div>
    </div>
    <div class="col-12 d-flex">
      <span class="link-primary1 fs-14 fw-500 ms-auto lh-1" (click)="taxRegimeSlab = true"><i
          class="bi bi-question-circle me-1"></i>Tax Regime Slabs
          FY{{selectedYear}} - FY{{(selectedYear + 1)}}</span>
    </div>
    <div class="col-6">
      <label class="radio-group" for="btnradio1">
        <input type="radio" class="radio-check" name="btnradio" id="btnradio1" autocomplete="off" checked >
        <div class="radio-content details-card card p-16" (click)="oldNewRadioBtnChangeFunction('old')">
          <div class="row row-24 ">
            <div class="col-12 ">
              <span class="title">OLD TAX REGIME</span>
            </div>
            <div class="col-6">
              <div class="detail-title">Total taxable income</div>
              <div class="detail-desc">{{oldRegimeSummary.total_tax_income| currency:currency}}</div>
            </div>
            <div class="col-6">
              <div class="detail-title">Total tax</div>
              <div class="detail-desc">{{oldRegimeSummary.total_tax| currency:currency}}</div>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div class="col-6">
      <label class="radio-group" for="btnradio2">
        <input type="radio" class="radio-check" name="btnradio" id="btnradio2" autocomplete="off">
        <div class="radio-content details-card card p-16" (click)="oldNewRadioBtnChangeFunction('new')">
          <div class="row row-24 ">
            <div class="col-12 ">
              <span class="title">NEW TAX REGIME</span>
            </div>
            <div class="col-6">
              <div class="detail-title">Total taxable income</div>
              <div class="detail-desc">{{newRegimeSummary.total_tax_income| currency:currency}}</div>
            </div>
            <div class="col-6">
              <div class="detail-title">Total tax</div>
              <div class="detail-desc">{{newRegimeSummary.total_tax| currency:currency}}</div>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div class="col-12">
      <div  role="alert" class="alert alert-info mb-0 {{alertShow2}}"><p class="mb-0"> Exemption for reimbursement components are provided based on the approved bills as on current date. Exemption amount will be updated automatically if there are any changes in the bills submitted against the reimbursement components.  </p>
        <div class="btn-close" (click)="alertShow2='hide'"></div>
      </div>
    </div>
    <div class="col-12">
      <div role="alert" class="alert alert-warning mb-0 {{alertShow1}}">
        <p class="mb-0 fs-14">Based on the declared investments, <span class="fw-600">{{getBetterRegime()}} Regime</span> seems to be more
          profitable for the employee.</p>
        <div class="btn-close" (click)="alertShow1='hide'"></div>
      </div>
    </div>
    <!-- test start -->
    <app-view-regime-comparison class="col-12" [oldNewFlag]="oldNewFlag" [currency]="currency" [taxRegimeData]="taxData" [hra80gg]="hra80gg"></app-view-regime-comparison>
    <!-- test end -->
    <div class="col-12">
      <div class="form-check">
        <input type="checkbox" name="incomefrom_self_occupiedproperty" id=""  class="form-check-input" (change)="ackCheckbox($event)">
        <label  for="" class="form-check-label">I acknowledge that I can choose my regime only once for the current financial year</label>
    </div>
    </div>

  </div>
</div>
<footer class="submit-footer">
  <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!enableSaveBtn" (click)="formSubmit()">SAVE</button>
</footer>

<!-- Tax slab -->
<app-view-tax-slab *ngIf="taxRegimeSlab === true" [taxRegimeSlab]="taxRegimeSlab" (close)="closeTaxSlab($event)"></app-view-tax-slab>

<!-- Info popup -->
<app-info-popup *ngIf="alertToggle" (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="investalertMsg"></app-info-popup>
<!-- End -->

