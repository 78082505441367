<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="salaryOnoldForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.salary_month.errors}">
                  <div class="col-12 form-row">
                      <p class="text-uppercase fw-500 mb-0 required">Salary month</p>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check ">
                          <input name="salary_month" type="radio" id="salary_month1" class="form-check-input  "
                              formControlName="salary_month" value="last_month"
                              (change)="radioBtnChangeFunction($event,'salary_month','last_month','sm_specific_month','sm_specific_year')">
                          <label for="salary_month1" class="form-check-label">Last month</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check ">
                          <input name="salary_month" type="radio" id="salary_month2" class="form-check-input "
                              formControlName="salary_month" value="this_month"
                              (change)="radioBtnChangeFunction($event,'salary_month','this_month','sm_specific_month','sm_specific_year')">
                          <label for="salary_month2" class="form-check-label">This month</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="salary_month" type="radio" id="salary_month3" class="form-check-input "
                              formControlName="salary_month" [value]="true"
                              (change)="radioBtnChangeFunction($event,'salary_month',true,'sm_specific_month','sm_specific_year')">
                          <label for="salary_month3" class="form-check-label">Specific month</label>
                      </div>
                  </div>
                  <!-- <div class="col-12 form-row" *ngIf="salaryOnoldForm.value.salary_month==true">
                      <ng-container>
                          <div class="input-group">
                              <input (click)="datePicker1.open()" class="form-control"
                                  placeholder="{{this.messageService.selectplaceholddisplay('Period')}}" matInput
                                  formControlName="sm_specific_month" [matDatepicker]="datePicker1"
                                  [matDatepickerFilter]="salFilter">
                              <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                          </div>
                      </ng-container>
                  </div> -->


                  <ng-container *ngIf="salaryOnoldForm.value.salary_month==true">
                    <div class="col-6">
                        <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_month?.touched || f?.sm_specific_month?.dirty) &&  f?.sm_specific_month?.errors}">
                            <label for="" class="form-label">Month </label>
                            <app-master-select class="config-app-master" style="width:9rem"
                            [placeholder]="month_placeholder"
                                    id="month" [isrequired]="false" [selectedItem]="salaryOnoldForm.value.sm_specific_month"
                                [type]="attendListOfMonth" formControlName="sm_specific_month" [(ngModel)]="f.sm_specific_month.value"
                                    (ngModelChange)="constructData($event)" [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_month?.touched || f?.sm_specific_month?.dirty) &&  f?.sm_specific_month?.errors}"> </app-master-select>
                        </div>
                        <div *ngIf="(submitted || f?.sm_specific_month?.touched || f?.sm_specific_month?.dirty)&& f?.sm_specific_month?.errors" class="invalid-feedback">
                            <div *ngIf="f?.sm_specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_year?.touched || f?.sm_specific_year?.dirty) &&  f.sm_specific_year.errors}">
                            <label for="" class="form-label">Year </label>
                            <ng-select class="form-ngselect  fs-14"
                            formControlName="sm_specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_year?.touched || f?.sm_specific_year?.dirty) &&  f.sm_specific_year.errors}">
                                <ng-option *ngFor="let yData of Years"
                                    [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="(submitted || f?.sm_specific_year?.touched || f?.sm_specific_year?.dirty)&& f?.sm_specific_year?.errors" class="invalid-feedback">
                            <div *ngIf="f?.sm_specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                        </div>
                    </div>
                </ng-container>
              </div>
              <div *ngIf="(submitted) && f.salary_month.errors" class="invalid-feedback">
                <div *ngIf="f.salary_month.errors.required">
                    {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm"
              (click)="resetPage();resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
              (click)="resetPage();submitSalaryHold()">
              Apply
          </button>
      </footer>
  </form>
</div>
