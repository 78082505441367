<div class=" config-layout">
  <header class=" config-header">
      <h5 class="m-0">Reminders  - {{month}} {{year}}</h5>
      <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-c1-loader *ngIf="loader"></app-c1-loader>
  <main  *ngIf="!loader" class=" config-container">
<form class="">
  <div class="container-fluid p-32 form-container">
      <div class="row row-16 mb-16">
        <div class="col-12 ">
          <span class="fw-500 text-uppercase">REMINDERS  - {{month}} {{year}}</span>
        </div>
        <div class="col-12" *ngIf="!infoClosed">
          <div class="alert alert-warning show" role="alert" >
              Reminder’s are items that needs to be checked, the employees salary processing will not be affected with reminders
            <button class="btn-close" (click)="infoClosed=true;"></button>
          </div>
        </div>
      </div>
      <div class="row row-24 row-cols-xl-5">
        <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let item of reminders;let ind = index" >
          <div style="min-height: 7.5rem;" class="card card-c2 card-hover card-highlight {{item.class}}"  (click)="showSidePanel = true;selectedList=item.list;selectedName=item.reminder">
            <div class="card-body p-16 text-center">
              <h6 class="fw-500  fs-20">{{item.list.length}}</h6>
              <hr class="hr-1 mb-16 mt-16">
              <div class="mb-0 fw-500 fs-12">{{item.reminder}}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4 col-sm-6" (click)="showSidePanel = true">
          <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight1">
            <div class="card-body p-16 text-center">
              <h6 class="fw-500  fs-20">5</h6>
              <hr class="hr-1 mb-16 mt-16">
              <div class="mb-0 fw-500 fs-12">Salary on hold
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight2">
            <div class="card-body p-16 text-center">
              <h6 class="fw-500  fs-20">23</h6>
              <hr class="hr-1 mb-16 mt-16">
              <div class="mb-0 fw-500 fs-12">Salary unprocessed last month
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight-primary">
            <div class="card-body p-16 text-center">
              <h6 class="fw-500  fs-20">12</h6>
              <hr class="hr-1 mb-16 mt-16">
              <div class="mb-0 fw-500 fs-12">Pending proposed investment declaration
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight3">
            <div class="card-body p-16 text-center">
              <h6 class="fw-500  fs-20">5</h6>
              <hr class="hr-1 mb-16 mt-16">
              <div class="mb-0 fw-500 fs-12">Pending confirmed investment declaration
              </div>
            </div>
          </div>
        </div> -->

      </div>

  </div>
</form>
</main>
</div>
<!--Slider -->
<!-- <div class="side-panel" style="--sidepanel-width:27rem;z-index:9999;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container"  autocomplete="off">
    <header class="side-panel-head">
        <h5>{{selectedName}}</h5>
        <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
            <div class="row row-24 ">
              <div class="col-12 form-row" *ngFor="let item of selectedList;let ind = index">
                <div class="card card-c2 ">
                      <div class="card-body d-flex align-items-center">
                        <div *ngIf="(item.profile_image == null || item.profile_image=='')" class="avatar-circle sq-57 {{item.color_code}} avatar-border" >{{this.appService.getFirstChar(item.first_name+" "+(item.middle_name!=null?item.middle_name:" ")+ " "+item.last_name,2)}}</div>
                        <img *ngIf="(item.profile_image != null && item.profile_image!='')" src="{{ item.profile_image }}"  class="avatar-circle sq-57 avatar-border2"  />
                        <div class="overflow-hidden  d-flex flex-column">
                            <p class="fs-14 fw-600 ms-16 mb-0">{{item.first_name+" "+(item.middle_name!=null?item.middle_name:" ")+ " "+item.last_name}} ({{item.employee_code}})</p>
                            <p class="fs-12 fw-500 text-light-400 ms-16 mb-0">{{item.designation}} | {{item.company}}</p>
                        </div>
                      </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </form>
</div> -->
<ng-container *ngIf="showSidePanel">
  <app-non-mapped-empp [showSidePanel]="showSidePanel" [selectedList]="selectedList" [selectedName]="selectedName" (closePanel)="closePanel($event)"></app-non-mapped-empp>
</ng-container>
