<form action="" class=""  [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row mb-16">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500">Expense Category</p>
                <div class="ms-auto" style="width:22.688rem">
                    <app-searchbar [searchlist]='categorylist' [searchplaceholder]='searchPlaceholder' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchpaygroup($event)"></app-searchbar>
        
                </div>
            </div>           
        </div>  
        <div class="row row-8">  
            <app-c1-loader *ngIf="loadercatg"></app-c1-loader>
        <ng-container *ngIf="!loadercatg">
          <div class="col-12 h-100" *ngIf="categorylist?.length == 0">          
            <div class="flex-center gap-8 flex-column p-16 h-100 " style="min-height: calc(100vh - 19rem);">
              <img src="/assets/images/helper/helper3.png" alt="" srcset="">
              <p class="mb-0 fs-12 lh-base">
                  No data to show
              </p>
            </div>
          </div>            
            <div class="col-12 " *ngFor="let item of categorylist, let i =index" >
                <div class="card card-c2">
                    <div class="card-body px-24 py-10 d-flex align-items-center">
                    <div class="form-check">
                        <label for="{{item['name']}}" class="form-check-label ">{{item['name']}}</label>
                        <input type="checkbox" [disabled]="disabled" [checked]="this.formGp.value.categories.indexOf(item['id']+'') != -1?true:false" class="form-check-input" name="" [value]="item['id']" id="{{item['name']}}" (change)="onCheckChange($event,i)" >
                    </div>

                    <a *ngIf="this.formGp.value.categories.indexOf(item['id']+'') != -1 && disabled == false" class="link-primary align-items-center fs-14 d-flex ms-auto" (click)="editDetail = true;dataId=item['id'];saveClicked=false;"><i class="icon-pencil-square me-1"></i> Edit</a>
                    </div>
                </div>
            </div>     
       </ng-container> 
        </div>
    </div>

    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid)" (click)="validatepolicysetting()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validatepolicysetting()">NEXT</button>
    </footer>
</form>
<app-expense-category-add *ngIf="editDetail" [editDetail]="editDetail" [dataId]="dataId" [from]="'policy'"
    (panelclose)="closefn($event)" (detailout)="detailout($event)">
</app-expense-category-add>