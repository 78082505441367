import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';
import { AppService } from 'src/app/app.global';
import { ManageAssetComponent } from 'src/app/@module/hr/manage-asset/manage-asset.component';
@Component({
  selector: 'app-inventory-view',
  templateUrl: './inventory-view.component.html',
  styleUrls: ['./inventory-view.component.scss']
})
export class InventoryViewComponent implements OnInit {
  @Input() viewInventory:any;
  @Input() isEdit:any
  @Input() id:any;
  @Input() hide:any;
  @Input() from:any;
  @Output() closeModel  = new EventEmitter();
  @Output() viewHistory = new EventEmitter();
  @Output() edit        = new EventEmitter()
  viewData:any          = [];
  loader                = true;
  modalToggle           = false
  currentIndex:any
  fileName:any
  currentDocName:any
  currentExtension:any


  constructor(
    public assetService:AssetSettingsService,
    public appService:AppService,

  ) { }

  ngOnInit(): void {
    this.getViewDetails()
  }
  close(){
    this.closeModel.emit()
    this.viewInventory  = false;
  }
  getViewDetails(){
    this.loader  = true;
    if(this.from !== 'inbox'){
      this.assetService.getSingleInventoryData(this.id).subscribe((res:any)=>{
        this.viewData = res.body;
        this.loader   = false
      })
    }else{
      this.assetService.getSingleInventoryInbox(this.id).subscribe((res:any)=>{
        this.viewData = res.body;
        this.loader   = false
      })
    }
  }
  historyView(){
    // this.viewInventory = false
    this.viewHistory.emit();
  }
  viewDocument(url:any,index:any){
    this.modalToggle      = true;
    this.fileName         = url;
    this.currentIndex     = index+1
  }
  moveForwardBackward(direction:any){
    if(direction=='forward')
    this.currentIndex+=1
    else
    this.currentIndex-=1
    this.fileName     = this.viewData?.asset_attachments[this.currentIndex-1]

  }
  editInventory(id:any){
   this.closeModel.emit()
   this.viewInventory = false
   this.edit.emit(id);
  }

}
