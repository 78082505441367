<!-- <select class="form-select" [ngClass]="{ 'is-invalid': submitted && selectedItem == '0' }" [value]="selectedItem" [disabled]="disabled" [(ngModel)]="key"  [(ngModel)]="key">
    <option [value]="0">{{placeholder}}</option>
    <option *ngFor="let item of items" [value]="item.id">{{ item.value }}</option>
</select> -->

<!-- <ng-select #ngSelecter class="form-ngselect text-muted" [dropdownPosition]="dropdownPosition" [appendTo]="appendTo"
  [class]="className" [disabled]="isdisabled" [(ngModel)]="key" [(ngModel)]="key" [placeholder]="placeholder"
  (blur)="change()"> -->
  <!--<ng-option [value]="0">{{placeholder}}</ng-option>-->

  <!-- <ng-container *ngIf="type=='schedule_dates';">
    <ng-option *ngFor="let number of createRange(range)" [value]="number">{{ordinal_suffix_of(number) }}</ng-option>
  </ng-container> -->
  <!-- <ng-container *ngIf="type!='schedule_dates' && type!='schedule_day';">

    <ng-option *ngFor="let item of items" [value]="item.id">
      <div class="text-trim" title="{{item.value}}">{{item.value}}</div>
    </ng-option>
  </ng-container>
  <ng-container *ngIf="type=='schedule_day';">
    <ng-option *ngFor="let number of createRange(range,startPoint)" [value]="number">{{ number }}</ng-option>
  </ng-container>
</ng-select> -->

<ng-container *ngIf="type=='schedule_dates';">
  <ng-select #ngSelecter class="form-ngselect text-muted" [placeholder]="placeholder" [dropdownPosition]="dropdownPosition" [appendTo]="appendTo"
  [class]="className" [disabled]="isdisabled" [(ngModel)]="key" [(ngModel)]="key" (blur)="change()">
    <ng-option *ngFor="let number of createRange(range)" [value]="number">{{ordinal_suffix_of(number) }}</ng-option>
  </ng-select>
  </ng-container>

  <ng-container *ngIf="type!='schedule_dates' && type!='schedule_day' && type != 'telephone_code';">
    <ng-select #ngSelecter class="form-ngselect text-muted" [placeholder]="placeholder" [items]="items"
    bindValue="id" bindLabel="value" [dropdownPosition]="dropdownPosition" [appendTo]="appendTo"
    [class]="className" [disabled]="isdisabled" [(ngModel)]="key" [(ngModel)]="key" (blur)="change()">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <div class="text-trim" title="{{item.value}}">{{item.value}}</div>
    </ng-template>
      </ng-select>
    </ng-container>

  <ng-container *ngIf="type=='schedule_day';">
    <ng-select #ngSelecter class="form-ngselect text-muted" [placeholder]="placeholder" [dropdownPosition]="dropdownPosition" [appendTo]="appendTo"
    [class]="className" [disabled]="isdisabled" [(ngModel)]="key" [(ngModel)]="key" (blur)="change()">
    <ng-option *ngFor="let number of createRange(range,startPoint)" [value]="number">{{ number }}</ng-option>

    </ng-select>
    </ng-container>


<ng-container *ngIf="type =='telephone_code'">
  <ng-select #ngSelecter class="form-ngselect text-muted" [placeholder]="placeholder" [items]="items" [clearable]="false"
  bindValue="id" bindLabel="value" [dropdownPosition]="dropdownPosition" [appendTo]="appendTo" [ngClass]="invalidClass"
  [class]="className" [disabled]="isdisabled" [(ngModel)]="key" [(ngModel)]="key" (blur)="change()">
    <ng-template ng-label-tmp let-item="item">
      <div class=" d-flex" title="+{{item.id}}"><img class="me-8" style="width: 1.5rem;" [src]="'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + item.code + '.svg'">+{{item.id}}</div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <div class="text-trim fs-14" title="{{item.value}} (+{{item.id}})"><img class="me-8" style="width: 1.5rem;" [src]="'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + item.code + '.svg'">{{item.value}} (+{{item.id}})</div>
    </ng-template>
  </ng-select>
</ng-container>

