import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeReportsService {

  private offset: number = 0;
  private limit: number = 20;
  header_filter : any = [];
  data_filter   : any = {};

  constructor(
    private http: HttpClient,
    public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(  !event.url.includes('employee-report-configure')){
          this.setDataFilter({})
          this.setHeaderFilter([])
        }
      }
    })
   }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  reportAPI(url:any,limit:any,offset:any,ordering:any,formData:any){
    if(formData == ''){
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`,this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/${url}/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`,this.httpOption)
    }
  }


  reportTypeList(type:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?report_type=${type}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  getSingleEmployeeReport(id: number) {
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list/?id=${id}`, this.httpOption)
  }

  EmployeeReportDropdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/report-list-dropdown/?ordering=name&report_type=Employee`, this.httpOption)
  }

  snapshotReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-snapshot-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-snapshot-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  bankDetailsReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-bank-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-bank-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  employeeDetailsReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-details-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-details-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  personalDetailsReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-personal-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-personal-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  loginReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-login-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-login-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  resignationReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-resignation-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-resignation-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }
  portalAccessReport(limit:any,offset:any,ordering:any,formData:any,filter:any) {
    if(filter){
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-disable-report/?filter_applied=db&limit=${limit}&offset=${offset}&ordering=${ordering}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/employee-disable-report/?filter_applied=form&limit=${limit}&offset=${offset}&${formData}`, this.httpOption)
    }
  }

  getOffset(): number {
    return this.offset;
  }

  calculateOffset(pageNumber: number): number {
    this.offset = pageNumber * this.limit;
    return this.offset;
  }

  getBuList(compid:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name&company_id=${compid}`, this.httpOption);
  }

  getBranchList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getCityList(stateid: number){
    return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?is_active=true&ordering=name&state=${stateid}`, this.httpOption);
  }
  getempList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name&employment_status=["Probation","Confirmed","Resigned"]`, this.httpOption);
  }
  getCountryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/country-list/`, this.httpOption);
  }
  getStateList(countryid: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/region-list/?country=${countryid}`, this.httpOption);
  }

  filterMemorize(body: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/report-filter-save/`, body, this.httpOption)
  }

  yearListDropdown() {
    return this.http.get(`${this.API_PATH}hr/api/v1/get-organization-created-year/`, this.httpOption)
  }
  userListDropdown(){
    return this.http.get(`${this.API_PATH}organization/api/v1/user-list-dropdown/`, this.httpOption)
  }

  setDataFilter(val:any){
    this.data_filter = val
  }

  setHeaderFilter(val:any){
    this.header_filter = val
  }
}
