import {Component, OnInit, Output,Input, EventEmitter} from '@angular/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class ProfilePicComponent implements OnInit {
  @Input() addProfileModal:any
  @Input() text:any
  @Input() imageUrl:any = "";
  @Input() loginAs:any = "";
  @Input() empId:any
  @Output() close = new EventEmitter()
  @Output() pic = new EventEmitter()
  // imageUrl: any = "";
  id = 0;
  invalidFile = false;
  errormsg:any = "";
  inputId:string = "fileInput";
  userData:any = []
  captureModal = false

  constructor( private authService:AuthService,private profileService:SnapshotService,private messageService:MessageService,private notificationService:NotificationService,public appservice:AppService) { }

  ngOnInit(): void {
    this.id         = this.authService.isEmployeeId;
    this.userData = this.authService.getUserData()
  }

  getURL(event: any) {
    if(this.imageUrl !='')
    this.updateProfileImg(event);

  }

  updateProfileImg(imageFile:any){

    if(this.loginAs=='Employee'){
      let tempData = {
        "temp_data": "profile_image",
        "data":{profile_image:imageFile}
      }
      this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
        if(res.employee_request == 0)
        {
          this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
        } else {
          this.imageUrl = imageFile;
          this.notificationService.handleSuccessNotification(res?.message,'Success');
        }
        this.close.emit()
      },
      (error:any)=>{
        console.log(error)
      })
    } else {
      this.profileService.updateProfileImg(this.empId,{profile_image:imageFile},this.loginAs).subscribe((res:any)=>{
        this.close.emit()
        this.pic.emit(imageFile)

      },
      (error:any)=>{
        console.log(error)
      }
      )
    }
  }

  getfileformat(event:any){

    // this.imageUrl = null;

    this.invalidFile = false;
    if(event==false){
      this.invalidFile = true;
      this.errormsg = this.messageService.validateFileFormat('jpeg,png,jpg');
      this.notificationService.handleErrorNotification(this.errormsg ,"Error");

    }

  }
  getvalidsize(event:any){

    this.notificationService.handleErrorNotification(event,"Error");

  }

}
