<form class="" autocomplete="off" [formGroup]="formGp">
  <div class="container-fluid p-32 form-container">
    <app-loader *ngIf="loader"></app-loader>
    <div class="row" *ngIf="!loader">
      <p class="text-uppercase fw-500">SELECT TEMPLATE</p>
      <div class="col-12 ">
        <div class="card card-c2 p-24">
          <div class="row row-16">
            <div class="col-md-3">
              <label class="radio-group" for="radio1">
                <input class="radio-check" name="templateID" type="radio" id="radio1" formControlName="templateID" [value]="0">
                <div class="card card-template py-1 radio-content">
                  <i class="icon-plus-circle m-auto fs-64 text-light-400"></i>
                </div>
                <p class="text-center mb-0 mt-8 fs-12 fw-500">Create New Letter</p>
              </label>
            </div>
            <ng-container *ngIf="defaultTemplate?.length !=0">
                <div class="col-md-3" *ngFor="let letter of defaultTemplate; let i = index">
                  <label class="radio-group" [for]="letter?.id">
                    <input class="radio-check" name="templateID" type="radio" [id]="letter?.id" formControlName="templateID" (change)="getTemplateDetails(letter?.letter_tag)" [value]="letter?.code_view_html">
                    <div class="card card-template py-1 radio-content">
                      <img src="{{letter?.preview_url}}" alt="" class="img-fluid" #image>
                    </div>
                    <p class="text-center mb-0 mt-8 fs-12 fw-500">{{letter?.name}}</p>
                  </label>
                </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid"
        (click)="validateForm()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
  </footer>
</form>
