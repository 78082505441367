import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-shift-detail',
  templateUrl: './shift-detail.component.html',
  styleUrls: ['./shift-detail.component.scss']
})
export class ShiftDetailComponent implements OnInit {
  activeTab=1;

  menuData:any = [];
  menuList:any;
  constructor(
    public appService:AppService,
    public route:ActivatedRoute,
    public router : Router
  ) { }

  ngOnInit(): void {
    this.getPermission();
  }

  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });

    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/shift')
      this.router.navigate([this.menuData[0].link]);
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/shift');

    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }

}
