import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trip-review',
  templateUrl: './trip-review.component.html',
  styleUrls: ['./trip-review.component.scss']
})
export class TripReviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
