import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  getCategoryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/search-category-list`, this.httpOption);
  }
  getRecentSearch(){
    return this.http.get(`${this.API_PATH}organization/api/v1/search-category-recent`, this.httpOption);
  }
  getSearchResult(url:string,param:string){
    return this.http.get(`${this.API_PATH}${url}?search=${param}`, this.httpOption);
  }
  saveRecentSearch(cat:string,searchParam:string){
    return this.http.get(`${this.API_PATH}organization/api/v1/search-module/?search_category=${cat}&search_text=${searchParam}`, this.httpOption);
  }
 
}
