import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class SalaryRevisionService {
  revision_realtime: EventEmitter<any> = new EventEmitter();
  FILTERDATA:any= "ordering=-created_at"
  constructor(
    private http: HttpClient,
    public router: Router,
    private websocket: WebSocket,
    public appservice:AppService
  ) {this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      if(!event.url.includes('salary-revision')){
        this.setFilterData(null)
      }
    }
  }) }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };
  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public requestSocket(){
    // console.log("Url----->",this.WEBSOCKET_URL)
    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}salaryreviserequest/${this.appservice.getSubDomain()}_salaryrevisereq/`);

    this.websocket.onopen = (event:any) => {

    }

    this.websocket.onmessage = (event:any) => {

      let data = JSON.parse(event.data);
      this.revision_realtime.emit(data);
    }

    this.websocket.onclose = (event:any) => {

    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

          if(event.url !== '/salary-revision'){
            this.closeSocket();
          }
      }
    });

    if (this.websocket.readyState === WebSocket.OPEN) {

    }
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
      // this.websocket.onclose = (event:any) => {

      // }
      this.websocket.addEventListener('close', (event) => {

      });
    }
  }


  getRevisionList(limit:any,offset:any,search:any,param:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-list/?limit=${limit}&offset=${offset}&search=${search}&${param}`, this.httpOption)
  }

  getRevDDList() {
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-list-dropdown/?ordering=employee__user__first_name`, this.httpOption)
  }
  getRevisionGraph(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-histrory-graph/${id}`, this.httpOption)
  }
  getRevisionTable(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-histrory-table/${id}/`, this.httpOption)
  }
  getEmployeeData(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-histrory-employee/${id}`, this.httpOption)
  }
  getRevisionDetail(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-histrory-detail/${id}/`, this.httpOption)
  }
  getFilterDetail(param:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-list/?${param}`, this.httpOption)
  }
  getRevDDListFilter(param:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-revision-list-dropdown/?${param}&ordering=employee__user__first_name`, this.httpOption)
  }
  setFilterData(val:any){
    this.FILTERDATA = val
  }

  applicableList(type:string){
    if(type == "BU"){
      return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    } else if(type == "Department")
      return  this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Designation")
      return  this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Grade")
      return  this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Company")
      return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
    else if(type == "Employee")
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name&employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}`, this.httpOption);
    else if(type == "Branch")
      return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&&ordering=name`, this.httpOption);
    else if(type == "City")
      return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?ordering=name&is_active=true`, this.httpOption);
    else
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name`, this.httpOption);
  }
}
