import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  menuData:any = [];
  menuList:any;
  activeTab=1;
  constructor(private appService: AppService,public router: Router) { }

  ngOnInit(): void {
    this.getPermission();
  }
  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/permissions')
      this.router.navigate([this.menuData[0]?.link]);
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/permissions');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }



}
