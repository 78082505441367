
<div class="row row-24 mb-24">
  <div class="col-12">
    <div class="card details-card shadow-0 px-24 py-16">
      <div class="row row-24">
        <div class="col-12 d-flex">
          <span class="title">FBP CATEGORY</span>
          <div class="d-flex align-items-center gap-8 ms-auto">
            <span class="ribbon ribbon-right ribbon-danger" *ngIf="fbpStatus == 'Invalid'">Invalid</span>
            <span *ngIf="fbpStatus != 'Invalid'" class=" fs-14 ms-auto px-2 fw-500 badge3" [ngClass]="{'badge-warning': fbpStatus === 'Pending','badge-success': fbpStatus === 'Accepted','badge-danger': fbpStatus === 'Rejected'}"  >
              {{fbpStatus}}
            </span>
          </div>
        </div>
        <div class="row row-16">
          <div class="col-12" *ngFor="let req of fbpReqViewData,let i = index" >
              <div class="card details-card py-16 px-24">
                <div class="row row-12">
                  <div class="col-12 d-flex">
                    <div class="fw-500 me-auto">
                      {{req.pay_component_name}}
                    </div>
                    <!-- <div class="fs-10 fw-500 text-light-400 text-uppercase">Max Amount :{{req.max_amount}} {{currency}}</div> -->
                  </div>
                  <div class="col-4">
                      <div class="detail-title">Amount annually</div>
                      <div class="detail-desc">{{req.annually_amount  | currency : currency}}</div>
                  </div>
                  <div class="col-4">
                      <div class="detail-title">Amount monthly</div>
                      <div class="detail-desc">{{req.monthly_amount  | currency : currency}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Maximum amount</div>
                    <div class="detail-desc">{{req?.max_amount | currency : currency}} </div>
                </div>
                </div>
              </div>
          </div>

          <div class="col-12" *ngIf="from == 'inbox'">
              <textarea name="" id="" rows="3" placeholder="Comment here..."
                  class="form-control my-12 textarea-comment"></textarea>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

