

<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Override PC/PT/ESI/LWF/TDS/PF</h5>
        <button class="btn-reset text-light-600" ><i class="icon-close-lg fs-16" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"></i></button>
    </header>
    <nav ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="activeTab" class="nav nav-custom sticky "  style="top: 3.625rem;">
        <li [ngbNavItem]="1">
            <a ngbNavLink class="nav-link">Override Pay component</a>
            <ng-template ngbNavContent>
               <app-override-pay-component [month]="month" [year]="year" [company]="company"  [permissions]="permissions"></app-override-pay-component>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink class="nav-link">Override PT</a>
            <ng-template ngbNavContent>
                <app-override [componentType]="componentType.toUpperCase()" [sampleFile]="'assets/docs/sample-override-pt.xlsx'" [navLoader]="navLoader"
                [month]="month" [year]="year" [company]="company"  [permissions]="permissions"  [fileName]="fileName" [data]="uploadData" [fileSize]="fileSize"  [invalidFormat]="invalidFormat" [loader]="loader" [employeeList]="empDD" [selctedData]="selctedData" [pageSize]="pageSize" [HistoryData]="HistoryData"
                (fileChange)="onFileChange($event)" (deleteFile)="deleteFile()" (validateData)="validateData()"  (changeSelectedEmployee)="changeSelectedEmployee($event)" (addEmployee)="addEmployee()" (checkall)="checkall($event)" (deleteSingleRow)="deleteSingleRow($event)" (save)="saveData()" (deleteCompleted)="deleteCompleted($event)" (completedList)="completedList()" (resetFilterEmit)="resetFilter()" (submitFilter)="submitFilter($event)"
                (updateValidatedData)="updateValidatedData($event)"></app-override>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink class="nav-link">Override ESI</a>
            <ng-template ngbNavContent>
                <app-override [componentType]="componentType.toUpperCase()"  [sampleFile]="'assets/docs/sample-override-esi.xlsx'"  [navLoader]="navLoader"
                [month]="month" [year]="year" [company]="company"  [permissions]="permissions"  [fileName]="fileName" [data]="uploadData" [fileSize]="fileSize"  [invalidFormat]="invalidFormat" [loader]="loader" [employeeList]="empDD" [selctedData]="selctedData" [pageSize]="pageSize" [HistoryData]="HistoryData"
                (fileChange)="onFileChange($event)" (deleteFile)="deleteFile()" (validateData)="validateData()"  (changeSelectedEmployee)="changeSelectedEmployee($event)" (addEmployee)="addEmployee()" (checkall)="checkall($event)" (deleteSingleRow)="deleteSingleRow($event)" (save)="saveData()" (deleteCompleted)="deleteCompleted($event)" (completedList)="completedList()"  (resetFilterEmit)="resetFilter()" (submitFilter)="submitFilter($event)"
                (updateValidatedData)="updateValidatedData($event)"></app-override>
            </ng-template>
        </li>
        <li [ngbNavItem]="4">
            <a ngbNavLink class="nav-link">Override LWF</a>
            <ng-template ngbNavContent>
                <app-override [componentType]="componentType.toUpperCase()" [sampleFile]="'assets/docs/sample-override-lwf.xlsx'"  [navLoader]="navLoader"
                [month]="month" [year]="year" [company]="company"  [permissions]="permissions"  [fileName]="fileName" [data]="uploadData" [fileSize]="fileSize"  [invalidFormat]="invalidFormat" [loader]="loader" [employeeList]="empDD" [selctedData]="selctedData" [pageSize]="pageSize" [HistoryData]="HistoryData"
                (fileChange)="onFileChange($event)" (deleteFile)="deleteFile()" (validateData)="validateData()"  (changeSelectedEmployee)="changeSelectedEmployee($event)" (addEmployee)="addEmployee()" (checkall)="checkall($event)" (deleteSingleRow)="deleteSingleRow($event)" (save)="saveData()" (deleteCompleted)="deleteCompleted($event)" (completedList)="completedList()"  (resetFilterEmit)="resetFilter()" (submitFilter)="submitFilter($event)"
                (updateValidatedData)="updateValidatedData($event)"></app-override>
            </ng-template>
        </li>
        <li [ngbNavItem]="5">
            <a ngbNavLink class="nav-link">Override TDS</a>
            <ng-template ngbNavContent>
                <app-override [componentType]="componentType.toUpperCase()" [sampleFile]="'assets/docs/sample-override-tds.xlsx'"  [navLoader]="navLoader"
                [month]="month" [year]="year" [company]="company"  [permissions]="permissions"  [fileName]="fileName" [data]="uploadData" [fileSize]="fileSize"  [invalidFormat]="invalidFormat" [loader]="loader" [employeeList]="empDD" [selctedData]="selctedData" [pageSize]="pageSize" [HistoryData]="HistoryData"
                (fileChange)="onFileChange($event)" (deleteFile)="deleteFile()" (validateData)="validateData()"  (changeSelectedEmployee)="changeSelectedEmployee($event)" (addEmployee)="addEmployee()" (checkall)="checkall($event)" (deleteSingleRow)="deleteSingleRow($event)" (save)="saveData()" (deleteCompleted)="deleteCompleted($event)" (completedList)="completedList()"  (resetFilterEmit)="resetFilter()" (submitFilter)="submitFilter($event)"
                (updateValidatedData)="updateValidatedData($event)"></app-override>
            </ng-template>
        </li>
        <li [ngbNavItem]="6">
            <a ngbNavLink class="nav-link">Override PF</a>
            <ng-template ngbNavContent>
                <app-override [componentType]="componentType.toUpperCase()" [sampleFile]="'assets/docs/sample-override-pf.xlsx'" [navLoader]="navLoader"
                [month]="month" [year]="year" [company]="company"  [permissions]="permissions"  [fileName]="fileName" [data]="uploadData" [fileSize]="fileSize"  [invalidFormat]="invalidFormat" [loader]="loader" [employeeList]="empDD" [selctedData]="selctedData" [pageSize]="pageSize" [HistoryData]="HistoryData"
                (fileChange)="onFileChange($event)" (deleteFile)="deleteFile()" (validateData)="validateData()"  (changeSelectedEmployee)="changeSelectedEmployee($event)" (addEmployee)="addEmployee()" (checkall)="checkall($event)" (deleteSingleRow)="deleteSingleRow($event)" (save)="saveData()" (deleteCompleted)="deleteCompleted($event)" (completedList)="completedList()"  (resetFilterEmit)="resetFilter()" (submitFilter)="submitFilter($event)"
                (updateValidatedData)="updateValidatedData($event)"></app-override>
            </ng-template>
        </li>
    </nav>
    <main [ngbNavOutlet]="nav"  class="config-container tabs-container">
    </main>
</div>
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
            <i class="bi bi-exclamation-triangle text-danger"></i>
            <h4 class="modal-title">Error Occured</h4>
            </div>
            <div class="modal-body">
                    <p class="mb-0">{{deleteMsg}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
                <h4 class="modal-title">{{countSelected()}} Employees selected</h4>
                <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
            </div>
            <div class="modal-body">
                <p class="mb-0">{{confirmMsgText}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="confirmMsg = false;">Cancel</button>
                <button type="button" class="btn btn-primary btn-sm text-uppercase"
                    (click)="uploadSaveData();confirmMsg = false;">Save</button>
            </div>
        </div>
    </div>
  </div>
<app-error-download [fileName]="errorFileName" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="failedData" [modalHeading]="componentType.toUpperCase()+' Data'" (closePanel)="closePanel()" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>

<app-upload-progress-bar  *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Uploading'" [body]="body" ></app-upload-progress-bar>
