import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function userpasswordValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const fieldPattern = /^[A-z0-9!@#$%^&*+=-_().]*$/.test(value);

      return !fieldPattern ? {pattern:true}: null;
  }
}

