<div class="side-panel-body" style="overflow-y: auto;">
  <div class="container-fluid p-32 ">
      <div class="col-12">
          <h5>DETAILS</h5>
      </div>
      <div class="card card-c2 p-32">
          <app-view-loader *ngIf="loader1"></app-view-loader>
          <div *ngIf="!loader1" class="row  row-16">
              <!-- <div class="col-12">
                  <div class="title">{{data?.requested_on | date : "d MMM YYYY"}}</div>
              </div> -->
              <div class="col-12 d-flex ">
                  <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
              </div>
              <div class="col-12 mt-8">
                  <h5 class="mb-8">Manage Asset
                  </h5>
                  <span class=" badge-custom {{data?.request_type_color_code}}">Manage Asset</span>
              </div>

              <ng-container *ngIf="requestitem?.return_assets?.length > 0">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">Return Asset Details</p>
                </div>
                <ng-container *ngFor="let return of requestitem?.return_assets, let ind = index">
                  <div class="col-12">
                    <div class="card details-card shadow-0 px-16">
                      <div class="card-body px-0">
                        <div class="row row-16">
                          <div class="col-sm-3 col-12">
                            <div class="detail-title">Asset name</div>
                            <div class="detail-desc">{{return?.product_name}}</div>
                          </div>
                          <div class="col-sm-3 col-12">
                            <div class="detail-title">Asset code</div>
                            <div class="detail-desc">{{return?.asset_code}}</div>
                          </div>
                          <div class="col-sm-3 col-12">
                            <div class="detail-title">Category</div>
                            <div class="detail-desc">{{return?.category_name}}</div>
                          </div>
                          <div class="col-sm-3 col-12">
                            <div class="detail-title">Subcategory</div>
                            <div class="detail-desc">{{return?.sub_category_name}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-0">
                        <div class="row row-16">

                          <div *ngIf="(return?.fnf_status == 'Pending' && return?.status == 'Pending')" class="col-12 d-flex align-items-center">
                            <div class="d-flex align-items-center ms-auto text-uppercase fw-500 fs-12 gap-8">
                              <span class="link-primary {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : '' }} " (click)="undoAsset(return?.id,'Unreturned','',ind,'return');addAssetArray(return?.id)">Unreturn</span>
                              <span class="text-light-400">|</span>
                              <span class="link-primary {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : '' }}" (click)="undoAsset(return?.id,'Returned','',ind,'return')">Return</span>
                            </div>
                          </div>

                          <ng-container *ngIf="(return?.fnf_status == 'Returned' || return?.fnf_status == 'Unreturned') && return?.status == 'Pending'" >
                            <div class="col-12 d-flex align-items-center ">
                              <div class="d-flex align-items-center gap-8 fw-500 fs-12">
                                <span class="badge px-16  {{return?.fnf_status == 'Unreturned' ? 'badge-danger' : 'badge-success'}} py-1">{{return?.fnf_status}}</span>
                              </div>
                              <div *ngIf="requestitem?.status == 'Pending'" class="d-flex align-items-center ms-auto gap-8 fs-12 fw-500 ">
                                <span *ngIf="return?.fnf_status == 'Returned'" class=" text-uppercase text-warning {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : 'pointer' }}" (click)="undoAsset(return?.id,'Undo','',ind,'return')"> <i
                                    class="icon-arrow-return-left-square me-1"></i>Undo</span>
                                <span *ngIf="return?.fnf_status == 'Unreturned'" class=" text-uppercase text-warning {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : 'pointer' }}" (click)="undoAsset(return?.id,'Undo','',ind,'return');(payrollStatus == 'Processed' || payrollStatus == 'Publish') ? '' : removeAssetArrays(return?.id);"> <i
                                      class="icon-arrow-return-left-square me-1"></i>Undo</span>
                              </div>
                            </div>
                            <div *ngIf="return?.fnf_status == 'Unreturned' && requestitem?.status == 'Accepted'" class="col-sm-3 col-12">
                              <div class="detail-title">Status</div>
                              <div class="detail-desc">{{return?.fnf_asset_status}}</div>
                            </div>
                          </ng-container>

                          <div *ngIf="return?.status == 'Invalid'" class="col-12 d-flex align-items-center ">
                            <div class="d-flex align-items-center gap-8 fw-500 fs-12">
                              <span class="badge px-16 badge-danger1 py-1">{{return?.status}}</span>
                            </div>
                          </div>

                          <ng-container *ngIf="return?.fnf_status == 'Unreturned' && requestitem?.status == 'Pending'" [formGroup]="unReturnedForm">
                            <ng-container formArrayName="assetArray" *ngFor="let item of assetArrays().controls; let j=index; ">
                              <ng-container [formGroupName]="j">

                                <div *ngIf="return?.id == assetArrays().controls[j].get('id')?.value" class="col-12">
                                  <label for="" class="form-label required">Status </label>
                                  <ng-select (change)="undoAsset(return?.id,'Unreturned',assetArrays().controls[j].get('status')?.value,'','stat')" class="form-ngselect" formControlName="status" [ngClass]="{ 'is-invalid': (submitted ||   assetArrays().controls[j].get('status')?.dirty || assetArrays().controls[j].get('status')?.touched) &&  assetArrays().controls[j].get('status')?.errors}" name=""  id="status" placeholder="{{this.messageService.selectplaceholddisplay('status')}}">
                                    <ng-option *ngFor="let item of unReturnedStatusArray" [value]="item">{{ item}}</ng-option>
                                  </ng-select>
                                  <div *ngIf="(submitted || assetArrays().controls[j].get('status')?.dirty || assetArrays().controls[j].get('status')?.touched) && assetArrays().controls[j].get('status')?.errors" class="invalid-feedback">
                                      <div *ngIf="assetArrays().controls[j].get('status')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="requestitem?.status == 'Pending'">
                <!-- <div class="col-12">
                  <ng-container [formGroup]="form" *ngIf="!requestitem?.achieved_status">
                  <textarea name="" id="" rows="3" placeholder="{{this.messageService.descriptiontext('comment')}}" formControlName="comment"
                  [ngClass]="{ 'is-invalid': f.comment.errors}" class="form-control textarea-comment"></textarea>
                  <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
                    {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                  </ng-container>
                </div> -->
                <div class="col-12 d-flex gap-16  py-16  position-sticky bottom-0 bg-white">

                  <div class="d-flex ms-auto gap-16">
                    <!-- <button (click)="confirm('Rejected')" class="btn btn-outline-danger text-uppercase btn-sm fw-400">Decline</button> -->
                    <button class="btn btn-primary text-uppercase btn-sm fw-400" (click)="confirm('Approved')">Submit</button>
                  </div>
                </div>
              </ng-container>
          </div>
      </div>
  </div>
</div>
<!-- test end -->
