import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invalid-request',
  templateUrl: './invalid-request.component.html',
  styleUrls: ['./invalid-request.component.scss']
})
export class InvalidRequestComponent implements OnInit {
  @Input() reasonForInvalid:any;

  constructor() { }

  ngOnInit(): void {
  }

}
