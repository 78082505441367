import { Component, Input, OnInit, Output,  EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-regime-comparison',
  templateUrl: './view-regime-comparison.component.html',
  styleUrls: ['./view-regime-comparison.component.scss']
})
export class ViewRegimeComparisonComponent implements OnInit {

  @Input() oldNewFlag:any;
  @Input() taxRegimeData:any;
  @Input() hra80gg:any;
  @Input() currency :any;

  currentYear: number   = new Date().getFullYear();
  nextYear: number      = (this.currentYear+1);

  constructor(
    public appservice:AppService
  ) { }

  ngOnInit(): void {
    let userData  = this.appservice.getEmpData();
    this.currency = userData.currency;
  }

  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  fieldName(data:any){
    let str;
    if(data == 'othersource_amount')
      str =  'Income from other sources';
    if(data == 'sb_account_amount')
      str =  'Interest earned through Saving Bank Account';
    if(data == 'source_80TTA_80TTB_amount')
      str =  '80TTA/ 80TTB';
    if(data == 'intrest_earned_amount')
      str =  'Interest earned from national savings certificate';
    return str;
  }

  chapter4aFieldName(data:any){
    let str;
    if(data == 'employee_contribute_pf_amount')
      str =  'Employee Contribution towards PF';
    if(data == 'ppf_amount')
      str =  'Contribution towards 15 Years PPF';
    if(data == 'POTD_amount')
      str =  'Five-year Post Office Time Deposit (POTD) Scheme';
    if(data == 'nsc_amount')
      str =  'Subscription to National Saving Scheme (NSC)';
    if(data == 'nps_amount')
      str =  'Deduction in respect of contribution to NPS (80 CCD(1))';
    if(data == 'ELSS_amount')
      str =  'Equity Linked Saving Schemes (Tax Saving Mutual Funds) ELSS';
    if(data == 'ULIP_amount')
      str =  'ULIP';
    if(data == 'interest_NSC_amount')
      str =  'Interest on National Saving Certificate';
    if(data == 'repay_house_amount')
      str =  'Repayment of Housing Principal';
    if(data == 'stampduty_amount')
      str =  'Stamp Duty paid for Purchase of House property';
    if(data == 'ssy_amount')
      str =  'Sukanya Samriddhi Yojana';
    if(data == 'nabard_rural_bond_amount')
      str =  'NABARD Rural Bonds';
    if(data == 'lic_amount')
      str =  'Life insurance premium';
    if(data == 'annuity_amount')
      str =  'Annuity plan of LIC or other insurer towards Pension Scheme (80 CCC)';
    if(data == 'contribution_vpf_amount')
      str =  'Contribution towards VPF';
    if(data == 'previous_employer_pf_amount')
      str =  'Previous Employer PF';
    if(data == 'tuition_fees')
      str =  'Children Education Tuition Fees';
    return str;
  }

  deductionFieldName(data:any){
    let str;
    if(data == 'health_insurance_selfspouse80d_amount')
      str =  '80D : Health insurance premium for Self / Spouse or Dependent Children';
    if(data == 'health_checkup_selfspouse80d_amount')
      str =  '80D : Preventive health checkup for Self / Spouse or Dependent Children';
    if(data == 'health_checkup_parents80d_amount')
      str =  '80D : Preventive health checkup for Dependent parents';
    if(data == 'medicalbill_senior80d_amount')
      str =  'Medical bills for super senior citizen';
    if(data == 'employee_contribution_amount')
      str =  '80CCD1(B): Employee’s additional contribution to NPS';
    if(data == 'tta_ttb_amount')
      str =  '80TTA: Exemption of interest from banks, post office, etc. (or) 80TTB: Exemption of interest from banks, post office, etc.';
    if(data == 'interest_education_amount')
      str =  '80E: Interest on education loan';
    if(data == 'interest_home_loan_amount80ee')
      str =  '80EE: Additional Interest on home loan for first-time homeowners borrowed between 1st April 2016 to 31st March 2017 ';
    if(data == 'interest_home_loan_amount80eea')
      str =  '80EEA: Additional Interest on home loan for first-time homeowners borrowed between 1st April 2019 to 31st March 2022';
    if(data == 'interest_electrical_vehicle_loan_amount')
      str =  '80EEB: Interest on electrical vehicle loan borrowed between 1st April 2019 to 31st March 2023';
    if(data == 'handicapped_dependent_amount')
      str =  '80 DD: Medical treatment for handicapped dependent';
    if(data == 'medical_expenditure_amount')
      str =  '80DDB: Medical Expenditure as per rule 11DD';
    if(data == 'self_suffering_amount')
      str =  '80U: Self-suffering from disability';
    if(data == 'donate_social_cause100_amount')
      str =  '80G: Deduction for donations towards social causes (100% exemption)';
    if(data == 'donate_social_cause50_amount')
      str =  '80G: Deduction for donations towards social causes (50% exemption)';
    if(data == 'royalty_patent_amount')
      str =  '80RRB: Deductions on Income by way of Royalty of a Patent';
    if(data == 'contribution_policalparties_amount')
      str =  '80GGC: Contribution by individuals to political parties';
    if(data == 'health_insurance_parents80d_amount')
      str =  '80D : Health insurance premium for Dependent parents';
    if(data == 'total_amount')
      str =  '80GGA: Deduction in respect of donations to certain funds, charitable institutions, scientific research, and rural development etc. ';
    if(data == 'employer_contribution_amount')
      str =  '80CCD(2): Employer’s contribution to NPS account, deducted from salary';
    return str;
  }

  stringToNumConversion(a:any){
    let num;
    num = Number(a);
    return num;
  }

}
