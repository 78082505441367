import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-it-declarations',
  templateUrl: './my-it-declarations.component.html',
  styleUrls: ['./my-it-declarations.component.scss']
})
export class MyItDeclarationsComponent implements OnInit {
activeTab =1;
  constructor() { }

  ngOnInit(): void {
  }

}
