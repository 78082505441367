import { Component, HostListener, OnInit } from '@angular/core';
import { MiscellaneousService } from 'src/app/@core/services/miscellaneous.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.scss']
})
export class MiscellaneousComponent implements OnInit {
  permissions:any = [];
  disabled:any = false;
  dateformaterror:any = false;
  timeformaterror:any = false;
  saveClicked =false
  loader = true
  datelist: any[] = [
    {
      dateformat: 'Select date format',
      value:''
    },
    {
      dateformat: 'American month, day and year (Eg:01/01/2013)',
      value:'MM/dd/yyyy'
    },
    {
      dateformat: 'Day, month and four digit year with dashes (Eg:10-01-2013)',
      value:'dd-MM-yyyy'

    },
    {
      dateformat: 'Day, month and four digit year with dots (Eg:10.01.2013)',
      value:'dd.MM.yyyy'

    },
    {
      dateformat: 'Day, textual month and year (Eg:04 October, 2013)',
      value:'dd MMMM,yyyy'

    },
    {
      dateformat: 'Four digit year, month and day with slashes (Eg:2013/10/01)',
      value:'yyyy/MM/dd'

    },
    {
      dateformat: 'Month abbreviation, day and year (Eg:Apr-17-2012)',
      value:'MMM-dd-yyyy'

    },
    {
      dateformat: 'Year, month abbreviation and day (Eg:2013-Dec-22)',
      value:'yyyy-MMM-dd'

    },
    {
      dateformat: 'Year, month and day with dashes (Eg:2013-03-31)',
      value:'yyyy-MM-dd'

    },

  ]
  timelist: any[] = [
    {
      timeformat: 'Select time format',
      value:''
    },
    {
      timeformat: 'Hour and minutes, with meridian (Eg:09:10 AM)',
      value:'hh:mm a'
    },
    {
      timeformat: 'Hour and minutes, 24 Hours Notation (Eg:22:10)',
      value:'HH:mm'
    },
    {
      timeformat: 'Hour, minutes and seconds, with meridian (Eg:09:10:10 AM)',
      value:'hh:mm:ss a'
    },
    {
      timeformat: 'Hour, minutes and seconds, 24 Hours Notation (Eg:15:10:55)',
      value:'HH:mm:ss'
    }
  ]
  selectedTime :any;
  selectedDate :any;
  saveselectedTime :any;
  saveselectedDate :any;
  datekey :any;
  timekey :any;

  constructor(
    private miscellaneousService :MiscellaneousService,
    private notificationService: NotificationService,
    public messageService : MessageService,
    private appService:AppService
  ) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPermission();
    }, 1500);
      this.getfulldata();

  }

  disabledValidation(selectedValue1:any = '',selectedValue2:any = '', validation:any = ''){
    if(validation == 1){
      if(selectedValue1 == '' || selectedValue1 == null)
      this.disabled = true;
      else
      this.disabled = false;

      return this.disabled;
    }
    if(validation == 2){
      if(selectedValue2 == '' || selectedValue2 == null)
      this.disabled = true;
      else
      this.disabled = false;

      return this.disabled;
    }

    if(selectedValue1 == '' || selectedValue2 == '' || selectedValue1 == null || selectedValue2 == null){
      this.disabled = true;
    }else
    this.disabled = false;

    return this.disabled
  }


  datetimeChange(key1:any,selectedValue1:any = '',key2:any,selectedValue2:any = ''){

    this.datekey    = key1;
    this.timekey    = key2;
    if(selectedValue1 != this.saveselectedDate || selectedValue2 != this.saveselectedTime){
      this.saveClicked =true
      this.miscellaneousService.miscellaneousSave({'setting_list':JSON.stringify([{'settings':this.datekey, 'value': selectedValue1}, {'settings':this.timekey, 'value': selectedValue2}])}).subscribe((res: any) => {
          if(res.status == 200){
            this.saveselectedDate = selectedValue1;
            this.saveselectedTime = selectedValue2;
            this.saveClicked =false
            this.notificationService.handleSuccessNotification("Saved Successfully","Success");
          }else{
            this.notificationService.handleErrorNotification("Error","Error");
            this.saveClicked =false
          }
          //Setting date and time format globally
        this.miscellaneousService.miscellaneousGet().subscribe((res: any) => {
          for(let i = 0; i < res.body.length; i++){
            if (res.body[i].setting=='date') {
              this.appService.setdateformat(res.body[i].value)
            }
            if (res.body[i].setting=='time') {
              this.appService.settimeformat(res.body[i].value)
            }
          }
        });
      });
    }

  }

  getfulldata(){
    this.miscellaneousService.miscellaneousGet().subscribe((res: any) => {
    for(let i = 0; i < res.body.length; i++){

      if (res.body[i].setting=='date') {

        this.selectedDate = res.body[i].value;
        this.saveselectedDate = res.body[i].value;
      }
      if (res.body[i].setting=='time') {

        this.selectedTime = res.body[i].value;
        this.saveselectedTime = res.body[i].value;

      }
    }
    this.loader = false
    });
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
