import { Component, OnInit, EventEmitter, Input, Output, ViewChild, SimpleChanges } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { AppService } from 'src/app/app.global';
import { ChallanService } from 'src/app/@core/services/challan.service';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
  selector: 'app-challan-map-employees',
  templateUrl: './challan-map-employees.component.html',
  styleUrls: ['./challan-map-employees.component.scss']
})

export class ChallanMapEmployeesComponent implements OnInit {

  @Input()empMapList: any;
  @Input()formGp: any;
  @Input()currency: any;
  @Input()company: any;
  @Input()selectedList1: any;
  @Input()items: any;
  @Input()loader1 :any;
  @Input()excessFlag :any;
  @Input()excessAmount :any;
  @Input()monthId : any;
  @Input()updateFlag : any;
  @Input()updateId    : any;

  @Output()submitmethod   = new EventEmitter();
  @Output() filterMethod  = new EventEmitter();
  @Output() countSelect  = new EventEmitter();


  searchString:any        = "";
  selectedList:any        = [];
  selectedEmpData:any     = [];
  filter                  = false;
  buList:any              = [];
  DepList:any             = [];
  DesignationList:any     = [];
  BranchList:any          = [];
  cityList:any            = [];
  gradeList:any           = [];
  page                    = 1;
  pageSize                = 20;
  amt:any                 = 0;
  editId:any              = 0;
  alertToggle             = false;
  empalertMsg             = "";
  inputdata:any;
  balnceAmount            = 0;
  isAdjustAmt             = false;
  amountExceed            = false;

  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(
    public messageService: MessageService,
    public appService: AppService,
    public challanService:ChallanService,
    public router: Router,
    public route:ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if((params['edit'])){
        if (!isNaN(params['editid'])) {
          this.editId = params['editid'];
        }
      }else if((params['adjustAmount'])){
        this.isAdjustAmt = true;
      }
      else{
        if (!isNaN(params['id'])) {
          // this.empMapListFunction();
        }
      }
    });
    this.createSelectedLIst('onInit');
  }


  /********* GET TABLE FILTER COUNT FUNCTION ****************/
  filteredcount(){
    return this.filterIt().length;
  }

  /********* TABLE FILTER FUNCTION ****************/
  filterIt() {
    return this.items.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey     = obj[key].toString().toLowerCase();
          const tempSearch  = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  /********* GET SELECTED EMPLOYEE COUNT FUNCTION ****************/
  countSelected(){
    return this.items.filter((obj :any) => obj.is_employee_mapped === true).length;
  }

  /********* CREATE SELECTED EMPLOYEE LIST ****************/
  createSelectedLIst(type:any){
    this.selectedList     = [];
    this.selectedEmpData  = [];
    this.amt              = 0;

    this.items.forEach((item:any, key:any) => {
      if(item.is_employee_mapped == true){
        this.amt += item.mapped_amount;

        // Non adjustment case
        if(!this.isAdjustAmt && this.formGp.value.amount >= this.amt){
        this.selectedList.push({"payroll_status":item.payroll_status_id,'actual_amount':item.pay_tax,'mapped_amount':item.mapped_amount});
        this.selectedEmpData.push({"img":item.profile_image,"empName":item.name,"empCode":item.employee_code,"color_code":item?.color_code})
         this.submitmethod.emit({'selectedList': this.selectedList,'selectedEmpData': this.selectedEmpData,'type': type,'valid': true});
         }else if(this.isAdjustAmt && this.formGp.value?.challan_excess_amount >= this.formGp.value?.amount){
          // Adjustment case
          this.selectedList.push({"payroll_status":item.payroll_status_id,'actual_amount':item.pay_tax,'mapped_amount':item.mapped_amount});
          this.selectedEmpData.push({"img":item.profile_image,"empName":item.name,"empCode":item.employee_code,"color_code":item?.color_code})
           this.submitmethod.emit({'selectedList': this.selectedList,'selectedEmpData': this.selectedEmpData,'type': type,'valid': true});
           }else{
            if(type != 'checkOrUncheck'){
              this.empalertMsg = "Total income tax amount of mapped employees is exceeding challan amount.";
              this.alertToggle = true;
              this.amountExceed = true;
              this.submitmethod.emit({'selectedList': this.selectedList,'selectedEmpData': this.selectedEmpData,'type': type,'valid': false});
            }
        }
      }
    });
  }

  /********* SUBMIT FILTER ****************/
  submitForm(data:any){
    this.searchString     = '';
    this.filter           = false
    this.filtercomp.getBUList(this.company);
    this.buList           = data.buList;
    this.DepList          = data.DepList;
    this.DesignationList  = data.DesignationList;
    this.gradeList        = data.gradeList;
    this.BranchList       = data.BranchList;
    // this.empMapListFunction();
    this.filterMethod.emit({'bu':this.buList ,'dept':this.DepList,'desig':this.DesignationList,'grade':this.gradeList,'branch':this.BranchList})
  }

  /********* RESET FILTER ****************/
  resetFilter(){
    this.searchString     = '';
    this.buList           = [];
    this.DepList          = [];
    this.DesignationList  = [];
    this.gradeList        = [];
    this.BranchList       = [];
    // this.empMapListFunction();
    this.filterMethod.emit({'bu':this.buList ,'dept':this.DepList,'desig':this.DesignationList,'grade':this.gradeList,'branch':this.BranchList})
    this.filter           = false;
  }

  /********* CLOSE FILTER ****************/
  closeFilter(){
    this.filter   = false;
  }

  selectAll(){
    this.inputdata = this.appService.selectAll(this.items);
  }

  unselectAll(){
    this.inputdata = this.appService.unselectAll(this.items);
  }

  /********* CHECK ALL FUNCTIONS ****************/
  checkall(event:any){
    var newList  = this.filterIt();
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);

    subArray.forEach((item:any, key:any) => {
     if(event.target.checked == true){
      // Normal case
      if(!this.isAdjustAmt && this.balnceAmount == 0){
        event.target.checked = false;
        // item.is_employee_mapped = false;
        this.empalertMsg     = "Total income tax amount of mapped employees is exceeding challan amount.";
        this.alertToggle     = true;
        this.amountExceed    = true;
      }else if(this.isAdjustAmt && (this.formGp.get('challan_excess_amount')?.value < this.formGp.get('amount')?.value)){

        // Adjustment case
        event.target.checked = false;
        // item.is_employee_mapped = false;
        this.empalertMsg     = "Total income tax amount of mapped employees is exceeding challan amount.";
        this.alertToggle     = true;
        this.amountExceed    = true;
      }
      else{
        if(this.excessFlag == true){

          // Adjustment case
          this.formGp.get('amount')?.setValue(Number(this.formGp.get('challan_excess_amount')?.value));
          this.formGp.get('tds')?.setValue(Number(this.formGp.get('challan_excess_amount')?.value));
          this.makeSetAsZero();
          // this.challanService.challanCreateEmpMapListFilter(this.excessAmount,this.monthId,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
          //  .subscribe((res: any) => {
          this.challanService.challanCreateEmpMapListFilter(this.formGp?.value?.amount,this.monthId,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
            .subscribe((res: any) => {
             this.items = res;
             this.items.forEach((data:any)=>{
               item.is_employee_mapped = data.is_employee_mapped;
              })
            })
        }else{
          if(this.updateFlag == false){
            // Add case
            this.challanService.challanCreateEmpMapListFilter(this.formGp?.value?.amount,this.formGp?.value?.challan_runpayroll,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
            .subscribe((res: any) => {
              this.items = res;
              this.items.forEach((data:any)=>{
                item.is_employee_mapped = data.is_employee_mapped;
              })

            })
        }
        else{

          // Edit case
          this.challanService.challanUpdateAmountListFilter(this.updateId,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
          .subscribe((res: any) => {
            this.items = res;
            this.items.forEach((data:any)=>{
              item.is_employee_mapped = data.is_employee_mapped;

            })
          });
        }
      }
    }
     }else{
          //  Adjustment case
          if(this.isAdjustAmt){
            this.formGp.get('amount')?.setValue(0);
            this.formGp.get('tds')?.setValue(0);
            this.makeSetAsZero();
          }

          item.is_employee_mapped = event.target.checked;
          item.mapped_amount = 0;
          if(this.countSelected() == 0){
           this.countSelect.emit()
        }
      }

    this.createSelectedLIst('checkAll');
  })

  }

  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item:any) => {
       return item.is_employee_mapped;

      });
    } else {
      return false;
    }
  }



  /********* TO CALCULATE BALANCE AMOUNT ****************/
  balanceAmount(){
    let balanceAmt = 0;
    let netIncome = 0;
    this.items.filter((item:any, key:any) => {
      if(item.is_employee_mapped == true){
        netIncome += item.mapped_amount;
      }
    })
    if(this.excessFlag == false){
    balanceAmt = Number(this.formGp.value.amount) - Number(netIncome);
    this.balnceAmount = balanceAmt;
    }else{
      // Adjustment case

      // balanceAmt = Number(this.excessAmount) - Number(netIncome);
      balanceAmt = Number(this.formGp.value?.amount) - Number(netIncome);
      this.balnceAmount = balanceAmt;
    }

    return this.balnceAmount;
  }

  // function for check and uncheck
  checkUncheck(event:any,item:any){
    if(event.target.checked == false){
        if(this.isAdjustAmt){
          let newAmt = this.formGp.get('amount')?.value - item?.mapped_amount;
          this.formGp.get('amount')?.setValue(Number(newAmt));
          this.formGp.get('tds')?.setValue(Number(newAmt));
          // this.excessAmount = newAmt;
          this.makeSetAsZero()
        }
        item.is_employee_mapped = false;
        item.mapped_amount = 0;
        if(this.countSelected() == 0){
          this.countSelect.emit()

        }
    }else{
      item.is_employee_mapped = true;

        //  Adjustment case
        if(this.isAdjustAmt){

          let excessBalance =  this.formGp.get('challan_excess_amount')?.value - this.formGp.get('amount')?.value;
          if(item?.pay_tax >= excessBalance){
            item.mapped_amount = excessBalance;
          } else{
            item.mapped_amount = item?.pay_tax;
          }

          let newAmt = this.formGp.get('amount')?.value + item?.mapped_amount;
          this.formGp.get('amount')?.setValue(Number(newAmt));
          this.formGp.get('tds')?.setValue(Number(newAmt));
          // this.excessAmount = newAmt;
          this.makeSetAsZero();

          if((this.formGp.get('challan_excess_amount')?.value < this.formGp.get('amount')?.value) || excessBalance == 0){
            event.target.checked = false;
            item.is_employee_mapped = false;
            this.empalertMsg = "Total income tax amount of mapped employees is exceeding challan amount.";
            this.alertToggle = true;
            this.amountExceed = true;
          }

          this.createSelectedLIst('checkOrUncheck');

        }else{
          // Normal cases
          if(item?.pay_tax >= this.balnceAmount){
            item.mapped_amount = this.balnceAmount;
          }
          else{
            item.mapped_amount = item?.pay_tax;
          }
          if(this.balnceAmount == 0){
            event.target.checked = false;
            item.is_employee_mapped = false;
            this.empalertMsg = "Total income tax amount of mapped employees is exceeding challan amount.";
            this.alertToggle = true;
            this.amountExceed = true;
          }

          this.createSelectedLIst('checkOrUncheck');

        }

    }
  }

  // For adjustment cases
  makeSetAsZero(){
    this.formGp.get('surcharge').setValue(0);
    this.formGp.get('education_cess').setValue(0);
    this.formGp.get('intrest').setValue(0);
    this.formGp.get('others').setValue(0);
    this.formGp.get('late_pay_fee').setValue(0);
  }
  // End

}
