<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="dailyAttendance" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <div class="row row-16 mb-16">
                    <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.attendance_date.errors}">
                        <div class="col-12 form-row">
                            <p class="text-uppercase fw-500 mb-0 required">Attendance Day</p>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_date" type="radio" id="attendance_date1"
                                    class="form-check-input  " formControlName="attendance_date" value="today"
                                    (change)="radioBtnChangeFunctions($event,'attendance_date','today','specific_month')">
                                <label for="attendance_date1" class="form-check-label">Today</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_date" type="radio" id="attendance_date2"
                                    class="form-check-input " formControlName="attendance_date" value="yesterday"
                                    (change)="radioBtnChangeFunctions($event,'attendance_date','yesterday','specific_month')">
                                <label for="attendance_date2" class="form-check-label">Yesterday</label>
                            </div>
                        </div>

                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_date" type="radio" id="attendance_date3"
                                    class="form-check-input " formControlName="attendance_date" [value]="true"
                                    (change)="radioBtnChangeFunctions($event,'attendance_date',true,'specific_month')">
                                <label for="attendance_date3" class="form-check-label">Select date</label>
                            </div>
                        </div>
                        <ng-container *ngIf="dailyAttendance.value.attendance_date==true">
                            <div class="input-group form-icon icon-right">

                                <input (click)="datePicker1.open()" class="form-control" [min]="minDate" [max]="maxDate"
                                    placeholder="{{this.messageService.selectplaceholddisplay('date')}}" matInput
                                    formControlName="specific_month" [matDatepicker]="datePicker1">

                                <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="(submitted) && f.attendance_date.errors" class="invalid-feedback">
                        <div *ngIf="f.attendance_date.errors.required">
                            {{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <label for="" class="form-label ">Attendance Status </label>
                        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('request status')}}"
                            formControlName="attendance_status" [items]="attendanceStatus" [multiple]="true"
                            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> Select All
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{ standalone : true }" />
                                {{ item.name }}
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="dailyAttendance.value.attendance_status?.length ">
                                    <span class="ng-value-label">{{dailyAttendance.value.attendance_status?.length}}
                                        attendance status
                                        selected</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                            </ng-template>
                        </ng-select>

                        <ul class="selected-values mt-16" *ngIf="dailyAttendance.value.attendance_status?.length != 0">
                            <li class="ng-value"
                                *ngFor="let item of dailyAttendance.value.attendance_status,let ki =index">
                                <ng-container *ngFor="let insideitem of attendanceStatus, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['name']==item">
                                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                        slice:0:15)+'..':(insideitem['name']) }}</span>
                                </ng-container>
                                <span class="ng-value-icon right"
                                    (click)="clearcommonForm('attendStat',ki)" aria-hidden="true">×</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 form-row">
                        <label for="" class="form-label ">Late or Early</label>
                        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('request status')}}"
                            formControlName="late_or_early" [items]="lateEarlyArr" [multiple]="true"
                            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> Select All
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{ standalone : true }" />
                                {{ item.name }}
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="dailyAttendance.value.late_or_early?.length ">
                                    <span class="ng-value-label">{{dailyAttendance.value.late_or_early?.length}}
                                        status
                                        selected</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                            </ng-template>
                        </ng-select>

                        <ul class="selected-values mt-16" *ngIf="dailyAttendance.value.late_or_early?.length != 0">
                            <li class="ng-value" *ngFor="let item of dailyAttendance.value.late_or_early,let ki =index">
                                <ng-container *ngFor="let insideitem of lateEarlyArr, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['name']==item">
                                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                        slice:0:15)+'..':(insideitem['name']) }}</span>
                                </ng-container>
                                <span class="ng-value-icon right"
                                    (click)="clearcommonForm('lateStatus',ki)" aria-hidden="true">×</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage();submitdailyAttendance()">
                Apply
            </button>
        </footer>
    </form>
</div>
