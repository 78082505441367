<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16 mb-16">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">VARIABLE PAYMENTS</p>
            </div>
            <ng-container formArrayName="components" *ngFor="let item of componentArray().controls; let i=index; ">
              <ng-container [formGroupName]="i">
                <div class="col-12 d-flex">
                    <div class="fw-500">Component {{i + 1}}</div>
                    <a *ngIf="i!=0" class="link-danger ms-auto" (click)="removeComponentArray(i)"><i class="icon-trash "></i></a>
                </div>

                <div class=" col-lg-6 form-row">
                    <label for="" class="form-label required">Component </label>
                    <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('component')}}" id="pay_component" formControlName="pay_component" name="pay_component"
                    [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('pay_component')?.dirty || componentArray().controls[i].get('pay_component')?.touched) &&  componentArray().controls[i].get('pay_component')?.errors}" (change)="componentChangeFunction($event,i)"  [items]="otherPaymentsData" bindValue="component_id" bindLabel="component_name">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="text-trim" title="{{item.component_name}}">{{item.component_name}}</div></ng-template>
                    </ng-select>
                    <div *ngIf="(componentArray().controls[i].get('pay_component')?.dirty || componentArray().controls[i].get('pay_component')?.touched) && componentArray().controls[i].get('pay_component')?.errors" class="invalid-feedback">
                      <div *ngIf="componentArray().controls[i].get('pay_component')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                </div>

                <div class=" col-lg-6 form-row">
                    <label for="" class="form-label">Formula </label>
                    <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('formula')?.dirty || componentArray().controls[i].get('formula')?.touched) &&  componentArray().controls[i].get('formula')?.errors}">
                      <input [readonly]="true" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('formula')?.dirty || componentArray().controls[i].get('formula')?.touched) &&  componentArray().controls[i].get('formula')?.errors}" type="text" class="form-control" autocomplete="off" id="formula" formControlName="formula">
                    </div>
                    <div
                    *ngIf="(componentArray().controls[i].get('formula')?.dirty || componentArray().controls[i].get('formula')?.touched) && componentArray().controls[i].get('formula')?.errors"
                    class="invalid-feedback">
                    <div *ngIf="componentArray().controls[i].get('formula')?.errors?.required">
                      {{this.messageService.validationDisplay('required')}}</div>
                  </div>
                </div>

                <div *ngIf="componentArray().controls[i].get('pay_component_type')?.value == 'Leave Encashment'" class=" col-lg-6 form-row">
                  <label for="" class="form-label required">Leave encashment days </label>
                  <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('no_of_days')?.dirty || componentArray().controls[i].get('no_of_days')?.touched) &&  componentArray().controls[i].get('no_of_days')?.errors}">
                    <input [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('no_of_days')?.dirty || componentArray().controls[i].get('no_of_days')?.touched) &&  componentArray().controls[i].get('no_of_days')?.errors}" type="text" class="form-control" autocomplete="off" id="no_of_days" placeholder="{{this.messageService.placeholderdisp('Leave encashment days')}}" formControlName="no_of_days">
                  </div>
                  <div
                  *ngIf="(componentArray().controls[i].get('no_of_days')?.dirty || componentArray().controls[i].get('no_of_days')?.touched) && componentArray().controls[i].get('no_of_days')?.errors"
                  class="invalid-feedback">
                  <div *ngIf="componentArray().controls[i].get('no_of_days')?.errors?.required">
                    {{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="componentArray().controls[i].get('no_of_days')?.errors?.pattern">
                    {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                    *ngIf="!(componentArray().controls[i].get('no_of_days')?.errors?.pattern) && componentArray().controls[i].get('no_of_days')?.errors?.max">
                    {{this.messageService.balanceAmountValidation()}}
                  </div>
                </div>
                </div>

                <div class=" col-lg-6 form-row">
                    <label for="" class="form-label required">{{componentArray().controls[i].get('pay_component_type')?.value == 'Leave Encashment' ? 'Leave encashment amount' : 'Variable as per salary structure'}} </label>
                    <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('pay_variable')?.dirty || componentArray().controls[i].get('pay_variable')?.touched) &&  componentArray().controls[i].get('pay_variable')?.errors}">
                      <input [readonly]="true" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('pay_variable')?.dirty || componentArray().controls[i].get('pay_variable')?.touched) &&  componentArray().controls[i].get('pay_variable')?.errors}" type="text" class="form-control" autocomplete="off" id="pay_variable" formControlName="pay_variable">
                      <span>{{currency}}</span>
                    </div>
                    <div
                    *ngIf="(componentArray().controls[i].get('pay_variable')?.dirty || componentArray().controls[i].get('pay_variable')?.touched) && componentArray().controls[i].get('pay_variable')?.errors"
                    class="invalid-feedback">
                    <div *ngIf="componentArray().controls[i].get('pay_variable')?.errors?.required">
                      {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="componentArray().controls[i].get('pay_variable')?.errors?.pattern">
                      {{this.messageService.validationDisplay('pattern')}}</div>
                    <div
                      *ngIf="!(componentArray().controls[i].get('pay_variable')?.errors?.pattern) && componentArray().controls[i].get('pay_variable')?.errors?.max">
                      {{this.messageService.balanceAmountValidation()}}
                    </div>
                  </div>
                </div>

                <div class=" col-lg-6 form-row" *ngIf="componentArray().controls[i].get('pay_component_type')?.value != 'Leave Encashment'">
                    <label for="" class="form-label required">Pay variable </label>
                    <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('payable_amount')?.dirty || componentArray().controls[i].get('payable_amount')?.touched) &&  componentArray().controls[i].get('payable_amount')?.errors}">
                      <input [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('payable_amount')?.dirty || componentArray().controls[i].get('payable_amount')?.touched) &&  componentArray().controls[i].get('payable_amount')?.errors}" type="text" class="form-control" autocomplete="off" id="payable_amount" placeholder="{{this.messageService.placeholderdisp('Pay variable')}}" formControlName="payable_amount">
                      <span>{{currency}}</span>
                    </div>
                    <div
                    *ngIf="(componentArray().controls[i].get('payable_amount')?.dirty || componentArray().controls[i].get('payable_amount')?.touched) && componentArray().controls[i].get('payable_amount')?.errors"
                    class="invalid-feedback">
                    <div *ngIf="componentArray().controls[i].get('payable_amount')?.errors?.required">
                      {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="componentArray().controls[i].get('payable_amount')?.errors?.pattern">
                      {{this.messageService.validationDisplay('pattern')}}</div>
                    <div
                      *ngIf="!(componentArray().controls[i].get('payable_amount')?.errors?.pattern) && componentArray().controls[i].get('payable_amount')?.errors?.max">
                      {{this.messageService.balanceAmountValidation()}}
                    </div>
                    <div
                      *ngIf="!(componentArray().controls[i].get('payable_amount')?.errors?.pattern) && componentArray().controls[i].get('payable_amount')?.errors?.maxlength">
                      {{this.messageService.fieldlengthvalidation('num','10')}}
                    </div>
                  </div>
                </div>

                <div *ngIf="componentArray().controls[i].get('pay_component_type')?.value == 'Leave Encashment'" class=" col-lg-6 form-row">
                  <label for="" class="form-label required">Leave encashment amount payable </label>
                  <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('encashment_amount')?.dirty || componentArray().controls[i].get('encashment_amount')?.touched) &&  componentArray().controls[i].get('encashment_amount')?.errors}">
                    <input [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('encashment_amount')?.dirty || componentArray().controls[i].get('encashment_amount')?.touched) &&  componentArray().controls[i].get('encashment_amount')?.errors}" type="text" class="form-control" autocomplete="off" id="encashment_amount" placeholder="{{this.messageService.placeholderdisp('Leave encashment amount payable')}}" formControlName="encashment_amount">
                    <span>{{currency}}</span>
                  </div>
                  <div
                  *ngIf="(componentArray().controls[i].get('encashment_amount')?.dirty || componentArray().controls[i].get('encashment_amount')?.touched) && componentArray().controls[i].get('encashment_amount')?.errors"
                  class="invalid-feedback">
                  <div *ngIf="componentArray().controls[i].get('encashment_amount')?.errors?.required">
                    {{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="componentArray().controls[i].get('encashment_amount')?.errors?.pattern">
                    {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                    *ngIf="!(componentArray().controls[i].get('encashment_amount')?.errors?.pattern) && componentArray().controls[i].get('encashment_amount')?.errors?.max">
                    {{this.messageService.balanceAmountValidation()}}
                  </div>
                </div>
                </div>


                <div class=" col-lg-6 form-row" *ngIf="componentArray().controls[i].get('pay_component_type')?.value == 'Leave Encashment'">
                  <label for="" class="form-label required">Leave encashment excemption amount</label>
                  <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('exception_amount')?.dirty || componentArray().controls[i].get('exception_amount')?.touched) &&  componentArray().controls[i].get('exception_amount')?.errors}">
                    <input [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('exception_amount')?.dirty || componentArray().controls[i].get('exception_amount')?.touched) &&  componentArray().controls[i].get('exception_amount')?.errors}" type="text" class="form-control" autocomplete="off" id="exception_amount" placeholder="{{this.messageService.placeholderdisp('excemption amount')}}" formControlName="exception_amount">
                    <span>{{currency}}</span>
                  </div>
                  <div
                  *ngIf="(componentArray().controls[i].get('exception_amount')?.dirty || componentArray().controls[i].get('exception_amount')?.touched) && componentArray().controls[i].get('exception_amount')?.errors"
                  class="invalid-feedback">
                  <div *ngIf="componentArray().controls[i].get('exception_amount')?.errors?.required">
                    {{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="componentArray().controls[i].get('exception_amount')?.errors?.pattern">
                    {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                    *ngIf="!(componentArray().controls[i].get('exception_amount')?.errors?.pattern) && componentArray().controls[i].get('exception_amount')?.errors?.max">
                    {{this.messageService.balanceAmountValidation()}}
                  </div>
                </div>
              </div>


                <div class=" col-12">
                    <label for="" class="form-label ">Comments </label>
                    <textarea class="form-control" [ngClass]="{ 'is-invalid': (componentArray().controls[i].get('comments')?.dirty || componentArray().controls[i].get('comments')?.touched) &&  componentArray().controls[i].get('comments')?.errors}" rows="4" placeholder="Write comments here..." formControlName="comments"></textarea>
                    <div
                    *ngIf="(componentArray().controls[i].get('comments')?.dirty || componentArray().controls[i].get('comments')?.touched) && componentArray().controls[i].get('comments')?.errors"
                    class="invalid-feedback">
                    <div *ngIf="componentArray().controls[i].get('comments')?.errors?.required">
                      {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="componentArray().controls[i].get('comments')?.errors?.maxlength">
                      {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="col-12 mt-8">
              <div class="link-primary1 fs-14" (click)="addComponentArray()">
                  <i class="icon-plus-square-fill me-8"></i>Add component
              </div>
          </div>
            <div class="col-12 d-flex">
                <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid || saveClicked" (click)="saveComponents()">SAVE</button>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="submitOtherPayments()">NEXT</button>
    </footer>
</form>
