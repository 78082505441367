import { Component, OnInit ,ViewChild} from '@angular/core';
import * as XLSX from 'xlsx';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';


@Component({
  selector: 'app-arrear-process',
  templateUrl: './arrear-process.component.html',
  styleUrls: ['./arrear-process.component.scss']
})
export class ArrearProcessComponent implements OnInit {
  status              = "Pending"
  fileName: string    = '';
  fileSize: string    = '';
  searchString:any    = "";
  readData:any        = [];
  inputData:any       = [];
  preparedData:any    = [];
  successData:any     = [];
  permissions:any     = [];
  ws:any              = null;
  invalidFormat       = false;
  page                = 1;
  pageSize            = 20;
  showSidePanel       = false;
  submiited           = false;
  processList:any     = [];
  confirmMsg          = false;
  confirmMsgText      = "";
  saveComplete        = false;
  alertToggle         = false;
  historyData:any     = [];
  loader              = false
  empalertMsg         = ""
  errorToggle         = false;
  errorCount          = 0;
  completed           = false;
  saveData:any        = [];
  isProgressStart     = false
  chunkLen            = 10
  percentage:any      = 0;
  iscompleted         = false;
  isDataUpload        = 0;
  successDataCount    = 0;
  failedData:any      = [];
  body                = '';
  actionMsg           = 'processed'
  exportData:any      = []
  downloadMsg         = false;
  filter              = false;
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  hasError            = false;


  purpose:any
  errorReason:any
  lopMonth:any
  lopYear:any
  successMsg:any;
  errorMsg:any
  year:any;
  month:any;
  company:any
  businessunit:any;
  department:any;
  designation:any;
  branch:any;
  grade:any;
  inputdata :any;

  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(
    public sds:SalaryDashboardService,
    private notificationService: NotificationService,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService : MessageService, 
    public previousRouteService :PreviousRouteService

  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      this.getPendingArrearData();
    })
  }
  getPermission(){
    if(this.previousRouteService.getPreviousUrl() == '/attendance-dashboard'){
      this.permissions = this.appService.getPermissions('/attendance-dashboard');
    }else{
      this.permissions = this.appService.getPermissions('/run-payroll');
    }
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getPendingArrearData(){
    this.loader     = true;
    this.actionMsg  = 'processed'

    this.sds.arrearProcessList(this.company,this.year,this.month,this.status,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{

      this.processList = res.body.data;
      this.loader      = false;


    })
  }
  getCompletedArrearData(){
    this.loader     = true;
    this.actionMsg  = 'unprocessed'

    this.sds.arrearProcessList(this.company,this.year,this.month,'Completed',this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{

      this.historyData = res.body.data;
      this.loader      = false;


    })
  }

  onFileChange(evt: any) {

    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string    = wb.SheetNames[0];
        this.ws                 = wb.Sheets[wsname];
        var ws                  = wb.Sheets[wsname];
        this.fileName           = target.files[0].name;
        this.fileSize           = this.appService.formatSizeUnits(target.files[0].size)
        this.readData           = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }
  processData(){

    if(this.readData.length <=1){
      this.errorToggle = true;
      this.errorMsg    = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.readData[0];
      let Heading = ['Employee Code','Employee Name','Salary Month','Salary Year', 'Arrear Month','Arrear Year','Arrear Days','Comment']
      let success = Heading.every(function(val:any) {

        return headerRow.indexOf(val) !== -1;
      });

      if(success == false){

        this.hasError       = true;
        this.errorReason    = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
      this.prepareUploadData();
    }
  }
  prepareUploadData(){
    this.inputData = [];
    this.readData.forEach((item:any, key:any) => {
      if(key != 0 && item[2]!= undefined){
        if(this.month == item[2] && this.year == item[3]){
          this.inputData.push({

            "employee"      : item[0]?item[0]:"",
            "emp_name"      : item[1]?item[1]:"",
            "process_month" : item[2]?item[2]:"",
            "process_year"  : item[3]?item[3]:"",
            "arrear_month"  : item[4]?item[4]:"",
            "arrear_year"   : item[5]?item[5]:"",
            "arear_days"    : parseInt(item[6]?item[6]:0),
            "remark"        : item[7]?item[7]:"",

          })
        }

      }
    })
    if(this.inputData.length <=0){
      this.hasError       = true;
      this.errorReason    = "The uploaded file does not contain any data for "+this.month+" "+this.year;
      this.clearData();
    }

  }
  checkSuccessData(){
    if(this.successData.length==0)
      this.clearData()
  }

 validateData(i:any){
    let index       = i;
    let arrear_days = this.historyData[index]['arrear_days']
    const digitdecimalpattern = /^-?[0-9]?[0-9]?(\.[0-9][0-9]?)?$/.test(arrear_days);

    if(!digitdecimalpattern){
      this.historyData[index]['error']   = true;
      this.historyData[index]['msg']     = this.messageService.validationDisplay('pattern');
    }
    else if(arrear_days==''){
      this.historyData[index]['error']   = false;
      this.historyData[index]['msg']     = '';

    }
    if(digitdecimalpattern){
      this.historyData[index]['error']   = false;
      this.historyData[index]['msg']     = '';
    }

  }


  filterIt() {
    if(this.status=='Pending'){
      return this.processList?.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    } else {
      return this.historyData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
  }
  filteredcount(){
    return this.filterIt().length;
  }
  importProcessArrear(){
    this.isDataUpload = 1;
    this.iscompleted  = false
    this.loader       = true
    this.saveComplete = true;
    this.chunkLen     = 10;
    this.percentage   = 0;

    if(this.inputData.length>0){
        this.isProgressStart  = true
        this.failedData       = []
        var l                 = this.inputData.length;
        var cLoop             = Math.ceil(l/this.chunkLen)
        var p                 = 100/cLoop
        this.successDataCount = 0
        this.processUpload(0,p);
    } else
    this.loader       = false
    this.alertToggle  = false;
  }

processUpload(k:any,p:any){
  var l = this.inputData.length;
  var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
  var saveDataArray:any = [];
  this.failedData       = [];
  this.percentage +=(p/4);
  for(var i= k*this.chunkLen; i<x; i++){
    saveDataArray.push(this.inputData[i])
  }

  this.sds.importArrear({'company_id':this.company,"data":saveDataArray}).subscribe((res:any)=>{

    this.body = x+' out of '+l+" data uploaded successfully"
    this.confirmMsg = false;
    if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
      res.body.failed_data.forEach((i:any, k:any) => {
            this.failedData.push(i)
        });
    }
    this.percentage +=((p/4)*3);
    if(x<l)
        this.processUpload(k+1,p);
    else{
          this.iscompleted = true
          this.isProgressStart = false
          this.successDataCount = this.inputData.length- this.failedData.length;
          if(this.failedData.length == 0){
            this.notificationService.handleSuccessNotification(this.successDataCount+" employee arrear data uploaded successfully.","Success");

          } else {
          this.exportData    = [];
          for (let i = 0; i < this.failedData.length; i++) {
            ['Employee Code','Employee Name','Salary Month','Salary Year', 'Arrear Month','Arrear Year','Arrear Days','Comment']
            this.exportData.push({'Employee Code':this.failedData[i]['data']['employee'],'Employee Name':this.failedData[i]['data']['emp_name'],'Salary Month':this.failedData[i]['data']['salary_month'], 'Salary Year':this.failedData[i]['data']['salary_year'],'Arrear Month':this.failedData[i]['data']['arrear_month'], 'Arrear Year':this.failedData[i]['data']['arrear_year'],'Arrear Days':this.failedData[i]['data']['arear_days'],'Comment':this.failedData[i]['data']['remark'],'Error description':this.failedData[i]['error']});
          }
            this.successMsg   = this.successDataCount+" out of "+l+" data uploaded successfully "
            this.errorMsg     = this.failedData.length+' employee(s) have error data'
            this.downloadMsg  = true;
        }
       this.checkAndCallLandingScreen();
       this.clearData();
    }
    this.showSidePanel = false;
  },
  (error:any)=>{
    this.isProgressStart = false
    this.notificationService.handleErrorNotification('Something went wrong','Error')
  }
  )
}


  clearData(){

    this.page          = 1;
    this.fileName      = '';
    this.fileSize      = '';
    this.saveData      = [];
    this.saveComplete  = false;
    this.submiited     = false;

  }

  checkall(event:any){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkallSelected(){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item: { checked: any; }) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }


  countSelected(){
    if(this.status=='Pending')
    return this.processList.filter((obj:any) => obj.checked === true).length;
    else
    return this.historyData.filter((obj:any) => obj.checked === true).length;


  }
  selectAll(){
    var selectList:any = this.status=='Pending'?this.processList:this.historyData;
    this.inputdata = this.appService.selectAll(selectList)

  }
  unselectAll(){

    var selectList:any = this.status=='Pending'?this.processList:this.historyData;
    this.inputdata = this.appService.unselectAll(selectList)

  }
  confirmation(type:any){
    let completeData:any;
    this.saveData     = [];
    this.saveComplete = false;
    completeData      = this.status=='Pending'?this.processList:this.historyData
    let statustext    =  type;
    this.purpose      = 'processing';
    this.actionMsg    = type=='ignore'?'ignored':this.actionMsg;

    for(let i=0;i<completeData.length;i++){
      if(completeData[i]?.checked == true){
        this.saveData.push(
          {
            "id"          : completeData[i]['id'],
            "arrear_month": completeData[i]['lop_month'],
            "arrear_year" : completeData[i]['lop_year']
          }

          );
        this.lopMonth = completeData[i]['lop_month'];
        this.lopYear  = completeData[i]['lop_year'];
      }
    }
    if(this.saveData.length>0){
      this.confirmMsgText ="Click the Save button, If you want to "+ statustext +" the arrear for the selected employees.";
      this.confirmMsg     = true;
    }

  }
  confirmationOverride(){
    this.purpose  = 'override';
    for(let i=0;i<this.historyData.length;i++){
      if(this.historyData[i]?.checked == true){

        this.saveData.push(
          {
            'id'       :this.historyData[i]['process_id'],
            'arear_days':Number(this.historyData[i]['arrear_days'])
          }
        );

      }
    }
    if(this.saveData.length>0){
      this.confirmMsgText = "Click the Save button, If you want to override the arrear days for the selected employees.";
      this.confirmMsg     = true;
    }
  }
  processArrear(){
    this.saveComplete = true;
    let savingData = {
      "month"         : this.month,
      "year"          : parseInt(this.year),
      'status'        : this.actionMsg=='ignored'?'Ignored':this.status=='Pending'?'Completed':'Pending',
      'data'          : this.saveData
    }
    this.isDataUpload = 1;
    this.iscompleted  = false
    this.loader       = true
    this.saveComplete = true;
    this.chunkLen     = 10;
    this.percentage   = 0;

    if(this.saveData.length>0){
        this.isProgressStart  = true
        this.failedData       = []
        var l                 = this.saveData.length;
        var cLoop             = Math.ceil(l/this.chunkLen)
        var p                 = 100/cLoop
        this.successDataCount = 0
        this.processArrearProgress(0,p);
    }
    // this.sds.processUnprocessArrear(savingData).subscribe((res:any)=>{
    //   if(res.body.error_list.length==0){
    //     this.notificationService.handleSuccessNotification(this.saveData.length+" employee arrear "+this.actionMsg+ " successfully.","Success");

    //   } else {
    //     this.exportData       = [];
    //     this.successDataCount = this.saveData.length - res.body.error_list.length
    //     let errorData         = res.body.error_list
    //     this.downloadError(errorData);

    //   }
    //   this.confirmMsg = false;
    //   this.checkAndCallLandingScreen();
    //   this.saveComplete = false;
    //   this.clearData();

    // })
  }
  processArrearProgress(k:any,p:any){
    var l = this.saveData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.failedData       = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveData[i])
    }

  this.sds.processUnprocessArrear({ "month": this.month,"year": parseInt(this.year),'status': this.actionMsg=='ignored'?'Ignored':this.status=='Pending'?'Completed':'Pending',"data":saveDataArray}).subscribe((res:any)=>{

    this.body = x+' out of '+l+" data uploaded successfully"
    this.confirmMsg = false;
    if(res.body.error_list != undefined && res.body.error_list.length != 0){
      res.body.error_list.forEach((i:any, k:any) => {
            this.failedData.push(i)
        });
    }
    this.percentage +=((p/4)*3);
    if(x<l)
        this.processArrearProgress(k+1,p);
    else{
          this.iscompleted = true
          this.isProgressStart = false
          this.successDataCount = this.saveData.length- this.failedData.length;
          if(this.failedData.length == 0){
            this.notificationService.handleSuccessNotification(this.successDataCount+" employee arrear data " + this.actionMsg+" successfully.","Success");

          } else {
          this.exportData    = [];
          for (let i = 0; i < this.failedData.length; i++) {

            this.exportData.push({'Employee code':this.failedData[i]['employee_code'],'Employee name':this.failedData[i]['name'],'Salary Month':this.month,'LOP Month':this.lopMonth,'Error description':this.failedData[i]['error']});
          }
            this.successMsg   = this.successDataCount+" out of "+l+" data " + this.actionMsg+" successfully "
            this.errorMsg     = this.failedData.length+' employee(s) have error data'
            this.downloadMsg  = true;
        }
        this.confirmMsg = false;
        this.checkAndCallLandingScreen();
        this.saveComplete = false;
        this.clearData();
    }

  })
  }
  saveArrearDay(){
    this.saveComplete = true;
    this.sds.saveArrearDay({'data':this.saveData}).subscribe((res:any)=>{

      if( res.body.error_list.length==0)
      this.notificationService.handleSuccessNotification("Employee arrear updated successfully.","Success");
      else{
        this.exportData       = [];
        this.successDataCount = this.saveData.length - res.body.error_list.length
        let errorData         = res.body.error_list
        this.downloadError(errorData);
      }
      this.checkAndCallLandingScreen();
      this.saveComplete = false;
      this.confirmMsg   = false;

      this.clearData();
    })
  }
  downloadError(errorData:any){
    for (let i = 0; i < errorData.length; i++) {
      this.exportData.push({'Employee code':errorData[i]['employee_code'],'Employee name':errorData[i]['name'],'Salary Month':this.month,'LOP Month':this.lopMonth,'Error description':errorData[i]['error']});
    }
      this.successMsg   = this.successDataCount+" out of "+this.saveData.length+" data " +this.actionMsg+ " successfully "
      this.errorMsg     = errorData.length+' employee(s) have error data'
      this.downloadMsg  = true;
  }

  checkAndCallLandingScreen(){
    if(this.status=='Pending')
    this.getPendingArrearData();
    else
    this.getCompletedArrearData();
  }
  closeInfo(bool:any){
    this.alertToggle = bool;
    this.loader      = false;

  }
  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList       = []
    this.checkAndCallLandingScreen();
    this.filter           = false
  }
  submitForm(data:any){
    this.filtercomp.getBUList(this.company);
    this.filter           = false
    this.buList           = data.buList;
    this.DepList          = data.DepList;
    this.DesignationList  = data.DesignationList;
    this.gradeList        = data.gradeList;
    this.BranchList       = data.BranchList;
    this.checkAndCallLandingScreen();
  }
  closeFilter(){
    this.filter  = false
  }
 checkValidity(){
    let count = 0
    let index = 0;
    this.historyData.forEach((item:any, value:any) => {
      if(item?.checked == true){
        if(this.historyData[index]['error']){
          count ++
        }

      }
      index++
    })
    return count;
  }

}
