import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder,FormGroup,Validators,FormArray } from '@angular/forms';
import { ShiftService } from 'src/app/@core/services/shift.service';
import { AppService } from 'src/app/app.global';
import { stringify } from 'querystring';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-shift-rotation-setup',
  templateUrl: './shift-rotation-setup.component.html',
  styleUrls: ['./shift-rotation-setup.component.scss']
})
export class ShiftRotationSetupComponent implements OnInit {
  activeTab         =1;
  disabled          = false;
  res               :any;
  id: number = 0;
  iseditForm=false;
  editflag: boolean = false;
  loader     = false;
  shift:any;
  shiftschedule:any;
  title ="Add Shift Rotation";
  hint = "Assign the shift rotation policy to the appropriate OU's."
  myForm: FormGroup = this.fb.group({
    id: 0,
    shiftdetails: this.fb.group({
      name                :  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      shift_schedule      : [null,[Validators.required]],
      applicable_period   : [null,Validators.required],
      }),
    shiftconfigure: this.fb.group({
      selected_shift   : this.fb.array([]),
      rotation_frequency:['',[Validators.required,NumberpatternValidator(),Validators.max(6),Validators.min(1)]]
    }),
    applicable: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })

  });


  constructor(private fb:FormBuilder,
    public messageService : MessageService,
    public shiftservice : ShiftService,
    public router: Router,
    public route:ActivatedRoute,
    public appservice:AppService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].shiftdetails.invalid) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 3) {
      if (this.myForm['controls'].shiftconfigure.invalid || this.myForm['controls'].shiftdetails.invalid || this.shiftconfigureForm.controls.selected_shift.value.length==0) {
        changeEvent.preventDefault();
      }
    }
  }
  get shiftdetailsForm() {
    return this.myForm.get('shiftdetails') as FormGroup;
  }
  get shiftconfigureForm() {
    return this.myForm.get('shiftconfigure') as FormGroup;
  }
  get applicableForm() {
    return this.myForm.get('applicable') as FormGroup;
  }
  selected_shift() : FormArray {
    return this.shiftconfigureForm.get("selected_shift") as FormArray
  }
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
  }
  submitMethodtime(value:any){
    this.shiftschedule= value;
    this.activeTab = 2;
  }
  submitForm(){
    this.res = this.applicableForm.controls.assignees.value;
    var applicable = this.applicableForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.res){
      arr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }
    let configarr:any = [];
    for(let i=0;i<this.shiftconfigureForm.controls.selected_shift.value.length;i++){
      configarr.push({
        "selected_shift":this.shiftconfigureForm.controls.selected_shift.value[i].shiftsid,
        "shift_order"   : i+1,
       });
    }
    var dataArray = {
    "rotation_name": this.shiftdetailsForm.controls.name.value,
    "shift_schedule": this.shiftdetailsForm.controls.shift_schedule.value,
    "applicable_period":this.shiftdetailsForm.controls.applicable_period.value,

    "shift_rotation_config": configarr,
    "rotation_frequency":this.shiftconfigureForm.controls.rotation_frequency.value,

    "shift_rotation_assign":arr
   };

    if(this.id == 0){
      this.shiftservice.shiftrotatCreate(dataArray).subscribe((res: any) => {
        this.router.navigate(['/shift/shift-rotation']);
      })
    }else {
      this.shiftservice.shiftrotatupdate(dataArray,this.id).subscribe((res: any) => {
        this.router.navigate(['/shift/shift-rotation']);
      })
    }

  }

  editForm(){
    this.loader = true;
    this.editflag = true
    this.shiftservice.shiftrotateditview(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.title ="Edit Shift Rotation";
      this.setEditForm(res);
    },(error:any)=>{
      this.router.navigate(['/shift/shift-rotation']);
    });
  }
  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }
    let assignees = [];
    let applicableto = "";
    let appkey ="";

    for(let key of res.shift_rotation_assign){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
      assignees.push(key[appkey]);
      applicableto = key.applicable
    }
    let shiftarray = [];
    for(let i=0;i<res.shift_rotation_config.length;i++){
      this.addShift();
        shiftarray.push({
          "shiftsid": res.shift_rotation_config[i].selected_shift
        })
    }
    this.myForm.reset(
      {
        id: res.id,
        shiftdetails: {
          name: res.rotation_name,
          shift_schedule: res.shift_schedule,
          applicable_period: res.shift_schedule=='Monthly' && res.applicable_period!='Last day of month'?Number(res.applicable_period):res.applicable_period,
        },
        shiftconfigure: {
          selected_shift: shiftarray,
          rotation_frequency: res.rotation_frequency,
        },
        applicable: {
          applicableto: applicableto,
          assignees: assignees
        }
    });
    this.loader = false;
  }

newShift(): FormGroup {
  return this.fb.group({
    "shiftsid": null
  })
}

addShift() {
  this.selected_shift().push(this.newShift());
}
}

