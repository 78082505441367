import { EventEmitter, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from './notification.service';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  notify: EventEmitter<any> = new EventEmitter();
  navigateUrl:any;
  constructor(
    private authService: AuthService,
    private websocket: WebSocket,
    public websocket1: WebSocket,
    private notificationservice: NotificationService,
    public router: Router,
    public appservice:AppService,
    private datePipe:DatePipe
  ) { }

  WEBSOCKET_URL: string = environment.webSocketEndPoint;

  public NotificationSocket(){
    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}notification/${this.appservice.getSubDomain()}_user_${this.authService.isUserId}/`);

    this.websocket.onopen = (event:any) => {
    }


    this.websocket.onmessage = (event:any) => {

      let data = JSON.parse(event.data);
      let notif_msg = '';
      if (data.event.message.length > 142) {
        notif_msg = data.event.message.substring(0, 142) + '...';
      }else{
        notif_msg = data.event.message;
      }

      this.notificationservice.PushNotification(
        `<div class="card card-c4 card-hide show" >
          <button type="button" aria-label="Close" class="toast-close-button  " style="">
          <span aria-hidden="true" aria-label="Close" class="toast-close-button  "></span></button>
          <div class="card-body d-flex align-items-start p-16">
            <div class="avatar-circle ${data.event.icon} notification-icon sq-40 bg8 text-accent2 me-16" >
              <i class=""></i>
            </div>
            <div class="text-start me-auto">
              <p class="fs-14 text-black mb-8">${this.formatDate(notif_msg)}</p>
            </div>
            <a class="ms-auto text-light-600 ms-16" (click)="hideCard($event)"></a>
          <div>
        </div>`,'');
      this.notify.emit(data.event);
    }

    if (this.websocket.readyState === WebSocket.OPEN) {

    }

  }
  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close()
    }
    // this.websocket.onclose = (event:any) => {

    // }
  }


  formatDate(notif_msg:any){
    // Updated the code with time formatting too
    let message = notif_msg
    message = message.replace(/\d{2}([-])\d{2}\1\d{4}/g, (dateString:any) => {
      let ndateString = dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
      return this.appservice.dateFormatDisplay(ndateString);
    });
    message = message.replace(/\b\d{2}:\d{2}:\d{2}\b/g, (timeString:any) => {
        return this.appservice.timeFormatDisplay(timeString);
    });
    message = message.replace(/(loan (request|process).*?)(\s*for\s*)(\d+(\.\d{1,2})?)/gi, (match: string, prefix: string, action: string, forText: string, amountString: string) => {
      const amount = parseFloat(amountString);
      let empData = this.appservice.getEmpData();
      const formattedAmount = this.appservice.formatCurrency(empData?.currency, amount);
      return `${prefix}${forText}${formattedAmount}`;
    });
    notif_msg = message
    return notif_msg
  }
}
