import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';

@Component({
  selector: 'app-loan-request-setup',
  templateUrl: './loan-request-setup.component.html',
  styleUrls: ['./loan-request-setup.component.scss']
})
export class LoanRequestSetupComponent implements OnInit {

  // Variables
  loanTypesDropdown  : any = [];
  activeTab                = 1 ;
  loanTypeDetails    : any = []
  currency           : any
  tab2touched        : boolean = false

  // Form
  loanRequestForm    !: FormGroup

  constructor(
    private loanServ : InvestmentDeclerationService,
    private fb       : FormBuilder,
    private router   : Router,
    private loanReServ     : LoanProcessService
  ) {
    this.loanRequestForm = this.fb.group({
      loanRequest    : this.fb.group({
        loan_type    : [null, [Validators.required]],
        loan_amount  : [null, [Validators.required, Validators.min(1)]],
        number_of_emi: [null, [Validators.required, Validators.min(1)]],
        required_date: [null, [Validators.required]],
        reason       : [null, [Validators.maxLength(100)]],
        document     : ['']
      })
    })
  }

  get loanDetailsForm() {
    return this.loanRequestForm.get('loanRequest') as FormGroup
  }

  ngOnInit(): void {
    this.getLoanTypes(); //Loan Type Fn
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(this.loanRequestForm['controls'].loanRequest.invalid){
        changeEvent.preventDefault();
      }else{
        this.tab2touched = true
      }
    }
  }

  // Step 1
  submitOne(data: any){
    this.activeTab = 2;
    this.tab2touched = true
    this.loanTypeDetails = data?.loanTypeDetails
    this.currency = data?.currency
  }

  // Step 2
  submitForm(data:any){
    let dataArray = {
      'loan_type' : this.loanDetailsForm.get('loan_type')?.value,
      'loan_amount' : Number(this.loanDetailsForm.get('loan_amount')?.value),
      "number_of_emi": Number(this.loanDetailsForm.get('number_of_emi')?.value),
      "required_date": moment(this.loanDetailsForm.get('required_date')?.value).format('YYYY-MM-DD'),
      "reason": this.loanDetailsForm.get('reason')?.value,
      "document": this.loanDetailsForm.get('document')?.value
    }
    this.loanServ.createLoanRequest(dataArray).subscribe((res:any)=>{
      this.router.navigate(['request'])
    })
  }

  // Dropdown API
  getLoanTypes(){
    this.loanReServ.getLoanTypesDropdown().subscribe((res:any)=>{
      this.loanTypesDropdown = res?.data
    })
  }

  // Setup Close
  backClicked() {
    this.loanServ.backClicked('/loan-setup');
  }
}
