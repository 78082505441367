import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
@Component({
  selector: 'app-fnf-it-declaration-approval',
  templateUrl: './fnf-it-declaration-approval.component.html',
  styleUrls: ['./fnf-it-declaration-approval.component.scss']
})
export class FnfItDeclarationApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() createdTime:any;
  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();
  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  viewHeader                = '';
  cityName:any              = '';
  viewId:any;
  investmentSingleData:any  = [];
  hra80ggSingleData:any     = [];
  cahpt80CSingleData:any    = [];
  otherSourceSingleData:any = [];
  deductionSingleData:any   = [];
  incomeLossSingleData:any  = [];
  taxData:any               = [];
  exeption_50:any           = [];
  hra80gg:any;
  investmentcard1           = false;
  investmentcard            = false;
  hra80ggFlag:any           = false;
  loader1:any               = false;
  from                      = 'fnf';
  requestitem:any           = [];
  currency:any;


  constructor(
    private investmentService:InvestmentDeclerationService,
  ) { }

  ngOnInit(): void {
  }

  Inv_Detailspanel(req_obj: any,req_type_name:any){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      this.loader1          = true;
      this.investmentcard1  = true;
      var viewId            = req_obj;
      this.viewId           = this.data?.id;
      this.viewHeader       = req_type_name

      this.investmentService.investmentSingleData(viewId).subscribe((res: any = []) =>{
        this.investmentSingleData   = res;
        this.hra80ggSingleData      = res?.hra80gg;
        this.cahpt80CSingleData     = res?.chapter4a80c;
        this.deductionSingleData    = res?.chapter4adeduction;
        this.otherSourceSingleData  = res?.income_from_othersource;
        this.incomeLossSingleData   = res?.incomeloss_property;
        this.taxData                = JSON.parse(res?.declared_datail);
        this.currency               = res?.currency;
        if(this.taxData?.excemption_declaration?.hasOwnProperty('hra'))
          this.hra80gg='hra';
        if(this.taxData?.excemption_declaration?.hasOwnProperty('80gg'))
          this.hra80gg='80gg';
        if(res.hra80gg?.length > 0){
          if(res.hra80gg[0]['select_one'] == true)
            this.hra80ggFlag = true;
          else
            this.hra80ggFlag = false;
        }

        if(this.deductionSingleData?.donate_80g?.length > 0){
          for(let i=0; i< this.deductionSingleData?.donate_80g?.length; i++){
            if(!this.deductionSingleData?.donate_80g[i]?.exemption){
              this.exeption_50.push(this.deductionSingleData?.donate_80g[i])
            }
          }
        }
        this.loader1           = false;
      });
      this.investmentcard1 = true;
    }
  }

  closeSidePanel(val: boolean){
    this.investmentcard1=val;
    this.fnfApprovalViewClose.emit(val);
  }

  refreshInbox(val: boolean){
    this.investmentcard1=val;
    this.fnfApprovalViewClose.emit(val);
  }
}
