<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Final Settlement</h5>
        <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
    </header>
  <aside class=" config-sidebar">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
            <li [ngbNavItem]="1">
                <a ngbNavLink class="step" [class]="{'success': finalSettlementForm['controls'].resignationDetails.valid}">
                    <div class="count">1</div>
                    <div class="content">
                        <span>Step 1</span>
                        <h6>Resignation Details</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-final-settlement-resignation-details (submitmethod)="submitOne($event)" [inboxId]="inboxId"
            (isSalaryProsessed)="isSalaryProsessed($event)" [formGp]="resignationDetails"
            [resignationData]="resignationData" [salary_hold]="salary_hold" [resigloader]="resigloader"
            [allowSave]="allowSave" [errorMsg]="errorMsg" (resignationEmitter)="resignationEmitter()"
            [payrollStatus]="payrollStatus"
            (warningMsg)="unProcessWarning()"></app-final-settlement-resignation-details>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink class="step" [class]="{'success': tab2touched}">
                    <div class="count">2</div>
                    <div class="content">
                        <span>Step 2</span>
                        <h6>Pending Requests</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-final-settlement-pending-requests [currency]="currency" [payrollStatus]="payrollStatus"  (warningMsg)="unProcessWarning()"
                        (submitmethod)="submitTwo()"></app-final-settlement-pending-requests>
                </ng-template>
            </li>
            <li [ngbNavItem]="3">
                <a ngbNavLink class="step"
                    [class]="{'success':finalSettlementForm['controls'].workDaysPayable.valid && tab3touched && this.attendanceProcessFlag == true}">
                    <div class="count">3</div>
                    <div class="content">
                        <span>Step 3</span>
                        <h6>Workdays Payable</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
          <app-final-settlement-workdays-payable [formGp]="workDaysPayableForm" [payrollStatus]="payrollStatus"  [inboxId]="inboxId"
            (warningMsg)="unProcessWarning()" (allowSaveWorkDays)="allowSaveWorkDays($event)"
            (submitmethod)="submitThree($event)" [resignationDetails]="resignationDetails"
            (attendanceProcessed)="attendanceProcessed($event)"
            (attendanceModuleWorkDaysSaveData)="attendanceModuleWorkDaysSaveData($event)"
            (lopOrAttendanceModule)="lopOrAttendanceModule($event)" [relievingStat]="resignationData?.relieving_month_payroll_details?.status"></app-final-settlement-workdays-payable>
                </ng-template>
            </li>

            <li [ngbNavItem]="4">
                <a ngbNavLink class="step" [class]="{'success': tab4touched}">
                    <div class="count">4</div>
                    <div class="content">
                        <span>Step 4</span>
                        <h6>Arrear Calculation</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
          <app-final-settlement-arrear-calculation [resignationDetails]="resignationDetails" [inboxId]="inboxId"
            (submitmethod)="submitFour()" [payrollStatus]="payrollStatus"
            (warningMsg)="unProcessWarning()"></app-final-settlement-arrear-calculation>
                </ng-template>
            </li>
            <li [ngbNavItem]="5">
                <a ngbNavLink class="step" [class]="{'success': tab5touched}">
                    <div class="count">5</div>
                    <div class="content">
                        <span>Step 5</span>
                        <h6>Investment Declaration</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-final-settlement-investment-declaration [empId]="empId" [payrollStatus]="payrollStatus"
                        (warningMsg)="unProcessWarning()"
                        (submitmethod)="submitFive()"></app-final-settlement-investment-declaration>
                </ng-template>
            </li>
            <li [ngbNavItem]="6">
                <a ngbNavLink class="step" [class]="{'success': tab6touched}">
                    <div class="count">6</div>
                    <div class="content">
                        <span>Step 6</span>
                        <h6>Reimbursement</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-final-settlement-reimbursement [empId]="empId" [payrollStatus]="payrollStatus"
            (warningMsg)="unProcessWarning()" (submitmethod)="submitSix()"></app-final-settlement-reimbursement>
                </ng-template>
            </li>
            <li [ngbNavItem]="7">
                <a ngbNavLink class="step" [class]="{'success': tab7touched}">
                    <div class="count">7</div>
                    <div class="content">
                        <span>Step 7</span>
                        <h6>Variable Payments</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-final-settlement-other-payments [formGp]="componentForm" [empId]="empId" [inboxId]="inboxId"
            [otherPaymentsData]="otherPaymentsData" [payrollStatus]="payrollStatus" (warningMsg)="unProcessWarning()"
            (submitmethod)="submitSeven()"
            [resignationDetails]="resignationDetails"></app-final-settlement-other-payments>
                </ng-template>
            </li>
            <li [ngbNavItem]="8">
                <a ngbNavLink class="step"
                    [class]="{'success': finalSettlementForm['controls'].noticePayClearence.valid && tab8touched}">
                    <div class="count">8</div>
                    <div class="content">
                        <span>Step 8</span>
                        <h6>Recovery/Pay</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-final-settlement-recovery-or-pay [formGp]="noticePayClearenceForm" [empId]="empId" [inboxId]="inboxId"
                        [noticePayAndClearenceData]="noticePayAndClearenceData" [payrollStatus]="payrollStatus"
                        (warningMsg)="unProcessWarning()" (submitmethod)="submitEight()"
            (getOtherPaymentData)="getOtherPaymentData()" [errnoticeCompmsg]="errnoticeCompmsg"
            [errclearenceCompmsg]="errclearenceCompmsg" [errBothCompmsg]="errBothCompmsg"
            [resignationDetails]="resignationDetails"></app-final-settlement-recovery-or-pay>
                </ng-template>
            </li>
            <li [ngbNavItem]="9">
        <a ngbNavLink class="step" [class]="{'success':  tab9touched}">
                    <div class="count">9</div>
                    <div class="content">
                        <span>Step 9</span>
                        <h6>Gratuity & Other payments</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
          <app-final-settlement-gratuity [formGp]="gratuityForm" [empId]="empId" [payrollStatus]="payrollStatus" [inboxId]="inboxId"
            (warningMsg)="unProcessWarning()" (submitmethod)="submitNine($event)"
            [resignationDetails]="resignationDetails" [loader]="loader" [errCompmsg]="errCompmsg"
            [errleaveCompmsg]="errleaveCompmsg" [bothCompMsg]="bothCompMsg" [gratuityData]="gratuityData"
            [attendance_lop]="is_attendance_lop"
            (getGratuityDetails)="getGratuityDetails()"></app-final-settlement-gratuity>
                </ng-template>
            </li>

            <!-- test start -->
            <li [ngbNavItem]="10">
              <a ngbNavLink class="step"  [class]="{'success': tab10touched}">
                  <div class="count">10</div>
                  <div class="content">
                      <span>Step 10</span>
                      <h6>Expense Reimbursements</h6>
                  </div>
              </a>
              <ng-template ngbNavContent>
                  <app-final-settlement-expense-reimbursements [empId]="empId" [payrollStatus]="payrollStatus" [resignationDetails]="resignationDetails" [currency]="currency" (warningMsg)="unProcessWarning()"
                  (submitmethod)="submitTen()"></app-final-settlement-expense-reimbursements>
              </ng-template>
            </li>
            <!-- test end -->

            <li [ngbNavItem]="11">
                <a ngbNavLink class="step"  [class]="{'success': tab10touched}">
                    <div class="count">11</div>
                    <div class="content">
                        <span>Step 11</span>
                        <h6>Process Settlement</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-final-settlement-process-f-and-f [formGp]="processFandFForm" [empId]="empId"
                        [processDetails]="processDetails" [inboxId]="inboxId" [payrollStatus]="payrollStatus"
            (payRollStatusEmitter)="payRollStatusEmitter($event)" [resignationDetails]="resignationDetails"
            [company_id]="company_id" [workDaysSaveData]="workDaysSaveData" [relievingStat]="resignationData?.relieving_month_payroll_details?.status" [lopOrAttendance]="this.lopOrAttendance" [workDaysPayableForm]="workDaysPayableForm"></app-final-settlement-process-f-and-f>
                </ng-template>
            </li>

        </ul>
    </aside>
    <main class=" config-container">
        <div [ngbNavOutlet]="nav"></div>
    </main>
</div>

<!-- Info popup start -->
<ng-container *ngIf="showInfo == true">
    <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- Info popup end -->
