<form class="" [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row row-16">
      <div class="col-12"> <span class="text-uppercase fw-500">WORK FROM HOME REQUEST
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Select the From and To date from the respective dropdown for which you want to raise WFH request and mention the reason below."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        </span>
      </div>
      <div *ngIf="warningMsgToggle" class="col-12">
        <div role="alert" class="alert alert-warning show">
          <p class="mb-0 fs-12
          ">{{warningMsg}}</p>
          <div class="btn-close pointer" (click)="warningMsgToggle=false"></div>
        </div>
      </div>
      <div class=" col-12 col-lg-6 ">
        <label for="" class="form-label required">From date</label>
        <div class="input-group form-icon icon-right"
          [ngClass]="{ 'is-invalid': (submitted ||f.from.touched || f.to.touched) && f.from.errors}">
          <input (click)="datePicker1.open()" (dateInput)="fromDate($event)"
            placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
            [ngClass]="{ 'is-invalid': (submitted || f.from.touched || f.to.touched) && f.from.errors}" matInput [matDatepickerFilter]="dateFilter"
            formControlName="from" [matDatepicker]="datePicker1" (keydown)="disableDate()"><i
            class="icon-calendar pointer" (click)="datePicker1.open()"></i>
          <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
        </div>
        <div *ngIf="(submitted || f.from.touched || f.to.touched) && f.from.errors" class="invalid-feedback">
          <div *ngIf="f.from.errors.required">{{this.messageService.validationDisplay('required')}}
          </div>
        </div>
      </div>
      <div class=" col-12 col-lg-6 ">
        <label for="" class="form-label required">To date </label>
        <div class="input-group form-icon icon-right"
          [ngClass]="{ 'is-invalid': (submitted || f?.to?.touched) && (f?.to?.errors || dateError) }">
          <input (click)="datePicker2.open()" (dateInput)="toDate()"
            placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
            [ngClass]="{ 'is-invalid': (submitted || f?.to?.touched) && (f?.to?.errors || dateError) }" matInput [matDatepickerFilter]="dateFilter"
            formControlName="to" [min]="f?.from?.value" [matDatepicker]="datePicker2" (keydown)="disableDate()"><i
            class="icon-calendar pointer" (click)="datePicker2.open()"></i>
          <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
        </div>
        <div *ngIf="(submitted || f?.to?.touched)  && (f?.to?.errors || dateError)" class="invalid-feedback">
          <div *ngIf="f?.to?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="formGp?.errors!=null">{{this.messageService.greaterThanMessage('To date', 'From date')}}</div>
          <div *ngIf="dateError">{{dateErrorMsg}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-c2 p-32 {{displayclass}}">
          <div class="d-flex flex-column gap-8">
            <div class="d-flex">
              <div class="fw-500 fs-14 text-uppercase">{{appservice.dateFormatDisplay(f.from.value)}} -
                {{appservice.dateFormatDisplay(f.to.value)}}</div>
              <div class="ms-auto fs-14">Total no. of days : <span class="fw-500"> {{totday}}
                  days</span>
              </div>
            </div>
            <app-table-loader *ngIf="loadertbl"></app-table-loader>
            <ng-container *ngIf="!loadertbl">
              <div class="card card-c2 p-24 overflow-auto" style="max-height: 23rem;" formArrayName="wfh_request">
                <div class="row row-16" *ngIf="wfh_request()?.length! > 0">
                  <ng-container *ngFor="let wfhday of wfh_request().controls,let i=index" [formGroupName]="i">
                    <div class="col-12">
                      <div ngbDropdown container="body"
                        class="card card-c2 {{wfhdays[i]?.disablekey ? 'pointer-event' : 'pointer'}}"
                        placement="bottom-right">
                        <div id="dropdownBasic1" ngbDropdownToggle placement="bottom-right"
                          class="card-body d-flex align-items-center gap-16 indicater px-16 py-14">
                          <span class="fw-500 ">{{appservice.dateFormatDisplay(wfhdays[i]?.date)}}
                          </span>
                          <span class="fs-10">( {{wfhdays[i]?.shift_start_time}} - {{wfhdays[i]?.shift_end_time}}
                            )</span>
                          <span class="fs-10">( {{wfhdays[i]?.shift_name}} )</span>
                          <span class="type-indicator sq-12 type-holiday" *ngIf="wfhdays[i]?.holiday== true"></span>
                          <span class="type-indicator sq-12 type-weekoff"
                            *ngIf="wfhdays[i]?.weekoff_day == true"></span>
                          <div class="ms-auto  d-inline-block " *ngIf="wfhdays[i]?.disablekey != true">
                            <button class="btn-reset fs-12 text-accent2  d-flex px-0">
                              {{wfh_request().controls[i]?.value?.day_portion}} <i
                                class="bi bi-caret-down-fill ms-1"></i>
                            </button>
                            <div ngbDropdownMenu class="bottom-right  fs-14" aria-labelledby="dropdownBasic1">
                              <button ngbDropdownItem
                                [class]="{'active':wfh_request().controls[i]?.value?.day_portion=='Full Day' }"
                                (click)=" daytime('Full Day',wfh_request().controls[i].value.shift_mapping,i)">Full
                                Day</button>
                              <button ngbDropdownItem
                                [class]="{'active':wfh_request().controls[i]?.value?.day_portion=='First Half Day' }"
                                (click)="daytime('First Half Day',wfh_request().controls[i].value.shift_mapping,i)">First
                                Half Day </button>
                              <button ngbDropdownItem
                                [class]="{'active':wfh_request().controls[i]?.value?.day_portion=='Second Half Day' }"
                                (click)="daytime('Second Half Day',wfh_request().controls[i].value.shift_mapping,i)">Second
                                Half Day</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <div class="d-flex flex-wrap pt-8 gap-16 fs-12">
              <div class="d-flex align-items-center gap-8">
                <span class="type-indicator sq-12 type-holiday"></span>Holiday
              </div>
              <div class="d-flex align-items-center gap-8">
                <span class="type-indicator sq-12 type-weekoff"></span>Weekoff
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 form-row">
        <label for="" class="form-label required">Reason </label>
        <textarea type="text" class="form-control" rows="4" formControlName="reason" placeholder="Enter reason here..."
          [ngClass]="{ 'is-invalid': (submitted || f.reason.dirty || f.reason.touched) && (f.reason.errors) }"></textarea>
        <div *ngIf="(submitted || f.reason.dirty || f.reason.touched) && f.reason.errors" class="invalid-feedback">
          <div *ngIf="f.reason.errors.required">{{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="f.reason.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid || disablenext"
      (click)="validateDetails()">NEXT</button>
  </footer>
</form>
<!-- Alert  -->

<ng-container *ngIf="alertToggle==true">
  <app-alert-toggle (modals)="closed($event)" [alertToggle]="alertToggle" [alertMsg]="alertMsg"></app-alert-toggle>
</ng-container>