<form class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">WEEKLY OFF DETAILS </p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Policy name</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter a name for the weekly off policy." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <input type="text" class="form-control" formControlName="weekPolicyName" placeholder="{{this.messageService.placeholderdisp('policy name')}}" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.weekPolicyName.touched|| f.weekPolicyName.dirty) &&  f.weekPolicyName.errors || f.weekPolicyName.errors?.exist}" (keyup)="keyUp()">
                <div *ngIf="(saveBtnClicked || f.weekPolicyName.touched || f.weekPolicyName.dirty) && f.weekPolicyName.errors || f.weekPolicyName.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.weekPolicyName.hasError('exist')">{{this.messageService.validationDisplay('policy name')}}</div>
                  <div *ngIf="f.weekPolicyName?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="!f.weekPolicyName?.errors?.pattern && f.weekPolicyName?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf=" f.weekPolicyName?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
            <div class="col-12">
              <app-week-off-table [formGp]="formGp"></app-week-off-table>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid|| saveBtnClicked" (click)="validateForm()">NEXT</button>
    </footer>
</form>
