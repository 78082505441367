<form action="" class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">BREAK SCHEDULE</p>
        </div>
        <div class="row row-16">
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label required">Do you want to enable break hour deduction </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Select Yes if you want to enable break hour deduction."
                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                        triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input class="form-check-input" formControlName="break_hour_deduction_enable" type="radio"
                            [value]=true (ngModelChange)="setRequired(true,'break_schedule');addmorebreak()">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="break_hour_deduction_enable" class="form-check-input" type="radio"
                            [value]=false (ngModelChange)="setRequired(false,'break_schedule');">
                        No
                    </label>
                </div>
            </div>
          <ng-container *ngIf="f.break_hour_deduction_enable.value">
            <div class="col-12">
                <p class=" fw-500 mb-0">Break Details</p>
            </div>
            <ng-container formArrayName="break_schedule" *ngFor="let item of breakarray().controls; let i=index; ">
                <ng-container [formGroupName]="i">
                    <div class="col-6">
                        <label for="" class="form-label required">Break Start Time </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                ngbPopover="Enter the Break Start Time." popoverClass="popover-default popover-info"
                                placement="bottom" container="body" triggers="hover"></i></span>
                    </div>
                    <div class="col">
                        <label for="" class="form-label required">Break End Time </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                ngbPopover="Enter the Break End Time." popoverClass="popover-default popover-info"
                                placement="bottom" container="body" triggers="hover"></i></span>
                    </div>
                    <div style="width:2.5rem" *ngIf="i!=0">&nbsp;</div>
                    <div class="col-12">
                        <div class="align-items-start d-flex flex-nowrap row row-16">
                            <div class=" col-6 form-row mt-0">

                                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || breakarray().controls[i].get('break_start_time')?.touched || breakarray().controls[i].get('break_start_time')?.dirty) &&  breakarray().controls[i].get('break_start_time')?.errors) || (samekey[i]?.from != undefined && samekey[i]?.from)}">

                                    <igx-time-picker
                                        formControlName="break_start_time"
                                        placeholder="{{this.messageService.descriptiontext('time')}}"
                                        [inputFormat]="'HH:mm'"
                                        [ngClass]="{ 'is-invalid': ((submitted || breakarray().controls[i].get('break_start_time')?.touched || breakarray().controls[i].get('break_start_time')?.dirty) &&  breakarray().controls[i].get('break_start_time')?.errors) || (samekey[i]?.from)}"
                                        class="form-control form-timepicker "
                                        (click)="picker1.open();breakhrexceeded=false;"
                                        #picker1
                                        [minValue]="formGp.controls['shift_start_time']?.value"
                                        (ngModelChange)="breakarrayChanges()">
                                    </igx-time-picker >

                                    <i *ngIf="breakarray().controls[i].get('break_start_time')?.value!=null"  class="icon-close text-light-400" (click)="clearTime(picker1);picker1.close()"></i>
                                </div>
                                <div *ngIf="((submitted || breakarray().controls[i].get('break_start_time')?.touched || breakarray().controls[i].get('break_start_time')?.dirty) && breakarray().controls[i].get('break_start_time')?.errors) "
                                    class="invalid-feedback">
                                    <div *ngIf="breakarray().controls[i].get('break_start_time')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="breakarray().controls[i].get('break_start_time')?.errors?.samefromto"> {{this.messageService.lessMessage('Break End Time', 'Value')}} </div>
                                    <div *ngIf="breakarray().controls[i].get('break_start_time')?.errors?.overlap">Value should not overlap with the previous break schedule </div>
                                    <div *ngIf="breakarray().controls[i].get('break_start_time')?.errors?.validgreater">{{this.messageService.greaterThanMessage('previous break end time', 'Value')}}</div>
                                    <div *ngIf="breakarray().controls[i].get('break_start_time')?.errors?.beforeShift">{{this.messageService.greaterMessage('Shift start time', 'Value')}}</div>
                                </div>
                            </div>

                            <div class=" col form-row mt-0">
                                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || breakarray().controls[i].get('break_end_time')?.touched || breakarray().controls[i].get('break_end_time')?.dirty) &&  breakarray().controls[i].get('break_end_time')?.errors) || (samekey[i]?.to != undefined && samekey[i]?.to)}">

                                    <igx-time-picker
                                        formControlName="break_end_time"
                                        placeholder="{{this.messageService.descriptiontext('time')}}"
                                        [inputFormat]="'HH:mm'"
                                        [ngClass]="{ 'is-invalid': ((submitted || breakarray().controls[i].get('break_end_time')?.touched || breakarray().controls[i].get('break_end_time')?.dirty) &&  breakarray().controls[i].get('break_end_time')?.errors) || (samekey[i]?.to != undefined && samekey[i]?.to)}"
                                        class="form-control form-timepicker "
                                        (click)="picker2.open();breakhrexceeded=false;"
                                        #picker2 (keyup)="totalbreak()"
                                        [maxValue]="formGp.controls['shift_end_time']?.value"
                                        (ngModelChange)="breakarrayChanges()">
                                    </igx-time-picker>

                                    <i *ngIf="breakarray().controls[i].get('break_end_time')?.value!=null"  class="icon-close text-light-400" (click)="clearTime(picker2);picker2.close()"></i>
                                </div>
                                <div class="invalid-feedback" *ngIf="((submitted || breakarray().controls[i].get('break_end_time')?.touched || breakarray().controls[i].get('break_end_time')?.dirty) && breakarray().controls[i].get('break_end_time')?.errors) || (samekey[i]?.to)" >
                                    <div *ngIf="breakarray().controls[i].get('break_end_time')?.errors?.required">  {{this.messageService.validationDisplay('required')}}</div>
                                    <div *ngIf="breakarray().controls[i].get('break_end_time')?.errors?.samefromto "> {{this.messageService.greaterThanMessage( 'Break Start Time', 'Value')}} </div>
                                    <div *ngIf="breakarray().controls[i].get('break_end_time')?.errors?.afterShift">{{this.messageService.lessThanMessage('Shift End time', 'Value')}}</div>
                                    <!-- <div *ngIf="breakarray().controls[i].get('break_end_time')?.errors?.overlap">Value should not overlap with the previous break schedule </div> -->
                                    <!-- <div *ngIf="breakarray().controls[i].get('break_end_time')?.errors?.validgreater">Value should be less than next break start time </div> -->

                                </div>
                            </div>

                            <a class="flex-center sq-40 text-danger mt-0" (click)="removebreakarray(i)" *ngIf="i!=0">
                                <i class="icon-trash "></i>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="col-12 mt-8">
                <a class="link-primary1 fs-14" (click)="addmorebreak()"><i class="icon-plus-square-fill me-8"></i>Add
                    Break Schedule</a>
            </div>
          </ng-container>

        </div>
    </div>

    <footer class="submit-footer">
        <button *ngIf="disabled==false" [disabled]="!formGp.valid || breakhrexceeded || samerror" (click)="validateForm()"
            class="btn btn-primary btn-sm ms-auto">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
    </footer>
</form>
