import { Component, OnInit,Input ,Output,EventEmitter} from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ExpenseAdvnaceProfileService } from 'src/app/@core/services/expense-advance-profile.service';
import { ManageAdvanceService } from 'src/app/@core/services/manage-advance.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-emp-advance-details-view',
  templateUrl: './emp-advance-details-view.component.html',
  styleUrls: ['./emp-advance-details-view.component.scss']
})
export class EmpAdvanceDetailsViewComponent implements OnInit {
  @Input() viewAdvance = false;
  @Input() advanceid:any;
  @Input() viewdata:any;
  @Input() viewLoader:any;
  @Input() currency:any;
  @Input() from:any;
  @Output() panelclose = new EventEmitter();

  reportlist:any                  = [];
  // viewdata:any                    = [];
  tblloader                       = true;
  addReport                       = false;
  pagereport                      = 1;
  pageSizereport: any             = 10;
  // viewLoader                      = true;
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  advancemapid:any;
  mapped                          ='';

  constructor(
    public appService: AppService,
    private ExpenseAdvnaceProfileService: ExpenseAdvnaceProfileService,
    public ManageAdvanceService:ManageAdvanceService,
    public NotificationService:NotificationService,
  ) { }

  ngOnInit(): void {
  //   this.ExpenseAdvnaceProfileService.getadvance(this.advanceid).subscribe((res: any) => {
  //     // this.viewdata=res;
  //     this.viewLoader = false;
  //  });

  }
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;

    // const segments         = filename?.split('/'); // Split by '/'
    // let encodedName        = segments[segments.length - 1]; // Get the last segment (filename + unique ID)
    // encodedName            = encodedName.split('.').slice(0, -1).join('.')
    // const decodedName      = decodeURIComponent(encodedName);
    // const filenameSegments = decodedName.split('_'); // Split by underscore to get segments
    // this.currentDocName    =  filenameSegments.slice(1).join(' ');
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  close(bool:any){
    this.modalToggle = bool;
  }

  closed(bool:any){
    this.alertToggle = bool
  }

  closefnview() {
    this.viewAdvance = false;
    this.panelclose.emit({"viewAdvance":this.viewAdvance,"mapped":this.mapped });
  }

  addReportfn(){
    this.tblloader = true;
    this.addReport=true;    
    this.reportlist=[];
    this.ExpenseAdvnaceProfileService.getreports().subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {        
        this.reportlist.push(res[i])
      }     
      this.tblloader = false;
    });
  }

  CheckedOrNot(value:any){
    let checked = value.target.checked;
    this.advancemapid = value.target.defaultValue;
  }

  mapreport(){
    let data={
      "report":this.advancemapid
    }
    this.mapped ='mapped';
    this.ExpenseAdvnaceProfileService.mapreport(data,this.advanceid).subscribe((res: any) => {  
      this.addReport=false;  
      this.closefnview();
    });
  }

  sendreminder(id:any){
    this.ManageAdvanceService.sendreminder({'advance_id': id }).subscribe((res: any) => {  
      this.NotificationService.handleSuccessNotification(res.data,"Success");
    });
  }
}
