<form [formGroup]="myForm">
  <div class=" config-layout">
    <header class=" config-header">
      <h5 class="m-0"> {{Action}} Page Access</h5>
      <button type="button" class="btn-reset text-light-600" routerLink="/page-access"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader || !isEmpDropdown"></app-fw-edit-loader>
    <ng-container *ngIf="!loader && isEmpDropdown">
      <aside class=" config-sidebar">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="step" [class]="{'success':myForm['controls'].configure.valid}">
              <div class="count">1</div>
              <div class="content">
                <span>Step 1</span>
                <h6>Define Group</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-pa-rights [formGp]="configureForm" [editflag]="editflag" [disabled]="disabled"
                [employeeDropdown]='employeeDropdown' (submitmethod)="groupSubmit()"></app-pa-rights>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="step" [class]="{'success':myForm['controls'].visibleorder.valid}"
              (submitmethod)="submitTwo()">
              <div class="count">2</div>
              <div class="content">
                <span>Step 2</span>
                <h6>Select Visible Menu</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-pa-visiblity [formGp]="visibleorderForm" [disabled]="disabled" [orderFormArray]="orderFormArray"
                (submitmethod)="submitTwo()"></app-pa-visiblity>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink class="step" [class]="{'success':myForm['controls'].employeeassignright.valid}">
              <div class="count">3</div>
              <div class="content">
                <span>Step 3</span>
                <h6>Assign Rights</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-pa-assign-rights [formGpSecond]="visibleorderForm" [disabled]="disabled"
                [formGp]="employeeassignrightForm" [dataItem]="dataItem" [orderFormArray]="orderFormArray"
                (submitmethod)="submitForm()" [subMenus]="subMenu"></app-pa-assign-rights>
            </ng-template>
          </li>
        </ul>


      </aside>
      <main class=" config-container">
        <div [ngbNavOutlet]="nav" class=""></div>
        <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
      </main>
    </ng-container>
  </div>
</form>
