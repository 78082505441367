import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Router, Event, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  inbox_item: EventEmitter<any> = new EventEmitter();

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  NEEDBDAYWIDGET:any  = true
  NEEDANNIVERSARY:any = true
  constructor(
    private http: HttpClient,
    public router: Router,
    private websocket: WebSocket,
    private authService: AuthService,
    public appservice:AppService
  ) {

   }

  // Welcome Summary NRU Listed checked
  getPersonalSummary(id:number){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-personal-summary/${id}/`, this.httpOption)
  }

  // Salary Summary NRU checked
  getSalarySummary(id:number,month:any,year:number,initial_check:boolean){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-salary-summary/${id}/?month=${month}&year=${year}&initial_check=${initial_check}`, this.httpOption)
  }

  // Tax Summary NRU checked
  getTaxSummary(id:number,year:number){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-tax-summary/${id}/?year=${year}`, this.httpOption)
  }

  // Financial Summary NRU checked
  getFinancialSummary(id:number,type:any,year:number){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-financial-summary/${id}/?type=${type}&financial_year=${year}`, this.httpOption)
  }

  // Reimbursement Summary NRU checked
  getReimbursementSummary(id:any,year:number){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-reimbursement-summary/${id}/?year=${year}`, this.httpOption)
  }

  // Announcement Summary NRU Listed checked
  getAnnouncementSummary(id:number,limit:number,offset:number){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-announcement-summary/${id}/?limit=${limit}&offset=${offset}`, this.httpOption)
  }

  // Yesterday Attendance Summary NRU Listed checked
  getYesterdayAttendance(id:number){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-attendance-info/${id}/`, this.httpOption)
  }

  // Attendane Summary NRU Listed checked
  getAttendanceSummary(id:number,month:any,year:number){
    return this.http.get(`${this.API_PATH}home/api/v1/profile-dashboard-attendance-monthly-info/${id}/?month=${month}&year=${year}`, this.httpOption)
  }

  getBdayList(){
    return this.http.get(`${this.API_PATH}home/api/v1/birthday-list/`, this.httpOption)
  }

  getAnniversaryList(){
    return this.http.get(`${this.API_PATH}home/api/v1/anniversary-list/`, this.httpOption)
  }

  // Get NEW JOINEE
  getnewJoinee(){
    return this.http.get(`${this.API_PATH}organization/api/v1/new_join_employee_list/`, this.httpOption)
  }

  // ws://localhost:8001/ws/read-unread-count/remya__readunreadcount__67/
  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public InboxCountSocket(){
    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}read-unread-count/${this.appservice.getSubDomain()}__readunreadcount__${this.authService.isUserId}/`);
    this.websocket.onopen = (event:any) => {
    }

    this.websocket.onmessage = (event:any) => {
      let data = JSON.parse(event.data);
      this.inbox_item.emit(data);
    }

    this.websocket.onclose = (event:any) => {
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          if(event.url !== '/home'){
           this.closeSocket();
          }
      }
    });

    if (this.websocket.readyState === WebSocket.OPEN) {
    }

  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
      this.websocket.addEventListener('close', (event) => {
      });
    }
  }

}
