import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { LocationGroupService } from 'src/app/@core/services/location-group.service';

@Component({
  selector: 'app-location-group-setup',
  templateUrl: './location-group-setup.component.html',
  styleUrls: ['./location-group-setup.component.scss']
})
export class LocationGroupSetupComponent implements OnInit {
  title             ='Add Location Group';
  activeTab         = 1;
  disabled          = false;
  editflag: boolean = false;
  loader            = false;
  id: number        = 0;
  iseditForm        =false;
  res               :any;
  tabclick          :any;
  myForm: FormGroup = this.fb.group({
    id: 0,
    groupadd: this.fb.group({
      name        :  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],      
      states      :  [null, [Validators.required]],
      cities      :  [null, [Validators.required]]
    })
  });
  summerydata:any=[];

  constructor(
    private fb:FormBuilder,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public notificationservice:NotificationService,
    public LocationGroupService:LocationGroupService,

  ) { }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })      
  }

  get groupaddForm() {
    return this.myForm.get('groupadd') as FormGroup;
  } 

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].groupadd.invalid) {
        changeEvent.preventDefault();
      }else{
        this.tabclick    =true;
      }
    }
  }

   editForm(){
    this.loader = true;
    this.editflag = true;
    this.tabclick    =true;
    this.title ='Edit Location Group';
    this.LocationGroupService.getSingleData(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res);
    },(error)=>{
      this.router.navigate(['location-group']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }
   let cityarray:any=[];
   let statearray:any=[];
   for(let i=0;i<res.states.length;i++){
    statearray.push(res.states[i].id)
   }
   for(let i=0;i<res.cities.length;i++){
    cityarray.push(res.cities[i].id)
   }
    this.myForm.reset(
      {
        id: res.id,
        groupadd: {
          name:  res.name,
          states: statearray,
          cities: cityarray,
        }
    });
    this.loader = false;
  }

  submitForm(){
    var dataArray = {
      "name": this.groupaddForm.controls.name.value,
      "states": this.groupaddForm.controls.states.value,
      "cities": this.groupaddForm.controls.cities.value,      
   };
    if(this.id == 0){
      this.LocationGroupService.creategroup(dataArray).subscribe((res: any) => {
        this.router.navigate(['location-group']);
      },
      (error) => {
      });
    } else {
      this.LocationGroupService.updategroup(this.id,dataArray).subscribe((res: any) => {
        this.router.navigate(['location-group']);
      },
      (error) => {
      });
    }
  }

  submitfn(inp:any){
    this.activeTab = 2;
    this.summerydata =inp;
    this.tabclick    =true;

  }

  datafn(inp:any){
    this.summerydata =inp;
  }

  tabclickfn(){
    if(this.myForm['controls'].groupadd.valid){
      this.activeTab=2;
    }
  }

}
