import { Component, OnInit,Input,Output,EventEmitter,ViewChild } from '@angular/core';
import { ShiftService } from 'src/app/@core/services/shift.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-shift-rotation-configure',
  templateUrl: './shift-rotation-configure.component.html',
  styleUrls: ['./shift-rotation-configure.component.scss']
})
export class ShiftRotationConfigureComponent implements OnInit {
  shiftList:any=[];
  status=true;
  shiftarray:any = [];
  validated : boolean = false;
  selectedshifts:any=[];
  selectedshiftsearch:any=[];
  rot_freq:any;
  @Input()shiftschedule: any;
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Input()componentError:any;
  @Input()disabled              = false;
  @Input()submitted:boolean=false;
  @Input()formGp :any;
  @ViewChild(SearchbarComponent) child:any;
  searchKeyword="";
  shiftsearch='';
  selectedshiftlist:any=[];
  isOpen = true;
  noshift :any;
  constructor(private fb:FormBuilder,
    private shiftService: ShiftService,
    public messageService : MessageService,

  ) { }

  ngOnInit(): void {
    if((this.editflag==true) || (!this.formGp.invalid && this.formGp.controls['selected_shift'].value.length!=0)){
      this.noshift =false;
    }else{
      this.noshift=true;
    }
    this.getshiftlist();
    this.getselectedshiftsearchlist();
    this.removeSelectedShift();
  }

  public allowDrop(ev:any): void {
    ev.preventDefault();
  }

  public drag(ev:any): void {
    ev.dataTransfer.setData("text", ev.target.id);
  }

  public drop1(ev:any): void {

    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    // ev.target?.appendChild(document.getElementById(data));

    for(let i=0;i<this.formGp.controls['selected_shift'].value.length;i++){
      if(this.formGp.controls['selected_shift'].value[i].shiftsid == data){
        this.selectedshift().removeAt(i);
      }
    }
    const index = this.selectedshiftlist.indexOf(Number(data), 0);
    if (index > -1) {
      this.selectedshiftlist.splice(index, 1);
    }
    if(this.formGp.controls['selected_shift'].value.length==0){
      this.noshift=true;
    }
  }
  public drop2(ev:any): void {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    const index = this.selectedshiftlist.indexOf(Number(data), 0);
    if(index == -1) {
      this.selectedshift().push(this.newShift(data));
      this.selectedshiftlist.push(Number(data));
    }
    this.noshift=false;
  }
  public drop3(ev:any): void {
    ev.preventDefault();
  }

  getshiftlist(){
    this.shiftService.shiftDropDownData(this.status).subscribe((res:any)=>{
      for(let i=0;i<res.length;i++){
        res[i]['name']  = res[i]['shift_name'];
      }
      this.shiftList   = res;
      this.createShiftArray()
    })

  }
  getselectedshiftsearchlist(){
    if(this.selectedshift().controls.length>0){
      for(let i=0;i<this.selectedshift().controls.length;i++){
        this.shiftService.shiftGet(this.selectedshift().controls[i].value.shiftsid).subscribe((res: any) => {
        res['name']=res['shift_name'];
        this.selectedshiftsearch.push(res);
        });
      }
    }
  }

  searchresults(data:any){
    this.shiftList = [];
    if(data == ""){
      this.searchKeyword  = "";
      this.getshiftlist();
    }else{
      this.shiftService.shiftGet(data).subscribe((res: any) => {
        this.searchKeyword  = res.shift_name
        res['name']=res['shift_name'];
        this.shiftList = [res];
      });
    }
}

searchshift(searchKeyword:any){
  this.shiftList = [];
  this.searchKeyword = searchKeyword;
  if(searchKeyword != ""){
    this.getshiftlist();
  }
}
searchresults1(data:any){
  this.shiftListArray = [];
  if(data == ""){
    this.searchKeyword  = "";
    this.getselectedshiftsearchlist();
  }else{
    this.shiftService.shiftGet(data).subscribe((res: any) => {
      this.searchKeyword  = res.shift_name
      // res['name']=res['shift_name'];
      this.shiftListArray = [res];
    });
  }
}

searchshift1(searchKeyword:any){
this.shiftListArray = [];
this.searchKeyword = searchKeyword;
if(searchKeyword != ""){
  this.getselectedshiftsearchlist();
}
}
  validateform() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();  }

  get f() { return this.formGp.controls; }

  selectedshift():FormArray{
    return this.formGp.get('selected_shift') as FormArray;
  }
  createRange(num:number,start=1){
    var items: number[] = [];
    for(var i = start; i <= num; i++){
      items.push(i);
    }
    return items;
  }

  shiftListArray:any = [];
  createShiftArray():any{
    this.shiftList.forEach((item:any, key:any) => {
      this.shiftListArray[item.id] = item;
    });
  }

  newShift(data:any): FormGroup {
    return this.fb.group({
      "shiftsid": data
    })
  }
  removeSelectedShift(){
      this.selectedshiftlist= [];
      if(this.selectedshift().controls.length>0){
        for(let i=0;i<this.selectedshift().controls.length;i++){
          this.selectedshiftlist.push(Number(this.selectedshift().controls[i].value.shiftsid));

        }
      }

    }
    checkform(){
      if(this.selectedshift().controls.length == 0){
        this.noshift=true;
      }else{
        this.noshift=false;
      }
    }
}
