import { Component, OnInit, ViewChild } from '@angular/core';
import { DesignationService } from 'src/app/@core/services/designation.service';
import { FormBuilder,Validators  } from '@angular/forms';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit {
  showSidePanel1 = false;
  designation_max_annual_salary :any;
  designation_max_experience :any;
  designation_min_annual_salary :any;
  designation_min_experience :any;
  designation_name :any;
  designation_id :any;
  desig_is_active:any;
  searchKeyword = ""
  deleteClicked = false
  showSidePanel                  = false;
  panelheader                    = "";
  buttonTitle                    = "";
  isdelete                       = false;
  designationList:any[]	         = [];
  dataId :any                    = "";
  deleteToggle                   = false;
  submitted                      = false;
  nodatafound                    = false;
  designationPlaceholder:string  = this.messageService.searchdisplay('designation');
  searchlistdata:any             = [] ;
  inputFocusClass                = false;
  designationFilterList:any[]	   = [];
  status                         = true;
  statusBtn:any                  = "Active";
  designationForm                = this.formBuilder.group({
                                    id: 0,
                                    name: ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                                    min_experience: ['', [digitdecimalValidator(),Validators.max(100)]],
                                    max_experience: ['', [digitdecimalValidator(),Validators.max(100)]],
                                    min_annual_salary: ['', [NumberpatternValidator(),Validators.maxLength(10)]],
                                    max_annual_salary: ['', [NumberpatternValidator(),Validators.maxLength(10)]],

                                  }

                                  );

  isWarning                       = false;
  isError                         = false;
  msgText                         = "";
  color:any;
  isAlert                         = false;
  experianceValidator             = false;
  salaryValidator                 = false;
  loader                          = true;

  loader1                         = false;

  validated : boolean = false
  designUnit : any =[]
  routeComponent : any
  editflag :boolean = false
  value:any
  isLoading = false
  searchData : Subject<void> = new Subject();
  private subjectKeyUp = new Subject<any>();


  @ViewChild(SearchbarComponent) child:any;
  /********INFINITY SCROLL VARIABLES  ****/
  offset                      = 0;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  designationDetailsList:any  = [];
  infinityloader              = false;
  permissions:any = [];


/*For Add new Designation*/
  addDesignation(header:any){
    this.showSidePanel    = true;
    this.panelheader      = "Add";
    this.buttonTitle      = "ADD";
    this.editflag = false;
    this.isdelete         = false;
    this.dataId           = 0;
    // this.isSuccess        = false;
    this.status           = true;
    this.submitted        = false;
    this.designationForm.enable();
    this.designationForm.reset({ id: 0, name:'', 'min_experience': '', max_experience: '',min_annual_salary:'',max_annual_salary:'' });
    this.child.clearBar();
  }
  /*Edit an existing designation*/
  editDesignation(id:any){
    this.showSidePanel1 =false;
    this.dataId           = id;
    this.editflag         = true;
    this.showSidePanel    = true;
    this.buttonTitle      = "SAVE";
    this.isdelete         = true;
    // this.isSuccess        = false;
    this.submitted        = false;
    this.loader1 = true;
    this.designationService.designationGet(id).subscribe((res:any)=>{
        this.designationForm.reset({ id: res.id, name:res.name, 'min_experience': res.min_experience, max_experience: res.max_experience,min_annual_salary:res.min_annual_salary,max_annual_salary:res.max_annual_salary});
        this.status = res.is_active;
        this.loader = false;
        this.loader1 = false;
        if(this.status==true){
          this.designationForm.enable();
          this.panelheader  = "Edit";
        }
        else{
          this.designationForm.disable();
          this.panelheader  = "View";
        }
    })

  }
 constructor(private designationService:DesignationService,private formBuilder: FormBuilder,public breakpointObserver: BreakpointObserver,public appService: AppService,public messageService : MessageService,
  public existDataService :  ExistDatasService,
  private route: ActivatedRoute
  ) { }
 ngOnInit(): void {
  this.route.url.subscribe((res:any)=>{
    this.routeComponent = res[0].path
  })

  this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
    this.existService();
  })

  this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.designationListData();

    this.searchData.pipe(delay(1000),
    switchMap(()=> this.designationService.designationList(this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.designationListData();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
  for (let i = 0; i < itemlist; ++i) {
    this.designationList.push(this.designationDetailsList[i]);
  }
  }
  searchDesig(searchKeyword:any){
    this.isLoading = true
    this.loader = true;
    this.offset            = 0;
    this.designationList = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      // this.designationListData();
      this.searchData.next();
    }
  }

  designationListData(){
    this.loader = true;
    this.designationService.designationList(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
     this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res?.results?.length;
    if(this.offset ==0){
      this.designationList = [];
    }

    if (res?.results?.length == 0) {
      this.nodatafound = this.offset == 0 ? true :false;
    }else {
      this.nodatafound = false;
      this.designationDetailsList = res?.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }
 /*Save(dataId=0)/update(dataId=!0)*/
  onSubmit(): void {
    this.submitted = true;
    this.experianceValidator  = this.validExperiance();
    this.salaryValidator      = this.validSalary();
    if (this.designationForm.invalid || this.salaryValidator== true || this.experianceValidator == true) {
          return;


    }
    this.status = false
    this.dataId = this.designationForm.value.id;
    if(this.dataId == 0){
      this.designationService.designationSave({'name':this.designationForm.value.name,'min_experience':this.designationForm.value.min_experience!=''?this.designationForm.value.min_experience:0,'max_experience':this.designationForm.value.max_experience!=''?this.designationForm.value.max_experience:0,'min_annual_salary':this.designationForm.value.min_annual_salary!=''?this.designationForm.value.min_annual_salary:0,'max_annual_salary':this.designationForm.value.max_annual_salary!=''?this.designationForm.value.max_annual_salary:0}).subscribe((res: any) => {
        this.status = true;
        this.offset = 0;
        this.designationList = [];
        this.designationListData()
        this.showSidePanel    = false;

        this.msgText          = "created";
        this.designationForm.reset();
      },(err: any) => {this.status = true});

      this.submitted        = false;

    }else{
      this.designationService.designationUpdate(this.dataId, {'name':this.designationForm.value.name,'min_experience':this.designationForm.value.min_experience!=''?this.designationForm.value.min_experience:0,'max_experience':this.designationForm.value.max_experience!=''?this.designationForm.value.max_experience:0,'min_annual_salary':this.designationForm.value.min_annual_salary!=''?this.designationForm.value.min_annual_salary:0,'max_annual_salary':this.designationForm.value.max_annual_salary!=''?this.designationForm.value.max_annual_salary:0}).subscribe((res: any) => {
        this.status = true
        this.offset = 0;
        this.designationList = [];
        this.designationListData();
        this.msgText          = "updated";
        this.showSidePanel    = false;
      },(err: any) => {this.status = true});

    }

  }
  deletedesig(id:any){
    this.designationForm.value.id = id;
    this.deleteToggle = true;
  }
  /*Delete an existing designation*/
  deleteDesignation(){
    this.deleteClicked = true
    this.designationService.designationDelete(this.designationForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.showSidePanel  = false;
      this.offset = 0;
      this.designationList = [];
      this.designationListData();
      this.msgText         = "deleted";
      this.deleteToggle=false;
      this.deleteClicked = false
    },
    (err: any) => {
      this.deleteToggle = false
      this.showSidePanel = false
      this.deleteClicked = false
    });


  }


  get f() { return this.designationForm.controls; }

  statusfilter(status:any){
    this.loader = true;
    this.status = status;
    this.designationList = [];
    this.offset            = 0;
    // this.designationListData();
    this.child.clearBar();
  }

 // search designation
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.designationList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  validExperiance() {
    this.experianceValidator = false;
    if((this.designationForm.value.min_experience) && (this.designationForm.value.max_experience!='') || (this.dataId!=0 && this.designationForm.value.max_experience>=0)){
      if((Number (this.designationForm.value.min_experience)) > (Number (this.designationForm.value.max_experience))){
        this.experianceValidator = true;
      }
      else{
        this.experianceValidator = false;
      }
    }
    let final_status = this.experianceValidator
    return final_status;
  }

  validSalary() {
    this.salaryValidator = false;
      if(this.designationForm.value.min_annual_salary!='' && (this.designationForm.value.max_annual_salary!='' || (this.dataId!=0 && this.designationForm.value.max_annual_salary >=0))){
        if(parseInt(this.designationForm.value.min_annual_salary) > parseInt(this.designationForm.value.max_annual_salary)){
          this.salaryValidator = true;
        }
        else{
          this.salaryValidator = false;
        }
      }
    let final_salary_status = this.salaryValidator ;
    return final_salary_status;
  }
  viewDesignation(id:any){
    if(this.permissions.v){
      this.showSidePanel1 = true;
      this.loader1 = true;
      this.designationService.designationGet(id).subscribe((res: any) => {
        this.designation_max_annual_salary = res.max_annual_salary;
        this.designation_max_experience = res.max_experience;
        this.designation_min_annual_salary =res.min_annual_salary;
        this.designation_min_experience = res.min_experience;
        this.designation_name = res.name;
        this.designation_id =res.id;
        this.desig_is_active =res.is_active;
        this.loader1 = false;
      })
    }
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.dataId,
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.designUnit = res.body
      this.loader = false
      if(this.designUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
