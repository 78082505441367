import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class BusinessunitService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }


  constructor(
    private http: HttpClient,
    public router : Router
  ) { }


  getBusinessUnitList(filter:string = '',limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/business_unit_list/?is_active=${filter}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }

  businessUnitSearch(filter:string = ''){
    return this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=${filter}&ordering=name`, this.httpOption)
  }

  getBusinessUnit(id:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/business_unit_detail/${id}/`, this.httpOption)
  }

  createBusinessUnit(data:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/business_unit_create/`, data, this.httpOption)
  }

  updateBusinessUnit(id: number, body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/business_unit_detail/${id}/`, body, this.httpOption)
  }

  deleteBusinessUnit(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}organization/api/v1/business_unit_detail/${id}/`, body, this.httpOption)
  }

  getSingleBusinessUnit(id:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/business_unit_list/?id=${id}`, this.httpOption)
  }


}
