import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageAccessService } from '../../../../../@core/services/page-access.service'
import { FormBuilder,FormGroup,FormArray,FormControl,ValidatorFn,AbstractControl, Validators, Form } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-pa-visiblity',
  templateUrl: './pa-visiblity.component.html',
  styleUrls: ['./pa-visiblity.component.scss']
})
export class PaVisiblityComponent implements OnInit {

  is_active: any    = true;
  menuDetails: any  = [];
  id:any            = 0
  constructor(
    private pageAccessService:PageAccessService,
    public fb:FormBuilder,
    public router: Router,
    public route:ActivatedRoute

  ) {
   }
    // tab start
    @Input()formGp : FormGroup = this.fb.group({
      orders: [],
      menuarray:[],
      detailedArr:[]
    });
    @Input()orderFormArray:any =[];
    @Input() disabled=false;
    @Output() submitmethod = new EventEmitter();

    public menulist:any=[];
    checkboxarray:any=[];
    detailedArr: any =[]

    validateVisibleOrders(){
      if (this.formGp.invalid) {
        return;
      }
      this.formGp.enable()
      this.formGp.get('detailedArr')?.value.sort( (a:any,b:any) => a.id - b.id );
      this.submitmethod.emit();
    }
    ngOnInit(): void {
      this.addAssignRole(this.orderFormArray)

      this.route.params.subscribe((params: Params) => {
        if(isNaN(params['id'])){
          this.id = params['id']
        }
      })
      if(this.disabled === false){
        this.formGp.enable()
      }
      else
        this.formGp.disable()
    }
    assignorder() : FormArray {
      return this.formGp.get("orders") as FormArray
    }
    newAssignOrder(): FormGroup {
      return this.fb.group({
        checkorders: ['', '']
      })
    }

    addAssignRole(list:any){
      let menuarraylist = this.formGp?.value?.menuarray
      let lengthorders = this.formGp?.value?.orders?.length
      for(let i=0;i<list.length;i++){
        if(this.id == 0 && lengthorders == 0){
          this.assignorder().push(this.newAssignOrder());
        }
        if(i == 0){
          this.formGp.patchValue({
            orders:[],
            menuarray:[]
          })
        }
        // if((list[i]['id'] == 20 || list[i]['id'] == 21 || list[i]['id'] == 22 || list[i]['id'] == 84) && this.id == 0  && lengthorders == 0){

        if((list[i]['default'] == true) && this.id == 0  && lengthorders == 0){
          this.checkboxarray.push({'checkorders':true})
          this.menulist.push(list[i]['id']);
          this.detailedArr.push(list[i])
        }else if(menuarraylist.indexOf(list[i]['id']) != -1){
          this.checkboxarray.push({'checkorders':true})
          this.menulist.push(list[i]['id']);
          this.detailedArr.push(list[i])
        }
        else
        this.checkboxarray.push({'checkorders':false})

      }
      this.formGp.reset({
        orders:this.checkboxarray,
        menuarray:this.menulist,
        detailedArr : this.detailedArr
      })


    }

    roleList(status: any) {
      this.pageAccessService.getmenuList(status)
      .subscribe((res: any) => {
        if(res.status == 200) {
          if (res.body.length > 0) {
            this.menuDetails = res.body;
            return this.menuDetails;
          }
        }
      })
    }

    checkboxSelect(menu:any,event:any){
      const formArray: FormArray = this.formGp.get('menuarray') as FormArray;
      const detailedArr :  FormArray = this.formGp.get('detailedArr') as FormArray
      if(event.target.checked){
        formArray.value.push(menu?.id);
        detailedArr.value.push(menu)
      }
      else{
        let i: number = 0;
        formArray.value.forEach((ctrl: any) => {
          if(ctrl == menu?.id) {
            formArray.value.splice(i,1);
            detailedArr.value.splice(i,1)
            return;
          }
          i++;
        });
      }
    }

}

