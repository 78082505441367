import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-proposed-incomeloss-property',
  templateUrl: './proposed-incomeloss-property.component.html',
  styleUrls: ['./proposed-incomeloss-property.component.scss']
})

export class ProposedIncomelossPropertyComponent implements OnInit {

  @Input()currency:any;
  @Input()limitData:any;
  @Input()investmentDetails:any;
  @Input()submitted:boolean                 = false;

  @Output()submitmethod                     = new EventEmitter();
  @Output()onOtherincome                    = new EventEmitter<any>();

  totalExemption:number                     = 0;
  alertShow                                 = 'show';
  loss_houseproperty_exempt_limit:any       = '';
  interest_paid_houseloan_exempt_limit:any  = '';
  netIncome:any                             = 0;

  constructor(
    public fb:FormBuilder,
    public messageService : MessageService,
  ) { }

  ngOnInit(): void {
    this.totalExeptionFunction();
    this.investDataFunction();
  }

  @Input()formGp : FormGroup = this.fb.group({
    incomefrom_self_occupiedproperty  : [''],
    intreston_houseloan_amount        : [''],
    incomeself_lender                 : [],
    incomefrom_letoutproperty         : [''],
    loss_lender                       : []
  });

  /********* FORM ARRAY CREATION FUNCTIONS ****************/
  lenderIncome() : FormArray {
    return this.formGp.get("incomeself_lender") as FormArray
  }

  newLenderIncome(): FormGroup {
    if(this.formGp.value.incomefrom_self_occupiedproperty == true){
      return this.fb.group({
        "lender_name"              : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "lender_pan"               : [null,[PanfieldValidator()]],
      })
    }else{
      return this.fb.group({
        "lender_name"              : [null],
        "lender_pan"               : [null],
      })
    }
  }

  addLenderIncome() {
    this.lenderIncome().push(this.newLenderIncome());
  }

  removeLenderIncome(i:number) {
    this.lenderIncome().removeAt(i);
  }

  removeAllLenderIncome(){
    if(this.formGp.value.incomeself_lender.length > 0){
      while(this.formGp.value.incomeself_lender.length !== 0){
        this.lenderIncome().removeAt(0);
      }
    }
  }

  lenderLoss() : FormArray {
    return this.formGp.get("loss_lender") as FormArray
  }

  newLenderLoss(): FormGroup {
    if(this.formGp.value.incomefrom_letoutproperty == true){
      return this.fb.group({
        "annual_rent_received"         : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(15)]],
        "muncipal_taxpaid"             : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(15)]],
        "netvalue_ab"                  : [null],
        "standard_deduction"           : [null],
        "repaying_homeloan_property"   : false,
        "intrestpaid_houseloan_amount" : [null],
        "lender_name"                  : [null,[Validators.maxLength(100),NamefieldValidator()]],
        "lender_pan"                   : [null,[PanfieldValidator()]],
        "net_income_houseproperty"     : [null]
      })
    }else{
      return this.fb.group({
        "annual_rent_received"         : [null],
        "muncipal_taxpaid"             : [null],
        "netvalue_ab"                  : [null],
        "standard_deduction"           : [null],
        "repaying_homeloan_property"   : false,
        "intrestpaid_houseloan_amount" : [null],
        "lender_name"                  : [null],
        "lender_pan"                   : [null],
        "net_income_houseproperty"     : [null]
      })
    }
  }

  addLenderLoss() {
    this.lenderLoss().push(this.newLenderLoss());
  }

  removeLenderLoss(i:number) {
    this.lenderLoss().removeAt(i);
  }

  removeAllLenderLoss(){
    if(this.formGp.value.loss_lender.length > 0){
      while(this.formGp.value.loss_lender.length !== 0){
        this.lenderLoss().removeAt(0);
      }
    }
  }

  get f() {
    return this.formGp.controls;
  }

  /********* CHECK BOX FUNCTION ****************/
  checkBoxFunctionTest(e:any, amountName:any) {

    var checkControlName  = e.target.name;
    var amountControlName = amountName;
    if(e.target.checked){

        this.formGp.get(checkControlName)?.setValue(true);
        this.validationFunction(true,checkControlName,amountControlName);

        if(e.target.name == 'incomefrom_self_occupiedproperty' && this.formGp.value.incomeself_lender.length == 0)
          this.addLenderIncome();

    }else{

      this.formGp.get(checkControlName)?.setValue(false);
      this.formGp.get(amountControlName)?.setValue('');
      this.validationFunction(false,checkControlName,amountControlName);
      this.removeAllLenderIncome();

    }
  }

  /********* VALIDATION AND SET VALUE FUNCTIONS ****************/
  validationFunction(check:any,checkCn:any,amountCn:any){
    if(check == true){
      this.formGp.get(amountCn)?.setValidators([Validators.required, digitdecimalValidator(),Validators.maxLength(15)]);
    }else{
      this.formGp.get(amountCn)?.clearValidators();
    }
    this.formGp.get(amountCn)?.updateValueAndValidity();
  }

  letoutFunction(e:any){
    var checkControlName  = e.target.name;
    if(e.target.checked){

      this.formGp.get(checkControlName)?.setValue(true);
      if(this.formGp.value.loss_lender.length == 0)
        this.addLenderLoss();
    }
    else{

      this.formGp.get(checkControlName)?.setValue(false);

        this.removeAllLenderLoss();
    }
  }

  repayingFunction(e:any,index:any){
    var checkControlName  = e.target.name;
    if(e.target.checked){
      (<FormArray>this.formGp.get("loss_lender")).at(index).get(checkControlName)?.setValue(true);
      this.repayingValidationFunction(true,index);
    }
    else{
      (<FormArray>this.formGp.get("loss_lender")).at(index).get(checkControlName)?.setValue(false);
      this.repayingValidationFunction(false,index);
    }
    this.netIncomeFunction(index);

  }

  repayingValidationFunction(check:any,index:any){
    if(check == true ){
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.setValidators([Validators.required,Validators.maxLength(100),NamefieldValidator()]);
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.setValidators([PanfieldValidator()]);
    }else{
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.clearValidators();
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.clearValidators();
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.clearValidators();

      (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.setValue('');
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.setValue('');
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.setValue('');
    }
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.updateValueAndValidity();
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.updateValueAndValidity();
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.updateValueAndValidity();
  }

  disabledFunction(){
    if(this.formGp.value.loss_lender.length > 0){
      for(let i=0; i< this.formGp.value.loss_lender.length; i++){
        (<FormArray>this.formGp.get("loss_lender")).at(i).get('netvalue_ab')?.disable();
        (<FormArray>this.formGp.get("loss_lender")).at(i).get('standard_deduction')?.disable();
      }
    }
  }

  netValueCalc(a:any,b:any,index:any){

    let c     = (Number(a)-Number(b));
    let d:any = Number(c)*(30/100);
    d         = parseFloat(d).toFixed(2);
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('netvalue_ab')?.setValue(c);
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('standard_deduction')?.setValue(Number(d));

    this.netIncomeFunction(index);
    this.totalExeptionFunction();

  }

  netIncomeFunction(index:any){
    if(this.formGp.value.loss_lender.length > 0){
      let c         = 0;
      let d         = 0;
      let e         = 0;
      let netAmount = 0;
      c             = Number(c) + Number((<FormArray>this.formGp.get("loss_lender")).at(index).get('netvalue_ab')?.value);
      d             = Number(d) + Number((<FormArray>this.formGp.get("loss_lender")).at(index).get('standard_deduction')?.value);
      e             = Number(e) + Number((<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.value);
      netAmount     = (Number(c)-(Number(d)+Number(e)));
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('net_income_houseproperty')?.setValue(netAmount);
    }
    this.totalExeptionFunction();
  }

  totalExeptionFunction(){
    if(this.formGp.value.loss_lender.length > 0){
      this.netIncome = 0;
      for(let i=0; i< this.formGp.value.loss_lender?.length; i++){
        this.netIncome +=  (<FormArray>this.formGp.get("loss_lender")).at(i).get('net_income_houseproperty')?.value
      }
    }

    this.totalExemption = -(Number(this.formGp.value.intreston_houseloan_amount == "" ? 0 : this.formGp.value.intreston_houseloan_amount == null ? 0 : this.formGp.value.intreston_houseloan_amount))+(Number(this.netIncome == "" ? 0 : this.netIncome == null ? 0 : this.netIncome));

    return this.totalExemption;
  }

  validateIncomeLoss(btnText:any){
    if(btnText == 'skip'){
      this.removeAllLenderIncome();
      this.removeAllLenderLoss();
      this.formGp.get('incomefrom_self_occupiedproperty')?.setValue(false);
      this.formGp.get('intreston_houseloan_amount')?.setValue('');
      this.formGp.get('incomefrom_letoutproperty')?.setValue(false);
      this.clearValidation();
    }
    else{
      this.submitted = true;
      if (this.formGp.invalid) {
        return;
      }
    }
    this.submitmethod.emit();
  }

  clearValidation(){
    this.formGp.get('intreston_houseloan_amount')?.clearValidators();
    this.formGp.get('intreston_houseloan_amount')?.updateValueAndValidity();
  }

  investDataFunction(){
    if(this.investmentDetails.count_info.hasOwnProperty('statutory_tax_settings')){
      this.loss_houseproperty_exempt_limit      = this.investmentDetails?.count_info?.statutory_tax_settings?.loss_houseproperty_exempt_limit;
      this.interest_paid_houseloan_exempt_limit = this.investmentDetails?.count_info?.statutory_tax_settings?.interest_paid_houseloan_exempt_limit;
    }
  }

}
