<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500 mb-24">Upload Part B <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="It is a consolidated statement covering details like salary paid, amount of tax paid, tax exempted and tax due." popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span></p>
        </div>

        <div class="row row-24">
            <div class="col-12" >
                <div class="card  card-c2 ">
                    <div class="card-body p-24 ">
                        <p class="mb-16 text-uppercase fw-500">Upload File</p>
                        <div class="row">
                          <div class="col-md-6" *ngIf="fileName!=''">

                            <div class="card card-c2  bg-secondary" *ngIf="fileName!=''">
                              <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                  <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                                  <span class="tips-helper ">{{fileName}}</span>

                                </div>
                                <i class="icon-trash  ms-auto flex-center link-danger" *ngIf="permissions && permissions.d" (click)="deleteFile()"></i>
                              </div>
                            </div>
                            <!-- <div class="card card-c2 bg-secondary" *ngIf="fileName!=''">
                              <div class="d-flex p-24">
                                  <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                  <span class="text-trim fw-500">{{fileName}}</span>
                                  <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                                  <span class="link-danger fw-500 flex-shrink-0" *ngIf="permissions && permissions.d" (click)="deleteFile()" ><i class="bi bi-trash ms-8 "></i></span>
                              </div>
                            </div> -->
                            <div class="d-flex fs-12 my-6 gap-4 align-items-center gap-4" *ngIf="uploadedEmployee!='' && uploadedEmployee!=undefined">
                              <i class="bi bi-info-circle"></i>
                              <span class="">Uploaded by : </span>
                              <span class="fw-500">{{uploadedEmployee}}</span>
                            </div>
                          </div>
                      </div>
                      <label *ngIf="fileName == ''" for="upload" class="btn btn-outline-primary radius-2 d-inline-flex px-5 is-invalid" [class.disabled]="fileName != ''">
                          <input type="file" name="" id="upload" hidden (change)="onChange($event)">Upload file
                      </label>
                      <span class="invalid-feedback" *ngIf="invalid">Kindly upload the file in zip format.</span>
                    </div>
                </div>
            </div>
            <div class="col-12" >
                <div class="card card-c2 ">
                    <div class="card-body p-24 ">
                        <p class="mb-24 text-uppercase fw-500">Instructions</p>
                        <ol class="fs-14 ps-16 mb-0 li-24">
                           <li>Click on the upload button.</li>
                           <li>Browse the saved Part-B zip file and upload it.</li>
                           <li>Click on the Next button.</li>
                        </ol>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName=='' " (click)="submitfile()">NEXT</button>

    </footer>
  </form>
  <app-upload-progress-bar [progress]="progress" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Preparing'" [body]="'Preparing generation of Form 16 documents'" [processingText]="'Processing...'">

  </app-upload-progress-bar>
  <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Delete Part B?</h4>
            </div>
            <div class="modal-body">
              <p class="mb-0">You are trying to delete the Part B files uploaded by {{deletepopup_B}}. All the data associated with this file will be permanently lost. Do you wish to continue?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="deleteToggle = false">Cancel</button>
                <button type="button" class="btn btn-danger btn-sm text-uppercase" (click)="deletPartB()">Delete</button>
            </div>
        </div>
    </div>
  </div>
