import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-policy-documents',
  templateUrl: './policy-documents.component.html',
  styleUrls: ['./policy-documents.component.scss']
})
export class PolicyDocumentsComponent implements OnInit {
  activeTab = 1;
  menuData:any = [];

  menuList:any;

  constructor(
    public route:ActivatedRoute,
    private appService: AppService,
    public router: Router
  ) { }
  ngOnInit(): void {
   this.getPermission()

  }
  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });
    this.reDirectTo();
  }

  reDirectTo(){

    if(this.router.url == '/document'){
      if(this.menuData.length>0)
      this.router.navigate([this.menuData[0]?.link]);
    }
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/document');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }
}
