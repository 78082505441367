import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-loan-filter',
  templateUrl: './loan-filter.component.html',
  styleUrls: ['./loan-filter.component.scss']
})
export class LoanFilterComponent implements OnInit {

  // Input
  @Input() filterPanel : any
  @Input() filterForm  : any
  @Input() from        : any
  @Input() OuFilterForm        : any

  // Output
  @Output() filterData        = new EventEmitter();
  @Output() close             = new EventEmitter();
  @Output() reset             = new EventEmitter();

  get f()  { return this.filterForm.controls; }

  // Var Initialisation
  loan_type_list       : any  = []
  loan_code_list       : any  = []
  status               : any  = [
    {id:"",name:"All"},
    {id:"Active",name:"Active"},
    {id:"Closed",name:"Closed"},
    {id:"Restructured",name:"Restructured"}
  ]



  constructor(
    public messageService : MessageService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appService      : AppService,
    private fb             : FormBuilder,
    private loanServ     : LoanProcessService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filterPanel'] && changes['filterPanel'].currentValue) {
      this.getLoanType() // Loan Type Dropdown
      this.getLoanCode() // Loan Code Dropdown
    }
  }

  getLoanType(){
    if(this.from == 'loanHR'){
      this.loanServ.listLoanTypes().subscribe((res:any)=>{
        this.loan_type_list = res?.body?.data
      })
    } else {
      this.loanServ.getLoanTypesDropdown().subscribe((res:any)=>{
        this.loan_type_list = res?.data
      })
    }
  }

  getLoanCode(){
    if(this.from == 'loanHR'){
      this.loanServ.listLoanCodes().subscribe((res:any)=>{
        this.loan_code_list = res?.body?.data?.loan_codes
      })
    } else {
      this.loanServ.getLoanCodeDropdown().subscribe((res:any)=>{
        this.loan_code_list = res?.body?.data?.loan_codes
      })
    }
  }

  closeFilter(){
    this.filterPanel  = false
    this.close.emit();
  }

  resetFilter(){
    this.filterForm.reset();
    this.reset.emit()
    this.closeFilter()
  }

  applyFilter(){
    let dataFilter = ''
    let loan_type = this.filterForm.controls.loan_type.value !=null ? this.filterForm.controls.loan_type.value : '';
    let loan_code = this.filterForm.controls.loan_code.value !=null ? this.filterForm.controls.loan_code.value : '';
    let status = this.filterForm.controls.status.value !=null ? this.filterForm.controls.status.value : '';
    if(this.from == 'loanHR'){
      let company = this.OuFilterForm.controls.company.value
      let bu = this.OuFilterForm.controls.bu.value
      let dept  = this.OuFilterForm.controls.department.value
      let desig = this.OuFilterForm.controls.designation.value
      let branch = this.OuFilterForm.controls.branch.value
      let employee_list = this.OuFilterForm.controls.employee_list.value
      let grade = this.OuFilterForm.controls.grade.value
      let employment_status = this.OuFilterForm.controls.employment_status.value
      let employee_type = this.OuFilterForm.controls.employee_type.value
      dataFilter = `loan_code=${loan_code}&loan_type__loan_type=${loan_type}&status__in=${status}&employee__company_id__in=${company}&employee__businessunit_id__in=${bu}&employee__department_id__in=${dept}&employee__designation_id__in=${desig}&employee__branch_id__in=${branch}&employee__grade_id__in=${grade}&employee__employment_type__in=${employee_type}&employee__employment_status__in=${employment_status}&employee__id__in=${employee_list}`
    } else {
      let requested_from_date = this.filterForm.controls.requested_from_date.value !='' ? moment(this.filterForm.controls.requested_from_date.value).format('YYYY-MM-DD') : '';
      let requested_to_date = this.filterForm.controls.requested_to_date.value !='' ? moment(this.filterForm.controls.requested_to_date.value).format('YYYY-MM-DD') : '';
      let closed_from_date = this.filterForm.controls.closed_from_date.value !='' ? moment(this.filterForm.controls.closed_from_date.value).format('YYYY-MM-DD') : '';
      let closed_to_date = this.filterForm.controls.closed_to_date.value !='' ? moment(this.filterForm.controls.closed_to_date.value).format('YYYY-MM-DD') : '';
      dataFilter = `loan_code=${loan_code}&loan_type__loan_type=${loan_type}&status__in=${status}&requested_from_date=${requested_from_date}&requested_to_date=${requested_to_date}&closed_from_date=${closed_from_date}&closed_to_date=${closed_to_date}`
    }
    this.filterData.emit(dataFilter)
  }

  // Date Format
  getDateFormat() {
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if (this.matDateFormat.display.dateInput == '') {
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  dojFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }
}
