<div  class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
            <h4 class="modal-title" >{{modalHeading}}</h4>
            </div>

            <div *ngIf="failedData?.length>0 && successDataCount>0" class="modal-body">
                    <p class="mb-0 d-flex align-items-baseline"><i class="icon-check text-success me-8"></i>{{successMsg}}</p>
                    <p class="mb-0 d-flex align-items-baseline"><i class="bi bi-exclamation-triangle text-danger fs-14 me-8"></i>{{errorMsg}}</p>
            </div>
            <div *ngIf="failedData?.length>0 && successDataCount==0" class="modal-body">
                <p class="mb-0  d-flex align-items-baseline"><i class="bi bi-exclamation-triangle text-danger fs-14 me-8"></i>{{errorMsg}}</p>
            </div>
            <div *ngIf="failedData?.length==0 && successDataCount>0" class="modal-body">
                <p class="mb-0  d-flex align-items-baseline"><i class="icon-check text-success me-8"></i>{{successMsg}}</p>
            </div>
            <div  class="modal-footer">
                <button *ngIf="failedData?.length>0" type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadError();">Download</button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="closePanel.emit();">Close</button>
            </div>
        </div>
    </div>
</div>