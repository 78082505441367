import { Component, OnInit, ViewChild } from '@angular/core';
import { ChallanSettingsPfService } from 'src/app/@core/services/challan-settings-pf.service';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-challan-provident-fund',
  templateUrl: './challan-provident-fund.component.html',
  styleUrls: ['./challan-provident-fund.component.scss']
})

export class ChallanProvidentFundComponent implements OnInit {

  challansettingsView   = false;
  loader                = true;
  pfList:any            = [];
  nodata                = false;
  loader1               = false;
  pfView:any            = [];
  assignees:any         = [];
  deleteToggle          = false;
  deleteClicked         = false
  pfId                  = 0;
  searchlistdata:any    = [];
  pfsearch:string       = this.messageService.searchdisplay('PF group');
  filterPanel           = false;
  filterData:any        = '';

  /********INFINITY SCROLL VARIABLES  ****/
  offset                = 0;
  listlength            = 0;
  direction             = '';
  defaultlimit:any      = 20;
  pfDetailsList:any     = [];
  infinityloader        = false;
  permissions:any       = [];
  searchKeyword         = "";
  isLoading = false
  searchData : Subject<void> = new Subject();

  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private challanSettingsPfService: ChallanSettingsPfService,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getPermission()
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.showDataItems();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.challanSettingsPfService.challanPfList(this.defaultlimit,this.offset,this.searchKeyword,this.filterData)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  showDataItems(){
    this.loader = true;
    this.challanSettingsPfService.challanPfList(this.defaultlimit,this.offset,this.searchKeyword,this.filterData).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.listlength = res?.results?.length;
    this.isLoading = false
    if(this.offset == 0){
      this.pfList = [];
    }
    if (res?.results?.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata        = false;
      this.pfDetailsList = res?.results;
    }
    this.infinityloader  = false;
    this.addItems(this.listlength);
    this.loader          = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.pfList?.push(this.pfDetailsList[i]);
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  viewPf(id:any){
    if(this.permissions.v){
      this.challansettingsView = true;
      this.loader1             = true;
      this.challanSettingsPfService.challanPfView(id).subscribe((res: any) => {

        this.pfView     = res;
        let key         = this.pfView?.applicability?.applicable;
        this.assignees  = this.pfView?.applicability[key];
        if(key == 'BU'){
          this.pfView.applicability['applicable']='Business Unit';
        }
        this.loader1   = false;
      })
    }
  }

  deleteModal(id:any){
    this.pfId         = id;
    this.deleteToggle = true;
  }

  deletePf(){
    this.deleteClicked = true;
    this.challanSettingsPfService.challanPfDelete(this.pfId,{'is_active' : false}).subscribe((res: any) => {
      this.offset         = 0;
      this.pfList         = [];
      this.showDataItems();
      this.deleteToggle   = false;
      this.deleteClicked  = false;
    },
    (err: any) => {
      this.deleteToggle   = false;
      this.deleteClicked  = false;
    });
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset         = 0;
      this.pfList         = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchPfGroup(searchKeyword:any){
    this.loader         = true;
    this.isLoading      = true;
    this.offset         = 0;
    this.pfList         = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  closeFilter(){
    this.filterPanel = false;
  }

  submitForm(data:any){
    this.filterPanel = false;
    let companyList = data.companyList
    let buList      = data.buList
    let BranchList  = data.BranchList
    let cityList    = data.cityList
    let status      = data.status
    this.filterData  = `company=[${companyList}]&business_unit=[${buList}]&branch=[${BranchList}]&city=[${cityList}]&is_active=${status}`
    this.offset       = 0;
    this.showDataItems()
  }

  resetFilter(){
    this.filterData   = '';
    this.child.clearBar();
    this.offset       = 0;
    this.filterPanel  = false;
  }
}
