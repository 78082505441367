import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/@core/services/company.service';
import { ConfigurationService } from 'src/app/@core/services/configuration.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-configuration-setup',
  templateUrl: './configuration-setup.component.html',
  styleUrls: ['./configuration-setup.component.scss']
})
export class ConfigurationSetupComponent implements OnInit {

  checkboxes: any[]  = [];
  activeTab          = 1;
  pay_frequency      = ['Monthly'];
  companyList:any[]  = [];
  id: number         = 0;
  loader             = false;
  iseditForm         = false;
  disabled           = false
  title              = "Add Payroll Configuration"
  tab2touched        = false
  compArr: any       = [];
  selectedComp: any  = [];
  editCompId:any     = 0;
  searchlistdata:any = [];
  comp_id:any;
  pay_cycle = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigurationService
  ) { }

  configForm: FormGroup = this.fb.group({

    company                                  : this.fb.array([]),
    pay_frequency                            : ['Monthly',[Validators.required]],
    first_pay_cycle                          : [null,[Validators.required]],
    first_pay_year                           : [null,[Validators.required]],
    pay_period                               : [false,[Validators.required]],
    pay_period_day                           : [null,],
    pay_day_status                           : [false,[Validators.required]],
    pay_day                                  : [null],
    attendance_lop                           : [true],
    work_day_status                          : [false,[Validators.required]],
    work_day                                 : [null],
    exclude_weekoff_status                   : [false,[Validators.required]],
    exclude_weekoff                          : [null],
    // exclude_holiday_status                   : [false,[Validators.required]],
    // proposed_declaration_cuttoff             : [null,[Validators.required]],
    proposed_declaration_fromdate            : [null,[Validators.required]],
    proposed_declaration_todate              : [null,[Validators.required]],
    proposed_declaration_fy_month            : [null,[Validators.required]],
    proposed_declaration_fy_date             : [null,[Validators.required]],
    proposed_required_approval_beforecutoff  : [false,[Validators.required]],
    proposed_allow_submit_aftercuttoff       : [false,[Validators.required]],
    proposed_required_approval_aftercuttoff  : [false,[Validators.required]],
    confirmed_declaration_fy_month           : [null,[Validators.required]],
    confirmed_declaration_fy_date            : [null,[Validators.required]],
    reimbursement_entitlement_day            : [null,[Validators.required]],
    // reimbursement_request_cuttoffmonth       : [null,[Validators.required]],
    reimbursement_request_fromdate           : [null,[Validators.required]],
    reimbursement_request_todate             : [null,[Validators.required]],
    reimbursement_type                       : ['Tax exemption provided on reimbursement component till year end. Non bill submitted amount taxed at year end',[Validators.required]],
    // attend_cutoff                            : [false,[Validators.required]],
    // attend_cutoff_day                        : [null],
    // proposed_declaration_day                 : [null,[Validators.required]],
    // proposed_declaration_year                : [null,[Validators.required]]
    prior_payroll                             : [false,[Validators.required]],
    fbp_duration_day                          : [15,[Validators.required,NumberpatternValidator(),Validators.max(90),Validators.min(1)]],
	send_confirm_investment_alert             : [false,[Validators.required]],
    specify_days                              : ['60'],
    can_edit_loanAmount_emi_by_owner                              : [false],
    can_edit_loanInterest_by_owner                            : [false]
  })

  ngOnInit(): void {
    this.loader = true;

    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
      else
        this.getCompanyList();

    })

  }

  get formArray(){
    return this.configForm.controls['company'] as FormArray;
  }


getCompanyList(){
  this.configService.getExcludedCompList(true,'','',0,'').subscribe((res: any)=>{
    this.companyList = res.body;
    this.createCheckBoxArray();
    this.loader      = false;

  })
}
activeCheckbox(data:any){
  this.checkboxes = [];
  this.checkboxes = data;
}
createCheckBoxArray(){
  for(let i=0;i<this.companyList.length;i++)
  {
    this.comp_id    = this.companyList[i].id;
    var dict:any    = {};
    dict['id']      = this.comp_id;
    dict['value']   = false;
    this.checkboxes.push(dict);

  }

}
changeTab(tabIndex:any){
  this.activeTab = tabIndex;
}

submitForm(){
    let exclude_weekoff:any = [];
    var dataArray = this.configForm.value;

    if(this.id != 0){
      delete (dataArray['company']);
      dataArray['company'] = this.editCompId
    }
    if(this.configForm.value.exclude_weekoff_status==false || this.configForm.value.attendance_lop==false){
      delete (dataArray['exclude_weekoff']);
      dataArray['exclude_weekoff'] = exclude_weekoff;
    }
    if(this.id == 0){
      this.configService.createConfig(dataArray).subscribe((res: any) => {
        this.router.navigate(['configuration']);
      });
    } else {
      this.configService.updateConfig(dataArray,this.id).subscribe((res: any) => {
        this.router.navigate(['configuration']);
      },
      (error) => {

      });
    }
  }

  editForm(){
    this.loader = true;
    this.configService.getConfigDetail(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res.body);
      this.title ="Edit Payroll Configuration"
    },(error)=>{
      this.router.navigate(['configuration']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.configForm.disable();
      this.disabled = true;
    }
    this.editCompId = res.company;

    let compArray:any = [];
    compArray.push(String(res.company));
    this.formArray.push(new FormControl(String(res.company)));
    Object.entries(res).forEach(([key, value]) => {
      if(key!='company'){
        this.configForm.get(key)?.setValue(value)
      }
      else
        this.configForm.get(key)?.setValue(compArray)
      if(key=='prior_payroll'){
        this.configForm.controls[key].disable();
      }


    })

    this.getCurrentCompany(res.company);
    this.buildSelectedData();


  }
  getCurrentCompany(id:any){
    this.companyService.companyGet(id).subscribe((res:any)=>{
      this.companyList = [res.body];

    })
  }
  submitMethod(activeTab: any){
    this.buildSelectedData()
    this.activeTab = activeTab;
     if (activeTab === 2) {
      this.tab2touched =true
    }

  }
  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {

      if (this.configForm.value.company.length==0) {
        changeEvent.preventDefault();
      } else
      this.tab2touched =true
      this.buildSelectedData()
    }
  }
  buildSelectedData(){

      this.compArr = [];
      for(let selectedId of this.configForm.value.company){
        var selectedComp =  this.companyList.filter(function(compId:any) {

          return compId.id == selectedId;
        });
        this.compArr.push(selectedComp[0]);

      }
      this.selectedComp = this.compArr;
      setTimeout(() => {
        this.loader = false;
      }, 1000);
  }
  clearComp(index:number){
    this.formArray.removeAt(index);
    this.buildSelectedData()

  }

}
