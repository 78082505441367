<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">REview</p>
        </div>
        <div class="row row-16">
            <div class="col-12">
                <div class="card details-card shadow-0 p-24">
                    <div class="row row-16">
                        <div class="col-12">
                            <p class="title">Trip details</p>
                        </div>
                        <div class="col-sm-6">
                            <div class="detail-title">Trip Name</div>
                            <div class="detail-desc">Trip 1 </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="detail-title">Trip Type</div>
                            <div class="detail-desc"><i
                                    class="bi-record-circle-fill me-8 text-light-400"></i>International</div>
                        </div>
                        <div class="col-12">
                            <div class="detail-title">Destination Country</div>
                            <div class="detail-desc">United Kingdom</div>
                        </div>
                        <div class="hstack align-items-center gap-8 fs-14">
                            <i class="icon-check-square-fill text-light-400"></i>Is visa required?
                        </div>
                        <div class="col-sm-4 col-12">
                            <div class="detail-title">Visa Number</div>
                            <div class="detail-desc">VISA157HSL12</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card details-card shadow-0 p-24">
                    <div class="row row-16">
                        <div class="col-12">
                            <p class="title">TRIP ITENERARY</p>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 p-24">
                                <div class="row row-16">
                                    <div class="col-12">
                                        <p class="title">Flight</p>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Trip Option</div>
                                        <div class="detail-desc"><i
                                                class="bi-record-circle-fill me-8 text-light-400"></i>Multi-city</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="card details-card shadow-0 p-24">
                                            <div class="row row-16">
                                                <div class="col-12">
                                                    <p class="title">1. FLIGHT</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Depart From</div>
                                                    <div class="detail-desc">Dept 1</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Arrive At</div>
                                                    <div class="detail-desc">Arrive 1</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="detail-title">Departure Time</div>
                                                    <div class="detail-desc">12:00:00 AM - 08:00:00 AM</div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="detail-title">Flight Preference </div>
                                                    <div class="detail-desc">Premium Economy </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Departure Date </div>
                                                    <div class="detail-desc">12/12/2023 </div>
                                                </div>
                                                <div class="col-12">

                                                    <div class="hstack align-items-center gap-8 fs-14">
                                                        <i class="icon-check-square-fill text-light-400"></i> Do you
                                                        want company to book ticket on your behalf?
                                                    </div>
                                                </div>
                                                <div class=" col-12">
                                                    <div class="detail-title">Description </div>
                                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                        dolore magna aliqua. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 p-24">
                                <div class="row row-16">
                                    <div class="col-12">
                                        <p class="title">HOTEL</p>
                                    </div>

                                    <div class="col-12">
                                        <div class="card details-card shadow-0 p-24">
                                            <div class="row row-16">
                                                <div class="col-12">
                                                    <p class="title">1. HOTEL</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Check-in Date</div>
                                                    <div class="detail-desc">12/12/2023</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Check-in Time</div>
                                                    <div class="detail-desc">07:00:00 AM</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="detail-title">Check-out Date</div>
                                                    <div class="detail-desc">12/12/2023</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Check-out Time</div>
                                                    <div class="detail-desc">07:00:00 AM</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="detail-title">Location</div>
                                                    <div class="detail-desc">Manchester</div>
                                                </div>
                                                <div class="col-12">

                                                    <div class="hstack align-items-center gap-8 fs-14">
                                                        <i class="icon-check-square-fill text-light-400"></i>Do you want
                                                        company to book accomodation on your behalf?
                                                    </div>
                                                </div>
                                                <div class=" col-12">
                                                    <div class="detail-title">Description </div>
                                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                        dolore magna aliqua. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 p-24">
                                <div class="row row-16">
                                    <div class="col-12">
                                        <p class="title">CAR RENTAL</p>
                                    </div>

                                    <div class="col-12">
                                        <div class="card details-card shadow-0 p-24">
                                            <div class="row row-16">
                                                <div class="col-12">
                                                    <p class="title">1. CAR RENTAL</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Pick-up Date</div>
                                                    <div class="detail-desc">12/12/2023</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Pick-up Time</div>
                                                    <div class="detail-desc">07:00:00 AM</div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Pick-up Location</div>
                                                    <div class="detail-desc">Manchester</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="detail-title">Drop-off Date</div>
                                                    <div class="detail-desc">12/12/2023</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Drop-off Time</div>
                                                    <div class="detail-desc">07:00:00 AM</div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Drop-off Location</div>
                                                    <div class="detail-desc">Manchester</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="detail-title">Car Type</div>
                                                    <div class="detail-desc">Premium</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Driver needed?</div>
                                                    <div class="detail-desc"><i
                                                            class="bi-record-circle-fill me-8 text-light-400"></i>Yes
                                                    </div>
                                                </div>
                                                <div class="col-12">

                                                    <div class="hstack align-items-center gap-8 fs-14">
                                                        <i class="icon-check-square-fill text-light-400"></i>Do you want
                                                        company to book accomodation on your behalf?
                                                    </div>
                                                </div>
                                                <div class=" col-12">
                                                    <div class="detail-title">Description </div>
                                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                        dolore magna aliqua. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 p-24">
                                <div class="row row-16">
                                    <div class="col-12">
                                        <p class="title">BUS</p>
                                    </div>

                                    <div class="col-12">
                                        <div class="card details-card shadow-0 p-24">
                                            <div class="row row-16">
                                                <div class="col-12">
                                                    <p class="title">1. BUS</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Depart From</div>
                                                    <div class="detail-desc">Frankfurt</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Arrive At</div>
                                                    <div class="detail-desc">Manchester</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="detail-title">Departure Date</div>
                                                    <div class="detail-desc">12/12/2023</div>
                                                </div>

                                                <div class="col-12">

                                                    <div class="hstack align-items-center gap-8 fs-14">
                                                        <i class="icon-check-square-fill text-light-400"></i>Do you want
                                                        company to book accomodation on your behalf?
                                                    </div>
                                                </div>
                                                <div class=" col-12">
                                                    <div class="detail-title">Description </div>
                                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                        dolore magna aliqua. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 p-24">
                                <div class="row row-16">
                                    <div class="col-12">
                                        <p class="title">Train</p>
                                    </div>

                                    <div class="col-12">
                                        <div class="card details-card shadow-0 p-24">
                                            <div class="row row-16">
                                                <div class="col-12">
                                                    <p class="title">1. Train</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Depart From</div>
                                                    <div class="detail-desc">Frankfurt</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Arrive At</div>
                                                    <div class="detail-desc">Manchester</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="detail-title">Departure Date</div>
                                                    <div class="detail-desc">12/12/2023</div>
                                                </div>

                                                <div class="col-12">

                                                    <div class="hstack align-items-center gap-8 fs-14">
                                                        <i class="icon-check-square-fill text-light-400"></i>Do you want
                                                        company to book accomodation on your behalf?
                                                    </div>
                                                </div>
                                                <div class=" col-12">
                                                    <div class="detail-title">Description </div>
                                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                        dolore magna aliqua. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-12">
                            <div class="detail-title">Document </div>
                            <div class="detail-desc">
                                <div class="card card-c2  bg-secondary">
                                    <div class="p-12 bg-secondary d-flex align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                        <div
                                            class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                            <span class="tips text-uppercase fs- fw-700 text-trim">DOCUMENT FILES
                                            </span>
                                            <span class="tips-helper ">DOCUMENT FILES</span>
                                            <div class="d-flex gap-8">
                                                <div class="link-primary">
                                                    <i class="bi bi-eye me-1"></i>View
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-12">
                            <div class="detail-title">Remark </div>
                            <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                dolore magna aliqua. </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto">NEXT</button>
    </footer>
</form>