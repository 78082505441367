<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Template</h5>
    <button class="btn-reset text-light-600" routerLink="/template"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success':formvalue}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Template details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-template-details [id]="id" [templatedetailForm]="TemplatedetailsForm"
            (onpreviewclick)="onPreviewClick($event)"></app-template-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" [disabled]="disabled">
        <a ngbNavLink class="step">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Preview</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-template-preview [previewall]="formvalue"
            (confirmbuttonclick)="submitForm($event)"></app-template-preview>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
