import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AppService } from 'src/app/app.global';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { ProfileDocumentService } from 'src/app/@core/services/profile-document.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';


@Component({
  selector: 'app-view-employee-profile',
  templateUrl: './view-employee-profile.component.html',
  styleUrls: ['./view-employee-profile.component.scss']
})
export class ViewEmployeeProfileComponent implements OnInit {

  modalToggle                = false;
  currentDocName             = "";
  currentExtension           = "";
  allowedExtension           = "";
  fileName:any;
  alertToggle                = false;
  empalertMsg:any            = '';
  familyData:any             = [];
  childData:any              = [];
  depData:any                = [];
  flag                       = true;
  addedData:any;
  updatedData:any;
  options:any;
  familyUpdated:any;
  childUpdated:any;
  dependUpdated:any;
  newDocId:any = [];
  oldDocId:any = [];
  loader1 : boolean = false;

  /********VIEW STATUS VARIABLES  ****/
  viewstatusPanel           = false;
  viewStatusData:any        = [];
  approvalRequired          = false;
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  fromViewStatus            = '';
  loader2                   = false;

  @Input()requestitem:any;
  @Input()loader:any;
  @Input()from:any;
  @Input()currentStaus:any;
  @Input()documentSingleData:any;
  @Input()reqId:any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  specificId: any;

  constructor(
    public appService: AppService,
    public userData : AuthService,
    private inboxservice: InboxService,
    private profileDocumentService: ProfileDocumentService,
    private investmentService: InvestmentDeclerationService,
  ) {
    this.investmentService.request_id.subscribe(result =>{
      if(Number(this.reqId) == Number(result))
        this.docViewStatus(this.specificId);
    })
   }

  ngOnInit(): void {
    setTimeout(() => {
      if(this.requestitem)
        this.function();
    }, 1000);
  }

  ngOnChanges(){
    this.loader = true;
    setTimeout(() => {
      if(this.requestitem)
        this.function();
    }, 1000);
  }

  replace(str: String){
    if(str != undefined){
      return str.replace(/[\_]/g," ");
    }else{
      return;
    }
  }

  formatDate(key:any = '',val: any){
    let date: any;
    if(key != '' && (key == 'from_date' || key =='to_date' || key == 'year_of_passing' || key == 'passport_expiry_date' || key == 'child_date_of_birth'
      || key == 'father_date_of_birth' || key == 'mother_date_of_birth' || key == 'spouse_date_of_birth' || key == 'dependent_DOB'
      || key == 'insuree_of_birth' || key == 'issue_date' || key == 'valid_upto')){
     date = this.appService.dateFormatDisplay(val);
     return date;
    }
    else if(key == ''){
      date = this.appService.dateFormatDisplay(val);
     return date;
    }
    else{
      return val;
    }

  }



  // downloadDoc(filename:any,docType: any){
  //   this.currentExtension = filename.split('.').pop();
  //   let FileSaver         = require('file-saver');
  //   FileSaver.saveAs(filename,docType+'.'+this.currentExtension);
  // }

  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    var currentDocName : any    = this.fileName.split('_').pop();
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.empalertMsg          = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }

  }

  fieldName(name:any){
    let re = '^[/_]$'
    name   = name.replace(re,'\s');
    return name;
  }


  function(){
      if( this.requestitem.category =='snapshot'){

        this.options  = this.requestitem.edit_data.snapshot.map( (op:  any) => {

          const key   = Object.keys(op);
          const value = Object.values(op);
          return {
            "value" : value,
            "key"   : key
          }
      });
      } else  if( this.requestitem.category =='profile_image'){

        this.options  = this.requestitem.edit_data.profile_image.map( (op:  any) => {

          const key   = Object.keys(op);
          const value = Object.values(op);
          return {
            "value" : value,
            "key"   : key
          }
      });
      }
      else if(this.requestitem.category =='education'){
        if(this.requestitem.edit_data?.education){
          this.options  = this.requestitem.edit_data?.education.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);

            return {
              "value" : value,
              "key"   : key
            }
        });
        }
        if(this.requestitem.new_data?.education){
          this.addedData = this.requestitem.new_data?.education;
          // console.log(this.options,'.........');
        }

      }
      else if( this.requestitem.category=='professional'){
        if(this.requestitem.edit_data?.professional){
          this.options  = this.requestitem.edit_data?.professional.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
          });
          this.updatedData = this.requestitem.edit_data?.professional;

        }
        if(this.requestitem.new_data?.professional){
          this.addedData = this.requestitem.new_data?.professional;

        }

      }
      else if( this.requestitem.category =='personal'){
        if(this.requestitem.edit_data?.personal){
          this.options  = this.requestitem.edit_data?.personal.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
        });
        }
         if(this.requestitem.new_data?.personal){
          this.options = this.requestitem.new_data?.personal;
        }

      }
      else if( this.requestitem.category =='family_data'){
        if(this.requestitem.edit_data?.family){
          let data:any=[];
          data.push(this.requestitem.edit_data?.family)

          this.familyUpdated  = data?.map( (op:  any) => {

            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
          });
        }
        if(this.requestitem.edit_data?.child){

          this.childUpdated  = this.requestitem.edit_data?.child.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
          });
        }
        if(this.requestitem.edit_data?.dependant){

          this.dependUpdated  = this.requestitem.edit_data?.dependant.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
        });
        }
        if(this.requestitem.new_data?.family){
          this.familyData = this.requestitem.new_data?.family
        }
        if(this.requestitem.new_data?.child){
          this.childData = this.requestitem.new_data?.child
        }
        if(this.requestitem.new_data?.dependant){
          this.depData = this.requestitem.new_data?.dependant
        }

      }


      else if( this.requestitem.category =='health'){
        if(this.requestitem.edit_data?.health){
          this.options  = this.requestitem.edit_data?.health.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
        });
        }
        if(this.requestitem.new_data?.health){
          this.addedData = this.requestitem.new_data?.health;
        }
      }
      else if( this.requestitem.category =='prev_employer'){
        if(this.requestitem.edit_data?.prev_employer){
          this.options  = this.requestitem.edit_data?.prev_employer.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
        });
        }
        else if(this.requestitem.new_data?.prev_employer){
          this.options = this.requestitem.new_data?.prev_employer;
        }
      }
      else if( this.requestitem.category=='bank'){
        if(this.requestitem.edit_data?.bank){
          this.options  = this.requestitem.edit_data?.bank.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }
        });
        }
        else if(this.requestitem.new_data?.bank){
          this.options = this.requestitem.new_data?.bank;
        }
      }
      else if( this.requestitem.category=='document'){
        if(this.from=='inbox'){
          this.oldDocId = this.requestitem?.document_edit_data != undefined?this.requestitem?.document_edit_data:[];
          this.newDocId = this.requestitem?.document_new_data != undefined?this.requestitem?.document_new_data:[];
        } else {
          this.oldDocId = this.requestitem?.edit_data?.document != undefined?this.requestitem?.edit_data?.document:[];
          this.newDocId = this.requestitem?.new_data?.document != undefined?this.requestitem?.new_data?.document:[];
        }

        if(this.requestitem.edit_data?.document){

          this.options  = this.oldDocId.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }

          });


        }
        if(this.requestitem.new_data?.document){
          this.addedData  = this.newDocId.map( (op:  any) => {
            const key   = Object.keys(op);
            const value = Object.values(op);
            return {
              "value" : value,
              "key"   : key
            }

          });



        }

    }

    this.loader = false;
   }
// Loader
showGif(event: {loaded: number, total: number}) {
  this.loader1 = event.loaded < event.total;
}

// Modal Close
close1(bool:any){
  this.modalToggle = bool;
}

 // Modal close for Invalid Extension
 closed(bool:any){
  this.alertToggle = bool
}
confirm(status:any,id:any,index:any){
  this.inboxservice.updateInboxDoc(id,{"status":status}).subscribe((res:any)=>{
    // this.successEmitter.emit(true);
    index.status = status

  })

}

// Document View Status
docViewStatus(docId:any){
  this.loader2             = true;
  this.specificId          = docId;
  if(typeof this.reqId === 'number' && typeof docId === 'number'){
    this.viewstatusPanel     = true;
    this.profileDocumentService.getDocViewStatus(this.reqId, docId).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.fromViewStatus     = res?.request_type == 'Reimbursement' ? 'reimbursement-request' : res?.request_type == 'Employee Profile' ? 'employee-request' : '';
      if(!res?.apporval_matrix_loading)
        this.loader2            = false;
      if(res.status == 1){
        this.viewStatusLevelCnt = res?.activity?.level_count;
        this.viewStatusmaxLevel = res?.activity?.maxlevel;
      }
    });
  }
}

}
