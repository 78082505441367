import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { off } from 'hammerjs';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-login-report',
  templateUrl: './login-report.component.html',
  styleUrls: ['./login-report.component.scss']
})
export class LoginReportComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() DesignationDD: any;
  @Input() checkListElements: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above needs to be removed
  @Input() loginForm : any;
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() callemp : any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() dateSetup :  any
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering : any
  @Input() ouFilterForm: any

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  isInvalidDate = (m: moment.Moment) =>  {
  }
  fromDateFilter = (date: Date | null): boolean => { return true; }
​
  @Input() resDataFilter:any;

  get f()  { return this.loginForm.controls; }

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  dateFormat          :any

  constructor( public employeereportService:EmployeeReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService, public cd : ChangeDetectorRef) { }


  ngOnInit(): void {
    this.getDateFormat()
  }
  // Date Format
  getDateFormat(){
    this.dateFormat = this.appservice.getdatepickerformat();
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();

    if(this.dateFormat == '' || this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  // Emiting the value of radio to parent
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,type:any){
    if(e.target.value == 'on' ){
     this.loginForm?.get(contrlName)?.setValue(true);
    }
    else{
     this.loginForm?.get(contrlName)?.setValue(val);
     this.loginForm?.get(type)?.setValue('');
    }
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.loginForm.get('date_of_login')?.reset('');
      this.loginForm.get('from_date')?.reset('');
      this.loginForm.get('to_date')?.reset('');
    }
    this.callMonthlyReport.emit({
      reportName : 'Employee Login Report'
    })
  }
  /************ Login FILTER *****************/
  applyBtn : boolean = false

  filterFormData(use:any){
  let selectedDate:any;
  if(this.loginForm.controls.from_date.value != '' && this.loginForm.controls.to_date.value != ''){
    let start1         = moment(this.loginForm.controls.from_date.value,this.dateFormat).format('YYYY-MM-DD');
    let end1           = moment(this.loginForm.controls.to_date.value,this.dateFormat).format('YYYY-MM-DD');
    selectedDate          = start1+','+end1;
  }
​ this.loginForm.controls.date_of_login.value == true ? this.loginForm.get('date_of_login')?.setValue(selectedDate) : this.loginForm.get('date_of_login')?.setValue(this.loginForm.controls.date_of_login.value);

  let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
  let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
  let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
  let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
  let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
  let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
  let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
  let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
  let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
  let date_of_login =this.loginForm.controls.date_of_login.value

    if(use == 'filter'){
      return  `date_of_login=${date_of_login}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}`
    }else{
      return JSON.stringify({'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' :this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value,'date_of_login':date_of_login})
    }
  }

  setRequired(val:any){
    if(val){
      this.loginForm.get('from_date')?.setValidators([Validators.required])
      this.loginForm.get('to_date')?.setValidators([Validators.required])
    }else{
      this.loginForm.get('from_date')?.clearValidators();
      this.loginForm.get('to_date')?.clearValidators();
    }
    this.loginForm.get('from_date')?.updateValueAndValidity();
    this.loginForm.get('to_date')?.updateValueAndValidity();
    this.loginForm.get('from_date')?.reset('');
    this.loginForm.get('to_date')?.reset('');
  }

submitLogin(){
  this.submitted      = true;
  if (this.loginForm.invalid) {
    setTimeout(()=>{
      this.scrollToFirstChild()
    },400)
    return;
  }

  let offset = 0
  let limit = 20
  offset = this.applyBtn == true ? 0 : this.employeereportService.calculateOffset(this.pageNumber)
  let filterForm = this.filterFormData('filter')

  this.submitDone.emit({
    limit : limit,
    offset : offset,
    filterForm : filterForm
  });
  this.viewDetail           = false;
  this.applyBtn = false
}


// Tags closing
applyTagClose1(){
  this.applyBtn = true
  if(this.closeControl == 'emp_status'){
    this.empstatArr = [];
  }else if(this.closeControl == 'emp_type'){
    this.tempArr = [];
  }
  this.ouFilterForm.get(this.closeControl).setValue('');
  this.submitLogin();
  }

  checkToday(start:any,end:any){
    const compareValue = start.localeCompare(end)
    if(compareValue == 0)
      return this.appservice.dateFormatDisplay(start)
    else
      return `${this.appservice.dateFormatDisplay(start)} - ${this.appservice.dateFormatDisplay(end)}`
  }

  convertToDate(value:any){
    var date = new Date(value); // M-D-YYYY

    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();

    var dateString = (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    dateString = dateString.split("-").reverse().join("-")
    return dateString
  }

  resetPage(){
    this.applyBtn = true
    this.employeereportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
