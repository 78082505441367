<div class="container-fluid p-32 form-container">
    <div class="row ">
        <p class="text-uppercase fw-500 mb-24">Upload new/Update existing</p>
    </div>
    <div class="row row-16">
        <div class="col-lg-3 col-md-4 col-6" *ngFor="let i of datatype;let ind = index">
            <div class="card card-c2 card-hover "  [ngClass]="{'active':i.status}" (click)="makeActive(ind)" >
                <div class="card-body py-16 px-24 d-flex align-items-center">
                    <div class="sq-42 {{i.bg}} flex-center rounded-circle me-8">
                        <i class="{{i.icon}}"></i>
                        </div>
                    <div class="fs-12 fw-500">{{i.name}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="selectedIndex == -1" (click)="goNext(3)">NEXT</button>
</footer>