<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="resignationForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
               <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>

                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500">Resignation Date</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="resignation_date" type="radio" id="last_week" class="form-check-input"
                                formControlName="resignation_date" value="last_week"
                                (change)="radioBtnChangeFunctions($event,'resignation_date','last_week','sm_specific_month');">
                            <label for="last_week" class="form-check-label">Last week</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="resignation_date" type="radio" id="last_15_days" class="form-check-input"
                                formControlName="resignation_date" value="last_15_days"
                                (change)="radioBtnChangeFunctions($event,'resignation_date','last_15_days','sm_specific_month');">
                            <label for="last_15_days" class="form-check-label">Last 15 days</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="resignation_date" type="radio" id="last" class="form-check-input"
                                formControlName="resignation_date" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'resignation_date','last_month','sm_specific_month');">
                            <label for="last" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="resignation_date" type="radio" id="this" class="form-check-input"
                                formControlName="resignation_date" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'resignation_date','this_month','sm_specific_month');">
                            <label for="this" class="form-check-label">Current month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="resignation_date" type="radio" id="specific" class="form-check-input "
                                formControlName="resignation_date" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'resignation_date',true,'sm_specific_month');">
                            <label for="specific" class="form-check-label">Specific period</label>
                        </div>
                    </div>
                    <ng-container *ngIf="resignationForm.value.resignation_date==true">
                        <!-- <div class="input-group form-icon icon-right"
                    >
                            <input (click)="datePicker1.open()" class="form-control" placeholder="Start date" matInput
                                formControlName="from_date" [matDatepicker]="datePicker1"
                                [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                            <mat-datepicker  touchUi="false" #datePicker1>

                            </mat-datepicker>
                        </div>

                    <div class="input-group form-icon icon-right">
                            <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                                formControlName="to_date" [matDatepicker]="datePicker2"
                                [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                            <mat-datepicker  touchUi="false" #datePicker2>
                            </mat-datepicker>
                    </div> -->

                    <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}">
                        <input (click)="datePicker1.open()" class="form-control" placeholder="From date" matInput
                            formControlName="from_date" [matDatepicker]="datePicker1" [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                        <mat-datepicker  touchUi="false" #datePicker1>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors" class="invalid-feedback">
                      <div *ngIf="f?.from_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                  <div class="input-group form-icon icon-right"
                    [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}">
                        <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                            formControlName="to_date" [matDatepicker]="datePicker2" [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}"
                            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                        <mat-datepicker  touchUi="false" #datePicker2>

                        </mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors" class="invalid-feedback">
                      <div *ngIf="f?.to_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>

                    </ng-container>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
                (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage();submitresignationForm()">
                Apply
            </button>
        </footer>
    </form>
</div>

