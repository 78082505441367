<form [formGroup]="payComponentForm">

  <div class=" config-layout">
    <header class=" config-header">
      <h5 class="m-0">{{headerText}} Pay Component</h5>
      <button class="btn-reset text-light-600" routerLink="/pay-component"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <ng-container *ngIf="!loader">
      <aside class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="step"
              [class]="{'success':payComponentForm['controls'].define.valid && componentError==false}">
              <div class="count">1</div>
              <div class="content">
                <span>Step 1</span>
                <h6>Define Pay Component</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-pc-define [formGp]="defineForm" [disabled]="disabled" [editflag]="editflag"
                (submitmethod)="submitPayDefine()" [componentType]="defaultComponent"
                [disabledComponent]="disabledComponent" (defaultData)="makeDefaultSettings()"
                (paytypeChange)="paytypeChange()" [nameError]="nameError" [componentError]="componentError"
                (prorateRestrictions)="prorateRestrictions()" [defaultCompName]="defaultCompName"
                (perquisiteSec)="changeSection()" [leaveTypes]="leaveTypes">

              </app-pc-define>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="step"
              [class]="{'success':(payComponentForm['controls'].statuatory.valid || payComponentForm['controls'].statuatory.disabled) && (tab1touched || editflag)}"
              (submitmethod)="submitStatuatory()">
              <div class="count">2</div>
              <div class="content">
                <span>Step 2</span>
                <h6>Statutory details</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-pc-statuatory-details [formGp]="statuatoryForm" [editflag]="editflag" [disabled]="disabled"
                (submitmethod)="submitStatuatory()" [sortError]="sortError" (sortFlagSet)="sortFlagSet($event)">

              </app-pc-statuatory-details>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink class="step"
              [class]="{'success': (payComponentForm['controls'].taxDetails.valid || payComponentForm['controls'].taxDetails.disabled) && (tab2touched || editflag)}">
              <div class="count">3</div>
              <div class="content">
                <span>Step 3</span>
                <h6>Tax details</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-pc-tax-details [formGp]="taxDetailsForm" [disabled]="disabled"
                [tax_config_disable]="tax_config_disable" (submitmethod)="submitForm()"
                [sectionIncomeDisable]="sectionIncomeDisable" [defaultCompName]="defaultCompName"
                [userDefinedAdd]="userDefinedAdd" [taxrestricted]="taxrestricted">

              </app-pc-tax-details>
            </ng-template>
          </li>

        </ul>

      </aside>
      <main class=" config-container">
        <div [ngbNavOutlet]="nav"></div>
      </main>
    </ng-container>
  </div>
</form>
