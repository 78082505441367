import { Component, OnInit, Input } from '@angular/core';
import { AssetRequestService } from 'src/app/@core/services/asset-request.service';
import { AppService } from 'src/app/app.global';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
@Component({
  selector: 'app-view-asset-request',
  templateUrl: './view-asset-request.component.html',
  styleUrls: ['./view-asset-request.component.scss']
})

export class ViewAssetRequestComponent implements OnInit {

  @Input()viewData :any;
  @Input()requestId :any;

  viewstatusPanel     = false;
  billLoader          = false;
  viewStatusData:any  = [];
  viewStatusLevelCnt: any;
  viewStatusmaxLevel: any;
  specificId: any;

  constructor(
    private assetRequestService: AssetRequestService,
    public appService: AppService,
    private investmentService: InvestmentDeclerationService,
  ) {
    this.investmentService.request_id.subscribe(result =>{
      if(Number(this.requestId) == Number(result))
        this.viewStatus(this.specificId);
    })
   }

  ngOnInit(): void {
  }

  viewStatus(id:any){
    this.viewstatusPanel  = true
    this.billLoader       = true;
    this.specificId       = id;
    if(typeof this.requestId === 'number' && typeof id === 'number'){
      this.assetRequestService.assetViewStatus(this.requestId,id).subscribe((res:any)=>{
        this.viewStatusData     = res;
        if(!res?.apporval_matrix_loading)
          this.billLoader       = false;
        this.viewStatusLevelCnt = res?.activity?.level_count;
        this.viewStatusmaxLevel = res?.activity?.maxlevel;
      })
    }
  }

}
