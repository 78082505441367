<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Loan Details</h3>
      <p class="text-helper mb-md-0">Manage employee loans, adjust EMIs, settle loans, and restructure repayments efficiently.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <app-searchbar [searchplaceholder]="'Search'" [isLoading]="isLoading" (keywordsearchEmitter)="searchLoan($event)"
            (searchEmitter)="searchLoan($event)"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle (click)="filterPanel=true">
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
        <!-- *ngIf="permissions?.a" add permission -->
        <button class="btn flex-shrink-0 radius-2 btn-outline-primary fs-10 fw-700 text-uppercase px-4"
        (click)="viewImportPanel = true">Import</button>
      </div>
    </div>
  </div>
  <div class="row row-16">
    <div class="col-12" *ngIf="!loader">
      <div class="card card-c2 py-16 flex-row align-items-center gap-64 px-32">
        <div class="indicater6 indicater-success ps-12 fs-12 hstack gap-12">
          <div class="fs-32 ">{{listDataArr?.loan_counts?.active_loans_count}}</div>
          <div class="text-uppercase text-light-500  fw-500 fs-12">ACTIVE LOAN</div>
        </div>
        <div class="indicater6 indicater-bg11 ps-12 fs-12 hstack gap-12">
          <div class="fs-32 ">{{listDataArr?.loan_counts?.closed_loans_count}}</div>
          <div class="text-uppercase text-light-500  fw-500 fs-12">Closed LOAN</div>
        </div>
        <div class="indicater6 indicater-bg12  ps-12 fs-12 hstack gap-12">
          <div class="fs-32 ">{{listDataArr?.loan_counts?.restructured_loans_count}}</div>
          <div class="text-uppercase text-light-500  fw-500 fs-12">RESTRUCTURED loan</div>
        </div>
      </div>
    </div>
    <app-table-loader *ngIf="loader"></app-table-loader>
    <div *ngIf="!loader" class="col-12">
      <div class="card card-c2">
        <div class="table-responsive radius-4">
          <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
            <thead>
              <tr>
                <th class="fw-600 text-uppercase" scope="col">LOAN code</th>
                <th class="fw-600 text-uppercase" scope="col">LOAN TYPE</th>
                <th class="fw-600 text-uppercase" scope="col">Employee Name</th>
                <th class="fw-600 text-uppercase" scope="col">Employee code</th>
                <th class="fw-600 text-uppercase" scope="col">Loan amount</th>
                <th class="fw-600 text-uppercase" scope="col">TO BE RECOVERED</th>
                <th class="fw-600 text-uppercase" scope="col">PENDING EMI</th>
                <th class="fw-600 text-uppercase" scope="col">Status</th>
              </tr>
            </thead>
            <tbody class="cell-16 ">
              <tr *ngIf="listDataArr?.results?.length == 0">
                <td colspan="11" class="bg-white">
                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                    <span class="fw-500 fs-14">No data found</span>
                  </div>
                </td>
              </tr>
              <ng-container *ngIf="listDataArr?.results?.length > 0">
                <tr *ngFor="let i of listDataArr?.results">
                  <td><a (click)="loanDetail(i?.id);" class="link-primary1">{{i?.loan_code}}</a></td>
                  <td>{{i?.loan_type}}</td>
                  <td>{{i?.employee_name}}</td>
                  <td>{{i?.employee_code}}</td>
                  <td>{{i?.loan_amount}}</td>
                  <td>{{i?.to_be_recovered}}</td>
                  <td>{{i?.pending_emis}}/{{i?.total_emis}}</td>
                  <td>
                    <span *ngIf="i?.status == 'Active'" class="badge badge-success py-1 px-3">{{i?.status}}</span>
                    <span *ngIf="i?.status == 'Closed'" class="badge badge-secondary py-1 px-3">{{i?.status}}</span>
                    <span *ngIf="i?.status == 'Restructured'" class="badge badge-primary py-1 px-3">{{i?.status}}</span>
                  </td>
                </tr>

              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="listDataArr?.count > defaultlimit && !loader">
      <ngb-pagination class="d-flex justify-content-end" [page]="page" [collectionSize]="listDataArr?.count"
        [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="defaultlimit"
        (pageChange)="pageChanged($event)"></ngb-pagination>
    </div>
  </div>
</div>
<app-view-loan-details *ngIf="viewDetails" [viewDetails]="viewDetails" [loanData]="viewSpecData" [from]="'hr'" [loader]="viewloader" (close)="closeDetails()"></app-view-loan-details>

<app-loan-filter *ngIf="filterPanel" [filterPanel]="filterPanel" [OuFilterForm]="OuFilterForm" (filterData)="applyFilter($event)" (close)="closeFilter()" (reset)="resetfilter()" [filterForm]="filterForm" [from]="'loanHR'"></app-loan-filter>

<ng-container *ngIf="viewImportPanel == true">
  <app-loan-details-import [viewSidePanel]="viewImportPanel" (closePanel)="closeImportPanel($event)"></app-loan-details-import>
</ng-container>
