import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GradeService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }
  //  API for Grade save
  gradeSave(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/grade_list/`, body, this.httpOption)
  }
  //  API for Grade list
  gradeList(status:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/grade_list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }
  //  API for get single Grade
  gradeGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/grade_detail/${id}`, this.httpOption)
  }
  //  API for update Grade
  gradeUpdate(id: number, body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/grade_detail/${id}/`, body, this.httpOption)
  }
  //  API for delete Grade
  gradeDelete(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}organization/api/v1/grade_detail/${id}/`, body, this.httpOption)
  }
  // API for grade list
  gardeListData(status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption)
  }

}
