import { Component, OnInit } from '@angular/core';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
import { AppService } from 'src/app/app.global';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { Subject } from 'rxjs';
import { delay,switchMap  } from 'rxjs/operators';
@Component({
  selector: 'app-manage-activities',
  templateUrl: './manage-activities.component.html',
  styleUrls: ['./manage-activities.component.scss']
})
export class ManageActivitiesComponent implements OnInit {

  filter                          = false;
  onBehalf:any                    = false
  showSidePanel:any               = true;
  sidebarLoader:any               = false;
  sideMenuData:any                = []
  offset                          = 0;
  listlength                      = 0;
  direction                       = '';
  defaultlimit:any                = 20;
  requestDetailList:any           = [];
  requestList:any                 = [];
  infinityloader                  = false;
  loader                          = false
  searchKeyword                   = ""
  nodatafound                     = false;
  status:any                      = 'Pending'
  reqDatewise:any                 = []
  selectedmenu                    = ''
  empId:any;
  request_type:any                = []
  request_by:any                  = []
  request_of:any                  = []
  pending_with:any                = []
  viewDetail                      = false
  requestType:any                 = ''
  searchlistdata:any              = [];
  empSelection                    = false;
  searchkey                       = this.messageService.searchdisplay('Employee');
  empDD:any                       = []
  selectedEmpData:any             = [];
  requestitem:any                 = [];
  approvalScreen                  = false
  viewScreen                      = false
  requestTypeDD:any               = []
  holidayFloatData:any            = [];
  holidatSearchData:any           = [];
  holiday_nodata                  = false;
  holiday_loader                  = false;
  holidayRequestView              = false;
  holidayRequestSingleData:any    = [];
  saveClicked                     = false;
  alertToggle                     = false
  sLoading                        = false;
  compOffData:any                 = []
  loader1                         = false;
  viewPanel:any                   = false;
  permissions:any                 = [];
  requestCancel                   = false
  ddLoader                        = false
  cancelStatus:any
  viewPanelList:any               = ['Work From Home','Expense Report','Asset','Check-in Request','Compensatory Off','Overtime','On Duty','Attendance Regularization','Employee Profile','Reimbursement','Confirmed Investment Declaration','Proposed Investment Declaration','FBP Declaration','Salary Revision'];
  title:any
  requestId:any
  empData:any;
  filterValue:any;
  parentMenu:any=''
  requestName:any
  requestFlag:any
  key:any = []
  requestItems:any
  investalertMsg:any
  viewData:any
  pageStatus:any
  hra80gg:any
  hra80ggFlag:any;
  exeption_50:any = []
  taxData:any
  currency:any
  currentReqId:any;
  requestStatus:any
  filterForm            = this.formBuilder.group({
    request_by          : '',
    request_of          : '',
    pending_with        : '',
    // request_type        : '',

  });
  empForm             = this.formBuilder.group({
    empid : [null,Validators.required]
  })
  searchingData : Subject<void> = new Subject();

  constructor(
    private activityService: ManageActivityService,
    public appservice:AppService,
    private formBuilder: FormBuilder,
    public messageService : MessageService,
    private employeeService : EmployeeService,
    private investmentService:InvestmentDeclerationService,
    private datePipe:DatePipe,
    private notificationService:NotificationService,



  ) { }

  ngOnInit(): void {
    this.initialDataLoading();
    this.searchingData.pipe(delay(1000),
    switchMap(() =>

    this.activityService.requestList(this.defaultlimit,this.offset,this.searchKeyword,this.selectedmenu,this.status,this.filterValue))).subscribe((res: any)=> {
      this.detailsList(res)

    });
  }
  getPermission(){
    this.permissions = this.appservice.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  initialDataLoading(){
    this.getPermission();
    this.getSideMenu();
    this.getLandingdata(this.status);
    this.getEmployeeDD();
    this.requestFilterData();
  }
  getSideMenu(){
    this.sidebarLoader  = true
    this.activityService.getSideMenu(this.status).subscribe((res:any)=>{
     this.sideMenuData  = res;
     this.sidebarLoader  = false

    })
  }
  getEmployeeDD(){
    this.ddLoader = true
    this.employeeService.managerList().subscribe((res: any) => {
      this.searchData(res);
    });
  }
  getStatuswiseData(status:any){
    this.requestList  = [];
    this.reqDatewise  = [];
    this.offset       = 0
    this.getLandingdata(status)
    this.getSideMenu()
  }
  searchData(data:any){
    let res = data;
    for(let i=0; i< res.length; i++){

        if(res[i]['first_name'] != null)
        res[i]['name'] = res[i]['first_name']+" ";
        if(res[i]['middle_name'] != null)
        res[i]['name'] += res[i]['middle_name']+" ";
        if(res[i]['last_name'] != null)
        res[i]['name']   += res[i]['last_name'];
        res[i]['name']   +=" ("+res[i]['employee_code']+")";
        res[i]['image']   = res[i]['profile_image'];
        res[i]['fullname']  = res[i]['name']


      }
      this.empDD          = res
      // this.searchlistdata = res;
      this.ddLoader = false
      this.selectAllForDropdownItems(this.empDD);

  }

  getLandingdata(status:any){

    this.loader = true;
    this.status = status
    this.activityService.requestList(this.defaultlimit,this.offset,this.searchKeyword,this.selectedmenu,this.status,this.filterValue).subscribe((res: any)=> {
      this.detailsList(res)

    })

  }
  detailsList(res:any){
    this.listlength = res?.results?.length;
    if(this.offset ==0){
      this.requestList = [];
      this.reqDatewise = [];
    }

    if (this.listlength == 0) {
      this.nodatafound = this.offset == 0 ? true :false;
      // this.nodatafound = true
    }else {
      this.nodatafound = false;
      this.requestDetailList = res?.results;
    }
    this.infinityloader = false;
    this.sLoading       = false;
    this.addItems(this.listlength);
  }
  closeReqPanel(){
    this.onBehalf = false;
  }
  onScrollDown() {

    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getLandingdata(this.status);
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.requestList.push(this.requestDetailList[i]);
    }
    this.constructData()
  }
  constructData(){
    this.key = [];
    for(let i=0;i<this.requestList.length;i++){
      let date:any
      date = this.appservice.dateFormatConvert(this.requestList[i]['created_at'])
      this.requestList[i]['created_display'] = this.datePipe.transform(this.requestList[i]['created_at'], 'hh:mm a')?.toString()

      if(this.key.indexOf(date)==-1)
      this.key.push(date)
      this.requestList[i]['datefilter'] = date
    }

    let key1 = this.key
    for(let i =0;i<this.key.length;i++){
      var filterData =  this.requestList.filter(function(req:any) {

        return req.datefilter == key1[i] ;
      });

      let newFormat:any = (this.key[i])
      this.reqDatewise[newFormat] = filterData

    }

    this.loader = false

  }
  searchRequest(searchKeyword:any){
    this.loader      = true;
    this.offset      = 0;
    this.requestList = [];
    this.reqDatewise = []
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getLandingdata(this.status);
    }
  }
  getSingleRequestDetail(id:any,type:any){
    this.activityService.singleRequest(id,type).subscribe((res:any)=>{

    })
  }
  dateFormatDisplay(date:any){

    var d = new Date();
    if(date == String(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate())){
      return 'TODAY';
    }else if(date == String(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+(d.getDate()-1))){
       return 'YESTERDAY';
    }else{
      return this.appservice.dateFormatDisplay(date)
    }
  }
  getReqSize(index:any){
    return this.reqDatewise[index].length
  }
  getDetailView(index:any){
    let data = [];
    return this.reqDatewise[index];
  }

  menuClick(menu:any,parent:any){
    this.selectedmenu = menu;
    this.requestList = [];
    this.reqDatewise = [];
    this.offset      = 0
    this.parentMenu  = parent
    this.getLandingdata(this.status)
  }
  clearcommonForm(control:any,index:number){

    let selectedData = this.filterForm.get(control)?.value
    selectedData.splice(index, 1);
    this.filterForm.get(control)?.setValue(selectedData)

  }

  searchresults(event:any){
    this.loader             = true;
    this.reqDatewise        = [];
    this.searchKeyword      = event
    // this.getLandingdata(this.status);
    this.searchingData.next();


  }

  searchActivity(searchKeyword:any){
    this.sLoading       = true
    this.loader         = true;
    this.offset         = 0;
    this.reqDatewise    = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      // this.getLandingdata(this.status);
    this.searchingData.next();


    }
  }
  behalfRequest(){
    this.empSelection = true
    this.empForm.reset()
  }
  setEmp(){
    this.empId        = this.empForm.get('empid')?.value
    let empid         = this.empId
    this.empSelection = false
    this.onBehalf     = true
    var emp =  this.empDD.filter(function(data:any) {

      return data.id === empid
    });
    this.selectedEmpData = emp[0]
    this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
      this.currency = res.currency
     })
  }
  viewRequestData(type:any,id:any){
    this.requestType    = type
    this.loader1        = true;
    this.requestId      = id
    this.viewPanel      = false
    this.approvalScreen = false
    this.viewScreen     = false

    this.activityService.singleRequest(id,type).subscribe((res:any)=>{

      this.empData                     = res['request_data']['employee']['full_name']
      this.title                       = "Details - "+this.requestType+" Of "+this.empData
      this.cancelStatus                = res.data?.cancellation_raised_status
      if(res.request_data.status == 'Pending'){
        this.inboxView(res,type)

      }
      else{

        this.requestView(res,type)
      }
      this.loader1    = false;

    })
    if(this.status=='Pending')
    this.approvalScreen = true
    else
    this.viewScreen = true
  }
  inboxView(res:any,type:any){
    this.requestitem = []
    this.pageStatus = 'inbox'

    this.requestitem                 = res['inbox']

    let result = res.request_data;

    if(new Date(result['created_at']).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)) {
      this.requestitem['list_created_display'] = this.datePipe.transform(result['created_at'], 'hh:mm a')?.toString()
      this.requestitem['created_display'] = this.datePipe.transform(result['created_at'], 'hh:mm a')?.toString() + " (" +moment(String(this.datePipe.transform(result['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
    }
    else if(new Date(result['created_at']).getFullYear() == new Date().getFullYear()){
      this.requestitem['list_created_display'] = this.datePipe.transform(result['created_at'], 'MMM dd')?.toString()
      this.requestitem['created_display'] = this.datePipe.transform(result['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString() + " (" + moment(String(this.datePipe.transform(result['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
    }
    else {
      this.requestitem['list_created_display'] = this.datePipe.transform(result['created_at'], 'dd/MM/yy')?.toString()
      this.requestitem['created_display'] = this.datePipe.transform(result['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString()
    }
    this.requestitem['error_message'] = res['error_message']

    if(type=='Leave')
    {
      this.requestitem['leave_data']      = res['data'];
      this.requestitem['leave_type_name'] = res['data']['leave_type_name'];
    }
    else if(type=='Holiday'){
      this.requestitem['holiday_name']   = res['data']['holiday_name'];
      this.requestitem['day_of_week']    = res['data']['day_of_week'];
      this.requestitem['holiday_date']   = this.dateFormatDisplay(res['data']['holiday_date']);
      this.requestitem['cancel_remark']  = res['data']['remark'];
      this.requestitem['data']           = res['data'];
    }
    else if(type == "Compensatory Off"){
      this.requestitem['compoff'] = res['data'];
      this.requestitem['request_type_color_code'] = res['request_data']['request_type']['color_code']
    }
    else if(type == "FBP Declaration"){
      this.requestitem['fbp_request_data']= res['data'];
    }
    else if(type=='Employee Profile'){

      this.requestitem['employee_profile']  = res['data'];
      if(res['data']['category']=='document'){
        this.requestitem['employee_profile']['document_edit_data'] =  res?.data?.edit_data
        this.requestitem['employee_profile']['document_new_data'] =  res?.data?.new_data
      }

    }
    else if(type == "Overtime"){
      this.requestitem['overtime']= res['data'];
      this.requestitem['request_type_color_code'] = res['request_data']['request_type']['color_code']

    }
    else if(type=='Reimbursement'){
      if(res['request_data']?.is_cancel ==true){
        this.requestitem['reimbursement_data'] = {'reimburse_bill':res['inbox']['cancellation_request_data'], 'remark' : res['request_data']?.['cancel_remark']};
      }
      else
        this.requestitem['reimbursement_data'] = res['data']['reimbursement_bill_inbox'];
      this.requestitem['currency']           = res['data']['currency']

    }
    else if(type=='Asset' || type=='Manage Asset'){
      this.requestitem['requests']= (res['error_message']==null)? res['inbox']['asset_inbox'][0]:''

    }
    else if(type=='On Duty'){

      this.requestitem['requests']= res['data']['onduty_inbox'];
      this.requestitem['data']    = res['data'];

    }
    else if(type=='Attendance Regularization' || type=='Check-in Request'){
      this.requestitem['requests']= res['data']['regularization_inbox'];
    }

    else{

      this.requestitem['requests']= res['data'];

    }

  }

  requestView(res:any,type:any){
    this.pageStatus                   = 'request'
    this.viewData                     = res.data
    this.viewData['error_message']    = null
    this.viewData['achieved_status']  = true
    if(this.viewPanelList.includes(type))
      this.viewPanel  = true
    else
      this.viewPanel  = false
    if(type=='Leave'){
      this.compOffData = [];
      if(this.viewData?.related_compoff_data?.length > 0){
        for(let i=0;i<this.viewData?.related_compoff_data?.length;i++){

          let attendance_date = this.appservice.dateFormatDisplay(this.viewData?.related_compoff_data?.[i]?.attendance_date);
          let eligible_compoff = this.viewData?.related_compoff_data?.[i]?.eligible_compoff == 'Full Day' ? '1 day' : '0.5 day';

          this.compOffData?.push(attendance_date+' - '+eligible_compoff);

        }
      }
    }
    else if(type=='Work From Home'){
      this.viewData['fulldayarr']=[];
      this.viewData['halfdayarr']=[];
      this.viewData['totday']    = 0;
      this.viewData['wfhid']     =this.requestId;
      for(let i=0;i<this.viewData.requests.length;i++){
       if(this.viewData.requests[i].day_portion=='Full Day'){
         this.viewData.fulldayarr.push(this.appservice.dateFormatDisplay(this.viewData?.requests[i]?.wfh_date));
         this.viewData.totday = this.viewData.totday+1;
       }else{
         this.viewData.halfdayarr.push((this.appservice.dateFormatDisplay(this.viewData?.requests[i]?.wfh_date))+' ('+this.viewData.requests[i].day_portion+')');
         this.viewData.totday = this.viewData.totday+0.5;
       }
     }
    }
    else if(type=='Expense Report'){
      this.viewData           = res?.data?.expense_report_data;
      this.viewData['status'] = res?.data?.status;

    }
    else if(type=='Attendance Regularization' || type=='On Duty'){
      this.viewData   = res?.data?.requests
    }
    else if(type=='Confirmed Investment Declaration' || type=='Proposed Investment Declaration'){
      this.taxData                = JSON.parse(this.viewData.declared_datail);

        if(this.taxData?.excemption_declaration?.hasOwnProperty('hra'))
        this.hra80gg='hra';
      if(this.taxData?.excemption_declaration?.hasOwnProperty('80gg'))
        this.hra80gg='80gg';
        if(res.hra80gg?.length > 0){
            if(res.hra80gg[0]['select_one'] == true)
              this.hra80ggFlag = true;
            else
              this.hra80ggFlag = false;
        }

        if(this.viewData.chapter4adeduction?.donate_80g?.length > 0){
          for(let i=0; i< this.viewData.chapter4adeduction?.donate_80g?.length; i++){
            if(!this.viewData.chapter4adeduction?.donate_80g[i]?.exemption){
              this.exeption_50.push(this.viewData.chapter4adeduction?.donate_80g[i])
            }
          }
        }
    }
    else if(type=='Final settlement Approval' || type=='Salary Revision'){
      this.viewData   = res?.request_data

    }

  }
  refreshPage(data:any){
    let action        = data?.approved_status
    this.currentReqId = data?.request_id
    this.activityService.onBehalfReqUpdate(data).subscribe((res:any)=>{
      this.approvalScreen = false
      this.notificationService.handleSuccessNotification("Request "+action+" Successfully","Success");
      if(action=='Approved'){

        this.loader      = true;
        this.requestList = [];
        this.requestDetailList = this.requestDetailList.filter((item:any) =>{ return item.id != this.currentReqId })
        if(this.requestDetailList.length==0)
          this.nodatafound = true
        this.addItems(this.requestDetailList.length)

      }
      else
      this.getLandingdata(this.status)
    })
  }
  cancelRequest(data:any){
    this.activityService.cancelRequest(data).subscribe((res:any)=>{
      this.viewScreen     = false
      this.approvalScreen = false
      this.requestCancel  = false
      this.notificationService.handleErrorNotification("Request Cancelled Successfully","Success")
      this.getLandingdata(this.status)

    })
  }
  requestFilterData(){
    this.investmentService.requestTypeListfilter().subscribe((res: any) => {
      this.request_type = res;

    });
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  applyfilter(){

    let request_of = this.filterForm.get('request_of')?.value
    let request_by = this.filterForm.get('request_by')?.value
    // let request_type = this.filterForm.get('request_type')?.value
    let pending_with = this.filterForm.get('pending_with')?.value
    this.filterValue = `request_of=[${request_of}]&request_by=[${request_by}]&pending_with=[${pending_with}]`
    this.requestList  = [];
    this.reqDatewise  = [];
    this.offset       = 0
    this.getLandingdata(this.status);
    this.filter = false
  }
  resetFilter(){
    this.filterValue = ''
    this.filterForm.patchValue({
      request_by          : '',
      request_of          : '',
      pending_with        : '',
      // request_type        : '',
    })
    this.requestList  = [];
    this.reqDatewise  = [];
    this.offset       = 0
    this.getLandingdata(this.status);
    this.filter = false

  }
  requestAction(data:any){

    this.requestItems = data
    this.requestName  = data?.type
    this.requestFlag  = data?.flag
    if(this.requestName=='Holiday'){
      this.holidayLists();
      this.searchHoliday();
    }

  }

  holidayLists(){

    this.holiday_loader = true;
    this.activityService.holidayFloatList('holiday_date','',this.empId).subscribe((res: any) => {
      this.holiday_loader   = false;
      this.holidayFloatData = res;

      if(this.holidayFloatData?.length == 0){
        this.holiday_nodata = true;
      }
      else{
        this.holiday_nodata = false;
      }
    });
  }
  searchHoliday(){
    this.holiday_loader = true;
    this.activityService.holidayFloatDropDown(this.empId).subscribe((res: any) => {
      for(let i=0; i< res.length; i++){
        if(res[i]['day_name'] != null && res[i]['day_name'] != '')
          res[i]['name'] = res[i]['holiday_name'] +' '+ 'day ' +res[i]['day_name'];
        else
          res[i]['name'] = res[i]['holiday_name'];
      }
      this.holiday_loader     = false;
      this.holidatSearchData  = res;
    });
  }
  holidaySearchFunction(data: any){
    if(data.search == false){
      this.holidayLists();
    }else{
      this.singleHoliday(data.searchid);
    }
  }
  singleHoliday(id: number){
    this.holiday_loader = true;
    this.investmentService.holidayFloatGet(id).subscribe((res: any) => {
      this.holidayFloatData = res;
      this.holiday_loader = false;
    });
  }
  requestSave(event:any){
    let data            = event;

    if(this.requestName!='FBP Declaration' && this.requestName!='Compensatory Off')
    data['employee_id'] = this.empId

    if(this.requestName=='Asset'){
      this.activityService.assetRequest(data).subscribe((res:any)=>{
        this.refreshReqPage()
      })
    }
    else if(this.requestName=='Expense Advance'){
      this.activityService.expenseAdvance(data).subscribe((res:any)=>{
        this.refreshReqPage()

      })
    }
    else if(this.requestName=='Holiday'){
      this.activityService.holidayFloatCreate(data).subscribe((res:any)=>{
        if(res?.error != ''){
          this.alertToggle = true;
          this.investalertMsg = res?.error;
          this.saveClicked = false;
        }else{
          this.alertToggle = false;
          this.saveClicked = false;

        }
        this.refreshReqPage()

      })
    }
    else if(this.requestName=='FBP Declaration'){

      this.activityService.fbpCreate(data).subscribe((res:any)=>{
        this.refreshReqPage()

      })
    }
    else if(this.requestName=='Compensatory Off'){
      let saveData = {
        'attendance_date':data?.attendance_date,
        'employee_id'    : this.empId
      }
      this.activityService.compOffRequestCreate(data?.compOffId,saveData).subscribe((res:any)=>{
        this.refreshReqPage()

      })
    }
    else if(this.requestName=='Expense Report'){

      this.activityService.expenseReportReq(data).subscribe((res:any)=>{
        this.refreshReqPage()

      })
    }
  }

  refreshReqPage(){
    this.requestFlag        = false;
    this.onBehalf           = false;
     setTimeout(() => {
       this.getLandingdata(this.status);
       this.getSideMenu();
    }, 1000);
  }
  closefn(data:any){
    this.requestFlag        = false;

  }
  closeViewPage(data:any){
    this.viewScreen = false
  }
  cancelViewClose(data:any){
    this.viewScreen = false
    this.viewPanel  = false

  }
  cancelLeaveRequest(cancelStatus:any,arrear:any,yearEndCancel:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Leave request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else if(!yearEndCancel){
      this.notificationService.handleErrorNotification("Leave cancellation request cannot be raised as leave year-end has already been processed.","Error");
    }else{
      this.requestCancel = true;
      this.requestStatus =  this.requestitem?.leave_data?.status
    }
  }

  cancelShorLeaveRequest(cancelStatus:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else{
     this.requestCancel = true
     this.requestStatus =  this.requestitem?.requests?.status

    }
  }
  cancelWFHRequest(cancelStatus:any,arrear:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Work from home request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else{
      this.requestCancel  = true
      this.requestStatus  = this.requestitem?.requests?.status

    }
  }
  cancelHolidayRequest(cancelStatus:any,arrear:any){
    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Holiday request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else{
      this.requestCancel = true;
      this.requestStatus  = this.requestitem?.data?.status

    }
  }

  closeCancelModal(data:any){
    this.requestCancel = false;

  }
}
