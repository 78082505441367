import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-hp-details',
  templateUrl: './hp-details.component.html',
  styleUrls: ['./hp-details.component.scss']
})
export class HpDetailsComponent implements OnInit {

  @Input()submitted:boolean=false;
  @Input()roleDropdown:any=[];
  @Input()disabled = false;
  previousYear: any;
  @Output()submitmethod = new EventEmitter();
  @Output()previousYearEmitter = new EventEmitter();
  @Input()formGp : any;
  @Input() id:any;
  @Input()editflag :any;
  
  yearList : any = [];
  holidayDetailUnit : any = []
  value:any;
  prorate_holiday = false;
 

  listOfMonth= [ {id:12,value:"January"},
  {id:11,value:"February"},
  {id:10,value:"March"},
  {id:9,value:"April"},
  {id:8,value:"May"},
  {id:7,value:"June"},
  {id:6,value:"July"},
  {id:5,value:"August"},
  {id:4,value:"September"},
  {id:3,value:"October"},
  {id:2,value:"November"},
  {id:1,value:"December"},];

  frommonthId : any;
  tomonthId : any
  
  private subjectKeyUp = new Subject<any>();

  constructor(
    public fb:FormBuilder,
    public messageService: MessageService,
    public existDataService :  ExistDatasService,
    public route: ActivatedRoute,
    private appService : AppService
  ) { }

  ngOnInit(): void {
    this.yearListCreate();
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
   
    if(this.prorated_holiday_policy().length == 0){
      this.addRoles();
    }
  }

 ngOnChanges(){
  this.previousYear = this.formGp.value.year
 }

 get f() { return this.formGp.controls; }
  validateHolidayPolicy() {
    this.submitted = true;
    this.disabled = true
    if (this.formGp.invalid) {
      return;
    }
   this.submitmethod.emit();
  }
  currentYear = new Date().getFullYear();

  yearListCreate(){
    var i:number;
    for(i = this.currentYear-1; i>=(this.currentYear-1);i--){
      this.yearList.push(String(i));

    }
    for(i = this.currentYear; i <= (this.currentYear+3) ; i++){
      this.yearList.push(String(i));
    }
  }
  changeYear(event:any){
    this.previousYearEmitter.emit(this.previousYear);
  }

  // Simplified function to use for validation
  existService(){

    if(!this.editflag){
      this.value ={
        "page":"holidaypolicy",
        "field":"holiday_policy_name",
        "value": this.f.holiday_policy_name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"holidaypolicy",
        "field":"holiday_policy_name",
        "value": this.f.holiday_policy_name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.holidayDetailUnit = res.body
      if(this.holidayDetailUnit.data ==='invalid'){
        this.f.holiday_policy_name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.holiday_policy_name.value)
  }

  setRequired(status:any,data:any){
    if(!this.f.is_floating_holiday.value){
      this.formGp.get('prorate_floating_holiday')?.setValue(false);
      this.remove_all_prorated_holiday_policy();
    }
    if(status){
      this.formGp.get(data).setValidators([Validators.required,Validators.pattern(/^[0-9]*$/),Validators.maxLength(3),Validators.min(1)])
    }
    else{
     this.formGp.get(data)?.clearValidators()
     this.formGp.get(data)?.setValue(null)
    }
    this.formGp.get(data).updateValueAndValidity()

  }

  remove_all_prorated_holiday_policy(){
    if(this.formGp.value.prorated_holiday_policy?.length > 0){
      while(this.formGp.value.prorated_holiday_policy?.length !== 0){
        this.prorated_holiday_policy().removeAt(0);
      }
    }

  }

  handleChange(status:any,data:any){
    if(status == true){
     if(this.prorated_holiday_policy().length == 0){
          this.addRoles();
      }
      this.formGp.controls['prorated_holiday_policy'].at(0).controls.from_month.setValidators([Validators.required])
      this.formGp.controls['prorated_holiday_policy'].at(0).controls.from_month.updateValueAndValidity()
      this.formGp.controls['prorated_holiday_policy'].at(0).controls.to_month.setValidators([Validators.required])
      this.formGp.controls['prorated_holiday_policy'].at(0).controls.to_month.updateValueAndValidity()
      this.formGp.controls['prorated_holiday_policy'].at(0).controls.count_number.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(3)])
      this.formGp.controls['prorated_holiday_policy'].at(0).controls.count_number.updateValueAndValidity()
      }else{
     this.formGp.get("prorated_holiday_policy").clearValidators()
     this.prorated_holiday_policy().removeAt(0);
  }
  }

 prorated_holiday_policy() : FormArray {
    return this.formGp.get("prorated_holiday_policy") as FormArray
  }

  newprorated(): FormGroup {
    return this.fb.group({
      "from_month": [null],
      "to_month": [null],
      "count_number": [],
     
    })
  }

  addRoles() {
    this.prorated_holiday_policy().push(this.newprorated());
    if(this.prorated_holiday_policy().length > 1){
      this.newsetRequired()
    }
  }

  removeRole(i:any){
    this.prorated_holiday_policy().removeAt(i);
    var prorateArr = ['from_month','to_month','count_number']
    prorateArr.forEach((item:any)=>{
    this.prorated_holiday_policy().controls[i]?.get(item)?.clearValidators();
   
    })
  }

  // function for set validations
  newsetRequired(){
    var prorateArr = ['from_month','to_month']
    for(let i=1;i<=this.prorated_holiday_policy().length;i++){
     prorateArr.forEach((item:any)=>{
      (this.prorated_holiday_policy().controls[i]?.get(item)?.setValidators([Validators.required]));
      (this.prorated_holiday_policy().controls[i]?.get(item)?.updateValueAndValidity());
     
    });
      
     (this.prorated_holiday_policy().controls[i]?.get('count_number')?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(3)]));
     (this.prorated_holiday_policy().controls[i]?.get('count_number')?.updateValueAndValidity());
    
    }
  }

   arr : any = []
  
  showValidation(index:any){
    
    this.listOfMonth.forEach((key:any)=>{
    if(key.value ==   this.prorated_holiday_policy().controls[index]?.get('from_month')?.value){
        this.frommonthId = key.id
    }
    if(key.value == this.prorated_holiday_policy().controls[index]?.get('to_month')?.value){
        this.tomonthId = key.id
    }
  })
  if(this.prorated_holiday_policy().controls[index]?.get('to_month')?.value !=null){
    if(this.tomonthId >= this.frommonthId){
      this.prorated_holiday_policy().controls[index]?.get('from_month')?.setErrors({
        'greater' : true
      })
    
    }

  }
  for(let i=0;i<this.prorated_holiday_policy().length;i++){
    if(i != index){
      var frmInd = this.appService.getMonthNumberFromName(this.prorated_holiday_policy().controls[index]?.get('from_month')?.value)
      var toInd = this.appService.getMonthNumberFromName(this.prorated_holiday_policy().controls[index]?.get('to_month')?.value)
      var frmI = this.appService.getMonthNumberFromName(this.prorated_holiday_policy().controls[i]?.get('from_month')?.value)
      var toI = this.appService.getMonthNumberFromName(this.prorated_holiday_policy().controls[i]?.get('to_month')?.value)
      if( ( frmInd >= frmI &&  frmInd <= toI) || ( toInd >= frmI &&  toInd <= toI)  ){

          this.prorated_holiday_policy().controls[index]?.get('from_month')?.setErrors({
            'overlap' : true
          })
      }
    }
  }
}
 
  

 
}


