import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, ValidationErrors, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { BankDetailsComponent } from '../employee-report-configure/bank-details/bank-details.component';
import { DisablePortalAccessComponent } from '../employee-report-configure/disable-portal-access/disable-portal-access.component';
import { EmployeeDetailsComponent } from '../employee-report-configure/employee-details/employee-details.component';
import { LoginReportComponent } from '../employee-report-configure/login-report/login-report.component';
import { PersonalDetailsComponent } from '../employee-report-configure/personal-details/personal-details.component';
import { ResignationReportComponent } from '../employee-report-configure/resignation-report/resignation-report.component';
import { SnapshotComponent } from '../employee-report-configure/snapshot/snapshot.component';
import { MatSort } from '@angular/material/sort';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-report-configuration',
  templateUrl: './employee-report-configuration.component.html',
  styleUrls: ['./employee-report-configuration.component.scss']
})
export class EmployeeReportConfigurationComponent implements OnInit {

  // Variables Initialization
  reportId          : number = 0
  reportName        : string = ''
  formData          : string = ''
  closeControl      : any = ''
  resetFrom         : any = ''
  isLoading         : boolean = false
  noData            : boolean = false
  addColumnFilter   : boolean = false
  viewDetail        : boolean = false
  alertShow         : boolean = true
  reportDownload    : boolean = false
  applyFilterPop    : boolean = false
  resetFilterPop    : boolean = false
  saveFilterModalClicked : boolean = false
  resetFilterModalClicked: boolean = false
  apiData           : any = []
  apiExcelData      : any = []
  displayedColumns  : any = []
  yearsList         : any = []
  hint_dict         : any = {}
  resDataFilter     : any
  resHeaderFilter   : any
  selectedYear      : any
  initialLoaded     : boolean = false
  colRearranged     : boolean = false
  calcHeaders       : any = []

  // Store initial state of columns
  initialFormState: any;

  // Sort
  ordering          : String = 'Employee Code'
  direction         : string = 'asc'
  sortProperty      : string = 'Employee Code'
  isSorted          : boolean = false

  // Page
  limit             : number = 20
  offset            : number = 0
  lastPageCount     : number = 0
  page              : number = 1
  pageNumber        : number = 1

  //Tag Filter Variables
  appliedFilter: any = {};
  tagMultiData: any = [];
  tagMultiCnt: any = 0;

  // Filter Memorize
  filterSubmitMem   : boolean = false
  addedColumns      : boolean = false

  // ViewChild
  @ViewChild(SnapshotComponent, { static: false }) appSnapshot!: SnapshotComponent;
  @ViewChild(BankDetailsComponent, { static: false }) appbank!: BankDetailsComponent;
  @ViewChild(EmployeeDetailsComponent, { static: false }) appempdetails!: EmployeeDetailsComponent;
  @ViewChild(PersonalDetailsComponent, { static: false }) apppersonaldetails!: PersonalDetailsComponent;
  @ViewChild(LoginReportComponent, { static: false }) applogin!: LoginReportComponent;
  @ViewChild(ResignationReportComponent, { static: false }) appResignation!: ResignationReportComponent;
  @ViewChild(DisablePortalAccessComponent, { static: false }) disableAccess!: DisablePortalAccessComponent;

  // Validation for Login Form
  requiredWithDateRange = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isNullDateRange = (value === null) ||
      (typeof value === 'object' && value.startDate === null && value.endDate === null);
    return isNullDateRange ? { required: true } : Validators.required(control);
  };

  // Form Initialization
  // Side Panel Form
  sidePanelForm     !: FormGroup

  // Filter Save Form
  filterSaveForm = this.fb.group({
    filter: true,
  });

  // Common OU Form
  OuFilterForm = this.fb.group({
    company: [''],
    bu: [''],
    branch: [''],
    designation: [''],
    department: [''],
    employee_list: [''],
    grade: [''],
    employee_type: [''],
    employment_status: [''],
  })

  // SnapshortForm
  snapshortForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    birthday              : '',
    created_date          : '',
    specific_date         : null,
    cd_specific_date      : null,
    bank_status           : '',
    personal_status       : '',
    document_status       : '',
    education_status      : '',
    family_status         : '',
    health_insurance_status : '',
    previous_employer_detail_status : '',
    work_experience_status : '',
    total_experience       : '',
    reporting_manager      : '',
    functional_manager     : '',
    date_of_leaving        : '',
    dol_specific_date      : null,
    date_of_joining        : '',
    to_date_join           : null,
    from_date_join         : null,
    date_of_confirmation   : '',
    to_date_confirm        : null,
    from_date_confirm      : null,
    from_date_birth        : null,
    to_date_birth          : null,
    specific_month         : null,
    specific_year          : null
  })

  // Bank Form
  bankDetailsForm = this.fb.group({
    ouFilterForm: this.OuFilterForm,
  })

  // Employee Details Form
  employeeDetailsForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    reporting_manager     : '',
    functional_manager    : '',
  })

  // Personal Details Form
  personalDetailsForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    pan_status            : '',
    aadhar_status         : '',
    passport_status       : '',
  })

  // Login Form
  loginForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    date_of_login         : ['',[this.requiredWithDateRange]],
    login_specific_date   : null,
    from_date             : '',
    to_date               : '',
  })

  // Resingation Form
  resignationForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    resignation_date      : '',
    from_date             : '',
    to_date               : '',
  })

  // Disable Form
  disabledForm = this.fb.group({
    ouFilterForm: this.OuFilterForm,
    specific_month: null,
    specific_year: null,
    disabled_on: ['this_month', Validators.required],
    disabled_by: ''
  })

  // Mat Table
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private fb       : FormBuilder,
    public route     : ActivatedRoute,
    public appService: AppService,
    private rprtServ : ReportsService,
    private cd       : ChangeDetectorRef,
    public router    : Router,
    private notify   : NotificationService,
    private empServ  : EmployeeReportsService,
    private attendReprtServ : AttendanceReportsService
  ) { }

  ngOnInit(): void {
     // ID & Report Name from Params
     this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.reportId = params['id'];
      }
      if(params['name']){
        let report = params['name']?.match(/[A-Z][a-z]*|[A-Z]/g);
        this.reportName = report.join(' ');
        this.initialLoaded = true
        this.isLoading = true
        this.getDateFormat().then(() => {
          this.reportInitialSetups();
        });
      }
    })
  }

   // Fn to setup initials for all reports
   reportInitialSetups(){
    this.isLoading = true
    this.apiExcelData = []
    this.appliedFilter = {};
    this.empServ.reportAPI(this.urlGenerator()?.url,this.limit,this.offset,this.ordering,this.formData).subscribe((res:any)=>{
      if(res?.data?.length != 0){
        this.noData = false;
        // Formatting dates and fields if needed
        for(let i = 0; i < res?.data?.length; i++){
          res.data[i]['Date of joining'] = (res?.data[i]['Date of joining'] == null || res?.data[i]['Date of joining'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of joining']);
          res.data[i]['Date of birth']   = (res?.data[i]['Date of birth'] == null || res?.data[i]['Date of birth'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of birth']);
          res.data[i]['Date of Confirmation']   = (res?.data[i]['Date of Confirmation'] == null || res?.data[i]['Date of Confirmation'] == '') ? '' :  this.appService.dateFormatDisplay(res?.data[i]['Date of Confirmation']);
          res.data[i]['Date Of leaving']   = (res?.data[i]['Date Of leaving'] == null || res?.data[i]['Date Of leaving'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date Of leaving']);
          res.data[i]['Date of marriage']   = (res?.data[i]['Date of marriage'] == null || res?.data[i]['Date of marriage'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of marriage']);
          res.data[i]['Created date']   = (res?.data[i]['Created date'] == null || res?.data[i]['Created date'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Created date']);
          res.data[i]['Date of Resignation']   = (res?.data[i]['Date of Resignation'] == null || res?.data[i]['Date of Resignation'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of Resignation']);
          res.data[i]['Date of resignation']   = (res?.data[i]['Date of resignation'] == null || res?.data[i]['Date of resignation'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of resignation']);
          res.data[i]['Date of retirement']   = (res?.data[i]['Date of retirement'] == null || res?.data[i]['Date of retirement'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of retirement']);
          res.data[i]['Date of settlement']   = (res?.data[i]['Date of settlement'] == null || res?.data[i]['Date of settlement'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of settlement']);
          res.data[i]['Father date of birth']   = (res?.data[i]['Father date of birth'] == null || res?.data[i]['Father date of birth'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Father date of birth']);
          res.data[i]['Mother date of birth']   = (res?.data[i]['Mother date of birth'] == null || res?.data[i]['Mother date of birth'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Mother date of birth']);
          res.data[i]['Spouse date of birth']   = (res?.data[i]['Spouse date of birth'] == null || res?.data[i]['Spouse date of birth'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Spouse date of birth']);
          res.data[i]['Login date']   = (res?.data[i]['Login date'] == null || res?.data[i]['Login date'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Login date']);
          res.data[i]['Relieving Date']   = (res?.data[i]['Relieving Date'] == null || res?.data[i]['Relieving Date'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Relieving Date']);
          res.data[i]['Relieving Date as per policy']   = (res?.data[i]['Relieving Date as per policy'] == null || res?.data[i]['Relieving Date as per policy'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Relieving Date as per policy']);
          res.data[i]['Resignation date']   = (res?.data[i]['Resignation date'] == null || res?.data[i]['Resignation date'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Resignation date']);
        }
        this.apiExcelData = res?.data
        const limitedData = res?.data.slice(0, 20);
        this.limit = 20
        this.apiData = new MatTableDataSource(limitedData);
        this.setDataSourceAttributes();
      }else{
        this.noData = true
      }
      this.settingFormVal(res?.applied_filter)
      this.lastPageCount = res?.count;
      this.resDataFilter = res?.data_filter;
      this.resHeaderFilter = res?.header_filter
      this.appliedFilter = res?.applied_filter;
      if(this.initialLoaded){
        this.empServ.setDataFilter(this.resDataFilter)
        this.empServ.setHeaderFilter(this.resHeaderFilter)
        this.initialLoaded = false;
      }
      // Columns setup by considering page & sort
      if(!this.isSorted){
        this.setColumnsPanel(res?.check_list,res?.header_list);
      }
      // Saved data setup for filter
      if(Object.keys(this.resDataFilter)?.length != 0){
        this.urlGenerator()?.form.reset(this.resDataFilter)
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        this.settingFormVal(this.resDataFilter)
      }
      // Download Report
      if(this.reportDownload){
        this.commonLogic(this.apiExcelData)
        this.reportDownload = false
      }
      this.isLoading = false
    })
  }

  // URL & Form collection
  urlGenerator(){
    let urlForm;
    if(this.reportName == 'Snapshot'){
      urlForm = { url : 'employee-snapshot-report', form : this.snapshortForm}
    }else if(this.reportName == 'Bank Details'){
      urlForm = { url : 'employee-bank-report', form : this.bankDetailsForm}
    }else if(this.reportName == 'Employee Details'){
      urlForm = { url : 'employee-details-report', form : this.employeeDetailsForm}
    }else if(this.reportName == 'Personal'){
      urlForm = { url : 'employee-personal-report', form : this.personalDetailsForm}
    }else if(this.reportName == 'Employee Login Report'){
      urlForm = { url : 'employee-login-report', form : this.loginForm}
    }else if(this.reportName == 'Resignation'){
      urlForm = { url : 'employee-resignation-report', form : this.resignationForm}
    }else{
      urlForm = { url : 'employee-disable-report', form : this.disabledForm}
    }
    return urlForm
  }

  // Side Panel Date & Status
  settingFormVal(data:any){
    const reportConfig = {
      'Snapshot': [
        { controlName: 'birthday', from: 'from_date_birth', to: 'to_date_birth', isMandatory: false },
        { controlName: 'date_of_joining', from: 'from_date_join', to: 'to_date_join', isMandatory: false },
        { controlName: 'date_of_confirmation', from: 'from_date_confirm', to: 'to_date_confirm', isMandatory: false },
        { controlName: 'date_of_leaving', from: 'specific_month', to: 'specific_year', isMandatory: false },
        { controlName: 'created_date', from: 'cd_specific_date', to: '', isMandatory: false }
      ],
      'Employee Login Report': { controlName: 'date_of_login', from: 'from_date', to: 'to_date', isMandatory: true},
      'Resignation': { controlName: 'resignation_date', from: 'from_date', to: 'to_date', isMandatory: false},
    };
    const defaultConfig = { controlName: 'disabled_on', from: 'specific_month', to: 'specific_year', isMandatory: true };
    const config = reportConfig[this.reportName as keyof typeof reportConfig] || defaultConfig;
    if (Array.isArray(config)) {
      config.forEach(cfg => {
        this.updateFormControl(data, this.urlGenerator()?.form, cfg.controlName, cfg.from, cfg.to,  cfg.isMandatory);
      });
    } else {
      this.updateFormControl(data, this.urlGenerator()?.form, config.controlName, config.from, config.to,  config.isMandatory);
    }
  }

  // For Specific date setup
  updateFormControl(filter: any, formGroup: FormGroup, controlName: string, fromDateControl: string, toDateControl: string, isMandatory : boolean) {
    const dateRange = filter?.[controlName];
    let dateArray = dateRange?.split(',');
    let isHyphen = dateRange?.split('-');
    if (dateRange && dateArray.length === 2) {
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: new Date(dateArray[0]),
        [toDateControl]: new Date(dateArray[1])
      });
    } else if(isHyphen?.length == 3){
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: new Date(dateRange),
        [toDateControl]: ''
      });
    }else if (isHyphen?.length === 2) {
      const atten_month = moment(isHyphen[1], 'M').format('MMMM');
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: atten_month,
        [toDateControl]: isHyphen[0]
      });
    } else {
      formGroup.get(controlName)?.reset(dateRange);
    }
    if (dateRange === undefined && isMandatory) {
      formGroup.get(controlName)?.reset('this_month');
    }
  }


  // Columns Side Panel
  // Reset Columns Button
  resetColumns(){
    const formArray = this.sidePanelForm.get('columnsFormArr') as FormArray;
    formArray.clear();
    if(this.empServ.header_filter?.length > 0){
      this.resetFilterPop = true
      this.resetFrom = 'columnReset'
    }else{
      // Rebuild the FormArray with the initial state
      this.initialFormState.forEach((item:any) => {
        formArray.push(this.fb.group({
          name: [item.name],
          checkbox: [item.checkbox]
        }));
      });
      this.headersApply();
    }
  }

  // Apply Columns Button
  headersApply(){
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) => {
      const indexA = this.calcHeaders.indexOf(a);
      const indexB = this.calcHeaders.indexOf(b);
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    // Table Columns setup
    this.displayedColumns = sortedCheckedItems
    this.addedColumns = !this.columnsFormArrForm().pristine
  }

  // Set Columns Panel from API
  setColumnsPanel(check_list: any, header_list: any) {
    this.calcHeaders = this.empServ.header_filter?.length > 0 ? this.empServ.header_filter : header_list
    // Preserving initial values for Reset
    this.initialFormState = check_list.map((item:any) => ({
      name: item,
      checkbox: header_list.includes(item)
    }));
    // Setting form array for columns
    this.sidePanelForm = this.fb.group({
      columnsFormArr: this.fb.array(
        check_list.map((item: any) => this.fb.group({
          name: [item],
          checkbox: [this.calcHeaders.includes(item)]
        }))
      )
    });
    this.headersApply()
  }

  // Get Columns Form Panel (Formarray)
  columnsFormArrForm(): FormArray {
    return this.sidePanelForm.get('columnsFormArr') as FormArray;
  }

  // Atleast One column should be checked
  onCheckboxChange(index: number) {
    const formArray = this.columnsFormArrForm();
    const checkboxControl = formArray.at(index).get('checkbox');
    const checkedItems = formArray.controls.filter(ctrl => ctrl.get('checkbox')?.value);
    if (checkedItems.length === 0) {
      // If it's the last item being unchecked, recheck it
      checkboxControl?.setValue(true, { emitEvent: false });
    }
  }

  // Filter Setup
  // Filter Panel ON and OFF
  panel(value: any) {
    this.viewDetail = value
  }

  // Reset Filter Filter
  resetFilterFn(val:any){
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    this.resetFilterPop = val
    if (typeof val !== 'boolean') {
      this.reportInitialSetups()
    }else{
      this.resetFrom = 'filterReset'
    }
  }

  // Reset Filter Normal
  reportFnCall(reprt:any){
    this.formData = ''
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    if(typeof this.resetFilterPop !== 'boolean' && Object.keys(this.empServ.data_filter)?.length == 0){
      this.reportInitialSetups()
      this.filterSubmitMem = false
    }else{
      if(Object.keys(this.empServ.data_filter)?.length > 0){
        this.resetFilterPop = true
        this.resetFrom = 'filterReset'
      }
    }
  }

  // Each report filter output
  FilterApiConfig(res:any){
    this.limit = res?.limit;
    this.offset = res?.offset;
    this.formData = res?.filterForm
    this.filterSubmitMem = !this.formData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
    this.resetFilterFn(res)
    this.isSorted = true
    this.viewDetail = false
  }

  // Filter SAVE
  filterMemorization(curr:any){
    let isColumnVal = this.addedColumns || this.empServ.header_filter?.length > 0
    let isFilterVal = this.filterSubmitMem || Object.keys(this.empServ.data_filter)?.length > 0
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) =>{
      return this.displayedColumns.indexOf(a) - this.displayedColumns.indexOf(b)
    })
    let dataFilter = this.reportDataFunctions[this.reportName]?.() ?? "";
    //dataFilter is not null, undefined, or empty setup
    dataFilter = dataFilter || JSON.stringify({});
    let apiDataFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterReset', set to empty object
      apiDataFilter = JSON.stringify({});
    } else if (this.resetFrom === 'columnReset') {
        // If resetFrom is 'columnReset', check data_filter length
        if (Object.keys(this.empServ.data_filter)?.length > 0) {
            apiDataFilter = dataFilter;
        } else {
            // If data_filter is empty, set to empty object
            apiDataFilter = JSON.stringify({});
        }
    } else if (isFilterVal === false) {
        // If isFilterVal is false, set to empty object
        apiDataFilter = JSON.stringify({});
    } else {
        // Default case: set to dataFilter
        apiDataFilter = dataFilter;
    }
    let apiHeaderFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterreset' and header_filter has items, set to data
      if (this.empServ.header_filter?.length > 0) {
        apiHeaderFilter = JSON.stringify(sortedCheckedItems);
      } else {
        // Otherwise, set to empty array
        apiHeaderFilter = JSON.stringify([]);
      }
    } else if (isColumnVal || this.colRearranged) {
      // If not resetting, check if addedColumns or header_filter has items
      apiHeaderFilter = JSON.stringify(sortedCheckedItems);
    } else {
      // Default case: empty array
      apiHeaderFilter = JSON.stringify([]);
    }
    this.rprtServ.filterMemorize({ 'data_filter': apiDataFilter, 'header_filter': apiHeaderFilter, 'is_active': true, 'report': Number(this.reportId) }).subscribe((res: any) => {
      if(curr == 'tag'){
        this.notify.handleSuccessNotification("Updated Successfully","Success")
      }else{
        this.notify.handleSuccessNotification("Created Successfully","Success")
      }
      if(curr == 'reset'){
        if(this.resetFrom == 'filterReset'){
          this.empServ.setDataFilter({})
          this.filterSubmitMem = false
          this.isSorted = true
          this.initialLoaded = true
        }else{
          this.empServ.setHeaderFilter([])
          this.addedColumns = false
          this.isSorted = false
        }
      }else{
        this.filterSubmitMem = false
        this.addedColumns = false
      }
      this.applyFilterPop = false
      this.colRearranged = false
      this.saveFilterModalClicked = false
      this.resetFrom = ''
      this.page = 1
      this.pageNumber = 0
      this.offset = 0
      this.limit = 20
      this.isSorted = false
      this.reportInitialSetups()
      if(curr == 'save'){
        this.router.navigate(['/employee-report']);
      }
    })
  }

  // Save data format from child
  reportDataFunctions: { [key: string]: () => any } = {
    'Snapshot': () => this.appSnapshot.getData(),
    'Bank Details': () => this.appbank.getData(),
    'Employee Details': () => this.appempdetails.getData(),
    'Personal': () => this.apppersonaldetails.getData(),
    'Employee Login Report': () => this.applogin.getData(),
    'Resignation': () => this.appResignation.getData(),
    'Disable Portal Access': () => this.disableAccess.getData()
  };

   // Popups
  // Filter Mem button
  saveFilterModal(){
    this.saveFilterModalClicked = true;
    if(this.filterSaveForm.get('filter')?.value == true){
      this.filterMemorization('save')
    }else{
      this.filterSubmitMem = false
      this.addedColumns = false
      this.colRearranged = false
      setTimeout(()=>{
        this.router.navigate(['/employee-report']);
        this.cd.detectChanges()
      },0)
    }
  }

  // Reset Saved Filter button
  resetFilterApply(){
    this.viewDetail = false
    this.filterMemorization('reset')
    // Filter reset
    if(this.resetFrom == 'filterReset'){
      this.resetFilterControllers()
    }
    this.resetFilterPop = false
  }

  // Reset from Parent
  resetFilterControllers(): void {
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'Snapshot': () => this.appSnapshot.resetFilterControllers('reset'),
      'Bank Details': () => this.appbank.resetFilterControllers('reset'),
      'Employee Details': () => this.appempdetails.resetFilterControllers('reset'),
      'Personal': () => this.apppersonaldetails.resetFilterControllers('reset'),
      'Employee Login Report': () => this.applogin.resetFilterControllers('reset'),
      'Resignation': () => this.appResignation.resetFilterControllers('reset'),
      'Disable Portal Access': () => this.disableAccess.resetFilterControllers('reset')
    };
    if (this.reportName in reportActions) {
      reportActions[this.reportName]();
    }
  }

  // Close Button
  confirm(): boolean {
    if(this.filterSubmitMem == true || this.addedColumns == true || this.colRearranged == true){
      this.applyFilterPop = true
      return false
    }else{
      return true
    }
  }

  // Download Excel
  export(){
    this.limit = this.lastPageCount
    this.offset = 0
    this.isSorted = true
    this.reportDownload = true
    this.reportInitialSetups()
  }

  // Download arrangements
  commonLogic(data: any) {
    let column = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject: any = {};
      for (const key of column) {
        if (obj.hasOwnProperty(key)) {
          newObject[key] = obj[key];
        }
      }
      newArray.push(newObject);
    }
    if (data != undefined)
      this.exportExcel(newArray, this.reportName);
    this.cd.detectChanges()
  }

  public exportExcel(jsonData: any[], fileName: string): void {
    let excelHeaders = []
    let templateToExcel :any = []
    if (!this.noData) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, fileName);
    }
    else {
      for (let i = 0; i < this.displayedColumns.length; i++) {
        excelHeaders.push(this.displayedColumns[i])
        templateToExcel = [excelHeaders, []];
      }
      const wss: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(templateToExcel);
      const wbb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
      XLSX.writeFile(wbb, fileName + '.xlsx')
    }
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, fileName + '.xlsx');
  }

  // Table COlumn Drop
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    if(this.displayedColumns?.length > 1){
      this.colRearranged = true
      this.calcHeaders = this.displayedColumns
    }
  }

  // Sort
  onSort(val: any) {
    this.direction = (this.sortProperty === val && this.direction === 'desc') ? 'asc' : 'desc';
    this.sortProperty = (this.sortProperty === val) ? this.sortProperty : val;
    this.ordering = `${this.direction === 'desc' ? '-' : ''}${val}`;
    this.formData = this.formData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Api call with updated Limit
  pageChanged(val: any) {
    this.pageNumber = val - 1
    this.offset = this.rprtServ.calculateOffset(val - 1)
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Tags
  // Show/Hide close icon
  isFilterVisible(reportName: string, filterKey: any): boolean {
    const reportFilters : { [key: string]: string[] } = {
      'Snapshot': [''],
      'Bank Details': [''],
      'Employee Details': [''],
      'Personal': [''],
      'Employee Login Report': ['date_of_login'],
      'Resignation': [''],
      'Disable Portal Access': ['disabled_on']
    };

    return reportName in reportFilters && !reportFilters[reportName].includes(filterKey);
  }

  // Call child fn for tag close
  handleTagClose(reportName: string, filterKey: any): void {
    const controlMap: { [key: string]: string } = {
      'emp_type': 'employee_type',
      'emp_status': 'employment_status'
    };

    this.closeControl = controlMap[filterKey] || filterKey;
    this.cd.detectChanges()
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'Snapshot': () => this.appSnapshot.applyTagClose1(),
      'Bank Details': () => this.appbank.applyTagClose1(),
      'Employee Details': () => this.appempdetails.applyTagClose1(),
      'Personal': () => this.apppersonaldetails.applyTagClose1(),
      'Employee Login Report': () => this.applogin.applyTagClose1(),
      'Resignation': () => this.appResignation.applyTagClose1(),
      'Disable Portal Access': () => this.disableAccess.applyTagClose1()
    };
    if (reportName in reportActions) {
      reportActions[reportName]();
    }
    if(Object.keys(this.empServ.data_filter)?.length > 0){
      this.filterMemorization('tag')
    }
  }

  // Utilities
  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Sort attribute for response
  setDataSourceAttributes() {
    if (this.apiData != undefined && this.apiData != null) {
      this.apiData.sort = this.sort;
    }
  }

  // More than 1 item in array
  tagMultiDataFunction(data: any) {
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if (data?.length == 1) {
      return this.tagMultiData;
    } else {
      this.tagMultiCnt = '+' + JSON.stringify(data?.length - 1);
      return this.tagMultiData;
    }
  }

  // Tage Underscore
  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  // Excess data on click of tags
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  // MatDatePicker
  salFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // DOJ based filter date
  dojFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // HTML for date split
  getDateRangeToDisplay(dateSetup: any, key: any): string {
    const dates = dateSetup?.[key];
    if (!dates) return '';
    const dateArray = dates.split(',');

    if (dateArray.length === 2) {
      return `${this.appService.dateFormatDisplay(dateArray[0])} - ${this.appService.dateFormatDisplay(dateArray[1])}`;
    } else if (dateArray.length === 1) {
      return this.appService.dateFormatDisplay(dateArray[0]) || '';
    } else if (dates.includes('-')) {
      const [year, month] = dates.split('-');
      return `${moment(month, 'M').format('MMMM')} ${year}`;
    } else {
      return this.appService.dateFormatDisplay(dates) || '';
    }
  }

  // Year List
  yearListFunction(){
    this.selectedYear = new Date().getFullYear();
    this.attendReprtServ.yearListDropdown().subscribe((res: any) => {
     if(res?.created_year == this.selectedYear){
        this.yearsList.push(res?.created_year)
      }
      else{
        for(let i=this.selectedYear;i>=res?.created_year;i--){
          this.yearsList.push(i)
        }
      }
    })
  }

  // Date Format
  getDateFormat(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const dateFormat = this.appService.getdatepickerformat();
        if (dateFormat !== '') {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }

}
