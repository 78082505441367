import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { PayGroupService } from 'src/app/@core/services/pay-group.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-pay-group',
  templateUrl: './pay-group.component.html',
  styleUrls: ['./pay-group.component.scss']
})
export class PayGroupComponent implements OnInit {
  loader                  = true;
  paygroupList:any        = [];
  rightspaygrp:any        = [];
  paygrpcomp:any          = [];
  appkey1:any             = [];
  assignees:any           = [];
  deleteClicked = false
  searchKeyword="";
  showSidePanel                  = false;
  nodata                         = false;
  paymentPlaceholder:string      = this.messageService.searchdisplay('pay group');
  searchlistdata:any             = [] ;
  status                         = true;
  deleteId:any                   = "";
  deleteToggle:any               = false;
  paygrpview:any                 = [];
  loader1                        = false;
  /********INFINITY SCROLL VARIABLES  ****/
  offset                  = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  paygroupDetailsList:any = [];
  infinityloader          = false;
  permissions:any         = [];
  statusBtn:any           = 'Active';
  iseditclicked= false
  isLoading = false
  searchData : Subject<void> = new Subject();

  @ViewChild(SearchbarComponent) child:any;
  constructor(public breakpointObserver: BreakpointObserver,
    private pgs:PayGroupService,
    public router: Router,
    public appService:AppService,
    private notificationService: NotificationService,
    public messageService : MessageService,
    ) { }

  ngOnInit(): void {
    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.payGroupData();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.pgs.getPayGroupList(this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  payGroupData(){
    this.loader = true;
    this.pgs.getPayGroupList(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res.results.length;
    if(this.offset ==0){
      this.paygroupList = [];
    }
    if (res.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata = false;
      this.paygroupDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.payGroupData();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; i++) {
      let flag =0;
      for(let j=0;j<this.paygroupList.length;j++){
        if(this.paygroupDetailsList[i]['id'] == this.paygroupList[j]['id'] ){
          flag ++;
        }
      }
      if(flag == 0)
        this.paygroupList.push(this.paygroupDetailsList[i]);
    }
  }

  /* searchbar result return */
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = ""
      this.offset = 0;
      this.paygroupList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  /* active or delete filter */
  statusfilter(value:any){
    this.status = value;
    this.offset = 0;
    this.paygroupList = [];
    this.child.clearBar();
  }

  editPayGroup(id:any,is_used:any){
    if(is_used ){
      if( this.iseditclicked == false){
        this.notificationService.handleErrorNotification('This paygroup cannot be edited as it is already being used in a salary structure.',"Error");
        this.iseditclicked = true;
        setTimeout(() => {
          this.iseditclicked = false;
        }, 5000);
      }
    }else
    this.router.navigate(['/pay-group/pay-group-setup/'+id]);
  }

  deleteContent(id:any){
    this.deleteClicked = true
    let data = { "is_active": false};
    this.pgs.deletePayGroup(id,data).subscribe((res: any) => {
      this.deleteClicked     = false
      this.deleteToggle     = false;
      this.loader           = true;
      this.offset           = 0;
      this.paygroupList = [];
      this.payGroupData();
    },
    (error:any)=>{
      this.deleteClicked     = false
      this.deleteToggle     = false;
    })
  }

  viewpaygrp(id:any){
     if(this.permissions.v){
      this.showSidePanel = true;
      this.loader1       = true;
      this.assignees     = [];
      this.pgs.getPayGroup(id).subscribe((res: any) => {
        this.paygrpview = res;
        this.rightspaygrp= this.paygrpview.assignments[0];
        for(let key of res.assignments){
          let appkey = key.unit_type.toLowerCase();
          if(appkey == "business unit"){
            appkey = "businessunit";
            key.unit_type ="BU"
          }
          if(appkey == "employee"){
            appkey = "employee";
          }
        this.appkey1 = appkey.toString()+"_name";

        if(appkey=="employee"){
          let empName = key[this.appkey1]['name']+" ("+key[this.appkey1]['employee_code']+")";
          let empImg  = key[this.appkey1]['profile_image'];
          let color_code  = key[this.appkey1]['color_code'];
          this.assignees.push({'name': empName,'img': empImg,'color_code':color_code})
        }else if(appkey=="company"){
          let compName = key.company_name;
          let compImg  = key.company_logo;
          this.assignees.push({'name': compName,'img': compImg})
        }else{
        this.assignees.push(key[this.appkey1]);
        }
        }
        this.loader1 = false;
      })
    }
  }
  searchpaygroup(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.paygroupList = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/pay-group/pay-group-setup']);
  }
}
