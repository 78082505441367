<div [formGroup]="templatedetailForm">
  <div class="container-fluid p-32  form-container">
    <h5 class="mb-24 text-uppercase fs-16 fw-500">TEMPLATE details</h5>
    <div class="row row-24" style="min-height: calc(100vh - (3.625rem + 5.5rem  + 4rem)); " cdkDropListGroup>
      <div class="col-lg-5">
        <div class="card card-c2 shadow-0 px-24 py-8 fixed-editor  overflow-auto">
          <div class="card-body px-0 py-16">
            <div class="form-icon icon-left">
              <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('tags')}}"  [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}"/>
              <i class="icon-search"></i>
            </div>

            <!-- <div class="alert alert-warning show h-auto align-items-start mt-24">
              <p class="mb-0">
                <span class="text-warning"></span>
                You can drag and drop the tags to embed
              </p>
              <div class="btn-close"></div>
            </div> -->
            <div class="d-flex flex-column ">

              <h5 class="fs-14 fw-600 my-16 text-uppercase">Recently used</h5>
              <div #activeList="cdkDropList"
              class="box-list"
              cdkDropList
              cdkDropListOrientation="vertical"
              [cdkDropListData]="tags"
              (cdkDropListDropped)="drop($event)" class="d-flex gap-8 flex-column justify-contents-items">
              <div class="badge rounded-pill me-auto fs-14 px-16 pointer" *ngFor="let tag of tags" cdkDrag>
                {{tag}}
              </div>
            </div>
              <h5 class="fs-14 fw-600 my-16 text-uppercase">All tags</h5>
              <div
              #activeList="cdkDropList"
              cdkDropList
              cdkDropListOrientation="vertical"
              [cdkDropListData]="tagList"
              (cdkDropListDropped)="drop($event)"
              class="d-flex gap-8 flex-column">
                <div
                *ngFor = "let tag of tagList | filter:searchString">
                  <span class="badge rounded-pill me-auto fs-14 px-16 pointer" cdkDrag>{{tag.name}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-7 ">
        <div class="row row-16  ">
          <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Template name</label>
            <input placeholder="{{this.messageService.placeholderdisp('Template Name')}}" formControlName="templateName" class="form-control">
            <span *ngIf="templatedetailForm.controls['templateName'].touched && templatedetailForm.controls['templateName'].invalid" class="errormsg">Name is required</span>
          </div>
          <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Template type</label>
              <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('Template type')}}" (change)="ontypeclick($event)" formControlName="templateType">
                <ng-option *ngFor="let item of templatetype" [value]="item">{{ item.name }}</ng-option>
              </ng-select>
              <span *ngIf="templatedetailForm.controls['templateType'].touched && templatedetailForm.controls['templateType'].invalid" class="errormsg">Template Type is required</span>
            </div>
          <div class="col-12 form-row">
            <label for="" class="form-label required">Subject</label>
            <input type="text" placeholder="{{this.messageService.placeholderdisp('Subject')}}" formControlName="subject" class="form-control">
            <span *ngIf="templatedetailForm.controls['subject'].touched && templatedetailForm.controls['subject'].invalid" class="errormsg">Subject is required</span>
          </div>
          <div class="col-12 form-row">
            <label for="" class="form-label required">Description </label>
            <div class="container">
              <div class="content">
                <div class="editor">
                  <div style="display: flex;">
                    <!-- <p>Hi</p>
                    <span #inactiveList="cdkDropList"
                    class="d-flex gap-8 flex-column justify-contents-items close"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    [cdkDropListData]="basket"
                    [cdkDropListConnectedTo]="[activeList]"
                    (cdkDropListDropped)="drop($event)">
                    <span class="variable badge rounded-pill me-auto fs-14 px-16 pointer" *ngFor="let item of basket" cdkDrag>
                     <span *ngIf="showItem" (click)="showItem=false">{{item}}</span>
                     <span *ngIf="!showItem"><input formControlName="tagname" style="background: #0078CE;border: 0rem;" type="text"></span>
                    </span>
                  </span> -->
                    <!-- <span cdkDropList [cdkDropListData]="basket" class="d-flex gap-8 flex-column justify-contents-items close"
                      (cdkDropListDropped)="drop($event)">
                      <span class="variable badge rounded-pill me-auto fs-14 px-16 pointer" *ngFor="let item of basket"
                        cdkDrag>
                        <input style="background: #0078CE; border: #0078CE;" value="{{item}}">
                        <button class="btn-reset"><i class="icon-close-lg fs-16"></i></button>
                      </span>
                    </span> -->
                  </div>
                  <ckeditor #editor
                  [data]="editorData"
                  formControlName="description"
                  [editor]="Editor"
                  (ready)="onReady($event)"
                  ></ckeditor>
                </div>
              </div>
            </div>
            <span *ngIf="templatedetailForm.controls['description'].touched && templatedetailForm.controls['description'].invalid" class="errormsg">Description is required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="!templatedetailForm.valid"
      (click)="onPreviewClick()">Save & Preview</button>
  </footer>
</div>
