<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Location group</p>
        </div>
        <div class="row row-16">
            <div class=" col-12 ">
                <label for="" class="form-label required">Location group name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Define the name of the location group." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                <input type="text" formControlName="name" class="form-control" placeholder="{{this.messageService.placeholderdisp('Location group name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name?.touched || f.name?.dirty) &&  f.name?.errors || f.name?.errors?.exist}" (keyup)="keyUp()">
              <div *ngIf="(submitted || f.name?.touched || f.name?.dirty) && f.name?.errors || f.name?.errors?.exist" class="invalid-feedback">
                <div *ngIf="f.name?.hasError('exist')">{{this.messageService.validationDisplay(f.name?.value)}}</div>
                <div *ngIf="f.name?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="!f.name?.errors?.pattern && f.name?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf="f.name?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              </div>
            </div>
            <div class=" col-12 ">
                <label for="" class="form-label required"> State </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose the states that are included in the location group." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                    <ng-select
                    [ngClass]="{ 'is-invalid': (submitted || f.states.touched) &&  f.states.errors}"
                    placeholder="{{this.messageService.selectplaceholddisplay('State')}}"
                    formControlName="states"
                    [items]="StateDropdown"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="multiselect"
                    (change)="cityfunction();clearcity();"
                    >
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        {{ item.name }}
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="formGp.value.states ">
                            <span class="ng-value-label">{{formGp.value.states.length }} State selected</span>
                        </div>
                    </ng-template>
                </ng-select>
                <div *ngIf="(submitted || f.states.touched) && f.states.errors" class="invalid-feedback">
                    <div *ngIf="f.states.errors.required">{{this.messageService.validationDisplay('required')}}
                     </div>
                </div>
            </div>
            <div class="col-12" *ngIf="formGp.value.states">
                <ul class="selected-values">
                    <li class="ng-value" *ngFor="let item of formGp.value.states,let ki =index">
                        <ng-container *ngFor="let insideitem of StateDropdown, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>    
                        </ng-container>
                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(ki,'state')" aria-hidden="true">×</span>    
                    </li>
                </ul>
    
            </div>
            <div class=" col-12 ">
                <label for="" class="form-label required">Cities </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Map the cities which are to be included in the location group." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <ng-select
                [ngClass]="{ 'is-invalid': (submitted || f.cities.touched) &&  f.cities.errors}"
                placeholder="{{this.messageService.selectplaceholddisplay('Cities')}}"
                formControlName="cities"
                [items]="CityDropdown"
                [multiple]="true"
                groupBy="selectedAllGroup"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                bindLabel="name"
                [closeOnSelect]="false"
                bindValue="id"
                class="multiselect"
                (change)="datafn()"
                >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                    {{ item.name }}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="formGp.value.cities ">
                        <span class="ng-value-label">{{formGp.value.cities.length }} City selected</span>
                    </div>
                </ng-template>
              </ng-select>
                <div *ngIf="(submitted || f.cities.touched) && f.cities.errors" class="invalid-feedback">
                    <div *ngIf="f.cities.errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <ul class="selected-values" *ngIf="formGp.value.cities">
                    <li class="ng-value" *ngFor="let item of formGp.value.cities,let ki =index">
                        <ng-container *ngFor="let insideitem of CityDropdown, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>    
                        </ng-container>
                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(ki,'city')" aria-hidden="true">×</span>    
                    </li>
                </ul>

            </div>  
        </div>
    </div>
    
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid || componentError)" (click)="validateGROUP()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validateGROUP()">NEXT</button>
      </footer>
</form>