import { Component, OnInit, ViewChild } from '@angular/core';
import { Form16Service } from 'src/app/@core/services/form16.service';
import { AppService } from 'src/app/app.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-form16',
  templateUrl: './form16.component.html',
  styleUrls: ['./form16.component.scss']
})
export class Form16Component implements OnInit {
  searchKeyword="";
  form16_data:any             = [];
  searchlistdata:any          = [] ;
  loader                      = false;
  permissions:any             = [];
  offset                      = 0;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  form16DataList:any          = [];
  infinityloader              = false;
  currentFilterYear           = this.appService.getFinancialYear();
  nodatafound                 = false;
  selectedYear :any           = this.appService.getFinancialYear();

  currentYear:any;
  searchkey =this.messageService.searchdisplay('Year');
  @ViewChild(SearchbarComponent) child:any;
  constructor( private form16: Form16Service,public messageService : MessageService,public appService: AppService) { }

  ngOnInit(): void {
    this.getPermission();
    this.getForm16Data();
   this.currentYear   = this.appService.getFinancialYear();

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getForm16Data(){
    this.loader   = true;
    this.form16.filterForm16(this.currentFilterYear,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{

      this.loader = false;

      this.listlength  = res?.body?.data.length;
      if(this.offset ==0){
        this.form16_data = [];
      }
      if (res.body.data.length == 0) {
        this.nodatafound = this.offset == 0 ? true :false;
      }else {
        this.nodatafound    = false;
        this.form16DataList = res.body.data;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);

    })

  }
  yearFilter(year:any){

    this.form16_data       = [];
    this.offset            = 0;
    this.currentFilterYear = year;
    this.getForm16Data();

  }
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getForm16Data();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.form16_data.push(this.form16DataList[i]);

    }


  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset          = 0;
      this.form16_data    = [];
      this.getForm16Data();
    }else{
      this.form16.filterForm16(data,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{

          this.form16_data = res?.body?.data;
          this.loader      = false;
        });
    }
  }
  searchform16(searchKeyword:any){
    this.loader = true;
    this.offset           = 0;
    this.form16_data= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getForm16Data();
    }
  }
  yearChange(value:any){

    this.selectedYear       = Number(value);
    this.currentFilterYear  = value;
    this.getForm16Data();
  }
}
