import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function spaceValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }
      // const nonSpace = /^(?! )*(?<! )$/.test(value);

      // return !nonSpace ? {pattern:true}: null;
      return ((control.value.startsWith(' ')) || (control.value.endsWith(' ')))? {pattern:true}: null;
  }
}

