import { Component, Input, OnInit, Output,EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-ar-compensatory-off',
  templateUrl: './ar-compensatory-off.component.html',
  styleUrls: ['./ar-compensatory-off.component.scss']
})
export class ArCompensatoryOffComponent implements OnInit {

 constructor(public messageService : MessageService, public cd :  ChangeDetectorRef) { }
  @Input() formGp:any  = []
  @Input()disabled = false;
  @Input() editId : any
  @Input() overtimeStatus : any
  sameValidation = false
  activeComp = false
  isconfirmed = false
  disData         : any = ['min_hour_full_day','min_hour_half_day']
  disNoData       : any = ['min_hour_full_day','min_hour_half_day','after_how_many_days','comp_off_eligibility_fulldays','comp_off_eligibility_halfdays']
  submitted = false;
  isClicked = false

  @Output() submitmethod = new EventEmitter();
  ngOnInit(): void {
    this.isClicked = false
    if(this.editId!=0)
      this.activeComp = true;
      else{
        if(this.overtimeStatus==false)
        this.activeComp = true;
        else
        this.activeComp = false;
      }
    if(this.formGp.controls.enable_compoff.value){
      this.setDisabled(true,this.disData,"init")
      if(this.formGp.controls.comp_off_expiry.value){
        this.setDisabled(true,['after_how_many_days'],"init")
      }
    }
  }

  ngAfterViewInit() {
    this.fullDay()
    this.halfDay()
  }
  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(4);
  }

  setDisabled(value: boolean,data:any,from:any="screen") {
    if(value) {
      data.forEach((item:any)=>{
        this.formGp.get(item).setValidators([Validators.required])
        if(item == 'after_how_many_days'){
          this.formGp.get(item).setValidators([Validators.required,Validators.min(1),Validators.max(365),NumberpatternValidator()])
        }
      })
      } else {
        data.forEach((item:any)=>{
          this.formGp.get(item).clearValidators()
        })
        this.isClicked = false
      }
      data.forEach((item:any)=>{
        if(from != "init")
          this.formGp.get(item)?.setValue(null);
        this.formGp.get(item).updateValueAndValidity()
      })
  }
  notanumber(val:any){
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }
  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }

  setFormula(){
    if(this.overtimeStatus==true){
      this.activeComp = false
      this.isconfirmed    = true
    }
    this.formGp.controls.comp_off_workdays.value = false
    this.formGp.controls.comp_off_expiry.value = false
    this.formGp.controls.comp_off_halfday_work.value = false
  }

  fullDay(){
    let full_day = moment(this.formGp.controls.min_hour_full_day.value,'HH:mm:ss').format('HH:mm')
      if(full_day == '00:00'){
        this.f.min_hour_full_day.setErrors({'fullZero' : true})
      }
    this.cd.detectChanges()
    this.halfDay()
  }

  halfDay(){
   if(this.formGp.controls.min_hour_half_day.value != null){
    let half_day = moment(this.formGp.controls.min_hour_half_day.value,'HH:mm:ss').format('HH:mm')
    if(half_day == '00:00'){
      this.f.min_hour_half_day.setErrors({'halfZero' : true})
    } else if(moment(this.formGp.controls.min_hour_half_day.value,'HH:mm:ss').isSame(moment(this.formGp.controls.min_hour_full_day.value,'HH:mm:ss'))){
      this.f.min_hour_half_day.setErrors({'samefull' : true})
    }
   }
    this.cd.detectChanges()
  }

  activateCompOff(status:any){
    this.activeComp = false
    if(status==false){
      this.formGp.get('enable_compoff').setValue(false)
      this.setDisabled(false,this.disNoData)
    }
    else{
      this.isClicked = true
      this.activeComp = true
    }
    this.isconfirmed = false
  }

}
