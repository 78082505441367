<form action="" class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16">
            <div class="col-12">
                <p class=" fw-500 mb-0">Location</p>
            </div>
            <ng-container formArrayName="geofencing_locations"
                *ngFor="let item of locationarray().controls; let i=index; ">
                <ng-container [formGroupName]="i">
                    <div class="col-12">
                        <div class="card card-c2 px-24 pt-16 pb-24">
                            <div class="row row-16">
                                <div class="col-12 d-flex">
                                    <p class="mb-0 title">Location {{i+1}}</p>
                                    <a class=" text-danger ms-auto" (click)="removelocationarray(i)" *ngIf="i!=0">
                                        <i class="icon-trash "></i>
                                    </a>
                                </div>
                                <div class=" col-12 form-row ">
                                    <label for="" class="form-label required">Location </label>
                                    <ng-select formControlName="geofencing_location_id" class="form-ngselect"
                                        [ngClass]="{ 'is-invalid': ((submitted || locationarray().controls[i].get ('geofencing_location_id')?.touched) && locationarray().controls[i].get('geofencing_location_id')?.errors) || locationarray().controls[i].get('geofencing_location_id')?.errors?.sameid}"
                                        placeholder="{{this.messageService.selectplaceholddisplay('location')}}" (change)="locselectfn()" [ngClass]="{ 'is-invalid':selectedSubSecError[i]}">
                                        <ng-option *ngFor="let location of locationdropdown"
                                            [value]="location.id">{{location.name}}</ng-option>
                                    </ng-select>

                                    <div *ngIf="((submitted || locationarray().controls[i].get ('geofencing_location_id')?.touched) && locationarray().controls[i].get('geofencing_location_id')?.errors) || locationarray().controls[i].get('geofencing_location_id')?.errors?.sameid"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="locationarray().controls[i].get('geofencing_location_id')?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                        <div *ngIf="locationarray().controls[i].get('geofencing_location_id')?.errors?.sameid">
                                            The data is already selected, please select
                                            different data.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="form-row">
                                        <label for="" class="form-label required">Is this location for specific
                                            period?</label>
                                    </div>
                                    <div class="col-lg-12 form-row mt-0">
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" formControlName="for_specific_days_only"
                                                    type="radio" [value]=true
                                                    (click)="setRequired(true,'duration_of_days',i)">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input formControlName="for_specific_days_only" class="form-check-input"
                                                    type="radio" [value]=false
                                                    (click)="setRequired(false,'duration_of_days',i)">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12"
                                    *ngIf="locationarray().controls[i].get('for_specific_days_only')?.value">
                                    <input type="text" formControlName="duration_of_days" class="form-control"
                                        placeholder="{{this.messageService.placeholderdisp('days')}}"
                                        [ngClass]="{ 'is-invalid': (submitted || locationarray().controls[i].get('duration_of_days')?.touched || locationarray().controls[i].get('duration_of_days')?.dirty) &&  locationarray().controls[i].get('duration_of_days')?.errors || locationarray().controls[i].get('duration_of_days')?.errors?.exist}">
                                    <div *ngIf="(submitted || locationarray().controls[i].get('duration_of_days')?.touched || locationarray().controls[i].get('duration_of_days')?.dirty) && locationarray().controls[i].get('duration_of_days')?.errors || locationarray().controls[i].get('duration_of_days')?.errors?.exist"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="locationarray().controls[i].get('duration_of_days')?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                        <div
                                            *ngIf="!locationarray().controls[i].get('duration_of_days')?.errors?.pattern && locationarray().controls[i].get('duration_of_days')?.errors?.maxlength">
                                            {{this.messageService.fieldlengthvalidation('value','10')}}
                                        </div>
                                        <div
                                            *ngIf="locationarray().controls[i].get('duration_of_days')?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </ng-container>
            </ng-container>
            <div class="col-12  ">
                <a class="link-primary1  " (click)="addmorelocation()"><i class="icon-plus-square-fill me-8"></i>Add
                    More</a>
            </div>
            <!-- </ng-container> -->
        </div>
    </div>
    <footer class="submit-footer">
        <button [disabled]="!formGp.valid || disabled" (click)="validateForm()"
            class="btn btn-primary btn-sm ms-auto">Save</button>
        <!-- <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button> -->
    </footer>
</form>
