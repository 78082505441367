<div class="config-layout">
    <header class="config-header">
        <h5 class="m-0">{{title}} confirmation policy</h5>
        <button class="btn-reset text-light-600" routerLink="/confirmation-policy"><i
                class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <ng-container *ngIf="!loader">
      <aside class="config-sidebar">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
            <li [ngbNavItem]="1">
                <a ngbNavLink class="step" [class]="{'success': confirmationMainForm['controls'].policySetting.valid || confirmationMainForm['controls'].policySetting.disabled}">
                    <div class="count">1</div>
                    <div class="content">
                        <span>Step 1</span>
                        <h6>Policy settings</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-confirmation-policy-settings [disabled]="disabled" [editflag]="editflag" [confirmationDropdown]="confirmationDropdown" [terminationDropdown]="terminationDropdown" [extensionDropdown]="extensionDropdown" [formGp]="policySetting" (submitMethod)="submitMethod(2)"></app-confirmation-policy-settings>
                </ng-template>
            <li [ngbNavItem]="2">
                <a ngbNavLink class="step" [class]="{'success': confirmationMainForm['controls'].confirmation_policy_applicablity.valid || confirmationMainForm['controls'].confirmation_policy_applicablity.disabled}">
                    <div class="count">2</div>
                    <div class="content">
                        <span>Step 2</span>
                        <h6>Applicability</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                  <!-- Common component -->
                  <app-common-applicability [disabled]="disabled" [heading]="'Applicability'" [isEditForm]="isEditForm" [formGp]="confirmation_policy_applicablity" (submitMethod)="submitForm()" [hint]="'Assign the policy to the appropriate organizational unit (OU) or specific set of employees.'"></app-common-applicability>
                </ng-template>
            </li>
        </ul>
      </aside>
      <main class="config-container">
          <div [ngbNavOutlet]="nav"></div>
      </main>
    </ng-container>
</div>
