import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup , Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataacessService } from 'src/app/@core/services/dataaccess.service';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-da-define',
  templateUrl: './da-define.component.html',
  styleUrls: ['./da-define.component.scss']
})
export class DaDefineComponent implements OnInit {
  validated: boolean = false;
  dataUnit : any = []
  id:any
  value:any
  private subjectKeyUp = new Subject<any>();

constructor(public formBuilder : FormBuilder,public messageService : MessageService,
    public existDataService :  ExistDatasService, public route: ActivatedRoute,
    public fb:FormBuilder,public appService:AppService,
    private cd : ChangeDetectorRef
  ) {
}
ngOnInit(){
  this.id = this.route.snapshot.params['id']
  this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
    this.existService();
  })
  this.selectedItems = [];
  if (this.moduleDropdown.length != 0) {
    this.selectAllForDropdownItems(this.moduleDropdown);
  }
  this.initiateErrorStatus();
}
@Input()formGp : FormGroup = this.fb.group({
  name: '',
  module: [''],
  employee: ['']
});
@Input()employeeDropdown:any=[];
@Input()moduleDropdown:any=[];
@Input() editflag:any

@Output()
submitmethod = new EventEmitter();
disabled            = false;
limitSelection      = false;
selectedItems: any  = [];

  validateConfigureFlow() {
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  clear(item:any,index:number){
    if(item == "module"){
      let daata = this.formGp.value.module;
      daata.splice(index, 1);
      this.formGp.patchValue({
        module: daata
      });
    }
    else if(item == "employee"){
      let daata = this.formGp.value.employee;
      daata.splice(index, 1);
      this.formGp.patchValue({
        employee: daata
      });
    }
    this.initiateErrorStatus();
  }

   // Simplified function to use for validation
   existService(){
    if(!this.editflag){
      this.value ={
        "page":"dataaccess",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"dataaccess",
        "field":"name",
        "value": this.f.name.value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.dataUnit = res.body
      if(this.dataUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  // Inactive Employee Setup
  ngChange(e:any){
    if(this.hasSpecificStatus(e) && !this.editflag){
      this.formGp?.get('employee')?.setErrors({
        'relievedError' : true
      })
    }
  }

  hasSpecificStatus(employees:any){
    const statuses = ['Relieved','Settled','Terminated','Retired']
    return employees.every((employee:any)=> statuses.includes(employee.employment_status));
  }

  initiateErrorStatus(){
    if(this.f.employee.value != null && this.f.employee.value != ''){
      const filteredData = this.employeeDropdown.filter((item:any) => this.f.employee.value.includes(item.id))
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData) && !this.editflag){
          this.formGp?.get('employee')?.setErrors({
            'relievedError' : true
          })
          this.formGp.get('employee')?.markAsTouched();
          this.cd.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.employeeDropdown.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }
}
