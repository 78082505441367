<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Weekly Off Details</h3>
      <p class="text-helper mb-md-0">Set and define week-offs for the employees on this page.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='weekOffPlaceHolder'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchWeekOff($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All'}"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active'}"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted'}"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions?.a" routerLink="/weekly-off/weekly-off-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add WEEKLY OFF </button>
      </div>
    </div>
  </div>
  <!-- No Data -->
  <div *ngIf="noData && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg6.png'" [noDataText]="'Plan Time Off!'"
      [noDataPara]="'Manage weekly offs for your team and keep everyone refreshed and balanced. Create and define your week-offs by clicking \'Add Week Off.\''"
      [hasPermission]="permissions?.a" (addData)="addNewdata()">
    </app-nodata-view>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16">
    <ng-container *ngIf="loader">
      <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </ng-container>
  </div>

  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="!loader">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let week_data of weekOffData">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewWeek(week_data?.id)">
                <div class="logo-img {{week_data?.color_code}}">
                  {{this.appService.getFirstChar(week_data?.policy_name,2)}}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 tips">{{week_data?.policy_name}}</h6>
                  <span class="tips-helper">{{week_data?.policy_name}}</span>
                  <div
                    class="fs-10 text-uppercase fw-500 {{(week_data?.is_active == true) ? 'text-success' : 'text-danger' }}">
                    {{(week_data?.is_active == true) ? 'Active' : 'Deleted' }}
                  </div>

                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section"
                *ngIf="week_data?.is_active == true && (permissions?.e || permissions?.d)">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical "></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngIf="permissions?.e" (click)="editWeekOff(week_data?.id)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button class="text-danger" ngbDropdownItem *ngIf="permissions?.d"
                    (click)="deleteAlert = true;deleteId = week_data?.id">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
  </ng-template>
  <!-- <app-infinity-loader *ngIf="infinityloader"></app-infinity-loader> -->
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:64.4375rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
      <app-view-loader *ngIf="vLoader"></app-view-loader>
      <ng-container *ngIf="!vLoader">
        <div class="container-fluid p-32">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Weekly off</p>
            <a class="link-primary fs-14 d-flex align-items-center ms-auto"
              *ngIf="(permissions?.e && viewData?.is_active)" (click)="editWeekOff(viewData?.id)">
              <i class="icon-pencil-square me-2"></i> Edit
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Weekly off details</span></div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Policy Name</div>
                    <div class="detail-desc">{{viewData?.policy_name}}</div>
                  </div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Status</div>
                    <div
                      class="fs-10 text-uppercase fw-500 {{(viewData?.is_active == true) ? 'text-success' : 'text-danger' }}">
                      {{(viewData?.is_active == true) ? 'Active' : 'Deleted' }}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-24">
                      <div class="card card-c2 p-0">
                        <div class="table-responsive radius-4">
                          <table class="table td-14 sticky-header td-white-space td-fs-14
                                            tr-fs-14  table-striped table-sm form-table">
                            <thead>
                              <tr>
                                <th class="fw-600">WEEK DAYS</th>
                                <th class="fw-600" scope="col">1ST</th>
                                <th class="fw-600" scope="col">2ND</th>
                                <th class="fw-600" scope="col">3RD</th>
                                <th class="fw-600" scope="col">4TH</th>
                                <th class="fw-600" scope="col">5TH</th>
                              </tr>
                            </thead>
                            <tbody class="cell-16 height-full">
                              <tr *ngFor="let view of viewData?.weekoff_mapping_view">
                                <td>{{view?.week_days}}</td>
                                <td>{{view['1ST']}}</td>
                                <td>{{view['2ND']}}</td>
                                <td>{{view['3RD']}}</td>
                                <td>{{view['4TH']}}</td>
                                <td>{{view['5TH']}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-24">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Applicability</span></div>
                  <div class=" col-12">
                    <div class="detail-title">Applicable to</div>
                    <div class="detail-desc">{{viewData?.applicable}}</div>
                  </div>
                  <div class=" col-12">
                    <div class="detail-title">{{viewData?.applicable}}</div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1">
                        <ng-container *ngIf="viewData?.applicable == 'Employee'">
                          <li class="ng-value" *ngFor="let rights of viewData?.applicableTo, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,2)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="viewData?.applicable == 'Company'">
                          <li class="ng-value" *ngFor="let rights of viewData?.applicableTo, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngIf="(viewData?.applicable != 'Employee') && (viewData?.applicable != 'Company')">
                          <div class="d-flex flex-wrap mt-8 gap-8">
                            <ng-container *ngFor="let rights of viewData?.applicableTo, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>

                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Delete  component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Weekly Off?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Weekly Off')}}

        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="deleteWeekOff(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
