<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Import Shift</h5>
        <button class="btn-reset text-light-600" (click)="backClicked()">
            <i class="icon-close-lg fs-16"></i></button>
    </header>
    <main class=" config-container">
        <div class="container-fluid p-32 form-container" [formGroup]="templateForm">
            <div class="row row-24">
                <div class="col-12">
                    <div class="card card-c2 p-24">
                        <div class="row row-16">
                            <div class="col-12">
                                <span class="title">SELECT DATES</span>
                            </div>
                            <div class="col-md-6">
                                <label for="" class="form-label required">From </label>
                                <div class="input-group form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': (f.fromdate.touched) && f.fromdate.errors}">
                                    <input (click)="datePicker1.open()" (dateInput)="fromDate($event)"
                                        placeholder="{{this.messageService.selectplaceholddisplay('date')}}" [min]="minDate"
                                        [max]="maxDate"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': ( f.fromdate.touched) && f.fromdate.errors}" matInput
                                        formControlName="fromdate" [matDatepicker]="datePicker1"
                                        (keydown)="disableDate()">
                                    <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                                    <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
                                </div>
                                <div *ngIf="( f.fromdate.touched) && f.fromdate.errors" class="invalid-feedback">
                                    <div *ngIf="f.fromdate.errors.required">
                                        {{this.messageService.validationDisplay('required')}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="" class="form-label  required">To </label>
                                <div class="input-group form-icon icon-right"
                                    [ngClass]="{ 'is-invalid': ( f.todate.touched) && f.todate.errors }">
                                    <input (click)="datePicker2.open()"
                                        placeholder="{{this.messageService.selectplaceholddisplay('date')}}"
                                        class="form-control" [matDatepickerFilter]="dobFilter"
                                        [ngClass]="{ 'is-invalid': ( f.todate.touched) && f.todate.errors }" matInput
                                        formControlName="todate" [min]="f.fromdate.value" [max]="maxValue"
                                        [matDatepicker]="datePicker2" (keydown)="disableDate()"><i
                                        class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                    <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                                </div>
                                <div *ngIf="(( f.todate.touched) || f.todate.touched) && f.todate.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.todate.errors">{{this.messageService.validationDisplay('required')}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 ">
                                <label for="" class="form-label required">Select filter to download employees details
                                </label>
                            </div>

                            <div class="col-12 mt-0">
                                <div class="form-check  form-check-inline">
                                    <input type="radio" id="pf1" class="form-check-input" [value]="false"
                                        formControlName="applicable" (click)="changeApplicable()">
                                    <label for="pf1" class="form-check-label">Organisation</label>
                                </div>
                                <div class="form-check   form-check-inline">
                                    <input type="radio" id="pf2" class="form-check-input" [value]="true"
                                        formControlName="applicable" (click)="changeApplicable()">
                                    <label for="pf2" class="form-check-label">Employee</label>
                                </div>
                            </div>
                            <ng-container *ngIf="f.applicable.value == false">

                                <div class="col-md-6">
                                    <label for="" class="from-label">Company </label>
                                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Company')}}"
                                        formControlName="company" [items]="companyDD" [multiple]="true"
                                        groupBy="selectedAllGroup" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" bindLabel="company_name"
                                        [closeOnSelect]="false" bindValue="id" class="multiselect"
                                        (change)="getbulist(templateForm.value.company)">
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                [ngModelOptions]="{standalone: true}" /> Select All
                                        </ng-template>

                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="templateForm.value.company?.length ">
                                                <span class="ng-value-label">{{templateForm.value.company?.length }}
                                                    Company
                                                    Selected</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="d-inline-flex align-items-center">
                                                <input id="item-{{index}}" class="me-8" type="checkbox"
                                                    [(ngModel)]="item$.selected"
                                                    [ngModelOptions]="{ standalone : true }" />
                                                <img class="avatar-sm sq-24 img-fluid rounded-circle"
                                                    *ngIf="item.logo !=  null && item.logo !=  ''" src="{{item.logo}}">
                                                <span *ngIf="item.logo ==  null || item.logo ==  ''"
                                                    class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appService.getFirstChar(item.company_name,1)}}</span></span>
                                                <span class="">{{ (item.company_name.length>15)? (item.company_name |
                                                    slice:0:15)+'..':(item.company_name) }}</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                    <ul class="selected-values mt-16 w-100" *ngIf="templateForm.value.company?.length != 0">
                                        <li class="ng-value"
                                            *ngFor="let item of templateForm.value.company,let ki =index">
                                            <ng-container *ngFor="let insideitem of companyDD, let k =index">
                                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                    <div class="d-inline-flex align-items-center">
                                                        <img class=" img-fluid sq-24 rounded-circle"
                                                            *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null"
                                                            src="{{insideitem.logo}}">
                                                        <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''"
                                                            class="  sq-24 avatar-circle rounded-circle me-1 bg{{k%5}}">
                                                            <span>
                                                                {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                                                        </span>
                                                        <span class=""> {{ (insideitem['company_name'].length>25)?
                                                            (insideitem['company_name'] |
                                                            slice:0:25)+'..':(insideitem['company_name']) }}</span>
                                                    </div>
                                                </span>
                                            </ng-container>
                                            <span *ngIf="disabled==false" class="ng-value-icon right"
                                                (click)="clearcommonForm('company',ki)" aria-hidden="true">×</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <label for="" class="from-label">Business unit</label>
                                    <ng-select [readonly]="templateForm.value.company?.length == 0 ? true : false"
                                        placeholder="{{this.messageService.selectplaceholddisplay('Business Unit')}}"
                                        formControlName="bu" [items]="buDD" [multiple]="true" groupBy="selectedAllGroup"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                                        [closeOnSelect]="false" bindValue="id" class="multiselect">
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                [ngModelOptions]="{standalone: true}" /> Select All
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="d-inline-flex align-items-center">
                                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                                                {{ item.name }}
                                            </div>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="templateForm.value.bu.length">
                                                <span class="ng-value-label">{{templateForm.value.bu.length}} Business
                                                    Unit
                                                    Selected</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                                        </ng-template>
                                    </ng-select>
                                    <ul class="selected-values mt-16 w-100" *ngIf="templateForm.value.bu?.length != 0 ">
                                        <li class="ng-value" *ngFor="let item of templateForm.value.bu,let ki =index">
                                            <ng-container *ngFor="let insideitem of buDD, let k =index">
                                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                                    slice:0:15)+'..':(insideitem['name']) }}</span>
                                            </ng-container>
                                            <span *ngIf="disabled==false" class="ng-value-icon right"
                                                (click)="clearcommonForm('bu',ki)" aria-hidden="true">×</span>
                                        </li>
                                    </ul>

                                </div>
                                <div class="col-md-6">
                                    <label for="" class="from-label">Department</label>
                                    <ng-select
                                        placeholder="{{this.messageService.selectplaceholddisplay('Department')}}"
                                        formControlName="department" [items]="DepDD" [multiple]="true"
                                        groupBy="selectedAllGroup" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                                        bindValue="id" class="multiselect">
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                [ngModelOptions]="{standalone: true}" /> Select All
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="d-inline-flex align-items-center">
                                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                                                {{ item.name }}
                                            </div>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="templateForm.value.department?.length ">
                                                <span class="ng-value-label">{{templateForm.value.department?.length}}
                                                    Department
                                                    Selected</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                                        </ng-template>
                                    </ng-select>
                                    <ul class="selected-values mt-16 w-100"
                                        *ngIf="templateForm.value.department?.length != 0">
                                        <li class="ng-value"
                                            *ngFor="let item of templateForm.value.department,let ki =index">
                                            <ng-container *ngFor="let insideitem of DepDD, let k =index">
                                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                                    slice:0:15)+'..':(insideitem['name']) }}</span>
                                            </ng-container>
                                            <span *ngIf="disabled==false" class="ng-value-icon right"
                                                (click)="clearcommonForm('department',ki)" aria-hidden="true">×</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <label for="" class="from-label">Designation</label>
                                    <ng-select
                                        placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}"
                                        formControlName="designation" [items]="DesignationDD" [multiple]="true"
                                        groupBy="selectedAllGroup" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                                        bindValue="id" class="multiselect">
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                [ngModelOptions]="{standalone: true}" /> Select All
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="d-inline-flex align-items-center">
                                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                                                {{ item.name }}
                                            </div>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="templateForm.value.designation?.length">
                                                <span class="ng-value-label">{{templateForm.value.designation?.length}}
                                                    Designation
                                                    Selected</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                                        </ng-template>
                                    </ng-select>
                                    <ul class="selected-values mt-16 w-100"
                                        *ngIf="templateForm.value.designation?.length != 0">
                                        <li class="ng-value"
                                            *ngFor="let item of templateForm.value.designation,let ki =index">
                                            <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                                    slice:0:15)+'..':(insideitem['name']) }}</span>
                                            </ng-container>
                                            <span *ngIf="disabled==false" class="ng-value-icon right"
                                                (click)="clearcommonForm('designation',ki)" aria-hidden="true">×</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <label for="" class="from-label">Branch</label>
                                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}"
                                        formControlName="branch" [items]="BranchDD" [multiple]="true"
                                        groupBy="selectedAllGroup" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                                        bindValue="id" class="multiselect">
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                [ngModelOptions]="{standalone: true}" /> Select All
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="d-inline-flex align-items-center">
                                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                                                {{ item.name }}
                                            </div>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="templateForm.value.branch?.length ">
                                                <span class="ng-value-label">{{templateForm.value.branch?.length }}
                                                    Branch
                                                    Selected.</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                                        </ng-template>
                                    </ng-select>
                                    <ul class="selected-values mt-16 w-100" *ngIf="templateForm.value.branch?.length != 0">
                                        <li class="ng-value"
                                            *ngFor="let item of templateForm.value.branch,let ki =index">
                                            <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                                    slice:0:15)+'..':(insideitem['name']) }}</span>
                                            </ng-container>
                                            <span *ngIf="disabled==false" class="ng-value-icon right"
                                                (click)="clearcommonForm('branch',ki)" aria-hidden="true">×</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <label for="" class="from-label">Grade</label>
                                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Grade')}}"
                                        formControlName="grade" [items]="gradeDD" [multiple]="true"
                                        groupBy="selectedAllGroup" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                                        bindValue="id" class="multiselect">
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                [ngModelOptions]="{standalone: true}" /> Select All
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="d-inline-flex align-items-center">
                                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                                                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                                            </div>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="templateForm.value.grade?.length ">
                                                <span class="ng-value-label">{{templateForm.value?.grade?.length }}
                                                    Grade
                                                    Selected.</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                    <ul class="selected-values mt-16 w-100" *ngIf="templateForm.value.grade?.length != 0">
                                        <li class="ng-value"
                                            *ngFor="let item of templateForm.value.grade,let ki =index">
                                            <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                                    slice:0:15)+'..':(insideitem['name']) }}</span>
                                            </ng-container>
                                            <span *ngIf="disabled==false" class="ng-value-icon right"
                                                (click)="clearcommonForm('grade',ki)" aria-hidden="true">×</span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="f.applicable.value == true">
                                <div class="col-md-6">
                                    <label for="" class="from-label">Employee </label>
                                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('employee')}}"
                                        formControlName="employee" [items]="employeeData" [multiple]="true" [ngClass]="{ 'is-invalid': (f.employee?.touched || f.employee.errors?.relievedError) && f.employee.errors}"
                                        groupBy="selectedAllGroup" [selectableGroup]="true" (change)="ngChange($event)"
                                        [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                                        bindValue="id" class="multiselect">
                                        <!-- <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                                [ngModelOptions]="{standalone: true}" /> Select All
                                        </ng-template> -->
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="templateForm.value.employee?.length">
                                                <span class="ng-value-label">{{templateForm.value.employee?.length}}
                                                    Employee
                                                    selected.</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8"
                                                    type="checkbox" [(ngModel)]="item$.selected"
                                                    [ngModelOptions]="{ standalone : true }" /><img
                                                    class="avatar-sm sq-24 img-fluid rounded-circle"
                                                    *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                                    src="{{item.profile_image}}">
                                                <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                                    class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>
                                                <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name |
                                                    slice:0:30)+'..':(item.name) }}</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                    <div *ngIf="(f?.employee?.touched || f.employee.errors?.relievedError) && f.employee.errors" class="invalid-feedback">
                                      <div *ngIf="f.employee.errors?.relievedError">{{this.messageService.inactiveStatus()}}</div>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="templateForm.value.employee?.length !=0">
                                  <ul class="selected-values" >
                                    <li class="ng-value" [ngClass]="{'ng-value-danger': isInsideItemIdFive(item)}"
                                        *ngFor="let item of templateForm.value.employee, let m =index">
                                        <ng-container *ngFor="let insideitem of employeeData, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                <div class="d-inline-flex align-items-center">
                                                    <img class="avatar-sm sq-24 img-fluid rounded-circle"
                                                        *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                                        src="{{insideitem.profile_image}}">
                                                    <span
                                                        *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                                        class="avatar-sm sq-24 rounded-circle {{insideitem.color_code}}">
                                                        <span>
                                                            {{this.appService.getFirstChar(insideitem.first_name+"
                                                            "+insideitem.last_name,2)}}</span>
                                                    </span>
                                                    <span class="ms-1">
                                                        {{insideitem.first_name+"
                                                        "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                                        ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                                    </span>
                                                    <span *ngIf="insideitem?.employment_status == 'Relieved' || insideitem?.employment_status == 'Settled' || insideitem?.employment_status == 'Terminated' || insideitem?.employment_status == 'Retired'" class="lh-1 ms-8"><i class="bi bi-info-circle fs-14 text-danger tooltip-icon" ngbPopover="Employee status : Inactive" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                                </div>
                                            </span>
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" aria-hidden="true"
                                            (click)="clearcommonForm('employee',m)"><i
                                                class="icon-close"></i></span>
                                    </li>
                                </ul>
                                </div>

                            </ng-container>
                            <div class="col-12"><button class="btn btn-outline-primary px-5 radius-4"
                                    [disabled]="templateForm.invalid || checkcommonFormValid()" (click)="generateTemplate();">
                                    <div class=" d-flex flex-center  w-100 text-center ">
                                        <div class="">{{(downloadLoader==false)?'Download Template':'Loading'}} </div>
                                        <div class="ms-4 dot-pulse" *ngIf="downloadLoader"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="card card-c2 p-24">
                        <div class="row row-16">
                            <div class="col-12">
                                <span class="title">Import shift</span>
                            </div>
                            <ng-container *ngIf="fileName">
                                <div class="col-md-6">


                                    <div class="card card-c2  bg-secondary" style="width:20rem;">
                                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                          <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                                          <span class="tips-helper ">{{fileName}}</span>

                                        </div>
                                        <i class="icon-trash  ms-auto flex-center link-danger" (click)="clearData()"></i>
                                      </div>
                                    </div>


                                    <!-- <div class="card card-c2 bg-secondary">
                                        <div class="d-flex p-24 ">
                                            <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                            <span class="text-trim fw-500">{{fileName}}</span>
                                            <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                                            <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()"><i
                                                    class="bi bi-trash ms-8 "></i></span>
                                        </div>
                                    </div> -->
                                </div>
                            </ng-container>
                            <div class="col-12">
                              <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                               class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                              <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                                  (change)="onFileChange($event)" id="upload" hidden>
                              Select File to Upload
                              </label>
                              <span class="invalid-feedback" *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="card card-c2 p-24">
                        <div class="row row-16">
                            <div class="col-12">
                                <span class="title">Instructions</span>
                            </div>

                            <div class="col-12 ">
                                <ol class="fs-14 ps-16 mb-0 li-24">
                                    <li>Click on "Download template" button to download the excel file.</li>
                                    <li>Enter or update the shift details as per the format provided in the template.
                                    </li>
                                    <li>Save the file.</li>
                                    <li>Click on the "Select file to upload" button and select the file.</li>
                                    <li>Click on "upload".</li>

                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <footer class="submit-footer justify-content-between  gap-16">
            <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
                (click)="uploadShift()">Upload</button>

        </footer>
    </main>
</div>
<!-- Delete popup -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Error Occured</h4>
            </div>
            <div class="modal-body">
                <p class="mb-0">{{deleteMsg}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm"
                    (click)="deleteToggle = false;">Close</button>
            </div>
        </div>
    </div>
</div>



<app-upload-progress-bar  *ngIf="showProgress && !this.empShift.BGPROGRESS" [progress]="progPercentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Assign Shift'" [showClose]="isProgressStart" [timeLeft]="timeLeft" [body]="body" (closePanel)="closeProgress()" [processingText]='"Proccessing...."' ></app-upload-progress-bar>

<app-error-download  *ngIf="errorLog && !this.empShift.RUNBG" [fileName]="'Import Shift Error'" [successDataCount]="successDataCount" [downloadMsg]="errorLog" [failedData]="exportData" [modalHeading]="'Import Shift'" (closePanel)="backClicked();errorLog =false" [successMsg]="successMsg" [errorMsg]="errorMsgA">  </app-error-download>
