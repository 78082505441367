<div class="container-fluid p-32 form-container" [formGroup]="formGp">
  <app-edit-loader *ngIf="loader1"></app-edit-loader>
  <ng-container *ngIf="!loader1">
    <div class="row row-16">
      <div class="col-12">
        <div class="card details-card indicater1 px-16 py-12">
          <div class="row">
            <div class="col-5">
              <div class="fw-600 fs-14">HRA/80GG</div>
            </div>
            <div class="col">
              <div class="detail-title">Total amount declared</div>
              <div class="detail-desc">{{totalDeclaredAmountFunction() | currency: currency}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div  role="alert" class="alert alert-warning mb-0 {{alertShow}}">
          <p class="mb-0">Select the checkbox below to fill details or skip the step by clicking on skip button.
            HRA/80GG, whichever option is on your salary structure, will be displayed here.
          </p>
          <div class="btn-close" (click)="alertShow='hide'"></div>
        </div>
      </div>
      <div class="col-6" *ngIf="hraValue == 1">
        <label for="test" class="card card-c2 p-16">
          <div class="form-check checkbox-24">
            <input type="checkbox" id="test" name="hra_check" class="form-check-input" (change)="checkBoxFunctionTest($event)">
            <label  for="test" class="form-check-label fw-600">HRA</label>
        </div>
        <div class="fs-14 ps-36 pt-8">HRA is a salary component paid by the employer to the employee who reside in rental accommodation. Employee can claim tax benefits on the amount paid as rent for accommodation each year. </div>

        </label>
      </div>
      <div class="col-6" *ngIf="hraValue == 0">
        <label for="test1" class="card card-c2 p-16">
          <div class="form-check checkbox-24">
            <input type="checkbox" id="test1"  name="hra_check" class="form-check-input" (change)="checkBoxFunctionTest($event)">
            <label  for="test1" class="form-check-label fw-600">80GG</label>
        </div>
        <div class="fs-14 ps-36 pt-8">80GG is a tax benefit offered by the Income Tax Act, 1961 for the employees who do not receive any HRA benefits. The maximum tax exemption on 80GG is upto ₹ 60000 per annum.</div>

        </label>
      </div>
      <div class="col-lg-12 form-row " style="display:none;">
        <ng-container *ngIf="hraValue == 1">
          <div class="form-check form-check-inline">
            <input [value]="true" type="radio" id="HRA1" class="form-check-input  " [checked]="isChecked">
            <label for="HRA1" class="form-check-label"> HRA </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                ngbPopover="HRA is a part of your salary provided by the employer for the expenses incurred towards rented accommodation. You can claim HRA exemption only if you are residing in a rented house."
                popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
        </ng-container>
        <ng-container *ngIf="hraValue == 0">
          <div class="form-check form-check-inline">
            <input [value]="false" type="radio" id="HRA2" class="form-check-input " [checked]="!isChecked">
            <label for="HRA2" class="form-check-label"> 80GG </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                ngbPopover="80GG is a tax benefit offered by the Income Tax Act, 1961 for the employees who do not receive any HRA benefits."
                popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="hraValue == 1">
      <div class="col-12" *ngIf="hra80GGCheckBox">
        <div class="fw-500 mb-8">HRA</div>
        <div class="card card-c2">
          <div class="card-body p-24">
            <ng-container formArrayName="hra80gg" *ngFor="let item of hra80ggs().controls; let i=index; ">
              <ng-container [formGroupName]="i">
                <div class="col-12 d-flex mt-16">
                  <span class="fw-500">Property {{(i+1)}}</span>
                  <a *ngIf="i!=0" class="ms-auto text-light-600" (click)="removeHra80gg(i)"><i class="icon-trash"></i></a>
                </div>
                <div class="row row-16">
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label required">From </label>
                    <!-- <app-master-select class="config-app-master"
                      [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('from_month')?.dirty || hra80ggs().controls[i].get('from_month')?.touched) &&  hra80ggs().controls[i].get('from_month')?.errors}"
                      [placeholder]="monthDropdownPlaceHolder" id="from_month" [submitted]="submitted"
                      [isrequired]="isrequired" [selectedItem]="hra80ggs().controls[i].get('from_month')?.value"
                      formControlName="from_month" [type]="listOfMonth" ></app-master-select>

                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('from_month')?.dirty || hra80ggs().controls[i].get('from_month')?.touched) && hra80ggs().controls[i].get('from_month')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('from_month')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    </div> -->

                    <!-- New updation -->
                    <div class=" col ">
                      <input type="hidden" class="form-control" formControlName="from_year">
                      <input type="hidden" class="form-control" formControlName="from_month" >
                      <div ngbDropdown  [ngClass]="{ 'is-invalid': hra80ggs().controls[i].get('from_month')?.errors}" class="input-dropdown four-dropdown " container="body">
                        <button type="button" [ngClass]="{ 'is-invalid': hra80ggs().controls[i].get('from_month')?.errors}" class="card card-c2  fs-14" ngbDropdownToggle>
                            <span class="">{{hra80ggs().controls[i].get('from_month')?.value}} {{hra80ggs().controls[i].get('from_year')?.value}}</span>
                        </button>
                        <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                        <div class="row">
                          <div class="col-5">
                            <label for="" class="form-label">Year </label>
                            <ng-select class="form-ngselect fs-14" placeholder="Select year" [ngModelOptions]="{standalone: true}" [ngModel]="hra80ggs().controls[i].get('from_year')?.value" (change)="changeFromMonthCarousel($event,'year',i)">
                                <ng-option *ngFor="let yData of fyArray" [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                          <div class="col-7">
                            <label for="" class="form-label">Month </label>
                            <ng-select class="form-ngselect  fs-14"  placeholder="Select month"  [ngModelOptions]="{standalone: true}" [ngModel]="hra80ggs().controls[i].get('from_month')?.value" (change)="changeFromMonthCarousel($event,'month',i)">
                                <ng-option *ngFor="let yData of fromMonths[i]"  [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div *ngIf="hra80ggs().controls[i].get('from_month')?.errors" class="invalid-feedback">
                        <div *ngIf="hra80ggs().controls[i].get('from_month')?.errors">{{this.messageService.greaterThanMessage('From month','To month')}}</div>
                      </div>
                    </div>
                    <!-- End -->
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="" class="form-label required">To </label>
                    <!-- <app-master-select class="config-app-master"
                      [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('to_month')?.dirty || hra80ggs().controls[i].get('to_month')?.touched) &&  hra80ggs().controls[i].get('to_month')?.errors}"
                      [placeholder]="monthDropdownPlaceHolder" id="to_month" [submitted]="submitted"
                      [isrequired]="isrequired" [selectedItem]="hra80ggs().controls[i].get('to_month')?.value"
                      formControlName="to_month" [type]="listOfMonth"></app-master-select>

                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('to_month')?.dirty || hra80ggs().controls[i].get('to_month')?.touched) && hra80ggs().controls[i].get('to_month')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('to_month')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    </div> -->

                      <!-- New updation -->
                      <div class=" col ">
                        <input type="hidden" class="form-control" formControlName="to_year">
                        <input type="hidden" class="form-control" formControlName="to_month" >
                        <div ngbDropdown  [ngClass]="{ 'is-invalid': hra80ggs().controls[i].get('to_month')?.errors}" class="input-dropdown four-dropdown " container="body">
                          <button type="button" [ngClass]="{ 'is-invalid': hra80ggs().controls[i].get('to_month')?.errors}" class="card card-c2  fs-14" ngbDropdownToggle>
                              <span class="">{{hra80ggs().controls[i].get('to_month')?.value}} {{hra80ggs().controls[i].get('to_year')?.value}}</span>
                          </button>
                          <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                          <div class="row">
                            <div class="col-5">
                              <label for="" class="form-label">Year </label>
                              <ng-select class="form-ngselect fs-14" placeholder="Select year" [ngModelOptions]="{standalone: true}" [ngModel]="hra80ggs().controls[i].get('to_year')?.value" (change)="changeToMonthCarousel($event,'year',i)">
                                  <ng-option *ngFor="let yData of fyArray" [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                            </div>
                            <div class="col-7">
                              <label for="" class="form-label">Month </label>
                              <ng-select class="form-ngselect  fs-14"  placeholder="Select month"  [ngModelOptions]="{standalone: true}" [ngModel]="hra80ggs().controls[i].get('to_month')?.value" (change)="changeToMonthCarousel($event,'month',i)">
                                  <ng-option *ngFor="let yData of toMonths[i]"  [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div *ngIf="hra80ggs().controls[i].get('to_month')?.errors" class="invalid-feedback">
                          <div *ngIf="hra80ggs().controls[i].get('to_month')?.errors">{{this.messageService.greaterThanMessage('From month','To month')}}</div>
                        </div>
                      </div>
                      <!-- End -->
                  </div>
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label required">Monthly amount</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the rent amount paid every month." popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                    <div class="form-icon icon-right"
                      [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('amount')?.dirty || hra80ggs().controls[i].get('amount')?.touched) &&  hra80ggs().controls[i].get('amount')?.errors}">
                      <input
                        [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('amount')?.dirty || hra80ggs().controls[i].get('amount')?.touched) &&  hra80ggs().controls[i].get('amount')?.errors}"
                        type="text" class="form-control" autocomplete="off" id="amount"
                        placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="amount"
                        (keyup)="validPan(i)">
                      <span class="fs-14 text-light-500">{{currency}}</span>
                    </div>

                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('amount')?.dirty || hra80ggs().controls[i].get('amount')?.touched) && hra80ggs().controls[i].get('amount')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('amount')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                      <div
                        *ngIf="!(hra80ggs().controls[i].get('amount')?.errors?.pattern) && hra80ggs().controls[i].get('amount')?.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                      <div *ngIf="hra80ggs().controls[i].get('amount')?.errors?.pattern">
                        {{this.messageService.validationDisplay('amount')}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 form-row">
                    <label for="" class="form-label required">City </label>

                    <app-master-select class="config-app-master"
                    [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('city')?.dirty || hra80ggs().controls[i].get('city')?.touched) &&  hra80ggs().controls[i].get('city')?.errors}"
                    [placeholder]="cityDropdownPlaceHolder" id="city" [submitted]="submitted"
                    [isrequired]="isrequired" [selectedItem]="hra80ggs().controls[i].get('city')?.value"
                    formControlName="city" [type]="metro_nonmetro_city"></app-master-select>
                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('city')?.dirty || hra80ggs().controls[i].get('city')?.touched) && hra80ggs().controls[i].get('city')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('city')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                </div>
                <p class="text-uppercase fw-500 pt-16">Landlord's details</p>
                <div class="row row-16">
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label required">Landlord's name</label>
                    <input type="text" class="form-control" autocomplete="off" id="landlord_name"
                      placeholder="{{this.messageService.placeholderdisp('name')}}" formControlName="landlord_name"
                      [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('landlord_name')?.dirty || hra80ggs().controls[i].get('landlord_name')?.touched) &&  hra80ggs().controls[i].get('landlord_name')?.errors}">
                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('landlord_name')?.dirty || hra80ggs().controls[i].get('landlord_name')?.touched) && hra80ggs().controls[i].get('landlord_name')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('landlord_name')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                      <div
                        *ngIf="!(hra80ggs().controls[i].get('landlord_name')?.errors?.pattern) && hra80ggs().controls[i].get('landlord_name')?.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                      <div *ngIf="hra80ggs().controls[i].get('landlord_name')?.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="" class="form-label {{pan_required == true ? 'required' : ''}}">Landlord's pan</label>
                    <!-- <label for="" class="form-label required">Landlord's PAN</label> -->
                    <input type="text" class="form-control" autocomplete="off" id="landlord_pan"
                      placeholder="{{this.messageService.placeholderdisp('pan')}}" formControlName="landlord_pan"
                      [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('landlord_pan')?.dirty || hra80ggs().controls[i].get('landlord_pan')?.touched) &&  hra80ggs().controls[i].get('landlord_pan')?.errors}">
                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('landlord_pan')?.dirty || hra80ggs().controls[i].get('landlord_pan')?.touched) && hra80ggs().controls[i].get('landlord_pan')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('landlord_pan')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="hra80ggs().controls[i].get('landlord_pan')?.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                    </div>
                  </div>
                  <div class=" col-md-12 form-row">
                    <label for="" class="form-label required">Landlord's address</label>
                    <textarea type="text" rows="1" class="form-control h-40" autocomplete="off" id="landlord_address"
                      placeholder="{{this.messageService.placeholderdisp('address')}}"
                      formControlName="landlord_address"
                      [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('landlord_address')?.dirty || hra80ggs().controls[i].get('landlord_address')?.touched) &&  hra80ggs().controls[i].get('landlord_address')?.errors}"></textarea>
                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('landlord_address')?.dirty || hra80ggs().controls[i].get('landlord_address')?.touched) && hra80ggs().controls[i].get('landlord_address')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('landlord_address')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                      <div
                        *ngIf="!(hra80ggs().controls[i].get('landlord_address')?.errors?.pattern) && hra80ggs().controls[i].get('landlord_address')?.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','500')}}</div>

                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="row mt-16">
              <div class="col-12">
                <button class="btn btn-outline-primary" (click)="addHra80gg();fromMonthArrayCreation();
                toMonthArrayCreation();">Add More</button>
              </div>

            </div>
          </div>
        </div>
      </div>
      </ng-container>
      <ng-container *ngIf="hraValue == 0">
      <div class="col-12" *ngIf="hra80GGCheckBox">
        <div class="fw-500 mb-8">80GG</div>
        <div class="card card-c2">
          <div class="card-body p-24">
            <div class="row row-16">
              <ng-container formArrayName="hra80gg" *ngFor="let item of hra80ggs().controls; let i=index; ">
                <ng-container [formGroupName]="i">
                  <div class=" col-md-12 form-row">
                    <label for="" class="form-label required">Amount</label>
                    <div class="form-icon icon-right"
                      [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('amount')?.dirty || hra80ggs().controls[i].get('amount')?.touched) &&  hra80ggs().controls[i].get('amount')?.errors}">
                      <input
                        [ngClass]="{ 'is-invalid': (submitted || hra80ggs().controls[i].get('amount')?.dirty || hra80ggs().controls[i].get('amount')?.touched) &&  hra80ggs().controls[i].get('amount')?.errors}"
                        type="text" class="form-control" autocomplete="off" id="amount"
                        placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="amount"
                        >
                      <span class="fs-14 text-light-500">{{currency}}</span>
                    </div>
                    <div class="fs-12"><span class="text-light-500">Max Limit :</span><span class="fw-500">
                        {{limitData.hra80gg}}</span>
                    </div>
                    <div
                      *ngIf="(submitted || hra80ggs().controls[i].get('amount')?.dirty || hra80ggs().controls[i].get('amount')?.touched) && hra80ggs().controls[i].get('amount')?.errors"
                      class="invalid-feedback">
                      <div *ngIf="hra80ggs().controls[i].get('amount')?.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                      <div
                        *ngIf="!(hra80ggs().controls[i].get('amount')?.errors?.pattern) && hra80ggs().controls[i].get('amount')?.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                      <div *ngIf="hra80ggs().controls[i].get('amount')?.errors?.pattern">
                        {{this.messageService.validationDisplay('amount')}}</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
    </div>

  </ng-container>
</div>
<!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
<footer class="submit-footer gap-16">
  <button class="btn btn-outline-primary btn-sm ms-auto text-uppercase"
  (click)="validateHra80gg('skip')">SKIP</button>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="!formGp.valid"
    (click)="validateHra80gg('next')">NEXT</button>
</footer>
