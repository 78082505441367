<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='investmentDeclerationView === true'>
  <input #myInput type='hidden' id='iFrameDrivenImageValue' value='n/a'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5> {{investmentSingleData.employee}} - {{viewHeader}}</h5>
      <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">

        <app-view-loader *ngIf="loader1"></app-view-loader>

        <div class="row row-24" *ngIf="!loader1">
          <div class="col-12">
            <div class="card details-card shadow-0 p-24">
              <div class="row row-16 " *ngIf="hra80ggSingleData.select_one">
                <div class="col-12 ">
                  <span class="title">HRA</span>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">From</div>
                  <div class="detail-desc">{{hra80ggSingleData.from_month}}</div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">To</div>
                  <div class="detail-desc">{{hra80ggSingleData.to_month}}</div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Amount</div>
                  <div class="detail-desc">{{hra80ggSingleData.amount | currency:currency}}</div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">City</div>
                  <div class="detail-desc">{{cityName}}</div>
                </div>
                <div class="col-12">
                  <hr class="hr-1">
                </div>
                <div class="col-12 ">
                  <span class="fs-14">Landlord’s details</span>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Landlord’s Name</div>
                  <div class="detail-desc"> {{hra80ggSingleData.landlord_name}}</div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12" *ngIf="hra80ggSingleData.landlord_pan!=''">
                  <div class="detail-title">Landlord’s PAN</div>
                  <div class="detail-desc">{{hra80ggSingleData.landlord_pan}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Landlord’s Address</div>
                  <div class="detail-desc">{{hra80ggSingleData.landlord_address}}</div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6 col-8" *ngIf="investmentSingleData.proposed_confirm">

                  <div class="p-12 bg-secondary radius-8 d-flex">
                    <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                    <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                      <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(hra80ggSingleData.landlord_document) }}</span>
                      <span class="tips-helper ">{{ getDocName(hra80ggSingleData.landlord_document) }}</span>
                        <div class="link-primary" (click)="viewDocument(hra80ggSingleData.landlord_document)">
                          <i class="bi bi-eye me-8"></i>View
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-16 " *ngIf="!hra80ggSingleData.select_one">
                <div class="col-12 ">
                  <span class="title">80GG</span>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Amount</div>
                  <div class="detail-desc">{{hra80ggSingleData.amount | currency:currency}}</div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6 col-8" *ngIf="investmentSingleData.proposed_confirm">

                  <div class="p-12 bg-secondary radius-8 d-flex">
                    <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                    <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                      <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(hra80ggSingleData.landlord_document) }}</span>
                      <span class="tips-helper ">{{ getDocName(hra80ggSingleData.landlord_document) }}</span>
                      <div class="link-primary" (click)="viewDocument(hra80ggSingleData.landlord_document)">
                        <i class="bi bi-eye me-8"></i>View
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container  *ngIf="cahpt80CSingleData != null">
            <div class="col-12">
              <div class="card details-card shadow-0 p-24 ">
                <div class="row row-16 ">
                  <div class="col-12 ">
                    <span class="title">Chapter iV A - 80 c</span><br>

                  </div>
                  <!-- <ng-container *ngIf="cahpt80CSingleData.employee_contribute_pf"> -->
                    <div class="col-12" >
                      <div class="fs-12 fw-500">Employee Contribution towards PF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.employee_contribute_pf==true?(cahpt80CSingleData.employee_contribute_pf_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.contribution_vpf"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Contribution towards VPF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.contribution_vpf==true?(cahpt80CSingleData.contribution_vpf_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container *ngIf="cahpt80CSingleData.contribution_15y_ppf"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Contribution towards 15 Years PPF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.contribution_15y_ppf==true?(cahpt80CSingleData.ppf_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.ppf_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.ppf_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.ppf_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ppf_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.ppf_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.previous_employer_pf"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Previous Employer PF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.previous_employer_pf==true?(cahpt80CSingleData.previous_employer_pf_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container *ngIf="cahpt80CSingleData.five_year_POTD"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Five-year Post Office Time Deposit (POTD) Scheme
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.five_year_POTD==true?(cahpt80CSingleData.POTD_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.POTD_document != ''">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.POTD_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.POTD_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.POTD_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.POTD_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="cahpt80CSingleData.subscribe_national_saving"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Subscription to National Saving Scheme (NSC)
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.subscribe_national_saving==true?(cahpt80CSingleData.nsc_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.nsc_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.nsc_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.nsc_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.nsc_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.nsc_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.deduction_contribute_NPS"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Deduction in respect of contribution to NPS (80 CCD(1))
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.deduction_contribute_NPS==true?(cahpt80CSingleData.nps_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.nps_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.nps_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.nps_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.nps_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.nps_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.equity_linked_saving"> -->
                  <div class="col-12">
                    <div class="fs-12 fw-500">Equity Linked Saving Schemes (Tax Saving Mutual Funds) ELSS
                    </div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{cahpt80CSingleData.equity_linked_saving==true?(cahpt80CSingleData.ELSS_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="cahpt80CSingleData.ELSS_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.ELSS_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.ELSS_document) }}</span>
                            <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ELSS_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="cahpt80CSingleData.ELSS_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.ULIP"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">ULIP
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.ULIP==true?(cahpt80CSingleData.ULIP_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.ULIP_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.ULIP_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.ULIP_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ULIP_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.ULIP_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.interest_NSC"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Interest on National Saving Certificate
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.interest_NSC==true?(cahpt80CSingleData.interest_NSC_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.interest_nsc_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.interest_nsc_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.interest_nsc_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.interest_nsc_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.interest_nsc_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.child_education_tuition_fees"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Children Education Tuition Fees
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.child_education_tuition_fees==true?(cahpt80CSingleData.tuition_fees| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.tuition_fees_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.tuition_fees_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.tuition_fees_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.tuition_fees_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.tuition_fees_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.repay_house_principal"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Repayment of Housing Principal
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.repay_house_principal==true?(cahpt80CSingleData.repay_house_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.tuition_fees_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.repay_house_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.repay_house_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.repay_house_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.tuition_fees_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.stampduty_purchase_house"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Stamp Duty paid for Purchase of House property
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.stampduty_purchase_house==true?(cahpt80CSingleData.stampduty_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.stampduty_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.stampduty_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.stampduty_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.stampduty_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.stampduty_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->

                  <!-- <ng-container  *ngIf="cahpt80CSingleData.saukanya_samridhi_yojana"> -->
                    <div class="col-12">
                      <div class="fs-12 fw-500">Sukanya samriddhi yojana
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData.saukanya_samridhi_yojana==true?(cahpt80CSingleData.ssy_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.ssy_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(cahpt80CSingleData.ssy_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(cahpt80CSingleData.ssy_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ssy_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.ssy_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->


                </div>
              </div>
            </div>
          </ng-container>
          <ng-container  *ngIf="diductionSingleData != null">

            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-16 ">
                  <div class="col-12 ">
                    <span class="title">Chapter iV A - DEDUCTIONS</span><br>

                  </div>
                  <div class="col-12 ">
                  <div class="fw-400 fs-14 mt-8">{{diductionSingleData.premium80D == true ? "80D: Premium paid by the employer, deducted from salary / 80D: Self paid premium" : "80D : Preventive medical insurance"}}
                  </div>
                </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{diductionSingleData.amount80D| currency:currency}}</div>
                  </div>
                  <div class="col-12" *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                      <div class="p-12 bg-secondary radius-8 d-flex">
                        <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                        <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                          <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.document80D) }}</span>
                          <span class="tips-helper ">{{ getDocName(diductionSingleData.document80D) }}</span>
                          <div class="link-primary" (click)="viewDocument(diductionSingleData.document80D)">
                            <i class="bi bi-eye me-8"></i>View
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <!-- <ng-container  *ngIf="diductionSingleData.employee_additional_contribution"> -->
                    <div class="fw-400 fs-14 mt-8">80CCD1(B): Employee's additional contribution to NPS
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.employee_additional_contribution==true?(diductionSingleData.employee_contribution_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.employee_contribution_document != ''">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.employee_contribution_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.employee_contribution_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.employee_contribution_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.employee_contribution_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.employer_contribution"> -->
                    <div class="fw-400 fs-14 mt-8">80CCD(2): Employer's contribution to NPS account, deducted from salary
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.employer_contribution==true?(diductionSingleData.employer_contribution_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.employer_contribution_document != ''">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.employer_contribution_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.employer_contribution_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.employer_contribution_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.employee_contribution_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.tta_ttb"> -->
                    <div class="fw-400 fs-14 mt-8">80TTA: Exemption of interest from banks, post office, etc. (or)  80TTB: Exemption of interest from banks, post office, etc.
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.tta_ttb==true?(diductionSingleData.tta_ttb_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.tta_tta_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.tta_tta_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.tta_tta_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.tta_tta_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.tta_tta_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.interest_education_loan"> -->
                    <div class="fw-400 fs-14 mt-8">80E: Interest on education loan
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.interest_education_loan==true?(diductionSingleData.interest_education_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.interest_education_document != ''">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.interest_education_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.interest_education_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.interest_education_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.interest_education_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.interest_home_loan"> -->
                    <div class="fw-400 fs-14 mt-8">80EE: Interest on home loan for first-time homeowners
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.interest_home_loan==true?(diductionSingleData.interest_home_loan_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.medical_treatment_handicapped"> -->
                    <div class="fw-400 fs-14 mt-8">80 DD: Medical treatment for handicapped dependent
                    </div>
                    <div class="fw-500 fs-12 mt-8">{{diductionSingleData.disabled_severely == true ? "Disabled" : "Severly disabled"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.medical_treatment_handicapped==true?(diductionSingleData.handicapped_dependent_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.handicapped_dependent_document != '' " >
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.handicapped_dependent_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.handicapped_dependent_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.handicapped_dependent_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.handicapped_dependent_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.medical_treatment_handicapped"> -->
                    <div class="fw-400 fs-14 mt-8">80DDB: Medical Expenditure as per rule 11DD
                    </div>
                    <div class="fw-500 fs-12 mt-8">{{diductionSingleData.disabled_severely == true ? "Upto 60 years" : "More than 60 years"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.medical_treatment_handicapped==true?(diductionSingleData.medical_expenditure_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Issue</div>
                      <div class="detail-desc">{{diductionSingleData.medical_treatment_handicapped==true?diductionSingleData.issue:'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.medical_expenditure_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.medical_expenditure_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.medical_expenditure_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.medical_expenditure_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.medical_expenditure_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.self_suffering_disability"> -->
                    <div class="fw-400 fs-14 mt-8">80U: Self-suffering from disability
                    </div>
                    <div class="fw-500 fs-12 mt-8">{{diductionSingleData.self_disabled_severely == true ? "Disabled" : "Severly disabled"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.self_suffering_disability==true?(diductionSingleData.self_suffering_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.self_suffering_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.self_suffering_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.self_suffering_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.self_suffering_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.self_suffering_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.donate_social_cause"> -->
                    <div class="fw-400 fs-14 mt-8">80G: Deduction for donations towards social causes
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.donate_social_cause==true?(diductionSingleData.donate_social_cause_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="fw-500 fs-12 mt-8">{{diductionSingleData.cash_other == true ? "Cash" : "Other"}}
                    </div>


                    <ng-container *ngIf="!diductionSingleData.cash_other">
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-title">Fund Type</div>
                        <div class="detail-desc">{{diductionSingleData.fund_type!=null ?diductionSingleData.fund_type:'-'}}</div>
                      </div>
                      <ng-container *ngFor="let donate of diductionSingleData.donate_80g,let i = index">
                        <div class="fw-500 fs-12 mt-8">Transaction details {{i + 1}}
                        </div>
                        <div class="col-lg-3  col-sm-6 col-12">
                          <div class="detail-title">Name</div>
                          <div class="detail-desc"> {{donate.name}}</div>
                        </div>
                        <div class="col-lg-3  col-sm-6 col-12">
                          <div class="detail-title">PAN</div>
                          <div class="detail-desc">{{donate.pan}}</div>
                        </div>
                        <div class="col-12">
                          <div class="detail-title">Address</div>
                          <div class="detail-desc">{{donate.address}}</div>
                        </div>
                        <div class="col-lg-3  col-sm-6 col-12">
                          <div class="detail-title">Payment Mode</div>
                          <div class="detail-desc"> {{donate.payment_mode}}</div>
                        </div>
                        <div class="col-lg-3  col-sm-6 col-12">
                          <div class="detail-title">Cheque No./Transaction ID</div>
                          <div class="detail-desc">{{donate.check_no_transaction_id}}</div>
                        </div>
                        <div class="col-lg-3  col-sm-6 col-12">
                          <div class="detail-title">Payment Date</div>
                          <div class="detail-desc">{{this.appservice.dateFormatDisplay(donate.payment_date)}}</div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.transaction_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.transaction_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.transaction_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.transaction_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.transaction_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.royalty_patent"> -->
                    <div class="fw-400 fs-14 mt-8">80RRB: Deductions on Income by way of Royalty of a Patent
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.royalty_patent==true?(diductionSingleData.royalty_patent_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.royalty_patent_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.royalty_patent_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.royalty_patent_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.royalty_patent_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.royalty_patent_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.contribution_individual_policalparties"> -->
                    <div class="fw-400 fs-14 mt-8">80GGC: Contribution by individuals to political parties
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.contribution_individual_policalparties==true?(diductionSingleData.contribution_policalparties_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12"  *ngIf="diductionSingleData.contribution_policalparties_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.contribution_policalparties_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.contribution_policalparties_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.contribution_policalparties_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.contribution_policalparties_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                  <!-- </ng-container> -->
                  <!-- <ng-container  *ngIf="diductionSingleData.gga80"> -->
                    <div class="fw-400 fs-14 mt-8">80GGA: Deduction in respect of donations to certain funds, charitable institutions, scientific research, and rural development etc.
                    </div>
                    <div class="fw-500 fs-12 mt-8">{{diductionSingleData.cash_other_80gga == true ? "Cash" : "Other"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{diductionSingleData.gga80==true?(diductionSingleData.total_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="diductionSingleData.transaction_80gga_document != '' ">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                          <div class="p-12 bg-secondary radius-8 d-flex">
                            <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(diductionSingleData.transaction_80gga_document) }}</span>
                              <span class="tips-helper ">{{ getDocName(diductionSingleData.transaction_80gga_document) }}</span>
                              <div class="link-primary" (click)="viewDocument(diductionSingleData.transaction_80gga_document)">
                                <i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12" *ngIf="diductionSingleData.transaction_80gga_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                      <ng-container *ngFor="let deduction of diductionSingleData.deduction_80gga,let i = index">
                      <span class="fw-400 fs-14">Transaction Details {{i + 1}}</span>
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-title">Cheque No./Transaction ID</div>
                        <div class="detail-desc"> {{deduction.check_no_transaction_id}}</div>
                      </div>
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-title">Date</div>
                        <div class="detail-desc">{{this.appservice.dateFormatDisplay(deduction.date)}}</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                    <!-- </ng-container> -->


                </div>
              </div>
            </div>
          </ng-container>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-16 ">
                <div class="col-12 ">
                  <span class="title">INCOME FROM OTHER SOURCES</span>
                </div>
                <!-- <ng-container  *ngIf="otherSourceSingleData.incomefrom_houseproperty"> -->

                  <div class="col-12 ">
                    <div class="fw-500 fs-12 mt-8">Income from House property
                  </div>
                </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData.incomefrom_houseproperty==true?(otherSourceSingleData.houseproperty_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData.houseproperty_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(otherSourceSingleData.houseproperty_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(otherSourceSingleData.houseproperty_document) }}</span>
                            <div class="link-primary" (click)="viewDocument(otherSourceSingleData.houseproperty_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData.houseproperty_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                <!-- </ng-container> -->
                <!-- <ng-container  *ngIf="otherSourceSingleData.incomefrom_othersource"> -->
                  <div class="fw-500 fs-12 mt-8">Income from other sources
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData.incomefrom_othersource==true?(otherSourceSingleData.othersource_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData.othersource_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(otherSourceSingleData.othersource_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(otherSourceSingleData.othersource_document) }}</span>
                            <div class="link-primary" (click)="viewDocument(otherSourceSingleData.othersource_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData.othersource_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                <!-- </ng-container> -->
                <!-- <ng-container  *ngIf="otherSourceSingleData.interest_earned_sbaccount"> -->
                  <div class="fw-500 fs-12 mt-8">Interest earned through Saving Bank Account
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData.interest_earned_sbaccount==true?(otherSourceSingleData.sb_account_amount| currency:currency):''}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData.sb_account_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(otherSourceSingleData.sb_account_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(otherSourceSingleData.sb_account_document) }}</span>
                            <div class="link-primary" (click)="viewDocument(otherSourceSingleData.sb_account_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData.sb_account_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                <!-- </ng-container> -->
                <!-- <ng-container  *ngIf="otherSourceSingleData.source_80TTA_80TTB"> -->
                  <div class="fw-500 fs-12 mt-8">80TTA/ 80TTB
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData.source_80TTA_80TTB==true?(otherSourceSingleData.source_80TTA_80TTB_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData.source_80TTA_80TTB_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(otherSourceSingleData.source_80TTA_80TTB_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(otherSourceSingleData.source_80TTA_80TTB_document) }}</span>
                            <div class="link-primary" (click)="viewDocument(otherSourceSingleData.source_80TTA_80TTB_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData.source_80TTA_80TTB_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                <!-- </ng-container> -->
                <!-- <ng-container  *ngIf="otherSourceSingleData.lossfrom_houseproperty"> -->
                  <div class="fw-500 fs-12 mt-8">Loss from House Property
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData.lossfrom_houseproperty==true?(otherSourceSingleData.lossfrom_houseproperty_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData.lossfrom_houseproperty_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(otherSourceSingleData.lossfrom_houseproperty_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(otherSourceSingleData.lossfrom_houseproperty_document) }}</span>
                            <div class="link-primary" (click)="viewDocument(otherSourceSingleData.lossfrom_houseproperty_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData.lossfrom_houseproperty_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                <!-- </ng-container> -->
                <!-- <ng-container  *ngIf="otherSourceSingleData.intrestpaid_houseloan"> -->
                  <div class="fw-500 fs-12 mt-8">Interest paid on housing loan
                  </div>
                  <div class="detail-title mt-8" *ngIf="otherSourceSingleData.intrestpaid_houseloan_option !=''">{{otherSourceSingleData.intrestpaid_houseloan_option}}
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData.intrestpaid_houseloan==true?(otherSourceSingleData.intrestpaid_houseloan_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData.intrestpaid_houseloan_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8">

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(otherSourceSingleData.intrestpaid_houseloan_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(otherSourceSingleData.intrestpaid_houseloan_document) }}</span>
                            <div class="link-primary" (click)="viewDocument(otherSourceSingleData.intrestpaid_houseloan_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData.intrestpaid_houseloan_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                <!-- </ng-container> -->
                <!-- <ng-container  *ngIf="otherSourceSingleData.pre_acquisition_interest"> -->
                  <div class="fw-500 fs-12 mt-8">Pre-acquisition Interest
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData.pre_acquisition_interest==true?(otherSourceSingleData.pre_acquisition_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData.pre_acquisition_document != '' ">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-8" >

                        <div class="p-12 bg-secondary radius-8 d-flex">
                          <img src="\assets\images\fileIcon.png" class="me-24 img-fluid h-40">
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ getDocName(otherSourceSingleData.pre_acquisition_document) }}</span>
                            <span class="tips-helper ">{{ getDocName(otherSourceSingleData.pre_acquisition_document) }}</span>
                            <div class="link-primary"  (click)="viewDocument(otherSourceSingleData.pre_acquisition_document)">
                              <i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData.pre_acquisition_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                <!-- </ng-container> -->
                <ng-container  *ngIf="otherSourceSingleData.lender != ''">
                  <span class="fw-400 fs-14">Lender informations</span>
                  <ng-container *ngFor="let lend of otherSourceSingleData.lender,let i = index">
                    <div class="fw-500 fs-12 mt-8">Lender {{i + 1}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Lender Name </div>
                      <div class="detail-desc"> {{lend.name}}</div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Lender PAN</div>
                      <div class="detail-desc">{{lend.pan}}</div>
                    </div>
                    <div class="col-12">
                      <div class="detail-title">Property Address</div>
                      <div class="detail-desc">{{lend.property_address}}</div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Possession date</div>
                      <div class="detail-desc"> {{this.appservice.dateFormatDisplay(lend.possession_date)}}</div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Owner Name</div>
                      <div class="detail-desc">{{lend.owner_name}}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </form>
</div>

<!-- Alert for investment declaration start -->
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{investalertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div>

<!-- Doc Preview Start -->
<div class="modal  modal-pdfviewer modal-custom {{modalToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16">
              <h4 class="modal-title">{{currentDocName}}</h4>
              <i (click)="modalToggle = false" class="ms-auto bi b bi-x-lg text-light-500"></i>
          </div>
          <div class="modal-body px-0">
              <div class="pdf-container" *ngIf="currentExtension=='pdf' ">
                  <pdf-viewer [src]="fileName" [original-size]="false" style="height: calc(100% - 1.375rem)!important;"></pdf-viewer>
              </div>
              <img  *ngIf="currentExtension!='pdf' && currentExtension!='zip' && currentExtension!='docx' && currentExtension!='xlsx' && currentExtension!='csv'" [src]="fileName" style="padding: 1.3125rem;width: 100%;">

          </div>

      </div>
  </div>
</div>
