<div class="container-fluid p-32 form-container">
    <app-c1-loader *ngIf="navLoader"></app-c1-loader>
    <div  *ngIf="!navLoader" class="row row-24 ">
        <div class="col-12 d-flex flex-wrap align-items-start">
            <div>
                <div class="mb-0 fw-500">Override {{componentType}} </div>
                <div class=" fs-12 fw-500 text-light-400">{{month}} {{year}}</div>
            </div>
            <div class="d-flex justify-content-end align-items-center ms-auto flex-wrap gap-16">

                <!-- <a  *ngIf="countSelected()>=1 && status=='PENDING'" class="link-danger text-nowrap" (click)="bulkDelete()"><i class="icon-trash me-8 "></i>Delete</a> -->

                <div style="min-width:22.688rem;">
                    <div class="form-icon2">
                      <i class="icon-search icon-left fs-14"></i>
                      <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                      <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1" autocomplete="off"/>
                    </div>
                </div>
                <button class="btn-square btn btn-secondary"  *ngIf="status=='COMPLETED'" (click)="filter  = true">
                    <i class="icon-filter-left fs-14"></i>
                </button>
                <button *ngIf="status=='PENDING' && permissions?.a "  class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 " (click)="deleteUploadedFile();showSidePanel=true">IMPORT</button>
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [disabled]="loader"  [checked]='status=="PENDING"' (click)='status="PENDING";searchString="";' [disabled]="loader">
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio1">PENDING</label>
                    <input type="radio" class="btn-check"  name="btnradio" id="btnradio2"  [disabled]="loader"  autocomplete="off" [checked]='status=="COMPLETED"' (click)='status="COMPLETED";page=1;searchString="";' [disabled]="loader">
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">COMPLETED</label>
                  </div>
            </div>
        </div>


        <ng-container *ngIf="componentType=='ESI' ">
            <app-override-esi [status]="status" [page]="page" [searchString]="searchString" [data]="data" [loader]="loader" [pageSize]="pageSize" [employeeList]="employeeList" [HistoryData]="HistoryData" [selctedData]="selctedData"
            (changeSelectedEmployee)="changeSelectedEmployee.emit($event)"
            (checkall)="checkall.emit($event)"
            (deleteSingleRow)="deleteSingleRow.emit($event)"
            (validateField)="validateField($event)"
            (focusoutfield)="focusoutfield($event)"
            ></app-override-esi>
        </ng-container>

        <ng-container *ngIf="componentType=='PT' ">
            <app-override-pt [status]="status" [page]="page" [searchString]="searchString" [data]="data" [loader]="loader" [pageSize]="pageSize" [employeeList]="employeeList" [HistoryData]="HistoryData" [selctedData]="selctedData"
            (changeSelectedEmployee)="changeSelectedEmployee.emit($event)"
            (checkall)="checkall.emit($event)"
            (deleteSingleRow)="deleteSingleRow.emit($event)"
            (validateField)="validateField($event)"
            (focusoutfield)="focusoutfield($event)"></app-override-pt>
        </ng-container>
        <ng-container *ngIf="componentType=='LWF' ">
            <app-override-lwf [status]="status" [page]="page" [searchString]="searchString" [data]="data" [loader]="loader" [pageSize]="pageSize" [employeeList]="employeeList" [HistoryData]="HistoryData" [selctedData]="selctedData"
            (changeSelectedEmployee)="changeSelectedEmployee.emit($event)"
            (checkall)="checkall.emit($event)"
            (deleteSingleRow)="deleteSingleRow.emit($event)"
            (validateField)="validateField($event)"
            (focusoutfield)="focusoutfield($event)"></app-override-lwf>
        </ng-container>
        <ng-container *ngIf="componentType=='PF' ">
            <app-override-pf [status]="status" [page]="page" [searchString]="searchString" [data]="data" [loader]="loader" [pageSize]="pageSize" [employeeList]="employeeList" [HistoryData]="HistoryData" [selctedData]="selctedData"
            (changeSelectedEmployee)="changeSelectedEmployee.emit($event)"
            (checkall)="checkall.emit($event)"
            (deleteSingleRow)="deleteSingleRow.emit($event)"
            (validateField)="validateField($event)"
            (focusoutfield)="focusoutfield($event)"></app-override-pf>
        </ng-container>
        <ng-container *ngIf="componentType=='TDS' ">
            <app-override-tds [status]="status" [page]="page" [searchString]="searchString" [data]="data" [loader]="loader" [pageSize]="pageSize" [employeeList]="employeeList" [HistoryData]="HistoryData" [selctedData]="selctedData"
            (changeSelectedEmployee)="changeSelectedEmployee.emit($event)"
            (checkall)="checkall.emit($event)"
            (deleteSingleRow)="deleteSingleRow.emit($event)"
            (validateField)="validateField($event)"
            (focusoutfield)="focusoutfield($event)"></app-override-tds>
        </ng-container>

        <div class="col-12" *ngIf='status=="PENDING"'>
            <button class="btn btn-outline-primary radius-2"  (click)="addEmployeeData()">Add Employee</button>
        </div>
        <div class="col-12" *ngIf="status=='PENDING' && this.appService.filteredcount(data,searchString)>pageSize">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="this.appService.filteredcount(data,searchString)" [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
        </div>
        <div class="col-12" *ngIf="status=='COMPLETED' && this.appService.filteredcount(HistoryData,searchString)>pageSize">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="this.appService.filteredcount(HistoryData,searchString)" [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
        </div>
    </div>

</div>
<footer class="submit-footer   gap-16" *ngIf='status=="PENDING"'>
     <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="bulkDelete()"  *ngIf="(countSelected()>=1 && status=='PENDING')">Delete</button>
    <p class="fs-14 ms-auto d-flex gap-8 mb-0">
        <span class="">{{countSelected()}} of {{data.length}} selected </span>
        <span *ngIf="data.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{data?.length}} employees</span>
        <span *ngIf="data.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{data?.length}} employees</span>
    </p>
    <button class="btn btn-primary btn-sm text-uppercase" [disabled]=" countSelectedWithoutError()==0"  (click)="saveData()">SAVE</button>
    <!-- <a  class="btn btn-primary ms-auto btn-sm text-uppercase">SAVE</a> -->
</footer>
<footer class="submit-footer   gap-16" *ngIf='status=="COMPLETED"'>
    <p class="fs-14 ms-auto d-flex gap-8 mb-0">
        <span class="">{{countSelected()}} of {{HistoryData.length}} selected </span>
        <span *ngIf="HistoryData.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{HistoryData?.length}} employees</span>
        <span *ngIf="HistoryData.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{HistoryData?.length}} employees</span>
    </p>
    <button class="btn btn-primary btn-sm text-uppercase" [disabled]=" countSelected()==0"   (click)="showinfo();">{{buttonText}}</button>
    <!-- <a  class="btn btn-primary ms-auto btn-sm text-uppercase">SAVE</a> -->
</footer>

<app-import-upload
    [fileName]="fileName" [fileSize]="fileSize" [invalidFormat]="invalidFormat" [title]="'Import Override '+componentType+' - '+month+' '+year"
    [submiited]="submiited" [showSidePanel]="showSidePanel" [btnText]="'Import Override '+componentType"  [heading]="'OVERRIDE '+componentType"
    (fileChange)="onFileChange($event)" (deleteFile)="deleteUploadedFile()" (validateData)="validatedata()" (closePanel)="showSidePanel=false"
    >
    <li>Download the <a class="link-primary" href="{{sampleFile}}"> <i class="icon-download me-1"></i> Sample Excel
        template</a></li>
    <li>Update the amount in respective field and save the file.</li>
    <li>Click the import override {{componentType}} button.</li>
    <li>Choose the file to be imported and click on upload.</li>
    <li>The file chosen should be in (.xls/.csv)format only.</li>
</app-import-upload>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
                <h4 class="modal-title">{{deletePossibleCount()}} Employees selected</h4>
                <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
            </div>
            <div class="modal-body">
                <p class="mb-0">Click the {{buttonText}} button, If you want to unmap or modify the overrided {{componentType}} for the selected employee(s).</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="confirmMsg = false;">Cancel</button>
                <button  type="button" class="btn btn-primary btn-sm text-uppercase" (click)="bulkDelete();confirmMsg = false;">{{buttonText}}</button>
            </div>
        </div>
    </div>
  </div>

<app-info-popup *ngIf="alertToggle == true" (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg" ></app-info-popup>

