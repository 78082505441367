import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExpensePolicyService } from 'src/app/@core/services/expense-policy.service';

@Component({
  selector: 'app-expense-policy-map-category',
  templateUrl: './expense-policy-map-category.component.html',
  styleUrls: ['./expense-policy-map-category.component.scss']
})
export class ExpensePolicyMapCategoryComponent implements OnInit {
  @Input()formGp        : any;
  @Input()submitted     : boolean=false;
  @Output() submitmethod= new EventEmitter();
  @Input()disabled      = false;
  @Input()loadercatg    = false;
  @Input() editflag     : any;
  @Input() categorylist : any=[];
  saveClicked           = false;
  editDetail            = false;
  dataId                : any;
  loader                = false;
  searchPlaceholder     = this.messageService.selectplaceholddisplay('expense category');
  searchKeyword         ='';
  constructor(
    public fb:FormBuilder,
    public messageService : MessageService,
    public appService:AppService,
    public ExpensePolicyService:ExpensePolicyService,

  ) { }

  ngOnInit(): void {
    const formArray: FormArray = this.formGp.get('categories') as FormArray;
  }
  
  onCheckChange(event:any,name:any) {
    const formArray: FormArray = this.formGp.get('categories') as FormArray;
    if(event.target.checked){
      formArray.push(new FormControl(event.target.value));
    }
    else{
      let i: number = 0;
      formArray.controls.forEach((ctrl: any) => {
        if(ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  validatepolicysetting() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  closefn(daata:any){
    this.editDetail = daata;
  }

  detailout(res:any){
    if(res != ''){
      const formArray: FormArray = this.formGp.get('categories') as FormArray;
      let tempid:any;
      let i: number = 0;

      // formArray.controls.forEach((ctrl: any) => {
      // if(this.dataId == ctrl.value) {          
      //       tempid=ctrl.value;
      //       formArray.removeAt(i);
      //       formArray.push(new FormControl(res.id.toString()));
      //       return;
      //     }
      //     i++;
      //   });    

        for(let p=0;p<this.categorylist.length;p++){
          for(let m=0;m<this.formGp.getRawValue().categories.length;m++){
            if(this.formGp.getRawValue().categories[m] == this.dataId &&
            this.categorylist[p]['id'] == this.dataId){
              formArray.removeAt(m)
              formArray.push(new FormControl(res['id'].toString()));
              this.categorylist[p]['id'] = res['id'];
              return;
            }
          }
        }
       
        // for(let k=0;k<this.categorylist.length;k++){
        //   if(tempid==this.categorylist[k].id){
        //     this.categorylist[k].id= res.id;            
        //   }
        // }
    }
  }
  searchresults(data:any){
    let categoryarr = this.categorylist;
    this.categorylist = [];
    if(data == ""){
      this.searchKeyword  = "";
      this.getcategorylist();    
    }
    else{
      for(let i = 0;i<categoryarr.length;i++){
        if(categoryarr[i]['id'] == data){
          this.categorylist = [categoryarr[i]];
        }
      }
          
    }
  }
  searchpaygroup(searchKeyword:any){
    this.categorylist = [];
      this.searchKeyword = searchKeyword;
      if(searchKeyword != ""){
        this.getcategorylist();
      }

  }
  getcategorylist(){
    this.loadercatg=true;
    this.ExpensePolicyService.getcategorySearchList(true,this.searchKeyword).subscribe((res:any)=>{
      this.categorylist   = res;
      for(let i=0;i<this.formGp.value?.categories.length;i++){
        this.ExpensePolicyService.getSinglecategoryData(this.formGp.value?.categories[i]).subscribe((result:any)=>{
          for(let k=0;k<this.categorylist.length;k++){
            if(this.categorylist[k].name==result.name){
              this.categorylist[k].id= result.id;        
            }
          }
        })    
       }
      this.loadercatg=false;
    })
  }
}
