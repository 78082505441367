import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyReimbursementService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getMyReimbursementData(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/profile-reimbursement/`, body, this.httpOption)
  }
  getMyReimbursementTableData(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/my-reimbursement-table/`, body, this.httpOption)
  }
  reimbursementCategoryDropDown(empid: any, year:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/reimbursement-category-list-dropdown/${empid}/?year=${year}`, this.httpOption)
  }
}
