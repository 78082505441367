import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';
import { EmppShiftMapService } from 'src/app/@core/services/empp-shift-map.service';
import { ShiftService } from 'src/app/@core/services/shift.service';
import { WeekoffService } from 'src/app/@core/services/weekoff.service';

@Component({
  selector: 'app-sm-assign-shift',
  templateUrl: './sm-assign-shift.component.html',
  styleUrls: ['./sm-assign-shift.component.scss']
})
export class SmAssignShiftComponent implements OnInit {

  assignControls    : any
  maxValue          : any
  id                : any
  shiftDayBetween   : any = []
  shiftDropdown     : any = []
  submitted         : boolean = false
  copyData          : boolean = false
  init              : boolean = false
  loader            : boolean = false
  minDate           : any

  weekoffDropdown   : any = [
    {name:"Full Day",    value:"Full Day"},
    {name:"First Half",  value:"First Half"},
    {name:"Second Half", value:"Second Half"},
    {name:"None",        value: null },
  ];

  @Input() formGp   : any

  @Output() submitmethod = new EventEmitter()
  @Output() createArray = new EventEmitter()


  constructor(
    public messageService : MessageService,
    public fb             : FormBuilder,
    public appService     : AppService,
    public empShiftServ   : EmppShiftMapService,
    public shiftService   : ShiftService,
    public weekOffService : WeekoffService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats
  ) { }

  ngOnInit(): void {
    let inThreeYears = new Date();
    inThreeYears.setFullYear (inThreeYears.getFullYear() + -3 )
    this.minDate = new Date(inThreeYears.getFullYear(), 0, 1);
    // Formarray intialise
    this.assignControls = <FormArray>this.formGp.controls.assign_shift_date
    // Shift API
    this.getShiftDropdownAPI()
    this.checkedData()
    let formVal = this.formGp.get('assign_shift_date').value
    if(formVal.length !=0){
      this.loader = true
      formVal.forEach((item:any)=>{
        return item['dateDisplayFormat'] = this.appService.dateFormatDisplay(item?.assignday)+' ('+item?.assigndate+')'
      })
      this.shiftDayBetween = formVal
      setTimeout(()=>{
        this.loader = false
      },1000)
    }

    // Date Format
    this.getDateFormat();
  }

  // Date Format
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  // Controls
  get f(){ return this.formGp.controls }

  controls() : FormArray {
    return (this.formGp.get("assign_shift_date") as FormArray);
  }

  // Shift API
  getShiftDropdownAPI(){
    this.shiftService.shiftDropDownData(true).subscribe((res:any)=>{
      this.shiftDropdown = res
    })
  }

  // Shift Days API
  getShiftDays(from:any,to:any){
    this.loader = true
    this.shiftDayBetween = []
    this.empShiftServ.getShiftDays(from,to).subscribe((res:any)=>{
      if(res.body.length !=0){
        this.shiftDayBetween = res.body
        this.loader = false
        this.createArray.emit(this.shiftDayBetween)
      }
    })
  }

  // Validate Form
  validateAssign(){
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  // checkedData
  checkedData(){
    let data = this.assignControls.value
    data.forEach((item:any,index:any)=>{
      if(item.shift == null && item.checkedValue == true){
        this.controls().controls[index].get('shift')?.setValidators([Validators.required])
      }else{
        this.controls().controls[index].get('shift')?.clearValidators();
      }
      this.controls().controls[index].get('shift')?.updateValueAndValidity();
    })
    let items = this.getCheckedData()
    if(items.length == 0){
      this.init = true
    }else{
      this.init = false
    }
  }

  // Date Picker
  disableDate(){
    return false;
  }

  // From Date
  fromDate(){
    let from = this.f.from.value
    this.maxValue = moment(from).add(30, 'days')
    if(this.f.to.value !== null && moment(this.f.from.value).isSameOrBefore(moment(this.f.to.value)) && moment(this.f.to.value).isSameOrBefore(this.maxValue)){
      this.toDate()
    }else{
      this.formGp.patchValue({
        to : null
      })
    }
  }

  // To Date
  toDate(){
    let from = moment(this.convertToDate(this.f.from.value)).format('YYYY-MM-DD')
    let to = moment(this.convertToDate(this.f.to.value)).format('YYYY-MM-DD')
    this.controls().clear();
    this.checkedData()
    this.getShiftDays(from,to)
  }

  // getChecked Data
  getCheckedData(){
    return this.assignControls.value.filter((item:any)=> item.checkedValue == true)
  }

  // Check All
  checkAll(e:any){
    if(e.target.checked){
       this.assignControls.controls.map((value:any) => value.get('checkedValue').setValue(true));
    }else{
       this.assignControls.controls.map((value:any) => value.get('checkedValue').setValue(false));
    }
    this.checkedData()
  }

  // Checked
  allCheck(){
    let list = this.appService.filterIt(this.formGp.get('assign_shift_date')?.value,'');
    let slicedArray = list.slice(0,this.formGp.get('assign_shift_date')?.value.length)
    if(slicedArray.length){
      return slicedArray.every((item:any)=>{
        return item.checkedValue
      })
    }else{
      return false;
    }
  }

  // Individual
  individualCheck(e:any,value:any){
    this.assignControls.controls.filter((item:any,index:any)=> {
      if(e.target.checked == true){
        if(index == value){
          item.get('checkedValue').setValue(true)
        }
      }
      else{
        if(index == value){
          item.get('checkedValue').setValue(false)
        }
      }
    })
    this.checkedData()
  }

  // Convert to needed format
  convertToDate(value:any){
    var date = new Date(value);
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var dateString = (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    dateString = dateString.split("-").reverse().join("-")
    return dateString
  }

  // selectedFirst
  selectedFirst(value:any,index:any){
    let td = document.getElementsByTagName('td')
    let tr = document.getElementsByTagName('tr')
    let formVal = this.assignControls.value
    if(formVal.length > 1 && (tr[index].id == 'row1')){
      this.copyData = true
    }
  }

  // Copy Data
  copyIt(){
    let formVal = this.assignControls.value
    let firstVal  = formVal[0].shift
    this.copyData = false
    this.controls().controls.forEach((item:any,index:any)=>{
      this.assignControls.controls.at(index).patchValue({
        shift   : firstVal
      })
    })
  }

  dobFilter(d:any){
    const currentDate = new Date();
    const dateRange = [
      new Date(2020, 0, 1),
      currentDate
    ];
    return (d >= dateRange[0]);
  }
}
