import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { PfNumberValidator } from 'src/app/@shared/validators/pfnumber.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { MessageService } from 'src/app/message.global';
import { AmountValidator } from 'src/app/@shared/validators/amount.validators';
​import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AadharnumValidator } from 'src/app/@shared/validators/aadharnum.validators';
import { EmployeeESInumValidator } from 'src/app/@shared/validators/esinum.validators';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';
import { uannumberValidator } from 'src/app/@shared/validators/uannumber.validators';

@Component({
  selector: 'app-ss-emp-statutory',
  templateUrl: './ss-emp-statutory.component.html',
  styleUrls: ['./ss-emp-statutory.component.scss']
})
export class SsEmpStatutoryComponent implements OnInit {

  constructor(public messageService : MessageService,public salaryService:SalaryStructureService) { }
  Isalert                     = true;
  @Input() employeeData:any   = []
  @Input() empSalaryData:any  = []
  @Input() formGp:any         = []
  @Output() changeTab         =  new EventEmitter();
  @Output() StatutoryError    =  new EventEmitter();
  year                        = new Date().getFullYear();
  month                       = new Date().getMonth();
  nextYear                    = new Date().getFullYear()+1;
  prevYear                    = new Date().getFullYear()-1;
  uniqueValidators : any = {
                              'pf_number'     :false,
                              'pension_number':false,
                              'esi_number'    :false,
                              'uan_number'    :false,
                              'error'         :0
                            }
  nonMandatoryField:any = ['pf_number','pension_number','esi_number','uan_number']
  submitted = false;
  ngOnInit(): void {
    this.addConditionalValidation();

  }


  get f() {return  this.formGp.controls; }

  addConditionalValidation(){
    this.addValidation("provident_fund","pf_number",PfNumberValidator(),'');
    this.addValidation("pension","pension_number",AadharnumValidator(),'');
    this.addValidation("employee_state_insurance","esi_number",EmployeeESInumValidator(),'');
    this.addValidation("employee_state_insurance","dispensry_name",UnamepatternValidator(),100);
    this.addValidation("provident_fund","uan_number",uannumberValidator(),'');
    // this.addValidation("gratuity","gratuity_in_ctc","",'');
    this.addValidation("volentary_provident_fund","amount",digitdecimalValidator(),10);
    this.addValidation("volentary_provident_fund","vpf_month",'','');
    this.addValidation("volentary_provident_fund","vpf_year",'','');
    this.addValidation("employer_contribution_nps","percentage",digitdecimalValidator(),100);
    this.esiValidator()/*Show error msg on existing ESI number.(validation changed 17 to 10 digit)*/
  }
  setValidator(control:any){
    switch(control) {
      case 'provident_fund': {
        this.addValidation("provident_fund","pf_number",PfNumberValidator(),'');
        this.addValidation("provident_fund","uan_number",uannumberValidator(),'');
      break;
      }
      case 'pension': {
        this.addValidation("pension","pension_number",AadharnumValidator(),'');
         break;
      }
      case 'employee_state_insurance': {
        this.addValidation("employee_state_insurance","esi_number",EmployeeESInumValidator(),'');
        this.addValidation("employee_state_insurance","dispensry_name",UnamepatternValidator(),100);
         break;
      }
      case 'volentary_provident_fund': {
        this.addValidation("volentary_provident_fund","amount",digitdecimalValidator(),10);
        this.addValidation("volentary_provident_fund","vpf_month",'','');
        this.addValidation("volentary_provident_fund","vpf_year",'','');
         break;
      }
      default: {
        this.addValidation("employer_contribution_nps","percentage",digitdecimalValidator(),100);
         break;
      }
   }
  }
  esiValidator(){
    if(this.formGp.get('esi_number').value!=null && this.formGp.get('esi_number').value!='' && this.formGp.get('esi_number').invalid){
      this.formGp.get('esi_number').markAsTouched();
    }
  }
  addValidation(chk:any,inpt:any,pattern:any,maxLength:any){

    this.formGp.get(chk).valueChanges.subscribe((value: any) => {

      if(value && pattern!="" && maxLength=='') {
        if((this.nonMandatoryField.includes(inpt)))
        this.formGp.get(inpt).setValidators([pattern])
        else
        this.formGp.get(inpt).setValidators([Validators.required,pattern])

      }
      else if(value && pattern!="" && maxLength!=''){
        if(inpt=="percentage")
        this.formGp.get(inpt).setValidators([Validators.required,pattern,Validators.max(maxLength)])
        else{

          if(inpt=="dispensry_name"){

            this.formGp.get(inpt).setValidators([pattern,Validators.maxLength(maxLength)])
          }
          else
          this.formGp.get(inpt).setValidators([Validators.required,pattern,Validators.maxLength(maxLength)])
        }

      }
      else if(value && pattern==""){
        this.formGp.get(inpt).setValidators([Validators.required])

      }
      else {
        if (this.uniqueValidators.hasOwnProperty(inpt)) {
          if(this.uniqueValidators[inpt]==true){

            this.uniqueValidators.error = this.uniqueValidators.error-1
            this.uniqueValidators[inpt] = false;
            this.StatutoryError.emit(this.uniqueValidators.error);

          }
        }
        this.formGp.get(inpt).clearValidators();
        this.formGp.get(inpt).setValue('');

        if(chk=='provident_fund'){
          this.resetValues();

        }
      }


      if(this.formGp.get('vpf_month').value=='')
        this.formGp.get('vpf_month').setValue(null)
      if(this.formGp.get('vpf_year').value=='')
        this.formGp.get('vpf_year').setValue(null)
      this.formGp.controls[inpt].updateValueAndValidity();
    });
  }
  resetValues(){

    this.formGp.get('vpf_month').setValue(null)
    this.formGp.get('vpf_year').setValue(null)
    this.formGp.get('volentary_provident_fund').setValue(false)
    this.formGp.get('pension').setValue(false)
    this.formGp.get('amount').setValue('')
    this.formGp.get('pension_number').setValue('')
    this.formGp.get('amount').clearValidators();
    this.formGp.get('pension_number').clearValidators();
  }
  validateStatutory() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.changeTab.emit(2);

  }
  docNumValidators(control:any,key:any){
    if(this.uniqueValidators[control]==true)
      this.uniqueValidators.error = this.uniqueValidators.error-1
    this.uniqueValidators[control] = false;
    if(this.formGp.controls[control].status=="VALID" && this.formGp.get(control).value!="" && this.formGp.get(control).value!=null && this.employeeData.empid!=undefined ){

      let data = {
        "employee"      : this.employeeData.empid,
        "document_type" : key,
        "document"      : this.formGp.get(control).value
      }
      this.salaryService.checkValidityDoc(data).subscribe((res:any)=>{
          this.uniqueValidators[control] = false;
          this.StatutoryError.emit(this.uniqueValidators.error);

      },
      (err: any) => {

        this.uniqueValidators[control]  = true;
        this.uniqueValidators.error     = this.uniqueValidators.error+1
        this.StatutoryError.emit(this.uniqueValidators.error);

      }
      )
    }

  }

}
