import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router, Event } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class EmppShiftMapService {
  FILTERDATA:any;
  FILTERDROP :any;
  FILTERTODATE :any;
  FILTERFROMDATE:any
  processPercentage: EventEmitter<any> = new EventEmitter();
  userdata:any = [];
  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router,
    private websocket: WebSocket,private authService: AuthService,public appservice:AppService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(  !event.url.includes('employee-shift')){
          this.setFilterData(null)
          this.setFilterDrop(null)
          this.setFilterDate(null,null)
        }
      }
    })
  }

  // Assign Shift
  createShiftMap(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/assign-shift-create/`,body, this.httpOption)
  }

  // FROM & TO days
  getShiftDays(from:any,to:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-date-range/?fromdate=${from}&todate=${to}`, this.httpOption)
  }

   // Landing Screen
   getLandingScreen(from:any,to:any,limit:any,offset:any,search:any,reporting_manager_status:any,filter:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping/?fromdate=${from}&todate=${to}&limit=${limit}&offset=${offset}&search=${search}&reporting_manager_status=${reporting_manager_status}&${filter}`, this.httpOption)
  }

  // Filter
  getFilterData(from:any,to:any,data:any,limit:any,offset:any,reporting_manager_status:any,search:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping/?fromdate=${from}&todate=${to}&${data}&limit=${limit}&offset=${offset}&reporting_manager_status=${reporting_manager_status}&search=${search}`,this.httpOption)
  }

  // Search
  getSearchedData(from:any,to:any,id:number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping/?fromdate=${from}&todate=${to}&limit=1&offset=1&emp_id=${id}`,this.httpOption)
  }

  getAllShiftDatas(from:any,to:any,limit:any,offset:any,search:any,reporting_manager_status:any,filter:any,id:any){
    if(id == 0 && reporting_manager_status == false){
      return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping/?fromdate=${from}&todate=${to}&limit=${limit}&offset=${offset}&search=${search}&reporting_manager_status=${reporting_manager_status}&${filter}`, this.httpOption)
    }else if(id == 0 && reporting_manager_status == true){
      return this.http.get(`${this.API_PATH}attendance/api/v1/team-shift-mapping/?fromdate=${from}&todate=${to}&limit=${limit}&offset=${offset}&search=${search}&reporting_manager_status=${reporting_manager_status}&${filter}`, this.httpOption)
    }
    else{
      return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping/?fromdate=${from}&todate=${to}&limit=1&offset=1&emp_id=${id}`,this.httpOption)
    }
  }

  // Get specifc emp data
  getEmpData(id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/assign-shift-update/${id}/`,this.httpOption)
  }

  // Update
  updateShiftData(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/shift-mapping-update/`,body,this.httpOption)
  }
  generateShiftTemplate(body:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping-export-excel/${body}`,this.httpOption)
  }
  uploadShift(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/shift-mapping-create/`,body,this.httpOption)

  }

  // Filter
  setFilterData(val:any){
   this.FILTERDATA = val
  }

  // Drop
  setFilterDrop(val:any){
   this.FILTERDROP = val
  }

  // Date
  setFilterDate(val1:any,val2:any){
    this.FILTERFROMDATE = val1
    this.FILTERTODATE = val2
  }
  

  public requestSocket(){

    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}shiftassign-progressbar/${this.appservice.getSubDomain()}_shift_assign_progressbar_${this.authService.isUserId}/`);
    this.websocket.onopen = (event:any) => {
      }
    this.websocket.onmessage = (event:any) => {
      let data = JSON.parse(event.data);
      this.processPercentage.emit(data);
    }
    this.websocket.onclose = (event:any) => {

      }
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
            if(event.url == '/login'){
             this.closeSocket();
            }
        }
      });
 
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
    }
  }

  getShiftProcessData() {
    return this.http.get(`${this.API_PATH}hr/api/v1/pdflive-get/?condition=SHIFT`, this.httpOption)
  }

  PROCESSURL:any='';
  PROCESSTEXT:any='';
  BGPROGRESS  =   true
  RUNBG = false
  setProgress(val:boolean=false){
    this.BGPROGRESS = val
  }

  setProcessData(url:any,text:any =""){
    this.PROCESSURL = url;
    this.PROCESSTEXT = text
  }

}
