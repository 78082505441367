import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { RoleService } from '../../../../@core/services/role.service'
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  showUserList = false;
  showSidePanel = false;
  is_active: any = true;
  roleDetails: any = [];
  loader = true;
  deleteAlert = false;
  deleteId = 0;
  roleDD = [];
  searchPH = this.messageService.searchdisplay('role');
  offset = 0;
  listlength = 0;
  direction = '';
  defaultlimit:any = 20;
  searchKeyword="";
  infinityloader = false;
  nodata = false;
  roleDataList = [];
  viewRoleData:any = [];
  loader1 = false;
  permissions:any = [];
  roleDefault = false;
  deleteClicked = false;
  listType = "active"
  companyData:any;
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;
  constructor(
    private roleService: RoleService,public appService:AppService,public messageService : MessageService,
    public breakpointObserver: BreakpointObserver,public router: Router,private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.roleList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.roleService.getroleList(this.is_active,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  selectItemList(value:any){
    this.loader = true;
    this.is_active = value;
    this.offset = 0;
    this.roleDetails = [];
    this.child.clearBar();
  }

  roleList() {
    this.loader = true;
    this.roleService.getroleList(this.is_active,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
      this.arrangeApiData(res)
    });
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res.body.results.length;
    if(this.offset ==0){
      this.roleDetails = [];
    }
    this.infinityloader = false;
    if (res.body.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata = false;
      this.roleDataList = res.body.results;
    }
    this.addItems(this.listlength);
    this.loader = false;
  }

  roleDropdownList(){
    this.roleService.getRoleDropdownList(this.is_active).subscribe((res: any) => {
      for(let i=0;i<res.body.length;i++){
        res.body[i]['name']  = res.body[i]['role_name'];
      }
      this.roleDD = res.body;
      this.loader = false;
    });
  }

  deleteRole(id:number){
    this.deleteClicked =true
    if(this.roleDefault == true){
      this.deleteClicked =false
      this.notificationService.handleErrorNotification("The user role you're trying to delete is system defined. This role cannot be deleted.","Error");
    } else {
      let data = { "is_active": false};
      this.roleService.deleteRole(data,id).subscribe((res: any) => {
        this.deleteAlert = false;
        this.offset = 0;
        this.roleDetails = [];
        this.deleteClicked =false
        this.roleList();
      });
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.roleList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.roleDetails.push(this.roleDataList[i]);

    }


  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.roleDetails = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  viewrole(id:any){
    if(this.permissions.v){
      this.showSidePanel = true;
      this.loader1       = true;
      this.roleService.getRoleviewdata(id).subscribe((res: any) => {
        this.viewRoleData = res.body;
        this.companyData = this.viewRoleData.employee_rights;
        this.loader1      = false;
      })
    }
  }

  searchRole(searchKeyword:any){
    this.loader = true;
    this.isLoading= true
    this.offset            = 0;
    this.roleDetails = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/role/setup-role']);
  }
}
