import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MasterSelectModule } from "../../@shared/master-select.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NguCarouselModule } from '@ngu/carousel';
import { CountUpModule } from 'ngx-countup';
import { RequestModule } from '../request/request.module';

@NgModule({
    declarations: [
        HomeComponent,
        DashboardComponent
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        NgSelectModule,
        NgxChartsModule,
        MasterSelectModule,
        NgbModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        NguCarouselModule,
        CountUpModule,
        RequestModule
    ]
})
export class HomeModule { }
