import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { FormBuilder,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';

@Component({
  selector: 'app-scheduled-reports',
  templateUrl: './scheduled-reports.component.html',
  styleUrls: ['./scheduled-reports.component.scss']
})
export class ScheduledReportsComponent implements OnInit {
  showSidePanel             = false;
  deleteAlert               = false;
  rescheduleAlert           = false;
  loader                    = false;
  nodata                    = false;
  schedules:any[]           = [];
  activeSchedules:any[]     = [];
  inactiveSchedules:any[]   = [];

  searchlistdata:any        = [];
  schedulesearch:string     = this.messageService.searchdisplay('Schedule');
  activeToggle              = '';
  activeCnt                 = 0;
  inactiveCnt               = 0;
  permissions:any           = [];
  loader1                   = false;
  viewScheduleData:any      = [];
  scheduleId                = 0;
  fromDateConvert: Date     = new Date();
  toDateConvert: Date       = new Date();
  isDateConvert             = false;
  investView:any []               = [
    {key:"Investment Type" ,value: ""},
    {key:"Requested On" ,value: ""}
  ];

  reScheduleReportForm      = this.formBuilder.group({
                                schedule_start_date: [null,[Validators.required]],
                                schedule_end_date: [null,[Validators.required]],
                              },{ validators: CustomDateValidators.fromToDate('schedule_start_date', 'schedule_end_date') });

  submitted                 = false;
  deleteClicked             = false;
  saveClicked               = false;
  statusBtn:any             = "All";
  searchKeyword             = "";

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength                 = 0;
  direction                  = '';
  defaultlimit:any           = 20;
  taskDetailsList:any        = [];
  infinityloader             = false;

  @ViewChild(SearchbarComponent) child:any;
  constructor(@Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,private reportService:ReportsService,public router: Router,public messageService : MessageService,public appservice:AppService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);

    this.getPermission();
    this.showDataItems();
  }

  showDataItems(){
    this.loader                = true;
    this.reportService.scheduleReportList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
      this.listlength          = res.results.length;
      if(this.offset == 0){
        this.activeSchedules   = [];
        this.inactiveSchedules = [];
        }
      if (res.results.length != 0) {
        this.taskDetailsList  = res.results;
      }
      this.infinityloader     = false;
      this.addItems(this.listlength);
      if (res.results.length == 0) {
        this.nodata = this.offset == 0 ? true:false;
      }else{
        this.nodata = false;
      }
      // this.nodata             = this.taskDetailsList.length==0?true:false;
      this.loader             = false;
    });
    this.reportService.scheduleReportDropDown(this.activeToggle).subscribe((res: any) => {
      for(let i=0; i< res.length; i++){
        res[i]['name']        = res[i]['scheduler_name']
      }
      this.searchlistdata     = res;
      this.loader             = false;
    });
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    // this.activeCnt    = 0;
    // this.inactiveCnt  = 0;
    for (let i = 0; i < itemlist; ++i) {
      if(this.taskDetailsList[i]['is_active'] == true){
        this.activeCnt        = i + 1;
        this.activeSchedules.push(this.taskDetailsList[i]);
      }
      else{
        this.inactiveCnt       = i + 1;
        this.inactiveSchedules.push(this.taskDetailsList[i]);}
      this.schedules.push(this.taskDetailsList[i]);
    }

  }

  /********* SEARCH FUNCTION ****************/
  searchresults(data:any){
    this.loader                 = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset               = 0;
      this.schedules            = [];
      this.activeSchedules      = [];
      this.inactiveSchedules    = [];
      this.taskDetailsList      = [];
      this.showDataItems();
    }else{
      this.reportService.scheduleReportGet(data).subscribe((res: any) => {
        this.schedules          = [];
        this.activeSchedules    = [];
        this.inactiveSchedules  = [];
        let test                = res;
        this.activeCnt          = 0;
        this.inactiveCnt        = 0;
        this.loader             = false;
        if(test['is_active'] == true){
          this.activeCnt        = 1;
          this.activeSchedules.push(test);
        }
        else{
          this.inactiveCnt      = 1;
          this.inactiveSchedules.push(test);
        }
      });
    }
  }

  /**************** METHOD FOR FILTER *********************/
  selectItemList(value:any){
    this.loader                 = true;
    this.activeToggle           = value;
    this.offset                 = 0;
    this.activeCnt              = 0;
    this.inactiveCnt            = 0;
    this.activeSchedules        = [];
    this.inactiveSchedules      = [];
    this.schedules              = [];
    this.taskDetailsList        = [];
    // this.showDataItems();
    this.child.clearBar();
  }

  editScheduleReport(id:any,is_filter:any){
    this.router.navigate(['/scheduled-reports/reports-setup/edit/'+id+'/'+is_filter]);
  }

  viewSchedule(id:any){
    if(this.permissions.v){
      this.showSidePanel          = true;

      this.reportService.scheduleReportGet(id).subscribe((res: any) => {
        this.viewScheduleData     = res;
        Object.entries(this.viewScheduleData).forEach(([key, value]) => {
          if(key == "report_scheduler_time"){
            this.viewScheduleData[key] = this.appservice.timeFormatDisplay(this.viewScheduleData[key]);
          }
          // if(key == "report_filter_saved"){
          //   this.addFilterViewData(res);
          // }
        })
        this.SidepanelScrollTop();
      })
    }
  }

  // addFilterViewData(data:any){
  //   for(let i=0;i<data.report_filter_saved.length;i++){
  //     if(data.report_filter_saved[i]['report__name'] == 'Investment request'){
  //       this.investView[0]['value']=data.report_filter_saved[i].data_filter.investment_type;

  //       this.investView[1]['value']=data.report_filter_saved[i].data_filter.requested_on;
  //     }else{

  //     }
  //   }
  // }

  getPermission(){
    this.permissions = this.appservice.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

   /********* DELETE SCHEDULES ****************/
   inactiveScheduleReport(id:any, Cnt:any){
    this.scheduleId               = id;
    if(Cnt == 1){
      this.deleteAlert            = true;
    }else if(Cnt == 2){
      this.deleteClicked          = true;
      this.reportService.inactivateScheduleReport(id, {'is_active': false}).subscribe((res: any) => {
        this.deleteClicked         = false;
        this.offset                = 0;
        this.activeCnt             = 0;
        this.inactiveCnt           = 0;
        this.activeSchedules       = [];
        this.inactiveSchedules     = [];
        this.schedules             = [];
        this.taskDetailsList       = [];
        this.showDataItems();
        this.deleteAlert           = false;
      });
    }
  }

   /********* INIFNITY SCROLL FUNCTION *******************/
   onScrollDown() {
    this.infinityloader           = true;
    this.offset                  += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader                 = false;
      this.direction              = "down";
    }else{
      this.infinityloader         = false
    }
  }

  reSheduleReport(id:any){
    this.reScheduleReportForm.reset();
    this.submitted                = false;
    this.scheduleId               = id;
    this.rescheduleAlert          = true;
  }

  get f()  { return this.reScheduleReportForm.controls; }

  submitReshedule(){
    this.submitted                 = true;
    if (this.reScheduleReportForm.invalid) {
      return;
    }
    this.saveClicked               = true;
    this.loader                    = true;
    let schedule_start_date        = this.appservice.dateFormatConvert(this.reScheduleReportForm.value.schedule_start_date);
    let schedule_end_date          = this.appservice.dateFormatConvert(this.reScheduleReportForm.value.schedule_end_date);

    this.reportService.reactivateScheduleReport(this.scheduleId,{'is_active':true,'schedule_start_date': schedule_start_date,'schedule_end_date': schedule_end_date}).subscribe((res:any)=>{
      this.loader                   = false;
      this.rescheduleAlert          = false;
      this.saveClicked              = false;
      this.offset                   = 0;
      this.activeCnt                = 0;
      this.inactiveCnt              = 0;
      this.activeSchedules          = [];
      this.inactiveSchedules        = [];
      this.schedules                = [];
      this.taskDetailsList          = [];
      this.showDataItems();
      this.router.navigate(['scheduled-reports']);
    });

  }

  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  createEmployeeDisplay(employee:any){
    let string:any = [];
    employee.forEach((item:any, key:any) => {
      string.push(item.first_name+(item.middle_name!=null && item.middle_name!=""?item.middle_name:"")+" "+item.last_name+" ("+item.employee_code+")");
    })
    return string.join(", ");
  }

  searchschedule(searchKeyword:any){
    this.loader            = true;
    this.offset            = 0;
    this.activeSchedules   = [];
    this.inactiveSchedules = [];
    this.searchKeyword     = searchKeyword;
    if(searchKeyword != ""){
      this.showDataItems();
    }
  }
  SidepanelScrollTop() {
    var list = document.getElementsByClassName('side-panel-body');
    for (var i = 0; i < list.length; i++) {
      list[i].scrollTo(0, 0)
    }
    this.loader1 = false
  }
  dateFilter(d: any){
    let dateRange = [new Date(),
      new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }


}
