import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttendanceRuleService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  getRuleList(isactive:any,limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-rule-list/?is_active=${isactive}&limit=${limit}&offset=${offset}&search=${search}`,this.httpOption)
  }
  getRuleDDList(isactive:any) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-rule-list/?is_active=${isactive}&ordering=attendance_rule_name`, this.httpOption)
  }
  getRuleDetail(id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-rule-detail/${id}/`,this.httpOption)
  }
  createRule(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-rule-create/`, body,this.httpOption)
  }
  updateRule(body: any,id:number){
    return this.http.put(`${this.API_PATH}attendance/api/v1/attendance-rule-detail/${id}/`, body, this.httpOption)
  }
  deleteRule(body: any,id:number){
    return this.http.patch(`${this.API_PATH}attendance/api/v1/attendance-rule-delete/${id}/`, body,this.httpOption)
  }
  getShiftMappingList(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping-list-dropdown/?is_active=true&ordering=shift_mapping_name`,this.httpOption)
  }
  getShiftDetail(id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-mapping-detail/${id}/`,this.httpOption)
  }

}
