<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="ctcSalaryStructureForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.ctc_breakup.errors}">
                    <div class="col-12 form-row">
                        <p class="text-uppercase required fw-500 mb-8">CTC breakup
                        </p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="ctc_breakup" type="radio" id="ctc_breakup1" class="form-check-input  "
                                formControlName="ctc_breakup" value="monthly"
                                (change)="radioBtnChangeFunctions($event,'ctc_breakup','monthly','','')">
                            <label for="ctc_breakup1" class="form-check-label">Monthly
                            </label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="ctc_breakup" type="radio" id="ctc_breakup2" class="form-check-input "
                                formControlName="ctc_breakup" value="yearly">
                            <label for="ctc_breakup2" class="form-check-label">Yearly</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="(submitted) && f.ctc_breakup.errors" class="invalid-feedback">
                    <div *ngIf="f.ctc_breakup.errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                </div>

                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase required fw-500 mb-0">Pay group
                        </p>
                    </div>
                    <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f.pay_group?.touched || f.pay_group?.dirty) &&  f.pay_group.errors}">
                        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Pay group')}}"
                        formControlName="pay_group" [items]="payGroupDD" [multiple]="true" [ngClass]="{ 'is-invalid': (submitted || f.pay_group?.touched || f.pay_group?.dirty) &&  f.pay_group.errors}"
                        groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                        bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="payLoader">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                [ngModelOptions]="{standalone: true}" /> Select All
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="d-inline-flex align-items-center">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                                {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                            </div>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!payLoader">
                            <div class="ng-value" *ngIf="ctcSalaryStructureForm.value.pay_group?.length ">
                                <span class="ng-value-label">{{ctcSalaryStructureForm.value.pay_group?.length }}
                                    pay group
                                    selected.</span>
                            </div>
                        </ng-template>
                        <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="payLoader">
                          <div class="loading-text">Loading...</div>
                        </ng-template>
                      </ng-select>
                      <ul class="selected-values mt-16" *ngIf="ctcSalaryStructureForm.value.pay_group?.length != 0 && !payLoader">
                          <li class="ng-value" *ngFor="let item of ctcSalaryStructureForm.value.pay_group,let ki =index">
                              <ng-container *ngFor="let insideitem of payGroupDD, let k =index">
                                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                      {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                      slice:0:15)+'..':(insideitem['name']) }}</span>
                              </ng-container>
                              <span class="ng-value-icon right"
                                  (click)="clearcommonForm('pay_group',ki)" aria-hidden="true">×</span>
                          </li>
                      </ul>
                    </div>
                    <div *ngIf="(submitted || f.pay_group?.touched || f.pay_group?.dirty) &&  f.pay_group.errors" class="invalid-feedback">
                      <div *ngIf="f.pay_group.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>
                </div>
                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Date of joining</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_joining" type="radio" id="date_of_joining1" class="form-check-input  "
                                formControlName="date_of_joining" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','last_month','doj_specific_month','doj_specific_year')">
                            <label for="date_of_joining1" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_joining" type="radio" id="date_of_joining2" class="form-check-input "
                                formControlName="date_of_joining" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining','this_month','doj_specific_month','doj_specific_year')">
                            <label for="date_of_joining2" class="form-check-label">This month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_joining" type="radio" id="date_of_joining3" class="form-check-input "
                                formControlName="date_of_joining" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'date_of_joining',true,'doj_specific_month','doj_specific_year')">
                            <label for="date_of_joining3" class="form-check-label">Specific month</label>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="ctcSalaryStructureForm.value.date_of_joining==true">
                        <div class="col-12 form-row">
                            <div class="input-group form-icon icon-right">
                                <input (click)="datePicker2.open()" class="form-control"
                                    placeholder="{{this.messageService.selectplaceholddisplay('Period')}}" matInput
                                    formControlName="doj_specific_month" [matDatepicker]="datePicker2"
                                    [matDatepickerFilter]="dojFilter">
                                <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                            </div>
                        </div>
                    </ng-container> -->

                    <ng-container *ngIf="ctcSalaryStructureForm.value.date_of_joining==true">
                      <div class="col-6">

                        <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_year?.touched || f?.doj_specific_year?.dirty) &&  f.doj_specific_year.errors}">
                            <label for="" class="form-label">Year </label>
                            <ng-select class="form-ngselect h-36 fs-16"
                            formControlName="doj_specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_year?.touched || f?.doj_specific_year?.dirty) &&  f.doj_specific_year.errors}">
                                <ng-option *ngFor="let yData of Years"
                                    [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="(submitted || f?.doj_specific_year?.touched || f?.doj_specific_year?.dirty)&& f?.doj_specific_year?.errors" class="invalid-feedback">
                            <div *ngIf="f?.doj_specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                        </div>
                      </div>
                      <div class="col-6">

                        <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_month?.touched || f?.doj_specific_month?.dirty) &&  f?.doj_specific_month?.errors}">
                            <label for="" class="form-label">Month </label>
                            <app-master-select class="config-app-master" style="width:9rem"
                            [placeholder]="month_placeholder"
                                    id="month" [isrequired]="false" [selectedItem]="ctcSalaryStructureForm.value.doj_specific_month"
                                    [type]="attendListOfMonth" formControlName="doj_specific_month" [(ngModel)]="f.doj_specific_month.value"
                                    (ngModelChange)="constructDate('doj_specific_month')" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_month?.touched || f?.doj_specific_month?.dirty) &&  f?.doj_specific_month?.errors}"> </app-master-select>
                        </div>
                        <div *ngIf="(submitted || f?.doj_specific_month?.touched || f?.doj_specific_month?.dirty)&& f?.doj_specific_month?.errors" class="invalid-feedback">
                            <div *ngIf="f?.doj_specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                        </div>
                      </div>

                  </ng-container>

                </div>

                <div class="row row-16 mb-16 ">
                    <p class="text-uppercase fw-500 mb-8">Date of leaving</p>
                    <div class="col-12 form-row">
                        <input name="date_of_leaving" type="radio" id="date_of_leaving1" class="form-check-input  "
                            formControlName="date_of_leaving" value="last_month"
                            (change)="radioBtnChangeFunctions($event,'date_of_leaving','last_month','dol_specific_month','dol_specific_year')">
                        <label for="date_of_leaving1" class="form-check-label">Last month</label>
                    </div>
                    <div class="col-12 form-row">
                        <input name="date_of_leaving" type="radio" id="date_of_leaving2" class="form-check-input "
                            formControlName="date_of_leaving" value="this_month"
                            (change)="radioBtnChangeFunctions($event,'date_of_leaving','this_month','dol_specific_month','dol_specific_year')">
                        <label for="date_of_leaving2" class="form-check-label">This month</label>
                    </div>
                    <div class="col-12 form-row">
                        <input name="date_of_leaving" type="radio" id="date_of_leaving3" class="form-check-input "
                            formControlName="date_of_leaving" [value]="true"
                            (change)="radioBtnChangeFunctions($event,'date_of_leaving',true,'dol_specific_month','dol_specific_year')">
                        <label for="date_of_leaving3" class="form-check-label">Specific month</label>
                    </div>
                    <!-- <ng-container *ngIf="ctcSalaryStructureForm.value.date_of_leaving==true">
                        <div class="input-group">
                            <input (click)="datePicker3.open()" class="form-control"
                                placeholder="{{this.messageService.selectplaceholddisplay('Period')}}" matInput
                                formControlName="dol_specific_month" [matDatepicker]="datePicker3">
                            <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
                        </div>
                    </ng-container> -->

                    <ng-container *ngIf="ctcSalaryStructureForm.value.date_of_leaving==true">
                      <div class="col-6">
                        <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_year?.touched || f?.dol_specific_year?.dirty) &&  f.dol_specific_year.errors}">
                            <label for="" class="form-label">Year </label>
                            <ng-select class="form-ngselect h-36 fs-16"
                            formControlName="dol_specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_year?.touched || f?.dol_specific_year?.dirty) &&  f.dol_specific_year.errors}">
                                <ng-option *ngFor="let yData of Years"
                                    [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="(submitted || f?.dol_specific_year?.touched || f?.dol_specific_year?.dirty)&& f?.dol_specific_year?.errors" class="invalid-feedback">
                            <div *ngIf="f?.dol_specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                        </div>
                      </div>
                      <div class="col-6">

                        <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_month?.touched || f?.dol_specific_month?.dirty) &&  f?.dol_specific_month?.errors}">
                            <label for="" class="form-label">Month </label>
                            <app-master-select class="config-app-master" style="width:9rem"
                            [placeholder]="month_placeholder"
                                    id="month" [isrequired]="false" [selectedItem]="ctcSalaryStructureForm.value.dol_specific_month"
                                    [type]="attendListOfMonth" formControlName="dol_specific_month" [(ngModel)]="f.dol_specific_month.value"
                                    (ngModelChange)="constructDate('dol_specific_month')" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_month?.touched || f?.dol_specific_month?.dirty) &&  f?.dol_specific_month?.errors}"> </app-master-select>
                        </div>
                        <div *ngIf="(submitted || f?.dol_specific_month?.touched || f?.dol_specific_month?.dirty)&& f?.dol_specific_month?.errors" class="invalid-feedback">
                            <div *ngIf="f?.dol_specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                        </div>
                      </div>

                  </ng-container>

                </div>
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitCtc()">
                Apply
            </button>
        </footer>
    </form>
</div>
