import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { LeaveYearEndService } from 'src/app/@core/services/leave-year-end.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-leave-year-end-details',
  templateUrl: './leave-year-end-details.component.html',
  styleUrls: ['./leave-year-end-details.component.scss']
})
export class LeaveYearEndDetailsComponent implements OnInit {

  policy_id       : any
  year            : any
  loader          : boolean = false
  filterPanel     : boolean = false
  viewData        : any     = []
  searchString    : any     = ''
  from            : any     = 'leave_year_end'
  filterData      : any     = ''
  permissions     : any     = [];
  enCashError     = ''
  carryError      = ''

  // Page
  page            = 1
  pageSize        = 20

  // Form
  companyList:any = [];
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];

  formData : any

  constructor(
    private route : ActivatedRoute,
    private leaveServ : LeaveYearEndService,
    public appServ : AppService,
    private fb : FormBuilder,
    private cd: ChangeDetectorRef,
    public messageService : MessageService
  ) { }

  ngOnInit(): void {
    // // Memorize
    // let arrFilter: any = []
    // let arrList = []
    // let arrData = []
    // if(this.leaveServ.INNERDATA != undefined){
    //   this.filterData = this.leaveServ.INNERDATA
    //   arrData = this.filterData.split("&")
    //   arrData.forEach((element: any) => {
    //     arrList = element.split('=')
    //     arrFilter[arrList[0]] =arrList[1]
    //   })
    //   this.formData = this.appServ.memFilterArray5(arrFilter)
    // }
    // Getting Policy ID
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id']) && !isNaN(params['year'])){
        this.policy_id = +params['id'];
        this.year = +params['year']
        this.getViewDownloadData()
      }
    })
    // Permissions
    this.getPermission()
  }

  // Main API
  getViewDownloadData(){
    this.loader = true
    this.viewData = []
    if(this.filterData == ''){
      this.leaveServ.getViewAndDownloadData(this.policy_id,this.year).subscribe((res:any)=>{
        this.arrangeAPIData(res.body)
        this.loader = false
      })
    }else{
      this.leaveServ.getFilterData(this.policy_id,this.year,this.filterData).subscribe((res:any)=>{
        this.arrangeAPIData(res.body)
        this.loader = false
      })
    }
  }


  // CheckAll
  checkAll(value:any){
    let newArr = this.appServ.filterIt(this.viewData,this.searchString)
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = newArr.slice(start,end)
    slicedArray.forEach((item:any)=>{
      item.checked = value.target.checked
    })
  }

  // Checked Property
  pageChange(){
    let newArr = this.appServ.filterIt(this.viewData,this.searchString)
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = newArr.slice(start,end)
    if(slicedArray.length){
      return slicedArray.every((item:any)=>{
        return item.checked
      })
    }else{
      return false;
    }
  }

  // Number Validation
  notanumber(val:any){
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }

  arrangeAPIData(res:any){
    this.viewData = [];
    for (let i = 0; i < res.length; i++) {
      let newObj :any = {};
      for (let key in res[i]) {
          if (res[i].hasOwnProperty(key)) {
              newObj[key] = {
                  "data": res[i][key],
                  "is_valid": true,
                  "error": ""
              };
          }
      }
      this.viewData.push(newObj);
    }
  }

  // Save
  saveSelected(){
    let list = this.getWholeData()
    let dataArray = list.map((item:any)=>{
      return {
        id : item.id.data,
        carry_forward_leave_balance : item.carry_forward_leave_balance.data,
        encashment_leave_balance : item.encashment_leave_balance.data
      }
    })
    this.leaveServ.editFieldViewDownload(dataArray).subscribe((res:any)=>{
      this.getViewDownloadData()
    })
  }

  countInvalidElements() {
    let count = 0;

    for (const item of this.viewData) {
      if(item?.checked){
        if (!item.carry_forward_leave_balance.is_valid) {
          count++;
        }

        if (!item.encashment_leave_balance.is_valid) {
          count++;
        }
      }
    }
    return count;
  }

  // Filter
  closeFilter(){
    this.filterPanel = false;
  }

  submitForm(data:any){
    this.filterPanel  = false
    this.companyList = data.companyList.join(',')
    this.buList = data.buList.join(',')
    this.DepList = data.DepList.join(',')
    this.DesignationList = data.DesignationList.join(',')
    this.gradeList = data.gradeList.join(',')
    this.BranchList = data.BranchList.join(',')
    this.filterData = `employee_leave__employee__company__in=${this.companyList}&employee_leave__employee__businessunit__in=${this.buList}&employee_leave__employee__department__in=${this.DepList}&employee_leave__employee__designation__in=${this.DesignationList}&employee_leave__employee__branch__in=${this.BranchList}&employee_leave__employee__grade__in=${this.gradeList}`
    this.leaveServ.setInnerData(this.filterData)
    this.getViewDownloadData()
  }

  resetFilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.filterData = ''
    this.leaveServ.setInnerData(null)
    this.getViewDownloadData()
    this.filterPanel  = false
  }

  // Whole Checked data
  getWholeData(){
    return this.viewData.filter((item:any)=> item?.checked == true && item?.status?.data != 'Completed')
  }

 trackChange :  boolean = false
  validateData(i:any,control:any){
   let index = 20*(this.page-1)+i
   let currentValue = this.viewData[index][control].data
   const digitdecimalpattern   = /^[0-9]\d*(\.\d{1,2})?$/.test(currentValue);
   this.viewData[index][control].is_valid = true
    this.viewData[index][control].error = null
   if(currentValue == '' || currentValue == null){
    this.viewData[index][control].is_valid = false
    this.viewData[index][control].error = this.messageService.validationDisplay('required')
   }else{
    if(digitdecimalpattern){
      let encash_balance = Number(this.viewData[index]['encashment_leave_balance'].data)
      let carry_balance = Number(this.viewData[index]['carry_forward_leave_balance'].data)
      let total_check = (encash_balance + carry_balance) > Number(this.viewData[index]['balance'].data)
      if(control == 'encashment_leave_balance'){
        if(Number(this.viewData[index][control].data> this.viewData[index]['encashment_leave_limit'].data)){
          this.viewData[index][control].is_valid = false
          this.viewData[index][control].error = this.messageService.lessThanMessage('Leave Encashed Limit','Leave Encashed')
        }
        if(total_check && !this.trackChange){
          this.trackChange = true
        }
        if(this.trackChange){
          this.calcDiff(index,control,'carry_forward_leave_balance')
        }
      }else{
        if(Number(this.viewData[index][control].data> this.viewData[index]['carry_forward_limit'].data)){
          this.viewData[index][control].is_valid = false
          this.viewData[index][control].error = this.messageService.lessThanMessage('Carry Forward Limit','Carry Forward Leave Balance')
        }
        if(total_check && !this.trackChange){
          this.trackChange = true
        }
        if(this.trackChange){
          this.calcDiff(index,control,'encashment_leave_balance')
        }
      }
    }else{
      this.viewData[index][control].is_valid  = false;
        this.viewData[index][control].error   = this.messageService.validationDisplay('pattern');
    }
   }
  }

  calcDiff(index:any,control:any,updateControl:any){
    let updateBalance = Number(this.viewData[index]['balance'].data) - Number(this.viewData[index][control].data)
    this.viewData[index][updateControl].data = (updateBalance <= 0 ? 0 : updateBalance)
  }

  // Export
  export(){
    let exportData : any =[]
    if (this.viewData.length === 0) {
      exportData.push({
        'Sl No.': '',
        'Emp Code': '',
        'Emp Name': '',
        'Company': '',
        'Business Unit': '',
        'Leave Code': '',
        'Leave Balance Available': '',
        'Leave Encashment Limit': '',
        'Leave Encashed': '',
        'Carry Forward Limit': '',
        'Carry Forward Leave Balance': '',
        'Lapsed Leave Balance': '',
        'Year End Status': '',
      });
    } else {
      for(let i=0;i< this.viewData.length;i++){
        exportData.push({
          'Sl No.' : i+1,
          'Emp Code' : this.viewData[i]['employee_code'].data,
          'Emp Name' :  this.viewData[i]['employee_name'].data,
          'Company' : this.viewData[i]['company_name'].data,
          'Businees Unit' : this.viewData[i]['bu_name'].data,
          'Leave Code' : this.viewData[i]['leave_code'].data,
          'Leave Balance Available' : this.viewData[i]['balance'].data,
          'Leave Encashment Limit' : this.viewData[i]['encashment_leave_limit'].data,
          'Leave Encashed' :  this.viewData[i]['encashment_leave_balance'].data,
          'Carry Forward Limit' : this.viewData[i]['carry_forward_limit'].data,
          'Carry Forward Leave Balance' : this.viewData[i]['carry_forward_leave_balance'].data,
          'Lapsed Leave Balance' : this.viewData[i]['lapsed_leave_balance'].data,
          'Year End Status' : this.viewData[i]['status'].data,
        })
      }
    }
    this.appServ.exportExcel(exportData,'Leave Year End Details')
    this.getViewDownloadData()
  }

  // Permission
  getPermission(){
    this.permissions = this.appServ.getPermissions('/leave-year-end');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
