<div class="row row-16">
  <div class="col-12">
    <div class="mb-0 fw-500">CHECK IN REQUEST</div>
  </div>
  <div class="col-12">
    <div class="card card-c2 p-0">
      <div class="table-responsive radius-4">
        <table
          class="table td-12 sticky-header td-white-space td-fs-14 tr-fs-14 table-striped table-sm form-table"
        >
          <thead>
            <tr>
              <th class="fw-600" scope="col">Punch Date</th>
              <th class="fw-600" scope="col">Punch Time</th>
              <th class="fw-600" scope="col">source</th>
              <th class="fw-600" scope="col">location</th>
              <th class="fw-600" scope="col">coordinates</th>
              <th class="fw-600" scope="col">status</th>
              <th class="fw-600" scope="col">view map</th>
              <th class="fw-600" scope="col">view activity</th>
            </tr>
          </thead>
          <tbody class="cell-16 height-full">
            <tr *ngIf="this.appService.filterIt(viewSpecData, '').length <= 0">
              <td colspan="9" class="bg-white">
                <div class="card card-c2 flex-center p-16 bg-dark-500">
                  <span class="fw-500 fs-14">No data found</span>
                </div>
              </td>
            </tr>
            <tr
              *ngFor="
                let request of $any(
                  viewSpecData | slice : (page - 1) * pageSize : page * pageSize
                );
                let ind = index
              "
            >
              <td
                data-toggle="tooltip"
                title="{{
                  this.appService.dateFormatDisplay(request?.punch_date)
                }}"
              >
                {{ this.appService.dateFormatDisplay(request?.punch_date) }}
              </td>
              <td
                data-toggle="tooltip"
                title="{{
                  this.appService.timeFormatDisplay(request?.punch_time)
                }}"
              >
                {{ this.appService.timeFormatDisplay(request?.punch_time) }}
              </td>
              <td data-toggle="tooltip" title="{{ request?.punch_type }}">
                {{ request?.punch_type }}
              </td>
              <td data-toggle="tooltip" title="{{ request?.location }}">
                {{ request?.location }}
              </td>
              <td
                data-toggle="tooltip"
                title="{{ request?.latitude + ', ' + request?.longitude }}"
              >
                {{ request?.latitude + ", " + request?.longitude }}
              </td>
              <td data-toggle="tooltip" title="{{ request?.status }}">
                <div class="hstack gap-8 align-items-center">
                  <span
                    *ngIf="request?.status == 'Pending'"
                    class="badge badge-warning fs-14 px-16 py-1"
                    >Pending</span
                  >
                  <span
                    *ngIf="request?.status == 'Accepted'"
                    class="badge badge-success fs-14 px-16 py-1"
                    >Accepted</span
                  >
                  <span
                    *ngIf="request?.status == 'Rejected'"
                    class="badge badge-danger fs-14 px-16 py-1"
                    >Rejected</span
                  >
                  <span
                    *ngIf="request.status == 'Cancelled'"
                    class="badge badge-secondary fs-14 px-16 py-1"
                    >Cancelled</span
                  >
                </div>
              </td>
              <td
                class="text-center"
                (click)="
                  showMap(request);
                  viewMapPanel = true
                "
              >
                <i class="icon-eye fs-18 link-primary"></i>
              </td>
              <td class="text-center" (click)="statusData(request?.id)">
                <i class="icon-eye fs-18 link-primary"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="col-12 mt-2"
      *ngIf="this.appService.filteredcount(viewSpecData, '') > pageSize"
    >
      <ngb-pagination
        class="d-flex justify-content-end"
        [(page)]="page"
        [collectionSize]="this.appService.filteredcount(viewSpecData, '')"
        [maxSize]="3"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
        [pageSize]="pageSize"
      ></ngb-pagination>
    </div>
  </div>
</div>
<app-request-location-view [request]="request" [profile]="this.viewData?.from_person" [viewMapPanel]="viewMapPanel"  (closeEmitter)="viewMapPanel=false"></app-request-location-view>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div
    class="side-panel"
    style="--sidepanel-width: 32.1875rem"
    [class.side-pane-active]="viewstatusPanel === true"
  >
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"
          ><i class="icon-close-lg fs-16"></i
        ></a>
      </header>

      <app-view-status
        [loader]="statusLoader"
        [approvalRequired]="true"
        [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt"
        [viewStatusmaxLevel]="viewStatusmaxLevel"
      ></app-view-status>
    </form>
  </div>
</ng-container>
<!-- end view status panel -->
