import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-employee-tags',
  templateUrl: './employee-tags.component.html',
  styleUrls: ['./employee-tags.component.scss']
})
export class EmployeeTagsComponent implements OnInit {

  @Input() key  : any;
  @Input() list: any;
  @Input() title: any;
  constructor(public appservice:AppService) { }

  ngOnInit(): void {
  }

  tagMultiDataFunction(){

  }
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

}
