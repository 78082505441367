import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  showSidePanel = false;
  selectedList:any = [];
  selectedName="";
  reminders:any = []
  company:any;
  infoClosed = false;
  year:any;
  month:any;
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  loader =true;
  constructor(public route:ActivatedRoute,private sds:SalaryDashboardService,public appService: AppService,) { }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })

    var dataArray = {
      "year": Number(this.year),
      "month": this.month
   };
    this.sds.getReminders( Number(this.year),this.months[this.month],this.company).subscribe((res:any)=>{
      // console.log("Myresult",res)
      this.createRemiderArray( res.body)
    });
    this.sds.getalerts( Number(this.year),this.months[this.month],this.company).subscribe((res:any)=>{
      // console.log(res)
    });
  }
  createRemiderArray(data:any){
    // console.log("data",data);
    if(data.hold_list != undefined){
      this.reminders.push({
        reminder:"Salary on hold",
        list:data.hold_list[0].employees,
        class:this.getRandomClass()

      })

    }
    if(data.bank_list != undefined){
      this.reminders.push({
        reminder:"Bank details missing",
        list:data.bank_list[0].employees,
        class:this.getRandomClass()
      })
    }
    if(data.pan_list != undefined){
      this.reminders.push({
        reminder:"Pan card details missing",
        list:data.pan_list[0].employees,
        class:this.getRandomClass()
      })
    }
    if(data.proposed_list != undefined){
      this.reminders.push({
        reminder:"Pending proposed investment declaration",
        list:data.proposed_list[0].employees,
        class:this.getRandomClass()
      })
    }
    if(data.confirmed_list != undefined){
      this.reminders.push({
        reminder:"Pending confirmed investment declaration",
        list:data.confirmed_list[0].employees,
        class:this.getRandomClass()
      })
    }
    if(data.fbp_list != undefined){
      this.reminders.push({
        reminder:"Pending FBP declaration",
        list:data.fbp_list[0].employees,
        class:this.getRandomClass()
      })
    }
    if(data.overtime_list != undefined){
      this.reminders.push({
        reminder:"Overtime component missing",
        list:data.overtime_list[0].employees,
        class:this.getRandomClass()
      })
    }
    if(data.pending_revision_list != undefined){
      this.reminders.push({
        reminder:"Pending salary revision approval",
        list:data.pending_revision_list[0].employees,
        class:this.getRandomClass()
      })
    }

    this.loader = false;
    // let len = this.reminders.length;
    // for (let i = 0; i < len; i++) {

    //   for (let u = 0; u < this.reminders[i]['list'].length; u++) {
    //       this.reminders[i]['list'][u].fullname = "";

    //       if(this.reminders[i]['list'][u].first_name != null)
    //        this.reminders[i]['list'][u].fullname += this.reminders[i]['list'][u].first_name+" ";
    //       if(this.reminders[i]['list'][u].middle_name != null)
    //        this.reminders[i]['list'][u].fullname += this.reminders[i]['list'][u].middle_name+" ";
    //       if(this.reminders[i]['list'][u].last_name != null)
    //        this.reminders[i]['list'][u].fullname += this.reminders[i]['list'][u].last_name;
    //   }
    //       }

  }

  getRandomClass(){
    const classes = ["highlight1", "highlight2", "highlight3", "highlight-primary"];
    const random = Math.floor(Math.random() * classes.length);
    return classes[random];
  }

  closePanel(val:any){
    this.showSidePanel = val
  }
}
