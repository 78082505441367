import { Component, OnInit,ViewChild } from '@angular/core';
import { ManageAdvanceService } from 'src/app/@core/services/manage-advance.service';
import { AppService } from 'src/app/app.global';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, Validators } from '@angular/forms';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
@Component({
  selector: 'app-manage-advance',
  templateUrl: './manage-advance.component.html',
  styleUrls: ['./manage-advance.component.scss']
})
export class ManageAdvanceComponent implements OnInit {
  viewAdvance = false;
  viewdetailsoutkey = false;
  navActive = 'Outstanding Advance';
  outstandingdata:any=[];
  loader = true;
  nodata = false;
  permissions: any=[];
  page = 1;
  listlength = 0;
  pageSize: any = 20;
  searchKeyword ='';
  detailsid:any;
  dispensedatakey:any='';
  searchlistdata:any=[];
  searchkey = this.messageService.searchdisplay('');
  tabkey='Outstanding';
  filter = false;
  filterscroll = false;
  filterdata: any = '';
  filterForm = this.formBuilder.group({
    company: '',
    bu: '',
    department: '',
    designation: '',
    grade: '',
    emp_status: '',
    emp_type: '',
    branch: '',
    repomanager: '',
    funmanager: '',
    sortby: ['All'],
    expense_date_from:'',
    expense_date_to:''
  });
  from = 'advancehr';
  viewdata:any=[]
  viewLoader = false;
  currency:any;
  title='View and track the outstanding advance details of your employees here.';
  @ViewChild(SearchbarComponent) child:any;
  tabchange=false;
  sLoading= false;

  constructor(
    public ManageAdvanceService : ManageAdvanceService,
    public appService: AppService,
    public messageService: MessageService,
    public employeeService: EmployeeService,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.listdata(this.tabkey);
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/manage-advance');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // searchlistfn(res:any){
  //   for(let i=0; i< res.length; i++){
  //     if(res[i]['first_name'] != null)
  //     res[i]['name'] = res[i]['first_name']+" ";
  //     if(res[i]['middle_name'] != null)
  //     res[i]['name'] += res[i]['middle_name']+" ";
  //     if(res[i]['last_name'] != null)
  //     res[i]['name']   += res[i]['last_name'];
  //     res[i]['name']   +=" ("+res[i]['employee_code']+")";
  //     res[i]['image']   = res[i]['profile_image'];  
  //   }  
  //   this.searchlistdata = res;
  // }

  getoutstandinglistData(){
    this.ManageAdvanceService.getoutstandinglistData(this.searchKeyword,this.filterdata).subscribe((res: any) => {
      this.outstandingdata=[];
      this.listlength = res?.length;​
      if (res?.length == 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
      for (let i = 0; i < this.listlength; ++i) {        
        this.outstandingdata.push(res[i]);
      }
      this.loader = false;
      this.sLoading  = false;
    }); 
    // this.ManageAdvanceService.getSearchListoutstanding(this.searchKeyword,this.filterdata).subscribe((res: any) => {
    //    this.searchlistdata=[];
    //    this.searchlistfn(res);
    // });

  }

  getadvancedata(){    
    this.loader = true;
    this.ManageAdvanceService.getadvancerequestlist(this.dispensedatakey,this.searchKeyword,this.filterdata).subscribe((res: any) => {
      this.outstandingdata=[];
      this.listlength = res?.length;​
      if (res?.length == 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
      for (let i = 0; i < this.listlength; ++i) {        
        this.outstandingdata.push(res[i]);
      }
      this.loader = false;
      this.sLoading  = false;
     });
     
    //  this.ManageAdvanceService.getSearchListrequest(this.dispensedatakey,this.searchKeyword,this.filterdata).subscribe((res: any) => {
    //  this.searchlistdata=[];
    // // for(let i=0; i< res.length; i++){        
    //   //   res[i]['name'] = res[i]['number'];        
    //   // }  
    //   // this.searchlistdata = res;
    //   this.searchlistfn(res);
    // });
  }

  listdata(value:any){
    if(this.tabchange==true && this.searchKeyword !=''){
      this.searchKeyword='';
      this.child.clearBar();
    }
    this.tabkey=value;
    this.page = 1;
    if(value=='Outstanding'){
      this.loader = true;
      this.title='View and track the outstanding advance details of your employees here.';
      this.getoutstandinglistData();
    }else if(value=='Advance'){
      this.dispensedatakey = 'False';
      this.title='Review employee advance requests, check status, and send reminders for pending actions.';
      this.getadvancedata();
    }else if(value=='Dispense'){
      this.title='View the approved and pending advance disbursements and send reminders for quick action.';
      this.dispensedatakey = 'True';
      this.getadvancedata();
    }
    this.tabchange=false;
  }

  searchresults(data: any) {
    this.loader = true
    this.outstandingdata = [];
    if (data == '') {
      this.searchKeyword = ""
      this.page = 1;
    } else {
      this.page = 1
      for(let i=0; i< this.searchlistdata.length; i++){
        if(this.searchlistdata[i].id==data){     
        this.searchKeyword = this.searchlistdata[i].employee_code;
        }
      }      
    }
    this.listdata(this.tabkey);
  }

  searchemp(keyWord: any) {
    this.sLoading  = true;
    this.loader   = true
    this.outstandingdata = [];
    this.searchKeyword = keyWord;
    if (this.searchKeyword != "") {
      this.page = 1;
      this.listdata(this.tabkey);
    } else {
      this.page =1;
    }
  }

  filterclick() {
    this.filter = true;
  }

  resultfilterfn(data: any) {
    this.page = 1; 
    this.filterdata = data;
    this.child.clearBar();
    this.applyfilterfn();
  }
​
  closefn(daata: any) {
    this.filter = daata;
  }
​
  resetCalled(e: any) {
    this.filter = false;
  }
​
  applyfilterfn() {
    this.loader = true;
    this.nodata = false;
    this.filter = false;
  }

  viewAdvancefn(id:any,currency:any){   
    this.currency=currency;
    this.viewLoader = true;
    if(this.tabkey=='Outstanding'){
      this.viewdetailsoutkey = true;
      this.ManageAdvanceService.getadvanceemp(id).subscribe((res: any) => {
        this.viewdata = res;
        this.viewLoader = false;
     });
    }else{
      this.viewAdvance = true;
      this.detailsid=id;
      this.ManageAdvanceService.getadvance(id).subscribe((res: any) => {
        if(this.tabkey=='Dispense'){
          if(res?.expense_advance?.dispense_advance==true){
            res.expense_advance['status']='Accepted';
          }else{
            res.expense_advance['status']='Pending';
          }
        }else{
          res.expense_advance['status']=res.status;
        }
        res.expense_advance['advanceid']=res.id;
        this.viewdata = res.expense_advance;
        this.viewLoader = false;
     });
    }
    
  }

  closefnview(daata:any){
    this.viewAdvance = daata.viewAdvance;   
  }

  closeoutstandview(daata:any){
    this.viewdetailsoutkey = daata;
  }

}
