import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-short-leave-type',
  templateUrl: './short-leave-type.component.html',
  styleUrls: ['./short-leave-type.component.scss']
})
export class ShortLeaveTypeComponent implements OnInit {

  // Var Initialization
  value               : any
  id                  : any
  private subjectKeyUp          = new Subject<any>();
  shortUnit           : any     = [];
  saveBtnClicked      : boolean = false
  limitDropdown                 = ['Week','Month']
  lastChangedTotalControl  : string  = '';
  lastChangedMaxControl    : string  = '';

  // Inputs from Parent
  @Input() shortLimit : any
  @Input() disabled   : any
  @Input() formGp     : any
  @Input() editFlag   : any

  // Output Emit
  @Output() submitMethod = new EventEmitter()

  constructor(
    public msgServ    : MessageService,
    public existDataService :  ExistDatasService,
    public route      : ActivatedRoute,
    private cd        : ChangeDetectorRef
  ) { }

  get f(){ return this.formGp.controls; }

  ngOnInit(): void {
    // Call for unique name
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    this.id = this.route.snapshot.params['id']
    this.formGp.get('totalHours')?.valueChanges.subscribe(() => {
      this.lastChangedTotalControl = 'totalHours';
    });

    this.formGp.get('totalMinutes')?.valueChanges.subscribe(() => {
      this.lastChangedTotalControl = 'totalMinutes';
    });

    this.formGp.get('maxHours')?.valueChanges.subscribe(() => {
      this.lastChangedMaxControl = 'maxHours';
    });

    this.formGp.get('maxMinutes')?.valueChanges.subscribe(() => {
      this.lastChangedMaxControl = 'maxMinutes';
    });
  }

  notanumber(val:any){
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editFlag){
      this.value ={
        "page":"shortLeavePolicy",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
      this.value ={
        "id":this.id,
        "page":"shortLeavePolicy",
        "field":"name",
        "value": this.f.name.value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.shortUnit = res.body
      if(this.shortUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  // Step 1 Submit
  submitForm(){
    this.saveBtnClicked=true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitMethod.emit(2);
  }

  // Greater time check on Min & Max in one request fields
  greaterValue(){
    let min = this.f.min_short_leave_hours_in_request.value
    let max = this.f.max_short_leave_hours_in_request.value
    if(moment(min).isAfter(moment(max))){
      this.formGp.patchValue({
        max_short_leave_hours_in_request : null,
        total_short_leave_hours : null
      })
    }
  }

  // Total Hours Field Setup
  formatFieldTime() {
    let input = this.f.total_short_leave_hours.value.replace(/[^\d]/g, '');
    if (input.length > 1) {
      input = input.slice(0, 2) + ':' + input.slice(2);
    }
    this.formGp.patchValue({
      total_short_leave_hours : input.slice(0, 5)
    })
  }

  // Clearing data on change of Backdated Field
  clearFormData(){
    this.formGp.patchValue({
      backdated_days : null
    })
    this.formGp.get('backdated_type').setValue(true)
    this.formGp.get('backdated_days')?.clearValidators();
    this.formGp.get('backdated_days')?.updateValueAndValidity();
  }

  // Required for Day Field on selection
  setRequired(isTrue: any){
    this.formGp.patchValue({
      backdated_days : null
    })
    if(isTrue){
      this.formGp.get('backdated_days')?.setValidators([Validators.required,Validators.min(1),Validators.max(365)]);
    }else{
      this.formGp.get('backdated_days')?.clearValidators();
    }
    this.formGp.get('backdated_days')?.updateValueAndValidity();
  }

  // Fn for multiplying the time with no of times
  multiplyTime(originalTime:any, factor:any) {
    const [hours, minutes] = originalTime.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    const multipliedMinutes = totalMinutes * factor;
    const multipliedHours = Math.floor(multipliedMinutes / 60);
    const multipliedRemainingMinutes = multipliedMinutes % 60;
    return `${multipliedHours.toString().padStart(2, '0')}:${multipliedRemainingMinutes.toString().padStart(2, '0')}`;
  }

  // Validations as per DOC for Total hours
  checkValidations(){
    let check = false
    let short_leave_limit = this.formGp.get('short_leave_limit_type')?.value
    let max_times = Number(this.formGp.get('max_no_leave_apply_times')?.value)
    let minHours = this.formGp.get('minHours')?.value
    let minMinutes = this.formGp.get('minMinutes')?.value
    let maxHours = this.formGp.get('maxHours')?.value
    let maxMinutes = this.formGp.get('maxMinutes')?.value
    let totalHours = this.formGp.get('totalHours')?.value
    let totalMinutes = this.formGp.get('totalMinutes')?.value
    this.lastChangedTotalControl = this.lastChangedTotalControl == '' ? 'totalHours' : this.lastChangedTotalControl
   if( short_leave_limit !== null &&
    (max_times !== null && max_times !== 0) &&
    minHours !== null && minHours !== "" &&
    minMinutes !== null && minMinutes !== "" &&
    maxHours !== null && maxHours !== "" &&
    maxMinutes !== null && maxMinutes !== "" &&
    totalHours !== null && totalHours !== "" &&
    totalMinutes !== null && totalMinutes !== "" &&
    !isNaN(parseFloat(minHours)) &&
    !isNaN(parseFloat(minMinutes)) &&
    !isNaN(parseFloat(maxHours)) &&
    !isNaN(parseFloat(maxMinutes)) &&
    !isNaN(parseFloat(totalHours)) &&
    !isNaN(parseFloat(totalMinutes)) &&
    !isNaN(max_times) &&
    totalHours.length <= 3 && totalMinutes.length <=2 &&
    this.lastChangedTotalControl !== ''){
      // Value can be 1:0 or 01:0 or 01:00 so need to setup any format to hh:mm
      const minTotalMinutes = `${minHours.padStart(2, '0')}:${minMinutes.padStart(2, '0')}`
      const maxTotalMinutes = `${maxHours.padStart(2, '0')}:${maxMinutes.padStart(2, '0')}`
      const totalMinutesHour = `${totalHours.padStart(2, '0')}:${totalMinutes.padStart(2, '0')}`
      const minMultiplied = this.multiplyTime(minTotalMinutes, max_times);
      const maxMultiplied = this.multiplyTime(maxTotalMinutes, max_times);
      const parseToMoment = (input:any) => input.includes(':') ? moment.duration(input) : moment(input, 'HH:mm');
      // Convert inputs to moment objects
      const totalMoment = parseToMoment(totalMinutesHour);
      const maxMoment = parseToMoment(maxMultiplied);
      const minMoment = parseToMoment(minMultiplied);
      // Compare the moment objects
      const totalMinutesMoment = moment.isDuration(totalMoment) ? totalMoment.asMinutes() : totalMoment.minutes();
      const maxMinutesMoment = moment.isDuration(maxMoment) ? maxMoment.asMinutes() : maxMoment.minutes();
      const minMinutesMoment = moment.isDuration(minMoment) ? minMoment.asMinutes() : minMoment.minutes();
      const errorExists = totalMinutesMoment > maxMinutesMoment || totalMinutesMoment < minMinutesMoment;
      const errorType = totalMinutesMoment > maxMinutesMoment ? 'maxValidation' : 'minValidation';
      if (errorExists) {
        this.formGp.get(this.lastChangedTotalControl)?.setErrors({
          [errorType]: true
        });
        check = true;
        this.formGp.get(this.lastChangedTotalControl)?.markAsTouched();
      }else {
        this.clearErrors('totalHours',['maxValidation'])
        this.clearErrors('totalMinutes',['maxValidation'])
        this.clearErrors('totalHours',['minValidation'])
        this.clearErrors('totalMinutes',['minValidation'])
      }
    }
    return check
  }

  // 4 hrs max on both min & max fields
  maxLimit(contrlOne:any,contrlTwo:any,cnrtl:boolean){
    let firstContrl = this.formGp.get(contrlOne)?.value
    let secContrl = this.formGp.get(contrlTwo)?.value
    if(firstContrl != null && secContrl != null && firstContrl.toString().length <= 2 && secContrl.toString().length <= 2 && !isNaN(firstContrl) && !isNaN(secContrl)){
      let fsCtrl =  Number(firstContrl)
      let secCtrl = Number(secContrl)
      fsCtrl += Math.floor(secCtrl / 60);
      secCtrl %= 60;
      let formattedMinutes = secCtrl < 10 ? '0' + secCtrl : secCtrl;
      let formattedHours = fsCtrl < 10 ? '0' + fsCtrl : fsCtrl;
      let clubbedVal = `${formattedHours}:${formattedMinutes}`;
      let control = cnrtl ? contrlOne : contrlTwo
      const [hourPart, minutePart] = clubbedVal.split(':').map(part => parseInt(part));
      if (!isNaN(hourPart) && !isNaN(minutePart) && (hourPart > 4 || (hourPart === 4 && minutePart > 0))) {
        this.formGp.get(control).setErrors({
          'maxValue' : true
        })
      }else{
        this.clearErrors(contrlOne,['maxValue'])
        this.clearErrors(contrlTwo,['maxValue'])
      }
      this.cd.detectChanges();
    }
  }

  // Max value to be greater than min
  validateMinMax() {
    let minHours = this.formGp.get('minHours')?.value
    let minMinutes = this.formGp.get('minMinutes')?.value
    let maxHours = this.formGp.get('maxHours')?.value
    let maxMinutes = this.formGp.get('maxMinutes')?.value
    if(minHours != null && minMinutes != null && maxHours != null && maxMinutes != null && !isNaN(parseFloat(minHours)) &&
    !isNaN(parseFloat(minMinutes)) && !isNaN(parseFloat(maxHours)) &&
    !isNaN(parseFloat(maxMinutes)) && this.lastChangedMaxControl != '' && minHours.length <=2 && minMinutes.length <=2 && maxHours.length <=2 && maxMinutes.length <=2){
      const minTotalMinutes = `${minHours.padStart(2, '0')}:${minMinutes.padStart(2, '0')}`
      const maxTotalMinutes = `${maxHours.padStart(2, '0')}:${maxMinutes.padStart(2, '0')}`
      if (moment(maxTotalMinutes,'HH:mm').isBefore(moment(minTotalMinutes,'HH:mm'))) {
        this.formGp.get(this.lastChangedMaxControl)?.setErrors({ 'minMaxError': true });
        this.formGp.get(this.lastChangedMaxControl).markAsTouched()
      }
      else{
        this.clearErrors('maxHours',['minMaxError'])
        this.clearErrors('maxMinutes',['minMaxError'])
      }
      this.cd.detectChanges();
    }
  }

  // Max value to be greater than min
  validateMaxTotal() {
    let maxHours = this.formGp.get('maxHours')?.value
    let maxMinutes = this.formGp.get('maxMinutes')?.value
    let totalHours = this.formGp.get('totalHours')?.value
    let totalMinutes = this.formGp.get('totalMinutes')?.value
    if(totalHours != null && totalMinutes != null && maxHours != null && maxMinutes != null && !isNaN(parseFloat(totalHours)) &&
    !isNaN(parseFloat(totalMinutes)) && !isNaN(parseFloat(maxHours)) &&
    !isNaN(parseFloat(maxMinutes)) && this.lastChangedTotalControl != '' && totalHours.length <=3 && totalMinutes.length <=2 && maxHours.length <=2 && maxMinutes.length <=2){
      const maxDuration = `${maxHours.padStart(2, '0')}:${maxMinutes.padStart(2, '0')}`
      const totalDuration = `${totalHours.padStart(2, '0')}:${totalMinutes.padStart(2, '0')}`
      const totalMinutes1 = moment.duration(maxDuration).asMinutes();
      const totalMinutes2 = moment.duration(totalDuration).asMinutes();
      if (totalMinutes1 > totalMinutes2) {
        this.formGp.get(this.lastChangedTotalControl)?.setErrors({ 'maxTotalError': true });
        this.formGp.get(this.lastChangedTotalControl).markAsTouched()
      }
      else{
        this.clearErrors('totalHours',['maxTotalError'])
        this.clearErrors('totalMinutes',['maxTotalError'])
      }
      this.cd.detectChanges();
    }
  }

  // Remove only specific error from control
  clearErrors(controlName: string, errorTypes: string[]) {
    const control = this.formGp.get(controlName);
    if (control) {
      const errors = control.errors;
      if (errors) {
        errorTypes.forEach(errorType => {
          if (errors[errorType]) {
            control.setErrors(null, { emitEvent: false });
            control.updateValueAndValidity();
          }
        });
      }
    }
  }

}
