<div class="card card-custom1" >
    <div class="card-body d-flex p-12" >
        <ngx-skeleton-loader appearance="circle"  [theme]="{ 'border-radius': '8px',height: '48px',width: '48px','background-color': '#EAEDEF','margin-right':'16px'}"></ngx-skeleton-loader>
        <div>
            <ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" *ngIf="loader" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '81px' ,'display':'block'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>

