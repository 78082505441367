
<div class="container-fluid p-32 form-container"  [formGroup]="formGp">
    <div class="row ">
        <p class="text-uppercase fw-500">alert</p>
    </div>
    <div class="row row-16">
      <div class="col-12 form-row">
        <label for="" class="form-label  ">Do you want to send absenteeism email alert?</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If you want to enable email alert in case of absenteeism of employee then select the option YES." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
      </div>
      <div class="col-lg-12 form-row mt-0">
          <div class="form-check form-check-inline">
              <label class="form-check-label" >
                  <input  formControlName="send_absent_email"   class="form-check-input" type="radio"  [value]=true (click)="setDisabled(true,'trigger_absent_continuously_absent');setDisabled(true,'whome_want_notify')">
                  Yes
              </label>
          </div>
          <div class="form-check form-check-inline">
              <label class="form-check-label" >
                  <input  formControlName="send_absent_email"  class="form-check-input" type="radio" [value]=false (click)="setDisabled(false,'trigger_absent_continuously_absent');setDisabled(false,'whome_want_notify')">
                  No
              </label>
          </div>
      </div>

     <ng-container *ngIf="f.send_absent_email.value == true">
      <div class="col-12 form-row">
        <label for="" class="form-label required">Trigger absenteeism alert when employee is continuously absent on</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Mention the days after which this alert will be initiated when employee are absent continuously." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Days')}}" formControlName="trigger_absent_continuously_absent" [ngClass]="{ 'is-invalid': (submitted || f.trigger_absent_continuously_absent.dirty || f.trigger_absent_continuously_absent.touched) && f.trigger_absent_continuously_absent.errors}" pattern="^(?:[1-9]|0[1-9]|10)$">

        <div *ngIf="(submitted || f.trigger_absent_continuously_absent.touched || f.trigger_absent_continuously_absent.dirty) && f.trigger_absent_continuously_absent.errors" class="invalid-feedback">
          <div *ngIf="f.trigger_absent_continuously_absent.errors.required">{{this.messageService.validationDisplay('required')}}
          </div>
          <div *ngIf="f.trigger_absent_continuously_absent.errors.pattern && f.trigger_absent_continuously_absent.errors.pattern.actualValue>10">{{this.messageService.fieldlengthvalidation('days','10')}}
          </div>
          <div *ngIf="f.trigger_absent_continuously_absent.errors.pattern && (f.trigger_absent_continuously_absent.errors.pattern.actualValue<=10 || notanumber(f.trigger_absent_continuously_absent.errors.pattern.actualValue))">{{this.messageService.validationDisplay('pattern')}}
            </div>
      </div>
      </div>
      <div class="col-12 form-row">
        <label for="" class="form-label required">Whom do you want to notify</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select multiple role to whom this alert should be triggered." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        <ng-select
          [readonly]="disabled" [ngClass]="{ 'is-invalid': (f.whome_want_notify.touched) &&  f.whome_want_notify.errors}"  placeholder="{{this.messageService.selectplaceholddisplay('role')}}" formControlName="whome_want_notify" [items]="rolesType" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="role_name" [closeOnSelect]="false" bindValue="id" class="multiselect" [attr.disabled]="disabled? true : null"
         >
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
            </ng-template>
            <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                {{ item.role_name }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="formGp.value.whome_want_notify?.length">
                    <span class="ng-value-label">{{formGp.value.whome_want_notify?.length}} role selected.</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ (item.role_name?.length>15)? (item.role_name | slice:0:15)+'..':(item.role_name)  }}
            </ng-template>
          </ng-select>
          <div *ngIf="(submitted || f.whome_want_notify.touched) && f.whome_want_notify.errors" class="invalid-feedback">
            <div *ngIf="f.whome_want_notify.errors.required">{{this.messageService.validationDisplay('required')}}
            </div>
        </div>

      </div>
      <div class="col-12">
        <ul class="selected-values">
            <li class="ng-value" *ngFor="let item of formGp.value.whome_want_notify, let m =index">
                <ng-container *ngFor="let insideitem of rolesType, let k =index">
                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['role_name']?.length>15)? (insideitem['role_name'] | slice:0:15)+'..':(insideitem['role_name'])  }}</span>
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right" aria-hidden="true" (click)="clear(m,'whome_want_notify')"><i class="icon-close"></i></span>
          </li>
        </ul>
      </div>
     </ng-container>
      <div class="col-12 form-row">
        <label for="" class="form-label">Do you want to send weekly attendance email alert?</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If you want to enable this alert on weekly basis then select the option “yes”." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
      </div>
      <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input  formControlName="send_weekly_absent_email"   class="form-check-input" type="radio"  [value]=true  (click)="setDisabled(true,'weekly_notify_cc');">
                Yes
            </label>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input  formControlName="send_weekly_absent_email"  class="form-check-input" type="radio" [value]=false (click)="setDisabled(false,'weekly_notify_cc');">
                No
            </label>
        </div>
      </div>
      <ng-container *ngIf="f.send_weekly_absent_email.value == true">
        <div class="col-12 form-row">
          <label for="" class="form-label">Mark CC to</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the roles to whom you want to CC the email." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          <ng-select
            [readonly]="disabled" [ngClass]="{ 'is-invalid': (f.weekly_notify_cc.touched) &&  f.weekly_notify_cc.errors}"  placeholder="{{this.messageService.selectplaceholddisplay('role')}}" formControlName="weekly_notify_cc" [items]="rolesType" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="role_name" [closeOnSelect]="false" bindValue="id" class="multiselect" [attr.disabled]="disabled? true : null"
           >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
              </ng-template>
              <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                  {{ item.role_name }}
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngIf="formGp.value.weekly_notify_cc?.length">
                      <span class="ng-value-label">{{formGp.value.weekly_notify_cc?.length}} role selected.</span>
                  </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{ (item.role_name?.length>15)? (item.role_name | slice:0:15)+'..':(item.role_name)  }}
              </ng-template>
            </ng-select>
            <div *ngIf="(submitted || f.weekly_notify_cc.touched) && f.weekly_notify_cc.errors" class="invalid-feedback">
              <div *ngIf="f.weekly_notify_cc.errors.required">{{this.messageService.validationDisplay('required')}}
              </div>
          </div>

        </div>
        <div class="col-12">
          <ul class="selected-values">
              <li class="ng-value" *ngFor="let item of formGp.value.weekly_notify_cc, let m =index">
                  <ng-container *ngFor="let insideitem of rolesType, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                      {{ (insideitem['role_name']?.length>15)? (insideitem['role_name'] | slice:0:15)+'..':(insideitem['role_name'])  }}</span>
                  </ng-container>
                  <span *ngIf="disabled == false" class="ng-value-icon right" aria-hidden="true" (click)="clear(m,'weekly_notify_cc')"><i class="icon-close"></i></span>
            </li>
          </ul>
        </div>
      </ng-container>
      <div class="col-12 form-row">
        <label for="" class="form-label">Do you want to send monthly attendance email alert?</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="If you want to enable this alert on monthly basis then select the option “yes”." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
      </div>
      <div class="col-lg-12 form-row mt-0">
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input  formControlName="send_monthly_absent_email"   class="form-check-input" type="radio"  [value]=true (click)="setDisabled(true,'monthly_notify_cc');">
                Yes
            </label>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input  formControlName="send_monthly_absent_email"  class="form-check-input" type="radio" [value]=false  (click)="setDisabled(false,'monthly_notify_cc');">
                No
            </label>
        </div>
      </div>
      <ng-container *ngIf="f.send_monthly_absent_email.value == true">
        <div class="col-12 form-row">
          <label for="" class="form-label">Mark CC to</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the roles to whom you want to CC the email." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          <ng-select
            [readonly]="disabled" [ngClass]="{ 'is-invalid': (f.monthly_notify_cc.touched) &&  f.monthly_notify_cc.errors}"  placeholder="{{this.messageService.selectplaceholddisplay('person')}}" formControlName="monthly_notify_cc" [items]="rolesType" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="role_name" [closeOnSelect]="false" bindValue="id" class="multiselect" [attr.disabled]="disabled? true : null"
           >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
              </ng-template>
              <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                  {{ item.role_name }}
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngIf="formGp.value.monthly_notify_cc?.length">
                      <span class="ng-value-label">{{formGp.value.monthly_notify_cc?.length}} role selected.</span>
                  </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{ (item.role_name?.length>15)? (item.role_name | slice:0:15)+'..':(item.role_name)  }}
              </ng-template>
            </ng-select>
            <div *ngIf="(submitted || f.monthly_notify_cc.touched) && f.monthly_notify_cc.errors" class="invalid-feedback">
              <div *ngIf="f.monthly_notify_cc.errors.required">{{this.messageService.validationDisplay('required')}}
              </div>
          </div>

        </div>
        <div class="col-12">
          <ul class="selected-values">
              <li class="ng-value" *ngFor="let item of formGp.value.monthly_notify_cc, let m =index">
                  <ng-container *ngFor="let insideitem of rolesType, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                      {{ (insideitem['role_name']?.length>15)? (insideitem['role_name'] | slice:0:15)+'..':(insideitem['role_name'])  }}</span>
                  </ng-container>
                  <span *ngIf="disabled == false" class="ng-value-icon right" aria-hidden="true" (click)="clear(m,'monthly_notify_cc')"><i class="icon-close"></i></span>
            </li>
          </ul>
        </div>
      </ng-container>
  </div>
</div>
<footer class="submit-footer">
  <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateForm()">NEXT</button>
  <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
</footer>
