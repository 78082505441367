<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='holidayRequestView === true'>
  <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>{{from=='behalf'?title:'View'}}</h5>
          <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">

              <!-- View loader start -->
              <app-view-loader *ngIf="loader"></app-view-loader>
              <!-- View loader end -->

              <div class="row row-24 mb-24" *ngIf="!loader">

                <div class="col-12">
                  <div class="mb-0 fw-500 text-uppercase">{{requestName == 'Holiday' ? 'HOLIDAY REQUEST' : 'Holiday Request Cancellation'}}</div>
                </div>

                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 py-16">
                          <div class="row row-24 " >
                              <div class="col-12 d-flex">
                                <span class="title">{{requestName == 'Holiday' ? 'HOLIDAY REQUEST DETAILS' : 'HOLIDAY REQUEST CANCELLATION DETAILS'}}</span>
                                <div class="d-flex align-items-center gap-8 ms-auto">
                                    <span class="ribbon ribbon-right ribbon-secondary" *ngIf="holidayRequestSingleData?.status == 'Cancelled'">Cancelled</span>
                                    <div class=" fs-14 ms-auto px-2 fw-500 badge3" *ngIf="holidayRequestSingleData?.status != 'Invalid' && holidayRequestSingleData?.status != 'Cancelled'"
                                    [ngClass]="{'badge-warning': holidayRequestSingleData?.status === 'Pending','badge-success': holidayRequestSingleData?.status === 'Accepted','badge-danger': holidayRequestSingleData?.status === 'Rejected'}">
                                      {{holidayRequestSingleData?.status}}
                                    </div>
                                </div>
                            </div>
                              <div class="col-12">
                                <div class="card card-c2 p-16">

                                    <div class="row row-24">
                                        <div class="col-lg-4  col-sm-6 col-12">
                                            <div class="detail-title">Holiday name</div>
                                            <div class="detail-desc">{{holidayRequestSingleData?.holiday_name}} {{holidayRequestSingleData?.day_name != null ? '(day '+(holidayRequestSingleData?.day_name)+')' : ''}}</div>
                                        </div>
                                        <div class="col-lg-4  col-sm-6 col-12">
                                          <div class="detail-title">Holiday date</div>
                                          <div class="detail-desc">
                                            {{this.appservice.dateFormatDisplay(holidayRequestSingleData?.holiday_date) +', '+ holidayRequestSingleData?.day_of_week}}
                                          </div>
                                      </div>
                                      <div class="col-12" *ngIf="requestName == 'Holiday Cancellation'">
                                        <div class="detail-title">Reason for cancellation</div>
                                        <div class="detail-desc">{{holidayRequestSingleData?.remark}}</div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
            </div>
        </div>
      </div>
      <ng-container *ngIf="(from == 'request' || (from == 'behalf' && !cancelStatus) && requestName != 'Holiday Cancellation')">
        <footer class="side-panel-footer" *ngIf="holidayRequestSingleData?.status == 'Pending' || holidayRequestSingleData?.status == 'Accepted' ">
          <button type="submit" class="ms-auto btn btn-outline-danger text-uppercase btn-sm" (click)="cancelRequest(holidayRequestSingleData?.status, holidayRequestSingleData?.cancellation_raised_status,holidayRequestSingleData?.arrear_valid)">
              CANCEL REQUEST
          </button>
        </footer>

        <app-cancel-request-modal *ngIf="requestCancel" [requestCancel]="requestCancel" [requestName]="'holiday'" [requestId]="requestId" [requestStatus]="holidayRequestSingleData?.status" (modal)="closeCancelModal($event)" (updateStatus)="updateStatus($event)" [from]="from"></app-cancel-request-modal>
      </ng-container>

  </form>
</div>
