import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-alert-toggle',
  templateUrl: './alert-toggle.component.html',
  styleUrls: ['./alert-toggle.component.scss']
})
export class AlertToggleComponent implements OnInit {

  @Input() alertToggle:any;
  @Input() alertMsg:any;
  @Output() modals = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  close(){
    this.alertToggle = false
    this.modals.emit(this.alertToggle)
  }
}
