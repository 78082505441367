import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-monthwise-data',
  templateUrl: './monthwise-data.component.html',
  styleUrls: ['./monthwise-data.component.scss']
})
export class MonthwiseDataComponent implements OnInit {
  activeTab1          = 1;
  page                = 1;
  pageSize            = 20;
  searchString:any    = "";
  landingLoader       = true;
  filter              = false;
  companyList:any     = [];
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  exportData:any      = [];
  excelTemp:any       = [];
  nodataFound         = false;
  loader              = false;
  detailedPanel       = false;
  monthlySummary:any;
  MonthlyData:any;
  year:any;
  month:any;
  company:any;
  detailedView:any;

  constructor(
    public route:ActivatedRoute,
    public salaryService: SalaryDashboardService,
    public messageService : MessageService,
    private _cdr: ChangeDetectorRef,
    public appService: AppService,


  ) { }

  ngOnInit(): void {
    this.getCompanyDropdown();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      this.getMonthlyData();
    })
  }
  getMonthlyData(){
    this.salaryService.getPriorMonthlyData(this.company,this.year).subscribe((res:any)=>{
      this.MonthlyData    = res.body.data;
      this.landingLoader  = false;
    })
  }
  getMonthlySummary(){
    this.detailedPanel  = true
    this.detailedView   = true;
    this.salaryService.getPriorSummary(this.company,this.year,this.month,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{
      if(res.body.length != 0)
      this.monthlySummary = res.body.data[0];
      this.detailedView   = false;
    })
  }
  getDetailedView(month:any,year:any){
    this.month = month;
    this.year  = year;
    this.getMonthlySummary();
  }
  getCompanyDropdown(){
    this.salaryService.companyDropDownData()
    .subscribe((res: any) => {
      if (res.status == 200) {
        if(res.body.length == 0){
          this.nodataFound = true;
          this.loader      = false;
          this._cdr.detectChanges();
        } else {
          this.companyList = res.body;
          if(this.company==''){
            this.company =  this.company==''?res.body[0]['id']:this.company;

          }
        }
      }
    })
  }
  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList       = []
    this.getMonthlySummary()
    this.filter           = false
  }
  submitForm(data:any){
    this.filter           = false
    this.filter           = false
    this.buList          = data.buList!=""?data.buList.join(','):[]
    this.DepList         = data.DepList!=""?data.DepList.join(','):[]
    this.DesignationList = data.DesignationList!=""?data.DesignationList.join(','):[]
    this.gradeList       = data.gradeList!=""?data.gradeList.join(','):[]
    this.BranchList      = data.BranchList!=""?data.BranchList.join(','):[]
    this.getMonthlySummary()
  }
  closeFilter(){
    this.filter  = false
  }
  reloadPage(){
    this.detailedPanel  = false;
    this.getMonthlyData();
  }
  exportMonthlyPrior(){
    this.salaryService.exportMonthlyPrior(this.month,this.year,this.company).subscribe((res:any)=>{

      let header          = res.body.headers;
      let body            = res.body.data;
      this.excelTemp      = [];
      this.exportData     = [];

        for(let i=0;i<body.length;i++){
          var dict:any = {};
          for(let j=0;j<header.length;j++){
            dict[header[j]] = body[i][header[j]];

          }
          this.excelTemp.push({
            dict
          })

        }

      for(let j=0;j<this.excelTemp.length;j++){
        this.exportData.push(this.excelTemp[j]['dict'])
      }
      this.appService.exportExcel(this.exportData,'Prior Payroll '+this.month +" "+this.year);

    })
  }

}
