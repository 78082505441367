import { Component, OnInit, Inject, ViewChild,ElementRef, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import { FormBuilder, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { RoleService } from 'src/app/@core/services/role.service';
import { MessageService } from 'src/app/message.global';
import { SnapshotComponent } from './snapshot/snapshot.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { LoginReportComponent } from './login-report/login-report.component';
import * as moment from 'moment';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { ResignationReportComponent } from './resignation-report/resignation-report.component';
import { AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { DisablePortalAccessComponent } from './disable-portal-access/disable-portal-access.component';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-employee-report-configure',
  templateUrl: './employee-report-configure.component.html',
  styleUrls: ['./employee-report-configure.component.scss']
})
export class EmployeeReportConfigureComponent implements OnInit {
  scrollLeft(e:any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft -= 150;
 }
 scrollRight(e:any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft += 150;
 }

 columnChangedArr : any = []
 // Calling the close fn of tag in parent from child
 @ViewChild(SnapshotComponent, { static: false }) appSnapshot!: SnapshotComponent;
 @ViewChild(BankDetailsComponent, { static: false }) appbank!: BankDetailsComponent;
 @ViewChild(EmployeeDetailsComponent, { static: false }) appempdetails!: EmployeeDetailsComponent;
 @ViewChild(PersonalDetailsComponent, { static: false }) apppersonaldetails!: PersonalDetailsComponent;
 @ViewChild(LoginReportComponent, { static: false }) applogin!: LoginReportComponent;
 @ViewChild(ResignationReportComponent, { static: false }) appResignation!: ResignationReportComponent;
  @ViewChild(DisablePortalAccessComponent, { static: false }) disableAccess!: DisablePortalAccessComponent;
  alertShow = true;
 table: any;
  scheduleReport      = false;
  applyFilter         = false;
  resetFilter         = false;
  viewDetail          = false;
  addColumnFilter     = false;
  dataSource:any      = [];
  columnNames:any     = [];
  page                = 1;
  pageSize            = 20;
  showTotalPages      = 3;
  fileType            = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension       = '.xlsx';
  fileName:any;
  loader              = true;
  nodata              = false;
  reportName:any      = '';
  paginateData: any[] = [];
  startDate             : any
  endDate               : any
  displayedColumns:any  = [];
  columnOptions:any     = [];
  columnOptions1:any    = [];
  datasControl          = new FormControl();
  columnsControl        = new FormControl();
  checkColumn:any       = [];
  tempArr: any          = [] ;
  empstatArr : any      = [];
  fullData:any          = [];
  callReports : any;
  callemp :boolean = false
  checkListValue:any
  checkList :any
  closeControl :any;
  responseLength :any;
  headerCheck :any;
  restFilterSave : boolean = false
  //FILTER MEMORIZE VARIABLES
  dataFilter: any;
  header_filter: any    = [];
  resDataFilter:any;
  resHeader_filter:any  = [];
  filterDisable         = false;
  resetReportName:any;
  is_colfilter          = false;
  //SCHEDULE REPORT MEMORIZE VARIABLES
  isFilter              = false;
  scheduleText:any      = '';
  //TAG FILTER VARIABLES
  appliedFilter:any     = [];
  tagMultiData:any      = [];
  tagMultiCnt:any       = 0;
  columnsArr : any = []
  defaultHeaderList:any=[];
  OuFilterForm = this.formBuilder.group({
    company               : [''],
    bu                    : [''],
    branch                : [''],
    designation           : [''],
    department            : [''],
    employee_list         : [''],
    grade                 : [''],
    employee_type         : [''],
    employment_status     : [''],
  })
  Snapshot = this.formBuilder.group({
    ouFilterForm          : this.OuFilterForm,
    birthday              : '',
    created_date          : '',
    specific_date         : null,
    cd_specific_date      :null,
    bank_status           : '',
    personal_status       :'',
    document_status       : '',
    education_status      : '',
    family_status         : '',
    health_insurance_status : '',
    previous_employer_detail_status : '',
    work_experience_status        : '',
    total_experience       : '',
    reporting_manager      : '',
    functional_manager     : '',
    date_of_leaving         : '',
    dol_specific_date      :null,
    date_of_joining        : '',
    to_date_join      :null,
    from_date_join      :null,
    date_of_confirmation        : '',
    to_date_confirm      :null,
    from_date_confirm      :null,
    from_date_birth      :null,
    to_date_birth      :null,
    specific_month: null,
    specific_year: null
  })
  bankdetails = this.formBuilder.group({
    ouFilterForm: this.OuFilterForm,
  })
  Employeedetails = this.formBuilder.group({
    ouFilterForm: this.OuFilterForm,
    reporting_manager: '',
    functional_manager: '',
  })
  personaldetails = this.formBuilder.group({
    ouFilterForm: this.OuFilterForm,
    pan_status: '',
    aadhar_status: '',
    passport_status: '',
  })
  requiredWithDateRange = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isNullDateRange = (value === null) ||
      (typeof value === 'object' && value.startDate === null && value.endDate === null);
    return isNullDateRange ? { required: true } : Validators.required(control);
  };
  loginForm = this.formBuilder.group({
    ouFilterForm: this.OuFilterForm,
  date_of_login           : ['',[this.requiredWithDateRange]],
    login_specific_date: null,
    from_date: '',
    to_date: '',
  })
  resignationForm = this.formBuilder.group({
    ouFilterForm: this.OuFilterForm,
    resignation_date: '',
    from_date: '',
    to_date: '',
  })
  disabled = false;
  companyDD: any = [{
    id: 0,
    company_name: ''
  }];
  buDD = [{
    id          : 0,
    name        : ''
  }];
  DepDD = [{
    id          : 0,
    name        : ''
  }];
  DesignationDD = [{
    id          : 0,
    name        : ''
  }];
  countryDD = [{
    id          : 0,
    name        : ''
  }];
  stateDD = [{
    id          : 0,
    name        : ''
  }];
  BranchDD = [{
    id          : 0,
    name        : ''
  }];
  cityDD = [{
    id          : 0,
    name        : ''
  }];
  gradeDD = [{
    id           : 0,
    name         : ''
  }];
  employeeDropdown:any  = [];
  filterSaveForm = this.formBuilder.group({
    filter: true,
  });
  scheduleReportFilterMemorizeForm = this.formBuilder.group({
    filter    : [true],
  });
  testform = this.formBuilder.group({
    checkbox  : [''],
  })
  submitted         = false;
  filterSubmit      = false;
  checkFilterSubmit = false;
  dateSetup          : any
  filterFormData      : any     = ''
  childFilterSubmit   : boolean = false
  toppings = new FormControl();
  checkboxData:any = [
    { id: '1', value: 'One' },
    { id: '2', value: 'Two' },
  ]
  columns1: string[] = [];
  columns2: string[] = [];
  employeetype: any[] = [
    {name: 'Permanent'},
    {name: 'Consultant'},
    {name: 'Wages'},
    {name: 'Trainee'},
    {name: 'Contractual'},
    {name: 'Apprentice'},
  ];
  employeestatus: any[] = [
    {name: 'Probation'},
    {name: 'Confirmed'},
    {name: 'Suspended'},
    {name: 'Absconding'},
    {name: 'Resigned'},
    {name: 'Terminated'},
    {name: 'Relieved'},
    {name: 'Retired'},
  ];
  emptypeDD = [
    {id:"Contractual",    name:"Contractual"},
    {id:"Consultant",    name:"Consultant"},
    {id:"Permanent",    name:"Permanent"},
    {id:"Trainee",    name:"Trainee"},
    {id:"Wages",    name:"Wages"},
  ];
  empstatusDD = [
    {id:0,    name:"Probation"},
    {id:1,    name:"Confirmed"},
    {id:2,    name:"Relieved"},
    {id:3,    name:"Resigned"},
    {id:4,    name:"Settled"},
    {id:5,    name:"Terminated"},
    {id:6,    name: 'Retired'},
    {id:7,    name:"NA"},
  ];
  reportId = 0;
  element: any;
  dateSplit           : any
  hasChange: boolean = false;
  hasChangeCount:any = 0;
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.columnChangedArr = this.displayedColumns
    this.filterSubmit = true
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  pageChangeDetect : boolean = false
  hint_dict: any;
  Years : any = []
  selectedYear : any
  constructor(public router: Router,
    public route:ActivatedRoute,
    public employeereportService:EmployeeReportsService,
    public cd : ChangeDetectorRef,
    private formBuilder: FormBuilder,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public roleService:RoleService,
    public messageService : MessageService,
    public leave_service : LeaveReportService,
    public attendanceReportService:AttendanceReportsService,
    private notify : NotificationService
  ) { }
  ngOnInit(): void {
    this.childFilterSubmit = true
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.initialLoaded = true
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
          this.reportId = params['id'];
      }
      if(params['name'] == 'Snapshot'){
        this.snapshotReport();
      }
      else if(params['name'] == 'BankDetails'){
        this.reportName = 'Bank Details'
        this.bankdetailsReport();
      }
      else if(params['name'] == 'EmployeeDetails'){
        this.reportName = 'Employee Details'
        this.EmployeedetailsReport();
      }
      else if(params['name'] == 'Personal')
        this.personalDetailsReport();
      else if(params['name'] == 'EmployeeLoginReport'){
        this.reportName = 'Employee Login Report'
        this.loginReport();
      }
      else if(params['name'] == 'Resignation'){
        this.reportName = 'Resignation'
        this.resignationReport();
      }
      else if (params['name'] == 'DisablePortalAccess') {
        this.reportName = 'Disable Portal Access'
        this.portalAccessReport();
      }
      })
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
    this.yearListFunction();
  }
dateSplitBirth : any
dateSplitLeave : any
dateSplitConfirmation : any
/******** Snapshot REPORT  ****/
snapshotReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.reportName         = "Snapshot";
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.appliedFilter      = [];
  this.employeereportService.snapshotReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
          this.dataSource[i]['Date of birth']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of birth']);
          this.dataSource[i]['Date of Confirmation']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Confirmation']);
          this.dataSource[i]['Date Of leaving']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Of leaving']);
          this.dataSource[i]['Created date']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Created date']);
          this.dataSource[i]['Date of Resignation']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Resignation']);
          this.dataSource[i]['Date of retirement']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of retirement']);
          this.dataSource[i]['Date of settlement']   = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of settlement']);
}
    }
      this.dataSource = new MatTableDataSource(this.dataSource);
      // this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      this.dateSetup =  res.applied_filter;
      if(res.applied_filter?.date_of_birth && res.applied_filter?.date_of_birth != 'today' &&res.applied_filter?.date_of_birth != 'tomorrow'  && res.applied_filter?.date_of_birth!='this_week' && res.applied_filter?.date_of_birth != 'this_month' && res.applied_filter?.date_of_birth != 'next_month'){
        this.dateSplitBirth = res.applied_filter?.date_of_birth?.split(",")
        if(this.dateSplitBirth!= undefined && res.applied_filter?.date_of_birth != 'today' && res.applied_filter?.date_of_birth != 'tomorrow' && res.applied_filter?.date_of_birth != 'this_week' &&res.applied_filter?.date_of_birth != 'this_month'&& res.applied_filter?.date_of_birth != 'next_month' ){
        this.Snapshot.get('birthday')?.setValue(true);
        this.Snapshot.get('from_date_birth')?.setValue(new Date(this.dateSplitBirth[0]))
        this.Snapshot.get('to_date_birth')?.setValue(new Date(this.dateSplitBirth[1]))
       }else{
           this.Snapshot.get('birthday')?.reset(res.applied_filter.date_of_birth)
       }
      }
      if(res.applied_filter?.date_of_joining && res.applied_filter?.date_of_joining != 'today' &&res.applied_filter?.date_of_joining != 'tomorrow'  && res.applied_filter?.date_of_joining!='next_month' && res.applied_filter?.date_of_joining != 'last_7_days'&& res.applied_filter?.date_of_joining && res.applied_filter?.date_of_joining != 'last_15_days' &&res.applied_filter?.date_of_joining != 'this_week'  && res.applied_filter?.date_of_joining!='last_month' && res.applied_filter?.date_of_joining != 'this_month'){
        this.dateSplit = res.applied_filter?.date_of_joining?.split(",")
       if(this.dateSplit!= undefined && res.applied_filter?.date_of_joining != 'today' && res.applied_filter?.date_of_joining != 'tomorrow' && res.applied_filter?.date_of_joining != 'last_15_days' &&res.applied_filter?.date_of_joining != 'last_7_days' && this.dateSplit!= undefined && res.applied_filter?.date_of_joining != 'next_month' && res.applied_filter?.date_of_joining != 'last_month' && res.applied_filter?.date_of_joining != 'this_week' &&res.applied_filter?.date_of_joining != 'this_month'){
        this.Snapshot.get('date_of_joining')?.setValue(true);
        this.Snapshot.get('from_date_join')?.setValue(new Date(this.dateSplit[0]))
        this.Snapshot.get('to_date_join')?.setValue(new Date(this.dateSplit[1]))
       }else{
           this.Snapshot.get('date_of_joining')?.reset(res.applied_filter.date_of_joining)
       }
      }
      if (res.applied_filter?.date_of_confirmation && res.applied_filter?.date_of_confirmation != 'last_15_days' && res.applied_filter?.date_of_confirmation != 'last_7_days' && res.applied_filter?.date_of_confirmation != 'last_month' && res.applied_filter?.date_of_confirmation != 'this_month') {
        this.dateSplitConfirmation = res.applied_filter?.date_of_confirmation?.split(",")
       if(this.dateSplitConfirmation!= undefined && res.applied_filter?.date_of_confirmation != 'last_month' && res.applied_filter?.date_of_confirmation != 'this_month' && res.applied_filter?.date_of_confirmation != 'last_15_days' &&res.applied_filter?.date_of_confirmation != 'last_7_days' ){
        this.Snapshot.get('date_of_confirmation')?.setValue(true);
        this.Snapshot.get('from_date_confirm')?.setValue(new Date(this.dateSplitConfirmation[0]))
        this.Snapshot.get('to_date_confirm')?.setValue(new Date(this.dateSplitConfirmation[1]))
       }else{
           this.Snapshot.get('date_of_confirmation')?.reset(res.applied_filter.date_of_confirmation)
       }
      }
      if(res?.applied_filter?.date_of_leaving){
        this.dateSplitLeave = res.applied_filter?.date_of_leaving
        this.Snapshot.get('date_of_leaving')?.setValue(res.applied_filter?.date_of_leaving)
      }
    this.nodata = res.data?.length==0?true:false;
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.checkListElements();
    if(!this.resetOffset && !this.pageChangeDetect){
    if(this.resHeader_filter?.length != 0)
      this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
    else
      this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.Snapshot.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        if(this.resDataFilter?.birthday && this.resDataFilter?.birthday!='today' && this.resDataFilter?.birthday!='tomorrow' && this.resDataFilter?.birthday!='this_week' && this.resDataFilter?.birthday!='this_month' && this.resDataFilter?.birthday!='next_month' && this.resDataFilter?.birthday?.length !=0 &&this.resDataFilter?.birthday!=''){
          this.Snapshot.get('birthday')?.setValue(true);
          this.Snapshot.get('from_date_birth')?.setValue(new Date(this.dateSplitBirth[0]))
          this.Snapshot.get('to_date_birth')?.setValue(new Date(this.dateSplitBirth[1]))
        }else{
          this.Snapshot.get('birthday')?.reset(this.resDataFilter.birthday);
        }
        if(this.resDataFilter.created_date != 'today' && this.resDataFilter.created_date != 'yesterday'  && this.resDataFilter.created_date != '' && this.resDataFilter.created_date != null){
          this.Snapshot.get('created_date')?.setValue(true);
          this.Snapshot.get('cd_specific_date')?.setValue(new Date(this.resDataFilter.created_date));
        }
        else{
          this.Snapshot.get('created_date')?.reset(this.resDataFilter.created_date);
        }
        if(this.resDataFilter.date_of_joining != 'last_month'&&this.resDataFilter.date_of_joining != 'last_15_days' &&this.resDataFilter.date_of_joining != 'this_week' && this.resDataFilter.date_of_joining != 'this_month' && this.resDataFilter.date_of_joining != 'next_month' && this.resDataFilter.date_of_joining != 'this_week' && this.resDataFilter.date_of_joining != 'last_7_days' && this.resDataFilter.date_of_joining != 'today' && this.resDataFilter.date_of_joining != 'Tomorrow'&& this.resDataFilter.date_of_joining != ''){
          this.Snapshot.get('date_of_joining')?.setValue(true);
          this.Snapshot.get('from_date_join')?.setValue(new Date(this.dateSplit[0]))
          this.Snapshot.get('to_date_join')?.setValue(new Date(this.dateSplit[1]))
        }else{
          this.Snapshot.get('date_of_joining')?.reset(this.resDataFilter.date_of_joining);
        }
        if(this.resDataFilter.date_of_leaving != 'last_month' && this.resDataFilter.date_of_leaving != 'current_month' && this.resDataFilter.date_of_leaving != '' && this.resDataFilter.date_of_leaving != null){
          this.Snapshot.get('date_of_leaving')?.setValue(true);
          // this.Snapshot.get('dol_specific_date')?.setValue(new Date(this.resDataFilter.date_of_leaving));
          const attendance_year_month = this.resDataFilter?.date_of_leaving
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.Snapshot.get('specific_year')?.setValue(split_year_month[0]);
          this.Snapshot.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.Snapshot.get('date_of_leaving')?.reset(this.resDataFilter.date_of_leaving);
        }
        if(this.resDataFilter.date_of_confirmation != 'last_month'&&this.resDataFilter.date_of_confirmation != 'last_15_days' &&this.resDataFilter.date_of_confirmation != 'last_7_days' && this.resDataFilter.date_of_confirmation != 'this_month' && this.resDataFilter.date_of_confirmation != ''){
          this.Snapshot.get('date_of_confirmation')?.setValue(true);
          this.Snapshot.get('from_date_confirm')?.setValue(new Date(this.dateSplitConfirmation[0]))
          this.Snapshot.get('to_date_confirm')?.setValue(new Date(this.dateSplitConfirmation[1]))
        }else{
          this.Snapshot.get('date_of_confirmation')?.reset(this.resDataFilter.date_of_confirmation);
        }
      }
    }
  /**Header filter data**/
  if(!this.resetOffset && !this.pageChangeDetect){
    if(this.resHeader_filter?.length != 0){
    this.headerFilterImplementation(this.resHeader_filter)
    }
        else { this.headerFilterImplementation(res?.header_list) }
  }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader           = false;
    this.pageChangeDetect = false
  });
}
/******** Bank Details REPORT  ****/
bankdetailsReport(){
        this.loader             = true;
        this.reportName         = "Bank Details";
        this.appliedFilter      = [];
        this.offset = this.resetOffset ? 0 : this.offset
        this.page = this.resetOffset ? 1 : this.page
        this.employeereportService.bankDetailsReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res.data?.length != 0){
            this.dataSource       = res.data;
            this.lastPage = res?.count
            if(this.dataSource?.length > 0){
              for(let i=0; i<this.dataSource?.length;i++){
                this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
              }
            }
            this.dataSource = new MatTableDataSource(this.dataSource);
            // this.fullData   = this.dataSource?.filteredData;
          }
          // if(this.childFilterSubmit){
            this.resDataFilter     = res?.data_filter;
            this.resHeader_filter  = res?.header_filter;
          // }
          this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
          if(res.hasOwnProperty('applied_filter'))
            this.appliedFilter =  res.applied_filter;
          this.nodata = res.data?.length==0?true:false;
          this.setDataSourceAttributes();
          this.columns2       = res['check_list'];
          this.columnOptions  = this.columns2;
          this.columnsControl.setValue(this.columnOptions1);
          this.checkListElements();
          /**dynamic data table**/
                // this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
        if(!this.resetOffset && !this.pageChangeDetect){
          if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
          else
          this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
        }
          if(this.resDataFilter != null && this.resDataFilter != undefined){
            if(!!Object.keys(this.resDataFilter)?.length == true){
              this.bankdetails.reset(this.resDataFilter);
              this.OuFilterForm.reset(this.resDataFilter)
              if(this.resDataFilter?.company?.length > 0){
                this.getBUList();
                if(this.resDataFilter?.bu?.length > 0)
                  this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
              }
              if(this.resDataFilter?.emp_status.length > 0){
                this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
              }
              if(this.resDataFilter?.emp_type.length > 0){
                this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
              }
            }
          }
          /**Header filter data**/
          if(!this.resetOffset && !this.pageChangeDetect){
            if(this.resHeader_filter?.length != 0){
            this.headerFilterImplementation(this.resHeader_filter)
            }
        else { this.headerFilterImplementation(res?.header_list) }
          }
            this.resetOffset = false
            this.checkListElements();
            this.checkChange = true
            this.headerFilterApply()
            this.loader           = false;
            this.pageChangeDetect = false
          });
      }
 /******** Employee Details REPORT  ****/
EmployeedetailsReport(){
  this.loader             = true;
  this.reportName         = "Employee Details";
  this.appliedFilter      = [];
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.employeereportService.employeeDetailsReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Date of Confirmation'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Confirmation']);
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
          this.dataSource[i]['Date of Resignation'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Resignation']);
          this.dataSource[i]['Date of birth'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of birth']);
          this.dataSource[i]['Date of leaving'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of leaving']);
          this.dataSource[i]['Date of marriage'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of marriage']);
          this.dataSource[i]['Father date of birth'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Father date of birth']);
          this.dataSource[i]['Mother date of birth'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Mother date of birth']);
          this.dataSource[i]['Spouse date of birth'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Spouse date of birth']);
          this.dataSource[i]['Date of settlement'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of settlement']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
    this.nodata = res.data?.length==0?true:false;
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.checkListElements();
    /**dynamic data table**/
    // this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
  if(!this.resetOffset && !this.pageChangeDetect){
    if(this.resHeader_filter?.length != 0)
      this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
    else
      this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
  }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.Employeedetails.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
      if(this.resHeader_filter?.length != 0){
      this.headerFilterImplementation(this.resHeader_filter)
      }
        else { this.headerFilterImplementation(res?.header_list) }
    }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader           = false;
      this.pageChangeDetect = false
    });
}
/******** Personal Details REPORT  ****/
personalDetailsReport(){
  this.loader             = true;
  this.reportName         = "Personal";
  this.appliedFilter      = [];
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.employeereportService.personalDetailsReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res.data;
      this.lastPage = res?.count;
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
    this.nodata = res.data?.length==0?true:false;
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.checkListElements();
    /**dynamic data table**/
    // this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
      this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
      this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.personaldetails.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0){
      this.headerFilterImplementation(this.resHeader_filter)
      }
        else { this.headerFilterImplementation(res?.header_list) }
    }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader           = false;
      this.pageChangeDetect = false
    });
}
/******** Login REPORT  ****/
loginReport(){
  this.loader             = true;
  this.reportName         = "Employee Login Report";
  this.appliedFilter      = [];
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.employeereportService.loginReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Login date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Login date']);
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
          this.dataSource[i]['Login time'] = this.appservice.timeFormatDisplay(this.dataSource[i]['Login time']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      // this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter        =  res.applied_filter;
      this.dateSetup            =  res.applied_filter;
      if(res.applied_filter?.date_of_login && res?.applied_filter?.date_of_login?.length !=0 && res.applied_filter?.date_of_login != 'last_15_days' &&res.applied_filter?.date_of_login != 'last_7_days'  && res.applied_filter?.date_of_login!='last_month' && res.applied_filter?.date_of_login != 'this_month'){
        this.dateSplit = res?.applied_filter?.date_of_login?.split(",")
      if(this.dateSplit!= undefined && res.applied_filter?.date_of_login != 'last_month' && res.applied_filter?.date_of_login != 'this_month' && res.applied_filter?.date_of_login != 'last_15_days' &&res.applied_filter?.date_of_login != 'last_7_days' ){
        this.loginForm.get('date_of_login')?.setValue(true);
        this.loginForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
        this.loginForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
       }else{
           this.loginForm.get('date_of_login')?.reset(res.applied_filter.date_of_login)
       }
      }
    this.nodata = res.data?.length==0?true:false;
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.checkListElements();
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0){
      this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      }
      else{
      this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
      }
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.loginForm.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
      }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        if(this.dateSplit!= undefined &&this.resDataFilter.date_of_login != 'last_month'&&this.resDataFilter.date_of_login != 'last_15_days' &&this.resDataFilter.date_of_login != 'last_7_days' && this.resDataFilter.date_of_login != 'this_month'){
          this.loginForm.get('date_of_login')?.setValue(true);
          this.loginForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
          this.loginForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
        }else{
          this.loginForm.get('date_of_login')?.reset(this.resDataFilter.date_of_login);
        }
      }
    }
  /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0){
      this.headerFilterImplementation(this.resHeader_filter)
      }
        else { this.headerFilterImplementation(res?.header_list) }
    }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader           = false;
      this.pageChangeDetect = false
    });
}
// Resignation report
resignationReport(){
  this.loader             = true;
  this.reportName         = "Resignation";
  this.appliedFilter      = [];
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.employeereportService.resignationReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
   if(res.data?.length != 0){
      this.dataSource       = res.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
          this.dataSource[i]['Relieving Date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Relieving Date']);
          this.dataSource[i]['Relieving Date as per policy'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Relieving Date as per policy']);
          this.dataSource[i]['Resignation date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Resignation date']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
      this.resDataFilter     = res?.data_filter;
      this.resHeader_filter  = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      this.dateSetup =  res.applied_filter
      if(res.applied_filter?.resignation_date && res.applied_filter?.resignation_date != 'last_15_days' &&  res.applied_filter?.resignation_date != 'last_week'  && res.applied_filter?.resignation_date !='last_month' && res.applied_filter?.resignation_date != 'this_month'){
        this.dateSplit = res.applied_filter?.resignation_date?.split(",")
        if(this.dateSplit!= undefined && res.applied_filter?.resignation_date != 'last_month' && res.applied_filter?.resignation_date != 'this_month' && res.applied_filter?.resignation_date != 'last_15_days' && res.applied_filter?.resignation_date != 'last_week' ){
        this.resignationForm.get('resignation_date')?.setValue(true);
        this.resignationForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
        this.resignationForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
       }else{
           this.resignationForm.get('resignation_date')?.reset(res.applied_filter.resignation_date)
       }
      }
    this.nodata = res.data?.length==0?true:false;
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.checkListElements();
    /**dynamic data table**/
  if(!this.resetOffset && !this.pageChangeDetect){
    if(this.resHeader_filter?.length != 0)
      this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
    else
      this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
  }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resignationForm.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        if(this.dateSplit!= undefined &&this.resDataFilter.resignation_date != 'last_month'&&this.resDataFilter.resignation_date != 'last_15_days' &&this.resDataFilter.resignation_date != 'last_week' && this.resDataFilter.resignation_date != 'this_month'){
          this.resignationForm.get('resignation_date')?.setValue(true);
          this.resignationForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
          this.resignationForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
        }else{
          this.resignationForm.get('resignation_date')?.reset(this.resDataFilter.resignation_date);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
       if(this.resHeader_filter?.length != 0){
      this.headerFilterImplementation(this.resHeader_filter)
      }
      else
      this.headerFilterImplementation(res?.header_list)
    }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader           = false;
      this.pageChangeDetect = false
  });
}
checkToday(start:any,end:any){
  const compareValue = start.localeCompare(end)
  if(compareValue == 0)
    return this.appservice.dateFormatDisplay(start)
  else
    return `${this.appservice.dateFormatDisplay(start)} - ${this.appservice.dateFormatDisplay(end)}`
}
headerFilterImplementation1(data:any,headerList:any){
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i1=0;i1<elements?.length;i1++){
      var elementss = <HTMLInputElement>elements[i1];
          elementss.checked = false;
    }
  }, 100);
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      for(let j=0;j<data?.length;j++){
        if(element.defaultValue == data[j]){
          element.disabled  = false;
          element.checked = true;
        }
      }
    }
  }, 1000);
}
headerFilterImplementation(headerFilter:any){
  this.header_filter = [];
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i1=0;i1<elements?.length;i1++){
      var elementss = <HTMLInputElement>elements[i1];
      elementss.checked = false;
    }
  }, 100);
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      for(let j=0;j<headerFilter?.length;j++){
        if(element.defaultValue == headerFilter[j]){
          element.checked   = true;
          element.disabled  = false;
          this.checkColumn.push(headerFilter[j]);
          this.header_filter.push(headerFilter[j]);
        }
      }
    }
  }, 1000);
  for (let i = 0; i < headerFilter?.length; i++) {
    const element = headerFilter[i];
    if (!this.displayedColumns.includes(element)) {
      this.displayedColumns.push(element);
    }
  }
}
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    if(this.dataSource != undefined && this.dataSource != null){
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort      = this.sort;
    }
  }
  headerFilterImplementations(headerFilter:any){
    this.header_filter = [];
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i1=0;i1<elements?.length;i1++){
        var elementss = <HTMLInputElement>elements[i1];
        elementss.checked = false;
      }
    }, 100);
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        for(let j=0;j<headerFilter?.length;j++){
          if(element.defaultValue == headerFilter[j]){
            element.checked   = true;
            element.disabled  = false;
            this.checkColumn.push(headerFilter[j]);
            this.header_filter.push(headerFilter[j]);
          }
        }
      }
    }, 1000);
    for(let i=0; i< headerFilter?.length; i++){
      this.displayedColumns.push(headerFilter[i]);
    }
  }
  checkListElement(value:any){
    this.checkListValue = value
    if(this.checkListValue){
      this.checkListElements();
    }
  }
  checkListElements(){
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for(let i=0;i<elements?.length;i++){
        var element = <HTMLInputElement>elements[i];
        for(let j=0;j<this.columns1?.length;j++){
          if(element.defaultValue == this.columns1[j]){
            // element.checked   = true;
            // element.disabled  = true;
             element.disabled  = false;
          }
        }
      }
    }, 1000);
  }
  dynamicHeader(data_length:any,header_list:any,check_list:any){
    // this.nodata           = data_length==0?true:false;
    this.columns1         = header_list.slice();
    // this.displayedColumns = this.columnsArr?.length > 0 ? this.dupliCateRemove(this.columnsArr) : this.dupliCateRemove(this.columns1)
    this.displayedColumns = this.dupliCateRemove(this.columns1)
    this.columnOptions1   = this.columns1;
  }
  dupliCateRemove(data:any){
    const uniqueColumnsSet = new Set();
    data.filter((column:any) => {
      if (!uniqueColumnsSet.has(column)) {
        uniqueColumnsSet.add(column);
        return true;
      }
      return false;
    });
    return Array.from(uniqueColumnsSet)
  }
  scheduleReportFunction(){
    this.isFilter       = true;
    this.scheduleText = '';
    if(this.resDataFilter != undefined && this.resDataFilter != null  && this.resHeader_filter != undefined  && this.resHeader_filter != null){
      if((!!Object.keys(this.resDataFilter)?.length == true || this.resHeader_filter?.length) && (this.submitted == false)){
        this.scheduleReport = true;
        this.scheduleText = 'Schedule the report with filters';
      }else if(this.submitted == true){
        this.scheduleReport = true;
        this.scheduleText = 'Save filters and schedule the report'
      }
      else{
        this.applyFilter    = false;
        this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
      }
    }else{
      this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
    }
  }
   /********* FILE EXPORT FUNCTION ****************/
   extremeLimit : boolean = false
   export() {
    let limit = this.lastPage
    let offset = 0
    this.fullData = []
    if(this.initialLoaded){
      this.loader = true
      if(this.reportName == 'Snapshot'){
        this.employeereportService.snapshotReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData  = res?.data;
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
                this.fullData[i]['Date of birth']   = this.appservice.dateFormatDisplay(this.fullData[i]['Date of birth']);
                this.fullData[i]['Date of Confirmation']   = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Confirmation']);
                this.fullData[i]['Date Of leaving']   = this.appservice.dateFormatDisplay(this.fullData[i]['Date Of leaving']);
                this.fullData[i]['Created date']   = this.appservice.dateFormatDisplay(this.fullData[i]['Created date']);
                this.fullData[i]['Date of Resignation']   = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Resignation']);
                this.fullData[i]['Date of retirement']   = this.appservice.dateFormatDisplay(this.fullData[i]['Date of retirement']);
                this.fullData[i]['Date of settlement']   = this.appservice.dateFormatDisplay(this.fullData[i]['Date of settlement']);
              }
          }
          }
          this.commonLogic(this.fullData)
        })
      }
      else if(this.reportName == 'Bank Details'){
        this.employeereportService.bankDetailsReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData  = res?.data;
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              }
          }
        }
          this.commonLogic(this.fullData)
        })
      }
      else if(this.reportName == 'Employee Details'){
        this.employeereportService.employeeDetailsReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData  = res?.data;
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of Confirmation'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Confirmation']);
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
                this.fullData[i]['Date of Resignation'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Resignation']);
                this.fullData[i]['Date of birth'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of birth']);
                this.fullData[i]['Date of leaving'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of leaving']);
                this.fullData[i]['Date of marriage'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of marriage']);
                this.fullData[i]['Father date of birth'] = this.appservice.dateFormatDisplay(this.fullData[i]['Father date of birth']);
                this.fullData[i]['Mother date of birth'] = this.appservice.dateFormatDisplay(this.fullData[i]['Mother date of birth']);
                this.fullData[i]['Spouse date of birth'] = this.appservice.dateFormatDisplay(this.fullData[i]['Spouse date of birth']);
                this.fullData[i]['Date of settlement'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of settlement']);
              }
          }
          }
          this.commonLogic(this.fullData)
        })
      }
      else if(this.reportName == 'Personal'){
        this.employeereportService.personalDetailsReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData  = res?.data;
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              }
          }
          }
          this.commonLogic(this.fullData)
        })
      }
      else if(this.reportName == 'Employee Login Report'){
        this.employeereportService.loginReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData  = res?.data;
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              }
          }
          }
          this.commonLogic(this.fullData)
        })
      }
      else if(this.reportName == 'Resignation'){
        this.employeereportService.resignationReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
          if(res?.data?.length != 0){
            this.fullData  = res?.data;
            if(this.fullData?.length > 0){
              for(let i=0; i<this.fullData?.length;i++){
                this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
                this.fullData[i]['Relieving Date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Relieving Date']);
                this.fullData[i]['Relieving Date as per policy'] = this.appservice.dateFormatDisplay(this.fullData[i]['Relieving Date as per policy']);
                 this.fullData[i]['Resignation date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Resignation date']);
              }
          }
          }
          this.commonLogic(this.fullData)
        })
      } else if (this.reportName == 'Disable Portal Acccess') {
        this.employeereportService.portalAccessReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res.data?.length != 0) {
            this.fullData = res?.data;
            if (this.fullData?.length > 0) {
              for (let i = 0; i < this.fullData?.length; i++) {
                this.fullData[i]['Last login'] = this.appservice.isValidDateTime(this.fullData[i]['Last login']) ? this.appservice.datetimeconvert(this.fullData[i]['Last login']) : '-';
                this.fullData[i]['Enabled on'] = this.appservice.isValidDateTime(this.fullData[i]['Enabled on']) ? this.appservice.datetimeconvert(this.fullData[i]['Enabled on']) : '-';
                this.fullData[i]['Disabled on'] = this.appservice.isValidDateTime(this.fullData[i]['Disabled on']) ? this.appservice.datetimeconvert(this.fullData[i]['Disabled on']) : '-';
                this.fullData[i]['Date of joining'] = this.appservice.isValidDateTime(this.fullData[i]['Date of joining']) ? this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']) : '-';
      }
    }
          } this.commonLogic(this.fullData)
        })
  }
    }
  }
  commonLogic(data:any){
    let column = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject : any = {};
      for (const key of column) {
          if (obj.hasOwnProperty(key)) {
              newObject[key] = obj[key];
          }
      }
      newArray.push(newObject);
    }
    if(data != undefined )
     this.exportExcel(newArray, this.reportName);
     this.loader = false
     this.extremeLimit = false
     this.cd.detectChanges()
  }
  excelHeaders: string[] = [];
  templateToExcel:string[][] =[]
  public exportExcel(jsonData: any[], fileName: string): void {
    if(!this.nodata){
     const ws: XLSX.WorkSheet  = XLSX.utils.json_to_sheet(jsonData);
     const wb: XLSX.WorkBook   = { Sheets: { 'data': ws }, SheetNames: ['data'] };
     const excelBuffer: any    = XLSX.write(wb,{ bookType: 'xlsx', type: 'array'});
     this.saveExcelFile(excelBuffer, fileName);
     }
    else{
      for(let i=0;i<this.displayedColumns.length;i++){
      this.excelHeaders.push(this.displayedColumns[i])
    this.templateToExcel = [this.excelHeaders,[]];
     }
    const wss: XLSX.WorkSheet=XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const wbb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
    XLSX.writeFile(wbb,fileName + this.fileExtension)
  }
}
 private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    if(this.nodata!=true)
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str.split('_');
    for (i=0; i<frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  tagMultiDataFunction(data:any){
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if(data?.length == 1){
      return this.tagMultiData;
    }else{
      this.tagMultiCnt = '+'+JSON.stringify(data?.length-1);
      return this.tagMultiData;
    }
  }

   // Reorder Arr
   reorderArray(arr1:any, arr2:any) {
    const set1 = new Set(arr1);
    const matchingItems = arr2.filter((item:any) => set1.has(item));
    const extraItems = arr2.filter((item:any) => !set1.has(item));
    matchingItems.sort((a:any, b:any) => arr1.indexOf(a) - arr1.indexOf(b));
    return matchingItems.concat(extraItems);
  }

applyTagClose(rptName:any,controlName:any){
  Object.keys(this.appliedFilter).length > 0 || this.columnApplied ? this.filterSubmit = true : this.filterSubmit = false;
  this.resetOffset  = true
  controlName = controlName == 'emp_type' ? 'employee_type'  : controlName
  controlName = controlName == 'emp_status' ? 'employment_status' : controlName

  if(rptName == 'Snapshot'){
      this.callemp= true
      this.closeControl = controlName
    } else if (rptName == 'Bank Details') {
    this.callemp= true
    this.closeControl = controlName
    } else if (rptName == 'Employee Details') {
      this.callemp= true
      this.closeControl = controlName
    } else if (rptName == 'Personal') {
        this.callemp= true
        this.closeControl = controlName
    } else if (rptName == 'Employee Login Report') {
          this.callemp= true
          this.closeControl = controlName
    } else if (rptName == 'Resignation') {
            this.callemp= true
            this.closeControl = controlName
    } else if (rptName == 'Disable Portal Acccess') {
      this.callemp = true
      this.closeControl = controlName
            }
          }
// Header checkbox change
checkChange : boolean  = false
headerFilterAdd(e:any,index:any){
  this.checkChange = true
  this.columnApplied = true
  this.header_filter=this.dupliCateRemove(this.header_filter)
  if(e.target.checked){
    this.submitted = true;
    this.checkColumn.push(e.target.value);
    this.header_filter.push(e.target.value);
  }else{
    if(this.header_filter.length==1){
      var elements = document.getElementsByName("check");
      setTimeout(() => {
          var element = <HTMLInputElement>elements[index];
            element.checked = true;
      })
    }
    else{
      this.checkColumn = this.dupliCateRemove(this.checkColumn)
      const index: number        = this.checkColumn.indexOf(e.target.value);
      const header_index: number = this.header_filter.indexOf(e.target.value);
      const column_1: number     = this.columns1.indexOf(e.target.value);
      this.checkColumn.splice(index, 1);
      this.header_filter.splice(header_index,1);
      this.columns1.splice(column_1,1);
    }
  }
}
resetColumnFilterFunction(){
  if(this.resHeader_filter?.length != 0){
    this.resetFilter = true;
    this.is_colfilter = true;
  }else{
    this.resetAllUnDefaultColumns();
  }
  this.columnChangedArr = []
}
 // test start
resetAllUnDefaultColumns(){
  this.checkFilterSubmit  = false;
  this.checkColumn = []
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      if(element.disabled == false)
        element.checked = false;
    }
    for(let j=0;j<this.header_filter?.length;j++){
      const index: number        = this.displayedColumns.indexOf(this.header_filter[j]);
      this.displayedColumns.splice(index, 1);
    }
    if(this.resHeader_filter?.length==0){
      this.dynamicHeader(this.defaultHeaderList?.length,this.defaultHeaderList,this.columns2);
      this.headerFilterImplementation(this.defaultHeaderList)
    }
    this.header_filter = []
    this.columnsArr =[]
  }, 1000);
  if(this.resHeader_filter?.length != 0){
    this.callAllReportsFunctions()
  }
}
// test end
columnApplied : boolean = false
headerFilterApply(){
  if(!this.checkChange){
    return
  }
  let array3 = this.columns1?.concat(this.checkColumn);
  array3     = array3?.filter((item:any,index:any)=>{
      return (array3.indexOf(item) == index)
  })
  if(this.columnChangedArr?.length !=0){
    array3 = this.reorderArray(this.columnChangedArr,array3)
  }
  this.displayedColumns   = array3;
   this.columnsArr  = this.displayedColumns;
  if(this.checkColumn?.length > 0 && this.columnApplied)
    this.checkFilterSubmit  = true;
  else
    this.checkFilterSubmit  = false;
  // this.checkFilterSubmit  = true;
  this.checkChange = false
}
ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort      = this.sort;
    this.getCompanyList();
    this.getBUList();
    this.getDepList();
    this.getDesignationList();
    this.getGradeList();
    this.getBranchList();
    this.getEmployeeList();
  this.selectAllForDropdownItems(this.empstatusDD);
  this.selectAllForDropdownItems(this.emptypeDD);
}
getCompanyList(){
  this.roleService.getCompanyList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.companyDD = res.body;
      this.selectAllForDropdownItems(this.companyDD);
    }
  });
}
getBUListFunction(){
  if(this.reportName == 'Snapshot'){
    this.Snapshot.get('bu')?.setValue('');
  }
  if(this.reportName == 'Bank Details'){
    this.bankdetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Employee Details'){
    this.Employeedetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Personal'){
    this.personaldetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Employee Login Report'){
    this.loginForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Resignation'){
    this.resignationForm.get('bu')?.setValue('');
  }
  this.buDD = [];
  this.getBUList();
}
getBUList(){
  let comp;
  if(this.reportName == 'Snapshot'){
    comp = this.Snapshot.value.company;
    this.Snapshot.get('bu')?.setValue('');
  }
  if(this.reportName == 'Bank Details'){
    comp = this.bankdetails.value.company;
    this.bankdetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Employee Details'){
    comp = this.Employeedetails.value.company;
    this.Employeedetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Personal'){
    comp = this.personaldetails.value.company;
    this.personaldetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Employee Login Report'){
    comp = this.loginForm.value.company;
    this.loginForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Resignation'){
    comp = this.loginForm.value.company;
    this.loginForm.get('bu')?.setValue('');
  }
  if(comp != undefined && comp != null && comp != ''){
    this.employeereportService.getBuList(comp).subscribe((res: any) => {
      this.buDD = res;
      this.selectAllForDropdownItems(this.buDD);
  });
  }
}
getDepList(){
  this.roleService.getDepartmentList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.DepDD = res.body;
        this.selectAllForDropdownItems(this.DepDD);
    }
  });
}
getDesignationList(){
  this.roleService.getDesignationList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.DesignationDD = res.body;
        this.selectAllForDropdownItems(this.DesignationDD);
    }
  });
}
getGradeList(){
  this.roleService.getGradeList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.gradeDD = res.body;
        this.selectAllForDropdownItems(this.gradeDD);
    }
  });
}
getBranchList(){
  this.employeereportService.getBranchList().subscribe((res: any) => {
    this.BranchDD = res;
    this.selectAllForDropdownItems(this.BranchDD);
  });
}
getEmployeeList(){
  this.leave_service.getEmpList(true)
  .subscribe((res: any) => {
    // if(res.status == 200) {
      if (res?.length > 0) {
        for(let i=0;i<res?.length;i++){
          res[i]['name'] = res[i]['first_name'];
          if(res[i]['first_name'] != null)
          res[i]['name'] = res[i]['first_name']+" ";
          if(res[i]['middle_name'] != null)
          res[i]['name'] += res[i]['middle_name']+" ";
          if(res[i]['last_name'] != null)
          res[i]['name']   += res[i]['last_name'];
          res[i]['name']   +=" ("+res[i]['employee_code']+")";
        }
        this.employeeDropdown = res;
        let len = this.employeeDropdown.length;
        for (let i = 0; i < len; i++) {
        this.employeeDropdown[i]['fullname']  = "";
        if(this.employeeDropdown[i]['first_name'] != null)
         this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name']+" ";
        if(this.employeeDropdown[i]['middle_name'] != null)
         this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name']+" ";
        if(this.employeeDropdown[i]['last_name'] != null)
         this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
        }
        // this.selectAllForDropdownItems(this.employeeDropdown);
      }
    // }
  })
}
dojFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
  return (d >= dateRange[0] && d<= dateRange[1])
}
salFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
  return (d >= dateRange[0] && d<= dateRange[1])
}
// Loader
loaderFn(value:any){
  this.loader = value
}
onChangEmpStatus(event:any, data: any){ // Use appropriate model type instead of any
  const index: number = this.empstatArr.indexOf(event.target.value);
  if(event.target.checked){
    this.empstatArr.push(data);
  }else{
    this.empstatArr.splice(index, 1);
  }
}
saveFilterClicked = false
saveFilter(){
  this.columnsArr = []
  if(this.filterSaveForm.value.filter == true){
    this.saveFilterClicked = true
    this.filterMemorizeFunction('saveFilter','');
  }else{
    this.applyFilter  = false;
    this.submitted    = false;
    this.filterSubmit = false;
    this.checkFilterSubmit = false;
  }
  this.router.navigate(['/employee-report'])
}

// Arrange data based on 1st & remove if not in 2nd
rearragneAndRemove(arr1:any,arr2:any){
  let result = arr1.filter((item:any) => arr2.includes(item))
  let additionalItems = arr2.filter((item:any) => !arr1.includes(item));
  result.push(...additionalItems);
  return result
}

filterMemorizeFunction(data:any,data1:any){
  if(this.header_filter?.length > 0){
    var mySet = new Set(this.header_filter);
    this.header_filter = [...mySet];
  }
  let headerNeeded = !this.arrayAreSame(this.displayedColumns, this.defaultHeaderList)
  if(this.columnChangedArr?.length !=0){
    this.columnChangedArr = this.rearragneAndRemove(this.columnChangedArr,this.checkColumn)
  }
    if (this.reportName == 'Resignation')
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.appResignation.getData() : ""
    else if (this.reportName == 'Snapshot') {
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.appSnapshot.getData() : ""
    } else if (this.reportName == 'Bank Details') {
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.appbank.getData() : ""
    } else if (this.reportName == 'Employee Details') {
    this.dataFilter =Object.keys(this.appliedFilter).length > 0 ? this.appempdetails.getData() : ""
    } else if (this.reportName == 'Personal') {
    this.dataFilter =Object.keys(this.appliedFilter).length > 0 ? this.apppersonaldetails.getData() : ""
    } else if (this.reportName == 'Employee Login Report') {
    this.dataFilter =Object.keys(this.appliedFilter).length > 0 ? this.applogin.getData() : ""
  }else{
    // (this.reportName == 'Disable Portal Acccess')
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.disableAccess.getData() : ""
  }

  if(this.dataFilter == undefined || this.dataFilter == null || this.dataFilter == "" || this.childCall){
    this.dataFilter = JSON.stringify({});
  }
  this.employeereportService.filterMemorize({'data_filter' : this.childCall == true ? JSON.stringify({}): this.dataFilter, 'header_filter' : this.columnChangedArr?.length != 0 ? JSON.stringify(this.columnChangedArr) : ((headerNeeded && !this.is_colfilter) ?  JSON.stringify(this.displayedColumns) : JSON.stringify([])), 'is_active' : true, 'report' : Number(this.reportId)}).subscribe((res: any) => {
    if(this.callemp || this.resetFilter){
      this.notify.handleSuccessNotification("Updated Successfully","Success")
    }else{
      this.notify.handleSuccessNotification("Created Successfully","Success")
    }
    this.saveFilterClicked = false
    this.submitted   = false;
    this.filterSubmit = false;
    this.checkFilterSubmit = false
    this.restFilterSave = false
    this.hasChangeCount = 0;
    if(data == 'saveFilter'){
      this.resetFilterControllers('save');
      this.applyFilter = false;
    }
    else if(data == 'resetFilter'){
      this.resetFilter = false;
      this.childCall = false;
      if(data1 == 'dataFilter')
        this.resetFilterControllers('reset');
      else if(data1 == 'colFilter')
        this.resetAllUnDefaultColumns();
      this.viewDetail = false;
    }
    if(data1 == 'saveAndSchedule'){
      this.scheduleReport = false;
      setTimeout(() => {
        if(this.reportId != undefined && this.reportId != null)
          this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/true']);
      }, 3000);
    }
  });
}
fiterResetDisableFunction(){
  if(!!Object.keys(this.resDataFilter)?.length == true)
    this.filterDisable = true;
  else
    this.filterDisable = false;
  }
resetFilterFunction(data:any){
  this.resetReportName = data;
  if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
    if(!!Object.keys(this.resDataFilter)?.length == true){
      this.resetFilter = true;
    }else{
      this.resetFilterControllers('reset');
    }
  }
}
resetFilterControllers(data:any){
  this.viewDetail = false;
  this.filterSubmit = false;
  if(this.reportName == 'Snapshot'){
    this.appSnapshot.resetFilterControllers('reset')
  }
    else if (this.reportName == 'Bank Details') {
    this.appbank.resetFilterControllers('reset')
  }
    else if (this.reportName == 'Employee Details') {
    this.appempdetails.resetFilterControllers('reset')
  }
    else if (this.reportName == 'Personal') {
    this.apppersonaldetails.resetFilterControllers('reset')
  }
    else if (this.reportName == 'Employee Login Report') {
    this.applogin.resetFilterControllers('reset')
  }
    else if (this.reportName == 'Resignation') {
    this.appResignation.resetFilterControllers('reset')
  }
    else if (this.reportName == 'Disable Portal Acccess') {
      this.disableAccess.resetFilterControllers('reset')
}
  }
childCall : boolean = false
resetFilterApply(){
  this.restFilterSave = true
  if(this.is_colfilter == true){
    this.header_filter = [];
    this.childCall = false
    this.filterMemorizeFunction('resetFilter','colFilter');
    this.is_colfilter = false
    this.childFilterSubmit = true;
  }
  else{
    this.childCall =  true
    this.dataFilter = JSON.stringify({});
    this.filterMemorizeFunction('resetFilter','dataFilter');
  }
}
disableDate(){
  return false;
}
confirm():boolean {
  // return false;
  if(this.isFilter){
    return true;
  }
  else{
  if(this.filterSubmit == true || this.checkFilterSubmit == true) {
    this.applyFilter  = true;
    this.filterSubmit = false;
    this.checkFilterSubmit = false;
    return false;
  }else{
    this.applyFilter = false;
    return true;
  }
}
}
reportMemorizeFunction(){
  if(this.reportId != undefined && this.reportId != null){
    if(this.scheduleReportFilterMemorizeForm.value.filter == false){
      if(this.scheduleText == 'Save filters and schedule the report'){
        this.filterMemorizeFunction('saveFilter','saveAndSchedule');
      }else{
        this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/true']);
      }
    }else{
      this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
    }
  }
}
public isChangesSaved() {
  if(this.submitted == true) {
   return confirm("Do you want to discard changes?")
  }
  return true;
}
onCreateGroupFormValueChange(form:any){
  const formValue:any [] = [];
  if(form == 'Snapshot'){
    Object.keys(this.Snapshot.controls).map((key) => {
      const parsedValue = {
        [key]: this.Snapshot?.get(key)?.value,
        changed: this.Snapshot?.get(key)?.dirty
      }
      formValue.push(parsedValue)
    })
  }
  if(form == 'bankdetails'){
    Object.keys(this.bankdetails.controls).map((key) => {
      const parsedValue = {
        [key]: this.bankdetails?.get(key)?.value,
        changed: this.bankdetails?.get(key)?.dirty
      }
      formValue.push(parsedValue)
    })
  }
  if(form == 'Employeedetails'){
    Object.keys(this.Employeedetails.controls).map((key) => {
      const parsedValue = {
        [key]: this.Employeedetails?.get(key)?.value,
        changed: this.Employeedetails?.get(key)?.dirty
      }
      formValue.push(parsedValue)
    })
  }
  if(form == 'personaldetails'){
    Object.keys(this.personaldetails.controls).map((key) => {
      const parsedValue = {
        [key]: this.personaldetails?.get(key)?.value,
        changed: this.personaldetails?.get(key)?.dirty
      }
      formValue.push(parsedValue)
    })
  }
  if(form == 'loginForm'){
    Object.keys(this.loginForm.controls).map((key) => {
      const parsedValue = {
        [key]: this.loginForm?.get(key)?.value,
        changed: this.loginForm?.get(key)?.dirty
      }
      formValue.push(parsedValue)
    })
  }
  if(form)
  if(formValue?.length > 0){
    for(let i=0;i<formValue?.length;i++){
      if(formValue[i]['changed']){
        this.hasChangeCount = this.hasChangeCount + 1;
      }
    }
  }
}
// get f()  { return this.loginForm.controls; }
// After reseting the form from child, main report fn is called
callReport(value:any){
  this.filterSubmit = false
  this.childFilterSubmit = true
  this.page = 1
  this.pageNumber = 0
  this.resetOffset = true
  this.callAllReportsFunctions()
}
  // Submit after filter applied, getting value from child
  submitDone(value:any){
    if(this.callemp && Object.keys(this.resDataFilter)?.length > 0){
      this.filterMemorizeFunction('saveFilter','')
    }else{
      this.callemp = false
    }
    this.limit = value.limit
    this.offset = value.offset
    this.filterFormData = value.filterForm
    this.filterSubmit = !this.filterFormData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
    this.childFilterSubmit = false
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
    this.callAllReportsFunctions()
    this.pageChangeDetect = false
    this.viewDetail = false
  }
filterSubmits(value:any){
  this.filterSubmit = value
}
// Reset filter btn
resetFilterFn(value:any){
  this.resetFilter = value
  this.columnApplied=false
}
// FIlter Panel ON and OFF
panel(value:any){
  this.viewDetail = value
}
 // Datafilters for filter save
 dataFilters(value:any){
  this.dataFilter = value.data
}
empTypeCheck(data:any,type:any, length:any){
  var elements = document.getElementsByName("emp_type");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element1 = <HTMLInputElement>elements[i];
      element1.checked = false;
    }
    if(length == 1){
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      for(let j=0;j<data?.length;j++){
        if(element.defaultValue == data[j]){
          element.checked = true;
          if(type == 'default')
            this.tempArr.push(data[j]);
        }
      }
    }
  }
  }, 1000);
}
empStatusCheck(data:any,type:any, length:any){
  var elements = document.getElementsByName("emp_status");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element1 = <HTMLInputElement>elements[i];
      element1.checked = false;
    }
    if(length == 1){
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      for(let j=0;j<data?.length;j++){
        if(element.defaultValue == data[j]){
          element.checked = true;
          if(type == 'default')
            this.empstatArr.push(data[j]);
        }
      }
    }
  }
  }, 1000);
}
// copied for avoiding the errors on other section currently working on snapshot
dynamicHeaders(value:any){
  this.responseLength = value.length
  this.headerCheck = value.header
  this.checkList = value.checklist
  this.nodata           = this.responseLength==0?true:false;
  this.columns1         = this.headerCheck;
  // this.columns2         = check_list;
  this.displayedColumns = this.columns1
  /**set Options**/
  // this.columnOptions    = this.columns2;
  this.columnOptions1   = this.columns1;
  // this.columnsControl.setValue(this.columnOptions1);
}
limit = 20
offset = 0
lastPage : any
initialLoaded : boolean  = false
pageNumber :  any = 1
pageResetIfFlag = true;
pageResetElseFlag = false
pageChanged(val: any) {
  this.pageNumber = val-1
  this.offset = this.employeereportService.calculateOffset(val-1)
  this.cd.detectChanges()
  this.pageChangeDetect = true
  this.callAllReportsFunctions()
  if (!this.columnApplied) {
    this.columnApplied = false;
  } else {
    this.columnApplied = true;
  }
}
  callParentFunction(val:any){
    this.initialLoaded = val
  }
  resetOffset : boolean = false
  resetCall(val:any){
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true;
  }
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  direction : string = 'asc'
  sortProperty : string = 'Employee Code'
  ordering : any = 'Employee Code'
  onSort(val: any) {
    if (this.sortProperty === val) {
      this.direction = this.direction === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortProperty = val;
      this.direction = 'desc';
    }
    const active = val.split(' ').join('_').toLowerCase();
    const newOrder = this.direction === 'desc' ? '-' : '';
    this.ordering = newOrder + (val.includes(' ') ? active : val.toLowerCase())
    this.cd.detectChanges()
    this.filterFormData = this.filterFormData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.pageChangeDetect = true
    this.columnApplied == true ? this.columnApplied = true : this.columnApplied = false
    this.callAllReportsFunctions()
    // this.columnApplied = false
  }
  arrayAreSame(res1:any,res2:any){
    if(res1.length !== res2.length){
      return false
    }
    for(let i=0;i<res1.length;i++){
      if(res1[i] !== res2[i]){
        return false
      }
    }
    return true
  }
  callAllReportsFunctions(){
    if (this.reportName == 'Snapshot')
      this.snapshotReport();
    else if (this.reportName == 'Bank Details')
      this.bankdetailsReport()
    else if (this.reportName == 'Employee Details')
      this.EmployeedetailsReport()
    else if (this.reportName == 'Personal')
    this.personalDetailsReport()
    else if (this.reportName == 'Employee Login Report')
      this.loginReport()
    else if (this.reportName == 'Resignation')
      this.resignationReport()
    else if (this.reportName == 'Disable Portal Acccess')
      this.portalAccessReport();
    }
  disabledForm = this.formBuilder.group({
    ouFilterForm: this.OuFilterForm,
    specific_month: null,
    specific_year: null,
    disabled_on: ['this_month', Validators.required],
    disabled_by: ''
  })
  yearListFunction(){
    this.selectedYear = new Date().getFullYear();
    this.attendanceReportService.yearListDropdown().subscribe((res: any) => {
     if(res?.created_year == this.selectedYear){
        this.Years.push(res?.created_year)
      }
      else{
        for(let i=this.selectedYear;i>=res?.created_year;i--){
          this.Years.push(i)
        }
      }
    })
  }
  // Disable Portal Acccess report
  portalAccessReport() {
    this.loader = true;
    this.reportName = "Disable Portal Acccess";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.employeereportService.portalAccessReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res.data;
        this.lastPage = res?.count
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < this.dataSource?.length; i++) {
            this.dataSource[i]['Last login'] = this.appservice.isValidDateTime(this.dataSource[i]['Last login']) ? this.appservice.datetimeconvert(this.dataSource[i]['Last login']) : '-';
            this.dataSource[i]['Enabled on'] = this.appservice.isValidDateTime(this.dataSource[i]['Enabled on']) ? this.appservice.datetimeconvert(this.dataSource[i]['Enabled on']) : '-';
            this.dataSource[i]['Disabled on'] = this.appservice.isValidDateTime(this.dataSource[i]['Disabled on']) ? this.appservice.datetimeconvert(this.dataSource[i]['Disabled on']) : '-';
            this.dataSource[i]['Date of joining'] = this.appservice.isValidDateTime(this.dataSource[i]['Date of joining']) ? this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']) : '-';
}
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.fullData = this.dataSource?.filteredData;
      }
      this.resDataFilter = res?.data_filter;
      this.resHeader_filter = res?.header_filter;
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;
      this.dateSetup = res.applied_filter
      this.appliedFilter.disabled_on = res.applied_filter?.disabled_at
      delete this.appliedFilter.disabled_at
      if (res.applied_filter?.disabled_on != 'last_month' && res.applied_filter?.disabled_on != 'this_month') {
        this.dateSplit = res.applied_filter?.disabled_on?.split("-")
        if (this.dateSplit != undefined && res.applied_filter?.disabled_on != 'last_month' && res.applied_filter?.disabled_at != 'this_month') {
          this.disabledForm.get('disabled_on')?.setValue(true);
          this.resignationForm.get('specific_month')?.setValue(new Date(this.dateSplit[1]))
          this.resignationForm.get('specific_year')?.setValue(new Date(this.dateSplit[0]))
        } else {
          this.disabledForm.get('disabled_on')?.reset(res.applied_filter.disabled_on)
        }
      }
      this.appliedFilter.disabled_on = this.appliedFilter.disabled_on == 'this_month' ? 'current_month' : this.appliedFilter.disabled_on
      this.nodata = res.data?.length == 0 ? true : false;
      this.setDataSourceAttributes();
      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.checkListElements();
      /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.disabledForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if (this.resDataFilter?.emp_status.length > 0) {
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if (this.resDataFilter?.emp_type.length > 0) {
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
      }
      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0) {
          this.headerFilterImplementation(this.resHeader_filter)
        }
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    });
  }
}
