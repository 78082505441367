<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">{{actiontitle}} Attendance Capture Policy</h5>
        <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <aside class=" config-sidebar">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
            <li [ngbNavItem]="1">
                <a ngbNavLink class="step"
                    [class]="{'success': attendanceCaptureForm['controls'].policydetails.valid || attendanceCaptureForm['controls'].policydetails.disabled}">
                    <div class="count">1</div>
                    <div class="content">
                        <span>Step 1</span>
                        <h6>Policy Details</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-attendance-capture-policy-details [formGp]="policy_details" [editflag]="editflag" [id]="id"
                        (submitmethod)="submitMethod()"></app-attendance-capture-policy-details>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink class="step"
                    [class]="{'success': attendanceCaptureForm['controls'].assign.valid || attendanceCaptureForm['controls'].assign.disabled}">
                    <div class="count">2</div>
                    <div class="content">
                        <span>Step 2</span>
                        <h6>Applicability</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-common-applicability [heading]="'Applicability'" [formGp]="assignForm" [isEditForm]="editflag" [disabled]="disabled"
                        (submitMethod)="submitForm()"></app-common-applicability>
                </ng-template>
            </li>
        </ul>
    </aside>
    <main class=" config-container">
        <div [ngbNavOutlet]="nav"></div>
         <!-- <pre>Form Values: {{attendanceCaptureForm.value | json}}</pre> -->
    </main>
</div>
