import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-add-unreported-expense',
  templateUrl: './add-unreported-expense.component.html',
  styleUrls: ['./add-unreported-expense.component.scss']
})
export class AddUnreportedExpenseComponent implements OnInit {

  // test start
  @Input()unreportedExpense :any;


  @Input() from:any
  @Input() unReportedReceiptList:any;
  @Input() currency:any;
  @Input() loader:any;

  @Output() addreceipt = new EventEmitter();
  @Output() closeUnReportedReceipt  = new EventEmitter();


  page = 1;
  pageSize = 20;
  searchString: any = "";
  // currenecyemp:any;
  selectedReceipt:any = [];
  // test end
  constructor(
    public appservice: AppService,
  ) { }

  ngOnInit(): void {
    // let userData        = JSON.parse(window.sessionStorage.getItem('userData') || '{}');
    // this.currenecyemp       = userData.currency;
  }

  checkall(event:any){
    this.selectedReceipt = [];
    var newList  = this.filterIt();
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      if(event.target.checked == true){
      item.checked = event.target.checked;
      this.selectedReceipt?.push(item);
      }else{
        item.checked = false;
        this.selectedReceipt = [];
      }
    })
  }

  checkallSelected(){
    var newList  = this.filterIt();
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
          return item.checked;
      });
    } else {
      return false;
    }
  }

  filterIt() {
    return this.unReportedReceiptList.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey     = obj[key].toString().toLowerCase();
          const tempSearch  = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  close(){
    this.closeUnReportedReceipt.emit();
  }

  addReceipt(){
    this.selectedReceipt = [];
    this.unReportedReceiptList.forEach((item:any, key:any) => {
      if(item.checked == true){
        // if(this.from!='profile')
        //   this.selectedReceipt?.push(item);
        // else
          this.selectedReceipt?.push(item['id']);

      }
    })
    this.addreceipt.emit({'addedreceipt':this.selectedReceipt})
  }

  countSelected(){
    return this.unReportedReceiptList.filter((obj :any) => obj.checked === true)?.length;
  }

}
