import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }


 
  cityList(isactive:any,limit:any,offset:any,search:any,country__in:any,state__in:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/city-list/?is_active=${isactive}&limit=${limit}&offset=${offset}&search=${search}&country__in=${country__in}&state__in=${state__in}`, this.httpOption)
  }
  countryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/country-list/`, this.httpOption)
  }
  stateList(country:string) {
    return this.http.get(`${this.API_PATH}organization/api/v1/region-list/?country=`+country, this.httpOption)
  }
  saveCity(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/city-list/`, body, this.httpOption)
  }
  updateCity(id:number,body: any){
    return this.http.put(`${this.API_PATH}organization/api/v1/city-detail/${id}/`, body, this.httpOption)
  }
  deleteCity(id:number,body: any){
    return this.http.patch(`${this.API_PATH}organization/api/v1/city-detail/${id}/`, body, this.httpOption)
  }
  cityDropdownList(isactive:any,country__in:any,state__in:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?ordering=name&is_active=${isactive}&country__in=${country__in}&state__in=${state__in}`, this.httpOption)
  }
  getCity(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/city-detail/${id}`, this.httpOption)
  }
}