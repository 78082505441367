import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationGroupService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }

  creategroup(data:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/location-group/`, data, this.httpOption)
  }
  getlocationgroupData(status:any,limit:any,offset:any,search:any,order:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/location-group/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`, this.httpOption);
  }
  getSearchList(status:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/location-group-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }
  getSingleData(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/location-group/${id}`, this.httpOption);
  }
  deletegroup(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}expense/api/v1/location-group/${id}/`,data, this.httpOption)
  }
  updategroup(id:number,data:any){
    return this.http.put(`${this.API_PATH}expense/api/v1/location-group/${id}/`,data, this.httpOption)
  }
  stateList(){
    return this.http.get(`${this.API_PATH}expense/api/v1/region-list/`, this.httpOption);
  }
  cityList(state:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/city-list/?state=[${state}]`, this.httpOption);
  }
}
