<form class="">
    <div class="container-fluid p-32 form-container">

        <div class="row row-24">
            <div class="col-12">
                 <span class="text-uppercase fw-500">SUMMARY</span>
            </div>
            <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                    <div class="row row-24">
                        <div class="col-12">
                            <span class="title">REIMBURSEMENT CATEGORY</span>
                        </div>
                        <div class="col-12">
                            <div class="card details-card indicater1 px-16 py-12">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="fw-600 fs-14">{{categorySummaryData[0].name}}</div>
                                        <div class="fw-500 fs-10 text-success mt-1" *ngIf="categorySummaryData[0]?.as_of_date == 'Monthly'">AS OF {{currentMonth}} {{currentYear}}</div>

                                        <div class="fw-500 fs-10 text-success mt-1" *ngIf="categorySummaryData[0]?.as_of_date == 'Yearly'">FISCAL YEAR {{this.appservice.getFinancialYearRange(currentYear)}}</div>
                                    </div>
                                    <div class="col">
                                        <div class="detail-title">Eligible</div>
                                        <div class="detail-desc">{{categorySummaryData[0].entitled | currency:currency}}</div>
                                    </div>
                                    <div class="col">
                                        <div class="detail-title">Claimed</div>
                                        <div class="detail-desc">{{categorySummaryData[0].claimed | currency:currency}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let item of summaryData[0];let i=index">

              <div class="col-12">
                  <div class="card details-card shadow-0 px-24 py-16">
                      <div class="row row-24">
                          <div class="col-12">
                              <span class="title">BILL {{i+1}}</span>
                          </div>
                          <div class="col-md-6">
                              <div class="detail-title">Claim date</div>
                              <div class="detail-desc">{{this.appservice.dateFormatDisplay(item.claim_date)}}</div>
                          </div>
                          <div class="col-md-6">
                              <div class="detail-title">Bill date</div>
                              <div class="detail-desc">{{this.appservice.dateFormatDisplay(item.bill_date)}}</div>
                          </div>
                          <div class="col-md-6">
                              <div class="detail-title">Bill no</div>
                              <div class="detail-desc">{{item.bill_no}}</div>
                          </div>
                          <div class="col-md-6">
                              <div class="detail-title">Bill amount</div>
                              <div class="detail-desc">{{item.bill_amount  |currency: currency}}</div>
                          </div>
                          <div class="col-12" *ngIf="(item.description!='' && item.description!=null)">
                              <div class="detail-title">Description</div>
                              <div class="detail-desc">{{item.description}}</div>
                          </div>

                          <div class="col-12">
                              <div class="detail-title mb-2">Document</div>
                              <div style="width:12.9375rem;">
                                  <div class="card card-c2  bg-secondary" >
                                      <div class="p-12 bg-secondary d-flex align-item-center">

                                          <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                              <span class="tips text-uppercase fs- fw-700 text-trim" >{{this.appservice.getDocName(item.document)}}</span>
                                              <span class="tips-helper ">{{this.appservice.getDocName(item.document)}}</span>

                                              <div class="d-flex gap-8">
                                                <div class="link-primary" (click)="viewDocument(item.document)">
                                                  <i class="bi bi-eye me-1"></i>View
                                                </div>
                                                <div class="link-primary" (click)="this.appservice.downloadFile(item.document,this.appservice.getDocName(item.document))">
                                                  <i class="bi bi-download me-1"></i>Download
                                                </div>
                                              </div>
                                          </div>

                                      </div>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </ng-container>


        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="submitForm()" [disabled]="isCompleted">SAVE</button>
    </footer>
</form>

<!-- un supported view format alert -->
<ng-container *ngIf="alertToggle==true">
  <app-alert-toggle (modals)="closed($event)" [alertToggle]="alertToggle" [alertMsg]="alertMsg"></app-alert-toggle>
</ng-container>
<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
