<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row mb-24">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">Preview</p>
            </div>
          
        </div>
        <div class="row row-24">

            <div class=" mx-auto" style="max-width:600px">
               
                <div class="mail-container p-56">
                    <div class="mail-body radius-4">
                        <div class="mail-header align-items-center">
    
                            <img class="header-img " src="assets\images\mail_images\template-bg2.png" alt="">
                            <div class="fs-32 mb-24 me-auto mail-title">{{previewall.subject}}</div>
                            <img class="header-img ms-auto" src="assets\images\mail_images\template-bg2.png" alt="">
                        </div>
                        
                        <div [innerHTML]="previewall.description" class="mail-content">
                            <!-- <p>{{previewall.description}}</p> -->
                        </div>
                    </div>
                    <div class="mail-footer mb-32">
                        <div>
                            <p>Skizzle </p>
                            <p>2021 Broadway, suit 815</p>
                            <p>Banglore</p>
                        </div>
                        <div class="ms-auto">
                            <p>Unsubscribe</p>
                        </div>
                    </div>
                    <hr class="hr-1  mx-auto" style="width: 4.5rem;">
                </div>
            </div>
            
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" (click)="postdata()">Confirm</button>
      </footer>
</form>