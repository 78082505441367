<nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky top-48">
    <li [ngbNavItem]="1" >
        <a ngbNavLink class="nav-link">Proposed declaration</a>
        <ng-template ngbNavContent>
            <app-my-proposed-declaration></app-my-proposed-declaration>
        </ng-template>
    </li>
    <li [ngbNavItem]="2" >
        <a ngbNavLink class="nav-link">Confirmed declaration</a>
        <ng-template ngbNavContent>
           <app-my-confirm-declaration></app-my-confirm-declaration>
        </ng-template>
    </li>
</nav>
<div [ngbNavOutlet]="nav" ></div>

