<div class="modal modal-c1 {{leaveYearEndModal == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Leave Year End </h4>
        <i class="ms-auto icon-close-lg " (click)="leaveYearEndModal = false"></i>
      </div>
      <div class="modal-body">
        <div class="row row-16">
          <div class="col-12">
            <img src="/assets/images/helper/leave-year-end/LYE-primary.png" alt="" class="img-fluid w-100">
          </div>
          <div class="col-12">
            <div class="card card-c2 border-0 radius-4 align-items-center flex-row bg10 py-8 ps-8 pe-16"
              style="min-height:3.625rem">
              <div class="sq-40 flex-center me-8">
                <i class="icon-info-circle fs-20 text-danger"></i>
              </div>
              <!-- <p class="mb-0 fs-12 fw-500">Leave year end process is overdue for <span class="link-primary1"
                  [ngbPopover]="popContent1" popoverClass="popover-input popover-info" container="body"
                  triggers="hover">{{bannerData?.overdue_length}} policies.</span></p> -->
                  <p class="mb-0 fs-12 fw-500">Leave year end process is overdue for
                    <ng-container *ngIf="bannerData?.overdue_length > 0; else emptyContentes">
                      <span class="link-primary1"
                            [ngbPopover]="popContent1"
                            popoverClass="popover-input popover-info popover-md"
                            container="body"
                            placement="right"
                            triggers="hover"
                            routerLink="/leave-year-end"
                            (click)="leaveYearEndModal = false">{{bannerData?.overdue_length}} policies.
                      </span>
                    </ng-container>
                    <ng-template #emptyContentes><span class="">{{bannerData?.overdue_length}} policies.</span></ng-template>
                    </p>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 border-0 radius-4 align-items-center flex-row bg-warning py-8 ps-8 pe-16"
              style="min-height:3.625rem">
              <div class="sq-40 flex-center me-8">
                <i class="bi bi-calendar3 fs-20 text-warning"></i>
              </div>
              <div>
                <!-- <p class="mb-0 fs-12 fw-500">Leave year end date is approaching for <span class="link-primary1"
                    [ngbPopover]="popContent2" popoverClass="popover-input popover-info" container="body"
                    triggers="hover">{{bannerData?.leaveyear_end_approach_length}} policies.</span></p> -->
                    <p class="mb-0 fs-12 fw-500">Leave year end date is approaching for
                      <ng-container *ngIf="bannerData?.leaveyear_end_approach_length > 0; else emptyContents">
                        <span class="link-primary1"
                              [ngbPopover]="popContent2"
                              popoverClass="popover-input popover-info popover-md"
                              container="body"
                              placement="right"
                              triggers="hover"
                              routerLink="/leave-year-end"
                              (click)="leaveYearEndModal = false">{{bannerData?.leaveyear_end_approach_length}} policies.
                        </span>
                      </ng-container>
                      <ng-template #emptyContents><span class="">{{bannerData?.leaveyear_end_approach_length}} policies.</span></ng-template>
                    </p>
                <p class="mb-0 fs-12 fw-500">You can modify the leave year end settings from leave policy configuration
                  (step 4) 24hours prior to the scheduled leave year end process date.</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 border-0 radius-4 align-items-center flex-row bg-warning py-8 ps-8 pe-16"
              style="min-height:3.625rem">
              <div class="sq-40 flex-center me-8">
                <i class="bi bi-calendar3 fs-20 text-warning"></i>
              </div>
              <div>
                <!-- <p class="mb-0 fs-12 fw-500">Processed auto leave year end for <span class="link-primary1"
                    [ngbPopover]="popContent3" popoverClass="popover-input popover-info" container="body"
                    triggers="hover">{{bannerData?.leaveyear_end_processed_length}} policies.</span></p> -->
                    <p class="mb-0 fs-12 fw-500">Processed auto leave year end for
                    <ng-container *ngIf="bannerData?.leaveyear_end_processed_length > 0; else emptyContent">
                      <span class="link-primary1"
                            [ngbPopover]="popContent3"
                            popoverClass="popover-input popover-info popover-md"
                            container="body"
                            placement="right"
                            triggers="hover"
                            routerLink="/leave-year-end"
                            (click)="leaveYearEndModal = false">{{bannerData?.leaveyear_end_processed_length}} policies.
                      </span>
                    </ng-container>
                    <ng-template #emptyContent><span class="">{{bannerData?.leaveyear_end_processed_length}} policies.</span></ng-template>
                    </p>
                <p class="mb-0 fs-12"> Leave year end process is not completed for {{bannerData?.employeependingcount}}
                  employees,</p>
                <p class="mb-0 fs-12"><span class="link-primary1" (click)="exportData()">Click here</span> to download
                  the report.</p>
                <p class="mb-0 fs-12">To process leave year end for the pending employees manually, navigate to HR >
                  Leave year end.</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 border-0 radius-4 bg21 align-items-center flex-row bg-warning py-8 ps-8 pe-16"
              style="min-height:3.625rem">
              <div class="sq-40 flex-center me-8">
                <i class="icon-info-circle fs-20 text-primary"></i>
              </div>
              <p class="mb-0 fs-12 fw-500">
                You have overall {{bannerData?.pendingrequest}} leave requests are pending for approval.</p>
            </div>
          </div>
          <div class="col-12 flex-center">
            <p class="mb-0 fs-12 fw-500 link-primary py-8" routerLink="/leave-year-end"
              (click)="leaveYearEndModal = false">View details</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #popContent1>
  <ul class="fs-12 mb-0 list-type-none li-12 px-8 py-16">
    <li *ngFor="let item of bannerData.overdue_list">{{item}}</li>
  </ul>
</ng-template>

<ng-template #popContent2>
  <ul class="fs-12 mb-0 list-type-none li-12 px-8 py-16">
    <li *ngFor="let item of bannerData.leaveyear_end_approach_list">{{item}}</li>
  </ul>
</ng-template>

<ng-template #popContent3>
  <ul class="fs-12 mb-0 list-type-none li-12 px-8 py-16">
    <li *ngFor="let item of bannerData?.leaveyear_end_processed_list_detail">{{item}}</li>
  </ul>
</ng-template>
