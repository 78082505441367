<form class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">DEFINE CHALLAN DETAILS</p>
        </div>
        <div class="row row-16">
            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Payroll month</label>
                <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('Payroll month')}}" formControlName="payroll_month" [ngClass]="{ 'is-invalid': (submitted || f.payroll_month.dirty || f.payroll_month.touched) && f.payroll_month.errors || f.payroll_month.errors?.exist}" [readonly]="editFlag?true:false"></ng-select>
                <div *ngIf="(submitted || f.payroll_month.dirty || f.payroll_month.touched) && f.payroll_month.errors || f.payroll_month.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.payroll_month.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Voucher no</label>
                <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Voucher name')}}" formControlName="voucher_no" [ngClass]="{ 'is-invalid': (submitted || f.voucher_no.dirty || f.voucher_no.touched) && f.voucher_no.errors || f.voucher_no.errors?.exist}" [readonly]="editFlag?true:false">
                <div *ngIf="(submitted || f.voucher_no.dirty || f.voucher_no.touched) && f.voucher_no.errors || f.voucher_no.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.voucher_no.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.voucher_no.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Amount</label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.amount.dirty || f.amount.touched) && f.amount.errors || f.amount.errors?.exist || f.amount.errors?.equal }">
                  <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="amount" [ngClass]="{ 'is-invalid': (submitted || f.amount.dirty || f.amount.touched) && f.amount.errors || f.amount.errors?.exist || f.amount.errors?.equal}" (keyup)="amountLimit()" [readonly]="editFlag?true:false">
                  <span>{{currency}}</span>
                </div>
                <div *ngIf="(submitted || f.amount.dirty || f.amount.touched) && f.amount.errors || f.amount.errors?.exist || f.amount.errors?.equal" class="invalid-feedback">
                  <div *ngIf="f.amount.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.amount.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                  <!-- <div *ngIf=" (f.amount.errors?.max)">{{this.messageService.balanceAmountValidation()}}</div> -->
                  <div *ngIf=" !(f.amount.errors?.greater) && (f.amount.errors?.equal)">{{this.messageService.challanAmountValidation()}}</div>
                  <div *ngIf="f.amount.errors?.greater">Mapped amount should not exceed the challan amount</div>
                </div>
            </div>

            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Voucher date</label>
                <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.voucher_date.touched) && (f.voucher_date.errors) }">
                  <input (click)="datePicker1.open()"  class="form-control"  placeholder="{{this.messageService.selectplaceholddisplay('Date')}} "
                  matInput formControlName="voucher_date"
                  [matDatepicker]="datePicker1" [matDatepickerFilter]="challanFilter" (keydown)="disableDate()" [ngClass]="{ 'is-invalid': (submitted || f.voucher_date.touched) && (f.voucher_date.errors) }" [readonly]="editFlag?true:false">
                  <i class="bi bi-calendar3 pointer" (click)="datePicker1.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f.voucher_date.dirty || f.voucher_date.touched) && f.voucher_date.errors || f.voucher_date.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.voucher_date.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.voucher_date.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Branch code</label>
                <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Branch Code')}}" formControlName="branch_code" [ngClass]="{ 'is-invalid': (submitted || f.branch_code.dirty || f.branch_code.touched) && f.branch_code.errors || f.branch_code.errors?.exist}" [readonly]="editFlag?true:false">
                <div *ngIf="(submitted || f.branch_code.dirty || f.branch_code.touched) && f.branch_code.errors || f.branch_code.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.branch_code.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.branch_code.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Bank details</label>
                <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Bank name')}}" formControlName="branch_details" [ngClass]="{ 'is-invalid': (submitted || f.branch_details.dirty || f.branch_details.touched) && f.branch_details.errors || f.branch_details.errors?.exist}" [readonly]="editFlag?true:false">
                <div *ngIf="(submitted || f.branch_details.dirty || f.branch_details.touched) && f.branch_details.errors || f.branch_details.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.branch_details.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.branch_details.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="!(f.branch_details.errors?.pattern) && f.branch_details.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">TDS</label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.tds.dirty || f.tds.touched) && f.tds.errors || f.tds.errors?.exist}">
                  <input type="text" class="form-control " placeholder="Enter TDS" formControlName="tds" [ngClass]="{ 'is-invalid': (submitted || f.tds.dirty || f.tds.touched) && f.tds.errors || f.tds.errors?.exist}" (keyup)="amountLimit()">
                  <span>{{currency}}</span>
                </div>
                <div *ngIf="(submitted || f.tds.dirty || f.tds.touched) && f.tds.errors || f.tds.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.tds.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.tds.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Surcharge</label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.surcharge.dirty || f.surcharge.touched) && f.surcharge.errors || f.surcharge.errors?.exist}">
                  <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Surcharge')}}" formControlName="surcharge" [ngClass]="{ 'is-invalid': (submitted || f.surcharge.dirty || f.surcharge.touched) && f.surcharge.errors || f.surcharge.errors?.exist}" (keyup)="amountLimit()">
                  <span>{{currency}}</span>
                </div>
                <div *ngIf="(submitted || f.surcharge.dirty || f.surcharge.touched) && f.surcharge.errors || f.surcharge.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.surcharge.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.surcharge.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Education cess</label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.education_cess.dirty || f.education_cess.touched) && f.education_cess.errors || f.education_cess.errors?.exist}">
                  <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Education cess')}}" formControlName="education_cess" [ngClass]="{ 'is-invalid': (submitted || f.education_cess.dirty || f.education_cess.touched) && f.education_cess.errors || f.education_cess.errors?.exist}" (keyup)="amountLimit()">
                  <span>{{currency}}</span>
                </div>
                <div *ngIf="(submitted || f.education_cess.dirty || f.education_cess.touched) && f.education_cess.errors || f.education_cess.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.education_cess.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.education_cess.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                  <div *ngIf="!(f.education_cess.errors?.pattern) && f.education_cess.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Interest</label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.intrest.dirty || f.intrest.touched) && f.intrest.errors || f.intrest.errors?.exist}">
                  <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Interest')}}" formControlName="intrest" [ngClass]="{ 'is-invalid': (submitted || f.intrest.dirty || f.intrest.touched) && f.intrest.errors || f.intrest.errors?.exist}" (keyup)="amountLimit()" [readonly]="editFlag?true:false">
                  <span>%</span>
                </div>
                <div *ngIf="(submitted || f.intrest.dirty || f.intrest.touched) && f.intrest.errors || f.intrest.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.intrest.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.intrest.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Others</label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.others.dirty || f.others.touched) && f.others.errors || f.others.errors?.exist}">
                  <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Other amount')}}" formControlName="others" [ngClass]="{ 'is-invalid': (submitted || f.others.dirty || f.others.touched) && f.others.errors || f.others.errors?.exist}" (keyup)="amountLimit()" [readonly]="editFlag?true:false">
                  <span>{{currency}}</span>
                </div>
                <div *ngIf="(submitted || f.others.dirty || f.others.touched) && f.others.errors || f.others.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.others.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.others.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Cheque/DD no</label>
                <input type="text" class="form-control " placeholder="Enter Cheque/DD no" formControlName="cheque_dd_no" [ngClass]="{ 'is-invalid': (submitted || f.cheque_dd_no.dirty || f.cheque_dd_no.touched) && f.cheque_dd_no.errors || f.cheque_dd_no.errors?.exist}" [readonly]="editFlag?true:false">
                <div *ngIf="(submitted || f.cheque_dd_no.dirty || f.cheque_dd_no.touched) && f.cheque_dd_no.errors || f.cheque_dd_no.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.cheque_dd_no.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.cheque_dd_no.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="!(f.cheque_dd_no.errors?.pattern) && f.cheque_dd_no.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','18')}}</div>
                </div>
            </div>

            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Payment date</label>
                <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.payment_date.touched) && (f.payment_date.errors) }">
                  <input (click)="datePicker2.open()"  class="form-control"  placeholder="{{this.messageService.selectplaceholddisplay('Date')}} "
                  matInput formControlName="payment_date"
                  [matDatepicker]="datePicker2"  [ngClass]="{ 'is-invalid': (submitted || f.payment_date.touched) && (f.payment_date.errors) }" [min]="minValue" [max]="maxValue" (keydown)="disableDate()">
                  <i class="bi bi-calendar3 pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f.payment_date.dirty || f.payment_date.touched) && f.payment_date.errors || f.payment_date.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.payment_date.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.payment_date.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Deduction date</label>
                <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.deduction_date.touched) && (f.deduction_date.errors) }">
                  <input (click)="datePicker3.open()"  class="form-control"  placeholder="{{this.messageService.selectplaceholddisplay('Date')}} "
                  matInput formControlName="deduction_date"
                  [matDatepicker]="datePicker3"  [ngClass]="{ 'is-invalid': (submitted || f.deduction_date.touched) && (f.deduction_date.errors) }" [min]="minValue" [max]="maxValue" (keydown)="disableDate()">
                  <i class="bi bi-calendar3 pointer" (click)="datePicker3.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f.deduction_date.dirty || f.deduction_date.touched) && f.deduction_date.errors || f.deduction_date.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.deduction_date.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.deduction_date.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Ack no</label>
                <input type="text" class="form-control " placeholder="Enter Ack no" formControlName="ack_no" [ngClass]="{ 'is-invalid': (submitted || f.ack_no.dirty || f.ack_no.touched) && f.ack_no.errors || f.ack_no.errors?.exist}" [readonly]="editFlag?true:false">
                <div *ngIf="(submitted || f.ack_no.dirty || f.ack_no.touched) && f.ack_no.errors || f.ack_no.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.ack_no.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.ack_no.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Minor head code</label>
                <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.minor_head_code.dirty || f.minor_head_code.touched) && f.minor_head_code.errors || f.minor_head_code.errors?.exist}" [placeholder]="'Select minor head code'" id="minor_head_code" [submitted]="submitted" [isrequired]="isrequired" [selectedItem]="f.minor_head_code.value" formControlName="minor_head_code" [type]="minor_head_code" [isdisabled]='editFlag'></app-master-select>

                <div *ngIf="(submitted || f.minor_head_code.dirty || f.minor_head_code.touched) && f.minor_head_code.errors || f.minor_head_code.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.minor_head_code.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.minor_head_code.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>

             <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Late pay fee</label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.late_pay_fee.dirty || f.late_pay_fee.touched) && f.late_pay_fee.errors || f.late_pay_fee.errors?.exist}">
                  <input type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('Late pay fee')}}" formControlName="late_pay_fee" [ngClass]="{ 'is-invalid': (submitted || f.late_pay_fee.dirty || f.late_pay_fee.touched) && f.late_pay_fee.errors || f.late_pay_fee.errors?.exist}" (keyup)="amountLimit()" [readonly]="editFlag?true:false">
                  <span>{{currency}}</span>
                </div>
                <div *ngIf="(submitted || f.late_pay_fee.dirty || f.late_pay_fee.touched) && f.late_pay_fee.errors || f.late_pay_fee.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.late_pay_fee.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.late_pay_fee.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                </div>
            </div>

        </div>
    </div>

  <!-- </div> -->
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid"
      (click)="validateSchedule()">NEXT</button>
  </footer>
</form>
