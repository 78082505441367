import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-configuration-config-company',
  templateUrl: './configuration-config-company.component.html',
  styleUrls: ['./configuration-config-company.component.scss']
})
export class ConfigurationConfigCompanyComponent implements OnInit {

  weekdays:any=[
    {id:'Sunday',name:'Sunday'},
    {id:'Monday',name:'Monday'},
    {id:'Tuesday',name:'Tuesday'},
    {id:'Wednesday',name:'Wednesday'},
    {id:'Thursday',name:'Thursday'},
    {id:'Friday',name:'Friday'},
    {id:'Saturday',name:'Saturday'},
  ]
  pay_frequency           = ['Monthly'];
  days:any                = [];
  Isalert                 = true;
  submitted               = false;
  saveClicked             = false;
  isDisable:any          = false;
  weekcalcRestrict:any    = [{
                      // 'exclude_holiday_status' : false,
                      'exclude_weekoff_status' : false,
                      'work_day_status'        : false,
                      'work_day'               : null,
                      'exclude_weekoff'        : null,
  }]
  year                        = new Date().getFullYear();
  month                       = new Date().getMonth();
  nextYear                    = new Date().getFullYear()+1;
  prevYear                    = new Date().getFullYear()-1;

  @Output() submitmethod  = new EventEmitter();
  @Output() clearComp     = new EventEmitter();
  @Input() configForm: any;
  @Input() selectedComp: any;
  @Input() editCompId: any;

  constructor(
    public messageService: MessageService,
    public appService: AppService,
  ) { }

  ngOnInit(): void {
    this.isDisable = this.editCompId==0?false:true;
    this.addConditionalValidator()
    this.configForm.controls['pay_frequency'].disable();
    this.selectAllForDropdownItems(this.weekdays);

  }
  addConditionalValidator(){
    this.setDisabled('pay_period','pay_period_day');
    this.setDisabled('pay_day_status','pay_day');
    this.setDisabled('send_confirm_investment_alert','specify_days');

  }

  setDisabled(control: any,name:any) {
    var  import_value = this.configForm.get('attendance_lop').value;

    if((control!='exclude_weekoff_status' && this.configForm.get(control).value==true) || (control=='exclude_weekoff_status' && this.configForm.get(control).value==true && import_value==true)) {
      if(name!='specify_days' && name!='pay_period_day')
     this.configForm.get(name).setValidators([Validators.required])
     else if(name=='specify_days')
     this.configForm.get(name).setValidators([Validators.required,digitdecimalValidator(),Validators.max(365)])
     else
     this.configForm.get(name).setValidators([Validators.required,NumberpatternValidator(),Validators.max(30)])

    } else {

      this.configForm.get(name).clearValidators();
      if(name!='proposed_required_approval_aftercuttoff' && name!='specify_days')
      this.configForm.get(name).setValue(null);
      else if(name=='specify_days')
      this.configForm.get(name).setValue(60);
      else
      this.configForm.get(name).setValue(false);
    }
    this.configForm.controls[name].updateValueAndValidity();
  }
  weekCalcControl(){

    Object.entries(this.weekcalcRestrict[0]).forEach(([key, value]) => {

      this.configForm.get(key).setValue(value);
    });

  }
  createRange(num:number,start=1){
    var items: number[] = [];
    for(var i = start; i <= num; i++){
      items.push(i);
    }
    return items;
  }

  submitMethod() {
    this.submitted = true;
    if (this.configForm.invalid) {
      return;
    }
    this.saveClicked = true
    this.submitmethod.emit();
  }
  clear(index:number){

    let daata = this.configForm.value.exclude_weekoff;
    daata.splice(index, 1);
    this.configForm.patchValue({
      exclude_weekoff: daata
      });

  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  removeComp(index:any){
    this.clearComp.emit(index);

  }

}
