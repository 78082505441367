<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="loader==false && permissions">
    <div class="col-12" >
        <div class="d-flex justify-content-between">
            <h5 class="mb-0">Bank Details</h5>
            <ng-container *ngIf="addEditPermission">
              <a class="link-primary fs-14 d-flex align-items-center" *ngIf="bankData.length!=0 && permissions?.e" (click)="editPaymentmode('Edit')"><i class="icon-pencil-square me-1"></i> Edit</a>
            </ng-container>
        </div>
    </div>
    <div class="col-12" *ngIf="bankData.length!=0">
        <div class="card details-card shadow-0 px-24 py-16">
            <div class="row row-24 ">
                <div *ngIf="getFieldPermission('salary_pay_mode')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Salary pay mode</div>
                    <div class="detail-desc">{{bankData.salary_pay_mode}}</div>
                </div>
                <ng-container *ngIf="getFieldPermission('account_holdername')">
                <div class="col-lg-3  col-sm-6 col-12" *ngIf="bankData.salary_pay_mode=='Online Transfer'">
                  <div class="detail-title">Account holder name</div>
                  <div class="detail-desc">{{bankData.account_holdername}}</div>
                </div>
                </ng-container>
                <ng-container *ngIf="getFieldPermission('account_number')">
                <div class="col-lg-3  col-sm-6 col-12" *ngIf="bankData.salary_pay_mode=='Online Transfer'">
                  <div class="detail-title">Account number</div>
                  <div class="detail-desc">{{bankData.account_number}}</div>
              </div>
              </ng-container>
              <ng-container *ngIf="getFieldPermission('bank_name')">
              <div class="col-lg-3  col-sm-6 col-12" *ngIf="bankData.salary_pay_mode=='Online Transfer'">
                <div class="detail-title">Bank name</div>
                <div class="detail-desc">{{bankData.bank_name!=null?bankData.bank_name:'-'}}</div>
              </div>
              </ng-container>
              <ng-container *ngIf="getFieldPermission('ifsc_code')">
              <div class="col-lg-3  col-sm-6 col-12" *ngIf="bankData.salary_pay_mode=='Online Transfer'">
                <div class="detail-title">IFSC code</div>
                <div class="detail-desc">{{bankData.ifsc_code}}</div>
              </div>
              </ng-container>
              <ng-container *ngIf="getFieldPermission('branch_name')">
              <div class="col-lg-3  col-sm-6 col-12" *ngIf="bankData.salary_pay_mode=='Online Transfer'">
                <div class="detail-title">Branch name</div>
                <div class="detail-desc">{{bankData.branch_name!=null?bankData.branch_name:'-'}}</div>
              </div>
              </ng-container>
              <ng-container *ngIf="getFieldPermission('branch_address')">
              <div class="col-lg-3  col-sm-6 col-12" *ngIf="bankData.salary_pay_mode=='Online Transfer'">
                <div class="detail-title">Branch address</div>
                <div class="detail-desc">{{bankData.branch_address!=null?bankData.branch_address:'-'}}</div>
              </div>
              </ng-container>
            </div>
        </div>
    </div>

</div>

<!-- showing when no employer information available -->
<div class="row row-16" *ngIf="bankData.length==0">
    <div class="col-12" >
        <div class="row row-24 py-16" >
            <div class="col-12 ">
                <div class="card details-card shadow-0 px-24 py-16">
                    <p class="title mb-24">Bank Details</p>
                    <div *ngIf="addEditPermission" class="col-12 d-flex">
                        <a *ngIf="permissions?.a" class="btn btn-outline-primary px-64" (click)="editPaymentmode('Add')">Add Bank Details</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->

<!-- Slider edit start-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
    <form class="side-panel-container" [formGroup]="bankForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <header class="side-panel-head">
            <h5> {{header}} Bank Information</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <!-- Edit loader start -->
              <app-edit-loader *ngIf="loader1" ></app-edit-loader>
              <!-- Edit loader end -->
              <ng-container *ngIf="!loader1" >
                <div class="row row-24 ">
                    <input type="hidden" class="form-control" name="" id="prof_id" placeholder ="" formControlName="id">
                    <input type="hidden" class="form-control" name="" id="employee_id" placeholder ="" formControlName="employee">

                    <div class="col-sm-6 form-row">
                        <label for="salary_pay_mode" class="form-label required">Salary pay mode</label>
                        <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.salary_pay_mode.touched) && f.salary_pay_mode.errors }" [placeholder] ="paymentPlaceholder" id="salary_pay_mode" [submitted]="submitted" [selectedItem]="f.salary_pay_mode.value" formControlName="salary_pay_mode" [type]="payment" [isrequired]="isrequired" (ngModelChange)="addConditionalValidation()"></app-master-select>
                        <div *ngIf="(submitted || f.salary_pay_mode.touched) && f.salary_pay_mode.errors" class="invalid-feedback" >
                            <div *ngIf="f.salary_pay_mode.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        </div>

                    </div>
                    <div class="col-sm-6 form-row" *ngIf="activeOnlineTrans">
                      <label for="account_holdername" class="form-label required">Account holder name</label>
                      <input class="form-control"  placeholder="{{this.messageService.placeholderdisp('account holder name')}}" formControlName="account_holdername" [ngClass]="{ 'is-invalid': (submitted || f.account_holdername.touched || f.account_holdername.dirty) && f.account_holdername.errors }">
                      <div *ngIf="(submitted || f.account_holdername.touched || f.account_holdername.dirty) && f.account_holdername.errors" class="invalid-feedback">
                        <div *ngIf="f.account_holdername.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="f.account_holdername.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!(f.account_holdername.errors.pattern) && f.account_holdername.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                    </div>
                    </div>
                    <div class="col-sm-6 form-row" *ngIf="activeOnlineTrans">
                      <label for="account_number" class="form-label required">Account number</label>
                      <input class="form-control"  placeholder="{{this.messageService.placeholderdisp('account number')}}" formControlName="account_number" [ngClass]="{ 'is-invalid': (submitted || f.account_number.touched || f.account_number.dirty) && f.account_number.errors || f.account_number.errors?.exist}" (keyup)="keyup('account_number',f.account_number.value)">
                      <div *ngIf="(submitted || f.account_number.touched || f.account_number.dirty) && f.account_number.errors || f.account_number.errors?.exist" class="invalid-feedback" >
                        <div *ngIf="f.account_number.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="f.account_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="f.account_number.hasError('exist')">{{this.messageService.validationDisplay(f.account_number.value)}}</div>
                    </div>
                    </div>
                    <div class="col-sm-6 form-row" *ngIf="activeOnlineTrans">
                      <label for="re_account_number" class="form-label required">Re-enter account number</label>
                      <input class="form-control"  placeholder="Re-enter account number" formControlName="re_account_number" [ngClass]="{ 'is-invalid': (submitted || f.re_account_number.touched || f.re_account_number.dirty ) && (f.re_account_number.errors || bankForm.errors) }">
                      <div *ngIf="(submitted || f.re_account_number.touched || f.re_account_number.dirty) && (f.re_account_number.errors || bankForm.errors)" class="invalid-feedback">
                        <div *ngIf="f.re_account_number.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="bankForm.errors">Account number & Re-enter account number should be
                          same.</div>
                    </div>
                    </div>
                    <div class="col-sm-6 form-row" *ngIf="activeOnlineTrans">
                      <label for="bank_name" class="form-label required">Bank name</label>
                      <input class="form-control"  placeholder="{{this.messageService.placeholderdisp('bank name')}}" formControlName="bank_name" [ngClass]="{ 'is-invalid': (submitted || f.bank_name.touched || f.bank_name.dirty) && f.bank_name.errors }">
                      <div *ngIf="(submitted || f.bank_name.touched || f.bank_name.dirty) && f.bank_name.errors" class="invalid-feedback">
                        <div *ngIf="f.bank_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="f.bank_name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="!(f.bank_name.errors.pattern) && f.bank_name.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                    </div>
                    </div>
                    <div class="col-sm-6 form-row" *ngIf="activeOnlineTrans">
                      <label for="ifsc_code" class="form-label required">IFSC code</label>
                      <input class="form-control"  placeholder="{{this.messageService.placeholderdisp('IFSC Code')}}" formControlName="ifsc_code" [ngClass]="{ 'is-invalid': (submitted || f.ifsc_code.touched || f.ifsc_code.dirty) && f.ifsc_code.errors }">
                      <div *ngIf="(submitted || f.ifsc_code.touched || f.ifsc_code.dirty) && f.ifsc_code.errors" class="invalid-feedback">
                        <div *ngIf="f.ifsc_code.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="f.ifsc_code.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                    </div>
                    </div>
                    <div class="col-sm-6 form-row" *ngIf="activeOnlineTrans">
                      <label for="branch_name" class="form-label ">Branch name</label>
                      <input class="form-control"  placeholder="{{this.messageService.placeholderdisp('branch name')}}" formControlName="branch_name" [ngClass]="{ 'is-invalid': (submitted || f.branch_name.touched || f.branch_name.dirty) && f.branch_name.errors }">
                      <div *ngIf="(submitted || f.branch_name.touched || f.branch_name.dirty) && f.branch_name.errors" class="invalid-feedback">
                        <div *ngIf="f.branch_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!(f.branch_name.errors.pattern) && f.branch_name.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                    </div>
                    </div>
                    <div class="col-sm-12 form-row" *ngIf="activeOnlineTrans">
                      <label for="branch_address" class="form-label ">Branch address</label>
                      <textarea rows="4" type="text" class=" form-control " name="" id="branch_address" formControlName="branch_address" placeholder="{{this.messageService.placeholderdisp('branch address')}}" [ngClass]="{ 'is-invalid': (submitted || f.branch_address.dirty || f.branch_address.touched) &&  f.branch_address.errors}">
                      </textarea>
                      <div *ngIf="(submitted || f.branch_address.touched || f.branch_name.dirty) && f.branch_address.errors" class="invalid-feedback">
                        <div *ngIf="f.branch_address.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!(f.branch_address.errors.pattern) && f.branch_address.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>


                    </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
        <footer class="side-panel-footer gap-32" *ngIf="!loader1">
            <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="submitted || saveClicked || bankForm.invalid">Save</button>
        </footer>
    </form>
</div>
<!-- slider section end -->

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg" ></app-info-popup>
</ng-container>
<!-- InfoPopup end -->
