import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { AmountValidator } from 'src/app/@shared/validators/amount.validators';
@Component({
  selector: 'app-advance-policy-add',
  templateUrl: './advance-policy-add.component.html',
  styleUrls: ['./advance-policy-add.component.scss']
})
export class AdvancePolicyAddComponent implements OnInit {
  policyDefineUnit:any =[];
  value:any;
  id:any
  validated : boolean           = false;
  @Input()formGp                : any;
  @Input() editflag             : any
  @Output() submitmethod        = new EventEmitter();
  @Input()componentError        : any;
  @Input()disabled              = false;
  private subjectKeyUp = new Subject<any>();
  @Input()submitted:boolean     = false;

  constructor(
    private fb:FormBuilder,
    public messageService : MessageService,
    public route : ActivatedRoute,
    public existDataService :  ExistDatasService
    ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    if(this.formGp.get('specify_limit')?.value ==true){
      this.setRequired(true,'specify_limit')
    }
    if(this.formGp.get('is_credit_limit')?.value ==true){
      this.setRequired(true,'is_credit_limit')
    }
  }

  setRequired(bool:any,inp:any){
    if(bool){
        if(inp=='specify_limit'){
          this.formGp.get('amount')?.setValidators([Validators.required,AmountValidator(),Validators.maxLength(6),Validators.min(1)]);      
        }else if(inp=='is_credit_limit'){
          this.formGp.get('credit_limit')?.setValidators([Validators.required,AmountValidator(),Validators.maxLength(6),Validators.min(1)]);  
        }
    }else{
      if(inp=='specify_limit'){
        this.formGp.get('amount')?.clearValidators();
        this.formGp.get('amount').reset();
      }else{
        this.formGp.get('credit_limit')?.clearValidators();
        this.formGp.get('credit_limit').reset();
      }  
    }
    this.formGp.get('amount')?.updateValueAndValidity();    
    this.formGp.get('credit_limit')?.updateValueAndValidity();    
  }

  CheckedOrNot(value:any){
    this.formGp.get('is_credit_limit')?.setValue(value.target.checked);
    this.setRequired(value.target.checked,'is_credit_limit');
  }

  existService(){
      if(!this.editflag){
        this.value ={
          "page": "ExpenseAdvancePolicy",
          "field":"name",
          "value": this.f?.name.value
        }
      }else{
         this.value ={
          "id":this.id,
          "page":"ExpenseAdvancePolicy",
          "field":"name",
          "value": this.f?.name.value
        }
      }

      this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
        this.policyDefineUnit = res.body

        if(this.policyDefineUnit.data ==='invalid'){
          this.f.name.setErrors({
            'exist' : true
          })
        }
      })
    }
  validatepolicysetting() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
