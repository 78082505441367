<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">

        <div class="row row-16">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">Challan group details </p>
            </div>
            <div class="col-6">
                <label for="" class="form-label required">Group name </label>

                <input type="text" formControlName="name" class="form-control " [ngClass]="{ 'is-invalid': (f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist}" placeholder="{{this.messageService.placeholderdisp('group name')}}" (ngModelChange)="keyUp('name')" >
                <div *ngIf=" f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                  <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                </div>
            </div>
            <div class="col-6">
                <label for="" class="form-label required">Responsible person </label>

                <ng-select class="form-ngselect" formControlName="responsible_persone"
                placeholder="{{this.messageService.selectplaceholddisplay('responsible person')}}" [items]="employeeDropdown"
                bindValue="id" bindLabel="name">
                <ng-template ng-label-tmp let-item="item">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                    </span>
                    {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":" ")+item.last_name +"
                    ("+item.employee_code+")"}}
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                    </span>
                    {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":" ")+item.last_name +"
                    ("+item.employee_code+")"}}
                  </div>
                </ng-template>
                </ng-select>
            </div>
            <div class="col-6">
                <label for="" class="form-label required">Group owner name</label>

                <ng-select class="form-ngselect" formControlName="group_owner"
                placeholder="{{this.messageService.selectplaceholddisplay('group owner name')}}" [items]="employeeDropdown"
                bindValue="id" bindLabel="name">
                <ng-template ng-label-tmp let-item="item">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                    </span>
                    {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":" ")+item.last_name +"
                    ("+item.employee_code+")"}}
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex  align-items-center">

                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null" src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                    </span>
                    {{item.first_name+" "+(item.middle_name!=null?item.middle_name+" ":" ")+item.last_name +"
                    ("+item.employee_code+")"}}
                  </div>
                </ng-template>
                </ng-select>
            </div>
            <div class="col-6">
                <label for="" class="form-label required" *ngIf="from=='esi'">Establishments ESI Number </label>
                <label for="" class="form-label required" *ngIf="from=='pf'">Group provident fund number </label>

                <input type="text" class="form-control" name="" formControlName = "number" id="" placeholder="Enter number" (ngModelChange)="keyUp('number')"
                [ngClass]="{ 'is-invalid' :  (f.number.dirty || f.number.touched) && f.number.errors || f.number.errors?.exist}">
                <div *ngIf="(f.number.touched || f.number.dirty) && f.number.errors || f.number.errors?.exist" class="invalid-feedback">
                    <div *ngIf="f.number.errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="f.number.hasError('exist')">{{this.messageService.validationDisplay(f.number?.value)}}</div>
                </div>
                <div *ngIf="f.number.errors?.['pattern']" class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}</div>

            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Registered address </label>

                <textarea type="text" class="form-control" rows="4" formControlName="address" placeholder="Enter address here..."
          [ngClass]="{ 'is-invalid': (f.address.dirty || f.address.touched) && (f.address.errors) }"></textarea>
        <div *ngIf="( f.address.dirty || f.address.touched) && f.address.errors" class="invalid-feedback">
          <div *ngIf="f.address.errors.required">{{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="f.address.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
          </div>
        </div>
            </div>
        </div>
    </div>
    <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateForm()">NEXT</button>
    </footer>
</form>
