<form class="" [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row ">
      <div class="d-flex gap-8 mb-16">
        <span class="text-uppercase fw-500">Leave Request</span>
        <span *ngIf="(leaveType?.length > 0)" class="fs-14 link-primary1 fw-500 ms-auto"
          (click)="leaveBalanceFunction()">Leave Balance</span>
        <span *ngIf="(formGp.value?.leave_type != null && formGp.value?.leave_type != '')"
          class="fs-14 link-primary1 fw-500 d-flex align-items-center" (click)="getLeaveRule()">
          <i class="bi bi-question-circle me-1"></i> Leave Rules</span>
      </div>
    </div>
    <!-- Validation message -->
    <div *ngIf="overtimeCompOffValidation" role="alert" class="alert alert-warning show">
      <p class="mb-0 fs-12
          ">{{overtimeCompOffValidationMsg}}</p>
      <div class="btn-close pointer" (click)="overtimeCompOffValidation=false"></div>
    </div>
    <!-- End -->
    <div class="row row-16">
      <div class=" col-12 form-row">
        <label for="" class="form-label required">Leave type </label>
        <!-- <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="select the leave type you want to raise a request"
                    popoverClass="popover-input popover-info" placement="bottom"
                    triggers="hover"></i></span> -->
        <ng-select class="form-ngselect" (change)="selected()"
          [ngClass]="{ 'is-invalid': (submitted || f.leave_type.touched) && f.leave_type.errors }"
          formControlName="leave_type" name="leave_type" id="leave_type" placeholder="Select Leave Type">
          <ng-option *ngFor="let li of leaveType" [value]="li.id">{{li.leave_name}}</ng-option>
        </ng-select>
        <div *ngIf="(submitted || f.leave_type.touched) && f.leave_type.errors" class="invalid-feedback">
          <div *ngIf="f.leave_type.errors.required">{{this.messageService.validationDisplay('required')}}
          </div>
        </div>
      </div>
      <div class=" col-12 col-lg-6 form-row">
        <label for="" class="form-label required">From date</label>
        <!-- <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Select from duration of leave from the drop down"
                    popoverClass="popover-input popover-info" placement="bottom"
                    triggers="hover"></i></span> -->
        <div class="input-group form-icon icon-right"
          [ngClass]="{ 'is-invalid': (submitted || f.from_date.dirty || f.from_date.touched) && (f.from_date.errors) }">
          <input (click)="datePicker1.open()" class="form-control" placeholder="Start date" matInput
            formControlName="from_date" [matDatepicker]="datePicker1" (keydown)="disableDate()"
            (dateChange)="dateChangeFunction($event,'fromDate')" [min]="fromMinDate"
            [ngClass]="{ 'is-invalid': (submitted || f.from_date.dirty || f.from_date.touched) && (f.from_date.errors) }"
            [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
          <mat-datepicker [dateClass]="dateClass" touchUi="false" (opened)="appendTypeIndicator()" #datePicker1
            (opened)="openCalendar('fromdate')" (monthSelected)="fromMonthSelection($event)">

          </mat-datepicker>
        </div>
        <div *ngIf="(submitted || f.from_date.dirty || f.from_date.touched) && f.from_date.errors"
          class="invalid-feedback">
          <div *ngIf="f.from_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
        </div>
      </div>
      <div class=" col-12 col-lg-6 form-row">
        <label for="" class="form-label required">To date</label>
        <div class="input-group form-icon icon-right"
          [ngClass]="{ 'is-invalid': (submitted || f.to_date.dirty || f.to_date.touched) && (f.to_date.errors || formGp.errors!=null) }">
          <input (click)="datePicker2.open()" class="form-control" placeholder="End date" matInput [min]="toMinDate"
            formControlName="to_date" [matDatepicker]="datePicker2" (keydown)="disableDate()"
            [ngClass]="{ 'is-invalid': (submitted || f.to_date.dirty || f.to_date.touched) && (f.to_date.errors || formGp.errors!=null) }"
            (dateChange)="dateChangeFunction($event,'toDate')" [matDatepickerFilter]="toDateFilter"><i
            class="icon-calendar pointer" (click)="datePicker2.open()"></i>
          <mat-datepicker [dateClass]="toDateClass" touchUi="false" (opened)="appendTypeIndicator()" #datePicker2
            (opened)="openCalendar('todate')" (monthSelected)="toMonthSelection($event)" [startAt]="startDate">

          </mat-datepicker>
        </div>
        <div *ngIf="(submitted || f.to_date.dirty || f.to_date.touched) && (f.to_date.errors || formGp.errors!=null)"
          class="invalid-feedback">
          <div *ngIf="f.to_date.errors?.required">{{this.messageService.validationDisplay('required')}}</div>

        </div>
      </div>

      <div class="col-12" *ngIf="(formGp.value.from_date != '' && formGp.value.to_date != '')">
        <div class="card card-c2   p-32">
          <div class="d-flex flex-column gap-8">
            <div class="d-flex">

              <div class="fw-500 fs-14 text-uppercase"
                *ngIf="formGp.value.from_date != '' && formGp.value.to_date == '' ">
                {{this.appservice.dateFormatDisplay(formGp.value.from_date)}}
              </div>

              <div class="fw-500 fs-14 text-uppercase"
                *ngIf="formGp.value.from_date == '' && formGp.value.to_date != '' ">
                {{this.appservice.dateFormatDisplay(formGp.value.to_date)}}</div>

              <div class="fw-500 fs-14 text-uppercase"
                *ngIf="formGp.value.from_date != '' && formGp.value.to_date != '' ">
                {{this.appservice.dateFormatDisplay(formGp.value.from_date)}} -
                {{this.appservice.dateFormatDisplay(formGp.value.to_date)}}</div>

              <div class="ms-auto fs-14" *ngIf="formGp.value.from_date != '' && formGp.value.to_date != ''">Total no. of
                days :
                <span class="fw-500">{{formGp.value?.leave_num_days}} days</span>
              </div>
            </div>
            <div class="card card-c2" *ngIf="formGp.value.from_date != ''">
              <div class="card-body d-flex indicater px-16 py-14">
                <span class="fw-500 ">{{this.appservice.dateFormatDisplay(formGp.value.from_date)}}</span>
                <div ngbDropdown class="ms-auto  d-inline-block ">
                  <button class="btn-reset fs-12 text-accent2" placement="right-bottom" id="dropdownBasic1"
                    ngbDropdownToggle>
                    {{fromLeavePortion == '' ? 'Full Day' : fromLeavePortion}} <i class="bi bi-caret-down-fill m-1"></i>
                  </button>
                  <div ngbDropdownMenu class="bottom-left  fs-14" aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="portionDropDown('from','Full Day')" [disabled]="!portionDropdwn?.from_date?.full_day">
                      Full Day
                    </button>
                    <!-- <ng-container *ngIf="viewListData?.halfday_leave_allow"> -->
                    <button ngbDropdownItem (click)="portionDropDown('from','First Half Day')" [disabled]="!portionDropdwn?.from_date?.first_half">
                      First Half Day
                    </button>
                    <button ngbDropdownItem (click)="portionDropDown('from','Second Half Day')" [disabled]="!portionDropdwn?.from_date?.second_half">
                      Second Half Day
                    </button>
                    <!-- </ng-container> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-c2"
              *ngIf="(this.appservice.dateFormatConvert(formGp.value.to_date) != this.appservice.dateFormatConvert(formGp.value.from_date)) && (formGp.value.to_date != '')">
              <div class="card-body d-flex indicater px-16 py-14 ">
                <span class="fw-500 ">{{this.appservice.dateFormatDisplay(formGp.value.to_date)}}</span>
                <div ngbDropdown class="ms-auto  d-inline-block ">
                  <button class="btn-reset fs-12 text-accent2" placement="right-bottom" id="dropdownBasic1"
                    ngbDropdownToggle>
                    {{toLeavePortion == '' ? 'First Half Day' : toLeavePortion}}<i
                      class="bi bi-caret-down-fill m-1"></i>
                  </button>
                  <div ngbDropdownMenu class="bottom-left  fs-14" aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="portionDropDown('to','Full Day')" [disabled]="!portionDropdwn?.to_date?.full_day">
                      Full Day
                    </button>
                    <!-- <ng-container *ngIf="viewListData.halfday_leave_allow"> -->
                    <button ngbDropdownItem (click)="portionDropDown('to','First Half Day')" [disabled]="!portionDropdwn?.to_date?.first_half">
                      First Half Day
                    </button>
                    <button ngbDropdownItem (click)="portionDropDown('to','Second Half Day')" [disabled]="!portionDropdwn?.to_date?.second_half">
                      Second Half Day
                    </button>
                    <!-- </ng-container> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Penality notification -->
      <div class="col-12 form-row">
        <div *ngIf="isPenality" role="alert" class="alert alert-warning show">
          <p class="mb-0 fs-12
                ">According to the leave policy {{penalityDays.join(', ')}} also will be counted as leave. Therefore,
            {{penalityCount}} additional days will be deducted from your leave balance.</p>
          <div class="btn-close pointer" (click)="isPenality=false"></div>
        </div>
      </div>
      <!-- End -->

      <!-- Comp off drop down -->
      <div
        *ngIf="formGp.value.leave_name == 'Compensatory off' && (formGp.value.from_date != '' && formGp.value.to_date != '') "
        class="col-12 form-row">
        <label for="" class="form-label required">Comp off date to compensate with </label>
        <ng-select (change)="getCompOffDayCount($event)"
          placeholder="{{this.messageService.selectplaceholddisplay('Comp off date to compensate with')}}"
          formControlName="compoff_date" [items]="compOffList" [multiple]="true" groupBy="selectedAllGroup"
          [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="compOff_date" [closeOnSelect]="false"
          bindValue="attendance_date" class="multiselect">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
              [ngModelOptions]="{standalone: true}" /> Select All
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
              [ngModelOptions]="{ standalone : true }" />
            {{ item.compOff_date }}
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="formGp?.value?.compoff_date?.length ">
              <span class="ng-value-label">{{formGp.value.compoff_date?.length}}
                Comp off date
                selected</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ (item.compOff_date.length>15)? (item.compOff_date | slice:0:15)+'..':(item.compOff_date) }}
          </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="formGp.value.compoff_date?.length != 0">
          <li class="ng-value" *ngFor="let item of formGp.value.compoff_date,let ki =index">
            <ng-container *ngFor="let insideitem of compOffList, let k =index">
              <span class="ng-value-label" *ngIf="insideitem['attendance_date']==item">
                {{ (insideitem['compOff_date'].length>15)? (insideitem['compOff_date'] |
                slice:0:15)+'..':(insideitem['compOff_date']) }}</span>
            </ng-container>
            <!-- Called getCompOffDayCount - Once the NEXT button get disabled it won't enabled if data is correct based on dropdown input -->
            <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('compoff_date',ki);"
              aria-hidden="true">×</span>
          </li>
        </ul>
      </div>
      <!-- End -->

      <div class="col-12 form-row">
        <label for="" class="form-label required">Reason </label>
        <!-- <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Mention the reason for the leave"
                    popoverClass="popover-input popover-info" placement="bottom"
                    triggers="hover"></i></span> -->
        <textarea type="text" class="form-control" rows="4" formControlName="reason" placeholder="Enter reason here..."
          [ngClass]="{ 'is-invalid': (submitted || f.reason.dirty || f.reason.touched) && (f.reason.errors) }"></textarea>
        <div *ngIf="(submitted || f.reason.dirty || f.reason.touched) && f.reason.errors" class="invalid-feedback">
          <div *ngIf="f.reason.errors.required">{{this.messageService.validationDisplay('required')}}</div>
          <div *ngIf="f.reason.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label {{document_required ? 'required' : ''}}">{{document_required
          ? 'Upload document' : 'Upload document (optional)'}}</label>
        <label class="upload-btn " *ngIf="formGp.value.document == ''"
          [ngClass]="{ 'is-invalid': (fileError == true)  }">
          <div class="upload-text">Upload documents <span class="text-primary">here
              <app-file-upload [crop]="false" [round]="false" [id]="'document'" [width]="504" (dataURL)="getURL($event)"
                (fullfile)="getFilename($event)" (loader)="getloader($event)" [FileType]="'.pdf,.doc'"
                (fileformat)="getfileformat($event)" (validsize)="getvalidsize($event)"></app-file-upload></span>
            <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC</div>
          </div>


          <input type="file" class="form-control" placeholder="" hidden>

        </label>
        <div *ngIf="(fileError == true)" class="invalid-feedback">
          <div>{{fileErrorMsg}}</div>
        </div>
        <!-- <div class="  flex-center text-center"
                                    *ngIf="docURL=='' && docLoader" >
                                    <div class="fs-12 text-muted">Loading </div>
                                    <div class="ms-4 dot-pulse"></div>

                                </div> -->
        <!-- <div *ngIf="formGp.value.document !=''"> -->
          <!-- <div class="d-flex p-24">
            <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
            <span class="text-trim fw-500">{{docName}}</span>
            <span class="ms-auto text-accent1 ">{{fileSize}}</span>
            <span class="link-danger fw-500 flex-shrink-0" (click)="deleteProfilePic($event)"><i
                class="icon-trash ms-8 "></i></span>
          </div> -->

          <!-- <div class="card card-c2  bg-secondary" style="width:16.375rem;">
            <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
              <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
              <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.document)}}</span>
                <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.document)}}</span>
                <div class="d-flex gap-8">
                  <div class="link-primary" (click)="viewDocument(formGp.value.document)">
                    <i class="bi bi-eye me-1"></i>View
                  </div>
                  <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.document,this.appservice.getDocName(formGp.value.document))">
                    <i class="bi bi-download me-1"></i>Download
                  </div>
                </div>
              </div>
              <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteProfilePic($event)"></i>
            </div>
          </div>
        </div> -->


        <div class="card card-c2  bg-secondary" style="width:16.375rem;" *ngIf="formGp.value.document != '' && formGp.value.document != null && !docLoader">
          <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
            <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
            <ng-container *ngIf="!docLoader">
            <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
              <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(formGp.value.document)}}</span>
              <span class="tips-helper ">{{this.appservice.getDocName(formGp.value.document)}}</span>
              <div class="d-flex gap-8">
                <div class="link-primary" (click)="viewDocument(formGp.value.document)">
                  <i class="bi bi-eye me-1"></i>View
                </div>
                <div class="link-primary" (click)="this.appservice.downloadFile(formGp.value.document,this.appservice.getDocName(formGp.value.document))">
                  <i class="bi bi-download me-1"></i>Download
                </div>
              </div>
            </div>
            <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteProfilePic($event)"></i>
          </ng-container>

          </div>
        </div>

        <div *ngIf="docLoader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
          <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
            <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
            <div class="  flex-center text-center"  *ngIf="docLoader">
              <div class="fs-12 text-muted">Loading </div>
              <div class="ms-4 dot-pulse"></div>
            </div>
          </div>
        </div>


      </div>
    </div>


  </div>
  <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase"
      [disabled]="!formGp.valid || invalidPass || overtimeCompOffValidation || isApi1"
      (click)="validateLeaveDetails()">NEXT</button>

    <!-- <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="validateLeaveDetails()">NEXT</button> -->
  </footer>
</form>

<!-- Validation -->
<app-alert-toggle *ngIf="alertToggle" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="errorPass"></app-alert-toggle>
<!-- End -->

<!-- Leave balance start -->
<div class="side-panel" style="--sidepanel-width:56.25rem;" [class.side-pane-active]='leaveBalance === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Total available balance after booking</h5>
      <a class="toggle-panel" (click)="leaveBalance = false"><i class="icon-close-lg"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="d-flex mb-16 align-items-center">
            <p class="text-uppercase fw-500 mb-0">LEAVE balance AFTER BOOKING</p>
            <div class="d-flex mb-16 align-items-center gap-8 ms-auto">
              <p class="mb-0">As of</p>
              <div class="input-group form-icon icon-right " style="width: 18.75rem;">
                <input (click)="datePicker3.open()" class="form-control" placeholder="today" matInput
                  [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker3" (keydown)="disableDate()"
                  (dateChange)="dateChangeFunctionLeaveBalance($event)"><i class="icon-calendar pointer"
                  (click)="datePicker3.open()"></i>
                <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
                <!-- test start -->
                <!-- <div class="slider-input">
                              <div class="align-items-center d-flex justify-content-around" (click)="datePicker3.open()">
                                <span class="fs-14 fw-500">As of</span>
                                <span class="fs-10 fw-500 text-light-500">{{laeveBalanceDate}}</span>
                              </div>
                            </div> -->
                <!-- test end -->
              </div>
            </div>
          </div>
          <app-table-loader *ngIf="loader"></app-table-loader>
          <ng-container *ngIf="!loader">
            <ng-container *ngFor="let leavetype of leaveTypeBalanceDataTile,let i = index">
              <div class="col-xl-4 col-6">
                <div class="card card-c2 card-highlight highlight{{(i+2)%5}}">
                  <div class="d-flex px-16 py-12 align-items-center justify-content-between gap-8">
                    <span class="fw-500 fs-12 text-trim" ngbPopover="{{leavetype?.leave_type}}"
                      popoverClass="popover-input popover-info" container="body"
                      triggers="hover">{{leavetype?.leave_type}}</span>
                    <span class="fw-600 fs-20">{{leavetype?.available}}/{{leavetype?.total}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">LEAVE balance details</p>
            </div>
            <app-view-leave-balance [leaveBalanceTable]="leaveBalanceTable"></app-view-leave-balance>

          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- un supported view format alert -->
<app-info-popup *ngIf="alertToggle1" (modal)="alertToggle1 = false" [infoToggle]="alertToggle1" [infoMsg]="alertMsg"></app-info-popup>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>


<!-- Leave rule start -->
<div class="side-panel" style="--sidepanel-width:56.25rem;" [class.side-pane-active]='leaveRule === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Leave Rule ({{leaveRuleData?.leave_type_details?.leave_name+' - '+leaveRuleData?.leave_type_details?.leave_code}})</h5>
      <a class="toggle-panel" (click)="leaveRule = false"><i class="icon-close-lg"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-table-loader *ngIf="loader"></app-table-loader>
        <div class="row row-16" *ngIf="!loader">
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Type Details</p>
              </div>
              <div class="card-body p-24">
                <ol class="li-reset li-16  fs-14">
                  <li>Leave name: {{leaveRuleData?.leave_type_details?.leave_name}}</li>

                  <li>Leave code: {{leaveRuleData?.leave_type_details?.leave_code}}</li>

                  <li>{{leaveRuleData?.leave_type_details?.paid_leave == true ? 'This leave is a paid leave.' : 'This leave is an Unpaid leave; There will be deduction in the salary if this leave is availed.'}}</li>

                  <li>{{leaveRuleData?.leave_type_details?.negative_balance_allowed == true ? 'Negative leave balance is allowed; you can take maximum of '+leaveRuleData?.leave_type_details?.negative_balance_days+' days leave in advance.' : 'Negative leave balance is not allowed in this leave type.'}}</li>

                  <li>{{leaveRuleData?.leave_type_details?.halfday_leave_allow ? 'Half day leave request is allowed in this leave type.' : 'Half day leave request is not allowed in this leave type.'}}</li>

                  <li *ngIf="">{{leaveRuleData?.leave_type_details?.cancellation_allowed ? 'Leave request cancellation is allowed after the approval of the leave.' : 'Leave request cancellation is not allowed after the approval of the leave.'}}</li>

                  <li
                    *ngIf="(leaveRuleData?.leave_type_details?.leave_encashable && leaveRuleData?.leave_type_details?.leaveendyear_leave_encash_allowed && !leaveRuleData?.leave_type_details?.max_encash_limit_status)">
                    This leave type can be encashed during the year-end process: Encash first and
                    carry forward the remaining balance </li>

                  <li
                    *ngIf="(leaveRuleData?.leave_type_details?.leave_encashable && leaveRuleData?.leave_type_details?.max_encash_limit_status)">
                    Maximum of {{leaveRuleData?.leave_type_details?.max_encash_limit}} leaves can be
                    encashed during the final settlement or all unused leaves can be encashed during
                    the final settlement.</li>

                  <li *ngIf="leaveRuleData?.leave_type_details?.weekoff_as_leave">
                    {{leaveRuleData?.leave_type_details?.weekoff_leave}}
                  </li>

                  <li *ngIf="leaveRuleData?.leave_type_details?.holiday_as_leave">
                    {{leaveRuleData?.leave_type_details?.holiday_leave}}
                  </li>

                </ol>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Credit Details</p>
              </div>
              <div *ngIf="(leaveRuleData?.leave_credit_details | json) != '{}'" class="card-body p-24">
                <ol class="li-reset li-16  fs-14">
                  <li>{{leaveRuleData?.leave_credit_details?.no_of_leaves}} leave will be credited on
                    {{this.appservice.ordinal_suffix_of(leaveRuleData?.leave_credit_details?.dayof_month)}}
                    of every {{leaveRuleData?.leave_credit_details?.period == 'Monthly' ? 'month' :
                    leaveRuleData?.leave_credit_details?.period == 'Yearly' ? 'year' :
                    leaveRuleData?.leave_credit_details?.period == 'Ouarterly' ? 'quarter' : 'half year'}}.</li>

                  <ng-container *ngIf="leaveRuleData?.leave_credit_details?.grant_leave_based_prev_month">
                    <li>
                      The above leave will be credited based on the employee's previous month attendance, employees should have {{leaveRuleData?.leave_credit_details?.prev_month_min_count}} or more {{leaveRuleData?.leave_credit_details?.req_attendance_calculate_from == true ? 'Paid days' : 'Present days'}} in the previous month to get the full leave quota.
                    </li>
                    <li *ngIf="leaveRuleData?.leave_credit_details?.criteria_not_met">
                      If the employee's previous month's attendance ({{leaveRuleData?.leave_credit_details?.req_attendance_calculate_from == true ? 'Paid days' : 'Present days'}}) does not match with the required criteria, then the current leave accrual will be prorated based on attendance.
                    </li>
                    <li *ngIf="!leaveRuleData?.leave_credit_details?.criteria_not_met">
                      If the employee's previous month's attendance ({{leaveRuleData?.leave_credit_details?.req_attendance_calculate_from == true ? 'Paid days' : 'Present days'}}) does not match with the required criteria, then no leave will be credited in the current leave accrual.
                    </li>
                  </ng-container>

                  <li *ngIf="leaveRuleData?.leave_credit_details?.carry_forward_next_cycle">Maximum of
                    {{leaveRuleData?.leave_credit_details?.carry_forward_limit}} leaves can be carry
                    forwarded to next leave cycle.</li>

                </ol>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Request Criteria</p>
              </div>
              <div class="card-body p-24">
                <ol class="li-reset li-16  fs-14">

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_waiting_status">
                    This leave type can be requested only after {{leaveRuleData?.leave_request_criteria?.waiting_period_days}} {{leaveRuleData?.leave_request_criteria?.waiting_period_effective}} from {{leaveRuleData?.leave_request_criteria?.waiting_period_start_date == true ? 'DOC' : 'DOJ'}}.
                  </li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.prior_days_required_req_leave">
                    Leave request should be raised in
                    {{leaveRuleData?.leave_request_criteria?.prior_days}} days' prior notice.</li>
                  <li *ngIf="leaveRuleData?.leave_request_criteria?.backdated_leave_allow">Back dated
                    leave request is allowed for this leave type (max
                    {{leaveRuleData?.leave_request_criteria?.backdated_days}} days before).
                  </li>
                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_during_probation">
                    {{leaveRuleData?.leave_request_criteria?.leave_during_probation ? 'Leave request can be raised during probation.' : 'Leave request cannot be raised during probation.'}}</li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.doc_required">Document is
                    mandatory if leave exceeds
                    {{leaveRuleData?.leave_request_criteria?.document_limit}} days.</li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_clubbing_not_allowed_status">
                    This leave type cannot be clubbed with {{leaveClubbedData.join(', ')}}.
                  </li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_taken_birthday">Leave can be
                    taken only on birthday.</li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_taken_wedding_anniversary">
                    Leave can be taken only on wedding anniversary.</li>

                </ol>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Request Limit</p>
              </div>
              <div class="card-body p-24">
                <ol class="li-reset li-16  fs-14">

                  <li>The maximum number of requests that can be raised in a tenure:
                    {{leaveRuleData?.leave_request_limit?.max_req_apply_tenure_status ?
                    leaveRuleData?.leave_request_limit?.max_req_apply_tenure+' requests.' : 'No limit.'}}</li>

                  <li>The maximum number of requests that can be raised in a year:
                    {{leaveRuleData?.leave_request_limit?.max_req_apply_year_status ?
                    leaveRuleData?.leave_request_limit?.max_req_apply_year+' requests.' : 'No limit.'}}</li>

                  <li>The maximum number of requests that can be raised in a month:
                    {{leaveRuleData?.leave_request_limit?.max_req_apply_month_status ?
                    leaveRuleData?.leave_request_limit?.max_req_apply_month+' requests.' : 'No limit.'}}</li>

                  <li>The maximum number of leaves that can be applied for in a month:
                    {{leaveRuleData?.leave_request_limit?.max_leave_apply_month_status ?
                    leaveRuleData?.leave_request_limit?.max_leave_apply_month+' leaves.' : 'No limit.'}}</li>

                  <li>The minimum number of leaves required per request:
                    {{leaveRuleData?.leave_request_limit?.min_leave_required ?
                    leaveRuleData?.leave_request_limit?.min_leave_days+' leaves.' : 'No limit.'}}
                  </li>

                  <li>The maximum number of leaves required per request:
                    {{leaveRuleData?.leave_request_limit?.max_leave_required ?
                    leaveRuleData?.leave_request_limit?.max_leave_days+' leaves.' : 'No limit.'}}
                  </li>

                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
