import { Component, OnInit ,Inject} from '@angular/core';
import { FormArray, FormBuilder, Validators ,FormGroup} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MessageService } from 'src/app/message.global';
import { ExpenseReceiptService } from 'src/app/@core/services/expense-receipt.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { Router} from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { ExpenseReportProfileService } from 'src/app/@core/services/expense-report-profile.service';


@Component({
  selector: 'app-bulk-expense',
  templateUrl: './bulk-expense.component.html',
  styleUrls: ['./bulk-expense.component.scss']
})
export class BulkExpenseComponent implements OnInit {
  selectedOption: string      = 'amount';
  reportDD:any               = [];
  categoryDD:any             = [];
  locationDD:any             = [];
  singleCategory:any         = [];
  isrequired:any             = false;
  milageRate:any             = 0;
  uploadeAttachments         = false;
  updateAttachmentIndex      = 0;
  uploadedFile:any           = [];
  viewAttchments:any         = false
  uploadedAttachments:any    = [];
  dailyRestriction:any       = [];
  monthlyRestriction:any     = [];
  weeklyRestriction:any      = [];
  yearlyRestriction:any      = [];
  lifeTimeRestriction:any    = [];
  restrictionType:any
  currency:any;
  // currencyPlaceHolder        = this.messageService.selectplaceholddisplay('currency')
  searchString:any = "";
  page = 1;
  pageSize = 20;
  bulkForm = this.fb.group({
              report          : [null],
              expense_type    : [false,[Validators.required]],
              receipts        : this.fb.array([])
  })
  employee_id:any;
  disableadd =false;
  minDate                    = new Date(2020, 0, 1);

  constructor(
    public fb             : FormBuilder,
    public appService     : AppService,
    public messageService : MessageService,
    public receiptService : ExpenseReceiptService,
    private router        : Router,
    private authService   : AuthService,
    public reportService  :ExpenseReportProfileService,


    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,

  ) { }

  ngOnInit(): void {
    let userData        = this.appService.getEmpData();;
    this.currency       = userData.currency;
    this.employee_id    = this.authService.isEmployeeId
    this.addMoreReceipt();
    this.changeExpenseType();
    this.getAllReport();
    // this.getLocationData();
  }
  get f() { return this.bulkForm.controls }

  receiptsarray() : FormArray {
    return this.bulkForm.get("receipts") as FormArray
  }
  newreceiptarray(): FormGroup {
    return this.fb.group({
      expense_date      : ['',Validators.required],
      category          : [null,Validators.required],
      amount            : [''],
      is_reimbursement  : [false],
      desicription      : [],
      attachments       : [[]],
      from_location     : [null,Validators.required],
      to_location       : [null],
      distance          : [0],
      // currency          : [null]
    })
  }
  addMoreReceipt() {
    this.receiptsarray().push(this.newreceiptarray());
    let sessionData = {
      url            : '',
      size           : '',
      required       : false,
      milegerate     : 0,
      limitType 	 : '',
      availableAmount: 0,
      frequency      : 0
    }
    this.uploadedFile.push(sessionData)
    if(this.bulkForm.get('expense_type')?.value==true)
    this.milegeValidators('add');
    else
    this.amountFiledValidators('add');
    for(let i=0;i<this.receiptsarray().controls.length-1;i++){
      this.receiptsarray().controls[i].get('expense_date')?.markAsTouched()
      this.receiptsarray().controls[i].get('category')?.markAsTouched()
      this.receiptsarray().controls[i].get('amount')?.markAsTouched()
      this.receiptsarray().controls[i].get('desicription')?.markAsTouched()
      this.receiptsarray().controls[i].get('from_location')?.markAsTouched()
      this.receiptsarray().controls[i].get('to_location')?.markAsTouched()

    }
    this.page = Math.ceil( this.receiptsarray().controls.length / this.pageSize);
  }
  removeReceipt(i:number) {
    this.receiptsarray()?.removeAt(i);
    this.uploadedFile.splice(i, 1);
  }
  getAllReport(){
    this.receiptService.getReportDropdown().subscribe((res:any)=>{
      this.reportDD = res;
    })
  }
  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  getCategoryData(type:any){
    this.receiptService.categoryListData(type,this.employee_id).subscribe((res:any)=>{
      this.categoryDD = res;
    })
  }
  changeExpenseType(){
    this.getCategoryData(this.bulkForm.get('expense_type')?.value)
    if(this.bulkForm.get('expense_type')?.value==true)
    this.milegeValidators('change');
    else
    this.amountFiledValidators('change');
  }
  milegeValidators(from:any){
    for(let i=0;i<this.receiptsarray().length;i++){

      this.setValidators(i,'amount',false,digitdecimalValidator,10)
      this.setValidators(i,'to_location',true,false,0)
      this.setValidators(i,'distance',true,digitdecimalValidator,10)
      if(from=='change'){

        this.receiptsarray().controls[i].get('distance')?.setValue('')
        this.setValue(i);

      }
    }

  }
  amountFiledValidators(from:any)
  {
    for(let i=0;i<this.receiptsarray().length;i++){
      if(!this.receiptsarray().controls[i].get('amount')?.errors){
        this.setValidators(i,'amount',true,digitdecimalValidator,10)
        }
          this.setValidators(i,'to_location',false,false,0)
       
          this.setValidators(i,'distance',false,digitdecimalValidator,10)
        if(from=='change'){
        this.setValue(i);
        this.receiptsarray().controls[i].get('distance')?.setValue(0)
        }
    }
  }
  setValue(i:any){
    this.receiptsarray().controls[i].get('from_location')?.setValue(null)
    this.receiptsarray().controls[i].get('category')?.setValue(null)
    this.receiptsarray().controls[i].get('to_location')?.setValue(null)
    this.receiptsarray().controls[i].get('amount')?.setValue('')
    this.receiptsarray().controls[i].get('is_reimbursement')?.setValue(false)
    this.receiptsarray().controls[i].get('is_reimbursement')?.enable();
  }

  getLocationData(id:any){
    this.receiptService.LocatonListData(id).subscribe((res:any)=>{
      this.locationDD = res;
    })
  }
  changeCategory(index:any){
    if(this.receiptsarray().controls[index].get('category')?.value!=null && this.receiptsarray().controls[index].get('category')?.value!=''){
    this.getLocationData(this.receiptsarray().controls[index].get('category')?.value);
    if(this.receiptsarray().controls[index].get('expense_date')?.value==null || this.receiptsarray().controls[index].get('expense_date')?.value==''){
      this.receiptService.getSingleCategoryData(this.receiptsarray().controls[index].get('category')?.value).subscribe((res:any)=>{
        this.setDefaultvalue(index,res);
        this.validateAttachment(index);
      })
    }
    else{
      let date = this.appService.dateFormatConvert(this.receiptsarray().controls[index].get('expense_date')?.value)
      this.receiptService.getCategoryConfig(this.receiptsarray().controls[index].get('category')?.value,date,'').subscribe((res:any)=>{
        this.singleCategory = res;
        this.minDate = res.date_of_joined;
        this.setDefaultvalue(index,res);
        this.validateAttachment(index);

      })
    }
   }
  }
  setDefaultvalue(index:any,value:any){
    this.singleCategory = value;
    this.receiptsarray().controls[index].get('is_reimbursement')?.setValue(this.singleCategory['is_reimbursable'])
    this.setValidators(index,'desicription',this.singleCategory['is_description'],false,500);

    if(this.singleCategory['is_reimbursable']==false)
    this.receiptsarray().controls[index].get('is_reimbursement')?.disable();

    // if(this.singleCategory['limit']!=null)
    // this.requestRestriction(value,index)


    this.uploadedFile[index]['size']              = this.singleCategory['attachment_minimum_amount_limit']
    this.uploadedFile[index]['required']          = this.singleCategory['is_attachment']
    this.uploadedFile[index]['milegerate']        = this.singleCategory['rate_per_km']
    this.uploadedFile[index]['limitType']         = this.singleCategory['limit_period']
    this.uploadedFile[index]['availableAmount']   = this.singleCategory['claim_limit_balance']
    this.uploadedFile[index]['frequency']         = this.singleCategory['claim_fequency_limit_balance']
    // if(this.bulkForm.get('expense_type')?.value==true)
    // this.calculateAmount(index)
    
    this.changevaluebulk(index);
  }
  setValidators(index:any,control:any,value:any,pattern:any,maxlength:any){
    if(value==true){

      if(pattern==false && maxlength==0)
      this.receiptsarray().controls[index].get(control)?.setValidators([Validators.required])
      else if(pattern==false && maxlength!=0)
      this.receiptsarray().controls[index].get(control)?.setValidators([Validators.required,Validators.maxLength(maxlength)])
      else if(pattern!=false && maxlength!=0)
      this.receiptsarray().controls[index].get(control)?.setValidators([Validators.required,Validators.maxLength(maxlength),digitdecimalValidator(),Validators.min(1)])
      else
      this.receiptsarray().controls[index].get(control)?.setValidators([Validators.required,digitdecimalValidator(),Validators.min(1)])

    }
    else
    this.receiptsarray().controls[index].get(control)?.clearValidators();
    this.receiptsarray().controls[index].get(control)?.updateValueAndValidity()
  }
  addAttachmentFunction(index:any){
    this.uploadeAttachments    = true;
    this.updateAttachmentIndex = index;

  }
  saveAttachment(data:any){
    this.uploadeAttachments                              = false;
    this.uploadedFile[this.updateAttachmentIndex]['url'] = [...this.uploadedFile[this.updateAttachmentIndex]['url'], ...data?.attachments];
    this.receiptsarray().controls[this.updateAttachmentIndex].get('attachments')?.setValue(this.uploadedFile[this.updateAttachmentIndex]['url'])

  }

  closeAttachment(){
    this.uploadeAttachments = false;
  }

  saveBulkReceipt(){
    let formValue = this.bulkForm.value;
    for(let i=0;i<this.receiptsarray().length;i++){
      let date =  this.appService.dateFormatConvert(this.receiptsarray().controls[i].get('expense_date')?.value)
      formValue.receipts[i].expense_date = date;

    }

    this.receiptService.saveBulkReceipt(formValue).subscribe((res:any)=>{
      this.router.navigate(['expense-receipt']);

    })

  }
  calculateAmount(index:any){

    if(this.receiptsarray().controls[index].get('category')?.value != null && this.receiptsarray().controls[index].get('category')?.value != ''){
      this.changevaluebulk(index);
    }

    this.milageRate = Number(this.uploadedFile[index]['milegerate'])*Number(this.receiptsarray().controls[index].get('distance')?.value)
    this.receiptsarray().controls[index].get('amount')?.setValue(this.milageRate);
    // this.makeRestriction(index,this.uploadedFile[index]['limitType'])

  }

  viewAttachment(index:any){
    this.viewAttchments         = true;
    this.uploadedAttachments     = [];
    for(let i=0;i<this.uploadedFile[index]['url'].length;i++){
    let attachment = {'attachment' :this.uploadedFile[index]['url'][i]}
    this.uploadedAttachments.push(attachment)

    }

    this.updateAttachmentIndex  = index
  }
  closeViewAttachment(){
    this.viewAttchments         = false;

  }
  saveViewAttachment(data:any){

    let latestattachment = []
    for(let i=0;i<data.attachments.length;i++ ){
      latestattachment.push(data.attachments[i]['attachment'])
    }

    this.uploadedFile[this.updateAttachmentIndex]['url'] = '';
    this.uploadedFile[this.updateAttachmentIndex]['url'] = latestattachment;
    this.receiptsarray().controls[this.updateAttachmentIndex].get('attachments')?.setValue(this.uploadedFile[this.updateAttachmentIndex]['url'])
    this.viewAttchments         = false;

  }
  validateAttachment(i:any){

    if(this.uploadedFile[i]['required']==true && this.uploadedFile[i]['size']==null){
      this.receiptsarray().controls[i].get('attachments')?.setValidators([Validators.required])
    }
    else if(this.uploadedFile[i]['required']==true && this.uploadedFile[i]['size']!=null){
      if(this.receiptsarray().controls[i].get('amount')?.value>=this.uploadedFile[i]['size']){
        this.receiptsarray().controls[i].get('attachments')?.setValidators([Validators.required])
      }
      else if(this.receiptsarray().controls[i].get('amount')?.value<this.uploadedFile[i]['size']){
        this.receiptsarray().controls[i].get('attachments')?.clearValidators();
      }
    }
    else{
      this.receiptsarray().controls[i].get('attachments')?.clearValidators();
    }
    this.receiptsarray().controls[i].get('attachments')?.updateValueAndValidity()
    // this.makeRestriction(i,this.uploadedFile[i]['limitType'])
    if(this.receiptsarray().controls[i].get('category')?.value != null && this.receiptsarray().controls[i].get('category')?.value != ''){
      this.changevaluebulk(i);
    }
  }
 
  validateExpenseDate(index:any){
    let expense_date = this.receiptsarray().controls[index].get('expense_date')?.value
    if(this.bulkForm.get('report')?.value!=null && this.bulkForm.get('report')?.value!='' && expense_date!=null && expense_date!=''){
      let id                 = this.bulkForm.get('report')?.value
      const expenseDate      = moment(expense_date).format('YYYY-MM-DD')
      let convertDate        = new Date(expenseDate)
      this.reportService.getSingleReportData(id).subscribe((res:any)=>{
        const fromDate      = moment(res.from_date).format('YYYY-MM-DD')
        const toDate        = moment(res.to_date).format('YYYY-MM-DD')
        const ConvertedFrom = new Date(fromDate);
        const ConvertedTo   = new Date(toDate);
        if(convertDate.getTime() < ConvertedFrom.getTime() || convertDate.getTime() > ConvertedTo.getTime() ){
          this.receiptsarray().controls[index].get('expense_date')?.setErrors({'exceed': true});
        }
        else{
        this.receiptsarray().controls[index].get('expense_date')?.setErrors(null);
        }
        
      })
    }
    if(this.receiptsarray().controls[index].get('category')?.value != null && this.receiptsarray().controls[index].get('category')?.value != '' && expense_date != null){
          this.changevaluebulk(index);
    }
  }

  filteredcount(){
    return this.filterIt().length;
  }

  filterIt() {
    return this.receiptsarray().controls.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey     = obj[key].toString().toLowerCase();
          const tempSearch  = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  validateExpenseDateReport(){
    for(let index=0;index<this.receiptsarray().controls.length;index++){

      let expense_date = this.receiptsarray().controls[index].get('expense_date')?.value
      if(this.bulkForm.get('report')?.value!=null && this.bulkForm.get('report')?.value!='' && expense_date!=null && expense_date!=''){
        let id                 = this.bulkForm.get('report')?.value
        const expenseDate      = moment(expense_date).format('YYYY-MM-DD')
        let convertDate        = new Date(expenseDate)
        this.reportService.getSingleReportData(id).subscribe((res:any)=>{
          const fromDate      = moment(res.from_date).format('YYYY-MM-DD')
          const toDate        = moment(res.to_date).format('YYYY-MM-DD')
          const ConvertedFrom = new Date(fromDate);
          const ConvertedTo   = new Date(toDate);
          if(convertDate.getTime() < ConvertedFrom.getTime() || convertDate.getTime() > ConvertedTo.getTime() ){

            this.receiptsarray().controls[index].get('expense_date')?.setErrors({'exceed': true});

          }
          else
          this.receiptsarray().controls[index].get('expense_date')?.setErrors(null);


        })

      }
    }
  }

  changevaluebulk(ind:any){
    let bulkData:any=[]
    for(let index=0;index<this.receiptsarray().controls.length;index++){
      if(this.receiptsarray().controls[index].get('expense_date')?.value!='' && this.receiptsarray().controls[index].get('expense_date')?.value !=null && this.receiptsarray().controls[index].get('category')?.value !=null){
        bulkData?.push({
              "expense_date": moment(this.receiptsarray().controls[index].get('expense_date')?.value).format('YYYY-MM-DD'),
              "category": this.receiptsarray().controls[index].get('category')?.value,
              "amount": Number(this.receiptsarray().controls[index].get('amount')?.value),
              "from_location": this.receiptsarray().controls[index].get('from_location')?.value,
              "to_location": this.receiptsarray().controls[index].get('to_location')?.value,
              "distance": Number(this.receiptsarray().controls[index].get('distance')?.value)            
        })}
      }
    this.receiptService.bulkexpensevalidate({"receipts": bulkData}).subscribe((res:any)=>{  
        this.disableadd = false;
        if(this.receiptsarray().controls[ind].get('category')?.hasError('exceed')){
          this.receiptsarray().controls[ind].get('category')?.setErrors({'exceed': false});
        }
        if(this.receiptsarray().controls[ind].get('amount')?.hasError('exceed')){
          this.receiptsarray().controls[ind].get('amount')?.setErrors({'exceed': false});
        }
        if(this.receiptsarray().controls[ind].get('distance')?.hasError('exceed')){
          this.receiptsarray().controls[ind].get('distance')?.setErrors({'exceed': false});
        }

        if(!this.receiptsarray().controls[ind].get('category')?.hasError('required')){
          this.receiptsarray().controls[ind].get('category')?.setErrors(null);
        }
        if(!this.receiptsarray().controls[ind].get('amount')?.hasError('required') && !this.receiptsarray().controls[ind].get('amount')?.hasError('pattern') && !this.receiptsarray().controls[ind].get('amount')?.hasError('min') && !this.receiptsarray().controls[ind].get('amount')?.hasError('maxlength')){
          this.receiptsarray().controls[ind].get('amount')?.setErrors(null);
        }
        if(!this.receiptsarray().controls[ind].get('distance')?.hasError('required') && !this.receiptsarray().controls[ind].get('distance')?.hasError('pattern') && !this.receiptsarray().controls[ind].get('distance')?.hasError('min') && !this.receiptsarray().controls[ind].get('distance')?.hasError('maxlength')){
          this.receiptsarray().controls[ind].get('distance')?.setErrors(null);
        }

    },(err:any)=>{
      this.disableadd = true;
      if(err.error.category_error != null){
        this.receiptsarray().controls[ind].get('category')?.setErrors({'exceed': true});
      }else if(!this.receiptsarray().controls[ind].get('category')?.hasError('required')){
        this.receiptsarray().controls[ind].get('category')?.setErrors(null);
      }else if(this.receiptsarray().controls[ind].get('category')?.hasError('exceed')){
        this.receiptsarray().controls[ind].get('category')?.setErrors({'exceed': false});
      }

      if(err.error.amount_error != null){
         this.receiptsarray().controls[ind].get('amount')?.setErrors({'exceed': true});
      }else if(!this.receiptsarray().controls[ind].get('amount')?.hasError('required') && !this.receiptsarray().controls[ind].get('amount')?.hasError('pattern') && !this.receiptsarray().controls[ind].get('amount')?.hasError('min') && !this.receiptsarray().controls[ind].get('amount')?.hasError('maxlength')){
        this.receiptsarray().controls[ind].get('amount')?.setErrors(null);
      }else if(this.receiptsarray().controls[ind].get('amount')?.hasError('exceed')){
        this.receiptsarray().controls[ind].get('amount')?.setErrors({'exceed': false});
      }

      if(err.error.distance_error != null){
        this.receiptsarray().controls[ind].get('distance')?.setErrors({'exceed': true});
      }else if(!this.receiptsarray().controls[ind].get('distance')?.hasError('required') && !this.receiptsarray().controls[ind].get('distance')?.hasError('pattern') && !this.receiptsarray().controls[ind].get('distance')?.hasError('min') && !this.receiptsarray().controls[ind].get('distance')?.hasError('maxlength')){
        this.receiptsarray().controls[ind].get('distance')?.setErrors(null);
      }else if(this.receiptsarray().controls[ind].get('distance')?.hasError('exceed')){
        this.receiptsarray().controls[ind].get('distance')?.setErrors({'exceed': false});
      } 
    })
  }
  locationsame(ind:any){
    if(this.receiptsarray().controls[ind].get('from_location')?.value !='' && this.receiptsarray().controls[ind].get('from_location')?.value !=null && this.receiptsarray().controls[ind].get('to_location')?.value !='' && this.receiptsarray().controls[ind].get('to_location')?.value !=null && (this.receiptsarray().controls[ind].get('from_location')?.value == this.receiptsarray().controls[ind].get('to_location')?.value)){
      this.receiptsarray().controls[ind].get('to_location')?.setErrors({
     'same' : true
      })
    }else{
      this.receiptsarray().controls[ind].get('to_location')?.setErrors(null);
    }
  }
}
