import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { RequestComponent } from './request/request.component';
import { LeaveRequestSetupComponent } from './leave/leave-request-setup/leave-request-setup.component';
import { OnDutySetupComponent } from './on-duty/on-duty-setup/on-duty-setup.component';
import { ProposedInvestmentDeclerationSetupComponent } from './investment-decleration/proposed-investment-decleration-setup/proposed-investment-decleration-setup.component';
import { ConfirmedInvestmentDeclerationSetupComponent } from './investment-decleration/confirmed-investment-decleration-setup/confirmed-investment-decleration-setup.component';
import { RegularizationSetupComponent } from './regularization/regularization-setup/regularization-setup.component';
import { ReimbursementSetupComponent } from './reimbursement/reimbursement-setup/reimbursement-setup.component';
import { WorkFromHomeSetupComponent } from './work-from-home/work-from-home-setup/work-from-home-setup.component';
import { ShortLeaveRequestSetupComponent } from './short-leave-request-setup/short-leave-request-setup.component';
import { LoanRequestSetupComponent } from './loan/loan-request-setup/loan-request-setup.component';
const routes: Routes = [LayoutService.childRoutes([
  { path: 'request', component: RequestComponent },
  { path: 'leave-request-setup', component: LeaveRequestSetupComponent },
  { path: 'leave-request-setup/:id', component: LeaveRequestSetupComponent },
  { path: 'on-duty-setup', component: OnDutySetupComponent },
  { path: 'on-duty-setup/:id', component: OnDutySetupComponent },
   { path: 'proposed-investment-decleration', component: ProposedInvestmentDeclerationSetupComponent },
   { path: 'proposed-investment-decleration/:id', component: ProposedInvestmentDeclerationSetupComponent },
  { path: 'confirmed-investment-decleration', component: ConfirmedInvestmentDeclerationSetupComponent },
  { path: 'confirmed-investment-decleration/:id', component: ConfirmedInvestmentDeclerationSetupComponent },
  { path: 'regularization-setup', component: RegularizationSetupComponent },
  { path: 'regularization-setup/:id', component: RegularizationSetupComponent },
  { path: 'reimbursement-setup', component: ReimbursementSetupComponent },
  { path: 'reimbursement-setup/:id', component: ReimbursementSetupComponent },
  { path: 'work-from-home-setup', component: WorkFromHomeSetupComponent },
  { path: 'work-from-home-setup/:id', component: WorkFromHomeSetupComponent },
  { path: 'short-leave-setup', component: ShortLeaveRequestSetupComponent },
  { path: 'loan-setup', component: LoanRequestSetupComponent },
  { path: 'short-leave-setup/:id', component: ShortLeaveRequestSetupComponent },
])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestRoutingModule { }
