import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AuthModule } from './@core/auth/auth.module';
import { LayoutModule } from './@shared/components/layout/layout.module';
import { AuthInterceptor } from './@core/interceptors/auth.interceptor';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DpDatePickerModule } from 'ng2-date-picker';

import { ResponseInterceptor } from './@core/interceptors/response.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LyHammerGestureConfig, LY_THEME, LY_THEME_NAME, StyleRenderer, LyTheme2 } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MiscellaneousService } from './@core/services/miscellaneous.service';
import { AppService } from './app.global';
// import { RollbarService, rollbarFactory, RollbarErrorHandler } from './@shared/rollbar/rollbar';
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { DataTablesModule } from 'angular-datatables';
import { MessageService } from './message.global';
import { HeaderComponent } from './@shared/components/layout/header/header.component';
import { SidebarComponent } from './@shared/components/layout/sidebar/sidebar.component';
import { FooterComponent } from './@shared/components/layout/footer/footer.component';
import { DateAgoPipe } from './@shared/pipes/date-ago.pipe';
// import { DownloadFileService } from './download-file.global';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { FileExtensionService } from './fileExtension.global';
import { HeaderInterceptor } from './@core/interceptors/header.interceptor';
import { MasterSelectModule } from "./@shared/master-select.module";
// import { QRCodeModule } from 'angularx-qrcode';
// import { AgmCoreModule } from '@agm/core';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        DateAgoPipe,
    ],
    providers: [
        AppService,
        MessageService,
        // DownloadFileService,
        // FileExtensionService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
        { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
        StyleRenderer,
        LyTheme2,
        { provide: LY_THEME_NAME, useValue: 'minima-light' },
        { provide: LY_THEME, useClass: MinimaLight, multi: true },
        { provide: WebSocket, useValue: 'ws' },
        // { provide: ErrorHandler, useClass: RollbarErrorHandler },
        // { provide: RollbarService, useFactory: rollbarFactory }
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        AuthModule,
        LayoutModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        // ToastrModule.forRoot({timeOut:10000,positionClass:"toast-bottom-left"})
        ToastrModule.forRoot({
            timeOut: 8000,
            positionClass: 'toast-bottom-left',
            // extendedTimeOut:100000,
            toastClass: 'toast-c1 ngx-toastr',
            closeButton: true,
            tapToDismiss: false,
        }),
        HammerModule,
        NgbModule,
        DpDatePickerModule,
        Ng2SearchPipeModule,
        InfiniteScrollModule,
        MasterSelectModule,
        // AgmCoreModule.forRoot({
        //   apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
        // }),
    ]
})
export class AppModule {
}
