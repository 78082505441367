import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { LeavePolicyService } from 'src/app/@core/services/leave-policy.service';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import {
  BreakpointObserver,BreakpointState} from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-leave-policy',
  templateUrl: './leave-policy.component.html',
  styleUrls: ['./leave-policy.component.scss']
})
export class LeavePolicyComponent implements OnInit {

  viewDetail2                 = false;
  status                      = true;
  deleteAlert                 = false;
  permissions:any             = [];
  offset                      = 0;
  infinityloader              = false;
  leavePolicyData :any        = [];
  loader                      = false;
  nodata                      = false;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  searchlistdata:any          = [] ;
  leaveListData:any           = [];
  viewListData:any            = [];
  deleteId                    = 0;
  leavePlaceholder            = this.messageService.searchdisplay('Leave Policy');
  viewRightsArray:any         = [];
  viewApplicable:any;
  from                        = 'leaveRule';
  editPermission              = false;
  viewloader                  = false;
  deleteClicked               = false;
  statusBtn:any               = 'Active';
  leaveMapping:any            = [];
  isLoading = false
  searchData : Subject<void> = new Subject();
  searchKeyword="";
  @ViewChild(SearchbarComponent) child:any;

  constructor(public appService:AppService,public messageService : MessageService, private leaveService:LeavePolicyService,private router:Router,
    public breakpointObserver: BreakpointObserver,
    ) { }

  ngOnInit(): void {
    this.getPermission();
    this.status           = true;
    this.getAllLeavepolicy();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.leaveService.getLeavePolicyData(this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  statusfilter(active_status:any){
    this.status           = active_status;
    this.leavePolicyData  = [];
    this.offset            = 0;
    this.child.clearBar();
  }

  getAllLeavepolicy(){
    this.loader           = true;
    this.leaveService.getLeavePolicyData(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength       = res.results.length;
    if(this.offset ==0){
      this.leavePolicyData = [];
    }
    if (res.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata               = false;
      this.leaveListData        = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  viewSingledata(id:number){
    if(this.permissions.v){
     this.viewloader = true;
      if(this.viewRightsArray.length!=0){
        this.viewRightsArray = [];
      }
      this.viewDetail2    = true;
      this.leaveService.getSingleLeavePolicyData(id).subscribe((res:any)=>{
        this.viewListData   = res;
        this.viewloader     = false;
        for(let i in this.viewListData.leave_policy_assignright){
          let item  = this.viewListData.leave_policy_assignright[i];

          item.employee_name['name']  = "";
          if(item.employee_name['first_name'] != null)
          item.employee_name['name'] += item.employee_name['first_name']+" ";
          if(item.employee_name['middle_name'] != null)
          item.employee_name['name'] += item.employee_name['middle_name']+" ";
          if(item.employee_name['last_name'] != null)
          item.employee_name['name'] += item.employee_name['last_name'];

          this.viewApplicable=item.applicable;
          if(item.applicable=='Company'){
            this.viewRightsArray.push({name:item.company_name,img:item.company_logo});
          }item
          if(item.applicable=='BU'){
            this.viewRightsArray.push(item.businessunit_name);
          }
          if(item.applicable=='Department'){
            this.viewRightsArray.push(item.department_name);
          }
          if(item.applicable=='Designation'){
            this.viewRightsArray.push(item.designation_name);
          }
          if(item.applicable=='Employee'){
            this.viewRightsArray.push({name:item.employee_name.name,img:item.employee_name['profile_image'],empcode:item.employee_name['employee_code'],color_code:item.employee_name['color_code']});
          }
          if(item.applicable=='Grade'){
            this.viewRightsArray.push(item.grade_name);
          }
        }
        var leavemapping =  this.viewListData.leave_mapping.filter(function(data:any) {
          return data.is_active === true;
        });
        this.leaveMapping = leavemapping;
      })
    }
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.leavePolicyData.push(this.leaveListData[i]);
    }
  }

  onScrollDown() {
    this.infinityloader     = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getAllLeavepolicy();
      this.loader       = false;
      this.direction    = "down";
    }
  }

  searchresults(data:any){
    this.loader             = true;
    if(data == ""){
    this.leavePolicyData    = [];
    this.searchKeyword  = "";
    this.offset = 0;
    this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  deleteLeavePolicy(id:number){
    this.deleteClicked =true
    let data = { "is_active": false};
    this.leaveService.deleteLeavepolicy(id,data).subscribe((res: any) => {
      this.deleteAlert      = false;
      this.loader           = true;
      this.offset           = 0;
      this.leavePolicyData  = [];
      this.getAllLeavepolicy();
      this.deleteClicked =false
    },
    (error:any)=>{
      this.deleteClicked = false
      this.deleteAlert   = false;
    })
  }

  editLeavepolicy(id:number){
    this.router.navigate(['/leave-rule/leave-policy-setup/'+id]);
  }

  /********* LEAVE POLICY COMPONENT CLOSE FUNCTION ****************/
  leavePolicyViewClose(data:any){
    this.viewDetail2 = data;
  }

  searchleavepolicy(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset           = 0;
    this.leavePolicyData= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/leave-rule/leave-policy-setup/new']);
  }
}
