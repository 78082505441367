import { Component, OnInit, Input,Output,EventEmitter} from '@angular/core';
import * as moment from 'moment';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-view-reimbursement-details',
  templateUrl: './view-reimbursement-details.component.html',
  styleUrls: ['./view-reimbursement-details.component.scss']
})
export class ViewReimbursementDetailsComponent implements OnInit {

  constructor(
    public appService:AppService,
    private notificationService:NotificationService
  ) { }
  modalToggle                     = false;
  alertMsg:any                    = '';
  alertToggle                     = false;
  fileName                        = "";
  currentYear                     = new Date().getFullYear();
  currentMonth                    = new Date().toLocaleString('en-us', { month: 'long' }).toUpperCase();
  currentDocName:any;
  currentExtension:any;
  loader = true;
  showTrend = false;
  requestCancel:any
  @Input()currency : any;
  loader1: boolean = false

  @Input() billDetails: any;
  @Input() isCancel:boolean = false;
  @Input() from:any;
  @Input() empId:any;
  @Input() billCancelStatus:any
  @Output() closeViewPage= new EventEmitter();

  ngOnInit(): void { }

  ngOnChanges(){
    if(this.billDetails != undefined)
      this.loader = false;
  }

  formatDate(date:any){
    var d = moment(date).format('DD');
    var m = moment(date).format('MMM');
    var y = moment(date).format('YYYY');
    return (d+' '+m+' '+y);
  }


  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    // var currentDocName : any    = this.fileName.split('_').pop();
    // this.currentDocName         = currentDocName.split('.').slice(0, -1);
    // this.currentDocName         = decodeURIComponent(this.currentDocName);
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool:any){
    this.alertToggle = bool
  }
  cancelRequest(){
    if(this.billCancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else{
      this.requestCancel = true;
      // this.cancelId      = cancelId;
    }
  }
  closeCancelModal(data:any){
    this.requestCancel = false;

  }
  updateStatus(data:any){
    this.requestCancel = false;
    this.closeViewPage.emit(data);

  }
}


