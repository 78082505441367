import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
  chart: any;
  constructor() { }
  uniqueId: any;
  context: any
  @Input() labels: any
  @Input() data: any
  @Input() color: any
  @Input() id: any
  @Input() showRupee: any
  @Input() currency: any
  @Input() total: any = ''
  @Input() radius: any
  @Input() height: any
  @Input() labelFontSize: any
  @Input() totalCenter: any
  @Input() labeltype: any
  @Input() showDays: any = ''
  @Input() from: any;
  @ViewChild('canvas', { static: false }) canvas!: ElementRef;
  ngOnInit(): void {
    this.radius = this.radius == undefined ? 45 : this.radius
    this.totalCenter = this.totalCenter == undefined ? false : this.totalCenter
    this.labeltype = this.labeltype == undefined ? 1 : this.labeltype
    this.createChart();
  }
  createChart() {
    setTimeout(() => {
      // Iniatlized the specific canvas with it's reference
      this.context = (<HTMLCanvasElement>this.canvas.nativeElement).getContext('2d');
      // ID is given inorder to use same chart more than 1's on same component
      this.uniqueId = 'canvas' + this.id;
      this.chart = new Chart(this.context, {
        type: 'doughnut', //this denotes the type of chart
        data: {
          labels: this.labels,
          datasets: [{
            data: this.data,
            backgroundColor: this.color,
            borderWidth: 0,
          }]
        },
        options: {
          cutout: this.radius,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: '#ffffff',
              borderWidth: 1,
              borderColor: 'rgba(0, 0, 0, 0.2)',
              bodyColor: '#324552',
              bodyFont: {
                size: 12,
                family: 'Work Sans',
              },
              titleColor: '#324552',
              titleFont: {
                size: 12,
                weight: 'bold',
                family: 'Work Sans'
              }
            },
          }
        }
      });
    })
  }

  getTotal() {
    let total = 0;
    this.data.forEach((item: any) => {
      total += Number(item);
    });
    // Round off for total upto 2places
    if(this.from != 'myteam')
      return total.toFixed(2);
    else
      return total;
  }
}
