import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AttendanceCapturePolicyService {

  constructor(
    private http: HttpClient,
    public router: Router) { }

    API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

attendanceCaptureList(is_active:any,limit:any,offset:any,search:any){
  return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-capture-list/?is_active=${is_active}&limit=${limit}&offset=${offset}&search=${search}`,this.httpOption)
}

attendanceCaptureCreate(body: any){
  return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-capture-create/`, body,this.httpOption)

}

attendanceCaptureDelete(body: any,id:number){
  return this.http.patch(`${this.API_PATH}attendance/api/v1/attendance-capture-delete/${id}/`, body,this.httpOption)

}

attendanceCapturegetDetail(id:any){
  return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-capture-detail/${id}/`,this.httpOption)
}

attendanceCaptureUpdate(body: any,id:number){
  return this.http.put(`${this.API_PATH}attendance/api/v1/attendance-capture-detail/${id}/`, body, this.httpOption)
}

applicableList(type:string){
  if(type == "BU"){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  } else if(type == "Department")
    return  this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  else if(type == "Designation")
    return  this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  else if(type == "Grade")
    return  this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  else if(type == "Company")
    return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
  else if(type == "Employee")
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list-all/?is_active=true&ordering=user__first_name&employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}`, this.httpOption);
  else
  return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list-all/?is_active=true&ordering=user__first_name`, this.httpOption);
}

attendenceCaptureDropdownList(is_active:any){
  return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-capture-list/?is_active=${is_active}&ordering=name`,this.httpOption)
}


}
