import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { SearchService } from 'src/app/@core/services/search.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    constructor(private _location: Location,private searchService: SearchService,public router: Router) {
        
     }
    goBack() {
        this._location.back();
    }
    filled = false;
    showSidePanel = false;
    res= []
    categories:any ={
        A:  [
            { key : 'Approval matrix',api_url:"", route_url:"",function_name:""},
            { key : 'Attendance Rule',api_url:"", route_url:"",function_name:""}
        ],
        B:  [
            { key :'BU',api_url:"", route_url:"",function_name:""},
            { key : 'Branch',api_url:"", route_url:"",function_name:""}
        ],
        C: [
            { key :'Challan',api_url:"", route_url:"",function_name:""},
            { key : 'City',api_url:"", route_url:"",function_name:""},
            { 
                key : 'Company',
                route_url:"/org_structure/company", 
                api_url:"organization/api/v1/company-list/",
                function_name:() => this.prepareCompanyData()
            },
            { key : 'Core',api_url:"", route_url:"",function_name:""}
        ],
        D:  [
            { key :'Data access',api_url:"", route_url:"",function_name:""},
            { key : 'Department',api_url:"", route_url:"",function_name:""},
            { key : 'Designation',api_url:"", route_url:"",function_name:""},
            { key : 'Documents',api_url:"", route_url:"",function_name:""}
        ],
        E: [
            { key :'Employee',api_url:"", route_url:"",function_name:""},
            { key : 'Error',api_url:"", route_url:"",function_name:""}
        ],
        F: [
            { key :'Form 16',api_url:"", route_url:"",function_name:""}
        ],
        G:  [
            { key :'General configuration',api_url:"", route_url:"",function_name:""},
            { key : 'Grade',api_url:"", route_url:"",function_name:""}
        ],
        H:  [
            { key :'HR',api_url:"", route_url:"",function_name:""},
            { key : 'Holiday Calendar',api_url:"", route_url:"",function_name:""},
            { key : 'Holiday Policy',api_url:"", route_url:"",function_name:""},
            { key : 'Holiday Rule',api_url:"", route_url:"",function_name:""},
            { key : 'Holiday Type',api_url:"", route_url:"",function_name:""},
            { key : 'Home',api_url:"", route_url:"",function_name:""}
        ],
        I: [
            { key :'Inbox',api_url:"", route_url:"",function_name:""}
        ],
        L: [
            { key :'Leave Balance',api_url:"", route_url:"",function_name:""},
            { key : 'Leave Policy',api_url:"", route_url:"",function_name:""},
            { key : 'Leave Rule',api_url:"", route_url:"",function_name:""},
            { key : 'Leave Type',api_url:"", route_url:"",function_name:""}
        ],
        M: [
            { key :'My Attendance',api_url:"", route_url:"",function_name:""},
            { key : 'My Finances',api_url:"", route_url:"",function_name:""}
        ],
        O: [
            { key :'Organization structure',api_url:"", route_url:"",function_name:""}
        ],
        P:  [
            { key :'Page access',api_url:"", route_url:"",function_name:""},
            { key : 'Pay component',api_url:"", route_url:"",function_name:""},
            { key : 'Pay group',api_url:"", route_url:"",function_name:""},
            { key : 'Payroll',api_url:"", route_url:"",function_name:""},
            { key : 'Payslip',api_url:"", route_url:"",function_name:""},
            { key : 'Profile',api_url:"", route_url:"",function_name:""}
        ],
        R: [
            { key :'Reports',api_url:"", route_url:"",function_name:""},
            { key : 'Request',api_url:"", route_url:"",function_name:""},
            { key : 'Roles',api_url:"", route_url:"",function_name:""}
        ],
        S:  [
            { key :'Salary Slip',api_url:"", route_url:"",function_name:""},
            { key : 'Salary dashboard',api_url:"", route_url:"",function_name:""},
            { key : 'Salary structure',api_url:"", route_url:"",function_name:""},
            { key : 'Scheduled Reports',api_url:"", route_url:"",function_name:""},
            { key : 'Setting',api_url:"", route_url:"",function_name:""},
            { key : 'Settings',api_url:"", route_url:"",function_name:""},
            { key : 'Shift',api_url:"", route_url:"",function_name:""},
            { key : 'Shift Mapping',api_url:"", route_url:"",function_name:""},
            { key : 'Statuary',api_url:"", route_url:"",function_name:""}
        ],
        T:  [
            { key :'Task owner',api_url:"", route_url:"",function_name:""},
            { key : 'Tax Slip',api_url:"", route_url:"",function_name:""},
            { key : 'Team',api_url:"", route_url:"",function_name:""},
            { key : 'Templates',api_url:"", route_url:"",function_name:""},
            { key : 'Time & Attendance',api_url:"", route_url:"",function_name:""}
            ]
        }
    recentList:any = []
    searchCategory:any = [];
    showAlert = false;
    results:any = []
    searchString = ""
    ngOnInit(): void {
        this.getRecentSearch();
    }

    getCategoryList(){
        this.searchService.getCategoryList().subscribe((res: any) => {
        res.body.data.forEach((element: any) => {
            if(this.categories[element.charAt(0)] == undefined)
            this.categories[element.charAt(0)] = []
            this.categories[element.charAt(0)].push(element)
        });
        });
    }
    getCatArray(key:any){
        return this.categories[key]
    }
    getRecentSearch(){
        this.searchService.getRecentSearch().subscribe((res: any) => {
            this.recentList = res.body.data
        })
    }
    saveRecentSearch(){
        this.searchService.saveRecentSearch(this.searchCategory.key,this.searchString).subscribe((res: any) => {
            //console.log(res)
        })
    }
    setCategory(cat:any){
        this.searchCategory = cat;
        if(this.searchString != '')
           this.searchData(); 
    }    
    searchData(){
        if(this.searchCategory.length <= 0 )
            this.showAlert = true;
        else { 
            this.saveRecentSearch();           
            this.searchService.getSearchResult(this.searchCategory.api_url,this.searchString).subscribe((res: any) => {
                this.prepareResult(res.body)                
            })
        }
    }
    prepareResult(res:any){
        this.res = res
        this.searchCategory.function_name();
    }
    prepareCompanyData(){
        this.showAlert = false;
        this.results = []
        this.res.forEach((element: any) => {
            this.results.push({
                id:element.id,
                name:element.company_name,
                text:element.industry.name,
                image:element.logo
            })
        });
    }
    goToView(id:any){
        this.router.navigate([this.searchCategory.route_url, { view: id }]);
    }
  


}
