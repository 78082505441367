import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordService } from '../../services/forgot-password.service'
import { FormBuilder,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { OrganizationService } from '../../services/organization.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public username: string = '';
  submitted:boolean       = false;
  inValidInput            = false;
  errorMsg:any            = "";
  currentlogodata:any     = "";
  loader                  = true;


  forgotForm = this.formBuilder.group
              ({
                username:[null,Validators.required]
              })

  constructor(
    private forgotpasswordService: ForgotPasswordService,
    public router: Router,private formBuilder:FormBuilder,
    public messageService : MessageService,
    public appservice:AppService,
    public OrganizationService: OrganizationService,
    private authService: AuthService

  ) { }

  ngOnInit(): void {
    this.getorgdata();

  }
  get f(){
    return this.forgotForm.controls;
  }
  forgotPasswordClick() {
    this.submitted    = true;
    this.inValidInput = false;
    if(this.forgotForm.invalid)
    return
    this.forgotpasswordService.forgotPassword(this.forgotForm.value).subscribe((res: any) => {
        localStorage.setItem('user_id', res.user_id);
        localStorage.setItem('email', res.email);
        this.router.navigate(['otp/']);
    },
    (error: any) => {

      this.inValidInput = true;
      this.errorMsg     = error?.error?.username_error==1?this.messageService.wrongLoginData('wrong_code_email'):'This employee is in inactive state. Please check with HR.';

    }
    )
  }
  getorgdata(){

    this.OrganizationService.getloginpagedata()
    .subscribe((res: any) => {
      this.currentlogodata = res.success.logo;
      this.loader          = false;

    },
    (error:any)=>{
      this.loader= false;

    })
  }
  logout(){
    this.appservice.resetPermissions()
    this.authService.doLogout()
  }
}
