import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';

@Component({
  selector: 'app-fnf-short-leave-approval',
  templateUrl: './fnf-short-leave-approval.component.html',
  styleUrls: ['./fnf-short-leave-approval.component.scss']
})

export class FnfShortLeaveApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() requestitem:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  colorScheme:any = {
    domain: ['#28A745','#FFC107','#DC3545']
  };

  timeOffTrend                    = false;
  multi: any                      = [];
  nullStatus                      = false;
  loader                          = false

  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  yearList: any                   = []
  currentYear                     = new Date().getFullYear()
  selectedYear: any;
  leave_type: any;
  formBuilder: any;
  barchart: any                   = [];
  barChartXAxisLabels:any         = [];
  barChartYAxisLabel:any;
  barchartLoader                  = false;
  warningMsg: any                 = '';
  loader1                         = true;

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  constructor(
    public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,
    public appService: AppService,
    private investmentService:InvestmentDeclerationService,
    public appservice:AppService,
  ) { }

  ngOnInit(): void {
  }

  // test start

  ngOnChanges(){
    this.loader1     = true;
      setTimeout(() => {
        this.loader1 = false;
      }, 2000);
    }

  form = this.fb.group({
    read_status     : [true,Validators.required],
    star            : [false,Validators.required],
    approved_status : [false,Validators.required],
    comment         : ['', Validators.maxLength(500)],
  })

  trendsForm = this.fb.group({
    leave_type      : [''],
  })

  get f() {return  this.form.controls; }

  trendFunction() {

    this.yearListCreate();
    this.selectCurrentYear();
    this.getTrendData();
  }

  yearListCreate() {
    this.yearList = [];
    var i: number;
    for (i = this.currentYear; i >= 2020; i--) {
      this.yearList.push(String(i))
    }
  }

  selectCurrentYear() {
    this.selectedYear = this.currentYear
    this.yearChange(this.selectedYear)
  }

  getTrendData(){
    this.barchartLoader = true;
    this.investmentService.shortLeaveTrend({'employee' : this.requestitem?.from_person?.id,'year' : Number(this.selectedYear)}).subscribe((res: any) => {
      this.multi      = res?.data;
      if(this.multi?.length > 0)
       this.barChartDataConstruction(this.multi)
      this.nullStatus = res?.null_status;
    });
  }

  yearChange(val: any) {
    this.selectedYear = val;
    this.getTrendData();
  }

  barChartDataConstruction(data:any){

    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    let cancelled:any        = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Hours';
    this.barchart            = [];

    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Accepted"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else if(item1?.name == "Cancelled"){
            cancelled.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });

    this.barchart.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 },{label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }

  viewDocument(filename: any) {
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.investalertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  confirm(status:any){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }
      this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
        if(res){
          this.refreshInbox(false);
          this.form.get('comment')?.reset('')

        }
      })
    }
  }

  refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

   // Modal Close
   close(bool:any){
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool:any){
    this.alertToggle = bool;
  }

  // test end

}
