import { Component, OnInit,Input ,ChangeDetectorRef,Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-prior-payroll-summary',
  templateUrl: './prior-payroll-summary.component.html',
  styleUrls: ['./prior-payroll-summary.component.scss']
})
export class PriorPayrollSummaryComponent implements OnInit {
  activeTab2          = 1;
  page                = 1;
  pageSize            = 20;
  searchString:any    = "";
  filter              = false;
  companyList:any     = [];
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  nodataFound         = false;
  loader              = false;
  landingLoader       = true;
  saveClicked         = false;
  isClosed            = false;
  saveConfirmed       = false;
  partialSave         = false;

  month:any;

  year:any;
  company:any;

  @Input() summaryData: any;
  @Input() uploadedMonth:any;
  @Input() pendingData:any;
  @Output() priorSave = new EventEmitter();

  consolidatedData:any;


  constructor(
    public appService: AppService,
    public messageService : MessageService,
    public salaryService: SalaryDashboardService,
    public route:ActivatedRoute,
    private _cdr: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.getCompanyDropdown();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);

      }
      this.getSummary();
    })

  }
  getCompanyDropdown(){
    this.salaryService.companyDropDownData()
    .subscribe((res: any) => {
      if (res.status == 200) {
        if(res.body.length == 0){
          this.nodataFound = true;
          this.loader      = false;
          this._cdr.detectChanges();
        } else {
          this.companyList = res.body;
          if(this.company==''){
            this.company =  this.company==''?res.body[0]['id']:this.company;

          }
        }
      }
    })
  }
  getSummary(){
    this.landingLoader = true;
    this.salaryService.getPriorSummary(this.company,this.year,'',this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{
      if(res.body.length != 0)
      this.summaryData      = res?.body?.data[0];
      this.landingLoader    = false
    })

  }
  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList       = []
    this.getSummary()
    this.filter           = false
  }
  submitForm(data:any){
    this.filter           = false
    this.filter           = false
    this.buList          = data.buList!=""?data.buList.join(','):[]
    this.DepList         = data.DepList!=""?data.DepList.join(','):[]
    this.DesignationList = data.DesignationList!=""?data.DesignationList.join(','):[]
    this.gradeList       = data.gradeList!=""?data.gradeList.join(','):[]
    this.BranchList      = data.BranchList!=""?data.BranchList.join(','):[]
    this.getSummary()
  }
  closeFilter(){
    this.filter  = false
  }
  saveData(){
    if(this.pendingData.length==0){
      this.saveClicked = true;
      this.priorSave.emit();
    }
    else
    this.saveConfirmed = true
  }
  savePriorPayroll(){
    this.partialSave  = true;
    this.priorSave.emit();
  }
}
