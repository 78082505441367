import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {  AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PageAccessService } from 'src/app/@core/services/page-access.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';

@Component({
  selector: 'app-page-access-setup',
  templateUrl: './page-access-setup.component.html',
  styleUrls: ['./page-access-setup.component.scss']
})
export class PageAccessSetupComponent implements OnInit {
  activeTab             = 1;
  submitted             = false;
  employeeDropdown:any  = [];
  orderFormArray:any    = [];
  response:any          = [];
  id: number            = 0;
  Action:any            = "Add";
  disabled              = false;
  loader                = false;
  defaultArr  : any          = [];
  newFormGroup !: FormArray
  subMenu:any;
  dataWithChild: any = []

  editflag : boolean = false;

  isEmpDropdown = false;

  myForm: FormGroup = this.fb.group({
    id: 0,
    configure: this.fb.group({
      name:  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      employee: ['', [Validators.required]]
    }),
    visibleorder:this.fb.group({
      orders: this.fb.array([],[Validators.required]),
      menuarray: ['', [Validators.required]],
      detailedArr : ['']
    }),
    employeeassignright: this.fb.group({
        assignrights:this.fb.array([],[Validators.required])
      })
  });
  // unamePattern = "^(?! )+[A-Za-z0-9 -._@/#&+]*(?<! )$";

    constructor(public fb:FormBuilder,
    private pas:PageAccessService,
    public router: Router,
    public route:ActivatedRoute,
    private cd : ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.loader = true;
    this.orderFormArray = [];
      this.pas.getempList(true)
      .subscribe((res: any) => {
        if(res.status == 200) {
          if (res.body.length > 0) {
            // for(let i=0;i<res.body.length;i++){
            //   res.body[i]['name'] = res.body[i]['first_name']+' '+res.body[i]['last_name']+' ('+res.body[i]['employee_code']+') ';
            // }
            for(let i=0;i<res.body.length;i++){
              res.body[i]['name']  = "";
              if(res.body[i]['first_name'] != null)
                res.body[i]['name'] += res.body[i]['first_name']+" ";
              if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
                res.body[i]['name'] += res.body[i]['middle_name']+" ";
              if(res.body[i]['last_name'] != null)
                res.body[i]['name'] += res.body[i]['last_name'];
                res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";

            }
            this.employeeDropdown = res.body;
            // this.loader = false;
          }
          // this.loader = false;
          this.isEmpDropdown= true;
        }
      })
      this.pas.getSubmenuList().subscribe((res:any)=>{
        this.subMenu = res?.body?.page_access
        if(this.subMenu != undefined){
          this.route.params.subscribe((params: Params) => {
            this.Action = "Add";
            if( !isNaN(params['id'])){
              this.Action = "Edit";
              this.editflag = true
              this.id = +params['id'];
              this.editForm();
            }else{
              this.loader = false;
            }
          })
        }
      })

      this.defaultMenus()
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].configure.invalid) {
        changeEvent.preventDefault();
      }
    }
    if (changeEvent.nextId === 3) {
      if (this.myForm['controls'].visibleorder.invalid || this.myForm['controls'].configure.invalid) {
        changeEvent.preventDefault();
      }
      this.visibleorderForm.get('detailedArr')?.value.sort( (a:any,b:any) => a.id - b.id );
      this.setupFormThree()
    }
  }

  groupSubmit(){
    if(this.configureForm.value.name != '' && this.configureForm.value.employee != ''){
      this.submitted = true;
      this.activeTab = 2;
    }else{
      this.submitted = false;
    }
  }
  submitTwo(){
    //  Setting up form for Step 3
    this.setupFormThree()
    this.activeTab = 3;
  }

  setupFormThree(){
    let assignRightArr : any =[]
    let detailedArr : any =[]

    if(this.assignright().getRawValue().length !=0){
      for(let i=0;i<this.assignright().getRawValue()?.length;i++){
        assignRightArr.push(this.assignright().getRawValue()[i][0]?.name)
        // assignRightArr.sort()
      }
    }

    this.visibleorderForm.controls['detailedArr'].value.forEach((item:any,index:any)=>{
      detailedArr.push(item?.name)
      this.newFormGroup = this.fb.array([])
      const existingFormGroup = assignRightArr?.length != 0 ?  this.areAllItemsSame(assignRightArr,item?.name) : false
      if(!existingFormGroup){
        this.newFormGroup.push(this.fb.group({
          'menu' : [item?.id],
          'level' : [item?.level],
          'name' : [item?.name],
          'add' : true,
          'edit' : true,
          'delete' : true,
          'view' : true,
          'lvlOneChildren' : this.fb.array([])
        }))
        this.assignright().push(this.newFormGroup)
      }
    })
    if(assignRightArr.length > this.visibleorderForm.controls['detailedArr'].value.length){
      for(let i=0;i<this.assignright().getRawValue()?.length;i++){
        if(detailedArr.indexOf(this.assignright().getRawValue()[i][0]?.name) == -1 ){
          console.log(1)
          this.assignright().removeAt(i)
        }
      }
    }
    // Calls the api
    this.setupFormControls()
  }

  areAllItemsSame(array:any, targetString :any) {
    for (let key of array) {
        if (key === targetString) {
            return true;
        }
    }
    return false;
  }


  areAllItemsSameControl(array:any, targetString :any) {
    for (let key of array) {
        if (key['menu'] === targetString) {
            return true;
        }
    }
    return false;
  }


  grandchildren : number = 0
  dataItem : any = []
  num : number = 0
  sectionArr : any = []
  setupFormControls(){
    this.dataWithChild = []
    if(this.subMenu?.length !=0){
      for(let i=0;i<this.assignright().getRawValue()?.length;i++){
        const index = this.subMenu.findIndex((item2:any) => this.objectsAreEqual(this.assignright()?.getRawValue()[i][0], item2));
        if (index !== -1) {
          this.dataWithChild.push(this.subMenu[index])
        }
      }
      if(this.dataWithChild?.length !=0){
        for(let i =0;i<this.dataWithChild?.length;i++){
          if(this.dataWithChild[i]?.children?.length != 0){
            for(let j=0;j < this.dataWithChild[i]?.children?.length;j++){
              this.grandchildren++
              if(this.grandchildren == 1 && this.id == undefined){
                this.dataItem.push(this.num++)
              }
              const existingFormArrOne = this.oneLevelChild(i).getRawValue()?.length!=0 ? this.areAllItemsSameControl(this.oneLevelChild(i).getRawValue(),this.dataWithChild[i]?.children[j]?.menu) : false
              if(!existingFormArrOne){
                this.oneLevelChild(i).push(
                  this.fb.group({
                    'view': false,
                    'add': false,
                    'edit': false,
                    'delete': [this.dataWithChild[i]?.children[j]?.d== null ? null : false],
                    'menu': [this.dataWithChild[i]?.children[j]?.menu],
                    'name': [this.dataWithChild[i]?.children[j]?.name],
                    'lvlTwoChildren' : this.fb.array([])
                  })
                )
                if(this.dataWithChild[i]?.children[j].children?.length != 0){
                  this.dataItem.push(this.num)
                  for(let k=0;k<this.dataWithChild[i]?.children[j].children?.length;k++){
                    this.twoLevelChild(i,j).push(
                      this.fb.group({
                        'view': false,
                        'add': false,
                        'edit': false,
                        'delete': [this.dataWithChild[i]?.children[j].children[k]?.d== null ? null : false],
                        'menu': [this.dataWithChild[i]?.children[j].children[k]?.menu],
                        'name': [this.dataWithChild[i]?.children[j].children[k]?.name],
                        'lvlThreeChildren' : this.fb.array([])
                    }))
                    let data = this.dataWithChild[i]?.children[j].children[k].children
                    if(data?.length !=0){
                      for(let l=0;l< data?.length;l++){
                        this.thirdLevelChild(i,j,k).push(
                          this.fb.group({
                            'view': false,
                            'add': false,
                            'edit': false,
                            'delete': [data[l]?.d== null ? null : false],
                            'menu': [data[l]?.menu],
                            'name': [data[l]?.name],
                            'lvlFourChildren' : this.fb.array([])
                          })
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  objectsAreEqual(obj1: any, obj2: any): boolean {
    return obj1.menu === obj2.menu;
  }

  // FormArray for HOME,PROFILE......
  details(index:any){
    return this.assignright().controls[index] as FormArray
  }

  // FormArray of Children from Details()
  oneLevelChild(index:any) : FormArray {
    return this.details(index).controls[0]?.get('lvlOneChildren') as FormArray
  }

  getDetailsFormGroup(index: any): FormGroup {
    return this.details(index).controls[0] as FormGroup;
  }

  // FormArray for Children of 1st Child
  twoLevelChild(index:any,childIndex:any) {
    return this.oneLevelChild(index).controls[childIndex]?.get('lvlTwoChildren') as FormArray
  }

  // FormArray for children of 2nd Child
  thirdLevelChild(index:any,childIndex:any,grandChildIndex:any) {
    return this.twoLevelChild(index,childIndex).controls[grandChildIndex]?.get('lvlThreeChildren') as FormArray
  }


  get configureForm() {
    return this.myForm.get('configure') as FormGroup;
  }
  get visibleorderForm() {
    return this.myForm.get('visibleorder') as FormGroup;
  }
  get employeeassignrightForm(){
    return this.myForm.get('employeeassignright') as FormGroup;
  }
  editForm(){
    this.loader = true;
    this.pas.pageAccessSingleItem(this.id).subscribe((res: any) => {
      this.setEditForm(res.body);
      if(res.body.default == true){
        this.Action = "View";
        this.disabled = true
        // this.myForm.disable()
      }
      return
    });
  }

  apiValue : any = []
  setEditForm(res:any){
    let menulist:any = [];
    let orderlist:any = [];
    let assignrightsarray:any = [];
    this.apiValue = res.page_access
    for(let key of res.page_access){

      if((key.level == 1)){
        menulist.push(
          key.menu
       );
       assignrightsarray.push({
        "id" : key?.menu,
        "name": key?.name,
        "url": "",
        "level": key?.level,
        "order": "",
        "model_name": null,
        "is_active": true,
        "parent": null,
        "default": false
       })
      }else if(key.level != 1){
        menulist.push(
          key.menu
       );
      assignrightsarray.push({
        "id" : key?.menu,
        "name": key?.name,
        "url": "",
        "level": key?.level,
        "order": "",
        "model_name": null,
        "is_active": true,
        "parent": null,
        "default": false
       })
      }
    }
    // return
    this.pas.getmenuList(true)
      .subscribe((res: any) => {
        if(res.status == 200) {
          if (res.body.length > 0) {
            res.body.forEach((key:any, value:any) => {
              if(key.level == 1){
                  this.assignorder().push(this.newAssignOrder());
                  if(menulist.indexOf(key.id) != -1){
                    orderlist.push({'checkorders':true})
                  }
                  else{
                  orderlist.push({'checkorders':false})
                  }
              }
            });
          }
            // called for form setup which is taking in step 2
            this.setupFormThree()
            for(let h=0;h< this.assignright().getRawValue()?.length;h++){
              let formData = this.assignright().getRawValue()[h][0]
              for(let j=0;j<this.apiValue?.length;j++){
                if(formData?.menu == this.apiValue[j]?.menu){
                  // Home,HR.....
                  formData['add'] = this.apiValue[j]['add']
                  formData['view'] = this.apiValue[j]['view']
                  formData['edit'] = this.apiValue[j]['edit']
                  formData['delete'] = formData['delete'] == null ? null : this.apiValue[j]['delete']
                  if(this.apiValue[j]['children']?.length !=0){
                    let firstLvlForm = formData?.lvlOneChildren
                    let firstLvlAPI = this.apiValue[j]['children']
                    for(let k=0;k< firstLvlAPI?.length;k++){
                      for(let l=0;l< firstLvlForm?.length;l++){
                        // HR>Employee Management
                        if(firstLvlForm[l]['menu'] == firstLvlAPI[k]['menu']){
                          this.oneLevelChild(h).at(l).patchValue({
                            view : firstLvlAPI[k]['view'],
                            add: firstLvlAPI[k]['add'],
                            edit: firstLvlAPI[k]['edit'],
                            delete: firstLvlForm[l]['delete'] == null ? null : firstLvlAPI[k]['delete'],
                            menu: firstLvlAPI[k]['menu'],
                            name: firstLvlAPI[k]['name'],
                          })
                          if(firstLvlAPI[k]['children']?.length !=0){
                            let secLvlForm =  firstLvlForm[l]['lvlTwoChildren']
                            let secLvlAPI = firstLvlAPI[k]['children']
                            for(let m=0;m< secLvlAPI?.length;m++){
                              for(let n = 0;n<secLvlForm?.length;n++){
                                // Employee Management > Employee

                                if(secLvlForm[n]['menu'] == secLvlAPI[m]['menu'] ){
                                  this.twoLevelChild(h,l).at(n).patchValue({
                                    view : secLvlAPI[m]['view'],
                                    add: secLvlAPI[m]['add'],
                                    edit: secLvlAPI[m]['edit'],
                                    delete: secLvlForm[n]['delete'] == null ? null : secLvlAPI[m]['delete'],
                                    menu: secLvlAPI[m]['menu'],
                                    name: secLvlAPI[m]['name'],
                                  })
                                  if(secLvlAPI[m]['children']?.length !=0){
                                    let thirdLvlForm = secLvlForm[n]['lvlThreeChildren']
                                    let thirdLvlAPI = secLvlAPI[m]['children']
                                    for(let o=0;o< thirdLvlAPI?.length;o++){
                                      for(let p=0;p< thirdLvlForm?.length;p++){
                                        if(thirdLvlForm[p]['menu'] == thirdLvlAPI[o]['menu']){
                                          this.thirdLevelChild(h,l,n).at(p).patchValue({
                                            view : thirdLvlAPI[o]['view'],
                                            add: thirdLvlAPI[o]['add'],
                                            edit: thirdLvlAPI[o]['edit'],
                                            delete: thirdLvlForm[p]['delete'] == null ? null : thirdLvlAPI[o]['delete'],
                                            menu: thirdLvlAPI[o]['menu'],
                                            name: thirdLvlAPI[o]['name'],
                                          })
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
      // setTimeout(()=>{
        this.loader = false;
      // },2000)
      })

      this.myForm.reset({
        id: res.id,
        configure: {
          name:  res.name,
          employee: res.employees,
        },
        visibleorder:{
          orders: orderlist,
          menuarray:menulist,
          detailedArr :assignrightsarray
        },
        employeeassignright:this.fb.group({
          assignrights:this.assignright().getRawValue()
        })
      });


    this.cd.detectChanges()
  }

  assignright() : FormArray {
    return this.employeeassignrightForm.get("assignrights") as FormArray
  }


  newAssignRight(): FormGroup {
    return this.fb.group({
      menu:0,
      view: false,
      edit: false,
      delete: false,
      add: false,
    })
  }

  assignorder() : FormArray {
    return this.visibleorderForm.get("orders") as FormArray
  }
  newAssignOrder(): FormGroup {
    return this.fb.group({
      checkorders: ['', '']
    })
  }

  submitForm(){
    var dataRightsArray : any = [];
    for(let i = 0;i< this.assignright().controls.length;i++){
      dataRightsArray.push({
        "view": this.assignright().getRawValue()[i][0]?.view,
        "add": this.assignright().getRawValue()[i][0]?.add,
        "edit": this.assignright().getRawValue()[i][0]?.edit,
        "delete": this.assignright().getRawValue()[i][0]?.delete,
        "menu": this.assignright().getRawValue()[i][0]?.menu,
      })
      if(this.assignright().getRawValue()[i][0]['name'] == 'Profile' || this.assignright().getRawValue()[i][0]['name'] == 'HR' || this.assignright().getRawValue()[i][0]['name'] == 'Setting'){
        let data = this.assignright().getRawValue()[i][0]
        if(data?.lvlOneChildren?.length !=0){
          for(let j=0;j< data?.lvlOneChildren?.length;j++){
            if(data?.lvlOneChildren[j].view == true || data?.lvlOneChildren[j].add == true || data?.lvlOneChildren[j].edit == true || data?.lvlOneChildren[j].delete == true){
              dataRightsArray.push({
                "view": data?.lvlOneChildren[j]?.view,
                "add": data?.lvlOneChildren[j]?.add,
                "edit": data?.lvlOneChildren[j]?.edit,
                "delete": data?.lvlOneChildren[j]?.delete == null ? false : data?.lvlOneChildren[j]?.delete,
                "menu": data?.lvlOneChildren[j]?.menu,
              })
            

              if(data?.lvlOneChildren[j]?.lvlTwoChildren?.length !=0){
                let secData = data?.lvlOneChildren[j]?.lvlTwoChildren
                for(let k=0;k<secData?.length;k++){
                  if(secData[k].view == true || secData[k].add == true || secData[k].edit == true || secData[k].delete == true){
                    dataRightsArray.push({
                      "view": secData[k]?.view,
                      "add": secData[k]?.add,
                      "edit": secData[k]?.edit,
                      "delete": secData[k]?.delete == null ? false : secData[k]?.delete,
                      "menu": secData[k]?.menu,
                    })
                  }
                  if(secData[k]?.lvlThreeChildren?.length !=0){
                    let thirdData = secData[k]?.lvlThreeChildren
                    for(let l=0;l<thirdData?.length;l++){
                      if(thirdData[l].view == true || thirdData[l].add == true || thirdData[l].edit == true || thirdData[l].delete == true){
                        dataRightsArray.push({
                          "view": thirdData[l]?.view,
                          "add": thirdData[l]?.add,
                          "edit": thirdData[l]?.edit,
                          "delete": thirdData[l]?.delete == null ? false : thirdData[l]?.delete,
                          "menu": thirdData[l]?.menu,
                        })
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    var dataArray = {
      "name": this.configureForm.controls.name.value,
      "employees": this.configureForm.controls.employee.value,
      "page_access":dataRightsArray,
   };
    if(this.id == 0){
      this.pas.pageAccessCreate(dataArray).subscribe((res: any) => {
        if(res.status == 201) {
          this.router.navigate(['page-access']);
        }
    }, (error) => {

    });
    } else {
      this.pas.pageAccessUpdate(dataArray,this.id).subscribe((res: any) => {
        if(res.status == 200) {
          this.router.navigate(['page-access']);
        }
      }, (error) => {

      });
    }
  }

  defaultMenus(){
    this.pas.pageAccessDefaultItem()
    .subscribe((res: any) => {
      for(let key of res.body.page_access){
        this.defaultArr.push(key.name)
      }
     this.allMenuFunction()

     })

  }

  allMenuFunction(){
    this.pas.getmenuList(true)
      .subscribe((res: any) => {
        if(res.status == 200) {
          if (res.body.length > 0) {
            res.body.forEach((key:any, value:any) => {
              if(key.level == 1){
                if(this.defaultArr.find((element: any) => element == key.name) !== undefined){
                  key.default = true;
                }else{
                 key.default = false;
                }
                this.orderFormArray.push(key);
              }
            });
          }
        }
      });

  }
}


// for(let h=0;h< res?.page_access?.length;h++){
//   let mainForm = this.fb.group({
//     'menu': res?.page_access[h]?.menu,
//     'level': res?.page_access[h]?.level,
//     'name': res?.page_access[h]?.name,
//     'add': res?.page_access[h]?.add,
//     'edit': res?.page_access[h]?.edit,
//     'delete': res?.page_access[h]?.delete,
//     'view': res?.page_access[h]?.view,
//     'lvlOneChildren': this.fb.array([])
//   })
//   let newFormGroup = this.fb.array([])
//   newFormGroup.push(mainForm)
//   this.assignright().push(newFormGroup)
//   assignrightArr.push(this.newFormGroup)
//   let key = res?.page_access[h]
//   if(key?.children?.length !=0){
//     for(let i=0;i<key?.children?.length;i++){
//       this.oneLevelChild(h).push(
//         this.fb.group({
//           'view': key?.children[i]['view'],
//           'add': key?.children[i]['add'],
//           'edit': key?.children[i]['edit'],
//           'delete': [key?.children[i]['delete']],
//           'menu': [key?.children[i]['menu']],
//           'name': [key?.children[i]['name']],
//           'lvlTwoChildren' : this.fb.array([])
//         })
//       )
//       let childKey = key?.children[i]?.children
//       if(childKey?.length !=0){
//         for(let j =0;j< childKey?.length;j++){
//           this.twoLevelChild(h,i).push(
//             this.fb.group({
//               'view': childKey[j]['view'],
//               'add': childKey[j]['add'],
//               'edit': childKey[j]['edit'],
//               'delete': [childKey[j]['delete']],
//               'menu': [childKey[j]['menu']],
//               'name': [childKey[j]['name']],
//               'lvlThreeChildren' : this.fb.array([])
//             })
//           )
//           let grandChildKey = childKey[j]?.children
//             if(grandChildKey?.length !=0){
//               for(let k=0;k< grandChildKey?.length;k++){
//                 this.thirdLevelChild(h,i,j).push(
//                   this.fb.group({
//                     'view': grandChildKey[k]['view'],
//                     'add': grandChildKey[k]['add'],
//                     'edit': grandChildKey[k]['edit'],
//                     'delete': [grandChildKey[k]['delete']],
//                     'menu': [grandChildKey[k]['menu']],
//                     'name': [grandChildKey[k]['name']],
//                     'lvlFourChildren' : this.fb.array([])
//                   })
//                 )
//               }
//             }
//         }
//       }
//     }
//   }
// }
