import {  Component, OnInit } from '@angular/core';
import {  FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';

@Component({
  selector: 'app-approval-matrix-setup',
  templateUrl: './approval-matrix-setup.component.html',
  styleUrls: ['./approval-matrix-setup.component.scss'],
})
export class ApprovalMatrixSetupComponent implements OnInit {

  activeTab = 1;
  roleDropdown = [];
  moduleDropdown = [];
  workflowDropdown:any = [];
  res:any;
  id: number = 0;
  iseditForm=false;
  loader = false;
  level = 0;
  disabled = false;
  editflag: boolean = false
  heading = "Add"

  myForm: FormGroup = this.fb.group({
    id: 0,
    configure: this.fb.group({
      name:  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      description: ['', [Validators.required,Validators.maxLength(500)]],
      roles:this.fb.array([])
    }),
    allocate: this.fb.group({
      module: [null, [Validators.required]],
      workflow: [null, [Validators.required]],
      is_cancel : [false]
    }),
    assign: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })
  });
  constructor(
    public fb:FormBuilder,
    private ams:ApprovalMatrixService,
    public router: Router,
    public route:ActivatedRoute,
    ) {}

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
    this.ams.roleList().subscribe((res: any) => { this.roleDropdown = res; });
    this.ams.moduleList().subscribe((res: any) => { this.moduleDropdown = res; });
    // this.ams.workflowList().subscribe((res: any) => { this.workflowDropdown = res; });
  }
  submitOne(){
    this.activeTab = 2;
  }
  submitTwo(){
    this.activeTab = 3;
  }
  get configureForm() {
    return this.myForm.get('configure') as FormGroup;
  }
  get allocateForm() {
    return this.myForm.get('allocate') as FormGroup;
  }
  get assignForm() {
    return this.myForm.get('assign') as FormGroup;
  }
   roles() : FormArray {
    return this.configureForm.get("roles") as FormArray
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].configure.invalid) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 3) {
      if (this.myForm['controls'].allocate.invalid || this.myForm['controls'].configure.invalid) {
        changeEvent.preventDefault();
      }
    }
  }

  submitForm(){
    
    this.res = this.configureForm.controls.roles;
   
    var dataRoleArray = [];
    for(let key of this.res.controls){
      dataRoleArray.push({
        "level":key.value.level,
        "role":key.value.role,
        "intimation":key.value.intimation,
        "days":key.value.days,
        "due_days":key.value.due_days,
     });
    }
    this.res = this.allocateForm.controls.workflow.value;
    var module = this.allocateForm.controls.module.value;
    var is_cancel = this.allocateForm.controls.is_cancel.value;
    // console.log('hai',is_cancel)
    var dataAllocateArray = [];
    for(let key of this.res){
      dataAllocateArray.push({
        "module":module,
        "request":key,
     });
    }
    this.res = this.assignForm.controls.assignees.value;
    var applicable = this.assignForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.res){
      arr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }

    var dataArray = {
      "name": this.configureForm.controls.name.value,
      "description": this.configureForm.controls.description.value,
      "approval_matrix_role":dataRoleArray,
      "approval_matrix_allocate":dataAllocateArray,
      "approval_matrix_assignright":arr,
      "is_cancel" : is_cancel

   };

    if(this.id == 0){
      this.ams.saveApproval(dataArray).subscribe((res: any) => {
        this.router.navigate(['approval-matrix']);
      });
    } else {
      this.ams.updateApproval(dataArray,this.id).subscribe((res: any) => {
        this.router.navigate(['approval-matrix']);
      },
      (error) => {

      });
    }

  }
  editForm(){
    this.heading = "Edit"
    this.loader = true;
    this.editflag = true
    this.ams.getApproval(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res);
    },(error)=>{
      this.router.navigate(['approval-matrix']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }

    let roleArray = [];
    let request = [];
    let assignees = [];
    let module = 0;
    let applicableto = "";
    let appkey ="";
    

    this.roles().removeAt(0);
    for(let key of res.approval_matrix_role){
     this.addRoles();
      roleArray.push({
        "level":key.level,
        "role":key.role,
        "intimation":key.intimation,
        "days":key.days,
        "due_days":key.due_days
     });

    }
    for(let key of res.approval_matrix_allocate){
      
      request.push(key.request);
      module = key.module
      
    }

    for(let key of res.approval_matrix_assignright){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
      assignees.push(key[appkey]);
      applicableto = key.applicable
    }
    this.myForm.reset(
      {
        id: res.id,
        configure: {
          name:  res.name,
          description: res.description,
          roles:roleArray
        },
        allocate: {
          module: module,
          workflow: request,
          is_cancel:res.is_cancel
        },
        assign: {
          applicableto: applicableto,
          assignees: assignees
        }
    });
    this.changeModule();
    this.loader = false;

  }
  newRole(): FormGroup {
    this.level = this.roles().length+1;
    return this.fb.group({
      "days": [0, [Validators.required,NumberpatternValidator(),Validators.maxLength(3)]],
      "level":this.level,
      "intimation": true,
      "role": [null, [Validators.required]],
      "due_days": [0, [NumberpatternValidator(),Validators.maxLength(3)]]

    })
  }

  addRoles() {
    this.roles().push(this.newRole());
  }
  changeModule(){
    this.ams.workflowListperModule(this.allocateForm.controls.module.value, this.allocateForm.controls.is_cancel.value).subscribe((res: any) => {
      this.workflowDropdown = [];
      for(let key of res){
        key.id = key.request_type_id;
        this.workflowDropdown.push(key);
        if (this.workflowDropdown.length != 0) {
          this.selectAllForDropdownItems(this.workflowDropdown);
          }
      }
      });

  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
