<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Review</p>
        </div>
        <div class="row row-16">
            <div class="col-12">
                <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                    <div class="row row-16 ">
                        <div class="col-12 ">
                            <p class="title mb-8">ADVANCE POLICY </p>
                        </div>
                        <div class="col-md-4">
                            <div class="detail-title">Advance policy name</div>
                            <div class="detail-desc">{{formGp.value?.name}}</div>
                        </div>
                        <div class=" col-md-4">
                            <div class="detail-title">Advance amount limit for a request</div>
                            <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-400"></i>{{formGp.value?.specify_limit?'Specify limit':'No limit'}}</div>
                        </div>
                        <div class=" col-md-4" *ngIf="formGp.value?.specify_limit">
                            <div class="detail-title">Amount </div>
                            <div class="detail-desc">{{formGp.value?.amount}} INR </div>
                        </div>
                        <ng-container *ngIf="formGp.value?.is_credit_limit">
                        <div class="col-12" >
                            <div class="hstack align-items-center gap-8 fs-14">
                                <i class="icon-check-square-fill text-light-400"></i>Set outstanding advance credit limit for the employees
                            </div>
                        </div>
                        <div class=" col-12">
                            <div class="detail-title">Specify limit </div>
                            <div class="detail-desc">{{formGp.value?.credit_limit}} INR </div>
                        </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                    <div class="row row-16 ">
                        <div class="col-12 "><span class="title">APPLICABILITY</span></div>
                        <div class="col-12">
                            <div class="detail-title">Applicable to</div>
                            <div class="detail-desc">{{applicableform?.applicable}}</div>
                        </div>
                        <div class="col-12">
                            <div class="detail-title">{{applicableform?.applicable}}(s) </div>
                              <div class="detail-desc">
                                <div class="selected-values mt-1" >
                                 <ng-container *ngIf="applicableform?.applicable == 'Employee'">
                                  <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                    <div class="ng-value-label" title="{{rights['first_name']+rights['middle_name']+rights['last_name'] + ' (' + rights['employee_code'] +' )'}}">
                                      <span class="d-inline-flex align-items-center">
                                        <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}" *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                                        <span class="avatar sq-24 avatar-circle {{rights.color_code}}" *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appService.getFirstChar(rights.first_name,2)}}</span>
                                          <span> {{rights['first_name']+(rights['middle_name']!=null && rights['middle_name']!=""?" " +rights['middle_name']:"")+" "+rights['last_name'] +" ("+rights['employee_code']+")"}}</span>
                                       </span>
                                    </div>
                                  </li>
                                 </ng-container>
                                 <ng-container *ngIf="applicableform?.applicable == 'Company'">
                                  <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                                    <div class="ng-value-label" title="{{rights.company_name}}">
                                      <span class="d-inline-flex align-items-center">
                                        <img class="img-fluid rounded-circle sq-24"  src="{{rights.logo}}" *ngIf="rights.logo !=  '' && rights.logo !=  null">
                                        <span class="avatar sq-24 avatar-circle {{rights.color_code}}"  *ngIf="rights.logo ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                          <span> {{rights.company_name}}</span>
                                       </span>
                                    </div>
                                  </li>
                                 </ng-container>
                                 <ng-container *ngIf="(applicableform?.applicable != 'Employee') && (applicableform?.applicable != 'Company')">
                                        <div class="d-flex flex-wrap mt-8 gap-8" >
                                          <ng-container *ngFor="let rights of assignees, let i=index;">
                                            <span class="badge3"  title="{{rights.name}}"> {{ (rights.length>25)? (rights.name | slice:0:25)+'..':(rights.name)  }}
                                            </span>
                                          </ng-container>

                                        </div>
                                 </ng-container>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveBtnClicked" (click)="validatefn()">Save</button>
    </footer>
</form>