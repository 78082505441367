import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {FormBuilder,Validators } from '@angular/forms';
import * as moment from 'moment';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { maxTenDigitDecimal } from 'src/app/@shared/validators/maxTenDigitDecimal.validators';
import { singleDigitDecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-leave-type-details',
  templateUrl: './leave-type-details.component.html',
  styleUrls: ['./leave-type-details.component.scss']
})
export class LeaveTypeDetailsComponent implements OnInit {

  constructor(public messageService : MessageService,public appService: AppService,public existDataService : ExistDatasService,public fb : FormBuilder,public leavetypeService:leavetypeService) { }
  @Input() leaveConfigForm: any;
  @Input() leaveTypeCode: any;
  @Input() leaveTypeName: any;
  @Input() leaveForm : any;
  @Input() mapLeaveType:any
  submitted = false;
  @Input() saveBtnClicked: any;
  @Input() activeIndex:any;


ngOnInit(): void {
  this.addConditionalValidator();
}


addConditionalValidator(){
  this.setValidators('negative_balance_days','negative_balance_allowed',true);
  this.setValidators('max_encash_limit','max_encash_limit_status',true);
  this.setValidators('weekoffleave_choice','do_you_want_weekoffleave',false);
  this.setValidators('holidayleave_choice','do_you_want_holidayleave',false);
 
}

get f() { return this.leaveForm.controls; }

setValidators(control:any,parent:any,needPattern:any){

  for(let i=0;i<this.leaveForm.controls.length;i++){

    if(this.leaveForm.controls[i].get(parent).value==true){
      if(needPattern==true && control!='negative_balance_days' && control != 'max_encash_limit')
      this.leaveForm.controls[i].get(control)?.setValidators([Validators.min(0.1),Validators.required,maxTenDigitDecimal(),digitdecimalValidator()])
      else if(control=='negative_balance_days' || control == 'max_encash_limit')
      this.leaveForm.controls[i].get(control)?.setValidators([,Validators.required,singleDigitDecimalValidator(),maxTenDigitDecimal(),Validators.min(0.5)])
      else
      this.leaveForm.controls[i].get(control)?.setValidators([Validators.required])

    }
    else{
      this.leaveForm.controls[i].get(control).clearValidators();
      this.leaveForm.controls[i].get(control).setValue('');
    }
    this.leaveForm.controls[i].get(control)?.updateValueAndValidity()

  }


}
encashableValidator(index:any,control:any){
 if(this.leaveForm.controls[index].get(control).value==false){
  this.leaveForm.controls[index].get('max_encash_limit').clearValidators();
  this.leaveForm.controls[index].get('max_encash_limit').setValue('');
  this.leaveForm.controls[index].get('max_encash_limit_status').setValue(false);
  this.leaveForm.controls[index].get('max_encash_limit')?.updateValueAndValidity()

 }
}


}


