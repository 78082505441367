<div class="side-panel" style="--sidepanel-width:45rem; " [class.side-pane-active]='showFinalSettlementView === true'>
  <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Details</h5>
          <a class="toggle-panel" (click)="closepopup()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body " style="overflow-y: auto;">
          <div class="container-fluid px-32 py-24 ">
            <app-view-loader *ngIf="loader"></app-view-loader>
              <ng-container *ngIf="!loader">
                <div class="row row-16">
                    <div class="col-12 d-flex">
                        <p class="text-uppercase fw-500 mb-0">FINAL SETTLEMENT DETAILS</p>
                        <a *ngIf="permissions?.e && currentStatus!='Pending'" class="ms-auto link-primary fs-14 d-flex align-items-center"
                            (click)="editData()"><i class="icon-pencil-square me-1"></i> Edit</a>
                    </div>
                    <ng-container *ngIf="completeData?.request_raised_by.length ">
                      <div class="col-12">
                        <div class="card card-c2 px-24 py-16">
                            <div class="row row-16">
                                <div class="col-12 d-flex align-items-start">
                                    <div class="title">RAISED BY</div>
                                    <div class="d-flex align-items-center gap-8 ms-auto">
                                        <span class="badge px-16 ms-auto fs-14 {{completeData?.request_raised_by[0].emp_req_status=='Pending'?'badge-warning':(completeData?.request_raised_by[0].emp_req_status=='Completed' || completeData?.request_raised_by[0].emp_req_status=='Accepted')?'badge-success':'badge-danger'}} py-1">{{completeData?.request_raised_by[0].emp_req_status}}</span>
                                        <span class="text-light-400">|</span>
                                        <span class="link-primary fs-14" (click)="requestViewStatus(completeData?.emp_req_id)">View Status</span>
                                    </div>
                                </div>

                                <div class=" col-12 d-flex align-items-start">
                                    <!-- <div class="avatar-circle sq-42 bg1 avatar-border" >AR</div> -->
                                     <img class="avatar-circle sq-42 avatar-border2"  *ngIf="completeData?.request_raised_by[0].profile_image !=  '' && completeData?.request_raised_by[0].profile_image != null" src="{{completeData?.request_raised_by[0].profile_image}}">
                                        <span *ngIf="completeData?.request_raised_by[0].profile_image ==  ''|| completeData?.request_raised_by[0].profile_image == null" class="avatar-sm sq-32 me-8 rounded-circle {{completeData?.request_raised_by[0].color_code}}" >
                                          <span>
                                            {{this.appservice.getFirstChar(completeData?.request_raised_by[0].employee_name,2)}}
                                          </span>
                                        </span>

                                    <div class="overflow-hidden  d-flex flex-column me-16">
                                        <p class="fs-14 fw-600 ms-16 mb-0">{{completeData?.request_raised_by[0].employee_name}}</p>
                                        <p class="fs-12 fw-500 text-light-400 ms-16 mb-0 lh-base">{{completeData?.request_raised_by[0].designation}} | Skizzle
                                        </p>
                                    </div>
                                    <div class="ms-auto fs-10  text-light-400 text-nowrap">{{this.appservice.timeFormatDisplay(completeData?.request_raised_by[0]?.request_time)}} {{this.appservice.dateFormatDisplay(completeData?.request_raised_by[0]?.request_date)}}</div>
                                </div>
                            </div>

                        </div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                        <div class="card card-c2 px-24 py-16 indicater1 ">
                            <div class="row row-16">

                                <div class=" col-12 d-flex align-items-start">
                                    <img class="avatar-circle sq-46 avatar-border2"  *ngIf="completeData?.profile_image !=  '' && completeData?.profile_image != null" src="{{completeData?.profile_image}}">
                                        <span *ngIf="completeData?.profile_image ==  ''|| completeData?.profile_image == null" class="avatar-sm sq-32 me-8 rounded-circle {{completeData?.color_code}}" >
                                          <span>
                                            {{this.appservice.getFirstChar(completeData?.employee_name,2)}}
                                          </span>
                                        </span>
                                    <div class="overflow-hidden ms-16  d-flex flex-column">
                                        <h6 class="fs-14 fw-500  mb-0">{{completeData?.employee_name}}</h6>
                                        <p class="fs-10 fw-500 text-light-400 mb-0 text-uppercase">{{completeData?.designation}}
                                                              </p>
                                        <span class="fs-10 fw-500 text-light-400 text-uppercase">EMP CODE:
                                          {{completeData?.employee_code}}</span>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="(completeData?.payslip!=null && completeData?.payslip!='') || (completeData?.taxslip!=null && completeData?.taxslip!='')">
                                    <div class="card card-c2 px-10 py-1 bg29 border-0">
                                        <div class="d-flex align-items-center gap-8 ">
                                          <span *ngIf="completeData?.taxslip!=null && completeData?.taxslip!=''" class="link-primary1 fs-12 fw-500" (click)="viewSlip('tax')"><i class="icon-eye me-1"></i> View Tax Slip</span>
                                          <span *ngIf="(completeData?.payslip!=null && completeData?.payslip!='') && (completeData?.taxslip!=null && completeData?.taxslip!='')" class="text-light-400">|</span>
                                          <span *ngIf="completeData?.payslip!=null && completeData?.payslip!=''" class="link-primary1 fs-12 fw-500" (click)="viewSlip('pay')"><i class="icon-eye me-1"></i> View F&F Payslip</span>

                                          <span *ngIf="(completeData?.payslip!=null && completeData?.payslip!='') && (completeData?.taxslip!=null && completeData?.taxslip!='')" class="text-light-400">|</span>
                                          <a class="link-primary fs-12 d-flex align-items-center ms-auto" (click)="downLoad()">
                                            <i class="icon-download me-1"></i>Download F&F Payslip
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 details-card  px-24 py-16 ">
                            <div class="row row-24">
                                <div class="col-12">
                                    <span class="title">BASIC INFORMATION</span>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Date of joining</div>
                                    <div class="detail-desc">{{this.appservice.dateFormatDisplay(completeData?.date_of_joined)}}</div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Department</div>
                                    <div class="detail-desc">{{completeData?.department}}</div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Date of resignation</div>
                                    <div class="detail-desc">{{this.appservice.dateFormatDisplay(completeData?.resignation_date)}}</div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Date of relieving</div>
                                    <div class="detail-desc">{{this.appservice.dateFormatDisplay(completeData?.relieving_date)}}</div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Employment status</div>
                                    <div class="detail-desc">{{completeData?.employment_status}}</div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Employment type</div>
                                    <div class="detail-desc">{{completeData?.employment_type}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 details-card  px-24 py-16 ">
                            <div class="row row-24">


                                <div class="col-12">
                                    <span class="title">Settlement details</span>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Leaving date</div>
                                    <div class="detail-desc">{{this.appservice.dateFormatDisplay(completeData?.leaving_date)}}</div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Final settlement date</div>
                                    <div class="detail-desc">{{completeData?.final_settlement_date!=null?this.appservice.dateFormatDisplay(completeData?.final_settlement_date):'-'}}</div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Last salary processed </div>
                                    <div class="detail-desc">{{this.appservice.dateFormatDisplay(completeData?.last_salary_processed_on)}} </div>
                                </div>
                                <div class="  col-sm-6 col-12">
                                  <div class="detail-title">Salary hold since </div>
                                  <div class="detail-desc">{{completeData?.salary_hold_since!=null?completeData?.salary_hold_since:'-'}}</div>
                                </div>


                                <div class="  col-sm-6 col-12">
                                    <div class="detail-title">Salary hold amount </div>
                                    <div class="detail-desc">{{completeData?.salary_hold_amount!=null?completeData?.salary_hold_amount:'-'}} </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- **********************************Approve/Reject Code*************************************************** -->
        <form class="mt-20" *ngIf="from =='inbox' || from=='behalf'" [formGroup]="form">
            <ng-container *ngIf="(from!=='behalf' && !requestitem?.achieved_status) || (from=='behalf' && requestitem['error_message']==null && !requestitem?.achieved_status)">
            <div class="col-12">
                        <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..." class="form-control mb-8 textarea-comment" formControlName="comment"
             [ngClass]="{ 'is-invalid': af.comment.errors}"></textarea>
              <div *ngIf="af.comment.errors" class="invalid-feedback">
                <div *ngIf="af.comment.errors?.maxlength">Maximum 500 characters allowed.</div>
              </div>
            </div>
            <div class="col-12 d-flex gap-16">
              <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400" (click)="confirm('Rejected')" >Decline</button>
              <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400" (click)="confirm('Approved')">Confirm</button>
            </div>
          </ng-container>
          <ng-container *ngIf="from=='behalf' && requestitem['error_message']!=null">
            <div class="alert alert-danger show" role="alert">
            {{requestitem['error_message']}}
            </div>
          </ng-container>
        </form>
    <!-- *********************************************************************************************************  -->
                </div>
              </ng-container>
          </div>
      </div>
      <footer class="side-panel-footer gap-32 pt-16" *ngIf="from !='inbox' && from!='behalf' && loader==false && currentStatus=='Completed' " >
          <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="setEmail()" type="button">SEND PAYLSIP to
              EMPLOYEE</button>
      </footer>
  </div>
</div>

<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Activities</h5>
            <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <app-view-status [loader]="loader1" [approvalRequired]="approvalRequired" [viewStatusData]="viewStatusData" [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>
      </form>
  </div>
</ng-container>

<div class="modal  modal-alert {{payslipToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="payslipForm" autocomplete="off">

      <div class="modal-content">

          <div class="modal-body">
            <div class="row row-16">
              <div class="col-12 form-row">
                  <label for="name" class="form-label required">Personal ID</label>
                  <input type="email" formControlName="email" class="form-control" id="name" placeholder="{{this.messageService.placeholderdisp('Personal ID')}}" [ngClass]="{ 'is-invalid': ( f.email.touched || f.email.dirty) && (f.email.errors) || f.email.errors?.exist}" >
                  <div *ngIf="( f.email.touched || f.email.dirty) && (f.email.errors) || f.email.errors?.exist" class="invalid-feedback">
                    <div *ngIf="f.email.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.email.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                    </div>

                  </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="payslipToggle = false">Cancel</button>
              <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase" (click)="sendPayslip()" [disabled]="!payslipForm.valid || isSend ">Send settlement slip </button>

          </div>
      </div>
    </form>
  </div>
</div>

<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="pdfUrl"></app-view-file>
</ng-container>

