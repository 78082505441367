import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AppService } from 'src/app/app.global';
import { Subject } from 'rxjs';
import { delay,switchMap,catchError  } from 'rxjs/operators';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';

@Component({
  selector: 'app-sr-pay-component',
  templateUrl: './sr-pay-component.component.html',
  styleUrls: ['./sr-pay-component.component.scss']
})
export class SrPayComponentComponent implements OnInit {
  salaryRevisionHistory = false;
  constructor(public fb:FormBuilder,public messageService : MessageService,public appService:AppService,private SSS:SalaryStructureService) { }

  @Input() employeeData:any     = []
  @Input() payGroupDD:any       = [];
  @Input() detailsForm:any      = [];
  @Input() calculationData:any  = [];
  @Input() gross_benefits:any;
  @Input() other_benefits:any;
  @Input() contributions:any;
  @Input() stepperData:any;
  @Input() recurring_deductions:any;
  @Input() componentLoader:any;
  @Input() salaryForm:any;
  @Input() empId:any;
  @Input() variableComponent : any
  @Output() submitmethod        = new EventEmitter();
  @Input()formGp : FormGroup = this.fb.group({
      gross_amount    : '',
      pay_group       : '',
      ctc             : '',
      gross_month     : [],
      other_month     : [],
      recurring_month : [],
  });
  moduleDropdown:any=[  {id:'January',value:"Jan"},
    {id:'February',value:"Feb"},
    {id:'March',value:"Mar"},
    {id:'April',value:"Apr"},
    {id:'May',value:"May"},
    {id:'June',value:"Jun"},
    {id:'July',value:"Jul"},
    {id:'August',value:"Aug"},
    {id:'September',value:"Sep"},
    {id:'October',value:"Oct"},
    {id:'November',value:"Nov"},
    {id:'December',value:"Dec"}
  ]

  submitted             = false;
  isCompleted           = false;
  Isalert               = true;
  other_allowanceIndex  = 0;
  saveData:any;
  recalculatedData:any;
  isRecalculated        = false
  searchData : Subject<void> = new Subject();
  @Output() recalculateComponent       = new EventEmitter();


  ngOnInit(): void {
    this.addmonth();
    this.addOthermonth();
    this.addrecurringmonth();
    this.formGp.get('gross_amount')?.disable();
    this.searchData.pipe(delay(1000),
    switchMap(() =>  this.SSS.getFormula(this.saveData,this.empId).pipe(
      catchError(async (error) => {})
      ))).subscribe((res:any)=>{
        if(res){
          this.recalculateComponent.emit(res);
          this.isRecalculated = false
        }

    });
  }
  ngOnChanges(changes: SimpleChange) {
    setTimeout(() => {
    this.addmonth();
    this.addOthermonth();
    this.addrecurringmonth();
  }, 1000);
}

  month() : FormArray {
    return this.formGp.get("gross_month") as FormArray
  }
  otherMonth() : FormArray {
    return this.formGp.get("other_month") as FormArray
  }
  recurringMonth() : FormArray {
    return this.formGp.get("recurring_month") as FormArray
  }
  RemoveData(index:any,control:any,control_index:any,select_control:any){
    select_control.close();
    if(control=='gross_month'){
      let selected_data = this.month().controls[control_index].value.gross_month;
      selected_data.splice(index, 1);
      this.month().controls[control_index].get('gross_month')?.setValue(selected_data);
    }
    else if(control=='other_month'){
      let selected_data = this.otherMonth().controls[control_index].value.other_month;
      selected_data.splice(index, 1);
      this.otherMonth().controls[control_index].get('other_month')?.setValue(selected_data);
    }
    else{
      let selected_data = this.recurringMonth().controls[control_index].value.recurring_month;
      selected_data.splice(index, 1);
      this.recurringMonth().controls[control_index].get('recurring_month')?.setValue(selected_data);
    }
    this.CalculateMonthlyPay(control_index,control);


  }


  newmonth(): FormGroup {

    return this.fb.group({
     'comp_id'            : [null],
     'name'            : [null],
     'gross_month'        : [null],
     'monthly_renum'      : ['',digitdecimalValidator()],
     'year_renum'         : ['',digitdecimalValidator()]

    })
  }
  newOthermonth(): FormGroup {

    return this.fb.group({
     'comp_id'            : [null],
     'name'            : [null],
     'other_month'        : [null],
     'monthly_renum'      : ['',digitdecimalValidator()],
     'year_renum'         : ['',digitdecimalValidator()]

    })
  }
  newmRecurringonth(): FormGroup {
    return this.fb.group({
     'comp_id'            : [null],
     'name'            : [null],
     'recurring_month'    : [null],
     'monthly_renum'      : ['',digitdecimalValidator()],
     'year_renum'         : ['',digitdecimalValidator()]
    })
  }
  addmonth() {
    this.month().clear();
    if(this.gross_benefits!=null && this.gross_benefits!=undefined && this.gross_benefits!=''){

      for(let i=0;i<this.gross_benefits.length;i++){

        this.month().push(this.newmonth());
        this.month().controls[i].get('comp_id')?.setValue(this.gross_benefits[i].pay_component);
        this.month().controls[i].get('name')?.setValue(this.gross_benefits[i].name);
        this.month().controls[i].get('monthly_renum')?.setValue(this.appService.roundUp(this.gross_benefits[i].monthly_renum));
        this.month().controls[i].get('year_renum')?.setValue(this.appService.roundUp(this.gross_benefits[i].year_renum));
        if(this.gross_benefits[i].pay_month && this.gross_benefits[i].pay_month.length>0){
         this.month().controls[i].get('gross_month')?.setValue(this.gross_benefits[i].pay_month)

        }
        this.month().controls[i].get('monthly_renum')?.setValidators([digitdecimalValidator(),Validators.maxLength(10),Validators.required]);
        this.month().controls[i].get('year_renum')?.setValidators([digitdecimalValidator(),Validators.maxLength(10),Validators.required]);
      }
    }

  }
  addOthermonth() {
    this.otherMonth().clear();
    if(this.other_benefits!=null && this.other_benefits!=undefined && this.other_benefits!=''){

      for(let i=0;i<this.other_benefits.length;i++){

        this.otherMonth().push(this.newOthermonth());
        this.otherMonth().controls[i].get('comp_id')?.setValue(this.other_benefits[i].pay_component)
        this.otherMonth().controls[i].get('name')?.setValue(this.other_benefits[i].name)
        this.otherMonth().controls[i].get('monthly_renum')?.setValue(this.appService.roundUp(this.other_benefits[i].monthly_renum))
        this.otherMonth().controls[i].get('year_renum')?.setValue(this.appService.roundUp(this.other_benefits[i].year_renum))
        if(this.other_benefits[i].pay_month && this.other_benefits[i].pay_month.length>0){
          this.otherMonth().controls[i].get('other_month')?.setValue(this.other_benefits[i].pay_month);
        }
        this.otherMonth().controls[i].get('monthly_renum')?.setValidators([digitdecimalValidator(),Validators.maxLength(10),Validators.required]);
        this.otherMonth().controls[i].get('year_renum')?.setValidators([digitdecimalValidator(),Validators.maxLength(10),Validators.required]);

      }
    }

  }
  addrecurringmonth() {
    this.recurringMonth().clear();
    if(this.recurring_deductions!=null && this.recurring_deductions!=undefined && this.recurring_deductions!=''){

      for(let i=0;i<this.recurring_deductions.length;i++){

        this.recurringMonth().push(this.newmRecurringonth());
        this.recurringMonth().controls[i].get('comp_id')?.setValue(this.recurring_deductions[i].pay_component)
        this.recurringMonth().controls[i].get('name')?.setValue(this.recurring_deductions[i].name)
        this.recurringMonth().controls[i].get('monthly_renum')?.setValue(this.appService.roundUp(this.recurring_deductions[i].monthly_renum))
        this.recurringMonth().controls[i].get('year_renum')?.setValue(this.appService.roundUp(this.recurring_deductions[i].year_renum))
        if(this.recurring_deductions[i].pay_month.length>0){
          this.recurringMonth().controls[i].get('recurring_month')?.setValue(this.recurring_deductions[i].pay_month)

        }
        // if(this.recurring_deductions[i].nature==false)
        // this.recurringMonth().controls[i].get('recurring_month')?.setValidators([Validators.required]);
        this.recurringMonth().controls[i].get('monthly_renum')?.setValidators([digitdecimalValidator(),Validators.maxLength(10),Validators.required]);
        this.recurringMonth().controls[i].get('year_renum')?.setValidators([digitdecimalValidator(),Validators.maxLength(10),Validators.required]);


      }
    }

  }
  submit(){
    this.submitted = true;
    const invalid = [];
        const controls = this.formGp.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }

    if (invalid.length>0) {
      return;
    }
    this.isCompleted = true;

    this.submitmethod.emit();
  }
  CalculateMonthlyPay(index:any,control:any){

    var selectedMonth;
    var yearlyValue;
    var MonthlyValue :any   = 0;
    var calculatedData :any = 0;
    if(control=='gross_month'){

      selectedMonth = this.month().controls[index].get('gross_month')?.value;
      yearlyValue   = this.gross_benefits[index].year_renum;
      if(selectedMonth.length>0)
      MonthlyValue    = (Number(yearlyValue)/selectedMonth.length);
      calculatedData  = Number(MonthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.month().controls[index].get('monthly_renum')?.setValue(this.appService.roundUp(calculatedData));
    }
    else if(control=='other_month'){

      selectedMonth = this.otherMonth().controls[index].get('other_month')?.value;
      yearlyValue   = this.other_benefits[index].year_renum;
      if(selectedMonth.length>0)
      MonthlyValue    = (Number(yearlyValue)/selectedMonth.length);
      calculatedData  = Number(MonthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.otherMonth().controls[index].get('monthly_renum')?.setValue(this.appService.roundUp(calculatedData));

    }
    else{

      selectedMonth = this.recurringMonth().controls[index].get('recurring_month')?.value;
      yearlyValue   = this.recurring_deductions[index].year_renum;
      if(selectedMonth.length>0)
      MonthlyValue  = (Number(yearlyValue)/selectedMonth.length);
      calculatedData  = Number(MonthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.recurring_deductions[index].monthly_renum=calculatedData
      this.recurringMonth().controls[index].get('monthly_renum')?.setValue(this.appService.roundUp(calculatedData));

    }


  }

  monthlyValidator(index:any,control:any){
    this.isRecalculated = true
    var yearlyValue:any;
    var monthPay;
    var fbpAmount;
    var totalAmount   = 0;
    var ctc           = this.stepperData.newCTC
    var newOther      = 0;
    var contribution  = 0;
    var selectedMonth:any;

    /* reset yearly value w.r.t month value*/
    if(control=='gross_benefits'){
      monthPay      = this.month().controls[index].get('monthly_renum')?.value;
      fbpAmount     = this.gross_benefits[index].fbp_amount;

      if(!(this.month().controls[index]?.get('monthly_renum')?.errors)){

        selectedMonth = this.month().controls[index].get('gross_month')?.value;
        yearlyValue   = (selectedMonth!=null && selectedMonth.length>0)?Number(monthPay)*Number(selectedMonth.length):Number(monthPay)*12;
        this.gross_benefits[index].year_renum = Number(yearlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.month().controls[index]?.get('year_renum')?.setValue(this.gross_benefits[index].year_renum)
        if(fbpAmount>0){

          if(Number(yearlyValue) > Number(fbpAmount) ){
            this.month().controls[index].get('monthly_renum')?.setErrors({
              'fbplimit' : true
            })

          }

        }
      }
      else{

        if(monthPay=='' || monthPay==null){

          this.gross_benefits[index].year_renum = 0;
          this.month().controls[index]?.get('year_renum')?.setValue(this.gross_benefits[index].year_renum)

        }

      }
    }
    else if(control=='other_benefits'){

      monthPay      = this.otherMonth().controls[index].get('monthly_renum')?.value;
      fbpAmount     = this.other_benefits[index].fbp_amount;


      if(!(this.otherMonth().controls[index]?.get('monthly_renum')?.errors)){


        selectedMonth = this.otherMonth().controls[index].get('other_month')?.value;
        yearlyValue   = (selectedMonth!=null && selectedMonth.length>0)?Number(monthPay)*Number(selectedMonth.length):Number(monthPay)*12;
        this.other_benefits[index].year_renum = Number(yearlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.otherMonth().controls[index]?.get('year_renum')?.setValue(this.other_benefits[index].year_renum)

        if(fbpAmount>0){

          if(Number(yearlyValue) > Number(fbpAmount) ){
            this.otherMonth().controls[index].get('monthly_renum')?.setErrors({
              'fbplimit' : true
            })

          }

        }
      }
      else{

        if(monthPay=='' || monthPay==null){

          this.other_benefits[index].year_renum = 0;
          this.otherMonth().controls[index]?.get('year_renum')?.setValue(this.other_benefits[index].year_renum)
        }


      }
    }

    /*end*/

    for(let i=0;i<this.gross_benefits.length;i++)
    {
      if(this.gross_benefits[i]['component_type_name']!='other allowance')
      totalAmount+=this.gross_benefits[i].year_renum;
      else
        this.other_allowanceIndex = i;
    }
    for(let j=0;j<this.other_benefits.length;j++)
    {
      totalAmount+=this.other_benefits[j].year_renum;
    }

    /*check total yearly amount exceeds CTC*/
    if(Number(totalAmount)>Number(ctc)){

      if(control=='gross_benefits'){

        this.month().controls[index].get('monthly_renum')?.setErrors({
          'max' : true
        })
      }
      if(control=='other_benefits'){

        this.otherMonth().controls[index].get('monthly_renum')?.setErrors({
          'max' : true
        })
      }
    }
    else{
      for(let j=0;j<this.month().controls.length;j++){
        if(this.month().controls[j].get('monthly_renum')?.errors?.max==true){
          this.month().controls[j].get('monthly_renum')?.setErrors(null);

        }
      }
      for(let j=0;j<this.otherMonth().controls.length;j++){
        if(this.otherMonth().controls[j].get('monthly_renum')?.errors?.max==true){
          this.otherMonth().controls[j].get('monthly_renum')?.setErrors(null);

        }
      }
      if(!(this.otherMonth().controls[index]?.get('monthly_renum')?.errors))
      this.recalculation()

    }
  }
  yearlyValidator(index:any,control:any){
    this.isRecalculated = true
    var monthlyValue:any;
    var yearPay;
    var fbpAmount;
    var totalAmount   = 0;
    var ctc           = this.stepperData.newCTC
    var newOther      = 0;
    var contribution  = 0;
    var selectedMonth:any;

    /* reset yearly value w.r.t month value*/
    if(control=='gross_benefits'){
      yearPay       = this.month().controls[index].get('year_renum')?.value;
      fbpAmount     = this.gross_benefits[index].fbp_amount;

      if(!(this.month().controls[index]?.get('year_renum')?.errors)){

        this.gross_benefits[index].year_renum = Number(yearPay)
        selectedMonth   = this.month().controls[index].get('gross_month')?.value;
        monthlyValue    = (selectedMonth!=null && selectedMonth.length>0)?Number(yearPay)/Number(selectedMonth.length):Number(yearPay)/12;
        this.gross_benefits[index].monthly_renum = Number(monthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.month().controls[index].get('monthly_renum')?.setValue(this.gross_benefits[index].monthly_renum)
        if(fbpAmount>0){

          if(Number(yearPay) > Number(fbpAmount) ){
            this.month().controls[index].get('year_renum')?.setErrors({
              'fbplimit' : true
            })

          }

        }
      }
      else{

        if(yearPay=='' || yearPay==null){

          this.gross_benefits[index].monthly_renum = 0;
          this.month().controls[index].get('monthly_renum')?.setValue(this.gross_benefits[index].monthly_renum)
          this.gross_benefits[index].year_renum = 0

        }

      }
    }
    else if(control=='other_benefits'){

      yearPay      = this.otherMonth().controls[index].get('year_renum')?.value;
      fbpAmount     = this.other_benefits[index].fbp_amount;


      if(!(this.otherMonth().controls[index]?.get('year_renum')?.errors)){


        selectedMonth = this.otherMonth().controls[index].get('other_month')?.value;
        monthlyValue   = (selectedMonth!=null && selectedMonth.length>0)?Number(yearPay)/Number(selectedMonth.length):Number(yearPay)/12;
        this.other_benefits[index].monthly_renum = Number(monthlyValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        this.otherMonth().controls[index].get('monthly_renum')?.setValue(this.other_benefits[index].monthly_renum)
        this.other_benefits[index].year_renum = Number(yearPay)

        if(fbpAmount>0){

          if(Number(yearPay) > Number(fbpAmount) ){
            this.otherMonth().controls[index].get('year_renum')?.setErrors({
              'fbplimit' : true
            })

          }

        }
      }
      else{

        if(yearPay=='' || yearPay==null){

          this.other_benefits[index].monthly_renum = 0;
          this.otherMonth().controls[index].get('monthly_renum')?.setValue(this.other_benefits[index].monthly_renum)
          this.other_benefits[index].year_renum = 0

        }

      }
    }

    /*end*/

    for(let i=0;i<this.gross_benefits.length;i++)
    {
      if(this.gross_benefits[i]['component_type_name']!='other allowance')
      totalAmount+=Number(this.gross_benefits[i].year_renum);
      else
        this.other_allowanceIndex = i;
    }
    for(let j=0;j<this.other_benefits.length;j++)
    {
      totalAmount+=this.other_benefits[j].year_renum;
    }

    /*check total yearly amount exceeds CTC*/
    if(Number(totalAmount)>Number(ctc)){

      if(control=='gross_benefits'){

        this.month().controls[index].get('year_renum')?.setErrors({
          'max' : true
        })
      }
      if(control=='other_benefits'){

        this.otherMonth().controls[index].get('year_renum')?.setErrors({
          'max' : true
        })
      }
    }
    else{
      for(let j=0;j<this.month().controls.length;j++){
        if(this.month().controls[j].get('year_renum')?.errors?.max==true){
          this.month().controls[j].get('year_renum')?.setErrors(null);

        }
      }
      for(let j=0;j<this.otherMonth().controls.length;j++){
        if(this.otherMonth().controls[j].get('year_renum')?.errors?.max==true){
          this.otherMonth().controls[j].get('year_renum')?.setErrors(null);

        }
      }
      if(!(this.otherMonth().controls[index]?.get('year_renum')?.errors))
      this.recalculation()
    }
  }
  recalculation(){

    let other_month_array     = this.formGp.get('other_month')?.value ;
    let gross_month_array     = this.formGp.get('gross_month')?.value ;
    let recurring_month_array = this.formGp.get('recurring_month')?.value ;

    for(let i=0;i< gross_month_array.length;i++){
      for(let j=0; j< this.calculationData.length;j++){

        if(gross_month_array[i]['comp_id']==this.calculationData[j]['pay_component']){
          if(gross_month_array[i]['gross_month']!=null)
          this.calculationData[j]['pay_month']      = gross_month_array[i]['gross_month'];
          if(this.calculationData[j]['component_type_name']!="other allowance"){
            this.calculationData[j]['monthly_renum']  = Number(gross_month_array[i]['monthly_renum']);
            this.calculationData[j]['year_renum']     = Number(gross_month_array[i]['year_renum']);
          }
        }

      }
    }

    for(let i=0;i< other_month_array.length;i++){

      for(let j=0; j< this.calculationData.length;j++){

        if(other_month_array[i]['comp_id']==this.calculationData[j]['pay_component']){
          if(other_month_array[i]['other_month']!=null)
          this.calculationData[j]['pay_month']      = other_month_array[i]['other_month'];
          this.calculationData[j]['monthly_renum']  = Number(other_month_array[i]['monthly_renum']);
          this.calculationData[j]['year_renum']     = Number(other_month_array[i]['year_renum']);

        }

      }
    }
    for(let i=0;i< recurring_month_array.length;i++){

      for(let j=0; j< this.calculationData.length;j++){

        if(recurring_month_array[i]['comp_id']==this.calculationData[j]['pay_component'] && recurring_month_array[i]['recurring_month']!=null){
          this.calculationData[j]['pay_month']      = recurring_month_array[i]['recurring_month'];
        }

      }
    }

    this.recalculatedData = this.calculationData?.slice();
    for(let j=0;j<this.calculationData.length;j++){
      for(let i=0;i<this.recalculatedData.length;i++){

        if(this.recalculatedData[i]['pay_component'] == null || this.recalculatedData[i]['component_type_name']=='other allowance' || this.recalculatedData[i]['pay_component']=='null'){
          this.recalculatedData.splice(i, 1);

        }
      }
    }
    let paygrp        = '';
    for(let i=0;i<this.payGroupDD.length;i++){
      if(this.payGroupDD[i].name ==this.salaryForm.getRawValue().structure.pay_group){
        paygrp = this.payGroupDD[i].id;
      }
    }
    let newCTC:any;
    if(this.salaryForm['controls'].structure.value.rev_type==true)
    newCTC = parseFloat(this.employeeData.ctc)+((parseFloat(this.salaryForm['controls'].structure.value.revise_per)*parseFloat(this.employeeData.ctc))/100);
    else
    newCTC  = this.salaryForm['controls'].structure.value.revise_ctc;
    this.saveData = {
        "CTC"               : parseInt(newCTC),
        "pay_group"         : this.salaryForm['controls'].structure.value.pay_group,
        'is_revision'       : true,
        "recalculate"       : true,
        'assign_salary'     : this.recalculatedData
    };

    this.searchData.next();

  }
  monthlyRDValidator(index:any){
    let mnth = this.recurring_deductions[index]?.nature ? 12 : (this.recurringMonth().controls[index].value.recurring_month?.length?this.recurringMonth().controls[index].value.recurring_month.length:12)
    this.recurringMonth().controls[index]?.get('year_renum')?.setValue (this.appService.roundUp(this.recurringMonth().controls[index]?.get('monthly_renum')?.value*mnth))
    this.calculateDeds(index,'monthly_renum')
  }
  yearlyRDValidator(index:any){
    let mnth = this.recurring_deductions[index]?.nature ? 12 : (this.recurringMonth().controls[index].value.recurring_month?.length?this.recurringMonth().controls[index].value.recurring_month.length:12)
    this.recurringMonth().controls[index]?.get('monthly_renum')?.setValue (this.appService.roundUp(this.recurringMonth().controls[index]?.get('year_renum')?.value/mnth))
    this.calculateDeds(index,'year_renum')
  }
  calculateDeds(index:any,fld:any){
    this.recurring_deductions[index].year_renum =Number(this.recurringMonth().controls[index]?.get('year_renum')?.value)
    this.recurring_deductions[index].monthly_renum =Number(this.recurringMonth().controls[index]?.get('monthly_renum')?.value)
    let ded:GLfloat = 0
    for(let j=0;j<this.recurringMonth().controls.length;j++){
      ded += Number(this.recurringMonth().controls[j].get('year_renum')?.value)
      this.recurringMonth().controls[j].get('year_renum')?.setErrors(null);
      this.recurringMonth().controls[j].get('monthly_renum')?.setErrors(null);
    }
    if(ded>this.stepperData?.gross_amount){
      this.recurringMonth().controls[index].get(fld)?.setErrors({
        'max' : true
      })
    }
    this.stepperData.net_gross = this.stepperData?.gross_amount-ded
  }

}
