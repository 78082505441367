import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HrITDeclarationService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  empletterList(page:any,limit:any,offset:any,search:any,filterparam:any,emp_id:any,submitkey:any,year:any,submit_type :any){
    let empid:any;
    if(emp_id == 0){
      empid = `&search=${search}`;
    }else{
      empid = `&employee_list=[${emp_id}]`
    }
    if(filterparam != undefined){
      return this.http.get(`${this.API_PATH}hr/api/v1/hr-it-declaration-list/?data=${page}&limit=${limit}&is_active=true&offset=${offset}&${filterparam}${empid}&proposed_confirm=${submitkey}&year=${year}&submit_type=${submit_type}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/hr-it-declaration-list/?data=${page}&limit=${limit}&is_active=true&offset=${offset}${empid}&ordering=&proposed_confirm=${submitkey}&year=${year}&submit_type=${submit_type}`, this.httpOption)
    }
  }

}

