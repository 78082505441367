import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';


@Component({
  selector: 'app-appointment-letter-inbox',
  templateUrl: './appointment-letter-inbox.component.html',
  styleUrls: ['./appointment-letter-inbox.component.scss']
})
export class AppointmentLetterInboxComponent implements OnInit {
  @Input() requestitem: any;
  @Input() from: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  viewDetail                      = false;
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewMapPanel          : boolean = false
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  regenerate = false;
  Viewdata:any=[];
  loader1 =false;
  fromViewStatus                  = '';

  constructor(private inboxservice: InboxService,
    private investmentService:InvestmentDeclerationService,
    ) { }

  ngOnInit(): void {
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,

      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }

    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }


  }


  regeneratefn(event:any){

    this.regenerate    = false;
    this.inboxservice.updateInboxItem(this.requestitem['id'],{
      'approved_status':'Approved'
      }).subscribe((res: any)=>{
        this.successEmitter.emit(true);
      })
  }

  closegen(bool:any){
    this.regenerate = bool;
  }

}
