import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChallanSettingsPfService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  
  challanPfList(limit:any,offset:any,search:any,filterdata:any) {

    if(filterdata != '' && filterdata != undefined){
        return this.http.get(`${this.API_PATH}payroll/api/v1/pf-groups/?limit=${limit}&offset=${offset}&search=${search}&${filterdata}`, this.httpOption)
      }else{
        return this.http.get(`${this.API_PATH}payroll/api/v1/pf-groups/?is_active=true&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
      }
  }

  challanPfView(id: number) {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pf-group/${id}/`, this.httpOption)
  }

  challanPfDelete(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}payroll/api/v1/pf-group/${id}/`, body, this.httpOption)
  }

  getempList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name&employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}`, this.httpOption);
  }

  applicableList(type:string){
    if(type == "Business Unit"){
      return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    }else if(type == "Branch")
      return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&&ordering=name`, this.httpOption);
    else if(type == "City")
      return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?ordering=name&is_active=true`, this.httpOption);
    else if(type == "Company")
      return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
    else
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name`, this.httpOption);
  }

  challanPfSave(body: any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/pf-groups/`, body, this.httpOption)
  }

  challanPfUpdate(body: any,id:number){
    return this.http.put(`${this.API_PATH}payroll/api/v1/pf-group/${id}/`, body, this.httpOption)
  }

  challanPfSearchDropdown(filterdata:any) {

    if(filterdata != '' && filterdata != undefined){
      return this.http.get(`${this.API_PATH}payroll/api/v1/pf-challan-groups-dropdown/?ordering=name&${filterdata}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}payroll/api/v1/pf-challan-groups-dropdown/?ordering=name&is_active=true`, this.httpOption)
    }
  }

}
