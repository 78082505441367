import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(
    public toasterService: ToastrService,
    public router: Router,
    public http: HttpClient
  ) {}

  handleSuccessNotification(message:any,heading:any){
      this.toasterService.success(message,heading,{ positionClass: 'toast-bottom-left' });
  }

  handleErrorNotification(message:any,heading:any){
    this.toasterService.error(message,heading,{ positionClass: 'toast-bottom-left' });
  }

  PushNotification(message:any,heading:any) {
    this.toasterService.info(message, heading, {
      positionClass: 'toast-top-right',
      toastClass: 'toast-custom',
      enableHtml: true,
      closeButton: true,
      tapToDismiss: true,
    });
  }

  GetNotificationList(limit:any,offset:any){
    return this.http.get(`${this.API_PATH}inbox/api/v1/notification-list?limit=${limit}&offset=${offset}`, this.httpOption)
  }
  getNotificationCount(){
    return this.http.get(`${this.API_PATH}inbox/api/v1/unread-count/`, this.httpOption)
  }

  MarkReadAllNotificationList(){
    return this.http.get(`${this.API_PATH}inbox/api/v1/mark-read-all`, this.httpOption)
  }

  UpdateNotification(id:number, body:any){
    return this.http.patch(`${this.API_PATH}inbox/api/v1/notification-detail/${id}/`, body, this.httpOption)
  }

  markReadNotification(body:any){
    return this.http.post(`${this.API_PATH}inbox/api/v1/bulk-unread/`, body, this.httpOption)
  }

  clearAllNotification(){
    return this.http.get(`${this.API_PATH}inbox/api/v1/mark-read-all/?notif_type=clear`, this.httpOption)
  }

  getWhatsNew(empcode:any){
    //console.log(1)
    return this.http.get(`https://announcements.skizzlehr.tech/index.php/wp-json/whatsnew/list/${empcode}`, this.httpOption)
  }
}
