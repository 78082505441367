<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16">
            <div class="col-12 d-flex align-items-center">
                <p class="text-uppercase fw-500 mb-0">MAP Leave type</p>
                <div class="form-icon icon-left ms-auto" style="width: 22.6875rem;">
                    <i class="icon-search"></i>
                    <input type="text" class="form-control" placeholder="Search leave type">
                </div>
            </div>
        </div>
        <div class="row row-8 mt-8">
           
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body px-24 py-10 d-flex align-items-center">
                        <div class="form-check">
                            <label for=" " class="form-check-label ">Casual Leave </label>
                            <input type="checkbox" class="form-check-input">
                        </div>
                        <a class="link-primary align-items-center fs-14 d-flex ms-auto"><i
                                class="icon-pencil-square me-1"></i> Edit</a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body px-24 py-10 d-flex align-items-center">
                        <div class="form-check">
                            <label for=" " class="form-check-label ">Sick Leave</label>
                            <input type="checkbox" class="form-check-input">
                        </div>
                        <a class="link-primary align-items-center fs-14 d-flex ms-auto"><i
                                class="icon-pencil-square me-1"></i> Edit</a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body px-24 py-10 d-flex align-items-center">
                        <div class="form-check">
                            <label for=" " class="form-check-label ">Compoff Leave</label>
                            <input type="checkbox" class="form-check-input">
                        </div>
                        <a class="link-primary align-items-center fs-14 d-flex ms-auto"><i
                                class="icon-pencil-square me-1"></i> Edit</a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body px-24 py-10 d-flex align-items-center">
                        <div class="form-check">
                            <label for=" " class="form-check-label ">Sabatical Leave</label>
                            <input type="checkbox" class="form-check-input">
                        </div>
                        <a class="link-primary align-items-center fs-14 d-flex ms-auto"><i
                                class="icon-pencil-square me-1"></i> Edit</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase">NEXT</button>
    </footer>
</form>