import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as assert from 'assert';

@Injectable({
  providedIn: 'root'
})
export class ExpenseAdvnaceProfileService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }

  createadvance(data:any){
    return this.http.post(`${this.API_PATH}request/api/v1/expense-advance-list/`, data, this.httpOption)
  }
  getlistData(status:any,search:any,expense_date__range:any){
    if(status=='Dispensed'){
      status = 'Accepted&advance_dispense=true';
    }
    return this.http.get(`${this.API_PATH}request/api/v1/expense-advance-list/?status=${status}&search=${search}&expense_date__range=${expense_date__range}`, this.httpOption);
  }
  getSearchList(search:any,status:any){
    return this.http.get(`${this.API_PATH}request/api/v1/expense-advance-list-dropdown/?ordering=number&search=${search}&status=${status}`, this.httpOption);
  }
  getSinglecategoryData(id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/expense-advance-list/${id}`, this.httpOption);
  }
  getreports(){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/?status=Draft`, this.httpOption);
  }

  getadvance(id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/expense-advance-detail/${id}/`, this.httpOption);
  }
  mapreport(data:any,id:any){
    return this.http.put(`${this.API_PATH}request/api/v1/expense-advance-detail/${id}/`, data, this.httpOption)
  }
  
  getvaliddata(id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/expense-advance-validation/${id}/`, this.httpOption);
  }

  getoutstandingdata(id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/advance-transaction-list/${id}/`, this.httpOption);
  }
}
