<ng-container *ngIf="permissions">

  <div class="container-fluid p-24">
    <app-table-loader *ngIf="loader"></app-table-loader>
    <div class="row row-16" *ngIf="!loader">
      <div class="col-12">
        <div class="card card-c2">
          <div class="card card-c2 overflow-hidden">
            <div class="table-responsive" style="max-height: calc(100vh - 8.8rem);">
              <table class="table table-striped td-10 th-10 table-sm sticky-header">
                <thead>
                  <tr>
                    <th scope="col">Profile tabs</th>
                    <!-- <th scope="col">VISIBLE for employee</th> -->
                    <th scope="col">visible for manager</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of tabPermissionData;let i=index">

                    <td>{{item?.profile_tab}} </td>
                    <!-- <td>
                                          <div class="form-check form-switch">
                                              <input class="form-check-input" type="checkbox" [(ngModel)]="item.employee_visible" [ngModelOptions]="{standalone: true}" value="{{item.employee_visible}}" (change)="savePermission(i)" [disabled]="!permissions?.e">

                                          </div>
                                      </td> -->
                    <td class="form-cell">
                      <div class="form-check form-switch">

                        <input class="form-check-input" type="checkbox" [(ngModel)]="item.manager_visible"
                          [ngModelOptions]="{standalone: true}" value="{{item.manager_visible}}"
                          (change)="savePermission(i)" [disabled]="!permissions?.e">
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>