import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import * as XLSX from 'xlsx';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';



@Component({
  selector: 'app-salary-revision-arrears',
  templateUrl: './salary-revision-arrears.component.html',
  styleUrls: ['./salary-revision-arrears.component.scss']
})
export class SalaryRevisionArrearsComponent implements OnInit {
  filter              = false;
  arrearModal         = false;
  loader              = false;
  downloadMsg         = false;
  errorLog            = false;
  downloadPopup       = false;
  status              = "Unprocessed";
  searchString:any    = "";
  page                = 1;
  pageSize            = 20;
  arrearList:any      = [];
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  items: any[]        = [];
  exportData:any      = [];
  failedData:any      = [];
  successDataCount    = 0;
  isComplete          = false;
  alertToggle         = false;
  empalertMsg         = "";
  statusFlag          = false;
  alertDiv            = true;
  showSidePanel       = false;
  errorMsg = ''
  successMsg = ''
  fileName: string    = '';
  fileSize: string    = '';
  invalidFormat       = false;
  iscompleted         = false;
  ws:any              = null;
  excelData:any       = [];
  errorToggle         = false;
  payComponent:any    = [] ;
  importData:any      = [];
  importTemp:any      = [];
  header:any          = [];
  chunkLen            = 10;
  percentage:any      = 0;
  isDataUpload        = 0;
  isProgressStart     = false;
  successEmployee:any = [];
  tempArrearComp:any  = [];
  revisionToggle      = false;

  headerRow:any;



  year:any;
  month:any;
  company:any;
  // status = 'PENDING';

  message:any;
  errorData:any;
  statusText:any;
  saveStatus:any;
  msgText:any;
  HeaderText:any;
  inputdata :any;
  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(
    public route:ActivatedRoute,
    public messageService : MessageService,
    private sds:SalaryDashboardService,
    private notificationService: NotificationService,
    public appService:AppService,
    public payComponentService:PayComponentService,


  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.getArrearList(this.status);
    this.getPaycomponenet();

  }
  getArrearList(status:any){
    this.status         = status;
    this.loader         = true;
    this.HeaderText     = this.textData(status);
    this.searchString   = "";
    this.alertDiv       = true;

    this.sds.getArrearData(this.year,this.month,status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{

      this.items  = res.body;

      for(let i=0;i<this.items.length;i++){
        this.items[i]['arrear_data']    = "";
        this.items[i]['arrear_data']    += this.items[i]['arrear_month']+" ";
        this.items[i]['arrear_data']    += this.items[i]['arrear_year'];
        this.items[i]['revision_data']  = "";
        this.items[i]['revision_data']  += this.items[i].salary_revision_month!=null?this.items[i].salary_revision_month[0]:''+" ";
        this.items[i]['revision_data']  += this.items[i].salary_revision_month!=null?this.items[i].salary_revision_month[1]:'';


      }
      this.loader = false
    })
  }
  checkall(event:any){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }
  filterIt() {
    return this.items.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  filteredcount(){
    return this.filterIt().length;
  }
  checkallSelected(){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every(item => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  countSelected(){
    return this.items.filter(obj => obj.checked === true).length;
  }
  selectAll(){
    this.inputdata = this.appService.selectAll(this.items)

  }
  unselectAll(){
    this.inputdata = this.appService.unselectAll(this.items)

  }

  processArrear(status:any){
    this.saveStatus = status
    this.statusText = status=='Processed'?'complete':status=='Unprocessed'?'pending':'ignore'
    this.msgText    = this.textData(status);

    this.arrearList = [];

    this.items.forEach((item:any, key:any) => {

      if(item.checked == true && item.salary_status=='Unprocessed'){
        this.arrearList.push(item.id)
      }
      else if(item.checked == true && item.salary_status!='Unprocessed'){
        this.statusFlag = true;
      }

    })
    if(this.statusFlag==true)
    {
        this.empalertMsg = "The selected employee(s) payroll has already been Processed/Freezed/Held/Published. Kindly undo the respective action to add/remove salary revision arrears.";
        this.alertToggle = true;
    }
    if(this.arrearList.length>0){

      this.message     = "Click the " +this.statusText+ " button, If you want to " +this.statusText+ " the salary revision arrears for the selected employees.";
      this.downloadMsg = true;
      this.isComplete  = false;
    }

  }
  textData(status:any){
    var text = status=='Processed'?'completed':status=='Unprocessed'?'pending':'ignored';
    return text;
  }
  saveArrear(){
    this.isComplete  = true;
    var dataArray = {
     "pay_month"    : this.month,
     "pay_year"     : this.year,
     "status"       : this.saveStatus,
     "arrear_ids"   : this.arrearList
   };

   this.sds.saveArrearData(dataArray).subscribe((res:any)=>{
    this.downloadMsg = false;
    this.getArrearList(this.status);
    this.failedData       = res.body.failed_data;
    this.successDataCount = res.body.success_data_count;
    if(res.body.failed_data.length==0)
    this.notificationService.handleSuccessNotification("Selected employee's salary revision arrears are moved to "+this.msgText +" successfully.",this.statusText);
    else{
      this.exportData = [];
      for (let i = 0; i < this.failedData.length; i++) {
        this.exportData.push({'Employee code':this.failedData[i].data['employee_code'], 'Employee name':this.failedData[i].data['employee_name'],'Pay arrear for': this.failedData[i].data['arrear_month']+" "+this.failedData[i].data['arrear_year'],'Salary effective from':this.failedData[i].data['salary_revision_month']!=null? this.failedData[i].data['salary_revision_month'][0]+" "+this.failedData[i].data['salary_revision_month'][1]:'','Error description':this.failedData[i]['error']});
      }
      this.successMsg = this.successDataCount+" out of "+this.arrearList.length+" data uploaded successfully "
      this.errorMsg = this.failedData.length+' employee(s) have error data'
      this.errorLog   = true;

    }

   })
  }
  downloadError(){
    this.exportData = [];
    for (let i = 0; i < this.failedData.length; i++) {
      this.exportData.push({'Employee code':this.failedData[i].data['employee_code'], 'Employee name':this.failedData[i].data['employee_name'],'Pay arrear for': this.failedData[i].data['arrear_month']+" "+this.failedData[i].data['arrear_year'],'Salary effective from': this.failedData[i].data['salary_revision_month'][0]+" "+this.failedData[i].data['salary_revision_month'][1],'Error description':this.failedData[i]['error']});
    }
    this.appService.exportExcel(this.exportData,'Arrear Error');
    this.errorLog = false;

  }
  closeFilter(){
    this.filter  = false
  }
  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList        = []
    this.getArrearList(this.status);
    this.filter           = false
  }
  submitForm(data:any){
    this.filter  = false
    this.filtercomp.getBUList(this.company);
    this.buList           = data.buList;
    this.DepList          = data.DepList;
    this.DesignationList  = data.DesignationList;
    this.gradeList        = data.gradeList;
    this.BranchList       = data.BranchList;
    this.getArrearList(this.status);
  }
  closeInfo(bool:any){
    this.alertToggle = bool;
  }
  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
    this.iscompleted    = false;


  }
  onFileChange(evt: any) {

    this.excelData  = [];
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){

      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      const file = evt.target.files[0];
      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string    = wb.SheetNames[0];
        this.ws                 = wb.Sheets[wsname];
        var ws                  = wb.Sheets[wsname];
        this.fileName           = target.files[0].name;
        this.fileSize           = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData          = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));

        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;

    }
  }
  processData(){

    if(this.excelData.length <=1){

      this.errorToggle = true;
      this.errorMsg    = "No data found in the uploaded file.";
      this.clearData();


    } else {

      let fixedHeader:any ;
      var headerRow      = this.excelData[0];
      var allComp        = this.payComponent;
      let index:any      = 0;
      var compHeader:any = [];
      fixedHeader        = ['Employee code','Employee name','Pay arrear month','Pay arrear year','Paid days','Total arrear amount']
      let success        = fixedHeader.every(function(val:any) {
      if(val=='Paid days'){
        index  = headerRow.indexOf(val);

        for(let i=7;i<index;i++){
          compHeader.push(headerRow[i].trim());
        }
      }

      return headerRow.indexOf(val) !== -1;
      });

      let compValid    = compHeader.every(function(val:any) {
        let splittedString:any;
        if(allComp.indexOf(val)==-1){
          if(val.includes('Arrear')){
            splittedString = val.split("Arrear", 1);
            splittedString = splittedString[0].trim();
            return allComp.indexOf(splittedString) !== -1;

          }

        }
        return allComp.indexOf(val) !== -1;
      });

      if(success == false || compValid==false){
        this.errorToggle = true;

        this.errorMsg    = success == false?this.messageService.invalidExcelFormat():"Trying to import pay component which does not exist in the system";
        this.clearData();
      }

      else{

        this.prepareUploadData();

      }
    }
  }
  getPaycomponenet(){

    this.payComponentService.getPayComponentList(true,false).subscribe((res:any)=>{
      for(let i=0;i<res.length;i++){
        this.payComponent.push(res[i]['name'])
      }

    })
  }
  prepareUploadData(){
    this.importData = [];
    this.importTemp = [];
    this.header     = [];
    this.headerRow  = '';
    this.chunkLen   = 10;
    this.percentage = 0;

    this.headerRow  = this.excelData[0];

    for(let i=0;i<this.headerRow.length;i++){
      switch(this.headerRow[i]){
        case 'Employee code':{
          this.header.push('employee');
          break;
        }
        case 'Employee name':{
          this.header.push('emp_name');
          break;
        }
        case 'Pay arrear month':{
          this.header.push('arrear_month');
          break;
        }
        case 'Pay arrear year':{
          this.header.push('arrear_year');
          break;
        // }case 'Salary effective month':{
        //   this.header.push('arrear_month');
        //   break;
        // }
        // case 'Salary effective year':{
        //   this.header.push('arrear_year');
        //   break;
        }
        case 'Total arrear amount':{
          this.header.push('arrear_amount');
          break;
        }case 'Paid days':{
          this.header.push('paid_days');
          break;
        }
        default:{
          this.header.push(this.headerRow[i]);
          break;
        }
      }

    }

    this.excelData.forEach((item:any, key:any) => {

      if(key != 0){

          let convertedMonth      = this.month.replace(/\s/g, '').toUpperCase();
            var dict:any = {};
            var prior_arrear_data:any = {};
            var temp = [];

            for(let i=0;i<this.header.length;i++){

              if(this.header[i].includes('Arrear')){
                prior_arrear_data = {
                  "name"    : this.header[i],
                  "amount"  : item[i]
                }
                temp.push(prior_arrear_data);

              }
              else
              dict[this.header[i]]= item[i];
            }
            // this.tempArrearComp.push({"prior_arrear_data":temp});
            this.tempArrearComp.push(temp);

          this.importTemp.push({
          dict
          })



      }

    })

    for(let j=0;j<this.importTemp.length;j++){
      this.importData.push(this.importTemp[j]['dict'])
      this.importData[j]['prior_arrear_data'] = (this.tempArrearComp[j]);
    }


    if(this.importData.length <=0){
      this.errorToggle = true;
      this.errorMsg = "The uploaded file does not contain any data for "+this.month+" "+this.year;
      this.clearData();

    }

  }
  uploadPrior(){
    this.isDataUpload     = 1;
    this.isProgressStart  = true
    this.failedData       = []
    var l                 = this.importData.length;
    var cLoop             = Math.ceil(l/this.chunkLen)
    var p                 = 100/cLoop
    this.successDataCount = 0
    this.uploadasChunks(0,p);

  }
  uploadasChunks(k:any,p:any){
    var l           = this.importData.length;
    var x           = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any   = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.importData[i])
    }

            let  data = {

              "company_id"   :this.company,
              "data"         :saveDataArray,

            }
              this.sds.uploadPriorArrear(data).subscribe((res:any)=>{
                let response = res.body;
                if(response.failed_data != undefined && response.failed_data.length != 0){
                  response.failed_data.forEach((i:any, k:any) => {
                        this.failedData.push(i)
                    });
                }
                // for(let k=0;k<response.employee_codes_array.length;k++){
                //   this.successEmployee.push(response.employee_codes_array[k])
                // }


                this.percentage +=((p/4)*3);
                if(x<l)
                    this.uploadasChunks(k+1,p);
                else{
                  this.iscompleted      = true
                  this.isProgressStart  = false
                  const uniqueData      = new Set(this.successEmployee);
                  this.successDataCount = response?.succesd_data_count

                  if(this.failedData.length == 0){
                    this.percentage = 0
                    this.notificationService.handleSuccessNotification("Prior arrear data for "+ this.successDataCount +" employee(s) was successfully uploaded.","Success");
                    this.clearData();
                    this.getArrearList(this.status);
                    this.showSidePanel = false;

                  } else {
                    this.exportData = [];
                    let excelTemp      = [];

                    for (let i = 0; i < this.failedData.length; i++) {
                      var dict:any = {};
                      let arrearIndex = 0;

                      for(let j=0;j<this.header.length;j++){

                        let failVal               = this.header[j]
                        if(this.headerRow[j].includes('Arrear')){
                          dict[this.headerRow[j]] = this.failedData[i]['data']['prior_arrear_data'][arrearIndex]['amount']
                          arrearIndex++;
                        }
                        else{

                          dict[this.headerRow[j]]   = this.failedData[i]['data'][failVal];
                        }
                        if(j==this.header.length-1)
                        dict['Error description']  = this.failedData[i]['error'];
                      }

                      excelTemp.push({
                        dict
                      })
                    }

                    for(let j=0;j<excelTemp.length;j++){
                      this.exportData.push(excelTemp[j]['dict'])
                    }
                    this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                    this.errorMsg   = this.failedData.length+' employee(s) have error data'
                    this.getArrearList(this.status);

                    this.revisionToggle = true;
                  }

                }
              })

        // })
      };



}
