import { Component, OnInit, ViewChild } from '@angular/core';
import { AssetPolicyService } from 'src/app/@core/services/asset-policy.service';
import { AppService } from 'src/app/app.global';
import { FormBuilder,Validators  } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { debounceTime, delay, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-asset-sub-category',
  templateUrl: './asset-sub-category.component.html',
  styleUrls: ['./asset-sub-category.component.scss']
})
export class AssetSubCategoryComponent implements OnInit {
  viewDetails = false;
  editDetails = false;

  activeToggle               = true;
  searchKeyword              = "";
  loader                     = false;
  assetCategoryList:any      = [];
  nodata                     = false;
  submitted                  = false;
  disable                    = false;
  header:any                 = '';
  loader1                    = false;
  assetCategoryViewData:any  = [];

  searchlistdata:any         = [];
  categorySearch:string      = this.messageService.searchdisplay('subcategory');

  deleteToggle               = false;
  deleteClicked              = false;
  isLoading = false
  searchData : Subject<void> = new Subject();
  statusBtn:any              = "Active";
  @ViewChild(SearchbarComponent) child:any;

  offset                        = 0;
  listlength                    = 0;
  direction                     = '';
  defaultlimit:any              = 20;
  categoryDetailList:any        = [];
  infinityloader                = false;
  permissions:any               = [];

  catStatus:any = true;

  categoryListDropDown:any      = [];
  subCategoryListDropDown:any   = [];

  private subjectKeyUp          = new Subject<any>();

  filterpanel                   = false;
  filterStatus:any              = [{name:'All', value: ''},{name:'Active', value: true},{name:'Inactive', value: false}];

  filterForm                    = this.formBuilder.group({
    asset_category_ids            : null,
    asset_sub_category_ids        : null,
    status                        : null
  });

  disabled                      = false;
  datafilter:any                = [];


  constructor(
    private assetPolicyService: AssetPolicyService,
    public appService: AppService,
    private formBuilder: FormBuilder,
    public messageService : MessageService,
    public existDataService: ExistDatasService,
    public breakpointObserver: BreakpointObserver,
  ) { }

  assetCategoryForm = this.formBuilder.group({
    id             : 0,
    name           : ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    asset_category : [null,[Validators.required]]
  })

  ngOnInit(): void {

    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.getSubCategoryList();
    this.showDataItems();

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.assetPolicyService.assetSubCategoryList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.datafilter)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  get f() { return this.assetCategoryForm.controls; }

  getCategoryList(){
    this.categoryListDropDown = [];
    this.assetPolicyService.assetCategoryFilterDropDown(this.catStatus).subscribe((res: any) => {
      this.categoryListDropDown = res;
      this.selectAllForDropdownItems(this.categoryListDropDown);
    });
  }

  getSubCategoryList(){
    this.subCategoryListDropDown = [];
    this.assetPolicyService.assetSubCategoryFilterDropDown().subscribe((res: any) => {
      this.subCategoryListDropDown = res;
      this.selectAllForDropdownItems(this.subCategoryListDropDown);
    });
  }

  showDataItems(){
    this.loader = true;
    this.assetPolicyService.assetSubCategoryList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.datafilter).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res?.results?.length;
    if(this.offset == 0){
      this.assetCategoryList = [];
    }
    if (res?.results?.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata = false;
      this.categoryDetailList = res?.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.assetCategoryList?.push(this.categoryDetailList[i]);
    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  onSubmit(){
    this.submitted = true;
    if (this.assetCategoryForm.invalid ) {
          return;
    }
    this.disable = true;

    this.assetPolicyService.assetSubCategortCreateAndUpdate(Number(this.assetCategoryForm.value.id),{'name':this.assetCategoryForm.value.name,'asset_category': this.assetCategoryForm.value?.asset_category}).subscribe((res: any) => {
      this.offset             = 0;
      this.assetCategoryList  = [];
      this.showDataItems();
      this.editDetails        = false;

      this.assetCategoryForm.reset();
    },(err: any) => {this.disable = false});

    this.submitted        = false;
  }

  viewAssetCategory(id:any){
    if(this.permissions?.v){
      this.viewDetails           = true;
      this.loader1               = true;
      this.assetCategoryViewData = [];
      this.assetPolicyService.getSingleSubAssetCategory(id).subscribe((res: any) => {
      this.assetCategoryViewData = res;
        this.editDetails         = false;
        this.loader1             = false;
      })
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword      = "";
      this.offset             = 0;
      this.assetCategoryList  = [];
      this.searchData.next();
    }else{
      this.searchData.next();
    }
  }

  searchCategory(searchKeyword:any){
    this.isLoading = true;
    this.loader                 = true;
    this.offset                 = 0;
    this.assetCategoryList      = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next();
    }
  }

  editCategory(id:any){
    this.loader1        = true;
    this.getCategoryList();
    this.viewDetails    = false;
    this.editDetails    = true;
    this.disable        = false;
    this.header         = 'Edit'
    this.assetPolicyService.getSingleSubAssetCategory(id).subscribe((res:any)=>{
      this.assetCategoryForm.reset({ id: res.id, name:res.name, asset_category: res?.asset_category});
      this.loader1      = false;
    })
  }

  deleteAssetCategoryfn(id:any){
    this.deleteToggle               = true;
    this.assetCategoryForm.value.id = id;
  }

  deleteAssetCategory(){
    this.deleteClicked = true
    this.assetPolicyService.deleteSubAssetCategory(this.assetCategoryForm.value.id,{'is_active' : false}).subscribe((res: any) => {
      this.editDetails    = false;
      this.offset         = 0;
      this.assetCategoryList  = [];
      this.showDataItems();
      this.deleteToggle   = false;
      this.deleteClicked  = false
    },
    (err: any) => {
      this.deleteToggle   = false
      this.editDetails    = false
      this.deleteClicked  = false
    });
  }

  addAssetCategory(){
    this.getCategoryList();
    this.editDetails = true;
    this.disable     = false;
    this.header      = 'Add';
    this.assetCategoryForm.reset({ id: 0, name:'', asset_category: null});
    this.child.clearBar();
  }

  existService(){
    let value;
    if(this.header == 'Add'){
      value ={
        "page":"AssetSubCategory",
        "field":"name",
        "value": this.f.name?.value,
        "is_active": true
      }
    }else{
      value ={
        "id": this.assetCategoryForm.value?.id,
        "page":"AssetSubCategory",
        "field":"name",
        "value": this.f.name?.value,
        "is_active": true
      }
    }

    this.existDataService.getSpecificName(value).subscribe((res:any)=>{

      this.loader = false

      if(res?.body?.data ==='invalid'){
        this.f.name?.setErrors({
          'exist' : true
        })
      }
    })
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  clearcommonForm(item:any,index:number){
    if(item == "asset_category_ids"){
       let daata = this.filterForm.value.asset_category_ids;
       daata.splice(index, 1);
       this.filterForm.patchValue({
        asset_category_ids: daata
       });
     }else if(item == "asset_sub_category_ids"){
      let daata = this.filterForm.value.asset_sub_category_ids;
       daata.splice(index, 1);
       this.filterForm.patchValue({
        asset_sub_category_ids: daata
       });
     }
  }

  resetfilter(){
    this.filterForm.get('status')?.reset(null);

    let asset_category_ids        =  this.filterForm.get('asset_category_ids')?.reset('');
    let asset_sub_category_ids    = this.filterForm.get('asset_sub_category_ids')?.reset('');
    this.activeToggle             = (this.filterForm.controls.status?.value == null || this.filterForm.controls.status?.value == null) ? true : this.filterForm.controls.status?.value;

    this.datafilter = 'asset_category_ids='+(asset_category_ids!=null && asset_category_ids!="" ?JSON.stringify(asset_category_ids):'')+'&asset_sub_category_ids='+(asset_sub_category_ids!=null && asset_sub_category_ids!="" ? JSON.stringify(asset_sub_category_ids):'');
    this.activeToggle       = true;
    this.filterpanel        = false;
    this.child.clearBar();
    this.offset             = 0;
    this.assetCategoryList  = [];
    this.showDataItems();
  }

  applyfilter(){

    this.filterpanel=false;
    if (this.filterForm.invalid) {
      return;
    }

    let asset_category_ids        = this.filterForm.controls.asset_category_ids?.value;
    let asset_sub_category_ids    = this.filterForm.controls.asset_sub_category_ids?.value;
    this.activeToggle             = (this.filterForm.controls.status?.value == null || this.filterForm.controls.status?.value == null) ? true : this.filterForm.controls.status?.value;

    this.datafilter = 'asset_category_ids='+(asset_category_ids!=null && asset_category_ids!="" ?JSON.stringify(asset_category_ids):'')+'&asset_sub_category_ids='+(asset_sub_category_ids!=null && asset_sub_category_ids!="" ? JSON.stringify(asset_sub_category_ids):'');

    this.child.clearBar();
    this.offset = 0;
    this.showDataItems();
  }

  addheadercontent(){
    this.header      = 'Add';
    this.editDetails = true;
    this.child.clearBar();
  }

}
