import { Component, OnInit,  Input } from '@angular/core';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout'
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html',
  styleUrls: ['./payroll-report.component.scss']
})

export class PayrollReportComponent implements OnInit {

  reports:any[]              = [];
  loader                     = false;
  nodata                     = false;
  searchlistdata:any         = [];
  tasksearch:string          = this.messageService.searchdisplay('Reports') ;
  permissions:any            = [];
  schedulePermissions1:any   = [];

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength                 = 0;
  direction                  = '';
  defaultlimit:any           = 20;
  reoprtDetailsList:any      = [];
  infinityloader             = false;
  searchKeyword="";
  sLoading = false;

  constructor(
    private reportService:ReportsService,public router: Router, public breakpointObserver: BreakpointObserver, public appservice:AppService,public messageService : MessageService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.getScheduleReportPermission1();
    // this.breakpointObserver
    // .observe(['(min-width: 1024px)'])
    // .subscribe((state: BreakpointState) => {
    //   if (state.matches) {
    //     this.defaultlimit = 40;
    //   }
    // });
    this.showDataItems();

  }

  getPermission(){
    this.permissions = this.appservice.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getScheduleReportPermission1(){
    this.schedulePermissions1 = this.appservice.getPermissions('/scheduled-reports');
    if(this.schedulePermissions1 == undefined){
      setTimeout(() => {
        this.getScheduleReportPermission1();
      }, 3000);
    }
  }

  showDataItems(){
    this.loader = true;
    this.reportService.reportTypeList('Payroll',this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
      this.listlength        = res.results.length;
      if(this.offset ==0){
        this.reports = [];
      }
      if (res.results.length != 0) {
        this.reoprtDetailsList = res.results;
      }
      this.infinityloader    = false;
      this.addItems(this.listlength);
      if (res.results.length == 0) {
        this.nodata = this.offset == 0 ? true:false;
      }else{
        this.nodata = false;
      }
      // this.nodata            = res.results.length==0?true:false;
      this.loader            = false;
      this.sLoading          = false;
    });
    // this.reportService.payrollReportDropdown().subscribe((res: any) => {
    //   this.searchlistdata = res;
    // });
  }

   /********* INIFNITY SCROLL FUNCTION *******************/
   onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any){
    for (let i = 0; i < itemlist; ++i) {
      this.reports.push(this.reoprtDetailsList[i]);
    }
  }

  reportTableView(reportname:any, reportid:any){
    if(this.permissions.v){
      if(reportid != undefined && reportid != null){
        reportname = reportname.replace(/\s/g, "");
        this.router.navigate(['/payroll-report/payroll-report-configure/'+reportname+'/'+reportid]);
      }
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.reports = [];
      this.reoprtDetailsList = [];
      this.showDataItems();
    }else{
      this.reportService.getSinglePayrollReport(data).subscribe((res: any) => {
        this.reports = res;
        this.loader = false;
      });
    }
  }

  searchpayroll(searchKeyword:any){
    this.sLoading = true;
    this.loader = true;
    this.offset           = 0;
    this.reports= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.showDataItems();
    }
  }
}
