<nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky top-48">
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">All</a>
        <ng-template ngbNavContent>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="table-responsive radius-4">
                        <table
                            class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                            <thead>
                                <tr>
                                    <th class="fw-600 text-uppercase" scope="col">Trip number</th>
                                    <th class="fw-600 text-uppercase" scope="col">Trip name</th>
                                    <th class="fw-600 text-uppercase" scope="col">trip date</th>
                                    <th class="fw-600 text-uppercase" scope="col">destination</th>
                                    <th class="fw-600 text-uppercase" scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody class="cell-16 ">
                                <ng-container *ngFor="let i of [1,2,3]">
                                    <tr>
                                        <td>
                                            <a (click)="viewDetail = true" class="link-primary1">Trip#00001</a>
                                        </td>
                                        <td>England</td>
                                        <td>
                                            <span class="text-nowrap">12/07/2023 - 25/07/2023</span>
                                        </td>
                                        <td>Manchester</td>
                                        <td>
                                            <span class="badge badge-success py-1 px-3">Approved</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a (click)="viewDetail = true" class="link-primary1">Trip#00001</a>
                                        </td>
                                        <td>England</td>
                                        <td>
                                            <span class="text-nowrap">12/07/2023 - 25/07/2023</span>
                                        </td>
                                        <td>Manchester</td>
                                        <td>
                                            <span class="badge badge-danger py-1 px-3">Rejected</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a (click)="viewDetail = true" class="link-primary1">Trip#00001</a>
                                        </td>
                                        <td>England</td>
                                        <td>
                                            <span class="text-nowrap">12/07/2023 - 25/07/2023</span>
                                        </td>
                                        <td>Manchester</td>
                                        <td>
                                            <span class="badge badge-warning py-1 px-3">Pending</span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">Pending</a>
        <ng-template ngbNavContent>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="table-responsive radius-4">
                        <table
                            class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                            <thead>
                                <tr>
                                    <th class="fw-600 text-uppercase" scope="col">Trip number</th>
                                    <th class="fw-600 text-uppercase" scope="col">Trip name</th>
                                    <th class="fw-600 text-uppercase" scope="col">trip date</th>
                                    <th class="fw-600 text-uppercase" scope="col">destination</th>
                                    <th class="fw-600 text-uppercase" scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody class="cell-16 ">
                                <tr *ngFor="let i of [1,2,3]">
                                    <td>
                                        <a (click)="viewDetail = true" class="link-primary1">Trip#00001</a>
                                    </td>
                                    <td>England</td>
                                    <td>
                                        <span class="text-nowrap">12/07/2023 - 25/07/2023</span>
                                    </td>
                                    <td>Manchester</td>
                                    <td>
                                        <span class="badge badge-warning py-1 px-3">Pending</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</nav>
<div class="container-fluid p-24 ">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Trips</h3>
            <p class="text-helper mb-md-0">Lorem ipsum dolor asit amet lorem</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class="form-icon icon-left" style="width:22.6875rem;">
                    <i class="icon-search"></i>
                    <input type="text" class="form-control fs-14" name="" placeholder="Search trips" id="">
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end">
                        <button ngbDropdownItem>All</button>
                        <button ngbDropdownItem>Active </button>
                        <button ngbDropdownItem>Deleted</button>
                    </div>
                </div>

                <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" routerLink="/trips-setup"><i
                        class="icon-plus"></i>ADD new
                    trip</button>
            </div>
        </div>
    </div>
    <div class="row row-16" [ngbNavOutlet]="nav"></div>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0">Trip 1</p>

                </div>

                <div class="row row-16">
                    <div class="col-12">
                        <div class="card details-card shadow-0 p-24">
                            <div class="row row-16">
                                <div class="col-12 d-flex">
                                    <p class="title">Trip details</p>
                                    <div class="d-flex ms-auto align-items-center gap-8">

                                        <span class="badge px-16 fs-14 badge-warning py-1">Pending</span>

                                        <span class="text-light-400">|</span>
                                        <span class="link-primary fs-14">View Status</span>
                                    </div>

                                </div>
                                <div class="col-sm-6">
                                    <div class="detail-title">Trip Name</div>
                                    <div class="detail-desc">Trip 1 </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="detail-title">Trip Type</div>
                                    <div class="detail-desc"><i
                                            class="bi-record-circle-fill me-8 text-light-400"></i>International</div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Destination Country</div>
                                    <div class="detail-desc">United Kingdom</div>
                                </div>
                                <div class="hstack align-items-center gap-8 fs-14">
                                    <i class="icon-check-square-fill text-light-400"></i>Is visa required?
                                </div>
                                <div class="col-sm-4 col-12">
                                    <div class="detail-title">Visa Number</div>
                                    <div class="detail-desc">VISA157HSL12</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 p-24">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="title">TRIP ITENERARY</p>
                                </div>
                                <div class="col-12">
                                    <div class="card details-card shadow-0 p-24">
                                        <div class="row row-16">
                                            <div class="col-12">
                                                <p class="title">Flight</p>
                                            </div>
                                            <div class="col-12">
                                                <div class="detail-title">Trip Option</div>
                                                <div class="detail-desc"><i
                                                        class="bi-record-circle-fill me-8 text-light-400"></i>Multi-city
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card details-card shadow-0 p-24">
                                                    <div class="row row-16">
                                                        <div class="col-12">
                                                            <p class="title">1. FLIGHT</p>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Depart From</div>
                                                            <div class="detail-desc">Dept 1</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Arrive At</div>
                                                            <div class="detail-desc">Arrive 1</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="detail-title">Departure Time</div>
                                                            <div class="detail-desc">12:00:00 AM - 08:00:00 AM</div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="detail-title">Flight Preference </div>
                                                            <div class="detail-desc">Premium Economy </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="detail-title">Departure Date </div>
                                                            <div class="detail-desc">12/12/2023 </div>
                                                        </div>
                                                        <div class="col-12">

                                                            <div class="hstack align-items-center gap-8 fs-14">
                                                                <i class="icon-check-square-fill text-light-400"></i> Do
                                                                you
                                                                want company to book ticket on your behalf?
                                                            </div>
                                                        </div>
                                                        <div class=" col-12">
                                                            <div class="detail-title">Description </div>
                                                            <div class="detail-desc">Lorem ipsum dolor sit amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore et
                                                                dolore magna aliqua. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card details-card shadow-0 p-24">
                                        <div class="row row-16">
                                            <div class="col-12">
                                                <p class="title">HOTEL</p>
                                            </div>

                                            <div class="col-12">
                                                <div class="card details-card shadow-0 p-24">
                                                    <div class="row row-16">
                                                        <div class="col-12">
                                                            <p class="title">1. HOTEL</p>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Check-in Date</div>
                                                            <div class="detail-desc">12/12/2023</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Check-in Time</div>
                                                            <div class="detail-desc">07:00:00 AM</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="detail-title">Check-out Date</div>
                                                            <div class="detail-desc">12/12/2023</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Check-out Time</div>
                                                            <div class="detail-desc">07:00:00 AM</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="detail-title">Location</div>
                                                            <div class="detail-desc">Manchester</div>
                                                        </div>
                                                        <div class="col-12">

                                                            <div class="hstack align-items-center gap-8 fs-14">
                                                                <i class="icon-check-square-fill text-light-400"></i>Do
                                                                you want
                                                                company to book accomodation on your behalf?
                                                            </div>
                                                        </div>
                                                        <div class=" col-12">
                                                            <div class="detail-title">Description </div>
                                                            <div class="detail-desc">Lorem ipsum dolor sit amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore et
                                                                dolore magna aliqua. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card details-card shadow-0 p-24">
                                        <div class="row row-16">
                                            <div class="col-12">
                                                <p class="title">CAR RENTAL</p>
                                            </div>

                                            <div class="col-12">
                                                <div class="card details-card shadow-0 p-24">
                                                    <div class="row row-16">
                                                        <div class="col-12">
                                                            <p class="title">1. CAR RENTAL</p>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Pick-up Date</div>
                                                            <div class="detail-desc">12/12/2023</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Pick-up Time</div>
                                                            <div class="detail-desc">07:00:00 AM</div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="detail-title">Pick-up Location</div>
                                                            <div class="detail-desc">Manchester</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="detail-title">Drop-off Date</div>
                                                            <div class="detail-desc">12/12/2023</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Drop-off Time</div>
                                                            <div class="detail-desc">07:00:00 AM</div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="detail-title">Drop-off Location</div>
                                                            <div class="detail-desc">Manchester</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="detail-title">Car Type</div>
                                                            <div class="detail-desc">Premium</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Driver needed?</div>
                                                            <div class="detail-desc"><i
                                                                    class="bi-record-circle-fill me-8 text-light-400"></i>Yes
                                                            </div>
                                                        </div>
                                                        <div class="col-12">

                                                            <div class="hstack align-items-center gap-8 fs-14">
                                                                <i class="icon-check-square-fill text-light-400"></i>Do
                                                                you want
                                                                company to book accomodation on your behalf?
                                                            </div>
                                                        </div>
                                                        <div class=" col-12">
                                                            <div class="detail-title">Description </div>
                                                            <div class="detail-desc">Lorem ipsum dolor sit amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore et
                                                                dolore magna aliqua. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card details-card shadow-0 p-24">
                                        <div class="row row-16">
                                            <div class="col-12">
                                                <p class="title">BUS</p>
                                            </div>

                                            <div class="col-12">
                                                <div class="card details-card shadow-0 p-24">
                                                    <div class="row row-16">
                                                        <div class="col-12">
                                                            <p class="title">1. BUS</p>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Depart From</div>
                                                            <div class="detail-desc">Frankfurt</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Arrive At</div>
                                                            <div class="detail-desc">Manchester</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="detail-title">Departure Date</div>
                                                            <div class="detail-desc">12/12/2023</div>
                                                        </div>

                                                        <div class="col-12">

                                                            <div class="hstack align-items-center gap-8 fs-14">
                                                                <i class="icon-check-square-fill text-light-400"></i>Do
                                                                you want
                                                                company to book accomodation on your behalf?
                                                            </div>
                                                        </div>
                                                        <div class=" col-12">
                                                            <div class="detail-title">Description </div>
                                                            <div class="detail-desc">Lorem ipsum dolor sit amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore et
                                                                dolore magna aliqua. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card details-card shadow-0 p-24">
                                        <div class="row row-16">
                                            <div class="col-12">
                                                <p class="title">Train</p>
                                            </div>

                                            <div class="col-12">
                                                <div class="card details-card shadow-0 p-24">
                                                    <div class="row row-16">
                                                        <div class="col-12">
                                                            <p class="title">1. Train</p>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Depart From</div>
                                                            <div class="detail-desc">Frankfurt</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="detail-title">Arrive At</div>
                                                            <div class="detail-desc">Manchester</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="detail-title">Departure Date</div>
                                                            <div class="detail-desc">12/12/2023</div>
                                                        </div>

                                                        <div class="col-12">

                                                            <div class="hstack align-items-center gap-8 fs-14">
                                                                <i class="icon-check-square-fill text-light-400"></i>Do
                                                                you want
                                                                company to book accomodation on your behalf?
                                                            </div>
                                                        </div>
                                                        <div class=" col-12">
                                                            <div class="detail-title">Description </div>
                                                            <div class="detail-desc">Lorem ipsum dolor sit amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore et
                                                                dolore magna aliqua. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Document </div>
                                    <div class="detail-desc">
                                        <div class="card card-c2  bg-secondary">
                                            <div class="p-12 bg-secondary d-flex align-item-center">
                                                <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                                <div
                                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                                    <span class="tips text-uppercase fs- fw-700 text-trim">DOCUMENT
                                                        FILES
                                                    </span>
                                                    <span class="tips-helper ">DOCUMENT FILES</span>
                                                    <div class="d-flex gap-8">
                                                        <div class="link-primary">
                                                            <i class="bi bi-eye me-1"></i>View
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-12">
                                    <div class="detail-title">Remark </div>
                                    <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore magna aliqua. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>