import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { spaceValidator } from 'src/app/@shared/validators/space.validators';
import { ExpenseAdvnaceProfileService } from 'src/app/@core/services/expense-advance-profile.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';

@Component({
  selector: 'app-add-expense-advance',
  templateUrl: './add-expense-advance.component.html',
  styleUrls: ['./add-expense-advance.component.scss']
})
export class AddExpenseAdvanceComponent implements OnInit {

  expensekey: any = [];
  submitted = false;
  loader = false;
  header = 'Add';
  viewdetailsout =false;
  exceedmsg=false;
  @Input() currency:any;
  @Input() addadvance: any;
  @Input() from: any;
  @Input() requestFor:any
  @Input () outstandingamount:any;
  @Input () amountlimitdata:any;
  @Output() panelclose = new EventEmitter();
  @Output() detailout = new EventEmitter();

  myForm:FormGroup= this.formBuilder.group({
    amount                     : ['', [Validators.required,NumberpatternValidator(), Validators.maxLength(6)]],
    expense_date               : [null, [Validators.required]],
    attachment                 : [null],
    purpose                    : ['', [Validators.required,spaceValidator(),Validators.maxLength(500)]],
    // trip                       : ['', [spaceValidator()]],
  })

  disabled                     = false;
  disable                      = false;

  fileError   = false;
  docURL: any = "";
  docLoader   = false;
  fileErrorMsg: any = "";
  docName: any      = "";
  fileSize: any     = "";
  amountlimit:any;

  // File Upload
  modalToggle                     = false;
  allowedExtension                = "";
  alertMsg:any                    = '';
  alertToggle                     = false;
  fileName                        = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;

  constructor(
    public ExpenseAdvnaceProfileService: ExpenseAdvnaceProfileService,
    public formBuilder: FormBuilder,
    public appService: AppService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {

    if(this.amountlimitdata.data?.credit_limit !=0 && this.amountlimitdata.data?.is_credit_limit && this.amountlimitdata.data?.outstanding_amount!=0  && this.amountlimitdata.data?.amount > (this.amountlimitdata.data?.credit_limit - this.amountlimitdata.data?.outstanding_amount)){

      this.amountlimit = this.amountlimitdata.data?.credit_limit - this.amountlimitdata.data?.outstanding_amount;
      this.myForm.get('amount')?.setValidators([Validators.required,NumberpatternValidator(),Validators.max(this.amountlimit)])

    }else if(this.amountlimitdata.data?.amount!=0 && this.amountlimitdata.data?.specify_limit){

      this.amountlimit = this.amountlimitdata.data?.amount;
      this.myForm.get('amount')?.setValidators([Validators.required,NumberpatternValidator(),Validators.max(this.amountlimitdata.data?.amount)])

    }else if(this.amountlimitdata.data?.credit_limit !=0 && this.amountlimitdata.data?.is_credit_limit && this.amountlimitdata.data?.outstanding_amount!=0  && ((this.amountlimitdata.data?.credit_limit > this.amountlimitdata.data?.outstanding_amount) || (this.amountlimitdata.data?.credit_limit == this.amountlimitdata.data?.outstanding_amount))){

      this.amountlimit = this.amountlimitdata.data?.credit_limit - this.amountlimitdata.data?.outstanding_amount;
      this.myForm.get('amount')?.setValidators([Validators.required,NumberpatternValidator(),Validators.max(this.amountlimit)])

    }
    if(this.amountlimit<0 || this.amountlimit==0){
      this.exceedmsg = true;
    }else{
      this.exceedmsg = false;
    }
  }

  validateamountfn(){

  }

  onSubmit() {
    if (this.myForm.invalid) {
      return;
    }
    this.disable =true;
    console.log("this.from",this.from);

    let myform = {
      'amount'      : this.myForm.value.amount,
      'purpose'     : this.myForm.value.purpose,
      'expense_date':  moment(this.myForm.value.expense_date).format('YYYY-MM-DD'),
      'attachment'  : this.myForm.value.attachment,
    }
    if(this.from=='behalf'){
      this.detailout.emit(myform);

    }
    else{

      this.ExpenseAdvnaceProfileService.createadvance(myform).subscribe((res: any) => {
        this.detailout.emit(true);
        this.closefn();
        this.myForm.reset();
      }, (err: any) => {
      });
    }
  }

  closefn() {
    this.addadvance = false;
    this.panelclose.emit(this.addadvance);
  }

  get f() { return this.myForm.controls; }

  expenseDate(inp:any){

  }

  disableDate(){
    return false;
  }

  dateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear() + 1, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  getURL(event: any) {
    this.docURL = event;
    this.fileError = false;
    this.myForm.get('attachment')?.setValue(event);
    this.docLoader = false;
  }

  getfileformat(event: any) {
    this.myForm.get('attachment')?.setValue('');
    this.docLoader = false;
    if (event == false) {
      this.fileError = true;
      this.fileErrorMsg = this.messageService.validateFileFormat('pdf,doc,img');
    }
  }

  getvalidsize(event: any) {
    this.myForm.get('attachment')?.setValue('');
    this.docLoader = false;
    this.fileError = true;
    this.fileErrorMsg = event;
  }

  deleteProfilePic(event: any) {
     this.myForm.get('attachment')?.setValue('');
    this.docURL = '';
  }

  getFilename(event: any) {
    this.docName = event.name;
    this.fileSize = this.appService.formatSizeUnits(event.size);
   }

  getloader(event: any) {
    this.docLoader = event;
  }

  closeoutstandview(daata:any){
    this.viewdetailsout = daata;
  }

   /********* VIEW DOCUMENT FUNCTION ****************/
   viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

}
