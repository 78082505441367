<form class="" [formGroup]="configForm">
    <div class="container-fluid p-32 form-container">
        <div class="row row-24">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">Setup configuration</p>
            </div>
            <div class="col-12">
                <div class="card card-c2 px-24 pt-16 pb-24">
                    <div class="row row-16">
                        <div class="col-12">
                            <div class="title">Attendance</div>
                        </div>
                        <div class="col-12">
                            <label for=" " class="form-label d-block">Do you want to allow user to edit OT
                                hours?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Enable this option if you want to allow the approver to edit or update the OT hours of the employee before approving the OT request"
                                        popoverClass="popover-input popover-info" placement="bottom"
                                        triggers="hover"></i></span></label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="" id="" [value]=true
                                    formControlName="edit_ot_hours">
                                <label class="form-check-label" for="">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="" id="" [value]=false
                                    formControlName="edit_ot_hours">
                                <label class="form-check-label" for="">No</label>
                            </div>
                        </div>

                          <div class="col-12">
                              <label for=" " class="form-label  required">Define number of hours after shift end time to initiate consolidate check in request </label>

                              <input type="text" class="form-control" placeholder="Enter Hours"
                                  formControlName="initiate_consolidate_checkinrequest"
                                  [ngClass]="{ 'is-invalid': (saveBtnClicked || f.initiate_consolidate_checkinrequest.touched || f.initiate_consolidate_checkinrequest.dirty) &&  f.initiate_consolidate_checkinrequest.errors}">
                              <div *ngIf="(saveBtnClicked || f.initiate_consolidate_checkinrequest.touched || f.initiate_consolidate_checkinrequest.dirty) && f.initiate_consolidate_checkinrequest.errors"
                                  class="invalid-feedback">
                                  <div *ngIf="f.initiate_consolidate_checkinrequest.errors.required">
                                      {{this.messageService.validationDisplay('required')}}</div>

                                  <div *ngIf="f.initiate_consolidate_checkinrequest.errors.pattern">
                                      {{this.messageService.validationDisplay('pattern')}}</div>
                                      <div *ngIf="!(f.initiate_consolidate_checkinrequest.errors?.pattern) && f.initiate_consolidate_checkinrequest.errors?.max">
                                        {{this.messageService.notGreaterMessage('10','Number of hours')}}</div>
                              </div>
                          </div>


                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2 px-24 pt-16 pb-24">
                    <div class="row row-16">
                        <div class="col-12">
                            <div class="title">leave year end process</div>
                        </div>
                        <div class="col-12">
                            <label for=" " class="form-label d-block">Do you want to show pop up notification
                                on the home dashboard when the leave policy year-end arises?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="If yes is selected, there will be a notification pop up in the home page dashboard reminding the leave year end process. If No is selected there will be no pop up in the home page dashboard"
                                        popoverClass="popover-input popover-info" placement="bottom"
                                        triggers="hover"></i></span>
                            </label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="" id="yes" [value]=true
                                    formControlName="showup_notify_home" (ngModelChange)="addValidator('showup_notify_home','prior_days');addValidator('showup_notify_home','timeattendancesettings_role')">
                                <label class="form-check-label" for="yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="" id="no" [value]=false
                                    formControlName="showup_notify_home"
                                    (ngModelChange)="addValidator('showup_notify_home','prior_days');addValidator('showup_notify_home','timeattendancesettings_role')">
                                <label class="form-check-label" for="no">No</label>
                            </div>
                        </div>
                        <ng-container *ngIf="f.showup_notify_home.value">
                            <div class="col-12">
                                <label for=" " class="form-label  required">How many days prior to the leave policy
                                    year-end
                                    the pop up notification should display? </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Select from when the system has to notify about the year end processing (max from 60 days prior can be selected)."
                                        popoverClass="popover-input popover-info" placement="bottom"
                                        triggers="hover"></i></span>
                                <input type="text" class="form-control" placeholder="Enter Days"
                                    formControlName="prior_days"
                                    [ngClass]="{ 'is-invalid': (saveBtnClicked || f.prior_days.touched || f.prior_days.dirty) &&  f.prior_days.errors}">
                                <div *ngIf="(saveBtnClicked || f.prior_days.touched || f.prior_days.dirty) && f.prior_days.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.prior_days.errors.required">
                                        {{this.messageService.validationDisplay('required')}}</div>

                                    <div *ngIf="f.prior_days.errors.pattern">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="!(f.prior_days.errors.pattern) && f.prior_days.errors.max">
                                          {{this.messageService.fieldlengthvalidation('days',60)}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                              <label for=" " class="form-label  required">Whom do you want to notify? </label>
                              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                      ngbPopover="Select the Role from the dropdown for whom the “Leave year end” notification has to be sent."
                                      popoverClass="popover-input popover-info" placement="bottom"
                                      triggers="hover"></i></span>
                              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('role')}}"
                              formControlName="timeattendancesettings_role" [items]="roleListarr" [multiple]="true"
                              groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                              bindLabel="role_name" [closeOnSelect]="false" bindValue="id" class="multiselect"
                              [ngClass]="{ 'is-invalid': (saveBtnClicked || f.timeattendancesettings_role.touched || f.timeattendancesettings_role.dirty) &&  f.timeattendancesettings_role.errors}">
                              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                      [ngModelOptions]="{standalone: true}" /> Select All
                              </ng-template>
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                  <div class="ng-value" *ngIf="configForm.value.timeattendancesettings_role?.length ">
                                      <span class="ng-value-label">{{configForm.value.timeattendancesettings_role?.length }} role
                                          selected</span>
                                  </div>
                              </ng-template>
                              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="d-inline-flex align-items-center">
                                      <input id="item-{{index}}" class="me-8" type="checkbox"
                                          [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      <img class="avatar-sm sq-24 img-fluid rounded-circle"
                                          *ngIf="item.logo !=  null && item.logo !=  ''" src="{{item.logo}}">
                                      <span *ngIf="item.logo ==  null || item.logo ==  ''"
                                          class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appService.getFirstChar(item.role_name,1)}}</span></span>
                                      <span class="">{{ (item.role_name?.length>15)? (item.role_name |
                                          slice:0:15)+'..':(item.role_name) }}</span>
                                  </div>
                              </ng-template>
                          </ng-select>
                          <div *ngIf="(saveBtnClicked || f.timeattendancesettings_role.touched || f.timeattendancesettings_role.dirty) && f.timeattendancesettings_role.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.timeattendancesettings_role.errors.required">
                              {{this.messageService.validationDisplay('required')}}</div>
                         </div>
                              <ul class="selected-values mt-16" *ngIf="configForm.value.timeattendancesettings_role?.length != 0">
                                  <li class="ng-value" *ngFor="let item of configForm.value.timeattendancesettings_role,let ki =index">
                                      <ng-container *ngFor="let insideitem of roleListarr, let k =index">
                                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                              <div class="d-inline-flex align-items-center">
                                                  <img class=" img-fluid sq-24 rounded-circle"
                                                      *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null"
                                                      src="{{insideitem.logo}}">
                                                  <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''"
                                                      class="  sq-24 avatar-circle rounded-circle me-1 {{insideitem.color_code}}">
                                                      <span>
                                                          {{this.appService.getFirstChar(insideitem.role_name,1)}}</span>
                                                  </span>
                                                  <span class=""> {{ (insideitem['role_name'].length>25)?
                                                      (insideitem['role_name'] |
                                                      slice:0:25)+'..':(insideitem['role_name']) }}</span>
                                              </div>
                                          </span>
                                      </ng-container>
                                      <span class="ng-value-icon right" (click)="clearcommonForm('roles',ki)"
                                          aria-hidden="true">×</span>
                                  </li>
                              </ul>
                          </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="!configForm.valid"
            (click)="validateForm()">SAVE</button>
    </footer>
</form>
