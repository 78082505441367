<div class="vstack gap-8 p-24">
  <ngx-skeleton-loader appearance="line"
      [theme]="{'background-color':'#EAEDEF','height': '20px','width': '100px'}"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line"
      [theme]="{'background-color':'#EAEDEF','height': '12px','width': '100%','margin-top':'16px'}"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line"
      [theme]="{'background-color':'#EAEDEF','height': '12px','width': '100%'}"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line"
      [theme]="{'background-color':'#EAEDEF','height': '12px','width': '100%'}"></ngx-skeleton-loader>
</div>
