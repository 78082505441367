import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  documentsSave(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/document_list/`, body, this.httpOption)

  }

  documentsList(status: any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/document_list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  documentsGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/document_detail/${id}`, this.httpOption)
  }

  documentsUpdate(id: number, body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/document_detail/${id}/`, body, this.httpOption)
  }

  documentsDelete(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}organization/api/v1/document_detail/${id}/`, body, this.httpOption)
  }

  // documentsDelete(id: number) {
  //   return this.http.delete(`${this.API_PATH}organization/api/v1/document_detail/${id}`, this.httpOption)
  // }
  documentsListData(filter:any = ''){
    return this.http.get(`${this.API_PATH}organization/api/v1/document-list-dropdown/?is_active=${filter}&ordering=name`, this.httpOption)
  }

}
