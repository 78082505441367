import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  activeTab = 1;
  menuData   : any = []
  menuList   : any

  constructor(
    public route:ActivatedRoute,
    private appService: AppService,
    private router        : Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(event.url == '/geo-fencing'){
          this.getPermission()
         }
      }
     });
  }

  ngOnInit(): void {
    this.getPermission()
  }



  createMenuList(){
    this.menuData = []
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/geo-fencing')
      this.router.navigate([this.menuData[0].link]);
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/geo-fencing');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }

}
