import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { OverrideService } from 'src/app/@core/services/override.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { AppService } from 'src/app/app.global';
import * as XLSX from 'xlsx';
import { MessageService } from 'src/app/message.global';
import { OverrideComponent } from './override/override.component';

@Component({
  selector: 'app-override-pt-esi-lws-tds',
  templateUrl: './override-pt-esi-lws-tds.component.html',
  styleUrls: ['./override-pt-esi-lws-tds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverridePtEsiLwsTdsComponent implements OnInit {
  activeTab = 1;
  year:any;
  month:any;
  company:any;
  empDD :any[] = [];
  permissions:any = [];
  fileName = '';
  data = [];
  validatedData:any[] = [];
  uploadData:any[] = [];
  fileSize = '';
  invalidFormat = false;
  deleteMsg = "";
  deleteToggle = false;
  componentType = "pt";
  Heading:any[] = ['Employee code', 'Employee name',	'Salary month',	'Salary year',	'Override PT amount',	'Comment']
  errorArray:any = [];
  loader = false;
  selctedData:any = [];
  empCodeList:any = []
  importedRows:any = []
  HistoryData:any = []
  pageSize = 20;
  errorFileName = 'PTError';
  successDataCount = 0;
  failedData:any = [];
  downloadMsg = false
  navLoader = false
  iscompleted = false;
  isDataUpload = 0;
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  gradeList:any = [];
  isProgressStart = false
  chunkLen = 10
  successMsg = ''
  errorMsg = ''
  body = ''
  confirmMsg          = false;
  confirmMsgText      = "";
  percentage:any = 0;
  @ViewChild(OverrideComponent) ChildEsi!: OverrideComponent;
  constructor(
    public appService: AppService,
    public route:ActivatedRoute,
    public sds:SalaryDashboardService,
    private os:OverrideService,
    private notificationService: NotificationService,
    public changeDetectorRef: ChangeDetectorRef,
    public messageService : MessageService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.getPermission();
    this.getEmployeeList();
    this.completedList()
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    this.navLoader = true
    if (changeEvent.nextId === 1) {

    }
    else if (changeEvent.nextId === 2) {
      this.componentType = "pt"
      this.errorFileName = "PTError"
      this.Heading = ['Employee code', 'Employee name',	'Salary month',	'Salary year',	'Override PT amount',	'Comment']
    }
    else if (changeEvent.nextId === 3) {
      this.componentType = "esi"
      this.errorFileName = "ESIError"
      this.Heading = ['Employee code',	'Employee name',	'Salary month','Salary Year',	'Override employee ESI',	'Override employer ESI',	'Comment']
    }
    else  if (changeEvent.nextId === 4) {
      this.componentType = "lwf"
      this.errorFileName = "LWFError"
      this.Heading = ['Employee code',	'Employee name',	'Salary month',	'Salary Year',	'Override employee LWF',	'Override employer LWF',	'Comment']
    }
    else  if (changeEvent.nextId === 5) {
      this.componentType = "tds"
      this.errorFileName = "TDSError"
      this.Heading = ['Emp code',	'Emp name',	'Salary month',	'Salary Year',	'Override TDS amount',	'Override Surcharge amount',	'Override cess amount',	'Comment']
    }
    else  if (changeEvent.nextId === 6) {
      this.componentType = "pf"
      this.errorFileName = "PFError"
      this.Heading = ['Employee code',	'Employee name',	'Salary month',	'Salary Year',	'Override employee PF',	'Override employee pension',	'Comment']
    }
    this.deleteFile()
    this.completedList()
    this.validatedData = [];
    this.uploadData = [];
    this.selctedData = [];
  }

  getEmployeeList(){
    this.sds.getempList(this.company ).subscribe((res: any) => {
        this.empDD = res.body;
        let len = this.empDD.length
        for (let i = 0; i < len; i++) {
          this.empCodeList[this.empDD[i].id] = this.empDD[i].employee_code
          this.empDD[i]['fullname']  = "";
          if(this.empDD[i]['first_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
          if(this.empDD[i]['last_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['last_name'];

          }

    });
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/run-payroll');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  onFileChange(event: any) {
    let evt = event.event;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].type.split('/');
    if(extension[1] =='xlsx' || extension[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: false,cellDates:true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];

        var ws = wb.Sheets[wsname];
        this.fileName = target.files[0].name;
        this.fileSize = this.appService.formatSizeUnits(target.files[0].size)
        this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: false,rawNumbers: false, dateNF:'MMMM YYYY',blankrows:false}));
        this.processData();
        this.changeDetectorRef.detectChanges();

      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }


  processData(){
    if(this.data.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.deleteFile();
      this.loader = false;
    } else {
      var headerRow = this.data[0];

      let success =  JSON.stringify(this.Heading)==JSON.stringify(headerRow)
      if(success == false){
        this.deleteToggle = true;
        this.deleteMsg = this.messageService.invalidExcelFormat();
        this.deleteFile();
        this.loader = false;
      }

    }
  }

  validateData(){
  
    this.loader = true;
    const validateDat =  this.prepareValidateData();

    if(validateDat.length >0){
      this.os.validate({ "component_type": this.componentType,"company" : this.company,"month":this.month,"year":parseInt(this.year) ,"employee_list":validateDat,"override":true}).subscribe((res: any) => {
        this.prepareUploadData(res);
      });
    } else {
      this.deleteToggle = true;
      this.deleteMsg = "The uploaded file do not contain any data for "+this.month+" "+this.year;
      this.deleteFile();
      this.loader = false;
    }

  }
  prepareValidateData(){
    if(this.componentType == 'esi')
      return this.prepareValidateESIData()
    else if(this.componentType == 'pt')
      return this.prepareValidatePTData()
    else if(this.componentType == 'pf')
      return this.prepareValidatePFData()
    else if(this.componentType == 'lwf')
      return this.prepareValidateLWFData()
    else if(this.componentType == 'tds')
      return this.prepareValidateTDSData()
    else
     return []

  }
  prepareValidateTDSData(){
    let validateData:any =[]
    let dataRow:any =[]
      this.data.forEach((item:any, key:any) => {
          if(key != 0 ){
            dataRow ={
              'employee_code' : item[0],
              'month': item[2],
              'year': item[3],
              'upload_employee_amount': !isNaN(parseFloat(item[4]) )?parseFloat(item[4]):0,
              'upload_employer_amount': !isNaN(parseFloat(item[5]) )?parseFloat(item[5]):0,
              'upload_cess': !isNaN(parseFloat(item[6]) )?parseFloat(item[6]):0,
              'comment': item[7]
            }
            this.validatedData.push(dataRow);
            validateData.push(dataRow)
          }
      });
      return validateData;
  }
  prepareValidateLWFData(){
    let validateData:any =[]
    let dataRow:any =[]
      this.data.forEach((item:any, key:any) => {
          if(key != 0 ){
            dataRow ={
              'employee_code' : item[0],
              'month': item[2],
              'year': item[3],
              'upload_employee_amount': !isNaN(parseFloat(item[4]) )?parseFloat(item[4]):0,
              'upload_employer_amount': !isNaN(parseFloat(item[5]) )?parseFloat(item[5]):0,
              'upload_cess':0,
              'comment': item[6]
            }
            this.validatedData.push(dataRow);
            validateData.push(dataRow)
          }
      });
      return validateData;
  }
  prepareValidatePFData(){
    let validateData:any =[]
    let dataRow:any =[]
      this.data.forEach((item:any, key:any) => {
          if(key != 0 ){
            dataRow ={
              'employee_code' : item[0],
              'month': item[2],
              'year': item[3],
              'upload_employee_amount': !isNaN(parseFloat(item[4]) )?parseFloat(item[4]):0,
              'upload_employer_amount': !isNaN(parseFloat(item[5]) )?parseFloat(item[5]):0,
              'upload_cess':0,
              'comment': item[6]
            }
            this.validatedData.push(dataRow);
            validateData.push(dataRow)
          }
      });
      return validateData;
  }
  prepareValidatePTData(){
    let validateData:any =[]
    let dataRow:any =[]
     this.data.forEach((item:any, key:any) => {
         if(key != 0 ){
           dataRow ={
             'employee_code' : item[0],
             'month': item[2],
             'year': item[3],
             'upload_employee_amount': !isNaN(parseFloat(item[4]) )?parseFloat(item[4]):0,
             'upload_employer_amount':0,
             'upload_cess':0,
             'comment': item[5]
           }
           this.validatedData.push(dataRow);
           validateData.push(dataRow)
         }
     });
     return validateData;
  }
  prepareValidateESIData(){
   let validateData:any =[]
   let dataRow:any =[]
    this.data.forEach((item:any, key:any) => {
        if(key != 0 ){
          dataRow ={
            'employee_code' : item[0],
            'month': item[2],
            'year': item[3],
            'upload_employee_amount': !isNaN(parseFloat(item[4]) )?parseFloat(item[4]):0,
            'upload_employer_amount': !isNaN(parseFloat(item[5]) )?parseFloat(item[5]):0,
            'upload_cess':0,
            'comment': item[6]
          }
          this.validatedData.push(dataRow);
          validateData.push(dataRow)
        }
    });
    return validateData;
  }

  prepareUploadData(res:any){
    this.importedRows = []
    res.data.forEach((item:any, key:any) => {
      this.selctedData.push(item.employee_id)
      this.uploadData.push(item)
      if(item.error){
       this.setDataIfError(key)
      }
      this.validateRow(this.uploadData.length-1)
      this.importedRows.push(this.uploadData.length-1)
    })
    this.loader = false;
    this.changeDetectorRef.detectChanges();
  }

  changeSelectedEmployee(data:any){
    
    let index = data.index
    this.selctedData[index]= data.empId;
    this.validatedData[index].employee_code = this.empCodeList[data.empId];
    this.validatedData[index].employee_id = data.empId;

    const validateDat:any = [];
    validateDat.push( this.validatedData[index])
    this.os.validate({ "component_type": this.componentType,"company" : this.company,"month":this.month,"year":parseInt(this.year) ,"employee_list":validateDat,"override":false}).subscribe((res: any) => {
      if(res.data[0] != undefined)
        this.uploadData[index] = res.data[0];
        this.validatedData[index].upload_employee_amount = res.data[0].upload_employee_amount?res.data[0].upload_employee_amount:0
        this.validatedData[index].upload_employer_amount = res.data[0].upload_employer_amount?res.data[0].upload_employer_amount:0
        this.validatedData[index].upload_cess = res.data[0].upload_cess?res.data[0].upload_cess:0
      this.changeDetectorRef.detectChanges();
      if(res.data[0] == undefined || res.data[0].error ){
        this.setDataIfError(index)
       }
       this.validateRow(index)
    });
  }
  validateRow(index:any){
    this.ChildEsi?.validateFieldRow(index,"upload_employee_amount");
    this.ChildEsi?.validateFieldRow(index,"upload_employer_amount");
    this.ChildEsi?.validateFieldRow(index,"upload_cess");
    this.ChildEsi?.validateFieldRow(index,"comment");
  }

  setDataIfError(key:any){
    this.uploadData[key].month = this.validatedData[key].month
    this.uploadData[key].year = this.validatedData[key].year
    this.uploadData[key].employee_code = this.validatedData[key].employee_code
    // this.uploadData[key].employee_name = this.validatedData[key].employee_name
    this.uploadData[key].upload_employee_amount = this.validatedData[key]?.upload_employee_amount && this.validatedData[key]?.upload_employee_amount!="NaN"?this.validatedData[key]?.upload_employee_amount:0
    this.uploadData[key].upload_employer_amount = this.validatedData[key]?.upload_employer_amount && this.validatedData[key]?.upload_employer_amount!="NaN"?this.validatedData[key]?.upload_employer_amount:0
    this.uploadData[key].upload_cess = this.validatedData[key].upload_cess && this.validatedData[key].upload_cess!="NaN"?this.validatedData[key].upload_cess:0
    this.uploadData[key].comment = this.validatedData[key].comment
    this.changeDetectorRef.detectChanges();
  }

  deleteFile(){
    this.fileName = '';
    this.data = [];
    this.fileSize = '';

    var delArrayRev =  this.importedRows.reverse()
    delArrayRev.forEach((item:any, value:any) =>{
      this.deleteaRow(item);
    })
    this.importedRows = []
  }
  deleteaRow(index:any){

    this.selctedData.splice(index, 1);
    this.uploadData.splice(index, 1);
    this.validatedData.splice(index, 1);
  }

  deleteRow(index:any){
    this.deleteaRow(index);
    const importIndex = this.importedRows.indexOf(index);
    if (importIndex > -1) {
      this.importedRows.splice(importIndex, 1);
    }
  }

  checkall(event:any){
    if(event.status == 'PENDING'){
      var newList = this.appService.filterIt(this.uploadData,event.searchString);
    } else {
      var newList = this.appService.filterIt(this.HistoryData,event.searchString);
    }
    var begin = (event.page-1) * this.pageSize ;
    var end = event.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.checked
    })
  }

  addEmployee(){
    let newRow  = {
      'employee_code' : '',
      'month': this.month,
      'year': this.year,
      'upload_employee_amount': 0,
      'upload_employer_amount': 0,
      'upload_cess': 0,
      'employee_overide': 0,
      'employer_overide': 0,
      'cess_overide':0,
      'comment':''
    }
    this.uploadData.push(newRow)
    this.validatedData.push(newRow)
  }

  deleteSingleRow(event:any){
    let index = event.index;
    this.deleteRow(index);
  }
  createfailedData(item:any){
    if(this.componentType == 'esi')
      return this.createESIfailedData(item)
    else if(this.componentType == 'pt')
      return this.createPTfailedData(item)
    else if(this.componentType == 'pf')
      return this.createPFfailedData(item)
    else if(this.componentType == 'lwf')
      return this.createLWFfailedData(item)
    else if(this.componentType == 'tds')
      return this.createTDSfailedData(item)
    else
    return []
  }
  createPTfailedData(item:any){
    return {'Employee code':item.employee_code,'Employee name':item.employee_name,'Salary month':item.month,'Salary Year':item.year,'Override PT amount':item?.upload_employee_amount,'Comment':item.comment,'Error':item.error_description};
  }
  createPFfailedData(item:any){
    return {'Employee code':item.employee_code,'Employee name':item.employee_name,'Salary month':item.month,'Salary Year':item.year,'Override employee PF':item?.upload_employee_amount,'Override employee pension':item.upload_employer_amount,'Comment':item.comment,'Error':item.error_description};
  }
  createLWFfailedData(item:any){
    return {'Employee code':item.employee_code,'Employee name':item.employee_name,'Salary month':item.month,'Salary Year':item.year,'Override employee LWF':item?.upload_employee_amount,'Override employer LWF':item.upload_employer_amount,'Comment':item.comment,'Error':item.error_description};
  }
  createTDSfailedData(item:any){
    return {'Employee code':item.employee_code,'Employee name':item.employee_name,'Salary month':item.month,'Salary Year':item.year,'Override TDS amount':item?.upload_employee_amount,'Override Surcharge amount':item.upload_employer_amount,'Override cess amount':item.upload_cess,'Comment':item.comment,'Error':item.error_description};
  }
  createESIfailedData(item:any){
    return {'Employee code':item.employee_code,'Employee name':item.employee_name,'Salary month':item.month,'Salary Year':item.year,'Override employee ESI':item?.upload_employee_amount,'Override employer ESI':item.upload_employer_amount,'Comment':item.comment,'Error':item.error_description};
  }
  uploadSaveData(){
    this.confirmMsg = false
    this.isProgressStart = true
    this.iscompleted = false
    this.isDataUpload = 1;

    this.failedData = []
    var l = this.countSelected();
    var cLoop = Math.ceil(l/this.chunkLen)
    var p = 100/cLoop
    this.successDataCount = 0
    this.processUpload(0,p);
}
processUpload(k:any,p:any){
  var l = this.countSelected();
  var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
  var saveDataArray:any = [];
  this.percentage +=(p/4);
  this.body = x+' out of '+l+" "+this.componentType.toUpperCase()+" data uploading"
  var uploadDataSelected = this.selectedArray()
  for(var i= k*this.chunkLen; i<x; i++){
    var item = uploadDataSelected[i]
    if(item.checked == true && item.error == false ){
      let upload_cess = 0;
      if(this.componentType=='pf'){
        if(item?.upload_employer_amount > 0){
          upload_cess = item?.cess_regular+item?.employer_regular-item?.upload_employer_amount
        } else {
          upload_cess = item?.cess_regular
        }
      } else {
        upload_cess = item.upload_cess?item.upload_cess:0
      }
      saveDataArray.push({
        "employee" : item.employee_id?item.employee_id:0,
        "employer_component" : item.employer_payslipcomponent_id?item.employer_payslipcomponent_id:0,
        "employee_component" : item.employee_payslipcomponent_id?item.employee_payslipcomponent_id:0,
        "tax_slip" : item.taxslip_id?item.taxslip_id:0,
        "contribution" :this.componentType.toUpperCase(),
        "gross_pay" : item?.gross_pay?item?.gross_pay:0,
        "upload_employee_amount" : item?.upload_employee_amount?item.upload_employee_amount:0,
        "upload_employer_amount" : item?.upload_employer_amount?item.upload_employer_amount:0,
        "upload_cess" : upload_cess,
        "comment" : item.comment?item.comment:"",
        "pay_month" : this.month,
        "pay_year" : this.year,
        "status" : "Processed",
        "is_active" : true,
        'override_employee_amount':item?.employee_overide?item.employee_overide:0,
        'override_employer_amount':item?.employer_overide?item.employer_overide:0,
        'override_cess':item?.cess_overide?item.cess_overide:0,
      })
    }else if(item.checked == true && item.error == true ){
      this.failedData.push(this.createfailedData(item))
    }
  }
  this.os.save({ "component_type": this.componentType.toUpperCase(),"company" : this.company,"month":this.month,"year":parseInt(this.year) ,"employee_list":        saveDataArray}).subscribe((res: any) => {

    this.successDataCount += res.data.length;

    this.percentage +=((p/4)*3);

    if(x<l)
    this.processUpload(k+1,p);
    else{
      this.percentage = '';
      this.iscompleted = true
      this.isProgressStart = false
      if(this.failedData.length > 0){
        this.downloadMsg =true
        // this.successMsg = this.successDataCount+" "+this.componentType.toUpperCase()+" data uploaded successfully";
        // this.errorMsg = this.failedData.length+" error data found"
        this.successMsg = this.successDataCount+" out of "+this.countSelected()+" "+this.componentType.toUpperCase()+" data uploaded successfully "
        this.errorMsg = this.failedData.length+' employee(s) have error data'
      } else {
        this.notificationService.handleSuccessNotification(this.successDataCount+" "+this.componentType.toUpperCase()+" data uploaded successfully.","Success");
      }
      this.bulkDelete();
      this.completedList()
    }


    this.changeDetectorRef.detectChanges();
  },
  (error:any)=>{
    this.isProgressStart = false
    this.notificationService.handleErrorNotification('Something went wrong','Error')

    }

  );
}

  saveData(){
    // this.isProgressStart = true
    // this.iscompleted = false
    // this.isDataUpload = 1;
    this.failedData = []
    const validateDat:any = [];
    this.uploadData.forEach((item:any, key:any) => {
      if(this.validatedData[key] && item?.checked == true)
      validateDat.push( this.validatedData[key])
    })

    this.os.validate({ "component_type": this.componentType,"company" : this.company,"month":this.month,"year":parseInt(this.year) ,"employee_list":validateDat,"override":true}).subscribe((res: any) => {
      this.validateBeforeSave(res);
      // this.loader= false
      this.confirmMsg = true
      this.confirmMsgText ="Click the Save button, If you want to add the "+this.componentType.toUpperCase()+" data for the selected employees.";
      this.changeDetectorRef.detectChanges();
    })

  }
  validateBeforeSave(res:any){
    res.data.forEach((item:any, key:any) => {
      this.uploadData[key]['employee_overide'] =item.employee_overide
      this.uploadData[key]['employer_overide'] =item.employer_overide
      this.uploadData[key]['cess_overide'] =item.cess_overide
      if(item.error && this.uploadData[key].error == false){
        this.uploadData[key].error = true;
        this.uploadData[key].error_description = item.error_description;
      } else if(item.error == false && this.uploadData[key].error == true){
        this.uploadData[key].error = false;
        this.uploadData[key].error_description = item.error_description;
      }
      if(item.error){
        this.setDataIfError(key)
      }

    })
  }
  bulkDelete(){
      this.ChildEsi?.bulkDelete();
  }

  completedList(){
    this.os.list(this.company,this.month,this.year,this.componentType,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,).subscribe((res: any) => {
      this.HistoryData = res.data
      this.loader = false
      this.navLoader = false
      this.changeDetectorRef.detectChanges();
   });
  }
  deleteCompleted(event:any){
    this.loader = true
    this.os.delete({"month":this.month,"year":parseInt(this.year) ,"employee_list":event.data}).subscribe((res: any) => {
      this.completedList()
      this.notificationService.handleSuccessNotification("Selected override data removed successfully.",this.componentType.toUpperCase()+" Data");
   });
  }
  closePanel(){
    this.downloadMsg =false
  }

  resetFilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.completedList();
  }
  submitFilter(event:any){
    let data = event.data
    this.buList = data.buList;
    this.DepList = data.DepList;
    this.DesignationList = data.DesignationList;
    this.gradeList = data.gradeList;
    this.BranchList = data.BranchList;
    this.completedList();
  }

  updateValidatedData(event:any){

    this.validatedData[event.index][event.field] =event.value !=''&& event.value!=null?parseFloat(event.value):0;
    const validateDat:any = [];
    validateDat.push( this.validatedData[event.index])
    this.os.validate({ "component_type": this.componentType,"company" : this.company,"month":this.month,"year":parseInt(this.year) ,"employee_list":validateDat,"override":true}).subscribe((res: any) => {
      if(res.data[0][event.field] != undefined)
        this.uploadData[event.index][event.field] = res.data[0][event.field];
      if(res.data[0]['employee_regular'] != undefined)
        this.uploadData[event.index]['employee_regular'] = res.data[0]['employee_regular'];
      if(res.data[0]['employer_regular'] != undefined)
        this.uploadData[event.index]['employer_regular'] = res.data[0]['employer_regular'];
      if(res.data[0]['cess_regular'] != undefined)
        this.uploadData[event.index]['cess_regular'] = res.data[0]['cess_regular'];
      if(res.data[0]['gross_pay'] != undefined)
        this.uploadData[event.index]['gross_pay'] = res.data[0]['gross_pay'];
      if(res.data[0]['currency'] != undefined)
        this.uploadData[event.index]['currency'] = res.data[0]['currency'];
      if(res.data[0]['error'] != undefined){
        this.uploadData[event.index]['error'] = res.data[0]['error'];
        this.uploadData[event.index]['error_description'] = res.data[0]['error_description'];
      }
      if(res.data[0]['upload_employee_amount'] != undefined)
        this.uploadData[event.index]['upload_employee_amount'] = res.data[0]['upload_employee_amount'];
      if(res.data[0]['upload_employer_amount'] != undefined)
        this.uploadData[event.index]['upload_employer_amount'] = res.data[0]['upload_employer_amount'];
      if(res.data[0]['upload_cess'] != undefined)
        this.uploadData[event.index]['upload_cess'] = res.data[0]['upload_cess'];
      this.changeDetectorRef.detectChanges();
    });
  }

  countSelected(){
      return this.selectedArray().length
  }
  selectedArray(){
    var delArray:any = [];
      this.uploadData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(this.uploadData[value])
      });
      return delArray
  }


}
