import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-letter-template-preview',
  templateUrl: './letter-template-preview.component.html',
  styleUrls: ['./letter-template-preview.component.scss']
})
export class LetterTemplatePreviewComponent implements OnInit {

  loader1   : boolean = false;
  submitted : boolean = false;
  pdfURL    : any     = ''

  @Input() formGp !: FormGroup
  @Input() showTab : any
  @Output() submitMethod = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if(this.showTab){
      this.pdfURL = this.formGp.get('previewURL')?.value
    }
    this.formGp.get('previewURL')?.valueChanges.subscribe((value) => {
      if (value !== null && this.pdfURL === '') {
        this.pdfURL = value;
      }
    });
  }

  validateLetterTemplate(){
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitMethod.emit();
  }

  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
   }
}
