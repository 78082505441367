import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationPolicyService } from 'src/app/@core/services/confirmation-policy.service';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { Location} from '@angular/common';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-confirmation-policy-setup',
  templateUrl: './confirmation-policy-setup.component.html',
  styleUrls: ['./confirmation-policy-setup.component.scss']
})
export class ConfirmationPolicySetupComponent implements OnInit {

  confirmationMainForm    : FormGroup = this.fb.group({
    policySetting         : this.fb.group({
      name                : [null,[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      description         : [null,[Validators.required,Validators.maxLength(500)]],
      probation_period    : [null,[Validators.required,Validators.min(1),Validators.max(1000)]],
      is_terminate        : [true],
      is_extend           : [false],
      extension_period    : [null],
      max_extension       : [null],
      is_edit_extend      : [false],
      is_send_letter      : [false],
      confirmation_letter : [null],
      extension_letter    : [null],
      termination_letter  : [null],
      confirmation_request_days : [null,[Validators.required,Validators.min(7),Validators.max(365)]]
    }),
    confirmation_policy_applicablity : this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]],
      applicablityOn : [true]
    })
  })
  editflag                : boolean   = false
  loader                  : boolean   = false
  isEditForm              : boolean   = false
  tab2touched             : boolean   = false
  disabled                : boolean   = false
  confirmationDropdown    : any       = []
  terminationDropdown     : any       = []
  extensionDropdown       : any       = []

  activeTab = 1;
  id        = 0;
  title     = 'Add'

  constructor(
    private fb          : FormBuilder,
    private confirmServ : ConfirmationPolicyService,
    public route        : ActivatedRoute,
    public router       : Router,
    private previousRoute: PreviousRouteService,
    private _location   : Location
  ) { }

  get policySetting(){
    return this.confirmationMainForm.get('policySetting') as FormGroup
  }

  get confirmation_policy_applicablity(){
    return this.confirmationMainForm.get('confirmation_policy_applicablity') as FormGroup
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
    this.getConfirmationTemplate();
    this.getExtensionTemplate();
    this.getTerminationTemplate();
  }

  backClicked() {
    if(this.previousRoute.getPreviousUrl() == '/home' || this.previousRoute.getPreviousUrl() == '/login')
      this.router.navigate(['confirmation-policy']);
    else
      this._location.back();
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(this.confirmationMainForm['controls'].policySetting.invalid){
        changeEvent.preventDefault();
      }
    }
  }

  // Next Step
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
    if (activeTab === 2) {
      this.tab2touched =true
    }
  }

  getConfirmationTemplate(){
    this.confirmServ.getConfirmationLetters().subscribe((res:any)=>{
      this.confirmationDropdown = res
    })
  }

  getTerminationTemplate(){
    this.confirmServ.getTerminationLetters().subscribe((res:any)=>{
      this.terminationDropdown = res
    })
  }

  getExtensionTemplate(){
    this.confirmServ.getExtensionLetters().subscribe((res:any)=>{
      this.extensionDropdown = res
    })
  }

  submitForm(){
    var applicable = this.confirmation_policy_applicablity.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var applicabilityArr = [];
    for(let key of this.confirmation_policy_applicablity.controls.assignees.value){
      applicabilityArr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }
    let dataArray = {
      ...this.confirmationMainForm.get('policySetting')?.value,
      'confirmation_policy_applicablity' : applicabilityArr
    }

    if(this.id == 0){
      this.confirmServ.createPolicy(dataArray).subscribe((res:any)=>{
        this.router.navigate(['confirmation-policy'])
      })
    }else{
      this.confirmServ.updatePolicy(this.id,dataArray).subscribe((res:any)=>{
        this.router.navigate(['confirmation-policy'])
      })
    }
  }

  // Edit Form
  editForm(){
    this.loader = true;
    this.confirmServ.updateViewPolicy(this.id).subscribe((res:any)=>{
      this.isEditForm = true
      this.title = 'Edit'
      this.editflag = true
      this.setEditForm(res);
      this.loader = false
    },(error)=>{
      this.router.navigate(['confirmation-policy'])
    })
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.confirmationMainForm.disable();
      this.disabled = true;
    }

    let applicableto = "";
    let assignees :any = []
    let appkey ="";

    // Step 2 Applicability
    for(let key of res.confirmation_policy_applicablity){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
      assignees.push(key[appkey]);
      applicableto = key.applicable
    }

    this.confirmationMainForm.reset({
      policySetting : {
        name                : res?.name,
        description         : res?.description,
        probation_period    : res?.probation_period,
        is_terminate        : res?.is_terminate,
        is_extend           : res?.is_extend,
        extension_period    : res?.extension_period,
        max_extension       : res?.max_extension,
        is_edit_extend      : res?.is_edit_extend,
        is_send_letter      : res?.is_send_letter,
        confirmation_letter : res?.confirmation_letter,
        extension_letter    : res?.extension_letter,
        termination_letter  : res?.termination_letter,
        confirmation_request_days : res?.confirmation_request_days
      },
      confirmation_policy_applicablity : {
        applicableto : applicableto,
        assignees : assignees
      }
    })
  }
}
