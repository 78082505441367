import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseSettingService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }

  createsetting(data:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-hr-expense-setting/`, data, this.httpOption)
  }
  getsettingData(status:any,limit:any,offset:any,search:any,order:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-hr-expense-setting/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`, this.httpOption);
  }
  getsettingSearchList(status:any,search:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-hr-expense-settings-dropdwon/?is_active=${status}&ordering=name&search=${search}`, this.httpOption);
  }
  getSinglesettingData(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-hr-expense-settings-detail/${id}`, this.httpOption);
  }
  deletesetting(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}expense/api/v1/expense-hr-expense-settings-detail/${id}/`,data, this.httpOption)
  }
  updatesetting(id:number,data:any){
    return this.http.put(`${this.API_PATH}expense/api/v1/expense-hr-expense-settings-detail/${id}/`,data, this.httpOption)
  }
  companylist(search:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-company-list-dropdown/?ordering=company_name&search=${search}`, this.httpOption);
  }

}
