<form class=""   [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">ADVANCE POLICY</p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Advance policy name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the name of the advance policy." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                <input type="text" formControlName="name" class="form-control" placeholder="{{this.messageService.placeholderdisp('Advance Policy name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.touched || f.name.dirty) &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
                <div *ngIf="(submitted || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                  <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                    <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
  
            </div>
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label required">Advance amount limit for a request</label>
                 <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If 'No limit' is selected, there is no limit for the advance request amount. If 'Specify limit' is selected, you can define the limit for advance requests." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input formControlName="specify_limit"  class="form-check-input"  type="radio" (click)="setRequired(true,'specify_limit');" [value]=true>
                        Specify limit
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input formControlName="specify_limit"  class="form-check-input" (click)="setRequired(false,'specify_limit');" type="radio" [value]=false>
                        No limit
                    </label>
                </div>                
            </div>
            <div class=" col-12 form-row" *ngIf="f.specify_limit.value">
                <label for="" class="form-label required">Amount </label>
                <input type="text" formControlName="amount" class="form-control"
                placeholder="{{this.messageService.placeholderdisp('limit')}}"
                [ngClass]="{ 'is-invalid': (submitted || f.amount.touched || f.amount.dirty) &&  f.amount.errors }">
                <div *ngIf="(submitted || f.amount.touched || f.amount.dirty) && f.amount.errors "
                    class="invalid-feedback">
                    <div *ngIf="f.amount.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                  
                    <div *ngIf="f.amount.errors?.pattern || f.amount?.errors?.min">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="!(f.amount?.errors?.pattern) && f.amount?.errors?.maxlength">    {{this.messageService.fieldlengthvalidation('num','6')}}</div>
                </div>
            </div>
            <div class="col-12">
                <div class=" align-items-center gap-8 fs-14">
                    <input type="checkbox" name="check" id="is_credit_limit" class="form-check-input ms-0" (change)="CheckedOrNot($event)" [checked]="f.is_credit_limit.value">
                    <label for="check " class="form-check-label fs-16">Set outstanding advance credit limit for the employees</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Check this box to define a credit limit for outstanding advances that employees can have at any time, including any unused amounts from previous advance requests." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                </div>
            </div>
            <div class=" col-12 form-row" *ngIf="f.is_credit_limit.value">
                <label for="" class="form-label required">Specify limit </label>
                <input type="text" formControlName="credit_limit" class="form-control"
                placeholder="{{this.messageService.placeholderdisp('limit')}}"
                [ngClass]="{ 'is-invalid': (submitted || f.credit_limit.touched || f.credit_limit.dirty) &&  f.credit_limit.errors }">
                <div *ngIf="(submitted || f.credit_limit.touched || f.credit_limit.dirty) && f.credit_limit.errors "
                    class="invalid-feedback">
                    <div *ngIf="f.credit_limit.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>                  
                    <div *ngIf="f.credit_limit.errors?.pattern || f.credit_limit?.errors?.min">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="!(f.credit_limit?.errors?.pattern) && f.credit_limit?.errors?.maxlength"> 
                        {{this.messageService.fieldlengthvalidation('num','6')}}</div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid || componentError)" (click)="validatepolicysetting()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validatepolicysetting()">NEXT</button>
    </footer>
</form>