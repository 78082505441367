import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrears',
  templateUrl: './arrears.component.html',
  styleUrls: ['./arrears.component.scss']
})
export class ArrearsComponent implements OnInit {
  status = 'PENDING';
  constructor() { }

  ngOnInit(): void {
  }

}
