<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='editDetail === true'>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>{{header}} Expense Category</h5>
            <a class="toggle-panel" (click)="closefn()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <app-c1-loader *ngIf="loader"></app-c1-loader>
                
                <div class="row row-16 ">
                    <ng-container *ngIf="!loader">
                    <div class="col-12">
                        <p class="text-uppercase fw-500 mb-0">EXPENSE CATEGORY</p>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 px-24 pt-16 pb-24">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="title mb-0">EXPENSE TYPE</p>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Expense Type</label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If Amount is selected, employee can directly enter the amount  under the selected expense category. If Mileage is selected, employee needs to enter the mileage and the amount gets calculated." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="col-12 form-row mt-0">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            
                                            <input formControlName="expense_type"  class="form-check-input"  type="radio" [value]=false (click)="setRequired(true,'amount');">
                                            Amount
                                        </label> 
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" > 
                                               <input formControlName="expense_type"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'mileage');">
                                            Mileage
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 px-24 pt-16 pb-24">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="title mb-0">EXPENSE INFORMATION</p>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Expense Category Name </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the name of the Expense category. Ex: Business class- Flight, Train, Bus, Car rentals, Hotel etc." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <input type="text" class="form-control" formControlName="name" name="" id="name"
                                    value="" placeholder="{{this.messageService.placeholderdisp('Expense category name')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.name?.dirty || f.name?.touched)  &&  f.name?.errors || f.name?.errors?.exist}"
                                    (keyup)="keyUp()">
                                    <div *ngIf="(submitted || f.name?.dirty || f.name?.touched) && f.name?.errors || f.name?.errors?.exist"
                                        class="invalid-feedback">
                                        <div *ngIf="f.name?.hasError('exist')">
                                            {{this.messageService.validationDisplay(f.name?.value)}}</div>
                                        <div *ngIf="f.name?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                        <div *ngIf="f.name?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!(f.name?.errors?.pattern) && f.name?.errors?.maxlength">
                                            {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Expense Category Code </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the Code for the expense category Ex: TWE, FWE etc." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <input type="text" formControlName="code_name" class="form-control " [ngClass]="{ 'is-invalid': (f.code_name?.touched || f.code_name?.dirty) && f.code_name?.errors }" placeholder="{{this.messageService.placeholderdisp('Expense category Code')}}"  (keyup)="keyUpcode()">
                                    <div *ngIf=" f.code_name?.errors" class="invalid-feedback">
                                        <div *ngIf="f.code_name?.hasError('exist')">{{this.messageService.validationDisplay(f.code_name?.value)}}</div>
                                    <div *ngIf="f.code_name?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                        <div *ngIf="f.code_name?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!f.code_name?.errors?.pattern && f.code_name?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','10')}}</div>
                                        <!-- <div *ngIf="f.code_name?.errors?.duplicatecode">Shift Code and Shift Name cannot be same.</div> -->
                                    </div>
                                </div>
                                <div class=" col-12 form-row">
                                    <label for="" class="form-label">Description </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Describe in few lines why this expense category is provided." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <input type="text" formControlName="description" class="form-control" placeholder="{{this.messageService.placeholderdisp('description')}}" [ngClass]="{ 'is-invalid': (submitted ||f.description?.touched || f.description?.dirty) &&  f.description?.errors}">
                                    <div *ngIf="(submitted ||f.description?.touched || f.description?.dirty) && f.description?.errors" class="invalid-feedback">
                                        <div *ngIf="!f.description?.errors?.pattern && f.description?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                                        </div>
                                        <div *ngIf="f.description?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="f.expense_type.value">
                        <div class="card card-c2 px-24 pt-16 pb-24">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="title mb-0">MILEAGE DETAILS</p>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Rate per kilometer </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Set rate per kilometer." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <input type="text" formControlName="rate_per_km" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('Rate per kilometer')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.rate_per_km?.touched || f.rate_per_km?.dirty) &&  f.rate_per_km?.errors }">
                                    <div *ngIf="(submitted || f.rate_per_km?.touched || f.rate_per_km?.dirty) && f.rate_per_km?.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.rate_per_km?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                       
                                        <div *ngIf="f.rate_per_km?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                         <div *ngIf="!(f.rate_per_km?.errors?.pattern) && f.rate_per_km?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','6')}}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Do you want to specify distance limit (KM)
                                    </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select 'yes' if you want to Set limit for maximum kilometer that can be entered in expense request." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="col-12 form-row mt-0">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_distance_limited"  class="form-check-input"  type="radio" (click)="setRequired(true,'is_distance_limited');" [value]=true>
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_distance_limited"  class="form-check-input" (click)="setRequired(false,'is_distance_limited');" type="radio" [value]=false>
                                            No
                                        </label>
                                    </div>
                                </div>
                                <ng-container *ngIf="f.is_distance_limited.value">                     
                                <div class="col-12">
                                    <label for="" class="form-label required">Specify Limit </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify the limit amount." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <input type="text" formControlName="limit" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('limit')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.limit?.touched || f.limit?.dirty) &&  f.limit?.errors }">
                                    <div *ngIf="(submitted || f.limit?.touched || f.limit?.dirty) && f.limit?.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.limit?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                      
                                        <div *ngIf="f.limit?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!(f.limit?.errors?.pattern) && f.limit?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','6')}}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label  required">Period for which limit is applicable </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose the time duration for which this limit will be applicable." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <ng-select class="form-ngselect" formControlName="limit_period" id="claim_limit" placeholder="Select period">
                                        <ng-option *ngFor="let limit_periodlist of limit_periodlist" [value]="limit_periodlist.id">
                                          {{limit_periodlist.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="f.expense_type.value==false">
                        <div class="card card-c2 px-24 pt-16 pb-24">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="title mb-0">SET LIMITATION</p>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label ">Amount Limit (INR) </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose an option if expense type is select as amount :
                                        i. Limit for all locations - Set a universal limit for all locations.
                                        ii. Location-dependent limit - Define limits for each location group.
                                        iii. No limit - No maximum amount restriction." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="col-12 form-row mt-0">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="amount_limit_type"  class="form-check-input"  type="radio" [value]="'limit_for_all_location'" (click)="setRequired(true,'limit_for_all_location');">
                                            Limit for all location
                                        </label>                                        
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="amount_limit_type"  class="form-check-input"  type="radio" [value]="'location_dependent_limit'" (click)="setRequired(true,'location_dependent_limit');">
                                            Location dependent limit
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="amount_limit_type"  class="form-check-input"  type="radio" [value]="'no_limit'"  (click)="setRequired(true,'no_limit');">
                                            No limit
                                        </label>
                                    </div>
                                </div>
                                <ng-container *ngIf="f.amount_limit_type.value!='no_limit'">
                                        <ng-container *ngIf="f.amount_limit_type.value=='location_dependent_limit'">
                                        <div class=" col-12 ">
                                            <label for="" class="form-label required"> Location group </label>
                                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Set limit universally for all locations
                                                " popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                             <ng-select
                                                [ngClass]="{ 'is-invalid': (submitted || f.location_group.touched) &&  f.location_group.errors}"
                                                placeholder="{{this.messageService.selectplaceholddisplay('Location group')}}"
                                                formControlName="location_group"
                                                [items]="locationdropdown"
                                                [multiple]="true"
                                                groupBy="selectedAllGroup"
                                                [selectableGroup]="true"
                                                [selectableGroupAsModel]="false"
                                                bindLabel="name"
                                                [closeOnSelect]="false"
                                                bindValue="id"
                                                class="multiselect"                                            
                                                >
                                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                                </ng-template>
                                                <ng-template
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-item$="item$"
                                                    let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                                    {{ item.name }}
                                                </ng-template>
                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value" *ngIf="f.location_group.value ">
                                                        <span class="ng-value-label">{{f.location_group.value.length }} State selected</span>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                            <div *ngIf="(submitted || f.location_group.touched) && f.location_group.errors" class="invalid-feedback">
                                                <div *ngIf="f.location_group.errors?.required">{{this.messageService.validationDisplay('required')}}
                                                 </div>
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="f.location_group.value">
                                            <ul class="selected-values">
                                                <li class="ng-value" *ngFor="let item of f.location_group.value,let ki =index">
                                                    <ng-container *ngFor="let insideitem of locationdropdown, let k =index">
                                                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                            {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>    
                                                    </ng-container>
                                                    <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(ki)" aria-hidden="true">×</span>    
                                                </li>
                                            </ul>
                                
                                        </div>
                                        </ng-container>
                                    <div class="col-12">
                                        <label for="" class="form-label required">Specify Limit </label>
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify the limit amount." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                        <input type="text" formControlName="limit" class="form-control"
                                        placeholder="{{this.messageService.placeholderdisp('limit')}}"
                                        [ngClass]="{ 'is-invalid': (submitted || f.limit?.touched || f.limit?.dirty) &&  f.limit?.errors }">
                                        <div *ngIf="(submitted || f.limit?.touched || f.limit?.dirty) && f.limit?.errors "
                                            class="invalid-feedback">
                                            <div *ngIf="f.limit?.errors?.required">
                                                {{this.messageService.validationDisplay('required')}}</div>
                                            
                                            <div *ngIf="f.limit?.errors?.pattern">
                                                {{this.messageService.validationDisplay('pattern')}}</div>
                                            <div *ngIf="!(f.limit?.errors?.pattern) && f.limit?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','6')}}</div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <label for="" class="form-label required ">Period for which limit is applicable </label>
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose the time duration for which this limit will be applicable." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                        <ng-select class="form-ngselect" formControlName="limit_period" id="claim_limit" placeholder="Select period">
                                            <ng-option *ngFor="let limit_periodlist of limit_periodlist" [value]="limit_periodlist.id">
                                              {{limit_periodlist.name}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 px-24 pt-16 pb-24">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="title mb-0">REQUEST SECTION</p>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Is description mandatory? </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If yes is selected employee needs to mandatorily provide description while raising the expense request. If no is selected, description is not mandatory." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="col-12 form-row mt-0">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_description"  class="form-check-input"  type="radio" [value]=true>
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_description"  class="form-check-input"  type="radio" [value]=false>
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Is attachment mandatory? </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If yes is selected employee needs to mandatorily provide receipt while raising the expense request. If no is selected, receipt is not mandatory." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="col-12 form-row mt-0">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_attachment"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'is_attachment');">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_attachment"  class="form-check-input"  type="radio" [value]=false (click)="setRequired(false,'is_attachment');">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="f.is_attachment.value">
                                    <label for="" class="form-label required">Define minimum amount to make attachment
                                        mandatory? </label>
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Set the minimum expense amount above  which attachment is mandatory to raise expense request." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="col-12 form-row mt-0" *ngIf="f.is_attachment.value">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_attachment_minimum_amount"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'is_attachment_minimum_amount');">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_attachment_minimum_amount"  class="form-check-input"  type="radio" [value]=false (click)="setRequired(false,'is_attachment_minimum_amount');">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="f.is_attachment_minimum_amount.value">
                                    <label for="" class="form-label required">Specify Expense Limit </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify the expense limit, above which attachments become mandatory." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <input type="text" formControlName="attachment_minimum_amount_limit" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('limit')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.attachment_minimum_amount_limit?.touched || f.attachment_minimum_amount_limit?.dirty) &&  f.attachment_minimum_amount_limit?.errors }">
                                    <div *ngIf="(submitted || f.attachment_minimum_amount_limit?.touched || f.attachment_minimum_amount_limit?.dirty) && f.attachment_minimum_amount_limit?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.attachment_minimum_amount_limit?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                        <div *ngIf="f.attachment_minimum_amount_limit?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!(f.attachment_minimum_amount_limit?.errors?.pattern) && f.attachment_minimum_amount_limit?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','6')}}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label ">Can the user file expenses for a date prior to his
                                        date of joining? </label>
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If yes is selected, employee can file expenses prior to the joining date." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                </div>
                                <div class="col-12 form-row mt-0">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_file_expense"  class="form-check-input"  type="radio" [value]=true>
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" >
                                            <input formControlName="is_file_expense"  class="form-check-input"  type="radio" [value]=false>
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="check" class="form-check p-0 d-flex ">
                                        <!-- <input type="checkbox" name="check" id="check " class="form-check-input ms-0"> -->
                                        <input type="checkbox" name="check" id="is_reimbursable" class="form-check-input ms-0" [checked]="f.is_reimbursable.value" (change)="CheckedOrNot($event)">
                                        <label for="check " class="form-check-label fs-16">Reimbursable</label>
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If checked, expense category is reimbursable otherwise it is not reimbursable, employee need to add expense details only for information purpose and amount cannot be claimed." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>      </label>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 px-24 pt-16 pb-24">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="title mb-0">CLAIM FREQUENCY</p>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label required">Claim frequency limit count </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the maximum claims allowed for a period." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <input type="text" formControlName="claim_fequency_limit" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('Claim frequency limit count')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.claim_fequency_limit?.touched || f.claim_fequency_limit?.dirty) &&  f.claim_fequency_limit?.errors }">
                                   <div *ngIf="(submitted || f.claim_fequency_limit?.touched || f.claim_fequency_limit?.dirty) && f.claim_fequency_limit?.errors "
                                    class="invalid-feedback">
                                        <div *ngIf="f.claim_fequency_limit?.errors?.required">              {{this.messageService.validationDisplay('required')}}</div>                                      
                                        <div *ngIf="f.claim_fequency_limit?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!(f.claim_fequency_limit?.errors?.pattern) && f.claim_fequency_limit?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','5')}}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label for="" class="form-label  required">Period for which limit is applicable </label>
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose the time duration for which this limit will be applicable." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    <ng-select class="form-ngselect" formControlName="claim_limit_period" id="claim_limit" placeholder="Select period">
                                      <ng-option *ngFor="let limit_periodlist of limit_periodlist" [value]="limit_periodlist.id">
                                        {{limit_periodlist.name}}
                                      </ng-option>
                                    </ng-select>

                                </div>
                            </div>
                        </div>
                    </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer">
            <!-- <button type="submit" class="  btn btn-outline-primary text-uppercase btn-sm" (click)="closefn()">
                Cancel
            </button> -->
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                [disabled]='!myForm.valid || disable'>
                SAVE
            </button>
        </footer>
    </form>
</div>

<!-- <pre>Form Values: {{myForm.value | json}}</pre> -->