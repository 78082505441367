import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-view-multiple-attachments',
  templateUrl: './view-multiple-attachments.component.html',
  styleUrls: ['./view-multiple-attachments.component.scss']
})

export class ViewMultipleAttachmentsComponent implements OnInit {

  @Input() from:any;
  @Input() viewAttchments:any;
  @Input() uploadedAttachments:any;
  @Input() fileType:any;
  @Input() receiptId:any;
  @Input() isAttachmentRequired:any;

  @Output() closeViewAttachment  = new EventEmitter();
  @Output() saveViewAttachment   = new EventEmitter();

  uploadeAttachments    = false;
  updateAttachmentIndex = 0;

  finalReceiptAttachment:any = [];

  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;

  deleteToggle = false;
  deleteClicked = false;

  showVaidation = false;

  removedIndex = 0;

  constructor(
    public appservice:AppService,
    private service:ExpenseReportRequestService,
    public messageService : MessageService,
    public notificationService : NotificationService,
  ) { }

  ngOnInit(): void {

  }

  closeModal(){
    this.closeViewAttachment.emit();
  }

  saveUpdatedAttachments(){

    if(this.from!='profile'){

      this.service.receiptUpdate(this.receiptId, {'attachments':this.uploadedAttachments}).subscribe((res: any) => {
        this.saveViewAttachment.emit({'attachments': this.uploadedAttachments});
      },(err: any) => {
        // this.disable = false;
      });
    }
    else{
      this.saveViewAttachment.emit({'attachments': this.uploadedAttachments});
    }
  }

  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.showVaidation          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  removeAttachment(index:any){
    if(this.isAttachmentRequired){
      if(this.uploadedAttachments?.length == 1){
        this.showVaidation = true;
        this.investalertMsg = 'Attachment is mandatory for the declared amount as per the policy.';
        this.deleteToggle = false;
      }else{
        if (index !== -1) {
          this.removedIndex = index;
          this.showVaidation = false;
           this.deleteToggle = true;
        }
      }
    }else{
      if (index !== -1) {
        this.removedIndex = index;
        this.deleteToggle = true;
      }
    }

    // if(this.from=='profile'){
    // this.saveViewAttachment.emit({'attachments': this.uploadedAttachments});
    // }


  }

  closeValidationPopup(bool:any){
    this.showVaidation = false;
  }

  close(bool:any){
    this.modalToggle = bool;
  }

  // Upload Attachment Section

  addAttachmentFunction(){
    this.uploadeAttachments = true;

    // test start
    if(this.uploadedAttachments?.length > 0){
      for(let i=0;i<this.uploadedAttachments?.length;i++){
        this.finalReceiptAttachment?.push(this.uploadedAttachments[i]?.['attachment'])
      }
    }
    // test end
  }

  saveAttachment(data:any){
    this.uploadeAttachments = false;
    if(this.from!='profile'){
      this.getSingleReceiptData();
    }else{
      let newFile = [];
      for(let i=0;i<data.attachments.length;i++){
        let attachment = {'attachment' :data.attachments[i]}
        newFile.push(attachment)
      }
      const c = [...this.uploadedAttachments, ...newFile];
      this.uploadedAttachments = [...new Set(c)];
      this.saveUpdatedAttachments();
    }

  }

  getSingleReceiptData(){
    this.uploadedAttachments = [];
    this.service.getSingleReceiptData(this.receiptId).subscribe((res:any)=>{
      this.uploadedAttachments = res?.attachments;
    })
  }

  closeAttachment(){
    this.uploadeAttachments = false;
  }

  deleteAttachment(){
    this.deleteToggle = false;
    this.uploadedAttachments?.splice(this.removedIndex, 1);
    this.service.receiptUpdate(this.receiptId, {'attachments':this.uploadedAttachments}).subscribe((res: any) => {
      // this.saveAttachment.emit({'attachments': this.finalReceiptAttachment});
      this.notificationService.handleSuccessNotification("Deleted successfully.","Success");
    });
    
  }

}
