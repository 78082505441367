import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { CityService } from 'src/app/@core/services/city.service';
import { MessageService } from 'src/app/message.global';


@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent implements OnInit {

  constructor( private cityService: CityService,
    public messageService : MessageService,
   ) { }

  @Input() commonFilter : any;
  @Input() filterpanel : any;
  countries:any = [];
  
  @Output() filterDone = new EventEmitter();
  @Output() filterPanel  = new EventEmitter();
  @Output() resetFilter =new EventEmitter();
  status:any = true;
  statusFilter = ['All','Active','Deleted']
  states:any = [];

  ngOnInit(): void {
    this.getCountryList()
  }
  // changeUppercase(item:any){
  //   let elem = item.charAt(0).toUpperCase() + item.slice(1)
  //   return elem;
    
  // }

  countryChange(){
    this.states = [];
    if(this.commonFilter.value.country.length !=0){
      this.cityService.stateList(this.commonFilter.value.country).subscribe((res: any) => {
      this.states = res;
      // this.selectAllForDropdownItems(this.states)
    });
  }
  else{
    this.commonFilter.get('state').reset('')
    this.states = [];
  }
  }
 

  close(){
    this.filterpanel = false
    this.filterPanel.emit(this.filterPanel)
  }
  applyFilter(){
    if(this.commonFilter.value.status == 'Active'){
      this.status = true;
    }
    else if(this.commonFilter.value.status == 'Deleted'){
      this.status = false;
    }
    else{
      this.status = ''
    }
    this.filterDone.emit({stat:this.status,country:this.commonFilter.value.country,state:this.commonFilter.value.state})
    this.filterpanel = false
    
  }

  ResetFilter(){
    this.filterPanel.emit(this.filterPanel)
    this.commonFilter.get('status')?.reset(null)
    this.commonFilter.get('country')?.reset('')
    this.commonFilter.get('state')?.reset('')
    this.resetFilter.emit({status: true})
    
  }

   // Select All
   selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  getCountryList(): void {
    this.cityService.countryList().subscribe((res: any) => {
      this.countries = res;
      this.selectAllForDropdownItems(this.countries);
     
    });
  }
 
  clearcommonForm(item:any,index:any){
    if(item == "country"){
      let daata = this.commonFilter.value.country;
      daata.splice(index, 1);
      this.commonFilter.patchValue({
        country: daata,
        
      });
      this.getCountryList();
    }
    else if(item == "state"){
      let daata = this.commonFilter.value.state;
      daata.splice(index, 1);
      this.commonFilter.patchValue({
        state: daata
      });
      
    }
}

}
