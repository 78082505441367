import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-import-upload',
  templateUrl: './import-upload.component.html',
  styleUrls: ['./import-upload.component.scss']
})
export class ImportUploadComponent implements OnInit {
  @Input() fileName = '';
  @Input() fileSize = '';
  @Input() title = '';
  @Input() heading = '';
  @Input() btnText = '';

  @Input() instructions = '';

  @Input() showSidePanel = false;
  @Input() invalidFormat = false;
  @Input() submiited = false;

  @Output() fileChange =  new EventEmitter<{event: any}>();
  @Output() deleteFile =  new EventEmitter;
  @Output() validateData = new EventEmitter;
  @Output() closePanel = new EventEmitter;
  constructor(public messageService : MessageService) { }

  ngOnInit(): void {
  }

  deleteUploadedFile(){
    this.deleteFile.emit()
  }

  onFileChange(evt: any) {
    this.fileChange.emit({event:evt});
  }
  closeSidePanel(){
    this.closePanel.emit()
  }


}
