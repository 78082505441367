import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { ShortLeaveService } from 'src/app/@core/services/short-leave.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-short-leave-policy',
  templateUrl: './short-leave-policy.component.html',
  styleUrls: ['./short-leave-policy.component.scss']
})
export class ShortLeavePolicyComponent implements OnInit {

  // Added services needed.
  constructor(
    private shortService : ShortLeaveService,
    public msgService    : MessageService,
    public appService    : AppService,
    public router        : Router
  ) { }

  // Var Initialization
  permissions   : any     = [];
  searchString  : string  = this.msgService.searchdisplay('short leave policy');
  searchKeyword : any     = ''
  statusBtn     : any     = "Active";
  loader        : boolean = false
  status        : boolean = true
  policyData    : any     = [];
  listLength    : any     = 0;
  policyDataList: any     = [];
  policyDropdown: any     = [];
  infinityloader: boolean = false;
  direction     : any     = ''
  viewDetail    : boolean = false;
  vLoader       : boolean = false;
  viewData      : any
  viewId        : number  = 0;
  noData        : boolean = false;
  deleteId      : any     = 0;
  deleteAlert   : boolean = false;
  deleteClicked : boolean = false;
  isLoading = false
  searchData : Subject<void> = new Subject();

  // Pagination
  limit        : number  = 25;
  offset       : number  = 0;

  // Getting SearchBar
  @ViewChild(SearchbarComponent) search : any;

  ngOnInit(): void {
    // Fn call for permission
    this.getPermission();
    // Fn call for main API
    this.getPolicyDetails();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.shortService.getShortPolicies(this.status,this.limit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  // Get Policy
  getPolicyDetails(){
    this.loader = true
    this.shortService.getShortPolicies(this.status,this.limit,this.offset,this.searchKeyword).subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    let result = res?.body.results
    this.policyDataList = result
    this.listLength = result?.length
    if(this.offset == 0){
      this.policyData = [];
    }
    this.infinityloader = false
    this.addItems(this.listLength);
    this.loader = false;
    this.noData = res?.body.count == 0 ? true : false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.policyData.push(this.policyDataList[i]);
    }
  }

  // Single Data Search
  singleDataSearch(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.policyData = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  // Keyword Based Search
  fullDataSearch(data:any){
    this.loader = true;
    this.isLoading = true;
    this.offset            = 0;
    this.policyData = [];
    this.searchKeyword = data;
    if(data != ""){
      this.searchData.next()
    }
  }

  // Status Filter
  selectItemList(value:any){
    this.loader = true;
    this.status = value;
    this.offset = 0;
    this.policyData = [];
    this.search.clearBar();
  }

  // No Data Fn
  addNewdata(){
    this.router.navigate(['/short-leave/short-leave-policy-setup']);
  }

  // Scroll Infinity
  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.limit;
    if(this.listLength){
      this.getPolicyDetails();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  // View Policy
  assignees : any = []
  viewPolicy(id:any){
    this.viewDetail = true
    this.vLoader = true
    let appkey1 = []
    this.assignees = []
    this.shortService.getSingleShortPolicy(id).subscribe((res:any)=>{
      this.viewData = res?.body
      // this.viewData.total_short_leave_hours = this.viewData?.total_short_leave_hours
      this.viewId = id
      // Applicability
      let key = res?.body.applicability?.applicable;
      this.assignees = res?.body.applicability[key];
      if(key == 'BU'){
        res.body.applicability['applicable']='Business Unit';
      }
      this.vLoader = false
    })
  }

  // Edit Policy Navigation
  editPolicy(id:number){
    this.router.navigate(['/short-leave/short-leave-policy-setup/'+id]);
  }

  // Delete Policy
  deletePolicy(id:number){
    this.deleteClicked = true
    let data = { "is_active": false};
    this.shortService.deleteShortPolicy(id,data).subscribe((res: any) => {
      this.deleteClicked = false;
      this.deleteAlert   = false;
      this.loader        = true;
      this.offset        = 0;
      this.policyData = [];
      this.getPolicyDetails();
    },(error:any)=>{
      this.deleteClicked = false;
      this.deleteAlert   = false;
    })
  }

  // Permission Setup
  getPermission(){
    this.permissions = this.appService.getPermissions('/short-leave');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
