import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { tickStep } from 'd3';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { SalaryProcessService } from 'src/app/@core/services/salary-process.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-process-salary',
  templateUrl: './process-salary.component.html',
  styleUrls: ['./process-salary.component.scss']
})
export class ProcessSalaryComponent implements OnInit {
  viewBreakup = false;
  viewBreakUpDetails: any;
  gross_benefits:any;
  other_benefits:any;
  contributions:any;
  recurring_deductions:any;
  componentLoader = true;
  filter  = false;
  page = 1;
  pageSize =20;
  items: any[] = [];
  checkboxes: boolean[] = [];
  allchecked:boolean[] = [];
  downloadMsg = false;
  alertToggle = false;
  searchString:any = "";
  year:number=0;
  month:any;
  message = "";
  empalertMsg = "";
  flag = false;
  // total: any;
  processText = "Process";
  modelText:any;
  status = "Unprocessed"
  selectedList:any = [];
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  is_active = true;
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  loader=true
  company:any;
  businessunit:any;
  department:any;
  designation:any;
  branch:any;
  grade:any;
  startDate=""
  endDate=""
  statusList:any = ['Processed','Unprocessed'];
  errorLog        = false;
  exportData:any  = [];
  saveData:any;
  selectedCount = 0;
  inputdata :any
  saveComplete = false;
  errorData:any  = []
  successMsg = ''
  errorMsg = ''
  errorList:any = []
  progress=0
  body = ''
  startProgress = false
  savedataCount:any;
  filename:any;
  modelHeading:any;

  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(public route:ActivatedRoute,public sds:SalaryDashboardService,private notificationService: NotificationService,
    public messageService : MessageService,
    public fb:FormBuilder,
    public router: Router,public appService:AppService,
    private SalaryProcessService:SalaryProcessService) {

  }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      // this.sds.getProcessList(this.year,this.month,'Unprocessed',this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{
      //  this.items = res.body.data;
      //   this.loader = false
      // });
      this.sds.getDateRangeSlider(this.company).subscribe((res:any)=>{

        res.body.data.forEach( (value:any, key:any)=>{

          if(value.payroll_month == this.month && value.year == this.year){
            this.defStartEndDate(value);
          }
        })
        this.sds.getProcessList(this.year,this.month,'Unprocessed',this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.startDate,this.endDate).subscribe((res:any)=>{
          this.items = res.body.data;
           this.loader = false
         });
      });
    })
  }

  updateList (status:any){
    this.loader       = true;
    this.status       = status;
    this.searchString = "";
    this.page = 1

    this.sds.getProcessList(this.year,this.month,status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList,this.startDate,this.endDate).subscribe((res:any)=>{
      this.items = res.body.data;

      this.selectedList = []
      if(status == 'Processed'){

        this.processText = "Unprocess";
        this.modelText   = "unprocessed";
      }
      else{

        this.processText = "Process";
        this.modelText   = "processed";

      }
      this.loader = false;
    });
  }
  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  filterIt() {
    return this.items.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  filteredcount(){
    return this.filterIt().length;
  }
  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every(item => {
        return item.checked;
      });
    } else {
      return false;
    }
  }

  createSelectedLIst(){
    this.SalaryProcessService.getProcessData().subscribe((res:any) => {
      if(res.data.percentage>0 && res.data.percentage<100){
        this.notificationService.handleErrorNotification("Salary processing cannot be done until the existing salary process is completed.","Salary process");
      } else {
        this.selectedList = [];
        this.saveComplete = false;

        if(this.status == 'Processed'){
          this.items.forEach((item:any, key:any) => {
            if(item.checked == true){
              if(item.status == 'Freeze' || item.status == 'Hold' ||  item.status == 'Publish'){
                this.selectedList.push({"id":item.id,
                "status":item.status});
                this.flag = true;
              }
              else if(item.status == 'Processed'){
                this.selectedList.push({"id":item.id,
                "status":"Unprocessed"});
              }
            }
          });
        }else{
          this.items.forEach((item:any, key:any) => {
            if(item.checked == true){
                this.selectedList.push({"id":item.id,
                "status":"Processed"});
            }
          });
        }
        if(this.selectedList.length<=0){
          this.notificationService.handleErrorNotification("Select Employees before "+this.processText+" Salary","Select Employees");
        }
        else  {
          if(this.flag == true){
            this.empalertMsg = "The selected employee(s) payroll has already been Freezed/Held/Published. Kindly undo the respective action to process/unprocess the salary";
            this.alertToggle = true;
            this.flag = false;
          }
          // if(this.status == 'Processed' || this.status == 'Unprocessed'){
            var list:any = [];
            list = this.selectedList.filter((item:any)=>{

              if(this.statusList.includes(item.status)){
                return item;
              }
            })
            this.selectedList = list;
            if(this.selectedList.length > 0){

              this.message ="Click the " +this.processText+ " button, If you want to " +this.processText+ " the salaries for the selected employees.";
              this.downloadMsg = true;
              this.flag = false;
            }
          // }
          // else {
          //   this.processSalary();
          // }

        }
      }
    })

  }

  defStartEndDate(value:any){

    let startYear = value.year;
    if(value.end_month=='January' && value.start_month=='December')
     startYear = value.year - 1;
    this.startDate = startYear+"-"+this.months[value.start_month]+"-"+this.pad(value.start_day)
    this.endDate   = value.year+"-"+this.months[value.end_month]+"-"+this.pad(value.end_day)
  }
  pad(d:number) {
      return (d < 10) ? '0' + d.toString() : d.toString();
  }


  processSalary(){
    if(this.status =='Processed')
      this.loader       = true;
    var dataArray = {
      "year": Number(this.year),
      "month": this.months[this.month],
      "start_date": this.startDate,
      "end_date": this.endDate,
      "employee_list":this.selectedList,
      "company":this.company,
      "is_fnf": false
   };
   this.saveComplete = true;
   this.downloadMsg = false;
   if(this.status !='Processed'){

    this.sds.setProcessData(this.selectedList.length,this.router.url,"Process")
    this.startProgress = true;this.showProgress = false
    this.startProgressBar()
  }

    this.sds.saveProcessList(dataArray).subscribe((res: any) => {
      if(this.status =='Processed')
        this.updateList(this.status);
      this.downloadMsg = false;
      var status:any;
      if(this.status =='Processed')
        status = 'unprocessed';
      else
      status = 'processed';

      if(this.status =='Processed'){

        if(res.body.error.length==0 )
            this.notificationService.handleSuccessNotification("Selected employee's salary "+status +" successfully.","Salary "+status);
        else
          this.errorPopup(res.body.error)
      }

    },
    (err: any) => {
      this.downloadMsg = false;
    })
  }
  errorPopup(data:any){
    if(this.status !='Processed')
      this.updateList(this.status);

    this.errorLog = true;
    this.saveData = data;
    this.exportData = []
    this.savedataCount = this.status =='Processed'?this.selectedList.length-this.saveData?.length:this.sds.PROCESSCOUNT-this.saveData?.length
    let totalCount     = this.status =='Processed'?this.selectedList.length:this.sds.PROCESSCOUNT
    this.filename      = "Salary " +this.processText+ " Error"
    this.modelHeading  = this.processText+" Salary";
    if(this.saveData?.length>0){
      for (let i = 0; i < this.saveData.length; i++) {
        this.exportData.push({'Employee code':this.saveData[i]['employee_code'], 'Employee name':this.saveData[i]['employee_name'], 'Error Description':this.saveData[i]['error_list'][0]['description']});
      }
      this.successMsg = this.savedataCount+" out of "+totalCount+" employee(s) salary processed successfully"
      this.errorMsg =this.saveData?.length+' employee(s) have error data'
    } else
    this.successMsg = this.savedataCount+" out of "+totalCount+" employee(s) salary processed successfully"

  }
  // downloadError(){

  //   for (let i = 0; i < this.saveData.error_list.length; i++) {
  //     this.exportData.push({'Employee code':this.saveData.error_list[i]['employee_code'], 'Employee name':this.saveData.error_list[i]['employee_name'], 'Error Description':this.saveData.error_list[i]['error_list'][0]['description']});
  //   }
  //   this.appService.exportExcel(this.exportData,this.processText+' Error');

  //   this.errorLog = false;
  //   this.sds.PROCESSURL = "";
  //   this.sds.PROCESSCOUNT = 0;

  // }
  viewBreakUp(id:any, status:any){
    this.componentLoader = true;
    this.sds.getViewBreakupDetails(id,this.year,this.month,status).subscribe((res:any)=>{
      if(res){
        this.viewBreakUpDetails = res.body;
        // this.total = (this.viewBreakUpDetails.total_earnings[0].contribution_total + this.viewBreakUpDetails.total_earnings[0].gross_benefits + this.viewBreakUpDetails.total_earnings[0].other_benefits) - this.viewBreakUpDetails.total_earnings[0].recurring_deductions;
           this.viewBreakup = true;
           this.componentLoader = false;
      }
    })

  }
  resetFilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.updateList(this.status);
    this.filter  = false
  }
  submitForm(data:any){
    this.filter  = false
    this.filtercomp.getBUList(this.company);
    this.buList = data.buList;
    this.DepList = data.DepList;
    this.DesignationList = data.DesignationList;
    this.gradeList = data.gradeList;
    this.BranchList = data.BranchList;
    this.updateList(this.status);
  }
  closeFilter(){
    this.filter  = false
  }
  selectAll(){
    this.inputdata = this.appService.selectAll(this.items)

  }
  unselectAll(){
    this.inputdata = this.appService.unselectAll(this.items)


  }
  countSelected(){
    return this.items.filter(obj => obj.checked === true).length;
  }
  closeInfo(bool:any){
    this.alertToggle = bool;
  }
  checkProgress(){
    if(this.startProgress){
      this.SalaryProcessService.getProcessData().subscribe((res:any) => {

        this.errorList =res.data;
        this.progress = res.data.percentage;
        if(this.sds.PROCESSURL == this.router.url && res.data.percentage==100){
          this.startProgress = false;
          this.errorPopup(res.data.error_list);

        }
      });
    }
  }
  showProgress = false
  progPercentage:any = 0
  isProgressStart = false
  timeLeft:any = ''
  iscompleted = false
  startProgressBar(){
    let cnt = 0;
    this.timeLeft = "0 min 0 sec"
    this.sds.setProgress(false);
    this.SalaryProcessService.processPercentage.subscribe(result =>{
      this.showProgress = true
      this.isProgressStart = true
      this.progPercentage = result.event.percentage
      this.body =  result.event.uploaded_count+" out of "+result.event.employee_count+" employee(s) salary processed"
      if(result.event.percentage == 100 && cnt == 0){
        this.timeLeft = "0 min 0 sec"
        this.isProgressStart = false
        this.iscompleted =true
        cnt++
        this.checkProgress()

      }
      else{
        this.convertMinSecs(result.event.time_left)
      }
    },
    (error:any)=>{
      this.closeProgress();
      this.notificationService.handleErrorNotification('Something went wrong','Error')

    })
  }


  convertMinSecs(min:any){
    let time = min*60;
    var minutes = Math.floor(time / 60);
    var seconds:any = time - minutes * 60;
    seconds = seconds.toFixed(2)
    this.timeLeft = minutes+" min "+seconds+" sec "
  }
  closeProgress(){
    this.sds.setProgress(true);
    this.isProgressStart=false;
    this.startProgress = false;
  }
}
