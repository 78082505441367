<form [formGroup]="form">
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='regenerate === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5 *ngIf="Viewdata?.status !='Unpublished' && Viewdata?.status!='Pending' && from!='inbox'">Regenerate </h5>
      <h5 *ngIf="(Viewdata?.status =='Unpublished' || Viewdata?.status=='Pending') || (from=='inbox')">Generate </h5>
      <a class="toggle-panel" (click)="closefn();"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <ng-container *ngIf="!loader1">
         <div class="row row-16">
            <div class="col-12"> 
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">EMPLOYEE DETAILS</p>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Name</div>
                    <div class="detail-desc">{{Viewdata?.employee?.first_name}} {{Viewdata?.employee?.middle_name?Viewdata?.employee?.middle_name:''}}  {{Viewdata?.employee?.last_name}} </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Employee code</div>
                    <div class="detail-desc">{{Viewdata?.employee?.employee_code}}</div>
                  </div>
                <ng-container *ngIf="from!='inbox'">
                  <div class="col-sm-6 col-12">
                      <div class="detail-title">Company</div>
                      <div class="detail-desc">{{Viewdata?.employee?.company}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                      <div class="detail-title">Department</div>
                      <div class="detail-desc">{{Viewdata?.employee?.department}}</div>
                  </div>
                </ng-container>

                <ng-container *ngIf="from=='inbox'">
                  <ng-container *ngIf="reqtype !='Confirmation Letter' && reqtype !='Termination Letter' && reqtype !='Probation Extension Letter'">
                    <div class="col-sm-6 col-12">
                        <div class="detail-title">Company</div>
                        <div class="detail-desc">{{Viewdata?.employee?.company}}</div>
                    </div>
                    <div class="col-sm-6 col-12">
                        <div class="detail-title">Department</div>
                        <div class="detail-desc">{{Viewdata?.employee?.department}}</div>
                    </div>
                  </ng-container>
                  <ng-container  *ngIf="reqtype =='Confirmation Letter'">
                      <div class="col-sm-6 col-12">
                          <div class="detail-title">Date of joining</div>
                          <div class="detail-desc">{{Viewdata?.date_of_joined}}</div>
                      </div>
                      <div class="col-sm-6 col-12">
                          <div class="detail-title">Date of confirmation</div>
                          <div class="detail-desc">{{Viewdata?.date_of_confirmation}}</div>
                      </div>
                  </ng-container>
                  <ng-container  *ngIf="reqtype =='Probation Extension Letter'">
                      <div class="col-sm-6 col-12">
                          <div class="detail-title">Date of joining</div>
                          <div class="detail-desc">{{Viewdata?.date_of_joined}}</div>
                      </div>
                      <div class="col-sm-6 col-12">
                          <div class="detail-title">Extension period(in days)</div>
                          <div class="detail-desc">{{Viewdata?.confirmation_request?.extension_period}}</div>
                      </div>
                      <div class="col-12">
                          <div class="detail-title">Reason for probation extension</div>
                          <div class="detail-desc">{{Viewdata?.confirmation_request?.reason}}</div>
                      </div>
                  </ng-container>
                  <ng-container  *ngIf="reqtype =='Termination Letter'">
                      <div class="col-sm-6 col-12">
                          <div class="detail-title">Date of joining</div>
                          <div class="detail-desc">{{Viewdata?.date_of_joined}}</div>
                      </div>
                      <div class="col-sm-6 col-12">
                          <div class="detail-title">Reason for Termination</div>
                          <div class="detail-desc">{{Viewdata?.confirmation_request?.reason}}</div>
                      </div>
                  </ng-container>
                </ng-container>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 d-flex">
                    <p class="text-uppercase fw-500 mb-0">Letter Details</p>
                  </div>
                  <div class="col-12">
                    <label for="" class="form-label required">Letter </label>
                    <input [readonly]="true" [attr.disabled]="true" type="text" class="form-control" autocomplete="off" formControlName="letter_template"
                    >
                  </div>
                  <div class="col-12 d-flex align-items-center gap-8">
                    <div class="link-primary1 fs-14 d-flex align-items-center" (click)="viewDocument(templatedata?.letter_template_pdf)">
                      <i class="icon-eye fs-16 me-8"></i>Preview Template
                    </div>
                    <span class="text-light-400">|</span>
                    <div class="link-primary1 fs-14 d-flex align-items-center" (click)="changeletter=true;changeletterkey = ''">
                      Change Letter
                    </div>
                  </div>        
                  <!-- *ngIf="Viewdata?.policy_send_letter"         -->
                  <div class="col-12">
                    <label for="check" class="form-check p-0 d-flex ">
                      <input type="checkbox" name="check" id="check " class="form-check-input ms-0" formControlName="is_send_letter"  [checked]="f.is_send_letter.value" (change)="CheckedOrNot($event)">
                      <label for="check " class="form-check-label fs-16">Do you want to share letter on email id?</label>
                    </label>
                  </div>
                  <div class="col-12" *ngIf="sendemail">
                    <label for="" class="form-label required"> Email ID</label>
                    <input type="email" formControlName="email" class="form-control" id="name"
                    placeholder="{{this.messageService.placeholderdisp('Email')}}"
                    [ngClass]="{ 'is-invalid': ( f.email.touched || f.email.dirty) && (f.email.errors ) }"                    >
                      <div *ngIf="(f.email.touched || f.email.dirty) && (f.email.errors ) "
                        class="invalid-feedback">
                        <div *ngIf="f.email.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="f.email.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                        </div>
                      </div>
                  </div>
                  <div class=" col-12 form-row">
                    <!-- <label for="" class="form-label required">Add comment </label> -->
                    <textarea class="form-control" name="remarks" id="remarks" rows="3" formControlName="remarks" [ngClass]="{ 'is-invalid': (f.remarks.dirty || f.remarks.touched) && (f.remarks.errors)}" placeholder="Write comment here..."></textarea>
                    <div *ngIf="f.remarks.errors" class="invalid-feedback">
                      <!-- <div *ngIf="f.remarks.errors?.required">{{this.messageService.validationDisplay('required')}}</div> -->
                      <div *ngIf="f.remarks.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="form.invalid || disablebtn" (click)="regeneratefn(Viewdata?.id,'generated')"  *ngIf="(Viewdata?.status =='Unpublished' || Viewdata?.status=='Pending') || (from=='inbox')"> Generate & Publish 
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="form.invalid || disablebtn" (click)="regeneratefn(Viewdata?.id,'regenerated')"  *ngIf="Viewdata?.status !='Unpublished' && Viewdata?.status!='Pending' && from!='inbox'"> Regenerate & Publish
      </button>
    </footer>
  </div>
</div>
</form>
<div class="modal  modal-alert {{changeletter == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Change Letter</h4>
        </div>
        <div class="modal-body">
          <ng-container [formGroup]="formchange" >
            <div class="col-12">
            <label for="" class="form-label required">Letter </label>
              <ng-select class="form-ngselect" formControlName="templatechange" id="template" placeholder="{{messageService.selectplaceholddisplay('letter template')}}" (change)="changeletterevent($event)">
                <ng-option *ngFor="let template of templatelist" [value]="template">
                  {{template?.name}}
                </ng-option>
              </ng-select>
          </div>
        </ng-container>
       </div>
        <div class="modal-footer">        
          <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="changeletterkey == '' || changeletterkey == undefined " (click)="changeletterfn();">Submit</button>
            <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="cancelletter();">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
    [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName">
  </app-view-file>