<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="punchTime" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0 required">Attendance Period</p>
                    </div>
                    <!-- <div class="col-12 form-row">
                        <label for="fromdate" class="form-check-label">From date</label>
                    </div> -->
                    <div class="col-12 form-row " [ngClass]="{ 'is-invalid': (submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f.from_date.errors}">
                        <div class="input-group form-icon icon-right">
                            <input (click)="datePicker1.open()" class="form-control"
                                placeholder="{{this.messageService.selectplaceholddisplay('from date')}}" matInput
                                formControlName="from_date" [matDatepicker]="datePicker1" [matDatepickerFilter]="salFilter"
                                [ngClass]="{ 'is-invalid': (submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f.from_date.errors}" (dateInput)="toDateChange()">
                            <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                            <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                        </div>
                    </div>
                    <div *ngIf="(submitted || f?.from_date?.touched || f?.from_date?.dirty) && f.from_date.errors" class="invalid-feedback">
                        <div *ngIf="f.from_date.errors.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>

                    <!-- <div class="col-12 form-row">
                        <label for="todate" class="form-check-label">To date</label>
                    </div> -->
                    <div class="col-12 form-row " [ngClass]="{ 'is-invalid': (submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f.to_date.errors}">
                        <div class="input-group form-icon icon-right">

                            <input (click)="datePicker2.open()" class="form-control"
                                placeholder="{{this.messageService.selectplaceholddisplay('to date')}}" matInput
                                formControlName="to_date" [matDatepicker]="datePicker2" [matDatepickerFilter]="salFilter"
                                [ngClass]="{ 'is-invalid': (submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f.to_date.errors}" [min]="f?.from_date?.value">
                            <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                            <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                        </div>
                    </div>

                    <div *ngIf="(submitted || f?.to_date?.touched || f?.to_date?.dirty) && f.to_date.errors" class="invalid-feedback">
                        <div *ngIf="f.to_date.errors.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                  </div>
                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitpunchTime()">
                Apply
            </button>
        </footer>
    </form>
</div>
