<div class="config-layout">
    <header class="config-header">
        <h5 class="m-0">{{title}} Short Leave Policy</h5>
        <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <ng-container *ngIf="!loader">
      <aside class="config-sidebar">
        <ul ngbNav #nav="ngbNav" class="steps gap-24" [(activeId)]="activeTab" (navChange)="onNavChange($event)">
            <li [ngbNavItem]="1">
                <a ngbNavLink class="step" [class]="{'success': shortForm['controls'].detailsForm.valid || shortForm['controls'].detailsForm.disabled}">
                    <div class="count">1</div>
                    <div class="content">
                        <span>Step 1</span>
                        <h6>Short Leave Policy Details</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-short-leave-type [shortLimit]="shortLimit" [editFlag]="editFlag" [disabled]="disabled" [formGp]="detailsForm" (submitMethod)="submitMethod(2)"></app-short-leave-type>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink class="step" [class]="{'success': shortForm['controls'].applicability.valid || shortForm['controls'].applicability.disabled}">
                    <div class="count">2</div>
                    <div class="content">
                        <span>Step 2</span>
                        <h6>Applicability</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                  <app-common-applicability [heading]="'Applicability'" [disabled]="disabled" [isEditForm]="editFlag" [formGp]="applicabilityForm" (submitMethod)="submitForm()"></app-common-applicability>
                </ng-template>
            </li>
          </ul>
      </aside>
      <main class="config-container">
        <div [ngbNavOutlet]="nav"></div>
      </main>
    </ng-container>
</div>
