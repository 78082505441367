import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-salary-reconciliation',
  templateUrl: './salary-reconciliation.component.html',
  styleUrls: ['./salary-reconciliation.component.scss']
})
export class SalaryReconciliationComponent implements OnInit {
  page = 1;
  pageSize =20;

  year:any;
  month:any;
  activetab="salarysummary"
  salarysummaryList = []
  empsalarysummaryList = []
  previousMonth = '';
  searchString:any = "";
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  loader1 = true
  loader2 = true
  company:any;

  activeTab1 = 1;
  filter  = false;
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  constructor(public route:ActivatedRoute,private sds:SalaryDashboardService,public messageService : MessageService) {
  }
  ngOnInit(): void {
    this.loader1 = true
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }

      const current = new Date(this.month+' 1,'+this.year);
      current.setMonth(current.getMonth()-1);
      this.previousMonth = current.toLocaleString('default', { month: 'long' });
    })
    this.getListData()

  }

  getListData(){
    this.sds.getSalarySummary(Number(this.year),this.months[this.month],this.company,this.buList,this.DepList,this.DesignationList,this.gradeList,this.BranchList).subscribe((res:any)=>{
      this.salarysummaryList = res.body.salary_summary
      this.loader1 = false
    });
    this.sds.getEmployeeSalarySummary(Number(this.year),this.months[this.month],this.company,this.buList,this.DepList,this.DesignationList,this.gradeList,this.BranchList).subscribe((res:any)=>{
      this.empsalarysummaryList = res.body.salary_summary
      this.loader2 = false
    });

  }

  resetFilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.searchString = ''
    this.getListData()
    this.filter  = false
  }
  submitForm(data:any){
    this.filter  = false
    this.filter           = false
    this.buList          = data.buList!=""?data.buList.join(','):[]
    this.DepList         = data.DepList!=""?data.DepList.join(','):[]
    this.DesignationList = data.DesignationList!=""?data.DesignationList.join(','):[]
    this.gradeList       = data.gradeList!=""?data.gradeList.join(','):[]
    this.BranchList      = data.BranchList!=""?data.BranchList.join(','):[]
    this.searchString = ''
    this.getListData()
  }
  closeFilter(){
    this.filter  = false
  }

  onNavChange(e:any){
    this.searchString = ''
  }

}
