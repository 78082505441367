import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LetterTemplateSettingsService {

  constructor(
    private http: HttpClient,
    public router: Router,
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  // Search API
  getSearchDropdown(status:any){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates-dropdown/?is_active=${status}&ordering=name`,this.httpOption)
  }

  // Landing Screen API
  getLandingScreenAPI(limit:any,offset:any,searchString:any,status:any){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/?limit=${limit}&offset=${offset}&is_active=${status}&search=${searchString}`,this.httpOption)
  }

  // View Component API
  getSingleData(id:any){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/${id}/`,this.httpOption)
  }

  // Update/Delete Component API
  updateLetter(statusInfo:any,id:any,action:any){
    if(action == ''){
      return this.http.put(`${this.API_PATH}letters_templates/api/v1/letter-templates/${id}/`,statusInfo,this.httpOption)
    }else{
      return this.http.patch(`${this.API_PATH}letters_templates/api/v1/letter-templates/${id}/`,statusInfo,this.httpOption)
    }
  }

  // Module Dropdown
  getModuleDropdown(){
    return this.http.get(`${this.API_PATH}organization/api/v1/module-dropdown/`,this.httpOption)
  }

  // Letter Types Dropdown
  getLetterTypeDropdown(id:any){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-type-dropdown/?module=${id == 0 ? '' : id}&is_other=${(id == '' || id == 0)?true:false}`,this.httpOption)
  }

  // Default Letters
  getDefaultLetters(){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/?is_default=true&ordering=name&is_active=true`,this.httpOption)
  }

  // Create Template
  createTemplate(body:any){
    return this.http.post(`${this.API_PATH}letters_templates/api/v1/letter-templates/`, body, this.httpOption)
  }

  // Tags API
  getTagsList(){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter_tags_list/`,this.httpOption)
  }

  // CreatePDF
  createPDF(body:any){
    return this.http.post(`${this.API_PATH}letters_templates/api/v1/letter-template-pdf/`, body, this.httpOption)
  }
}
