import { Component, OnInit,Output,EventEmitter, Input} from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-expense-report-summary',
  templateUrl: './expense-report-summary.component.html',
  styleUrls: ['./expense-report-summary.component.scss']
})
export class ExpenseReportSummaryComponent implements OnInit {

  @Input() formGp:any;
  @Input() receiptListData:any;
  @Input() advanceListData:any;
  @Input() currency:any;

  @Output() submitmethod      = new EventEmitter();

  page1              = 1;
  pageSize1          = 20;
  searchString1: any = "";

  page2              = 1;
  pageSize2          = 20;
  searchString2: any = "";

  viewAttchments          = false;
  uploadedAttachments:any = [];
  viewAttachmentIndex     = 0;
  receiptId               = 0;

  saveClicked  = false;

  constructor(
    public appService: AppService,
  ) { }

  ngOnInit(): void {
  }

  viewAttachmentFunction(index:any,id:any){
    this.viewAttchments      = true;
    this.receiptId           = id;
    this.viewAttachmentIndex = index;
    this.uploadedAttachments = this.receiptListData[this.viewAttachmentIndex].attachments;
  }

  closeViewAttachment(){
    this.viewAttchments = false;
  }

  saveViewAttachment(data:any){
    this.viewAttchments = false;
  }

  changeTab(){
    this.saveClicked   = true;
    this.submitmethod.emit();
  }

}
