import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NotificationSettingsService } from 'src/app/@core/services/notification-settings.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  // Variables Initialization
  showModal       : boolean = false;
  listAPIData     : any     = [];
  id              : number  = 0;
  selectedMenu    : any     = ''
  selectedSubMenu : any     = ''
  saveClicked     : boolean = false;
  loader          : boolean = false;
  permissions     : any;
  sideMenuArr     : any     = []
  subMenuArr     : any     = []
  loaderMenu      : boolean = false
  configureMainIndex  : any = 0
  configureSubIndex:any     = 0
  menuId          : any
  rolesDropdownArr: any     = []

  // Form
  notifyForm     !: FormGroup
  configureForm  !: FormGroup

  @ViewChild('activeDropdown', { static: true }) activeDropdown !: NgbDropdown;

  constructor(
    private notifyServ : NotificationSettingsService,
    private fb         : FormBuilder,
    public appService  : AppService,
    private notifyToast: NotificationService,
    private rolesServ  : RoleService,
    public messageService : MessageService,
    private cd         : ChangeDetectorRef,
    private renderer   : Renderer2
  ) {
    // Form Initialize
    this.notifyForm = this.fb.group({
      menusList        : this.fb.array([])
    })
    this.configureForm = this.fb.group({
      config : [false], // false - emp, true - custom
      notify_trigger_type : [false], // false - role, true - ou
      role: [[]],
      organization_type: ['Company'],
      receiver_type: ['']
    })
  }

  get f(){
    return this.configureForm?.controls
  }

  ngOnInit(): void {
    this.getListAPI(); // api for landing screen
    this.getPermission(); //permissions
    this.rolesDropdown(); // role dropdown
    this.onMenuClick(0);
  }

  // List API
  getListAPI(){
    this.loader = true
    this.loaderMenu = true
    this.notifyServ.sideMenuAPI().subscribe((res:any)=>{
      this.listAPIData = res?.body?.data
      this.populateForm()
    })
  }

  // Set Menu
  setMenu(id:any,menu:any,index:any){
    this.loaderMenu = true
    this.onMenuClick(index)
    this.selectedMenu = menu
    this.menuId = id
    this.subMenuArr = this.matchSelectedMenu()
    this.selectedSubMenu = this.matchSelectedMenu()[0]?.type
    this.cd.detectChanges()
    setTimeout(()=>{
      this.loaderMenu = false
    },1500)
  }

  // Set Sub Menu
  subSectionArray(menu:any){
    this.selectedSubMenu = menu
    this.loaderMenu = true
    setTimeout(()=>{
      this.loaderMenu = false
    },1500)
  }

  activeMenuIndex: any = null;
  activeMenuIndices: number[] = [];
  onMenuClick(index: number) {
    const idx = this.activeMenuIndices.indexOf(index);
    if (idx > -1) {
      this.activeMenuIndices.splice(idx, 1);
    } else {
      this.activeMenuIndices.push(index);
    }
  }

  onSubMenuClick(index: number, type: string) {
    this.selectedMenu = type;
    this.activeMenuIndex = index;
  }

  getSelectedSubsections() {
    let sortedArr : any = []
    for (let control of this.notifyControls().controls) {
      if(this.selectedMenu == control?.get('category')?.value){
        let arrayName = control?.get('additionalControl')?.value
        for (let subControl of arrayName) {
            if (subControl.subSection === this.selectedSubMenu) {
              sortedArr.push(subControl)
            }
        }
        return sortedArr;
      }
    }
    return [];
  }

  // Condition Checking
  conditionControlSatisfied(index:any,subArr:any){
    let formVal = this.notifyControls()?.at(index)?.get('category')?.value
    if(!subArr){
      return formVal.toLowerCase() === this.selectedMenu.toLowerCase()
    }else {
      if(formVal.toLowerCase() === this.selectedMenu.toLowerCase()){
        let arrayName = this.notifyControls()?.at(index)?.get('additionalControl')?.value
        for (let subControl of arrayName) {
          if(subControl.subSection === this.selectedSubMenu){
            return true
          }
        }
      }
      return false
    }
  }

  subMenuControlSatisfied(subArr:any,item:any){
    if(!subArr){
      return true
    }else{
      return item.get('subSection')?.value === this.selectedSubMenu
    }
  }

  // Configure
  setConfigure(mainIndex:any,subIndex:any){
    this.showModal = true
    this.configureMainIndex = mainIndex
    this.configureSubIndex = subIndex
    this.id = this.getAdditionalControls(mainIndex)?.at(subIndex).get('id')?.value
    let data = this.getAdditionalControls(mainIndex)?.at(subIndex)?.get('configure')?.value
    this.configureForm.patchValue({
      config : data?.config || false,
      notify_trigger_type : data?.notify_trigger_type || false,
      role: data?.role || [],
      organization_type: data?.organization_type || 'Company',
    })
    if(this.configureForm?.get('notify_trigger_type')?.value == false && this.configureForm?.get('config')?.value){
      this.setRequired(true)
    }
  }

  // Required for configure
  setRequired(isTrue:any){
    if(isTrue){
      this.configureForm?.get('role')?.setValidators([Validators.required])
    }else{
      this.configureForm?.get('role')?.clearValidators()
    }
    this.configureForm?.get('role')?.updateValueAndValidity()
  }

  // Reset Configure
  resetConfig(){
    this.configureForm?.reset({
      config : false,
      notify_trigger_type : true,
      role: [],
      organization_type: 'Company'
    })
  }

  // Clear ROLE/OU
  clearForm(){
    this.configureForm?.get('role')?.setValue([])
    this.configureForm?.get('organization_type')?.setValue('Company')
  }

  // Roles Dropdown
  rolesDropdown(){
    this.rolesServ.getRoleDropdownList(true).subscribe((res:any)=>{
      this.rolesDropdownArr = res?.body
      this.selectAllForDropdownItems(this.rolesDropdownArr);
    })
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Make the main menu to open if children is selected
  getMainChildClass(index: number) : boolean{
    return this.activeMenuIndices.includes(index);
  }

  // Clear Role
  clear(index:any){
    let data = this.configureForm.value.role;
    data.splice(index, 1);
    this.configureForm.patchValue({
      role: data
    });
  }

  // Configure Btn to be enabled/not
  configureBtnEnable(mainIndex:any,subIndex:any){
    let menuName = this.getAdditionalControls(mainIndex)?.at(subIndex)?.get('menuName')?.value
    let email_notify = this.getAdditionalControls(mainIndex)?.at(subIndex)?.get('email_notify')?.value
    let push_notify = this.getAdditionalControls(mainIndex)?.at(subIndex)?.get('push_notify')?.value
    // Only for reminder configure
    return menuName.toLowerCase().includes('reminder') && (email_notify || push_notify)
  }

  // Update API
  toggleChange(mainIndex:any,subIndex:any,control:any){
    this.loaderMenu = true
    let formVal = this.getAdditionalControls(mainIndex)?.at(subIndex)?.get(control)?.value
    let id = this.getAdditionalControls(mainIndex)?.at(subIndex)?.get('id')?.value
    const { control: value } = control;
    let body = {
      [control]: formVal
    }
    this.reusedAPI(id,body)
  }

  // Configure SAVE
  saveConfigure(){
    let form = this.configureForm.value
    form.receiver_type = this.configureForm.get('config')?.value == true ? 'Custom' : 'All employees'
    delete form.config
    this.saveClicked = true
    this.reusedAPI(this.id,this.configureForm?.value)
  }

  reusedAPI(id:any,body:any){
    this.notifyServ.updateList(id,body).subscribe((res:any)=>{
      this.saveClicked = false
      this.showModal = false
      this.notifyToast.handleSuccessNotification("Updated Successfully","Success");
      this.notifyControls().clear();
      this.getListAPI();
    },
    (error:any)=>{
      this.saveClicked = false
      this.notifyToast.handleErrorNotification('Something went wrong','Error')
    })
  }

  // Form Setups on Below

  // Initial FormArray of Main FormGroup
  notifyControls() : FormArray {
    return this.notifyForm?.get('menusList') as FormArray
  }

  // Individual FormArray
  getAdditionalControls(index:any) : FormArray {
    return this.notifyControls()?.at(index)?.get('additionalControl') as FormArray
  }

  // Formgroup setup with data
  createMenuItems(data?: any): FormGroup {
    const formGroup = this.fb.group({
      category: [data?.category || ''],
      id: [data?.id || null],
      menuName: [data?.notification_name || ''],
      push_notify: new FormControl({ value: data?.push_notify || false, disabled: !data?.push_enable }),
      email_notify: new FormControl({ value: data?.email_notify || false, disabled: !data?.email_enable }),
      receiver: [data?.receiver || ''],
      configure: this.fb.group({
        config: [(data?.receiver_type == 'All employees' ? false : true) || false],
        notify_trigger_type: [data?.notify_trigger_type || false],
        role: [data?.role || null],
        organization_type: [data?.organization_type || 'Company'],
      }),
      subSection : [data?.subsection || '']
    });
    return formGroup;
  }

  // Set Form from api data
  populateForm(): void {
    const menusArray = this.notifyControls() as FormArray;
    this.sideMenuArr = this.setSideMenu(this.listAPIData)
    this.listAPIData.forEach((moduleData:any, index:any) => {
      const keys = Object.keys(moduleData).filter(key => key !== 'module' && key !== 'count');
      keys.forEach(key => {
        const items = moduleData[key];
        const initialItem = this.fb.group({
          category: [key],
          id: [index],
          additionalControl: this.fb.array([])
        });
        const additionalControl = initialItem.get('additionalControl') as FormArray;
        if (Array.isArray(items)) {
          items.forEach((item: any) => {
            item.category = key;
            additionalControl.push(this.createMenuItems(item));
          });
        }
        else {
          const subsections = Object.keys(items);
          subsections.forEach(subsection => {
            const subsectionItems = items[subsection];
            subsectionItems.forEach((item: any) => {
              item.category = key;
              item.subsection = subsection;
              additionalControl.push(this.createMenuItems(item));
            });
          });
        }
        menusArray.push(initialItem);
      });
      if(this.selectedMenu == '' || this.selectedMenu == undefined){
        this.selectedMenu = this.setSideMenu(this.listAPIData)[index].children[0]?.type
        this.cd.detectChanges();
        return ;
      }
    });
    this.loader = false
    this.loaderMenu = false
  }

  // Form Setup Ends

  // Setting Menu
  setSideMenu(data:any){
    return data.map((module:any) => {
      let transformedModule : {
        module: string;
        count: number;
        children: { type: string; notifications: any, id:any }[];
      } = {
          module: module.module,
          count: module.count || 0,
          children: []
      };
      for (let key in module) {
        if ((Array.isArray(module[key]) || typeof module[key] === 'object') && key !== "count" && key !== "module") {
          let notifications: any[] = [];
          if (Array.isArray(module[key])) {
            notifications = module[key];
          } else {
            notifications = Object.values(module[key]).reduce((acc: any[], val: any) => acc.concat(val), []);
          }
          transformedModule.children.push({
            type: key,
            notifications: notifications,
            id: notifications.map((item: any) => item.id)
          });
        }
      }
      return transformedModule;
    });
  }

  matchSelectedMenu() {
    let nestedChildren : any = []
    this.listAPIData.forEach((module: any) => {
      if (module[this.selectedMenu]) {
        if (!Array.isArray(module[this.selectedMenu])) {
         if(Object.keys(module[this.selectedMenu])?.length > 0){
           for(let key in module[this.selectedMenu]){
            nestedChildren.push({
              type: key,
              notifications : module[this.selectedMenu][key]
            });
           }
         }
        }
      }
    });
    return nestedChildren
  }

  // Permissions
  getPermission(){
    this.permissions = this.appService.getPermissions('/notifications');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
