import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit, SimpleChange, ViewChild, ChangeDetectorRef ,ChangeDetectionStrategy,} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { LeaveBalanceService } from 'src/app/@core/services/leave-balance.service';
import { AppService } from 'src/app/app.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';
import { NguCarouselConfig } from '@ngu/carousel';
import * as moment from 'moment';

@Component({
  selector: 'app-my-leave-balance',
  templateUrl: './my-leave-balance.component.html',
  styleUrls: ['./my-leave-balance.component.scss'],
  providers: [TitleCasePipe],
  // changeDetection: ChangeDetectionStrategy.OnPush


})
export class MyLeaveBalanceComponent implements OnInit {
  single = [
    {
      'name': 'Available',
      'value': 5
    },
    {
      'name': 'Consumed',
      'value': 5
    }
  ];
  graphlabels: any = [];
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  leaveStatus: any = '';
  searchString: any = '';
  loader = true;
  trendLoader = true;
  upcomingLoader = true;
  leaveBalanceTableLoader = true;
  trend: any = [];
  upcoming: any = [];
  events: string[] = [];
  colorScheme: any = {
    domain: ['#0078CE', '#D8DBF2']
  };
  graph_colorScheme: any = ['#0078CE', '#D8DBF2'];
  graphdata: any = [];
  leaveDetails: any;
  leaveData: any;
  leaveBalanceTable: any;
  userData: any;
  userId: any;
  leaveTypes: any;
  selectedLeaveTypes: any;
  date: any;
  leaveview: any = [];
  viewpanel = false;
  loader1 = false;
  page = 1;
  pageSize = 20;
  filterData: any = [];
  permissions: any
  modalToggle = false;
  currentDocName = "";
  currentExtension = "";
  fileName: any;
  alertToggle = false;
  investalertMsg: any = '';
  loadertbl = false;
  requestId: any;
  searchkey:any='';
  searchlistdata:any  = [];
  leavedatatemp:any =[];
  searchplaceholder:string =this.messageService.searchdisplay('Leave Type');
  @ViewChild(SearchbarComponent) child: any;
  onSelect(event: any) {
  }
  // ngselect
  year: any = new Date().getFullYear()
  yearRange: any;
  public leaveBalanceTiles: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 3, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  sLoading = false;
  constructor(
    private leaveBalance: LeaveBalanceService,
    private datePipe: DatePipe,
    private authService: AuthService,
    public appService: AppService,
    private titleCase: TitleCasePipe,
    public messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.yearRange = this.generateArrayOfYears()
    this.userId = this.authService?.isEmployeeId;
    this.getLeaveBalance();
    this.getleavetypes();
    this.getUpcomingLeave();
    this.getLeaveTrendDropdown();
    this.getLeaveData();
    this.getPermission();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 3
    var years = []

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  // Permission
  getPermission() {
    this.permissions = this.appService.getPermissions('/my-leave-balance');
    if (this.permissions == undefined) {
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  disableDate() {
    return false;
  }

  addEvent(val: any) {
    this.year = val;
    this.getLeaveBalance();
    this.getleavetypes();
    this.getUpcomingLeave();
    this.getLeaveTrendDropdown();
    this.getLeaveTrend();
    this.getLeaveData();
  }

  getleavetypes(){
    this.leaveBalance.leaveTypeDropdown(this.userId,this.searchkey,this.year).subscribe((res:any)=>{
      for(let i=0;i<res.length;i++){
        res[i]['name']  = res[i]['leave_name'];
      }
      // this.searchlistdata   = res;
      this.leavedatatemp = res;
    })
  }

  getLeaveBalance() {
    this.loader = true;
    this.leaveBalance.leaveData({ 'employee': this.userId, 'year': this.year, 'search':this.searchkey }).subscribe((res: any) => {
      this.leaveDetails = [res.data];
      this.leaveData = res.data;
        this.sLoading = false;
        this.loader = false;
        // this.getleavetypes();
    });
    this.cdr.detectChanges();
  }

  searchresults(data:any){
    this.loader = true;
    this.leaveData = [];
    if(data == ""){
      this.searchkey  = "";
      this.getLeaveBalance();
    }else{
        for(let i=0;i<this.leavedatatemp.length;i++){
          if(this.leavedatatemp[i].id==data){
            this.searchkey  = this.leavedatatemp[i].leave_name;
          }
        }
        this.getLeaveBalance();
    }
}

searchshift(searchKeyword:any){
  this.loader = true;
  this.sLoading = true;
  this.leaveData = [];
  this.searchkey = searchKeyword;
  if(searchKeyword != ""){
    this.getLeaveBalance();
  }
}

  getLeaveTrend() {
    this.trendLoader = true;
    this.graphlabels = ['Available', 'Consumed']
    if(this.selectedLeaveTypes != null){
       this.leaveBalance.leaveTrend({ 'employee': this.userId, 'leave_type': this.selectedLeaveTypes, 'year': this.year }).subscribe((res: any) => {
        this.graphdata = [];
        this.trend = res?.trend;
        if(res.trend != undefined ){
          Object.entries(res.trend).forEach(([key,value]) =>{
            if (key != 'total') {
              this.graphdata.push(value);
            }
          })
        }
        this.trendLoader = false;
        this.cdr.detectChanges();
      })
    }else{
      this.trendLoader = false;
      this.trend.total = 0
    }
    }
  getUpcomingLeave() {
    this.upcomingLoader = true;
    this.leaveBalance.upcomingLeave({ 'employee': this.userId, 'year': this.year }).subscribe((res: any) => {
      if (res) {
        this.upcoming = res.upcoming;
        this.upcomingLoader = false;
      }
    })
  }

  createarrayDisplay(array:any){
    let string:any =[];
    array.forEach((item:any) => {
      string.push(this.appService.dateFormatDisplay(item));
    })
    return string.join(", ");
  }

  datecheck(d1:any,d2:any){
    if(moment(d1).isSame(d2)){
      return 'same';
    }else if(moment(d1).isSameOrBefore(d2)){
      return 'before';
    }else{
      return 'after';
    }
  }

  getLeaveTrendDropdown() {
    this.leaveBalance.leaveTypeDropdown(this.userId,'',this.year).subscribe((res:any)=>{      
      for(let i=0;i<res.length;i++){
        res[i]['name']  = res[i]['leave_name'];
      }
      this.leaveTypes = res;
      if (res.length != 0) {
        this.selectedLeaveTypes = res[0]['id'];
        if (this.selectedLeaveTypes != '' && this.selectedLeaveTypes != undefined) {
          this.getLeaveTrend();
        }
      } else {
        this.trendLoader = false;
        this.trend.total = 0;
        this.selectedLeaveTypes = '';
      }
    })
    this.cdr.detectChanges();

  }
  getLeaveData() {
    this.loadertbl = true;
    this.leaveBalance.leaveDatatable(this.userId, this.searchString, this.leaveStatus, this.year).subscribe((res: any) => {
      for (let i = 0; i < res?.data?.length; i++) {
        res.data[i]['transaction_type'] = res?.data[i]['type'];
        if (((res?.data[i]['leave_request']) != null) && ((res?.data[i]['leave_request']) != '')) {
          res.data[i]['view_detail'] = 'View';
        } else {
          res.data[i]['view_detail'] = '--';
        }
      }
      this.leaveBalanceTable = res?.data;


      this.filterData = res?.status_list;
      this.loadertbl = false;
    })
    this.cdr.detectChanges();
  }

  filterFn(value: any) {
    if (value == '')
      this.leaveStatus = '';
    else
      this.leaveStatus = [value];
    this.getLeaveData();
    //  this.child.clearBar();
    // this.searchString = '';
  }

  searchStringChanged() {
    this.getLeaveData();
  }

  leaveTypeChanged() {
    this.selectedLeaveTypes = this.selectedLeaveTypes;
    this.getLeaveTrend();
  }

  dateFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 5, 0, 1),
    new Date(new Date().getFullYear() - 5, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  viewComp(id: any) {
    this.loader1 = true;
    this.viewpanel = true;
    this.leaveBalance.leavesingleview(id).subscribe((res: any) => {
      res['from_date_day'] = res['from_date_name']
      res['to_date_day'] = res['to_date_name']
      res['full_day_dates'] = res['full_day_portion_dates']
      res['half_day_dates'] = res['half_day_portion_dates']
      this.leaveview = res;
      this.requestId = res['employeerequestid'];
      this.loader1 = false;
    })
  }

  filterIt() {
    return this.leaveBalanceTable.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  leaveViewClose(data: any) {
    this.viewpanel = data;
  }
  closeViewPage(data: any) {
    this.viewpanel = data;
    if (data == false) {
      this.getLeaveBalance();
      this.getUpcomingLeave();
      this.getLeaveData();
      this.getLeaveTrend();
    }
  }

}
