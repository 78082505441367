
<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Prior Payroll</h5>
        <button class="btn-reset text-light-600" ><i class="icon-close-lg fs-16" (click)="backClicked()"></i></button>
    </header>
    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky "  style="top: 3.625rem;">
        <li [ngbNavItem]="1">
            <a ngbNavLink class="nav-link">Consolidated data</a>
            <ng-template ngbNavContent>
               <app-consolidated-data></app-consolidated-data>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink class="nav-link">Month wise data</a>
            <ng-template ngbNavContent>
                <app-monthwise-data></app-monthwise-data>
            </ng-template>
        </li>

    </nav>
    <main [ngbNavOutlet]="nav"  class="config-container">
    </main>
</div>
