import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLetterHrService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  empletterList(status:any,limit:any,offset:any,search:any,filterparam:any,emp_id:any){
    let empid:any;
    if(emp_id == 0){
      empid = `&search=${search}`;
    }else{
      empid = `&employee_list=[${emp_id}]`
    }
    if(filterparam != undefined){
      return this.http.get(`${this.API_PATH}letters_templates/api/v1/employees-letters/?limit=${limit}&is_active=true&offset=${offset}&status=${status}&${filterparam}${empid}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}letters_templates/api/v1/employees-letters/?limit=${limit}&is_active=true&offset=${offset}&status=${status}${empid}&ordering=`, this.httpOption)
    }
  }

  getOneemp(id:number){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/employees-letters-detail/${id}/`, this.httpOption)
  }

  createemp(body:any){
    return this.http.post(`${this.API_PATH}letters_templates/api/v1/generate-letter/`,body, this.httpOption)
  }

  empunpublish(id: number, body:any,from:any ){
    if(from !='inbox'){
     return this.http.put(`${this.API_PATH}letters_templates/api/v1/employees-letters-detail/${id}/`,body , this.httpOption)}
    else{
      return this.http.put(`${this.API_PATH}letters_templates/api/v1/employee-letter-generate/${id}/`,body , this.httpOption)
    }
  }

  empupdate(id: number, body:any){
    return this.http.put(`${this.API_PATH}letters_templates/api/v1/emps-detail/${id}/`,body, this.httpOption)
  }

  empListData(status:any,filterparam:any){
    if(filterparam != undefined && filterparam != ''){
      return  this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-employee-dropdown/?is_active=true&status=${status}&${filterparam}`, this.httpOption);
    }else{
      return  this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-employee-dropdown/?is_active=true&status=${status}&ordering=full_name`, this.httpOption);
    }
  }

  lettertemplatelist(from:any,type:any){
    if(from=='hr'){
      return  this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates-dropdown/?is_other=true&is_active=true`, this.httpOption);
    }else{
      return  this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates-dropdown/?letter_type=${type}&is_active=true`, this.httpOption);
    }
  }

  letterpreview(id:any){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/letter-templates/${id}/`, this.httpOption);
  }

  letterpreviewinbox(id:any){
    return this.http.get(`${this.API_PATH}inbox/api/v1/inbox-letter-template-view/${id}/`, this.httpOption);
  }

  lettercount(filterparam:any){
    if(filterparam != undefined && filterparam != ''){
      return this.http.get(`${this.API_PATH}letters_templates/api/v1/letters-letter_type-count/?is_active=true&status=Pending&${filterparam}`, this.httpOption);
    }else{
      return this.http.get(`${this.API_PATH}letters_templates/api/v1/letters-letter_type-count/?is_active=true&status=Pending`, this.httpOption);
    }
  }

  getletterpdf(body:any){
    return this.http.post(`${this.API_PATH}letters_templates/api/v1/letter-template-pdf/`,body, this.httpOption)
  }
  getletterpdfinbox(body:any){
    return this.http.post(`${this.API_PATH}inbox/api/v1/inbox-letter-template-pdf/`,body, this.httpOption)
  }

  getempList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name`, this.httpOption);
  }
}

