import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-consolidated-data',
  templateUrl: './consolidated-data.component.html',
  styleUrls: ['./consolidated-data.component.scss']
})
export class ConsolidatedDataComponent implements OnInit {
  activeTab1          = 1;
  page                = 1;
  pageSize            = 20;
  searchString:any    = "";
  filter              = false;
  companyList:any     = [];
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  nodataFound         = false;
  loader              = false;
  landingData         = false;
  landingLoader       = true;
  summaryData:any     = [];
  year:any;
  month:any;
  company:any;

  constructor(
    public route:ActivatedRoute,
    public salaryService: SalaryDashboardService,
    public messageService : MessageService,
    private _cdr: ChangeDetectorRef


  ) { }

  ngOnInit(): void {

    this.getCompanyDropdown();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      this.getSummary();
    })
  }
  editPayroll(){

  }
  getSummary(){
    this.landingLoader    = true;
    this.salaryService.getPriorSummary(this.company,this.year,'',this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{
      // console.log("respone",res);
      if(res?.body.length !== 0)
      this.summaryData    = res?.body?.data[0];

      this.landingLoader  = false

    })

  }
  getCompanyDropdown(){
    this.salaryService.companyDropDownData()
    .subscribe((res: any) => {
      if (res.status == 200) {
        if(res.body.length == 0){
          this.nodataFound = true;
          this.loader      = false;
          this._cdr.detectChanges();
        } else {
          this.companyList = res.body;
          if(this.company==''){
            this.company =  this.company==''?res.body[0]['id']:this.company;

          }
        }
      }
    })
  }
  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList       = []
    this.getSummary()
    this.filter           = false
  }
  submitForm(data:any){
    this.filter           = false
    this.filter           = false
    this.buList          = data.buList!=""?data.buList.join(','):[]
    this.DepList         = data.DepList!=""?data.DepList.join(','):[]
    this.DesignationList = data.DesignationList!=""?data.DesignationList.join(','):[]
    this.gradeList       = data.gradeList!=""?data.gradeList.join(','):[]
    this.BranchList      = data.BranchList!=""?data.BranchList.join(','):[]
    this.getSummary()
  }
  closeFilter(){
    this.filter  = false
  }
}
