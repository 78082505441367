<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="loader==false && permissions">
    <div class="col-12">
        <div class="d-flex justify-content-between">
            <h5 class="mb-0">Family</h5>
            <ng-container *ngIf="addEditPermission">
                <a class="link-primary  d-flex align-items-center" *ngIf="familyData.length!=0"
                    (click)="editFamily('Edit')"><i class="icon-pencil-square me-1"></i> Edit</a>
            </ng-container>
        </div>
    </div>
    <div class="col-12" *ngIf="familyData.length!=0">
        <div class="card details-card shadow-0 px-24 py-16">
            <div class="row row-24 ">
                <div class="col-12 "><span class="title">Parental Information</span></div>
                <div *ngIf="getFieldPermission('father_name')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Father’s name</div>
                    <div class="detail-desc">{{familyData?.father_name}}</div>
                </div>
                <div *ngIf="getFieldPermission('father_date_of_birth')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Father’s DOB</div>
                    <div class="detail-desc">{{familyData?.father_date_of_birth!=null &&
                        familyData?.father_date_of_birth!='' && familyData?.father_date_of_birth!='None' &&
                        familyData?.father_date_of_birth!='none'
                        ?this.appservice.dateFormatDisplay(familyData?.father_date_of_birth):'-'}}</div>
                </div>
                <div *ngIf="getFieldPermission('mother_name')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Mother’s name</div>
                    <div class="detail-desc">{{(familyData?.mother_name!=null &&
                        familyData?.mother_name!="")?familyData?.mother_name:'-'}}</div>
                </div>
                <div *ngIf="getFieldPermission('mother_date_of_birth')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Mother’s DOB</div>
                    <div class="detail-desc">{{familyData?.mother_date_of_birth!=null &&
                        familyData?.mother_date_of_birth!='' && familyData?.mother_date_of_birth!='None' &&
                        familyData?.mother_date_of_birth!='none'
                        ?this.appservice.dateFormatDisplay(familyData?.mother_date_of_birth):'-'}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="(familyData.length!=0 && familyData.marital_status!=null)">
        <div class="card details-card shadow-0 px-24 py-16">
            <div class="row row-24 ">
                <div class="col-12"><span class="title">Marriage Details</span></div>
                <div *ngIf="getFieldPermission('marital_status')" class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">{{familyData?.marital_status}}</div>
                </div>
                <ng-container *ngIf="getFieldPermission('date_of_marriage')">
                    <div class="col-lg-3  col-sm-6 col-12"
                        *ngIf="familyData.marital_status!='Single' && familyData.marital_status!=null">
                        <div class="detail-title">Date of marriage</div>
                        <div class="detail-desc">{{familyData?.date_of_marriage!=null &&
                            familyData?.date_of_marriage!='' && familyData?.date_of_marriage!='None' &&
                            familyData?.date_of_marriage!='none'
                            ?this.appservice.dateFormatDisplay(familyData?.date_of_marriage):'-'}}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="getFieldPermission('spouse_name')">
                    <div class="col-lg-3  col-sm-6 col-12"
                        *ngIf="familyData.marital_status!='Single' && familyData.marital_status!=null">
                        <div class="detail-title">Spouse name</div>
                        <div class="detail-desc">{{familyData?.spouse_name!=null?familyData?.spouse_name:'-'}}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="getFieldPermission('spouse_date_of_birth')">
                    <div class="col-lg-3  col-sm-6 col-12"
                        *ngIf="familyData.marital_status!='Single' && familyData.marital_status!=null">
                        <div class="detail-title">Spouse DOB</div>
                        <div class="detail-desc">{{familyData?.spouse_date_of_birth!=null &&
                            familyData?.spouse_date_of_birth!='' && familyData?.spouse_date_of_birth!='None' &&
                            familyData?.spouse_date_of_birth!='none'
                            ?this.appservice.dateFormatDisplay(familyData?.spouse_date_of_birth):'-'}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <!--Child data-->
    <!-- <div class="col-12" *ngIf="familyData.length!=0 && (familyData.marital_status!='Single' && familyData.marital_status!=null) && permissions"> -->
    <div class="col-12" *ngIf="familyData.length!=0 && familyData?.children.length!=0 && permissions">
        <div class="card details-card shadow-0 px-24 py-16">
            <div class="row row-24 ">
                <div class="col-12"><span class="title">Children Details</span></div>
                <div class="col-12" *ngFor="let child of familyData.children;let i=index">
                    <div class="border radius-4 p-16">
                        <div class="row row-24 ">
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div class="fs-12 fw-600">Child {{i+1}}</div>
                                <div class="d-flex gap-24 fs-18 ">
                                    <a *ngIf="permissions?.d" class="link-danger" (click)="deleteChild(child.id)"><i
                                            class="icon-trash"></i></a>
                                    <!-- <a *ngIf="permissions?.e" class="link-primary" (click)="addUpdateChild(child.id)"><i class="icon-pencil-square"></i></a> -->
                                </div>
                            </div>
                            <div *ngIf="getFieldPermission('child_name')" class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title">Children name</div>
                                <div class="detail-desc">{{child.child_name!=null?child.child_name:'-'}}</div>
                            </div>
                            <div *ngIf="getFieldPermission('child_date_of_birth')" class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title">Children DOB</div>
                                <div class="detail-desc">{{this.child.child_date_of_birth!=null &&
                                    this.child.child_date_of_birth!='' && this.child.child_date_of_birth!='None' &&
                                    this.child.child_date_of_birth!='none'
                                    ?this.appservice.dateFormatDisplay(this.child.child_date_of_birth):'-'}}</div>
                            </div>
                            <div *ngIf="getFieldPermission('child_gender')" class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title">Gender</div>
                                <div class="detail-desc">{{child.child_gender!=null?child.child_gender:'-'}}</div>
                            </div>
                            <ng-container *ngIf="getFieldPermission('is_dependent')">
                                <div class="col-lg-3  col-sm-6 col-12" *ngIf="child.is_dependent">
                                    <div class="detail-title">Dependency</div>
                                    <div class="detail-desc">Dependent</div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="getFieldPermission('is_going_to_school')">
                                <div class="col-lg-3  col-sm-6 col-12" *ngIf="child.is_going_to_school">
                                    <div class="detail-title">Schooling</div>
                                    <div class="detail-desc">Going to school</div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="getFieldPermission('is_going_to_hostel')">
                                <div class="col-lg-3  col-sm-6 col-12" *ngIf="child.is_going_to_hostel">
                                    <div class="detail-title">Hostel</div>
                                    <div class="detail-desc">Hostel</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 d-flex" *ngIf="permissions?.a">
                    <a class="btn btn-outline-primary h-40 px-64 radius-2" (click)="addUpdateChild(0)">Add New Child</a>
                </div> -->

            </div>
        </div>
    </div>
    <!--Dependent data-->
    <div class="col-12" *ngIf="familyData.length!=0 && familyData?.dependent_detail.length!=0 && permissions">
        <div class="card details-card shadow-0 px-24 py-16">
            <div class="row row-24 ">
                <div class="col-12"><span class="title">Dependent Details</span></div>
                <div class="col-12" *ngFor="let depend of familyData.dependent_detail;let i= index">
                    <div class="border radius-4 p-16">
                        <div class="row row-24 ">
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div class="fs-12 fw-600">Dependent {{i+1}}</div>
                                <div class="d-flex gap-24 fs-18 ">
                                    <a *ngIf="permissions?.d" class="link-danger"
                                        (click)="deleteDependent(depend.id)"><i class="icon-trash"></i></a>
                                    <!-- <a *ngIf="permissions?.e" class="link-primary" (click)="addUpdateDependent(depend.id)"><i class="icon-pencil-square"></i></a> -->
                                </div>
                            </div>
                            <div *ngIf="getFieldPermission('dependent_name')" class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title">Dependent name</div>
                                <div class="detail-desc">{{depend.dependent_name!=null?depend.dependent_name:'-'}}</div>
                            </div>
                            <div *ngIf="getFieldPermission('dependent_of_birth')" class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title">Dependent DOB</div>
                                <div class="detail-desc">{{depend.dependent_of_birth!=null &&
                                    depend.dependent_of_birth!='' && depend.dependent_of_birth!='None' &&
                                    depend.dependent_of_birth!='none'
                                    ?this.appservice.dateFormatDisplay(depend.dependent_of_birth):'-'}}</div>
                            </div>
                            <div *ngIf="getFieldPermission('dependent_gender')" class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title">Gender</div>
                                <div class="detail-desc">{{depend.dependent_gender!=null?depend.dependent_gender:'-'}}
                                </div>
                            </div>
                            <div *ngIf="getFieldPermission('dependent_relation')" class="col-lg-3  col-sm-6 col-12">
                                <div class="detail-title">Relation</div>
                                <div class="detail-desc">
                                    {{depend.dependent_relation!=null?depend.dependent_relation:'-'}}</div>
                            </div>
                            <div *ngIf="getFieldPermission('dependent_remark')" class="col-12">
                                <div class="detail-title">Remarks</div>
                                <div class="detail-desc">{{depend.dependent_remark!=null?depend.dependent_remark:'-'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 d-flex" *ngIf="permissions?.a">
                    <a class="btn btn-outline-primary h-40 px-64 radius-2" (click)="addUpdateDependent(0)">Add New Dependency</a>
                </div> -->

            </div>
        </div>
    </div>

</div>
<!-- showing when no family information available -->
<div class="row row-16" *ngIf="familyData.length==0">
    <div class="col-12">
        <div class="row row-24 py-16">
            <div class="col-12 ">
                <div class="card details-card shadow-0 px-24 py-16">
                    <p class="title mb-24">Family</p>
                    <div *ngIf="addEditPermission" class="col-12 d-flex">
                        <a *ngIf="permissions?.a" class="btn btn-outline-primary px-64" (click)="addNewFamilydata()">Add
                            Family Details</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- COMPLETE FAMILY DETAILS WITH CHILD AND DEPENDANCY DATA -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
    <form class="side-panel-container" [formGroup]="completeFamily" (ngSubmit)="onSubmit()" autocomplete="off">
        <header class="side-panel-head">
            <h5> {{familyHeader}} Family Details</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1"></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24 ">
                        <div class="col-12">
                            <p class="text-uppercase fw-500 mb-0 fs-16">Family DETAILS</p>
                        </div>
                        <div class="col-12">
                            <div class="card card-c2">
                                <div class="card-body p-16">
                                    <span class="fs-14 fw-600">Family Details </span>
                                </div>
                                <div class="card-body px-16 py-25">
                                    <div class="row row-16">
                                        <div class="col-12">
                                            <p class="text-uppercase fw-500 mb-0 fs-16">Parental DETAILS</p>
                                        </div>
                                        <input type="text" value="" formControlName="id" id="id" hidden>
                                        <input type="text" value="" formControlName="employee" id="employee" hidden>
                                        <div class="col-sm-6 form-row">
                                            <label for="father_name" class="form-label required">Father’s name </label>
                                            <input type="text" class="form-control " name="" id="father_name"
                                                placeholder="{{this.messageService.placeholderdisp('father’s name')}}"
                                                formControlName="father_name"
                                                [ngClass]="{ 'is-invalid': (familySubmitted || f.father_name.dirty || f.father_name.touched) &&  f.father_name.errors}">
                                            <div *ngIf="(familySubmitted || f.father_name.dirty || f.father_name.touched) && f.father_name.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.father_name.errors.required">
                                                    {{this.messageService.validationDisplay('required')}}</div>
                                                <div
                                                    *ngIf="!(f.father_name.errors.pattern) && f.father_name.errors.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                            </div>
                                            <div *ngIf="(familySubmitted || f.father_name.dirty) && f.father_name.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.father_name.errors.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-row">
                                            <label for="father_date_of_birth" class="form-label">Father’s DOB </label>
                                            <div class="input-group form-icon icon-right"
                                                [ngClass]="{ 'is-invalid': (familySubmitted || f.father_date_of_birth.dirty || f.father_date_of_birth.touched) &&  f.father_date_of_birth.errors}">
                                                <input (click)="datePicker.open()" class="form-control"
                                                    placeholder="Father’s DOB" matInput
                                                    formControlName="father_date_of_birth"
                                                    [matDatepickerFilter]="dobFilter" [matDatepicker]="datePicker"
                                                    (keydown)="disableDate()"><i class="icon-calendar pointer"
                                                    (click)="datePicker.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
                                            </div>
                                            <div *ngIf="(familySubmitted || f.father_date_of_birth.dirty || f.father_date_of_birth.touched) &&  f.father_date_of_birth.errors"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="f.father_date_of_birth.errors">{{this.messageService.validationDisplay('pattern')}}</div> -->

                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-row">
                                            <label for="mother_name" class="form-label">Mother’s name</label>
                                            <input type="text" class="form-control " name="" id="mother_name"
                                                placeholder="{{this.messageService.placeholderdisp('mother’s name')}}"
                                                formControlName="mother_name"
                                                [ngClass]="{ 'is-invalid': (familySubmitted || f.mother_name.dirty) &&  f.mother_name.errors}">
                                            <div *ngIf="(familySubmitted || f.mother_name.dirty) && f.mother_name.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.mother_name.errors.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                                <div
                                                    *ngIf="!(f.mother_name.errors.pattern) && f.mother_name.errors.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-row">
                                            <label for="mother_date_of_birth" class="form-label">Mother’s DOB </label>
                                            <div class="input-group form-icon icon-right"
                                                [ngClass]="{ 'is-invalid': (familySubmitted || f.mother_date_of_birth.dirty || f.mother_date_of_birth.touched) &&  f.mother_date_of_birth.errors}">
                                                <input (click)="datePicker1.open()" class="form-control"
                                                    placeholder="Mother’s DOB" matInput
                                                    formControlName="mother_date_of_birth"
                                                    [matDatepickerFilter]="dobFilter" [matDatepicker]="datePicker1"
                                                    (keydown)="disableDate()"><i class="icon-calendar pointer"
                                                    (click)="datePicker1.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                                            </div>
                                            <div *ngIf="(familySubmitted || f.mother_date_of_birth.dirty || f.mother_date_of_birth.touched) &&  f.mother_date_of_birth.errors"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="f.mother_date_of_birth.errors">{{this.messageService.validationDisplay('pattern')}}</div> -->

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <p class="text-uppercase fw-500 mb-0 fs-16">MARITAL STATUS</p>
                                        </div>
                                        <div class="col-sm-6 form-row">
                                            <label for="marital_status" class="form-label">Marriage status</label>

                                            <app-master-select [placeholder]="StatusPlaceHolder" id="marital_status"
                                                [submitted]="familySubmitted" [selectedItem]="f.marital_status.value"
                                                formControlName="marital_status" [type]="marital_status"
                                                [isrequired]="isrequired"
                                                (ngModelChange)="isSingle=true"></app-master-select>
                                        </div>
                                        <div class="col-sm-6 form-row"
                                            *ngIf="(f.marital_status.value!='Single' && f.marital_status.value!=null)">
                                            <label for="date_of_marriage" class="form-label">Date of marriage</label>
                                            <div class="input-group form-icon icon-right"
                                                [ngClass]="{ 'is-invalid': (familySubmitted || f.date_of_marriage.dirty || f.date_of_marriage.touched) &&  f.date_of_marriage.errors}">
                                                <input (click)="datePicker2.open()" class="form-control"
                                                    placeholder="{{this.messageService.selectplaceholddisplay('date of marriage')}}"
                                                    matInput formControlName="date_of_marriage"
                                                    [matDatepickerFilter]="dobFilter" [matDatepicker]="datePicker2"
                                                    (keydown)="disableDate()"><i class="icon-calendar pointer"
                                                    (click)="datePicker2.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                                            </div>
                                            <div *ngIf="(familySubmitted || f.date_of_marriage.dirty || f.date_of_marriage.touched) &&  f.date_of_marriage.errors"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="f.date_of_marriage.errors">{{this.messageService.validationDisplay('pattern')}}</div> -->

                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-row"
                                            *ngIf="(f.marital_status.value!='Single' && f.marital_status.value!=null)">
                                            <label for="spouse_name" class="form-label">Spouse name </label>
                                            <input type="text" class="form-control " name="spouse_name" id=""
                                                placeholder="{{this.messageService.placeholderdisp('Spouse Name')}}"
                                                formControlName="spouse_name"
                                                [ngClass]="{ 'is-invalid': (familySubmitted || f.spouse_name.dirty) &&  f.spouse_name.errors}">
                                            <div *ngIf="(familySubmitted || f.spouse_name.dirty) && f.spouse_name.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.spouse_name.errors.pattern">
                                                    {{this.messageService.validationDisplay('pattern')}}</div>
                                                <div
                                                    *ngIf="!(f.spouse_name.errors.pattern) && f.spouse_name.errors.maxlength">
                                                    {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-row"
                                            *ngIf="(f.marital_status.value!='Single' && f.marital_status.value!=null)">
                                            <label for="spouse_date_of_birth" class="form-label">Spouse DOB </label>
                                            <div class="input-group form-icon icon-right"
                                                [ngClass]="{ 'is-invalid': (familySubmitted || f.spouse_date_of_birth.dirty || f.spouse_date_of_birth.touched) &&  f.spouse_date_of_birth.errors}">
                                                <input (click)="datePicker3.open()" class="form-control"
                                                    placeholder="Spouse DOB" matInput
                                                    formControlName="spouse_date_of_birth"
                                                    [matDatepickerFilter]="dobFilter" [matDatepicker]="datePicker3"
                                                    (keydown)="disableDate()"><i class="icon-calendar pointer"
                                                    (click)="datePicker3.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
                                            </div>
                                            <div *ngIf="(familySubmitted || f.spouse_date_of_birth.dirty || f.spouse_date_of_birth.touched) &&  f.spouse_date_of_birth.errors"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="f.spouse_date_of_birth.errors">{{this.messageService.validationDisplay('pattern')}}</div> -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-container formArrayName="child" *ngFor="let ch of child().controls; let i = index">
                            <ng-container [formGroupName]="i">
                                <div class="col-12">
                                    <div class="card card-c2">
                                        <div class="card-body p-16 d-flex">
                                            <p class="fs-14 fw-500 mb-0">Children Details {{i+1}}</p>
                                            <a class="ms-auto"><i class="icon-trash pointer text-dark"
                                                    (click)="deleteChildSlider(i,child().controls[i].get('id')?.value)"></i>
                                            </a>
                                        </div>
                                        <div class="card-body px-16 py-25">
                                            <div class="row row-16">
                                                <div class="col-sm-6 form-row">
                                                    <label for="child_name" class="form-label required">Children name
                                                    </label>
                                                    <input type="text" class="form-control " name="" id="child_name"
                                                        placeholder="{{this.messageService.placeholderdisp('Children Name')}}"
                                                        formControlName="child_name"
                                                        [ngClass]="{ 'is-invalid': (child().controls[i].get('child_name')?.touched || child().controls[i].get('child_name')?.dirty || familySubmitted) &&  child().controls[i].get('child_name')?.errors}">
                                                    <div *ngIf="(familySubmitted || child().controls[i].get('child_name')?.dirty|| child().controls[i].get('child_name')?.touched) && child().controls[i].get('child_name')?.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="child().controls[i].get('child_name')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}</div>
                                                        <div
                                                            *ngIf="child().controls[i].get('child_name')?.errors?.pattern">
                                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                                        <div
                                                            *ngIf="!(child().controls[i].get('child_name')?.errors?.pattern) && child().controls[i].get('child_name')?.errors?.maxlength">
                                                            {{this.messageService.fieldlengthvalidation('word','100')}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 form-row">
                                                    <label for="child_date_of_birth"
                                                        class="form-label required">Children DOB </label>
                                                    <div class="input-group form-icon icon-right"
                                                        [ngClass]="{ 'is-invalid': (child().controls[i].get('child_date_of_birth')?.dirty || child().controls[i].get('child_date_of_birth')?.touched || familySubmitted) &&  child().controls[i].get('child_date_of_birth')?.errors}">
                                                        <input (click)="datePicker4.open()" class="form-control"
                                                            placeholder="Children DOB"
                                                            [ngClass]="{ 'is-invalid': (child().controls[i].get('child_date_of_birth')?.dirty || child().controls[i].get('child_date_of_birth')?.touched) &&  child().controls[i].get('child_date_of_birth')?.errors}"
                                                            matInput formControlName="child_date_of_birth"
                                                            [matDatepickerFilter]="dobFilter"
                                                            [matDatepicker]="datePicker4" (keydown)="disableDate()"><i
                                                            class="icon-calendar pointer"
                                                            (click)="datePicker4.open()"></i>
                                                        <mat-datepicker touchUi="false" #datePicker4></mat-datepicker>
                                                    </div>
                                                    <div *ngIf="(child().controls[i].get('child_date_of_birth')?.dirty || child().controls[i].get('child_date_of_birth')?.touched || familySubmitted) &&  child().controls[i].get('child_date_of_birth')?.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="child().controls[i].get('child_date_of_birth')?.errors?.required">
                                                        {{this.messageService.validationDisplay('required')}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 form-row">
                                                    <label for="" class="form-label required">Gender</label>
                                                    <app-master-select [placeholder]="genderPlaceHolder"
                                                        id="child_gender" [submitted]="familySubmitted"
                                                        [selectedItem]="child().controls[i].get('child_gender')?.value"
                                                        formControlName="child_gender" [type]="gender"
                                                        [ngClass]="{ 'is-invalid': ( child().controls[i].get('child_gender')?.dirty || child().controls[i].get('child_gender')?.touched || familySubmitted) && child().controls[i].get('child_gender')?.errors }"></app-master-select>
                                                    <div *ngIf="( child().controls[i].get('child_gender')?.dirty || child().controls[i].get('child_gender')?.touched || familySubmitted) && child().controls[i].get('child_gender')?.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="child().controls[i].get('child_gender')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 ">
                                                    <div class="form-check">
                                                        <label for="is_dependent{{i}}" class="form-check-label ">Is
                                                            dependent</label>
                                                        <input type="checkbox" class="form-check-input" name=""
                                                            id="is_dependent{{i}}" formControlName="is_dependent">
                                                    </div>
                                                </div>
                                                <div class="col-12 ">
                                                    <div class="form-check">
                                                        <label for="is_going_to_hostel{{i}}"
                                                            class="form-check-label ">Is going to hostel</label>
                                                        <input type="checkbox" class="form-check-input"
                                                            id="is_going_to_hostel{{i}}"
                                                            formControlName="is_going_to_hostel">
                                                    </div>
                                                </div>
                                                <div class="col-12 ">
                                                    <div class="form-check">
                                                        <label for="is_going_to_school{{i}}"
                                                            class="form-check-label ">Is going to school</label>
                                                        <input type="checkbox" class="form-check-input"
                                                            id="is_going_to_school{{i}}"
                                                            formControlName="is_going_to_school">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div class="col-12">
                            <div class="card card-c2 px-16">
                                <div class="card-body px-0 d-flex py-16">
                                    <p class="fs-14 mb-0 fw-600">New Section</p>
                                    <span class="ms-auto link-primary1 fs-14" (click)="addChild()">
                                        <i class="icon-plus-square-fill me-8"></i>Add Child Details
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ng-container formArrayName="dependant" *ngFor="let ch of dependant().controls; let j = index">
                            <ng-container [formGroupName]="j">
                                <div class="col-12">

                                    <div class="card card-c2">
                                        <div class="card-body d-flex p-16">
                                            <span class="fs-14 fw-600">Dependent Details {{j+1}}</span>
                                            <a class="ms-auto"><i class="icon-trash pointer text-dark"
                                                    (click)="deleteDependSlider(j,dependant().controls[j].get('id')?.value)"></i>
                                            </a>
                                        </div>
                                        <div class="card-body px-16 py-25">
                                            <div class="row row-16">
                                                <input type="text" value="" formControlName="id" id="id" hidden>
                                                <input type="text" value="" formControlName="family" id="family" hidden>
                                                <div class="col-sm-6 form-row">
                                                    <label for="dependent_name" class="form-label required">Dependent
                                                        name</label>
                                                    <input type="text" class="form-control " name="" id="dependent_name"
                                                        placeholder="{{this.messageService.placeholderdisp('dependent name')}}"
                                                        formControlName="dependent_name"
                                                        [ngClass]="{ 'is-invalid': (familySubmitted || dependant().controls[j].get('dependent_name')?.dirty||  dependant().controls[j].get('dependent_name')?.touched) &&  dependant().controls[j].get('dependent_name')?.errors}">
                                                    <div *ngIf="(familySubmitted || dependant().controls[j].get('dependent_name')?.dirty||  dependant().controls[j].get('dependent_name')?.touched) && dependant().controls[j].get('dependent_name')?.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="dependant().controls[j].get('dependent_name')?.errors?.pattern">
                                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                                        <div
                                                            *ngIf="dependant().controls[j].get('dependent_name')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}</div>
                                                        <div
                                                            *ngIf="!(dependant().controls[j].get('dependent_name')?.errors?.pattern) && dependant().controls[j].get('dependent_name')?.errors?.maxlength">
                                                            {{this.messageService.fieldlengthvalidation('word','100')}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6 form-row">
                                                    <label for="dependent_of_birth"
                                                        class="form-label required">Dependent DOB </label>
                                                    <div class="input-group form-icon icon-right"
                                                        [ngClass]="{ 'is-invalid': (familySubmitted || dependant().controls[j].get('dependent_of_birth')?.dirty || dependant().controls[j].get('dependent_of_birth')?.touched) &&  dependant().controls[j].get('dependent_of_birth')?.errors}">
                                                        <input (click)="datePicker5.open()" class="form-control"
                                                            placeholder="Dependent DOB"
                                                            [ngClass]="{ 'is-invalid': (familySubmitted || dependant().controls[j].get('dependent_of_birth')?.dirty || dependant().controls[j].get('dependent_of_birth')?.touched) &&  dependant().controls[j].get('dependent_of_birth')?.errors}"
                                                            matInput formControlName="dependent_of_birth"
                                                            [matDatepickerFilter]="dobFilter"
                                                            [matDatepicker]="datePicker5" (keydown)="disableDate()"><i
                                                            class="icon-calendar pointer"
                                                            (click)="datePicker5.open()"></i>
                                                        <mat-datepicker touchUi="false" #datePicker5></mat-datepicker>
                                                    </div>
                                                    <div *ngIf="(familySubmitted || dependant().controls[j].get('dependent_of_birth')?.dirty || dependant().controls[j].get('dependent_of_birth')?.touched) &&  dependant().controls[j].get('dependent_of_birth')?.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="dependant().controls[j].get('dependent_of_birth')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>

                                                    </div>
                                                </div>
                                                <div class="col-sm-6 form-row">
                                                    <label for="dependent_gender"
                                                        class="form-label required">Gender</label>
                                                    <app-master-select [placeholder]="genderPlaceHolder"
                                                        id="dependent_gender" [isrequired]='"true"'
                                                        [submitted]="familySubmitted"
                                                        [selectedItem]="dependant().controls[j].get('dependent_gender')?.value"
                                                        formControlName="dependent_gender" [type]="gender"
                                                        [ngClass]="{ 'is-invalid': ( dependant().controls[j].get('dependent_gender')?.touched || familySubmitted) && dependant().controls[j].get('dependent_gender')?.errors }"></app-master-select>
                                                    <div *ngIf="( dependant().controls[j].get('dependent_gender')?.touched || familySubmitted) && dependant().controls[j].get('dependent_gender')?.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="dependant().controls[j].get('dependent_gender')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 form-row">
                                                    <label for="dependent_relation" class="form-label required">Relation
                                                    </label>
                                                    <app-master-select [placeholder]="relationPlaceHolder"
                                                        id="dependent_relation" [isrequired]='"true"'
                                                        [submitted]="familySubmitted"
                                                        [selectedItem]="dependant().controls[j].get('dependent_relation')?.value"
                                                        formControlName="dependent_relation" [type]="relation"
                                                        [ngClass]="{ 'is-invalid': ( dependant().controls[j].get('dependent_relation')?.touched || familySubmitted) && dependant().controls[j].get('dependent_relation')?.errors }"></app-master-select>
                                                    <div *ngIf="( dependant().controls[j].get('dependent_relation')?.touched || familySubmitted) && dependant().controls[j].get('dependent_relation')?.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="dependant().controls[j].get('dependent_relation')?.errors?.required">
                                                            {{this.messageService.validationDisplay('required')}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 form-row ">
                                                    <label for="dependent_remark" class="form-label">Remarks </label>
                                                    <textarea rows="4" type="text" class="form-control " name=""
                                                        id="dependent_remark"
                                                        placeholder="{{this.messageService.placeholderdisp('remarks')}}"
                                                        formControlName="dependent_remark"
                                                        [ngClass]="{ 'is-invalid': (familySubmitted || dependant().controls[j].get('dependent_remark')?.dirty) &&  dependant().controls[j].get('dependent_remark')?.errors}">
                          </textarea>
                                                    <div *ngIf="(familySubmitted || dependant().controls[j].get('dependent_remark')?.dirty) && dependant().controls[j].get('dependent_remark')?.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="dependant().controls[j].get('dependent_remark')?.errors?.maxlength">
                                                            {{this.messageService.fieldlengthvalidation('word','500')}}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div class="col-12">
                            <div class="card card-c2 px-16">
                                <div class="card-body px-0 d-flex py-16">
                                    <p class="fs-14 mb-0 fw-600">New Section</p>
                                    <span class="ms-auto link-primary1 fs-14" (click)="addDependant()">

                                        <i class="icon-plus-square-fill me-8"></i>Add Dependent Details
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </ng-container>

            </div>
        </div>
        <footer class="side-panel-footer gap-32" *ngIf="!loader1">
            <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="familySubmitted || completeFamily.invalid">Save</button>
        </footer>
    </form>
</div>
<!-- END COMPLETE FAMILY DATA -->



<!--Delete confirmation box for child-->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Delete {{deleteText}}?</h4>
            </div>
            <div class="modal-body">
                <p class="mb-0">{{this.messageService.Deletemsgdisplay(deleteText)}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                    (click)="deleteToggle = false">Cancel</button>
                <button *ngIf="deleteText=='child'" type="button" class="btn btn-danger btn-sm text-uppercase"
                    (click)="deleteChildConfirm()">Delete</button>
                <button *ngIf="deleteText=='dependency'" type="button" class="btn btn-danger btn-sm text-uppercase"
                    (click)="deleteDependConfirm()">Delete</button>
            </div>

        </div>
    </div>
</div>

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
    <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- InfoPopup end -->
