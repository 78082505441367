import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PayrollReportConfigureComponent } from 'src/app/@module/hr/reports/payroll//payroll-report-configure/payroll-report-configure.component';

/************STATIC DEACTIVATE GUARD *****************/
// @Injectable({
//   providedIn: 'root'
// })
// export class DeacivateGuard implements CanDeactivate<PayrollReportConfigureComponent> {

//   canDeactivate(
//     component: PayrollReportConfigureComponent,
//     currentRoute: ActivatedRouteSnapshot,
//     currentState: RouterStateSnapshot,
//     nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//       let can = component.canDeactivate();
//       if (!can) {
//         return false;
//       }
//       return true;
//   }

// }

/************ DYNAMIC DEACTIVATE GUARD *****************/
export interface CanComponentDeactivate {
  confirm(): boolean;
}
@Injectable()
export class DeacivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

      if (!component.confirm()) {
        return false;
      }else{
        return true;
      }
    }
}
