import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';

@Component({
  selector: 'app-leave-year-end-settings',
  templateUrl: './leave-year-end-settings.component.html',
  styleUrls: ['./leave-year-end-settings.component.scss']
})
export class LeaveYearEndSettingsComponent implements OnInit {
  @Input() formGp: any;
  @Input() policyDetailsForm: any;

  @Output() submitmethod = new EventEmitter();
  saveBtnClicked     : boolean = false
  leaveYearEndMonth:any


  constructor(public messageService : MessageService,public appService: AppService,public fb : FormBuilder,public leavetypeService:leavetypeService) { }

  ngOnInit(): void {
    this.setLeaveyerEndMonth();
    if(this.formGp.controls['manual_auto'].value){
      this.setRequired('process_to_carriedon_month');this.setRequired('process_to_carron_date')
    } else {
      this.removeRequired('process_to_carriedon_month');this.removeRequired('process_to_carron_date')
    }
  }

 get f() {return  this.formGp.controls; }


  validateForm(){
    this.saveBtnClicked=true;
    if(this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }
  setLeaveyerEndMonth(){
    if(this.policyDetailsForm.controls.leave_cycle.value==false){
      this.leaveYearEndMonth = ['January']
      if(this.f.process_to_carriedon_month.value != 'January')
        this.f.process_to_carriedon_month.setValue(null)
    }else {
      this.leaveYearEndMonth = ['April']
      if(this.f.process_to_carriedon_month.value != 'April')
        this.f.process_to_carriedon_month.setValue(null)
    }
  }
  setRequired(name:any) {
    this.formGp.controls[name].enable();
    this.formGp.get(name).setValidators([Validators.required])
    this.formGp.controls[name].updateValueAndValidity();
  }
  removeRequired(name:any){
    this.formGp.controls[name].setValue(null);
    this.formGp.controls[name].disable();
    this.formGp.get(name).clearValidators()
    this.formGp.controls[name].updateValueAndValidity();
  }


}
