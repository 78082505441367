<div class="container-fluid p-24 " *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Generate Challan</h3>
            <p class="text-helper mb-md-0">Generate and manage the statutory challans here.</p>
        </div>
        <div class="col-md-8 col-lg-6 d-flex">
          <div class="ms-auto" style="width:9rem">
            <ng-select class="form-ngselect h-36 fs-14 w-100" [clearable]="false" (change)="yearChange($event)"
              [(ngModel)]="placeholderData">
              <ng-option class="fs-12" *ngFor="let year of nextYears" [value]="year">
                {{year}} </ng-option>
            </ng-select>
          </div>

            <!-- <ng-select class="form-ngselect ms-auto" style="max-width: 346px;" [clearable]="false" (change)="yearChange($event)"
            [(ngModel)]="placeholderData">
            <ng-option class="fs-12" *ngFor="let year of nextYears" [value]="year">
              {{year}} </ng-option>
          </ng-select> -->
        </div>

    </div>
    <div class="row row-16">
        <div class="col-lg-2 col-md-3">
            <div class="card card-c2">
                <div class="card-body py-24 px-0 ">
                    <div class="text-uppercase fs-12 fw-500 px-24 text-light-500 pb-8">CHALLAN</div>
                    <ul ngbNav #nav="ngbNav" [activeId]="activeTab" class=" vetical-tab tab-scroll overflow-auto">
                        <li [ngbNavItem]="1" title="Provident fund">
                            <a ngbNavLink class="tab-link text-trim">Provident fund</a>
                            <ng-template ngbNavContent>
                             <app-challan-generation-provident-fund [fyArray]="fyArray" [fMonth]="fMonth" [changedYear]="changedYear"></app-challan-generation-provident-fund>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2" title="Employee state insurance">
                            <a ngbNavLink class="tab-link text-trim">Employee state insurance</a>
                            <ng-template ngbNavContent>
                            <app-challan-generation-employee-state-insurence  [fyArray]="fyArray" [fMonth]="fMonth" [changedYear]="changedYear"></app-challan-generation-employee-state-insurence>
                            </ng-template>
                        </li>
                        <!-- <li [ngbNavItem]="">
                            <a ngbNavLink class="tab-link text-trim">Labour welfare fund </a>
                            <ng-template ngbNavContent>
                                <div class="card card-c2 p-24"></div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="">
                            <a ngbNavLink class="tab-link text-trim">Professional tax</a>
                            <ng-template ngbNavContent>
                                <div class="card card-c2 p-24"></div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="">
                            <a ngbNavLink class="tab-link text-trim">Income tax</a>
                            <ng-template ngbNavContent>
                                <div class="card card-c2 hstack align-items-start">
                                    <div class=" ">
                                        <div class="card-body px-24 pt-24">
                                            <div class="fw-500">Income Tax (FY 2020 - 2021)</div>
                                        </div>
                                        <div class="card-body p-0">
                                            <nav class="nav nav-custom shadow-0">
                                                <a class="nav-link {{activeTab2 == 'challan' ? 'active' : ''}}"
                                                    (click)="activeTab2 = 'challan'">Challan</a>
                                                <a class="nav-link {{activeTab2 == 'returns' ? 'active' : ''}}"
                                                    (click)="activeTab2 = 'returns'">Generate Returns</a>
                                            </nav>
                                        </div>
                                        <div class="card-body p-24">
                                            <ng-container *ngIf="activeTab2 == 'challan'">
                                                <div class="row row-16">
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex view-section  align-items-center">
                                                                    <div class="logo-img  cbg-accent2">Q1 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">1st Quarter</h6>
                                                                        <span class="tips-helper">1st Quarter</span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Quarter duration:
                                                                        </span> Apr 2020 - Jun 2020 </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex view-section  align-items-center">
                                                                    <div class="logo-img  cbg-warning">Q2 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">2nd Quarter</h6>
                                                                        <span class="tips-helper">2nd Quarter </span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Quarter duration:
                                                                        </span> Apr 2020 - Jun 2020 </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex view-section  align-items-center">
                                                                    <div class="logo-img  cbg-secondary">Q3 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">3rd Quarter </h6>
                                                                        <span class="tips-helper"> 3rd Quarter </span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Quarter duration:
                                                                        </span> Apr 2020 - Jun 2020 </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex view-section  align-items-center">
                                                                    <div class="logo-img  cbg-success">Q4 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">4th Quarter </h6>
                                                                        <span class="tips-helper">4th Quarter</span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Quarter duration:
                                                                        </span> Apr 2020 - Jun 2020 </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="activeTab2 == 'returns'">
                                                <div class="row row-16">
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex w-100 align-items-center p-16">
                                                                    <div class="logo-img  cbg-accent2">G1 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">Group 1 </h6>
                                                                        <span class="tips-helper">Group 1</span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                    <a class="link-primary ms-auto fs-12 fw-500"
                                                                        (click)="downloadChallan = true"><i
                                                                            class="icon-download me-1"></i> Download</a>
                                                                </div>
                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Processed
                                                                            Employee Count : </span> 5/5 </span>
                                                                    <span class="link-primary fw-500"
                                                                        (click)="generateChallan = true">Regenerate
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex w-100 align-items-center p-16">
                                                                    <div class="logo-img  cbg-warning">G2 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">Group 1 </h6>
                                                                        <span class="tips-helper">Group 1</span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                    <a class="link-primary ms-auto fs-12 fw-500"
                                                                        (click)="downloadChallan = true"><i
                                                                            class="icon-download me-1"></i> Download</a>
                                                                </div>
                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Processed
                                                                            Employee Count : </span> 5/5 </span>
                                                                    <span class="link-primary fw-500"
                                                                        (click)="generateChallan = true">Regenerate
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex w-100 align-items-center p-16">
                                                                    <div class="logo-img   cbg-secondary">G3 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">Group 1 </h6>
                                                                        <span class="tips-helper">Group 1</span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                    <a class="link-primary ms-auto fs-12 fw-500"
                                                                        (click)="downloadChallan = true"><i
                                                                            class="icon-download me-1"></i> Download</a>
                                                                </div>
                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Processed
                                                                            Employee Count : </span> 5/5 </span>
                                                                    <span class="link-primary fw-500"
                                                                        (click)="generateChallan = true">Regenerate
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="card card-custom1 card-hover">
                                                            <div class="card-body p-0 d-flex align-items-start">
                                                                <div class="d-flex w-100 align-items-center p-16">
                                                                    <div class="logo-img  cbg-success">G4 </div>
                                                                    <div class="overflow-hidden">
                                                                        <h6 class="mb-1 tips">Group 1 </h6>
                                                                        <span class="tips-helper">Group 1</span>
                                                                        <div
                                                                            class="fs-10 text-uppercase fw-500  text-success ">
                                                                            PROCESSED</div>
                                                                    </div>
                                                                    <a class="link-primary ms-auto fs-12 fw-500"
                                                                        (click)="downloadChallan = true"><i
                                                                            class="icon-download me-1"></i> Download</a>
                                                                </div>
                                                            </div>
                                                            <div class="card-body flex-column ">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 gap-8 fs-12">
                                                                    <span class="text-trim "><span
                                                                            class="text-light-400 ">Processed
                                                                            Employee Count : </span> 5/5 </span>
                                                                    <span class="link-primary fw-500"
                                                                        (click)="generateChallan = true">Regenerate
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-10 col-md-9">
            <div [ngbNavOutlet]="nav"></div>

        </div>

    </div>
</div>


