import { Component, OnInit } from '@angular/core';
import { HomeSettingQuicklinkService } from 'src/app/@core/services/homesettingquicklink.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {
  loader          : boolean = false;
  loaderMenu      : boolean = false;
  listData        : any = [];
  currentmenu     : any;
  menudata        : any = [];
  updateddata     : any = [];
  permissions     : any;
  constructor(
    private quicklinkservice : HomeSettingQuicklinkService,
    private notifyToast: NotificationService,
    public appService  : AppService,
  ) { }

  ngOnInit(): void {
    this.loaderMenu =true;
    this.getList();
    this.getPermission(); 
  }

    getPermission(){
      this.permissions = this.appService.getPermissions('/home-page');
      if(this.permissions == undefined){
        setTimeout(() => {
          this.getPermission();
        }, 1000);
      }
    }

  getList(){
    this.loader = true
    this.quicklinkservice.getListAPI().subscribe((res:any)=>{
      this.listData = res?.body?.data
      this.loaderMenu = false;
      this.menuclick(this.listData[0])
    })
  }
  menuclick(data:any){
    this.currentmenu = data.menu;
    this.loader = true;
    if(this.currentmenu == 'Quick Links' ){
      this.menudata = data?.QuickLinks_data;
      this.loader = false;
    }else if(this.currentmenu == 'Internal Links' ){
      this.menudata = data.InternalLinks_data;
      this.loader = false;
    }
  }
  linkchecked(event:any,index:any,data:any){
    let checkedkey = event.target.checked;
    this.loader = true;
    if(checkedkey != data.button_enable){
      let updateddata = {
        "id": data.id,
        "section": data.section,
        "name": data.name,
        "button_enable": checkedkey,
        "disable_enable": !checkedkey,
        "module": data.module
      }
      this.quicklinkservice.updateList(data.id,updateddata).subscribe((res:any)=>{
        this.notifyToast.handleSuccessNotification("Updated Successfully","Success");
        this.getList();
      },(error:any)=>{
        this.notifyToast.handleErrorNotification('Something went wrong','Error')
      })
    }
  }

}
