import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { MessageService } from 'src/app/message.global';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { AppService } from 'src/app/app.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-proposed-hra80-gg',
  templateUrl: './proposed-hra80-gg.component.html',
  styleUrls: ['./proposed-hra80-gg.component.scss']
})

export class ProposedHra80GGComponent implements OnInit {

  @Input()cityList:any            = [];
  @Input()submitted:boolean       = false;
  @Input()loader:any;
  @Input()limitData:any;
  @Input()currency:any;
  @Input()empId:any;
  @Input()from:any;

  @Output() submitmethod          = new EventEmitter();
  @Output() onHra80ggValidation   = new EventEmitter<any>();

  HRA: boolean                    = true;
  isChecked: boolean              = true;
  monthDropdownPlaceHolder        = this.messageService.selectplaceholddisplay('month');
  listOfMonth:string              = "listOfMonth";
  cityDropdownPlaceHolder         = this.messageService.selectplaceholddisplay('city');
  metro_nonmetro_city:string      = "metro_nonmetro_city";
  inputId: string                 = "landlord-doc";
  isrequired                      = false;
  pan_required                    = false;
  newTaxRegime                    = false;
  hraValue: any                   = 1;
  alertShow                       = 'show';
  loader1                         = false;
  amountValue:number              = 0;
  hra80GGCheckBox:any             = false;
  investData:any                  = [];

  // FY ne updation
  fyArray:any                    = [];
  listOfMonths                   = ["January","February","March","April","May","June","July",
  "August","September","October","November","December"];
  fromMonths: any                = [];
  toMonths:any                   = [];
  lopMonthArray:any              = [];
  currentMonth                   = new Date().toLocaleString('en-us', { month: 'long' })
  currentYear                    = Number(new Date().getFullYear());
  currentFY                      = this.appService.getFinancialYear();


  constructor(
    private investmentService:InvestmentDeclerationService,
    public messageService : MessageService,
    public fb:FormBuilder,
    public appService: AppService,
    private activityService:ManageActivityService
    ) { }

  ngOnInit(): void {

    this.newTaxRegimeValidation();
    // this.hra80GGCheckFunction();
  }

  @Input()formGp : FormGroup = this.fb.group({
    hra80gg           : []
  });

  /********* FORM ARRAY CREATION FUNCTIONS ****************/
  hra80ggs() : FormArray {
    return this.formGp.get("hra80gg") as FormArray
  }

  newHra80gg(): FormGroup {
    if(this.hraValue == 1){
      return this.fb.group({
        "select_one"        : this.hraValue == 1 ? true : false,
        "from_month"        : ['April',[Validators.required]],
        "to_month"          : ['April',[Validators.required]],
        "amount"            : [,[Validators.required, digitdecimalValidator(),Validators.maxLength(15)]],
        "landlord_name"     : [,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "landlord_pan"      : [,[PanfieldValidator()]],
        "landlord_address"  : [,[Validators.required,Validators.maxLength(500)]],
        "landlord_document" : [''],
        "city"              : [,[Validators.required]],
        "from_year"         : [this.currentFY,[Validators.required]],
        "to_year"           : [this.currentFY,[Validators.required]],
      })
    }
    if(this.hraValue == 0){
      return this.fb.group({
        "select_one"        : this.hraValue == 1 ? true : false,
        "from_month"        : [null],
        "to_month"          : [null],
        "amount"            : [,[Validators.required, digitdecimalValidator(),Validators.maxLength(15)]],
        "landlord_name"     : [null],
        "landlord_pan"      : [null],
        "landlord_address"  : [null],
        "landlord_document" : [null],
        "city"              : [null],
        "from_year"         : [null],
        "to_year"           : [null],
      })
    }
    else{
      return this.fb.group({
        "select_one"        : this.hraValue == 1 ? true : false,
        "from_month"        : null,
        "to_month"          : null,
        "amount"            : [''],
        "landlord_name"     : [null],
        "landlord_pan"      : [null],
        "landlord_address"  : [null],
        "landlord_document" : [''],
        "city"              : null,
        "from_year"         : [null],
        "to_year"           : [null],
      })
    }
  }

  addHra80gg() {
    this.hra80ggs().push(this.newHra80gg());

  }

  removeHra80gg(i:number) {
    this.hra80ggs().removeAt(i);
  }

  removeAllHra80gg(){
    if(this.formGp.value.hra80gg.length > 0){
      while(this.formGp.value.hra80gg.length !== 0){
        this.hra80ggs().removeAt(0);
      }
    }
  }

  get f() {return  this.formGp.controls; }

  /********* RADIO BUTTON FUNCTION ****************/
  mainCheckBox(e:any) {
    if(e == 'HRA1'){
      this.onHra80ggValidation.emit(true);
    }else{
      this.onHra80ggValidation.emit(false);
    }
  }

  /********* PAN CARD VALIDATION ****************/
  validPan(index:any) {

    // Statutory cofiguration start
    let actual_rent_paid_limit:any;
    if(this.investData.count_info.hasOwnProperty('statutory_tax_settings')){
        actual_rent_paid_limit = Number(this.investData.count_info.statutory_tax_settings.actual_rent_paid_limit)
    }
    // Statutory cofiguration end

    if(Number((<FormArray>this.formGp.get("hra80gg")).at(index).get('amount')?.value) > actual_rent_paid_limit){
      this.pan_required = true;
      (<FormArray>this.formGp.get("hra80gg")).at(index).get('landlord_pan')?.setValidators([Validators.required,PanfieldValidator()]);
    }else{
      this.pan_required = false;
      (<FormArray>this.formGp.get("hra80gg")).at(index).get('landlord_pan')?.setValidators([PanfieldValidator()]);
    }
    (<FormArray>this.formGp.get("hra80gg")).at(index).get('landlord_pan')?.updateValueAndValidity();
  }

  /********* NEW TAX REGIME VALIDATION ****************/
  newTaxRegimeValidation(){

    this.hraValue = 1;
    this.loader1  = true;
    if(this.from=='behalf'){
      this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
        this.setTaxRegime(res)
      })
    }
    else{

      this.investmentService.requestDetails().subscribe((res:any)=>{
        this.setTaxRegime(res)
      });
    }
  }
  setTaxRegime(res:any){
    this.investData = res;
      if(res.count_info.hasOwnProperty('assigned_salary_data')){
        if(res.count_info.assigned_salary_data.HRA == 0){
          this.mainCheckBox('HRA2');
        }
        else{
          this.mainCheckBox('HRA1');
        }
        this.loader1  = false;
        this.hraValue = res.count_info.assigned_salary_data.HRA;
        this.hra80GGCheckFunction();
      }
  }
  /********* SUBMITT ****************/
  validateHra80gg(btnText:any) {
    if(btnText == 'skip'){
      this.removeAllHra80gg();
      this.hra80GGCheckFunction();
    }
    else{
      this.submitted = true;
      if (this.formGp.invalid) {
        return;
      }
    }
    this.submitmethod.emit();
  }

  /********* CHECK BOX FUNCTIONS ****************/
  checkBoxFunctionTest(e:any){
    if(e.target.checked == true){
      this.hra80GGCheckBox  = true;
      this.addHra80gg();
      this.fyArrayCreation();
      this.fromMonthArrayCreation();
      this.toMonthArrayCreation();
    }else{
      this.hra80GGCheckBox  = false;
      this.amountValue      = 0;
      this.removeAllHra80gg();
    }

  }

  hra80GGCheckFunction(){
    if(this.formGp.value.hra80gg?.length > 0 ){
      this.fyArrayCreation();
      this.fromMonthArrayCreation();
      this.toMonthArrayCreation();
      this.hra80GGCheckBox  = true;
      var elements = document.getElementsByName("hra_check");
      setTimeout(() => {
        for(let i=0;i<elements?.length;i++){
          var element1 = <HTMLInputElement>elements[i];
          element1.checked = true;
        }
      }, 10);
      this.loader1  = false;
    }else{
      this.hra80GGCheckBox  = false;
      var elements = document.getElementsByName("hra_check");
      setTimeout(() => {
        for(let i=0;i<elements?.length;i++){
          var element1 = <HTMLInputElement>elements[i];
          element1.checked = false;
        }
      }, 10);
      this.loader1  = false;
    }
  }

  /********* TOTAL DECLARED AMOUNT FUNCTION ****************/
  totalDeclaredAmountFunction(){
    if(this.formGp.value.hra80gg?.length > 0){
      this.amountValue = 0;

      if(this.hraValue == 1){
        for(let i=0;i<this.formGp.value.hra80gg.length;i++){
          let fromMonthNumber = this.appService.getMonthNumberFromName(((<FormArray>this.formGp.get("hra80gg")).at(i).get('from_month')?.value));
          let toMonthNumber = this.appService.getMonthNumberFromName(((<FormArray>this.formGp.get("hra80gg")).at(i).get('to_month')?.value));
          let monthDiff = (toMonthNumber - fromMonthNumber) + 1;

          let fromYear          = ((<FormArray>this.formGp.get("hra80gg")).at(i).get('from_year')?.value);
          let toYear            = ((<FormArray>this.formGp.get("hra80gg")).at(i).get('to_year')?.value);
          const yearsDifference = Number(toYear) - Number(fromYear);
          const totalDiff       = yearsDifference * 12 + monthDiff;
          let totalMonthAmt     = totalDiff * Number((<FormArray>this.formGp.get("hra80gg")).at(i).get('amount')?.value);

          // let totalMonthAmt = monthDiff * Number((<FormArray>this.formGp.get("hra80gg")).at(i).get('amount')?.value)
          this.amountValue = Number(this.amountValue) + Number(totalMonthAmt);
        }
      }else if(this.hraValue == 0){
        for(let i=0;i<this.formGp.value.hra80gg.length;i++){
          this.amountValue = Number(this.amountValue) + Number((<FormArray>this.formGp.get("hra80gg")).at(i).get('amount')?.value);
        }
      }

    }

    return this.amountValue;
  }

  // FY updation

  fyArrayCreation(){
    this.fyArray = [];
    this.fyArray?.push(this.currentFY,Number(this.currentFY) + 1);
  }

  fromMonthArrayCreation(){
    if(this.formGp.value.hra80gg?.length > 0){
      for(let i=0;i<this.formGp.value.hra80gg?.length;i++){

          if(Number((<FormArray>this.formGp.get("hra80gg")).at(i).get('from_year')?.value) == this.currentFY){
            this.fromMonths[i] = this.listOfMonths.slice(3);
          }else{
            this.fromMonths[i] = this.listOfMonths.slice(0,-9);
          }

      }
    }

  }

  toMonthArrayCreation(){
    if(this.formGp.value.hra80gg?.length > 0){
      for(let i=0;i<this.formGp.value.hra80gg?.length;i++){

          if(Number((<FormArray>this.formGp.get("hra80gg")).at(i).get('to_year')?.value) == this.currentFY){
            this.toMonths[i] = this.listOfMonths.slice(3);
          }else{
            this.toMonths[i] = this.listOfMonths.slice(0,-9);
          }
      }
    }

  }

  changeFromMonthCarousel(key:any,field:any,index:any){

    if(field == 'month'){
      (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.setValue(key);

    }else{
      this.fromMonths[index]  = [];
      if(Number(key) != this.currentFY){
        this.fromMonths[index] = this.listOfMonths.slice(0,-9);
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.setValue('January');
      }
      else{
        this.fromMonths[index] = this.listOfMonths.slice(3);
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.setValue('April');
      }
      (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_year')?.setValue(key);

    }

    if((<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.value != null && (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.value != ''){
      if((((<FormArray>this.formGp.get("hra80gg")).at(index).get('from_year')?.value) == ((<FormArray>this.formGp.get("hra80gg")).at(index).get('to_year')?.value)) && (this.appService.getMonthNumberFromName(((<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.value)) > this.appService.getMonthNumberFromName(((<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.value)))){
        (<FormArray>this.formGp.get("hra80gg"))?.at(index).get('from_month')?.setErrors({
           'greater' : true,
         }),
         (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.setErrors(null)
      }else if((((<FormArray>this.formGp.get("hra80gg")).at(index).get('from_year')?.value) >  ((<FormArray>this.formGp.get("hra80gg")).at(index).get('to_year')?.value))){
        (<FormArray>this.formGp.get("hra80gg"))?.at(index).get('from_month')?.setErrors({
          'greater' : true,
        }),
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.setErrors(null)
      }
      else{
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.setErrors(null);
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.setErrors(null)
      }
    }

  }

  changeToMonthCarousel(key:any,field:any,index:any){

    if(field == 'month'){
      (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.setValue(key);

    }else{
      this.toMonths[index]  = [];
      if(Number(key) != this.currentFY){
        this.toMonths[index] = this.listOfMonths.slice(0,-9);
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.setValue('January');
      }
      else{
        this.toMonths[index] = this.listOfMonths.slice(3);
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.setValue('April');
      }
      (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_year')?.setValue(key);

    }

    if((<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.value != null && (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.value != ''){
      if((((<FormArray>this.formGp.get("hra80gg")).at(index).get('from_year')?.value) == ((<FormArray>this.formGp.get("hra80gg")).at(index).get('to_year')?.value)) && (this.appService.getMonthNumberFromName(((<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.value)) > this.appService.getMonthNumberFromName(((<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.value)))){
        (<FormArray>this.formGp.get("hra80gg"))?.at(index).get('to_month')?.setErrors({
           'greater' : true,
         }),
         (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.setErrors(null)
      }else if((((<FormArray>this.formGp.get("hra80gg")).at(index).get('from_year')?.value) > ((<FormArray>this.formGp.get("hra80gg")).at(index).get('to_year')?.value))){
        (<FormArray>this.formGp.get("hra80gg"))?.at(index).get('to_month')?.setErrors({
          'greater' : true,
        }),
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.setErrors(null);
      }
      else{
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('to_month')?.setErrors(null);
        (<FormArray>this.formGp.get("hra80gg")).at(index).get('from_month')?.setErrors(null)
      }
    }
  }

  // End

}
