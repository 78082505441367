import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { InviteEmployeeService } from 'src/app/@core/services/invite-employee.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import {Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-send-new-user',
  templateUrl: './send-new-user.component.html',
  styleUrls: ['./send-new-user.component.scss']
})
export class SendNewUserComponent implements OnInit {

  searchString    = ''
  dataFilter      : any = this.inviteEmp.FILTERDATA
  successMsg      : any
  errorMsg        : any
  loader          : boolean = false
  noData          : boolean = false
  errorLog        : boolean = false
  downloadMsg     : boolean = false
  canSave         : boolean = true;

  invitePermission: any
  inviteData      : any = []
  count           = 0
  objLength       : any = []
  datas           : any = []
  filterData      : any = []
  failedData      : any = []
  exportData      : any = []
  list            : any = []


  page            = 1
  pageSize        = 20

  filterpanel     : boolean  = false
  from            = 'send-new-user';

  // Form for Filter
  filterForm  = this.formBuilder.group({
    company       : '',
    bu            : '',
    branch        : '',
    department    : '',
    designation   : '',
    grade         : '',
    emp_status    : [['Probation','Confirmed']],
    emp_type    : '',
    repomanager    : '',
    funmanager    : '',
    sortby    : 'Alphabetically (A-Z)',
  })

  canGenerate : boolean = true;
  gnrtdPwddPop = false
  gnrtdPwdList:any[] = []
This: any;

  constructor(
    public messageService : MessageService,
    private formBuilder   : FormBuilder,
    public appService     : AppService,
    public inviteEmp      : InviteEmployeeService,
    public notificationService : NotificationService,
    public clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    // this.getInviteData('new')
    this.getInviteDataFilter();
    // Permissions
    this.getInvite()
    let arrFilter: any = []
    let arrList = []
    let arrData = []
    if(this.dataFilter){
      arrData = this.dataFilter.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })

   
      let ordering:any = this.appService.memOrdering()
      this.filterForm.reset(this.appService.memFilterArray(arrFilter,ordering));
    }

  }

  // API
  getInviteData(value:any){
    this.loader = true
    this.inviteEmp.getInviteData(value,'Probation,Confirmed').subscribe((res:any)=>{
      if(res.body.length != 0){
        this.noData = false
        this.inviteData = res.body
      }else{
        this.noData = true
      }
      this.loader = false
    })
  }

  // FilterAPI
  getInviteDataFilter(){
    this.loader = true
    this.inviteEmp.getFilteredData(this.dataFilter).subscribe((res:any)=>{
      this.inviteData = []
      if(res.body.length != 0){
        this.noData = false
        this.filterData = res.body
        this.inviteData = this.filterData
      }else{
        this.noData = true
      }
      this.loader = false
    })
    this.filterpanel = false
  }

  // Filter
  resultfilterfn(data:any){
    this.dataFilter = data;
    this.inviteEmp.setFilterData(data)
    this.searchString = ''
    this.getInviteDataFilter();
  }

  // Close Panel
  closefn(daata:any){
    this.filterpanel = daata;
  }

  // PERMISSION
  getInvite(){
    this.invitePermission = this.appService.getPermissions('');
    if(this.invitePermission == undefined){
      setTimeout(() => {
        this.getInvite();
      }, 1000);
    }
  }

  // Data
  filterIt(){
    return this.inviteData.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  actualCount(){
    this.count = this.inviteData.filter((obj:any) =>obj.checked === true).length
    this.objLength = this.inviteData.filter((obj:any) =>obj.checked === true)
  }

  // CheckAll
  checkAll(value:any){
    let list = this.filterIt()
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = list.slice(start,end)
    slicedArray.forEach((item:any)=>{
      item.checked = value.target.checked
    })
    this.actualCount()
  }

  // Checked Property
  pageChange(){
    let list = this.filterIt();
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = list.slice(start,end)
    if(slicedArray.length){
      return slicedArray.every((item:any)=>{
        return item.checked
      })
    }else{
      return false;
    }
  }

  // Whole Checked data
  getWholeData(){
    return this.inviteData.filter((item:any)=> item.checked == true)
  }

  // Individual Checking
  individualData(value:any,id:any){
    let data = this.getWholeData()
    data.forEach((item:any)=>{
      this.datas.push(item.id)
    })
    this.actualCount()
  }

  // Cancel Selection
  cancelSelect(){
    this.appService.unselectAll(this.inviteData)
    this.actualCount()
  }

  // Send Data to Parent
  sendPassWord(){
    this.canSave = false;
    this.datas = []
    let data = this.getWholeData()
    data.forEach((item:any)=>{
      this.datas.push(item.id)
    })
    this.list =  this.datas.length != 0 ?JSON.stringify(this.datas):JSON.stringify([])
    let dta = `{"invite_category":"new","employee_list":${this.list}}`
    this.inviteEmp.sendPassword(dta).subscribe((res:any)=>{
      if(res.status == 200 && res.body.failed_employee_list.length ==0){
        this.searchString = ''
        this.datas = []
        this.objLength = []
        this.notificationService.handleSuccessNotification("Password successfully sent to the selected employees.","Success")
        // this.getInviteData('new');
        this.getInviteDataFilter();
      }else{
        this.errorLog = true
        this.exportData = []
        this.failedData = res.body.failed_employee_list
        for(let i = 0; i < this.failedData.length; i++){
          this.exportData.push({'Employee Code' : this.failedData[i]['data'],'Error Description' : this.failedData[i]['error']})
        }
        this.datas = this.datas.filter((val:any) => !this.failedData.includes(val));
        // console.log(this.datas)
        this.inviteData.forEach((item:any)=>{
          this.datas.forEach((data:any)=>{
            if(item.id == data){
              item.checked = false
            }
          })
        })
        this.successMsg = (this.datas.length - this.failedData.length) +" out of "+this.datas.length+" employee(s) password sent successfully"
        this.errorMsg =this.failedData.length+' employee(s) show error data'

      }
      this.canSave = true
    })
  }

  close(){
    this.errorLog = false
    // this.getInviteData('new')
    this.getInviteDataFilter();
  }

  generatePassWord(){
    this.canGenerate = false;
    let datas: any[] = []
    let data = this.getWholeData()

    data.forEach((item:any)=>{
      datas.push(item.id)
    })
    this.inviteEmp.generatePassword({"employee_list":datas}).subscribe((res:any)=>{
      this.gnrtdPwddPop = true
      this.gnrtdPwdList = res.body.geneared_employee_list
    },(error:any)=>{
      this.canGenerate = true;
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    })

  }
  closePwdList(){
    this.canGenerate = true;
    this.gnrtdPwddPop = false
    // this.getInviteData('new')
  }


}
