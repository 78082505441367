
    <div class="container-fluid p-32 form-container" [formGroup]="formGp">
        <div class="row ">
            <div class="col-12 d-flex justify-content-between flex-wrap">
                <div>
                    <p class="text-uppercase fw-500 mb-0">Employee statutory</p>
                    <p class="text-helper mb-0">Add Employee statutory details </p>
                </div>
            </div>
        </div>
        <div class="col-12 mt-16" *ngIf="Isalert && (empSalaryData.message!='' && empSalaryData.message!=null)">
          <div role="alert" class="alert alert-warning mb-0 show">
              <p class="mb-0 fs-14">{{empSalaryData.message}}</p>
              <div class="btn-close pointer" (click)="Isalert=false;"></div>
          </div>
        </div>
        <div class="row mt-24">
            <div class="col">
                <div class="card card-c2  px-16 ">
                    <div class="card-body px-0 py-16 d-flex align-items-center ">
                        <div class=" me-8 ">
                            <img *ngIf="employeeData.profile_image !=  null && employeeData.profile_image !=  ''" class="avatar-circle sq-40  " src="{{employeeData.profile_image}}">
                            <div *ngIf="employeeData.profile_image ==  '' || employeeData.profile_image ==  null" class="avatar-circle sq-40 {{employeeData.color_code}} avatar-border">{{employeeData.nameShort}}</div>
                        </div>
                        <h6 class="fw-600 mb-0">{{employeeData.name+" ("+employeeData.employee_code+")"}}</h6>
                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-8">
                            <div class="col-md-12 form-row">
                                <label for="" class="form-label ">Select tax regime</label>
                            </div>
                            <div class="col-md-12 form-row" [ngClass]="{ 'is-invalid': (submitted ||f.tax_regime.touched || f.tax_regime.dirty) &&  f.tax_regime.errors}">
                                <div class="form-check form-check-inline">
                                    <input formControlName="tax_regime" id="regime1" type="radio" [value]="false" class="form-check-input">
                                    <label for="regime1" class="form-check-label">Old</label>
                                </div>
                                <div class="form-check form-check-inline"><input id="regime2" formControlName="tax_regime" type="radio" [value]="true"
                                        class="form-check-input"><label for="regime2" class="form-check-label">New</label>
                                </div>
                            </div>
                            <div *ngIf="(submitted ||f.tax_regime.touched || f.tax_regime.dirty) && f.tax_regime.errors" class="invalid-feedback">
                                <div *ngIf="f.tax_regime.errors.required">{{this.messageService.validationDisplay('required')}}

                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="provident_fund" id="part-gross" class="form-check-input " (ngModelChange)="setValidator('provident_fund')">
                                    <label for="part-gross" class="form-check-label fw-500">Provident fund</label>
                                </div>
                            </div>
                            <div class="col-lg-6 form-row"  [ngClass]="{ 'd-none':formGp.value.provident_fund != true}">
                                <label for="" class="form-label" >PF number</label>
                                <input [ngClass]="{ 'is-invalid': (submitted ||f.pf_number.touched || f.pf_number.dirty) &&  (f.pf_number.errors || uniqueValidators.pf_number==true)}" type="text" autocomplete="off" formControlName="pf_number" id="name" placeholder="{{this.messageService.placeholderdisp('PF number')}}"
                                    class="form-control" (ngModelChange)="docNumValidators('pf_number','PF')">
                                <div *ngIf="(submitted ||f.pf_number.touched || f.pf_number.dirty) && (f.pf_number.errors || uniqueValidators.pf_number==true)" class="invalid-feedback">
                                    <div *ngIf="f.pf_number.errors?.required">{{this.messageService.validationDisplay('required')}}

                                </div>
                                    <div *ngIf="f.pf_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="(!(f.pf_number.errors) && uniqueValidators.pf_number==true)">{{this.messageService.usedDataValidation('PF')}}</div>
                            </div>
                            </div>
                            <div class="col-lg-6 form-row"  [ngClass]="{ 'd-none':formGp.value.provident_fund != true}">
                                <label for="" class="form-label">UAN number</label>
                                <input [ngClass]="{ 'is-invalid': (submitted || f.uan_number.dirty) &&  (f.uan_number.errors || uniqueValidators.uan_number==true)}" type="text" autocomplete="off" formControlName="uan_number" id="name" placeholder="{{this.messageService.placeholderdisp('UAN number')}}"
                                    class="form-control" (ngModelChange)="docNumValidators('uan_number','UAN')">
                                <div *ngIf="(submitted || f.uan_number.dirty) && (f.uan_number.errors || uniqueValidators.uan_number==true)" class="invalid-feedback">

                                    <div *ngIf="f.uan_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="(!(f.uan_number.errors) && uniqueValidators.uan_number==true)">{{this.messageService.usedDataValidation('UAN')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 py-16" *ngIf="formGp.value.provident_fund == true">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="pension" id="part-pension" class="form-check-input " (ngModelChange)="setValidator('pension')">
                                    <label for="part-pension" class="form-check-label fw-500">Pension</label>
                                </div>
                            </div>
                            <div class="col-lg-12  form-row"  [ngClass]="{ 'd-none':formGp.value.pension != true}">
                                <label for="" class="form-label">Pension number </label>
                                <input [ngClass]="{ 'is-invalid': (submitted ||f.pension_number.touched || f.pension_number.dirty) &&  (f.pension_number.errors || uniqueValidators.pension_number==true)}" type="text" autocomplete="off" formControlName="pension_number" id="name" placeholder="{{this.messageService.placeholderdisp('Pension number')}}"
                                    class="form-control" (ngModelChange)="docNumValidators('pension_number','Pension')">
                                <div *ngIf="(submitted ||f.pension_number.touched || f.pension_number.dirty) &&  (f.pension_number.errors || uniqueValidators.pension_number==true)" class="invalid-feedback">
                                    <div *ngIf="f.pension_number.errors?.required">{{this.messageService.validationDisplay('required')}}
                                </div>
                                    <div *ngIf="f.pension_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="(!(f.pension_number.errors) && uniqueValidators.pension_number==true)">{{this.messageService.usedDataValidation('pension')}}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="employee_state_insurance" id="part-esi" class="form-check-input " (ngModelChange)="setValidator('employee_state_insurance')">
                                    <label for="part-esi" class="form-check-label fw-500">Employee state insurance</label>
                                </div>
                            </div>
                            <div class="col-lg-6 form-row"  [ngClass]="{ 'd-none':formGp.value.employee_state_insurance != true}">
                                <label for="" class="form-label">ESI number </label>
                                <input [ngClass]="{ 'is-invalid': (submitted ||f.esi_number.touched || f.esi_number.dirty) && (f.esi_number.errors || uniqueValidators.esi_number==true)}" type="text" autocomplete="off" formControlName="esi_number" id="name" placeholder="{{this.messageService.placeholderdisp('ESI number')}}"
                                    class="form-control" (ngModelChange)="docNumValidators('esi_number','ESI')">
                                <div *ngIf="(submitted ||f.esi_number.touched || f.esi_number.dirty) && (f.esi_number.errors || uniqueValidators.esi_number==true)" class="invalid-feedback">
                                    <div *ngIf="f.esi_number.errors?.required">{{this.messageService.validationDisplay('required')}}
                                </div>
                                    <div *ngIf="f.esi_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="(!(f.esi_number.errors) && uniqueValidators.esi_number==true)">{{this.messageService.usedDataValidation('ESI')}}</div>
                            </div>
                            </div>
                            <div class="col-lg-6 form-row"  [ngClass]="{ 'd-none':formGp.value.employee_state_insurance != true}">
                                <label for="" class="form-label">Dispensary name</label>
                                <input [ngClass]="{ 'is-invalid': (submitted ||f.dispensry_name.touched || f.dispensry_name.dirty) &&  f.dispensry_name.errors}" type="text" autocomplete="off" formControlName="dispensry_name" id="name" placeholder="{{this.messageService.placeholderdisp('Dispensary name')}}"
                                    class="form-control">
                                <div *ngIf="(submitted ||f.dispensry_name.touched || f.dispensry_name.dirty) && f.dispensry_name.errors" class="invalid-feedback">
                                  <div *ngIf="!(f.dispensry_name.errors.pattern) && f.dispensry_name.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                  <div *ngIf="f.dispensry_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="labour_welfare_fund" id="part-lwf" class="form-check-input " (ngModelChange)="setValidator('labour_welfare_fund')">
                                    <label for="part-lwf" class="form-check-label fw-500">Labour welfare fund</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="professional_tax" id="part-tax" class="form-check-input ">
                                    <label for="part-tax" class="form-check-label fw-500">Professional tax</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="gratuity" id="part-gratuity" class="form-check-input " (ngModelChange)="setValidator('gratuity')">
                                    <label for="part-gratuity" class="form-check-label fw-500">Gratuity</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 py-16" *ngIf="formGp.value.provident_fund == true">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="volentary_provident_fund" id="part-vpf" class="form-check-input " (ngModelChange)="setValidator('volentary_provident_fund')">
                                    <label for="part-vpf" class="form-check-label fw-500">Voluntary provident fund</label>
                                </div>
                            </div>

                            <div class="col-lg-6 form-row" [ngClass]="{ 'd-none':formGp.value.volentary_provident_fund != true}">
                                <label for="" class="form-label required">Amount </label>
                                <div class="form-icon icon-right " [ngClass]="{ 'is-invalid': (submitted ||f.amount.touched || f.amount.dirty) &&  f.amount.errors}">

                                    <input [ngClass]="{ 'is-invalid': (submitted ||f.amount.touched || f.amount.dirty) &&  f.amount.errors}" type="text" autocomplete="off" formControlName="amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}"
                                        class="form-control">
                                        <span>{{empSalaryData.currency}}</span>
                                </div>
                                <div *ngIf="(submitted ||f.amount.touched || f.amount.dirty) && f.amount.errors" class="invalid-feedback">
                                    <div *ngIf="f.amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                                  <div *ngIf="f.amount.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                  <div *ngIf="(f.amount.errors.maxlength && !(f.amount.errors.pattern))">{{this.messageService.fieldlengthvalidation('num','10')}}</div>

                                </div>

                            </div>
                            <div class="col-lg-6 form-row" [ngClass]="{ 'd-none':formGp.value.volentary_provident_fund != true}">
                              <label for="" class="form-label required">Effective from </label>
                              <!-- <i class="bi bi-info-circle text-accent4 ms-2 fs-12 tooltip-icon" ngbPopover="Select the month from when this new salary update should be effective from" popoverClass="popover-default popover-info" container="body" triggers="hover"></i> -->


                              <div ngbDropdown  class="input-dropdown four-dropdown"  placement="top-end" >
                                <button type="button" class="card card-c2" ngbDropdownToggle>
                                    <span class="btn-placeholder" *ngIf="formGp.value.vpf_month==null && formGp.value.vpf_year==null" >Select month and year</span>
                                    {{formGp.value.vpf_month}} {{formGp.value.vpf_year}}
                                </button>

                                <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 22rem;">
                                  <div class="row">
                                    <div class="col-5">
                                      <label for="" class="form-label">Year </label>
                                      <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('Year')}}" formControlName="vpf_year" >
                                        <ng-option [value]="prevYear">{{prevYear}}</ng-option>
                                        <ng-option [value]="year">{{year}}</ng-option>
                                        <ng-option [value]="nextYear">{{nextYear}}</ng-option>
                                      </ng-select>
                                    </div>
                                    <div class="col-7">
                                      <label for="" class="form-label">Month </label>
                                      <app-master-select formControlName="vpf_month" [selectedItem]="f.vpf_month.value" [placeholder]="'Select month'" id="vpf_month"  [submitted]="submitted" [isrequired]="true"  [type]="'listOfMonth'" ></app-master-select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-16">
                            <div class="col-12 form-row">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="employer_contribution_nps" id="part-nps" class="form-check-input " (ngModelChange)="setValidator('employer_contribution_nps')">
                                    <label for="part-nps" class="form-check-label fw-500">Employer contribution to NPS</label>
                                    <i class="bi bi-info-circle text-accent4 ms-2 fs-12 tooltip-icon" ngbPopover="10% is the maximum allowed limit for non government employees and 14% for government employees" popoverClass="popover-default popover-info" container="body" triggers="hover"></i>

                                </div>
                            </div>
                            <div class="col-lg-12 form-row" [ngClass]="{ 'd-none':formGp.value.employer_contribution_nps != true}">
                                <label for="" class="form-label required">Percentage </label>
                                <div class="form-icon icon-right "  [ngClass]="{ 'is-invalid': (submitted ||f.percentage.touched || f.percentage.dirty) &&  f.percentage.errors}">

                                    <input [ngClass]="{ 'is-invalid': (submitted ||f.percentage.touched || f.percentage.dirty) &&  f.percentage.errors}" type="text" autocomplete="off" formControlName="percentage" id="name" placeholder="{{this.messageService.placeholderdisp('Percentage')}} " class="form-control">
                                    <span>%</span>
                                </div>
                                <div *ngIf="(submitted ||f.percentage.touched || f.percentage.dirty) && f.percentage.errors" class="invalid-feedback">
                                    <div *ngIf="f.percentage.errors.required">{{this.messageService.validationDisplay('required')}}
                                </div>
                                    <div *ngIf="f.percentage.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="(f.percentage.errors.max && !(f.percentage.errors.pattern))">{{this.messageService.fieldlengthvalidation('percentage','100')}}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    </div>

    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="validateStatutory()" [disabled]="(!formGp.valid || uniqueValidators.error>0)">Next</button>
    </footer>
