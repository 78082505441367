<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">HOLIDAY MAPPING</p>
        </div>
        <!-- <div class="col-12 pb-10">
            <ng-container *ngIf="formGp.value.is_requested">
                <div  role="alert" class="alert alert-warning show m-0"><p  class="mb-0"><span  class="text-warning">Note:</span> Holiday policy you are trying to edit is mapped with various employees. You cannot edit this holiday policy.</p></div>
            </ng-container>
        </div> -->
        <div class="row row-16">
           <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Map holiday </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose the holidays to be mapped to the holiday policy.
                    " popoverClass="popover-input popover-info" placement="bottom"  triggers="hover"></i></span>
                <!-- <ng-select class="form-ngselect" placeholder="Select Holiday "></ng-select> -->
                    <ng-select
                    [ngClass]="{ 'is-invalid': (submitted || f.holiday_policy.touched || f.holiday_policy.dirty) &&  f.holiday_policy.errors}"
                   
                    placeholder="{{this.messageService.selectplaceholddisplay('Holiday(s)')}}"
                    formControlName="holiday_policy"
                    [items]="holidayDropdown"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="holiday_name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class=" multiselect"
                    >
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-flex align-items-center gap-8">

                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                      </div>
                    </ng-template>                    
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <div class="d-flex align-items-center gap-8">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        {{ item.holiday_name }}
                    </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="formGp.value.holiday_policy.length ">
                            <span class="ng-value-label">{{formGp.value.holiday_policy.length }} Holiday(s) selected</span>
                        </div>
                    </ng-template>

                </ng-select>
           
            <div *ngIf="(submitted || f.holiday_policy.touched || f.holiday_policy.dirty) && f.holiday_policy.errors" class="invalid-feedback">
                <div *ngIf="f.holiday_policy.errors.required">{{this.messageService.validationDisplay('required')}}
            </div></div>
        </div>

            <div class="col-12">
                <ul class="selected-values" *ngIf="formGp.value.holiday_policy">
                    <li class="ng-value" *ngFor="let item of formGp.value.holiday_policy,let ki =index">
                        <ng-container *ngFor="let insideitem of holidayDropdown, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['holiday_name'].length>15)? (insideitem['holiday_name'] | slice:0:15)+'..':(insideitem['holiday_name'])  }}</span>

                        </ng-container>
                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(ki)" aria-hidden="true">×</span>

                    </li>
                </ul>
          
            </div>
    </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="(!formGp['controls'].holiday_policy.valid ) " (click)="validateHolidayPolicy()" >NEXT</button>
    </footer>
</form>
