import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-loan-request',
  templateUrl: './view-loan-request.component.html',
  styleUrls: ['./view-loan-request.component.scss']
})
export class ViewLoanRequestComponent implements OnInit {

  // Input
  @Input() loader               : any
  @Input() loanData             : any;
  @Input() requestId            : any;
  @Input() loanView             : any
  @Input() requestName          : any

  // Ouput
  @Output() closeLoanPanel      = new EventEmitter()
  @Output() closeViewPage        = new EventEmitter();

  viewstatusPanel      : boolean = false
  statusLoader         : boolean = false
  requestCancel        : boolean = false
  viewStatusData       : any
  viewStatusLevelCnt   : any
  viewStatusmaxLevel   : any
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension     : any
  fileName                        = ''
  alertToggle                     = false;
  investalertMsg       : any      = '';
  currency             : any

  constructor(
    private invServ   : InvestmentDeclerationService,
    public appServ    : AppService
  ) { }

  ngOnInit(): void {
    let userData = this.appServ.getEmpData()
    this.currency = userData?.currency
  }

  // DOC view
  viewDocument(filename:any){
    this.modalToggle      = true;
    this.fileName         = filename;
    this.currentDocName   = this.appServ.getDocName(filename);
    this.currentExtension = this.fileName.split('.').pop();
    if(this.currentExtension == 'doc' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'csv'){
      this.modalToggle    = false;
      this.alertToggle    = true;
      this.investalertMsg = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }else if(this.currentExtension == 'pdf'){
      this.modalToggle    = true;
      this.alertToggle    = false;
    }
  }

  // Download File
  downLoad(val:any){
    let url = this.appServ.getDocName(val).split('.')[0]
    this.appServ.downloadFile(val,url)
  }

  // Modal close for Invalid Extension
  closed(bool:any){
    this.alertToggle = bool
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

  // View Status
  statusData(id:any){
    this.viewstatusPanel = true
    this.statusLoader = true
    this.invServ.viewStatusLoanData(this.requestId,id).subscribe((res:any)=>{
      this.viewStatusData = res
      this.statusLoader = false
      this.viewStatusLevelCnt = res?.activity?.level_count
      this.viewStatusmaxLevel = res?.activity?.maxlevel
    })
  }

  // Cancellation
  cancelRequest(){
    this.requestCancel = true
  }

  // Main model popup
  closeFunction(){
    this.closeLoanPanel.emit(false);
  }

  // Cancel Popup
  closeCancelModal(data:any){
    this.requestCancel = data?.show;
    if(data?.action == 'submit')
      this.closeViewPage.emit(data?.show);
  }

  getDocName(name:string){
    if(name != undefined){
      var docname : any  = name.split('_').pop();
      docname = docname.replace(/[&\/\\#,+()$~%'":*?<>{}0-9]/g, '', "");
      return docname;
    }
  };
}
