<!-- <div class="container-fluid px-32 py-24"> -->
  <div class="row row-16">
      <div class="col-12">
          <div class="col-12 d-flex position-relative">
              <span class=" text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}

        <i *ngIf="requestitem?.compoff?.is_system_generated" class="icon-message-system text-warning fs-20 ms-8"></i>
              </span>
      <button *ngIf="from!='behalf'" class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
                  (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
                  <i class="bi toggle-star"></i>
              </button>
          </div>
          <div class="col-12 mt-8 card-ribbon">
              <div class="w-100 d-flex align-items-center gap-8">
                  <h5 class="mb-8">Compensatory Off Approval</h5>
                  <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
                      <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
                          *ngIf="requestitem?.approved_status == 'Pending'">Pending</span>
                      <span class="badge px-16 ms-auto fs-14 badge-success py-1"
                          *ngIf="requestitem?.approved_status == 'Approved'">Accepted</span>
                      <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
                          *ngIf="requestitem?.approved_status == 'Rejected'">Rejected</span>
                      <span class="text-light-400">|</span>
                      <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
                  </ng-container>
                  <!-- Invalid request -->
                  <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
                      class="ribbon ribbon-right ribbon-danger  ribbon-sm"
                      style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
              </div>
              <span class=" badge-custom {{requestitem?.request_type_color_code}} ">Comp Off Approval</span>
              <span
                  *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
                  class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
          </div>
      </div>
      <ng-container *ngIf="from!='behalf'">

        <app-progressbar [requestitem]="requestitem"></app-progressbar>
        <div class="col-12">
            <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
            </p>
        </div>
      </ng-container>
      <div class="col-12">
          <div class="row row-8">
              <div class="col-12">
                  <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                      <div class="row row-24">
                          <div class="col-12">
                              <div class="d-flex">
                                  <span class="title">1 comp off generated</span>
                                  <span class="text-accent2 pointer ms-auto fs-12 fw-500"
                                      (click)="getCompOffTrendDetails(currentYear)"><i
                                          class="bi bi-graph-up me-1"></i>Comp off
                                      Trend</span>
                              </div>
                          </div>
                          <div class="col-12">
                              <div class="card border-0 card-c5 month-active text-light-500">
                                  <div class="card-body radius-4 text-center {{requestitem?.request_type_color_code}} flex-center flex-column text-light-600"
                                      style="width: 7rem;">
                                      <div class=" fs-12 text-uppercase lh-1">{{requestitem?.compoff?.day_of_week}}
                                      </div>
                                      <div class="fw-500">{{requestitem?.compoff?.attendance_date | date : 'd'}}
                                          {{requestitem?.compoff?.attendance_date | date : 'MMM'}}</div>
                                  </div>
                                  <div class="card-body justify-content-center p-0 d-flex flex-column">
                                      <div class="card card-c2 border-0 px-24">
                                          <div class="card-body px-0 py-12">
                                              <div class="row row-16">
                                                  <div class="col-12">
                                                      <span class="fs-14 fw-500">Actual Shift Time</span>
                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-in </div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.compoff?.actual_shift_details?.check_in)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-out</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.compoff?.actual_shift_details?.check_out)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Total hours</div>
                                                      <div class="detail-desc">
                                                          {{(requestitem?.compoff?.actual_shift_details?.total_hour ==
                                                          '' || requestitem?.compoff?.actual_shift_details?.total_hour
                                                          == null) ? '-' :
                                                          this.appservice.hhmmTimeFormat(requestitem?.compoff?.actual_shift_details?.total_hour)}}
                                                          Hours</div>

                                                  </div>
                                              </div>
                                          </div>
                                          <div class="card-body px-0 py-12">
                                              <div class="row row-16">
                                                  <div class="col-12">
                                                      <span class="fs-14 fw-500">Employee Check-in and Check-out
                                                          Time</span>
                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-in </div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.compoff?.checkin_time)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-out</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.compoff?.checkout_time)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Total working hours</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.hhmmTimeFormat(requestitem?.compoff?.total_work_hours)}}
                                                          Hours</div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Extra working hours</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.hhmmTimeFormat(requestitem?.compoff?.extra_hours)}}
                                                          Hours</div>
                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Compensatory off generated</div>
                                                      <div class="detail-desc">{{requestitem?.compoff?.eligible_compoff == 'Full Day' ? '1
                                                          Day' : '0.5 Day'}} </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-12">
          <form class="row row-16 ">

              <ng-container [formGroup]="form" *ngIf="!requestitem?.achieved_status">
                  <div class="col-12">
                      <textarea name="" id="" rows="3"
                          placeholder="{{this.messageService.descriptiontext('comment')}}" formControlName="comment"
                          [ngClass]="{ 'is-invalid': f.comment.errors}"
                          class="form-control textarea-comment"></textarea>
                      <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
                          {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                  </div>
              </ng-container>
              <div *ngIf="!requestitem?.achieved_status" class="col-12 d-flex gap-16">
                  <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                      (click)="confirm('Rejected')">Decline</button>
                  <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                      (click)="confirm('Approved')">Confirm</button>
              </div>

          </form>
      </div>
  </div>


<!-- Trend Graph Sidebar -->
<div *ngIf="timeOffTrend" class="side-panel" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='timeOffTrend === true'>
  <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Comp off trend</h5>
          <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
      </header>

      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-24">

                  <div class="col-12 ">
                      <div class="card card-c2 pb-24  px-24 pt-24">
                          <div class="row row-12">

                              <div class="col-12 d-flex">
                                  <div class="fs-24 me-auto">Comp off trend - {{selectedYear}} </div>

                                  <ng-select class="form-ngselect ms-auto fs-14" [clearable]="false"
                                      placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                                      (change)="getCompOffTrendDetails($event)" [(ngModel)]="selectedYearDisp"
                                      [ngModelOptions]="{standalone: true}">
                                      <ng-option class="fs-12" *ngFor="let year of yearList"
                                          [value]="year.k">{{year.k}}</ng-option>
                                  </ng-select>
                              </div>
                              <div *ngIf="barchartLoader" class="col-12">

                                  <app-graph-loader></app-graph-loader>
                              </div>
                              <div *ngIf="!barchartLoader" class="col-12">

                                  <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
                                      [yText]="barChartYAxisLabel"></app-bar-chart>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </form>
</div>
<!-- End -->

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
      <form class="side-panel-container" autocomplete="off">
          <header class="side-panel-head">
              <h5>Activities</h5>
              <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
          </header>
          <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
              [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [sendReminder]="sendReminder" [requestId]="requestitem.request_data['id']" (closePanel)="viewstatusPanel=false"></app-view-status>
      </form>
  </div>
</ng-container>
<!-- End -->
