
<div class="section-sidebar" style="width:17rem">
  <div class="text-light-500 fs-12 fw-500 px-24 pt-24 pb-16 text-uppercase"></div>
  <ul class="navbar-nav nav-vertical nav ms-auto">
    <li class="nav-item" *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
      <a class="nav-link">
        <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader>
      </a>
      <a class="nav-link">
        <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader>
      </a>

    </li>
  </ul>
</div>
