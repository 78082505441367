import { Component, Input, OnInit } from '@angular/core';
import { LeaveYearEndService } from 'src/app/@core/services/leave-year-end.service';
import { AppService } from 'src/app/app.global';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { ReportDownload } from 'src/app/@module/hr/leave-year-end/report-download';
@Component({
  selector: 'app-notification-leave-year-end',
  templateUrl: './notification-leave-year-end.component.html',
  styleUrls: ['./notification-leave-year-end.component.scss']
})
export class NotificationLeaveYearEndComponent implements OnInit {

  leaveYearEndModal  : boolean = false;
  leaveYearEndModal1 : boolean = false;
  leaveYearEndModal2 : boolean = false;
  leaveYearEndModal3 : boolean = false;
  leaveYearEndModal4 : boolean = false;
  leave_policy_id    : any

  fileType          = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension     = '.xlsx';
  leave_year_proccess_on : any

  @Input() bannerData:any

  constructor(
    public appService : AppService,
    private reportDownload :ReportDownload
  ) { }

  ngOnInit(): void {
    if(Object.keys(this.bannerData).length != 0){
      this.leaveYearEndModal = true
    }
  }

  // Export Report
  exportData(){
    this.reportDownload.downLoadReport(this.bannerData?.pendingleaveyearend_mapping_list,'')
  }
}
