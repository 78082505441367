import { Component, OnInit,Input } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-prior-summary',
  templateUrl: './prior-summary.component.html',
  styleUrls: ['./prior-summary.component.scss']
})
export class PriorSummaryComponent implements OnInit {
  @Input() salarysummaryList:any;
  @Input() empsalarysummaryList:any;
  // @Input() month:any;
  // @Input() year:any;
  @Input() searchString:any;
  @Input() page:any;
  @Input() pageSize:any;
  @Input() issalsumm    = false
  @Input() isempsalsumm = false
  @Input() currency:any;
  salaryHeader:any = [];
  empHeader:any    = [];
  constructor(public appservice:AppService) { }

  ngOnInit(): void {
    if(this.issalsumm==true){

      Object.entries(this.salarysummaryList[0]).forEach(([key, value]) => {
        this.salaryHeader.push(key);

      })
    }
    if(this.isempsalsumm==true){

      Object.entries(this.empsalarysummaryList[0]).forEach(([key, value]) => {
        if(key!='id')
        this.empHeader.push(key);

      })
    }
  }
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

}
