import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getPersonalData(id:number,from:any) {
    if(from == 'HR'){
      return this.http.get(`${this.API_PATH}organization/api/v1/hr-employee-detail-view/${id}/`, this.httpOption)
    }else if(from == 'Reporting Manager'){
      return this.http.get(`${this.API_PATH}organization/api/v1/team-employee-detail-view/${id}/`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}organization/api/v1/profile-employee-detail-view/${id}/`, this.httpOption)
    }
  }

  updateBankData(id: number, body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/bank-detail/${id}/`, body, this.httpOption)
  }
  createBankData(body:any) {
    return this.http.post(`${this.API_PATH}organization/api/v1/bank-list/`, body, this.httpOption)
  }

}
