import { Component, OnInit } from '@angular/core';
import { ChallanService } from 'src/app/@core/services/challan.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-challan-details',
  templateUrl: './challan-details.component.html',
  styleUrls: ['./challan-details.component.scss']
})

export class ChallanDetailsComponent implements OnInit {

  companyChallanId            = 0;
  financialyear:any           = '';
  challanPeriod:any           = '';
  company                     = 0;
  currency:any                = '';
  challanDetails:any          = [];
  permissions:any             = [];
  loader                      = false;
  

  // Excess amount related variables
  excessAmountList : any      = [];
  challanDueFlag              = false;
  challanDueList : any        = [];
  excess_id : any;
  excessAmount : any;


  constructor(
    public challanService:ChallanService,
    public router: Router,
    public route:ActivatedRoute,
    public appservice:AppService,
  ) { }

  ngOnInit(): void {

    this.getPermission();

    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
          this.companyChallanId = params['id'];
          this.getChallanDetails();
          this.getExcessAmountDetails()
      }

      if( !isNaN(params['financialyear'])){
        this.financialyear = params['financialyear'];
      }

      if( !isNaN(params['company'])){
        this.company = params['company'];
      }
      if (isNaN(params['currency'])) {
        this.currency = params['currency'];
      }
     

    })
  }

  /********* GET CHALLAN DETAILS FUNCTION ****************/
  getChallanDetails(){
    this.loader            = true;
    this.challanDetails    = [];
    this.challanService.challanMonthList(this.companyChallanId)
    .subscribe((res: any) => {
      this.challanDetails  = res?.data;
      this.challanPeriod   = res?.challan_period;
      this.currency        = res?.company_currency;
      this.loader          = false;
    })
  }

  /********* GET PAGE PERMISSION FUNCTION ****************/
  getPermission(){
    this.permissions = this.appservice.getPermissions('/challan/add-challan');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

   /********* GET CURRENT FINANCIAL YEAR RANGE FUNCTION ****************/
   getCurrentFinancialYear() {
    var today       = new Date();
    var fiscalyear;
    if(this.financialyear == today.getFullYear()){
      if ((today.getMonth() + 1) <= 3) {
        fiscalyear = (Number(this.financialyear) - 1) + "-" + Number(this.financialyear);
      } else {
        fiscalyear = Number(this.financialyear) + "-" + (Number(this.financialyear) + 1);
      }
    }else{
      fiscalyear = Number(this.financialyear) + "-" + (Number(this.financialyear) + 1);
    }

    return fiscalyear;
  }

  // Function for get excess amount adjustments details
  getExcessAmountDetails(){
    this.loader            = true;
    this.excessAmountList    = [];
    this.challanService.getExcessAmountList(this.companyChallanId)
    .subscribe((res: any) => {
      this.excessAmountList  = res?.data;
      this.loader          = false;
    })

  }

  // Function for get challn due list
  challanDueLoader = false;
  getChallanDueList(excessId:any,amount:any){
    this.challanDueLoader = true;
    this.challanDueList    = [];
    this.excess_id = excessId;
    this.excessAmount = amount;
    this.challanService.getChallanDueList(this.companyChallanId)
    .subscribe((res: any) => {
      this.challanDueList  = res?.data;
      this.challanDueLoader = false;
    })
  }

  // Function for adjust excess amount
  submitExcessAmount(challan_runpayroll_id:any,payroll_month:any,tax_amount:any){
   
   this.router.navigate(['/challan/challan-setup/adjustAmount/'+Number(this.excessAmount)+'/'+Number(challan_runpayroll_id)+'/'+this.currency+'/'+Number(this.excess_id)+'/'+this.financialyear+'/'+this.company+'/'+payroll_month+'/'+Number(tax_amount)]);
  }

}
