<form class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">

        <div class="row row-16 ">
            <div class="col-12">
                <p class="text-uppercase fw-500">Policy settings</p>
            </div>
            <div class="col-12">
                <label for="names" class="form-label required"> Policy name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enter a name for the policy." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
                <input type="text" name="name" class="form-control" formControlName="name" placeholder="{{this.msgServ.placeholderdisp('policy name')}}" id="names" (keyup)="keyUp()" [ngClass]="{ 'is-invalid' : (saveBtnClicked || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist}">
                <div *ngIf="(saveBtnClicked || f?.name?.touched || f?.name?.dirty) && f?.name?.errors || f?.name?.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f?.name?.hasError('exist')">{{this.msgServ.validationDisplay('confirmation policy name')}}</div>
                  <div *ngIf="f?.name?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="!f?.name?.errors?.pattern && f?.name?.errors?.maxlength">{{this.msgServ.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf=" f?.name?.errors?.pattern">{{this.msgServ.validationDisplay('pattern')}}</div>
                </div>
            </div>
            <div class="col-12">
                <label for="descript" class="form-label required">Policy description </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Describe the policy in few lines." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
                <textarea formControlName="description"
                [ngClass]="{ 'is-invalid' : (saveBtnClicked || f.description.touched || f.description.dirty) && f.description.errors}"
                type="text" class="form-control "
                placeholder="{{this.msgServ.descriptiontext('desc')}}"></textarea>
                <div *ngIf="(saveBtnClicked || f?.description?.touched || f?.description?.dirty) && f?.description?.errors" class="invalid-feedback">
                  <div *ngIf="f?.description?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="!f?.description?.errors?.pattern && f?.description?.errors?.maxlength">{{this.msgServ.fieldlengthvalidation('word','500')}}</div>
                </div>
            </div>
            <div class="col-12">
                <label for="based_on" class="form-label required">Probation period (in days)</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enter how many days employees will be on probation" popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
                <input type="text" name="probation_period" pattern="^(0|[1-9]\d*)$" class="form-control" formControlName="probation_period" placeholder="{{this.msgServ.placeholderdisp('no of days')}}" id="no_of_month" [ngClass]="{ 'is-invalid' : (saveBtnClicked || f.probation_period.touched || f.probation_period.dirty) && f.probation_period.errors}">
                <div *ngIf="(saveBtnClicked || f?.probation_period?.touched || f?.probation_period?.dirty) && f?.probation_period?.errors" class="invalid-feedback">
                  <div *ngIf="f?.probation_period?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="f?.probation_period?.errors?.pattern || notanumber(f.probation_period.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                  <div *ngIf="!f?.probation_period?.errors?.pattern && f.probation_period.hasError('min')">{{this.msgServ.fieldlengthvalidation('minValueNew',0)}}</div>
                  <div *ngIf="!f?.probation_period?.errors?.pattern && f.probation_period.hasError('max')">{{this.msgServ.fieldlengthvalidation('minDays',1000)}}</div>
                </div>
            </div>
            <div class="col-12">
                <label for="option_terminate" class="form-label required">Do you want to allow the approver to terminate the employee during the confirmation? </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                      ngbPopover="Enable this option to allow approver to terminate the employee during their confirmation." popoverClass="popover-input popover-info"
                      placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8 form-row">
                <div class="form-check form-check-inline">
                    <input name="is_terminate" type="radio" id="radio1" class="form-check-input" formControlName="is_terminate" [value]="true">
                    <label for="radio1" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="is_terminate" type="radio" id="radio2" class="form-check-input" formControlName="is_terminate" [value]="false">
                    <label for="radio2" class="form-check-label">No </label>
                </div>
            </div>
            <div class="col-12">
                <label for="option_extend" class="form-label required">Do you want to allow the approver to extend the probation period of the employee?</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                      ngbPopover="Enable this option to allow approver to extend the employee's probation period." popoverClass="popover-input popover-info"
                      placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8 form-row">
                <div class="form-check form-check-inline">
                    <input name="is_extend" type="radio" id="radio3" class="form-check-input" [value]="true" formControlName="is_extend" (change)="extensionControlValidate(f.is_extend.value);resetExtension()">
                    <label for="radio3" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="is_extend" type="radio" id="radio4" class="form-check-input" [value]="false" formControlName="is_extend" (change)="extensionControlValidate(f.is_extend.value);resetExtension()">
                    <label for="radio4" class="form-check-label">No </label>
                </div>
            </div>
            <ng-container *ngIf="f.is_extend.value == true">
              <div class="col-md-6">
                  <label for="extend_period" class="form-label required">Extension period (in days) </label>
                  <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Specify the number of days the probation period has to be extended." popoverClass="popover-input popover-info"
                    placement="bottom" triggers="hover"></i></span>
                  <input type="text" name="extension_period" class="form-control" pattern="^(0|[1-9]\d*)$" placeholder="{{this.msgServ.placeholderdisp('no of days')}}" id="no_extend" formControlName="extension_period" [ngClass]="{ 'is-invalid' : (saveBtnClicked || f.extension_period.touched || f.extension_period.dirty) && f.extension_period.errors}">
                  <div *ngIf="(saveBtnClicked || f?.extension_period?.touched || f?.extension_period?.dirty) && f?.extension_period?.errors" class="invalid-feedback">
                    <div *ngIf="f?.extension_period?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                    <div *ngIf="f?.extension_period?.errors?.pattern || notanumber(f.extension_period.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                    <div *ngIf="!f?.extension_period?.errors?.pattern && f.extension_period.hasError('min')">{{this.msgServ.fieldlengthvalidation('minValueNew',0)}}</div>
                    <div *ngIf="!f?.extension_period?.errors?.pattern && f.extension_period.hasError('max')">{{this.msgServ.fieldlengthvalidation('minDays',1000)}}</div>
                  </div>
              </div>
              <div class="col-md-6">
                <label for="no_extend_period" class="form-label required">No.of times extension can be done </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enter the maximum number of times the probation period for the employee can be extended." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
                <input type="text" name="max_extension" class="form-control" pattern="^(0|[1-9]\d*)$" placeholder="{{this.msgServ.placeholderdisp('no of times')}}" id="no_extend" formControlName="max_extension" [ngClass]="{ 'is-invalid' : (saveBtnClicked || f.max_extension.touched || f.max_extension.dirty) && f.max_extension.errors}">
                <div *ngIf="(saveBtnClicked || f?.max_extension?.touched || f?.max_extension?.dirty) && f?.max_extension?.errors" class="invalid-feedback">
                  <div *ngIf="f?.max_extension?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="f?.max_extension?.errors?.pattern || notanumber(f.max_extension.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                  <div *ngIf="!f?.max_extension?.errors?.pattern && f.max_extension.hasError('min')">{{this.msgServ.fieldlengthvalidation('minValueNew',0)}}</div>
                  <div *ngIf="!f?.max_extension?.errors?.pattern && f.max_extension.hasError('max')">{{this.msgServ.fieldlengthvalidation('minTimes',5)}}</div>
                </div>
              </div>
              <div class="col-12">
                <label for="edit_extension" class="form-label required">Do you want to allow approver to edit extension period?
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enable this option to allow the approver to modify the duration of the employee's extension period." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
              </div>
              <div class="col-12 mt-8 form-row">
                  <div class="form-check form-check-inline">
                      <input name="is_edit_extend" type="radio" id="radio5" class="form-check-input" formControlName="is_edit_extend" [value]="true">
                      <label for="radio5" class="form-check-label">Yes </label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input name="is_edit_extend" type="radio" id="radio6" class="form-check-input" formControlName="is_edit_extend" [value]="false">
                      <label for="radio6" class="form-check-label">No </label>
                  </div>
              </div>
            </ng-container>
            <div class="col-12">
                <label for="send_letter" class="form-label required">Would you like to initiate the letter generation task for the task owner? </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enable this option to initiate the letter generation task for the task owners." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8 form-row">
                <div class="form-check form-check-inline">
                    <input name="is_send_letter" type="radio" id="radio7" class="form-check-input" [value]="true" formControlName="is_send_letter" (click)="setRequired(true);resetForm()">
                    <label for="radio7" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="is_send_letter" type="radio" id="radio8" class="form-check-input" [value]="false" formControlName="is_send_letter" (click)="setRequired(false);resetForm()">
                    <label for="radio8" class="form-check-label">No </label>
                </div>
            </div>
            <ng-container *ngIf="f.is_send_letter?.value == true">
              <div class="col-4">
                <label for="letter_type" class="form-label required">Confirmation template</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Select this template to generate a confirmation letter for employees." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
                <ng-select class="form-ngselect" placeholder="{{this.msgServ.selectplaceholddisplay('confirmation template')}}" formControlName="confirmation_letter">
                  <ng-option *ngFor="let template of confirmationDropdown" [value]="template?.id">{{template?.name}}</ng-option>
                </ng-select>
              </div>
              <div class="col-4">
                <label for="letter_type" class="form-label required">Extension template</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Select this template to generate an extension letter for employees." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
                <ng-select class="form-ngselect" placeholder="{{this.msgServ.selectplaceholddisplay('extension template')}}" formControlName="extension_letter">
                  <ng-option *ngFor="let template of extensionDropdown" [value]="template?.id">{{template?.name}}</ng-option>
                </ng-select>
              </div>
              <div class="col-4">
                <label for="letter_type" class="form-label required">Termination template</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Select this template to generate a termination letter for employees." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
                <ng-select class="form-ngselect" placeholder="{{this.msgServ.selectplaceholddisplay('termination template')}}" formControlName="termination_letter">
                  <ng-option *ngFor="let template of terminationDropdown" [value]="template?.id">{{template?.name}}</ng-option>
                </ng-select>
              </div>
            </ng-container>
            <div class="col-12">
                <label for="day_confirm" class="form-label required">How many days before the confirmation date should the confirmation request be initiated?</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                      ngbPopover="Specify how many days prior to the confirmation date, the confirmation request should be automatically initiated." popoverClass="popover-input popover-info"
                      placement="bottom" triggers="hover"></i></span>
                <input type="text" name="confirmation_request_days" class="form-control" placeholder="{{this.msgServ.placeholderdisp('days')}}" formControlName="confirmation_request_days" id="day_confirm" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.confirmation_request_days.touched || f.confirmation_request_days.dirty) &&  f.confirmation_request_days.errors}"
                pattern="^(0|[1-9]\d*)$">
                <div *ngIf="(saveBtnClicked || f.confirmation_request_days.touched || f.confirmation_request_days.dirty) &&  f.confirmation_request_days.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmation_request_days.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="!f.confirmation_request_days.errors?.pattern && (f.confirmation_request_days.hasError('min'))">
                    {{this.msgServ.fieldlengthvalidation('minValueNew',7)}}
                  </div>
                  <div *ngIf="!f.confirmation_request_days.errors?.pattern && (f.confirmation_request_days.hasError('max'))">
                    {{this.msgServ.fieldlengthvalidation('minDays',365)}}
                  </div>
                  <div *ngIf="f.confirmation_request_days.errors?.pattern || notanumber(f.confirmation_request_days.errors?.pattern?.actualValue)">
                    {{this.msgServ.validationDisplay('pattern')}}</div>
                </div>
            </div>

        </div>
    </div>
    <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid|| saveBtnClicked" (click)="validateForm()">NEXT</button>
    </footer>
</form>
