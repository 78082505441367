<div class=" config-layout">
  <header class=" config-header">
      <h5 class="m-0">Details</h5>
      <button class="btn-reset text-light-600" routerLink="/pending-request/{{year}}/{{month}}/{{company}}">
          <i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
      <div class="container-fluid p-32 form-container">
          <div class="row mb-24">
              <div class="col-md-4">
                  <p class="text-uppercase fw-500 mb-0">{{tableHeader}}</p>
              </div>
              <div class="col-md-8">
                  <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
                    <div style="min-width:22.688rem;">
                      <div class="form-icon2">
                        <i class="icon-search icon-left fs-14"></i>
                        <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                        <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                      </div>
                  </div>
                  </div>
              </div>
          </div>
          <div class="row row-24">
              <div class="col-12">
                  <div class="card card-c2 overflow-hidden">
                    <app-table-loader *ngIf="loader"></app-table-loader>

                      <div class="table-responsive" *ngIf="!loader">
                          <table class="table table-striped table-sm form-table">
                              <thead>
                                  <tr>
                                      <th scope="col" class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)" [checked]="checkallSelected()" >
                                      </th>
                                      <th class="fw-600" scope="col">employee name</th>
                                      <th class="fw-600" scope="col">employee CODE</th>
                                      <th class="fw-600" scope="col">request type</th>
                                      <th class="fw-600" scope="col">PENDING WITH</th>
                                  </tr>
                              </thead>
                              <tbody class="cell-16">
                                <tr *ngFor="let item of $any(filterdata |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">

                                  <td class="form-cell">
                                    <input [ngClass]="'page'+page" class="form-check-input m-0" type="checkbox"
                                    [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" value="{{item.id}}" >
                                  </td>
                                      <td>{{item?.fullname}}</td>
                                      <td>{{item?.employee_code}}</td>
                                      <td>{{item?.request_type}}</td>
                                      <td>


                                          <div class="d-flex align-items-center overlap-avatar overlap-left" >
                                            <ng-container *ngFor="let aacpt of fyData[ind]?.pending_with,let i = index">
                                              <img  class="avatar-sm sq-32 avatar-circle"  *ngIf="i < 3 && aacpt.profile_image !=  '' && aacpt.profile_image !=  null"  src="{{aacpt.profile_image}}" [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body">
                                              <div class="avatar-sm sq-32 avatar-circle {{aacpt.color_code}}" *ngIf="i < 3 && (aacpt.profile_image ==  ''|| aacpt.profile_image ==  null)" [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body"><span>{{this.appService.getFirstChar(aacpt.first_name+' '+aacpt.last_name,2)}}</span></div>
                                              <ng-template #popContentSingle>
                                                <div class="user-details">
                                                    <span class="user-label">{{aacpt?.first_name}} {{aacpt?.middle_name}} {{aacpt.last_name}}</span>
                                                    <span class="user-code">EMP CODE: {{aacpt?.employee_code}} </span>
                                                </div>
                                              </ng-template>
                                              <div *ngIf="i==3" class="avatar-sm sq-32 avatar-circle" [ngbPopover]="popContentMultilpe"  popoverClass="user-list multi-user" triggers="click" container="body"><span>+{{this.appService.subEmp(fyData[ind]?.pending_with?.length)}}</span></div>
                                            </ng-container>
                                            <ng-template #popContentMultilpe>
                                              <ul>
                                                <ng-container *ngFor="let re of fyData[ind]?.pending_with,let i = index">
                                                  <ng-container *ngIf="i >= 3">
                                                    <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                                                        <img  class="user-avatar" src="{{re.profile_image}}">
                                                        <div class="user-details">
                                                          <span class="user-label">{{re?.first_name}} {{re?.middle_name}} {{re.last_name}}</span>
                                                          <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                                        </div>
                                                    </li>
                                                    <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                                                        <span class="user-avatar {{re.color_code}}">{{this.appService.getFirstChar(re.first_name+' '+re.last_name,2)}}</span>
                                                        <div class="user-details">
                                                            <span class="user-label">{{re?.first_name}} {{re?.middle_name}} {{re.last_name}}</span>
                                                            <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                                        </div>
                                                    </li>
                                                  </ng-container>
                                                </ng-container>
                                              </ul>
                                            </ng-template>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div class="col-12" *ngIf="filteredcount()>pageSize">
                <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize"  [collectionSize]="filteredcount()" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
            </div>
          </div>
      </div>
      <footer class="submit-footer justify-content-end gap-16">
        <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="filterdata?.length>0">
          <span class="">{{countSelected()}} of {{filterdata?.length}} selected </span>
          <span *ngIf="filterdata?.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{filterdata?.length}} employees</span>
          <span *ngIf="filterdata?.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{filterdata?.length}} employees</span>
        </p>
          <button class="btn btn-primary btn-sm text-uppercase" [disabled]="countSelected()==0"  (click)="createSelectedLIst();">send reminder </button>
      </footer>
  </main>
</div>
<div class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
<div class="modal-dialog modal-dialog-centered ">
  <div class="modal-content">
        <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">{{selectedList.length}} Employee(s) selected</h4>
        <button type="button" class="btn-close ms-auto my-0" (click)="downloadMsg = false" ></button>
        </div>
        <div class="modal-body">
            <p class="mb-0">Click the Send Reminder button to notify the approver of the pending requests. </p>
        </div>
        <div class="modal-footer">
        <button [disabled]="saveComplete" type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="downloadMsg = false;" >Cancel</button>
        <button [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase" (click)="sendReminder()">SEND REMINDER</button>

        </div>
    </div>
</div>
</div>


