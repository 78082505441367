<!-- <form class=""> -->
    <div class="container-fluid p-32 form-container">
        <div class="row mb-24">
            <div class="col-md-4">
                <p class="text-uppercase fw-500 mb-0">Verify Part A</p>
            </div>
            <div class="col-md-8" *ngIf="sucessData.length>0">
                <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
                    <div style="min-width:22.688rem;">
                        <div class="form-icon2">
                          <i class="icon-search icon-left fs-14"></i>
                          <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                          <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                        </div>
                    </div>
                    <!-- <button  class="btn flex-shrink-0 btn-outline-primary btn-icon fw-500 fs-12" (click)="downloadAll(form16A_Data.downloadfile)"><i class="bi bi-download"></i>Download All </button> -->
                    <button  class="btn flex-shrink-0 btn-outline-primary btn-icon fw-500 fs-12" (click)="this.appservice.downloadFile(form16A_Data.downloadfile,'PartA.zip')"><i class="bi bi-download"></i>Download All </button>
                </div>
            </div>
        </div>
        <div class="row row-24">
            <div class="col-12" *ngIf="form16A_Data.total_errors > 0">
                <div class="card card-c2 overflow-hidden">
                    <div class="table-responsive" >
                        <table class="table table-striped table-sm" >
                            <thead>
                                <tr>
                                    <th class="fw-600" scope="col">Uploaded file</th>
                                    <th class="fw-600" scope="col">File count</th>
                                    <th class="fw-600" scope="col">Employees count</th>
                                    <th class="fw-600" scope="col">Errors</th>
                                    <th class="fw-600" scope="col">Error report</th>
                                </tr>
                            </thead>
                            <tbody class="cell-16">
                              <tr>
                                  <td>{{form16A_File}}</td>
                                  <td>{{form16A_Data.total_count}}</td>
                                  <td>{{form16A_Data.employee_count}}</td>
                                  <td>{{form16A_Data.total_errors}}</td>
                                  <td>
                                    <div class="d-flex gap-16">
                                        <div class="d-flex gap-16">
                                          <a class="link-primary fs-20" (click)="errorToggle=true;"><i class="bi bi-eye"></i></a>
                                          <a class="link-primary fs-20" (click)="export()" ><i class="bi bi-download"></i></a>
                                        </div>
                                    </div>
                                  </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="sucessData.length > 0">
                <div class="card card-c2 overflow-hidden">
                    <div class="table-responsive">
                        <table class="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th class="fw-600" scope="col">Employee name</th>
                                    <th class="fw-600" scope="col">Employee code</th>
                                    <th class="fw-600" scope="col">Download</th>
                                </tr>
                            </thead>
                            <tbody class="cell-16">

                              <tr *ngFor='let data of $any(sucessData|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)'>

                                    <td>{{data.first_name+" "+(data.middle_name!=null?data.middle_name:" ")+ " "+data.last_name}}</td>
                                    <td>{{data.employee_code}}</td>
                                    <td>
                                       <div class="d-flex gap-16">

                                            <a class="link-primary fs-20" (click)="viewDocument(data.document)" ><i class="bi bi-eye"></i></a>
                                            <!-- <a class="link-primary fs-20" (click)="downloadDoc(data.document,data.first_name+' '+data.last_name+'('+data.employee_code+')PartA')"><i class="bi bi-download"></i></a>
                                            -->
                                            <a class="link-primary fs-20" (click)="this.appservice.downloadFile(data.document,data.first_name+' '+data.last_name+'('+data.employee_code+')PartA')"><i class="bi bi-download"></i></a>
                                        </div>
                                    </td>
                                </tr>

                              </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="this.appservice.filteredcount(sucessData,searchString)>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="this.appservice.filteredcount(sucessData,searchString)"  [(page)]="page"
                  [pageSize]="pageSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
              </ngb-pagination>
            </div>

        </div>
    </div>
    <footer class="submit-footer">
        <a class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="goNext(3)">Next</a>
    </footer>
<!-- </form> -->

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<!-- Error view -->
<div class="modal  modal-pdfviewer modal-custom {{errorToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
          <div class="modal-header gap-16">
              <h4 class="modal-title">Part A Error</h4>
              <i (click)="errorToggle = false" class="ms-auto bi b bi-x-lg text-light-500"></i>
          </div>
          <div class="modal-body px-0 p-10">
            <div class="pdf-container overflow-scroll p-16">
              <div class="card card-c2 overflow-hidden">
                <div class="table-responsive">
                  <table class="table table-striped table-sm td-12 th-12">
                   <thead>
                     <tr>
                         <th class="fw-600" scope="col">Employee name</th>
                         <th class="fw-600" scope="col">Employee code</th>
                         <th class="fw-600" scope="col">Error description</th>
                     </tr>
                   </thead>
                   <tbody class="cell-16">
                     <tr *ngFor="let data of errorData">
                       <td>{{data.first_name!=''?data.first_name+" "+(data.middle_name!=null?data.middle_name:" ")+ " "+data.last_name:''}}</td>
                       <td>{{data.employee_code}}</td>
                       <td>{{data.document_error}}</td>
                     </tr>
                   </tbody>

                  </table>
                </div>
              </div>
            </div>

          </div>

      </div>
  </div>
</div>
