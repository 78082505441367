import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-confirmation-policy-settings',
  templateUrl: './confirmation-policy-settings.component.html',
  styleUrls: ['./confirmation-policy-settings.component.scss']
})
export class ConfirmationPolicySettingsComponent implements OnInit {

  @Input() disabled  : any
  @Input() formGp   !: FormGroup
  @Input() extensionDropdown : any
  @Input() confirmationDropdown : any
  @Input() terminationDropdown : any
  @Input() editflag : any;

  @Output() submitMethod = new EventEmitter();

  saveBtnClicked     : boolean = false
  addBtn             : boolean = false
  listOfMonth                  = [
    {id : 1, name : 'January'},
    {id : 2, name : 'February'},
    {id : 3, name : 'March'},
    {id : 4, name : 'April'},
    {id : 5, name : 'May'},
    {id : 6, name : 'June'},
    {id : 7, name : 'July'},
    {id : 8, name : 'August'},
    {id : 9, name : 'September'},
    {id : 10, name : 'October'},
    {id : 11, name : 'November'},
    {id : 12, name : 'December'}
  ];

  letterControls    : any     = ['confirmation_letter','extension_letter','termination_letter']

  private subjectKeyUp = new Subject<any>();
  confirmationUnit  : any =[]
  value             : any
  id                : any

  constructor(
    public msgServ   : MessageService,
    public existDataService :  ExistDatasService,
    public route: ActivatedRoute
  ) { }

  get f(){ return this.formGp.controls; }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    this.id = this.route.snapshot.params['id']
    if(this.formGp.get('is_send_letter')?.value == true){
      this.setRequired(true)
    }
    if(this.formGp.get('is_extend')?.value == true){
      this.extensionControlValidate(true)
    }
  }

  // Validation based on control
  extensionControlValidate(val:any){
    if(val){
      this.formGp.get('extension_period')?.setValidators([Validators.required,Validators.min(1),Validators.max(1000)])
      this.formGp.get('max_extension')?.setValidators([Validators.required,Validators.min(1),Validators.max(5)])
    }else{
      this.formGp.get('extension_period')?.clearValidators()
      this.formGp.get('max_extension')?.clearValidators()
    }
    this.formGp.get('extension_period')?.updateValueAndValidity()
    this.formGp.get('max_extension')?.updateValueAndValidity()
  }

  resetExtension(){
    this.formGp.get('extension_period')?.setValue(null)
    this.formGp.get('max_extension')?.setValue(null)
    this.formGp.get('is_edit_extend')?.setValue(false)
  }

  validateForm() {
    this.saveBtnClicked=true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitMethod.emit(2);
  }

  notanumber(val:any){
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":"confirmationPolicy",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
      this.value ={
        "id":this.id,
        "page":"confirmationPolicy",
        "field":"name",
        "value": this.f.name.value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.confirmationUnit = res.body
      if(this.confirmationUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  setRequired(isTrue:any){
    if(isTrue){
      this.letterControls.forEach((letter:any)=>{
        this.formGp.get(letter)?.setValidators([Validators.required])
      })
    }else{
      this.letterControls.forEach((letter:any)=>{
        this.formGp.get(letter)?.clearValidators()
      })
    }
  }

  resetForm(){
    this.letterControls.forEach((letter:any)=>{
      this.formGp.get(letter)?.setValue(null);
      this.formGp.get(letter)?.updateValueAndValidity()
    })
  }
}
