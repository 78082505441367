<form class="" [formGroup]="policyForm" autocomplete="off">
    <ng-container formArrayName="configureform">
        <ng-container>
            <div class="container-fluid p-32 form-container">
                <div class="row row-16">

                    <div class="col-12 d-flex">
                      <p  class="text-uppercase fw-500 mb-0">Configure leave type</p>
                      <div class="ms-auto" style="width:22.688rem">
                        <app-searchbar [searchlist]='mapLeaveTypeList | sort:"name"' [searchplaceholder]='leaveTypePlaceholder'
                        (keywordsearchEmitter)="searchLeaveType($event)" (searchEmitter)="searchresults($event)"
                        (closesearchEmitter)="onCleared($event)"></app-searchbar>
                      </div>
                    </div>
                </div>
                <app-c1-loader *ngIf="loader"></app-c1-loader>

                <div class="row row-8 mt-8">
                    <div class="col-12" *ngFor="let item of mapLeaveType;let ind=index">
                        <ng-container *ngIf="item.leaveId!=leaveTypeId; else all_data">
                            <div class="card card-c2">
                                <div class="card-body px-24 py-10 d-flex align-items-center">
                                    <span class="me-10 text-trim" title="{{item.name}}">{{item.name}}</span>
                                    <ng-container *ngIf="activeTabConfig[ind]['step5']==true">

                                      <i class="icon-check-circle ms-8 fs-18 text-success"></i>
                                      <a class="link-primary align-items-center fs-14 d-flex ms-auto"
                                          (click)="AddLeaveConfig(item.name,item.leaveCode,item.leaveId,ind);">
                                          <i class="icon-pencil-square me-1"></i> Edit
                                      </a>
                                    </ng-container>
                                    <span class="ms-auto link-primary1 fs-14 d-flex align-items-center"
                                        (click)="AddLeaveConfig(item.name,item.leaveCode,item.leaveId,ind)"
                                        *ngIf="activeTabConfig[ind]['step5']==false">

                                        <i class="icon-plus-square-fill fs-16 me-8 "></i>Add
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #all_data>
                        <div class="col-12">
                            <div class="card card-c2">
                                <div class="card-body d-flex p-0 bg-white position-sticky border-bottom align-items-center"
                                    style="top:3.625rem">
                                    <p class="mb-0 py-16 ps-16 w-25 text-trim" (click)="showDetails = !showDetails;">
                                        {{leaveTypeName}}</p>
                                    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTabConfig[this.activeIndex]['tab']"
                                        class="nav nav-c2 py-16 px-40" (navChange)="onNavChange($event)">
                                        <li [ngbNavItem]="1">
                                            <a ngbNavLink class="step"
                                                [class]="{'success':leaveConfigForm['controls'].leaveform?.valid && activeTabConfig[this.activeIndex]['step1']==true}">
                                            </a>
                                            <ng-template ngbNavContent>
                                                <app-leave-type-details [leaveTypeName]="leaveTypeName"
                                                    [leaveTypeCode]="leaveTypeCode" [leaveForm]="leaveForm"
                                                    [leaveConfigForm]="leaveConfigForm" [mapLeaveType]="mapLeaveType"
                                                    [saveBtnClicked]="saveBtnClicked"
                                                    [activeIndex]="activeIndex"></app-leave-type-details>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="2">
                                            <a ngbNavLink class="step"
                                                [class]="{'success': ((creditdetailsForm.controls[activeIndex].get('leavemapping_creditrule')?.value.length!=0 && creditdetailsForm.controls[activeIndex].get('grant_leave')?.value==false) || creditdetailsForm.controls[activeIndex].get('grant_leave')?.value==true)&&  activeTabConfig[this.activeIndex]['step2']==true}">
                                            </a>
                                            <ng-template ngbNavContent>
                                                <app-leave-credit-details [creditdetailsForm]="creditdetailsForm"
                                                    [leaveConfigForm]="leaveConfigForm" [leaveTypeCode]="leaveTypeCode"
                                                    [leaveCreditArr]="leaveCreditArr" [saveBtnClicked]="saveBtnClicked"
                                                    [editData]="editData"></app-leave-credit-details>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="3">
                                            <a ngbNavLink class="step"
                                                [class]="{'success':leaveConfigForm['controls'].leaveEligibilty?.valid && activeTabConfig[this.activeIndex]['step3']==true }">
                                            </a>
                                            <ng-template ngbNavContent>
                                                <app-leave-eligibility [leaveEligibiltyForm]="leaveEligibiltyForm"
                                                    [leaveConfigForm]="leaveConfigForm" [leaveTypeCode]="leaveTypeCode"
                                                    [saveBtnClicked]="saveBtnClicked"></app-leave-eligibility>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="4">
                                            <a ngbNavLink class="step"
                                                [class]="{'success':leaveConfigForm['controls'].leaveRequestcriteria?.valid && activeTabConfig[this.activeIndex]['step4']==true  }">
                                            </a>
                                            <ng-template ngbNavContent>
                                                <app-leave-request-criteria
                                                    [leaveRequestCriteriaForm]="leaveRequestCriteriaForm"
                                                    [leaveConfigForm]="leaveConfigForm" [leaveTypeCode]="leaveTypeCode"
                                                    [saveBtnClicked]="saveBtnClicked"></app-leave-request-criteria>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="5">
                                            <a ngbNavLink class="step"
                                                [class]="{'success':leaveConfigForm['controls'].leaveRequestLimit?.valid && activeTabConfig[this.activeIndex]['step5']==true}">
                                            </a>
                                            <ng-template ngbNavContent>
                                                <app-leave-requeset-limit
                                                    [leaveRequestLimitForm]="leaveRequestLimitForm"
                                                    [leaveConfigForm]="leaveConfigForm" [leaveTypeCode]="leaveTypeCode"
                                                    [saveBtnClicked]="saveBtnClicked"></app-leave-requeset-limit>
                                            </ng-template>
                                        </li>
                                    </nav>
                                    <i (click)="showDetails = !showDetails"
                                        class="bi {{showDetails ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}}  py-16 pe-32" id="showdetails{{leaveTypeCode}}"></i>
                                </div>
                                <ng-container *ngIf="showDetails">
                                    <div class="card-body p-0 border-top-0">
                                        <div [ngbNavOutlet]="nav"></div>
                                    </div>
                                    <div class="card-body d-flex p-16 gap-16 justify-content-end bg-white position-sticky"
                                        style="bottom:4.5rem">
                                        <!-- <button class="btn btn-outline-primary  btn-sm   text-uppercase" (click)="check()">jjjj</button> -->
                                        <button class="btn btn-outline-primary  btn-sm   text-uppercase"
                                            (click)="unchangeActivetab()">cancel</button>
                                        <button class="btn btn-primary   btn-sm  text-uppercase"
                                            (click)="changeActivetab()"
                                            [disabled]="checkFormValidity()">{{activeTabConfig[this.activeIndex]['tab']!=5?'Continue':'Save'}}</button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <footer class="submit-footer">
                <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="configureFunction()"
                    [disabled]="checkOverAllValidity()">NEXT</button>
            </footer>
        </ng-container>
    </ng-container>
</form>
