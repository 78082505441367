import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { ChatComponent } from './chat.component';

const routes: Routes = [LayoutService.childRoutes([
  { path: 'chat', component:ChatComponent },
  
])
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule { }
