import { Routes, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { AuthGuard } from 'src/app/@core/auth/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor() { }
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: LayoutComponent,
      children: routes,
      canActivate: [AuthGuard]
    };
  }
}
