import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-menu',
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.scss']
})
export class DynamicMenuComponent implements OnInit {
  @Input() menuList:any;
  constructor() { }

  ngOnInit(): void {
  }

}
