import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ChallanService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  companyDropDownData(){
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption)
  }
  challanList(company_id:any,year:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-list/?company_id=${company_id}&year=${year}`, this.httpOption)
  }
  challanMonthList(company_challan_id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-quarter-month-list/?company_challan_id=${company_challan_id}`, this.httpOption)
  }
  // challanEmpMapList(challan_runpayroll_id:any){
  //   return this.http.get(`${this.API_PATH}hr/api/v1/challan-employee-map-list/?challan_runpayroll_id=${challan_runpayroll_id}`, this.httpOption)
  // }
  challanCreateEmpMapListFilter(challan_amount:any,challan_runpayroll_id:any,bu:any,dept:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-employee-map-list/?challan_operation=create&challan_amount=${challan_amount}&challan_runpayroll_id=${challan_runpayroll_id}&businessunit=[${bu}]&department=[${dept}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`, this.httpOption)
  }
  challanUpdateEmpMapListFilter(challan_id:any,challan_amount:any,bu:any,dept:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-employee-map-list/?challan_operation=update&challan_id=${challan_id}&challan_amount=${challan_amount}&businessunit=[${bu}]&department=[${dept}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`, this.httpOption)
  }
  challanUpdatedEmpMapListFilter(challan_id:any,bu:any,dept:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-employee-map-list/?challan_operation=get&challan_id=${challan_id}&businessunit=[${bu}]&department=[${dept}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`, this.httpOption)
  }
  challanCreate(body: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/challan-create/`, body, this.httpOption)
  }
  challanUpdateList(company_challan_id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-update-list/${company_challan_id}/`, this.httpOption)
  }
  challanDelete(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}hr/api/v1/challan-delete/${id}/`, body, this.httpOption)
  }
  challanEdit(challan_id: number) {
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-update/${challan_id}/`, this.httpOption)
  }
  challanUpdate(challan_id: number, body:any) {
    return this.http.put(`${this.API_PATH}hr/api/v1/challan-update/${challan_id}/`, body, this.httpOption)
  }

  tdsList(company_id:any,year:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/tds-list/?company_id=${company_id}&year=${year}`, this.httpOption)
  }
  tdsDownload(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/tds-download-excel/${id}/`,   {responseType: 'blob' as 'blob'})
  }
  tdsGenarate(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/tds-generate-excel/${id}/`, this.httpOption)
  }

  getExcessAmountList(company_challan_id : any){
    return this.http.get(`${this.API_PATH}hr/api/v1/excess-challan-list/?company_challan_id=${company_challan_id}`, this.httpOption)

  }

  getChallanDueList(company_challan_id : any){
    return this.http.get(`${this.API_PATH}hr/api/v1/tax-liability-month-list/?company_challan_id=${company_challan_id}`, this.httpOption)

  }

  generateExcessAmount(data: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/challan-create/`, data, this.httpOption)
  }

  challanUpdateAmountListFilter(challan_id:any,bu:any,dept:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/challan-employee-map-list/?challan_operation=update&challan_id=${challan_id}&businessunit=[${bu}]&department=[${dept}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`, this.httpOption)
  }
}
