import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-letter-template-select-template',
  templateUrl: './letter-template-select-template.component.html',
  styleUrls: ['./letter-template-select-template.component.scss']
})
export class LetterTemplateSelectTemplateComponent implements OnInit {

  @Input() formGp   !: FormGroup
  @Input() disabled              = false
  @Input() defaultTemplate : any
  @Input() editorForm : any

  @Output() submitMethod         = new EventEmitter();

  @ViewChild('image') public image !: ElementRef;

  submitted        : boolean = false;
  loader           : boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loader = false
    if(this.image && this.image.nativeElement){
      const image: HTMLImageElement = this.image.nativeElement;
      image.onload = () => {
        this.loader = false
      };
    }
  }

  getTemplateDetails(tags:any){
    this.formGp.valueChanges.subscribe((newValue:any) => {
      this.editorForm.get('template')?.reset(null)
      this.editorForm.get('recentTags')?.reset(null)
    });
    this.formGp.patchValue({
      tags : tags
    })
  }

  // Validate
  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitMethod.emit(3);
  }
}
