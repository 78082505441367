import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TemplateService } from 'src/app/@core/services/template.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { convertToObject } from 'typescript';
import { MyUploadAdapter } from '../../upload-adapter';
import { UploadAdapterService } from 'src/app/@core/services/upload-adapter.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplateDetailsComponent implements OnInit, OnChanges {

  constructor(public form: FormBuilder, public http: HttpClient,
    private dataUrl: UploadAdapterService,
     private templateservice:TemplateService,
     public messageService : MessageService,
     private appService: AppService) {

  }
  empName: string = ''
  enable: boolean = true;
  arr = [10, 20];
  showItem = true;
  tags = ['Joining Date', 'Probation Date'];
  templatetype:any;
  tagnamedetails:any;
  limit=20;
  offset=0;
  searchFilterName: any= '' ;
  public searchFilter: any;
  searchString:any = "";
  // unamePattern = "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";




Editor = ClassicEditor;


editorData: any = this.test();

  @Output()
  onpreviewclick = new EventEmitter();

  basket = ['Employee Name'];
  @Input() templatedetailForm : any;
  @Input() id : any;



  activeCustomers = [
    'John',
    'Watson'
  ];

  inactiveCustomers = [
    'Adam',
    'Jack',
    'Katherin'
  ];



  ngOnInit() {
    this.getTemplateTypedetails();
    this.empName = "Employee Name";

    // this.editor = new Editor();
    // this.templateservice.getsingletemplatedetail(this.id).subscribe((res: any) => {
    //   this.templatedetailForm.patchValue({
    //     templateName: res.body.template_name,
    //     templateType: res.body.template_type,
    //     subject: res.body.subject,
    //     description: res.body.description,
    //     tagname: res.body.tag
    //   })
    // });



  }
  get f(){
    return this.templatedetailForm.controls;
  }

  ngOnChanges(change:SimpleChanges): void {
    // this.basket = [];
    // this.basket[0] = 'Employee Name'
  if(!this.templatedetailForm.controls['tagname'].value){
    this.templatedetailForm.get('tagname')?.patchValue('Employee Name');
  }
//  console.log(this.templatedetailForm,'Changes-details');
//  console.log(this.templatedetailForm.controls['templateType'].value,'Details....');

  // this.templateservice.getTagnameDetails(this.limit,this.offset,this.templatedetailForm.controls['templateType'].value.id).subscribe((res:any)=>{
  //   this.tagList = res.body.results;
  //   console.log(this.tagList,'OnChanges......');
  // })
}

  tagname() {
    return `
    <span cdkDropList [cdkDropListData]="basket"
                    class="d-flex gap-8 flex-column justify-contents-items" (cdkDropListDropped)="drop($event)">
                    <span class="variable badge rounded-pill me-auto fs-14 px-16 pointer" *ngFor="let item of basket"
                      cdkDrag>
                      {{item}}
                    </span>
                  </span>`
  }
  change({ editor }: ChangeEvent) {
     this.editorData = editor.getData();
  }


onReady(editor : any): void {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any) => {
      return new MyUploadAdapter( loader, this.http, this.dataUrl );

  };
}

  remove() {
    this.empName = '';
  }

  public dragging: boolean = false;

  public handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }

  public handleClick(event: MouseEvent): void {
    if (this.dragging) {
      this.dragging = false;
      return
    }
  }
  test() {
    return `
    <p>Hi Employee Name,</p>
    <br>
    <p>Welcome to the Organization, We are glad to have you as part of our team.
    As a initiation we will be setting up series or meeting with our management for your better knowledge of
    organization and work culture.</p><br>
  <p>Looking forward for your greater efforts. </p><br>
<br>
  <span>Team Company Name</span> <br>`
  }

  data = [];


  getTemplateTypedetails() {
    this.templateservice.getTemplateType().subscribe((res:any)=>{
      this.templatetype=res.body;
      // console.log(this.templatetype);
    })
  }
  tagList:any=[];
  ontypeclick(templateTypeId:any)
  {
    // console.log(templateTypeId)
    let id=templateTypeId.id;
    let limit=20;
    let offset=0;
    this.templateservice.getTagnameDetails(limit,offset,id).subscribe((res:any)=>{
      this.tagList = res.body.results;
      // console.log(this.tagList);
    })
  }

  onclick() {
    this.enable = false;
  }

  onPreviewClick() {
    // this.templatedetailForm.get('tagname')?.patchValue(this.basket[0]);
    // this.templatedetailForm.get('templatetype')?.patchValue(this.templatedetailForm.get('templatetype')?.value.name);
    //var plainText = this.templatedetailForm.get('description')?.value.replace(/<[^>]*>/g, '');
   // this.templatedetailForm.get('description')?.patchValue((this.templatedetailForm.get('description')?.value.replace(/<[^>]*>/g, '')).replace(/(\r\n|\n|\r)/gm, ""));
    var templatedetailsdata = {
      "template_name": this.templatedetailForm.controls['templateName'].value,
      "template_type": this.templatedetailForm.controls['templateType'].value.name,
      "subject": this.templatedetailForm.controls['subject'].value,
      "description": this.templatedetailForm.controls['description'].value,
      "tag": this.templatedetailForm.controls['tagname'].value
   };
    this.onpreviewclick.emit(templatedetailsdata);
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex,
  //     );
  //   }
  // }
  //-------------------------

  datasample()
  {
    // console.log("type clicked");
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     debugger
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex,
  //     );
  //     this.basket = [];
  //     this.basket.push(event.container.data[0])
  //     const index = this.tags.indexOf(event.container.data[0]);
  //     if (index > -1) {
  //       this.tags.splice(index, 1);
  //     }
  //   }
  // }

  dropNew(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      debugger
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      this.tags.push(event.container.data[0])
      this.basket = [];
      // const index = this.basket.indexOf(event.container.data[0]);
      // if (index > -1) {
      //   this.basket.splice(index, 1);
      // }
    }
  }

  //-----------------------------

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      // console.log('dropped Event', `> dropped '${event.item.data}' into '${event.container.id}'`);
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      // console.log('dropped Event', `> dropped '${event.item.data}' into '${event.container.id}'`);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }



}
