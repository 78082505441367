import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fbp-declaration-view',
  templateUrl: './fbp-declaration-view.component.html',
  styleUrls: ['./fbp-declaration-view.component.scss']
})
export class FbpDeclarationViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
