  <!-- <form [formGroup]="settingForm" (ngSubmit)="onSubmit()"  autocomplete="off"> -->
<div class="d-flex flex-column " style="min-height: calc(100vh - 3rem);" *ngIf="permissions">

  <div class="container-fluid p-24">
    <div class="row  mb-16">
        <div class="col-md-4">
            <h3>Miscellaneous </h3>
            <p class="text-helper mb-md-0">Configure date, time and other miscellaneous settings.
            </p>
        </div>
    </div>
    <app-c1-loader *ngIf="loader || !permissions?.v"></app-c1-loader>
    <div class="card card-c2 px-24 pt-16 pb-24"  *ngIf="!loader && permissions?.v">

      <div class="row row-16">
        <div class="col-12"> <div class="title">Date & time</div></div>
        <div class="col-md-5">
          <label for="dateformat" class="form-label required">Date format</label>
          <!-- <ng-container > -->
            <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('date format')}}" #myInputField="ngModel" [ngClass]="{'is-invalid' : disabledValidation(selectedDate,selectedTime,1)}"
            [(ngModel)]="selectedDate" [readonly]="!permissions.a && !permissions.e?true:false"
            >
            <ng-option *ngFor="let date of datelist" [value]="date.value"> {{date.dateformat}}
            </ng-option>
          </ng-select>
          <div *ngIf="disabledValidation(selectedDate,selectedTime,1)" class="invalid-feedback" style="display:block !important">{{this.messageService.validationDisplay('required')}}</div>

          <!-- </ng-container> -->
          <!-- <ng-container *ngIf="permissions.a == false && permissions.e == false && permissions.v == true">
            <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('date format')}}"
            [(ngModel)]="selectedDate"
            [readonly]="true"
            >
            <ng-option *ngFor="let date of datelist" [value]="date.value"> {{date.dateformat}}
            </ng-option>
          </ng-select>
          <div *ngIf="disabledValidation(selectedDate,selectedTime,1)" class="invalid-feedback" style="display:block !important">{{this.messageService.validationDisplay('required')}}</div>
          </ng-container> -->


        </div>

          <div class="col-md-5">
            <label for="timeformat" class="form-label required">Time format</label>
            <!-- <ng-container *ngIf="permissions.a || permissions.e"> -->
            <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('time format')}}" [ngClass]="{'is-invalid' : disabledValidation(selectedDate,selectedTime,2)}"
                    [(ngModel)]="selectedTime" [readonly]="!permissions.a && !permissions.e?true:false">
                        <ng-option *ngFor="let time of timelist" [value]="time.value"> {{time.timeformat}}
                        </ng-option>
            </ng-select>
            <div *ngIf="disabledValidation(selectedDate,selectedTime,2)" class="invalid-feedback" style="display:block !important">{{this.messageService.validationDisplay('required')}}</div>

          <!-- </ng-container> -->
          <!-- <ng-container *ngIf="permissions.a == false && permissions.e == false && permissions.v == true">
            <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('Time format')}}"
                    [(ngModel)]="selectedTime"
            [readonly]="true"
            >
                        <ng-option *ngFor="let time of timelist" [value]="time.value"> {{time.timeformat}}
                        </ng-option>
            </ng-select> -->
            <div *ngIf="disabledValidation(selectedDate,selectedTime,2)" class="invalid-feedback" style="display:block !important">{{this.messageService.validationDisplay('required')}}</div>

          <!-- </ng-container> -->

          </div>
        </div>
    </div>
    <!-- <div class="card card-c2 px-24 pt-16 mt-24 pb-24"  >

      <div class="row row-16">
        <div class="col-12"> <div class="title">Password protection</div></div>
        <div class="col-12 form-row">
            <label class="form-label required"> Do you want to publish password protected pay slips? </label>

          </div>
        <div class="col-md-12 form-row ">
          <div class="form-check form-check-inline">
              <input  name=""  type="radio" class="form-check-input" [value]="true">
              <label   class="form-check-label">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input  name=""  type="radio" class="form-check-input" [value]="false">
            <label   class="form-check-label">No</label>
        </div>
      </div>
        </div>
    </div> -->
  </div>
  <footer class="side-panel-footer radius-0 mt-auto gap-32" *ngIf="permissions.a || permissions.e">
     <button  class="ms-auto btn text-uppercase btn-sm btn btn-primary" type="button" [disabled]="disabledValidation(selectedDate,selectedTime)|| saveClicked" (click)="datetimeChange('date',selectedDate,'time',selectedTime)">Save</button>
  </footer>
</div>
<!-- </form> -->
