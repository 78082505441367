import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyAttendenceCalenderService } from 'src/app/@core/services/my-attendence-calender.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import * as moment from 'moment';
import { AttendanceDashboardService } from 'src/app/@core/services/attendance-dashboard.service';
import { AppService } from 'src/app/app.global';
import { AttendanceCalendarComponent } from 'src/app/@shared/components/attendance-calendar/attendance-calendar.component';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { Location } from '@angular/common';
import { AttendanceTableComponent } from 'src/app/@shared/components/attendance-table/attendance-table.component';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { MyTeamAttendanceService } from 'src/app/@core/services/myteam-attendance.service';
import { DatePipe } from '@angular/common';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
@Component({
  selector: 'app-employee-attendance-view',
  templateUrl: './employee-attendance-view.component.html',
  styleUrls: ['./employee-attendance-view.component.scss']
})
export class EmployeeAttendanceViewComponent implements OnInit {
  overrideAttendance = false;
  @Input() from: any;
  deductionLeave = false;
  deductionPaid = false;
  addPunch = false;
  activeEmpData: any;
  activeYear: any;
  activeMonth: any;
  dropdownMonth: any
  dropdownYear: any
  activeEmployee: any;
  activatePunch: any;
  infoMsg: any
  punchId: any
  clickedDate: any
  empDoj: any
  permissions: any
  validation: any = ""
  loader = true;
  savePunch = false
  deleteToggle = false;
  deleteClick = false;
  showInfo = false;
  hasShift = true;
  isChecked = true;
  view = 1;
  outputData: any
  advanceDetails: any = []
  today = this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  options = [
    { name: 'On Duty' },
    { name: 'Regularization' },
    { name: 'Leave' },
    { name: 'Holiday' },
  ];
  punchForm = this.fb.group({
    punch: this.fb.array([]),
  });
  EmpListData: any;
  overrideData:any=[];
  activeempCode:any;
  statusoptions = [
    { name: 'Absent' },
    { name: 'Present' },
    { name: 'Rollback Status' },
  ];
  overrideform = this.fb.group({
    firsthalfstatus : ['Absent'],
    secondhalfstatus : ['Absent']
  });
  radiovalue = true;
  statusalert = false;
  overridedisable = false;
  firsthalfdisable = false;
  secondhalfdisable = false;
  btnclicked= false;
  fromoverrde:any;
  noedit = true;
  errormsg:any='Rollback is not required as no changes have been made to the attendance status.';
  empList:any = []
  nodata = true
  loopcompleted = false
  isteam = false
  @ViewChild(AttendanceCalendarComponent) calendar: any;
  @ViewChild(AttendanceTableComponent) table: any;
  constructor(
    private attendenceCalenderService: MyAttendenceCalenderService,
    public route: ActivatedRoute,
    private snapshotService: SnapshotService,
    public fb: FormBuilder,
    public attendanceDashboardService: AttendanceDashboardService,
    public appservice: AppService,
    public messageService: MessageService,
    private notificationService: NotificationService,
    public _location: Location, private livelocationService: LivelocationService,
    public router: Router,
    public empServ: EmployeeService,
    private MyTeamAttendanceService: MyTeamAttendanceService,
    private datePipe: DatePipe,
    private previousRouteService: PreviousRouteService,

  ) { }

  ngOnInit(): void {

    this.getPermission()
    this.route.params.subscribe((params: Params) => {

      if (!isNaN(params['employee']))
        this.activeEmployee = parseInt(params['employee']);
      else if (!isNaN(params['myTeamEmpId']))
        this.activeEmployee = parseInt(params['myTeamEmpId']);
      if (this.router.url.includes('my-team-attendance')) 
        this.isteam = true


    })
    this.getList();
  }
  getData(){
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['year']) && params['year']) {
        this.activeYear = params['year'];
      } else {
        this.activeYear = (new Date()).getFullYear();
      }
      if (isNaN(params['month']) && params['month']) {
        this.activeMonth = params['month'];
      } else {
        this.activeMonth = moment(new Date()).format('MMMM')
      }
      if (!isNaN(params['employee'])) {
        this.activeEmployee = parseInt(params['employee']);
        this.webCheckinValid()
        this.snapshotService.getEmployeeBasicData(this.activeEmployee).subscribe((res: any) => {
          this.activeEmpData = res.first_name + " " + (res.middle_name != null ? res.middle_name : "") + " " + res.last_name + " (" + res.employee_code + ")";
          this.activeempCode = res.employee_code;
        })
      }


      if (!isNaN(params['myTeamEmpId'])) {
        this.activeEmployee = +params['myTeamEmpId'];
        this.activeEmployee = parseInt(params['myTeamEmpId']);
        this.webCheckinValid()
        this.snapshotService.getEmployeeBasicData(this.activeEmployee).subscribe((res: any) => {
          this.activeEmpData = res.first_name + " " + (res.middle_name != null ? res.middle_name : "") + " " + res.last_name + " (" + res.employee_code + ")";
          this.activeempCode = res.employee_code;
        })
      }

    })
  }

  validationShift = '';
  getSummary() {
    this.snapshotService.getEmployeeBasicData(this.activeEmployee).subscribe((res: any) => {
      this.activeempCode = res.employee_code;
    })
    this.attendenceCalenderService.getAttendanceSummary(this.activeEmployee, this.activeMonth, this.activeYear,this.isteam?"team":"hr").subscribe((res: any) => {
      this.advanceDetails = res?.output;
      if (!res?.output?.is_unassigned_shift_dates_exist)
        this.validationShift = "Shift not configured. For more details contact HR."
    else
      this.validationShift = ""
    // console.log("validationShift",this.validationShift)
    })
  }

  getPermission() {
    if(this.previousRouteService.getPreviousUrl() == '/my-team-attendance' || this.previousRouteService.getPreviousUrl()?.includes('my-team-attendance')){
      this.permissions = this.appservice.getPermissions('/my-team-attendance');
      this.fromoverrde='team';
    }else{
      this.permissions = this.appservice.getPermissions('/employee-attendance');
      this.fromoverrde='hr';
    }

    if (this.permissions == undefined) {
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  visibleRange: any
  webCheckinValid() {
    this.attendenceCalenderService.getWebcheckinValidations(this.activeEmployee,this.isteam?"team":"hr").subscribe((res: any) => {

      if (res.status != 0) {
        this.outputData = res?.output
        if (res?.output?.is_attendance_rule_configed == false)
          this.validation = "Attendance rule is not configured. For more details contact HR."
        else if (res?.output?.is_attendance_capture_configed == false)
          this.validation = "Attendance capture policy is not configured. For more details contact HR."
        // else if (res?.output?.shift_data?.is_shift_error)
        //   this.validation = "Shift not configured. For more details contact HR."
        else
          this.validation = ""
        this.dropdownYear = Object.keys(res?.output?.dropdown_data)
        this.dropdownMonth = res?.output?.dropdown_data
        const monthsNums: any = { January: 0, February: 1, March: 2, April: 3, May: 4, June: 5, July: 6, August: 7, September: 8, October: 9, November: 10, December: 11 }
        this.visibleRange = {
          start: new Date(this.dropdownYear[0], monthsNums[this.dropdownMonth[this.dropdownYear[0]][0]], 1),
          end: new Date(this.dropdownYear[this.dropdownYear.length - 1], monthsNums[this.dropdownMonth[this.dropdownYear[this.dropdownYear.length - 1]][this.dropdownMonth[this.dropdownYear[this.dropdownYear.length - 1]].length - 1]] + 1, 0, 23, 59, 59)
        }

        this.empDoj = res?.output?.date_of_joined
        this.loader = false
        this.validateMonth()
      }

    })
  }

  addPunchAction(data: any) {
    this.activatePunch = true;
    this.hasShift = data[1]['shiftFrom'] == 0 ? false : true;
    this.activatePunch = true;
    this.clickedDate = data[0]['clickedDate'];
    this.overrideData = data;
    this.radiovalue=true;
  }
  activateAddPunch() {   
    this.punchForm.reset();
    if (this.hasShift == true) {
      this.showInfo = false;
      if (this.punch().controls.length == 0)
        this.addpunchData()
      else {
        this.punch().clear();
        this.addpunchData();
      }
      this.overrideAttendance = true;
    }
    else {
      this.showInfo = true;
      this.infoMsg = this.messageService.unassignedShiftMsg();
    }

  }

  punch() {
    return this.punchForm.get('punch') as FormArray;
  }
  addpunchData() {
    this.punch().push(this.createPunch());

  }
  deletePunch(index: any) {
    this.punch().removeAt(index);

  }

  createPunch(): FormGroup {

    return this.fb.group({
      "punch_time": [null, [Validators.required]],
      "remark": ['', [Validators.maxLength(500)]]
    })

  }
  savePunchdata() {

    if (this.punchForm.invalid)
      return

    if (!this.outputData?.is_attendance_capture_configed) {
      this.showInfo = true;
      this.infoMsg = 'Attendance capture policy has not been assigned yet.'
    } else if (!this.outputData?.is_attendance_rule_configed) {
      this.showInfo = true;
      this.infoMsg = 'Attendance rule has not been assigned yet.'
    } else {
      this.savePunch = true;
      let punchArray = [];
      let punchdata = this.punchForm.getRawValue().punch;

      for (let i = 0; i < this.punchForm.getRawValue().punch.length; i++) {
        punchArray.push({
          "punch_time": moment(punchdata[i]['punch_time']).format("HH:mm:ss"),
          "remark": punchdata[i]['remark'],

        })
      }

      let data = {
        "employee_id": this.activeEmployee,
        "punch_date": this.clickedDate,
        "data": punchArray
      }
      this.attendanceDashboardService.savePunchData(data,this.fromoverrde).subscribe((res: any) => {

        this.notificationService.handleSuccessNotification("Employee's punch data added successfully", "Success");
        this.getSummary()
        if (this.view == 1)
          this.calendar?.getCalendarData(true);
        else
          this.table.getTableData()
        // this.addPunch = false;
        this.overrideAttendance = false;
        this.savePunch = false

      })
    }
  }

  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }
  closeInfo(bool: any) {
    this.showInfo = false;
  }
  origin: any
  destination: any
  viewdata: any
  viewempmap: any

  viewempmapfn(data: any) {
    this.livelocationService.getOneemployee(this.activeEmployee, data?.clickedDate,this.isteam == true?'team-calender':'hr-calender').subscribe((res: any) => {
      if (res.body?.real_time_data?.length) {
        this.filterDataofTime(res.body, data)
      }
    })
  }
  filterDataofTime(data: any, punchData: any) {
    let rtd: any[] = [{
      "created_at_formatted": punchData?.clickedDate + " " + punchData?.punchData?.punch_time,
      "latitude": punchData?.punchData?.latitude,
      "location_name": punchData?.punchData?.location,
      "longitude": punchData?.punchData?.longitude
    }];
    this.viewdata = data;
    this.viewempmap = true;

    this.viewdata.real_time_data = rtd
    this.origin = { lat: punchData?.punchData?.latitude, lng: punchData?.punchData?.longitude };
    this.destination = { lat: punchData?.punchData?.latitude, lng: punchData?.punchData?.longitude };
  }
  changeMonthYear(currentTime: any) {
      this.activeYear = currentTime.getFullYear()
      this.activeMonth = moment(currentTime).format('MMMM') // this.appservice.getMonthName(currentTime.getMonth() + 1, 'long');
      this.getSummary()
  }

  validateMonth() {
    if (!this.dropdownMonth[this.activeYear].includes(this.activeMonth))
      this.activeMonth = this.dropdownMonth[this.activeYear][0]
    this.getSummary();
  }
  backClicked() {
    if (this.router.url.includes('my-team-attendance')) {
      this.router.navigate(['my-team-attendance']);
    } else if (this.router.url.includes('employee-attendance')) {
      this.router.navigate(['employee-attendance']);
    }
  }
  getList() {
    if (this.router.url.includes('my-team-attendance')) {
      this.MyTeamAttendanceService.empDDList(true, 'user__first_name').subscribe((res: any) => {
        this.arrangeSearchData(res?.data);
      });
    } else if (this.router.url.includes('employee-attendance')) {
      this.empServ.empDDfilterList(true, "ordering=user__first_name").subscribe((res: any) => {
        this.arrangeSearchData(res);
       });
     }
   }

   arrangeSearchData(res:any){
    for(let i=0; i< res?.length; i++){
        if(res[i]['first_name'] != null)
        res[i]['name'] = res[i]['first_name']+" ";

        if(res[i]['last_name'] != null)
        res[i]['name']   += res[i]['last_name'];
        res[i]['name']   +=" ("+res[i]['employee_code']+")";
        res[i]['image']   = res[i]['profile_image'];
        this.empList.push(res[i].id)
        if(res[i].id == this.activeEmployee){
          this.getData()
          this.nodata = false
        }
      if(i==res.length-1)
        this.loopcompleted = true
    }
      this.EmpListData = res;
  }
  overrideclick(){
    this.punchForm.reset();
    this.overridedisable =true;
    let firsthalf = this.overrideData[6]['status_firstHalf'];
    let secondhalf = this.overrideData[7]['status_secondHalf'];
    this.overrideform.get('firsthalfstatus')?.setValue(firsthalf);
    this.overrideform.get('secondhalfstatus')?.setValue(secondhalf);

    if(firsthalf == 'Holiday' || secondhalf == 'Holiday' || (firsthalf == 'Leave' && secondhalf == 'Leave') || (firsthalf == 'Weekoff' && secondhalf == 'Weekoff')){
      this.firsthalfdisable =true;
      this.secondhalfdisable =true;
      this.overridedisable =true;
    }else if(firsthalf == 'Leave' || firsthalf == 'Weekoff'){
      this.firsthalfdisable =true;
    }else if(secondhalf == 'Leave' || secondhalf == 'Weekoff'){
      this.secondhalfdisable =true;
    }else{
      // this.overridedisable =false;
      this.firsthalfdisable =false;
      this.secondhalfdisable =false;
    }
  }
  saveoverridedata(){
    this.btnclicked=true;
    if (this.overrideform.invalid)
      return

    if (!this.outputData?.is_attendance_capture_configed) {
      this.showInfo = true;
      this.infoMsg = 'Attendance capture policy has not been assigned yet.'
      this.statusalert = false;
    } else if (!this.outputData?.is_attendance_rule_configed) {
      this.showInfo = true;
      this.infoMsg = 'Attendance rule has not been assigned yet.'
      this.statusalert = false;
    } else {
            let data = {
              "employee_code": this.activeempCode,
              "punch_date": this.clickedDate,
              "first_half_value": this.overrideform.value.firsthalfstatus,
              "second_half_value": this.overrideform.value.secondhalfstatus
            }
            this.attendanceDashboardService.saveoverrideData(data,this.fromoverrde).subscribe((res: any) => {
              this.notificationService.handleSuccessNotification("Employee's attendance status has been updated successfully.", "Success");
              this.getSummary()
              this.webCheckinValid();
              if (this.view == 1)
                this.calendar?.getCalendarData();
              else
                this.table.getTableData()
              this.overrideAttendance = false;
              this.savePunch = false
              this.statusalert = false;
            })
          }
    }
    resetform(){
      this.overrideAttendance = false
      this.punchForm.reset();
      this.overrideform.reset();
      this.overrideclick()
    }
    statusoverride(key:any){
      if((this.overrideData[6]['status_firstHalf']==this.overrideform.value.firsthalfstatus) && (this.overrideData[7]['status_secondHalf']==this.overrideform.value.secondhalfstatus)){
        this.noedit =true;
      }else{
        this.noedit =false;
        this.overridedisable =false;
      }
      if(this.overrideData[8]['override_status'] == false){
        if(this.overrideform.value.firsthalfstatus == 'Rollback Status'){
          this.overrideform?.get('firsthalfstatus')?.setErrors({
            'firsthalferr' : true
          })
        }else{
          this.overrideform?.get('firsthalfstatus')?.setErrors(null)
        }
        if(this.overrideform.value.secondhalfstatus == 'Rollback Status'){
          this.overrideform?.get('secondhalfstatus')?.setErrors({
            'secondhalferr' : true
          })
        }else{
          this.overrideform?.get('secondhalfstatus')?.setErrors(null)
        }
      }
    }
    get f()  { return this.overrideform.controls; }

}
