import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TimeAttendenceSettingsService } from 'src/app/@core/services/time-attendence-settings.service';
import { CompanyService } from 'src/app/@core/services/company.service';
import { ConfigurationService } from 'src/app/@core/services/configuration.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';


@Component({
  selector: 'app-time-attendance-settings-setup',
  templateUrl: './time-attendance-settings-setup.component.html',
  styleUrls: ['./time-attendance-settings-setup.component.scss']
})
export class TimeAttendanceSettingsSetupComponent implements OnInit {

  constructor(  private fb: FormBuilder,
    public router: Router,
    public route:ActivatedRoute,
    public TAS: TimeAttendenceSettingsService,private companyService: CompanyService, private configService: ConfigurationService) { }
  activeTab = 1;
  companyList:any[]  = [];
  editCompId:any     = 0;
  loader  = false;
  id : number = 0
  checkboxes: any[]  = [];
  comp_id:any;
  iseditForm         = false;
  title     = "Add"
  disabled  = false
  compArr : any = []
  selectedComp: any  = [];


  ngOnInit(): void {
    this.loader = true;
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
      else
        this.getCompanyList();
      })
  }

  configForm: FormGroup = this.fb.group({
    company                                  : this.fb.array([]),
    edit_ot_hours                            : [false],
    showup_notify_home                       : [false],
    prior_days                               : [null],
    timeattendancesettings_role              : [null],
    initiate_consolidate_checkinrequest      : [null,[Validators.required,NumberpatternValidator(),Validators.max(10)]]
})

get compArray(){
  return this.configForm.controls['company'] as FormArray;
}

getCompanyList(){
  this.TAS.timeAttendenceCompList(true,'','',0,'').subscribe((res: any)=>{
    this.companyList = res;
    this.createCheckBoxArray();
    this.loader      = false;

  })
}
activeCheckbox(data:any){
  this.checkboxes = [];
  this.checkboxes = data;
}
createCheckBoxArray(){
  for(let i=0;i<this.companyList.length;i++)
  {
    this.comp_id    = this.companyList[i].id;
    var dict:any    = {};
    dict['id']      = this.comp_id;
    dict['value']   = false;
    this.checkboxes.push(dict);
  }
}

submitMethod(activeTab: any){
  this.buildSelectedData()
  this.activeTab = activeTab;
}

onNavChange(changeEvent: NgbNavChangeEvent){
  if (changeEvent.nextId === 2) {
   if (this.configForm.value.company.length==0) {
      changeEvent.preventDefault();
    }
    this.buildSelectedData()
  }
}

submitForm(){
  var dataArray:any;
  var rolearr : any = []
  if(this.configForm.controls.timeattendancesettings_role.value != null){
    for(let key of this.configForm.controls.timeattendancesettings_role.value)
    rolearr.push({
      whom_notify:key
    })
  }

  dataArray = {
    edit_ot_hours : this.configForm.controls.edit_ot_hours.value,
    showup_notify_home : this.configForm.controls.showup_notify_home.value,
    prior_days : (this.configForm.controls.showup_notify_home.value == true)?this.configForm.controls.prior_days.value:0,
    company : this.configForm.controls.company.value,
    timeattendancesettings_role: rolearr,
    initiate_consolidate_checkinrequest :this.configForm.controls.initiate_consolidate_checkinrequest.value,

  }
  if(this.id != 0){
    delete (dataArray['company']);
    dataArray['company'] = this.editCompId
  }
  if(this.id == 0){
    this.TAS.timeAttendenceCreate(dataArray).subscribe((res: any) => {
      this.router.navigate(['time-attendance-settings']);
    });
  } else {
    this.TAS.updateTimeAttendence(dataArray,this.id).subscribe((res: any) => {
      this.router.navigate(['time-attendance-settings']);
    },
    (error) => {

    });
  }

}

editForm(){
  this.loader = true;
  this.TAS.timeAttendenceDetail(this.id).subscribe((res: any) => {
    this.iseditForm=true;
    this.setEditForm(res);
    this.title ="Edit"
  },(error)=>{
    this.router.navigate(['time-attendance-settings']);
  });
}

setEditForm(res:any){
  if(res.is_active == false){
    this.configForm.disable();
    this.disabled = true;
  }
  let rolearray = []
  for(let key of res.timeattendancesettings_role){
    rolearray.push(key.whom_notify_details.id)
  }
  this.editCompId = res.company;
  let compArray:any = [];
    compArray.push(String(res.company));
    this.compArray.push(new FormControl(String(res.company)));
    Object.entries(res).forEach(([key, value]) => {
      if(key=='company'){
        this.configForm.get(key)?.setValue(compArray)
      }
     })
     this.configForm.reset(
      {
        edit_ot_hours : res.edit_ot_hours,
        showup_notify_home : res.showup_notify_home,
        prior_days : res.prior_days,
        timeattendancesettings_role : rolearray,
        initiate_consolidate_checkinrequest: res.initiate_consolidate_checkinrequest
      })

    this.getCurrentCompany(res.company);
    this.buildSelectedData();

}

getCurrentCompany(id:any){
  this.companyService.companyGet(id).subscribe((res:any)=>{
    this.companyList = [res.body];

  })
}

buildSelectedData(){
 this.compArr = [];
  for(let selectedId of this.configForm.value.company){
    var selectedComp =  this.companyList.filter(function(compId:any) {
      return compId.id == selectedId;
    });
    this.compArr.push(selectedComp[0]);
  }
  this.selectedComp = this.compArr;
  setTimeout(() => {
    this.loader = false;
  }, 1000);
}





}
