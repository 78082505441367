import { Component, OnInit,ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { DocumentManagementService } from 'src/app/@core/services/document-management.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { FormBuilder} from '@angular/forms';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import * as moment from 'moment';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-manage-documents',
  templateUrl: './manage-documents.component.html',
  styleUrls: ['./manage-documents.component.scss']
})
export class ManageDocumentsComponent implements OnInit {

  viewDetail                  = false;
  detailsView                 = false;
  versionDetail               = false;
  uploadVersion               = false;
  viewEmp                     = false;
  activeTab                   = 1;
  activeTab2                  = 1;
  permissions:any             = [];
  searchString:any            = "";
  ackSearchString:any         = "";
  page                        = 1;
  pageSize                    = 20;
  noDataFound                 = false;
  loader                      = true;
  policyDetails:any           = [];
  singlePolicyData:any        = [];
  ackDetails:any              = [];
  viewLoader                  = false;
  fileType: string            = "";
  docURL: any                 = "";
  docName: any                = "";
  fileSize: any               = "";
  docLoader                   = false;
  fileError                   = false;
  fileErrorMsg: any           = "";
  isupdate                    = false;
  modalToggle                 = false;
  currentDocName              = "";
  currentExtension:any        = "";
  isdelete                    = false;
  deleteToggle                = false;
  deleteClicked               = false;
  versionLoader:any           = false;
  versionHistory:any          = [];
  ackList:any                 = [];
  ackPendingList:any          = [];
  ackCompleteList:any         = [];
  allAckList:any              = [];
  ackLoader:any               = false;
  exportData:any              = [];
  searchKeyword               = ""
  searchlistdata:any          = [] ;
  filter                      = false;
  categoryData:any            = [];
  createdByData:any           = [];
  policyCompleteData:any      = [];
  searchCompleteList:any      = [];
  usedFileSize:any            = 0;
  deleteMsg                   = "";
  minDate                     = new Date(2020, 0, 1);
  policyPlaceholder:any       = this.messageService.searchdisplay('policy');
  filterForm                  = this.formBuilder.group({
                                category               : '',
                                acknowledgement        : '',
                                createdon              : '',
                                createdby              : '',
                                status                 : '',

  });
  ackData = [
    {id   : true,name  : 'Yes'},
    {id   : false,name  : 'No'},
  ];
  statusData = [
    {id   : true,name  : 'Active'},
    {id   : false,name  : 'Inactive'},
  ];
  deleteId:any;
  from:any;
  deleteTxt:any;
  assignRight:any;
  activeId:any;
  consumedFileData:any;
  // dataFilter:any = '';
  dataFilter:any              =  this.docService.FILTERDATA
  viewUrl:any;
  @ViewChild(SearchbarComponent) child:any;
  sLoading=false;

  constructor(

    public appService: AppService,
    public messageService : MessageService,
    public docService:DocumentManagementService,
    private formBuilder: FormBuilder,
    private employeeService : EmployeeService,



  ) { }

  ngOnInit(): void {


  this.getPermission();
  this.getConsumedSize();
  // this.getAllDocument();
  this.getCategoryList();
  this.managerList();
  this.selectAllForDropdownItems(this.ackData);
  this.selectAllForDropdownItems(this.statusData);

   let arrFilter: any = [];
   let arrList        = [];
   let arrData        = [];
   if(this.dataFilter){
     arrData = this.dataFilter.split("&")
     arrData.forEach((element: any) => {
       arrList = element.split('=');
       arrFilter[arrList[0]] =arrList[1];
     })

     this.filterForm.reset(this.memFilterArray2(arrFilter));
    }

    this.getAllDocument();
  }


  memFilterArray2(arrFilter:any){
    return {
      category         : arrFilter['category']!='' ? JSON.parse(arrFilter['category']) : '',
      acknowledgement  : arrFilter['is_acknowledge']!='' ? JSON.parse(arrFilter['is_acknowledge']) : '',
      status           : arrFilter['is_active']!='' ? JSON.parse(arrFilter['is_active']) : '',
      createdby        : arrFilter['created_by']!='' ? JSON.parse(arrFilter['created_by']) : '',
      createdon        : arrFilter['created_on']!='' ? arrFilter['created_on'] : '',

    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getConsumedSize(){
    this.docService.getConsumedFileSize().subscribe((res:any)=>{

      this.usedFileSize     = 0;
      let tempSize          = 0;
      this.consumedFileData = res[0]

      if(this.consumedFileData?.used_unit=='GB'){

        tempSize          = (this.consumedFileData?.used_size/5)*100.
        this.usedFileSize = (tempSize).toFixed(2);
      }
      else if(this.consumedFileData?.used_unit=='MB'){

        tempSize          = (this.consumedFileData?.used_size/5000)*100
        this.usedFileSize = (tempSize).toFixed(2);

      }
      else{

        tempSize          = (this.consumedFileData?.used_size/5000000)*100
        this.usedFileSize = (tempSize).toFixed(2);

      }

    })
  }
  getAllDocument(){

    // if(this.dataFilter == '' || this.dataFilter == undefined){
      this.loader = true;
      this.docService.getManageDocument(this.searchKeyword).subscribe((res:any)=>{
        this.policyCompleteData  = res.slice();
        this.policyDetails       = res;
        this.loader              = false;
        this.sLoading=false;
        this.applyFilter();

      })
      // this.docService.getPolicyDropdown(this.dataFilter).subscribe((res:any)=>{
      //   this.searchlistdata = res;
      //   for(let i=0;i<res.length;i++){
      //     this.searchlistdata[i]['name'] = this.searchlistdata[i]['subject']
      //   }
      //   this.searchCompleteList = this.searchlistdata.slice();
      // })
    // }
    // else
    // this.applyFilter();
  }
  viewPolicyDetails(id:any){
    this.viewDetail = true;
    this.viewLoader = true;
    this.activeId   = id;
    this.docService.getSinglePolicy(id).subscribe((res:any)=>{
      this.singlePolicyData = res;

      this.assignRight      = res.doc_policy_assignright[0]
      for(let i=0;i<this.assignRight.employee.length;i++){
        this.assignRight.employee[i]['name'] =  this.assignRight.employee[i]['first_name']+" ";
        this.assignRight.employee[i]['name'] +=  this.assignRight.employee[i]['middle_name']!=null?this.assignRight.employee[i]['middle_name']+" ":""
        this.assignRight.employee[i]['name'] +=  this.assignRight.employee[i]['last_name']+" ("+this.assignRight.employee[i]['employee_code']+")"
      }

      this.viewLoader       = false;
    })

  }
  getURL(event: any) {
    this.docURL           = event;
    this.fileError        = false;
    this.docLoader        = false;
    this.currentDocName   = this.appService.getDocName(event);

  }

  getfileformat(event: any) {

    this.docLoader = false;
    if (event == false) {
      this.fileError = true;
      this.fileErrorMsg = this.messageService.validateFileFormat('pdf,doc');
    }

  }
  getFilename(event: any) {
    this.docName = event.name;
    this.fileSize = this.appService.formatSizeUnits(event.size);
    window.sessionStorage.setItem('this.docName', event.name);
  }

  getloader(event: any) {

    this.docLoader = event;
  }
  getvalidsize(event: any) {

    this.docLoader    = false;
    this.fileError    = true;
    this.fileErrorMsg = event;
  }
  updateFile(){
    this.isupdate = true;

  }
  viewDocument(docType:any){

    this.modalToggle      = true;
    this.currentDocName   = this.appService.getDocName(docType);
    this.currentExtension = this.currentDocName?.split('.').pop();
    this.viewUrl          = docType

  }
  close(bool:any){
    this.modalToggle = bool;
  }
  deleteFile(){
    this.docURL = '';
  }
  saveVersion(){
    this.docService.createVersion({ "doc_file":this.docURL,"document_repo_policy":this.activeId}).subscribe((res:any)=>{
      this.uploadVersion  = false
      this.viewPolicyDetails(this.activeId);
      this.getConsumedSize();
    })
  }
  getVersions(){
    this.versionLoader  = true;
    this.versionDetail  = true;
    this.docService.getPolicyVersion(this.activeId).subscribe((res:any)=>{

      this.versionHistory = res;
      for(let i=0;i<this.versionHistory.length;i++){
        this.versionHistory[i]['doc_file_name'] = this.appService.getDocName(this.versionHistory[i]['doc_file'])
      }
      this.versionLoader  = false;

    })
  }
  deletConfirm(from:any,id:any){
    this.from         = from
    this.deleteMsg    = this.from=='Document policy'?this.messageService.Deletemsgdisplay(from):'This will delete the file permanently. You cannot restore it again.'
    this.deleteId     = id;
    this.deleteToggle = true;

  }
  deletePolicy(){
    this.deleteClicked = true;
    if(this.from=='Document policy'){

      this.docService.policyDelete(this.deleteId).subscribe((res:any)=>{
        this.deleteClicked = false;
        this.deleteToggle  = false;
        this.viewDetail    = false;
        this.getAllDocument();


      })
    }
    else{
      this.docService.versionDelete(this.deleteId).subscribe((res:any)=>{
        this.deleteClicked = false;
        this.deleteToggle  = false;
        this.getVersions();
      })
    }
  }
  getAckList(id:any){
    this.ackLoader        = true
    this.viewEmp          = true;
    this.ackSearchString  = ''
    let result:any        = [];
    this.activeTab2       = 1
    // if(this.ackList.length==0){
      this.docService.getAckDetails(id).subscribe((res:any)=>{
        this.allAckList           = res;
        this.ackCompleteList      = this.allAckList.acknowledgement_completed_employee_list_data;
        this.ackPendingList       = this.allAckList.acknowledgement_pending_employee_list_data;
        this.ackLoader            = false

      })
    // }


  }

  exportAckData(){
    this.exportData = [];
    this.ackList    = [];
    this.ackList    = this.activeTab2==1?this.ackCompleteList:this.ackPendingList;
    for(let i=0;i<this.ackList.length;i++){
      this.exportData.push({
        'Employee Code'     : this.ackList[i]['employee']['employee_code'],
        'Full name'         : this.ackList[i]['employee_full_name'],
        'Employment status' : this.ackList[i]['employee']['employment_status'],
        'Company'           : this.ackList[i]['employe_company']['company_name'],
        'Business Unit'     : this.ackList[i]['businessunit']['name'],
        'Department'        : this.ackList[i]['department']['name'],
        'Designation'       : this.ackList[i]['designation']['name'],
        'Branch'            : this.ackList[i]['branch']['name'],
        'Grade'             : this.ackList[i]['grade']['name'],
        'Category'          : this.ackList[i]['document_category'],
        'Priority'          : this.ackList[i]['priority'],
        'Policy Name'       : this.ackList[i]['Document_policy'],
        'Created by'        : this.ackList[i]['document_created_by'],
        'Created on'        : this.ackList[i]['document_created_on'],
        'Status'            : this.ackList[i]['status'],
        'Acknowledged by'   : this.ackList[i]['acknowledged_by'],
        'Acknowledged on'   : this.ackList[i]['acknowledgement_date'],


      })
    }
    let fileName = this.activeTab2==1?'Acknowledged List':'Not Acknowledged List'
    this.appService.exportExcel(this.exportData,fileName);


  }
  searchPolicy(searchKeyword:any){
    this.loader             = true;
    this.sLoading           = true;
    this.searchKeyword      = searchKeyword;
    if(searchKeyword != ""){

      this.policyDetails  = this.policyDetails.filter(function(element:any) {
        return element.subject.toLowerCase().includes(searchKeyword.toLowerCase());
      });
      this.loader           = false;
      this.sLoading           = false;
    }

  }
  searchresults(data:any){

    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      if(this.dataFilter==''){
          this.policyDetails  = this.policyCompleteData;
      }
      else{
           this.applyFilter()
      }

      this.loader         = false;

    }else{
        this.searchKeyword      = data;
        this.policyDetails = this.policyDetails.filter(function(element:any) {
          return element.id == data;
        });
        this.loader           = false;
    }
  }
  searchCategory(searchKeyword:any){
    this.loader             = true;
    this.policyDetails      = [];
    this.searchKeyword      = searchKeyword;
    if(searchKeyword != ""){
      this.getAllDocument();
    }
  }
  closefn(){
    this.filter = false;
    // this.filterForm.reset();
  }
  getCategoryList(){
    this.docService.categoryListData(true).subscribe((res: any) => {
      this.categoryData = res;
      this.selectAllForDropdownItems(this.categoryData);
    });
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  clearcommonForm(control:any,index:number){

    let selectedData = this.filterForm.get(control)?.value
    selectedData.splice(index, 1);
    this.filterForm.get(control)?.setValue(selectedData)

  }
  managerList(){
    this.employeeService.managerList().subscribe((res: any) => {

     for(let i=0;i<res.length;i++){
      res[i]['name'] = res[i]['first_name'];
      if(res[i]['first_name'] != null)
      res[i]['name'] = res[i]['first_name']+" ";
      if(res[i]['middle_name'] != null)
      res[i]['name'] += res[i]['middle_name']+" ";
      if(res[i]['last_name'] != null)
      res[i]['name']   += res[i]['last_name'];
      res[i]['name']   +=" ("+res[i]['employee_code']+")";

    }
    this.createdByData = res;

    this.selectAllForDropdownItems(this.createdByData);
    });
  }
  applyFilter(){
    if(this.searchKeyword!='')
    this.child.clearBar();
    this.page                        = 1;
    this.pageSize                    = 20;
    let createdOn:any = this.filterForm.value.createdon!='' && this.filterForm.value.createdon!=null?moment(this.filterForm.value.createdon).format('YYYY-MM-DD'):'';
    this.dataFilter = 'category=['+ this.filterForm.value.category+']&is_acknowledge=['+this.filterForm.value.acknowledgement+']&is_active=['+this.filterForm.value.status+']&created_by=['+this.filterForm.value.createdby+']&created_on='+createdOn;
    this.filter = false;

    this.docService.setFilterData(this.dataFilter)

    this.policyDetails =  this.policyCompleteData
    if(this.filterForm.value.category!=null && this.filterForm.value.category!='')
    this.policyDetails = this.policyDetails.filter((item:any )=> this.filterForm.value.category.includes(item.category));

    if(this.filterForm.value.acknowledgement!=null && this.filterForm.value.acknowledgement!='')
    this.policyDetails = this.policyDetails.filter((item:any )=> this.filterForm.value.acknowledgement.includes(item.is_acknowledge));

    if(this.filterForm.value.status!=null && this.filterForm.value.status!='')
    this.policyDetails = this.policyDetails.filter((item:any )=> this.filterForm.value.status.includes(item.status));

    if(createdOn!=null && createdOn!='')
    this.policyDetails = this.policyDetails.filter((item:any )=> createdOn==(item.created_on));


    if(this.filterForm.value.createdby!=null && this.filterForm.value.createdby!='')
    this.policyDetails = this.policyDetails.filter((item:any )=> this.filterForm.value.createdby.includes(item.created_by_id));
    // this.searchlistdata = this.searchCompleteList.filter((el:any) => {
    //   return this.policyDetails.some((f:any) => {
    //     return f.id === el.id
    //   });
    // });

  }
  resetFilter(){
    this.filterForm.reset('');
    this.dataFilter     = '';
    this.filter         = false;
    this.policyDetails  = this.policyCompleteData;
    // this.searchlistdata = this.searchCompleteList;
    this.child.clearBar();

  }
  clearUploadedData(){
    this.docURL           = '';
    this.fileError        = false;
    this.docLoader        = false;
    this.currentDocName   = '';
    this.uploadVersion    = true

  }

  goToLink(url: string){
    let exactURL = url?.startsWith('http') ? url : 'https://' +url
    window.open(exactURL, "_blank");
  }
  onNavChange(changeEvent: NgbNavChangeEvent){
    this.ackSearchString = '';
  }
}
