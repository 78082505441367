import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DataacessService } from 'src/app/@core/services/dataaccess.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
@Component({
  selector: 'app-data-access-setup',
  templateUrl: './data-access-setup.component.html',
  styleUrls: ['./data-access-setup.component.scss']
})
export class DataAccessSetupComponent implements OnInit {

  activeTab         = 1;
  employeeDropdown  = [];
  moduleDropdown    = [];
  is_active         = true;
  companylist       = [];
  departmentlist    = [];
  designationlist   = [];
  gradelist         = [];
  citylist          = [];
  branchlist        = [];
  bulist            = [];
  dataacceessid:any = 0;
  id: number        = 0;
  res:any;
  rightArray:any = [];
  currentrightArray:any = [];
  listForm:any;
  loader                = false;

  editflag : boolean = false


  Action          = '';
  companyData:any = [];
  buArray:any []  =  [];
  emp_data: any   = [];

  myForm: FormGroup = this.fb.group({
    id: 0,
    configure: this.fb.group({
      name:  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      module: ['', [Validators.required]],
      employee: ['', [Validators.required]]
    }),
    employeeassignright: this.fb.group({
      assignrights:this.fb.array([],[Validators.required])
    })
  });
  constructor(
    public fb:FormBuilder,
    private ds:DataacessService,
    public router: Router,
    public route:ActivatedRoute

    ) {}

  ngOnInit(): void {
    this.loader = true;
    this.route.params.subscribe((params: Params) => {
      this.Action = "Add";
      if( !isNaN(params['id'])){
        this.Action = "Edit";
        this.id = +params['id'];
        this.editflag = true
        this.dataacceessid = this.id;
        this.editForm();
      }
    })
    this.ds.getModuleList(true).subscribe((res: any) => { this.moduleDropdown = res.body;
        });
        this.ds.getEmpList(true).subscribe((res: any) => {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name']  = "";
            if(res.body[i]['first_name'] != null)
              res.body[i]['name'] += res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
              res.body[i]['name'] += res.body[i]['last_name'];
              res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";

          }
          this.employeeDropdown = res.body;
          this.loader = false;
       });
      this.getFormData(this.is_active);
  }
  submitForm(){
    this.res = this.employeeassignrightForm.controls.assignrights;
    var employeeRightsArray = [];
    for(let key of this.res.controls){
    var dataRightsArray = [];
      dataRightsArray.push({
        "company":(key.value.company!="")?key.value.company:[],
        "department":(key.value.department!="")?key.value.department:[],
        "city":(key.value.city!="")?key.value.city:[],
        "branch":(key.value.branch!="")?key.value.branch:[],
        "designation":(key.value.designation!="")?key.value.designation:[],
        "grade":(key.value.grade!="")?key.value.grade:[],
        "businessunit":(key.value.businessunit!="")?key.value.businessunit:[]
     });
     employeeRightsArray.push({'employee':key.value.employee,'data_access_right':dataRightsArray})
    }

    var dataArray = {
      "name": this.configureForm.controls.name.value,
      "module": this.configureForm.controls.module.value,
      "data_access_employee":employeeRightsArray,
   };
    if(this.id == 0){
      this.ds.saveAccessRight(dataArray).subscribe((res: any) => {
        if(res.status == 201) {
          this.router.navigate(['data-access']);
        }
    }, (error) => {

    });
    } else {
      this.ds.dataAccessUpdate(dataArray,this.id).subscribe((res: any) => {
        if(res.status == 200) {
          this.router.navigate(['data-access']);
        }
      }, (error) => {

      });
    }
  }

  editForm(){
    this.loader = true;
    this.ds.dataAccessGet(this.id).subscribe((res: any) => {
      this.setEditForm(res.body);
      return
    });
  }

  setEditForm(res:any){
    let employeearray:any = [];
    for(let key of res.data_access_employee){
      employeearray.push(
        key.employee.id
     );
    }

    this.emp_data = employeearray;

    for(let key of res.data_access_employee){
        key.data_access_right[0].employee = employeearray[res.data_access_employee.indexOf(key)];
        this.rightArray.push(key.data_access_right[0]);
        this.addAssignRights();

        this.currentrightArray.push({
          "company":(key.data_access_right[0].company!="")?key.data_access_right[0].company:[],
          "businessunit":(key.data_access_right[0].businessunit!="")?key.data_access_right[0].businessunit:[],
          "branch":(key.data_access_right[0].branch!="")?key.data_access_right[0].branch:[],
          "city":(key.data_access_right[0].city!="")?key.data_access_right[0].city:[],
          "department":(key.data_access_right[0].department!="")?key.data_access_right[0].department:[],
          "grade":(key.data_access_right[0].grade!="")?key.data_access_right[0].grade:[],
          "designation":(key.data_access_right[0].designation!="")?key.data_access_right[0].designation:[],
          "employee":(key.data_access_right[0].employee!="")?key.data_access_right[0].employee:[],
       });
    }
    this.myForm.reset(
      {
        id: res.id,
        configure: {
          name:  res.name,
          module: res.module,
          employee:employeearray
        },
        employeeassignright:{
          assignrights:this.currentrightArray
        }
    });

    this.buArrayCreation();
    this.buListCreation();
  }

  buArrayCreation(){
    for(let i=0; i<this.emp_data.length; i++){
      this.buArray[i]=Array();
    }
  }

  buListCreation(){
    const myForm = (<FormArray>this.employeeassignrightForm.get("assignrights"));
    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);

      if(this.listForm.controls.company.value.length > 0) {
        this.getBUList(this.listForm.controls.company.value,k);
      }
    }
  }

  assignright() : FormArray {
    return this.employeeassignrightForm.get("assignrights") as FormArray
  }
  addAssignRights(){
      this.assignright().push(this.newAssignRight());
  }

  newAssignRight(): FormGroup {
    return this.fb.group({
      employee:'',
      company: [''],
      department: [''],
      businessunit: [''],
      grade: [''],
      branch:[''],
      designation: [''],
      city: ['']
    })
  }
  submitOne(){
    this.activeTab = 2;
  }

  get configureForm() {
    return this.myForm.get('configure') as FormGroup;
  }

  get employeeassignrightForm() {
    return this.myForm.get('employeeassignright') as FormGroup;
  }

  getFormData(status:any){
    this.ds.getcompanyList(status)
    .subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name'] = res.body[i]['company_name'];
          }
          this.companylist = res.body;
        }
      }
    })
    // this.ds.getbuList(status,this.companyData)
    // .subscribe((res: any) => {
    //   if(res.status == 200) {
    //     if (res.body.length > 0) {
    //       this.bulist = res.body;
    //     }
    //   }
    // })
    this.ds.getdepartmentList(status)
    .subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          this.departmentlist = res.body;
        }
      }
    })
    this.ds.getdesignationList(status)
    .subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          this.designationlist = res.body;
        }
      }
    })
    this.ds.getbranchList(status)
    .subscribe((res: any) => {
    if(res.status == 200) {
      if (res.body.length > 0) {
        this.branchlist = res.body;
      }
    }
    })
    this.ds.getcityList(status)
    .subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          this.citylist = res.body;
        }
      }
    })
    this.ds.getgradeList(status)
    .subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          this.gradelist = res.body;
        }
      }
    })
}
  companyChange(data: any){
    this.getBUList(data.compData,data.index);
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].configure.invalid) {
        changeEvent.preventDefault();
      }
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  countAssign(){
    var retResult = 0;
    const myForm = (<FormArray>this.employeeassignrightForm.get("assignrights"));

    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);
      if(this.listForm.value.company != '' || this.listForm.value.businessunit !="" || this.listForm.value.department != "" || this.listForm.value.designation != "" || this.listForm.value.grade != "" ||
       this.listForm.value.city !="" || this.listForm.value.branch !="") {
        retResult++;
      }
    }
    return retResult;
  }

  getBUList(data:any,index:any){
    if(data != undefined && data != null && data != ''){
      this.ds.getbuList(true,data).subscribe((res: any) => {
        if(res.status == 200 && res.body.length > 0) {
          if(index == 'commonForm'){
            this.bulist=(res.body);
            this.selectAllForDropdownItems(this.bulist);
          }
          else{
            this.buArray[index] = (res.body);
            this.selectAllForDropdownItems(this.buArray[index]);
          }
        }else{
          if(index == 'commonForm')
            this.bulist = [];
          else
            this.buArray[index] = [];
        }
      });
    }
  }
}
