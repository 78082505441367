import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomColor'
})

export class RandomColorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let i = value%5;
    let colors:any[] = ['bg0', 'bg1', 'bg2', 'bg3', 'bg4']
    var res = colors[i];
    return res;
  }
}
