<form class="" [formGroup]="formGp">
  <div class="container-fluid p-0 form-container">
    <div class="d-flex">
      <div class="config-summernote" style="width: calc(100% - 300px);">
        <div #summernoteEditor [ngxSummernote]="config" formControlName="template"></div>
      </div>
      <div class="config-toolbar" style="width:300px">
        <div class="container p-16">
          <div class="row row-16">
            <div class="col-12">
              <div class="fs-12 fw-500 text-uppercase">TAGS</div>
            </div>
            <div class="col-12">
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('tags')}}"
              [(ngModel)]="searchString" [ngModelOptions]="{standalone : true}" [items]="tagsArr" [multiple]="true"
              groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
              bindLabel="name" [closeOnSelect]="false" bindValue="value" class="multiselect">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                            [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                    </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="searchString?.length ">
                        <span class="ng-value-label">{{searchString?.length }}
                            tags
                            selected.</span>
                    </div>
                </ng-template>
              </ng-select>
              <ul class="selected-values gap-8 mt-2" *ngIf="searchString.length!=0">
                <li class="ng-value m-0 " *ngFor="let tag of searchString; let ind = index">
                  <span class="d-flex align-items-center p-0 " id="dragElement" draggable="true" (dragstart)="onDragStart($event,tag)" ngbPopover="{{removeUnderscoreAddUpperCase(tag)}}"
                  popoverClass="popover-default popover-info" container="body"
                  triggers="hover">{{this.removeUnderscoreAddUpperCase(tag)}}</span>
                  <span class="ng-value-icon right m-0 " (click)="applyTagClose(tag)">x</span>
                </li>
              </ul>
            </div>
            <div class="col-12" *ngIf="showAlert">
              <div role="alert" class="alert alert-warning mb-0 pe-32 show">
                <p class="mb-0 fs-14 hstack "> Drag and drop the tags to embed</p>
                <div class="fs-9 btn-close pointer" (click)="showAlert = false"></div>
              </div>
            </div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion" #acc="ngbAccordion" activeIds="ngb-panel-k">
                <ngb-panel id="ngb-panel-k" *ngIf="recentlyUsed.length > 0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button fs-12 fw-500 text-uppercase py-14">Recently
                      used</button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-wrap gap-8">
                      <div *ngFor="let recent of recentlyUsed" class="badge draggable badge-c5 w-17ch d-inline-block lh-lg" id="dragElement" draggable="true" (dragstart)="onDragStart($event,recent)" ngbPopover="{{removeUnderscoreAddUpperCase(recent)}}"
                      popoverClass="popover-default popover-info" container="body"
                      triggers="hover">{{removeUnderscoreAddUpperCase(recent)}}</div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ng-container *ngFor="let panel of getObjectKeys(tagList); let i=index">
                  <ngb-panel id="ngb-panel-{{i}}">
                    <ng-template ngbPanelHeader>
                      <button ngbPanelToggle class="accordion-button fs-12 fw-500 text-uppercase py-14">{{removeUnderscoreAddUpperCase(panel)}}</button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="d-flex flex-wrap gap-8">
                        <div *ngFor="let tag of tagList[panel]" class="badge draggable badge-c5 w-17ch d-inline-block lh-lg" id="dragElement" draggable="true" (dragstart)="onDragStart($event,tag)" ngbPopover="{{removeUnderscoreAddUpperCase(tag)}}"
                        popoverClass="popover-default popover-info" container="body"
                        triggers="hover">{{removeUnderscoreAddUpperCase(tag)}}</div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ng-container>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid"
    (click)="validateForm()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
  </footer>
</form>

<!-- Image Modal -->
<div class="modal  modal-alert {{imgModal == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Insert Image</h4>
      </div>
      <div class="modal-body py-0">
        <div [ngClass]="{ 'is-invalid': isFormat || isSize}">
          <div class="col-12">
            <label for="note-dialog-image-file" class="form-label">Select from files</label>
            <input id="note-dialog-image-file" class="note-image-input form-control-file note-form-control note-input" type="file" name="files" accept="image/*" [(ngModel)]="imgFile" (change)="validateImg($event,'file')" autocomplete="off">
          </div>
          <div class="col-12">
            <label for="note-dialog-image-url" class="form-label">Image URL</label>
            <input id="note-dialog-image-url" class="note-image-url form-control note-form-control note-input" type="text" [(ngModel)]="imgUrl" (ngModelChange)="validateImg($event,'url')" autocomplete="off">
          </div>
        </div>
        <div *ngIf="isFormat || isSize" class="invalid-feedback">
          <div *ngIf="isFormat">{{this.messageService.validateFileFormat('jpeg,png,jpg')}}</div>
          <div *ngIf="isSize">File size should be less than 5MB.</div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="imgModal = false;imgFile='';imgUrl='';isSize=false;isFormat=false" type="button"
          class="btn text-uppercase btn-outline-primary btn-sm">Cancel</button>
        <button type="button" class="btn text-uppercase btn-primary btn-sm" [disabled]="isFormat" (click)="imgSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-- Hint Modal -->
<div class="modal  modal-alert {{hintModal == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-lg">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">Help</h4>
        <button class="btn-reset ms-auto text-light-600" (click)="hintModal = false">
        <i class="icon-close-lg fs-16"></i></button>
      </div>
      <div class="modal-body pt-0">
        <div class="table-responsive">
          <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">Enter</span></td>
                  <td class="pe-0"><span>Insert Paragraph</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+Z</span></td>
                  <td class="pe-0"><span>Undo the last command</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+Y</span></td>
                  <td class="pe-0"><span>Redo the last command</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">TAB</span></td>
                  <td class="pe-0"><span>Tab</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+B</span></td>
                  <td class="pe-0"><span>Set a bold style</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+I</span></td>
                  <td class="pe-0"><span>Set a italic style</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+U</span></td>
                  <td class="pe-0"><span>Set a underline style</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+SHIFT+S</span></td>
                  <td class="pe-0"><span>Set a strikethrough style</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+BACKSLASH</span></td>
                  <td class="pe-0"><span>Clean a style</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+SHIFT+L</span></td>
                  <td class="pe-0"><span>Set left align</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+SHIFT+E</span></td>
                  <td class="pe-0"><span>Set center align</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+SHIFT+R</span></td>
                  <td class="pe-0"><span>Set right align</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+SHIFT+J</span></td>
                  <td class="pe-0"><span>Set full align</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+SHIFT+NUM7</span></td>
                  <td class="pe-0"><span>Toggle unordered list</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+SHIFT+NUM8</span></td>
                  <td class="pe-0"><span>Toggle ordered list</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+LEFTSQUAREBRACKET</span></td>
                  <td class="pe-0"><span>Outdent on current paragraph</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+RIGHTSQUAREBRACKET</span></td>
                  <td class="pe-0"><span>Indent on current paragraph</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+NUM0</span></td>
                  <td class="pe-0"><span>Change current block's format as a paragraph(P tag)</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+NUM1</span></td>
                  <td class="pe-0"><span>Change current block's format as H1</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+NUM2</span></td>
                  <td class="pe-0"><span>Change current block's format as H2</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+NUM3</span></td>
                  <td class="pe-0"><span>Change current block's format as H3</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+NUM4</span></td>
                  <td class="pe-0"><span>Change current block's format as H4</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+NUM5</span></td>
                  <td class="pe-0"><span>Change current block's format as H5</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+NUM6</span></td>
                  <td class="pe-0"><span>Change current block's format as H6</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+ENTER</span></td>
                  <td class="pe-0"><span>Insert horizontal rule</span></td>
                </tr>
                <tr>
                  <td class="ps-0"><span class="fw-500 fs-14 text-white bg-dark p-1 text-uppercase">CTRL+K</span></td>
                  <td class="pe-0"><span>Show link dialog</span></td>
                </tr>
              </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Link Modal -->
<div class="modal  modal-alert {{linkModal == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">Insert Link</h4>
        <button class="btn-reset ms-auto text-light-600" (click)="linkModal = false;isFormat=false;linkUrl='';linkText='';openInNewWindow=true;useDefaultProtocol=true;isTextRequired=false;isURLRequired=false">
          <i class="icon-close-lg fs-16"></i></button>
      </div>
      <div class="modal-body py-0">
        <div class="row row-16">
          <!-- <form > -->
            <div class="col-12" [ngClass]="{'is-invalid': isTextRequired}">
              <label class="form-label" for="linkUrl">Text to display</label>
              <input type="text" class="form-control" id="linkText" [ngClass]="{'is-invalid': isTextRequired}" [(ngModel)]="linkText" (ngModelChange)="textValid()" name="linkText" placeholder="{{this.messageService.placeholderdisp('link text')}}" autocomplete="off">
            </div>
            <div *ngIf="isTextRequired" class="invalid-feedback">
              <div *ngIf="isTextRequired">{{this.messageService.validationDisplay('required')}}
              </div>
            </div>
            <div class="col-12" [ngClass]="{'is-invalid': isURLRequired || isFormat}">
              <label class="form-label" for="linkText">To what URL should this link go?</label>
              <input type="url" class="form-control" id="linkUrl" [ngClass]="{'is-invalid': isURLRequired ||isFormat}" [(ngModel)]="linkUrl" name="linkUrl" (ngModelChange)="urlValid()" placeholder="{{this.messageService.placeholderdisp('url')}}" autocomplete="off">
            </div>
            <div *ngIf="isURLRequired || isFormat" class="invalid-feedback">
              <div *ngIf="isURLRequired">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="isFormat && !isURLRequired">Enter a valid URL.</div>
            </div>
            <div class="col-12">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="openInNewWindow" [(ngModel)]="openInNewWindow" name="openInNewWindow">
                <label class="form-check-label" for="openInNewWindow">Open in new window</label>
              </div>
            </div>
            <div class="col-12 mt-8">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="useDefaultProtocol" [(ngModel)]="useDefaultProtocol"
                  name="useDefaultProtocol">
                <label class="form-check-label" for="useDefaultProtocol">Use default protocol</label>
              </div>
            </div>
          <!-- </form> -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn text-uppercase btn-primary btn-sm" [disabled]="isFormat || isURLRequired || isTextRequired" (click)="linkSubmit()">Insert Link</button>
      </div>
    </div>
  </div>
</div>
