import { Component, OnInit,Input,EventEmitter,Output } from '@angular/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { ExpenseAdvnaceProfileService } from 'src/app/@core/services/expense-advance-profile.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-advance-outstanding-detail-view',
  templateUrl: './advance-outstanding-detail-view.component.html',
  styleUrls: ['./advance-outstanding-detail-view.component.scss']
})
export class AdvanceOutstandingDetailViewComponent implements OnInit {

  @Input() viewdetailsout=false;
  @Output() viewpanelclose = new EventEmitter();
  @Input() currency:any;
  @Input() viewdata:any=[];
  @Input() from:any;
  @Input() viewLoader:any=false;

  loaderview=false;
  // viewdata:any=[];
  userId:any;
  page = 1;
  pageSize: any = 20;

  constructor(
    private ExpenseAdvnaceProfileService: ExpenseAdvnaceProfileService,
    private authService:AuthService,
    public appService: AppService
  ) { }

  ngOnInit(): void {
    if(this.from=='advancehr'){
      
    }else{
      this.userId = this.authService?.isEmployeeId;
      this.loaderview=true;
      this.ExpenseAdvnaceProfileService.getoutstandingdata(this.userId).subscribe((res: any) => {
        this.viewdata=res.data;      
        this.loaderview=false;     
     });
    }    
  }

  closefn() {
    this.viewdetailsout = false;
    this.viewpanelclose.emit(this.viewdetailsout);
  }
}
