<div class="col-12">
  <div class="card card-c2 p-0">
    <div class="table-responsive" style="max-height: calc(100vh - 9rem);">
      <table class="table table-striped table-sm sticky-header  table-minheight">
        <thead>
          <tr>
            <th class="fw-600" scope="col">DATE</th>
            <th class="fw-600" scope="col">TYPE</th>
            <th class="fw-600" scope="col">FROM DATE</th>
            <th class="fw-600" scope="col">TO DATE</th>
            <th class="fw-600" scope="col">ADDED/used</th>
            <th class="fw-600" scope="col">BALANCE</th>
            <th class="fw-600" scope="col">REMARKS</th>
            <th class="fw-600" scope="col" *ngIf="isLeave">VIEW DETAILS</th>
          </tr>
        </thead>
        <tbody class="cell-16">
          <ng-container *ngIf="this.appservice.filteredcount(leaveBalanceTable,searchString)==0">
            <tr>
              <td [attr.colspan]="colspan" class="bg-white">
                <div class="card card-c2 flex-center p-16 bg-dark-500">
                  <span class="fw-500 fs-14">No data found</span>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr
            *ngFor="let task of $any(leaveBalanceTable | filter : searchString | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index">
            <td class="text-nowrap" ngbPopover="{{this.appservice.dateFormatDisplay(task?.transaction_date)}}"
              popoverClass="popover-input popover-info" container="body" triggers="hover">
              {{this.appservice.dateFormatDisplay(task?.transaction_date)}}</td>
            <td ngbPopover="{{task?.type}}" popoverClass="popover-input popover-info" container="body" triggers="hover">
              <div class="text-trim-2 w-30ch">{{task?.type}}</div>
            </td>
            <td class="text-nowrap"
              ngbPopover="{{(task?.from_date == '' || task?.from_date == null) ? '--' : this.appservice.dateFormatDisplay(task?.from_date)}}"
              popoverClass="popover-input popover-info" container="body" triggers="hover">{{(task?.from_date == '' ||
              task?.from_date == null) ? '--' : this.appservice.dateFormatDisplay(task?.from_date)}}</td>
            <td class="text-nowrap"
              ngbPopover="{{(task?.to_date == '' || task?.to_date == null) ? '--' : this.appservice.dateFormatDisplay(task?.to_date)}}"
              popoverClass="popover-input popover-info" container="body" triggers="hover">{{(task?.to_date == '' ||
              task?.to_date == null) ? '--' : this.appservice.dateFormatDisplay(task?.to_date)}}</td>
            <td class="text-nowrap" ngbPopover="{{task?.leave_count}}" popoverClass="popover-input popover-info"
              container="body" triggers="hover"><span
                class="{{(task?.transaction_mode === true) ? 'text-success' : 'text-danger' }}">{{task?.leave_count}}</span>
            </td>
            <td class="text-nowrap" ngbPopover="{{task?.balance}}" popoverClass="popover-input popover-info"
              container="body" triggers="hover">{{task?.balance}}</td>
            <td ngbPopover="{{(task?.remark == '' || task?.remark == null ? '--' : dateFormatInString(task?.remark) )}}"
              popoverClass="popover-input popover-info" container="body" triggers="hover">
              <div class="text-trim-2 w-30ch" style="min-width:10rem"> {{(task?.remark == '' || task?.remark == null ? '--' :
                dateFormatInString(task?.remark))}}</div>
            </td>
            <td *ngIf="isLeave"><span class="{{task?.view_detail !== '--' ? 'link-primary' : ''}} hstack align-item-center"
                (click)="task?.view_detail !== '--' && viewfn(task?.leave_request)"><i class="icon-eye me-1 lh-1 fs-12"
                  *ngIf="task?.view_detail !== '--'"></i>{{task?.view_detail}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="col-12 mt-3" *ngIf="this.appservice.filteredcount(leaveBalanceTable,searchString)>pageSize">
  <ngb-pagination class="d-flex justify-content-end"
    [collectionSize]="this.appservice.filteredcount(leaveBalanceTable,searchString)" [(page)]="page"
    [pageSize]="pageSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
  </ngb-pagination>
</div>
