import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,FormControl,FormArray } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdvancePolicyService } from 'src/app/@core/services/advancepolicy.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
import { AmountValidator } from 'src/app/@shared/validators/amount.validators';

@Component({
  selector: 'app-advance-policy-setup',
  templateUrl: './advance-policy-setup.component.html',
  styleUrls: ['./advance-policy-setup.component.scss']
})
export class AdvancePolicySetupComponent implements OnInit {
  activeTab         =1;
  disabled          = false;
  res               :any;
  title             ='Add';
  id: number        = 0;
  iseditForm        =false;
  editflag: boolean = false;
  loader            = false;
  loadercatg:boolean= false;
  categorylist      : any=[];
  applicablearredit : any=[];
  tabtouched        = false;
  myForm: FormGroup = this.fb.group({
    id: 0,
    setting: this.fb.group({
      name                 :  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      specify_limit        :  [false, [Validators.required]],
      amount               :  [null,[AmountValidator(),Validators.maxLength(6),Validators.min(1)]],
      is_credit_limit      :  [false, [Validators.required]],
      credit_limit         :  [null,[AmountValidator(),Validators.maxLength(6),Validators.min(1)]]
    }),    
    applicable: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })   
  });
  constructor(private fb:FormBuilder,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public AdvancePolicyService:AdvancePolicyService,
    public notificationservice:NotificationService,
    private ams:ApprovalMatrixService
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }  

  submit(tab:any){
    this.activeTab = tab;
    if(tab==3){
      this.tabtouched=true;
    }
  }
  get settingForm() {
    return this.myForm.get('setting') as FormGroup;
  }
  
  get applicableForm() {
    return this.myForm.get('applicable') as FormGroup;
  }
  
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].setting.invalid) {
        changeEvent.preventDefault();
      }
    }else if(changeEvent.nextId === 3){
      if(this.myForm['controls'].setting.invalid || this.myForm['controls'].applicable.invalid ) {
        changeEvent.preventDefault();
        }else{
          this.tabtouched=true;
        }
    }   
  }

  tabclick(tab:any){
    if(tab==2){
      if(this.myForm['controls'].setting.valid){
        this.activeTab=2;
      }
    }
    else if(tab==3){
      if(this.myForm['controls'].setting.valid && this.myForm['controls'].applicable.valid){
        this.activeTab=3;
        this.tabtouched=true;
      }else{
        this.tabtouched=false;
      }
    }
    
  }

  editForm(){
    this.loader = true;
    this.editflag = true;
    this.tabtouched=true;
    this.title ='Edit';
    this.AdvancePolicyService.getSinglepolicyData(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res);
    },(error)=>{
      this.router.navigate(['advance-policy']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }

    let assignees = [];
    let appkey ="";
    appkey = res.applicability?.applicable;
     
   for(let i=0;i<res.applicability[appkey]?.length;i++){
    assignees.push(res.applicability[appkey][i].id);
   }  
 

    this.myForm.reset(
      {
        id: res.id,
        setting: {
          name:  res.name,
          amount: res.amount,
          specify_limit: res.specify_limit,
          is_credit_limit: res.is_credit_limit,
          credit_limit: res.credit_limit,
        },
        applicable: {
          applicableto: appkey,
          assignees: assignees
        }
    });
    this.applicablearredit = res.applicability;
    this.loader = false;
  }

  submitForm(){
    this.res = this.applicableForm.controls.assignees.value;
    var applicable = this.applicableForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU"){
      applicableStr = "businessunit";
    }
    var arr:any = {};
    arr['applicable']= applicable;
    arr[applicableStr?.toLowerCase()]= [];
    for(let key of this.res){
      arr[applicableStr?.toLowerCase()].push(key);
    }
    
    var dataArray = {
      "name": this.settingForm.controls.name.value,
      "amount": this.settingForm.controls.amount.value,
      "specify_limit": this.settingForm.controls.specify_limit.value,
      "is_credit_limit": this.settingForm.controls.is_credit_limit.value,
      "credit_limit": this.settingForm.controls.credit_limit.value,
      "applicability": arr      
    };

    if(this.id == 0){
      this.AdvancePolicyService.createpolicy(dataArray).subscribe((res: any) => {
        this.router.navigate(['advance-policy']);
      },
      (error) => {
      });
    } else {
      this.AdvancePolicyService.updatepolicy(this.id,dataArray).subscribe((res: any) => {
        this.router.navigate(['advance-policy']);
      },
      (error) => {
      });
    }
  }
  applicablelist(event:any){
    let list:any=event;   
    let tempar:any=[]
    this.applicablearredit=[];
    this.applicablearredit['applicable']= this.applicableForm.controls.applicableto.value;
      for(let i=0;i<this.applicableForm.controls.assignees.value.length;i++){
        for(let k=0;k<list.length;k++){
          if(this.applicableForm.controls.assignees.value[i] == list[k].id){
            if(this.applicableForm.controls.applicableto.value == "Employee"){
                    list[k].name = list[k].first_name+" ";
                    if(list[k].middle_name !=null && list[k].middle_name!='')
                    list[k].name +=list[k].middle_name+" ";
                    if(list[k].last_name !=null)
                    list[k].name +=list[k].last_name;
                    list[k].name +=" ("+list[k].employee_code+")";
            } else if(this.applicableForm.controls.applicableto.value == "Company"){
                    list[k].name = list[k].company_name;
            }
            tempar.push(list[k]);
          }
        }
      }
      this.applicablearredit[this.applicableForm.controls.applicableto.value]= tempar;    
  }

}

