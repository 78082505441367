<form [formGroup]="ouFilterForm">
  <div class="row row-16 mb-16" *ngIf="type === 'leave'">
    <div class="col-12 form-row">
      <label for="" class="form-label {{isTypeRequired && 'required'}}">Leave Type </label>
      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('leave type')}}" [ngClass]="{ 'is-invalid': (submitted || f.leave_type.touched || f.leave_type.dirty) &&  f.leave_type.errors}"
          formControlName="leave_type" [items]="leaveType" [multiple]="true" groupBy="selectedAllGroup"
          [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="leave_name"
          [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="lvTypeLoader">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!lvTypeLoader">
              <div class="ng-value" *ngIf="ouFilterForm.value.leave_type?.length ">
                  <span class="ng-value-label">{{ouFilterForm.value.leave_type?.length }} leave type
                      selected</span>
              </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="d-inline-flex align-items-center" >
                  <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                      [ngModelOptions]="{ standalone : true }" />

                  <span class="">{{ (item.leave_name?.length>15)? (item.leave_name |
                      slice:0:15)+'..':(item.leave_name) }}</span>
              </div>
          </ng-template>
          <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="lvTypeLoader">
            <div class="loading-text">Loading...</div>
          </ng-template>
      </ng-select>
      <div *ngIf="(submitted || f.leave_type.touched || f.leave_type.dirty) && f.leave_type.errors"
          class="invalid-feedback">
          <div *ngIf="f.leave_type.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
      </div>
      <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.leave_type?.length != 0 && !lvTypeLoader">
          <li class="ng-value" *ngFor="let item of ouFilterForm.value.leave_type,let ki =index">
              <ng-container *ngFor="let insideitem of leaveType, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                      <div class="d-inline-flex align-items-center">
                          <span class=""> {{ (insideitem['leave_name'].length>25)?
                              (insideitem['leave_name'] |
                              slice:0:25)+'..':(insideitem['leave_name']) }}</span>
                      </div>
                  </span>
              </ng-container>
              <span  class="ng-value-icon right"
                  (click)="clearcommonForm('leaveType',ki)" aria-hidden="true">×</span>
          </li>
      </ul>
    </div>

    <div class="col-12 form-row">
        <label for="" class="form-label {{isPolicyRequired && 'required'}}">Leave Policy </label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('leave policy')}}" [ngClass]="{ 'is-invalid': (submitted || f.leave_policy.touched || f.leave_policy.dirty) &&  f.leave_policy.errors}"
            formControlName="leave_policy" [items]="leavePolicy" [multiple]="true"
            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="lvPolicyLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!lvPolicyLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.leave_policy?.length ">
                    <span class="ng-value-label">{{ouFilterForm.value.leave_policy?.length }} leave policy
                        selected</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                    <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{ standalone : true }" />

                    <span class="">{{ (item.name?.length>15)? (item.name |
                        slice:0:15)+'..':(item.name) }}</span>
                </div>
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="lvPolicyLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <div *ngIf="(submitted || f.leave_policy.touched || f.leave_policy.dirty) && f.leave_policy.errors"
          class="invalid-feedback">
          <div *ngIf="f.leave_policy.errors.required">
              {{this.messageService.validationDisplay('required')}}</div>
      </div>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.leave_policy?.length != 0 && !lvPolicyLoader">
            <li class="ng-value" *ngFor="let item of ouFilterForm.value.leave_policy,let ki =index">
                <ng-container *ngFor="let insideitem of leavePolicy, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        <div class="d-inline-flex align-items-center">

                            <span class=""> {{ (insideitem['name'].length>25)?
                                (insideitem['name'] |
                                slice:0:25)+'..':(insideitem['name']) }}</span>
                        </div>
                    </span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('leavePolicy',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
  </div>
  <div class="row row-16 mb-16">
    <div class="col-12 form-row">
        <p class="text-uppercase fw-500 mb-0">Organization structure</p>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label ">Company </label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Company')}}"
            formControlName="company" [items]="companyDD" [multiple]="true" groupBy="selectedAllGroup"
            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="company_name"
            [closeOnSelect]="false" bindValue="id" class="multiselect" (change)="getBUListFunction()" [loading]="compLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!compLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.company?.length ">
                    <span class="ng-value-label">{{ouFilterForm.value.company?.length }}
                        company
                        selected</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                    <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{ standalone : true }" />
                    <img class="avatar-sm sq-24 img-fluid rounded-circle"
                        *ngIf="item.logo !=  null && item.logo !=  ''" src="{{item.logo}}">
                    <span *ngIf="item.logo ==  null || item.logo ==  ''"
                        class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appservice.getFirstChar(item.company_name,1)}}</span></span>
                    <span class="">{{ (item.company_name?.length>15)? (item.company_name |
                        slice:0:15)+'..':(item.company_name) }}</span>
                </div>
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="compLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.company?.length != 0 && !compLoader">
            <li class="ng-value" *ngFor="let item of ouFilterForm.value.company,let ki =index">
                <ng-container *ngFor="let insideitem of companyDD, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        <div class="d-inline-flex align-items-center">
                            <img class=" img-fluid sq-24 rounded-circle"
                                *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null"
                                src="{{insideitem.logo}}">
                            <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''"
                                class="  sq-24 avatar-circle rounded-circle me-1 bg{{k%5}}">
                                <span>
                                    {{this.appservice.getFirstChar(insideitem.company_name,1)}}</span>
                            </span>
                            <span class=""> {{ (insideitem['company_name'].length>25)?
                                (insideitem['company_name'] |
                                slice:0:25)+'..':(insideitem['company_name']) }}</span>
                        </div>
                    </span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('company',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label ">Business unit</label>
        <ng-select [readonly]="ouFilterForm.value.company?.length == 0 ? true : false"
            placeholder="{{this.messageService.selectplaceholddisplay('Business Unit')}}"
            formControlName="bu" [items]="buDD" [multiple]="true" groupBy="selectedAllGroup"
            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
            [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="buLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" />
                {{ item.name }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!buLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.bu?.length">
                    <span class="ng-value-label">{{ouFilterForm.value.bu?.length}} business
                        unit
                        selected</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="buLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.bu?.length != 0 && !buLoader">
            <li class="ng-value" *ngFor="let item of ouFilterForm.value.bu,let ki =index">
                <ng-container *ngFor="let insideitem of buDD, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                        slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('bu',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label ">Branch</label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}"
            formControlName="branch" [items]="BranchDD" [multiple]="true" groupBy="selectedAllGroup"
            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
            [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="branchLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" />
                {{ item.name }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!branchLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.branch.length ">
                    <span class="ng-value-label">{{ouFilterForm.value.branch.length }} branch
                        selected</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="branchLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.branch?.length != 0 && !branchLoader">
            <li class="ng-value" *ngFor="let item of ouFilterForm.value.branch,let ki =index">
                <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                        slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('branch',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label">Designation</label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}"
            formControlName="designation" [items]="DesignationDD" [multiple]="true"
            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="desigLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" />
                {{ item.name }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!desigLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.designation?.length">
                    <span class="ng-value-label">{{ouFilterForm.value.designation?.length}}
                        designation
                        selected</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="desigLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.designation?.length != 0 && !desigLoader">
            <li class="ng-value"
                *ngFor="let item of ouFilterForm.value.designation,let ki =index">
                <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                        slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('designation',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label ">Department </label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Department')}}"
            formControlName="department" [items]="DepDD" [multiple]="true" groupBy="selectedAllGroup"
            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
            [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="deptLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" />
                {{ item.name }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!deptLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.department?.length ">
                    <span class="ng-value-label">{{ouFilterForm.value.department?.length}}
                        department
                        selected</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="deptLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.department?.length != 0 && !deptLoader">
            <li class="ng-value"
                *ngFor="let item of ouFilterForm.value.department,let ki =index">
                <ng-container *ngFor="let insideitem of DepDD, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                        slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('department',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label ">Grade </label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Grade')}}"
            formControlName="grade" [items]="gradeDD" [multiple]="true" groupBy="selectedAllGroup"
            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
            [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="gradeLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" />
                {{ item.name }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!gradeLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.grade?.length ">
                    <span class="ng-value-label">{{ouFilterForm.value.grade?.length }} grade
                        selected.</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="gradeLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.grade?.length != 0 && !gradeLoader">
            <li class="ng-value" *ngFor="let item of ouFilterForm.value.grade,let ki =index">
                <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                        slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('grade',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
    <div class="col-12 form-row" *ngIf="from=='location'">
      <label for="" class="form-label ">Reporting Manager </label>
      <ng-select [placeholder]="'Select reporting manager'" formControlName="reporting_manager" class="form-ngselect" [clearable]="false">
        <ng-option  class="fs-12" *ngFor="let reporting of reportingDD" [value]="reporting.id">{{reporting.name}}</ng-option>
    </ng-select>
  </div>
  </div>
  <div class="row row-16 mb-16">
    <div class="col-12 form-row">
        <p class="text-uppercase fw-500 mb-0">Employee search
        </p>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label">Employees</label>
        <ng-select [readonly]="disabled"
            placeholder="{{this.messageService.selectplaceholddisplay('Employees')}}"
            formControlName="employee_list" [items]="employeeDropdown" [multiple]="true"
            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="empLoader">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!empLoader">
                <div class="ng-value" *ngIf="ouFilterForm.value.employee_list?.length">
                    <span class="ng-value-label">{{ouFilterForm.value.employee_list?.length}}
                        employee
                        selected.</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                        class="avatar-sm sq-24 img-fluid rounded-circle"
                        *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                        src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                        class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appservice.getFirstChar(item.name,1)}}</span></span>
                    <span class="mt-1 ms-1">{{ (item.name?.length>15)? (item.name |
                        slice:0:30)+'..':(item.name) }}</span>
                </div>
            </ng-template>
            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="empLoader">
              <div class="loading-text">Loading...</div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16" *ngIf="ouFilterForm.value.employee_list?.length !=0 && !empLoader">
            <li class="ng-value"
                *ngFor="let item of ouFilterForm.value.employee_list, let m =index">
                <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                        <div class="d-inline-flex align-items-center">
                            <img class="avatar-sm sq-24 img-fluid rounded-circle"
                                *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                src="{{insideitem.profile_image}}">
                            <span
                                *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                class="avatar-sm sq-24 rounded-circle {{insideitem.color_code}}">
                                <span>
                                    {{this.appservice.getFirstChar(insideitem.fullname,1)}}</span>
                            </span>
                            <span class="mx-1">
                                {{insideitem.first_name+"
                                "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                            </span>
                        </div>
                    </span>
                </ng-container>
                <span  class="ng-value-icon right" aria-hidden="true"
                    (click)="clearcommonForm('employee',m)"><i class="icon-close"></i></span>
            </li>
        </ul>
    </div>
    <div class="col-12 form-row">
        <label for="" class="form-label ">Employment status </label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employment Status')}}"
            formControlName="employment_status" [items]="empstatusDDn" [multiple]="true"
            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
            bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select all
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{ standalone : true }" class="me-8" />
                    {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                </div>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="ouFilterForm.value.employment_status?.length ">
                    <span class="ng-value-label">{{ouFilterForm.value.employment_status?.length
                        }}
                        employment status
                        selected.</span>
                </div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16"
            *ngIf="ouFilterForm.value.employment_status?.length != 0">
            <li class="ng-value"
                *ngFor="let item of ouFilterForm.value.employment_status,let ki =index">
                <ng-container *ngFor="let insideitem of empstatusDDn, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['name']==item">
                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                        slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span  class="ng-value-icon right"
                    (click)="clearcommonForm('employment_status',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
    <div class="col-12">
        <label for="name" class="form-label ">Employment type</label>
        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employment Type')}}"
            formControlName="employee_type" [items]="emptypeDDn" [multiple]="true"
            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
            bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{standalone: true}" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{ standalone : true }" class="me-8" />
                    {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                </div>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="ouFilterForm.value.employee_type?.length ">
                    <span class="ng-value-label">{{ouFilterForm.value.employee_type?.length }}
                        employment type
                        selected.</span>
                </div>
            </ng-template>
        </ng-select>
        <ul class="selected-values mt-16"
            *ngIf="ouFilterForm.value.employee_type?.length != 0">
            <li class="ng-value"
                *ngFor="let item of ouFilterForm.value.employee_type,let ki =index">
                <ng-container *ngFor="let insideitem of emptypeDDn, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['name']==item">
                        {{ (insideitem['name'].length>15)? (insideitem['name'] |
                        slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span class="ng-value-icon right"
                    (click)="clearcommonForm('employee_type',ki)" aria-hidden="true">×</span>
            </li>
        </ul>
    </div>
  </div>
</form>
