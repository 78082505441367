import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationHierarchyComponent } from './organization-hierarchy/organization-hierarchy.component';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';

const routes: Routes = [ LayoutService.childRoutes([
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'organization-hierarchy', component: OrganizationHierarchyComponent}
])];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationHierarchyRoutingModule { }
 