import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
@Component({
  selector: 'app-shift-rotation-details',
  templateUrl: './shift-rotation-details.component.html',
  styleUrls: ['./shift-rotation-details.component.scss']
})
export class ShiftRotationDetailsComponent implements OnInit {
  policyDefineUnit:any =[];
  value:any;
  id:any
  scheduledfor:any =[
    'Monthly',
    'Weekly'
  ];
  shiftschedule:any;
  applicable_periodkey = this.messageService.selectplaceholddisplay('Applicable period');
  @Input()formGp : FormGroup    = this.fb.group({
    name                  : [''],
    shift_schedule        : [''],
    applicable_period     : [''],
  });
  validated : boolean = false;
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Input()componentError:any;
  @Input()disabled              = false;
  private subjectKeyUp = new Subject<any>();
  @Input()submitted:boolean=false;

  constructor( private fb:FormBuilder,
    public messageService : MessageService,
    public route : ActivatedRoute,
    public existDataService :  ExistDatasService
    ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
  }
    // Simplified function to use for validation
    existService(){
      if(!this.editflag){
        this.value ={
          "page": "ShiftRotation",
          "field":"rotation_name",
          "value": this.f.name.value
        }
      }else{
         this.value ={
          "id":this.id,
          "page":"ShiftRotation",
          "field":"rotation_name",
          "value": this.f.name.value
        }
      }

      this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
        this.policyDefineUnit = res.body

        if(this.policyDefineUnit.data ==='invalid'){
          this.f.name.setErrors({
            'exist' : true
          })
        }
      })
    }
  validateform() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.shiftschedule = this.formGp.controls['shift_schedule'].value;
    this.submitmethod.emit(this.shiftschedule);
  }

  get f() { return this.formGp.controls; }
  // For validation of keyup(name)

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
  resetApplicablePeriod(){
    this.formGp.get('applicable_period')?.setValue(null);
  }

}

