<div class=" config-layout" *ngIf="permissions">
  <header class=" config-header">
    <h5 class="m-0">{{getcurrentPage()}}  - {{month}} {{year}}</h5>
    <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i
        class="icon-close-lg fs-16"></i></button>
  </header>
  <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky " style="top: 3.625rem;"
    (navChange)="onNavChange($event)">
    <li [ngbNavItem]="1">
      <a ngbNavLink class="nav-link">Monthly Variable</a>
      <ng-template ngbNavContent>
        <div class="container-fluid p-32 form-container">
          <div class="row row-24 ">
            <div class="col-12 d-flex flex-wrap">
              <div class="mb-0 fw-500">MONTHLY VARIABLE</div>
              <div class="d-flex align-items-center ms-auto gap-16">
                <!-- <a *ngIf="(countSelected()>=1 && status=='Unprocessed' && permissions?.a)"
                  class="link-danger text-nowrap" (click)="bulkDelete()"><i
                    class="icon-trash me-8 pointer "></i>Delete</a> -->
                <div style="min-width:22.688rem;">
                  <div class="form-icon2">
                    <i class="icon-search icon-left fs-14"></i>
                    <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
                      (click)="searchString=''"></div>
                    <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  "
                      name="searchString" [(ngModel)]="searchString" (keyup)="page=1" autocomplete="off"/>
                  </div>
                </div>
                <div *ngIf="status=='Processed'" ngbDropdown class="d-inline-block" (click)="filter  = true">
                  <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                    <i class="icon-filter-left fs-13"></i>
                  </button>
                </div>
                <button *ngIf="status=='Unprocessed' && permissions?.a"
                  class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 "
                  (click)="showSidePanel=true;clearData()">IMPORT</button>
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
                    [checked]='status=="Unprocessed"' (click)="getMonthlyData('Unprocessed','monthly')"
                    [disabled]="loader">
                  <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700"
                    for="btnradio1">Pending</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                    [checked]='status=="Processed"' (click)="getMonthlyData('Processed','monthly')" [disabled]="loader">
                  <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">Added</label>
                </div>

              </div>
            </div>


            <div class="col-12" *ngIf="monthlyData?.length>0 && Isalert && status=='Unprocessed'">
              <div role="alert" class="alert alert-warning show mb-0">
                <p class="mb-0 fs-14">Click on the editable fields to update the data</p>
                <div class="btn-close pointer" (click)="Isalert=false;"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 p-0">
                <app-table-loader *ngIf="loader"></app-table-loader>

                <div *ngIf="!loader" class="table-responsive radius-4">
                  <table class="table td-12 th-12 sticky-header td-fs-14
                                    tr-fs-14 table-striped table-sm form-table">
                    <thead>

                      <tr>
                        <th scope="col" class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                            [checked]="checkallSelected()">
                        </th>
                        <th class="fw-600" scope="col">EMP CODE</th>
                        <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                        <th class="fw-600" scope="col">VARIABLE COMPONENT</th>
                        <th class="fw-600" scope="col">AMOUNT (MONTHLY)</th>
                        <th class="fw-600" scope="col">AMOUNT (YEARLY)</th>
                        <th class="fw-600" scope="col">PAYMENT AMOUNT</th>
                        <th class="fw-600" scope="col">SALARY STATUS</th>
                        <th *ngIf="status=='Unprocessed' && permissions?.a" class="fw-600" scope="col">
                        </th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 height-full">
                      <tr *ngIf="monthlyData.length==0">
                        <td colspan="9">
                          <div class="card card-c2 flex-center p-16 bg-dark-500">
                            <span class="fw-500 fs-14">No data found</span>
                          </div>
                        </td>
                      </tr>
                      <tr
                        *ngFor="let item of $any(monthlyData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                        <td class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked">
                        </td>
                        <td>
                          <div class=" {{!(item?.employee_code.is_valid)?'cell-error is-invalid':''}}">
                            {{item.employee_code.data}}

                          </div>
                          <div *ngIf="!(item?.employee_code.is_valid)" class="invalid-feedback">
                            {{item?.employee_code.error}}</div>
                        </td>
                        <td class="form-cell">
                          <ng-container *ngIf="status=='Unprocessed';else nameelse">
                            <ng-select appendTo="body" class="form-ngselect"
                              placeholder="{{this.messageService.selectplaceholddisplay('Employee')}}"
                              [(ngModel)]="item?.employee.data" (change)="setEmpCode($event,monthlyData.indexOf(item))">
                              <ng-container *ngFor="let emp of empDD">

                                <ng-option [value]="emp.id">
                                  <div class="d-inline-flex  align-items-center">

                                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                                      *ngIf="emp?.profile_image !=  '' && emp?.profile_image != null"
                                      src="{{emp?.profile_image}}">
                                    <span *ngIf="emp?.profile_image ==  ''|| emp?.profile_image == null"
                                      class="avatar-sm sq-32 me-8 rounded-circle {{emp?.color_code}}">
                                      <span>
                                        {{this.appService.getFirstChar(emp?.first_name+" "+emp?.last_name,2)}}</span>
                                    </span>
                                    {{emp?.first_name+" "+(emp?.middle_name!=null?emp?.middle_name+" ":"
                                    ")+emp?.last_name +" ("+emp?.employee_code+")"}}
                                  </div>
                                </ng-option>
                              </ng-container>

                            </ng-select>
                          </ng-container>
                          <ng-template #nameelse>
                            {{item?.employee_name.data}}
                          </ng-template>

                        </td>
                        <td>
                          <ng-container *ngIf="status=='Unprocessed';else compelse">

                            <ng-select class="hasError  {{(item.pay_component.is_valid==false) ?'is-invalid':''}}"
                              appendTo="body" class="form-ngselect"
                              placeholder="{{this.messageService.selectplaceholddisplay('Component')}}"
                              [(ngModel)]="item.pay_component.data" (change)="setAmount($event,monthlyData.indexOf(item))">
                              <ng-container *ngFor="let comp of item?.variable_com">

                                <ng-option [value]="comp.component_id">
                                  <div class="d-inline-flex  align-items-center">
                                    {{comp.component_name}}
                                  </div>
                                </ng-option>
                              </ng-container>

                            </ng-select>
                            <div *ngIf="!(item.pay_component.is_valid)" class="invalid-feedback">
                              <div>{{item.pay_component.error}}</div>
                            </div>
                          </ng-container>
                          <ng-template #compelse>
                            {{item?.pay_component_name.data}}
                          </ng-template>
                        </td>
                        <ng-container *ngIf="rowLoader[monthlyData.indexOf(item)]">
                        <td><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '75%' }"></ngx-skeleton-loader></td>
                        <td><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '75%' }"></ngx-skeleton-loader></td>
                        <td><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '75%' }"></ngx-skeleton-loader></td>
                      </ng-container>
                      <ng-container *ngIf="!rowLoader[monthlyData.indexOf(item)]">
                        <td class="form-cell">
                          {{item?.pay_component_amount.data | currency: currency}}
                          <span class=" ms-8" *ngIf="item?.var_hint"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                            ngbPopover="{{item?.var_hint}}" popoverClass="popover-input popover-info"
                            placement="bottom" triggers="hover"></i></span>
                        </td>
                        <td class="form-cell">
                          {{item?.pay_component_yearly_amount.data | currency: currency}}

                        </td>
                        <td class="form-cell">
                          <ng-container *ngIf="status=='Unprocessed';else amountelse">

                            <input class="hasError form-control {{(item.pay_amount.is_valid==false)?'is-invalid':''}}"
                              type="text" [(ngModel)]="item.pay_amount.data" (keyup)="validateField(monthlyData.indexOf(item),'pay_amount')"
                              placeholder="{{this.messageService.placeholderdisp('Payment amount')}}">
                            <div *ngIf="!(item.pay_amount.is_valid)" class="invalid-feedback">
                              <div>{{item.pay_amount.error}}</div>
                            </div>
                          </ng-container>
                          <ng-template #amountelse>
                            {{item?.pay_amount.data | currency:currency}}
                          </ng-template>


                        </td></ng-container>
                        <td>
                          <span *ngIf="item.salary_status.data=='Publish'"
                            class="badge  badge-success py-1">Published</span>
                          <span *ngIf="item.salary_status.data=='Processed'"
                            class="badge badge-warning py-1">Processed</span>
                          <span *ngIf="item.salary_status.data=='Hold'" class="badge badge-primary py-1">Held</span>
                          <span *ngIf="item.salary_status.data=='Freeze'"
                            class="badge badge-holiday py-1">Freezed</span>
                          <span *ngIf="item.salary_status.data=='Unprocessed'"
                            class="badge badge-danger py-1">Unprocessed</span>
                        </td>
                        <td *ngIf="status=='Unprocessed' && permissions?.a "> <i class="icon-trash pointer"
                            (click)="deleteSingleRow(monthlyData.indexOf(item))"></i> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="status=='Unprocessed' && permissions?.a">
              <button class="btn btn-outline-primary radius-2" (click)="addMonthlyData()">Add Employee</button>
            </div>
            <div class="col-12" *ngIf="filteredcount()>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="filteredcount()"
                [pageSize]="pageSize" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true"
                [boundaryLinks]="true">
              </ngb-pagination>
            </div>
          </div>
        </div>
        <footer class="submit-footer gap-16 justify-content-end">
          <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="bulkDelete()"  *ngIf="(countSelected()>=1 && status=='Unprocessed' && permissions?.a)">Delete</button>
          <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="monthlyData.length>0">
            <span class="">{{countSelected()}} of {{monthlyData?.length}} selected </span>
            <span *ngIf="monthlyData.length != countSelected()" class="link-primary fw-500"
              (click)="selectAll();">Select all {{monthlyData?.length}} employees</span>
            <span *ngIf="monthlyData.length == countSelected()" class="link-primary fw-500"
              (click)="unselectAll();">Unselect all {{monthlyData?.length}} employees</span>
          </p>

          <button *ngIf="status=='Unprocessed'" [disabled]="countSelected()==0 || invalidData()==countSelected()"
            (click)="constructData('Processed')" class="btn btn-primary btn-sm ms-16 text-uppercase">Save</button>
          <button *ngIf="status=='Processed'" [disabled]="countSelected()==0 || invalidData()==countSelected()"
            (click)="constructData('Unprocessed')" class="btn btn-primary btn-sm ms-16 text-uppercase">Remove</button>

        </footer>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink class="nav-link">Overtime</a>
      <ng-template ngbNavContent>
        <div class="container-fluid p-32 form-container">
          <div class="row row-24 ">
            <div class="col-12 d-flex flex-wrap">
              <div class="mb-0 fw-500 text-uppercase">Overtime process</div>
              <div class="d-flex align-items-center ms-auto gap-16">
                <!-- <a class="link-danger text-nowrap"><i class="icon-trash me-8 pointer "></i>Delete</a> -->
                <div style="max-width: 363px">
                  <div class="form-icon2">
                    <i class="icon-search icon-left fs-14"></i>
                    <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
                      (click)="searchString=''"></div>
                    <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  "
                      name="searchString" [(ngModel)]="searchString" (keyup)="page=1" autocomplete="off"/>
                  </div>
                </div>
                <div *ngIf="status=='Completed'" ngbDropdown class="d-inline-block" (click)="filter  = true">
                  <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                    <i class="icon-filter-left fs-13"></i>
                  </button>
                </div>
                <button *ngIf="status=='Pending'"
                  class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 " (click)="showSidePanel=true;">IMPORT</button>
                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
                      [checked]='status=="Pending"' (click)="toggleOverTime('Pending')"
                      [disabled]="loader">
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700"
                      for="btnradio1">Pending</label>
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                      [checked]='status=="Completed"' (click)="toggleOverTime('Completed')"
                      [disabled]="loader">
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">Added</label>
                  </div>

              </div>
            </div>

            <div class="col-12">
              <div class="card card-c2 p-0">
                <app-table-loader *ngIf="loader"></app-table-loader>

                <div class="table-responsive radius-4" *ngIf="!loader">

                  <table class="table td-12 th-12 sticky-header td-white-space td-fs-14
                                    tr-fs-14 table-striped table-sm form-table" *ngIf="status=='Pending'">
                    <thead>
                      <tr>
                        <th scope="col" class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)" [checked]="checkallSelected()">
                        </th>
                        <th class="fw-600" scope="col">EMP CODE</th>
                        <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                        <th class="fw-600" scope="col">Company</th>
                        <th class="fw-600" scope="col">Department</th>
                        <th class="fw-600" scope="col">OT MONTH YEAR</th>
                        <th class="fw-600 bg21" scope="col">TOTAL OT HOURS</th>
                        <th class="fw-600" scope="col">working day ot hours
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Working day OT hours = Actual OT hours in a working day x OT rate for working day" popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>

                        </th>
                        <th class="fw-600" scope="col">Weekly off ot hours
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Weekly off ot hours = Actual OT hours in a weekly off x OT rate for weekly off" popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>

                        </th>
                        <th class="fw-600" scope="col">HOLIday ot hours
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Holiday ot hours = Actual OT hours in a holiday x OT rate for holiday" popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                        </th>
                        <th class="fw-600" scope="col">NATIONAL HOLIday ot hours
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="National holiday ot hours = Actual OT hours in a national holiday x OT rate for national holiday" popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                        </th>
                        <th class="fw-600" scope="col">Half working day ot hours
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Half working day ot hours = Actual OT hours in a half working day x OT rate for half working day" popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 height-full">
                      <tr *ngIf="overTimeData.length==0">
                        <td colspan="12">
                          <div class="card card-c2 flex-center p-16 bg-dark-500">
                            <span class="fw-500 fs-14">No data found</span>
                          </div>
                        </td>
                      </tr>
                      <tr *ngFor="let item of $any(overTimeData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                        <td class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked">

                        </td>
                        <td>{{item?.employee_code}}</td>
                        <td>{{item?.name}}</td>
                        <td>{{item?.company}}</td>
                        <td>{{item?.department}}</td>
                        <td>{{item?.process_month}} {{item?.process_year}}</td>
                        <td class="bg21">{{item?.total_ot_hours}}</td>
                        <!-- <td>{{item?.working_day_ot_hours}}</td> -->
                        <td>
                          <input  type="text" class="hasError form-control {{(item?.working_day_ot_hours_error) ?'is-invalid':''}} " [(ngModel)]="item.working_day_ot_hours" placeholder="{{this.messageService.placeholderdisp('Weekly off ot hours')}}" (keyup)="validateData(overTimeData.indexOf(item),'working_day_ot_hours')"/>
                          <div *ngIf="item?.working_day_ot_hours_error" class="invalid-feedback">
                            <div>{{item?.working_day_ot_hours_msg}}</div>
                          </div>
                        </td>

                          <td>
                            <input  type="text" class="hasError form-control {{(item?.week_off_ot_hours_error) ?'is-invalid':''}} " [(ngModel)]="item.week_off_ot_hours" placeholder="{{this.messageService.placeholderdisp('Weekly off ot hours')}}" (keyup)="validateData(overTimeData.indexOf(item),'week_off_ot_hours')"/>
                            <div *ngIf="item?.week_off_ot_hours_error" class="invalid-feedback">
                              <div>{{item?.week_off_ot_hours_msg}}</div>
                            </div>
                          </td>
                          <td>
                            <input  type="text" class="hasError form-control {{(item?.holiday_ot_hours_error) ?'is-invalid':''}} " [(ngModel)]="item.holiday_ot_hours" placeholder="{{this.messageService.placeholderdisp('Holiday ot hours')}}" (keyup)="validateData(overTimeData.indexOf(item),'holiday_ot_hours')"/>
                            <div *ngIf="item?.holiday_ot_hours_error" class="invalid-feedback">
                              <div>{{item?.holiday_ot_hours_msg}}</div>
                            </div>
                          </td>
                          <td>
                            <input  type="text" class="hasError form-control {{(item?.national_holiday_ot_hours_error) ?'is-invalid':''}} " [(ngModel)]="item.national_holiday_ot_hours" placeholder="{{this.messageService.placeholderdisp('National holiday ot hours')}}" (keyup)="validateData(overTimeData.indexOf(item),'national_holiday_ot_hours')"/>
                            <div *ngIf="item?.national_holiday_ot_hours_error" class="invalid-feedback">
                              <div>{{item?.national_holiday_ot_hours_msg}}</div>
                            </div>
                          </td>
                          <td>
                            <input  type="text" class="hasError form-control {{(item?.half_working_day_ot_hours_error) ?'is-invalid':''}} " [(ngModel)]="item.half_working_day_ot_hours" placeholder="{{this.messageService.placeholderdisp('Half working day ot hours')}}" (keyup)="validateData(overTimeData.indexOf(item),'half_working_day_ot_hours')"/>
                            <div *ngIf="item?.half_working_day_ot_hours_error" class="invalid-feedback">
                              <div>{{item?.half_working_day_ot_hours_msg}}</div>
                            </div>
                          </td>

                      </tr>
                    </tbody>
                  </table>
                <!-- completed -->
                <table class="table td-12 th-12 sticky-header td-white-space td-fs-14
                                    tr-fs-14 table-striped table-sm form-table" *ngIf="status=='Completed'">
                    <thead>
                      <tr>
                        <th scope="col" class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)" [checked]="checkallSelected()">
                        </th>
                        <th class="fw-600" scope="col">EMP CODE</th>
                        <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                        <th class="fw-600" scope="col">Company</th>
                        <th class="fw-600" scope="col">OT HOURLY RATE</th>
                        <th class="fw-600" scope="col">OVERTIME HOURS</th>
                        <th class="fw-600" scope="col">OVERTIME AMOUNT</th>

                      </tr>
                    </thead>
                    <tbody class="cell-16 height-full">
                      <tr *ngIf="overTimeData.length==0">
                        <td colspan="7">
                          <div class="card card-c2 flex-center p-16 bg-dark-500">
                            <span class="fw-500 fs-14">No data found</span>
                          </div>
                        </td>
                      </tr>
                      <tr *ngFor="let item of $any(overTimeData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                        <td class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked">

                        </td>
                        <td>{{item?.employee_code}}</td>
                        <td>{{item?.name}}</td>
                        <td>{{item?.company}}</td>
                        <td>{{item?.hourly_rate}}</td>
                        <td>{{item?.total_ot_hours}}</td>
                        <td>{{item?.total_ot_amount}}</td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="filteredcount()>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="filteredcount()"
                [pageSize]="pageSize" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true"
                [boundaryLinks]="true">
              </ngb-pagination>
            </div>
          </div>
        </div>
        <footer class="submit-footer gap-16 justify-content-end">
          <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="overTimeData.length>0">
            <span class="">{{countSelected()}} of {{overTimeData?.length}} selected </span>
            <span *ngIf="overTimeData.length != countSelected()" class="link-primary fw-500"
              (click)="selectAll();">Select all {{overTimeData?.length}} employees</span>
            <span *ngIf="overTimeData.length == countSelected()" class="link-primary fw-500"
              (click)="unselectAll();">Unselect all {{overTimeData?.length}} employees</span>
          </p>

          <button *ngIf="status=='Pending'" [disabled]="countSelected()==0 || invalidOvertimeData() == countSelected()"
            (click)="constructOvertime('Completed')" class="btn btn-primary btn-sm ms-16 text-uppercase">Process Overtime</button>
          <button *ngIf="status=='Completed'" [disabled]="countSelected()==0"
            (click)="constructOvertime('Pending')" class="btn btn-primary btn-sm ms-16 text-uppercase">Unprocess Overtime</button>

        </footer>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink class="nav-link">Perquisite</a>
      <ng-template ngbNavContent>
        <div class="container-fluid p-32 form-container">
          <div class="row row-24 ">
            <div class="col-12 d-flex flex-wrap">

              <div class="mb-0 fw-500">PERQUISITE</div>
              <div class="d-flex align-items-center ms-auto gap-16">
                <!-- <a *ngIf="(countSelected()>=1 && status=='Unprocessed' && permissions?.a)"
                  class="link-danger text-nowrap" (click)="bulkDelete()"><i
                    class="icon-trash me-8 pointer "></i>Delete</a> -->
                <div style="max-width: 363px">
                  <div class="form-icon2">
                    <i class="icon-search icon-left fs-14"></i>
                    <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
                      (click)="searchString=''"></div>
                    <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  "
                      name="searchString" [(ngModel)]="searchString" (keyup)="page=1" autocomplete="off"/>
                  </div>
                </div>
                <div *ngIf="status=='Processed'" ngbDropdown class="d-inline-block" (click)="filter  = true">
                  <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                    <i class="icon-filter-left fs-13"></i>
                  </button>
                </div>
                <button *ngIf="status=='Unprocessed' && permissions?.a"
                  class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 "
                  (click)="showSidePanel=true;">IMPORT</button>
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
                    [checked]='status=="Unprocessed"' (click)="getMonthlyData('Unprocessed','perquisite')"
                    [disabled]="loader">
                  <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700"
                    for="btnradio1">Pending</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                    [checked]='status=="Processed"' (click)="getMonthlyData('Processed','perquisite')"
                    [disabled]="loader">
                  <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">Added</label>
                </div>

              </div>
            </div>
            <div class="col-12" *ngIf="monthlyData?.length>0 && Isalert && status=='Unprocessed'">
              <div role="alert" class="alert alert-warning show mb-0">
                <p class="mb-0 fs-14">Click on the editable fields to update the data</p>
                <div class="btn-close pointer" (click)="Isalert=false;"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 p-0">
                <app-table-loader *ngIf="loader"></app-table-loader>

                <div *ngIf="!loader" class="table-responsive radius-4">
                  <table class="table td-12 th-12 sticky-header td-white-space td-fs-14
                                  tr-fs-14 table-striped table-sm form-table">
                    <thead>
                      <tr>
                        <th scope="col" class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                            [checked]="checkallSelected()">
                        </th>
                        <th class="fw-600" scope="col">EMP CODE</th>
                        <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                        <th class="fw-600" scope="col">PERQUISITE COMPONENT</th>
                        <th class="fw-600" scope="col">DEFAULT VALUE</th>
                        <th class="fw-600" scope="col">PAYABLE AMOUNT</th>
                        <th class="fw-600" scope="col">AMOUNT RECOVERED</th>
                        <th class="fw-600" scope="col">SALARY STATUS</th>
                        <th *ngIf="status=='Unprocessed' && permissions?.a" class="fw-600" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 height-full">
                      <tr *ngIf="monthlyData.length==0">
                        <td colspan="9">
                          <div class="card card-c2 flex-center p-16 bg-dark-500">
                            <span class="fw-500 fs-14">No data found</span>
                          </div>
                        </td>
                      </tr>
                      <tr
                        *ngFor="let item of $any(monthlyData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">

                        <td class="form-cell">
                          <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked">

                        </td>
                        <td>
                          <div class=" {{!(item?.employee_code.is_valid)?'cell-error is-invalid':''}}">
                            {{item.employee_code.data}}

                          </div>
                          <div *ngIf="!(item?.employee_code.is_valid)" class="invalid-feedback">
                            {{item?.employee_code.error}}</div>
                        </td>

                        <td class="form-cell">
                          <ng-container *ngIf="status=='Unprocessed';else nameelse">

                            <ng-select appendTo="body" class="form-ngselect"
                              placeholder="{{this.messageService.selectplaceholddisplay('Employee')}}"
                              [ngModel]="item.employee?.data" (change)="setEmpCode($event,monthlyData.indexOf(item))">


                              <ng-container *ngFor="let emp of empDD">

                                <ng-option [value]="emp.id">
                                  <div class="d-inline-flex  align-items-center">

                                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                                      *ngIf="emp?.profile_image !=  '' && emp?.profile_image != null"
                                      src="{{emp?.profile_image}}">
                                    <span *ngIf="emp?.profile_image ==  ''|| emp?.profile_image == null"
                                      class="avatar-sm sq-32 me-8 rounded-circle {{emp?.color_code}}">
                                      <span>
                                        {{this.appService.getFirstChar(emp.first_name+" "+emp.last_name,2)}}</span>
                                    </span>
                                    {{emp?.first_name+" "+(emp?.middle_name!=null?emp?.middle_name+" ":"
                                    ")+emp?.last_name +" ("+emp?.employee_code+")"}}
                                  </div>
                                </ng-option>
                              </ng-container>

                            </ng-select>
                          </ng-container>
                          <ng-template #nameelse>
                            {{item?.employee_name?.data}}
                          </ng-template>
                        </td>
                        <td>
                          <ng-container *ngIf="status=='Unprocessed';else compelse">

                            <ng-select appendTo="body"
                              class="form-ngselect hasError  {{(item.pay_component.is_valid==false) ?'is-invalid':''}}"
                              placeholder="{{this.messageService.selectplaceholddisplay('Component')}}"
                              [(ngModel)]="item.pay_component.data" (change)="setAmount($event,monthlyData.indexOf(item))">
                              <ng-container *ngFor="let comp of item?.variable_com">

                                <ng-option [value]="comp.component_id">
                                  <div class="d-inline-flex  align-items-center">
                                    {{comp.component_name}}
                                  </div>
                                </ng-option>
                              </ng-container>

                            </ng-select>
                            <div *ngIf="!(item.pay_component.is_valid)" class="invalid-feedback">
                              <div>{{item.pay_component.error}}</div>
                            </div>
                          </ng-container>
                          <ng-template #compelse>
                            {{item?.pay_component_name.data}}
                          </ng-template>


                        </td>
                        <td class="form-cell">
                          {{item?.pay_component_yearly_amount.data | currency:currency}}

                        </td>
                        <td class="form-cell">
                          <ng-container *ngIf="status=='Unprocessed';else amountelse">

                            <input class="hasError form-control {{(item.pay_amount.is_valid==false)?'is-invalid':''}}"
                              type="text" [(ngModel)]="item.pay_amount.data" (keyup)="validateField(monthlyData.indexOf(item),'pay_amount')"
                              placeholder="{{this.messageService.placeholderdisp('Payable amount')}}">
                            <div *ngIf="!(item.pay_amount.is_valid)" class="invalid-feedback">
                              <div>{{item.pay_amount.error}}</div>
                            </div>
                          </ng-container>
                          <ng-template #amountelse>
                            {{item?.pay_amount.data | currency:currency}}
                          </ng-template>

                        </td>
                        <td class="form-cell">
                          <ng-container *ngIf="status=='Unprocessed';else recamount">

                            <input
                              class="hasError form-control {{(item.amount_recovered.is_valid==false)?'is-invalid':''}}"
                              type="text" [(ngModel)]="item.amount_recovered.data"
                              (keyup)="validateField(monthlyData.indexOf(item),'amount_recovered')"
                              placeholder="{{this.messageService.placeholderdisp('Amount recovered')}}">
                            <div *ngIf="!(item.amount_recovered.is_valid)" class="invalid-feedback">
                              <div>{{item.amount_recovered.error}}</div>
                            </div>
                          </ng-container>
                          <ng-template #recamount>
                            {{item?.amount_recovered.data | currency:currency}}
                          </ng-template>

                        </td>
                        <td>
                          <span *ngIf="item?.salary_status?.data=='Publish'"
                            class="badge  badge-success py-1">Published</span>
                          <span *ngIf="item?.salary_status?.data=='Processed'"
                            class="badge badge-warning py-1">Processed</span>
                          <span *ngIf="item?.salary_status?.data=='Hold'" class="badge badge-primary py-1">Held</span>
                          <span *ngIf="item?.salary_status?.data=='Freeze'"
                            class="badge badge-holiday py-1">Freezed</span>
                          <span *ngIf="item?.salary_status?.data=='Unprocessed'"
                            class="badge badge-danger py-1">Unprocessed</span>
                        </td>
                        <td *ngIf="status=='Unprocessed' && permissions?.a "> <i class="icon-trash pointer"
                            (click)="deleteSingleRow(monthlyData.indexOf(item))"></i> </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="status=='Unprocessed' && permissions?.a">
              <button class="btn btn-outline-primary radius-2" (click)="addMonthlyData()">Add Employee</button>
            </div>
            <div class="col-12" *ngIf="filteredcount()>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="filteredcount()"
                [pageSize]="pageSize" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true"
                [boundaryLinks]="true">
              </ngb-pagination>
            </div>
          </div>
        </div>
        <footer class="submit-footer gap-16 justify-content-end">
          <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="bulkDelete()"  *ngIf="(countSelected()>=1 && status=='Unprocessed' && permissions?.a)">Delete</button>
          <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="monthlyData.length>0">
            <span class="">{{countSelected()}} of {{monthlyData?.length}} selected </span>
            <span *ngIf="monthlyData.length != countSelected()" class="link-primary fw-500"
              (click)="selectAll();">Select all {{monthlyData?.length}} employees</span>
            <span *ngIf="monthlyData.length == countSelected()" class="link-primary fw-500"
              (click)="unselectAll();">Unselect all {{monthlyData?.length}} employees</span>
          </p>

          <button *ngIf="status=='Unprocessed'" [disabled]="countSelected()==0 || invalidData()==countSelected()"
            (click)="constructData('Processed')" class="btn btn-primary btn-sm ms-16 text-uppercase">Save</button>
          <button *ngIf="status=='Processed'" [disabled]="countSelected()==0 || invalidData()==countSelected()"
            (click)="constructData('Unprocessed')" class="btn btn-primary btn-sm ms-16 text-uppercase">Remove</button>

        </footer>
      </ng-template>
    </li>
  </nav>
  <main class=" config-container tabs-container" [ngbNavOutlet]="nav">
  </main>
</div>

<div class="side-panel" style="--sidepanel-width:56.875rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Import {{getcurrentPage()}} - {{month}} {{year}}</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="title mb-24">{{getcurrentPage()}} - {{month}} {{year}}</div>
              <div class="row" *ngIf="fileName">
                <div class="col-md-6">
                  <div class="card card-c2 bg-secondary  mb-24" *ngIf="fileName!=''">
                    <div class="d-flex p-24 ">
                      <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                      <span class="text-trim fw-500">{{fileName}}</span>
                      <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                      <span class="link-danger fw-500 flex-shrink-0" (click)="clearImportData()"><i
                          class="bi bi-trash ms-8 "></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                  (change)="onFileChange($event)" id="upload" hidden>
                Import {{getcurrentPage()}}
              </label>
              <span class="invalid-feedback"
                *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="title mb-24">Instructions</div>
              <ol class="fs-14 ps-16 mb-0 li-24">
                <li>Download the
                  <a *ngIf="activeTab==1" class="link-primary" href="assets/docs/sample-monthly-payment-import.xlsx"> <i
                      class="icon-download me-1"></i> Sample Excel
                    template</a>
                    <a *ngIf="activeTab==2" class="link-primary" href="assets/docs/sample-overtime-process.xlsx"> <i
                      class="icon-download me-1"></i> Sample Excel
                    template</a>
                  <a *ngIf="activeTab==3" class="link-primary" href="assets/docs/sample-perquisite-import.xlsx"> <i
                      class="icon-download me-1"></i> Sample Excel
                    template</a>
                </li>
                <li>Add the amounts in respective fields as per the format provided in the excel.</li>
                <li>Save the excel file.</li>
                <li>Click on the {{getcurrentPage()}} button to import the details added in
                  the excel file. </li>
                <li>Choose the file to be imported.</li>
                <li>The file chosen should be in (.xls/.csv)format only.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer gap-32 pt-16">
      <button  class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || submiited"
        type="button" (click)="uploadData('excel')">Upload</button>
    </footer>
  </div>
</div>

<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
  (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>


<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Error Occured</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{deleteMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm"
          (click)="deleteToggle = false;bulkDelete();">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">{{saveData.length}} Employees selected</h4>
        <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{confirmMsgText}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="confirmMsg = false;">Cancel</button>
        <button [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase"
          (click)="saveMonthlyData('save');confirmMsg = false;">{{buttonText}}</button>
      </div>
    </div>
  </div>
</div>

<!-- Error data download popup -->
<!-- <div  class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title" *ngIf="successDataCount>0">{{successDataCount}} {{pageRef}} data uploaded successfully.</h4>
          <h4 class="modal-title" *ngIf="successDataCount==0">No {{pageRef}} data uploaded</h4>
          </div>
          <div *ngIf="failedData.length>0" class="modal-body">
                  <p class="mb-0">{{failedData.length}} error data found.</p>
                  <p class="mb-0">Click on download to view the error details</p>
          </div>
          <div  class="modal-footer">
              <button *ngIf="failedData.length>0" type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadError();">Download</button>
              <button type="button" class="btn btn-outline-primary btn-sm" (click)="downloadMsg = false;bulkDelete()">Close</button>
          </div>
      </div>
  </div>
</div> -->

<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg"></app-info-popup>
</ng-container>
<app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted" [body]="body" [processingText]='"Proccessing...."'
  [isProgressStart]="isProgressStart" [heading]="popupHead"></app-upload-progress-bar>
<app-error-download [fileName]="downloadFile" [successDataCount]="successDataCount"
  [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="modelHead"
  (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>
