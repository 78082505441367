import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Params } from '@angular/router';
import * as XLSX from 'xlsx';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MessageService } from 'src/app/message.global';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-lop-reversal',
  templateUrl: './lop-reversal.component.html',
  styleUrls: ['./lop-reversal.component.scss']
})
export class LopReversalComponent implements OnInit {
  status = "Unprocessed"
  submitted = false;
  searchString:any = "";
  year:any;
  month:any;
  permissions:any = [];
  fileName: string = '';
  fileSize: string = '';
  dataType: string = '';
  ws:any= null;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  data:any = [];
  preparedData:any = [];
  page = 1;
  pageSize = 20;
  keyArray:any = [];
  inputData:any =[];
  failedData:any =[];
  successData:any =[];
  successDataCount=0;
  downloadMsg = false;
  deleteToggle = false;
  deleteMsg = "";
  showSidePanel    = false;
  Isalert          = true;
  empDD :any[]     = [];
  validatedLopData:any = [];
  invalidFormat    = false;
  exportData:any      = [];
  selctedData:any     = [];
  submiited           = false;
  tempData:any        = [];
  tempSelect:any      = [];
  ErrorData:any       = [];
  addData:any         = [];
  listOfMonth:string              = "listOfMonth";
  listOfMonthkey = this.messageService.selectplaceholddisplay('Month');
  listOfYearkey = this.messageService.selectplaceholddisplay('Year');
  payrollData:any = []
  payrollYears:any = []
  confirmMsg          = false;
  saveData:any        = [];
  confirmMsgText      = "";
  saveComplete        = false;
  statusFlag          = false;
  alertToggle         = false;
  empalertMsg         = "The selected employee(s) payroll has already been Processed/Freezed/Held/Published. Kindly undo the respective action to add/remove LOP reversal";
  isDelete            = false;
  deleteClicked       = false;
  from:any            = '';
  delArray :any       = [];
  rowData:any
  company:any;
  historyData:any = []
  loader = true
  inputdata :any
  errorMsg = ''
  successMsg = ''
  body = '';
  isProgressStart = false
  chunkLen = 10
  percentage:any = 0;
  iscompleted = false;
  isDataUpload = 0;
  constructor(public appService: AppService, public route:ActivatedRoute, public sds:SalaryDashboardService, private notificationService: NotificationService,public messageService : MessageService) { }

  ngOnInit(): void {
    this.getPermission();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.getEmployeeList();
    this.historyList()
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/run-payroll');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: false,cellDates:true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        this.ws = wb.Sheets[wsname];

        var ws = wb.Sheets[wsname];
        this.fileName = target.files[0].name;
        this.fileSize = this.appService.formatSizeUnits(target.files[0].size)
        // this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: false,rawNumbers: false, dateNF:'MMMM YYYY',blankrows:false}));
        this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: false,rawNumbers: false, dateNF:'YYYY',blankrows:false}));
        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }

  processData(){
    if(this.data.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.clearData();
    } else {
      var headerRow = this.data[0];
      let Heading =['Employee Code','Salary month',	'Salary Year','LOP Year', 'LOP Month', 'LOP reversal days', 'Comment']
      let success = Heading.every(function(val:any) {
        return headerRow.indexOf(val) !== -1;
      });
      if(success == false){
        this.deleteToggle = true;
        this.deleteMsg = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
      this.prepareUploadData();
    }
  }

  toMonthName(monthNumber:any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }
  prepareUploadData(){
    this.submiited     = false;
    this.data.forEach((item:any, key:any) => {
      if(key != 0){
        // this.preparedData[item[0]+item[1].replaceAll(' ','')] = item;
        let k = item[0]+item[1].replaceAll(' ','')
        this.keyArray.push({k : key-1,v:k});
        let excelMonth:any = ""
        if(isNaN(item[2])){
          excelMonth = item[2]
        }else{
          excelMonth = this.toMonthName(item[2])
        }
        let lopMonth:any = ""
        if(isNaN(item[4])){
          lopMonth = item[4]
        }else{
          lopMonth = this.toMonthName(item[4])
        }
        if(this.month == excelMonth && this.year == item[3] ){
          this.inputData.push({
            "index":key-1,
            "employee": item[0]?item[0]:"",
            "lop_month": item[4]?lopMonth:"",
            "lop_year": item[5]?item[5]:"",
            "reversal_lop": item[6]?item[6]:0,
            "comment": item[7]?item[7]:"",
          })
        }
      }
    });
    if(this.inputData.length <=0){
      this.deleteToggle = true;
      this.deleteMsg = "The uploaded file do not contain any data for "+this.month+" "+this.year;
      this.clearData();
    }
  }
  uploadData(){
    let uploadData:any = []
    this.submiited     = true;
    this.status="Unprocessed"
    this.inputData.forEach((item:any, key:any) => {

        uploadData.push({
          "employee": item.employee?item.employee:"",
          "lop_month": item.lop_month?item.lop_month:0,
          "lop_year": item.lop_year?item.lop_year:0,
          "reversal_lop": item.reversal_lop?item.reversal_lop:0,
          "comment": item.comment?item.comment:"",
          "payroll_month": this.month,
          "payroll_year":  this.year,
        })
    });

    this.sds.getactuallopdata ({ "company_id" : this.company,"data":uploadData}).subscribe((res: any) => {
      this.showSidePanel  = false;
      for(let i=0;i<res.body.error_info.length;i++){
        this.validatedLopData.push(res.body.error_info[i]);
        this.validateData('reversal_lop',this.validatedLopData.length-1)
        this.validateData('comment',this.validatedLopData.length-1)
      }
      for(let i=0;i<this.validatedLopData.length;i++){
        this.validatedLopData[i]

        this.selctedData.push(this.validatedLopData[i]['employee_id']);
      }

      this.payrollData.push(  res.body.error_info[0]['payroll_data']?res.body.error_info[0]['payroll_data']:[]);
      this.payrollYears[this.payrollData.length-1] = []
      this.payrollData[this.payrollData.length-1]?.forEach((pData:any) => {
        if(this.payrollYears[this.payrollData.length-1] == undefined || !this.payrollYears[this.payrollData.length-1].includes(pData.pay_year))
          this.payrollYears[this.payrollData.length-1]?.push(pData.pay_year)
      })

    });
  }
  getEmployeeList(){
    this.sds.employeeList(this.company ).subscribe((res: any) => {
        this.empDD = res.body;
        let len = this.empDD.length
        for (let i = 0; i < len; i++) {
          this.empDD[i]['fullname']  = "";
          if(this.empDD[i]['first_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
           if(this.empDD[i]['middle_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['middle_name']+" ";
          if(this.empDD[i]['last_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['last_name'];
           this.empDD[i]['fullname']   +=" ("+this.empDD[i]['employee_code']+")";


          }

    });
  }
  addEmployee(){
   let initialData:any  =
    {
      "checked":false,
      "employee": {
        "data": "",
        "is_valid": false,
        "error": null
       },
      "lop_month": {
          "data": null,
          "is_valid": false,
          "error": null
      },
      "lop_year": {
          "data": null,
          "is_valid": false,
          "error": null
      },
      "reversal_lop": {
          "data": 0,
          "is_valid": false,
          "error": null
      },
      "comment": {
          "data": "",
          "is_valid": false,
          "error": null
      },
      "employee_id": null,
      "actual_lop": '',
      "payroll_month": {
        "data": this.month,
        "is_valid": false,
        "error": null
    },
      "payroll_year": {
        "data": this.year,
        "is_valid": false,
        "error": null
    },
    "payroll_status" :''
    }
    this.addData.push(this.validatedLopData.length);
    this.validatedLopData.push(initialData);
    this.page = Math.ceil(this.validatedLopData.length / this.pageSize);
  }
  setEmpCode(data:any,index_withPage:any){
    let addempdata:any = [];
    // let index_withPage = this.pageSize*(this.page-1)+ i;
    let employee = this.empDD.find(item => item?.id === data?.id);
    let reset    = false;

    this.validatedLopData[index_withPage]['employee'].data      = employee?.employee_code;
    this.validatedLopData[index_withPage]['employee'].is_valid  = false;
    this.validatedLopData[index_withPage]['employee_name']      = employee?.first_name+" "+(employee?.middle_name!=null?employee.middle_name+" ":" ")+employee?.last_name;
    this.validatedLopData[index_withPage]['employee_code']     = employee?.employee_code;

    // To get actual lop in add employee row
    this.validatedLopData[index_withPage]['reversal_lop'].data  = 0;
    this.validatedLopData[index_withPage]['comment'].data       = '';

    addempdata.push({
      "employee": this.validatedLopData[index_withPage]['employee'].data,
      "lop_month": this.validatedLopData[index_withPage]['lop_month'].data,
      "lop_year": this.validatedLopData[index_withPage]['lop_year'].data,
      "reversal_lop": this.validatedLopData[index_withPage]['reversal_lop'].data,
      "comment": this.validatedLopData[index_withPage]['comment'].data,
      "payroll_month": this.month,
      "payroll_year": this.year,
    })
    this.sds.getactuallopdata ({ "company_id" : this.company,"data":addempdata}).subscribe((res: any) => {
        this.validatedLopData[index_withPage]['actual_lop']      = res.body.error_info[0].actual_lop;
        this.validatedLopData[index_withPage]['employee'].is_valid= res.body.error_info[0]['employee'].is_valid;
        this.validatedLopData[index_withPage]['employee'].error= res.body.error_info[0]['employee'].error;
        this.validatedLopData[index_withPage]['lop_month'].is_valid= res.body.error_info[0]['lop_month'].is_valid;
        this.validatedLopData[index_withPage]['lop_month'].error  = res.body.error_info[0]['lop_month'].error;
        this.validatedLopData[index_withPage]['payroll_status']    = res.body.error_info[0]['payroll_status'];

        this.payrollData[index_withPage] = res.body.error_info[0]['payroll_data']?res.body.error_info[0]['payroll_data']:[]

        this.payrollYears[index_withPage] = []
        this.payrollData[index_withPage]?.forEach((pData:any) => {
          if(this.payrollYears[index_withPage] == undefined || !this.payrollYears[index_withPage].includes(pData.pay_year))
            this.payrollYears[index_withPage]?.push(pData.pay_year)
        })
    })

    Object.entries(this.selctedData).forEach(([key, value]) => {
      if(key==index_withPage){
        this.selctedData[key] = data;
        reset = true;
        }
    })
    if(reset==false)
    this.selctedData.push(data);

  }
  getlopmonth(data:any,index_withPage:any){
    let lopmonthdata:any=[];
    let actuallop :any;
    // let index_withPage = this.pageSize*(this.page-1)+ i;
    lopmonthdata.push({
      "employee": this.validatedLopData[index_withPage]['employee'].data,
      "lop_month": this.validatedLopData[index_withPage]['lop_month'].data,
      "lop_year": this.validatedLopData[index_withPage]['lop_year'].data,
      "reversal_lop": this.validatedLopData[index_withPage]['reversal_lop'].data,
      "comment": this.validatedLopData[index_withPage]['comment'].data,
      "payroll_month": this.month,
      "payroll_year": this.year,
    })
    if((this.validatedLopData[index_withPage]['employee'].data) != ''){
     this.sds.getactuallopdata ({ "company_id" : this.company,"data":lopmonthdata}).subscribe((res: any) => {
      actuallop = res.body.error_info[0].actual_lop;
      this.validatedLopData[index_withPage]['actual_lop']      = actuallop?.toFixed(2);
      this.validatedLopData[index_withPage]['employee'].is_valid= res.body.error_info[0]['employee'].is_valid;
      this.validatedLopData[index_withPage]['employee'].error  = res.body.error_info[0]['employee'].error;
      this.validatedLopData[index_withPage]['lop_month'].is_valid= res.body.error_info[0]['lop_month'].is_valid;
        this.validatedLopData[index_withPage]['lop_month'].error  = res.body.error_info[0]['lop_month'].error;
    })
    }
  }

  deleteRow(index:any){
    this.loader = true
    // let index   = this.pageSize*(this.page-1)+ i;
    if(this.status=='Unprocessed'){
      this.validatedLopData.splice(index, 1);
      this.selctedData.splice(index, 1);
      this.inputData.splice(index, 1);
      for(let i=0;i<this.addData;i++){
        if(this.addData[i]==index)
        this.addData.splice(i,1)
      }
      this.loader = false
    } else if( this.historyData[index].payroll_status=='Unprocessed'){
      this.rowData = {
        "section": "lop_reversal",
        "delete_id_list": [ this.historyData[index].id]
        }
      this.confirmRowDel('single');
      this.loader = false
    }
    else if( this.historyData[index]?.payroll_status!='' &&  this.historyData[index]?.payroll_status!=null){
      this.loader     = false;
      this.alertToggle = true;
    }
  }
  confirmRowDel(source:any){
    this.isDelete = true;
    this.from     = source;
  }
  deleteLOPReversal(){
    this.deleteClicked  = false;
    if(this.from=='single'){
      this.deleteHistory(this.rowData)

    }
    else{
      let data = {
        "section": "lop_reversal",
        "delete_id_list": this.delArray
        }
      this.deleteHistory(data);

    }
  }

  validateData(field:any,index:any){

    if(field=='comment'){
      let data = this.validatedLopData[index]['comment'].data;
      const unamePattern = /^(?! )+[A-Za-z0-9 @#&_+\-\;':"\\,\/]*[A-Za-z0-9@#&_+\-\;':"\\,\/]$/.test(this.validatedLopData[index]['comment'].data);
      // const unamePattern = true
      if(unamePattern){
        if(data.length>100){

          this.validatedLopData[index]['comment'].is_valid = true;
          this.validatedLopData[index]['comment'].error    = this.messageService.fieldlengthvalidation('word',500);

        }
         else
         this.validatedLopData[index]['comment'].is_valid = false;
      }
      else{
        this.validatedLopData[index]['comment'].is_valid = true;
        this.validatedLopData[index]['comment'].error    = this.messageService.validationDisplay('pattern');
        // this.validatedLopData[index]['comment'].is_valid = false;

      }

    }
    else if(field=='reversal_lop'){
      let lop = this.validatedLopData[index]['reversal_lop'].data;
      const NumberPattern = /^[0-9]\d*(\.[5])?$/.test(lop);
      if(NumberPattern){
        if(lop<=31){
          if(Number(lop) <= Number(this.validatedLopData[index].actual_lop) && Number(this.validatedLopData[index].actual_lop)>0){

            this.validatedLopData[index]['reversal_lop_pattern'] = false;
          }else if(Number(lop) > Number(this.validatedLopData[index].actual_lop)){

            this.validatedLopData[index]['reversal_lop_pattern'] = true;
            this.validatedLopData[index]['reversal_lop_msg']     = this.messageService.lessThanMessage('Actual LOP days', 'LOP Reversal days');
          }else{

          }
        }
        // else{
        //     this.validatedLopData[index]['reversal_lop_pattern'] = true;
        //     this.validatedLopData[index]['reversal_lop_msg']     = this.messageService.fieldlengthvalidation('days',31);
        // }

      } else if(lop == ""){
        this.validatedLopData[index]['reversal_lop_pattern'] = false;
        this.validatedLopData[index]['reversal_lop_msg']     = ""
      }
      else{
        this.validatedLopData[index]['reversal_lop_pattern'] = true;
        this.validatedLopData[index]['reversal_lop_msg']     = this.messageService.validationDisplay('pattern');

      }

    }

  }
  filterIt() {
    if(this.status=='Unprocessed'){
      return this.validatedLopData?.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey?.includes(tempSearch);
          }
        });
      });
    } else {
      return this.historyData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey?.includes(tempSearch);
          }
        });
      });
    }
  }

  filteredcount(){
    return this.filterIt().length;
  }
  saveLOPreverseData(){
    this.isDataUpload = 1;
    this.iscompleted = false
    this.loader       = true
    this.saveComplete = true;
    this.percentage   = 0;

    if(this.saveData.length>0){
        this.isProgressStart = true
        this.failedData = []
        var l = this.saveData.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.successDataCount = 0
        this.processUpload(0,p);
    } else
    this.loader       = false
    this.alertToggle  = false;
}

processUpload(k:any,p:any){
    var l = this.saveData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveData[i])
    }

    this.sds.savelopreverse({ company_id : this.company,"data":saveDataArray}).subscribe((res: any) => {
      this.body = x+' out of '+l+" data uploaded successfully"
        this.confirmMsg = false;
        if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
            res.body.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
        }
        // this.successDataCount += res.body.succesd_data_count;
        this.percentage +=((p/4)*3);
        if(x<l)
            this.processUpload(k+1,p);
        else{
          this.iscompleted = true
              this.isProgressStart = false
            this.successDataCount = this.saveData.length- this.failedData.length;
            if(this.failedData.length == 0){
                this.notificationService.handleSuccessNotification(this.successDataCount+" LOP reversal data uploaded successfully.","Success");
                if(this.validatedLopData.length == this.saveData.length)
                  this.CompleteUpload();
                else
                    this.bulkDelete()
            } else {
                this.downloadMsg = true;
                this.exportData    = [];
                for (let i = 0; i < this.failedData.length; i++) {
                  this.exportData.push({'Employee code':this.failedData[i]['data']['employee'], 'LOP Days':this.failedData[i]['data']['actual_lop'], 'Comment':this.failedData[i]['data']['comment'],'Error description':this.failedData[i]['error']});
                }
                this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                this.errorMsg = this.failedData.length+' employee(s) have error data'
            }
            this.historyList()
        }

    },
    (error:any)=>{
      this.isProgressStart = false
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    }
    );
}
  // saveLOPreverseData(){
  // this.submitted    = true;
  // this.saveComplete = true;


  //   this.sds.savelopreverse({ "company_id" : this.company,"data":this.saveData}).subscribe((res: any) => {

  //     this.failedData       = res.body.failed_data;
  //     this.confirmMsg       = false;

  //     this.successDataCount = this.saveData.length- this.failedData.length;
  //     if(this.failedData.length == 0){
  //         this.notificationService.handleSuccessNotification(this.successDataCount+" LOP data uploaded successfully.","Success");
  //         if(this.validatedLopData.length == this.saveData.length)
  //           this.CompleteUpload();
  //         else
  //           this.bulkDelete()
  //     } else {
  //       this.downloadMsg = true;
  //     }
  //     this.historyList()
  //   })
  // }
  downloadError(){

    for (let i = 0; i < this.failedData.length; i++) {
      this.exportData.push({'Employee Code':this.failedData[i]['data']['employee'], 'LOP Month':this.failedData[i]['data']['lop_month']+' '+this.failedData[i]['data']['lop_year'], 'LOP reversal days':this.failedData[i]['data']['reversal_lop'], 'Comment':this.failedData[i]['data']['comment'],'Error description':this.failedData[i]['error']});
    }
    this.appService.exportExcel(this.exportData,'LOP Reversal Error');
    this.downloadMsg = false;
    this.CompleteUpload();

  }
  checkSuccessData(){
    if(this.successData.length==0)
      this.clearData()
  }
  CompleteUpload(){
    this.fileName      = '';
    this.data          = [];
    this.fileSize      = '';
    this.validatedLopData = [];
    this.selctedData   = [];
    this.addData       = [];
    this.submiited     = false;
    this.inputData     = [];
    this.successData   = [];
    this.keyArray      = [];
    this.preparedData  = [];
    this.exportData    = [];
  }
  clearData(){
    this.tempData      = this.validatedLopData;
    this.tempSelect    = this.selctedData;
    this.validatedLopData = [];
    this.selctedData   = [];
    this.data          = [];
    this.inputData     = [];
    this.successData   = [];
    this.keyArray      = [];
    this.preparedData  = [];
    this.submiited     = false;

    for(let i=0;i<this.addData.length;i++){
      this.validatedLopData.push(this.tempData[this.addData[i]]);
      this.selctedData.push(this.tempSelect[this.addData[i]]);
    }
    this.page = 1;
    this.fileName      = '';
    this.fileSize      = '';
  }

  cleardoc(){
    if(this.validatedLopData.length ==0){
      this.fileName      = '';
      this.fileSize      = '';
    }
  }

  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item: { checked: any; }) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  historyList(){
    this.sds.attendanceHistory(this.year,this.month,this.company,"lop_reversal").subscribe((res:any)=>{
      this.historyData = res.body.data;
      this.loader = false
     });
  }
  deleteHistory(data:any){
    this.sds.deleteHistory(data).subscribe((res:any)=>{
      this.isDelete = false;
      this.historyList()
     });
  }

  bulkDelete(){
    this.loader = true
    this.delArray = [];
    if(this.status == 'Unprocessed'){
      this.validatedLopData.forEach((item:any, value:any) => {
        if(item?.checked == true)
        this.delArray.push(value)
      });
      var delArrayRev = this.delArray.reverse()
      delArrayRev.forEach((item:any, value:any) =>{
        this.deleteRow(item);
      })
      this.loader = false
    } else {
      this.historyData.forEach((item:any, value:any) => {
        if(item?.checked == true && item?.payroll_status=='Unprocessed')
        this.delArray.push(this.historyData[value].id)
        else if(item?.checked == true && item?.payroll_status!='Unprocessed')
          this.statusFlag = true;

      });
      if(this.statusFlag==true)
      {

        this.alertToggle = true;
        if(this.delArray.length==0)
        this.loader = false;
      }
      if(this.delArray.length){
        this.confirmRowDel('bulk');
      }
    }


  }
  countSelected(){
    var delArray:any = [];
    if(this.status == 'Unprocessed'){
      this.validatedLopData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
    } else {
      this.historyData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(this.historyData[value].id)
      });
    }
    return delArray.length
  }
  selectAll(){
    this.inputdata = this.appService.selectAll(this.validatedLopData)


  }
  unselectAll(){
    this.inputdata = this.appService.unselectAll(this.validatedLopData)


  }
  confirmation(){
    this.saveData     = [];
    this.saveComplete = false;

    for(let i=0;i<this.validatedLopData.length;i++) {
      if(this.validatedLopData[i]?.checked == true && (this.validatedLopData[i]?.payroll_status=='Unprocessed' || this.validatedLopData[i]?.payroll_status=='' || this.validatedLopData[i]?.payroll_status==null)){

        this.saveData.push({

        "employee"    : this.validatedLopData[i]['employee'].data,
        "lop_month"   : this.validatedLopData[i]['lop_month'].data,
        "lop_year"    : this.validatedLopData[i]['lop_year'].data,
        "actual_lop"  : Number(this.validatedLopData[i].actual_lop),
        "reversal_lop"  : Number(this.validatedLopData[i]['reversal_lop'].data),
        "comment"     : this.validatedLopData[i]['comment'].data,
        "payroll_month": this.month,
        "payroll_year": this.year,
        })
      }
      else if(this.validatedLopData[i]?.checked == true && this.validatedLopData[i]?.payroll_status!='' && this.validatedLopData[i]?.payroll_status!=null && this.validatedLopData[i]?.payroll_status!='Unprocessed'){
        this.statusFlag = true;
      }
    }

    if(this.saveData.length>0){
      this.confirmMsgText ="Click the Save button, If you want to add the LOP reversal for the selected employees.";
      this.confirmMsg     = true;
    }
    if(this.statusFlag==true)
      this.alertToggle = true;

  }
  closeInfo(bool:any){
    this.alertToggle = bool;
    this.loader      = false;

  }
}
