import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-pc-tax-details',
  templateUrl: './pc-tax-details.component.html',
  styleUrls: ['./pc-tax-details.component.scss']
})
export class PcTaxDetailsComponent implements OnInit {

  iscomputation                 = false;
  istaxable_limit               = false;
  isrequired                    = true;
  taxSubmit                     = false;
  sectionSubmit                 = false;
  taxConfig:string              = "tax_config";
  incomeSection:string          = "income_section";
  taxConfigPlaceHolder          = this.messageService.selectplaceholddisplay('tax configuration');
  incomePlaceHolder             = this.messageService.selectplaceholddisplay('section');
  submitted : boolean           = false;
  @Input()disabled              = false;
  @Output() submitmethod        = new EventEmitter();
  activeIncome                  = true;
  activeLimit                   = true;
  limitRestriction              = ['Child Education Allowance','Child Hostel Allowance'];

  saveClicked = false
  @Input()userDefinedAdd:any;
  @Input()taxrestricted:any;
  @Input()sectionIncomeDisable:any;
  @Input()tax_config_disable:any;
  @Input()defaultCompName:any;
  @Input()formGp : FormGroup    = this.fb.group({
                                      tax_configuration       : [''],
                                      non_taxable_limit       : [''],
                                      tax_computation         : [''],
                                      under_section_income    : ['']
                                });

  constructor(private fb:FormBuilder,public messageService : MessageService,

    ) { }

  ngOnInit(): void {

    if(this.formGp.controls['under_section_income'].disabled){

      this.sectionIncomeDisable = true;
    }

    if(this.formGp.controls['non_taxable_limit'].disabled || this.limitRestriction.includes(this.defaultCompName)){

      this.activeLimit = false;
    }
    else{

      this.activeLimit = true;
    }
    if(this.defaultCompName!='Reimbursement' && this.defaultCompName!='LTA' && this.defaultCompName!='Leave Encashment'){


      if((this.formGp.getRawValue().tax_configuration=='Taxable') && this.formGp.getRawValue().tax_configuration!=null)
      {
        this.iscomputation    = this.userDefinedAdd;
        this.istaxable_limit  = false;
      }

      else if((this.formGp.getRawValue().tax_configuration=='Non-Taxable') && this.formGp.getRawValue().tax_configuration!=null){
        this.iscomputation    = this.userDefinedAdd;
        this.istaxable_limit  = false;
      }
      else if((this.formGp.getRawValue().tax_configuration=='Partially Taxable') && this.formGp.getRawValue().tax_configuration!=null){

        if(this.formGp.getRawValue().non_taxable_limit==0)
          this.formGp.get('non_taxable_limit')?.setValue(null);
        this.iscomputation    = this.userDefinedAdd;
        this.istaxable_limit  = this.userDefinedAdd?true:false;

      }
    }
    else{

        this.iscomputation    = true;
        this.istaxable_limit  = true;
        this.activeLimit      = true;
        if(this.formGp.get('non_taxable_limit')?.value==null || this.formGp.get('non_taxable_limit')?.value==''){
            this.formGp.get('non_taxable_limit')?.setValue(0);
        }


    }
    this.setUserdefinedData()
    this.setValidator();
    this.setValidatorIncome();
    if(this.sectionIncomeDisable==true){
      this.formGp.get('under_section_income')?.disable();
      this.formGp.get('tax_configuration')?.disable();
      this.activeIncome     = true;

    }
    this.setValidatorIncome();
  }
  validateTax(){

    if (this.formGp.invalid) {
      return;
    }
    this.saveClicked = true
    this.submitmethod.emit();
  }
  sectionChange(){
    this.sectionSubmit = false;
    if(this.f.under_section_income.value ==null){
      this.sectionSubmit = true;
    }

  }

  get f() { return this.formGp.controls; }

  setTaxableLimit(){

    if(this.defaultCompName!='Reimbursement' && this.defaultCompName!='LTA' && this.defaultCompName!='Leave Encashment'){

      if((this.formGp.getRawValue().tax_configuration=='Taxable'))
      {
        this.iscomputation    = this.userDefinedAdd;
        this.istaxable_limit  = false;
        // this.formGp.get('non_taxable_limit')?.setValue(0);

      }

      else if((this.formGp.getRawValue().tax_configuration=='Non-Taxable')){

        this.iscomputation    = this.userDefinedAdd;
        this.istaxable_limit  = false;


      }
      else if((this.formGp.getRawValue().tax_configuration=='Partially Taxable') ){
        this.iscomputation    = this.userDefinedAdd;
        this.istaxable_limit  = this.userDefinedAdd?true:false;
        this.formGp.get('non_taxable_limit')?.setValue(null);

      }


      else if(this.f.tax_configuration.value ==null){
        this.taxSubmit = true;

      }
    }
    else{
      this.iscomputation    = true;

    }
    this.setUserdefinedData()
    this.setValidator();
    this.setValidatorIncome();

  }
  setUserdefinedData(){
    if(this.defaultCompName=='User Defined'){
      if(this.formGp.getRawValue().tax_configuration!='Non-Taxable')
      this.formGp.get('under_section_income')?.setValue('Section 17(1)')
      else
      this.formGp.get('under_section_income')?.setValue('')

    }
  }

  setValidator(){
    if(this.istaxable_limit==true && this.activeLimit == true){
      this.formGp.get('non_taxable_limit')?.setValidators([NumberpatternValidator(),Validators.required,Validators.maxLength(10)])

    }
    else {
      this.formGp.get('non_taxable_limit')?.clearValidators();
      this.formGp.get('non_taxable_limit')?.setValue(null);

    }
    this.formGp.controls['non_taxable_limit'].updateValueAndValidity();
  }
  setValidatorIncome(){
    if(this.formGp.get('under_section_income')?.value!='' && this.formGp.get('under_section_income')?.value!=null){
      this.formGp.get('under_section_income')?.setValidators([Validators.required])
    }
    else {
      this.formGp.get('under_section_income')?.clearValidators();
      this.formGp.get('under_section_income')?.setValue('');
    }
    this.formGp.controls['under_section_income'].updateValueAndValidity();
  }

}
