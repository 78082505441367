<!-- Doc Preview Start -->
<div class="modal  modal-pdfviewer modal-custom {{modalToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title w-18ch text-trim" title="{{currentDocName}}">{{currentDocName}}</h4>
        <i (click)="close()" class="ms-auto bi b bi-x-lg text-light-500"></i>
      </div>
      <app-table-loader *ngIf="loader1"></app-table-loader>
      <div class="modal-body px-0">
        <div class="pdf-container" *ngIf="currentExtension=='pdf' || currentExtension=='PDF'">
          <pdf-viewer [src]="fileName" [render-text]="false" [original-size]="false" (on-progress)="showGif($event)" style="height: calc(100% - 22px)!important;">
          </pdf-viewer>
        </div>
        <div class="pdf-container" *ngIf="currentExtension=='doc' || currentExtension=='docx'">

          <ngx-doc-viewer
            [url]="fileName"
            viewer="google"
            style="height: calc(100% - 22px)!important;">
          </ngx-doc-viewer>

        </div>

        <img
          *ngIf="currentExtension!='pdf' && currentExtension!='PDF' && currentExtension!='zip' && currentExtension!='docx' && currentExtension!='xlsx' && currentExtension!='csv'"
          [src]="fileName" style="padding: 1.3125rem;width: 100%;min-height: 50vh;" class="img-fluid">
      </div>
    </div>
  </div>
</div>
