<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row mb-16">
      <div class="col-md-4 col-lg-6">
        <p class="text-uppercase fw-500 mb-0">Summary</p>
      </div>
    </div>
    <div class="row row-16">
      <div class="col-12">
        <div class="card details-card p-16">
          <div class="row row-16">
            <div class="col-12">
              <div class="title">REport Deatails</div>
            </div>
            <div class=" col-md-3">
              <div class="detail-title">Report name</div>
              <div class="detail-desc">{{formGp.value?.name}}</div>
            </div>
            <div class=" col-9">
              <div class="detail-title">Business purpose</div>
              <div class="detail-desc">{{formGp.value?.business_purpose}}</div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="detail-title">From date</div>
              <div class="detail-desc"> {{this.appService.dateFormatDisplay(formGp.value?.from_date)}}</div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="detail-title">To date</div>
              <div class="detail-desc"> {{this.appService.dateFormatDisplay(formGp.value?.to_date)}}</div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="detail-title">Amount </div>
              <div class="detail-desc">{{formGp.value?.total_amount | currency : currency}}</div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="detail-title">Amount to be reimbursed</div>
              <div class="detail-desc">{{formGp.value?.to_reimbursed | currency : currency}}</div>
            </div>
            <!-- <div class="col-sm-3 col-12">
              <div class="detail-title">Trip</div>
              <div class="detail-desc">Trip 1</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card details-card p-16">
          <div class="row row-16">
            <div class="col-12">
              <div class="title">EXPENSE RECEIPT</div>
            </div>
            <div class="col-12">
              <div class="card card-c2">
                <div class="table-responsive radius-4">
                  <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                    <thead>
                      <tr>
                        <th class="fw-600 text-uppercase" scope="col">Receipt number</th>
                        <th class="fw-600 text-uppercase" scope="col">EXPENSE Date</th>
                        <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                        <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                        <th class="fw-600 text-uppercase" scope="col">Report name</th>
                        <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 ">

                      <ng-container *ngIf="receiptListData?.length == 0">
                        <tr>
                          <td colspan="6">
                            <div class="card card-c2 flex-center p-16 bg-dark-500">
                              <span class="fw-500 fs-14">No data found</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>

                      <ng-container *ngFor='let receipt of $any(receiptListData|filter:searchString1| slice: (page1-1) * pageSize1 : (page1-1) * pageSize1 + pageSize1);let i=index;'>
                        <tr [class]="receipt?.is_expired ? 'td-alert' : ''">
                          <td>{{receipt?.number}}

                            <i *ngIf="receipt?.is_expired" class="fs-14 ms-8 icon-info-circle text-danger" ngbPopover="Expired" popoverClass="popover-default popover-info " container="body" triggers="hover"></i>

                          </td>
                          <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(receipt?.expense_date)}}</span></td>
                          <td>{{receipt?.category?.name}}</td>
                          <td>{{receipt?.amount | currency : currency}}</td>
                          <td>{{receipt?.report?.name}}</td>
                          <td *ngIf="(receipt?.attachments == null || receipt?.attachments?.length == 0)">---</td>
                          <td *ngIf="(receipt?.attachments != null && receipt?.attachments?.length > 0)"><span class="link-primary"><i class="icon-eye fs-18 " (click)="viewAttachmentFunction(i,receipt?.id)"></i></span></td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="this.appService.filteredcount(receiptListData,searchString1)>pageSize1">
              <ngb-pagination class="d-flex justify-content-end"
                [collectionSize]="this.appService.filteredcount(receiptListData,searchString1)" [(page)]="page1" [maxSize]="3"
                [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card details-card p-16">
          <div class="row row-16">
            <div class="col-12">
              <div class="title">ADVANCE DETAILS</div>
            </div>
            <div class="col-12">
              <div class="card card-c2">
                <div class="table-responsive radius-4">
                  <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                    <thead>
                      <tr>
                        <th class="fw-600 text-uppercase" scope="col">Advance number</th>
                        <th class="fw-600 text-uppercase" scope="col">Date</th>
                        <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                        <th class="fw-600 text-uppercase" scope="col">DISPENSED AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 ">

                      <ng-container *ngIf="advanceListData?.length == 0">
                        <tr>
                          <td colspan="4">
                            <div class="card card-c2 flex-center p-16 bg-dark-500">
                              <span class="fw-500 fs-14">No data found</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>

                      <ng-container *ngFor='let advance of $any(advanceListData|filter:searchString2| slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2);let j=index;'>
                        <tr>
                          <td>{{advance?.number}}</td>
                          <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(advance?.expense_date)}}</span></td>
                          <td>{{advance?.amount | currency : currency}}</td>
                          <td>{{advance?.dispansed_amount | currency : currency}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="this.appService.filteredcount(advanceListData,searchString2)>pageSize2">
        <ngb-pagination class="d-flex justify-content-end"
          [collectionSize]="this.appService.filteredcount(advanceListData,searchString2)" [(page)]="page2" [maxSize]="3"
          [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
      </div>

    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="saveClicked" (click)="changeTab()">SAVE</button>
  </footer>
</form>

<!-- Other components -->
<app-view-multiple-attachments *ngIf="viewAttchments" [from]="'expenseReportRequestSummary'" [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="receiptId" (closeViewAttachment)="closeViewAttachment()" (saveViewAttachment)="saveViewAttachment($event)"></app-view-multiple-attachments>
<!-- End -->
