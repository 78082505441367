import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-leave-eligibility',
  templateUrl: './leave-eligibility.component.html',
  styleUrls: ['./leave-eligibility.component.scss']
})
export class LeaveEligibilityComponent implements OnInit {

  constructor(public messageService : MessageService,public appService: AppService,public existDataService : ExistDatasService,public fb : FormBuilder,public leavetypeService:leavetypeService) { }

  @Input() leaveConfigForm: any;
  @Input() leaveTypeCode: any;
  @Input() leaveTypeName: any;
  @Input() leaveEligibiltyForm : any;
  @Input() mapLeaveType:any
  submitted = false;
  @Input() saveBtnClicked: any;

  empstatusDD = [
    {id:0,name:"Contractual"},
    {id:1,name:"Consultant"},
    {id:2,name:"Permanent"},
    {id:3,name:"Trainee"},
    {id:4,name:"Wages"}
  ];
  gender = [{id:0 ,name:'Male'},{id:1,name:'Female'},{id:2,name:'Other'}]


  ngOnInit(): void {
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.gender);
    this.addValidator();

  }
    // Select All
    selectAllForDropdownItems(items: any[]) {
      let allSelect = (items: any[]) => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
    }

 get f() { return this.leaveEligibiltyForm.controls; }

 clearcommonForm(item:any,index:any,i:any){
  if(item == "emp_status"){
    let daata = this.leaveEligibiltyForm.controls[i].controls.employment_type?.value;
    daata.splice(index, 1);
    this.leaveEligibiltyForm.controls[i].controls.employment_type?.patchValue(daata
    );
  }
  else if(item == "gender"){
    let daata = this.leaveEligibiltyForm.controls[i].controls.gender.value;
    daata.splice(index, 1);
    this.leaveEligibiltyForm.controls[i].controls.gender.patchValue(daata
    );
  }
 }
 addValidator(){
  this.setValidator('leave_applicable_emp_type','employment_type');
  this.setValidator('leave_applicable_gender','gender');
  this.setValidator('leave_waiting_status','waiting_period_days');
 }
 setValidator(parent:any,control:any){
  for(let i=0;i<this.leaveEligibiltyForm.controls.length;i++){
    if(this.leaveEligibiltyForm.controls[i].get(parent).value==true){
      if(parent=='leave_waiting_status'){
        this.leaveEligibiltyForm.controls[i].get(control)?.setValidators([Validators.required,NumberpatternValidator(),Validators.min(1),Validators.maxLength(3)]);
       if(this.leaveEligibiltyForm.controls[i].get('waiting_period_effective').value == '' || this.leaveEligibiltyForm.controls[i].get('waiting_period_effective').value == null){
        this.leaveEligibiltyForm.controls[i].get('waiting_period_effective')?.setValue('Calender days');
       }    
      }else{
      this.leaveEligibiltyForm.controls[i].get(control)?.setValidators([Validators.required])
      }
    }
    else{
      this.leaveEligibiltyForm.controls[i].get(control)?.clearValidators();
      this.leaveEligibiltyForm.controls[i].get(control)?.setValue(null);
      if(parent=='leave_waiting_status'){
        this.leaveEligibiltyForm.controls[i].get('waiting_period_days')?.reset();
        }
    }
    this.leaveEligibiltyForm.controls[i].get(control)?.updateValueAndValidity()
  }
 }


}
