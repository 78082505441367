import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function digitdecimalValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      // const digitdecimalpattern = /^\\d+(\\.\\d{1,3})?$/.test(value);
      const digitdecimalpattern = /^[0-9]\d*(\.\d{1,2})?$/.test(value);


      return !digitdecimalpattern ? {pattern:true}: null;
  }
}
export function singleDigitDecimalValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const digitdecimalpattern = /^[0-9]\d*(\.[5]?$)?$/.test(value);

      return !digitdecimalpattern ? {pattern:true}: null;
  }
}

export function noDecimalValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const NumberPattern = /^[0-9]*$/.test(value);

      return !NumberPattern ? {pattern:true}: null;
  }
}

export function twoDigitDecimalValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const digitdecimalpattern = /^[0-9][0-9]*(\.\d{1,2})?$/.test(value);


      return !digitdecimalpattern ? {pattern:true}: null;
  }
}



