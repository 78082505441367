import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-fnf-asset-approval',
  templateUrl: './fnf-asset-approval.component.html',
  styleUrls: ['./fnf-asset-approval.component.scss']
})
export class FnfAssetApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() requestitem:any;
  @Input() empId:any;
  @Input() archieved_status:any;
  @Input() loader:any;
  @Input() payrollStatus:any;

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();
  @Output() unprocessWarningPopup =  new EventEmitter();

  pointerEventArray:any      = [];
  unReturnedStatusArray: any = ['Missing'];
  viewInventory              = false;
  currentId:any              = "";
  finalAction:any            = '';
  submitted                  = false;
  loader1                    = false;

  constructor(
    public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService: MessageService,
    public appService: AppService,
    private notificationService: NotificationService,
  ) { }

  form = this.fb.group({
    read_status       : [true,Validators.required],
    approved_status   : [false,Validators.required],
    comment           : ['', Validators.maxLength(500)],
  })

  ngOnChanges(){
   this.loader1       = true;
   if(!this.loader){
    this.pointerEventArray = [];
    this.removeAllAssetArrays();
    if(this.requestitem?.return_assets?.length > 0){
      for(let i=0;i<this.requestitem?.return_assets?.length;i++){
        this.pointerEventArray?.push({['loader'+i]: false});
        if(this.requestitem?.return_assets[i]['fnf_status'] == 'Unreturned'){
          this.addAssetArray(this.requestitem?.return_assets[i]['id']);
          if( this.requestitem?.status != 'Pending')
            (<FormArray>this.unReturnedForm.get("assetArray"))?.controls?.[i]?.get('status')?.reset(this.requestitem?.return_assets[i]['fnf_asset_status']);
          else if(this.requestitem?.status == 'Pending'  && this.requestitem?.return_assets[i]['fnf_asset_status'] != '')
            (<FormArray>this.unReturnedForm.get("assetArray"))?.controls?.[i]?.get('status')?.reset(this.requestitem?.return_assets[i]['fnf_asset_status']);
        }
      }
    }
    this.loader1  = false;
   }
  }

  ngOnInit(): void {

  }

  get f() {return  this.form.controls; }

  unReturnedForm : FormGroup = this.fb.group({
    assetArray  : this.fb.array([])
  });

  assetArrays() : FormArray {

    return this.unReturnedForm.get("assetArray") as FormArray
  }

  newAssetArray(id:any): FormGroup {

    return this.fb.group({
      "status" : [null, [Validators.required]],
      "id"     : id
    })
  }

  addAssetArray(id:any) {
    this.assetArrays().push(this.newAssetArray(id));
  }


  removeAllAssetArrays(){

    if(this.unReturnedForm.value.assetArray?.length > 0){
      while(this.unReturnedForm.value.assetArray?.length !== 0){
        this.assetArrays().removeAt(0);
      }
    }
  }

  removeAssetArrays(id:any) {
    if(this.unReturnedForm.value.assetArray?.length > 0){
      for(let i=0;i<this.unReturnedForm.value.assetArray?.length;i++){
        if(Number((<FormArray>this.unReturnedForm.get("assetArray")).at(i).get('id')?.value) == Number(id) ){
          this.assetArrays().removeAt(i);
          return;
        }
      }
    }
  }

  undoAsset(id:any,status:any,remark:any,index:any,from:any){

    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      this.submitted = false;

      if(this.pointerEventArray?.length > 0 && from == 'return'){
        this.pointerEventArray[index]['loader'+index] = true;
      }
      this.finalSettlementService.assetProductUpdate({'asset_product_list': [id],'status': status,'asset_status': remark}).subscribe((res:any)=>{
        this.getInboxData();

        setTimeout(() => {
          if(this.pointerEventArray?.length > 0 && from == 'return'){
            this.pointerEventArray[index]['loader'+index] = false;
          }
        }, 1000);

      })
    }
  }


  getInboxData(){
    this.finalSettlementService.getSingleInboxData('Asset',this.data['id']).subscribe((res:any)=>{
      this.requestitem = res?.data;
    })
  }

  confirm(status:any){

    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      var type         = 'Asset Request';
      var returnFlag   = false;

      this.finalAction =='Rejected'

      this.form.get('approved_status')?.setValue(status);

      if (this.unReturnedForm.invalid) {
        this.submitted = true;
        return;
      }

      if(this.requestitem?.return_assets?.length > 0){
        for(let i = 0;i<this.requestitem?.return_assets?.length ;i++){
          if(this.requestitem?.return_assets[i]?.fnf_status == 'Pending' && this.requestitem?.return_assets[i]?.status != 'Invalid'){
            returnFlag = true;
            this.notificationService.handleErrorNotification(type+" cannot be submitted in pending state.","Pending "+type);
            return;
          }
        }
      }

      if(this.form.controls.comment.valid){
        this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
            if(res){
              this.refreshInbox(false);
              this.form.get('comment')?.reset('')

            }
          })
        }
    }
  }

  refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }


}
