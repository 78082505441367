import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChallanSettingsPfService } from 'src/app/@core/services/challan-settings-pf.service';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
@Component({
  selector: 'app-challan-settings-group-details',
  templateUrl: './challan-settings-group-details.component.html',
  styleUrls: ['./challan-settings-group-details.component.scss']
})

export class ChallanSettingsGroupDetailsComponent implements OnInit {

  @Input() formGp:any;
  private subjectKeyUp = new Subject<any>();

  @Input() employeeDropdown:any;
  @Input() from:any;

  @Output() submitmethod  = new EventEmitter();
  saveBtnClicked: boolean = false;

  editFlag                = false;
  pageName:any;
  fieldName:any;
  id                      = 0;
  value:any;
  uniqueValue:any;

  constructor(
    public fb:FormBuilder,
    public appservice:AppService,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public challanSettingsPfService: ChallanSettingsPfService,
    public existDataService :  ExistDatasService,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      if((params['pf'] == 'pf')){
        if(!isNaN(params['id'])){
          this.editFlag = true;
          this.id = +params['id'];
        }else{
          this.editFlag = false;
        }
        this.pageName  = 'PfChallanGroup';     

      }else{
        // ESI code
      }
      this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
        this.existService();
      })
    });

  }

  get f() {return  this.formGp.controls; }

  keyUp(from:any){
    if(from == 'name'){
      this.subjectKeyUp.next(this.f.name?.value);
      this.uniqueValue = this.f.name?.value;
      this.fieldName   = 'name';
    }
    else{
      if(this.from == 'pf'){
        this.fieldName = 'pf_number';
      }else{
        this.fieldName = 'esi_number';
        this.pageName  = 'EsiChallanGroup';
      }
      this.subjectKeyUp.next(this.f.number?.value)
      this.uniqueValue = this.f.number?.value;
    }
  }

  existService(){
    if(!this.editFlag){
      this.value ={
        "page"   : this.pageName,
        "field"  : this.fieldName,
        "value"  : this.uniqueValue
      }
    }else{
       this.value ={
        "id"     : this.id,
        "page"   : this.pageName,
        "field"  : this.fieldName,
        "value"  : this.uniqueValue
      }
    }

    if(this.uniqueValue != null && this.uniqueValue != ''){
      this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
        if(res.body.data === 'invalid' && (this.fieldName == 'pf_number' || this.fieldName == 'esi_number')){
          this.f.number.setErrors({
            'exist' : true
          })
        } else if(res.body.data === 'invalid' && this.fieldName == 'name'){
          this.f.name.setErrors({
            'exist' : true
          })
        }
      })
    }
  }

  validateForm() {
    this.saveBtnClicked = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

}
