import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WorkfromhomeService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  // Create
  wfhCreate(body:any){
    return this.http.post(`${this.API_PATH}request/api/v1/wfh-request-create/`,body, this.httpOption)
  }

  // FROM & TO days
  getwfhDays(from:any,to:any,id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/wfh-check-eligibility/${id}/?from_date=${from}&to_date=${to}`, this.httpOption)
  }
   // Get
   wfhGet(id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=Work From Home&request_id=${id}`, this.httpOption)
  }

}
