import { Component, OnInit } from '@angular/core';
import { EmailValidator, FormBuilder,  Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { OrganizationService } from 'src/app/@core/services/organization.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-email-header',
  templateUrl: './email-header.component.html',
  styleUrls: ['./email-header.component.scss']
})
export class EmailHeaderComponent implements OnInit {
  emailnameform:any;
  btnenable = false;
  submitted = false;
  currentdata:any=[];
  loader= false;
  email_name:any;
  permissions:any = [];

  constructor(
    public messageService : MessageService,
    public OrganizationService : OrganizationService,
    public formBuilder: FormBuilder,
    public appService: AppService
    ) { }

  ngOnInit(): void {
    this.getPermission();
    this.getmaildata();
    this.emailnameform = this.formBuilder.group({
      name: ['', [Validators.required, UnamepatternValidator(), Validators.maxLength(50)]]
    });
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/email-header');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getmaildata(){
    this.loader= true;
    this.OrganizationService.getemaildata()
    .subscribe((res: any) => {
     this.currentdata = res.success;
     this.emailnameform.get('name').setValue(this.currentdata.name);
     this.loader= false;
     if(this.permissions?.e == false){
      this.emailnameform.get('name')?.disable();
      this.btnenable = false;
     }
    })
  }
  savemailname(){
    this.btnenable = false;
    this.submitted = true;
    if(this.emailnameform.invalid)
    return;
    let myFormData ={
      'name' : this.emailnameform.value.name,
    }
    this.OrganizationService.updatemaildata(myFormData,this.currentdata.id)
    .subscribe((res: any) => {
    })
  }
  valuechange($event:any){
    this.btnenable = true;
  }
  get f() { return this.emailnameform.controls; }
 
}
