<div class=" config-layout">
  <header class=" config-header">
      <h5 class="m-0">Alerts - {{month}} {{year}}</h5>
      <button class="btn-reset text-light-600" routerLink="/attendance-dashboard/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">

<form class="">
  <div class="container-fluid p-32 form-container">
    <ng-container  *ngIf="!loader" >
      <div class="row row-16 mb-16">
        <div class="col-12 ">
          <span class="fw-500 text-uppercase">ALERTs - {{month}} {{year}}</span>
        </div>
        <ng-container *ngIf="!alertClose">

          <div class="col-12" >
            <div class="alert alert-danger show" role="alert" >
              Attendance can't be processed for employees with alert data
              <button class="btn-close" (click)="alertClose=true"></button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row row-24 row-cols-xl-5">
        <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let item of reminders;let ind = index" >
          <div class="card h-100 card-c2 card-hover card-highlight {{item.class}}"  (click)="showSidePanel = true;selectedList=item.list;selectedName=item.reminder">
            <div class="card-body p-16 text-center">
              <h6 class="fw-500  fs-20">{{item.listcount}}</h6>
              <hr class="hr-1 mb-16 mt-16">
              <div class="mb-0 fw-500 fs-12">{{item.reminder}}
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </ng-container>
    <app-c1-loader *ngIf="loader"></app-c1-loader>
  </div>
</form>
</main>
</div>
<ng-container *ngIf="showSidePanel">
  <app-non-mapped-empp [showSidePanel]="showSidePanel" [selectedList]="selectedList" [selectedName]="selectedName" (closePanel)="closePanel($event)"></app-non-mapped-empp>
</ng-container>
