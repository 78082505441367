import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function PfNumberValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      // const Pinpattern = /^[A-Z]{5}[0-9]{7}[0]{3}[0-9]{7}$/.test(value);

      const Pinpattern = /^[A-Z]{5}[0-9]{17}$/.test(value);

      return !Pinpattern ? {pattern:true}: null;
  }
}

