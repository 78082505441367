<form class="">
    <div class="container-fluid p-32 form-container" [formGroup]="formGp">
        <div class="row ">
            <p class="text-uppercase fw-500">{{dataId==0?'ADD':'EDIT'}} DETAILS</p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Subject </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Enter the Subject line for the document. The document will be displayed on the employee profile page with this subject line.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>

                <input class="form-control"  placeholder="{{this.messageService.placeholderdisp('subject')}}" formControlName="subject" [ngClass]="{ 'is-invalid': ( f.subject.touched || f.subject.dirty) && f.subject.errors || f.subject.errors?.exist}"  (keyup)="keyUp()">
                <div *ngIf="(f.subject.touched || f.subject.dirty) && f.subject.errors || f.subject.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.subject.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.subject.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="!(f.subject.errors.pattern) && f.subject.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf="f.subject.hasError('exist')">{{this.messageService.validationDisplay(f.subject.value)}}</div>

                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Description </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Enter a few lines of description on the document.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                <textarea  [ngClass]="{ 'is-invalid': (f.description.dirty || f.description.touched) &&  f.description.errors}" rows="4" type="text" formControlName="description" class=" form-control " name="" id="" placeholder="{{this.messageService.descriptiontext('desc')}}">
                </textarea>
                <div *ngIf="(f.description.dirty || f.description.touched) && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="!(f.description.errors?.pattern) && (f.description.errors.maxlength)">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                  <div *ngIf="f.description.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>

              </div>
            </div>
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label required">Priority</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Define the priority level of the Policy document: low, medium, and High.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">

                        <input class="form-check-input" type="radio" formControlName="priority" [value]="'Low'">Low <span
                            class="badge badge-circle badge-green sq-10 p-0 ms-8"></span>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" formControlName="priority" [value]="'Medium'">Medium <span
                            class="badge badge-circle badge-orange sq-10 p-0 ms-8"></span>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" formControlName="priority" [value]="'High'">High <span
                            class="badge badge-circle badge-red sq-10 p-0 ms-8"></span>
                    </label>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Category </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Select the category, under which the document should be mapped.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <ng-select  class="form-ngselect" formControlName="category" name="category"  id="category" placeholder="{{this.messageService.selectplaceholddisplay('category')}}" [ngClass]="{ 'is-invalid': (f.category.touched) && f.category.errors }" >
                <ng-option *ngFor="let li of categoryList" [value]="li.id">{{li.name}}</ng-option>

              </ng-select>
              <div *ngIf="(f.category.touched) && f.category.errors" class="invalid-feedback">
                <div *ngIf="f.category.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Add attachments </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Attach the document that you want to add. The maximum file size allowed is 10MB.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>


                <div class="card card-c2 p-24">
                    <div class="row row-16">
                        <div class="col-12" *ngIf="f.doc_policy_attachment.value==null || f.doc_policy_attachment.value=='' ">
                            <label class="upload-btn " [ngClass]="{ 'is-invalid': (fileError == true)  }">
                                <div class="upload-text">Upload documents <span class="text-primary">here

                                  <app-file-upload-docrepo [crop]="false" [round]="false" [id]="'document'" [width]="504"
                                  (dataURL)="getURL($event)" (fullfile)="getFilename($event)" (loader)="getloader($event)"
                                  [FileType]="'.pdf,.doc,.docx'" (fileformat)="getfileformat($event)"
                                  (validsize)="getvalidsize($event)"></app-file-upload-docrepo>
                                </span>
                                    <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC</div>
                                </div>
                                <input type="file" class="form-control" placeholder="" hidden>
                            </label>
                            <div *ngIf="(fileError == true)" class="invalid-feedback">
                              <div>{{fileErrorMsg}}</div>
                            </div>
                        </div>

                        <div class="col-lg-6" *ngIf="docLoader || (f.doc_policy_attachment.value!=null && f.doc_policy_attachment.value!='')">
                          <div class="card card-c2  bg-secondary" style="width:18.375rem;" >
                            <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                              <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                              <ng-container *ngIf="(f.doc_policy_attachment.value!=null && f.doc_policy_attachment.value!='' && !docLoader)">
                              <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                <span class="tips text-uppercase  fw-700 text-trim">{{this.appservice.getDocName(f.doc_policy_attachment.value)}}</span>
                                <span class="tips-helper ">{{this.appservice.getDocName(f.doc_policy_attachment.value)}}</span>
                                <div class="d-flex gap-8">
                                  <div class="link-primary" (click)="viewDocument(f.doc_policy_attachment.value,f.subject.value)">
                                    <i class="bi bi-eye me-1"></i>View
                                  </div>
                                  <div class="link-primary" (click)="this.appservice.downloadFile(f.doc_policy_attachment.value,this.appservice.getDocName(f.doc_policy_attachment.value))">
                                    <i class="bi bi-download me-1"></i>Download
                                  </div>
                                </div>
                              </div>
                              <i class="icon-trash  ms-auto flex-center link-danger" *ngIf="f.doc_policy_attachment.value!=null && f.doc_policy_attachment.value!=''" (click)="updateFile()"></i>
                              </ng-container>
                              <div class="  flex-center text-center" *ngIf="docLoader">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="col-lg-6 " *ngIf="docLoader || (f.doc_policy_attachment.value!=null && f.doc_policy_attachment.value!='')">
                            <div class="card card-c2  bg-secondary">
                                <div class="card-body  ">
                                    <div class="d-flex align-items-center mb-8 gap-16">
                                        <i class="icon-file-earmark-text-fill fs-32 text-accent2"></i>
                                        <div *ngIf="!docLoader">
                                            <h6 class="mb-1">{{this.appservice.getDocName(f.doc_policy_attachment.value)}}</h6>

                                        </div>
                                        <div class="ms-auto ">
                                            <i class="icon-check-circle-bold text-success fs-20"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex gap-24 fs-12 fw-500" [ngClass]="{ 'is-invalid': (fileError == true)  }">
                                      <ng-container *ngIf="(f.doc_policy_attachment.value!=null && f.doc_policy_attachment.value!='' && !docLoader)">

                                        <div class="link-primary" (click)="this.appservice.downloadFile(f.doc_policy_attachment.value,this.appservice.getDocName(f.doc_policy_attachment.value))" >
                                            <i class="bi bi-download me-1"></i>Download
                                        </div>
                                        <div class="link-primary" (click)="viewDocument(f.doc_policy_attachment.value,f.subject.value)">
                                            <i class="bi bi-eye me-1"></i>View
                                        </div>
                                      </ng-container>
                                        <div class="  flex-center text-center" *ngIf="docLoader">
                                          <div class="fs-12 text-muted">Loading </div>
                                          <div class="ms-4 dot-pulse"></div>

                                      </div>
                                    </div>
                                    <div *ngIf="(fileError == true)" class="invalid-feedback">
                                      <div>{{fileErrorMsg}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex align-items-start" *ngIf="f.doc_policy_attachment.value!=null && f.doc_policy_attachment.value!=''" (click)="updateFile()">

                          <a class="link-danger d-flex align-items-center ms-auto"><i class="icon-trash me-8 "></i></a>



                        </div> -->
                    </div>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label">URL </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Share any links or URLs along with the details if required. This field is optional.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                <input type="text" formControlName="url" class="form-control" placeholder="{{this.messageService.placeholderdisp('url')}}" [ngClass]="{ 'is-invalid': (f.url.dirty || f.url.touched) &&  f.url.errors || f.url.errors?.exist}">
                    <div *ngIf="(f.url.dirty || f.url.touched) && f.url.errors || f.url.errors?.exist" class="invalid-feedback">
                        <div *ngIf="f.url.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                    </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="" id="is_download" formControlName="is_download">
                <label for="is_download" class="form-check-label ">Allow employee to download the document</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Enable this option to provide access to employees for downloading the attached file.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="" id="is_acknowledge" formControlName="is_acknowledge">
                <label for="is_acknowledge" class="form-check-label ">Acknowledgement required from employee</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover='Enable this option if you require an acknowledgement from the employee after sharing the document .' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="formGp.invalid" (click)="submitStep1()">NEXT</button>
    </footer>
</form>

<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
      [currentExtension]="currentExtension" [fileName]="f.doc_policy_attachment.value"></app-view-file>
</ng-container>
