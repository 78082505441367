import { Component, OnInit, Input, Inject, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { LeaveBalanceHRService } from 'src/app/@core/services/leave-balance-hr.service';
@Component({
  selector: 'app-view-leave-balance',
  templateUrl: './view-leave-balance.component.html',
  styleUrls: ['./view-leave-balance.component.scss']
})
export class ViewLeaveBalanceComponent implements OnInit {
​
  employee: number                = 0;
  leaveBalanceDataTile:any[]      = [];
  leaveTypeBalanceDataTile:any[]  = [];
  // leaveBalanceTable:any[]         = [];
  page                            = 1;
  pageSize                        = 20;
  laeveBalanceDate: any           = "";
  loader                          = true;
  currentYear: number             = new Date().getFullYear();
  colspan : any                   = ''
​
  @Input()leaveBalanceTable:any[] = [];
  @Input()id:any;
  @Input()from:any;
  @Input() searchString : any
  @Input() isLeave : any
  @Output() viewClose             = new EventEmitter();
  @Output() view = new EventEmitter();
  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    private investmentService:InvestmentDeclerationService,
    private authService:AuthService,
    private leaveService : LeaveBalanceHRService
  ) { }
​
  ngOnInit(): void {
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    if(this.from == 'inbox'){
      this.employee = this.id;
    }
    else{
      this.employee                        = this.authService.isEmployeeId;
    }
​
    this.colspan = this.isLeave ? '8' : '7'
​
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes?.searchString){
      this.page = 1
    }
  }
​
  /********* DISABLE DATE FUNCTION ****************/
  disableDate(){
    return false;
  }
​
  /********* DATE CHANGE FUNCTION ****************/
  dateChangeFunction(e:any){
​
    this.laeveBalanceDate                = this.appservice.dateFormatConvert(e.target.value);
    this.leaveBalanceFunction();
​
  }
​
  /********* LEAVE BALANCE FUNCTION ****************/
  leaveBalanceFunction(){
    this.loader = true;
​
    if(this.laeveBalanceDate == ""){
      this.laeveBalanceDate               = this.appservice.dateFormatConvert(new Date());
    }
​
    this.investmentService.leaveBalance({'employee': this.employee,'selected_date': this.laeveBalanceDate}).subscribe((res:any)=>{
        this.loader                       = false;
        this.leaveBalanceDataTile         = [res.data];
        // this.leaveBalanceTable            = res.data.response_data;
        this.leaveTypeBalanceDataTile     = res.data.leave_data;
    })
​
  }
​
  /********* CLOSE FUNCTION ****************/
  closeFunction(){
    this.viewClose.emit(false);
  }
  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 5, 0, 1),
      new Date(new Date().getFullYear() + 5, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
​
​
  // View
  viewfn(id:any){
   this.view.emit(id)
  }

  dateFormatInString(data:any){
    var res:any = []
    res         = data?.match(/\d{4}-\d{2}-\d{2}/g);
    res?.forEach((dateString: any) => {
      data = data?.replace(dateString,this.appservice.dateFormatDisplay(dateString))
    })
    return data;
  }
}
