<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Process/Unprocess Salary - {{month}} {{year}} </h5>
        <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <main class=" config-container">

            <div class="container-fluid p-32 form-container">
                <div class="row mb-24">
                    <div class="col-md-4">
                        <p class="text-uppercase fw-500 mb-0">{{status}} salary  - {{month}} {{year}}</p>
                        </div>
                        <div class="col-md-8">
                        <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">

                            <div style="min-width:22.688rem;">
                                <div class="form-icon2">
                                  <i class="icon-search icon-left fs-14"></i>
                                  <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                                  <input class="form-control  fs-14" autocomplete="off" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                                </div>
                            </div>
                            <div ngbDropdown class="d-inline-block" (click)="filter  = true">
                                <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                                    <i class="icon-filter-left fs-13"></i>
                                </button>
                             </div>
                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" (click)='updateList("Unprocessed")' name="btnradio" id="btnradio1" autocomplete="off" [checked]='status=="Unprocessed"' [disabled]="loader">
                                <label  class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio1">unprocessed</label>
                                <input type="radio" class="btn-check" (click)='updateList("Processed")' name="btnradio" id="btnradio2" autocomplete="off" [checked]='status=="Processed"' [disabled]="loader">
                                <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">processed</label>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="row row-24">
                    <div class="col-12">
                        <div class="card card-c2 overflow-hidden">
                          <app-table-loader *ngIf="loader"></app-table-loader>

                            <div class="table-responsive" *ngIf="!loader">
                                <table class="table td-12 th-12 sticky-header td-white-space table-striped table-sm form-table " >
                                    <thead>
                                        <tr >
                                            <th scope="col" class="form-cell">
                                                <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)" [checked]="checkallSelected()" >
                                            </th>
                                            <th class="fw-600 col-3" scope="col" >Employee name</th>
                                            <th class="fw-600 col-2" scope="col" >Employee code</th>
                                            <!-- <th  *ngIf="status == 'Unprocessed'" class="fw-600 col-2" scope="col" >Total days</th> -->
                                            <th  class="fw-600 col-2" scope="col">Total work days</th>
                                            <th  *ngIf="status == 'Processed'" class="fw-600 col-2" scope="col">Paid days</th>
                                            <th class="fw-600 col-2" scope="col">GROSS PAY</th>
                                            <th *ngIf="status == 'Processed'" class="fw-600 col-2" scope="col">NET PAY</th>
                                            <th   class="fw-600 col-2" scope="col" >Status</th>
                                            <th  *ngIf="status == 'Processed'" class="fw-600 col-2" scope="col">DETAILs</th>
                                        </tr>
                                    </thead>
                                    <tbody class="cell-16">
                                        <tr *ngIf="items.length<=0">
                                            <td colspan="9">
                                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                    <span class="fw-500 fs-14">No data found</span>
                                                  </div>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let item of $any(items |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                              <td class="form-cell">
                                                  <input [ngClass]="'page'+page" class="form-check-input m-0" type="checkbox"
                                                  [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" value="{{item.id}}" >
                                              </td>

                                            <td  class="text-trim w-15ch" style="width:30%" title="{{item.employee_name}}"
                                            data-toggle="tooltip" > {{item.employee_name}} </td>
                                            <td >{{item.employee_code}}</td>
                                            <td *ngIf="status=='Unprocessed'">{{item.total_days}}</td>
                                            <td *ngIf="status=='Processed'">{{item.work_days}}</td>
                                            <td *ngIf="status=='Processed'">{{item.paid_days}}</td>

                                            <td >{{(item.gross_pay) | currency:item.currency}}</td>
                                            <td  *ngIf="status == 'Processed'">{{(item.net_pay) | currency:item.currency}}</td>
                                            <td >
                                              <span *ngIf="item.status=='Publish'"  class="badge  badge-success py-1">Published</span>
                                              <span *ngIf="item.status=='Processed'"  class="badge badge-warning py-1">Processed</span>
                                              <span *ngIf="item.status=='Hold'"  class="badge badge-primary py-1">Held</span>
                                              <span *ngIf="item.status=='Freeze'"  class="badge badge-holiday py-1">Freezed</span>
                                              <span *ngIf="item.status=='Unprocessed'"  class="badge badge-danger py-1">Unprocessed</span>

                                            </td>
                                            <td *ngIf="status == 'Processed'">
                                                <span (click)="viewBreakUp(item.id,item.status)"
                                                class="link-primary1 d-flex align-items-center text-nowrap"><i class="icon-eye me-1"></i> View breakup</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="filteredcount()>pageSize">
                        <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize"  [collectionSize]="filteredcount()" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                </div>
            </div>
            <footer class="submit-footer gap-16 justify-content-end" >
                <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="items.length>0">
                    <span class="">{{countSelected()}} of {{items?.length}} selected </span>
                    <span *ngIf="items.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{items?.length}} employees</span>
                    <span *ngIf="items.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{items?.length}} employees</span>
                </p>
                <button [disabled]="countSelected()==0"  (click)="createSelectedLIst();" class="btn btn-primary btn-sm text-uppercase" >{{processText}}</button>
            </footer>

    </main>
</div>


<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='viewBreakup === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="viewBreakup = false"><i class="icon-close-lg fs-16"></i></a>
        </header>

        <div class="side-panel-body" style="overflow-y: auto;">
            <app-view-breakup-details
            [componentLoader]="componentLoader"
            [viewBreakUpDetails]="viewBreakUpDetails"></app-view-breakup-details>
        </div>

    </div>
</div>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>

<div class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
            <h4 class="modal-title">{{selectedList.length}} Employee(s) selected</h4>
            <button type="button" class="btn-close ms-auto my-0" (click)="downloadMsg = false" ></button>
            </div>
            <div class="modal-body">
                <p class="mb-0"> {{message}}</p>
            </div>
            <div class="modal-footer">
            <button [disabled]="saveComplete" type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="downloadMsg = false;" >Cancel</button>
            <button [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase" (click)="processSalary()">{{processText}}</button>

            </div>
        </div>
    </div>
</div>


<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg" ></app-info-popup>
</ng-container>

<!-- Error data download popup -->
<!-- <div  class="modal  modal-alert {{errorLog == true && saveData?.error_list.length>0 ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
          <div class="modal-header align-items-start gap-16">

          <h4 class="modal-title">{{sds.PROCESSCOUNT-saveData?.error_list.length}} out of {{sds.PROCESSCOUNT}} employee's salary is {{modelText}} successfully</h4>

          </div>
          <div *ngIf="saveData?.error_list.length>0" class="modal-body">
            <p class="mb-0">{{saveData?.error_list.length}} error data found.</p>
            <p class="mb-0">Click on download to view the error details</p>
          </div>
          <div  class="modal-footer">
              <button *ngIf="saveData?.error_list.length>0" type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadError();">Download</button>
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="errorLog = false;">Close</button>
          </div>
      </div>
  </div>
</div> -->

<app-error-download [fileName]="filename" [successDataCount]="savedataCount" [downloadMsg]="errorLog" [failedData]="exportData" [modalHeading]="modelHeading" (closePanel)="errorLog =false" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>


<app-upload-progress-bar  *ngIf="showProgress&& startProgress" [progress]="progPercentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Salary Processing'" [timeLeft]="timeLeft" [body]="body" (closePanel)="closeProgress()" [processingText]='"Proccessing...."'></app-upload-progress-bar>
