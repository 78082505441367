import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss']
})
export class ViewFileComponent implements OnInit {

  loader1: boolean = false;

  @Input() modalToggle:any
  @Input() currentDocName:any
  @Input() currentExtension:any
  @Input() fileName:any

  @Output() modal= new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  // Close the modal
  close(){
    this.modalToggle = false
    this.modal.emit(this.modalToggle)
  }

  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
   }
}
