<div class="container-fluid px-32 py-24">
  <app-view-loader *ngIf="viewloader"></app-view-loader>
  <ng-container *ngIf="!viewloader">
    <div class="row row-24">
      <div class="col-12 mt-16" *ngIf="Isalert && empData.message!=''">
        <div role="alert" class="alert alert-warning mb-0 show">
            <p class="mb-0 fs-14">{{empData.message}}</p>
            <div class="btn-close pointer" (click)="Isalert=false;"></div>
        </div>
      </div>

      <div class="col-12 mt-16" *ngIf="employemntAlert && (!empData.employee_details?.employee_active)">
        <div role="alert" class="alert alert-warning mb-0 show mb-12">
            <p class="mb-0 fs-14">The employment status for the employee is {{empData.employee_details?.employment_status}}. Salary structure cannot be revised.</p>
            <div class="btn-close pointer" (click)="employemntAlert=false;"></div>
        </div>
      </div>
        <div class="col-12">
            <div class="card card-c2">
                <div class="card-body p-16 d-flex align-items-center">
                    <img *ngIf="empData.employee_details?.profile_image !=  null && empData.employee_details?.profile_image !=  ''" class="img-fluid avatar-circle  sq-42 me-16" src="{{empData.employee_details.profile_image}}" />

                    <div *ngIf="empData.employee_details?.profile_image ==  '' || empData.employee_details?.profile_image ==  null" class="avatar-circle avatar-border sq-42 me-16 {{empData.employee_details?.color_code}} fs-14">{{this.appService.getFirstChar(empData.employee_details?.first_name+" "+empData.employee_details?.last_name,2)}}</div>
                    <div class="d-flex gap-6 flex-column" style="width: calc(100% - 230px);">
                        <div class="fs-14 fw-600">{{empData.employee_details?.first_name+" "+(empData.employee_details?.middle_name!=null?empData.employee_details?.middle_name:'')+" "+empData.employee_details?.last_name}} ({{empData.employee_details?.employee_code}})</div>
                          <div class="fs-12 fw-500 text-light-400">{{empData.employee_details?.designation_name}} | {{empData.employee_details?.company_name}}</div>
                    </div>

                    <div *ngIf="buttonActivate==true  && empData.employee_details?.employee_active" class="btn ms-auto btn-primary text-uppercase fs-10 fw-700" (click)="reviseSalary(empData.employee_details?.id,empData.message,empData.is_salary_revision_pending,empData.is_sal_revision_not_processed)">REVISE SALARY</div>
                </div>



                <div class="card-body p-16" *ngIf="tableData?.length>0">

                    <ul class="timeline timeline-vetical">
                        <li class="timeline-item " *ngFor="let item of tableData,let i = index">
                            <div class="timeline-title bg{{i%3 +1}}">
                                {{item.salary_revision.year}}
                            </div>
                            <div class="timeline-content ">
                                {{item.salary_revision.designation}}
                            </div>
                        </li>

                    </ul>

                </div>
            </div>
        </div>
        <div *ngIf="tableData?.length==0;else alldata" class="row pt-4">
          <!-- <div class="col-12 text-center">
              <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
                  style="width: 25rem;">
              <p class="text-uppercase text-light-400 fw-700 fs-14 mt-3">Salary revisions have not yet been updated</p>

          </div> -->
        <app-nodata-view [noDataText]="'Salary revisions have not yet been updated'" [hasPermission]="false"> </app-nodata-view>

        </div>
        <ng-template #alldata >
          <ng-container *ngIf="empData.last_revision_length >0">

            <div class="col-6" >
                <div class="row row-16">
                    <div class="col-6">
                        <div  class="card card-c2 h-100 card-highlight  highlight1">
                            <div class="card-body p-16 text-center">
                                <h6 class="fw-600 fs-20">{{empData.last_revision_details.last_revision}}</h6>
                                <hr class="hr-1 mb-16 mt-16">
                                <div class="mb-0 fw-500 fs-12">Last revision </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card card-c2 h-100 card-highlight highlight2">
                            <div class="card-body p-16 text-center">
                                <h6 class="fw-600 fs-20">{{empData.last_revision_details.avg_rev_period}} months</h6>
                                <hr class="hr-1 mb-16 mt-16">
                                <div class="mb-0 fw-500 fs-12">Average revision period</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card card-c2 h-100 card-highlight highlight3">
                            <div class="card-body p-16 text-center">
                                <h6 class="fw-600 fs-20">{{empData.last_revision_details.avg_revised_percentage | number : '.2-2'}} %</h6>
                                <hr class="hr-1 mb-16 mt-16">
                                <div class="mb-0 fw-500 fs-12">Average salary increment
                                    percentage</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card card-c2 h-100 card-highlight highlight4">
                            <div class="card-body p-16 text-center">
                                <h6 class="fw-600 fs-20">{{empData.last_revision_details.total_revisions}}</h6>
                                <hr class="hr-1 mb-16 mt-16">
                                <div class="mb-0 fw-500 fs-12">Total revisions till date</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card card-c2 p-16"  style="height:15.4rem;">
                    <div class="d-flex mb-auto">
                        <div class="fw-500">Revision Analysis</div>
                        <div class="ms-auto fs-14 text-light-400">Yearly CTC</div>
                        <!-- <ng-select class="form-ng-select ms-auto fs-14"
                        placeholder="Yearly CTC" [clearable]="false" >
                        </ng-select> -->
                    </div>
                          <app-line-chart [pin]="true" [labels]="financialLabels" [data]="financialData" [fill]="false" [type]="'Yearly CTC'" [bgColor]="'#ffffff'"></app-line-chart>

                    <!-- <div class="h-100 line-chart-1" #containerRef> -->
                            <!-- <ngx-charts-line-chart  #chart [scheme]="colorScheme" [results]="multi" [gradient]="true" [xAxis]="true" [yAxis]="true"  [showXAxisLabel]="true" [showYAxisLabel]="true"
                            [timeline]="true" [tooltipDisabled]="true" [view]="[350, 270]"> </ngx-charts-line-chart> -->

                    <!-- </div> -->
                </div>
            </div>
          </ng-container>

          <div class="col-12 fw-500">REVISION HISTORY </div>
          <div class="col-12">
              <div class="card card-c2 p-0">
                  <div class="table-responsive radius-4">
                      <table class="table td-14 sticky-header td-white-space td-fs-14
                          tr-fs-14  table-striped table-sm form-table">
                          <thead>
                              <tr>
                                  <th class="fw-600">EFFECTIVE</th>
                                  <th class="fw-600" scope="col">Pay month</th>
                                  <th class="fw-600" scope="col">PAY GROUP</th>
                                  <th class="fw-600" scope="col">ANNUAL CTC</th>
                                  <th class="fw-600" scope="col">INCREMENT</th>
                                  <th class="fw-600" scope="col">STATUS</th>
                                  <th class="fw-600" scope="col" >Details</th>
                              </tr>
                          </thead>
                          <tbody class="cell-16 height-full">
                              <tr *ngFor="let item of tableData,let i = index">
                                  <td >{{item.salary_revision?.effective_from}}</td>
                                  <td>{{item.salary_revision?.salary_pay_month}}</td>
                                  <td>{{item.salary_revision?.pay_group}}</td>
                                  <td>{{item.salary_revision?.revise_ctc | currency: item.currency}}</td>
                                  <td><span class="{{item.increment>0?'graph-increase text-success':'graph-decrease text-danger'}}" ngbPopover="{{item.revised_perentage}} %" container="body" triggers="hover" popoverClass="popover-default" placement="bottom">{{item.increment>0?(item.increment| currency: item.currency):item.increment*-1 | currency: item.currency}}</span></td>
                                  <td>

                                      <span class="badge {{item.salary_revision?.status=='Pending' ? 'badge-warning': (item.salary_revision?.status=='Rejected' || item.salary_revision?.status=='Invalid') ? 'badge-danger':'badge-success'}} fs-12 px-16 py-1" ngbPopover="{{item.approver?.approver_full_name}}" container="body"
                                      triggers="hover" popoverClass="popover-default" placement="bottom">{{item.salary_revision?.status}}</span>
                                  </td>
                                  <td class="text-center link-primary">
                                      <span href="" class="link-primary d-flex align-items-center gap-4" (click)="hisId=item.salary_revision?.id;salaryRevisionDetailsView = true;appraisalMonth=item.salary_revision?.effective_from"><i class="icon-eye"></i> View</span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
        </ng-template>

    </div>
  </ng-container>
</div>
<!-- SALARY REVISION VIEW START -->
<div class="side-panel" style="--sidepanel-width:59.0625rem;" [class.side-pane-active]='salaryRevisionDetailsView === true'>
    <div class="side-panel-container">
      <header class="side-panel-head">
          <h5>{{appraisalMonth}} Appraisal Details</h5>
          <a class="toggle-panel" (click)="salaryRevisionDetailsView = false"><i class=" icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">

            <app-salary-revision-details-view  [requestitem]=""  *ngIf="salaryRevisionDetailsView" [hisId]="hisId" [from]="from"></app-salary-revision-details-view>


      </div>
    </div>
  </div>
  <!-- SALARY REVISION VIEW START -->



<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg" ></app-info-popup>
</ng-container>
<!-- Request confirmation -->
<div class="modal  modal-alert {{reqConfirm == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16rem">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Are you sure?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{confirmMsg}}</p>
          </div>
          <div class="modal-footer" >
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="reqConfirm = false">No</button>
              <button type="button" class="btn btn-danger btn-sm text-uppercase" (click)="confirmRequest()">Yes</button>
          </div>
      </div>
  </div>
</div>
