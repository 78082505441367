<div class="row row-16">
    <div class="col-md-6">
        <div class="chart-container flex-center" style="--h:{{height}}">
            <span *ngIf="totalCenter" class="total-legend">
                <span *ngIf="showRupee">{{getTotal() | currency : currency}}</span>
                <span *ngIf="!showRupee">{{getTotal()}}</span>
            </span>
            <canvas #canvas [id]="uniqueId">{{ chart }}</canvas>
        </div>
    </div>
    <div class="col-md-6 flex-center">
        <div class="c{{labeltype}}-label-wrap label-wrap" style="--fs:{{labelFontSize}}">
            <div class="label-items">
                <div class="label-item" *ngFor="let label of labels,let i = index">
                    <span class="item-color" [ngStyle]="{'background-color': color[i]}"></span>
                    <span class="item-name" title="{{label}}">{{label}}</span>
                    <!-- Added a boolean value setup for currency(the values are represented with currency symbol and also formatted based on digits) -->
                    <span class="item-value" *ngIf="showRupee">{{data[i] | currency : currency}}</span>
                    <span class="item-value" *ngIf="!showRupee && showDays==''">{{data[i]}}</span>
                    <span class="item-value" *ngIf="showDays!=''">{{data[i]}} {{showDays}}</span>
                </div>
            </div>
            <div class="label-item" *ngIf="total!=''">
                <span class="item-color"></span>
                <!-- Total is defined dynamically because in Home, Both chart total naming is different -->
                <span class="item-name">{{total}}</span>
                <!-- Added a boolean value setup for currency (the values are represented with currency symbol and also formatted based on digits) -->
                <span class="item-value" *ngIf="showRupee">{{getTotal() | currency : currency}}</span>
                <span class="item-value" *ngIf="!showRupee">{{getTotal()}}</span>
            </div>
        </div>
    </div>
</div>