<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Trip DETAILS</p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Trip Name </label>
                <input type="text" class="form-control " placeholder="Enter Trip Name ">
            </div>
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label required">Trip Type </label>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio">
                        Domestic
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio">
                        International
                    </label>
                </div>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Destination Country </label>
                <ng-select class="form-ngselect" placeholder="Select Destination Country "></ng-select>
            </div>

            <div class="col-12 form-row">
                <label for="check" class="form-check p-0 d-flex ">
                    <input type="checkbox" name="check" id="check " class="form-check-input ms-0">
                    <label for="check " class="form-check-label fs-16">Is visa required?</label>
                </label>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Visa Number </label>
                <input type="text" name="" class="form-control" placeholder="Enter Visa Number  " id="">
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto">NEXT</button>
    </footer>
</form>