import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetSettingsService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };


  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  saveOrUpdateCompanyConfig(body: any,id:any){
    if(id==0)
    return this.http.post(`${this.API_PATH}asset/api/v1/asset-setting-list/`,body, this.httpOption)
    else
    return this.http.put(`${this.API_PATH}asset/api/v1/asset-setting-detail/${id}/`,body, this.httpOption)

  }
  getAssetList(status: any, limit: any, offset: any, search:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-setting-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`,this.httpOption)
  }
  companyDropDownData(status: any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/payroll-config-dropdown/?is_active=${status}&ordering=company__company_name`, this.httpOption)
  }
  getSingleAssetData(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-setting-detail/${id}/`,this.httpOption)
  }
  getExcludedCompList(status: any,id :any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}asset/api/v1/company-without-settings/?is_active=${status}&id=${id}&limit=${limit}&offset=${offset}&search=${search}&ordering=company_name`, this.httpOption);
  }
  getExcludedCompDropdown(status: any) {
    return this.http.get(`${this.API_PATH}asset/api/v1/company-without-settings/?is_active=${status}&ordering=company_name`, this.httpOption);
  }
  getIncludedCompDropdown(status: any) {
    return this.http.get(`${this.API_PATH}asset/api/v1/company-with-settings/?is_active=${status}&ordering=company_name`, this.httpOption);
  }
  getAssetSubcategory(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-subcategory-dropdown-list/?category=${id}`,this.httpOption)
  }
  getAssetSubcategoryActive(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-subcategory-dropdown-list/?category=${id}&is_active=true`,this.httpOption)
  }
  saveOrUpdateAsset(body: any,id:number){

    if(id==0){
      return this.http.post(`${this.API_PATH}asset/api/v1/asset-list/`,body, this.httpOption)
    }
    else{
      return this.http.put(`${this.API_PATH}asset/api/v1/asset-detail/${id}/`,body, this.httpOption)
    }

  }
  getAssetManagementData(status:any,limit:any,offset:any,search:any,filter:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-list/?status=${status}&limit=${limit}&offset=${offset}&search=${search}&${filter}`,this.httpOption)

  }
  getSingleInventoryData(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-detail/${id}/`,this.httpOption)
  }

  getSingleInventoryInbox(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/inbox-asset-detail/${id}/`,this.httpOption)
  }

  getInventoryDropdown(status:any,filter:any,ordering:any,employee:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-search-dropdown/?status=${status}&ordering=${ordering}&assigned_to__in=${employee}&${filter}`,this.httpOption)
  }
  getSingleInventoryHistory(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/inventory-history/${id}`,this.httpOption)

  }
  deleteInventory(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}asset/api/v1/asset-detail/${id}/`, body, this.httpOption)
  }
  uploadInventory(body:any){
    return this.http.post(`${this.API_PATH}asset/api/v1/upload_assets/`,body, this.httpOption)

  }
  getEmployeeAssetData(limit:any,offset:any,search:any,filter:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/my-asset/?limit=${limit}&offset=${offset}&search=${search}&${filter}`,this.httpOption)

  }
  getMyassetDropdown(filter:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/my-asset-search-dropdown/?${filter}`,this.httpOption)
  }
  getSingleEmployeeAsset(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/my-asset-single/${id}`,this.httpOption)

  }
  getAssetSingleList(company:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-setting-list/?company_id=${company}`,this.httpOption)
  }

  empDDList(isactive:any,ordering:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list-all/?is_active=${isactive}&ordering=${ordering}`, this.httpOption);
  }

  applicableList(type:string){
    if(type == "BU"){
      return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    } else if(type == "Department")
      return  this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Designation")
      return  this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Grade")
      return  this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Company")
      return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
    else if(type == "Employee")
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list-all/?is_active=true&ordering=user__first_name`, this.httpOption);
    else
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list-all/?is_active=true&ordering=user__first_name`, this.httpOption);
  }

  getAssetProfileDropdown(section:any,ordering:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-profile-dropdown/?section=${section}&ordering=${ordering}`,this.httpOption)
  }
}
