import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { ShortLeaveService } from 'src/app/@core/services/short-leave.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { Location} from '@angular/common';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
@Component({
  selector: 'app-short-leave-policy-setup',
  templateUrl: './short-leave-policy-setup.component.html',
  styleUrls: ['./short-leave-policy-setup.component.scss']
})
export class ShortLeavePolicySetupComponent implements OnInit {

  id                      : number = 0;
  shortLimit              : any    = ['Month','Week']
  editFlag                : boolean   = false
  loader                  : boolean   = false
  isEditForm              : boolean   = false
  tab2touched             : boolean   = false
  disabled                : boolean   = false
  activeTab               : number    = 1;
  title                   : any       = 'Add'
  constructor(
    private fb            : FormBuilder,
    private shortService  :  ShortLeaveService,
    public router         : Router,
    public route          : ActivatedRoute,
    private previousRoute : PreviousRouteService,
    private _location     : Location
  ) { }

  // Form Initialization
  shortForm : FormGroup = this.fb.group({
    detailsForm : this.fb.group({
      name: [null,[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      short_leave_limit_type: [null,[Validators.required]],
      max_no_leave_apply_times: [null,[Validators.required, Validators.min(1),Validators.maxLength(2)]],
      minHours : [null,[Validators.required,this.NumberPatternValid(2)]],
      minMinutes : [null,[Validators.required,this.NumberPatternValid(2),Validators.max(59)]],
      maxHours : [null,[Validators.required,this.NumberPatternValid(2)]],
      maxMinutes : [null,[Validators.required,this.NumberPatternValid(2),Validators.max(59)]],
      totalHours : [null,[Validators.required,this.NumberPatternValid(3)]],
      totalMinutes : [null,[Validators.required,this.NumberPatternValid(2),Validators.max(59)]],
      allow_backdated_request: [false,[Validators.required]],
      // false - Day, true - as per reversal
      backdated_type: [true,[Validators.required]],
      backdated_days: [null],
      allow_more_than_one_leave_day: [false,[Validators.required]],
    }),
    applicability: this.fb.group({
      applicableto: [null,[Validators.required]],
      assignees: [null,[Validators.required]]
    })
  })

  // Pattern Validator
  NumberPatternValid(maxDigits: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control?.value;
      if (!value) {
        return null;
      }
      const pattern = new RegExp(`^(0\\d?|[1-9]\\d{0,${maxDigits - 1}})$`).test(value);
      return !pattern ? { pattern: true } : null;
    };
  }

  ngOnInit(): void {
    // Getting ID from Params
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }

  // Individual Forms
  get detailsForm(){
    return this.shortForm.get('detailsForm') as FormGroup
  }

  get applicabilityForm(){
    return this.shortForm.get('applicability') as FormGroup
  }

  // Close Button
  backClicked() {
    if(this.previousRoute.getPreviousUrl() == '/login')
      this.router.navigate(['request']);
    else
      this._location.back();
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(this.shortForm['controls'].detailsForm.invalid){
        changeEvent.preventDefault();
      }
    }
  }

  // Next Step
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
    if (activeTab === 2) {
      this.tab2touched =true
    }
  }

  // Edit Form
  editForm(){
    this.loader = true;
    this.title = 'Edit';
    this.shortService.getSingleShortPolicy(this.id).subscribe((res:any)=>{
      // this.isEditForm = true;
      this.setEditForm(res?.body);
      this.editFlag = true;
      this.loader = false;
    },(error)=>{
      this.router.navigate(['short-leave']);
    })
  }

  setEditForm(res:any){
    if(res?.is_active == false){
      this.shortForm.disable();
      this.disabled = true
    }

    let assignees :any = [];
    let appkey ="";

    // Step 2 Applicability
    appkey = res.applicability?.applicable;
    for(let i=0;i<res.applicability[appkey]?.length;i++){
      assignees.push(res.applicability[appkey][i].id);
    }

    // Resetting Form with new
    this.shortForm.reset({
      detailsForm : {
        name: res?.name,
        short_leave_limit_type: res?.short_leave_limit_type,
        limit_start_day: res?.limit_start_day,
        max_no_leave_apply_times: res?.max_no_leave_apply_times,
        minHours : res?.min_short_leave_hours_in_request?.split(':')[0],
        minMinutes : res?.min_short_leave_hours_in_request?.split(':')[1],
        maxHours : res?.max_short_leave_hours_in_request?.split(':')[0],
        maxMinutes : res?.max_short_leave_hours_in_request?.split(':')[1],
        totalHours : res?.total_short_leave_hours?.split(':')[0],
        totalMinutes : res?.total_short_leave_hours?.split(':')[1],
        allow_backdated_request: res?.allow_backdated_request,
        // false - Day, true - as per reversal
        backdated_type: res?.backdated_type,
        backdated_days: res?.backdated_days,
        allow_more_than_one_leave_day: res?.allow_more_than_one_leave_day,
      },
      applicability: {
        applicableto: appkey,
        assignees: assignees
      }
    })
  }

  convertTimeFormat(timeString:any) {
    const timeComponents = timeString.split(':').map(Number);
    const hours = timeComponents[0] || 0;
    const minutes = timeComponents[1] || 0;
    const seconds = timeComponents.length > 2 ? timeComponents[2] || 0 : 0;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

  // Final Submit
  submitForm(){
    var applicableto = this.applicabilityForm.controls.applicableto.value;
    var applicableStr = applicableto;
    let detailsFormValue = {
      ...this.shortForm.get('detailsForm')?.value,
      min_short_leave_hours_in_request: this.convertTimeFormat(parseInt(this.shortForm.get('detailsForm')?.get('minHours')?.value) + ':'+ parseInt(this.shortForm.get('detailsForm')?.get('minMinutes')?.value)),
      max_short_leave_hours_in_request: this.convertTimeFormat(parseInt(this.shortForm.get('detailsForm')?.get('maxHours')?.value)+':' + parseInt(this.shortForm.get('detailsForm')?.get('maxMinutes')?.value)),
      total_short_leave_hours: this.convertTimeFormat(parseInt(this.shortForm.get('detailsForm')?.get('totalHours')?.value) + ':'+ parseInt(this.shortForm.get('detailsForm')?.get('totalMinutes')?.value))
    }
    let dataArray = {
      ...detailsFormValue,
      'applicability' : {
        'applicable' : applicableStr,
        'object_ids' : this.applicabilityForm.get('assignees')?.value
      }
    }
    if(this.id == 0){
      this.shortService.createShortPolicy(dataArray).subscribe((res:any)=>{
        this.router.navigate(['short-leave'])
      })
    }else{
      this.shortService.updatePolicyData(this.id,dataArray).subscribe((res:any)=>{
        this.router.navigate(['short-leave'])
      })
    }
  }
}
