import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function PanfieldValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const fieldPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);

      return !fieldPattern ? {pattern:true}: null;
  }
}

