import {ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder,  Validators } from '@angular/forms';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { GeolocationService } from '../../../../@core/services/geo-location.service';
import { forEach } from 'jszip';
import { NotificationService } from 'src/app/@core/services/notification.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-geo-location',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss']
})
export class GeoLocationComponent implements OnInit {
  viewDetail               = false;
  viewSidePanel            = false;
  dataId                   : any;
  submitted                : any;
  locationViewdata         : any =[];
  offset                   = 0;
  listlength               = 0;
  direction                = '';
  defaultlimit:any         = 20;
  geolocationDetailsList   :any   = [];
  mappedempdata            :any   = [];
  geolocationlist:any      = [];
  infinityloader           = false;
  permissions:any          = [];
  searchKeyword            = "";
  search                   = "";
  statusBtn:any            = "Active";
  activeToggle:any         = "True";
  loader1                  = false;
  loader2                  = false;
  loader                   = true;
  nodata                   = false;
  searchlistdata:any       = [];
  searchlistdata2:any       = [];
  locsearch:string       = this.messageService.searchdisplay('Location');
  locsearch2:string       = this.messageService.searchdisplay('Employee');
  deleteToggle             = false;
  deletealert              = false;
  deleteid                 :any;
  deleteempid              :any;
  locationid               :any;
  deleteClicked            = false;
  deleteempmodel           = false;
  mappedlength             = 0;
  alertMsg                 :any;
  addeditpanel             = false;
  page                    = 1;
  pageSize                = 20;

  // import variables
  fileName       : string    = '';
  fileSize       : string    = '';
  invalidFormat              = false;
  deleteToggle2               = false;
  deleteMsg                  = "";
  iscompleted                = false;
  isDataUpload               = 0;
  percentage     :any        = 0;
  isProgressStart            = false;
  successDataCount           = 0;
  downloadMsg                = false;
  invalid                    = false
  confirmMsg                 = false;
  exportData     :any        = [];
  excelData      :any        = [];
  importData     :any        = [];
  failedData     :any        = [];
  importTemp     :any        = [];
  errorMsg                   = '';
  successMsg                 = '';
  body                       = '';
  ws             :any        = null;
  saveDataArray  :any        = []
  chunkLen                   = 10;
  sLoading = false
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private GeolocationService:GeolocationService,
    public formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService : MessageService,
    private notificationService:NotificationService,
    public changeDetectorRef: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {
      this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.defaultlimit = 40;
        }
      });
      this.getPermission();
      this.showDataItems();

  }
 showDataItems(){
    this.loader = true;
    this.GeolocationService.getlocationList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
      this.listlength = res.body.length;
      if(this.offset == 0){
        this.geolocationlist = [];
      }

      if (res.body.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.geolocationDetailsList = res.body;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
      this.sLoading = false
    })

  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.geolocationlist.push(this.geolocationDetailsList[i]);
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset     = 0;
      this.geolocationlist  = [];
      this.showDataItems();
    }else{
      this.GeolocationService.getOnelocation(data).subscribe((res: any) => {
        this.searchKeyword  = res.body.location_name;
        if(res.body.employees_mapped?.length  != 0 && res.body.employees_mapped?.length !=undefined){
          res.body['count_employees'] = res.body.employees_mapped.length;
        }else{
          res.body['count_employees']= 0;
        }
        this.geolocationlist = [res.body];
        this.loader    = false;
      });
    }
  }

  searchlocation(searchKeyword:any){
    this.sLoading = true
    this.loader         = true;
    this.offset         = 0;
    this.geolocationlist = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.showDataItems();
    }
  }

  filterItemList(value:any){
    this.loader       = true;
    this.activeToggle = value;
    this.geolocationlist    = [];
    this.offset       = 0;
    this.child.clearBar();
  }

  viewlocation(id:any){
    this.search  = '';
    this.locationid= id;
    if(this.permissions.v){
    this.mappedempdata = [];
    this.mappedlength = 0;
      this.viewDetail     = true;
      this.loader1     = true;
      this.GeolocationService.getOnelocation(id).subscribe((res: any) => {
        this.locationViewdata   = res.body;
        this.mappedlength       = this.locationViewdata.employees_mapped.length;
        this.mappedempdata      = this.locationViewdata.employees_mapped;
        this.getmappedemployes(id);
        this.loader1            = false;
      })
    }
  }

  getmappedemployes(id:any){
    this.loader2 = true;
    this.GeolocationService.locationListData2(id,this.search).subscribe((res: any) => {
      for(let i = 0;i<res?.body.employees.length;i++){
        res.body.employees[i]['id']=res.body.employees[i]['assigned_id'];
        res.body.employees[i]['name']=res.body.employees[i]['employee_full_name']+ "("+res.body.employees[i]['employee__employee_code']+")";
        res.body.employees[i]['color_code']=res.body.employees[i]['employee__color_code'];
        res.body.employees[i]['image']=res.body.employees[i]['employee__profile_image'];
      }
      this.searchlistdata2 =  res.body.employees;
      this.mappedempdata = this.searchlistdata2;
      this.loader2 = false;
    });
  }

  searchresults2(data:any){
    this.mappedempdata = [];
    if(data == ""){
      this.search = '';
      this.getmappedemployes(this.locationid);

    }else{
        for(let i = 0;i<this.searchlistdata2.length;i++){
          if(this.searchlistdata2[i]['id']==data){
            this.mappedempdata.push(this.searchlistdata2[i]);
          }
        }
    }
  }

  searchlocation2(searchKeyword:any){
    this.mappedempdata = [];
    this.search  = searchKeyword;
    if(searchKeyword != ""){
      this.getmappedemployes(this.locationid);
      
    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/geo-fencing/geo-locations');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  addheadercontent(event:any){
    this.addeditpanel = true;
    this.dataId=0
  }

  locationdetailout(event:any){
    if(event == true){
      this.showDataItems();
    }
  }

  closefn(daata:any){
    this.addeditpanel = daata;
  }

  deletefn(id:any,mapped:any){
    this.deleteid       = id;
    if(mapped == 0){
    this.deleteToggle   = true;
    }else{
      this.alertMsg = 'Geo fencing details cannot be deleted while employees are mapped to it. Only when no employees are mapped, deletion is allowed.'
      this.deletealert   = true;
      this.deleteToggle   = false;
    }
  }

  deleteLocation(){
    this.deleteClicked = true;
    this.GeolocationService.locationDelete(this.deleteid,{'is_active': 'False'}).subscribe((res: any) => {
      this.viewDetail  = false;
      this.offset         = 0;
      this.geolocationlist = [];
      this.showDataItems();
      this.deleteToggle   = false;
      this.deleteClicked  = false
    },
    (err: any) => {
      this.deleteToggle   = false
      this.deleteClicked  = false
    });
  }

  deleteemp(id:any){
    this.deleteempid = id;
    this.deleteempmodel = true;
  }

  deletemappedemp(){
    this.GeolocationService.deleteAssignedLocation(this.deleteempid).subscribe((res: any) => {
      this.deleteempmodel = false;
      if(res.status=1)
      this.notificationService.handleSuccessNotification("Deleted Successfully","Success");
      this.getmappedemployes(this.locationid);
      this.mappedempdata = this.searchlistdata2;
      this.showDataItems();
    },
    (err: any) => {
    });
  }

  closed(bool:any){
    this.deletealert = bool
  }

  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
  }

  // File Change
  onFileChange(evt: any) {
    this.excelData  = [];
    this.importData = []
    this.iscompleted = false
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){

      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];

        this.fileName   = target.files[0].name;
        this.fileSize   = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData  = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
        this.changeDetectorRef.detectChanges();

      };
      reader.readAsBinaryString(target.files[0]);
    }else{
      this.invalidFormat = true;
    }
  }

  // Processing Data
  processData(){
    if(this.excelData.length <=1){
      this.deleteToggle2 = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.excelData[0];
      let Heading = ['Location Name',	'Latitude',	'Longitude', 'Radius']
      this.invalid = headerRow.every((element:any) => Heading.includes(element));
      let success =   JSON.stringify(Heading)==JSON.stringify(headerRow)

      if(success == false || !this.invalid){
        this.deleteToggle2 = true;
        this.deleteMsg = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
       this.prepareUploadData()
    }
  }

  // Before Upload
  prepareUploadData(){
    let headerRow   = this.excelData[0];
    let header:any  = [];
    this.importTemp = [];
    this.importData = []

    for(let i=0;i<headerRow.length;i++){
      switch(headerRow[i]){
        case 'Location Name':{
          header.push('location_name');
          break;
        }
        case 'Latitude':{
          header.push('latitude');
          break;
        }
        case 'Longitude' : {
          header.push('longitude');
          break;
        }
        case 'Radius' : {
          header.push('radius')
          break
        }
        default:{
          header.push(headerRow[i]);
          break;
        }
      }
    }
    this.excelData.forEach((item:any, key:any) => {
      var dict:any = {};
      for(let i=0;i<header.length;i++){
        dict[header[i]]= item[i];
      }
      this.importTemp.push({
        dict
      })
    })
    for(let j=0;j<this.importTemp.length;j++){
      this.importData.push(this.importTemp[j]['dict'])
    }
  }

  // Upload
  uploadBalance(){
    this.isDataUpload     = 1;
    this.iscompleted = false
    this.isProgressStart  = true
    this.failedData       = []
    this.saveDataArray = []
    var l                 = this.importData.length;
    var cLoop             = Math.ceil(l/this.chunkLen)
    var p                 = 100/cLoop
    this.successDataCount = 0
    this.uploadasChunks(0,p);
  }

  uploadasChunks(k:any,p:any){
    var l           = this.importData.length;
    this.percentage = 0
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    this.saveDataArray = [];
    this.percentage +=(p/4);
    for(var i= 1; i<this.importData.length; i++){
      this.saveDataArray.push(this.importData[i])
    }
    this.GeolocationService.uploadImportData({'data':this.saveDataArray}).subscribe((res:any)=>{
      this.body = x+' out of '+l+" data uploaded successfully"
      this.confirmMsg = false;
      if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
        res.body.failed_data.forEach((i:any, k:any) => {
              this.failedData.push(i)
        })
      }
    this.percentage +=((p/4)*3);
    this.iscompleted = true
    this.isProgressStart = false
    this.successDataCount = this.successDataCount+res.body.success_data_count;

    if(this.failedData.length == 0){
      this.notificationService.handleSuccessNotification(this.saveDataArray.length+" location data uploaded successfully.","Success");
      this.CompleteUpload();
    }else {
      this.exportData    = [];
      for (let i = 0; i < this.failedData.length; i++) {

        this.exportData.push({'Location Name':this.failedData[i]['data']['location_name'],'Latitude ':this.failedData[i]['data']['latitude'], 'Longitude':this.failedData[i]['data']['longitude'],'Radius':this.failedData[i]['data']['radius'],'Error Description':this.failedData[i]['error']});

      }
      this.successMsg = this.successDataCount+" out of "+(this.importData.length-1)+" data uploaded successfully "
      this.errorMsg = this.failedData.length+' location(s) have error data'
      this.downloadMsg = true;
    }
  },
  (error:any)=>{
    this.isProgressStart = false
    this.notificationService.handleErrorNotification('Something went wrong','Error')
  }
  )
  }

  // After Uploading
  CompleteUpload(){
    this.fileName      = '';
    this.excelData          = [];
    this.fileSize      = '';
    this.exportData  = [];
    this.failedData = [];
    this.importTemp    = [];
    this.importData    = [];
  }

}
