
<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Employee Details</h5>
        <button class="btn-reset text-light-600" routerLink="/employee"><i class="icon-close-lg fs-16"></i></button>
    </header>

    <main class=" config-container">
        <form class="">

                <app-profile  [from]="'hr'"></app-profile>



        </form>
    </main>
</div>

