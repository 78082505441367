import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as assert from 'assert';

@Injectable({
  providedIn: 'root'
})
export class ManageAdvanceService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }


  getoutstandinglistData(search:any,filterdata:any){
    if(filterdata!=''){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-management-outstanding/?search=${search}&${filterdata}`, this.httpOption);
    }else{
      return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-management-outstanding/?search=${search}`, this.httpOption);
    }
  }
  getadvancerequestlist(dispensedatakey:any,search:any,filterdata:any){
    let filterstring = '';
    if(dispensedatakey=='True'){
      filterstring ='status_filter=["Accepted","Pending"]';
    }else{
      // filterstring = 'dispense_advance='+dispensedatakey;
      filterstring = '';
    }
    if(search!=''){
      filterstring+=`&search=${search}`;
    }
    if(filterdata!=''){
      filterstring+=`&${filterdata}`;
    }
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-request-management/?${filterstring}`, this.httpOption);
  }
  getSearchListoutstanding(search:any,filterdata:any){
    if(filterdata!=''){
      return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-dropwon-outstanding-employee/?ordering=name&search=${search}&${filterdata}`, this.httpOption);
    }else{
      return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-dropwon-outstanding-employee/?ordering=name&search=${search}`, this.httpOption);
    }
  }
  getSearchListrequest(dispensedatakey:any,search:any,filterdata:any){
    let filterstring = '';
    if(dispensedatakey=='True'){
      filterstring ='status_filter=["Accepted","Pending"]';
    }else{
      // filterstring = 'dispense_advance='+dispensedatakey;
      filterstring = '';
    }
    if(search!=''){
      filterstring+=`&search=${search}`;
    }
    if(filterdata!=''){
      filterstring+=`&${filterdata}`;
    }
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-request-employee-dropdown/?ordering=name&${filterstring}`, this.httpOption);   
  }
  getadvanceemp(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-management-outstanding-detail/?employee_id=${id}`, this.httpOption);
  }
  getadvance(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-advance-request-management/${id}/`, this.httpOption);
  }
  sendreminder(body: any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-advance-management-reminder/`, body, this.httpOption)
  }
}
