import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Validators,FormBuilder } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { AppService } from 'src/app/app.global';
import { RoleService } from 'src/app/@core/services/role.service';
import { EmppShiftMapService } from 'src/app/@core/services/empp-shift-map.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { NotificationService } from 'src/app/@core/services/notification.service';
import {Location} from '@angular/common';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { Router} from '@angular/router';


@Component({
  selector: 'app-shift-mapping-import',
  templateUrl: './shift-mapping-import.component.html',
  styleUrls: ['./shift-mapping-import.component.scss']
})
export class ShiftMappingImportComponent implements OnInit {

  maxValue: any;
  body = '';
  errorMsg = '';
  successMsg = '';
  errorLog = false;
  invalid = false
  downloadLoader = false
  minDate :any
  currentYr = new Date().getFullYear()+1
  maxDate = new Date(this.currentYr,0,1)

  constructor(
    private formBuilder     : FormBuilder,
    public messageService   : MessageService,
    private employeeService : EmployeeService,
    public appService       : AppService,
    private rs              : RoleService,
    public empShift         : EmppShiftMapService,
    public changeDetectorRef: ChangeDetectorRef,
    private notificationService: NotificationService,
    private _location: Location,
    private previousRouteService: PreviousRouteService,
    private router:Router
) { }

  ngOnInit(): void {
    let inThreeYears = new Date();
    inThreeYears.setFullYear (inThreeYears.getFullYear() + -3 )
    this.minDate = new Date(inThreeYears.getFullYear(), 0, 1);
    this.maxValue = this.maxDate
    this.getCompanyList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    this.getEmployeeList()
  }
  disabled  = false;

  templateForm            = this.formBuilder.group({
                              fromdate      : [null,[Validators.required]],
                              todate        : [null,[Validators.required]],
                              employee      : [[]],
                              company       : [[]],
                              bu            : [[]],
                              department    : [[]],
                              designation   : [[]],
                              grade         : [[]],
                              city          : [[]],
                              branch        : [[]],
                              applicable    : [false,[Validators.required]],

                            })
  companyDD :any =  [{
    id            : 0,
    company_name  : ''
  }];

  buDD = [{
    id          : 0,
    name        : ''
  }];

  DepDD = [{
    id          : 0,
    name        : ''
  }];

  DesignationDD = [{
    id          : 0,
    name        : ''
  }];

  BranchDD = [{
    id          : 0,
    name        : ''
  }];

  gradeDD = [{
    id           : 0,
    name         : ''
  }];
  employeeData :any   = [];
  exportData:any      = [];
  fileName: string    = '';
  fileSize: string    = '';
  invalidFormat       = false;
  excelData:any       = [];
  ws:any              = null;
  deleteToggle        = false;
  confirmMsg          = false;
  deleteMsg           = "";
  importTemp:any      = [];
  importData:any      = [];
  iscompleted         = false;
  successDataCount    = 0;
  isProgressStart     = false;
  chunkLen            = 10;
  percentage:any      = 0;

  failedData:any      = [];
  saveDataArray:any = []
  dateFlag = false


  get f(){ return this.templateForm.controls }

  disableDate(){
    return false;
  }

  fromDate(value:any){
    let from      = this.f.fromdate.value
    this.maxValue = moment(from).add(30, 'days')
    if(moment(this.f.fromdate.value).isSameOrAfter(moment(this.f.todate.value))){
      this.templateForm.patchValue({
        todate : null
      })
    }
  }
  getCompanyList(){
    this.employeeService.getCompanyList().subscribe((res: any) => {
      this.companyDD = res;
      this.selectAllForDropdownItems(this.companyDD);
    });
  }
  getbulist(data:any){
    this.buDD = []
    this.rs.getBuList(data).subscribe((res: any) => {
      this.buDD = res.body;
      this.selectAllForDropdownItems(this.buDD);
    });
    this.templateForm.get('bu')?.patchValue(this.buDD)
  }

  getDepList(){
    this.employeeService.getDepartmentList().subscribe((res: any) => {
          this.DepDD = res;
          this.selectAllForDropdownItems(this.DepDD);
    });
  }

  getDesignationList(){
    this.employeeService.getDesignationList().subscribe((res: any) => {
          this.DesignationDD = res;
          this.selectAllForDropdownItems(this.DesignationDD);
    });
  }
  getGradeList(){
    this.employeeService.getGradeList().subscribe((res: any) => {
          this.gradeDD = res;
          this.selectAllForDropdownItems(this.gradeDD);
    });
  }
  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }
  getEmployeeList(){
    this.rs.getempList(true).subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name']  = "";
            if(res.body[i]['first_name'] != null)
              res.body[i]['name'] += res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
              res.body[i]['name'] += res.body[i]['last_name'];
              res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";
          }
          this.employeeData = res.body;
          // this.selectAllForDropdownItems(this.employeeData);

        }
      }
    });
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  clearcommonForm(item:any,index:number){
    if(item == "company"){
      let daata = this.templateForm.value.company;

      daata.splice(index, 1);
      this.templateForm.patchValue({
        company: daata,
        bu:[]

      });
      this.getbulist(daata)
     } else if(item == "bu"){
      let daata = this.templateForm.value.bu;
      daata.splice(index, 1);
      this.templateForm.patchValue({
        bu: daata
      });
    } else if(item == "department"){
      let daata = this.templateForm.value.department;
      daata.splice(index, 1);
      this.templateForm.patchValue({
        department: daata
      });
    } else if(item == "designation"){
      let daata = this.templateForm.value.designation;
      daata.splice(index, 1);
      this.templateForm.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.templateForm.value.grade;
      daata.splice(index, 1);
      this.templateForm.patchValue({
        grade: daata
      });
    } else if(item == "branch"){
      let daata = this.templateForm.value.branch;
      daata.splice(index, 1);
      this.templateForm.patchValue({
        branch: daata
      });
    }
    else if(item == "employee"){
      let daata = this.templateForm.value.employee;
      daata.splice(index, 1);
      this.templateForm.patchValue({
        employee: daata
      });
    }
    this.initiateErrorStatus();
  }
  generateTemplate(){
    this.downloadLoader = true
    let filter = '?fromdate='+this.appService.dateFormatConvert(this.templateForm.value.fromdate)+'&todate='+this.appService.dateFormatConvert(this.templateForm.value.todate)+'&employee='+JSON.stringify(this.templateForm.value.employee)+'&company='+JSON.stringify(this.templateForm.value.company)+'&businessunit='+JSON.stringify(this.templateForm.value.bu)+'&department='+JSON.stringify(this.templateForm.value.department)+'&designation='+JSON.stringify(this.templateForm.value.designation)+'&grade='+JSON.stringify(this.templateForm.value.grade)+'&city='+JSON.stringify(this.templateForm.value.city)+'&branch='+JSON.stringify(this.templateForm.value.branch)+'&employment_status='+JSON.stringify(['Probation','Confirmed','Resigned']);

    this.empShift.generateShiftTemplate(filter).subscribe((res:any)=>{
      let header = res.body.headers;
      let body   = res.body.data;
      let excelTemp      = [];
      this.exportData = [];


      for (let i = 0; i < body.length; i++) {
        var dict:any = {};

        for(let j=0;j<header.length;j++){
          if(header[j]=='Employee Name')
          dict[header[j]] = body[i]['emp_name']
          else if(header[j]=='Employee Code')
          dict[header[j]] = body[i]['emp_code']
          else
          dict[header[j]] = '';

        }
        excelTemp.push({
          dict
        })

      }
      for(let j=0;j<excelTemp.length;j++){
        this.exportData.push(excelTemp[j]['dict'])
      }
      this.appService.exportExcel(this.exportData,'Shift Template');
      this.downloadLoader = false

    })



  }
  changeApplicable(){
    this.templateForm.get('company')?.reset([])
    this.templateForm.get('bu')?.reset([])
    this.templateForm.get('department')?.reset([])
    this.templateForm.get('designation')?.reset([])
    this.templateForm.get('branch')?.reset([])
    this.templateForm.get('grade')?.reset([])
    this.templateForm.get('employee')?.reset([])
  }
  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
  }
  onFileChange(evt: any) {
    this.excelData  = [];
    this.importData = []
    this.iscompleted = false
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){

      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      const file = evt.target.files[0];
      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true ,cellDates:true});
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];
        this.fileName   = target.files[0].name;
        this.fileSize   = this.appService.formatSizeUnits(target.files[0].size)

        const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: true, rawNumbers: false, blankrows: false });

        // Remove whitespaces from cells and filter out rows with all empty cells
        this.excelData = jsonData
        .map((row: any) => row.map((cell:any) => (typeof cell === 'string' ? cell.trim() : cell)))
         .filter(row => row.some((cell:any) => cell !== ''));
      this.changeDetectorRef.detectChanges();
        this.processData();

      };
      reader.readAsBinaryString(target.files[0]);

    }
    else{

      this.invalidFormat = true;
    }
  }
  processData(){
    if(this.excelData.length <=1){
      this.deleteToggle = true;
      this.deleteMsg    = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.excelData[0];
      let Heading:any ;

      Heading = ['Employee Code','Employee Name'];
      let success = Heading.every(function(val:any) {
        return headerRow.indexOf(val) !== -1;
      });
      let dateArr =[]
      dateArr.push(headerRow.slice(2))
      var date_regex = /^\d{4}[- /.]\d{2}[- /.]\d{2}$/ ;
      var times= 0
      for(let key  in dateArr[0]){
        ++times;
        let dateTostr = dateArr[0][key]
        this.invalid = date_regex.test(dateTostr);
        if(!this.invalid){
          break;
        }
      }
      if(times !== dateArr[0]?.length || !headerRow.slice(0,2).every((ele:any) =>Heading.includes(ele))){
        this.invalid = false
      }
      if(success == false || !this.invalid){
        this.deleteToggle = true;
        this.deleteMsg    = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else{
        this.prepareUploadData();

      }

    }
  }
  prepareUploadData(){

    let headerRow   = this.excelData[0];
    let header:any  = [];
    this.importTemp = [];
    this.importData = []

    for(let i=0;i<headerRow.length;i++){
      switch(headerRow[i]){
        case 'Employee Code':{
          header.push('employee_code');
          break;
        }
        case 'Employee Name':{
          header.push('employee_name');
          break;
        }
        default:{
          header.push(headerRow[i]);
          break;
        }
      }
    }
    this.excelData.forEach((item:any, key:any) => {
      var dict:any = {};
      for(let i=0;i<header.length;i++){
        dict[header[i]]= item[i] == undefined ? '' : item[i];
      }
      this.importTemp.push({
        dict
      })
    })
    for(let j=1;j<this.importTemp.length;j++){
      this.importData.push(this.importTemp[j]['dict'])
    }
  }

  uploadShift(){
    this.empShift.getShiftProcessData().subscribe((res:any) => {
      if(res.body.data?.percentage>0 && res.body.data?.percentage<100){
        this.notificationService.handleErrorNotification("Assigning Shift cannot be done until the existing shift assigning process is completed.","Assign Shift");
      } else {
        this.saveData()
      }
    })


  }
  saveData(){

    this.iscompleted = false
    if(this.importData.length>0){
    this.isProgressStart  = true
    this.failedData       = []
    this.saveDataArray = []
    this.empShift.uploadShift({'data':this.importData}).subscribe((res:any)=>{
    })
    this.startProgressBar()
    this.empShift.setProcessData(this.router.url,"Import")
    }
  }


backClicked() {
  if(this.previousRouteService.getPreviousUrl() == '/home' || this.previousRouteService.getPreviousUrl() == '/login')
    this.router.navigate(['employee-shift']);
  else
    this._location.back();
}

checkcommonFormValid(){
  if(this.f.applicable.value == false){
    if(this.f.company?.value?.length != 0 || this.f.bu?.value?.length != 0 || this.f.department?.value?.length != 0 || this.f.designation?.value?.length != 0 || this.f.grade?.value?.length != 0 || this.f.branch?.value?.length != 0 ){
      return false;
    } else{
      return true;
    }
  }else{
    if(this.f.employee?.value?.length != 0){
      return false
    }else{
      return true
    }
  }
}

dobFilter(d:any){
  const currentDate = new Date();
  const dateRange = [
    new Date(2020, 0, 1),
    currentDate
  ];
  return (d >= dateRange[0]);
}

 ngChange(e:any){
    if(this.f.applicable.value == true && this.hasSpecificStatus(e)){
      this.templateForm?.get('employee')?.setErrors({
        'relievedError' : true
      })
    }
  }

  hasSpecificStatus(employees:any){
    const statuses = ['Relieved','Settled','Terminated','Retired']
    return employees.some((employee:any)=> statuses.includes(employee.employment_status));
  }

  initiateErrorStatus(){
    if(this.f.employee.value != null && this.f.employee.value != ''){
      const filteredData = this.employeeData.filter((item:any) => this.f.employee.value.includes(item.id))
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData)){
          this.templateForm?.get('employee')?.setErrors({
            'relievedError' : true
          })
          this.templateForm.get('employee')?.markAsTouched();
          this.changeDetectorRef.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.employeeData.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }

showProgress = false
progPercentage:any = 0
timeLeft:any = ''
empCntB = 0
startProgressBar(){
  this.empShift.RUNBG = false
  let cnt = 0;
  this.empShift.setProgress(false);
  this.successDataCount = 0
  this.showProgress = true
    this.isProgressStart = true
    this.body =  "Shift data is processing..."
  this.empShift.processPercentage.subscribe(result =>{
    this.progPercentage = result.event.percentage
    this.body =  "Shift data processed for "+ result.event.uploaded_count +" out of "+result.event.employee_count+" employee(s)."
    this.successMsg = "Shift Assigned for "+ result.event.uploaded_count +" out of "+result.event.employee_count+" employee(s) successfully"
    if(result.event.percentage == 100 && cnt===0){
      this.timeLeft = ""
      this.isProgressStart = false
      this.iscompleted =true
      // this.errorLog = true;
      this.successDataCount = result.event.uploaded_count
      this.empCntB = result.event.employee_count
      cnt++
        this.checkProgress()
    }
    else{
      this.convertMinSecs(result.event.time_left)
    }
  },
  (error:any)=>{
  this.closeProgress();
  this.notificationService.handleErrorNotification('Something went wrong','Error')

  })
}
convertMinSecs(min:any){
  let time = min*60;
  var minutes = Math.floor(time / 60);
  var seconds:any = time - minutes * 60;
  seconds = seconds.toFixed(2)
  this.timeLeft = minutes+" min "+seconds+" sec "
}
closeProgress(){
  this.empShift.RUNBG = true
  this.empShift.setProgress(true);
  this.isProgressStart=false;
  this.showProgress = false
}
checkProgress(){
  if(!this.empShift.BGPROGRESS){
    this.empShift.getShiftProcessData().subscribe((res:any) => {
      this.errorList =res.body.data;
      if( res.body.data.percentage==100){
        this.empShift.setProgress(true);
        this.errorPopup();
        this.showProgress = false
      }
    });
  }
}

errorList:any = []

errorMsgA = ''
errorPopup(){
  this.exportData = []
  let sucLen = this.errorList?.error_list[0]?.failed_employee_count?this.empCntB-this.errorList?.error_list[0]?.failed_employee_count:this.empCntB
  this.successMsg = "Shift Assigned for "+ sucLen +" out of "+this.empCntB+" employee(s) successfully"
    this.errorLog = true;

  if(this.errorList.error_list[0]?.failed_employee_count>0){
    for (let i = 0; i < this.errorList.error_list[0]?.failed_employee_data.length; i++) {
      const orderedData : any = {};
      orderedData['Employee Name'] = this.errorList.error_list[0]?.failed_employee_data[i].data['employee_name']
      orderedData['Employee Code'] = this.errorList.error_list[0]?.failed_employee_data[i].data['employee_code']
      for (const key in this.errorList.error_list[0]?.failed_employee_data[i]['data']) {
        if (key !== 'employee_name' && key !== 'employee_code' && key !== 'error') {
          orderedData[key] = this.errorList.error_list[0]?.failed_employee_data[i]['data'][key];
        }
      }
      orderedData['Error Description'] = this.errorList.error_list[0]?.failed_employee_data[i].error
      this.exportData.push(orderedData);
    }
    this.errorMsgA =this.errorList?.error_list[0]?.failed_employee_count+' employee(s) have error data.'
  }
}
    }
