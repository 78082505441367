import { Component, OnInit, Input, EventEmitter,Output } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';

@Component({
  selector: 'app-gps-lost-table',
  templateUrl: './gps-lost-table.component.html',
  styleUrls: ['./gps-lost-table.component.scss']
})
export class GpsLostTableComponent implements OnInit {
  @Input() gpsdata:any;
  @Input() loadergps:any;
  @Output() closepanel      =  new EventEmitter();
  page = 1;
  pageSize: any = 20;
  searchString:any='';
  constructor(
    public appService: AppService,
    private livelocationService : LivelocationService,
  ) { }

  ngOnInit(): void {

  }
  closePanel(){
    this.closepanel.emit();
  }

  notifyemployee(id:any){
    this.livelocationService.notifyemp(id).subscribe((res: any) => {
    })
  }

}
