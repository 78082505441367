import { Component, OnInit,Input } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { Router } from '@angular/router';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout'
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-employee-report',
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.scss']
})
export class EmployeeReportComponent implements OnInit {

  employeereports:any[]              = [];
  loader                     = false;
  nodata                     = false;
  searchlistdata:any         = [];
  tasksearch:string          = this.messageService.searchdisplay('Reports') ;
  permissions:any            = [];
  schedulePermissions1:any   = [];

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength                 = 0;
  direction                  = '';
  defaultlimit:any           = 20;
  reoprtDetailsList:any      = [];
  infinityloader             = false;
  searchKeyword="";
  sLoading = false;

  constructor(public messageService : MessageService,public employeereportService:EmployeeReportsService, public router: Router, public breakpointObserver: BreakpointObserver, public appservice:AppService) { }

  ngOnInit(): void {

    this.getPermission();
    this.getScheduleReportPermission1();
    this.showDataItems();
  }

    getPermission(){
      this.permissions = this.appservice.getPermissions('');
      if(this.permissions == undefined){
        setTimeout(() => {
          this.getPermission();
        }, 1000);
      }
    }
    getScheduleReportPermission1(){
      this.schedulePermissions1 = this.appservice.getPermissions('/scheduled-reports');
      if(this.schedulePermissions1 == undefined){
        setTimeout(() => {
          this.getScheduleReportPermission1();
        }, 3000);
      }
    }

    showDataItems(){
      this.loader = true;
      this.employeereportService.reportTypeList('Employee',this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
        this.listlength        = res.results.length;
        if(this.offset ==0){
          this.employeereports = [];
        }
        if (res.results.length != 0) {
          this.reoprtDetailsList = res.results;
        }

        this.infinityloader    = false;
        this.addItems(this.listlength);
        if (res.results.length == 0) {
          this.nodata = this.offset == 0 ? true:false;
        }else{
          this.nodata = false;
        }
        this.loader            = false;
        this.sLoading          = false;
      });
      // this.employeereportService.EmployeeReportDropdown().subscribe((res: any) => {
      //   this.searchlistdata = res;
      // });
    }

    /********* INIFNITY SCROLL FUNCTION *******************/
   onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any){
    for (let i = 0; i < itemlist; i++) {
      this.employeereports.push(this.reoprtDetailsList[i]);
    }

  }


  reportTableView(reportname:any, reportid:any){
    if(this.permissions.v){
      if(reportid != undefined && reportid != null){
        reportname = reportname.replace(/\s/g, "");
        this.router.navigate(['/employee-report/employee-report-configure/'+reportname+'/'+reportid]);
      }
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.employeereports = [];
      this.reoprtDetailsList = [];
      this.showDataItems();
     }
    else{

      this.employeereportService.getSingleEmployeeReport(data).subscribe((res: any) => {
        this.employeereports = res;
        this.loader = false;
      });
    }
  }

  searchemployee(searchKeyword:any){
    this.sLoading = true;
    this.loader = true;
    this.offset           = 0;
    this.employeereports= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.showDataItems();
    }
  }


  }


