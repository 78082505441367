import { Component, OnInit, ViewChild,Inject, EventEmitter, Output,Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-monthly-tds',
  templateUrl: './monthly-tds.component.html',
  styleUrls: ['./monthly-tds.component.scss']
})
export class MonthlyTDSComponent implements OnInit {

   // Data from parent
  @Input() companyDD:any
  @Input() gradeDD:any
  @Input() cityDD:any
  @Input() BranchDD:any
  @Input() stateDD:any
  @Input() buDD:any
  @Input() countryDD:any
  @Input() DepDD:any
  @Input() DesignationDD:any
  @Input() checkListElements:any
  @Input() disabled :any
  @Input() empstatusDD:any
  @Input() emptypeDD:any
  @Input() employeeDropdown:any
  // Above needs to be cleared
  @Input() monthlyTDSForm : any
  @Input() salFilter : any
  @Input() viewDetail:any
  @Input() callMonthlyTDS :any
  @Input() closeControl:any
  @Input() empstatArr:any
  @Input() tempArr:any
  @Input() submitted :any
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() ouFilterForm: any
  @Input() Years : any;

  @Output() filterPanel = new EventEmitter();
  @Output() callMonthlyReport = new EventEmitter();
  @Output() submitDone = new EventEmitter();
  @Output()  resetFilter = new EventEmitter();
  @Input() resDataFilter:any;

  @ViewChild('scrollElement') scrollElement!: ElementRef;


  dateFormat : any
  attendListOfMonth:string  = "listOfMonth";
  year_placeholder = 'Select year'
  month_placeholder = 'Select month'
  monthValue : any
  selectedYear = new Date().getFullYear();

  get f()  { return this.monthlyTDSForm.controls; }

  constructor(
    private reportService:ReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService,
    public cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getDateFormat()
  }

  getDateFormat(){
    this.dateFormat = this.appservice.getdatepickerformat();
    if(this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  applyBtn : boolean = false

  filterFormData(use:any){
    if(this.monthlyTDSForm.controls.salary_month.value == true){
      this.monthlyTDSForm.get('salary_month')?.setValue(this.monthlyTDSForm.controls.specific_year.value+'-'+this.monthValue)
    }
    else{
      this.monthlyTDSForm.get('salary_month')?.setValue(this.monthlyTDSForm.controls.salary_month.value)
    }
    let salary_month    = this.monthlyTDSForm.controls.salary_month.value == null ? '' : this.monthlyTDSForm.controls.salary_month.value;
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    if(use == 'filter'){
      return `salary_month=${salary_month}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'salary_month' : salary_month,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

  // Filters Data Submit
   submitMonthlyTDS(){
    this.submitted          = true;
    if (this.monthlyTDSForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    })
    this.viewDetail           = false;
    this.applyBtn = false
  }

  // Emiting the value of radio to parent
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthtype:any,yeartype:any){
    if(e.target.value == 'on' ){
      this.monthlyTDSForm?.get(contrlName)?.setValue(true);
     }
     else{
      this.monthlyTDSForm?.get(contrlName)?.setValue(val);
      this.monthlyTDSForm?.get(monthtype)?.setValue(null);
      this.monthlyTDSForm?.get(yeartype)?.setValue(null);
     }
    this.cd.detectChanges()
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
    this.setRequired(false)
  }

  // Resetting the filters
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.monthlyTDSForm.get('salary_month')?.reset('this_month');
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.monthlyTDSForm.get('specific_month')?.reset(null);
      this.monthlyTDSForm.get('specific_year')?.reset(null);
    }
    this.callMonthlyReport.emit({
      reportName : 'Monthly TDS Deduction Report'
    })
  }

  // Tags closing
  applyTagClose(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }
    this.ouFilterForm.get(this.closeControl)?.setValue('');
    this.submitMonthlyTDS();
  }

  resetPage(){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'salary_month' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  monthChangeFn(event:any){
    // this.monthValue = (new Date(Date.parse(event +" 1, "+this.selectedYear+"")).getMonth()+1);
    var date = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    this.monthValue = String(date.getMonth() + 1).padStart(2, '0');
  }

  setRequired(val:any){
    var arr = ['specific_month','specific_year']
    if(val){
      arr.forEach((item:any)=>{
        this.monthlyTDSForm.get(item)?.setValidators([Validators.required]);
        this.monthlyTDSForm.get(item)?.markAsTouched();
        this.monthlyTDSForm.get(item)?.updateValueAndValidity();
      })
    }else{
      arr.forEach((item:any)=>{
        this.monthlyTDSForm.get(item)?.clearValidators()
        this.monthlyTDSForm.get(item)?.updateValueAndValidity();
      })
    }
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}


