import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CropperService {

  constructor(
    private http: HttpClient,

  ) { }
  round: any = true;
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  countryList() {
    return this.round;
  }

  FileUpload(body: any) {
    return this.http.post(`${this.API_PATH}api/v1/azure-upload/`, body, this.httpOption)
  }
}
