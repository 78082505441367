<form class="" [formGroup]="formGp">
  <div class="container-fluid p-32 form-container">
    <app-c1-loader *ngIf="loader"></app-c1-loader>
    <div class="row row-16 mb-16" *ngIf="!loader">
      <div class="col-12">
        <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
          <p class="mb-0"> {{errorMsg}}
          </p>
          <div class="btn-close" (click)="alertShow='hide'"></div>
        </div>
      </div>
      <div class="col-12 d-flex">
        <p class="text-uppercase fw-500 mb-0">WORKDAYS PAYABLE</p>
      </div>
      <div class="col-12">
        <div class="card card-c2 details-card  px-24 py-16 ">
          <div class="row row-24">
            <div class="col-12">
              <span class="title">SUMMARY</span>
            </div>
            <div class="col-md-3 col-sm-4 col-6">
              <div class="detail-title">Notice period</div>
              <div class="detail-desc">{{workDaysPayableData?.notice_period}} days</div>
            </div>
            <div class="col-md-3 col-sm-4 col-6">
              <div class="detail-title">No of days served</div>
              <div class="detail-desc">{{workDaysPayableData?.no_of_days_served}} days</div>
            </div>
            <div class="col-md-3 col-sm-4 col-6">
              <div class="detail-title">Shortfall in notice</div>
              <div class="detail-desc">{{workDaysPayableData?.shortfall_in_notice}} days</div>
            </div>
            <div class="col-md-3 col-sm-4 col-6">
              <div class="detail-title">Excess days served</div>
              <div class="detail-desc">{{workDaysPayableData?.excess_days_served}} days</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="card card-c2">
          <div class="card-body hstack indicater indicater-bg8">
            <label for="" class="form-label required text-uppercase my-2 fw-600">ATTENDANCE CORrECTION ARREAR </label>
            <div class="ms-auto gap-16 hstack">
              <button class="btn btn-primary btn-sm ms-auto text-uppercase">calculate</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-c2">
          <div class="card-body hstack indicater indicater-bg6">
            <label for="" class="form-label required text-uppercase my-2 fw-600">ATTENDANCE </label>
            <div class="ms-auto gap-16 hstack">
              <button class="btn btn-primary btn-sm ms-auto text-uppercase">calculate</button>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-12">
        <div class="card card-c2">
          <div class="card-body hstack indicater indicater-bg8">
            <label for="" class="form-label required text-uppercase my-2 fw-600">ATTENDANCE CORRECTION ARREAR </label>
            <div class="ms-auto gap-16 hstack">
              <button class="btn btn-outline-danger text-uppercase btn-sm">IGNORE</button>
              <button class="btn btn-primary text-uppercase btn-sm">Process</button>
            </div>
          </div>
          <div class="card-body ">
            <div class="row row-16">
              <div class="col-12">
                <p class="mb-0 fw-500">Details</p>
              </div>
              <div class="col-12">
                <div class="gap-16 hstack flex-wrap">
                  <label class="card card-c2 hstack checkbox-22 p-8 gap-8" for="id1">
                    <input class="form-check-input m-0" type="checkbox" value="" id="id1">
                    December 2022
                  </label>
                  <label class="card card-c2 hstack checkbox-22 p-8 gap-8" for="id2">
                    <input class="form-check-input m-0" type="checkbox" value="" id="id2">
                    November 2022
                  </label>
                  <label class="card card-c2 hstack checkbox-22 p-8 gap-8" for="id3">
                    <input class="form-check-input m-0" type="checkbox" value="" id="id3">
                    October 2022
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-c2">
          <div class="card-body hstack indicater indicater-bg6">
            <label for="" class="form-label required text-uppercase my-2 fw-600">ATTENDANCE </label>
          </div>
          <div class="card-body ">
            <div class="row row-16">
              <div class="col-12">
                <p class="mb-0 fw-500">Details</p>
              </div>
              <div class="col-12">
                <div class="gap-16 hstack flex-wrap">
                  <label class="card card-c2 hstack checkbox-22 p-8 gap-8" for="id1">
                    <input class="form-check-input m-0" type="checkbox" value="" id="id1">
                    December 2022
                  </label>
                  <label class="card card-c2 hstack checkbox-22 p-8 gap-8" for="id2">
                    <input class="form-check-input m-0" type="checkbox" value="" id="id2">
                    November 2022
                  </label>
                  <label class="card card-c2 hstack checkbox-22 p-8 gap-8" for="id3">
                    <input class="form-check-input m-0" type="checkbox" value="" id="id3">
                    October 2022
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <ng-container *ngIf="workDaysPayableData?.attendance_lop == false">
      <div class="col-12">
        <div class="card card-c2">
          <div class="card-body hstack indicater indicater-bg8">
            <label for="" class="form-label required text-uppercase my-2 fw-600">ATTENDANCE CORRECTION ARREAR </label>
            <div class="ms-auto gap-16 hstack">
              <button class="btn btn-outline-danger text-uppercase btn-sm" (click)="arrearIgnored()" *ngIf="arrearprocessFlag == false && arrearprocessFlag != null">IGNORE</button>
              <button class="btn btn-primary text-uppercase btn-sm" (click)="arrearProcessFunction()" *ngIf="arrearprocessFlag == false && arrearprocessFlag != null">Process</button>
              <button class="btn btn-primary text-uppercase btn-sm" (click)="arrearUnprocessFunction()" *ngIf="arrearprocessFlag == true && arrearprocessFlag != null">Unprocess</button>
            </div>
          </div>
          <div class="card-body ">
            <div class="row row-16">
              <div class="col-12">
                <p class="mb-0 fw-500">Arrear Details</p>
              </div>
              <app-table-loader *ngIf="loader"></app-table-loader>

              <div class="col-12" *ngIf="!loader">
                <div class="card card-c2 p-0">
                  <div class="table-responsive  scrollbar-10 radius-4">
                    <table class="table sticky-header table-striped table-sm  td-width-15ch
                    ">

                      <thead>
                        <tr>
                          <th class="fw-600  checkbox-22 " style="width:3rem" scope="col">
                            <input class="form-check-input checkbox-16 m-0" type="checkbox"
                            (change)="checkall($event)" [checked]="checkAllSelected()"
                            value="" *ngIf="workDaysPayableData?.fnf_reversal_lop?.length != 0">
                          </th>
                          <th class="fw-600" scope="col">MONTH AND YEAR</th>
                          <th class="fw-600" scope="col">Arrear days</th>
                          <th class="fw-600" scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="workDaysPayableData?.fnf_reversal_lop?.length == 0">
                          <td colspan="4">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                              </div>
                          </td>
                      </tr>
                        <tr *ngFor="let items of workDaysPayableData?.fnf_reversal_lop; let i = index">
                          <!-- <ng-container *ngIf="items?.status != 'No Arrears'"> -->
                          <td class="checkbox-22" style="width:3rem">
                            <input class="form-check-input mt-1 checkbox-16"
                            [(ngModel)]="items.checked" type="checkbox"
                            (click)="viewButton($event,items?.status,items?.checked)" [ngModelOptions]="{standalone: true}" *ngIf="items?.status != 'Ignored'">
                          </td>
                          <td>{{items?.lop_month}} {{items?.lop_year}}</td>
                          <td *ngIf="items?.status != 'Completed'">{{items?.arrear_days}}
                            <!-- <ng-container *ngIf="items?.status == 'Completed'">
                              <ng-template #popContent >
                                <div class="d-flex flex-column flex-center" >
                                    <span class="fs-14 text-center">Actual days : {{items?.original_arrear_days}}</span>

                                </div>
                                </ng-template>
                          <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                          </ng-container> -->
                          </td>
                          <td *ngIf="items?.status == 'Completed'">
                            <div class="form-icon icon-right {{errFlag == true  ?'is-invalid':''}}">
                              <input  type="text" value="{{items.arrear_days}}" [(ngModel)]="items.arrear_days"  [ngModelOptions]="{standalone: true}" (keyup)="arrearProcessOveride(i)" class="hasError form-control {{errFlag == true  ?'is-invalid':''}}">

                                      <ng-template #popContent >
                                        <div class="d-flex flex-column flex-center" >
                                            <span class="fs-14 text-center">Actual days : {{items?.original_arrear_days}}</span>
                                          </div>
                                        </ng-template>
                                        <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover" *ngIf="items.arrear_days != items?.original_arrear_days"></i>
                                      </div>
                                      <div *ngIf="errFlag == true" class="invalid-feedback">
                                        <div *ngIf="errFlag == true">{{errValidation}}</div>
                                      </div>
                          </td>
                          <td>
                            <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
                            *ngIf="items?.status == 'Pending'">Pending</span>
                        <span class="badge px-16 ms-auto fs-14 badge-success py-1"
                            *ngIf="items?.status == 'Completed'">Processed</span>
                        <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
                            *ngIf="items?.status == 'Ignored'">Ignored</span>

                          </td>
                        <!-- </ng-container> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-c2">
          <div class="card-body hstack indicater indicater-bg6" >
            <label for="" class="form-label required text-uppercase my-2 fw-600">ATTENDANCE </label>
            <div class="ms-auto gap-16 hstack">

              <button class="btn btn-primary text-uppercase btn-sm" (click)="attendanceArr?.attendance_processed == false? attendanceProcessFunction():attendanceUnProcessFunction()">{{attendanceArr?.attendance_processed == false? 'Process':'Unprocess'}}</button>
            </div>
          </div>

          <div class="card-body " *ngIf="attendanceArr?.attendance_processed == true">
            <div class="row row-16">
              <div class="col-12">
                <p class="mb-0 fw-500">Attendance Details</p>
              </div>
              <div class="col-12">
                <div class="card card-c2 p-16 hstack gap-16 indicater indicater-bg8">
                  <p class="mb-0 fw-600 fs-14 text-uppercase">Total payable days</p>
                  <div class="d-flex gap-16 align-items-center ms-auto">
                    <span class="fs-24">{{attendanceArr.actual_paid_days}} </span><i (click)="overtimeHours = true;attendErrFlag = false" class="icon-pencil-square link-primary "></i>
                  </div>
                  </div>
                  </div>
              <div class="col-12">
                <p class="mb-0 fw-500">Overtime Details</p>

              </div>
              <div class="col-12">
                <div class="card card-c2 p-0">
                  <div class="table-responsive  scrollbar-10 radius-4">
                    <table class="table sticky-header table-striped table-sm td-white-space td-width-15ch
                    ">
                      <thead>
                        <tr>
                          <th class="fw-600" scope="col">OVERTIME MONTHS</th>
                          <th class="fw-600" scope="col">OVERTIME HOURS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="attendanceArr?.overtime?.length == 0">
                          <td colspan="3">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                              </div>
                          </td>
                      </tr>
                        <tr *ngFor="let item of attendanceArr?.overtime; let i=index">
                          <td>
                            <!-- <div class="d-flex align-items-center"> -->
                              {{item?.overtime_month}} {{item?.overtime_year}}
                            <!-- </div> -->
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              {{item?.total_overtime}}
                              <ng-template #popContent >
                                <div class="d-flex flex-column flex-center" >
                                    <span class="fs-14 text-center">Actual hours : {{item?.original_total_overtime}}</span>
                                  </div>
                                </ng-template>
                                <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"  *ngIf="item.total_overtime != item.original_total_overtime"></i>
                                 <i (click)="totalPaidDays = true;overtimedata(item?.overtime_process_id)"
                                class="icon-pencil-square link-primary ms-auto"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="workDaysPayableData?.attendance_lop == true">
      <div class="col-12">
        <div class="card card-c2 details-card  px-24 py-16 ">
          <div class="row row-24">
            <div class="col-12">
              <span class="title">TOTAL PAYABLE DAYS</span>
            </div>

            <div class="col-12">
              <div class="detail-title">Settlement month</div>
              <div class="detail-desc">{{resignationDetails?.value?.pay_month}} {{resignationDetails?.value?.pay_year}}</div>
            </div>

            <!-- <div class="col-lg-12 form-row">
              <label for="" class="form-label">Settlement month</label>
              <p>{{resignationDetails?.value?.pay_month}} {{resignationDetails?.value?.pay_year}}</p>
              <input [readonly]="true" type="text" class="form-control " placeholder="" placeholder=" " formControlName="pay_month">

            </div> -->
            <div class=" col-lg-12 form-row">
              <label for="" class="form-label required">Present days </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                  ngbPopover="The No.of days the employee is present during the attendance month"
                  popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                  container="body"></i></span>
              <div class="form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.present_days.dirty || f.present_days.touched) && f.present_days.errors}">
                <input type="text" class="form-control "
                  placeholder="{{this.messageService.placeholderdisp('Present days')}}" formControlName="present_days"
                  [ngClass]="{ 'is-invalid': (submitted || f.present_days.dirty || f.present_days.touched) && f.present_days.errors}"
                  (keyup)="computeTotalPayableDays($event,formGp.get('present_days')?.value,formGp.get('weekoff_days')?.value,'present_days')">
                <span>days</span>
              </div>
              <div *ngIf="(submitted || f.present_days.dirty || f.present_days.touched) && f.present_days.errors"
                class="invalid-feedback">
                <div *ngIf="f.present_days.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.present_days.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.present_days.errors?.pattern) && f.present_days.errors?.max">Total payable days cannot
                  be more than the total days for which employee has worked in the organization.</div>
              </div>
            </div>
            <div class=" col-lg-6 form-row">
              <label for="" class="form-label required">Weekoff’s </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                  ngbPopover="No. of weekoffs in the attendance month ( eg: sat and sunday)"
                  popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                  container="body"></i></span>
              <div class="form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.weekoff_days.dirty || f.weekoff_days.touched) && f.weekoff_days.errors }">
                <input type="text" class="form-control "
                  placeholder="{{this.messageService.placeholderdisp('Weekoff’s')}}" formControlName="weekoff_days"
                  [ngClass]="{ 'is-invalid': (submitted || f.weekoff_days.dirty || f.weekoff_days.touched) && f.weekoff_days.errors }"
                  (keyup)="computeTotalPayableDays($event,formGp.get('present_days')?.value,formGp.get('weekoff_days')?.value,'weekoff_days')">
                <span>days</span>
              </div>
              <div *ngIf="(submitted || f.weekoff_days.dirty || f.weekoff_days.touched) && f.weekoff_days.errors "
                class="invalid-feedback">
                <div *ngIf="f.weekoff_days.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.weekoff_days.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="(!f.weekoff_days.errors?.pattern) && f.weekoff_days.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','2')}}</div>
              </div>
            </div>
            <div class=" col-lg-6 form-row">
              <label for="" class="form-label required">LOP days </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                  ngbPopover="No. of LOP days in the attendance month." popoverClass="popover-default popover-info"
                  triggers="mouseenter:mouseleave" container="body"></i></span>
              <div class="form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.lop_days.dirty || f.lop_days.touched) && f.lop_days.errors}">
                <input type="text" class="form-control "
                  placeholder="{{this.messageService.placeholderdisp('LOP days')}}" formControlName="lop_days"
                  [ngClass]="{ 'is-invalid': (submitted || f.lop_days.dirty || f.lop_days.touched) && f.lop_days.errors}"
                  (keyup)="keyUpLopDays($event, formGp.get('lop_days')?.value)">
                <span>days</span>
              </div>
              <div *ngIf="(submitted || f.lop_days.dirty || f.lop_days.touched) && f.lop_days.errors"
                class="invalid-feedback">
                <div *ngIf="f.lop_days.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.lop_days.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="(!f.lop_days.errors?.pattern) && f.lop_days.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','2')}}</div>
              </div>
            </div>
            <ng-container formArrayName="fnf_reversal_lop" *ngFor="let item of reversalLOP().controls; let i=index; ">
              <ng-container [formGroupName]="i">
                <div class=" col-md-6 form-row">
                  <label for="" class="form-label required">LOP reversal days </label>
                </div>
                <div class=" col-lg-6 form-row">
                  <label for="" class="form-label required">LOP month for reversal</label>
                </div>
                <div class="col-12 mt-0">
                  <div class="row row-16 d-flex flex-nowrap">
                    <div class=" col-6 ">
                      <input type="text" class="form-control" autocomplete="off" name="reversal_days" id="reversal_days"
                        placeholder="{{this.messageService.placeholderdisp('LOP reversal days')}}"
                        formControlName="reversal_days"
                        [ngClass]="{ 'is-invalid': (submitted || reversalLOP().controls[i].get('reversal_days')?.dirty || reversalLOP().controls[i].get('reversal_days')?.touched) &&  reversalLOP().controls[i].get('reversal_days')?.errors}"
                        (keyup)="lopReversalDaysKeyUp(i)">
                      <div
                        *ngIf="(submitted || reversalLOP().controls[i].get('reversal_days')?.dirty || reversalLOP().controls[i].get('reversal_days')?.touched) && reversalLOP().controls[i].get('reversal_days')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="reversalLOP().controls[i].get('reversal_days')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="reversalLOP().controls[i].get('reversal_days')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                        <div
                          *ngIf="!(reversalLOP().controls[i].get('reversal_days')?.errors?.pattern) && reversalLOP().controls[i].get('reversal_days')?.errors?.max">
                          LOP reversal days cannot exceed the actual LOP days.
                        </div>
                      </div>
                    </div>
                    <div class=" col ">

                      <!-- test start -->
                      <!-- <div class=" col "> -->
                        <input type="hidden" class="form-control" formControlName="pay_year">
                        <input type="hidden" class="form-control" formControlName="pay_month" >
                        <div ngbDropdown  [ngClass]="{ 'is-invalid': reversalLOP().controls[i].get('pay_month')?.errors}" class="input-dropdown four-dropdown " container="body">
                          <button type="button" [ngClass]="{ 'is-invalid': reversalLOP().controls[i].get('pay_month')?.errors}" class="card card-c2  fs-14" ngbDropdownToggle>
                              <span class="">{{reversalLOP().controls[i].get('pay_month')?.value}} {{reversalLOP().controls[i].get('pay_year')?.value}}</span>
                          </button>
                          <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                          <div class="row">
                            <div class="col-5">
                              <label for="" class="form-label">Year </label>
                              <ng-select class="form-ngselect fs-14" placeholder="Select year" [ngModelOptions]="{standalone: true}" [ngModel]="reversalLOP().controls[i].get('pay_year')?.value" (change)="changeCarousel($event,'year',i)">
                                  <ng-option *ngFor="let yData of salaryYears" [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                            </div>
                            <div class="col-7">
                              <label for="" class="form-label">Month </label>
                              <ng-select class="form-ngselect  fs-14"  placeholder="Select month"  [ngModelOptions]="{standalone: true}" [ngModel]="reversalLOP().controls[i].get('pay_month')?.value" (change)="changeCarousel($event,'month',i)">
                                  <ng-option *ngFor="let yData of months[i]"  [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div *ngIf="reversalLOP().controls[i].get('pay_month')?.errors" class="invalid-feedback">
                          <div *ngIf="reversalLOP().controls[i].get('pay_month')?.errors">{{this.messageService.greaterThanMessage('From month','To month')}}</div>
                        </div>
                      <!-- </div> -->
                       <!-- test end -->


                      <!-- <input type="hidden" class="form-control" formControlName="pay_year"
                        [(ngModel)]="lopMonthArray[i]['year']">
                      <input type="hidden" class="form-control" formControlName="pay_month"
                        [(ngModel)]="lopMonthArray[i]['month']">
                      <div ngbDropdown class="input-dropdown four-dropdown " container="body">
                        <button type="button" class="card card-c2  fs-14" ngbDropdownToggle>
                          <span class="">{{lopMonthArray[i]['month']}} {{lopMonthArray[i]['year']}}</span>
                        </button>
                        <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                          <div class="row">
                            <div class="col-5">
                              <label for="" class="form-label">Year </label>
                              <ng-select class="form-ngselect fs-14" placeholder="Select year"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="lopMonthArray[i]['year']"
                                (change)="changeCarousel($event,'year',i)">
                                <ng-option *ngFor="let yData of salaryYears" [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                            </div>
                            <div class="col-7">
                              <label for="" class="form-label">Month </label>
                              <ng-select class="form-ngselect  fs-14" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="lopMonthArray[i]['month']" (change)="changeCarousel($event,'month',i)">
                                <ng-option *ngFor="let yData of months[i]" [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <!-- test end -->
                    </div>
                    <a *ngIf="i!=0" class="sq-40 flex-center link-danger" (click)="removeLOPreserveal(i)"><i
                        class="icon-trash"></i></a>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="col-12 mt-8">
              <div class="link-primary1 fs-14" (click)="addLOPreserveal('add','','')">
                <i class="icon-plus-square-fill me-8"></i>Add LOP reversal month and day
              </div>
            </div>
            <div class=" col-12 form-row">
              <label for="" class="form-label required">Total payable days
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                    ngbPopover="Total Payable Days = Present days+ Week-offs (If weekends are paid). Total Payable Days = Present days (If weekends are not paid)"
                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"
                    container="body"></i></span>
              </label>
              <div class="form-icon icon-right">
                <input [readonly]="true" type="text" class="form-control " placeholder=" "
                  formControlName="total_payable_days">
                <span>days</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex gap-16">
      <button class="btn btn-outline-danger btn-sm ms-auto"
      (click)="lopDetailsResetConfirm()" >RESET</button>
    </div>
    </ng-container>
    </div>
  </div>
  <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto"
      (click)="submitWorkDaysPayableDetails()" [disabled]="!attendanceProcessFlag" *ngIf="workDaysPayableData?.attendance_lop == false">NEXT</button>
      <button class="btn btn-primary btn-sm ms-auto"
      (click)="submitWorkDaysPayableDetails()"  *ngIf="workDaysPayableData?.attendance_lop == true">NEXT</button>
  </footer>
</form>
<form class="" [formGroup]="attendanceEditForm">
<div class="modal  modal-alert {{overtimeHours == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Edit</h4>
      </div>
        <div class="col-12" *ngIf="attendErrFlag">
                <div role="alert" class="alert alert-warning mb-0 show">
                    <p class="mb-0"> {{attendanceErrMsg}}
                    </p>
                </div>
            </div>
      <div class="modal-body">
        <div class="row row-16">
          <div class="col-12">
            <label for="" class="form-label required">Present days </label>
            <input type="text" class="form-control" placeholder="Enter count " formControlName="prasent_days" [(ngModel)]="attendanceArr.prasent_days"  [ngClass]="{ 'is-invalid': (attendanceEditForm.controls.prasent_days?.touched || attendanceEditForm.controls.prasent_days?.dirty) &&  attendanceEditForm.controls.prasent_days?.errors }"  >
            <div *ngIf="(attendanceEditForm.controls.prasent_days?.touched || attendanceEditForm.controls.prasent_days?.dirty) && attendanceEditForm.controls.prasent_days?.errors"
            class="invalid-feedback">
            <div *ngIf="attendanceEditForm.controls.prasent_days?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="attendanceEditForm.controls.prasent_days?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                  *ngIf="(!attendanceEditForm.controls.prasent_days?.errors?.pattern) && attendanceEditForm.controls.prasent_days?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Week off </label>
            <input type="text" class="form-control" placeholder="Enter count " formControlName="weekly_offs"  [ngClass]="{ 'is-invalid': (attendanceEditForm.controls.weekly_offs?.touched || attendanceEditForm.controls.weekly_offs?.dirty) &&  attendanceEditForm.controls.weekly_offs?.errors }" [(ngModel)]="attendanceArr.weekly_offs">
            <div *ngIf="(attendanceEditForm.controls.weekly_offs?.touched || attendanceEditForm.controls.weekly_offs?.dirty) && attendanceEditForm.controls.weekly_offs?.errors"
            class="invalid-feedback">
            <div *ngIf="attendanceEditForm.controls.weekly_offs?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="attendanceEditForm.controls.weekly_offs?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                  *ngIf="(!attendanceEditForm.controls.weekly_offs?.errors?.pattern) && attendanceEditForm.controls.weekly_offs?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Holidays </label>
            <input type="text" class="form-control" placeholder="Enter count " formControlName="holiday_paid"  [ngClass]="{ 'is-invalid': (attendanceEditForm.controls.holiday_paid?.touched || attendanceEditForm.controls.holiday_paid?.dirty) &&  attendanceEditForm.controls.holiday_paid?.errors }" [(ngModel)]="attendanceArr.holiday_paid">
            <div *ngIf="(attendanceEditForm.controls.holiday_paid?.touched || attendanceEditForm.controls.holiday_paid?.dirty) && attendanceEditForm.controls.holiday_paid?.errors"
            class="invalid-feedback">
            <div *ngIf="attendanceEditForm.controls.holiday_paid?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="attendanceEditForm.controls.holiday_paid?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                  *ngIf="(!attendanceEditForm.controls.weekly_offs?.errors?.pattern) && attendanceEditForm.controls.holiday_paid?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Paid leave </label>
            <input type="text" class="form-control" placeholder="Enter count " formControlName="leave_paid"  [ngClass]="{ 'is-invalid': (attendanceEditForm.controls.leave_paid?.touched || attendanceEditForm.controls.leave_paid?.dirty) &&  attendanceEditForm.controls.leave_paid?.errors }"  [(ngModel)]="attendanceArr.leave_paid">
            <div *ngIf="(attendanceEditForm.controls.leave_paid?.touched || attendanceEditForm.controls.leave_paid?.dirty) && attendanceEditForm.controls.leave_paid?.errors"
            class="invalid-feedback">
            <div *ngIf="attendanceEditForm.controls.leave_paid?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="attendanceEditForm.controls.leave_paid?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                  *ngIf="(!attendanceEditForm.controls.weekly_offs?.errors?.pattern) && attendanceEditForm.controls.leave_paid?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                </div>
          </div>

          
          <div class="col-12">
            <label for="" class="form-label required">Absent </label>
            <input type="text" class="form-control" placeholder="Enter count " formControlName="absent_unpaid"  [ngClass]="{ 'is-invalid': (attendanceEditForm.controls.absent_unpaid?.touched || attendanceEditForm.controls.absent_unpaid?.dirty) &&  attendanceEditForm.controls.absent_unpaid?.errors }"  [(ngModel)]="attendanceArr.absent_unpaid">
            <div *ngIf="(attendanceEditForm.controls.absent_unpaid?.touched || attendanceEditForm.controls.absent_unpaid?.dirty) && attendanceEditForm.controls.absent_unpaid?.errors"
            class="invalid-feedback">
            <div *ngIf="attendanceEditForm.controls.absent_unpaid?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="attendanceEditForm.controls.absent_unpaid?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                  *ngIf="(!attendanceEditForm.controls.weekly_offs?.errors?.pattern) && attendanceEditForm.controls.absent_unpaid?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                </div>
          </div>

          <div class="col-12">
            <label for="" class="form-label required">Unpaid leave </label>
            <input type="text" class="form-control" placeholder="Enter count " formControlName="leave_unpaid"  [ngClass]="{ 'is-invalid': (attendanceEditForm.controls.leave_unpaid?.touched || attendanceEditForm.controls.leave_unpaid?.dirty) &&  attendanceEditForm.controls.leave_unpaid?.errors }"  [(ngModel)]="attendanceArr.leave_unpaid">
            <div *ngIf="(attendanceEditForm.controls.leave_unpaid?.touched || attendanceEditForm.controls.leave_unpaid?.dirty) && attendanceEditForm.controls.leave_unpaid?.errors"
            class="invalid-feedback">
            <div *ngIf="attendanceEditForm.controls.leave_unpaid?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="attendanceEditForm.controls.leave_unpaid?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                  *ngIf="(!attendanceEditForm.controls.weekly_offs?.errors?.pattern) && attendanceEditForm.controls.leave_unpaid?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                </div>
          </div>

          <div class="col-12">
            <label for="" class="form-label required">Paid days deduction </label>
            <input type="text" class="form-control" placeholder="Enter count " formControlName="deduction_paid_days"  [ngClass]="{ 'is-invalid': (attendanceEditForm.controls.deduction_paid_days?.touched || attendanceEditForm.controls.deduction_paid_days?.dirty) &&  attendanceEditForm.controls.deduction_paid_days?.errors }"  [(ngModel)]="attendanceArr.deduction_paid_days">
            <div *ngIf="(attendanceEditForm.controls.deduction_paid_days?.touched || attendanceEditForm.controls.deduction_paid_days?.dirty) && attendanceEditForm.controls.deduction_paid_days?.errors"
            class="invalid-feedback">
            <div *ngIf="attendanceEditForm.controls.deduction_paid_days?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="attendanceEditForm.controls.deduction_paid_days?.errors?.pattern">
                  {{this.messageService.validationDisplay('pattern')}}</div>
                  <div
                  *ngIf="(!attendanceEditForm.controls.weekly_offs?.errors?.pattern) && attendanceEditForm.controls.deduction_paid_days?.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                </div>
          </div>
           

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="overtimeHours = false"
          class="btn btn-outline-primary text-uppercase btn-sm">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase btn-sm" (click)="attendanceOveride();" [disabled]="!attendanceEditForm.valid">save</button>
      </div>
    </div>
  </div>
</div>
</form>

<form class="" [formGroup]="overtimeForm">
<div class="modal  modal-alert {{totalPaidDays == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Edit</h4>
      </div>
      <div class="col-12" *ngIf="overtimeErrFlag">
        <div role="alert" class="alert alert-warning mb-0 show">
            <p class="mb-0"> {{overtimeErrMsg}}
            </p>
        </div>
    </div>
      <div class="modal-body">
        <div class="row row-16">
          <div class="col-12">
            <label for="" class="form-label required">Working Day OT Hours </label>
            <div class="form-icon icon-right"   [ngClass]="{ 'is-invalid': (overtimeForm.controls.working_day_ot_hours?.touched || overtimeForm.controls.working_day_ot_hours?.dirty) &&  overtimeForm.controls.working_day_ot_hours?.errors }">
              <igx-time-picker formControlName="working_day_ot_hours"  placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (overtimeForm.controls.working_day_ot_hours?.touched || overtimeForm.controls.working_day_ot_hours?.dirty) &&  overtimeForm.controls.working_day_ot_hours?.errors }" class="form-control form-timepicker " (click)="picker1.open()" #picker1 ></igx-time-picker>
            </div>
            <div *ngIf="(overtimeForm.controls.working_day_ot_hours?.touched || overtimeForm.controls.working_day_ot_hours?.dirty) &&  overtimeForm.controls.working_day_ot_hours?.errors"
            class="invalid-feedback">
            <div *ngIf="overtimeForm.controls.working_day_ot_hours?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
          </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Weekly Off OT Hours </label>
             <div class="form-icon icon-right"   [ngClass]="{ 'is-invalid': (overtimeForm.controls.week_off_ot_hours?.touched || overtimeForm.controls.week_off_ot_hours?.dirty) &&  overtimeForm.controls.week_off_ot_hours?.errors }">
              <igx-time-picker formControlName="week_off_ot_hours"  placeholder="{{this.messageService.descriptiontext('hours')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (overtimeForm.controls.week_off_ot_hours?.touched || overtimeForm.controls.week_off_ot_hours?.dirty) &&  overtimeForm.controls.week_off_ot_hours?.errors }" class="form-control form-timepicker " (click)="picker2.open()" #picker2></igx-time-picker>
            </div>
            <div *ngIf="(overtimeForm.controls.week_off_ot_hours?.touched || overtimeForm.controls.week_off_ot_hours?.dirty) &&  overtimeForm.controls.week_off_ot_hours?.errors"
            class="invalid-feedback">
            <div *ngIf="overtimeForm.controls.week_off_ot_hours?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
          </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Holiday OT Hours </label>
             <div class="form-icon icon-right"   [ngClass]="{ 'is-invalid': (overtimeForm.controls.holiday_ot_hours?.touched || overtimeForm.controls.holiday_ot_hours?.dirty) &&  overtimeForm.controls.holiday_ot_hours?.errors }">
              <igx-time-picker formControlName="holiday_ot_hours"  placeholder="{{this.messageService.descriptiontext('hours')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (overtimeForm.controls.holiday_ot_hours?.touched || overtimeForm.controls.holiday_ot_hours?.dirty) &&  overtimeForm.controls.holiday_ot_hours?.errors }" class="form-control form-timepicker " (click)="picker3.open()" #picker3></igx-time-picker>
            </div>
            <div *ngIf="(overtimeForm.controls.holiday_ot_hours?.touched || overtimeForm.controls.holiday_ot_hours?.dirty) &&  overtimeForm.controls.holiday_ot_hours?.errors"
            class="invalid-feedback">
            <div *ngIf="overtimeForm.controls.holiday_ot_hours?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
          </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">National Holiday OT Hours </label>
             <div class="form-icon icon-right"   [ngClass]="{ 'is-invalid': (overtimeForm.controls.national_holiday_ot_hours?.touched || overtimeForm.controls.national_holiday_ot_hours?.dirty) &&  overtimeForm.controls.national_holiday_ot_hours?.errors }">
              <igx-time-picker formControlName="national_holiday_ot_hours"  placeholder="{{this.messageService.descriptiontext('hours')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (overtimeForm.controls.national_holiday_ot_hours?.touched || overtimeForm.controls.national_holiday_ot_hours?.dirty) &&  overtimeForm.controls.national_holiday_ot_hours?.errors }" class="form-control form-timepicker " (click)="picker4.open()" #picker4></igx-time-picker>
            </div>
            <div *ngIf="(overtimeForm.controls.national_holiday_ot_hours?.touched || overtimeForm.controls.national_holiday_ot_hours?.dirty) &&  overtimeForm.controls.national_holiday_ot_hours?.errors"
            class="invalid-feedback">
            <div *ngIf="overtimeForm.controls.national_holiday_ot_hours?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
          </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Half Working Day OT Hours </label>
             <div class="form-icon icon-right"   [ngClass]="{ 'is-invalid': (overtimeForm.controls.half_working_day_ot_hours?.touched || overtimeForm.controls.half_working_day_ot_hours?.dirty) &&  overtimeForm.controls.half_working_day_ot_hours?.errors }">
              <igx-time-picker formControlName="half_working_day_ot_hours"  placeholder="{{this.messageService.descriptiontext('hours')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (overtimeForm.controls.half_working_day_ot_hours?.touched || overtimeForm.controls.half_working_day_ot_hours?.dirty) &&  overtimeForm.controls.half_working_day_ot_hours?.errors }" class="form-control form-timepicker " (click)="picker5.open()" #picker5></igx-time-picker>
            </div>
            <div *ngIf="(overtimeForm.controls.half_working_day_ot_hours?.touched || overtimeForm.controls.half_working_day_ot_hours?.dirty) &&  overtimeForm.controls.half_working_day_ot_hours?.errors"
            class="invalid-feedback">
            <div *ngIf="overtimeForm.controls.half_working_day_ot_hours?.errors?.required">
                {{this.messageService.validationDisplay('required')}}</div>
          </div>
          </div>
        <!-- </div> -->
      </div>
      <div class="modal-footer">
        <button type="button" (click)="totalPaidDays = false"
          class="btn btn-outline-primary text-uppercase btn-sm">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase btn-sm" (click)="overtimeOverrride()" [disabled]="!overtimeForm.valid">save</button>
      </div>
    </div>
  </div>
</div>
</div>
</form>

<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{empalertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <!-- <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title"></h4> -->
      </div>
      <div class="modal-body">
        <p class="mb-0">{{resetMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase fw-500"
          (click)="confirmMsg = false">CANCEL</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase fw-500" (click)="confirmReset()"
          [disabled]="resetClicked">RESET</button>
      </div>
    </div>
  </div>
</div>

<!-- Error Download -->
<app-error-download [fileName]="'Arrear Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Arrear'" (closePanel)="downloadMsg = false" [successMsg]="''" [errorMsg]="errorMsg">  </app-error-download>
<!-- End -->
