import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HolidayCalendarService {
    API_PATH: string = environment.apiPath;
    httpOption = {
      headers : new HttpHeaders({
        'Content-Type' : 'application/json',
      })
    }


    constructor(
      private http: HttpClient,
      public router : Router
    ) { }


    getHolidayCalendarList(year:any,holiday_type:any,holiday_name:any){
      return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-calender-list/?year=${year}&&holiday_type=${holiday_type}&holiday_name=${holiday_name}`, this.httpOption);
    }
    getHolidayCalendarMonthList(year:any=''){
      return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-calender-month/?year=${year}`, this.httpOption);
    }
    holidayTypeSearch(year:any = '',holiday_type: any = ''){
      return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-calender-dropdown/?holiday__is_active=true&ordering=holiday__holiday_name&year=${year}&holiday_type=${holiday_type}`, this.httpOption)
    }



  }
