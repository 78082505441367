import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShortLeaveService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  // Get all policies
  getShortPolicies(status:any,limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/short-leave-policy-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }

  // Get Single Policy Data
  getSingleShortPolicy(id:number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/short-leave-policy-detail/${id}/`, this.httpOption);
  }

  // Update Policy
  updatePolicyData(id:number,body:any){
    return this.http.put(`${this.API_PATH}attendance/api/v1/short-leave-policy-detail/${id}/`,body, this.httpOption);
  }

  // Create Policy
  createShortPolicy(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/short-leave-policy-list/`,body, this.httpOption);
  }

  // Delete Policy
  deleteShortPolicy(id:number, body:any){
    return this.http.patch(`${this.API_PATH}attendance/api/v1/short-leave-policy-detail/${id}/`,body, this.httpOption);
  }

  // Dropdown Policy
  getDropdownPolicies(status:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shl-policy-dropdown-list/?is_active=${status}&ordering=name`, this.httpOption);
  }
}
