import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-final-settlement-pending-requests',
  templateUrl: './final-settlement-pending-requests.component.html',
  styleUrls: ['./final-settlement-pending-requests.component.scss']
})

export class FinalSettlementPendingRequestsComponent implements OnInit {

  @Input() currency:any;
  @Input() payrollStatus:any;

  @Output() submitmethod = new EventEmitter();
  @Output() warningMsg   = new EventEmitter();

  showSidePanel          = false;
  requests:any           = [];
  loader                 = false;
  loader1                = false;
  nodata                 = false;
  empId:any              = [];
  data:any;
  reqName:any;
  requestitem:any        = [];
  createdTime:any;
  reqStatus:any = '';

  /********INFINITY SCROLL VARIABLES  ****/
  offset                 = 0;
  listlength             = 0;
  direction              = '';
  defaultlimit:any       = 20;
  requestDetailsList:any = [];
  infinityloader         = false;
  inboxdata:any          = [];
  inboxDetailsList:any   = [];

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb:FormBuilder,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {

    this.loader = true;
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.empId = parseInt(params['id']);
        this.getPendingRequests();
      }
    });
  }

  getPendingRequests(){
    this.loader = true;
    this.finalSettlementService.requestList(this.defaultlimit,this.offset,this.empId).subscribe((res: any) => {
      if(this.offset ==0){
        this.requests = [];
      }
      this.listlength = res?.results?.length;
      if (res?.results?.length != 0) {
        this.requestDetailsList =  res?.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.nodata = this.requests?.length==0?true:false;
      this.loader = false;
    });
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {

      if(new Date(this.requestDetailsList[i]['created_at']).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)) {

        this.requestDetailsList[i]['created_at_card'] = this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'hh:mm a')?.toString();

        this.requestDetailsList[i]['created_at'] = this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'hh:mm a')?.toString() + " (" +moment(String(this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
    }
      else if(new Date(this.requestDetailsList[i]['created_at']).getFullYear() == new Date().getFullYear()){

        this.requestDetailsList[i]['created_at_card'] = this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'MMM dd')?.toString();

        this.requestDetailsList[i]['created_at'] = this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString() + " (" + moment(String(this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
      }
      else {

        this.requestDetailsList[i]['created_at_card'] = this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'dd/MM/yy')?.toString();

        this.requestDetailsList[i]['created_at'] = this.datePipe.transform(this.requestDetailsList[i]['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString()
      }

      this.requests.push(this.requestDetailsList[i]);
    }
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getPendingRequests();
      this.loader       = false;
      this.direction    = "down";
    }
  }

  submitPendingRequests(){
    this.submitmethod.emit();
  }

  approvalSection(item:any,data:any){
    this.reqStatus = data?.status;
    this.data    = data;
    this.reqName = item == 'Manage Asset' ? 'Asset' : item;

    if(item == 'Reimbursement' || item == 'FBP Declaration' || item == 'Employee Profile' || item =='Work From Home' || item=='On Duty' || item=='Holiday' || item =='Attendance Regularization' || item =='Overtime' || item=='Compensatory Off'|| item=='Leave' || item == 'Check-in Request' || item == 'Manage Asset' || item == 'Expense Report' || item == 'Short Leave'){

      this.getRequestData();
    } else if(item == 'Reimbursement Cancellation'){
      this.getCancellationReimbursementData()
    }
    else if(item == 'Holiday Cancellation'  || item == 'Attendance Regularization Cancellation' || item == 'Work From Home Cancellation' || item == 'On Duty Cancellation' || item == 'Leave Cancellation' || item == 'Short Leave Cancellation'){
      var frags = item.split(' ')
      frags.splice(-1)
      this.reqName = frags.join(' ')
      this.getRequestData();

    }

    this.showSidePanel = true;
  }

  fnfApprovalViewClose(){
    this.showSidePanel = false;
    this.offset        = 0;
    this.requests      = [];
    this.getPendingRequests();
  }


  getRequestData(){
    this.loader1 = true;
    this.finalSettlementService.getSingleInboxData(this.reqName,this.data['id']).subscribe((res:any)=>{
      this.requestitem = res?.data;
      this.loader1     = false;
    })
  }

  getCancellationReimbursementData(){
    this.loader1 = true;
    this.finalSettlementService.getSingleInboxData("Reimbursement",this.data['id']).subscribe((res:any)=>{
      this.requestitem = {'reimburse_bill':res?.data};
      this.loader1 = false;
    })
  }

  unprocessWarningPopup(){
    this.warningMsg.emit();
  }


}
