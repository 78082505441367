<!-- <form class=""> -->
    <div class="container-fluid p-32 form-container">
      <app-c1-loader *ngIf="loader1"></app-c1-loader>
        <div class="row row-16 mb-24" *ngIf="!loader1">
            <div class="col-12 d-flex align-items-start">
                <span class="fw-500 text-uppercase">MAP EMPLOYEES</span>
                <div class="ms-auto d-flex gap-16">
                  <div style="min-width:22.688rem;">
                    <div class="form-icon2">
                      <i class="icon-search icon-left fs-14"></i>
                      <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                      <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                    </div>
                  </div>
                    <button class="btn-square btn btn-secondary" (click)="filter  = true">
                        <i class="icon-filter-left fs-13"></i>
                      </button>
                </div>
            </div>
            <div class="col-12 d-flex gap-32 flex-wrap">
              <p class="fs-14 mb-0">Employees selected : {{countSelected()}}</p>
              <!-- <p class="fs-14 mb-0" >Challan amount : {{ excessFlag == false ? (formGp?.value?.amount | currency: currency) : (excessAmount | currency: currency)}}</p> -->
              <p class="fs-14 mb-0" >Challan amount : {{ excessFlag == false ? (formGp?.value?.amount | currency: currency) : (formGp?.value?.amount | currency: currency)}}</p>
              <p class="fs-14 mb-0">Balance amount : {{balanceAmount() | currency: currency}}</p>
            </div>
            <div class="col-12">
                <div class="card card-c2 overflow-hidden">
                    <div class="table-responsive">
                        <table class="table table-striped form-table table-sm td-white-space">
                            <thead>
                                <tr>
                                    <th scope="col" class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)" [checked]="checkallSelected()" >
                                    </th>
                                    <th class="fw-600 col-3" scope="col">Employee CODE</th>
                                    <th class="fw-600 col-3" scope="col">Employee name</th>
                                    <th class="fw-600 col-3" scope="col">PAN no</th>
                                    <th class="fw-600 col-3" scope="col">SALARY PROCESSED DATE</th>
                                    <th class="fw-600 col-3" scope="col">gross salary</th>
                                    <th class="fw-600 col-3" scope="col">INCOME TAX</th>
                                    <th class="fw-600 col-3" scope="col">SURCHARGE</th>
                                    <th class="fw-600 col-3" scope="col">EDUCATIONAL CESS</th>
                                    <th class="fw-600 col-3" scope="col">Total TAX</th>
                                    <th class="fw-600 col-3" scope="col">TAX PAID IN THIS CHALLAN</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="items.length<=0">
                                  <td colspan="8">
                                      <div class="card card-c2 flex-center p-16 bg-dark-500">
                                          <span class="fw-500 fs-14">No data found</span>
                                        </div>
                                  </td>
                                </tr>
                                <tr *ngFor="let item of $any(items |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                    <td class="form-cell">

                                        <input [ngClass]="'page'+page" class="form-check-input m-0" type="checkbox" [(ngModel)]="item.is_employee_mapped" [ngModelOptions]="{standalone: true}" value="{{item.id}}" (click)="createSelectedLIst('check');checkUncheck($event,item)">
                                    </td>
                                    <td class=" col-3" scope="col">{{item?.employee_code}}</td>
                                    <td class=" col-2" scope="col">{{item?.name}}</td>
                                    <td class=" col-2" scope="col">{{item?.pan_number}}</td>
                                    <td class=" col-2" scope="col">{{this.appService.dateFormatDisplay(item?.created_at)}}</td>
                                    <td class=" col-2" scope="col">{{item?.gross_amount | currency:currency}}</td>
                                    <td class=" col-2" scope="col">{{item?.month_raw_tax | currency:currency}}</td>
                                    <td class=" col-2" scope="col">{{item?.month_surcharge | currency:currency}}</td>
                                    <td class=" col-2" scope="col">{{item?.month_education_cess | currency:currency}}</td>
                                    <td class=" col-2" scope="col">{{item?.pay_tax | currency:currency}}</td>
                                    <td class=" col-2" scope="col">{{item?.mapped_amount | currency:currency}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="filteredcount()>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize"  [collectionSize]="filteredcount()" [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
          </div>

        </div>

    </div>
    <footer class="submit-footer">
        <button *ngIf="!isAdjustAmt" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="countSelected()==0 || formGp.value.amount < this.amt"  (click)="createSelectedLIst('submit');">NEXT</button>

        <button *ngIf="isAdjustAmt" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="countSelected()==0 || amountExceed"  (click)="createSelectedLIst('submit');">NEXT</button>
    </footer>
<!-- </form> -->

<!-- FILTER -->
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>

<!-- AMOUNT EXCEED MODAL -->
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{empalertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div>
