<!-- test start -->
<div class="side-panel-body" style="overflow-y: auto;">
  <div class="container-fluid p-32 ">
      <div class="col-12">
          <h5>DETAILS</h5>
      </div>
      <div class="card card-c2 p-32">
          <div class="row  row-16">

              <div class="col-12 d-flex ">
                  <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
              </div>
              <div class="col-12 mt-8">

                  <h5 class="mb-8 text-break" *ngIf="requestitem?.request_data?.is_cancel == false">Short Leave (SHL)</h5>
                  <h5 class="mb-8 text-break" *ngIf="requestitem?.request_data?.is_cancel == true">Short Leave Cancellation (SHL)</h5>
              </div>

              <app-view-loader *ngIf="loader1"></app-view-loader>

              <div *ngIf="!loader1" class="row row-8">
                <div class="col-12">
                  <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                    <div class="row row-24">
                      <div class="col-12">
                        <div class="d-flex">
                          <span class="title">SHORT Leave Details</span>
                          <span (click)="trendFunction()" class="text-accent2 pointer ms-auto fs-12 fw-500"><i
                              class="bi bi-graph-up me-1"></i>Short Leave Trend</span>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card card-c5 month-active text-light-500">
                          <div class="card-body uniquebg2 flex-center flex-column  text-light-600">
                            <div class="fw-500 fs-24 text-uppercase"> {{requestitem?.date+" "+requestitem?.total_duration|date:'HH:mm'}}</div>
                            <div class="fs-14">Hours</div>
                          </div>
                          <div class="card-body gap-1 justify-content-center d-flex flex-column overflow-hidden">
                            <p class="fs-14 mb-0 text-trim" title="Short Leave">Short Leave</p>
                            <p class="fs-14 mb-0 fw-500" >{{this.appservice.dateFormatDisplay(requestitem?.date)}} </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="detail-title">Date</div>
                        <div class="detail-desc">{{this.appservice.dateFormatDisplay(requestitem?.date)}}
                        </div>
                      </div>
                      <div class="col-12" *ngFor="let item of requestitem?.short_leave_portion">
                        <div class="row row-24">
                          <div class="col-4">
                            <div class="detail-title">From time</div>
                            <div class="detail-desc">{{this.appservice.timeFormatDisplay(item?.from_time)}}</div>
                          </div>
                          <div class="col-4">
                            <div class="detail-title">To time</div>
                            <div class="detail-desc">{{this.appservice.timeFormatDisplay(item?.to_time)}}</div>
                          </div>
                          <div class="col-4">
                            <div class="detail-title">Duration</div>
                            <div class="detail-desc"> {{this.appservice.formatTime(item?.duration)}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="detail-title">Reason</div>
                        <div class="detail-desc">{{requestitem?.reason}}</div>
                      </div>
                      <div class="col-12" *ngIf="requestitem?.request_data?.is_cancel == false">
                        <div class="detail-title mb-8">Document</div>
                        <div class="detail-desc" *ngIf="requestitem?.document == ''">-</div>
                        <div style="width:12.9375rem;" *ngIf="requestitem?.document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                            <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim" >{{this.appservice.getDocName(requestitem?.document) }}</span>
                              <span class="tips-helper " >{{this.appservice.getDocName(requestitem?.document) }}</span>
                              <div class="d-flex gap-8"  >
                                 <div class="link-primary" (click)="viewDocument(requestitem?.document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary"
                                  (click)="this.appservice.downloadFile(requestitem?.document,this.appservice.getDocName(requestitem?.document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12" *ngIf="requestitem?.request_data?.is_cancel == true">
                        <div class="detail-title">Reason for cancellation</div>
                        <div class="detail-desc">{{requestitem?.remark}}</div>
                      </div>



                    </div>
                  </div>
                </div>


                <form *ngIf="reqStatus == 'Pending'" class="mt-20" [formGroup]="form">
                  <ng-container *ngIf="!requestitem?.achieved_status">
                    <div class="col-12">
                      <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
                        class="form-control mb-8 textarea-comment" formControlName="comment"
                        [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                      <div *ngIf="f.comment.errors" class="invalid-feedback">
                        <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-flex gap-16 mt-20">
                      <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                        (click)="confirm('Rejected')">Decline</button>
                      <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                        (click)="confirm('Approved')">Confirm</button>
                    </div>
                  </ng-container>
                </form>
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngIf="timeOffTrend" class="side-panel" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='timeOffTrend === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5> Short leave</h5>
        <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
      </header>

      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">


          <div class="row row-16">

            <div class="col-12">
              <div class="card card-c2 pb-24  px-24 pt-24">
                <div class="row row-12">

                  <div class="col-12 d-flex">
                    <div class="fs-24 me-auto">Short leave</div>

                    <ng-select class="form-ngselect h-36 fs-14" placeholder="Select year" [(ngModel)]="selectedYear"
                      [clearable]="false" (change)="yearChange(selectedYear)" name="selectedYear">
                      <ng-option *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-12" *ngIf="barchartLoader">

                    <app-graph-loader></app-graph-loader>
                  </div>
                  <div *ngIf="!barchartLoader" class="col-12">

                    <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
                      [yText]="barChartYAxisLabel"></app-bar-chart>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </form>
  </div>

  <app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
    [alertMsg]="investalertMsg"></app-alert-toggle>

    <app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
    [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
