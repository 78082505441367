<form action="" class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">

        <div class="row ">
            <p class="text-uppercase fw-500">Policy settings</p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Separation policy name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter a name for your separation policy."
                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                        triggers="hover"></i></span>
                <input type="text" formControlName="name" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('name')}}"
                    [ngClass]="{ 'is-invalid': (submitted || f.name.touched || f.name.dirty) &&  f.name.errors || f.name.errors?.exist}"
                    (keyup)="keyUp()">
                <div *ngIf="(submitted || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist"
                    class="invalid-feedback">
                    <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                    <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                    <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Policy description </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter a few lines of description on the separation policy."
                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                        triggers="hover"></i></span>
                <textarea type="text" formControlName="description" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('description')}}"
                    [ngClass]="{ 'is-invalid': (submitted ||f.description.touched || f.description.dirty) &&  f.description.errors}" row="6"></textarea>
                <div *ngIf="(submitted ||f.description.touched || f.description.dirty) && f.description.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="!f.description.errors.pattern && f.description.errors.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','500')}}
                    </div>
                    <div *ngIf="f.description.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                    </div>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Notice period days for probation period employees (in days)
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter the number of days the employee must serve as a notice period when on probation."
                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                        triggers="hover"></i></span>
                <input type="text" formControlName="probation_notice_period" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('Notice Period')}}"
                    [ngClass]="{ 'is-invalid': (submitted ||f.probation_notice_period.touched || f.probation_notice_period.dirty) &&  f.probation_notice_period.errors}">
                <div *ngIf="(submitted ||f.probation_notice_period.touched || f.probation_notice_period.dirty) && f.probation_notice_period.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.probation_notice_period.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!(f.probation_notice_period.errors.pattern) && f.probation_notice_period.errors.max">
                        {{this.messageService.fieldlengthvalidation('days','365')}}
                    </div>
                    <div *ngIf="f.probation_notice_period.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Notice period days for confirmed employees (in days) </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter the number of days of notice the employee must serve after the employment status is confirmed."
                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                        triggers="hover"></i></span>
                <input type="text" formControlName="confirm_notice_period" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('Notice Period')}}"
                    [ngClass]="{ 'is-invalid': (submitted ||f.confirm_notice_period.touched || f.confirm_notice_period.dirty) &&  f.confirm_notice_period.errors}">
                <div *ngIf="(submitted ||f.confirm_notice_period.touched || f.confirm_notice_period.dirty) && f.confirm_notice_period.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.confirm_notice_period.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!(f.confirm_notice_period.errors.pattern) && f.confirm_notice_period.errors?.max">
                        {{this.messageService.fieldlengthvalidation('days','365')}}
                    </div>
                    <div *ngIf="f.confirm_notice_period.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>
            <!-- <div class="col-lg-12 form-row ">
                <label for="" class="form-label required">Is backdated resignation request allowed? </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enable this option if you want to allow the employees to apply for resignation on previous dates." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            </div> -->
            <!-- <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="backdated_resignation_allow" id="flexRadioDefault1" formControlName="backdated_resignation_allow" [value]="true" (ngModelChange)="addConditionalValidation()">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="backdated_resignation_allow" id="flexRadioDefault2" checked formControlName="backdated_resignation_allow" [value]="false" >
                  <label class="form-check-label" for="flexRadioDefault2">
                    No
                  </label>
                </div>
            </div> -->
            <!-- <div class=" col-12 form-row" *ngIf="f.backdated_resignation_allow.value">
                <label for="" class="form-label required">Maximum number of days allowed for backdated resignation (If backdated resignation request is allowed) </label>

                <input type="text" formControlName="backdated_resignation_period" class="form-control" placeholder="{{this.messageService.placeholderdisp('Backen Resignation Period')}}" [ngClass]="{ 'is-invalid': (submitted ||f.backdated_resignation_period.touched || f.backdated_resignation_period.dirty) &&  f.backdated_resignation_period.errors}">
                <div *ngIf="(submitted ||f.backdated_resignation_period.touched || f.backdated_resignation_period.dirty) && f.backdated_resignation_period.errors" class="invalid-feedback">
                    <div *ngIf="f.backdated_resignation_period.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!(f.backdated_resignation_period.errors.pattern) && f.backdated_resignation_period.errors?.max">{{this.messageService.fieldlengthvalidation('days','365')}}
                    </div>
                    <div *ngIf="f.backdated_resignation_period.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div> -->
            <!-- <div class="col-12">
                <label for="" class="form-label required">Is backdated resignation request allowed? </label>
            </div>
            <div class="col-12 mt-8 form-row">
                <div class="form-check form-check-inline">
                    <input name="radio-name" type="radio" id="radio1" class="form-check-input" value="radio1">
                    <label for="radio1" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="radio-name" type="radio" id="radio2" class="form-check-input" value="radio2">
                    <label for="radio2" class="form-check-label">No </label>
                </div>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Maximum number of days allowed for backdated resignation
                </label>
                <input type="text" name="" class="form-control" placeholder="Enter " id="">
            </div> -->
            <div class="col-12">
                <label for="" class="form-label required">Would you like to send the relieving letter? </label>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input formControlName="is_send_relieving_letter"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'relieveing_process_days','letter_template')">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                        <input formControlName="is_send_relieving_letter"  class="form-check-input" type="radio" [value]=false (click)="setRequired(false,'relieveing_process_days','letter_template')">
                        No
                    </label>
                </div>
            </div>
            <ng-container *ngIf="f.is_send_relieving_letter?.value">
            <div class="col-12">
                <label for="" class="form-label required">How many days before relieving should relieving letter task be
                    initiated to task owner? </label>
                    <input type="text" formControlName="relieveing_process_days" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('days')}}"
                    [ngClass]="{ 'is-invalid': (submitted ||f.relieveing_process_days.touched || f.relieveing_process_days.dirty) &&  f.relieveing_process_days.errors}">
                <div *ngIf="(submitted ||f.relieveing_process_days.touched || f.relieveing_process_days.dirty) && f.relieveing_process_days.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.relieveing_process_days.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!(f.relieveing_process_days.errors.pattern) && f.relieveing_process_days.errors?.max">
                        {{this.messageService.fieldlengthvalidation('days','365')}}
                    </div>
                    <div *ngIf="f.relieveing_process_days.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Map a letter template</label>
                <ng-select class="form-ngselect"
                                formControlName="letter_template"
                                [ngClass]="{ 'is-invalid': (submitted || f.letter_template.touched) &&  f.letter_template.errors }"
                                name=""  id="letter_template"
                                placeholder="{{messageService.selectplaceholddisplay('letter template')}}"  [clearable]="false">
                        <ng-option *ngFor="let letter_template of templatelist"  [value]="letter_template.id">{{letter_template.name}}</ng-option>
              </ng-select>
                        <div *ngIf="(submitted || f.letter_template.touched) && f.letter_template.errors" class="invalid-feedback">
                            <div *ngIf="f.letter_template.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        </div>
            </div>
            </ng-container>
            <!-- <div class="col-12">
                <label for="" class="form-label required">Enable exit interview to the employees? </label>
            </div>
            <div class="col-12 mt-8 form-row">
                <div class="form-check form-check-inline">
                    <input name="radio-name" type="radio" id="radio1" class="form-check-input" value="radio1">
                    <label for="radio1" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="radio-name" type="radio" id="radio2" class="form-check-input" value="radio2">
                    <label for="radio2" class="form-check-label">No </label>
                </div>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">How many days before relieving should an exit interview for an
                    employee be initiated? </label>
                <input type="text" name="" class="form-control" placeholder="Enter " id="">
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Exit interview template to be applicable </label>
                <ng-select class="form-ngselect" placeholder="Select "></ng-select>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Enable exit clearance workflow? </label>
            </div>
            <div class="col-12 mt-8 form-row">
                <div class="form-check form-check-inline">
                    <input name="radio-name" type="radio" id="radio1" class="form-check-input" value="radio1">
                    <label for="radio1" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="radio-name" type="radio" id="radio2" class="form-check-input" value="radio2">
                    <label for="radio2" class="form-check-label">No </label>
                </div>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">How many days before relieving should exit clearance task be
                    initiated to task owner? </label>
                <input type="text" name="" class="form-control" placeholder="Enter " id="">
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Clearance checklist template to be applicable? </label>
                <ng-select class="form-ngselect" placeholder="Select "></ng-select>
            </div> -->

        </div>
    </div>

    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase"
            [disabled]="(!formGp.valid || componentError)" (click)="validatepolicysetting()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"
            (click)="validatepolicysetting()">NEXT</button>
    </footer>
</form>
