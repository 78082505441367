import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tax-changes-banner',
  templateUrl: './tax-changes-banner.component.html',
  styleUrls: ['./tax-changes-banner.component.scss']
})
export class TaxChangesBannerComponent implements OnInit {
  @Input() bannerSidebar:any;
  @Output() panelclose = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  closefn(){
    this.bannerSidebar = false;
    this.panelclose.emit(this.bannerSidebar);
  }
}
