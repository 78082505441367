<div class="side-panel" style="--sidepanel-width: 45rem" [class.side-pane-active]="showSidePanel === true">
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>On behalf request</h5>
      <a class="toggle-panel" (click)="newRequestPanelClose()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto">
      <div class="container-fluid p-32">
        <app-c1-loader *ngIf="loader3"></app-c1-loader>
        <ng-container *ngIf="!loader3">
          <div class="d-flex gap-16">
            <div class="icon-left flex-grow-1 search-dropdowns">
              <app-searchbar [searchlist]="dropdownData" [searchplaceholder]="requestTypeSearch"
                (searchEmitter)="searchtyperesults($event)"
                (keywordsearchEmitter)="searchrequestType($event)"></app-searchbar>
            </div>
          </div>
          <!-- RECENTLY USED SECTION START -->
          <ng-container *ngIf="
              recentUsedReq != '' &&
              searchKeywordRequestType == '' &&
              requestSearchData == ''
            ">
            <p class="text-uppercase fw-500 pt-24">
              Recently used
            </p>
            <div class="row row-12">
              <div class="col-12 col-sm-4 col-md-3" *ngFor="let recentreq of recentUsedReq; let i = index">
                <div class="card card-c2 p-16 h-100 card-hover" (click)="getRequest(recentreq.name)">
                  <div class="card-body flex-center text-center pt-0 px-0 pb-8">
                    <div class="flex-center d-flex sq-36">
                      <i *ngIf="recentreq.icon" class="{{ recentreq.icon }}  fs-26"></i>
                    </div>
                    <span *ngIf="recentreq.image == null">
                      <div *ngIf="recentreq.icon == null" class="logo-img bg{{ i % 5 }} ">
                        {{ this.appservice.getFirstChar(recentreq.name, 2) }}
                      </div>
                    </span>
                  </div>
                  <div class="card-body overflow-hidden flex-center text-center px-0 pb-0 pt-8 h-100"
                    style="min-height: 2.3125rem">
                    <div class="fs-12 fw-500 text-trim-3">
                      {{ recentreq.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- RECENTLY USED SECTION END -->

          <!-- ALL REQUEST SECTION START -->

          <p class="text-uppercase fw-500 pt-24">All</p>
          <app-c1-loader *ngIf="loader2"></app-c1-loader>

          <div class="row pt-5 flex-center text-center" *ngIf="reqTypeSearchlistdata?.length == 0 && !loader2">
            <div class="col-12">
              <img class="img-fluid mx-auto" src="/assets/images/bg/15 Startup launch.png" style="width: 438px" />
              <p class="text-uppercase text-light-400 fw-700 fs-14">NO DATA</p>
            </div>
          </div>
          <div *ngIf="reqTypeSearchlistdata?.length > 0 && !loader2" class="row row-12">
            <div class="col-12 col-sm-4 col-md-3" *ngFor="let reqtype of reqTypeSearchlistdata; let i = index">
              <div class="card card-c2 p-16 h-100 card-hover" (click)="getRequest(reqtype.name)">
                <div class="card-body flex-center text-center pt-0 px-0 pb-8">
                  <div class="flex-center d-flex sq-36">
                    <i *ngIf="reqtype.icon" class="{{ reqtype.icon }} fs-26"></i>
                  </div>
                  <span *ngIf="reqtype.icon == null">
                    <div class="logo-img bg{{ i % 5 }} ">
                      {{ this.appservice.getFirstChar(reqtype.name, 2) }}
                    </div>
                  </span>
                </div>
                <div class="card-body overflow-hidden flex-center text-center px-0 pb-0 pt-8 h-100"
                  style="min-height: 2.3125rem">
                  <div class="fs-12 fw-500 text-trim-3">{{ reqtype.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- ALL REQUEST SECTION END -->
        </ng-container>
      </div>
    </div>
  </form>
</div>
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="investalertMsg"></app-info-popup>
</ng-container>
