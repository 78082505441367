<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='assetRequest === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Asset Request {{from=='behalf'?'Of '+requestFor:''}} </h5>
      <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">Asset Request</p>
          </div>

          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12">
                  <label for="" class="form-label required">Request type </label>
                </div>
                <div class="col-12 mt-8">
                  <form class="row-16 row" [formGroup]="formGp">
                    <div class="col-12 form-row">
                      <div class="form-check form-check-inline">
                        <input name="asset_type" type="radio" id="New" formControlName="asset_type"
                          class="form-check-input" value="New" (ngModelChange)="assetTypeChange()">
                        <label for="New" class="form-check-label">New </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                          ngbPopover="Choose this option when requesting a new asset."
                          popoverClass="popover-input popover-info" container="body" placement="bottom" triggers="hover"></i></span>
                      </div>
                      <div class="form-check form-check-inline">
                        <input name="asset_type" type="radio" id="Return" formControlName="asset_type"
                          class="form-check-input" value="Return" (ngModelChange)="assetTypeChange()">
                        <label for="Return" class="form-check-label">Return </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                          ngbPopover="Choose this option when returning an asset."
                          popoverClass="popover-input popover-info" container="body" placement="bottom" triggers="hover"></i></span>
                      </div>
                      <div class="form-check form-check-inline">
                        <input name="asset_type" type="radio" id="Both" formControlName="asset_type"
                          class="form-check-input" value="Both" (ngModelChange)="assetTypeChange()">
                        <label for="Both" class="form-check-label">Both </label>
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                          ngbPopover="Choose this option if you want to request a new asset as well as return an asset."
                          popoverClass="popover-input popover-info" container="body" placement="bottom" triggers="hover"></i></span>
                      </div>
                    </div>
                    <ng-container *ngIf="formGp.value.asset_type == 'New' || formGp.value.asset_type == 'Both'">
                      <ng-container formArrayName="assetArray"
                        *ngFor="let item of assetArrays().controls; let i=index; ">
                        <ng-container [formGroupName]="i">
                          <div class="col-12">
                            <div class="card details-card shadow-0 p-16">
                              <div class="row row-16 ">
                                <div class="col-12 d-flex">
                                  <p class="title mb-0">NEW PRODUCT DETAILS </p>
                                  <span class="link-danger ms-auto" (click)="removeAssetArrays(i)"> <i
                                      class="icon-trash"></i> </span>
                                </div>
                                <div class="col-6">
                                  <label for="" class="form-label required">Category </label>

                                  <ng-select class="form-ngselect" formControlName="asset_category"
                                    [items]="categoryListDropDown" bindLabel="name"
                                    (change)="getAssetSubCategory(formGp.value.assetArray[i].asset_category,i)"
                                    bindValue="id"
                                    [ngClass]="{ 'is-invalid': (assetArrays().controls[i].get('asset_category')?.dirty || assetArrays().controls[i].get('asset_category')?.touched) &&  assetArrays().controls[i].get('asset_category')?.errors}"
                                    name="" id="asset_category"
                                    placeholder="{{this.messageService.selectplaceholddisplay('category')}}">
                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                      let-search="searchTerm">
                                      <div class="d-inline-flex align-items-center">
                                        <span> {{item?.name}}</span>
                                      </div>
                                    </ng-template>
                                  </ng-select>
                                  <div
                                    *ngIf="(assetArrays().controls[i].get('asset_category')?.dirty || assetArrays().controls[i].get('asset_category')?.touched) && assetArrays().controls[i].get('asset_category')?.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="assetArrays().controls[i].get('asset_category')?.errors?.required">
                                      {{this.messageService.validationDisplay('required')}}</div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <label for="" class="form-label required">Subcategory </label>
                                  <ng-select class="form-ngselect"
                                    [readonly]="(assetArrays().controls[i].get('asset_category')?.value == null || assetArrays().controls[i].get('asset_category')?.value == '') ? true : false"
                                    formControlName="asset_sub_category" [items]="subCategoryListDropDown[i]"
                                    bindLabel="name" bindValue="id"
                                    [ngClass]="{ 'is-invalid': (assetArrays().controls[i].get('asset_sub_category')?.dirty || assetArrays().controls[i].get('asset_sub_category')?.touched) &&  assetArrays().controls[i].get('asset_sub_category')?.errors}"
                                    name="" id="asset_sub_category"
                                    placeholder="{{this.messageService.selectplaceholddisplay('subcategory')}}">
                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                      let-search="searchTerm">
                                      <div class="d-inline-flex align-items-center">
                                        <span> {{item?.name}}</span>
                                      </div>
                                    </ng-template>
                                  </ng-select>
                                  <div
                                    *ngIf="(assetArrays().controls[i].get('asset_sub_category')?.dirty || assetArrays().controls[i].get('asset_sub_category')?.touched) && assetArrays().controls[i].get('asset_sub_category')?.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="assetArrays().controls[i].get('asset_sub_category')?.errors?.required">
                                      {{this.messageService.validationDisplay('required')}}</div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="" class="form-label required">Reason </label>
                                  <input type="text" formControlName="asset_reason" name="asset_reason"
                                    class="form-control" placeholder="{{this.messageService.placeholderdisp('reason')}}"
                                    id=""
                                    [ngClass]="{ 'is-invalid': (assetArrays().controls[i].get('asset_reason')?.dirty || assetArrays().controls[i].get('asset_reason')?.touched) &&   assetArrays().controls[i].get('asset_reason')?.errors}"
                                    autocomplete="off">

                                  <div
                                    *ngIf="(assetArrays().controls[i].get('asset_reason')?.dirty || assetArrays().controls[i].get('asset_reason')?.touched) && assetArrays().controls[i].get('asset_reason')?.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="assetArrays().controls[i].get('asset_reason')?.errors?.required">
                                      {{this.messageService.validationDisplay('required')}}</div>
                                    <div
                                      *ngIf="!(assetArrays().controls[i].get('asset_reason')?.errors?.pattern) && assetArrays().controls[i].get('asset_reason')?.errors?.maxlength">
                                      {{this.messageService.fieldlengthvalidation('word','500')}}</div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <div class="col-12">
                        <span class=" link-primary1 hstack fs-14" (click)="addAssetArray()">
                          <i class="icon-plus-square-fill fs-20 me-8"></i>Add More
                        </span>
                      </div>
                    </ng-container>
                  </form>
                </div>

                <div *ngIf="formGp.value.asset_type == 'Return' || formGp.value.asset_type == 'Both'" class="col-12">
                  <div class="row row-16">
                    <div class="col-12">
                      <label class="form-label">Select the product you want to return</label>
                    </div>
                    <div class="col-12 mt-8">
                      <div class="card card-c2 p-0">
                        <div class="table-responsive scrollbar-10 radius-4">
                          <table
                            class="table sticky-header table-striped table-borderless td-12 td-fs-14 tr-fs-14 table-sm form-table sticky-first-col">
                            <thead>
                              <tr>
                                <th scope="col" class="text-center" style="width:46px;"><input class="form-check-input checkbox-16 m-0" type="checkbox"
                                    (click)="checkall($event)" [checked]="checkAllSelected()" value="">
                                </th>
                                <th class="fw-600 text-uppercase" scope="col">Asset name</th>
                                <th class="fw-600 text-uppercase" scope="col">category</th>
                                <th class="fw-600 text-uppercase" scope="col">subcategory</th>
                                <th class="fw-600 text-uppercase" scope="col">Assigned on</th>
                                <th class="fw-600 text-uppercase" scope="col">Assigned by</th>
                              </tr>
                            </thead>
                            <tbody class="cell-16">

                              <ng-container *ngIf="employeeAssetList?.length==0">
                                <tr>
                                  <td colspan="6">
                                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                                      <span class="fw-500 fs-14">No data found</span>
                                    </div>
                                  </td>
                                </tr>
                              </ng-container>

                              <ng-container
                                *ngFor='let item of $any(employeeAssetList|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)'>
                                <tr>
                                  <td scope="col" class="text-center"><input class="form-check-input m-0 checkbox-16" type="checkbox"
                                      value="" [(ngModel)]="item.checked">
                                  </td>
                                  <td class="text-trim w-17ch" data-toggle="tooltip" title="{{item?.asset_details?.name}}">{{item?.asset_details?.name}}</td>
                                  <td class="text-trim w-17ch" data-toggle="tooltip" title="{{item?.asset_details?.category_name}}">{{item?.asset_details?.category_name}}</td>
                                  <td class="text-trim w-17ch" data-toggle="tooltip" title="{{item?.asset_details?.sub_category_name}}">{{item?.asset_details?.sub_category_name}}</td>
                                  <td class="text-trim w-17ch" data-toggle="tooltip" title="{{(item?.assigned_on == null || item?.assigned_on == '') ? '-' :
                                  this.appservice.dateFormatDisplay(item?.assigned_on)}}">{{(item?.assigned_on == null || item?.assigned_on == '') ? '-' :
                                    this.appservice.dateFormatDisplay(item?.assigned_on)}}</td>
                                  <td class="text-trim w-17ch" data-toggle="tooltip" title="{{item?.assigned_by}}">{{item?.assigned_by}}</td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="col-12" *ngIf="this.appservice.filteredcount(employeeAssetList,searchString)>pageSize">
                      <ngb-pagination class="d-flex justify-content-end"
                        [collectionSize]="this.appservice.filteredcount(employeeAssetList,searchString)" [(page)]="page"
                        [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        [disabled]="!buttonDiableFunction() || saveClicked" (click)="requestSubmitFunction()">
        Submit
      </button>
    </footer>
  </div>
</div>
