import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-fbp-declaration',
  templateUrl: './view-fbp-declaration.component.html',
  styleUrls: ['./view-fbp-declaration.component.scss']
})
export class ViewFbpDeclarationComponent implements OnInit {

  @Input()fbpReqViewData:any;
  @Input()from:any;
  @Input()currency:any;
  @Input()fbpStatus:any;

  constructor(
    private appService:AppService
  ) { }

  ngOnInit(): void {
    this.getEmployeeCurrency();
  }

  getEmployeeCurrency(){
    let userData  = this.appService.getEmpData();
    this.currency = userData.currency;
  }

}
