<div class="" [formGroup]="addReportForm">
  <div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='newReport === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>{{modelLabel}} Report </h5>
        <a class="toggle-panel" (click)="closeReport()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader"></app-view-loader>

          <div class="row row-16 " *ngIf="!loader">
            <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">REPORT DETAILS </p>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Report name </label>

              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter a name for this expense report." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>

              <input type="text" autocomplete="off" class="form-control" formControlName="name" name="" id="name" value="" placeholder="{{this.messageService.placeholderdisp('report name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched)  &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
              <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                  <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class=" col-12 form-row">
              <label for="" class="form-label  ">Business purpose</label>

              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Explain the purpose of this business-related expense." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>

              <textarea type="text" class="form-control" formControlName="business_purpose" rows="4"  placeholder="{{this.messageService.placeholderdisp('description here...')}}"
              [ngClass]="{ 'is-invalid': (submitted || f.business_purpose.dirty || f.business_purpose.touched)  &&  f.business_purpose.errors || f.business_purpose.errors?.exist}"></textarea>
              <div *ngIf="(submitted || f.business_purpose.dirty || f.business_purpose.touched) && f.business_purpose.errors || f.business_purpose.errors?.exist" class="invalid-feedback">
                <div *ngIf=" f.business_purpose.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
            </div>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">From date </label>

              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the date from which the expenses are recorded." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>

              <div class="input-group form-icon icon-right"
                  [ngClass]="{ 'is-invalid': (submitted || f.from_date.dirty || f.from_date.touched) && (f.from_date.errors) }">
                  <input (click)="datePicker1.open()" class="form-control" placeholder="Enter from date" matInput formControlName="from_date" [matDatepicker]="datePicker1" (keydown)="disableDate()" [min]="fromMinDate" [ngClass]="{ 'is-invalid': (submitted || f.from_date.dirty || f.from_date.touched) && (f.from_date.errors) }" (dateChange)="dateChangeFunction()"><i class="icon-calendar pointer" (click)="datePicker1.open()">
                  </i> <mat-datepicker  touchUi="false"#datePicker1>
                  </mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.from_date.dirty || f.from_date.touched) && f.from_date.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.from_date.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>

            <div class="col-12">
              <label for="" class="form-label required">To date </label>

              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Enter the date upto which the expenses are recorded." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>

              <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.to_date.dirty || f.to_date.touched) && (f.to_date.errors ) }">
                <input (click)="datePicker2.open()" class="form-control" placeholder="Enter to date" matInput [min]="toMinDate" formControlName="to_date" [matDatepicker]="datePicker2" (keydown)="disableDate()" [ngClass]="{ 'is-invalid': (submitted || f.to_date.dirty || f.to_date.touched) && (f.to_date.errors ) }"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                <mat-datepicker  touchUi="false" #datePicker2 >
                </mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.to_date.dirty || f.to_date.touched) && (f.to_date.errors)"
                      class="invalid-feedback">
                      <div *ngIf="f.to_date.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>

            <!-- WILL BE IMPLEMENT IN FUTURE -->

            <!-- <div class="col-12">
              <label for="" class="form-label  ">Trip </label>
              <ng-select class="form-ngselect" placeholder="Select trip "></ng-select>
            </div> -->
          </div>
        </div>
      </div>
       <!-- <pre>Form Values: {{addReportForm.value | json}}</pre> -->
      <footer class="side-panel-footer" *ngIf="!loader">
        <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="createReport()" [disabled]='disable || !addReportForm.valid'>
          Save
        </button>
      </footer>
    </div>
  </div>
</div>
