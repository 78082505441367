import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { AuthModule } from 'src/app/@core/auth/auth.module';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { HomeModule } from 'src/app/@module/home/home.module';
import { SettingsModule } from 'src/app/@module/settings/settings.module';
import { ProfileModule } from 'src/app/@module/profile/profile.module';
import { HrModule } from 'src/app/@module/hr/hr.module';
import { InboxModule } from 'src/app/@module/inbox/inbox.module';
import { RequestModule } from 'src/app/@module/request/request.module';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from 'src/app/@module/search/search.module';
import { C1LoaderComponent } from '../c1-loader/c1-loader.component';
import { EmployeeDirectoryModule } from 'src/app/@module/employee-directory/employee-directory.module';
import { OrganizationHierarchyModule } from 'src/app/@module/organization-hierarchy/organization-hierarchy.module';
import { MyTeamModule } from 'src/app/@module/my-team/my-team.module';
import { ChatModule } from 'src/app/@module/chat/chat.module';




@NgModule({
  declarations: [
    // HeaderComponent,
    // SidebarComponent,
    // FooterComponent,
    LayoutComponent,
  ],
  imports: [
    CommonModule,
    AuthModule,
    HomeModule,
    ProfileModule,
    SettingsModule,
    RouterModule,
    HrModule,
    InboxModule,
    RequestModule,
    SearchModule,
    NgbModule,
    EmployeeDirectoryModule,
    OrganizationHierarchyModule,
    MyTeamModule,
    ChatModule
  ]
})
export class LayoutModule { }
