<app-edit-loader *ngIf="loader"></app-edit-loader>
<ng-container *ngIf="!loader">
    <div class="flex-center flex-column full-page bg-nonlogin">
        <!-- <a routerLink="/">
      <img class="img-fluid logo" src="../../assets/images/SkizzleHR-Logo/SkizzleHR-HorizontalLogo-Color.svg" alt="">
    </a> -->
        <div class="card form-card mx-auto">
            <div class="card-body  p-32">
                <a routerLink="/login" class="text-center d-block">
                    <img class="img-fluid org-logo" *ngIf="currentlogodata.logo != '' && currentlogodata.logo != null"
                        src="{{currentlogodata.logo}}" alt="">

                </a>
                <h3 class="fw-600 text-center pt-40 pb-16">OTP Verification</h3>
                <!-- <h3 class="pb-4  pt-40 fw-600">Forgot Password? <br>Don’t worry! we got your back</h3> -->
                <p class="fs-12 text-light-500 text-center">Enter the verification code that we have sent to your email id
                    <b>{{emailId}}</b>. The code is valid for 5 minutes.
                </p>
                <form #userPost="ngForm">

                    <div class="mb-3 d-flex justify-content-between">

                        <input class="form-control input-otp" [(ngModel)]="input_1" #input1 type="text" maxlength="1"
                            size="1" (input)="onInputEntry($event, input2)" autofocus
                            (ngModelChange)="nameChanged($event,'1')" name="input-otp1" />
                        <input class="form-control input-otp" [(ngModel)]="input_2" #input2 type="text" maxlength="1"
                            size="1" (input)="onInputEntry($event, input3)" name="input-otp2"
                            (ngModelChange)="nameChanged($event,'2')" />
                        <input class="form-control input-otp" [(ngModel)]="input_3" #input3 type="text" maxlength="1"
                            size="1" (input)="onInputEntry($event, input4)" name="input-otp3"
                            (ngModelChange)="nameChanged($event,'3')" />
                        <input class="form-control input-otp" [(ngModel)]="input_4" #input4 type="text" maxlength="1"
                            size="1" (input)="onInputEntry($event, input5)" name="input-otp4"
                            (ngModelChange)="nameChanged($event,'4')" />
                        <input class="form-control input-otp" [(ngModel)]="input_5" #input5 type="text" maxlength="1"
                            size="1" (input)="onInputEntry($event, input6)" name="input-otp5"
                            (ngModelChange)="nameChanged($event,'5')" />
                        <input class="form-control input-otp" [(ngModel)]="input_6" #input6 type="text" maxlength="1"
                            size="1" (input)="onInputEntry($event, reset)" name="input-otp6"
                            (ngModelChange)="nameChanged($event,'6')" />
                    </div>
                    <small [ngClass]="((otp_status == false || invalid)) ? 'd-block' : 'd-none'" id="helpId"
                        class="form-text text-danger">{{this.messageService.wrongLoginData('wrong_otp')}}</small>
                    <small [ngClass]="(isRequired && submitted) ? 'd-block' : 'd-none'" id="helpId"
                        class="form-text text-danger">{{this.messageService.validationDisplay('required')}}</small>
                    <p class="fs-14 text-center mb-0 pt-28">Didn't you get a code? <span class="fw-500 link-primary"
                            (click)="resendOtp()">Resend OTP</span></p>
                    <div class="d-grid gap-2 pt-5">
                        <button (click)="otpClick()" type="button" name="" #reset id="reset" class="btn btn-primary"
                            [disabled]="(otp_status == false || invalid || (isRequired) || submitted)">Verify</button>
                    </div>
                </form>
            </div>
        </div>
        <p class="text-center mb-0 text-light-400 py-24"><small><a
                    href="https://www.skizzlehr.tech/pages/privacy-policy.php" class="link-secondary"
                    target="_blank">Privacy
                    policy</a> | <a href="https://www.skizzlehr.tech/pages/terms-and-condition.php"
                    class="link-secondary" target="_blank">Terms and conditions</a> |
                {{this.appservice.version}}</small></p>
    </div>
</ng-container>
