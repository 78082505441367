<div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='filter === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
        <h5>Filters</h5>
        <a class="toggle-panel" (click)="closeMethod()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
            <div class="row row-24 ">
                <div class="col-12" *ngIf="isCompanyNeeded || from=='groupEsi'">
                  <label for="name" class="form-label fw-500 fs-16 text-uppercase">Company </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Company')}}" [items]="companyDD"
                      [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="company_name" [closeOnSelect]="false" bindValue="id"
                      class="multiselect" [(ngModel)]="companyList" (change)="companyChange()">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                      </ng-template>

                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="companyList.length ">
                              <span class="ng-value-label">{{companyList.length }} Company
                                  Selected</span>
                          </div>
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index">
                          <div class="d-inline-flex align-items-center">
                            <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                            <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="item.logo !=  null && item.logo !=  ''"
                                  src="{{item.logo}}">
                          <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.company_name,1)}}</span></span>

                          <span class="">{{ (item.company_name.length>15)? (item.company_name | slice:0:15)+'..':(item.company_name)  }}</span>
                          </div>
                      </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="companyList.length != 0">
                      <li class="ng-value" *ngFor="let item of companyList,let ki =index">
                          <ng-container *ngFor="let insideitem of companyDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                <div class="d-inline-flex align-items-center">
                                  <img class=" img-fluid sq-24 rounded-circle"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                                  <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="  sq-24 avatar-circle rounded-circle me-1 bg{{k%5}}" >
                                      <span>
                                      {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                                  </span>
                                  <span class=""> {{ (insideitem['company_name'].length>25)? (insideitem['company_name'] | slice:0:25)+'..':(insideitem['company_name'])  }}</span>
                               </div>
                              </span>
                          </ng-container>
                          <span class="ng-value-icon right"
                              (click)="clearcommonForm('company',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>
                </div>
                <div class="col-12">
                    <label for="name" class="form-label fw-500 fs-16">BUSINESS UNIT</label>
                    <ng-select
                    [loading]="buLoader"
                    [(ngModel)]="buList"
                    placeholder="{{this.messageService.selectplaceholddisplay('Business Unit')}}"
                    [items]="buDD"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="multiselect"
                    >
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                            {{ item.name }}
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngIf="buList.length">
                                <span class="ng-value-label">
                                    {{buList.length}} Business Unit Selected</span>
                            </div>
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index">
                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                        </ng-template>
                    </ng-select>
                    <ul class="selected-values my-16" *ngIf="buList?.length != 0">
                        <li class="ng-value" *ngFor="let item of buList,let ki =index">
                            <ng-container *ngFor="let insideitem of buDD, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                </span>
                            </ng-container>
                            <span  class="ng-value-icon right" (click)="clearcommonForm('businessunit',ki)" aria-hidden="true">×</span>

                        </li>
                    </ul>
                </div>
                <div class="col-12" *ngIf="from!='groupEsi'">
                    <label for="name" class="form-label fw-500 fs-16 text-uppercase">Department</label>
                    <ng-select [loading]="DepLoader" placeholder="{{this.messageService.selectplaceholddisplay('Department')}}" [(ngModel)]="DepList" [items]="DepDD" [multiple]="true" groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                            </ng-template>
                            <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-item$="item$"
                                let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                {{ item.name }}
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                            <div class="ng-value" *ngIf="DepList.length ">
                                <span class="ng-value-label">{{DepList.length}} Department Selected</span>
                            </div>
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index">
                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                        </ng-template>
                    </ng-select>

                    <ul class="selected-values my-16" *ngIf="DepList?.length != 0">
                        <li class="ng-value" *ngFor="let item of DepList,let ki =index">
                            <ng-container *ngFor="let insideitem of DepDD, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                    {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                </span>
                            </ng-container>
                            <span  class="ng-value-icon right" (click)="clearcommonForm('department',ki)" aria-hidden="true">×</span>

                        </li>
                    </ul>
                </div>
                <div class="col-12" *ngIf="from!='groupEsi'">
                    <label for="name" class="form-label fw-500 fs-16 text-uppercase">Designation</label>
                    <ng-select [loading]="DesigLoader" placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}" [(ngModel)]="DesignationList" [items]="DesignationDD" [multiple]="true" groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        {{ item.name }}
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                            <div class="ng-value" *ngIf="DesignationList.length">
                                <span class="ng-value-label">{{DesignationList.length}} Designation Selected</span>
                            </div>
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index">
                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                        </ng-template>
                    </ng-select>
                    <ul class="selected-values my-16" *ngIf="DesignationList.length != 0">
                        <li class="ng-value" *ngFor="let item of DesignationList,let ki =index">
                            <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                    {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                            </ng-container>
                            <span class="ng-value-icon right" (click)="clearcommonForm('designation',ki)" aria-hidden="true">×</span>
                        </li>
                    </ul>
                </div>
                <div class="col-12">
                    <label for="name" class="form-label fw-500 fs-16 text-uppercase">Branch</label>
                    <ng-select [loading]="BranchLoader" placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}" [(ngModel)]="BranchList" [items]="BranchDD" [multiple]="true" groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                            {{ item.name }}
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                            <div class="ng-value" *ngIf="BranchList.length">
                                <span class="ng-value-label">{{BranchList.length}} Branch Selected</span>
                            </div>
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index">
                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                        </ng-template>
                    </ng-select>
                    <ul class="selected-values my-16" *ngIf="BranchList.length != 0">
                        <li class="ng-value" *ngFor="let item of BranchList,let ki =index">
                            <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                    {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                            </ng-container>
                            <span class="ng-value-icon right" (click)="clearcommonForm('branch',ki)" aria-hidden="true">×</span>
                        </li>
                    </ul>
                </div>
                <div class="col-12" *ngIf="from!='groupEsi'">
                    <label for="name" class="form-label fw-500 fs-16 text-uppercase">Grade</label>
                    <ng-select [loading]="gradeLoader" placeholder="{{this.messageService.selectplaceholddisplay('Grade')}}" [(ngModel)]="gradeList" [items]="gradeDD" [multiple]="true" groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                            {{ item.name }}
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                            <div class="ng-value" *ngIf="gradeList.length">
                                <span class="ng-value-label">{{gradeList.length}} Grade Selected</span>
                            </div>
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index">
                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                        </ng-template>
                    </ng-select>
                    <ul class="selected-values my-16" *ngIf="gradeList.length != 0">
                        <li class="ng-value" *ngFor="let item of gradeList,let ki =index">
                            <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                    {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                            </ng-container>
                            <span class="ng-value-icon right" (click)="clearcommonForm('grade',ki)" aria-hidden="true">×</span>
                        </li>
                    </ul>
                </div>
                <div class="col-12" *ngIf="from=='groupEsi'">
                    <label for="name" class="form-label fw-500 fs-16 text-uppercase">city</label>
                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('city')}}" [(ngModel)]="cityList" [items]="cityDD" [multiple]="true" groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index">
                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                            {{ item.name }}
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                            <div class="ng-value" *ngIf="cityList.length">
                                <span class="ng-value-label">{{cityList.length}} city Selected</span>
                            </div>
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index">
                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                        </ng-template>
                    </ng-select>
                    <ul class="selected-values my-16" *ngIf="cityList.length != 0">
                        <li class="ng-value" *ngFor="let item of cityList,let ki =index">
                            <ng-container *ngFor="let insideitem of cityDD, let k =index">
                                <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                    {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                            </ng-container>
                            <span class="ng-value-icon right" (click)="clearcommonForm('city',ki)" aria-hidden="true">×</span>
                        </li>
                    </ul>
                </div>

                <div class="col-12" *ngIf="from=='groupEsi'">
                  <label for="" class="form-label text-uppercase fw-500"> Status</label>
                  <ng-select class="form-ngselect" [(ngModel)]="status" [items]="statusDD" bindLabel="name"
                    bindValue="value" name="" id="status"
                    placeholder="{{this.messageService.selectplaceholddisplay('status')}}">
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div class="d-inline-flex align-items-center">
                        <span> {{item?.name}}</span>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
            </div>
        </div>
    </div>
    <footer class="side-panel-footer">
        <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetMethd()">
            reset
        </button>
        <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        (click)="applyMethod();" >
            Apply
        </button>
    </footer>
  </div>
</div>
