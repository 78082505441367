<form class="">
    <div class="container-fluid p-32 form-container">
    <app-c1-loader *ngIf="loader"></app-c1-loader>

        <div class="row row-16 mb-16" *ngIf="!loader">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">ARREAR CALCULATION</p>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body hstack indicater indicater-bg8">
                        <label for="" class="form-label required text-uppercase my-2 fw-600">salary revision
                            arrears</label>
                        <div class="ms-auto gap-16 hstack">
                            <button class="btn btn-outline-danger text-uppercase btn-sm" (click)="arrearIgnored()" *ngIf="arrearprocessFlag == false && arrearprocessFlag != null">IGNORE</button>
                            <button class="btn btn-primary text-uppercase btn-sm" (click)="arrearprocessFunction()" *ngIf="arrearprocessFlag == false && arrearprocessFlag != null">Process</button>
                            <button class="btn btn-primary text-uppercase btn-sm" (click)="arrearUnprocessFunction()" *ngIf="arrearprocessFlag == true && arrearprocessFlag != null">Unprocess</button>
                        </div>
                    </div>
                    <div class="card-body ">
                        <div class="row row-16">
                            <div class="col-12">
                                <p class="mb-0 fw-500">Details</p>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-0">
                                    <div class="table-responsive  scrollbar-10 radius-4">
                                        <table class="table sticky-header table-striped table-sm td-white-space td-width-15ch th-12 td-12
                    ">
                                            <thead>
                                                <tr>
                                                    <th class="fw-600  checkbox-22 " style="width:3rem" scope="col">
                                                        <input class="form-check-input checkbox-16 m-0" type="checkbox"
                                                        (click)="checkall($event)" [checked]="checkAllSelected()"
                                                        value="" *ngIf="arrearArr?.length != 0">
                                                    </th>
                                                    <th class="fw-600" scope="col">MONTH AND YEAR</th>
                                                    <th class="fw-600" scope="col">Days</th>
                                                    <th class="fw-600" scope="col" >Amount</th>
                                                    <th class="fw-600" scope="col" >Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngIf="arrearArr?.length == 0">
                                                    <td colspan="5">
                                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                            <span class="fw-500 fs-14">No data found</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let items of arrearArr; let i = index">
                                                    <td class="checkbox-22" style="width:3rem">
                                                        <input class="form-check-input m-0 checkbox-16"
                                                        [(ngModel)]="items.checked" type="checkbox"
                                                        (click)="viewButton($event,items?.status,items?.checked)" [ngModelOptions]="{standalone: true}" *ngIf="items?.status != 'Ignored'">
                                                    </td>
                                                    <td>{{items?.arrear_month}} {{items?.arrear_year}}</td>
                                                    <td>{{items?.paid_days}}</td>
                                                    <td >{{items?.arrear_amount}}</td>
                                                    <td>
                                                        <span class="badge px-16 ms-auto fs-14 badge-warning py-1" *ngIf="items?.status == 'Unprocessed'">Pending</span>
                                                        <span class="badge px-16 ms-auto fs-14 badge-success py-1" *ngIf="items?.status == 'Processed'">Processed</span>
                                                        <span class="badge px-16 ms-auto fs-14 badge-danger py-1" *ngIf="items?.status == 'Ignored'">Ignored</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body hstack indicater indicater-bg6">
                        <label for="" class="form-label required text-uppercase my-2 fw-600">ATTENDANCE CORRECTION
                            arrears </label>
                        <div class="ms-auto gap-16 hstack">
                            <button class="btn btn-primary text-uppercase btn-sm">process</button>
                        </div>
                    </div>
                    <div class="card-body ">
                        <div class="row row-16">
                            <div class="col-12">
                                <p class="mb-0 fw-500">Details</p>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-0">
                                    <div class="table-responsive  scrollbar-10 radius-4">
                                        <table
                                            class="table sticky-header table-striped table-sm td-white-space td-width-15ch">
                                            <thead>
                                                <tr>

                                                    <th class="fw-600" scope="col">MONTH AND YEAR</th>
                                                    <th class="fw-600" scope="col">Days</th>
                                                    <th class="fw-600" scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let i of [1,2,3]">
                                                    <td>December 2022</td>
                                                    <td>2</td>
                                                    <td>Amount</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" (click)="validateArrear()">NEXT</button>
    </footer>
</form>

<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-info-circle text-accent2"></i>
          <h4 class="modal-title">Info</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{empalertMsg}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="alertToggle = false">Ok</button>
        </div>
      </div>
    </div>
  </div>