import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class holidaytypeService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }


  constructor(
    private http: HttpClient,
    public router : Router
  ) { }


  getholidaytypeList(filter:string = '',limit:any,offset:any,isDisabled:any,search:any,year:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-list/?is_active=${filter}&limit=${limit}&offset=${offset}&is_disabled=${isDisabled}&search=${search}&year=${year}`, this.httpOption);
  }

  holidayTypeSearch(filter:any = '',isDisabled:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-list-dropdown/?is_active=${filter}&ordering=holiday_name&is_disabled=${isDisabled}`, this.httpOption)
  }

  getholidayType(id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-detail/${id}/`, this.httpOption)
  }

  createholidaytype(data:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/holiday-list/`, data, this.httpOption)
  }

  updateholidayType(id: number, body:any) {
    return this.http.put(`${this.API_PATH}attendance/api/v1/holiday-detail/${id}/`, body, this.httpOption)
  }

  deleteholidayType(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}attendance/api/v1/holiday-delete/${id}/`, body, this.httpOption)
  }

 


}
