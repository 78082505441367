<div class="container-fluid p-32 form-container" [formGroup]="formGp">
    <div class="row row-16">
        <div class="col-12 fw-500">REIMBURSEMENT CATEGORY</div>


          <div  class="col-lg-4 col-sm-6 " *ngFor="let category of categoryData;let i=index;">
              <label class="radio-group" for="btnradio{{i+1}}">
                  <input type="radio" class="radio-check" name="btnradio" id="btnradio{{i+1}}" autocomplete="off" (change)="setpaycomponent(category.pay_component_id)" [checked]="f.paycomponent.value==category.pay_component_id?true:false">
                  <div class="radio-content details-card card-hover card ">
                      <div class="card-body indicater indicater-bg{{i+1}}">
                          <div class="fw-600 mb-1 tips text-trim text-uppercase fs-14 pe-24">{{category.name}}</div>
                          <span class="tips-helper">{{category.name}}</span>
                          <div class="fs-10 fw-500 text-success" *ngIf="category?.as_of_date == 'Monthly'">AS OF {{currentMonth}} {{currentYear}}</div>

                          <div class="fs-10 fw-500 text-success" *ngIf="category?.as_of_date == 'Yearly'">FISCAL YEAR {{this.appService.getFinancialYearRange(currentYear)}}</div>
                      </div>
                      <div class="card-body pt-8 ">
                          <div class="row row-24 ">

                              <div class="col-6">
                                  <div class="detail-title">Eligible</div>
                                  <div class="detail-desc">{{category?.balance| currency:currency}}</div>
                              </div>
                              <div class="col-6">
                                  <div class="detail-title">Claimed</div>
                                  <div class="detail-desc">{{category?.claimed| currency:currency}}</div>
                              </div>
                          </div>
                      </div>

                  </div>
              </label>
          </div>


          <div *ngIf="categoryData?.length==0;" class="row pt-4">
            <!-- <div class="col-12 text-center">
                <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
                    style="width: 27.375rem;">
                <p class="text-uppercase text-light-400 fw-700 fs-14">REIMBURSEMENT CATEGORY HAVEN'T BEEN CONFIGURED YET</p>

            </div> -->
            <app-nodata-view [noDataText]="'REIMBURSEMENT CATEGORY HAVE NOT BEEN CONFIGURED YET'" [hasPermission]="false"> </app-nodata-view>
        </div>

        <input type="text" formControlName="paycomponent" hidden>
    </div>
</div>
<footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="formGp.invalid" (click)="changeTab(2)">NEXT</button>
</footer>
