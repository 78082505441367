<nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky top-48">
    <li [ngbNavItem]="1" (click)="clearSearch()">
        <a ngbNavLink class="nav-link">All</a>
        <ng-template ngbNavContent>
          <app-receipt-landing-table [status]="''" (searchout)="searchout($event)" [permissions]="permissions" [currency]="currency" (clearSearch)="clearSearch()" [clearBar]="clearBar"></app-receipt-landing-table>

        </ng-template>
    </li>
    <li [ngbNavItem]="2" (click)="clearSearch()">
        <a ngbNavLink class="nav-link">Unreported</a>
        <ng-template ngbNavContent>
          <app-receipt-landing-table [status]="'Unreported'" (searchout)="searchout($event)" [permissions]="permissions" [currency]="currency" (clearSearch)="clearSearch()" [clearBar]="clearBar" [sLoading]="sLoading"></app-receipt-landing-table>
        </ng-template>
    </li>
    <li [ngbNavItem]="3" (click)="clearSearch()">
      <a ngbNavLink class="nav-link">Unsubmitted</a>
      <ng-template ngbNavContent>
        <app-receipt-landing-table [status]="'Unsubmitted'" (searchout)="searchout($event)" [permissions]="permissions" [currency]="currency" (clearSearch)="clearSearch()" [clearBar]="clearBar" [sLoading]="sLoading" ></app-receipt-landing-table>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" (click)="clearSearch()">
      <a ngbNavLink class="nav-link">Submitted</a>
      <ng-template ngbNavContent>
        <app-receipt-landing-table [status]="'Submitted'" (searchout)="searchout($event)" [permissions]="permissions" [currency]="currency" (clearSearch)="clearSearch()" [clearBar]="clearBar" [sLoading]="sLoading"></app-receipt-landing-table>
      </ng-template>
    </li>
    <li [ngbNavItem]="5" (click)="clearSearch()">
      <a ngbNavLink class="nav-link">Accepted</a>
      <ng-template ngbNavContent>
        <app-receipt-landing-table [status]="'Accepted'" (searchout)="searchout($event)" [permissions]="permissions" [currency]="currency" (clearSearch)="clearSearch()" [clearBar]="clearBar" [sLoading]="sLoading"></app-receipt-landing-table>
      </ng-template>
    </li>

</nav>
<div class="container-fluid p-24 ">
    <div class="row mb-16">
        <div class="col-md-4">
            <h3>Expense Receipts</h3>
            <p class="text-helper mb-md-0">Add and manage your expense receipts here.</p>
        </div>
        <div class="col">
            <div class="d-flex justify-content-end  gap-16">
              <div style="width:22.6875rem;">
                <div class="">
                  <app-searchbar  #search [searchlist]='searchlistdata' [searchplaceholder]='receiptPlaceholder' (searchEmitter)="searchresults($event)" [isLoading]="sLoading"  (keywordsearchEmitter)="searchReceipt($event)"></app-searchbar>
                </div>
              </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle (click)="filter=true">
                        <i class="icon-filter-left fs-13"></i>
                    </button>

                </div>
                <ng-container >

                  <button class="btn flex-shrink-0 btn-outline-primary btn-icon btn-add" routerLink="/bulk-expense"><i
                          class="icon-plus"></i>ADD
                      bulk EXPENSE</button>
                  <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="editDetail = true"><i
                          class="icon-plus"></i>ADD new EXPENSE</button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row row-16" [ngbNavOutlet]="nav"></div>
</div>



<app-add-new-expense [from]="'receipt'" [addExpense]="editDetail" (saveReceipt)="saveReceipt($event)" (closeAddExpense)="closeAddExpense()"></app-add-new-expense>



<div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='filter === true'  [formGroup]="filterForm">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="closefn()"><i class=" icon-close-lg fs-16"></i></a>

    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 ">
          <div class="col-12 form-row">
            <label for="" class="form-label ">Expense category</label>
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Expense category')}}"
              formControlName="category" [items]="categoryData" [multiple]="true" groupBy="selectedAllGroup"
              [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
              bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                  {{ item.name }}
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.category.length">
                  <span class="ng-value-label">{{filterForm.value.category.length}} Category
                    Selected</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.category?.length != 0">
              <li class="ng-value" *ngFor="let item of filterForm.value.category,let ki =index">
                <ng-container *ngFor="let insideitem of categoryData, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                    slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span  class="ng-value-icon right" (click)="clearcommonForm('category',ki)"
                  aria-hidden="true">×</span>
              </li>
            </ul>
          </div>


          <div class="col-12 form-row">
            <label for="" class="form-label ">Expense date</label>

          <div class="form-icon icon-right vertical-datepicker">
            <input
              class="form-control datepicker-vertical"
              type="text"
              onkeydown="return false"
              opens="right"
              ngxDaterangepickerMd
              [closeOnAutoApply]="true"
              [showCustomRangeLabel]="true"
              [alwaysShowCalendars]="false"
              [ranges]="ranges"
              [linkedCalendars]="true"
              [locale]="{ applyLabel: 'ok' }"
              [isInvalidDate]="isInvalidDate"
              [showClearButton]="true"
              placeholder="Select date"
              formControlName="expense_date"
            />
            <i class="icon-calendar pointer-event"></i>
          </div>
          </div>

        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetFilter()">
        reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="applyFilter()" [disabled]="filterForm.invalid">
        Apply
      </button>
    </footer>
  </div>
</div>


