<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">LEAVE POLICY DETAILS</p>
        </div>
        <div class="row row-16">
            <div class=" col-12">
                <label for="name" class="form-label required">Policy Name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter a unique name for the leave policy. This is a custom type."
                        popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                <input type="text" formControlName="name" class="form-control "
                    placeholder="{{this.messageService.placeholderdisp('leave policy name')}}"
                    [ngClass]="{ 'is-invalid': (saveBtnClicked || f.name.touched || f.name.dirty) &&  f.name.errors || f.name.errors?.exist}"
                    (keyup)="keyUp()">
                <div *ngIf="(saveBtnClicked || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist"
                    class="invalid-feedback">
                    <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay('Policy name')}}</div>
                    <div *ngIf="f.name.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                    <div *ngIf="f.name.errors?.pattern">
                        {{this.messageService.validationDisplay('pattern')}}</div>

                </div>
            </div>
            <div class="col-lg-12 ">
                <div class="form-row">
                    <label for=" " class="form-label ">Leave Cycle </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [value]=false formControlName="leave_cycle">
                    <label class="form-check-label">
                        Calendar
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                            ngbPopover="Calendar based leave cycle starts at the beginning of the year and lapses at the end of the year eg: Casual leaves."
                            popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [value]=true formControlName="leave_cycle">
                    <label class="form-check-label">
                        Financial
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                            ngbPopover="Financial based leave cycle begins on every financial year, like April 1st and lapses on March 31st. eg : Earned leaves"
                            popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
            </div>

            <div class="col-12">
                <label for="" class="form-label">Description </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter a few lines of description on the leave policy."
                        popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                <textarea placeholder="{{this.messageService.descriptiontext('desc')}}" class="form-control"
                    [ngClass]="{ 'is-invalid': (saveBtnClicked || f.description.touched || f.description.dirty) &&  f.description.errors}"
                    formControlName="description"></textarea>

                <div *ngIf="(saveBtnClicked ||f.description.touched || f.description.dirty) && f.description.errors"
                    class="invalid-feedback">
                    <div *ngIf="!f.description.errors.pattern && f.description.errors.maxlength">
                        {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                </div>
            </div>
            <div class="col-12">
                <label for="" class="form-label required">Policy valid from </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Select the date from when this policy is effective."
                        popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
                <div class="input-group form-icon icon-right"
                    [ngClass]="{ 'is-invalid': (f.policy_valid_from.touched) && f.policy_valid_from.errors}">
                    <input (click)="datePicker1.open()" (dateInput)="policy_valid_from($event)"
                        placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
                        [ngClass]="{ 'is-invalid': ( f.policy_valid_from.touched) && f.policy_valid_from.errors}"
                        matInput formControlName="policy_valid_from" [matDatepicker]="datePicker1"
                        (keydown)="disableDate()" [min]="minDate">
                    <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                    <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
                </div>
                <div *ngIf="( f.policy_valid_from.touched) && f.policy_valid_from.errors" class="invalid-feedback">
                    <div *ngIf="f.policy_valid_from.errors.required">
                        {{this.messageService.validationDisplay('required')}}
                    </div>
                </div>
            </div>


            <div class=" col-md-6 form-row">
                <label for="name" class="form-label required">Map leave type </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Select for which leave type, this policy has to be mapped."
                        popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>

            <ng-container formArrayName="mapleave" *ngFor="let map of f.mapleave.controls; let i = index">
                <ng-container [formGroupName]="i">
                    <div class="col-12 form-row mt-8">
                        <div class="form-check">
                            <input type="checkbox" name="leaveType" id="{{leaveType[i]['leave_name']}}"
                                class="form-check-input " formControlName="checked" (change)="checkboxAction(i)">
                            <label for="{{leaveType[i]['leave_name']}}"
                                class="form-check-label">{{leaveType[i]['leave_name']}}</label>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase"
            [disabled]="!formGp.valid || CheckSelectedCount()==0" (click)="validateForm()">NEXT</button>
    </footer>
</form>
