import { Component, OnInit, Inject, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';


@Component({
  selector: 'app-bank-details-report',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() DesignationDD: any;
  @Input() checkListElements: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above all needs to be removed
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() bankdetails : any;
  @Input() callemp : any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() ouFilterForm: any
  @Input() resDataFilter:any;

  get f()  { return this.bankdetails.controls; }

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  constructor(public employeereportService:EmployeeReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService, public cd : ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  // Emiting the value of radio to parent
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,type:any,form:any){
    if(e.target.value == 'on' ){
     this.bankdetails?.get(contrlName)?.setValue(true);
    }
    else{
     this.bankdetails?.get(contrlName)?.setValue(val);
     this.bankdetails?.get(type)?.setValue('');
    }
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

    // Select All
    selectAllForDropdownItems(items: any[]) {
      let allSelect = (items: any[]) => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
    }

    resetFilterFunction(){
      if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.resetFilter.emit(true)
        }else{
          this.resetFilterControllers('reset');
        }
      }
    }

    resetFilterControllers(data:any){
      this.viewDetail = false;
      this.filterPanel.emit(this.viewDetail)
      if(data == 'reset'){
        this.ouFilterForm.get('company')?.reset('');
        this.ouFilterForm.get('department')?.reset('');
        this.ouFilterForm.get('bu')?.reset('');
        this.ouFilterForm.get('designation')?.reset('');
        this.ouFilterForm.get('grade')?.reset('');
        this.ouFilterForm.get('branch')?.reset('');
        this.ouFilterForm.get('employee_list')?.reset('');
        this.ouFilterForm.get('employment_status')?.reset('');
        this.ouFilterForm.get('employee_type')?.reset('');
      }
      this.callMonthlyReport.emit({
        reportName : 'Bank Details'
      })
    }

  applyBtn : boolean = false

  filterFormData(use:any){
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value)

    if(use == 'filter'){
      return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

    /************ Bank Details FILTER *****************/
submitBankDetails(){
  this.submitted      = true;
  if (this.bankdetails.invalid) {
    return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.employeereportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail           = false;
    this.applyBtn = false
  }


// Tags closing
applyTagClose1(){
  this.applyBtn = true
  // if(this.closeControl == 'emp_status'){
  //   this.empstatArr = [];
  // }else if(this.closeControl == 'emp_type'){
  //   this.tempArr = [];
  // }
  this.ouFilterForm.get(this.closeControl)?.setValue('');
  this.submitBankDetails();
  }

resetPage(){
  this.applyBtn = true
  this.employeereportService.calculateOffset(0)
  this.cd.detectChanges()
}

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }
}






