import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { RoleService } from '../../../../../@core/services/role.service'
import { FormArray, FormBuilder, FormGroup , Validators } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-role-define',
  templateUrl: './role-define.component.html',
  styleUrls: ['./role-define.component.scss']
})
export class RoleDefineComponent implements OnInit {
  //formGp: any;
  public empId: any;
  public roleName: string = '';
  public count: any = 0;

  validated : boolean = false
  roleUnit : any
  routeComponent : any
  value:any
  private subjectKeyUp = new Subject<any>();

  @ViewChild('ngSelectComponent') ngSelectComponent: any;
  temp_list: any = [];
  empArr: any = [];
  emp_data: any = [];
  emp_edit_data: any = [];
  role_employee: any;
  role_id: number = 0;
  // maxLength = 40;

  submitted = false;
  is_active: any = true;
  selected_flag = false;
  constructor(
    private roleService: RoleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public messageService : MessageService,
    public existDataService :  ExistDatasService,
    private route: ActivatedRoute,
    public appService:AppService,
    private cd : ChangeDetectorRef
  ) { }

  @Input()formGp : FormGroup = this.formBuilder.group({
    role_name: ['', [Validators.required]],
    employee: ['', [Validators.required]]
  });
  @Input()assignformGp : FormGroup = this.formBuilder.group({
    list : this.formBuilder.array([])
  });

  @Input() employeeDropdown:any =[];
  @Input() disabled=false;
  @Input() editflag:any;

  @Output()
  submitmethod = new EventEmitter();
  // unamePattern =  "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";
  @Output() companyChange1 = new EventEmitter();


  ngOnInit(): void {

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    // this.selectAllForDropdownItems(this.employeeDropdown);

    this.route.url.subscribe((res:any)=>{
      this.routeComponent = res[0].path
    })

    this.role_id = this.route.snapshot.params['id'];
    this.initiateErrorStatus();
  }

  validateConfigureFlow() {
    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit(this.formGp.value);
  }
  // convenience getter for easy access to form fields
  get f() { return this.formGp.controls; }



  empList(status: any) {
    this.roleService.getempList(status)
    .subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          this.employeeDropdown = res.body;
        }
      }
    })
  }

  userExists(id: number) {
    return this.employeeDropdown.some(function(el:any) {
      return el.id === id;
    });
  }

  assignForm() : FormArray {
    return this.assignformGp.get("list") as FormArray
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  clear(index:number){
    let daata = this.formGp.value.employee;
    daata.splice(index, 1);
    const myForm = (<FormArray>this.formGp.get("employee"));
    myForm.patchValue(daata);
    this.assignForm().removeAt(index)
    this.initiateErrorStatus();
    this.companyChange1.emit({myForm : this.formGp.value.employee.length, index: index});
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":this.routeComponent,
        "field":"role_name",
        "value": this.f.role_name.value
      }
    }else{
       this.value ={
        "id":this.role_id,
        "page":this.routeComponent,
        "field":"role_name",
        "value": this.f.role_name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.roleUnit = res.body

      if(this.roleUnit.data ==='invalid'){
        this.f.role_name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)

  keyUp(){
    this.subjectKeyUp.next(this.f.role_name.value)
  }

  // Inactive Employee Setup
  ngChange(e:any){
    if(this.hasSpecificStatus(e) && !this.editflag){
      this.formGp?.get('employee')?.setErrors({
        'relievedError' : true
      })
    }
  }

  hasSpecificStatus(employees:any){
    const statuses = ['Relieved','Settled','Terminated','Retired']
    return employees.every((employee:any)=> statuses.includes(employee.employment_status));
  }

  initiateErrorStatus(){
    if(this.f.employee.value != null && this.f.employee.value != ''){
      const filteredData = this.employeeDropdown.filter((item:any) => this.f.employee.value.includes(item.id))
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData) && !this.editflag){
          this.formGp?.get('employee')?.setErrors({
            'relievedError' : true
          })
          this.formGp.get('employee')?.markAsTouched();
          this.cd.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.employeeDropdown.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }
}
