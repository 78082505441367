import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OverrideService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  validate(data:any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/overide-validate/`,data, this.httpOption)
  }
 
  save( body:any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/overide-save/`, body, this.httpOption)
  }
  list(company:any,month:any,year:any,component_type:any,businessunit:any,department:any,designation:any,branch:any,grade:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/overide-list-get/?company=${company}&month=${month}&year=${year}&component_type=${component_type}&status=Processed&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`, this.httpOption)
  }
  delete(data:any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/overide-delete/`,data, this.httpOption);
  }

  payGroupList(month:any,year:any,status:any,company:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/overide-pay-group-dropdown/?is_active=true&month=${month}&year=${year}&status=${status}&company=${company}`, this.httpOption)
  }
  payComponentOverrideSave(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/overide-component-list-save/`, data, this.httpOption)
  }
  employeeList(companyId:any,paygroup:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name&company__in=${companyId}&paygroup=${paygroup}`, this.httpOption);
  }
}
