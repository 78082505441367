import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  
} from '@angular/common/http';
import {  BehaviorSubject,  Observable,throwError} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { WebsocketService } from '../services/websocket.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';

const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  constructor(
    private authService: AuthService,
    private websocketService:WebsocketService,
    public router: Router,
    private appservice:AppService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = request;
    let run = true;

    const token:any = this.authService.getToken();
    const userData:any = this.authService.getUserData();
    const helper = new JwtHelperService();

    if (token != null && !authReq.url.includes('token') ) {

      if(helper.isTokenExpired(token)){

        const rToken:any = this.authService.getRefreshToken();
        if(helper.isTokenExpired(rToken)){
          localStorage.removeItem('access_token');
          run = false;
        } else {
          return this.handleRefresh(authReq, next);
        }
      } else {
        authReq = this.addTokenHeader(request,token);
      }
    } else if(!authReq.url.includes('token') &&!authReq.url.includes('login') && !authReq.url.includes('get-otp') && !authReq.url.includes('verify-otp')  && !authReq.url.includes('reset_password') && !authReq.url.includes('session-timeout') ){
      run = false;
    } 

    if(run == true ){
      return next.handle(authReq).pipe(
        catchError(error => {
          if ( error.status === 401 && !authReq.url.includes('token')  )  {
            return this.handleRefresh(authReq, next);
          }
          return throwError(error);
      })
      );
    } else {
      this.websocketService.closeSocket();
      this.router.navigate(['login']);
      return EMPTY;
    }
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY,  'Bearer ' + token) });
  }

  private handleRefresh(authReq: HttpRequest<any>, next: HttpHandler) {
    if( !this.isRefreshing){
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refresh().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access);
          return next.handle(this.addTokenHeader(authReq, token.access));
        }),
        catchError(error=>{
          if ( error.status === 401){
            this.isRefreshing = false;
            this.appservice.resetPermissions()
            this.authService.doLogout()
            this.websocketService.closeSocket();
          }
          return throwError(error);
        })
      );
    }

    return this.refreshTokenSubject.pipe(filter(token => token!== null),
      take(1),
      switchMap((token)=>next.handle(this.addTokenHeader(authReq, token))
      ));
  }
  
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

}
