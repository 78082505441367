<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row ">
      <div class="d-flex gap-8 mb-16">
        <span class="text-uppercase fw-500">SUMMARY</span>
        <span class="fs-14 link-primary1 fw-500 d-flex align-items-center ms-auto"
          *ngIf="(formGp.value?.leave_type != null && formGp.value?.leave_type != '')" (click)="getLeaveRule()">
          <i class="bi bi-question-circle me-1"></i>Leave Rules</span>
      </div>
    </div>
    <div class="row row-24">
      <div class="col-12">
        <div class="card details-card shadow-0 px-24 py-16">
          <div class="row row-24">
            <div class="col-12">
              <span class="title">Leave SUMMARY</span>
            </div>
            <div class="col-12">
              <div class="detail-title">Leave type</div>
              <div class="detail-desc">{{formGp.value?.leave_name}}</div>
            </div>

            <div *ngIf="formGp.value?.compoff_date?.length > 0" class="col-12">
              <div class="detail-title">Comp off date to compensate with</div>
              <div class="detail-desc">{{formGp.value?.compoff_date_num.join(', ')}}</div>
            </div>

            <div class="col-12">
              <div class="detail-title">Reason</div>
              <div class="detail-desc">{{formGp.value?.reason}}</div>
            </div>
            <div class="col-12">
              <div class="detail-title mb-2">Document</div>
              <div style="width:12.9375rem;">

                <div class="detail-desc" *ngIf="formGp.value?.document == ''">-</div>

                <div class="card card-c2  bg-secondary" *ngIf="formGp.value?.document != ''">

                  <div class="p-12 bg-secondary d-flex align-item-center">

                    <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                    <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                      <span
                        class="tips text-uppercase fs- fw-700 text-trim">{{this.appservice.getDocName(formGp.value?.document)}}</span>
                      <span class="tips-helper ">{{this.appservice.getDocName(formGp.value?.document)}}</span>

                      <div class="d-flex gap-8">
                        <div class="link-primary" (click)="viewDocument(formGp.value?.document)">
                          <i class="bi bi-eye me-1"></i>View
                        </div>
                        <div class="link-primary"
                          (click)="this.appservice.downloadFile(formGp.value?.document,this.appservice.getDocName(formGp.value?.document))">
                          <i class="bi bi-download me-1"></i>Download
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </div>
            <div class="col-12">
              <span class="title">Leave for</span>
            </div>
            <!-- <div class="col-12" *ngIf="formGp.value?.from_date != formGp.value?.to_date">
                            <span
                                class="fw-500 fs-14 text-uppercase">{{this.appservice.dateFormatDisplay(formGp.value?.from_date)}}
                                <span class="text-light-500">to</span>
                                {{this.appservice.dateFormatDisplay(formGp.value?.to_date)}}
                                ({{formGp.value?.leave_num_days}} {{(formGp.value?.leave_num_days <= 1)? 'day'
                                    :'days'}})</span>
                        </div> -->
            <div class="col-12 d-flex" *ngIf="formGp.value?.from_date != formGp.value?.to_date">
              <span class="fw-500 fs-14 text-uppercase">{{this.appservice.dateFormatDisplay(formGp.value?.from_date)}}
                <span class="text-light-500">to</span>
                {{this.appservice.dateFormatDisplay(formGp.value?.to_date)}}
              </span>
              <span class="ms-auto fs-14">No. of days: <span class="fw-600">{{formGp.value?.leave_num_days}}
                  {{(formGp.value?.leave_num_days <= 1)? 'day' :'days'}}</span> </span>
            </div>
            <ng-container *ngFor="let portion of portions,let i = index"></ng-container>
            <div class="col-12">
              <div class="detail-title">Full day</div>
              <div class="detail-desc">{{ (fullDay != '' && fullDay != null) ? fullDay.join(', ') : '-' }}
              </div>
            </div>
            <div class="col-12">
              <div class="detail-title">Half day</div>
              <div class="detail-desc">{{ (halfDay != '' && halfDay != null) ? halfDay.join(', ') : '-'}}
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card details-card shadow-0 px-24 py-16">
          <div class="row row-24">
            <div class="col-12 d-flex">
              <span class="title">BALANCE LEAVES</span>
              <span class="link-primary1 fs-14 fw-500 ms-auto" (click)="leaveBalanceFunction1()"><i
                  class="bi bi-eye me-1"></i>Details</span>
            </div>
            <div class="{{formGp.value.leave_name == 'Compensatory off' ? 'col-md-12' : 'col-md-6'}}">
              <div class="card card-c2 bg14 px-24 py-16">
                <div class="fs-24 fw-500 pb-16">{{validationPortionData?.leaves_available_after_booking}} days
                </div>
                <div class="detail-desc">TOTAL AVAILABLE LEAVES AFTER BOOKING </div>
                <div class="detail-title">As of today</div>
              </div>
            </div>
            <div *ngIf="formGp.value.leave_name != 'Compensatory off'" class="col-md-6">
              <div class="card card-c2 bg-warning px-24 py-16">
                <div class="fs-24 fw-500 pb-16">{{validationPortionData?.estimated_leave_balance}} days</div>
                <div class="detail-desc">ESTIMATED TOTAL LEAVE BALANCE </div>
                <div class="detail-title">As of {{this.appservice.dateFormatDisplay(lastDate)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveClicked"
      (click)="validateLeaveSummary()">SAVE</button>
  </footer>
</form>

<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="investalertMsg"></app-alert-toggle>
<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
  [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>


<!-- Leave balance start -->
<div class="side-panel" style="--sidepanel-width:56.25rem;" [class.side-pane-active]='leaveBalance === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Total available balance after booking</h5>
      <a class="toggle-panel" (click)="leaveBalance = false"><i class="icon-close-lg"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-table-loader *ngIf="loader"></app-table-loader>
        <div class="row row-16" *ngIf="!loader">
          <div class="col-12">
            <div class="card card-c2 hstack px-24 gap-16 py-16 indicater3 bg14 indicater-bg10">
              <div class="vstack justify-content-center gap-4">
                <span class="fs-12 fw-500 text-uppercase">TOTAL available LEAVES AFTER BOOKING </span>
                <span class="fs-12  text-light-500">As of today</span>
              </div>
              <div class="ms-auto fs-28">{{leaveBalanceDataTile?.total_avail_leaves_after_booking}} Days
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 hstack px-24 gap-16 py-16 indicater3 bg-warning indicater-bg6">
              <div class="vstack justify-content-center gap-4">
                <span class="fs-12 fw-500 text-uppercase">ESTIMATED TOTAL LEAVE BALANCE </span>
                <span class="fs-12  text-light-500">As of
                  {{this.appservice.dateFormatDisplay(lastDate)}}</span>
              </div>
              <div class="ms-auto fs-28">{{leaveBalanceDataTile?.estimated_total_leave_balance}} Days
              </div>
            </div>
          </div>
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0">LEAVE balance details</p>
          </div>

          <app-view-leave-balance [leaveBalanceTable]="leaveBalanceTable"></app-view-leave-balance>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Leave rule start -->
<div class="side-panel" style="--sidepanel-width:56.25rem;" [class.side-pane-active]='leaveRule === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Leave Rule ({{leaveRuleData?.leave_type_details?.leave_name+' - '+leaveRuleData?.leave_type_details?.leave_code}})</h5>
      <a class="toggle-panel" (click)="leaveRule = false"><i class="icon-close-lg"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-table-loader *ngIf="loader"></app-table-loader>
        <div class="row row-16" *ngIf="!loader">
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Type Details</p>
              </div>
              <div class="card-body p-24">
                <ol class="li-reset li-16  fs-14">
                  <li>Leave name: {{leaveRuleData?.leave_type_details?.leave_name}}</li>

                  <li>Leave code: {{leaveRuleData?.leave_type_details?.leave_code}}</li>

                  <li>{{leaveRuleData?.leave_type_details?.paid_leave == true ? 'This leave is a paid leave.' : 'This leave is an Unpaid leave; There will be deduction in the salary if this leave is availed.'}}</li>

                  <li>{{leaveRuleData?.leave_type_details?.negative_balance_allowed == true ? 'Negative leave balance is allowed; you can take maximum of '+leaveRuleData?.leave_type_details?.negative_balance_days+' days leave in advance.' : 'Negative leave balance is not allowed in this leave type.'}}</li>

                  <li>{{leaveRuleData?.leave_type_details?.halfday_leave_allow ? 'Half day leave request is allowed in this leave type.' : 'Half day leave request is not allowed in this leave type.'}}</li>

                  <li *ngIf="">{{leaveRuleData?.leave_type_details?.cancellation_allowed ? 'Leave request cancellation is allowed after the approval of the leave.' : 'Leave request cancellation is not allowed after the approval of the leave.'}}</li>

                  <li
                    *ngIf="(leaveRuleData?.leave_type_details?.leave_encashable && leaveRuleData?.leave_type_details?.leaveendyear_leave_encash_allowed && !leaveRuleData?.leave_type_details?.max_encash_limit_status)">
                    This leave type can be encashed during the year-end process: Encash first and
                    carry forward the remaining balance </li>

                  <li
                    *ngIf="(leaveRuleData?.leave_type_details?.leave_encashable && leaveRuleData?.leave_type_details?.max_encash_limit_status)">
                    Maximum of {{leaveRuleData?.leave_type_details?.max_encash_limit}} leaves can be
                    encashed during the final settlement or all unused leaves can be encashed during
                    the final settlement.</li>

                  <li *ngIf="leaveRuleData?.leave_type_details?.weekoff_as_leave">
                    {{leaveRuleData?.leave_type_details?.weekoff_leave}}
                  </li>

                  <li *ngIf="leaveRuleData?.leave_type_details?.holiday_as_leave">
                    {{leaveRuleData?.leave_type_details?.holiday_leave}}
                  </li>

                </ol>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Credit Details</p>
              </div>
              <div *ngIf="(leaveRuleData?.leave_credit_details | json) != '{}'" class="card-body p-24">
                <ol class="li-reset li-16  fs-14">
                  <li>{{leaveRuleData?.leave_credit_details?.no_of_leaves}} leave will be credited on
                    {{this.appservice.ordinal_suffix_of(leaveRuleData?.leave_credit_details?.dayof_month)}}
                    of every {{leaveRuleData?.leave_credit_details?.period == 'Monthly' ? 'month' :
                    leaveRuleData?.leave_credit_details?.period == 'Yearly' ? 'year' :
                    leaveRuleData?.leave_credit_details?.period == 'Ouarterly' ? 'quarter' : 'half year'}}.</li>

                  <ng-container *ngIf="leaveRuleData?.leave_credit_details?.grant_leave_based_prev_month">
                    <li>
                      The above leave will be credited based on the employee's previous month attendance, employees should have {{leaveRuleData?.leave_credit_details?.prev_month_min_count}} or more {{leaveRuleData?.leave_credit_details?.req_attendance_calculate_from == true ? 'Paid days' : 'Present days'}} in the previous month to get the full leave quota.
                    </li>
                    <li *ngIf="leaveRuleData?.leave_credit_details?.criteria_not_met">
                      If the employee's previous month's attendance ({{leaveRuleData?.leave_credit_details?.req_attendance_calculate_from == true ? 'Paid days' : 'Present days'}}) does not match with the required criteria, then the current leave accrual will be prorated based on attendance.
                    </li>
                    <li *ngIf="!leaveRuleData?.leave_credit_details?.criteria_not_met">
                      If the employee's previous month's attendance ({{leaveRuleData?.leave_credit_details?.req_attendance_calculate_from == true ? 'Paid days' : 'Present days'}}) does not match with the required criteria, then no leave will be credited in the current leave accrual.
                    </li>
                  </ng-container>

                  <li *ngIf="leaveRuleData?.leave_credit_details?.carry_forward_next_cycle">Maximum of
                    {{leaveRuleData?.leave_credit_details?.carry_forward_limit}} leaves can be carry
                    forwarded to next leave cycle.</li>

                </ol>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Request Criteria</p>
              </div>
              <div class="card-body p-24">
                <ol class="li-reset li-16  fs-14">

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_waiting_status">
                    This leave type can be requested only after {{leaveRuleData?.leave_request_criteria?.waiting_period_days}} {{leaveRuleData?.leave_request_criteria?.waiting_period_effective}} from {{leaveRuleData?.leave_request_criteria?.waiting_period_start_date == true ? 'DOC' : 'DOJ'}}.
                  </li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.prior_days_required_req_leave">
                    Leave request should be raised in
                    {{leaveRuleData?.leave_request_criteria?.prior_days}} days' prior notice.</li>
                  <li *ngIf="leaveRuleData?.leave_request_criteria?.backdated_leave_allow">Back dated
                    leave request is allowed for this leave type (max
                    {{leaveRuleData?.leave_request_criteria?.backdated_days}} days before).
                  </li>
                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_during_probation">
                    {{leaveRuleData?.leave_request_criteria?.leave_during_probation ? 'Leave request can be raised during probation.' : 'Leave request cannot be raised during probation.'}}</li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.doc_required">Document is
                    mandatory if leave exceeds
                    {{leaveRuleData?.leave_request_criteria?.document_limit}} days.</li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_clubbing_not_allowed_status">
                    This leave type cannot be clubbed with {{leaveClubbedData.join(', ')}}.
                  </li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_taken_birthday">Leave can be
                    taken only on birthday.</li>

                  <li *ngIf="leaveRuleData?.leave_request_criteria?.leave_taken_wedding_anniversary">
                    Leave can be taken only on wedding anniversary.</li>

                </ol>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="mb-0 fw-500">Leave Request Limit</p>
              </div>
              <div class="card-body p-24">
                <ol class="li-reset li-16  fs-14">

                  <li>The maximum number of requests that can be raised in a tenure:
                    {{leaveRuleData?.leave_request_limit?.max_req_apply_tenure_status ?
                    leaveRuleData?.leave_request_limit?.max_req_apply_tenure+' requests.' : 'No limit.'}}</li>

                  <li>The maximum number of requests that can be raised in a year:
                    {{leaveRuleData?.leave_request_limit?.max_req_apply_year_status ?
                    leaveRuleData?.leave_request_limit?.max_req_apply_year+' requests.' : 'No limit.'}}</li>

                  <li>The maximum number of requests that can be raised in a month:
                    {{leaveRuleData?.leave_request_limit?.max_req_apply_month_status ?
                    leaveRuleData?.leave_request_limit?.max_req_apply_month+' requests.' : 'No limit.'}}</li>

                  <li>The maximum number of leaves that can be applied for in a month:
                    {{leaveRuleData?.leave_request_limit?.max_leave_apply_month_status ?
                    leaveRuleData?.leave_request_limit?.max_leave_apply_month+' leaves.' : 'No limit.'}}</li>

                  <li>The minimum number of leaves required per request:
                    {{leaveRuleData?.leave_request_limit?.min_leave_required ?
                    leaveRuleData?.leave_request_limit?.min_leave_days+' leaves.' : 'No limit.'}}
                  </li>

                  <li>The maximum number of leaves required per request:
                    {{leaveRuleData?.leave_request_limit?.max_leave_required ?
                    leaveRuleData?.leave_request_limit?.max_leave_days+' leaves.' : 'No limit.'}}
                  </li>

                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
