import { Component, Input, OnInit ,Output,EventEmitter,ViewChild} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { CompanyService } from 'src/app/@core/services/company.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/@core/services/configuration.service';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-configuration-select-company',
  templateUrl: './configuration-select-company.component.html',
  styleUrls: ['./configuration-select-company.component.scss']
})
export class ConfigurationSelectCompanyComponent implements OnInit {

  counter               = 0;
  status_flag           = true;
  searchlistdata:any    = [];
  searchKeyword         = ""
  statusBtn:any         = "Active";
  loader                = false;
  isEdit                = false;
  Isalert               = true;
  comp_id:any;
  @Input()checkboxes: any[] = [];
  @Input() companies: any;
  @Input() formArray: any;
  @Input() configForm: any;
  @Input() editCompId: any;
  @Output() submitmethod   = new EventEmitter();
  @Output() activeCheckbox = new EventEmitter();
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private configService: ConfigurationService,
    public appService: AppService

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {

      if( !isNaN(params['id'])){
        this.counter = 1;
        this.isEdit = true;

      }
	    else{

        this.searchData();
      }
    })

  }

  
  createCheckBoxArray(){

    for(let i=0;i<this.companies.length;i++)
    {
      this.comp_id    = this.companies[i].id;
      var dict:any    = {};
      dict['id']      = this.comp_id;
      dict['value']   = false;
      this.checkboxes.push(dict);

    }
  }
  isChecked(i:any,Checkvalue:any){
    let controlValue = this.formArray.controls;

    for(let j=0;j<controlValue.length;j++){

      if(controlValue[j].value==Checkvalue){
        for(let j=0;j<this.checkboxes.length;j++){
          if(this.checkboxes[j]['id']==Checkvalue)
            return this.checkboxes[j]['value'];
        }

      }

    }
    return

  }

  onCheckChange(event: any,i:any) {

    for(let j=0;j<this.checkboxes.length;j++){
      if(this.checkboxes[j].id==event.target.value)
      this.checkboxes[j].value = event.target.checked;
    }
    this.activeCheckbox.emit(this.checkboxes);

    if(event.target.checked){

      this.formArray.push(new FormControl(event.target.value));

    }

    else{

      let i: number = 0;
      this.formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {

          this.formArray.removeAt(i);

          return;
        }
        i++;
      });
    }


  }
  submitMethod() {

    this.submitmethod.emit();
  }

  searchData(){
    this.configService.getExcludedCompDropdown(this.status_flag,'','',0,'')
    .subscribe((res: any) => {
      if (res.status == 200) {
        for(let i=0; i< res.body.length; i++){
          res.body[i]['name'] = res.body[i]['company_name']
          res.body[i]['image'] = res.body[i]['logo']
        }
        this.searchlistdata = res.body;
      }
    })
  }
  getCompanyList(){
    this.configService.getExcludedCompList(this.status_flag,'','',0,this.searchKeyword).subscribe((res: any)=>{
      this.companies = res.body;

    })

    this.checkCompany();

  }
  searchresults(data:any){

    this.loader = true;
    if(data == ""){

      this.companies = [];
      this.searchKeyword  = "";

      this.getCompanyList();
        this.loader = false;

    }
      else{

        this.companyService.companyGet(data).subscribe((res: any)=>{

        this.companies = [res.body];
        this.loader = false;
      });

    this.checkCompany();
  }
  }
  searchCompany(searchKeyword:any){
    this.loader     = true;
    this.companies  = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getCompanyList();
    }
  }


  checkCompany() {

    var elements = document.getElementsByName("check");

    this.counter  = 0;

    setTimeout(() => {

      for(let i=0;i<elements.length;i++){
        var element = <HTMLInputElement>elements[i];
        this.formArray.controls.forEach((ctrl: FormControl) => {

          if(element.value==ctrl.value){
           this.counter++;
           element.checked = true


          }

        })
      }
    }, 1000);
    this.loader = false;


  }

}
