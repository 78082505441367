import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpensePolicyService } from 'src/app/@core/services/expense-policy.service';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-expense-policy',
  templateUrl: './expense-policy.component.html',
  styleUrls: ['./expense-policy.component.scss']
})
export class ExpensePolicyComponent implements OnInit {
  viewDetail = false;
  activeTab = 1;
  editDetail = false;
  selectedOption: string = 'amount';
  permissions:any = [];
  detailsView                = false;
  filterpanel                = true;
  exppolicyData :any     = [];
  status                     = true;
  offset                     = 0;
  listlength                 = 0;
  defaultlimit:any           = 20;
  infinityloader             = false;
  loader                     = true;
  nodata                     = false;
  deleteAlert                = false;
  deleteClicked              = false;
  viewLoader                 = false;
  deleteId                   = 0;
  searchKeyword              = "";
  componentDetailsList:any   = [];
  searchlistdata:any         = [] ;
  viewdatas:any              = [];
  catPlaceholder:string      = this.messageService.searchdisplay('Expense Policy');
  statusBtn:any              = 'Active';
  direction                  = '';
  dataId                     : any;
  categorylist               :any=[];
  assignees                  :any=[];
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    public ExpensePolicyService:ExpensePolicyService,
    public messageService : MessageService,
    public router : Router,
    public breakpointObserver: BreakpointObserver,
    public appService:AppService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    setTimeout(()=>{
      this.loader = false;
    }, 3000);
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.getexppolicyData(true);
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.ExpensePolicyService.getpolicyData(this.status,this.defaultlimit,this.offset,this.searchKeyword,'')
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getexppolicyData(active_status:any){
    this.loader           = true;
    this.status           = active_status;

    this.ExpensePolicyService.getpolicyData(active_status,this.defaultlimit,this.offset,this.searchKeyword,'').subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength       = res.results.length;
    if(this.offset ==0){
      this.exppolicyData = [];
    }
    if (res.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata               = false;
      this.componentDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.exppolicyData.push(this.componentDetailsList[i]);
    }
  }

  viewdata(id:any){
    if(this.permissions?.v){
      this.viewDetail = true
      this.viewLoader    = true;
      this.ExpensePolicyService.getSinglepolicyData(id).subscribe((res: any) => {
        this.viewdatas = res;
        let key = res?.applicability?.applicable;
        this.assignees = res?.applicability[key];
        if(key == 'BU'){
          res.applicability['applicable']='Business Unit';
        }
        this.categorylist =[];
        let categories = res.categories;
        for(let k=0;k<categories.length;k++){
          this.ExpensePolicyService.getSinglecategoryData(categories[k]).subscribe((res:any)=>{
            this.categorylist.push(res) ;
          })
          if(k==(categories.length-1)){
            this.viewLoader    = false;
          }
        }
      });
    }
  }

  searchresults(data:any){
    this.loader           = true;
    this.exppolicyData = [];
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchexpense(searchKeyword:any){
    this.loader = true;
    this.isLoading = true;
    this.offset            = 0;
    this.exppolicyData = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  onScrollDown() {
    this.infinityloader     = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
     this.getexppolicyData(this.status);
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  editpolicy(id:number){
    this.router.navigate(['/policy/expense-policy-setup/'+id]);
  }

  addNewdata(){
    this.router.navigate(['/policy/expense-policy-setup']);
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/policy/expense-policy');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
