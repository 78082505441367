import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function maxTenDigitDecimal(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      // const digitdecimalpattern = /^\\d+(\\.\\d{1,3})?$/.test(value);
      const digitdecimalpattern = /^(?=.{1,7}$)(?:[1-9]\d{0,5}|\d{1,6}(\.\d+)?)$/.test(value);


      return !digitdecimalpattern ? {pattern:true}: null;
  }
}

