import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-loader',
  template: `
  <div class="card card-c2 p-0">

    <div class="table-responsive radius-4">
      <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '80px' }"></ngx-skeleton-loader></th>
              <th scope="col"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '80px' }"></ngx-skeleton-loader></th>
              <th scope="col"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '80px' }"></ngx-skeleton-loader></th>
              <th scope="col"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '80px' }"></ngx-skeleton-loader></th>

            </tr>
          </thead>
          <tbody>
            <tr  *ngFor="let dummy of ' '.repeat(5).split('')">
              <td><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '75%' }"></ngx-skeleton-loader></td>
              <td><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '75%' }"></ngx-skeleton-loader></td>
              <td><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '75%' }"></ngx-skeleton-loader></td>
              <td><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '75%' }"></ngx-skeleton-loader></td>

            </tr>
          </tbody>
        </table>
    </div>
  </div>
   `,
  styles: [
  ]
})
export class TableLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
