<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">TRIP ITINERARY</p>
        </div>
        <div class="row row-16">
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body px-24 py-10 d-flex align-items-center">
                        <span class="  ">Flight </span>
                        <span class="ms-auto link-primary1 fs-14 d-flex align-items-center">
                            <i class="icon-plus-square-fill fs-16 me-8 "></i>Add
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body px-24 py-10 d-flex align-items-center">
                        <span class="  "> Hotel</span>
                        <i class="icon-check-circle ms-8 fs-18 text-success"></i>
                        <a class="link-primary align-items-center fs-14 d-flex ms-auto">
                            <i class="icon-pencil-square me-1"></i> Edit
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body d-flex p-0 bg-white  border-bottom align-items-center">
                        <p class="mb-0 flex-shrink-0  py-16 ps-16">Flight
                        </p>
                        <i class="bi  bi-caret-up-fill ms-auto py-16 pe-32"></i>
                    </div>
                    <div class="card-body p-24 border-top-0">
                        <div class="row row-16">
                            <div class="col-12">
                                <label for="" class="form-label  ">Trip Option </label>
                            </div>
                            <div class="col-12 form-row">
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="amount" class="form-check-input" value="amount">
                                    <label for="amount" class="form-check-label">One Way</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="mileage" class="form-check-input" value="mileage">
                                    <label for="mileage" class="form-check-label">Round Trip</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="mileage" class="form-check-input" value="mileage">
                                    <label for="mileage" class="form-check-label">Multi-city</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-16">
                                    <div class="row row-16">
                                        <div class="col-12 d-flex">
                                            <p class="title mb-0">1. FLIGHT </p>
                                            <i class="icon-trash link-danger ms-auto"></i>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Depart From </label>
                                            <ng-select class="form-ngselect" placeholder="Select city"></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Arrive At </label>
                                            <ng-select class="form-ngselect" placeholder="Select city"></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Departure Time </label>
                                            <ng-select class="form-ngselect" placeholder="Select Time  "></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Flight Preferrences </label>
                                            <ng-select class="form-ngselect" placeholder="Select Class "></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label required">Departure Date </label>
                                            <input type="text" name="" class="form-control" placeholder="Enter Date "
                                                id="">
                                        </div>
                                        <div class="col-12 form-row">
                                            <label for="check" class="form-check p-0 d-flex ">
                                                <input type="checkbox" name="check" id="check "
                                                    class="form-check-input ms-0">
                                                <label for="check " class="form-check-label fs-16">Do you want company
                                                    to book ticket on your behalf? </label>
                                            </label>
                                        </div>
                                        <div class=" col-12 form-row">
                                            <label for="" class="form-label required">Description </label>
                                            <textarea type="text" class="form-control " rows="4"
                                                placeholder="Enter description here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card card-c2 p-16">
                                    <div class="row row-16">
                                        <div class="col-12 d-flex">
                                            <p class="title mb-0">2. FLIGHT </p>
                                            <i class="icon-trash link-danger ms-auto"></i>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Depart From </label>
                                            <ng-select class="form-ngselect" placeholder="Select city"></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Arrive At </label>
                                            <ng-select class="form-ngselect" placeholder="Select city"></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Departure Time </label>
                                            <ng-select class="form-ngselect" placeholder="Select Time  "></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  ">Flight Preferrences </label>
                                            <ng-select class="form-ngselect" placeholder="Select Class "></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label required">Departure Date </label>
                                            <input type="text" name="" class="form-control" placeholder="Enter Date "
                                                id="">
                                        </div>
                                        <div class="col-12 form-row">
                                            <label for="check" class="form-check p-0 d-flex ">
                                                <input type="checkbox" name="check" id="check "
                                                    class="form-check-input ms-0">
                                                <label for="check " class="form-check-label fs-16">Do you want company
                                                    to book ticket on your behalf? </label>
                                            </label>
                                        </div>
                                        <div class=" col-12 form-row">
                                            <label for="" class="form-label required">Description </label>
                                            <textarea type="text" class="form-control " rows="4"
                                                placeholder="Enter description here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class=" link-primary1 fs-14 fw-500">
                                    <i class="icon-plus-square-fill me-8"></i>Add Flight
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body d-flex p-0 bg-white  border-bottom align-items-center">
                        <p class="mb-0 flex-shrink-0  py-16 ps-16">Hotel
                        </p>
                        <i class="bi  bi-caret-up-fill ms-auto py-16 pe-32"></i>
                    </div>
                    <div class="card-body p-24 border-top-0">
                        <div class="row row-16">
                            <div class="col-12">
                                <div class="card card-c2 p-16">
                                    <div class="row row-16">
                                        <div class="col-12 d-flex">
                                            <p class="title mb-0">1. HOTEL </p>
                                            <i class="icon-trash link-danger ms-auto"></i>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label required">Check-in Date </label>
                                            <input type="text" name="" class="form-control" placeholder="Select Date "
                                                id="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label required">Check-in Time </label>
                                            <input type="text" name="" class="form-control" placeholder="Select Time "
                                                id="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label required"> Check-out Date </label>
                                            <input type="text" name="" class="form-control" placeholder="Select Date "
                                                id="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label required"> Check-out Time</label>
                                            <input type="text" name="" class="form-control" placeholder="Select Time "
                                                id="">
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label  ">Location </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Location"></ng-select>
                                        </div>
                                        <div class="col-12 form-row">
                                            <label for="check" class="form-check p-0 d-flex ">
                                                <input type="checkbox" name="check" id="check "
                                                    class="form-check-input ms-0">
                                                <label for="check " class="form-check-label fs-16">Do you want company
                                                    to book accomodation on your behalf? </label>
                                            </label>
                                        </div>
                                        <div class=" col-12 form-row">
                                            <label for="" class="form-label required">Description </label>
                                            <textarea type="text" class="form-control " rows="4"
                                                placeholder="Enter description here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class=" link-primary1 fs-14 fw-500">
                                    <i class="icon-plus-square-fill me-8"></i>Add Hotel
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body d-flex p-0 bg-white  border-bottom align-items-center">
                        <p class="mb-0 flex-shrink-0  py-16 ps-16">Car Rental
                        </p>
                        <i class="bi  bi-caret-up-fill ms-auto py-16 pe-32"></i>
                    </div>
                    <div class="card-body p-24 border-top-0">
                        <div class="row row-16">
                            <div class="col-12">
                                <div class="card card-c2 p-16">
                                    <div class="row row-16">
                                        <div class="col-12 d-flex">
                                            <p class="title mb-0">1. Car Rental </p>
                                            <i class="icon-trash link-danger ms-auto"></i>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  required">Pick-up Date </label>
                                            <ng-select class="form-ngselect" placeholder="Select Date "></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  required">Pick-up Time </label>
                                            <ng-select class="form-ngselect" placeholder="Select Time "></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label  required">Pick-up Location </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Location"></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label required">Drop-off Date </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Date"></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label required">Drop-off Time </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Time"></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label  required">Drop-off Location </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Location"></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label  required">Drop-off Location </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Location"></ng-select>
                                        </div>
                                        <div class="col-12 ">
                                            <label for="" class="form-label  ">Car Type </label>
                                            <ng-select class="form-ngselect" placeholder="Select Car Type "></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label  ">Driver needed? </label>
                                        </div>
                                        <div class="col-12 form-row">
                                            <div class="form-check form-check-inline">
                                                <input name="receipt-type" type="radio" id="amount"
                                                    class="form-check-input" value="amount">
                                                <label for="amount" class="form-check-label">Yes </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input name="receipt-type" type="radio" id="mileage"
                                                    class="form-check-input" value="mileage">
                                                <label for="mileage" class="form-check-label">No </label>
                                            </div>
                                        </div>
                                        <div class="col-12 form-row">
                                            <label for="check" class="form-check p-0 d-flex ">
                                                <input type="checkbox" name="check" id="check "
                                                    class="form-check-input ms-0">
                                                <label for="check " class="form-check-label fs-16">Do you want company
                                                    to book car on your behalf? </label>
                                            </label>
                                        </div>
                                        <div class=" col-12 form-row">
                                            <label for="" class="form-label required">Description </label>
                                            <textarea type="text" class="form-control " rows="4"
                                                placeholder="Enter description here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class=" link-primary1 fs-14 fw-500">
                                    <i class="icon-plus-square-fill me-8"></i>Add Car Rental
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body d-flex p-0 bg-white  border-bottom align-items-center">
                        <p class="mb-0 flex-shrink-0  py-16 ps-16">Bus
                        </p>
                        <i class="bi  bi-caret-up-fill ms-auto py-16 pe-32"></i>
                    </div>
                    <div class="card-body p-24 border-top-0">
                        <div class="row row-16">
                            <div class="col-12">
                                <div class="card card-c2 p-16">
                                    <div class="row row-16">
                                        <div class="col-12 d-flex">
                                            <p class="title mb-0">1. Bus </p>
                                            <i class="icon-trash link-danger ms-auto"></i>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  required">Depart From </label>
                                            <ng-select class="form-ngselect" placeholder="Select city "></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  required">Arrive At </label>
                                            <ng-select class="form-ngselect" placeholder="Select city "></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label  required">Departure Date </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Date"></ng-select>
                                        </div>
                                        <div class="col-12 form-row">
                                            <label for="check" class="form-check p-0 d-flex ">
                                                <input type="checkbox" name="check" id="check "
                                                    class="form-check-input ms-0">
                                                <label for="check " class="form-check-label fs-16">Do you want company
                                                    to book ticket on your behalf?</label>
                                            </label>
                                        </div>
                                        <div class=" col-12 form-row">
                                            <label for="" class="form-label required">Description </label>
                                            <textarea type="text" class="form-control " rows="4"
                                                placeholder="Enter description here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class=" link-primary1 fs-14 fw-500">
                                    <i class="icon-plus-square-fill me-8"></i>Add Bus
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2">
                    <div class="card-body d-flex p-0 bg-white  border-bottom align-items-center">
                        <p class="mb-0 flex-shrink-0  py-16 ps-16">Train
                        </p>
                        <i class="bi  bi-caret-up-fill ms-auto py-16 pe-32"></i>
                    </div>
                    <div class="card-body p-24 border-top-0">
                        <div class="row row-16">
                            <div class="col-12">
                                <div class="card card-c2 p-16">
                                    <div class="row row-16">
                                        <div class="col-12 d-flex">
                                            <p class="title mb-0">1. TRAIN </p>
                                            <i class="icon-trash link-danger ms-auto"></i>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  required">Depart From </label>
                                            <ng-select class="form-ngselect" placeholder="Select city "></ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label  required">Arrive At </label>
                                            <ng-select class="form-ngselect" placeholder="Select city "></ng-select>
                                        </div>
                                        <div class="col-12">
                                            <label for="" class="form-label  required">Departure Date </label>
                                            <ng-select class="form-ngselect" placeholder=" Select Date"></ng-select>
                                        </div>
                                        <div class="col-12 form-row">
                                            <label for="check" class="form-check p-0 d-flex ">
                                                <input type="checkbox" name="check" id="check "
                                                    class="form-check-input ms-0">
                                                <label for="check " class="form-check-label fs-16">Do you want company
                                                    to book ticket on your behalf?</label>
                                            </label>
                                        </div>
                                        <div class=" col-12 form-row">
                                            <label for="" class="form-label required">Description </label>
                                            <textarea type="text" class="form-control " rows="4"
                                                placeholder="Enter description here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class=" link-primary1 fs-14 fw-500">
                                    <i class="icon-plus-square-fill me-8"></i>Add Train
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label  ">Add attachments </label>
                <div class="card card-c2 px-16">
                    <div class="card-body px-0 py-16">
                        <label class="upload-btn ">
                            <div class="upload-text">Upload documents <span class="text-primary">here
                                </span>
                                <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC</div>
                            </div>
                            <input type="file" class="form-control" placeholder="" hidden>
                        </label>
                    </div>
                    <div class="card-body px-0 py-16">
                        <div class="row row-16">
                            <div class="col-md-6">
                                <div class="card card-c2 bg-secondary">
                                    <div class="d-flex p-24">
                                        <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                        <span class="text-trim fw-500">Expense Receipt </span>
                                        <span class="ms-auto text-accent1 ">4MB</span>
                                        <span class="link-danger fw-500 flex-shrink-0"><i
                                                class="icon-trash ms-8 "></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card card-c2 bg-secondary">
                                    <div class="d-flex p-24">
                                        <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                        <span class="text-trim fw-500">Expense Receipt </span>
                                        <span class="ms-auto text-accent1 ">4MB</span>
                                        <span class="link-danger fw-500 flex-shrink-0"><i
                                                class="icon-trash ms-8 "></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Remarks </label>
                <textarea type="text" class="form-control " rows="4" placeholder="Enter Remarks  here..."></textarea>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto">NEXT</button>
    </footer>
</form>