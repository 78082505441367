import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OtpService {

  public otpStatus: any = true; 

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(
    private http: HttpClient,
    public router: Router) { }

  verfyOTP(data: any) {
    return this.http.post<any>(`${this.API_PATH}api/v1/verify-otp/`, data, this.httpOption);
  }
}
