import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
@Component({
  selector: 'app-add-expense-policy',
  templateUrl: './add-expense-policy.component.html',
  styleUrls: ['./add-expense-policy.component.scss']
})
export class AddExpensePolicyComponent implements OnInit {
  policyDefineUnit:any =[];
  value:any;
  id:any

  @Input()formGp : FormGroup    = this.fb.group({
    name                        : ['',Validators.required],
    description                 : [''],   
  });
  validated : boolean = false;
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Input()componentError:any;
  @Input()disabled              = false;
  private subjectKeyUp = new Subject<any>();
  @Input()submitted:boolean=false;

  constructor( private fb:FormBuilder,
    public messageService : MessageService,
    public route : ActivatedRoute,
    public existDataService :  ExistDatasService
    ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
  }

  existService(){
      if(!this.editflag){
        this.value ={
          "page": "ExpensePolicy",
          "field":"name",
          "value": this.f.name.value
        }
      }else{
         this.value ={
          "id":this.id,
          "page":"ExpensePolicy",
          "field":"name",
          "value": this.f.name.value
        }
      }

      this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
        this.policyDefineUnit = res.body

        if(this.policyDefineUnit.data ==='invalid'){
          this.f.name.setErrors({
            'exist' : true
          })
        }
      })
    }
  validatepolicysetting() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
