import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ar-attendance-details',
  templateUrl: './ar-attendance-details.component.html',
  styleUrls: ['./ar-attendance-details.component.scss'],
})


export class ArAttendanceDetailsComponent implements OnInit {

  @Input() formGp:any         = []
  @Input() penaltyForm:any         = []

  // unamePattern = "^[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";
  submitted = false;

  validated : boolean = false
  attendanceUnit : any =[]
  value:any
  private subjectKeyUp = new Subject<any>();
  id:any

  @Input()disabled = false;
  @Input()editflag:any;
  @Output() submitmethod = new EventEmitter();

  constructor(@Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,public fb:FormBuilder,public appservice:AppService,public messageService : MessageService, public existDataService :  ExistDatasService, public route: ActivatedRoute, public cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.id = this.route.snapshot.params['id']

    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);

    if(this.formGp.get('end_day').value !== null && this.formGp.get('end_day').value !== undefined){
      if(this.formGp.get('attendance_calculate').value !== null && this.formGp.get('attendance_calculate').value !== undefined){
        let data = this.formGp.get('end_day').value
       this.selectedValue('attendance_calculate',data)
      }

    }else{
      let data = this.ordinal_suffix_of(1)
      this.formGp.get('start_day').setValue(data)
    }

  }

  setDisabled(value: boolean,name:any) {
    if(value) {
     this.formGp.controls[name].enable();
     this.formGp.get(name).setValidators([Validators.required])
    } else {
      this.formGp.controls[name].disable();
      this.formGp.get(name).clearValidators()
    }
    this.formGp.controls[name].updateValueAndValidity();
  }
  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(1);
  }

  disableDate(){
    return false;
  }
  createRange(num:number,start=1){
    var items: number[] = [];
    for(var i = start; i <= num; i++){
      items.push(i);
    }
    return items;
  }
  ordinal_suffix_of(i:number) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}
displaymonth(i:number) {
  if(i<=1)
    return i+" month";
  else
    return i+" months";
}
notanumber(val:any){
  if (typeof +val === "number" && !isNaN(+val)) return null;
  else return true;
}

// None Change
noneChange(e:any){
  if(e.target.value == 'Auto' || e.target.value == 'Single'){
    this.formGp.patchValue({
      keep_time : 'None'
    })
    this.penaltyForm.patchValue({
      late_coming_penalty : false
    })
  }else{
    this.formGp.patchValue({
      keep_time : 'Strict'
    })
  }
}


// Simplified function to use for validation
existService(){
  if(!this.editflag){
    this.value ={
      "page":"attendanceRule",
      "field":"attendance_rule_name",
      "value": this.f.attendance_rule_name.value
    }
  }else{
     this.value ={
      "id":this.id,
      "page":"attendanceRule",
      "field":"attendance_rule_name",
      "value": this.f.attendance_rule_name.value
    }
  }

  this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
    this.attendanceUnit = res.body

    if(this.attendanceUnit.data ==='invalid'){
      this.f.attendance_rule_name.setErrors({
        'exist' : true
      })
    }
  })
}


  // For validation of keyup(name)

  keyUp(){

      this.subjectKeyUp.next(this.f.attendance_rule_name.value)

  }

  selectedValue(val:any,value:any){
    let data = this.formGp.controls[val].value
    let currentMonth = new Date().getMonth()
    if(!data){
      if(value == undefined){
        value = this.formGp.get('end_day').value
      }
      let num = Number(value)+1
      let prefixValue = this.ordinal_suffix_of(num)
      // if(currentMonth == 2){
      //   prefixValue = '1st'
      // }
      this.formGp.patchValue({
        start_day: prefixValue
      });
    }else{
      let prefixValue = this.ordinal_suffix_of(1)
      this.formGp.patchValue({
        start_day: prefixValue
      });

    }

  }

  attendance_cycle(value:any){
    this.formGp.get('end_day').reset()
  }

}
