<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">View Letter </h5>
    <button class="btn-reset text-light-600" routerLink="/my-letters"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <div class="container-fluid p-32 form-container">
      <div class="row mb-24">
      </div>
    </div>
  </main>
</div>