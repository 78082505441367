<form [formGroup]="form">
<div class="modal  modal-alert {{requestCancel == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Cancel Request</h4>
      </div>
      <div class="modal-body py-0">
          <label for="" class="form-label required">Reason</label>
          <textarea class="form-control" name="comment" id="comment" placeholder="Enter reason here..." rows="3" formControlName="comment" [ngClass]="{ 'is-invalid': (f.comment.dirty || f.comment.touched) && (f.comment.errors)}"></textarea>
          <div *ngIf="f.comment.errors" class="invalid-feedback">
            <div *ngIf="f.comment.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
          </div>
      </div>
      <div class="modal-footer">
        <button (click)="close()" type="button"
          class="btn text-uppercase btn-outline-primary btn-sm">Cancel</button>
        <button type="button" class="btn text-uppercase btn-primary btn-sm" [disabled]="!form.valid || saveClicked" (click)="submitRequestCancel()">Submit</button>
      </div>
    </div>
  </div>
</div>
</form>
