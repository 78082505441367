<div class="modal {{captureModal  ? 'show':''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width:47.5rem">
    <div class="modal-content">
      <div class="modal-header px-32">
        <h5 class="modal-title ">Capture Photo</h5>
        <button type="button" (click)="this.close.emit()" class="btn-close shadow-0" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body p-0 overflow-hidden" style="height: 423px;" *ngIf="!openCropper">
        <div class="row row-16">
          <div class="col-12  d-flex  bg-darkgrey">
            <webcam class="mx-auto" [width]="853" [height]="423" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
              *ngIf="openWbcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
              [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
              (initError)="handleInitError($event)"  [mirrorImage]="'Never'"></webcam>

            <img *ngIf="!openCropper && !openWbcam" class="img-fluid mx-auto" [src]="DataUrl" style="height: 423px;">


          </div>
        </div>
      </div>
      <div class="modal-body p-0 overflow-hidden" *ngIf="openCropper==true">
        <app-webcam-cropper [DataURL]="DataUrl" (saveImage)="saveImage($event)" (close)="openCropper=false"></app-webcam-cropper>
      </div>
      <div class="modal-footer py-16 px-32" *ngIf="!openCropper">
        <div class="m-0 w-100">
          <div class="row row-16 w-100">
            <div class="col-4 d-flex">
              <a *ngIf="DataUrl==''" class="link-primary text-uppercase my-auto fs-12 m-0 fw-700"
                (click)="captureInfoModal=true">How to
                capture?</a>
            </div>
            <div class="col-4 d-flex justify-content-center">
              <a *ngIf="DataUrl==''" class="m-0 rounded-circle sq-56 bg-accent1 text-white flex-center fs-26"
                (click)="triggerSnapshot();">
                <!-- <img class="img-fluid" src="/assets/images/temp/camera1.png"> -->
                <i class="icon-camera"></i>
              </a>
              <a *ngIf="DataUrl!=''" class="m-0 rounded-circle sq-56 bg-accent1 text-white flex-center fs-26"
                (click)="this.openCropper = true"><i class="icon-tick-circle"></i></a>
            </div>

            <div class="col-4 d-flex" *ngIf="DataUrl!=''"> <a
                class="link-primary text-uppercase my-auto ms-auto fs-12 m-0 fw-700"
                (click)="DataUrl='';openWbcam=true">RETAKE</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal {{captureInfoModal  ? 'show':''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width:47.5rem">
    <div class="modal-content">
      <div class="modal-header px-32">
        <h5 class="modal-title ">Tutorial</h5>
        <button type="button" (click)="captureInfoModal = false" class="btn-close shadow-0" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body p-32 bg29">
        <div class="row row-24">

          <div class="col-7">
            <p class="mb-24 fw-500 text-uppercase">How to capture the picture</p>

            <ol class="ps-16 mb-0 li-16 fs-12">
              <li>The camera works better when your face is fully visible and how you look like on a typical day.</li>
              <li>Look straight ahead and center your face in the frame.</li>
              <li>Try not to wear masks while taking the picture.</li>
              <li>Make sure you are the only person in view.</li>
            </ol>
          </div>
          <div class="col-5">
            <img class="img-fluid w-100 mx-auto" src="/assets/images/helper/selfie-info.png">
          </div>

        </div>
      </div>
      <div class="modal-footer py-24 px-32">
        <button class="btn btn-primary text-uppercase fs-14 m-0 radius-2" (click)="captureInfoModal = false">Continue to
          capture</button>
      </div>
    </div>
  </div>
</div>