<div class=" config-layout">
  <form [formGroup]="bulkForm"    autocomplete="off">

    <header class=" config-header">
        <h5 class="m-0">Add Bulk Expense </h5>
        <button class="btn-reset text-light-600" routerLink="/expense-receipt"><i
                class="icon-close-lg fs-16"></i></button>
    </header>
    <main class=" config-container">
        <div class="container-fluid p-32 form-container">
            <div class="row row-16 ">
                <div class="col-12">
                    <p class="text-uppercase fw-500 mb-0">Expense recEIpts</p>
                </div>
                <div class="col-12">
                  <label for="" class="form-label required ">Expense type</label>
                </div>
                <div class="col-12 form-row">

                  <div class="form-check form-check-inline">
                    <input name="receipt-type" type="radio" id="amount" class="form-check-input"
                    [value]="false" formControlName="expense_type" (ngModelChange)="changeExpenseType()" name="expense_type">
                    <label for="amount" class="form-check-label">Amount</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input name="receipt-type" type="radio" id="mileage" class="form-check-input"
                      [value]="true" formControlName="expense_type" (ngModelChange)="changeExpenseType()"  name="expense_type">
                      <label for="mileage" class="form-check-label">Mileage</label>
                  </div>
                </div>
                <div class="col-12">
                  <label for="" class="form-label  ">Report</label>
                  <ng-select  class="form-ngselect" formControlName="report" name="report"  id="report" placeholder="{{this.messageService.selectplaceholddisplay('report')}}" appendTo="body" (ngModelChange)="validateExpenseDateReport()">
                    <ng-option *ngFor="let li of reportDD" [value]="li.id">{{li.name}}</ng-option>
                  </ng-select>

                </div>
                <!-- <ng-container *ngIf="f.expense_type.value==false"> -->
                    <div class="col-12">
                        <div class="card card-c2">
                            <div class="table-responsive radius-4">
                                <table
                                    class="table  vertical-align-top  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                                    <thead>
                                        <tr>
                                            <th class="fw-600 text-uppercase" scope="col">No.</th>
                                            <th class="fw-600 text-uppercase" scope="col">EXPENSE DATE</th>
                                            <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>

                                            <th class="fw-600 text-uppercase" scope="col" *ngIf="f.expense_type.value==false">LOCATION</th>
                                            <ng-container *ngIf="f.expense_type.value==true">
                                              <th class="fw-600 text-uppercase" scope="col">FROM LOCATION</th>
                                              <th class="fw-600 text-uppercase" scope="col">TO LOCATION</th>
                                            </ng-container>

                                            <th class="fw-600 text-uppercase" scope="col"  *ngIf="f.expense_type.value==true">DISTANCE</th>
                                            <!-- <th class="fw-600 text-uppercase" scope="col" *ngIf="f.expense_type.value==false">CURRENCY</th> -->
                                            <th class="fw-600 text-uppercase" scope="col">Amount</th>
                                            <th class="fw-600 text-uppercase" scope="col">CLAIM REIMBURSEMENT</th>
                                            <th class="fw-600 text-uppercase" scope="col">DESCRIPTION</th>
                                            <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                                            <th class="fw-600 text-uppercase" scope="col">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody class="cell-16 " formArrayName="receipts">
                                      <tr *ngIf="receiptsarray().controls.length<=0">
                                        <td colspan="11">
                                            <div class="card card-c2 flex-center p-16 bg-dark-500">
                                                <span class="fw-500 fs-14">No data found</span>
                                              </div>
                                        </td>
                                     </tr>
                                        <tr *ngFor="let item of $any(receiptsarray().controls|filter:searchString| slice: (page-1) * pageSize : page * pageSize); let i = index" [formGroupName]="i" id="row{{i+1}}">
                                            <td class="form-cell"><div class="mt-12">{{i+1}}</div></td>

                                            <td class="form-cell">
                                              <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (receiptsarray().controls[i].get('expense_date')?.touched) && receiptsarray().controls[i].get('expense_date')?.errors || receiptsarray().controls[i].get('expense_date')?.errors?.exceed}">
                                                <input (click)="datePicker.open()" class="form-control" placeholder="{{this.messageService.selectplaceholddisplay('Expense Date')}}" matInput formControlName="expense_date"  [matDatepicker]="datePicker" [ngClass]="{ 'is-invalid': (receiptsarray().controls[i].get('expense_date')?.touched) &&receiptsarray().controls[i].get('expense_date')?.errors || receiptsarray().controls[i].get('expense_date')?.errors?.exceed}" (ngModelChange)="validateExpenseDate(i)"  [min]="minDate">
                                                <i class="icon-calendar pointer" (click)="datePicker.open()"></i>
                                                <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
                                              </div>
                                              <div *ngIf="(receiptsarray().controls[i].get('expense_date')?.touched) && receiptsarray().controls[i].get('expense_date')?.errors?.required || receiptsarray().controls[i].get('expense_date')?.errors?.exceed" class="invalid-feedback">
                                                <div *ngIf="(receiptsarray().controls[i].get('expense_date')?.touched) && receiptsarray().controls[i].get('expense_date')?.errors?.required">
                                                  {{this.messageService.validationDisplay('required')}}
                                                </div>
                                                <div *ngIf="receiptsarray().controls[i].get('expense_date')?.hasError('exceed')">
                                                  {{this.messageService.reportRangeValidation()}}
                                                </div>
                                            </div>
                                            </td>
                                            <td class="form-cell">
                                              <ng-select  class="form-ngselect" formControlName="category" name="category"  id="category" placeholder="{{this.messageService.selectplaceholddisplay('category')}}" [ngClass]="{ 'is-invalid': (receiptsarray().controls[i].get('category')?.touched) && receiptsarray().controls[i].get('category')?.errors || receiptsarray().controls[i].get('category')?.errors?.exceed }" (change)="changeCategory(i)" appendTo="body">
                                                <ng-option *ngFor="let li of categoryDD" [value]="li.id">{{li.name}}</ng-option>
                                              </ng-select>
                                              <div *ngIf="(receiptsarray().controls[i].get('category')?.touched) && receiptsarray().controls[i].get('category')?.errors || receiptsarray().controls[i].get('category')?.errors?.exceed" class="invalid-feedback">
                                                <div *ngIf="receiptsarray().controls[i].get('category')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                <div *ngIf="receiptsarray().controls[i].get('category')?.hasError('exceed')">{{this.messageService.availableFrequency(singleCategory?.claim_limit_period)}}</div>
                                              </div>
                                            </td>
                                            <td class="form-cell">
                                              <ng-select  class="form-ngselect" formControlName="from_location" name="from_location"  id="from_location" placeholder="{{this.messageService.selectplaceholddisplay('Location')}}" [ngClass]="{ 'is-invalid': (receiptsarray().controls[i].get('from_location')?.touched) && receiptsarray().controls[i].get('from_location')?.errors }" appendTo="body" (change)="changevaluebulk(i);locationsame(i)">
                                                <ng-option *ngFor="let li of locationDD" [value]="li.id">{{li.name}}</ng-option>
                                              </ng-select>
                                              <div *ngIf="(receiptsarray().controls[i].get('from_location')?.touched) && receiptsarray().controls[i].get('from_location')?.errors" class="invalid-feedback">
                                                <div *ngIf="receiptsarray().controls[i].get('from_location')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                              </div>
                                            </td>
                                            <td *ngIf="f.expense_type.value==true" class="form-cell">
                                              <ng-select  class="form-ngselect" formControlName="to_location" name="to_location"  id="to_location" placeholder="{{this.messageService.selectplaceholddisplay('Location')}}" [ngClass]="{ 'is-invalid': (receiptsarray().controls[i].get('to_location')?.touched) && receiptsarray().controls[i].get('to_location')?.errors }" appendTo="body" (change)="locationsame(i)">
                                                <ng-option *ngFor="let li of locationDD" [value]="li.id">{{li.name}}</ng-option>
                                              </ng-select>
                                              <div *ngIf="(receiptsarray().controls[i].get('to_location')?.touched) && receiptsarray().controls[i].get('to_location')?.errors" class="invalid-feedback">
                                                <div *ngIf="receiptsarray().controls[i].get('to_location')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                <div *ngIf="receiptsarray().controls[i].get('to_location')?.errors?.same">{{this.messageService.sameasvalidation('From location','To location')}}</div>
                                              </div>
                                            </td>


                                            <ng-container *ngIf="f.expense_type.value==true">

                                              <td class="form-cell">
                                                <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('distance')}}" id="" formControlName="distance" [ngClass]="{ 'is-invalid': ( receiptsarray().controls[i].get('distance')?.dirty || receiptsarray().controls[i].get('distance')?.touched)  &&  receiptsarray().controls[i].get('distance')?.errors}" (keyup)="calculateAmount(i)">
                                                <div *ngIf="(receiptsarray().controls[i].get('distance')?.dirty || receiptsarray().controls[i].get('distance')?.touched) && receiptsarray().controls[i].get('distance')?.errors" class="invalid-feedback">
                                                  <div *ngIf="receiptsarray().controls[i].get('distance')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                  <div *ngIf="receiptsarray().controls[i].get('distance')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                  <div *ngIf="!(receiptsarray().controls[i].get('distance')?.errors?.pattern) && receiptsarray().controls[i].get('distance')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                                                  <div *ngIf="!(receiptsarray().controls[i].get('distance')?.errors?.pattern) && receiptsarray().controls[i].get('distance')?.errors?.min">{{this.messageService.fieldlengthvalidation('minvalue',1)}}</div>
                                                  <div *ngIf="receiptsarray().controls[i].get('distance')?.hasError('exceed')">{{this.messageService.availableLimitdistance(singleCategory?.limit_period)}}</div>
                                                </div>
                                              </td>
                                              <td style=" background-color: #0078ce1a; ">
                                                 <div class="mt-12">
                                                  {{receiptsarray().controls[i].get('amount')?.value | currency : currency}}
                                                 </div>
                                              </td>
                                            </ng-container>

                                            <td  *ngIf="f.expense_type.value==false" class="form-cell">
                                              <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('amount')}}" id="" formControlName="amount" [ngClass]="{ 'is-invalid': ( receiptsarray().controls[i].get('amount')?.dirty || receiptsarray().controls[i].get('amount')?.touched)  &&  receiptsarray().controls[i].get('amount')?.errors}" (keyup)="validateAttachment(i)">
                                                <div *ngIf="( receiptsarray().controls[i].get('amount')?.touched || receiptsarray().controls[i].get('amount')?.dirty) && receiptsarray().controls[i].get('amount')?.errors" class="invalid-feedback">
                                                  <div *ngIf="receiptsarray().controls[i].get('amount')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                  <div *ngIf="receiptsarray().controls[i].get('amount')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                  <div *ngIf="!(receiptsarray().controls[i].get('amount')?.errors?.pattern) && receiptsarray().controls[i].get('amount')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
                                                  <!-- <div *ngIf="!(receiptsarray().controls[i].get('amount')?.errors?.pattern) && receiptsarray().controls[i].get('amount')?.errors?.max">{{this.messageService.fieldlengthvalidation('amount',singleCategory['limit'])}}</div> -->
                                                  <div *ngIf="!(receiptsarray().controls[i].get('amount')?.errors?.pattern) && receiptsarray().controls[i].get('amount')?.errors?.min">{{this.messageService.fieldlengthvalidation('minvalue',1)}}</div>
                                                  <!-- <div *ngIf="receiptsarray().controls[i].get('amount')?.hasError('exceed')">{{this.messageService.availableLimit('amount')}}</div> -->
                                                  <div *ngIf="!(receiptsarray().controls[i].get('amount')?.errors?.pattern) && (receiptsarray().controls[i].get('amount')?.errors?.max || receiptsarray().controls[i].get('amount')?.hasError('exceed')) && singleCategory?.amount_limit_type =='location_dependent_limit'">{{this.messageService.LimitExceededlocation()}}</div>
                                                  <div *ngIf="!(receiptsarray().controls[i].get('amount')?.errors?.pattern) && (receiptsarray().controls[i].get('amount')?.errors?.max || receiptsarray().controls[i].get('amount')?.hasError('exceed')) && singleCategory?.amount_limit_type =='limit_for_all_location'">{{this.messageService.LimitExceeded()}}</div>

                                                </div>
                                            </td>
                                            <td class="checkbox-24">
                                              <div class="mt-12">
                                                <input type="checkbox" name="check" id="check " class="form-check-input ms-0" formControlName="is_reimbursement">

                                              </div>
                                            </td>
                                            <td class="form-cell">

                                              <input type="text" name="" class="form-control" placeholder="{{this.messageService.descriptiontext('desc')}}" id="" formControlName="desicription" [ngClass]="{ 'is-invalid': ( receiptsarray().controls[i].get('desicription')?.dirty || receiptsarray().controls[i].get('desicription')?.touched)  &&  receiptsarray().controls[i].get('desicription')?.errors}" >
                                                <div *ngIf="(receiptsarray().controls[i].get('desicription')?.dirty || receiptsarray().controls[i].get('desicription')?.touched) && receiptsarray().controls[i].get('desicription')?.errors" class="invalid-feedback">
                                                  <div *ngIf="receiptsarray().controls[i].get('desicription')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                  <div *ngIf="receiptsarray().controls[i].get('desicription')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>

                                                </div>
                                            </td>
                                            <ng-container *ngIf="receiptsarray().controls[i].get('attachments')?.value.length;else attachelse">

                                              <td class="form-cell"><i class="icon-eye fs-18 link-primary " (click)="viewAttachment(i)"></i></td>
                                            </ng-container>
                                            <ng-template #attachelse>
                                              <td class="form-cell"><div class="link-primary mt-12" (click)="addAttachmentFunction(i)"> Add </div></td>
                                            </ng-template>
                                            <td class="form-cell">
                                              <div class="mt-12">
                                                <i class="icon-trash fs-18 link-danger " (click)="removeReceipt(i)"></i>
                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="!disableadd">
                        <span class=" link-primary1 fs-14 fw-500" (click)="addMoreReceipt()">
                            <i class="icon-plus-square-fill me-8"></i>Add More Expenses
                        </span>
                    </div>
                    <div class="col-12" *ngIf="filteredcount()>pageSize">
                      <ngb-pagination class="d-flex justify-content-end"  [pageSize]="pageSize"  [collectionSize]="filteredcount()" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                <!-- </ng-container> -->

            </div>
        </div>
        <footer class="submit-footer gap-16 justify-content-end">
            <button class="btn btn-primary btn-sm text-uppercase"  [disabled]=" bulkForm.invalid || receiptsarray().controls.length==0" (click)="saveBulkReceipt()">SAVE </button>
        </footer>
    </main>
  </form>
</div>

<app-upload-multiple-attachments *ngIf="uploadeAttachments" [from]="'profile'" [uploadeAttachments]="uploadeAttachments" [fileType]="'.pdf,.doc'" [receiptId]="0" (closeAttachment)="closeAttachment()" (saveAttachment)="saveAttachment($event)"></app-upload-multiple-attachments>
<app-view-multiple-attachments *ngIf="viewAttchments" [from]="'profile'" [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="0" (closeViewAttachment)="closeViewAttachment()" (saveViewAttachment)="saveViewAttachment($event)"></app-view-multiple-attachments>

