import { Component, OnInit,ViewChild} from '@angular/core';
import { FormBuilder,Validators  } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { DocumentManagementService } from 'src/app/@core/services/document-management.service';
import { Subject } from 'rxjs'
import { debounceTime} from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';


@Component({
  selector: 'app-documents-category',
  templateUrl: './documents-category.component.html',
  styleUrls: ['./documents-category.component.scss']
})
export class DocumentsCategoryComponent implements OnInit {

  viewDetail                  = false;
  editDetail                  = false;
  activeTab                   = 1;
  permissions:any             = [];
  offset                      = 0;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  categoryDetailsList:any     = [];
  infinityloader              = false;
  loader                      = false;
  categoryList:any            = [];
  nodatafound                 = false;
  status                      = true;
  searchKeyword               = ""
  searchlistdata:any          = [] ;
  editflag :boolean           = false
  dataId :any                 = "";
  categoryUnit : any          = []
  statusBtn:any               = "Active";
  loader1                     = false;
  isCategoryActive            = false;
  deleteToggle                = false;
  deleteClicked               = false;
  submitted                   = false;
  categoryPlaceholder:any     = this.messageService.searchdisplay('category');
  deleteId:any
  singleCategory:any
  categoryLabel:any;
  routeComponent : any
  value:any
  categoryForm                = this.formBuilder.group({
                                name: ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                              })
  @ViewChild(SearchbarComponent) child:any;
  sLoading                    = false;

  private subjectKeyUp = new Subject<any>();

  constructor(
    private formBuilder: FormBuilder,
    public appService: AppService,
    public messageService : MessageService,
    public docService:DocumentManagementService,
    private route: ActivatedRoute,
    public existDataService :  ExistDatasService,
    public breakpointObserver: BreakpointObserver,


  ) { }
  ngOnInit(): void {
  this.route.url.subscribe((res:any)=>{
    this.routeComponent = res[0].path
  })
  this.breakpointObserver.observe(['(min-width: 1024px)']).subscribe((state: BreakpointState) => {
    if (state.matches) {
      this.defaultlimit = 40;
    }
  });
  this.getPermission();
  this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
    this.existService();
  })
  this.getCategoryList();

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getCategoryList(){
    this.loader = true;

    this.docService.CategoryList(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {

      this.listlength = res.results.length;
      if(this.offset ==0){
        this.categoryList = [];
      }

      if (res.results.length == 0) {
        this.nodatafound = this.offset == 0 ? true :false;
      }else {
        this.nodatafound = false;
        this.categoryDetailsList = res.results;
      }

      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
      this.sLoading = false;
    })
    // this.docService.categoryListData(this.status).subscribe((res: any) => {
    //   this.searchlistdata = res;

    // });
  }
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.categoryList.push(this.categoryDetailsList[i]);
    }

  }
  searchCategory(searchKeyword:any){
    this.sLoading           = true;
    this.loader             = true;
    this.offset             = 0;
    this.categoryList       = [];
    this.searchKeyword      = searchKeyword;
    if(searchKeyword != ""){
      this.getCategoryList();
    }
  }
  get f() { return this.categoryForm.controls; }
  statusfilter(status:any){
    this.loader       = true;
    this.status       = status;
    this.categoryList = [];
    this.offset            = 0;
    this.child.clearBar();
  }
  existService(){
    if(!this.editflag){
      this.value ={
        "page":'DocumentRepositoryCategory',
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.dataId,
        "page":'DocumentRepositoryCategory',
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.categoryUnit = res.body

      this.loader = false

      if(this.categoryUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }
  onScrollDown() {
    this.infinityloader     = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.categoryList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  saveCategory(){
    this.submitted    = true;

    if(this.categoryForm.invalid)
    return
    else{

      this.docService.categorySaveorUpdate(this.dataId,this.categoryForm.value).subscribe((rese:any)=>{
        this.getCategoryList();
        this.editDetail   = false;
        this.submitted    = false;
      })

    }
  }
  addCategory(){
    this.loader1       = false
    this.editDetail    = false
    this.categoryLabel = 'Add';
    this.editDetail    = true
    this.dataId        = ''
    this.categoryForm.reset();


  }
  editCategory(id:any){
    this.loader1       = true
    this.categoryLabel = 'Edit';
    this.editDetail    = true
    this.dataId        = id
    this.viewDetail    = false
    this.docService.getSingleCatgory(id).subscribe((res:any)=>{
      this.categoryForm.get('name')?.setValue(res.name)
      this.loader1       = false

    })


  }
  deleteCategory(id:any){
    this.deleteId       = id;
    this.deleteToggle   = true;
    this.deleteClicked  = false;


  }
  deleteCategoryConfirm(){
    this.deleteClicked = true;
    this.docService.categoryDelete(this.deleteId).subscribe((res:any)=>{
      this.deleteToggle = false;
      this.getCategoryList();
    },
    (err: any) => {
      this.deleteToggle = false;

    })
  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.categoryList = [];
      this.getCategoryList();
    }else{
        this.docService.getSingleCatgory(data).subscribe((res: any) => {
          this.searchKeyword  = res.name
          this.categoryList   = [res];
          this.loader         = false;
        });
    }
  }
  viewcategory(id:any){
    this.viewDetail = true
    this.loader1    = true;

    this.docService.getSingleCatgory(id).subscribe((res:any)=>{

      this.singleCategory = res;
      this.loader1        = false;

    })
  }
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
