import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatutoryService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };
  // ********************************************* StatuatoryConfiguration ***************************************
  getStatuatoryConfiguration( year: number,company: number) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/statuatory-configuration-list/?year=${year}&company=${company}`, this.httpOption);
  }

  lockStatuatoryConfiguration( id: number, data: any) {
    return this.http.put(`${this.API_PATH}general_settings/api/v1/statuatory-configuration-detail/${id}/`, data , this.httpOption);
  }
  // ********************************************* esi SETTINGS ***************************************

  createEmpStateInsuranceSettings( id: number, data: any) {
    if(id == 0) {
      return this.http.post(`${this.API_PATH}general_settings/api/v1/esi-list/`, data, this.httpOption);
    } else {
      return this.http.put(`${this.API_PATH}general_settings/api/v1/esi-detail/${id}/`, data, this.httpOption);
    }
  }

  getEmpStateInsuranceSettings( id: number,) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/esi-list/?statuatory=${id}`, this.httpOption);
  }

  // ********************************************* LWF SETTINGS ***************************************

  createLabourWelfareFund( id: number, data: any) {
    if(id == 0) {
      return this.http.post(`${this.API_PATH}general_settings/api/v1/labour-welfare-list/`, data, this.httpOption);
    } else {
      return this.http.put(`${this.API_PATH}general_settings/api/v1/labour-welfare-detail/${id}/`, data, this.httpOption);
    }
  }
  getLabourWelfareFund( id: number,) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/labour-welfare-list/?statuatory=${id}`, this.httpOption);
  }


  // ********************************************* TAX SETTINGS ***************************************
  createTaxSettings( id: number, data: any) {
    if(id == 0) {
      return this.http.post(`${this.API_PATH}general_settings/api/v1/tax-settings-list/`, data, this.httpOption);
    } else {
      return this.http.put(`${this.API_PATH}general_settings/api/v1/tax-settings-detail/${id}/`, data, this.httpOption);
    }
  }

  getTaxSettings( id: number,) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/tax-settings-list/?statuatory=${id}`, this.httpOption);
  }

  // *********************************************  GRATUITY   ***************************************
  createGratuitySetting( id: number, data: any) {
    if(id == 0) {
      return this.http.post(`${this.API_PATH}general_settings/api/v1/gratuity-setting-list/`, data, this.httpOption);
    } else {
      return this.http.put(`${this.API_PATH}general_settings/api/v1/gratuity-setting-detail/${id}/`, data, this.httpOption);
    }
  }

  getGratuitySetting( id: number,) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/gratuity-setting-list/?statuatory=${id}`, this.httpOption);
  }

  // *********************************************  PROFESSIONAL TAX SETTINGS ***************************************
  createProfessionalTaxSettings( id: number, data: any) {
    if(id == 0) {
      return this.http.post(`${this.API_PATH}general_settings/api/v1/professional-tax-list/`, data, this.httpOption);
    } else {
      return this.http.put(`${this.API_PATH}general_settings/api/v1/professional-tax-detail/${id}/`, data, this.httpOption);
    }
  }

  getProfessionalSettings( id: number,) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/professional-tax-list/?statuatory=${id}`, this.httpOption);
  }

  // *********************************************  PROFESSIONAL TAX SETTINGS ***************************************
  createProvidentFundSetting( id: number, data: any) {
    if(id == 0) {
      return this.http.post(`${this.API_PATH}general_settings/api/v1/pf-settings-list/`, data, this.httpOption);
    } else {
      return this.http.put(`${this.API_PATH}general_settings/api/v1/pf-settings-detail/${id}/`, data, this.httpOption);
    }
  }

  getProvidentFundSetting( id: number,) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/pf-settings-list/?statuatory=${id}`, this.httpOption);
  }
  getStatuatoryCompanyList(id:any = '',company_id: any = '',year: number,offset: number, limit: number,status: any ='',search:any=''){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/statuatory-list/?id=${id}&company__id=${company_id}&year=${year}&limit=${limit}&offset=${offset}&company__is_active=${status}&search=${search}`, this.httpOption);
  }
  getGeneralDropdownList(tags: any,year: number){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/general-dropdown/?&year=${year}&tags=${tags}`, this.httpOption);
  }
  getDefaultStatutoryData(tags: any,year: number){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/statuitory-defaults/?name=&tags=${tags}&year=${year}`, this.httpOption);
  }


}
