import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {  AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-daily-attendance',
  templateUrl: './daily-attendance.component.html',
  styleUrls: ['./daily-attendance.component.scss']
})
export class DailyAttendanceComponent implements OnInit {

  constructor(public attendanceReportService:AttendanceReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService, public cd : ChangeDetectorRef) { }

    @Input() companyDD: any;
    @Input() gradeDD: any;
    @Input() BranchDD: any;
    @Input() buDD: any;
    @Input() DepDD: any;
    @Input() DesignationDD: any;
    @Input() checkListElements: any;
    @Input() disabled: any;
    @Input() empstatusDD: any;
    @Input() emptypeDD: any;
    @Input() employeeDropdown: any;
    @Input() callemp : any;
    // Above needs to be cleared
    @Input() dailyAttendance : any;
    @Input() salFilter: any;
    @Input() viewDetail: any;
    @Input() closeControl: any;
    @Input() empstatArr: any;
    @Input() tempArr: any;
    @Input() submitted: any;
    @Input() attendanceStatus: any;
    @Input() lateEarlyArr: any;
    @Input() pageNumber : any
    @Input() lastPage :any
    @Input() ordering :any
    @Input() ouFilterForm: any
    @Input() resDataFilter:any;
    minDate = new Date(2000, 0, 1);
  maxDate = new Date();

    get f()  { return this.dailyAttendance.controls; }

    @Output() filterPanel                   = new EventEmitter();
    @Output() callMonthlyReport             = new EventEmitter();
    @Output() submitDone                    = new EventEmitter();
    @Output() resetFilter                   = new EventEmitter();

    @ViewChild('scrollElement') scrollElement!: ElementRef;


  ngOnInit(): void {
  }

  // Emiting the value of radio to parent
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthtype:any){
    if(e.target.value == 'on' ){
     this.dailyAttendance?.get(contrlName)?.setValue(true);
    }
    else{
     this.dailyAttendance?.get(contrlName)?.setValue(val);
     this.dailyAttendance?.get(monthtype)?.setValue(null);
    }
    if(val == true) {
      this.dailyAttendance.get(monthtype)?.setValidators([Validators.required]);
      this.dailyAttendance.get(monthtype)?.updateValueAndValidity();
    } else {
      this.dailyAttendance.get(monthtype)?.clearValidators()
      this.dailyAttendance.get(monthtype)?.updateValueAndValidity();
    }
  }

  filterFormData(use:any){
    this.dailyAttendance.controls.attendance_date.value == true ? this.dailyAttendance.get('attendance_date')?.setValue(this.appservice.dateFormatConvert(this.dailyAttendance.controls.specific_month.value)) : this.dailyAttendance.get('attendance_date')?.setValue(this.dailyAttendance.controls.attendance_date.value);

    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let attendance_date    = this.dailyAttendance.controls.attendance_date.value==null?'':this.dailyAttendance.controls.attendance_date.value;
    let attendance_status    = this.dailyAttendance.controls.attendance_status.value==''?JSON.stringify([]):JSON.stringify(this.dailyAttendance.controls.attendance_status.value);
    let late_or_early    = this.dailyAttendance.controls.late_or_early.value==''?JSON.stringify([]):JSON.stringify(this.dailyAttendance.controls.late_or_early.value);
    if(use == 'filter'){
      return `attendance_date=${attendance_date}&attendance_status=${attendance_status}&late_or_early=${late_or_early}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'attendance_date':this.dailyAttendance.controls.attendance_date.value,'attendance_status':this.dailyAttendance.controls.attendance_status.value,'late_or_early':this.dailyAttendance.controls.late_or_early.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

  applyBtn : boolean = false

  submitdailyAttendance(){
    this.submitted      = true;
    let offset = 0
    if (this.dailyAttendance.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let limit = 20
    offset = this.applyBtn == true ? 0 :this.attendanceReportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail           = false;
    this.applyBtn = false
  }


  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
      let allSelect = (items: any[]) => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
  }

  clearcommonForm(item:any,index:any){
   if(item == "attendStat"){
      let daata = this.dailyAttendance.value.attendance_status;
      daata.splice(index, 1);
      this.dailyAttendance.patchValue({
        attendance_status: daata
      });
    }else if(item == "lateStatus"){
      let daata = this.dailyAttendance.value.late_or_early;
      daata.splice(index, 1);
      this.dailyAttendance.patchValue({
        late_or_early: daata
      });
    }
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.dailyAttendance.get('attendance_date')?.reset('today');
      this.dailyAttendance.get('specific_month')?.reset(null);
      this.dailyAttendance.get('attendance_status')?.reset('');
      this.dailyAttendance.get('late_or_early')?.reset('');
    }
    this.callMonthlyReport.emit({
      reportName : 'Daily Attendance'
    })
  }

  // Tags closing
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }
    if(this.closeControl == 'late_or_early' || this.closeControl == 'attendance_status'){
      this.dailyAttendance.get(this.closeControl)?.setValue('')
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('');
    }
    this.submitdailyAttendance();
  }


  resetPage(){
    this.applyBtn = true
    this.attendanceReportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'attendance_date' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
