import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {
  profile_data: EventEmitter<any> = new EventEmitter();
  userdata:any = [];
  constructor(private http: HttpClient,public router: Router,private websocket: WebSocket,private authService: AuthService,public appservice:AppService) { }

  API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public requestSocket(){

    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}profile/${this.appservice.getSubDomain()}_employee_profile_${this.authService.isUserId}/`);

    this.websocket.onmessage = (event:any) => {
      let data = JSON.parse(event.data);
      this.profile_data.emit(data);
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if(event.url !== '/profile/snapshot'){
          this.closeSocket();
        }
      }
    });
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
    }
  }

}
