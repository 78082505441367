import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssetRequestService } from 'src/app/@core/services/asset-request.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-asset-request',
  templateUrl: './asset-request.component.html',
  styleUrls: ['./asset-request.component.scss']
})
export class AssetRequestComponent implements OnInit {

  viewAsset = false;

  @Input()assetRequest:any;
  @Input()from:any;
  @Input()empId:any;
  @Input()requestFor:any;

  categoryListDropDown:any    = [];
  subCategoryListDropDown:any = [];
  categoryLoader              = false;
  subCategoryLoader           = false;

  saveClicked                 = false;

  employeeAssetList:any       = [];
  employeeAssetListLoader     = false;

  page                        = 1;
  pageSize                    = 20;
  searchString: any           = "";

  @Output()requestSave    = new EventEmitter();
  @Output()requestClose   = new EventEmitter();

  constructor(
    private assetRequestService: AssetRequestService,
    public fb:FormBuilder,
    public messageService : MessageService,
    public router: Router,
    public appservice:AppService,
    public activityService:ManageActivityService
  ) { }

  formGp : FormGroup = this.fb.group({
    asset_type  : 'New',
    assetArray  : this.fb.array([])
  });

  ngOnInit(): void {
    this.addAssetArray();
    this.getAssetCategory();
    this.getEmployeeAssetList();
  }

  getAssetCategory(){
    this.categoryLoader       = true;
    this.categoryListDropDown = [];
    if(this.from=='behalf'){
      this.activityService.assetCategoryDropDown(true,this.empId).subscribe((res: any) => {
        this.categoryListDropDown = res;
        this.categoryLoader       = false;
      });
    }
    else{

      this.assetRequestService.assetCategoryDropDown(true).subscribe((res: any) => {
        this.categoryListDropDown = res;
        this.categoryLoader       = false;
      });
    }
  }

  getEmployeeAssetList(){
    this.employeeAssetListLoader = true;
    this.employeeAssetList       = [];
    if(this.from=='behalf'){
      this.activityService.employeeAssetList(this.empId).subscribe((res: any) => {
        this.employeeAssetList       = res;
        this.employeeAssetListLoader = false;
      });
    }
    else{

      this.assetRequestService.employeeAssetList().subscribe((res: any) => {
        this.employeeAssetList       = res;
        this.employeeAssetListLoader = false;
      });
    }
  }



  assetArrays() : FormArray {

    return this.formGp.get("assetArray") as FormArray
  }

  newAssetArray(): FormGroup {

    return this.fb.group({
      "asset_category"     : [null, [Validators.required]],
      "asset_sub_category" : [null, [Validators.required]],
      "asset_reason"       : [null, [Validators.required,Validators.maxLength(500)]],
      "asset"              : '',

    })
  }

  addAssetArray() {
    this.assetArrays().push(this.newAssetArray());
    this.assetArrayCreation();
  }

  removeAssetArrays(i:number) {
    this.assetArrays().removeAt(i);
    if (i !== -1) {
      this.subCategoryListDropDown.splice(i, 1);
    }
  }

  removeAllAssetArrays(){
    if(this.formGp.value.assetArray?.length > 0){
      while(this.formGp.value.assetArray?.length !== 0){
        this.assetArrays().removeAt(0);
      }
    }
  }

  assetTypeChange(){
    this.removeAllAssetArrays();
      if(this.formGp.value.asset_type == 'New' || this.formGp.value.asset_type == 'Both')
        this.addAssetArray();
      this.subCategoryListDropDown = [];
      this.unCheck();
  }

  unCheck(){
    if(this.employeeAssetList?.length){
      this.employeeAssetList?.forEach((item:any, key:any) => {
        item.checked = false;
      })
    }
  }

  assetArrayCreation(){
    this.subCategoryListDropDown[Number(this.formGp.value.assetArray?.length) - 1]=Array();
  }

  getAssetSubCategory(data:any,index:any){
    (<FormArray>this.formGp.get("assetArray")).at(index).get('asset_sub_category')?.setValue(null);
    if (typeof data === 'number'){
    this.assetRequestService.assetSubCategoryDropDown(true,data).subscribe((res: any) => {
      if(res?.length > 0) {
          this.subCategoryListDropDown[index] = (res);
      }else{
          this.subCategoryListDropDown[index] = [];
      }
    });
  }
  }

  checkall(event:any){
    var newList  = this.employeeAssetList;
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkAllSelected(){
    var newList  = this.employeeAssetList
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
          return item.checked;
      });
    }else{
      return false;
    }
  }

  requestSubmitFunction(){
    this.saveClicked              = true;

    let asset_request_product:any = [];
    let newArray:any              = [];
    let returnArray:any           = [];

    if(this.formGp.value.asset_type == 'New' || this.formGp.value.asset_type == 'Both'){
      newArray = this.formGp.value.assetArray;
    }if(this.formGp.value.asset_type == 'Return' || this.formGp.value.asset_type == 'Both'){

      this.employeeAssetList?.forEach((item:any, value:any) => {
        if(item?.checked == true )
          returnArray.push({'asset_category': item?.asset_details?.asset_category,'asset_sub_category': item?.asset_details?.asset_sub_category,'asset_reason':'','asset':item?.id})
      });
    }

    asset_request_product = [...newArray,...returnArray];
    if(this.from=='behalf'){
      this.requestSave.emit(
        {'asset_type': this.formGp.value.asset_type,'asset_request_product': asset_request_product}
      )
      this.saveClicked  = false;

    }else{

      this.assetRequestService.assetRequestCreate({'asset_type': this.formGp.value.asset_type,'asset_request_product': asset_request_product}).subscribe((res: any) => {
          this.requestSave.emit({'from': 'Asset Request'})
          this.saveClicked  = false;
      },
      (err: any) =>{
        this.saveClicked  = false;
      });
    }
  }

  close(){
    this.requestClose.emit(false);
  }

  buttonDiableFunction(){

    if(this.formGp.value.asset_type == 'New'){
      if (this.formGp.invalid || this.formGp.value.assetArray?.length == 0) {
        return false;
      }else{
        return true;
      }
    }else if(this.formGp.value.asset_type == 'Return'){
      if(this.employeeAssetList.filter((obj :any) => obj.checked === true)?.length == 0){
        return false;
      }else{
        return true;
      }
    }else{
      if (this.formGp.invalid) {
        return false;
      }else if(this.formGp.value.assetArray?.length == 0 && this.employeeAssetList.filter((obj :any) => obj.checked === true)?.length == 0){
        return false;
      }else{
        return true;
      }
    }
  }
}
