import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-request-location-view',
  templateUrl: './request-location-view.component.html',
  styleUrls: ['./request-location-view.component.scss']
})
export class RequestLocationViewComponent implements OnInit {

  constructor(public appService: AppService) { }
  @Input() viewMapPanel:any;
  @Input() request:any;
  @Input() profile:any;
  @Output() closeEmitter: EventEmitter<boolean> =  new EventEmitter();

  viewProfileModal = false;
  viewProfileImage = false;
  zoom: number = 18;
  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#0c0c0c"
      }
    ]
  }]

  openedWindow: boolean = false; 
  ngOnInit(): void {
  }

 

  openWindow() {
    if (this.openedWindow)
      this.openedWindow = false;
    else
    this.openedWindow = true;
  }



}
