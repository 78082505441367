<div class="{{from == 'myteam' ? '' : 'config-layout'}}" *ngIf="permissions?.v">
  <header *ngIf="from != 'myteam'" class=" config-header">
    <h5 class="m-0">Leave Balance</h5>
    <button class="btn-reset text-light-600" routerLink="/leave-balance">
      <i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class="{{from == 'myteam' ? '' : 'config-container'}}">
    <div class="{{from == 'myteam' ? '' : 'container-fluid p-32 form-container'}}">
      <!-- No Data -->
      <!-- <div *ngIf="noDataFullScreen && (!empLoader || !tableLoader);else all_data" class="row pt-4">
          <app-nodata-view [noDataText]="'No leave balance data found'" [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>

        </div> -->
      <!-- <ng-template #all_data> -->
      <div class="row row-16">
        <div class="col-3">
          <!-- Emp Loader -->
          <div class="card card-c2 p-24" *ngIf="empLoader">
            <div class="d-flex gap-16 mb-24 align-items-center">
              <ngx-skeleton-loader appearance="circle"
                [theme]="{ 'border-radius': '50%',height: '40px',width: '40px','background-color': '#EAEDEF','margin-right':'15px'}"></ngx-skeleton-loader>
              <div class="d-flex flex-column gap-1">
                <ngx-skeleton-loader appearance="line"
                  [theme]="{'background-color':'#EAEDEF','height': '22px','width': '110px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="line"
                  [theme]="{'background-color':'#EAEDEF','height': '22px','width': '95px','margin-bottom':'2px'}"></ngx-skeleton-loader>
              </div>
            </div>
            <div class="mb-2" style="height: 70px;">
              <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '69px','width': '100%'}"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="card card-c2">
            <div class="card-body p-24" *ngIf="!empLoader">
              <div class="d-flex align-items-center">
                <img *ngIf="(empData?.profile_image!=null && empData?.profile_image!='')" class="avatar-circle sq-32"
                  src="{{empData?.profile_image}}" alt="">
                <div *ngIf="(empData?.profile_image ==  null || empData?.profile_image == '')"
                  class="avatar-circle fs-12 sq-32 {{empData?.color_code}}">
                  {{this.appService.getFirstChar(empData?.name,2)}}</div>
                <div class="overflow-hidden gap-5 ms-16 d-flex flex-column">
                  <p class="fs-20 fw-500  text-trim mb-0">{{empData?.name}}</p>
                  <p class="fs-12 fw-500 mb-0  text-trim lh-base text-light-400">EMP CODE: {{empData?.employee_code}}
                  </p>
                </div>
              </div>
            </div>
            <div class="{{!empLoader && !noDataFullScreen? 'card-body  py-24 px-0':''}}">
              <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class=" vetical-tab tab-scroll overflow-auto">
                <li [ngbNavItem]="i" *ngFor="let type of leaveTypeData; let i = index">
                  <a ngbNavLink class="tab-link text-trim"
                    (click)="onNavChange(type?.leavetype_id)">{{type?.leave_name}}</a>
                  <!-- Clickable HTML -->
                  <ng-template ngbNavContent>
                    <div class="row row-16">
                      <div class="col-6">
                        <h5 class="mb-0 text-trim w-75">{{type?.leave_name}}</h5>
                      </div>
                      <div class="col-6">
                        <ng-select class="form-ngselect ms-auto" style="width:9.5rem" [(ngModel)]="year"
                          (change)="changeYear($event)">
                          <ng-option *ngFor="let yData of yearRange" [value]="yData">{{yData}}</ng-option>
                        </ng-select>
                      </div>
                      <div class="col-12">
                        <div class="card card-c2 px-24 py-16 flex-row align-items-center indicater indicater3"
                          *ngIf="!tableLoader">
                          <div class="fs-14 fw-500 text-break">{{type?.leave_name}} available</div>
                          <ng-container *ngIf="from != 'myteam'">
                            <a class="link-primary fs-14 px-16 d-flex align-items-center" (click)="editScreen(type?.leave_name)"
                              *ngIf="permissions?.e && year == currentYear">
                              <i class="icon-pencil-square me-1"></i> Edit</a>
                          </ng-container>
                          <div class="fs-28 ms-auto text-nowrap">{{leaveData[0]?.available_leave ==undefined ? '0' :
                            leaveData[0]?.available_leave}}/{{leaveData[0]?.total == undefined ? '0' :
                            leaveData[0]?.total}} Days</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <app-table-loader *ngIf="tableLoader"></app-table-loader>
                        <div class="card card-c2 p-24" *ngIf="!tableLoader">
                          <div class="row row-16">
                            <div class="col-md-4 col-lg-6">
                              <h5 class="mb-0">Leave data</h5>
                            </div>
                            <div class="col-md-8 col-lg-6">
                              <div class="d-flex justify-content-end  gap-16">
                                <div class="form-icon2" style="width:22.6875rem;">
                                  <i class="icon-search icon-left fs-14"></i>
                                  <div *ngIf="(searchString != '' && searchString != null)"
                                    class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                                  <input type="text" class="form-control fs-14" name="search" autocomplete="off"
                                    placeholder="{{this.msgServ.searchdisplay('')}}" id="" [(ngModel)]="searchString"
                                    (keyup)="page=1;">
                                </div>
                                <div ngbDropdown class="d-inline-block">
                                  <button class="btn-square btn btn-secondary" placement="bottom-left"
                                    ngbDropdownToggle>
                                    <i class="icon-filter-left fs-13"></i>
                                  </button>
                                  <div ngbDropdownMenu class="dropdown-menu-end"
                                    style="max-height : 13.8rem; overflow:auto">
                                    <button ngbDropdownItem *ngFor="let filter of filterData" (click)="filterFn(filter)"
                                      [class]="{'active' : filterResult == filter}">{{filter}}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <app-view-leave-balance [leaveBalanceTable]="tableData" [searchString]="searchString"
                              [isLeave]="true" (view)="viewComp($event)"></app-view-leave-balance>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div [ngbNavOutlet]="nav"></div>
          <ng-container *ngIf="noDataFullScreen">
            <div class="row row-16">

              <div class="col">
                <ng-select class="form-ngselect ms-auto" style="width:9.5rem" [(ngModel)]="year"
                  (change)="changeYear($event)">
                  <ng-option *ngFor="let yData of yearRange" [value]="yData">{{yData}}</ng-option>
                </ng-select>
              </div>
              <div class="col-12" *ngIf="!empLoader && alertDiv">
                <div role="alert" class="alert alert-warning show mb-0" id="mapShow">
                  <p class="mb-0 fs-14">{{alertMsg}}</p>
                  <div class="btn-close pointer" (click)="alertDiv = false"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

      </div>
      <!-- </ng-template> -->
    </div>
  </main>
</div>



<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;" [class.side-pane-active]='editLeave === true'>
  <div class="side-panel-container" autocomplete="off" [formGroup]="editForm">
    <header class="side-panel-head">
      <h5>Edit</h5>
      <a class="toggle-panel" (click)="editLeave = false;formReset()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">

        <div class="row row-16 ">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0 text-trim pe-16">{{leaveData[0]?.leave_name}}</p>
            <span class="fs-14 fw-500 ms-auto text-accent2 text-nowrap">Current Balance:
              {{leaveData[0]?.available_leave}}/{{leaveData[0]?.total}}</span>
          </div>
          <div class="col-12 ">
            <label for="" class="form-label required">Select your leave operation </label>
          </div>

          <div class="col-12 mt-0">
            <div class="form-check form-check-inline">
              <input formControlName="transaction" class="form-check-input" type="radio" name="transaction"
                id="addTrans" [value]="true" (click)="clearData()">
              <label class="form-check-label" for="addTrans">Add Leave</label>
            </div>
            <div class="form-check form-check-inline">
              <input formControlName="transaction" class="form-check-input" type="radio" name="transaction"
                id="deductTrans" [value]="false" (click)="clearData()">
              <label class="form-check-label" for="deductTrans">Deduct Leave</label>
            </div>
          </div>
          <ng-container *ngIf="type; else normal">
            <ng-container *ngIf="f.transaction.value; else deduction">
              <div class="col-12">
                <label for="comp_off_add" class="form-label required">Compensatory off date </label>
                <div class="input-group form-icon icon-right" [ngClass]="{'is-invalid':(submitted || f.comp_off_add.touched || f.comp_off_add.dirty) &&  f.comp_off_add.errors}">
                  <input (click)="datePicker2.open()" class="form-control" placeholder="{{this.msgServ.selectplaceholddisplay('date')}}" matInput
                  formControlName="comp_off_add" [matDatepicker]="datePicker2" [ngClass]="{'is-invalid':(submitted || f.comp_off_add.touched || f.comp_off_add.dirty) &&  f.comp_off_add.errors}"
                  [min]="minDate" [max]="maxDate"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker  touchUi="false" #datePicker2></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f?.comp_off_add?.touched || f?.comp_off_add?.dirty) &&  f?.comp_off_add?.errors" class="invalid-feedback">
                  <div *ngIf="f?.comp_off_add?.errors?.required">{{this.msgServ.validationDisplay('required')}}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <label for="leave" class="form-label required">Leave </label>
              </div>
              <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input formControlName="leave_type" class="form-check-input" type="radio"  [value]=false>
                    Half Day
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input formControlName="leave_type" class="form-check-input" type="radio" [value]=true>
                    Full Day
                  </label>
                </div>
              </div>
            </ng-container>
            <ng-template #deduction>
            <div class="col-12">
              <label for="" class="form-label required">Deduct compensatory date </label>
              <ng-select formControlName="comp_off_deduct" [ngClass]="{'is-invalid':(submitted || f.comp_off_deduct.touched || f.comp_off_deduct.dirty) &&  f.comp_off_deduct.errors}"
              placeholder="{{this.msgServ.selectplaceholddisplay('compensatory leave')}}" [items]="compOffDropdown"
              [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
              bindLabel="compOff_date" [closeOnSelect]="false" bindValue="attendance_date" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{ standalone : true }" />
                {{ item.compOff_date }}
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="editForm.value.comp_off_deduct.length">
                  <span class="ng-value-label">{{editForm.value.comp_off_deduct.length}} compensatory date selected.</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ (item.compOff_date.length>15)? (item.compOff_date | slice:0:15)+'..':(item.compOff_date) }}
              </ng-template>
              </ng-select>
              <ul class="selected-values mt-2" *ngIf="editForm.value.comp_off_deduct?.length > 0">
                <li class="ng-value" *ngFor="let item of editForm.value.comp_off_deduct, let m =index">
                  <ng-container *ngFor="let insideitem of compOffDropdown, let k =index">
                    <span class="ng-value-label" title="{{insideitem['compOff_date']}}" *ngIf="insideitem['attendance_date']==item">
                      {{(insideitem?.compOff_date.length>15)? (insideitem?.compOff_date |
                      slice:0:15)+'..':(insideitem?.compOff_date) }}</span>
                  </ng-container>
                  <span class="ng-value-icon right" aria-hidden="true" (click)="clear(m)"><i
                      class="icon-close"></i></span>
                </li>
              </ul>
              <div *ngIf="(submitted || f?.comp_off_deduct?.touched || f?.comp_off_deduct?.dirty) &&  f?.comp_off_deduct?.errors" class="invalid-feedback">
                <div *ngIf="f?.comp_off_deduct?.errors?.required">{{this.msgServ.validationDisplay('required')}}
                </div>
              </div>
            </div>
           </ng-template>
          </ng-container>
          <ng-template #normal>
            <div class="col-12">
              <label for="leave" class="form-label required">Leave </label>
              <input formControlName="count" type="text"
                [ngClass]="{ 'is-invalid': (f.count.touched|| f.count.dirty) &&  f.count.errors}" pattern="-?\d+(\.\d+)?$"
                autocomplete="off" class="form-control" name="count" id="leave"
                placeholder="{{this.msgServ.placeholderdisp('Days')}}">
              <div *ngIf="(f.count.touched || f.count.dirty) && f.count.errors" class="invalid-feedback">
                <div *ngIf="f.count.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
                <div *ngIf="f.count.hasError('min') && !(f.count.errors?.pattern)">
                  {{this.msgServ.fieldlengthvalidation('minvalue',0.01)}}
                </div>
                <div *ngIf="f.count.hasError('max') && !(f.count.errors?.pattern)">
                  {{this.msgServ.fieldlengthvalidation('value',200)}}
                </div>
                <div *ngIf=" f.count.errors?.pattern || (notanumber(f.count.errors?.pattern?.actualValue))">
                  {{this.msgServ.validationDisplay('pattern')}}</div>
              </div>
            </div>
          </ng-template>
          <div class="col-12">
            <label for="remark" class="form-label">Remarks </label>
            <textarea type="text" formControlName="remarks" class="form-control" name="remarks" id="remark" rows="4" placeholder="{{this.msgServ.placeholderdisp('remarks')}}" autocomplete="off" [ngClass]="{ 'is-invalid': (submitted || f.remarks.dirty || f.remarks.touched) && (f.remarks.errors) }"></textarea>
            <div *ngIf="(submitted || f.remarks.dirty || f.remarks.touched) && f.remarks.errors" class="invalid-feedback">
              <div *ngIf="f.remarks.errors.maxlength">{{this.msgServ.fieldlengthvalidation('word','100')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="  btn btn-outline-primary text-uppercase btn-sm"
        (click)="editLeave = false;formReset()">
        Cancel
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        [disabled]="!editForm.valid || submitted" (click)="saveData()">
        save
      </button>
    </footer>
  </div>

  <!-- View -->
  <ng-container *ngIf="viewpanel == true">
    <app-view-leave-request [leaveRequestData]="viewData" [leaveRequestView]="viewpanel"
      (leaveViewClose)="leaveViewClose($event)" [loader1]="viewLoader"></app-view-leave-request>
  </ng-container>
