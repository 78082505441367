import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class InviteEmployeeService {
  FILTERDATA:any=`invite_category=new&employment_status__in=Probation,Confirmed&ordering=user__first_name`
  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      if(  !event.url.includes('invite-employees')){
        this.setFilterData(`invite_category=new&employment_status__in=Probation,Confirmed&ordering=user__first_name`)
      }
    }
  })}

  // Initial Table Data
  getInviteData(data:any,status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/invite-employee-list/?invite_category=${data}&employment_status__in=${status}&ordering=user__first_name`, this.httpOption)
  }

  // Filter
  getFilteredData(data:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/invite-employee-list/?${data}`, this.httpOption)
  }

  // Send Password
  sendPassword(body:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/invite-employee/`, body, this.httpOption)
  }
  generatePassword(body:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/geneare-employee-password/`, body, this.httpOption)
  }
  setFilterData(val:any){
    this.FILTERDATA = val
  }
}
