import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

 

  constructor(
    private http: HttpClient,
  ) { }

  fileUpload(data: any) {
    return this.http.post<any>(`${this.API_PATH}api/v1/azure-upload/`, data, this.httpOption);
  }
}
