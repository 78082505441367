import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { CompensatoryOffService } from 'src/app/@core/services/compensatory-off.service';
import { AppService } from 'src/app/app.global';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-compensatory-off-setup',
  templateUrl: './compensatory-off-setup.component.html',
  styleUrls: ['./compensatory-off-setup.component.scss']
})

export class CompensatoryOffSetupComponent implements OnInit {

  @Input()reqFlag: any;
  @Input()listData: any;
  @Input()loader: any;
  @Input()nodata: any;
  @Input()from: any;
  @Input()empId: any;
  @Input()requestFor:any;

  @Output() compOffClose = new EventEmitter();
  // @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  @Output() successEmitter = new EventEmitter();

  currentYear            =  new Date().getFullYear();
  selectedYearDisp       = String(this.currentYear);
  selectedYear           = this.currentYear;
  yearList: any          = [];

  compOffReview          = false;
  loader1                = false;
  compOffReviewData:any  = [];
  attendanceDate:any;

  compOffId = 0;

  constructor(
    public messageService : MessageService,
    private compensatoryOffService: CompensatoryOffService,
    public appservice: AppService,
    public router: Router,
    private authService: AuthService,
    private activityservice:ManageActivityService
  ) { }

  ngOnInit(): void {
    this.yearListCreate();
  }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }

  getOndutyTrendDetails(event:any){
    let emp_Id       = this.from=='behalf'?this.empId:this.authService.isEmployeeId
    this.loader      = true;
    this.listData    = [];
    this.selectedYear = event;
    this.compensatoryOffService.getCompOffRequestList(emp_Id, event).subscribe((res: any) => {
      this.loader     = false;
      // let all_expired = res?.every((key:any) => key?.is_expired === true);
      this.listData   = res;
      if(this.listData?.length == 0){
        this.nodata = true;
      }
      // else if(all_expired){
      //   this.nodata = true;
      // }
      else{
        this.nodata = false;
      }
    });
  }

  compOffReviewFunction(id:any,date:any){
    this.compOffReview     = true;
    this.loader1           = true;
    this.compOffReviewData = [];
    this.attendanceDate    = date;
    this.compOffId         = id;
    this.compensatoryOffService.getCompOffRequestDetail(id).subscribe((res:any)=>{
      if(res){
        this.compOffReviewData = res;
        // this.attendanceDate    = date;
        this.loader1           = false;
      }
    })
  }

  closeFunction(){
    this.compOffClose.emit();
  }

  submitCompOffReq(){
    if(this.from=='behalf'){
      this.successEmitter.emit({'compOffId': this.compOffId, "attendance_date": this.appservice.dateFormatConvert(this.attendanceDate)});

    }
    else{

      this.compensatoryOffService.compOffRequestCreate(this.compOffId,{"attendance_date": this.appservice.dateFormatConvert(this.attendanceDate)}).subscribe((res:any)=>{
        if(res){
          // this.reqFlag       = false;
          this.compOffReview = false;
          this.successEmitter.emit({'success': true, 'year': this.selectedYear});
        }
      })
    }
  }

}
