<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" routerLink="/weekly-off"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step"
          [class]="{'success': weekOffForm['controls'].weekOff.valid || weekOffForm['controls'].weekOff.disabled}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Weekly Off Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-weekly-off-details [disabled]="disabled" [editflag]="editflag" [formGp]="weekOffMapForm"
            (submitmethod)="submitMethod(2)"></app-weekly-off-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step"
          [class]="{'success': weekOffForm['controls'].weekoff_assign.valid || weekOffForm['controls'].weekoff_assign.disabled}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Applicability</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-common-applicability [disabled]="disabled" [heading]="'Applicability'" [isEditForm]="editflag" [formGp]="WeekOffAssignForm" (submitMethod)="submitForm()"></app-common-applicability>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
