import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
// import { MapsAPILoader } from "@agm/core";
import { AppService } from 'src/app/app.global';
import { InfoWindow, LatLng, MapTypeStyle } from '@agm/core/services/google-maps-types';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-employee-map-view',
  templateUrl: './app-employee-map-view.component.html',
  styleUrls: ['./app-employee-map-view.component.scss']
})
export class EmployeeMapViewComponent implements OnInit {
  activeTab = 1;
  sidebarCollapse=true;
  constructor(
    private datePipe: DatePipe,
    public appservice: AppService) {

  }
  today: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  centre:any
  @Input() origin: any
  @Input() destination: any
  @Input() data: any;
  @Output() closepanel = new EventEmitter();

  // lat: number = 8
  // lng: number = 76
  zoom: number = 18;
  // icon: any

  infoToggle:any
  dirs: Array<any> = []

  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#0c0c0c"
      }
    ]
  }]
  ways:any = []
  map: any;
  ngOnInit(): void {
    this.prepareMapList()
  }
  closePanel() {
    this.closepanel.emit();
  }

  click = -1

  openedWindow: number = -1; // alternative: array of numbers

  openWindow(id: any) {
    if (this.isInfoWindowOpen(id))
      this.openedWindow = -1;
    else
      this.openedWindow = id; // alternative: push to array of numbers
  }

  isInfoWindowOpen(id: any) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Settings'));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Zoom'));
  }

//   getDistance(origin: any, destination: any,ways:any) {
//     const matrix = new google.maps.DistanceMatrixService();
//     return new Promise((resolve, reject)=>{
//       matrix.getDistanceMatrix({
//           origins: [origin],
//           destinations: [destination],
//           travelMode: google.maps.TravelMode.DRIVING,
//           // waypoints: ways,
//       },
//       function(response, status) {
//         if (status === 'OK'){
//           resolve( response)
//         } else {
//           reject(response);
//         }
//       });
//     });
// }
  async calcRoute(origin: any, destination: any,ways:any) {
  var directionsService = new google.maps.DirectionsService();
  var request = {
    origin: origin,
    destination: destination,
    waypoints: ways,
    optimizeWaypoints: true,
    travelMode: google.maps.TravelMode.DRIVING
  };
  var res:any = null
  await directionsService.route(request, function(response, status) {
    if (status == google.maps.DirectionsStatus.OK) {
        res= response.routes[0].legs[0].distance.text;
    }
  });
 return res
}
  prepareMapList(){
    let origin = false
    let originPts:any
    let originVal:any
    let originKey:any
    let DestPts:any
    let DestVal:any
    let DestKey:any
    let ways:any = []
    let waysVal:any = []
    let k=0
    this.data.tracking_datas.forEach((val: any, key: any) => {
      if(key == 0)
        this.centre =  val

      if(this.dirs.length>0 || val.realtime_status == 'Check-in'|| origin == true){
        if(origin == false && val.mobility_status == "Mobile"){
          originPts =  new google.maps.LatLng({ lat: Number(val.latitude), lng: Number(val.longitude) })
          originVal = val
          originKey = key
          origin = true
          ways = []
          waysVal = []
        } else if(origin == false && val.mobility_status == "Stationary"){
          this.dirs.push({
            isDirection : false,
            key:k,
            marker: new google.maps.LatLng({ lat: Number(val.latitude), lng: Number(val.longitude) }),
            markerVal:val,
            markerKey:key,
          })
          k++
        } else if(origin == true && (val.mobility_status=="Stationary"|| key == this.data.tracking_datas.length-1 )){
          origin = false
          DestPts =  new google.maps.LatLng({ lat: Number(val.latitude), lng: Number(val.longitude) })
          DestVal = val
          DestKey = key

          let dis = this.calcRoute(originPts,DestPts,ways)
          for(var i = ways.length-23;i>=0;i--){
            let x1 = Math.floor(Math.random()*ways.length)
            ways.splice(x1, 1);
            waysVal.splice(x1, 1);
          }

          let rs,ms
          rs =   { polylineOptions: { strokeColor: '#0066DD',strokeWeight: 5 } ,suppressMarkers: true},
          ms =  {}
          this.dirs.push({
            isDirection : true,
            key:k,
            origin: originPts,
            destination: DestPts,
            renderOptions: rs,
            markerOptions : ms,
            waypoints :ways,
            waysVal: waysVal,
            originVal:originVal,
            DestVal:DestVal,
            originKey:originKey,
            DestKey:DestKey,
            distance:dis
          })
          k++
          if(key<this.data.tracking_datas.length-1 && val.realtime_status != "Check-out"){
            originPts = DestPts
            originVal = DestVal
            originKey = DestKey
            origin = true
            ways = []
            waysVal = []
          }
        } else {
          ways.push({ location:  new google.maps.LatLng({ lat: parseFloat(val.latitude), lng: parseFloat(val.longitude) }),stopover: false, })
          waysVal.push({ lat: parseFloat(val.latitude), lng: parseFloat(val.longitude),val:val })
        }
      }

    })
    // console.log(this.dirs)
  }
infoWindow(val:any){
  console.log(val)
  return `<div class="card card-c2 border-0" style="max-width: 16rem;">
          <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                  <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                  <span class=" fw-500 text-trim-3" title="`+val.location+`">`+val.location+`</span>
          </div>
          <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
              <div class="hstack gap-16 justify-content-between">
                  <span class="text-light-500 ">Mobility Status</span>
                  <span class=" text-end text-uppercase text-warning fw-500">`+val.mobility_status+`</span>
              </div>
              <div class="hstack gap-16 justify-content-between">
                  <span class="text-light-500 ">Time</span>
                  <span class=" text-end fw-500">`+this.appservice.formatTimeDate(val.timestamp)+`</span>
              </div>
              <div class="hstack gap-16 justify-content-between">
                  <span class="text-light-500 ">Date</span>
                  <span class="text-end fw-500"  >`+this.appservice.dateFormatDisplay(val.timestamp)+`</span>
              </div>
          </div>
      </div>`
}
  clicklist(click:any,showBreadcrumb:boolean=false){
    this.click = click
    let newdirs:any = []
    this.dirs.forEach((val: any, key: any) => {
      if(key==click && val.isDirection){
        this.centre =   val.origin
        newdirs.push({
          isDirection : true,
          key:val.key,
          origin: val.origin,
          destination: val.destination,
          renderOptions: { polylineOptions: { strokeColor: "#111B22",strokeWeight: 5,zIndex:99 },suppressMarkers: true },
          markerOptions: {
            origin: { "icon":"assets/images/helper/map-img/map-pin1.png" ,  infoWindow: this.infoWindow(val.originVal)},
            destination:  { "icon":"assets/images/helper/map-img/map-pin1.png",  infoWindow: this.infoWindow(val.DestVal)},
          },
          waypoints :val.waypoints,
          waysVal :val.waysVal,
          originVal:val.originVal,
          DestVal:val.DestVal,
          originKey:val.originKey,
          DestKey:val.DestKey,
          distance:val.distance
        })
        if(showBreadcrumb)
          this.ways = val.waysVal
      } else if(val.isDirection){
        newdirs.push({
          isDirection : true,
          key:val.key,
          origin: val.origin,
          destination: val.destination,
          renderOptions: { polylineOptions: { strokeColor: "#0066DD",strokeWeight: 5,zIndex:1 } ,suppressMarkers: true},
          markerOptions: {},
          waypoints :val.waypoints,
          waysVal :val.waysVal,
          originVal:val.originVal,
          DestVal:val.DestVal,
          originKey:val.originKey,
          DestKey:val.DestKey,
          distance:val.distance
        })
      } else {
        newdirs.push({
          isDirection : false,
          key:val.key,
          marker: val.marker,
          markerVal:val.markerVal,
          markerKey:val.markerKey,
      })
    }
    })
    this.dirs = newdirs
    // console.log(this.ways)
  }

}
