import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MyAttendenceCalenderService {
  checkin_item: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpClient,
    public router: Router,
    private websocket: WebSocket,
    public appservice:AppService,
    private authService: AuthService) { }

    API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    createAttendenceMark(empId:any,body:any){
      return this.http.post(`${this.API_PATH}attendance/api/v1/profile-attendance-mark/${empId}/`,body, this.httpOption)
    }

    getWebcheckinValidations(empId:any,from:any="user"){
      if(from == "user")
        return this.http.get(`${this.API_PATH}attendance/api/v1/profile-webpunch-validations/${empId}/`, this.httpOption)
      else if(from == "hr")
        return this.http.get(`${this.API_PATH}attendance/api/v1/hr-webpunch-validations/${empId}/`, this.httpOption)
      else
        return this.http.get(`${this.API_PATH}attendance/api/v1/team-webpunch-validations/${empId}/`, this.httpOption)
    }
    getCalendarData(empId:any,month:any,year:any,from:any="user"){
      if(from == "user")
       return this.http.get(`${this.API_PATH}attendance/api/v1/profile-attendance-calender/${empId}/?attendance_month=${month}&attendance_year=${year}`, this.httpOption)
      else if(from == "HR")
        return this.http.get(`${this.API_PATH}attendance/api/v1/hr-attendance-calender/${empId}/?attendance_month=${month}&attendance_year=${year}`, this.httpOption)
      else 
        return this.http.get(`${this.API_PATH}attendance/api/v1/team-attendance-calender/${empId}/?attendance_month=${month}&attendance_year=${year}`, this.httpOption)
    }
    getAttendanceSummary(empId:any,month:any,year:any,from:any="user"){
      if(from == "user")
        return this.http.get(`${this.API_PATH}attendance/api/v1/profile-attendance-details/${empId}/?attendance_month=${month}&attendance_year=${year}`, this.httpOption)
      else if(from == "hr")
        return this.http.get(`${this.API_PATH}attendance/api/v1/hr-attendance-details/${empId}/?attendance_month=${month}&attendance_year=${year}`, this.httpOption)
      else 
        return this.http.get(`${this.API_PATH}attendance/api/v1/team-attendance-details/${empId}/?attendance_month=${month}&attendance_year=${year}`, this.httpOption)
    }

  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public checkinSocket(){
    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}check_incheck_out/${this.appservice.getSubDomain()}_check_incheck_out_${this.authService.isUserId}/`);

    this.websocket.onopen = (event:any) => {
    }

    this.websocket.onmessage = (event:any) => {
      let data = JSON.parse(event.data);
      this.checkin_item.emit(data);
    }

    this.websocket.onclose = (event:any) => {
    }

    if (this.websocket.readyState === WebSocket.OPEN) {
    }

  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
      // this.websocket.onclose = (event:any) => {

      // }
      this.websocket.addEventListener('close', (event) => {

      });
    }
  }

}
