import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  getroleList(status: any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/role-employee-list/?limit=${limit}&offset=${offset}&is_active=${status}&search=${search}`, this.httpOption);
  }

  getempList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name`, this.httpOption);
  }

  createRole(data: any, id: number) {
    if(id == 0) {
      return this.http.post(`${this.API_PATH}organization/api/v1/role-list/`, data, this.httpOption);
    } else {
      return this.http.put(`${this.API_PATH}organization/api/v1/role-detail/${id}/`, data, this.httpOption);
    }
  }

  createRoleEmployee(data: any) {
    return this.http.post(`${this.API_PATH}organization/api/v1/role-employee-create/`, data, this.httpOption);
  }

  getEmpRoleList(id: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/role-list/?id=${id}`, this.httpOption);
  }

  deleteRole(data: any, id: number) {
      return this.http.patch(`${this.API_PATH}organization/api/v1/role-delete/${id}/`, data, this.httpOption);
  }

  getBuList(companyId:number){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name&company_id=${companyId}`, this.httpOption);
  }

  getDepartmentList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getDesignationList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getGradeList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getBranchList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getCityList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getCompanyList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
  }

  getRoleDropdownList(status:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/role-dropdown-list/?is_active=${status}&ordering=role_name`, this.httpOption);
  }

  getRoleSearch(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/role-employee-retrieve/${id}/`, this.httpOption);
  }
  getRoleviewdata(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/role-view/${id}/`, this.httpOption);
  }

  getcityList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }
}
