import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog';
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import {
  STYLES as CROPPER_STYLES,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig
} from '@alyle/ui/image-cropper';
import { Platform } from '@angular/cdk/platform';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl `{
     
      ${cropper.root} {
        max-width: 504px
        height: 240px
      }
    }`,
    sliderContainer: lyl  `{
      text-align: center
      max-width: 504px
      margin: 36px
    }`,
    // container: lyl `{
    //   border-radius: 24px
    // }`,
    // dialogContainer:lyl `{
    //   border-radius: 24px
    // }`
    // slider: lyl `{
    //   background: #0066DD!important;
    // height: 8px!important;
    // border-radius: 4px 0px 0px 4px!important;
    // }`
  };
};

@Component({
  templateUrl: './cropper-dialog.html',
  styleUrls: ['./cropper-dialog.scss'],
  
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class CropperDialog implements WithStyles, AfterViewInit {

  readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
  ready: boolean=false;
  croppedImage?: string;
  scale: any =1;
  // ready: boolean;
  minScale: number=1;
  // max: number=5;
  // minScale: number=0.1;
  // step="0.000001";
  @ViewChild(LyImageCropper, { static: true })
    cropper!: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 200,
    height: 200,
    fill: '#ffffff',

   
    round: true,
    type: 'image/png',
 
    // keepAspectRatio: true,
    responsiveArea: true,
    // resizableArea:true,
    // output: ImgResolution.OriginalImage,
  };

  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef,
    private _platform: Platform
  ) { }

  ngAfterViewInit() {
    // Load image when dialog animation has finished
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.event);
    });
    
  }

  onCropped(e: ImgCropperEvent) {
    console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
    // Close the dialog if it fails
    this.dialogRef.close();
  }

}
