import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'levelTransform'
})

export class LevelTransformPipe implements PipeTransform {
  transform(itemList: any, searchKeyword: number, childrencheck:number = 0): any {
    if (!itemList)
      return [];
    if (!searchKeyword)
      return itemList;

    // var array1 = [20, 21, 22, 84];

    var array1 = ['Home', 'Inbox', 'Request', 'Profile'];

    let filteredList:any = [];
        itemList.forEach((item:any, value:any) => {
          let propValueList:any = Object.values(item);
            if (propValueList[0] == searchKeyword && childrencheck == 0) {
              propValueList[4].forEach((item1:any, value1:any) => {
                if(array1.find(element => element == item1.parent) !== undefined){
                  item1.default = true;
                }else{
                  item1.default = false;
                }
              })
              filteredList = propValueList[4];
            }else if(propValueList[0] == searchKeyword && childrencheck == 1){
              propValueList[4].forEach((item1:any, value1:any) => {
                if(array1.find(element => element == item1.parent) !== undefined){
                  item1.default = true;
                }else{
                  item1.default = false;
                }
                if(item1.children.length != 0){
                  filteredList.push(item1);
                }
              })
            }
        });

      return filteredList;
    }
}
