import { AbstractControl, ValidatorFn } from "@angular/forms";


export class AmountCompareValidator {
    static minMax(minAmount: string, maxAmount: string, errorName: string = 'minMax'): ValidatorFn {
        return (group: AbstractControl): { [key: string]: boolean } | null => {

            const minimum       = (group.get(minAmount)?.value)
            const maximum       = (group.get(maxAmount)?.value)

            if ((minimum !== null && maximum !== null && minimum !== '' && maximum !== '') &&  (Number(minimum) > Number(maximum))) {

                return {[errorName]: true};
            }
            return null;


        };
    }

}
