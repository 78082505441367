import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-income-tax',
  templateUrl: './income-tax.component.html',
  styleUrls: ['./income-tax.component.scss']
})
export class IncomeTaxComponent implements OnInit {
  activeTab                = 1;
  challan_permissions:any  = [];
  tds_permissions:any      = [];

  menuData   : any = []
  menuList   : any


  constructor(
    public route:ActivatedRoute,
    private appService: AppService,
    private router        : Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(event.url == '/challan'){
          this.getPermission()
         }
      }
     });
  }

  ngOnInit(): void {
    this.getPermission()
  }


  createMenuList(){
    this.menuData = []
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })
    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/challan')
      this.router.navigate([this.menuData[0]?.link]);
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/challan');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }

}
