import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortalAccessService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getPortalAccessList(filterdata:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/disabled_employee_list/?${filterdata}`, this.httpOption)
  }
  employeeList(){
    return this.http.get(`${this.API_PATH}organization/api/v1/disabled_employee_search_list/?is_active=true&ordering=user__first_name`, this.httpOption)
  }
  disableEmployee(body:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/disable_active_employee/`, body, this.httpOption)
  }
  enabeleEmployee(body:any,id:any){
    return this.http.put(`${this.API_PATH}organization/api/v1/enable_deactive_employee/${id}/`, body, this.httpOption)
  }

 
  
}
