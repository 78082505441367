
<form action="" class=""  [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">

      <div class="row ">
          <p  class="text-uppercase fw-500">SHIFT ROTATION DETAILS </p>
      </div>
      <div class="row row-16">
          <div class=" col-lg-6 form-row">
              <label for="" class="form-label required">Rotation Name</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter a name for shift rotation." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <input type="text" formControlName="name" class="form-control" placeholder="{{this.messageService.placeholderdisp('name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.touched || f.name.dirty) &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
            <div *ngIf="(submitted || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
              <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
              <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
            </div>
          </div>
          <div class=" col-lg-6 form-row">
            <label for="shift_schedule" class="form-label required">Shift scheduled for </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Shift automapping can be scheduled on a weekly or monthly basis." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <ng-select  [ngClass]="{ 'is-invalid': (submitted || f.shift_schedule.touched || f.shift_schedule.dirty) &&  f.shift_schedule.errors || f.shift_schedule.errors?.exist}" class="form-ngselect" formControlName="shift_schedule" id="scheduledfor" placeholder="{{this.messageService.selectplaceholddisplay('novalue')}}" (change)="resetApplicablePeriod()">
              <ng-option *ngFor="let scheduledfor of scheduledfor" [value]="scheduledfor">
                {{scheduledfor}}
              </ng-option>
            </ng-select>
            <div *ngIf="(submitted || f.shift_schedule.touched || f.shift_schedule.dirty) && f.shift_schedule.errors || f.shift_schedule.errors?.exist"  class="invalid-feedback">
              <div *ngIf="f.shift_schedule.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
        </div>
        <div class=" col-lg-6 form-row">
            <label for="applicable_period" class="form-label required">Applicable period </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose the date for which the shift should be automatically mapped. If you map the shift on a weekly basis, choose which day the shift must be mapped automatically, for example, if you choose Sunday. The shift will be scheduled from Sunday to Sunday for one week. If you map the shift on a monthly basis and the applicable period is the 4th. The shift will be scheduled from the 4th of this month to the 4th of next month." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <ng-container *ngIf="f.shift_schedule.value !='Weekly' && f.shift_schedule.value !='Monthly'">
              <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('novalue')}}" [readonly]="true">
              </ng-select>
            </ng-container>
          <ng-container *ngIf="f.shift_schedule.value =='Weekly'">
            <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.applicable_period.touched) && f.applicable_period.errors }" [submitted]="submitted" [isrequired]='"true"' [placeholder]="applicable_periodkey" id="applicable_period" [submitted]="submitted" [selectedItem]="f.applicable_period.value" formControlName="applicable_period" [type]="'weekdays'" [selectError]='"applicable_period"'></app-master-select>
          </ng-container>
          <ng-container *ngIf="f.shift_schedule.value =='Monthly'">
            <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || formGp.controls['applicable_period'].touched) &&  formGp.controls['applicable_period'].errors}" [placeholder]="applicable_periodkey" id="applicable_period" [submitted]="submitted"
            [isrequired]="'true'" [selectedItem]="formGp.controls['applicable_period'].value" formControlName="applicable_period" [type]="'schedule_dates'"  [range]="28" [lastDay]="true">
            </app-master-select>
          </ng-container>
            <div *ngIf="(submitted || f.applicable_period.touched) && f.applicable_period.errors" class="invalid-feedback">
              <div *ngIf="f.applicable_period.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
        </div>

      </div>
  </div>

  <footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid || componentError)" (click)="validateform()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validateform()">NEXT</button>
  </footer>
</form>
