<form class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16 mb-24">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">Configure Short leave </p>
            </div>
            <div class="col-12">
                <label for="name" class="form-label required">Policy name </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify a unique policy name for short leave policy" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <input type="text" name="name" class="form-control" formControlName="name" placeholder="{{this.msgServ.placeholderdisp('policy name')}}" id="name" (keyup)="keyUp()" [ngClass]="{ 'is-invalid' : (saveBtnClicked || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist}">
                <div *ngIf="(saveBtnClicked || f?.name?.touched || f?.name?.dirty) && f?.name?.errors || f?.name?.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f?.name?.hasError('exist')">{{this.msgServ.validationDisplay('policy name')}}</div>
                  <div *ngIf="f?.name?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="!f?.name?.errors?.pattern && f?.name?.errors?.maxlength">{{this.msgServ.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf=" f?.name?.errors?.pattern">{{this.msgServ.validationDisplay('pattern')}}</div>
                </div>
            </div>
            <div class="col-md-6">
                <label for="short_leave_limit_type" class="form-label required">Short leave limit </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select if you want to apply a weekly or monthly short leave limit." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <ng-select class="form-ngselect" formControlName="short_leave_limit_type" placeholder="{{this.msgServ.selectplaceholddisplay('short leave limit')}}" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.short_leave_limit_type?.dirty || f.short_leave_limit_type?.touched) &&  f.short_leave_limit_type?.errors}" (ngModelChange)="checkValidations()">
                  <ng-option *ngFor="let dropdown of limitDropdown" [value]="dropdown">{{dropdown}}</ng-option>
                </ng-select>
                <div *ngIf="(saveBtnClicked || f.short_leave_limit_type?.dirty || f.short_leave_limit_type?.touched) && f.short_leave_limit_type?.errors"
                class="invalid-feedback">
                  <div *ngIf="f.short_leave_limit_type?.errors?.required">
                    {{this.msgServ.validationDisplay('required')}}</div>
                </div>
            </div>
            <!-- <div class="col-md-6">
                <label for="limit_start_day" class="form-label required">On which day of the week, limit should start? </label>
                <ng-select class="form-ngselect" formControlName="limit_start_day" placeholder="{{this.msgServ.selectplaceholddisplay('start day')}}"></ng-select>
            </div> -->
            <div class="col-md-6">
                <label for="times" class="form-label required">No of times short leave can applied </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Define how many times short leave can be applied in a week or month." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                <input type="text" name="max_no_leave_apply_times" pattern="^(0|[1-9]\d*)$" formControlName="max_no_leave_apply_times" class="form-control" placeholder="{{this.msgServ.placeholderdisp('limit')}}" id="times" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.max_no_leave_apply_times?.dirty || f.max_no_leave_apply_times?.touched) &&  f.max_no_leave_apply_times?.errors}" (ngModelChange)="checkValidations()">
                <div *ngIf="(saveBtnClicked || f.max_no_leave_apply_times?.dirty || f.max_no_leave_apply_times?.touched) && f.max_no_leave_apply_times?.errors"
                class="invalid-feedback">
                  <div *ngIf="f.max_no_leave_apply_times?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="f?.max_no_leave_apply_times?.errors?.pattern || notanumber(f?.max_no_leave_apply_times.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                  <div *ngIf="!f?.max_no_leave_apply_times?.errors?.pattern && f.max_no_leave_apply_times.hasError('min')">{{this.msgServ.fieldlengthvalidation('minvalue',0)}}</div>
                  <div *ngIf="!f?.max_no_leave_apply_times?.errors?.pattern && f.max_no_leave_apply_times.hasError('maxlength')">{{this.msgServ.fieldlengthvalidation('num',2)}}</div>
                </div>
            </div>
        </div>
        <div class="row row-16 ">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">short leave duration</p>
            </div>
            <div class="col-12">
              <label for="minHoursLabel" class="form-label required">Minimum short leave hours in one request </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Define the minimum short leave hours that an be applied in one request." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12">
                <div class="align-items-start d-flex flex-nowrap row row-16">
                    <div class="col-6 form-row mt-0">
                        <input type="text" name="minHours" class="form-control" placeholder="{{this.msgServ.placeholderdisp('hours')}}" id="minHours" formControlName="minHours" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.minHours?.dirty || f.minHours?.touched) &&  f.minHours?.errors}" (ngModelChange)="validateMinMax();maxLimit('minHours','minMinutes',true);checkValidations()">
                        <div *ngIf="(saveBtnClicked || f.minHours?.dirty || f.minHours?.touched) && f.minHours?.errors"
                        class="invalid-feedback">
                          <div *ngIf="f.minHours?.errors?.required">
                            {{this.msgServ.validationDisplay('required')}}</div>
                            <div *ngIf="!f?.minHours?.errors?.pattern && f.minHours.hasError('maxValue')">{{this.msgServ.fieldlengthvalidation('hours',4)}}</div>
                            <div *ngIf="f?.minHours?.errors?.pattern || notanumber(f?.minHours.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                        </div>
                    </div>
                    <div class="col-6 form-row mt-0">
                        <input type="text" name="minMinutes" class="form-control" placeholder="{{this.msgServ.placeholderdisp('minutes')}}" id="minMinutes" formControlName="minMinutes" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.minMinutes?.dirty || f.minMinutes?.touched) &&  f.minMinutes?.errors}" (ngModelChange)="validateMinMax();maxLimit('minHours','minMinutes',false);checkValidations()">
                        <div *ngIf="(saveBtnClicked || f.minMinutes?.dirty || f.minMinutes?.touched) && f.minMinutes?.errors"
                        class="invalid-feedback">
                          <div *ngIf="f.minMinutes?.errors?.required">
                            {{this.msgServ.validationDisplay('required')}}</div>
                          <div *ngIf="!f?.minMinutes?.errors?.pattern && f.minMinutes.hasError('maxValue')">{{this.msgServ.fieldlengthvalidation('hours',4)}}</div>
                          <div *ngIf="!f?.minMinutes?.errors?.pattern && f.minMinutes.hasError('max')">{{this.msgServ.fieldlengthvalidation('minutes',59)}}</div>
                          <div *ngIf="f?.minMinutes?.errors?.pattern || notanumber(f?.minMinutes.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <label for="maxHoursLabel" class="form-label required">Maximum short leave hours in one request </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Define the maximum short leave hours that an be applied in one request." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12">
                <div class="align-items-start d-flex flex-nowrap row row-16">
                    <div class="col-6 form-row mt-0">
                        <input type="text" name="maxHours" class="form-control" placeholder="{{this.msgServ.placeholderdisp('hours')}}" id="maxHours" formControlName="maxHours" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.maxHours?.dirty || f.maxHours?.touched) &&  f.maxHours?.errors}" (ngModelChange)="validateMinMax();validateMaxTotal();maxLimit('maxHours','maxMinutes',true);checkValidations()">
                        <div *ngIf="(saveBtnClicked || f.maxHours?.dirty || f.maxHours?.touched) && f.maxHours?.errors"
                        class="invalid-feedback">
                          <div *ngIf="f.maxHours?.errors?.required">
                            {{this.msgServ.validationDisplay('required')}}</div>
                          <div *ngIf="!f?.maxHours?.errors?.pattern && f.maxHours.hasError('maxValue')">{{this.msgServ.fieldlengthvalidation('hours',4)}}</div>
                          <div *ngIf="!f?.maxHours?.errors?.pattern && f.maxHours.hasError('minMaxError')">{{this.msgServ.fieldlengthvalidation('minGreat','minimum short leave hours in one request')}}</div>
                          <div *ngIf="f?.maxHours?.errors?.pattern || notanumber(f?.maxHours.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                        </div>
                    </div>
                    <div class="col-6 form-row mt-0">
                        <input type="text" name="maxMinutes" class="form-control" placeholder="{{this.msgServ.placeholderdisp('minutes')}}" id="maxMinutes" formControlName="maxMinutes" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.maxMinutes?.dirty || f.maxMinutes?.touched) &&  f.maxMinutes?.errors}" (ngModelChange)="validateMinMax();validateMaxTotal();maxLimit('maxHours','maxMinutes',false);checkValidations()">
                        <div *ngIf="(saveBtnClicked || f.maxMinutes?.dirty || f.maxMinutes?.touched) && f.maxMinutes?.errors"
                        class="invalid-feedback">
                          <div *ngIf="f.maxMinutes?.errors?.required">
                            {{this.msgServ.validationDisplay('required')}}</div>
                          <div *ngIf="!f?.maxMinutes?.errors?.pattern && f.maxMinutes.hasError('maxValue')">{{this.msgServ.fieldlengthvalidation('hours',4)}}</div>
                          <div *ngIf="!f?.maxMinutes?.errors?.pattern && f.maxMinutes.hasError('minMaxError')">{{this.msgServ.fieldlengthvalidation('minGreat','minimum short leave hours in one request')}}</div>
                          <div *ngIf="!f?.maxMinutes?.errors?.pattern && f.maxMinutes.hasError('max')">{{this.msgServ.fieldlengthvalidation('minutes',59)}}</div>
                          <div *ngIf="f?.maxMinutes?.errors?.pattern || notanumber(f?.maxMinutes.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
              <label for="totalHoursLabel" class="form-label required">Total short leave hours</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Define the maximum hours of short leave allowed within your chosen limit (weekly/monthly)" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12">
                <div class="align-items-start d-flex flex-nowrap row row-16">
                    <div class="col-6 form-row mt-0">
                        <input type="text" name="totalHours" class="form-control" placeholder="{{this.msgServ.placeholderdisp('hours')}}" id="totalHours" formControlName="totalHours" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.totalHours?.dirty || f.totalHours?.touched) && f.totalHours?.errors}" (ngModelChange)="validateMaxTotal();checkValidations()">
                        <div *ngIf="(saveBtnClicked || f.totalHours?.dirty || f.totalHours?.touched) && f.totalHours?.errors"
                        class="invalid-feedback">
                          <div *ngIf="f.totalHours?.errors?.required">
                            {{this.msgServ.validationDisplay('required')}}</div>
                            <div *ngIf="!f?.totalHours?.errors?.pattern && f.totalHours.hasError('maxValidation')">{{this.msgServ.shortLeaveMessage('maximum',f.short_leave_limit_type.value)}}</div>
                            <div *ngIf="!f?.totalHours?.errors?.pattern && f.totalHours.hasError('minValidation')">{{this.msgServ.shortLeaveMessage('minimum',f.short_leave_limit_type.value)}}</div>
                            <div *ngIf="!f?.totalHours?.errors?.pattern && f.totalHours.hasError('maxTotalError')">{{this.msgServ.fieldlengthvalidation('minGreat','maximum short leave hours in one request')}}</div>
                            <div *ngIf="f?.totalHours?.errors?.pattern || notanumber(f?.totalHours.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                        </div>
                    </div>
                    <div class="col-6 form-row mt-0">
                        <input type="text" name="totalMinutes" class="form-control" placeholder="{{this.msgServ.placeholderdisp('minutes')}}" id="totalMinutes" formControlName="totalMinutes" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.totalMinutes?.dirty || f.totalMinutes?.touched) && f.totalMinutes?.errors}" (ngModelChange)="validateMaxTotal();checkValidations()">
                        <div *ngIf="(saveBtnClicked || f.totalMinutes?.dirty || f.totalMinutes?.touched) && f.totalMinutes?.errors"
                        class="invalid-feedback">
                          <div *ngIf="f.totalMinutes?.errors?.required">
                            {{this.msgServ.validationDisplay('required')}}</div>
                            <div *ngIf="!f?.totalMinutes?.errors?.pattern && f.totalMinutes.hasError('maxValidation')">{{this.msgServ.shortLeaveMessage('maximum',f.short_leave_limit_type.value)}}</div>
                            <div *ngIf="!f?.totalMinutes?.errors?.pattern && f.totalMinutes.hasError('minValidation')">{{this.msgServ.shortLeaveMessage('minimum',f.short_leave_limit_type.value)}}</div>
                            <div *ngIf="!f?.totalMinutes?.errors?.pattern && f.totalMinutes.hasError('maxTotalError')">{{this.msgServ.fieldlengthvalidation('minGreat','maximum short leave hours in one request')}}</div>
                          <div *ngIf="!f?.totalMinutes?.errors?.pattern && f.totalMinutes.hasError('max')">{{this.msgServ.fieldlengthvalidation('minutes',59)}}</div>
                          <div *ngIf="f?.totalMinutes?.errors?.pattern || notanumber(f?.totalMinutes.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <label for="backdated_allowed" class="form-label required">Backdated short leave application allowed </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes or no if you allow the employees to apply for backdated short leaves." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-0 form-row">
                <div class="form-check form-check-inline">
                    <input name="allow_backdated_request" type="radio" id="back_yes" class="form-check-input" [value]="true" formControlName="allow_backdated_request" (click)="clearFormData()">
                    <label for="back_yes" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="allow_backdated_request" type="radio" id="back_no" class="form-check-input" [value]="false" formControlName="allow_backdated_request" (click)="clearFormData()">
                    <label for="back_no" class="form-check-label">No </label>
                </div>
            </div>
            <ng-container *ngIf="f.allow_backdated_request.value == true">
              <div class="col-12">
                <label for="backdated_application" class="form-label required">Backdated short leave application </label>
              </div>
              <div class="col-12 mt-0 form-row">
                  <div class="form-check form-check-inline">
                      <input name="backdated_type" type="radio" id="back_short_rev" class="form-check-input" [value]=true formControlName="backdated_type" (click)="setRequired(false);">
                      <label for="back_short_rev" class="form-check-label">As per the reversal </label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input name="backdated_type" type="radio" id="back_short_day" class="form-check-input" [value]=false formControlName="backdated_type" (click)="setRequired(true);">
                      <label for="back_short_day" class="form-check-label">Day </label>
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Define the no. of days the employee is allowed to apply for backdated short leave." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                  </div>
              </div>
              <div class="col-md-6" *ngIf="f.backdated_type.value == false">
                  <input type="text" name="backdated_days" formControlName="backdated_days" class="form-control" pattern="^(0|[1-9]\d*)$" placeholder="{{this.msgServ.placeholderdisp('days')}}" id="day_input" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.backdated_days?.dirty || f.backdated_days?.touched) &&  f.backdated_days?.errors}">
                  <div *ngIf="(saveBtnClicked || f.backdated_days?.dirty || f.backdated_days?.touched) && f.backdated_days?.errors"
                    class="invalid-feedback">
                      <div *ngIf="f.backdated_days?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                      <div *ngIf="!f?.backdated_days?.errors?.pattern && f.backdated_days.hasError('min')">{{this.msgServ.fieldlengthvalidation('minvalue',0)}}</div>
                      <div *ngIf="!f?.backdated_days?.errors?.pattern && f.backdated_days.hasError('max')">{{this.msgServ.fieldlengthvalidation('days',365)}}</div>
                      <div *ngIf="f?.backdated_days?.errors?.pattern || notanumber(f?.backdated_days.errors?.pattern?.actualValue)">{{this.msgServ.validationDisplay('pattern')}}</div>
                  </div>
              </div>
            </ng-container>
            <div class="col-12">
                <label for="can_emp" class="form-label required">Can employee mark more than one short leave in a calendar day
                ? </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select 'yes' If you allow employee to mark any number of short leave requests in one day within the limit set. Select 'no' If you allow employee to apply only one request in one day." popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-0 form-row">
                <div class="form-check form-check-inline">
                    <input name="allow_more_than_one_leave_day" type="radio" id="mark_yes" class="form-check-input" [value]="true" formControlName="allow_more_than_one_leave_day">
                    <label for="mark_yes" class="form-check-label">Yes </label>
                </div>
                <div class="form-check form-check-inline">
                    <input name="allow_more_than_one_leave_day" type="radio" id="mark_no" class="form-check-input" [value]="false" formControlName="allow_more_than_one_leave_day">
                    <label for="mark_no" class="form-check-label">No </label>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid || saveBtnClicked" (click)="submitForm()">NEXT</button>
    </footer>
</form>
