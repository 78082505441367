import { Component, OnInit, ViewChild } from '@angular/core';
import { Form16Service } from 'src/app/@core/services/form16.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AppService } from 'src/app/app.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';


@Component({
  selector: 'app-form16',
  templateUrl: './form16.component.html',
  styleUrls: ['./form16.component.scss']
})
export class Form16Component implements OnInit {
  offset                      = 0;
  searchKeyword="";
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  form16DetailsList:any       = [];
  infinityloader              = false;
  permissions:any             = [];
  form16List:any              = [];
  loader :any                 = false;
  nodatafound                 = false;
  currentExtension            = "pdf";
  searchlistdata:any          = [] ;
  // currentFilterYear:any       = "all";
  currentFilterYear:any       = this.appService.getFinancialYear();
  modalToggle: boolean        = false
  errorToggle                 = false;
  fileName:any                = '';
  loader1                     = false
  currentDocName              = 'Form 16'
  selectedYear :any           = this.appService.getFinancialYear();
  paramdata:any;



  selectkey =this.messageService.selectplaceholddisplay('Year');
  @ViewChild(SearchbarComponent) child:any;
  constructor(private form16Service:Form16Service,private authService:AuthService,public appService: AppService,public messageService : MessageService) { }


  ngOnInit(): void {
    this.getPermission();
    this.getGeneratedData();
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getGeneratedData(){
    this.loader = true;

    this.paramdata = {
      'employee':this.authService.isEmployeeId,
      'offset'  :this.defaultlimit,
      'limit'   : this.offset
    }
    if(this.currentFilterYear!='')
    this.paramdata["year"] = this.currentFilterYear;

    this.form16Service.getMyfinanceForm16(this.paramdata,this.searchKeyword).subscribe((res:any)=>{
      this.listlength = res.body.data.length;
      if(this.offset ==0){
        this.form16List = [];
      }
      if (this.listlength == 0) {
        this.nodatafound = this.offset == 0 ? true :false;
      }else {
        this.nodatafound       = false;
        this.form16DetailsList = res.body.data;

      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;

    })
    this.form16Service.myFinanceSearchDrpdown(this.authService.isEmployeeId).subscribe((res:any)=>{

      for(let i=0; i< res.body.length; i++){
        res.body[i]['id'] = res.body[i]['year']
      }
      this.searchlistdata = res.body;

    })

  }
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getGeneratedData();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.form16List.push(this.form16DetailsList[i]);
    }

  }
  // downloadDoc(data:any,filename:any){
  //   this.currentExtension = data.split('.').pop();
  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(data,filename+'.'+this.currentExtension);

  // }
  searchresults(data:any){
    this.loader       = true;
    this.nodatafound  = false;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset     = 0;
      this.form16List = [];
      this.getGeneratedData();
    }else{
      this.paramdata = {
        'employee':this.authService.isEmployeeId,
        'year'    : data
      }
      this.form16Service.getMyfinanceForm16(this.paramdata,this.searchKeyword).subscribe((res:any)=>{
          this.form16List = res.body.data;
          this.loader     = false;
        });
    }
  }
  yearFilter(year:any){
    this.form16List        = [];
    this.offset            = 0;
    this.currentFilterYear = year;
    this.getGeneratedData();
    // this.child.clearBar();
  }
  searchform16(searchKeyword:any){
    this.loader = true;
    this.offset           = 0;
    this.form16List= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getGeneratedData();
    }
  }

  viewDocument(filename:any){
    this.modalToggle      = true;
    this.fileName         = filename;
  }

  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }
  yearChange(value:any){
    this.currentFilterYear  = value;
    this.getGeneratedData();

  }
}
