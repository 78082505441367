<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Details </h5>
    <button class="btn-reset text-light-600" routerLink="/manage-advance"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <div class="container-fluid p-32 form-container">
      <div class="row row-24">
        <div class="col-12">
          <p class="text-uppercase fw-500 mb-0">OUTSTANDING advance details </p>
        </div>
        <div class="col-12">
          <div class="card card-c2 overflow-hidden">
            <div class="table-responsive">
              <table class="table  td-12 th-12 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th class="fw-600" scope="col">DATE</th>
                    <th class="fw-600" scope="col">OPENING OUTSTANDING BALANCE</th>
                    <th class="fw-600" scope="col">ADVANCE AMOUNT CREDITED</th>
                    <th class="fw-600" scope="col">CLOSING OUTSTANDING BALANCE</th>
                    <th class="fw-600" scope="col">REASON</th>
                  </tr>
                </thead>
                <tbody class="cell-16">
                  <tr>
                    <td>12/07/2023</td>
                    <td>1000</td>
                    <td>2000</td>
                    <td>3000</td>
                    <td>Advance dispensed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>