import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
@Component({
  selector: 'app-attendance-capture-policy-details',
  templateUrl: './attendance-capture-policy-details.component.html',
  styleUrls: ['./attendance-capture-policy-details.component.scss']
})
export class AttendanceCapturePolicyDetailsComponent implements OnInit {
  @Input() editflag: any
  @Input() id: any
  @Input() formGp: any;
  @Output() submitmethod = new EventEmitter();
  value: any
  attendanceUnit: any = []
  saveBtnClicked: boolean = false
  private subjectKeyUp = new Subject<any>();
  maxBackDays = [0, 1, 2, 3, 4, 5, 6]
  restrictedValue = ['Holidays & Weekends', 'Holidays', 'Weekends', 'None']
  trackIntervel = [5,10,15,20,30, 45, 60]
  constructor(public fb: FormBuilder,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public existDataService: ExistDatasService) { }
  ngOnInit(): void {

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d: any) => {
      this.existService();
      this.multipleSetRequired();

    })
  }
  get f() { return this.formGp.controls; }
  // Simplified function to use for validation
  existService() {
    if (!this.editflag) {
      this.value = {
        "page": "AttendanceCapture",
        "field": "name",
        "value": this.f.name.value
      }
    } else {
      this.value = {
        "id": this.id,
        "page": "AttendanceCapture",
        "field": "name",
        "value": this.f.name.value
      }
    }
    if (this.value.value != ' ' || this.value.value != '') {
      this.existDataService.getSpecificName(this.value).subscribe((res: any) => {
        this.attendanceUnit = res.body
        if (this.attendanceUnit.data === 'invalid') {
          this.f.name.setErrors({
            'exist': true
          })
        }
      })
    }
  }
  // For validation of keyup(name)
  keyUp() {
    this.subjectKeyUp.next(this.f.name.value)
  }
  resetRequestdaytype(val: any, from: any) {
    if (val == 'max_wfh_request_day_type'&& this.formGp.get('is_wfh_allowed').value) {
      if (from == 'click')
        this.formGp.get('max_wfh_request_days')?.reset(null)
      if (this.formGp.get('max_wfh_request_day_type')?.value == true)
        this.formGp.get('max_wfh_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      else
        this.formGp.get('max_wfh_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(7), Validators.min(1)])
    }
    if (val == 'max_on_duty_day_type' && this.formGp.get('on_duty').value) {

      if (from == 'click')
        this.formGp.get('max_on_duty_request_days')?.reset(null)
      if (this.formGp.get('max_on_duty_day_type')?.value == true)
        this.formGp.get('max_on_duty_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      else
        this.formGp.get('max_on_duty_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(7), Validators.min(1)])
    }
    if (val == 'max_attend_regular_day_type'  && this.formGp.get('is_attend_regular_allowed').value) {
      if (from == 'click')
        this.formGp.get('max_attend_regular_request_days')?.reset(null)
      if (this.formGp.get('max_attend_regular_day_type')?.value == true)
        this.formGp.get('max_attend_regular_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      else
        this.formGp.get('max_attend_regular_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(7), Validators.min(1)])
    }
  }
  is_ip_restrictedArr: any = ['from_ip_range', 'to_ip_range']
  multipleSetRequired() {
    if (this.formGp.get('is_ip_restricted')?.value == true) {
      if (this.attendance_capture_iprange().length == 0) {
        this.addIps();
      }
      this.is_ip_restrictedArr.forEach((item: any) => {
        this.attendance_capture_iprange().controls[0].get(item)?.setValidators([Validators.required, Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')])
        this.attendance_capture_iprange().controls[0].get(item)?.updateValueAndValidity()
      })
    } else {
      if (this.attendance_capture_iprange().length !== 0) {
        this.is_ip_restrictedArr.forEach((item: any) => {
          this.attendance_capture_iprange().controls[0].get(item)?.clearValidators()
          this.attendance_capture_iprange().controls[0].get(item)?.reset(null)
        })
      }
      this.attendance_capture_iprange().clear();
    }
  }
  setResetfunction(val: any) {
    // if(val == 'max_on_duty_back_days'){
    //   this.formGp.get(val).reset(0)
    // }
    // else{
    this.formGp.get(val).reset(null)
    // }
  }
  setRequired(type: any, val: any) {
    if (type) {
      if (val == 'prior_day_for_on_duty' || val == 'prior_day_for_wfh') {
        this.formGp.get(val)?.setValidators([Validators.required, NumberpatternValidator(), Validators.maxLength(3), Validators.min(1)]);
      } else {
        this.formGp.get(val)?.setValidators([Validators.required]);
      }
    }
    else {
      this.formGp.get(val)?.clearValidators()
    }
    this.formGp.get(val)?.updateValueAndValidity()
  }
  checksetRequired(type: any, val: any) {
    let getCheck = type?.target?.checked
    if (!getCheck && val == 'web_checkin' && this.formGp.controls.is_ip_restricted.value == true) {
      this.is_ip_restrictedArr.forEach((item: any) => {
        this.attendance_capture_iprange().controls[0].get(item)?.clearValidators()
        this.attendance_capture_iprange().controls[0].get(item)?.reset(null)
      })
      //  this.formGp.get('is_ip_restricted')?.reset(false);
    }
    if (getCheck && val == 'max_on_duty_day_type') {

      if (this.formGp.get('max_on_duty_day_type')?.value == true)
        this.formGp.get('max_on_duty_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      else
        this.formGp.get('max_on_duty_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(7), Validators.min(1)])
      this.formGp.get('max_on_duty_request_days')?.updateValueAndValidity()
    }
    else if (!getCheck && val == 'max_on_duty_day_type') {
      this.formGp.get('max_on_duty_back_days')?.clearValidators()
      this.formGp.get('max_on_duty_request_days')?.clearValidators()
      this.formGp.get('prior_day_for_on_duty')?.clearValidators()
      this.formGp.get('max_on_duty_day_type')?.reset(false)
      this.formGp.get('max_on_duty_request_days')?.reset(null)
      this.formGp.get('is_allow_backdated_onduty')?.reset(false)
      this.formGp.get('max_on_duty_back_days')?.reset(null)
      this.formGp.get('is_future_on_duty')?.reset(true)
      this.formGp.get('is_prior_notice_for_on_duty')?.reset(false)
      this.formGp.get('prior_day_for_on_duty')?.reset(null)
    }
    else if (getCheck && val == 'max_wfh_request_day_type') {
      if (this.formGp.get('max_wfh_request_day_type')?.value == true)
        this.formGp.get('max_wfh_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      else
        this.formGp.get('max_wfh_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(7), Validators.min(1)])
      this.formGp.get('max_wfh_request_days').updateValueAndValidity()
      this.formGp.get('request_raising_restricted')?.setValidators([Validators.required])
      this.formGp.get('request_raising_restricted')?.updateValueAndValidity()
      this.formGp.get('request_raising_restricted')?.setValue('Holidays & Weekends');
    }
    else if (!getCheck && val == 'max_wfh_request_day_type') {
      this.formGp.get('max_wfh_request_days')?.clearValidators()
      this.formGp.get('max_wfh_back_days')?.clearValidators()
      this.formGp.get('prior_day_for_wfh')?.clearValidators()
      this.formGp.get('request_raising_restricted')?.clearValidators()
      this.formGp.get('max_wfh_request_day_type')?.reset(false)
      this.formGp.get('max_wfh_request_days')?.reset(null)
      this.formGp.get('is_backdated_wfh_allowed')?.reset(false)
      this.formGp.get('max_wfh_back_days')?.reset(null)
      this.formGp.get('is_future_wfh')?.reset(true)
      this.formGp.get('is_prior_notice_for_wfh')?.reset(false)
      this.formGp.get('prior_day_for_wfh')?.reset(null)
      this.formGp.get('request_raising_restricted')?.reset(null)
    }
    else if (getCheck && val == 'max_attend_regular_day_type') {
      if (this.formGp.get('max_attend_regular_day_type')?.value == true)
        this.formGp.get('max_attend_regular_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      else
        this.formGp.get('max_attend_regular_request_days')?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(7), Validators.min(1)])
      this.formGp.get('max_attend_regular_request_days')?.updateValueAndValidity()
    }
    else if (!getCheck && val == 'max_attend_regular_day_type') {
      this.formGp.get('max_attend_regular_back_days')?.clearValidators()
      this.formGp.get('max_attend_regular_request_days')?.clearValidators()
      this.formGp.get('max_attend_regular_day_type')?.reset(false)
      this.formGp.get('max_attend_regular_request_days')?.reset(null)
      this.formGp.get('is_backdated_attend_regular_allowed')?.reset(false)
      this.formGp.get('max_attend_regular_back_days')?.reset(null)
      this.formGp.get('is_future_attend_regular_allowed')?.reset(false)

    }
  }
  validateForm() {
    this.saveBtnClicked = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }
  attendance_capture_iprange(): FormArray {
    return this.formGp.get("attendance_capture_iprange") as FormArray
  }
  newIPranges(): FormGroup {
    return this.fb.group({
      "from_ip_range": [null, [Validators.required, Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
      "to_ip_range": [null, [Validators.required, Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
    })
  }
  addIps() {
    this.attendance_capture_iprange().push(this.newIPranges());
  }
  removeIp(i: any) {
    this.attendance_capture_iprange().removeAt(i);
  }
  mobileValidator(parent: any) {
    if (parent == 'mobile_checkin') {
      this.mobileCheckValidator(parent, 'location_captured');
      this.mobileCheckValidator(parent, 'enable_geo_fencing');
      this.mobileCheckValidator(parent, 'enable_realtime_tracking');
      this.mobileCheckValidator(parent, 'idle_interval_threshold');
      // this.mobileCheckValidator(parent, 'track_intravel');
      this.mobileCheckValidator(parent, 'location_request_approves');
      // this.mobileCheckValidator(parent, 'live_location_notify');
    }
    else if (parent == 'enable_geo_fencing') {
      this.mobileCheckValidator(parent, 'location_request_approves');
    }
    else if(parent=='location_captured'){
      this.mobileCheckValidator(parent,'enable_geo_fencing')
    }
    else {
      // this.mobileCheckValidator(parent, 'track_intravel');
      this.mobileCheckValidator(parent, 'idle_interval_threshold');
    }
  }
  mobileCheckValidator(parent: any, child: any) {
    if (parent == 'mobile_checkin') {
      if (this.formGp.get(parent).value == false) {
        // if (child != 'track_intravel')
        if (child != 'idle_interval_threshold')
          this.formGp.get(child).setValue(false)
        else
          this.formGp.get(child).setValue(15)
      }
    }
    else {
      // if (child != 'track_intravel')
      if (child != 'idle_interval_threshold')
        this.formGp.get(child).setValue(false)
      else{
        this.formGp.get(child).setValue(15)
        this.formGp.get(child).setValidators([Validators.required])
      }
    }
    this.formGp.get(child)?.updateValueAndValidity()
  }

  //To check same range IP validation
  showValidation(index:any){

    for(let i=0;i<this.attendance_capture_iprange()?.length;i++){
      if(i != index){
        var frmInd = Number(this.attendance_capture_iprange().controls[index]?.get('from_ip_range')?.value?.split(".")
        .map((d:any) => (d).substring(-3) )
        .join(""))
        var toInd = Number(this.attendance_capture_iprange().controls[index]?.get('to_ip_range')?.value?.split(".")
        .map((d:any) => (d).substring(-3) )
        .join(""))
        var frmI = Number(this.attendance_capture_iprange().controls[i]?.get('from_ip_range')?.value?.split(".")
        .map((d:any) => (d).substring(-3) )
        .join(""))
        var toI = Number(this.attendance_capture_iprange().controls[i]?.get('to_ip_range')?.value?.split(".")
        .map((d:any) => (d).substring(-3) )
        .join(""))
        if( ( frmInd >= frmI &&  frmInd <= toI) || ( toInd >= frmI &&  toInd <= toI)  ){
            this.attendance_capture_iprange().controls[index]?.get('from_ip_range')?.setErrors({
              'overlap' : true
            })
        }else{
          this.attendance_capture_iprange().controls[index]?.get('from_ip_range')?.setErrors(null)
        }
      }
    }
  }

  resetSelfie(event:any){
    if(event.target.checked){
      this.addRequired('is_photo_need_verification')
      this.addRequired('is_save_photo')
    } else {
      this.removeRequired('is_photo_need_verification')
      this.removeRequired('is_save_photo')
    }
    this.formGp.get('is_save_photo').setValue(false)
    this.formGp.get('is_photo_need_verification').setValue(false)
    this.resetSave()
    this.resetVerify()

  }
  resetSave(){
    this.formGp.get('save_photo_checkin_from_mobile').setValue(false)
    this.formGp.get('save_photo_checkin_from_web').setValue(false)
    if(this.formGp.get('is_selfie_enabled').value == true)
      this.ValidateSaveCapture()
  }
  resetVerify(){
    this.formGp.get('verify_photo_checkin_from_mobile').setValue(false)
    this.formGp.get('verify_photo_checkin_from_web').setValue(false)
    if(this.formGp.get('is_selfie_enabled').value == true)
      this.ValidateVerifyCapture()
  }

  ValidateVerifyCapture(){
    if(this.formGp.get('is_photo_need_verification').value == true )
      this.removeRequired('is_save_photo')
    else
      this.addRequired('is_save_photo')


    if(this.formGp.get('is_photo_need_verification').value == true &&this.formGp.get('verify_photo_checkin_from_mobile').value == false && this.formGp.get('verify_photo_checkin_from_web').value == false){
      this.addRequired('verify_photo_checkin_from_mobile')
      this.addRequired('verify_photo_checkin_from_web')
    } else {
      this.removeRequired('verify_photo_checkin_from_mobile')
      this.removeRequired('verify_photo_checkin_from_web')
    }
  }
  ValidateSaveCapture(){
    if(this.formGp.get('is_save_photo').value == true)
      this.removeRequired('is_photo_need_verification')
    else
      this.addRequired('is_photo_need_verification')

    if(this.formGp.get('is_save_photo').value == true &&this.formGp.get('save_photo_checkin_from_mobile').value == false && this.formGp.get('save_photo_checkin_from_web').value == false){
      this.addRequired('save_photo_checkin_from_mobile')
      this.addRequired('save_photo_checkin_from_web')
    } else {
      this.removeRequired('save_photo_checkin_from_mobile')
      this.removeRequired('save_photo_checkin_from_web')
    }
  }
  addRequired(item:any){
    this.formGp.get(item).setValidators([Validators.requiredTrue])
    this.formGp.get(item)?.updateValueAndValidity()
  }
  removeRequired(item:any){
    this.formGp.get(item).clearValidators()
    this.formGp.get(item)?.updateValueAndValidity()
  }
  backDatedValidator(mainParent:any,parent:any,requiredcontrol:any,notrequiredcontrol:any){
    if(this.formGp.get(mainParent).value==true)
    {
      this.formGp.get(notrequiredcontrol)?.clearValidators()
      this.formGp.get(notrequiredcontrol)?.setValue(null)
      if(this.formGp.get(parent).value==false){
        this.formGp.get(requiredcontrol)?.setValidators([Validators.required, NumberpatternValidator(), Validators.max(31), Validators.min(1)])
      }
      else{

        this.formGp.get(requiredcontrol)?.setValidators([Validators.required])
      }
      this.formGp.get(requiredcontrol)?.updateValueAndValidity()
      this.formGp.get(notrequiredcontrol)?.updateValueAndValidity()
    }

  }
  backDatedRestriction(parent:any,child:any){
    if(parent=='is_allow_backdated_onduty'){
      if(this.formGp.get(parent).value==true){
        if(this.formGp.get(child).value==true){
          this.backDatedValidator(parent,child,'backday_month','backday_day')
        }
        else{

          this.backDatedValidator(parent,child,'backday_day','backday_month')

        }
      }
      else{

       this.clearBackdatedValidator('backday_month')
       this.clearBackdatedValidator('backday_day')
       this.formGp.get(child).setValue(true)

      }
    }
    else if(parent=='is_backdated_wfh_allowed'){
      if(this.formGp.get(parent).value==true){
        if(this.formGp.get(child).value==true){
          this.backDatedValidator(parent,child,'wfh_month','wfh_day')
        }
        else{
          this.backDatedValidator(parent,child,'wfh_day','wfh_month')

        }
      }
      else{
       this.clearBackdatedValidator('wfh_month')
       this.clearBackdatedValidator('wfh_day')
       this.formGp.get(child).setValue(true)


      }
    }
    else{
      if(this.formGp.get(parent).value==true){
        if(this.formGp.get(child).value==true){
          this.backDatedValidator(parent,child,'regular_month','regular_day')
        }
        else{
          this.backDatedValidator(parent,child,'regular_day','regular_month')

        }
      }
      else{
       this.clearBackdatedValidator('regular_month')
       this.clearBackdatedValidator('regular_day')
       this.formGp.get(child).setValue(true)


      }
    }
  }
  clearBackdatedValidator(control:any){

    this.formGp.get(control)?.clearValidators()
    this.formGp.get(control)?.setValue(null)
    this.formGp.get(control)?.updateValueAndValidity()

  }
  // End
  createRange(num:number,start=1){
    var items: number[] = [];
    for(var i = start; i <= num; i++){
      items.push(i);
    }
    return items;
  }
  displaymonth(i:number) {
    if(i<=1)
      return i+" month";
    else
      return i+" months";
  }
}
