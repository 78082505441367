<div class="container-fluid p-24">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Tax Slip</h3>
      <p class="text-helper mb-md-0">Tax slips are a summary of your total earnings and deductions for the fiscal year.
      </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='taxslipsearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchtaxslip($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16rem" *ngIf="loader">
    <app-common-loader class="col-md-3 col-sm-6" [cardType]="'card6'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <ng-container *ngIf="!loader">
    <div *ngIf="!template_list_flag;else all_data "  class="row " style="height: calc(100vh - 15rem);">
      <!-- <div class="col-12 text-center">
                <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
                    style="width: 27.375rem;">
                <p class="text-uppercase text-light-400 fw-700 fs-14">No data found</p>
             <ng-container *ngIf="permissions.a"><a class="text-uppercase link-primary fw-700 fs-14" (click)="addholidayForm()">Add NOW</a></ng-container>
            </div> -->
      <app-nodata-view class="vstack" [noDataImg]="'bg10.png'"  [noDataText]="'No data found'" [hasPermission]="false"> </app-nodata-view>

    </div>
    <ng-template #all_data>
      <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        [fromRoot]="true" (scrolled)="onScrollDown()">
        <ng-container *ngFor="let taxslip of taxSlipList">
          <div class="col-md-3 col-sm-6 ">
            <div class="card  card-c2 card-hover" (click)="viewDocument(taxslip.pdf,taxslip.template_name)">
              <div class="card-body p-12">
                <div class="img-hover">
                  <div class="img-desc"> <i class="icon-eye"></i> Preview</div>
                  <img alt="" class="card-img" src="{{taxslip.image}}">
                </div>
              </div>
              <div class="card-body indicater1 p-16">
                <div class="fw-500 fs-14 pb-1 text-trim">{{taxslip.template_name}}</div>
                <!-- <div class="fs-12 fw-500 text-light-400 text-trim-2">{{taxslip.description}}</div> -->
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Loader updations -->
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card6'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader" >
            <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
        </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->

  </ng-container>


</div>

<!-- Alert for employee request start -->
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{empalertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-pdfviewer modal-custom {{modalToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">{{currentDocName}}</h4>
        <i (click)="modalToggle = false" class="ms-auto bi b bi-x-lg text-light-500"></i>
      </div>
      <div class="modal-body">
        <div class="pdf-container">
          <pdf-viewer [src]="fileName" [original-size]="false" [render-text]="true"
            (on-progress)="showGif($event)"></pdf-viewer>
        </div>
      </div>

    </div>
  </div>
</div>
