import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-upload-multiple-attachments',
  templateUrl: './upload-multiple-attachments.component.html',
  styleUrls: ['./upload-multiple-attachments.component.scss']
})
export class UploadMultipleAttachmentsComponent implements OnInit {

  @Input() from:any;
  @Input() uploadeAttachments:any;
  @Input() fileType:any;
  @Input() receiptId:any;
  @Input() finalReceiptAttachment:any;

  @Output() closeAttachment  = new EventEmitter();
  @Output() saveAttachment   = new EventEmitter();

  attachmentArray:any     = [];
  attachmentNameArray:any = [];
  fileError               = false;
  fileErrorMsg: any       = "";
  docLoader               = false;

  disable                 = false;

  uploadedFile:any           = [];

  // File Upload
  modalToggle                     = false;
  allowedExtension                = "";
  alertMsg:any                    = '';
  alertToggle                     = false;
  fileName                        = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;

  constructor(
    private service:ExpenseReportRequestService,
    public messageService : MessageService,
    public appservice: AppService,
  ) { }

  ngOnInit(): void {
  }


  getURL(event: any) {
    this.attachmentArray?.push(event);
    this.fileError        = false;
    this.uploadedFile[this.uploadedFile.length-1]['url']    = event;
    this.uploadedFile[this.uploadedFile.length-1]['loader'] = false;
  }

  getfileformat(event: any) {

    if (event == false) {
      this.fileError    = true;
      this.fileErrorMsg = this.messageService.validateFileFormat('pdf,doc,img');
    }
  }

  getvalidsize(event: any) {

    this.fileError    = true;
    this.fileErrorMsg = event;
  }


  getFilename(event: any) {

    this.uploadedFile[this.uploadedFile.length-1]['size'] = this.appservice.formatSizeUnits(event.size);
  }

  getloader(event: any) {
    this.docLoader = event;


    if(event==true){

      let file = {
        loader  : event,
        url     : '',
        size    : ''
      }
      this.uploadedFile.push(file)
    }
    else{
      this.uploadedFile[this.uploadedFile.length-1]['loader'] = event;
    }
  }

  deleteAttachment(index:any){
    if (index !== -1) {
      this.attachmentArray?.splice(index, 1);
      this.attachmentNameArray?.splice(index, 1);
    }

    this.uploadedFile.splice(index, 1);
  }


  close(){
    this.closeAttachment.emit();
  }

  saveAttachments(){
    if(this.from!='profile'){

      if(this.finalReceiptAttachment?.length == 0){
        this.finalReceiptAttachment = this.attachmentArray ;
      }else{
        this.finalReceiptAttachment = [...this.attachmentArray, ...this.finalReceiptAttachment];
      }

      this.service.receiptUpdate(this.receiptId, {'attachments':this.finalReceiptAttachment}).subscribe((res: any) => {
        this.saveAttachment.emit({'attachments': this.finalReceiptAttachment});
      },(err: any) => {
        this.disable = false;
      });
    }
    else
      this.saveAttachment.emit({'attachments': this.attachmentArray});
  }

   /********* VIEW DOCUMENT FUNCTION ****************/
   viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

}
