import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-view-comp-off-request',
  templateUrl: './view-comp-off-request.component.html',
  styleUrls: ['./view-comp-off-request.component.scss']
})
export class ViewCompOffRequestComponent implements OnInit {

  @Input()viewData:any;

  constructor(
    public appservice:AppService,
  ) { }

  ngOnInit(): void {
  }

}
