import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OtpService } from '../../services/otp.service'
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { OrganizationService } from '../../services/organization.service';
import { ForgotPasswordService } from '../../services/forgot-password.service'

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OTPComponent implements OnInit {

  public otp_status: boolean = true;
  public input_1: string = '';
  public input_2: string = '';
  public input_3: string = '';
  public input_4: string = '';
  public input_5: string = '';
  public input_6: string = '';
  isRequired:boolean     = true;
  submitted              = false;
  invalid:boolean        = false;
  currentlogodata:any    = [];
  loader                 = true;

  emailId:any;
  validArray:any = [{
    'input_1':false,
    'input_2':false,
    'input_3':false,
    'input_4':false,
    'input_5':false,
    'input_6':false,

  }]
  onInputEntry(event:any, nextInput:any) {
    let input = event.target;
    let length = input.value.length;
    let maxLength = input.attributes.maxlength.value;
    if (length >= maxLength) {
      nextInput.focus();
    }
  }
  constructor(
    private otpService: OtpService,
    public router: Router,
    public messageService : MessageService,
    public appservice:AppService,
    public OrganizationService: OrganizationService,
    private forgotpasswordService: ForgotPasswordService,

  ) { }

  ngOnInit(): void {
    this.emailId = localStorage.getItem('email');
    this.getorgdata();
  }

  otpClick() {
    this.submitted = true;
    if(this.input_1!='' && this.input_2!='' && this.input_3!='' && this.input_4!='' && this.input_5!='' && this.input_6!='' ){
      this.isRequired = false;
      this.otpService.verfyOTP({username: localStorage.getItem('user_id'), otp: this.input_1 + this.input_2 + this.input_3 + this.input_4 + this.input_5 + this.input_6})
      .subscribe((res: any) => {
        if (res.status == '1') {

          this.otp_status = true;

          localStorage.setItem('userData', JSON.stringify(res.data));
          this.router.navigate(['newpassword/']);
        } else {
          this.otp_status = false;
          this.submitted  = false;

        }
      })
    }
    else{
        this.isRequired = true;
    }
  }
  nameChanged(event:any,key:any) {
    let regexp_number   = new RegExp('[0-9]');
    let test_number     = regexp_number.test(event);
    this.otp_status     = true;
    let count 			= 0;

    if (!test_number && event.length) {
      this.invalid = true;
    }
    else
      this.invalid = false;
    this.validArray[0]['input_'+key] = this.invalid;
    Object.entries(this.validArray[0]).forEach(([key, value]) => {
      if(value==true)
      count ++;
    })
    if(count>0)
    this.invalid = true;

    if(this.input_1!='' && this.input_2!='' && this.input_3!='' && this.input_4!='' && this.input_5!='' && this.input_6!='' )
    this.isRequired = false;
    else{

      this.isRequired = true;
    }


  }
  getorgdata(){

    this.OrganizationService.getloginpagedata()
    .subscribe((res: any) => {
     this.currentlogodata = res.success;
     this.loader          = false;

    })
  }


  resendOtp(){
    this.forgotpasswordService.forgotPassword({'username' : this.emailId}).subscribe((res: any) => {

    })
  }

}
