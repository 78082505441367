<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row ">
      <p class="text-uppercase fw-500">Setup configuration</p>
    </div>
    <div class="row row-16" [formGroup]="settingsForm">
      <div class="col-12">
        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16 ">
            <div class="col-12  ">
              <p class="title mb-8">Asset</p>

            </div>
            <ng-container formArrayName="setting_data" *ngFor="let item of SettingsArray.controls; let i=index; ">
              <ng-container [formGroupName]="i">
                <div class="col-12">
                  <label for="" class="form-label ">How many days after DOJ, the task to assign asset for new joinee should be triggered?</label>
                  <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter after how many days  asset assignment task should initiate for a new employee.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>

                  <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('days')}} " id="" formControlName="assets_given_after_days" [ngClass]="{ 'is-invalid' : ( SettingsArray.controls[i].get('assets_given_after_days').dirty || SettingsArray.controls[i].get('assets_given_after_days').touched) && SettingsArray.controls[i].get('assets_given_after_days').errors}">
                  <div *ngIf="(SettingsArray.controls[i].get('assets_given_after_days').touched || SettingsArray.controls[i].get('assets_given_after_days').dirty) && SettingsArray.controls[i].get('assets_given_after_days').errors " class="invalid-feedback">
                    <div *ngIf="SettingsArray.controls[i].get('assets_given_after_days').errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="SettingsArray.controls[i].get('assets_given_after_days').errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                    </div>
                    <div *ngIf="!(SettingsArray.controls[i].get('assets_given_after_days').errors.pattern) && SettingsArray.controls[i].get('assets_given_after_days').errors.maxlength">{{this.messageService.fieldlengthvalidation('num','2')}}
                    </div>


                  </div>
                </div>

                <div class="col-12">
                  <label for="" class="form-label ">How many days before relieving, the task to recover assets from the employee should be triggered?</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter how many days prior the system should initiate task to recover asset from the relieving employee.
                      ' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                  <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('days')}} " id="" formControlName="assets_taken_before_days" [ngClass]="{ 'is-invalid' : ( SettingsArray.controls[i].get('assets_taken_before_days').dirty || SettingsArray.controls[i].get('assets_taken_before_days').touched) && SettingsArray.controls[i].get('assets_taken_before_days').errors}">
                  <div *ngIf="(SettingsArray.controls[i].get('assets_taken_before_days').touched || SettingsArray.controls[i].get('assets_taken_before_days').dirty) && SettingsArray.controls[i].get('assets_taken_before_days').errors " class="invalid-feedback">
                    <div *ngIf="SettingsArray.controls[i].get('assets_taken_before_days').errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="SettingsArray.controls[i].get('assets_taken_before_days').errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                    </div>
                    <div *ngIf="!(SettingsArray.controls[i].get('assets_taken_before_days').errors.pattern) && SettingsArray.controls[i].get('assets_taken_before_days').errors.maxlength">{{this.messageService.fieldlengthvalidation('num','2')}}
                    </div>
                  </div>
                </div>

                <!-- <div class="col-12">
                  <label for="" class="form-label ">How many days before the warranty expiration date, the notification should be triggered to the task owner?</label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter how many days prior warranty expiration notification will be triggered to the task owner.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                  <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('days')}} " id="" formControlName="warranty_notification_days" [ngClass]="{ 'is-invalid' : ( SettingsArray.controls[i].get('warranty_notification_days').dirty || SettingsArray.controls[i].get('warranty_notification_days').touched) && SettingsArray.controls[i].get('warranty_notification_days').errors}">
                  <div *ngIf="(SettingsArray.controls[i].get('warranty_notification_days').touched || SettingsArray.controls[i].get('warranty_notification_days').dirty) && SettingsArray.controls[i].get('warranty_notification_days').errors " class="invalid-feedback">
                    <div *ngIf="SettingsArray.controls[i].get('warranty_notification_days').errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="SettingsArray.controls[i].get('warranty_notification_days').errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                    </div>
                    <div *ngIf="!(SettingsArray.controls[i].get('warranty_notification_days').errors.pattern) && SettingsArray.controls[i].get('warranty_notification_days').errors.maxlength">{{this.messageService.fieldlengthvalidation('num','2')}}
                    </div>
                  </div>
                </div> -->
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto  text-uppercase" [disabled]="(settingsForm.invalid || selectedComp.length==0) || saveClicked" (click)="submitMethod()">SAVE</button>
</footer>
</form>
