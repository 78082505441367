import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';

@Component({
  selector: 'app-fnf-it-reimbursement-approval',
  templateUrl: './fnf-it-reimbursement-approval.component.html',
  styleUrls: ['./fnf-it-reimbursement-approval.component.scss']
})
export class FnfItReimbursementApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() reimbursement:any;
  @Input() currency:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  requestitem:any = [];

  constructor(
    public finalSettlementService: FinalSettlementService,
  ) { }

  ngOnInit(): void {
  }

  // test start

  refreshInbox(event: boolean){
      this.fnfApprovalViewClose.emit(event)
  }

  // test end

}
