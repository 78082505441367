<form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Summary</p>
        </div>
        <div class="row row-16">
            <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                    <div class="row row-24 ">
                        <div class="col-12 "><span class="title">CHALLAN DETAILS</span></div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Payroll month</div>
                            <div class="detail-desc">{{formGp.value?.payroll_month}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Voucher no</div>
                            <div class="detail-desc">{{formGp.value?.voucher_no}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Amount</div>
                            <div class="detail-desc">{{formGp.value?.amount | currency: currency}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Voucher date</div>
                            <div class="detail-desc">{{this.appService.dateFormatDisplay(formGp.value?.voucher_date)}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Branch code</div>
                            <div class="detail-desc">{{formGp.value?.branch_code}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Bank details</div>
                            <div class="detail-desc">{{formGp.value?.branch_details}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">TDS</div>
                            <div class="detail-desc">{{formGp.value?.tds | currency: currency}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Surcharge</div>
                            <div class="detail-desc">{{formGp.value?.surcharge | currency: currency}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Education cess</div>
                            <div class="detail-desc">{{formGp.value?.education_cess | currency: currency}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Interest</div>
                            <div class="detail-desc">{{formGp.value?.intrest}}%</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Others</div>
                            <div class="detail-desc">{{formGp.value?.others | currency: currency}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Cheque/DD no</div>
                            <div class="detail-desc">{{formGp.value?.cheque_dd_no}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Payment date</div>
                            <div class="detail-desc">{{this.appService.dateFormatDisplay(formGp.value?.payment_date)}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Deduction date</div>
                            <div class="detail-desc">{{this.appService.dateFormatDisplay(formGp.value?.deduction_date)}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Ack no</div>
                            <div class="detail-desc">{{formGp.value?.ack_no}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Minor head code</div>
                            <div class="detail-desc">{{formGp.value?.minor_head_code}}</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="detail-title">Late pay fee</div>
                            <div class="detail-desc">{{formGp.value?.late_pay_fee | currency: currency}}</div>
                        </div>
                        <div class="col-12">
                            <div class="detail-title">Selected employees</div>
                            <div class="detail-desc">
                                <div class="d-flex flex-wrap mt-8 gap-8">
                                    <span class="badge2" *ngFor="let empData of selectedEmpData let i = index">
                                        <div class="avatar-circle sq-24 fs-10 {{empData.color_code}}"  alt="" *ngIf="empData.img ==null || empData.img ==''">{{this.appService.getFirstChar(empData.empName,2)}}</div>
                                        <img class="avatar-circle sq-24 {{empData.color_code}}"  src="{{empData.img}}" alt="" *ngIf="empData.img !=null && empData.img !=''">

                                          {{empData?.empName +' ('+ empData?.empCode +')'}}

                                    </span>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveClicked" (click)="saveChallan()">SAVE</button>
    </footer>
</form>
