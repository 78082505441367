<form class=""  [formGroup]="formGp">
<div class="container-fluid p-32 form-container">
    <div class="row ">
        <p class="text-uppercase fw-500">LEAVE YEAR END SETTINGS</p>
    </div>
    <div class="row row-16">
        <div class="col-lg-12 ">
            <div class="form-row">
                <label for=" " class="form-label ">Leave year end process should be</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select the appropriate option. Leave year end process can be automated or can be manually performed." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label" ><input class="form-check-input" type="radio" [value]=true formControlName="manual_auto" (change)="setRequired('process_to_carriedon_month');setRequired('process_to_carron_date')">Auto</label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label" ><input class="form-check-input" type="radio" [value]=false formControlName="manual_auto" (change)="removeRequired('process_to_carriedon_month');removeRequired('process_to_carron_date')">Manual</label>
            </div>
        </div>
        <ng-container *ngIf="formGp.controls['manual_auto'].value">
            <div class="col-12">
                <label for=" " class="form-label required">Leave year end process to be carried out on
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Choose month and date from respective dropdown to automate Leave Year End process." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-md-6 mt-1">   
                <ng-select type="text" class="form-ngselect" placeholder="Select month" formControlName="process_to_carriedon_month" appendTo="body" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.process_to_carriedon_month.touched) &&  f.process_to_carriedon_month.errors?.required}">
                        <ng-option *ngFor="let leaveYearEndMonth of leaveYearEndMonth" [value]="leaveYearEndMonth">{{leaveYearEndMonth}}</ng-option>
                </ng-select>
                <div *ngIf="(saveBtnClicked || f.process_to_carriedon_month.touched) && f.process_to_carriedon_month.errors?.required" class="invalid-feedback">
                    <div *ngIf="f.process_to_carriedon_month.errors?.required"> {{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
            <div class="col-md-6 mt-1">
                <app-master-select class="config-app-master" [placeholder]="'Select day'" id="from_date"
                    [isrequired]="'true'"  formControlName="process_to_carron_date" [type]="'schedule_dates'" [range]="15" [lastDay]="false" [ngClass]="{ 'is-invalid': (saveBtnClicked || f.process_to_carron_date.touched || f.process_to_carron_date.dirty) &&  f.process_to_carron_date.errors}"></app-master-select>
                <div *ngIf="(saveBtnClicked || f.process_to_carron_date.touched || f.process_to_carron_date.dirty) && f.process_to_carron_date.errors" class="invalid-feedback">
                    <div *ngIf="f.process_to_carron_date.errors?.required"> {{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
        </ng-container>
        <div class="col-lg-12">
        <div class="form-row">
            <label for=" " class="form-label ">What happens to negative leave balances during the year end?</label>

        </div>

        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input class="form-check-input" type="radio" value="Deduct from salary" formControlName="negative_balance_leaveyearend">Deduct from Salary
            </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want to deduct the negative leaves from the employee's salary." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input class="form-check-input" type="radio" value="Nullify and reset to zero" formControlName="negative_balance_leaveyearend">Nullify and Reset Balance to Zero
            </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want to nullify or reset the negative leave to zero." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input class="form-check-input" type="radio" value="Carry forward to next cycle" formControlName="negative_balance_leaveyearend">Carry Forward to Next Year
            </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"  ngbPopover="Select this option if you want to carry forward the negative leave to the next leave cycle." popoverClass="popover-default popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
    </div>

    </div>

</div>
<footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase"  [disabled]="!formGp.valid" (click)="validateForm()">NEXT</button>
</footer>
</form>
