<div class="side-panel" style="--sidepanel-width:48.75rem;" [class.side-pane-active]='taxRegimeSlab === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Tax Regime </h5>
      <a class="toggle-panel" (click)="closeSlab()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 mb-24">
          <div class="col-12">
            <div class="fw-500 text-uppercase">Tax REGIME SLABS FY{{selectedYear}} - FY{{(selectedYear + 1)}}</div>
          </div>
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-0">
                <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom shadow-0 px-0">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink class="nav-link">Age group < 60</a>
                        <ng-template ngbNavContent>
                          <div class="row row-16">
                            <div class="col-12">
                              <div class="title">AGE GROUP < 60</div>
                              </div>
                              <div class="col-12 mt-16 fs-14">For individual (resident or non-resident) less than 60
                                years of age anytime
                                during the previous year: </div>

                              <div class="col-12">
                                <div class="card card-c2 p-0">
                                  <div class="table-responsive">
                                    <table class="table table-striped  table-sm table-bordered">
                                      <thead>
                                        <tr>

                                          <th colspan="2" class="text-center">OLD TAX REGIME </th>
                                          <th colspan="2" class="text-center">NEW TAX REGIME U/S 115BAC</th>
                                        </tr>
                                        <tr>
                                          <th>income tax slab</th>
                                          <th>income tax rate</th>
                                          <th>income tax slab</th>
                                          <th>income tax rate</th>
                                        </tr>
                                      </thead>
                                      <tbody class="cell-16">
                                        <tr>
                                          <td>Up to Rs 2.5L</td>
                                          <td>Nil</td>
                                          <td>Up to Rs 3L</td>
                                          <td>Nil</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 2.5L - Rs 5L</td>
                                          <td>5% above Rs 2.5L</td>
                                          <td>Rs 3L - Rs 7L</td>
                                          <td>5% on income which
                                            exceeds Rs 3L </td>
                                        </tr>
                                        <tr>
                                          <td>Rs 5L - Rs 10L</td>
                                          <td>Rs 12,500 + 20% above Rs 5L</td>
                                          <td>Rs 7L - Rs 10L</td>
                                          <td>Rs 20,000 + 10% above Rs 7L</td>
                                        </tr>
                                        <tr>
                                          <td>Above Rs 10L</td>
                                          <td>Rs 1,12,500 + 30% above Rs 10L</td>
                                          <td>Rs 10L - Rs 12L</td>
                                          <td>Rs 50,000 + 15% above Rs 10L</td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>Rs 12L - Rs 15L </td>
                                          <td>Rs 80,000 + 20% above Rs 12L</td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>Above Rs 15.00 Lakh</td>
                                          <td>Rs 1,40,000 + 30% above Rs 15L</td>
                                        </tr>
                                        <!-- <tr>
                                          <td></td>
                                          <td></td>
                                          <td>Above Rs 15 Lakh </td>
                                          <td>Rs 1,87,500 + 30% above Rs 15 Lakh</td>
                                        </tr> -->

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12">
                                <p class="title">Difference of slab rates between the new tax regime and the old tax regime</p>
                              </div>

                              <div class="col-12">
                                <div class="card card-c2 p-0">
                                  <div class="table-responsive">
                                    <table class="table table-striped  table-sm table-bordered">
                                      <thead>
                                        <tr>
                                          <th rowspan="2" class="text-wrap" style="vertical-align: middle;">INCOME TAX SLAB FY{{selectedYear}} - FY{{(selectedYear + 1)}}
                                          </th>
                                          <th colspan="3" class="text-center">OLD REGIME </th>
                                          <th>NEW REGIME</th>
                                        </tr>
                                        <tr>
                                          <th>age (18-59)</th>
                                          <th>AGE (60-79)</th>
                                          <th>AGE (>=80)</th>
                                          <th>ALL AGES</th>
                                        </tr>
                                      </thead>
                                      <tbody class="cell-16">
                                        <tr>
                                          <td>Rs 0L - Rs 2.5L</td>
                                          <td>NIL</td>
                                          <td>NIL</td>
                                          <td>NIL</td>
                                          <td>NIL</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 2.5L - Rs 3L</td>
                                          <td rowspan="2" class="text-wrap" style="vertical-align: middle;">5% (Tax Rebate)</td>
                                          <td>NIL</td>
                                          <td>NIL</td>
                                          <td>NIL</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 3L - Rs 5L</td>
                                          <td>5% (Tax Rebate)</td>
                                          <td>NIL</td>
                                          <td rowspan="3" class="text-wrap" style="vertical-align: middle;">5% (Tax Rebate)</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 5L - Rs 6L</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <!-- <td>10%</td> -->
                                        </tr>
                                        <tr>
                                          <td>Rs 6L - Rs 7L</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <!-- <td>10% (Tax Rebate)</td> -->
                                        </tr>
                                        <tr>
                                          <td>Rs 7L - Rs 7.5L</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <td rowspan="3" class="text-wrap" style="vertical-align: middle;">10% (Tax Rebate)</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 7.5L - Rs 9L</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <!-- <td>10%</td> -->
                                        </tr>
                                        <tr>
                                          <td>Rs 9L - Rs 10L</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <td>20%</td>
                                          <!-- <td>15%</td> -->
                                        </tr>
                                        <tr>
                                          <td>Rs 10L - Rs 12L</td>
                                          <td>30%</td>
                                          <td>30%</td>
                                          <td>30%</td>
                                          <td>15%</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 12L - Rs 15L</td>
                                          <td>30%</td>
                                          <td>30%</td>
                                          <td>30%</td>
                                          <td>20%</td>
                                        </tr>
                                        <tr>
                                          <td>Above Rs 15L</td>
                                          <td>30%</td>
                                          <td>30%</td>
                                          <td>30%</td>
                                          <td>30%</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12">
                                <p class="text-uppercase fw-500 mb-0">SURCHARGE ON INCOME TAX </p>
                              </div>

                              <div class="col-12">
                                <div role="alert" class="alert alert-warning {{alertShow}}">
                                  <p class="mb-0 fs-14">This tax is applicable irrespective of age or regime for employees with higher
                                    salary</p>
                                  <div class="btn-close" (click)="alertShow='hide'"></div>
                                </div>
                              </div>

                              <div class="col-12 mt-1">
                                <div class="card card-c2 p-0">
                                  <div class="table-responsive">
                                    <table class="table table-striped  table-sm table-bordered">
                                      <thead>

                                        <tr>
                                          <th>LEVEL OF INCOME</th>
                                          <th>SURCHARGE ON INCOME TAX</th>

                                        </tr>
                                      </thead>
                                      <tbody class="cell-16">
                                        <tr>
                                          <td>Less than Rs.50 Lakhs</td>
                                          <td>NIL</td>

                                        </tr>
                                        <tr>
                                          <td>Rs 50 Lakhs - Rs 1 Crore</td>
                                          <td>10%</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 1 Crore - Rs 2 Crore</td>
                                          <td>15%</td>
                                        </tr>
                                        <tr>
                                          <td>Rs 2 Crores - Rs 5 Crores</td>
                                          <td>25%</td>
                                        </tr>
                                        <tr>
                                          <td>More than Rs 5 Crores</td>
                                          <td>25% (New regime)<br />
                                            37% (Old regime)</td>

                                        </tr>

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12">
                                <div class="card details-card px-24 py-16">
                                  <div class="row row-16">
                                    <div class="col-12">
                                      <div class="title">SPECIAL NOTE</div>
                                    </div>
                                    <div class="col-12">
                                      <ol class="fs-14 ps-16 li-16">
                                        <li>Rebate u/s 87-A Resident Individual whose Total Income is not more than ₹ 7,00,000 is also
                                          eligible for a Rebate of up to 100% of income tax under new regime.</li>
                                        <li>
                                          Rebate u/s 87-A Resident Individual whose Total Income is not more than ₹ 5,00,000 is also
                                          eligible for a Rebate of up to 100% of income tax or ₹ 12,500, whichever is less under old regime.
                                        </li>
                                        <li> For the FY {{selectedYear}} - {{(selectedYear + 1)}}, the New Tax Regime will be the default Tax Regime.
                                        </li>
                                        <li> The taxpayer opting for concessional rates in the New Tax Regime will not be allowed certain
                                          Exemptions and Deductions (like 80C, 80D,80TTB, HRA) available in the Old Tax Regime. </li>
                                      </ol>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                        </ng-template>


                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink class="nav-link">Age group >= 60</a>
                    <ng-template ngbNavContent>
                      <div class="row row-16">
                        <div class="col-12">
                          <div class="title">AGE GROUP >= 60</div>
                        </div>
                        <div class="col-12 mt-16 fs-14">For individual (resident or non-resident), 60 years or more but
                          less than 80
                          years of age anytime during the previous year:</div>
                          <div class="col-12">
                            <div class="card card-c2 p-0">
                              <div class="table-responsive">
                                <table class="table table-striped  table-sm table-bordered">
                                  <thead>
                                    <tr>

                                      <th colspan="2" class="text-center">OLD TAX REGIME </th>
                                      <th colspan="2" class="text-center">NEW TAX REGIME U/S 115BAC</th>
                                    </tr>
                                    <tr>
                                      <th>income tax slab</th>
                                      <th>income tax rate</th>
                                      <th>income tax slab</th>
                                      <th>income tax rate</th>
                                    </tr>
                                  </thead>
                                  <tbody class="cell-16">
                                    <tr>
                                      <td>Up to Rs 3L</td>
                                      <td>Nil</td>
                                      <td>Up to Rs 3L</td>
                                      <td>Nil</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 3L - Rs 5L</td>
                                      <td>5% above Rs 3L</td>
                                      <td>Rs 3L - Rs 7L</td>
                                      <td>5% on income which
                                        exceeds Rs 3L </td>
                                    </tr>
                                    <tr>
                                      <td>Rs 5L - Rs 10L</td>
                                      <td>Rs 10,000 + 20% above Rs 5L</td>
                                      <td>Rs 7L - Rs 10L</td>
                                      <td>Rs 20,000 + 10% above Rs 7L</td>
                                    </tr>
                                    <tr>
                                      <td>Above Rs 10L</td>
                                      <td>Rs 1,10,000 + 30% above Rs 10L</td>
                                      <td>Rs 10L - Rs 12L</td>
                                      <td>Rs 50,000 + 15% above Rs 10L</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>Rs 12L - Rs 15L </td>
                                      <td>Rs 80,000 + 20% above Rs 12L</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>Above Rs 15.00 Lakh</td>
                                      <td>Rs 1,40,000 + 30% above Rs 15L</td>
                                    </tr>


                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                         <div class="col-12">
                          <p class="title">Difference of slab rates between the new tax regime and the old tax regime</p>
                        </div>

                        <div class="col-12">
                          <div class="card card-c2 p-0">
                            <div class="table-responsive">
                              <table class="table table-striped  table-sm table-bordered">
                                <thead>
                                  <tr>
                                    <th rowspan="2" class="text-wrap" style="vertical-align: middle;">INCOME TAX SLAB FY{{selectedYear}} - FY{{(selectedYear + 1)}}
                                    </th>
                                    <th colspan="3" class="text-center">OLD REGIME </th>
                                    <th>NEW REGIME</th>
                                  </tr>
                                  <tr>
                                    <th>age (18-59)</th>
                                    <th>AGE (60-79)</th>
                                    <th>AGE (>=80)</th>
                                    <th>ALL AGES</th>
                                  </tr>
                                </thead>
                                <tbody class="cell-16">
                                  <tr>
                                    <td>Rs 0L - Rs 2.5L</td>
                                    <td>NIL</td>
                                    <td>NIL</td>
                                    <td>NIL</td>
                                    <td>NIL</td>
                                  </tr>
                                  <tr>
                                    <td>Rs 2.5L - Rs 3L</td>
                                    <td rowspan="2" class="text-wrap" style="vertical-align: middle;">5% (Tax Rebate)</td>
                                    <td>NIL</td>
                                    <td>NIL</td>
                                    <!-- <td rowspan="2" class="text-wrap" style="vertical-align: middle;">5% (Tax Rebate)</td> -->
                                    <td>NIL</td>
                                  </tr>
                                  <tr>
                                    <td>Rs 3L - Rs 5L</td>
                                    <td>5% (Tax Rebate)</td>
                                    <td>NIL</td>
                                    <td rowspan="3" class="text-wrap" style="vertical-align: middle;">5% (Tax Rebate)</td>
                                  </tr>
                                  <tr>
                                    <td>Rs 5L - Rs 6L</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <!-- <td>10%</td> -->
                                  </tr>
                                  <tr>
                                    <td>Rs 6L - Rs 7L</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <!-- <td>10% (Tax Rebate)</td> -->
                                  </tr>
                                  <tr>
                                    <td>Rs 7L - Rs 7.5L</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td rowspan="3" class="text-wrap" style="vertical-align: middle;">10% (Tax Rebate)</td>
                                  </tr>
                                  <tr>
                                    <td>Rs 7.5L - Rs 9L</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <!-- <td>10%</td> -->
                                  </tr>
                                  <tr>
                                    <td>Rs 9L - Rs 10L</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <!-- <td>15%</td> -->
                                  </tr>
                                  <tr>
                                    <td>Rs 10L - Rs 12L</td>
                                    <td>30%</td>
                                    <td>30%</td>
                                    <td>30%</td>
                                    <td>15%</td>
                                  </tr>
                                  <tr>
                                    <td>Rs 12L - Rs 15L</td>
                                    <td>30%</td>
                                    <td>30%</td>
                                    <td>30%</td>
                                    <td>20%</td>
                                  </tr>
                                  <tr>
                                    <td>Above Rs 15L</td>
                                    <td>30%</td>
                                    <td>30%</td>
                                    <td>30%</td>
                                    <td>30%</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <p class="text-uppercase fw-500 mb-0">SURCHARGE ON INCOME TAX </p>
                        </div>

                        <div class="col-12">
                          <div role="alert" class="alert alert-warning {{alertShow}}">
                            <p class="mb-0 fs-14">This tax is applicable irrespective of age or regime for employees with higher
                              salary</p>
                            <div class="btn-close" (click)="alertShow='hide'"></div>
                          </div>
                        </div>

                        <div class="col-12 mt-1">
                          <div class="card card-c2 p-0">
                            <div class="table-responsive">
                              <table class="table table-striped  table-sm table-bordered">
                                <thead>

                                  <tr>
                                    <th>LEVEL OF INCOME</th>
                                    <th>SURCHARGE ON INCOME TAX</th>

                                  </tr>
                                </thead>
                                <tbody class="cell-16">
                                  <tr>
                                    <td>Less than Rs.50 Lakhs</td>
                                    <td>NIL</td>

                                  </tr>
                                  <tr>
                                    <td>Rs 50 Lakhs - Rs 1 Crore</td>
                                    <td>10%</td>
                                  </tr>
                                  <tr>
                                    <td>Rs 1 Crore - Rs 2 Crore</td>
                                    <td>15%</td>
                                  </tr>
                                  <tr>
                                    <td>Rs 2 Crores - Rs 5 Crores</td>
                                    <td>25%</td>
                                  </tr>
                                  <tr>
                                    <td>More than Rs 5 Crores</td>
                                    <td>25% (New regime)<br />
                                      37% (Old regime)</td>

                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="card details-card px-24 py-16">
                            <div class="row row-16">
                              <div class="col-12">
                                <div class="title">SPECIAL NOTE</div>
                              </div>
                              <div class="col-12">
                                <ol class="fs-14 ps-16 li-16">
                                  <li>Rebate u/s 87-A Resident Individual whose Total Income is not more than ₹ 7,00,000 is also
                                    eligible for a Rebate of up to 100% of income tax under new regime.</li>
                                  <li>
                                    Rebate u/s 87-A Resident Individual whose Total Income is not more than ₹ 5,00,000 is also
                                    eligible for a Rebate of up to 100% of income tax or ₹ 12,500, whichever is less under old regime.
                                  </li>
                                  <li> For the FY {{selectedYear}} - {{(selectedYear + 1)}}, the New Tax Regime will be the default Tax Regime.
                                  </li>
                                  <li> The taxpayer opting for concessional rates in the New Tax Regime will not be allowed certain
                                    Exemptions and Deductions (like 80C, 80D,80TTB, HRA) available in the Old Tax Regime. </li>
                                </ol>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink class="nav-link">Age group >= 80</a>
                    <ng-template ngbNavContent>
                      <div class="row row-16">
                        <div class="col-12">
                          <div class="title">AGE GROUP >= 80</div>
                        </div>
                        <div class="col-12 mt-16 fs-14">For individual (resident or non-resident) 80 years of age or
                          more anytime
                          during the previous year: </div>
                          <div class="col-12">
                            <div class="card card-c2 p-0">
                              <div class="table-responsive">
                                <table class="table table-striped  table-sm table-bordered">
                                  <thead>
                                    <tr>

                                      <th colspan="2" class="text-center">OLD TAX REGIME </th>
                                      <th colspan="2" class="text-center">NEW TAX REGIME U/S 115BAC</th>
                                    </tr>
                                    <tr>
                                      <th>income tax slab</th>
                                      <th>income tax rate</th>
                                      <th>income tax slab</th>
                                      <th>income tax rate</th>
                                    </tr>
                                  </thead>
                                  <tbody class="cell-16">
                                    <tr>
                                      <td>Up to Rs 5L</td>
                                      <td>Nil</td>
                                      <td>Up to Rs 3L</td>
                                      <td>Nil</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 5L - Rs 10L</td>
                                      <td>20% above Rs 5L</td>
                                      <td>Rs 3L - Rs 7L</td>
                                      <td>5% on income which
                                        exceeds Rs 3L</td>
                                    </tr>
                                    <tr>
                                      <td>Above Rs 10L</td>
                                      <td>Rs 1,00,000 + 30% above Rs 10L</td>
                                      <td>Rs 7L - Rs 10L</td>
                                      <td>Rs 20,000 + 10% above Rs 7L</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>Rs 10L - Rs 12L</td>
                                      <td>Rs 50,000 + 15% above Rs 10L</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>Rs 12L - Rs 15L </td>
                                      <td>Rs 80,000 + 20% above Rs 12L</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>Above Rs 15L</td>
                                      <td>Rs 1,40,000 + 30% above Rs 15L</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <p class="title">Difference of slab rates between the new tax regime and the old tax regime</p>
                          </div>

                          <div class="col-12">
                            <div class="card card-c2 p-0">
                              <div class="table-responsive">
                                <table class="table table-striped  table-sm table-bordered">
                                  <thead>
                                    <tr>
                                      <th rowspan="2" class="text-wrap" style="vertical-align: middle;">INCOME TAX SLAB FY{{selectedYear}} - FY{{(selectedYear + 1)}}
                                      </th>
                                      <th colspan="3" class="text-center">OLD REGIME </th>
                                      <th>NEW REGIME</th>
                                    </tr>
                                    <tr>
                                      <th>age (18-59)</th>
                                      <th>AGE (60-79)</th>
                                      <th>AGE (>=80)</th>
                                      <th>ALL AGES</th>
                                    </tr>
                                  </thead>
                                  <tbody class="cell-16">
                                    <tr>
                                      <td>Rs 0L - Rs 2.5L</td>
                                      <td>NIL</td>
                                      <td>NIL</td>
                                      <td>NIL</td>
                                      <td>NIL</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 2.5L - Rs 3L</td>
                                      <td rowspan="2" class="text-wrap" style="vertical-align: middle;">5% (Tax Rebate)</td>
                                      <td>NIL</td>
                                      <td>NIL</td>
                                      <td>NIL</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 3L - Rs 5L</td>
                                      <td>5% (Tax Rebate)</td>
                                      <td>NIL</td>
                                      <td rowspan="3" class="text-wrap" style="vertical-align: middle;">5% (Tax Rebate)</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 5L - Rs 6L</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 6L - Rs 7L</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <!-- <td>10% (Tax Rebate)</td> -->
                                    </tr>
                                    <tr>
                                      <td>Rs 7L - Rs 7.5L</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <td rowspan="3" class="text-wrap" style="vertical-align: middle;">10% (Tax Rebate)</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 7.5L - Rs 9L</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <!-- <td>10%</td> -->
                                    </tr>
                                    <tr>
                                      <td>Rs 9L - Rs 10L</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                      <!-- <td>15%</td> -->
                                    </tr>
                                    <tr>
                                      <td>Rs 10L - Rs 12L</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                      <td>15%</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 12L - Rs 15L</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                      <td>20%</td>
                                    </tr>
                                    <tr>
                                      <td>Above Rs 15L</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <p class="text-uppercase fw-500 mb-0">SURCHARGE ON INCOME TAX </p>
                          </div>

                          <div class="col-12">
                            <div role="alert" class="alert alert-warning {{alertShow}}">
                              <p class="mb-0 fs-14">This tax is applicable irrespective of age or regime for employees with higher
                                salary</p>
                              <div class="btn-close" (click)="alertShow='hide'"></div>
                            </div>
                          </div>

                          <div class="col-12 mt-1">
                            <div class="card card-c2 p-0">
                              <div class="table-responsive">
                                <table class="table table-striped  table-sm table-bordered">
                                  <thead>

                                    <tr>
                                      <th>LEVEL OF INCOME</th>
                                      <th>SURCHARGE ON INCOME TAX</th>

                                    </tr>
                                  </thead>
                                  <tbody class="cell-16">
                                    <tr>
                                      <td>Less than Rs.50 Lakhs</td>
                                      <td>NIL</td>

                                    </tr>
                                    <tr>
                                      <td>Rs 50 Lakhs - Rs 1 Crore</td>
                                      <td>10%</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 1 Crore - Rs 2 Crore</td>
                                      <td>15%</td>
                                    </tr>
                                    <tr>
                                      <td>Rs 2 Crores - Rs 5 Crores</td>
                                      <td>25%</td>
                                    </tr>
                                    <tr>
                                      <td>More than Rs 5 Crores</td>
                                      <td>25% (New regime)<br />
                                        37% (Old regime)</td>

                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="card details-card px-24 py-16">
                              <div class="row row-16">
                                <div class="col-12">
                                  <div class="title">SPECIAL NOTE</div>
                                </div>
                                <div class="col-12">
                                  <ol class="fs-14 ps-16 li-16">
                                    <li>Rebate u/s 87-A Resident Individual whose Total Income is not more than ₹ 7,00,000 is also eligible for a Rebate of up to 100% of income tax under new regime.</li>
                                    <li>
                                      For the FY {{selectedYear}} - {{(selectedYear + 1)}}, the New  Tax Regime will be the default Tax Regime.
                                    </li>
                                    <li> The taxpayer opting for concessional rates in the New Tax Regime will not be allowed certain Exemptions and Deductions (like 80C, 80D,80TTB, HRA) available in the Old Tax Regime.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </ng-template>
                  </li>
                </nav>

              </div>
              <div class="card-body p-24">
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
