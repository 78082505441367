<form action="" class=""  [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
      <div class="row ">
          <p class="text-uppercase fw-500">SHIFT TIMING</p>
      </div>
      <div class="row row-16">
          <div class=" col-lg-6 form-row">
              <label for="" class="form-label required">Shift Start Time </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select Shift Start Time as per the defined shift." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
               <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || f.shift_start_time?.touched || f.shift_start_time?.dirty) &&  f.shift_start_time.errors)}" >
                <igx-time-picker
                    formControlName="shift_start_time"
                    placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || f.shift_start_time?.touched || f.shift_start_time?.dirty) &&  f.shift_start_time.errors}"
                    class="form-control form-timepicker "
                    (click)="picker1.open()" #picker1
                    (ngModelChange)="addValidation('shift_start_time','shift_end_time');">
                </igx-time-picker>

                <i *ngIf="f.shift_start_time.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker1);picker1.close()"></i>
              </div>
                <div *ngIf="(submitted || f.shift_start_time?.touched || f.shift_start_time?.dirty) && f.shift_start_time.errors" class="invalid-feedback">
                    <div *ngIf="f.shift_start_time.errors?.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                </div>
          </div>
          <div class=" col-lg-6 form-row">
              <label for="" class="form-label required">Shift End Time </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select Shift End Time as per the defined shift." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || f.shift_end_time?.touched || f.shift_end_time?.dirty) &&  f.shift_end_time.errors) }" >
                <igx-time-picker formControlName="shift_end_time"
                    placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': ((submitted || f.shift_end_time?.touched|| f.shift_end_time?.dirty) &&  f.shift_end_time.errors) }"
                    class="form-control form-timepicker "
                    (click)="picker2.open()" #picker2
                    (ngModelChange)="shifthrs();addValidation('shift_start_time','shift_end_time');">
                </igx-time-picker>
                <!-- [minValue]="f.shift_start_time.value" -->
                <i *ngIf="f.shift_end_time.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker2);picker2.close()"></i></div>

                <div *ngIf="((submitted || f.shift_end_time?.touched || f.shift_end_time?.dirty) && f.shift_end_time.errors) " class="invalid-feedback">
                    <div *ngIf="f.shift_end_time.errors?.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="f.shift_end_time.errors?.same && !f.shift_end_time.errors?.required">{{this.messageService.sameasvalidation( 'Shift End Time','Shift Start Time')}}</div>
                </div>
          </div>
          <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">Minimum working hours required for half day </label>
            <!-- {{f.min_work_hours_for_halfday?.errors|json}} -->
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the minimum working hours for half day from the dropdown." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <div class="form-icon icon-right"  [ngClass]="{ 'is-invalid': ((submitted || f.min_work_hours_for_halfday?.touched || f.min_work_hours_for_halfday?.dirty) &&  f.min_work_hours_for_halfday?.errors)}">
              <igx-time-picker
                formControlName="min_work_hours_for_halfday"
                placeholder="{{this.messageService.descriptiontext('time')}}"
                [inputFormat]="'HH:mm'"
                (ngModelChange)="resetHourDependfield('halfday');addValidation('min_work_hours_for_halfday','min_work_hours_for_fullday');"
                [ngClass]="{ 'is-invalid':((submitted || f.min_work_hours_for_halfday?.touched || f.min_work_hours_for_halfday?.dirty) &&  f.min_work_hours_for_halfday?.errors) }"
                class="form-control form-timepicker "
                (click)="picker6.open()" #picker6
                [minValue]="timeminvalue"
                (keydown)="keydownfn()">
                <!-- [maxValue]="f.min_work_hours_for_fullday.value"
                 -->
                </igx-time-picker>
              <i *ngIf="f.min_work_hours_for_halfday.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker6);picker6.close()"></i></div>

              <div *ngIf="((submitted || f.min_work_hours_for_halfday?.touched || f.min_work_hours_for_halfday?.dirty) && f.min_work_hours_for_halfday?.errors)" class="invalid-feedback">
                  <div *ngIf="f.min_work_hours_for_halfday?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.min_work_hours_for_halfday?.errors?.exeeds  && !(f.min_work_hours_for_halfday?.errors?.required)"> {{this.messageService.notGreaterMessage(' shift hours', 'Value')}} </div>

              </div>
        </div>
        <div class=" col-lg-6 form-row">
          <label for="" class="form-label required">Minimum working hours required for full day </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the minimum working hours for full day from the dropdown." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': ((submitted || f.min_work_hours_for_fullday?.touched) && (f.min_work_hours_for_fullday.errors)) }">
            <igx-time-picker
                formControlName="min_work_hours_for_fullday"
                [ngClass]="{ 'is-invalid':((submitted || f.min_work_hours_for_fullday?.touched || f.min_work_hours_for_fullday?.dirty) &&  f.min_work_hours_for_fullday.errors) }"
                placeholder="{{this.messageService.descriptiontext('time')}}"
                [inputFormat]="'HH:mm'"  class="form-control form-timepicker "
                (click)="picker7.open()" #picker7  [minValue]="timeminvalue"
                (ngModelChange)="minworkhr();resetHourDependfield('mark_full_day_absent');addValidation('min_work_hours_for_halfday','min_work_hours_for_fullday');"
                (keydown)="keydownfn()">
            </igx-time-picker>

            <i *ngIf="f.min_work_hours_for_fullday.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker7);picker7.close()"></i></div>

            <div *ngIf="((submitted || f.min_work_hours_for_fullday?.touched) && (f.min_work_hours_for_fullday.errors)) " class="invalid-feedback">
                <div *ngIf="f.min_work_hours_for_fullday.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.min_work_hours_for_fullday.errors?.min  && !(f.min_work_hours_for_fullday.errors?.required)"> {{this.messageService.greaterThanMessage('Minimum hours for half day', 'Value')}} </div>
                <div *ngIf="f.min_work_hours_for_fullday.errors?.exeeds  && !(f.min_work_hours_for_fullday.errors?.required)"> {{this.messageService.notGreaterMessage(' shift hours', 'Value')}} </div>
            </div>
      </div>
          <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">First Half End Timing</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the end timing for the first half." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
             <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.first_half_end?.touched || f.first_half_end?.dirty) &&  f.first_half_end?.errors}" >
              <igx-time-picker
                formControlName="first_half_end"
                placeholder="{{this.messageService.descriptiontext('time')}}"
                [inputFormat]="'HH:mm'"
                [ngClass]="{ 'is-invalid': (submitted || f.first_half_end?.touched || f.first_half_end?.dirty) &&  f.first_half_end?.errors}"
                class="form-control form-timepicker "
                (click)="picker1end.open()" #picker1end
                (ngModelChange)="addValidation('first_half_end','second_half_start');"
                (keydown)="keydownfn()">
            </igx-time-picker>

              <i *ngIf="f.first_half_end?.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker1end);picker1end.close()"></i>
            </div>
              <div *ngIf="(submitted || f.first_half_end?.touched || f.first_half_end?.dirty) && f.first_half_end?.errors" class="invalid-feedback">
                  <div *ngIf="f.first_half_end.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.first_half_end.errors?.beforeShift && !(f.first_half_end.errors?.required)">{{this.messageService.greaterMessage('Shift Start Timing', 'Value')}}</div>
                  <div *ngIf="f.first_half_end.errors?.afterShift && !(f.first_half_end.errors?.required)">{{this.messageService.lessThanMessage('Shift Start Timing', 'Value')}}</div>
                  <div *ngIf="f.first_half_end.errors?.beforeminhr && !(f.first_half_end.errors?.required)">{{this.messageService.greaterThanMessage('Minimum working hours required for half day', 'Value')}}</div>
              </div>
         </div>
         <div class=" col-lg-6 form-row">
          <label for="" class="form-label required">Second Half Start Timing </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the start timing for the second half." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
           <div class="form-icon icon-right" [ngClass]="{'is-invalid': ((submitted || f.second_half_start?.dirty|| f.second_half_start?.touched) &&  f.second_half_start.errors) }">
            <igx-time-picker formControlName="second_half_start"
                placeholder="{{this.messageService.descriptiontext('time')}}"
                [inputFormat]="'HH:mm'"
                [ngClass]="{ 'is-invalid': (submitted || f.second_half_start?.touched|| f.second_half_start?.dirty) &&  f.second_half_start.errors}"
                class="form-control form-timepicker "
                (click)="picker1start.open()" #picker1start
                (ngModelChange)="addValidation('first_half_end','second_half_start');"
                (keydown)="keydownfn()">
            </igx-time-picker>

            <i *ngIf="f.second_half_start.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker1start);picker1start.close()"></i>
          </div>
            <div *ngIf="((submitted || f.second_half_start?.dirty|| f.second_half_start?.touched) && f.second_half_start.errors) " class="invalid-feedback">
                <div *ngIf="f.second_half_start.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.second_half_start.errors?.min && !(f.second_half_start.errors?.required)">{{this.messageService.greaterMessage('First Half End Timing', 'Value')}}</div>
                <div *ngIf="f.second_half_start.errors?.afterShift && !(f.second_half_start.errors?.required)">{{this.messageService.lessThanMessage('Shift End Timing', 'Value')}}</div>
                <div *ngIf="f.second_half_start.errors?.beforeShift && !(f.second_half_start.errors?.required)">{{this.messageService.greaterMessage('Shift Start Timing', 'Value')}}</div>
                <!-- <div *ngIf="f.second_half_start.errors?.beforeminhr && !(f.second_half_start.errors?.required)">{{this.messageService.greaterThanMessage('Minimum working hours required for full day', 'Value')}}</div> -->
            </div>
          </div>
          <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Do you want to allow check-in before shift starts </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose yes if you want to permit employees to clock in before their shift starts. Maximum value allowed in this field is 120 mins." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input formControlName="before_shift_start_enable"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'before_shift_start_time')">
                      Yes
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input formControlName="before_shift_start_enable"  class="form-check-input" type="radio" [value]=false (click)="setRequired(false,'before_shift_start_time')">
                      No
                  </label>
              </div>
          </div>
          <div class=" col-12 form-row mt-8" *ngIf="f.before_shift_start_enable.value">
            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.before_shift_start_time?.touched || f.before_shift_start_time?.dirty) &&  f.before_shift_start_time?.errors}">
              <igx-time-picker formControlName="before_shift_start_time"  placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || f.before_shift_start_time?.touched || f.before_shift_start_time?.dirty) &&  f.before_shift_start_time.errors}" class="form-control form-timepicker " (click)="picker3.open()" #picker3 [maxValue]="beforeshiftmaxvalue"  [minValue]="timeminvalue" (keydown)="keydownfn()"></igx-time-picker>

              <i *ngIf="f.before_shift_start_time.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker3);picker3.close()"></i></div>

              <div *ngIf="(submitted || f.before_shift_start_time?.touched || f.before_shift_start_time?.dirty) && f.before_shift_start_time.errors" class="invalid-feedback">
                  <div *ngIf="f.before_shift_start_time.errors?.required">{{this.messageService.validationDisplay('required')}}
                  </div>
              </div>
          </div>

         <div class="col-lg-12 form-row ">
              <label for="" class="form-label">Do you want to provide grace time for late checkin </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose yes, if you want to permit grace time for late check-in. Grace time Shouldn't be greater than the Minimum hours required for Half day." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input formControlName="grace_time_late_checkin_enable"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'grace_time_late_checkin_time')">
                      Yes
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input  formControlName="grace_time_late_checkin_enable" class="form-check-input" type="radio" [value]=false (click)="setRequired(false,'grace_time_late_checkin_time')">
                      No
                  </label>
              </div>
          </div>
          <div class=" col-12 form-row mt-8" *ngIf="f.grace_time_late_checkin_enable.value">
            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.grace_time_late_checkin_time?.touched || f.grace_time_late_checkin_time?.dirty) &&  f.grace_time_late_checkin_time.errors}">
              <igx-time-picker
                formControlName="grace_time_late_checkin_time"
                placeholder="{{this.messageService.descriptiontext('time')}}"
                [inputFormat]="'HH:mm'"
                [ngClass]="{ 'is-invalid': (submitted || f.grace_time_late_checkin_time?.touched || f.grace_time_late_checkin_time?.dirty) &&  f.grace_time_late_checkin_time?.errors}"
                class="form-control form-timepicker "
                (click)="picker4.open()" #picker4
                  [minValue]="timeminvalue"
                  [maxValue]="f.min_work_hours_for_halfday?.value"
                (keydown)="keydownfn()">
            </igx-time-picker>

              <i *ngIf="f.grace_time_late_checkin_time.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker4);picker4.close()"></i></div>

              <div *ngIf="(submitted || f.grace_time_late_checkin_time?.touched || f.grace_time_late_checkin_time?.dirty) && f.grace_time_late_checkin_time.errors" class="invalid-feedback">
                  <div *ngIf="f.grace_time_late_checkin_time.errors?.required">{{this.messageService.validationDisplay('required')}}
                  </div>
              </div>
          </div>
          <div class="col-lg-12 form-row ">
              <label for="" class="form-label ">Do you want to provide grace time for early checkout </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose yes, if you want to permit grace time for early check-out. Grace time Shouldn't be greater than the Minimum hours required for Half day." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input formControlName="grace_time_early_checkout_enable"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'grace_time_early_checkout_time')">
                      Yes
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input formControlName="grace_time_early_checkout_enable"  class="form-check-input" type="radio" [value]=false (click)="setRequired(false,'grace_time_early_checkout_time')">
                      No
                  </label>
              </div>
          </div>
          <div class=" col-12 form-row" *ngIf="f.grace_time_early_checkout_enable.value">
            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.grace_time_early_checkout_time?.touched || f.grace_time_early_checkout_time?.dirty) &&  f.grace_time_early_checkout_time?.errors}">
                <igx-time-picker
                    formControlName="grace_time_early_checkout_time"
                    placeholder="{{this.messageService.descriptiontext('time')}}"
                    [inputFormat]="'HH:mm'"
                    [ngClass]="{ 'is-invalid': (submitted || f.grace_time_early_checkout_time?.touched || f.grace_time_early_checkout_time?.dirty) &&  f.grace_time_early_checkout_time?.errors}"
                    class="form-control form-timepicker "
                    (click)="picker5.open()" #picker5
                    [minValue]="timeminvalue"
                    [maxValue]="f.min_work_hours_for_halfday?.value"
                    (keydown)="keydownfn()">
                </igx-time-picker>
                <!-- [maxValue]="f.min_work_hours_for_halfday.value" -->
              <i *ngIf="f.grace_time_early_checkout_time.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker5);picker5.close()"></i></div>

              <div *ngIf="(submitted || f.grace_time_early_checkout_time?.touched || f.grace_time_early_checkout_time?.dirty) && f.grace_time_early_checkout_time?.errors" class="invalid-feedback">
                  <div *ngIf="f.grace_time_early_checkout_time.errors?.required">{{this.messageService.validationDisplay('required')}}
                  </div>
                  <div *ngIf="f.grace_time_early_checkout_time.errors?.max">{{this.messageService.notGreaterMessage(' Grace time for early checkout', 'Minimum working hours required for half day')}}
                  </div>
              </div>
          </div>
          <div class=" col-12 form-row">
              <label for="" class="form-label ">Do you want to mark Half-day absent if no attendance is recorded for </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes for half-day absent if no attendance is recorded for the minimum hours specified below." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input formControlName="mark_half_day_absent_status"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'mark_half_day_absent')">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                        <input formControlName="mark_half_day_absent_status"  class="form-check-input" type="radio" [value]=false (click)="setRequired(false,'mark_half_day_absent')">
                        No
                    </label>
                </div>
              </div>
              <div class="form-icon icon-right mt-8" *ngIf="f.mark_half_day_absent_status.value" [ngClass]="{ 'is-invalid': (submitted || f.mark_half_day_absent?.touched || f.mark_half_day_absent?.dirty) &&  f.mark_half_day_absent.errors}">
                <igx-time-picker
                    formControlName="mark_half_day_absent"
                    placeholder="{{this.messageService.descriptiontext('time')}}"
                    [inputFormat]="'HH:mm'"
                    [ngClass]="{ 'is-invalid': (submitted || f.mark_half_day_absent?.touched || f.mark_half_day_absent?.dirty) &&  f.mark_half_day_absent.errors}"
                    class="form-control form-timepicker "
                    (click)="picker8.open()" #picker8
                    [minValue]="timeminvalue"
                    [maxValue]="f.min_work_hours_for_halfday?.value"
                    (keydown)="keydownfn();">
                </igx-time-picker>
                <!-- [maxValue]="f.min_work_hours_for_halfday.value" -->
                <i *ngIf="f.mark_half_day_absent.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker8);picker8.close()"></i></div>

                <div *ngIf="(submitted || f.mark_half_day_absent?.touched || f.mark_half_day_absent?.dirty) && f.mark_half_day_absent.errors" class="invalid-feedback">
                    <div *ngIf="f.mark_half_day_absent.errors?.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                </div>
          </div>
          <div class=" col-12 form-row">
              <label for="" class="form-label ">Do you want to mark Full-day absent if no attendance is recorded for </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes for full-day absent if no attendance is recorded for the minimum hours specified below." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input formControlName="mark_full_day_absent_status"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'mark_full_day_absent')">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                        <input formControlName="mark_full_day_absent_status"  class="form-check-input" type="radio" [value]=false (click)="setRequired(false,'mark_full_day_absent')">
                        No
                    </label>
                </div>
              </div>
              <div class="form-icon icon-right mt-8" *ngIf="f.mark_full_day_absent_status.value" [ngClass]="{ 'is-invalid': (submitted || f.mark_full_day_absent?.touched || f.mark_full_day_absent?.dirty) &&  f.mark_full_day_absent.errors}">
                <igx-time-picker
                    formControlName="mark_full_day_absent"
                    placeholder="{{this.messageService.descriptiontext('time')}}"
                    [inputFormat]="'HH:mm'"
                    [ngClass]="{ 'is-invalid': (submitted || f.mark_full_day_absent?.touched || f.mark_full_day_absent?.dirty) &&  f.mark_full_day_absent.errors}"
                    class="form-control form-timepicker "
                    (click)="picker9.open()" #picker9
                    [minValue]="timeminvalue"
                    [maxValue]="f.min_work_hours_for_fullday?.value"
                    (keydown)="keydownfn()">
                </igx-time-picker>
                <!-- [maxValue]="f.min_work_hours_for_fullday.value"  -->
                <i *ngIf="f.mark_full_day_absent.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker9);picker9.close()"></i></div>
                <div *ngIf="(submitted || f.mark_full_day_absent?.touched || f.mark_full_day_absent?.dirty) && f.mark_full_day_absent.errors" class="invalid-feedback">
                    <div *ngIf="f.mark_full_day_absent.errors?.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="f.mark_full_day_absent.errors?.max">{{this.messageService.notGreaterMessage('Value', 'Minimum working hours required for full day')}}
                    </div>
                </div>
          </div>



      </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" [disabled]="!formGp.valid  " (click)="validateForm()" class="btn btn-primary btn-sm ms-auto">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateForm()">NEXT</button>
  </footer>
</form>
