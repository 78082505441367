import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    // observe: 'response'
  };

  /*const MultipartHeaders: HttpHeaders = new HttpHeaders({
    Accept: 'application/json'
  });*/

  // public Config: any = {
  //   api: {
  //     baseURL: 'http://11.98.155.150/back/ws', //obviously fake
  //     multiContentOptions: { headers : new HttpHeaders({
  //       'Accept': 'application/json',
  //     }) },
  //   }
  // };


  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  getshiftList(status: any,id :any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift/?is_active=${status}&id=${id}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }

  shiftCreate(data: any) {
      return this.http.post<any>(`${this.API_PATH}attendance/api/v1/shift-create/`, data);
  }

  shiftupdate(data: any, id: number) {
      return this.http.put<any>(`${this.API_PATH}attendance/api/v1/shift-update/${id}/`, data);
  }
  shiftDropDownData(status: any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-list-dropdown/?is_active=${status}&ordering=shift_name`, this.httpOption)
  }

  shiftGet(id: number) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/shift-detail/${id}`, this.httpOption)
  }

  shiftDelete(id: number) {
    return this.http.patch(`${this.API_PATH}attendance/api/v1/shift-delete/${id}/`,  { is_active: false },this.httpOption)
  }
//   shiftDeleteCheck(id: number) {
//     return this.http.get(`${this.API_PATH}attendance/api/v1/shift-delete-check/?id=${id}`, this.httpOption)
// }
getshiftrotatList(status: any,id :any,limit:any,offset:any,search:any) {
  return this.http.get(`${this.API_PATH}attendance/api/v1/shift-rotation/?is_active=${status}&id=${id}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
}

shiftrotatCreate(data: any) {
    return this.http.post<any>(`${this.API_PATH}attendance/api/v1/shift-rotation-create/`, data);
}

shiftrotatupdate(data: any, id: number) {
    return this.http.put<any>(`${this.API_PATH}attendance/api/v1/shift-rotation-update/${id}/`, data);
}
shiftrotatDropDownData(status: any){
  return this.http.get(`${this.API_PATH}attendance/api/v1/shift-rotation-list-dropdown/?is_active=${status}&ordering=rotation_name`, this.httpOption)
}

shiftrotatGet(id: number) {
  return this.http.get(`${this.API_PATH}attendance/api/v1/shift-rotation-detail/${id}/`, this.httpOption)
}
shiftrotatview(id: number) {
  return this.http.get(`${this.API_PATH}attendance/api/v1/shift-rotation-view/${id}/`, this.httpOption)
}
shiftrotateditview(id: number) {
  return this.http.get(`${this.API_PATH}attendance/api/v1/shift-rotation-detail/${id}`, this.httpOption)
}
shiftrotatDelete(id: number) {
  return this.http.patch(`${this.API_PATH}attendance/api/v1/shift-rotation-delete/${id}/`,  { is_active: false },this.httpOption)
}
}
