import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function gstValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const fieldPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value);

      return !fieldPattern ? {pattern:true}: null;
  }

}

