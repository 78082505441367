
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AppService } from 'src/app/app.global';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  chat_item: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private websocket: WebSocket,
    public router: Router,
    private authService: AuthService,
    public appservice:AppService

  ) { }
  round: any = true;
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }


  private messagesSubject = new Subject<string>();
  messages$ = this.messagesSubject.asObservable();
  history:any 

  sendMessage(message: string): void {
    this.messagesSubject.next(message);
    if(this.history == undefined)
        this.history =[]
    
    this.sendChat(message,this.history).subscribe((res: any) => {
      this.history.push({
        "role": "user",
        "content": message
      });
      this.history = res.Answer      
      // this.history.push(res.Answer[res.Answer.length-1]);
    })
  }

WEBSOCKET_URL: string =environment.webSocketEndPoint;

public chatSocket(){

    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}chat-openai/${this.appservice.getSubDomain()}_chatopenai_${this.authService.isUserId}/`);
    this.websocket.onopen = (event:any) => {
      // console.log('Opened chat socket: ', event);
    }

    this.websocket.onmessage = (event:any) => {

      let data = JSON.parse(event.data);
      this.chat_item.emit(data);
    }

    this.websocket.onclose = (event:any) => {
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          // Hide loading indicator
          if(event.url !== '/chat'){
           this.closeSocket();
          }
      }
    });

    if (this.websocket.readyState === WebSocket.OPEN) {
    }

  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();

      this.websocket.addEventListener('close', (event) => {
      });
    }
  }
  
  sendChat(message: any,history:any=[]){
    return this.http.post(`${this.API_PATH}chat/api/v1/answer_question/`, {"question": message, "version": "employee","coversation_history":history}, this.httpOption)
  }
}