import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-ar-punch-details',
  templateUrl: './ar-punch-details.component.html',
  styleUrls: ['./ar-punch-details.component.scss']
})
export class ArPunchDetailsComponent implements OnInit {
  constructor(public messageService : MessageService,
  public appservice:AppService) { }
  @Input() formGp:any  = []
  submitted = false;
  @Input()disabled = false;
  @Output() submitmethod = new EventEmitter();
  ngOnInit(): void {
  }
  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(5);
  }
  
  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }
}
