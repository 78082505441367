<div class="border-start border-bottom">
    <div class="hstack justify-content-around align-items-end gap-16">
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '1rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '2rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '3rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '6rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '10rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '16rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '8rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '5rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '3rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '9rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '7rem','width': '12px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '13rem','width': '12px' }"></ngx-skeleton-loader>

    </div>
</div>