import { Component, OnInit, Input, Output, EventEmitter,ViewChild,AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ExpenseReceiptService } from 'src/app/@core/services/expense-receipt.service';
import { ReceiptLandingTableComponent } from '../receipt-landing-table/receipt-landing-table.component';
@Component({
  selector: 'app-view-expense-receipt',
  templateUrl: './view-expense-receipt.component.html',
  styleUrls: ['./view-expense-receipt.component.scss']
})
export class ViewExpenseReceiptComponent implements OnInit {

  // test start
  @Input() from:any;
  @Input() viewReciept:any;
  @Input() editReciept:any;
  @Output() closeView  = new EventEmitter();

  @Input() viewRecieptLoader:any;
  @Input() viewRecieptData:any;
  @Input() currency:any;
  editId:any;
  editDetail         = false;
  modalToggle        = false;
  currentDocName     = "";
  currentExtension   = "";
  fileName:any;

  alertToggle         = false;
  alertMsg:any        = "";

  constructor(
    public appservice: AppService,
    public receiptService: ExpenseReceiptService,
    private child:ReceiptLandingTableComponent

  ) { }

  ngOnInit(): void {

  }
  editRreceipt(id:any){

    this.viewReciept  = false
    this.editDetail   = true;
    this.editId       = id;
  }
  saveReceipt(form:any){

    let formValue = form?.form_value;
    formValue['expense_date'] = this.appservice.dateFormatConvert(formValue['expense_date'])
    this.receiptService.saveOrUpdateReceipt(form?.editId,formValue).subscribe((res:any)=>{
      this.editDetail = false;
      this.child.getLandingPageData();
      this.closeView.emit();


    })
  }
  closeAddExpense(){
    this.editDetail = false
    this.closeView.emit();

  }
  closeViewModel(){
    this.closeView.emit();

  }
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    // var currentDocName : any    = this.fileName.split('_').pop();
    // this.currentDocName         = currentDocName.split('.').slice(0, -1);
    // this.currentDocName         = decodeURIComponent(this.currentDocName);
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
  }
  close(bool:any){
    this.modalToggle = bool;
  }

  expiredFunction(basedOn:any,period:any){
    this.alertToggle   = true;
    this.alertMsg      = 'Expense receipt exceeds the validity period of'+' '+period+' '+basedOn;
  }
}
