<div class="card card-c2 p-24">
        <div class="table-responsive card card-c2 radius-4 p-16">
            <table class="table table-borderless table-sm">
                <thead>
                  <tr>
                    <th *ngFor="let dummy of ' '.repeat(7).split(''), let x = index" class="text-center" scope="col"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '15px','width': '35px ' }"></ngx-skeleton-loader></th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let dummy of ' '.repeat(5).split('')">
                    <th *ngFor="let dummy of ' '.repeat(7).split(''), let x = index" class="text-center" scope="col"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '45px','width': '95% ','border-radius':'50px ','margin-top':'10px' }"></ngx-skeleton-loader></th>
      
                  </tr>
                </tbody>
              </table>
          </div>
        <!-- <ng-container *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
            <div class="mb-15">
              <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '74px','width': '100%' }"></ngx-skeleton-loader>
            </div>
          </ng-container> 
    </div> -->
</div>