import { Component, OnInit, Input,  Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-tax-slab',
  templateUrl: './view-tax-slab.component.html',
  styleUrls: ['./view-tax-slab.component.scss']
})
export class ViewTaxSlabComponent implements OnInit {

  @Input()taxRegimeSlab:any;
  @Output() close = new EventEmitter();

  activeTab = 1;
  alertShow = 'show';
  selectedYear: any;


  constructor(
    public appService: AppService
  ) { }

  ngOnInit(): void {
    this.selectedYear   = this.appService.getFinancialYear();
  }

  closeSlab(){
    this.close.emit(false);
  }

}
