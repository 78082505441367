import { AfterViewInit, Component, OnInit } from '@angular/core';
import {  FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/@core/services/role.service'
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';

@Component({
  selector: 'app-role-setup',
  templateUrl: './role-setup.component.html',
  styleUrls: ['./role-setup.component.scss']
})
export class RoleSetupComponent implements OnInit ,AfterViewInit{
  activeTab = 1;
  employeeDropdown:any =[];
  is_active = true;
  role_id = 0;
  companyDD = [];
  buDD = [];
  DepDD = [];
  DesignationDD = [];
  BranchDD = [];
  cityDD = [];
  gradeDD = [];
  emp_data: any = [];
  AccessArr:any;
  saveBtn:string = "SAVE";
  disabled = false;
  isDefault = false;
  loader    = false;
  isEmpDropdown =false;
  companyData:any = [];
  buArray:any []  =  [];

  myForm: FormGroup = this.fb.group({
    id: 0,
    define: this.fb.group({
      role_name:  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      employee: ['', [Validators.required]]
    }),
    assign: this.fb.group({
      list : this.fb.array([])
    })
  });
  empArr: any = [];
  selectedPeople: any = [];
  listForm:any;
  empValid:any[] = [];
  heading = "Add"
  editflag : boolean = false
  constructor(
    public fb:FormBuilder,
    private rs:RoleService,
    private route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.getEmployeeList();
    this.role_id = this.route.snapshot.params['id'];
    if(this.role_id != 0 && this.role_id !== undefined) {
      this.heading = "Edit"
      this.loader = true;
      this.editflag = true
      this.empRoleList(this.role_id);
      this.saveBtn = "Save";
    }
    if(this.role_id == undefined)
      this.role_id = 0;
  }

  ngAfterViewInit(){
    this.getCompanyList();
    // this.getBUList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getCityList();
    this.getGradeList();
  }

  empRoleList(id: any) {
    this.rs.getEmpRoleList(id)
      .subscribe((res: any) => {
        this.loader = false;
        if(res.status == 200) {
          if (res.body.length > 0) {
            if(res.body[0].default == true)
              this.isDefault = true;
            let rights:any = [];
            for (let i = 0; i < res.body[0].role_employee.length; i++) {
              this.emp_data.push(res.body[0].role_employee[i].employee);
              this.formArr().push( this.newRight());
              if(res.body[0].role_employee[i].role_employee.length>0 ){
              rights.push({
                employee: res.body[0].role_employee[i].employee,
                company: res.body[0].role_employee[i].role_employee[0].company,
                businessunit: res.body[0].role_employee[i].role_employee[0].businessunit,
                department: res.body[0].role_employee[i].role_employee[0].department,
                designation: res.body[0].role_employee[i].role_employee[0].designation,
                grade: res.body[0].role_employee[i].role_employee[0].grade,
                city: res.body[0].role_employee[i].role_employee[0].city,
                branch: res.body[0].role_employee[i].role_employee[0].branch,
              });
              } else {
                rights.push({
                  employee: res.body[0].role_employee[i].employee,
                  company: null,
                  businessunit: null,
                  department: null,
                  designation: null,
                  grade: null,
                  city: null,
                  branch: null,
                })
              }
            }
            this.myForm.setValue({id:id,define:{role_name: res.body[0].role_name, employee: this.emp_data},assign:{list:rights}});
            if(res.body[0].is_active == false ||res.body[0].default == true){
              this.myForm.disable();
              this.disabled = true;
            }

            this.buArrayCreation();
            this.buListCreation();

          }
        }
      })
  }

  buArrayCreation(){
    for(let i=0; i<this.emp_data.length; i++){
      this.buArray[i]=Array();
    }
  }

  buListCreation(){
    const myForm = (<FormArray>this.assignForm.get("list"));
    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);

      if(this.listForm.controls.company.value != undefined && this.listForm.controls.company.value.length > 0) {
        this.getBUList(this.listForm.controls.company.value,k);
      }
    }
  }

  companyChange1(data:any){
    this.createFormArray();
    this.buListCreation();
  }

  newRight(): FormGroup {
    return this.fb.group({
      employee: [null, [Validators.required]],
      company: [null, [Validators.required]],
      businessunit: [null, [Validators.required]],
      department: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      grade: [null, [Validators.required]],
      city: [null, [Validators.required]],
      branch: [null, [Validators.required]]
    })
  }

  getCompanyList(){
    this.rs.getCompanyList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.companyDD = res.body;
      }
    });
  }

  companyChange(data: any){
    this.buDD = [];
    this.getBUList(data.compData,data.index);
  }

  getDepList(){
    this.rs.getDepartmentList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DepDD = res.body;
      }
    });
  }

  getDesignationList(){
    this.rs.getDesignationList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DesignationDD = res.body;
      }
    });
  }
  getBranchList(){
    this.rs.getBranchList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.BranchDD = res.body;
      }
    });
  }
  getCityList(){
    this.rs.getCityList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.cityDD = res.body;
      }
    });
  }
  getGradeList(){
    this.rs.getGradeList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.gradeDD = res.body;
      }
    });
  }

  getEmployeeList(){
    this.rs.getempList(this.is_active).subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name']  = "";
            if(res.body[i]['first_name'] != null)
              res.body[i]['name'] += res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
              res.body[i]['name'] += res.body[i]['last_name'];
              res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";
          }
          this.employeeDropdown = res.body;
        }
      }

      this.isEmpDropdown= true;
    });
  }

  submitOne(event: any){
    this.activeTab = 2;
    this.createFormArray();
    // this.createSelectedArray();
  }

  get defineForm() {
    return this.myForm.get('define') as FormGroup;
  }
  get assignForm() {
    return this.myForm.get('assign') as FormGroup;
  }
  assignFormGP() {
    return this.myForm.get('assign') as FormGroup;
  }

  formArr() : FormArray {
    return this.assignFormGP().get('list') as FormArray
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
     if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].define.invalid) {
        changeEvent.preventDefault();
      } else {
        this.createFormArray();
        // this.createSelectedArray();
      }
    }
  }

  newGp(empId:number): FormGroup {
    return this.fb.group({
      employee: [empId, [Validators.required]],
      company: [[], [Validators.required]],
      businessunit: [[], [Validators.required]],
      department: [[], [Validators.required]],
      designation: [[], [Validators.required]],
      grade: [[], [Validators.required]],
      city: [[], [Validators.required]],
      branch: [[], [Validators.required]]
    })
  }
  createSelectedArray(){
    this.selectedPeople.splice(0, 1);
    if(this.myForm.value.define.employee.length > 0){
      this.empArr = [];
      let emp;
      for(let empId of this.myForm.value.define.employee){
        emp = this.employeeDropdown.filter((p: { id: any; }) => p.id===empId);
        this.empArr.push(emp[0] );
        this.selectedPeople = this.empArr;
      }
    }
  }

  createFormArray() {
      this.AccessArr = this.assignForm.controls.list;
      var len = this.myForm.value.define.employee.length;
      while (this.formArr().length > len) {
        var flen = this.formArr().length-1;
        this.formArr().removeAt(flen);
      }
      for (let i = 0; i < len; i++){
        this.empValid[i] = false;
        var empId = this.myForm.value.define.employee[i];
        if(this.AccessArr.value[i] == undefined){
          this.formArr().push(this.newGp(empId));
        } else if(this.AccessArr.value[i].employee != empId){
          this.formArr().removeAt(i);
          this.formArr().insert(i,this.newGp(empId));
          // this.formArr().removeAt(i);
          // this.formArr().push(this.newGp(empId));
        }
      }
      this.createSelectedArray();
  }

  submitForm(){
    this.AccessArr = this.assignForm.controls.list;
    var dataAccessArray = [];
    for(let key of this.AccessArr.controls){
      dataAccessArray.push({
        "employee":key.value.employee,
        "role_employee": [{
        "company":key.value.company,
        "businessunit":key.value.businessunit,
        "department":key.value.department,
        "designation":key.value.designation,
        "grade":key.value.grade,
        "city":key.value.city,
        "branch":key.value.branch,
        }]
     });
    }
    var formData = {
      "role_name": this.myForm.value.define.role_name,
      "role_employee": dataAccessArray
    };

    this.rs.createRole(formData,this.role_id).subscribe((res: any) => {
      this.router.navigate(['role']);
    },
    (error) => {

    });

  }

  checkValid() {
    var retResult = true;
    const myForm = (<FormArray>this.assignForm.get("list"));

    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);
      if(this.listForm.controls.company.valid == false && this.listForm.controls.businessunit.valid == false && this.listForm.controls.department.valid == false && this.listForm.controls.designation.valid == false && this.listForm.controls.grade.valid == false && this.listForm.controls.city.valid == false && this.listForm.controls.branch.valid == false) {
        retResult = false;
      }
    }
    return retResult;
  }

  countAssign(){
    var retResult = 0;
    const myForm = (<FormArray>this.assignForm.get("list"));

    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);
      if(this.listForm.controls.company.valid == true || this.listForm.controls.businessunit.valid == true || this.listForm.controls.department.valid == true || this.listForm.controls.designation.valid == true || this.listForm.controls.grade.valid == true || this.listForm.controls.city.valid == true || this.listForm.controls.branch.valid == true) {
        retResult++;
        this.empValid[k] = true;
      } else {
        this.empValid[k] = false;
      }
    }
    return retResult;
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  getBUList(data:any,index:any){
    this.rs.getBuList(data).subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
        if(index == 'commonForm'){
          this.buDD=(res.body);
          this.selectAllForDropdownItems(this.buDD);
        }
        else{
          this.buArray[index] = (res.body);
          this.selectAllForDropdownItems(this.buArray[index]);
        }
      }else{
        if(index == 'commonForm')
          this.buDD = [];
        else
          this.buArray[index] = [];
      }
    });
  }
}
