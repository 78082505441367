
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32 ">
              <div class="card card-c2 p-32">
                  <div class="row  row-16">

                      <div class="col-12 d-flex ">
                          <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
                      </div>
                      <div class="col-12 mt-8">
                          <h5 class="mb-8">Investment Declaration Approval</h5>
                          <span class=" badge-custom {{data?.request_type_color_code}}">Investment Declaration </span>
                      </div>

                      <div class=" col-md-9  col-lg-6">
                        <button class="btn btn-outline-primary w-100 fw-400 btn-sm radius-2" (click)="Inv_Detailspanel(data?.request_object,data?.request_type_name)">Verify
                          Details</button>
                      </div>

                  </div>
              </div>
          </div>
      </div>

      <!-- test start -->
      <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='investmentcard1 === true'>
        <div class="side-panel-container">
          <form class="side-panel-container" autocomplete="off" >
            <header class="side-panel-head">
              <h5> {{investmentSingleData?.employee}} - {{viewHeader}}</h5>
              <a class="toggle-panel" (click)="investmentcard1=false"><i class="icon-close-lg fs-16"></i></a>
            </header>
            <div class="side-panel-body" style="overflow-y: auto;">
              <div class="container-fluid p-32">
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <ng-container  *ngIf="!loader1">
                  <app-view-investment-declaration
                  [from]="from"
                  [investmentSingleData]="investmentSingleData"
                  [viewHeader]="viewHeader"
                  [hra80ggSingleData] = "hra80ggSingleData"
                  [cahpt80CSingleData] = "cahpt80CSingleData"
                  [otherSourceSingleData] = "otherSourceSingleData"
                  [deductionSingleData] = "deductionSingleData"
                  [incomeLossSingleData] = "incomeLossSingleData"
                  [exeption_50]="exeption_50"
                  [taxData]="taxData"
                  [hra80gg]="hra80gg"
                  [hra80ggFlag]="hra80ggFlag"
                  [loader1]="loader1"
                  [cityName]="cityName"
                  [requestitem]="viewId"
                  [investmentcard1]="investmentcard1"
                  [currency]="currency"
                  [reqStatus]="reqStatus"
                  (investmentcardClose)="closeSidePanel($event)"
                  (refreshEmitter)="refreshInbox($event)"
                  ></app-view-investment-declaration>
                </ng-container>
              </div>
            </div>
          </form>
        </div>

        </div>
      <!-- test end -->
