import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  showSidePanel = false;
  selectedList:any = [];
  selectedName="";
  reminders:any = []
  company:any;
  alertClose = false;
  year:any;
  month:any;
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  loader =true;
  constructor(public route:ActivatedRoute,private sds:SalaryDashboardService,public appservice:AppService) { }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })

    this.sds.getalerts( Number(this.year),this.months[this.month],this.company).subscribe((res:any)=>{
      this.createRemiderArray( res.body)
    });

  }

  createRemiderArray(data:any){
    // if(data.attendance_list != undefined){
    //   this.reminders.push({
    //     reminder:"Attendance not processed",
    //     list:data.attendance_list[0].employees,
    //     class:this.getRandomClass()
    //   })
    // }
    if(data.salary_structure_list != undefined){
      this.reminders.push({
        reminder:"Salary structure missing",
        list:data.salary_structure_list[0].employees,
        class:this.getRandomClass()

      })
    }
    // if(data.bank_list != undefined){
    //   this.reminders.push({
    //     reminder:"Bank details missing",
    //     list:data.bank_list[0].employees,
    //     class:this.getRandomClass()
    //   })
    // }
    // if(data.pan_list != undefined){
    //   this.reminders.push({
    //     reminder:"Pan card details missing",
    //     list:data.pan_list[0].employees,
    //     class:this.getRandomClass()
    //   })
    // }
    if(data.unprocessed_list != undefined){
      this.reminders.push({
        reminder:"Salary unprocessed last month",
        list:data.unprocessed_list[0].employees,
        class:this.getRandomClass()
      })
    }
    if(data.attendance_policy_list != undefined){
      this.reminders.push({
        reminder:"Attendance policy not mapped",
        list:data.attendance_policy_list[0].employees,
        class:this.getRandomClass()

      })
    }
    if(data.attendance_unprocessed_lm_list != undefined){
      this.reminders.push({
        reminder:"Attendance unprocessed last month",
        list:data.attendance_unprocessed_lm_list[0].employees,
        class:this.getRandomClass()

      })
    }

    this.loader = false;
  }

  getRandomClass(){
    const classes = ["highlight1", "highlight2", "highlight3", "highlight-primary"];
    const random = Math.floor(Math.random() * classes.length);
    return classes[random];
  }

  closePanel(val:any){
    this.showSidePanel= val
  }
}
