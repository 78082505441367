import { Component, Input, OnInit, EventEmitter, Output, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { IgxTimePickerComponent } from 'igniteui-angular';
import * as moment from 'moment';
import { ShiftService } from 'src/app/@core/services/shift.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';

@Component({
  selector: 'app-od-edit-details',
  templateUrl: './od-edit-details.component.html',
  styleUrls: ['./od-edit-details.component.scss']
})
export class OdEditDetailsComponent implements OnInit {

  @Input() formGrp : FormGroup = this.fb.group({
    dutyRequest : this.fb.array([])
  })
  @Input() data : any
  @Output() editDetails = new EventEmitter()
  @Output() submitmethod = new EventEmitter()

  disabled      : boolean = false
  submitted     : boolean = false
  formData      : any     = []
  shiftDropdown : any     = []
  canSave       : any     = true;

  constructor(
    private fb     : FormBuilder,
    public msgServ : MessageService,
    public shiftSer: ShiftService,
    public appServ : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private cd : ChangeDetectorRef
  ) { }

  @ViewChild('picker', { static: false }) picker !: IgxTimePickerComponent;

  ngOnInit(): void {
    // Date Format
    this.getDateFormat();
    this.assignForm().clear()
    if(this.data.length > 0){
      for(let i=0;i<this.data?.length; i++){
        this.addDetail();
      }
      (<FormArray>this.formGrp.get("dutyRequest")).reset(this.data.value);

      for(let j=0;j<(<FormArray>this.formGrp.get("dutyRequest"))?.length;j++){
        this.getDiff(j);
        this.checkinShiftWindow(j);
        this.checkoutShiftWindow(j);
        if((<FormArray>this.formGrp.get("dutyRequest"))?.controls?.[j]?.get('reason')?.errors?.maxlength)
          (<FormArray>this.formGrp.get("dutyRequest"))?.controls?.[j]?.get('reason')?.markAsTouched();
      }
    }

    this.getShiftDropdownAPI()
  }

    // Date Format
    getDateFormat(){
      this.matDateFormat.display.dateInput = this.appServ.getdatepickerformat();
      if(this.matDateFormat.display.dateInput == ''){
        setTimeout(() => {
          this.getDateFormat();
        }, 1000);
      }
    }


  newDetail() : FormGroup {
    return this.fb.group({
      "onduty_date" : [,[Validators.required]],
      "checkin_time"       : [,[Validators.required]],
      "checkout_time"      : [,[Validators.required]],
      "checkin_date" : [,[Validators.required]],
      "checkout_date" : [,[Validators.required]],
      "reason"         : [,[Validators.required,Validators.maxLength(500)]],
      "hours"          : [,[Validators.required]],
      "shift_mapping"  : [,[Validators.required]],
      "shift_name"     : [null],
      "shift_name_code": [null],
      "shift_checkin_date"   : [],
      "shift_checkout_date"  : [],

      "allowed_checkin_date"  : [],
      "allowed_checkin_time"  : [],
      "allowed_checkout_date" : [],
      "allowed_checkout_time" : [],
    },
    {
      validators: [CustomDateValidators.checkoutDate('checkout_date', 'shift_checkin_date','shift_checkout_date'),CustomDateValidators.checkinDate('checkin_date', 'shift_checkin_date')]
    }

    )
  }

  addDetail(){
    this.assignForm().push(this.newDetail())
  }

  assignForm() :  FormArray{
    return this.formGrp.get('dutyRequest') as FormArray
  }

  removeDetail(i:number) {
    this.assignForm().removeAt(i);
    this.formData = (<FormArray>this.formGrp.get("dutyRequest")).value;
    this.editDetails.emit(i);
    if((<FormArray>this.formGrp.get("dutyRequest"))?.length == 0)
     this.canSave = false;
    else
     this.canSave = true;
  }

  disableDate(){
    return false;
  }

  getShiftDropdownAPI(){
    this.shiftSer.shiftDropDownData(true).subscribe((res:any)=>{
      this.shiftDropdown = res
    })
  }

  /**
 * Takes 1 parameter ie.Index and return the diff of check in and check out
 * @param i first input
 * @returns return the diff of check in and check out
 */

  getDiff(i:number){
    if(this.assignForm().at(i).get('checkout_time')?.value !== '' && this.assignForm().at(i).get('checkout_time')?.value !== null && this.assignForm().at(i).get('checkin_time')?.value !== '' && this.assignForm().at(i).get('checkout_date')?.value !== '' && this.assignForm().at(i).get('checkin_date')?.value !== ''){
      var start_time=moment(this.assignForm().at(i).get('checkin_time')?.value,'HH:mm').format('HH:mm')
      var end_time=moment(this.assignForm().at(i).get('checkout_time')?.value,'HH:mm').format('HH:mm')
      var start_date = moment(this.assignForm().at(i).get('checkin_date')?.value).format('YYYY-MM-DD')
      var end_date = moment(this.assignForm().at(i).get('checkout_date')?.value).format('YYYY-MM-DD')

      var start_datetime = moment( start_date+ 'T' + start_time);
      var end_datetime = moment(end_date + 'T' + end_time);

      // Calculate the time difference in milliseconds
      var durationMs = end_datetime.diff(start_datetime);

      // Convert milliseconds to hours, minutes, and seconds
      var durationHours = Math.floor(durationMs / 3600000);
      var durationMinutes = Math.floor((durationMs % 3600000) / 60000);
      // var durationSeconds = Math.floor((durationMs % 60000) / 1000);
      var formattedHours = String(durationHours).padStart(2, '0');
      var formattedMinutes = String(durationMinutes).padStart(2, '0');
      this.assignForm().at(i).patchValue({
        'hours' : formattedHours+':'+formattedMinutes
      })
    }
    this.cd.detectChanges()
  }

  // Make null for checkout date, checkout time and hours when the check in are after check out data
  makeOutNull(i:any){
    let check = false

    if(this.assignForm().at(i).get('checkout_time')?.value !== '' && this.assignForm().at(i).get('checkout_date')?.value !== '' && this.assignForm().at(i).get('checkin_date')?.value !== '' && this.assignForm().at(i).get('checkin_time')?.value !== ''){
      let start_time = moment(this.assignForm().at(i).get('checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      let start_date = moment(this.assignForm().at(i).get('checkin_date')?.value).format('YYYY-MM-DD')
      let end_date = moment(this.assignForm().at(i).get('checkout_date')?.value).format('YYYY-MM-DD')
      let end_time = moment(this.assignForm().at(i).get('checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss')

      if(this.assignForm().controls[i].errors?.checkin || this.assignForm().controls[i].errors?.fromToDate1){
        this.assignForm().at(i).patchValue({
          'checkout_time' : null,
          'hours' : '00:00'
        })
        this.assignForm().at(i).get('checkout_time')?.markAsTouched()

        check = true
      }else if (moment(start_date,'YYYY-MM-DD').isSame(moment(end_date,'YYYY-MM-DD'), 'day')) {
        if(moment(start_time,'HH:mm:ss').isAfter(moment(end_time,'HH:mm:ss'))){
          this.assignForm().at(i).patchValue({
            'checkout_time' : null,
            'hours' : '00:00'
          })
        this.assignForm().at(i).get('checkout_time')?.markAsTouched()
          check = true
        }
      }
    }
   return check

  }

  // If dates are same the check out should start from check in or from 00:000
  isSameDates(i:any): boolean {
    let start_date = moment(this.assignForm().at(i).get('checkin_date')?.value).format('YYYY-MM-DD')
    let end_date = moment(this.assignForm().at(i).get('checkout_date')?.value).format('YYYY-MM-DD')
    return moment(start_date,'YYYY-MM-DD').isSame(moment(end_date,'YYYY-MM-DD'), 'day');
  }


  // Check in and out dates are there on initial, when i change check in value which is after check out date then checkout date should be null setted.
  isBeforeDates(i:any): boolean {
    let check = false
    if((this.assignForm().at(i).get('checkout_date')?.value != '' || this.assignForm().at(i).get('checkout_date')?.value != null) && (this.assignForm().at(i).get('checkout_time')?.value == '' || this.assignForm().at(i).get('checkout_time')?.value == null)){
      let start_date = moment(this.assignForm().at(i).get('checkin_date')?.value).format('YYYY-MM-DD')
      let end_date = moment(this.assignForm().at(i).get('checkout_date')?.value).format('YYYY-MM-DD')
      check = moment(start_date,'YYYY-MM-DD').isAfter(moment(end_date,'YYYY-MM-DD'), 'day');
      if(check){
        this.assignForm().at(i).patchValue({
          'checkout_date' : null
        })
        this.assignForm().at(i).get('checkout_date')?.markAsTouched()
      }
    }
    return check
  }

  setForm(){
    this.canSave = false;
    this.submitmethod.emit()
  }

  
  checkValidation(i:any,control:any){
    let check = false

    if(this.assignForm().at(i).get('checkout_time')?.value !== '' && this.assignForm().at(i).get('checkout_date')?.value !== '' && this.assignForm().at(i).get('checkin_date')?.value !== '' && this.assignForm().at(i).get('checkin_time')?.value !== '' && this.assignForm().at(i).get('allowed_checkin_date')?.value !== '' && this.assignForm().at(i).get('allowed_checkin_time')?.value !== ''){
      var start_time = moment(this.assignForm().at(i).get('checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      let start_date = moment(this.assignForm().at(i).get('checkin_date')?.value).format('YYYY-MM-DD')
      let end_date = moment(this.assignForm().at(i).get('checkout_date')?.value).format('YYYY-MM-DD')
      let end_time = moment(this.assignForm().at(i).get('checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss')

       if (moment(start_date,'YYYY-MM-DD').isSame(moment(end_date,'YYYY-MM-DD'), 'day')) {
        if(moment(start_time,'HH:mm:ss').isSame(moment(end_time,'HH:mm:ss'))){
          this.assignForm().at(i).get(control)?.setErrors({
            'sametime' : true
          })

          check = true
        }
      }
    }
   return check

  }

  checkinShiftWindow(i:any){
    let check = false

    if(this.assignForm().at(i).get('checkout_time')?.value !== '' && this.assignForm().at(i).get('checkout_date')?.value !== '' && this.assignForm().at(i).get('checkin_date')?.value !== '' && this.assignForm().at(i).get('checkin_time')?.value !== '' && this.assignForm().at(i).get('allowed_checkin_date')?.value !== '' && this.assignForm().at(i).get('allowed_checkin_time')?.value !== '' && this.assignForm().at(i).get('allowed_checkout_date')?.value !== '' && this.assignForm().at(i).get('allowed_checkout_time')?.value !== ''){

      var start_time = moment(this.assignForm().at(i).get('checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      let start_date = moment(this.assignForm().at(i).get('checkin_date')?.value).format('YYYY-MM-DD')


      let allowed_checkin_date = moment(this.assignForm().at(i).get('allowed_checkin_date')?.value).format('YYYY-MM-DD');
      let allowed_checkin_time = moment(this.assignForm().at(i).get('allowed_checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss');

      if (moment(start_date,'YYYY-MM-DD').isSame(moment(allowed_checkin_date,'YYYY-MM-DD'), 'day')){
        if(moment(start_time,'HH:mm:ss').isBefore(moment(allowed_checkin_time,'HH:mm:ss'))){
          this.assignForm().at(i).get('checkin_time')?.setErrors({
            'outoffshift' : true
          })
          this.assignForm().at(i).get('checkin_time')?.markAsTouched()
        }
      }
    }
    return check;
  }

  checkoutShiftWindow(i:any){
    let check = false

    if(this.assignForm().at(i).get('checkout_time')?.value !== '' && this.assignForm().at(i).get('checkout_date')?.value !== '' && this.assignForm().at(i).get('checkin_date')?.value !== '' && this.assignForm().at(i).get('checkin_time')?.value !== '' && this.assignForm().at(i).get('allowed_checkin_date')?.value !== '' && this.assignForm().at(i).get('allowed_checkin_time')?.value !== '' && this.assignForm().at(i).get('allowed_checkout_date')?.value !== '' && this.assignForm().at(i).get('allowed_checkout_time')?.value !== ''){

      let end_date = moment(this.assignForm().at(i).get('checkout_date')?.value).format('YYYY-MM-DD')
      let end_time = moment(this.assignForm().at(i).get('checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss')

      let allowed_checkout_date = moment(this.assignForm().at(i).get('allowed_checkout_date')?.value).format('YYYY-MM-DD');
      let allowed_checkout_time = moment(this.assignForm().at(i).get('allowed_checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss');

      if (moment(end_date,'YYYY-MM-DD').isSame(moment(allowed_checkout_date,'YYYY-MM-DD'), 'day')){
        if(moment(end_time,'HH:mm:ss').isAfter(moment(allowed_checkout_time,'HH:mm:ss'))){
          this.assignForm().at(i).get('checkout_time')?.setErrors({
            'outoffshift' : true
          })
          this.assignForm().at(i).get('checkout_time')?.markAsTouched()
        }
      }

    }
   return check
  }
  
}
