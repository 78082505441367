<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Statutory</h5>
        <button class="btn-reset text-light-600" routerLink="/statutory"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <div class="container-fluid p-24" style="position:relative;top:3.625rem;">
        <div class="row mb-24">
            <div class="col-md-4 col-lg-6">
                <h3>Statutory</h3>
                <p class="text-helper mb-md-0">Configure statutory details and get started with the transactions
                    within no time</p>
            </div>
            <div class="col-md-8 col-lg-6">
                <div class="d-inline-flex float-end">
                    <app-financial-year [(ngModel)]="selectedYear"
                        (selectYear)="yearChange($event)"></app-financial-year>
                </div>
            </div>
        </div>
        <div class="row row-24" *ngIf="permissions">
            <!-- Loader updations -->
            <ng-container *ngIf="loader">
                <app-common-loader class="col-md-6 col-sm-12" [cardType]="'card5'"
                    *ngFor="let dummy of ' '.repeat(6).split(''), let x = index"></app-common-loader>
            </ng-container>
            <ng-container *ngIf="!loader">
              <div class="col-12">
                <div *ngIf="noData;else all_data " class="row pt-4" style="height: calc(100vh - 12rem);">
                    <app-nodata-view class="vstack" [noDataImg]="'bg11.png'"  [noDataText]="'No data found'" [hasPermission]="false"> </app-nodata-view>
                </div>
                </div>
                <ng-template #all_data>
                    <div class="col-md-6 col-sm-12" *ngFor="let i of statutory">
                        <div class="card card-custom2 border radius-4 h-100">
                            <div
                                class="card-body status  d-flex flex-column align-items-start {{i.status  ? 'status-success' : 'status-pending'}}">
                                <h6>{{ i.name }}</h6>
                                <p class="fs-13 text-light-400  mb-auto">
                                    {{ i.text }}
                                </p>
                                <ng-container *ngIf="permissions.v && i.status == true">
                                    <button (click)="selectSidebar(i.id,i.status)"
                                        class="btn mt-16 btn-outline-primary fs-13 text-uppercase px-32 h-auto radius-2">Details
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="permissions.a && i.status == false">
                                    <button [disabled]="(isActive == 'false')" (click)="selectSidebar(i.id,i.status)"
                                        class="btn mt-16 btn-outline-primary fs-13 text-uppercase px-32 h-auto radius-2">Review
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
<div class="side-panel" style="--sidepanel-width: 720px" [class.side-pane-active]="taxSettings === true">
    <form class="side-panel-container" [formGroup]="taxForm" (ngSubmit)="taxFormSubmit()" autocomplete="off">
        <header class="side-panel-head">
            <h5 class="d-flex align-items-center">
                <span
                    class="status-icon {{statutory[0]['status']  ? 'status-success' : 'status-pending' }} me-12"></span>
                Tax
            </h5>
            <a class="toggle-panel" (click)="taxSettings = false; taxSubmitted = false;taxSubmitClicked=false"><i
                    class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
            <div class="container-fluid p-32">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1"></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24">
                        <div class="col-12" *ngIf="alertShow == 'show' ">
                            <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
                                <p class="mb-0"><span class="text-warning">Note:
                                    </span> This settings cannot be changed in the middle of the financial year </p>
                                <button class="btn-close" type="button" (click)="alertShow = 'hide' "></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="text-uppercase fw-500 mb-0">EXEMPTION</p>
                                </div>
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">For how many children education allowance
                                        would be
                                        admissible?
                                        <span class=" ms-8">
                                            <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Maximum upto 2 children are eligible for education allowance."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i>
                                        </span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="child_education"
                                        formControlName="child_education" id=""
                                        placeholder="{{this.messageService.placeholderdisp('children count')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.child_education.dirty || f.child_education.touched) && f.child_education.errors}">
                                    <div *ngIf="(taxSubmitted || f.child_education.touched || f.child_education.dirty) && f.child_education.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.child_education.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.child_education.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="f.child_education.errors?.['maxlength'] && !f.child_education.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','2')}}
                                    </div>
                                </div>
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Maximum tuition fees exemption for
                                        children going to school (annually)?
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Maximum of  ₹1200 can be claimed as tax exemption for tution fees per year for upto maximum 2 children."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="max_tuition_fees"
                                        formControlName="max_tuition_fees" id=""
                                        placeholder="{{this.messageService.placeholderdisp('tuition fees exemption')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.max_tuition_fees.dirty || f.max_tuition_fees.touched) && f.max_tuition_fees.errors}">
                                    <div *ngIf="(taxSubmitted || f.max_tuition_fees.touched || f.max_tuition_fees.dirty) && f.max_tuition_fees.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.max_tuition_fees.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.max_tuition_fees.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}
                                    </div>
                                    <div *ngIf="f.max_tuition_fees.errors?.['maxlength'] && !f.max_tuition_fees.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}}
                                    </div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required">
                                        Maximum amount exemption for children going to hostel (annually)?
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="max_hostle_fees"
                                        formControlName="max_hostle_fees" id=""
                                        placeholder="{{this.messageService.placeholderdisp('hostel exemption')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.max_hostle_fees.dirty || f.max_hostle_fees.touched) && f.max_hostle_fees.errors}">
                                    <div *ngIf="(taxSubmitted || f.max_hostle_fees.touched || f.max_hostle_fees.dirty) && f.max_hostle_fees.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.max_hostle_fees.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.max_hostle_fees.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="f.max_hostle_fees.errors?.['maxlength']  && !f.max_hostle_fees.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                                <!-- <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Maximum net taxable amount to avail tax
                                        credit
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Net taxable amount is the amount left after total deductions from the taxable income. The maximum limit to avail tax credit is ₹500000/-."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="max_net_tax"
                                        formControlName="max_net_tax" id=""
                                        placeholder="{{this.messageService.placeholderdisp('taxable amount')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.max_net_tax.dirty || f.max_net_tax.touched) && f.max_net_tax.errors}">
                                    <div *ngIf="(taxSubmitted || f.max_net_tax.touched || f.max_net_tax.dirty) && f.max_net_tax.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.max_net_tax.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.max_net_tax.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="f.max_net_tax.errors?.['maxlength'] && !f.max_net_tax.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div> -->
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Tax rebate (87A) for old tax regime applicable upto taxable income of
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Tax Rebate 87(A) of the Income Tax Act offers a rebate to individual taxpayers on their tax liability. In the old tax regime, this rebate is available for individuals with a net taxable income of ₹5 lakh or less."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0 ">
                                    <input type="text" class="form-control" name="tax_rebate_87A"
                                        formControlName="tax_rebate_87A" id=""
                                        placeholder="{{this.messageService.placeholderdisp('amount')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.tax_rebate_87A .dirty || f.tax_rebate_87A.touched) && f.tax_rebate_87A.errors}">
                                    <div *ngIf="(taxSubmitted || f.tax_rebate_87A.touched || f.tax_rebate_87A .dirty) && f.tax_rebate_87A.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.tax_rebate_87A.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.tax_rebate_87A.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="f.tax_rebate_87A.errors?.['maxlength'] && !f.tax_rebate_87A.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Tax rebate (87A) for new tax regime applicable upto taxable income of
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Tax Rebate 87(A) of the Income Tax Act offers a rebate to individual taxpayers on their tax liability. In the new tax regime, this rebate is available for individuals with a net taxable income of ₹7 lakh or less."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0 ">
                                    <input type="text" class="form-control" name="newregima_tax_rebate_87A"
                                        formControlName="newregima_tax_rebate_87A" id=""
                                        placeholder="{{this.messageService.placeholderdisp('amount')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.newregima_tax_rebate_87A.dirty || f.newregima_tax_rebate_87A.touched) && f.newregima_tax_rebate_87A.errors}">
                                    <div *ngIf="(taxSubmitted || f.newregima_tax_rebate_87A.touched || f.newregima_tax_rebate_87A.dirty) && f.newregima_tax_rebate_87A.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.newregima_tax_rebate_87A.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.newregima_tax_rebate_87A.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="f.newregima_tax_rebate_87A.errors?.['maxlength'] && !f.newregima_tax_rebate_87A.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Specify standard deduction for new tax regime
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="The standard deduction is a flat reduction from the employee's total salary for the financial year. Taxpayers are eligible for the standard deduction of Rs.75,000 under the new regime."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="newregima_standard_deduction"
                                        formControlName="newregima_standard_deduction" id=""
                                        placeholder="{{this.messageService.placeholderdisp('standard deduction')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.newregima_standard_deduction.dirty || f.newregima_standard_deduction.touched) && f.newregima_standard_deduction.errors}">
                                    <div *ngIf="(taxSubmitted || f.newregima_standard_deduction.touched || f.newregima_standard_deduction.dirty) && f.newregima_standard_deduction.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.newregima_standard_deduction.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.newregima_standard_deduction.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}
                                    </div>
                                    <div *ngIf="f.newregima_standard_deduction.errors?.['maxlength'] && !f.newregima_standard_deduction.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Specify standard deduction for old tax regime
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="The standard deduction is a flat reduction from the employee's total salary for the financial year. Taxpayers are eligible for the standard deduction of Rs.50,000 under the old regime."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="special_standard_deduction"
                                        formControlName="special_standard_deduction" id=""
                                        placeholder="{{this.messageService.placeholderdisp('standard deduction')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.special_standard_deduction.dirty || f.special_standard_deduction.touched) && f.special_standard_deduction.errors}">
                                    <div *ngIf="(taxSubmitted || f.special_standard_deduction.touched || f.special_standard_deduction.dirty) && f.special_standard_deduction.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.special_standard_deduction.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.special_standard_deduction.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}
                                    </div>
                                    <div *ngIf="f.special_standard_deduction.errors?.['maxlength'] && !f.special_standard_deduction.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Superannuation exemption limit
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Superannuation is the amount contributed by the employer to the employee which can be used as retirement benefit. Upto ₹1 lakh towards employee’s superannuation fund is exempted from taxation. . Any amount above ₹1 lakh will be subject to taxation."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="superannuation_exempt_limit"
                                        formControlName="superannuation_exempt_limit" id=""
                                        placeholder="{{this.messageService.placeholderdisp('limit')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.superannuation_exempt_limit.dirty || f.superannuation_exempt_limit.touched) && f.superannuation_exempt_limit.errors}">
                                    <div *ngIf="(taxSubmitted || f.superannuation_exempt_limit.touched || f.superannuation_exempt_limit.dirty) && f.superannuation_exempt_limit.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.superannuation_exempt_limit.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.superannuation_exempt_limit.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}
                                    </div>
                                    <div *ngIf="f.superannuation_exempt_limit.errors?.['maxlength'] && !f.superannuation_exempt_limit.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                                <div class="col-12 form-row ">
                                    <label for="" class="form-label required">Lifetime tax exemption limit for leave
                                        encashment, at settlement
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Enter the lifetime max limit for Leave encashment at settlement."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="leave_encashment_exempt_limit"
                                        formControlName="leave_encashment_exempt_limit" id=""
                                        placeholder="{{this.messageService.placeholderdisp('limit')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.leave_encashment_exempt_limit?.dirty || f.leave_encashment_exempt_limit?.touched) && f.leave_encashment_exempt_limit?.errors}">
                                    <div *ngIf="(taxSubmitted || f.leave_encashment_exempt_limit?.touched || f.leave_encashment_exempt_limit?.dirty) && f.leave_encashment_exempt_limit?.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.leave_encashment_exempt_limit?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.leave_encashment_exempt_limit?.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}
                                    </div>
                                    <div *ngIf="f.leave_encashment_exempt_limit?.errors?.['maxlength'] && !f.leave_encashment_exempt_limit?.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="text-uppercase fw-500 mb-0">INVESTMENT</p>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required">Specify maximum number of requests for
                                        proposed investment
                                        declaration
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Set a request limit for proposed investment declarations in a financial year."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="max_pro_investment_declare_request"
                                        formControlName="max_pro_investment_declare_request" id=""
                                        placeholder="{{this.messageService.placeholderdisp('maximum requests')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.max_pro_investment_declare_request.dirty || f.max_pro_investment_declare_request.touched) && f.max_pro_investment_declare_request.errors}">
                                    <div *ngIf="(taxSubmitted || f.max_pro_investment_declare_request.touched || f.max_pro_investment_declare_request.dirty) && f.max_pro_investment_declare_request.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.max_pro_investment_declare_request.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.max_pro_investment_declare_request.errors?.['pattern']"
                                        class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}
                                    </div>
                                    <div *ngIf="f.max_pro_investment_declare_request.errors?.['maxlength'] && !f.max_pro_investment_declare_request.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}}</div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required">Specify maximum number of requests for
                                        confirmed investment
                                        declaration
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Set a request limit for confirmed investment declarations in a financial year."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="max_con_investment_declare_request"
                                        formControlName="max_con_investment_declare_request" id=""
                                        placeholder="{{this.messageService.placeholderdisp('maximum requests')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.max_con_investment_declare_request.dirty || f.max_con_investment_declare_request.touched) && f.max_con_investment_declare_request.errors}">
                                    <div *ngIf="(taxSubmitted || f.max_con_investment_declare_request.touched || f.max_con_investment_declare_request.dirty) && f.max_con_investment_declare_request.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.max_con_investment_declare_request.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.max_con_investment_declare_request.errors?.['pattern']"
                                        class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}
                                    </div>
                                    <div *ngIf="f.max_con_investment_declare_request.errors?.['maxlength'] && !f.max_con_investment_declare_request.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}}</div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="from_month_tax_process_investment" class="form-label required">
                                        From which month tax should be processed considering confirmed documents
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Select the month from which the tax should be processed based on the confirmed investment declaration documents provided by the employee."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <app-master-select
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.from_month_tax_process_investment.touched) && f.from_month_tax_process_investment.errors}"
                                        [placeholder]="monthDropdownPlaceHolder" id="from_month_tax_process_investment"
                                        [submitted]="taxSubmitted" [isrequired]="isrequired"
                                        [selectedItem]="f.from_month_tax_process_investment.value"
                                        formControlName="from_month_tax_process_investment"
                                        [type]="listOfMonth"></app-master-select>
                                    <div *ngIf="(taxSubmitted || f.from_month_tax_process_investment.touched) && f.from_month_tax_process_investment.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.from_month_tax_process_investment.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="from_month_pay_reimburse_per_bill" class="form-label required">
                                        From which month tax for reimbursement be processed as per the bill submitted
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Select from which month the reimbursements should be processed based on the bills submitted by the employee."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <app-master-select
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.from_month_pay_reimburse_per_bill.touched) && f.from_month_pay_reimburse_per_bill.errors}"
                                        [placeholder]="monthDropdownPlaceHolder" id="from_month_pay_reimburse_per_bill"
                                        [submitted]="taxSubmitted" [isrequired]="isrequired"
                                        [selectedItem]="f.from_month_pay_reimburse_per_bill.value"
                                        formControlName="from_month_pay_reimburse_per_bill"
                                        [type]="listOfMonth"></app-master-select>
                                    <div *ngIf="(taxSubmitted || f.from_month_pay_reimburse_per_bill.touched) && f.from_month_pay_reimburse_per_bill.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.from_month_pay_reimburse_per_bill.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-12 form-row">
                                      <label for="" class="form-label required mb-8">Do you want to send confirmed investment
                                          declaration alert </label>
                                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes if you want to send an alert for confirmed investment." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                  </div>
                                  <div class="col-lg-12 form-row mb-24">
                                      <div class="form-check form-check-inline">
                                          <input class="form-check-input" name="send_confirm_investment_alert" formControlName = "send_confirm_investment_alert" type="radio" id="send_confirm_investment_alert1" [value]=true checked (ngModelChange)="setConditionalValue('tax_settings')">
                                          <label class="form-check-label" for="send_confirm_investment_alert1">
                                              Yes
                                          </label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                          <input class="form-check-input" name="send_confirm_investment_alert" formControlName = "send_confirm_investment_alert" type="radio" id="send_confirm_investment_alert2" [value]=false (ngModelChange)="setConditionalValue('tax_settings')">
                                          <label class="form-check-label" for="send_confirm_investment_alert2">
                                              No
                                          </label>
                                      </div>
                                  </div> -->
                                <!-- <div *ngIf="this.taxForm.get('send_confirm_investment_alert')?.value" class="col-12 form-row">
                                      <label for="" class="form-label required">Specify days
                                      </label>
                                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify the days to process the investment declaration submitted by the employee." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                  </div> -->
                                <!-- <div *ngIf="this.taxForm.get('send_confirm_investment_alert')?.value" class="col-md-6 form-row mb-24">
                                      <input type="text"  max="100" class="form-control" name="specify_days" formControlName = "specify_days" id=""
                                          placeholder="{{this.messageService.placeholderdisp('Days')}}" [ngClass]="{ 'is-invalid' : (taxSubmitted || f.specify_days.dirty || f.specify_days.touched) && f.specify_days.errors}">
                                      <div *ngIf="(taxSubmitted || f.specify_days.touched || f.specify_days.dirty) && f.specify_days.errors " class="invalid-feedback">
                                          <div *ngIf="f.specify_days.errors.required">{{this.messageService.validationDisplay('required')}}
                                          </div>
                                      </div>
                                      <div *ngIf="f.specify_days.errors?.['pattern']" class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}</div>
                                      <div *ngIf="f.specify_days.errors?.['max'] && !f.specify_days.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('days','365')}}                         </div>
                                  </div> -->
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required ">HRA exemption calculation
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Choose the appropriate options (Prorate with paid days, DOJ, Projected DOL) or Actual rent paid amount for HRA exemption calculation. If an employee joins or quits in the middle of the month, you can specify whether you want the entire HRA or a prorated HRA for that month based on the employee’s date of joining or date of relieving in this setting."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-lg-12 form-row mt-0">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="HRA_exempt_calculation"
                                            formControlName="HRA_exempt_calculation" type="radio"
                                            id="HRA_exempt_calculation1" checked [value]=true>
                                        <label class="form-check-label" for="HRA_exempt_calculation1">
                                            Prorate with paid days, DOJ, Projected DOL
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="HRA_exempt_calculation"
                                            formControlName="HRA_exempt_calculation" type="radio"
                                            id="HRA_exempt_calculation2" [value]=false>
                                        <label class="form-check-label" for="HRA_exempt_calculation2">
                                            Actual rent paid amount
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required">Actual rent paid limit to ensure PAN
                                        availability of landlord
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="If the payment of rent is more than Rs 1 lakh per annum, then the PAN of the house owner will be required to be submitted."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="actual_rent_paid_limit"
                                        formControlName="actual_rent_paid_limit" id=""
                                        placeholder="{{this.messageService.placeholderdisp('limit')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.actual_rent_paid_limit.dirty || f.actual_rent_paid_limit.touched) && f.actual_rent_paid_limit.errors}">
                                    <div *ngIf="(taxSubmitted || f.actual_rent_paid_limit.touched || f.actual_rent_paid_limit.dirty) && f.actual_rent_paid_limit.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.actual_rent_paid_limit.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.actual_rent_paid_limit.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="f.actual_rent_paid_limit.errors?.['maxlength'] && !f.actual_rent_paid_limit.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}} </div>
                                </div>
                                <!-- <div class="col-12 form-row">
                                      <label for="" class="form-label required">Loss from house property exemption limit
                                      </label>
                                       <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="An employee can claim deduction under Section 24 of interest paid on home loan for each of the houses separately. However, the overall loss from house property that can be claimed for a year is limited to ₹ 2 lakhs." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                  </div>
                                  <div class="col-md-6 form-row mb-24">
                                      <input type="text" class="form-control" name="loss_houseproperty_exempt_limit" formControlName = "loss_houseproperty_exempt_limit" id=""
                                      placeholder="{{this.messageService.placeholderdisp('Limit')}}" [ngClass]="{ 'is-invalid' : (taxSubmitted || f.loss_houseproperty_exempt_limit.dirty || f.loss_houseproperty_exempt_limit.touched) && f.loss_houseproperty_exempt_limit.errors}">
                                      <div *ngIf="(taxSubmitted || f.loss_houseproperty_exempt_limit.touched || f.loss_houseproperty_exempt_limit.dirty) && f.loss_houseproperty_exempt_limit.errors " class="invalid-feedback">
                                          <div *ngIf="f.loss_houseproperty_exempt_limit.errors.required">{{this.messageService.validationDisplay('required')}}
                                          </div>
                                      </div>
                                      <div *ngIf="f.loss_houseproperty_exempt_limit.errors?.['pattern']" class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}</div>
                                      <div *ngIf="f.loss_houseproperty_exempt_limit.errors?.['maxlength'] && !f.loss_houseproperty_exempt_limit.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('num','10')}}                         </div>
                                  </div>
                                  <div class="col-12 form-row">
                                      <label for="" class="form-label required">Interest paid on housing loan exemption limit
                                      </label>
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="The maximum exemption amount that can be claimed from the interest paid on home loan is limited to ₹2 lakhs." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                  </div>
                                  <div class="col-md-6 form-row mb-24">
                                      <input type="text" class="form-control" name="interest_paid_houseloan_exempt_limit" formControlName = "interest_paid_houseloan_exempt_limit" id=""
                                          placeholder="{{this.messageService.placeholderdisp('Limit')}}" [ngClass]="{ 'is-invalid' : (taxSubmitted || f.interest_paid_houseloan_exempt_limit.dirty || f.interest_paid_houseloan_exempt_limit.touched) && f.interest_paid_houseloan_exempt_limit.errors}">
                                      <div *ngIf="(taxSubmitted || f.interest_paid_houseloan_exempt_limit.touched || f.interest_paid_houseloan_exempt_limit.dirty) && f.interest_paid_houseloan_exempt_limit.errors " class="invalid-feedback">
                                          <div *ngIf="f.interest_paid_houseloan_exempt_limit.errors.required">{{this.messageService.validationDisplay('required')}}
                                          </div>
                                      </div>
                                      <div *ngIf="f.interest_paid_houseloan_exempt_limit.errors?.['pattern']" class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}</div>
                                      <div *ngIf="f.interest_paid_houseloan_exempt_limit.errors?.['maxlength'] && !f.interest_paid_houseloan_exempt_limit.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('mum','10')}}                         </div>
                                  </div> -->
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row row-16">
                                <div class="col-12">
                                    <p class="text-uppercase mb-0 fw-500">TDS</p>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required">Education cess charges (%)
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="A small amount of your tax liability is paid to support government educational institutions with mid-day meals, infrastructure, and special schemes.  Maximum 3% to 4% is paid as education cess."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="education_cess_charge"
                                        formControlName="education_cess_charge" id=""
                                        placeholder="{{this.messageService.placeholderdisp('charges')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.education_cess_charge.dirty || f.education_cess_charge.touched) && f.education_cess_charge.errors}">
                                    <div *ngIf="(taxSubmitted || f.education_cess_charge.touched || f.education_cess_charge.dirty) && f.education_cess_charge.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.education_cess_charge.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                    </div>
                                    <div *ngIf="f.education_cess_charge.errors?.['pattern']" class="invalid-feedback">
                                        {{this.messageService.validationDisplay('pattern')}}</div>
                                    <div *ngIf="f.education_cess_charge.errors?.['max'] && !f.education_cess_charge.errors?.['pattern']"
                                        class="invalid-feedback">
                                        {{this.messageService.fieldlengthvalidation('percentage','100')}} </div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label mb-8 required">Do you want to deduct TDS in higher
                                        slab (if PAN not available)?
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="If the employee's PAN is not provided to the employer, the tax will be deducted at source under section 206 AA, either a rate of 20%."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class=" col-12 form-row  mt-0">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="TDS_higher_slab"
                                            formControlName="TDS_higher_slab" type="radio" id="TDS_higher_slab1" checked
                                            [value]=true>
                                        <label class="form-check-label" for="TDS_higher_slab1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="TDS_higher_slab"
                                            formControlName="TDS_higher_slab" type="radio" id="TDS_higher_slab2"
                                            [value]=false>
                                        <label class="form-check-label" for="TDS_higher_slab2">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <!-- //flat tax -->
                                <div class="col-12 form-row">
                                    <label for="" class="form-label mb-8 required">Do you want to deduct flat income tax for any employment type?
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="A flat tax is a single tax rate applied to selected employment type regardless of income. Employees under flat tax rate is not eligible for any income tax exemptions like 80C, 80D, etc. System will restrict the employees in selected employment type from raising any investment declarations. " popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class=" col-12 form-row  mt-0">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="deduct_flat_rate_tax" formControlName="deduct_flat_rate_tax" type="radio"  id="deduct_flat_rate_tax1"   [value]=true  (ngModelChange)="setflatTax(true)">
                                        <label class="form-check-label" for="deduct_flat_rate_tax1" > Yes </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="deduct_flat_rate_tax" formControlName="deduct_flat_rate_tax" type="radio" id="deduct_flat_rate_tax2" [value]=false  (ngModelChange)="setflatTax(false)">
                                        <label class="form-check-label" for="deduct_flat_rate_tax2">  No  </label>
                                    </div>
                                </div>

                                <ng-container  *ngIf="this.taxForm.get('deduct_flat_rate_tax')?.value">
                                    <div class="col-md-6 form-row">
                                        <label for="" class="form-label required">Employment Type</label>
                                        <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employment Type')}}" formControlName="tax_employment_type" [items]="emptypeDD" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect"  [ngClass]="{ 'is-invalid' : (taxSubmitted || f.tax_employment_type.dirty || f.tax_employment_type.touched) && f.tax_employment_type.errors}">
                                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}" /> Select All
                                            </ng-template>

                                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="d-inline-flex align-items-center">
                                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" class="me-8" />
                                                    {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                                                </div>
                                            </ng-template>
                                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                <div class="ng-value" *ngIf="taxForm.value.tax_employment_type.length ">
                                                    <span class="ng-value-label">{{taxForm.value.tax_employment_type.length }} Employment type Selected.</span>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                        <ul class="selected-values mt-16" *ngIf="taxForm.value.tax_employment_type.length != 0">
                                            <li class="ng-value" *ngFor="let item of taxForm.value.tax_employment_type,let ki =index">
                                            <ng-container *ngFor="let insideitem of emptypeDD, let k =index">
                                                <span class="ng-value-label" *ngIf="insideitem['name']==item">{{ (insideitem['name'].length>15)? (insideitem['name'] |slice:0:15)+'..':(insideitem['name']) }}</span>
                                            </ng-container>
                                            <span  class="ng-value-icon right" aria-hidden="true"  (click)="clearempType(ki)">×</span>
                                            </li>
                                        </ul>
                                        <div *ngIf="(taxSubmitted || f.tax_employment_type.dirty || f.tax_employment_type.touched) && f.tax_employment_type.errors " class="invalid-feedback">
                                            <div *ngIf="f.tax_employment_type.errors.required"> {{this.messageService.validationDisplay('required')}} </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-row">
                                        <label for="" class="form-label required">Tax rate</label>
                                        <input type="text" class="form-control" name="tax_rate" formControlName="tax_rate" id="" placeholder="{{this.messageService.placeholderdisp('tax rate')}}" [ngClass]="{ 'is-invalid' : (taxSubmitted || f.tax_rate.dirty || f.tax_rate.touched) && f.tax_rate.errors}">
                                        <div *ngIf="(taxSubmitted || f.tax_rate.touched || f.tax_rate.dirty) && f.tax_rate.errors " class="invalid-feedback">
                                            <div *ngIf="f.tax_rate.errors.required"> {{this.messageService.validationDisplay('required')}} </div>
                                            <div *ngIf="f.tax_rate.errors?.['pattern']"> {{this.messageService.validationDisplay('pattern')}}</div>
                                            <div *ngIf="f.tax_rate.errors?.['maxlength'] && !f.tax_rate.errors?.['pattern']"> {{this.messageService.fieldlengthvalidation('num','2')}} </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <!-- flat tax -->
                                <div class="col-12 form-row">
                                    <label for="" class="form-label mb-8 required">Do you want to show income tax of
                                        employees in challan whose salary is withheld? <span class=" ms-8"><i
                                                class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Choose Yes if you want to deposit income tax of employees whose salary is withheld."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class=" col-12 form-row  mt-0">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="deposit_income_tax_of_salary"
                                            formControlName="deposit_income_tax_of_salary" type="radio"
                                            id="deposit_income_tax_of_salary1" checked [value]=true>
                                        <label class="form-check-label" for="deposit_income_tax_of_salary1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="deposit_income_tax_of_salary"
                                            formControlName="deposit_income_tax_of_salary" type="radio"
                                            id="deposit_income_tax_of_salary2" [value]=false>
                                        <label class="form-check-label" for="deposit_income_tax_of_salary2">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label mb-8 required">Do you want to show employees whose
                                        income Tax Is Zero in challan?
                                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                                ngbPopover="Choose yes if you want to show employees whose income Tax is Zero."
                                                popoverClass="popover-default popover-info" placement="bottom"
                                                container="body" triggers="hover"></i></span>
                                    </label>
                                </div>
                                <div class=" col-12 form-row  mt-0">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="show_income_tax_zero_employee"
                                            formControlName="show_income_tax_zero_employee" type="radio"
                                            id="show_income_tax_zero_employee1" checked [value]=true>
                                        <label class="form-check-label" for="show_income_tax_zero_employee1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" name="show_income_tax_zero_employee"
                                            formControlName="show_income_tax_zero_employee" type="radio"
                                            id="show_income_tax_zero_employee2" [value]=false>
                                        <label class="form-check-label" for="show_income_tax_zero_employee2">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <!-- LTA block period start -->
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required">Current LTA block period (start year)
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="lta_block_period_start"
                                        formControlName="lta_block_period_start" id=""
                                        placeholder="{{this.messageService.placeholderdisp('year')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.lta_block_period_start.dirty || f.lta_block_period_start.touched) && f.lta_block_period_start.errors}"
                                        (keyup)="blockPeriodCalculation('lta_block_period_start')">
                                    <div *ngIf="(taxSubmitted || f.lta_block_period_start.touched || f.lta_block_period_start.dirty) && f.lta_block_period_start.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.lta_block_period_start.errors.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                        <div *ngIf="f.lta_block_period_start.errors?.['pattern']">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div
                                            *ngIf="f.lta_block_period_start.errors?.['maxlength'] && !f.lta_block_period_start.errors?.['pattern']">
                                            {{this.messageService.fieldlengthvalidation('num','4')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 form-row">
                                    <label for="" class="form-label required">Current LTA block period (end year)
                                    </label>
                                </div>
                                <div class="col-md-6 form-row mt-0">
                                    <input type="text" class="form-control" name="lta_block_period_end"
                                        formControlName="lta_block_period_end" id=""
                                        placeholder="{{this.messageService.placeholderdisp('year')}}"
                                        [ngClass]="{ 'is-invalid' : (taxSubmitted || f.lta_block_period_end.dirty || f.lta_block_period_end.touched) && (f.lta_block_period_end.errors || taxForm.errors!=null)}"
                                        (keyup)="blockPeriodCalculation('lta_block_period_end')">
                                    <div *ngIf="(taxSubmitted || f.lta_block_period_end.touched || f.lta_block_period_end.dirty) && (f.lta_block_period_end.errors || taxForm.errors!=null) "
                                        class="invalid-feedback">
                                        <div *ngIf="f.lta_block_period_end?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}
                                        </div>
                                        <div *ngIf="f.lta_block_period_end.errors?.['pattern']">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div
                                            *ngIf="f.lta_block_period_end.errors?.['maxlength'] && !f.lta_block_period_end.errors?.['pattern']">
                                            {{this.messageService.fieldlengthvalidation('num','4')}}
                                        </div>
                                        <div *ngIf="(!f.lta_block_period_end.errors?.['maxlength'] && !f.lta_block_period_end.errors?.['pattern']) && taxForm.errors!=null">{{this.messageService.greaterThanMessage('Current LTA block period (start year)', 'Current LTA block period (end year)')}}</div>
                                    </div>
                                    <div *ngIf="taxSubmitted && seletedYearValidation"
                                        class="col-12 form-row text-danger">
                                        Financial Year is not selected
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <footer *ngIf="!loader1" class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                [disabled]="statutory[0]['status']|| taxSubmitClicked == true">
                Lock
            </button>
        </footer>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width: 720px" [class.side-pane-active]="providentFund === true">
    <form class="side-panel-container" [formGroup]="pf_form" (ngSubmit)="pf_formSubmit()" autocomplete="off">
        <header class="side-panel-head">
            <h5 class="d-flex align-items-center">
                <span
                    class="status-icon {{statutory[1]['status']  ? 'status-success' : 'status-pending' }} me-12"></span>
                Provident fund
            </h5>
            <a class="toggle-panel" (click)="closeSidePanel('pf_form',false);pfSubmitClicked=false"><i
                    class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
            <div class="container-fluid p-32">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1"></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-16">
                        <div class="col-12">
                            <div role=" alert" class="alert alert-warning mb-0 {{alertShow}}">
                                <p class="mb-0"><span class="text-warning">Note:
                                    </span> This settings cannot be changed in the middle of the financial year </p>
                                <button class="btn-close" type="button" (click)="alertShow = 'hide' "></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="text-uppercase mb-0 fw-500">PROVIDENT FUND SETTINGS</p>
                        </div>
                        <div class="col-12 form-row">
                            <label for="" class="form-label mb-8 required">Do you want to apply for provident
                                fund?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Choose Yes If your company wants to apply for the provident Fund."
                                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div class="col-lg-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_PF" formControlName="apply_PF" type="radio"
                                    id="apply_PF1" [value]="true" checked
                                    (ngModelChange)="setConditionalValue('pf_form')">
                                <label class="form-check-label" for=" apply_PF1">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_PF" formControlName="apply_PF" type="radio"
                                    id="apply_PF2" [value]="false" (ngModelChange)="setConditionalValue('pf_form')">
                                <label class="form-check-label" for="apply_PF2">
                                    No
                                </label>
                            </div>
                        </div>
                        <!-- <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12 form-row">
                            <label for="" class="form-label required">Company PF number
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Update the company PF number in this field."
                                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                                        triggers="hover"></i></span>

                            </label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="" formControlName="company_PF_number" id=""
                                placeholder="Enter company PF number"
                                [ngClass]="{ 'is-invalid' :  (pf_formSubmitted || pf_f.company_PF_number.dirty || pf_f.company_PF_number.touched) && pf_f.company_PF_number.errors}">
                            <div *ngIf="(pf_formSubmitted || pf_f.company_PF_number.touched || pf_f.company_PF_number.dirty) && pf_f.company_PF_number.errors "
                                class="invalid-feedback">
                                <div *ngIf="pf_f.company_PF_number.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="pf_f.company_PF_number.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                        </div> -->
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12 form-row">
                            <label for="" class="form-label required">Employee contribution rate
                                <span class="ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Employee's contribution to PF is 12% of PF wages. Select the Employee PF contribution rate from the dropdown for PF calculation."
                                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-md-6 form-row mt-0">
                            <ng-select class="form-ngselect"
                                placeholder="{{this.messageService.selectplaceholddisplay('employee contribution rate')}}"
                                [readonly]="dropdownFlag"
                                [ngClass]="{ 'is-invalid' : (pf_formSubmitted ||  pf_f.employee_contribution_rate.dirty  || pf_f.employee_contribution_rate.touched) && pf_f.employee_contribution_rate.errors}"
                                formControlName="employee_contribution_rate" [items]="employee_contri_rate_pf"
                                bindValue="id" bindLabel="name">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <div class="text-trim" title="{{item.name}}">{{item.name}} </div>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="(pf_formSubmitted || pf_f.employee_contribution_rate.touched) && pf_f.employee_contribution_rate.errors"
                                class="invalid-feedback">
                                <div *ngIf="pf_f.employee_contribution_rate.errors.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                            </div>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12 form-row">
                            <label for="" class="form-label required">Employer contribution rate
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Employer's contribution to PF is 12% of PF wages. Select the Employer PF contribution rate from the dropdown for PF calculation."
                                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-md-6 form-row mt-0">
                            <ng-select class="form-ngselect"
                                placeholder="{{this.messageService.selectplaceholddisplay('employer contribution rate')}}"
                                (ngModelChange)="employerContribution()"
                                [ngClass]="{ 'is-invalid' : (pf_formSubmitted || pf_f.employer_contribution_rate.dirty  ||  pf_f.employer_contribution_rate.touched) &&  pf_f.employer_contribution_rate.errors}"
                                formControlName="employer_contribution_rate" [items]="employer_contri_rate_pf"
                                bindValue="id" bindLabel="name">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <div class="text-trim" title="{{item.name}}">{{item.name}}</div>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="(pf_formSubmitted ||  pf_f.employer_contribution_rate.touched) &&  pf_f.employer_contribution_rate.errors"
                                class="invalid-feedback">
                                <div *ngIf=" pf_f.employer_contribution_rate.errors.required">
                                    {{this.messageService.validationDisplay('required')}}</div>
                            </div>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12  form-row ">
                            <input type="checkbox" name="check" class="form-check-input ms-0"
                                formControlName="contribute_in_ctc">
                            <label class="form-label">Include employer contribution in the CTC</label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                    ngbPopover="Check this option if you want include employer's contribution in CTC."
                                    popoverClass="popover-default popover-info" placement="bottom" container="body"
                                    triggers="hover"></i></span>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12  form-row ">
                            <input type="checkbox" name="check" class="form-check-input ms-0"
                                formControlName="prorate_employer_pf" (change)="prorateSettings()">
                            <label class="form-label">Prorate PF as per paid days</label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value && this.pf_form.get('prorate_employer_pf')?.value"
                            class="col-12  form-row  ms-24">
                            <input type="checkbox" name="check" class="form-check-input ms-0"
                                formControlName="prorate_employee_pf">
                            <label class="form-label">Prorate employees’ PF Contribution limit as per paid
                                days</label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12  form-row ">
                            <input type="checkbox" name="check" class="form-check-input ms-0"
                                formControlName="include_admin_charge">
                            <label class="form-label">Include admin charges in employee CTC</label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                    ngbPopover="1% is paid by the employer to the PF department as admin charges. Check this option if you want to include this in employee's CTC."
                                    popoverClass="popover-default popover-info" placement="bottom" container="body"
                                    triggers="hover"></i></span>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12 form-row">
                            <label for="" class="form-label  required">Do you want to deposit PF for
                                employees whose salary is on hold?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Choose yes If you want to process PF for employees, whose salaries are on hold."
                                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class=" col-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="deposit_PF_from_salary"
                                    formControlName="deposit_PF_from_salary" type="radio" id="deposit_PF_from_salary1"
                                    [value]="true" checked>
                                <label class="form-check-label" for=" ">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="deposit_PF_from_salary"
                                    formControlName="deposit_PF_from_salary" type="radio" id="deposit_PF_from_salary2"
                                    [value]="false">
                                <label class="form-check-label" for="deposit_PF_from_salary2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12 form-row">
                            <label for="" class="form-label  required">Display PF for employees on salary
                                register whose salary is on hold?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Choose yes If you want to display the PF of the employees on the salary structure, whose salaries are on hold."
                                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class=" col-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="show_employee_PF_registration"
                                    formControlName="show_employee_PF_registration" type="radio"
                                    id="show_employee_PF_registration1" [value]="true" checked>
                                <label class="form-check-label" for="show_employee_PF_registration1">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="show_employee_PF_registration"
                                    formControlName="show_employee_PF_registration" type="radio"
                                    id="show_employee_PF_registration2" [value]="false">
                                <label class="form-check-label" for="show_employee_PF_registration2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class="col-12 form-row">
                            <label for="" class="form-label mb-8 required">Do you want to display employees on
                                challan whose PF is zero?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Choose yes if you want to display the employees in challan whose PF is zero."
                                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.pf_form.get('apply_PF')?.value" class=" col-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="show_employee_PF_challan_zero"
                                    formControlName="show_employee_PF_challan_zero" type="radio"
                                    id="show_employee_PF_challan_zero1" [value]="true" checked>
                                <label class="form-check-label" for="show_employee_PF_challan_zero1">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="show_employee_PF_challan_zero"
                                    formControlName="show_employee_PF_challan_zero" type="radio"
                                    id="show_employee_PF_challan_zero2" [value]="false">
                                <label class="form-check-label" for="show_employee_PF_challan_zero2">
                                    No
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="this.pf_form.get('apply_PF')?.value">
                        <div class="col-lg-12">
                            <div class="form-row">
                                <label for=" " class="form-label required">Roundoff EPF contribution on
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="This setting is for rounding off employee EPF contribution amounts."
                                            popoverClass="popover-default popover-info" placement="bottom"
                                            triggers="hover"></i></span>
                                </label>
                            </div>
                            <div class="">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"><input class="form-check-input" type="radio" value="None" formControlName="epf_roundoff"> None </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Nearest" formControlName="epf_roundoff"> Nearest </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Ceil" formControlName="epf_roundoff"> Ceil</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-row">
                                <label for=" " class="form-label required">Roundoff EPS contribution on
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="This setting is for rounding off employee EPS contribution amounts."
                                            popoverClass="popover-default popover-info" placement="bottom"
                                            triggers="hover"></i></span>
                                </label>
                            </div>
                            <div class="">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"><input class="form-check-input" type="radio" value="None" formControlName="eps_roundoff"> None </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Nearest" formControlName="eps_roundoff"> Nearest </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Ceil" formControlName="eps_roundoff"> Ceil</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-row">
                                <label for=" " class="form-label required">Roundoff EDLI contribution on
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="This setting is for rounding off employee EDLI contribution amounts."
                                            popoverClass="popover-default popover-info" placement="bottom"
                                            triggers="hover"></i></span>
                                </label>
                            </div>
                            <div class="">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"><input class="form-check-input" type="radio" value="None" formControlName="edli_roundoff"> None </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Nearest" formControlName="edli_roundoff"> Nearest </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Ceil" formControlName="edli_roundoff"> Ceil</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-row">
                                <label for=" " class="form-label required">Roundoff admin charges on
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="This setting is for rounding off admin charges amounts."
                                            popoverClass="popover-default popover-info" placement="bottom"
                                            triggers="hover"></i></span>
                                </label>
                            </div>
                            <div class="">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"><input class="form-check-input" type="radio" value="None" formControlName="admin_roundoff"> None </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Nearest" formControlName="admin_roundoff"> Nearest </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label"> <input class="form-check-input" type="radio" value="Ceil" formControlName="admin_roundoff"> Ceil</label>
                                </div>
                            </div>
                        </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <footer *ngIf="!loader1" class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm "
                [disabled]="statutory[1]['status']|| pfSubmitClicked == true">Lock </button>
        </footer>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width: 720px" [class.side-pane-active]="employeeStateInsurance === true">
    <form class="side-panel-container" [formGroup]="EmpStateInsuranceForm" (ngSubmit)="EmpStateInsuranceFormSubmit()"
        autocomplete="off">
        <header class="side-panel-head">
            <h5 class="d-flex align-items-center">
                <span
                    class="status-icon {{statutory[2]['status']  ? 'status-success' : 'status-pending' }} me-12"></span>
                Employee state insurance
            </h5>
            <a class="toggle-panel"
                (click)="employeeStateInsurance = false; esisubmitted = false;esiSubmitClicked = false"><i
                    class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
            <div class="container-fluid p-32">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1"></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1">

                    <div class="row row-16">
                        <div class="col-12" *ngIf="alertShow == 'show' ">
                            <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
                                <p class="mb-0"><span class="text-warning">Note:
                                    </span> ESI deductions is applicable only if the employee’s monthly salary is less
                                    than or equal to ₹21,000. If an employee receive a salary revision which increases
                                    their monthly salary above ₹21,000, they have to continue making ESI contributions
                                    till the end of the contribution period in which the salary was revised
                                    (April-September or October-March). </p>
                                <button class="btn-close" type="button" (click)="alertShow = 'hide' "></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="text-uppercase mb-0 fw-500">EMPLOYEE STATE INSURANCE SETTINGS</p>
                        </div>
                        <div class="col-12 form-row">
                            <label for="" class="form-label mb-8 required">Do you want to apply for ESI?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Select yes If your organization wants to apply for ESI."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div class="col-lg-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_ESI" type="radio" id="yes" [value]=false
                                    formControlName="apply_ESI" checked [value]=true
                                    (ngModelChange)="setConditionalValue('esi')">
                                <label class="form-check-label" for=" yes">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_ESI" type="radio" id="no" [value]=false
                                    formControlName="apply_ESI" [value]=false
                                    (ngModelChange)="setConditionalValue('esi')">
                                <label class="form-check-label" for="no">
                                    No
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="this.EmpStateInsuranceForm.get('apply_ESI')?.value">

                        <!-- <div class="col-12 form-row">
                            <label for="" class="form-label required">Company ESI number </label>
                        </div>
                        <div  class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="" id="" placeholder="Enter company ESI number"
                                formControlName="company_ESI_number"
                                [ngClass]="{ 'is-invalid' : (esisubmitted || esi.company_ESI_number.dirty  || esi.company_ESI_number.touched) && esi.company_ESI_number.errors}">
                            <div *ngIf="(esisubmitted || esi.company_ESI_number.touched || esi.company_ESI_number.dirty ) && esi.company_ESI_number.errors "
                                class="invalid-feedback">
                                <div *ngIf="esi.company_ESI_number.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="esi.company_ESI_number.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                        </div> -->
                        <div  class="col-12 form-row">
                            <label for="" class="form-label required">Wage ceiling for ESI contribution
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="The wage ceiling limit is limited to 21000/-."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="wageceilling_ESI_contribution"
                                formControlName="wageceilling_ESI_contribution" id=""
                                placeholder="{{this.messageService.placeholderdisp('wage ceiling')}}"
                                [ngClass]="{ 'is-invalid' : (esisubmitted || esi.wageceilling_ESI_contribution.dirty || esi.wageceilling_ESI_contribution.touched) && esi.wageceilling_ESI_contribution.errors}">
                            <div *ngIf="(esisubmitted || esi.wageceilling_ESI_contribution.touched || esi.wageceilling_ESI_contribution.dirty) && esi.wageceilling_ESI_contribution.errors "
                                class="invalid-feedback">
                                <div *ngIf="esi.wageceilling_ESI_contribution.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="esi.wageceilling_ESI_contribution.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div *ngIf="esi.wageceilling_ESI_contribution.errors?.['maxlength'] && !esi.wageceilling_ESI_contribution.errors?.['pattern']"
                                class="invalid-feedback">
                                {{this.messageService.fieldlengthvalidation('num','10')}}
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <label for="" class="form-label required">Employee contribution rate
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Employee contribution rate is 0.75% of Gross."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div  class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="employee_contribution"
                                formControlName="employee_contribution" id=""
                                placeholder="{{this.messageService.placeholderdisp('employee contribution rate')}}"
                                [ngClass]="{ 'is-invalid' : (esisubmitted || esi.employee_contribution.dirty || esi.employee_contribution.touched) && esi.employee_contribution.errors}">
                            <div *ngIf="(esisubmitted || esi.employee_contribution.touched || esi.employee_contribution.dirty) && esi.employee_contribution.errors "
                                class="invalid-feedback">
                                <div *ngIf="esi.employee_contribution.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="esi.employee_contribution.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div *ngIf="esi.employee_contribution.errors?.['maxlength'] && !esi.employee_contribution.errors?.['pattern']"
                                class="invalid-feedback">
                                {{this.messageService.fieldlengthvalidation('num','10')}}
                            </div>
                        </div>
                        <div  class="col-12 form-row">
                            <label for="" class="form-label required">Employer contribution rate
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Employer contribution rate is 3.25% of Gross."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div  class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="employer_contribution"
                                formControlName="employer_contribution" id=""
                                placeholder="{{this.messageService.placeholderdisp('employer contribution rate')}}"
                                [ngClass]="{ 'is-invalid' : (esisubmitted || esi.employer_contribution.dirty || esi.employer_contribution.touched) && esi.employer_contribution.errors}">
                            <div *ngIf="(esisubmitted || esi.employer_contribution.touched || esi.employer_contribution.dirty) && esi.employer_contribution.errors "
                                class="invalid-feedback">
                                <div *ngIf="esi.employer_contribution.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="esi.employer_contribution.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div *ngIf="esi.employer_contribution.errors?.['maxlength'] && !esi.employer_contribution.errors?.['pattern']"
                                class="invalid-feedback">
                                {{this.messageService.fieldlengthvalidation('num','10')}}
                            </div>
                        </div>
                        <div  class="col-12 form-row  ">
                            <input type="checkbox" name="check" class="form-check-input ms-0"
                                formControlName="contribute_in_ctc">
                            <label class="form-label">Include employer contribution in the CTC</label>
                            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                    ngbPopover="Enable this option if you want to Include employer contribution in the CTC."
                                    popoverClass="popover-default popover-info" container="body"
                                    triggers="hover"></i></span>
                        </div>

                        <!--  ROUND OFF CONFIGURATION NEW FILEDS -->
                        <div class="col-lg-12">
                            <div class="form-row">
                                <label for=" " class="form-label required">Roundoff employee ESI contribution
                                    on
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="This setting is for rounding off employee ESI contribution amounts."
                                            popoverClass="popover-default popover-info" placement="bottom"
                                            triggers="hover"></i></span>
                                </label>
                            </div>
                            <div class="">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="None"
                                        formControlName="employee_roundoff">
                                    <label class="form-check-label">
                                        None
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="Nearest"
                                        formControlName="employee_roundoff">
                                    <label class="form-check-label">
                                        Nearest
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="Ceil"
                                        formControlName="employee_roundoff">
                                    <label class="form-check-label">
                                        Ceil
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-row">
                                <label for=" " class="form-label required ">Roundoff employers ESI contribution
                                    on
                                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                            ngbPopover="This setting is for rounding off employers ESI contribution amounts."
                                            popoverClass="popover-default popover-info" placement="bottom"
                                            triggers="hover"></i></span>
                                </label>
                            </div>
                            <div class=" ">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="None"
                                        formControlName="employer_roundoff">
                                    <label class="form-check-label">
                                        None
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="Nearest"
                                        formControlName="employer_roundoff">
                                    <label class="form-check-label">
                                        Nearest
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="Ceil"
                                        formControlName="employer_roundoff">
                                    <label class="form-check-label">
                                        Ceil
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- END -->
                      </ng-container>

                    </div>
                </ng-container>
            </div>
        </div>
        <footer *ngIf="!loader1" class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm "
                [disabled]="statutory[2]['status'] || esiSubmitClicked == true">Lock</button>
        </footer>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width: 720px" [class.side-pane-active]="labourWelfareFund === true">
    <form class="side-panel-container" [formGroup]="LabourWelfareFundForm" (ngSubmit)="LabourWelfareFundFormSubmit()"
        autocomplete="off">
        <header class="side-panel-head">
            <h5 class="d-flex align-items-center">
                <span
                    class="status-icon {{statutory[3]['status']  ? 'status-success' : 'status-pending' }} me-12"></span>
                Labour welfare fund
            </h5>
            <a class="toggle-panel"
                (click)="labourWelfareFund = false;labourWelfareFundSubmitted=false;lwfSubmitClicked = false"><i
                    class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
            <div class="container-fluid p-32">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1"></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1">


                    <div class="row row-16">
                        <div class="col-12" *ngIf="alertShow == 'show' ">
                            <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
                                <p class="mb-0"><span class="text-warning">Note:
                                    </span> The Labour Welfare Fund Act was introduced by the government to provide
                                    employees with social security. Employees and employers both contribute to the LWF.
                                    The LWF limit varies from state to state. </p>
                                <button class="btn-close" type="button" (click)="alertShow = '' "></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="text-uppercase mb-0 fw-500">LABOUR WELFARE FUND SETTINGS</p>
                        </div>
                        <div class="col-12 form-row">
                            <label for="" class="form-label   required">Do you want to apply for labour
                                welfare fund?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="If your organization has applied for LWF, then select the option yes and fill the below details."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div class="col-lg-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_labourwelfare_fund" type="radio" id="yes"
                                    formControlName="apply_labourwelfare_fund" checked [value]=true>
                                <label class="form-check-label" for=" yes"
                                    (ngModelChange)="setConditionalValue('labour_welfare')">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_labourwelfare_fund" type="radio" id="no"
                                    formControlName="apply_labourwelfare_fund" [value]=false
                                    (ngModelChange)="setConditionalValue('labour_welfare')">
                                <label class="form-check-label" for="no">
                                    No
                                </label>
                            </div>
                        </div>
                        <div *ngIf="this.LabourWelfareFundForm.get('apply_labourwelfare_fund')?.value"
                            class="col-12 form-row">
                            <label for="" class="form-label  required">Do you want to process labour welfare
                                fund on settlement?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Select yes. If you want to process the LWF during employee's settlement."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.LabourWelfareFundForm.get('apply_labourwelfare_fund')?.value"
                            class=" col-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="process_LWF_settlement" type="radio" id="yes"
                                    formControlName="process_LWF_settlement" checked [value]=true>
                                <label class="form-check-label" for=" ">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="process_LWF_settlement"
                                    formControlName="process_LWF_settlement" type="radio" id="no" [value]=false>
                                <label class="form-check-label" for="Deduction">
                                    No
                                </label>
                            </div>
                        </div>
                        <!-- <div *ngIf="this.LabourWelfareFundForm.get('apply_labourwelfare_fund')?.value" class="col-12 form-row">
                        <label for="" class="form-label required">Do you want to prorate labour welfare fund on basis of pay days?
                        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select yes if LWF is calculated based on paid days of the month For eg: If LWF is ₹20 per month and employee worked 25days in the month of November. Then LWF is calculated as 20*(25/30). If No is selected then it will be ₹20 only irrespective of paid days." popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>
                    </label>
                    </div>
                    <div *ngIf="this.LabourWelfareFundForm.get('apply_labourwelfare_fund')?.value" class=" col-12 form-row mt-0">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" name="prorate_LWF_perdays" formControlName="prorate_LWF_perdays" type="radio" id="yes" checked [value]=true>
                            <label class="form-check-label" for=" ">
                              Yes
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" name="prorate_LWF_perdays" type="radio" formControlName="prorate_LWF_perdays" id="no" [value]=false>
                            <label class="form-check-label" for="Deduction">
                               No
                            </label>
                        </div>
                    </div> -->
                        <div *ngIf="this.LabourWelfareFundForm.get('apply_labourwelfare_fund')?.value"
                            class="col-12 form-row">
                            <input type="checkbox" name="check" class="form-check-input ms-0"
                                formControlName="contribute_in_ctc">
                            <label class="form-label">Include employer's contribution in the CTC</label>
                        </div>
                        <div *ngIf="seletedYearValidation" class="col-12 form-row text-danger">
                            Financial Year is not selected
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <footer *ngIf="!loader1" class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                [disabled]="statutory[3]['status'] || lwfSubmitClicked == true">Lock</button>
        </footer>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width: 720px" [class.side-pane-active]="gratuity === true">
    <form class="side-panel-container" [formGroup]="gratuity_form" (ngSubmit)="gratuity_formSubmit()"
        autocomplete="off">
        <header class="side-panel-head">
            <h5 class="d-flex align-items-center">
                <span
                    class="status-icon {{statutory[4]['status']  ? 'status-success' : 'status-pending' }} me-12"></span>Gratuity
            </h5>
            <a class="toggle-panel"
                (click)=" gratuity = false; gratuity_formSubmitted = false;gratuitySubmitClicked = false"><i
                    class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
            <div class="container-fluid p-32">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1"></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1">

                    <div class="row row-16">
                        <div class="col-12" *ngIf="alertShow == 'show' ">
                            <div role="alert" class="alert mb-0 alert-warning {{alertShow}}">
                                <p class="mb-0"><span class="text-warning">Note:
                                    </span> Gratuity is a monetary benefit provided by the employer to the employee for
                                    the services rendered to the organization. The gratuity amount is paid at the time
                                    of retirement. The maximum tax exemption for gratuity that can be provided to an employee in his lifetime is 20 lakhs.</p>
                                <button class="btn-close" type="button" (click)="alertShow = '' "></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="text-uppercase fw-500 mb-0">GRATUITY SETTINGS</p>
                        </div>
                        <div class="col-12 form-row">
                            <label for="" class="form-label   required">Do you want to apply for
                                gratuity?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Select yes, If your organization wants to apply for Gratuity."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div class="col-lg-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_gratuity" formControlName="apply_gratuity"
                                    type="radio" id="apply_gratuity1" [value]="true" checked
                                    (ngModelChange)="setConditionalValue('gratuity')">
                                <label class="form-check-label" for=" apply_gratuity1">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_gratuity" formControlName="apply_gratuity"
                                    type="radio" id="apply_gratuity2" [value]="false"
                                    (ngModelChange)="setConditionalValue('gratuity')">
                                <label class="form-check-label" for="apply_gratuity2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-12 form-row">
                            <label for="" class="form-label required">Employer contribution rate
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover=" Employer contribution rate 4.81% of Basic+DA."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="employer_contribution"
                                formControlName="employer_contribution" id=""
                                placeholder="{{this.messageService.placeholderdisp('employer contribution rate')}}"
                                [ngClass]="{ 'is-invalid' : (gratuity_formSubmitted || g_f.employer_contribution.dirty || g_f.employer_contribution.touched) && g_f.employer_contribution.errors}">
                            <div *ngIf="((gratuity_formSubmitted || g_f.employer_contribution.touched) || g_f.employer_contribution.dirty) && g_f.employer_contribution.errors "
                                class="invalid-feedback">
                                <div *ngIf="g_f.employer_contribution.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="g_f.employer_contribution.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="g_f.employer_contribution.errors?.['maxlength'] && !g_f.employer_contribution.errors?.['pattern']"
                                class="invalid-feedback">
                                {{this.messageService.fieldlengthvalidation('num','10')}}
                            </div>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-12 form-row">
                            <label for="" class="form-label required">Tax free gratuity limit
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Tax free gratuity limit ₹ 20,00,000."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="tax_free_gratuity_limit"
                                formControlName="tax_free_gratuity_limit" id=""
                                placeholder="{{this.messageService.placeholderdisp('tax free gratuity limit')}}"
                                [ngClass]="{ 'is-invalid' : (gratuity_formSubmitted || g_f.tax_free_gratuity_limit.dirty || g_f.tax_free_gratuity_limit.touched) && g_f.tax_free_gratuity_limit.errors}">
                            <div *ngIf="((gratuity_formSubmitted || g_f.tax_free_gratuity_limit.touched) || g_f.tax_free_gratuity_limit.dirty) && g_f.tax_free_gratuity_limit.errors "
                                class="invalid-feedback">
                                <div *ngIf="g_f.tax_free_gratuity_limit.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="g_f.tax_free_gratuity_limit.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div *ngIf="g_f.tax_free_gratuity_limit.errors?.['maxlength'] && !g_f.tax_free_gratuity_limit.errors?.['pattern']"
                                class="invalid-feedback">
                                {{this.messageService.fieldlengthvalidation('num','10')}}
                            </div>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-12 form-row">
                            <label for="" class="form-label required">
                                Calculate gratuity on
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Choose the year and month from the dropdown, from when gratuity must be calculated."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-md-6 form-row mt-0">
                            <app-master-select
                                [ngClass]="{ 'is-invalid': (gratuity_formSubmitted || g_f.calculate_gratuity_year.touched) && g_f.calculate_gratuity_year.errors }"
                                [appendTo]='"body"' [dropdownPosition]='"auto"' [placeholder]="yearDropdownPlaceHolder"
                                id="calculate_gratuity_year" [submitted]="gratuity_formSubmitted"
                                [selectedItem]="g_f.calculate_gratuity_year.value"
                                formControlName="calculate_gratuity_year" [type]="gratuity_year"
                                [isrequired]="isrequired"></app-master-select>
                            <div *ngIf="(gratuity_formSubmitted || g_f.calculate_gratuity_year.touched) && g_f.calculate_gratuity_year.errors "
                                class="invalid-feedback">
                                <div *ngIf="g_f.calculate_gratuity_year.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-md-6 form-row mt-0">
                            <app-master-select
                                [ngClass]="{ 'is-invalid': (gratuity_formSubmitted || g_f.calculate_gratuity_month.touched) && g_f.calculate_gratuity_month.errors }"
                                [appendTo]='"body"' [dropdownPosition]='"auto"' [placeholder]="monthDropdownPlaceHolder"
                                id="calculate_gratuity_month" [submitted]="gratuity_formSubmitted"
                                [selectedItem]="g_f.calculate_gratuity_month.value"
                                formControlName="calculate_gratuity_month" [type]="gratuity_month"
                                [isrequired]="isrequired"></app-master-select>
                            <div *ngIf="(gratuity_formSubmitted || g_f.calculate_gratuity_month.touched) && g_f.calculate_gratuity_month.errors "
                                class="invalid-feedback">
                                <div *ngIf="g_f.calculate_gratuity_month.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.gratuity_form.get('apply_gratuity')?.value" class="col-12  form-row ">
                            <input type="checkbox" name="check" class="form-check-input ms-0"
                                formControlName="contribute_in_ctc">
                            <label class="form-label">Include employer contribution in the CTC
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Enable this option if you want to include employer’s contribution in the CTC."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <footer *ngIf="!loader1" class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm "
                [disabled]="statutory[4]['status']|| gratuitySubmitClicked == true">Lock</button>
        </footer>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width: 720px" [class.side-pane-active]="professionalTax === true">
    <form class="side-panel-container" [formGroup]="prof_tax_form" (ngSubmit)="prof_tax_formSubmit()"
        autocomplete="off">
        <header class="side-panel-head">
            <h5 class="d-flex align-items-center">
                <span
                    class="status-icon {{statutory[5]['status']  ? 'status-success' : 'status-pending' }} me-12"></span>
                Professional Tax
            </h5>
            <a class="toggle-panel"
                (click)="professionalTax = false; prof_tax_formSubmitted = false;ptSubmitClicked=false"><i
                    class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
            <div class="container-fluid p-32">
                <!-- Edit loader start -->
                <app-edit-loader *ngIf="loader1"></app-edit-loader>
                <!-- Edit loader end -->
                <ng-container *ngIf="!loader1">

                    <div class="row row-16">
                        <div class="col-12" *ngIf="alertShow == 'show' ">
                            <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
                                <p class="mb-0"><span class="text-warning">Note:
                                    </span> The state government levies a tax on professionals. Professional tax varies
                                    by state. The maximum amount of professional tax that can be paid in a year is
                                    ₹2500. Depending on your state, the professional tax deduction cycle can be monthly
                                    or yearly. </p>
                                <button class="btn-close" type="button" (click)="alertShow = '' "></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="text-uppercase mb-0 fw-500">PROFESSIONAL TAX SETTINGS </p>
                        </div>
                        <div class="col-12 form-row">
                            <label for="" class="form-label required">Do you want to apply for professional
                                tax?
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="Select yes, If your organization wants to apply for Professional Tax."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div class="col-lg-12 form-row mt-0">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_pt" formControlName="apply_pt" type="radio"
                                    id="apply_pt1" [value]="true" checked (ngModelChange)="setConditionalValue('tax')">
                                <label class="form-check-label" for="apply_pt1">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="apply_pt" formControlName="apply_pt" type="radio"
                                    id="apply_pt2" [value]="false" (ngModelChange)="setConditionalValue('tax')">
                                <label class="form-check-label" for="apply_pt2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div *ngIf="this.prof_tax_form.get('apply_pt')?.value" class="col-12 form-row">
                            <label for="" class="form-label required">Certificate number
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                        ngbPopover="All the employers must obtain a Professional Tax – Employee Registration Certificate-RC within 30 days of starting the employment."
                                        popoverClass="popover-default popover-info" container="body"
                                        triggers="hover"></i></span>
                            </label>
                        </div>
                        <div *ngIf="this.prof_tax_form.get('apply_pt')?.value" class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="mention_certificate_number"
                                formControlName="mention_certificate_number" id=""
                                placeholder="{{this.messageService.placeholderdisp('certificate number')}}"
                                [ngClass]="{ 'is-invalid' :  (prof_tax_formSubmitted || p_tax_f.mention_certificate_number.dirty || p_tax_f.mention_certificate_number.touched) && p_tax_f.mention_certificate_number.errors}">
                            <div *ngIf="(prof_tax_formSubmitted || p_tax_f.mention_certificate_number.touched || p_tax_f.mention_certificate_number.dirty) && p_tax_f.mention_certificate_number.errors "
                                class="invalid-feedback">
                                <div *ngIf="p_tax_f.mention_certificate_number.errors.required">
                                    {{this.messageService.validationDisplay('required')}}
                                </div>
                            </div>
                            <div *ngIf="p_tax_f.mention_certificate_number.errors?.['pattern']"
                                class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div *ngIf="p_tax_f.mention_certificate_number.errors?.['maxlength'] && !p_tax_f.mention_certificate_number.errors?.['pattern']"
                                class="invalid-feedback">
                                {{this.messageService.fieldlengthvalidation('num','25')}}
                            </div>
                        </div>
                        <div *ngIf="this.prof_tax_form.get('apply_pt')?.value" class="col-12 form-row">
                            <label for="" class="form-label">Circle number </label>
                        </div>
                        <div *ngIf="this.prof_tax_form.get('apply_pt')?.value" class="col-md-6 form-row mt-0">
                            <input type="text" class="form-control" name="mention_circle_number"
                                formControlName="mention_circle_number" id=""
                                placeholder="{{this.messageService.placeholderdisp('circle number')}}"
                                [ngClass]="{ 'is-invalid' : (prof_tax_formSubmitted || p_tax_f.mention_circle_number.dirty || p_tax_f.mention_circle_number.touched) && p_tax_f.mention_circle_number.errors}">
                            <div *ngIf="(prof_tax_formSubmitted || p_tax_f.mention_circle_number.touched || p_tax_f.mention_circle_number.dirty) && p_tax_f.mention_circle_number.errors "
                                class="invalid-feedback">

                            </div>
                            <div *ngIf="p_tax_f.mention_circle_number.errors?.['pattern']" class="invalid-feedback">
                                {{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div *ngIf="p_tax_f.mention_circle_number.errors?.['maxlength'] && !p_tax_f.mention_circle_number.errors?.['pattern']"
                                class="invalid-feedback">
                                {{this.messageService.fieldlengthvalidation('num','25')}}
                            </div>
                        </div>
                        <div *ngIf="prof_tax_formSubmitted && seletedYearValidation"
                            class="col-12 form-row text-danger">
                            Financial Year is not selected
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <footer *ngIf="!loader1" class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                [disabled]="statutory[5]['status'] || ptSubmitClicked == true">
                Lock
            </button>
        </footer>
    </form>
</div>
<!-- view -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- View loader start -->
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <!-- View loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24 mb-24">
                        <div class="d-flex mb-16">
                            <p class="text-uppercase fw-500 mb-0">Tax Settings</p>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title">EXEMPTION</span></div>
                                    <div class="col-12">
                                        <div class="detail-title">For how many children education allowance
                                            would be
                                            admissible?</div>
                                        <div class="detail-desc">{{taxView.child_education}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Maximum tuition fees exemption for children
                                            going to
                                            school (annually)?</div>
                                        <div class="detail-desc">{{taxView.max_tuition_fees}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Maximum amount exemption for children going to
                                            hostel (annually)?
                                        </div>
                                        <div class="detail-desc">{{taxView.max_hostle_fees}}</div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <div class="detail-title">Maximum net taxable amount to avail tax credit
                                        </div>
                                        <div class="detail-desc">{{taxView.max_net_tax}}</div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="detail-title">Tax rebate (87A) for old tax regime applicable upto taxable income of
                                        </div>
                                        <div class="detail-desc">{{taxView.tax_rebate_87A}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Tax rebate (87A) for new tax regime applicable upto taxable income of
                                        </div>
                                        <div class="detail-desc">{{taxView.newregima_tax_rebate_87A}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Specify standard deduction for new tax regime
                                        </div>
                                        <div class="detail-desc">{{taxView.newregima_standard_deduction}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Specify standard deduction for old tax regime
                                        </div>
                                        <div class="detail-desc">{{taxView.special_standard_deduction}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Superannuation exemption limit
                                        </div>
                                        <div class="detail-desc">{{taxView.superannuation_exempt_limit}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Lifetime tax exemption limit for leave
                                            encashment, at settlement
                                        </div>
                                        <div class="detail-desc">{{taxView?.leave_encashment_exempt_limit}}
                                        </div>
                                    </div>
                                    <div class="col-12 "><span class="title">INVESTMENT</span></div>
                                    <div class="col-12">
                                        <div class="detail-title">Specify maximum number of requests for
                                            proposed investment
                                            declaration
                                        </div>
                                        <div class="detail-desc">{{taxView?.max_pro_investment_declare_request}}
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Specify maximum number of requests for
                                            confirmed investment
                                            declaration
                                        </div>
                                        <div class="detail-desc">{{taxView?.max_con_investment_declare_request}}
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">From which month tax should be processed
                                            considering confirmed documents
                                        </div>
                                        <div class="detail-desc">{{taxView.from_month_tax_process_investment}}
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">From which month tax for reimbursement be
                                            processed as per the bill submitted
                                        </div>
                                        <div class="detail-desc">{{taxView.from_month_pay_reimburse_per_bill}}
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                <div class="detail-title">Do you want to send confirmed investment
                                  declaration alert?
                                </div>
                                <div class="detail-desc">
                                    <span class="fw-500 " *ngIf="taxView.send_confirm_investment_alert">Yes</span>
                                   <span class="fw-500" *ngIf="taxView.send_confirm_investment_alert==false">No
                                    </span>
                                </div>
                              </div>
                              <div class="col-12" *ngIf="taxView.specify_days">
                                <div class="detail-title">Specify days</div>
                                <div class="detail-desc">{{taxView.specify_days}}</div>
                              </div> -->
                                    <!-- <div class="col-12">
                                <div class="detail-title">From which month the tax for reimbursement be processed as per the bill submitted
                                </div>
                                <div class="detail-desc">{{taxView.from_month_pay_reimburse_per_bill}}</div>
                              </div> -->
                                    <div class="col-12">
                                        <div class="detail-title">HRA exemption calculation
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500" *ngIf="taxView.HRA_exempt_calculation">Prorate
                                                with paid days, DOJ, Projected DOL
                                            </span>
                                            <span class="fw-500" *ngIf="taxView.HRA_exempt_calculation==false">Actual
                                                rent paid
                                                amount
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Actual rent paid limit to ensure PAN
                                            availability of landlord
                                        </div>
                                        <div class="detail-desc">{{taxView.actual_rent_paid_limit}}</div>
                                    </div>
                                    <!-- <div class="col-12">
                                <div class="detail-title">Loss from house property exemption limit
                                </div>
                                <div class="detail-desc">{{taxView.loss_houseproperty_exempt_limit}}</div>
                              </div> -->
                                    <!-- <div class="col-12">
                                <div class="detail-title">Interest paid on housing loan exemption limit
                                </div>
                                <div class="detail-desc">{{taxView.interest_paid_houseloan_exempt_limit}}</div>
                              </div> -->
                                    <div class="col-12 "><span class="title">TDS</span></div>
                                    <div class="col-12">
                                        <div class="detail-title">Education cess charges (%)
                                        </div>
                                        <div class="detail-desc">{{taxView.education_cess_charge}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to deduct TDS in higher slab (if
                                            PAN not available)?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="taxView.TDS_higher_slab">Yes</span>
                                            <span class="fw-500" *ngIf="taxView.TDS_higher_slab==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to deduct flat income tax for any employment type?</div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="taxView.deduct_flat_rate_tax">Yes</span>
                                            <span class="fw-500" *ngIf="taxView.deduct_flat_rate_tax==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <ng-container  *ngIf="taxView.deduct_flat_rate_tax">
                                        <div class="col-6">
                                            <div class="detail-title">Employment Type</div>
                                            <div class="detail-desc">
                                                <ul class="selected-values ">
                                                    <li class="ng-value" *ngFor="let item of taxView.tax_employment_type">
                                                        <span class="ng-value-label" >{{ item }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="detail-title">Tax rate</div>
                                            <div class="detail-desc">{{taxView.tax_rate}}</div>
                                        </div>
                                    </ng-container>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to show income tax of employees in
                                            challan whose salary is withheld?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 "
                                                *ngIf="taxView.deposit_income_tax_of_salary">Yes</span>
                                            <span class="fw-500" *ngIf="taxView.deposit_income_tax_of_salary==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to show employees whose income Tax
                                            Is zero in challan?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 "
                                                *ngIf="taxView.show_income_tax_zero_employee">Yes</span>
                                            <span class="fw-500" *ngIf="taxView.show_income_tax_zero_employee==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <!-- LTA block period start -->
                                    <div class="col-12">
                                        <div class="detail-title">Current LTA block period (start year)
                                        </div>
                                        <div class="detail-desc">{{taxView.lta_block_period_start}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Current LTA block period (end year)
                                        </div>
                                        <div class="detail-desc">{{taxView.lta_block_period_end}}</div>
                                    </div>
                                    <!-- LTA block period end -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanelpf === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanelpf = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- View loader start -->
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <!-- View loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24 mb-24">
                        <div class="d-flex ">
                            <p class="text-uppercase fw-500">PROVIDENT FUND</p>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title">PROVIDENT FUND SETTINGS</span>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to apply for provident fund?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="pfview.apply_PF">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.apply_PF==false">No
                                            </span>
                                        </div>
                                    </div>
                                        <!-- <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Company PF number</div>
                                        <div class="detail-desc">{{pfview.company_PF_number}}</div>
                                        </div> -->
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Employee contribution rate </div>
                                        <div class="detail-desc">{{pfview.employee_contribution_rate_name}}
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Employer contribution rate </div>
                                        <div class="detail-desc">{{pfview.employer_contribution_rate_name}}
                                        </div>
                                    </div>
                                    <!-- <div class="col-12" *ngIf="pfview.apply_PF">
                                  <div class="detail-title">Allow specifying contribution rate at the employee level
                                  </div>
                                  <div class="detail-desc">
                                      <span class="fw-500 " *ngIf="pfview.contribute_in_ctc">Yes</span>
                                      <span class="fw-500" *ngIf="pfview.contribute_in_ctc==false">No
                                      </span>
                                  </div>
                                </div> -->
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Include employer contribution in the CTC
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="pfview.contribute_in_ctc">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.contribute_in_ctc==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Prorate PF as per paid days
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="pfview.prorate_employer_pf">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.prorate_employer_pf==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Prorate employees’ PF Contribution limit as
                                            per paid days
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="pfview.prorate_employee_pf">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.prorate_employee_pf==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Include admin charges in employee CTC
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="pfview.include_admin_charge">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.include_admin_charge==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Do you want to deposit PF for employees whose
                                            salary is on hold?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="pfview.deposit_PF_from_salary">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.deposit_PF_from_salary==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Display PF for employees on salary register
                                            whose salary is on hold?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 "
                                                *ngIf="pfview.show_employee_PF_registration">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.show_employee_PF_registration==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="pfview.apply_PF">
                                        <div class="detail-title">Do you want to display employees on challan
                                            whose PF is zero?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 "
                                                *ngIf="pfview.show_employee_PF_challan_zero">Yes</span>
                                            <span class="fw-500" *ngIf="pfview.show_employee_PF_challan_zero==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="pfview.apply_PF">
                                    <div class="col-12">
                                      <div class="detail-title">Roundoff EPF contribution on</div>
                                      <div class="detail-desc">{{pfview?.epf_roundoff}}</div>
                                    </div>
                                    <div class="col-12">
                                      <div class="detail-title">Roundoff EPS contribution on</div>
                                      <div class="detail-desc">{{pfview?.eps_roundoff}}</div>
                                    </div>
                                    <div class="col-12">
                                      <div class="detail-title">Roundoff EDLI contribution on</div>
                                      <div class="detail-desc">{{pfview?.edli_roundoff}}</div>
                                    </div>
                                    <div class="col-12">
                                      <div class="detail-title">Roundoff admin charges on</div>
                                      <div class="detail-desc">{{pfview?.admin_roundoff}}</div>
                                    </div>
                                </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanelesi === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanelesi = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- View loader start -->
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <!-- View loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24 mb-24">
                        <div class="d-flex ">
                            <p class="text-uppercase fw-500">Employee state insurance</p>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title">Employee state insurance
                                            SETTINGS</span></div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to apply for ESI?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="esiview.apply_ESI">Yes</span>
                                            <span class="fw-500" *ngIf="esiview.apply_ESI==false">No
                                            </span>
                                        </div>
                                    </div>
                                      <!-- <div class="col-12" *ngIf="esiview.apply_ESI">
                                        <div class="detail-title">Company ESI number</div>
                                        <div class="detail-desc">{{esiview.company_ESI_number}}</div>
                                      </div> -->
                                    <div class="col-12" *ngIf="esiview.apply_ESI">
                                        <div class="detail-title">Wage ceiling for ESI contribution</div>
                                        <div class="detail-desc">{{esiview.wageceilling_ESI_contribution}}</div>
                                    </div>
                                    <div class="col-12" *ngIf="esiview.apply_ESI">
                                        <div class="detail-title">Employee contribution rate</div>
                                        <div class="detail-desc">{{esiview.employee_contribution_rate_name}}
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="esiview.apply_ESI">
                                        <div class="detail-title">Employer contribution rate</div>
                                        <div class="detail-desc">{{esiview.employer_contribution_rate_name}}
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="esiview.apply_ESI">
                                        <div class="detail-title">Include employer contribution in the CTC
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="esiview.contribute_in_ctc">Yes</span>
                                            <span class="fw-500" *ngIf="esiview.contribute_in_ctc==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="esiview.apply_ESI">
                                    <div class="col-12">
                                        <div class="detail-title">Roundoff employee ESI contribution on</div>
                                        <div class="detail-desc">
                                            {{esiview?.employee_roundoff}}</div>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Roundoff employers ESI contribution on</div>
                                        <div class="detail-desc">
                                            {{esiview?.employer_roundoff}}</div>
                                    </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanellwf === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanellwf = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- View loader start -->
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <!-- View loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24 mb-24">
                        <div class="d-flex ">
                            <p class="text-uppercase fw-500"> Labour welfare fund</p>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title"> Labour welfare fund
                                            SETTINGS</span></div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to apply for labour welfare fund?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="lwfview.apply_labourwelfare_fund">Yes</span>
                                            <span class="fw-500" *ngIf="lwfview.apply_labourwelfare_fund==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="lwfview.apply_labourwelfare_fund">
                                        <div class="detail-title">Do you want to process labour welfare fund on
                                            settlement?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="lwfview.process_LWF_settlement">Yes</span>
                                            <span class="fw-500" *ngIf="lwfview.process_LWF_settlement==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12" *ngIf="lwfview.apply_labourwelfare_fund">
                                <div class="detail-title">Do you want to prorate labour welfare fund on basis of pay days?
                                </div>
                                <div class="detail-desc">
                                    <span class="fw-500 " *ngIf="lwfview.prorate_LWF_perdays">Yes</span>
                                   <span class="fw-500" *ngIf="lwfview.prorate_LWF_perdays==false">No
                                    </span>
                                </div>
                              </div> -->
                                    <div class="col-12" *ngIf="lwfview.apply_labourwelfare_fund">
                                        <div class="detail-title">Include employer's contribution in the CTC
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="lwfview.contribute_in_ctc">Yes</span>
                                            <span class="fw-500" *ngIf="lwfview.contribute_in_ctc==false">No
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanelgratuity === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanelgratuity = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- View loader start -->
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <!-- View loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24 mb-24">
                        <div class="d-flex ">
                            <p class="text-uppercase fw-500"> Gratuity</p>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title">Gratuity SETTINGS</span></div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to apply for gratuity?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="gratuityView.apply_gratuity">Yes</span>
                                            <span class="fw-500" *ngIf="gratuityView.apply_gratuity==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="gratuityView.apply_gratuity">
                                        <div class="detail-title">Employer contribution rate
                                        </div>
                                        <div class="detail-desc">
                                            {{gratuityView.employer_contribution_rate_name}}
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="gratuityView.apply_gratuity">
                                        <div class="detail-title">Tax free gratuity limit
                                        </div>
                                        <div class="detail-desc">{{gratuityView.tax_free_gratuity_limit}}
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="gratuityView.apply_gratuity">
                                        <div class="detail-title"> Calculate gratuity on
                                        </div>
                                        <span class="detail-title">Year
                                        </span>
                                        {{gratuityView.calculate_gratuity_year}}
                                        <br />
                                        <span class="detail-title">Month
                                        </span>
                                        {{gratuityView.calculate_gratuity_month}}
                                    </div>
                                    <div class="col-12" *ngIf="gratuityView.apply_gratuity">
                                        <div class="detail-title">Include employer contribution in the CTC
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="gratuityView.contribute_in_ctc">Yes</span>
                                            <span class="fw-500" *ngIf="gratuityView.contribute_in_ctc==false">No
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanelpt === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanelpt = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- View loader start -->
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <!-- View loader end -->
                <ng-container *ngIf="!loader1">
                    <div class="row row-24 mb-24">
                        <div class="d-flex ">
                            <p class="text-uppercase fw-500"> Professional Tax</p>
                        </div>
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 py-16">
                                <div class="row row-24 ">
                                    <div class="col-12 "><span class="title"> Professional Tax SETTINGS</span>
                                    </div>
                                    <div class="col-12">
                                        <div class="detail-title">Do you want to apply for professional tax?
                                        </div>
                                        <div class="detail-desc">
                                            <span class="fw-500 " *ngIf="proftaxView.apply_pt">Yes</span>
                                            <span class="fw-500" *ngIf="proftaxView.apply_pt==false">No
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="proftaxView.apply_pt">
                                        <div class="detail-title">Certificate number
                                        </div>
                                        <div class="detail-desc">{{proftaxView.mention_certificate_number}}
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="proftaxView.apply_pt">
                                        <div class="detail-title">Circle number
                                        </div>
                                        <div class="detail-desc">
                                            {{(proftaxView.mention_circle_number == '' || proftaxView.mention_circle_number == null) ? '-' : proftaxView.mention_circle_number}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
