<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="loader==false && permissions">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h5 class="mb-0">Health Insurance</h5>
      <ng-container *ngIf="addEditPermission">
        <a class="link-primary fs-14
          d-flex align-items-center" *ngIf="insuranceData.length!=0 && permissions?.e" (click)="editInsurance()"><i
            class="icon-pencil-square me-1"></i> Edit</a>
      </ng-container>
    </div>
  </div>
  <div class="col-12" *ngIf="insuranceData.length!=0">
    <div class="card details-card shadow-0 px-24 py-16">

      <div class="row row-24 " *ngFor="let insuranceData of insuranceData let i = index;let last = last">

        <div *ngIf="getFieldPermission('insuree_name')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Insuree name</div>
          <div class="detail-desc">{{insuranceData.insuree_name}}</div>
        </div>

        <div *ngIf="getFieldPermission('insuree_of_birth')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Insuree DOB</div>
          <div class="detail-desc">{{this.appservice.dateFormatDisplay(insuranceData.insuree_of_birth)}}</div>
        </div>
        <div *ngIf="getFieldPermission('insuree_gender')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Gender</div>
          <div class="detail-desc">{{insuranceData.insuree_gender}}</div>
        </div>
        <div *ngIf="getFieldPermission('insurance_code')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Insurance code</div>
          <div class="detail-desc">{{insuranceData.insurance_code!=null?insuranceData.insurance_code:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('assured_sum')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Assured sum</div>
          <div class="detail-desc">{{insuranceData.assured_sum!=null?insuranceData.assured_sum:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('insurance_company')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Insurance company</div>
          <div class="detail-desc">{{insuranceData.insurance_company!=null?insuranceData.insurance_company:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('company_code')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Company code</div>
          <div class="detail-desc">{{insuranceData.company_code!=null?insuranceData.company_code:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('issue_date')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Issue date</div>
          <div class="detail-desc">{{insuranceData.issue_date!=null && insuranceData.issue_date!='' &&
            insuranceData.issue_date!='None' && insuranceData.issue_date!='none'
            ?this.appservice.dateFormatDisplay(insuranceData.issue_date):'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('valid_upto')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Valid upto</div>
          <div class="detail-desc">{{insuranceData.valid_upto!=null && insuranceData.valid_upto!='' &&
            insuranceData.valid_upto!='None' && insuranceData.valid_upto!='none'
            ?this.appservice.dateFormatDisplay(insuranceData.valid_upto):'-'}}</div>
        </div>
        <div class="col-12 mb-24" *ngIf="!last">
          <hr class="">
        </div>

      </div>

    </div>
  </div>
</div>

<!-- showing when no employer information available -->
<div class="row row-16" *ngIf="insuranceData.length==0">
  <div class="col-12">
    <div class="row row-24 py-16">
      <div class="col-12 ">
        <div class="card details-card shadow-0 px-24 py-16">
          <p class="title mb-24">Health insurance</p>
          <div *ngIf="addEditPermission" class="col-12 d-flex">
            <a *ngIf="permissions?.a" class="btn btn-outline-primary px-64" (click)="addNewInsurance()">Add Health
              Insurance</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Slider edit start-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" [formGroup]="insuranceFormArray" autocomplete="off">
    <header class="side-panel-head">
      <h5>{{heading}} Health insurance Details</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 ">
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0 fs-16">HEALTH INSURANCE INFORMATION</p>
          </div>
          <ng-container formArrayName="insurance" *ngFor="let insur of insurance().controls; let i = index">
            <ng-container [formGroupName]="i">
              <div class="card card-c2">
                <div class="card-body p-16 d-flex">
                  <p class="fs-14 fw-500 mb-0">Health insurance Information {{i+1}}</p>
                  <a class="ms-auto"><i class="icon-trash pointer text-dark" *ngIf="i!=0"
                      (click)="deleteInsurance(i,insurance().controls[i].get('id')?.value)"></i> </a>
                </div>
                <div class="card-body px-16 py-24">
                  <div class="row row-16">
                    <input type="text" value="" formControlName="id" id="id" hidden>
                    <input type="text" value="" formControlName="employee" id="employee" hidden>
                    <div class="col-sm-6 form-row">
                      <label for="insuree_name" class="form-label required ">Insuree name</label>
                      <input type="text" class="form-control " name="" id="insuree_name"
                        placeholder="{{this.messageService.placeholderdisp('Insuree Name')}}"
                        formControlName="insuree_name"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('insuree_name')?.dirty || insurance().controls[i].get('insuree_name')?.touched) &&  insurance().controls[i].get('insuree_name')?.errors}">
                      <div
                        *ngIf="(submitted || insurance().controls[i].get('insuree_name')?.dirty || insurance().controls[i].get('insuree_name')?.touched) && insurance().controls[i].get('insuree_name')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="insurance().controls[i].get('insuree_name')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div
                          *ngIf="!(insurance().controls[i].get('insuree_name')?.errors?.pattern) && insurance().controls[i].get('insuree_name')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        <div *ngIf="insurance().controls[i].get('insuree_name')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>

                      </div>
                    </div>

                    <div class="col-sm-6 form-row">
                      <label for="insuree_of_birth" class="form-label required ">Insuree DOB</label>
                      <div class="input-group form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('insuree_of_birth')?.touched) && insurance().controls[i].get('insuree_of_birth')?.errors }">
                        <input (click)="datePicker.open()" class="form-control" placeholder="Insuree DOB" matInput
                          formControlName="insuree_of_birth" [matDatepickerFilter]="dobFilter"
                          [matDatepicker]="datePicker" (keydown)="disableDate()"><i class="icon-calendar pointer"
                          (click)="datePicker.open()"></i>
                        <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
                      </div>
                      <div
                        *ngIf="((submitted || insurance().controls[i].get('insuree_of_birth')?.touched) || insurance().controls[i].get('insuree_of_birth')?.touched) && insurance().controls[i].get('insuree_of_birth')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="insurance().controls[i].get('insuree_of_birth')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row">
                      <label for="insuree_gender" class="form-label required ">Gender</label>
                      <app-master-select [placeholder]="genderPlaceHolder" id="insuree_gender" [submitted]="submitted"
                        [selectedItem]="insurance().controls[i].get('insuree_gender')?.value"
                        formControlName="insuree_gender" [type]="gender"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('insuree_gender')?.touched) &&  insurance().controls[i].get('insuree_gender')?.errors}"
                        [isrequired]="isrequired"></app-master-select>
                      <div
                        *ngIf="(submitted || insurance().controls[i].get('insuree_gender')?.touched) && insurance().controls[i].get('insuree_gender')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="insurance().controls[i].get('insuree_gender')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row">
                      <label for="insurance_code" class="form-label">Insurance code</label>
                      <input type="text" class="form-control " name="" id="insurance_code"
                        placeholder="{{this.messageService.placeholderdisp('Insurance Code')}}"
                        formControlName="insurance_code"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('insurance_code')?.dirty ) &&  insurance().controls[i].get('insurance_code')?.errors}">
                      <div
                        *ngIf="(submitted || insurance().controls[i].get('insurance_code')?.dirty ) && insurance().controls[i].get('insurance_code')?.errors"
                        class="invalid-feedback">

                        <div
                          *ngIf="!(insurance().controls[i].get('insurance_code')?.errors?.pattern) && insurance().controls[i].get('insurance_code')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>

                        <div *ngIf="insurance().controls[i].get('insurance_code')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row">
                      <label for="assured_sum" class="form-label">Assured sum</label>
                      <input type="text" class="form-control " name="" id="assured_sum"
                        placeholder="{{this.messageService.placeholderdisp('Assured Sum')}}"
                        formControlName="assured_sum"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('assured_sum')?.dirty) &&  insurance().controls[i].get('assured_sum')?.errors}">
                      <div
                        *ngIf="(submitted || insurance().controls[i].get('assured_sum')?.dirty) && insurance().controls[i].get('assured_sum')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="insurance().controls[i].get('assured_sum')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>

                        <!-- <div *ngIf="insurance().controls[i].get('assured_sum')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div> -->
                        <div
                          *ngIf="!(insurance().controls[i].get('assured_sum')?.errors?.pattern) && insurance().controls[i].get('assured_sum')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('num','10')}}</div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row">
                      <label for="insurance_company" class="form-label">Insurance company</label>
                      <input type="text" class="form-control " name="" id="insurance_company"
                        placeholder="{{this.messageService.placeholderdisp('Insurance Company')}}"
                        formControlName="insurance_company"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('insurance_company')?.dirty ) &&  insurance().controls[i].get('insurance_company')?.errors}">
                      <div
                        *ngIf="(submitted || insurance().controls[i].get('insurance_company')?.dirty ) && insurance().controls[i].get('insurance_company')?.errors"
                        class="invalid-feedback">

                        <div
                          *ngIf="!(insurance().controls[i].get('insurance_company')?.errors?.pattern) && insurance().controls[i].get('insurance_company')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        <div *ngIf="insurance().controls[i].get('insurance_company')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row">
                      <label for="company_code" class="form-label">Company code </label>
                      <input type="text" class="form-control " name="" id="company_code"
                        placeholder="{{this.messageService.placeholderdisp('Company Code')}}"
                        formControlName="company_code"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('company_code')?.dirty ) &&  insurance().controls[i].get('company_code')?.errors}">
                      <div
                        *ngIf="(submitted || insurance().controls[i].get('company_code')?.dirty ) && insurance().controls[i].get('company_code')?.errors"
                        class="invalid-feedback">

                        <div
                          *ngIf="!(insurance().controls[i].get('company_code')?.errors?.pattern) && insurance().controls[i].get('company_code')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>

                        <div *ngIf="insurance().controls[i].get('company_code')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row">
                      <label for="issue_date"
                        class="form-label {{(insurance().controls[i].get('valid_upto')?.value != null && insurance().controls[i].get('valid_upto')?.value != '')? 'required':''}}">Issue
                        date</label>
                      <div class="input-group form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('issue_date')?.dirty || insurance().controls[i].get('issue_date')?.touched) &&  insurance().controls[i].get('issue_date')?.errors}">
                        <input (click)="datePicker1.open()" class="form-control"
                          placeholder="{{this.messageService.selectplaceholddisplay('issue date')}}"
                          [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('issue_date')?.dirty || insurance().controls[i].get('issue_date')?.touched) &&  insurance().controls[i].get('issue_date')?.errors}"
                          matInput formControlName="issue_date" [matDatepickerFilter]="issueDateFilter"
                          [matDatepicker]="datePicker1" (keydown)="disableDate()"
                          (dateChange)="dateChangeFunction($event,i,'issue_date','add')"><i
                          class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                        <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                      </div>
                      <div
                        *ngIf="(submitted || insurance().controls[i].get('issue_date')?.dirty || insurance().controls[i].get('issue_date')?.touched ) && insurance().controls[i].get('issue_date')?.errors"
                        class="invalid-feedback">

                        <div *ngIf="insurance().controls[i].get('issue_date')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row">
                      <label for="valid_upto"
                        class="form-label {{(insurance().controls[i].get('issue_date')?.value != null && insurance().controls[i].get('issue_date')?.value != '')? 'required':''}}">Valid
                        upto</label>
                      <div class="input-group form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('valid_upto')?.dirty ||  insurance().controls[i].get('valid_upto')?.touched) &&   (insurance().controls[i].get('valid_upto')?.errors || insurance().controls[i].errors!=null)}">
                        <input (click)="datePicker2.open()" class="form-control"
                          placeholder="{{this.messageService.selectplaceholddisplay('valid upto')}}" matInput
                          formControlName="valid_upto" [matDatepickerFilter]="validDateFilter"
                          [matDatepicker]="datePicker2" (keydown)="disableDate()"
                          [ngClass]="{ 'is-invalid': (submitted || insurance().controls[i].get('valid_upto')?.dirty ||  insurance().controls[i].get('valid_upto')?.touched) &&   (insurance().controls[i].get('valid_upto')?.errors || insurance().controls[i].errors!=null)}"
                          (dateChange)="dateChangeFunction($event,i,'valid_upto','edit')"><i
                          class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                        <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                      </div>
                      <div
                        *ngIf="(submitted ||  insurance().controls[i].get('valid_upto')?.dirty || insurance().controls[i].get('valid_upto')?.touched ) &&  (insurance().controls[i].get('valid_upto')?.errors || insurance().controls[i].errors!=null)"
                        class="invalid-feedback">
                        <!-- <div *ngIf="insurance().controls[i].get('valid_upto')?.errors">
                          {{this.messageService.validationDisplay('pattern')}}</div> -->
                        <div *ngIf="insurance().controls[i]?.errors!=null">Valid date should be greater than issue date
                        </div>

                        <div *ngIf="insurance().controls[i].get('valid_upto')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="col-12">
            <div class="card card-c2 p-16 flex-row">
              <p class="fs-14 fw-500 mb-0">New Section</p>
              <span class="ms-auto link-primary1 fs-14" (click)="addInsurance()">
                <i class="icon-plus-square-fill me-8"></i>Add Health insurance Information
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer gap-32">
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit" (click)="onSubmit()"
        [disabled]="!insuranceFormArray.valid">Save</button>
    </footer>
  </form>
</div>

<!--Delete confirmation box for educational data-->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Insurance Information?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('insurance information')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteToggle = false">Cancel</button>

        <button type="button" class="btn btn-danger btn-sm text-uppercase"
          (click)="deleteInsuranceConfirm()">Delete</button>
      </div>

    </div>
  </div>
</div>

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- InfoPopup end -->
