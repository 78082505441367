import { Component, OnInit, ViewChild } from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AttendanceRuleService } from 'src/app/@core/services/attendance-rule.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AppService } from 'src/app/app.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { ArLateComingPenaltyComponent } from './ar-late-coming-penalty/ar-late-coming-penalty.component';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
@Component({
  selector: 'app-attendance-rule-setup',
  templateUrl: './attendance-rule-setup.component.html',
  styleUrls: ['./attendance-rule-setup.component.scss']
})
export class AttendanceRuleSetupComponent implements OnInit {
  activeTab = 1;
  shiftList:any;
  id=0;
  loader = false;
  leaveTypes : any = []
  iseditForm=false;
  disabled = false
  tab4touched =false
  tab5touched =false
  tab3touched =false
  tab2touched =false
  editflag : boolean = false
  title ="Add Attendance Rule"
  workingDay:any;
  weekOff:any;
  holiday:any;
  nationalHoliday:any;
  halfDay:any;
  overtimeType:any
  compoOffStatus:any = false;
  overtimeStatus:any = false;
  constructor(private _location: Location,public fb:FormBuilder,private ars:AttendanceRuleService, public router: Router,public route:ActivatedRoute, public datePipe:DatePipe,private appService:AppService, public leaveType : leavetypeService
    ,private previousRouteService: PreviousRouteService
    ) { }
  myForm: FormGroup = this.fb.group({
    id: 0,
    details: this.fb.group({
      attendance_rule_name:  [null, [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      attend_rule_desc: [null, [Validators.maxLength(500)]],
      calculate_total_work : [false],
      manage_punch : ['Double'],
      keep_time : ['Strict',[Validators.required]],
      weekoff_paid : [false],
      attendance_cycle : [true],
      attendance_calculate : [false],
      start_day : [1],
      end_day : [null],
      arrear_upto: [0],
      mark_absent_weekoff: [false],
      mark_absent_holiday: [false],
    }),
    penalty: this.fb.group({
      grace_day_come_late_go_early: [null],
      late_come_go_early_deduction: [false],
      deduction_type: [true],
      late_coming_penalty : [false],
      prior_deduct_attendancerule : this.fb.array([])
    }),
    compensatory: this.fb.group({
      auto_initiate_extra_working_benefit: [false],
      min_hour_full_day: [null],
      min_hour_half_day: [null],
      comp_off_expiry: [false],
      after_how_many_days: [null,],
      enable_compoff : [false],
      comp_off_workdays : [false],
      comp_off_weekoff : [false],
      comp_off_holiday : [false],
      comp_off_nationalholiday : [false],
      comp_off_eligibility_fulldays : [null],
      comp_off_halfday_work : [false],
      comp_off_eligibility_halfdays : [null]
    }),
    overtime: this.fb.group({
      which_overtime_start_after_shift_end: [null],
      max_overtime_hour_allow_working_day: [null],
      min_hour_required_consider_overtime_day: [null],
      max_overtime_hour_allow_weekoff: [null],
      max_overtime_hour_allow_holiday: [null],
      max_overtime_hour_allow_nationalholiday: [null],
      enable_overtime : [false],
      set_overtime_workday : [false],
      overtime_eligibility_fullday : [null],
      max_overtime_hour_allow__working_day : [null],
      // rate_working_days : [null],
      set_overtime_weekoff : [false],
      max_overtime_hour_allow__weekoff : [null],
      // rate_weekoff : [null],
      set_overtime_holiday : [false],
      max_overtime_hour_allow__holiday : [null],
      // rate_holiday :[null],
      set_overtime_nationalholiday : [false],
      max_overtime_hour_allow__nationalholiday : [null],
      // rate_nationalholiday : [null],
      set_overtime_halfday_work : [false],
      overtime_eligibility_halfday : [null],
      max_overtime_hour_allow_halfday_working : [null],
      rate_halfday_working  : [null],
      working_day_overtime  : this.fb.array([]),
      weekoff_overtime      : this.fb.array([]),
      holiday_day_overtime  : this.fb.array([]),
      national_day_overtime : this.fb.array([]),
      half_day_overtime     : this.fb.array([]),


    }),
    punch: this.fb.group({
      min_hour_mark_fullday_present: [null],
      min_hour_mark_halfday_present: [null],
    }),
    shift: this.fb.group({
      shift: [null, [Validators.required]],
    }),
    attendance_rule_assignright: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    }),
    alert : this.fb.group({
      send_absent_email : [false],
      trigger_absent_continuously_absent: [null],
      whome_want_notify: [null],
      send_weekly_absent_email : [false],
      weekly_notify_cc: [null],
      send_monthly_absent_email : [false],
      monthly_notify_cc: [null],
    })
  });
  @ViewChild (ArLateComingPenaltyComponent) lateComing !: ArLateComingPenaltyComponent


  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].details.invalid) {
        changeEvent.preventDefault();
      } else
      this.tab2touched =true
    } else  if (changeEvent.nextId === 3) {
      if (this.myForm['controls'].penalty.invalid || this.myForm['controls'].details.invalid|| (this.tab2touched == false && !this.iseditForm)) {
        changeEvent.preventDefault();
      } else{
        this.tab3touched =true
        this.overtimeStatus = this.overtimeForm.controls.enable_overtime.value
      }
    } else  if (changeEvent.nextId === 4 ) {
      if (this.myForm['controls'].penalty.invalid || this.myForm['controls'].details.invalid || this.myForm['controls'].compensatory.invalid || (this.tab3touched == false && !this.iseditForm) ) {
        changeEvent.preventDefault();
        this.compoOffStatus = this.compensatoryForm.controls.enable_compoff.value;
      } else
      this.tab4touched =true
    } else  if (changeEvent.nextId === 5) {
      if (this.myForm['controls'].overtime.invalid ||this.myForm['controls'].penalty.invalid || this.myForm['controls'].details.invalid || this.myForm['controls'].compensatory.invalid || (this.tab4touched == false && !this.iseditForm) ) {
        changeEvent.preventDefault();
      }else
      this.tab5touched =true
    } else  if (changeEvent.nextId === 6) {
      if (this.myForm['controls'].punch.invalid ||this.myForm['controls'].overtime.invalid ||this.myForm['controls'].penalty.invalid || this.myForm['controls'].details.invalid || this.myForm['controls'].compensatory.invalid|| this.myForm['controls'].alert.invalid || (this.tab5touched == false && !this.iseditForm)) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 7) {
      if (this.myForm['controls'].punch.invalid ||this.myForm['controls'].overtime.invalid ||this.myForm['controls'].penalty.invalid || this.myForm['controls'].details.invalid || this.myForm['controls'].compensatory.invalid || this.myForm['controls'].shift.invalid) {
        changeEvent.preventDefault();
      }
    }
  }

  get detailsForm() {
    return this.myForm.get('details') as FormGroup;
  }
  get penaltyForm() {
    return this.myForm.get('penalty') as FormGroup;
  }
  get compensatoryForm() {
    return this.myForm.get('compensatory') as FormGroup;
  }
  get overtimeForm() {
    return this.myForm.get('overtime') as FormGroup;
  }
  get punchForm() {
    return this.myForm.get('punch') as FormGroup;
  }
  get shiftForm() {
    return this.myForm.get('shift') as FormGroup;
  }
  get assignForm() {
    return this.myForm.get('attendance_rule_assignright') as FormGroup;
  }
  get alertForm() {
    return this.myForm.get('alert') as FormGroup;
  }

  leave_prio() : FormArray{
    return this.penaltyForm.get('prior_deduct_attendancerule') as FormArray
  }

  createLeaveFormGroup() :FormGroup {
    return this.fb.group({
      'leave_type': new FormControl(null),
      'priority': new FormControl(this.leave_prio()?.length+1)
    })
  }

  addLeaves(){
    this.leave_prio().push(this.createLeaveFormGroup())
  }
  workingDayarray() : FormArray {
    return this.overtimeForm.get("working_day_overtime") as FormArray
  }
  weekoffarray() : FormArray {
    return this.overtimeForm.get("weekoff_overtime") as FormArray
  }
  holidayarray() : FormArray {
    return this.overtimeForm.get("holiday_day_overtime") as FormArray
  }
  nationalHolidayarray() : FormArray {
    return this.overtimeForm.get("national_day_overtime") as FormArray
  }
  halfDayarray() : FormArray {
    return this.overtimeForm.get("half_day_overtime") as FormArray
  }
  newlocationarray(): FormGroup {
    return this.fb.group({
      "overtime_type" : [this.overtimeType],
      "hours_from"    : [null,Validators.required],
      "hours_to"      : [null,[Validators.required]],
      "rate"          : [null,[Validators.required,Validators.max(50),Validators.required,Validators.min(0.01),digitdecimalValidator()]]
    })
  }

  addOverTime(type:any) {
    this.overtimeType = type;
    switch(type) {
      case 'Working Day': {
        this.workingDayarray().push(this.newlocationarray());
         break;
      }
      case 'Week Off': {
        this.weekoffarray().push(this.newlocationarray());

         break;
      }
      case 'Holiday': {
        this.holidayarray().push(this.newlocationarray());

         break;
      }
      case 'National Holiday': {
        this.nationalHolidayarray().push(this.newlocationarray());

         break;
      }
      default: {
        this.halfDayarray().push(this.newlocationarray());
         break;
      }
    }
  }
  backClicked() {
     if(this.previousRouteService.getPreviousUrl() == '/home' || this.previousRouteService.getPreviousUrl() == '/login')
    this.router.navigate(['attendance-rule']);
    else
    this._location.back();
  }
  ngOnInit(): void {
    this.getLeaveType()
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
    if (activeTab === 4) {
      this.tab4touched =true
      this.compoOffStatus = this.compensatoryForm.controls.enable_compoff.value;
    } else  if (activeTab === 5) {
      this.tab5touched =true
    } else  if (activeTab === 3) {
      this.tab3touched =true
    } else  if (activeTab === 2) {
      this.tab2touched =true
    }

  }

  getLeaveType(){
    this.leaveType.getDropDownList().subscribe((res:any)=>{
      this.leaveTypes = res
      if (this.leaveTypes?.length != 0) {
        this.selectAllForDropdownItems(this.leaveTypes);
      }
    })
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  submitForm(){
    var applicable = this.assignForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.assignForm.controls.assignees.value){
      arr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }
    var dataArray:any;
    dataArray = {
      // Step 1
      "attendance_rule_name": this.detailsForm.controls.attendance_rule_name.value,
      "attend_rule_desc": this.detailsForm.controls.attend_rule_desc.value,
      "calculate_total_work" : this.detailsForm.controls.calculate_total_work.value,
      "manage_punch" : this.detailsForm.controls.manage_punch.value,
      "keep_time" : this.detailsForm.controls.keep_time.value,
      "weekoff_paid" : this.detailsForm.controls.weekoff_paid.value,
      "arrear_upto": this.detailsForm.controls.arrear_upto.value?this.detailsForm.controls.arrear_upto.value : 0,
      "attendance_cycle" : this.detailsForm.controls.attendance_cycle.value,
      "attendance_calculate" : this.detailsForm.controls.attendance_calculate.value,
      "start_day" : (this.detailsForm.controls.start_day.value && this.detailsForm.controls.attendance_cycle.value == false)?(this.detailsForm.controls.attendance_calculate?.value == false ? parseInt(this.detailsForm.controls.end_day.value)+1 : 1):null,
      "end_day" : (this.detailsForm.controls.end_day.value && this.detailsForm.controls.attendance_cycle.value == false)?this.detailsForm.controls.end_day.value:null,
      "mark_absent_weekoff": this.detailsForm.controls.mark_absent_weekoff.value,
      "mark_absent_holiday": this.detailsForm.controls.mark_absent_holiday.value,

      // Step 2
      "late_coming_penalty" : this.penaltyForm.controls.late_coming_penalty.value,
      // "grace_day_come_late_go_early" : Number(this.penaltyForm.controls.grace_day_come_late_go_early.value),

      // Step 3
      "enable_compoff" : this.compensatoryForm.controls.enable_compoff.value,

      // Step 4
      "enable_overtime" : this.overtimeForm.controls.enable_overtime.value,

      // Step 5
      "send_absent_email" : this.alertForm.controls.send_absent_email.value,
      "send_weekly_absent_email" : this.alertForm.controls.send_weekly_absent_email.value,
      "send_monthly_absent_email" : this.alertForm.controls.send_monthly_absent_email.value,

      // Step 6
      "attendance_rule_assignright":arr
   };

    if(this.penaltyForm.controls.late_coming_penalty.value == true){
      dataArray.grace_day_come_late_go_early = Number(this.penaltyForm.controls.grace_day_come_late_go_early.value);
      dataArray.late_come_go_early_deduction = this.penaltyForm.controls.late_come_go_early_deduction.value;
      dataArray.deduction_type = this.penaltyForm.controls.deduction_type.value;
    }else{
      dataArray.deduction_type = false
      dataArray.grace_day_come_late_go_early = 0
      dataArray.late_come_go_early_deduction = true
    }

    if(this.penaltyForm.controls.late_coming_penalty.value == true && this.penaltyForm.controls.late_come_go_early_deduction.value == true){
      dataArray.prior_deduct_attendancerule = this.penaltyForm.controls.prior_deduct_attendancerule.value;
      dataArray.prior_deduct_attendancerule[0].priority = dataArray.prior_deduct_attendancerule[0].priority == null ? 1 : dataArray.prior_deduct_attendancerule[0].priority
    }else{
      dataArray.prior_deduct_attendancerule = []
    }

    if(this.compensatoryForm.controls.enable_compoff.value == true){
      dataArray.min_hour_full_day = this.appService.timeFormatAttConvert(this.compensatoryForm.controls.min_hour_full_day.value);
      dataArray.min_hour_half_day = this.appService.timeFormatAttConvert(this.compensatoryForm.controls.min_hour_half_day.value);
      dataArray.auto_initiate_extra_working_benefit = this.compensatoryForm.controls.auto_initiate_extra_working_benefit.value;
      dataArray.comp_off_workdays = this.compensatoryForm.controls.comp_off_workdays.value;
      dataArray.comp_off_expiry = this.compensatoryForm.controls.comp_off_expiry.value;
      dataArray.comp_off_weekoff = this.compensatoryForm.controls.comp_off_weekoff.value
      dataArray.comp_off_holiday = this.compensatoryForm.controls.comp_off_holiday.value
      dataArray.comp_off_nationalholiday = this.compensatoryForm.controls.comp_off_nationalholiday.value
      dataArray.comp_off_halfday_work = this.compensatoryForm.controls.comp_off_halfday_work.value
      if(this.compensatoryForm.controls.comp_off_workdays.value == true){
        dataArray.comp_off_eligibility_fulldays = this.compensatoryForm.controls.comp_off_eligibility_fulldays.value
      }else{
        dataArray.comp_off_eligibility_fulldays = null
      }
      if(this.compensatoryForm.controls.comp_off_halfday_work.value == true){
        dataArray.comp_off_eligibility_halfdays = this.compensatoryForm.controls.comp_off_eligibility_halfdays.value
      }else{
        dataArray.comp_off_eligibility_halfdays = null
      }
    }else{
      dataArray.auto_initiate_extra_working_benefit = false
      dataArray.comp_off_expiry = false
      dataArray.comp_off_workdays = false
      dataArray.comp_off_weekoff = false
      dataArray.comp_off_holiday = false
      dataArray.comp_off_nationalholiday = false
      dataArray.comp_off_halfday_work = false
      dataArray.after_how_many_days = null
      dataArray.comp_off_eligibility_fulldays = null
      dataArray.comp_off_eligibility_halfdays = null
      dataArray.min_hour_full_day = null
      dataArray.min_hour_half_day = null
    }

    if(this.compensatoryForm.controls.enable_compoff.value == true && this.compensatoryForm.controls.comp_off_expiry.value == true){
      dataArray.after_how_many_days = this.compensatoryForm.controls.after_how_many_days.value;
    }else{
      dataArray.after_how_many_days = 0
    }

    if(this.overtimeForm.controls.enable_overtime.value == true){
      dataArray.min_hour_required_consider_overtime_day = this.appService.timeFormatAttConvert(this.overtimeForm.controls.min_hour_required_consider_overtime_day.value);
      dataArray.set_overtime_workday = this.overtimeForm.controls.set_overtime_workday.value;
      if(this.overtimeForm.controls.set_overtime_workday.value == true){
        dataArray.overtime_eligibility_fullday = this.overtimeForm.controls.overtime_eligibility_fullday.value
        dataArray.max_overtime_hour_allow_working_day =  this.appService.timeFormatAttConvert(this.overtimeForm.controls.max_overtime_hour_allow__working_day.value);
        // dataArray.rate_working_days = Number(this.overtimeForm.controls.rate_working_days.value)
      }else{
        dataArray.overtime_eligibility_fullday = null
        dataArray.max_overtime_hour_allow_working_day =  null
        // dataArray.rate_working_days = 0
      }
      dataArray.set_overtime_weekoff = this.overtimeForm.controls.set_overtime_weekoff.value
      if(this.overtimeForm.controls.set_overtime_weekoff.value == true){
        dataArray.max_overtime_hour_allow_weekoff = this.appService.timeFormatAttConvert(this.overtimeForm.controls.max_overtime_hour_allow__weekoff.value);
        // dataArray.rate_weekoff = Number(this.overtimeForm.controls.rate_weekoff.value)
      }else{
        dataArray.max_overtime_hour_allow_weekoff = null
        // dataArray.rate_weekoff = 0
      }
      dataArray.set_overtime_holiday = this.overtimeForm.controls.set_overtime_holiday.value
      if(this.overtimeForm.controls.set_overtime_holiday.value == true){
        dataArray.max_overtime_hour_allow_holiday = this.appService.timeFormatAttConvert(this.overtimeForm.controls.max_overtime_hour_allow__holiday.value);
        // dataArray.rate_holiday = Number(this.overtimeForm.controls.rate_holiday.value)
      }else{
        dataArray.max_overtime_hour_allow_holiday = null
        // dataArray.rate_holiday = 0
      }
      dataArray.set_overtime_nationalholiday = this.overtimeForm.controls.set_overtime_nationalholiday.value
      if(this.overtimeForm.controls.set_overtime_nationalholiday.value == true){
        dataArray.max_overtime_hour_allow_nationalholiday = this.appService.timeFormatAttConvert(this.overtimeForm.controls.max_overtime_hour_allow__nationalholiday.value);
        // dataArray.rate_nationalholiday = Number(this.overtimeForm.controls.rate_nationalholiday.value)
      }else{
        dataArray.max_overtime_hour_allow_nationalholiday = null
        // dataArray.rate_nationalholiday = 0
      }
      dataArray.set_overtime_halfday_work = this.overtimeForm.controls.set_overtime_halfday_work.value
      if(this.overtimeForm.controls.set_overtime_halfday_work.value == true){
        dataArray.overtime_eligibility_halfday = this.overtimeForm.controls.overtime_eligibility_halfday.value
        dataArray.max_overtime_hour_allow_halfday_working = this.appService.timeFormatAttConvert(this.overtimeForm.controls.max_overtime_hour_allow_halfday_working.value)
        dataArray.rate_halfday_working = Number(this.overtimeForm.controls.rate_halfday_working.value)
      }else{
        dataArray.overtime_eligibility_halfday = null
        dataArray.max_overtime_hour_allow_halfday_working = null
        dataArray.rate_halfday_working = 0
      }
      let half_day_overtime = []

      half_day_overtime     = this.overtimeForm.controls.half_day_overtime.value
      for(let i=0;i<half_day_overtime?.length;i++){
        half_day_overtime[i]['hours_from'] = this.appService.timeFormatAttConvert(half_day_overtime[i]['hours_from'])
        half_day_overtime[i]['hours_to']   = this.appService.timeFormatAttConvert(half_day_overtime[i]['hours_to'])
      }

      let holiday_day_overtime = []
      holiday_day_overtime     = this.overtimeForm.controls.holiday_day_overtime.value
      for(let i=0;i<holiday_day_overtime?.length;i++){
        holiday_day_overtime[i]['hours_from'] = this.appService.timeFormatAttConvert(holiday_day_overtime[i]['hours_from'])
        holiday_day_overtime[i]['hours_to']   = this.appService.timeFormatAttConvert(holiday_day_overtime[i]['hours_to'])
      }

      let national_day_overtime = []
      national_day_overtime     = this.overtimeForm.controls.national_day_overtime.value
      for(let i=0;i<national_day_overtime?.length;i++){
        national_day_overtime[i]['hours_from'] = this.appService.timeFormatAttConvert(national_day_overtime[i]['hours_from'])
        national_day_overtime[i]['hours_to']   = this.appService.timeFormatAttConvert(national_day_overtime[i]['hours_to'])
      }

      let weekoff_overtime = []
      weekoff_overtime     = this.overtimeForm.controls.weekoff_overtime.value
      for(let i=0;i<weekoff_overtime?.length;i++){
        weekoff_overtime[i]['hours_from'] = this.appService.timeFormatAttConvert(weekoff_overtime[i]['hours_from'])
        weekoff_overtime[i]['hours_to']   = this.appService.timeFormatAttConvert(weekoff_overtime[i]['hours_to'])
      }

      let working_day_overtime = []
      working_day_overtime     = this.overtimeForm.controls.working_day_overtime.value
      for(let i=0;i<working_day_overtime?.length;i++){
        working_day_overtime[i]['hours_from'] = this.appService.timeFormatAttConvert(working_day_overtime[i]['hours_from'])
        working_day_overtime[i]['hours_to']   = this.appService.timeFormatAttConvert(working_day_overtime[i]['hours_to'])
      }


      let mergedData = [ ...half_day_overtime, ...holiday_day_overtime, ...national_day_overtime, ...weekoff_overtime, ...working_day_overtime];
      dataArray.overtime_rates = mergedData

    }else{
      const clone = Object.assign({}, this.overtimeForm.controls);
      delete clone.enable_overtime;
      delete clone.set_overtime_workday
      // delete clone.rate_working_days
      // delete clone.rate_weekoff
      // delete clone.rate_holiday
      // delete clone.rate_nationalholiday
      delete clone.rate_halfday_working
      delete clone.set_overtime_halfday_work
      delete clone.set_overtime_holiday
      delete clone.set_overtime_nationalholiday
      delete clone.set_overtime_weekoff
      for(let prob in clone){
        dataArray[prob]=null
      }
      // dataArray.rate_working_days = 0
      // dataArray.rate_weekoff = 0
      // dataArray.rate_holiday = 0
      // dataArray.rate_nationalholiday = 0
      dataArray.rate_halfday_working = 0
      dataArray.set_overtime_workday = false
      dataArray.set_overtime_halfday_work = false
      dataArray.set_overtime_holiday = false
      dataArray.set_overtime_nationalholiday = false
      dataArray.set_overtime_weekoff = false
      dataArray.overtime_rates       = []
    }

    if(this.alertForm.controls.send_absent_email.value == true){
      dataArray.trigger_absent_continuously_absent = this.alertForm.controls.trigger_absent_continuously_absent.value
      let data = this.alertForm.controls.whome_want_notify.value
      let daatArr: { role: any; }[] = []
      if(data.length !== 0){
        data.forEach((item:any)=>{
          daatArr.push({
            "role" : item
          })
        })
      }

      dataArray.whome_want_notify = daatArr
    }else{
      dataArray.trigger_absent_continuously_absent = 0
      dataArray.whome_want_notify = []
    }

    let week_Arr: { cc_role: any; }[] = []
    if(this.alertForm.controls.send_weekly_absent_email.value == true){
      let weekly_alert = this.alertForm.controls.weekly_notify_cc.value
      if(weekly_alert?.length !== 0){
        weekly_alert.forEach((item:any)=>{
          week_Arr.push({
            "cc_role" : item
          })
        })
      }
    }
    dataArray.weekly_notify_cc = week_Arr
    let month_Arr: { cc_role: any; }[] = []
    if(this.alertForm.controls.send_monthly_absent_email.value == true){
      let monthly_alert = this.alertForm.controls.monthly_notify_cc.value
      if(monthly_alert?.length !== 0){
        monthly_alert.forEach((item:any)=>{
          month_Arr.push({
            "cc_role" : item
          })
        })
      }
    }
    dataArray.monthly_notify_cc = month_Arr
    if(this.id == 0){
      this.ars.createRule(dataArray).subscribe((res: any) => {
        this.router.navigate(['attendance-rule']);
      });
    } else {
      this.ars.updateRule(dataArray,this.id).subscribe((res: any) => {
        this.router.navigate(['attendance-rule']);
      },
      (error) => {

      });
    }
  }
  editForm(){
    this.loader = true;
    this.ars.getRuleDetail(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res.body);
      this.title ="Edit Attendance Rule"
      this.editflag = true
      this.loader = false;
    },(error)=>{
      this.router.navigate(['attendance-rule']);
    });
  }
  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }

    let assignees = [];
    let applicableto = "";
    let appkey ="";
    let leave_TypeData = []
    let rolesData = []
    let weeklyData =  []
    let monthlyData = []


    this.leave_prio().removeAt(0)
    for(let key of res.prior_deduct_attendancerule){
      this.addLeaves();
     leave_TypeData.push({
      "leave_type" : key.leave_type,
      "priority" : key.priority
     })
    }
    let overtime_rate = res?.overtime_rates
    if(overtime_rate!=undefined){

      var working =  overtime_rate.filter(function(working:any) {
        return working.overtime_type == "Working Day"
      });
      var weekoff =  overtime_rate.filter(function(weekoff:any) {
        return weekoff.overtime_type == "Week Off"
      });
      var holiday =  overtime_rate.filter(function(holiday:any) {
        return holiday.overtime_type == "Holiday"
      });
      var national =  overtime_rate.filter(function(national:any) {
        return national.overtime_type == "National Holiday"
      });
      var halfday =  overtime_rate.filter(function(halfday:any) {
        return halfday.overtime_type == "Half Day"
      });

      this.weekOff            = weekoff;
      this.workingDay         = working;
      this.holiday            = holiday;
      this.nationalHoliday    = national
      this.halfDay            = halfday;
      for(let i=0;i<this.weekOff?.length;i++){
        this.addOverTime('Week Off')

      }
      for(let i=0;i<this.workingDay?.length;i++){
        this.addOverTime('Working Day')

      }

      for(let i=0;i<this.holiday?.length;i++){
        this.addOverTime('Holiday')
      }
      for(let i=0;i<this.nationalHoliday?.length;i++){
        this.addOverTime('National Holiday')
      }
      for(let i=0;i<this.halfDay.length;i++){
        this.addOverTime('Half Day')
      }
    }
    if(res.late_come_go_early_deduction && res.late_coming_penalty){
       if(this.leave_prio()?.length == 0){
        this.addLeaves()
        this.setDisabled(true)
       }
    }

    for(let key of res.whome_want_notify){
     rolesData.push(key.role)
    }
    for(let key of res.weekly_notify_cc){
      weeklyData.push(key.cc_role)
    }
    for(let key of res.monthly_notify_cc){
    monthlyData.push(key.cc_role)
    }

    for(let key of res.attendance_rule_assignright){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
      assignees.push(key[appkey]);
      applicableto = key.applicable
    }

    if(!res.attendance_cycle){
      this.setRequired(true,['end_day'],this.detailsForm)
    }
    if(res.comp_off_workdays && res.enable_compoff){
      this.setRequired(true,['comp_off_eligibility_fulldays'],this.compensatoryForm)
    }

    if(res.set_overtime_workday && res.enable_overtime){
      this.setRequired(true,['overtime_eligibility_fullday'],this.overtimeForm)
    }

    this.myForm.reset(
      {
        id:this.id,
        details: {
          attendance_rule_name:  res.attendance_rule_name,
          attend_rule_desc: res.attend_rule_desc,
          calculate_total_work : res.calculate_total_work,
          manage_punch : res.manage_punch,
          keep_time : res.keep_time,
          weekoff_paid : res.weekoff_paid,
          arrear_upto: res.arrear_upto,
          attendance_cycle : res.attendance_cycle,
          attendance_calculate : res.attendance_calculate,
          start_day : res.start_day,
          end_day : parseInt(res.end_day),
          mark_absent_weekoff: res.mark_absent_weekoff,
          mark_absent_holiday: res.mark_absent_holiday,
        },
        penalty: {
          late_coming_penalty : res.late_coming_penalty,
          grace_day_come_late_go_early: res.grace_day_come_late_go_early,
          late_come_go_early_deduction: res.late_come_go_early_deduction,
          deduction_type: res.deduction_type,
          prior_deduct_attendancerule : leave_TypeData
        },
        compensatory: {
          enable_compoff : res.enable_compoff,
          auto_initiate_extra_working_benefit: res.auto_initiate_extra_working_benefit,
          min_hour_full_day: res.min_hour_full_day,
          min_hour_half_day: res.min_hour_half_day,
          comp_off_expiry: res.comp_off_expiry,
          after_how_many_days: res.after_how_many_days,
          comp_off_workdays : res.comp_off_workdays,
          comp_off_weekoff : res.comp_off_weekoff,
          comp_off_holiday : res.comp_off_holiday,
          comp_off_nationalholiday : res.comp_off_nationalholiday,
          comp_off_eligibility_fulldays : res.comp_off_eligibility_fulldays,
          comp_off_halfday_work : res.comp_off_halfday_work,
          comp_off_eligibility_halfdays : res.comp_off_eligibility_halfdays
        },
        overtime: {
          enable_overtime : res.enable_overtime,
          which_overtime_start_after_shift_end: res.which_overtime_start_after_shift_end,
          set_overtime_workday : res.set_overtime_workday,
          overtime_eligibility_fullday : res.overtime_eligibility_fullday,
          max_overtime_hour_allow__working_day: res.max_overtime_hour_allow_working_day,
          // rate_working_days : res.rate_working_days,
          set_overtime_weekoff : res.set_overtime_weekoff,
          max_overtime_hour_allow__weekoff: res.max_overtime_hour_allow_weekoff,
          // rate_weekoff : res.rate_weekoff,
          set_overtime_holiday : res.set_overtime_holiday,
          max_overtime_hour_allow__holiday: res.max_overtime_hour_allow_holiday,
          // rate_holiday : res.rate_holiday,
          set_overtime_nationalholiday : res.set_overtime_nationalholiday,
          max_overtime_hour_allow__nationalholiday: res.max_overtime_hour_allow_nationalholiday,
          // rate_nationalholiday : res.rate_nationalholiday,
          set_overtime_halfday_work : res.set_overtime_halfday_work,
          overtime_eligibility_halfday : res.overtime_eligibility_halfday,
          max_overtime_hour_allow_halfday_working : res.max_overtime_hour_allow_halfday_working,
          rate_halfday_working : res.rate_halfday_working,
          min_hour_required_consider_overtime_day: res.min_hour_required_consider_overtime_day,
          working_day_overtime  : this.workingDay,
          weekoff_overtime      : this.weekOff,
          holiday_day_overtime  : this.holiday,
          national_day_overtime : this.nationalHoliday,
          half_day_overtime     : this.halfDay
        },
        alert : {
          send_absent_email :  res.send_absent_email,
          trigger_absent_continuously_absent : res.trigger_absent_continuously_absent,
          whome_want_notify : rolesData,
          send_weekly_absent_email : res.send_weekly_absent_email,
          weekly_notify_cc : weeklyData,
          send_monthly_absent_email : res.send_monthly_absent_email,
          monthly_notify_cc : monthlyData
        },
        attendance_rule_assignright: {
          applicableto: applicableto,
          assignees: assignees,
        }

    });

  }

  setRequired(value: boolean,data:any,form:any) {
    if(value) {
    data.forEach((item:any)=>{
      form?.get(item).setValidators([Validators.required])
    })
    } else {
      data.forEach((item:any)=>{
        form?.get(item).clearValidators()
      })
    }
    data.forEach((item:any)=>{
      form?.get(item).updateValueAndValidity()
    })
  }

  setDisabled(value:any){
    for(let i=0;i<this.leave_prio()?.length;i++){
      if(value){
        this.leave_prio()?.controls[i]?.get('leave_type')?.setValidators([Validators.required])
      }else{
        this.leave_prio()?.controls[i]?.get('leave_type')?.clearValidators()
      }

      this.leave_prio()?.controls[i]?.get('leave_type')?.updateValueAndValidity()
    }
  }
}
