<div class="modal {{addProfileModal ? 'show':''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width:30.875rem">
    <div class="modal-content">
      <div class="modal-header px-24 ">
        <h5 class="modal-title ">{{text}} Your Profile Picture</h5>
        <button type="button" (click)="this.close.emit()" class="btn-close shadow-0" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body pb-32 px-24">
        <div class="row row-16">
          <div class="col-12 d-flex" *ngIf="(imageUrl!='' &&  imageUrl != null)">
            <div class="mx-auto">
              <img class="avatar-circle sq-96 " *ngIf="(imageUrl!=null) " [src]="imageUrl">
            </div>
          </div>
          <div class="col-12" *ngIf="imageUrl ==  '' || imageUrl == null">
            <div class="bg47 radius-4 hstack gap-16 py-16 px-32" style="max-height: 170px;">
              <div class="vstack justify-content-center ">
                <span class="fs-24">{{text}} your</span>
                <span class="fs-24 fw-500 text-accent2">Profile Picture</span>
              </div>
              <img src="/assets/images/bg/bg-5.png" style="height: 150px;" class="img-fluid w-50 object-fit-contain">
            </div>
          </div>
          <div class="col-6">
            <div class="card card-c2 card-hover px-12 py-10 hstack gap-10" (click)="captureModal=true">
              <span class="radius-8 text-white bg-accent1 flex-center fs-20 sq-44"><i class="icon-camera"></i></span>
              <!-- <img src="/assets/images/temp/camera.png"> -->
              <span class="fs-14">Camera</span>
            </div>
          </div>
          <div class="col-6">
            <label for="fileInput" class="w-100">
              <div class="card card-c2 card-hover px-12 py-10 hstack gap-10 ">
                <span class="radius-8 text-white bg-accent1 flex-center fs-20 sq-44"><i class="icon-gallery"></i></span>
                <span class="fs-14">Gallery</span>
                <app-file-upload [crop]="true" [round]="false" [width]="504" (dataURL)="getURL($event)" [id]="inputId"
                  [FileType]="'.png,.jpeg,.jpg'" (fileformat)="getfileformat($event)"
                  (validsize)="getvalidsize($event)"></app-file-upload>
              </div>
            </label>
          </div>
          <div class="col-12">
            <div role="alert" class="alert alert-info  mb-0 pe-16 show">
              <p class="mb-0 fs-14 hstack "> Please make sure your face is clearly visible in your photo.</p>
              <div class="fs-9 pointer"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<app-camera-modal *ngIf="captureModal" [captureModal]="captureModal" (pictureTaken)="getURL($event);captureModal=false"
  (close)="captureModal=false"></app-camera-modal>