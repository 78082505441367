import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-final-settlement-arrear-calculation',
  templateUrl: './final-settlement-arrear-calculation.component.html',
  styleUrls: ['./final-settlement-arrear-calculation.component.scss']
})
export class FinalSettlementArrearCalculationComponent implements OnInit {

  arrearprocessFlag : any
  arrearArr : any   = []
  saveData  : any  = []
  @Input() resignationDetails: any;
  @Input() payrollStatus:any;
  @Input() inboxId:any;
  @Output() submitmethod = new EventEmitter();
  @Output() warningMsg = new EventEmitter();
  empId : any;
  loader =false;
  alertMsg = '';
  statArr : any = [];
  empalertMsg = '';
  alertToggle = false;



  constructor( public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route: ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb: FormBuilder,private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.empId = parseInt(params['id']);
        this.arrearList()

        
      }
    });
  }

  // arrear list
  arrearList(){
    this.loader = true;
    this.arrearprocessFlag = null;
    this.finalSettlementService.getResignationDetails(this.empId, 'arrear_calculation',this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year,this.inboxId).subscribe((res: any) => {
      this.arrearArr = res.data
      this.loader = false;

    })

  }

  checkall(event:any){
    var newList = this.arrearArr;
    newList.forEach((items:any) => {
      if(event.target.checked == true){
      this.statArr.push(items.status)
      var sameElement = this.statArr.every( (item:any) => item === items.status )
      if(!sameElement){
        this.empalertMsg = "Different status cannot be selected";
        this.alertToggle = true;
        event.target.checked = false;
        newList.forEach((data:any) => {
        data.checked = false;
        this.arrearprocessFlag = null;
        this.statArr = [];
      })
      }else{
        items.checked = true;
        if(items?.status == 'Unprocessed'){
          this.arrearprocessFlag = false;
        }else{
          this.arrearprocessFlag = true;
        }
      }
    }else{
      items.checked = false;
      this.statArr = [];
      this.arrearprocessFlag = null;
    }
    })
   
  }
  
  checkAllSelected(){
    if(this.arrearArr.length){
      return this.arrearArr.every((item:any) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  
  // appear ignore or process button
  viewButton(event:any,status:any,checked : any){
    if(event.target.checked == true){
    this.statArr.push(status)
    var sameElement = this.statArr.every( (item:any) => item === status )
    if( !sameElement){
      checked = false;
      this.empalertMsg = "Different status cannot be selected";
      this.alertToggle = true;
      event.target.checked = false;
      this.statArr.pop(status)
    }else{
      if(status == 'Unprocessed'){
        this.arrearprocessFlag = false;
      }else{
        this.arrearprocessFlag = true;
      }
    }
  }else{
    this.statArr = [];
    this.arrearprocessFlag = null;

  }
}

  // arrear process
  arrearprocessFunction(){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.warningMsg.emit();
    }else{
      this.statArr  = [];
      this.saveData = [];
      var arrearId  = [];
      for(let i=0;i<this.arrearArr?.length;i++) {
        if(this.arrearArr[i]?.checked == true){
          arrearId.push(this.arrearArr[i]?.id)
        }
      }
      this.finalSettlementService.salaryRevisionArrears({'pay_month':this.resignationDetails?.value?.pay_month,'pay_year':parseInt(this.resignationDetails?.value?.pay_year),'status':'Processed','arrear_ids':arrearId}).subscribe((res: any) => {
        if(res?.failed_data?.length != 0){
        this.alertMsg = res?.failed_data[0]?.error
        this.notificationService.handleErrorNotification(this.alertMsg,"Error");
        }else{
        this.arrearList()
        }

      })
    }

  }

  // arrear unprocess
  arrearUnprocessFunction(){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.warningMsg.emit();
    }else{
      this.statArr  = [];
      this.saveData = [];
      var arrearId  = [];
      for(let i=0;i<this.arrearArr?.length;i++) {
        if(this.arrearArr[i]?.checked == true){
          arrearId.push(this.arrearArr[i]?.id)
        }
      }
      this.finalSettlementService.salaryRevisionArrears({'pay_month':this.resignationDetails?.value?.pay_month,'pay_year':parseInt(this.resignationDetails?.value?.pay_year),'status':'Unprocessed','arrear_ids':arrearId}).subscribe((res: any) => {
        if(res?.failed_data?.length != 0){
          this.alertMsg = res?.failed_data[0]?.error
          this.notificationService.handleErrorNotification(this.alertMsg,"Error");
          }else{
          this.arrearList()
          }
      })
    }
  }

  // arrear ignored
  arrearIgnored(){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.warningMsg.emit();
    }else{
      this.saveData = [];
      var arrearId  = [];
      for(let i=0;i<this.arrearArr.length;i++) {
        if(this.arrearArr[i]?.checked == true){
          arrearId.push(this.arrearArr[i]?.id)

        }
      }
      this.finalSettlementService.salaryRevisionArrears({'pay_month':this.resignationDetails?.value?.pay_month,'pay_year':parseInt(this.resignationDetails?.value?.pay_year),'status':'Ignored','arrear_ids':arrearId}).subscribe((res: any) => {
        this.arrearList()
      })
    }
  }

  validateArrear(){
    this.submitmethod.emit();

  }

}
