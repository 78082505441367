import { Component, OnInit,Output,EventEmitter, Input} from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-expense-report-expense-receipt',
  templateUrl: './expense-report-expense-receipt.component.html',
  styleUrls: ['./expense-report-expense-receipt.component.scss']
})

export class ExpenseReportExpenseReceiptComponent implements OnInit {

  @Input() receiptListData:any;
  @Input() receiptListLoader:any;
  @Input() currency:any;
  @Input() unReportedReceiptList:any;
  @Input() unReportedReceiptListMainArray:any;
  @Input() formGp:any;
  @Input() from:any;
  @Input() empId:any

  @Output() unReportReceiptData   = new EventEmitter();
  @Output() submitmethod          = new EventEmitter();


  page              = 1;
  pageSize          = 20;
  searchString: any = "";

  unreportedExpense     = false;
  unReportReceiptLoader = false;

  viewReciept         = false;
  viewRecieptLoader   = false;
  viewRecieptData:any = [];

  addExpense = false;

  uploadeAttachments    = false;
  updateAttachmentIndex = 0;
  receiptId             = 0;

  viewAttchments          = false;
  uploadedAttachments:any = [];
  viewAttachmentIndex     = 0;
  isAttachmentRequired = false;

  alertShow               = 'show';

  finalReceiptAttachment:any = [];

  deleteToggle = false;
  deleteClicked = false;

  removeIndex = 0;

  constructor(
    public fb:FormBuilder,
    public appService: AppService,
    public messageService : MessageService,
    private service:ExpenseReportRequestService,
    private notificationService: NotificationService,
    private activityService:ManageActivityService
  ) { }

  ngOnInit(): void {

  }

  removeReceipt(index:any,data:any){

    this.deleteToggle = true;

    this.removeIndex = index;

  }

  deleteReceipt(){

    this.deleteToggle = false;

    if (this.removeIndex !== -1) {

      this.receiptListData?.splice(this.removeIndex, 1);

      // Update the receipts in Report
      let receipts = this.receiptListData?.map((item1 :any)=> {
        return item1?.id;
      });

      this.updateExpenseReport(receipts,'delete');
      // End
    }

  }

  updateExpenseReport(data:any,from:any){
    this.receiptListLoader = true;
    // this.receiptListData = [];
    this.service.updateExpenseReport(this.formGp.get('id')?.value,{'receipts': data}).subscribe((res:any)=>{
      this.receiptListData   = [];
      this.receiptListData   = res?.receipts;
      this.unreportedExpense = false;
      this.formGp.get('total_amount')?.setValue(res?.total_amount);
      this.formGp.get('to_reimbursed')?.setValue(res?.to_reimbursed);
      this.unReportReceiptData.emit({'receiptlist': this.receiptListData, 'addedreceipt': [], 'action': 'mapReceipt','total_amount': res?.total_amount,'to_reimbursed':res?.to_reimbursed});
      this.receiptListLoader = false;

      if(from == 'map')
        this.notificationService.handleSuccessNotification("Created successfully","Success");
      else if( from == 'delete')
        this.notificationService.handleSuccessNotification("Deleted successfully","Success");

      let lastpage = Math.ceil(this.receiptListData?.length / this.pageSize);
      if(this.page>lastpage){
        this.page = lastpage>0?lastpage:1
      }

    },
    (error: any) => {
      this.receiptListLoader = false;
    });
  }

  // Map Unreported Expense
  getUnReportedData(){
    this.uncheck();
    this.unreportedExpense = true;

      this.unReportReceiptLoader = true;
      this.unReportedReceiptList = [];
      let expense_date:any       = [];
      expense_date?.push(this.appService.dateFormatConvert(this.formGp.get('from_date')?.value))
      expense_date?.push(this.appService.dateFormatConvert(this.formGp.get('to_date')?.value))
      this.service.receiptList(JSON.stringify(expense_date)).subscribe((res:any)=>{
        this.unReportedReceiptList          = res;
        this.unReportedReceiptListMainArray = this.unReportedReceiptList?.slice()
        this.unReportReceiptLoader          = false;

      })

  }

  uncheck(){
    if(this.unReportedReceiptList?.length){
      this.unReportedReceiptList?.forEach((item:any, key:any) => {
        item.checked = false;
      })
    }
  }

  addedReceipt(data:any){
    // this.unreportedExpense = false;

    let receipts = this.receiptListData?.map((item1 :any)=> {
      return item1?.id;
    });

    const c = [...receipts, ...data?.addedreceipt];
    receipts = [...new Set(c)];

    this.updateExpenseReport(receipts,'map');

  }

  getSingleReportData(){

    this.receiptListLoader = true;
    this.receiptListData = [];
    this.service.getSingleReport(Number(this.formGp.get('id')?.value)).subscribe((res: any) => {
      // this.receiptListData = [];
      this.receiptListData = res?.receipts;
      this.formGp.get('total_amount')?.setValue(res?.total_amount);
      this.formGp.get('to_reimbursed')?.setValue(res?.to_reimbursed);
      this.unReportReceiptData.emit({'receiptlist': this.receiptListData, 'addedreceipt': [], 'action': 'mapReceipt','total_amount': res?.total_amount,'to_reimbursed':res?.to_reimbursed});
      this.receiptListLoader = false;

    });
  }

  closeReceipt(){
    this.unreportedExpense = false;
  }

  // Receipt View
  detailedReceiptView(id:any){
    this.viewRecieptLoader = true;
    this.viewReciept       = true;
    this.getSigleReceiptView(id);
  }

  getSigleReceiptView(id:any){
    this.viewRecieptData = [];
    this.service.getSingleReceiptData(id).subscribe((res:any)=>{
      this.viewRecieptData    = res;
      this.receiptListData[this.updateAttachmentIndex].attachments = this.viewRecieptData?.attachments;
      this.viewRecieptLoader  = false;
    })
  }

  // Add Expense Section
  addExpenseData(){
    this.addExpense = true;
  }

  saveReceipt(data:any){
    this.addExpense = false;
    let formValue = data?.form_value;
    formValue['expense_date'] = this.appService.dateFormatConvert(formValue['expense_date'])
    if(this.from=='behalf'){
      let data:any = formValue;
      data['employee_id'] = this.empId;
      this.activityService.saveReceipt(data).subscribe((res:any)=>{
        this.setReportData(res,formValue)
      })
    }
    else{

      this.service.saveReceipt(formValue).subscribe((res:any)=>{

        this.setReportData(res,formValue)

      })
    }
  }
  setReportData(res:any,formValue:any){
    if(this.formGp?.get('id')?.value ==  Number(formValue?.report)){
      // this.receiptListData?.push(res);
      this.getSingleReportData();
    }else if(formValue?.report == null){
      this.unReportedReceiptList?.push(res);
    }
    this.unReportReceiptData.emit({'receiptlist': this.receiptListData, 'addedreceipt': this.unReportedReceiptList, 'action': 'saveNewReceipt'})
  }

  closeAddExpense(){
    this.addExpense = false;
  }

  // Upload Multiple Attachment
  addAttachmentFunction(index:any,id:any){
    this.finalReceiptAttachment = [];
    this.uploadeAttachments     = true;
    this.updateAttachmentIndex  = index;
    this.receiptId = id;
  }

  saveAttachment(data:any){
    this.uploadeAttachments = false;
    this.getSigleReceiptView(this.receiptId);

  }

  closeAttachment(){
    this.uploadeAttachments = false;
  }

  // View Multiple Attachments
  viewAttachmentFunction(index:any,id:any){
    this.uploadedAttachments  = [];
    this.viewAttchments       = true;
    this.receiptId            = id;
    this.viewAttachmentIndex  = index;
    this.uploadedAttachments  = this.receiptListData[this.viewAttachmentIndex]?.attachments?.slice();

    // Doc required validation
      if(this.receiptListData[this.viewAttachmentIndex]?.category?.is_attachment){
        this.isAttachmentRequired = true;
        if(this.receiptListData[this.viewAttachmentIndex]?.category?.is_attachment_minimum_amount){
          if(Number(this.receiptListData[this.viewAttachmentIndex]?.amount) >= Number(this.receiptListData[this.viewAttachmentIndex]?.category?.attachment_minimum_amount_limit) ){
            this.isAttachmentRequired = true;
          }else{
            this.isAttachmentRequired = false;
          }
        }
      }
    // End
  }

  closeViewAttachment(){
    this.viewAttchments = false;
    this.getSingleReportData();
  }

  saveViewAttachment(data:any){

  }

  changeTab(){
    this.submitmethod.emit();
  }

}
