import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseReportProfileService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  reportLandingdata(status:any,filter:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/?status=${status}&is_active=true&${filter}`, this.httpOption)

  }
  reportDelete(id: number,body:any) {
    return this.http.patch(`${this.API_PATH}expense/api/v1/expense-report/${id}/`,body, this.httpOption)
  }
  getReportDropdown(status:any) {
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report-dropdown/?status=${status}&is_active=true`, this.httpOption)
  }
  getSingleReportData(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/${id}/`, this.httpOption)
  }


}
