import { Component, OnInit,Input, Output, EventEmitter, SimpleChanges, SimpleChange  } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormArray, FormBuilder, FormGroup , Validators } from '@angular/forms';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent implements OnInit {
  @Input() placeholders: any;
  @Input() items:any
  @Input() bindLabelName = '';
  @Input() bindValue = ''
  @Input() disabled:any
  @Input() indexValue:any
  @Input() selectedData : any
  @Input() cleared:any
  @Output() selectedDatas =  new EventEmitter();


  selection :any

  constructor(
    public messageService : MessageService,
    public appService:AppService

   ) { }

  ngOnInit(): void {
  // Select All
    this.selectAllForDropdownItems(this.items);
    // console.log("items",this.items);

  }

  // ngOnChanges(changes:SimpleChanges){
  //   console.log(changes.selectedData.currentValue);
  //   this.selectedData = changes.selectedData.currentValue
  // }

  commonFn(value:any){
   this.selectedData = [...value]
   console.log("selection",this.selectedData);

   this.selectedDatas.emit({
    selectedData : this.selectedData
   })
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {

    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Close the selected items
  clear(item:any,index:number,i:number){
    this.cleared = true
   console.log("clear selected",this.selectedData);

    this.selectedDatas.emit({
      cleared : this.cleared,
      selectedData : this.selectedData,
      unitValue : item,
      index : index,
      spot : i
    })
  }

}
