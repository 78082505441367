<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Assign Geo Locations</h5>
    <button class="btn-reset text-light-600" routerLink="/geo-fencing/assign-geo-location"><i
        class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step"
            [class]="{'success':myForm['controls'].employees.valid || myForm['controls'].employees.disabled}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Employee</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-employee-assign [disabled]="disabled" [editflag]="editflag" [employeeDropdown]="employeeDropdown"
              [formGp]="employeesForm" (submitmethod)="submitMethod(2)"></app-employee-assign>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step"
            [class]="{'success':(myForm['controls'].locations.valid && tab2touched) || (myForm['controls'].locations.disabled && isEditForm) }">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Locations</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-location-assign [disabled]="disabled" [formGp]="locationsForm" [locationdropdown]="locationdropdown"
              (submitmethod)="submitForm()"></app-location-assign>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class=" config-container">
      <!-- <pre>Form Values: {{myForm.value | json}}</pre>  -->
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
