<div class="side-panel-body" style="overflow-y: auto;">
    <div class="container-fluid p-32 ">
        <div class="col-12">
            <h5>DETAILS</h5>
        </div>
        <div class="card card-c2 p-32">
            <div class="row  row-16">
                <!-- <div class="col-12">
                    <div class="title">{{data?.requested_on | date : "d MMM YYYY"}}</div>
                </div> -->
                <div class="col-12 d-flex ">
                    <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
                </div>
                <div class="col-12 mt-8">
                    <h5 class="mb-8">Work From Home<span
                            *ngIf="data?.is_cancel ==true">&nbsp;Cancellation</span>&nbsp;Request Approval</h5>
                    <span class=" badge-custom {{data?.request_type_color_code}}">WFH<span
                            *ngIf="data?.is_cancel ==true">&nbsp;Cancellation</span>&nbsp;Request</span>
                </div>
                <div class="col-12">
                    <div class="row row-8">
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                                <div class="row row-16">
                                    <div class="col-12 d-flex mb-8">
                                        <span class="title">Work from home details</span>
                                        <span class="text-accent2 pointer ms-auto fs-12 fw-500"
                                            (click)="timegraph();timeOffTrend = true"><i
                                                class="bi bi-graph-up me-1"></i>Time off
                                            Trend</span>
                                    </div>
                                    <app-table-loader *ngIf="loader"></app-table-loader>
                                    <ng-container *ngIf="!loader">
                                        <div class="col-12">
                                            <div class="card card-c2">
                                                <div class="card-body p-16">
                                                    <div class="d-flex align-items-center gap-16">
                                                        <div class=" bg5 flex-center flex-column radius-4 sq-88  ">
                                                            <div class="fw-500 fs-24 text-uppercase lh-1">{{totday}}
                                                            </div>
                                                            <div class="fs-14">days</div>
                                                        </div>
                                                        <div class="d-flex flex-column gap-8">
                                                            <div class="fs-14 ">Work From Home</div>
                                                            <div class="fs-14 fw-500">
                                                                {{this.appService.dateFormatDisplay(workFromhome?.start_date)}}
                                                                -
                                                                {{this.appService.dateFormatDisplay(workFromhome?.end_date)}}
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-wrap ms-auto gap-16 fs-12">
                                                            <div class="d-flex align-items-center gap-8">
                                                                <span
                                                                    class="type-indicator sq-12 type-holiday"></span>Holiday
                                                            </div>
                                                            <div class="d-flex align-items-center gap-8">
                                                                <span
                                                                    class="type-indicator sq-12 type-weekoff"></span>Weekoff
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body p-16 overflow-hidden" style="max-height: 235px;">
                                                    <div class="row row-8">
                                                        <div class="col-12"
                                                            *ngFor="let request of $any(workFromhome?.requests),let ind = index">
                                                            <div class="card card-c2 pointer">
                                                                <div
                                                                    class="card-body d-flex align-items-center gap-16 indicater px-16 py-8">
                                                                    <span
                                                                        class="fw-500 ">{{this.appService.dateFormatDisplay(request?.wfh_date)}}</span>
                                                                    <span class="fs-10">( {{request?.shift_start_time}}
                                                                        - {{request?.shift_end_time}} )</span>
                                                                    <span class="fs-10">( {{request?.shift_name}}
                                                                        )</span>
                                                                    <span class="type-indicator sq-12 type-1"
                                                                        *ngIf="request?.holiday_status"></span>
                                                                    <span class="type-indicator sq-12 type-2"
                                                                        *ngIf="request?.weekoff_status"></span>
                                                                    <span
                                                                        class="ms-auto fw-500 fs-12 text-accent1">{{request?.day_portion}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="detail-title">Reason</div>
                                            <div class="detail-desc">{{workFromhome?.reason}}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="reqStatus == 'Pending'" [formGroup]="form">
                    <div class="col-12">
                        <textarea name="" id="" rows="3" placeholder="Comment here..." formControlName="comment"
                            class="form-control my-12 textarea-comment"
                            [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                        <div *ngIf="f.comment.errors" class="invalid-feedback">
                            <div *ngIf="f.comment.errors?.maxlength">
                                {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                        </div>
                    </div>
                    <div class="col-12 d-flex gap-16">
                        <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                            (click)="confirm('Rejected')">Decline</button>
                        <button class=" btn btn-primary text-uppercase btn-sm fw-400"
                            (click)="confirm('Approved')">Confirm</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- Trend Graph Sidebar -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='timeOffTrend === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5> Time off trend</h5>
            <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-24">
                    <div class="col-12 ">
                        <div class="card card-c2 pb-24  px-24 pt-24">
                            <div class="row row-12">
                                <div class="col-12 d-flex">
                                    <div class="fs-24 me-auto">Time off trend - {{selectedYearDisp}} </div>
                                    <ng-select class="form-ngselect ms-auto fs-14"
                                        placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                                        (change)="getwfhtrendDetails($event)" [(ngModel)]="selectedYearDisp"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option class="fs-12" *ngFor="let year of yearList"
                                            [value]="year.k">{{year.k}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-12 " *ngIf="!graphLoader">
                                    <app-bar-chart [data]="multi" [labels]="xAxisLabel"
                                        [yText]="yAxisLabel" [id]="6"></app-bar-chart>
                                </div>
                                <div class="col-12 " *ngIf="graphLoader">
    
                                    <app-graph-loader></app-graph-loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>