
  <div class="row row-16">
    <div class="col-12">
      <div class="col-12 d-flex position-relative">
        <span class=" text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
        <button *ngIf="from!='behalf'" class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
          (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
          <i class="bi toggle-star"></i>
        </button>
      </div>
      <div class="col-12 mt-8 card-ribbon">
        <div class="w-100 d-flex align-items-start gap-8">
          <h5 class="mb-8 text-break" *ngIf="requestitem?.request_data?.is_cancel == false">Short Leave (SHL)</h5>
          <h5 class="mb-8 text-break" *ngIf="requestitem?.request_data?.is_cancel == true">Short Leave Cancellation (SHL)</h5>
          <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
            <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
              *ngIf="requestitem?.approved_status == 'Pending'">Pending</span>
            <span class="badge px-16 ms-auto fs-14 badge-success py-1"
              *ngIf="requestitem?.approved_status == 'Approved'">Accepted</span>
            <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
              *ngIf="requestitem?.approved_status == 'Rejected'">Rejected</span>
            <span class="text-light-400">|</span>
            <span class="link-primary fs-14 text-nowrap" (click)="requestViewStatus()">View Status</span>
          </ng-container>
          <!-- Invalid request -->
          <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
            class="ribbon ribbon-right ribbon-danger  ribbon-sm"
            style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
        </div>
        <span *ngIf="requestitem?.request_data?.is_cancel == false"
          class=" badge-custom {{requestitem?.request_type_color_code}} ">Short Leave (SHL)</span>
        <span *ngIf="requestitem?.request_data?.is_cancel == true"
          class=" badge-custom {{requestitem?.request_type_color_code}} ">Short Leave Cancellation (SHL)</span>
        <span
          *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
          class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
      </div>
    </div>
    <ng-container *ngIf="from!='behalf'">

      <app-progressbar [requestitem]="requestitem"></app-progressbar>

      <div class="col-12">
        <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
        </p>
      </div>
    </ng-container>
    <div>


      <app-view-loader *ngIf="loader1"></app-view-loader>
      <ng-container  *ngIf="!loader1">
        <div class="row row-8">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pt-16 pb-24">
              <div class="row row-24">
                <div class="col-12">
                  <div class="d-flex">
                    <span class="title">SHORT Leave Details</span>
                    <span (click)="trendFunction()" class="text-accent2 pointer ms-auto fs-12 fw-500"><i
                        class="bi bi-graph-up me-1"></i>Short Leave Trend</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="card card-c5 month-active text-light-500">
                    <div class="card-body uniquebg2 flex-center flex-column  text-light-600">
                      <div class="fw-500 fs-24 text-uppercase"> {{requestitem?.requests?.date+" "+requestitem?.requests?.total_duration|date:'HH:mm'}}</div>
                      <div class="fs-14">Hours</div>
                    </div>
                    <div class="card-body gap-1 justify-content-center d-flex flex-column overflow-hidden">
                      <p class="fs-14 mb-0 text-trim" title="Short Leave">Short Leave</p>
                      <p class="fs-14 mb-0 fw-500" >{{this.appservice.dateFormatDisplay(requestitem?.requests?.date)}} </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Date</div>
                  <div class="detail-desc">{{this.appservice.dateFormatDisplay(requestitem?.requests?.date)}}
                  </div>
                </div>
                <div class="col-12" *ngFor="let item of requestitem?.requests?.short_leave_portion">
                  <div class="row row-24">
                    <div class="col-4">
                      <div class="detail-title">From Time</div>
                      <div class="detail-desc">{{this.appservice.timeFormatDisplay(item.from_time)}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">To Time</div>
                      <div class="detail-desc">{{this.appservice.timeFormatDisplay(item.to_time)}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Duration</div>
                      <div class="detail-desc"> {{this.appservice.formatTime(item.duration)}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Reason</div>
                  <div class="detail-desc">{{requestitem?.requests?.reason}}</div>
                </div>
                <div class="col-12" *ngIf="requestitem?.request_data?.is_cancel == false">
                  <div class="detail-title mb-8">Document</div>
                  <div class="detail-desc" *ngIf="requestitem?.requests?.document == ''">-</div>
                  <div style="width:12.9375rem;" *ngIf="requestitem?.requests?.document != ''">

                    <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                      <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                      <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                        <span class="tips text-uppercase fs- fw-700 text-trim" >{{this.appservice.getDocName(requestitem?.requests?.document) }}</span>
                        <span class="tips-helper " >{{this.appservice.getDocName(requestitem?.requests?.document) }}</span>
                        <div class="d-flex gap-8"  >
                           <div class="link-primary" (click)="viewDocument(requestitem?.requests?.document)">
                            <i class="bi bi-eye me-1"></i>View
                          </div>
                          <div class="link-primary"
                            (click)="this.appservice.downloadFile(requestitem?.requests?.document,this.appservice.getDocName(requestitem?.requests?.document))">
                            <i class="bi bi-download me-1"></i>Download
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="requestitem?.request_data?.is_cancel == true">
                  <div class="detail-title">Reason for Cancellation</div>
                  <div class="detail-desc">{{requestitem?.requests?.remark}}</div>
                </div>



              </div>
            </div>
          </div>


          <form class="mt-20" [formGroup]="form">
            <ng-container *ngIf="!requestitem?.achieved_status">
              <div class="col-12" *ngIf="requestitem?.requests?.status=='Pending'">
                <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
                  class="form-control mb-8 textarea-comment" formControlName="comment"
                  [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                <div *ngIf="f.comment.errors" class="invalid-feedback">
                  <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex gap-16">
              <ng-container *ngIf="requestitem?.requests?.status=='Pending'">

                <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                  (click)="confirm('Rejected')">Decline</button>
                <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                  (click)="confirm('Approved')">Confirm</button>

              </ng-container>

              </div>
            </ng-container>

          </form>

        </div>
      </ng-container>
    </div>
  </div>



<!-- Trend Graph Sidebar -->
<div *ngIf="timeOffTrend" class="side-panel" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='timeOffTrend === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5> Short leave</h5>
      <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
    </header>

    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">


        <div class="row row-16">

          <div class="col-12">
            <div class="card card-c2 pb-24  px-24 pt-24">
              <div class="row row-12">

                <div class="col-12 d-flex">
                  <div class="fs-24 me-auto">Short leave</div>

                  <ng-select class="form-ngselect h-36 fs-14" placeholder="Select year" [(ngModel)]="selectedYear"
                    [clearable]="false" (change)="yearChange(selectedYear)" name="selectedYear">
                    <ng-option *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
                  </ng-select>
                </div>
                <div class="col-12" *ngIf="barchartLoader">

                  <app-graph-loader></app-graph-loader>
                </div>
                <div *ngIf="!barchartLoader" class="col-12">

                  <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
                    [yText]="barChartYAxisLabel"></app-bar-chart>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </form>
</div>


<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [sendReminder]="sendReminder" [requestId]="requestitem?.request_data?.id" (closePanel)="viewstatusPanel=false"></app-view-status>

    </form>
  </div>
</ng-container>

<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="investalertMsg"></app-alert-toggle>

<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
  [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
