<div *ngIf="showProgress" class="draggable-popup {{popupClass}} py-16 ps-16 pe-20" id="draggableElement">
    <div class="d-flex align-items-baseline h-100">
        <i *ngIf="popupClass =='popup-primary'" class="icon-arrow-repeat text-accent2 fs-14 me-8"></i>
        <i *ngIf="popupClass =='popup-danger'" class="bi bi-exclamation-triangle text-danger fs-14 me-8"></i>
        <i *ngIf="popupClass =='popup-success'" class="bi bi-check2-circle text-success fs-16 me-8"></i>
        <div class="d-flex flex-column w-100 h-100">
            <div class="d-flex mb-auto align-items-center mb-8" *ngIf="popupClass =='popup-primary'">
                <p class="mb-0 fs-14 lh-1 me-16">{{this.taskText}}</p>
                <button class="ms-auto sq-30 radius-2 btn-accent2 btn-reset" (click)="this.nav.emit()"><i
                        class="icon-chevron-right-lg fs-12"></i></button>
            </div>
            <div class="d-flex align-items-center mb-8" *ngIf="popupClass !='popup-primary'">
              <p class="mb-0 fs-14 lh-1 me-16">{{processText}}</p>
              <i class="icon-close-lg fs-10 ms-auto pointer"   (click)="this.close.emit()"></i>
            </div>
            <div *ngIf="popupClass =='popup-primary'" class="d-flex flex-column  gap-8">
              <div class="d-flex align-items-end">
                  <span class="fs-12">{{progPercentage| number : '1.2-2'}}% completed</span>
                  <span class="fs-10 ms-auto opacity-75">{{timeLeft}} remaining</span>
              </div>
              <ngb-progressbar class="progress-type4" [value]="progPercentage"></ngb-progressbar>
            </div>

            <div class="d-flex flex-column align-items-start gap-8" *ngIf="popupClass !='popup-primary'">
              <span class="mb-0 fs-12 text-trim">{{completeText}}</span>
              <button  *ngIf="popupClass !='popup-primary'" class="{{btnClass}} btn fs-10" style="max-height:2.5rem" (click)="this.viewDetails.emit()">View Details</button>
          </div>
        </div>
    </div>
</div>
