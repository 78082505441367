import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-leave-summary',
  templateUrl: './leave-summary.component.html',
  styleUrls: ['./leave-summary.component.scss']
})
export class LeaveSummaryComponent implements OnInit {

  @Input() employeeDropdown      : any
  @Input() submitted             : any
  @Input() companyDD             : any
  @Input() buDD                  : any
  @Input() DesignationDD         : any
  @Input() BranchDD              : any
  @Input() DepDD                 : any
  @Input() gradeDD               : any
  @Input() empstatusDD           : any
  @Input() emptypeDD             : any
  @Input() leavePolicy           : any
  @Input() leaveType             : any
  // Above needs to be removed
  @Input() balanceForm           : any
  @Input() viewDetail            : any
  @Input() resDataFilter         : any
  @Input() disabled              : any
  @Input() callReport            : any
  @Input() salFilter             : any
  @Input() closeControl          : any
  @Input() checkListElements     : any
  @Input() dateSetup             : any
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() ouFilterForm: any
  @Input() dojFilter : any

  @Output() callReports  = new EventEmitter();
  @Output() filterPanel  = new EventEmitter();
  @Output() resetFilter  = new EventEmitter();
  @Output() submitDone   = new EventEmitter();

  dateFormat          :any

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  // Form
  get f()  { return this.balanceForm.controls; }

  fromDateFilter = (date: Date | null): boolean => { return true; }
  toDateFilter = (date: Date | null): boolean => { return true; }

  constructor(
    public messageService : MessageService,
    private reportService : ReportsService,
    public appservice : AppService,
    public leaveService : LeaveReportService,
    public cd : ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.getDateFormat()
    this.setRequired()
  }

  filterFormData(use:any){
    let selectedDate :any
    if(this.balanceForm.controls.from_date.value != '' && this.balanceForm.controls.to_date.value != ''){
      let start1         = moment(this.balanceForm.controls.from_date.value,this.dateFormat).format('YYYY-MM-DD');
      let end1           = moment(this.balanceForm.controls.to_date.value,this.dateFormat).format('YYYY-MM-DD');
      selectedDate          = [start1,end1];
    }

    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let leave_policy = this.stringifyCont(this.ouFilterForm.controls.leave_policy.value)
    let leave_type = this.stringifyCont(this.ouFilterForm.controls.leave_type.value)
    let leave_period = selectedDate!=null && selectedDate!="" ?selectedDate:JSON.stringify([])

    if(use == 'filter'){
      return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&branch=${branch}&grade=${grade}&employee_list=${employee_list}&employment_status=${employment_status}&employee_type=${employee_type}&leave_policy=${leave_policy}&leave_type=${leave_type}&leave_period=${leave_period}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'employment_status' : this.ouFilterForm.controls.employment_status?.value, 'employee_type' : this.ouFilterForm.controls.employee_type.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value,'leave_policy': this.ouFilterForm.controls.leave_policy.value,'leave_type': this.ouFilterForm.controls.leave_type.value,'leave_period': selectedDate});
    }
  }

  setRequired(){
    this.balanceForm.get('from_date')?.setValidators([Validators.required])
    this.balanceForm.get('to_date')?.setValidators([Validators.required])
    this.balanceForm.get('from_date')?.updateValueAndValidity();
    this.balanceForm.get('to_date')?.updateValueAndValidity();
  }

  applyBtn : boolean = false

  submitBalanceReport(){
    this.submitted          = true;
    let offset = 0
    if (this.balanceForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }

    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    })
    this.viewDetail           = false;
    this.applyBtn = false
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  // Resetting the filters
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.ouFilterForm.get('leave_policy')?.reset('');
      this.ouFilterForm.get('leave_type')?.reset('');
      this.ouFilterForm.get('leave_period')?.reset('');
      this.balanceForm.get('from_date')?.reset('')
      this.balanceForm.get('to_date')?.reset('')
    }
    this.callReports.emit({
      reportName : 'Leave Balance Summary Report'
    })
  }

  // Tag Close
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'employment_status'){
      this.empstatusDD = [];
    }else if(this.closeControl == 'employee_type'){
      this.emptypeDD = [];
    }

    this.ouFilterForm.get(this.closeControl)?.setValue('');
    this.submitBalanceReport();
  }

  // Date Format
  getDateFormat(){
     this.dateFormat = this.appservice.getdatepickerformat();
    if(this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  resetPage(val:any){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'leave_period' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  fromDateVal(value:any){
    if(moment(this.f.from_date.value).isSameOrAfter(moment(this.f.to_date.value))){
      this.balanceForm.patchValue({
        to_date : null
      })
    }
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
