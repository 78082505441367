import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder,  Validators } from '@angular/forms';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { GeolocationService } from '../../../../@core/services/geo-location.service';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-assign-geo-location',
  templateUrl: './assign-geo-location.component.html',
  styleUrls: ['./assign-geo-location.component.scss']
})
export class AssignGeoLocationComponent implements OnInit {
  assignGeoLoc = false;
  submitted                : any;
  locationViewdata         : any =[];
  offset                   = 0;
  listlength               = 0;
  direction                = '';
  defaultlimit:any         = 20;
  geolocationDetailsList   :any   = [];
  geolocationlist:any      = [];
  infinityloader           = false;
  permissions:any          = [];
  searchKeyword            = "";
  statusBtn:any            = "Active";
  activeToggle:any         = "True";
  loader1                  = false;
  loader                   = true;
  nodata                   = false;
  searchlistdata:any       = [];
  locsearch:string         = this.messageService.searchdisplay('Employee');
  deleteToggle             = false;
  deletealert              = false;
  viewSidePanel             = false;
  deletekey                 :any;
  deleteid                 :any;
  empassignid               :any;
  deleteClicked            = false;
  deletemodel           = false;
  mappedlength             = 0;
  alertMsg                 :any;
  empId:any;
  sLoading = false
  filter             = false;
  filterscroll =false;
  // filterdata:any ='ordering=-created_at'
  filterdata:any =this.GeolocationService.FILTERDATA;

  filterForm                = this.formBuilder.group({
    company               : '',
    bu                    : '',
    department            : '',
    designation           : '',
    grade                 : '',
    emp_status            : '',
    emp_type              : '',
    branch                : '',
    repomanager           :'',
    funmanager            :'',
    sortby                : ['Latest'],
  }
);
from = 'EmployeeAssign';
@ViewChild(SearchbarComponent) child:any;

// import
fileName       : string    = '';
  fileSize       : string    = '';
  invalidFormat              = false;
  deleteToggle2               = false;
  deleteMsg                  = "";
  iscompleted                = false;
  isDataUpload               = 0;
  percentage     :any        = 0;
  isProgressStart            = false;
  successDataCount           = 0;
  downloadMsg                = false;
  invalid                    = false
  confirmMsg                 = false;
  exportData     :any        = [];
  excelData      :any        = [];
  importData     :any        = [];
  failedData     :any        = [];
  importTemp     :any        = [];
  errorMsg                   = '';
  successMsg                 = '';
  body                       = '';
  ws             :any        = null;
  saveDataArray  :any        = []
  chunkLen                   = 10;


  constructor(
    private GeolocationService:GeolocationService,
    public formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService : MessageService,
    private employeeService : EmployeeService,
    public notificationservice:NotificationService,
    public changeDetectorRef: ChangeDetectorRef

    ) { }

  ngOnInit(): void {
      this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.defaultlimit = 40;
        }
      });

    let arrFilter: any = []
    let arrList = []
    let arrData = []
    if(this.filterdata){
      arrData = this.filterdata.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })
      let ordering:any = this.appService.memOrdering()
      this.filterForm.reset(this.appService.memFilterArray(arrFilter,ordering));
    }

      this.getPermission();
      this.applyfilterfn();

  }

  showDataItems(){
    this.loader = true;
    this.GeolocationService.getlocationassignList(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.filterdata).subscribe((res: any)=> {
      this.listlength = res.body.results.length;
      if(this.offset == 0){
        this.geolocationlist = [];
      }

      if (res.body.results.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.geolocationDetailsList = res.body.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
      this.sLoading = false
    })

  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.geolocationlist.push(this.geolocationDetailsList[i]);
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset     = 0;
      this.geolocationlist  = [];
      this.showDataItems();
    }else{
      this.GeolocationService.getemplocation(data).subscribe((res: any) => {
        this.searchKeyword  = res?.body.employee_name;
        res.body['color_code']=res?.body['employee_color_code'];
        res.body['locations_count']=res?.body['count_of_locations'];
        res.body['id']  = data;
        this.geolocationlist  = [];
        this.geolocationlist.push(res?.body);
        this.loader    = false;
      });
    }
  }

  searchlocation(searchKeyword:any){
    this.sLoading = true
    this.loader         = true;
    this.offset         = 0;
    this.geolocationlist = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.showDataItems();
    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/geo-fencing/assign-geo-location');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      if(this.filterscroll = false){
        this.showDataItems();
        }else{
        this.applyfilterfn();
        }
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  addnew(input:any){

  }

  viewemp(id:any){
    this.empId  = id;
    if(this.permissions.v){
      this.assignGeoLoc     = true;
      this.loader1     = true;
      this.GeolocationService.getemplocation(id).subscribe((res: any) => {
        this.locationViewdata   = res.body;
        this.empassignid= res.body.location_data[0]['assigned_id'];

        this.loader1            = false;
      })
    }

  }

  filterclick(){
    this.filter = true;
   }

   resultfilterfn(data:any){
    this.filterdata = data;
    this.GeolocationService.setFilterData(data)
    this.offset = 0;
    this.child.clearBar();
  }

  closefn(daata:any){
    this.filter = daata;
   }

   applyfilterfn(){
    this.loader = true;
    this.nodata       = false;
    this.showDataItems();
    this.filter = false;
   }

   deletemapped(id:any,key:any){

    this.offset         = 0;
   if(key=='location'){
    this.GeolocationService.deleteAssignedLocation(id).subscribe((res: any) => {
      this.assignGeoLoc  = false;
      this.deletemodel =false;
      if(res.status=1)
      this.notificationservice.handleSuccessNotification("Deleted Successfully","Success");
      this.showDataItems();
    });
   }else{
    this.GeolocationService.deleteAllEmpLocation(id).subscribe((res: any) => {
      this.assignGeoLoc  = false;
      this.deletemodel =false;
      if(res.status=1)
      this.notificationservice.handleSuccessNotification("Deleted Successfully","Success");
      this.showDataItems();
    });
  }
  }

  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
  }

  // File Change
  onFileChange(evt: any) {
    this.excelData  = [];
    this.importData = []
    this.iscompleted = false
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){

      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];

        this.fileName   = target.files[0].name;
        this.fileSize   = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData  = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
        this.changeDetectorRef.detectChanges();

      };
      reader.readAsBinaryString(target.files[0]);
    }else{
      this.invalidFormat = true;
    }
  }

  // Processing Data
  processData(){
    if(this.excelData.length <=1){
      this.deleteToggle2 = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.excelData[0];


      let Heading = ['Employee Name', 'Employee Code', 'Location Name',	'For Specific Period',	'Period Days']
      this.invalid = headerRow.every((element:any) => Heading.includes(element));
      let success =   JSON.stringify(Heading)==JSON.stringify(headerRow)

      if(success == false || !this.invalid){
        this.deleteToggle2 = true;
        this.deleteMsg = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
       this.prepareUploadData()
    }
  }

  // Before Upload
  prepareUploadData(){
    let headerRow   = this.excelData[0];
    let header:any  = [];
    this.importTemp = [];
    this.importData = []

    for(let i=0;i<headerRow.length;i++){
      switch(headerRow[i]){
        case 'Employee Name':{
          header.push('employee_name');
          break;
        }
        case 'Employee Code':{
          header.push('employee_code');
          break;
        }
        case 'Location Name' : {
          header.push('location_name');
          break;
        }
        case 'For Specific Period' : {
          header.push('for_specific_days_only')
          break
        }
        case 'Period Days' : {
          header.push('duration_of_days')
          break
        }
        default:{
          header.push(headerRow[i]);
          break;
        }
      }
    }
    this.excelData.forEach((item:any, key:any) => {
      var dict:any = {};
      for(let i=0;i<header.length;i++){
        dict[header[i]]= item[i];
      }
      this.importTemp.push({
        dict
      })
    })
    for(let j=0;j<this.importTemp.length;j++){
      this.importData.push(this.importTemp[j]['dict'])
    }
  }

  // Upload
  uploadBalance(){
    this.isDataUpload     = 1;
    this.iscompleted = false
    this.isProgressStart  = true
    this.failedData       = []
    this.saveDataArray = []
    var l                 = this.importData.length;
    var cLoop             = Math.ceil(l/this.chunkLen)
    var p                 = 100/cLoop
    this.successDataCount = 0
    this.uploadasChunks(0,p);
  }

  uploadasChunks(k:any,p:any){
    var l           = this.importData.length;
    this.percentage = 0
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    this.saveDataArray = [];
    this.percentage +=(p/4);
    for(var i= 1; i<this.importData.length; i++){
      this.saveDataArray.push(this.importData[i])
    }
    this.GeolocationService.uploadempImportData(this.saveDataArray).subscribe((res:any)=>{

      this.body = x+' out of '+l+" data uploaded successfully"
      this.confirmMsg = false;
      if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
        res.body.failed_data.forEach((i:any, k:any) => {
              this.failedData.push(i)
        })
      }
    this.percentage +=((p/4)*3);
    this.iscompleted = true
    this.isProgressStart = false
    this.successDataCount = this.successDataCount+res.body.success_data_count;
    if(this.failedData.length == 0){
      this.notificationservice.handleSuccessNotification(this.saveDataArray.length+" location data uploaded successfully.","Success");
      this.CompleteUpload();
    }else {
      this.exportData    = [];
      for (let i = 0; i < this.failedData.length; i++) {

        this.exportData.push({'Employee Name':this.failedData[i]['data']['employee_name'],'Latitude ':this.failedData[i]['data']['employee_code'], 'Location Name':this.failedData[i]['data']['location_name'],'For Specific Period':this.failedData[i]['data']['for_specific_days_only'],'Period Days':this.failedData[i]['data']['duration_of_days'],'Error Description':this.failedData[i]['error']});
      }
      this.successMsg = this.successDataCount+" out of "+(this.importData.length-1)+" data uploaded successfully "
      this.errorMsg = this.failedData.length+' location(s) have error data'
      this.downloadMsg = true;

    }
  },
  (error:any)=>{
    this.isProgressStart = false
    this.notificationservice.handleErrorNotification('Something went wrong','Error')
  }
  )
  }

  // After Uploading
  CompleteUpload(){
    this.fileName      = '';
    this.excelData          = [];
    this.fileSize      = '';
    this.exportData  = [];
    this.failedData = [];
    this.importTemp    = [];
    this.importData    = [];
    this.viewSidePanel = false
    this.offset = 0
    this.showDataItems();
  }
}
