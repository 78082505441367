import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function UnamepatternValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }
      const unamePattern = /^(?! )+[A-Za-z0-9 @#&_+\-\;':"\\,\/]*[A-Za-z0-9@#&_+\-\;':"\\,\/]$/.test(value);

      return !unamePattern ? {pattern:true}: null;
      // return null
  }
}

