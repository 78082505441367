<form [formGroup]="formGp">
  <div class="container-fluid p-32 form-container">
    <div class="row">
      <div class="col-12">
        <p class="text-uppercase fw-500 mb-8">Assign Rights</p>
        <p class="text-helper mb-24">
          Below you can select visible menu one by one and give rights
          accordingly to the employees.
        </p>
      </div>
    </div>
    <app-view-loader *ngIf="loader"></app-view-loader>
    <ng-container *ngIf="!loader">
      <div class="row">
        <div class="col-12 mb-24">
          <ul class="tabs tab-pill">
            <li *ngFor="let m of menu; let i = index">
              <a
                (click)="setIndex(i, m.menu)"
                class="pill"
                [class.active]="selectedmenu === i"
                [class.success]="m.status === 1"
                >{{ m.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-section col-12">
        <p *ngIf="oneLevelChild(mainId)?.controls?.length == 0" class="text-helper mb-24">
          {{ selectname }} is all caught up as it doesn’t have any section’s or pages
        </p>
        <ng-container *ngIf="(subMenus | levelTransform : firstlevelmenu).length > 0" >
          <div class="tab-view" [class.active]="selectedmenu >= 0">
            <div class="col-12" *ngIf="oneLevelChild(mainId)?.controls?.length != 0" >
              <p class="text-uppercase fs-14 fw-500 mb-16">{{ selectname }}</p>
              <div class="card shadow-0 px-16 mb-16">
                <div class="col-12 form-row my-10" *ngIf="selectname != 'Setting'" >
                  <label for="" class="form-label">Rights for pages</label>
                </div>
                <ng-container formArrayName="assignrights">
                  <ng-container *ngFor="let details of details(mainId).controls; let i = index" [formGroupName]="mainId">
                    <ng-container *ngFor="let levelOne of oneLevelChild(mainId).controls; let j = index" [formGroupName]="0">
                      <ng-container formArrayName="lvlOneChildren">
                        <app-pa-children-menu [index]="j" [mainFormArr]="oneLevelChild(mainId)"></app-pa-children-menu>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
              <ng-container *ngFor="let second of newSection()?.controls; let j = index" >
                <div class="card shadow-0 px-16 mb-16">
                  <div class="card-body px-0 py-16">
                    <div class="d-flex justify-content-between align-items-center" >
                      Section
                      <a class="text-light-600" (click)="deleteSection(j)" ><i class="icon-trash"></i></a>
                    </div>
                  </div>
                  <div class="card-body px-0 py-24">
                    <div class="row row-24">
                      <div class="col-12 mb-24 form-row">
                        <label for="" class="form-label">Select section </label>
                        <ng-select
                          class="form-ngselect submenuSection{{ j }}"
                          [items]="sectionArr"
                          name="submenuSection{{ j }}"
                          (change)="sectionSelect($event, j)"
                          placeholder="{{ this.messageService.selectplaceholddisplay('section')}}"
                          bindLabel="name"
                          bindValue="menu"
                          [(ngModel)]="selectedSubSec[j]"
                          [ngModelOptions]="{ standalone: true }"
                          [ngClass]="{ 'is-invalid': selectedSubSecError[j] }"
                        >
                        </ng-select>
                        <div *ngIf="selectedSubSecError[j]" class="invalid-feedback d-inline">
                          <div *ngIf="selectedSubSecError[j]">
                            The data is already selected, please select
                            different data.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row row-16" *ngIf="selectedSubSec[j] && !selectedSubSecError[j]">
                      <div class="col-12 form-row">
                        <label for="" class="form-label">Rights for pages</label>
                      </div>
                      <ng-container formArrayName="assignrights">
                        <ng-container *ngFor="let details of details(mainId).controls;let i = index" [formArrayName]="mainId">
                          <ng-container *ngFor="let levelOne of oneLevelChild(mainId).controls;let l = index" [formGroupName]="0">
                            <ng-container formArrayName="lvlOneChildren">
                              <!-- [ngClass]="{ 'is-invalid':  secondLvlChild(l).errors?.customError}" -->
                              <ng-container *ngIf="secondLvlChild(l).value.length != 0 && secondlevelmenu[j] == nFormGp(l)?.get('menu')?.value">
                                <div [ngClass]="{ 'is-invalid': secondLvlChild(l).status == 'INVALID' }">
                                  <ng-container *ngFor="let section of secondLvlChild(l).controls;let k = index" [formArrayName]="0">
                                    <ng-container formArrayName="lvlTwoChildren">
                                      <!-- Checking if ngselect current one is same of formgroup menu -->
                                      <div class="col-12 form-row my-10" [formGroup]="nSecFormGp(l, k)">
                                        <div class="form-check">
                                          <input id="{{nSecFormGp(l, k)?.get('menu')?.value}}"
                                            class="form-check-input"
                                            type="checkbox"
                                            value=""
                                            [checked]="nSecFormGp(l, k)?.get('view')?.value || nSecFormGp(l, k)?.get('add')?.value || nSecFormGp(l, k)?.get('edit')?.value || nSecFormGp(l, k)?.get('delete')?.value"
                                            [disabled]="nSecFormGp(l, k)?.get('view')?.disabled || nSecFormGp(l, k)?.get('add')?.disabled || nSecFormGp(l, k)?.get('edit')?.disabled || nSecFormGp(l, k)?.get('delete')?.disabled"
                                            (click)="setCheckChildrens(nSecFormGp(l, k)?.get('menu')?.value,$event,l,k)"/>
                                          <label class="form-check-label" for="{{nSecFormGp(l, k)?.get('menu')?.value}}">
                                            {{nSecFormGp(l, k)?.get("name")?.value}}
                                          </label>
                                          <ng-container
                                            *ngIf="getFormArrayLength(l, k) == 0; else child">
                                            <div class="mt-16 d-flex gap-80 flex-wrap" *ngIf="(nSecFormGp(l, k)?.get('delete')?.value ||nSecFormGp(l, k)?.get('add')?.value || nSecFormGp(l, k)?.get('edit')?.value ||nSecFormGp(l, k)?.get('delete')?.value ||nSecFormGp(l, k)?.get('view')?.value) == true">
                                              <div class="form-check" *ngIf="nSecFormGp(l, k)?.get('name')?.value != null">
                                                <input
                                                  class="form-check-input"
                                                  formControlName="view"
                                                  type="checkbox"
                                                  [id]="'view'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)"
                                                  [checked]="nSecFormGp(l, k)?.get('view')?.value"
                                                  [attr.disabled]="
                                                    nSecFormGp(l, k)?.get('add')?.value == true ||
                                                    nSecFormGp(l, k)?.get('edit')?.value == true ||
                                                    nSecFormGp(l, k)?.get('delete')?.value == true ? true : null"/>
                                                <label class="form-check-label" [for]="'view'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)">View</label>
                                              </div>
                                              <div class="form-check" *ngIf="nSecFormGp(l, k)?.get('add')?.value != null">
                                                <input
                                                  class="form-check-input"
                                                  formControlName="add"
                                                  type="checkbox"
                                                  [id]="'add'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)"
                                                  [checked]="nSecFormGp(l, k)?.get('add')?.value"/>
                                                <label class="form-check-label" [for]="'add'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)">Add</label>
                                              </div>
                                              <div
                                                class="form-check"
                                                *ngIf="nSecFormGp(l, k)?.get('edit')?.value != null">
                                                <input
                                                  class="form-check-input"
                                                  formControlName="edit"
                                                  type="checkbox"
                                                  [id]="'edit'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)"
                                                  [checked]="nSecFormGp(l, k)?.get('edit')?.value"/>
                                                <label class="form-check-label" [for]="'edit'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)">Edit</label>
                                              </div>
                                              <div class="form-check" *ngIf="nSecFormGp(l, k)?.get('delete')?.value != null">
                                                <input
                                                  class="form-check-input"
                                                  type="checkbox"
                                                  formControlName="delete"
                                                  [id]="'delete'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)"
                                                  [checked]="nSecFormGp(l, k)?.get('delete')?.value"/>
                                                <label class="form-check-label" [for]="'delete'+nSecFormGp(l, k)?.get('menu')?.value + (l+k+1)">Delete</label>
                                              </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                      <ng-template #child>
                                        <ng-container *ngIf="thirdlevelmenu && thirdlevelmenu.includes(nSecFormGp(l, k)?.get('menu')?.value) && (nSecFormGp(l, k)?.get('add')?.value == true ||
                                              nSecFormGp(l, k)?.get('edit')?.value == true || nSecFormGp(l, k)?.get('delete')?.value == true || nSecFormGp(l, k)?.get('view')?.value == true) && thirdLvlChild(l, k).value.length != 0">
                                          <ng-container *ngFor="let grandChild of thirdLvlChild(l,k).controls;let m = index" [formArrayName]="0">
                                            <ng-container formArrayName="lvlThreeChildren">
                                              <div class="col-12 form-row my-10" [formGroup]="nThirdFormGp(l, k, m)">
                                                <div class="form-check">
                                                  <input
                                                    id="{{nThirdFormGp(l, k, m)?.get('menu')?.value}}"
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    [checked]="
                                                      nThirdFormGp(l, k, m)?.get('view')?.value || nThirdFormGp(l, k, m)?.get('add')?.value || nThirdFormGp(l, k, m)?.get('edit')?.value || nThirdFormGp(l, k, m)?.get('delete')?.value"
                                                    [disabled]="nThirdFormGp(l, k, m)?.get('view')?.disabled || nThirdFormGp(l, k, m)?.get('add')?.disabled || nThirdFormGp(l, k, m)?.get('edit')?.disabled ||
                                                      nThirdFormGp(l, k, m)?.get('delete')?.disabled" (click)="setCheckChildrenss(nThirdFormGp(l,k,m)?.get('menu')?.value,$event,l,k,m);"/>
                                                  <label class="form-check-label" for="{{nThirdFormGp(l, k, m)?.get('menu')?.value}}">{{nThirdFormGp(l, k, m)?.get("name")?.value}}</label>
                                                  <div class="mt-16 d-flex gap-80 flex-wrap"
                                                    *ngIf="(nThirdFormGp(l, k, m)?.get('delete')?.value || nThirdFormGp(l,k,m)?.get('add')?.value || nThirdFormGp(l,k,m)?.get('edit')?.value ||
                                                        nThirdFormGp(l,k,m)?.get('delete')?.value || nThirdFormGp(l,k,m)?.get('view')?.value) == true">
                                                    <div class="form-check" *ngIf="nThirdFormGp(l,k,m)?.get('name')?.value != null">
                                                      <input
                                                        class="form-check-input"
                                                        formControlName="view"
                                                        type="checkbox"
                                                        [id]="'view'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)"
                                                        [checked]="nThirdFormGp(l,k,m)?.get('view')?.value"
                                                        [attr.disabled]="(nThirdFormGp(l,k,m)?.get('add')?.value == true || nThirdFormGp(l,k,m)?.get('edit')?.value == true ||
                                                          nThirdFormGp(l,k,m)?.get('delete')?.value == true) ? true : null"/>
                                                      <label class="form-check-label" [for]="'view'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)">View</label>
                                                    </div>
                                                    <div class="form-check"*ngIf="nThirdFormGp(l,k,m)?.get('add')?.value != null">
                                                      <input
                                                        class="form-check-input"
                                                        formControlName="add"
                                                        type="checkbox"
                                                        [id]="'add'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)"
                                                        [checked]="nThirdFormGp(l,k,m)?.get('add')?.value"/>
                                                      <label class="form-check-label" [for]="'add'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)">Add</label>
                                                    </div>
                                                    <div class="form-check" *ngIf="nThirdFormGp(l,k,m)?.get('edit')?.value != null">
                                                      <input
                                                        class="form-check-input"
                                                        formControlName="edit"
                                                        type="checkbox"
                                                        [id]="'edit'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)"
                                                        [checked]="nThirdFormGp(l,k,m)?.get('edit')?.value"/>
                                                      <label class="form-check-label" [for]="'edit'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)">Edit</label>
                                                    </div>
                                                    <div class="form-check" *ngIf="nThirdFormGp(l,k,m)?.get('delete')?.value != null">
                                                      <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        formControlName="delete"
                                                        [id]="'delete'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)"
                                                        [checked]="nThirdFormGp(l,k,m)?.get('delete')?.value"/>
                                                      <label class="form-check-label" [for]="'delete'+nThirdFormGp(l, k, m)?.get('menu')?.value + (l + k + m + 2)">Delete</label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </ng-container>
                                          </ng-container>
                                        </ng-container>
                                      </ng-template>
                                    </ng-container>
                                  </ng-container>
                                </div>
                                <div *ngIf="secondLvlChild(l).status == 'INVALID'" class="invalid-feedback">
                                  Select at least one option under the parent category.
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div
                  class="card shadow-0 px-16"
                  *ngIf="newSection().length - 1 < sectionHaveChildLength"
                >
                  <div class="card-body px-0 py-16">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      New Section
                    </div>
                  </div>
                  <div class="card-body px-0 py-24">
                    <div class="row row-24">
                      <div class="col-12 d-flex">
                        <button
                          class="btn btn-outline-primary h-40 px-64 radius-2"
                          (click)="subMenuSection()"
                        >
                          Add New Section
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <footer class="submit-footer">
    <button
      *ngIf="disabled"
      type="submit"
      (click)="validateAssignRightFlow()"
      class="btn btn-primary btn-sm ms-auto"
      [disabled]="disabled"
    >
      SAVE
    </button>
    <button
      *ngIf="disabled == false"
      type="submit"
      (click)="validateAssignRightFlow()"
      class="btn btn-primary btn-sm ms-auto"
      [disabled]="!formGp.valid || saveClicked"
    >
      SAVE
    </button>
  </footer>
</form>
