<div class="config-layout">
  <header class="config-header">
    <h5 class="m-0">{{title}} Configuration</h5>
    <button class="btn-reset text-light-600" routerLink="/expense-settings"><i class="icon-close-lg fs-16"></i></button>
  </header>

  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar">
      <ul ngbNav #nav="ngbNav" [activeId]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1" (click)="activeTab=1;">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].companymap.valid }">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Select Companies</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-expense-settings-select-companies [formGp]="companymapForm" [companydatain]="companydatain"
              [editflag]="editflag" [disabled]="disabled" [submitted]="iseditForm"
              (submitmethod)="submit(2)"></app-expense-settings-select-companies>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" (click)="tabclick(2)">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].details.valid }">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Setup Configuration</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-expense-settings-configuration [formGp]="detailsForm" [disabled]="disabled" [submitted]="iseditForm"
              [editflag]="editflag" (submitmethod)="submitForm()"></app-expense-settings-configuration>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
