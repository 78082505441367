import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl  } from '@angular/forms';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, switchMap } from 'rxjs/operators'
@Component({
  selector: 'app-leave-type',
  templateUrl: './leave-type.component.html',
  styleUrls: ['./leave-type.component.scss']
})
export class LeaveTypeComponent implements OnInit {
  viewDetail = false;
  editDetail = false;
  leavetypeForm = this.formBuilder.group({
    id: 0,
    leave_code       : ['', [Validators.required,UnamepatternValidator(), Validators.maxLength(5)]],
    leave_name       : ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    leave_unpaid_paid: [false, [Validators.required]]
  });
  viewleavetypedata:any=[];
  searchlistdata:any = [];
  LeaveTypeDetails:any = [];
  LeaveTypeList:any = [];
  leavesearch : string = this.messageService.searchdisplay('Leave Type');
  deleteClass =false;
  header = '';
  addEditBtn = '';
  is_active : boolean = true;
  deleteToggle= false;

  public leave_list_flag: boolean = true;
  submitted = false;
  status_filter :string = 'true';
  disabled = false;
  loader = true;
  loader1 = false;
  deleteClicked =false
  leaveUnit : any =[]
  editflag: boolean = false
  value:any
  private subjectKeyUp = new Subject<any>();
  id:any

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  leavetypDetailsList:any       = [];
  infinityloader          = false;
  permissions:any = [];
  searchKeyword="";
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private leavetypeService : leavetypeService,
    private formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public router: Router,
    public messageService : MessageService,
    public existDataService :  ExistDatasService,
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      if(d.fieldValue != '' && d.fieldValue != null){
        this.existService(d.fieldName,d.fieldValue);
      }
    })
    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.LeaveTypeListfn();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.leavetypeService.getleavetypeList(this.status_filter,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // add form
  addleaveForm(){
    this.editDetail = true
    this.editflag = false
    this.header = "Add"
    this.addEditBtn = "Add"
    this.deleteClass = false
    this.leavetypeForm.reset({ id: 0, leave_unpaid_paid:false});
    this.leavetypeForm.enable();
    this.submitted= false;
    this.disabled = false;
    this.child.clearBar();
  }

  // edit form
  editleaveForm(id:any){
    this.id = id
    this.editflag = true
    this.viewDetail = false;
    this.loader1 = true;
    this.editDetail = true
    this.addEditBtn = "Save"
    this.leavetypeService.getLeaveType(id).subscribe((res: any) => {
      this.deleteClass = true;
      this.loader1 = false;
      this.header = res.is_active==true?"Edit":"View";
      if(res.is_active==false){
        this.leavetypeForm.disable();
        this.disabled = true;
      }
      else{
        this.leavetypeForm.enable();
        this.disabled = false;
      }
      this.leavetypeForm.reset({ id: res.id, leave_name: res.leave_name, leave_code: res.leave_code, leave_unpaid_paid: res.leave_unpaid_paid});
      if(res.leave_name == 'Compensatory off'){
        this.leavetypeForm.get('leave_name')?.disable();
        this.leavetypeForm.setControl('leave_unpaid_paid', new FormControl(true));
        this.leavetypeForm.get('leave_unpaid_paid')?.disable()
      }
    });
  }

  /********* STATUS FILTER **************/
  selectItemList(value:any){
    this.offset                 = 0;
    this.LeaveTypeList      = [];
    this.status_filter          = value
    this.LeaveTypeListfn();
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.LeaveTypeListfn();
      this.loader       = false;
      this.direction    = "down";
    }
    else{
      this.infinityloader = false;
    }
  }

  LeaveTypeListfn(){
    this.loader = true;
    this.leavetypeService.getleavetypeList(this.status_filter,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    if (res.results.length == 0) {
      this.leave_list_flag = (this.offset == 0) ? false :true;
      this.listlength  = 0;
    } else {
      this.leave_list_flag = true;
      this.listlength = res.results.length;
      if(this.offset ==0){
        this.LeaveTypeList = [];
      }
      this.leavetypDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

   /********* INIFNITY SCROLL DATA CREATION ****************/
   addItems(itemlist:any) {
    for (let i = 0; i < itemlist; i++) {
      this.LeaveTypeList.push(this.leavetypDetailsList[i]);
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.LeaveTypeList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  leavetypFormSubmit(){
    this.submitted = true;
    // return if validation fails
    if(this.leavetypeForm.invalid){
      return
    }
    this.disabled = true;
    if(this.addEditBtn === "Add"){
      // add api
      this.leavetypeService.createleavetype(this.leavetypeForm.value).subscribe((res: any) => {
        this.editDetail = false,
        this.offset = 0;
        this.LeaveTypeList = [];
        this.LeaveTypeListfn()
      },
      (err: any) => {
        this.disabled = false;
      });
    }else{
      //  API for update leave type
      this.leavetypeService.updateLeaveType( this.leavetypeForm.value.id, this.leavetypeForm.value).subscribe((res: any) => {
        this.offset = 0;
        this.LeaveTypeList = [];
        this.editDetail = false,
        this.LeaveTypeListfn()
      },
      (err: any) => {
        this.disabled = false;
      });
    }
  }

  // active status filter
  listFilter(value:string=''){
    this.offset            = 0;
    this.LeaveTypeList = [];
    this.status_filter = value
    this.child.clearBar();
  }

  deleteleavetyp(id:any){
    this.leavetypeForm.value.id = id;
    this.deleteToggle = true;
  }

  confirmDelete(){
    this.deleteClicked = true
    this.leavetypeService.deleteLeaveType(this.leavetypeForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.deleteToggle = false,
      this.editDetail = false,
      this.deleteClicked = false
      this.offset = 0;
      this.LeaveTypeList = [];
      this.LeaveTypeListfn()
    },
    (err: any) => {
      this.deleteClicked = false
      this.deleteToggle = false
      this.editDetail = false
    });
  }

  get f() {
    return this.leavetypeForm.controls;
  }
  viewleavetyp(id:any){
    if(this.permissions.v){
      this.viewDetail = true;
      this.loader1 = true;
      this.leavetypeService.getLeaveType(id).subscribe((res: any) => {
        this.viewleavetypedata =res;
        this.loader1 = false;
      })
    }
  }
  searchleavetype(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset           = 0;
    this.LeaveTypeList= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // Simplified function to use for validation
  existService(fieldName :any, fieldValue:any){
    if(!this.editflag){
      this.value ={
        "page":"leavetype",
        "field":fieldName,
        "value": fieldValue
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"leavetype",
        "field":fieldName,
        "value": fieldValue
      }
    }
    const fc = this.leavetypeForm.get(fieldName);
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.leaveUnit = res.body
      this.loader = false
      if(this.leaveUnit.data ==='invalid'){
        fc?.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(fieldName :any, fieldValue:any){
    this.subjectKeyUp.next({fieldName,fieldValue})
  }
}










