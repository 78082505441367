import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-shift-break-schedule',
  templateUrl: './shift-break-schedule.component.html',
  styleUrls: ['./shift-break-schedule.component.scss']
})
export class ShiftBreakScheduleComponent implements OnInit {

  constructor(public messageService : MessageService,public appservice:AppService,private fb:FormBuilder,public notificationservice:NotificationService) { }
  @Input()formGp : FormGroup    = this.fb.group({
    break_hour_deduction_enable : [''],
    break_schedule              : [],
  });
  breakhrexceeded=false;
  tempdata:any=[];
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Output() breakexceeded        = new EventEmitter();
  @Input()componentError:any;
  @Input()submitted:boolean=false;
  @Input()disabled = false;
  @Input()shift: any;
  @Input()shifttimingForm:any
  samekey :any=[];
  samerror =false;

  ngOnInit(): void {
    // this.breakarrayChanges();
  }
  ngAfterViewInit(): void {
    this.breakarrayChanges();
  }

  validateForm() {
    this.submitted = true;
    this.tempdata= this.breakarray().value;
    for(let i=0;i<this.tempdata.length;i++){
      this.tempdata[i]['break_start_time']=this.appservice.timeFormatAttConvert(this.tempdata[i]['break_start_time']);
      this.tempdata[i]['break_end_time']=this.appservice.timeFormatAttConvert(this.tempdata[i]['break_end_time']);
    }
    this.totalbreak();
    if (this.formGp.invalid || this.breakhrexceeded) {
      return;
    }
    this.submitmethod.emit();
  }
  sumTime(times:any){

    let sumSeconds = 0;
    times.forEach((time:any) => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });

    return new Date(sumSeconds * 1000).toISOString().substr(11, 8);

  }
  totalbreak(){
  let totbreak:any;
  let breakhours:any=[];
   for(let i=0;i<this.tempdata.length;i++){
    let tempdata2=(this.tempdata[i]['break_start_time']);
    let tempdata3=(this.tempdata[i]['break_end_time']);
    breakhours.push(this.appservice.Timedifference(tempdata2,tempdata3));
    }
    totbreak = this.sumTime(breakhours);
    const [shh, smm] = totbreak.split(":");
    const smin       = +smm + (60 * shh);
    const [shifthh, shiftmm] = this.shift?.split(":");
    const shiftmin       = +shiftmm + (60 * shifthh);
    if((smin>120) || (smin>shiftmin)){
      this.breakhrexceeded=true;
      this.notificationservice.handleErrorNotification("Maximum allowable break duration is 2 hours, and it cannot exceed shift hours - Minimum hours required for full day.","Error");
    }else{
      this.breakhrexceeded=false;
    }
    this.breakexceeded.emit(this.breakhrexceeded);
   }

  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }
  get f() { return this.formGp.controls; }
  breakarray() : FormArray {
    return this.formGp.get("break_schedule") as FormArray;
  }
  newbreakarray(): FormGroup {
    return this.fb.group({
      "break_start_time" : [null,Validators.required],
      "break_end_time" : [null,Validators.required],
    })
  }
  addmorebreak() {
    this.breakarray().push(this.newbreakarray());
    this.samekey.length = this.breakarray().value.length;
  }
  removebreakarray(i:number) {
    this.breakarray()?.removeAt(i);
  }

  setRequired(value: boolean,item:any) {

    if(value) {
      this.formGp.get(item)?.setValidators([Validators.required]);
      for(let i=0;i<this.breakarray().value.length;i++){
        this.breakarray().controls[i].get('break_start_time')?.setValidators([Validators.required]);
        this.breakarray().controls[i].get('break_end_time')?.setValidators([Validators.required]);
      }
    } else {
      this.formGp.get(item)?.clearValidators();
      this.breakarray().clear();
      this.breakhrexceeded=false;
    }
      this.formGp.get(item)?.updateValueAndValidity();
  }
  breakarrayChanges(){
    const sStart = this.shifttimingForm.controls.shift_start_time.value
    const sEnd = this.shifttimingForm.controls.shift_end_time.value
    if(sStart !=null && sStart!= null){
      if (moment(sStart,'HH:mm:ss').isBefore(moment(sEnd,'HH:mm:ss'))) {
      for(let i=0;i<this.breakarray().value.length;i++){
        this.onFromSelect(i)
        this.onToSelect(i)
      }
      }
    }
  }

  onFromSelect(index:any){
    if(!this.breakarray().controls[index].get('break_start_time')?.errors?.required){

      if(this.testWithShiftStart(index)==true){
        this.breakarray().controls[index].get('break_start_time')?.setErrors({
          'beforeShift': true,
        })
      }else if(this.validsame(index) == true ){

        this.breakarray().controls[index].get('break_start_time')?.setErrors({
          'samefromto': true,
        })

      } else if(this.testOverlap(index)== true){

        this.breakarray().controls[index].get('break_start_time')?.setErrors({
          'overlap': true
        })

      }  else if(index>0 && this.testGreater(index)== true){

        this.breakarray().controls[index].get('break_start_time')?.setErrors({
          'validgreater': true
        })

      } else {
        this.breakarray().controls[index].get('break_start_time')?.setErrors(null)
      }

    }
  }
  onToSelect(index:any){
    if(!this.breakarray().controls[index].get('break_end_time')?.errors?.required){

      if(this.testWithShiftEnd(index)==true){
        this.breakarray().controls[index].get('break_end_time')?.setErrors({
          'afterShift': true,
        })
      } else if(this.validsame(index) == true ){
        this.breakarray().controls[index].get('break_end_time')?.setErrors({
          'samefromto': true,
        })

      } /*else if(this.testOverlap(index)== true){

        this.breakarray().controls[index].get('break_end_time')?.setErrors({
          'overlap': true
        })

      }  else if(index < this.breakarray().value.length-1 && this.testLess(index) == true){

        this.breakarray().controls[index].get('break_end_time')?.setErrors({
          'validgreater': true
        })

      }*/ else {
        this.breakarray().controls[index].get('break_end_time')?.setErrors(null)
      }

    }
  }
  testOverlap(index:any){

    let value1 =  this.breakarray().controls[index].get('break_start_time')?.value
    let value2 =  this.breakarray().controls[index].get('break_end_time')?.value
    let ret = false;
    this.breakarray().value.forEach((item:any, key:any) => {

      if(key > index && (moment(value1,'HH:mm:ss').isBetween(moment(item.break_start_time,'HH:mm:ss'),moment(item.break_end_time,'HH:mm:ss')) || moment(value2,'HH:mm:ss').isBetween(moment(item.break_start_time,'HH:mm:ss'),moment(item.break_end_time,'HH:mm:ss'))) )
        ret = true
    });
    return ret
  }
  testGreater(index:any){
    let value1 =  this.breakarray().controls[index].get('break_start_time')?.value

    let pre2 =  this.breakarray().controls[index-1].get('break_end_time')?.value
    let ret = false;
    if(moment(value1,'HH:mm:ss').isSameOrBefore(moment(pre2,'HH:mm:ss')))
      ret = true
    return ret
  }
  testLess(index:any){
    let value1 =  this.breakarray().controls[index].get('break_end_time')?.value

    let pre2 =  this.breakarray().controls[index+1].get('break_start_time')?.value
    let ret = false;
    if(moment(value1,'HH:mm:ss').isSameOrAfter(moment(pre2,'HH:mm:ss')))
      ret = true
    return ret
  }
  validsame(index:any){
    let value1 =  this.breakarray().controls[index].get('break_start_time')?.value
    let value2 =  this.breakarray().controls[index].get('break_end_time')?.value

    if (moment(value1,'HH:mm:ss').isSameOrAfter(moment(value2,'HH:mm:ss'))) {
      return true
    }else{
      return false
    }

  }
  testWithShiftStart(index:any){
    let value1 =  this.breakarray().controls[index].get('break_start_time')?.value
    const sStart = this.shifttimingForm.controls.shift_start_time.value

    if (moment(value1,'HH:mm:ss').isBefore(moment(sStart,'HH:mm:ss'))) {
      return true
    }else{
      return false
    }

  }

  testWithShiftEnd(index:any){

    let value2 =  this.breakarray().controls[index].get('break_end_time')?.value
    const sEnd = this.shifttimingForm.controls.shift_end_time.value

    if (moment(value2,'HH:mm:ss').isAfter(moment(sEnd,'HH:mm:ss'))) {
      return true
    }else{
      return false
    }
  }
}

