import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { AssetReportServiceService } from 'src/app/@core/services/asset-report-service.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-asset-request-report',
  templateUrl: './asset-request-report.component.html',
  styleUrls: ['./asset-request-report.component.scss']
})
export class AssetRequestReportComponent implements OnInit {

  @Input() employeeDropdown      : any
  @Input() submitted             : any
  @Input() companyDD             : any
  @Input() buDD                  : any
  @Input() DesignationDD         : any
  @Input() BranchDD              : any
  @Input() DepDD                 : any
  @Input() gradeDD               : any
  @Input() empstatusDD           : any
  @Input() emptypeDD             : any
  @Input() checkListElements     : any
  // Above needs to be removed
  @Input() liveLocationForm      : any
  @Input() ouFilterForm          : any
  @Input() viewDetail            : any
  @Input() resDataFilter         : any
  @Input() disabled              : any
  @Input() callReport            : any
  @Input() salFilter             : any
  @Input() closeControl          : any
  @Input() pageNumber            : any
  @Input() lastPage              : any
  @Input() reportingDD           : any
  @Input() ordering              : any
  @Input() timeSetup             : any
  @Input() Years                 : any;
  @Input()  requestStatus        : any;
  @Input()  requestType          : any;

  @Output() callReports  = new EventEmitter();
  @Output() filterPanel  = new EventEmitter();
  @Output() resetFilter  = new EventEmitter();
  @Output() submitDone   = new EventEmitter();

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  initialValue        :any      = [];
  hasChange           :boolean  = false;
  hasChangeCount      :any      = 0;
  dataFilter          :any      = []
  dataSource          :any      = [];
  reportName          :any      = '';
  fullData            :any      = []

  attendListOfMonth:string  = "listOfMonth";
  monthValue : any
  selectedYear              = new Date().getFullYear();
  year_placeholder          = 'Select year';
  month_placeholder         = 'Select month';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  requestStatusList = [{name:'Pending',value:'Pending'},{name:'Approved',value:'Accepted'},{name:'Rejected',value:'Rejected'}]
  requestTypeList = [{name:'New',value:'New'},{name:'Return',value:'Return'}];

  get f()  { return this.liveLocationForm.controls; }

  constructor(
    public messageService : MessageService,
    private reportService : ReportsService,
    public appservice : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public assetReportService: AssetReportServiceService,
    public cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getDateFormat()
    this.selectAllForDropdownItems(this.requestStatusList)
    this.selectAllForDropdownItems(this.requestTypeList)
  }

  // test start

  applyBtn : boolean = false

  setFormControlsToEmptyString(formGroup:FormGroup) {
    for (const controlName of Object.keys(formGroup.controls)) {
      const control = formGroup.get(controlName);

      if (control?.value === null) {
        control?.setValue('');
      }
    }
  }


  filterFormData(use:any){
    if(this.liveLocationForm.controls.request_month.value == true){
      this.liveLocationForm.get('request_month')?.setValue(this.liveLocationForm.controls.specific_year.value+'-'+this.monthValue)
    }
    else{
      this.liveLocationForm.get('request_month')?.setValue(this.liveLocationForm.controls.request_month.value)
    }

    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);

    let request_month    = this.liveLocationForm.controls.request_month.value==null?'':this.liveLocationForm.controls.request_month.value;

    let request_status    = this.liveLocationForm.controls.request_status.value==''?JSON.stringify([]):JSON.stringify(this.liveLocationForm.controls.request_status.value);

    let request_type    = this.liveLocationForm.controls.request_type.value==''?JSON.stringify([]):JSON.stringify(this.liveLocationForm.controls.request_type.value);
    if(use == 'filter'){
      return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&branch=${branch}&grade=${grade}&employee_list=${employee_list}&emp_status=${employment_status}&emp_type=${employee_type}&request_month=${request_month}&request_status=${request_status}&request_type=${request_type}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'request_month' : request_month,'company' : this.ouFilterForm.controls.company.value, 'bu' : this.ouFilterForm.controls.bu.value, 'department' : this.ouFilterForm.controls.department.value,'grade' : this.ouFilterForm.controls.grade.value, 'emp_status' : this.ouFilterForm.controls.employment_status.value == null ? '' : this.ouFilterForm.controls.employment_status.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type.value, 'branch' : this.ouFilterForm.controls.branch.value, 'employee_list' : this.ouFilterForm.controls.employee_list.value,'designation': this.ouFilterForm.controls.designation.value,'request_status' : this.liveLocationForm.controls.request_status.value, 'request_type' : this.liveLocationForm.controls.request_type.value});
    }
  }

  stringifyCont(control: any): string {
    return control === '' || control == null ? JSON.stringify([]) : JSON.stringify(control);
  }

  submitassetRequest(){
    this.submitted          = true;
    if (this.liveLocationForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let formData = this.filterFormData('filter')
    this.submitDone.emit({
        limit : limit,
        offset : offset,
        filterForm : formData
    })
    this.viewDetail = false;
    this.applyBtn = false
  }

  getData() {
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'request_month' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  setDataSourceAttributes() {
    if(this.dataSource != undefined && this.dataSource != null){
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort      = this.sort;
    }
  }

  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

   radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthtype:any,yeartype:any){
      this.cd.detectChanges();
      if(e.target.value == 'on' ){
      this.liveLocationForm?.get(contrlName)?.setValue(true);
      }
      else{
      this.liveLocationForm?.get(contrlName)?.setValue(val);
      this.liveLocationForm?.get(monthtype)?.setValue(null);
      this.liveLocationForm?.get(yeartype)?.setValue(null);
      }
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  // Resetting the filters
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.resetForm()
    }
    this.callReports.emit({
      reportName : 'Asset Request'
    })
  }

  resetForm(){
    this.ouFilterForm.get('company')?.reset('');
    this.ouFilterForm.get('department')?.reset('');
    this.ouFilterForm.get('bu')?.reset('');
    this.ouFilterForm.get('designation')?.reset('');
    this.ouFilterForm.get('grade')?.reset('');
    this.ouFilterForm.get('branch')?.reset('');
    this.ouFilterForm.get('employee_list')?.reset('');
    this.ouFilterForm.get('employment_status')?.reset('');
    this.ouFilterForm.get('employee_type')?.reset('');
    this.liveLocationForm.get('request_month')?.reset('this_month')
    this.liveLocationForm.get('request_status')?.reset('')
    this.liveLocationForm.get('request_type')?.reset('')
  }

  // Tag Close
  applyTagClose1(){
    // console.log('tag',this.closeControl)
    this.applyBtn = true
    if(this.closeControl == 'company'){
      this.ouFilterForm.get('company')?.setValue('')
      this.ouFilterForm.get('bu')?.setValue('')
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('');
    }
    this.submitassetRequest();
  }

  disable(){
    return false
  }

  resetPage(val:any){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  // Date Format
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  setRequired(val:any){
    var arr = ['specific_month','specific_year']
    if(val){
      arr.forEach((item:any)=>{
          this.liveLocationForm.get(item)?.setValidators([Validators.required]);
          this.liveLocationForm.get(item)?.updateValueAndValidity();
        })
      }else{
        arr.forEach((item:any)=>{
          this.liveLocationForm.get(item)?.clearValidators()
          this.liveLocationForm.get(item)?.updateValueAndValidity();
        })
      }

  }

  //function for get month no from month name
  monthChangeFn(event:any){
    var date        = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    this.monthValue = String(date.getMonth() + 1).padStart(2, '0');
  }

  clearcommonForm(item:any,index:any){
    if(item == "requestStat"){
      let daata = this.liveLocationForm.value.request_status;
      daata.splice(index, 1);
      this.liveLocationForm.patchValue({
        request_status: daata
      });
    }else if(item == "requestType"){
      let daata = this.liveLocationForm.value.request_type;
      daata.splice(index, 1);
      this.liveLocationForm.patchValue({
        request_type: daata
      });
    }
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

}
