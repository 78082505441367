import { Component, OnInit ,ViewChild} from '@angular/core';
import { EmployeeLetterProfileService } from 'src/app/@core/services/my-letters.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-my-letter',
  templateUrl: './my-letter.component.html',
  styleUrls: ['./my-letter.component.scss']
})
export class MyLetterComponent implements OnInit {
  searchplaceholder:string       = this.messageService.searchdisplay('Letter');
  @ViewChild(SearchbarComponent) child:any;
  permissions:any=[];
  offset                   = 0;
  listlength               = 0;
  direction                = '';
  defaultlimit:any         = 20;
  loader =false;
  searchlistdata:any=[];
  infinityloader           = false;
  nodata                   = false;
  empDetailsList   :any   = [];
  searchKeyword='';
  empletterlist :any   = [];
  filterdata:any;
  yearRange: any;

  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  year: any = new Date().getFullYear()
  sLoading = false;

  constructor(
    private EmployeeLetterProfileService : EmployeeLetterProfileService,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService : MessageService,
  ) { }

  ngOnInit(): void {
    this.yearRange = this.generateArrayOfYears()
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.getPermission();
    this.showDataItems();
  }

  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 3
    var years = []

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/my-letters');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  showDataItems(){
    this.loader = true;
    this.empletterlist=[];
    this.EmployeeLetterProfileService.empletterList('Published',this.defaultlimit,this.offset,this.searchKeyword,this.year).subscribe((res: any)=> {
      this.listlength = res?.body?.results?.length;
      if(this.offset == 0){
        this.empletterlist = [];
      }

      if (res?.body?.results?.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.empDetailsList = res?.body?.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
      this.sLoading = false
    })

    // this.EmployeeLetterProfileService.empletterListData('Published',this.searchKeyword,this.year).subscribe((res: any) => {
    //   this.searchlistdata =  res?.body;
    //   this.loader = false;
    // });
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.empletterlist.push(this.empDetailsList[i]);
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset     = 0;
      this.empletterlist  = [];
      this.showDataItems();
    }else{
     let searchres = this.empletterlist.filter((obj:any) =>obj.id === data);
        this.empletterlist=[];
        this.empletterlist = searchres;
        if(this.empletterlist.length!=0){
         this.searchKeyword  = this.empletterlist[0].letter?.letter_template?.name;
        }
        this.loader    = false;
    }
  }

  searchemp(searchKeyword:any){
    this.loader         = true;
    this.sLoading       = true;
    this.offset         = 0;
    this.empletterlist = [];
    this.searchKeyword  = searchKeyword;
    if(searchKeyword != ""){
      this.showDataItems();
    }
  }


  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  viewDocument(filename:any,exactName:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    // var currentDocName : any    = this.fileName.split('/').pop();
    this.currentDocName         = exactName;
    // this.currentDocName         = decodeURIComponent(this.currentDocName);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  close(bool:any){
    this.modalToggle = bool;
  }

  addEvent(val: any) {
    if(val !=undefined){
       this.year = val;
    }else{
       this.year = '';
    }
    this.showDataItems();
  }

    // Remove Underscore
    removeUnderScore(val:any){
      if(val?.includes('_')){
        return val?.split('_').join(' ')
      }else{
        return val
      }
    }

  downLoad(file:any,name:any){
    let url = this.removeUnderScore(name?.split('.')[0])
    this.appService.downloadFile(file,url)
  }

}
