import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExpenseSettingService } from 'src/app/@core/services/expense-settings.service'; 

@Component({
  selector: 'app-expense-settings-select-companies',
  templateUrl: './expense-settings-select-companies.component.html',
  styleUrls: ['./expense-settings-select-companies.component.scss']
})
export class ExpenseSettingsSelectCompaniesComponent implements OnInit {
  searchlistdata:any        =[];
  companysearch:string      = this.messageService.searchdisplay('company');
  searchKeyword             = ""
  loader                    = false;
  companydata:any           =[];
  listlength:any;
  
  @Input()formGp        : any;
  @Input()companydatain : any;
  @Input()submitted     : boolean=false;
  @Output() submitmethod= new EventEmitter();
  @Input()disabled      = false;
  @Input() editflag     : any;

  constructor(
    private ExpenseSettingService: ExpenseSettingService,
    public messageService : MessageService,
    public appService:AppService,
    public fb:FormBuilder,

  ) { }

  ngOnInit(): void {
    const formArray: FormArray = this.formGp.get('company') as FormArray;
    if(this.editflag==false){
      this.companyList();
    }else{
      this.companydata =  [this.companydatain];
    }
  }

  validatepolicysetting() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  onCheckChange(event:any,name:any) {
    const formArray: FormArray = this.formGp.get('company') as FormArray;
    if(event.target.checked){
      formArray.push(new FormControl(event.target.value));
    }
    else{
      let i: number = 0;
      formArray.controls.forEach((ctrl: any) => {
        if(ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  companyList(){
    this.loader = true;
    this.ExpenseSettingService.companylist(this.searchKeyword)
    .subscribe((res: any) => {
    this.companydata = [];
    this.listlength =res.length;
      if(res.length != 0) {
        for (let i = 0; i < res.length; i++) {
          this.companydata.push(res[i]);
          res[i]['name'] = res[i]['company_name']
          res[i]['image'] = res[i]['logo']
        }
        this.searchlistdata = res;
      }
      this.loader = false;
    })    
  }

  searchcompany(searchKeyword:any){
    this.loader = true;
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
       this.companydata = [];
      this.companyList();
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.companyList();
    }else{
      this.companydata = this.companydata.filter(function(element:any) {
        return element.id == data;
      });
      this.loader           = false;
    }            
  }

}
