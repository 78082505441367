
import {Component, OnInit, Output,Input, EventEmitter} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { FileUploadService } from 'src/app/@core/services/file-upload.service';


@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss']
})
export class CameraModalComponent implements OnInit {
  @Input() captureModal:any
  @Output() public pictureTaken = new EventEmitter<WebcamImage>();
  @Output() close = new EventEmitter()

  constructor(
    private upload: FileUploadService,
  ) { }

  // toggle webcam on/off
  // public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string = '';
  captureInfoModal = false
  public videoOptions: MediaTrackConstraints = {
    width: {ideal: 853},
    height: {ideal: 423}
  };
  public errors: WebcamInitError[] = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  // public toggleWebcam(): void {
  //   this.showWebcam = !this.showWebcam;
  // }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  DataUrl = ""
  openWbcam = true
  openCropper = false
  
  public handleImage(webcamImage: WebcamImage): void {
      this.DataUrl = webcamImage.imageAsDataUrl
      this.openWbcam = false
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  saveImage(img:any){
    this.upload.fileUpload({name:"webcamimage.jpeg", dataURL:img}).subscribe((res: any) => {
      if(res.status == 200) {
        this.pictureTaken.emit(res.body.data_url)
      }
    })
  }
}
