<div class="{{nodata ? '' :'config-layout'}}" >
    <header class=" config-header">
        <h5 class="m-0">Employee Details</h5>
        <button class="btn-reset text-light-600" routerLink="/my-team-dashboard">
            <i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-nodata-view *ngIf=" nodata && loopcompleted " class="vstack" [noDataImg]="'bg6.png'" [noDataText]="'No data found'" [hasPermission]="false"> </app-nodata-view>
    <main class=" config-container"  *ngIf="!nodata">
        <nav ngbNav #nav="ngbNav" [(activeId)]="navTab" class="nav nav-custom sticky top-48 ">
            <li [ngbNavItem]="1">
                <a ngbNavLink class="nav-link">Profile</a>
                <ng-template ngbNavContent>

                  <!-- PROFILE -->
                  <app-profile [from]="'myteam'"></app-profile>

                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink class="nav-link">Attendance</a>
                <ng-template ngbNavContent>
                    <div class="row row-24">
                        <div class="col-12">

                            <!-- ATTENDANCE -->
                            <app-employee-attendance-view [from]="'myteam'"></app-employee-attendance-view>

                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3">
                <a ngbNavLink class="nav-link">Leave balance</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid p-32 form-container">

                      <!-- LEAVE BALANACE -->
                      <app-leave-balance-view [from]="'myteam'"></app-leave-balance-view>

                    </div>
                </ng-template>
            </li>
        </nav>
        <div [ngbNavOutlet]="nav">
        </div>
    </main>
</div>
