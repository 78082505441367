import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { DesignationService } from 'src/app/@core/services/designation.service';
import { Location } from '@angular/common';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';



@Component({
    selector: 'app-salary-revision-setup',
    templateUrl: './salary-revision-setup.component.html',
    styleUrls: ['./salary-revision-setup.component.scss']
})
export class SalaryRevisionSetupComponent implements OnInit {
    activeTab             = 1;
    payGroupDD:any        = [];
    employeeDetails:any   = []
    empId                 = 0;
    componentLoader       = true
    error                 = '';
    salaryId              = 0;
    previousData:any      = [];
    loader                = false;
    defaultMonthData:any  = [];
    calculationData:any;
    gross_benefits:any;
    other_benefits:any;
    contributions:any;
    recurring_deductions:any;
    stepperData:any;
    variableComponent:any;

    constructor(public fb:FormBuilder,private salaryStructureService:SalaryStructureService,public route:ActivatedRoute,private router:Router,private designationService:DesignationService,private _location: Location,
    private previousRouteService: PreviousRouteService,private cdref: ChangeDetectorRef
    ) { }
    salaryForm: FormGroup = this.fb.group({
        id: 0,
        structure: this.fb.group({
            employee: [null],
            designation: [null, [Validators.required]],
            pay_group : [null, [Validators.required]],
            rev_type: [false],
            revise_ctc : [this.employeeDetails?.ctc, [digitdecimalValidator(),Validators.maxLength(10)]],
            revise_per : [''],
            effective_from  : [null, [Validators.required]],
            salary_pay_month : [null, [Validators.required]],
            gross_month     : this.fb.array([]),
            other_month     : this.fb.array([]),
            recurring_month : this.fb.array([]),
            gross_amount : [null]
        }),
        details : this.fb.group({

        })
    })
    ngAfterContentChecked() {
      this.cdref.detectChanges();
   }
    ngOnInit(): void {
        this.payGroupList();
        this.route.params.subscribe((params: Params) => {
           this.empId = params.id
           this.EmployeeDetails();
        })
    }
    get detailsForm() {
        return this.salaryForm.get('details') as FormGroup;
    }
    get structureForm() {
        return this.salaryForm.get('structure') as FormGroup;
    }
    payGroupList(){
        this.salaryStructureService.getPayGroupList().subscribe((res:any)=>{
            this.payGroupDD = res.body;
        });
    }
    EmployeeDetails(){
        this.loader = true;
        this.salaryStructureService.getEmployeeDetails(this.empId).subscribe((res:any)=>{
            // this.structureForm.get('revise_ctc')?.setValue(res.body?.ctc);
            this.structureForm.get('pay_group')?.setValue(res.body?.pay_group);
            this.structureForm.get('designation')?.setValue(res.body?.employee_data?.designation);
            this.structureForm.get('employee')?.setValue(this.empId);
            this.salaryId= res.body?.id
            this.employeeDetails = res.body;
            this.loader = false;



        });
    }
    makeDefaultSettings(){
        // this.previousData = this.salaryForm['controls'].structure.value;
        // console.log("Previous data",this.previousData)
        // if(this.previousData.length==0 || (this.previousData.revise_ctc!=this.salaryForm['controls'].structure.value.revise_ctc) || (this.previousData.pay_group!=this.salaryForm['controls'].structure.value.pay_group)){
          this.error = '';
          this.componentLoader      = true;
          let newCTC:any;
          if(this.salaryForm['controls'].structure.value.rev_type==true)
          newCTC = parseFloat(this.employeeDetails.ctc)+((parseFloat(this.salaryForm['controls'].structure.value.revise_per)*parseFloat(this.employeeDetails.ctc))/100);
          else
          newCTC  = this.salaryForm['controls'].structure.value.revise_ctc;
          let data = {"CTC": parseInt(newCTC),"pay_group": this.salaryForm['controls'].structure.value.pay_group,'is_revision':true};
          this.stepperData = this.salaryForm['controls'].structure.value;
          let paygrp            = '';
          let designationName   = '';

          for(let i=0;i<this.payGroupDD.length;i++){
            if(this.payGroupDD[i]?.id ==this.salaryForm['controls'].structure.value.pay_group){
              paygrp = this.payGroupDD[i]?.name;
            }

          }
          this.designationService.designationListData(true).subscribe((res: any) => {
            for(let i=0;i<res.length;i++){
              if(res[i].id ==this.salaryForm['controls'].structure.value.designation){
                designationName = this.payGroupDD[i]?.name;
              }

            }
          });
          this.stepperData['paygrp']          = paygrp;
          this.stepperData['designationName'] = designationName;
          this.stepperData['newCTC']          = newCTC;

          this.salaryStructureService.getFormula(data,this.empId).subscribe((res:any)=>{
              this.constructData(res);
              this.componentLoader      = false;
              this.activeTab = 2;
          },
          (err: any) => {

              this.error =  err.error.error
              this.salaryForm['controls'].structure.get('pay_group')?.setErrors({
                  'conflict': this.error,
                  })
              this.activeTab = 1;
          });

        // }
        // else{
        //   this.activeTab = 2;

        // }

    }
    onNavChange(changeEvent: NgbNavChangeEvent){
        if (changeEvent.nextId === 2) {
            changeEvent.preventDefault();
            if (this.salaryForm['controls'].structure.valid) {
                this.makeDefaultSettings()
            }
        } else if(changeEvent.nextId === 1) {
            this.removeValidators()
        }

    }
    submitMethod(activeTab: any){
        if (activeTab === 2) {
            if (this.salaryForm['controls'].structure.valid) {
                this.makeDefaultSettings()
            }
        }

    }
    removeValidators(){
        this.structureForm.controls['gross_month'] = this.fb.array([]);
        this.structureForm.controls['other_month'] = this.fb.array([]);
        this.structureForm.controls['recurring_month'] = this.fb.array([]);
    }
    submitForm(){
        this.saveSalaryStructure();
    }
    saveSalaryStructure(){

        let salaryStructure = this.salaryForm.getRawValue().structure;
        Object.entries(salaryStructure).forEach(([key, value]) => {
          if(key=='pay_group'){
            for(let i=0;i<this.payGroupDD.length;i++){
              if(this.payGroupDD[i]['name']!= undefined && this.payGroupDD[i]['name'] == value){
                salaryStructure[key]   = '';
                salaryStructure[key]   = (this.payGroupDD[i]['id']);
              }
            }
          }
        })

        let other_month_array     = this.salaryForm['controls'].structure.value.other_month ;
        let gross_month_array     = this.salaryForm['controls'].structure.value.gross_month ;
        let recurring_month_array = this.salaryForm['controls'].structure.value.recurring_month ;

        for(let i=0;i< gross_month_array.length;i++){
          for(let j=0; j< this.calculationData.length;j++){
            if(gross_month_array[i]['comp_id']==this.calculationData[j]['pay_component'] ){
              if(gross_month_array[i]['gross_month']!=null)
              this.calculationData[j]['pay_month']      = gross_month_array[i]['gross_month'];
              if(this.calculationData[j]['component_type_name']!="other allowance")
               this.calculationData[j]['monthly_renum']  = gross_month_array[i]['monthly_renum'];

            }
          }
        }
        for(let i=0;i< other_month_array.length;i++){
          for(let j=0; j< this.calculationData.length;j++){
            if(other_month_array[i]['comp_id']==this.calculationData[j]['pay_component'] ){
              if(other_month_array[i]['other_month']!=null)
              this.calculationData[j]['pay_month']      = other_month_array[i]['other_month'];
              this.calculationData[j]['monthly_renum']  = other_month_array[i]['monthly_renum'];

            }
          }
        }

        for(let i=0;i< recurring_month_array.length;i++){
          for(let j=0; j< this.calculationData.length;j++){
            if((recurring_month_array[i]['comp_id']==this.calculationData[j]['pay_component']) && ( recurring_month_array[i]['name']==this.calculationData[j]['name']) && this.calculationData[j]['component_type'] == "recurring_deductions"){
              if(recurring_month_array[i]['recurring_month']!=null)
              this.calculationData[j]['pay_month'] = recurring_month_array[i]['recurring_month'];
              this.calculationData[j]['monthly_renum']  = recurring_month_array[i]['monthly_renum'];
            }
          }
        }

        let newCTC:any;

        if(this.salaryForm['controls'].structure.value.rev_type==true)
        newCTC = parseFloat(this.employeeDetails.ctc)+((parseFloat(this.salaryForm['controls'].structure.value.revise_per)*parseFloat(this.employeeDetails.ctc))/100);
        else
        newCTC  = this.salaryForm['controls'].structure.value.revise_ctc;

        let assign_salary = {'assign_salary':this.calculationData,'employee':this.empId,"is_revision": true,"ctc":  newCTC,"is_active": true,"is_assigned":true};
        let mergedData = { ...this.salaryForm.getRawValue().statutory, ...salaryStructure, ...assign_salary};


        this.salaryStructureService.updateSalary(this.salaryId,mergedData).subscribe((res:any)=>{
            this.salaryStructureService.salaryRevisionCreate  ({
                "salary_revision": {
                    "employee"        : this.empId,
                    "designation"     : this.salaryForm['controls'].structure.value.designation,
                    "pay_group"       : this.salaryForm['controls'].structure.value.pay_group,
                    "revise_type"     : this.salaryForm['controls'].structure.value.revise_type,
                    "revise_ctc"      : newCTC,
                    "effective_from"  : this.salaryForm['controls'].structure.value.effective_from,
                    "salary_pay_month": this.salaryForm['controls'].structure.value.salary_pay_month,
                    "is_active"       : true
                },
                "revise_structure"  : res.body.id,
                "current_structure" : this.salaryId
            } )       .subscribe((res2:any)=>{

            this.router.navigate(['salary-revision']);
        })
        })
    }
    backClicked() {
      if(this.previousRouteService.getPreviousUrl() == '/home' || this.previousRouteService.getPreviousUrl() == '/login')
      this.router.navigate(['salary-revision']);
      else
      this._location.back();
    }
    defualtData(data:any){
      this.defaultMonthData = data;
    }
    recalculateComponent(res:any){
      this.constructData(res);
    }
    constructData(res:any){
      this.calculationData = res.body.assign_salary;
      this.stepperData['gross_amount']    = res.body.gross_amount;
      this.stepperData['net_gross']       = res.body.net_gross;

      var gross =  this.calculationData.filter(function(gross_sal:any) {

        return gross_sal.component_type == "gross_benefits" && gross_sal?.is_display==true;
      });
      var other =  this.calculationData.filter(function(other_sal:any) {

        return other_sal.component_type == "other_benefits" && other_sal?.is_display==true;
      });
      var contibution =  this.calculationData.filter(function(contribution_sal:any) {

        return contribution_sal.component_type == "contributions" && contribution_sal?.is_display==true;
      });
      var recurring =  this.calculationData.filter(function(recurring_sal:any) {

        return recurring_sal.component_type == "recurring_deductions" && recurring_sal?.is_display==true;
      });
      // Variable component display
      var variable = this.calculationData.filter(function(variable:any) {
        return variable?.is_display==false;
      });
      this.variableComponent = ''
      for(let i=0;i<variable.length;i++){
        if(i==0)
        this.variableComponent = variable[i]['name']
        else if(i!=variable.length-1)
        this.variableComponent +=', ' +variable[i]['name']
        else
        this.variableComponent +=' and ' +variable[i]['name']
      }
      this.gross_benefits       = gross;
      this.other_benefits       = other;
      this.contributions        = contibution;
      this.recurring_deductions = recurring;
      this.salaryForm['controls'].structure.get('gross_amount')?.setValue(res.body.gross_amount);
    }
}
