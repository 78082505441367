import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SalaryDashboardService {
  PROCESSCOUNT:any = 0;
  PROCESSURL:any='/run-payroll';
  PROCESSTEXT:any='';
  BGPROGRESS  =   true

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };
  setProgress(val:boolean=false){
    this.BGPROGRESS = val
  }

  setProcessData(count:any,url:any,text:any =""){
    // console.log(2)
    this.PROCESSCOUNT = count;
    this.PROCESSURL = url;
    this.PROCESSTEXT = text
  }
  getProcessCount(){
    return this.PROCESSCOUNT;
  }
  getProcessUrl(){
    return this.PROCESSURL;
  }

  getDateRangeSlider(companyId:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/date-range-slider/?company=${companyId}`,this.httpOption)
  }
  getFreezePublishList(year:any,month:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/freeze-publish/?year=${year}&month=${month}`,this.httpOption)
  }
  publishSalary(body: any){
    return this.http.post(`${this.API_PATH}hr/api/v1/publish-pay-slip-save/`, body, this.httpOption)
  }
  getProcessList(year:any,month:any,status:any,company:any,businessunit:any,department:any,designation:any,branch:any,grade:any,start_date:any,end_date:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/process-unprocess-get/?year=${year}&month=${month}&status=${status}&company=${company}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]&start_date=${start_date}&end_date=${end_date}`,this.httpOption)
  }
  saveProcessList(body: any){
    return this.http.post(`${this.API_PATH}hr/api/v1/process-unprocess-save/`, body, this.httpOption)
  }
  saveAttendance(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/monthly-paid-days-upload/`, body, this.httpOption)
  }
  validateAttendance(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/monthly-paid-days-validate/`, body, this.httpOption)
  }
  getReminders(year:any,month:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/reminder-list/?year=${year}&month=${month}&company=${company}`, this.httpOption)
  }
  getalerts(year:any,month:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/alert-list/?year=${year}&month=${month}&company=${company}`, this.httpOption)
  }

  getSalarySummary(year:any,month:any,company:any,buList:any,DepList:any,DesignationList:any,gradeList:any,BranchList:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/salary-summary/?year=${year}&month=${month}&company=${company}&businessunit=[${buList}]&department=[${DepList}]&designation=[${DesignationList}]&grade=[${gradeList}]&branch=[${BranchList}]`,this.httpOption)
  }
  getEmployeeSalarySummary(year:any,month:any,company:any,buList:any,DepList:any,DesignationList:any,gradeList:any,BranchList:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/employee-salary-summary/?year=${year}&month=${month}&company=${company}&businessunit=[${buList}]&department=[${DepList}]&designation=[${DesignationList}]&grade=[${gradeList}]&branch=[${BranchList}]`,this.httpOption)
  }
  getViewBreakupDetails(id:any,year:any,month:any,status:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/process-unprocess-salary-breakdown/?employee=${id}&month=${month}&year=${year}&status=${status}`,this.httpOption)
  }
  getPayrollList(year:any,month:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/payroll-list/?year=${year}&month=${month}`,this.httpOption)
  }
  getPayrollMonthData(year:any,month:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/payroll-month-data/?year=${year}&month=${month}&company=${company}`,this.httpOption)
  }
  getPayrollGraph(year:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/payroll-summary/?graph_year=${year}&company=${company}`,this.httpOption)
  }
  companyDropDownData(){
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption)
  }
  savelopreverse(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/lop-reverse-upload/`, body, this.httpOption)
  }
  getactuallopdata(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/lop-reverse-validate/`, body, this.httpOption)
  }
  verifyLOPData(data: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/lop-validate/`, data, this.httpOption)
  }
  employeeList(companyId:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name&company__in=${companyId}`, this.httpOption);
  }
  getempList(companyId: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name&company__in=${companyId}&employment_status=["Probation","Confirmed","Resigned","Relieved","Terminated","Retired"]`, this.httpOption);
  }
  submitLOPData(data: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/lop-upload/`, data, this.httpOption)
  }

  getHoldList(company:any,businessunit:any='',department:any='',designation:any='',branch:any='',grade:any=''){
    return this.http.get(`${this.API_PATH}hr/api/v1/hold-list-get/?company=${company}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`,this.httpOption)
  }
  saveHoldList(body: any){
    return this.http.post(`${this.API_PATH}hr/api/v1/hold-release-salary/`, body, this.httpOption)
  }

  getPayrollRun(year:any,month:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/payroll-run-get/?year=${year}&month=${month}&company=${company}`,this.httpOption)
  }
  tabdetails(company:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/company-payroll-config-detail/?company_id=${company}`,this.httpOption)
  }
  attendanceHistory(year:any,month:any,company:any,section:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-data/?company_id=${company}&payroll_month=${month}&payroll_year=${year}&section=${section}`,this.httpOption)
  }
  deleteHistory(data: any){
    return this.http.patch(`${this.API_PATH}attendance/api/v1/attendance-data-delete/`, data, this.httpOption)
  }
  getArrearData(year:any,month:any,status:any,company:any,businessunit:any,department:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/salary-arrear-landing/?pay_year=${year}&pay_month=${month}&status=${status}&company=${company}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`,this.httpOption)
  }
  saveArrearData(data: any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/salary-arrears/`, data, this.httpOption)
  }
  getVariablepayment(year:any,month:any,status:any,company:any,type:any,businessunit:any,department:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/variable-payment-list/?pay_year=${year}&pay_month=${month}&status=${status}&company=${company}&pay_type=${type}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`,this.httpOption)
  }
  getVariableData(employeeId:any,type:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/employee-components/?employee=${employeeId}&pay_type=${type}`, this.httpOption);
  }
  saveMonthlyData(data: any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/variable-payments-create/`, data, this.httpOption)
  }
  verifyMonthlyData(data: any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/variable-payments-import-validate/`, data, this.httpOption)
  }
  getCompanyHold(company:any,year:any,month:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/hold-list-get/?company=${company}&month=${month}&year=${year}`,this.httpOption)
  }
  getPriorMonthlyData(company:any,year:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/priorpayroll-monthdata/?company=${company}&year=${year}`,this.httpOption)

  }
  uploadPriorPayroll(data: any){
    return this.http.post(`${this.API_PATH}hr/api/v1/priorpayroll-upload/`, data, this.httpOption)
  }
  validatePriorPayroll(data: any){
    return this.http.post(`${this.API_PATH}hr/api/v1/priorpayroll-validate/`, data, this.httpOption)
  }
  getPriorSummary(company:any,year:any,month:any,businessunit:any,department:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/priorpayroll-view/?company=${company}&year=${year}&month=${month}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`,this.httpOption)

  }
  disablePriorPayroll(company:any,year:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/priorpayroll-disable/?company=${company}&year=${year}`, this.httpOption)
  }
  deleteUploadedPayroll(company:any,year:any,month:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/priorpayroll-delete/?company=${company}&year=${year}&month=${month}`, this.httpOption)
  }
  savePriorpayroll(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/priorpayroll-finalsave/`, data, this.httpOption)

  }

  getStatutoryCheckData(year:any,month:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/statutory-check/?year=${year}&month=${month}&company=${company}`,this.httpOption)
  }
  getPayrollststus(employee:any,status:any,pay_month:any,pay_year:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/pay-role-status/?employee=${employee}&status=${status}&pay_month=${pay_month}&pay_year=${pay_year}`,this.httpOption)
  }
  deleteMonthlyPrior(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/priorpayroll-multidelete/`, data, this.httpOption)

  }
  exportMonthlyPrior(pay_month:any,pay_year:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/priorpayroll-export-excel/?pay_month=${pay_month}&pay_year=${pay_year}&company=${company}`,this.httpOption)

  }
  getFYReminders(year:any,month:any,company:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/pending-request-list/?pay_year=${year}&pay_month=${month}&company=${company}`, this.httpOption)
  }

  sendFYReminder(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/send-reminder/`, data, this.httpOption)
  }

  attendanceProcessList(company:any,year:any,month:any,status:any,businessunit:any,department:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/attendance-employee-get/?company=${company}&month=${month}&year=${year}&status=${status}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`,this.httpOption)
  }
  saveAttendanceProcess(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-process-unprocess/`, body, this.httpOption)
  }

  updateAttendenceprocess(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-process-overide/`, body, this.httpOption)
  }

  uploadAttendenceprocess(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-process-excel-upload/`, body, this.httpOption)
  }
  arrearProcessList(company:any,year:any,month:any,status:any,businessunit:any,department:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/arrear-employee-get/?company=${company}&month=${month}&year=${year}&status=${status}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`,this.httpOption)
  }

  validateAttendanceProcess(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-process-excel-validate/`, body, this.httpOption)
  }
  processUnprocessArrear(data:any ){
    return this.http.post(`${this.API_PATH}attendance/api/v1/arrear-process-unprocess/`, data, this.httpOption)

  }
  importArrear(data:any ){
    return this.http.post(`${this.API_PATH}attendance/api/v1/arrear-process-excel-upload/`, data, this.httpOption)

  }
  saveArrearDay(data:any ){
    return this.http.post(`${this.API_PATH}attendance/api/v1/arrear-process-overide/`, data, this.httpOption)
  }
  getOvertimeData(year:any,month:any,status:any,company:any,businessunit:any,department:any,designation:any,branch:any,grade:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/overtime-employee-get/?year=${year}&month=${month}&status=${status}&company=${company}&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]`,this.httpOption)
  }
  overrideOvertime(data: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/overtime-process-overide/`, data, this.httpOption)
  }
  processUnprocessOvertime(data: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/overtime-process-unprocess/`, data, this.httpOption)
  }
  uploadOvertime(data: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/overtime-process-excel-upload/`, data, this.httpOption)
  }
  uploadPriorArrear(data: any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/salary-arrear-excel-upload/`, data, this.httpOption)
  }
  getReimbursementList(status:any,company:any,businessunit:any,department:any,designation:any,branch:any,grade:any,pay_month:any,pay_year:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-hr-employee-reimbursement-list/?status=${status}&company=[${company}]&businessunit=[${businessunit}]&department=[${department}]&designation=[${designation}]&branch=[${branch}]&grade=[${grade}]&pay_month=${pay_month}&pay_year=${pay_year}`,this.httpOption)
  }
  saveReimbursement(body: any){

    return this.http.post(`${this.API_PATH}expense/api/v1/expense-hr-employee-reimbursement-status-update/`, body, this.httpOption)
  }
  getEmployeeReimbursement(id:any,status:any,month:any,year:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-hr-employee-reimbursement-reports/?employee=${id}&status=${status}&pay_month=${month}&pay_year=${year}&is_automatic_or_manual=true`, this.httpOption)
  }
  companyGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/company-data/${id}/`, this.httpOption)
  }
}



