<form class="" [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row row-16 ">
      <div class="col-12">
        <p class="text-uppercase fw-500 mb-0">LETTER TEMPLATE DETAILS </p>
      </div>
      <div class="col-md-6">
        <label for="name" class="form-label required">Template name </label>
        <input formControlName="name"
        [ngClass]="{ 'is-invalid': (submitted || f.name.touched|| f.name.dirty) &&  f.name.errors || f.name.errors?.exist}"
        type="text" class="form-control "
        placeholder="{{this.msgServ.placeholderdisp('Template Name')}}" id="name" (keyup)="keyUp()">
        <div *ngIf="(submitted || f.name.touched || f.name.dirty) && f.name.errors || f.name.errors?.exist"
        class="invalid-feedback">
        <div *ngIf="f.name.errors?.required">
            {{this.msgServ.validationDisplay('required')}}</div>
        <div *ngIf="f.name.hasError('exist')">
          {{this.msgServ.validationDisplay('letter template name')}}</div>
        <div *ngIf="!f.name.errors?.pattern && f.name.errors?.maxlength">
            {{this.msgServ.fieldlengthvalidation('word','100')}}</div>
        <div *ngIf=" f.name.errors?.pattern">{{this.msgServ.validationDisplay('pattern')}}
        </div>
      </div>
      </div>
      <div class="col-md-6">
        <label for="module" class="form-label required">Module </label>
        <ng-select formControlName="module" [clearable]="false" (change)="moduleChanged()"
          [ngClass]="{ 'is-invalid': (submitted || f.module.touched) &&  f.module.errors}"
          class="form-ngselect " placeholder="{{this.msgServ.selectplaceholddisplay('module')}}">
          <ng-option *ngFor="let mod of modulesDropdown" [value]="mod?.id">{{mod?.name}}
          </ng-option>
        </ng-select>
        <div *ngIf="(submitted || f.module.touched || f.module.dirty) && f.module.errors"
        class="invalid-feedback">
        <div *ngIf="f.module.errors?.required">
            {{this.msgServ.validationDisplay('required')}}</div>
        </div>
      </div>
      <div class="col-md-6">
        <label for="" class="form-label required">Letter type </label>
        <ng-select formControlName="letter_type" [clearable]="false"
        [ngClass]="{ 'is-invalid': (submitted || f.letter_type.touched) &&  f.letter_type.errors}"
        class="form-ngselect " placeholder="{{this.msgServ.selectplaceholddisplay('letter type')}}">
        <ng-option *ngFor="let mod of typesDropdown" [value]="mod?.id">{{mod?.name}}
        </ng-option>
        </ng-select>
        <div *ngIf="(submitted || f.letter_type.touched || f.letter_type.dirty) && f.letter_type.errors"
          class="invalid-feedback">
          <div *ngIf="f.letter_type.errors?.required">
              {{this.msgServ.validationDisplay('required')}}</div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid"
        (click)="validateForm()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
  </footer>
</form>
