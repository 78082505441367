import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {

  viewDetails               = false;
  viewSpecData    : any     = []
  listDataArr     : any     = []
  searchString    : any     = ""
  page                      = 1
  loader                    = true
  filterPanel     : boolean = false
  filter          : any     =""
  defaultlimit    : any     = 20;
  offset                    = 0;
  viewImportPanel : boolean = false
  permissions     : any

  filterForm        = this.fb.group({
    loan_type                     : [null],
    loan_code                     : [null],
    status                        : [null],
    requested_from_date           : '',
    requested_to_date             : '',
    closed_from_date              : '',
    closed_to_date                : '',
  })

  OuFilterForm      = this.fb.group({
    company            : [''],
    bu                 : [''],
    branch             : [''],
    designation        : [''],
    department         : [''],
    employee_list      : [''],
    grade              : [''],
    employee_type      : [''],
    employment_status  : [''],
  })

  viewloader        = false
  isLoading         = false
  searchData : Subject<void> = new Subject();

  constructor(private LPS:LoanProcessService, private fb : FormBuilder, public appService : AppService) { }

  ngOnInit(): void {
    this.listData()
    this.searchData.pipe(delay(1000),
    switchMap(() =>   this.LPS.hrLoanListing(this.offset,this.defaultlimit,this.filter,this.searchString))).subscribe((res:any)=>{
      this.listDataArr = res.body
      this.loader = false;
      this.isLoading = false
    });
    this.getPermission(); // permission
  }

  listData(){
    this.loader = true
    this.LPS.hrLoanListing(this.offset,this.defaultlimit,this.filter,this.searchString).subscribe((res:any)=>{
      this.listDataArr = res.body
      this.loader = false;
    });
  }

  loadData(){
    this.loader = true
    this.searchData.next()
  }

  pageChanged(event:any){
    this.page = event
    this.offset = (this.page-1)*this.defaultlimit;
    this.loadData()
  }

  // View Loan Details
  loanDetail(key:any){
    this.viewDetails = true;
    this.viewloader = true
    this.LPS.hrLoanDetails(key).subscribe((res:any)=>{
      this.viewSpecData = res?.body?.data[0]
      this.viewloader = false
    })
  }

  // Filter
  applyFilter(data:any){
    this.searchString = ''
    this.filter = "&"+data
    this.filterPanel = false
    this.loadData()

  }

  closeFilter(){
    this.filterPanel = false;
  }

  resetfilter(){
    this.searchString = ''
    this.filter = ''
    this.loadData()
  }

  searchLoan(keyWord:any){
    this.isLoading = true
    this.offset = 0
    this.searchString = keyWord
    this.loadData()
  }

  searchResults(search:any){
    this.loader = true
    if(search == ''){
      this.searchString = ''
      this.offset = 0
    }else{
      this.searchData.next()
    }
  }

  // View details panel close
  closeDetails(){
    this.viewDetails = false
    this.listData()
  }

  // Import panel close
  closeImportPanel(val:any){
    this.viewImportPanel = val
  }

  // Permission
  getPermission(){
    this.permissions = this.appService.getPermissions('/loan-details');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
