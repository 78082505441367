<!-- test start -->
<div class="side-panel side-pane-active" style="--sidepanel-width:57.625rem;"
  [class.side-pane-active]='applyAdvance === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Apply Advance </h5>
      <a class="toggle-panel" (click)="closeAdvance()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 ">Advance DETAILS</p>
          </div>
          <app-c1-loader *ngIf="loader"></app-c1-loader>
          <ng-container *ngIf="!loader">
          <div class="col-12">
            <div class="card card-c2 p-16">
              <div class="row row-16 ">
                <div class="card card-c2">
                  <div class="table-responsive radius-4">
                    <table
                      class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                      <thead>
                        <tr>
                          <th scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox"
                              value="" (click)="checkall($event)" [checked]="checkallSelected()"></th>
                          <th class="fw-600 text-uppercase" scope="col">ADVANCE NUMBER</th>
                          <th class="fw-600 text-uppercase" scope="col">Expense date</th>
                          <th class="fw-600 text-uppercase" scope="col">Amount</th>
                          <th class="fw-600 text-uppercase" scope="col">DISPENSED AMOUNT</th>
                          <th class="fw-600 text-uppercase" scope="col">PURPOSE</th>
                        </tr>
                      </thead>
                      <tbody class="cell-16 ">

                        <ng-container *ngIf="applyAdvanceData?.length == 0">
                          <tr>
                            <td colspan="6">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                <span class="fw-500 fs-14">No data found</span>
                              </div>
                            </td>
                          </tr>
                        </ng-container>

                        <ng-container *ngFor='let advance of $any(applyAdvanceData|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize);let i=index;'>
                          <tr>
                            <td scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox" [(ngModel)]="advance.checked"
                                value=""></td>
                            <td>{{advance?.number}} </td>
                            <td><span class="text-nowrap">{{this.appservice.dateFormatDisplay(advance?.expense_date)}}</span></td>
                            <td>{{advance?.amount | currency : currency}}</td>
                            <td>{{advance?.dispansed_amount | currency : currency}}</td>
                            <td>{{advance?.purpose}}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- test start -->
          <div class="col-12" *ngIf="this.appservice.filteredcount(applyAdvanceData,searchString)>pageSize">
            <ngb-pagination class="d-flex justify-content-end"
              [collectionSize]="this.appservice.filteredcount(applyAdvanceData,searchString)" [(page)]="page" [maxSize]="3"
              [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
          </div>
          <!-- test end -->
        </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="countSelected()==0" (click)="addAdvance()">
        ADD
      </button>
    </footer>
  </div>
</div>
<!-- test end -->
