import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {FormBuilder, FormGroup,FormArray, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { maxTenDigitDecimal } from 'src/app/@shared/validators/maxTenDigitDecimal.validators'
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { singleDigitDecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
@Component({
  selector: 'app-leave-credit-details',
  templateUrl: './leave-credit-details.component.html',
  styleUrls: ['./leave-credit-details.component.scss']
})
export class LeaveCreditDetailsComponent implements OnInit {
  activeTab3 =0;
  constructor(public messageService : MessageService,public appService: AppService,public existDataService : ExistDatasService,public fb : FormBuilder,public leavetypeService:leavetypeService) { }

  @Input() leaveConfigForm: any;
  @Input() creditdetailsForm : any;
  @Input() leaveTypeCode: any;
  @Input() leaveCreditArr: any;
  @Input() saveBtnClicked: any;
  @Input() editData:any;
  activeIndex:any = '';

  submitted = false;
  creditedLeaveValues = ['Monthly','Quarterly','Half Yearly','Yearly'];
  roundoffValues = [
    'Round off to nearest half day','Round off to nearest full day','Round off to ceiling half day','Round off to ceiling full day','Round off to min (previous) half day','Round off to min (previous) full day']

  accuralLeaveValues = ['Prorate based on credit rule start date','Do not prorate']
  effectiveValues = ['Days','Months','Years'];
  from_DOJ_DOC:any = [{name:'DOJ',value:false},{name:'DOC',value:true}];
  leave_credit_timeofexiting_values = ['Prorate based on exit date of employee','Do not prorate','Do not credit leave when employee is under notice period']


  ngOnInit(): void {

   for(let i=0;i<this.creditdetailsForm.controls?.length;i++){
      if(this.leaveTypeCode==this.creditdetailsForm.controls[i].get('leave_code')?.value){

        this.activeIndex = i;

        if(this.leavemapping_creditrule()?.length == 0 && this.editData?.length==0 && this.creditdetailsForm.controls[i].get('grant_leave')?.value==false){
          // this.addcreditRule();
        }
        // else{
        //   if(this.leavemapping_creditrule().length == 0 && this.creditdetailsForm.controls[i].get('grant_leave')?.value==false)
        //   this.setCreditForm();
        // }

      }
    }
    this.addValidators()
    this.addManualValidator();


  }


 get f() { return this.creditdetailsForm.controls; }

 leavemapping_creditrule(): FormArray {
  return this.creditdetailsForm.controls[this.activeIndex].get("leavemapping_creditrule") as FormArray
}

leavecreditrule_firstmonth() : FormArray {
  return this.creditdetailsForm.controls[this.activeIndex].get("leavecreditrule_firstmonth") as FormArray
}

leavecreditrule_lastmonth() : FormArray {
  return this.creditdetailsForm.controls[this.activeIndex].get("leavecreditrule_lastmonth") as FormArray
}

  newCreditrule(): FormGroup {
    return this.fb.group({
      "effective_type"                : ['Days',[Validators.required]],
      "effective_from"                : [0,[Validators.required,maxTenDigitDecimal(),singleDigitDecimalValidator()]],
      "from_doj_doc"                  : [true,[Validators.required]],
      "do_want_to_credit_leave"       : ['Yearly',[Validators.required]],
      "no_of_leaves_credited"         : ['',[Validators.required,Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      "which_dayof_month"             : [null,[Validators.required]],
      'leavecreditrule_firstmonth'    : this.fb.array([]),
      leavecreditrule_lastmonth       : this.fb.array([]),
      first_month_rule                : [false],
      leave_credit_timeofjoining      : ['Prorate based on credit rule start date',[Validators.required]],
      leave_credit_timeofexiting      : ['Prorate based on exit date of employee',[Validators.required]],
      last_month_rule                 : [false],
      grant_leave_prev_month          : [false],
      req_attend_calculate            : [true],
      prev_month_greater_than         : ['',[Validators.min(0.1),maxTenDigitDecimal(),singleDigitDecimalValidator()]],
      criteria_not_met                : [true],
      roundoff_leave_granting         : [false],
      credit_lapse_enable             : [false],
      credit_lapse_cond               : [true],
      credit_lapse                    : ['',[Validators.min(1)]],
      roundoff_option                 : [null],
      leave_opening_balance_enable    : [false],
      leave_opening_balance           : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      carry_over_prev_creditrule      : [false],
      carry_over_limit                : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      carry_forward_next_year         : [false],
      carry_forward_limit             : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      max_carry_forward_status        : [false],
      max_carry_over_status           : [false]
    })
  }
firstmonth(creditId: number): FormArray {
  return this.leavemapping_creditrule()
    .at(creditId)
    .get('leavecreditrule_firstmonth') as FormArray;
}
lastmonth(creditId: number): FormArray {
  return this.leavemapping_creditrule()
    .at(creditId)
    .get('leavecreditrule_lastmonth') as FormArray;
}
newfstMonthrule(creditIndex:any): FormGroup {
  return this.fb.group({
    "from_date"   : [null,[Validators.required]],
    "to_date"     : [null,[Validators.required]],
    "count_number": ['',[Validators.required,maxTenDigitDecimal(),digitdecimalValidator(),this.customValidator(creditIndex)]],


  })
}

newLstMonthrule(creditIndex:any): FormGroup {
  return this.fb.group({
    "from_date"   : [null,[Validators.required]],
    "to_date"     : [null,[Validators.required]],
    "count_number": ['',[Validators.required,maxTenDigitDecimal(),digitdecimalValidator(),this.customValidator(creditIndex)]],


  })
}

addcreditRule() {

  this.leavemapping_creditrule().push(this.newCreditrule());

}

addFstMonthRule(creditIndex:any,value:any){
  if(value==true)
  this.firstmonth(creditIndex).push(this.newfstMonthrule(creditIndex));
  else
  this.firstmonth(creditIndex).clear();

}
addLstMonthRule(creditIndex:any,value:any){
  if(value==true)
  this.lastmonth(creditIndex).push(this.newLstMonthrule(creditIndex));
  else
  this.lastmonth(creditIndex).clear();

}

removeFirstRule(creditIndex: number, ruleId: number) {
  this.firstmonth(creditIndex).removeAt(ruleId);
}
removeLastRule(creditIndex: number, ruleId: number) {
  this.lastmonth(creditIndex).removeAt(ruleId);
}

removeCreditRule(creditIndex: number) {
  if(this.activeTab3==creditIndex)
  this.activeTab3 = this.activeTab3-1;
  this.leavemapping_creditrule().removeAt(creditIndex);
}

makeItAsEmpty(control:any,index:any){
  this.leavemapping_creditrule().controls[index].get(control)?.setValue('')
}

addValidators(){
  this.setValidators('leave_opening_balance_enable','leave_opening_balance')
  this.setValidators('max_carry_over_status','carry_over_limit')
  this.setValidators('carry_over_prev_creditrule','max_carry_over_status')
  this.setValidators('max_carry_forward_status','carry_forward_limit')
  this.setValidators('carry_forward_next_year','max_carry_forward_status')
  this.setValidators('grant_leave_prev_month','prev_month_greater_than')
  this.setValidators('roundoff_leave_granting','roundoff_option')
  this.setValidators('credit_lapse_enable','credit_lapse')
}

setValidators(parent: any, control: any) {
  for (let i = 0; i < this.leavemapping_creditrule()?.length; i++) {
    if (this.leavemapping_creditrule().controls[i].get(parent)?.value === true) {
      if (parent !== 'roundoff_leave_granting' && parent !== 'carry_forward_next_year' && parent !== 'credit_lapse_enable' && parent !== 'carry_over_prev_creditrule') {
        this.leavemapping_creditrule().controls[i].get(control)?.setValidators([
          Validators.required,
          Validators.min(0.1),
          maxTenDigitDecimal(),
          singleDigitDecimalValidator()
        ]);
      } else if (parent === 'credit_lapse_enable') {
        this.leavemapping_creditrule().controls[i].get('credit_lapse')?.markAsTouched();
        if (this.leavemapping_creditrule().controls[i].get('credit_lapse_cond')?.value) {
          this.leavemapping_creditrule().controls[i].get(control)?.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.maxLength(3),
            NumberpatternValidator()
          ]);
        } else {
          this.leavemapping_creditrule().controls[i].get(control)?.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.max(60),
            NumberpatternValidator()
          ]);
        }
      } else {
        this.leavemapping_creditrule().controls[i].get(control)?.setValidators([Validators.required]);
      }
    } else {
      this.leavemapping_creditrule().controls[i].get(control)?.clearValidators();
      if (parent !== 'roundoff_leave_granting' && parent !== 'carry_forward_next_year' && parent !== 'carry_over_prev_creditrule') {
        if (this.leavemapping_creditrule().controls[i].get(control)?.value !== '') {
          this.leavemapping_creditrule().controls[i].get(control)?.setValue('');
        }
      } else if (parent === 'carry_forward_next_year') {
        if (this.leavemapping_creditrule().controls[i].get(control)?.value !== false) {
          this.leavemapping_creditrule().controls[i].get(control)?.setValue(false);
        }
        if (this.leavemapping_creditrule().controls[i].get('carry_forward_limit')?.value !== '') {
          this.leavemapping_creditrule().controls[i].get('carry_forward_limit')?.clearValidators();
          this.leavemapping_creditrule().controls[i].get('carry_forward_limit')?.setValue('');
        }
      } else if (parent === 'carry_over_prev_creditrule') {
        if (this.leavemapping_creditrule().controls[i].get(control)?.value !== false) {
          this.leavemapping_creditrule().controls[i].get(control)?.setValue(false);
        }
        if (this.leavemapping_creditrule().controls[i].get('carry_over_limit')?.value !== '') {
          this.leavemapping_creditrule().controls[i].get('carry_over_limit')?.clearValidators();
          this.leavemapping_creditrule().controls[i].get('carry_over_limit')?.setValue('');
        }
      } else {
        if (this.leavemapping_creditrule().controls[i].get(control)?.value !== null) {
          this.leavemapping_creditrule().controls[i].get(control)?.setValue(null);
        }
      }
    }
    this.leavemapping_creditrule().controls[i].get(control)?.updateValueAndValidity();
  }
  this.leavemapping_creditrule().updateValueAndValidity({ onlySelf: true });
}

addManualValidator(){
  if(this.creditdetailsForm.controls[this.activeIndex].get("max_carry_forward_count_status").value==true){
    this.creditdetailsForm.controls[this.activeIndex].get('carry_forward_count').setValidators([Validators.required,Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()])
  }
  else{
    this.creditdetailsForm.controls[this.activeIndex].get('carry_forward_count').clearValidators();
    this.creditdetailsForm.controls[this.activeIndex].get('carry_forward_count').setValue('')

  }
  this.creditdetailsForm.controls[this.activeIndex].get('carry_forward_count')?.updateValueAndValidity()

}



 setCreditForm(){


    Object.entries( this.editData[this.activeIndex]['leavemapping_configuration'][0] ).forEach(([key, value]) => {

      if(key=='leavemapping_creditrule' && this.editData[this.activeIndex]['leavemapping_configuration'][0]['grant_leave']==false){
        let credit_data:any = value;

        for(let map=0;map<credit_data?.length;map++){
          this.addcreditRule();
          Object.entries( credit_data[map] ).forEach(([key, value]) => {

            if(key!='leavecreditrule_firstmonth' && key!='leavecreditrule_lastmonth'){

              this.leavemapping_creditrule().controls[map].get(key)?.setValue(value);

            }
            else if(key=='leavecreditrule_firstmonth'){
              let firstMonthdata:any = value;
              for(let first=0;first<firstMonthdata?.length;first++){
                this.addFstMonthRule(map,true);
                Object.entries( firstMonthdata[first] ).forEach(([key, value]) => {

                  this.firstmonth(map).controls[first].get(key)?.setValue(value);

                })

              }
            }
            else{
              let lastMonthdata:any = value;

              for(let last=0;last<lastMonthdata?.length;last++){
                this.addLstMonthRule(map,true)

                Object.entries( lastMonthdata[last] ).forEach(([key, value]) => {

                  this.lastmonth(map).controls[last].get(key)?.setValue(value);

                })

              }

            }

          })
        }
      }


    })

 }
 checkCreditRuleApplicable(value:any){
  if(value==false){
    this.activeTab3 = 0
  this.addcreditRule()
  } else {
    this.leavemapping_creditrule().clear();
    this.resetManualData();

  }

 }
 resetManualData(){
  this.creditdetailsForm.controls[this.activeIndex].get("max_carry_forward_count_status").setValue(false)
  this.addManualValidator();
 }
 validateFstRule(i:any,first:any){
  if(first>0){
    this.firstmonth(i).controls[first].get('from_date')?.setValidators(Validators.min(this.firstmonth(i).controls[first-1]?.get('to_date')?.value+1))
    this.firstmonth(i).controls[first].get('from_date')?.updateValueAndValidity()
  }
  if(this.firstmonth(i).controls[first-1]?.get('to_date')?.value == 'Last day of month'){
    this.firstmonth(i).controls[first].get('from_date')?.setValidators(Validators.min(29))
    this.firstmonth(i).controls[first].get('from_date')?.updateValueAndValidity()
  }

    this.firstmonth(i).controls[first].get('to_date')?.setValidators(Validators.min(this.firstmonth(i).controls[first].get('from_date')?.value+1))
    this.firstmonth(i).controls[first].get('to_date')?.updateValueAndValidity()

 }
 validateLstRule(i:any,last:any){
  if(last>0){
    this.lastmonth(i).controls[last].get('from_date')?.setValidators(Validators.min(this.lastmonth(i).controls[last-1]?.get('to_date')?.value+1))
    this.lastmonth(i).controls[last].get('from_date')?.updateValueAndValidity()
  }
  if(this.lastmonth(i).controls[last-1]?.get('to_date')?.value == 'Last day of month'){
    this.lastmonth(i).controls[last].get('from_date')?.setValidators(Validators.min(29))
    this.lastmonth(i).controls[last].get('from_date')?.updateValueAndValidity()
  }
    this.lastmonth(i).controls[last].get('to_date')?.setValidators(Validators.min(this.lastmonth(i).controls[last].get('from_date')?.value+1))
    this.lastmonth(i).controls[last].get('to_date')?.updateValueAndValidity()
 }
 changemonthRule(i:any){
    if(this.leavemapping_creditrule().controls[i].get('do_want_to_credit_leave')?.value != 'Monthly'){
      this.leavemapping_creditrule().controls[i].get('first_month_rule')?.setValue(false)
      this.leavemapping_creditrule().controls[i].get('last_month_rule')?.setValue(false)
      this.firstmonth(i).clear();
      this.lastmonth(i).clear();
    }
 }

 customValidator(creditIndex:any)
{
  return (control:FormControl)=>
  {
      const thisValue = control.value;
      const max=this.leavemapping_creditrule().controls[creditIndex].get('no_of_leaves_credited')?.value
      if (thisValue <= max) {
        return null;
      }
    return {
        'max': true
    }

  }
}
updateValidity(i:any){
  for(let first=0;first<this.firstmonth(i)?.controls?.length;first++){
    this.firstmonth(i)?.controls[first]?.get('count_number')?.updateValueAndValidity()
  }
  for(let last=0;last<this.lastmonth(i)?.controls?.length;last++){
    this.lastmonth(i)?.controls[last]?.get('count_number')?.updateValueAndValidity()
  }
}
}
