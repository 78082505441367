import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { RoleService } from 'src/app/@core/services/role.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-salary-dashboard-filter',
  templateUrl: './salary-dashboard-filter.component.html',
  styleUrls: ['./salary-dashboard-filter.component.scss']
})
export class SalaryDashboardFilterComponent implements OnInit {

  constructor(private rs:RoleService,public messageService : MessageService,public appService : AppService,private _cd: ChangeDetectorRef) { }
  companyDD: any = []
  companyList : any = []
  buDD:any = [];
  buList:any = [];
  DepDD:any = [];
  DepList:any = [];
  DesignationDD:any = [];
  DesignationList:any = [];
  BranchDD:any = [];
  BranchList:any = [];
  cityDD:any = [];
  cityList:any = [];
  gradeDD:any = [];
  gradeList:any = [];

  buLoader:boolean = false
  DepLoader:boolean = false
  DesigLoader:boolean = false
  BranchLoader:boolean = false
  gradeLoader:boolean = false
  
  statusDD:any = [{name:'All', value: ''},{name:'Active', value: true},{name:'Inactive', value: false}];
  status:any=true;

  @Input()filter:any;
  @Input() formData :any
  @Input() company:any;
  @Input() isCompanyNeeded : any
  @Output() applymethod = new EventEmitter();
  @Output() resetmethod = new EventEmitter();
  @Output() closemethod = new EventEmitter();
  @Input() from:any='';


  ngOnInit(): void {
    if(this.isCompanyNeeded)
      this.getCompanyList();
    this.buDD = [];
    if(!this.isCompanyNeeded){
      this.getBUList(this.company);
    }
    this.getDepList()
    this.getDesignationList()
    this.getBranchList()
    this.getGradeList()
    this.getCityList()
  }

  getCompanyList(){
    this.rs.getCompanyList().subscribe((res: any) => {
      if(res.body.length>0){
        this.companyDD = res.body;
        this.selectAllForDropdownItems(this.companyDD);
      }else{
        this.companyDD = []
      }
    });
  }

  companyChange(){
    let id = this.companyList?.join(',');
    if(this.companyList.length==0){
      this.buList = '';
    }
    this.getBUList(id)
  }

  getBUList(data:any){
    this.buDD = [];
    this.buLoader = true
    this.rs.getBuList(data).subscribe((res: any) => {
      this.buLoader = false
      if(res.status == 200 && res.body.length > 0) {
        this.buDD=(res.body);
        this.selectAllForDropdownItems(this.buDD);
      }else{
        this.buDD = [];
      }
      this._cd.markForCheck();
    });
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  getDepList(){
    this.DepLoader = true
    this.rs.getDepartmentList().subscribe((res: any) => {
    this.DepLoader = false
      if(res.status == 200 && res.body.length > 0) {
          this.DepDD = res.body;
          this.selectAllForDropdownItems(this.DepDD);

      }
      this._cd.markForCheck();
    });
  }

  getDesignationList(){
    this.DesigLoader = true
    this.rs.getDesignationList().subscribe((res: any) => {
    this.DesigLoader = false
      if(res.status == 200 && res.body.length > 0) {
          this.DesignationDD = res.body;
          this.selectAllForDropdownItems(this.DesignationDD);
      }
      this._cd.markForCheck();
    });
  }
  getBranchList(){
    this.BranchLoader = true
    this.rs.getBranchList().subscribe((res: any) => {
    this.BranchLoader = false
      if(res.status == 200 && res.body.length > 0) {
          this.BranchDD = res.body;
          this.selectAllForDropdownItems(this.BranchDD);
      }
      this._cd.markForCheck();
    });
  }

  getGradeList(){
    this.gradeLoader = true
    this.rs.getGradeList().subscribe((res: any) => {
    this.gradeLoader = false
      if(res.status == 200 && res.body.length > 0) {
          this.gradeDD = res.body;
          this.selectAllForDropdownItems(this.gradeDD);
      }
      this._cd.markForCheck();
    });
  }

  getCityList(){
    this.rs.getcityList(true).subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.cityDD = res.body;
          // this.selectAllForDropdownItems(this.cityDD);

      }
    });
  }

  clearcommonForm(item:any,index:number){
    if(item == "company"){
      this.companyList.splice(index, 1);
      this.buList = [];

      if(this.companyList.length==0)
      this.companyList = [];
      this.companyChange()
    }
    else if(item == "businessunit"){
      this.buList.splice(index, 1);
      if(this.buList.length==0)
      this.buList = '';

    } else if(item == "department"){
      this.DepList.splice(index, 1);
      if(this.DepList.length==0)
      this.DepList = '';
    } else if(item == "designation"){
      this.DesignationList.splice(index, 1);
      if(this.DesignationList.length==0)
      this.DesignationList = '';
    } else if(item == "grade"){
      this.gradeList.splice(index, 1);
      if(this.gradeList.length==0)
      this.gradeList = '';
    } else if(item == "branch"){
      this.BranchList?.splice(index, 1);
      if(this.BranchList?.length==0)
      this.BranchList = '';
    } else if(item == "city"){
      this.cityList.splice(index, 1);
      if(this.cityList.length==0)
      this.cityList = '';
    }
  }
  resetMethd(){
    if(this.isCompanyNeeded)
      this.buDD = [];
    this.companyList = []
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.cityList = []
    this.status = true;
    this.resetmethod.emit();
  }

  closeMethod(){
    this.closemethod.emit();
  }

  applyMethod(){
    if(this.from=='groupEsi'){
      this.applymethod.emit({companyList:this.companyList,buList:this.buList,BranchList:this.BranchList,cityList:this.cityList,status:this.status});
    }else{
      this.applymethod.emit({companyList:this.companyList,buList:this.buList,DepList:this.DepList,  DesignationList:this.DesignationList,gradeList:this.gradeList,BranchList:this.BranchList});
    }
  }

}
