import { Component, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/@core/services/template.service';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { delay, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-taxslip-template',
  templateUrl: './taxslip-template.component.html',
  styleUrls: ['./taxslip-template.component.scss']
})
export class TaxslipTemplateComponent implements OnInit {
  // modalToggle = false;
  fileName: any =  "https://skizzlehrdevbkdstrage.blob.core.windows.net/gamil/6202f4a1-2c19-4802-82c8-67796985e2df_Generate%20%283%29.pdf";
  modalToggle                = false;
  currentDocName             = "";
  currentExtension           = "";
  allowedExtension           = "";
  //fileName:any;
  alertToggle                = false;
  empalertMsg:any            = '';
  offset = 0;
  limit = 20;
  taxSlipList : any = [];
  taxSlipListDetails : any =[];
  listLength : any;
  nodata = false;
  infinityloader = false
  loader = true;
  loader1:any;
  direction = '';
  template_list_flag: boolean = true;
  searchKeyword="";
  searchlistdata:any = [];
  taxslipsearch : string = this.messageService.searchdisplay('tax slip');
  htmldata:any;
  value:any
  isLoading = false
  searchData : Subject<void> = new Subject();

  constructor(
    public messageService : MessageService,
    private templateService : TemplateService) { }

  ngOnInit(): void {
    this.getTaxSlip();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.templateService.getTaxSlip(this.offset,this.limit,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getTaxSlip(){
    this.loader = true;
    this.templateService.getTaxSlip(this.offset,this.limit,this.searchKeyword).subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    if(res.body.length == 0){
      this.template_list_flag = (this.offset == 0) ? false :true;
      this.listLength = 0;
    }
    else{
      this.template_list_flag = true;
      this.listLength = res.body.length;
      if(this.offset == 0){
        this.taxSlipList = [];
      }
      this.taxSlipListDetails = res.body;
      this.infinityloader = false;
      this.addItems(this.listLength);
    }
    this.loader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.taxSlipList.push(this.taxSlipListDetails[i]);
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.limit;
    if(this.listLength){
      this.getTaxSlip();
      this.loader = false;
      this.direction = "down";
    }else{
      this.infinityloader = false;
    }
  }

  searchtaxslip(searchKeyword:any){
    this.loader = true;
    this.isLoading = true;
    this.offset = 0;
    this.taxSlipList= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.taxSlipList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  viewDocument(filename:any, template_name:any){
    this.modalToggle            = true;
    this.loader1  = false;
    this.fileName               = filename;
    this.currentDocName    = template_name;
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.empalertMsg          = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }

  }
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
   }
}
