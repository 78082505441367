import { Component, OnInit, EventEmitter, Input, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';

@Component({
  selector: 'app-regularization-edit-details',
  templateUrl: './regularization-edit-details.component.html',
  styleUrls: ['./regularization-edit-details.component.scss']
})
export class RegularizationEditDetailsComponent implements OnInit {

  submitted     : boolean = false;
  disabled      : boolean = false
  formData      : any     = []
  canSave       : any     = true;
  alertShow               = 'show';

  @Input() editDetailsData : any;
  @Input() formGp          : FormGroup = this.fb.group({
    regularization_request : this.fb.array([])
  });

  @Output() submitmethod        = new EventEmitter();
  @Output() editDetails         = new EventEmitter()

  constructor(
    public fb            :FormBuilder,
    public messageService: MessageService,
    public appServ       : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private cd :  ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // Date Format    
    this.getDateFormat();
    this.detail().clear();
    if(this.editDetailsData?.length > 0){
      for(let i=0;i<this.editDetailsData?.length; i++){
        this.addDetail();
      }
      (<FormArray>this.formGp.get("regularization_request")).reset(this.editDetailsData.value);

      for(let j=0;j<(<FormArray>this.formGp.get("regularization_request"))?.length;j++){
        this.getDiff(j);
        this.checkinShiftWindow(j);
        this.checkoutShiftWindow(j);
        if((<FormArray>this.formGp.get("regularization_request"))?.controls?.[j]?.get('reason')?.errors?.maxlength)
          (<FormArray>this.formGp.get("regularization_request"))?.controls?.[j]?.get('reason')?.markAsTouched();
      }
    }
    if((<FormArray>this.formGp.get("regularization_request"))?.length > 0){
      for(let j=0;j<(<FormArray>this.formGp.get("regularization_request"))?.length;j++){
      this.checkValidation(j,'regulized_checkout_time');
        if((<FormArray>this.formGp.get("regularization_request"))?.controls?.[j]?.get('regulized_checkout_time')?.errors?.sametime == true){
          (<FormArray>this.formGp.get("regularization_request"))?.controls?.[j]?.get('regulized_checkout_time')?.markAsTouched();        
        }       
      }
    }

   }

  // Date Format
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appServ.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  /********* FORM ARRAY CREATION FUNCTIONS ****************/
  detail() : FormArray {
    return this.formGp.get("regularization_request") as FormArray
  }

  newDetail(): FormGroup {
      return this.fb.group({
        "regulized_date" : [,[Validators.required]],
        "shift_name"     : [null],
        "regulized_checkin_date" : [,[Validators.required]],
        "regulized_checkin_time"  : ['00:00:00',[Validators.required]],
        "regulized_checkout_date" : [,[Validators.required]],
        "regulized_checkout_time" : ['00:00:00',[Validators.required]],
        "reason"         : [,[Validators.required,Validators.maxLength(500)]],
        "hours"          : [,[Validators.required]],
        "shift_mapping"  : [,[Validators.required]],
        "shift_name_code": [null],
        "checkin_date"   : [],
        "checkout_date"  : [],

        "allowed_checkin_date"  : [],
        "allowed_checkin_time"  : [],
        "allowed_checkout_date" : [],
        "allowed_checkout_time" : [],
      },
      {
        validators: [CustomDateValidators.checkoutDate('regulized_checkout_date', 'checkin_date','checkout_date'),CustomDateValidators.checkinDate('regulized_checkin_date', 'checkin_date')]
      }

      )
  }

  addDetail() {
    this.detail().push(this.newDetail());
  }

  removeDetail(i:number) {
    this.detail().removeAt(i);
    this.formData = (<FormArray>this.formGp.get("regularization_request")).value;
    this.editDetails.emit(i);
    if((<FormArray>this.formGp.get("regularization_request"))?.length == 0)
      this.canSave = false;
   else
      this.canSave = true;
  }

  disableDate(){
    return false;
  }

   /**
 * Takes 1 parameter ie.Index and return the diff of check in and check out
 * @param i first input
 * @returns return the diff of check in and check out
 */

   getDiff(i:number){
    if(this.detail().at(i).get('regulized_checkout_time')?.value !== '' && this.detail().at(i).get('regulized_checkout_time')?.value !== null && this.detail().at(i).get('regulized_checkin_time')?.value !== '' && this.detail().at(i).get('regulized_checkin_time')?.value !== null && this.detail().at(i).get('regulized_checkout_date')?.value !== '' && this.detail().at(i).get('regulized_checkout_date')?.value !== null && this.detail().at(i).get('regulized_checkin_date')?.value !== ''){

      var start_time=moment(this.detail().at(i).get('regulized_checkin_time')?.value,'HH:mm').format('HH:mm')
      var end_time=moment(this.detail().at(i).get('regulized_checkout_time')?.value,'HH:mm').format('HH:mm')
      var start_date = moment(this.detail().at(i).get('regulized_checkin_date')?.value).format('YYYY-MM-DD')
      var end_date = moment(this.detail().at(i).get('regulized_checkout_date')?.value).format('YYYY-MM-DD')

      var start_datetime = moment( start_date+ 'T' + start_time);
      var end_datetime = moment(end_date + 'T' + end_time);

      // Calculate the time difference in milliseconds
      var durationMs = end_datetime.diff(start_datetime);

      // Convert milliseconds to hours, minutes, and seconds
      var durationHours = Math.floor(durationMs / 3600000);
      var durationMinutes = Math.floor((durationMs % 3600000) / 60000);
      // var durationSeconds = Math.floor((durationMs % 60000) / 1000);
      var formattedHours = String(durationHours).padStart(2, '0');
      var formattedMinutes = String(durationMinutes).padStart(2, '0');
      this.detail().at(i).patchValue({
        'hours' : formattedHours+':'+formattedMinutes
      })
    }
    this.cd.detectChanges()
  }

  get f() {return  this.formGp.controls; }

  submitResignationDetails(){
    this.canSave = false;
    this.submitmethod.emit();
  }

  // Make null for checkout date, checkout time and hours when the check in are after check out data
  makeOutNull(i:any){
    let check = false
    // checking all values are not null
    if(this.detail().at(i).get('regulized_checkout_time')?.value !== '' && this.detail().at(i).get('regulized_checkout_time')?.value !== null && this.detail().at(i).get('regulized_checkout_date')?.value !== '' && this.detail().at(i).get('regulized_checkout_date')?.value !== null && this.detail().at(i).get('regulized_checkin_date')?.value !== '' && this.detail().at(i).get('regulized_checkin_time')?.value !== ''){
      let start_time = moment(this.detail().at(i).get('regulized_checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      let start_date = moment(this.detail().at(i).get('regulized_checkin_date')?.value).format('YYYY-MM-DD')
      let end_date = moment(this.detail().at(i).get('regulized_checkout_date')?.value).format('YYYY-MM-DD')
      let end_time = moment(this.detail().at(i).get('regulized_checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      if(this.detail().controls[i].errors?.fromToDate1 == true || this.detail().controls[i].errors?.checkin == true){
        this.detail().at(i).patchValue({
          'regulized_checkout_time' : null,
          'hours' : '00:00'
        })
        this.detail().at(i).get('regulized_checkout_time')?.markAsTouched()

        check = true
      }else if (moment(start_date,'YYYY-MM-DD').isSame(moment(end_date,'YYYY-MM-DD'), 'day')) {
        if(moment(start_time,'HH:mm:ss').isAfter(moment(end_time,'HH:mm:ss'))){
          this.detail().at(i).patchValue({
            'regulized_checkout_time' : null,
            'hours' : '00:00'
          })
         this.detail().at(i).get('regulized_checkout_time')?.markAsTouched()
          check = true
        }
      }
    }
  return check
  }

  // If dates are same, the check out should start from check in or from 00:00
  isSameDates(i:any): boolean {
    let start_date = moment(this.detail().at(i).get('regulized_checkin_date')?.value).format('YYYY-MM-DD')
    let end_date = moment(this.detail().at(i).get('regulized_checkout_date')?.value).format('YYYY-MM-DD')
    return moment(start_date,'YYYY-MM-DD').isSame(moment(end_date,'YYYY-MM-DD'), 'day');
  }

  //If Check in and out dates are there on initial, when i change check in value which is after check out date then checkout date should be null setted.
  isBeforeDates(i:any): boolean {
    let check = false
    if((this.detail().at(i).get('regulized_checkout_date')?.value != '' || this.detail().at(i).get('regulized_checkout_date')?.value != null) && (this.detail().at(i).get('checkout_time')?.value == '' || this.detail().at(i).get('checkout_time')?.value == null)){
      let start_date = moment(this.detail().at(i).get('regulized_checkin_date')?.value).format('YYYY-MM-DD')
      let end_date = moment(this.detail().at(i).get('regulized_checkout_date')?.value).format('YYYY-MM-DD')
      check = moment(start_date,'YYYY-MM-DD').isAfter(moment(end_date,'YYYY-MM-DD'), 'day');

      if(check){
        this.detail().at(i).patchValue({
          'regulized_checkout_date' : null
        })
        this.detail().at(i).get('regulized_checkout_date')?.markAsTouched()
      }
    }
    return check
  }


  checkValidation(i:any,control:any){
    let check = false
    // checking all values are not null
    if(this.detail().at(i).get('regulized_checkout_time')?.value !== '' && this.detail().at(i).get('regulized_checkout_time')?.value !== null && this.detail().at(i).get('regulized_checkout_date')?.value !== '' && this.detail().at(i).get('regulized_checkout_date')?.value !== null && this.detail().at(i).get('regulized_checkin_date')?.value !== '' && this.detail().at(i).get('regulized_checkin_time')?.value !== ''){
      let start_time = moment(this.detail().at(i).get('regulized_checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      let start_date = moment(this.detail().at(i).get('regulized_checkin_date')?.value).format('YYYY-MM-DD')
      let end_date = moment(this.detail().at(i).get('regulized_checkout_date')?.value).format('YYYY-MM-DD')
      let end_time = moment(this.detail().at(i).get('regulized_checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      if (moment(start_date,'YYYY-MM-DD').isSame(moment(end_date,'YYYY-MM-DD'), 'day')) {
        if(moment(start_time,'HH:mm:ss').isSame(moment(end_time,'HH:mm:ss'))){
          this.detail().at(i).get(control)?.setErrors({
            'sametime' : true
          })
      check = true
        }
      }
    }
  return check
  }

  checkinShiftWindow(i:any){
    let check = false

    if(this.detail().at(i).get('regulized_checkout_time')?.value !== '' && this.detail().at(i).get('regulized_checkout_time')?.value !== null && this.detail().at(i).get('regulized_checkout_date')?.value !== '' && this.detail().at(i).get('regulized_checkout_date')?.value !== null && this.detail().at(i).get('regulized_checkin_date')?.value !== '' && this.detail().at(i).get('regulized_checkin_time')?.value !== '' && this.detail().at(i).get('allowed_checkin_date')?.value !== '' && this.detail().at(i).get('allowed_checkin_time')?.value !== '' && this.detail().at(i).get('allowed_checkout_date')?.value !== '' && this.detail().at(i).get('allowed_checkout_time')?.value !== ''){

      let start_time = moment(this.detail().at(i).get('regulized_checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss')
      let start_date = moment(this.detail().at(i).get('checkin_date')?.value).format('YYYY-MM-DD')


      let allowed_checkin_date = moment(this.detail().at(i).get('allowed_checkin_date')?.value).format('YYYY-MM-DD');
      let allowed_checkin_time = moment(this.detail().at(i).get('allowed_checkin_time')?.value,'HH:mm:ss').format('HH:mm:ss');

      if (moment(start_date,'YYYY-MM-DD').isSame(moment(allowed_checkin_date,'YYYY-MM-DD'), 'day')){
        if(moment(start_time,'HH:mm:ss').isBefore(moment(allowed_checkin_time,'HH:mm:ss'))){
          this.detail().at(i).get('regulized_checkin_time')?.setErrors({
            'outoffshift' : true
          })
          this.detail().at(i).get('regulized_checkin_time')?.markAsTouched()
        }
      }
    }
    return check;
  }

  checkoutShiftWindow(i:any){
    let check = false

    if(this.detail().at(i).get('regulized_checkout_time')?.value !== '' && this.detail().at(i).get('regulized_checkout_time')?.value !== null && this.detail().at(i).get('regulized_checkout_date')?.value !== '' && this.detail().at(i).get('regulized_checkout_date')?.value !== null && this.detail().at(i).get('regulized_checkin_date')?.value !== '' && this.detail().at(i).get('regulized_checkin_time')?.value !== '' && this.detail().at(i).get('allowed_checkin_date')?.value !== '' && this.detail().at(i).get('allowed_checkin_time')?.value !== '' && this.detail().at(i).get('allowed_checkout_date')?.value !== '' && this.detail().at(i).get('allowed_checkout_time')?.value !== ''){

      let end_date = moment(this.detail().at(i).get('regulized_checkout_date')?.value).format('YYYY-MM-DD')
      let end_time = moment(this.detail().at(i).get('regulized_checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss')

      let allowed_checkout_date = moment(this.detail().at(i).get('allowed_checkout_date')?.value).format('YYYY-MM-DD');
      let allowed_checkout_time = moment(this.detail().at(i).get('allowed_checkout_time')?.value,'HH:mm:ss').format('HH:mm:ss');

      if (moment(end_date,'YYYY-MM-DD').isSame(moment(allowed_checkout_date,'YYYY-MM-DD'), 'day')){
        if(moment(end_time,'HH:mm:ss').isAfter(moment(allowed_checkout_time,'HH:mm:ss'))){
          this.detail().at(i).get('regulized_checkout_time')?.setErrors({
            'outoffshift' : true
          })
          this.detail().at(i).get('regulized_checkout_time')?.markAsTouched()
        }
      }

    }
   return check
  }


}
