import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.scss']
})
export class CommonLoaderComponent implements OnInit {

  loader = true;
  @Input() cardType:any;
  constructor() { }

  ngOnInit(): void {
  }

}
