<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Salary Revision Arrears - {{month}} {{year}}</h5>
    <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i
        class="icon-close-lg fs-16"></i></button>
  </header>

  <main class=" config-container">
    <!-- <form class=""> -->
    <div class="container-fluid p-32 form-container">
      <div class="row mb-24">
        <div class="col-md-4">
          <p class="text-uppercase fw-500 mb-0">{{HeaderText}} arrears</p>
        </div>
        <div class="col-md-8">
          <div class="d-flex align-items-center justify-content-end flex-wrap gap-16">
            <div style="min-width:22.688rem;">
              <div class="form-icon2">
                <i class="icon-search icon-left fs-14"></i>
                <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
                  (click)="searchString=''"></div>
                <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  "
                  name="searchString" [(ngModel)]="searchString" (keyup)="page=1" />
              </div>
            </div>
            <button class="btn-square btn btn-secondary" (click)="filter  = true">
              <i class="icon-filter-left fs-13"></i>
            </button>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" class="btn-check" (click)='getArrearList("Unprocessed")' name="btnradio"
                id="btnradio1" autocomplete="off" [checked]='status=="Unprocessed"' [disabled]="loader">
              <label class="btn btn-outline-accent2 text-uppercase fs-10 fw-700" for="btnradio1">Pending</label>
              <input type="radio" class="btn-check" (click)='getArrearList("Processed")' name="btnradio" id="btnradio2"
                autocomplete="off" [checked]='status=="Processed"' [disabled]="loader">
              <label class="btn btn-outline-accent2 text-uppercase fs-10 fw-700" for="btnradio2">Completed</label>
              <input type="radio" class="btn-check" (click)='getArrearList("Ignored")' name="btnradio" id="btnradio3"
                autocomplete="off" [checked]='status=="Ignored"' [disabled]="loader">
              <label class="btn btn-outline-accent2 text-uppercase fs-10 fw-700" for="btnradio3">IGNORED</label>
            </div>
          </div>
        </div>
      </div>


      <div class="row row-24">

        <div class="col-12" *ngIf="alertDiv && status=='unprocessed'">
          <div role="alert" class="alert alert-warning show mb-0" id="mapShow">
            <p class="mb-0 fs-14"> Please <a
                class="link-primary1" (click)="showSidePanel=true">Click here</a> if any salary revision arrears of  prior payroll moths need to be paid this month.</p>
            <div class="btn-close pointer" (click)="alertDiv = false"></div>
          </div>
        </div>

        <div class="col-12">
          <div class="card card-c2 p-0">
            <app-table-loader *ngIf="loader"></app-table-loader>

            <div *ngIf="!loader" class="table-responsive radius-4">
              <table
                class="table sticky-header td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                <thead>
                  <tr>

                    <th scope="col" class="form-cell" *ngIf="status!='ignored'">
                      <input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                        [checked]="checkallSelected()">
                    </th>
                    <th class="fw-600 text-uppercase" scope="col">Employee code</th>
                    <th class="fw-600 text-uppercase" scope="col">EMPLOYEE NAME</th>
                    <th class="fw-600 text-uppercase" scope="col">PAY ARREARS FOR</th>
                    <th class="fw-600 text-uppercase" scope="col">SALARY EFFECTIVE FROM</th>
                    <th class="fw-600 text-uppercase" scope="col">PAID DAYS</th>
                    <th *ngIf="status=='processed'" class="fw-600 text-uppercase" scope="col">Arrear amount </th>
                    <th class="fw-600 text-uppercase" scope="col">Salary status</th>

                    <!-- <th class="fw-600" scope="col"> </th> -->
                  </tr>
                </thead>
                <tbody class="cell-16 height-full">
                  <tr *ngIf="items.length<=0">
                    <td colspan="8">
                      <div class="card card-c2 flex-center p-16 bg-dark-500">
                        <span class="fw-500 fs-14">No data found</span>
                      </div>
                    </td>
                  </tr>
                  <tr
                    *ngFor="let item of $any(items |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                    <td class="form-cell" *ngIf="status!='ignored'">
                      <input [ngClass]="'page'+page" class="form-check-input m-0" type="checkbox"
                        [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" value="{{item.id}}">
                    </td>
                    <td>{{item.employee_code}}</td>
                    <td style="width:30%"> {{item.employee_name}} </td>
                    <td>{{item.arrear_month}} {{item.arrear_year}}</td>
                    <td>{{item?.salary_revision_month!=null?item.salary_revision_month[0]:''}} {{item?.salary_revision_month!=null?item.salary_revision_month[1]:''}}</td>
                    <td>{{item.paid_days}}</td>
                    <td *ngIf="status=='processed'">{{item.arrear_amount | currency:item.currency}}</td>
                    <td>
                      <span *ngIf="item.salary_status=='Publish'" class="badge  badge-success py-1">Published</span>
                      <span *ngIf="item.salary_status=='Processed'" class="badge badge-warning py-1">Processed</span>
                      <span *ngIf="item.salary_status=='Hold'" class="badge badge-primary py-1">Held</span>
                      <span *ngIf="item.salary_status=='Freeze'" class="badge badge-holiday py-1">Freezed</span>
                      <span *ngIf="item.salary_status=='Unprocessed'" class="badge badge-danger py-1">Unprocessed</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="filteredcount()>pageSize">
          <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize" [collectionSize]="filteredcount()"
            [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
        </div>
      </div>
    </div>

    <footer class="submit-footer gap-16 justify-content-end" *ngIf="status!=='Ignored'">
      <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="items.length>0">
        <span class="">{{countSelected()}} of {{items?.length}} selected </span>
        <span *ngIf="items.length != countSelected()" class="link-primary fw-500" (click)="selectAll();">Select all
          {{items?.length}} employees</span>
        <span *ngIf="items.length == countSelected()" class="link-primary fw-500" (click)="unselectAll();">Unselect all
          {{items?.length}} employees</span>
      </p>
      <ng-container *ngIf="status=='Unprocessed'">
        <button [disabled]="countSelected()==0" class="btn btn-primary btn-sm text-uppercase"
          (click)="processArrear('Ignored')">IGNORE ARREAR</button>
        <button [disabled]="countSelected()==0" class="btn btn-primary btn-sm text-uppercase"
          (click)="processArrear('Processed')">Move to Completed</button>
      </ng-container>
      <ng-container *ngIf="status=='Processed'">
        <button [disabled]="countSelected()==0" class="btn btn-primary btn-sm text-uppercase"
          (click)="processArrear('Unprocessed')">Move to pending</button>
      </ng-container>
    </footer>

    <!-- </form> -->
  </main>
</div>
<!--Slider -->
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
  (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>

<div class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">{{arrearList.length}} Employee(s) selected</h4>
        <button type="button" class="btn-close ms-auto my-0" (click)="downloadMsg = false"></button>
      </div>
      <div class="modal-body">
        <p class="mb-0"> {{message}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="downloadMsg = false;">Cancel</button>
        <button type="button" [disabled]="isComplete" class="btn btn-primary btn-sm text-uppercase"
          (click)="saveArrear()">{{statusText}}</button>

      </div>
    </div>
  </div>
</div>

<!-- Error data download popup -->
<!-- <div  class="modal  modal-alert {{errorLog == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title" *ngIf="successDataCount>0">{{successDataCount}} arrears move to {{msgText}} successfully.</h4>
          <h4 class="modal-title" *ngIf="successDataCount==0">No arrears uploaded</h4>
          </div>
          <div *ngIf="failedData.length>0" class="modal-body">
                  <p class="mb-0">{{failedData.length}} error data found.</p>
                  <p class="mb-0">Click on download to view the error details</p>
          </div>
          <div  class="modal-footer">
              <button *ngIf="failedData.length>0" type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2" (click)="downloadError();">Download</button>
              <button type="button" class="btn btn-outline-primary btn-sm" (click)="errorLog = false;">Close</button>
          </div>
      </div>
  </div>
</div> -->


<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg"></app-info-popup>
</ng-container>
<app-error-download [fileName]="'Arrear Error'" [successDataCount]="successDataCount" [downloadMsg]="errorLog"
  [failedData]="exportData" [modalHeading]="'Arrear Upload'" (closePanel)="errorLog = false;" [successMsg]="successMsg"
  [errorMsg]="errorMsg"> </app-error-download>

<!-- prior payroll import -->
<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>PRIOR ARREAR</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="title mb-24">{{month}} {{year}}</div>
              <div class="row" *ngIf="fileName">
                <div class="col-md-6">
                  <div class="card card-c2 bg-secondary  mb-24">
                    <div class="d-flex p-24 ">
                      <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                      <span class="text-trim fw-500">{{fileName}}</span>
                      <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                      <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()"><i
                          class="bi bi-trash ms-8 "></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                  (change)="onFileChange($event)" id="upload" hidden>
                Import Prior Arrear
              </label>

            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="title mb-24">Instructions </div>
              <ol class="fs-14 ps-16 mb-0 li-24">
                <li>Download the <a class="link-primary" href="assets/docs/sample-prior-arrear.xlsx"> <i
                      class="icon-download me-1"></i> Sample Excel
                    template</a></li>
                <li>Enter the prior arrear details in the excel as per the format provided in the excel sheet.</li>
                <li>Save the file.</li>
                <li>To import the file click the "Import prior arrear" button.</li>
                <li>Choose the file to be imported and click on the upload button.</li>
                <li>The file chosen should be in (.xls/.csv)format only.</li>
              </ol>
            </div>
          </div>
        </div>

      </div>
    </div>
    <footer class="side-panel-footer gap-32 pt-16">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
        (click)="uploadPrior()">Upload</button>
    </footer>
  </div>

</div>
<app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted"
  [isProgressStart]="isProgressStart" [heading]="'Uploading'"></app-upload-progress-bar>
  <app-error-download [fileName]="'Prior arrear revision error'" [successDataCount]="successDataCount" [downloadMsg]="revisionToggle"
  [failedData]="exportData" [modalHeading]="'Prior arrear revision'"
  (closePanel)="revisionToggle = false;showSidePanel=false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg">
</app-error-download>

<div class="modal  modal-alert {{errorToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Error Occured</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{errorMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="errorToggle = false;">Close</button>
      </div>
    </div>
  </div>
</div>
