import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {ApprovalMatrixService} from 'src/app/@core/services/approval-matrix.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-approval-matrix',
  templateUrl: './approval-matrix.component.html',
  styleUrls: ['./approval-matrix.component.scss']
})
export class ApprovalMatrixComponent implements OnInit {
  rightsapprove : any=[];
  allocatapprove : any=[];
  showSidePanel = false;
  viewapprovmatrixdata:any=[];
  loader =  true;
  items:any[] = [];
  itemsList:any[] = [];
  matrixDropdown:any[] = [];
  status:any = true;
  defaultlimit:any = 20;
  offset = 0;
  infinityloader  = false;
  direction = '';
  listlength = 0;
  nodata = false;
  deleteAlert = false;
  deleteId = 0;
  searchString = this.messageService.searchdisplay('approval matrix');
  loader1      = false;
  permissions:any = [];
  appkey1:any             = [];
  assignees:any           = [];
  deleteClicked = false
  searchKeyword="";
  listType = "active"
  @ViewChild(SearchbarComponent) child:any;

  isLoading = false
  searchData : Subject<void> = new Subject();

  constructor(private ApprovalMatrixService: ApprovalMatrixService, public breakpointObserver: BreakpointObserver,public router: Router,public messageService : MessageService,public appService:AppService
    ) { }

  ngOnInit(): void {
    this.getPermission();
    this.offset = 0;
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;

      }
    });
    this.getList();

    this.searchData.pipe(delay(1000),
    switchMap(()=> this.ApprovalMatrixService.ApprovalMatrixList(this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

/********* STATUS FILTER **************/
selectItemList(value:any){
  this.loader = true;
  this.status = value;
  this.offset = 0;
  this.items = [];
  // this.getList();
  this.child.clearBar();
}
response:any

getList(){
  this.response = this.ApprovalMatrixService.ApprovalMatrixList(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
    this.arrangeApiData(res)
  });
}

arrangeApiData(res:any){
  this.isLoading = false
  this.itemsList = res.results;
  this.listlength = res.results.length;
  if(this.offset ==0){
    this.items = [];
  }
  this.infinityloader = false;
  this.addItems(this.listlength);
  this.loader = false;
  this.nodata = res.count==0?true:false;
}
 /********* INIFNITY SCROLL DATA CREATION ****************/
 addItems(itemlist:any) {
  for (let i = 0; i < itemlist; ++i) {
    this.items.push(this.itemsList[i]);
  }
}
 /********* INIFNITY SCROLL FUNCTION *******************/
 onScrollDown() {
  this.infinityloader = true;
  this.offset += this.defaultlimit;
  if(this.listlength){
    this.getList();
    this.loader       = false;
    this.direction    = "down";
  }else{
    this.infinityloader = false;
  }
}
/********* INIFNITY SCROLL DATA CREATION ****************/
  editMatrix(id:number){
    this.router.navigate(['/approval-matrix/setup-approval-matrix/'+id]);
  }

  deleteMatrix(id:number){
    this.deleteClicked = true
    let data = { "is_active": false};
    this.ApprovalMatrixService.deleteApproval(id,data).subscribe((res: any) => {
      this.deleteAlert = false;
      this.loader = true;
      this.items = [];
      this.offset = 0;
      this.getList();
      this.deleteClicked = false
    });
  }
  filterMatrix(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.items = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }
  viewdataapproval(id:any){
    if(this.permissions.v){
      this.showSidePanel = true;
      this.assignees     = [];
      this.ApprovalMatrixService.getApprovalview(id).subscribe((res: any) => {
      this.viewapprovmatrixdata = res;
      this.rightsapprove= this.viewapprovmatrixdata.rights[0];
      for(let key of res.rights){
        let appkey = key.applicable.toLowerCase();
        if(appkey == "bu"){
          appkey = "businessunit";
          key.applicable ="BU"
        }
        this.appkey1 = appkey.toString();
        if(appkey=="employee"){
          let empName = key[this.appkey1]['first_name']+(key[this.appkey1]['middle_name']!=null && key[this.appkey1]['middle_name']!=""?" " +key[this.appkey1]['middle_name']:"")+" "+key[this.appkey1]['last_name'] +" ("+key[this.appkey1]['employee_code']+")";
          let empImg  = key[this.appkey1]['profile_image'];
          let color_code  = key[this.appkey1]['color_code'];
          this.assignees.push({'name': empName,'img': empImg, 'color_code':color_code})
        }else if(appkey=="company"){
          let compName = key.company;
          let compImg  = key.company_logo;
          this.assignees.push({'name': compName,'img': compImg})
        }else{
        this.assignees.push(key[this.appkey1]);
        }
      }
      this.loader1=false
      this.allocatapprove= this.viewapprovmatrixdata.allocation[0];
      })
    }
  }
  searchmatrix(searchKeyword:any){
    this.isLoading = true
    this.loader = true;
    this.offset            = 0;
    this.items = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/approval-matrix/setup-approval-matrix/']);
  }
}
