
<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Salary Reconciliation  - {{month}} {{year}}</h5>
        <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
    </header>

    <main class=" config-container">
        <app-c1-loader  *ngIf="loader1 || loader2"></app-c1-loader>
        <ng-container class="" *ngIf="!loader1 && !loader2" >
            <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab1" class="nav radius-4 nav-custom shadow-0 p-0" style="min-height: unset;" (navChange)="onNavChange($event)">
                <li [ngbNavItem]="1" >
                    <a ngbNavLink class="nav-link h-100 py-16">Salary Summary</a>
                    <ng-template ngbNavContent>
                        <app-reconcilation-view [issalsumm]="true" [salarysummaryList]="salarysummaryList" [month]="month" [year]="year" [searchString]="searchString" [page]="page" [pageSize]="pageSize"></app-reconcilation-view>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" >
                    <a ngbNavLink class="nav-link h-100 py-16">Employee Salary Summary</a>
                    <ng-template ngbNavContent>
                        <app-reconcilation-view [isempsalsumm]="true" [empsalarysummaryList]="empsalarysummaryList" [month]="month" [year]="year" [searchString]="searchString" [page]="page" [pageSize]="pageSize"></app-reconcilation-view>
                    </ng-template>
                </li>

            </nav>

            <div class="container-fluid p-32 form-container">
                <div class="row mb-24">
                    <div class="col-md-4">
                        <p class="text-uppercase fw-500 mb-0">Salary reconciliation  - {{month}} {{year}}</p>
                    </div>
                    <div class="col-md-8">
                        <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
                            <div style="max-width: 363px">
                                <div class="form-icon icon-left">
                                    <input type="text" class="form-control fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}   " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1" autocomplete="off">
                                    <i class="icon-search"></i>
                                </div>
                            </div>
                            <button class="btn-square btn btn-secondary" (click)="filter  = true">
                                <i class="icon-filter-left fs-13"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row row-24">
                    <div class="col-12">

                                <div [ngbNavOutlet]="nav" ></div>


                    </div>
                </div>
            </div>
        </ng-container>
    </main>
</div>
<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
