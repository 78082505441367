import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { saveAs } from 'file-saver';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-import-setup',
  templateUrl: './employee-import-setup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class EmployeeImportSetupComponent implements OnInit {
  activeTab = 1;
  selectedDataTypes:any = [];
  selectedUploadType:any = [];
  selectedDTIndex:any = -1;
  selectedUTIndex:any = -1;
  fileDownloaded = false;
  existing = false;
  downloadMsg = false;
  failedData:any = [];
  successDataCount = 0;
  headerRow:any = [];
  initialHeader:any = [];
  dataArranged:any[]= [];
  errorData:any[]= [];
  errorCount = 0;
  deleteToggle = false;
  isSave = false;
  deleteMessage="";
  isupload = 0;
  isDataUpload = 0;
  percentage:any = 0;
  iscompleted = false;
  isProgressStart = false
  chunkLen = 10
  errorMsg = ''
  successMsg = ''
  body = '';
  constructor(private es : EmployeeService,public changeDetectorRef: ChangeDetectorRef,private notificationService: NotificationService,public router: Router, public appservice:AppService, public datePipe:DatePipe) {  }
  datatype= [{
    name:'Snapshot',
    value: 'snapshot',
    icon:'icon-person-lines-fill text-danger',
    bg:'bg5',
    status:false,

  },{
    name:'Professionals',
    value: 'professional',
    icon:'icon-briefcase-grid text-accent1 ',
    bg:'bg6',
    status:false,

  },{
    name:'Education',
    value: 'education',
    icon:'icon-graduation-cap text8',
    bg:'bg7',
    status:false,

  },{
    name:'Personal',
    value: 'personal',
    icon:'icon-person2 text-accent2',
    bg:'bg8',
    status:false,

  },{
    name:'Family',
    value: 'family',
    icon:'icon-family text14',
    bg:'bg9',
    status:false,

  },{
    name:'Health Insurance',
    value: 'health',
    icon:'icon-shield-plus text13',
    bg:'bg10',
    status:false,

  },{
    name:'Previous employer',
    value: 'prev_employer',
    icon:'icon-person-badge-settings text12',
    bg:'bg11',
    status:false,

  },{
    name:'Bank Details',
    value: 'bank',
    icon:'icon-bank text11',
    bg:'bg12',
    status:false,

  },
  // {
  //   name:'Documents',
  //   value: 'documents',
  //   icon:'icon-documents.svg',
  //   bg:'bg13',
  //   status:false,
  // },
  ];
  uploadtype= [{
    name:'Upload new',
    icon:'icon-arrow2-up-circle text12',
    bg:'bg11',
    status:false,
  },{
    name:'Update existing',
    icon:'icon-clock-repeat text12',
    bg:'bg11',
    status:false,
  },
  ];
  data:any = []
  initialdata:any = [];
  fileName = "";
  fileSize = "";
  uploadSuccess = false

  ngOnInit(): void {
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.selectedDTIndex == -1) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 3) {
      if (this.selectedUTIndex == -1) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 4) {
      if (this.selectedUTIndex == -1) {
        changeEvent.preventDefault();
      }else {
        this.isupload =0
        this.isDataUpload = 0;
    }
    } else  if (changeEvent.nextId === 5) {
      if (this.fileName == "" || this.selectedUTIndex == -1) {
        changeEvent.preventDefault();
      }
    }
  }

  selectDataType(selectedIndex:any){
    if(this.datatype[selectedIndex]['status'] == false){
      this.datatype[selectedIndex]['status']=true;
      if( this.selectedDTIndex >=0)
        this.datatype[this.selectedDTIndex]['status']=false;
      this.selectedDataTypes = this.datatype[selectedIndex]['value'];
      this.selectedDTIndex = selectedIndex;
    } else {
      this.datatype[selectedIndex]['status']=false;
      this.selectedDataTypes = null;
      this.selectedDTIndex = -1;
    }
    this.fileName = '';
    this.data = [];
    this.fileSize = '';
    let fD =  this.fileDownloaded
    this.readData({data: this.data , fileName: this.fileName,fileSize:this.fileSize});
    this.fileDownloaded = fD;
  }

  selectUploadType(selectedIndex:any){
    if(this.uploadtype[selectedIndex]['status'] == false){
      this.uploadtype[selectedIndex]['status']=true;
      if( this.selectedUTIndex >=0)
        this.uploadtype[this.selectedUTIndex]['status']=false;
      this.selectedUploadType = this.uploadtype[selectedIndex]['name'];
      this.selectedUTIndex = selectedIndex;
      if(this.selectedUploadType == "Update existing")
        this.existing = true;
      else
        this.existing = false;
    } else {
      this.uploadtype[selectedIndex]['status']=false;
      this.selectedUploadType = null;
      this.selectedUTIndex = -1;
      this.existing = false;
    }
    this.fileName = '';
    this.data = [];
    this.fileSize = '';
    let fD =  this.fileDownloaded
    this.readData({data: this.data , fileName: this.fileName,fileSize:this.fileSize});
    this.fileDownloaded = fD;
    this.dataArranged = [];
  }
  changeTab(tabIndex:number){
    if(tabIndex == 4){
      this.isupload =0
      this.isDataUpload = 0;
    }
    this.activeTab = tabIndex;
  }
  downloadTemplate(){

    this.fileDownloaded = true;

    this.es.downloadSnapshotTemplate(this.existing,this.selectedDataTypes).subscribe(fileData => {
      this.downloadFile(fileData,"template");
    });
  }
  downloadFile(fileData:any,type:string) {
    const blob: any = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      if(type == "template"){
        if(this.existing)
          var tempName = this.selectedDataTypes+"_existing_"+Date.now()
        else
          var tempName = this.selectedDataTypes+"_new_"+Date.now()
      } else
      var tempName = this.selectedDataTypes+"_error_"+Date.now()
      link.setAttribute("download", tempName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  readData(data:any){
    this.dataArranged = [];
    this.fileDownloaded = true;
    this.data = data.data;
    this.fileName = data.fileName;
    this.fileSize = data.fileSize;
    if(this.data.length){
      this.headerRow = this.data.shift();
      this.initialHeader = this.headerRow.slice(0);
      if(this.selectedDataTypes == "family"){
        this.initialdata = this.data.slice(0);
      }
      this.validateData();
    }
  }
  validateData(){


    if(this.selectedDataTypes == "snapshot")
      this.validateSnapShotData();
    else if(this.selectedDataTypes == "health")
      this.validateInsureData();
    else if(this.selectedDataTypes == "prev_employer")
      this.validatePreEmpData();
    else if(this.selectedDataTypes == "education")
      this.validateEducationData();
    else if(this.selectedDataTypes == "bank")
      this.validateBankData();
    else if(this.selectedDataTypes == "professional")
      this.validateProfessionalData();
    else if(this.selectedDataTypes == "family"){
      this.headerRow = this.initialHeader.slice(0);
      this.data = this.initialdata.slice(0);
      this.validateFamilyData();
    }
    else if(this.selectedDataTypes == "personal")
      this.validatePersonalData();
  }
  validateFamilyData(){
    var childKeyArray=this.arraySearch("Children Name");
    var depkeyArray=this.arraySearch("Dependent Name");
    var childArray:any = [];
    var depArray:any = [];
    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      childArray = [];
      depArray = [];
      childKeyArray.forEach(element => {
        if(item[element]  || item[element+1] || item[element+2] || item[element+3] || item[element+4] || item[element+5] ){
        childArray.push({'child_name':item[element]?item[element]:"",
                'child_date_of_birth':item[element+1]?this.dateFormatConvert(item[element+1]):"",
                'child_gender':item[element+2]?item[element+2]:"",
                'is_dependent':item[element+3]?item[element+3]:"",
                'is_going_to_school':item[element+4]?item[element+4]:"",
                'is_going_to_hostel':item[element+5]?item[element+5]:"",
        });
        }
      });
      depkeyArray.forEach(element => {
        if(item[element]  || item[element+1] || item[element+2] || item[element+3] || item[element+4] ){
        depArray.push({'dependent_name':item[element]?item[element]:"",
                'dependent_of_birth':item[element+1]?this.dateFormatConvert(item[element+1]):"",
                'dependent_gender':item[element+2]?item[element+2]:"",
                'dependent_relation':item[element+3]?item[element+3]:"",
                'dependent_remark':item[element+4]?item[element+4]:"",

        });}
      });
      dataObject = {
        "children" : childArray,
        "dependent_detail" : depArray,
        "employee": item[0]?item[0]:"",
        "first_name" : item[1]?item[1]:"",
        "last_name" : item[2]?item[2]:"",
        "father_name"	: item[3]?item[3]:"",
        "father_date_of_birth"	:item[4]?this.dateFormatConvert(item[4]):"",
        "mother_name"	: item[5]?item[5]:"",
        "mother_date_of_birth"	:item[6]?this.dateFormatConvert(item[6]):"",
        "marital_status": item[7]?item[7]:"",
        "date_of_marriage":item[8]?this.dateFormatConvert(item[8]):"",
        "spouse_name": item[9]?item[9]:"",
        "spouse_date_of_birth":item[10]?this.dateFormatConvert(item[10]):"",

      };
      dataArray.push(dataObject);
    });
    var inputData = {data:dataArray};

    this.es.validateFamily(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeFamilyData();
    });
  }

  arrangeFamilyData(){
    this.dataArranged = [];
    var keyt = "";
    this.errorCount = 0;
    this.headerRow = this.initialHeader.slice(0);

    this.data.forEach((key:any, value:any) => {

      // key = key.filter(function(x:any){
      //   return x != "";
      // });
      if(key.length>0){
        this.dataArranged[value] = [];
        var keyC = 0
        var keyD = 0
        var header = this.headerRow.slice(0);

        header.forEach((k:any, val:any) => {
          this.dataArranged[value]['rowNum'] = value;
          if(val < 11){
            this.dataArranged[value][k] = key[val];
            if(k == "Employee Code")
              keyt = "employee";
            else if(k == "Father's Name")
              keyt = "father_name";
            else if(k == "Father's DOB")
              keyt = "father_date_of_birth";
            else if(k == "Mother's Name")
              keyt = "mother_name";
            else if(k == "Mother's DOB")
              keyt = "mother_date_of_birth";
            else if(k == 'Marital Status')
              keyt = "marital_status";
            else if(k == 'Date of Marriage')
              keyt = "date_of_marriage";
            else if(k == 'Spouse Name')
              keyt = "spouse_name";
            else if(k == 'Spouse DOB')
              keyt = "spouse_date_of_birth";
            else if(k == 'Last Name')
              keyt = "last_name";
            else if(k == 'First Name')
              keyt = "first_name";

            if(this.errorData[value][keyt] != null && this.errorData[value][keyt] != undefined){
              this.dataArranged[value][k] = "hasErrorData";
              this.errorCount++;
            } else if(key[val] == undefined || key[val] == ''){
              this.dataArranged[value][k] = "hasError";
              this.data[value][val] = null;
            }
          }   else if(val>=11){
            if(["Children Name","Children DOB","Children Gender","Is dependant","Is going to school","Is going to hostel"].includes(k)){
              if(key[val] != undefined && key[val] != ''){
                this.dataArranged[value][k+keyC] = key[val];
              }

              if(k=="Children Name"){
                keyt = "child_name";
              } else if(k=="Children DOB"){
                keyt = "child_date_of_birth";
              } else if(k=="Children Gender"){
                keyt = "child_gender";
              } else if(k=="Is dependant"){
                keyt = "is_dependent";
              } else if(k=="Is going to school"){
                keyt = "is_going_to_school";
              } else if(k == "Is going to hostel"){
                keyt = "is_going_to_hostel";
              }

              if(this.errorData[value]['children'][keyC] != undefined &&this.errorData[value]['children'][keyC][keyt] != undefined && this.errorData[value]['children'][keyC][keyt] != null  ){
                this.errorCount++;
              this.dataArranged[value][k+keyC] = "hasErrorData";
              }else if(key[val] == undefined || key[val] == '') {
                this.dataArranged[value][k+keyC] = "hasError";
              }
              if(value == this.data.length-1){
                this.headerRow[val] = k+keyC;
              }
              if(k == "Hostel"){
                keyC++;
              }
            } else  if(["Dependent Name","Dependent DOB","Dependent Gender","Dependent relationship","Dependent remarks"].includes(k)){
              if(key[val] != undefined && key[val] != ''){
                this.dataArranged[value][k+keyD] = key[val];
              }

              if(k=="Dependent Name"){
                keyt = "dependent_name"
              } else if(k=="Dependent DOB"){
              keyt = "dependent_of_birth";
              } else if(k=="Dependent Gender"){
                keyt = "dependent_gender";
              } else if(k=="Dependent relationship"){
                keyt = "dependent_relation";
              } else if(k=="Dependent remarks"){
                keyt = "dependent_remark";
              }
              if(this.errorData[value]['dependent_detail'][keyD] != undefined && this.errorData[value]['dependent_detail'][keyD][keyt] != undefined && this.errorData[value]['dependent_detail'][keyD][keyt] != null){
              this.errorCount++;
                this.dataArranged[value][k+keyD] = "hasErrorData";
              }  else if(key[val] == undefined || key[val] == ''){
                this.dataArranged[value][k+keyD] = "hasError";
              }
              if(value == this.data.length-1){
                this.headerRow[val] = k+keyD;
              }
              if(k == "Dependent remarks"){
                keyD++;
              }
            }
          }
        });
      }
    });

    if(this.errorCount > 0 && this.isupload == 1){
      this.confirmErrorUpload();
    } else if(this.isupload == 1) {
      this.uploadDatas();
    }
    this.data = this.data.filter(function(x:any){
      return x.length>0;
    });
    this.changeDetectorRef.detectChanges();
  }

  validateSnapShotData(){


    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      let fm = "";
      let rm = "";

      if(item[24] && item[24] != "" && typeof item[24] === 'string' || item[24] instanceof String) {
        let aEmpcode = item[24].split(" (");
        fm = aEmpcode[0]
      }
      if(item[23] && item[23] != "" && typeof item[23] === 'string' || item[23] instanceof String) {
        let aEmpcode = item[23].split(" (");
        rm = aEmpcode[0]
      }
        dataObject = {
          "prefix" : item[0]?item[0]:"",
          "first_name" : item[1]?item[1]:"",
          "last_name" : item[3]?item[3]:"",
          "middle_name" : item[2]?item[2]:"",
          "employee_code": item[4]?item[4]:"",
          "date_of_birth" : item[5]?this.dateFormatConvert(item[5]):"",
          "gender" : item[6]?item[6]:"",
          "blood_group" : item[7]?item[7]:"",
          "nationality" : item[8]?item[8]:"",
          "work_email" : item[9]?item[9]:"",
          "phone_code" : item[10]?item[10]:"",
          "mobile_number" : item[11]?item[11]:"",
          "date_of_joined" : item[12]?this.dateFormatConvert(item[12]):"",
          "employment_type" : item[13]?item[13]:"",
          "employment_status" : item[14]?item[14]:"",
          "date_of_confirmation" : item[15]?this.dateFormatConvert(item[15]):"",
          "skill_type" : item[16]?item[16]:"",
          "company" : item[17]?item[17]:"",
          "businessunit" : item[18]?item[18]:"",
          "department" : item[19]?item[19]:"",
          "designation" : item[20]?item[20]:"",
          "branch" : item[21]?item[21]:"",
          "grade" : item[22]?item[22]:"",
          // "city" : item[23]?item[23]:"",
          "reporting_manager" :rm,
          "functional_manager" : fm,
          "resignation_date" : item[25]?this.dateFormatConvert(item[25]):"",
          "notice_period" : item[26]?item[26]:"",
          "leaving_date" : item[27]?this.dateFormatConvert(item[27]):"",
          "reason_for_leaving" : item[28]?item[28]:"",
          "is_exclude_from_fnf" : item[29]?(item[29] == 'Yes' ? true : false):'',
        };
        dataArray.push(dataObject);
    });

    var inputData = {is_update:this.existing,data:dataArray};
    this.es.validateSnapShot(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeData();
    });
  }
  validatePersonalData(){
    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      dataObject = {
        "employee" : item[0]?item[0]:"",
        "first_name" : item[1]?item[1]:"",
        "last_name" : item[2]?item[2]:"",
        "pan_number" : item[3]?item[3]:"",
        "aadhar_number" : item[4]?item[4]:"",
        "passport_number" : item[5]?item[5]:"",
        "passport_expiry_date" : item[6]?this.dateFormatConvert(item[6]):"",
        "personal_email": item[7]?item[7]:"",
        "emergency_contact_name": item[8]?item[8]:"",
        "phone_code": item[9]?item[9]:"",
        "emergency_contact_number": item[10]?item[10]:"",
        "relation": item[11]?item[11]:"",
        "current_address": item[12]?item[12]:"",
        "current_country": item[13]?item[13]:"",
        "current_state": item[14]?item[14]:"",
        "current_city": item[15]?item[15]:"",
        "current_pin": item[16]?item[16]:"",
        "permanent_address": item[17]?item[17]:"",
        "permanent_country": item[18]?item[18]:"",
        "permanent_state": item[19]?item[19]:"",
        "permanent_city": item[20]?item[20]:"",
        "permanent_pin": item[21]?item[21]:"",
      };
      dataArray.push(dataObject);
    });
    var inputData = {is_update : this.existing,data:dataArray};

    this.es.validatePersonal(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeData();
    });
  }

  validateInsureData(){
    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      dataObject = {
        "employee" : item[0]?item[0]:"",
        "first_name" : item[1]?item[1]:"",
        "last_name" : item[2]?item[2]:"",
        "insuree_name" : item[3]?item[3]:"",
        // "relationship" : item[4]?item[4]:"",
        "insuree_of_birth" : item[4]?this.dateFormatConvert(item[4]):"",
        "insuree_gender": item[5]?item[5]:"",
        "insurance_code" : item[6]?item[6]:"",
        "assured_sum" : item[7]?item[7]:"",
        "insurance_company" : item[8]?item[8]:"",
        "company_code" : item[9]?item[9]:"",
        "issue_date" : item[10]?this.dateFormatConvert(item[10]):"",
        "valid_upto" : item[11]?this.dateFormatConvert(item[11]):""
      };
      dataArray.push(dataObject);
    });
    var inputData = {data:dataArray};

    this.es.validateHealth(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeData();
    });

  }
  validateBankData(){
    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      dataObject = {
        "employee" : item[0]?item[0]:"",
        "first_name" : item[1]?item[1]:"",
        "last_name" : item[2]?item[2]:"",
        "salary_pay_mode" : item[3]?item[3]:"",
        "account_number" : item[4]?item[4]:"",
        "bank_name" : item[5]?item[5]:"",
        "account_holdername" : item[6]?item[6]:"",
        "ifsc_code" : item[7]?item[7]:"",
        "branch_name" : item[8]?item[8]:"",
        "branch_address" : item[9]?item[9]:"",
      };
      dataArray.push(dataObject);
    });
    var inputData = {data:dataArray};

    this.es.validateBank(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeData();
    });
  }
  validateEducationData(){
    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      dataObject = {
        "employee" : item[0]?item[0]:"",
        "first_name" : item[1]?item[1]:"",
        "last_name" : item[2]?item[2]:"",
        "qualification" : item[3]?item[3]:"",
        "specialization" : item[4]?item[4]:"",
        "year_of_passing" : item[5]?this.dateFormatConvert(item[5]):"",
        "university_board" : item[6]?item[6]:"",
        "college_school": item[7]?item[7]:"",
        "grade_cgpa": item[8]?item[8]:""
      };
      dataArray.push(dataObject);
    });
    var inputData = {data:dataArray};

    this.es.validateEducation(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeData();
    });
  }

  validateProfessionalData(){
    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      dataObject = {
        "employee" : item[0]?item[0]:"",
        "first_name" : item[1]?item[1]:"",
        "last_name" : item[2]?item[2]:"",
        "previous_company" : item[3]?item[3]:"",
        "previous_designation" : item[4]?item[4]:"",
        "from_date" : item[5]?this.dateFormatConvert(item[5]):"",
        "to_date" : item[6]?this.dateFormatConvert(item[6]):"",
        "job_description": item[7]?item[7]:""
      };
      dataArray.push(dataObject);
    });
    var inputData = {data:dataArray};

    this.es.validateProfessional(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeData();
    });
  }

  validatePreEmpData(){
    var dataArray:any = [];
    var dataObject = {}
    this.data.forEach((item:any, value:any) => {
      dataObject = {
                "employee" : item[0]?item[0]:"",
                "first_name" : item[1]?item[1]:"",
                "last_name" : item[2]?item[2]:"",
                "income_after_exemption" : item[3]?item[3]:0,
                // "tax_deducted_by" : item[4]?item[4]:0,
                "exempted_gratuity" : item[4]?item[4]:0,
                "exempted_encashed_leave": item[5]?item[5]:0,
                "pf_amount": item[6]?item[6]:0,
                "pt_amount": item[7]?item[7]:0,
                "nps_deduction": item[8]?item[8]:0,
                "income_tax": item[9]?item[9]:0,
                "surcharge": item[10]?item[10]:0,
                "cess": item[11]?item[11]:0,
      };
      dataArray.push(dataObject);
    });
    var inputData = {data:dataArray};

    this.es.validatePreEmployer(inputData).subscribe((res: any) => {
      this.errorData = res.error_info;
      this.arrangeData();
    });
  }
  arrangeData(){
    this.dataArranged = [];
    var keyt = "";
    this.errorCount = 0;

    this.data.forEach((key:any, value:any) => {

      if(key.length>0){
        this.dataArranged[value] = [];
        this.headerRow.forEach((k:any, val:any) => {
          this.dataArranged[value]['rowNum'] = value;
          keyt = k.split(' ').join('_');
          if(keyt.toLowerCase() == "date_of_joining"){
            keyt = "date_of_joined";
          } else if (keyt.toLowerCase() == "business_unit"){
            keyt = "businessunit";
          }else if(keyt == 'Grade/CGPA')
            keyt = "grade_cgpa";
          else if(keyt == 'College/School')
            keyt = "college_school";
          else if(keyt == 'Tax_Deducted_by_previous_employer')
            keyt = "tax_deducted_by";
          else if(keyt == 'Account_holder_Name')
          keyt = "account_holdername";
          else if(keyt == 'Insuree_date_of_birth')
            keyt = "insuree_of_birth";
          else if(keyt == "Employee_Code" && this.selectedDataTypes != "snapshot")
            keyt = "employee";
          else if(keyt == "Exempted_gratuity_amount")
            keyt = "exempted_gratuity";
          else if(keyt == "Exempted_leave_encashment_amount")
            keyt = "exempted_encashed_leave";
          else if(keyt == "PF_amount_deducted")
            keyt = "pf_amount";
          else if(keyt == "Professional_tax_amount_deducted")
            keyt = "pt_amount";
          else if(keyt == "Employer_NPS_deduction_80CCD(2)")
            keyt = "nps_deduction";
          else if(keyt == 'Mobile_number_Country_code'){
            keyt = 'phone_code'
          }
          if(key[val] != undefined && key[val] != ''){
            this.dataArranged[value][k] = key[val];
          }

          if(this.errorData != undefined && (this.errorData[value][keyt.toLowerCase()] != null || this.errorData[value][keyt.toLowerCase()] != undefined)){
            this.dataArranged[value][k] = "hasErrorData";
            this.errorCount++;
          }  else if(key[val] == undefined || key[val] == ''){
              this.dataArranged[value][k] = "hasError";
              this.data[value][val] = null;
          }

        });
      }

    });

    if(this.errorCount > 0 && this.isupload == 1){
      this.confirmErrorUpload();
    } else if(this.isupload == 1) {
      this.uploadDatas();
    }
    this.changeDetectorRef.detectChanges();
    this.data = this.data.filter(function(x:any){
      return x.length>0;
    });

  }

  deleteRows(deleteRows:any){
    var filtered:any = [];
    var delArray:any = [];
    var errorfiltered:any = [];
    deleteRows.forEach((item:any, value:any) => {
      if(item == true)
        delArray.push(value);
    });

    var delLen = delArray.length;
    filtered = this.data.filter(function(val:any, index:any, arr:any){
      if(delArray.includes(index) == false) return val;
    });
    errorfiltered =this.errorData.filter(function(val:any, index:any, arr:any){
      if(delArray.includes(index) == false) return val;
    });
    this.errorData = errorfiltered
    this.data = [];
    this.data = filtered;
    if(this.data.length == 0){
      this.fileName = '';
      this.data = [];
      this.fileSize = '';
      this.readData({data: this.data , fileName: this.fileName,fileSize:this.fileSize});
      this.activeTab = 4;
    }

    if(this.selectedDataTypes == "family"){
      this.initialdata = this.data.slice(0);
      this.arrangeFamilyData();
    }else
      this.arrangeData();
    this.changeDetectorRef.detectChanges();
    this.notificationService.handleSuccessNotification(delLen+" rows deleted successfully.","Success");
  }
  dateFormatConvert(dateData:any){
    if(dateData != null){
      if(dateData instanceof moment ){
        // return this.appservice.dateFormatConvert(date)
        return this.convertDate(dateData)
      } else if(dateData instanceof Date){
        moment(dateData).add(1, 'minute');
        return this.convertDate(dateData)
      } else
        return dateData;
    } else return null;
  }
  convertDate(date:any){
    return this.datePipe.transform(date, 'dd-MM-yyyy')
  }

  downloadError(){
    this.es.downloadErrorFile({"import": this.selectedDataTypes,"error": this.failedData}).subscribe(fileData => {
      this.downloadFile(fileData,"error");
      this.downloadMsg = false;
      // this.router.navigate(['employee']);
      this.clearData()
      this.changeDetectorRef.detectChanges();
    });
  }
  uploadData(){
    this.isupload = 1;
    this.validateData();
    // if(this.errorCount > 0){
    //   this.confirmErrorUpload();
    // } else {
    //   this.uploadDatas();
    // }
  }

  confirmErrorUpload(){
    this.isupload = 0;
    this.deleteToggle = true;
    this.isSave = true;
    this.deleteMessage=this.errorCount+' Error data found. Do you still want to proceed without corrections?';
  }

  uploadDatas(){
    this.isDataUpload = 1;
    this.deleteToggle = false;
    this.body = ''
    if(this.selectedDataTypes == "snapshot")
      this.uploadSnapShotData();
    else if(this.selectedDataTypes == "health")
      this.uploadInsureData();
    else if(this.selectedDataTypes == "prev_employer")
      this.uploadPreEmpData();
    else if(this.selectedDataTypes == "education")
      this.uploadEducationData();
    else if(this.selectedDataTypes == "bank")
      this.uploadBankData();
    else if(this.selectedDataTypes == "professional")
      this.uploadProfessionalData();
    else if(this.selectedDataTypes == "family")
      this.uploadFamilyData();
    else if(this.selectedDataTypes == "personal")
      this.uploadPersonalData();

  }


    processPersonalUpload(k:any,p:any){

        var dataObject = {}
        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){


            var item = this.data[i]
            dataObject = {
                "employee" : item[0]?item[0]:"",
                "first_name" : item[1]?item[1]:"",
                "last_name" : item[2]?item[2]:"",
                "pan_number" : item[3]?item[3]:"",
                "aadhar_number" : item[4]?item[4]:"",
                "passport_number" : item[5]?item[5]:"",
                "passport_expiry_date" : item[6]?this.dateFormatConvert(item[6]):"",
                "personal_email": item[7]?item[7]:"",
                "emergency_contact_name": item[8]?item[8]:"",
                "phone_code": item[9]?item[9]:"",
                "emergency_contact_number": item[10]?item[10]:"",
                "relation": item[11]?item[11]:"",
                "current_address": item[12]?item[12]:"",
                "current_country": item[13]?item[13]:"",
                "current_state": item[14]?item[14]:"",
                "current_city": item[15]?item[15]:"",
                "current_pin": item[16]?item[16]:"",
                "permanent_address": item[17]?item[17]:"",
                "permanent_country": item[18]?item[18]:"",
                "permanent_state": item[19]?item[19]:"",
                "permanent_city": item[20]?item[20]:"",
                "permanent_pin": item[21]?item[21]:"",
            };
            dataArray.push(dataObject);
        }
        var inputData = {data:dataArray};
        this.es.savePersonal(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
            if(res.failed_data.length != 0){
            res.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
            }
            this.percentage +=((p/4)*3);
            if(x<l)
            this.processPersonalUpload(k+1,p);
            else{
              this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
               setTimeout(() => {
                this.iscompleted = true
                this.isProgressStart = false
              }, 3000);
              if( this.failedData.length == 0){
                // this.uploadSuccess = true;
                  this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
                  this.clearData()
                  // this.router.navigate(['employee']);
              } else {
                  this.downloadMsg = true;
                  // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully"
                  // this.errorMsg = this.failedData.length+' employee(s) have error data'
              }
            }
            this.body = x+' out of '+l+" employee data uploaded successfully"
            this.changeDetectorRef.detectChanges();
        });

    }

    uploadPersonalData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processPersonalUpload(0,p);
    }

    processSnapShotUpload(k:any,p:any){

        var dataObject = {}
        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){


        var item = this.data[i]
        let fm = "";
        let rm = "";
        if(item[24] && item[24] != "" && typeof item[24] === 'string' || item[24] instanceof String) {
          let aEmpcode = item[24].split(" (");
          fm = aEmpcode[0]
        }
        if(item[23] && item[23] != "" && typeof item[23] === 'string' || item[23] instanceof String) {
          let aEmpcode = item[23].split(" (");
          rm = aEmpcode[0]
        }
        dataObject = {
            "prefix" : item[0]?item[0]:"",
            "first_name" : item[1]?item[1].trim():"",
            "middle_name" : item[2]?item[2].trim():"",
            "last_name" : item[3]?item[3].trim():"",
            "employee_code": item[4]?item[4]:"",
            "date_of_birth" : item[5]?this.dateFormatConvert(item[5]):"",
            "gender" : item[6]?item[6]:"",
            "blood_group" : item[7]?item[7]:"",
            "nationality" : item[8]?item[8]:"",
            "work_email" : item[9]?item[9]:"",
            "phone_code" : item[10]?item[10]:"",
            "mobile_number" : item[11]?item[11]:"",
            "date_of_joined" : item[12]?this.dateFormatConvert(item[12]):"",
            "employment_type" : item[13]?item[13]:"",
            "employment_status" : item[14]?item[14]:"",
            "date_of_confirmation" : item[15]?this.dateFormatConvert(item[15]):"",
            "skill_type" : item[16]?item[16]:"",
            "company" : item[17]?item[17]:"",
            "businessunit" : item[18]?item[18]:"",
            "department" : item[19]?item[19]:"",
            "designation" : item[20]?item[20]:"",
            "branch" : item[21]?item[21]:"",
            "grade" : item[22]?item[22]:"",
            "reporting_manager" : rm,
            "functional_manager" : fm,
            "resignation_date" : item[25]?this.dateFormatConvert(item[25]):"",
            "notice_period" : item[26]?item[26]:"",
            "leaving_date" : item[27]?this.dateFormatConvert(item[27]):"",
            "reason_for_leaving" : item[28]?item[28]:"",
            "is_exclude_from_fnf" : item[29]?(item[29] == 'Yes' ? true : false):false,
            };
            dataArray.push(dataObject);
        }
        var inputData = {is_update:this.existing,data:dataArray};
        this.es.saveSnapShot(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
        if(res.failed_data?.length != 0){
            res.failed_data?.forEach((i:any, k:any) => {
            this.failedData.push(i)
            });
        }
        if(res.error != undefined && res.error != ""){
          this.notificationService.handleErrorNotification(res.error,"Backend Error Occured");
        }
        this.percentage +=((p/4)*3);
        if(x<l)
            this.processSnapShotUpload(k+1,p);
        else{
            this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
            setTimeout(() => {
              this.iscompleted = true
              this.isProgressStart = false
            }, 3000);
            if( this.failedData.length == 0){
              // this.uploadSuccess = true;
              this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
              this.clearData()
              // this.router.navigate(['employee']);
            } else {
              this.downloadMsg = true;
              // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully"
              // this.errorMsg = this.failedData.length+' employee(s) have error data'
            }
        }
        this.body = x+' out of '+l+" employee data uploaded successfully"
        this.changeDetectorRef.detectChanges();
        });

    }

    uploadSnapShotData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processSnapShotUpload(0,p);
    }

    processInsureUpload(k:any,p:any){

        var dataObject = {}
        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){


            var item = this.data[i]
            dataObject = {
                "employee" : item[0]?item[0]:"",
                "first_name" : item[1]?item[1]:"",
                "last_name" : item[2]?item[2]:"",
                "insuree_name" : item[3]?item[3]:"",
                "insuree_of_birth" : item[4]?this.dateFormatConvert(item[4]):"",
                "insuree_gender": item[5]?item[5]:"",
                "insurance_code" : item[6]?item[6]:"",
                "assured_sum" : item[7]?item[7]:"",
                "insurance_company" : item[8]?item[8]:"",
                "company_code" : item[9]?item[9]:"",
                "issue_date" : item[10]?this.dateFormatConvert(item[10]):"",
                "valid_upto" : item[11]?this.dateFormatConvert(item[11]):""
            };
            dataArray.push(dataObject);
        }
        var inputData = {data:dataArray};
        this.es.saveHealth(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
            if(res.failed_data.length != 0){
            res.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
            }
            this.percentage +=((p/4)*3);
            if(x<l)
            this.processInsureUpload(k+1,p);
            else{
              this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
              setTimeout(() => {
                this.iscompleted = true
                this.isProgressStart = false
              }, 3000);
              if( this.failedData.length == 0){
                // this.uploadSuccess = true;
                  this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
                  this.clearData()
                  // this.router.navigate(['employee']);
              } else {
                  this.downloadMsg = true;
                  // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                  // this.errorMsg = this.failedData.length+' employee(s) have error data'
              }
            }
            this.body = x+' out of '+l+" employee data uploaded successfully"
            this.changeDetectorRef.detectChanges();
        });

    }

    uploadInsureData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processInsureUpload(0,p);
    }


    processPreEmpUpload(k:any,p:any){

        var dataObject = {}
        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){


            var item = this.data[i]
            dataObject = {
                "employee" : item[0]?item[0]:"",
                "first_name" : item[1]?item[1]:"",
                "last_name" : item[2]?item[2]:"",
                "income_after_exemption" : item[3]?item[3]:0,
                // "tax_deducted_by" : item[4]?item[4]:"",
                "exempted_gratuity" : item[4]?item[4]:0,
                "exempted_encashed_leave": item[5]?item[5]:0,
                "pf_amount": item[6]?item[6]:0,
                "pt_amount": item[7]?item[7]:0,
                "nps_deduction": item[8]?item[8]:0,
                "income_tax": item[9]?item[9]:0,
                "surcharge": item[10]?item[10]:0,
                "cess": item[11]?item[11]:0,
            };
            dataArray.push(dataObject);
        }
        var inputData = {data:dataArray};
        this.es.savePreEmployer(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
            if(res.failed_data.length != 0){
            res.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
            }
            this.percentage +=((p/4)*3);
            if(x<l)
            this.processPreEmpUpload(k+1,p);
            else{
              this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
               setTimeout(() => {
                this.iscompleted = true
                this.isProgressStart = false
              }, 3000);
              if( this.failedData.length == 0){
                // this.uploadSuccess = true;
                  this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
                  this.clearData()
                  // this.router.navigate(['employee']);
              } else {
                  this.downloadMsg = true;
                  // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                  // this.errorMsg = this.failedData.length+' employee(s) have error data'
              }
            }
            this.body = x+' out of '+l+" employee data uploaded successfully"
            this.changeDetectorRef.detectChanges();
        });

    }

    uploadPreEmpData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processPreEmpUpload(0,p);
    }


    processEducationUpload(k:any,p:any){

        var dataObject = {}
        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){


            var item = this.data[i]
            dataObject = {
                "employee" : item[0]?item[0]:"",
                "first_name" : item[1]?item[1]:"",
                "last_name" : item[2]?item[2]:"",
                "qualification" : item[3]?item[3]:"",
                "specialization" : item[4]?item[4]:"",
                "year_of_passing" : item[5]?this.dateFormatConvert(item[5]):"",
                "university_board" : item[6]?item[6]:"",
                "college_school": item[7]?item[7]:"",
                "grade_cgpa": item[8]?item[8]:""
            };
            dataArray.push(dataObject);
        }
        var inputData = {data:dataArray};
        this.es.saveEducation(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
            if(res.failed_data.length != 0){
            res.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
            }
            this.percentage +=((p/4)*3);
            if(x<l)
            this.processEducationUpload(k+1,p);
            else{
              this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
               setTimeout(() => {
                this.iscompleted = true
                this.isProgressStart = false
              }, 3000);
              if( this.failedData.length == 0){
                // this.uploadSuccess = true;
                  this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
                  this.clearData()
                  // this.router.navigate(['employee']);
              } else {
                  this.downloadMsg = true;
                  // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                  // this.errorMsg = this.failedData.length+' employee(s) have error data'
              }
            }
            this.body = x+' out of '+l+" employee data uploaded successfully"
            this.changeDetectorRef.detectChanges();
        });

    }

    uploadEducationData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processEducationUpload(0,p);
    }


    processProfessionalUpload(k:any,p:any){

        var dataObject = {}
        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){


            var item = this.data[i]
            dataObject = {
            "employee" : item[0]?item[0]:"",
            "first_name" : item[1]?item[1]:"",
            "last_name" : item[2]?item[2]:"",
            "previous_company" : item[3]?item[3]:"",
            "previous_designation" : item[4]?item[4]:"",
            "from_date" : item[5]?this.dateFormatConvert(item[5]):"",
            "to_date" : item[6]?this.dateFormatConvert(item[6]):"",
            "job_description": item[7]?item[7]:""
            };
            dataArray.push(dataObject);
        }
        var inputData = {data:dataArray};
        this.es.saveProfessional(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
            if(res.failed_data.length != 0){
            res.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
            }
            this.percentage +=((p/4)*3);
            if(x<l)
            this.processProfessionalUpload(k+1,p);
            else{
              this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
               setTimeout(() => {
                this.iscompleted = true
                this.isProgressStart = false
              }, 3000);
              if( this.failedData.length == 0){
                // this.uploadSuccess = true;
                  this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
                  this.clearData()
                  // this.router.navigate(['employee']);
              } else {
                  this.downloadMsg = true;
                  // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                  // this.errorMsg = this.failedData.length+' employee(s) have error data'
              }
            }
            this.body = x+' out of '+l+" employee data uploaded successfully"
            this.changeDetectorRef.detectChanges();
        },
        (error:any)=>{
          this.isProgressStart = false
          // this.clearData()
          this.notificationService.handleErrorNotification('Something went wrong','Error')
        }
        );

    }

    uploadProfessionalData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processProfessionalUpload(0,p);
    }

    processBankUpload(k:any,p:any){

        var dataObject = {}
        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){


            var item = this.data[i]
            dataObject = {
                "employee" : item[0]?item[0]:"",
                "first_name" : item[1]?item[1]:"",
                "last_name" : item[2]?item[2]:"",
                "salary_pay_mode" : item[3]?item[3]:"",
                "account_number" : item[4]?item[4]:"",
                "bank_name" : item[5]?item[5]:"",
                "account_holdername" : item[6]?item[6]:"",
                "ifsc_code" : item[7]?item[7]:"",
                "branch_name" : item[8]?item[8]:"",
                "branch_address" : item[9]?item[9]:"",
            };
            dataArray.push(dataObject);
        }
        var inputData = {data:dataArray};
        this.es.saveBank(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
            if(res.failed_data.length != 0){
            res.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
            }
            this.percentage +=((p/4)*3);
            if(x<l)
            this.processBankUpload(k+1,p);
            else{
              this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
               setTimeout(() => {
                this.iscompleted = true
                this.isProgressStart = false
              }, 3000);
              if( this.failedData.length == 0){
                // this.uploadSuccess = true;
                  this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
                  this.clearData()
                  // this.router.navigate(['employee']);
              } else {
                  this.downloadMsg = true;
                  // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                  // this.errorMsg = this.failedData.length+' employee(s) have error data'
              }
            }
            this.body = x+' out of '+l+" employee data uploaded successfully"
            this.changeDetectorRef.detectChanges();
        });

    }

    uploadBankData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processBankUpload(0,p);
    }

    processFamilyUpload(k:any,p:any){

        var childKeyArray=this.arraySearch("Children Name");
        var depkeyArray=this.arraySearch("Dependent Name");
        var childArray:any = [];
        var depArray:any = [];
        var dataObject = {}

        var l = this.data.length;
        var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
        var dataArray:any = [];
        this.percentage +=(p/4);
        for(var i= k*this.chunkLen; i<x; i++){
            var item = this.data[i]
            childArray = [];
            depArray = [];
            childKeyArray.forEach(element => {
              if(item[element]  || item[element+1] || item[element+2] || item[element+3] || item[element+4] || item[element+5] ){
                childArray.push({'child_name':item[element]?item[element]:"",
                        'child_date_of_birth':item[element+1]?this.dateFormatConvert(item[element+1]):"",
                        'child_gender':item[element+2]?item[element+2]:"",
                        'is_dependent':item[element+3]?item[element+3]:false,
                        'is_going_to_school':item[element+4]?item[element+4]:false,
                        'is_going_to_hostel':item[element+5]?item[element+5]:false,
                });
              }
            });
            depkeyArray.forEach(element => {
              if(item[element]  || item[element+1] || item[element+2] || item[element+3] || item[element+4] ){
                depArray.push({'dependent_name':item[element]?item[element]:"",
                        'dependent_of_birth':item[element+1]?this.dateFormatConvert(item[element+1]):"",
                        'dependent_gender':item[element+2]?item[element+2]:"",
                        'dependent_relation':item[element+3]?item[element+3]:"",
                        'dependent_remark':item[element+4]?item[element+4]:"",
                });
              }
            });
            dataObject = {
                "children" : childArray,
                "dependent_detail" : depArray,
                "employee": item[0]?item[0]:"",
                "first_name" : item[1]?item[1]:"",
                "last_name" : item[2]?item[2]:"",
                "father_name"	: item[3]?item[3]:"",
                "father_date_of_birth"	:item[4]?this.dateFormatConvert(item[4]):"",
                "mother_name"	: item[5]?item[5]:"",
                "mother_date_of_birth"	:item[6]?this.dateFormatConvert(item[6]):"",
                "marital_status": item[7]?item[7]:"",
                "date_of_marriage":item[8]?this.dateFormatConvert(item[8]):"",
                "spouse_name": item[9]?item[9]:"",
                "spouse_date_of_birth":item[10]?this.dateFormatConvert(item[10]):"",
            };
            dataArray.push(dataObject);
        }
        var inputData = {data:dataArray};
        this.es.saveFamily(inputData).subscribe((res: any) => {
            this.successDataCount += res.succesd_data_count;
            if(res.failed_data.length != 0){
            res.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
            }
            this.percentage +=((p/4)*3);
            if(x<l)
            this.processFamilyUpload(k+1,p);
            else{
              this.body = x+' out of '+l+" employee data uploaded successfully";this.percentage = 100;this.changeDetectorRef.detectChanges();
               setTimeout(() => {
                this.iscompleted = true
                this.isProgressStart = false
              }, 3000);
              if( this.failedData.length == 0){
                // this.uploadSuccess = true;
                  this.notificationService.handleSuccessNotification(this.successDataCount+" employee data uploaded successfully.","Success");
                  this.clearData()
                  // this.router.navigate(['employee']);
              } else {
                  this.downloadMsg = true;
                  // this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                  // this.errorMsg = this.failedData.length+' employee(s) have error data'
              }
            }
            this.body = x+' out of '+l+" employee data uploaded successfully"
            this.changeDetectorRef.detectChanges();
        });

    }

    uploadFamilyData(){
        this.isProgressStart = true
        this.failedData = []
        var l = this.data.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.processFamilyUpload(0,p);
    }

  arraySearch(val:any) {
    let start = [];
    for (var i=0; i<this.headerRow.length; i++)
        if (this.headerRow[i].includes(val) )
            start.push(i);
    return start;
  }

  clearData(){
    if( this.selectedDTIndex >=0)
        this.datatype[this.selectedDTIndex]['status']=false;
    if( this.selectedUTIndex >=0)
        this.uploadtype[this.selectedUTIndex]['status']=false;
    this.successDataCount=0
    this.selectedDTIndex = -1;
    this.selectedUTIndex = -1;
    this.activeTab = 1;
    this.fileName = '';
    this.data = [];
    this.fileSize = '';

    this.readData({data: this.data , fileName: this.fileName,fileSize:this.fileSize});
    this.fileDownloaded = false;
    this.changeDetectorRef.detectChanges();
  }


}
