import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-verify-part-a',
  templateUrl: './verify-part-a.component.html',
  styleUrls: ['./verify-part-a.component.scss']
})
export class VerifyPartAComponent implements OnInit {
  page              = 1;
  pageSize          = 20;
  items:any         = [];
  errorData:any     = [];
  sucessData:any    = [];
  exportData: any   = [];
  downloadMsg       = false;
  modalToggle       = false;
  errorToggle       = false;
  searchString:any  = "";
  fileName:any;
  filteredCount:any;
  currentDocName = 'Form 16 Part A'
  currentExtension = 'pdf'
  loader1 : boolean = false

  @Input() form16A_Data :any;
  @Input() form16A_File :any;
  @Output() changeTab = new EventEmitter();

  constructor(public messageService : MessageService,public appservice:AppService) {

  }
  ngOnInit(): void {

    let data = this.form16A_Data.data;
    
    for(let i=0;i< data.length;i++){
        data[i]['name']  = "";
        if(data[i]['first_name'] != null && data[i]['first_name'] != undefined && data[i]['first_name'] != "")
        data[i]['name'] += data[i]['first_name']+" ";
        if(data[i]['middle_name'] != null && data[i]['middle_name'] !=undefined && data[i]['middle_name'] != "")
        data[i]['name'] += data[i]['middle_name']+" ";
        if(data[i]['last_name'] != null && data[i]['last_name'] != undefined && data[i]['last_name'] != "")
        data[i]['name'] += data[i]['last_name'];
        this.sucessData.push(data[i])

    }
    for (let j=0;j<this.form16A_Data.error.length;j++){
      this.errorData.push(this.form16A_Data.error[j])

    }

    for (let i = 0; i < this.errorData.length; i++) {
      this.exportData.push({'Employee name':this.errorData[i].first_name!=''? this.errorData[i].first_name+(this.errorData[i].middle_name!=null?this.errorData[i].middle_name:" ")+ " "+this.errorData[i].last_name:'', 'Employee code':this.errorData[i].employee_code,
      'Error description': this.errorData[i].document_error});
    }


  }
  viewDocument(filename:any){

    this.modalToggle      = true;
    this.fileName         = filename;

  }
  // downloadDoc(filename:any,docType:any){
  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(filename,docType+'.pdf');
  // }
  goNext(tab:number){
    this.changeTab.emit(tab);
  }
  export() {

    this.appservice.exportExcel(this.exportData, 'PartA Error')
  }

  
  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }
}
