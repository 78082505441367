<div class="bg-pattern main-height p-16" *ngIf="permissions">
  <div class="container-lg">
    <div class="card-chat px-42 py-32">
      <div class="row row-16 overflow-auto " #scrollMe [scrollTop]="scrolltop"
        style="max-height: calc(100vh - 13rem);">
        <ng-container *ngIf="messages?.length==1 || messages == undefined">
          <div class="col-12">
            <div class="flex-center flex-column">
              <div class="fs-35 fw-600">Hello {{this.snapshotService.getCurrentUserName()}}</div>
              <p class="fs-18 fw-500">I am SkizzleHR Assistant. How can I help you ?</p>
              <img src="/assets/images/helper/bot.png" alt="" class="img-fluid my-40">
            </div>
          </div>
          <div class="col-lg-8 mx-auto">
            <div class="row row-8">
              <div class="col-md-4 col-6" (click)="clickMessage('How to apply leave?')">
                <div class="card card-c2 p-16 h-100  card-hover">
                  <p class="mb-0 fs-14">How to apply leave?</p>
                </div>
              </div>
              <div class="col-md-4 col-6" (click)="clickMessage('How to apply for a WFH?')">
                <div class="card card-c2 p-16 h-100  card-hover">
                  <p class="mb-0 fs-14">How to apply for a WFH?</p>
                </div>
              </div>
              <div class="col-md-4 col-6" (click)="clickMessage('How to mark my attendance?')">
                <div class="card card-c2 p-16  card-hover">
                  <p class="mb-0 fs-14">How to mark my attendance?</p>
                </div>
              </div>
              <div class="col-md-4 col-6" (click)="clickMessage('How to regularize my attendance?')">
                <div class="card card-c2 p-16 h-100  card-hover">
                  <p class="mb-0 fs-14">How to regularize my attendance?</p>
                </div>
              </div>
              <div class="col-md-4 col-6" (click)="clickMessage('How to download my payslip?')">
                <div class="card card-c2 p-16 h-100  card-hover">
                  <p class="mb-0 fs-14">How to download my payslip?
                  </p>
                </div>
              </div>
              <div class="col-md-4 col-6" (click)="clickMessage('How to download my salary structure?')">
                <div class="card card-c2 p-16 h-100  card-hover">
                  <p class="mb-0 fs-14">How to download my salary structure?
                  </p>
                </div>
              </div>

            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let message of messages">
          <div class="col-12" *ngIf="message.role == 'user'">
            <div class="d-flex gap-16 px-12">
              <img
                *ngIf="(this.snapshotService.getCurrentUserPic() != null && this.snapshotService.getCurrentUserPic()!='')"
                class="sq-40 avatar-circle" [src]="this.snapshotService.getCurrentUserPic()">
              <div
                *ngIf="(this.snapshotService.getCurrentUserPic() == null || this.snapshotService.getCurrentUserPic()=='')"
                class="sq-40 avatar-circle avatar-border fs-14 fw-500 bg50 {{this.snapshotService.getCurrentUserColor()}}">
                {{this.appService.getFirstChar(this.snapshotService.getCurrentUserName(),2)}}
              </div>
              <div class="fs-14 mt-8">
                <p class="mb-0  fw-500" [innerHTML]="message.content"></p>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="message.role == 'assistant'">
            <div class="d-flex gap-16 text-white bg-accent1 radius-4 p-12">
              <div class="sq-40 fs-20 avatar-circle bg49 fw-500  ">
                <i class="icon-ai"></i>
              </div>
              <!-- <img class="sq-40 avatar-circle" src="/assets/images/temp/ai.png"> -->
              <div class="fs-14 mt-8">
                <p class="mb-0" [innerHTML]="message.content"></p>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="col-12" *ngIf="loader">
          <div class="d-flex gap-16 text-white bg-accent1 radius-4 p-12">
            <!-- <img class="sq-40 avatar-circle" src="/assets/images/temp/ai.png"> -->
            <div class="sq-40 fs-20 avatar-circle bg49 fw-500  ">
              <i class="icon-ai"></i>
            </div>
            <div class="fs-14 mt-8">
              <div class="lds-ellipsis mt-1 position-relative">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <!-- <p class="mb-0"> -->
              <!-- </p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="position-sticky top-100">
        <div class="chat-input">
          <textarea class="form-control" #textarea [(ngModel)]="message" (keydown.enter)="prevententer($event);"
            (input)="adjustTextareaHeight()" placeholder="Please type your question here..."></textarea>
          <button  *ngIf="!disableSend" class="btn fs-20 text-accent2 px-0 " (click)="sendMessage()"><i class="icon-send"></i></button>
          <button  *ngIf="disableSend" class="btn fs-20 text-accent4 px-0 " ><i class="icon-send"></i></button>
        </div>

      </div>
    </div>
  </div>
</div>