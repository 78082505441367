import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HolidayPolicyService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }


  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }


  holidayList(year:any,show_floating:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-list-dropdown/?is_active=true&ordering=holiday_name&year=${year}&show_floating=${show_floating}`, this.httpOption);
  }
  applicableList(type:string){
    if(type == "BU"){
      return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    } else if(type == "Department")
      return  this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Designation")
      return  this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Grade")
      return  this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Company")
      return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
    else if(type == "Employee")
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name&employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}`, this.httpOption);
    else
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name`, this.httpOption);
  }


  saveHolidayPolicy(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/holiday-policy-create/`, body, this.httpOption)
  }

  getHolidayPolicy(id: number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-policy-update/${id}/`,  this.httpOption)
  }

  updateHolidayPolicy(body: any,id:number){
    return this.http.put(`${this.API_PATH}attendance/api/v1/holiday-policy-update/${id}/`, body, this.httpOption)
  }

  getHolidayPolicyList(status: any,limit:any,offset:any,search:any,year:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-policy-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&year=${year}`,  this.httpOption)
  }
  holidayPolicyDropDownData(status: any,limit:any,offset:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/holiday-policy-list-dropdown/?is_active=${status}&limit=${limit}&offset=${offset}&ordering=holiday_policy_name`, this.httpOption)
  }

  holidayPolicyDelete(id: number) {
    return this.http.patch(`${this.API_PATH}attendance/api/v1/holiday-policy-delete/${id}/`,  { is_active: false },this.httpOption)
  }

  futureHolidayCheck(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/holiday-future-holiday-check/`, body, this.httpOption)
  }
 
}
