import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EmailValidator, FormBuilder,  Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { OrganizationService } from 'src/app/@core/services/organization.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-login-page-customization',
  templateUrl: './login-page-customization.component.html',
  styleUrls: ['./login-page-customization.component.scss']
})
export class LoginPageCustomizationComponent implements OnInit {
  FileType: string = "image/png, image/gif, image/jpeg";
  inputId: string = "logo";
  invalidFile           = false;
  logo_flag = false;
  defaultbtn = false;
  defaultcls = false;
  errormsg:any
  imageUrl: any ;
  org_form:any;
  submitted = false;
  btnenable = false;
  orgid:any;
  organizationlogo:any=[];
  welcom_msg:any='';
  currentdata:any=[];
  loader=false;
  Isalert          = true;
  permissions:any = [];

  constructor(public messageService : MessageService,
    public OrganizationService : OrganizationService,
    public formBuilder: FormBuilder,private changeDetectorRef: ChangeDetectorRef,public appservice:AppService) { }

  ngOnInit(): void {
    this.getPermission();
    this.getorgdata();
    this.org_form = this.formBuilder.group({
      logo: [''],
      welcome_message: ['', [Validators.required, UnamepatternValidator(), Validators.maxLength(18)]]
    });
  }
  
  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }
  getPermission(){
    this.permissions = this.appservice.getPermissions('/customize-login');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  onSearchChange(searchValue: string): void {
    console.log(searchValue);
  }
  saveorg(){
    this.btnenable = false;
    this.submitted = true;
    if(this.org_form.invalid)
    return;
    let myFormData ={
      'background_image' : this.org_form.value.logo,
      'welcome_message' : this.org_form.value.welcome_message
    }
    this.OrganizationService.updatelogindata(myFormData,this.currentdata.id)
    .subscribe((res: any) => {
    })
  }
  getorgdata(){
    this.loader= true;
    this.OrganizationService.getorgdataid()
    .subscribe((res: any) => {
      if(res.length != 0){
        this.orgid = res[0]['id'];
        this.OrganizationService.getorgdata(this.orgid)
        .subscribe((res: any) => {
          this.organizationlogo = res.logo;
        })
      }
    })
    this.OrganizationService.getlogindata()
    .subscribe((res: any) => {
     this.currentdata = res.success;
     this.welcom_msg =this.currentdata.welcome_message;
     if(this.currentdata.background_image == '/assets/images/bg/bg-2.svg'){
        this.defaultcls=true;
     }
     if(this.currentdata.welcome_message == ''){
      this.org_form.get('welcome_message').setValue('Login to SkizzleHR');
     }
     if(this.permissions?.e == false){
      this.org_form.get('welcome_message')?.disable();
      this.defaultbtn = true;
      this.btnenable = false;
    }
     if(this.currentdata.background_image == ''){
      this.org_form.get('logo').setValue('/assets/images/bg/bg-2.svg');
      this.imageUrl = "/assets/images/bg/bg-2.svg";
     }
     this.loader= false;
    })

  }
  setdefualt(){
    this.btnenable = true;
    this.defaultbtn = true;
    this.org_form.get('welcome_message').setValue('Login to SkizzleHR');
    this.org_form.get('logo').setValue('/assets/images/bg/bg-2.svg');
     this.imageUrl = "/assets/images/bg/bg-2.svg";
  }
  getURL(event: any) {
    this.invalidFile = false;
    this.defaultbtn = false;
    this.btnenable = true;
    this.errormsg = "";
    this.imageUrl = event;
    this.logo_flag = true;
    this.org_form.get('logo').setValue(event);
  }
  deleteLogo(event: any) {
    this.invalidFile = false;
    this.errormsg = "";
    this.org_form.get('logo').setValue('');
    this.imageUrl = '';
    this.logo_flag = true;
  }
  getfileformat(event:any){
    this.org_form.get('logo').setValue('');
    this.imageUrl    = '';
    this.invalidFile = false;
    if(event==false){
      this.invalidFile = true;
      this.errormsg = this.messageService.validateFileFormat('jpeg,png,jpg');
    }
  }
  getvalidsize(event:any){
    this.org_form.get('logo').setValue('');
    this.imageUrl    = '';
    this.invalidFile = true;
    this.errormsg    = event;
  }
  get f() { return this.org_form.controls; }
// Keyup for Validation
  // keyUp(fieldName :any, fieldValue:any){
  //   this.subjectKeyUp.next({fieldName, fieldValue})
  // }
  valuechange($event1:any){
    this.btnenable = true;
    this.defaultbtn = false;
  }
}
