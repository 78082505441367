import { Component, OnInit, Input,Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-investment-not-submitted',
  templateUrl: './investment-not-submitted.component.html',
  styleUrls: ['./investment-not-submitted.component.scss']
})
export class InvestmentNotSubmittedComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() checkListElements: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above need to be cleared
  @Input() investmentForm: any;
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() callMonthlyTDS: any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering :any
  @Input() DesignationDD : any
  @Input() ouFilterForm: any
  @Input() Years : any;

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  yearList : any        = [];
  hasChangeCount:any  = 0;
  changedYear     = Number(new Date().getFullYear());
  selectedYear    = Number(new Date().getFullYear());
  currentYear               = new Date().getFullYear();
  placeholderData : any

  @Input() resDataFilter:any;

  get f()  { return this.investmentForm.controls; }

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  year_placeholder  = 'Select year'
  month_placeholder = 'Select month'
  monthValue : any
  attendListOfMonth:string  = "listOfMonth";
  constructor(
    private reportService:ReportsService,
    public appservice:AppService,
    public messageService: MessageService,
    public cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.yearListCreate();
  }


  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.investmentForm.get('financial_year')?.reset(null);
      this.investmentForm.get('investment_type')?.reset([true,false]);
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');

    }
    this.callMonthlyReport.emit({
      reportName : 'Investment Request'
    })
  }

  applyBtn : boolean = false

  filterFormData(use:any){
    let investment_type = this.investmentForm.controls.investment_type.value[0] == true && this.investmentForm.controls.investment_type.value[1] == true ? '': this.investmentForm.controls.investment_type.value[1]?"True":"False";
    // let investment_typed =  this.investmentForm.controls.investment_type.value[0] == true && this.investmentForm.controls.investment_type.value[1] == true ? '': this.investmentForm.controls.investment_type.value[0]?"proposed":"confirmed";


    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);

    if(use == 'filter'){
      return `financial_year=${this.investmentForm.controls.financial_year.value}&investment_type=${investment_type}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'investment_type' : investment_type,'financial_year' : this.investmentForm.controls.financial_year.value,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

  submitInvestment(){
    this.submitted      = true;
    let offset = 0
    if (this.investmentForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail         = false;
    this.applyBtn = false
  }

  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }
    this.closeControl = this.closeControl =='employment_type'?'employee_type': this.closeControl
    this.ouFilterForm.get(this.closeControl)?.setValue('');
    this.submitInvestment();
  }

  disableDate(){
    return false;
  }

  financialYearRange(num:any){
    let a = num;
    a = Number(a) + 1;
    return a;
  }
  // Financial Year Change
  yearChange(value:any){
    this.selectedYear = Number(value);
  }

  yearListCreate(){
    this.yearList=[];
    var i:number;
    for(i = this.appservice.getFinancialYear(); i >= 2020 ; i--){
      this.yearList.push(String(i))
    }
  }

  resetPage(){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => (key !== 'financial_year' && key !== 'investment_type')  && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-2 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }
  constructData(event:any){
    var date = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    this.monthValue = String(date.getMonth() + 1).padStart(2, '0');
  }
  setRequired(type:any){

    var arr = ['req_specific_month','req_specific_year']
    let val = this.investmentForm.get(type).value;
    if(val == true){
      arr.forEach((item:any)=>{
        this.investmentForm.get(item)?.setValidators([Validators.required]);
        this.investmentForm.get(item)?.markAsTouched();
        this.investmentForm.get(item)?.updateValueAndValidity();
      })
    }else{
      arr.forEach((item:any)=>{
        this.investmentForm.get(item)?.clearValidators()
        this.investmentForm.get(item)?.updateValueAndValidity();
      })
    }
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
