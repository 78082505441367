<form action="" class="" [formGroup]="formGp" autocomplete="off">

    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">CONFIGURE ROTATION</p>
        </div>
        <div class="row row-16">
            <div class="col-12">
                <div role="alert" class="alert alert-warning mb-0 show mb-12" *ngIf="isOpen">
                    <p class="mb-0 fs-14">In the right panel, you can rearrange the shift order by dragging and dropping shifts from the left panel.</p>
                    <div class="btn-close pointer" (click)="isOpen=false;"></div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-c2 p-24 h-100">
                    <div class="d-flex flex-wrap align-items-start"  (drop)="drop3($event)">
                        <p class="fs-14 fw-500 mb-0 text-uppercase">AVAILABLE SHIFTS</p>
                        <div class="ms-auto h-36">
                            <app-searchbar [searchlist]='shiftList' [searchplaceholder]='shiftsearch'
                                (searchEmitter)="searchresults($event)"
                                (keywordsearchEmitter)="searchshift($event)"></app-searchbar>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap gap-16  align-content-start h-100 mt-20" (drop)="drop1($event)"
                        (dragover)="allowDrop($event)" id="divdrop1">
                        <ng-container *ngFor="let shift_list of shiftList;let i=index">
                            <div *ngIf="selectedshiftlist.indexOf(shift_list.id)<= -1"
                                class="badge badge-c5 {{shift_list.color_code}} draggable" id="{{shift_list.id}}"
                                [draggable]="true" (dragstart)="drag($event)">{{shift_list.name}}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-c2 p-24 h-100" id="div1">
                    <div class="d-flex flex-wrap align-items-start">
                        <p class="fs-14 fw-500 text-uppercase m-0">SELECTED SHIFTS</p>
                        <div class="ms-auto h-36">
                            <!-- <app-searchbar [searchlist]='selectedshiftsearch' [searchplaceholder]='shiftsearch'
                                (searchEmitter)="searchresults1($event)"
                                (keywordsearchEmitter)="searchshift1($event)"></app-searchbar> -->
                        </div>

                    </div>

                    <div class="d-flex flex-wrap gap-16 mt-20 align-content-start h-100" (drop)="drop2($event)"
                        (dragover)="allowDrop($event)" formArrayName="selected_shift">
                        <ng-container *ngFor="let shift_list of selectedshift().controls,let i=index"
                            [formGroupName]="i">
                            <div class="badge badge-c5 {{shiftListArray[selectedshift().controls[i].value.shiftsid]?.color_code}} draggable"
                                id="{{shiftListArray[selectedshift().controls[i].value.shiftsid]?.id}}"
                                [draggable]="true" (dragstart)="drag($event)">
                                {{shiftListArray[selectedshift().controls[i].value.shiftsid]?.shift_name}} </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class=" col-12 form-row" (drop)="drop3($event)">
                <label for="" class="form-label required">Rotation frequency </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Enter the shift rotation frequency here.The maximum rotation frequency allowed is 6. eg: If Rotation frequency is 2.
                  The shift rotation pattern will be like 1st shift for 2 weeks and 2nd shift for next 2 weeks 3rd shift for next 2 weeks and so on."
                        popoverClass="popover-default popover-info" placement="bottom" container="body"
                        triggers="hover"></i></span>
                <input type="text" formControlName="rotation_frequency" class="form-control"
                    placeholder="{{this.messageService.placeholderdisp('Rotation frequency')}}"
                    [ngClass]="{ 'is-invalid': (submitted || f.rotation_frequency.touched || f.rotation_frequency.dirty) &&  f.rotation_frequency.errors || f.rotation_frequency.errors?.exist}" (keyup)="checkform()">
                <div *ngIf="(submitted || f.rotation_frequency.touched || f.rotation_frequency.dirty) && f.rotation_frequency.errors || f.rotation_frequency.errors?.exist"
                    class="invalid-feedback">
                    <div *ngIf="f.rotation_frequency.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="!f.rotation_frequency.errors?.pattern && f.rotation_frequency.errors?.max">
                        {{this.messageService.fieldlengthvalidation('value','6')}}</div>
                    <div *ngIf="f.rotation_frequency.errors?.pattern || f.rotation_frequency.errors?.min">
                        {{this.messageService.validationDisplay('pattern')}}</div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2 p-24 h-100">
                    <p class="fs-14 fw-500 mb-0 text-uppercase">SHIFT ROTATION PATTERN<span class=" ms-8"><i
                                class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                                ngbPopover="Below are the weeks, as per the frequency the shifts will be automatically assigned to weeks below."
                                popoverClass="popover-default popover-info" placement="bottom" container="body"
                                triggers="hover"></i></span></p>

                    <div class="d-flex flex-wrap gap-8 mt-20" *ngIf="!f.rotation_frequency.errors">
                        <ng-container *ngFor="let shift_list of selectedshift().controls;let i=index">
                            <ng-container *ngFor="let freq of createRange(f.rotation_frequency?.value),let x = index">
                                <ng-container *ngIf="shiftschedule=='Weekly'">
                                    <div
                                        class="btn-c1 {{shiftListArray[selectedshift().controls[i].value.shiftsid]?.color_code}}">
                                        W{{(i*(f.rotation_frequency?.value))+(1+x)}}</div>
                                </ng-container>
                                <ng-container *ngIf="shiftschedule=='Monthly'">
                                    <div
                                        class="btn-c1 {{shiftListArray[selectedshift().controls[i].value.shiftsid]?.color_code}}">
                                        M{{(i*(f.rotation_frequency?.value))+(1+x)}}</div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase"
            [disabled]="(!formGp.valid || componentError || noshift)" (click)="validateform()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"
            (click)="validateform()">NEXT</button>
    </footer>
</form>
