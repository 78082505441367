import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { Input,Output,EventEmitter} from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-generate-form16',
  templateUrl: './generate-form16.component.html',
  styleUrls: ['./generate-form16.component.scss']
})
export class GenerateForm16Component implements OnInit {
  page              = 1;
  pageSize          = 20;
  searchString:any  = "";
  items:any         = [];
  downloadMsg       = false;
  errorToggle       = false;
  modalToggle       = false;
  errorData:any     = [];
  sucessData:any    = [];
  exportData: any   = [];
  currentDocName    = 'Form 16'
  currentExtension  = 'pdf'
  loader1 : boolean = false
  fileName:any;

  @Input() generatedData:any;
  @Output() changeTab     = new EventEmitter();
  @Output() publishForm   = new EventEmitter();


  constructor(public messageService : MessageService,public appservice:AppService) {

  }
  ngOnInit(): void {
    let data = this.generatedData.data;

    for(let i=0;i< data.length;i++){

      data[i]['name']  = "";
      if(data[i]['first_name'] != null && data[i]['first_name'] != undefined && data[i]['first_name'] != "")
      data[i]['name'] += data[i]['first_name']+" ";
      if(data[i]['middle_name'] != null && data[i]['middle_name'] !=undefined && data[i]['middle_name'] != "")
      data[i]['name'] += data[i]['middle_name']+" ";
      if(data[i]['last_name'] != null && data[i]['last_name'] != undefined && data[i]['last_name'] != "")
      data[i]['name'] += data[i]['last_name'];
      this.sucessData.push(data[i])

    }
    for (let j=0;j<this.generatedData.error_list.length;j++){
      this.errorData.push(this.generatedData.error_list[j])

    }
    for (let i = 0; i < this.errorData.length; i++) {
      this.exportData.push({'Employee name':this.errorData[i].first_name!=''? this.errorData[i].first_name+(this.errorData[i].middle_name!=null?this.errorData[i].middle_name:" ")+ " "+this.errorData[i].last_name:'', 'Employee code':this.errorData[i].employee_code,
      'Error description': this.errorData[i].document_error});
    }

  }
  viewDocument(filename:any){
    this.modalToggle      = true;
    this.fileName         = filename;

  }
  // downloadDoc(filename:any,docType:any){

  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(filename,docType+'.pdf');
  // }
  goNext(tab:number){
    this.publishForm.emit();
  }
  // downloadAll(url:any){
  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(url,'Form 16.zip');
  // }

  export() {

    this.appservice.exportExcel(this.exportData, 'Form 16 Error');
  }

   // Loader
   showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

}
