import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import {  AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';

@Component({
  selector: 'app-punch-time',
  templateUrl: './punch-time.component.html',
  styleUrls: ['./punch-time.component.scss']
})
export class PunchTimeComponent implements OnInit {

  constructor(public attendanceReportService:AttendanceReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public messageService: MessageService, public cd : ChangeDetectorRef) { }

    @Input() companyDD: any;
    @Input() gradeDD: any;
    @Input() BranchDD: any;
    @Input() buDD: any;
    @Input() DepDD: any;
    @Input() DesignationDD: any;
    @Input() checkListElements: any;
    @Input() disabled: any;
    @Input() empstatusDD: any;
    @Input() emptypeDD: any;
    @Input() employeeDropdown: any;
    @Input() callemp : any;
    // Above needs to be cleared
    @Input() punchTime : any;
    @Input() salFilter: any;
    @Input() viewDetail: any;
    @Input() closeControl: any;
    @Input() empstatArr: any;
    @Input() tempArr: any;
    @Input() submitted: any;
    @Input() pageNumber : any
    @Input() lastPage :any
    @Input() ordering : any
    @Input() ouFilterForm: any
    @Input() resDataFilter:any;

    @ViewChild('scrollElement') scrollElement!: ElementRef;

    get f()  { return this.punchTime.controls; }

    @Output() filterPanel                   = new EventEmitter();
    @Output() callMonthlyReport             = new EventEmitter();
    @Output() submitDone                    = new EventEmitter();
    @Output() resetFilter                   = new EventEmitter();


  ngOnInit(): void {
    this.getDateFormat()
  }

  // Date Format
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  filterFormData(use:any){
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let from_date    = this.punchTime.controls.from_date.value==null?'':this.appservice.dateFormatConvert(this.punchTime.controls.from_date.value);
    let to_date    = this.punchTime.controls.to_date.value==null?'':this.appservice.dateFormatConvert(this.punchTime.controls.to_date.value);
    if(use == 'filter'){
      return `from_date=${from_date}&to_date=${to_date}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'from_date':this.appservice.dateFormatConvert(this.punchTime.controls.from_date.value),'to_date':this.appservice.dateFormatConvert(this.punchTime.controls.to_date.value),'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' : this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value});
    }
  }

  applyBtn : boolean = false

  // function for apply filter
  submitpunchTime(){
    this.submitted      = true;
    if (this.punchTime.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.attendanceReportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    });
    this.viewDetail           = false;
    this.applyBtn = false
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
      let allSelect = (items: any[]) => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.resetFilter.emit(true)
        }else{
          this.resetFilterControllers('reset');
        }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.punchTime.get('from_date')?.reset('');
      this.punchTime.get('to_date')?.reset('');
    }
      this.callMonthlyReport.emit({
        reportName : 'Clocking Time Report'

      })
  }

  // Tags closing
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'emp_status'){
      this.empstatArr = [];
    }else if(this.closeControl == 'emp_type'){
      this.tempArr = [];
    }
    this.ouFilterForm.get(this.closeControl)?.setValue('');
    this.submitpunchTime();
    }

    toDateChange(){
      if(this.f?.to_date?.value != null && !moment(this.f.from_date.value).isSameOrBefore(moment(this.f.to_date.value))){
        this.punchTime?.patchValue({
          to_date : null
        })
        this.f?.to_date.markAsTouched()
      }
    }

    resetPage(){
      this.applyBtn = true
      this.attendanceReportService.calculateOffset(0)
      this.cd.detectChanges()
    }

    getData(){
      const data = this.filterFormData('')
      const emptyKeys = Object.keys(data)
      .filter((key:any) => (key !== 'from_date' && key !== 'to_date')  && this.isValueEmpty(data[key]));
      return emptyKeys?.length ==  Object.keys(data)?.length-2 ? "" : data
    }

    isValueEmpty(value:any) {
      return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
    }

    stringifyCont(control: any): string {
      return control === '' ? JSON.stringify([]) : JSON.stringify(control);
    }

    scrollToFirstChild() {
      const scrollEl = this.scrollElement.nativeElement;
      const firstElement = scrollEl.querySelector('.is-invalid');
      if (firstElement) {
        const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
        scrollEl.scrollTop = scrollPosition;
      }
    }
}
