<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Details </h5>
    <button class="btn-reset text-light-600" (click)="closePanel()"><i
        class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <div class="container-fluid p-32 form-container">
      <div class="row row-16 ">
        <div class="col-12">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">GPS INFORMATION</p>
          </div>
        </div>
        <div class="col-12 ">

          <div class="card card-c2">
            <app-table-loader *ngIf="loadergps"></app-table-loader>
            <div class="table-responsive radius-4" *ngIf="!loadergps">
              <table
                class="table sticky-header td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th class="fw-600 text-uppercase" scope="col">Employee NAME</th>
                    <th class="fw-600 text-uppercase" scope="col">EMPLOYEE CODE</th>
                    <th class="fw-600 text-uppercase" scope="col">GPS LOST DATE & TIME</th>
                    <th class="fw-600 text-uppercase" scope="col">GPS LOST LOCATION</th>
                    <th class="fw-600 text-uppercase" scope="col">GPS RESTORED DATE & TIME</th>
                    <th class="fw-600 text-uppercase" scope="col">GPS Restored LOCATION</th>
                    <th class="fw-600 text-uppercase" scope="col">GPS LOST DURATION</th>
                    <th class="fw-600 text-uppercase" scope="col">ACTION</th>

                  </tr>
                </thead>
                <tbody *ngIf="gpsdata?.length==0">
                  <tr>
                    <td colspan="9">

                      <div class="card card-c2 flex-center p-16 bg-dark-500">
                        <span class="fw-500 fs-14">No data found</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody class="cell-16" *ngIf="gpsdata?.length!=0">

                  <tr *ngFor='let gpsdata of $any(gpsdata| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index'>
                    <td>{{gpsdata?.employee_name}}</td>
                    <td>{{gpsdata?.employee__code}}</td>
                    <td>{{gpsdata?.gps_lost_datetime !=
                      'NA'?appService.datetimeconvert(gpsdata?.gps_lost_datetime):'--'}}</td>
                    <td>{{gpsdata?.gps_lost_location != 'NA'?gpsdata?.gps_lost_location:'--'}}</td>
                    <td>{{gpsdata?.gps_restored_datetime !=
                      'NA' && gpsdata?.gps_restored_datetime?appService.datetimeconvert(gpsdata?.gps_restored_datetime):'--'}}</td>
                    <td>{{gpsdata?.gps_restored_location != 'NA'?gpsdata?.gps_restored_location:'--'}}</td>
                    <td>{{gpsdata?.gps_lost_duration != 'NA'?gpsdata?.gps_lost_duration:'--'}}</td>
                    <td *ngIf="gpsdata?.gps_restored_datetime =='NA'"> <span class="link-primary" (click)="notifyemployee(gpsdata?.employee_id)">Notify</span></td>
                    <td *ngIf="gpsdata?.gps_restored_datetime !='NA'"> <span class="link-primary" >--</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div class="col-12" *ngIf="this.appService.filteredcount(gpsdata,searchString)>pageSize">
          <ngb-pagination class="d-flex justify-content-end"
            [collectionSize]="this.appService.filteredcount(gpsdata,searchString)" [(page)]="page" [maxSize]="3"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
        </div>
      </div>
    </div>

  </main>
</div>
