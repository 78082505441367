import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-confirmed-incomeloss-property',
  templateUrl: './confirmed-incomeloss-property.component.html',
  styleUrls: ['./confirmed-incomeloss-property.component.scss']
})
export class ConfirmedIncomelossPropertyComponent implements OnInit {

  @Input()submitted:boolean                   = false;
  @Input()currency:any;
  @Input()limitData:any;
  @Input()investmentDetails:any;
  @Output() submitmethod                      = new EventEmitter();
  @Output() onOtherincome                     = new EventEmitter<any>();
  totalExemption:number                       = 0;
  alertShow                                   = 'show';
  intreston_houseloan_document_loader         = false;
  intreston_houseloan_document: any           = "";
  net_income_houseproperty_document_loader    = false;
  net_income_houseproperty_document: any      = "";
  currentDocId                                = 0;
  fileError:any;
  fileType: string                            = ".png,.jpeg,.jpg,.zip,.docx,.xlsx,.doc,.dotx,.pdf";
  filesizeError :any                          = [
                                                  {
                                                      'intreston_houseloan_document':false,
                                                    }
                                                ]
  sigleMulti:any;
  multiCurrentDocId:any                       = 0;
  docLoadersArray :any                        = [];
  loss_houseproperty_exempt_limit:any         = '';
  interest_paid_houseloan_exempt_limit:any    = '';
  netIncome:any                               = 0;
  errormsg:any;

  // File Upload
  modalToggle                                 = false;
  allowedExtension                            = "";
  alertMsg:any                                = '';
  alertToggle                                 = false;
  fileName                                    = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;

  constructor(
    public fb:FormBuilder,
    public messageService : MessageService,
    public appservice:AppService
  ) { }

  ngOnInit(): void {
    this.totalExeptionFunction();

    if(this.formGp.value.loss_lender.length == 0){
      this.removeDocLoadersArray();
    }else{
      for(let i=0;i<this.formGp.value.loss_lender.length;i++){
        this.docLoadersArray.push({['loader'+this.docLoadersArray.length]:false,['filesize'+this.docLoadersArray.length]:false});
      }
    }

    this.investDataFunction();
  }

  @Input()formGp : FormGroup = this.fb.group({
    incomefrom_self_occupiedproperty  : [''],
    intreston_houseloan_amount        : [''],
    intreston_houseloan_document      : [''],
    incomeself_lender                 : [],
    incomefrom_letoutproperty         : [''],
    loss_lender                       : []
  });

  /********* incomeself_lender FORM ARRAY FUNCTIONS ****************/
  lenderIncome() : FormArray {
    return this.formGp.get("incomeself_lender") as FormArray
  }

  newLenderIncome(): FormGroup {
    if(this.formGp.value.incomefrom_self_occupiedproperty == true){
      return this.fb.group({
        "lender_name"              : [null,[Validators.required,Validators.maxLength(100),NamefieldValidator()]],
        "lender_pan"               : [null,[PanfieldValidator()]],
      })
    }else{
      return this.fb.group({
        "lender_name"              : [null],
        "lender_pan"               : [null],
      })
    }
  }

  addLenderIncome() {
    this.lenderIncome().push(this.newLenderIncome());
  }

  removeLenderIncome(i:number) {
    this.lenderIncome().removeAt(i);
  }

  removeAllLenderIncome(){
    if(this.formGp.value.incomeself_lender.length > 0){
      while(this.formGp.value.incomeself_lender.length !== 0){
        this.lenderIncome().removeAt(0);
      }
    }
  }


  lenderLoss() : FormArray {
    return this.formGp.get("loss_lender") as FormArray
  }

  newLenderLoss(): FormGroup {
    if(this.formGp.value.incomefrom_letoutproperty == true){
      return this.fb.group({
        "annual_rent_received"         : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(15)]],
        "muncipal_taxpaid"             : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(15)]],
        "netvalue_ab"                  : [null],
        "standard_deduction"           : [null],
        "repaying_homeloan_property"   : false,
        "intrestpaid_houseloan_amount" : [null],
        "lender_name"                  : [null,[Validators.maxLength(100),NamefieldValidator()]],
        "lender_pan"                   : [null,[PanfieldValidator()]],
        "net_income_houseproperty_document" : [null,[Validators.required]],
        "net_income_houseproperty"     : [null]
      })
    }else{
      return this.fb.group({
        "annual_rent_received"         : [null],
        "muncipal_taxpaid"             : [null],
        "netvalue_ab"                  : [null],
        "standard_deduction"           : [null],
        "repaying_homeloan_property"   : false,
        "intrestpaid_houseloan_amount" : [null],
        "lender_name"                  : [null],
        "lender_pan"                   : [null],
        "net_income_houseproperty_document" : [null],
        "net_income_houseproperty"     : [null]
      })
    }
  }

  addLenderLoss() {
    this.lenderLoss().push(this.newLenderLoss());
    this.creatDocLoadersArray();
  }

  removeLenderLoss(i:number) {
    this.lenderLoss().removeAt(i);
  }

  removeAllLenderLoss(){
    if(this.formGp.value.loss_lender.length > 0){
      while(this.formGp.value.loss_lender.length !== 0){
        this.lenderLoss().removeAt(0);
      }
    }
  }

  get f() {
    return this.formGp.controls;
  }

  /********* CHECK BOC FUNCTION ****************/
  checkBoxFunctionTest(e:any, amountName:any, docName:any) {
    var checkControlName  = e.target.name;
    var amountControlName = amountName;
    var docControlName    = docName;
    if(e.target.checked){

        this.formGp.get(checkControlName)?.setValue(true);
        this.validationFunction(true,checkControlName,amountControlName,docControlName);

        if(e.target.name == 'incomefrom_self_occupiedproperty' && this.formGp.value.incomeself_lender.length == 0)
          this.addLenderIncome();

    }else{

      this.formGp.get(checkControlName)?.setValue(false);
      this.formGp.get(amountControlName)?.setValue('');
      this.formGp.get(docControlName)?.setValue('');
      this.validationFunction(false,checkControlName,amountControlName,docControlName);
      this.removeAllLenderIncome();

    }
  }

  /********* VALIDATION FUNCTIONS ****************/
  validationFunction(check:any,checkCn:any,amountCn:any,docCn:any){
    if(check == true){
      this.formGp.get(amountCn)?.setValidators([Validators.required, digitdecimalValidator(),Validators.maxLength(15)]);
      this.formGp.get(docCn)?.setValidators([Validators.required]);
    }else{
      this.formGp.get(amountCn)?.clearValidators();
      this.formGp.get(docCn)?.clearValidators();
    }
    this.formGp.get(amountCn)?.updateValueAndValidity();
    this.formGp.get(docCn)?.updateValueAndValidity();
  }

  letoutFunction(e:any){
    var checkControlName  = e.target.name;
    if(e.target.checked){

      this.formGp.get(checkControlName)?.setValue(true);
      if(this.formGp.value.loss_lender.length == 0)
        this.addLenderLoss();
    }
    else{

      this.formGp.get(checkControlName)?.setValue(false);

        this.removeAllLenderLoss();
    }
  }

  repayingFunction(e:any,index:any){
    var checkControlName  = e.target.name;
    if(e.target.checked){
      (<FormArray>this.formGp.get("loss_lender")).at(index).get(checkControlName)?.setValue(true);
      this.repayingValidationFunction(true,index);
      this.netIncomeFunction(index);
    }
    else{
      (<FormArray>this.formGp.get("loss_lender")).at(index).get(checkControlName)?.setValue(false);
      this.repayingValidationFunction(false,index);
      this.netIncomeFunction(index);
    }
  }

  repayingValidationFunction(check:any,index:any){
    if(check == true ){
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.setValidators([Validators.required,Validators.maxLength(100),NamefieldValidator()]);
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.setValidators([PanfieldValidator()]);
    }else{
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.clearValidators();
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.clearValidators();
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.clearValidators();

      (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.setValue('');
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.setValue('');
      (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.setValue('');
    }
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.updateValueAndValidity();
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_name')?.updateValueAndValidity();
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('lender_pan')?.updateValueAndValidity();
  }

  disabledFunction(){
    if(this.formGp.value.loss_lender.length > 0){
      for(let i=0; i< this.formGp.value.loss_lender.length; i++){
        (<FormArray>this.formGp.get("loss_lender")).at(i).get('netvalue_ab')?.disable();
        (<FormArray>this.formGp.get("loss_lender")).at(i).get('standard_deduction')?.disable();
      }
    }
  }

  netValueCalc(a:any,b:any,index:any){

    let c     = (Number(a)-Number(b));
    let d:any = Number(c)*(30/100);
    d         = parseFloat(d).toFixed(2);
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('netvalue_ab')?.setValue(c);
    (<FormArray>this.formGp.get("loss_lender")).at(index).get('standard_deduction')?.setValue(Number(d));

    this.netIncomeFunction(index);
    this.totalExeptionFunction();

  }

  netIncomeFunction(index:any){
    if(this.formGp.value.loss_lender.length > 0){
      let c = 0;
      let d = 0;
      let e = 0;
      let netAmount = 0;
        c = Number(c) + Number((<FormArray>this.formGp.get("loss_lender")).at(index).get('netvalue_ab')?.value);
        d = Number(d) + Number((<FormArray>this.formGp.get("loss_lender")).at(index).get('standard_deduction')?.value);
        e = Number(e) + Number((<FormArray>this.formGp.get("loss_lender")).at(index).get('intrestpaid_houseloan_amount')?.value);

        netAmount = (Number(c)-(Number(d)+Number(e)));
        (<FormArray>this.formGp.get("loss_lender")).at(index).get('net_income_houseproperty')?.setValue(netAmount);
    }
    this.totalExeptionFunction();
  }

  totalExeptionFunction(){
    if(this.formGp.value.loss_lender.length > 0){
      this.netIncome = 0;
      for(let i=0; i< this.formGp.value.loss_lender.length; i++){
        this.netIncome +=  (<FormArray>this.formGp.get("loss_lender")).at(i).get('net_income_houseproperty')?.value
      }
    }

    this.totalExemption = -(Number(this.formGp.value.intreston_houseloan_amount == "" ? 0 : this.formGp.value.intreston_houseloan_amount == null ? 0 : this.formGp.value.intreston_houseloan_amount))+(Number(this.netIncome == "" ? 0 : this.netIncome == null ? 0 : this.netIncome));

    return this.totalExemption;
  }

  validateIncomeLoss(btnText:any){
    if(btnText == 'skip'){
      this.removeAllLenderIncome();
      this.removeAllLenderLoss();
      this.formGp.get('incomefrom_self_occupiedproperty')?.setValue(false);
      this.formGp.get('intreston_houseloan_amount')?.setValue('');
      this.formGp.get('intreston_houseloan_document')?.setValue('');
      this.formGp.get('incomefrom_letoutproperty')?.setValue(false);
      this.clearValidation();
    }
    else{
      this.submitted = true;
      if (this.formGp.invalid) {
        return;
      }
    }
    this.submitmethod.emit();
  }

  clearValidation(){
    this.formGp.get('intreston_houseloan_amount')?.clearValidators();
    this.formGp.get('intreston_houseloan_amount')?.updateValueAndValidity();
    this.formGp.get('intreston_houseloan_document')?.clearValidators();
    this.formGp.get('intreston_houseloan_document')?.updateValueAndValidity();
  }

  /********* FILE UPLOADE FUNCTIONS ****************/
  currentId(id:number,singleMulti:any){
    if(singleMulti == 'single'){
      this.currentDocId = id;
      this.sigleMulti = 'single';
    }else{
      this.multiCurrentDocId = id;
      this.sigleMulti = 'multi';
    }
  }

  getURL(event: any) {
    if(this.sigleMulti == 'single'){
      if(this.currentDocId == 1){
        this.removeErrorMsg('intreston_houseloan_document')
        this.formGp.get('intreston_houseloan_document')?.setValue(event);
        this.intreston_houseloan_document_loader = false;
      }
    }else{
      (<FormArray>this.formGp.get("loss_lender")).at(this.multiCurrentDocId).get('net_income_houseproperty_document')?.setValue(event);
      this.docLoadersArray[this.multiCurrentDocId]['loader'+this.multiCurrentDocId]   = false;
      this.docLoadersArray[this.multiCurrentDocId]['filesize'+this.multiCurrentDocId] = false;
    }

  }

  getloader(event:any){
    if(this.sigleMulti == 'single'){
      if(this.currentDocId == 1)
        this.intreston_houseloan_document_loader = event;
    }
    else if(this.sigleMulti == 'multi'){
      this.docLoadersArray[this.multiCurrentDocId]['loader'+this.multiCurrentDocId]=event;
    }
  }

  getFilename(event:any){
    if(this.sigleMulti == 'single'){
      if(this.currentDocId == 1){
        this.intreston_houseloan_document = event.name;
        window.sessionStorage.setItem('this.intreston_houseloan_document',event.name );
      }
    }
    else if(this.sigleMulti == 'multi'){

    }
  }

  getvalidsize(event:any,number:any){
    if(number == 1){
      this.fileError = event;
      if(this.currentDocId == 1){
        this.errorMsgGenerator('intreston_houseloan_document');
        this.intreston_houseloan_document_loader = false;
      }
    }else{

      this.docLoadersArray[this.multiCurrentDocId]['filesize'+this.multiCurrentDocId] = true;
      this.errormsg = event;
    }

  }

  deleteDoc(id: number, singleMulti:any) {
    if(singleMulti == 'single'){
      if(id == 1)
        this.formGp.get('intreston_houseloan_document')?.setValue('');
    }
    if(singleMulti == 'multi'){
      (<FormArray>this.formGp.get("loss_lender")).at(id).get('net_income_houseproperty_document')?.setValue('');
    }
  }

  /********* ERROR REMOVE FUNCTION ****************/
  removeErrorMsg(control: any){
    Object.entries(this.filesizeError[0]).forEach(([key, value]) => {
      if(key == control){
      this.filesizeError[key] = false;}
    })
  }

  /********* ERROR GENERATE FUNCTION ****************/
  errorMsgGenerator(control:any){
    Object.entries(this.filesizeError[0]).forEach(([key, value]) => {
      if(key == control){
      this.formGp.get(control)?.setValue('');
      this.filesizeError[key] = true;}
    })
  }


  creatDocLoadersArray(){

    this.docLoadersArray.push({['loader'+this.docLoadersArray.length]:false,['filesize'+this.docLoadersArray.length]:false});
  }

  removeDocLoadersArray(){
    if(this.docLoadersArray.length > 0){
      while(this.docLoadersArray.length !== 0){
        this.docLoadersArray.splice(0);
      }
    }
  }

  investDataFunction(){
    if(this.investmentDetails.count_info.hasOwnProperty('statutory_tax_settings')){
      this.loss_houseproperty_exempt_limit = this.investmentDetails?.count_info?.statutory_tax_settings?.loss_houseproperty_exempt_limit;
      this.interest_paid_houseloan_exempt_limit = this.investmentDetails?.count_info?.statutory_tax_settings?.interest_paid_houseloan_exempt_limit;
    }
  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }


}
