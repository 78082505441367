import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxDeductorService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }
  getTaxDeductorList(year:any,company_id:any,limit:any,offset:any,search:any=null,status:any="") {
    if(company_id != null){
      return this.http.get(`${this.API_PATH}hr/api/v1/tax-deductor-list/?year=${year}&company_id=${company_id}&limit=${limit}&offset=${offset}&status=${status}`, this.httpOption)
    }else if(search != null){
      return this.http.get(`${this.API_PATH}hr/api/v1/tax-deductor-list/?year=${year}&limit=${limit}&offset=${offset}&search=${search}&status=${status}`, this.httpOption)
    } else      
      return this.http.get(`${this.API_PATH}hr/api/v1/tax-deductor-list/?year=${year}&limit=${limit}&offset=${offset}&status=${status}`, this.httpOption)
  }

  taxDeductorSave(body: any,id:any){
    return this.http.put(`${this.API_PATH}hr/api/v1/tax-deductor-update/${id}/`, body, this.httpOption)
  }
  getDeductorDetail(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/tax-deductor-update/${id}/`,this.httpOption)
  }


}