<form [formGroup]="myForm">
  <div class=" config-layout">
    <header class=" config-header">
      <h5 class="m-0"> {{Action}} Data Access</h5>
      <button class="btn-reset text-light-600" routerLink="/data-access"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <ng-container *ngIf="!loader">
      <aside class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="step" [class]="{'success':myForm['controls'].configure.valid}">
              <div class="count">1</div>
              <div class="content">
                <span>STEP 1</span>
                <h6>Define Data Access</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-da-define [formGp]="configureForm" [editflag]="editflag" (submitmethod)="submitOne()"
                [employeeDropdown]="employeeDropdown" [moduleDropdown]="moduleDropdown"></app-da-define>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="step" [class]="{'success':myForm['controls'].employeeassignright.valid}">
              <div class="count">2</div>
              <div class="content">
                <span>STEP 2</span>
                <h6>Assign Rights</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-da-rights [editdata]="rightArray" [formGp]="employeeassignrightForm" [dataacceessid]="dataacceessid"
                [formGpEmloyee]="configureForm" (submitmethod)="submitForm()" [companylist]="companylist"
                [departmentlist]="departmentlist" [citylist]="citylist" [branchlist]="branchlist" [bulist]="bulist"
                [designationlist]="designationlist" [gradelist]="gradelist" [buArray]="buArray"
                (companyChange)="companyChange($event)"></app-da-rights>
            </ng-template>
          </li>
        </ul>
        <div class="mt-auto" *ngIf="activeTab == 2">
          <div class="card card-details">
            <p class="mb-0">Rights assigned to <span class="highlight">{{countAssign()}}</span> out of <span
                class="highlight">{{myForm.value.configure.employee.length}}</span> Employees</p>
          </div>
        </div>

      </aside>
      <main class=" config-container">
        <div [ngbNavOutlet]="nav"></div>
        <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
      </main>
    </ng-container>
  </div>
</form>
