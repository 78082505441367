import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { Validators } from '@angular/forms';
import { RoleService } from 'src/app/@core/services/role.service';

@Component({
  selector: 'app-ar-alert',
  templateUrl: './ar-alert.component.html',
  styleUrls: ['./ar-alert.component.scss']
})
export class ArAlertComponent implements OnInit {

  submitted : boolean = false
  rolesType : any = []

  get f() {return  this.formGp.controls; }

  @Input() formGp : any;
  @Input() disabled : any
  @Output() submitmethod = new EventEmitter();
  constructor(
    public messageService : MessageService,
    public roleService    : RoleService
  ) { }

  ngOnInit(): void {
    this.getRolesData();
  }


validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(4);
  }

  setDisabled(value:any,data:any){
    if(value) {
      this.formGp.get(data).setValidators([Validators.required])
     } else {
       this.formGp.get(data).clearValidators()
     }
     this.formGp.get(data)?.setValue(null);
     this.formGp.controls[data].updateValueAndValidity();
  }

  getRolesData(){
    this.roleService.getRoleDropdownList(true).subscribe((res:any)=>{
      this.rolesType = res.body
      if (this.rolesType.length != 0) {
        this.selectAllForDropdownItems(this.rolesType);
      }
    })
  }

  notanumber(val:any){
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }

  // Role Clear
  clear(index:any,formCntrl:any){
    if(formCntrl == 'whome_want_notify'){
      let data = this.formGp.value.whome_want_notify;
      data.splice(index, 1);
      this.formGp.patchValue({
        whome_want_notify: data
      });
    }else if(formCntrl == 'weekly_notify_cc'){
      let data = this.formGp.value.weekly_notify_cc;
      data.splice(index, 1);
      this.formGp.patchValue({
        weekly_notify_cc: data
      });
    }else{
      let data = this.formGp.value.monthly_notify_cc;
      data.splice(index, 1);
      this.formGp.patchValue({
        monthly_notify_cc: data
      });
    }

  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }


}
