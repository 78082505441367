import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InboxService } from 'src/app/@core/services/inbox.service';

@Component({
  selector: 'app-fnf-work-from-home-approval',
  templateUrl: './fnf-work-from-home-approval.component.html',
  styleUrls: ['./fnf-work-from-home-approval.component.scss']
})
export class FnfWorkFromHomeApprovalComponent implements OnInit {

  
  @Input() data:any;
  @Input() workFromhome:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  timeOffTrend = false;
  loader                    = false;
  from_day:any;
  to_day:any;
  totday = 0;
  currentYear =  new Date().getFullYear();

  selectedYearDisp = String(this.currentYear)
  selectedYear = this.currentYear
  yearList: any = [];

  multi:any                 = [];
  graphLoader             = false;
  xAxisLabel:any = [];
  yAxisLabel: string = 'Days';

  colorScheme :any = {
    domain: ['#28A745','#FFC107','#DC3545']
  };
  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();


  constructor(public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,public appService: AppService,private datePipe:DatePipe,private notificationService: NotificationService,private inboxservice: InboxService) { }



  ngOnInit(): void {
    
  }

  timegraph(){
    this.yearList = []
    this.yearListCreate();
    this.getwfhtrendDetails(this.currentYear);
  }

  ngOnChanges(){
    this.loader       = true;
      setTimeout(() => {
        this.getwfhdaysdata();
      }, 1800);
    }

  getwfhdaysdata(){
      this.totday = 0;
        for(let i=0;i<this.workFromhome.requests?.length;i++){
          if(this.workFromhome?.requests[i].day_portion=='Full Day'){
            this.totday = this.totday+1;
          }else{
            this.totday = this.totday+0.5;
          }
        }
      this.loader       = false;
  }

  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  get f() {return  this.form.controls}; 

  confirm(status:any){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }
      this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
        if(res){
          this.refreshInbox(false);
          this.form.get('comment')?.reset('')

        }
      })
    }
  }

  onSelect(event:any) {
  }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }

  getwfhtrendDetails(event:any){
    this.selectedYearDisp = String(event)
    this.graphLoader = true;
    if(this.empId != null && this.empId != '' && this.empId != undefined){
      this.inboxservice.getwfhTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.prepareTrendData(res.data)
        }
       })
    }
  }

  prepareTrendData(data:any){
    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    this.xAxisLabel = [];
  
    this.multi           = [];
    data.forEach((item:any) => {
      this.xAxisLabel?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Approved"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });
    this.multi.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 })  
    this.graphLoader = false;
  
  }


  // test start
  refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

// test end

}
