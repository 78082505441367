<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" routerLink="/location-group"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar">
      <ul ngbNav #nav="ngbNav" class="steps gap-24" [activeId]="activeTab" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1" (click)="activeTab=1;">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].groupadd.valid }">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Location Group</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-location-group-add [formGp]="groupaddForm" [editflag]="editflag" [disabled]="disabled"
              [submitted]="iseditForm" (submitmethod)="submitfn($event)"
              (dataemit)="datafn($event)"></app-location-group-add>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" (click)="tabclickfn()">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].groupadd.valid && tabclick}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Review</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-location-group-review [summerydata]="summerydata"
              (submitmethod)="submitForm()"></app-location-group-review>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
