import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-challan-generation',
  templateUrl: './challan-generation.component.html',
  styleUrls: ['./challan-generation.component.scss']
})

export class ChallanGenerationComponent implements OnInit {
  activeTab       = 1;
  generateChallan = false;
  downloadChallan = false;
  activeTab2      = 'challan';

  nextYears: any;
  placeholderData: any;
  yearRange:any;
  yearOfCurrent: any;
  changedYear: any

  listOfMonth   = ["April","May","June","July",
  "August","September","October","November","December","January","February","March"];
  fMonth:any    = 'April';
  permissions:any;
  fyArray:any   = [];


  constructor(
    public appservice : AppService,
  ) { }

  ngOnInit(): void {

    this.getPermission();
    this.yearOfCurrent    = Number(this.appservice.getFinancialYear());
    this.yearRange        = this.appservice.getFinancialYearRange(this.yearOfCurrent);
    this.placeholderData  = "FY "+ this.yearRange;
    this.nextYears        = this.appservice.getFinancialYearDropdown();
    this.fyArrayCreation(this.yearOfCurrent);
  }

  fyArrayCreation(year:any){
    this.fyArray = [];
    for(let i=0;i<this.listOfMonth?.length;i++){
      if(this.listOfMonth[i] == 'January' || this.listOfMonth[i] == 'February' || this.listOfMonth[i] == 'March')
        this.fyArray?.push({'month': this.listOfMonth[i], 'year': Number(year + 1)});
      else
        this.fyArray?.push({'month': this.listOfMonth[i], 'year': year})
    }
  }

  yearChange(value:any){
    setTimeout(() => {
      this.changedYear     = Number(value.toString().substr(0,4))
      this.placeholderData = '';
      this.placeholderData = "FY "+value;
      this.fyArrayCreation(this.changedYear);
    }, 10);
  }

  getPermission(){
    this.permissions = this.appservice.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
