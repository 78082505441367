<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Reimbursement {{from=='behalf'?' Of '+ requestFor:''}}</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="stepperLoader"></app-fw-edit-loader>
  <ng-container *ngIf="!stepperLoader">
    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success':this.reimbursementForm['controls'].category.valid}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Select Category</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-reimbursement-category [formGp]="categoryForm" [categoryData]="categoryData" [currency]="currency"
              (submitmethod)="changeTab($event)"></app-reimbursement-category>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step"
            [class]="{'success':this.reimbursementForm['controls'].details.valid && this.reimbursementForm.get('details')?.value.reimbursement.length>0}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Reimbursement Details</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-reimbursement-details [categorySummaryData]="categorySummaryData" [formGp]="detailsForm"
              (submitmethod)="changeTab($event)"></app-reimbursement-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="step"
            [class]="{'success':(this.reimbursementForm['controls'].category.valid && this.reimbursementForm['controls'].details.valid && this.reimbursementForm.get('details')?.value.reimbursement.length>0)}">
            <div class="count">3</div>
            <div class="content">
              <span>Step 3</span>
              <h6>Summary</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-reimbursement-summary [summaryData]="summaryData" [categorySummaryData]="categorySummaryData"
              (submitmethod)="createBill()" [currency]="currency"></app-reimbursement-summary>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
