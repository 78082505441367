import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-reconcilation-view',
  templateUrl: './reconcilation-view.component.html',
  styleUrls: ['./reconcilation-view.component.scss']
})
export class ReconcilationViewComponent implements OnInit {

  @Input() salarysummaryList:any;
  @Input() empsalarysummaryList:any;

  @Input() month:any;
  @Input() year:any;
  @Input() searchString:any;
  @Input() page:any;
  @Input() pageSize:any;
  @Input() issalsumm =false
  @Input() isempsalsumm =false
  @Input() fromDashboard =false


  constructor(public appservice:AppService) { }

  ngOnInit(): void {
  }



  getPreMonth(month:any,year:any){
    const current = new Date(month+' 1'+year);
    current.setMonth(current.getMonth()-1);
    return  current.toLocaleString('default', { month: 'short' });
  }

  filterIt(){
    let data = this.issalsumm ? this.salarysummaryList : this.empsalarysummaryList
    return data.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

}
