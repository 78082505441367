<div class="container-fluid p-32 form-container">
    <div class="row row-24 ">
        <div class="col-12 d-flex flex-wrap align-items-start">
            <div class="mb-0 fw-500 text-uppercase">Attendance - February 2023</div>
            <div class="d-flex justify-content-end align-items-center ms-auto flex-wrap gap-16">
                <a class="link-danger text-nowrap"><i class="icon-trash me-8 "></i>Delete</a>
                <div style="min-width:22.688rem;">
                    <div class="form-icon2">
                        <i class="icon-search icon-left fs-14"></i>
                        <div class="btn-close icon-right pointer fs-12"></div>
                        <input class="form-control  fs-14" placeholder=" " />
                    </div>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13rem"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end">
                        <button ngbDropdownItem>All</button>
                        <button ngbDropdownItem>Active </button>
                        <button ngbDropdownItem>Deleted</button>

                    </div>
                </div>
                <button class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 text-uppercase">Import
                </button>

                <div class="btn-group" role="group" aria-label="">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
                        [checked]='status=="PENDING"' (click)='status="PENDING"'>
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700"
                        for="btnradio1">PENDING</label>
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                        [checked]='status=="COMPLETED"' (click)='status="COMPLETED"'>
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700"
                        for="btnradio2">COMPLETED</label>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card card-c2 p-0">
                <div class="table-responsive radius-4">
                    <table *ngIf="status=='PENDING'"
                        class="table sticky-header td-white-space td-12 th-12 td-fs-14 table-striped tr-fs-14 table-sm form-table sticky-first-col ">
                        <thead>
                            <tr>
                                <th class="form-cell" style="width: 2.5rem;">
                                    <input class="form-check-input m-0" type="checkbox" value="">
                                </th>
                                <th class="fw-600" scope="col">EMP CODE</th>
                                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                <th class="fw-600" scope="col">Company</th>
                                <th class="fw-600" scope="col">Department</th>
                                <th class="fw-600" scope="col">LOP MONTH</th>
                            </tr>
                        </thead>
                        <tbody class="height-full">
                            <tr *ngFor="let i of [1,2,3,4,5,6]">
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox">
                                </td>
                                <td>
                                    <input type="text" class=" form-control  " placeholder=" " />
                                </td>
                                <td>
                                    <input type="text" class=" form-control  " placeholder=" " />
                                </td>
                                <td>
                                    <ng-select appendTo="body" class="form-ngselect" placeholder=" ">
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select appendTo="body" class="form-ngselect" placeholder=" ">
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select appendTo="body" class="form-ngselect" placeholder=" ">
                                    </ng-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table *ngIf="status=='COMPLETED'"
                        class="table sticky-header  td-12 th-12 td-white-space table-striped table-sm form-table sticky-first-col ">
                        <thead>
                            <tr>
                                <th class="form-cell" style="width: 2.5rem;">
                                    <input class="form-check-input m-0" type="checkbox" value="">
                                </th>
                                <th class="fw-600" scope="col">EMP CODE</th>
                                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                <th class="fw-600" scope="col">Company</th>
                                <th class="fw-600" scope="col">Arrear month</th>
                                <th class="fw-600" scope="col">Arrear days</th>
                            </tr>
                        </thead>
                        <tbody class="height-full">
                            <tr *ngFor="let i of [1,2,3,4,5,6]">
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox">
                                </td>
                                <td>
                                    EMPT348
                                </td>
                                <td>
                                    Kanmani Neha
                                </td>
                                <td>
                                    XYZ Technologies
                                </td>
                                <td>
                                    January
                                </td>
                                <td>
                                    2
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="submit-footer gap-16" *ngIf="status=='PENDING'">
    <button class="btn btn-primary btn-sm text-uppercase" type="button">save and continue</button>
</footer>