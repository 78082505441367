import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { HrRoutingModule } from './hr-routing.module';
import { EmployeeComponent } from './employee/employee/employee.component';
import { DataTypeComponent } from './employee/employee-import-setup/data-type/data-type.component';
import { UploadComponent } from './employee/employee-import-setup/upload/upload.component';
import { DownloadTempletComponent } from './employee/employee-import-setup/download-templet/download-templet.component';
import { UploadFileComponent } from './employee/employee-import-setup/upload-file/upload-file.component';
import { PreviewSaveComponent } from './employee/employee-import-setup/preview-save/preview-save.component';
import { SalaryComponent } from './salary/salary/salary.component';
import { EmployeeImportSetupComponent } from './employee/employee-import-setup/employee-import-setup.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SalaryStructureSetupComponent } from './salary/salary-structure-setup/salary-structure-setup.component';
import { SsEmpStatutoryComponent } from './salary/salary-structure-setup/ss-emp-statutory/ss-emp-statutory.component';
import { SsSalaryStructureComponent } from './salary/salary-structure-setup/ss-salary-structure/ss-salary-structure.component';
import { SsSalaryDetailsComponent } from './salary/salary-structure-setup/ss-salary-details/ss-salary-details.component';
// import { PayrollComponent } from './salary-dashboard/payroll/payroll.component';
// import { ImportAttendanceComponent } from './run-payroll/import-attendance/import-attendance.component';
import { RemindersComponent } from './run-payroll/reminders/reminders.component';
import { ProcessSalaryComponent } from './run-payroll/process-salary/process-salary.component';
import { SalaryReconciliationComponent } from './run-payroll/salary-reconciliation/salary-reconciliation.component';
import { FreezPublishSalaryComponent } from './run-payroll/freez-publish-salary/freez-publish-salary.component';
// import { DataTablesModule } from 'angular-datatables';
import { NguCarouselModule } from '@ngu/carousel';
import { CountUpModule } from 'ngx-countup';
import { EmployeeHrEditComponent } from './employee/employee-hr-edit/employee-hr-edit.component';
import { ProfileModule } from '../profile/profile.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Form16Component } from './form-16/form16/form16.component';
import { Form16SetupComponent } from './form-16/form16-setup/form16-setup.component';
import { UploadPartAComponent } from './form-16/form16-setup/upload-part-a/upload-part-a.component';
import { UploadPartBComponent } from './form-16/form16-setup/upload-part-b/upload-part-b.component';
import { VerifyPartBComponent } from './form-16/form16-setup/verify-part-b/verify-part-b.component';
import { VerifyPartAComponent } from './form-16/form16-setup/verify-part-a/verify-part-a.component';
import { GenerateForm16Component } from './form-16/form16-setup/generate-form16/generate-form16.component';
import { PublishForm16Component } from './form-16/form16-setup/publish-form16/publish-form16.component';
import { TemplateComponent } from './template/template/template.component';
import { TemplateSetupComponent } from './template/template-setup/template-setup.component';
import { TemplateDetailsComponent } from './template/template-setup/template-details/template-details.component';
import { TemplatePreviewComponent } from './template/template-setup/template-preview/template-preview.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { EmployeeReportConfigureComponent } from './reports/employee/employee-report-configure/employee-report-configure.component';
import { EmployeeReportComponent } from './reports/employee/employee-report/employee-report.component';
import { ScheduledReportsComponent } from './scheduled-reports/scheduled-reports.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { RsScheduleDetailsComponent } from './reports/report-setup/rs-schedule-details/rs-schedule-details.component';
import { RsAssignReportComponent } from './reports/report-setup/rs-assign-report/rs-assign-report.component';
import { ReportSetupComponent } from './reports/report-setup/report-setup.component';
import { ErrorComponent } from './error/error.component';
import { HttpClientModule } from '@angular/common/http';
// import { NgxEditorModule } from 'ngx-editor';
import { SalaryDashboardComponent } from './salary-dashboard/salary-dashboard/salary-dashboard.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatSelectModule } from '@angular/material/select';
import { PayrollReportComponent } from './reports/payroll/payroll-report/payroll-report.component';
import { PayrollReportConfigureComponent } from './reports/payroll/payroll-report-configure/payroll-report-configure.component';
import { MatSortModule } from '@angular/material/sort'
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { StylePaginatorDirective } from './reports/payroll/payroll-report-configure/style-paginator.directive';
import { IgxTimePickerModule } from 'igniteui-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PayslipTemplateComponent } from './template/payslip-template/payslip-template.component';
import { TaxslipTemplateComponent } from './template/taxslip-template/taxslip-template.component';
import { SalaryRevisionComponent } from './salary-revision/salary-revision.component';
import { SalaryRevisionSetupComponent } from './salary-revision/salary-revision-setup/salary-revision-setup.component';
import { SrDefineSalaryDetailsComponent } from './salary-revision/salary-revision-setup/sr-define-salary-details/sr-define-salary-details.component';
import { SrPayComponentComponent } from './salary-revision/salary-revision-setup/sr-pay-component/sr-pay-component.component';
// import { SalaryRevisionHistoryViewComponent } from './salary-revision/salary-revision-history-view/salary-revision-history-view.component';
// import { SalaryRevisionDetailsViewComponent } from './salary-revision/salary-revision-details-view/salary-revision-details-view.component';
import { AttendanceAndLopComponent } from './run-payroll/attendance-and-lop/attendance-and-lop.component';
import { AttendanceComponent } from './run-payroll/attendance-and-lop/attendance/attendance.component';
import { LopComponent } from './run-payroll/attendance-and-lop/lop/lop.component';
import { LopReversalComponent } from './run-payroll/attendance-and-lop/lop-reversal/lop-reversal.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { RunPayrollComponent } from './run-payroll/run-payroll.component';
import { SalaryRevisionArrearsComponent } from './run-payroll/salary-revision-arrears/salary-revision-arrears.component';
import { VariablePaymentsAndDeductionsComponent } from './run-payroll/variable-payments-and-deductions/variable-payments-and-deductions.component';
// import { ProcessOrUnprocessComponent } from './run-payroll/process-or-unprocess/process-or-unprocess.component';
import { OverridePtEsiLwsTdsComponent } from './run-payroll/override-pt-esi-lws-tds/override-pt-esi-lws-tds.component';
// import { FreezeOrUnfreezeSalaryComponent } from './run-payroll/freeze-or-unfreeze-salary/freeze-or-unfreeze-salary.component';
import { PublishSalaryComponent } from './run-payroll/publish-salary/publish-salary.component';
import { AlertsComponent } from './run-payroll/alerts/alerts.component';
// import { ReminderComponent } from './run-payroll/reminder/reminder.component';
import { OverridePtComponent } from './run-payroll/override-pt-esi-lws-tds/override-pt/override-pt.component';
import { OverrideEsiComponent } from './run-payroll/override-pt-esi-lws-tds/override-esi/override-esi.component';
import { OverrideLwfComponent } from './run-payroll/override-pt-esi-lws-tds/override-lwf/override-lwf.component';
import { OverrideTdsComponent } from './run-payroll/override-pt-esi-lws-tds/override-tds/override-tds.component';
import { OverridePfComponent } from './run-payroll/override-pt-esi-lws-tds/override-pf/override-pf.component';
import { ViewBreakupDetailsComponent } from './run-payroll/view-breakup-details/view-breakup-details.component';
import { HeldSalaryComponent } from './run-payroll/held-salary/held-salary.component';
import { IncomeTaxComponent } from './income-tax/income-tax.component';
import { ChallanComponent } from './income-tax/challan/challan.component';
import { ReturnsComponent } from './income-tax/returns/returns.component';
import { ChallanUpdateComponent } from './income-tax/challan-update/challan-update.component';
import { ChallanDetailsComponent } from './income-tax/challan-details/challan-details.component';
import { ChallanSetupComponent } from './income-tax/challan-setup/challan-setup.component';
import { ChallanDefineDetailsComponent } from './income-tax/challan-setup/challan-define-details/challan-define-details.component';
import { ChallanMapEmployeesComponent } from './income-tax/challan-setup/challan-map-employees/challan-map-employees.component';
import { ChallanSummaryComponent } from './income-tax/challan-setup/challan-summary/challan-summary.component';
import { PriorPayrollComponent } from './prior-payroll/prior-payroll/prior-payroll.component';
import { PriorPayrollSetupComponent } from './prior-payroll/prior-payroll-setup/prior-payroll-setup.component';
import { PriorPayrollDetailsComponent } from './prior-payroll/prior-payroll-setup/prior-payroll-details/prior-payroll-details.component';
import { PriorPayrollSummaryComponent } from './prior-payroll/prior-payroll-setup/prior-payroll-summary/prior-payroll-summary.component';
import { ConsolidatedDataComponent } from './prior-payroll/prior-payroll/consolidated-data/consolidated-data.component';
import { MonthwiseDataComponent } from './prior-payroll/prior-payroll/monthwise-data/monthwise-data.component';
import { MonthlyTDSComponent } from './reports/payroll/payroll-report-configure/monthly-tds/monthly-tds.component';
import { CtcPerSalaryComponent } from './reports/payroll/payroll-report-configure/ctc-per-salary/ctc-per-salary.component';
import { FinalSettlementComponent } from './final-settlement/final-settlement/final-settlement.component';
import { FinalSettlementSetupComponent } from './final-settlement/final-settlement-setup/final-settlement-setup.component';
import { FinalSettlementResignationDetailsComponent } from './final-settlement/final-settlement-setup/final-settlement-resignation-details/final-settlement-resignation-details.component';
import { FinalSettlementWorkdaysPayableComponent } from './final-settlement/final-settlement-setup/final-settlement-workdays-payable/final-settlement-workdays-payable.component';
import { FinalSettlementPendingRequestsComponent } from './final-settlement/final-settlement-setup/final-settlement-pending-requests/final-settlement-pending-requests.component';
import { FinalSettlementInvestmentDeclarationComponent } from './final-settlement/final-settlement-setup/final-settlement-investment-declaration/final-settlement-investment-declaration.component';
import { FinalSettlementReimbursementComponent } from './final-settlement/final-settlement-setup/final-settlement-reimbursement/final-settlement-reimbursement.component';
import { FinalSettlementOtherPaymentsComponent } from './final-settlement/final-settlement-setup/final-settlement-other-payments/final-settlement-other-payments.component';
import { FinalSettlementRecoveryOrPayComponent } from './final-settlement/final-settlement-setup/final-settlement-recovery-or-pay/final-settlement-recovery-or-pay.component';
import { FinalSettlementGratuityComponent } from './final-settlement/final-settlement-setup/final-settlement-gratuity/final-settlement-gratuity.component';
import { FinalSettlementProcessFAndFComponent } from './final-settlement/final-settlement-setup/final-settlement-process-f-and-f/final-settlement-process-f-and-f.component';
import { EmployeeStatutoryComponent } from './reports/payroll/payroll-report-configure/employee-statutory/employee-statutory.component';
import { EsiComponent } from './reports/payroll/payroll-report-configure/esi/esi.component';
import { SalaryOnHoldComponent } from './reports/payroll/payroll-report-configure/salary-on-hold/salary-on-hold.component';
import { SalaryRegisterComponent } from './reports/payroll/payroll-report-configure/salary-register/salary-register.component';
import { PfComponent } from './reports/payroll/payroll-report-configure/pf/pf.component';
import { SnapshotComponent } from './reports/employee/employee-report-configure/snapshot/snapshot.component';
import { PtComponent } from './reports/payroll/payroll-report-configure/pt/pt.component';
import { BankDetailsComponent } from './reports/employee/employee-report-configure/bank-details/bank-details.component';
import { EmployeeDetailsComponent } from './reports/employee/employee-report-configure/employee-details/employee-details.component';
import { PersonalDetailsComponent } from './reports/employee/employee-report-configure/personal-details/personal-details.component';
import { LoginReportComponent } from './reports/employee/employee-report-configure/login-report/login-report.component';
import { CtcRevisionComponent } from './reports/payroll/payroll-report-configure/ctc-revision/ctc-revision.component';
import { PaySalaryComponent } from './reports/payroll/payroll-report-configure/pay-salary/pay-salary.component';
import { ReimbursementRequestComponent } from './reports/payroll/payroll-report-configure/reimbursement-request/reimbursement-request.component';
import { YtdSalaryDetailsComponent } from './reports/payroll/payroll-report-configure/ytd-salary-details/ytd-salary-details.component';
import { InvestmentRequestComponent } from './reports/payroll/payroll-report-configure/investment-request/investment-request.component';
import { OverrideComponent } from './run-payroll/override-pt-esi-lws-tds/override/override.component';
import { LwfComponent } from './reports/payroll/payroll-report-configure/lwf/lwf.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { InviteEmployeeComponent } from './invite-employee/invite-employee.component';
// import { TableDataComponent } from './invite-employee/table-data/table-data.component';
import { FnfItDeclarationApprovalComponent } from './final-settlement/final-settlement-setup/fnf-it-declaration-approval/fnf-it-declaration-approval.component';
import { SendNewUserComponent } from './invite-employee/send-new-user/send-new-user.component';
import { ResetOldUserComponent } from './invite-employee/reset-old-user/reset-old-user.component';
import { DynamicMenuComponent } from 'src/app/@shared/components/dynamic-menu/dynamic-menu.component';
import { FnfItReimbursementApprovalComponent } from './final-settlement/final-settlement-setup/fnf-it-reimbursement-approval/fnf-it-reimbursement-approval.component';
import { ShiftMappingComponent } from './employee-shift/shift-mapping/shift-mapping.component';
import { ShiftMappingSetupComponent } from './employee-shift/shift-mapping-setup/shift-mapping-setup.component';
import { SmAssignShiftComponent } from './employee-shift/shift-mapping-setup/sm-assign-shift/sm-assign-shift.component';
import { SmApplicabilityComponent } from './employee-shift/shift-mapping-setup/sm-applicability/sm-applicability.component';
import { FnfFbpApprovalComponent } from './final-settlement/final-settlement-setup/fnf-fbp-approval/fnf-fbp-approval.component';
import { FnfEmployeeApprovalComponent } from './final-settlement/final-settlement-setup/fnf-employee-approval/fnf-employee-approval.component';
import { LeaveYearEndComponent } from './leave-year-end/leave-year-end.component';
import { PendingLeaveRequestComponent } from './leave-year-end/pending-leave-request/pending-leave-request.component';
import { LeaveYearEndDetailsComponent } from './leave-year-end/leave-year-end-details/leave-year-end-details.component';
import { LeaveBalanceComponent } from './leave-balance/leave-balance.component';
import { EmployeeAttendanceComponent } from './employee-attendance/employee-attendance.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EmployeeAttendanceViewComponent } from './employee-attendance/employee-attendance-view/employee-attendance-view.component';
import { LeaveBalanceViewComponent } from './leave-balance/leave-balance-view/leave-balance-view.component';
import { ShiftMappingImportComponent } from './employee-shift/shift-mapping-import/shift-mapping-import.component';
import { FinancialYearPendingRequestComponent } from './run-payroll/financial-year-pending-request/financial-year-pending-request.component';
import { FinancialYearPendingRequestViewComponent } from './run-payroll/financial-year-pending-request/financial-year-pending-request-view/financial-year-pending-request-view.component';
// import { LeaveBalanceImportComponent } from './leave-balance/leave-balance-import/leave-balance-import.component';
import { AttendanceProcessComponent } from './run-payroll/attendance-and-lop/attendance-process/attendance-process.component';
import { ArrearProcessComponent } from './run-payroll/attendance-and-lop/arrear-process/arrear-process.component';
import { ArrearsComponent } from './run-payroll/attendance-and-lop/arrears/arrears.component';
import { LeaveReportComponent } from './reports/leave/leave-report/leave-report.component';
import { LeaveReportConfigureComponent } from './reports/leave/leave-report-configure/leave-report-configure.component';
import { LeaveParticularDayComponent } from './reports/leave/leave-report-configure/leave-particular-day/leave-particular-day.component';
import { LeaveCarryForwardComponent } from './reports/leave/leave-report-configure/leave-carry-forward/leave-carry-forward.component';
import { LeaveAvailedComponent } from './reports/leave/leave-report-configure/leave-availed/leave-availed.component';
import { LeaveCreditComponent } from './reports/leave/leave-report-configure/leave-credit/leave-credit.component';
import { LeaveEncashmentComponent } from './reports/leave/leave-report-configure/leave-encashment/leave-encashment.component';
import { LeaveLapsedComponent } from './reports/leave/leave-report-configure/leave-lapsed/leave-lapsed.component';
import { LeaveNegativeComponent } from './reports/leave/leave-report-configure/leave-negative/leave-negative.component';
import { LeaveLopComponent } from './reports/leave/leave-report-configure/leave-lop/leave-lop.component';
import { LeaveSummaryComponent } from './reports/leave/leave-report-configure/leave-summary/leave-summary.component';
import { LeaveLedgerComponent } from './reports/leave/leave-report-configure/leave-ledger/leave-ledger.component';
import { LeaveRequestComponent } from './reports/leave/leave-report-configure/leave-request/leave-request.component';
import { FnfRegularizationApprovalComponent } from './final-settlement/final-settlement-setup/fnf-regularization-approval/fnf-regularization-approval.component';
import { FnfWorkFromHomeApprovalComponent } from './final-settlement/final-settlement-setup/fnf-work-from-home-approval/fnf-work-from-home-approval.component';
import { FnfHolidayApprovalComponent } from './final-settlement/final-settlement-setup/fnf-holiday-approval/fnf-holiday-approval.component';
import { FnfLeaveApprovalComponent } from './final-settlement/final-settlement-setup/fnf-leave-approval/fnf-leave-approval.component';
import { FnfOvertimeApprovalComponent } from './final-settlement/final-settlement-setup/fnf-overtime-approval/fnf-overtime-approval.component';
import { FnfCompOffApprovalComponent } from './final-settlement/final-settlement-setup/fnf-comp-off-approval/fnf-comp-off-approval.component';
import { FinalSettlementArrearCalculationComponent } from './final-settlement/final-settlement-setup/final-settlement-arrear-calculation/final-settlement-arrear-calculation.component';
import { FnfOndutyApprovalComponent } from './final-settlement/final-settlement-setup/fnf-onduty-approval/fnf-onduty-approval.component';
import { LocationComponent } from './location/location.component';
import { GeoLocationComponent } from './location/geo-location/geo-location.component';
import { AssignGeoLocationComponent } from './location/assign-geo-location/assign-geo-location.component';
import { AttendanceReportsComponent } from './reports/attendance/attendance-reports/attendance-reports.component';
import { AttendanceReportConfigureComponent } from './reports/attendance/attendance-report-configure/attendance-report-configure.component';
import { PunchTimeComponent } from './reports/attendance/attendance-report-configure/punch-time/punch-time.component';
import { AttendanceRegularizationRequestComponent } from './reports/attendance/attendance-report-configure/attendance-regularization-request/attendance-regularization-request.component';
import { ArrearDaysComponent } from './reports/attendance/attendance-report-configure/arrear-days/arrear-days.component';
import { AttendanceRegisterComponent } from './reports/attendance/attendance-report-configure/attendance-register/attendance-register.component';
import { CompoffRequestComponent } from './reports/attendance/attendance-report-configure/compoff-request/compoff-request.component';
import { MonthlyOvertimeDetailComponent } from './reports/attendance/attendance-report-configure/monthly-overtime-detail/monthly-overtime-detail.component';
import { MonthlyPaidDayComponent } from './reports/attendance/attendance-report-configure/monthly-paid-day/monthly-paid-day.component';
import { MonthlyShiftRoasterComponent } from './reports/attendance/attendance-report-configure/monthly-shift-roaster/monthly-shift-roaster.component';
import { MonthlyWorkingHourComponent } from './reports/attendance/attendance-report-configure/monthly-working-hour/monthly-working-hour.component';
import { OndutyRequestComponent } from './reports/attendance/attendance-report-configure/onduty-request/onduty-request.component';
import { OvertimeRequestComponent } from './reports/attendance/attendance-report-configure/overtime-request/overtime-request.component';
import { FloatingHolidayRequestComponent } from './reports/attendance/attendance-report-configure/floating-holiday-request/floating-holiday-request.component';
import { MusterRollForm25Component } from './reports/attendance/attendance-report-configure/muster-roll-form25/muster-roll-form25.component';
import { AbsentDetailsComponent } from './reports/attendance/attendance-report-configure/absent-details/absent-details.component';
import { DailyAttendanceComponent } from './reports/attendance/attendance-report-configure/daily-attendance/daily-attendance.component';
import { LateEarlyComponent } from './reports/attendance/attendance-report-configure/late-early/late-early.component';
import { LiveLocationComponent } from './live-location/live-location.component';
import { ResignationReportComponent } from './reports/employee/employee-report-configure/resignation-report/resignation-report.component';
import { FinalSettlementReportComponent } from './reports/payroll/payroll-report-configure/final-settlement-report/final-settlement-report.component';
import { WorkFromHomeReportComponent } from './reports/attendance/attendance-report-configure/work-from-home-report/work-from-home-report.component';
import { CheckInRequestReportComponent } from './reports/attendance/attendance-report-configure/check-in-request-report/check-in-request-report.component';

import { AssignEmployeeLocationSetupComponent } from './location/assign-employee-location-setup/assign-employee-location-setup.component';
import { EmployeeAssignComponent } from './location/assign-employee-location-setup/employee-assign/employee-assign.component';
import { LocationAssignComponent } from './location/assign-employee-location-setup/location-assign/location-assign.component';
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { environment } from 'src/environments/environment';
import { MarkerCluster } from './live-location/marker-cluster.directive';
import { AgmOverlays } from 'agm-overlays';
import { ExpenseManagementComponent } from './expense-management/expense-management.component';
import { ExpenseReimbursementComponent } from './run-payroll/expense-reimbursement/expense-reimbursement.component';
import { ReportDownload } from './leave-year-end/report-download';
import { PolicyDocumentsComponent } from './policy-documents/policy-documents/policy-documents.component';
import { ManageDocumentsComponent } from './policy-documents/manage-documents/manage-documents.component';
import { DocumentsCategoryComponent } from './policy-documents/documents-category/documents-category.component';
import { ManageDocumentsSetupComponent } from './policy-documents/manage-documents-setup/manage-documents-setup.component';
import { ManageDocumentsAddDetailsComponent } from './policy-documents/manage-documents-setup/manage-documents-add-details/manage-documents-add-details.component';
import { ManageDocumentsApplicabilityComponent } from './policy-documents/manage-documents-setup/manage-documents-applicability/manage-documents-applicability.component';
import { LocationRemoteComponent } from './reports/attendance/attendance-report-configure/location-remote/location-remote.component';
import { LocationLiveComponent } from './reports/attendance/attendance-report-configure/location-live/location-live.component';
import { ExpenseReportComponent } from './reports/expense/expense-report/expense-report.component';
import { ExpenseReportConfigureComponent } from './reports/expense/expense-report-configure/expense-report-configure.component';
// import { OuFiltersComponent } fro../../@shared/components/ou-filters/ou-filters.componentent';
import { AttendanceDashboardComponent } from './attendance-dashboard/attendance-dashboard.component';
import { AttendanceAlertComponent } from './attendance-dashboard/attendance-alert/attendance-alert.component';
import { AttendanceReminderComponent } from './attendance-dashboard/attendance-reminder/attendance-reminder.component';
import { PortalAccessComponent } from './portal-access/portal-access.component';
import { ManageAssetComponent } from './manage-asset/manage-asset.component';
import { AssetReportComponent } from './reports/asset/asset-report/asset-report.component';
import { FnfAssetApprovalComponent } from './final-settlement/final-settlement-setup/fnf-asset-approval/fnf-asset-approval.component';
import { FnfCheckInRequestApprovalComponent } from './final-settlement/final-settlement-setup/fnf-check-in-request-approval/fnf-check-in-request-approval.component';
import { AssetReportConfigureComponent } from './reports/asset/asset-report-configure/asset-report-configure.component';
import { ManageAdvanceComponent } from './manage-advance/manage-advance.component';
import { OutstandingAdvanceDetailsComponent } from './manage-advance/outstanding-advance-details/outstanding-advance-details.component';
import { ExpenseListingComponent } from './expense-management/expense-listing/expense-listing.component';
import { ExpenseMainFilterComponent } from './reports/expense/expense-report-configure/expense-main-filter/expense-main-filter.component';
import { FnfExpenseReportRequestApprovalComponent } from './final-settlement/final-settlement-setup/fnf-expense-report-request-approval/fnf-expense-report-request-approval.component';
import { FinalSettlementExpenseReimbursementsComponent } from './final-settlement/final-settlement-setup/final-settlement-expense-reimbursements/final-settlement-expense-reimbursements.component';
import { FnfViewExpenseReportComponent } from './final-settlement/final-settlement-setup/fnf-view-expense-report/fnf-view-expense-report.component';
import { DisablePortalAccessComponent } from './reports/employee/employee-report-configure/disable-portal-access/disable-portal-access.component';
import { AssetRequestReportComponent } from './reports/asset/asset-report-configure/asset-request-report/asset-request-report.component';
import { HardwareInventoryReportComponent } from './reports/asset/asset-report-configure/hardware-inventory-report/hardware-inventory-report.component';
import { AssetHistoryReportComponent } from './reports/asset/asset-report-configure/asset-history-report/asset-history-report.component';
import { EmployeeTagsComponent } from './reports/employee-tags/employee-tags.component';
import { EmployeeLettersComponent } from './employee-letters/employee-letters.component';
import { ChallanGenerationComponent } from './challan-generation/challan-generation.component';
import { ChallanGenerationProvidentFundComponent } from './challan-generation/challan-generation-provident-fund/challan-generation-provident-fund.component';
import { ChallanGenerationEmployeeStateInsurenceComponent } from './challan-generation/challan-generation-employee-state-insurence/challan-generation-employee-state-insurence.component';
import { FnfShortLeaveApprovalComponent } from './final-settlement/final-settlement-setup/fnf-short-leave-approval/fnf-short-leave-approval.component';
import { InvestmentNotSubmittedComponent } from './reports/payroll/payroll-report-configure/investment-not-submitted/investment-not-submitted.component';
import { OverridePayComponentComponent } from './run-payroll/override-pt-esi-lws-tds/override-pay-component/override-pay-component.component';
import { VariablePaidComponent } from './reports/payroll/payroll-report-configure/variable-paid/variable-paid.component';
import { OvertimePaymentComponent } from './reports/payroll/payroll-report-configure/overtime-payment/overtime-payment.component';
import { GratitudePaidComponent } from './reports/payroll/payroll-report-configure/gratitude-paid/gratitude-paid.component';
import { NpsEmployerComponent } from './reports/payroll/payroll-report-configure/nps-employer/nps-employer.component';
import { TdsComputationComponent } from './reports/payroll/payroll-report-configure/tds-computation/tds-computation.component';
import { LeaveReportConfigurationComponent } from './reports/leave/leave-report-configuration/leave-report-configuration.component';
import { AssetReportConfigurationComponent } from './reports/asset/asset-report/asset-report-configuration/asset-report-configuration.component';
import { ExpenseReportConfigurationComponent } from './reports/expense/expense-report-configuration/expense-report-configuration.component';
import { EmployeeReportConfigurationComponent } from './reports/employee/employee-report-configuration/employee-report-configuration.component';
import { PayrollReportConfigurationComponent } from './reports/payroll/payroll-report-configuration/payroll-report-configuration.component';
import { AttendanceReportConfigurationComponent } from './reports/attendance/attendance-report-configuration/attendance-report-configuration.component';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { InvestmentDeclarationComponent } from './investment-declaration/investment-declaration.component';
import { ViewInvestmentDeclarationComponent } from './investment-declaration/view-investment-declaration/view-investment-declaration.component';

import { LoanDetailsImportComponent } from './loan-details/loan-details-import/loan-details-import.component';
import { ManageActivitiesComponent } from './manage-activities/manage-activities.component';
import { RequestModule } from '../request/request.module';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
]);
@NgModule({
  providers: [
    ReportDownload
  ],
  declarations: [
    EmployeeComponent,
    EmployeeImportSetupComponent,
    DataTypeComponent,
    UploadComponent,
    DownloadTempletComponent,
    UploadFileComponent,
    PreviewSaveComponent,
    SalaryComponent,
    SalaryStructureSetupComponent,
    SsEmpStatutoryComponent,
    SsSalaryStructureComponent,
    SsSalaryDetailsComponent,
    // PayrollComponent,
    // ImportAttendanceComponent,
    RemindersComponent,
    ProcessSalaryComponent,
    SalaryReconciliationComponent,
    FreezPublishSalaryComponent,
    Form16Component,
    Form16SetupComponent,
    UploadPartAComponent,
    UploadPartBComponent,
    VerifyPartBComponent,
    VerifyPartAComponent,
    GenerateForm16Component,
    PublishForm16Component,
    EmployeeHrEditComponent,
    TemplateComponent,
    TemplateSetupComponent,
    TemplateDetailsComponent,
    TemplatePreviewComponent,
    EmployeeReportComponent,
    EmployeeReportConfigureComponent,
    ScheduledReportsComponent,
    RsScheduleDetailsComponent,
    RsAssignReportComponent,
    ReportSetupComponent,
    ErrorComponent,
    SalaryDashboardComponent,
    PayrollReportComponent,
    PayrollReportConfigureComponent,
    StylePaginatorDirective,
    PayslipTemplateComponent,
    TaxslipTemplateComponent,
    SalaryRevisionComponent,
    SalaryRevisionSetupComponent,
    SrDefineSalaryDetailsComponent,
    SrPayComponentComponent,
    // SalaryRevisionHistoryViewComponent,
    // SalaryRevisionDetailsViewComponent,
    AttendanceAndLopComponent,
    AttendanceComponent,
    LopComponent,
    LopReversalComponent,
    RunPayrollComponent,
    SalaryRevisionArrearsComponent,
    VariablePaymentsAndDeductionsComponent,
    // ProcessOrUnprocessComponent,
    OverridePtEsiLwsTdsComponent,
    // FreezeOrUnfreezeSalaryComponent,
    PublishSalaryComponent,
    AlertsComponent,
    // ReminderComponent,
    OverridePtComponent,
    OverrideEsiComponent,
    OverrideLwfComponent,
    OverrideTdsComponent,
    OverridePfComponent,
    ViewBreakupDetailsComponent,
    HeldSalaryComponent,
    IncomeTaxComponent,
    ChallanComponent,
    ReturnsComponent,
    ChallanUpdateComponent,
    ChallanDetailsComponent,
    ChallanSetupComponent,
    ChallanDefineDetailsComponent,
    ChallanMapEmployeesComponent,
    ChallanSummaryComponent,
    PriorPayrollComponent,
    PriorPayrollSetupComponent,
    PriorPayrollDetailsComponent,
    PriorPayrollSummaryComponent,
    ConsolidatedDataComponent,
    MonthwiseDataComponent,
    MonthlyTDSComponent,
    CtcPerSalaryComponent,
    FinalSettlementComponent,
    FinalSettlementSetupComponent,
    FinalSettlementResignationDetailsComponent,
    FinalSettlementWorkdaysPayableComponent,
    FinalSettlementPendingRequestsComponent,
    FinalSettlementInvestmentDeclarationComponent,
    FinalSettlementReimbursementComponent,
    FinalSettlementOtherPaymentsComponent,
    FinalSettlementRecoveryOrPayComponent,
    FinalSettlementGratuityComponent,
    FinalSettlementProcessFAndFComponent,
    EsiComponent,
    SalaryOnHoldComponent,
    SalaryRegisterComponent,
    PfComponent,
    EmployeeStatutoryComponent,
    SnapshotComponent,
    PtComponent,
    PfComponent,
    EmployeeStatutoryComponent,
    CtcRevisionComponent,
    PaySalaryComponent,
    BankDetailsComponent,
    EmployeeDetailsComponent,
    PersonalDetailsComponent,
    LoginReportComponent,
    ReimbursementRequestComponent,
    YtdSalaryDetailsComponent,
    InvestmentRequestComponent,
    OverrideComponent,
    LwfComponent,
    AuditLogComponent,
    InviteEmployeeComponent,
    //  TableDataComponent,
    FnfItDeclarationApprovalComponent,
    SendNewUserComponent,
    ResetOldUserComponent,
    DynamicMenuComponent,
    FnfItReimbursementApprovalComponent,
    ShiftMappingComponent,
    ShiftMappingSetupComponent,
    SmAssignShiftComponent,
    SmApplicabilityComponent,
    FnfFbpApprovalComponent,
    FnfEmployeeApprovalComponent,
    LeaveYearEndComponent,
    PendingLeaveRequestComponent,
    LeaveYearEndDetailsComponent,
    LeaveBalanceComponent,
    EmployeeAttendanceComponent,
    EmployeeAttendanceViewComponent,
    LeaveBalanceViewComponent,
    ShiftMappingImportComponent,
    FinancialYearPendingRequestComponent,
    FinancialYearPendingRequestViewComponent,
    // LeaveBalanceImportComponent,
    AttendanceProcessComponent,
    ArrearProcessComponent,
    ArrearsComponent,
    LeaveReportComponent,
    LeaveReportConfigureComponent,
    LeaveParticularDayComponent,
    LeaveCarryForwardComponent,
    LeaveAvailedComponent,
    LeaveCreditComponent,
    LeaveEncashmentComponent,
    LeaveLapsedComponent,
    LeaveNegativeComponent,
    LeaveLopComponent,
    LeaveSummaryComponent,
    LeaveLedgerComponent,
    LeaveRequestComponent,
    FnfOndutyApprovalComponent,
    FnfRegularizationApprovalComponent,
    FnfWorkFromHomeApprovalComponent,
    FnfHolidayApprovalComponent,
    FnfLeaveApprovalComponent,
    FnfOvertimeApprovalComponent,
    FnfCompOffApprovalComponent,
    FinalSettlementArrearCalculationComponent,


    LocationComponent,
    GeoLocationComponent,
    AttendanceReportsComponent,
    AttendanceReportConfigureComponent,
    AttendanceRegularizationRequestComponent,
    ArrearDaysComponent,
    AttendanceRegisterComponent,
    CompoffRequestComponent,
    MonthlyOvertimeDetailComponent,
    MonthlyPaidDayComponent,
    MonthlyShiftRoasterComponent,
    MonthlyWorkingHourComponent,
    OndutyRequestComponent,
    OvertimeRequestComponent,
    FloatingHolidayRequestComponent,
    MusterRollForm25Component,
    AbsentDetailsComponent,
    DailyAttendanceComponent,
    LateEarlyComponent,
    PunchTimeComponent,
    AssignGeoLocationComponent,
    LiveLocationComponent,
    ResignationReportComponent,
    FinalSettlementReportComponent,
    WorkFromHomeReportComponent,
    CheckInRequestReportComponent,

    AssignEmployeeLocationSetupComponent,
    EmployeeAssignComponent,
    MarkerCluster,
    LocationAssignComponent,
    ExpenseManagementComponent,
    ExpenseReimbursementComponent,
    PolicyDocumentsComponent,
    ManageDocumentsComponent,
    DocumentsCategoryComponent,
    ManageDocumentsSetupComponent,
    ManageDocumentsAddDetailsComponent,
    ManageDocumentsApplicabilityComponent,
    LocationRemoteComponent,
    LocationLiveComponent,
    PortalAccessComponent,
    AttendanceDashboardComponent,
    AttendanceAlertComponent,
    AttendanceReminderComponent,
    ManageAssetComponent,
    AssetReportComponent,
    FnfAssetApprovalComponent,
    FnfCheckInRequestApprovalComponent,
    AssetReportConfigureComponent,
    ExpenseReportComponent,
    ExpenseReportConfigureComponent,
    ManageAdvanceComponent,
    OutstandingAdvanceDetailsComponent,
    ExpenseListingComponent,
    ExpenseMainFilterComponent,
    FnfExpenseReportRequestApprovalComponent,
    FinalSettlementExpenseReimbursementsComponent,
    FnfViewExpenseReportComponent,
    AssetReportConfigureComponent,
    AssetRequestReportComponent,
    HardwareInventoryReportComponent,
    AssetHistoryReportComponent,
	DisablePortalAccessComponent,
    EmployeeTagsComponent,
    EmployeeLettersComponent,
    ChallanGenerationComponent,
    ChallanGenerationProvidentFundComponent,
    ChallanGenerationEmployeeStateInsurenceComponent,
    FnfShortLeaveApprovalComponent,
    InvestmentNotSubmittedComponent,
    OverridePayComponentComponent,
    VariablePaidComponent,
    OvertimePaymentComponent,
    GratitudePaidComponent,
    NpsEmployerComponent,
    TdsComputationComponent,
    LeaveReportConfigurationComponent,
    EmployeeReportConfigurationComponent,
    AssetReportConfigurationComponent,
    ExpenseReportConfigurationComponent,
    PayrollReportConfigurationComponent,
    AttendanceReportConfigurationComponent,
    LoanDetailsComponent,
    InvestmentDeclarationComponent,
    ViewInvestmentDeclarationComponent,
    LoanDetailsImportComponent,
    ManageActivitiesComponent
  ],
  imports: [
    CommonModule,
    HrRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    NgSelectModule,
    MasterSelectModule,
    InfiniteScrollModule,
    NguCarouselModule,
    CountUpModule,
    NgxChartsModule,
    Ng2SearchPipeModule,
    FormsModule,
    ProfileModule,
    AngularMyDatePickerModule,
    NgxSummernoteModule,
    DragDropModule,
    MatTableModule,
    DpDatePickerModule,
    HttpClientModule,
    // NgxEditorModule,
    MatDatepickerModule,
    MatSliderModule,
    MatFormFieldModule,
    // MatInputModule,
    MatMomentDateModule,
    CKEditorModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatPaginatorModule,
    NgxMaterialTimepickerModule,
    MatMomentDateModule,
    IgxTimePickerModule,
    PdfViewerModule,
    MatProgressBarModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MMM/YYYY',
      clearLabel: 'Cancel'
    }),
    FullCalendarModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: environment.locKey,
      libraries: ['places']
    }),
    AgmMarkerClustererModule,
    RequestModule

  ],
  exports: [
    OverrideEsiComponent,
    ShiftMappingComponent,
    LeaveBalanceViewComponent,
    EmployeeAttendanceViewComponent,
    LiveLocationComponent
    // SalaryRevisionDetailsViewComponent
  ]
})
export class HrModule {

}
