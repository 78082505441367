<form class="">
    <div class="container-fluid p-32 form-container">

        <div class="row row-16">
            <div class="col-12"><span class="text-uppercase fw-500">SUMMARY</span>
            </div>
            <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                    <div class="row row-24">
                        <div class="col-12">
                            <span class="title">WORK FROM HOME REQUEST</span>
                        </div>
                        <div class="col-12 d-flex">
                            <span class="fs-14 fw-500 text-uppercase"> {{from_day}} - {{to_day}}</span>
                            <span class="fs-14 ms-auto">
                                No of days :
                                <span class="fw-500">{{totday}} days</span>
                            </span>
                        </div>
                        <div class="col-12">
                            <div class="detail-title">Full day</div>
                            <div class="detail-desc" *ngIf="fulldayarr != null && fulldayarr !=''">{{createarrayDisplay(fulldayarr)}}</div>
                            <div class="detail-desc" *ngIf="fulldayarr == null || fulldayarr ==''">--</div>
                        </div>

                        <div class="col-12">
                            <div class="detail-title">Half day</div>
                            <div class="detail-desc" *ngIf="halfdayarr != null && halfdayarr !=''">{{createarrayDisplay(halfdayarr)}}</div>
                            <div class="detail-desc" *ngIf="halfdayarr == null || halfdayarr ==''">--</div>
                        </div>
                        <div class="col-12">
                            <div class="detail-title">Reason</div>
                            <div class="detail-desc">{{formGp.value.reason}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveClicked" (click)="validateSummary()">SAVE</button>
  </footer>
</form>

