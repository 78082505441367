import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TimeAttendenceSettingsService {

  constructor(
    private http: HttpClient,
    public router: Router) { }

    API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }



    timeAttendenceCompList(status: any,id :any,limit:any,offset:any,search:any) {
      return this.http.get(`${this.API_PATH}general_settings/api/v1/time-and-attend-settings-company-list/?is_active=${status}&id=${id}&limit=${limit}&offset=${offset}&search=${search}&ordering=company_name`, this.httpOption);
    }
    timeAttendenceCompDropdown(status: any) {
      return this.http.get(`${this.API_PATH}general_settings/api/v1/time-and-attend-settings-dropdown/?is_active=${status}&ordering=company__company_name`, this.httpOption);
    }

    timeAttendenceList(status: any, limit: any, offset: any, search:any){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/time-and-attend-settings-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`,this.httpOption)
    }

    timeAttendenceCreate(body:any){
      return this.http.post(`${this.API_PATH}general_settings/api/v1/time-and-attend-settings-create/`, body,this.httpOption)

    }

    timeAttendenceRoleList(){
      return this.http.get(`${this.API_PATH}organization/api/v1/role-dropdown-list/?is_active=true&ordering=role_name&self=true`, this.httpOption)
    }

    timeAttendenceDetail(id:number){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/time-and-attend-settings-detail/${id}/`,this.httpOption)

    }
    updateTimeAttendence(body: any,id:any){
      return this.http.put(`${this.API_PATH}general_settings/api/v1/time-and-attend-settings-detail/${id}/`, body, this.httpOption)
    }

}
