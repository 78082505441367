<form action="" class=""  [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row row-16 ">
      <div class="col-12">
        <p class="text-uppercase fw-500 mb-0">Setup configuration </p>
      </div>
      <div class="col-12">
        <div class="card card-c2 p-16">
          <div class="row row-16 ">
            <div class="col-12">
              <p class="title mb-0">Expense settings</p>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Do you want to specify an expiry limit on expenses that can be
                submitted in SkizzleHR ? </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If 'Yes' is selected, define the receipt validity period.
                  " popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check form-check-inline">
                <label class="form-check-label" >
                    <input formControlName="is_expiry_limit"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'expiry_period');">
                    Yes
                </label> 
              </div>
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input formControlName="is_expiry_limit"  class="form-check-input"  type="radio" [value]=false (click)="setRequired(false,'expiry_period');">
                      No
                  </label>
              </div>
            </div>
          <ng-container *ngIf="f.is_expiry_limit?.value">
            <div class="col-md-6">
              <label for="" class="form-label required">Based on </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Choose days or months." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <ng-select class="form-ngselect" formControlName="based_on" id="based_on" placeholder="Select Based on" [ngClass]="{ 'is-invalid': (submitted || f.based_on?.touched || f.based_on?.dirty) &&  f.based_on?.errors }">
                <ng-option *ngFor="let based_on of based_on" [value]="based_on">
                  {{based_on}}
                </ng-option>
              </ng-select>
                      <div *ngIf="(submitted || f.based_on?.touched || f.based_on?.dirty) && f.based_on?.errors " class="invalid-feedback">
                          <div *ngIf="f.based_on?.errors?.required">
                              {{this.messageService.validationDisplay('required')}}
                          </div> 
                      </div>
            </div>
            <div class="col-md-6">
              <label for="" class="form-label required">Expiry period </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the number for the expiry period, after which the expense receipts cannot be submitted." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
               <input type="text" formControlName="expiry_period" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('expiry period')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.expiry_period?.touched || f.expiry_period?.dirty) &&  f.expiry_period?.errors }">
                                    <div *ngIf="(submitted || f.expiry_period?.touched || f.expiry_period?.dirty) && f.expiry_period?.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.expiry_period?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>                                      
                                        <div *ngIf="f.expiry_period?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!(f.expiry_period?.errors?.pattern) && f.expiry_period?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','3')}}</div>
                                    </div>
            </div>
          </ng-container>

            <div class="col-12">
              <label for="" class="form-label required">Do you want to configure the expense report submission window?
              </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select 'Yes' to allow employees to submit expense reports for a certain time period. Enter the From Date and  To dates for this period. If 'No' is selected, there will be no restriction and employee can submit expense report at any time." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check form-check-inline">
                <label class="form-check-label" >
                    <input formControlName="is_report_submission"  class="form-check-input"  type="radio" [value]=true (click)="setRequired(true,'is_report_submission');">
                    Yes
                </label> 
              </div>
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input formControlName="is_report_submission"  class="form-check-input"  type="radio" [value]=false (click)="setRequired(false,'is_report_submission');">
                      No
                  </label>
              </div>
            </div>
            <div class="col-md-6"  *ngIf="f.is_report_submission?.value">
              <label for="" class="form-label required">From date </label>
              <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || formGp.controls['from_date'].touched) &&  formGp.controls['from_date'].errors}" [placeholder]="messageService.selectplaceholddisplay('date')" id="from_date" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="formGp.controls['from_date'].value" formControlName="from_date" [type]="'schedule_dates'"  [range]="28" [lastDay]="true">
              </app-master-select>
              <div *ngIf="(submitted || f.from_date.touched) && f.from_date.errors" class="invalid-feedback">
                <div *ngIf="f.from_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-md-6"  *ngIf="f.is_report_submission?.value">
              <label for="" class="form-label required">To date </label>
              <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || formGp.controls['to_date'].touched) &&  formGp.controls['to_date'].errors}" [placeholder]="messageService.selectplaceholddisplay('date')" id="to_date" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="formGp.controls['to_date'].value" formControlName="to_date" [type]="'schedule_dates'"  [range]="28" [lastDay]="true">
              </app-master-select>
              <div *ngIf="(submitted || f.to_date.touched) && f.to_date.errors" class="invalid-feedback">
                <div *ngIf="f.to_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <!-- commented as trip isnot implimented yet -->
            <!-- <div class="col-12">
              <label for="" class="form-label required">How many days are allowed for backdated request? </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Define the maximum allowed days for backdated trip requests." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <input type="text" formControlName="backdated_request_days" class="form-control"
                                    placeholder="{{this.messageService.placeholderdisp('backdated request days')}}"
                                    [ngClass]="{ 'is-invalid': (submitted || f.backdated_request_days?.touched || f.backdated_request_days?.dirty) &&  f.backdated_request_days?.errors }">
                                    <div *ngIf="(submitted || f.backdated_request_days?.touched || f.backdated_request_days?.dirty) && f.backdated_request_days?.errors "
                                        class="invalid-feedback">
                                        <div *ngIf="f.backdated_request_days?.errors?.required">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                      
                                        <div *ngIf="f.backdated_request_days?.errors?.pattern">
                                            {{this.messageService.validationDisplay('pattern')}}</div>
                                        <div *ngIf="!(f.backdated_request_days?.errors?.pattern) && f.backdated_request_days?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','3')}}</div>
                                    </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid|| saveBtnClicked" (click)="validatefn()">Save</button>
  </footer>
</form>