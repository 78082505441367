import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-letter-template-details',
  templateUrl: './letter-template-details.component.html',
  styleUrls: ['./letter-template-details.component.scss']
})
export class LetterTemplateDetailsComponent implements OnInit {

  @Input() formGp !: FormGroup
  @Input() disabled          = false;
  @Input() typesDropdown   : any
  @Input() modulesDropdown : any
  @Input() editflag        : any;

  @Output() submitMethod     = new EventEmitter();
  @Output() moduleIdFn       = new EventEmitter();

  submitted        : boolean = false;

  letterUnit       : any     = []
  id               : any
  private subjectKeyUp = new Subject<any>();

  constructor(
    public msgServ : MessageService,
    public existDataService :  ExistDatasService,
    public route   : ActivatedRoute
  ) { }

  get f(){
    return this.formGp.controls
  }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.id = this.route.snapshot.params['id']
  }

  // Validate
  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitMethod.emit(2);
  }

  moduleChanged(){
    this.formGp.patchValue({
      'letter_type' : null
    })
    this.moduleIdFn.emit(this.formGp.get('module')?.value)
  }

  // Simplified function to use for validation
existService(){
  let value = {}
  if(!this.editflag){
    value ={
      "page":"letterTemplates",
      "field":"name",
      "value": this.f.name.value
    }
  }else{
    value ={
      "id":this.id,
      "page":"letterTemplates",
      "field":"name",
      "value": this.f.name.value
    }
  }

  this.existDataService.getSpecificName(value).subscribe((res:any)=>{
    this.letterUnit = res?.body
    if(this.letterUnit.data ==='invalid'){
      this.f.name.setErrors({
        'exist' : true
      })
    }
  })
}


  // For validation of keyup(name)

  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
