import { Component, OnInit,ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ExpenseReceiptService } from 'src/app/@core/services/expense-receipt.service';
import { MessageService } from 'src/app/message.global';
import { FormBuilder} from '@angular/forms';
import * as moment from 'moment';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { ReceiptLandingTableComponent } from 'src/app/@shared/components/receipt-landing-table/receipt-landing-table.component';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AddNewExpenseComponent } from 'src/app/@shared/components/add-new-expense/add-new-expense.component'
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-expense-receipts',
  templateUrl: './expense-receipts.component.html',
  styleUrls: ['./expense-receipts.component.scss']
})
export class ExpenseReceiptsComponent implements OnInit {
  activeTab                   = 1;
  viewDetail                  = false;
  editDetail                  = false;
  searchKeyword               = ""
  searchlistdata:any          = [] ;
  selectedOption: string      = 'amount';
  categoryData:any            = [];
  filter                      = false
  dataFilter:any              = '';
  permissions:any             = [];
  clearBar                    = false;
  receiptPlaceholder:any      = this.messageService.searchdisplay('expense receipt');
  filterForm                  = this.formBuilder.group({
                                category                : '',
                                expense_date            : ''

                              }
                              );
  selected: any;
  ranges: any = {
                    'Today'       : [moment(), moment()],
                    'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                    'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
  isInvalidDate = (m: moment.Moment) =>  {
    }
  currency:any;
  employee_id:any;
  sLoading=false;
  @ViewChild(ReceiptLandingTableComponent) child:any
  @ViewChild(AddNewExpenseComponent) addExpense:any
  @ViewChild(SearchbarComponent) search:any

  constructor(
    public appService: AppService,
    public receiptService: ExpenseReceiptService,
    public messageService : MessageService,
    private formBuilder: FormBuilder,
    private authService:AuthService,
    private notificationService:NotificationService,



  ) { }

  ngOnInit(): void {
    let userData        = this.appService.getEmpData();
    this.employee_id    = this.authService.isEmployeeId;
    this.getPermission();
    this.getCategoryData();
    this.currency       = userData.currency;
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/expense-receipt');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  searchout(data:any){
    this.sLoading = data;

  }
  clearSearch(){
    this.search?.clearBar();
    this.clearBar = true


  }
  saveReceipt(form:any){

    let formValue = form?.form_value;
    formValue['expense_date'] = this.appService.dateFormatConvert(formValue['expense_date'])
    this.receiptService.saveOrUpdateReceipt(form?.editId,formValue).subscribe((res:any)=>{
      // if(form?.editId !=''){
      //   this.notificationService.handleSuccessNotification("Updated successfully.","Success");        
      // }
      this.editDetail = false;
      this.addExpense.resetForm();
      this.child.getLandingPageData();

    })
  }
  searchReceipt(searchKeyword:any){
    this.sLoading =true;
    this.child.searchReceipt(searchKeyword)
  }
  searchresults(data:any){
    this.child.searchresults(data)

  }
  closeAddExpense(){
    this.editDetail = false
    this.addExpense.resetForm();
  }
  getCategoryData(){
    this.receiptService.categoryListData('',this.employee_id).subscribe((res:any)=>{
      this.categoryData = res;
      this.selectAllForDropdownItems(this.categoryData);

    })
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  clearcommonForm(control:any,index:number){

    let selectedData = this.filterForm.get(control)?.value
    selectedData.splice(index, 1);
    this.filterForm.get(control)?.setValue(selectedData)

  }
  closefn(){
    this.filter = false;
    this.filterForm.reset();
  }
  applyFilter(){
    let selectedDate:any;
    let expense_date      = this.filterForm.controls.expense_date.value;
    if(expense_date.startDate!=null && expense_date.startDate!=''){

      let start         = moment(expense_date.startDate._d).format('YYYY-MM-DD');
      let end           = moment(expense_date.endDate._d).format('YYYY-MM-DD');
      selectedDate      = '"'+start +'",'  +'"'+end+'"';

    }

    this.dataFilter = 'category=['+ this.filterForm.value.category+']&expense_date=['+(selectedDate!=null && selectedDate!="" ?selectedDate:'')+']';
    this.child.applyFilter(this.dataFilter)
    this.searchKeyword='';
    this.search?.clearBar();
    this.filter = false;

  }
  resetFilter(){
    this.filterForm.get('category')?.setValue('');
    this.filterForm.get('expense_date')?.setValue('');
    this.dataFilter = '';
    this.child.resetFilter()
    this.searchKeyword='';
    this.search?.clearBar();
    this.filter = false;
  }
  get f() { return this.filterForm.controls; }
  addNewReceipt(){
      this.filterForm.reset();
      this.editDetail = true
  }

}
