<div class="section-layout" style="--section-sideabar-width:17rem;" *ngIf="permissions">
  <app-menu-loader *ngIf="sidebarLoader">

  </app-menu-loader>
  <div class="section-sidebar" style="width:17rem" *ngIf="!sidebarLoader">
    <div class="text-light-500 fs-12 fw-500 px-24 pt-24 pb-16 text-uppercase">REQUESTS</div>
    <ul class="navbar-nav nav-vertical nav ms-auto">
      <li class="nav-item">
        <a class="nav-link" [class.active]="selectedmenu == ''" (click)="menuClick('','')" >
          <span> All </span>
          <span>{{sideMenuData?.total_count_request}}</span>
        </a>
      </li>
      <ng-container *ngFor="let main of sideMenuData.data">

        <li class="nav-item {{parentMenu==main.id?'show' :''}}"   ngbDropdown>

          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button" >
            <span>{{main?.name}}</span>
            <span>{{main?.count}}</span>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu {{parentMenu==main.id?'show':''}}">

            <ng-container *ngFor="let sub of main.data">

              <a ngbDropdownItem  [class.active]="selectedmenu == sub?.id" (click)="menuClick(sub?.id,main?.id)">
                <span> {{sub?.name}} </span>
                <span>{{sub?.count}}</span>
              </a>
            </ng-container >

          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="section-main">
    <nav class="nav nav-custom sticky top-48">
      <a class="nav-link" [ngClass]="{ active: status == 'Pending' }" (click)="getStatuswiseData('Pending')">
        Pending
      </a>
      <a class="nav-link" [ngClass]="{ active: status == 'Accepted' }" (click)="getStatuswiseData('Accepted')">
        Approved
      </a>
      <a class="nav-link" [ngClass]="{ active: status == 'Rejected' }" (click)="getStatuswiseData('Rejected')">
        Rejected
      </a>
      <a class="nav-link" [ngClass]="{ active: status == 'Cancelled' }" (click)="getStatuswiseData('Cancelled')">
        <!-- Undo -->
         Cancelled
      </a>
    </nav>


    <div class="container-fluid p-24 ">
      <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
          <h3>Manage Activities</h3>
          <p class="text-helper mb-md-0">View and manage all employee requests and tasks, take action on pending requests, and raise requests on behalf of employees.</p>
        </div>
        <div class="col-md-8 col-lg-6">
          <div class="d-flex justify-content-end  gap-16">
            <div class=" " style="width:22.6875rem;">
              <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey"
                (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchActivity($event)"  [isLoading]="sLoading"></app-searchbar>
            </div>
            <button class="btn-square btn btn-secondary" (click)="filter = true">
              <i class="icon-filter-left fs-13"></i>
            </button>
            <button  *ngIf="permissions?.a"  class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="behalfRequest()">on behalf request</button>

          </div>
        </div>
      </div>
      <app-c1-loader *ngIf="loader"></app-c1-loader>

      <div class="row" style="height: calc(100vh - 14rem);" *ngIf="nodatafound && !loader; else all_data">
        <app-nodata-view class="vstack" [noDataImg]="'bg6.png'"  [noDataText]="'No Request Added Yet'" [hasPermission]="true">
        </app-nodata-view>
      </div>
      <ng-template #all_data>
        <div  class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()"  *ngIf="!loader">

          <ng-container *ngFor="let filter of key;let i=index">

            <div class="col-12 d-flex">
              <div class="fs-12 text-light-500 text-uppercase">{{dateFormatDisplay(filter) +" ("+ getReqSize(filter) +")"}}</div>
             <ng-container *ngIf="i==0">
               <div class="d-flex flex-wrap  ms-auto gap-16 fs-12">
                 <div class="d-flex align-items-center gap-8">
                   <span class="type-indicator sq-12 type-overdue"></span>Overdue
                 </div>
                 <div class="d-flex align-items-center gap-8">
                   <span class="type-indicator sq-12 type-on-behalf-request"></span>On Behalf Request
                 </div>
               </div>
             </ng-container>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let req of reqDatewise[filter]">
              <div class="card card-c2 hstack align-items-center p-16 pointer" (click)="viewRequestData(req?.request_type?.name,req?.id)">
                <!-- <img class="avatar-circle sq-44 avatar-border  me-12 img-fluid rounded-circle"
                  src="https://skizzlehrdevbkdstrage.blob.core.windows.net/remya/8c7b66ff-8fbc-41f4-bef8-e517dd477783_bg127.png"> -->
                  <img *ngIf="req.employee.profile_image !=  null && req.employee.profile_image !=  ''" alt=""
                  class="avatar-circle sq-44 avatar-border  me-12 img-fluid rounded-circle" src="{{req.employee.profile_image}}" />
                <div *ngIf="req.employee.profile_image ==  '' || req.employee.profile_image ==  null"
                  class="avatar-circle avatar-border fs-14 fw-500 {{req.employee.color_code}}  sq-44 me-12">
                  {{this.appservice.getFirstChar(req.employee.first_name+ " "+req.employee.last_name,2)}}</div>
                <div class="vstack text-trim">
                  <div class="d-flex align-items-start w-100">
                    <div class="fs-14 fw-600 text-trim pe-8">  {{req?.employee?.first_name+"
                      "+(req?.employee?.middle_name!=null?req?.employee?.middle_name:" ")+ "
                      "+req?.employee?.last_name}} ({{req?.employee?.employee_code}})</div>
                    <span class="ms-auto text-light-500 fs-10 text-nowrap">{{req.created_display}}</span>
                  </div>
                  <p class="mb-0 fs-12 text-light-500 w-100 text-trim">Requested By: {{req?.requested_by}}</p>
                  <div class="hstack">
                    <div class="badge-custom fs-10 radius-4 {{req?.request_type?.color_code}}">{{req?.request_type?.name}} </div>
                    <div class="ms-auto d-flex gap-4">
                      <span *ngIf="req?.is_mail_overdue" class="type-indicator radius-4 sq-12 type-overdue"></span>
                      <span *ngIf="req?.is_on_behalf" class="type-indicator radius-4 sq-12 type-on-behalf-request"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

      </ng-template>
    </div>
  </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:27rem;" [class.side-pane-active]='filter === true'  [formGroup]="filterForm">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Filters </h5>
      <a class="toggle-panel" (click)="filter = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <ngb-accordion class="custom-accordion" #acc="ngbAccordion" activeIds="ngb-panel-0">
              <ngb-panel id="ngb-panel-0">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="accordion-button">Request Of</button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row row-16">

                    <div class="col-12 form-row">
                      <label for="name" class="form-label ">Employee Code/Name</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employee Code/Name')}}"
                        formControlName="request_of" [items]="empDD" [multiple]="true" groupBy="selectedAllGroup"
                        [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                        bindValue="id" class="multiselect" [loading]="ddLoader">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                            [ngModelOptions]="{standalone: true}" /> Select All
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="filterForm.value.request_of.length">
                            <span class="ng-value-label">{{filterForm.value.request_of.length}}
                              Employee
                              selected.</span>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                              [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                              class="avatar-sm sq-24 img-fluid rounded-circle"
                              *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
                            <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                              class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appservice.getFirstChar(item.name,1)}}</span></span>
                            <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name |
                              slice:0:30)+'..':(item.name) }}</span>
                          </div>
                        </ng-template>
                      </ng-select>
                      <ul class="selected-values my-16" *ngIf="filterForm.value.request_of?.length !=0">
                        <li class="ng-value" *ngFor="let item of filterForm.value.request_of, let m =index">
                          <ng-container *ngFor="let insideitem of empDD, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex">
                                <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                  src="{{insideitem.profile_image}}">
                                <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                  class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                                  <span>
                                    {{this.appservice.getFirstChar(insideitem.first_name+"
                                    "+insideitem.last_name,2)}}</span>
                                </span>
                                <span class="mt-2 ms-1">
                                  {{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                  ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                </span>
                              </div>
                            </span>
                          </ng-container>
                          <span  class="ng-value-icon right" aria-hidden="true"
                            (click)="clearcommonForm('request_of',m)"><i class="icon-close"></i></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="ngb-panel-1">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="accordion-button">Request By</button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row row-16">

                    <div class="col-12 form-row">
                      <label for="name" class="form-label ">Employee Code/Name</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employee Code/Name')}}"
                        formControlName="request_by" [items]="empDD" [multiple]="true" groupBy="selectedAllGroup"
                        [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                        bindValue="id" class="multiselect"  [loading]="ddLoader">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                            [ngModelOptions]="{standalone: true}" /> Select All
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="filterForm.value.request_by.length">
                            <span class="ng-value-label">{{filterForm.value.request_by.length}}
                              Employee
                              selected.</span>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                              [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                              class="avatar-sm sq-24 img-fluid rounded-circle"
                              *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
                            <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                              class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appservice.getFirstChar(item.name,1)}}</span></span>
                            <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name |
                              slice:0:30)+'..':(item.name) }}</span>
                          </div>
                        </ng-template>
                      </ng-select>
                      <ul class="selected-values my-16" *ngIf="filterForm.value.request_by?.length !=0">
                        <li class="ng-value" *ngFor="let item of filterForm.value.request_by, let m =index">
                          <ng-container *ngFor="let insideitem of empDD, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex">
                                <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                  src="{{insideitem.profile_image}}">
                                <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                  class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                                  <span>
                                    {{this.appservice.getFirstChar(insideitem.first_name+"
                                    "+insideitem.last_name,2)}}</span>
                                </span>
                                <span class="mt-2 ms-1">
                                  {{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                  ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                </span>
                              </div>
                            </span>
                          </ng-container>
                          <span  class="ng-value-icon right" aria-hidden="true"
                            (click)="clearcommonForm('request_by',m)"><i class="icon-close"></i></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="ngb-panel-2">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="accordion-button">Pending with</button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row row-16">

                    <div class="col-12 form-row">
                      <label for="name" class="form-label ">Employee Code/Name</label>
                      <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employee Code/Name')}}"
                        formControlName="pending_with" [items]="empDD" [multiple]="true" groupBy="selectedAllGroup"
                        [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                        bindValue="id" class="multiselect"  [loading]="ddLoader">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                            [ngModelOptions]="{standalone: true}" /> Select All
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="filterForm.value.pending_with.length">
                            <span class="ng-value-label">{{filterForm.value.pending_with.length}}
                              Employee
                              selected.</span>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                              [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                              class="avatar-sm sq-24 img-fluid rounded-circle"
                              *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
                            <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                              class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appservice.getFirstChar(item.name,1)}}</span></span>
                            <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name |
                              slice:0:30)+'..':(item.name) }}</span>
                          </div>
                        </ng-template>
                      </ng-select>
                      <ul class="selected-values my-16" *ngIf="filterForm.value.pending_with?.length !=0">
                        <li class="ng-value" *ngFor="let item of filterForm.value.pending_with, let m =index">
                          <ng-container *ngFor="let insideitem of empDD, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex">
                                <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                  src="{{insideitem.profile_image}}">
                                <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                  class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                                  <span>
                                    {{this.appservice.getFirstChar(insideitem.first_name+"
                                    "+insideitem.last_name,2)}}</span>
                                </span>
                                <span class="mt-2 ms-1">
                                  {{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                  ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                </span>
                              </div>
                            </span>
                          </ng-container>
                          <span  class="ng-value-icon right" aria-hidden="true"
                            (click)="clearcommonForm('pending_with',m)"><i class="icon-close"></i></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- <ngb-panel id="ngb-panel-3">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="accordion-button">Request Type</button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row row-16">
                    <div class="col-12 form-row">
                      <label for="name" class="form-label">Type </label>
                      <ng-select dropdownPosition="top"
                        formControlName="request_type"
                        placeholder="{{this.messageService.selectplaceholddisplay('Request Type')}}">
                        <ng-option *ngFor="let item of request_type" [value]="item.id">{{ item.name }}</ng-option>
                      </ng-select>

                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm" (click)="resetFilter()">
        Reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="applyfilter()">
        Apply
      </button>
    </footer>
  </div>
</div>

<app-request-panel *ngIf="onBehalf" [emp_Id]="empId" [showSidePanel]="onBehalf" (closeReqPanel)="closeReqPanel()" (requestAction)="requestAction($event)" [from]="'behalf'"></app-request-panel>



<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" ><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">


      </div>
    </div>

  </div>
</div>

<!-- employee selection side pannel -->
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
[class.side-pane-active]='empSelection === true' [formGroup]="empForm">
<div class="side-panel-container" autocomplete="off">
  <header class="side-panel-head">
    <h5>Select employee</h5>
    <a class="toggle-panel" (click)="empSelection=false"><i class="icon-close-lg fs-16"></i></a>
  </header>
  <div class="side-panel-body" style="overflow-y: auto;">
    <div class="container-fluid p-32">

      <div class="row row-16">
        <div class="col-md-12 form-row">
          <label for="name" class="form-label ">Employee </label>
          <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employee')}}"
            formControlName="empid" [items]="empDD" class="form-ngselect" bindValue="id"
            bindLabel="fullname">
            <ng-template ng-label-tmp let-item="item">
              <span class="ng-value-label">
                <div class="d-inline-flex align-items-center">
                  <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null"
                    class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1">
                    <span>{{this.appservice.getFirstChar(item.fullname,2)}}</span>
                  </div>
                  <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"
                    *ngIf="item.profile_image !=  '' && item.profile_image !=  null"
                    [src]="item.profile_image">
                  {{item.fullname}}
                </div>
              </span> </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="d-inline-flex  align-items-center">
                <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                  *ngIf="item.profile_image !=  '' && item.profile_image != null"
                  src="{{item.profile_image}}">
                <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                  class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                  <span>
                    {{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                </span>
                {{item.fullname}}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <footer class="side-panel-footer">

    <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="setEmp()"  [disabled]="!empForm.valid" >
      Next
    </button>
  </footer>
</div>
</div>
<!-- Approval screens -->
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
[class.side-pane-active]='approvalScreen === true'>
<div class="side-panel-container" autocomplete="off">
  <header class="side-panel-head">
    <h5>{{title}}
            </h5>
    <a class="toggle-panel" (click)="approvalScreen=false"><i class="icon-close-lg fs-16"></i></a>
  </header>
  <div class="side-panel-body" style="overflow-y: auto;">
    <div class="container-fluid p-32">
      <div class="card card-c2 p-16">
        <app-leave-approval-inbox [requestitem]="requestitem" [from]="'behalf'" *ngIf="requestType=='Leave'"
        (successEmitter)="refreshPage($event)" (cancelEmitter)="cancelRequest($event)"></app-leave-approval-inbox>
        <app-appointment-letter-inbox *ngIf="requestType=='Confirmation Letter' || requestType=='Termination Letter' || requestType=='Probation Extension Letter' || requestType=='Relieving Letter' || requestType=='Appointment Letter' || requestType=='Salary Revision Letter'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)"></app-appointment-letter-inbox>
        <app-asset-request-inbox *ngIf="requestType=='Asset'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-asset-request-inbox>
        <app-attendance-regularization-inbox *ngIf="requestType=='Attendance Regularization'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'" [cancelStatus]="cancelStatus" (cancelEmitter)="cancelRequest($event)"></app-attendance-regularization-inbox>
        <app-checkin-inbox *ngIf="requestType=='Check-in Request'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-checkin-inbox>
        <app-compo-off-inbox *ngIf="requestType=='Compensatory Off'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-compo-off-inbox>
        <app-confirmation-req-inbox *ngIf="requestType=='Confirmation'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-confirmation-req-inbox>
        <app-confirmed-investment-inbox *ngIf="requestType=='Confirmed Investment Declaration' || requestType=='Proposed Investment Declaration'" [requestitem]="requestitem"
        (refreshEmitter)="refreshPage($event)" [from]="'behalf'"></app-confirmed-investment-inbox>
        <app-advance-dispense-inbox *ngIf="requestType=='Advance Dispense'|| requestType=='Expense Advance'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-advance-dispense-inbox>
        <app-employee-data-inbox [requestitem]="requestitem" *ngIf="requestType=='Employee Profile'"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-employee-data-inbox>
        <app-expense-report-inbox *ngIf="requestType=='Expense Report'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-expense-report-inbox>
        <app-fbp-inbox *ngIf="requestType=='FBP Declaration'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-fbp-inbox>
        <app-final-settlement-process-inbox *ngIf="requestType=='Final settlement Process'"
        [requestitem]="requestitem" ></app-final-settlement-process-inbox>
        <app-final-settlement-approval-inbox *ngIf="requestType=='Final settlement Approval'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-final-settlement-approval-inbox>
        <app-holiday-inbox [requestitem]="requestitem" *ngIf="requestType=='Holiday'"
        (successEmitter)="refreshPage($event)" [from]="'behalf'" (cancelEmitter)="cancelRequest($event)"></app-holiday-inbox>
        <app-manage-asset-inbox *ngIf="requestType=='Manage Asset'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-manage-asset-inbox>
        <app-onduty-inbox *ngIf="requestType=='On Duty'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'" [cancelStatus]="cancelStatus" (cancelEmitter)="cancelRequest($event)"></app-onduty-inbox>
        <app-overtime-inbox *ngIf="requestType=='Overtime'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-overtime-inbox>
        <app-reimbursement-inbox *ngIf="requestType=='Reimbursement'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'" [cancelStatus]="cancelStatus" (cancelEmitter)="cancelRequest($event)"></app-reimbursement-inbox>
        <app-salary-revision-inbox [requestitem]="requestitem" *ngIf="requestType=='Salary Revision'"
        (successEmitter)="refreshPage($event)" [from]="'behalf'"></app-salary-revision-inbox>
        <app-shortleave-inbox *ngIf="requestType=='Short Leave'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'" (cancelEmitter)="cancelRequest($event)"></app-shortleave-inbox>
        <app-wfh-inbox *ngIf="requestType=='Work From Home'" [requestitem]="requestitem"
        (successEmitter)="refreshPage($event)" [from]="'behalf'" (cancelEmitter)="cancelRequest($event)"></app-wfh-inbox>

      </div>
    </div>
  </div>
  <footer class="side-panel-footer" *ngIf="requestType=='Leave' || requestType=='Short Leave' || requestType=='Work From Home' || requestType=='Holiday'">
    <button *ngIf="requestType=='Leave' && !(requestitem?.leave_data?.cancellation_raised_status)" class="btn btn-outline-danger text-uppercase btn-sm fw-400" (click)="cancelLeaveRequest(requestitem?.leave_data?.cancellation_raised_status,requestitem?.leave_data?.arrear_valid,requestitem?.leave_data?.yearend_cancel_status)">
      CANCEL REQUEST
    </button>
    <button *ngIf="(requestType=='Short Leave' && requestitem?.request_data?.is_cancel == false)" class="btn btn-outline-danger text-uppercase btn-sm fw-400" (click)="cancelShorLeaveRequest(requestitem?.requests?.cancellation_raised_status)">
      CANCEL REQUEST
    </button>
    <button *ngIf="requestType=='Work From Home'  && !(requestitem?.request_data?.is_cancel)" class="btn btn-outline-danger text-uppercase btn-sm fw-400" (click)="cancelWFHRequest(requestitem?.requests?.cancellation_raised_status,requestitem?.requests?.arrear_valid)">
      CANCEL REQUEST
    </button>
    <button *ngIf="requestType=='Holiday' && (requestitem?.request_data?.is_cancel == false)" class="btn btn-outline-danger text-uppercase btn-sm fw-400" (click)="cancelHolidayRequest(requestitem?.data?.cancellation_raised_status,requestitem?.data?.arrear_valid)">
      CANCEL REQUEST

    </button>
    <app-cancel-request-modal *ngIf="requestCancel" [requestCancel]="requestCancel" [requestName]="'leave'" [requestId]="requestitem?.request_data?.id" [requestStatus]="requestitem?.leave_data?.status" (modal)="closeCancelModal($event)" [from]="'behalf'" (updateStatus)="cancelRequest($event)"></app-cancel-request-modal>


  </footer>
</div>
</div>
 <!-- Approval screen ends -->
  <!-- Request -->
<app-asset-request *ngIf="(requestName == 'Asset') && requestFlag == true" [assetRequest]="requestFlag" (requestSave)="requestSave($event)" (requestClose)="requestFlag = false" [from]="'behalf'" [requestFor]="selectedEmpData?.fullname" [empId]="empId"></app-asset-request>
<app-add-expense-advance *ngIf="requestName=='Expense Advance' && requestFlag == true" [addadvance]="requestFlag" [from]="'behalf'" [amountlimitdata]="requestItems?.amountlimitdata" (panelclose)="closefn($event)" (detailout)="requestSave($event)" [outstandingamount]="requestItems?.outstandingamount" [requestFor]="selectedEmpData?.fullname" [currency]>
</app-add-expense-advance>
<app-holiday-setup *ngIf="requestFlag==true && requestName=='Holiday'" [holidayRequestFlag]="requestFlag"
  [holidayFloatData]="holidayFloatData" [holidatSearchData]="holidatSearchData" [loader]="holiday_loader"
  [nodata]="holiday_nodata" [saveClicked]="saveClicked" (holidayReqClose)="closefn($event)"
  (holidaySearch)="holidaySearchFunction($event)" (holidayReqSave)="requestSave($event)" [from]="'behalf'" [empId]="empId" [requestFor]="selectedEmpData?.fullname">
</app-holiday-setup>
<app-compensatory-off-setup *ngIf="requestFlag==true && requestName=='Compensatory Off'" [reqFlag]="requestFlag" [listData]="requestItems?.compOffListData"
  [loader]="requestItems?.holiday_loader" [nodata]="requestItems?.holiday_nodata" (successEmitter)="requestSave($event)"
  (compOffClose)="closefn(false)" [from]="'behalf'" [empId]="empId" [requestFor]="selectedEmpData?.fullname"></app-compensatory-off-setup>

  <app-fbp-declaration-setup *ngIf="requestFlag== true && requestName=='FBP Declaration'" [fbdeclarationFlag]="requestFlag" (fbpReqSave)="requestSave($event)"
  (fbpReqClose)="closefn($event)" [from]="'behalf'" [empId]="empId" [requestFor]="selectedEmpData?.fullname"></app-fbp-declaration-setup>


   <!-- Request end -->

   <ng-container *ngIf="alertToggle == true">
    <app-info-popup (modal)="alertToggle=false" [infoToggle]="alertToggle" [infoMsg]="investalertMsg"></app-info-popup>
  </ng-container>

  <!-- Request page view -->
   <ng-container *ngIf="viewScreen==true">
    <ng-container *ngIf="requestType=='Leave'">
      <app-view-leave-request *ngIf="!loader1" [leaveRequestView]="true" [from]="'behalf'"
        [leaveRequestData]="viewData" [loader1]="loader1" [requestName]="requestType" [requestId]="requestId"
        [compOffData]="compOffData" (closeViewPage)="cancelRequest($event)"
        (leaveViewClose)="cancelViewClose($event)" [title]="title" [cancelStatus]="cancelStatus"></app-view-leave-request>
    </ng-container>
    <ng-container *ngIf="requestType=='Short Leave'">
      <app-view-short-leave *ngIf="!loader1" [loader]="loader1" [shortLeaveData]="viewData"
       [shortLeaveView]="true" [requestName]="requestType" [requestId]="requestId" (closeViewPage)="cancelRequest($event)"
       (shortLeaveClose)="cancelViewClose($event)" [from]="'behalf'"  [title]="title" ></app-view-short-leave>
    </ng-container>
    <ng-container *ngIf="requestType=='Holiday'">
      <app-holiday-request-view [holidayRequestView]="true" [loader]="loader1" [holidayRequestSingleData]="viewData" [from]="'behalf'" [requestName]="requestType"
       [requestId]="requestId" (holidayViewClose)="cancelViewClose($event)"
       (closeViewPage)="cancelRequest($event)"  [title]="title" [cancelStatus]="cancelStatus"></app-holiday-request-view>
    </ng-container>
    <ng-container *ngIf="requestType == 'Expense Advance' && !loader1">
      <app-emp-advance-details-view  [viewAdvance]="true" [from]="'expenseAdvanceRequestView'" [viewdata]="viewData.expense_advance" [viewLoader]="loader1" [currency]="viewData.currency" (panelclose)="cancelViewClose($event)" > </app-emp-advance-details-view>
    </ng-container>
    <ng-container  *ngIf="requestType == 'Final settlement Approval' && !loader1">
      <app-final-settelement-expansion [from]="'behalf view'" [employeeId]="viewData.employee.id"
        [showFinalSettlementView]="true" (closeExpansion)="cancelViewClose($event)"
      >
      </app-final-settelement-expansion>
    </ng-container>
    <ng-container *ngIf="viewPanel == true">
      <div class="side-panel" style="--sidepanel-width: 45rem" [class.side-pane-active]="viewPanel === true">
        <div class="side-panel-container">
          <header class="side-panel-head">
            <h5>{{title}}</h5>
            <a class="toggle-panel" (click)="viewPanel = false"><i class="icon-close-lg"></i></a>
          </header>
          <div class="side-panel-body" style="overflow-y: auto" *ngIf="requestType != 'Work From Home'">
            <div class="container-fluid px-32 py-24">

              <app-view-loader *ngIf="loader1"></app-view-loader>
              <ng-container *ngIf="requestType=='Confirmed Investment Declaration' || requestType=='Proposed Investment Declaration'">
                <div class="mb-12 d-flex align-items-center">
                  <span class="fs-12 ms-auto text-light-400 me-8">Status :</span>
                  <span class="fw-500 badge fs-9 text-uppercase py-1"
                    [ngClass]="{ 'badge-success1': viewData.active === true }">{{
                      viewData.active == true ? "ACTIVE" : "INACTIVE"
                    }}</span>
                </div>
                <app-view-investment-declaration [from]="'behalf'" [investmentSingleData]="viewData"
                  [viewHeader]="requestType" [hra80ggSingleData]="viewData.hra80gg"
                  [cahpt80CSingleData]="viewData.chapter4a80c" [otherSourceSingleData]="viewData.income_from_othersource"
                  [deductionSingleData]="viewData.chapter4adeduction" [incomeLossSingleData]="viewData.incomeloss_property"
                  [hra80ggFlag]="hra80ggFlag" [exeption_50]="exeption_50"
                  [investmentcard1]="true"  [taxData]="taxData"
                  [hra80gg]="hra80gg" [loader1]="loader1" [currency]="viewData.currency"
                  (investmentcardClose)="cancelViewClose($event)" [requestitem]="viewData"></app-view-investment-declaration>
              </ng-container>
              <app-view-employee-profile *ngIf="requestType=='Employee Profile'" [requestitem]="viewData" [reqId]="requestId" [loader]="loader1" [from]="'behalf'"
              ></app-view-employee-profile>
              <app-view-regularization-request *ngIf="requestType == 'Attendance Regularization' && !loader1"
                [regularizationCancel]="cancelStatus" [data]="viewData" [id]="requestId"
                [loader]="loader1" (closePanel)="cancelViewClose($event)" [from]="'behalf'" (closeViewPage)="cancelRequest($event)"></app-view-regularization-request>

              <app-view-on-duty-request *ngIf="requestType == 'On Duty' && !loader1" [onDutyData]="viewData"
                [onDutyCancel]="cancelStatus" [onDutyID]="requestId" [loader]="loader1"
                (closePanel)="cancelViewClose($event)" [from]="'behalf'" (closeViewPage)="cancelRequest($event)"></app-view-on-duty-request>

              <app-view-comp-off-request *ngIf="!loader1 && requestType == 'Compensatory Off'"
                [viewData]="viewData"></app-view-comp-off-request>
              <app-view-overtime-request *ngIf="!loader1 && requestType == 'Overtime'"
                [viewData]="viewData"></app-view-overtime-request>

              <app-view-checkin-request *ngIf="!loader1 && requestType == 'Check-in Request'" [viewData]="viewData"
                [checkInId]="requestId" ></app-view-checkin-request>

              <!-- Asset -->
              <app-view-asset-request *ngIf="!loader1 && requestType == 'Asset'" [viewData]="viewData" [requestId]="requestId" ></app-view-asset-request>


              <!-- Expense Module -->
                <app-view-expense-report-request
                *ngIf="!loader1 && requestType == 'Expense Report'"
                [viewData]="viewData" [loader]="loader1" [currency]="viewData?.data?.currency"
              ></app-view-expense-report-request>

              <!-- End -->
              <app-view-reimbursement *ngIf="requestType == 'Reimbursement'" [reimbursementData]="viewData" [loader1]="loader1" [requestId]="requestId"
              [from]="'behalf'" [reimbursementType]="'Reimbursement'" (closeViewPage)="closeViewPage($event)" (closeViewPage)="cancelRequest($event)">
              </app-view-reimbursement>
              <ng-container *ngIf="requestType == 'FBP Declaration'">
                <div class="d-flex mb-16">
                  <p class="text-uppercase fw-500 mb-0">FBP Declaration Request</p>
                </div>

                  <app-view-fbp-declaration [fbpReqViewData]="viewData.fbp_request_category" [currency]="viewData.currency" [from]="'behalf'"
                    [fbpStatus]="viewData.status"></app-view-fbp-declaration>

              </ng-container>

            </div>
            <app-salary-revision-details-view  *ngIf="requestType == 'Salary Revision'" [requestitem]="requestitem" [from]="'behalf'" [hisId]="viewData.request_object"
            (refreshEmitter)="cancelRequest($event)"></app-salary-revision-details-view>
          </div>

          <app-view-wfh-request *ngIf="requestType == 'Work From Home'" [loader1]="loader1"
            [workFromHomedata]="viewData" [workFromHomecancel]="cancelStatus"
            (closePanel)="cancelViewClose($event)" [from]="'behalf'"  (closeViewPage)="cancelRequest($event)" ></app-view-wfh-request>

        </div>
      </div>
    </ng-container>
   </ng-container>
   <!-- End -->
