import { Component, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-checkin-verification',
  templateUrl: './checkin-verification.component.html',
  styleUrls: ['./checkin-verification.component.scss']
})
export class CheckinVerificationComponent implements OnInit {
  @Input() verificationModal:any
  @Input() failedmodal:any
  @Input() WebCamDataUrl:any
  @Input() VerifyText:any
  @Input() retake:any
  @Input() VerifyClass:any
  @Output() close = new EventEmitter()
  @Output() retakeFn = new EventEmitter()
  constructor(
    public snapshotService:SnapshotService,public appService:AppService
  ) { }

  ngOnInit(): void {
  }

}
