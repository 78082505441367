import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import * as moment from 'moment';
@Component({
  selector: 'app-final-settlement-investment-declaration',
  templateUrl: './final-settlement-investment-declaration.component.html',
  styleUrls: ['./final-settlement-investment-declaration.component.scss']
})
export class FinalSettlementInvestmentDeclarationComponent implements OnInit {

  @Input() empId:any;
  @Input() payrollStatus:any;

  @Output() submitmethod  = new EventEmitter();
  @Output() warningMsg    = new EventEmitter();

  showComponent                   = false;
  nodata                          = false;
  from                            = 'fnfIT';
  investmentSingleData:any        = [];
  hra80ggSingleData:any           = [];
  cahpt80CSingleData:any          = [];
  otherSourceSingleData:any       = [];
  diductionSingleData:any         = [];
  incomeLossSingleData:any        = [];
  hra80ggFlag:any                 = false;
  taxData:any                     = [];
  hra80gg:any;
  exeption_50:any                 = [];
  loader1                         = false;
  alertShow                       = 'show';
  reqType:any;
  currency:any;

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb:FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getFnFItdeclarationDetails();
  }

  addITdeclaration(type:any){
    if(this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish'){
      this.warningMsg.emit();
    }else{
      this.showComponent = true;
      this.reqType = type;
    }
  }

  submitItDeclaration(){
    this.submitmethod.emit();
  }

  getFnFItdeclarationDetails(){
    this.loader1 = true;
    this.finalSettlementService.getFnFItDeclarationList(this.empId, this.appService.getFinancialYear()).subscribe((res: any) => {
      this.currency              = res?.data?.currency;
      if(res?.data?.confirmed?.length == 0 && res?.data?.proposed?.length == 0){
        this.nodata = true;
      }else if(res?.data?.confirmed?.length != 0){
        this.nodata = false;
        this.investmentSingleData  = res?.data?.confirmed[0];
        this.hra80ggSingleData     = res?.data?.confirmed[0]?.hra80gg;
        this.cahpt80CSingleData    = res?.data?.confirmed[0]?.chapter4a80c;
        this.diductionSingleData   = res?.data?.confirmed[0]?.chapter4adeduction;
        this.otherSourceSingleData = res?.data?.confirmed[0]?.income_from_othersource;
        this.incomeLossSingleData  = res?.data?.confirmed[0]?.incomeloss_property;
        this.taxData               = JSON.parse(res?.data?.confirmed[0]?.declared_datail);
        this.currency              = res?.data?.currency;

        if(this.taxData?.excemption_declaration?.hasOwnProperty('hra'))
        this.hra80gg='hra';
      if(this.taxData?.excemption_declaration?.hasOwnProperty('80gg'))
        this.hra80gg='80gg';
        if(res.hra80gg?.length > 0){
            if(res.hra80gg[0]['select_one'] == true)
              this.hra80ggFlag = true;
            else
              this.hra80ggFlag = false;
        }

        if(this.diductionSingleData?.donate_80g?.length > 0){
          for(let i=0; i< this.diductionSingleData?.donate_80g?.length; i++){
            if(!this.diductionSingleData?.donate_80g[i]?.exemption){
              this.exeption_50.push(this.diductionSingleData?.donate_80g[i])
            }
          }
        }
      }else if(res?.data?.confirmed?.length == 0 && res?.data?.proposed?.length != 0){
        this.nodata   = false;
        this.investmentSingleData  = res?.data?.proposed[0];
        this.hra80ggSingleData     = res?.data?.proposed[0]?.hra80gg;
        this.cahpt80CSingleData    = res?.data?.proposed[0]?.chapter4a80c;
        this.diductionSingleData   = res?.data?.proposed[0]?.chapter4adeduction;
        this.otherSourceSingleData = res?.data?.proposed[0]?.income_from_othersource;
        this.incomeLossSingleData  = res?.data?.proposed[0]?.incomeloss_property;
        this.taxData               = JSON.parse(res?.data?.proposed[0]?.declared_datail);
        this.currency              = res?.data?.currency;

        if(this.taxData?.excemption_declaration?.hasOwnProperty('hra'))
        this.hra80gg='hra';
      if(this.taxData?.excemption_declaration?.hasOwnProperty('80gg'))
        this.hra80gg='80gg';
        if(res.hra80gg?.length > 0){
            if(res.hra80gg[0]['select_one'] == true)
              this.hra80ggFlag = true;
            else
              this.hra80ggFlag = false;
        }

        if(this.diductionSingleData?.donate_80g?.length > 0){
          for(let i=0; i< this.diductionSingleData?.donate_80g?.length; i++){
            if(!this.diductionSingleData?.donate_80g[i]?.exemption){
              this.exeption_50.push(this.diductionSingleData?.donate_80g[i])
            }
          }
        }
      }
      this.loader1 = false;
    });
  }

  closeSidePanel(data:any){
    // this.investmentDeclerationView = data;
  }

  close(){
    this.showComponent = false;
    this.getFnFItdeclarationDetails();
  }

}
