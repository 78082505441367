import { Component, OnInit, ViewChild } from '@angular/core';
import { BusinessunitService } from 'src/app/@core/services/businessunit.service';
import { CompanyService } from 'src/app/@core/services/company.service';
import { FormBuilder, Validators  } from '@angular/forms';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
​import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'
@Component({
  selector: 'app-business-unit',
  templateUrl: './business-unit.component.html',
  styleUrls: ['./business-unit.component.scss']
})
export class BusinessUnitComponent implements OnInit {
  buViewis_active:any;
  buViewid:any;
  buViewname:any;
  buViewcompany:any;
  showSidePanel1 =false;
  searchlistdata:any = [];
  companyDropdown:any = [];
  bussinessUnitDetails:any = [];
  bussinessUnitList:any = [];
  businesssearch : string = this.messageService.searchdisplay('business unit');
  showSidePanel =false;
  deleteClass =false;
  header = '';
  addEditBtn = 'Save';
  inputFocusClass = false;
  validationmsg = false;
  is_active : boolean = true;
  businessUnitIdEdit:any = '';
  deleteToggle= false;
  deleteId : any;
  public bu_list_flag: boolean = true;
  submitted = false;
  status_filter :string = 'true';
  disabled = false;
  loader = true;
  deleteClicked = false
  checkoutForm = this.formBuilder.group({
    id: 0,
    name: ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    company: ['', Validators.required]
  });
  loader1 = false;
  statusBtn:any = "Active";
  bulogo:any;

  validated : boolean = false
  bussinessUnit : any =[]
  editflag : boolean = false
  value :any
  isLoading = false
  searchData : Subject<void> = new Subject();
  busId = 0
  private subjectKeyUp = new Subject<any>();


  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  buDetailsList:any       = [];
  infinityloader          = false;
  permissions:any = [];
  searchKeyword="";
  // unamePattern =  "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";
  @ViewChild(SearchbarComponent) child:any;
  colors = ["bg0","bg1","bg2","bg3","bg4"];
  randColor(){
    return this.colors[Math.floor(Math.random() * this.colors.length)];
  }


​constructor(
    private businessunitService : BusinessunitService,
    private companyService : CompanyService,
    private formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public router: Router,
    private route: ActivatedRoute,
    public messageService : MessageService,
    public existDataService :  ExistDatasService
  ) { }
​
  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.getPermission();

​
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.businessUnitList()
    this.companyService.companyDropDownData('true').subscribe((res: any) => {
      this.companyDropdown = res.body;
      this.loader = false;
    });


    this.searchData.pipe(delay(1000),
    switchMap(()=> this.businessunitService.getBusinessUnitList(this.status_filter,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
​
​
​
  // add form
  addBuForm(){
    this.showSidePanel = true
    this.header = "Add"
    this.addEditBtn = "Save"
    this.deleteClass = false
    this.checkoutForm.reset({ id: 0});
    this.checkoutForm.enable();
    this.submitted= false;
    this.disabled = false;
    this.editflag = false;
    this.child.clearBar();
  }
​
  // edit form
  editBuForm(id:any){
    this.showSidePanel1 = false;
    this.editflag = true;
    this.buViewid=id;
    this.loader1 = true;
    this.showSidePanel = true
    this.addEditBtn = "Save"
    this.submitted= false;
​
    this.businessunitService.getBusinessUnit(id).subscribe((res: any) => {
      this.deleteClass = true;
      this.loader1 = false;
      this.header = res.is_active==true?"Edit":"View";
      if(res.is_active==false){
        this.checkoutForm.disable();
        this.disabled = true;
​
​
      }
      else{
        this.checkoutForm.enable();
        this.disabled = false;
​
      }
​
      this.checkoutForm.reset({ id: res.id, name: res.name, company: res.company});
​
    });
​
  }
  /********* STATUS FILTER **************/
  selectItemList(value:any){
    this.offset                 = 0;
    this.bussinessUnitList      = [];
    this.status_filter          = value
    this.businessUnitList();
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.businessUnitList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.bussinessUnitList.push(this.buDetailsList[i]);
    }
  }
  searchBU(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.busId = 0
    this.offset            = 0;
    this.bussinessUnitList = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }
  businessUnitList(){
    this.loader = true;
    this.businessunitService.getBusinessUnitList(this.status_filter,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }
​
  arrangeApiData(res:any){
    this.isLoading = false
    if(this.offset ==0){
      this.bussinessUnitList = [];
    }
    if (res.results.length == 0) {
      this.bu_list_flag = (this.offset == 0) ? false :true;
      this.listlength  = 0;
    } else {
      this.bu_list_flag = true;
      this.listlength = res.results.length;
      this.buDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }
​
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.busId = 0
      this.bussinessUnitList = [];
      this.searchData.next()
    }else{
      this.busId = data
      this.searchData.next()
    }
  }
​
  BUFormSubmit(){
    this.submitted = true;
    // return if validation fails
    if(this.checkoutForm.invalid){
      return
    }
​   this.disabled = true;
​
    if(this.header === "Add"){
      // add api
      this.businessunitService.createBusinessUnit(this.checkoutForm.value).subscribe((res: any) => {
​
        this.showSidePanel = false,
        this.offset = 0;
        this.bussinessUnitList = [];
        this.businessUnitList()
      },
      (err: any) => {
​       this.disabled = false;
      });
​
    }else{
      //  API for update Grade
      this.businessunitService.updateBusinessUnit( this.checkoutForm.value.id, this.checkoutForm.value).subscribe((res: any) => {
        this.offset = 0;
        this.bussinessUnitList = [];
        this.showSidePanel = false,
        this.businessUnitList()
      },
      (err: any) => {
        this.disabled = false;
      });
    }
  }
​
  // active status filter
  listFilter(value:string=''){
    this.offset            = 0;
    this.bussinessUnitList = [];
    this.status_filter = value
    this.child.clearBar();
  }

  deletebu(id:any){
    this.checkoutForm.value.id = id;
    this.deleteToggle = true;
  }

  confirmDelete(){
    this.deleteClicked = true
    this.businessunitService.deleteBusinessUnit(this.checkoutForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.deleteToggle = false,
      this.showSidePanel = false,
      this.offset = 0;
      this.bussinessUnitList = [];
      this.businessUnitList()
      this.deleteClicked = false
    },
    (err: any) => {
​       this.deleteClicked = false
      this.deleteToggle = false
      this.showSidePanel = false
​
    });
  }
​
  get f() {
    return this.checkoutForm.controls;
  }
  viewBU(id:any){
    if(this.permissions.v){
      this.showSidePanel1 = true;
      this.loader1 = true;
      this.businessunitService.getBusinessUnit(id).subscribe((res: any) => {
        this.buViewid=res.id;
        this.buViewname = res.name;
        this.buViewcompany = res.company_name;
        this.buViewis_active = res.is_active;
        this.bulogo = res.company_logo;
        this.loader1 = false;
      })
    }
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":"BusinessUnit",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.buViewid,
        "page":"BusinessUnit",
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.bussinessUnit = res.body

      this.loader = false

      if(this.bussinessUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }
}
