<form action="" class="" [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row row-12 mb-24">
      <div class="col-12">
        <p class="text-uppercase mb-0 fw-500 ">Statutory details </p>
      </div>
      <div class="col-lg-12 mt-16 form-row">
        <label for="" class="form-label required">Select whichever is applicable</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover='Select whichever statutory properties will be applicable for this component.'
            popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
      </div>
      <div class="col-12 ">
        <div class="form-check ">
          <input type="checkbox" class="form-check-input" name="" id="part-tax" formControlName="pf_contribution" (change)="pfContribution($event)">
          <label for="part-tax" class="form-check-label ">Consider for PF contribution</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover='If this option is unchecked, no PF deduction will be calculated on this component. If this option is checked, Then select any of the below options.'
              popoverClass="popover-default popover-info" placement="bottom" container="body"
              triggers="hover"></i></span>
        </div>
      </div>
      <ng-container *ngIf="f.pf_contribution.value==true">
        <div class="col-12 ms-20">
          <div class="form-check ">
            <!-- <input type="checkbox" class="form-check-input" name="" id="pf_always" formControlName="pf_always"> -->
            <input class="form-check-input" type="radio" id="pf_always" formControlName="pf_always"
            [value]="true">
            <label for="pf_always" class="form-check-label ">Always consider for PF contribution</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                ngbPopover='Select this option if you desire to always consider this component for PF contribution. For example, if the paycomponents Basic and Sepcial allowance have this option selected, the system will always consider these two components for PF contribution.'
                popoverClass="popover-default popover-info" placement="bottom" container="body"
                triggers="hover"></i></span>
          </div>
        </div>
        <div class="col-12 ms-20">
          <div class="form-check ">
            <!-- <input type="checkbox" class="form-check-input" name="" id="pf_wage" formControlName="pf_always"> -->
            <input class="form-check-input" type="radio" id="pf_wage" formControlName="pf_always"
            [value]="false">
            <label for="pf_wage" class="form-check-label ">Consider for PF contribution only when PF wage is less than
              15000</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                ngbPopover='If you want the Component to be considered for PF contribution when the PF wage is less than 15000, choose this option. If the sum of PF wage exceeds 15000 when this component is included, the EPF will be calculated only for a maximum amount of 15000.'
                popoverClass="popover-default popover-info" placement="bottom" container="body"
                triggers="hover"></i></span>
          </div>
        </div>
      </ng-container>
      <div class="col-12 ">
        <div class="form-check ">
          <input type="checkbox" class="form-check-input" name="" id="pt_contribution"
            formControlName="pt_contribution">
          <label for="pt_contribution" class="form-check-label ">Consider for PT deduction</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover='Select this option if you want to consider  this  paycomponent for PT deduction. PT deduction is done based on the Gross earnings and the state to which the employee is mapped to.'
              popoverClass="popover-default popover-info" placement="bottom" container="body"
              triggers="hover"></i></span>
        </div>
      </div>
      <div class="col-12 ">
        <div class="form-check ">
          <input type="checkbox" class="form-check-input" name="" id="part-Insurance"
            formControlName="esi_contribution">
          <label for="part-Insurance" class="form-check-label ">Consider for ESI contribution</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover='Select this option if you want to consider  this  paycomponent for ESI deduction.'
              popoverClass="popover-default popover-info" placement="bottom" container="body"
              triggers="hover"></i></span>
        </div>
      </div>
      <div class="col-12 ">
        <div class="form-check ">
          <input type="checkbox" class="form-check-input" name="" id="esi_limit" formControlName="esi_limit">
          <label for="esi_limit" class="form-check-label ">Consider checking the limit of ESI applicability</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover='Pay Components for which ESI limit applicability is enabled, will be considered to check ESI limit of Rs 21000. If sum of those pay components are less than 21000 then ESI will be deducted for the employee or else no ESI will be deducted.'
              popoverClass="popover-default popover-info" placement="bottom" container="body"
              triggers="hover"></i></span>
        </div>
      </div>
    </div>
      <div class="row mb-24  row-8">
      <div class="col-lg-6 form-row">
        <label for="roundoff" class="form-label required">Round off configuration </label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="select this option if you want to round of the value
            i)ceil option rounds the next highest number eg: 100.72 = 101
            ii)nearest option rounds of the nearest number eg: 5336.49 = 5336"
            popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        <ng-select class="form-ngselect"
          placeholder="{{this.messageService.selectplaceholddisplay('round off configuration')}}"
          formControlName="roundoff"
          [ngClass]="{ 'is-invalid': (submitted || f.roundoff.touched) &&  f.roundoff.errors}">
          <ng-option value="Ceil">Ceil</ng-option>
          <ng-option value="Nearest">Nearest</ng-option>
          <ng-option value="None">None</ng-option>
        </ng-select>
        <div *ngIf="(submitted || f.roundoff.touched) && f.roundoff.errors" class="invalid-feedback">
          <div *ngIf="f.roundoff.errors.required">{{this.messageService.validationDisplay('required')}}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-24  row-8">
      <div class="col-12 form-row">
        <label for="" class="form-label required">Show on payslip </label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover='To show a pay component on the employees payslip, select "Yes” '
            popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
      </div>
      <div class="col-lg-6 form-row">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="flexRadioDefault1" formControlName="show_salary_slip"
            [value]="true">
          <label class="form-check-label" for="flexRadioDefault1">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="flexRadioDefault2" formControlName="show_salary_slip"
            [value]="false">
          <label class="form-check-label" for="flexRadioDefault2">
            No
          </label>
        </div>
      </div>
    </div>
    <div class="row mb-24  row-8">
      <div class="col-12 form-row">
        <label for="" class="form-label required">Show on salary register</label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover='To show a pay component on the employees salary register, select "Yes” '
            popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
      </div>
      <div class="col-lg-6 form-row">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="flexRadioDefault1" formControlName="show_salary_register"
            [value]="true" (change)="handleChange(true)">
          <label class="form-check-label" for="flexRadioDefault1">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="flexRadioDefault2" formControlName="show_salary_register"
            [value]="false" (change)="handleChange(false)">
          <label class="form-check-label" for="flexRadioDefault2">
            No
          </label>
        </div>
      </div>
    </div>
    <div class="row mb-24 " *ngIf="sort_order">
      <div class="col-lg-6 form-row">
        <label for="salary_register_order" class="form-label required">Salary register sort order </label>
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover="If the component is to be displayed on the salary register, we must provide the salary register sort order. This sort order will be followed wherever we have component level break up, such as salary registers, payslips, YTD, reports, and so on."
            popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        <input type="text" class="form-control" autocomplete="off" name="name" id="name"
          placeholder="{{this.messageService.placeholderdisp('salary register sort order')}}"
          formControlName="salary_register_order"
          [ngClass]="{ 'is-invalid': (submitted || f.salary_register_order.touched || f.salary_register_order.dirty || sortError==1 ) &&  (f.salary_register_order.errors || sortError==1 ) || f.salary_register_order.errors?.exist}"
          (keyup)="keyUp()">
        <div
          *ngIf="(submitted || f.salary_register_order.touched || f.salary_register_order.dirty || sortError==1 ) &&  (f.salary_register_order.errors || sortError==1) || f.salary_register_order.errors?.exist"
          class="invalid-feedback">
          <div *ngIf="f.salary_register_order.errors?.pattern">Please enter valid Salary register sort order.</div>
          <div *ngIf="f.salary_register_order.errors?.required">{{this.messageService.validationDisplay('required')}}
          </div>
          <div *ngIf="!(f.salary_register_order.errors?.pattern) && f.salary_register_order.errors?.maxlength">
            {{this.messageService.fieldlengthvalidation('num','4')}}</div>
          <div *ngIf="sortError==1 && !(f.salary_register_order?.errors)">
            {{this.messageService.validationDisplay('salary register sort order')}}
          </div>
          <div *ngIf="f.salary_register_order.hasError('exist')">{{this.messageService.validationDisplay('salary
            register sort order')}}</div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid && !formGp.disabled"
      (click)="validateStatuatory()">NEXT</button>
  </footer>
</form>
