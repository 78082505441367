<div class="side-panel-body" style="overflow-y: auto;">
    <div class="container-fluid p-32 ">
        <div class="col-12">
            <h5>DETAILS</h5>
        </div>
        <div class="card card-c2 p-32">
            <div class="row  row-16">

                <div class="col-12 d-flex ">
                    <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
                </div>
                <div class="col-12 mt-8">
                    <h5 class="mb-8">Compensatory Off Approval</h5>
                    <span class=" badge-custom {{data?.request_type_color_code}}">Comp Off Approval</span>
                </div>
                <div class="col-12">
                    <div class="row row-8">
                        <div class="col-12">
                            <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                                <div class="row row-24">
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <span class="title">1 comp off generated</span>
                                            <span class="text-accent2 pointer ms-auto fs-12 fw-500"
                                                (click)="timegraph();timeOffTrend = true"><i
                                                    class="bi bi-graph-up me-1"></i>Comp off
                                                Trend</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="card border-0 card-c5 month-active text-light-500">
                                            <div class="card-body radius-4 text-center {{data.request_type_color_code}} flex-center flex-column text-light-600"
                                                style="width: 5.1875rem;">
                                                <div class=" fs-12 text-uppercase lh-1">
                                                    {{CompOff?.day_of_week}}</div>
                                                <div class="fw-500">{{CompOff?.attendance_date | date
                                                    : 'd'}}
                                                    {{CompOff?.attendance_date | date : 'MMM'}}</div>
                                            </div>
                                            <div class="card-body justify-content-center p-0 d-flex flex-column">
                                                <div class="card card-c2 border-0 px-24">
                                                    <div class="card-body px-0 py-12">
                                                        <div class="row row-16">
                                                            <div class="col-12">
                                                                <span class="fs-14 fw-500">Actual Shift Time</span>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Checkin </div>
                                                                <div class="detail-desc">
                                                                    {{this.appservice.timeFormatDisplay(CompOff?.actual_shift_details?.check_in)}}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Checkout</div>
                                                                <div class="detail-desc">
                                                                    {{this.appservice.timeFormatDisplay(CompOff?.actual_shift_details?.check_out)}}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Total hours</div>
                                                                <div class="detail-desc">
                                                                    {{(CompOff?.actual_shift_details?.total_hour
                                                                    == '' ||
                                                                    CompOff?.actual_shift_details?.total_hour
                                                                    == null) ? '-' :
                                                                    this.appservice.hhmmTimeFormat(CompOff?.actual_shift_details?.total_hour)}}
                                                                    Hours</div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-body px-0 py-12">
                                                        <div class="row row-16">
                                                            <div class="col-12">
                                                                <span class="fs-14 fw-500">Employee Checkin and Checkout
                                                                    Time</span>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Checkin </div>
                                                                <div class="detail-desc">
                                                                    {{this.appservice.timeFormatDisplay(CompOff?.checkin_time)}}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Checkout</div>
                                                                <div class="detail-desc">
                                                                    {{this.appservice.timeFormatDisplay(CompOff?.checkout_time)}}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Total working hours</div>
                                                                <div class="detail-desc">
                                                                    {{this.appservice.hhmmTimeFormat(CompOff?.total_work_hours)}}
                                                                    Hours</div>

                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Extra working hours</div>
                                                                <div class="detail-desc">
                                                                    {{this.appservice.hhmmTimeFormat(CompOff?.extra_work_hours)}}
                                                                    Hours</div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="detail-title">Compensatory off generated
                                                                </div>
                                                                <div class="detail-desc">
                                                                    {{CompOff?.eligible_compoff ==
                                                                    'Full Day' ? '1 Day' : '0.5 Day'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="reqStatus == 'Pending'" class="col-12">
                    <form class="row row-16 ">
                        <ng-container [formGroup]="form">
                            <div class="col-12">
                                <textarea name="" id="" rows="3"
                                    placeholder="{{this.messageService.descriptiontext('comment')}}"
                                    formControlName="comment" [ngClass]="{ 'is-invalid': f.comment.errors}"
                                    class="form-control textarea-comment"></textarea>
                                <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
                                    {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                            </div>
                        </ng-container>
                        <div class="col-12 d-flex gap-16">
                            <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                                (click)="confirm('Rejected')">Decline</button>
                            <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                                (click)="confirm('Approved')">Confirm</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Trend Graph Sidebar -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='timeOffTrend === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Comp off trend</h5>
            <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-24">
                    <div class="col-12 ">
                        <div class="card card-c2 pb-24  px-24 pt-24">
                            <div class="row row-12">
                                <div class="col-12 d-flex">
                                    <div class="fs-24 me-auto">Comp off trend - {{selectedYear}} </div>
                                    <ng-select class="form-ngselect ms-auto fs-14"
                                        placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                                        (change)="getCompOffTrendDetails($event)" [(ngModel)]="selectedYearDisp"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option class="fs-12" *ngFor="let year of yearList"
                                            [value]="year.k">{{year.k}}</ng-option>
                                    </ng-select>
                                </div>
                                <div *ngIf="barchartLoader" class="col-12">

                                    <app-graph-loader></app-graph-loader>
                                </div>
                                <div *ngIf="!barchartLoader" class="col-12">

                                    <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
                                        [yText]="barChartYAxisLabel" [id]="0"></app-bar-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>