<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Details</h5>
    <button class="btn-reset text-light-600" routerLink=" " (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <div class="container-fluid p-32 form-container">
      <app-my-proposed-declaration *ngIf="activeToggle == 'proposed'" [from]="from" [emp_id]="emp_id"></app-my-proposed-declaration>
      <app-my-confirm-declaration *ngIf="activeToggle == 'confirmed'" [from]="from" [emp_id]="emp_id"></app-my-confirm-declaration>
    </div>

  </main>
</div>