import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { MessageService } from 'src/app/message.global';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs'

@Component({
  selector: 'app-pc-statuatory-details',
  templateUrl: './pc-statuatory-details.component.html',
  styleUrls: ['./pc-statuatory-details.component.scss']
})
export class PcStatuatoryDetailsComponent implements OnInit {

  checkarray:any                = [];
  sort_order                    = false;
  checkBoxError                 = false;
  submitted : boolean           = false;
  @Input()disabled              = false;
  @Output() submitmethod        = new EventEmitter();
  @Output() sortFlagSet         = new EventEmitter();
  @Input()sortError:any;
  @Input() editflag:any
  @Input()formGp : FormGroup    = this.fb.group({
                                    // provident_fund          : [''],
                                    // professional_tax        : [''],
                                    // employee_insurance      : [''],
                                    // limit_esi               : [''],
                                    // roundoff                : [''],
                                    show_salary_slip        : [''],
                                    show_salary_register    : [''],
                                    salary_register_order   : [''],
                                    possible_checkbox       : ['']
                                  });

  payStatutoryUnit : any =[]
  value:any
  private subjectKeyUp = new Subject<any>();
  id:any

  constructor(private fb:FormBuilder,public messageService : MessageService,public route: ActivatedRoute,
    public existDataService :  ExistDatasService   ) { }

  ngOnInit(): void {

    this.subjectKeyUp.pipe(debounceTime(400)).subscribe((d:any)=>{
      this.existService();
    })

    this.id = this.route.snapshot.params['id']

    if(this.formGp.getRawValue().show_salary_register==true)
      this.sort_order = true;

    else
      this.sort_order = false;
      // this.existService();

  }

  handleChange(status:any){
    this.sort_order = status;
    if(status==false)
    this.formGp.get('salary_register_order')?.setValue(0);
    else
    this.formGp.get('salary_register_order')?.setValue('');


  }
  validateStatuatory()

  {

    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit();
  }


  get f() { return this.formGp.controls; }
  validateSortOrder(){

    this.sortError = false;
    this.sortFlagSet.emit(this.sortError);
  }

   // Simplified function to use for validation
   existService(){
    if(!this.editflag){
      this.value ={
        "page": "paycomponent",
        "field":"salary_register_order",
        "value": this.f.salary_register_order.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"paycomponent",
        "field":"salary_register_order",
        "value": this.f.salary_register_order.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.payStatutoryUnit = res.body

      if(this.payStatutoryUnit.data ==='invalid'){
        this.f.salary_register_order.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)

  keyUp(){
    this.sortError = 0;
    this.subjectKeyUp.next(this.f.salary_register_order.value)
  }
  pfContribution(event:any){
    if(!(event.target.checked)){
      this.formGp.get('pf_always')?.setValue(false);
    }
    else{
      this.formGp.get('pf_always')?.setValue(true);

    }
  }
}
