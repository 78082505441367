import {Component, EventEmitter, Input, OnInit, Output,OnChanges,SimpleChanges  } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-override-pt',
  templateUrl: './override-pt.component.html',
  styleUrls: ['./override-pt.component.scss']
})
export class OverridePtComponent implements OnInit {
 
  @Input() status = 'PENDING';
  @Input() page = 1
  @Input() searchString:any = "";

  
  @Input() data:any = [];
  @Input() loader = false;
  @Input() pageSize:any;
  @Input() employeeList:any = [];
  @Input() HistoryData:any = [];
  @Input() selctedData:any = [];
  @Output() changeSelectedEmployee = new EventEmitter<{empId: any,index:any,page:any}>();
  @Output() checkall = new EventEmitter<{searchString: any,page:any,checked:any,status:any}>();
  @Output() deleteSingleRow = new EventEmitter<{searchString: any,page:any,index:any}>();
  @Output() deleteCompleted = new EventEmitter<{id: any}>();
  @Output() validateField = new EventEmitter<{i: any,field:any}>();
  @Output() focusoutfield = new EventEmitter<{i: any,field:any}>();
  empArr : any = []
  constructor(public messageService : MessageService,public appService: AppService) { }

  ngOnInit(): void {
  }
  setEmpCode(data:any,i:any){
    this.changeSelectedEmployee.emit({empId:data,index:i,page:this.page});
  }

  checkAll(event:any){
    this.checkall.emit({searchString:this.searchString,page:this.page,checked:event.target.checked,status:this.status});
  }
  checkAllSelected(){
    if(this.status == 'PENDING'){
      var newList = this.appService.filterIt(this.data,this.searchString);
      var begin = (this.page-1) * this.pageSize ;
      var end = this.page* this.pageSize;
      var subArray = newList.slice(begin,end);
      if(subArray.length){
        return subArray.every((item: { checked: any; }) => {
          return item.checked;
        });
      } else {
        return false;
      }
    } else {
      var newList = this.appService.filterIt(this.HistoryData,this.searchString);
      var begin = (this.page-1) * this.pageSize ;
      var end = this.page* this.pageSize;
      var subArray = newList.slice(begin,end);
      if(subArray.length){
        return subArray.every((item: { checked: any; }) => {
          return item.checked;
        });
      } else {
        return false;
      }
    }
  }
  deleteRow(index:any){
    this.deleteSingleRow.emit({searchString:this.searchString,page:this.page,index:index});
    let lastpage = Math.ceil(this.data.length / this.pageSize);
    if(this.page>lastpage){
      this.page = lastpage>0?lastpage:1
    }
  }
  deleteCompletedRow(id:any){
    this.deleteCompleted.emit({id:id})
  }

  validateRow(i:any,field:any){
    this.validateField.emit({i:i,field:field})
  }
  focusOutfield(i:any,field:any){
    this.focusoutfield.emit({i:i,field:field})
  }

  checkifNoInputError(index:any){
    // let index:any                   = this.pageSize*(this.page-1)+ i;
    var rowvalid:any = true
    if(this.data[index].inputerror!=undefined){
      if(this.data[index].inputerror?.employee_overide != undefined && !this.data[index].inputerror?.employee_overide?.is_valid)
          rowvalid = false
      if(this.data[index].inputerror?.employer_overide != undefined && !this.data[index].inputerror?.employer_overide?.is_valid)
          rowvalid = false
      if(this.data[index].inputerror?.cess_overide != undefined && !this.data[index].inputerror?.cess_overide?.is_valid)
          rowvalid = false
      if(this.data[index].inputerror?.comment != undefined && !this.data[index].inputerror?.comment?.is_valid)
          rowvalid = false
    }
    return rowvalid
  }


  empFunction(empid:any) : any{
    this.employeeList.forEach((item:any) => {
      if(!this.selctedData.includes(item?.id) || empid==item?.id){
      this.empArr.push(item)
    }
    })
    return this.empArr;

  }
}
