import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class leavetypeService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }


  constructor(
    private http: HttpClient,
    public router : Router
  ) { }


  getleavetypeList(filter:any = '',limit:any,offset:any,search:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type/?is_active=${filter}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }

  LeaveTypeSearch(filter:string = ''){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type/?is_active=${filter}&ordering=leave_name`, this.httpOption)
  }

  getLeaveType(id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-detail/${id}/`, this.httpOption)
  }

  createleavetype(data:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/leave-type/`, data, this.httpOption)
  }

  updateLeaveType(id: number, body:any) {
    return this.http.put(`${this.API_PATH}attendance/api/v1/leave-type-detail/${id}/`, body, this.httpOption)
  }

  deleteLeaveType(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}attendance/api/v1/leave-type-detail/${id}/`, body, this.httpOption)
  }

  getSingleLeaveType(id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-detail/${id}`, this.httpOption)
  }

  getDropDownList(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-dropdown/?is_active=true&ordering=leave_name&leave_unpaid_paid=true`, this.httpOption)
  }

  getUnPaidDropDownList(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-dropdown/?is_active=true&ordering=leave_name&leave_unpaid_paid=false`, this.httpOption)
  }

  getLeaveDropdown(){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type/?is_active=true&ordering=leave_name`, this.httpOption)

  }


}
