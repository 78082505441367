import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyTeamRoutingModule } from './my-team-routing.module';
import { MyTeamDashboardComponent } from './my-team-dashboard/my-team-dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MyTeamEmployeeDetailsComponent } from './my-team-employee-details/my-team-employee-details.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MatDatepickerModule } from '@angular/material/datepicker';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);
import { FormsModule } from '@angular/forms';
import { MyTeamAttendanceComponent } from './my-team-attendance/my-team-attendance.component';
import { MasterSelectModule } from "../../@shared/master-select.module";
import { OrganizationHierarchyModule } from "../../@module/organization-hierarchy/organization-hierarchy.module";
import { ProfileModule } from "../../@module/profile/profile.module";
import { HrModule } from '../hr/hr.module';
import { MyTeamLocationComponent } from './my-team-location/my-team-location.component';


@NgModule({
    declarations: [
        MyTeamDashboardComponent,
        MyTeamEmployeeDetailsComponent,
        MyTeamAttendanceComponent,
        MyTeamLocationComponent
    ],
    imports: [
        CommonModule,
        MyTeamRoutingModule,
        NgbModule,
        NgSelectModule,
        NgxChartsModule,
        FullCalendarModule,
        FormsModule,
        MatDatepickerModule,
        MasterSelectModule,
        OrganizationHierarchyModule,
        ProfileModule,
  		  HrModule,
    ]
})
export class MyTeamModule { }
