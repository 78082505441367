<div class="side-panel side-pane-active" style="--sidepanel-width:57.625rem;"
  [class.side-pane-active]='viewdetailsout === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="closefn()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 ">OUTSTANDING ADVANCE DETAILS</p>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-16">
              <div class="card card-c2">
                <app-table-loader *ngIf="loaderview || viewLoader"></app-table-loader>
                <div class="table-responsive radius-4" *ngIf="!loaderview && !viewLoader">
                  <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                    <thead>
                      <tr>
                        <th class="fw-600 text-uppercase" scope="col">Date</th>
                        <th class="fw-600 text-uppercase" scope="col">OPENING OUTSTANDING BALANCE</th>
                        <th class="fw-600 text-uppercase" scope="col">ADVANCE AMOUNT CREDITED</th>
                        <th class="fw-600 text-uppercase" scope="col">CLOSING OUTSTANDING BALANCE</th>
                        <th class="fw-600 text-uppercase" scope="col">REASON</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="viewdata.length == 0 ">
                      <tr>
                          <td colspan="9">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                              </div>
                          </td>
                      </tr>
                    </tbody>
                    <tbody class="cell-16" *ngIf="viewdata.length > 0 ">
                      <ng-container *ngFor='let viewdata of $any(viewdata| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index'>
                        <tr>
                          <td > {{appService.dateFormatDisplay(viewdata?.transaction_date)}}</td>
                          <td>{{viewdata.opening_balance | currency : currency}}</td>
                          <td>{{viewdata?.transaction_amount | currency : currency}}</td>
                          <td>{{viewdata?.closing_balance | currency : currency}}</td>
                          <td>{{viewdata?.reason}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="this.appService.filteredcount(viewdata,'')>pageSize">
              <ngb-pagination class="d-flex justify-content-end"
                  [collectionSize]="this.appService.filteredcount(viewdata,'')" [(page)]="page"
                  [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                  [pageSize]="pageSize">
              </ngb-pagination>
            </div>
        </div>
      </div>
    </div>

  </div>
</div>