<form class="">
  <div class="container-fluid p-32 form-container">
      <div class="row row-16 mb-16">
          <div class="row row-16 mb-16">
              <div class="col-12 d-flex">
                  <p class="text-uppercase fw-500 mb-0">PENDING REQUESTS</p>
              </div>

              <app-loader *ngIf="loader" ></app-loader>

              <app-nodata-view *ngIf="nodata && !loader;else all_data" [noDataText]="'NO DATA'" [hasPermission]="false"> </app-nodata-view>

          </div>

              <ng-template #all_data>
          <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
              (scrolled)="onScrollDown()">
                <ng-container  *ngIf="!loader">
                  <div class="row row-16 ">
                    <div class="col-md-6" *ngFor="let req of requests let i = index">
                      <div class="card card-c2 px-16">
                        <div class="card-body hstack px-0 gap-12 align-items-start"
                          (click)="approvalSection(req?.request_type_name,req)">

                          <img
                            *ngIf="req?.employee_profile?.profile_image !=  null && req?.employee_profile?.profile_image !=  ''"
                            class="avatar-circle sq-32" src="{{req?.employee_profile?.profile_image}}">
                          <div
                            *ngIf="req?.employee_profile?.profile_image ==  '' || req?.employee_profile?.profile_image ==  null"
                            class="avatar-circle fs-12 fw-500 sq-32  {{req?.employee_profile?.color_code}}">
                            {{this.appService.getFirstChar(req?.employee_profile?.first_name+'
                            '+req?.employee_profile?.last_name,2)}}
                          </div>
                          <div class="overflow-hidden w-100">
                            <div class="hstack">
                              <p class="fs-14 fw-600 text-trim mb-0">{{req?.employee_profile?.first_name+'
                                '+(req?.employee_profile?.middle_name!=null?req?.employee_profile?.middle_name:'')+'
                                '+req?.employee_profile?.last_name}} </p>
                              <span class="ms-auto text-light-500 fs-10 text-nowrap">{{req?.created_at_card}}</span>

                            </div>
                            <p class="mb-0 fs-12 text-light-500 w-100 text-trim">{{req?.request_type_name}} Request Approval
                            </p>
                            <div class="badge-custom fs-10 {{req?.request_type_color_code}}">{{req?.request_type_name}}
                            </div>
                          </div>

                        </div>
                        <div class="card-body hstack px-0 pt-8">
                          <div class="fs-12  fw-500 text-light-400 text-nowrap">{{ req?.status == 'Accepted' ? "Accepted by"
                            : req?.status== 'Rejected'?
                            "Rejected by" : req?.status== 'Pending'? 'Pending with' : ""}}</div>
                          <div class="d-flex align-items-center overlap-avatar overlap-right" container="main-content">

                            <ng-container *ngFor="let aacpt of req.accepted_by,let i = index">
                              <img class="avatar-sm sq-32 avatar-circle"
                                *ngIf="i < 3 && aacpt.profile_image !=  '' && aacpt.profile_image !=  null"
                                src="{{aacpt.profile_image}}" [ngbPopover]="popContentSingle"
                                popoverClass="user-list single-user" triggers="hover" container="body">
                              <div class="avatar-sm sq-32 avatar-circle {{aacpt.color_code}}"
                                *ngIf="i < 3 && (aacpt.profile_image ==  ''|| aacpt.profile_image ==  null)"
                                [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                                container="body"><span>{{this.appService.getFirstChar(aacpt.first_name+'
                                  '+aacpt.last_name,2)}}</span></div>
                              <ng-template #popContentSingle>
                                <div class="user-details">
                                  <span class="user-label">{{aacpt?.name}}</span>
                                  <span class="user-code">EMP CODE: {{aacpt?.employee_code}} </span>
                                </div>
                              </ng-template>
                              <div *ngIf="i==3" class="avatar-sm sq-32 avatar-circle" [ngbPopover]="popContentMultilpe"
                                popoverClass="user-list multi-user" triggers="click" container="body">
                                <span>+{{this.appService.subEmp(req.accepted_by.length)}}</span>
                              </div>
                            </ng-container>
                            <ng-template #popContentMultilpe>
                              <ul>
                                <ng-container *ngFor="let re of req.accepted_by,let i = index">
                                  <ng-container *ngIf="i >= 3">
                                    <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                                      <img class="user-avatar" src="{{re.profile_image}}">
                                      <div class="user-details">
                                        <span class="user-label">{{re.name}}</span>
                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                      </div>
                                    </li>
                                    <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                                      <span
                                        class="user-avatar {{re.color_code}}">{{this.appService.getFirstChar(re.first_name+'
                                        '+re.last_name,2)}}</span>
                                      <div class="user-details">
                                        <span class="user-label">{{re.name}}</span>
                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                      </div>
                                    </li>
                                  </ng-container>
                                </ng-container>
                              </ul>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                      </div>
                  </div>
                </ng-container>
              </div>
              </ng-template>

              <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>

          </div>
      </div>
  <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="submitPendingRequests()">NEXT</button>
  </footer>
</form>

<!-- Slider -->
<div class="side-panel" style="--sidepanel-width: 45rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>View</h5>
          <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <ng-container
      *ngIf="data?.request_type_name == 'Confirmed Investment Declaration' || data?.request_type_name == 'Proposed Investment Declaration'">
      <app-fnf-it-declaration-approval [data]="data" [createdTime]="createdTime"
        (fnfApprovalViewClose)="fnfApprovalViewClose()" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-it-declaration-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Reimbursement' ">
        <app-fnf-it-reimbursement-approval [data]="data" [reimbursement]="requestitem?.reimbursement_bill_inbox" [currency]="requestitem?.currency" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" (fnfApprovalViewClose)="fnfApprovalViewClose()" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-it-reimbursement-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Reimbursement Cancellation'">
        <app-fnf-it-reimbursement-approval [data]="data" [reimbursement]="requestitem" [currency]="requestitem?.reimburse_bill?.currency" [empId]="requestitem?.reimburse_bill?.from_person?.id" [archieved_status]="requestitem?.reimburse_bill?.achieved_status" (fnfApprovalViewClose)="fnfApprovalViewClose()" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus"  (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-it-reimbursement-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'FBP Declaration' && showSidePanel">
      <app-fnf-fbp-approval [data]="data" [fbp]="requestitem"
        (fnfApprovalViewClose)="fnfApprovalViewClose()" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-fbp-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Employee Profile' && showSidePanel">
      <app-fnf-employee-approval [data]="data" [profile]="requestitem" [loader]="loader1"
        (fnfApprovalViewClose)="fnfApprovalViewClose()" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-employee-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'On Duty' || data?.request_type_name == 'On Duty Cancellation'">
        <app-fnf-onduty-approval [data]="data" [onDuty]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-onduty-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Attendance Regularization' || data?.request_type_name == 'Attendance Regularization Cancellation'">
        <app-fnf-regularization-approval [data]="data" [regularization]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-regularization-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Work From Home'|| data?.request_type_name == 'Work From Home Cancellation'">
          <app-fnf-work-from-home-approval [data]="data" [workFromhome]="requestitem"
            (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-work-from-home-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Holiday' || data?.request_type_name == 'Holiday Cancellation'">
        <app-fnf-holiday-approval [data]="data" [holiday]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-holiday-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Overtime'">
        <app-fnf-overtime-approval [data]="data" [overTime]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-overtime-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Compensatory Off'">
        <app-fnf-comp-off-approval [data]="data" [CompOff]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-comp-off-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Leave' || data?.request_type_name == 'Leave Cancellation'">
        <app-fnf-leave-approval [data]="data" [Leave]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-leave-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Check-in Request'">
        <app-fnf-check-in-request-approval [data]="data" [requestitem]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-check-in-request-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Manage Asset'">
        <app-fnf-asset-approval [data]="data" [requestitem]="requestitem"
          (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [loader]="loader" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-asset-approval>
      </ng-container>

      <ng-container *ngIf="data?.request_type_name == 'Expense Report'">
        <app-fnf-expense-report-request-approval [data]="data" [requestitem]="requestitem"
        (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [currency]="currency" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-expense-report-request-approval>
      </ng-container>

      <app-fnf-short-leave-approval *ngIf="data?.request_type_name == 'Short Leave' || data?.request_type_name == 'Short Leave Cancellation'" [data]="data" [requestitem]="requestitem"
      (fnfApprovalViewClose)="fnfApprovalViewClose()" [empId]="requestitem?.from_person?.id" [archieved_status]="requestitem?.achieved_status" [payrollStatus]="payrollStatus" [reqStatus]="reqStatus" (unprocessWarningPopup)="unprocessWarningPopup()"></app-fnf-short-leave-approval>

  </div>
</div>
