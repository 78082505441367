import {  Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-ss-salary-structure',
  templateUrl: './ss-salary-structure.component.html',
  styleUrls: ['./ss-salary-structure.component.scss']
})
export class SsSalaryStructureComponent implements OnInit {
  Isalert                     = true;
  @Input() empSalaryData:any  = []
  @Input() employeeData:any   = [];
  @Input() payGroupDD:any     = [];
  @Input() formGp:any         = [];
  @Output() changeTab         =  new EventEmitter();
  @Output() defaultData       = new EventEmitter();
  submitted                   = false;
  isValid                     = false;
  @Input() currentCTC:any;
  @Input() currentPayGp:any;
  constructor(
    public messageService : MessageService,
    private notificationService: NotificationService,
    ) { }

  ngOnInit(): void {

   this.formGp.get('ctc').enable();
   this.formGp.get('pay_group').enable();
   if(this.formGp.get('ctc').value!='' ){
    this.validateEdit('ctc')
   }
   if( this.formGp.get('pay_group').value=='')
    this.formGp.get('pay_group').setValue(null);
  else  
    this.validateEdit('pay_group')
  // for(let i=0;i<this.payGroupDD.length;i++){
  //   if(this.payGroupDD[i].name ==this.formGp.get('pay_group')?.value)
  //   this.formGp.get('pay_group')?.setValue(this.payGroupDD[i].id)
  //   }

  }
  get f() {return  this.formGp.controls; }

  validateStructure() {
    this.submitted = true;

    if (this.formGp.invalid && this.isValid==false) {
      return;
    }
    this.changeTab.emit(3);
  }
  validateEdit(field:any){
    if(this.empSalaryData?.processed){
      this.isValid  = true;
      if(field=='pay_group'){
        if(this.currentPayGp!=this.formGp.get('pay_group').value){
          this.isValid  = false;
          this.f.pay_group.setErrors({
            'exist' : true
          })     
          this.notificationService.handleErrorNotification(this.messageService.ctcPaygrpRestriction(),"Error");
        }
      }
      else{
        if(this.currentCTC!=this.formGp.get('ctc').value && (!(this.formGp.get('ctc').errors?.maxlength) && !(this.formGp.get('ctc').errors?.pattern) && !(this.formGp.get('ctc').errors?.required))){
          this.isValid  = false;
          this.f.ctc.setErrors({
            'exist' : true
          })      
          this.notificationService.handleErrorNotification(this.messageService.ctcPaygrpRestriction(),"Error");

        }
      }
    } else
    this.isValid  = true;
  }


}
