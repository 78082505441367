<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="employeeStatutoryForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
              <div class="row row-16 mb-16">
                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">Date of joining</p>
                  </div>
                  <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="date_of_joining" type="radio" id="date_of_joining1"
                            class="form-check-input  " formControlName="date_of_joining" value="last_month"
                            (change)="radioBtnChangeFunctions($event,'date_of_joining','last_month','doj_specific_month','doj_specific_year');">
                        <label for="date_of_joining1" class="form-check-label">Last month</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="date_of_joining" type="radio" id="date_of_joining2"
                            class="form-check-input " formControlName="date_of_joining" value="this_month"
                            (change)="radioBtnChangeFunctions($event,'date_of_joining','this_month','doj_specific_month','doj_specific_year');">
                        <label for="date_of_joining2" class="form-check-label">This month</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="date_of_joining" type="radio" id="date_of_joining3"
                            class="form-check-input " formControlName="date_of_joining" [value]="true"
                            (change)="radioBtnChangeFunctions($event,'date_of_joining',true,'doj_specific_month','doj_specific_year');setRequired('date_of_joining')">
                        <label for="date_of_joining3" class="form-check-label">Specific month</label>
                    </div>
                </div>
                  <!-- <ng-container>
                      <div class="input-group">
                          <input (click)="datePicker1.open()" class="form-control"
                              placeholder="{{this.messageService.selectplaceholddisplay('Period')}}" matInput
                              formControlName="doj_specific_month" [matDatepicker]="datePicker1"
                              [matDatepickerFilter]="salFilter">
                          <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                      </div>
                  </ng-container> -->
                  <ng-container *ngIf="employeeStatutoryForm.value.date_of_joining==true">
                    <div class="col-6">
                      <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_year?.touched || f?.doj_specific_year?.dirty) &&  f.doj_specific_year.errors}">
                        <label for="" class="form-label">Year </label>
                        <ng-select class="form-ngselect h-36 fs-16"
                        formControlName="doj_specific_year" placeholder="{{this.messageService.selectplaceholddisplay('year')}}" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_year?.touched || f?.doj_specific_year?.dirty) &&  f.doj_specific_year.errors}">
                            <ng-option *ngFor="let yData of Years"
                                [value]="yData">{{yData}}</ng-option>
                        </ng-select>
                      </div>
                      <div *ngIf="(submitted || f?.doj_specific_year?.touched || f?.doj_specific_year?.dirty)&& f?.doj_specific_year?.errors" class="invalid-feedback">
                          <div *ngIf="f?.doj_specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_month?.touched || f?.doj_specific_month?.dirty) &&  f?.doj_specific_month?.errors}">
                        <label for="" class="form-label">Month </label>
                        <app-master-select class="config-app-master" style="width:9rem"
                        [placeholder]="month_placeholder"
                                id="month" [isrequired]="false" [selectedItem]="employeeStatutoryForm.value.doj_specific_month"
                                [type]="attendListOfMonth" formControlName="doj_specific_month" [(ngModel)]="f.doj_specific_month.value"
                                (ngModelChange)="constructDate($event,'doj')" [ngClass]="{ 'is-invalid': (submitted || f?.doj_specific_month?.touched || f?.doj_specific_month?.dirty) &&  f?.doj_specific_month?.errors}"> </app-master-select>
                      </div>
                      <div *ngIf="(submitted || f?.doj_specific_month?.touched || f?.doj_specific_month?.dirty)&& f?.doj_specific_month?.errors" class="invalid-feedback">
                          <div *ngIf="f?.doj_specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">Date of leaving</p>
                  </div>
                  <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="date_of_leaving" type="radio" id="date_of_leaving1"
                            class="form-check-input  " formControlName="date_of_leaving" value="last_month"
                            (change)="radioBtnChangeFunctions($event,'date_of_leaving','last_month','dol_specific_month','dol_specific_year')">
                        <label for="date_of_leaving1" class="form-check-label">Last month</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="date_of_leaving" type="radio" id="date_of_leaving2"
                            class="form-check-input " formControlName="date_of_leaving" value="this_month"
                            (change)="radioBtnChangeFunctions($event,'date_of_leaving','this_month','dol_specific_month','dol_specific_year')">
                        <label for="date_of_leaving2" class="form-check-label">This month</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="date_of_leaving" type="radio" id="date_of_leaving3"
                            class="form-check-input " formControlName="date_of_leaving" [value]="true"
                            (change)="radioBtnChangeFunctions($event,'date_of_leaving',true,'dol_specific_month','dol_specific_year');setRequired('date_of_leaving')">
                        <label for="date_of_leaving3" class="form-check-label">Specific month</label>
                    </div>
                </div>
                  <!-- <ng-container>
                      <div class="input-group">
                          <input (click)="datePicker1.open()" class="form-control"
                              placeholder="{{this.messageService.selectplaceholddisplay('Period')}}" matInput
                              formControlName="dol_specific_month" [matDatepicker]="datePicker1"
                              [matDatepickerFilter]="salFilter">
                          <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                      </div>
                  </ng-container> -->
                  <ng-container *ngIf="employeeStatutoryForm.value.date_of_leaving==true">
                    <div class="col-6">
                      <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_year?.touched || f?.dol_specific_year?.dirty) &&  f.dol_specific_year.errors}">
                        <label for="" class="form-label">Year </label>
                        <ng-select class="form-ngselect h-36 fs-16"
                        formControlName="dol_specific_year" placeholder="{{this.messageService.selectplaceholddisplay('year')}}" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_year?.touched || f?.dol_specific_year?.dirty) &&  f.dol_specific_year.errors}">
                            <ng-option *ngFor="let yData of Years"
                                [value]="yData">{{yData}}</ng-option>
                        </ng-select>
                      </div>
                      <div *ngIf="(submitted || f?.dol_specific_year?.touched || f?.dol_specific_year?.dirty)&& f?.dol_specific_year?.errors" class="invalid-feedback">
                        <div *ngIf="f?.dol_specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_month?.touched || f?.dol_specific_month?.dirty) &&  f?.dol_specific_month?.errors}">
                        <label for="" class="form-label">Month </label>
                        <app-master-select class="config-app-master" style="width:9rem"
                        [placeholder]="month_placeholder"
                                id="month" [isrequired]="false" [selectedItem]="employeeStatutoryForm.value.dol_specific_month"
                                [type]="attendListOfMonth" formControlName="dol_specific_month" [(ngModel)]="f.dol_specific_month.value"
                                (ngModelChange)="constructDate($event,'dol')" [ngClass]="{ 'is-invalid': (submitted || f?.dol_specific_month?.touched || f?.dol_specific_month?.dirty) &&  f?.dol_specific_month?.errors}"> </app-master-select>
                      </div>
                      <div *ngIf="(submitted || f?.dol_specific_month?.touched || f?.dol_specific_month?.dirty)&& f?.dol_specific_month?.errors" class="invalid-feedback">
                        <div *ngIf="f?.dol_specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                      </div>
                  </div>
                </ng-container>
                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">ESI applicability</p>
                  </div>
                  <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="esi_applicable" type="radio" id="esi1" class="form-check-input  "
                            formControlName="esi_applicable" value="true">
                        <label for="esi1" class="form-check-label">Yes</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check  mb-8">
                        <input name="esi_applicable" type="radio" id="esi2" class="form-check-input "
                            formControlName="esi_applicable" value="false">
                        <label for="esi2" class="form-check-label">No</label>
                    </div>
                </div>

                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">PF applicability</p>
                  </div>
                  <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="pf_applicable" type="radio" id="pf1" class="form-check-input  "
                            formControlName="pf_applicable" value="true">
                        <label for="pf1" class="form-check-label">Yes</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check  mb-8">
                        <input name="pf_applicable" type="radio" id="pf2" class="form-check-input "
                            formControlName="pf_applicable" value="false">
                        <label for="pf2" class="form-check-label">No</label>
                    </div>
                </div>


                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">PT applicability</p>
                  </div>
                  <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="pt_applicable" type="radio" id="pt1" class="form-check-input  "
                            formControlName="pt_applicable" value="true">
                        <label for="pt1" class="form-check-label">Yes</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check  mb-8">
                        <input name="pt_applicable" type="radio" id="pt2" class="form-check-input "
                            formControlName="pt_applicable" value="false">
                        <label for="pt2" class="form-check-label">No</label>
                    </div>
                </div>

                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">LWF applicability</p>
                  </div>
                  <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="lwf_applicable" type="radio" id="lwf1" class="form-check-input  "
                            formControlName="lwf_applicable" value="true">
                        <label for="lwf1" class="form-check-label">Yes</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check  mb-8">
                        <input name="lwf_applicable" type="radio" id="lwf2" class="form-check-input "
                            formControlName="lwf_applicable" value="false">
                        <label for="lwf2" class="form-check-label">No</label>
                    </div>
                </div>

                  <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">Tax regime</p>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="tax_regime" type="radio" id="tax_regime1" class="form-check-input  "
                              formControlName="tax_regime" value="old">
                          <label for="tax_regime1" class="form-check-label">Old</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check  mb-8">
                          <input name="tax_regime" type="radio" id="tax_regime2" class="form-check-input "
                              formControlName="tax_regime" value="new">
                          <label for="tax_regime2" class="form-check-label">New</label>
                      </div>
                  </div>
              </div>

          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
              (click)="resetPage();resetFilterFunction();">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
              (click)="resetPage();submitEmployeeStatutory();">
              Apply
          </button>
      </footer>
  </form>
</div>
