<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Templates</h3>
      <p class="text-helper mb-md-0">Enables the user to create custom email template.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <div class="">
            <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey"
              (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchtemplate($event)"></app-searchbar>
          </div>
          <ul class="drop-down-menu search-result {{(inputFocusClass == true) ? 'active' : ''}}">
            <p class="note"> No Result msg </p>
            <p class="text-center text-helper mt-1">No Template Found...</p>
            <p class="note"> Loader </p>
            <p class="loader text-center">
              <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
            </p>
            <p class="note"> Search Results</p>
          </ul>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>

          </div>
        </div>

        <button *ngIf="permissions.a" routerLink="/template/template-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add TEMPLATE</button>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
  <div *ngIf="!template_list_flag;else all_data " class="row pt-4">
    <!-- <div class="col-12 text-center">
            <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/15 Startup launch.png"
                style="width: 27.375rem;">
            <p class="text-uppercase text-light-400 fw-700 fs-14">NO TEMPLATE ADDED YET</p>
            <ng-container *ngIf="permissions.a">
            <a    class="text-uppercase link-primary fw-700 fs-14" (click)="showSidePanel = true">Add NOW</a></ng-container>
        </div> -->
    <app-nodata-view [noDataText]="'NO TEMPLATE ADDED YET'" [hasPermission]="permissions?.a"
      (addData)="showSidePanel = true"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-24 " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-md-3 col-sm-6 " *ngFor="let templatedetail of templateDetails">
          <div class="card card-c2 card-hover">
            <div class="card-body indicater p-0">
              <div class="d-flex">
                <div class="overflow-hidden view-section"
                  (click)="showSidePanel = true;viewsingledata(templatedetail['id'])">
                  <p class="mb-0">{{templatedetail.template_name}}</p>
                  <span
                    class="fs-10 text-uppercase fw-500  {{templatedetail.is_active?'text-success':'text-danger'}}">{{templatedetail.is_active?'Active':'Inactive'}}</span>
                </div>
                <div ngbDropdown class="dropdown-section  d-inline-block " style="width: 1.625rem;"
                  *ngIf="templatedetail.is_active && (permissions.e || permissions.d)">
                  <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                    <i class="icon-three-dots-vertical fs-16"></i>
                  </button>
                  <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                    <button *ngIf="permissions.e" ngbDropdownItem routerLink="/template/template-setup"
                      (click)="editHeaderContent(templatedetail['id'])">
                      <i class="icon-pencil-square me-1"></i> Edit
                    </button>
                    <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                      (click)="deleteAlert = true;deleteclick(templatedetail['id'])">
                      <i class="icon-trash  me-1"></i> Delete
                    </button>
                  </div>
                </div>
              </div>


            </div>
            <div class="card-body p-16" (click)="showSidePanel = true;viewsingledata(templatedetail['id'])">
              <div class="row  row-16 fs-12">
                <div class="col-lg-6 ">
                  <div class="text-light-400 fs-10 text-uppercase">Date created</div>
                  <div class="fw-500 ">{{templatedetail.created_at | date:"dd MMM YYYY"}}</div>
                </div>
                <div class="col-lg-6 overflow-hidden">
                  <div class="text-light-400 fs-10 text-uppercase">Template type</div>
                  <div class="fw-500 text-trim tips">{{templatedetail.template_type}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <p *ngIf="getdata.length === 0">No templates added yet</p> -->
      </ng-container>

    </div>
  </ng-template>

</div>
<!-- <div class="loader text-center" [style.display]="infinityloader">
    <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
</div> -->
<app-infinity-loader *ngIf="infinityloader"></app-infinity-loader>

<!-- view -->
<div class="side-panel" style="--sidepanel-width:40.875rem;" [class.side-pane-active]='showSidePanel === true'
  *ngIf="permissions">
  <div class="side-panel-container" autocomplete="off" *ngIf="permissions.v">
    <header class="side-panel-head">
      <h5>View email template</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- View loader start -->
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <!-- View loader end -->
        <ng-container *ngIf="!loader1">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Welcome template</p>
            <a *ngIf="is_active && permissions.e" routerLink="/template/template-setup"
              class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="editHeaderContent(viewid)">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>


          <div class="mail-container p-56">
            <div class="mail-body radius-4">
              <div class="mail-header align-items-center">

                <img class="header-img " src="assets\images\mail_images\template-bg2.png" alt="">
                <div class="fs-32 mb-24 me-auto mail-title">{{subject}}</div>
                <img class="header-img ms-auto" src="assets\images\mail_images\template-bg2.png" alt="">
              </div>

              <div class="mail-content">
                <p [innerHTML]="description"></p>
                <!-- <p>Hi Leslie!</p>
                            <p> Welcome to the Organization, We are glad to have you as part of our team. </p>
                            <p>As a initiation we will be setting up series or meeting with our management for your better knowledge of organization and work culture.Looking forward for your greater efforts.</p>
                            <p>Team Skizzle</p>
                            <hr class="hr-1 mb-24">
                            <p>To continue to your account click the button below</p>
                            <div class="flex-center">

                                <div class="btn btn-primary d-inline-block px-24">Click here to login</div>
                            </div> -->
              </div>
            </div>
            <div class="mail-footer mb-32">
              <div>
                <p>Skizzle </p>
                <p>2021 Broadway, suit 815</p>
                <p>Banglore</p>
              </div>
              <div class="ms-auto">
                <p>Unsubscribe</p>
              </div>
            </div>
            <hr class="hr-1  mx-auto" style="width: 4.5rem;">
          </div>
        </ng-container>


      </div>
    </div>

  </div>
</div>
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Template?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">This will delete the template permanently. You’ll have to update again to get it back in the
          list.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm" (click)="deletetemplate()">Delete</button>
      </div>
    </div>
  </div>
</div>