<div class="container-fluid p-32 form-container" [formGroup]="formGp">
  <div class="row row-16">
    <div class="col-12">
      <div class="card details-card indicater1 px-16 py-12">
          <div class="row">
              <div class="col-5"> <div class="fw-600 fs-14">Income/Loss from property</div> </div>
              <div class="col">
                <div class="detail-title">Maximum limit</div>
                <div class="detail-desc">{{limitData?.income_loss_property | currency: currency}}</div>
            </div>
              <div class="col">
                  <div class="detail-title">Total amount declared</div>
                  <div class="detail-desc">{{totalExeptionFunction() | currency: currency}}</div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-12">
      <div  role="alert" class="alert alert-warning mb-0 {{alertShow}}">
        <p class="mb-0">Select the checkbox below to fill details or skip the step by clicking on skip button
        </p>
        <div class="btn-close" (click)="alertShow='hide'"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-c2" >
          <div class="card-body px-24 py-16">
              <div class="row row-12">
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" name="incomefrom_self_occupiedproperty" id=""  class="form-check-input" formControlName="incomefrom_self_occupiedproperty" (change)="checkBoxFunctionTest($event,'intreston_houseloan_amount')">
                          <label  for="" class="form-check-label fs-14">Income from self occupied property</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Employees paying interest on housing loan for a self-occupied property are eligible for a tax exemption up to 2,00,000. Enter the interest paid and as well as lender details below." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.incomefrom_self_occupiedproperty">
                    <div class=" col-md-6 form-row">
                        <label for="" class="form-label required">Interest on housing loan (self occupied) </label>
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.intreston_houseloan_amount.dirty || f.intreston_houseloan_amount.touched) &&  f.intreston_houseloan_amount.errors}">
                          <input type="text" class="form-control" autocomplete="off" name="intreston_houseloan_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="intreston_houseloan_amount" [ngClass]="{ 'is-invalid': (submitted || f.intreston_houseloan_amount.dirty || f.intreston_houseloan_amount.touched) &&  f.intreston_houseloan_amount.errors}" (keyup)="totalExeptionFunction()">
                          <span>{{currency}}</span>
                        </div>
                        <div *ngIf="(submitted || f.intreston_houseloan_amount.dirty || f.intreston_houseloan_amount.touched) && f.intreston_houseloan_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.intreston_houseloan_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.intreston_houseloan_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                            <div
                            *ngIf="!(f.intreston_houseloan_amount.errors?.pattern) && f.intreston_houseloan_amount.errors?.maxlength">
                            {{this.messageService.fieldlengthvalidation('num','15')}}</div>
                        </div>
                        <!-- <div class="fs-12"><span class="text-light-500">Max Limit :</span><span class="fw-500"> {{limitData.self_houseloan_interest}}</span></div> -->
                    </div>
                    <ng-container formArrayName="incomeself_lender" *ngFor="let item of lenderIncome().controls; let i=index; ">
                      <ng-container   [formGroupName]="i">
                        <div class="col-12 d-flex">
                          <span class="fw-500">Lender information {{(i+1)}}</span>
                          <a *ngIf="i!=0" class="ms-auto text-light-600" (click)="removeLenderIncome(i)"><i class="icon-trash"></i></a>
                        </div>

                        <div class=" col-md-6 form-row">
                            <label for="" class="form-label required">Lender name </label>
                            <input type="text" class="form-control" autocomplete="off"  name="lender_name" id="lender_name" placeholder="{{this.messageService.placeholderdisp('Name')}}" formControlName="lender_name"
                            [ngClass]="{ 'is-invalid': (submitted || lenderIncome().controls[i].get('lender_name')?.dirty || lenderIncome().controls[i].get('lender_name')?.touched) &&  lenderIncome().controls[i].get('lender_name')?.errors}">
                            <div *ngIf="(submitted || lenderIncome().controls[i].get('lender_name')?.dirty || lenderIncome().controls[i].get('lender_name')?.touched) && lenderIncome().controls[i].get('lender_name')?.errors" class="invalid-feedback">
                              <div *ngIf="lenderIncome().controls[i].get('lender_name')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="!(lenderIncome().controls[i].get('lender_name')?.errors?.pattern) && lenderIncome().controls[i].get('lender_name')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                              <div *ngIf="lenderIncome().controls[i].get('lender_name')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                          </div>
                        </div>
                        <div class=" col-md-6 form-row">
                            <label for="" class="form-label">Lender pan </label>
                            <input type="text" class="form-control" autocomplete="off"  name="lender_pan" id="lender_pan" placeholder="{{this.messageService.placeholderdisp('PAN')}}" formControlName="lender_pan"  [ngClass]="{ 'is-invalid': (lenderIncome().controls[i].get('lender_pan')?.touched || lenderIncome().controls[i].get('lender_pan')?.dirty)
                            &&  (lenderIncome().controls[i].get('lender_pan')?.errors)}">
                            <div *ngIf=" (lenderIncome().controls[i].get('lender_pan')?.errors)" class="invalid-feedback">
                              <!-- <div *ngIf="lenderIncome().controls[i].get('lender_pan')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div> -->
                              <div *ngIf="lenderIncome().controls[i].get('lender_pan')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                              <div *ngIf="!(lenderIncome().controls[i].get('lender_pan')?.errors?.pattern) && lenderIncome().controls[i].get('lender_pan')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <div class="col-md-6 form-row">
                      <button class="btn btn-outline-primary btn-sm fw-400" (click)="addLenderIncome()">Add More</button>
                    </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" name="incomefrom_letoutproperty" id=""  class="form-check-input" formControlName="incomefrom_letoutproperty" (change)="letoutFunction($event)">
                          <label  for="" class="form-check-label fs-14">Income from let-out property</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="If your employee receives a rental income from the let-out property, then enter the annual rent amount received and the tax paid information below." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>

                  <ng-container *ngIf="formGp.value.incomefrom_letoutproperty">
                    <ng-container formArrayName="loss_lender" *ngFor="let item of lenderLoss().controls; let i=index; ">
                      <ng-container   [formGroupName]="i">
                        <div class="col-12 d-flex">
                          <span class="fw-500">Property {{(i+1)}}</span>
                          <a *ngIf="i!=0" class="ms-auto text-light-600" (click)="removeLenderLoss(i)"><i class="icon-trash"></i></a>
                        </div>

                        <div class=" col-md-6 form-row">
                            <label for="" class="form-label required">(a) Annual rent received </label>
                            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('annual_rent_received')?.dirty || lenderLoss().controls[i].get('annual_rent_received')?.touched) &&  lenderLoss().controls[i].get('annual_rent_received')?.errors}">
                              <input [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('annual_rent_received')?.dirty || lenderLoss().controls[i].get('annual_rent_received')?.touched) &&  lenderLoss().controls[i].get('annual_rent_received')?.errors}" type="text" class="form-control" autocomplete="off" id="annual_rent_received" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="annual_rent_received" (keyup)="netValueCalc(lenderLoss().controls[i].get('annual_rent_received')?.value,lenderLoss().controls[i].get('muncipal_taxpaid')?.value,i)">
                              <span>{{currency}}</span>
                            </div>
                            <div *ngIf="(submitted || lenderLoss().controls[i].get('annual_rent_received')?.dirty || lenderLoss().controls[i].get('annual_rent_received')?.touched) && lenderLoss().controls[i].get('annual_rent_received')?.errors" class="invalid-feedback">
                              <div *ngIf="lenderLoss().controls[i].get('annual_rent_received')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="lenderLoss().controls[i].get('annual_rent_received')?.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(lenderLoss().controls[i].get('annual_rent_received')?.errors?.pattern) && lenderLoss().controls[i].get('annual_rent_received')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                        </div>
                        <div class=" col-md-6 form-row">
                            <label for="" class="form-label required">(b) Municipal taxes paid </label>
                            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('muncipal_taxpaid')?.dirty || lenderLoss().controls[i].get('muncipal_taxpaid')?.touched) &&  lenderLoss().controls[i].get('muncipal_taxpaid')?.errors}">
                              <input [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('muncipal_taxpaid')?.dirty || lenderLoss().controls[i].get('muncipal_taxpaid')?.touched) &&  lenderLoss().controls[i].get('muncipal_taxpaid')?.errors}" type="text" class="form-control" autocomplete="off" id="muncipal_taxpaid" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="muncipal_taxpaid" (keyup)="netValueCalc(lenderLoss().controls[i].get('annual_rent_received')?.value,lenderLoss().controls[i].get('muncipal_taxpaid')?.value,i)">
                              <span>{{currency}}</span>
                            </div>
                            <div *ngIf="(submitted || lenderLoss().controls[i].get('muncipal_taxpaid')?.dirty || lenderLoss().controls[i].get('muncipal_taxpaid')?.touched) && lenderLoss().controls[i].get('muncipal_taxpaid')?.errors" class="invalid-feedback">
                              <div *ngIf="lenderLoss().controls[i].get('muncipal_taxpaid')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="lenderLoss().controls[i].get('muncipal_taxpaid')?.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(lenderLoss().controls[i].get('muncipal_taxpaid')?.errors?.pattern) && lenderLoss().controls[i].get('muncipal_taxpaid')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                        </div>
                        <div class=" col-md-6 form-row">
                            <label for="" class="form-label required">(c) Net value (a-b) </label>
                            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('netvalue_ab')?.dirty || lenderLoss().controls[i].get('netvalue_ab')?.touched) &&  lenderLoss().controls[i].get('netvalue_ab')?.errors}">
                              <input [readonly]="true" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('netvalue_ab')?.dirty || lenderLoss().controls[i].get('netvalue_ab')?.touched) &&  lenderLoss().controls[i].get('netvalue_ab')?.errors}" type="text" class="form-control" autocomplete="off" id="netvalue_ab" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="netvalue_ab">
                              <span>{{currency}}</span>
                            </div>
                            <div *ngIf="(submitted || lenderLoss().controls[i].get('netvalue_ab')?.dirty || lenderLoss().controls[i].get('netvalue_ab')?.touched) && lenderLoss().controls[i].get('netvalue_ab')?.errors" class="invalid-feedback">
                              <div *ngIf="lenderLoss().controls[i].get('netvalue_ab')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="lenderLoss().controls[i].get('netvalue_ab')?.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                          </div>
                        </div>
                        <div class=" col-md-6 form-row">
                            <label for="" class="form-label required">(d) Standard deduction (30% of the Net Value)</label>
                            <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('standard_deduction')?.dirty || lenderLoss().controls[i].get('standard_deduction')?.touched) &&  lenderLoss().controls[i].get('standard_deduction')?.errors}">
                              <input [readonly]="true" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('standard_deduction')?.dirty || lenderLoss().controls[i].get('standard_deduction')?.touched) &&  lenderLoss().controls[i].get('standard_deduction')?.errors}" type="text" class="form-control" autocomplete="off" id="standard_deduction" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="standard_deduction">
                              <span>{{currency}}</span>
                            </div>
                            <div *ngIf="(submitted || lenderLoss().controls[i].get('standard_deduction')?.dirty || lenderLoss().controls[i].get('standard_deduction')?.touched) && lenderLoss().controls[i].get('standard_deduction')?.errors" class="invalid-feedback">
                              <div *ngIf="lenderLoss().controls[i].get('standard_deduction')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="lenderLoss().controls[i].get('standard_deduction')?.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                          </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input type="checkbox" name="repaying_homeloan_property" id=""  class="form-check-input" formControlName="repaying_homeloan_property" (change)="repayingFunction($event,i)">
                                <label  for="" class="form-check-label fs-14">(e) I'm repaying home loan for this property: </label>
                                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="If you are paying home loan for the let-out property, then enter the interest paid on the home loan and the lender information below." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                            </div>
                        </div>
                        <ng-container *ngIf="lenderLoss().controls[i].get('repaying_homeloan_property')?.value">
                          <div class=" col-md-6 form-row">
                              <label for="" class="form-label required">Interest paid on home loan </label>
                              <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.dirty || lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.touched) &&  lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.errors}">
                                <input [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.dirty || lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.touched) &&  lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.errors}" type="text" class="form-control" autocomplete="off" id="intrestpaid_houseloan_amount" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="intrestpaid_houseloan_amount" (keyup)="netIncomeFunction(i)">
                                <span>{{currency}}</span>
                              </div>
                              <!-- <div class="fs-12"><span class="text-light-500">Max Limit :</span><span class="fw-500"> {{interest_paid_houseloan_exempt_limit}}</span></div> -->
                              <div *ngIf="(submitted || lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.dirty || lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.touched) && lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.errors" class="invalid-feedback">
                                <div *ngIf="lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.errors?.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                                <div *ngIf="!(lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.errors?.pattern) && lenderLoss().controls[i].get('intrestpaid_houseloan_amount')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                            </div>
                          </div>
                          <div class="col-12"><span class="fw-500">Lender information {{(i+1)}}</span> </div>

                          <div class=" col-md-6 form-row">
                              <label for="" class="form-label required">Lender name </label>
                              <input type="text" class="form-control" autocomplete="off" id="lender_name" placeholder="{{this.messageService.placeholderdisp('Name')}}" formControlName="lender_name" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('lender_name')?.dirty || lenderLoss().controls[i].get('lender_name')?.touched) &&  lenderLoss().controls[i].get('lender_name')?.errors}">
                              <div *ngIf="(submitted || lenderLoss().controls[i].get('lender_name')?.dirty || lenderLoss().controls[i].get('lender_name')?.touched) && lenderLoss().controls[i].get('lender_name')?.errors" class="invalid-feedback">
                                <div *ngIf="lenderLoss().controls[i].get('lender_name')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                <div *ngIf="!(lenderLoss().controls[i].get('lender_name')?.errors?.pattern) && lenderLoss().controls[i].get('lender_name')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                                <div *ngIf="lenderLoss().controls[i].get('lender_name')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            </div>
                          </div>
                          <div class=" col-md-6 form-row">
                              <label for="" class="form-label">Lender pan </label>
                              <input type="text" class="form-control" autocomplete="off" id="lender_pan" placeholder="{{this.messageService.placeholderdisp('PAN')}}" formControlName="lender_pan" [ngClass]="{ 'is-invalid': (submitted || lenderLoss().controls[i].get('lender_pan')?.dirty || lenderLoss().controls[i].get('lender_pan')?.touched) &&  lenderLoss().controls[i].get('lender_pan')?.errors}">
                              <div *ngIf="(submitted || lenderLoss().controls[i].get('lender_pan')?.dirty || lenderLoss().controls[i].get('lender_pan')?.touched) && lenderLoss().controls[i].get('lender_pan')?.errors" class="invalid-feedback">
                                <!-- <div *ngIf="lenderLoss().controls[i].get('lender_pan')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div> -->
                                <div *ngIf="lenderLoss().controls[i].get('lender_pan')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                            </div>
                          </div>
                          </ng-container>
                      <!-- </ng-container>
                    </ng-container>

                    <div class="col-12">
                        <button class="btn btn-outline-primary" (click)="addLenderLoss()">Add More</button>
                    </div> -->
                      <div class=" col-12 form-row">
                          <label for="" class="form-label required">(f)	Net income/loss from house property (c-(d+e))</label>
                          <div class="form-icon icon-right" >
                            <input [readonly]="true" type="text" class="form-control" autocomplete="off" id="net_income_houseproperty" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="net_income_houseproperty" name="net_income_houseproperty" >
                            <span>{{currency}}</span>
                          </div>
                          <!-- <div class="fs-12"><span class="text-light-500">Max Limit :</span><span class="fw-500"> {{loss_houseproperty_exempt_limit}}</span></div> -->
                      </div>
                  </ng-container>
                </ng-container>

                <div class="col-12">
                    <button class="btn btn-outline-primary" (click)="addLenderLoss()">Add More</button>
                </div>
                  </ng-container>
                  <div class="col-12 ">
                      <div class="box-1 gap-16">Total income/loss from property (- Income from self-occupied property + Income from let-out property ) <span><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="A maximum of ₹2,00,000 is allowed as exemption for housing loan interests on Self-occupied Property and Let-out Property for the below field." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span><div class="ms-auto fw-500">{{totalExemption | currency: currency}}</div>
                      </div>
                    </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
  <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
<footer class="submit-footer gap-16">
  <button class="btn btn-outline-primary btn-sm ms-auto text-uppercase" (click)="validateIncomeLoss('skip')">SKIP</button>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="!formGp.valid" (click)="validateIncomeLoss('next')">NEXT</button>
</footer>
