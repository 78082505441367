
    <div class="container-fluid p-32 form-container">
      <div class="row mb-24">
          <div class="col-md-4">
              <p class="text-uppercase fw-500 mb-0">Publish</p>
          </div>
          <div class="col-md-8">
              <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
                <div style="min-width:22.688rem;">
                    <div class="form-icon2">
                      <i class="icon-search icon-left fs-14"></i>
                      <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                      <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                    </div>
                </div>

              </div>
          </div>
      </div>
    <app-table-loader *ngIf="loader"></app-table-loader>

      <div class="row row-24" *ngIf="!loader">

          <div class="col-12">
              <div class="card card-c2 overflow-hidden">
                  <div class="table-responsive">
                      <table class="table table-striped table-sm">
                          <thead>
                              <tr>
                                  <th class="fw-600" scope="col">Employee name</th>
                                  <th class="fw-600" scope="col">Employee code</th>
                                  <th class="fw-600" scope="col">Generated Date</th>


                              </tr>
                          </thead>
                          <tbody class="cell-16">

                            <tr *ngFor='let data of $any(publishedData|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)'>

                              <td>{{data.first_name+" "+(data.middle_name!=null?data.middle_name:" ")+ " "+data.last_name}}</td>
                              <td>{{data.employee_code}}</td>

                              <td>{{this.appservice.dateFormatDisplay(data?.generated_date)}}</td>


                          </tr>

                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          <div class="col-12" *ngIf="this.appservice.filteredcount(publishedData,searchString)>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="this.appservice.filteredcount(publishedData,searchString)" [(page)]="page"
                  [maxSize]="2" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
          </div>
      </div>
  </div>
  <footer class="submit-footer">
      <!-- <a class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="goNext(6)">Publish</a> -->
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveClick" (click)="goNext(6)">Publish</button>

  </footer>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
