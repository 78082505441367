import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import { ReimbursementRequestService } from 'src/app/@core/services/reimbursement-request.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AppService } from 'src/app/app.global';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
import { ActivatedRoute, Router,Params } from '@angular/router';



@Component({
  selector: 'app-reimbursement-setup',
  templateUrl: './reimbursement-setup.component.html',
  styles: [
  ]
})
export class ReimbursementSetupComponent implements OnInit {
  activeTab = 1;
  empId:any;
  categoryData:any;
  summaryData:any         = [];
  categorySummaryData:any = [];
  currency:any;
  stepperLoader = false;
  from:any;
  requestFor:any = ''
  reimbursementForm: FormGroup = this.formBuilder.group({
                                      category     : this.formBuilder.group({
                                                      paycomponent:['',Validators.required]
                                      }),
                                      details      : this.formBuilder.group({
                                                      reimbursement:this.formBuilder.array([])
                                      }),

                                })
  constructor(private _location: Location,
    private formBuilder:FormBuilder,
    private service:ReimbursementRequestService,
    private authService:AuthService,
    public appservice:AppService,
    public router: Router,
    private investmentService:InvestmentDeclerationService,
    private activityService:ManageActivityService,
    public route:ActivatedRoute,

    ) { }
  backClicked() {
    this.investmentService.backClicked('/reimbursement-setup');
  }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
       this.empId = params['id']
       this.from  = 'behalf'
       this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
        this.requestFor = "";
        if(res['first_name'] != null)
        this.requestFor+= res['first_name']+" ";
        if(res['middle_name'] != null)
        this.requestFor+= res['middle_name']+" ";
        if(res['last_name'] != null)
        this.requestFor+= res['last_name'];
        this.requestFor+=" ("+res['employee_code']+")"
        this.currency = res.currency

      })
      }
      else{

        this.empId    = this.authService.isEmployeeId
        this.currency = userData.currency;

      }

    })
    this.stepperLoader  = true;
    let userData        = this.appservice.getEmpData();
    this.getCategoryDetails();
  }
  getCategoryDetails(){
    this.service.getCategoryDetails(this.empId).subscribe((res:any)=>{
      this.categoryData   = res.data;
      this.stepperLoader  = false;

    })
  }
  get categoryForm() {
    return this.reimbursementForm.get('category') as FormGroup;
  }
  get detailsForm() {
    return this.reimbursementForm.get('details') as FormGroup;
  }
  changeTab(number:any){
    this.activeTab = number;
    this.constructSummary();
  }
  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if (this.reimbursementForm['controls'].category.invalid) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 3) {
      if (this.reimbursementForm['controls'].category.invalid || this.reimbursementForm['controls'].details.invalid) {
        changeEvent.preventDefault();
      }
    }
    this.constructSummary();

  }
  constructSummary(){
    this.summaryData          = [];
    this.categorySummaryData  = [];
    let categoryId            = this.reimbursementForm['controls'].category.value.paycomponent;
    let billData              = this.reimbursementForm.getRawValue().details;
    var reimbursement         = this.categoryData.filter(function(category:any) {
      return category.pay_component_id == categoryId;
    });

    billData = billData.reimbursement;
    for(let i=0;i<billData.length;i++){
      billData[i]['claim_date'] = this.appservice.dateFormatConvert(billData[i]['claim_date']);
      billData[i]['bill_date'] = this.appservice.dateFormatConvert(billData[i]['bill_date']);

    }
    this.categorySummaryData.push(reimbursement[0]);
    this.summaryData.push(billData);


  }
  createBill(){

    let billData              = this.reimbursementForm.getRawValue().details;
    let claimData;
    billData                  = billData.reimbursement;
    for(let i=0;i<billData.length;i++){
      claimData = this.appservice.dateFormatConvert(billData[i]['claim_date']);
      delete (billData[i]['claim_date']);
      billData[i]['bill_date']  = this.appservice.dateFormatConvert(billData[i]['bill_date']);

    }

    let InitialSetData  = {'employee':this.empId,'claim_date':claimData,'reimbursement':billData}

    let BillData ={ ...InitialSetData, ...this.reimbursementForm['controls'].category.value};
    if(this.from=='behalf'){
      BillData['employee_id'] = this.empId
      this.activityService.reimbursementRequest(BillData).subscribe((res:any)=>{
        this.router.navigate(['manage-activities']);

      })
    }
    else{

      this.service.createReimbursementRequest(BillData).subscribe((res:any)=>{
        this.router.navigate(['request']);

      })
    }

  }

}
