import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';



@Component({
  selector: 'app-prior-payroll-setup',
  templateUrl: './prior-payroll-setup.component.html',
  styleUrls: ['./prior-payroll-setup.component.scss']
})
export class PriorPayrollSetupComponent implements OnInit {

  activeTab           = 1;
  hasData             = false;
  MonthlyData:any     = [];
  SaveData:any        = [];
  pendingData:any     = [];
  uploadedMonth:any;


  summaryData:any;
  month:any;
  year:any;
  company:any;

  constructor(
    public salaryService: SalaryDashboardService,
    public route:ActivatedRoute,
    private notificationService: NotificationService,
    public router:Router

  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }

    })
  }
  nextPage(page:any){
    this.activeTab = 2;
    this.getMonthlyData();

  }
  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if(this.hasData==false)
      changeEvent.preventDefault();
      else{

        this.activeTab = 2;
        this.getMonthlyData();

      }
    }
  }

  dataCheck(status:any){
    this.hasData = status;
  }
  priorSave(){
    this.salaryService.savePriorpayroll({"data":this.SaveData}).subscribe((res:any)=>{
      this.notificationService.handleSuccessNotification("Employee prior payroll data saved successfully","Success");
      this.router.navigateByUrl("/run-payroll/"+this.year+"/"+this.month+"/"+this.company)

    })

  }
  getMonthlyData(){
   let tempData :any = {};
    this.salaryService.getPriorMonthlyData(this.company,this.year).subscribe((res:any)=>{
      this.MonthlyData      = res.body.data;
      let monthArray        = [];
      for(let i=0;i<this.MonthlyData.length;i++){
        if(this.MonthlyData[i]['status']=='UPLOADED'){
          tempData = {
          "pay_year"  : this.MonthlyData[i].year,
          "pay_month" : this.MonthlyData[i].month,
          "company"   : this.company

          }
          this.SaveData.push(tempData);
          monthArray.push(this.MonthlyData[i]?.month+" "+this.MonthlyData[i].year)
        }
        if(this.MonthlyData[i]['pending']!=0 ){
          this.pendingData.push(this.MonthlyData[i]);
        }
      }
      this.uploadedMonth = monthArray.join(", ");

    })
  }
}
