<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Disable Portal Access</h3>
      <p class="text-helper mb-md-0">Portal access for employees can be manually disabled or enabled here.</p>
    </div>
    <div class="col-md-8 col-lg-6" *ngIf="!loader && !loader1">
      <div class="d-flex justify-content-end  gap-16">
        <div style="min-width:22.688rem;">
          <div class="form-icon2">
            <i class="icon-search icon-left fs-14"></i>
            <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
              (click)="searchString=''"></div>
            <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('employee')}}  " autocomplete="off"
              name="searchString" [(ngModel)]="searchString" (keyup)="page=1" />
          </div>
        </div>
        <div class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" (click)="filter  = true">
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a"
          (click)="disablePortalAccess = true">Disable
          portal access</button>
      </div>
    </div>
  </div>
  <app-c1-loader *ngIf="loader || loader1"></app-c1-loader>
  <div class="row row-16" *ngIf="!loader && !loader1">
    <div class="col-12" *ngIf="!loader && (filteredcount()<=0)">
      <div class="row " style="height: calc(100vh - 11rem);">
        <app-nodata-view class="vstack"  [noDataImg]="'bg20.png'"
          [noDataText]="'Manage Portal Access Here!'"
          [noDataPara]="'Keep your portal secure by managing user access. Enable or disable portal access for individuals based on their role and current needs.'"
          [hasPermission]="false"> </app-nodata-view>
      </div>
    </div>
    <div class="col-12"  *ngIf="filteredcount()>0 ">
      <div class="card card-c2">
        <div class="table-responsive radius-4"
          style="max-height: calc(100vh - {{(filteredcount()>pageSize) ? '13.6rem':'11rem'}})">
          <table class="table   td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table sticky-header">
            <thead>
              <tr>
                <th class="fw-600 text-uppercase" scope="col">Employee code</th>
                <th class="fw-600 text-uppercase" scope="col">Employee name</th>
                <th class="fw-600 text-uppercase" scope="col">Department</th>
                <th class="fw-600 text-uppercase" scope="col">Disabled On</th>
                <th class="fw-600 text-uppercase" scope="col">Disabled by</th>
                <th class="fw-600 text-uppercase" scope="col">REASON</th>
                <th *ngIf="permissions?.e " class="fw-600 text-uppercase" scope="col">Action</th>
              </tr>
            </thead>
            <!-- <tbody *ngIf="filteredcount()<=0 ">
              <tr>
                <td colspan="7">

                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                    <span class="fw-500 fs-14">No data found</span>
                  </div>
                </td>
              </tr>
            </tbody> -->
            <tbody class="cell-16 ">
              <ng-container
                *ngFor="let i of $any(list |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                <tr>
                  <td>{{i.employee_code}}</td>
                  <td class="text-trim w-30ch">{{i.employee_name}}</td>
                  <td>{{i.department}}</td>
                  <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(i.disabled_at)}}</span></td>
                  <td>{{i.disablesd_by}}</td>
                  <td class="text-trim w-30ch" title="{{i.reason_for_disabled}}">{{i.reason_for_disabled}}</td>
                  <td *ngIf="permissions?.e"><a (click)="enablePortalAccess = true;enableEmp=i"
                      class="link-primary1">Enable Portal Access</a></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="filteredcount()>pageSize">
      <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize" [collectionSize]="filteredcount()"
        [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='enablePortalAccess === true'>
  <form class="side-panel-container" autocomplete="off" [formGroup]="enablePortalAccessFm"
    (ngSubmit)="saveEnableAccess()">
    <header class="side-panel-head">
      <h5>Enable Portal Access </h5>
      <a class="toggle-panel" (click)="this.closeEnableSidebar()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">ENABLE PORTAL ACCESS</p>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">EMPLOYEE DETAILS</p>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Employee name</div>
                  <div class="detail-desc">
                    <div class="d-flex gap-8">
                      <img *ngIf="(enableEmp.profile_image !=  null && enableEmp.profile_image !=  '') "
                        class="avatar-circle sq-24" src="{{enableEmp.profile_image}}" />
                      <span *ngIf="(enableEmp.profile_image ==  null || enableEmp.profile_image ==  '') "
                        class="avatar-sm sq-24 me-8 rounded-circle {{enableEmp?.color_code}}"><span>{{this.appService.getFirstChar(enableEmp?.employee_name,2)}}</span></span>
                      {{enableEmp?.employee_name}}
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Employee code</div>
                  <div class="detail-desc">{{enableEmp?.employee_code}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Department</div>
                  <div class="detail-desc">{{enableEmp?.department}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Date of joining</div>
                  <div class="detail-desc">{{this.appService.dateFormatDisplay(enableEmp?.date_of_joined)}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Last login date and time</div>
                  <div class="detail-desc">{{enableEmp?.employee_last_login_time != "" &&
                    appService.isValidDateTime(enableEmp?.employee_last_login_time)?this.appService.datetimeconvert(enableEmp?.employee_last_login_time):"-"}}
                  </div>
                </div>
                <div class=" col-12">
                  <div class="detail-title">Reason for disabling portal access </div>
                  <div class="detail-desc">{{enableEmp?.reason_for_disabled}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Reason for enabling portal access </label>
            <input type="text" formControlName="reason_for_enabled" class="form-control" placeholder="Enable reason"
              [ngClass]="{ 'is-invalid': (enablePortalAccessFm.controls.reason_for_enabled.touched ||enablePortalAccessFm.controls.reason_for_enabled.dirty) &&  enablePortalAccessFm.controls.reason_for_enabled.errors}">
            <div
              *ngIf="( enablePortalAccessFm.controls.reason_for_enabled.touched ||  enablePortalAccessFm.controls.reason_for_enabled.dirty) &&  enablePortalAccessFm.controls.reason_for_enabled.errors"
              class="invalid-feedback">
              <div *ngIf="enablePortalAccessFm.controls.reason_for_enabled.errors.required">
                {{this.messageService.validationDisplay('required')}}</div>
              <div
                *ngIf="! enablePortalAccessFm.controls.reason_for_enabled.errors.pattern &&  enablePortalAccessFm.controls.reason_for_enabled.errors.maxlength">
                {{this.messageService.fieldlengthvalidation('word','500')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        [disabled]='!enablePortalAccessFm.valid'>
        Enable
      </button>
    </footer>
  </form>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='disablePortalAccess === true'>

  <form class="side-panel-container" autocomplete="off" [formGroup]="disablePortalAccessFm"
    (ngSubmit)="saveDisableAccess()">
    <header class="side-panel-head">
      <h5>Disable Portal Access </h5>
      <a class="toggle-panel" (click)="this.closeDisableSidebar()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">DISABLE PORTAL ACCESS</p>
          </div>
          <div class="col-12 form-row">
            <label class="form-label">Select an Employee</label>
            <div class="card card-c2 gap-16">
              <!-- <i class="icon-search"></i>
              <input type="text" class="form-control fs-14" name="" placeholder="Search employee " id=""> -->

              <app-searchbar #search [searchlist]='empList' [searchplaceholder]="'Search by employee code or employee name'"
                (searchEmitter)="setDisableEmployee($event)"
                (keywordsearchEmitter)="setDisableEmployee($event)"></app-searchbar>
            </div>
          </div>
          <ng-container *ngIf="disableEmp?.length>0">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">EMPLOYEE DETAILS</p>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">Employee name</div>
                    <div class="detail-desc">
                      <div class="d-flex gap-8">
                        <img *ngIf="(disableEmp[0].profile_image !=  null && disableEmp[0].profile_image !=  '') "
                          class="avatar-circle sq-24" src="{{disableEmp[0].profile_image}}" />
                        <span *ngIf="(disableEmp[0].profile_image ==  null || disableEmp[0].profile_image ==  '') "
                          class="avatar-sm sq-24 me-8 rounded-circle {{disableEmp[0]?.color_code}}"><span>{{this.appService.getFirstChar(disableEmp[0]?.employee_name,2)}}</span></span>
                        {{disableEmp[0]?.employee_name}}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">Employee code</div>
                    <div class="detail-desc">{{disableEmp[0]?.employee_code}}</div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">Department</div>
                    <div class="detail-desc">{{disableEmp[0]?.department}}</div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">Date of joining</div>
                    <div class="detail-desc">{{this.appService.dateFormatDisplay(disableEmp[0]?.date_of_joined)}}</div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="detail-title">Last login date and time</div>
                    <div class="detail-desc">{{enableEmp?.last_login_date_and_time != "" &&
                      appService.isValidDateTime(disableEmp[0]?.last_login_date_and_time)?this.appService.datetimeconvert(disableEmp[0]?.last_login_date_and_time):"-"}}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-12">
              <!-- <pre>Form Values: {{disablePortalAccessFm.value | json}}</pre> -->
              <label for="" class="form-label required">Reason for disabling portal access </label>
              <input type="text" formControlName="reason_for_disabled" class="form-control" placeholder="Disable reason"
                [ngClass]="{ 'is-invalid': (disablePortalAccessFm.controls.reason_for_disabled.touched ||disablePortalAccessFm.controls.reason_for_disabled.dirty) &&  disablePortalAccessFm.controls.reason_for_disabled.errors}">
              <div
                *ngIf="( disablePortalAccessFm.controls.reason_for_disabled.touched ||  disablePortalAccessFm.controls.reason_for_disabled.dirty) &&  disablePortalAccessFm.controls.reason_for_disabled.errors"
                class="invalid-feedback">
                <div *ngIf="disablePortalAccessFm.controls.reason_for_disabled.errors.required">
                  {{this.messageService.validationDisplay('required')}}</div>
                <div
                  *ngIf="! disablePortalAccessFm.controls.reason_for_disabled.errors.pattern &&  disablePortalAccessFm.controls.reason_for_disabled.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" [disabled]='!disablePortalAccessFm.valid'
        class="ms-auto btn btn-primary text-uppercase btn-sm">
        Disable
      </button>
    </footer>
  </form>

</div>

<app-salary-dashboard-filter  *ngIf='filter === true' [filter]="filter" [isCompanyNeeded]='true' (applymethod)="submitForm($event)"
  (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
