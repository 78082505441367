import { Component, OnInit } from '@angular/core';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { ActivatedRoute, Params } from '@angular/router';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-prior-payroll',
  templateUrl: './prior-payroll.component.html',
  styleUrls: ['./prior-payroll.component.scss']
})
export class PriorPayrollComponent implements OnInit {

  activeTab = 1;
  month:any;
  year:any;
  company:any;

  constructor(
    public salaryService: SalaryDashboardService,
    public route:ActivatedRoute,
    private previousRouteService: PreviousRouteService,
    public router:Router,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }

    })
  }
  backClicked() {
    let url  = this.previousRouteService.getPreviousUrl();
    if(url?.includes('/salary-dashboard')){
      this.router.navigateByUrl("/salary-dashboard")
    }
    else{
      this.router.navigateByUrl("/run-payroll/"+this.year+"/"+this.month+"/"+this.company)
    }

  }

}
