<!-- 
    <app-sidebar class="main-sidebar {{expandClass}}" (sidebarExpanded)="checkSidebarExpanded($event)"></app-sidebar>
    <app-header class="main-topbar "  ></app-header>
    <main class="main-container">
        <div class="main-content">
            <router-outlet></router-outlet> 
        </div>
     </main> -->
     <router-outlet></router-outlet> 

     