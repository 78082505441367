<ng-container *ngIf="permissions">
  <nav class="nav nav-custom sticky">
    <a class="nav-link {{navActive == 'Outstanding Advance' ? 'active':''}}"
      (click)="navActive = 'Outstanding Advance';tabchange=true;listdata('Outstanding');">Outstanding Advance</a>
    <a class="nav-link {{navActive == 'Advance Request' ? 'active':''}}"
      (click)="navActive = 'Advance Request';tabchange=true;listdata('Advance');">Advance
      Request</a>
    <a class="nav-link {{navActive == 'Dispense Advance' ? 'active':''}}"
      (click)="navActive = 'Dispense Advance';tabchange=true;listdata('Dispense');">Dispense Advance</a>
  </nav>
  <div class="container-fluid p-24 ">
    <div class="row mb-16">
      <div class="col-md-4 col-lg-6">
        <h3>{{navActive}}</h3>
        <p class="text-helper mb-md-0">{{title}}</p>
      </div>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex justify-content-end  gap-16">
          <div class="form-icon " style="width:22.6875rem;">
            <app-searchbar  #search [searchlist]='searchlistdata' [searchplaceholder]="searchkey"  [isLoading]="sLoading"
              (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"></app-searchbar>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
              id="dropdownBasic1" ngbDropdownToggle>
              <i class="icon-filter-left fs-13"></i>
            </button>
            <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
            (filterClose)="closefn($event)"  (resetCalled)="resetCalled($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-16">
            <div class="col-12" *ngIf="!loader && nodata">
              <div class="row " style="height: calc(100vh - 14rem);" >
                <app-nodata-view class="vstack" [noDataImg]="'bg10.png'"
                  [noDataText]="'It seems that there is no Advance Data available'"
                  [noDataPara]="'There are no advance requests or disbursements recorded at this time. Updates will appear here as they occur.'"
                  [hasPermission]="false"> </app-nodata-view>
              </div>
            </div>
      <div class="col-12" *ngIf="!nodata">
        <div class="card card-c2">
          <app-table-loader *ngIf="loader"></app-table-loader>
          <div class="table-responsive radius-4" *ngIf="!loader" style="max-height: calc(100vh - 13.5rem);">
            <table
              class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table sticky-header">
              <thead>
                <tr>
                  <th class="fw-600 text-uppercase" scope="col" *ngIf="navActive != 'Outstanding Advance'">Advance
                    number
                  </th>
                  <th class="fw-600 text-uppercase" scope="col">Employee Name</th>
                  <th class="fw-600 text-uppercase" scope="col">Employee code</th>
                  <th class="fw-600 text-uppercase" scope="col">Department</th>
                  <th class="fw-600 text-uppercase" scope="col">designation</th>
                  <th class="fw-600 text-uppercase" scope="col" *ngIf="navActive == 'Outstanding Advance'">total
                    outstanding advance</th>
                  <ng-container *ngIf="navActive == 'Advance Request'">
                    <th class="fw-600 text-uppercase" scope="col">Requested date</th>
                    <th class="fw-600 text-uppercase" scope="col">Requested amount</th>
                  </ng-container>
                  <ng-container *ngIf="navActive == 'Dispense Advance'">
                    <th class="fw-600 text-uppercase" scope="col">DISPENSE date</th>
                    <th class="fw-600 text-uppercase" scope="col">DISPENSE amount</th>
                  </ng-container>
                  <th class="fw-600 text-uppercase" scope="col" *ngIf="navActive != 'Outstanding Advance'">Status</th>
                  <th class="fw-600 text-uppercase" scope="col">Action</th>
                </tr>
              </thead>
              <!-- <tbody *ngIf="nodata ">
                <tr>
                  <td colspan="9">

                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                      <span class="fw-500 fs-14">No data found</span>
                    </div>
                  </td>
                </tr>
              </tbody> -->
              <tbody class="cell-16 ">
                <ng-container
                  *ngFor='let dataa of $any(outstandingdata| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index'>
                  <tr>
                    <td *ngIf="navActive != 'Outstanding Advance'">{{dataa?.expense_advance?.number}}</td>
                    <td>{{dataa?.employee_name}}</td>
                    <td>{{dataa?.employee_code}}</td>
                    <td>{{dataa?.department}}</td>
                    <td>{{dataa?.designation}}</td>
                    <td *ngIf="navActive == 'Outstanding Advance'">{{dataa?.total_outstanding_amount | currency :
                      dataa?.currency}}</td>
                    <ng-container *ngIf="navActive == 'Advance Request'">
                      <td><span
                          class="text-nowrap">{{this.appService.dateFormatConvert(dataa?.expense_advance?.expense_date)}}</span>
                      </td>
                      <td>{{dataa?.expense_advance?.amount | currency : dataa?.expense_advance?.currency}}</td>
                      <td *ngIf="dataa?.status == 'Pending'"><span class="badge badge-warning py-1 px-3">Pending</span>
                      </td>
                      <td *ngIf="dataa?.status == 'Carry forward'"><span class="badge badge-primary py-1 px-3">Carry
                          forwarded</span></td>
                      <td *ngIf="dataa?.status == 'Rejected'"><span class="badge badge-danger py-1 px-3">Rejected</span>
                      </td>
                      <td *ngIf="dataa?.status == 'Accepted'"><span
                          class="badge badge-success py-1 px-3">Accepted</span></td>
                    </ng-container>
                    <ng-container *ngIf="navActive == 'Dispense Advance'">
                      <td><span
                          class="text-nowrap">{{this.appService.dateFormatConvert(dataa?.expense_advance?.dispansed_date)}}</span>
                      </td>
                      <td>{{(dataa?.expense_advance?.dispense_advance)?(dataa?.expense_advance?.dispansed_amount |
                        currency : dataa?.expense_advance?.currency):'--'}}</td>
                      <td *ngIf="!dataa?.expense_advance?.dispense_advance"><span
                          class="badge badge-warning py-1 px-3">Pending</span></td>
                      <td *ngIf="dataa?.expense_advance?.dispense_advance"><span
                          class="badge badge-success py-1 px-3">Accepted</span></td>
                    </ng-container>

                    <td *ngIf="navActive == 'Outstanding Advance'"><a (click)="viewAdvancefn(dataa?.id, dataa?.currency)" class="link-primary1">View Details</a></td>
                    <td *ngIf="navActive != 'Outstanding Advance'"><a (click)="viewAdvancefn(dataa?.id, dataa?.expense_advance?.currency)" class="link-primary1">View Details</a></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="this.appService.filteredcount(outstandingdata,searchKeyword)>pageSize && !loader">
        <ngb-pagination class="d-flex justify-content-end"
          [collectionSize]="this.appService.filteredcount(outstandingdata,searchKeyword)" [(page)]="page" [maxSize]="3"
          [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
      </div>
    </div>
  </div>
  <app-emp-advance-details-view *ngIf="viewAdvance" [viewAdvance]="viewAdvance" [viewdata]="viewdata"
    [advanceid]="detailsid" [viewLoader]="viewLoader" [currency]="currency" [from]="'advancehr'"
    (panelclose)="closefnview($event)"> </app-emp-advance-details-view>
  <app-advance-outstanding-detail-view *ngIf="viewdetailsoutkey" [viewdetailsout]="viewdetailsoutkey"
    [viewdata]="viewdata" [currency]="currency" (viewpanelclose)="closeoutstandview($event)" [from]="'advancehr'"
    [viewLoader]="viewLoader"> </app-advance-outstanding-detail-view>
</ng-container>
