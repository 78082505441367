<div class="container-fluid p-24">
  <div class="row row-24">
    <div class="col-12">
      <h3>Team Attendance</h3>
    </div>
    <div class="col-4">
      <div class="card card-c2 p-24 h-100" (click)="notInYet = true">
        <div class="row row-16">
          <div class="col-12">
            <span class="fw-500">Not In Yet</span>
          </div>
          <div *ngIf="myTeamLoader" class="col-12">
            <ngx-skeleton-loader appearance="circle"
              [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}">
            </ngx-skeleton-loader>
          </div>
          <ng-container *ngIf="!myTeamLoader">
            <!-- <div class="col-12" *ngIf="(myTeamData.subheaderlist[0].total - myTeamData.subheaderlist[0].count) == 0">
                        <div class="d-flex align-items-center gap-8">
                            <img src="/assets/images/helper/helper3.png" alt="" srcset="">
                            <p class="mb-0 fs-12">
                                No Data to show
                            </p>
                        </div>
                    </div> -->
            <div class="col-12" *ngIf="myTeamData.subheaderlist[0].count == 0">
              <div class="d-flex align-items-center gap-8">
                <img src="assets\images\helper\helper2.png"  alt="" srcset="">
                <p class="mb-0 fs-12">
                  Its a houseful! Everyone from your team is in today
                </p>
              </div>
            </div>
            <div class="col-12" *ngIf="(myTeamData.subheaderlist[0].count != 0) ">
              <div class="avatar-overlap avatar-overlap-left mt-8" style="--size:3rem; --count :7;--gap:2.5rem;">
                <ng-container *ngFor="let item of myTeamData.notindata; let i = index">
                  <img class="avatar" [ngbPopover]="popContentSingle" triggers="hover"
                    *ngIf="((item.profile_image !='') && (item.profile_image != null)) && (i<6)"
                    src="{{item.profile_image}}" container="body" popoverClass="user-list single-user">
                  <div *ngIf="(item.profile_image =='') || (item.profile_image == null) && (i<6)"
                    class="avatar {{item.color_code}}" [ngbPopover]="popContentSingle" triggers="hover" container="body"
                    popoverClass="user-list single-user">
                    <span>{{this.appservice.getFirstChar(item.first_name+'
                      '+item.last_name,2)}}</span>
                  </div>
                  <ng-template #popContentSingle>
                    <div class="user-details">
                      <span class="user-label">{{item?.first_name+'
                        '+(item?.middle_name!=null?item?.middle_name:'')+'
                        '+item?.last_name}}</span>
                      <span class="user-code">EMP CODE: {{item?.employee_code}} </span>
                    </div>
                  </ng-template>
                  <div class="avatar fs-12" *ngIf="i==6" (click)="onPopoverClick($event)"
                    [ngbPopover]="popContentMultilpe" popoverClass="user-list multi-user" triggers="click"
                    container="body">
                    +{{myTeamData?.notindata?.length-6}}</div>
                </ng-container>
              </div>
            </div>
            <ng-template #popContentMultilpe>
              <ul>
                <ng-container *ngFor="let re of  myTeamData.notindata, let i = index">
                  <li *ngIf="re?.profile_image !='' && re?.profile_image !=null && (i>=6)">
                    <img class="user-avatar" src="{{re?.profile_image}}">
                    <div class="user-details">
                      <span class="user-label">{{re?.first_name+'
                        '+(re?.middle_name!=null?re?.middle_name:'')+'
                        '+re?.last_name}}</span>
                      <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                    </div>
                  </li>
                  <li *ngIf="(re?.profile_image =='' || re?.profile_image ==null ) && (i>=6)">
                    <span class="user-avatar {{re?.color_code}}">{{this.appservice.getFirstChar(re?.first_name+'
                      '+re?.last_name,2)}}</span>
                    <div class="user-details">
                      <span class="user-label">{{re?.first_name+'
                        '+(re?.middle_name!=null?re?.middle_name:'')+'
                        '+re?.last_name}}</span>
                      <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </ng-template>
            <div class="col-12">
              <ngb-progressbar class="progress-type4" type="info"
                [value]="((myTeamData.subheaderlist[0].total- myTeamData.subheaderlist[0].count-myTeamData.subheaderlist[2].count)/myTeamData.subheaderlist[0].total )*100"></ngb-progressbar>
              <span class="fs-12 text-light-500">{{myTeamData.subheaderlist[0].total-
                myTeamData.subheaderlist[0].count-myTeamData.subheaderlist[2].count}}/{{myTeamData.subheaderlist[0].total}} Employees are
                in</span>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card card-c2 p-24 h-100">
        <div class="row row-16">
          <div class="col-12 d-flex align-items-center">
            <span class="fw-500">Time Offs</span>
            <!-- <app-master-select class="config-app-master ms-auto" style="width:9rem"
                            [placeholder]="this.messageService.selectplaceholddisplay('month')" id="from_month"
                            [isrequired]="false" [selectedItem]="leaveCurrentMonth" [(ngModel)]="leaveCurrentMonth"
                            [type]="leaveListOfMonth" (ngModelChange)="monthChangeFunction($event)"></app-master-select> -->
            <div ngbDropdown class="input-dropdown four-dropdown ms-auto" container="body">
              <button type="button" class="card card-c2 h-36 fs-14" ngbDropdownToggle>
                <span class="">{{leaveCurrentMonth}} {{leaveCurrentYear}}</span>
              </button>
              <div ngbDropdownMenu class="card-c2 card p-24 " style="width:272px;">
                <div class="row">
                  <div class="col-5">
                    <label for="" class="form-label">Year </label>
                    <ng-select placeholder="Month" class="form-ngselect h-36 fs-14" [(ngModel)]="leaveCurrentYear"
                      (change)="monthChangeFunction()">
                      <ng-option *ngFor="let yData of listYears" [value]="yData">{{yData}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-7">
                    <label for="" class="form-label">Month </label>
                    <ng-select placeholder="Year" class="form-ngselect four-dropdown  h-36 fs-14"
                      [(ngModel)]="leaveCurrentMonth" (change)="monthChangeFunction()">
                      <ng-option *ngFor="let yData of months" [value]="yData">{{yData}}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="timeoffLoader" class="col-12">
            <ngx-skeleton-loader appearance="circle"
              [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}">
            </ngx-skeleton-loader>
          </div>
          <ng-container *ngIf="!timeoffLoader">
            <div class="col-12">
              <span class="fs-12 fw-500 text-uppercase">Total Employees taking timeoff</span>
            </div>
            <div class="col-12 d-flex align-items-baseline">
              <div class="d-flex align-items-baseline">
                <span class="fs-48 fw-500 lh-1">{{myTeamData.subheaderlist[1].count}}</span>
                <span class="text-light-400 fw-500">/{{myTeamData.subheaderlist[1].total}}</span>
              </div>
              <div class="ms-auto fs-12 fw-500 text-uppercase">
                <span
                  [ngClass]="{'text-success': myTeamData.subheaderlist[1].status=='positive', 'text-danger' : myTeamData.subheaderlist[1].status=='negative' }">
                  <i *ngIf="myTeamData.subheaderlist[1].status=='positive'" class="graph-increase "></i>
                  <i *ngIf="myTeamData.subheaderlist[1].status=='negative'" class="graph-decrease "></i>

                  {{myTeamData.subheaderlist[1].prevpercentage}}%</span>
                <span class="ms-1 text-light-400">From {{myTeamData.subheaderlist[1].prevmonth}}</span>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card card-c2 p-24 h-100" (click)="onLeaveToday = true">
        <div class="row row-16">
          <div class="col-12">
            <span class="fw-500">On Leave Today</span>
          </div>
          <div *ngIf="myTeamLoader" class="col-12">
            <ngx-skeleton-loader appearance="circle"
              [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}">
            </ngx-skeleton-loader>
          </div>
          <ng-container *ngIf="!myTeamLoader">
            <div class="col-12" *ngIf="myTeamData.subheaderlist[2].count != 0">
              <div class="avatar-overlap avatar-overlap-left mt-8" style="--size:3rem; --count :7;--gap:2.5rem;">
                <ng-container *ngFor="let item of myTeamData.onleavedata; let i = index">
                  <img class="avatar" *ngIf="(item.profile_image !='') && (item.profile_image != null) && (i<6)"
                    src="{{item.profile_image}}" [ngbPopover]="popContentSingle" triggers="hover" container="body"
                    popoverClass="user-list single-user">
                  <div *ngIf="((item.profile_image =='') || (item.profile_image == null)) && (i<9)"
                    class="avatar {{item.color_code}} " [ngbPopover]="popContentSingle" triggers="hover"
                    container="body" popoverClass="user-list single-user">
                    <span>{{this.appservice.getFirstChar(item.first_name+'
                      '+item.last_name,2)}}</span>
                  </div>
                  <ng-template #popContentSingle>
                    <div class="user-details">
                      <span class="user-label">{{item?.first_name+'
                        '+(item?.middle_name!=null?item?.middle_name:'')+'
                        '+item?.last_name}}</span>
                      <span class="user-code">EMP CODE: {{item?.employee_code}} </span>
                    </div>
                  </ng-template>
                  <div class="avatar fs-12" *ngIf="i==6" [ngbPopover]="popContentMultilpe2"
                    popoverClass="user-list multi-user" triggers="click" container="body">
                    +{{item?.length-6}}</div>
                </ng-container>
              </div>
            </div>
            <ng-template #popContentMultilpe2>
              <ul>
                <ng-container *ngFor="let re of  myTeamData.onleavedata, let i = index">
                  <ng-container *ngIf="i >= 3">
                    <li *ngIf="re?.profile_image !='' && re?.profile_image !=null">
                      <img class="user-avatar" src="{{re?.profile_image}}">
                      <div class="user-details">
                        <span class="user-label">{{re?.first_name+'
                          '+(re?.middle_name!=null?re?.middle_name:'')+'
                          '+re?.last_name}}</span>
                        <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                      </div>
                    </li>
                    <li *ngIf="re?.profile_image =='' || re?.profile_image ==null">
                      <span class="user-avatar {{re?.color_code}}">{{this.appservice.getFirstChar(re?.first_name+'
                        '+re?.last_name,2)}}</span>
                      <div class="user-details">
                        <span class="user-label">{{re?.first_name+'
                          '+(re?.middle_name!=null?re?.middle_name:'')+'
                          '+re?.last_name}}</span>
                        <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </ng-template>
            <div class="col-12" *ngIf="myTeamData.subheaderlist[2].count == 0">
              <div class="d-flex align-items-center gap-8">
                <img src="assets\images\helper\helper1.png"   alt="" srcset="">
                <p class="mb-0 fs-12">
                  No one is on leave today!
                </p>
              </div>
            </div>
            <!-- <div class="col-12">
                        <div class="d-flex align-items-center gap-8">
                            <img src="/assets/images/helper/helper3.png" alt="" srcset="">
                            <p class="mb-0 fs-12">
                                No data to show
                            </p>
                        </div>
                    </div> -->
            <div class="col-12">
              <ngb-progressbar class="progress-type4" type="info"
                [value]="(myTeamData.subheaderlist[2].count/myTeamData.subheaderlist[2].total)*100"></ngb-progressbar>
              <span
                class="fs-12 text-light-500">{{myTeamData.subheaderlist[2].count}}/{{myTeamData.subheaderlist[2].total}}
                Employees are on leave</span>
            </div>
          </ng-container>
        </div>
      </div>

    </div>

    <div class="col-12 mt-25" *ngIf="myTeamLoader">
      <ng-container *ngFor="let dummy of ' '.repeat(1).split(''), let x = index">
        <div class="mt-15">
          <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color': '#EAEDEF','height': '74px','width': '100%' }"></ngx-skeleton-loader>
        </div>
      </ng-container>
    </div>

    <div class="col-12" *ngIf="!myTeamLoader">

      <div class="card card-c2 ">

        <div class="card-body d-flex gap-16 ps-0 pb-0 pe-24 align-items-end">
          <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky top-48 p-0 shadow-0">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="nav-link">Team Attendance</a>
              <ng-template ngbNavContent>

                <div class="row row-16">
                  <div class="col-12 d-flex align-items-center">
                    <!-- <app-master-select class="config-app-master" style="width:9rem"
                                            [placeholder]="this.messageService.selectplaceholddisplay('month')"
                                            id="from_month" [isrequired]="false" [selectedItem]="attendCurrentMonth"
                                            [(ngModel)]="attendCurrentMonth" [type]="attendListOfMonth"
                                            (ngModelChange)="attendancemonthChangeFn($event)"> </app-master-select> -->

                    <div ngbDropdown class="input-dropdown " container="body">
                      <button type="button" class="card card-c2 h-36 fs-14" ngbDropdownToggle>
                        <span class="">{{activeMonth}} {{activeYear}}</span>
                      </button>
                      <div ngbDropdownMenu class="card-c2 card p-24 " style="width:272px;">
                        <div class="row">
                          <div class="col-5">
                            <label for="" class="form-label">Year </label>
                            <ng-select placeholder="Month" class="form-ngselect h-36 fs-14" [(ngModel)]="activeYear"
                              (change)="getattendancedata()">
                              <ng-option *ngFor="let yData of listYears" [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                          <div class="col-7">
                            <label for="" class="form-label">Month </label>
                            <ng-select placeholder="Year" class="form-ngselect four-dropdown  h-36 fs-14"
                              [(ngModel)]="activeMonth" (change)="getattendancedata()">
                              <ng-option *ngFor="let yData of months" [value]="yData">{{yData}}</ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end ms-auto gap-16">
                      <div class="form-icon " style="width:22.6875rem;">
                        <app-searchbar [searchlist]='searchListData' [searchplaceholder]='searchPlaceholder'
                          (searchEmitter)="searchResults($event)"
                          (keywordsearchEmitter)="searchEmp($event)"></app-searchbar>
                      </div>
                      <div ngbDropdown class="d-inline-block">
                        <button class=" btn-square btn btn-secondary" (click)="this.filterclick()" placement="bottom-left"
                          id="dropdownBasic1" ngbDropdownToggle>
                          <i class="icon-filter-left fs-13"></i>
                        </button>
                          <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="'myteam'" [filter]="filter"
                          (filterClose)="closefn($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
                      </div>
                      <button *ngIf="permissions?.a" class="btn flex-shrink-0 radius-2 btn-primary fs-10 fw-700 text-uppercase px-4"
                      (click)="showSidePanel=true;">Bulk Upload</button>
                    </div>
                  </div>
                  <div class="col-12">
                    <app-table-loader *ngIf="timeOffLoader"></app-table-loader>
                    <div *ngIf="!timeOffLoader" class="table-responsive card card-c2 scrollbar-10">

                      <table *ngIf="myTeamattendanceData?.length == 0"
                        class="table sticky-header sticky-first-col table-sm form-cell-sm td-0 scrollbar-10 ">
                        <thead>
                          <tr>
                            <th class="fw-600 text-center bg-white" scope="col">Name</th>
                            <th class="fw-600 text-center" style="min-width:4.875rem" scope="col"
                              *ngFor="let header of displayedColumns; let i = index">
                              {{header}}</th>
                          </tr>
                        </thead>
                        <tbody class="">
                          <tr>
                            <td [attr.colspan]="displayedColumns?.length+1">
                              <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">
                                No data found</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table *ngIf="myTeamattendanceData?.length > 0"
                        class="table sticky-header sticky-first-col table-sm form-cell-sm td-0 scrollbar-10 ">
                        <thead>
                          <tr>
                            <th class="fw-600 text-center bg-white" scope="col">Name</th>
                            <th #dateColumn class="fw-600 text-center {{(i+1) == getCurrentDay() ? 'current-day':''}}"
                              style="min-width:4.875rem" scope="col"
                              *ngFor="let header of myTeamattendanceData[0].attendance_detail ; let i = index">
                              {{header?.weekday?.slice(0,3)}}</th>
                          </tr>
                        </thead>
                        <tbody class="">

                          <tr *ngFor="let item of myTeamattendanceData ; let i = index">
                            <td (click)="singleEmployeeview(item?.employee_id)">
                              <div class=" p-12 d-flex align-items-center pointer bg-white">
                                <img *ngIf="(item?.profile_image!=null && item?.profile_image!='')"
                                  class="avatar-circle sq-32" src="{{item?.profile_image}}" alt="">
                                <div *ngIf="(item?.profile_image ==  null || item?.profile_image == '')"
                                  class="avatar-circle fs-12 sq-32 {{item?.color_code}}">
                                  {{this.appservice.getFirstChar(item?.name,2)}}</div>
                                <div class="overflow-hidden ms-16 d-flex flex-column">
                                  <p class="fs-14 fw-600  text-trim mb-0">
                                    {{item?.name}}</p>
                                  <p class="fs-10 mb-0  text-trim lh-base">
                                    {{item?.employee_code}}</p>
                                </div>
                              </div>
                            </td>
                            <td *ngFor="let day of myTeamattendanceData[i].attendance_detail,let j=index;"
                              class="cell-status-2 {{day?.attendance_class}} {{(j+1) == getCurrentDay() ? 'current-day':''}}">
                              <span class="status-span">{{j+1}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>

                  <div class="col-12">
                    <div class="d-flex flex-wrap gap-16 fs-12">
                      <div class="d-flex align-items-center gap-8">
                        <span class="type-indicator sq-16 type-weekoff"></span>Week off
                      </div>
                      <div class="d-flex align-items-center gap-8">
                        <span class="type-indicator sq-16 type-leave"></span>On leave
                      </div>
                      <div class="d-flex align-items-center gap-8">
                        <span class="type-indicator sq-16 type-present"></span>Present
                      </div>
                      <div class="d-flex align-items-center gap-8">
                        <span class="type-indicator sq-16 type-absent"></span>Absent
                      </div>
                      <div class="d-flex align-items-center gap-8">
                        <span class="type-indicator sq-16 type-holiday"></span>Holiday
                      </div>
                      <div class="d-flex align-items-center gap-8">
                        <span class="type-indicator sq-16 type-on-duty"></span>On duty
                      </div>
                      <div class="d-flex align-items-center gap-8">
                        <span class="type-indicator sq-16 type-WFH"></span>WFH
                      </div>
                      <ngb-pagination *ngIf="myTeamattendanceData?.length > 0"
                        class="ms-auto  d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPage"
                        [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
                        (pageChange)="getPageFromService($event)"></ngb-pagination>
                    </div>
                  </div>
                </div>


              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="nav-link">Shift</a>
              <ng-template ngbNavContent>
                <app-shift-mapping [fromfile]="true" [reporting_manager_status]="true"></app-shift-mapping>
              </ng-template>
            </li>
          </nav>

        </div>
        <div class="card-body px-24 py-16">
          <div class="" [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="notInYet">
  <app-non-mapped-empp [showSidePanel]="notInYet" [selectedList]="myTeamData?.notindata" [selectedName]="'Not in yet'"
    (closePanel)="closePanelNot($event)"></app-non-mapped-empp>
</ng-container>
<ng-container *ngIf="onLeaveToday">
  <app-non-mapped-empp [showSidePanel]="onLeaveToday" [selectedList]="myTeamData?.onleavedata"
    [selectedName]="'On leave today'" (closePanel)="closePanelLeave($event)"></app-non-mapped-empp>
</ng-container>
<ng-container *ngIf="showSidePanel">
  <app-import-employee-punch-override [showSidePanel]="showSidePanel" (closePanel1)="closesidePanel($event)" (uploadeddata)="uploadeddata($event)" [from]="'team'"></app-import-employee-punch-override>
</ng-container>
