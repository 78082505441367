<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="loader==false && permissions">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h5 class="mb-0">Education</h5>
      <ng-container *ngIf="addEditPermission">
        <a class="link-primary fs-14 d-flex align-items-center" *ngIf="educationData.length!=0 && permissions?.e"
          (click)="editEducation();"><i class="icon-pencil-square me-8"></i> Edit</a>
      </ng-container>
    </div>
  </div>
  <div class="col-12" *ngIf="educationData.length!=0">
    <div class="card details-card shadow-0 p-24 ">
      <div class="row row-24 " *ngFor="let edu of educationData let i = index;let last = last">
        <div *ngIf="getFieldPermission('qualification')" class=" col-12 d-flex align-items-center">
          <div class="avatar-box me-16">{{getFirstChar(edu.qualification,2) | uppercase}}</div>
          <div class="" style="width: calc(100% - 3.625rem);">
            <h6 *ngIf="getFieldPermission('qualification')" class="mb-0 text-trim">{{edu.qualification}}</h6>
            <span *ngIf="getFieldPermission('year_of_passing')"
              class="fs-10 fw-500 text-light-500 text-uppercase">{{(edu.year_of_passing!='' &&
              edu.year_of_passing!=null && edu.year_of_passing!=='None' &&
              edu.year_of_passing!=='none')?this.appservice.dateFormatDisplay(this.edu.year_of_passing):"-"}}</span>

          </div>
        </div>
        <div *ngIf="getFieldPermission('college_school')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">College/School</div>
          <div class="detail-desc">{{edu.college_school!=null?edu.college_school:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('university_board')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">University/Board</div>
          <div class="detail-desc">{{edu.university_board!=null?edu.university_board:'-'}}</div>
        </div>
        <div *ngIf="getFieldPermission('specialization')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Specialization</div>
          <div class="detail-desc">{{edu.specialization}}</div>
        </div>
        <div *ngIf="getFieldPermission('grade_cgpa')" class="col-lg-3  col-sm-6 col-12">
          <div class="detail-title">Grade/CGPA</div>
          <div class="detail-desc">{{edu.grade_cgpa!=null?edu.grade_cgpa:'-'}}</div>
        </div>
        <div class="col-12 mb-24" *ngIf="!last">
          <hr class="">
        </div>

      </div>
    </div>
  </div>

</div>

<!-- showing when no educational information available -->
<div class="row row-16" *ngIf="educationData.length==0">
  <div class="col-12">
    <div class="row row-24 py-16">
      <div class="col-12 ">
        <div class="card details-card shadow-0 px-24 py-16">
          <p class="title mb-24">Education</p>
          <div *ngIf="addEditPermission" class="col-12 d-flex">
            <a *ngIf="permissions?.a" class="btn btn-outline-primary px-64" (click)="addNewEducation()">Add Educational
              Data</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Slider edit start-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanelCreate === true'>
  <form class="side-panel-container" [formGroup]="educationForm" autocomplete="off">
    <header class="side-panel-head">
      <h5>{{heading}} Education Details</h5>
      <a class="toggle-panel" (click)="showSidePanelCreate = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 ">
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0 fs-16">EDUCATION INFORMATION</p>
          </div>
          <ng-container formArrayName="education" *ngFor="let edu of education().controls; let i = index">
            <ng-container [formGroupName]="i">
              <div class="card card-c2">
                <div class="card-body p-16 d-flex">
                  <p class="fs-14 fw-500 mb-0">Education Information {{i+1}}</p>
                  <a class="ms-auto"><i class="icon-trash pointer text-dark" *ngIf="i!=0"
                      (click)="deleteEducation(i,education().controls[i].get('id')?.value)"></i> </a>
                </div>
                <div class="card-body px-16 py-24">
                  <div class="row row-16">
                    <input type="hidden" class="form-control" name="" id="prof_id" placeholder="" formControlName="id">
                    <input type="hidden" class="form-control" name="" id="employee_id" placeholder=""
                      formControlName="employee">
                    <div class="col-sm-6 form-row ">
                      <label for="qualification" class="form-label required">Qualification </label>
                      <input type="text" class="form-control " name="qualification" id="qualification"
                        placeholder="{{this.messageService.placeholderdisp('qualification')}}"
                        formControlName="qualification"
                        [ngClass]="{ 'is-invalid': (submitted || education().controls[i].get('qualification')?.touched || education().controls[i].get('qualification')?.dirty) && education().controls[i].get('qualification')?.errors}">
                      <div
                        *ngIf="(submitted || education().controls[i].get('qualification')?.touched || education().controls[i].get('qualification')?.dirty) && education().controls[i].get('qualification')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="education().controls[i].get('qualification')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="education().controls[i].get('qualification')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                        <div
                          *ngIf="!(education().controls[i].get('qualification')?.errors?.pattern) && education().controls[i].get('qualification')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="specialization" class="form-label required">Specialization </label>
                      <input type="text" class="form-control " name="specialization" id="specialization"
                        placeholder="{{this.messageService.placeholderdisp('specialization')}}"
                        formControlName="specialization"
                        [ngClass]="{ 'is-invalid': (submitted || education().controls[i].get('specialization')?.touched || education().controls[i].get('specialization')?.dirty) && education().controls[i].get('specialization')?.errors}">

                      <div
                        *ngIf="(submitted || education().controls[i].get('specialization')?.touched || education().controls[i].get('specialization')?.dirty) && education().controls[i].get('specialization')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="education().controls[i].get('specialization')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="education().controls[i].get('specialization')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                        <div
                          *ngIf="!(education().controls[i].get('specialization')?.errors?.pattern) && education().controls[i].get('specialization')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="year_of_passing" class="form-label">Year of passing</label>
                      <div class="input-group form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || education().controls[i].get('year_of_passing')?.touched || education().controls[i].get('year_of_passing')?.dirty) && education().controls[i].get('year_of_passing')?.errors}">
                        <input (click)="datePicker.open()" class="form-control"
                          placeholder="{{this.messageService.selectplaceholddisplay('year of passing')}}" matInput
                          formControlName="year_of_passing" [matDatepickerFilter]="dateFilter"
                          [matDatepicker]="datePicker" (keydown)="disableDate()"><i class="icon-calendar pointer"
                          (click)="datePicker.open()"></i>
                        <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
                      </div>
                      <div
                        *ngIf="(submitted || education().controls[i].get('year_of_passing')?.touched || education().controls[i].get('year_of_passing')?.dirty) && education().controls[i].get('year_of_passing')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="education().controls[i].get('year_of_passing')?.errors">
                          {{this.messageService.validationDisplay('pattern')}}</div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="university_board" class="form-label ">University/Board</label>
                      <input type="text" class="form-control " name="university_board" id="university_board"
                        placeholder="{{this.messageService.placeholderdisp('university/board')}}"
                        formControlName="university_board"
                        [ngClass]="{ 'is-invalid': (submitted || education().controls[i].get('university_board')?.touched || education().controls[i].get('university_board')?.dirty) && education().controls[i].get('university_board')?.errors}">
                      <div
                        *ngIf="(submitted || education().controls[i].get('university_board')?.touched || education().controls[i].get('university_board')?.dirty) && education().controls[i].get('university_board')?.errors"
                        class="invalid-feedback">
                        <div
                          *ngIf="!(education().controls[i].get('university_board')?.errors?.pattern) && education().controls[i].get('university_board')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        <div *ngIf="education().controls[i].get('university_board')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="college_school" class="form-label required">College/School </label>
                      <input type="text" class="form-control " name="college_school" id="college_school"
                        placeholder="{{this.messageService.placeholderdisp('college/school')}}"
                        formControlName="college_school"
                        [ngClass]="{ 'is-invalid': (submitted || education().controls[i].get('college_school')?.touched || education().controls[i].get('college_school')?.dirty) && education().controls[i].get('college_school')?.errors}">
                      <div
                        *ngIf="(submitted || education().controls[i].get('college_school')?.touched || education().controls[i].get('college_school')?.dirty) && education().controls[i].get('college_school')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="education().controls[i].get('college_school')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div
                          *ngIf="!(education().controls[i].get('college_school')?.errors?.pattern) && education().controls[i].get('college_school')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        <div *ngIf="education().controls[i].get('college_school')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>

                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="grade_cgpa" class="form-label">Grade/CGPA</label>
                      <input type="text" class="form-control " name="grade_cgpa" id="grade_cgpa"
                        placeholder="{{this.messageService.placeholderdisp('grade/cgpa')}}" formControlName="grade_cgpa"
                        [ngClass]="{ 'is-invalid': (submitted || education().controls[i].get('grade_cgpa')?.touched || education().controls[i].get('grade_cgpa')?.dirty) && education().controls[i].get('grade_cgpa')?.errors}">
                      <div
                        *ngIf="(submitted || education().controls[i].get('grade_cgpa')?.touched || education().controls[i].get('grade_cgpa')?.dirty) && education().controls[i].get('grade_cgpa')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="education().controls[i].get('grade_cgpa')?.errors">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="col-12">
            <div class="card card-c2 p-16 flex-row">
              <p class="fs-14 fw-500 mb-0">New Section</p>
              <span class="ms-auto link-primary1 fs-14" (click)="addEducation()">
                <i class="icon-plus-square-fill me-8"></i>Add Education Information
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer gap-32">
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit" (click)="onCreateSubmit()"
        [disabled]="!educationForm.valid">Save</button>
    </footer>
  </form>

</div>
<!--Delete confirmation box for educational data-->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Educational Information?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('educational information')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteToggle = false">Cancel</button>

        <button type="button" class="btn btn-danger btn-sm text-uppercase"
          (click)="deleteEducationConfirm()">Delete</button>
      </div>
    </div>
  </div>
</div>

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- InfoPopup end -->