
import { Component, OnInit,Input,Output,EventEmitter, Inject, ChangeDetectionStrategy, ChangeDetectorRef  } from '@angular/core';
import { PersonalService } from 'src/app/@core/services/personal.service';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { FormBuilder,Validators  } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { EmailinputValidator } from 'src/app/@shared/validators/emailinput.validators';
import { PanfieldValidator} from 'src/app/@shared/validators/panfield.validators';
import { PhonenoValidator, phoneNumberValidator } from 'src/app/@shared/validators/phoneno.validators';
import { PinValidator } from 'src/app/@shared/validators/pinnum.validators';
import { AadharnumValidator } from 'src/app/@shared/validators/aadharnum.validators';
import { PassportValidator } from 'src/app/@shared/validators/passportnum.validators';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { debounceTime } from 'rxjs/operators'
import { Subject } from 'rxjs';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalComponent implements OnInit {
  showSidePanel       = false;
  loader              = true;
  submitted           = false;
  cState              = false;
  cCity               = false;
  pState              = false;
  pCity               = false;
  currentYear:number  = 0;
  phoneCode:any       = [];
  header:any          = "";
  loader1             = false;
  permissions:any     = [];
  @Output() loadData  = new EventEmitter();
  @Input() is_employeeRequest:any;
  @Input() loginAs :any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;
  deleteClicked = false

  validated : boolean = false
  personalUnit : any = []
  field : any

  edit : boolean = false;

  private subjectKeyUp = new Subject<any>();

  personalForm        = this.formBuilder.group({
                        id                        : 0,
                        employee                  : 0,
                        pan_number                : ['', [Validators.required,PanfieldValidator()]],
                        aadhar_number             : ['', [Validators.required,AadharnumValidator()]],
                        passport_number           : ['', [PassportValidator()]],
                        passport_expiry_date      : [null],
                        personal_email            : ['', [EmailinputValidator()]],
                        emergency_contact_name    : ['', [NamefieldValidator(),Validators.maxLength(100)]],
                        emergency_contact_number  : ['', [Validators.minLength(5),Validators.maxLength(15), NumberpatternValidator()]],
                        relation                  : ['', [UnamepatternValidator(),Validators.maxLength(100)]],
                        permanent_address         : ['', [Validators.maxLength(500)]],
                        permanent_city            : [''],
                        permanent_state           : [''],
                        permanent_country         : [''],
                        permanent_pin             : ['', [PinValidator()]],
                        current_address           : ['', [Validators.maxLength(500)]],
                        current_city              : [''],
                        current_state             : [''],
                        current_country           : [''],
                        current_pin               : ['', [PinValidator()]],
                        address_current_same      : [''],
                        phone_code                : ['']

                      },{
                        validators: phoneNumberValidator('phone_code', 'emergency_contact_number')
                      });
  @Input() personalData:any;
  @Input() id:any;
  @Input() city_list:any;
  @Input() permanent_city:any;
  @Input() country_list:any;
  @Input() current_state_list:any;
  @Input() permenent_state_list:any;
  @Input() routingUrl:any;
  checkedbox:any;
  value:any
  showInfo  = false;
  infoMsg:any;

  constructor( @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
  public appservice:AppService,private personalService:PersonalService,public messageService : MessageService,
    private notificationService:NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,public datepipe: DatePipe,public snapshot:SnapshotService, private existDataService : ExistDatasService) { }

  ngOnInit(): void {

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService(d.fieldName,d.fieldValue)
    })


    this.getPermission();
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.loader       = false;
    this.currentYear  = new Date().getFullYear();
    // this.getPhonecode();
    this.loadData.emit();
    this.personalForm.get('current_country')?.enable();
    this.personalForm.get('current_pin')?.enable();
  }
  getPermission(){
    this.permissions = this.appservice.getPermissions(this.routingUrl);
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  editPersonal(event:any){
    if(this.is_employeeRequest.is_personal_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo            = false;
      this.saveClicked = false
      if(event=='Edit')
        this.loader1 = true;
      this.getPersonalData();
      // this.getPhonecode();
      this.submitted  = false;
      this.personalForm.reset();
      this.edit = true;


      if(event=='Edit'){
        this.cState              = true;
        this.cCity               = true;
        this.pState              = true;
        this.pCity               = true;
        this.loader1             = false;
        this.personalForm.get('current_country')?.enable();
        this.personalForm.get('current_pin')?.enable();
        this.personalForm.get('current_address')?.enable();

      }
      else{
        this.personalForm.get('permanent_city')?.disable();
        this.personalForm.get('permanent_state')?.disable();
        this.personalForm.get('current_state')?.disable();
        this.personalForm.get('current_city')?.disable();
        this.personalForm.get('phone_code')?.setValue('91')
      }
      this.header         = event;
      this.showSidePanel  = true;
      this.personalForm.get('employee')?.setValue(this.id);
    }
  }
  getPhonecode(){
    this.personalService.getCountryCode().subscribe((res:any)=>{
      this.phoneCode  = res;

    })
  }

  getPersonalData(){
    this.personalService.getPersonalData(this.id,this.loginAs).subscribe((res:any)=>{
      if(res.personal.length>0){
        this.personalData       = res.personal[0];
        let passport_date:any   = '';
        if(this.personalData.passport_expiry_date!=null){
          passport_date  = this.personalData.passport_expiry_date;
        }else
        passport_date = null;
        this.personalForm.reset({
                        pan_number                  : this.personalData.pan_number,
                        aadhar_number               : this.personalData.aadhar_number,
                        passport_number             : this.personalData.passport_number,
                        passport_expiry_date        : passport_date,
                        personal_email              : this.personalData.personal_email,
                        emergency_contact_name      : this.personalData.emergency_contact_name,
                        emergency_contact_number    : this.personalData.emergency_contact_number,
                        relation                    : this.personalData.relation,
                        permanent_address           : this.personalData.permanent_address,
                        permanent_city              : (this.personalData.permanent_city != null)?this.personalData.permanent_city.id:null,
                        permanent_state             : (this.personalData.permanent_state != null)?this.personalData.permanent_state.id:null,
                        permanent_country           : (this.personalData.permanent_country != null)?this.personalData.permanent_country.id:null,
                        permanent_pin               : this.personalData.permanent_pin,
                        current_address             : this.personalData.current_address ,
                        current_city                : (this.personalData.current_city != null)?this.personalData.current_city.id:null,
                        current_state               : (this.personalData.current_state != null)?this.personalData.current_state.id:null,
                        current_country             : (this.personalData.current_country != null)?this.personalData.current_country.id:null,
                        current_pin                 : this.personalData.current_pin,
                        id                          : this.personalData.id,
                        employee                    : this.personalData.employee,
                        phone_code                  : (this.personalData.phone_code == null || this.personalData.phone_code == '') ? '91' : this.personalData.phone_code


        })
      }
      else{
        this.personalData = res.personal;
      }

      if(this.personalForm?.get('permanent_country')?.value == null || this.personalForm?.get('permanent_country')?.value == '')
        this.personalForm.get('permanent_state')?.disable();
      if(this.personalForm?.get('permanent_state')?.value == null || this.personalForm?.get('permanent_state')?.value == '')
        this.personalForm.get('permanent_city')?.disable();
      if(this.personalForm?.get('current_country')?.value == null || this.personalForm?.get('current_country')?.value == '')
        this.personalForm.get('current_state')?.disable();
      if(this.personalForm?.get('current_state')?.value == null || this.personalForm?.get('current_state')?.value == '')
        this.personalForm.get('current_city')?.disable();
    })
  }

  currentStateList() {

    this.personalForm.get('current_state')?.setValue(null);
    this.personalForm.get('current_city')?.setValue(null);
    // this.personalForm.get('current_city')?.disable();
    // this.personalForm.get('current_state')?.disable();
    if(this.personalForm.value.current_country){
        this.cState             = true
        this.personalService.geStateList(this.personalForm.value.current_country)
        .subscribe((res: any) => {

          if (res.length != 0) {
            this.current_state_list = res;
            this.personalForm.get('current_state')?.enable();

          }
        else
        this.cState     = false;
        })
      } else{
        this.personalForm.get('current_city')?.disable();
        this.personalForm.get('current_state')?.disable();
      }
      }
  currentCity(){

    this.personalForm.get('current_city')?.setValue(null);
    // this.personalForm.get('current_city')?.disable();
    if(this.personalForm.value.current_state){
        this.cCity     = true;
        this.personalService.getCityList(this.personalForm.value.current_state,true)
        .subscribe((res: any) => {
          this.personalForm.get('current_city')?.enable();

          if (res.length != 0) {
            this.city_list = res;

          }
          else
          this.cCity     = false;


        })
      } else{
        this.personalForm.get('current_city')?.disable();
      }
  }
  permenentCity(){

    this.personalForm.get('permanent_city')?.setValue(null);
    // this.personalForm.get('permanent_city')?.disable();
    if(this.personalForm.value.permanent_state){
    this.pCity          = true;

    this.personalService.getCityList(this.personalForm.value.permanent_state,true)
    .subscribe((res: any) => {
      this.personalForm.get('permanent_city')?.enable();
      if(this.personalForm.get('address_current_same')?.value==true)
      this.setCurrentSelectData();
      if (res.length != 0) {
        this.permanent_city = res;

      }
      else
      this.pCity            = false;

    })
  } else{
    this.personalForm.get('permanent_city')?.disable();
  }
  }
  permenentStateList() {
    this.personalForm.get('permanent_state')?.setValue(null);
    this.personalForm.get('permanent_city')?.setValue(null);
    // this.personalForm.get('permanent_city')?.disable();
    // this.personalForm.get('permanent_state')?.disable();
    if(this.personalForm.value.permanent_country){
    this.pState               = true;

    this.personalService.geStateList(this.personalForm.value.permanent_country)
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.permenent_state_list = res;
        this.personalForm.get('permanent_state')?.enable();
        if(this.personalForm.get('address_current_same')?.value==true)
          this.setCurrentSelectData();

      }
      else
      this.pState                 = false;

    })
  } else{
    this.personalForm.get('permanent_city')?.disable();
    this.personalForm.get('permanent_state')?.disable();
  }
  }

  get f() { return this.personalForm.controls; }

  checkValue(event:any){
    if(event.target.checked){
      this.checkedbox =true;
      this.setCurrentSelectData();
    }
    else{
      this.checkedbox =false;
      this.personalForm.get('current_country')?.enable();
      this.personalForm.get('current_pin')?.enable();
      this.personalForm.get('current_address')?.enable();
      this.personalForm.get('current_address')?.setValue("");
      this.personalForm.get('current_city')?.setValue(null);
      this.personalForm.get('current_state')?.setValue(null);
      this.personalForm.get('current_country')?.setValue(null);
      this.personalForm.get('current_pin')?.setValue("");
	    this.cState     = false;
      this.cCity      = false;

    }
  }

  currentValueChange(){
    if(this.personalForm.get('address_current_same')?.value==true)
      this.setCurrentSelectData();

  }
  setCurrentSelectData(){

    this.current_state_list = this.permenent_state_list;
    this.city_list          = this.permanent_city;
    this.cState             = false;
    this.cCity              = false;
    // this.personalForm.get('current_pin')?.disable();
    // this.personalForm.get('current_city')?.disable();
    // this.personalForm.get('current_state')?.disable();
    // this.personalForm.get('current_country')?.disable();
    // this.personalForm.get('current_address')?.disable();
    this.personalForm.get('current_address')?.setValue(this.personalForm.value.permanent_address);
    this.personalForm.get('current_city')?.setValue(this.personalForm.value.permanent_city);
    this.personalForm.get('current_state')?.setValue(this.personalForm.value.permanent_state);
    this.personalForm.get('current_country')?.setValue(this.personalForm.value.permanent_country);
    this.personalForm.get('current_pin')?.setValue(this.personalForm.value.permanent_pin);
  }
  saveClicked =false
  onSubmit(){
    this.submitted          = true;

    let passport_date:any   = '';

    if(this.personalForm.invalid){
      for(let i in this.personalForm.controls)
        this.personalForm.controls[i].markAsTouched();
      return;
    } else {
      this.saveClicked          = true;
    }
    if(this.personalForm.value.passport_expiry_date!=null){

        passport_date  = this.appservice.dateFormatConvert(this.personalForm.value.passport_expiry_date);
    }
    else
      passport_date = null

    let PersonalDataSubmit = {
      'pan_number'                : this.personalForm.value.pan_number,
      'aadhar_number'             : this.personalForm.value.aadhar_number,
      'passport_number'           : this.personalForm.value.passport_number,
      'passport_expiry_date'      : passport_date,
      'personal_email'            : this.personalForm.value.personal_email,
      'emergency_contact_name'    : this.personalForm.value.emergency_contact_name,
      'emergency_contact_number'  : this.personalForm.value.emergency_contact_number,
      'relation'                  : this.personalForm.value.relation,
      'current_address'           : this.personalForm.getRawValue().current_address,
      'current_pin'               : this.personalForm.getRawValue().current_pin,
      'permanent_address'         : this.personalForm.value.permanent_address,
      'permanent_pin'             : this.personalForm.value.permanent_pin,
      'employee'                  : this.personalForm.value.employee,
      'current_country'           : this.personalForm.getRawValue().current_country,
      'current_state'             : this.personalForm.getRawValue().current_state,
      'current_city'              : this.personalForm.getRawValue().current_city,
      'permanent_country'         : this.personalForm.value.permanent_country,
      'permanent_state'           : this.personalForm.getRawValue().permanent_state,
      'permanent_city'            : this.personalForm.getRawValue().permanent_city,
      'phone_code'                : (this.personalForm.value.emergency_contact_number!=null && this.personalForm.value.emergency_contact_number!='')?this.personalForm.getRawValue().phone_code:null,
      "old_object"                : this.personalForm.value.id,
    }





    if(this.personalData.length == 0 ){
      if(this.loginAs=='Employee'){
        PersonalDataSubmit.old_object = 0;
        delete (PersonalDataSubmit.employee);
        let tempData = {
          "temp_data": "personal",
          "data"     : PersonalDataSubmit,
        }

        this.submitted        = false;
        this.showSidePanel    = false;
        this.snapshot.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          if(res.employee_request == 0)
          {
            this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          }
          this.getPersonalData();
          this.submitted        = false;
        },
        (err: any) => {
          this.saveClicked = false;
          if(err.error.hasOwnProperty('pan_number'))
          this.setError('pan_number');
        })
      }
      else{
        this.personalService.createPersonalData(PersonalDataSubmit).subscribe((res:any)=>{
          this.getPersonalData();
          this.showSidePanel    = false;
          this.submitted        = false;
          this.saveClicked = false;
      },
      (err: any) => {

       this.saveClicked = false;
       if(err.error.hasOwnProperty('pan_number'))
       this.setError('pan_number');

      })
      }
    }
    else{
      this.submitted        = false;

      if(this.loginAs=='Employee'){
        delete (PersonalDataSubmit.employee);
        let tempData = {
          "temp_data": "personal",
          "data"     : PersonalDataSubmit,
        }
        this.showSidePanel    = false;

        this.snapshot.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          if(res.employee_request == 0)
          {
            this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          }
          this.getPersonalData();
          this.submitted        = false;
        },
        (err: any) => {
          this.saveClicked = false;
          if(err.error.hasOwnProperty('pan_number'))
          this.setError('pan_number');
        })
      }
      else{
        this.personalService.updatePersonalData(this.personalData.id,PersonalDataSubmit).subscribe((res:any)=>{

          this.showSidePanel    = false;
          this.submitted        = false;
          this.getPersonalData();
        },
        (err: any) => {
          this.saveClicked = false;
          if(err.error.hasOwnProperty('pan_number'))
          this.setError('pan_number');
        })
      }

    }

  }
  setError(field:any){
    const fc = this.personalForm.get(field);
    fc?.setErrors({
           'exist' : true
    })
    this.changeDetectorRef.detectChanges();
  }
  disableDate(){
    return false;
  }
  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 10, 0, 1),
      new Date(new Date().getFullYear() + 10, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  // Simplified function to use for validation
  existService(fieldName :any, fieldValue:any){

    const fc = this.personalForm.get(fieldName);
    if(!this.edit){
      this.value = {
        "page":"Personal",
        "field":`${fieldName}`,
        "value": `${fieldValue}`
      }
    }else{
      this.value = {
        "id": this.personalForm.get("id")?.value,
        "page":"Personal",
        "field":`${fieldName}`,
        "value": `${fieldValue}`
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.personalUnit = res.body

      this.loader = false

        if(this.personalUnit.data ==='invalid'){
          fc?.setErrors({
            'exist' : true
          })
        }
      this.changeDetectorRef.detectChanges();

    })
  }

  // For validation of keyup(name)

  keyUp(fieldName :any, fieldValue:any){

    this.subjectKeyUp.next({fieldName,fieldValue});

  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }

  phnNumSelected(val:any){
    this.personalForm.get('emergency_contact_number')?.markAsTouched();
    this.personalForm.get('emergency_contact_number')?.updateValueAndValidity();
    this.changeDetectorRef.detectChanges()
  }

  hasEmergencyContactError(): boolean {
    const emergencyContactControl = this.personalForm.get('emergency_contact_number');
    return !!(emergencyContactControl && (emergencyContactControl.dirty || emergencyContactControl.touched) && emergencyContactControl.errors);
  }
}

