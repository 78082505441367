<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">

      <app-c1-loader *ngIf="loader"></app-c1-loader>
      <ng-container *ngIf="!loader">
        <div class="row row-16 mb-16" *ngIf="payrollStatus != 'Processed' && payrollStatus != 'Publish'">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">Process f&f</p>
            </div>
            <div class="col-12">
                <div class="card card-c2  details-card ">
                    <div class="card-body hstack indicater indicater-bg8">
                        <div class="row row-16 w-100">
                            <div class="col">
                                <label for="" class="form-label required text-uppercase my-2 fw-600">process salary
                                </label>
                            </div>
                            <div class="col flex-grow-0">
                            </div>

                            <div class="col pe-0 flex-grow-0">
                                <div class="ms-auto gap-16 hstack">
                                    <button class="btn btn-primary text-uppercase btn-sm" (click)="processFandF('process')" [disabled]="saveClicked">process</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="row row-16 mb-16" *ngIf="payrollStatus == 'Processed' || payrollStatus == 'Publish'">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">Process f&f</p>
                <button class="btn btn-outline-primary btn-sm ms-auto" (click)="processFandF('unprocess')"
                    [disabled]="saveClicked">UNPROCESS F&F</button>
            </div>

            <div class="col-sm-12 form-row">
                <div class="card card-c2 details-card p-24">
                    <div class="row row-16">
                        <div class="col-12 d-flex">
                            <div class="title">F&F SUMMARY</div>
                            <div class="d-flex align-items-center ms-auto gap-8 "
                                *ngIf="(processDetails?.payslip!=null && processDetails?.payslip!='') || (processDetails?.taxslip!=null && processDetails?.taxslip!='')">
                                <span *ngIf="processDetails?.taxslip!=null && processDetails?.taxslip!=''"
                                    class="link-primary1 fs-12 fw-500" (click)="viewSlip('tax')">View Tax Slip</span>
                                <ng-container *ngIf="processDetails?.payslip!=null && processDetails?.payslip!=''">
                                    <span class="text-light-400">|</span>
                                    <span class="link-primary1 fs-12 fw-500" (click)="viewSlip('pay')">View F&F
                                        Payslip</span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="detail-title text-uppercase fs-10 fw-500">total PAYABLE AMOUNT</div>
                            <div class="detail-desc fs-24">{{processDetails?.gross | currency :
                                processDetails?.currency}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </ng-container>
    </div>
    <footer class="submit-footer">
        <button *ngIf="payrollStatus == 'Processed'" class="btn btn-primary btn-sm ms-auto text-uppercase"
            (click)="finishProcess()" [disabled]="saveClicked || loader">FINISH</button>
    </footer>
</form>

<!-- PDF viewer start -->
<ng-container *ngIf="modalToggle== true">
    <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
        [currentExtension]="currentExtension" [fileName]="pdfUrl"></app-view-file>
</ng-container>