import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {FormBuilder, Validators } from '@angular/forms';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { maxTenDigitDecimal } from 'src/app/@shared/validators/maxTenDigitDecimal.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { singleDigitDecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';

@Component({
  selector: 'app-leave-requeset-limit',
  templateUrl: './leave-requeset-limit.component.html',
  styleUrls: ['./leave-requeset-limit.component.scss']
})
export class LeaveRequesetLimitComponent implements OnInit {

  constructor(public messageService : MessageService,public appService: AppService,public existDataService : ExistDatasService,public fb : FormBuilder,public leavetypeService:leavetypeService) { }

  @Input() leaveConfigForm: any;
  @Input() leaveTypeCode: any;
  @Input() leaveTypeName: any;
  @Input() leaveRequestLimitForm : any;
  @Input() saveBtnClicked: any;


  submitted = false;


  ngOnInit(): void {
    this.addValidator()
  }
  addValidator(){
    this.setValidator('max_req_apply_tenure_status','max_req_apply_tenure','number')
    this.setValidator('max_req_apply_year_status','max_req_apply_year','number')
    this.setValidator('max_req_apply_month_status','max_req_apply_month','number')
    this.setValidator('max_leave_apply_month_status','max_leave_apply_month','decimal')
    this.setValidator('min_leave_per_req_status','min_leave_per_req','decimal')
    this.setValidator('max_leave_per_req_status','max_leave_per_req','decimal')

  }
  setValidator(parent:any,control:any,patternType:any){
    for(let i=0;i<this.leaveRequestLimitForm.length;i++){
      if(this.leaveRequestLimitForm.controls[i].get(parent).value==true){
        if(patternType=='decimal')
        this.leaveRequestLimitForm.controls[i].get(control)?.setValidators([Validators.required,singleDigitDecimalValidator(),maxTenDigitDecimal(),Validators.min(0.5)])
        else
        this.leaveRequestLimitForm.controls[i].get(control)?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(10)])

      }
      else{
        this.leaveRequestLimitForm.controls[i].get(control)?.clearValidators();
        this.leaveRequestLimitForm.controls[i].get(control)?.setValue('');

      }
      this.leaveRequestLimitForm.controls[i].get(control)?.updateValueAndValidity()
    }
  }
  get f() { return this.leaveRequestLimitForm.controls; }

  setMinMaxValidator(index:any){
    let minValue  =  this.leaveRequestLimitForm.controls[index].get('min_leave_per_req').value
    let maxValue  =  this.leaveRequestLimitForm.controls[index].get('max_leave_per_req').value
    if(minValue!='' && minValue!=null && maxValue!='' && maxValue!=null ){
      if(Number(minValue) > Number(maxValue)){
        this.leaveRequestLimitForm.controls[index].get('max_leave_per_req')?.setErrors({
          'maxlimit' : true
        })
      }
      else{

        delete this.leaveRequestLimitForm.controls[index].get('max_leave_per_req')?.errors?.maxlimit;
        this.leaveRequestLimitForm.controls[index].get('max_leave_per_req').updateValueAndValidity();
      }
    }

  }

}
