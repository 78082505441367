import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-held-salary',
  templateUrl: './held-salary.component.html',
  styleUrls: ['./held-salary.component.scss']
})
export class HeldSalaryComponent implements OnInit {
  filter = false;
  year:number=0;
  month:any;
  company:any;
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  items: any[] = [];
  loader=true
  page = 1;
  pageSize =20;
  searchString:any = "";
  selectedList:any = [];
  message = "";
  downloadMsg = false;
  componentLoader = true;
  viewBreakup = false;
  viewBreakUpDetails: any;
  // total: any;
  inputdata :any;
  saveComplete  = false

  constructor(public route:ActivatedRoute,private sds:SalaryDashboardService,public messageService : MessageService,private notificationService: NotificationService,public appservice:AppService) { }

  ngOnInit(): void {
     this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = params['company'];
      }
    })
    this.getListData()
  }
  resetFilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    this.getListData()
    this.filter  = false
  }
  submitForm(data:any){
    console.log("filter data",data);
    this.filter           = false
    this.buList          = data.buList!=""?data.buList.join(','):[]
    this.DepList         = data.DepList!=""?data.DepList.join(','):[]
    this.DesignationList = data.DesignationList!=""?data.DesignationList.join(','):[]
    this.gradeList       = data.gradeList!=""?data.gradeList.join(','):[]
    this.BranchList      = data.BranchList!=""?data.BranchList.join(','):[]
    this.getListData()
  }
  closeFilter(){
    this.filter  = false
  }
  getListData(){
    this.sds.getHoldList(this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{
      this.items        = res.body.data;
      this.loader       = false
      this.searchString = "";

     });
  }

  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }
  filterIt() {
    return this.items.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  filteredcount(){
    return this.filterIt().length;
  }
  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every(item => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  createSelectedLIst(){
    this.selectedList = [];

    this.items.forEach((item:any, key:any) => {
      if(item.checked == true){
        this.selectedList.push({"id":item.id,"month":item.pay_month,"year":item.pay_year});
      }
    });
    if(this.selectedList.length<=0){
      this.notificationService.handleErrorNotification("Select Employees before Release Salary","Select Employees");
    } else  {
      this.message = "Click the Release salary button, If you want to release the salaries for the selected employees."
      this.downloadMsg = true;
    }
  }


  releaseSalary(){
    this.loader       = true
    this.saveComplete = true;
    var data ={
      "employee_list":this.selectedList
    }
    this.sds.saveHoldList(data).subscribe((res: any) => {
      this.getListData()
      this.downloadMsg = false;
      this.notificationService.handleSuccessNotification("Selected Employee's salary released successfully.","Salary Released");
      this.saveComplete = false;
    });
  }


  viewBreakUp(id:any, status:any,year:any,month:any){
    this.componentLoader = true;
    this.sds.getViewBreakupDetails(id,year,month,status).subscribe((res:any)=>{
      if(res){
        this.viewBreakUpDetails = res.body;
        // this.total = (this.viewBreakUpDetails.total_earnings[0].contribution_total + this.viewBreakUpDetails.total_earnings[0].gross_benefits + this.viewBreakUpDetails.total_earnings[0].other_benefits) - this.viewBreakUpDetails.total_earnings[0].recurring_deductions;
           this.viewBreakup = true;
           this.componentLoader = false;
      }
    })

  }

  selectAll(){
    this.inputdata = this.appservice.selectAll(this.items)
  }
  countSelected(){
    return this.items.filter(obj => obj.checked === true).length;
  }
  unselectAll(){
    this.inputdata = this.appservice.unselectAll(this.items)


  }

}
