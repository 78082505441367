<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Pay Group</h3>
      <p class="text-helper mb-md-0">A pay group is a set of employees who are grouped together for payroll processing.
      </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='paymentPlaceholder'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchpaygroup($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>

        <button *ngIf="permissions.a" routerLink="/pay-group/pay-group-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add
          Pay Group</button>
      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata == false" class="row row-16" infiniteScroll [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
    <ng-container *ngIf="loader==false">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 " *ngFor="let item of paygroupList,let i = index">
        <div class="card card-custom1 card-hover">
          <div class="card-body p-0 d-flex align-items-start">
            <div class="d-flex p-16 {{(item.is_active == true) ? 'view-section' : 'w-100' }} "
              (click)="viewpaygrp(item.id)">

              <div class="logo-img {{item.color_code}}"> {{ this.appService.getFirstChar(item['name'],2) }}</div>
              <div class="overflow-hidden">
                <h6 class="tips text-trim mb-0">{{item['name']}}</h6>
                <span class="tips-helper">{{item['name']}}</span>
                <span
                  class="fs-10 text-uppercase fw-500 {{(item['is_active'] == true) ? 'text-success' : 'text-danger'}}">{{(item['is_active']
                  == true) ? 'Active' : 'Deleted'}}</span>
              </div>
            </div>
            <div *ngIf="item.is_active==true && (permissions.e || permissions.d)" ngbDropdown
              class="pe-16 py-16 dropdown-section d-inline-block ">
              <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-three-dots-vertical fs-16"></i>
              </button>
              <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                <button *ngIf="permissions.e" ngbDropdownItem
                  (click)="editPayGroup(item.id,item.is_used_salarystructure)">
                  <i class="icon-pencil-square me-1"></i> Edit
                </button>
                <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                  (click)="deleteToggle=true;deleteId=item.id;">
                  <i class="icon-trash  me-1"></i> Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Loader updations -->
    <ng-container *ngIf="infinityloader">
      <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </ng-container>

  </div>
  <div *ngIf="nodata == true && !loader" class="row " style="height: calc(100vh - 12rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg43.png'" [noDataText]="'It Looks Like Pay Groups Are Not Set'"
      [noDataPara]="'Add pay groups to organize your salary structure. Use \'Add Pay Group\' to configure them.'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()">
    </app-nodata-view>
  </div>
</div>
<!-- view -->
<div class="side-panel" style="--sidepanel-width:44.6875rem;" [class.side-pane-active]='showSidePanel === true'
  *ngIf="permissions">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- View loader start -->
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <!-- View loader end -->
        <ng-container *ngIf="!loader1">
          <div class="d-flex mb-16" *ngIf="permissions">
            <p class="text-uppercase fw-500 mb-0">PAY GROUP</p>
            <a *ngIf="permissions.e && paygrpview.is_active"
              class="link-primary fs-14 d-flex align-items-center ms-auto"
              (click)="editPayGroup(paygrpview.id,paygrpview.is_used_salarystructure)">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-24 ">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">PAY GROUP</span></div>
                  <div class="col-6">
                    <div class="detail-title">Pay group name</div>
                    <div class="detail-desc">{{paygrpview.name}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="paygrpview.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="paygrpview.is_active==false">DELETED</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Payslip</div>
                    <div class="detail-desc">{{paygrpview.pay_slip_name!=null && paygrpview.pay_slip_name!='' ?
                      paygrpview.pay_slip_name:'--'}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Tax slip</div>
                    <div class="detail-desc">{{paygrpview.tax_slip_name!=null && paygrpview.tax_slip_name!='' ?
                      paygrpview.tax_slip_name:'--'}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Appointment letter</div>
                    <div class="detail-desc">{{paygrpview.appointment_letter?.name != null && paygrpview.appointment_letter?.name !='' ? paygrpview.appointment_letter?.name  : '--'}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Salary revision letter</div>
                    <div class="detail-desc">{{paygrpview.salary_revision_letter?.name != null && paygrpview.salary_revision_letter?.name !='' ? paygrpview.salary_revision_letter?.name  : '--'}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Description</div>
                    <div class="detail-desc">{{paygrpview.description != null && paygrpview.description != '' ? paygrpview.description : '-'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">COMPONENTS </span></div>
                  <div class="row row-16">
                    <div class="col-sm-6" *ngFor="let paycomponent of paygrpview.paycomponent, let i = index">
                      <div class="card card-c2">
                        <div class="card-body p-16">
                          <div class="d-flex mb-16  align-items-center gap-8"
                            *ngIf="paycomponent['pay_component_name'] != null">
                            <div class="fs-12 sq-24 flex-center radius-4 {{paycomponent.color_code}}">
                              {{ this.appService.getFirstChar(paycomponent['pay_component_name'],2) }}
                            </div>
                            <div class="fs-12 fw-600 text-break">
                              {{paycomponent.pay_component_name}}
                            </div>
                          </div>
                          <div class="d-flex mb-16  align-items-center gap-8"
                            *ngIf="paycomponent['pay_component_name'] == null">
                            <div class="fs-12 sq-24 flex-center radius-4 {{paycomponent.color_code}}">
                              {{ this.appService.getFirstChar(paycomponent['contribution'],2) }}
                            </div>
                            <div class="fs-12 fw-600">
                              {{paycomponent.contribution}}
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="detail-title" *ngIf="paycomponent.formula_type == true">Formula</div>
                            <div class="detail-title" *ngIf="paycomponent.formula_type == false">Fixed</div>
                            <div class="detail-desc" *ngIf="paycomponent.formula_type == true">{{paycomponent.formula}}
                            </div>
                            <div class="detail-desc" *ngIf="paycomponent.formula_type == false">{{paycomponent.formula}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">RIGHTS</span></div>
                  <div class="col-12">
                    <div class="detail-title">Applicable to</div>
                    <div class="detail-desc">{{rightspaygrp.unit_type}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">{{rightspaygrp.unit_type}}(s) </div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1">
                        <ng-container *ngIf="rightspaygrp.unit_type == 'Employee'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="rightspaygrp.unit_type == 'Company'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngIf="(rightspaygrp.unit_type != 'Employee') && (rightspaygrp.unit_type != 'Company')">
                          <div class="d-flex flex-wrap mt-8 gap-8">
                            <ng-container *ngFor="let rights of assignees, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>

                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Pay Group?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Pay Group')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteToggle = false;deleteClicked = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="deleteContent(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
