
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewAdvance === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="closefnview()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader" >
        <div class="row row-24 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">ADVANCE DETAILS ({{viewdata?.number}})</p>            
          </div>
          <ng-container *ngIf="from != 'expenseAdvanceRequestView' && from !='advancehr' && viewdata?.dispense_advance">
          <div class="col-12" *ngIf="viewdata?.report_name == null || viewdata?.report_name == ''">
            <div role="alert" class="alert alert-warning py-10 mb-0 pe-16 show">
              <p class="mb-0 fs-14 hstack "> <span class="fw-600 pe-8">Note : </span> Apply this advance to the report </p>
              <span class="ms-auto link-primary1 fw-500" (click)="addReportfn()">Add to report </span>
            </div>
          </div>
         </ng-container>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">ADVANCE DETAILS</p>
                  <div class="d-flex align-items-center gap-8 ms-auto">
                    <span class=" fs-14 ms-auto px-2 fw-500 badge-warning  badge3"
                      *ngIf="viewdata?.status == 'Pending'">
                      {{viewdata?.status}}
                    </span>
                    <span class=" fs-14 ms-auto px-2 fw-500 badge-success  badge3"
                      *ngIf="viewdata?.status == 'Accepted' || viewdata?.status == 'Approved'">
                      {{viewdata?.status}}
                    </span>
                    <span class=" fs-14 ms-auto px-2 fw-500 badge-danger  badge3"
                      *ngIf="viewdata?.status == 'Rejected'">
                      {{viewdata?.status}}
                    </span>
                    <span class="fs-14 ms-auto px-2 fw-500 badge-primary  badge3"
                      *ngIf="viewdata?.status == 'Carry forward'">
                      {{viewdata?.status}}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="card details-card shadow-0 p-16">
                    <div class="row row-16">

                      <div class="col-sm-4 col-12">
                        <div class="detail-title">Associated report</div>
                        <div class="detail-desc">{{viewdata?.report_name?viewdata?.report_name:'--'}}</div>
                      </div>
                      <div class="col-12">
                        <div class="detail-title">Expense advance amount</div>
                        <div class="detail-desc">{{viewdata?.amount | currency : currency}}</div>
                      </div>
                      <div class="col-sm-6 col-12" *ngIf="viewdata?.dispense_advance">
                        <div class="detail-title">Dispensed amount</div>
                        <div class="detail-desc">{{viewdata?.dispansed_amount | currency : currency}}</div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="detail-title">Outstanding amount</div>
                        <div class="detail-desc">{{viewdata?.outstanding_amount | currency : currency}}</div>
                      </div>
                      <div class=" col-12">
                        <div class="detail-title">Purpose</div>
                        <div class="detail-desc">{{viewdata?.purpose}}</div>
                      </div>
                      <div class="col-sm-4 col-12">
                        <div class="detail-title">Required date</div>
                        <div class="detail-desc">{{appService.dateFormatDisplay(viewdata?.expense_date)}}</div>
                      </div>
                      <!-- <div class="col-sm-4 col-12">
                        <div class="detail-title">Apply to Trip</div>
                        <div class="detail-desc"> {{viewdata?.trip}}</div>
                      </div> -->
                      <div class="col-12" *ngIf="viewdata?.attachment!=null">
                        <div class="detail-title mb-8">Document</div>
                        <div class="detail-desc" *ngIf="viewdata?.attachment == ''">-</div>
                        <div style="width:12.9375rem;" *ngIf="viewdata?.attachment != ''">

                            <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                                <i
                                    class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                                <div
                                    class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                                    <span class="tips text-uppercase fs- fw-700 text-trim"
                                        *ngIf="viewdata?.attachment != ''">{{
                                        this.appService.getDocName(viewdata?.attachment) }}</span>
                                    <span class="tips-helper " *ngIf="viewdata?.attachment != ''">{{
                                        this.appService.getDocName(viewdata?.attachment) }}</span>
                                    <div class="d-flex gap-8">

                                        <div class="link-primary" (click)="viewDocument(viewdata?.attachment)">
                                            <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary"
                                            (click)="this.appService.downloadFile(viewdata?.attachment,this.appService.getDocName(viewdata?.attachment))">
                                            <i class="bi bi-download me-1"></i>Download
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer" *ngIf="from=='advancehr' && (viewdata?.status == 'Pending')">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="sendreminder(this.viewdata.advanceid)">
        Send reminder
      </button>
    </footer>
  </div>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:57.625rem;"
  [class.side-pane-active]='addReport === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Add to Report</h5>
      <a class="toggle-panel" (click)="addReport = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 ">REPORT DETAILS</p>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-16">
              <div class="card card-c2">
                <app-table-loader *ngIf="tblloader"></app-table-loader>
                <div class="table-responsive radius-4" *ngIf="!tblloader ">
                  <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                    <thead>
                      <tr>

                        <th class="fw-600 text-uppercase" scope="col">Select</th>
                        <th class="fw-600 text-uppercase" scope="col">Report number</th>
                        <th class="fw-600 text-uppercase" scope="col">report name</th>
                        <th class="fw-600 text-uppercase" scope="col">report date</th>
                        <th class="fw-600 text-uppercase" scope="col">total</th>

                      </tr>
                    </thead>
                    <tbody *ngIf="reportlist.length==0 ">
                      <tr>
                          <td colspan="9">
                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                  <span class="fw-500 fs-14">No data found</span>
                              </div>
                          </td>
                      </tr>
                    </tbody>
                    <tbody class="cell-16" *ngIf="reportlist.length > 0 ">
                      <ng-container *ngFor='let reportlist of $any(reportlist| slice: (pagereport-1) * pageSizereport : (pagereport-1) * pageSizereport + pageSizereport),let i = index'>
                        <tr>
                          <td scope="col" class="form-cell">
                            <input name="radio-name" type="radio" id="radio2"
                              class="form-check-input m-0" value="{{reportlist.id }}"(change)="CheckedOrNot($event)">
                            </td>
                          <td>{{reportlist.number}}</td>
                          <td>{{reportlist?.name}}</td>
                          <td>{{appService.dateFormatDisplay(reportlist?.from_date)}} - {{appService.dateFormatDisplay(reportlist?.to_date)}}</td>
                          <td>{{reportlist?.total_amount | currency : currency}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="this.appService.filteredcount(reportlist,'')>pageSizereport">
              <ngb-pagination class="d-flex justify-content-end"
                  [collectionSize]="this.appService.filteredcount(reportlist,'')" [(page)]="pagereport"
                  [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                  [pageSize]="pageSizereport">
              </ngb-pagination>
            </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="reportlist.length == 0" (click)="mapreport()">
        Add
      </button>
    </footer>
  </div>
</div>
<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
    [alertMsg]="investalertMsg">
</app-alert-toggle>
<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"   [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName">
</app-view-file>
