import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-upload-progress-bar',
  templateUrl: './upload-progress-bar.component.html',
  styleUrls: ['./upload-progress-bar.component.scss']
})
export class UploadProgressBarComponent implements OnInit {

  @Input() progress: any;
  @Input() iscompleted: any;
  @Input() isProgressStart: any;

  @Input() heading: any;
  @Input() body: any;
  @Input() processingText: any;
  @Input() timeLeft: any = '';
  @Input() showClose:boolean = false

  @Output() closePanel = new EventEmitter;

  constructor() { }

  ngOnInit() {
  }

}
