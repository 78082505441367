import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';

@Component({
  selector: 'app-fnf-leave-approval',
  templateUrl: './fnf-leave-approval.component.html',
  styleUrls: ['./fnf-leave-approval.component.scss']
})
export class FnfLeaveApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() Leave:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  timeOffTrend = false;
  pageSize = 20;
  page = 1
  viewDetails = false;
  viewData:any = [];
  today =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  btndisable = false
  delArray : any = []
  laeveBalanceDate: any           = "";
  leaveBalance = false;
  compOffData: any = [];


  colorScheme :any = {
    domain: ['#28A745','#FFC107','#DC3545']
  };
  multi : any = [];
  nullStatus                      = false;
  loader = false

  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  disabled                        = false;

  leaveTypeBalanceDataTile:any[]  = [];
  leaveBalanceTable:any[]         = [];


  // For year dropdown in on duty request trend
  leaveType:any                   = [];
  yearList : any                  = []
  currentYear                     = new Date().getFullYear()
  selectedYear :any;
  selectedYear1 :any              = 'All leave types';
  leave_type:any;
  formBuilder: any;
  lastDate:any                    = new Date(this.currentYear, 11, 31);
  barchart:any               = [];
  barChartXAxisLabels:any    = [];
  barChartYAxisLabel:any;
  barchartLoader             = false;
  warningMsgToggle: any = false;
  warningMsg: any = '';

  onSelect(event:any) {
  }

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  constructor(public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,public appService: AppService,private datePipe:DatePipe,private notificationService: NotificationService,private inboxservice: InboxService,private investmentService:InvestmentDeclerationService, public appservice:AppService,) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.validation();
      if (this.Leave.related_compoff_data?.length > 0) {
        for (let i = 0; i < this.Leave.related_compoff_data?.length; i++) {

          let attendance_date = this.appservice.dateFormatDisplay(this.Leave.related_compoff_data?.[i]?.attendance_date);
          let eligible_compoff = this.Leave.related_compoff_data?.[i]?.eligible_compoff == 'Full Day' ? '1 day' : '0.5 day';

          this.compOffData?.push(attendance_date + ' - ' + eligible_compoff);

        }
      }
    }, 1000);
  }

  timeOffTrendFunction(){
    this.yearList = []
    this.getLeaveType();
    this.yearListCreate();
    this.selectCurrentYear()
    this.timeOffTrend = true;
    this.getTimeOffTrendData();
  }

  form = this.fb.group({
    read_status     : [true,Validators.required],
    star            : [false,Validators.required],
    approved_status : [false,Validators.required],
    comment         : ['', Validators.maxLength(500)],
  })

  trendsForm = this.fb.group({
    leave_type      : [''],
  })


  getLeaveType(){
    this.investmentService.getleavetypeList(this.empId).subscribe((res:any)=>{
     this.leaveType = res;
    })
  }

  yearListCreate(){
    this.yearList=[];
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push(String(i))
    }
  }

  selectCurrentYear(){
    this.selectedYear = this.currentYear
    this.yearChange(this.selectedYear)
  }

  getTimeOffTrendData(){
    this.barchartLoader = true;
    this.investmentService.timeOffTrend({'employee' : this.empId,'leave_type' : this.leave_type,'year' : Number(this.selectedYear)}).subscribe((res: any) => {
      this.multi      = res?.data;
      if(this.multi?.length > 0)
      this.barChartDataConstruction(this.multi)
     this.nullStatus = res?.null_status;
    });
  }

   // Bar chart
   barChartDataConstruction(data:any){

    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Days';
    this.barchart            = [];
    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Accepted"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });

    this.barchart.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }
  // End

  yearChange(val:any){
    this.selectedYear = val;
    this.getTimeOffTrendData();
  }

  leaveTypeChange(val:any){
    this.leave_type = val;
    this.selectedYear1 = val;
    this.getTimeOffTrendData();
  }

  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 5, 0, 1),
    new Date(new Date().getFullYear() + 5, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  disableDate(){
    return false;
  }

  dateChangeFunction(e:any){
    this.laeveBalanceDate = this.appservice.dateFormatConvert(e.target.value);
    this.leaveBalanceFunction();

  }

  leaveBalanceFunction(){
    this.leaveBalance = true;
    this.loader = true;
    if(this.laeveBalanceDate == ""){
      this.laeveBalanceDate               = this.appservice.dateFormatConvert(new Date());
    }
    this.investmentService.leaveTransaction( this.empId, this.laeveBalanceDate).subscribe((res:any)=>{
        this.loader                       = false;
        this.leaveBalanceTable            = res?.data?.leave_transaction_data;
        this.leaveTypeBalanceDataTile     = res?.data?.leave_balance_data
        ;
    })

  }

  get f() {return  this.form.controls; }

  confirm(status:any){

    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }
      this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
        if(res){
          this.refreshInbox(false);
          this.form.get('comment')?.reset('')

        }
      })
    }
  }

    /********* VIEW DOCUMENT FUNCTION ****************/
    viewDocument(filename:any){
      this.modalToggle            = true;
      this.fileName               = filename;
      this.currentDocName         = this.appservice.getDocName(filename);
      this.currentExtension       = this.fileName.split('.').pop();
      if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
        this.modalToggle          = false;
        this.alertToggle          = true;
        this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
      }
    }


   // test start
   refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

// test end

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool:any){
    this.alertToggle = bool
  }

  // Date Format for FULL day
  fullday(datas:any) {
    let data : any = [];
    if(datas?.length > 0 && datas != null && datas != undefined){
      for(let i=0;i<datas?.length;i++){
         data?.push(this.appservice.dateFormatDisplay(datas[i]))
      }
    }

    return data?.reverse();
  }
  // End

  // Date Format for HALF day
  halfday(datas:any,fromportion:any,toportion:any) {
    let data : any = [];
    if(datas?.length > 0 && datas != null && datas != undefined){

      for(let i=0;i<datas?.length;i++){
         data?.push(this.appservice.dateFormatDisplay(datas[i]))
      }
      data = data?.reverse();
      if(data?.length > 1){
        data[0] = data[0]+' ('+fromportion+')';
        data[1] = data[1]+' ('+toportion+')';
        }else{
          data[0] = data[0]+' ('+fromportion+')';
        }
    }
    return data;
  }
  // End


    // Validation fot OT and Comp off
    validation() {
      if (this.Leave.invalid_dates?.length > 0) {
        this.warningMsgToggle = true;
        for (let i = 0; i < this.Leave.invalid_dates?.lengt; i++) {
          this.Leave.invalid_dates[i] = this.appservice.dateFormatDisplay(this.Leave.invalid_dates[i])
        }
        if (this.Leave.invalid_reason == 'Overtime approved & attendance processed')
          this.warningMsg = "Overtime is already processed for the selected dates" + ' (' + this.Leave.invalid_dates?.join(', ') + ')' + ". You cannot approve the work from home request for these dates.";
        else
          this.warningMsg = "Compensatory off credited against the selected dates" + ' (' + this.Leave.invalid_dates?.join(', ') + ')' + ". has already been availed. You cannot approve work from home request for the selected dates.";
      } else {
        this.warningMsgToggle = false;
        this.warningMsg = '';
      }
    }
    // End


}
