import { ChangeDetectorRef, Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, delay, switchMap } from 'rxjs/operators';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { LeaveBalanceHRService } from 'src/app/@core/services/leave-balance-hr.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as XLSX from 'xlsx';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';


@Component({
  selector: 'app-leave-balance',
  templateUrl: './leave-balance.component.html',
  styleUrls: ['./leave-balance.component.scss']
})
export class LeaveBalanceComponent implements OnInit {

  // Variables
  landScreenData     : any
  loader             : boolean = false;
  searchListData     : any     = [];
  searchHolder       : any
  searchString       : any
  empId              : number  = 0
  searchData                   = new Subject<any>();
  filterpanel        : boolean = false;
  filterData         : any     = `employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`
  filterResultData   : any     = `employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`
  resetFil           : boolean = false
  noData             : boolean = false;
  from                         = 'EmployeeLeave';
  displayedColumns   : any     = ['Employee']
  permissions        : any
  selectedName       : any     = 'Employees not mapped'
  selectedList       : any     = []
  showSidePanel      : boolean = false
  alertDiv           : boolean = true

  // Import SidePanel
  viewSidePanel      : boolean = false
  fileName       : string    = '';
  fileSize       : string    = '';
  invalidFormat              = false;
  deleteToggle               = false;
  deleteMsg                  = "";
  iscompleted                = false;
  isDataUpload               = 0;
  percentage     :any        = 0;
  isProgressStart            = false;
  successDataCount           = 0;
  downloadMsg                = false;
  invalid                    = false
  confirmMsg                 = false;
  exportData     :any        = [];
  excelData      :any        = [];
  importData     :any        = [];
  failedData     :any        = [];
  importTemp     :any        = [];
  errorMsg                   = '';
  successMsg                 = '';
  body                       = '';
  ws             :any        = null;
  saveDataArray  :any        = []
  chunkLen                   = 10;

  // Page
  page                         = 1
  offset                       = 0
  limit                        = 20
  lastPage           : any

  format = 'Array'
  // Filter Form
  filterForm  = this.fb.group({
    company          : '',
    bu               : '',
    branch           : '',
    department       : '',
    designation      : '',
    grade            : '',
    emp_status       : '',
    emp_type         : '',
    repomanager      : '',
    funmanager       : '',
    sortby           : 'All',
  })
  sLoading = false
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private leaveService  : LeaveBalanceHRService,
    public messageServ    : MessageService,
    private fb            : FormBuilder,
    public empServ        : EmployeeService,
    public appService    : AppService,
    private notificationService: NotificationService,
    public changeDetectorRef: ChangeDetectorRef
  ) { }

  searchDataCalled : boolean = false
  isMemorized : boolean = false
  ngOnInit(): void {
    // PlaceHolder for Search
    this.searchHolder = this.messageServ.searchdisplay('employee')
    // Search EMP Delay
    this.searchData.pipe(delay(1000),
    switchMap(() => this.leaveService.getAllDatasLeave(this.limit,this.offset,this.filterData,this.searchString,this.empId))).subscribe((res:any)=>{
      // Used this var for not overriding the main api call with search data
      this.searchDataCalled = true;
      this.arrangeApiData(res.body)
    })
    // Permission
    this.getPermission()
    let arrFilter: any = []
    let arrList = []
    let arrData = []
    if(this.leaveService.FILTERDATA != undefined && this.leaveService.FILTERDROP != undefined){
      this.filterData = this.leaveService.FILTERDATA
      this.isMemorized = true
      arrData = this.filterData.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })
      this.filterResultData = this.leaveService.FILTERDROP
      let ordering:any = this.appService.memOrdering()
      this.filterForm.reset(this.appService.memFilterArray3(arrFilter,ordering));
    }else{
      this.isMemorized = false
    }
    // Initial API call
    this.landingScreenDataAPI();
  }

  // Main API
  landingScreenDataAPI(){
    if(this.searchString == undefined){
      this.searchString = ''
    }
    this.loader = true
    this.leaveService.getAllDatasLeave(this.limit,this.offset,this.filterData,this.searchString,this.empId).subscribe((res:any)=>{
      if(!this.searchDataCalled){
        this.arrangeApiData(res.body)
      }
    })

  }


  // API Data Arranging
  arrangeApiData(res:any){
    if(res.data.length!=0){
      this.landScreenData = res?.data
      this.lastPage = res?.list_length
      this.selectedList = res?.not_assigned_employees_data
    }else{
      this.selectedList = []
    }
    this.displayedColumns = []
      this.displayedColumns = ['Employee']
      res?.leavetypeslist?.forEach((leave:any)=>{
        this.displayedColumns.push(leave)
      })
    this.noData = res.data?.length == 0 ? true : false
    this.loader = false
    this.sLoading = false
  }


  // Search Results
  searchResults(data:any){
    this.loader = true
    if(data == ''){
      this.searchString = ""
      this.offset = this.empId = 0
      this.page = 1
      this.landScreenData = []
      this.searchData.next()
    }else{
      this.empId = data
      this.offset = 0
      this.page = 1
      this.searchData.next()
    }
  }

  // Search Emp
  searchEmp(keyWord:any){
    this.sLoading = true
    this.loader = true
    this.offset = this.empId = 0
    this.landScreenData = []
    this.searchString = keyWord
    if(this.searchString!=""){
      this.page = this.offset = 0
      this.searchData.next()
    }else{
      this.page = this.offset = 0
    }
  }

  // Filter
  resultfilterfn(data:any){
    this.searchDataCalled = false
    this.child.clearBar();
    this.searchListData = []
    this.filterData = data
    this.leaveService.setFilterData(data)
  }

  // Reset
  resetCalled(e:any){
    this.resetFil = e
    this.filterData = ''
    this.filterpanel = false
    this.offset = 0
  }

  searchDrop(val:any){
    this.filterResultData = val
    this.leaveService.setFilterDrop(val)
    this.offset = 0
    this.page = 1
    this.child.clearBar()
  }

  // Close Panel
  closefn(daata:any){
    this.filterpanel = daata;
  }

  // Pagination
  getPageFromService(val:any){
    let oldOffset = (val - 1) * this.limit
    this.offset = oldOffset
    this.searchDataCalled = false
    this.landingScreenDataAPI()
  }

  // Export
  exportDatas(){
    this.limit = this.lastPage
    this.offset = 0
    let newData : any = []
    let exportingData : any =[]
    this.loader = true
    this.leaveService.getLandingScreenData(this.limit,this.offset,this.searchString,this.filterData,this.empId).subscribe((res:any)=>{
      if(res.body.data.length !== 0){
        newData = res.body.data
        for(let i=0;i<newData.length;i++){
          let exportData : any = {}
          for(let j = 0;j<newData[i].leavetypedata.length;j++){
            exportData['Employee Code'] = newData[i].employee_code
            exportData['Employee Name'] = newData[i].name
            if(newData[i].leavetypedata[j].balance == 'NA' || newData[i].leavetypedata[j].taken == 'NA'){
              exportData[newData[i].leavetypedata[j].leave_name] = "NA"
            }else{
              exportData[newData[i].leavetypedata[j].leave_name] = newData[i].leavetypedata[j].taken+"/"+newData[i].leavetypedata[j].balance
            }
          }

          exportingData.push(exportData)
        }
      }else{
        for(let key in this.displayedColumns){
          exportingData.push({[this.displayedColumns[key]]: '' });
        }
      }
      this.appService.exportExcel(exportingData, 'Leave Balance')
      this.loader = false
    })
    this.limit = 20
  }

  // Permission
  getPermission(){
    this.permissions = this.appService.getPermissions('/leave-balance');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  closePanel(val:any){
    this.showSidePanel = val
  }

  // Import
  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
  }

  // File Change
  onFileChange(evt: any) {
    this.excelData  = [];
    this.importData = []
    this.iscompleted = false
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){

      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];

        this.fileName   = target.files[0].name;
        this.fileSize   = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData  = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
        this.changeDetectorRef.detectChanges();

      };
      reader.readAsBinaryString(target.files[0]);
    }else{
      this.invalidFormat = true;
    }
  }

  // Processing Data
  processData(){
    if(this.excelData.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.excelData[0];
      let Heading = ['Employee Code',	'Employee Name',	'Leave type',	'Type of transaction',	'Number of days',	'Remarks']
      let success = Heading.every(function(val:any) {

        return headerRow.indexOf(val) !== -1;
      });
      this.invalid = headerRow.every((element:any) => Heading.includes(element));
      const hasDuplicates = new Set(headerRow).size !== headerRow.length;
      if(success == false || !this.invalid || hasDuplicates){
        this.deleteToggle = true;
        this.deleteMsg = this.messageServ.invalidExcelFormat();
        this.clearData();
      }
      else
       this.prepareUploadData()
    }
  }

  // Before Upload
  prepareUploadData(){
    let headerRow   = this.excelData[0];
    let header:any  = [];
    this.importTemp = [];
    this.importData = []

    for(let i=0;i<headerRow.length;i++){
      switch(headerRow[i]){
        case 'Employee Code':{
          header.push('employee_code');
          break;
        }
        case 'Employee Name':{
          header.push('employee_name');
          break;
        }
        case 'Leave type' : {
          header.push('leavetype_code');
          break;
        }
        case'Type of transaction' : {
          header.push('leavetype_transaction')
          break
        }
        case 'Number of days' : {
          header.push('count');
          break;
        }
        case 'Remarks' : {
          header.push('remarks');
          break;
        }
        default:{
          header.push(headerRow[i]);
          break;
        }
      }
    }
    this.excelData.forEach((item:any, key:any) => {
      var dict:any = {};
      for(let i=0;i<header.length;i++){
        dict[header[i]]= item[i];
      }
      this.importTemp.push({
        dict
      })
    })
    for(let j=0;j<this.importTemp.length;j++){
      this.importTemp[j]['dict']['leavetype_code'] = this.importTemp[j]['dict']['leavetype_code'] == undefined ? "" : this.importTemp[j]['dict']['leavetype_code']
      this.importTemp[j]['dict']['employee_name'] = this.importTemp[j]['dict']['employee_name'] == undefined ? "" : this.importTemp[j]['dict']['employee_name']
      this.importTemp[j]['dict']['employee_code'] = this.importTemp[j]['dict']['employee_code'] == undefined ? "" : this.importTemp[j]['dict']['employee_code']
      this.importTemp[j]['dict']['remarks'] = this.importTemp[j]['dict']['remarks'] == undefined ? "" : this.importTemp[j]['dict']['remarks']
      this.importData.push(this.importTemp[j]['dict'])
    }
  }

  // Upload
  uploadBalance(){
    this.isDataUpload     = 1;
    this.iscompleted = false
    this.isProgressStart  = true
    this.failedData       = []
    this.saveDataArray = []
    var l                 = this.importData.length;
    var cLoop             = Math.ceil(l/this.chunkLen)
    var p                 = 100/cLoop
    this.successDataCount = 0
    this.uploadasChunks(0,p);
  }

  uploadasChunks(k:any,p:any){
    var l           = this.importData.length;
    this.percentage = 0
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    this.saveDataArray = [];
    this.percentage +=(p/4);
    for(var i= 1; i<this.importData.length; i++){
      this.importData[i]['leavetype_transaction'] = (this.importData[i]['leavetype_transaction']=='Addition' || this.importData[i]['leavetype_transaction'] =='addition') ? true : (this.importData[i]['leavetype_transaction'] == 'deduction' || this.importData[i]['leavetype_transaction'] == 'Deduction') ? false : this.importData[i]['leavetype_transaction']
      this.saveDataArray.push(this.importData[i])
    }
    this.leaveService.uploadImportData({'data':this.saveDataArray}).subscribe((res:any)=>{
      this.body = x+' out of '+l+" data uploaded successfully"
      this.confirmMsg = false;
      if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
        res.body.failed_data.forEach((i:any, k:any) => {
              this.failedData.push(i)
        })
      }
    this.percentage +=((p/4)*3);
    this.iscompleted = true
    this.isProgressStart = false
    this.successDataCount = (this.importData.length-1) - this.failedData.length;
    if(this.failedData.length == 0){
      this.notificationService.handleSuccessNotification(this.saveDataArray.length+" leave data uploaded successfully.","Success");
      this.CompleteUpload();
    }else {
      this.exportData    = [];
      for (let i = 0; i < this.failedData.length; i++) {
        this.failedData[i]['data']['Employee Code'] = this.failedData[i]['data']['employee_code']
        this.failedData[i]['data']['Employee Name'] = this.failedData[i]['data']['employee_name']
        this.failedData[i]['data']['Leave type'] = this.failedData[i]['data']['leavetype_code']
        this.failedData[i]['data']['Type of transaction'] = this.failedData[i]['data']['leavetype_transaction'] == true ? 'Addition' : (this.failedData[i]['data']['leavetype_transaction'] == false ? 'Deduction' : this.failedData[i]['data']['leavetype_transaction'])
        this.failedData[i]['data']['Number of days'] = this.failedData[i]['data']['count']
        this.failedData[i]['data']['Remarks'] = this.failedData[i]['data']['remarks']
        this.failedData[i]['data']['Error description'] = this.failedData[i]['error']
        delete this.failedData[i]['data']['employee_code']
        delete this.failedData[i]['data']['employee_name']
        delete this.failedData[i]['data']['leavetype_code']
        delete this.failedData[i]['data']['leavetype_transaction']
        delete this.failedData[i]['data']['count']
        delete this.failedData[i]['data']['remarks']
        this.exportData.push(this.failedData[i].data)
      }
      this.successMsg = this.successDataCount+" out of "+(this.importData.length-1)+" data uploaded successfully "
      this.errorMsg = this.failedData.length+' employee(s) have error data'
      this.downloadMsg = true;
    }
  },
  (error:any)=>{
    this.isProgressStart = false
    this.notificationService.handleErrorNotification('Something went wrong','Error')
  }
  )
  }

  // After Uploading
  CompleteUpload(){
    this.fileName      = '';
    this.excelData          = [];
    this.fileSize      = '';
    this.exportData  = [];
    this.failedData = [];
    this.importTemp    = [];
    this.importData    = [];
  }

  closeSidePanel(){
    this.viewSidePanel = false
    this.child.clearBar()
  }
}


