import { Component, Input, OnInit ,Output,EventEmitter,ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyService } from 'src/app/@core/services/company.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { TimeAttendenceSettingsService } from 'src/app/@core/services/time-attendence-settings.service';


@Component({
  selector: 'app-tas-select-companies',
  templateUrl: './tas-select-companies.component.html',
  styleUrls: ['./tas-select-companies.component.scss']
})
export class TasSelectCompaniesComponent implements OnInit {
  counter               = 0;
  status_flag           = true;
  searchlistdata:any    = [];
  searchKeyword         = ""
  loader                = false;
  isEdit                = false;
  
  @Input()checkboxes: any[] = [];
  @Input() companies: any;
  @Input() formArray: any;
  @Input() configForm: any;
  @Input() editCompId: any;
  @Output() submitmethod   = new EventEmitter();
  @Output() activeCheckbox = new EventEmitter();
 
  constructor( private companyService: CompanyService,
    private route: ActivatedRoute,
    public appService: AppService,public TAS: TimeAttendenceSettingsService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.counter = 1;
        this.isEdit = true;
      }
	    else{
        this.getCompanyList();
      }
    })
  }

  isChecked(i:any,Checkvalue:any){
    let controlValue = this.formArray.controls;
    for(let j=0;j<controlValue.length;j++){
      if(controlValue[j].value==Checkvalue){
        for(let j=0;j<this.checkboxes.length;j++){
          if(this.checkboxes[j]['id']==Checkvalue)
            return this.checkboxes[j]['value'];
        }
      }
    }
    return
  }

  onCheckChange(event: any,i:any) {
    for(let j=0;j<this.checkboxes.length;j++){
      if(this.checkboxes[j].id==event.target.value)
      this.checkboxes[j].value = event.target.checked;
    }
    this.activeCheckbox.emit(this.checkboxes);
    if(event.target.checked){
      this.formArray.push(new FormControl(event.target.value));
    }
    else{
      let i: number = 0;
      this.formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          this.formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  submitMethod() {
    this.submitmethod.emit();
  }

  getCompanyList(){
    this.TAS.timeAttendenceCompList(this.status_flag,'','',0,this.searchKeyword).subscribe((res: any)=>{
    this.companies = res;
    for(let i=0; i< res.length; i++){
        res[i]['name'] = res[i]['company_name']
        res[i]['image'] = res[i]['logo']
    }
    this.searchlistdata = res;
    })
    this.checkCompany();
  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.companies = [];
      this.searchKeyword  = "";
      this.getCompanyList();
        this.loader = false;
    }
    else{
      this.companyService.companyGet(data).subscribe((res: any)=>{
        this.companies = [res.body];
        this.loader = false;
      });
      this.checkCompany();
     }
  }
  searchCompany(searchKeyword:any){
    this.loader     = true;
    this.companies  = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getCompanyList();
    }
  }

  checkCompany() {
    var elements = document.getElementsByName("check");
    this.counter  = 0;
    setTimeout(() => {
      for(let i=0;i<elements.length;i++){
        var element = <HTMLInputElement>elements[i];
        this.formArray.controls.forEach((ctrl: FormControl) => {
          if(element.value==ctrl.value){
           this.counter++;
           element.checked = true
          }

        })
      }
    }, 1000);
    this.loader = false;
  }
}
