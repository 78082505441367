<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">View map </h5>
    <button class="btn-reset text-light-600" (click)="closePanel()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar sidebar-collapse  p-0" [ngClass]="{'active': sidebarCollapse }" >
    <button class=" sidebar-collapse-btn link-primary1 btn-reset position-absolute end-0  p-16" (click)="sidebarCollapse = !sidebarCollapse">
      <i class="icon-chevron-right-circle fs-20" [ngClass]="{'icon-chevron-left-circle': sidebarCollapse }"></i>
    </button>

    <div class="text-uppercase fs-12 fw-500 px-24 py-16">
      LIVE TRACK DETAILS
    </div>

    <ul class="timeline-c3">
      <li class="timeline-li-1">
        <i class="icon-clock-fill fs-16"></i>
        <span class="text-nowrap">{{today+' '+data.shift_details.shift_start_time|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Shift start time</span>
      </li>


      <ng-container *ngFor="let dir of dirs">
        <!-- <li>{{dir.key}}</li> -->
        <li class="timeline-li-1" *ngIf="dir.isDirection && dir.originVal?.realtime_status == 'Check-in'">
          <i class="icon-arrow-right-circle-fill fs-16"></i>
          <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>
        <li class="timeline-li-1" *ngIf="dir.isDirection && dir.DestVal?.realtime_status == 'Check-in'">
          <i class="icon-arrow-right-circle-fill fs-16"></i>
          <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>
        <li class="timeline-li-1" *ngIf="!dir.isDirection && dir.markerVal?.realtime_status == 'Check-in'">
          <i class="icon-arrow-right-circle-fill fs-16"></i>
          <span class="text-nowrap">{{dir.markerVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>
        <!-- && (dir.originVal?.location != dir.DestVal?.location || dir.waysVal?.length>0) -->
        <li *ngIf="dir.isDirection " class="timeline-li-2 li-card "  [class.active]="dir.key == click" (click)="clicklist(dir.key)">
          <div class="card-c8 px-8 ">
            <div class="fs-12 hstack gap-12 py-8 fw-600 align-items-start">
              <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
              <span class="text-trim-2" title="{{dir.originVal?.location}}">{{dir.originVal?.location}}</span>
            </div>
            <div class="divider-c1">
              <span> <i class="icon-map-route "></i>
                {{( dir.distance | async)}}
              </span>
            </div>
            <div class="fs-12 hstack gap-12 py-8 fw-600 align-items-start">
              <span class="text-nowrap">{{dir.DestVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
              <span class="text-trim-2" title="{{dir.DestVal?.location}}">{{dir.DestVal?.location}}</span>
            </div>
          </div>
        </li>

        <li *ngIf="!dir.isDirection" class="timeline-li-2">
          <div class="card-c8 px-8  ">
            <div class=" hstack fw-600 align-items-stretch fs-10 gap-8 py-2">
              <i class="icon-stopwatch1-fill fs-16 my-auto"></i>
              <span class="text-trim-3" title="{{dir.markerVal?.location}}">{{dir.markerVal?.location}}</span>
              <span class="text-nowrap ms-auto">{{today+" "+dir.markerVal?.plugin_data.stationary_interval|date:this.appservice.gettimeformat()}}
              </span>
            </div>
          </div>
        </li>
        <li class="timeline-li-1" *ngIf="!dir.isDirection && dir.markerVal?.realtime_status == 'Check-out'">
          <i class="icon-arrow-left-circle-fill fs-16"></i>
          <span class="text-nowrap">{{dir.markerVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>

        <li class="timeline-li-1" *ngIf="dir.isDirection && dir.originVal?.realtime_status == 'Check-out'">
          <i class="icon-arrow-left-circle-fill fs-16"></i>
          <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>

        <li class="timeline-li-1" *ngIf="dir.isDirection && dir.DestVal?.realtime_status == 'Check-out'">
          <i class="icon-arrow-left-circle-fill fs-16"></i>
          <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>

    </ng-container>
      <li class="timeline-li-1">
        <i class="icon-clock-fill fs-16"></i>
        <span class="text-nowrap">{{today+' '+data.shift_details.shfit_endtime|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Shift end time</span>
      </li>
      <li class="timeline-li-1" *ngIf="data.tracking_datas[data.tracking_datas?.length-1]?.realtime_status == 'Check-out'">
        <i class="icon-arrow-left-circle-fill fs-16"></i>
        <span class="text-nowrap">{{data.tracking_datas[data.tracking_datas?.length-1].timestamp|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Check-Out</span>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <!-- <div [ngbNavOutlet]="nav"></div> -->
    <div class="container-fluid  p-0 form-container">
      <div class="row row-16">
        <!-- <div class="col-12 ">
                    <p class="text-uppercase fw-500 mb-0">LOCATION MAP</p>
                </div> -->
        <div class="col-12 mt-0">
          <div class="card card-c2 radius-8">
            <div #gmapContainer id="map">
              <agm-map [mapTypeControl]='true' [latitude]="centre.latitude" [styles]="styles" [longitude]="centre.longitude"
                [zoom]="zoom" style="height: calc(100vh - 4rem);" (mapReady)="mapReady($event)">
                <button id="Settings" class="toggle-button px-16 controls button mt-16 btn-map btn-breadcrumb"  (click)="clicklist(click,true)">
                 <i class=" me-8 fs-16 {{ways.length<=0 ? 'icon-square':'icon-check-square-fill text-accent1'}}"  ></i> Show Breadcrumbs
                </button>
                <button id="Zoom" class="mt-16 sq-40 radius-6 ms-16 btn-map btn-zoom flex-center fs-20" (click)="sidebarCollapse = !sidebarCollapse">
                 <i class="icon-min-fullscreen" [ngClass]="{'icon-fullscreen': sidebarCollapse }"></i>
                </button>
                <ng-container *ngFor="let m of ways; let i = index">
                  <agm-overlay [latitude]="m.lat" [longitude]="m.lng" (markerClick)="openWindow(i)"
                    [title]="'map-circle'">
                    <i class="icon-geo-alt-fill"></i>
                    <agm-info-window [isOpen]="isInfoWindowOpen(i)" *ngIf="isInfoWindowOpen(i)">
                      <div class=" hstack align-items-start gap-16 overflow-hidden">
                       {{this.appservice.dateFormatDisplay(m.val.timestamp)+" "+this.appservice.formatTimeDate(m.val.timestamp)}}
                      </div>
                    </agm-info-window>
                  </agm-overlay>
                </ng-container>
                <ng-container *ngFor="let dir of dirs">
                <agm-direction *ngIf="dir.isDirection"  [origin]="dir.origin" [destination]="dir.destination"
                  [renderOptions]="dir.renderOptions" [markerOptions]="dir.markerOptions" [waypoints]="dir.waypoints">
                </agm-direction>
              </ng-container>
              </agm-map>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</div>
<div class="modal  modal-alert {{infoToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">vvv</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="infoToggle = false;">Ok</button>
      </div>
    </div>
  </div>
</div>
