<div class="side-panel-body" style="overflow-y: auto;">
    <div class="container-fluid p-32 ">
        <div class="col-12">
            <h5>DETAILS</h5>
        </div>
        <div class="card card-c2 p-32">
            <div class="row  row-16">

                <div class="col-12 d-flex ">
                    <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
                </div>
                <div class="col-12 mt-8">
                    <h5 class="mb-8">Leave<span *ngIf="data?.is_cancel ==true">&nbsp;Cancellation</span>&nbsp;Request
                        Approval -
                        {{Leave?.leave_type_name}}</h5>
                    <span class=" badge-custom {{data?.request_type_color_code}}">Leave<span
                            *ngIf="data?.is_cancel ==true">&nbsp;Cancellation</span>&nbsp;Request</span>
                </div>
                <div class="row row-8">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                            <div class="row row-24">
                                <div class="col-12">
                                    <div class="d-flex">
                                        <span class="title">Leave Details</span>
                                        <span (click)="timeOffTrendFunction()"
                                            class="text-accent2 pointer ms-auto fs-12 fw-500"><i
                                                class="bi bi-graph-up me-1"></i>Time off Trend</span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card card-c5 month-active text-light-500">
                                        <div class="card-body bg8 flex-center flex-column sq-86 text-light-600">
                                            <div class="fw-500 fs-24 text-uppercase lh-1">{{Leave?.applied_days}} </div>
                                            <div class="fs-14">days</div>
                                        </div>
                                        <div class="card-body justify-content-center d-flex flex-column">
                                            <div class="fs-14 ">{{Leave?.leave_type_name}}</div>
                                            <div class="fs-14 fw-500" *ngIf="Leave?.from_date != Leave?.to_date">
                                                {{this.appservice.dateFormatDisplay(Leave?.from_date)}} -
                                                {{this.appservice.dateFormatDisplay(Leave?.to_date)}} <span
                                                    class="text-accent1 ms-8">({{Leave?.to_date_portion}})</span></div>
                                            <div class="fs-14 fw-500" *ngIf="Leave?.from_date == Leave?.to_date">
                                                {{this.appservice.dateFormatDisplay(Leave?.from_date)}}<span
                                                    class="text-accent1">({{Leave?.from_date_portion}})</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="detail-title">Full Day</div>
                                    <div class="detail-desc">{{(Leave?.full_day_dates?.length == 0 ||
                                      Leave?.full_day_dates == null || Leave?.full_day_dates == '') ?
                                      '-' : fullday(Leave?.full_day_dates)?.join(', ')}}</div>
                                  </div>
                                  <div class="col-12">
                                    <div class="detail-title">Half Day</div>
                                    <div class="detail-desc">{{(Leave?.half_day_dates?.length == 0 ||
                                      Leave?.half_day_dates == null || Leave?.half_day_dates == '')? '-'
                                      : halfday(Leave?.half_day_dates, Leave?.from_date_portion,
                                      Leave?.to_date_portion).join(', ')}}</div>
                                  </div>
                    
                                  <div *ngIf="Leave?.leave_type_name == 'Compensatory off'" class="col-12">
                                    <div class="detail-title">Comp off date selected to compensate with</div>
                                    <div class="detail-desc">{{compOffData?.join(', ')}}</div>
                                  </div>
                                <div class="col-12">
                                    <div class="detail-title">Reason</div>
                                    <div class="detail-desc">{{Leave?.reason}}</div>
                                </div>
                                <div class="col-12" *ngIf="data?.is_cancel == false">
                                    <div class="detail-title mb-8">Document</div>
                                    <div class="detail-desc" *ngIf="Leave?.document == ''">-</div>
                                    <div style="width:12.9375rem;" *ngIf="Leave?.document != ''">

                                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                                            <i
                                                class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                                            <div
                                                class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                                                <span class="tips text-uppercase fs- fw-700 text-trim"
                                                    *ngIf="Leave?.document != ''">{{
                                                    this.appservice.getDocName(Leave?.document) }}</span>
                                                <span class="tips-helper " *ngIf="Leave?.document != ''">{{
                                                    this.appservice.getDocName(Leave?.document) }}</span>
                                                <div class="d-flex gap-8">

                                                    <div class="link-primary" (click)="viewDocument(Leave?.document)">
                                                        <i class="bi bi-eye me-1"></i>View
                                                    </div>
                                                    <div class="link-primary"
                                                        (click)="this.appservice.downloadFile(Leave?.document,this.appservice.getDocName(Leave?.document))">
                                                        <i class="bi bi-download me-1"></i>Download
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="data?.is_cancel == true">
                                    <div class="detail-title">Reason for cancellation</div>
                                    <div class="detail-desc">{{data?.cancel_remark == null ? '-' :
                                        data?.cancel_remark}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-16" *ngIf="data?.is_cancel == false">
                        <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                            <div class="row row-24">
                                <div class="col-12 d-flex">
                                    <span class="title">BALANCE LEAVES</span>
                                    <span class="text-accent2 pointer fs-12 ms-auto" (click)="leaveBalanceFunction()"><i
                                            class="bi bi-eye me-1"></i>Details</span>
                                </div>
                                <div class="col-md-6">
                                    <div class="card card-c2 bg14 h-100 px-24 py-16">
                                        <div class="fs-24 fw-500 pb-8">{{Leave?.available_leave}} days</div>
                                        <div class="detail-desc fs-12">TOTAL AVAILABLE LEAVES AFTER BOOKING </div>
                                        <div class="detail-title fs-12">As of Today</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card card-c2 bg-warning h-100 px-24 py-16">
                                        <div class="fs-24 fw-500 pb-8">{{Leave?.total_available_leave}} days</div>
                                        <div class="detail-desc fs-12">ESTIMATED TOTAL LEAVE BALANCE </div>
                                        <div class="detail-title fs-12">As OF
                                            {{this.appservice.dateFormatDisplay(lastDate)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form *ngIf="reqStatus == 'Pending'" class="mt-20" [formGroup]="form">
                        <ng-container>
                            <div class="col-12">
                                <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
                                    class="form-control mb-8 textarea-comment" formControlName="comment"
                                    [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                                <div *ngIf="f.comment.errors" class="invalid-feedback">
                                    <div *ngIf="f.comment.errors?.maxlength">
                                        {{this.messageService.fieldlengthvalidation('word','500')}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex gap-16 mt-3">
                                <button type="button"
                                    class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                                    (click)="confirm('Rejected')">Decline</button>
                                <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                                    (click)="confirm('Approved')">Confirm</button>
                            </div>
                        </ng-container>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="side-panel" style="--sidepanel-width:56.25rem;" [class.side-pane-active]='leaveBalance === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Total available balance after booking</h5>
            <a class="toggle-panel" (click)="leaveBalance = false"><i class="icon-close-lg"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16">
                    <div class="d-flex mb-16 align-items-center">
                        <p class="text-uppercase fw-500 mb-0">LEAVE balance AFTER BOOKING</p>
                        <!-- <ng-select class="form-ngselect ms-auto" style="min-width: 18.75rem;" placeholder="As of Today">
              </ng-select> -->
                        <div class="input-group form-icon icon-right ms-auto" style="width: 18.75rem;">
                            <input (click)="datePicker3.open()" class="form-control" placeholder="As of Today" matInput
                                [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker3"
                                (keydown)="disableDate()" (dateChange)="dateChangeFunction($event)"><i
                                class="icon-calendar pointer" (click)="datePicker3.open()"></i>
                            <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="!loader"> -->
                    <!-- <div class="row row-16 mb-24"> -->
                    <ng-container *ngFor="let leavetype of leaveTypeBalanceDataTile,let i = index">
                        <div class="col-lg-3 col-md-4 col-sm-6 ng-star-inserted">
                            <div class="card card-c2 card-highlight highlight{{(i+2)%5}}">
                                <div class="d-flex px-16 py-12 align-items-center justify-content-between">
                                    <span class="fw-500 fs-12">{{leavetype?.leave_type}}</span>
                                    <span class="fw-600 fs-20">{{leavetype?.used}}/{{leavetype?.total}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- </div> -->
                    <div class="col-12">
                        <p class="text-uppercase fw-500 mb-0">LEAVE balance details</p>
                    </div>
                    <app-view-leave-balance [leaveBalanceTable]="leaveBalanceTable"></app-view-leave-balance>
                    <!-- </ng-container> -->
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>
<!-- Trend Graph Sidebar -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='timeOffTrend === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5> Time off trend</h5>
            <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16">
                    <div class="col-12 d-flex align-items-center">
                        <p class="fw-500 mb-0">Leave Type</p>
                        <ng-select class="form-ngselect ms-auto" placeholder="All leave types"
                            [(ngModel)]="selectedYear1" (change)="leaveTypeChange(selectedYear1)" name="selectedYear1">
                            <ng-option *ngFor="let item of leaveType" [value]="item?.id">{{ item?.leave_name
                                }}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 pb-24  px-24 pt-24">
                            <div class="row row-12">

                                <div class="col-12 d-flex">
                                    <div class="fs-24 me-auto">Time off trend - {{selectedYear}}</div>

                                    <ng-select class="form-ngselect-inline pointer" placeholder="Select year"
                                        [(ngModel)]="selectedYear" (change)="yearChange(selectedYear)"
                                        name="selectedYear">
                                        <ng-option *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-12" *ngIf="barchartLoader">

                                    <app-graph-loader></app-graph-loader>
                                  </div>
                                  <div *ngIf="!barchartLoader" class="col-12">
                                   <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
                                      [yText]="barChartYAxisLabel" [id]="1"></app-bar-chart>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
    [alertMsg]="investalertMsg"></app-alert-toggle>
<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
    [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>

    
      <!-- OT and Comp off Validation -->
      <div *ngIf="warningMsgToggle" class="col-12">
        <div role="alert" class="alert alert-warning show">
          <p class="mb-0 fs-12
        ">{{warningMsg}}</p>
          <div class="btn-close pointer" (click)="warningMsgToggle=false"></div>
        </div>
      </div>
      <!-- End -->