<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="attendanceRegister" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <div class="row row-16 mb-16">
                    <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.attendance_month.errors}">
                        <div class="col-12 form-row">
                            <p class="text-uppercase fw-500 mb-0 required">Attendance Month</p>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_month" type="radio" id="attendance_month1"
                                    class="form-check-input  " formControlName="attendance_month" value="last_month"
                                    (change)="radioBtnChangeFunctions($event,'attendance_month','last_month','specific_month','specific_year');setRequired(false)">
                                <label for="attendance_month1" class="form-check-label">Last month</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_month" type="radio" id="attendance_month2"
                                    class="form-check-input " formControlName="attendance_month" value="this_month"
                                    (change)="radioBtnChangeFunctions($event,'attendance_month','this_month','specific_month','specific_year');setRequired(false)">
                                <label for="attendance_month2" class="form-check-label">Current month</label>
                            </div>
                        </div>
                        <div class="col-12 form-row">
                            <div class="form-check">
                                <input name="attendance_month" type="radio" id="attendance_month3"
                                    class="form-check-input " formControlName="attendance_month" [value]="true"
                                    (change)="radioBtnChangeFunctions($event,'attendance_month',true,'specific_month','specific_year');setRequired(true)">
                                <label for="attendance_month3" class="form-check-label">Specific month</label>
                            </div>
                        </div>
                        <ng-container *ngIf="attendanceRegister.value.attendance_month==true">
                            <div class="col-6">
                                <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                                    <label for="" class="form-label">Month </label>
                                    <app-master-select class="config-app-master" style="width:9rem"
                                    [placeholder]="month_placeholder"
                                            id="month" [isrequired]="false" [selectedItem]="attendanceRegister.value.specific_month"
                                        [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                                            (ngModelChange)="attendancemonthChangeFn($event)" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                                </div>
                                <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                                    <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                    <label for="" class="form-label">Year </label>
                                    <ng-select class="form-ngselect  fs-14"
                                    formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                                        <ng-option *ngFor="let yData of Years"
                                            [value]="yData">{{yData}}</ng-option>
                                    </ng-select>
                                </div>
                                <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                                    <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="(submitted) && f.attendance_month.errors" class="invalid-feedback">
                        <div *ngIf="f.attendance_month.errors.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                    </div>
                </div>
                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
                (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage();submitattendanceRegister()">
                Apply
            </button>
        </footer>
    </form>
</div>



