<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">{{Action}} Pay Group</h5>
        <button class="btn-reset text-light-600" routerLink="/pay-group"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader || loaderPC"></app-fw-edit-loader>
    <ng-container  *ngIf="!loader && !loaderPC">
    <aside class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24" >
            <li [ngbNavItem]="1" >
                <a ngbNavLink class="step"  [class]="{'success':myForm['controls'].define.valid }"  >
                    <div class="count">1</div>
                    <div class="content">
                        <span>Step 1</span>
                        <h6>Define Pay Group</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <app-pg-define [formGp]="defineForm" [editflag]="editflag" [disabled]="disabled" (submitmethod)="submitOne()"></app-pg-define>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].mapcomponent.valid}"
            (submitmethod)="submitTwo()">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Map Pay Components</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-pg-map-pay [paycomponentlist]="paycomponentlist" [disabled]="disabled" [formGp]="mapcomponentForm"
              (submitmethod)="submitTwo()"></app-pg-map-pay>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="step"
            [class]="{'success':myForm['controls'].formula.valid && this.mapcomponentForm.value.paycomponents.length ==this.formulaForm.value.componentformula.length && !this.checkcreateFormulaValid()}">
            <div class="count">3</div>
            <div class="content">
              <span>Step 3</span>
              <h6>Create Formula</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-pg-create-formula [formGp]="formulaForm" [disabled]="disabled" [formGpSecond]="mapcomponentForm"
              (submitmethod)="submitThree()"></app-pg-create-formula>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].assignrights.valid }">
            <div class="count">4</div>
            <div class="content">
              <span>Step 4</span>
              <h6>Assign Rights</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-common-applicability [heading]="'Assign Rights'" [disabled]="disabled" [isEditForm]="editflag" [formGp]="assignrightsForm" (submitMethod)="submitForm()"></app-common-applicability>
          </ng-template>
        </li>
      </ul>

    </aside>
    <main class=" config-container">
        <div [ngbNavOutlet]="nav" ></div>
            <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
    </main>
  </ng-container>
</div>
