import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { EmployeeLetterHrService } from 'src/app/@core/services/employee-letter-hr.service';

@Component({
  selector: 'app-seperation-policy-settings',
  templateUrl: './seperation-policy-settings.component.html',
  styleUrls: ['./seperation-policy-settings.component.scss']
})
export class SeperationPolicySettingsComponent implements OnInit {
  policyDefineUnit:any =[];
  value:any;
  id:any
  templatelist:any=[];
  @Input()formGp : any;
  // @Input()formGp : FormGroup    = this.fb.group({
  //   name                        : ['',Validators.required],
  //   description                 : [''],
  //   probation_notice_period     : [''],
  //   confirm_notice_period       : [''],
  //   // backdated_resignation_allow : [''],
  //   // backdated_resignation_period: [''],
  // });
  validated : boolean = false;
  @Input() editflag:any
  @Output() submitmethod        = new EventEmitter();
  @Input()componentError:any;
  @Input()disabled              = false;
  private subjectKeyUp = new Subject<any>();
  @Input()submitted:boolean=false;

  constructor( private fb:FormBuilder,
    public messageService : MessageService,
    public route : ActivatedRoute,
    public existDataService :  ExistDatasService,
    private EmployeeLetterHrService : EmployeeLetterHrService,
    ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })
    this.getlettertemplatelist();
  }
    // Simplified function to use for validation
    existService(){
      if(!this.editflag){
        this.value ={
          "page": "SeperationPolicy",
          "field":"name",
          "value": this.f.name.value
        }
      }else{
         this.value ={
          "id":this.id,
          "page":"SeperationPolicy",
          "field":"name",
          "value": this.f.name.value
        }
      }

      this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
        this.policyDefineUnit = res.body

        if(this.policyDefineUnit.data ==='invalid'){
          this.f.name.setErrors({
            'exist' : true
          })
        }
      })
    }
  validatepolicysetting() {
    this.submitted = true;

    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }
  // For validation of keyup(name)

  keyUp(){

    this.subjectKeyUp.next(this.f.name.value)

  }
  // addConditionalValidation(){
  // this.addValidation("backdated_resignation_allow","backdated_resignation_period",digitdecimalValidator(),365);
  // }
  // addValidation(chk:any,inpt:any,pattern:any,maxLength:any){
  //   this.formGp.get(chk)?.valueChanges.subscribe((value: any) => {
  //     this.formGp.get(inpt)?.setValidators([Validators.required,pattern,Validators.maxLength(maxLength)])
  //   });
  // }

  setRequired(value: boolean,item:any,item2:any) {
    if(value) {
      this.formGp.get(item)?.setValidators([Validators.required,digitdecimalValidator(),Validators.max(365)])
      this.formGp.get(item2)?.setValidators([Validators.required])
    } else {
      this.formGp.get(item)?.clearValidators()
      this.formGp.get(item2)?.clearValidators()
      this.formGp.get(item)?.setValue(null);
      this.formGp.get(item2)?.setValue(null);
    }
      this.formGp.get(item)?.updateValueAndValidity();
      this.formGp.get(item2)?.updateValueAndValidity();
  }

  getlettertemplatelist(){
    this.EmployeeLetterHrService.lettertemplatelist('','Relieving Letter').subscribe((res: any) => {
      this.templatelist = res.body;
    });
  }

}
