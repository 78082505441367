<div class="side-panel-body" style="overflow-y: auto;">
  <div class="container-fluid p-32 ">
      <div class="col-12">
          <h5>DETAILS</h5>
      </div>
      <div class="card card-c2 p-32">
          <div class="row  row-16">

              <div class="col-12 d-flex ">
                  <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
              </div>
              <div class="col-12 mt-8">
                  <h5 class="mb-8">Check-in
                  </h5>
                  <span class=" badge-custom {{data?.request_type_color_code}}">Check-in</span>
              </div>
              <div class="col-12">
                  <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                      <div class="row row-24" *ngIf="data?.is_cancel==false">
                          <div class="col-12">
                              <div class="d-flex">
                                  <span class="title">Attendance punch details</span>
                              </div>
                          </div>
                          <div class="col-12">
                            <div class="card card-c2 p-0">
                              <app-table-loader *ngIf="!requestitem?.requests"></app-table-loader>
                              <div *ngIf="requestitem?.requests" class="table-responsive radius-4">
                                <table
                                  class="table table-striped table-borderless  td-12 sticky-header td-white-space td-fs-14 tr-fs-14 sticky-first-col sticky-last-col table-sm form-table">
                                  <thead>
                                    <tr>
                                      <th class="text-center">
                                        <input class="form-check-input checkbox-16 m-0" type="checkbox" (click)="checkall($event)"
                                          [checked]="checkAllSelected()" value="" />
                                      </th>
                                      <th class="fw-600" scope="col">Punch DATE</th>
                                      <th class="fw-600" scope="col">Punch time</th>
                                      <th class="fw-600" scope="col">LOcation</th>
                                      <th class="fw-600" scope="col">Status</th>
                                      <th class="fw-600" class="text-center" scope="col">
                                        View Map
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody class="cell-16 height-full">
                                    <tr
                                      *ngFor="let request of $any(requestitem?.requests| slice : (page - 1) * pageSize  : page * pageSize); let ind = index">
                                      <td class="text-center">
                                        <input class="form-check-input mt-1 checkbox-16" [(ngModel)]="request.checked"
                                          type="checkbox" [disabled]="
                                            request?.status == 'Cancelled' ||
                                            request?.status == 'Invalid'
                                          " />
                                      </td>
                                      <td data-toggle="tooltip" title="{{
                                          this.appService.dateFormatDisplay(
                                            request?.punch_date
                                          )
                                        }}">
                                        {{
                                        this.appService.dateFormatDisplay(
                                        request?.punch_date
                                        )
                                        }}
                                      </td>
                                      <td data-toggle="tooltip" title="{{
                                          this.appService.timeFormatDisplay(
                                            request?.punch_time
                                          )
                                        }}">
                                        {{
                                        this.appService.timeFormatDisplay(
                                        request?.punch_time
                                        )
                                        }}
                                      </td>

                                      <td class="text-trim w-15ch" data-toggle="tooltip" title="{{
                                        request?.location
                                      }}">
                                        {{ request?.location }}

                                      </td>

                                      <td data-toggle="tooltip" title="{{ request?.status }}">
                                        <div class="hstack gap-8 align-items-center">
                                          <span *ngIf="request?.status == 'Pending'"
                                            class="badge badge-warning fs-14 px-16 py-1">Pending</span>
                                          <span *ngIf="request?.status == 'Accepted'"
                                            class="badge badge-success fs-14 px-16 py-1">Accepted</span>
                                          <span *ngIf="request?.status == 'Rejected'"
                                            class="badge badge-danger fs-14 px-16 py-1">Rejected</span>
                                          <span *ngIf="request?.status == 'Cancelled'"
                                            class="badge badge-secondary fs-14 px-16 py-1">Cancelled</span>
                                        </div>
                                      </td>
                                      <td class="text-center" (click)="showMap( request,request?.status);viewDetail = true">
                                        <i class="icon-eye fs-18 link-primary"></i>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <ngb-pagination *ngIf="requestitem?.requests?.length > pageSize" class="d-flex justify-content-end mt-2"
                              [collectionSize]="requestitem?.requests?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
                              [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
                          </div>
                      </div>

                  </div>
              </div>
              <div *ngIf="reqStatus == 'Pending'" class="col-12" [formGroup]="form">
                  <textarea name="" id="" rows="3" placeholder="{{this.messageService.descriptiontext('comment')}}"
                      formControlName="comment" [ngClass]="{ 'is-invalid': f.comment.errors}"
                      class="form-control textarea-comment"></textarea>
                  <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
                      {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>
              <div class="col-12 d-flex gap-16 sticky-footer py-16" *ngIf="data?.is_cancel==false && reqStatus == 'Pending'">
                  <div class="d-flex gap-16">
                      <p class="mb-0 d-flex align-items-center gap-6">
                          <span class="fw-600">{{countSelected()}}</span>
                          <span class="fs-14">Requests selected</span>
                      </p>
                      <button class="btn btn-outline-danger btn-icon fs-14 radius-2" [disabled]="btndisable"
                          (click)="approveReject('Reject')"> <i class="icon-close-circle"></i>
                          Reject</button>
                      <button class="btn btn-outline-success btn-icon fs-14 radius-2" [disabled]="btndisable"
                          (click)="approveReject('Accepted')"> <i class="icon-check-circle"></i>
                          Approve</button>
                  </div>
                  <button class=" btn btn-primary text-uppercase ms-auto btn-sm fw-400"
                      (click)="confirm()">Submit</button>
              </div>
          </div>
      </div>
  </div>
</div>


<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Details</h5>
        <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
          <div class="row row-24 mb-24">
            <div class="col-12 d-flex">
              <p class="text-uppercase  fw-500 ">GEO LOCATION DETAILS</p>
              <a class="link-primary fs-14 ms-auto" (click)="  viewMapPanel = true "><i class="icon-eye me-1"></i> View
                Map</a>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">PUNCH DETAILS</p>
                    <span *ngIf="individualStatus == 'Pending'"
                    class="badge badge-warning fs-14 ms-auto py-1 px-3">Pending</span>
                    <span *ngIf="individualStatus == 'Accepted'"
                      class="badge badge-success fs-14 ms-auto py-1 px-3">Accepted</span>
                    <span *ngIf="individualStatus == 'Rejected'"
                      class="badge badge-danger fs-14 ms-auto py-1 px-3">Rejected</span>
                    <span *ngIf="individualStatus == 'Cancelled'"
                      class="badge badge-secondary fs-14 ms-auto py-1 px-3">Cancelled</span>
                  </div>
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Punch date</div>
                          <div class="detail-desc">{{ this.appService.dateFormatDisplay( request?.punch_date ) }}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Punch time</div>
                          <div class="detail-desc">{{ this.appService.timeFormatDisplay( request?.punch_time ) }} </div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Source</div>
                          <div class="detail-desc">{{request?.punch_type}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Location</div>
                          <div class="detail-desc">{{request?.location}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Co-ordinates</div>
                          <div class="detail-desc">{{request?.latitude}}, {{request?.longitude}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-request-location-view [request]="request" [profile]="this.requestitem?.from_person" [viewMapPanel]="viewMapPanel"
  (closeEmitter)="viewMapPanel=false"></app-request-location-view>
