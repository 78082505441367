<div class="">
  <div class="container-fluid p-32 form-container">
    <div class="row mb-24">
      <div class="col-md-4">
        <p class="text-uppercase fw-500 mb-0">{{status == 'PENDING' ? 'Pending expense reimbursements' : 'Expense reimbursements'}} </p>
      </div>
      <div class="col-md-8">
        <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
          <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" id="1" name="sh" (click)="status = 'PENDING';getExpenseReimburseData();getManualExpenseData();"
              [checked]='status=="PENDING"' />
            <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="1">PENDING</label>
            <input class="btn-check" name="sh" type="radio" id="2" (click)="status = 'REIMBURSED';getExpenseReimburseData();getManualExpenseData();"
              [checked]='status=="REIMBURSED"' />
            <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="2">REIMBURSED </label>
          </div>
        </div>
      </div>
    </div>
    <app-c1-loader *ngIf="expenseLoader || manualExpenseLoader"></app-c1-loader>
    <div *ngIf="!expenseLoader && !manualExpenseLoader" class="row row-24">
      <div *ngIf="manualExpenseData?.length > 0" class="col-12">
        <div role="alert" class="alert alert-warning py-10 mb-0 pe-32 {{alertShow}}">
          <p class="mb-0 fs-14 hstack ">Employee has {{manualExpenseData?.length}} reports which is Awaiting reimbursement. <span
              class="ms-auto link-primary1 fw-500 px-1" (click)="pendingExpense = true">Click here</span> to view the
            details</p>
          <div class="btn-close fs-9 pointer" (click)="alertShow='hide'"></div>
        </div>
      </div>
        <div class="col-12">
          <div class="card card-c2 overflow-hidden">
            <div class="table-responsive">
              <table class="table  td-12 th-12 table-striped table-sm form-table">
                <thead>
                  <tr>
                    <th scope="col" class="form-cell">
                      <input class="form-check-input m-0" type="checkbox" (click)="checkall($event)"
                      [checked]="checkAllSelected()" value="">
                    </th>
                    <th class="fw-600" scope="col">report name</th>
                    <th class="fw-600" scope="col">Amount to be paid</th>
                    <th class="fw-600" scope="col">STATUS</th>
                    <th class="fw-600" scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody class="cell-16">

                  <tr *ngIf="expenseReimburseData?.length == 0">
                    <td colspan="5">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                            <span class="fw-500 fs-14">No data found</span>
                        </div>
                    </td>
                  </tr>

                  <tr *ngFor="let expense of $any(expenseReimburseData | slice: (page1-1) * pageSize1 : page1 * pageSize1),let ind = index">
                    <td class="form-cell">
                      <input class="form-check-input m-0" [(ngModel)]="expense.checked" type="checkbox">
                    </td>
                    <td>{{expense?.name}}</td>
                    <td>{{expense?.to_reimbursed | currency : currency}}</td>
                    <td>
                      <span class="badge {{expense?.status == 'Pending for reimbursement via payroll' ? 'badge-warning' : 'badge-primary'}} py-1">{{expense?.status}}</span>
                    </td>
                    <td><span class="link-primary1 d-flex align-items-center" (click)="viewExpenseDetail(expense?.id, expense?.status);"> <i
                          class="icon-eye me-1"></i>View
                        Details</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12">
          <ngb-pagination *ngIf="expenseReimburseData?.length>pageSize1" class="d-flex justify-content-end"
            [collectionSize]="expenseReimburseData?.length" [(page)]="page1" [maxSize]="3" [rotate]="true"
            [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize1"></ngb-pagination>
        </div>

        <div class="col-12 d-flex">
          <button class="btn btn-primary btn-sm fw-400 text-uppercase ms-auto" [disabled]="countSelected()==0 || saveClicked" (click)="reimburseOrUndoAction()">{{status == 'PENDING' ? 'Reimburse' : 'Undo Reimbursement'}}</button>
        </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" (click)="submitExpense()">NEXT</button>
  </footer>
</div>

<!-- Manual Expense Details -->
<div class="side-panel side-pane-active" style="--sidepanel-width:56.25rem;"
  [class.side-pane-active]='pendingExpense === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="pendingExpense = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">Pending expense reimbursements for {{employee}} </p>
          </div>

          <div class="col-12">
            <div role="alert" class="alert alert-warning py-10 mb-0 pe-32 {{alertShow1}}">
              <p class="mb-0 fs-14 hstack ms-24">Expense reports in "Awaiting reimbursement" status can be reimbursed manually later from the Expense Management page, or you can change the status to "Pending for reimbursement via payroll" to process along with the Final settlement.</p>
              <div class="btn-close fs-9 pointer" (click)="alertShow1='hide'"></div>
            </div>
          </div>

          <div class="col-12">
            <div class="card card-c2">
              <div class="table-responsive radius-4">
                <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                  <thead>
                    <tr>
                      <th class="fw-600 text-uppercase" scope="col">report name</th>
                      <th class="fw-600 text-uppercase" scope="col">Amount to be paid</th>
                      <th class="fw-600 text-uppercase" scope="col">Status</th>
                      <th class="fw-600 text-uppercase" scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody class="cell-16 ">

                    <tr *ngIf="manualExpenseData?.length == 0">
                      <td colspan="5">
                          <div class="card card-c2 flex-center p-16 bg-dark-500">
                              <span class="fw-500 fs-14">No data found</span>
                          </div>
                      </td>
                  </tr>

                    <ng-container  *ngFor="let manual of $any(manualExpenseData | slice: (page2-1) * pageSize2 : page2 * pageSize2),let ind = index">
                      <tr>
                        <td scope="col" class="form-cell">{{manual?.name}}</td>
                        <td><span class="text-nowrap">{{manual?.to_reimbursed | currency : currency}} </span></td>
                        <td><span class="badge badge-warning py-1 px-3">Awaiting reimbursement</span></td>
                        <td><a (click)="viewExpenseDetail(manual?.id,'Awaiting reimbursement')" class="link-primary1">View Details</a></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12">
            <ngb-pagination *ngIf="manualExpenseData?.length>pageSize2" class="d-flex justify-content-end"
              [collectionSize]="manualExpenseData?.length" [(page)]="page2" [maxSize]="3" [rotate]="true"
              [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize2"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- View Details -->
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5> </h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">REPORT DETAILS</p>

          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">{{requestitem?.name}}</p>
                  <span class="badge {{viewStat == 'Reimbursed' ? 'badge-primary' : 'badge-warning'}} ms-auto py-1 px-3">{{viewStat}}</span>
                </div>
                <div class="col-12">
                  <app-fnf-view-expense-report class="row row-16 " [requestitem]="requestitem" [receipts]="requestitem?.receipts" [advances]="requestitem?.advances" [from]="'component'" [currency]="currency"></app-fnf-view-expense-report>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Error Download -->
<app-error-download [fileName]="'Expense Reimbursements Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Expense Reimbursements'" (closePanel)="downloadMsg = false" [successMsg]="''" [errorMsg]="errorMsg">  </app-error-download>
<!-- End -->
