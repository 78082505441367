<!-- <div class="card card-c2 p-24 mx-auto" style="max-width: 431.008px;">
    <div class="fs-24">Payroll checklist</div>
    <div class="fs-12 mb-16 fw-500 text-light-400">The Payroll Checklist enables you to verify that the
        steps
        listed below are completed before processing the month's payroll.</div>
    <div class="d-flex flex-column bg29 p-12 radius-4 mb-16">
        <div class="d-flex w-100 fs-12 mb-8">
            <span>Checklist progress</span>
            <span *ngIf="config.prior_payroll_status" class="ms-auto fw-500">{{progress/33}}/3</span>
            <span *ngIf="config.prior_payroll_status==false" class="ms-auto fw-500">{{progress/50}}/2</span>
        </div>
        <div class="mb-0">
            <ngb-progressbar class="progress-type3" type="success" [value]="progress"></ngb-progressbar>
        </div>
    </div>
    <ul class="custom-checklist">
        <li [class]="{'success':config.is_payroll_configured==false }" routerLink="/configuration">Update
            Payroll configuration</li>
        <li [class]="{'success':config.is_statutory_configured==false }" routerLink="/statutory">Configure
            statutory for company</li>
        <li *ngIf="config.prior_payroll_status" [class]="{'success':config.complete_priorpayroll==true }"
            (click)="movePrior()">Add prior payroll</li>
    </ul>
</div> -->
<div class="card card-c2 mx-auto" style="width: 936px;">
    <div class="card-body bg-accent1 bg-payroll-checklist p-24 text-white">
        <div class="fs-32 ">Payroll checklist</div>
        <p class="fs-12 mb-0 fw-500 ">A payroll checklist is a set of steps that must be completed before processing the
            payroll.</p>
    </div>
    <div class="card-body p-24">
        <div class="row row-24">
            <div class="col-12">
                <div class="card card-c2 p-24 flex-row align-items-center gap-16" routerLink="/configuration"
                    [ngClass]="{'card-hover':config.is_payroll_configured==true, 'pointer-event':config.is_payroll_configured==false}">
                    <div [ngClass]="{'avatar-success':config.is_payroll_configured==false }"
                        class="avatar-circle cbg-accent2 flex-center fs-20  sq-56">
                        <i class="icon-cash-fill  fs-26"></i>
                    </div>
                    <div class="d-flex gap-3 flex-column">
                        <p class="fw-600 mb-0">Update Payroll Configuration</p>
                        <p class="fs-12 fw-500 mb-0 text-light-400">Define your company's payroll settings to automate
                            your payroll process.</p>
                    </div>
                    <i class="icon-chevron-right-lg ms-auto fs-18"></i>

                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2  p-24 flex-row align-items-center gap-16"
                    [ngClass]="{'card-hover':config.is_statutory_configured==true }" routerLink="/statutory">
                    <div [ngClass]="{'avatar-success':config.is_statutory_configured==false }"
                        class="avatar-circle cbg-accent1 flex-center fs-20  sq-56 ">
                        <i class="icon-buildings-fill  fs-26"></i>
                    </div>
                    <div class="d-flex gap-3 flex-column">
                        <p class="fw-600 mb-0">Configure Statutory for Company</p>
                        <p class="fs-12 fw-500 mb-0 text-light-400">Lock your company's statutory settings for accurate
                            calculation of tax exemptions and other benefits.</p>
                    </div>
                    <i class="icon-chevron-right-lg ms-auto fs-18"></i>

                </div>
            </div>
            <div class="col-12">
                <div class="card card-c2 p-24 flex-row align-items-center gap-16" *ngIf="config.prior_payroll_status"
                    [ngClass]="{'card-hover ':config.complete_priorpayroll==false }" (click)="movePrior()">
                    <div [ngClass]="{'avatar-success':config.complete_priorpayroll==true }"
                        class="avatar-circle cbg-warning flex-center fs-20  sq-56">
                        <i class="icon-card-add-fill  fs-26"></i>
                    </div>
                    <div class="d-flex gap-3 flex-column">
                        <p class="fw-600 mb-0">Add Prior Payroll</p>
                        <p class="fs-12 fw-500 mb-0 text-light-400">Upload your prior payroll details if you are using
                            SkizzleHR in the
                            midst of the financial year for accurate tax calculation.</p>
                    </div>
                    <i class="icon-chevron-right-lg ms-auto fs-18"></i>

                </div>
            </div>
        </div>
    </div>
</div>