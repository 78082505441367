import * as moment from "moment"
import { LeaveYearEndService } from "src/app/@core/services/leave-year-end.service"
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { AppService } from "src/app/app.global";
import { Injectable } from "@angular/core";

@Injectable()

export class ReportDownload {

  exportAPIData : any = []
  fileType            = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension       = '.xlsx';

  constructor(
    private leaveServ : LeaveYearEndService,
    private appService : AppService,
  ){}

  // Year End Report
  downLoadReport(policId:any,year:any){
    let exportDataDownload : any = []
    this.leaveServ.exportData('',JSON.stringify(policId),'Pending',year).subscribe((res:any)=>{
      this.exportAPIData = res?.body?.data
      if(this.exportAPIData.length != 0){
        for(let i=0;i<this.exportAPIData.length;i++){
          exportDataDownload.push({
            'Sl No.' : i+1,
            'Employee Code'  : this.exportAPIData[i]['employee_code'],
            'Employee Name'  : this.exportAPIData[i]['employee_name'],
            'Company' : this.exportAPIData[i]['company_name'],
            'Department' : this.exportAPIData[i]['department_name'],
            'Leave Policy' : this.exportAPIData[i]['leave_policy_name'],
            'Leave Code' : this.exportAPIData[i]['leave_code'],
            'Reason' : 'There are '+this.exportAPIData[i]['pending_leave_request_count']+' pending leave requests'
          })
        }
        if(exportDataDownload.length !=0)
          this.exportExcel(exportDataDownload,'Leave Year End Pending Employee')
      }
    })
  }


  exportExcel(data: any[], fileName: string): void {
    // After API setup check the year end and process date
    let process_year = this.exportAPIData[0]?.leave_cycle_year
    const headers = ['Sl No.', 'Employee Code', 'Employee Name', 'Company', 'Department','Leave Policy','Leave Code', 'Reason'];
    const ws: XLSX.WorkSheet = XLSX.utils.sheet_add_json(
      XLSX.utils.aoa_to_sheet([headers]),
      data,
      { skipHeader: true, origin: 'A5' }
    );

    // Add heading row
    for (let i = 0; i < headers.length; i++) {
      ws[XLSX.utils.encode_cell({ r: 3, c: i })] = { t: 's', v: headers[i] };
    }

    // Set row height for rows 1, 2, and 3
    ws['!rows'] = [    { hpt: 20, hpx: 20 }, // row 1
    { hpt: 20, hpx: 20 }, // row 2
    { hpt: 20, hpx: 20 }, // row 3
  ];

    ws['A1'] = { t: 's', v: 'Leave Year End Pending Employees Report', s: { font: { bold: true }, alignment: { horizontal: 'center' } } };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: headers.length - 1 } }];

    ws['A2'] = { t: 's', v: 'Leave Cycle', s: { font: { bold: true }, alignment: { horizontal: 'center' } } };
    ws['A3'] = { t: 's', v: 'Leave Year End Processed On', s: { font: { bold: true }, alignment: { horizontal: 'center' } } };

    ws['C2'] = { t: 's', v: process_year, s: { font: { bold: true }, alignment: { horizontal: 'center' } } };
    ws['C3'] = { t: 's', v: this.appService.dateFormatDisplay(this.exportAPIData[0]?.leave_year_end_processed_on), s: { font: { bold: true }, alignment: { horizontal: 'center' } } };

    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 1 } });
    ws['!merges'].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 1 } });

    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Save Excel file
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}
