<!-- <div class="container-fluid px-32 py-24"> -->
  <div class="row row-16">
    <div class="col-12 d-flex position-relative">
      <span class="text-light-500 fs-12 text-nowrap">{{ requestitem?.created_display }}

        <i class="icon-message-system text-warning fs-20 ms-8"></i>
      </span>
      <button *ngIf="from!='behalf' "class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star'] == true"
        (click)="
          performAction(2, 0, requestitem['id'], '', '', requestitem['star'])
        ">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8">Check-in</h5>
        <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
          <div class="d-flex ms-auto align-items-center gap-8">
            <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
              *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
            <span class="badge px-16 ms-auto fs-14 badge-success py-1"
              *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
            <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
              *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
            <span class="text-light-400"
              *ngIf="requestitem.approved_status == 'Pending' || requestitem.approved_status == 'Approved' || requestitem.approved_status == 'Rejected'">|</span>
            <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
          </div>
        </ng-container>
        <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
          class="ribbon ribbon-right ribbon-danger  ribbon-sm" style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
      </div>
      <span class=" badge-custom {{ requestitem.request_type_color_code }}">Check-in</span>
      <span *ngIf="
          requestitem.is_mail_overdue &&
          !requestitem?.achieved_status &&
          requestitem?.request_data?.status != 'Invalid'
        " class="ribbon ribbon-right ribbon-danger" style="right: 0.5rem; bottom: 0">Overdue</span>
    </div>
    <ng-container *ngIf="from!='behalf'">

      <app-progressbar [requestitem]="requestitem"></app-progressbar>
      <div class="col-12">
        <p class="mb-16 fs-14" [innerHTML]="requestitem?.message"></p>
      </div>
    </ng-container>
    <div class="col-12" *ngIf="
        !requestitem?.request_data?.is_cancel && !requestitem?.achieved_status
      ">
      <div role="alert" class="alert alert-warning mb-0 {{ alertShow }}">
        <p class="mb-0 fs-14">
          You can select multiple requests by clicking on the checkbox. Once the
          status is updated it cannot be reversed.
        </p>
        <div class="btn-close pointer" (click)="alertShow = 'hide'"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="row row-16">
        <div class="col-12">
          <div class="card details-card shadow-0 px-24 pt-16 pb-24">
            <div class="row row-24">
              <div class="col-12">
                <div class="d-flex">
                  <span class="title">Attendance punch details</span>
                </div>
              </div>
              <div class="col-12">
                <div class="card card-c2 p-0">
                  <app-table-loader *ngIf="!requestitem?.requests"></app-table-loader>
                  <div *ngIf="requestitem?.requests" class="table-responsive radius-4">
                    <table
                      class="table table-striped table-borderless  td-12 sticky-header td-white-space td-fs-14 tr-fs-14 sticky-first-col sticky-last-col table-sm form-table">
                      <thead>
                        <tr>
                          <th class="text-center">
                            <input class="form-check-input checkbox-16 m-0" type="checkbox" (click)="checkall($event)"
                              [checked]="checkAllSelected()" value="" />
                          </th>
                          <th class="fw-600" scope="col">Punch DATE</th>
                          <th class="fw-600" scope="col">Punch time</th>
                          <!-- <th class="fw-600" scope="col">Source</th> -->
                          <th class="fw-600" scope="col">LOcation</th>
                          <!-- <th class="fw-600" scope="col">COORDINATES</th> -->
                          <th class="fw-600" scope="col">Status</th>
                          <th class="fw-600" class="text-center" scope="col">
                            View Map
                          </th>
                        </tr>
                      </thead>
                      <tbody class="cell-16 height-full">
                        <tr
                          *ngFor="let request of $any(requestitem?.requests| slice : (page - 1) * pageSize  : page * pageSize); let ind = index">
                          <td class="text-center">
                            <input class="form-check-input mt-1 checkbox-16" [(ngModel)]="request.checked"
                              type="checkbox" [disabled]="
                                request?.status == 'Cancelled' ||
                                request?.status == 'Invalid'
                              " />
                          </td>
                          <td data-toggle="tooltip" title="{{
                              this.appService.dateFormatDisplay(
                                request?.checkin_request_date?.punch_date
                              )
                            }}">
                            {{
                            this.appService.dateFormatDisplay(
                            request?.checkin_request_date?.punch_date
                            )
                            }}
                          </td>
                          <td data-toggle="tooltip" title="{{
                              this.appService.timeFormatDisplay(
                                request?.checkin_request_date?.punch_time
                              )
                            }}">
                            {{
                            this.appService.timeFormatDisplay(
                            request?.checkin_request_date?.punch_time
                            )
                            }}
                          </td>

                          <td class="text-trim w-15ch" data-toggle="tooltip" title="{{
                            request?.checkin_request_date?.location
                          }}">
                            {{ request?.checkin_request_date?.location }}

                          </td>

                          <td data-toggle="tooltip" title="{{ request?.status }}">
                            <div class="hstack gap-8 align-items-center">
                              <span *ngIf="request?.status == 'Pending'"
                                class="badge badge-warning fs-14 px-16 py-1">Pending</span>
                              <span *ngIf="request?.status == 'Approved'"
                                class="badge badge-success fs-14 px-16 py-1">Accepted</span>
                              <span *ngIf="request?.status == 'Rejected'"
                                class="badge badge-danger fs-14 px-16 py-1">Rejected</span>
                              <span *ngIf="request?.status == 'Cancelled'"
                                class="badge badge-secondary fs-14 px-16 py-1">Cancelled</span>
                            </div>
                          </td>
                          <td class="text-center" (click)="showMap( request?.checkin_request_date,request?.status);viewDetail = true">
                            <i class="icon-eye fs-18 link-primary"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <ngb-pagination *ngIf="requestitem?.requests?.length > pageSize" class="d-flex justify-content-end mt-2"
                  [collectionSize]="requestitem?.requests?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
                  [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!requestitem?.achieved_status" class="col-12" [formGroup]="form">
          <textarea name="" id="" rows="3" placeholder="{{ this.messageService.descriptiontext('comment') }}"
            formControlName="comment" [ngClass]="{ 'is-invalid': f.comment.errors }"
            class="form-control textarea-comment"></textarea>
          <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
            {{ this.messageService.fieldlengthvalidation("word", "500") }}
          </div>
        </div>
        <app-request-submit class="sticky-footer" [form]="form" *ngIf="
            !requestitem?.request_data?.is_cancel &&
            !requestitem?.achieved_status
          " [requestitem]="requestitem" (successEmitter)="refreshInbox($event)" [from]="from"></app-request-submit>
      </div>
    </div>
  </div>
  <div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Details</h5>
        <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
          <div class="row row-24 mb-24">
            <div class="col-12 d-flex">
              <p class="text-uppercase  fw-500 ">GEO LOCATION DETAILS</p>
              <a class="link-primary fs-14 ms-auto" (click)="  viewMapPanel = true "><i class="icon-eye me-1"></i> View
                Map</a>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">PUNCH DETAILS</p>

                    <span *ngIf="individualStatus == 'Pending'"
                      class="badge badge-warning fs-14 ms-auto py-1 px-3">Pending</span>
                    <span *ngIf="individualStatus == 'Approved'"
                      class="badge badge-success fs-14 ms-auto py-1 px-3">Accepted</span>
                    <span *ngIf="individualStatus == 'Rejected'"
                      class="badge badge-danger fs-14 ms-auto py-1 px-3">Rejected</span>
                    <span *ngIf="individualStatus == 'Cancelled'"
                      class="badge badge-secondary fs-14 ms-auto py-1 px-3">Cancelled</span>
                  </div>
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Punch date</div>
                          <div class="detail-desc">{{ this.appService.dateFormatDisplay( request?.punch_date ) }}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Punch time</div>
                          <div class="detail-desc">{{ this.appService.timeFormatDisplay( request?.punch_time ) }} </div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Source</div>
                          <div class="detail-desc">{{request?.punch_type}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Location</div>
                          <div class="detail-desc">{{request?.location}}</div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Co-ordinates</div>
                          <div class="detail-desc">{{request?.latitude}}, {{request?.longitude}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-15" *ngIf="request?.selfie_image != null && request?.selfie_image != ''">
              <div class="card card-c2 p-16 details-card">
                <div class="row row-16">
                  <div class="col-12">
                    <span class="title">
                      Check in CAPTURE
                    </span>
                  </div>
                  <div class="col flex-grow-0">
                    <div class="detail-title">Photo</div>
                    <div class="detail-desc"> <a class="ms-1"  (click)="viewProfileModal = true;viewProfileImage=request.selfie_image"><img class="sq-80 radius-8 object-fit-contain" [src]="request.selfie_image"></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header><app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [sendReminder]="sendReminder" [requestId]="requestitem.request_data['id']" (closePanel)="viewstatusPanel=false"></app-view-status>
    </form>
  </div>
</ng-container>
<app-request-location-view [request]="request" [profile]="this.requestitem?.from_person" [viewMapPanel]="viewMapPanel"
  (closeEmitter)="viewMapPanel=false"></app-request-location-view>
<ng-container *ngIf="viewProfileModal">
  <div class="modal {{viewProfileModal ? 'show':''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width:30.875rem">
      <div class="modal-content radius-24">
        <div class="modal-header px-24 ">
          <h5 class="modal-title ">Attendance image</h5>
          <button type="button" (click)="viewProfileModal = false" class="btn-close shadow-0" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body pb-32 px-24 mx-auto">
          <div class="row row-16">
            <div class="col-12">
              <img [src]="viewProfileImage" class="img-fluid radius-24 ">
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
