import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-financial-year',
  template: `
     <!-- <div class=" d-inline-flex btn btn-outline-primary" (click)="financialYear.open()"> -->
         <!-- <span class="text-uppercase text-nowrap  fs-12 fw-600 ">FINANCIAL YEAR : </span> -->
        <ng-select #financialYear class="form-ngselect form-ngselect2 ngselect-outline-primary" appendTo="ng-select"  placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
        [(ngModel)]="placeholderData" [closeOnSelect]="true" style="width:13.5rem" (change)="yearChange($event)">
            <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year"> {{year}} </ng-option>
        </ng-select>
    <!-- </div> -->
  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinancialYearComponent),
      multi: true
    }
  ]
})
export class FinancialYearComponent implements  ControlValueAccessor {
    yearList : any = []
    @Output() selectYear = new EventEmitter();
    @ViewChild('ngSelecter') ngselect: any;
    private _selectedYear: any;
    data : any
    currentYear =  new Date().getFullYear();
    placeholderData : any;
  	yearRange       :any;
  	yearOfCurrent   : any

    constructor(public messageService: MessageService,
    private appService:AppService
    ) { }

    ngOnInit(): void {
      var i:number;
      this.yearList = this.appService.getFinancialYearDropdown();
      this.yearOfCurrent = Number(this.appService.getFinancialYear());
      this.yearRange = this.appService.getFinancialYearRange(this.yearOfCurrent)
      this.placeholderData = "FINANCIAL YEAR "+ this.yearRange

    }

    get selectedYear(): any {
        return this._selectedYear;
    }
    set selectedYear(val) {
        this._selectedYear = val;
        this.propagateChange(this._selectedYear);
    }
    propagateChange = (_: any) => { };
    propagateTouche = (_: any) => { };

    registerOnChange(fn: any): void {
      this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
      this.propagateTouche = fn;
    }
    change() {
      this.propagateTouche(this._selectedYear);
    }
    writeValue(obj: any): void {
        if (obj !== undefined) {
            this._selectedYear = this.appService.getFinancialYearRange(obj);
        }
    }
    yearChange(value:any){
      setTimeout(() => {
        this.placeholderData = ''
        this.data = value.toString().substr(0,4)
        this.selectYear.emit(this.data);
        this.placeholderData = "FINANCIAL YEAR "+value
      },10);
    }

}
