import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-view-breakup-details',
  templateUrl: './view-breakup-details.component.html',
  styleUrls: ['./view-breakup-details.component.scss']
})
export class ViewBreakupDetailsComponent implements OnInit {

  @Input() viewBreakUpDetails: any;
  @Input() componentLoader: any;
  // @Input() total: any;

  fileName: any =  "https://skizzlehrdevbkdstrage.blob.core.windows.net/gamil/6202f4a1-2c19-4802-82c8-67796985e2df_Generate%20%283%29.pdf";
  modalToggle                = false;
  pdfUrl:any                 = '';
  loader1                    = false
  currentDocName             = 'Payslip'
  currentExtension           = 'pdf'
  allowedExtension           = "";
  alertToggle                = false;
  empalertMsg:any            = '';
  currency:any;
  earningData:any;

  constructor(
    public appService:AppService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(){
    setTimeout(() => {
      if(this.viewBreakUpDetails != undefined){
        this.currency = this.viewBreakUpDetails.total_earnings[0].currency;

        // this.total    = this.viewBreakUpDetails.total_earnings[0]?.total_earnings + this.viewBreakUpDetails.total_earnings[0].expense_reimbursement - this.viewBreakUpDetails.total_earnings[0].recurring_deductions ;

      }
    }, 1000);

    // this.earningData = [ ...this.viewBreakUpDetails?.gross_benefits, ...this.viewBreakUpDetails?.other_benefits];
    this.earningData = (this.viewBreakUpDetails?.gross_benefits && this.viewBreakUpDetails?.other_benefits) ? [ ...this.viewBreakUpDetails?.gross_benefits, ...this.viewBreakUpDetails?.other_benefits] : []
  }


  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
   }

  closeInfo(bool:any){
    this.modalToggle = bool;
  }
  viewDocument(url:any){

    if(url != null){
      this.modalToggle = true;
      this.currentDocName = this.appService.getPayTaxName(url,'Payslip')
      this.pdfUrl = url+"?"+Date.now()
    }

  }

  downLoad(){
    let url = this.viewBreakUpDetails?.total_earnings[0].payslip_url+"?"+Date.now()
    let doc_name = this.appService.getPayTaxName(this.viewBreakUpDetails?.total_earnings[0].payslip_url,'Payslip')
    this.appService.downloadFile(url,doc_name)
  }


}
