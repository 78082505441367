<div class="search-wrap">
    <div class="search-header">
        <div class="header-title">
            <h5 class="mb-0">Search</h5>
            <button class="btn-reset text-light-600" (click)="goBack()"><i class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="search-box">
            <div class="d-flex align-items-center gap-24">
                <div class=" search-form">
                    <i class="icon-search"></i>
                    <input class="  " placeholder="Search...   " [(ngModel)]="searchString" />
                </div>
                <button class="btn btn-primary search-btn"  (click)="searchData()"> Search</button>
            </div>
            <div class="d-flex mt-16 gap-16">
                <div class="text-nowrap">Recent search :</div>
                <div class="d-flex flex-wrap gap-8">
                    <div class="badge pointer">Inbox</div>
                    <div class="badge pointer">Employee profile</div>
                    <div class="badge pointer">Requests</div>
                    <div class="badge pointer">Requests</div>
                    <div class="badge pointer">Employee profile</div>
                    <div class="badge pointer">Inbox</div>
                    <div class="badge pointer">Employee profile</div>
                    <div class="badge pointer">Requests</div>
                    <div class="badge pointer">Requests</div>
                    <div class="badge pointer">Employee profile</div>

                </div>
            </div>

        </div>
    </div>
    <div class="search-main">
        <div class="search-category">
             <div class="cat-header">
                 <div class="fs-14 text-uppercase position-absolute">Category</div>
                <div class="float-end ms-auto">
                    <!-- <form id="search" class="form-expandable"> -->
                        <!-- [(ngModel)]="searchString" -->
                    <input class="exp-input filled" #search type="search"
                    [placeholder]="filled?'Search':''"
                    [ngClass]="{'filled':filled}"
                    (click)="filled=!filled"
                    (blur)="filled=search.value?true:false"
                    [(ngModel)]="search.value"
                    >
                            <button class="close" *ngIf="filled" (click)="search.value='';filled=false">x</button>
                    <!-- </form> -->
                </div>
             </div>
             <div class="cat-content ">
                <ng-container *ngFor = "let cats of categories | keyvalue">
                    <div class="cat-title">{{cats.key}}</div>
                    <div (click)="setCategory(cat)" *ngFor = "let cat of getCatArray(cats.key)|filter:search.value" class="cat-item"  [class]="{'active' :searchCategory.key == cat.key}">{{cat.key}}</div>
                </ng-container>
             </div>
        </div>
        <div class="search-result">
            <div class="search-details" *ngIf="results.length>0">
                <div >
                    <p class="mb-0">{{searchCategory.key}} profiles</p>
                    <span class="fs-12 text-light-500 ">Showing {{results.length}} results for ‘{{searchCategory.key}}’</span>
                </div>
                <button class=" btn-square btn btn-secondary ms-auto"   (click)="showSidePanel = true">
                    <i class="icon-filter-left fs-13"></i>
                </button>


            </div>
            <div class="container-fluid px-32 py-24">

                <div *ngIf="showAlert"  role="alert" class="alert alert-info show"><p class="mb-0 fs-14"> Start typing a category OR select a category from the CATEGORIES list to start the search  </p>
                    <div class="btn-close"></div>
                </div>
                <div class="row row-24">


                    <div  *ngFor="let i of results" class="col-lg-3 col-sm-4">
                        <div class="card card-c2 card-hover indicater2" (click)="goToView(i.id)">
                            <div class="card-body d-flex align-items-center">
                                <img src="{{i.image}}" class="avatar-circle sq-40 "  alt="">

                                <div class="overflow-hidden ms-16 d-flex flex-column">
                                    <span class="fs-14 fw-500 pb-1 text-trim">{{i.name}}</span>
                                    <span class="fs-10 fw-500 text-light-400 text-trim text-uppercase mb-0">{{i.text}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-3 col-sm-4" *ngFor="let i of [1,1,1,1,1,1,1,1]">
                        <div class="card card-c2 card-hover indicater2">
                            <div class="card-body d-flex align-items-center">
                                <img src="assets\images\uploads\user-img\user2.jpg" class="avatar-circle sq-40 " alt="">

                                <div class="overflow-hidden ms-16 d-flex flex-column">
                                    <span class="fs-14 fw-500 pb-1 text-trim">Bessie Cooper</span>
                                    <span class="fs-10 fw-500 text-light-400 text-trim text-uppercase mb-0">Marketing Coordinator </span>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-3 col-sm-4">
                        <div class="card card-c2 card-hover indicater2">
                            <div class="card-body d-flex align-items-center">

                                <img src="assets\images\uploads\user-img\user3.jpg" class="avatar-circle sq-40 " alt="">

                                <div class="overflow-hidden ms-16 d-flex flex-column">
                                    <span class="fs-14 fw-500 pb-1 text-trim">Jacob Jones</span>
                                    <span class="fs-10 fw-500 text-light-400 text-trim text-uppercase mb-0">Nursing Assistant</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-4">
                        <div class="card card-c2 card-hover indicater2">
                            <div class="card-body d-flex align-items-center">

                                <img src="assets\images\uploads\user-img\user5.jpg" class="avatar-circle sq-40 " alt="">

                                <div class="overflow-hidden ms-16 d-flex flex-column">
                                    <span class="fs-14 fw-500 pb-1 text-trim">Bessie Cooper</span>
                                    <span class="fs-10 fw-500 text-light-400 text-trim text-uppercase mb-0">Marketing Coordinator </span>
                                </div>
                            </div>
                        </div>
                    </div>  -->

                </div>
                <div *ngIf="!showAlert && results.length ==0" class="flex-center flex-column mt-5 h-100" style="min-height: calc(100vh - 19.375rem);">
                    <img src="assets\images\bg\search-result.png" alt="">
                    <p>No results to show</p>
                    <p class="fs-12 text-light-500">Select a category to search</p>
                </div>
            </div>



        </div>
    </div>
</div>
<div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='showSidePanel === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <div class="row row-16 ">
                    <div class="col-md-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Department</p>
                    </div>
                    <div class="col-md-12 form-row">
                        <ng-select class="form-ngselect"
                            placeholder="Sales department" ></ng-select>
                    </div>
                    <div class="col-md-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Gender</p>
                    </div>
                    <div class="col-lg-12 form-row ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox"  [value]=true checked>
                            <label class="form-check-label"> Male </label>
                        </div>
                    </div>
                    <div class="col-lg-12 form-row ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" [value]=false>
                            <label class="form-check-label" for="">Female</label>
                        </div>
                    </div>
                    <div class="col-md-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Sort by</p>
                    </div>
                    <div class="col-lg-12 form-row ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox"  [value]=true checked>
                            <label class="form-check-label">All</label>
                        </div>
                    </div>
                    <div class="col-lg-12 form-row ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" [value]=false>
                            <label class="form-check-label" for="">Latest</label>
                        </div>
                    </div>
                    <div class="col-lg-12 form-row ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox"  [value]=false>
                            <label class="form-check-label">Alphabetically (A-Z)</label>
                        </div>
                    </div>
                    <div class="col-lg-12 form-row ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" [value]=false>
                            <label class="form-check-label" for="">Alphabetically (Z-A)</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm">
                Apply
            </button>
        </footer>
    </form>
</div>
