
        <div class="row row-16 ">

          <div class="col-12">
            <div class="card details-card shadow-0 p-24">
              <div class="row row-12 " *ngIf="taxData?.earnings?.is_hra_present_in_salary">
                <div class="col-12 ">
                  <span class="title">HRA</span>
                </div>
                <div class="col-12 mt-24">
                  <div class="card details-card indicater1 bg-dark-400 px-16 py-12">
                    <div class="row">
                      <div class="col-5">
                        <div class="fw-600 fs-12">HRA/80GG</div>
                      </div>
                      <div class="col">
                        <div class="detail-title">Total Amount Declared</div>
                        <div class="detail-desc">{{taxData?.declared_amount?.hra_data?.rent_paid | currency:currency}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let hra80gg of hra80ggSingleData,let i = index">
                  <div class="col-12 ">
                    <span class="fs-14 fw-500">Property {{(i+1)}}</span>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">From</div>
                    <div class="detail-desc">{{hra80gg?.from_month+', '+hra80gg?.from_year}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">To</div>
                    <div class="detail-desc">{{hra80gg?.to_month+', '+hra80gg?.to_year}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{hra80gg?.amount | currency:currency}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">City</div>
                    <div class="detail-desc">{{hra80gg?.city}}</div>
                  </div>
                  <div class="col-12">
                    <hr class="hr-1">
                  </div>
                  <div class="col-12 ">
                    <span class="fs-14">Landlord’s details</span>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Landlord’s name</div>
                    <div class="detail-desc"> {{hra80gg?.landlord_name}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Landlord’s pan</div>
                    <div class="detail-desc">{{(hra80gg?.landlord_pan == '' || hra80gg?.landlord_pan == null)? '-' : hra80gg?.landlord_pan}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Landlord’s address</div>
                    <div class="detail-desc">{{hra80gg?.landlord_address}}</div>
                  </div>
                  <div class="col-12"  *ngIf="investmentSingleData?.proposed_confirm">
                    <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                        <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                      <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                        <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(hra80gg.landlord_document) }}</span>
                        <span class="tips-helper ">{{ this.appservice.getDocName(hra80gg.landlord_document) }}</span>
                        <div class="d-flex gap-8">

                          <div class="link-primary" (click)="viewDocument(hra80gg.landlord_document)">
                            <i class="bi bi-eye me-1"></i>View
                          </div>
                          <div class="link-primary" (click)="this.appservice.downloadFile(hra80gg.landlord_document,this.appservice.getDocName(hra80gg.landlord_document))">
                            <i class="bi bi-download me-1"></i>Download
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </ng-container>

              </div>
              <div class="row row-12 " *ngIf="!taxData?.earnings?.is_hra_present_in_salary">
                <div class="col-12 ">
                  <span class="title">80GG</span>
                </div>
                <div class="col-12 mt-24">
                  <div class="card details-card indicater1 bg-dark-400 px-16 py-12">
                    <div class="row">
                      <div class="col-5">
                        <div class="fw-600 fs-12">HRA/80GG</div>
                      </div>
                      <div class="col">
                          <div class="detail-title">Maximum limit</div>
                          <div class="detail-desc">{{investmentSingleData?.hra80gg_limit| currency:currency}}</div>
                      </div>
                      <div class="col">
                        <div class="detail-title"> Total amount declared</div>
                        <div class="detail-desc">{{taxData?.declared_amount?.hra80gg | currency:currency}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let hra80gg of hra80ggSingleData,let i = index">
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{hra80gg.amount| currency:currency}}</div>
                  </div>
                  <div class="col-12" *ngIf="investmentSingleData.proposed_confirm">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(hra80gg.landlord_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(hra80gg.landlord_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(hra80gg.landlord_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(hra80gg.landlord_document,this.appservice.getDocName(hra80gg.landlord_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>

                  </div>

              </ng-container>
              </div>
            </div>
          </div>
          <ng-container  *ngIf="cahpt80CSingleData != null">
            <div class="col-12">
              <div class="card details-card shadow-0 p-24 ">
                <div class="row row-12 ">
                  <div class="col-12 ">
                    <span class="title">Chapter VI A - 80 c</span>

                  </div>

                  <div class="col-12 mt-24">
                    <div class="card details-card indicater1 bg-dark-400 px-16 py-12">
                      <div class="row">
                        <div class="col-5">
                          <div class="fw-600 fs-12">CHAPTER VI A - 80C</div>
                        </div>
                        <div class="col">
                            <div class="detail-title">Maximum limit</div>
                            <div class="detail-desc">{{investmentSingleData?.chapter4a80c_limit| currency:currency}}</div>
                        </div>
                        <div class="col">
                          <div class="detail-title">Total amount declared</div>
                          <div class="detail-desc">{{taxData?.declared_amount?.chapter4a80c| currency:currency}}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                    <div class="col-12" >
                      <div class="fs-12 fw-500">Employee contribution towards PF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.employee_contribute_pf==true?(cahpt80CSingleData?.employee_contribute_pf_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Contribution towards VPF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.contribution_vpf==true?(cahpt80CSingleData?.contribution_vpf_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Contribution towards 15 years PPF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.contribution_15y_ppf==true?(cahpt80CSingleData?.ppf_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.ppf_document != '' ">



                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.ppf_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.ppf_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ppf_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.ppf_document,this.appservice.getDocName(cahpt80CSingleData.ppf_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.ppf_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Previous Employer PF
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.previous_employer_pf==true?(cahpt80CSingleData?.previous_employer_pf_amount| currency:currency):'-'}}</div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Five-year Post Office Time Deposit (POTD) Scheme
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.five_year_POTD==true?(cahpt80CSingleData?.POTD_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.POTD_document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.POTD_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.POTD_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.POTD_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.POTD_document,this.appservice.getDocName(cahpt80CSingleData.POTD_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData.POTD_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Subscription to National Saving Scheme (NSC)
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.subscribe_national_saving==true?(cahpt80CSingleData?.nsc_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.nsc_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.nsc_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.nsc_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.nsc_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.nsc_document,this.appservice.getDocName(cahpt80CSingleData.nsc_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.nsc_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <!-- NEW -->
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">NABARD rural bonds
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.nabard_rural_bond==true?(cahpt80CSingleData?.nabard_rural_bond_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.nabard_rural_bond_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.nabard_rural_bond_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.nabard_rural_bond_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.nabard_rural_bond_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.nabard_rural_bond_document,this.appservice.getDocName(cahpt80CSingleData.nabard_rural_bond_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.nabard_rural_bond_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Life insurance premium
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.lic==true?(cahpt80CSingleData?.lic_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.lic_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.lic_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.lic_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.lic_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.lic_document,this.appservice.getDocName(cahpt80CSingleData.lic_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.lic_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Annuity plan of LIC or other insurer towards pension scheme (80 CCC)
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.annuity==true?(cahpt80CSingleData?.annuity_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.annuity_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.annuity_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.annuity_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.annuity_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.annuity_document,this.appservice.getDocName(cahpt80CSingleData.annuity_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.annuity_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <!-- END -->

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Deduction in respect of contribution to NPS (80 CCD(1))
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.deduction_contribute_NPS==true?(cahpt80CSingleData?.nps_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.nps_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.nps_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.nps_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.nps_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.nps_document,this.appservice.getDocName(cahpt80CSingleData.nps_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.nps_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                  <div class="col-12">
                    <div class="fs-12 fw-500">Equity linked saving schemes (tax saving mutual funds) ELSS
                    </div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{cahpt80CSingleData?.equity_linked_saving==true?(cahpt80CSingleData?.ELSS_amount| currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData.proposed_confirm">
                    <div class="col-12" *ngIf="cahpt80CSingleData?.ELSS_document != '' ">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData?.ELSS_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.ELSS_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ELSS_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.ELSS_document,this.appservice.getDocName(cahpt80CSingleData.ELSS_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="cahpt80CSingleData?.ELSS_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">ULIP
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.ULIP==true?(cahpt80CSingleData?.ULIP_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.ULIP_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.ULIP_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.ULIP_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ULIP_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.ULIP_document,this.appservice.getDocName(cahpt80CSingleData.ULIP_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.ULIP_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Interest on national saving certificate
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.interest_NSC==true?(cahpt80CSingleData?.interest_NSC_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData.interest_nsc_document != '' ">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.interest_nsc_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.interest_nsc_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.interest_nsc_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.interest_nsc_document,this.appservice.getDocName(cahpt80CSingleData.interest_nsc_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.interest_nsc_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Children education tuition fees
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.child_education_tuition_fees==true?(cahpt80CSingleData?.tuition_fees| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.tuition_fees_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.tuition_fees_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.tuition_fees_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.tuition_fees_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.tuition_fees_document,this.appservice.getDocName(cahpt80CSingleData.tuition_fees_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.tuition_fees_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Repayment of housing principal
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.repay_house_principal==true?(cahpt80CSingleData?.repay_house_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.repay_house_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.repay_house_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.repay_house_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.repay_house_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.repay_house_document,this.appservice.getDocName(cahpt80CSingleData.repay_house_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.repay_house_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Stamp duty paid for purchase of house property
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.stampduty_purchase_house==true?(cahpt80CSingleData?.stampduty_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.stampduty_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.stampduty_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.stampduty_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.stampduty_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.stampduty_document,this.appservice.getDocName(cahpt80CSingleData.stampduty_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.stampduty_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="col-12">
                      <div class="fs-12 fw-500">Sukanya samriddhi yojana
                      </div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{cahpt80CSingleData?.saukanya_samridhi_yojana==true?(cahpt80CSingleData?.ssy_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="cahpt80CSingleData?.ssy_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(cahpt80CSingleData.ssy_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(cahpt80CSingleData.ssy_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(cahpt80CSingleData.ssy_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(cahpt80CSingleData.ssy_document,this.appservice.getDocName(cahpt80CSingleData.ssy_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="cahpt80CSingleData?.ssy_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                </div>
              </div>
            </div>
          </ng-container>
          <ng-container  *ngIf="deductionSingleData != null">

            <div class="col-12">
              <div class="card details-card shadow-0 p-24">
                <div class="row row-12 ">
                  <div class="col-12 ">
                    <span class="title">Chapter VI A - DEDUCTIONS</span>

                  </div>
                  <div class="col-12 mt-24">
                    <div class="card details-card indicater1 bg-dark-400 px-16 py-12">
                      <div class="row">
                        <div class="col-5">
                          <div class="fw-600 fs-12">CHAPTER VI A - DEDUCTIONS</div>
                        </div>

                        <div class="col">
                          <div class="detail-title">Total amount declared</div>
                          <div class="detail-desc">{{taxData?.declared_amount?.chapter4adeduction| currency:currency}}</div>
                        </div>
                      </div>
                    </div>
                  </div>


                    <!-- NEW -->
                    <div class="fw-400 fs-14 mt-8">80D : Health insurance premium for self / spouse or dependent children
                    </div>
                    <div class="fw-500 fs-14 mt-8">{{deductionSingleData?.health_insurance_selfspouse80d_age == false ? "Age : Less than 60" : "Age : Greater than 60"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.health_insurance_selfspouse80d==true?(deductionSingleData?.health_insurance_selfspouse80d_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.health_insurance_selfspouse80d_document != '' " >

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.health_insurance_selfspouse80d_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.health_insurance_selfspouse80d_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.health_insurance_selfspouse80d_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.health_insurance_selfspouse80d_document,this.appservice.getDocName(deductionSingleData.health_insurance_selfspouse80d_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.health_insurance_selfspouse80d_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80D : Health insurance premium for dependent parents
                    </div>
                    <div class="fw-500 fs-14 mt-8">{{deductionSingleData?.health_insurance_parents80d_age == false ? "Age : Less than 60" : "Age : Greater than 60"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.health_insurance_parents80d==true?(deductionSingleData?.health_insurance_parents80d_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.health_insurance_parents80d_document != '' " >

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.health_insurance_parents80d_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.health_insurance_parents80d_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.health_insurance_parents80d_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.health_insurance_parents80d_document,this.appservice.getDocName(deductionSingleData.health_insurance_parents80d_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.health_insurance_parents80d_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>


                    <div class="fw-400 fs-14 mt-8">80D : Preventive health checkup for self / spouse or dependent children
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.health_checkup_selfspouse80d==true?(deductionSingleData?.health_checkup_selfspouse80d_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.health_checkup_selfspouse80d_document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.health_checkup_selfspouse80d_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.health_checkup_selfspouse80d_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.health_checkup_selfspouse80d_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.health_checkup_selfspouse80d_document,this.appservice.getDocName(deductionSingleData.health_checkup_selfspouse80d_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.health_checkup_selfspouse80d_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80D : Preventive health checkup for dependent parents
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.health_checkup_parents80d==true?(deductionSingleData?.health_checkup_parents80d_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.health_checkup_parents80d_document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.health_checkup_parents80d_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.health_checkup_parents80d_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.health_checkup_parents80d_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.health_checkup_parents80d_document,this.appservice.getDocName(deductionSingleData.health_checkup_parents80d_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.health_checkup_parents80d_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">Medical bills for super senior citizen
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.medicalbill_senior80d==true?(deductionSingleData?.medicalbill_senior80d_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.medicalbill_senior80d_document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.medicalbill_senior80d_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.medicalbill_senior80d_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.medicalbill_senior80d_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.medicalbill_senior80d_document,this.appservice.getDocName(deductionSingleData.medicalbill_senior80d_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.medicalbill_senior80d_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <!-- END -->

                    <div class="fw-400 fs-14 mt-8">80CCD1(B): Employee's additional contribution to NPS
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.employee_additional_contribution==true?(deductionSingleData?.employee_contribution_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.employee_contribution_document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.employee_contribution_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.employee_contribution_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.employee_contribution_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.employee_contribution_document,this.appservice.getDocName(deductionSingleData.employee_contribution_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.employee_contribution_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80CCD(2): Employer's contribution to NPS account, deducted from salary
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.employer_contribution==true?(deductionSingleData?.employer_contribution_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.employer_contribution_document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.employer_contribution_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.employer_contribution_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.employer_contribution_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.employer_contribution_document,this.appservice.getDocName(deductionSingleData.employer_contribution_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.employee_contribution_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80TTA: Exemption of interest from banks, post office, etc. (or)  80TTB: Exemption of interest from banks, post office, etc.
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData.tta_ttb==true?(deductionSingleData.tta_ttb_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.tta_tta_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.tta_tta_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.tta_tta_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.tta_tta_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.tta_tta_document,this.appservice.getDocName(deductionSingleData.tta_tta_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.tta_tta_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80E: Interest on education loan
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.interest_education_loan==true?(deductionSingleData?.interest_education_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.interest_education_document != ''">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.interest_education_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.interest_education_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.interest_education_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.interest_education_document,this.appservice.getDocName(deductionSingleData.interest_education_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.interest_education_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80EE: Additional interest on home loan for first-time homeowners borrowed between 1st April 2016 to 31st March 2017
                    </div>
                    <div class="col-12">
                      <div class="fs-14 mb-12">I hereby declare that:</div>
                      <ol class="ps-16 li-12 fs-14 mb-0">
                        <li>First Residential Property</li>
                        <li>Loan amount should be less than 35 lac.</li>
                        <li>The property value should be less than 50 lac.</li>
                        <li>The loan should be sanctioned between <b>1st April 2016 to 31st March 2017</b></li>
                      </ol>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.interest_home_loan_80ee==true?(deductionSingleData?.interest_home_loan_amount80ee| currency:currency):'-'}}</div>
                    </div>

                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.interest_home_loan_document80ee != '' " >

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.interest_home_loan_document80ee) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.interest_home_loan_document80ee) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.interest_home_loan_document80ee)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.interest_home_loan_document80ee,this.appservice.getDocName(deductionSingleData.interest_home_loan_document80ee))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.interest_home_loan_document80ee == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <!-- NEW -->
                    <div class="fw-400 fs-14 mt-8">80EEA: Additional interest on home loan for first-time homeowners borrowed between 1st april 2019 to 31st march 2022
                    </div>
                    <div class="col-12">
                      <div class="fs-14 mb-12">I hereby declare that:</div>
                      <ol class="ps-16 li-12 fs-14 mb-0">
                        <li>First Residential Property</li>
                        <li>Loan amount should be less than 45 lac.</li>
                        <li>The loan should be sanctioned between <b>1st April 2019 to 31st March 2022</b></li>
                      </ol>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.interest_home_loan_80eea==true?(deductionSingleData?.interest_home_loan_amount80eea| currency:currency):'-'}}</div>
                    </div>

                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.interest_home_loan_document80eea != '' " >

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.interest_home_loan_document80eea) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.interest_home_loan_document80eea) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.interest_home_loan_document80eea)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.interest_home_loan_document80eea,this.appservice.getDocName(deductionSingleData.interest_home_loan_document80eea))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.interest_home_loan_document80eea == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80EEB: Interest on electrical vehicle loan borrowed between 1st april 2019 to 31st march 2023
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.interest_electrical_vehicle_loan_80eeb==true?(deductionSingleData?.interest_electrical_vehicle_loan_amount| currency:currency):'-'}}</div>
                    </div>

                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.interest_electrical_vehicle_loan_document != '' " >

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.interest_electrical_vehicle_loan_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.interest_electrical_vehicle_loan_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.interest_electrical_vehicle_loan_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.interest_electrical_vehicle_loan_document,this.appservice.getDocName(deductionSingleData.interest_electrical_vehicle_loan_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.interest_electrical_vehicle_loan_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                    <!-- END -->

                    <div class="fw-400 fs-14 mt-8">80 DD: Medical treatment for handicapped dependent
                    </div>
                    <div class="fw-500 fs-14 mt-8">{{deductionSingleData?.disabled_severely == false ? "Disabled" : "Severly disabled"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.medical_treatment_handicapped==true?(deductionSingleData?.handicapped_dependent_amount| currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.handicapped_dependent_document != '' " >

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.handicapped_dependent_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.handicapped_dependent_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.handicapped_dependent_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.handicapped_dependent_document,this.appservice.getDocName(deductionSingleData.handicapped_dependent_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.handicapped_dependent_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80DDB: Medical expenditure as per rule 11DD
                    </div>
                    <div class="fw-500 fs-14 mt-8">{{deductionSingleData?.year_60_more == false ? "Upto 60 years" : "More than 60 years"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.medical_expenditure==true?(deductionSingleData?.medical_expenditure_amount|currency:currency):'-'}}</div>
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Issue</div>
                      <div class="detail-desc">{{deductionSingleData?.medical_expenditure==true?deductionSingleData?.issue:'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.medical_expenditure_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center"  style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.medical_expenditure_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.medical_expenditure_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.medical_expenditure_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.medical_expenditure_document,this.appservice.getDocName(deductionSingleData.medical_expenditure_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.medical_expenditure_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80U: Self-suffering from disability
                    </div>
                    <div class="fw-500 fs-14 mt-8">{{deductionSingleData?.self_disabled_severely == false ? "Disabled" : "Severly disabled"}}
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.self_suffering_disability==true?(deductionSingleData.self_suffering_amount|currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.self_suffering_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.self_suffering_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.self_suffering_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.self_suffering_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.self_suffering_document,this.appservice.getDocName(deductionSingleData.self_suffering_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.self_suffering_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80G: Deduction for donations towards social causes (100% exemption)
                    </div>

                    <div class="col-12">
                      <div class="detail-title">Transaction type</div>
                        <div class="fw-500 fs-12 mt-8">{{deductionSingleData?.cash_other100 == false ? "Cash" : "Other"}}
                        </div>
                    </div>

                    <ng-container *ngIf="deductionSingleData?.donate_80g?.length > 0">
                      <div class="col-12">
                        <div class="detail-title">Fund type</div>
                        <div class="detail-desc">{{deductionSingleData?.fund_type100!=null ?deductionSingleData?.fund_type100_name:'-'}}</div>
                      </div>
                    </ng-container>

                    <div class="col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.donate_social_cause100==true?(deductionSingleData?.donate_social_cause100_amount|currency:currency):'-'}}</div>
                    </div>

                    <ng-container *ngIf="deductionSingleData?.donate_80g?.length > 0">

                      <div class="fw-500 fs-14 mt-8">Transaction Details
                      </div>
                      <ng-container *ngFor="let donate of deductionSingleData?.donate_80g,let i = index">
                        <ng-container *ngIf="donate.exemption">
                          <div class="fw-500 fs-12 mt-8">Transaction {{i + 1}}
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Name</div>
                            <div class="detail-desc"> {{donate.name}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">PAN</div>
                            <div class="detail-desc">{{donate.pan}}</div>
                          </div>
                          <div class="col-12">
                            <div class="detail-title">Address</div>
                            <div class="detail-desc">{{donate.address}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Payment mode</div>
                            <div class="detail-desc"> {{donate.payment_mode}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Cheque no./transaction id</div>
                            <div class="detail-desc">{{donate.check_no_transaction_id}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Payment date</div>
                            <div class="detail-desc">{{this.dateFormatDisplay(donate.payment_date)}}</div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.transaction_document100 != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.transaction_document100) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.transaction_document100) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.transaction_document100)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.transaction_document100,this.appservice.getDocName(deductionSingleData.transaction_document100))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.transaction_document100 == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <!-- NEW -->
                    <div class="fw-400 fs-14 mt-8">80G: Deduction for donations towards social causes (50% exemption)
                    </div>

                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Transaction type</div>
                    <div class="fw-500 fs-12 mt-8">{{deductionSingleData?.cash_other50 == false ? "Cash" : "Other"}}
                    </div>
                    </div>

                    <ng-container *ngIf="exeption_50?.length > 0">
                      <div class="col-12">
                        <div class="detail-title">Fund type</div>
                        <div class="detail-desc">{{deductionSingleData?.fund_type50!=null ?deductionSingleData.fund_type50_name:'-'}}</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.donate_social_cause_50==true?(deductionSingleData?.donate_social_cause50_amount|currency:currency):'-'}}</div>
                    </div>

                    <ng-container *ngIf="exeption_50?.length > 0">

                      <div class="fw-500 fs-14 mt-8">Transaction Details
                      </div>
                      <ng-container *ngFor="let donate of exeption_50,let i = index">

                          <div class="fw-500 fs-12 mt-8">Transaction {{i + 1}}
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Name</div>
                            <div class="detail-desc"> {{donate.name}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">PAN</div>
                            <div class="detail-desc">{{donate.pan}}</div>
                          </div>
                          <div class="col-12">
                            <div class="detail-title">Address</div>
                            <div class="detail-desc">{{donate.address}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Payment mode</div>
                            <div class="detail-desc"> {{donate.payment_mode}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Cheque no./transaction id</div>
                            <div class="detail-desc">{{donate.check_no_transaction_id}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">Payment date</div>
                            <div class="detail-desc">{{this.dateFormatDisplay(donate.payment_date)}}</div>
                          </div>
                        <!-- </ng-container> -->
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.transaction_document50 != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.transaction_document50) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.transaction_document50) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.transaction_document50)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.transaction_document50,this.appservice.getDocName(deductionSingleData.transaction_document50))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.transaction_document50 == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <!-- END -->

                    <div class="fw-400 fs-14 mt-8">80RRB: Deductions on income by way of royalty of a patent
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.royalty_patent==true?(deductionSingleData?.royalty_patent_amount|currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.royalty_patent_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.royalty_patent_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.royalty_patent_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.royalty_patent_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.royalty_patent_document,this.appservice.getDocName(deductionSingleData.royalty_patent_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.royalty_patent_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80GGC: Contribution by individuals to political parties
                    </div>
                    <div class="col-lg-3  col-sm-6 col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData?.contribution_individual_policalparties==true?(deductionSingleData?.contribution_policalparties_amount|currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12"  *ngIf="deductionSingleData?.contribution_policalparties_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.contribution_policalparties_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.contribution_policalparties_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.contribution_policalparties_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.contribution_policalparties_document,this.appservice.getDocName(deductionSingleData.contribution_policalparties_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.contribution_policalparties_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>

                    <div class="fw-400 fs-14 mt-8">80GGA: Deduction in respect of donations to certain funds, charitable institutions, scientific research, and rural development etc.
                    </div>
                    <div class="col-12">
                      <div class="detail-title">Type</div>
                    <div class="fw-500 fs-14 mt-8">{{deductionSingleData?.cash_other_80gga == true ? "Cash" : "Other"}}
                    </div>
                    </div>
                    <div class="col-12">
                      <div class="detail-title">Amount</div>
                      <div class="detail-desc">{{deductionSingleData.gga80==true?(deductionSingleData?.total_amount|currency:currency):'-'}}</div>
                    </div>
                    <ng-container *ngIf="investmentSingleData.proposed_confirm">
                      <div class="col-12" *ngIf="deductionSingleData?.transaction_80gga_document != '' ">

                          <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                              <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                              <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(deductionSingleData.transaction_80gga_document) }}</span>
                              <span class="tips-helper ">{{ this.appservice.getDocName(deductionSingleData.transaction_80gga_document) }}</span>
                              <div class="d-flex gap-8">

                                <div class="link-primary" (click)="viewDocument(deductionSingleData.transaction_80gga_document)">
                                  <i class="bi bi-eye me-1"></i>View
                                </div>
                                <div class="link-primary" (click)="this.appservice.downloadFile(deductionSingleData.transaction_80gga_document,this.appservice.getDocName(deductionSingleData.transaction_80gga_document))">
                                  <i class="bi bi-download me-1"></i>Download
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-12" *ngIf="deductionSingleData?.transaction_80gga_document == '' ">
                        <div class="detail-title">Document</div>
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!deductionSingleData?.cash_other_80gga">
                      <ng-container *ngFor="let deduction of deductionSingleData.deduction_80gga,let i = index">
                      <span class="fw-400 fs-14">Transaction Details {{i + 1}}</span>
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-title">Cheque no./transaction id</div>
                        <div class="detail-desc"> {{deduction.check_no_transaction_id}}</div>
                      </div>
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-title">Date</div>
                        <div class="detail-desc">{{this.dateFormatDisplay(deduction.date)}}</div>
                      </div>
                    </ng-container>
                  </ng-container>



                </div>
              </div>
            </div>
          </ng-container>

          <!-- NEW -->
          <div class="col-12">
            <div class="card details-card shadow-0 p-24">
              <div class="row row-12 ">
                <div class="col-12 ">
                  <span class="title">Income/Loss from property</span>
                </div>

                <div class="col-12 mt-24">
                  <div class="card details-card indicater1 bg-dark-400 px-16 py-12">
                    <div class="row">
                      <div class="col-5">
                        <div class="fw-600 fs-12">INCOME/LOSS FROM PROPERTY</div>
                      </div>
                      <div class="col">
                          <div class="detail-title">Maximum Limit</div>
                          <div class="detail-desc">{{investmentSingleData?.income_loss_property |currency:currency}}</div>
                      </div>
                      <div class="col">
                        <div class="detail-title">Total amount declared</div>
                        <div class="detail-desc">{{taxData?.declared_amount?.incomeloss_property|currency:currency}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                  <div class="col-12 ">
                    <div class="fw-500 fs-12 mt-8">Income from Self Occupied property
                    </div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{incomeLossSingleData?.incomefrom_self_occupiedproperty==true?(incomeLossSingleData?.intreston_houseloan_amount|currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngFor="let selfLender of incomeLossSingleData?.incomeself_lender,let i = index">
                      <div class="fw-500 fs-12 mt-8">Lender information {{i + 1}}
                      </div>
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-title">Lender name</div>
                        <div class="detail-desc"> {{selfLender.lender_name}}</div>
                      </div>
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-title">Lender pan</div>
                        <div class="detail-desc">{{selfLender.lender_pan}}</div>
                      </div>
                  </ng-container>
                  <ng-container *ngIf="investmentSingleData?.proposed_confirm">
                    <div class="col-12" *ngIf="incomeLossSingleData?.intreston_houseloan_document != '' && incomeLossSingleData?.intreston_houseloan_document != null ">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(incomeLossSingleData?.intreston_houseloan_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(incomeLossSingleData?.intreston_houseloan_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(incomeLossSingleData?.intreston_houseloan_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(incomeLossSingleData?.intreston_houseloan_document,this.appservice.getDocName(incomeLossSingleData?.intreston_houseloan_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="incomeLossSingleData?.intreston_houseloan_document == '' || incomeLossSingleData?.intreston_houseloan_document == null ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <hr class="hr-1">
                  </div>

                    <div class="col-12 ">
                      <div class="fw-500 fs-12 mt-8">Income from Let-out property
                      </div>
                    </div>


                    <!-- test start -->
                    <ng-container *ngIf="incomeLossSingleData?.loss_lender?.length == 0;else loss_lender_data">
                      <div class="col-lg-3  col-sm-6 col-12">
                        <div class="detail-desc">-</div>
                      </div>
                    </ng-container>
                    <!-- test end -->
                    <ng-template #loss_lender_data>
                      <ng-container *ngFor="let lossLender of incomeLossSingleData?.loss_lender,let i = index">
                          <div class="fw-500 fs-12 mt-8">Property {{i + 1}}
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">(a) Annual rent received</div>
                            <div class="detail-desc"> {{lossLender?.annual_rent_received|currency:currency}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">(b) Municipal taxes paid</div>
                            <div class="detail-desc">{{lossLender?.muncipal_taxpaid|currency:currency}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">(c) Net value (a-b)</div>
                            <div class="detail-desc"> {{lossLender?.netvalue_ab|currency:currency}}</div>
                          </div>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">(d) Standard deduction (30% of the net value)</div>
                            <div class="detail-desc">{{lossLender?.standard_deduction}}</div>
                          </div>
                          <ng-container *ngIf="lossLender?.repaying_homeloan_property">
                            <div class="col-12 ">
                              <div class="fw-500 fs-12 mt-8">(e) I'm repaying home loan for this property:
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Interest paid on home loan</div>
                              <div class="detail-desc">{{lossLender?.intrestpaid_houseloan_amount|currency:currency}}</div>
                            </div>
                            <div class="fw-500 fs-12 mt-8">Lender information {{i + 1}}
                            </div>
                            <div class="col-lg-3  col-sm-6 col-12">
                              <div class="detail-title">Lender name</div>
                              <div class="detail-desc">{{lossLender?.lender_name}}</div>
                            </div>
                            <div class="col-lg-3  col-sm-6 col-12">
                              <div class="detail-title">Lender pan</div>
                              <div class="detail-desc">{{lossLender?.lender_pan}}</div>
                            </div>
                          </ng-container>
                          <div class="col-lg-3  col-sm-6 col-12">
                            <div class="detail-title">(f) Net income/loss from house property (c-(d+e))</div>
                            <div class="detail-desc">{{lossLender?.net_income_houseproperty|currency:currency}}</div>
                          </div>

                          <ng-container *ngIf="investmentSingleData?.proposed_confirm">
                            <div class="col-12" *ngIf="lossLender?.net_income_houseproperty_document != '' && lossLender?.net_income_houseproperty_document != null ">

                                <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                                    <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                                  <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(lossLender?.net_income_houseproperty_document) }}</span>
                                    <span class="tips-helper ">{{ this.appservice.getDocName(lossLender?.net_income_houseproperty_document) }}</span>
                                    <div class="d-flex gap-8">

                                      <div class="link-primary" (click)="viewDocument(lossLender?.net_income_houseproperty_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary" (click)="this.appservice.downloadFile(lossLender?.net_income_houseproperty_document,this.appservice.getDocName(lossLender?.net_income_houseproperty_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="lossLender?.net_income_houseproperty_document == '' || lossLender?.net_income_houseproperty_document == null ">
                              <div class="detail-title">Document</div>
                              <div class="detail-desc">-</div>
                            </div>
                          </ng-container>
                      </ng-container>
                    </ng-template>


              </div>
            </div>
          </div>
          <!-- END -->

          <div class="col-12">
            <div class="card details-card shadow-0 p-24">
              <div class="row row-12 ">
                <div class="col-12 ">
                  <span class="title">INCOME FROM OTHER SOURCES</span>
                </div>
                <div class="col-12 mt-24">
                  <div class="card details-card indicater1 bg-dark-400 px-16 py-12">
                    <div class="row">
                      <div class="col-5">
                        <div class="fw-600 fs-12">INCOME FROM OTHER SOURCES</div>
                      </div>

                      <div class="col">
                        <div class="detail-title">Total amount declared</div>
                        <div class="detail-desc">{{taxData?.declared_amount?.income_from_othersource|currency:currency}}</div>
                      </div>
                    </div>
                  </div>
                </div>



                  <div class="fw-500 fs-12 mt-8">Income from other sources
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData?.incomefrom_othersource==true?(otherSourceSingleData?.othersource_amount|currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData?.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData?.othersource_document != '' ">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(otherSourceSingleData?.othersource_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(otherSourceSingleData?.othersource_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(otherSourceSingleData?.othersource_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(otherSourceSingleData?.othersource_document,this.appservice.getDocName(otherSourceSingleData?.othersource_document) )">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData?.othersource_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <hr class="hr-1">
                  </div>

                  <div class="fw-500 fs-12 mt-8">Interest earned through saving bank account
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData?.interest_earned_sbaccount==true?(otherSourceSingleData?.sb_account_amount|currency:currency):''}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData?.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData?.sb_account_document != '' ">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(otherSourceSingleData?.sb_account_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(otherSourceSingleData?.sb_account_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(otherSourceSingleData?.sb_account_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(otherSourceSingleData?.sb_account_document,this.appservice.getDocName(otherSourceSingleData?.sb_account_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData?.sb_account_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>

                  <div class="fw-500 fs-12 mt-8">80TTA/ 80TTB
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData?.source_80TTA_80TTB==true?(otherSourceSingleData?.source_80TTA_80TTB_amount|currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData?.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData?.source_80TTA_80TTB_document != '' ">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(otherSourceSingleData?.source_80TTA_80TTB_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(otherSourceSingleData?.source_80TTA_80TTB_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(otherSourceSingleData?.source_80TTA_80TTB_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(otherSourceSingleData?.source_80TTA_80TTB_document,this.appservice.getDocName(otherSourceSingleData?.source_80TTA_80TTB_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData?.source_80TTA_80TTB_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <div class="col-12">
                    <hr class="hr-1">
                  </div>

                  <!-- NEW -->

                  <div class="fw-500 fs-12 mt-8">Interest earned from national savings certificate
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Amount</div>
                    <div class="detail-desc">{{otherSourceSingleData?.intrest_earned==true?(otherSourceSingleData?.intrest_earned_amount|currency:currency):'-'}}</div>
                  </div>
                  <ng-container *ngIf="investmentSingleData?.proposed_confirm">
                    <div class="col-12" *ngIf="otherSourceSingleData?.intrest_earned_document != '' ">

                        <div class="p-12 bg-secondary radius-8 d-flex align-items-center" style="max-width:14.4375rem;">
                            <i class="bi bi-file-earmark-text-fill me-16 text-accent2 fs-40 lh-1"></i>
                          <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                            <span class="tips text-uppercase fs- fw-700 text-trim">{{ this.appservice.getDocName(otherSourceSingleData.intrest_earned_document) }}</span>
                            <span class="tips-helper ">{{ this.appservice.getDocName(otherSourceSingleData?.intrest_earned_document) }}</span>
                            <div class="d-flex gap-8">

                              <div class="link-primary" (click)="viewDocument(otherSourceSingleData?.intrest_earned_document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="this.appservice.downloadFile(otherSourceSingleData?.intrest_earned_document,this.appservice.getDocName(otherSourceSingleData?.intrest_earned_document))">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="otherSourceSingleData?.intrest_earned_document == '' ">
                      <div class="detail-title">Document</div>
                      <div class="detail-desc">-</div>
                    </div>
                  </ng-container>

                  <!-- END -->


              </div>
            </div>
          </div>


          <div class="col-12">
            <div class="card details-card card-c6 shadow-0 p-16">
              <div class="row row-12">
                <div class="col-12 mb-8">
                  <div class="title">{{investmentSingleData.tax_regime == true ? 'NEW' : 'OLD'}} TAX REGIME</div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                  <div class="detail-title">Total taxable income</div>
                  <div class="detail-desc">{{taxData?.regime_summary?.total_tax_income|currency:currency}}</div>
              </div>
              <div class="col-lg-3  col-sm-6 col-12">
                <div class="detail-title">Total tax</div>
                <div class="detail-desc">{{taxData?.regime_summary?.total_tax|currency:currency}}</div>
            </div>
              </div>
            </div>
          </div>
          <!-- NEW TAX REGIME START -->
          <!-- test start -->
          <ng-container *ngIf="investmentSingleData?.tax_regime">
            <div class="col-12 fw-500">Earnings</div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Gross
                      <div class="ms-auto fw-500">{{taxData?.earnings?.gross|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-12">
                      <ng-container *ngFor="let salaryBreak of taxData?.earnings?.salary_break_down | keyvalue">
                        <div class="d-flex w-100">{{salaryBreak?.key}}<div class="ms-auto ">{{stringToNumConversion(salaryBreak.value) | currency: currency}}</div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>

            <div class="col-12">
              <div class="fw-500">Income from other sources </div>
            </div>
            <div class="col-12 mt-8" *ngFor="let otherSource of taxData?.earnings?.income_from_othersource?.income_from_othersource_input_data | keyvalue">
              <div class="box-1 fw-500 bg-body">{{fieldName(otherSource.key)}} <div class="ms-auto ">{{otherSource.value}} </div>
              </div>
            </div>

            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total exemptions under income from other sources <div class="ms-auto ">{{taxData?.earnings?.income_from_othersource?.income_from_othersource|currency:currency}} </div>
              </div>
            </div>

            <div class="col-12 mt-8">
              <div class="box-1 fw-500 bg-body">Income from previous employer <div class="ms-auto ">{{taxData?.earnings?.prev_employer_income|currency:currency}} </div>
              </div>
            </div>

             <!-- Income/Loss from Property start -->
             <div class="col-12">
              <div class="fw-500">Income/Loss from Property</div>
            </div>
            <ng-container *ngIf="(taxData?.earnings?.incomeloss_property | keyvalue)?.length">
              <div class="col-12" *ngIf="(taxData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender | keyvalue)?.length">
                <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                  <ngb-panel id="ngb-panel-0">
                    <ng-template ngbPanelHeader>
                      <button ngbPanelToggle class="accordion-button">
                        Income from let-out property
                        <div class="ms-auto fw-500">{{taxData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender_net_amount|currency:currency}}</div>
                      </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="d-flex flex-column gap-12">
                        <ng-container *ngFor="let incomeLess of taxData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender,let i = index">
                          <div class="d-flex w-100">Property {{(i+1)}}<div class="ms-auto ">{{incomeLess?.net_income_houseproperty|currency:currency}}</div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-template>
                  </ngb-panel>

                </ngb-accordion>
              </div>
            </ng-container>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500">Total Income/Loss from property <div class="ms-auto ">{{taxData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender_net_amount|currency:currency}} </div>
              </div>
            </div>
            <!-- Income/Loss from Property end -->

            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total taxable income FY {{this.appservice.getFinancialYearRange(currentYear)}} <div class="ms-auto ">{{taxData?.earnings?.total_taxable_income|currency:currency}} </div>
              </div>
            </div>

            <div class="col-12 mt-24">
              <div class="fw-600 fs-18">Exemptions under Section 16</div>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 bg-body"><div class="me-32 ">Standard deduction</div> <div class="ms-auto ">{{taxData?.excemption_under_section16?.standard_deduction | currency: currency}} </div>
              </div>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 "><div class="me-32 ">Total exemptions under section 16 </div><div class="ms-auto ">{{taxData?.taxable_income?.excemption_under_section16 | currency: currency}} </div>
              </div>
            </div>

            <div class="col-12">
              <div class="fw-500">Tax </div>
            </div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Tax Details
                      <div class="ms-auto fw-500">{{taxData?.tax_details?.total_payable_tax|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-12">
                      <div class="d-flex w-100">Raw tax on total taxable income<div class="ms-auto ">{{taxData?.tax_details?.Tax_on_total_taxable_income|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Rebate under section 87A (a)<div class="ms-auto {{taxData?.tax_details?.Rebate_under_Section_87A > 0 ? 'text-danger' : ''}}">{{taxData?.tax_details?.Rebate_under_Section_87A|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Relief Under Section 87A (b)<div class="ms-auto {{taxData?.tax_details?.marginal_relief_of_normal_slab < 0 ? 'text-danger' : ''}}">{{taxData?.tax_details?.marginal_relief_of_normal_slab|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Raw Tax on total taxable income after rebate<div class="ms-auto ">{{taxData?.tax_details?.tax_on_total_taxable_income_after_rebate|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Surcharge on raw tax<div class="ms-auto ">{{taxData?.tax_details?.surcharge_charge|currency:currency}}
                        </div>
                      </div>
                      <div class="d-flex w-100">Education cess ({{taxData?.tax_details.education_cess_charge}}% of total tax)<div class="ms-auto ">{{taxData?.tax_details?.education_cess_amount|currency:currency}}
                      </div>
                    </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total tax payable for FY {{this.appservice.getFinancialYearRange(currentYear)}} <div class="ms-auto ">{{taxData?.tax_details?.total_payable_tax|currency:currency}}</div>
              </div>
            </div>

            <div class="col-12">
              <div class="fw-500">Tax payable </div>
            </div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Tax paid so far
                      <div class="ms-auto fw-500">{{taxData?.tax_payable?.tax_paid_so_far_sum|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ng-container  *ngIf="taxData?.tax_payable?.tax_paid_so_far?.length > 0">
                        <div class="d-flex flex-column gap-12" *ngFor="let taxPaid of taxData?.tax_payable?.tax_paid_so_far">
                          <div class="d-flex w-100">{{taxPaid?.month == 'tax_paid_by_prev_employer'?'Tax paid till now':taxPaid?.month}}<div class="ms-auto ">{{taxPaid?.tax |currency:currency}}</div>
                          </div>

                        </div>
                    </ng-container>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="ngb-panel-1">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Balance payable
                      <div class="ms-auto fw-500">{{taxData?.tax_payable?.tax_balance_payable_sum |currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ng-container  *ngIf="taxData?.tax_payable?.tax_balance_payable?.length >0">
                      <div class="d-flex flex-column gap-12" *ngFor="let taxBalance of taxData?.tax_payable?.tax_balance_payable">
                        <div class="d-flex w-100">{{taxBalance?.month}}<div class="ms-auto ">{{taxBalance?.tax | currency:currency}}</div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total tax payable in upcoming months<div class="ms-auto ">{{taxData?.tax_payable?.tax_balance_payable_sum|currency:currency}}</div>
              </div>
            </div>
          </ng-container>
          <!-- OLD TAX REGIME START -->

          <ng-container *ngIf="!investmentSingleData.tax_regime">
            <div class="col-12 fw-500">Earnings</div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Gross
                      <div class="ms-auto fw-500">{{taxData?.earnings?.gross|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-12">
                      <ng-container *ngFor="let salaryBreak of taxData?.earnings?.salary_break_down | keyvalue">
                        <div class="d-flex w-100">{{salaryBreak?.key}}<div class="ms-auto ">{{stringToNumConversion(salaryBreak.value) | currency: currency}}</div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>

            <div class="col-12">
              <div class="fw-500">Income from other sources </div>
            </div>
            <div class="col-12 mt-8" *ngFor="let otherSource of taxData?.earnings?.income_from_othersource?.income_from_othersource_input_data | keyvalue">
              <div class="box-1 fw-500 bg-body">{{fieldName(otherSource.key)}} <div class="ms-auto ">{{stringToNumConversion(otherSource.value) | currency:currency}} </div>
              </div>
            </div>

            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total exemptions under income from other sources <div class="ms-auto ">{{taxData?.earnings?.income_from_othersource?.income_from_othersource|currency:currency}} </div>
              </div>
            </div>

            <div class="col-12 mt-8">
              <div class="box-1 fw-500 bg-body">Income from previous employer <div class="ms-auto ">{{taxData?.earnings?.prev_employer_income|currency:currency}} </div>
              </div>
            </div>

            <!-- Income/Loss from Property start -->
            <div class="col-12">
              <div class="fw-500">Income/Loss from Property</div>
            </div>
            <ng-container *ngIf="(taxData?.earnings?.incomeloss_property | keyvalue)?.length">
              <div class="col-12 mt-8" *ngIf="(taxData?.earnings?.incomeloss_property?.income_from_self_occupiedproperty?.incomeself_lender | keyvalue)?.length">
                <div class="box-1 fw-500 bg-body">Interest on housing loan (self occupied)  <div class="ms-auto ">{{taxData?.earnings?.incomeloss_property?.income_from_self_occupiedproperty?.incomeself_lender_amount | currency:currency}} </div>
                </div>
              </div>

              <div class="col-12" *ngIf="(taxData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender | keyvalue)?.length">
                <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                  <ngb-panel id="ngb-panel-0">
                    <ng-template ngbPanelHeader>
                      <button ngbPanelToggle class="accordion-button">
                        Income from Let-out property
                        <div class="ms-auto fw-500">{{taxData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender_net_amount|currency:currency}}</div>
                      </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="d-flex flex-column gap-12">
                        <ng-container *ngFor="let incomeLess of taxData?.earnings?.incomeloss_property?.income_from_letout_property?.loss_lender,let i = index">
                          <div class="d-flex w-100">Property {{(i+1)}}<div class="ms-auto ">{{incomeLess?.net_income_houseproperty|currency:currency}}</div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-template>
                  </ngb-panel>

                </ngb-accordion>
              </div>
            </ng-container>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500">Total income/loss from property <div class="ms-auto ">{{taxData?.earnings?.incomeloss_property?.total_income_loss_property|currency:currency}} </div>
              </div>
            </div>
            <!-- Income/Loss from Property end -->

            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total taxable income FY {{this.appservice.getFinancialYearRange(currentYear)}} <div class="ms-auto ">{{taxData?.earnings?.total_taxable_income|currency:currency}} </div>
              </div>
            </div>

            <div class="col-12 mt-24">
              <div class="fw-500">Exemptions under section 16</div>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 bg-body">Professional tax <div class="ms-auto ">{{taxData?.excemption_under_section16?.professional_tax|currency:currency}} </div>
              </div>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 bg-body">Standard deduction <div class="ms-auto ">{{taxData?.excemption_under_section16?.standard_deduction|currency:currency}} </div>
              </div>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500">Total exemptions under section 16 <div class="ms-auto ">{{taxData?.taxable_income?.excemption_under_section16 |currency:currency}} </div>
              </div>
            </div>

            <div class="col-12 mt-24">
              <div class="fw-500">Exemptions based on declaration</div>
            </div>
            <div class="col-12">
              <div class="d-flex w-100 fs-14">Particulars<div class="ms-auto ">Declared Amount</div>
              </div>
            </div>
            <div class="col-12">
              <hr class="hr-1">
            </div>
            <div class="col-12">
              <div class="fw-500">Exemptions (Section 10) </div>
            </div>

            <div class="col-12"  *ngIf="hra80gg == 'hra'">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      HRA
                      <div class="ms-auto fw-500">{{taxData?.excemption_declaration?.hra|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-12" *ngFor="let rentData of taxData?.excemption_declaration?.rent_data | keyvalue">
                      <div class="d-flex w-100">{{removeUnderscoreAddUpperCase(rentData.key)}}<div class="ms-auto ">{{stringToNumConversion(rentData.value) | currency:currency}}</div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="col-12" *ngIf="hra80gg == '80gg'">
              <div class="box-1 fw-500 bg-body">80GG <div class="ms-auto ">{{taxData?.excemption_declaration?.['80gg']|currency:currency}} </div>
              </div>
            </div>
            <div class="col-12" *ngIf="taxData?.excemption_declaration?.Child_Education_Allowance">
              <div class="box-1 fw-500 bg-body">Children education allowance <div class="ms-auto ">{{taxData?.excemption_declaration?.Child_Education_Allowance|currency:currency}} </div>
              </div>
            </div>
            <div class="col-12" *ngIf="taxData?.excemption_declaration?.Child_Hostel_Allowance">
              <div class="box-1 fw-500 bg-body">Children hostel allowance <div class="ms-auto ">{{taxData?.excemption_declaration?.Child_Hostel_Allowance|currency:currency}} </div>
              </div>
            </div>

            <!-- Reimbursement component wise start -->
            <div class="col-12" *ngIf="taxData?.excemption_declaration?.Reimbursement_Bill_Amount?.total_bill_amount > 0">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Reimbursement
                      <div class="ms-auto fw-500">{{taxData?.excemption_declaration?.Reimbursement_Bill_Amount?.total_bill_amount | currency: currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-12 fs-14" >
                      <div *ngFor="let billAmt of taxData?.excemption_declaration?.Reimbursement_Bill_Amount?.accepted_bills_dict | keyvalue" class="d-flex w-100">{{(billAmt.key)}}<div class="ms-auto ">{{stringToNumConversion(billAmt?.value) | currency: currency}}</div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <!-- Reimbursement component wise end -->

            <div class="col-12 mt-8">
              <div class="box-1 fw-500">Total expemtions under section 10 <div class="ms-auto ">{{taxData?.excemption_declaration?.total_excemption|currency:currency}} </div>
              </div>
            </div>
            <div class="col-12">
              <div class="fw-500">Chapter VI A - 80 C</div>
            </div>
            <div class="col-12 mt-8" *ngFor="let chapter4a of taxData?.chapter4a80c?.chapter4a80c_input_data | keyvalue">
              <div class="box-1 fw-500 bg-body">{{chapter4aFieldName(chapter4a.key)}} <div class="ms-auto ">{{stringToNumConversion(chapter4a.value) | currency:currency}} </div>
              </div>
            </div>

            <div class="col-12 mt-8">
              <div class="box-1 fw-500">Total exemptions under chapter VI A - 80C <div class="ms-auto ">{{taxData?.chapter4a80c?.chapter4a80c_data|currency:currency}} </div>
              </div>
            </div>
            <div class="col-12">
              <div class="fw-500">Chapter VI A - deductions</div>
            </div>
            <div class="col-12 mt-8" *ngFor="let deduction of taxData?.chapter4adeduction?.chapter4adeduction_input_data | keyvalue">
              <div class="box-1 fw-500 bg-body">{{deductionFieldName(deduction.key)}} <div class="ms-auto ">{{stringToNumConversion(deduction.value) | currency:currency}} </div>
              </div>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500">Total exemptions under chapter VI A - deductions <div class="ms-auto ">{{taxData?.chapter4adeduction?.chapter4adeduction_data|currency:currency}} </div>
              </div>
            </div>


            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total exemptions based on declration <div class="ms-auto ">{{taxData?.excemption_based_on_declaration|currency:currency}} </div>
              </div>
            </div>

            <div class="col-12">
              <div class="fw-500">Taxable Income </div>
            </div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Taxable Income Details
                      <div class="ms-auto fw-500">{{taxData?.taxable_income?.total_taxable_income_in_FY|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-12">
                      <div class="d-flex w-100">Earning’s<div class="ms-auto ">{{taxData?.taxable_income?.earnings|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Exemption’s under section 16<div class="ms-auto text-danger">{{taxData?.taxable_income?.excemption_under_section16 == 0 ? (0|currency:currency) : (-(taxData?.taxable_income?.excemption_under_section16)|currency:currency)}}</div>
                      </div>
                      <div class="d-flex w-100">Exemption’s based on declaration<div class="ms-auto text-danger">{{(taxData?.taxable_income?.excemption_based_on_declaration == 0) ? (0|currency:currency) : (-(taxData?.taxable_income?.excemption_based_on_declaration)|currency:currency)}}</div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total Tax Income FY {{this.appservice.getFinancialYearRange(currentYear)}} <div class="ms-auto ">{{taxData?.taxable_income?.total_taxable_income_in_FY|currency:currency}}</div>
              </div>
            </div>

            <div class="col-12">
              <div class="fw-500">Tax </div>
            </div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Tax Details
                      <div class="ms-auto fw-500">{{taxData?.tax_details?.total_payable_tax|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-12">
                      <div class="d-flex w-100">Raw tax on total taxable income<div class="ms-auto ">{{taxData?.tax_details?.Tax_on_total_taxable_income|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Rebate under section 87A<div class="ms-auto {{taxData?.tax_details?.Rebate_under_Section_87A < 0 ? 'text-danger' : ''}}">{{taxData?.tax_details?.Rebate_under_Section_87A|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Raw Tax on total taxable income after rebate<div class="ms-auto ">{{taxData?.tax_details?.tax_on_total_taxable_income_after_rebate|currency:currency}}</div>
                      </div>
                      <div class="d-flex w-100">Surcharge on raw tax<div class="ms-auto ">{{taxData?.tax_details?.surcharge_charge|currency:currency}}
                        </div>
                      </div>
                      <div class="d-flex w-100">Education cess ({{taxData?.tax_details?.education_cess_charge}}% of total tax)<div class="ms-auto ">{{taxData?.tax_details?.education_cess_amount|currency:currency}}
                      </div>
                    </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total tax payable for FY {{this.appservice.getFinancialYearRange(currentYear)}} <div class="ms-auto ">{{taxData?.tax_details?.total_payable_tax|currency:currency}}</div>
              </div>
            </div>

            <div class="col-12">
              <div class="fw-500">Tax Payable </div>
            </div>
            <div class="col-12">
              <ngb-accordion class="custom-accordion1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Tax paid so far
                      <div class="ms-auto fw-500">{{taxData?.tax_payable?.tax_paid_so_far_sum|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ng-container  *ngIf="taxData?.tax_payable?.tax_paid_so_far?.length > 0">
                      <div class="d-flex flex-column gap-12" *ngFor="let taxPaid of taxData?.tax_payable?.tax_paid_so_far">
                        <div class="d-flex w-100">{{taxPaid?.month == 'tax_paid_by_prev_employer'?'Tax paid till now':taxPaid?.month}}<div class="ms-auto ">{{stringToNumConversion(taxPaid?.tax) | currency:currency}}</div>
                        </div>

                      </div>
                    </ng-container>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="ngb-panel-1">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button">
                      Balance payable
                      <div class="ms-auto fw-500">{{taxData?.tax_payable?.tax_balance_payable_sum|currency:currency}}</div>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ng-container  *ngIf="taxData?.tax_payable?.tax_balance_payable?.length > 0">
                      <div class="d-flex flex-column gap-12" *ngFor="let taxBalance of taxData?.tax_payable?.tax_balance_payable">
                        <div class="d-flex w-100">{{taxBalance?.month}}<div class="ms-auto ">{{stringToNumConversion(taxBalance?.tax) | currency:currency}}</div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="col-12 mt-8">
              <div class="box-1 fw-500 ">Total tax payable in upcoming months<div class="ms-auto ">{{taxData?.tax_payable?.tax_balance_payable_sum|currency:currency}}</div>
              </div>
            </div>

          </ng-container>



        </div>


              <form class="mt-20" *ngIf="from=='inbox' || from=='behalf' || (from=='fnf' && reqStatus == 'Pending') " [formGroup]="form">
              <ng-container *ngIf="!requestitem?.achieved_status">
              <div class="col-12">
				          <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..." class="form-control mb-8 textarea-comment" formControlName="comment"
               [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                <div *ngIf="f.comment.errors" class="invalid-feedback">
                  <div *ngIf="f.comment.errors?.maxlength">Maximum 500 characters allowed.</div>
                </div>
              </div>
              <div class="col-12 d-flex gap-16">
                <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400" (click)="confirm('Rejected')" >Decline</button>
                <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400" (click)="confirm('Approved')">Confirm</button>
              </div>
            </ng-container>
            </form>



<!-- un supported view format alert -->
<ng-container *ngIf="alertToggle==true">
  <app-alert-toggle (modals)="closed($event)" [alertToggle]="alertToggle" [alertMsg]="investalertMsg"></app-alert-toggle>
</ng-container>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close1($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

