<aside class="sidebar {{theme}}">
  <!-- <aside class="sidebar skizzle-default"> -->
  <div class="sidebar--menu-icon">
    <div [ngClass]="expandDisp" *ngIf="selectedListItemIndex == -1" class="sidebar--collapse-btn pointer" (click)="setIndex(collapseValue)">
      <i class="bi bi-chevron-right lh-1"></i>
    </div>
    <div *ngIf="selectedListItemIndex != -1" class="sidebar--collapse-btn " (click)="setIndex(-1)">
      <i class="bi bi-chevron-right"></i>
    </div>
    <a routerLink="/home" class="brand-logo" (click)="setIndex(homeMenuIndex);removeParentClassActive()">
      <img class="img-fluid" src="assets/images/SkizzleHR-Logo/SkizzleHR-Logomark-Transparent.svg" alt="" />
    </a>
    <ul class="menu-icon">
      <ng-container *ngFor="let list of subMenuList; let i = index; ">
        <ng-container *ngIf="list.url != '/profile'">
          <li class="menu{{i}}"  *ngIf="list.url != ''" [class.active]="parentActive === i" (click)="setIndex(i);removeParentClassActive();clicked=false;"
            routerLink="{{ list.url }}" placement="right" ngbPopover="{{list.name}}" popoverClass="popover-type1" container="body" triggers="hover">
            <a class="icon-link"><i class="{{list.icon}}"></i></a>
          </li>
          <li class="menu{{i}}  {{(list.name === 'Setting') ? 'mt-auto' : ''}}" *ngIf="list.url == ''" [class.active]="parentActive === i" (click)="setIndex(i);clicked=false" placement="right" ngbPopover="{{list.name}}" popoverClass="popover-type1" container="body" triggers="hover">
            <a class="icon-link"><i class="{{list.icon}}"></i></a>

          </li>
      </ng-container>
      </ng-container>
    </ul>

    <a class="user-profile" [class.disabled]="this.authService.isSuperUser()" [class.active]="clicked == true" ngbPopover="Profile" placement="right"  popoverClass="popover-type1"
    container="body" triggers="hover" (click)="setIndex(profilemenuindex);clicked=true;">
    <!-- <a class="user-profile active" [ngbPopover]="popContent" placement="right" popoverClass="profile-popover" container="main-container" (click)="setIndex(profilemenuindex)"> -->
      <div *ngIf="(basicEmpData.profile_image == null || basicEmpData.profile_image=='')" class="no-image rounded-circle {{this.snapshotService.getCurrentUserColor()}}">
        {{this.appService.getFirstChar(basicEmpData.first_name +
          " " +
          basicEmpData.last_name,2)}}
      </div>
      <img *ngIf="(basicEmpData.profile_image != null && basicEmpData.profile_image!='')" class="img-fluid rounded-circle {{this.snapshotService.getCurrentUserColor()}}"
        src="{{ basicEmpData.profile_image }}" alt=""/>
    </a>
  </div>
  <div class="sidebar--menu-list" [class.active]="selectedListItemIndex != -1">


    <ng-template #menuRef let-menulist>
      <ng-container *ngFor="let list of menulist; let i = index">
        <ul id="menu{{i}}" class="menu-list " [class.active]="selectedListItemIndex === i" *ngIf="list.url !='/request'">
          <ng-container *ngFor="let parentlink of list.children">
            <ng-container *ngIf="env.name=='local'|| env.name=='development'">
             
              <li *ngIf="(parentlink?.children?.length <= 0 || countThirdLevel(parentlink?.children)==0) " placement="right" ngbPopover="{{parentlink.name}}" popoverClass="popover-type1" container="body" triggers="hover">
                <a routerLink="{{ parentlink.url }}" (click)="removeParentClassActive();" routerLinkActive="active">{{
                  parentlink.name
                  }}</a>
              </li>
              <li *ngIf="parentlink?.children?.length > 0  && countThirdLevel(parentlink?.children)>0" class="hasSubmenu" >
                <a class="subParent" (click)="toggleSubmenu($event, 'showMenu')"  placement="right" ngbPopover="{{parentlink.name}}" popoverClass="popover-type1" container="body" triggers="hover">{{
                  parentlink.name
                  }}</a>
                <ul>
                  <ng-container *ngFor="let childlink of parentlink.children">
                    <li placement="right" ngbPopover="{{childlink.name}}" popoverClass="popover-type1" container="body" triggers="hover">
                      <a (click)="changeParentActiveClass()" class="subChild" routerLink="{{ childlink.url }}" routerLinkActive="active pointer-event">
                        {{ childlink.name }}</a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
            <ng-container *ngIf="env.name!='local'&& env.name!='development'">
             
              <li *ngIf="(parentlink?.children?.length <= 0 || countThirdLevel(parentlink?.children)==0) " placement="right" ngbPopover="{{parentlink.name}}" popoverClass="popover-type1" container="body" triggers="hover">
                <a routerLink="{{ parentlink.url }}" (click)="removeParentClassActive();" routerLinkActive="active">{{
                  parentlink.name
                  }}</a>
              </li>
              <li *ngIf="parentlink?.children?.length > 0  && countThirdLevel(parentlink?.children)>0" class="hasSubmenu" >
                <a class="subParent" (click)="toggleSubmenu($event, 'showMenu')"  placement="right" ngbPopover="{{parentlink.name}}" popoverClass="popover-type1" container="body" triggers="hover">{{
                  parentlink.name
                  }}</a>
                <ul>
                  <ng-container *ngFor="let childlink of parentlink.children">
                    <li placement="right" ngbPopover="{{childlink.name}}" popoverClass="popover-type1" container="body" triggers="hover">
                      <a (click)="changeParentActiveClass()" class="subChild" routerLink="{{ childlink.url }}" routerLinkActive="active pointer-event">
                        {{ childlink.name }}</a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
    </ng-template>
    <!-- <ul class="menu-list" [class.active]="selectedListItemIndex === 10" >
      <ng-container >

        <li class="hasSubmenu">
          <a class="subParent" (click)="toggleSubmenu($event, 'showMenu')" >My finances</a>
          <ul>
            <li>
              <a class="subChild" routerLink="/payslip" routerLinkActive="active ">Payslip</a>
            </li>
            <li>
              <a class="subChild" routerLink="/taxslip" routerLinkActive="active ">Tax slip</a>
            </li>
            <li>
              <a class="subChild" routerLink="form16" routerLinkActive="active ">Form 16</a>
            </li>
          </ul>
        </li>
        <li >
          <a routerLink="/holiday-calender" routerLinkActive="active">Holiday Calender</a>
        </li>
        <li >
          <a routerLink="/leave-balance" routerLinkActive="active">Leave Balance</a>
        </li>
      </ng-container>
    </ul> -->
    <ng-container *ngTemplateOutlet="menuRef; context: { $implicit: subMenuList }"></ng-container>
    <ul class="menu-list user-detail" [class.active]='selectedListItemIndex === 10' >
            <li class="" *ngIf="clicked">
                <a class="" >
                    <h6 class="user-name ">{{this.snapshotService.getCurrentUserName()}}</h6>
                    <span class="user-desg ">{{this.snapshotService.getCurrentUserDesignation()}}</span>
                </a>
                <!-- <div>
                    <i class="bi bi-box-arrow-in-left" (click)="doLogout()" ></i>
                </div> -->
            </li>
        </ul>
  </div>
</aside>
<!-- Profile Popover -->
  <!-- Change theme Sidepanel start-->
  <div class="side-panel change-theme-sidepanel" style="--sidepanel-width: 406px" [class.side-pane-active]="changeThemesidePanel === true">
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Color and Theme</h5>
        <a class="toggle-panel" (click)="changeThemesidePanel = false; this.changeThemesidePanel1.emit(false);"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto">
        <div class="container-fluid p-32">
          <div class="row row-16">
            <div class="col-4 text-center">
              <div class="theme-box theme-default mx-auto {{theme == 'skizzle-default' ? 'active': ''}}"
                (click)="theme = 'skizzle-default'">
                <div class="theme-primary"></div>
                <div class="theme-secondary"></div>
              </div>
              <div class="fw-500 fs-14 mt-8">Default</div>
            </div>
          </div>
        </div>
      </div>
      <footer class="side-panel-footer gap-32">
        <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="isCompleted" (click)="changetheme(theme);isCompleted=true;">Apply</button>
      </footer>
    </div>
  </div>
  
  <div *ngIf="showProgress &&  this.sds.BGPROGRESS" class="draggable-popup {{popupClass}} py-16 ps-16 pe-20" id="draggableElement">
    <div class="d-flex align-items-baseline h-100">
        <i *ngIf="popupClass =='popup-primary'" class="icon-arrow-repeat text-accent2 fs-14 me-8"></i>
        <i *ngIf="popupClass =='popup-danger'" class="bi bi-exclamation-triangle text-danger fs-14 me-8"></i>
        <i *ngIf="popupClass =='popup-success'" class="bi bi-check2-circle text-success fs-16 me-8"></i>
        <div class="d-flex flex-column w-100 h-100">
            <div class="d-flex mb-auto align-items-center mb-8" *ngIf="popupClass =='popup-primary'">
                <p class="mb-0 fs-14 lh-1 me-16">Salary {{this.taskText}}ing</p>
                <button class="ms-auto sq-30 radius-2 btn-accent2 btn-reset" (click)="this.router.navigateByUrl(this.sds.getProcessUrl());"><i
                        class="icon-chevron-right-lg fs-12"></i></button>
            </div>
            <div class="d-flex align-items-center mb-8" *ngIf="popupClass !='popup-primary'">
              <p class="mb-0 fs-14 lh-1 me-16">Salary Process {{processText}}</p>
              <i class="icon-close-lg fs-10 ms-auto pointer"   (click)="closeprogress()"></i>
            </div>
            <div *ngIf="popupClass =='popup-primary'" class="d-flex flex-column  gap-8">
              <div class="d-flex align-items-end">
                  <span class="fs-12">{{progPercentage| number : '1.2-2'}}% completed</span>
                  <span class="fs-10 ms-auto opacity-75">{{timeLeft}} remaining</span>
              </div>
              <ngb-progressbar class="progress-type4" [value]="progPercentage"></ngb-progressbar>
            </div>

            <div class="d-flex flex-column align-items-start gap-8" *ngIf="popupClass !='popup-primary'">
              <span class="mb-0 fs-12 text-trim">{{completeText}}</span>
              <button  *ngIf="popupClass !='popup-primary'" class="{{btnClass}} btn fs-10" style="max-height:2.5rem" (click)="viewDetails()">View Details</button>
          </div>
        </div>
    </div>
</div>

<app-error-download [fileName]="'Salary Process Error'" [successDataCount]="this.empCnt-saveData?.error_list?.length" [downloadMsg]="errorLog" [failedData]="exportData" [modalHeading]="'Process Salary'" (closePanel)="closeprogress()" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>

<!-- Attendance process -->

<div *ngIf="showProgressA&&  this.aps.BGPROGRESS" class="draggable-popup {{popupClassA}} py-16 ps-16 pe-20" id="draggableElement">
  <div class="d-flex align-items-baseline h-100">
      <i *ngIf="popupClassA =='popup-primary'" class="icon-arrow-repeat text-accent2 fs-14 me-8"></i>
      <i *ngIf="popupClassA =='popup-danger'" class="bi bi-exclamation-triangle text-danger fs-14 me-8"></i>
      <i *ngIf="popupClassA =='popup-success'" class="bi bi-check2-circle text-success fs-16 me-8"></i>
      <div class="d-flex flex-column w-100 h-100">
          <div class="d-flex mb-auto align-items-center mb-8" *ngIf="popupClassA =='popup-primary'">
              <p class="mb-0 fs-14 lh-1 me-16">Attendance Processing</p>
              <button class="ms-auto sq-30 radius-2 btn-accent2 btn-reset" (click)="this.router.navigateByUrl(this.aps.PROCESSURL);"><i
                      class="icon-chevron-right-lg fs-12"></i></button>
          </div>
          <div class="d-flex align-items-center mb-8" *ngIf="popupClassA !='popup-primary'">
            <p class="mb-0 fs-14 lh-1 me-16">Attendance Process {{processTextA}}</p>
            <i class="icon-close-lg fs-10 ms-auto pointer"   (click)="closeprogressA()"></i>
          </div>
          <div *ngIf="popupClassA =='popup-primary'" class="d-flex flex-column  gap-8">
            <div class="d-flex align-items-end">
                <span class="fs-12">{{progPercentageA| number : '1.2-2'}}% completed</span>
                <span class="fs-10 ms-auto opacity-75">{{timeLeftA}} remaining</span>
            </div>
            <ngb-progressbar class="progress-type4" [value]="progPercentageA"></ngb-progressbar>
          </div>

          <div class="d-flex flex-column align-items-start gap-8" *ngIf="popupClassA !='popup-primary'">
            <span class="mb-0 fs-12 text-trim">{{completeTextA}}</span>
            <button  *ngIf="popupClassA !='popup-primary'" class="{{btnClassA}} btn fs-10" style="max-height:2.5rem" (click)="viewDetailsA()">View Details</button>
        </div>
      </div>
  </div>
</div>
<app-error-download [fileName]="'Attendance Process Error'" [successDataCount]="this.empCntA-saveDataA?.error_list?.length" [downloadMsg]="errorLogA" [failedData]="exportDataA" [modalHeading]="'Attendance Process'" (closePanel)="closeprogressA()" [successMsg]="successMsgA" [errorMsg]="errorMsgA">  </app-error-download>


<app-progress-bar [showProgress]="showProgressB || empShift.RUNBG" [popupClass]="popupClassB" [taskText]="'Assign Shift'" [processText]="processTextB" [progPercentage]="progPercentageB" [timeLeft]="timeLeftB" [completeText]="completeTextB" [btnClass]="btnClassB" (viewDetails)="viewDetailsB()" (nav)="this.router.navigateByUrl('/employee-shift');closeprogressB()" (close)="closeprogressB()"></app-progress-bar>

<app-error-download [fileName]="'Assign Shift Error'" [successDataCount]="this.empCntB-saveDataB?.error_list?.length" [downloadMsg]="errorLogB" [failedData]="exportDataB" [modalHeading]="'Assign Shift'" (closePanel)="closeprogressB()" [successMsg]="successMsgB" [errorMsg]="errorMsgB">  </app-error-download>