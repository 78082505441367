import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationHierarchyRoutingModule } from './organization-hierarchy-routing.module';
import { OrganizationHierarchyComponent } from './organization-hierarchy/organization-hierarchy.component';

import { OrgchartModule } from '@dabeng/ng-orgchart';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';
@NgModule({
  declarations: [
    OrganizationHierarchyComponent
  ],
  exports: [
    OrganizationHierarchyComponent
  ],
  imports: [
    CommonModule,
    OrganizationHierarchyRoutingModule,
    OrgchartModule,
    MasterSelectModule
  ]
})
export class OrganizationHierarchyModule { }
