<div class="side-panel-body" style="overflow-y: auto;">
    <div class="container-fluid p-32 ">
        <div class="col-12">
            <h5>DETAILS</h5>
        </div>
        <div class="card card-c2 p-32">
            <div class="row  row-16">

                <div class="col-12 d-flex ">
                    <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
                </div>
                <div class="col-12 mt-8">
                    <h5 class="mb-8">Floating Holiday<span
                            *ngIf="data?.is_cancel ==true">&nbsp;Request Cancellation</span>&nbsp;Approval</h5>
                    <span class=" badge-custom {{data?.request_type_color_code}}" *ngIf="data?.is_cancel == false">Holiday Approval</span>
                            <span class=" badge-custom {{data?.request_type_color_code}}" *ngIf="data?.is_cancel ==true"> Floating Holiday Cancellation Request</span>
                </div>
                <app-c1-loader *ngIf="!holiday.holiday_name"></app-c1-loader>
                <div class="col-12">
                    <div class="card card-c2 details-card" *ngIf="holiday.holiday_name">
                        <div class="card-body indicater1 px-24 py-12 fs-12">
                            <!-- <div class="fw-600 pb-8">{{holiday.holiday_name}} </div> -->
                            <div class="row row-16">
                                <div class="col-12">
                                    <div class="detail-title fw-600">{{holiday.holiday_name}}</div>
                                    <div class="detail-desc">{{holiday?.holiday_date +', '+holiday?.day_of_week}}</div>
                                </div>
                                <div class="col-12" *ngIf="data?.is_cancel == true">
                                    <div class="detail-title">Reason for cancellation</div>
                                    <div class="detail-desc">{{data?.cancel_remark == null ? '-' :
                                        data?.cancel_remark}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="reqStatus == 'Pending'" class="col-12" [formGroup]="form">
                    <div class="col-12 mt-1">
                        <textarea name="" id="" rows="3"
                            placeholder="{{this.messageService.descriptiontext('comment')}}"
                            [ngClass]="{ 'is-invalid': f.comment.errors}" class="form-control textarea-comment"
                            formControlName="comment"></textarea>
                        <div *ngIf="f.comment.errors" class="invalid-feedback">
                            <div *ngIf="f.comment.errors?.maxlength">
                                {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="reqStatus == 'Pending'" class="col-12 d-flex gap-16">
                    <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                        (click)="confirm('Rejected')">Decline</button>
                    <button class=" btn btn-primary text-uppercase btn-sm fw-400"
                        (click)="confirm('Approved')">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>