import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EsichellansettingService } from 'src/app/@core/services/esi-chellan-setting.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-challan-employee-state-insurance',
  templateUrl: './challan-employee-state-insurance.component.html',
  styleUrls: ['./challan-employee-state-insurance.component.scss']
})

export class ChallanEmployeeStateInsuranceComponent implements OnInit {
  challansettingsView = false;
  loader                  = true;
  esigroupList:any        = [];
  rightsesigroup:any        = [];
  esigroupcomp:any          = [];
  appkey1:any             = [];
  assignees:any           = [];
  deleteClicked = false
  searchKeyword="";
  showSidePanel                  = false;
  nodata                         = false;
  esigroupPlaceholder:string      = this.messageService.searchdisplay('ESI group');
  searchlistdata:any             = [] ;
  status                         = true;
  deleteId:any                   = "";
  deleteToggle:any               = false;
  esigroupview:any                 = [];
  loader1                        = false;
  /********INFINITY SCROLL VARIABLES  ****/
  offset                  = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  esigroupDetailsList:any = [];
  infinityloader          = false;
  permissions:any         = [];
  statusBtn:any           = 'Active';
  iseditclicked= false
  filterPanel=false;
  filterData:any='';
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private EsichellansettingService:EsichellansettingService,
    public router: Router,
    public appService:AppService,
    public messageService : MessageService,
  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.esiGroupData();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.EsichellansettingService.getesigroupData(this.status,this.defaultlimit,this.offset,this.searchKeyword,'',this.filterData)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
   }

   getPermission(){
    this.permissions = this.appService.getPermissions('/challan-settings');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  esiGroupData(){
    this.loader = true;
    this.EsichellansettingService.getesigroupData(this.status,this.defaultlimit,this.offset,this.searchKeyword,'',this.filterData).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res.results.length;
    if(this.offset ==0){
      this.esigroupList = [];
    }
    if (res.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata = false;
      this.esigroupDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.esiGroupData();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; i++) {
      let flag =0;
      for(let j=0;j<this.esigroupList.length;j++){
        if(this.esigroupDetailsList[i]['id'] == this.esigroupList[j]['id'] ){
          flag ++;
        }
      }
      if(flag == 0)
        this.esigroupList.push(this.esigroupDetailsList[i]);
    }
  }

  addNewdata(){
    this.router.navigate(['/challan-settings/challan-settings-setup/esi']);
  }

  viewesigrp(id:any){
    if(this.permissions.v){
      this.challansettingsView = true;
      this.loader1       = true;
      this.assignees     = [];
      this.EsichellansettingService.getSingleesigroupData(id).subscribe((res: any) => {
        this.esigroupview = res;
        let key = res?.applicability?.applicable;
        this.assignees = res?.applicability[key];
        if(key == 'BU'){
          res.applicability['applicable']='Business Unit';
        }
        this.loader1 = false;
      })
    }
  }


  deletegrp(id:any){
    this.deleteClicked = true;
    let data = { "is_active": false};
    this.EsichellansettingService.deleteesigroup(id,data).subscribe((res: any) => {
      this.deleteClicked     = false;
      this.deleteToggle     = false;
      this.loader           = true;
      this.offset           = 0;
      this.esigroupList = [];
      this.esiGroupData();
    },
    (error:any)=>{
      this.deleteClicked     = false
      this.deleteToggle     = false;
    })
  }

  searchesigroup(searchKeyword:any){
    this.loader = true;
    this.isLoading = true;
    this.offset            = 0;
    this.esigroupList = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = ""
      this.offset = 0;
      this.esigroupList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  closeFilter(){
    this.filterPanel = false;
  }

  submitForm(data:any){
    this.filterPanel  = false;
     let companyList = data.companyList
     let buList = data.buList
     let BranchList = data.BranchList
     let cityList = data.cityList
     let status   = data.status
    this.filterData = `company=[${companyList}]&business_unit=[${buList}]&branch=[${BranchList}]&city=[${cityList}]&is_active=${status}`;
    this.offset       = 0;
    this.esiGroupData();
  }

  resetFilter(){
    this.filterData = '';
    this.child.clearBar();
    this.offset       = 0;
    this.filterPanel  = false
  }
}
