import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-fnf-check-in-request-approval',
  templateUrl: './fnf-check-in-request-approval.component.html',
  styleUrls: ['./fnf-check-in-request-approval.component.scss']
})
export class FnfCheckInRequestApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() requestitem:any;
  @Input() empId:any;
  @Input() archieved_status:any;

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  pageSize              = 20;
  page                  = 1
  btndisable            = false
  delArray : any        = [];

  request:any;
  individualStatus: any;
  viewDetail            = false;
  viewMapPanel: boolean = false;

  constructor(
    public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,
    public appService: AppService,
    private notificationService: NotificationService,
  ) { }

  form = this.fb.group({
    read_status       : [true,Validators.required],
    approved_status   : [false,Validators.required],
    comment           : ['', Validators.maxLength(500)],
  })

  ngOnInit(): void {
  }

  get f() {return  this.form.controls; }

  checkall(event:any){
  var newList   = this.requestitem?.requests
  var begin     = (this.page-1) * this.pageSize ;
  var end       = this.page* this.pageSize;
  var subArray  = newList?.slice(begin,end);
  subArray.forEach((item:any, key:any) => {
    item.checked = event.target.checked
  })
}

checkAllSelected(){
  var newList     = this.requestitem?.requests
  var begin       = (this.page-1) * this.pageSize ;
  var end         = this.page* this.pageSize;
  var subArray    = newList?.slice(begin,end);
  if(subArray?.length){
    return subArray.every((item: { checked: any,status:any }) => {
        return item.checked;
    });
  }else {
    return false;
  }
}

countSelected(){
  this.btndisable   = false
  var delArray:any  = [];
  this.requestitem?.requests?.forEach((item:any, value:any) => {

    if(item?.checked == true )
      delArray.push(value)
  });
  return delArray?.length
}

approveReject(val:any){
  if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
    this.unprocessWarningPopup.emit();
  }else{
    this.btndisable = true;
    if(this.countSelected().length == 0)
      return;
    if(val == 'Accepted'){
      this.action('Accepted');
    }else{
      this.action('Rejected');
    }
  }
}

action(value:any){
if(this.countSelected()?.length == 0){
  this.notificationService.handleErrorNotification("Please select data to accept or reject","Select data");
  return;
}
this.requestitem?.requests.forEach((item:any) => {
  if(value == 'Accepted' && item.checked == true ){
      this.updateOnduty(item.id,'Accepted');
    }else if(value == 'Rejected' && item.checked == true ){
      this.updateOnduty(item.id,'Rejected');
    }
});
this.notificationService.handleSuccessNotification("Updated Successfully","Success");

}

updateOnduty(id: any,val:any){
  this.finalSettlementService.updateFnFCheckInItem(id,`{"status":"${val}"}`).subscribe((res:any)=>{
    if(res){
      for(let i = 0;i<this.requestitem.requests.length;i++){
        if(this.requestitem.requests[i].id == id){
            this.requestitem.requests[i].status = val;
        }
      }
      this.updateCheckBoxes();
    }
  });
}

updateCheckBoxes(){
  this.requestitem?.requests.forEach((item:any) => {
   item.checked = false;
  });
}

showMap(req:any,status:any){
  this.request          = req;
  this.individualStatus = status;
}

confirm(){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      var status: any;
      var flag = true;
      for(let i = 0;i<this.requestitem?.requests?.length;i++){

        if(this.requestitem?.requests[i].status == 'Pending'){
          this.notificationService.handleErrorNotification("Check-in request cannot be submitted in pending state.","Pending Check-in Request");
          return;
        }

        if(flag == true && this.requestitem.requests[i].status == 'Accepted'){
          status = 'Accepted';
          flag = false;
        }
        if(flag == true && this.requestitem.requests[i].status == 'Rejected'){
          status = 'Rejected';
        }
      }
      if(status=='Accepted'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }

      if(this.form.controls.comment.valid){
        this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
            if(res){
              this.refreshInbox(false);
              this.form.get('comment')?.reset('')

            }
          })
      }
    }
  }

  refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

}
