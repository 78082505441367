import { Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { WeekoffService } from 'src/app/@core/services/weekoff.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-weekly-off',
  templateUrl: './weekly-off.component.html',
  styleUrls: ['./weekly-off.component.scss']
})
export class WeeklyOffComponent implements OnInit {

  searchlistdata     :any        = [];
  componentDetails   :any        = [];
  permissions        :any        = [];
  weekOffData        :any        = [];
  viewData           :any        = [];
  statusBtn          :any        = 'Active';
  status             :boolean    = true
  viewDetail         :boolean    = false;
  noData             :boolean    = false
  loader             :boolean    = true
  deleteAlert        :boolean    = false
  deleteClicked      :boolean    = false
  infinityloader     :boolean    = false
  vLoader            :boolean    = false
  deleteId           :any
  listLength         :any
  viewId             :any
  searchKeyword                  = ''
  direction                      = '';
  defaultlimit                   = 25;
  offset                         = 0
  weekOffPlaceHolder             = this.messageService.searchdisplay('weekly off');
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) search:any;

  constructor(
    public messageService : MessageService,
    public WeekoffService : WeekoffService,
    public appService     : AppService,
    public router         : Router
  ) { }

  ngOnInit(): void {
    // Permissions
    this.getPermission();
    // Week Off
    this.getWeekOffData(true)
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.WeekoffService.getWeekList(this.status,this.defaultlimit,this.offset,this.searchKeyword,'')
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  // Search
  searchresults(value:any){
    this.loader = true
    this.weekOffData = []
    if(value == ""){
      this.searchKeyword = "";
      this.searchData.next();
    }else{
      this.searchData.next();
    }
  }

  searchWeekOff(value:any){
    this.loader = true
    this.isLoading = true
    this.weekOffData = []
    this.searchKeyword = value
    if(this.searchKeyword != ""){
      this.searchData.next();
    }
  }

  // Filter
  statusfilter(value:any){
    this.status = value;
    this.weekOffData = []
    this.offset = 0
    this.search.clearBar();
  }

  // Week Off Data
  getWeekOffData(value:any){
    this.loader = true
    this.status = value
    this.WeekoffService.getWeekList(this.status,this.defaultlimit,this.offset,this.searchKeyword,'').subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listLength = res.body.results.length
    if(this.offset == 0){
      this.weekOffData = []
    }
    if(res.body.results.length == 0){
      this.noData = this.offset == 0 ? true : false
    }else{
      this.noData = false
      this.componentDetails = res.body.results
    }

    this.infinityloader = false
    this.addItems(this.listLength)
    this.loader = false
  }

  addItems(list:any){
    for(let i=0;i<list;i++){
      this.weekOffData.push(this.componentDetails[i])
    }
  }

  // Edit Week Off
  editWeekOff(id:any){
    this.router.navigate(['/weekly-off/weekly-off-setup/'+id])
  }

  viewWeek(id:any){
    if(this.permissions?.v){
      this.viewDetail = true
      this.vLoader = true
      this.WeekoffService.getOneWeekfOff(id).subscribe((res:any)=>{
        this.viewData = res.body;
        this.viewId = id
        this.vLoader = false
        this.AssignRights()
      })
    }
  }

  AssignRights(){
    this.viewData.applicable = this.viewData.weekoff_assign[0].applicable;
    this.viewData.applicableTo = [];
    this.viewData.weekoff_assign.forEach((item:any, key:any) => {
      if(this.viewData.applicable == "Company"){
      let compName = item.company;
      let compImg  = item.company_logo;
      this.viewData.applicableTo.push({'name': compName,'img': compImg})
      }else if(this.viewData.applicable == "Department")
        this.viewData.applicableTo.push(item.department);
      else if(this.viewData.applicable == "Designation")
        this.viewData.applicableTo.push(item.designation);
      else if(this.viewData.applicable == "Employee"){
      let empName = item.employee.first_name+' '+(item.employee.middle_name !=null? item.employee.middle_name+' ' : '')+item.employee.last_name +" ("+item.employee.employee_code+")";
      let empImg  =  item.employee.profile_image;
      let color_code  =  item.employee.color_code;
      this.viewData.applicableTo.push({'name': empName,'img': empImg,'color_code':color_code})
        // this.viewData.applicableTo.push(item.employee_name.name+" ("+item.employee_name.employee_code+")");
      }else if(this.viewData.applicable == "Grade")
        this.viewData.applicableTo.push(item.grade);
      else if(this.viewData.weekoff_assign[0].applicable == "BU")
        this.viewData.applicableTo.push(item.businessunit);
    });
    if(this.viewData.applicable == "BU")
     this.viewData.applicable = "Business Unit"

  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset         += this.defaultlimit;
    if(this.listLength){
      this.getWeekOffData(this.status);
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  // Delete Week Off
  deleteWeekOff(id:any){
    this.deleteClicked = true
    let data = {"is_active" : false}
    this.WeekoffService.deleteWeekOff(id,data).subscribe((res:any)=>{
      this.deleteClicked =  false
      this.deleteAlert = false
      this.loader = true
      this.offset = 0
      this.weekOffData = []
      this.getWeekOffData(this.status)
    })
  }

  // Permission
  getPermission(){
    this.permissions = this.appService.getPermissions('/weekly-off');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // function for add in no data screen

  addNewdata(){
    this.router.navigate(['/weekly-off/weekly-off-setup']);
  }
}
