import { Component, OnInit,ViewChild } from '@angular/core';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute,Params } from '@angular/router';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';
import { FormBuilder} from '@angular/forms';
@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss']
})
export class SalaryComponent implements OnInit {
  salaryRevisionHistory       = false;
  filter                      = false;
  employeeSlaryData:any       = [];
  currentStatus:boolean       = true;
  showSidePanel               = false;
  nodataFound                 = false;
  currentEmp                  = 0;
  salaryData :any             = [];
  loader                      = false;
  viewloader                  = false;
  offset                      = 0;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  designationDetailsList:any  = [];
  searchlistdata:any          = [] ;
  infinityloader              = false;
  empSalaryData:any           = [];
  permissions:any             = [];
  revisePermissions:any       = [];
  Isalert                     = true;
  employemntAlert             = true;
  showInfo                    = false;
  filterdata:any              =  this.salaryservice.FILTERDATA
  searchkey                   = this.messageService.searchdisplay('Employee')
  searchKeyword               = "";
  from                        = 'salary';
  applyfilter                 = false
  resetFilter                 = false


  gross_benefits:any;
  other_benefits:any;
  contributions:any;
  infoMsg:any;
  recurring_deductions:any;
  @ViewChild(SearchbarComponent) child:any;

  filterForm                = this.formBuilder.group({
    company               : '',
    bu                    : '',
    department            : '',
    designation           : '',
    grade                 : '',
    emp_status            : '',
    emp_type              : '',
    branch                : '',
    repomanager           :'',
    funmanager            :'',
    sortby                : ['All'],
  }
);
  constructor(private salaryservice:SalaryStructureService,private router:Router,public appService: AppService,public route:ActivatedRoute,public messageService : MessageService,
    private formBuilder: FormBuilder,
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['tab'])){
       if(params['tab']==1){

         this.currentStatus  = true;
         this.from           = 'salary'
       }
        else{
          this.currentStatus  = false;
          this.from           = 'salary_unassigned'
        }
      }

    })

    let arrFilter: any = []
    let arrList = []
    let arrData = []
   
    if(this.filterdata){
      arrData = this.filterdata.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })

      let ordering:any = this.appService.memOrdering1()
     
      this.filterForm.reset(this.appService.memFilterArray2(arrFilter,ordering));
    }
    this.getPermission();
    this.getRevisePermission();
    this.applyfilterfn();
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/salary-structure');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }

  }
  getRevisePermission(){
    this.revisePermissions = this.appService.getPermissions('/salary-revision');
    if(this.revisePermissions == undefined){
      setTimeout(() => {
        this.getRevisePermission();
      }, 1000);
    }

  }
  salaryFilter(status:any){
    if(this.currentStatus!=status){

      this.currentStatus      = status;
      this.employeeSlaryData  = [];
      this.salaryData         = [];
      this.offset             = 0;
      this.child.clearBar();
      this.searchlistdata = []
    }

  }
  result:any;
  result1:any;
  getEmployeeSalaryStruct(status:any){
      this.loader       = true;

      this.result=this.salaryservice.getEmployeeSalary(status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{
        this.salarygetfn1(res);
      })
  }


  salaryUpdate(sal_id:any)
  {
    if(this.currentStatus==true){
      this.viewloader = true;
      this.salaryservice.getSingleSalary(sal_id).subscribe((res:any)=>{

        this.empSalaryData = res.body;

        this.viewloader     = false;

        var gross =  this.empSalaryData.assign_salary.filter(function(gross_sal:any) {

          return gross_sal.component_type == "gross_benefits" && gross_sal?.is_display==true;;
        });
        var other =  this.empSalaryData.assign_salary.filter(function(other_sal:any) {

          return other_sal.component_type == "other_benefits" && other_sal?.is_display==true;;
        });
        var contibution =  this.empSalaryData.assign_salary.filter(function(contribution_sal:any) {

          return contribution_sal.component_type == "contributions" && contribution_sal?.is_display==true;;
        });
        var recurring =  this.empSalaryData.assign_salary.filter(function(recurring_sal:any) {

          return recurring_sal.component_type == "recurring_deductions" && recurring_sal?.is_display==true;;
        });

        this.gross_benefits       = gross;
        this.other_benefits       = other;
        this.contributions        = contibution;
        this.recurring_deductions = recurring;
      })

    }

    if(this.currentStatus==true && this.permissions.v==true){
         this.showSidePanel=true
    }
    if(this.currentStatus==false && this.permissions.a==true)
    this.router.navigate(['/salary-structure/assign-salary-structure/'+sal_id]);
    this.currentEmp    = sal_id;

  }
  onScrollDown() {
    this.infinityloader     = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.applyfilterfn();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader  = false;
    }
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.salaryData.push(this.employeeSlaryData[i]);
    }
  }
  searchresults(event:any){
    this.loader            = true;
    this.salaryData        = [];

    if(event == ""){
      this.searchKeyword  = "";

      this.applyfilterfn();

    }else{
      this.salaryservice.getSingleSalary(event).subscribe((res:any)=>{
        this.salaryData       = [res.body];
        this.loader           = false;
      })
    }
  }
  sLoading = false
  searchsalary(searchKeyword:any){
    this.sLoading = true
    this.loader = true;
    this.offset            = 0;
    this.salaryData= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.applyfilterfn();
    }
  }
  monthManipulation(data:any){
    data = data.toString();
    data = data.replace(/,/g, ", ");
    return data;
  }
  salarygetfn1(key1:any){
    this.sLoading = false
    this.loader           = false;
     let res = key1;
      this.listlength       = res.body.results.length;
      if(this.offset ==0){
        this.salaryData = [];
      }
      if (this.listlength == 0) {
        this.nodataFound = this.offset == 0 ? true :false;
      }else {
        this.nodataFound       = false;
        this.employeeSlaryData = res.body.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
        
    }
  
  resultfilterfn(data:any){
    this.filterdata  = data;
    this.salaryservice.setFilterData(data)
    this.offset      = 0;
    this.applyfilter = true;
    this.child.clearBar();

    this.applyfilterfn();
  }
  resetCalled(event:any){
    this.resetFilter = event;
  }
  applyfilterfn(){
    this.loader = true;
    this.nodataFound       = false;
    if(this.currentStatus==false && this.filterdata==''){
      let data          = ['Probation','Confirmed','Resigned']
      this.filterdata   = 'employee__employment_status__in='+data
    } 


    this.salaryservice.getfilterdata(this.currentStatus,this.defaultlimit,this.offset,this.searchKeyword, this.filterdata).subscribe((res:any)=>{
      this.salarygetfn1(res);
    })

    this.filter = false;
   }
   filterclick(){
    this.filter = true;
    if(this.currentStatus==true)
    this.from           = 'salary'

   else
     this.from           = 'salary_unassigned'

   }
   closefn(daata:any){
    this.filter = daata;
   }
   reviseSalary(status:any,id:any){
    if(status==false)
    this.router.navigate(['/salary-revision/salary-revision-setup/'+id]);
    else{
      this.infoMsg  ='The salary revision request for '+this.empSalaryData.employee_data?.first_name+" "+(this.empSalaryData.employee_data?.middle_name!=null?this.empSalaryData.employee_data?.middle_name:'')+" "+this.empSalaryData.employee_data?.last_name+ '('+this.empSalaryData.employee_data?.employee_code+')'+' has already been raised and is awaiting for approval.';
      this.showInfo = true;

    }

   }
   closeInfo(bool:any){
    this.showInfo = false

   }
}
