import { Component, OnInit, EventEmitter, Input, Inject, Output, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MessageService } from 'src/app/message.global';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';

@Component({
  selector: 'app-lr-leave-details',
  templateUrl: './lr-leave-details.component.html',
  styleUrls: ['./lr-leave-details.component.scss']
})

export class LrLeaveDetailsComponent implements OnInit {

  leaveRule = false;
  leaveBalance = false;
  leaveType: any = [];
  submitted = false;
  fileType: string = "";
  docURL: any = "";
  docName: any = "";
  fileSize: any = "";
  docLoader = false;
  fileError = false;
  dateDiff: any = "";
  fromLeavePortion: any = 'Full Day';
  toLeavePortion: any = 'Full Day';
 @Input() employee:any
  leaveTypeBalanceDataTile: any[] = [];
  leaveBalanceTable: any[] = [];
  laeveBalanceDate: any = "";
  loader = true;
  errorPass = "";
  invalidPass = false;
  alertToggle = false;
  from_halfDay: number = 0;
  to_halfDay: number = 0;
  leaveDates: number = 0;
  weekoff :any  = [];
  holiday = [];
  toWeekOff :any = [];
  toHoliday = [];
  dateTxt: any = [];
  toDateTxt: any = [];
  weekoffArray: any = [];
  holidayArray: any = [];
  toWeekoffArray: any = [];
  toHolidayArray: any = [];
  leaveRuleData: any = [];
  leaveClubbedData: any = [];
  portionDataArray: any = [
    {
      'date': null,
      'portion': "",
    },
    {
      'date': null,
      'portion': "",
    }
  ];
  portionDropdwn :  any = []
  currentYear: number = new Date().getFullYear();
  validationData: any = [];
  selectedLevel: any;
  fromMinDate = new Date(new Date().getFullYear() - 5, 11, 31);
  toMinDate = new Date(new Date().getFullYear() - 5, 11, 31);

  // Comp Off
  compOffList: any[] = [];
  leaveName: any = '';
  disabled = false;
  selectedCompOffDayNumbers: any = [];
  selectedCompOffDateAndNum: any = [];

  // Penality
  penalityDates: any = [];
  isPenality = false;
  penalityDays: any = [];

  document_required = false;

  // File upload
  modalToggle                     = false;
  allowedExtension                = "";
  alertMsg:any                    = '';
  alertToggle1                    = false;
  fileName                        = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;

  @Output() submitmethod = new EventEmitter();
  @Output() validationError = new EventEmitter();
  @Output() validationResult = new EventEmitter();

  // test start
  @Output() overtimeCompoffError   = new EventEmitter();
  @Output() validationPortionResult = new EventEmitter();

  validationPortionData:any         = [];
  invalidPass1                      = false;
  errorPass1                        = '';

  overtimeCompOffValidation         = false;
  overtimeCompOffValidationMsg: any = '';
  // test end
  penalityCount = 0

  saturday = 6;
  fileErrorMsg: any = "";

  startDate = new Date();

  fromDateFilter = (date: Date | null): boolean => { return true; }
  toDateFilter = (date: Date | null): boolean => { return true; }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const d = moment(cellDate).date();
    if (view === 'month') {
      const weekoff = this.weekoffArray.find((weekoff:any) => weekoff.date == d)
      return weekoff
      ? weekoff.portion === 'Full Day'
        ? 'status-week-off'
        : weekoff.portion === 'First Half'
        ? 'status-first-week-off'
        : 'status-second-week-off'
      : this.holidayArray.indexOf(d) !== -1
      ? 'status-holiday'
      : '';
    }
    return '';
  };

  toDateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const d = moment(cellDate).date();
    if (view === 'month') {
      const weekoff = this.weekoffArray.find((weekoff:any) => weekoff.date == d)
      return weekoff
      ? weekoff.portion === 'Full Day'
        ? 'status-week-off'
        : weekoff.portion === 'First Half'
        ? 'status-first-week-off'
        : 'status-second-week-off'
      : this.holidayArray.indexOf(d) !== -1
      ? 'status-holiday'
      : '';
    }
    return '';
  };
  appendTypeIndicator() {
    const htmlContent = `
    <div class="d-flex flex-wrap w-100 px-16 pb-16 gap-16 fs-12 fw-600">
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-weekoff"></span>WEEKEND
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-holiday"></span>HOLIDAY
      </div>
    </div>
  `;
    const newDiv = this.renderer.createElement('div');
    newDiv.innerHTML = htmlContent;
    setTimeout(() => {
      if (document.getElementsByTagName('mat-datepicker-content')) {
        var element = document.getElementsByTagName('mat-datepicker-content');
        element[0]?.appendChild(newDiv);
      }
    })
  }
  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public fb: FormBuilder,
    private investmentService: InvestmentDeclerationService,
    public appservice: AppService,
    private authService: AuthService,
    private renderer: Renderer2,
    public messageService: MessageService,
    private leavetypeService: leavetypeService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.getDateFormat();


    this.docName = window.sessionStorage.getItem('this.docName');
    this.fileSize = window.sessionStorage.getItem('this.fileSize');
    this.dateDiff = window.sessionStorage.getItem('this.dateDiff');

    if (this.formGp.get('compoff_date')?.value?.length > 0) {
      this.selected();
    }

    if (this.formGp.value.portion?.length != 0) {
      if (this.formGp.value.portion?.length == 1) {
        this.fromLeavePortion = this.formGp.value.portion[0]['portion'];
        // this.toLeavePortion   = 'First Half Day';
      } else {
        this.fromLeavePortion = this.formGp.value.portion[0]['portion'];
        this.toLeavePortion = this.formGp.value.portion[1]['portion'];
      }

      this.getWeekOffHolidayList(moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").month() + 1, moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").year());

      this.getToWeekOffHolidayList(moment(this.appservice.dateFormatConvert(this.formGp.value.to_date), "YYYY-MM-DD").month() + 1, moment(this.appservice.dateFormatConvert(this.formGp.value.to_date), "YYYY-MM-DD").year());

    }else{
      this.fromLeavePortion = 'Full Day';
      this.toLeavePortion = 'Full Day';

      this.weekOffHolidayList(this.dateTxt);
      this.toWeekOffHolidayList(this.toDateTxt);

    }

    // To check the document validator
    if(this.formGp.get('document')?.hasValidator(Validators.required))
    this.document_required = true;
    else
    this.document_required = false;
    //End

    this.getLeaveType();

    // To check penality dates
    if(this.formGp.value?.penalty_dates?.length > 0){
      this.getDayName(this.formGp.value)
      this.penalityCount = this.formGp.value?.penality_count
    }else{
      this.isPenality = false;
    }

    if(this.formGp.value?.validateDuration){
      this.portionDropdwn = this.formGp.value?.validateDuration
    }
    // End
  }

  @Input() formGp: FormGroup = this.fb.group({
    leave_type: ['', [Validators.required]],
    from_date: ['', [Validators.required]],
    to_date: ['', [Validators.required]],
    reason: ['', [Validators.required, Validators.maxLength(100)]],
    document: [''],
    // portion     : [''],
    leave_policy: [''],
    portion: [],
    leave_num_days: [0],
    leave_dates: [],
    compoff_date: [],
    compoff_number: [],
    leave_name: [],
    compoff_date_num: [],
    penalty_dates: [],
    validateDuration : []
  });

  getLeaveType() {
    this.investmentService.getleavetypeList(this.employee).subscribe((res: any) => {
      this.leaveType = res;
    })
  }

  disableDate() {
    return false;
  }

  get f() { return this.formGp.controls; }

  getValidationData() {
    this.removeValidation();
    this.validationError.emit(false);
    if (this.formGp.value.leave_type != null && this.formGp.value.from_date != '' && this.formGp.value.to_date != '') {
      this.penalityDates = [];

      this.investmentService.leaveRequestValidation({ 'leave_type': this.formGp.value.leave_type, 'employee': this.employee, 'from_date': this.appservice.dateFormatConvert(this.formGp.value.from_date), 'to_date': this.appservice.dateFormatConvert(this.formGp.value.to_date), 'portion': this.formGp.value.portion }).subscribe((res: any) => {

        this.validationData = res?.data;

        // To check the document is required or not
        if (this.validationData?.doc_required)
          this.formGp.get('document')?.setValidators([Validators.required]);
        else
          this.formGp.get('document')?.clearValidators();
        this.formGp.get('document')?.updateValueAndValidity();
        this.document_required = this.validationData?.doc_required;
        // End

        // To check the penality
        if (this.validationData?.penalty_dates?.length > 0) {
          this.getDayName(this.validationData);
          for (let i = 0; i < this.validationData?.penalty_dates?.length; i++) {
            let penalityDate = {
              date : this.appservice.dateFormatConvert(this.validationData?.penalty_dates[i]?.date),
              portion : this.validationData?.penalty_dates[i]?.portion
            }
            this.penalityDates?.push(penalityDate)
          }
          this.penalityCount = this.validationData?.penalty
          this.formGp.get('penalty_dates')?.setValue(this.penalityDates);
          this.formGp.get('penality_count')?.setValue(this.penalityCount)
        } else {
          this.isPenality = false;
          this.formGp.get('penalty_dates')?.setValue('');
        }
        // End

        this.checkOvertimeAndCompOff();
        this.formGp.get('leave_dates')?.setValue(res?.data?.leave_dates);
        this.dateDiff = Number(res.data.leave_dates.length) - (this.from_halfDay + this.to_halfDay);
        this.calculateNumberOfDays();
        window.sessionStorage.setItem('this.dateDiff', this.dateDiff);
        this.validationResult.emit({ 'ValidationResult': res.data, 'total_days': this.dateDiff });
        this.leaveDates = res.data.leave_dates.length;
      },
        (error: any) => {
          this.errorPass = error.error.error;
          this.invalidPass = true;
          this.alertToggle = true;
          this.validationError.emit(true);
        })

    }
  }

  validateLeaveDetails() {
    if (this.invalidPass && !this.formGp.invalid) {
      this.validationError.emit({'error': true, 'errorMsg': this.errorPass});
      return;
    }
    if (this.invalidPass1) {
      this.alertToggle = true;
      this.errorPass = this.errorPass1;
      this.validationError.emit({'error': true, 'errorMsg': this.errorPass1});
      return;
    }if((this.formGp.get('leave_name')?.value == 'Compensatory off') && (Number(this.formGp.get('compoff_number')?.value) != Number(this.formGp.get('leave_num_days')?.value))){
      this.alertToggle = true;
      this.errorPass   = 'The number of leave days selected should be equal to the number of comp off balance days selected.';
      this.validationError.emit({'error': true, 'errorMsg': this.errorPass});
      return;
    }
    if(this.isApi2){
      return;
    }
    if (this.formGp.invalid) {
      return;
    }
    // this.formGp.get('portion')?.setValue(this.portionDataArray);
    this.submitted = true;
    this.submitmethod.emit({ 'total_days': this.formGp.get('leave_num_days')?.value });
  }

  getURL(event: any) {
    this.docURL = event;
    this.fileError = false;
    this.formGp.get('document')?.setValue(event);
    this.docLoader = false;
  }

  getfileformat(event: any) {
    this.formGp.get('document')?.setValue('');
    this.docLoader = false;
    if (event == false) {
      this.fileError = true;
      this.fileErrorMsg = this.messageService.validateFileFormat('pdf,doc');
    }
  }

  getvalidsize(event: any) {
    this.formGp.get('document')?.setValue('');
    this.docLoader = false;
    this.fileError = true;
    this.fileErrorMsg = event;
  }

  deleteProfilePic(event: any) {
    this.formGp.get('document')?.setValue('');
    this.docURL = '';
  }

  getFilename(event: any) {
    this.docName = event.name;
    this.fileSize = this.appservice.formatSizeUnits(event.size);
    window.sessionStorage.setItem('this.fileSize', this.fileSize);
    window.sessionStorage.setItem('this.docName', event.name);
  }

  getloader(event: any) {
    this.docLoader = event;
  }


  leaveBalanceFunction() {
    this.leaveBalance = true;
    this.loader = true;

    if (this.laeveBalanceDate == "") {
      this.laeveBalanceDate = this.appservice.dateFormatConvert(new Date());
    }

    this.investmentService.leaveTransaction(this.employee, this.laeveBalanceDate).subscribe((res: any) => {
      this.loader = false;
      this.leaveBalanceTable = res?.data?.leave_transaction_data;
      this.leaveTypeBalanceDataTile = res?.data?.leave_balance_data;
    })

  }

  leaveBalanceViewClose(data: any) {
    this.leaveBalance = data;
  }

  openCalendar(data: any) {


    if(data == 'todate'){
      this.startDate = new Date();
      if(this.formGp.value.to_date != ''){
        this.startDate = new Date( moment(this.appservice.dateFormatConvert(this.formGp.value.to_date), "YYYY-MM-DD").year(),moment(this.appservice.dateFormatConvert(this.formGp.value.to_date), "YYYY-MM-DD").month(),moment(this.appservice.dateFormatConvert(this.formGp.value.to_date), "YYYY-MM-DD").day());
      }else{
        this.startDate =  new Date( moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").year(),0,moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").month() + 1);
      }

    }
    setTimeout(() => {
      const buttons = document.querySelectorAll
        ('.mat-calendar-previous-button,.mat-calendar-next-button')
      if (buttons) {
        Array.from(buttons).forEach(button => {
          this.renderer.listen(button, "click", (event) => {
            const date = new Date();
            let elements = document.querySelectorAll(".mat-calendar");
            const cells = elements[0].querySelectorAll(".mat-calendar-body-cell");
            if (data == 'fromdate')
              this.dateTxt = [];
            else
              this.toDateTxt = [];
            cells.forEach(x => {

              const date = new Date(String(x.getAttribute("aria-label")));
              const dateTxt =
                date.getFullYear() +
                "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("00" + date.getDate()).slice(-2);
              if (data == 'fromdate')
                this.dateTxt.push(dateTxt);
              else
                this.toDateTxt.push(dateTxt);
            });
            if (data == 'fromdate')
              this.weekOffHolidayList(this.dateTxt);
            else
              this.toWeekOffHolidayList(this.toDateTxt);
          });
        })
      }
    })
  }


  weekOffHolidayList(date: any) {
    let year = 0;
    let month = 0;

    if (this.dateTxt.length == 0) {
      this.dateTxt = this.appservice.dateFormatConvert(new Date());
    } else {
      this.dateTxt = this.dateTxt[0];
    }

    year = moment(this.dateTxt, "YYYY-MM-DD").year();
    month = moment(this.dateTxt, "YYYY-MM-DD").month() + 1;

    this.getWeekOffHolidayList(month, year);

  }

  getWeekOffHolidayList(month: any, year: any) {
    this.weekoffArray = [];
    this.holidayArray = [];
    this.weekoff = [];
    this.holiday = [];

    this.investmentService.getWeekOffHolidayList({ 'employee': this.employee, 'month': month, 'year': year }).subscribe((res: any) => {
      this.weekoff = res.weekoff;
      this.holiday = res.holiday;
      this.fromDateFilter = (date: Date | null): boolean => {
        this.weekoffArray = [];
        this.holidayArray = [];
        for (let i = 0; i < this.weekoff.length; i++) {
          const weekoff = {
            date: moment(this.weekoff[i]?.date, "YYYY-MM-DD").date(),
            portion: this.weekoff[i]?.portion
          };
          this.weekoffArray.push(weekoff);
        }
        for (let i = 0; i < this.holiday.length; i++) {
          const holiday = moment(this.holiday[i], "YYYY-MM-DD").date();
          this.holidayArray.push(holiday);
        }

        const a = this.appservice.dateFormatConvert(date);
        const d = moment(date, "YYYY-MM-DD").date();
        const weekIndex = this.weekoffArray.findIndex((weekoff:any) => weekoff.date == d && weekoff.portion === 'Full Day')
        return weekIndex == -1 && this.holidayArray.indexOf(d) == -1;
      };
    })
  }

  toWeekOffHolidayList(date: any) {
    let year = 0;
    let month = 0;

    if (this.toDateTxt?.length == 0) {
      this.toDateTxt = this.appservice.dateFormatConvert(new Date());
    } else {
      this.toDateTxt = this.toDateTxt[0];
    }

    year = moment(this.toDateTxt, "YYYY-MM-DD").year();
    month = moment(this.toDateTxt, "YYYY-MM-DD").month() + 1;

    this.getToWeekOffHolidayList(month, year);
  }

  getToWeekOffHolidayList(month: any, year: any) {

    this.toWeekoffArray = [];
    this.toHolidayArray = [];
    this.toWeekOff = [];
    this.toHoliday = [];

    this.investmentService.getWeekOffHolidayList({ 'employee': this.employee, 'month': month, 'year': year }).subscribe((res: any) => {
      this.toWeekOff = res.weekoff;
      this.toHoliday = res.holiday;
      this.toDateFilter = (date: Date | null): boolean => {
        this.toWeekoffArray = [];
        this.toHolidayArray = [];
        for (let i = 0; i < this.toWeekOff.length; i++) {
          const weekoff = {
            date: moment(this.toWeekOff[i]?.date, "YYYY-MM-DD").date(),
            portion: this.toWeekOff[i]?.portion
          };
          this.toWeekoffArray.push(weekoff);
        }
        for (let i = 0; i < this.toHoliday.length; i++) {
          const holiday = moment(this.toHoliday[i], "YYYY-MM-DD").date();
          this.toHolidayArray.push(holiday);
        }
        // const d = moment(date,"YYYY-MM-DD").date();
        const a = this.appservice.dateFormatConvert(date);
        const d = moment(date, "YYYY-MM-DD").date();
        const weekIndex = this.toWeekoffArray.findIndex((weekoff:any) => weekoff.date == d && weekoff.portion === 'Full Day')
        return weekIndex == -1 && this.toHolidayArray.indexOf(d) == -1;

      };

    })
  }

  dateFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 5, 0, 1),
    new Date(new Date().getFullYear() + 5, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  dateChangeFunctionLeaveBalance(e: any) {

    this.laeveBalanceDate = this.appservice.dateFormatConvert(e.target.value);
    this.leaveBalanceFunction();

  }

  portions(): FormArray {
    return this.formGp.get("portion") as FormArray
  }

  newPortion(data: any): FormGroup {
    return this.fb.group({
      "date": data?.date,
      "portion": data?.portion,
    })
  }

  addPortion(data: any) {
    this.portions().push(this.newPortion(data));
  }

  removePortion(i: number) {
    this.portions().removeAt(i);
  }

  removeAllPortion() {
    if (this.formGp.value.portion?.length > 0) {
      while (this.formGp.value.portion?.length !== 0) {
        this.portions().removeAt(0);
      }
    }
  }

  dateChangeFunction(e: any, fromToDate: any) {

    this.removeValidation();
    this.isPenality = false;

    if (fromToDate == 'fromDate') {
      this.formGp.get('to_date')?.setValue('');

      this.toMinDate = this.formGp.get('from_date')?.value;
      this.fromLeavePortion = 'Full Day';
      this.toLeavePortion = 'Full Day';
      this.formGp.get('leave_num_days')?.setValue(0)

      this.startDate = new Date( moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").year(),0,moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").month() + 1);

      this.getToWeekOffHolidayList(moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").month() + 1, moment(this.appservice.dateFormatConvert(this.formGp.value.from_date), "YYYY-MM-DD").year());


      // If array length zero
      if (this.formGp.value.portion?.length == 0) {
        this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.from_date), 'portion': this.fromLeavePortion })
      } else {
        // from and to dates are same

        if (this.appservice.dateFormatConvert(this.formGp.value.from_date) == this.appservice.dateFormatConvert(this.formGp.value.to_date)) {
          this.removeAllPortion();
          this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.from_date), 'portion': this.fromLeavePortion })
        } else if (this.appservice.dateFormatConvert(this.formGp.value.from_date) != this.appservice.dateFormatConvert(this.formGp.value.to_date)) {
          // from and to dates are different
          if (this.formGp.value.to_date != '' && this.formGp.value.to_date != null) {
            // Case1:- to date is not empty and array length is 1 (ie, from and to date was same)
            if (this.formGp.value.portion?.length == 1) {
              this.removeAllPortion();
              this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.from_date), 'portion': this.fromLeavePortion })
              this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.to_date), 'portion': this.toLeavePortion })
            } else {
              // Case2:- to date is not empty and array length is 2 (ie, from and to date was different)
              (<FormArray>this.formGp.get("portion")).at(0)?.get('date')?.setValue(this.appservice.dateFormatConvert(this.formGp.value.from_date));
              (<FormArray>this.formGp.get("portion")).at(0)?.get('portion')?.setValue(this.fromLeavePortion);
            }

          } else {
            (<FormArray>this.formGp.get("portion")).at(0)?.get('date')?.setValue(this.appservice.dateFormatConvert(this.formGp.value.from_date));
            (<FormArray>this.formGp.get("portion")).at(0)?.get('portion')?.setValue(this.fromLeavePortion);
          }
        }
      }
    } else if (fromToDate == 'toDate') {

      // If array length zero
      if (this.formGp.value.portion?.length == 0) {
        this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.to_date), 'portion': this.toLeavePortion })
      } else {
        // from and to dates are same
        if (this.appservice.dateFormatConvert(this.formGp.value.from_date) == this.appservice.dateFormatConvert(this.formGp.value.to_date)) {
          this.removeAllPortion();
          this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.to_date), 'portion': this.toLeavePortion })
        } else {

          if (this.formGp.value.from_date != '' && this.formGp.value.from_date != null) {
            // Case1:- from date is not empty and array length is 1 (ie, from and to date was same)
            if (this.formGp.value.portion?.length == 1) {
              this.removeAllPortion();
              this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.from_date), 'portion': this.fromLeavePortion })
              this.addPortion({ 'date': this.appservice.dateFormatConvert(this.formGp.value.to_date), 'portion': this.toLeavePortion })
            } else {
              // Case2:- from date is not empty and array length is 2 (ie, from and to date was different)
              (<FormArray>this.formGp.get("portion")).at(1)?.get('date')?.setValue(this.appservice.dateFormatConvert(this.formGp.value.to_date));
              (<FormArray>this.formGp.get("portion")).at(1)?.get('portion')?.setValue(this.toLeavePortion);
            }
          } else {
            (<FormArray>this.formGp.get("portion")).at(0)?.get('date')?.setValue(this.appservice.dateFormatConvert(this.formGp.value.to_date));
            (<FormArray>this.formGp.get("portion")).at(0)?.get('portion')?.setValue(this.toLeavePortion);
          }
        }
      }
    }
    // this.getValidationData();

    this.dateValidations();

  }

  portionDropDown(from_to: any, portion: string) {
    if (from_to == 'from') {
      this.fromLeavePortion = portion;
      (<FormArray>this.formGp.get("portion")).at(0)?.get('portion')?.setValue(this.fromLeavePortion);
    } else {
      this.toLeavePortion = portion;
      (<FormArray>this.formGp.get("portion")).at(1)?.get('portion')?.setValue(this.toLeavePortion);
    }
    // this.getValidationData();
    // this.calculateNumberOfDays();

    // this.portionValidation();

    this.dateValidations();
  }

  calculateNumberOfDays() {
    let num = 0;
    let fromDayPortion = (this.fromLeavePortion == 'First Half Day' || this.fromLeavePortion == 'Second Half Day' ? 0.5 : this.fromLeavePortion == 'Full Day' ? 0 : 0);
    let toDayPortion = (this.toLeavePortion == 'First Half Day' || this.toLeavePortion == 'Second Half Day' ? 0.5 : this.toLeavePortion == 'Full Day' ? 0 : 0);

    // When the from date and to dates are same.
    if (this.appservice.dateFormatConvert(this.formGp.value.from_date) == this.appservice.dateFormatConvert(this.formGp.value.to_date)){
      toDayPortion = 0;
    }
    // End
    let calculatePortionValue = (dates: any[]) => {
      if (!dates || dates.length === 0) {
        return 0;
      }
      return dates.reduce((acc, curr) => {
        return acc + 1;
      }, 0);
    };
    num = (Number(this.formGp.get('leave_dates')?.value?.length) + Number(calculatePortionValue(this.formGp.get('penalty_dates')?.value))) - Number(fromDayPortion + toDayPortion);
    this.formGp.get('leave_num_days')?.setValue(Number(num));
    this.docRequiredFunction();
  }

  // Date Format
  getDateFormat() {
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if (this.matDateFormat.display.dateInput == '') {
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  getLeaveRule() {

    this.leaveClubbedData = [];
    this.leaveRule = true;
    this.loader = true;

    this.investmentService.getLeaveRule(this.employee, this.formGp.get('leave_type')?.value).subscribe((res: any) => {
      this.loader = false;
      this.leaveRuleData = res?.data?.[0];

      if (this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.length > 0) {
        for (let i = 0; i < this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.length; i++) {
          this.leaveClubbedData?.push(this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.[i]?.leave_name + ' (' + this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.[i]?.leave_code + ')')
        }
      }

    })

  }

  getLeaveClubbedData(data: any) {
    let a = []
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        a?.push()
      }
    }
  }

  selected() {

    this.removeValidation();
    this.isPenality = false;

    // this.getValidationData();
    this.dateValidations();

    this.leavetypeService.getLeaveType(this.formGp.get('leave_type')?.value).subscribe((res: any) => {
      this.formGp.get('leave_name')?.setValue(res?.leave_name);
      if (this.formGp.get('leave_name')?.value == 'Compensatory off') {

        this.formGp.get("compoff_date")?.setValidators([Validators.required]);
        this.formGp.get("compoff_date")?.updateValueAndValidity();


        this.investmentService.getCompOffList(this.employee,'profile').subscribe((res: any) => {

          this.compOffList = res;

          this.compOffDropdown();
        })
      } else {
        this.formGp.get("compoff_date")?.clearValidators();
        this.formGp.get("compoff_date")?.updateValueAndValidity();
        this.compOffList = [];
        this.formGp.get('compoff_date')?.setValue([]);
      }

    });
    // this.leaveName = this.leaveType.filter((v :any)=> v?.id == this.formGp?.get('leave_type')?.value)[0]?.leave_name;

  }

  compOffDropdown() {

    if (this.compOffList?.length > 0) {

      for (let i = 0; i < this.compOffList?.length; i++) {

        let attendance_date = this.appservice.dateFormatDisplay(this.compOffList?.[i]?.attendance_date);
        let attendance_day = this.compOffList?.[i]?.eligible_compoff == 'Full Day' ? '1 day' : '0.5 day';
        let attendance_count = this.compOffList?.[i]?.eligible_compoff == 'Full Day' ? 1 : 0.5;
        let compOff_Date = attendance_date + ' - ' + attendance_day;

        this.compOffList[i] = { "attendance_date": this.compOffList?.[i]?.attendance_date, "eligible_compoff": this.compOffList?.[i]?.eligible_compoff, 'compOff_date': compOff_Date, 'compOff_day': attendance_day, 'compOff_count': attendance_count }

      }

      this.compOffList = this.compOffList.sort((a:any,b:any)=>{
        let first      = a?.attendance_date
        let second     = b?.attendance_date
        return new Date(first).valueOf() - new Date(second).valueOf();
      });
    }

  }

  getCompOffDayCount(e: any) {

    this.selectedCompOffDayNumbers = [];
    this.selectedCompOffDateAndNum = [];

    if (this.formGp?.get('compoff_date')?.value?.length == e?.length) {
      for (let i = 0; i < e?.length; i++) {
        if (this.formGp?.get('compoff_date')?.value?.[i] == e?.[i]?.['attendance_date']) {
          this.selectedCompOffDayNumbers.push(e?.[i]?.compOff_count);
          this.selectedCompOffDateAndNum?.push(e?.[i]?.compOff_date);
        }
      }
      this.formGp.get('compoff_date_num')?.setValue(this.selectedCompOffDateAndNum);
      if (this.selectedCompOffDayNumbers?.length > 0) {
        const sumInArray = this.selectedCompOffDayNumbers.reduce((a: any, b: any) => a + b);
        this.formGp.get('compoff_number')?.setValue(sumInArray);
        if (Number(this.formGp.get('compoff_number')?.value) != Number(this.formGp.get('leave_num_days')?.value)) {
          // this.invalidPass = true;
          // this.alertToggle = true;
          this.errorPass = 'The number of leave days selected should be equal to the number of comp off balance days selected.';
          this.validationError.emit({'error': true, 'errorMsg': this.errorPass});
        }else{
          this.invalidPass = false;
          this.alertToggle = false;
          this.errorPass = '';
          this.validationError.emit({'error': false, 'errorMsg': ''});
        }
      } else {
        this.invalidPass = false;
        this.alertToggle = false;
        this.errorPass = '';
        this.validationError.emit({'error': false, 'errorMsg': ''});
      }

    }
  }

  clearcommonForm(item: any, index: number) {
    if (item == "compoff_date") {
      let daata = this.formGp.value?.compoff_date;
      daata.splice(index, 1);
      this.formGp.patchValue({
        compoff_date: daata
      });
    }

    this.selectedCompOffDayNumbers.splice(index, 1);
    if(this.selectedCompOffDayNumbers?.length > 0){
      const sumInArray = this.selectedCompOffDayNumbers.reduce((a: any, b: any) => a + b);
      this.formGp.get('compoff_number')?.setValue(sumInArray);
      this.compOffValidation();
    }else{
      this.formGp.get('compoff_number')?.setValue(0);
  }
  }

  compOffValidation(){
    if (Number(this.formGp.get('compoff_number')?.value) != Number(this.formGp.get('leave_num_days')?.value)) {
      this.invalidPass = true;
      this.alertToggle = true;
      this.errorPass = 'The number of leave days selected should be equal to the number of comp off balance days selected.';
      this.validationError.emit({'error': true, 'errorMsg': this.errorPass});
    }else{
      this.invalidPass = false;
      this.alertToggle = false;
      this.errorPass   = '';
      this.validationError.emit({'error': false, 'errorMsg': ''});
    }
  }

  // Overtime and Comp off related validation start
  checkOvertimeAndCompOff() {

    if (this.validationData?.overtime_dates?.length > 0) {
      this.showValidation('Overtime');
    }else if(this.validationData?.compoff_dates?.length > 0){
      this.showValidation('Compoff');
    }else{
      this.overtimeCompOffValidation = false;
      this.overtimeCompOffValidationMsg = '';
      this.validationError.emit({'error': false, 'errorMsg': ''});
      this.overtimeCompoffError.emit({'error': false});
    }
    // if (this.validationData?.overtime_dates?.length > 0) {
    //   this.showValidation('Overtime');
    // } else if (this.validationData?.overtime_dates?.length == 0) {
    //   this.removeValidation();
    //   this.overtimeCompOffValidation    = false;
    //   this.overtimeCompOffValidationMsg = '';
    // } else if ((this.validationData?.overtime_dates?.length == 0) && this.validationData?.compoff_dates?.length > 0) {
    //   this.showValidation('Compoff');
    // } else if ((this.validationData?.overtime_dates?.length == 0) && this.validationData?.compoff_dates?.length == 0) {
    //   this.overtimeCompOffValidation = false;
    //   this.overtimeCompOffValidationMsg = '';
    //   this.validationError.emit({'error': false, 'errorMsg': ''});

    // }
  }

  showValidation(type: any) {
    this.overtimeCompOffValidation = true;
    // this.validationError.emit({'error': true, 'errorMsg': ''});
    this.overtimeCompoffError.emit({'error': true});
    if (type == 'Overtime') {
      if (this.validationData?.overtime_dates?.length > 0) {
        for (let i = 0; i < this.validationData?.overtime_dates?.length; i++) {
          this.validationData.overtime_dates[i] = this.appservice.dateFormatDisplay(this.validationData?.overtime_dates[i])
        }
        // this.overtimeCompOffValidationMsg = "Overtime has already been processed for the selected dates" + ' (' + this.validationData?.overtime_dates?.join(', ') + ')' + ". You cannot raise the leave request for these dates.";

        this.overtimeCompOffValidationMsg = "Leave request cannot be raised as OT hours and attendance has already been processed for this dates" + ' (' + this.validationData?.overtime_dates?.join(', ') + ')' + ".";

      }} else if (type == 'Compoff') {
        if (this.validationData?.compoff_dates?.length > 0) {
          for (let i = 0; i < this.validationData?.compoff_dates?.length; i++) {
            this.validationData.compoff_dates[i] = this.appservice.dateFormatDisplay(this.validationData?.compoff_dates[i])
          }

          this.overtimeCompOffValidationMsg = "Leave request cannot be raised, as comp-off  for this dates" + ' (' + this.validationData?.compoff_dates?.join(', ') + ')' + " has already been availed."

          // this.overtimeCompOffValidationMsg = "Compensatory off credited against the selected dates" + ' (' + this.validationData?.compoff_dates?.join(', ') + ')' + ". has already been availed. You cannot raise leave request for the selected dates.";
        }
      }
  }

  removeValidation() {
    this.invalidPass = false;
    this.errorPass = '';
  }

  getDayName(arr:any) {

    this.penalityDays = [];
    this.isPenality = true;
    if (arr?.penalty_dates?.length > 0) {
      for (let i = 0; i < arr?.penalty_dates?.length; i++) {

        let date = this.appservice.dateFormatDisplay(arr?.penalty_dates[i]?.date);
        let day = new Date(arr?.penalty_dates[i]?.date).toLocaleDateString('en-US', { weekday: 'long' });

        this.penalityDays?.push(date + ' (' + day + ')')

      }
    }
  }

  closed(bool: any) {
    this.alertToggle = bool;
  }

  // Month change
  fromMonthSelection(e:any){

    let a = moment(e.toDate()).format('YYYY-MM-DD');

    this.getWeekOffHolidayList(moment(a).month() + 1, moment(a).year());

  }

  toMonthSelection(e:any){

    let a = moment(e.toDate()).format('YYYY-MM-DD');

    this.getToWeekOffHolidayList(moment(a).month() + 1, moment(a).year());
  }

  // End


  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle1         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }
  // test end


  isApi1 = false;
  isValSet = false
  // New updtions start
  dateValidations(){
    this.isApi1 = true;
    this.validationData = [];
    this.removeValidation();
    this.validationError.emit({'error': false, 'errorMsg':''});
    if (this.formGp.value.leave_type != null && this.formGp.value.from_date != '' && this.formGp.value.to_date != '') {
      this.penalityDates = [];
      this.investmentService.leaveRequestValidation({ 'leave_type': this.formGp.value.leave_type, 'employee': this.employee, 'from_date': this.appservice.dateFormatConvert(this.formGp.value.from_date), 'to_date': this.appservice.dateFormatConvert(this.formGp.value.to_date) }).subscribe((res: any) => {
        this.validationData = res?.data;
        this.portionDropdwn = res?.data?.portions_validation
        // Set fromPortion to the first key in from_date with a true value
        this.fromLeavePortion = !this.isValSet ?
        (() => {
          const portion = Object.entries(this.portionDropdwn.from_date).find(([key, value]) => {
            if (key === 'full_day' && value === true) return true;
            if ((key === 'second_half' || key === 'first_half') && value === true && this.portionDropdwn.from_date.full_day === false) return true;
            return false;
          })?.[0];
          const formattedPortion = this.removeUnderscoreAddUpperCase(portion);
          return portion === 'second_half' || portion === 'first_half' ? formattedPortion + ' Day' : formattedPortion;
        })()
        : this.fromLeavePortion;
        this.toLeavePortion = !this.isValSet ?
        (() => {
          const portion = Object.entries(this.portionDropdwn.to_date).find(([key, value]) => {
            if (key === 'full_day' && value === true) return true;
            if ((key === 'second_half' || key === 'first_half') && value === true && this.portionDropdwn.to_date.full_day === false) return true;
            return false;
          })?.[0];
          const formattedPortion = this.removeUnderscoreAddUpperCase(portion);
          return portion === 'second_half' || portion === 'first_half' ? formattedPortion + ' Day' : formattedPortion;
        })()
        : this.toLeavePortion;
        this.isValSet = true
        this.formGp.get('validateDuration')?.setValue(this.portionDropdwn)
        // To check the penality
        if (this.validationData?.penalty_dates?.length > 0) {
          this.getDayName(this.validationData);
          for (let i = 0; i < this.validationData?.penalty_dates?.length; i++) {
            let penalityDate = {
              date : this.appservice.dateFormatConvert(this.validationData?.penalty_dates[i]?.date),
              portion : this.validationData?.penalty_dates[i]?.portion
            }
            this.penalityDates?.push(penalityDate)
          }
          this.penalityCount = this.validationData?.penalty
          this.formGp.get('penalty_dates')?.setValue(this.penalityDates);
          this.formGp.get('penality_count')?.setValue(this.penalityCount)
        } else {
          this.isPenality = false;
          this.formGp.get('penalty_dates')?.setValue('');
        }
        // End

        this.checkOvertimeAndCompOff();
        this.formGp.get('leave_dates')?.setValue(res?.data?.leave_dates);
        this.dateDiff = Number(res.data.leave_dates.length) - (this.from_halfDay + this.to_halfDay);
        this.calculateNumberOfDays();
        window.sessionStorage.setItem('this.dateDiff', this.dateDiff);
        this.validationResult.emit({ 'ValidationResult': res.data, 'total_days': this.dateDiff });

        // To check the document is required or not
        this.docRequiredFunction();
        // End

        this.portionValidation();
        this.isApi1 = false;
      },
        (error: any) => {
          this.errorPass    = error.error.error;
          this.invalidPass  = true;
          this.alertToggle  = true;
          this.validationError.emit({'error': true, 'errorMsg': this.errorPass});
          this.formGp.get('leave_num_days')?.setValue(0);
          this.isApi1 = false;
        })
    }
  }

  isApi2 = false;

  portionValidation(){
    this.isApi2 = true;
    this.validationPortionData = [];
    this.invalidPass1 = false;
    this.errorPass1 = '';
    this.validationError.emit({'error': false, 'errorMsg': ''});

    if (this.formGp.value.leave_type != null && this.formGp.value.from_date != '' && this.formGp.value.to_date != '') {
      this.penalityDates = [];
      this.investmentService.leaveRequestValidation({ 'leave_type': this.formGp.value.leave_type, 'employee': this.employee, 'from_date': this.appservice.dateFormatConvert(this.formGp.value.from_date), 'to_date': this.appservice.dateFormatConvert(this.formGp.value.to_date),'portion': this.formGp.value.portion,'penalty': this.validationData?.penalty}).subscribe((res: any) => {
        this.validationPortionData = res?.data;
        this.docRequiredFunction();
        if((this.formGp.get('leave_name')?.value == 'Compensatory off' && this.invalidPass)){
          // The comp off day exceed scenario
          this.calculateNumberOfDays();
          if((Number(this.formGp.get('compoff_number')?.value) != Number(this.formGp.get('leave_num_days')?.value)))
            this.validationError.emit({'error': true, 'errorMsg': 'The number of leave days selected should be equal to the number of comp off balance days selected.'});
          else
            this.validationError.emit({'error': false, 'errorMsg': ''});
          this.invalidPass = false;
        }
        else if(this.invalidPass){
          // Date range error scenario
          this.validationError.emit({'error': true, 'errorMsg': this.errorPass});
        }else if (this.validationPortionData?.penalty_dates?.length != 0){
          this.getDayName(this.validationPortionData);
          for (let i = 0; i < this.validationPortionData?.penalty_dates?.length; i++) {
            let penalityDate = {
              date : this.appservice.dateFormatConvert(this.validationPortionData?.penalty_dates[i]?.date),
              portion : this.validationPortionData?.penalty_dates[i]?.portion
            }
            this.penalityDates.push(penalityDate);
          }
          this.penalityCount = this.validationPortionData?.penalty
          this.formGp.get('penality_count')?.setValue(this.penalityCount)
          this.formGp.get('penalty_dates')?.setValue(this.penalityDates);
          this.calculateNumberOfDays();
          this.changeDetectorRef.detectChanges();
        }else if (this.validationPortionData?.penalty_dates?.length == 0){
          this.isPenality = false;
          this.formGp.get('penalty_dates')?.setValue('');
        }
        else{
          // No error scenario
        this.calculateNumberOfDays();
          this.validationError.emit({'error': false, 'errorMsg': ''});
        }
        this.validationPortionResult.emit({ 'validationPortionResult': res?.data, 'error': false});
        this.isApi2 = false;
        this.changeDetectorRef.detectChanges();


      },
        (error: any) => {
          this.errorPass1   = error.error.error;
          this.invalidPass1 = true;
          if(!this.invalidPass)
          this.calculateNumberOfDays();
          this.validationError.emit({'error': true, 'errorMsg': this.errorPass1});
          this.validationPortionResult.emit({ 'validationPortionResult':this.errorPass1, 'error': true});
          this.isApi2 = false;
          this.changeDetectorRef.detectChanges();
        })

    }
  }

  docRequiredFunction(){
    if ((this.validationData?.doc_required_count != -1) && (this.formGp.get('leave_num_days')?.value > this.validationData?.doc_required_count)){
      this.formGp.get('document')?.setValidators([Validators.required]);
      this.document_required = true;
    }else{
      this.formGp.get('document')?.clearValidators();
      this.document_required = false;
    }
    this.formGp.get('document')?.updateValueAndValidity();
  }
  // End

  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
}
