<div ly-dialog-content class="crop-container" [lyPx]="0">

  <h4>Crop Image</h4>
    <!-- <div *ngIf="ready">
      <button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
      <button (click)="cropper.zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button>
      <button (click)="cropper.center()" ly-button appearance="icon"><ly-icon>filter_center_focus</ly-icon></button>
      <button (click)="cropper.rotate(-90)" ly-button appearance="icon"><ly-icon>rotate_90_degrees_ccw</ly-icon></button>
      <button (click)="cropper.fit()" ly-button>Fit</button>
      <button (click)="cropper.fitToScreen()" ly-button>Fit to screen</button>
      <button (click)="cropper.setScale(1)" ly-button>1:1</button>
    </div> -->
  
    <ly-img-cropper
      [config]="myConfig"
      [(scale)]="scale"
      (minScale)="minScale = $event"
      (ready)="ready = true"
      (cleaned)="ready = false"
      (cropped)="onCropped($event)"
      (loaded)="onLoaded($event)"
      (error)="onError($event)"
    >
    </ly-img-cropper>
    
  </div>
  <div *ngIf="ready" [className]="classes.sliderContainer">
    <ly-slider
      [thumbVisible]="false"
      [min]="minScale"
      [max]="1"
      [(ngModel)]="scale"
      (input)="scale = $event.value"
      [step]="0.000001"
    ></ly-slider>
  </div>
  <div style="padding-top: 5.625rem;" class="justify-content-center gap-16 pb-24"container *ngIf="ready" [lyJustifyContent]="'justify-center'" ly-dialog-actions>
    <button  class="btn btn-outline-primary btn-sm text-uppercase"  (click)="dialogRef.close()">CANCEL</button>
    <button class="btn btn-primary  btn-sm text-uppercase" (click)="dialogRef.close(cropper.crop())">Save</button>
  </div>