import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataacessService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  getDataaccessList(status:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/dataaccess-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption);
  }

  getModuleList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/module/?is_active=${status}&ordering=name`, this.httpOption);
  }
  getEmpList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name`, this.httpOption);
  }
  getDataaccessDropdownList(status:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/dataaccess-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }

  dataAccessGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/dataaccess-detail/${id}`, this.httpOption)
  }

  dataAccessUpdate(body: any,id:number) {
    return this.http.put(`${this.API_PATH}organization/api/v1/dataaccess-update/${id}/`, body, this.httpOption)
  }
  dataAccessDelete(id:number) {
    return this.http.patch(`${this.API_PATH}organization/api/v1/dataaccess-delete/${id}/`,{'is_active':false}, this.httpOption)
  }

  getcompanyList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=${status}&ordering=company_name`, this.httpOption);
  }
  getbuList(status: any,companyid: number){
    return this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=${status}&ordering=name&company_id=${companyid}`, this.httpOption);
  }
  getdepartmentList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }
  getdesignationList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }
  getgradeList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }
  getcityList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }
  getbranchList(status: any){
    return this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }

  saveAccessRight(body:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/dataaccess-create/`, body, this.httpOption)
  }
  getdataview(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/dataaccess-view/${id}/`, this.httpOption);
  }
}
