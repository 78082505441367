import { AfterViewInit, Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements AfterViewInit {
  public chart: any;
  constructor() { }

  @Input() data: any;
  @Input() labels: any;
  @Input() yText: any;
  @Input() viewLegend: any
  @Input() legendPosition: any
  @Input() currency: any = ""
  uniqueId: any;
  context: any
  @Input() id: any
  options: any

  @ViewChild('canvas', { static: false }) canvas!: ElementRef;
  callback(value: any, index: any, values: any) {
    if (this.currency != "")
      return value.toLocaleString("en-US", { style: "currency", currency: "INR" });
    else
      return value
  }

  ngAfterViewInit(): void {
    this.viewLegend = this.viewLegend == undefined ? true : this.viewLegend
    this.legendPosition = this.legendPosition == undefined ? 'start' : this.legendPosition
    this.createChart();
  }
  createChart() {
    let ticksvar: any = ""
    if (this.currency == "INR") {
      ticksvar = { font: { size: 12, family: 'Work Sans' }, color: '#324552', callback: function (value: any, index: any, values: any) { return value.toLocaleString("en-US", { style: "currency", currency: "INR" }) } }
    } else if (this.currency == "USD") {
      ticksvar = { font: { size: 12, family: 'Work Sans' }, color: '#324552', callback: function (value: any, index: any, values: any) { return value.toLocaleString("en-US", { style: "currency", currency: "USD" }) } }
    } else {
      ticksvar = { font: { size: 12, family: 'Work Sans' }, color: '#324552' }
    }

    this.options = {
      plugins: {
        title: { display: true }, legend: { display: this.viewLegend, position: 'bottom', align: this.legendPosition, labels: { font: { size: 13, family: 'Work Sans', weight: 'normal' }, boxWidth: 13, boxHeight: 13, useBorderRadius: true, borderRadius: 2, } },
        tooltip: {
          backgroundColor: '#ffffff',
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.2)',
          bodyColor: '#324552',
          bodyFont: {
            size: 12,
            family: 'Work Sans',
          },
          titleColor: '#324552',
          titleFont: {
            size: 12,
            weight: '600',
            family: 'Work Sans'
          }
        },
      },
      responsive: true, maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true, ticks: { font: { size: 12, family: 'Work Sans' }, color: '#324552' },
          grid: { display: false, drawOnChartArea: true }
        },
        y: { stacked: true, ticks: ticksvar, title: { display: true, text: this.yText, color: '#7D92A1', font: { family: 'Work Sans', size: 12, } }, grid: { color: '#F8FAFB', drawOnChartArea: true } },
      }
    }

    setTimeout(() => {
      // Initialized the specific canvas with it's reference
      this.context = (<HTMLCanvasElement>this.canvas.nativeElement).getContext('2d');
      // ID is given inorder to use same chart more than 1's on same component
      this.uniqueId = 'canvas' + this.id;
      this.chart = new Chart(this.context, {
        type: 'bar', //this denotes tha type of chart
        data: {// values on X-Axis


          labels: this.labels,
          datasets: this.data

          // [
          // {
          //   label: "Approved ",
          //   data: ['0', ' 6', '1', '4', '0', '0', '6', '2', '1', '3', '0', '0'],
          //   backgroundColor: '#009722',
          //   barThickness: 10,
          // },
          // {
          //   label: "Pending",
          //   data: ['0', '3', '0', '1', '2', '0', ' 6', '1', '4', '0', '0', '0'],
          //   backgroundColor: '#FFC107',
          //   barThickness: 10,
          // },
          // {
          //   label: " Rejected",
          //   data: ['0', '6', '2', '1', '3', '0', '3', '0', '1', '2', '0', '0'],
          //   backgroundColor: '#DC3545',
          //   barThickness: 10,
          // }
          // ]

        },
        options: this.options

      });
    })
  }

}
