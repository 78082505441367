<div class="config-layout">
  <header class="config-header">
    <h5 class="m-0">{{title}} Configuration</h5>
    <button class="btn-reset text-light-600" routerLink="/asset-settings"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class="config-sidebar">
      <ul ngbNav #nav="ngbNav" class="steps gap-24" [(activeId)]="activeTab" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success':settingsForm.value.company.length>0}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Select Companies</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-asset-settings-select-companies [settingsForm]="settingsForm" [formArray]="formArray"
              [companies]="companyList" (submitmethod)="submitMethod(2)" [editCompId]="editCompId"
              [checkboxes]="checkboxes" (activeCheckbox)="activeCheckbox($event)"></app-asset-settings-select-companies>
          </ng-template>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step" [class]="{'success':settingsForm.valid && settingsForm.value.company.length>0}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Setup Configuration</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-asset-settings-setup-configuration [settingsForm]="settingsForm" [selectedComp]="selectedComp"
              (submitmethod)="submitForm()" [editCompId]="editCompId"
              [SettingsArray]="SettingsArray"></app-asset-settings-setup-configuration>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class="config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>

</div>
