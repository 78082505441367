import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder,Validators ,FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { EmpcodeValidator } from 'src/app/@shared/validators/empcode.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { GstnumValidator } from 'src/app/@shared/validators/gstnum.validators';
import { AccountnumValidator } from 'src/app/@shared/validators/accountnum.validators';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ChallanService } from 'src/app/@core/services/challan.service';
import { AppService } from 'src/app/app.global';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import * as moment from 'moment';

@Component({
  selector: 'app-challan-setup',
  templateUrl: './challan-setup.component.html',
  styleUrls: ['./challan-setup.component.scss']
})

export class ChallanSetupComponent implements OnInit {

  activeTab             = 1;
  empMapList:any        = [];
  selectedList1:any     = [];
  selectedEmpData:any   = [];
  amountExceed          = true;
  loader                = false;
  company:any           = 0;
  editId:any            = 0;
  financialyear:any     = 0;
  currency:any;
  editFlag = false
  items : any = [];
  buList:any              = [];
  DepList:any             = [];
  DesignationList:any     = [];
  BranchList:any          = [];
  cityList:any            = [];
  gradeList:any           = [];
  loader1                 = false;
  excessAmount            = 0;
  monthId                 = 0;
  excessId                = 0;
  excessFlag              = false;
  updateFlag              = false;
  validFlag               = false;
  payrollMonth:any;
  adjustMonthTaxAmt       = 0;

  constructor(
    private _location: Location,
    private formBuilder:FormBuilder,
    public router: Router,
    public route:ActivatedRoute,
    public challanService:ChallanService,
    public appservice:AppService,
    private previousRouteService: PreviousRouteService,

   ) { }

  backClicked() {

    if(this.previousRouteService.getPreviousUrl() == '/home' || this.previousRouteService.getPreviousUrl() == '/login')
    this.router.navigate(['challan/add-challan']);
    else
    this._location.back();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {

      if((params['edit'])){
        if (!isNaN(params['editid'])) {
          this.editId = params['editid'];
          this.editForm();
        }
      }else if((params['adjustAmount'])){
        if (!isNaN(params['excessamount'])) {
          this.excessAmount = params['excessamount'];
        }
        if (!isNaN(params['monthid'])) {
          this.monthId      = params['monthid'];
        }
        if (!isNaN(params['excessid'])) {
          this.excessId     = params['excessid'];
        }

        if (isNaN(params['currency'])) {
          this.currency = params['currency'];
        }

        if (isNaN(params['monthname'])) {
          this.payrollMonth = params['monthname'];
        }

        if (!isNaN(params['taxamount'])) {
          this.adjustMonthTaxAmt  = params['taxamount'];
        }

        this.adjustAmountFunction();
        // this.excessAmountEmpList()
      }
      else{
        if (!isNaN(params['id'])) {
          this.challanForm['controls'].define.get('challan_runpayroll')?.setValue(params['id']);
        }
      }
        if (isNaN(params['month'])) {
          this.challanForm['controls'].define.get('payroll_month')?.setValue(params['month']);
        }
        if (!isNaN(params['company'])) {
          this.company = params['company'];
        }
        if (isNaN(params['currency'])) {
          this.currency = params['currency'];
        }
        if (!isNaN(params['financialyear'])) {
          this.financialyear = params['financialyear'];
        }
      });
    }

  challanForm: FormGroup = this.formBuilder.group({

    define      : this.formBuilder.group({
      challan_runpayroll: [''],
      payroll_month     : ['',[Validators.required]],
      voucher_no        : ['',[Validators.required,EmpcodeValidator()]],
      amount            : ['',[Validators.required,digitdecimalValidator()]],
      voucher_date      : ['',[Validators.required]],
      branch_code       : ['',[Validators.required,EmpcodeValidator()]],
      branch_details    : ['',[Validators.required,Validators.maxLength(500),UnamepatternValidator()]],
      tds               : ['',[Validators.required,digitdecimalValidator()]],
      surcharge         : ['',[Validators.required,digitdecimalValidator()]],
      education_cess    : ['',[Validators.required,digitdecimalValidator()]],
      intrest           : ['',[Validators.required,digitdecimalValidator()]],
      others            : ['',[Validators.required,digitdecimalValidator()]],
      cheque_dd_no      : ['',[Validators.required,GstnumValidator(),Validators.maxLength(18)]],
      payment_date      : ['',[Validators.required]],
      deduction_date    : ['',[Validators.required]],
      ack_no            : ['',[Validators.required,AccountnumValidator()]],
      minor_head_code   : [null,[Validators.required]],
      late_pay_fee      : ['',[Validators.required,digitdecimalValidator()]],
      challan_mapped_amt : [''],
      challan_excess_amount : ['']
    }),

  });


  /********* FORM NAVIGATION FUNCTION ****************/
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.challanForm['controls'].define.invalid || this.validFlag) {
        changeEvent.preventDefault();
      }
    }
    if (changeEvent.nextId === 3) {

      if (this.selectedList1?.length === 0 || !this.amountExceed || this.validFlag || this.challanForm['controls'].define.invalid) {
        changeEvent.preventDefault();
      }
    }
  }

 get defineForm() {
  return this.challanForm.get('define') as FormGroup;
 }

 /********* SUBMIT FIRST FORM ****************/
 submitOne(){
  this.activeTab = 2;
 }


 /********* SUBMIT SECOND FORM ****************/
 submitTwo(data:any){
  this.selectedEmpData  = data.selectedEmpData;
  this.selectedList1    = data.selectedList;
  this.amountExceed     = data.valid;
  if(data.type == 'submit')
    this.activeTab      = 3;
 }

 /********** SAVE FORM ****************/
 submitForm(){

  let challan_runpayroll     = this.challanForm.getRawValue().define['challan_runpayroll'];
  let challan_reciept        = this.challanForm.getRawValue().define['challan_reciept'];
  let payroll_month          = this.challanForm.getRawValue().define['payroll_month'];
  let voucher_no             = this.challanForm.getRawValue().define['voucher_no'];
  let amount                 = this.challanForm.getRawValue().define['amount'];
  let voucher_date           = this.appservice.dateFormatConvert(this.challanForm.getRawValue().define['voucher_date']);
  let branch_code            = this.challanForm.getRawValue().define['branch_code'];
  let branch_details         = this.challanForm.getRawValue().define['branch_details'];
  let tds                    = this.challanForm.getRawValue().define['tds'];
  let surcharge              = this.challanForm.getRawValue().define['surcharge'];
  let education_cess         = this.challanForm.getRawValue().define['education_cess'];
  let intrest                = this.challanForm.getRawValue().define['intrest'];
  let others                 = this.challanForm.getRawValue().define['others'];
  let cheque_dd_no           = this.challanForm.getRawValue().define['cheque_dd_no'];
  let payment_date           = this.appservice.dateFormatConvert(this.challanForm.getRawValue().define['payment_date']);
  let deduction_date         = this.appservice.dateFormatConvert(this.challanForm.getRawValue().define['deduction_date']);
  let ack_no                 = this.challanForm.getRawValue().define['ack_no'];
  let minor_head_code        = this.challanForm.getRawValue().define['minor_head_code'];
  let late_pay_fee           = this.challanForm.getRawValue().define['late_pay_fee'];

  if(this.amountExceed){
    if(this.editId == 0 && this.excessId == 0){
      this.challanService.challanCreate({'challan_map': this.selectedList1, 'challan_runpayroll':challan_runpayroll, 'challan_reciept': challan_reciept, 'payroll_month': payroll_month,'voucher_no': voucher_no,'amount': amount, 'voucher_date':voucher_date, 'branch_code': branch_code,'branch_details': branch_details,'tds': tds,'surcharge': surcharge,'education_cess': education_cess,'intrest':intrest, 'others': others,'cheque_dd_no': cheque_dd_no,'payment_date': payment_date,'deduction_date': deduction_date,'ack_no': ack_no,'minor_head_code': minor_head_code,'late_pay_fee': late_pay_fee}).subscribe((res:any)=>{
        this.router.navigate(['challan/add-challan/'+this.company+'/'+this.financialyear]);
      });
    }
    else if(this.editId != 0 && this.excessId == 0){
      this.challanService.challanUpdate(this.editId,{'challan_map': this.selectedList1, 'challan_runpayroll':challan_runpayroll, 'challan_reciept': challan_reciept, 'payroll_month': payroll_month,'voucher_no': voucher_no,'amount': amount, 'voucher_date':voucher_date, 'branch_code': branch_code,'branch_details': branch_details,'tds': tds,'surcharge': surcharge,'education_cess': education_cess,'intrest':intrest, 'others': others,'cheque_dd_no': cheque_dd_no,'payment_date': payment_date,'deduction_date': deduction_date,'ack_no': ack_no,'minor_head_code': minor_head_code,'late_pay_fee': late_pay_fee}).subscribe((res:any)=>{
         this.router.navigate(['challan/add-challan/'+this.company+'/'+this.financialyear]);
        });
    }
    else if(this.excessId != 0){

      this.challanService.challanCreate({'challan_map': this.selectedList1, 'challan_runpayroll':challan_runpayroll, 'challan_reciept': challan_reciept, 'payroll_month': payroll_month,'voucher_no': voucher_no,'amount': amount, 'voucher_date':voucher_date, 'branch_code': branch_code,'branch_details': branch_details,'tds': tds,'surcharge': surcharge,'education_cess': education_cess,'intrest':intrest, 'others': others,'cheque_dd_no': cheque_dd_no,'payment_date': payment_date,'deduction_date': deduction_date,'ack_no': ack_no,'minor_head_code': minor_head_code,'late_pay_fee': late_pay_fee,'duplicate':true}).subscribe((res:any)=>{
        this.router.navigate(['challan/add-challan/'+this.company+'/'+this.financialyear]);
      });
    }

  }

 }

 /********* EDIT FORM ****************/
  editForm(){
    this.loader = true;
    this.updateFlag = true;
    if(this.editId != undefined && this.editId != null){
      this.challanService.challanEdit(this.editId).subscribe((res:any)=>{
        this.challanForm['controls']?.define?.reset(res);
        this.setValidator();
        if(res?.is_adjusted){
          this.editFlag = true;
        }else{
          this.editFlag = false;
        }
        for(let i=0;i<res?.challan_map?.length;i++){
          this.selectedList1.push({"payroll_status":res?.challan_map[i]['payroll_status']})
        }
        this.empMapListEditFunction();

        this.loader       = false;
        return
      },(error)=>{

      });

    }

  }

  /********* GET EMPLOYEE MAP LIST FUNCTION - EDIT ****************/
  empMapListEditFunction(){
     this.loader1  = true;
     this.items    = [];
     if(this.defineForm?.value?.amount > this.defineForm?.value?.challan_mapped_amt){
     this.challanService.challanUpdateEmpMapListFilter(this.editId,this.defineForm?.value?.amount,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
     .subscribe((res: any) => {
       this.items = res;
       this.loader1 = false;
      });
    }else{
      this.challanService.challanUpdateAmountListFilter(this.editId,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
      .subscribe((res: any) => {
        this.items = res;
        this.loader1 = false;
       });
    }
  }

  /********* GET EMPLOYEE MAP LIST FUNCTION - CREATE ****************/
  empMapListFunction(){

    this.loader1 = true;
    this.items   = [];
    this.challanService.challanCreateEmpMapListFilter(this.defineForm?.value?.amount,this.defineForm?.value?.challan_runpayroll,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
      .subscribe((res: any) => {
      this.items = res;
      this.loader1 = false
    })
  }

  // after apply filter in second step
  filterApply(data:any){
  this.buList = data.bu;
  this.DepList = data.dept;
  this.DesignationList = data.desig;
  this.BranchList = data.branch;
  this.gradeList = data.grade;
  if(this.editId == 0 && this.excessId == 0){
    this.empMapListFunction()
  }else if(this.editId != 0 && this.excessId == 0){
    this.empMapListEditFunction()
  }
  else if(this.excessId != 0){
    this.excessAmountEmpList()
  }
  else{}
  }

  // Function according to amount in first step
  emplistFunction(){
    if(this.editId == 0 && this.excessId == 0){
      this.empMapListFunction()
    }else if(this.editId != 0 && this.excessId == 0){
      this.empMapListEditFunction()
    }
    else{}
  }

  // Function for getting data in first step for adjust amount
  adjustAmountFunction(){
    var date = new Date();
    var y = date.getFullYear();
    var m = this.appservice.getMonthNumberFromName(this.payrollMonth)
    var lastDay = new Date(y, m + 1, 0);
    const finalday = moment(lastDay).format('YYYY-MM-DD');
    this.excessFlag = true;
    this.loader = true;
    if(this.excessId != undefined && this.excessId != null){
      this.challanService.challanEdit(this.excessId).subscribe((res:any)=>{
      // this.challanForm['controls']?.define?.reset(res);

      // test start

      this.challanForm.reset(
        {
          define : {
            payroll_month     : this.payrollMonth,
            voucher_no        : res?.voucher_no,
            amount            : res?.challan_excess_amount > this.adjustMonthTaxAmt ? this.adjustMonthTaxAmt : res?.challan_excess_amount,
            voucher_date      : res?.voucher_date,
            branch_code       : res?.branch_code,
            branch_details    : res?.branch_details,
            tds               : res?.challan_excess_amount > this.adjustMonthTaxAmt ? this.adjustMonthTaxAmt : res?.challan_excess_amount,
            surcharge         : 0,
            education_cess    : 0,
            intrest           : 0,
            others            : 0,
            cheque_dd_no      : res?.cheque_dd_no,
            payment_date      : finalday,
            deduction_date    : finalday,
            ack_no            : res?.ack_no,
            minor_head_code   : res?.minor_head_code,
            late_pay_fee      : 0,
            challan_excess_amount : res?.challan_excess_amount > this.adjustMonthTaxAmt ? this.adjustMonthTaxAmt : res?.challan_excess_amount,
          },
      });
      this.excessAmountEmpList();
      // test end

      this.editFlag = true;
      for(let i=0;i<res?.challan_map?.length;i++){
        this.selectedList1.push({"payroll_status":res?.challan_map[i]['payroll_status']})
      }
      this.loader       = false;
      return
    },(error)=>{

    });
  }
}

// Function for getting list of employees in second step for adjust amount
excessAmountEmpList(){
  this.loader1 = true;
  // this.challanService.challanCreateEmpMapListFilter(this.excessAmount,this.monthId,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
  //   .subscribe((res: any) => {
  this.challanService.challanCreateEmpMapListFilter(this.defineForm?.value?.amount,this.monthId,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList)
    .subscribe((res: any) => {
    this.items = res;
    this.loader1 = false

  })
}

countSelect(){
  this.selectedList1 = []
}

validStepper(data:any){
  this.validFlag = data

}

setValidator(){
  let total_amt = Number(this.defineForm.value.tds) + Number(this.defineForm.value.surcharge) + Number(this.defineForm.value.education_cess) + Number(this.defineForm.value.intrest) + Number(this.defineForm.value.others) + Number(this.defineForm.value.late_pay_fee);
  if(this.defineForm?.value?.amount != total_amt){
    this.challanForm['controls'].define.get('amount')?.setErrors({
      'equal' : true
    })
    this.challanForm['controls'].define.get('amount')?.markAsTouched()
  }
}

}
