<form class="">
  <div class="container-fluid p-32 form-container">
      <div class="row mb-24">
          <div class="col-12 col-lg-6">
              <p class="text-uppercase fw-500 mb-0">Configure Companies</p>
          </div>

          <div class="col-12 mt-16" *ngIf="Isalert && editCompId!=selectedComp[0]?.id">
            <div role="alert" class="alert alert-warning mb-0 show">
                <p class="mb-0 fs-14"><span class="text-warning">Note : </span>By selecting multiple companies, you are
                  going to give same rights to all the companies below</p>
                <div class="btn-close pointer" (click)="Isalert=false;"></div>
            </div>
          </div>

      </div>
      <div class="row row-16" [formGroup]="configForm">
          <div class="col-12">
              <p class="fs-12 mb-0 text-light-500">Companies Selected : {{selectedComp.length}}</p>
              <ul class="selected-values mt-8">

                  <li class="ng-value" *ngFor="let company of selectedComp,let ki =index">
                      <span class="ng-value-label">
                          <div class="d-inline-flex align-items-center w-30ch" title="{{company?.company_name}}">
                              <img *ngIf="company?.logo !=  null && company?.logo !=  ''" class="avatar-sm sq-24 img-fluid rounded-circle me-1" src="{{company?.logo}}">
                              <span *ngIf="company?.logo ==  null || company?.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{ki%5}} me-1" >
                                  <span>
                                  {{this.appService.getFirstChar(company?.company_name,1)}}</span>
                              </span>
                              <span class="text-trim"> {{company?.company_name}}</span>
                          </div>
                      </span>
                      <span aria-hidden="true" class="ng-value-icon right" *ngIf="editCompId!=company?.id">
                          <i class="icon-close" (click)="removeComp(ki)"></i>
                      </span>
                  </li>

              </ul>
          </div>
           <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">GENERAL CONFIGURATION</p>
          </div>
          <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Have you run payroll earlier this financial year?</label>
          </div>
              <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline me-32">
                  <label class="form-check-label" >
                      <input formControlName="prior_payroll" class="form-check-input"  type="radio" [value]="true" >
                      Yes, we have already run payroll this financial year
                  </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="If this option is selected you need to enter the previous payroll information before proceeding further." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>

              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input formControlName="prior_payroll" class="form-check-input" type="radio"  [value]="false" >
                      No, we are going to run our first payroll
                  </label>
                  <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="Select this option if you are running your payroll for the first time and no previous payroll information is required for this option." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              </div>
          </div>
          <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">PAYROLL SCHEDULE</p>
          </div>
          <div class="col-lg-6 form-row">
              <label for="" class="form-label required">Pay frequency </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Pay frequency is how often you pay your employee. Here in SkizzleHR it is monthly by default." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
              <ng-select class="form-ngselect" placeholder="Select frequency" formControlName="pay_frequency"
              [ngClass]="{ 'is-invalid': (submitted || configForm.controls['pay_frequency'].touched) && configForm.controls['pay_frequency'].errors }">
                  <ng-option *ngFor="let item of pay_frequency" [value]="item">{{ item}}
                  </ng-option>
              </ng-select>
              <div *ngIf="(submitted ||configForm.controls['pay_frequency'].touched) &&configForm.controls['pay_frequency'].errors" class="invalid-feedback">
                  <div *ngIf="configForm.controls['pay_frequency'].errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
          </div>
          <!-- <div class="col-lg-6 form-row">
              <label for="" class="form-label required">Which pay cycle do you want to start using SkizzleHR</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Select the pay cycle from which you want to start using SkizzleHR. If the pay cycle starts in the middle of the financial year, the previous month’s salary details and TDS need to be updated in the system." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>

                <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || configForm.controls['first_pay_cycle'].touched) &&  configForm.controls['first_pay_cycle'].errors}" [placeholder]="'Select month'" id="first_pay_cycle" [submitted]="submitted"
                [isrequired]="'true'" [selectedItem]="configForm.controls['first_pay_cycle'].value" formControlName="first_pay_cycle" [type]="'listOfMonth'" [isdisabled]='isDisable'></app-master-select>

                <div *ngIf="(submitted ||configForm.controls['first_pay_cycle'].touched) && configForm.controls['first_pay_cycle'].errors" class="invalid-feedback">
                    <div *ngIf="configForm.controls['first_pay_cycle'].errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
          </div> -->



          <!-- <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Pay period </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="The pay period is the period for which you pay your employee. If your organization prefers calendar days as a pay period, then the salary will be paid from beginning of the month to last day of the month  If your organization has a specific day as a pay period, then choose the option Set Value. If the set value is selected as 26th, then the salary will be calculated from the 27th of the previous month to the 26th of the current month." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
              <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input   class="form-check-input"  type="radio" [value]="false" formControlName="pay_period" (ngModelChange)="setDisabled('pay_period','pay_period_day')">
                      Calendar days
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input   class="form-check-input" type="radio"  [value]="true"  formControlName="pay_period"  (ngModelChange)="setDisabled('pay_period','pay_period_day')">
                      Set value
                  </label>
              </div>
          </div>

          <div   class="col-lg-12 form-row mt-8">


              <app-master-select class="config-app-master" *ngIf="configForm.controls['pay_period'].value==true"  [ngClass]="{ 'is-invalid': (submitted || configForm.controls['pay_period_day'].touched) &&  configForm.controls['pay_period_day'].errors}" [placeholder]="'Select date'" id="pay_period_day" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="configForm.controls['pay_period_day'].value" formControlName="pay_period_day" [type]="'schedule_dates'" [range]="28" [lastDay]="true"></app-master-select>

              <div *ngIf="(submitted || configForm.controls['pay_period_day'].touched || configForm.controls['pay_period_day'].dirty) && configForm.controls['pay_period_day'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['pay_period_day'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['pay_period_day'].pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>
              </div>
          </div> -->
          <!-- FIRST PAY CYCLE WITH MONTH AND YEAR -->
          <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Which pay cycle do you want to start using SkizzleHR</label>
            <div ngbDropdown  class="input-dropdown"   [ngClass]="{ 'is-invalid': ((submitted || configForm.controls['first_pay_cycle'].touched) &&  configForm.controls['first_pay_cycle'].errors) || (submitted || configForm.controls['first_pay_year'].touched ) &&  configForm.controls['first_pay_year'].errors}">
              <button type="button" class="card card-c2" ngbDropdownToggle [disabled]="isDisable"  [ngClass]="{ 'is-invalid': ((submitted || configForm.controls['first_pay_cycle'].touched) &&  configForm.controls['first_pay_cycle'].errors) || (submitted || configForm.controls['first_pay_year'].touched ) &&  configForm.controls['first_pay_year'].errors}">
                  <span class="btn-placeholder" *ngIf="configForm.value.first_pay_cycle==null && configForm.value.first_pay_year==null" >Select month and year</span>
                  {{configForm.value.first_pay_cycle}} {{configForm.value.first_pay_year}}
              </button>

              <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 25rem;">
                <div class="row">
                  <div class="col-5">
                    <label for="" class="form-label required">Year </label>
                    <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('Year')}}" formControlName="first_pay_year" >
                      <ng-option [value]="prevYear">{{prevYear}}</ng-option>
                      <ng-option [value]="year">{{year}}</ng-option>
                      <ng-option [value]="nextYear">{{nextYear}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-7">
                    <label for="" class="form-label required">Month </label>
                    <app-master-select formControlName="first_pay_cycle" [selectedItem]="configForm.controls['first_pay_cycle'].value" [placeholder]="'Select month'" id="first_pay_cycle"  [submitted]="submitted" [isrequired]="true"  [type]="'listOfMonth'" ></app-master-select>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="((submitted || configForm.controls['first_pay_cycle'].touched) &&  configForm.controls['first_pay_cycle'].errors) || (submitted || configForm.controls['first_pay_year'].touched ) &&  configForm.controls['first_pay_year'].errors" class="invalid-feedback">
              <div *ngIf="((submitted || configForm.controls['first_pay_cycle'].touched) &&  configForm.controls['first_pay_cycle'].errors) || (submitted || configForm.controls['first_pay_year'].touched ) &&  configForm.controls['first_pay_year'].errors">{{this.messageService.validationDisplay('required')}}</div>

          </div>
          </div>

          <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Pay day </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Pay Day is a reminder on the salary dashboard for HR to process the employees' salaries. If you choose 'last day of the month', HR will get a reminder on the last day of each month that this employee's salary is pending. If you choose 'set value' and enter a specific date, HR will be reminded to process the salaries of the pending employees on the salary dashboard page on that specific day." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
              <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input   class="form-check-input"  type="radio" [value]="false" (ngModelChange)="setDisabled('pay_day_status','pay_day')" formControlName="pay_day_status">
                      Last day of the month
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input   class="form-check-input" type="radio" [value]="true" (ngModelChange)="setDisabled('pay_day_status','pay_day')" formControlName="pay_day_status">
                      Set value
                  </label>
              </div>
          </div>
          <div class="col-lg-12 form-row mt-8">

              <app-master-select class="config-app-master" *ngIf="configForm.controls['pay_day_status'].value == true"  [ngClass]="{ 'is-invalid': (submitted || configForm.controls['pay_day'].touched) &&  configForm.controls['pay_day'].errors}" [placeholder]="'Select date'" id="pay_day" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="configForm.controls['pay_day'].value" formControlName="pay_day" [type]="'schedule_dates'"  [range]="28" [lastDay]="false"></app-master-select>

              <div *ngIf="(submitted || configForm.controls['pay_day'].touched || configForm.controls['pay_day'].dirty) && configForm.controls['pay_day'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['pay_day'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['pay_day'].pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>
              </div>
          </div>

          <div class="col-12 mt-32">
              <div class="fw-500 text-uppercase">Work Days Calculation</div>
          </div>

          <div class="col-lg-12 form-row ">
              <label for="" class="form-label " >How do you want to capture employee attendance details for payroll? </label>
            </div>
            <div class="col-lg-12 form-row mt-1">
                <div class="form-check">
                    <label class="form-check-label" >
                        <input   class="form-check-input"  type="radio" formControlName="attendance_lop" [value]="false"  (change)="weekCalcControl()">
                        Automatically fetch the attendance details from the time and attendance module
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="System will calculate employees' paid days from Time and Attendance module automatically, You need to process attendance for the respective month from the Run Payroll menu before finalizing the payroll." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                </div>
                <div class="form-check mt-8" >
                    <label class="form-check-label" >
                        <input   class="form-check-input" type="radio" formControlName="attendance_lop" [value]="true"  (change)="weekCalcControl()">
                        Manually upload the attendance details
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="If you do not want system calculated paid days you can manually upload the employees' paid days in excel. Before finalizing payroll for any month." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                </div>

            </div>
            <!-- <div class="col-lg-12 form-row ">
            <label for="" class="form-label " >How do you want to import your employee's attendance data? </label>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input   class="form-check-input"  type="radio" formControlName="attendance_lop" [value]="false" (change)="weekCalcControl()">
                      Attendance import
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input   class="form-check-input" type="radio" formControlName="attendance_lop" [value]="true" (change)="weekCalcControl()">
                      LOP import
                  </label>
              </div>
          </div> -->

          <ng-container  *ngIf="configForm.controls['attendance_lop'].value==true">
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label " >Pay cycle </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Pay cycle is the period where employees are paid.Calendar Days: This option calculates the pay cycle based on the actual calendar month  from 1st to Last day of month, for February it will be 1st to 28th.Set Value: This option allows you to define a constant pay cycle, e.g., if the set value is 25th January, pay period starts on January 25th and ends on February 26th." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input   class="form-check-input"  type="radio" formControlName="work_day_status" [value]="false" (ngModelChange)="setDisabled('work_day_status','work_day')">
                        Calender days
                    </label>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                        <input   class="form-check-input" type="radio" formControlName="work_day_status" [value]="true" (ngModelChange)="setDisabled('work_day_status','work_day')">
                        Set value
                    </label>
                </div>
            </div>
            <div   class="col-lg-12 form-row mt-8" *ngIf="configForm.controls['work_day_status'].value==true">

                <app-master-select  class="config-app-master" [ngClass]="{ 'is-invalid': (submitted || configForm.controls['work_day'].touched) &&  configForm.controls['work_day'].errors}" [placeholder]="'Select date'" id="work_day" [submitted]="submitted"
                [isrequired]="'true'" [selectedItem]="configForm.controls['work_day'].value" formControlName="work_day" [type]="'schedule_day'" [startPoint]="1" [range]="28" [lastDay]="false"></app-master-select>

                <div *ngIf="(submitted || configForm.controls['work_day'].touched || configForm.controls['work_day'].dirty) && configForm.controls['work_day'].errors " class="invalid-feedback">
                    <div *ngIf="configForm.controls['work_day'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="configForm.controls['work_day'].pattern">{{this.messageService.validationDisplay('pattern')}}
                    </div>
                </div>
            </div>
            </ng-container>

             <!-- single day salary calculation -->
          <div class="col-lg-12 form-row ">
            <label for="" class="form-label " >Workdays in a month for calculating one day’s salary</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="As per pay cycle (default): Automatically calculates daily salary based on actual calendar days of the payroll month. E.g., February = monthly salary/28.Set value: Allows manual setting of dividing factor for daily salary calculation, regardless of month length. E.g., if set value is 30 then for February = monthly salary/30." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input   class="form-check-input"  type="radio" formControlName="pay_period" [value]="false" (ngModelChange)="setDisabled('pay_period','pay_period_day')">
                      As per pay cycle
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input   class="form-check-input" type="radio" formControlName="pay_period" [value]="true" (ngModelChange)="setDisabled('pay_period','pay_period_day')">
                      Set value
                  </label>
              </div>
          </div>
          <div   class="col-lg-12 form-row mt-8" *ngIf="configForm.controls['pay_period'].value==true">

              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (submitted || configForm.controls['pay_period_day'].touched || configForm.controls['pay_period_day'].dirty) &&  configForm.controls['pay_period_day'].errors}" autocomplete="off" id="pay_period_day" placeholder="{{this.messageService.placeholderdisp('workdays')}}" formControlName="pay_period_day" name="pay_period_day">

              <div *ngIf="(submitted || configForm.controls['pay_period_day'].touched || configForm.controls['pay_period_day'].dirty) && configForm.controls['pay_period_day'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['pay_period_day'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['pay_period_day'].errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="configForm.controls['pay_period_day'].errors?.['max'] && !(configForm.controls['pay_period_day'].errors?.pattern)">{{this.messageService.fieldlengthvalidation('days','30')}}
                  </div>
              </div>
          </div>

          <!-- end -->
            <ng-container  *ngIf="configForm.controls['attendance_lop'].value==true">

            <div class="col-lg-12 form-row ">
                <label for="" class="form-label required">Exclude weekoff’s</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="If your organization desires to exclude week offs as paid days, then choose the yes option and select the week offs from the below list. If your organization considers week-offs as paid, then select the option No." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input   class="form-check-input"  type="radio" formControlName="exclude_weekoff_status" [value]="true" (ngModelChange)="setDisabled('exclude_weekoff_status','exclude_weekoff')">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                        <input   class="form-check-input" type="radio"  formControlName="exclude_weekoff_status" [value]="false" (ngModelChange)="setDisabled('exclude_weekoff_status','exclude_weekoff')">
                        No
                    </label>
                </div>
            </div>

            <!-- <div class="row row-16 pt-16"> -->
              <div class=" col-12 form-row" *ngIf="configForm.controls['exclude_weekoff_status'].value==true" [ngClass]="{ 'is-invalid': (configForm.controls['exclude_weekoff'].touched && configForm.controls['exclude_weekoff'].errors) }">
                  <label for="" class="form-label required">Select weekoff’s below </label>
                  <ng-select
                      [ngClass]="{ 'is-invalid': (configForm.controls['exclude_weekoff'].touched && configForm.controls['exclude_weekoff'].errors) }"
                      placeholder="{{this.messageService.selectplaceholddisplay('weekoff’s')}}"
                      formControlName="exclude_weekoff"
                      [items]="weekdays"
                      [multiple]="true"
                      groupBy="selectedAllGroup"
                      [selectableGroup]="true"
                      [selectableGroupAsModel]="false"
                      bindLabel="name"
                      [closeOnSelect]="false"
                      bindValue="id"
                      class="form-ngselect "
                      >
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                        </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="configForm.value.exclude_weekoff.length">
                              <span class="ng-value-label">{{configForm.value.exclude_weekoff.length}} Week off selected.</span>
                          </div>
                      </ng-template>
                      <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />


                       <span class="">{{ (item.name.length>30)? (item.name | slice:0:30)+'..':(item.name)  }}</span>
                      </div>
                  </ng-template>
                  </ng-select>
                  <div *ngIf="configForm.controls['exclude_weekoff'].touched && configForm.controls['exclude_weekoff'].errors" class="invalid-feedback">
                      <div *ngIf="configForm.controls['exclude_weekoff'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  </div>
              </div>
              <div class="col-12">
                  <ul class="selected-values " *ngIf="configForm.value.exclude_weekoff?.length !=0">
                      <li class="ng-value" *ngFor="let item of configForm.value.exclude_weekoff, let m =index" >
                          <ng-container *ngFor="let insideitem of weekdays, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item" title="{{insideitem.name}}">
                          <div class="d-inline-flex align-items-center">

                              <span class=""> {{ (insideitem['name'].length>25)? (insideitem['name'] | slice:0:25)+'..':(insideitem['name'])  }}</span>
                           </div>
                           </span>
                          </ng-container>
                          <span  class="ng-value-icon right" aria-hidden="true" (click)="clear(m)"><i class="icon-close"></i></span>
                     </li>
                  </ul>
              </div>

            <!-- </div> -->
          </ng-container>

          <!-- <ng-container  *ngIf="configForm.controls['attendance_lop'].value==false">
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label">Exclude holidays</label>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input   class="form-check-input"  type="radio" formControlName="exclude_holiday_status" [value]="true" >
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                        <input   class="form-check-input" type="radio" formControlName="exclude_holiday_status" [value]="false" >
                        No
                    </label>
                </div>
            </div>
          </ng-container> -->

          <div class="col-12 mt-32">
              <div class="fw-500 text-uppercase">INVESTMENT DECLARATION</div>
          </div>
          <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Cut-off date for investment declaration in a month  </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Proposed declarations should be sumbitted before the cut-off date in a month. proposed investment declaration is locked one week prior before processing the salary. Investments declared after the cut-off date will not be considered and it has to be re-submitted again for the next month." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
              </div>
              <div class="col-lg-6 form-row mt-0">
                  <label for="" class="form-label required">From </label>
                  <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || configForm.controls['proposed_declaration_fromdate'].touched) &&  configForm.controls['proposed_declaration_fromdate'].errors}" [placeholder]="'Select date'" id="proposed_declaration_fromdate" [submitted]="submitted"
                  [isrequired]="'true'" [selectedItem]="configForm.controls['proposed_declaration_fromdate'].value" formControlName="proposed_declaration_fromdate" [type]="'schedule_dates'"  [range]="28" [lastDay]="true"></app-master-select>

                  <div *ngIf="(submitted || configForm.controls['proposed_declaration_fromdate'].touched || configForm.controls['proposed_declaration_fromdate'].dirty) && configForm.controls['proposed_declaration_fromdate'].errors " class="invalid-feedback">
                      <div *ngIf="configForm.controls['proposed_declaration_fromdate'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="configForm.controls['proposed_declaration_fromdate'].pattern">{{this.messageService.validationDisplay('pattern')}}
                      </div>
                  </div>
              </div>


              <div class="col-lg-6 form-row mt-0">
                  <label for="" class="form-label required">To </label>
                  <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || configForm.controls['proposed_declaration_todate'].touched) &&  configForm.controls['proposed_declaration_todate'].errors}" [placeholder]="'Select date'" id="proposed_declaration_todate" [submitted]="submitted"
                  [isrequired]="'true'" [selectedItem]="configForm.controls['proposed_declaration_todate'].value" formControlName="proposed_declaration_todate" [type]="'schedule_dates'"  [range]="28" [lastDay]="true"></app-master-select>

                  <div *ngIf="(submitted || configForm.controls['proposed_declaration_todate'].touched || configForm.controls['proposed_declaration_todate'].dirty) && configForm.controls['proposed_declaration_todate'].errors " class="invalid-feedback">
                      <div *ngIf="configForm.controls['proposed_declaration_todate'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="configForm.controls['proposed_declaration_todate'].pattern">{{this.messageService.validationDisplay('pattern')}}
                      </div>
                  </div>
              </div>


          <div class="col-lg-12 form-row ">
                  <label for="" class="form-label required">Cut-off date for proposed investment declaration in a financial year  </label>
                  <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Set the Cut-off date for the employees to submit the proposed declaration during the financial year. Proposed declarations submitted after the cut-off date will not be considered and excess of tax will be deducted." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
          <div class="col-lg-6 form-row mt-0">
              <label for="" class="form-label required">Month </label>


                <app-master-select class="config-app-master"   [ngClass]="{ 'is-invalid': (submitted || configForm.controls['proposed_declaration_fy_month'].touched) &&  configForm.controls['proposed_declaration_fy_month'].errors}" [placeholder]="'Select month'" id="proposed_declaration_fy_month" [submitted]="submitted"
                [isrequired]="'true'" [selectedItem]="configForm.controls['proposed_declaration_fy_month'].value" formControlName="proposed_declaration_fy_month" [type]="'listOfMonth'"></app-master-select>

                <div *ngIf="(submitted ||configForm.controls['proposed_declaration_fy_month'].touched) && configForm.controls['proposed_declaration_fy_month'].errors" class="invalid-feedback">
                    <div *ngIf="configForm.controls['proposed_declaration_fy_month'].errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
          </div>
          <div class="col-lg-6 form-row mt-0">
              <label for="" class="form-label required">Date </label>
              <!-- <ng-select class="form-ngselect" placeholder="Select Date" formControlName="proposed_declaration_fy_date"
              [ngClass]="{ 'is-invalid': (submitted || configForm.controls['proposed_declaration_fy_date'].touched) &&  configForm.controls['proposed_declaration_fy_date'].errors && !configForm.controls['proposed_declaration_fy_date'].disabled}">
                  <ng-option *ngFor="let number of createRange(31)" [value]="number">{{ ordinal_suffix_of(number) }}</ng-option>
              </ng-select> -->

              <app-master-select  class="config-app-master" [ngClass]="{ 'is-invalid': (submitted || configForm.controls['proposed_declaration_fy_date'].touched) &&  configForm.controls['proposed_declaration_fy_date'].errors}" [placeholder]="'Select date'" id="proposed_declaration_fy_date" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="configForm.controls['proposed_declaration_fy_date'].value" formControlName="proposed_declaration_fy_date" [type]="'schedule_dates'"  [range]="28" [lastDay]="true"></app-master-select>

              <div *ngIf="(submitted || configForm.controls['proposed_declaration_fy_date'].touched || configForm.controls['proposed_declaration_fy_date'].dirty) && configForm.controls['proposed_declaration_fy_date'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['proposed_declaration_fy_date'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['proposed_declaration_fy_date'].pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>
              </div>
          </div>
          <div class="col-lg-12 form-row ">
              <label for="" class="form-label">Require approval of any changes in proposed investment declaration submitted before financial year cut-off date </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="By default in SkizzleHR, the proposed investment declaration raised by an employee goes for approval as per the approval matrix. If you want to enable this option, then select yes or If your organization does not follow any approval matrix for the proposed investment declaration, then select the option no. " popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input   class="form-check-input"  type="radio" formControlName="proposed_required_approval_beforecutoff" [value]="true">
                      Yes
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input   class="form-check-input" type="radio"  formControlName="proposed_required_approval_beforecutoff" [value]="false">
                      No
                  </label>
              </div>
          </div>
          <div class="col-lg-12 form-row ">
              <label for="" class="form-label">Allow employee to submit proposed investment declaration after financial year cut-off date </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover='"If your organization wants to allow a grace period for their employees who haven’t submitted their declarations on or before the cut-off day, then choose  "Yes" or else select "No""' popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                      <input   class="form-check-input"  type="radio" formControlName="proposed_allow_submit_aftercuttoff" [value]="true" (ngModelChange)="setDisabled('proposed_allow_submit_aftercuttoff','proposed_required_approval_aftercuttoff')">
                      Yes
                  </label>
              </div>
              <div class="form-check form-check-inline" >
                  <label class="form-check-label" >
                      <input   class="form-check-input" type="radio"  formControlName="proposed_allow_submit_aftercuttoff" [value]="false" (ngModelChange)="setDisabled('proposed_allow_submit_aftercuttoff','proposed_required_approval_aftercuttoff')">
                      No
                  </label>
              </div>
          </div>
          <ng-container *ngIf="configForm.controls['proposed_allow_submit_aftercuttoff'].value==true">
            <div class="col-lg-12 form-row ">
                <label for="" class="form-label">Require approval of any changes in proposed investment declaration after the financial year cut-off date </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Select the option Yes if approval is required for proposed investment declaration submitted after cut-off date or else select the option No." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" >
                        <input   class="form-check-input"  type="radio" formControlName="proposed_required_approval_aftercuttoff" [value]="true" >
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline" >
                    <label class="form-check-label" >
                        <input   class="form-check-input" type="radio"  formControlName="proposed_required_approval_aftercuttoff" [value]="false">
                        No
                    </label>
                </div>
            </div>
          </ng-container>
          <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Cut-off date for confirmed investment declaration in a financial year </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Similar to the proposed investment declaration, you can set a cutoff date for your employees to submit a confirmed investment declaration on time. Select the relevant month and the date from the respective drop-downs below." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
          </div>
          <div class="col-lg-6 form-row mt-0">
              <label for="" class="form-label required">Month </label>

                <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || configForm.controls['confirmed_declaration_fy_month'].touched) &&  configForm.controls['confirmed_declaration_fy_month'].errors}" [placeholder]="'Select month'" id="confirmed_declaration_fy_month" [submitted]="submitted"
                [isrequired]="'true'" [selectedItem]="configForm.controls['confirmed_declaration_fy_month'].value" formControlName="confirmed_declaration_fy_month" [type]="'listOfMonth'"></app-master-select>

                <div *ngIf="(submitted ||configForm.controls['confirmed_declaration_fy_month'].touched) && configForm.controls['confirmed_declaration_fy_month'].errors" class="invalid-feedback">
                    <div *ngIf="configForm.controls['confirmed_declaration_fy_month'].errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
          </div>
          <div class="col-lg-6 form-row mt-0">
              <label for="" class="form-label required">Date </label>

              <app-master-select class="config-app-master"  [ngClass]="{ 'is-invalid': (submitted || configForm.controls['confirmed_declaration_fy_date'].touched) &&  configForm.controls['confirmed_declaration_fy_date'].errors}" [placeholder]="'Select date'" id="confirmed_declaration_fy_date" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="configForm.controls['confirmed_declaration_fy_date'].value" formControlName="confirmed_declaration_fy_date" [type]="'schedule_dates'"  [range]="28" [lastDay]="true"></app-master-select>

              <div *ngIf="(submitted || configForm.controls['confirmed_declaration_fy_date'].touched || configForm.controls['confirmed_declaration_fy_date'].dirty) && configForm.controls['confirmed_declaration_fy_date'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['confirmed_declaration_fy_date'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['confirmed_declaration_fy_date'].pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>
              </div>
          </div>
          <div class="col-12 form-row">
            <label for="" class="form-label required mb-8">Do you want to show the confirmed investment alert in the announcement? </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="Select yes if you want to send an alert for confirmed investment." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
          <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                  <input class="form-check-input" name="send_confirm_investment_alert" formControlName = "send_confirm_investment_alert" type="radio" id="send_confirm_investment_alert1" [value]=true checked (ngModelChange)="setDisabled('send_confirm_investment_alert','specify_days')">
                  <label class="form-check-label" for="send_confirm_investment_alert1">
                      Yes
                  </label>
              </div>
              <div class="form-check form-check-inline">
                  <input class="form-check-input" name="send_confirm_investment_alert" formControlName = "send_confirm_investment_alert" type="radio" id="send_confirm_investment_alert2" [value]=false (ngModelChange)="setDisabled('send_confirm_investment_alert','specify_days')">
                  <label class="form-check-label" for="send_confirm_investment_alert2">
                      No
                  </label>
              </div>
          </div>
          <div *ngIf="this.configForm.get('send_confirm_investment_alert')?.value" class="col-lg-12 form-row">
            <label for="" class="form-label required">Specify days
            </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="Confirmed investment declaration alert will be displayed in the announcement before the specified days. By default, the alert is triggered 30 days before the closing of the confirmed investment declaration window. (edited)" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
          <div *ngIf="this.configForm.get('send_confirm_investment_alert')?.value" class="col-lg-12 form-row mt-0">
            <input type="text"  max="100" class="form-control" name="specify_days" formControlName = "specify_days" id=""
                placeholder="{{this.messageService.placeholderdisp('days')}}" [ngClass]="{ 'is-invalid' : (submitted || configForm.controls['specify_days'].dirty || configForm.controls['specify_days'].touched) && configForm.controls['specify_days'].errors}">
            <div *ngIf="(submitted || configForm.controls['specify_days'].touched || configForm.controls['specify_days'].dirty) && configForm.controls['specify_days'].errors " class="invalid-feedback">
                <div *ngIf="configForm.controls['specify_days'].errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
            </div>
            <div *ngIf="configForm.controls['specify_days'].errors?.['pattern']" class="invalid-feedback">{{this.messageService.validationDisplay('pattern')}}</div>

            <div *ngIf="configForm.controls['specify_days'].errors?.['max'] && !configForm.controls['specify_days'].errors?.['pattern']" class="invalid-feedback">
              {{this.messageService.fieldlengthvalidation('days','365')}}                         </div>
          </div>
          <div class="col-12">
            <div class="fw-500 text-uppercase">Loan</div>
          </div>
            <div class="col-12 form-row">
              <label for="loan_amount" class="form-label required ">
                Can the loan amount and EMI be edited by task owner when processing the loan
              </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="If this option is enabled, the task owner will have permission to modify the loan amount and EMI submitted by the requester during loan processing."
                popoverClass="popover-default popover-info" placement="bottom" container="body"
                triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check form-check-inline">
                <input type="radio" id="loan_amount_yes" class="form-check-input" name="can_edit_loanAmount_emi_by_owner" formControlName="can_edit_loanAmount_emi_by_owner" [value]='true'>
                <label for="loan_amount_yes" class="form-check-label">Yes </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="loan_amount_no" class="form-check-input" name="can_edit_loanAmount_emi_by_owner" formControlName="can_edit_loanAmount_emi_by_owner" [value]="false">
                <label for="loan_amount_no" class="form-check-label">No </label>
              </div>
            </div>
            <div class="col-12 form-row">
              <label for="loan_interest" class="form-label required">
                Can the loan interest be edited by the task owner when processing the loan
              </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="If this option is enabled, the task owner will have the permission to modify the loan interest rate during loan processing."
                popoverClass="popover-default popover-info" placement="bottom" container="body"
                triggers="hover"></i></span>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check form-check-inline">
                <input type="radio" id="loan_int_yes" class="form-check-input" name="can_edit_loanInterest_by_owner" formControlName="can_edit_loanInterest_by_owner" [value]="true">
                <label for="loan_int_yes" class="form-check-label">Yes </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="loan_int_no" class="form-check-input" name="can_edit_loanInterest_by_owner" formControlName="can_edit_loanInterest_by_owner" [value]="false">
                <label for="loan_int_no" class="form-check-label">No </label>
              </div>
            </div>
          <div class="col-12">
            <div class="fw-500 text-uppercase">REIMBURSEMENT AND FBP</div>
          </div>

          <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Monthly entitlement day </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="Select the date on which the reimbursement amount has to be credited to the employees. By default the entitlement date is fixed as the 1st of every month" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <app-master-select class="config-app-master" [dropdownPosition]="'top'" [ngClass]="{ 'is-invalid': (submitted || configForm.controls['reimbursement_entitlement_day'].touched) &&  configForm.controls['reimbursement_entitlement_day'].errors}" [placeholder]="'Select date'" id="reimbursement_entitlement_day" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="configForm.controls['reimbursement_entitlement_day'].value" formControlName="reimbursement_entitlement_day" [type]="'schedule_dates'" [range]="28" [lastDay]="true"></app-master-select>

              <div *ngIf="(submitted || configForm.controls['reimbursement_entitlement_day'].touched || configForm.controls['reimbursement_entitlement_day'].dirty) && configForm.controls['reimbursement_entitlement_day'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['reimbursement_entitlement_day'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['reimbursement_entitlement_day'].pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>
              </div>
          </div>

          <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Cut-off date for reimbursement claim request in a month </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="This option enables you to set a cut-off date for your employees to submit bills for reimbursement claims. Select the appropriate date from the drop-down below. Any reimbursement request raised by the employee after the cutoff date will not be considered for current month payroll." placement="bottom" container="body" triggers="hover" popoverClass="popover-default popover-info"></i></span>



          </div>
           <div class="col-lg-6 form-row mt-0">
              <label for="" class="form-label required">From </label>
              <app-master-select class="config-app-master" [dropdownPosition]="'top'" [ngClass]="{ 'is-invalid': (submitted || configForm.controls['reimbursement_request_fromdate'].touched) &&  configForm.controls['reimbursement_request_fromdate'].errors}" [placeholder]="'Select date'" id="reimbursement_request_fromdate" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="configForm.controls['reimbursement_request_fromdate'].value" formControlName="reimbursement_request_fromdate" [type]="'schedule_dates'" [range]="28" [lastDay]="true"></app-master-select>

              <div *ngIf="(submitted || configForm.controls['reimbursement_request_fromdate'].touched || configForm.controls['reimbursement_request_fromdate'].dirty) && configForm.controls['reimbursement_request_fromdate'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['reimbursement_request_fromdate'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['reimbursement_request_fromdate'].pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>
              </div>
           </div>


           <div class="col-lg-6 form-row mt-0">
              <label for="" class="form-label required">To </label>
              <app-master-select class="config-app-master" [dropdownPosition]="'top'" [ngClass]="{ 'is-invalid': (submitted || configForm.controls['reimbursement_request_todate'].touched) &&  configForm.controls['reimbursement_request_todate'].errors}" [placeholder]="'Select date'" id="reimbursement_request_todate" [submitted]="submitted"
              [isrequired]="'true'" [selectedItem]="configForm.controls['reimbursement_request_todate'].value" formControlName="reimbursement_request_todate" [type]="'schedule_dates'" [range]="28" [lastDay]="true"></app-master-select>

              <div *ngIf="(submitted || configForm.controls['reimbursement_request_todate'].touched || configForm.controls['reimbursement_request_todate'].dirty) && configForm.controls['reimbursement_request_todate'].errors " class="invalid-feedback">
                  <div *ngIf="configForm.controls['reimbursement_request_todate'].errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="configForm.controls['reimbursement_request_todate'].pattern">{{this.messageService.validationDisplay('pattern')}}
                  </div>
              </div>
           </div>

           <!-- FBP configure start -->
           <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">Specify maximum duration for FBP declaration </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon"  ngbPopover="Specify the days for the FBP declaration window. By default, the value is set as 15 days. FBP declaration window will close after the specified days." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
           </div>
           <div class="col-lg-6 form-row mt-0">
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (submitted || configForm.controls['fbp_duration_day'].touched || configForm.controls['fbp_duration_day'].dirty) &&  configForm.controls['fbp_duration_day'].errors}" autocomplete="off" id="fbp_duration_day" placeholder="{{this.messageService.placeholderdisp('duration')}}" formControlName="fbp_duration_day" name="fbp_duration_day">

            <div *ngIf="(submitted || configForm.controls['fbp_duration_day'].touched || configForm.controls['fbp_duration_day'].dirty) && configForm.controls['fbp_duration_day'].errors " class="invalid-feedback">
              <div *ngIf="configForm.controls['fbp_duration_day'].errors.required">{{this.messageService.validationDisplay('required')}}</div>


            </div>
            <div *ngIf="configForm.controls['fbp_duration_day'].errors?.['pattern']">{{this.messageService.validationDisplay('pattern')}}
            </div>

            <div *ngIf="configForm.controls['fbp_duration_day'].errors?.['max'] && !configForm.controls['fbp_duration_day'].errors?.['pattern']" class="invalid-feedback">
              {{this.messageService.fieldlengthvalidation('days','90')}}
            </div>

            <div *ngIf="configForm.controls['fbp_duration_day'].errors?.['min'] && !configForm.controls['fbp_duration_day'].errors?.['pattern']" class="invalid-feedback">
              {{this.messageService.fieldlengthvalidation('minvalue','0')}}
            </div>

           </div>
           <!-- FBP configure end -->

           <div class="col-12 mt-32">
              <div class="fw-500 ">Reimbursement type</div>
          </div>
          <div class="col-12">
              <div class="form-check" >
                  <label class="form-check-label" >
                      <input   class="form-check-input" type="radio" formControlName="reimbursement_type" value="Tax exemption provided on reimbursement component till year end. Non bill submitted amount taxed at year end" >Tax exemption provided on reimbursement component till year end. non bill submitted amount taxed at year end
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="The reimbursement component amount will be paid with the monthly salary by default, and TDS for unbilled amounts will be deducted at the end of the financial year." placement="bottom" container="body" triggers="hover" popoverClass="popover-default popover-info"></i></span>
                  </label>
                  <label class="form-check-label mt-16" >
                      <input   class="form-check-input" type="radio" formControlName="reimbursement_type" value="Reimbursement component paid every month as taxable component unless bills are submitted" >Reimbursement component paid every month as taxable component unless bills are submitted
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="TDS for the reimbursement component will be deducted on each month if the bills are not submitted before cut-off date." placement="bottom" container="body" triggers="hover" popoverClass="popover-default popover-info"></i></span>
                  </label>
                  <label class="form-check-label mt-16" >
                      <input   class="form-check-input" type="radio" formControlName="reimbursement_type" value="Reimbursement component paid against bill submission. If no bills submitted, paid as taxable component at financial year end">Reimbursement component paid against bill submission. If no bills submitted, paid as taxable component at financial year end
                      <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="The reimbursement component will be paid on a claim basis, TDS will be deducted for non-bill submitted amounts, and the balance will be paid on the March month payroll." placement="bottom" container="body" triggers="hover" popoverClass="popover-default popover-info"></i></span>

                  </label>
              </div>
          </div>

      </div>
  </div>
  <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto  text-uppercase" [disabled]="(!configForm.valid || selectedComp.length==0) || saveClicked" (click)="submitMethod()">SAVE</button>
  </footer>
</form>
