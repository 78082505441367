    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500 mb-24">template</p>
        </div>
        <div class="row row-24">
            <div class="col-12" >
                <div class="card card-c2 ">
                    <div class="card-body p-24 ">
                        <p class="mb-24 text-uppercase fw-500">Download template</p>
                        <button class="btn btn-outline-primary radius-2 px-5" (click)="download()">Download</button>  
                    </div>
                </div>
            </div>
            <div class="col-12" >
                <div class="card card-c2 ">
                    <div class="card-body p-24 ">
                        <p class="mb-24 text-uppercase fw-500">Instructions</p>
                        <ol class="fs-14 ps-16 mb-0 li-24">
                            <li> Download the template.</li>
                            <li> Downloaded template will be based on the datatype selected.</li>
                            <li> Click on Next </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto " (click)="goNext(4)">NEXT</button>
    </footer>
