
import { environment } from "src/environments/environment";
import { HttpClient, HttpClientModule, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { UploadAdapterService } from "src/app/@core/services/upload-adapter.service";
import { FILE } from "dns";

export class MyUploadAdapter {
    loader: any;
    imageName: any;
    xhr: any;
    public data_val: any;
    constructor( 
        loader: any,
        private http: HttpClient,
        private sendUrl: UploadAdapterService) {
        this.loader = loader;
    }
    public httpOption: any = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response'
      };
      

    upload() {

        return this.loader.file
            .then( (file: any) => new Promise( ( resolve, reject ) => {
                this._initRequest();
                this._initListeners( resolve, reject, file );
                this._sendRequest( file );
            } ) );
       

    }
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open( 'POST', `${environment.apiPath}api/v1/azure-upload/`, true ); // TODO change the URL
        xhr.responseType = 'json';
    xhr.setRequestHeader("Accept", "application/json");
    }
    _initListeners( resolve: { (value: unknown): void; (arg0: { default: any; }): void; }, reject: { (reason?: any): void; (arg0: string | undefined): any; }, file: { name: any; } ) {
        const xhr = this.xhr;
        const loader = this.loader;
        // console.log(typeof(file));
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;
        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            // console.log('LLL Load');
            const response = xhr.response;
            // console.log('response', response)
            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }
            resolve( {
                default: response.data_url
            } );
        } );
        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', (evt: { lengthComputable: any; total: any; loaded: any; }) => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }

    fileUpload(data: any) {
        return this.http.post<any>(`${environment.apiPath}api/v1/azure-upload/`, data, this.httpOption);
      }

    _sendRequest( file: Blob) {
        this.imageName=this.loader.file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = event => {
            this.data_val = reader.result;
            const data = new FormData();
            data.append( 'name', this.imageName);
            data.append( 'dataURL', this.data_val);
            this.xhr.send(data);
        }
    }
}