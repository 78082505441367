<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row mb-16">
      <div class="col-md-4 col-lg-6">
        <p class="text-uppercase fw-500 mb-0">ADVANCE DETAILS</p>
      </div>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex justify-content-end  gap-16">
          <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="getApplyAdvanceData()"><i class="icon-plus"></i>Add
            Advances </button>
        </div>
      </div>
    </div>
    <div class="row row-16">

      <app-c1-loader *ngIf="advanceListLoader"></app-c1-loader>
      <ng-container *ngIf="!advanceListLoader">
      <div class="col-12">
        <div class="card card-c2">
          <div class="table-responsive radius-4">
            <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
              <thead>
                <tr>

                  <th class="fw-600 text-uppercase" scope="col">Advance number</th>
                  <th class="fw-600 text-uppercase" scope="col">Date</th>
                  <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                  <th class="fw-600 text-uppercase" scope="col">DISPENSED AMOUNT</th>
                  <th class="fw-600 text-uppercase" scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody class="cell-16">

                <ng-container *ngIf="advanceListData?.length == 0">
                  <tr>
                    <td colspan="5">
                      <div class="card card-c2 flex-center p-16 bg-dark-500">
                        <span class="fw-500 fs-14">No data found</span>
                      </div>
                    </td>
                  </tr>
                </ng-container>

                <ng-container *ngFor='let advance of $any(advanceListData|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize);let i=index;'>
                  <tr>

                    <td><a class="link-primary1" (click)="viewAdvanceFunction(advance?.id)">{{advance?.number}}</a></td>
                    <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(advance?.expense_date)}}</span></td>
                    <td>{{advance?.amount | currency : currency}}</td>
                    <td>{{advance?.dispansed_amount | currency : currency}}</td>
                    <td><a class="link-primary1" (click)="removeAdvance(advanceListData.indexOf(advance),advance)">Remove</a></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="this.appService.filteredcount(advanceListData,searchString)>pageSize">
        <ngb-pagination class="d-flex justify-content-end"
          [collectionSize]="this.appService.filteredcount(advanceListData,searchString)" [(page)]="page" [maxSize]="3"
          [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
      </div>
      </ng-container>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" (click)="changeTab()">NEXT</button>
  </footer>
</form>

<app-expense-apply-advance *ngIf="applyAdvance" [from]="'reportRequest'" [applyAdvance]="applyAdvance" [applyAdvanceData]="applyAdvanceData" [currency]="currency" [loader]="addAdvanceLoader" (addadvance)="addadvance($event)" (closeadvance)="closeadvance()"></app-expense-apply-advance>

<app-emp-advance-details-view *ngIf="viewAdvance" [viewAdvance]="viewAdvance" [viewdata]="viewdata" [advanceid]="advanceid" [viewLoader]="viewLoader" [currency]="currency" [from]="'expenseAdvanceRequestView'"  (panelclose)="closefnview($event)"> </app-emp-advance-details-view>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16rem">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Advance?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Advance')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteAdvance()">Delete</button>
      </div>
    </div>
  </div>
</div>
