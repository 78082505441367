import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-fnf-holiday-approval',
  templateUrl: './fnf-holiday-approval.component.html',
  styleUrls: ['./fnf-holiday-approval.component.scss']
})
export class FnfHolidayApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() holiday:any;
  @Input() empId:any;
  @Input() archieved_status:any;
  @Input() reqId:any
  showTrend = false;

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  constructor(public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,public appService: AppService,private datePipe:DatePipe,private notificationService: NotificationService) { }

  ngOnInit(): void {

  }


form = this.fb.group({
  read_status: [true,Validators.required],
  star: [false,Validators.required],
  approved_status: [false,Validators.required],
  comment: ['', Validators.maxLength(500)],
})

get f() {return  this.form.controls; }

confirm(status:any){

  if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
    this.unprocessWarningPopup.emit();
  }else{
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form.controls.comment.valid){
      this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
        if(res){
          this.refreshInbox(false)
          this.form.get('comment')?.reset('')

        }
      })
    }

  }
}


  // test start

  refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

// test end

}
