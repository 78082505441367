<nav class="nav nav-custom sticky">
  <a class="nav-link" [ngClass]="{'active': activeToggle == ''}" (click)="statusFilter('')">All</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Pending'}" (click)="statusFilter('Pending')">Pending</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Accepted'}" (click)="statusFilter('Accepted')">Accepted</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Rejected'}" (click)="statusFilter('Rejected')">Rejected</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Draft'}" (click)="statusFilter('Draft')">Draft</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Reimbursed'}" (click)="statusFilter('Reimbursed')">Reimbursed</a>
</nav>
<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4">
      <h3>Expense Reports</h3>
      <p class="text-helper mb-md-0">Create and manage your expense reports on this page.</p>
    </div>
    <div class="col">
      <div class="d-flex justify-content-end  gap-16">
        <div style="width:22.6875rem;">
          <div class="">
            <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='reportPlaceholder' (searchEmitter)="searchresults($event)" [isLoading]="sLoading"  (keywordsearchEmitter)="searchReport($event)"></app-searchbar>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block" (click)="filter=true">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>

        </div>
        <ng-container>
          <button class="btn flex-shrink-0 btn-outline-primary btn-icon btn-add" (click)="addExpenseReportRequest()"><i
              class="icon-plus"></i>EXPENSE REPORT REQUEST</button>
          <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="newReport=true;editId=0;"><i class="icon-plus"></i>ADD new
            REPORT</button>
        </ng-container>
      </div>
    </div>
  </div>
  <app-table-loader *ngIf="loader"></app-table-loader>

  <div class="row row-16" *ngIf="!loader">
    <div class="col-12" *ngIf="landingScreenData?.length<=0">
      <div class="row" style="height: calc(100vh - 13rem);">
        <app-nodata-view class="vstack" [noDataImg]="'bg2.png'" [noDataText]="'Let\'s Manage Your Expenses'"
          [noDataPara]="'Create and manage your expense reports effortlessly. Keep track of all your expense details and summaries!'"
          [hasPermission]="false"> </app-nodata-view>
      </div>
    </div>
    <div class="col-12" *ngIf="landingScreenData?.length>0">
      <div class="card card-c2">
        <div class="table-responsive radius-4">

          <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
            <thead>
              <tr>
                <th class="fw-600 text-uppercase" scope="col">REPORT NUMBER</th>
                <th class="fw-600 text-uppercase" scope="col">Report name</th>
                <th class="fw-600 text-uppercase" scope="col">Report Date</th>
                <th class="fw-600 text-uppercase" scope="col">total</th>
                <th class="fw-600 text-uppercase" scope="col">to be reimbursed</th>
                <th class="fw-600 text-uppercase" scope="col">Status</th>
                <th class="fw-600 text-uppercase" scope="col" *ngIf="activeToggle=='' || activeToggle=='Draft'">Action</th>
              </tr>
            </thead>
            <tbody class="cell-16 ">
              <!-- <tr *ngIf="landingScreenData?.length<=0">
                <td colspan="7">
                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                      <span class="fw-500 fs-14">No data found</span>
                  </div>
                </td>
              </tr> -->

              <tr *ngFor="let item of $any(landingScreenData |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">

                  <td><a (click)="getSingleReportView(item?.id)" class="link-primary1">{{item?.number}}</a></td>
                  <td>{{item?.name}}</td>
                  <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(item?.from_date)}} - {{this.appService.dateFormatDisplay(item?.to_date)}}</span></td>
                  <td>{{(item?.total_amount == null || item?.total_amount == '') ? '-' : item?.total_amount | currency:currency}}</td>
                  <td>{{(item?.to_reimbursed == null || item?.to_reimbursed == '') ? '-' : item?.to_reimbursed | currency:currency}}</td>
                  <td><span class="badge {{statusList[item?.status]}} py-1 px-3">{{item?.status}}</span></td>
                  <td *ngIf="activeToggle=='' || activeToggle=='Draft'"><i class="icon-trash text-danger fs-18 pointer" (click)="deletConfirm(item?.id,'report',item?.status)"></i></td>

                </tr>


            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="this.appService.filteredcount(landingScreenData,searchString)>pageSize">
    <ngb-pagination class="d-flex justify-content-end"
      [collectionSize]="this.appService.filteredcount(landingScreenData,searchString)" [(page)]="page" [maxSize]="3"
      [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
  </div>
</div>

<app-add-new-report *ngIf="newReport" [newReport]="newReport" (submitreport)="submitreport()" (closereport)="closereport()" [editId]="editId"></app-add-new-report>

<div class="side-panel side-pane-active" style="--sidepanel-width:922px;"
  [class.side-pane-active]='viewReport === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="getLandingScreenData(this.activeToggle);viewReport = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewReportLoader"></app-view-loader>

        <div class="row row-24 mb-24" *ngIf="!viewReportLoader">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 ">REPORT DETAILS{{"("+singleReport?.number+")"}}</p>
            <a class="link-primary fs-14 ms-auto" (click)="editReportData(singleReport?.id)" *ngIf="singleReport?.status=='Draft' || singleReport?.status=='Rejected'"><i class="icon-pencil-square me-1"></i>
              Edit</a>
              <div class="d-flex align-items-center ms-auto gap-16 fs-14 "
                *ngIf="singleReport?.expense_slip!=null && singleReport?.expense_slip!=''">
                <a class="link-primary" (click)="viewDocument(singleReport?.expense_slip, 'Expense Slip')">
                  View slip
                </a>
                <span class="text-light-400">|</span>
                <a class="link-primary fs-14 d-flex align-items-center ms-auto"
                  (click)="this.appService.downloadFile(singleReport?.expense_slip,'Expense slip')">
                  <i class="icon-download me-1"></i>Download slip
                </a>
              </div>
          </div>
          <div class="col-12">
            <div class="card details-card px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">{{singleReport?.name}}</p>
                  <span
                      class="badge {{singleReport.status=='Reimbursed'?'badge-primary':'badge-warning'}} ms-auto py-1 px-3">{{singleReport.status=='Accepted'?'Awaiting Reimbursement':singleReport.status}}</span>
                </div>
                <div class="col-12">
                  <div class="card details-card p-16">
                    <div class="row row-16">
                      <div class=" col-12">
                        <div class="detail-title">Report Name</div>
                        <div class="detail-desc">{{singleReport?.name}}</div>
                      </div>
                      <div class=" col-12">
                        <div class="detail-title">Business Purpose</div>
                        <div class="detail-desc">{{singleReport?.business_purpose}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">From Date</div>
                        <div class="detail-desc">{{this.appService.dateFormatDisplay(singleReport?.from_date)}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">To Date</div>
                        <div class="detail-desc">{{this.appService.dateFormatDisplay(singleReport?.to_date)}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Total Amount </div>
                        <div class="detail-desc">{{singleReport?.total_amount | currency:currency}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Advance Amount </div>
                        <div class="detail-desc">{{singleReport?.advance_amount | currency:currency}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Non Reimbursible Amount </div>
                        <div class="detail-desc">{{singleReport?.non_reimbursable_amount | currency:currency}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Amount to be Reimbursed</div>
                        <div class="detail-desc">{{singleReport?.to_reimbursed | currency:currency}} </div>
                      </div>
                      <!-- <div class="col-sm-3 col-12">
                        <div class="detail-title">Trip</div>
                        <div class="detail-desc">Trip 1</div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="singleReport?.status=='Reimbursed'">
                  <div class="col-12 align-items-center d-flex">
                    <p class="title mb-8">Reimbursement Details</p>
                  </div>
                  <div class="card details-card p-16">
                    <div class="row row-16">
                      <div class="col-sm-3 col-12" *ngIf="singleReport?.reimbursement_detail?.reimbursement_mode=='Reimbursement via payroll'">
                        <div class="detail-title">Description</div>
                        <div class="detail-desc">{{singleReport?.reimbursement_detail?.description}}</div>
                      </div>
                      <div class="col-sm-3 col-12" *ngIf="singleReport.is_automatic_or_manual===false && singleReport.is_advance_refund_or_carry_forward">
                        <div class="detail-title">Have you received the balance advance?</div>
                        <div class="detail-desc">Yes, Employee has returned the balance amount.</div>
                      </div>
                      <div class="col-sm-6 col-12" *ngIf="singleReport.is_automatic_or_manual===false && singleReport.is_advance_refund_or_carry_forward == false">
                        <div class="detail-title">Have you received the balance advance?</div>
                        <div class="detail-desc">No, Employee can carry forward the balance advance to use it on future reports</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Reimbursed On</div>
                        <div class="detail-desc">{{this.appService.dateFormatDisplay(singleReport?.reimbursed_on)}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Reimbursement Mode</div>
                        <div class="detail-desc">{{singleReport?.reimbursement_detail?.reimbursement_mode}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="card details-card ">
                    <div class="card-body pb-0 pt-8">
                      <nav class="nav nav-custom sticky px-0">
                        <a class="nav-link" [ngClass]="{'active': viewToggle == 'expense'}" (click)="viewToggle='expense'">Expense</a>
                        <a class="nav-link" [ngClass]="{'active': viewToggle == 'advance'}" (click)="viewToggle='advance'">Advances & Refunds</a>
                      </nav>
                    </div>
                    <div class="card-body ">
                      <div class="row row-16">
                        <ng-container *ngIf="viewToggle=='expense';else advance">
                          <div class="col-12">
                            <div class="card card-c2">
                              <div class="table-responsive radius-4">
                                <table
                                  class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                                  <thead>
                                    <tr>
                                      <!-- <th scope="col" class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value="">
                                      </th> -->
                                      <th class="fw-600 text-uppercase" scope="col">Receipt number</th>
                                      <th class="fw-600 text-uppercase" scope="col">EXPENSE Date</th>
                                      <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                                      <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                                      <th class="fw-600 text-uppercase" scope="col">Report name</th>
                                      <th class="fw-600 text-uppercase" scope="col">ATTACHMENT</th>
                                      <th class="fw-600 text-uppercase" scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody class="cell-16 ">
                                    <tr *ngIf="singleReport?.receipts?.length<=0">
                                      <td colspan="7">
                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                            <span class="fw-500 fs-14">No data found</span>
                                        </div>
                                      </td>
                                    </tr>

                                    <ng-container  *ngFor="let item of $any(singleReport?.receipts | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                                    <tr [class]="item?.is_expired ? 'td-alert' : ''">
                                        <!-- <td scope="col" class="form-cell">
                                          <input class="form-check-input m-0" type="checkbox" value="">
                                        </td> -->
                                        <td>
                                          <a class="link-primary1" (click)="getSigleReceiptView(item?.id)">{{item?.number}}</a>

                                          <i *ngIf="item?.is_expired" class="fs-14 ms-8 icon-info-circle text-danger" ngbPopover="Expired" popoverClass="popover-default popover-info " container="body" triggers="hover"></i>

                                        </td>
                                        <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(item?.expense_date)}}</span></td>
                                        <td>{{item?.category.name}}</td>
                                        <td>{{item?.amount | currency:currency}}</td>
                                        <td>{{item?.report.name}}</td>
                                        <td>
                                          <span *ngIf="item?.attachments?.length==0" class="link-primary" (click)="addAttachment(ind,item?.id)">Add</span>
                                          <span *ngIf="item?.attachments?.length>0"class="link-primary" (click)="viewAttachment(item?.id,ind)"><i class="icon-eye fs-18 "></i></span>
                                        </td>
                                        <td *ngIf="singleReport?.status!='Draft' && singleReport?.status!='Rejected'">--</td>
                                        <td *ngIf="singleReport?.status=='Draft' || singleReport?.status=='Rejected'"><span class="link-primary" (click)="deletConfirm(item?.id,'receipt','')">Remove</span></td>
                                    </tr>
                                    </ng-container>


                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <ng-container *ngIf="singleReport?.receipts?.length>0">

                            <div class="col-12" *ngIf="this.appService.filteredcount(singleReport?.receipts,'')>pageSize">
                              <ngb-pagination class="d-flex justify-content-end"
                                [collectionSize]="this.appService.filteredcount(singleReport?.receipts,'')" [(page)]="page" [maxSize]="3"
                                [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-template #advance>
                          <div class="col-12">
                            <div class="card card-c2">
                              <div class="table-responsive radius-4">
                                <table
                                  class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                                  <thead>
                                    <tr>
                                      <!-- <th scope="col" class="form-cell">
                                        <input class="form-check-input m-0" type="checkbox" value="">
                                      </th> -->
                                      <th class="fw-600 text-uppercase" scope="col">ADVANCE NUMBER</th>
                                      <th class="fw-600 text-uppercase" scope="col">DATE</th>
                                      <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                                      <th class="fw-600 text-uppercase" scope="col">DISPENSED AMOUNT</th>
                                      <th class="fw-600 text-uppercase" scope="col">ACTION</th>

                                    </tr>
                                  </thead>
                                  <tbody class="cell-16 ">
                                    <tr *ngIf="singleReport?.advances?.length<=0">
                                      <td colspan="4">
                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                            <span class="fw-500 fs-14">No data found</span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr *ngFor="let item of $any(singleReport?.advances | slice: (page-1) * pageSize : page * pageSize),let ind = index">


                                        <td><a class="link-primary1">{{item?.number}}</a></td>
                                        <td><span class="text-nowrap">{{this.appService.dateFormatDisplay(item?.expense_date)}} </span></td>
                                        <td>{{item?.amount | currency:currency}}</td>
                                        <td>{{item?.dispansed_amount | currency:currency}}</td>
                                        <td *ngIf="singleReport?.status=='Accepted'">--</td>
                                        <td *ngIf="singleReport?.status!='Accepted'"><span class="link-primary" (click)="deleteClicked=false;deletConfirm(item?.id,'advance','')">Remove</span></td>
                                      </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="col-12" *ngIf="this.appService.filteredcount(singleReport?.advances,'')>pageSize">
                            <ngb-pagination class="d-flex justify-content-end"
                              [collectionSize]="this.appService.filteredcount(singleReport?.advances,'')" [(page)]="page" [maxSize]="3"
                              [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
                          </div>
                        </ng-template>
                      <ng-container *ngIf="singleReport?.status=='Rejected' || singleReport?.status=='Draft'">
                        <ng-container *ngIf="viewToggle=='expense';else advancefooter">

                          <div class="col-12 d-flex gap-8 ">
                            <span class="link-primary1 align-items-center d-flex fs-14" (click)="getUnreportedExpense()">
                              <i class="icon-plus-square-fill me-8"></i>Add Unreported Expenses
                            </span>
                            <span class="text-light-500">|</span>
                            <span class="link-primary1 align-items-center d-flex fs-14" (click)="addExpense=true;sideaddexp=true;">
                              <i class="icon-plus-square-fill me-8"></i>Add New Expenses
                            </span>
                          </div>
                        </ng-container>
                        <ng-template #advancefooter>
                          <div class="col-12 d-flex gap-8 ">
                            <span class="link-primary1 align-items-center d-flex fs-14" (click)="getAdvanceList()">
                              <i class="icon-plus-square-fill me-8"></i>Apply Advance
                            </span>

                          </div>
                        </ng-template>
                       </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm">
        Save
      </button>
    </footer> -->
  </div>
</div>

<app-add-new-expense *ngIf="addExpense" [from]="'receipt'" [addExpense]="addExpense" (saveReceipt)="saveReceipt($event)" (closeAddExpense)="closeAddExpense()"></app-add-new-expense>


<app-view-expense-receipt *ngIf="viewReciept" [from]="'expenseReceipt'" [viewReciept]="viewReciept" [viewRecieptLoader]="viewRecieptLoader" [viewRecieptData]="viewRecieptData" [currency]="currency" (closeView)="closeView()"></app-view-expense-receipt>



<app-add-unreported-expense *ngIf="unreportedExpense" [from]="'profile'" [unreportedExpense]="unreportedExpense" [unReportedReceiptList]="unReportedReceiptList" [loader]="unReportReceiptLoader" (addreceipt)="mapUnreportedExpense($event)" (closeUnReportedReceipt)="this.getLandingScreenData(this.activeToggle);closeReceipt()" [currency]="currency"></app-add-unreported-expense>


<app-expense-apply-advance *ngIf="applyAdvance" [from]="'profile'" [applyAdvance]="applyAdvance" [applyAdvanceData]="applyAdvanceData" [currency]="currency" [loader]="addAdvanceLoader" (addadvance)="addadvance($event)" (closeadvance)="closeadvance()"></app-expense-apply-advance>


<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16rem">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Delete {{deleteLabel | titlecase}}?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">This will delete the {{deleteLabel}} permanently. You cannot restore it again.</p>
          </div>
          <div class="modal-footer" >
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="deleteToggle = false">Cancel</button>
              <button   type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteData()">Delete</button>
          </div>
      </div>
  </div>
</div>
<div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='filter === true'  [formGroup]="filterForm">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="closefn()"><i class=" icon-close-lg fs-16"></i></a>

    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 ">

          <div class="col-12">
            <label for="name" class="form-label ">From Date</label>
            <div class="input-group form-icon icon-right">
              <input (click)="datePicker1.open()" class="form-control"
                placeholder="{{this.messageService.selectplaceholddisplay('From date')}}" matInput
                formControlName="from_date" [matDatepicker]="datePicker1" [min]="datemin" [matDatepickerFilter]="yearRestrictFilter"><i
                class="icon-calendar pointer" (click)="datePicker1.open()" ></i>
              <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
            </div>
          </div>
          <div class="col-12">
            <label for="name" class="form-label ">To Date</label>
            <div class="input-group form-icon icon-right"
              [ngClass]="{ 'is-invalid':f.to_date.touched && (filterForm.errors!=null)}">
              <input [ngClass]="{ 'is-invalid':f.to_date.touched && (filterForm.errors!=null)}"
                (click)="datePicker2.open()" class="form-control"
                placeholder="{{this.messageService.selectplaceholddisplay('To date')}}" matInput
                formControlName="to_date" [matDatepicker]="datePicker2" [min]="datemin" [matDatepickerFilter]="yearRestrictFilter"><i
                class="icon-calendar pointer" (click)="datePicker2.open()"></i>
              <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
            </div>
            <div *ngIf="f.to_date.touched && (filterForm.errors!=null)" class="invalid-feedback">
              <div *ngIf="filterForm.errors!=null">{{this.messageService.greaterThanMessage('From Date','To Date')}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetFilter()">
        reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="applyFilter()" [disabled]="filterForm.invalid">
        Apply
      </button>
    </footer>
  </div>
</div>
<app-view-multiple-attachments *ngIf="viewAttchments" [from]="'profile'" [viewAttchments]="viewAttchments" [uploadedAttachments]="uploadedAttachments" [receiptId]="receiptId" [isAttachmentRequired]="isAttachmentRequired" (closeViewAttachment)="closeViewAttachment()" (saveViewAttachment)="saveViewAttachment($event)"></app-view-multiple-attachments>
<app-upload-multiple-attachments *ngIf="uploadeAttachments" [from]="'profile'" [uploadeAttachments]="uploadeAttachments" [fileType]="'.pdf,.doc'" [receiptId]="receiptId" [finalReceiptAttachment]="finalReceiptAttachment" (closeAttachment)="closeAttachment()" (saveAttachment)="saveAttachment($event)"></app-upload-multiple-attachments>
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="reportReqMsg"></app-info-popup>
</ng-container>
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
    [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
