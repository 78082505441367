import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssetReportServiceService } from 'src/app/@core/services/asset-report-service.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-asset-report',
  templateUrl: './asset-report.component.html',
  styleUrls: ['./asset-report.component.scss']
})
export class AssetReportComponent implements OnInit {

  // test start
  /**** PERMISSIONS ****/
  permissions: any;

  /**** General Variables ****/
  loader: boolean            = false;
  noData: boolean            = false;
  searchListData: any        = [];
  placeHolder: string        = this.msgService.searchdisplay('Reports') ;
  assetReports: any          = [];

  /******** INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength                 = 0;
  direction                  = '';
  defaultlimit:any           = 20;
  reoprtDetailsList:any      = [];
  infinityloader             = false;
  searchKeyword              = "";
  schedulePermissions1:any   = [];
  sLoading = false;

  // test end

  constructor(
    public appService: AppService,
    public msgService: MessageService,
    public assetReportService: AssetReportServiceService,
    public router: Router
  ) { }

  ngOnInit(): void {

    // Normal Permission
    this.getPermission();
    this.landingAPI();
    // Scheduled Report
    this.getScheduleReportPermission1();
  }

 // Landing Screen Data
 landingAPI(){
  this.loader = true
  this.assetReportService.reportTypeList('Asset',this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{
    this.listlength        = res?.results?.length;
    if(this.offset ==0){
      this.assetReports = [];
    }
    if (res.results.length != 0) {
      this.reoprtDetailsList = res?.results;
    }
    this.infinityloader    = false;
    this.addItems(this.listlength);
    if (res?.results?.length == 0) {
      this.noData = this.offset == 0 ? true:false;
    }else{
      this.noData = false;
    }
    this.loader            = false;
    this.sLoading = false;
  });
  // this.assetReportService.leaveReportDropdown().subscribe((res: any) => {
  //   this.searchListData = res;
  // });
}

// Pushing data on scroll
addItems(itemlist:any){
  for (let i = 0; i < itemlist; i++) {
    this.assetReports?.push(this.reoprtDetailsList[i]);
  }
}

// Search Reports
searchResults(data:any){
  this.loader = true;
  if(data == ""){
    this.searchKeyword      = "";
    this.offset             = 0;
    this.assetReports       = [];
    this.reoprtDetailsList  = [];
    this.landingAPI();
   }
  else{
    this.assetReportService.getSingleLeaveReport(data).subscribe((res: any) => {
      this.assetReports = res;
      this.loader       = false;
    });
  }
}

searchLeave(val:any){
  this.sLoading         = true;
  this.loader           = true;
  this.offset           = 0;
  this.assetReports     = [];
  this.searchKeyword    = val;
  if(this.searchKeyword != ""){
    this.landingAPI();
  }
}

// Scroll
onScrollDown() {
  this.infinityloader = true;
  this.offset        += this.defaultlimit;
  if(this.listlength){
    this.landingAPI();
    this.loader       = false;
    this.direction    = "down";
  }else{
    this.infinityloader = false;
  }
}

// Navigate to table data
reportTableView(reportname:any, reportid:any){
  if(this.permissions.v){
    if(reportid != undefined && reportid != null){
      reportname = reportname.replace(/\s/g, "");
      this.router.navigate(['/asset-report/asset-report-configure/'+reportname+'/'+reportid]);
    }
  }
}

// Permission
getPermission(){
  this.permissions = this.appService.getPermissions('');
  if(this.permissions == undefined){
    setTimeout(() => {
      this.getPermission();
    }, 1000);
  }
}

  // Scheduled Report
  getScheduleReportPermission1(){
    this.schedulePermissions1 = this.appService.getPermissions('/scheduled-reports');
    if(this.schedulePermissions1 == undefined){
      setTimeout(() => {
        this.getScheduleReportPermission1();
      }, 3000);
    }
  }

}
