import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-reimbursement-summary',
  templateUrl: './reimbursement-summary.component.html',
  styles: [
  ]
})
export class ReimbursementSummaryComponent implements OnInit {

  modalToggle                 = false;
  isCompleted                 = false;
  currentDocName              = "";
  currentExtension            = "";
  allowedExtension            = "";
  fileName:any;
  alertToggle                 = false;
  loader1 : boolean = false
  alertMsg:any                = '';
  currentYear                 = new Date().getFullYear();
  currentMonth                = new Date().toLocaleString('en-us', { month: 'long' }).toUpperCase();
  @Input() summaryData:any;
  @Input() categorySummaryData:any;
  @Input() currency:any;
  @Output() submitmethod        = new EventEmitter();

  constructor(
    public appservice:AppService,
    

  ) { }

  ngOnInit(): void {

  }
  
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }
  submitForm(){
    this.isCompleted = true;
    this.submitmethod.emit();
  }

  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

   // Modal close for Invalid Extension
   closed(bool:any){
    this.alertToggle = bool
  }
}
