import { Component, OnInit } from '@angular/core';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-challan-settings',
  templateUrl: './challan-settings.component.html',
  styleUrls: ['./challan-settings.component.scss']
})
export class ChallanSettingsComponent implements OnInit {
  challansettingsView = false;
  activeTab           = 1;
  permissions:any;

  constructor(
    private previousRouteService: PreviousRouteService,
    public appservice : AppService,
  ) { }

  ngOnInit(): void {

    this.getPermission();
    if(this.previousRouteService.getPreviousUrl()=='/challan-settings/challan-settings-setup/esi' || this.previousRouteService.getPreviousUrl()?.includes('esi')){
      this.activeTab             = 2;
    }
  }

  getPermission(){
    this.permissions = this.appservice.getPermissions('/challan-settings');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
