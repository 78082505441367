
    <form class="container-fluid p-32 form-container" [formGroup]="formGp">
        <div class="row ">
            <div class="col-12 d-flex justify-content-between flex-wrap">
                <div>
                    <p class="text-uppercase fw-500 mb-0">Assign rights</p>
                    <p class="text-helper mb-0">Assign rights to selected employees</p>
                </div>
                <a *ngIf="disabled==false && defineForm.value.employee.length !=0;"  class="btn btn-outline-primary h-40 px-32 radius-2" (click)="showSidePanel = true;setCommonFormEmployees()">Assign same rights to everyone</a>
            </div>
        </div>
        <div class="row mt-24 row-16"  formArrayName="list">
            <div class="col-12"  *ngFor="let frm of assignForm().controls; let i=index" >

                <div class="card shadow-0 border px-16" [formGroupName]="i">
                    <div class="card-body px-0 py-16 d-flex align-items-center ">
                        <div class=" me-8 sq-40" [class]="{'avatar-success':empValid[i] }">
    
                            <img  *ngIf="getEmployeeName(i).profile_image !=  '' && getEmployeeName(i).profile_image != null" class="avatar sq-40 rounded-circle img-fluid "
                                src="{{getEmployeeName(i).profile_image}}">
                            <div  *ngIf="getEmployeeName(i).profile_image ==  '' || getEmployeeName(i).profile_image == null" class="avatar sq-40 rounded-circle img-fluid  {{getEmployeeName(i).color_code}}" ><span>{{getEmployeeName(i).firstChar}}</span></div>
                        </div>
    
    
                        <h6 class="fw-600 mb-0  text-break me-16">{{getEmployeeName(i).name}}</h6>
                        <button *ngIf="disabled==false"  class="btn-reset ms-auto text-light-600"  (click)="clearEmployee(i)"><i class="icon-trash"></i></button>
                    </div>
                    <div class="card-body px-0 py-24 bt">
                        <div class="row row-24">
                            <div class="col-12 col-md-6 form-row" >
                                <label for="" class="form-label required">Company </label>
                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('company')}}"
                                    formControlName="company"
                                    [items]="companyDD"
                                    [multiple]="true"
                                     groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="company_name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    (change)="companyChangeFunction(formGp.value.list[i].company,i)">
                                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                     </ng-template>
                                  <!-- <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.company_name }}
                                  </ng-template> -->
                                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngIf="formGp.value.list[i].company.length ">
                                            <span class="ng-value-label">{{formGp.value.list[i].company.length }} Company selected</span>
                                        </div>
                                    </ng-template>
    <!-- App -->
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-item$="item$"
                                        let-index="index">
                                        <div class="d-inline-flex align-items-center">
                                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" (change)="clear('company',index,i)"/>
                                          <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="item.logo !=  null && item.logo !=  ''"src="{{item.logo}}">
                                          <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.company_name,1)}}</span></span>
    
                                          <span class="mt-1 ms-1">{{ (item.company_name.length>15)? (item.company_name | slice:0:15)+'..':(item.company_name)  }}</span>
                                         </div>
                                        <!-- {{ (item.company_name.length>15)? (item.company_name | slice:0:15)+'..':(item.company_name)  }} -->
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.list[i].company.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.list[i].company,let ki =index">
                                        <ng-container *ngFor="let insideitem of companyDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                <!-- {{ (insideitem['company_name'].length>15)? (insideitem['company_name'] | slice:0:15)+'..':(insideitem['company_name'])  }} -->
                                                <div class="d-inline-flex align-items-center">
                                                  <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                                                  <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{k%5}} me-1" >
                                                      <span>
                                                      {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                                                  </span>
                                                  <span class=""> {{ (insideitem['company_name'].length>15)? (insideitem['company_name'] | slice:0:15)+'..':(insideitem['company_name'])  }} </span>
                                               </div>
                                              </span>
    
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear('company',ki,i)" aria-hidden="true"><i class="icon-close"></i></span>
    
                                    </li>
                                </ul>
                            </div>
    
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">Business unit</label>
                                <ng-select [readonly]="formGp.value.list[i].company?.length == 0 ? true : false"
                                    placeholder="{{this.messageService.selectplaceholddisplay('business unit')}}"
                                    formControlName="businessunit"
                                    [items]="buArray[i]"
                                    [multiple]="true"
                                    groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    >
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                        </ng-template>
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                            {{ item.name }}
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="formGp.value.list[i].businessunit.length">
                                                <span class="ng-value-label">
                                                  {{formGp.value.list[i].businessunit.length}} Business Unit Selected</span>
                                            </div>
                                        </ng-template>
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index">
                                            {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                        </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.list[i].businessunit?.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.list[i].businessunit,let ki =index">
                                        <ng-container *ngFor="let insideitem of buArray[i], let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                               {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                            </span>
    
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear('businessunit',ki,i)" aria-hidden="true">×</span>
    
                                    </li>
                                </ul>
                            </div>
    
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">Department </label>
                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('department')}}"
                                    formControlName="department"
                                    [items]="DepDD"
                                    [multiple]="true"
                                    groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    >
    
                                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-item$="item$"
                                        let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                        {{ item.name }}
                                    </ng-template>
                                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngIf="formGp.value.list[i].department.length">
                                            <span class="ng-value-label">{{formGp.value.list[i].department.length}} Department Selected</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.list[i].department.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.list[i].department,let ki =index">
                                        <ng-container *ngFor="let insideitem of DepDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                               {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                            </span>
    
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear('department',ki,i)" aria-hidden="true">×</span>
    
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label">Designation</label>
                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('designation')}}"
                                    formControlName="designation"
                                    [items]="DesignationDD"
                                    [multiple]="true"
                                    groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    >
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                        </ng-template>
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                            {{ item.name }}
                                        </ng-template>
                                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    
                                              <div class="ng-value" *ngIf="formGp.value.list[i].designation.length">
                                                  <span class="ng-value-label">{{formGp.value.list[i].designation.length}} Designation Selected</span>
                                              </div>
                                          </ng-template>
                                      <ng-template
                                          ng-option-tmp
                                          let-item="item"
                                          let-index="index">
                                          {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                      </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.list[i].designation.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.list[i].designation,let ki =index">
                                        <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                               {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                            </span>
    
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear('designation',ki,i)" aria-hidden="true">×</span>
    
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">Branch</label>
                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('branch')}}"
                                    formControlName="branch"
                                    [items]="BranchDD"
                                    [multiple]="true"
                                    groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    >
                                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                      </ng-template>
                                      <ng-template
                                          ng-option-tmp
                                          let-item="item"
                                          let-item$="item$"
                                          let-index="index">
                                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                          {{ item.name }}
                                      </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="formGp.value.list[i].branch.length ">
                                                <span class="ng-value-label">{{formGp.value.list[i].branch.length}} Branch Selected</span>
                                            </div>
                                        </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.list[i].branch.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.list[i].branch,let ki =index">
                                        <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                               {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                            </span>
    
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear('branch',ki,i)" aria-hidden="true">×</span>
    
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">City</label>
                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('city')}}"
                                    formControlName="city"
                                    [items]="cityDD"
                                    [multiple]="true"
                                    groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    >
                                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                      </ng-template>
                                      <ng-template
                                          ng-option-tmp
                                          let-item="item"
                                          let-item$="item$"
                                          let-index="index">
                                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                          {{ item.name }}
                                      </ng-template>
                                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                          <div class="ng-value" *ngIf="formGp.value.list[i].city.length ">
                                              <span class="ng-value-label">{{formGp.value.list[i].city.length }} City selected</span>
                                          </div>
                                      </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.list[i].city.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.list[i].city,let ki =index">
                                        <ng-container *ngFor="let insideitem of cityDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                               {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                            </span>
    
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear('city',ki,i)" aria-hidden="true">×</span>
    
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12  form-row">
                                <label for="" class="form-label ">Select grade </label>
                                <ng-select
                                    placeholder="{{this.messageService.selectplaceholddisplay('grade')}}"
                                    formControlName="grade"
                                    [items]="gradeDD"
                                    [multiple]="true"
                                    groupBy="selectedAllGroup"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    bindLabel="name"
                                    [closeOnSelect]="false"
                                    bindValue="id"
                                    class="multiselect"
                                    >
                                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                      </ng-template>
                                      <ng-template
                                          ng-option-tmp
                                          let-item="item"
                                          let-item$="item$"
                                          let-index="index">
                                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                          {{ item.name }}
                                      </ng-template>
                                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    
                                          <div class="ng-value" *ngIf="formGp.value.list[i].grade.length">
                                              <span class="ng-value-label">{{formGp.value.list[i].grade.length }} Grade Selected</span>
                                          </div>
                                      </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="formGp.value.list[i].grade.length != 0">
                                    <li class="ng-value" *ngFor="let item of formGp.value.list[i].grade,let ki =index">
                                        <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                               {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}
                                            </span>
    
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear('grade',ki,i)" aria-hidden="true">×</span>
    
                                    </li>
                                </ul>
                            </div>
                        </div>
    
                    </div>
    
                </div>
            </div>
        </div>
    </form>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto  text-uppercase" [disabled]="checkValid() || saveClicked==true || defineForm.value.employee.length ==0" (click)="submitform()" >{{saveBtn}}</button>
    </footer>

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>

        <form  class="side-panel-container"  [formGroup]="commonForm">
        <header class="side-panel-head">
            <h5>Assign same rights to everyone</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-14"></i></a>
        </header>
            <div class="side-panel-body" style="overflow-y: auto;">
                <div class="container-fluid p-32">
                  <div class="col-12 mt-16" *ngIf="Isalert">
                    <div  role="alert" class="alert alert-warning show">
                      <p class="mb-0">
                      <span class="text-warning">Note:</span> By selecting the rights here, you are going to give same rights to everyone below </p>
                      <div class="btn-close pointer" (click)="Isalert=false;"></div>
                    </div>
                  </div>
                    <p class="text-uppercase fw-500">Selected Employees</p>
                    <div class="row row-24">
                        <div class="col-12">
                            <ul class="selected-values ">
                                <li class="ng-value" *ngFor="let item of commonForm.value.employee, let k =index">
                                    <span class="ng-value-label">
                                        <img *ngIf="getEmployeeNameById(item).profile_image !=  '' && getEmployeeNameById(item).profile_image != null" class="avatar sq-24 rounded-circle img-fluid me-8"
                                            src="{{getEmployeeNameById(item).profile_image}}">
                                        <div *ngIf="getEmployeeNameById(item).profile_image ==  '' || getEmployeeNameById(item).profile_image ==  null" class="avatar sq-24 rounded-circle img-fluid me-8 {{getEmployeeNameById(item).color_code}}"  ><span>{{getEmployeeNameById(item).firstChar}}</span></div>
                                        {{getEmployeeNameById(item).name}}</span>
                                    <span *ngIf="disabled==false" class="ng-value-icon right" aria-hidden="true" (click)="clearcommonForm('employee',k)"><i class="icon-close"></i></span>
                                </li>
                            </ul>
                        </div>

                </div>
                <p class="text-uppercase fw-500 mt-24">Rights</p>

                        <div class="row row-24">
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">Company </label>
                                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('company')}}" formControlName="company" [items]="companyDD" groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [multiple]="true" bindLabel="company_name" [closeOnSelect]="false" bindValue="id"  class="multiselect" (change)="companyChangeFunction(commonForm.value.company,'commonForm')">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                </ng-template>
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    <div class="d-inline-flex align-items-center"><input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img class="avatar-sm sq img-fluid rounded-circle me-1" *ngIf="item.logo !=  null && item.logo !=  ''"src="{{item.logo}}">
                                      <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.company_name,1)}}</span></span>

                                      <span class="mt-1 ms-1">{{ (item.company_name.length>15)? (item.company_name | slice:0:15)+'..':(item.company_name)  }}</span>
                                     </div>
                                </ng-template>
                                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngIf="commonForm.value.company.length ">
                                            <span class="ng-value-label">{{commonForm.value.company.length }} Company Selected</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.company_name.length>15)? (item.company_name | slice:0:15)+'..':(item.company_name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="commonForm.value.company.length != 0">
                                    <li class="ng-value" *ngFor="let item of commonForm.value.company,let ki =index">
                                        <ng-container *ngFor="let insideitem of companyDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                              <div class="d-inline-flex align-items-center">
                                                <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                                                <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{k%5}} me-1" >
                                                    <span>
                                                    {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                                                </span>
                                                <span class=""> {{ (insideitem['company_name'].length>15)? (insideitem['company_name'] | slice:0:15)+'..':(insideitem['company_name'])  }} </span>
                                             </div>
                                            </span>
                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('company',ki)" aria-hidden="true">×</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">Business unit</label>
                                <ng-select [readonly]="commonForm.value.company?.length == 0 ? true : false" placeholder="{{this.messageService.selectplaceholddisplay('business unit')}}" formControlName="businessunit" [items]="buDD" [multiple]="true" groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                </ng-template>
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                    {{ item.name }}
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngIf="commonForm.value.businessunit.length">
                                            <span class="ng-value-label">{{commonForm.value.businessunit.length}} Business Unit Selected</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="commonForm.value.businessunit?.length != 0">
                                    <li class="ng-value" *ngFor="let item of commonForm.value.businessunit,let ki =index">
                                        <ng-container *ngFor="let insideitem of buDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('businessunit',ki)" aria-hidden="true">×</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">Department </label>
                                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('department')}}" formControlName="department" [items]="DepDD" [multiple]="true" groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                </ng-template>
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                    {{ item.name }}
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                                        <div class="ng-value" *ngIf="commonForm.value.department.length ">
                                            <span class="ng-value-label">{{commonForm.value.department.length}} Department Selected</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="commonForm.value.department.length != 0">
                                    <li class="ng-value" *ngFor="let item of commonForm.value.department,let ki =index">
                                        <ng-container *ngFor="let insideitem of DepDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('department',ki)" aria-hidden="true">×</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label">Designation</label>
                                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('designation')}}" formControlName="designation" [items]="DesignationDD" [multiple]="true" groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                </ng-template>
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                    {{ item.name }}
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                                        <div class="ng-value" *ngIf="commonForm.value.designation.length">
                                            <span class="ng-value-label">{{commonForm.value.designation.length}} Designation Selected</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="commonForm.value.designation.length != 0">
                                    <li class="ng-value" *ngFor="let item of commonForm.value.designation,let ki =index">
                                        <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('designation',ki)" aria-hidden="true">×</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">Branch</label>
                                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('branch')}}" formControlName="branch" [items]="BranchDD" [multiple]="true" groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                </ng-template>
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                    {{ item.name }}
                                </ng-template> <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">


                                        <div class="ng-value" *ngIf="commonForm.value.branch.length ">
                                            <span class="ng-value-label">{{commonForm.value.branch.length }}  Branch Selected</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="commonForm.value.branch.length != 0">
                                    <li class="ng-value" *ngFor="let item of commonForm.value.branch,let ki =index">
                                        <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('branch',ki)" aria-hidden="true">×</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6 form-row">
                                <label for="" class="form-label ">City</label>
                                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('city')}}" formControlName="city" [items]="cityDD" [multiple]="true" groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                                     <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                  </ng-template>
                                  <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-item$="item$"
                                      let-index="index">
                                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                      {{ item.name }}
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngIf="commonForm.value.city.length ">
                                            <span class="ng-value-label">{{commonForm.value.city.length }} City Selected</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="commonForm.value.city.length != 0">
                                    <li class="ng-value" *ngFor="let item of commonForm.value.city,let ki =index">
                                        <ng-container *ngFor="let insideitem of cityDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('city',ki)" aria-hidden="true">×</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12  form-row">
                                <label for="" class="form-label ">Select Grade </label>
                                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('grade')}}" formControlName="grade" [items]="gradeDD" [multiple]="true" groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="multiselect"  >
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                                </ng-template>
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-item$="item$"
                                    let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                                    {{ item.name }}
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngIf="commonForm.value.grade.length ">
                                            <span class="ng-value-label">{{commonForm.value.grade.length }} Grade Selected.</span>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index">
                                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                                    </ng-template>
                                </ng-select>
                                <ul class="selected-values my-16" *ngIf="commonForm.value.grade.length != 0">
                                    <li class="ng-value" *ngFor="let item of commonForm.value.grade,let ki =index">
                                        <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                                        </ng-container>
                                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('grade',ki)" aria-hidden="true">×</span>
                                    </li>
                                </ul>
                            </div>
                        </div>


                </div>
            </div>
            <footer class="side-panel-footer">
                <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="checkcommonFormValid()"   (click)="updateFormGp()">Done</button>
            </footer>
        </form>

</div>
