import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageActivityService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getSideMenu(status:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/inbox-side-menu/?status=${status}`, this.httpOption)
  }
  requestList(limit:any,offset:any,search:any,request_type_id:any='',status:any,filterparam:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/inbox-request-list/?limit=${limit}&offset=${offset}&search=${search}&status=${status}&request_type_id=${request_type_id}&${filterparam}`, this.httpOption)
  }
  singleRequest(id: number,type:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-request_detail/?request_type=${type}&request_id=${id}`, this.httpOption)
  }
  onBehalfReqUpdate(body: any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-approve/`, body, this.httpOption);
  }
  assetRequest(body: any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-asset_request/`, body, this.httpOption);
  }
  expenseAdvance(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-advance-create/`, body, this.httpOption);
  }
  ondutyRequest(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-on-duty-create/`, body, this.httpOption);
  }
  investmentDeclaration(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-investments-declaration-request-create/`, body, this.httpOption);
  }
  attendanceRegularization(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-regularize-attendance-create/`, body, this.httpOption);

  }
  reimbursementRequest(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-reimbursement-create/`, body, this.httpOption);

  }
  wfhRequest(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-work-from-home-create/`, body, this.httpOption);

  }
  shortLeaveRequest(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-short-leave-request-create/`, body, this.httpOption);

  }
  leaveRequest(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-leave-request-create/`, body, this.httpOption);

  }
  getReqCount(id: number) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-request-count/?employee_id=${id}`, this.httpOption)
  }
  expenseReportList(search:any,emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-report-list/?search=${search}&status_filter=["Rejected","Draft"]&is_active=true&employee_id=${emp}`, this.httpOption)
  }
  expenseReportListDropDown(emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-report-list/?status_filter=["Rejected","Draft"]&ordering=name&is_active=true&employee_id=${emp}`, this.httpOption)
  }
  expenseReportCreate(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-report-request-create/`, body, this.httpOption);
  }
    createReport(body: any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-report-list/`, body, this.httpOption)
  }
  getReportDropdown(emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-report-dropdown/?employee_id=${emp}&status_filter=${JSON.stringify(['Draft','Rejected'])}&is_active=true`, this.httpOption)
  }
  getCategoryConfigValidate(id:any,date:any,emp:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/validate-expense-category/${id}/?receipt_date=${date}&employee_id=${emp}`, this.httpOption)
  }
  advanceList(emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-advance-list/?unmap=True&employee_id=${emp}`, this.httpOption)
  }
  updateOndutyItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}manage_activities/api/v1/manage-activity-on-duty-date-update/${id}/`,data, this.httpOption)
  }
  updateRegularizationItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}manage_activities/api/v1/manage-activity-attendance-regularization-date-update/${id}/`,data, this.httpOption)
  }
  updateCheckIn(id:any,data:any){
    return this.http.put(`${this.API_PATH}manage_activities/api/v1/manage-activity-checkin-date-update/${id}/`,data, this.httpOption)
  }

  holidayFloatList(datesort: any,search: any,emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-employee-holiday-list/?ordering=${datesort}&search=${search}&employee_id=${emp}`, this.httpOption)
  }
  holidayFloatDropDown(emp:any){
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-holiday-request-dropdown/?employee_id=${emp}&ordering=holiday__holiday_name`, this.httpOption)
  }
  holidayFloatCreate(body: any) {
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-holiday-request-create/`, body, this.httpOption)
  }
  assetCategoryDropDown(status:any,emp:any){
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-asset_category_list/?is_active=${status}&ordering=name&employee_id=${emp}`, this.httpOption)
  }
  employeeAssetList(emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-employee-asset-list/?employee_id=${emp}`, this.httpOption)
  }
  fbpCreate(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-fbp-request-create/`, body, this.httpOption);
  }
  updateReimbursementItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}manage_activities/api/v1/manage-activity-inbox-reimbursement-bill-update/${id}/`,data, this.httpOption)
  }
  requestTypeList(search:any,emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-request-type-list/?employee_id=${emp}&ordering=name&search=${search}`, this.httpOption)
  }
  compOffRequestCreate(id:any,body:any) {
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-compensatory-request-create/${id}/`, body, this.httpOption)
  }
  saveReceipt(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-receipts/`, body, this.httpOption)
  }
  expenseReportReq(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-expense-report-request-create/`, body, this.httpOption);
  }
  cancelRequest(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-cancel-request/`, body, this.httpOption);
  }
  employeeBasicData(emp:any) {
    return this.http.get(`${this.API_PATH}manage_activities/api/v1/manage-activity-employee-detail-view/${emp}`, this.httpOption)
  }
  sendReminder(body:any){
    return this.http.post(`${this.API_PATH}manage_activities/api/v1/manage-activity-reminder-notification/`, body, this.httpOption);

  }
}
