import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog';
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import {
  STYLES as CROPPER_STYLES,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent
} from '@alyle/ui/image-cropper';
import { CropperService } from '../../../@core/services/cropper.service';
import { NotificationService } from 'src/app/@core/services/notification.service';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl `{
      ${cropper.root} {
        max-width: 604px
        height: 294px
      }
    }`,
    sliderContainer: lyl `{
      text-align: center
      max-width: 604px
      position: relative
      ${slider.root} {
        width: 80%
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: 35px
      }
    }`,
    slider: lyl `{
      padding: 1em
    }`
  };
};

@Component({
  templateUrl: './cropper-dialog.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})


export class CropperDialog implements WithStyles, AfterViewInit {

  public round: any = false;
  readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
  ready: boolean=false;
  scale: any =5;
  minScale: number=1;

  currentUrl:any = this.previousRouteService.getCurrentUrl();

  @ViewChild(LyImageCropper, { static: true })
    cropper!: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width:  this.currentUrl == '/customize-login'?269:200,
    height: this.currentUrl == '/customize-login'?298:200,
    round:  this.cropperService.round,
    // type: 'image/png',
    // keepAspectRatio: true,
    responsiveArea: true,
    resizableArea: true,
    // extraZoomOut: this.currentUrl == '/customize-login'?false:true,
    output: {
      width : 0,
      height: 0
    },
  };

  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef,
    private cropperService: CropperService,
    private notificationService:NotificationService,
    private previousRouteService: PreviousRouteService
  ) { }

  ngAfterViewInit() {
    // Load image when dialog animation has finished
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.event);
    });
  }

  onCropped(e: ImgCropperEvent) {
    // console.log('cropped img: ', e);
    // this.cropperService.FileUpload(e).subscribe(
    //   (res: any) => {
    //   console.log('response:', res);
    // });
  }
  onLoaded(e: ImgCropperEvent) {
    // console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    this.notificationService.handleErrorNotification(`'${e.name}' is not a valid image`,"Error");
    this.dialogRef.close();
  }

}
