import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-download-templet',
  templateUrl: './download-templet.component.html',
  styleUrls: ['./download-templet.component.scss']
})
export class DownloadTempletComponent implements OnInit {

  constructor() { }
  @Output() downloadTemplate = new EventEmitter();
  @Output() changeTab =  new EventEmitter();
  ngOnInit(): void {
  }
  download(){
    this.downloadTemplate.emit();
  }
  goNext(tab:number){
    this.changeTab.emit(tab);
  }
}
