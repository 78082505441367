import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReimbursementRequestService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getCategoryDetails(employeeid:number) {
    return this.http.get(`${this.API_PATH}request/api/v1/reimbursement-category-list/${employeeid}/`, this.httpOption)
  }
  createReimbursementRequest(data:any){
    return this.http.post(`${this.API_PATH}request/api/v1/reimbursement-create/`, data, this.httpOption)

  }
  getIndividualBillData(billId:any,reqId:any){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${reqId}&request_section=Reimbursement&reimbursement_bill_id=${billId}`, this.httpOption)

  }

}
