<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="personaldetails" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">PAN status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="pan_status" type="radio" id="panCompleted" class="form-check-input"
                                formControlName="pan_status" value="True">
                            <label for="pancompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="pan_status" type="radio" id="pan_notcompleted" class="form-check-input "
                                formControlName="pan_status" value="False">
                            <label for="pan_notcompleted" class="form-check-label">Not completed</label>
                        </div>
                    </div>

                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Aadhaar status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="aadhar_status" type="radio" id="adharCompleted" class="form-check-input  "
                                formControlName="aadhar_status" value="True">
                            <label for="adharcompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="aadhar_status" type="radio" id="adharnot_completed"
                                class="form-check-input " formControlName="aadhar_status" value="False">
                            <label for="adharnot_completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Passport status</p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="passport_status" type="radio" id="psprtCompleted"
                                class="form-check-input  " formControlName="passport_status" value="True">
                            <label for="pscompleted" class="form-check-label">Completed</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="passport_status" type="radio" id="psprtnot_completed"
                                class="form-check-input " formControlName="passport_status" value="False">
                            <label for="psnot-completed" class="form-check-label">Not completed</label>
                        </div>
                    </div>
                </div>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
                (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage();submitpersonalDetails()">
                Apply
            </button>
        </footer>
    </form>
</div>
