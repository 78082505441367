import {Component, OnInit, Output,Input, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() showProgress:any
  @Input() popupClass:any
  @Input() taskText:any
  @Input() processText:any
  @Input() progPercentage:any
  @Input() timeLeft:any
  @Input() completeText:any
  @Input() btnClass:any

  @Output() viewDetails = new EventEmitter()
  @Output() nav = new EventEmitter()
  @Output() close = new EventEmitter()


}
