<!-- filter -->
<div class="side-panel" style="--sidepanel-width: 27rem" [class.side-pane-active]="filterpanel === true">
    <form class="side-panel-container" [formGroup]="commonFilter" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
            <div class="container-fluid p-32">
                <div class="row row-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">STATUS</p>
                    </div>
                    <div class="col-12 form-row">
                        <ng-select class="form-ngselect" formControlName="status" name="" id="status"
                            placeholder="Select filter">
                            <ng-option *ngFor="let statusFilter of statusFilter" [value]="statusFilter">{{
                                statusFilter }}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">COUNTRY</p>
                    </div>
                    <div class="col-12 form-row">
                        <ng-select placeholder="{{
              this.messageService.selectplaceholddisplay('country')
            }}" formControlName="country" [items]="countries" [multiple]="true" groupBy="selectedAllGroup"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                            [closeOnSelect]="false" bindValue="id" class="multiselect" (change)="countryChange()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{ standalone: true }" />
                                Select All
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="commonFilter.value.country?.length">
                                    <span class="ng-value-label">{{ commonFilter.value.country?.length }} country
                                        selected</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="d-inline-flex align-items-center">
                                    <input id="item-{{ index }}" class="me-8" type="checkbox"
                                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" />
                                    <span class="">{{
                                        item.name?.length > 15
                                        ? (item.name | slice : 0 : 15) + ".."
                                        : item.name
                                        }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values mt-16" *ngIf="commonFilter.value.country?.length != 0">
                            <li class="ng-value" *ngFor="
                let item of commonFilter.value.country;
                let ki = index
              ">
                                <ng-container *ngFor="let insideitem of countries; let k = index">
                                    <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                                        <div class="d-inline-flex align-items-center">
                                            <span class="">
                                                {{
                                                insideitem["name"].length > 25
                                                ? (insideitem["name"] | slice : 0 : 25) + ".."
                                                : insideitem["name"]
                                                }}</span>
                                        </div>
                                    </span>
                                </ng-container>
                                <span class="ng-value-icon right" (click)="clearcommonForm('country', ki)"
                                    aria-hidden="true">×</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">STATE</p>
                    </div>
                    <div class="col-12 form-row">
                        <ng-select placeholder="{{
              this.messageService.selectplaceholddisplay('State') 
            }}" formControlName="state" [items]="states" [multiple]="true" groupBy="selectedAllGroup"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                            [closeOnSelect]="false" bindValue="id" class="multiselect">
                            <!-- <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{ standalone: true }" />
                                Select All
                            </ng-template> -->
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngIf="commonFilter.value.state?.length">
                                    <span class="ng-value-label">{{ commonFilter.value.state?.length}} state
                                        selected</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="d-inline-flex align-items-center">
                                    <input id="item-{{ index }}" class="me-8" type="checkbox"
                                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" />
                                    <span class="">{{
                                        item.name?.length > 15
                                        ? (item.name | slice : 0 : 15) + ".."
                                        : item.name
                                        }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values mt-16" *ngIf="commonFilter.value.state?.length != 0">
                            <li class="ng-value" *ngFor="let item of commonFilter.value.state; let ki = index">
                                <ng-container *ngFor="let insideitem of states; let k = index">
                                    <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                                        <div class="d-inline-flex align-items-center">
                                            <span class="">
                                                {{
                                                insideitem["name"].length > 25
                                                ? (insideitem["name"] | slice : 0 : 25) + ".."
                                                : insideitem["name"]
                                                }}</span>
                                        </div>
                                    </span>
                                </ng-container>
                                <span class="ng-value-icon right" (click)="clearcommonForm('state', ki)"
                                    aria-hidden="true">×</span>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="ResetFilter()">
                Reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="applyFilter()">
                Apply
            </button>
        </footer>
    </form>
</div>