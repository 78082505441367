<div class=" config-layout">
   
    <header class=" config-header">
        <h5 class="m-0">Import</h5>
        <button class="btn-reset text-light-600" routerLink="/employee"><i class="icon-close-lg fs-16"></i></button>
    </header>
  <aside class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab"  class="steps gap-24"  (navChange)="onNavChange($event)" >
            <li [ngbNavItem]="1" >
                <a ngbNavLink class="step" [class]="{'success':selectedDTIndex != -1}"  >
                    <div class="count">1</div>
                    <div class="content">
                        <span>Step 1</span>
                        <h6>Select data type</h6>
                    </div>
                </a>
                <ng-template ngbNavContent>
          <app-data-type (selectDataType)="selectDataType($event)" (changeTab)="changeTab($event)"
            [selectedIndex]="selectedDTIndex" [datatype]="datatype"></app-data-type>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink class="step"  [class]="{'success':selectedUTIndex != -1}">
                    <div class="count">2</div>
                    <div class="content">
                        <span>Step 2</span>
                        <h6>Upload new/Update existing</h6>
                    </div>
                </a>
              <ng-template ngbNavContent>
          <app-upload (selectUploadType)="selectUploadType($event)" (changeTab)="changeTab($event)"
            [selectedIndex]="selectedUTIndex" [datatype]="uploadtype"></app-upload>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
                <a ngbNavLink class="step" [class]="{'success':fileDownloaded || activeTab == 4}">
                    <div class="count">3</div>
                    <div class="content">
                        <span>Step 3</span>
                        <h6>Download template</h6>
                    </div>
                </a>
              <ng-template ngbNavContent>
          <app-download-templet (downloadTemplate)="downloadTemplate()"
            (changeTab)="changeTab($event)"></app-download-templet>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
                <a ngbNavLink class="step" [class]="{'success':data.length !=0}" >
                    <div class="count">4</div>
                    <div class="content">
                        <span>Step 4</span>
                        <h6>Upload file</h6>
                    </div>
                </a>
              <ng-template ngbNavContent>
          <app-upload-file (changeTab)="changeTab($event)" (fileChange)="readData($event)" [fileName]="fileName"
            [fileSize]="fileSize" [dataType]="selectedDataTypes"></app-upload-file>
              </ng-template>
            </li>
            <li [ngbNavItem]="5">
                <a ngbNavLink class="step" >
                    <div class="count">5</div>
                    <div class="content">
                        <span>Step 5</span>
                        <h6>Preview and Upload</h6>
                    </div>
                </a>
              <ng-template ngbNavContent>
                <app-view-loader *ngIf="isupload!=0 || isDataUpload !=0"></app-view-loader>

          <app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'"
            [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Uploading'" [body]="body"
            [processingText]='"Proccessing...."'></app-upload-progress-bar>
                
          <app-preview-save *ngIf="isupload==0 && isDataUpload==0" [initialHeader]="initialHeader"
            [headerData]="headerRow" (validateData)="validateData()" [errorCount]="errorCount" [errorData]="errorData"
            [dataArranged]="dataArranged" (uploadData)="uploadData()" (deleteRows)="deleteRows($event)" [data]="data"
            [dataType]="selectedDataTypes"></app-preview-save>
              </ng-template>
            </li>
        </ul>
        
    </aside>
    <main class=" config-container">
        <div [ngbNavOutlet]="nav" ></div>
        
    </main>
</div>

<!-- <app-error-download [fileName]="'Employee Import Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="failedData" [modalHeading]="'Import Employee'" (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download> -->


<div *ngIf="failedData.length" class="modal  modal-alert {{downloadMsg == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title" *ngIf="successDataCount>0">{{successDataCount}} employee data uploaded successfully.
        </h4>
            <h4 class="modal-title" *ngIf="successDataCount==0">No employee data uploaded</h4>
            <button type="button" class="btn-close ms-auto m-0" (click)="downloadMsg = false;clearData()"></button>
            </div>
            <div *ngIf="failedData.length" class="modal-body">
                    <p class="mb-0">Download {{failedData.length}} error data.</p>
            </div>
            <div  *ngIf="failedData.length" class="modal-footer">
        <button type="button" class="btn btn-primary text-uppercase w-50 fs-14 radius-2"
          (click)="downloadError();">Download</button>
            </div>
        </div>
    </div>
</div>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        
      <div class="modal-content" >
            <div class="modal-header gap-16">
            <i class="bi bi-exclamation-triangle text-danger"></i>
            <h4 class="modal-title">Are you sure?</h4>
            </div>
            <div class="modal-body">
                    <!-- <p class="mb-0">23 Error data found. Do you still want to proceed without corrections?</p> -->
                    <p class="mb-0">{{deleteMessage}}</p>
            </div>

            <div class="modal-footer" *ngIf="isSave == true">
                <button type="button" class="btn btn-danger btn-sm"  (click)="uploadDatas()">YES</button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false">NO</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="failedData.length == 0" class="modal  modal-alert {{uploadSuccess == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
            <div class="modal-header align-items-start gap-16">
            <h4 class="modal-title" >Data uploaded successfully.</h4>
            <button type="button" class="btn-close ms-auto m-0" (click)="uploadSuccess= false;clearData()"></button>
            </div>
            <div  class="modal-body">
                    <p class="mb-0">{{successDataCount}} new employee data uploaded successfully.</p>
            </div>
            
        </div>
    </div>
</div>
