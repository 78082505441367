<nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky top-48" (activeIdChange)="searchString='';loadData()">
  <li [disabled]="loader" [ngbNavItem]="1">
    <a ngbNavLink class="nav-link">All</a>
    <ng-template ngbNavContent>
      <app-expense-listing [searchString]="searchString" [page]="page" [listData]="listDataArr" [loader]="loader" (refreshList)=" loadData()"></app-expense-listing>
    </ng-template>
  </li>
  <li [disabled]="loader" [ngbNavItem]="2">
    <a ngbNavLink class="nav-link">Awaiting Reimbursement</a>
    <ng-template ngbNavContent>
      <app-expense-listing  [searchString]="searchString" [page]="page" [listData]="listDataArr" [loader]="loader" (refreshList)=" loadData()"></app-expense-listing>
    </ng-template>
  </li>
  <li [disabled]="loader" [ngbNavItem]="3">
    <a ngbNavLink class="nav-link">Reimbursed</a>
    <ng-template ngbNavContent>
      <app-expense-listing  [searchString]="searchString" [page]="page" [listData]="listDataArr" [loader]="loader"></app-expense-listing>
    </ng-template>
  </li>
  <li [disabled]="loader" [ngbNavItem]="4">
    <a ngbNavLink class="nav-link">Pending for reimbursement via payroll</a>
    <ng-template ngbNavContent>
      <app-expense-listing  [searchString]="searchString" [page]="page" [listData]="listDataArr" [loader]="loader"></app-expense-listing>
    </ng-template>
  </li>
</nav>
<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Expense Management</h3>
      <p class="text-helper mb-md-0">Details of Expense report raised by employees can be viewed and managed here.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon2 " style="width:22.6875rem;">
          <i class="icon-search icon-left "></i>
          <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
          <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  "  autocomplete="off" name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left"  (click)="filterPanel = true" >
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-16" [ngbNavOutlet]="nav"></div>
</div>

<app-common-employee-filters *ngIf='filterPanel === true' [filterForm]="filterForm" [from]="'expensehr'" [filter]="filterPanel"
(filterClose)="closeFilter($event)"  (resetCalled)="resetFilter()" (filterOutput)="submitForm($event)"></app-common-employee-filters>
