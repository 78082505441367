import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function yearPatternValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }
      const unamePattern = /(?:(?:20|21)[0-9]{2})/g.test(value);

      return !unamePattern ? {pattern:true}: null;
      // return null
  }
}

