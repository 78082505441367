import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function CgpaValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const Cgpapattern = /^([A-Za-z0-9+])+(\.[0-9]{0,2})?$/.test(value);

      return !Cgpapattern ? {pattern:true}: null;
  }
}

