import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-data-type',
  templateUrl: './data-type.component.html',
  styleUrls: ['./data-type.component.scss']
})
export class DataTypeComponent implements OnInit {
  @Input() datatype:any = [];
  @Output() changeTab =  new EventEmitter();
  selectedDataType:any = null;
  @Input() selectedIndex:any = -1;
  @Output() selectDataType=  new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  makeActive(index:number){
    this.selectDataType.emit(index);
  }
  goNext(tab:number){
    this.changeTab.emit(tab);
  }

}
