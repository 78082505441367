import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,FormControl,FormArray } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ExpensePolicyService } from 'src/app/@core/services/expense-policy.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { spaceValidator } from 'src/app/@shared/validators/space.validators';
@Component({
  selector: 'app-expense-policy-setup',
  templateUrl: './expense-policy-setup.component.html',
  styleUrls: ['./expense-policy-setup.component.scss']
})
export class ExpensePolicySetupComponent implements OnInit {
  activeTab         =1;
  disabled          = false;
  res               :any;
  title             ='Add';
  id: number        = 0;
  iseditForm        =false;
  editflag: boolean = false;
  loader            = false;
  loadercatg:boolean= false;
  categorylist      : any=[];
  hint              = "Set the applicability of the Expense policy (Company-wise or Location-wise etc.)."
  myForm: FormGroup = this.fb.group({
    id: 0,
    setting: this.fb.group({
      name        :  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      description : ['', [Validators.required,spaceValidator(),Validators.maxLength(500)]]
    }),
    policymap: this.fb.group({
      categories: this.fb.array([],[Validators.required]),

    }),
    applicable: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })

  });
  constructor(private fb:FormBuilder,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public ExpensePolicyService:ExpensePolicyService,
    public notificationservice:NotificationService) { }

  ngOnInit(): void {
    this.getcategorylist();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }

  getcategorylist(){
    this.loadercatg=true;
    this.ExpensePolicyService.getcategorySearchList(true,'').subscribe((res:any)=>{
      this.categorylist   = res;
      this.loadercatg=false;
    })
  }

  // submit(tab:any){
  //   this.activeTab = tab;
  // }

  get settingForm() {
    return this.myForm.get('setting') as FormGroup;
  }
  get policymapForm() {
    return this.myForm.get('policymap') as FormGroup;
  }
  get applicableForm() {
    return this.myForm.get('applicable') as FormGroup;
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].setting.invalid) {
        changeEvent.preventDefault();
      }
    }else if(changeEvent.nextId === 3){
        if(this.myForm['controls'].setting.invalid || this.myForm['controls'].policymap.invalid ) {
        changeEvent.preventDefault();
        }
    }
  }

  tabclick(tab:any){
    if(tab == 2){
      if(this.myForm['controls']?.setting.valid){
        this.activeTab=2;
      }
    }else if(tab == 3){
      if(this.myForm['controls']?.policymap.valid && this.myForm['controls']?.setting.valid){
        this.activeTab=3;
      }
    }
  }

  editForm(){
    this.loader = true;
    this.editflag = true;
    // this.tabclick    =true;
    this.title ='Edit';
    this.ExpensePolicyService.getSinglepolicyData(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res);
    },(error)=>{
      this.router.navigate(['policy/expense-policy']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }

    let assignees = [];
    let appkey ="";
    appkey = res.applicability?.applicable;

   for(let i=0;i<res.applicability[appkey].length;i++){
    assignees.push(res.applicability[appkey][i].id);
   }

   let categoryarr:any=[];
   for(let i=0;i<res.categories?.length;i++){
    const formArray: FormArray = this.policymapForm.get('categories') as FormArray;
    formArray.push(new FormControl(res.categories[i].toString()));
    categoryarr.push(res.categories[i].toString());
   }

   for(let i=0;i<res.categories?.length;i++){
    this.ExpensePolicyService.getSinglecategoryData(res.categories[i]).subscribe((result:any)=>{
      for(let k=0;k<this.categorylist.length;k++){
        if(this.categorylist[k].name==result.name){
          this.categorylist[k].id= result.id;
        }
      }
    })
   }

    this.myForm.reset(
      {
        id: res.id,
        setting: {
          name:  res.name,
          description: res.description,
        },
        policymap: {
          categories:  categoryarr,
        },
        applicable: {
          applicableto: appkey,
          assignees: assignees
        }
    });

    this.loader = false;
  }

  submitForm(){
    this.res = this.applicableForm.controls.assignees.value;
    var applicable = this.applicableForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU"){
      applicableStr = "businessunit";
    }
    var arr:any = {};
    arr['applicable']= applicable;
    arr[applicableStr.toLowerCase()]= [];
    for(let key of this.res){
      arr[applicableStr.toLowerCase()].push(key);
    }
    let categoryarr:any=[] ;
    for(let i=0;i<this.policymapForm.controls.categories.value.length;i++){
      categoryarr.push(parseInt(this.policymapForm.controls.categories.value[i]))
    }
    var dataArray = {
      "name": this.settingForm.controls.name.value,
      "description": this.settingForm.controls.description.value,
      "categories": categoryarr,
      "applicability": arr
    };

    if(this.id == 0){
      this.ExpensePolicyService.createpolicy(dataArray).subscribe((res: any) => {
        this.router.navigate(['policy/expense-policy']);
      },
      (error) => {
      });
    } else {
      this.ExpensePolicyService.updatepolicy(this.id,dataArray).subscribe((res: any) => {
        this.router.navigate(['policy/expense-policy']);
      },
      (error) => {
      });
    }
  }
}
