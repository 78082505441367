import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-leave-lop',
  templateUrl: './leave-lop.component.html',
  styleUrls: ['./leave-lop.component.scss']
})
export class LeaveLopComponent implements OnInit {

  // Filter dropdown
  @Input() employeeDropdown      : any
  @Input() submitted             : any
  @Input() companyDD             : any
  @Input() buDD                  : any
  @Input() DesignationDD         : any
  @Input() BranchDD              : any
  @Input() DepDD                 : any
  @Input() gradeDD               : any
  @Input() empstatusDD           : any
  @Input() emptypeDD             : any
  @Input() leavePolicy           : any
  @Input() leaveType             : any
  // Ends
  @Input() lopForm               : any
  @Input() viewDetail            : any
  @Input() resDataFilter         : any
  @Input() disabled              : any
  @Input() callReport            : any
  @Input() salFilter             : any
  @Input() closeControl          : any
  @Input() dateSetup             : any
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering : any
  @Input() ouFilterForm: any

  @Output() callReports  = new EventEmitter();
  @Output() filterPanel  = new EventEmitter();
  @Output() resetFilter  = new EventEmitter();
  @Output() submitDone   = new EventEmitter();

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  dateFormat          :any

  dojFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  // Form
  get f()  { return this.lopForm.controls; }

  constructor(
    public messageService : MessageService,
    private reportService : ReportsService,
    public appservice : AppService,
    public leaveService : LeaveReportService,
    public cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getDateFormat()
  }

  setRequired(val:any){
    if(val){
      this.lopForm.get('from_date')?.setValidators([Validators.required])
      this.lopForm.get('to_date')?.setValidators([Validators.required])
    }else{
      this.lopForm.get('from_date')?.clearValidators();
      this.lopForm.get('to_date')?.clearValidators();
    }
    this.lopForm.get('from_date')?.updateValueAndValidity();
    this.lopForm.get('to_date')?.updateValueAndValidity();
    this.lopForm.get('from_date')?.reset('');
    this.lopForm.get('to_date')?.reset('');
  }

  applyBtn : boolean = false

  filterFormData(use:any){
    let selectedDate :any
    if(this.lopForm.controls.from_date.value != '' && this.lopForm.controls.to_date.value != ''){
      let start1         = moment(this.lopForm.controls.from_date.value,this.dateFormat).format('YYYY-MM-DD');
      let end1           = moment(this.lopForm.controls.to_date.value,this.dateFormat).format('YYYY-MM-DD');
      selectedDate          = [start1,end1];
    }

    this.lopForm.controls.transaction_date.value == true ? this.lopForm.get('transaction_date')?.setValue(selectedDate) : this.lopForm.get('transaction_date')?.setValue(this.lopForm.controls.transaction_date.value);
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let leave_policy = this.stringifyCont(this.ouFilterForm.controls.leave_policy.value)
    let leave_type = this.stringifyCont(this.ouFilterForm.controls.leave_type.value)

    if(use == 'filter'){
      return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&branch=${branch}&grade=${grade}&employee_list=${employee_list}&employment_status=${employment_status}&employee_type=${employee_type}&leave_policy=${leave_policy}&leave_type=${leave_type}&transaction_date=${this.lopForm.controls.transaction_date.value}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'employment_status' : this.ouFilterForm.controls.employment_status?.value, 'employee_type' : this.ouFilterForm.controls.employee_type.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value,'leave_policy': this.ouFilterForm.controls.leave_policy.value,'leave_type': this.ouFilterForm.controls.leave_type.value,'transaction_date': this.lopForm.controls.transaction_date.value});
    }
  }

  submitLOPReport(){
    this.submitted          = true;
    let offset = 0
    if (this.lopForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }
    let filterForm = this.filterFormData('filter')
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    })
      this.viewDetail           = false;
      this.applyBtn = false
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  // Resetting the filters
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.lopForm.get('transaction_date')?.reset('this_month');
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.ouFilterForm.get('leave_policy')?.reset('');
      this.ouFilterForm.get('leave_type')?.reset('');
      this.lopForm.get('from_date')?.reset('')
      this.lopForm.get('to_date')?.reset('')
    }
    this.callReports.emit({
      reportName : 'Unpaid Leave Report'
    })
  }

  // Tag Close
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'employment_status'){
      this.empstatusDD = [];
    }else if(this.closeControl == 'employee_type'){
      this.emptypeDD = [];
    }
    this.ouFilterForm.get(this.closeControl)?.setValue('');
    this.submitLOPReport();
  }

  // Radio Buttons
  radioBtnChangeFunctions(e:any,contrlName:any,val:any,type:any){
    if(e.target.value == 'on' ){
      this.lopForm?.get(contrlName)?.setValue(true);
    }
    else{
      this.lopForm?.get(contrlName)?.setValue(val);
      this.lopForm?.get(type)?.setValue('');
    }
  }

  // Date Format
  getDateFormat(){
    this.dateFormat = this.appservice.getdatepickerformat();
    if(this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  resetPage(val:any){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'transaction_date' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }

  fromDateVal(value:any){
    if(moment(this.f.from_date.value).isSameOrAfter(moment(this.f.to_date.value))){
      this.lopForm.patchValue({
        to_date : null
      })
    }
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
