import { Injectable } from '@angular/core';

@Injectable()

export class MessageService{
    validationOutKey:any;
    lengthOutKey:any;
    deleteOutKey:any;
    fileformatKey:any;
    placeholderOutKey:any;
    searchOutKey:any;
    selectplaceholdOutKey:any;
    descOutKey:any;
    usedDataMsg:any;
    invalidMsg:any;
    reqMsg:any;

    constructor(){

     }
    // field validation message
     validationDisplay(validationKey:any){

      if(validationKey == 'required'){
        this.validationOutKey = 'This field is required.'
       } else if(validationKey == 'pattern'){
          this.validationOutKey = 'Enter a valid data.'
         }else if(validationKey == 'amount'){
          this.validationOutKey = 'Enter a valid amount.'
         }else{
          this.validationOutKey = 'Value entered already exists'
         }
      return this.validationOutKey;
    }
    validationdigits(fieldkey:any,digitKey:any){
      return this.validationOutKey = fieldkey + ' should be ' + digitKey + ' digits.'
    }
    dobValidation(dob:any){
      return "Date of birth should be on or before "+dob;
    }
    // field length validation message
     fieldlengthvalidation(wordnumkey:any,lengthKey:any){
      if(wordnumkey == 'word'){
        this.lengthOutKey = 'The value entered should not exceed ' + lengthKey + ' characters.'
      }else if(wordnumkey == 'num'){
        this.lengthOutKey = 'The value entered should not exceed ' + lengthKey + ' digits.'
      }
      else if(wordnumkey == 'minimum'){
        this.lengthOutKey = 'Minimum ' + lengthKey + '  characters required.'
      }
      else if(wordnumkey == 'minvalue'){
        this.lengthOutKey = 'The value entered should be above ' + lengthKey + '.'
      }
      else if(wordnumkey == 'minamount'){
        this.lengthOutKey = 'The amount entered should be above ' + lengthKey + '/-.'
      }
      else if(wordnumkey == 'days'){
        this.lengthOutKey = 'The value entered should not exceed ' + lengthKey + ' days.'
      }
      else if(wordnumkey == 'amount'){
        this.lengthOutKey = 'The amount entered should not exceed ' + lengthKey + '/-.'
      }
      else if(wordnumkey == 'value'){
        this.lengthOutKey = 'The value entered should not exceed ' + lengthKey + '.'
      }
      else if(wordnumkey == 'percentage'){
        this.lengthOutKey = 'The value entered should not exceed  ' + lengthKey + '%.'
      }
      else if(wordnumkey == 'years'){
        this.lengthOutKey = 'The value entered should not exceed ' + lengthKey + ' years.'
      }
      else if(wordnumkey == 'hours'){
        this.lengthOutKey = 'The value entered should not exceed '+ lengthKey + ' hours.'
      }
      else if(wordnumkey == 'minutes'){
        this.lengthOutKey = 'Value entered should not exceed ' + lengthKey + ' mins'
      }
      else if(wordnumkey == 'minGreat'){
        this.lengthOutKey = 'Value entered should be greater than or equal to ' + lengthKey + '.'
      }
      else if(wordnumkey == 'minDays'){
        this.lengthOutKey = 'Value entered should not exceed ' + lengthKey + ' days.'
      }
      else if(wordnumkey == 'minTimes'){
        this.lengthOutKey = 'Value entered should not exceed ' + lengthKey + ' times.'
      }
      else if(wordnumkey == 'minValueNew'){
        this.lengthOutKey = 'Value entered should be above ' + lengthKey + '.'
      }

      return  this.lengthOutKey
    }
    balanceAmountValidation(){
      this.lengthOutKey = 'The amount entered should not exceed the available balance.'
      return  this.lengthOutKey

    }
    availableLimit(key:any){
      this.lengthOutKey = 'The '+key+' entered should not exceed the available limit.'
      return  this.lengthOutKey

    }

    availableLimitdistance(key:any){
      this.lengthOutKey = 'Distance limit for this '+key+' has exceeded.'
      return  this.lengthOutKey

    }
    LimitExceeded(){
      this.lengthOutKey = 'The amount exceeds the specified limit.'
      return  this.lengthOutKey

    }

    LimitExceededlocation(){
      this.lengthOutKey = 'Amount exceeds the maximum limit defined for the location.'
      return  this.lengthOutKey
    }

    limitAmountValidation(){
      this.lengthOutKey = 'The amount entered should not exceed the available limit.'
      return  this.lengthOutKey

    }
    availableFrequency(period:any){
      this.lengthOutKey = 'Expense claim request limit for this '+ period + ' has exceeded.'
      return  this.lengthOutKey
    }
    monthlyAmountValidation(){
      this.lengthOutKey = 'The total Amount exceeds the specified CTC limit.'
      return  this.lengthOutKey

    }
    deductionAmountValidation(){
      this.lengthOutKey = 'Net take home cannot be less than zero.'
      return  this.lengthOutKey

    }
    revisedCTCValidation(){
      this.lengthOutKey = 'The revised CTC cannot be the same as the current CTC.'
      return  this.lengthOutKey

    }

    inactiveStatus() {
      this.lengthOutKey = 'Employees selected are already in Relieved, Settled, Terminated, Retired.'
      return  this.lengthOutKey
    }

    overlapMsg(){
      this.lengthOutKey = 'OT hours cannot overlap with previous entries.'
      return this.lengthOutKey
    }
    greaterThanMessage(first_field:any, second_field:any){
      return  this.deleteOutKey = ' ' + second_field + ' should be greater than ' + first_field +'.';
    }
    lessThanMessage(first_field:any, second_field:any){
      return  this.deleteOutKey = ' ' + second_field + ' should be less than or equal to ' + first_field +'.';
    }
    lessMessage(first_field:any, second_field:any){
      return   second_field + ' should be less than ' + first_field +'.';
    }
    greaterMessage(first_field:any, second_field:any){
      return  second_field + ' should be greater than or equal to ' + first_field +'.';
    }
    notGreaterMessage(first_field:any, second_field:any){
      return  second_field + ' should not be greater than ' + first_field +'.';
    }
    shiftwindowMessage(control1:any,control2:any){
      this.lengthOutKey = `${control1} and ${control2} timing should be specified in shift window only.`
      return  this.lengthOutKey
    }
    // Short Leave
    shortLeaveMessage(field:any,type:any){
      if(field == 'maximum'){
        this.lengthOutKey = `Value entered should not exceed the (${field} short leave hours) * (no. of times short leave can be applied in a ${type.toLowerCase()})`
      }else{
        this.lengthOutKey = `Value entered should not be less than (${field} short leave hours) * (no. of times short leave can be applied in a ${type.toLowerCase()})`
      }
      return this.lengthOutKey
    }
    // Delete popup message
    Deletemsgdisplay(fieldKey:any){
      return  this.deleteOutKey = 'This will delete the ' + fieldKey + ' permanently. You’ll have to add again to get it back in the list.'
    }
    validateFileFormat(format:any){
      return this.validationOutKey = "Kindly upload files in the following formats: "+format + '.';
    }
    //place holder text
    placeholderdisp(fieldKey:any){
      return  this.placeholderOutKey = 'Enter ' + fieldKey.toLowerCase();
    }
    //input field text
    searchdisplay(fieldKey:any){
      if(fieldKey == 'novalue'){
        this.searchOutKey = 'Search '
      }else{
        this.searchOutKey = 'Search ' + fieldKey.toLowerCase();
      }
      return  this.searchOutKey
    }
    //input selectfield text
    selectplaceholddisplay(fieldKey:any){
      if(fieldKey == 'novalue'){
        this.selectplaceholdOutKey = 'Select '
      }else{
        this.selectplaceholdOutKey = 'Select ' + fieldKey.toLowerCase();
      }
      return  this.selectplaceholdOutKey
    }
    //input selectfield text
    descriptiontext(fieldKey:any){
      if(fieldKey == 'desc'){
        this.descOutKey = 'Write description here...'
      } else if(fieldKey == 'time'){
        this.descOutKey = 'hh : mm'
      }else if(fieldKey == 'comment'){
        this.descOutKey = 'Comment here...'
      }
      return  this.descOutKey
    }
	//Validation for used information by other employee
    usedDataValidation(fieldKey:any){
      this.usedDataMsg = "This "+fieldKey+ " number is already being used by an employee."
      return this.usedDataMsg;
    }
    wrongLoginData(field:any){
      let messages:any =
        {'wrong_password':'You have entered an incorrect password.',
        'wrong_otp':'Incorrect OTP entered.',
        'wrong_code_email':'You have entered an incorrect email id or employee code.',
        'password_regenerated':'Your password has been reset. Please contact your HR for more details.',
        'disabled':'Your access to the portal has been disabled. Please contact your HR for further information or assistance.',
        'inactive':'Your access has been denied as your account is deactivated. Please contact your HR for further information.'
      }
      return messages[field]

      // if(field == 'wrong_password'){
      //   this.invalidMsg = 'You have entered an incorrect password.'
      // }
      // else if(field == 'wrong_otp'){
      //   this.invalidMsg = 'Incorrect OTP entered.'
      // }
      // else if(field == 'wrong_code_email'){
      //   this.invalidMsg = 'You have entered an incorrect email id or employee code.'
      // }
      // else if(field == 'password_regenerated'){
      //   this.invalidMsg = 'Your password has been reset. Please contact your HR for more details.'
      // }

      // else if(field == 'disabled'){
      //   this.invalidMsg = 'Sorry, your access to the portal has been disabled. Please contact your HR'
      // }
      // return this.invalidMsg;
    }
    requestRestriction(person:any){
      if(person=='Employee')
      this.reqMsg = 'Your last request is pending, you cannot initiate new request.';
      else
      this.reqMsg = 'You cannot edit employee profile since last request raised by employee is pending.';
      return this.reqMsg;
    }
    invalidExcelFormat(){
      this.invalidMsg = 'Invalid Header or Header Missing.'
      return this.invalidMsg;

    }
    ctcPaygrpRestriction(){
      this.invalidMsg = "You cannot update the Pay group/Annual CTC amount here as this employee was already involved in one or more pay runs. Please create a Salary Revision in order to update this employee's Pay group/CTC.";
      return this.invalidMsg;
    }
    unassignedShiftMsg(){
      this.invalidMsg = "You cannot add punch for this employee since the shift is not assigned for selected date.";
      return this.invalidMsg;
    }
    sameasvalidation(first_field:any, second_field:any){
      this.reqMsg = first_field+ ' cannot be the same as ' +second_field +'.'
      return  this.reqMsg
    }
    challanAmountValidation(){
      this.lengthOutKey = 'Sum of TDS, Education cess, Surcharge, Others, Interest, Late pay fee is not equal to total challan amount.'
      return  this.lengthOutKey

    }
    timingPattern(){
      this.invalidMsg = "The start time of the next overtime hours should match the end time of the previous overtime hour.";
      return this.invalidMsg
    }

    reportRangeValidation(){
      this.invalidMsg = "Selected expense receipt doesn’t fall under expense report date range."
      return this.invalidMsg
    }
}
