import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import { AuthService } from 'src/app/@core/auth/auth.service';

export class LoginFormValidators {

  // Format the UserDetails
  static ValidateFormat(authService:AuthService,control:AbstractControl):ValidationErrors | null{
    let data = JSON.parse(localStorage.getItem('userData') || '{}');
    
    if(control.value.length>0){

      let formvalue = control.value.length >0?control.value.toLowerCase():'';
      let fname     = data?.first_name.toLowerCase();
      let lname     = data?.last_name.toLowerCase();
      let comp      = data?.company_name?.trim().toLowerCase();
      let company   = comp?.replace(/\s/g, "")

      if(lname.length>=3){
        if(formvalue.includes(fname) || formvalue.includes(company) || formvalue.includes(lname)){
          return { invalidformat: true};
        }
      }
      else{

        if(formvalue.includes(fname) || formvalue.includes(company)){
          return { invalidformat: true};
        }
      }
    }
    return null;
    }

    // LowerCase
  static ValidateLowerCase(control:AbstractControl):ValidationErrors | null{
    let regexp_lower    = new RegExp('(?=.*[a-z])');
    let test_lower      = regexp_lower.test(control.value);

    if (control.value.length >0 && !test_lower) {

      return { lowercase: true};
    }
    return null;
  }

  // Number Validation
  static ValidateNumber(control: AbstractControl): ValidationErrors | null{
    const value = control.value;

      if (!value) {
          return null;
      }

      let regexp_number   = new RegExp('(?=.*[0-9])');
      let test_number     = regexp_number.test(control.value);

      if (control.value.length >0 && !test_number) {
        return { numberPattern: true};
      }
      return null;
  }

  // Special Character
  static ValidateSpecialCharacter(control: AbstractControl): ValidationErrors | null{
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':`~"\\|,.<>\/?]+/;
      let test_special      = specialCharacters.test(control.value);

      if (control.value.length >0 && !test_special) {

        return { special: true};
      }
      return null;
  }

  // Pass and Confirm Pass
  static ValidatePassword(group: AbstractControl): ValidationErrors | null {
    let pass        = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value

    if(pass!='' && confirmPass!=''){
      return pass === confirmPass ? null : { notSame: true }

    }
    else
      return null
  }


  static ValidateUpperCase(c: AbstractControl): ValidationErrors | null {

    let regexp_upper    = new RegExp('(?=.*[A-Z])');
    let  test_upper     = regexp_upper.test(c.value);

    if (c.value.length >0 && !test_upper) {
      return { uppercase: true};
    }
    return null;
  }
}
