<div id="top" class="container-fluid p-32 form-container" [formGroup]="formGp">

  <div class="row row-16">
    <!-- <div class="col-12">
      <div *ngIf="tot_amount" role="alert" class="alert alert-warning {{alertShow}}"><p class="mb-0"><span class="text-warning">Note:
      </span> The total amount entered under 80C section should not exceed 1,50,000 </p>
      <button class="btn-close" type="button" (click)="alertShow = '' "></button></div>
    </div> -->
    <div class="col-12">
      <div class="card details-card indicater1 px-16 py-12">
          <div class="row">
              <div class="col-5"> <div class="fw-600 fs-14">Chapter VI A - 80 C</div> </div>
              <div class="col">
                  <div class="detail-title">Maximum limit</div>
                  <div class="detail-desc">{{limitData.chapter4a80c | currency: currency}}</div>
              </div>
              <div class="col">
                  <div class="detail-title">Total amount declared</div>
                  <div class="detail-desc">{{validDeduction() | currency: currency}}</div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-12">
      <div  role="alert" class="alert alert-warning mb-0 {{alertShow}}">
        <p class="mb-0">Select the checkbox below to fill details or skip the step by clicking on skip button
        </p>
        <div class="btn-close" (click)="alertShow='hide'"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-c2 px-24 py-8" >
          <div class="card-body px-0 py-16">
              <p class=" fw-500 mb-16">Under Section 80C, A Deduction of Rs 1,50,000 Can Be Claimed From Your Total Taxable Income</p>
              <div class="row row-12">
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id=""  class="form-check-input " formControlName="employee_contribute_pf">
                          <label  for="" class="fs-14">Employee contribution towards PF</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Every month, a part of employee's salary is deducted towards EPF(employee provident Fund). The total amount deducted yearly can be claimed while computing your total taxable income." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label">Amount</label>
                    <div class="form-icon icon-right">
                      <input type="text" class="form-control" autocomplete="off" id="employee_contribute_pf_amount" placeholder="0" formControlName="employee_contribute_pf_amount" name="employee_contribute_pf_amount">
                      <span>{{currency}}</span>
                    </div>
                  </div>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id=""  class="form-check-input " formControlName="contribution_vpf" >
                          <label  for="" class="fs-14">Contribution towards VPF</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="The employee's voluntary provident fund (VPF) contribution is an additional contribution made to increase his provident fund account. This contribution qualifies for a deduction under this section." popoverClass="popover-default popover-info"triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label">Amount</label>
                    <div class="form-icon icon-right">
                      <input type="text" class="form-control" autocomplete="off" id="contribution_vpf_amount" placeholder="0" formControlName="contribution_vpf_amount" name="contribution_vpf_amount">
                      <span>{{currency}}</span>
                    </div>
                  </div>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id=""  class="form-check-input " name="contribution_15y_ppf" formControlName="contribution_15y_ppf" (change)="checkBoxFunctionTest($event,'ppf_amount')">
                          <label  for="" class="fs-14">Contribution towards 15 years PPF</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Investment in Public Provident Fund (PPF) eligible for deduction under the section 80C.. You can invest from ₹ 500 upto maximum ₹ 1.5 lakh in a financial year. The interest on PPF is currently tax-free." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.contribution_15y_ppf">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.ppf_amount.dirty || f.ppf_amount.touched) &&  f.ppf_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="ppf_amount" name="ppf_amount" [ngClass]="{ 'is-invalid': (submitted || f.ppf_amount.dirty || f.ppf_amount.touched) &&  (f.ppf_amount.errors || amountBoxName == 'ppf_amount')}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.ppf_amount.dirty || f.ppf_amount.touched) && f.ppf_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.ppf_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.ppf_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.ppf_amount.errors.pattern) && f.ppf_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>

                          </div>
                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id=""  class="form-check-input " formControlName="previous_employer_pf">
                          <label  for="" class="fs-14">Previous employer PF</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="The amount contributed by the previous employer  to the employee's PF account will be considered for the same fiscal year." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <div class=" col-md-6 form-row">
                    <label for="" class="form-label">Amount</label>
                    <div class="form-icon icon-right">
                      <input type="text" class="form-control" autocomplete="off" id="previous_employer_pf_amount" placeholder="0" formControlName="previous_employer_pf_amount" name="previous_employer_pf_amount">
                      <span>{{currency}}</span>
                    </div>
                  </div>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="five_year_POTD" class="form-check-input " formControlName="five_year_POTD" (change)="checkBoxFunctionTest($event,'POTD_amount')">
                          <label  for="" class="fs-14">Five-year post office time deposit (POTD) scheme</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Post Office Time Deposits (POTD) is similar to Fixed deposits. The investment tenures are one,two,three and five years. Only five year POTD qualifies for tax-saving under this section." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.five_year_POTD">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.POTD_amount.dirty || f.POTD_amount.touched) && (f.POTD_amount.errors || amountBoxName == 'POTD_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="POTD_amount" name="POTD_amount" [ngClass]="{ 'is-invalid': (submitted || f.POTD_amount.dirty || f.POTD_amount.touched) &&  (f.POTD_amount.errors || amountBoxName == 'POTD_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.POTD_amount.dirty || f.POTD_amount.touched) && f.POTD_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.POTD_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.POTD_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.POTD_amount.errors.pattern) && f.POTD_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="subscribe_national_saving"  class="form-check-input " formControlName="subscribe_national_saving" (change)="checkBoxFunctionTest($event,'nsc_amount')">
                          <label  for="" class="fs-14">Subscription to national saving scheme (NSC)</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Investments towards NSC with a maturity period of 5 years are eligible for exemption under section 80C( max upto 1,50000)." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.subscribe_national_saving">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.nsc_amount.dirty || f.nsc_amount.touched) &&  (f.nsc_amount.errors || amountBoxName == 'nsc_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="nsc_amount" name="nsc_amount" [ngClass]="{ 'is-invalid': (submitted || f.nsc_amount.dirty || f.nsc_amount.touched) &&  (f.nsc_amount.errors || amountBoxName == 'nsc_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.nsc_amount.dirty || f.nsc_amount.touched) && f.nsc_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.nsc_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.nsc_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.nsc_amount.errors.pattern) && f.nsc_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>

                          </div>

                      </div>
                  </ng-container>

                  <!-- NEW ITEMS -->
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="nabard_rural_bond"  class="form-check-input " formControlName="nabard_rural_bond" (change)="checkBoxFunctionTest($event,'nabard_rural_bond_amount')">
                          <label  for="" class="fs-14">NABARD rural bonds</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover=" NABARD Bonds are tax-free and are eligible for exemption under section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.nabard_rural_bond">
                    <div class=" col-md-6 form-row">
                        <label for="" class="form-label required">Amount</label>
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.nabard_rural_bond_amount.dirty || f.nabard_rural_bond_amount.touched) &&  (f.nabard_rural_bond_amount.errors || amountBoxName == 'nabard_rural_bond_amount')}">
                          <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="nabard_rural_bond_amount" name="nabard_rural_bond_amount" [ngClass]="{ 'is-invalid': (submitted || f.nabard_rural_bond_amount.dirty || f.nabard_rural_bond_amount.touched) &&  (f.nabard_rural_bond_amount.errors || amountBoxName == 'nabard_rural_bond_amount')}" >
                          <span>{{currency}}</span>
                        </div>
                        <div *ngIf="(submitted || f.nabard_rural_bond_amount.dirty || f.nabard_rural_bond_amount.touched) && f.nabard_rural_bond_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.nabard_rural_bond_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.nabard_rural_bond_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                            <div *ngIf="!(f.nabard_rural_bond_amount.errors.pattern) && f.nabard_rural_bond_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                        </div>
                    </div>
                  </ng-container>

                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="lic"  class="form-check-input " formControlName="lic" (change)="checkBoxFunctionTest($event,'lic_amount')">
                          <label  for="" class="fs-14">Life insurance premium</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="As per section 80C any amount paid towards life insurance for yourself, spouse or children can be claimed as tax exemption upto ₹1,50,000/-." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.lic">
                    <div class=" col-md-6 form-row">
                        <label for="" class="form-label required">Amount</label>
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.lic_amount.dirty || f.lic_amount.touched) &&  (f.lic_amount.errors || amountBoxName == 'lic_amount')}">
                          <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="lic_amount" name="lic_amount" [ngClass]="{ 'is-invalid': (submitted || f.lic_amount.dirty || f.lic_amount.touched) &&  (f.lic_amount.errors || amountBoxName == 'lic_amount')}" >
                          <span>{{currency}}</span>
                        </div>
                        <div *ngIf="(submitted || f.lic_amount.dirty || f.lic_amount.touched) && f.lic_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.lic_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.lic_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                            <div *ngIf="!(f.lic_amount.errors.pattern) && f.lic_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                        </div>
                    </div>
                  </ng-container>

                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="annuity"  class="form-check-input " formControlName="annuity" (change)="checkBoxFunctionTest($event,'annuity_amount')">
                          <label  for="" class="fs-14">Annuity plan of LIC or other insurer towards pension scheme (80 CCC)</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Employees can claim a tax deduction for pension fund investments, such as the purchase of a new policy or the renewal of an existing pension policy. The insurance must be based on a pension or annuity to qualify for this deduction. Each year, a maximum of 1.5 lakh can be claimed." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.annuity">
                    <div class=" col-md-6 form-row">
                        <label for="" class="form-label required">Amount</label>
                        <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.annuity_amount.dirty || f.annuity_amount.touched) &&  (f.annuity_amount.errors || amountBoxName == 'annuity_amount')}">
                          <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="annuity_amount" name="annuity_amount" [ngClass]="{ 'is-invalid': (submitted || f.annuity_amount.dirty || f.annuity_amount.touched) &&  (f.annuity_amount.errors || amountBoxName == 'annuity_amount')}" >
                          <span>{{currency}}</span>
                        </div>
                        <div *ngIf="(submitted || f.annuity_amount.dirty || f.annuity_amount.touched) && f.annuity_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.annuity_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.annuity_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                            <div *ngIf="!(f.annuity_amount.errors.pattern) && f.annuity_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                        </div>
                    </div>
                  </ng-container>
                  <!-- END -->

                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="deduction_contribute_NPS" class="form-check-input " formControlName="deduction_contribute_NPS" (change)="checkBoxFunctionTest($event,'nps_amount')">
                          <label  for="" class="fs-14">Deduction in respect of contribution to NPS (80 CCD(1))</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Any contribution made by an individual to the National Pension Scheme qualifies for a deduction under 80 CCD (1)." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.deduction_contribute_NPS">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.nps_amount.dirty || f.nps_amount.touched) &&  (f.nps_amount.errors || amountBoxName == 'nps_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="nps_amount" name="nps_amount" [ngClass]="{ 'is-invalid': (submitted || f.nps_amount.dirty || f.nps_amount.touched) &&  (f.nps_amount.errors || amountBoxName == 'nps_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.nps_amount.dirty || f.nps_amount.touched) && f.nps_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.nps_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.nps_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.nps_amount.errors.pattern) && f.nps_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="equity_linked_saving" class="form-check-input " formControlName="equity_linked_saving" (change)="checkBoxFunctionTest($event,'ELSS_amount')">
                          <label  for="" class="fs-14">Equity linked saving schemes (tax saving mutual funds) ELSS</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Equity Linked Savings Scheme (ELSS) are mutual funds created to offer you tax savings (Max Upto ₹1, 50,000), eligible for exemption under Section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.equity_linked_saving">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.ELSS_amount.dirty || (f.ELSS_amount.touched || amountBoxName == 'ELSS_amount')) &&  f.ELSS_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="ELSS_amount" name="ELSS_amount" [ngClass]="{ 'is-invalid': (submitted || f.ELSS_amount.dirty || (f.ELSS_amount.touched || amountBoxName == 'ELSS_amount')) &&  f.ELSS_amount.errors}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.ELSS_amount.dirty || f.ELSS_amount.touched) && f.ELSS_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.ELSS_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.ELSS_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.ELSS_amount.errors.pattern) && f.ELSS_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="ULIP" class="form-check-input " formControlName="ULIP" (change)="checkBoxFunctionTest($event,'ULIP_amount')">
                          <label  for="" class="fs-14">ULIP</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Investment in ULIP offers you tax savings (Max Upto ₹1, 50,000), eligible for exemption under Section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.ULIP">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.ULIP_amount.dirty || f.ULIP_amount.touched) &&  (f.ULIP_amount.errors || amountBoxName == 'ULIP_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="ULIP_amount" name="ULIP_amount" [ngClass]="{ 'is-invalid': (submitted || f.ULIP_amount.dirty || f.ULIP_amount.touched) &&  (f.ULIP_amount.errors || amountBoxName == 'ULIP_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.ULIP_amount.dirty || f.ULIP_amount.touched) && f.ULIP_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.ULIP_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.ULIP_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.ULIP_amount.errors.pattern) && f.ULIP_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="interest_NSC" class="form-check-input " formControlName="interest_NSC" (change)="checkBoxFunctionTest($event,'interest_NSC_amount')">
                          <label  for="" class="fs-14">Interest on national saving certificate</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="The interest earned on NSC is tax-free. Only the final year's interest does not earn a tax deduction when the NSC matures since it is not reinvested but is paid back to the investor along with the prior years' interest and the capital amount." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.interest_NSC">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.interest_NSC_amount.dirty || f.interest_NSC_amount.touched) &&  (f.interest_NSC_amount.errors || amountBoxName == 'interest_NSC_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="interest_NSC_amount" name="interest_NSC_amount" [ngClass]="{ 'is-invalid': (submitted || f.interest_NSC_amount.dirty || f.interest_NSC_amount.touched) &&  (f.interest_NSC_amount.errors || amountBoxName == 'interest_NSC_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.interest_NSC_amount.dirty || f.interest_NSC_amount.touched) && f.interest_NSC_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.interest_NSC_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.interest_NSC_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.interest_NSC_amount.errors.pattern) && f.interest_NSC_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="child_education_tuition_fees" class="form-check-input " formControlName="child_education_tuition_fees" (change)="checkBoxFunctionTest($event,'tuition_fees')">
                          <label  for="" class="fs-14">Children education tuition fees</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="A max upto 1.5 lakhs towards children education can be claimed as exemption under section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.child_education_tuition_fees">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.tuition_fees.touched || f.tuition_fees.dirty) &&  (f.tuition_fees.errors || amountBoxName == 'tuition_fees')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="tuition_fees" name="tuition_fees" [ngClass]="{ 'is-invalid': (submitted || f.tuition_fees.touched || f.tuition_fees.dirty) &&  (f.tuition_fees.errors || amountBoxName == 'tuition_fees')}" >
                            <span>{{currency}}</span>
                          </div>
                      <div *ngIf="(submitted || f.tuition_fees.touched || f.tuition_fees.dirty) && f.tuition_fees.errors" class="invalid-feedback">
                          <div *ngIf="f.tuition_fees.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="f.tuition_fees.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                          <div *ngIf="f.tuition_fees.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>

                      </div>

                    </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="repay_house_principal" class="form-check-input " formControlName="repay_house_principal" (change)="checkBoxFunctionTest($event,'repay_house_amount')">
                          <label  for="" class="fs-14">Repayment of housing principal</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Principal amount of EMI to repay home loan is eligible for deduction under section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.repay_house_principal">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.repay_house_amount.dirty || f.repay_house_amount.touched) &&  (f.repay_house_amount.errors || amountBoxName == 'repay_house_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="repay_house_amount" name="repay_house_amount" [ngClass]="{ 'is-invalid': (submitted || f.repay_house_amount.dirty || f.repay_house_amount.touched) &&  (f.repay_house_amount.errors || amountBoxName == 'repay_house_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.repay_house_amount.dirty || f.repay_house_amount.touched) && f.repay_house_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.repay_house_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.repay_house_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.repay_house_amount.errors.pattern) && f.repay_house_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id=""  name="stampduty_purchase_house" class="form-check-input " formControlName="stampduty_purchase_house" (change)="checkBoxFunctionTest($event,'stampduty_amount')">
                          <label  for="" class="fs-14">Stamp duty paid for purchase of house property</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Any contribution made to a development authority, such as the Delhi Development Authority (DDA), in order to acquire a house is likewise deductible under section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.stampduty_purchase_house">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.stampduty_amount.dirty || f.stampduty_amount.touched) &&  (f.stampduty_amount.errors || amountBoxName == 'stampduty_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="stampduty_amount" name="stampduty_amount" [ngClass]="{ 'is-invalid': (submitted || f.stampduty_amount.dirty || f.stampduty_amount.touched) &&  (f.stampduty_amount.errors || amountBoxName == 'stampduty_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.stampduty_amount.dirty || f.stampduty_amount.touched) && f.stampduty_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.stampduty_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.stampduty_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.stampduty_amount.errors.pattern) && f.stampduty_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" id="" name="saukanya_samridhi_yojana" class="form-check-input " formControlName="saukanya_samridhi_yojana" (change)="checkBoxFunctionTest($event,'ssy_amount')">
                          <label  for="" class="fs-14">Sukanya samriddhi yojana </label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="In this scheme, you can open an account on behalf of your minor daughter till the age of 10. Any amount deposited in this account would be eligible for deduction under Section 80C. Further, this account can be opened for a maximum of two girls." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave" ></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.saukanya_samridhi_yojana">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.ssy_amount.dirty || f.ssy_amount.touched) &&  (f.ssy_amount.errors || amountBoxName == 'ssy_amount')}">
                            <input type="text" class="form-control" autocomplete="off" id="name" placeholder="{{this.messageService.placeholderdisp('amount')}}" formControlName="ssy_amount" name="ssy_amount" [ngClass]="{ 'is-invalid': (submitted || f.ssy_amount.dirty || f.ssy_amount.touched) &&  (f.ssy_amount.errors || amountBoxName == 'ssy_amount')}" >
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.ssy_amount.dirty || f.ssy_amount.touched) && f.ssy_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.ssy_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.ssy_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.ssy_amount.errors.pattern) && f.ssy_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>

                      </div>
                  </ng-container>
              </div>
          </div>


      </div>
    </div>
  </div>
</div>
<!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
<footer class="submit-footer gap-16">
  <button class="btn btn-outline-primary btn-sm ms-auto text-uppercase"  (click)="validateChap2('skip')">SKIP</button>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="!formGp.valid" (click)="validateChap2('next')">NEXT</button>
</footer>
