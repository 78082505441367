<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4">
      <h3>Employee</h3>
    </div>
    <div class="col-md-8">
      <div class="d-flex justify-content-end gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey" [isLoading]="sLoading"
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
            id="dropdownBasic1" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
            (filterClose)="closefn($event)"  (resetCalled)="resetCalled()" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
        </div>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="viewToggle" id="grid" [checked]='viewToggle=="grid"' (click)="toggleMode('grid')" />
          <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="grid"><i
              class="icon-grid fs-13"></i></label>
          <input type="radio" class="btn-check" name="viewToggle" id="list" [checked]='viewToggle=="list"' (click)="toggleMode('list')" />
          <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="list"><i
              class="icon-list-ul fs-13"></i> </label>
        </div>
        <a *ngIf="permissions.a" routerLink="/employee/employee-setup"
          class="btn btn-outline-primary btn-icon text-uppercase fs-10 radius-2 btn-sm">
          Import
        </a>
        <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add"
          (click)="getFormPrefillData();showSidePanel = true">
          <i class="icon-plus"></i>Add employee
        </button>
      </div>
    </div>
  </div>
  <div class="row mb-16">
    <div class="col-12">
      <div class="card card-c2">
        <div class="card-body p-24 d-flex gap-24 flex-wrap">
          <div class="statistic bg-primary">
            <span>total employees :</span> {{statusList.total_employees}}
          </div>
          <div class="statistic bg-success">
            <span>Confirmed : </span> {{statusList.confirmed}}
          </div>
          <div class="statistic bg-accent1">
            <span>Probation : </span> {{statusList.probation}}
          </div>
          <div class="statistic bg-warning">
            <span>relieved : </span> {{statusList.relieved}}
          </div>
          <div class="statistic bg-info">
            <span>Resigned : </span> {{statusList.resigned}}
          </div>
          <div class="statistic bg-danger">
            <span>Terminated : </span> {{statusList.terminated}}
          </div>
          <div class="statistic bg-47">
            <span>settled : </span> {{statusList.settled}}
          </div>
          <div class="statistic bg-48">
            <span>retired : </span> {{statusList.retired}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="nodata;else all_data" class="row " style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg22.png'" [noDataText]="'Start building your ideal team!'"
      [noDataPara]="'The roster is waiting to be filled! Click \'Add Employee\' to bring on new talent or \'Import\' to load the team quickly.'"
      [hasPermission]="permissions?.a" (addData)="showSidePanel = true"> </app-nodata-view>
    </div>
  <ng-template #all_data>
    <ng-container *ngIf="viewToggle=='grid'">
      <div class="row row-16  mb-16" *ngIf="loader">
        <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </div>
      <div class="row row-16  " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        [fromRoot]="true" (scrolled)="onScrollDown()">
        <ng-container *ngIf="!loader">
          <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let i of emp;let ind = index">
            <div class="card card-c2 card-hover px-16" (click)="profileEdit(i.id)">
              <div class="card-body pb-8 px-0">
                <div class="d-flex gap-16">
                  <img *ngIf="i.profile_image !=  null && i.profile_image !=  ''" alt=""
                    class="avatar-circle avatar-border sq-45" src="{{i.profile_image}}" />
                  <div *ngIf="i.profile_image ==  '' || i.profile_image ==  null"
                    class="avatar-circle avatar-border fs-14 fw-500 {{i.color_code}}  sq-45">{{i.nameShort}}</div>
                  <div class="vstack text-trim">
                    <p class="mb-0 fs-12 fw-500 lh-base text-trim" title="{{i.name}}">{{i.name}}</p>
                    <p class="mb-0 fs-10 fw-500 lh-base text-trim text-light-500 hstack gap-4">
                      <span>{{i.employee_code}}</span>|
                      <span>{{i.designation}}</span>
                    </p>
                    <span class="text-uppercase fs-10 fw-500
                                  {{(i.employment_status == 'Confirmed') ? 'text-success' :
                                (i.employment_status == 'Probation') ? 'text-accent1':
                                (i.employment_status == 'Relieved') ? 'text25':
                                (i.employment_status == 'Resigned') ? 'text7':
                                (i.employment_status == 'Settled') ? 'text23':
                                (i.employment_status == 'Retired') ? 'text24':
                                (i.employment_status == 'Terminated') ? 'text-danger': ' '}}
                                ">{{i.employment_status}}</span>
                  </div>
                </div>
              </div>
              <div class="card-body px-0 pt-8 fs-12 justify-content-between hstack">
                <span class="text-light-500">Date of Joining</span>
                <span>{{this.appservice.dateFormatDisplay(i.date_of_joined)}}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="viewToggle=='list'">
      <app-table-loader *ngIf="loader"></app-table-loader>
      <ng-container *ngIf="!loader">
          <div class="row row-16 ">
      <div class="col-12">
        <div class="table-responsive card  scrollbar-10 table-minheight"
          style="max-height: calc(100vh - 17.6rem)" >
          <table class="table sticky-header table-bordered table-hover table-sm form-cell-sm  sticky-first-col td-white-space">
            <thead>
              <tr>
                <th class="fw-600 text-center" scope="col">Employee </th>
                <th class="fw-600 text-center" >Email Address</th>
                <th class="fw-600 text-center" >Date of Joining</th>
                <th class="fw-600 text-center" >Company</th>
                <th class="fw-600 text-center" >Business Unit</th>
                <th class="fw-600 text-center" >Department</th>
                <th class="fw-600 text-center" >Designation</th>
                <th class="fw-600 text-center" >Branch</th>
                <th class="fw-600 text-center" >Employment Type</th>
                <th class="fw-600 text-center" >Employment Status</th>
                <th class="fw-600 text-center" >Current Experience</th>
                <th class="fw-600 text-center" >Total Experience</th>
                <th class="fw-600 text-center" >Reporting Manager</th>
                <th class="fw-600 text-center" >Mobile Number</th>
                <th class="fw-600 text-center" >Aadhar</th>
                <th class="fw-600 text-center" >PAN</th>
              </tr>
            </thead>
            <tbody class="">
              <tr *ngFor="let i of emp;let ind = index">
                <td (click)="profileEdit(i.id)">
                  <div class=" d-flex align-items-center pointer">
                    <img *ngIf="i.profile_image !=  null && i.profile_image !=  ''" alt=""
                    class="avatar-circle avatar-border sq-36" src="{{i.profile_image}}" >
                    <div *ngIf="i.profile_image ==  '' || i.profile_image ==  null"
                    class="avatar-circle avatar-border fs-12 fw-500 {{i.color_code}}  sq-36">{{i.nameShort}}</div>
                    <div class="overflow-hidden ms-16 d-flex flex-column">
                      <p class="fs-14 fw-600 w-15ch text-trim mb-0" ngbPopover="{{i.name}}"
                        popoverClass="popover-input popover-info" container="body" triggers="hover">{{i.name}}</p>
                      <p class="fs-10 mb-0  text-trim lh-base">{{i?.employee_code}}</p>
                    </div>
                  </div>
                </td>
                <td >{{i?.work_email}}</td>
                <td >{{this.appservice.dateFormatDisplay(i.date_of_joined)}}</td>
                <td >{{i?.company}}</td>
                <td >{{i?.bu}}</td>
                <td >{{i?.department}}</td>
                <td >{{i?.designation}}</td>
                <td >{{i?.branch}}</td>
                <td >{{i?.employment_type!=''?i?.employment_type:'-'}}</td>
                <td >{{i.employment_status}}</td>
                <td >{{i?.current_experience}}</td>
                <td >{{i?.total_experience?i?.total_experience:'-'}}</td>
                <td >{{i?.reporting_manager?i?.reporting_manager:'-'}}</td>
                <td >{{i?.mobile_number?i?.mobile_number:'-'}}</td>
                <td >{{i?.aadhar_number?i?.aadhar_number:'-'}}</td>
                <td >{{i?.pan_number?i?.pan_number:'-'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12" *ngIf="listCount>40">
        <ngb-pagination class="d-flex justify-content-end" [page]="page" [collectionSize]="listCount"
          [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="defaultlimit"
          (pageChange)="pageChanged($event)"></ngb-pagination>
      </div>

      </div>
    </ng-container >
    </ng-container>
  </ng-template>
</div>
<div class="side-panel" style="--sidepanel-width:56.875rem;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" [formGroup]="employeeForm" autocomplete="off">
    <header class="side-panel-head">
      <h5>Add New Employee</h5>
      <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <p class="mb-16 fs-14"><span class="text-primary">Note</span> - Employees snapshot information is entered here.
          which is mandatory. The employees other information can be added by visiting their profile section. </p>
        <div class="row ">
          <div class="col-12">
            <div class="card card-c2">
              <div class="card-body p-24">
                <p class="text-uppercase fw-500 mb-16">Basic information</p>
                <div class="row row-16">
                  <div class="col-md-4 col-sm-6 " *ngIf="imageUrl==''">
                    <label for="pro-pic" class="form-label">Profile image</label>
                    <label for="pro-pic" class="upload-btn is-invalid">
                      <img *ngIf="imageUrl!=''" class="upload-img" [src]="imageUrl">
                      <div class="upload-text"> <span class="text-primary">Upload</span> your image</div>
                      <app-file-upload [crop]="true" [round]="false" [id]='"pro-pic"' [width]="504"
                        (dataURL)="getURL($event)" [FileType]="'.png,.jpeg,.jpg'" (fileformat)="getfileformat($event)"
                        (validsize)="getvalidsize($event)"></app-file-upload>
                    </label>
                    <span class="invalid-feedback" *ngIf="invalidFile">{{errormsg}}</span>
                  </div>
                  <div *ngIf="imageUrl!=''" class="col-md-4 col-sm-6 mx-auto ">
                    <label for="pro-pic_new" class="form-label">Profile image</label>
                    <label for="pro-pic_new" class="upload-btn">
                      <img class="upload-img" [src]="imageUrl" alt="">
                      <div class="upload-text"> <span class="text-primary">Upload </span> Other image</div>
                      <button class=" btn-reset p-0 upload-delete " (click)="deleteProfilePic($event)">
                        <i class="icon-trash"></i>
                      </button>
                      <label for="pro-pic_new" class="is-invalid">
                        <app-file-upload [crop]="true" [round]="false" [id]='"pro-pic_new"' [width]="504"
                          (dataURL)="getURL($event)" [FileType]="'.png,.jpeg,.jpg'" (fileformat)="getfileformat($event)"
                          (validsize)="getvalidsize($event)"></app-file-upload>
                      </label>
                      <span class="invalid-feedback" *ngIf="invalidFile">{{errormsg}}</span>
                    </label>
                  </div>
                  <div class="col-md-8 col-sm-6 ">
                    <div class="row row-16">
                      <div class=" col-md-6 col-sm-6 form-row">
                        <label for="name" class="form-label required">Prefix </label>
                        <app-master-select
                          [ngClass]="{ 'is-invalid': ( f.prefix.touched || submitted) && f.prefix.errors }"
                          [submitted]="submitted" [isrequired]='"true"' [placeholder]="prefixkey"
                          [selectedItem]="f.prefix.value" id="prefix" formControlName="prefix" [type]='"salutation"'
                          [selectError]='"Prefix"'></app-master-select>
                        <div *ngIf="( f.prefix.touched || submitted) && f.prefix.errors" class="invalid-feedback">
                          <div *ngIf="f.prefix.errors.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                        </div>
                      </div>
                      <div class=" col-md-6 col-sm-6 form-row">
                        <label for="first_name" class="form-label required">First name </label>
                        <input type="text" class="form-control" formControlName="first_name" id="first_name"
                          placeholder="{{this.messageService.placeholderdisp('First name')}}" autocomplete="off"
                          [ngClass]="{ 'is-invalid': (submitted || f.first_name.touched || f.first_name.dirty) && f.first_name.errors }">
                        <div *ngIf="(submitted || f.first_name.touched || f.first_name.dirty) && f.first_name.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.first_name.errors.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="f.first_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                          </div>
                          <div *ngIf="!(f.first_name.errors.pattern) && f.first_name.errors.maxlength">
                            {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 form-row">
                        <label for="middle_name" class="form-label">Middle name </label>
                        <input type="text" class="form-control" formControlName="middle_name" id="middle_name"
                          placeholder="{{this.messageService.placeholderdisp('Middle Name')}}"
                          [ngClass]="{ 'is-invalid': (submitted || f.middle_name.dirty ) && f.middle_name.errors }">
                        <div *ngIf="(submitted || f.middle_name.dirty) && f.middle_name.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.middle_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                          </div>
                          <div *ngIf="!(f.middle_name.errors.pattern) && f.middle_name.errors.maxlength">
                            {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 form-row">
                        <label for="last_name" class="form-label ">Last name </label>
                        <input type="text" class="form-control" formControlName="last_name" id="last_name"
                          placeholder="{{this.messageService.placeholderdisp('Last Name')}}"
                          [ngClass]="{ 'is-invalid': (submitted || f.last_name.touched || f.last_name.dirty) && f.last_name.errors }">
                        <div *ngIf="(submitted || f.last_name.touched || f.last_name.dirty) && f.last_name.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.last_name.errors.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                          <div *ngIf="f.last_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                          </div>
                          <div *ngIf="!(f.last_name.errors.pattern) && f.last_name.errors.maxlength">
                            {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Date of birth </label>
                    <div class="form-icon icon-right"
                      [ngClass]="{ 'is-invalid': (submitted || f.do_birth.touched) && f.do_birth.errors }">
                      <input (click)="datePicker1.open()" class="form-control"
                        placeholder="{{this.messageService.selectplaceholddisplay('Date of birth')}}" matInput
                        formControlName="do_birth" [matDatepickerFilter]="dobFilter" [max]="datedob"
                        [matDatepicker]="datePicker1" (keydown)="disableDate()"
                        [ngClass]="{ 'is-invalid': (submitted || f.do_birth.touched) && f.do_birth.errors }">
                      <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                      <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                    </div>
                    <div *ngIf="(submitted || f.do_birth.touched) && f.do_birth.errors" class="invalid-feedback">
                      <div *ngIf="f.do_birth.errors">{{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="f.do_birth.errors.matDatepickerMax">
                        {{this.messageService.dobValidation(this.appservice.dateFormatDisplay(datedob))}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Gender </label>
                    <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.gender.touched) && f.gender.errors }"
                      [submitted]="submitted" [isrequired]='"true"' [placeholder]="genderkey" id="gender"
                      [submitted]="submitted" [selectedItem]="f.gender.value" formControlName="gender" [type]="'gender'"
                      [selectError]='"Gender"'></app-master-select>
                    <div *ngIf="(submitted || f.gender.touched) && f.gender.errors" class="invalid-feedback">
                      <div *ngIf="f.gender.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label">Blood group </label>
                    <app-master-select
                      [ngClass]="{ 'is-invalid': (submitted || f.blood_group.touched) && f.blood_group.errors }"
                      [submitted]="submitted" [isrequired]='"true"' [placeholder]="bloodgrpkey" id="blood_group"
                      [selectedItem]="f.blood_group.value" formControlName="blood_group" [type]="'blood_Group'"
                      [selectError]='"Blood Group"'></app-master-select>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Work email</label>
                    <input type="email" formControlName="email" class="form-control" id="name"
                      placeholder="{{this.messageService.placeholderdisp('Work email')}}"
                      [ngClass]="{ 'is-invalid': (submitted || f.email.touched || f.email.dirty) && (f.email.errors || mailError!='') || f.email.errors?.exist}"
                      (keyup)="keyUp('user__email',f.email.value)" (focusout)="offKey('user__email',f.email.value)">
                    <div
                      *ngIf="(submitted || f.email.touched || f.email.dirty) && (f.email.errors || mailError!='') || f.email.errors?.exist"
                      class="invalid-feedback">
                      <div *ngIf="f.email.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="f.email.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                      </div>
                      <!-- <div *ngIf="!(f.email.errors?.pattern) && mailError!=''">{{mailError}}</div> -->
                      <div *ngIf="f.email.hasError('exist')">{{this.messageService.validationDisplay(f.email.value)}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Phone number </label>
                    <div class="d-flex form-ngselect-input"
                      [ngClass]="{ 'is-invalid': hasEmergencyContactError() || f.phone_number.errors?.exist}">
                      <app-master-select
                        [invalidClass]="{ 'is-invalid': hasEmergencyContactError() || f.phone_number.errors?.exist}"
                        [ngClass]="{ 'is-invalid': hasEmergencyContactError() || f.phone_number.errors?.exist }"
                        [isrequired]='"true"' [placeholder]="''" id="phone_code" [submitted]="submitted" (selectedValue)="phnNumSelected($event)"
                        [selectedItem]="f.phone_code.value" formControlName="phone_code" [type]='"telephone_code"'></app-master-select>
                      <input type="text" class=" form-control" name="phone_code" id="phone_code"
                        placeholder="{{this.messageService.placeholderdisp('Phone number')}}"
                        formControlName="phone_number"
                        [ngClass]="{ 'is-invalid': hasEmergencyContactError() || f.phone_number.errors?.exist}"

                        (focusout)="offKey('mobile_number',f.phone_number.value)" autocomplete="off">
                        <!-- (keyup)="keyUp('mobile_number',f.phone_number.value)" -->
                    </div>
                    <div
                      *ngIf="hasEmergencyContactError() || f.phone_number.errors?.exist"
                      class="invalid-feedback">
                      <!-- <div
                        *ngIf="f.phone_code.errors && f.phone_code.errors.required  && employeeForm.get('phone_number').valid">
                        {{this.messageService.validationDisplay('required')}}</div> -->
                      <div
                        *ngIf="f.phone_number.errors && (f.phone_number.errors.pattern || f.phone_number.errors.minlength || f.phone_number.errors?.maxlength)">
                        {{this.messageService.validationDisplay('pattern')}}
                      </div>
                      <div *ngIf="f.phone_number.errors && f.phone_number.errors?.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                      <div *ngIf="phnError!='' && !(f.phone_number.errors)">{{phnError}}</div>
                      <div *ngIf="f.phone_number.hasError('exist')">
                        {{this.messageService.validationDisplay(f.phone_number.value)}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Nationality </label>
                    <app-master-select
                      [ngClass]="{ 'is-invalid': (submitted || f.nationality.touched) && f.nationality.errors }"
                      [isrequired]='"true"' [placeholder]="natkey" id="nationality" [submitted]="submitted"
                      [selectedItem]="f.nationality.value" formControlName="nationality" [type]='"nationality"'
                      [selectError]='"Nationality"'></app-master-select>
                    <div *ngIf="(submitted || f.nationality.touched) && f.nationality.errors" class="invalid-feedback">
                      <div *ngIf="f.nationality.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Date of joining </label>
                    <div class="form-icon icon-right"
                      [ngClass]="{ 'is-invalid': (submitted || f.do_join.touched) && f.do_join.errors }">
                      <input (click)="datePicker2.open();" class="form-control"
                        placeholder="{{this.messageService.selectplaceholddisplay('Date of joining')}}" matInput
                        formControlName="do_join"  [max]="maxdoj" [matDatepickerFilter]="dojFilter" [matDatepicker]="datePicker2"
                        (keydown)="disableDate()"
                        [ngClass]="{ 'is-invalid': (submitted || f.do_join.touched) && f.do_join.errors }">
                        <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                      <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                    </div>
                    <div *ngIf="(submitted  || f.do_join.touched) && f.do_join.errors" class="invalid-feedback">
                      <div *ngIf="f.do_join.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Employee code </label>
                    <input type="text" class="form-control" autocomplete="off"
                      [ngClass]="{ 'is-invalid': (submitted || f.emp_code.touched || f.emp_code.dirty) && (f.emp_code.errors || codeError!='') || f.emp_code.errors?.exist}"
                      formControlName="emp_code" id="name"
                      placeholder="e.g. EMP001"
                      (keyup)="keyUp('employee_code',f.emp_code.value)"
                      (focusout)="offKey('employee_code',f.emp_code.value)">
                    <div
                      *ngIf="(submitted || f.emp_code.touched || f.emp_code.dirty) && (f.emp_code.errors || codeError!='') || f.emp_code.errors?.exist"
                      class="invalid-feedback">
                      <div *ngIf="f.emp_code.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                      <div *ngIf="f.emp_code.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                      </div>
                      <div *ngIf="!(f.emp_code.errors?.pattern) && codeError!=''">{{codeError}}</div>
                      <div *ngIf="f.emp_code.hasError('exist')">
                        {{this.messageService.validationDisplay(f.emp_code.value)}}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="text-uppercase fw-500 mb-0">Employment details </p>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Employment type </label>
                    <app-master-select
                      [ngClass]="{ 'is-invalid': (submitted || f.emp_type.touched) && f.emp_type.errors }"
                      [isrequired]='"true"' [placeholder]="emptypekey" id="emp_type" [submitted]="submitted"
                      [selectedItem]="f.emp_type.value" formControlName="emp_type" [type]='"employment_Type"'
                      [selectError]='"Employment Type"'></app-master-select>
                    <div *ngIf="(submitted || f.emp_type.touched) && f.emp_type.errors" class="invalid-feedback">
                      <div *ngIf="f.emp_type.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Employment status </label>
                    <app-master-select
                      [ngClass]="{ 'is-invalid': ((submitted || f.emp_status.touched ) && f.emp_status.errors)  }"
                      [isrequired]='"true"' [placeholder]="empstakey" id="emp_status" [submitted]="submitted"
                      [selectedItem]="f.emp_status.value" formControlName="emp_status"
                      [type]='"profile_section_emp_status"' [selectError]='"Employment Status"'
                      (ngModelChange)="statusChange()"></app-master-select>
                    <div *ngIf="((submitted || f.emp_status.touched ) && f.emp_status.errors) "
                      class="invalid-feedback">
                      <div *ngIf="f.emp_status.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                      <div *ngIf="f.emp_status.errors?.pattern">Employee status cannot be {{f.emp_status.value}}.</div>
                    </div>
                  </div>
                  <div class="col-12 form-row">
                    <label for="name" class="form-label ">Date of confirmation </label>
                    <div class="form-icon icon-right"
                      [ngClass]="{ 'is-invalid': submitted && f.do_confirmation.errors }">
                      <input (click)="datePicker3.open()" class="form-control"
                        placeholder="{{this.messageService.selectplaceholddisplay('Date of confirmation')}}" matInput
                        formControlName="do_confirmation" [min]="f.do_join.value" [matDatepicker]="datePicker3"><i
                        class="icon-calendar pointer" (click)="datePicker3.open()"></i>
                      <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
                    </div>
                    <div *ngIf="submitted && f.do_confirmation.errors" class="invalid-feedback">
                      <div *ngIf="f.do_confirmation.errors">{{this.messageService.validationDisplay('pattern')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="text-uppercase fw-500 mb-0">Position details</p>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label ">Reporting manager </label>
                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Reporting manager')}}" [ngClass]="{ 'is-invalid': f.reporting_manager.touched && f.reporting_manager.errors?.relievedError}" (change)="assignedToChangeFunction($event,'reporting_manager')"
                      formControlName="reporting_manager" [items]="empDD" class="form-ngselect" bindValue="id"
                      bindLabel="fullname">
                      <ng-template ng-label-tmp let-item="item">
                        <span class="ng-value-label">
                          <div class="d-inline-flex align-items-center">
                            <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null"
                              class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1">
                              <span>{{this.appservice.getFirstChar(item.fullname,2)}}</span>
                            </div>
                            <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"
                              *ngIf="item.profile_image !=  '' && item.profile_image !=  null"
                              [src]="item.profile_image">
                            {{item.fullname}}
                          </div>
                        </span> </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex  align-items-center">
                          <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                            *ngIf="item.profile_image !=  '' && item.profile_image != null"
                            src="{{item.profile_image}}">
                          <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                            class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                            <span>
                              {{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                          </span>
                          {{item.fullname}}
                        </div>
                      </ng-template>
                    </ng-select>
                    <div *ngIf="f.reporting_manager.touched && f.reporting_manager.errors?.relievedError" class="invalid-feedback">
                      <div *ngIf="f.reporting_manager.errors?.relievedError">Employee is already {{empReportStatus}}.</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label ">Functional manager </label>
                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Functional manager')}}" [ngClass]="{ 'is-invalid': f.functional_manager.touched && f.functional_manager.errors?.relievedError}" (change)="assignedToChangeFunction($event,'functional_manager')"
                      formControlName="functional_manager" [items]="empDD" class="form-ngselect" bindValue="id"
                      bindLabel="fullname">
                      <ng-template ng-label-tmp let-item="item">
                        <span class="ng-value-label">
                          <div class="d-inline-flex align-items-center">
                            <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null"
                              class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1">
                              <span>{{this.appservice.getFirstChar(item.fullname,2)}}</span>
                            </div>
                            <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"
                              *ngIf="item.profile_image !=  '' && item.profile_image !=  null"
                              [src]="item.profile_image">
                            {{item.fullname}}
                          </div>
                        </span> </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex  align-items-center">
                          <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                            *ngIf="item.profile_image !=  '' && item.profile_image != null"
                            src="{{item.profile_image}}">
                          <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                            class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                            <span>
                              {{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                          </span>
                          {{item.fullname}}
                        </div>
                      </ng-template>
                    </ng-select>
                    <div *ngIf="f.functional_manager.touched && f.functional_manager.errors?.relievedError" class="invalid-feedback">
                      <div *ngIf="f.functional_manager.errors?.relievedError">Employee is already {{empFunctionStatus}}.</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Company </label>
                    <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Company')}}"
                      formControlName="company" [items]="companyDD"
                      [ngClass]="{ 'is-invalid': (submitted || f.company.touched) && f.company.errors }"
                      class="form-ngselect" (change)="getBUListFunction()" bindValue="id" bindLabel="company_name">
                      <ng-template ng-label-tmp let-item="item" let-index="index">
                        <div class="d-inline-flex align-items-center">
                          <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                            *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                          <span *ngIf="item.logo ==  ''|| item.logo == null"
                            class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                            <span>
                              {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                          </span>
                          {{item.company_name}}
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex align-items-center">
                          <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                            *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                          <span *ngIf="item.logo ==  ''|| item.logo == null"
                            class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                            <span>
                              {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                          </span>
                          {{item.company_name}}
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-inline-flex align-items-center">
                          <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                            *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                          <span *ngIf="item.logo ==  ''|| item.logo == null"
                            class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                            <span>
                              {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                          </span>
                          {{item.company_name}}
                        </div>
                      </ng-template>
                    </ng-select>
                    <div *ngIf="(submitted || f.company.touched) && f.company.errors" class="invalid-feedback">
                      <div *ngIf="f.company.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required"> Business unit </label>
                    <ng-select [readonly]="(f.company.value) ? false : true"
                      [ngClass]="{ 'is-invalid': (submitted || f.business_unit.touched) && f.business_unit.errors }"
                      class="form-ngselect" formControlName="business_unit"
                      placeholder="{{this.messageService.selectplaceholddisplay('Business unit')}}">
                      <ng-option *ngFor="let item of buDD" [value]="item.id">{{ item.name }}</ng-option>
                    </ng-select>
                    <div *ngIf="(submitted || f.business_unit.touched) && f.business_unit.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.business_unit.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row  ">
                    <label for="name" class="form-label required">Department </label>
                    <ng-select dropdownPosition="top"
                      [ngClass]="{ 'is-invalid': (submitted || f.department.touched) && f.department.errors }"
                      class="form-ngselect" formControlName="department"
                      placeholder="{{this.messageService.selectplaceholddisplay('Department')}} ">
                      <ng-option *ngFor="let item of DepDD" [value]="item.id">{{ item.name }}</ng-option>
                    </ng-select>
                    <div *ngIf="(submitted || f.department.touched) && f.department.errors" class="invalid-feedback">
                      <div *ngIf="f.department.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row ">
                    <label for="name" class="form-label required"> Designation </label>
                    <ng-select dropdownPosition="top"
                      [ngClass]="{ 'is-invalid': (submitted || f.designation.touched) && f.designation.errors }"
                      class="form-ngselect" formControlName="designation"
                      placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}">
                      <ng-option *ngFor="let item of DesignationDD" [value]="item.id">{{ item.name }}</ng-option>
                    </ng-select>
                    <div *ngIf="(submitted || f.designation.touched) && f.designation.errors" class="invalid-feedback">
                      <div *ngIf="f.designation.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Branch </label>
                    <ng-select dropdownPosition="top"
                      [ngClass]="{ 'is-invalid': (submitted || f.branch.touched) && f.branch.errors }"
                      class="form-ngselect" formControlName="branch"
                      placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}">
                      <ng-option *ngFor="let item of BranchDD" [value]="item.id">{{ item.name }}</ng-option>
                    </ng-select>
                    <div *ngIf="(submitted || f.branch.touched) && f.branch.errors" class="invalid-feedback">
                      <div *ngIf="f.branch.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 form-row">
                    <label for="name" class="form-label required">Grade </label>
                    <ng-select dropdownPosition="top"
                      [ngClass]="{ 'is-invalid': (submitted || f.grade.touched) && f.grade.errors }"
                      class="form-ngselect" formControlName="grade"
                      placeholder="{{this.messageService.selectplaceholddisplay('Grade')}} ">
                      <ng-option *ngFor="let item of gradeDD" [value]="item.id">{{ item.name }}</ng-option>
                    </ng-select>
                    <div *ngIf="(submitted || f.grade.touched) && f.grade.errors" class="invalid-feedback">
                      <div *ngIf="f.grade.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                    </div>
                  </div>
                  <div class="col-12 form-row">
                    <label for="name" class="form-label required"> Skill type </label>
                    <app-master-select [dropdownPosition]='"top"' [appendTo]='"ng-select"'
                      [ngClass]="{ 'is-invalid': (submitted || f.skill_type.touched) && f.skill_type.errors }"
                      [isrequired]='"true"' [placeholder]="skillkey" id="emp_status" [submitted]="submitted"
                      [selectedItem]="f.skill_type.value" formControlName="skill_type" [type]='"skill_type"'
                      [selectError]='"Skill Type"'></app-master-select>
                    <div *ngIf="(submitted || f.skill_type.touched) && f.skill_type.errors" class="invalid-feedback">
                      <div *ngIf="f.skill_type.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer gap-32 pt-16">
      <label for="send-password" class="form-check p-0 d-flex ">
        <input type="checkbox" name="check" id="sendPassword" class="form-check-input ms-0"
          (change)="CheckedOrNot($event)">
        <label for="sendPassword" class="form-check-label fs-16">Send Password</label>
      </label>
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="submitEmployee()"
        type="button">Save</button>
    </footer>
  </form>
</div>
