import { Component, OnInit,Input } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { Router } from '@angular/router';
import {  AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout'
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-attendance-reports',
  templateUrl: './attendance-reports.component.html',
  styleUrls: ['./attendance-reports.component.scss']
})
export class AttendanceReportsComponent implements OnInit {

  attendanceReports:any[]              = [];
  loader                     = false;
  nodata                     = false;
  searchlistdata:any         = [];
  tasksearch:string          = this.messageService.searchdisplay('Reports') ;
  permissions:any            = [];
  schedulePermissions1:any   = [];

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength                 = 0;
  direction                  = '';
  defaultlimit:any           = 20;
  reoprtDetailsList:any      = [];
  infinityloader             = false;
  searchKeyword="";
  sLoading = false;

  constructor(public messageService : MessageService,public attendanceReportService:AttendanceReportsService, public router: Router, public breakpointObserver: BreakpointObserver, public appservice:AppService) { }

  ngOnInit(): void {
    this.getPermission();
    this.getScheduleReportPermission1();
    this.attendanceReportList();
  }

  getPermission(){
    this.permissions = this.appservice.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // function for list all attendance reports
  attendanceReportList(){
    this.loader = true;
    this.attendanceReportService.attendanceReportTypeList('Attendance',this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any) => {
      this.listlength        = res.results.length;
      if(this.offset ==0){
        this.attendanceReports = [];
      }
      if (res.results.length != 0) {
        this.reoprtDetailsList = res.results;
      }
      this.infinityloader    = false;
      this.addItems(this.listlength);
      if (res.results.length == 0) {
        this.nodata = this.offset == 0 ? true:false;
      }else{
        this.nodata = false;
      }
      this.loader            = false;
      this.sLoading          = false;
    });
    // this.attendanceReportService.attendanceReportDropdown().subscribe((res: any) => {
    //   this.searchlistdata = res;
    // });

  }

  addItems(item:any){
    for (let i = 0; i < item; i++) {
      this.attendanceReports.push(this.reoprtDetailsList[i]);
    }
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.attendanceReportList();
      this.loader       = false;
      this.direction    = "down";
    }else{
        this.infinityloader = false;
    }
  }

  // function for view table view of single report
  reportTableView(reportname:any, reportid:any){
    if(this.permissions.v){
      if(reportid != undefined && reportid != null){
        reportname = reportname.replace(/\s/g, "");
        this.router.navigate(['/attendance-report/attendance-report-configure/'+reportname+'/'+reportid]);
      }
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.attendanceReports = [];
      // this.reoprtDetailsList = [];
      this.attendanceReportList();
     }
    else{
      this.attendanceReports = [];
      this.attendanceReportService.getSingleAttendanceReport(data).subscribe((res: any) => {
        this.attendanceReports = res;
        this.loader = false;
      });
    }
  }

  searchAttendance(searchKeyword:any){
    this.sLoading = true;
    this.loader = true;
    this.offset           = 0;
    this.attendanceReports= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.attendanceReportList();
    }
  }

  // Scheduled Report
  getScheduleReportPermission1(){
    this.schedulePermissions1 = this.appservice.getPermissions('/scheduled-reports');
    if(this.schedulePermissions1 == undefined){
      setTimeout(() => {
        this.getScheduleReportPermission1();
      }, 3000);
    }
  }
}
