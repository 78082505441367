import { Component, OnInit, ViewChild } from '@angular/core';
import { SeparationPolicyService } from 'src/app/@core/services/separation-policy.service';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-seperation-policy',
  templateUrl: './seperation-policy.component.html',
  styleUrls: ['./seperation-policy.component.scss']
})
export class SeperationPolicyComponent implements OnInit {
detailsView                = false;
filterpanel                = true;
SeparationPolicyData :any  = [];
status                     = true;
offset                     = 0;
listlength                 = 0;
defaultlimit:any           = 20;
infinityloader             = false;
loader                     = true;
nodata                     = false;
deleteAlert                = false;
deleteClicked              = false;
viewLoader                 = false;
deleteId                   = 0;
searchKeyword              = "";
componentDetailsList:any   = [];
searchlistdata:any         = [] ;
viewpolicydata:any         = [];
viewpolicydatarights:any   = [];
separationPlaceholder:string  = this.messageService.searchdisplay('Separation Policy');
statusBtn:any = 'Active';
direction                  = '';
appkey1:any             = [];
assignees:any           = [];
@ViewChild(SearchbarComponent) child:any;
permissions:any = [];
isLoading = false
searchData : Subject<void> = new Subject();

constructor(
    public separationpolicyservice:SeparationPolicyService,
    public messageService : MessageService,
    public router : Router,
    public breakpointObserver: BreakpointObserver,
    public appService:AppService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    setTimeout(()=>{
      this.loader = false;
    }, 3000);
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.getSeparationpolicyData(true);
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.separationpolicyservice.getSeparationPolicyData(this.status,this.defaultlimit,this.offset,this.searchKeyword,'')
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/separation/separation-policy');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getSeparationpolicyData(active_status:any){
    this.loader           = true;
    this.status           = active_status;
    this.separationpolicyservice.getSeparationPolicyData(active_status,this.defaultlimit,this.offset,this.searchKeyword,'').subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength       = res.results.length;
    if(this.offset ==0){
      this.SeparationPolicyData = [];
    }
    if (res.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata               = false;
      this.componentDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.SeparationPolicyData.push(this.componentDetailsList[i]);
    }
  }

  viewdatapolicy(id:any){
    if(this.permissions.v){
      this.detailsView =true;
      this.viewLoader    = true;
      this.assignees = [];
      this.separationpolicyservice.getviewSeparationPolicyData(id).subscribe((res: any) => {
        this.viewpolicydata = res;
        this.viewpolicydatarights= this.viewpolicydata.applicablity[0];
      for(let key of res.applicablity){
        let appkey = key.applicable.toLowerCase();
        if(appkey == "bu"){
          appkey = "businessunit";
          key.applicable ="BU"
        }
        this.appkey1 = appkey.toString();
        if(appkey=="employee"){
          let empName = key[this.appkey1]['first_name']+(key[this.appkey1]['middle_name']!=null && key[this.appkey1]['middle_name']!=""?" " +key[this.appkey1]['middle_name']:"")+" "+key[this.appkey1]['last_name'] +" ("+key[this.appkey1]['employee_code']+")";
          let empImg  = key[this.appkey1]['profile_image'];
          let color_code  = key[this.appkey1]['color_code'];
          this.assignees.push({'name': empName,'img': empImg, 'color_code':color_code})
        }else if(appkey=="company"){
          let compName = key.company;
          let compImg  = key.company_logo;
          this.assignees.push({'name': compName,'img': compImg})
        }else{
        this.assignees.push(key[this.appkey1]);
        }
      }
        this.viewLoader    = false;
      });
    }
  }

  searchresults(data:any){
    this.loader           = true;
    this.SeparationPolicyData = [];
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.searchData.next();
    }else{
      this.searchData.next();
    }
  }

  searchpolicy(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.SeparationPolicyData = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next();
    }
  }

  statusfilter(active_status:any){
    this.status           = active_status;
    this.SeparationPolicyData = [];
    this.offset            = 0;
    this.child.clearBar();
  }

  deletePoilcy(id:number){
    this.deleteClicked = true
    let data = { "is_active": false};
    this.separationpolicyservice.deleteSeparationpolicy(id,data).subscribe((res: any) => {
      this.deleteClicked = false
      this.deleteAlert      = false;
      this.loader           = true;
      this.offset           = 0;
      this.SeparationPolicyData = [];
      this.getSeparationpolicyData(this.status);

    })
  }

  onScrollDown() {
    this.infinityloader     = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
     this.getSeparationpolicyData(this.status);
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  editseparationpolicy(id:number){
    this.router.navigate(['/separation/separation-policy-setup/'+id]);
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/separation/separation-policy-setup']);
  }
}
