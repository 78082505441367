<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0"> {{heading}} Role</h5>
    <button class="btn-reset text-light-600" routerLink="/role"><i class="icon-close-lg"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader || !isEmpDropdown"></app-fw-edit-loader>
  <div *ngIf="!loader && isEmpDropdown">
    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step"
            [class]="{'success':myForm['controls'].define.valid || myForm['controls'].define.disabled }">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Define Role</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-role-define [formGp]="defineForm" [editflag]="editflag" [assignformGp]="assignForm"
              (submitmethod)="submitOne($event)" [disabled]="disabled" [employeeDropdown]="employeeDropdown"
              (companyChange1)="companyChange1($event)"></app-role-define>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="isDefault==false">
          <a ngbNavLink class="step"
            [class]="{'success':(checkValid() && myForm.value.assign.list.length) || myForm['controls'].assign.disabled}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Assign Rights </h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-role-assign-rights [defineForm]="defineForm" [formGp]="assignForm" [disabled]="disabled"
              [selectedEmp]="selectedPeople" [companyDD]="companyDD" [empValid]="empValid" [buDD]="buDD" [DepDD]="DepDD"
              [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [cityDD]="cityDD" [gradeDD]="gradeDD"
              [buArray]="buArray" (submitmethod)="submitForm()" [saveBtn]="saveBtn"
              (companyChange)="companyChange($event)"></app-role-assign-rights>
          </ng-template>
        </li>
      </ul>
      <div class="mt-auto" *ngIf="activeTab == 2">
        <div class="card card-details">
          <p class="mb-0">Rights assigned to <span class="highlight">{{countAssign()}}</span> out of <span
              class="highlight">{{myForm.value.define.employee.length}}</span> Employees</p>
        </div>
      </div>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
      <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
    </main>
  </div>
</div>