<form action="" class=""  [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row mb-16">
      <div class="col-md-4 col-lg-6">
        <p class="text-uppercase fw-500 mb-0">Select Companies</p>
      </div>
      <div class="col-md-8 col-lg-6" *ngIf="!editflag">
        <div class="d-flex justify-content-end  gap-16">
          <div class="form-icon" style="width:22.6875rem;">
            <div class="form-icon " style="width:22.6875rem;">
              <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='companysearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchcompany($event)"></app-searchbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-16">
      <div class="col-12">
        <p class="fs-14 mb-0">Companies selected : {{this.formGp.value.company.length}}</p>
      </div>
      <ng-container *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index" ></app-common-loader>
      </ng-container>
      <ng-container *ngIf="loader==false">
      <div class="col-lg-4 col-sm-6 " *ngFor="let companydata of companydata;let i=index">
        <label class="card card-custom1 card-hover  {{editflag?'label-disabled':''}}" for="{{companydata?.company_name}}">
          <div class="card-body p-16 d-flex align-items-center">
            <input class="form-check-input" name="check" type="checkbox" [checked]="this.formGp.value.company.indexOf(companydata['id']+'') != -1?true:false" id="{{companydata?.id}}" (change)="onCheckChange($event,i)" [value]="companydata?.id">
            
            <!-- <div class="{{companydata?.color_code}} sq-48 avatar-box">{{this.appService.getFirstChar(companydata?.company_name,2)}}</div> -->
            <img src="{{companydata?.logo}}" class="logo-img img-fluid sq-48">
            <div class="overflow-hidden ms-8">
              <div class="fs-14 fw-500 text-trim">{{companydata?.company_name}}</div>
               <span class="fs-10 text-uppercase fw-500 text-success">Active</span>
              </div>
          </div>
        </label>
      </div>
      </ng-container>
    </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid)" (click)="validatepolicysetting()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validatepolicysetting()">NEXT</button>
</footer>
</form>