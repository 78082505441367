<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Alerts - {{month}} {{year}}</h5>
        <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-c1-loader *ngIf="loader"></app-c1-loader>
    <main  *ngIf="!loader" class=" config-container">

  <form class="">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16 mb-16">
          <div class="col-12 ">
            <span class="fw-500 text-uppercase">ALERTs - {{month}} {{year}}</span>
          </div>
          <ng-container *ngIf="!alertClose">

            <div class="col-12" >
              <div class="alert alert-danger show" role="alert" >
                Salaries can't be processed for employees with alert data
                <button class="btn-close" (click)="alertClose=true"></button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row row-24 row-cols-xl-5">
          <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let item of reminders;let ind = index" >
            <div style="min-height: 7.5rem;" class="card card-c2 card-hover card-highlight {{item.class}}"  (click)="showSidePanel = true;selectedList=item.list;selectedName=item.reminder">
              <div class="card-body p-16 text-center">
                <h6 class="fw-500  fs-20">{{item.list.length}}</h6>
                <hr class="hr-1 mb-16 mt-16">
                <div class="mb-0 fw-500 fs-12">{{item.reminder}}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-3 col-md-4 col-sm-6" (click)="showSidePanel = true">
            <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight1">
              <div class="card-body p-16 text-center">
                <h6 class="fw-500  fs-20">5</h6>
                <hr class="hr-1 mb-16 mt-16">
                <div class="mb-0 fw-500 fs-12">Attendance not processed
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight2">
              <div class="card-body p-16 text-center">
                <h6 class="fw-500  fs-20">23</h6>
                <hr class="hr-1 mb-16 mt-16">
                <div class="mb-0 fw-500 fs-12">Statutory details missing
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight-primary">
              <div class="card-body p-16 text-center">
                <h6 class="fw-500  fs-20">12</h6>
                <hr class="hr-1 mb-16 mt-16">
                <div class="mb-0 fw-500 fs-12">Salary structure missing
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight3">
              <div class="card-body p-16 text-center">
                <h6 class="fw-500  fs-20">5</h6>
                <hr class="hr-1 mb-16 mt-16">
                <div class="mb-0 fw-500 fs-12">Bank details missing
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div style="min-height: 7.5rem;" class="card card-c2 card-highlight highlight3">
              <div class="card-body p-16 text-center">
                <h6 class="fw-500  fs-20">5</h6>
                <hr class="hr-1 mb-16 mt-16">
                <div class="mb-0 fw-500 fs-12">Pan card details missing
                </div>
              </div>
            </div>
          </div> -->
        </div>

    </div>
  </form>
  </main>
  </div>
  <!--Slider -->
  <ng-container *ngIf="showSidePanel">
    <app-non-mapped-empp [showSidePanel]="showSidePanel" [selectedList]="selectedList" [selectedName]="selectedName" (closePanel)="closePanel($event)"></app-non-mapped-empp>
  </ng-container>
