  <!-- <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom sticky top-48">
      <li [ngbNavItem]="1">
          <a ngbNavLink class="nav-link">Manage Documents</a>
          <ng-template ngbNavContent>
              <app-manage-documents></app-manage-documents>
          </ng-template>
      </li>
      <li [ngbNavItem]="2">
          <a ngbNavLink class="nav-link">Category</a>
          <ng-template ngbNavContent>
              <app-documents-category></app-documents-category>
          </ng-template>
      </li>
  </nav>
  <div [ngbNavOutlet]="nav"></div> -->

<app-menu-tab *ngIf="menuData" [menuList]="menuData"></app-menu-tab>
<router-outlet ></router-outlet>
