<div class="table-responsive card card-c2 scrollbar-10" [formGroup]="formGp">
  <table class="table table-striped sticky-header table-sm form-cell-sm">
    <thead>
      <tr>
        <th class="fw-600" scope="col">WEEK DAYS</th>
        <th class="fw-600" scope="col">1ST</th>
        <th class="fw-600" scope="col">2ND</th>
        <th class="fw-600" scope="col">3RD</th>
        <th class="fw-600" scope="col">4TH</th>
        <th class="fw-600" scope="col">5TH</th>
      </tr>
    </thead>

    <tbody class="">
      <tr formArrayName="weekoffdata">
        <td class="fw-600">Monday</td>
        <td class="form-cell" *ngFor="let item of weekoff_mapping.controls; let i=index;">
          <ng-container [formGroupName]="i">
            <ng-select formControlName="monday" [items]="weekoffs" bindLabel="name" bindValue="value" appendTo="body"
              class="form-ngselect" placeholder="Select Week off"></ng-select>
          </ng-container>
        </td>
      </tr>
      <tr formArrayName="weekoffdata">
        <td class="fw-600">Tuesday</td>
        <td class="form-cell" *ngFor="let item of weekoff_mapping.controls; let i=index;">
          <ng-container [formGroupName]="i">
            <ng-select formControlName="tuesday" [items]="weekoffs" bindLabel="name" bindValue="value" appendTo="body"
              class="form-ngselect" placeholder="Select Week off"></ng-select>
          </ng-container>
        </td>
      </tr>
      <tr formArrayName="weekoffdata">
        <td class="fw-600">Wednesday</td>
        <td class="form-cell" *ngFor="let item of weekoff_mapping.controls; let i=index;">
          <ng-container [formGroupName]="i">
            <ng-select formControlName="wednesday" [items]="weekoffs" bindLabel="name" bindValue="value" appendTo="body"
              class="form-ngselect" placeholder="Select Week off"></ng-select>
          </ng-container>
        </td>
      </tr>
      <tr formArrayName="weekoffdata">
        <td class="fw-600">Thursday</td>
        <td class="form-cell" *ngFor="let item of weekoff_mapping.controls; let i=index;">
          <ng-container [formGroupName]="i">
            <ng-select formControlName="thursday" [items]="weekoffs" bindLabel="name" bindValue="value" appendTo="body"
              class="form-ngselect" placeholder="Select Week off"></ng-select>
          </ng-container>
        </td>
      </tr>
      <tr formArrayName="weekoffdata">
        <td class="fw-600">Friday</td>
        <td class="form-cell" *ngFor="let item of weekoff_mapping.controls; let i=index;">
          <ng-container [formGroupName]="i">
            <ng-select formControlName="friday" [items]="weekoffs" bindLabel="name" bindValue="value" appendTo="body"
              class="form-ngselect" placeholder="Select Week off"></ng-select>
          </ng-container>
        </td>
      </tr>
      <tr formArrayName="weekoffdata">
        <td class="fw-600">Saturday</td>
        <td class="form-cell" *ngFor="let item of weekoff_mapping.controls; let i=index;">
          <ng-container [formGroupName]="i">
            <ng-select formControlName="saturday" [items]="weekoffs" bindLabel="name" bindValue="value" appendTo="body"
              class="form-ngselect" placeholder="Select Week off"></ng-select>
          </ng-container>
        </td>
      </tr>

      <tr formArrayName="weekoffdata">
        <td class="fw-600">Sunday</td>
        <td class="form-cell" *ngFor="let item of weekoff_mapping.controls; let i=index;">
          <ng-container [formGroupName]="i">
            <ng-select formControlName="sunday" [items]="weekoffs" bindLabel="name" bindValue="value" appendTo="body"
              class="form-ngselect" placeholder="Select Week off"></ng-select>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>