import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.global';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class MyTeamDashboardService {
  emp_at_work: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpClient,
    public router: Router,
    private websocket: WebSocket,
    public appservice:AppService,
    private authService: AuthService,
  ) { }

  API_PATH: string = environment.apiPath;

  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  // Websocket
  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public myTeamempAtWorkSocket(){

    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}team-dashboard-employees-at-work/${this.appservice.getSubDomain()}_employees_at_work_${this.authService.isUserId}/`);

    this.websocket.onopen = (event:any) => {
    }

    this.websocket.onmessage = (event:any) => {
      let data = JSON.parse(event.data);
      this.emp_at_work.emit(data);
    }

    this.websocket.onclose = (event:any) => {
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          // Hide loading indicator
          if(event.url !== '/my-team-dashboard'){
            this.closeSocket();
          }
      }
    });

    if (this.websocket.readyState === WebSocket.OPEN) {
    }
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
      // this.websocket.onclose = (event:any) => {
      // }
      this.websocket.addEventListener('close', (event) => {
      });
    }
  }
  // End

  // test start
  getEmployeeAtWork(date:any) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-dashboard-employees-at-work/?checkdate=${date}`, this.httpOption)
  }

  getTeamUpdates() {
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-dashboard-team-updates/`, this.httpOption)
  }

  getMyTeam() {
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-dashboard-my-team/`, this.httpOption)
  }

  getLeaderboard(statusdata:any,month:any, year:any) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-dashboard-leaderboard/?statusdata=${statusdata}&month=${month}&year=${year}`, this.httpOption)
  }

  getPunchSource(month:any, year:any) {
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-dashboard-punchsource/?month=${month}&year=${year}`, this.httpOption)
  }

  getWorkforceStates(month:any, year:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-dashboard-workforce-stats/?month=${month}&year=${year}`, this.httpOption)
  }

  getTimeOff(days:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/team-dashboard-timeoff/?days=${days}`, this.httpOption)
  }
  // test end

}
