import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-non-mapped-empp',
  templateUrl: './non-mapped-empp.component.html',
  styleUrls: ['./non-mapped-empp.component.scss']
})
export class NonMappedEmppComponent implements OnInit {

  @Input() selectedName  : any
  @Input() selectedList  : any
  @Input() showSidePanel : any
  @Input() searchNeeded  : any
  @Input() from          : any ='';

  searchHolder         = this.messageServ.searchdisplay('employee')
  searchString         = ''

  @Output() closePanel   = new EventEmitter()

  constructor(
    public appService : AppService,
    public messageServ : MessageService
  ) { }

  ngOnInit(): void {
    // console.log("searchNeeded",this.searchNeeded)
  }

  closeThePanel(){
    this.closePanel.emit(false)
  }
}
