import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChallanGenerationPfService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  // test start

  challanGenerationLandingPage(month:any, year:any) {
    return this.http.get(`${this.API_PATH}payroll/api/v1/pf-challans/?month=${month}&year=${year}`, this.httpOption)
  }

  generatePfChallan(body: any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/pf-challan-generate/`, body, this.httpOption)
  }

  generatedPfChallan(month:any, year:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/pf-challan-generated-list/?month=${month}&year=${year}`, this.httpOption)
  }

  regeneratPfChallanView(id:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/pf-challan-regenerate-view-detail/${id}/`, this.httpOption)
  }

  regeneratePfChallan(body: any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/pf-challan-regenerate/`, body, this.httpOption)
  }

  textDownload(id:number){
    return this.http.get(`${this.API_PATH}payroll/api/v1/pf-generate-report-text/${id}/`, this.httpOption)
  }
  // test end
}
