import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: Object[], args: string): any {
    if (!items || !items.length || args.trim() === ',' || /(?:,,|,[$&+,:;=?@#|'<>.^*()%!-\[\]])/.test(args)) { return []; }

    if (!args) { return items; }

    const employeeCodesAndNames = args.split(',');
    // Check if input is empty or contains only commas
    if (employeeCodesAndNames.length === 0 || employeeCodesAndNames.every(arg => arg.trim() === '')) {
      return items
    }

    // Search only on the basis of Employee Code and Employee name
    return items
      .filter((item:Record<string, any>) =>  employeeCodesAndNames.some(arg =>
        employeeCodesAndNames.some(arg =>
          Object.entries(item)
            .filter(([key]) => key === 'employee_code' || key === 'employee_name')
            .some(([key, value]) => value?.toString().toLowerCase().includes(arg.toLowerCase()))
        )
      ))
  }

}
