import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,FormControl,FormArray } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ExpenseSettingService } from 'src/app/@core/services/expense-settings.service'; 
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-expense-settings-setup',
  templateUrl: './expense-settings-setup.component.html',
  styleUrls: ['./expense-settings-setup.component.scss']
})
export class ExpenseSettingsSetupComponent implements OnInit {
  activeTab         = 1;
  disabled          = false;
  res               : any;
  title             ='Add';
  id: number        = 0;
  iseditForm        = false;
  editflag: boolean = false;
  loader            = false;
  companylist       : any=[];
  companydatain      : any=[];
  dataArray:any      =[] ;

  myForm: FormGroup = this.fb.group({
    id: 0,    
    companymap: this.fb.group({
      company: this.fb.array([],[Validators.required]),
      
    }),
    details: this.fb.group({
      is_expiry_limit: [false, [Validators.required]],
      expiry_period: [null],
      is_report_submission: [false, [Validators.required]],
      from_date: [null],
      to_date: [null],
      // backdated_request_days: [null, [Validators.required,NumberpatternValidator(),Validators.maxLength(3),Validators.min(1)]],
      based_on: [null],
    })

  });
  constructor(private fb:FormBuilder,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,
    public ExpenseSettingService:ExpenseSettingService,
    public notificationservice:NotificationService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }

  submit(tab:any){
    this.activeTab = tab;
  }
 
  get companymapForm() {
    return this.myForm.get('companymap') as FormGroup;
  }
  get detailsForm() {
    return this.myForm.get('details') as FormGroup;
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].companymap.invalid) {
        changeEvent.preventDefault();
      }
    }
  }

  tabclick(tab:any){
    if(tab=2){
      if(this.myForm['controls'].companymap.valid){
        this.activeTab=2;
      }
    }    
  }

  editForm(){
    this.loader = true;
    this.editflag = true;
    // this.tabclick    =true;
    this.title ='Edit';
    this.ExpenseSettingService.getSinglesettingData(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res);
    },(error)=>{
      this.router.navigate(['expense-settings']);
    });
  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }
   
   let companyarr:any=[];
    const formArray: FormArray = this.companymapForm.get('company') as FormArray;
    formArray.push(new FormControl(res.company.id.toString()));
    companyarr.push(res.company.id.toString());

    this.companydatain=res.company;

    this.myForm.reset(
      {
        id: res.id,       
        companymap: {
          company:  companyarr,
        },
        details: {
          is_expiry_limit: res.is_expiry_limit,
          expiry_period: res.expiry_period,
          is_report_submission: res.is_report_submission,
          from_date: res.from_date,
          to_date: res.to_date,
          // backdated_request_days: res.backdated_request_days,
          based_on: res.based_on
        }
    });

    this.loader = false;
  }

  submitForm(){
    if(this.editflag){
      this.dataArray = {
        "is_expiry_limit"     : this.detailsForm.controls.is_expiry_limit.value,
        "expiry_period"       : this.detailsForm.controls.expiry_period.value,
        "is_report_submission": this.detailsForm.controls.is_report_submission.value,
        "from_date"           : this.detailsForm.controls.from_date.value,
        "to_date"             : this.detailsForm.controls.to_date.value,
        // "backdated_request_days": this.detailsForm.controls.backdated_request_days.value,
        "based_on"            : this.detailsForm.controls.based_on.value,
        "company"             : this.companymapForm.controls.company.value[0],      
      };
    }else{
      let companyarr:any=[];
      for(let i=0;i<this.companymapForm.controls.company.value.length;i++){
        companyarr.push(parseInt(this.companymapForm.controls.company.value[i]))
      }
      this.dataArray = {
        "is_expiry_limit"     : this.detailsForm.controls.is_expiry_limit.value,
        "expiry_period"       : this.detailsForm.controls.expiry_period.value,
        "is_report_submission": this.detailsForm.controls.is_report_submission.value,
        "from_date"           : this.detailsForm.controls.from_date.value,
        "to_date"             : this.detailsForm.controls.to_date.value,
        // "backdated_request_days": this.detailsForm.controls.backdated_request_days.value,
        "based_on"            : this.detailsForm.controls.based_on.value,
        "company"             : companyarr,      
      };
  
    }   
    
    if(this.id == 0){
      this.ExpenseSettingService.createsetting(this.dataArray).subscribe((res: any) => {
        this.router.navigate(['expense-settings']);
      },
      (error) => {
      });
    } else {
      this.ExpenseSettingService.updatesetting(this.id,this.dataArray).subscribe((res: any) => {
        this.router.navigate(['expense-settings']);
      },
      (error) => {
      });
    }
  }
}
