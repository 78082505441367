import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LeaveYearEndService {
  FILTERDATA:any;
  INNERDATA :any
  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(  !event.url.includes('leave-year-end')){
          this.setFilterData(null)
          this.setInnerData(null)
        }
      }
    })
  }

  // Get List
  getLandingList(limit:any,offset:any,search:any,year:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-list/?limit=${limit}&offset=${offset}&search=${search}&year=${year}`, this.httpOption)
  }

  getAllData(limit:any,offset:any,search:any,year:any,id:any){
    if(id == 0){
      return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-list/?limit=${limit}&offset=${offset}&search=${search}&year=${year}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-list/?limit=1&offset=0&search=${search}&year=${year}&id=${id}`, this.httpOption)
    }
  }

  // Get Individual Leave Policy
  getIndividualLeave(id:number,year:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-detail/?leave_mapping=[${id}]&year=${year}`, this.httpOption)
  }

  // Get Pending Leave
  getPendingLeave(id:number,leave_id:number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-pending-leave-requests/${id}/?leavetype=${leave_id}`, this.httpOption)
  }

  // get View and Download Data
  getViewAndDownloadData(id:number,year:number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-process-list/?leave_mapping=${id}&year=${year}`, this.httpOption)
  }

  // Bulk Year End
  createBulkYear(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/leave-year-end-process-create/`,body, this.httpOption)
  }

  // Send Reminder
  sendReminder(body:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/send-reminder/`,body, this.httpOption)
  }

  // Accept / Reject
  acceptRejectRequest(id:any,body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/leave-year-end-pending-leave-requests/${id}/`,body, this.httpOption)
  }

  // Banner Notification
  getBannerNotification(){
    return this.http.get(`${this.API_PATH}home/api/v1/leave-year-end-notify/`, this.httpOption)
  }

  // Get Filter Data
  getFilterData(id:any,year:any,data:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-process-list/?leave_mapping=${id}&year=${year}&${data}`, this.httpOption)
  }

  // Export Data
  exportData(policy_id:any,map_id:any,status:any,year:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-year-end-process-export/?leave_policy=[]&leave_mapping=${map_id}&status=${status}&year=${year}`, this.httpOption)
  }

  // Dropdown
  dropdownList(search:any,year:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-mapping-dropdown/?leave_policy__is_active=true&search=${search}&year=${year}&ordering=leave_policy__name`, this.httpOption)
  }

  // Edit -  View & Download
  editFieldViewDownload(body:any){
    return this.http.put(`${this.API_PATH}attendance/api/v1/leave-year-end-update-data/`,body, this.httpOption)
  }

  // Filter
  setFilterData(val:any){
    this.FILTERDATA = val
  }

  // Inner Filter
  setInnerData(val:any){
    this.INNERDATA = val
  }
}
