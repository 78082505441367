import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { DocumentManagementService } from 'src/app/@core/services/document-management.service';

@Component({
  selector: 'app-document-repository',
  templateUrl: './document-repository.component.html',
  styleUrls: ['./document-repository.component.scss']
})
export class DocumentRepositoryComponent implements OnInit {

  viewDetails                 = false;
  permissions:any             = [];
  offset                      = 0;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  nodatafound                 = false;
  infinityloader              = false;
  loader                      = false;
  viewLoader:any              = false;
  searchKeyword               = ""
  searchlistdata:any          = [] ;
  policyList:any              = [];
  policyDetailList:any        = [];
  singlePolicyData:any        = [];
  policyPlaceholder:any       = this.messageService.searchdisplay('category');
  sLoading = false
  policyId:any

  constructor(
    public appService: AppService,
    public messageService : MessageService,
    public docService:DocumentManagementService,
  ) { }

  ngOnInit(): void {

  this.getPermission();
  this.getEmployeePolicy();

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getEmployeePolicy(){
    this.loader = true;
    this.docService.employeePolicyList(this.defaultlimit,this.offset,this.searchKeyword,'').subscribe((res:any)=>{
      this.listlength = res['results'].length;
      if(this.offset ==0){
        this.policyList = [];
      }

      if (res['results'].length == 0) {
        this.nodatafound = this.offset == 0 ? true :false;
      }else {
        this.nodatafound      = false;
        this.policyDetailList = res['results'];
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.sLoading = false;
      this.loader = false;
    })
    // this.docService.employeePolicySearchList().subscribe((res: any) => {
    //   this.searchlistdata = res['results'];
    //   for(let i=0;i<this.searchlistdata.length;i++){
    //     this.searchlistdata[i]['name']        = this.searchlistdata[i]['category_name']
    //     this.searchlistdata[i]['color_code']  = this.searchlistdata[i]['category_color_code']
    //     this.searchlistdata[i]['id']          = this.searchlistdata[i]['category_id']
    //   }

    // })

  }
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.policyList.push(this.policyDetailList[i]);
    }
    for(let j=0;j<this.policyList.length;j++){
      let value = this.policyList[j]['category_color_code'].slice(0, -1)

    }

  }
  searchCategory(searchKeyword:any){
    this.loader             = true;
    this.sLoading           = true;
    this.offset             = 0;
    this.policyList         = [];
    this.searchKeyword      = searchKeyword;
    if(searchKeyword != ""){
      this.getEmployeePolicy();
    }
  }
  onScrollDown() {
    this.infinityloader     = true;
    this.offset            += this.defaultlimit;

    if(this.listlength){
      this.getEmployeePolicy();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.policyList = [];
      this.getEmployeePolicy();
    }else{
        this.docService.employeePolicyList(20,0,'',data).subscribe((res: any) => {

          this.searchKeyword  = res?.name
          this.policyList     = res['results'];
          this.loader         = false;
        });
    }
  }
  viewPolicy(id:any){
    this.viewDetails  = true
    this.viewLoader   = true;

    this.docService.getSingleEmpPolicy(id).subscribe((res:any)=>{

      this.singlePolicyData = res;
      this.viewLoader       = false;


    })
  }

  goToLink(url: string){
    let exactURL = url?.startsWith('http') ? url : 'https://' +url
    window.open(exactURL, "_blank");
  }

}
