<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewInventory === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>{{from == 'inbox' ? 'View' : 'Details'}} </h5>
      <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader"></app-view-loader>

        <div class="row row-16" *ngIf="!loader">
          <div class="col-12 d-flex gap-8">
            <p class="text-uppercase fw-500 mb-0">Inventory</p>
            <div *ngIf="hide != false" class="hstack align-items-center gap-8 ms-auto">
              <ng-container *ngIf="isEdit && viewData?.is_active">

                <a class="link-primary fs-14  text-nowrap" (click)="editInventory(viewData?.id)" ><i
                    class="icon-pencil-square me-1"></i>
                  Edit</a>
                <span class="text-light-400">|</span>
              </ng-container>
              <a class="link-primary fs-14  text-nowrap" (click)="historyView()">Inventory History</a>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">Inventory Details</p>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Asset name</div>
                  <div class="detail-desc">{{viewData?.name}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc">{{viewData?.status}}
                    <!-- <span>{{viewData?.status}}</span> -->
                  </div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Asset code</div>
                  <div class="detail-desc">{{viewData?.code}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Serial no</div>
                  <div class="detail-desc">{{viewData?.serial_number!=null && viewData?.serial_number!=''?viewData?.serial_number:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Category</div>
                  <div class="detail-desc">{{viewData?.asset_category_name}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Subcategory</div>
                  <div class="detail-desc">{{viewData?.asset_sub_category_name}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Description</div>
                  <div class="detail-desc">{{viewData?.description != null && viewData?.description != '' ? viewData?.description : '-'}} </div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Vendor</div>
                  <div class="detail-desc">{{viewData?.vendor!=null && viewData?.vendor!=''?viewData?.vendor:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">GST no</div>
                  <div class="detail-desc">{{viewData?.gst_number!=null && viewData?.gst_number!=''?viewData?.gst_number:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Invoice no</div>
                  <div class="detail-desc">{{viewData?.invoice_number!=null && viewData?.invoice_number!=''?viewData?.invoice_number:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Invoice date</div>
                  <div class="detail-desc">{{viewData?.invoice_date!=null && viewData?.invoice_date!=''?this.appService.dateFormatDisplay(viewData?.invoice_date):'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Payment type</div>
                  <div class="detail-desc">{{viewData?.payment_type!=null && viewData?.payment_type!=''?viewData?.payment_type:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Currency</div>
                  <div class="detail-desc">{{viewData?.currency!=null && viewData?.currency!=''?viewData?.currency:''}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Cost (per item)</div>
                  <div class="detail-desc">{{viewData?.cost_per_item!=null && viewData?.cost_per_item!=''?viewData?.cost_per_item:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Other cost</div>
                  <div class="detail-desc">{{viewData?.other_cost!=null && viewData?.other_cost!=''?viewData?.other_cost:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">GST amount</div>
                  <div class="detail-desc">{{viewData?.gst!=null && viewData?.gst!=''?viewData?.gst:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Manufacturing date</div>
                  <div class="detail-desc">{{viewData?.manufacturing_date!=null && viewData?.manufacturing_date!=''?this.appService.dateFormatDisplay(viewData?.manufacturing_date):'-'}}</div>
                </div>
                <ng-container *ngIf="viewData?.warranty_notification_enable == true">
                  <div class="col-12">
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i class="icon-check-square-fill text-light-500"></i>Enable warranty expiry notification
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">How many days before the warranty expiration date, the notification should be triggered to the task owner?</div>
                  <div class="detail-desc">{{viewData?.warranty_notification_days}}</div>
                  </div>
                </ng-container>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Warranty period (month)</div>
                  <div class="detail-desc">{{viewData?.warranty_period!=null && viewData?.warranty_period!=''?viewData?.warranty_period:'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Warranty expiry</div>
                  <div class="detail-desc">{{viewData?.warranty_expiry_date!=null && viewData?.warranty_expiry_date!=''?this.appService.dateFormatDisplay(viewData?.warranty_expiry_date):'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Last audit date</div>
                  <div class="detail-desc">{{viewData?.last_audit_date!=null && viewData?.last_audit_date!=''?this.appService.dateFormatDisplay(viewData?.last_audit_date):'-'}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">End of life</div>
                  <div class="detail-desc">{{viewData?.end_of_life_date!=null && viewData?.end_of_life_date!='' ?this.appService.dateFormatDisplay(viewData?.end_of_life_date):'-'}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Assigned on</div>
                  <div class="detail-desc">{{viewData?.assigned_on!=null && viewData?.assigned_on!=''?this.appService.dateFormatDisplay(viewData?.assigned_on):'-'}}</div>
                </div>
                <div  class="col-sm-6 col-12">
                  <div class="detail-title">Assigned by</div>


                  <div class="d-flex align-items-center" *ngIf="viewData?.assigned_by!=null && viewData?.assigned_by!='';else assigned_on">
                    <img
                      *ngIf="(viewData?.assigned_by!=null && (viewData?.assigned_by.profile_image !=  '' && viewData?.assigned_by.profile_image !=  null))"
                      class="user-img img-fluid rounded-circle sq-32" src="{{viewData?.assigned_by.profile_image}}">
                    <div
                      *ngIf="(viewData?.assigned_by!=null && (viewData?.assigned_by.profile_image ==  '' || viewData?.assigned_by.profile_image ==  null))"
                      class="avatar-circle fs-14 fw-500 {{viewData?.assigned_by.color_code}} sq-32 rounded-circle">
                      <span>{{this.appService.getFirstChar(viewData?.assigned_by.first_name+'
                        '+viewData?.assigned_by.last_name,2)}}</span>
                    </div>

                    <div class="detail-desc p-2">
                      {{viewData?.assigned_by!=null?(viewData?.assigned_by.first_name+"
                      "+(viewData?.assigned_by.middle_name!=null?viewData?.assigned_by.middle_name:'')+"
                      "+viewData?.assigned_by.last_name+" ("+viewData?.assigned_by.employee_code+")"):'-'}}
                    </div>
                  </div>
                  <ng-template #assigned_on>
                    <div class="detail-desc">-</div>
                  </ng-template>
                </div>
                <div  class="col-sm-6 col-12">
                  <div class="detail-title">Assigned to</div>


                  <div class="d-flex align-items-center" *ngIf="viewData?.assigned_to!=null && viewData?.assigned_to!='';else assigned_to">
                    <img
                      *ngIf="(viewData?.assigned_to!=null && (viewData?.assigned_to.profile_image !=  '' && viewData?.assigned_to.profile_image !=  null))"
                      class="user-img img-fluid rounded-circle sq-32" src="{{viewData?.assigned_to.profile_image}}">
                    <div
                      *ngIf="(viewData?.assigned_to!=null && (viewData?.assigned_to.profile_image ==  '' || viewData?.assigned_to.profile_image ==  null))"
                      class="avatar-circle fs-14 fw-500 {{viewData?.assigned_to.color_code}} sq-32 rounded-circle">
                      <span>{{this.appService.getFirstChar(viewData?.assigned_to.first_name+'
                        '+viewData?.assigned_to.last_name,2)}}</span>
                    </div>

                    <div class="detail-desc p-2">
                      {{viewData?.assigned_to!=null?(viewData?.assigned_to.first_name+"
                      "+(viewData?.assigned_to.middle_name!=null?viewData?.assigned_to.middle_name:'')+"
                      "+viewData?.assigned_to.last_name+" ("+viewData?.assigned_to.employee_code+")"):'-'}}
                    </div>
                  </div>
                  <ng-template #assigned_to>
                    <div class="detail-desc">-</div>
                  </ng-template>
                </div>

                <div class=" col-12">
                  <div class="detail-title">Attachment</div>

                  <div class="row row-8">
                    <div class="col-6" *ngFor="let item of viewData?.asset_attachments,let i = index">
                      <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                        <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                        <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                          <span class="tips text-uppercase fs- fw-700 text-trim">{{this.appService.getDocName(item)}}</span>
                          <span class="tips-helper ">{{this.appService.getDocName(item)}}</span>
                          <div class="d-flex gap-8">

                            <div class="link-primary"  (click)="viewDocument(item,i)">
                              <i class="bi bi-eye me-1"></i>View
                            </div>
                            <div class="link-primary" (click)="this.appService.downloadFile(item,this.appService.getDocName(item))">
                              <i class="bi bi-download me-1"></i>Download
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="detail-desc" *ngIf="viewData?.asset_attachments.length==0">-</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">MANAGED BY</span></div>
                <div class="col-12">
                  <div class="detail-title">Asset is managed by</div>
                  <div class="detail-desc">{{viewData.applicability?.applicable}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">{{viewData.applicability?.applicable}}(s) </div>
                  <div class="detail-desc">
                    <div class="selected-values mt-1">
                      <ng-container *ngIf="viewData.applicability?.applicable == 'Employee'">
                        <li class="ng-value" *ngFor="let rights of viewData.applicability[viewData.applicability?.applicable], let i=index;">
                          <div class="ng-value-label" title="{{rights.name}}">
                            <span class="d-inline-flex align-items-center">
                              <img class="img-fluid rounded-circle sq-24" src="{{rights.profile_image}}"
                                *ngIf="rights.profile_image !=  '' && rights.profile_image !=  null">
                              <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                *ngIf="rights.profile_image ==  null || rights.profile_image ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                              <span> {{rights.name}}</span>
                            </span>
                          </div>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="viewData.applicability?.applicable == 'Company'">
                        <li class="ng-value" *ngFor="let rights of viewData.applicability[viewData.applicability?.applicable], let i=index;">
                          <div class="ng-value-label" title="{{rights.name}}">
                            <span class="d-inline-flex align-items-center">
                              <img class="img-fluid rounded-circle sq-24" src="{{rights?.logo}}"
                                *ngIf="rights?.logo !=  '' && rights?.logo !=  null">
                              <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                *ngIf="rights?.logo ==  null || rights?.logo ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                              <span> {{rights.name}}</span>
                            </span>
                          </div>
                        </li>
                      </ng-container>
                      <ng-container
                        *ngIf="(viewData.applicability?.applicable != 'Employee') && (viewData.applicability?.applicable != 'Company')">
                        <div class="d-flex flex-wrap mt-8 gap-8">
                          <ng-container *ngFor="let rights of viewData.applicability[viewData.applicability?.applicable], let i=index;">
                            <span class="badge3" title="{{rights.name}}"> {{ (rights.length>25)? (rights |
                              slice:0:25)+'..':(rights.name) }}
                            </span>
                          </ng-container>

                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
<div class="modal show " style="--bs-modal-width:636px" tabindex="-1" *ngIf="modalToggle">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content radius-24">
      <div class="modal-header border-0 px-30 py-22 gap-16">
        <h4 class="modal-title">Asset images</h4>
        <i class="icon-close-lg ms-auto pointer" (click)="modalToggle=false"></i>
      </div>
      <div class="modal-body px-30 pb-30">
        <div class="row row-24">
          <div class="col-12">
            <div class="  position-relative">
              <div class="bg-dark-500" style="height: 294px;">

                <lib-ngx-image-zoom [thumbImage]=fileName [fullImage]=fileName [magnification]="2" [enableScrollZoom]="true" [enableLens]="true" [lensWidth]="200" [lensHeight]="200"></lib-ngx-image-zoom>
              </div>
              <button class="btn btn-light translate-middle sq-32 rounded-circle position-absolute top-50 border" *ngIf="currentIndex!=1" (click)="moveForwardBackward('backward')"> <i
                  class="icon-chevron-left-lg"></i> </button>
              <button class="btn btn-light translate-middle sq-32 rounded-circle position-absolute start-100 top-50 border" *ngIf="currentIndex!=viewData?.asset_attachments.length" (click)="moveForwardBackward('forward')">
                <i class="icon-chevron-right-lg"></i>
              </button>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center img-carousel-count">
            <div class="fs-22">{{currentIndex}}/{{viewData?.asset_attachments.length}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
