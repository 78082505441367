import { Component, OnInit, ViewChild } from '@angular/core';
import { InviteEmployeeService } from 'src/app/@core/services/invite-employee.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import {Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-reset-old-user',
  templateUrl: './reset-old-user.component.html',
  styleUrls: ['./reset-old-user.component.scss']
})
export class ResetOldUserComponent implements OnInit {

  searchlistdata  : any = []
  searchlist  : any = []
  inviteData      : any = []
  inviteDataIds      : any = []

  resetPermission : any = []
  failedData      : any = []
  successDataCount = 0
  canSave         : boolean = true

  successMsg      : any
  errorMsg        : any
  errorLog        : boolean = false
  exportData      : any = []

  page            = 1
  pageSize        = 20
  canGenerate : boolean = true;
  gnrtdPwddPop = false
  gnrtdPwdList:any[] = []
  confirmPwddPop = false
  selectedValue:any = ""
  haveComma = false

  @ViewChild(SearchbarComponent) search! :SearchbarComponent;

  searchkey       = this.messageService.searchdisplay('employee code or employee name');

  constructor(
    private messageService : MessageService,
    public appService     : AppService,
    private inviteEmp      : InviteEmployeeService,
    private notificationService : NotificationService,
    public clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.getInviteData('existing')
    this.getReset()
  }

  // API
  getInviteData(value:any){

    this.inviteEmp.getInviteData(value,'').subscribe((res:any)=>{
      for(let i=0; i< res.body.length; i++){
        if(res.body[i]['employee_name'] != undefined)
        res.body[i]['name']   = res.body[i]['employee_name'];
        res.body[i]['name']   +=" ("+res.body[i]['employee_code']+")";
        res.body[i]['image']   = res.body[i]['profile_image'];
      }
     
      this.searchlist = res.body
      this.prepareSearchList()
    })
  }

  // Checking Search
  searchresults(value:any){
    if(!isNaN(value) && value !=""){
        let data = this.searchlistdata.filter((item:any)=>{
          return item.id == value
        })
      if(data[0]){
        this.inviteDataIds.push(data[0].id)
        this.inviteData.push(data[0])
        this.search.clearBar();
        this.prepareSearchList()
      }
    }
  }
  searchKeyFn(value:any){
    this.selectedValue = value
  }
  enterClicked(){
    let data = this.transform(this.searchlistdata,this.selectedValue)
    data.forEach((element: any) => {
      this.inviteDataIds.push(element.id)
      this.inviteData.push(element)
    });
    this.search.clearBar();
    this.prepareSearchList()
  }
  transform(items: Object[], args: string): any {
    if (!items || !items.length || args.trim() === ',' || /(?:,,|,[$&+,:;=?@#|'<>.^*()%!-\[\]])/.test(args)) { return []; }

    if (!args) { return []; }

    const employeeCodesAndNames = args.split(',');

    // Check if input is empty or contains only commas
    if (employeeCodesAndNames.length === 0 || employeeCodesAndNames.every(arg => arg.trim() === '')) {
      return []
    }

    return items
      .filter((item:Record<string, any>) =>  employeeCodesAndNames.some(arg =>
        employeeCodesAndNames.some(arg =>
          Object.entries(item)
            .filter(([key]) => key === 'employee_code' || key === 'employee_name')
            .some(([key, value]) => value?.toString().toLowerCase().includes(arg.toLowerCase()))
        )
      ))
  }

  prepareSearchList(){
    if(this.inviteData.length == 0)
      this.searchlistdata = this.searchlist
    else
    this.searchlistdata = this.searchlist.filter((e: any) => {
        return this.inviteDataIds.indexOf(e.id) == -1;
      });
  }



  // Whole Checked data
  getWholeData(){
    return this.inviteData.filter((item:any)=> item.checked == true)
  }


  // Check All Data In Table
  checkAll(value:any){
    let list = this.inviteData
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = list.slice(start,end)
    slicedArray.forEach((item:any)=>{
      item.checked = value.target.checked
    })

  }

  // Checked
  pageChange(){
    let list = this.inviteData
    let start = (this.page - 1) * this.pageSize
    let end   = this.page * this.pageSize
    let slicedArray = list.slice(start,end)
    if(slicedArray.length){
      return slicedArray.every((item:any)=>{
        return item.checked
      })
    }else{
      return false;
    }

  }

   // Tag Close
   applyTagClose(index:any){
    this.inviteData.splice(index,1)
    this.prepareSearchList()
  }

  // Send Data to Parent
  sendPassWord(){
    this.canSave = false;
    let datas:any = []
    let data = this.getWholeData()
    data.forEach((item:any)=>{
      datas.push(item.id)
    })

    this.inviteEmp.sendPassword({"invite_category":"existing","employee_list":datas}).subscribe((res:any)=>{
      if(res.status == 200 && res.body.failed_employee_list.length == 0){
        this.appService.unselectAll(this.inviteData)
       this.notificationService.handleSuccessNotification("Password successfully sent to the selected employees.","Success")
      }else{
        this.errorLog = true
        this.exportData = []
        this.failedData = res.body.failed_employee_list
        for(let i = 0; i < this.failedData.length; i++){
          this.exportData.push({'Employee Code' : this.failedData[i]['data'],'Error Description' : this.failedData[i]['error']})
        }

        this.successDataCount = datas.length - this.failedData.length
        this.successMsg = (datas.length - this.failedData.length) +" out of "+datas.length+" employee(s) password sent successfully"
        this.errorMsg = this.failedData.length+' employee(s) show error data'
      }
      this.canSave = true
    })

  }


  // Permissions
  getReset(){
    this.resetPermission = this.appService.getPermissions('');
    if(this.resetPermission == undefined){
      setTimeout(() => {
        this.getReset();
      }, 1000);
    }
  }
  generatePassWord(){
    this.canGenerate = false;
    let datas: any[] = []
    let dataArray = this.getWholeData()
    dataArray.forEach((item:any)=>{
      datas.push(item.id)
    })
    this.inviteEmp.generatePassword({"employee_list":datas}).subscribe((res:any)=>{
      this.confirmPwddPop=false
      this.gnrtdPwddPop = true
      this.gnrtdPwdList = res.body.geneared_employee_list
    },(error:any)=>{

      this.canGenerate = true;
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    })

  }
  closePwdList(){
    this.canGenerate = true;
    this.gnrtdPwddPop = false
  }


  defaultFilterFunction = (items: any[], query: string) => {
    if (query.includes(',')) {
      this.haveComma = true
      return this.transform(this.searchlistdata,query)
    } else {
      this.haveComma = false
      return items.filter((item: any) => {
          if (typeof item === 'string') {
              // string logic, check equality of strings
              return item.toLowerCase().indexOf(query.toLowerCase()) > -1;
          }
          else if (typeof item === 'object' && item instanceof Object) {
              // object logic, check property equality
                return item['name'] ? item['name'].toLowerCase().indexOf(query.toLowerCase()) > -1 : "";
              // 
          } 
          else{
            return this.transform(items,query)
            // return null
          }
      });
    }
  }
  
}
