import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-final-settlement-expense-reimbursements',
  templateUrl: './final-settlement-expense-reimbursements.component.html',
  styleUrls: ['./final-settlement-expense-reimbursements.component.scss']
})

export class FinalSettlementExpenseReimbursementsComponent implements OnInit {

  @Input() empId: any;
  @Input() payrollStatus:any;
  @Input() resignationDetails:any;
  @Input() currency:any;

  @Output() submitmethod = new EventEmitter();
  @Output() warningMsg   = new EventEmitter();

  status                    = "PENDING";
  pendingExpense            = false;
  viewDetail                = false;
  alertShow                 = 'show';
  alertShow1                = 'show';
  expenseLoader             = false;
  expenseReimburseData:any  = [];
  manualExpenseLoader       = false;
  manualExpenseData:any     = [];
  pageSize1                 = 20;
  page1                     = 1;
  pageSize2                 = 20;
  page2                     = 1;
  saveClicked               = false;
  requestitem:any           = [];
  viewStat:any              = '';
  employee:any              = '';

  successDataCount          = 0;
  failedData:any            = [];
  exportData:any            = [];
  downloadMsg               = false;
  errorMsg: any;

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route: ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this.getExpenseReimburseData();
    this.getManualExpenseData();
  }

  getExpenseReimburseData(){
    this.expenseLoader = true;
    this.expenseReimburseData  = [];
    var status =  this.status == 'PENDING' ? 'Pending for reimbursement via payroll' : 'Reimbursed';
    this.finalSettlementService.getFnfExpenseReimbursementData(status,JSON.stringify([this.empId])).subscribe((res: any) => {

      this.expenseReimburseData = res;
      this.expenseLoader        = false;

    })
  }

  getManualExpenseData(){
    this.manualExpenseLoader   = true;
    this.manualExpenseData     = [];
    this.finalSettlementService.getFnfExpenseManualReimbursementData('Accepted',JSON.stringify([this.empId])).subscribe((res: any) => {
      this.manualExpenseData   = res;
      this.employee = res?.length > 0 ? res[0]['employee_name']+' ('+res[0]['employee_code']+')' : '';
      this.manualExpenseLoader = false;

    })
  }

  checkall(event:any){
    var newList = this.expenseReimburseData
    var begin   = (this.page1-1) * this.pageSize1 ;
    var end     = this.page1* this.pageSize1;
    var subArray = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      if(event.target.checked == true){
        item.checked = event.target.checked
      }else{
        item.checked = false;
      }
    })
  }

  checkAllSelected(){
    var newList = this.expenseReimburseData;
    var begin   = (this.page1-1) * this.pageSize1 ;
    var end     = this.page1* this.pageSize1;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
          return item.checked;
      });
    } else {
      return false;
    }
  }

  countSelected(){
    return this.expenseReimburseData.filter((obj :any) => obj.checked === true)?.length;
  }


  viewExpenseDetail(id:any, status:any){
    this.viewDetail = true;
    this.viewStat = status == 'Pending for reimbursement via payroll' ? 'Pending for reimbursement via payroll' : status == 'Awaiting reimbursement' ? 'Awaiting reimbursement' : 'Reimbursed'
    this.requestitem  = [];
    this.finalSettlementService.getExpenseReportDetail(id).subscribe((res: any) => {
      this.requestitem = res;
    })
  }

  reimburseOrUndoAction(){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.warningMsg.emit();
    }else{
      this.saveClicked = true;
      var arrearId = [];

      if(this.expenseReimburseData?.length > 0){
        for(let i=0;i<this.expenseReimburseData?.length;i++) {
          if(this.expenseReimburseData[i]?.checked == true){
            arrearId?.push(this.expenseReimburseData[i]?.id)
          }
        }
      }

      var status =  this.status == 'PENDING' ? 'Reimbursed' : 'Pending';

      this.finalSettlementService.updateFnfExpenseReimbursementStatus({'expense_reimbursement_list': arrearId,'pay_month': this.resignationDetails?.value?.pay_month,'pay_year': this.resignationDetails?.value?.pay_year,'status': status }).subscribe((res: any) => {
        this.saveClicked = false;

        if(res?.error_list != undefined && res?.error_list?.length != 0)
          this.downloadErrorData(res);
        else
          this.notificationService.handleSuccessNotification("Updated successfully","Success");
        this.getExpenseReimburseData();
      },(err: any) => {
        this.saveClicked = false;
      });
    }
  }


  downloadErrorData(data:any){
    this.exportData    = [];
    this.failedData    = [];
      if(data?.error_list != undefined && data?.error_list?.length != 0){
        data?.error_list?.forEach((i:any, k:any) => {
              this.failedData?.push(i)
          });
      }
      if(this.failedData?.length > 0){
      for (let i = 0; i < this.failedData?.length; i++) {
        this.exportData.push({'Employee code':this.failedData[i]['employee_code'],'Report':this.failedData[i]['report'],'Error description':this.failedData[i]['error_description']});
      }

      this.errorMsg     = this.failedData?.length+' error data';
      this.downloadMsg  = true;
    }
  }

  submitExpense(){
    this.submitmethod.emit();
  }

}
