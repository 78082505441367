<div class="side-panel" style="--sidepanel-width:27rem;z-index:9999;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>{{selectedName}}</h5>
      <a class="toggle-panel" (click)="closeThePanel()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32 h-100">
        <div class="row row-16 ">
              <div class="col-12" *ngIf="searchNeeded=='true'">
            <div class="form-icon2">
              <i class="icon-search icon-left fs-14"></i>
              <div _ngcontent-kjn-c1041="" class="btn-close icon-right pointer fs-12" (click)="searchString=''"
                *ngIf="(searchString != '' && searchString != null)"></div>
              <input type="text" class="form-control fs-14" placeholder="{{this.messageServ.searchdisplay('')}}"
                [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}">
               </div>
              </div>
              <ng-template #all_data>
                <div class="row row-16 mt-8">
                <div class="col-12" *ngFor="let item of selectedList|filter:searchString;let ind = index">
                <div class="card card-c2 px-24 py-8" *ngIf="selectedName != 'Announcements'">
                  <div class="card-body d-flex align-items-center px-0 py-8">
                    <div *ngIf="(item.profile_image == null || item.profile_image=='')"
                      class="avatar-circle sq-57 {{item.color_code}} avatar-border">
                      {{this.appService.getFirstChar(item.first_name+" "+(item.middle_name!=null?item.middle_name:" ")+
                      " "+item.last_name,2)}}</div>
                    <img *ngIf="(item.profile_image != null && item.profile_image!='')" src="{{ item.profile_image }}"
                      class="avatar-circle sq-57 avatar-border2" />
                    <div class="overflow-hidden  d-flex flex-column">
                      <p class="fs-14 fw-600 ms-16 mb-0">{{item.first_name+"
                        "+(item.middle_name!=null?item.middle_name:" ")+ " "+item.last_name}} ({{item.employee_code}})
                      </p>
                      <p class="fs-12 fw-500 text-light-400 ms-16 mb-0">{{item.designation}} | {{item.company}}</p>
                    </div>
                  </div>
                  <div class="card-body d-flex align-items-center fs-10 fw-500 px-0 py-8 fs-10" *ngIf="selectedName !='Employees shift not mapped' && from=='attendancedashboard'">
                    <span class="text-light-400">{{selectedName}}</span>
                    <span class="ms-auto">{{item.count}}</span>
                  </div>
                  </div>
                  <div class="card card-c2 px-24 py-8" *ngIf="selectedName == 'Announcements'">
                    <div class="card-body d-flex px-0 py-12">
                      <div class="d-flex flex-column align-items-start gap-2">
                        <div class="badge badge-danger fs-8 fw-500 py-1 px-2">WINDOW CLOSES
                          SOON</div>
                        <p class="mb-0 fs-14 fw-500 text-trim  lh-base">{{item?.name}}</p>
                        <span class="text-light-500 text-trim  fs-12">Window open till
                          <span class="fw-500">{{this.appService.dateFormatDisplay(item?.value)}}</span>
                        </span>
                        <a href="" class="link-primary1 fs-12 fw-500" routerLink="/request">Declare
                          Now</a>
                      </div>
                      <div class="sq-42 flex-center bg-alert ms-auto fs-20 avatar-circle {{item?.icon_bg}}">
                        <i class="{{item?.icon}}"></i>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
          </ng-template>
        </div>
        <div *ngIf="(selectedList|filter:searchString).length <=0 ;else all_data" class="row row-16 h-100">
          <div class="col-12 text-center">
            <div class="flex-center flex-column h-100">
              <img class="img-fluid mx-auto " src="/assets/images/helper/helper3.png">
              <p class="text-light-400 fs-14 mt-3">No data to show</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
