import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DesignationService } from 'src/app/@core/services/designation.service';
import { MessageService } from 'src/app/message.global';
import { Validators } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';

@Component({
  selector: 'app-sr-define-salary-details',
  templateUrl: './sr-define-salary-details.component.html',
  styleUrls: ['./sr-define-salary-details.component.scss']
})
export class SrDefineSalaryDetailsComponent implements OnInit,AfterViewInit {
    salaryRevisionHistory         = false;
    @Input() payGroupDD:any       = [];
    @Input() formGp:any           = [];
    @Input() defaultMonthData:any;
    @Input() employeeDetails:any  = [];
    @Output() submitmethod        = new EventEmitter();
    @Output() defualtData         = new EventEmitter();
    @Input() error                = ''
    DesignationDD:any             = [];
    submitted                     = false;
    Isalert                       = true;
    arrear                        = true;
    currentYear                   = new Date("Y");
    year                          = new Date().getFullYear();
    month                         = new Date().getMonth();
    nextYear                      = new Date().getFullYear()+1;
    currentYearMonth              = Number(this.year+""+this.month);
    alertmsg                      = '';
    permissions:any               = [];
    monthDate:any = [
                      {id:'0',value:"01-01",data:"January"},
                      {id:'1',value:"02-01",data:"February"},
                      {id:'2',value:"03-01",data:"March"},
                      {id:'3',value:"04-01",data:"April"},
                      {id:'4',value:"05-01",data:"May"},
                      {id:'5',value:"06-01",data:"June"},
                      {id:'6',value:"07-01",data:"July"},
                      {id:'7',value:"08-01",data:"August"},
                      {id:'8',value:"09-01",data:"September"},
                      {id:'9',value:"10-01",data:"October"},
                      {id:'10',value:"11-01",data:"November"},
                      {id:'11',value:"12-01",data:"December"},
                    ]


    monthEffective:any  = this.monthDate[this.month]['data'];
    monthPayout:any     = this.monthDate[this.month]['data'];

    constructor(private designationService:DesignationService,public messageService : MessageService,
    public appService:AppService,
    ) { }
    ngOnInit(): void {
      if(this.defaultMonthData.length==0)
      {
        this.setDefaultdata();
      }
      this.designationListData();
      this.addConditionalValidation('revise_ctc');
      this.addConditionalValidation('revise_per');
      this.changeMonth();
      this.constructMonthData();


    }
    setDefaultdata(){

      this.defaultMonthData = [{
        effectiveYear     : this.year,
        effectiveMonth    : this.monthDate[this.month]['id'],
        effectiveDate     : this.year+' '+this.monthDate[this.month]['data'],
        payoutYear        : this.year,
        payoutMonth       : this.monthDate[this.month]['id'],
        payoutDate        : this.year+' '+this.monthDate[this.month]['data'],
      }]
    }
    getPermission(){
      this.permissions = this.appService.getPermissions('/salary-revision');

      if(this.permissions == undefined){
        setTimeout(() => {
          this.getPermission();
        }, 1000);
      }
    }
    /*construct monthly value for effective and payout month
    control:identify function calls from payout/effective month
    field:identifier for year/month
    */
    constructMonthData(event:any='',control:any='',field:any=''){

      let currentDate   = new Date();
      let doj_year      = new Date(this.employeeDetails.employee_data.date_of_joined).getFullYear();
      let doj_month     = new Date(this.employeeDetails.employee_data.date_of_joined).getMonth();
      let doj_combined  = Number(doj_year+""+doj_month);
      let singledate;
      let selectedYear;

      selectedYear = control=='effective_from'?this.defaultMonthData[0]['effectiveYear']:control=='salary_pay_month'?this.defaultMonthData[0]['payoutYear']:this.year;

      for(let i=0;i<this.monthDate.length;i++){
        singledate          = new Date(moment(selectedYear+"-"+this.monthDate[i]['value']).format('YYYY-MM-DD'))
        let effectiveMonth  = new Date(singledate).getMonth();
        effectiveMonth      = Number(selectedYear+""+effectiveMonth);

        if(currentDate.getTime() > singledate.getTime() ){

          this.monthDate[i]['effective']    = false;
          this.monthDate[i]['payoutMonth']  = true;

        }

        else{
          this.monthDate[i]['effective']    = false;
          this.monthDate[i]['payoutMonth']  = false;
        }
        if(doj_year == new Date(singledate).getFullYear() && doj_combined > effectiveMonth){

          this.monthDate[i]['effective']    = true;

        }
        if(this.currentYearMonth == effectiveMonth){
          this.monthDate[i]['effective']    = false;
          this.monthDate[i]['payoutMonth']  = false;

        }
      }
      if(control=='effective_from'){
          if(field=='month')
            this.monthEffective = this.monthDate[event]['data'];

        this.defaultMonthData[0]['effectiveDate'] = selectedYear+' '+this.monthEffective;
      }
      else if(control=='salary_pay_month'){
        if(field=='month')
          this.monthPayout = this.monthDate[event]['data'];
        this.defaultMonthData[0]['payoutDate'] = selectedYear+' '+this.monthPayout;
      }
      let effectiveForm  = this.defaultMonthData[0].effectiveYear+'-'+this.monthDate[this.defaultMonthData[0].effectiveMonth]['value']
      this.formGp.get('effective_from').setValue(effectiveForm);
      let payoutForm  = this.defaultMonthData[0].payoutYear+'-'+this.monthDate[this.defaultMonthData[0].payoutMonth]['value']
      this.formGp.get('salary_pay_month').setValue(payoutForm);
      this.changeMonth();

    }
    addConditionalValidation(control:any){

      if(this.formGp.get('rev_type').value==false) {

         this.formGp.get('revise_ctc').setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(10)])
         this.formGp.get('revise_per').clearValidators();
         this.formGp.get('revise_per').setValue('');

      } else {

          this.formGp.get('revise_per').setValidators([Validators.required,digitdecimalValidator(),Validators.max(100)])
          this.formGp.get('revise_ctc').clearValidators();
          this.formGp.get('revise_ctc').setValue('');

      }
      this.formGp.controls[control].updateValueAndValidity();

    }

    ngAfterViewInit() {
        if(this.error != ""){
            this.createError()
        }
    }
    get f() {return  this.formGp.controls; }
    designationListData(){
        this.designationService.designationListData(true).subscribe((res: any) => {
            this.DesignationDD = res;
        });
    }

    // calculateRevCTC(){
    //     if(!(this.formGp.get('revise_per').errors?.pattern)){

    //       var per = parseFloat(this.employeeDetails.ctc)+((parseFloat(this.formGp.get('revise_per').value)*parseFloat(this.employeeDetails.ctc))/100);
    //       // this.formGp.get('revise_ctc')?.setValue(per);
    //       if(per==parseFloat(this.employeeDetails.ctc))
    //       this.formGp.get('revise_per').setErrors({
    //         'exist':true
    //       })
    //     }
    // }
    // calculateRevPER(){
    //     if(parseFloat(this.employeeDetails.ctc)==parseFloat(this.formGp.get('revise_ctc').value)){

    //       this.formGp.get('revise_ctc').setErrors({
    //         'exist':true
    //       })
    //     }
    //     else{

    //       if((parseFloat(this.formGp.get('revise_ctc').value) != parseFloat(this.employeeDetails.ctc)) && this.employeeDetails.ctc != undefined){
    //           var val = (parseFloat(this.formGp.get('revise_ctc').value) - parseFloat(this.employeeDetails.ctc))*100/parseFloat(this.employeeDetails.ctc);
    //           // this.formGp.get('revise_per')?.setValue(val);
    //       }
    //     }
    // }
    changeMonth(){
        if(this.f.effective_from.value != null && this.f.salary_pay_month.value != null){
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

            var f1 = new Date(this.f.effective_from.value).getMonth();
            var f2 = new Date(this.f.salary_pay_month.value).getMonth();
            var monthdiff = this.monthDiff(new Date(this.f.effective_from.value),new Date(this.f.salary_pay_month.value));
            if(monthdiff < 0){
                this.formGp.controls.salary_pay_month.setErrors({
                'max': 'max',
                })
                this.alertmsg = ''
            } else {
                this.formGp.controls.salary_pay_month.setErrors(null)
                this.formGp.updateValueAndValidity();
                this.alertmsg = "The arrears from "+monthNames[f1]+" "+new Date(this.f.effective_from.value).getFullYear()+" - "+monthNames[f2]+" "+new Date(this.f.salary_pay_month.value).getFullYear()+" ("+ monthdiff+" months) will be payed out in the month of "+monthNames[f2]+" "+new Date(this.f.salary_pay_month.value).getFullYear()
                this.arrear   = true;
            }
        }
    }
    monthDiff(d1:Date, d2:Date) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months
    }
    validateForm() {
        this.submitted = true;
        if (this.formGp.invalid) {
          return;
        }
        this.submitmethod.emit(1);
        this.defualtData.emit(this.defaultMonthData);
    }
    createError(){
        this.formGp.controls.pay_group.setErrors({
        'conflict': this.error,
        })
        this.submitted = true;
    }

}
