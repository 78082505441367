import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SeparationPolicyService } from 'src/app/@core/services/separation-policy.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { spaceValidator } from 'src/app/@shared/validators/space.validators';
@Component({
  selector: 'app-seperation-policy-setup',
  templateUrl: './seperation-policy-setup.component.html',
  styleUrls: ['./seperation-policy-setup.component.scss']
})
export class SeperationPolicySetupComponent implements OnInit {
  activeTab         =1;
  disabled          = false;
  res               :any;
  title ='Add Separation Policy';
  id: number = 0;
  iseditForm=false;
  editflag: boolean = false;
  loader     = false;
  myForm: FormGroup = this.fb.group({
    id: 0,
    setting: this.fb.group({
      name        :  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      description : ['', [Validators.required,spaceValidator(),Validators.maxLength(500)]],
      probation_notice_period     : ['',[Validators.required,digitdecimalValidator(),Validators.max(365)]],
      confirm_notice_period       : ['',[Validators.required,digitdecimalValidator(),Validators.max(365)]],
      is_send_relieving_letter : [false,[Validators.required]],
      relieveing_process_days  : [null],
      letter_template          : [null]
      // backdated_resignation_allow : [true,[Validators.required]],
      // backdated_resignation_period: [''],
    }),
    applicable: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })

  });
  constructor(private fb:FormBuilder,
    public messageService : MessageService,
    public router: Router,
    public route:ActivatedRoute,public separationpolicyservice:SeparationPolicyService,
    public notificationservice:NotificationService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }
  submitOne(){
    this.activeTab = 2;
  }
  get settingForm() {
    return this.myForm.get('setting') as FormGroup;
  }
  get applicableForm() {
    return this.myForm.get('applicable') as FormGroup;
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  submitForm(){
    this.res = this.applicableForm.controls.assignees.value;
    var applicable = this.applicableForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.res){
      arr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }

    var dataArray = {
      "name": this.settingForm.controls.name.value,
      "description": this.settingForm.controls.description.value,
      "probation_notice_period": this.settingForm.controls.probation_notice_period.value,
      "confirm_notice_period": this.settingForm.controls.confirm_notice_period.value,
      "is_send_relieving_letter": this.settingForm.controls.is_send_relieving_letter.value,
      "relieveing_process_days": this.settingForm.controls.relieveing_process_days.value,
      "letter_template": this.settingForm.controls.letter_template.value,
      // "backdated_resignation_allow": this.settingForm.controls.backdated_resignation_allow.value,
      // "backdated_resignation_period": this.settingForm.controls.backdated_resignation_period.value,
      "seperation_policy_applicablity": arr
   };
    if(this.id == 0){
      this.separationpolicyservice.createSeparationPolicy(dataArray).subscribe((res: any) => {
        this.router.navigate(['separation']);
      },
      (error) => {
        // this.notificationservice.handleErrorNotification(error.error.error,"Error");
      });
    } else {
      this.separationpolicyservice.updateSeparationPolicy(this.id,dataArray).subscribe((res: any) => {
        this.router.navigate(['separation']);
      },
      (error) => {
        // this.notificationservice.handleErrorNotification(error.error.error,"Error");
      });
    }

  }
  editForm(){
    this.loader = true;
    this.editflag = true;
    this.title ='Edit Separation Policy';
    this.separationpolicyservice.getSingleSeparationPolicyData(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res);
    },(error)=>{
      this.router.navigate(['separation']);
    });
  }
  setEditForm(res:any){
    if(res.is_active == false){
      this.myForm.disable();
      this.disabled = true;
    }

    let assignees = [];
    let applicableto = "";
    let appkey ="";


    for(let key of res.seperation_policy_applicablity){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
      assignees.push(key[appkey]);
      applicableto = key.applicable
    }
    this.myForm.reset(
      {
        id: res.id,
        setting: {
          name:  res.name,
          description: res.description,
          probation_notice_period: res.probation_notice_period,
          confirm_notice_period: res.confirm_notice_period,
          is_send_relieving_letter:  res.is_send_relieving_letter,
          relieveing_process_days:  res?.relieveing_process_days,
          letter_template:  res?.letter_template,
          // backdated_resignation_allow: res.backdated_resignation_allow,
          // backdated_resignation_period: res.backdated_resignation_period,
        },
        applicable: {
          applicableto: applicableto,
          assignees: assignees
        }
    });
    this.loader = false;
  }
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].setting.invalid) {
        changeEvent.preventDefault();
      }
    }
  }
  tab2click(){
    if(this.myForm['controls'].setting.valid){
      this.activeTab=2;
    }
  }

}
