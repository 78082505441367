<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="investmentForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 required mb-0">Financial year</p>
                    </div>
                    <div class="col-12 form-row">
                        <!-- <label for="" class="form-label ">Financial Year </label> -->
                        <ng-select class="form-ngselect " (change)="yearChange($event)"
                            placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                            formControlName="financial_year"
                            [ngClass]="{ 'is-invalid': (submitted || f.financial_year.dirty || f.financial_year.touched) && f.financial_year.errors}">
                            <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year"> {{year +'-'+
                                financialYearRange(year)}} </ng-option>
                        </ng-select>
                        <div *ngIf="(submitted || f.financial_year.dirty || f.financial_year.touched) && f.financial_year.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.financial_year.errors?.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                        </div>
                    </div>
                </div>
                <!-- New end -->
                <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.investment_type.errors}">
                    <div class="col-12 form-row">
                        <p class="text-uppercase required fw-500 mb-0">Investment type
                        </p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="investment_type" type="radio" id="investment_type1" class="form-check-input  "
                                formControlName="investment_type" value="proposed">
                            <label for="investment_type1" class="form-check-label">Propose
                            </label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="investment_type" type="radio" id="investment_type2" class="form-check-input "
                                formControlName="investment_type" value="confirmed">
                            <label for="investment_type2" class="form-check-label">Confirm</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="(submitted) && f.investment_type.errors" class="invalid-feedback">
                    <div *ngIf="f.investment_type.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                </div>
                <!-- New start -->
                <!-- New end -->
                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0">Request on
                        </p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check ">
                            <input name="requested_on" type="radio" id="requested_on1" class="form-check-input  "
                                formControlName="requested_on" value="last_month"
                                (change)="radioBtnChangeFunction($event,'requested_on','last_month','req_specific_month','req_specific_year')">
                            <label for="requested_on1" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="requested_on" type="radio" id="requested_on2" class="form-check-input "
                                formControlName="requested_on" value="this_month"
                                (change)="radioBtnChangeFunction($event,'requested_on','this_month','req_specific_month','req_specific_year')">
                            <label for="requested_on2" class="form-check-label">This month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="requested_on" type="radio" id="requested_on3" class="form-check-input "
                                formControlName="requested_on" [value]="true"
                                (change)="radioBtnChangeFunction($event,'requested_on',true,'req_specific_month','req_specific_year')">
                            <label for="requested_on3" class="form-check-label">Specific month</label>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="investmentForm.value.requested_on==true">
                        <div class="col-12 form-row">
                            <div class="input-group form-icon icon-right">
                                <input (click)="datePicker2.open()" class="form-control"
                                    placeholder="{{this.messageService.selectplaceholddisplay('Date of confirmation')}}"
                                    formControlName="req_specific_month" [matDatepickerFilter]="salFilter"
                                    [matDatepicker]="datePicker2" (keydown)="disableDate()"><i
                                    class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                            </div>
                        </div>
                    </ng-container> -->
                    <ng-container *ngIf="investmentForm.value.requested_on==true">
                      <div class="col-6">
                          <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.req_specific_month?.touched || f?.req_specific_month?.dirty) &&  f?.req_specific_month?.errors}">
                              <label for="" class="form-label">Month </label>
                              <app-master-select class="config-app-master" style="width:9rem"
                              [placeholder]="month_placeholder"
                                      id="month" [isrequired]="false" [selectedItem]="investmentForm.value.req_specific_month"
                                  [type]="attendListOfMonth" formControlName="req_specific_month" [(ngModel)]="f.req_specific_month.value"
                                      (ngModelChange)="constructData($event)" [ngClass]="{ 'is-invalid': (submitted || f?.req_specific_month?.touched || f?.req_specific_month?.dirty) &&  f?.req_specific_month?.errors}"> </app-master-select>
                          </div>
                          <div *ngIf="(submitted || f?.req_specific_month?.touched || f?.req_specific_month?.dirty)&& f?.req_specific_month?.errors" class="invalid-feedback">
                              <div *ngIf="f?.req_specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                      <div class="col-6">
                          <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.req_specific_year?.touched || f?.req_specific_year?.dirty) &&  f.req_specific_year.errors}">
                              <label for="" class="form-label">Year </label>
                              <ng-select class="form-ngselect  fs-16"
                              formControlName="req_specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.req_specific_year?.touched || f?.req_specific_year?.dirty) &&  f.req_specific_year.errors}">
                                  <ng-option *ngFor="let yData of Years"
                                      [value]="yData">{{yData}}</ng-option>
                              </ng-select>
                          </div>
                          <div *ngIf="(submitted || f?.req_specific_year?.touched || f?.req_specific_year?.dirty)&& f?.req_specific_year?.errors" class="invalid-feedback">
                              <div *ngIf="f?.req_specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                              </div>
                          </div>
                      </div>
                  </ng-container>
                </div>
                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitInvestment()">
                Apply
            </button>
        </footer>
    </form>
</div>
