import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function TanValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const fieldPattern = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/.test(value);

      return !fieldPattern ? {pattern:true}: null;
  }
}

