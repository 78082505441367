import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { AppService } from 'src/app/app.global';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { LeaveLapsedComponent } from '../leave-report-configure/leave-lapsed/leave-lapsed.component';
import { LeaveAvailedComponent } from '../leave-report-configure/leave-availed/leave-availed.component';
import { LeaveCarryForwardComponent } from '../leave-report-configure/leave-carry-forward/leave-carry-forward.component';
import { LeaveCreditComponent } from '../leave-report-configure/leave-credit/leave-credit.component';
import { LeaveEncashmentComponent } from '../leave-report-configure/leave-encashment/leave-encashment.component';
import { LeaveLedgerComponent } from '../leave-report-configure/leave-ledger/leave-ledger.component';
import { LeaveLopComponent } from '../leave-report-configure/leave-lop/leave-lop.component';
import { LeaveNegativeComponent } from '../leave-report-configure/leave-negative/leave-negative.component';
import { LeaveParticularDayComponent } from '../leave-report-configure/leave-particular-day/leave-particular-day.component';
import { LeaveRequestComponent } from '../leave-report-configure/leave-request/leave-request.component';
import { LeaveSummaryComponent } from '../leave-report-configure/leave-summary/leave-summary.component';
import { NotificationService } from 'src/app/@core/services/notification.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-leave-report-configuration',
  templateUrl: './leave-report-configuration.component.html',
  styleUrls: ['./leave-report-configuration.component.scss']
})
export class LeaveReportConfigurationComponent implements OnInit {

  // Variables Initialization
  reportId          : number = 0
  reportName        : string = ''
  formData          : string = ''
  closeControl      : any = ''
  resetFrom         : any = ''
  isLoading         : boolean = false
  noData            : boolean = false
  addColumnFilter   : boolean = false
  viewDetail        : boolean = false
  alertShow         : boolean = true
  reportDownload    : boolean = false
  applyFilterPop    : boolean = false
  resetFilterPop    : boolean = false
  saveFilterModalClicked : boolean = false
  resetFilterModalClicked: boolean = false
  initialLoaded    : boolean = false
  colRearranged     : boolean = false

  calcHeaders       : any = []
  apiData           : any = []
  apiExcelData      : any = []
  displayedColumns  : any = []
  hint_dict         : any = {}
  resDataFilter     : any
  resHeaderFilter   : any

  // Store initial state of columns
  initialFormState: any;

  // Sort
  ordering          : String = 'Employee Code'
  direction         : string = 'asc'
  sortProperty      : string = 'Employee Code'
  isSorted          : boolean = false

  // Page
  limit             : number = 20
  offset            : number = 0
  lastPageCount     : number = 0
  page              : number = 1
  pageNumber        : number = 1

  //Tag Filter Variables
  appliedFilter: any = {};
  tagMultiData: any = [];
  tagMultiCnt: any = 0;

  // Filter Memorize
  filterSubmitMem   : boolean = false
  addedColumns      : boolean = false

  // ViewChild
  @ViewChild(LeaveLapsedComponent, { static: false }) lapsedReport !: LeaveLapsedComponent;
  @ViewChild(LeaveParticularDayComponent, { static: false }) balanceParticularDay !: LeaveParticularDayComponent;
  @ViewChild(LeaveCarryForwardComponent, { static: false }) carryForwadReport !: LeaveCarryForwardComponent;
  @ViewChild(LeaveAvailedComponent, { static: false }) availedReport !: LeaveAvailedComponent;
  @ViewChild(LeaveCreditComponent, { static: false }) creditReport !: LeaveCreditComponent;
  @ViewChild(LeaveEncashmentComponent, { static: false }) encashmentReport !: LeaveEncashmentComponent;
  @ViewChild(LeaveNegativeComponent, { static: false }) negativeReport !: LeaveNegativeComponent;
  @ViewChild(LeaveLopComponent, { static: false }) lopReport !: LeaveLopComponent;
  @ViewChild(LeaveSummaryComponent, { static: false }) balanceReport !: LeaveSummaryComponent;
  @ViewChild(LeaveLedgerComponent, { static: false }) ledgerReport !: LeaveLedgerComponent;
  @ViewChild(LeaveRequestComponent, { static: false }) requestReport !: LeaveRequestComponent;

  // Form Initialization
  // Side Panel Form
  sidePanelForm     !: FormGroup

  // Filter Save Form
  filterSaveForm = this.fb.group({
    filter: true,
  });

  // Common OU Form
  OuFilterForm = this.fb.group({
    leave_policy: [''],
    leave_type: [''],
    company: [''],
    bu: [''],
    branch: [''],
    designation: [''],
    department: [''],
    employee_list: [''],
    grade: [''],
    employee_type: [''],
    employment_status: [''],
  })

  // Lapsed Form
  lapsedForm = this.fb.group({
    period: [true, [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

   // Leave Balance on a Particular Day Form
   balanceParticularForm = this.fb.group({
    selected_date: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Leave carry forward form
  carryForwardForm = this.fb.group({
    period: [true, [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Availed Form
  availedForm = this.fb.group({
    transaction_date: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Credit Form
  creditForm = this.fb.group({
    leave_credit_for: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Encashment Form
  encashmentForm = this.fb.group({
    period: [true, [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Negative Form
  negativeForm = this.fb.group({
    selected_date: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // LOP Form
  lopForm = this.fb.group({
    transaction_date: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Balance Form
  balanceForm = this.fb.group({
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Ledger Form
  ledgerForm = this.fb.group({
    transaction_date: ['this_month', [Validators.required]],
    trans_from_date: '',
    trans_to_date: '',
    from_date: ['', [Validators.required]],
    to_date: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Request Form
  requestForm = this.fb.group({
    leave_period: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    request_type: '',
    status: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Mat Table
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private fb       : FormBuilder,
    public route     : ActivatedRoute,
    private lvServ   : LeaveReportService,
    public appService: AppService,
    private rprtServ : ReportsService,
    private cd       : ChangeDetectorRef,
    public router    : Router,
    private notify   :  NotificationService
  ) { }

  ngOnInit(): void {
    // ID & Report Name from Params
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.reportId = params['id'];
      }
      if(params['name']){
        let report = params['name']?.match(/[A-Z][a-z]*|[A-Z]/g);
        this.reportName = report.join(' ');
        this.initialLoaded = true
        this.isLoading = true
        this.getDateFormat().then(() => {
          this.reportInitialSetups();
        });
      }
    })
  }

  // Fn to setup initials for all reports
  reportInitialSetups(){
    this.isLoading = true
    this.apiExcelData = []
    this.appliedFilter = {};
    this.lvServ.reportAPI(this.urlGenerator()?.url,this.limit,this.offset,this.ordering,this.formData).subscribe((res:any)=>{
      if(res?.data?.length != 0){
        this.noData = false;
        // Formatting dates and fields if needed
        for(let i = 0; i < res?.data?.length; i++){
          res.data[i]['Date of Joining'] = (res?.data[i]['Date of Joining'] == null || res?.data[i]['Date of Joining'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Date of Joining']);
          res.data[i]['Leave Type'] = res?.data[i]['Leave type']
          res.data[i]['Employee status'] = res?.data[i]['Employee Status']
          res.data[i]['Lapsed on'] = (res?.data[i]['Lapsed on'] == null || res?.data[i]['Lapsed on'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Lapsed on']);
          res.data[i]['Applied on'] = (res?.data[i]['Applied on'] == null || res?.data[i]['Applied on'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Applied on']);
          res.data[i]['Availed From'] = (res?.data[i]['Availed From'] == null || res?.data[i]['Availed From'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Availed From']);
          res.data[i]['Availed To'] = (res?.data[i]['Availed To'] == null || res?.data[i]['Availed To'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Availed To']);
          res.data[i]['Approved on'] = (res?.data[i]['Approved on'] == null || res?.data[i]['Approved on'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Approved on'])
          res.data[i]['Granted on'] = (res?.data[i]['Granted on'] == null || res?.data[i]['Granted on'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Granted on'])
          res.data[i]['Transaction date'] = (res?.data[i]['Transaction date'] == null || res?.data[i]['Transaction date'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Transaction date']);
          res.data[i]['Closing Balance'] = res?.data[i]['Closing balance']
          res.data[i]['Last updated on'] = (res?.data[i]['Last updated on'] == null || res?.data[i]['Last updated on'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Last updated on'])
          res.data[i]['Rejected on'] = (res?.data[i]['Rejected on'] == null || res?.data[i]['Rejected on'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['Rejected on'])
          res.data[i]['From date'] = (res?.data[i]['from_date'] == null || res?.data[i]['from_date'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['from_date'])
          res.data[i]['To date'] = (res?.data[i]['to_date'] == null || res?.data[i]['to_date'] == '') ? '' : this.appService.dateFormatDisplay(res?.data[i]['to_date'])
          if(this.reportName == 'Leave Ledger Report'){
            res.data[i]['Opening balance'] = res?.data[i]['Opening_balance']
          }
        }
        this.apiExcelData = res?.data
        const limitedData = res?.data.slice(0, 20);
        this.limit = 20
        this.apiData = new MatTableDataSource(limitedData);
        this.setDataSourceAttributes();
      }else{
        this.noData = true
      }
      this.settingFormVal(res?.applied_filter)
      this.lastPageCount = res?.count;
      this.resDataFilter = res?.data_filter;
      this.resHeaderFilter = res?.header_filter
      this.appliedFilter = res?.applied_filter;
      if(this.initialLoaded){
        this.lvServ.setDataFilter(this.resDataFilter)
        this.lvServ.setHeaderFilter(this.resHeaderFilter)
        this.initialLoaded = false;
      }
      // Columns setup by considering page & sort
      if(!this.isSorted){
        this.setColumnsPanel(res?.check_list,res?.header_list);
      }
      // Saved data setup for filter
      if(Object.keys(this.resDataFilter)?.length != 0){
        this.urlGenerator()?.form.reset(this.resDataFilter)
        this.OuFilterForm.reset(this.resDataFilter)
        this.settingFormVal(this.resDataFilter)
      }
      // Download Report
      if(this.reportDownload){
        this.commonLogic(this.apiExcelData)
        this.reportDownload = false
      }
      this.isLoading = false
    })
  }

  // URL & Form collection
  urlGenerator(){
    let urlForm;
    if(this.reportName == 'Lapsed Leaves Report'){
      urlForm = { url : 'lapsed-leave-report', form : this.lapsedForm}
    }else if(this.reportName == 'Leave Balance On A Particular Day Report'){
      urlForm = { url : 'leave-balance-single-report', form : this.balanceParticularForm}
    }else if(this.reportName == 'Leave Carry Forward Report'){
      urlForm = { url : 'leave-carryforward-report', form : this.carryForwardForm}
    }else if(this.reportName == 'Leave Availed Report'){
      urlForm = { url : 'leave-availed-report', form : this.availedForm}
    }else if(this.reportName == 'Leave Credit Report'){
      urlForm = { url : 'leave-credit-report', form : this.creditForm}
    }else if(this.reportName == 'Leave Encashment Report'){
      urlForm = { url : 'leave-encashment-report', form : this.encashmentForm}
    }else if(this.reportName == 'Negative Leave Balance Report'){
      urlForm = { url : 'negative-balance-report', form : this.negativeForm}
    }else if(this.reportName == 'Unpaid Leave Report'){
      urlForm = { url : 'leave-lop-report', form : this.lopForm}
    }else if(this.reportName == 'Leave Balance Summary Report'){
      urlForm = { url : 'leave-balance-summary-report', form : this.balanceForm}
    }else if(this.reportName == 'Leave Ledger Report'){
      urlForm = { url : 'leave-ledger-report', form : this.ledgerForm}
    }else{
      urlForm = { url : 'leave-request-status-report', form : this.requestForm}
    }
    return urlForm
  }

  // Side Panel Date & Status
  settingFormVal(data:any){
    const reportConfig = {
      'Leave Availed Report': { controlName: 'transaction_date', from: 'from_date', to: 'to_date',applyStatus: false },
      'Unpaid Leave Report': { controlName: 'transaction_date', from: 'from_date', to: 'to_date', applyStatus: false },
      'Leave Ledger Report': { controlName: 'transaction_date', from: 'trans_from_date', to: 'trans_to_date', applyStatus: false },
      'Leave Balance Summary Report': { controlName: 'leave_period', from: 'from_date', to: 'to_date', applyStatus: true },
      'Leave Request Status Report': { controlName: 'leave_period', from: 'from_date', to: 'to_date', applyStatus: true }
    };
    const defaultConfig = { controlName: 'leave_credit_for', from: 'from_date', to: 'to_date' };
    const config = reportConfig[this.reportName as keyof typeof reportConfig] || defaultConfig;
    this.updateFormControl(data, this.urlGenerator()?.form, config.controlName, config.from, config.to);
    if (config?.applyStatus && this.appliedFilter?.status) {
      this.urlGenerator()?.form.get('status')?.reset(this.appliedFilter?.status);
    }
  }

  // For Specific date setup
  updateFormControl(filter: any, formGroup: FormGroup, controlName: string, fromDateControl: string, toDateControl: string){
    const dateRange = filter?.[controlName];
    if (dateRange && dateRange !== 'last_month' && dateRange !== 'this_month') {
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: new Date(dateRange[0]),
        [toDateControl]: new Date(dateRange[1])
      });
    } else {
      formGroup.get(controlName)?.reset(dateRange);
    }
    if(dateRange == undefined){
      formGroup.get(controlName)?.reset('this_month');
    }
  }

  // Columns Side Panel
  // Reset Columns Button
  resetColumns(){
    const formArray = this.sidePanelForm.get('columnsFormArr') as FormArray;
    formArray.clear();
    if(this.lvServ.header_filter?.length > 0){
      this.resetFilterPop = true
      this.resetFrom = 'columnReset'
    }else{
      // Rebuild the FormArray with the initial state
      this.initialFormState.forEach((item:any) => {
        formArray.push(this.fb.group({
          name: [item.name],
          checkbox: [item.checkbox]
        }));
      });
      this.headersApply();
    }
  }

  // Apply Columns Button
  headersApply(){
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) => {
      const indexA = this.calcHeaders.indexOf(a);
      const indexB = this.calcHeaders.indexOf(b);
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    // Table Columns setup
    this.displayedColumns = sortedCheckedItems
    this.addedColumns = !this.columnsFormArrForm().pristine
  }

  // Set Columns Panel from API
  setColumnsPanel(check_list: any, header_list: any) {
    this.calcHeaders = this.lvServ.header_filter?.length > 0 ? this.lvServ.header_filter : header_list
    // Preserving initial values for Reset
    this.initialFormState = check_list.map((item:any) => ({
      name: item,
      checkbox: header_list.includes(item)
    }));
    // Setting form array for columns
    this.sidePanelForm = this.fb.group({
      columnsFormArr: this.fb.array(
        check_list.map((item: any) => this.fb.group({
          name: [item],
          checkbox: [this.calcHeaders.includes(item)]
        }))
      )
    });
    this.headersApply()
  }

  // Get Columns Form Panel (Formarray)
  columnsFormArrForm(): FormArray {
    return this.sidePanelForm.get('columnsFormArr') as FormArray;
  }

  // Atleast One column should be checked
  onCheckboxChange(index: number) {
    const formArray = this.columnsFormArrForm();
    const checkboxControl = formArray.at(index).get('checkbox');
    const checkedItems = formArray.controls.filter(ctrl => ctrl.get('checkbox')?.value);
    if (checkedItems.length === 0) {
      // If it's the last item being unchecked, recheck it
      checkboxControl?.setValue(true, { emitEvent: false });
    }
  }

  // Filter Setup
  // Filter Panel ON and OFF
  panel(value: any) {
    this.viewDetail = value
  }

  // Reset Filter Filter
  resetFilterFn(val:any){
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    this.resetFilterPop = val
    if (typeof val !== 'boolean') {
      this.reportInitialSetups()
    }else{
      this.resetFrom = 'filterReset'
    }
  }

  // Reset Filter Normal
  reportFnCall(reprt:any){
    this.formData = ''
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    if(typeof this.resetFilterPop !== 'boolean' && Object.keys(this.lvServ.data_filter)?.length == 0){
      this.reportInitialSetups()
      this.filterSubmitMem = false
    }else{
      if(Object.keys(this.lvServ.data_filter)?.length > 0){
        this.resetFilterPop = true
        this.resetFrom = 'filterReset'
      }
    }
  }

  // Each report filter output
  FilterApiConfig(res:any){
    this.limit = res?.limit;
    this.offset = res?.offset;
    this.formData = res?.filterForm
    this.filterSubmitMem = !this.formData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
    this.resetFilterFn(res)
    this.isSorted = true
    this.viewDetail = false
  }

  // Filter SAVE
  filterMemorization(curr:any){
    let isColumnVal = this.addedColumns || this.lvServ.header_filter?.length > 0
    let isFilterVal = this.filterSubmitMem || Object.keys(this.lvServ.data_filter)?.length > 0
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) =>{
      return this.displayedColumns.indexOf(a) - this.displayedColumns.indexOf(b)
    })
    let dataFilter = this.reportDataFunctions[this.reportName]?.() ?? "";
    //dataFilter is not null, undefined, or empty setup
    dataFilter = dataFilter || JSON.stringify({});
    let apiDataFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterReset', set to empty object
      apiDataFilter = JSON.stringify({});
    } else if (this.resetFrom === 'columnReset') {
        // If resetFrom is 'columnReset', check data_filter length
        if (Object.keys(this.lvServ.data_filter)?.length > 0) {
            apiDataFilter = dataFilter;
        } else {
            // If data_filter is empty, set to empty object
            apiDataFilter = JSON.stringify({});
        }
    } else if (isFilterVal === false) {
        // If isFilterVal is false, set to empty object
        apiDataFilter = JSON.stringify({});
    } else {
        // Default case: set to dataFilter
        apiDataFilter = dataFilter;
    }
    let apiHeaderFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterreset' and header_filter has items, set to data
      if (this.lvServ.header_filter?.length > 0) {
        apiHeaderFilter = JSON.stringify(sortedCheckedItems);
      } else {
        // Otherwise, set to empty array
        apiHeaderFilter = JSON.stringify([]);
      }
    } else if (isColumnVal || this.colRearranged) {
      // If not resetting, check if addedColumns or header_filter has items
      apiHeaderFilter = JSON.stringify(sortedCheckedItems);
    } else {
      // Default case: empty array
      apiHeaderFilter = JSON.stringify([]);
    }
    this.rprtServ.filterMemorize({ 'data_filter': apiDataFilter, 'header_filter': apiHeaderFilter, 'is_active': true, 'report': Number(this.reportId) }).subscribe((res: any) => {
      if(curr == 'tag'){
        this.notify.handleSuccessNotification("Updated Successfully","Success")
      }else{
        this.notify.handleSuccessNotification("Created Successfully","Success")
      }
      if(curr == 'reset'){
        if(this.resetFrom == 'filterReset'){
          this.lvServ.setDataFilter({})
          this.filterSubmitMem = false
          this.isSorted = true
          this.initialLoaded = true
        }else{
          this.lvServ.setHeaderFilter([])
          this.addedColumns = false
          this.isSorted = false
        }
      }else{
        this.filterSubmitMem = false
        this.addedColumns = false
      }
      this.applyFilterPop = false
      this.colRearranged =  false
      this.saveFilterModalClicked = false
      this.resetFrom = ''
      this.page = 1
      this.pageNumber = 0
      this.offset = 0
      this.limit = 20
      this.reportInitialSetups()
      if(curr == 'save'){
        this.router.navigate(['/leave-report']);
      }
    })
  }

  // Save data format from child
  reportDataFunctions: { [key: string]: () => any } = {
    'Leave Balance On A Particular Day Report': () => this.balanceParticularDay.getData(),
    'Leave Availed Report': () => this.availedReport.getData(),
    'Leave Carry Forward Report': () => this.carryForwadReport.getData(),
    'Leave Credit Report': () => this.creditReport.getData(),
    'Leave Encashment Report': () => this.encashmentReport.getData(),
    'Lapsed Leaves Report': () => this.lapsedReport.getData(),
    'Leave Ledger Report': () => this.ledgerReport.getData(),
    'Unpaid Leave Report': () => this.lopReport.getData(),
    'Negative Leave Balance Report': () => this.negativeReport.getData(),
    'Leave Request Status Report': () => this.requestReport.getData(),
    'Leave Balance Summary Report': () => this.balanceReport.getData()
  };

  // Popups
  // Filter Mem button
  saveFilterModal(){
    this.saveFilterModalClicked = true;
    if(this.filterSaveForm.get('filter')?.value == true){
      this.filterMemorization('save')
    }else{
      this.filterSubmitMem = false
      this.addedColumns = false
      this.colRearranged = false
      setTimeout(()=>{
        this.router.navigate(['/leave-report']);
        this.cd.detectChanges()
      },0)
    }
  }

  // Reset Saved Filter button
  resetFilterApply(){
    this.viewDetail = false
    this.filterMemorization('reset')
    // Filter reset
    if(this.resetFrom == 'filterReset'){
      this.resetFilterControllers()
    }
    this.resetFilterPop = false
  }

  // Reset from Parent
  resetFilterControllers(): void {
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'Leave Balance On A Particular Day Report': () => this.balanceParticularDay.resetFilterControllers('reset'),
      'Leave Carry Forward Report': () => this.carryForwadReport.resetFilterControllers('reset'),
      'Leave Availed Report': () => this.availedReport.resetFilterControllers('reset'),
      'Leave Credit Report': () => this.creditReport.resetFilterControllers('reset'),
      'Leave Encashment Report': () => this.encashmentReport.resetFilterControllers('reset'),
      'Negative Leave Balance Report': () => this.negativeReport.resetFilterControllers('reset'),
      'Lapsed Leaves Report': () => this.lapsedReport?.resetFilterControllers('reset'),
      'Unpaid Leave Report': () => this.lopReport.resetFilterControllers('reset'),
      'Leave Balance Summary Report': () => this.balanceReport.resetFilterControllers('reset'),
      'Leave Ledger Report': () => this.ledgerReport.resetFilterControllers('reset'),
      'Leave Request Status Report': () => this.requestReport.resetFilterControllers('reset')
    };

    if (this.reportName in reportActions) {
      reportActions[this.reportName]();
    }
  }

  // Close Button
  confirm(): boolean {
    if(this.filterSubmitMem == true || this.addedColumns == true || this.colRearranged == true){
      this.applyFilterPop = true
      return false
    }else{
      return true
    }
  }

  // Download Excel
  export(){
    this.limit = this.lastPageCount
    this.offset = 0
    this.isSorted = true
    this.reportDownload = true
    this.reportInitialSetups()
  }

  // Download arrangements
  commonLogic(data: any) {
    let column = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject: any = {};
      for (const key of column) {
        if (obj.hasOwnProperty(key)) {
          newObject[key] = obj[key];
        }
      }
      newArray.push(newObject);
    }
    if (data != undefined)
      this.exportExcel(newArray, this.reportName);
    this.cd.detectChanges()
  }

  public exportExcel(jsonData: any[], fileName: string): void {
    let excelHeaders = []
    let templateToExcel :any = []
    if (!this.noData) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, fileName);
    }
    else {
      for (let i = 0; i < this.displayedColumns.length; i++) {
        excelHeaders.push(this.displayedColumns[i])
        templateToExcel = [excelHeaders, []];
      }
      const wss: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(templateToExcel);
      const wbb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
      XLSX.writeFile(wbb, fileName + '.xlsx')
    }
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, fileName + '.xlsx');
  }

  // Table COlumn Drop
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    if(this.displayedColumns?.length > 1){
      this.colRearranged = true
      this.calcHeaders = this.displayedColumns
    }
  }

  // Sort
  onSort(val: any) {
    this.direction = (this.sortProperty === val && this.direction === 'desc') ? 'asc' : 'desc';
    this.sortProperty = (this.sortProperty === val) ? this.sortProperty : val;
    this.ordering = `${this.direction === 'desc' ? '-' : ''}${val}`;
    this.formData = this.formData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Api call with updated Limit
  pageChanged(val: any) {
    this.pageNumber = val - 1
    this.offset = this.rprtServ.calculateOffset(val - 1)
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Tags
  // Show/Hide close icon
  isFilterVisible(reportName: string, filterKey: any): boolean {
    const reportFilters : { [key: string]: string[] } = {
      'Leave Balance On A Particular Day Report': ['selected_date'],
      'Leave Carry Forward Report': ['period'],
      'Leave Availed Report': ['transaction_date', 'leave_policy', 'leave_type'],
      'Leave Credit Report': ['leave_credit_for'],
      'Leave Encashment Report': ['period', 'leave_policy'],
      'Negative Leave Balance Report': ['selected_date'],
      'Lapsed Leaves Report': ['period'],
      'Unpaid Leave Report': ['transaction_date'],
      'Leave Balance Summary Report': ['leave_period'],
      'Leave Ledger Report': ['transaction_date', 'from_date', 'to_date'],
      'Leave Request Status Report': ['leave_period', 'leave_type', 'status']
    };

    return reportName in reportFilters && !reportFilters[reportName].includes(filterKey);
  }

  // Call child fn for tag close
  handleTagClose(reportName: string, filterKey: any): void {
    this.closeControl = filterKey === 'employment_type' ? 'employee_type' : filterKey;
    this.cd.detectChanges()
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'Leave Balance On A Particular Day Report': () => this.balanceParticularDay.applyTagClose1(),
      'Leave Carry Forward Report': () => this.carryForwadReport.applyTagClose1(),
      'Leave Availed Report': () => this.availedReport.applyTagClose1(),
      'Leave Credit Report': () => this.creditReport.applyTagClose1(),
      'Leave Encashment Report': () => this.encashmentReport.applyTagClose1(),
      'Negative Leave Balance Report': () => this.negativeReport.applyTagClose1(),
      'Lapsed Leaves Report': () => this.lapsedReport?.applyTagClose1(),
      'Unpaid Leave Report': () => this.lopReport.applyTagClose1(),
      'Leave Balance Summary Report': () => this.balanceReport.applyTagClose1(),
      'Leave Ledger Report': () => this.ledgerReport.applyTagClose1(),
      'Leave Request Status Report': () => this.requestReport.applyTagClose1()
    };

    if (reportName in reportActions) {
      reportActions[reportName]();
    }
    if(Object.keys(this.lvServ.data_filter)?.length > 0){
      this.filterMemorization('tag')
    }
  }

  // Utilities Used
  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Sort attribute for response
  setDataSourceAttributes() {
    if (this.apiData != undefined && this.apiData != null) {
      this.apiData.sort = this.sort;
    }
  }

  // More than 1 item in array
  tagMultiDataFunction(data: any) {
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if (data?.length == 1) {
      return this.tagMultiData;
    } else {
      this.tagMultiCnt = '+' + JSON.stringify(data?.length - 1);
      return this.tagMultiData;
    }
  }

  // Tage Underscore
  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  // Excess data on click of tags
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  // MatDatePicker
  salFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // DOJ based filter date
  dojFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // HTML for date split
  getDateRangeToDisplay(dateSetup: any, key: any): string {
    const dates = dateSetup?.[key];
    return dates
      ? `${this.appService.dateFormatDisplay(dates[0])} - ${this.appService.dateFormatDisplay(dates[1])}`
      : '';
  }

  // Date Format
  getDateFormat(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const dateFormat = this.appService.getdatepickerformat();
        if (dateFormat !== '') {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }
}
