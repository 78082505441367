
  <div class="row row-12">
    <div class="col-12 d-flex position-relative">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}</span>

      <button *ngIf="from!='behalf'" class="btn-reset ms-auto btn-star text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
        (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8">
        <h5 class="mb-8">Employee Data Approval</h5>
        <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
          *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
        <span class="badge px-16 ms-auto fs-14 badge-success py-1"
          *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
        <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
          *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
        <span class="text-light-400">|</span>
        <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
      </div>

      <span class="badge-custom {{requestitem.request_type_color_code}}">Employee Data</span>
      <span
        *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
        class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <ng-container  *ngIf="from!='behalf'">

      <ng-container *ngIf="requestitem.level == 1">
        <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Approved'">
          <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
            <i class="bi fs-16 bi-check-square-fill text-success "></i>
            <span class="">Approved by </span>
            <span class="fw-600">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id
              ?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
          </div>
        </div>
        <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Rejected'">
          <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
            <i class="bi fs-16  bi-x-square-fill text-danger "></i>
            <span class="">Rejected by </span>
            <span class="fw-600">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id
              ?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
          </div>
        </div>
        <div class="col-12" *ngIf="requestitem.approved_person_list[0]['status'] == 'Pending'">
          <div class="card card-c2 p-16 fs-12 flex-row align-items-center gap-8">
            <i class="bi fs-16  bi-x-square-fill text-warning "></i>
            <span class="">Pending with </span>
            <span class="fw-600">{{requestitem.approved_person_list[0]['id'] == requestitem.to_person.id ||
              requestitem.approved_person_list[0]['id'] ==
              null?'You':requestitem.approved_person_list[0].approved_person_name}}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="requestitem.level > 1">
        <div class="col-12">
          <div class="card card-c2 px-16 pt-16 pb-8">
            <div class="dropdown_progres">
              <ul class="progress_drp progress_vertical">
                <ng-container *ngFor="let item of requestitem.approved_person_list;let i = index">
                  <ng-container
                    *ngIf="requestitem.approved_person_list[i]['level'] < requestitem.level || requestitem.approved_person_list[i]['level'] == requestitem.level ">
                    <li class="progress__item progress__item--accepted"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Approved'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Approved by </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] ==
                            requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="progress__item progress__item--rejected"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Rejected'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Rejected by </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] ==
                            requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="progress__item progress__item--pending"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Pending'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Pending with </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] == requestitem.to_person.id ||
                            requestitem.approved_person_list[i]['id'] ==
                            null?'You':requestitem.approved_person_list[i].approved_person_name}}</div>
                        </div>
                      </div>
                    </li>
                    <li class="progress__item progress__item--intimation"
                      *ngIf="requestitem.approved_person_list[i]['status'] == 'Intimation'">
                      <div class="progress__tile  ">
                        <div class="fs-12 text-center">
                          <div class="">Intimation sent to </div>
                          <div class="">{{requestitem.approved_person_list[i]['id'] ==
                            requestitem.to_person.id?'You':requestitem.approved_person_list[i].approved_person_name}}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-container>

                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="col-12">
        <p class="mb-0 fs-14" [innerHTML]="requestitem.message">
        </p>
      </div>
    </ng-container>
    <app-view-employee-profile [requestitem]="requestitem.employee_profile" [loader]="loader" [from]="from"
      (successEmitter)="refreshInbox($event)" [documentSingleData]="requestitem">
    </app-view-employee-profile>


    <ng-container [formGroup]="form" *ngIf="(from!=='behalf' && !requestitem?.achieved_status) || (from=='behalf' && requestitem['error_message']==null && !requestitem?.achieved_status)">
      <div class="col-12">
        <textarea name="" id="" rows="3" placeholder="{{this.messageService.descriptiontext('comment')}}"
          [ngClass]="{ 'is-invalid': f.comment.errors}" class="form-control textarea-comment"
          formControlName="comment"></textarea>
        <div *ngIf="f.comment.errors" class="invalid-feedback">
          <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
        </div>
      </div>
      <div class="col-12 d-flex gap-16">
        <button *ngIf="requestitem?.employee_profile?.category!='document'" type="button"
          class="ms-auto btn btn-outline-danger text-uppercase btn-sm fw-400" (click)="confirm('Rejected')">
          Decline
        </button>
        <button *ngIf="requestitem?.employee_profile?.category!='document'"
          class="btn btn-primary text-uppercase btn-sm fw-400" (click)="confirm('Approved')">
          Confirm
        </button>
        <button *ngIf="requestitem?.employee_profile?.category=='document'"
          class="ms-auto btn btn-primary text-uppercase btn-sm fw-400" (click)="confirm('Approved')">
          Submit
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="from=='behalf' && requestitem['error_message']!=null">
      <div class="alert alert-danger show" role="alert">
      {{requestitem['error_message']}}
      </div>
    </ng-container>
  </div>
<!-- </div> -->


<!-- Alert for employee request start -->
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{empalertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div>

<!-- Doc Preview Start -->
<div class="modal  modal-pdfviewer modal-custom {{modalToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">{{currentDocName}}</h4>
        <i (click)="modalToggle = false" class="ms-auto bi b bi-x-lg text-light-500"></i>
      </div>
      <div class="modal-body px-0">
        <div class="pdf-container" *ngIf="currentExtension=='pdf' ">
          <pdf-viewer [src]="fileName" [original-size]="false" style="height: calc(100% - 1.375rem)!important;">
          </pdf-viewer>
        </div>
        <img
          *ngIf="currentExtension!='pdf' && currentExtension!='zip' && currentExtension!='docx' && currentExtension!='xlsx' && currentExtension!='csv'"
          [src]="fileName" style="padding: 1.3125rem;width: 100%;">
      </div>
    </div>
  </div>
</div>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>

      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"
        [from]="fromViewStatus"  [sendReminder]="sendReminder" [requestId]="requestitem?.request_data?.id" (closePanel)="viewstatusPanel=false"></app-view-status>



    </form>
  </div>
</ng-container>
<!-- end view status panel -->
