import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators} from '@angular/forms';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-expense-report-inbox',
  templateUrl: './expense-report-inbox.component.html',
  styleUrls: ['./expense-report-inbox.component.scss']
})
export class ExpenseReportInboxComponent implements OnInit {

  @Input() requestitem:any;
  @Input() from:any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  pageSize = 20;
  page = 1

  viewReciept = false;
  viewRecieptLoader = false;
  viewRecieptData:any = [];
  activeMenu = "Expense"

  viewAdvance   = false;
  advanceid     = 0;
  viewLoader    = false;
  viewdata:any  = [];

  viewAttchments          = false;
  receiptId = 0
  uploadedAttachments:any  = []

  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })
  sendReminder:any
  get f() {return  this.form.controls; }

  constructor(
    private inboxservice: InboxService,private investmentService:InvestmentDeclerationService,public appService: AppService,private ExpenseReportRequestService:ExpenseReportRequestService,private fb: FormBuilder,public messageService : MessageService) { }

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
  }
  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{
        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
              if(this.requestitem['id'] == id){
                this.requestitem[key] = value;
              }
          })
        }
      })
    }
  }

  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }

    // Receipt View
    detailedReceiptView(id:any){
      this.viewRecieptLoader = true;
      this.viewReciept = true;
      this.getSigleReceiptView(id);
    }

    getSigleReceiptView(id:any){
      this.viewRecieptData = [];
      this.ExpenseReportRequestService.getSingleReceiptData(id).subscribe((res:any)=>{
        this.viewRecieptData = res;
        this.viewRecieptLoader  = false;
      })
    }


    confirm(status:any){
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }
      if(this.from=='behalf'){
        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);

      }
      else{
        this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(true);
          }
        })
      }
    }
    viewAdvanceFunction(id:any){
      this.viewLoader  = true;
      this.viewAdvance = true;
      this.advanceid   = id;
      this.viewdata    = [];
      this.ExpenseReportRequestService.getAdvanceDetailedView(this.advanceid).subscribe((res: any) => {
        this.viewdata   = res;
        this.viewLoader = false;
     });
    }

    viewAttachmentFunction(index:any,id:any){
      this.viewAttchments      = true;
      this.receiptId           = id;
      this.uploadedAttachments = this.requestitem?.requests?.expense_report_data?.expense_receipts[index].attachments;
    }


}
