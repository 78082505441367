import { Component, OnInit,Output,EventEmitter, Input} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-expense-report-advance-details',
  templateUrl: './expense-report-advance-details.component.html',
  styleUrls: ['./expense-report-advance-details.component.scss']
})

export class ExpenseReportAdvanceDetailsComponent implements OnInit {

  @Input() advanceListData:any;
  @Input() advanceListLoader:any;
  @Input() currency:any;
  @Input() formGp:any;
  @Input() from:any;
  @Input() empId:any

  @Output() updatedadvancelist   = new EventEmitter();
  @Output() submitmethod         = new EventEmitter();


  page              = 1;
  pageSize          = 20;
  searchString: any = "";

  applyAdvance     = false;
  addAdvanceLoader = false;
  @Input()applyAdvanceData :any;

  viewAdvance  = false;
  advanceid    = 0;
  viewLoader   = false;
  viewdata:any = [];

  constructor(
    public fb:FormBuilder,
    public appService: AppService,
    public messageService : MessageService,
    private service:ExpenseReportRequestService,
    public activityService:ManageActivityService
  ) { }

  ngOnInit(): void {
  }

  removeIndex   = 0;

  deleteToggle  = false;
  deleteClicked = false;

  removeAdvance(index:any,data:any){

    this.deleteToggle = true;

    this.removeIndex = index;
  }

  deleteAdvance(){

    this.deleteToggle = false;

    if (this.removeIndex !== -1) {

      this.advanceListData?.splice(this.removeIndex, 1);

      // Update the receipts in Report
      let advances = this.advanceListData?.map((item1 :any)=> {
        return item1?.id;
      });

      this.updateExpenseReport({'advances': advances});
      // End
    }

  }

  updateExpenseReport(data:any){
    this.advanceListLoader = true;
    this.advanceListData   = [];
    this.service.updateExpenseReport(this.formGp.get('id')?.value,data).subscribe((res:any)=>{
      this.advanceListData = res?.advances;
      this.formGp.get('total_amount')?.setValue(res?.total_amount);
      this.formGp.get('to_reimbursed')?.setValue(res?.to_reimbursed);
      this.updatedadvancelist.emit({'advancelist': this.advanceListData,'advanceadd': this.applyAdvanceData,'total_amount': res?.total_amount,'to_reimbursed':res?.to_reimbursed})
      this.advanceListLoader = false;

      let lastpage = Math.ceil(this.advanceListData?.length / this.pageSize);
      if(this.page>lastpage){
        this.page = lastpage>0?lastpage:1
      }

    });
  }


  getApplyAdvanceData(){
    this.uncheck();
    this.applyAdvance       = true;

    this.addAdvanceLoader  = true;
    this.applyAdvanceData   = [];
    if(this.from=='behalf'){

      this.activityService.advanceList(this.empId).subscribe((res:any)=>{
        this.applyAdvanceData          = res;
        this.addAdvanceLoader         = false;

      })
    }
    else{
      this.service.advanceList().subscribe((res:any)=>{
        this.applyAdvanceData          = res;
        this.addAdvanceLoader         = false;

      })
    }
  }

  uncheck(){
    if(this.applyAdvanceData?.length){
        this.applyAdvanceData?.forEach((item:any, key:any) => {
          item.checked = false;
        })
    }
  }

  addadvance(data:any){
    this.applyAdvance      = false;
    // this.advanceListLoader = true;

    // const c = [...this.advanceListData, ...data?.addedadvance];
    // this.advanceListData = [...new Set(c)];


    // const newArray = this.applyAdvanceData.filter(({ id }:any) => !data?.addedadvance.some((e:any) => e.id === id));
    // this.applyAdvanceData = newArray;

    let advances = this.advanceListData?.map((item1 :any)=> {
      return item1?.id;
    });

    const c = [...advances, ...data?.addedadvance];
    advances = [...new Set(c)];

    this.updateExpenseReport({'advances': advances});

    // this.getSingleReportData();

    // this.updatedadvancelist.emit({'advancelist': this.advanceListData, 'advanceadd': this.applyAdvanceData})


    // this.advanceListLoader = false;

  }

  getSingleReportData(){

    this.advanceListLoader = true;
    this.advanceListData = [];
    this.service.getSingleReport(Number(this.formGp.get('id')?.value)).subscribe((res: any) => {
      this.advanceListData = res?.advances;
      this.formGp.get('total_amount')?.setValue(res?.total_amount);
      this.formGp.get('to_reimbursed')?.setValue(res?.to_reimbursed);
      this.updatedadvancelist.emit({'advancelist': this.advanceListData, 'advanceadd': this.applyAdvanceData,'total_amount': res?.total_amount,'to_reimbursed':res?.to_reimbursed})
      this.advanceListLoader = false;

    });
  }

  closeadvance(){
    this.applyAdvance = false;
  }

  viewAdvanceFunction(id:any){

    this.viewLoader  = true;
    this.viewAdvance = true;
    this.advanceid   = id;
    this.service.getAdvanceDetailedView(this.advanceid).subscribe((res: any) => {
      this.viewdata   = res;
      this.viewLoader = false;
   });

  }

  closefnview(data:any){
    this.viewAdvance = data;
  }


  changeTab(){
    this.submitmethod.emit();
  }

}
