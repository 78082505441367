<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{headerText}} Leave Policy</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step"
            [class]="{'success': policyForm['controls'].policyDetails.valid || policyForm['controls'].policyDetails.disabled}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Leave Policy Details</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-leave-policy-details [formGp]="policyDetailsForm" [editflag]="editflag" [id]="id"
              [leaveType]="leaveType" (submitNext)="submitNext()" (checkboxEvent)="checkboxEvent($event,'add')"
              (checkboxCount)="checkboxCount($event)"></app-leave-policy-details>


          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step" [class]="{'success': mapLeaveType.length!=0 && step2Validity()}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Configure Leave Type</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-leave-policy-configure-leave-type [mapLeaveType]="mapLeaveType" [leaveConfigForm]="configureForm"
              [policyForm]="policyForm" (submitmethod)="submitTwo()" (duplicateCheck)="checkDuplicateRule()"
              [is_valid]="is_valid" [editData]="editData"
              [activeTabConfig]="activeTabConfig"></app-leave-policy-configure-leave-type>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="step"
            [class]="{'success': policyForm['controls'].leave_policy_leaveendprocess.valid || policyForm['controls'].leave_policy_leaveendprocess.disabled}">
            <div class="count">3</div>
            <div class="content">
              <span>Step 3</span>
              <h6>Leave Year End Settings</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-leave-year-end-settings [formGp]="leaveYearEndForm" [policyDetailsForm]="policyDetailsForm"
              (submitmethod)="submitThree()"></app-leave-year-end-settings>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink class="step"
            [class]="{'success': policyForm['controls'].assign.valid || policyForm['controls'].assign.disabled}">
            <div class="count">4</div>
            <div class="content">
              <span>Step 4</span>
              <h6>Applicability</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-common-applicability [heading]="'Applicability'" [disabled]="disabled" [isEditForm]="editflag" [formGp]="assignForm" (submitMethod)="submitForm()" [hint]="'Assign this leave policy to the appropriate OU or for specific or multiple employees.'"></app-common-applicability>
          </ng-template>
        </li>

      </ul>

    </aside>
    <main class=" config-container">
      <!-- <pre>Form Values: {{policyForm.value | json}}</pre> -->
      <!-- <pre>activeTabConfig: {{activeTabConfig | json}}</pre> -->
      <div [ngbNavOutlet]="nav"></div>

    </main>
  </ng-container>
</div>
