import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLetterProfileService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  empletterList(status:any,limit:any,offset:any,search:any,generated_year:any){
      return this.http.get(`${this.API_PATH}letters_templates/api/v1/employee-letter-list/?status=${status}&limit=${limit}&offset=${offset}&search=${search}&generated_year=${generated_year}`, this.httpOption)   
  }
  
  empletterListData(status:any,search:any,generated_year:any){
      return  this.http.get(`${this.API_PATH}letters_templates/api/v1/employee-letter-dropdown/?status=${status}&ordering=name&generated_year=${generated_year}&search=${search}`, this.httpOption);   
  }

  getOneemp(id:number){
    return this.http.get(`${this.API_PATH}letters_templates/api/v1/employees-letters-detail/${id}/`, this.httpOption)
  }


}

