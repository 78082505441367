import { Component, Input, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';

@Component({
  selector: 'app-final-settlement-process-inbox',
  templateUrl: './final-settlement-process-inbox.component.html',
  styleUrls: ['./final-settlement-process-inbox.component.scss']
})
export class FinalSettlementProcessInboxComponent implements OnInit {

  @Input() requestitem: any;

    // View Status
    billLoader                      = false;
    viewstatusPanel                 = false;
    viewStatusData:any              = [];
    viewStatusLevelCnt:any;
    viewStatusmaxLevel:any;


  constructor(
    private inboxservice: InboxService,
    public prevRouter: PreviousRouteService,
    public router: Router,
    private investmentService:InvestmentDeclerationService
  ) { }

  ngOnInit(): void {
    this.prevRouter.setPreviousUrl(this.router.url);
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let dataArray: any = [];
    if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
        if (res) {
          Object.entries(dataArray).forEach(([key, value]) => {
            if (this.requestitem['id'] == id) {
              this.requestitem[key] = value;
            }
          })
        }
      })
    }
  }

  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
    }
}
