import { Component, OnInit, Output,EventEmitter, Input, HostListener, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

// import { EventEmitter } from 'stream';
@Component({
  selector: 'app-am-configure-flow',
  templateUrl: './am-configure-flow.component.html',
  styleUrls: ['./am-configure-flow.component.scss']
})
export class AmConfigureFlowComponent implements OnInit {
  level:number = 0;
  sLevel = 0;
  @Input()submitted:boolean=false;
  @ViewChild('ngSelector') ngselect: any;

  validated : boolean = false
  approvalUnit : any =[]
  id:any
  value:any
  private subjectKeyUp = new Subject<any>();


  constructor(public fb:FormBuilder,public messageService : MessageService,private notificationService: NotificationService, private existDataService : ExistDatasService, public route: ActivatedRoute) {
  }
  ngOnInit(){
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.id = this.route.snapshot.params['id']
    if(this.roles().length == 0){
      this.addRoles();
    }
    if(this.disabled == true){
      this.roles().disable();
    }
  }
  @Input()formGp : FormGroup = this.fb.group({
    name: '',
    description: ['']
  });
  @Input()roleDropdown:any=[];
  @Input()disabled = false;
  @Input() editflag:any

  @Output()
  submitmethod = new EventEmitter();
  // unamePattern =  "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";
  // descriptionPattern =  "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"\-\=]*(?<! )$";

  get f() {return  this.formGp.controls; }


  @HostListener('window:resize', ['$event'])
  sizeChange(event : any | null) {
    this.ngselect.close();
  }

  validateConfigureFlow() {
    this.submitted = true;

    // if(this.formGp.value.name.length > 100){
    //   this.formGp.controls['name'].setErrors({ incorrect: true, message: ''});
    //   this.notificationService.handleErrorNotification("Maximum 100 characters allowed.","Error");
    //   return;
    // }
    // if(this.formGp.value.description.length > 500){
    //   this.formGp.controls['description'].setErrors({ incorrect: true, message: ''});
    //   this.notificationService.handleErrorNotification("Maximum 500 characters allowed.","Error");
    //   return;
    // }
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  roles() : FormArray {
    return this.formGp.get("roles") as FormArray
  }

  newRole(): FormGroup {
    this.level = this.roles().length+1;
    
    return this.fb.group({
      "days": [0, [Validators.required,NumberpatternValidator(),Validators.maxLength(3)]],
      "level":this.level,
      "intimation": false,
      "role": [null, [Validators.required]],
      "due_days": [0, [NumberpatternValidator(),Validators.maxLength(3)]]

    })
  }

  addRoles() {
    this.roles().push(this.newRole());
    
  }

  removeRole(i:number) {
    this.roles().removeAt(i);
  }
  showLevel(i:number){
    this.sLevel = 0;
    for(let x=0; x<=i ; x++){
      if(this.roles().controls[x].value.intimation == true){
        this.sLevel++;
      }
    }
    this.roles().controls[i].value.level=i-this.sLevel+1
    if(this.roles().controls[i].value.intimation == true)
      return '';
    else
      return i-this.sLevel+1;

  }

  // Simplified function to use for validation
  existService(){

    if(!this.editflag){
      this.value ={
        "page":"ApprovalMatrix",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"ApprovalMatrix",
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.approvalUnit = res.body

      if(this.approvalUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)

  keyUp(){

    this.subjectKeyUp.next(this.f.name.value)

  }

}
