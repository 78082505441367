import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AttendanceCapturePolicyService } from 'src/app/@core/services/attendance-capture-policy.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-attendance-capture-policy',
  templateUrl: './attendance-capture-policy.component.html',
  styleUrls: ['./attendance-capture-policy.component.scss']
})

export class AttendanceCapturePolicyComponent implements OnInit {
  viewDetail = false;
  attendancecaptureArr :any = [];
  attendancecaptureArrList : any = []
  status_filter :any = true;
  statusBtn:any = "Active";
  deleteAlert = false;
  searchString = this.messageService.searchdisplay('Attendance capture policy');
  searchlistdata :any = []
  loader = true;
  viewLoader = false;
  deleteClicked = false
  deleteId = 0;
  listflag = true;
  viewData:any = []
  viewApplicable: any;
  viewRightsArray : any = []
  isLoading = false
  searchData : Subject<void> = new Subject();

  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  infinityloader          = false;
  permissions:any = [];
  searchKeyword="";


  @ViewChild(SearchbarComponent) child:any;

    constructor(public router: Router,
    public  attendanceCaptureService: AttendanceCapturePolicyService,
    public appService: AppService,
    public breakpointObserver: BreakpointObserver,public messageService : MessageService,) { }

  ngOnInit(): void {
    this.getPermissions();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.attendanceCaptureList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.attendanceCaptureService.attendanceCaptureList(this.status_filter,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermissions(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermissions();
      }, 1000);
    }
  }

  attendanceCaptureList(){
    this.loader = true;
    this.attendanceCaptureService.attendanceCaptureList(this.status_filter,this.defaultlimit,this.offset,this.searchKeyword).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    if (res.results.length == 0) {
      this.listflag = (this.offset == 0) ? false :true;
      this.listlength  = 0;
    } else {
      this.listflag = true;
      this.listlength = res.results.length;
      if(this.offset ==0){
        this.attendancecaptureArr = [];
      }
      this.attendancecaptureArrList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.attendanceCaptureList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.attendancecaptureArr.push(this.attendancecaptureArrList[i]);
    }
  }

  searchAttendanceCapture(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset           = 0;
    this.attendancecaptureArr= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.attendancecaptureArr = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  selectItemList(value:any){
    this.loader = true;
    this.status_filter = value;
    this.offset = 0;
    this.attendancecaptureArr = [];
    this.child.clearBar();
  }

viewAttencecaptureDetails(id:any){
  if(this.permissions.v){
    this.viewDetail = true;
    this.viewLoader = true
    this.attendanceCaptureService.attendanceCapturegetDetail(id).subscribe((res: any) => {
      this.viewData = res;
      this.viewRightsArray = []
      for(let i in this.viewData.attendance_capture_assignright){
        let item=this.viewData.attendance_capture_assignright[i];
           item['name']  = "";
           if(item['first_name'] != null)
           item['name'] += item['first_name']+" ";
           if(item['middle_name'] != null)
           item['name'] += item['middle_name']+" ";
           if(item['last_name'] != null)
           item['name'] += item['last_name'];
        this.viewApplicable=item.applicable;
        if(item.applicable=='Company'){
           // this.viewRightsArray.push(item.company_name);
           let compName = item.company_name;
           let compImg  = item.company_logo;
           this.viewRightsArray.push({'name': compName,'img': compImg})
        }
        if(item.applicable=='BU'){
           this.viewRightsArray.push(item.businessunit_name);
        }
        if(item.applicable=='Department'){
           this.viewRightsArray.push(item.department_name);
        }
        if(item.applicable=='Designation'){
           this.viewRightsArray.push(item.designation_name);
        }
        if(item.applicable=='Employee'){
           // this.viewRightsArray.push(item.employee_name.name+" ("+item.employee_name.employee_code+")");
           let empName = item.employee_name.name+" ("+item.employee_name.employee_code+")";
           let empImg  =  item.employee_name.profile_image;
           let empclrcode =  item.employee_name.color_code;

           this.viewRightsArray.push({'name': empName,'img': empImg,'color_code':empclrcode})
        }
        if(item.applicable=='Grade'){
           this.viewRightsArray.push(item.grade_name);
        }
      }
      this.viewLoader = false;
    })
  }
}

  editAttencecapturePolicy(id:number){
    this.router.navigate(['/attendance-capture-policy/attendance-capture-policy-setup/'+id]);
  }

  confirmDelete(id:number){
    this.deleteClicked = true
    this.attendanceCaptureService.attendanceCaptureDelete({ "is_active": false},id).subscribe((res: any) => {
      this.deleteAlert = false
      this.offset            = 0;
      this.attendancecaptureArr = [];
      this.attendanceCaptureList();
      this.deleteClicked = false
    });
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/attendance-capture-policy/attendance-capture-policy-setup']);
  }

  addSufix(parent:any,child:any){
    if(this.viewData[parent]==true){
      if(Number(this.viewData[child]) > 1)
      return 'Months'
      else
      return 'Month'
    }
    else
    return 'Days'
  }
}
