import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'app-manage-documents-add-details',
  templateUrl: './manage-documents-add-details.component.html',
  styleUrls: ['./manage-documents-add-details.component.scss']
})
export class ManageDocumentsAddDetailsComponent implements OnInit {

  @Input() formGp   : any
  @Input() dataId:any
  @Input() categoryList:any = []
  @Output() submitmethod    = new EventEmitter()


  fileType: string          = "";
  docURL: any               = "";
  docName: any              = "";
  fileSize: any             = "";
  docLoader                 = false;
  fileError                 = false;
  fileErrorMsg: any         = "";
  modalToggle               = false;
  currentDocName            = "";
  currentExtension          = "";
  isupdate                  = false;
  documentUnit : any        = []

  value:any
  routeComponent : any
  private subjectKeyUp = new Subject<any>();



  constructor(

    public messageService: MessageService,
    public appservice: AppService,
    public existDataService :  ExistDatasService,


  ) { }
  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(600)).subscribe((d:any)=>{
      this.existService();
    })
  }
  get f() { return this.formGp.controls; }

  existService(){

    if(this.dataId==0){
      this.value ={
        "page":'DocumentRepositoryPolicy',
        "field":"subject",
        "value": this.f.subject.value
      }
    }else{
       this.value ={
        "id":this.dataId,
        "page":'DocumentRepositoryPolicy',
        "field":"subject",
        "value": this.f.subject.value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.documentUnit = res.body
      if(this.documentUnit.data ==='invalid'){
        this.f.subject.setErrors({
          'exist' : true
        })
      }
    })
  }
  keyUp(){

    this.subjectKeyUp.next(this.f.subject.value)
  }
  getURL(event: any) {
    this.docURL = event;
    this.fileError = false;
    this.formGp.get('doc_policy_attachment')?.setValue(event);
    this.docLoader = false;

  }

  getfileformat(event: any) {
    this.formGp.get('doc_policy_attachment')?.setValue('');
    this.docLoader = false;
    if (event == false) {
      this.fileError = true;
      this.fileErrorMsg = this.messageService.validateFileFormat('pdf,doc');
    }

  }
  getFilename(event: any) {
    this.docName = event.name;
    this.fileSize = this.appservice.formatSizeUnits(event.size);
    window.sessionStorage.setItem('this.docName', event.name);
  }

  getloader(event: any) {
    this.fileErrorMsg = '';
    this.fileError    = false
    this.docLoader    = event;
  }
  getvalidsize(event: any) {
    this.formGp.get('doc_policy_attachment')?.setValue('');
    this.docLoader    = false;
    this.fileError    = true;
    this.fileErrorMsg = event;
  }
  updateFile(){
    this.isupdate = true;
    this.formGp.get('doc_policy_attachment')?.setValue('');

  }
  submitStep1(){
    if(this.formGp.invalid)
    return
    else{

      this.submitmethod.emit();
    }


  }
  viewDocument(filename:any,docType:any){

    this.modalToggle      = true;
    this.currentDocName   = docType;
    this.currentExtension = filename?.split('.').pop();

  }
  close(bool:any){
    this.modalToggle = bool;
  }


}
