import { Component, OnInit, SimpleChange } from '@angular/core';
import {Location} from '@angular/common';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HolidayPolicyService } from 'src/app/@core/services/holiday-policy.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-holiday-policy-setup',
  templateUrl: './holiday-policy-setup.component.html',
  styleUrls: ['./holiday-policy-setup.component.scss']
})
export class HolidayPolicySetupComponent implements OnInit {
  activeTab = 1;
  res:any;
  id: number = 0;
  iseditForm=false;
  loader = false;
  disabled = false;
  action_header = 'Add';
  prevYear: any;
  editflag: boolean = false
  resp : any
  public data : any
  validFlag: any;
  holidayMappingArray:any = [];
  isFutureHolday = false;
  hint = "Select the OUs to which this holiday policy will be applicable. Users can select multiple OUs using the check box."

  constructor(
    private _location: Location,
    private fb: FormBuilder,
    public router: Router,
    public route:ActivatedRoute,
    private hps: HolidayPolicyService,
    private notificationService: NotificationService
    ) { }


  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }

  holidayForm: FormGroup = this.fb.group({
    id: 0,
    is_requested: false,
    policydetails: this.fb.group({
    holiday_policy_name: [null,[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    year: [null,Validators.required],
    is_floating_holiday : [false],
    max_floating_request: [null],
    prorate_floating_holiday :[false],
    prorated_holiday_policy:this.fb.array([]),
    }),

    holiday_policy:[null, [Validators.required]],
    assign: this.fb.group({
      applicableto: [null, [Validators.required]],
      assignees: [null, [Validators.required]]
    })
  })


  submitOne(){
    this.activeTab = 2;
  }
  submitTwo(){
    this.checkUnmappedFutureDates();
    this.activeTab = 3;
  }
  get policy_details(){
    return this.holidayForm.get('policydetails') as FormGroup;
  }
  get assignForm() {
    return this.holidayForm.get('assign') as FormGroup;
  }
  get holidayPolicy()  {
    return this.holidayForm.get("holiday_policy") as FormArray;
  }

  get holidayPolicyName(){
    return this.holidayForm.get('holiday_policy_name') as FormControl;
  }
  get year(){
    return this.holidayForm.get('year') as FormControl;
  }
  get max_floating_request(){
    return this.holidayForm.get('max_floating_request') as FormControl;
  }

  prorated_holiday_policy() : FormArray {
    return this.policy_details.get("prorated_holiday_policy") as FormArray
  }


  newprorated(): FormGroup {

    return this.fb.group({
      "from_month": [null],
      "to_month": [null],
      "count_number": []

    })
  }

  addRoles() {
    this.prorated_holiday_policy().push(this.newprorated());
  }

  backClicked() {
    this.router.navigate(['/holiday-rule/holiday-policy']);
  }

  validFn(event:any){
      this.validFlag = event;
  }


  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.holidayForm['controls'].policydetails.invalid ) {
        changeEvent.preventDefault();
      }
    } else  if (changeEvent.nextId === 3) {
      if (this.holidayForm['controls'].holiday_policy.invalid || this.holidayForm['controls'].policydetails.invalid || this.validFlag) {
        changeEvent.preventDefault();
      }
      else if(this.holidayForm?.value?.holiday_policy?.length <  this.policy_details?.value?.max_floating_request){
        this.notificationService.handleErrorNotification("The number of Floating holidays allocated should be equal to or greater than the Maximum floating request count.","Error");
        changeEvent.preventDefault();
      }else{
        this.checkUnmappedFutureDates();
      }
    }
  }

  editForm(){
    this.loader = true;
    this.action_header = 'Edit'
    this.editflag = true
    this.hps.getHolidayPolicy(this.id).subscribe((res: any) => {
      this.iseditForm=true;
      this.setEditForm(res);

    },(error: any)=>{
      this.router.navigate(['/holiday-rule/holiday-policy']);
    });
  }

  setEditForm(res:any){

    this.id=res.id;
    let applicableto = "";
    let assignees = [];
    let appkey ="";
    if(res.is_active == false){
      this.holidayForm.disable();
      this.disabled = true;
    }

    let holidayprorate = []
    this.prorated_holiday_policy().removeAt(0);
    for(let key of res.prorated_holiday_policy){
    this.addRoles();
    holidayprorate.push({
       "from_month":key.from_month,
       "to_month":key.to_month,
       "count_number":key.count_number,

    });
  }
  var flags = [], holidayMappingArr = [], l =  res.holiday_policy.length, i;
    for( i=0; i<l; i++) {
        if( flags[ res.holiday_policy[i].holiday]) continue;
        flags[ res.holiday_policy[i].holiday] = true;
        holidayMappingArr.push( res.holiday_policy[i].holiday);
    }

    for(let key of res.holiday_policy_assignright){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
      assignees.push(key[appkey]);
      applicableto = key.applicable
    }
     this.holidayForm.reset(
      {
        id: res.id,
        holiday_mapped_ids: res.holiday_mapped_ids,
        is_requested: res.is_requested,
        policydetails:{
        holiday_policy_name: res.holiday_policy_name,
        year: res.year,
        max_floating_request: res.max_floating_request,
        is_floating_holiday : res.is_floating_holiday,
        prorate_floating_holiday: res.prorate_floating_holiday,
        prorated_holiday_policy:holidayprorate,
        },
        holiday_policy: holidayMappingArr,
        assign: {
          applicableto: applicableto,
          assignees: assignees
        },
    });

    this.policy_details.get('year')?.disable()
    this.policy_details.get('max_floating_request')?.disable()
    this.policy_details.get('is_floating_holiday')?.disable()
    this.holidayMappingArray = holidayMappingArr.slice();
  }

  submitForm(){
    if(!this.holidayForm.invalid){
      this.res=this.holidayForm.controls.holiday_policy.value;
      var formArray = [];
      for(let key of this.res){
        formArray.push({
          "holiday":key,
       });
      }

      this.resp = this.policy_details.controls.prorated_holiday_policy;
      var dataprorateArray = [];
      if(this.policy_details.controls.prorate_floating_holiday.value == true){
      for(let key of this.resp.controls){
        dataprorateArray.push({
          "from_month":key.value.from_month,
          "to_month":key.value.to_month,
          "count_number":key.value.count_number,

       });
      }
    }
    else{
      dataprorateArray = [];

    }

      this.res = this.assignForm.controls.assignees.value;
        var applicable = this.assignForm.controls.applicableto.value;
        var applicableStr = applicable;
        if(applicable == "BU")
          applicableStr = "Businessunit";
        var arrRights = [];
        for(let key of this.res){
          arrRights.push({
            "applicable":applicable,
            [applicableStr.toLowerCase()]:key,
         });
        }
       var dataArray = {
       "is_requested": this.holidayForm.controls.is_requested.value,
       "holiday_policy_name": this.policy_details.controls.holiday_policy_name.value,
       "is_floating_holiday":this.policy_details.controls.is_floating_holiday.value,
       "max_floating_request":this.policy_details.controls.is_floating_holiday.value == true? this.policy_details.controls.max_floating_request.value:0,
       "year": this.policy_details.controls.year.value,
       "holiday_policy": formArray,
       "holiday_policy_assignright":arrRights,
       "prorate_floating_holiday":this.policy_details.controls.prorate_floating_holiday.value,
       "prorated_holiday_policy":dataprorateArray
       }


      if(this.id == 0){
        this.hps.saveHolidayPolicy(dataArray).subscribe((res: any) => {
          this.router.navigate(['/holiday-rule/holiday-policy']);
    });

      } else {
        this.hps.updateHolidayPolicy(dataArray,this.id).subscribe((res: any) => {
          this.router.navigate(['/holiday-rule/holiday-policy']);
        },
        (error) => {

        });
      }
    }


   };
    previousYear(val:any){
      this.prevYear= val;
      if(this.prevYear !== this.policy_details.value.year){
        this.holidayForm.controls['holiday_policy'].setValue(null);
      }
    }

  checkUnmappedFutureDates(){
    let arr = this.holidayMappingArray.filter((x:any) => this.holidayForm?.value?.holiday_policy.indexOf(x)===-1);
    if( arr?.length > 0 && this.id > 0)
    this.hps.futureHolidayCheck({"unmapped_holidays":arr}).subscribe((res: any) => {
      this.isFutureHolday = res?.data;
    });
  }

}
