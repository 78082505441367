import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { AppService } from 'src/app/app.global';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MessageService } from 'src/app/message.global';


@Component({
  selector: 'app-monthly-salary-view',
  templateUrl: './monthly-salary-view.component.html',
  styleUrls: ['./monthly-salary-view.component.scss']
})
export class MonthlySalaryViewComponent implements OnInit {
  @Input() salarysummaryList:any;
  @Input() empsalarysummaryList:any;
  @Input() month:any;
  @Input() year:any;
  @Input() searchString:any;
  @Input() page:any;
  @Input() pageSize:any;
  @Input() issalsumm    = false
  @Input() isempsalsumm = false
  @Input() isRunParoll  = true
  @Output() reloadpage  = new EventEmitter();

  items: any[]          = [];
  deletedData:any       = [];
  inputdata :any
  isDelete              = false;
  deleteClicked         = false;
  successDataCount      = 0;
  downloadMsg           = false;
  exportData:any        = [];
  successMsg:any;
  errorMsg:any;


  constructor(
    public appservice:AppService,
    public salaryService: SalaryDashboardService,
    private notificationService: NotificationService,
    public messageService : MessageService

  ) { }

  ngOnInit(): void {

  }
  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }
  filterIt() {
    return this.empsalarysummaryList.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  filteredcount(){
    return this.filterIt().length;
  }
  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item:any) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  countSelected(){
    return this.empsalarysummaryList.filter((obj:any) => obj.checked === true).length;
  }
  selectAll(){
    this.inputdata = this.appservice.selectAll(this.empsalarysummaryList)

  }
  unselectAll(){
    this.inputdata = this.appservice.unselectAll(this.empsalarysummaryList)
  }
  createSelectedLIst(){
    this.deletedData = [];

    for(let i=0;i<this.empsalarysummaryList.length;i++)
    {
      if(this.empsalarysummaryList[i]?.checked == true){
        this.deletedData.push(this.empsalarysummaryList[i]?.id)
      }
    }
    this.isDelete  = true;


  }
  confirmDelete(){

    this.deleteClicked = true;
    this.salaryService.deleteMonthlyPrior({ "pay_month": this.month, "pay_year": this.year, "employee": this.deletedData }).subscribe((res:any)=>{

      let data = res.body;
      this.exportData = [];
      this.successDataCount = this.deletedData.length - data.failed_data.length;

      if(data.failed_data.length>0){

        for (let i = 0; i < data.failed_data.length; i++) {
          this.exportData.push({'Employee code':data.failed_data[i]['employee_code'],'Employee name':data.failed_data[i]['employee_name'],'Error description':data.failed_data[i]['error']});

        }
        this.successMsg   = this.successDataCount+" out of "+this.deletedData.length +" data deleted successfully "
        this.errorMsg     = data.failed_data.length+' employee(s) have error data'
        this.downloadMsg  = true;

      }
      else
      this.notificationService.handleSuccessNotification("Deleted Successfully","Success");
      this.isDelete = false;
      this.reloadpage.emit();
    })
  }

}
