<div class="side-panel" style="--sidepanel-width: 27rem" [class.side-pane-active]="filterpanel === true" [formGroup]="filterForm">
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="closeFilter()">
        <i class="icon-close-lg fs-16"></i>
      </a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto">
      <div class="container-fluid p-32">
        <div class="row row-16">


          <!-- Asset name -->

          <div class="col-12">
            <label for="name" class="form-label text-uppercase fw-500 ">Asset name</label>

            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('asset name')}}" formControlName="asset__name" [items]="assetName_list" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="name" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center overflow-hidden w-100">
                  <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                  <span class="text-trim">{{item.name}}</span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.asset__name?.length">
                  <span class="ng-value-label">{{ filterForm.value.asset__name.length }} Asset Name Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.asset__name?.length >0">
              <li class="ng-value" *ngFor=" let item of filterForm.value.asset__name; let ki = index" >
                <ng-container *ngFor="let insideitem of assetName_list; let k = index">
                  <span class="ng-value-label" *ngIf="insideitem['name'] == item">
                    {{insideitem["name"].length > 15? (insideitem["name"] | slice : 0 : 15) + "..": insideitem["name"]}}</span >
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right" (click)="clearcommonForm('asset__name', ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <!-- End name -->
          <!-- Asset code -->

          <div class="col-12">
            <label for="name" class="form-label text-uppercase fw-500 ">Asset code</label>

            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('asset code')}}" formControlName="asset__code" [items]="assetCode_list" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="code" [closeOnSelect]="false" bindValue="code" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center overflow-hidden w-100">
                  <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                  <span class="text-trim">{{item.code}}</span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.asset__code?.length">
                  <span class="ng-value-label">{{ filterForm.value.asset__code.length }} Asset Code Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.asset__code?.length >0">
              <li class="ng-value" *ngFor=" let item of filterForm.value.asset__code; let ki = index" >
                <ng-container *ngFor="let insideitem of assetCode_list; let k = index">
                  <span class="ng-value-label" *ngIf="insideitem['code'] == item">
                    {{insideitem["code"].length > 15? (insideitem["code"] | slice : 0 : 15) + "..": insideitem["code"]}}</span >
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right" (click)="clearcommonForm('asset__code', ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <!-- End code -->

          <!-- Asset serial no -->

          <div class="col-12 ">
            <label for="name" class="form-label text-uppercase fw-500 ">Asset serial number</label>

            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('asset serial number')}}" formControlName="asset__serial_number" [items]="assetSerial_list" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="serial_number" [closeOnSelect]="false" bindValue="serial_number" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center overflow-hidden w-100">
                  <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                  <span class="text-trim">{{item.serial_number}}</span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.asset__serial_number?.length">
                  <span class="ng-value-label">{{ filterForm.value.asset__serial_number.length }} Serial Number Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.asset__serial_number?.length >0">
              <li class="ng-value" *ngFor=" let item of filterForm.value.asset__serial_number; let ki = index" >
                <ng-container *ngFor="let insideitem of assetSerial_list; let k = index">
                  <span class="ng-value-label" *ngIf="insideitem['serial_number'] == item">
                    {{insideitem["serial_number"].length > 15? (insideitem["serial_number"] | slice : 0 : 15) + "..": insideitem["serial_number"]}}</span >
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right" (click)="clearcommonForm('asset__serial_number', ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <!-- End serial no -->
          <!-- Asset cateory -->

          <div class="col-12 ">
            <label for="name" class="form-label text-uppercase fw-500">Asset category</label>

            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('asset category')}}" formControlName="asset__asset_category_id" [items]="categoryDD" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center overflow-hidden w-100">
                  <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                  <span class="text-trim">{{item.name}}</span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.asset__asset_category_id?.length">
                  <span class="ng-value-label">{{ filterForm.value.asset__asset_category_id.length }} Category Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.asset__asset_category_id?.length >0">
              <li class="ng-value" *ngFor=" let item of filterForm.value.asset__asset_category_id; let ki = index" >
                <ng-container *ngFor="let insideitem of categoryDD; let k = index">
                  <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                    {{insideitem["name"].length > 15? (insideitem["name"] | slice : 0 : 15) + "..": insideitem["name"]}}</span >
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right" (click)="clearcommonForm('asset__asset_category_id', ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <!-- End category -->

          <!-- Asset sub cateory -->

          <div class="col-12">
            <label for="name" class="form-label text-uppercase fw-500">Asset subcategory</label>

            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('asset subcategory')}}" formControlName="asset__asset_sub_category_id" [items]="subCategoryDD" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center overflow-hidden w-100">
                  <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>

                  <span class="text-trim">{{item.name}}</span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.asset__asset_sub_category_id?.length">
                  <span class="ng-value-label">{{ filterForm.value.asset__asset_sub_category_id.length }} Subcategory Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.asset__asset_sub_category_id?.length >0">
              <li class="ng-value" *ngFor=" let item of filterForm.value.asset__asset_sub_category_id; let ki = index" >
                <ng-container *ngFor="let insideitem of subCategoryDD; let k = index">
                  <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                    {{insideitem["name"].length > 15? (insideitem["name"] | slice : 0 : 15) + "..": insideitem["name"]}}</span >
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right" (click)="clearcommonForm('asset__asset_sub_category_id', ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <!-- End subcategory -->

          <!-- Asset Assigned by -->

          <div class="col-12">
            <label for="name" class="form-label text-uppercase fw-500 ">assigned by</label>

            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('assigned by')}}" formControlName="assigned_by" [items]="empDD" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center overflow-hidden w-100">
                  <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" class="me-8"/>
                  <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"  *ngIf="item.profile_image !=  null && item.profile_image !=  ''"  src="{{item.profile_image}}">
                  <span *ngIf="(item.profile_image ==  null || item.profile_image ==  '')" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>
                  <span class="text-trim">{{item.name}}</span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.assigned_by?.length">
                  <span class="ng-value-label">{{ filterForm.value.assigned_by.length }} Employee Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.assigned_by?.length >0">
              <li class="ng-value" *ngFor=" let item of filterForm.value.assigned_by; let ki = index" >
                <ng-container *ngFor="let insideitem of empDD; let k = index">
                  <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                    <!-- {{insideitem["name"].length > 15? (insideitem["name"] | slice : 0 : 15) + "..": insideitem["name"]}}</span > -->
                    <div class="d-inline-flex">
                      <img class="avatar-sm sq-32 img-fluid rounded-circle"
                        *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                        src="{{insideitem.profile_image}}">
                      <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                        class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                        <span>
                          {{this.appService.getFirstChar(insideitem.first_name+"
                          "+insideitem.last_name,2)}}</span>
                      </span>
                      <span class="mt-2 ms-1">
                        {{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                        ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                      </span>
                    </div>
                  </span >
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right" (click)="clearcommonForm('assigned_by', ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <!-- End Assigned by -->

          <div *ngIf="from != 'asset'" class="col-12">
            <label for="name" class="form-label text-uppercase fw-500">Assigned on</label>
            <div class="input-group form-icon icon-right">
              <input (click)="datePicker8.open()" class="form-control"
                placeholder="{{this.messageService.selectplaceholddisplay('Assigned on')}}" matInput
                formControlName="assigned_on" [matDatepicker]="datePicker8"  [matDatepickerFilter]="dateFilter" ><i
                class="icon-calendar pointer" (click)="datePicker8.open()" ></i>
              <mat-datepicker touchUi="false" #datePicker8></mat-datepicker>
            </div>
          </div>
          <!-- <div *ngIf="from == 'asset'" class="col-12">
            <label for="name" class="form-label text-uppercase fw-500 ">Returned on</label>
            <div class="input-group form-icon icon-right">
              <input (click)="datePicker2.open()" class="form-control"
                placeholder="{{this.messageService.selectplaceholddisplay('Returned on')}}" matInput
                formControlName="returned_on" [matDatepicker]="datePicker2"  [matDatepickerFilter]="dateFilter" ><i
                class="icon-calendar pointer" (click)="datePicker2.open()"></i>
              <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
            </div>
          </div> -->

        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetfilter()">
        Reset
      </button>
      <button type="submit" (click)="applyfilter()" class="ms-auto btn btn-primary text-uppercase btn-sm">
        Apply
      </button>
    </footer>
  </div>
</div>
