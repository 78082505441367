import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-fnf-employee-approval',
  templateUrl: './fnf-employee-approval.component.html',
  styleUrls: ['./fnf-employee-approval.component.scss']
})

export class FnfEmployeeApprovalComponent implements OnInit {

  @Input() data:any;
  @Input() profile:any;
  @Input() loader:any;

  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  @Input() payrollStatus:any;
  @Input() reqStatus:any;
  @Output() unprocessWarningPopup =  new EventEmitter();

  form = this.fb.group({
    read_status     : [true, Validators.required],
    star            : [false, Validators.required],
    approved_status : [false, Validators.required],
    comment         : ['', Validators.maxLength(500)],
  })

  constructor(
    public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,
  ) { }

  ngOnInit(): void {
  }

  get f() { return this.form.controls; }

  confirm(status: any) {

    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      if (status == 'Approved') {
        this.form.controls['approved_status'].setValue('Approved');
      } else {
        this.form.controls['approved_status'].setValue('Rejected');
      }
      if (this.form['controls'].comment.valid) {

        this.finalSettlementService.updateInboxItem(this.data?.id, this.form.value).subscribe((res) => {
          if (res) {
            this.fnfApprovalViewClose.emit(false);
          }
        })
      }
    }

  }

}
