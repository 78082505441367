import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-overtime-request',
  templateUrl: './view-overtime-request.component.html',
  styleUrls: ['./view-overtime-request.component.scss']
})

export class ViewOvertimeRequestComponent implements OnInit {

  @Input()viewData:any;

  constructor(
    public appservice:AppService,
  ) { }

  ngOnInit(): void {
  }

}
