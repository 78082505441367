import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-location-group-review',
  templateUrl: './location-group-review.component.html',
  styleUrls: ['./location-group-review.component.scss']
})
export class LocationGroupReviewComponent implements OnInit {
  @Input()summerydata:any  =[];
  @Output() submitmethod = new EventEmitter();
  saveBtnClicked = false;

  constructor() { }

  ngOnInit(): void {
  }
  validatefn(){
    this.saveBtnClicked =true;
    this.submitmethod.emit();
  }
}
