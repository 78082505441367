import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() pin : any
  @Input() labels : any
  @Input() data : any
  @Input() fill : any
  @Input() type : any
  @Input() ratio = 2.5
  @Input() bgColor : any
  @Input() xtitle : any
  @Input() ytitle : any

  nonNullCount : any

  constructor() { }

  public chart: any;
  ngOnInit(): void {
    this.nonNullCount = this.labels.filter((value:any)=>value !=null)?.length;
    this.createChart();
  }
  createChart() {
    this.chart = new Chart("MyChart", {
      type: 'line',
      data: {
        labels: this.data,
        datasets: [
          {
            label: this.type,
            data: this.labels,
            borderColor: '#0078CE',
            backgroundColor : this.bgColor,
            borderWidth: 2,
            fill: this.fill,
            pointRadius: (context)=>{
              const dataIndex = context.dataIndex;
              return this.data[dataIndex] !== null ? 2 : 0
            },
            spanGaps: false,
            pointBackgroundColor: "#0078CE",
          }
        ]
      },
      options: {
        interaction: {
          mode: 'nearest'
        },
        aspectRatio: this.ratio,
        scales: {
          y: {
            // min:0,
            title: {
              display: true,
              text: this.xtitle
            },      
            beginAtZero: true,
            grid: {
              color: '#EAEDEF',
            },
            ticks: {
              color: '#566976',
              font: {
                family: 'Work Sans',
                size: 10,
                weight: 'normal'
              },
              // precision : 0,
              // stepSize: this.starter,
              maxTicksLimit : 4
            },
          },
          x: {
            grid: {
              display: false
            },
            title: {
              display: true,
              text: this.ytitle
            },
            ticks: {
              color: '#566976',
              font: {
                family: 'Work Sans',
                size: 10,
                weight: 'normal'
              }
            }
          }
        },
        elements: {
          point: {
            pointStyle: this.nonNullCount === 1 ? true : this.pin
          }
        },
        plugins: {
          tooltip: {
            backgroundColor: '#ffffff',
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.2)',
            bodyColor: '#324552',
            bodyFont: {
              size: 12,
              family: 'Work Sans',
            },
            titleColor: '#324552',
            titleFont: {
              size: 12,
              weight: 'bold',
              family: 'Work Sans'
            }
          },
          legend: {
            display: false,
          }
        }
      }
    });
  }
}
