import { Component, OnInit,  Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { RoleService } from '../../../../../@core/services/role.service'
import { FormArray, FormBuilder, FormGroup , Validators } from '@angular/forms';
import { Router,  } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-role-assign-rights',
  templateUrl: './role-assign-rights.component.html',
  styleUrls: ['./role-assign-rights.component.scss']
})
export class RoleAssignRightsComponent implements OnInit {
  showSidePanel =false;
  @Input()empValid:any[] = [];
  @ViewChild('ngSelectComponent') ngSelectComponent: any;
  Isalert                   = true;
  @Input()selectedEmp = [{
    id: 0,
    employee_code: '',
    profile_image: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    name:'',
    color_code:'',
    firstChar:''
  }];
  @Input()formGp : FormGroup = this.formBuilder.group({
    list : this.formBuilder.array([])
  });
  @Input() disabled=false;
  @Input()defineForm: FormGroup = this.formBuilder.group({
    role_name: ['', [Validators.required]],
    employee: ['', [Validators.required]]
  });

  @Input()companyDD :any =  [{
    id: 0,
    company_name: ''}];

  @Input()buDD = [{
    id: 0,
    name: ''}];

  @Input()DepDD = [{
    id: 0,
    name: ''}];
  @Input()DesignationDD = [{
    id: 0,
    name: ''}];
  @Input()BranchDD = [{
    id: 0,
    name: ''}];
  @Input()cityDD = [{
    id: 0,
    name: ''}];
  @Input()gradeDD = [{
    id: 0,
    name: ''}];
  @Input()saveBtn='';
  @Input()buArray:any = [{
    id: 0,
    name: ''
  }];

  @Output() submitmethod = new EventEmitter();
  @Output() checkMethod = new EventEmitter();
  @Output() companyChange = new EventEmitter();
  saveClicked = false

  commonForm: FormGroup = this.formBuilder.group({
    employee: ['', [Validators.required]],
    company: [[], [Validators.required]],
    businessunit: [[], [Validators.required]],
    department: [[], [Validators.required]],
    designation: [[], [Validators.required]],
    grade: [[], [Validators.required]],
    city: [[], [Validators.required]],
    branch: [[], [Validators.required]]
  })
  listForm:any;

  ngOnInit(): void {
    this.saveClicked = false
    if (this.companyDD.length != 0) {
      this.selectAllForDropdownItems(this.companyDD);
    }
    if (this.DepDD.length != 0) {
      this.selectAllForDropdownItems(this.DepDD);
    }
    if (this.DesignationDD.length != 0) {
      this.selectAllForDropdownItems(this.DesignationDD);
    }
    if (this.BranchDD.length != 0) {
      this.selectAllForDropdownItems(this.BranchDD);
    }
    if (this.gradeDD.length != 0) {
      this.selectAllForDropdownItems(this.gradeDD);
    }
  }
  constructor(
    private roleService: RoleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public messageService : MessageService,
    public appService:AppService
  ) { }


  setCommonFormEmployees(){
    let empList = [];
    for(let emp of this.defineForm.value.employee){
      empList.push(emp);
    }
    this.commonForm.patchValue({
      employee: empList
    });
  }
  assignForm() : FormArray {
    return this.formGp.get("list") as FormArray
  }

  getEmployeeName(index:number){
    let emp =  this.assignForm().at(index);
    let selEmp = this.selectedEmp.filter(p => p?.id===emp.value.employee)
    if(selEmp.length){
      selEmp[0]['firstChar'] = this.appService.getFirstChar(selEmp[0]['name'],1);
      return selEmp[0];
    } else return {profile_image: '','name':'','firstChar':'','color_code':''}
  }

  getEmployeeNameById(index:number){

    let selEmp = this.selectedEmp.filter(p => p?.id===index)
    if(selEmp.length){ selEmp[0]['firstChar'] = this.appService.getFirstChar(selEmp[0]['name'],1);
      return selEmp[0];
    } else return {profile_image: '','name':'','firstChar':'','color_code':''}
  }


  clear(item:any,index:number,i:number){
    if(item == "company"){
      let daata = this.formGp.value.list[i].company;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("list")).at(i);
      myForm.patchValue({
        company: daata,
        businessunit: []
      });
      this.companyChangeFunction(this.formGp.value.list[i].company,i);
    } else if(item == "businessunit"){
      let daata = this.formGp.value.list[i].businessunit;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("list")).at(i);
      myForm.patchValue({
        businessunit: daata
      });
    } else if(item == "department"){
      let daata = this.formGp.value.list[i].department;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("list")).at(i);
      myForm.patchValue({
        department: daata
      });
    } else if(item == "city"){
      let daata = this.formGp.value.list[i].city;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("list")).at(i);
      myForm.patchValue({
        city: daata
      });
    } else if(item == "designation"){
      let daata = this.formGp.value.list[i].designation;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("list")).at(i);
      myForm.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.formGp.value.list[i].grade;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("list")).at(i);
      myForm.patchValue({
        grade: daata
      });
    }  else if(item == "branch"){
      let daata = this.formGp.value.list[i].branch;
      daata.splice(index, 1);
      const myForm = (<FormArray>this.formGp.get("list")).at(i);
      myForm.patchValue({
        branch: daata
      });
    }
  }



  clearEmployee(index:number){
    let daata = this.defineForm.value.employee;
    daata.splice(index, 1);
    const myForm = (<FormArray>this.defineForm.get("employee"));
    myForm.patchValue(daata);
    this.assignForm().removeAt(index)
    if(this.commonForm.value.company.notEmpty)
    this.clearcommonForm("employee",index);
    for(let i=0;i<this.assignForm().length;i++){
      this.companyChangeFunction(this.formGp.value.list[i].company,i)
    }
  }

  clearcommonForm(item:any,index:number){
    if(item == "company"){
      let daata = this.commonForm.value.company;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        company: daata,
        businessunit:[]
      });
      this.companyChangeFunction(this.commonForm.value.company,'commonForm');
    } else if(item == "businessunit"){
      let daata = this.commonForm.value.businessunit;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        businessunit: daata
      });
    } else if(item == "department"){
      let daata = this.commonForm.value.department;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        department: daata
      });
    } else if(item == "city"){
      let daata = this.commonForm.value.city;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        city: daata
      });
    } else if(item == "designation"){
      let daata = this.commonForm.value.designation;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.commonForm.value.grade;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        grade: daata
      });
    } else if(item == "employee"){
      let daata = this.commonForm.value.employee;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        employee: daata
      });
    }  else if(item == "branch"){
      let daata = this.commonForm.value.branch;
      daata.splice(index, 1);
      this.commonForm.patchValue({
        branch: daata
      });
    }
  }

  updateFormGp(){
    if(this.commonForm.controls.company.valid || this.commonForm.controls.businessunit.valid || this.commonForm.controls.department.valid || this.commonForm.controls.designation.valid || this.commonForm.controls.grade.valid || this.commonForm.controls.city.valid || this.commonForm.controls.branch.valid ){
      const myForm = (<FormArray>this.formGp.get("list"));
      for(let empId of this.commonForm.value.employee){
        for(let k = 0; k < myForm.length; k++ ){

          if(myForm.value[k].employee == empId){
            const listForm = (<FormArray>this.formGp.get("list")).at(k);
            var comps:any = [];
            this.commonForm.value.company?.forEach((element: any) => {
              comps.push(element);
            });
            var bus:any = [];
            this.commonForm.value.businessunit?.forEach((element: any) => {
              bus.push(element);
            });
            var dep:any = [];
            this.commonForm.value.department?.forEach((element: any) => {
              dep.push(element);
            });
            var des:any = [];
            this.commonForm.value.designation?.forEach((element: any) => {
              des.push(element);
            });
            var grade:any = [];
            this.commonForm.value.grade?.forEach((element: any) => {
              grade.push(element);
            });
            var city:any = [];
            this.commonForm.value.city?.forEach((element: any) => {
              city.push(element);
            });
            var branch:any = [];
            this.commonForm.value.branch?.forEach((element: any) => {
              branch.push(element);
            });
            listForm.patchValue({
              company: comps,
              // businessunit: bus,
              department: dep,
              designation: des,
              grade: grade,
              city: city,
              branch: branch,
            });
          }
        }
      }
      this.updateBuGp(this.commonForm.value.company,this.commonForm.value.businessunit);
      this.showSidePanel = false
    }

  }

  updateBuGp(comp:any,bu:any){
    
    const myForm = (<FormArray>this.formGp.get("list"));
      for(let k = 0; k < myForm.length; k++ ){
        this.companyChange.emit({compData : comp, index: k});
        this.listForm = myForm.at(k);
        this.listForm.patchValue({
          businessunit: bu!=null?bu:[],
        });
      }
  }

  submitform() {
    if (this.checkValid()) {
      return;
    }
    this.saveClicked = true
    this.submitmethod.emit(this.formGp.value);
  }

  checkValid() {
    var retResult = false;
    const myForm = (<FormArray>this.formGp.get("list"));
    var  AccessArr;
    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);
      if(this.listForm.controls.company.valid == false && this.listForm.controls.businessunit.valid == false && this.listForm.controls.department.valid == false && this.listForm.controls.designation.valid == false && this.listForm.controls.grade.valid == false && this.listForm.controls.city.valid == false && this.listForm.controls.branch.valid == false) {
        retResult = true;
      }
    }
    return retResult;
  }

  checkcommonFormValid(){
    if(this.commonForm.controls.company.valid || this.commonForm.controls.businessunit.valid || this.commonForm.controls.department.valid || this.commonForm.controls.designation.valid || this.commonForm.controls.grade.valid || this.commonForm.controls.city.valid || this.commonForm.controls.branch.valid ){
      return false;
    } else{
      return true;
    }

  }

  checkCompleted(k:any){
    var retResult = false;
    const myForm = (<FormArray>this.formGp.get("list"));
    this.listForm = myForm.at(k);
    if(this.listForm.controls.company.valid == true || this.listForm.controls.businessunit.valid == true || this.listForm.controls.department.valid == true || this.listForm.controls.designation.valid == true || this.listForm.controls.grade.valid == true || this.listForm.controls.city.valid == true || this.listForm.controls.branch.valid == true) {
      retResult = true;
    }

    return retResult;
  }

  companyChangeFunction(data:any,index:any){
    this.companyChange.emit({compData : data, index: index});
    if(this.formGp.value.list[index]?.company.length == 0){
      const myForm = (<FormArray>this.formGp.get("list")).at(index);
      myForm.patchValue({
        businessunit: []
      });
    }

    this.commonForm.get('businessunit')?.reset();
    
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
