import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getdepartmentdata(search:any,perPage:any,status:any,searchKey:any){
    const url=`${this.API_PATH}organization/api/v1/department_list?limit=${search}&offset=${perPage}&is_active=${status}&search=${searchKey}`;
    return this.http.get(url, this.httpOption)
  }
  departmentSave(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/department_list/`, body, this.httpOption)
  }

  departmentList(status:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/department_list/?is_active=${status}`, this.httpOption)
  }

  departmentGet(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/department_detail/${id}`, this.httpOption)
  }

  departmentUpdate(id: number, body:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/department_detail/${id}/`, body, this.httpOption)
  }

  departmentDelete(id: number) {
    return this.http.patch(`${this.API_PATH}organization/api/v1/department_detail/${id}/`, { is_active: false },this.httpOption)
  }
  departmentListData(status:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=${status}&ordering=name`, this.httpOption)
  }

}
