<div class="container-fluid p-32 form-container" [formGroup]="formGp">
  <div class="row row-16">
    <div class="col-12">
      <div class="card details-card indicater1 px-16 py-12">
          <div class="row">
              <div class="col-5"> <div class="fw-600 fs-14">Income from other sources</div> </div>

              <div class="col">
                  <div class="detail-title">Total amount declared</div>
                  <div class="detail-desc">{{totalDeclaredFunction() | currency: currency}}</div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-12">
      <div  role="alert" class="alert alert-warning mb-0 {{alertShow}}">
        <p class="mb-0">Select the checkbox below to fill details or skip the step by clicking on skip button
        </p>
        <div class="btn-close" (click)="alertShow='hide'"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-c2" >
          <div class="card-body px-24 py-16">
              <div class="row row-12">

                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" name="incomefrom_othersource" id=""  class="form-check-input " formControlName="incomefrom_othersource" (change)="checkBoxFunctionTest($event,'othersource_amount')">
                          <label  for="" class="form-check-label fs-14">Income from other sources</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"  ngbPopover="Income from Other Sources is the income received by the employee from other sources like dividends, interest on loans, gifts, rental income, agricultural income and so on and not from the salary." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.incomefrom_othersource">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.othersource_amount.dirty || f.othersource_amount.touched) &&  f.othersource_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="othersource_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="othersource_amount" [ngClass]="{ 'is-invalid': (submitted || f.othersource_amount.dirty || f.othersource_amount.touched) &&  f.othersource_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.othersource_amount.dirty || f.othersource_amount.touched) && f.othersource_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.othersource_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.othersource_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.othersource_amount.errors.pattern) && f.othersource_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" name="interest_earned_sbaccount" id=""  class="form-check-input " formControlName="interest_earned_sbaccount" (change)="checkBoxFunctionTest($event,'sb_account_amount')">
                          <label  for="" class="form-check-label fs-14">Interest earned through saving bank account</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"  ngbPopover="Interest earned on a savings bank account is tax-free up to a limit of ₹10,000/-. If the interest received from these sources exceeds ₹10,000/- the excess interest earned on the savings account will be taxed." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.interest_earned_sbaccount">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.sb_account_amount.dirty || f.sb_account_amount.touched) &&  f.sb_account_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="sb_account_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="sb_account_amount" [ngClass]="{ 'is-invalid': (submitted || f.sb_account_amount.dirty || f.sb_account_amount.touched) &&  f.sb_account_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.sb_account_amount.dirty || f.sb_account_amount.touched) && f.sb_account_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.sb_account_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.sb_account_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.sb_account_amount.errors.pattern) && f.sb_account_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" name="source_80TTA_80TTB" id=""  class="form-check-input " formControlName="source_80TTA_80TTB" (change)="checkBoxFunctionTest($event,'source_80TTA_80TTB_amount')">
                          <label  for="" class="form-check-label fs-14">80TTA/ 80TTB</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"  ngbPopover="80TTA is applicable for individuals or HUF invested only in saving accounts can claim upto ₹10,000 as deduction. 80TTB is applicable only for senior citizens invested in saving accounts and fixed deposit accounts can claim upto ₹ 50,000 as deduction." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.source_80TTA_80TTB">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.source_80TTA_80TTB_amount.dirty || f.source_80TTA_80TTB_amount.touched) &&  f.source_80TTA_80TTB_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="source_80TTA_80TTB_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="source_80TTA_80TTB_amount" [ngClass]="{ 'is-invalid': (submitted || f.source_80TTA_80TTB_amount.dirty || f.source_80TTA_80TTB_amount.touched) &&  f.source_80TTA_80TTB_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.source_80TTA_80TTB_amount.dirty || f.source_80TTA_80TTB_amount.touched) && f.source_80TTA_80TTB_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.source_80TTA_80TTB_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.source_80TTA_80TTB_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.source_80TTA_80TTB_amount.errors.pattern) && f.source_80TTA_80TTB_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                  </ng-container>
                  <div class="col-12"><hr class="hr-1 px-16"></div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input type="checkbox" name="intrest_earned" id=""  class="form-check-input " formControlName="intrest_earned" (change)="checkBoxFunctionTest($event,'intrest_earned_amount')">
                          <label  for="" class="form-check-label fs-14">Interest earned from national savings certificate</label>
                          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"  ngbPopover="Employees can claim a deduction for the investments made in the National Savings Certificate up to ₹1.5 lakhs per annum under Section 80C." popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
                      </div>
                  </div>
                  <ng-container *ngIf="formGp.value.intrest_earned">
                      <div class=" col-md-6 form-row">
                          <label for="" class="form-label required">Amount</label>
                          <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.intrest_earned_amount.dirty || f.intrest_earned_amount.touched) &&  f.intrest_earned_amount.errors}">
                            <input type="text" class="form-control" autocomplete="off" name="intrest_earned_amount" id="name" placeholder="{{this.messageService.placeholderdisp('Amount')}}" formControlName="intrest_earned_amount" [ngClass]="{ 'is-invalid': (submitted || f.intrest_earned_amount.dirty || f.intrest_earned_amount.touched) &&  f.intrest_earned_amount.errors}">
                            <span>{{currency}}</span>
                          </div>
                          <div *ngIf="(submitted || f.intrest_earned_amount.dirty || f.intrest_earned_amount.touched) && f.intrest_earned_amount.errors" class="invalid-feedback">
                              <div *ngIf="f.intrest_earned_amount.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                              <div *ngIf="f.intrest_earned_amount.errors.pattern">{{this.messageService.validationDisplay('amount')}}</div>
                              <div *ngIf="!(f.intrest_earned_amount.errors.pattern) && f.intrest_earned_amount.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','15')}}</div>
                          </div>
                      </div>
                  </ng-container>

              </div>
          </div>
      </div>
    </div>
  </div>
</div>
<!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
<footer class="submit-footer gap-16">
  <button class="btn btn-outline-primary btn-sm ms-auto text-uppercase" (click)="validateOtherIncome('skip')">SKIP</button>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="!formGp.valid" (click)="validateOtherIncome('next')">NEXT</button>
</footer>
