import { Component, Input, OnInit } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-pa-children-menu',
  templateUrl: './pa-children-menu.component.html',
  styleUrls: ['./pa-children-menu.component.scss']
})
export class PaChildrenMenuComponent implements OnInit {

  @Input() subMenu : any
  @Input() firstlevelmenu : any
  @Input() selectname : any
  @Input() selectedmenu : any
  @Input() index : any
  @Input() mainFormArr : any

  sectionArr : any = []
  selectedSubSec : any = []
  dataItem : any = []
  counter = 0

  constructor(
    public messageService : MessageService
  ) { }

  ngOnInit(): void {
  }

  get f() {
    return this.nFormGp(this.index).controls
  }

  // 1st Level Child
  nFormGp(index:any){
    return this.mainFormArr.controls[index] as FormGroup
  }

  // 2nd Level Child FormArray
  secondLvlChild() : FormArray{
    return this.nFormGp(this.index).get('lvlTwoChildren') as FormArray
  }

  // 2nd Level Child FormGroup
  nSecFormGp(index:any){
    return this.secondLvlChild().controls[index] as FormGroup
  }

  // Main Menu Selection
  setCheckChildren(menuId:any,e:any){
    let checked = e?.target?.checked
    if(this.nFormGp(this.index).get('menu')?.value == menuId){
      this.nFormGp(this.index).patchValue({
        view : checked,
        add: checked,
        edit: checked,
        delete: this.nFormGp(this.index)?.get('delete')?.value == null ? null : checked
      })
    }
  }
}
