<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="tdsComputationForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <!-- New start -->
              <div class="row row-16 mb-16 " [ngClass]="{ 'is-invalid': (submitted) && f.financial_year.errors}">
                <div class="col-12 form-row">
                  <p class="text-uppercase required fw-500 mb-8">Financial year</p>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="financial_year" type="radio" id="financial_year1" class="form-check-input  "
                            formControlName="financial_year" value="previous_year">
                        <label for="financial_year1" class="form-check-label">Previous</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check  mb-8">
                        <input name="financial_year" type="radio" id="financial_year2" class="form-check-input "
                            formControlName="financial_year" value="current_year">
                        <label for="financial_year2" class="form-check-label">Current</label>
                    </div>
                </div>
              </div>
                <div *ngIf="(submitted) && f.financial_year.errors" class="invalid-feedback">
                  <div *ngIf="f.financial_year.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
                </div>
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitGratitude()">
              Apply
          </button>
      </footer>
  </form>
</div>
