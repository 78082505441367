import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';

@Component({
  selector: 'app-weekly-off-details',
  templateUrl: './weekly-off-details.component.html',
  styleUrls: ['./weekly-off-details.component.scss']
})
export class WeeklyOffDetailsComponent implements OnInit {

  @Input() disabled  :any
  @Input() editflag  :any
  @Input() formGp    :FormGroup = this.fb.group({
    weekPolicyName   : [null,[Validators.required]],
    weekoffdata  : []
  })

  @Output() submitmethod = new EventEmitter();

  saveBtnClicked     : boolean = false
  value              : any
  id                 : any
  attendanceUnit     : any =[]
  private subjectKeyUp = new Subject<any>();


  constructor(
    public fb     : FormBuilder,
    public messageService : MessageService,
    public route: ActivatedRoute,
    public existDataService : ExistDatasService
  ) { }

  get f() {return  this.formGp.controls; }

  get weekoff_mapping() {
    return this.formGp.get('weekoffdata') as FormArray;
  }

  ngOnInit(): void {
    // Validation
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.id = this.route.snapshot.params['id']
  }


  validateForm() {
    this.saveBtnClicked=true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(2);
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":"WeekOff",
        "field":"policy_name",
        "value": this.f.weekPolicyName.value
      }
    }else{
      this.value ={
        "id":this.id,
        "page":"WeekOff",
        "field":"policy_name",
        "value": this.f.weekPolicyName.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.attendanceUnit = res.body

      if(this.attendanceUnit.data ==='invalid'){
        this.f.weekPolicyName.setErrors({
          'exist' : true
        })
      }
    })
  }

  keyUp(){
    this.subjectKeyUp.next(this.f.weekPolicyName.value)
  }
}
