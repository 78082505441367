
import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { StyleRenderer, lyl, WithStyles, ThemeRef, ThemeVariables } from '@alyle/ui';
import {
  ImgCropperConfig,
  ImgCropperEvent,
  LyImageCropper,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig,
  STYLES as CROPPER_STYLES
} from '@alyle/ui/image-cropper';
import { Platform } from '@angular/cdk/platform';
import { LySliderChange } from '@alyle/ui/slider';
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
// const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
//   ref.renderStyleSheet(CROPPER_STYLES);
//   const cropper = ref.selectorsOf(CROPPER_STYLES);

//   return {
//     root: lyl `{
//       ${cropper.root} {
//         width: 604px
//         height: 294px
//       }
//     }`,
//     sliderContainer: lyl `{
//       text-align: center
//       max-width: 400px
//       margin: 14px
//     }`,
//     cropperResult: lyl `{
//       position: relative
//       width: 150px
//       height: 150px
//     }`,
//   };
// };
// const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
//   ref.renderStyleSheet(CROPPER_STYLES);
//   return {
//     cropper: lyl `{
//       max-width: 400px
//       height: 300px
//     }`,
//     cropperResult: lyl `{
//       position: relative
//       width: 150px
//       height: 150px
//     }`,
//     sliderContainer: lyl `{
//       text-align: center
//       max-width: 400px
//       margin: 14px
//     }`
//   };
// };
const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    cropper: lyl`{
            width: 100%
            height: 430px
          }`,
    root: lyl`{
      ${cropper.root} {
        max-width: 100%
        height: 430px
      }
    }`,
    sliderContainer: lyl`{
      text-align: center
      max-width: 100%
      margin: 14px
    }`,
    slider: lyl`{
      padding: 1em
    }`,
    cropperResult: lyl`{
  position: relative
  width: 150px
  height: 150px
}`,
  };
};

@Component({
  selector: 'app-webcam-cropper',
  templateUrl: './webcam-cropper.component.html',
  styleUrls: ['./webcam-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class WebcamCropperComponent implements WithStyles, AfterViewInit {
  classes = this.sRenderer.renderSheet(STYLES);
  croppedImage?: string | null = null;
  ready: boolean = false;
  scale: any = 2;
  minScale: number = 1;
  @Output() saveImage: any = new EventEmitter();
  @Output() close: any = new EventEmitter();
  @ViewChild(LyImageCropper) readonly cropper!: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 350, // Default `250`
    height: 350, // Default `200`
    round: false,
    responsiveArea: true,
    resizableArea: true,
    type: 'image/jpeg',

  };
  @Input() DataURL = ""

  constructor(
    readonly sRenderer: StyleRenderer,
    private _platform: Platform
  ) { }

  ngAfterViewInit() {

    // demo: Load image from URL and update position, scale & rotate
    // this is supported only for browsers
    if (this._platform.isBrowser) {
      const config: ImgCropperLoaderConfig = {
        scale: 0.745864772531767,
        xOrigin: 422,
        yOrigin: 287,
        // areaWidth: 100,
        // areaHeight: 100,
        rotation: 0,
        originalDataURL: this.DataURL
      };
      this.cropper.loadImage(config);
    }

  }

  onCropped(e: ImgCropperEvent) {
    // this.croppedImage = e.dataURL;
    // console.log('cropped img: ', e);
    this.saveImage.emit(e.dataURL)
  }
  onLoaded(e: ImgCropperEvent) {
    // console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }
  onSliderInput(event: LySliderChange) {
    this.scale = event.value as number;
  }

}
