<!-- Loader for Employee Data -->
<div class="card card-c2 p-24 h-100" *ngIf="loader == 'loader_employee'">
    <div style="height: 32px;"></div>
    <div class="d-flex gap-16 mb-28 align-items-center">
        <ngx-skeleton-loader appearance="circle"
            [theme]="{ 'border-radius': '50%',height: '70px',width: '70px','background-color': '#EAEDEF','margin-right':'15px'}"></ngx-skeleton-loader>
        <div class="d-flex flex-column gap-1">
            <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '22px','width': '130px','margin-bottom':'2px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '22px','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        </div>
    </div>
    <div class="mb-2" style="height: 70px;">
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '69px','width': '100%'}"></ngx-skeleton-loader>
    </div>
</div>

<!-- Loader for Salary Summary -->
<div class="p-24 h-100" *ngIf="loader == 'loader_salary'">
    <div class="row row-16">
        <div style="height: 10px;"></div>
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <ngx-skeleton-loader appearance="circle"
                    [theme]="{ 'border-radius': '50%',height: '86px',width: '86px','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
                <div class="d-flex flex-column">
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div style="height: 25px;">
            <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '24px','width': '100%'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>

<!-- Loader for Financial Summary -->
<div class="p-24 h-100" *ngIf="loader == 'loader_financial'">
    <div class="col-12 d-flex">
        <div style="height: 10px;"></div>
    </div>
    <div class="col-12" style="height:180px">
        <div class="col-10 mx-auto h-100">
            <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '100%','width': '100%'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>


<!-- Loader for Reimbursement -->
<div class="p-24 h-100" *ngIf="loader == 'loader_reimbursement'">
    <div class="row row-12">
        <div style="height: 10px;"></div>
        <div class="col-12 mt-24">
            <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '100px','width': '100%'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>


<!-- Loader for Tax -->
<div class="p-24 h-100" *ngIf="loader == 'loader_tax'">
    <div class="row row-16">
        <div style="height: 10px;"></div>
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <ngx-skeleton-loader appearance="circle"
                    [theme]="{ 'border-radius': '50%',height: '86px',width: '86px','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
                <div class="d-flex flex-column">
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="mb-2" style="height: 95px;">
            <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '94px','width': '100%'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>


<!-- Loader for Announcemnets -->
<div class="border-0 h-100 px-24 mb-2 overflow-hidden" *ngIf="loader == 'loader_announce'">
    <app-c1-loader></app-c1-loader>
</div>

<!-- Loader for trends -->
<div class="p-24 h-100" *ngIf="loader == 'loader_trends'">
    <div class="row row-16">
        <div style="height: 10px;"></div>
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <ngx-skeleton-loader appearance="circle"
                    [theme]="{ 'border-radius': '50%',height: '86px',width: '86px','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
                <div class="d-flex flex-column">
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="  flex-shrik-0 text-white h-100" *ngIf="loader == 'checkinLoader'">
    <ngx-skeleton-loader appearance="line"
        [theme]="{'background-color':'#EAEDEF','height': '100%','width': '11.5rem','margin-bottom':'0px'}"></ngx-skeleton-loader>
</div>
<!-- DASHBOARD -->
<div class="card card-c2 px-24 py-16 radius-8 border-0 hstack gap-16 align-items-center flex-wrap text-white "
    style="min-height:5.5rem" *ngIf="loader == 'dashboard_employee'">
    <div class="vstack justify-content-center gap-8 text-center text-lg-start">
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '22px','width': '130px','margin-bottom':'2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '22px','width': '105px','margin-bottom':'2px'}"></ngx-skeleton-loader>
    </div>
    <div class=" p-8 gap-8 mx-auto card-body radius-8 ms-lg-auto">
        <ngx-skeleton-loader appearance="line"
            [theme]="{'background-color':'#EAEDEF','height': '69px','width': '100%'}"></ngx-skeleton-loader>
    </div>
</div>


<!-- Quick Links -->
<div class="p-24 h-100" *ngIf="loader == 'loader_quick'">
    <div class="col-12 d-flex">
        <div style="height: 10px;"></div>
    </div>
    <div class="col-12" style="height: 70px">
        <div class="col-10 mx-auto h-100">
            <ngx-skeleton-loader appearance="line"
                [theme]="{'background-color':'#EAEDEF','height': '100%','width': '100%'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>

<!-- Attendance -->
<div class="border-0 h-100 px-24 mb-2 overflow-hidden" *ngIf="loader == 'loader_attendance'">
    <div class="row row-16">
        <div class="col-12 mt-25">
            <ng-container *ngFor="let dummy of ' '.repeat(2).split(''), let x = index">
                <div class="mt-15">
                    <ngx-skeleton-loader appearance="line"
                        [theme]="{'background-color': '#EAEDEF','height': '74px','width': '100%' }"></ngx-skeleton-loader>
                </div>
            </ng-container>
        </div>
    </div>
