<div class="container-fluid p-32 form-container" [formGroup]="formGp">
    <div class="row ">
        <p class="text-uppercase fw-500">ATTENDANCE RULE</p>
    </div>
    <div class="row row-16">
        <div class=" col-12 form-row">
            <label for="" class="form-label required">Attendance rule name </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Enter a unique name for the attendance rule" popoverClass="popover-input popover-info"
                    placement="bottom" triggers="hover"></i></span>
            <input formControlName="attendance_rule_name"
                [ngClass]="{ 'is-invalid': (submitted || f.attendance_rule_name.touched|| f.attendance_rule_name.dirty) &&  f.attendance_rule_name.errors || f.attendance_rule_name.errors?.exist}"
                type="text" class="form-control "
                placeholder="{{this.messageService.placeholderdisp('Attendance Rule Name')}}" (keyup)="keyUp()">
            <div *ngIf="(submitted || f.attendance_rule_name.touched || f.attendance_rule_name.dirty) && f.attendance_rule_name.errors || f.attendance_rule_name.errors?.exist"
                class="invalid-feedback">
                <div *ngIf="f.attendance_rule_name.hasError('exist')">
                    {{this.messageService.validationDisplay('attendance rule name')}}</div>
                <div *ngIf="f.attendance_rule_name.errors.required">
                    {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="!f.attendance_rule_name.errors.pattern && f.attendance_rule_name.errors.maxlength">
                    {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                <div *ngIf=" f.attendance_rule_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
            </div>
        </div>
        <div class=" col-12 form-row">
            <label for="" class="form-label">Attendance rule description </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Enter few lines describing the attendance rule"
                    popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            <textarea formControlName="attend_rule_desc"
                [ngClass]="{ 'is-invalid': (submitted || f.attend_rule_desc.touched || f.attend_rule_desc.dirty) &&  f.attend_rule_desc.errors}"
                type="text" class="form-control "
                placeholder="{{this.messageService.descriptiontext('desc')}}"></textarea>
            <div *ngIf="(submitted ||f.attend_rule_desc.touched || f.attend_rule_desc.dirty) && f.attend_rule_desc.errors"
                class="invalid-feedback">
                <div *ngIf="!f.attend_rule_desc.errors.pattern && f.attend_rule_desc.errors.maxlength">
                    {{this.messageService.fieldlengthvalidation('word','500')}}</div>
            </div>
        </div>
        <div class="col-12 form-row">
            <label for="" class="form-label">How do you want to calculate total working hours</label>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="calculate_total_work" class="form-check-input" type="radio" [value]=false>
                    First check-in and last check-out
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Working hours is calculated based on the employee's first and last punches of the day."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="calculate_total_work" class="form-check-input" type="radio" [value]=true>
                    Every valid check-in and check-out
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Working hours is calculated based on every single punch done by the employee."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
        </div>

        <div class="col-12 form-row">
            <label for="" class="form-label">How do you want to manage punch</label>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="manage_punch" #rad1 class="form-check-input" type="radio" value="Double"
                        (click)="noneChange($event)">
                    Double
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Minimum two punches are required to mark the attendance. If there is only one punch the attendance status will show Absent"
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="manage_punch" #rad2 class="form-check-input" type="radio" value="Single"
                        (click)="noneChange($event)">
                    Single
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Only one punch is required to mark the attendance. Employee attendance status will be Present only if there is a Single punch."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="manage_punch" #rad3 class="form-check-input" type="radio" value="Auto"
                        (click)="noneChange($event)">
                    Auto
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="For Auto punch attendance status will be marked as present automatically"
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
        </div>

        <div class="col-12 form-row">
            <label for="" class="form-label required">How do you want to keep the timings?</label>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="keep_time" class="form-check-input" type="radio" value='Strict'
                        [attr.disabled]="(rad2.checked === true || rad3.checked === true)? true:null">
                    Strict
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Late coming penality or deduction will be applicable for this option."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="keep_time" class="form-check-input" type="radio" value='Flexi'
                        [attr.disabled]="(rad2.checked === true || rad3.checked === true)? true : null">
                    Flexi
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="Employees are required to complete a minimum number of working hours.Late coming penality or deduction will not be applicable for this option. "
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="keep_time" class="form-check-input" type="radio" value='None'
                        [attr.disabled]="rad1.checked === true ? true : null">
                    None
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="If Manage punch is Auto, this option is selected by default."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>

        </div>

        <div class="col-12 form-row">
            <label for="" class="form-label">Are week-offs treated as paid days?</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Weekly off count is considered as per the shift and weekly off mapped in employee's calendar.'Choose 'Paid' if you want weekly offs to be included in the salary dividing factor,or select 'Unpaid' if you want to exclude the week offs from the calculation.'"
                    popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="weekoff_paid" class="form-check-input" type="radio" [value]=true>
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="weekoff_paid" class="form-check-input" type="radio" [value]=false>
                    No
                </label>
            </div>
        </div>

        <div class=" col-12 form-row">
            <label for="" class="form-label">Attendance correction arrears upto</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Select until how many months arrears will be calculated by the system."
                    popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            <ng-select formControlName="arrear_upto" [clearable]="false"
                [ngClass]="{ 'is-invalid': (submitted || f.arrear_upto.touched) &&  f.arrear_upto.errors}"
                class="form-ngselect " placeholder="{{this.messageService.selectplaceholddisplay('value')}}">
                <ng-option *ngFor="let number of createRange(6,0)" [value]="number">{{displaymonth(number)}}
                </ng-option>
            </ng-select>
        </div>

        <div class="col-12 form-row">
            <label for="" class="form-label">Attendance cycle</label>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="attendance_cycle" class="form-check-input" type="radio" [value]=false
                        (change)="setDisabled(true,'end_day');attendance_cycle($event)">
                    Manual
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="For manual option you have to manually select the start and end days for attendance cycle calculation."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="attendance_cycle" class="form-check-input" type="radio" [value]=true
                        (change)="setDisabled(false,'end_day');attendance_cycle($event)">
                    Calendar
                </label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                        ngbPopover="For calendar option, attendance will be calculated from 1st to last day of the month."
                        popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
        </div>
        <ng-container *ngIf="formGp.controls['attendance_cycle'].value == false">
            <div class="col-12 form-row">
                <label for="" class="form-label">How would you like to calculate the attendance?</label>
            </div>
            <div class="col-lg-12 form-row mt-0">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="attendance_calculate" class="form-check-input" type="radio"
                            [value]=false
                            (ngModelChange)="selectedValue('attendance_calculate',formGp.controls['end_day'].value)">
                        Actual days
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                            ngbPopover="By default, start day will be 1 you need to select the  End Day. For eg. If you select the end day as 15. the system will automatically take the start day as 16. It means attendance will be calculated 16th of previous month to 15th of current month."
                            popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input formControlName="attendance_calculate" class="form-check-input" type="radio" [value]=true
                            (ngModelChange)="selectedValue('attendance_calculate',formGp.controls['end_day'].value)">
                        Optimistic
                    </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                            ngbPopover="By default start day will be 1. you need to select the end day. End Day will work as a cut off date. It means system will consider Actual Attendance of employees from Date 1st to 25th of the month from 26th to last day, system will consider Present automatically for that month. If employee is absent for any day between 26th to last day of the month, it will be considered as Negative Arrears which will be recovered from the employee."
                            popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
                </div>
            </div>
            <div class="col-lg-6 form-row">
                <label for="" class="form-label">Start day</label>
                <input formControlName="start_day" class="form-control" type="text"
                    placeholder="{{this.messageService.placeholderdisp('start day')}}" [attr.disabled]="true">

            </div>
            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">End day
                </label>
                <app-master-select class="config-app-master" [dropdownPosition]="'top'"
                    [ngClass]="{ 'is-invalid': (submitted || formGp.controls['end_day'].touched) &&  formGp.controls['end_day'].errors}"
                    [placeholder]="'Select end day'" id="end_day" [submitted]="submitted" [isrequired]="'true'"
                    [selectedItem]="formGp.controls['end_day'].value" formControlName="end_day"
                    [type]="'schedule_dates'" [range]="28" [lastDay]="false"
                    (selectedValue)="selectedValue('attendance_calculate',$event)"></app-master-select>

                <div *ngIf="(submitted || f.end_day.touched) && f.end_day.errors" class="invalid-feedback">
                    <div *ngIf="f.end_day.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
        </ng-container>
        <div class="col-12 form-row">
            <label for="" class="form-label">Mark [absent - weeklyoff - absent] as [absent - absent - absent] </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Enable this option if you want to Mark 'Absent' for the employee if the employee takes a day off before and after a week off"
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="mark_absent_weekoff" class="form-check-input" type="radio" [value]=true>
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline"
                [ngClass]="{ 'is-invalid': (submitted || f.mark_absent_weekoff.touched) &&  f.mark_absent_weekoff.errors}">
                <label class="form-check-label">
                    <input formControlName="mark_absent_weekoff" class="form-check-input" type="radio" [value]=false>
                    No
                </label>
            </div>
        </div>
        <div class="col-12 form-row">
            <label for="" class="form-label">Mark [absent - holiday - absent] as [absent - absent - absent] </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Enable this option if you want to Mark 'Absent' for the employee if the employee takes a day off before and after a Holiday."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
        </div>
        <div class="col-lg-12 form-row mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input formControlName="mark_absent_holiday" class="form-check-input" type="radio" [value]=true>
                    Yes
                </label>
            </div>
            <div class="form-check form-check-inline"
                [ngClass]="{ 'is-invalid': (submitted || f.mark_absent_holiday.touched) &&  f.mark_absent_holiday.errors}">
                <label class="form-check-label">
                    <input formControlName="mark_absent_holiday" class="form-check-input" type="radio" [value]=false>
                    No
                </label>
            </div>
        </div>
    </div>
</div>
<footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid"
        (click)="validateForm()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
</footer>
