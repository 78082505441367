import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EmployeeDirectoryRoutingModule } from './employee-directory-routing.module';
import { EmployeeDirectoryComponent } from './employee-directory/employee-directory.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';




@NgModule({
  declarations: [
    EmployeeDirectoryComponent,
    
   
  ],
  imports: [
    CommonModule,
    EmployeeDirectoryRoutingModule,
    NgbModule,
    InfiniteScrollModule,
    MasterSelectModule
    
  ]
})
export class EmployeeDirectoryModule { }
