<app-table-loader *ngIf="loader"></app-table-loader>
<div class="col-12" *ngIf="(landingScreenData.length<=0) && !loader">
  <div class="row" style="height: calc(100vh - 13rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg2.png'" [noDataText]="'Let\'s Manage Your Expense Receipts'"
      [noDataPara]="'Easily upload and manage all your expense receipts. Keep your expense records organized and accessible.'"
      [hasPermission]="false"> </app-nodata-view>
  </div>
</div>
<div class="col-12" *ngIf="!loader && (landingScreenData.length>0)">
  <div class="card card-c2">
      <div class="table-responsive radius-4" >
          <table
              class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
              <thead>
                  <tr>
                      <th class="fw-600 text-uppercase" scope="col">Receipt number</th>
                      <th class="fw-600 text-uppercase" scope="col">EXPENSE Date</th>
                      <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                      <th class="fw-600 text-uppercase" scope="col">AMOUNT</th>
                      <th class="fw-600 text-uppercase" scope="col">Report name</th>
                      <th class="fw-600 text-uppercase" scope="col">Status</th>
                      <th class="fw-600 text-uppercase" scope="col" *ngIf="permissions?.d">ACTION</th>

                  </tr>
              </thead>
              <tbody class="cell-16 ">
                <!-- <tr *ngIf="landingScreenData.length<=0">
                  <td colspan="7">
                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                        <span class="fw-500 fs-14">No data found</span>
                    </div>
                  </td>
                </tr> -->
                <ng-container *ngFor="let item of $any(landingScreenData |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                <tr [class]="item?.status == 'Expired' ? 'td-alert' : ''">
                      <td>
                          <a (click)="getSigleReceiptView(item?.id)" class="link-primary1">{{item?.number}}</a>
                      </td>
                      <td>
                          <span class="text-nowrap">{{this.appService.dateFormatDisplay(item?.expense_date)}}</span>
                      </td>
                      <td>{{item?.category.name}}</td>
                      <td> {{item?.amount | currency : currency}}</td>
                      <td>{{(item?.report.name!=''&&item?.report.name!=null)?item?.report.name:'--'}}</td>
                      <td>
                          <span class="badge {{statusList[item.status]}} py-1 px-3">{{item?.status}}</span>
                      </td>
                      <td *ngIf="permissions?.d">
                        <span class="link-danger" (click)="deletConfirm(item?.id,item.status)"> <i class="icon-trash"></i></span>

                    </td>
                </tr>
                </ng-container>
              </tbody>
          </table>
      </div>
  </div>
</div>
<div class="col-12" *ngIf="this.appService.filteredcount(landingScreenData,searchString)>pageSize">
  <ngb-pagination class="d-flex justify-content-end"
    [collectionSize]="this.appService.filteredcount(landingScreenData,searchString)" [(page)]="page" [maxSize]="3"
    [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
</div>

<app-view-expense-receipt *ngIf="viewReciept" [from]="'expenseReceipt'" [viewReciept]="viewReciept" [viewRecieptLoader]="viewRecieptLoader" [viewRecieptData]="viewRecieptData" [currency]="currency" (closeView)="closeView()"></app-view-expense-receipt>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16rem">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Delete Receipt?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">This will delete the receipt permanently. Do you want to delete?</p>
          </div>
          <div class="modal-footer" >
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="deleteToggle = false">Cancel</button>
              <button   type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteReceipt()">Delete</button>
          </div>
      </div>
  </div>
</div>
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="receiptMsg"></app-info-popup>
</ng-container>


