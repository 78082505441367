import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from 'src/app/@shared/components/cropping/cropper-dialog';
import { CropperService } from 'src/app/@core/services/cropper.service';
import { FileUploadService } from 'src/app/@core/services/file-upload.service';

@Component({
  selector: 'app-file-upload-docrepo',
  templateUrl: './file-upload-docrepo.component.html',
  styleUrls: ['./file-upload-docrepo.component.scss']
})
export class FileUploadDocrepoComponent implements OnInit {

  cropped?: string;
  @Input() crop:any;
  @Input() round:any;
  @Input() width:any;
  @Input() id:any;
  @Input() FileType:any;
  @Output() dataURL:any       = new EventEmitter();
  @Output() validsize:any     = new EventEmitter();
  @Output() fullfile:any      = new EventEmitter();
  @Output() loader:any        = new EventEmitter();
  @Output() fileformat:any    = new EventEmitter();
  constructor(
    private upload: FileUploadService,
    private cs: CropperService,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

  }

  //Method to convert URL string to Blob
  dataURItoBlob(dataURI: any) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  //File upload without CROP.
  sendFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if(Number(file.size) <= (10*1024*1024)) {

        const imageName       = file.name;
        // const extension       = file.type.split('/');
        // const final_extension = "."+extension[1];

        const extension       = event?.target?.files[0]?.name.split('.').pop();
        const final_extension = extension?.toLowerCase();

        if((this.FileType!='' && this.FileType.includes(final_extension)==true) || this.FileType==''){
          this.loader.emit(true);
          const reader = new FileReader();
          this.fullfile.emit(file);
          reader.readAsDataURL(file);
          reader.onload = event => {
            this.upload.fileUpload({name:imageName, dataURL:reader.result,document_repository:true}).subscribe((res: any) => {

              if(res.status == 200) {
                this.dataURL.emit(res.body.data_url);

              }
            })
          };
        }

        else{
            this.loader.emit(false);
            this.fileformat.emit(false);
      }
      }
        else {

          this.validsize.emit('File size should be less than 10MB.')
        }
    }
  }

  onFileChange(event: any) {
    const file            = event?.target?.files[0];
    const extension       = event?.target?.files[0]?.name?.split('.').pop();
    const final_extension = extension?.toLowerCase();

    if((this.FileType!='' && this.FileType.includes(final_extension)==true) || this.FileType==''){
      if(Number(file?.size) <= (10*1024*1024)) {
        this.cs.round = this.round; // set false for square cropping. Set true for round cropping.

        this.cropped = null!;

        if (this.crop == true) {
          this.loader.emit(true);

          this._dialog.open<CropperDialog, Event>(CropperDialog, {
            data: event,
            width: this.width,
            containerClass : "cropContainer",
            disableClose: true,
          }).afterClosed.subscribe((result?: ImgCropperEvent) => {
            if (result) {

              const base64              = result.dataURL;
              const imageName: any      = result.name;
              if(Number(result?.size) <= (10*1024*1024)) {

                this._cd.markForCheck();

                this.upload.fileUpload({name:imageName, dataURL:base64,document_repository:true}).subscribe((res: any) => {

                  if(res.status == 200) {
                    this.dataURL.emit(res.body.data_url)
                  }
                })
              }
              else{
                    this.validsize.emit('File size should be less than 10MB.')

              }
            }

          });
        } else {

          this.sendFile(event);
        }
      }
      else{
        this.validsize.emit('File size should be less than 10MB.')

      }
    }
    else{
      this.fileformat.emit(false);

    }
  }
}
