import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { MyTeamDashboardComponent } from './my-team-dashboard/my-team-dashboard.component';
import { MyTeamEmployeeDetailsComponent } from './my-team-employee-details/my-team-employee-details.component';
import { MyTeamAttendanceComponent } from './my-team-attendance/my-team-attendance.component';
import { MyTeamLocationComponent } from './my-team-location/my-team-location.component';

const routes: Routes = [ LayoutService.childRoutes([
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'my-team-dashboard', component: MyTeamDashboardComponent},
  { path: 'mted', component: MyTeamEmployeeDetailsComponent},
  { path: 'my-team-attendance', component: MyTeamAttendanceComponent},
  { path: 'my-team-attendance/my-team-employee-details/:myTeamEmpId', component: MyTeamEmployeeDetailsComponent},
  { path: 'my-team-location', component: MyTeamLocationComponent},

])];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyTeamRoutingModule { }
