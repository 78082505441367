<div *ngIf="permissions" class="section-layout section-rt"  style="--section-sideabar-width:24.5rem;"  >
  <div class="section-main p-24">
    <div class="row row-16">
      <div class="col-12">
        <h3>Dashboard</h3>
      </div>
      <!-- Employees At work -->
      <div class="col-12">
        <div class="card card-c2 p-24">
          <div class="row row-16">
            <div class="col-12 d-flex">
              <p class="fw-500 mb-0">Employees At work</p>
              <div class="input-group form-icon icon-right ms-auto h-36" style="width: 9rem;">
                <input (click)="datePicker3.open()" class="form-control fs-14" placeholder="Today" matInput
                  [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker3" (keydown)="disableDate()"
                  (dateChange)="getEmployeeAtWork($event.target.value)"><i class="icon-calendar pointer"
                  (click)="datePicker3.open()"></i>
                <mat-datepicker touchUi="false" #datePicker3></mat-datepicker>
              </div>
            </div>
            <app-common-loader *ngIf="employeeAtWorkLoader" [cardType]="'card15'"></app-common-loader>
            <ng-container *ngIf="!employeeAtWorkLoader">
              <ng-container *ngFor="let item of employeeAtWorkCount; let i = index">
                <div class="col">
                  <div class="card card-c2 p-8 vstack border-0 card-hover {{
                                        item?.header == 'ON LEAVE' ? 'cbg-1':
                                        item?.header == 'LATE'? 'cbg-2':
                                        item?.header == 'NOT IN' ? 'cbg-accent2':
                                        item?.header == 'ON DUTY' ? 'cbg-warning':
                                        item?.header == 'WFH' ? 'cbg-success':''
                                    }}" (click)="sidePanelView(item?.header)">
                    <div class="hstack">
                      <span class="fs-20 fw-500">{{item?.count}}</span>
                      <i class="fs-22 ms-auto p-6
                                            {{
                                                item?.header == 'ON LEAVE' ? 'icon-calendar-fill1':
                                                item?.header == 'LATE'? 'icon-clock-fill':
                                                item?.header == 'NOT IN' ? 'icon-calendar-fill-x':
                                                item?.header == 'ON DUTY' ? 'icon-briefcase-timer-fill':
                                                item?.header == 'WFH' ? 'icon-house-fill':''
                                            }}"></i>
                    </div>
                    <p class="mb-0 fs-12 text-light-600 text-uppercase">{{item?.header}}</p>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Workforce stats -->
      <div class="col-12">
        <div class="card card-c2 p-24">
          <div class="row row-24">
            <div class="col-12 d-flex ">
              <p class="fw-500 mb-0">Team stats</p>
              <div ngbDropdown class="input-dropdown  ms-auto" container="body">
                <button type="button" class="card card-c2 h-36 fs-14" ngbDropdownToggle>
                  <span class="">{{workforceCurrentMonth}} {{workforceCurrentYear}}</span>
                </button>
                <div ngbDropdownMenu class="card-c2 card p-24 " style="width:272px;">
                  <div class="row">
                    <div class="col-5">
                      <label for="" class="form-label">Year </label>
                      <ng-select placeholder="Month" class="form-ngselect fs-14 h-36" [(ngModel)]="workforceCurrentYear"
                        (change)="getWorkforceStates()">
                        <ng-option *ngFor="let yData of listYears" [value]="yData">{{yData}}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-7">
                      <label for="" class="form-label">Month </label>
                      <ng-select placeholder="Year" class="form-ngselect fs-14 four-dropdown h-36"
                        [(ngModel)]="workforceCurrentMonth" (change)="getWorkforceStates()">
                        <ng-option *ngFor="let yData of months" [value]="yData">{{yData}}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-common-loader *ngIf="workForceStatesLoader" [cardType]="'card15'"></app-common-loader>
            <div *ngIf="!workForceStatesLoader" class="col-12">
              <div class="card card-c2 horizontal-body border-0">
                <ng-container *ngFor="let work of workforceStatesData?.data; let i = index">
                  <div class="card-body py-0 {{i == 0 ? 'ps-0' : 'ps-32'}} pe-32">
                    <div class="fs-10 fw-500 text-uppercase">{{work?.subheader}} </div>
                    <ng-container *ngIf="work?.subheader == 'ATTENDANCE'">
                      <div class="fs-28 fw-500">{{work?.percentage}}%</div>
                      <div class="fs-10 text-uppercase d-flex gap-4">
                        <span class="{{work?.status == 'positive' ? 'text-success graph-increase' : 'text-danger graph-decrease'}}">{{work?.prevmonthpercentage}}%</span><span
                          class="text-light-400">From {{work?.prevmonth}}</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="work?.subheader != 'ATTENDANCE'">
                      <div class="fs-10 text-uppercase d-flex  align-items-baseline gap-4">
                        <div class="fs-28 fw-500">{{work?.hrs}}</div>
                        <span class="text-light-400">Hrs</span>
                        <div class="fs-28 fw-500">{{work?.min}}</div>
                        <span class="text-light-400">Min</span>
                      </div>
                      <div class="fs-10 text-uppercase d-flex gap-4">
                        <span
                          class="{{work?.status == 'positive' ? 'text-success graph-increase' : 'text-danger graph-decrease'}}">{{work?.prevmonthpercentage}}%</span><span
                          class="text-light-400">From {{work?.prevmonth}}</span>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- Leaderboard -->
            <div class="col-6">
              <div class="card card-c2 p-16 h-100">
                <div class="row row-16 h-100">
                  <div class="col-12 d-flex align-items-center">
                    <div class="vstack">
                      <p class="fw-500 mb-0">Leaderboard</p>
                      <p class="fs-10 mb-0 text-light-400 lh-1">{{leadboardFilter}}</p>
                    </div>

                    <ng-select class="form-ngselect fs-14 ms-auto h-36" style="width:11rem" placeholder="Less Leaves"
                      [(ngModel)]="leadboardFilter" (change)="getLeaderboard(leadboardFilter,'board')"
                      [items]="leaderboardData?.filterlist" bindValue="leadboardFilter" bindLabel="leadboardFilter">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="text-trim" title="{{item}}">{{item}}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div *ngIf="!leaderboardLoader && leaderboardData?.toplist?.length == 0" class="col-12">
                    <div class="flex-center gap-8 flex-column p-16 h-100">
                      <img src="assets\images\no-data\bg18.png" class="img-fluid nodata-sm">
                      <p class="fs-12 fw-600 text-uppercase text-light-400">NO DATA available</p>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="leaderboardLoader">
                    <div class="d-flex gap-50 justify-content-center">
                      <ngx-skeleton-loader appearance="circle"
                        [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
                    </div>
                  </div>
                  <ng-container *ngIf="!leaderboardLoader  && leaderboardData?.toplist?.length > 0">
                    <div class="col-12">
                      <div class="d-flex gap-20 justify-content-center text-center">
                        <div *ngIf="leaderboardData?.toplist?.[0]?.['2nd']"
                          class="d-flex flex-column align-items-center  flex-basis-100">
                          <div class="avatar-2nd mb-8">
                            <div class="avatar-overlap avatar-overlap-left "
                              style="--size:2.9375rem; --count : {{leaderboardData?.toplist?.[0]?.['2nd']?.length == 1 ? '1' : leaderboardData?.toplist?.[0]?.['2nd']?.length == 2 ? '2' : '3'}};--gap:.625rem;">
                              <ng-container
                                *ngFor="let second of leaderboardData?.toplist?.[0]?.['2nd']; let i = index">
                                <div *ngIf="i < 3 && (second?.profile_image == '' || second?.profile_image == null)"
                                  class="avatar fs-16 {{second?.color_code}}">{{
                                  this.appservice.getFirstChar(second?.first_name
                                  + "
                                  " + second?.last_name,2)}}</div>
                                <img *ngIf="i < 3 && (second?.profile_image != '' && second?.profile_image != null)"
                                  class="avatar  " src="{{second?.profile_image}}">
                              </ng-container>
                            </div>
                          </div>
                          <p class="fs-12 fw-500 mb-0 text-trim-2 mt-auto">
                            {{leaderboardDataSecond?.slice(0, 2)?.join(', ')}}
                            <span *ngIf="leaderboardDataSecond?.length > 2" class="text-accent1">
                              +{{subEmp(leaderboardDataSecond?.length)}}</span>
                          </p>
                          <span *ngIf="leaderboardDataSecond?.length == 1" class="fs-10 fw-500 text-light-400">{{
                            leaderboardData?.toplist?.[0]?.['2nd']?.[0]?.['designation']}}</span>
                        </div>
                        <div *ngIf="leaderboardData?.toplist?.[0]?.['1st']"
                          class="d-flex flex-column align-items-center  flex-basis-100">
                          <div class="avatar-1st ">
                            <div class="avatar-overlap avatar-overlap-left "
                              style="--size:3.75rem; --count : {{leaderboardData?.toplist?.[0]?.['1st']?.length == 1 ? '1' : leaderboardData?.toplist?.[0]?.['1st']?.length == 2 ? '2' : '3'}};--gap:.875rem;">
                              <ng-container *ngFor="let first of leaderboardData?.toplist?.[0]?.['1st']; let i = index">
                                <div *ngIf="i < 3 && (first?.profile_image == '' || first?.profile_image == null)"
                                  class="avatar fs-16 {{first?.color_code}}">{{
                                  this.appservice.getFirstChar(first?.first_name
                                  + "
                                  " + first?.last_name,2)}}</div>
                                <img *ngIf="i < 3 && (first?.profile_image != '' && first?.profile_image != null)"
                                  class="avatar " src="{{first?.profile_image}}">
                              </ng-container>
                            </div>
                          </div>
                          <p class="fs-12 fw-500 mb-0 text-trim-2 mt-auto">
                            {{leaderboardDataFirst?.slice(0, 2)?.join(', ')}}
                            <span *ngIf="leaderboardDataFirst?.length > 2" class="text-accent1">
                              +{{subEmp(leaderboardDataFirst?.length)}}</span>
                          </p>
                          <span *ngIf="leaderboardDataFirst?.length == 1" class="fs-10 fw-500 text-light-400">{{
                            leaderboardData?.toplist?.[0]?.['1st']?.[0]?.['designation']}}</span>
                        </div>
                        <div *ngIf="leaderboardData?.toplist?.[0]?.['3rd']"
                          class="d-flex flex-column align-items-center  flex-basis-100">
                          <div class="avatar-3rd mb-8">
                            <div class="avatar-overlap avatar-overlap-left "
                              style="--size:3.125rem; --count : {{leaderboardData?.toplist?.[0]?.['3rd']?.length == 1 ? '1' : leaderboardData?.toplist?.[0]?.['3rd']?.length == 2 ? '2' : '3'}};--gap:.625rem;">
                              <ng-container *ngFor="let third of leaderboardData?.toplist?.[0]?.['3rd']; let i = index">
                                <div *ngIf="i < 3 && (third?.profile_image == '' || third?.profile_image == null)"
                                  class="avatar fs-16 {{third?.color_code}}">{{
                                  this.appservice.getFirstChar(third?.first_name
                                  + "
                                  " + third?.last_name,2)}}</div>
                                <img *ngIf="i < 3 && (third?.profile_image != '' && third?.profile_image != null)"
                                  class="avatar " src="{{third?.profile_image}}">
                              </ng-container>
                            </div>
                          </div>
                          <p class="fs-12 fw-500 mb-0 text-trim-2 mt-auto">
                            {{leaderboardDataThird?.slice(0, 2)?.join(', ')}}
                            <span *ngIf="leaderboardDataThird?.length > 2" class="text-accent1">
                              +{{subEmp(leaderboardDataThird?.length)}}</span>
                          </p>
                          <span *ngIf="leaderboardDataThird?.length == 1" class="fs-10 fw-500 text-light-400">{{
                            leaderboardData?.toplist?.[0]?.['3rd']?.[0]?.['designation']}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 flex-center link-primary1 fs-12 py-8 fw-600" (click)="getSeAllFunction()">
                      SEE ALL
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <!-- Employee punch sources -->
            <!-- <div class="col-6">
                <div class="card card-c2 p-16 h-100">
                    <div class="row row-16 h-100">
                        <div class="col-12 d-flex align-items-center">
                            <p class="fw-500 mb-0">Employee punch sources</p>
                            <div ngbDropdown class="input-dropdown four-dropdown ms-auto" container="body">
                                <button type="button" class="card card-c2 h-36 fs-14" ngbDropdownToggle>
                                    <span class="">{{punchSourceCurrentMonth}} {{punchSourceCurrentYear}}</span>
                                </button>
                                <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="" class="form-label">Year </label>
                                            <ng-select style="width:9rem" placeholder="Month" class="form-ngselect h-36"
                                                [(ngModel)]="punchSourceCurrentYear"
                                                (change)="empPunchChangeFunction()">
                                                <ng-option *ngFor="let yData of listYears"
                                                    [value]="yData">{{yData}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-7">
                                            <label for="" class="form-label">Month </label>
                                            <ng-select style="width:9rem" placeholder="Year" class="form-ngselect h-36"
                                                [(ngModel)]="punchSourceCurrentMonth"
                                                (change)="empPunchChangeFunction()">
                                                <ng-option *ngFor="let yData of months"
                                                    [value]="yData">{{yData}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="punchSourceLoader" class="col-12"><ngx-skeleton-loader appearance="circle"
                                [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '100%','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="!punchSourceLoader && punchSourceData?.data?.length == 0" class="col-12  ">
                            <div class="flex-center gap-8 flex-column p-16 h-100 ">
                                <img src="/assets/images/helper/helper3.png" alt="" srcset="">
                                <p class="mb-0 fs-12 lh-base">
                                    No data to show
                                </p>
                            </div>
                        </div>
                        <div  class="col-12">
                            <app-doughnut-chart [data]="punchSourceData" [color]="attendanceColor" [labels]="punchSourceLabels" [total]="'Total'" [id]="0" [showRupee]="false"></app-doughnut-chart>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-6">
              <div class="card card-c2 p-16 h-100">
                <div class="row row-16 h-100">
                  <div class="col-12 d-flex align-items-center">
                    <p class="fw-500 mb-0">Employee punch sources</p>
                    <!-- <div ngbDropdown class="input-dropdown four-dropdown ms-auto" container="body">
                                            <button type="button" class="card card-c2 h-36 fs-14" ngbDropdownToggle>
                                                <span class="">{{punchSourceCurrentMonth}}
                                                    {{punchSourceCurrentYear}}</span>
                                            </button>
                                            <div ngbDropdownMenu class="card-c2 card p-24 " style="width: 352px;">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <label for="" class="form-label">Year </label>
                                                        <ng-select style="width:9rem" placeholder="Month"
                                                            class="form-ngselect h-36 fs-14"
                                                            [(ngModel)]="punchSourceCurrentYear"
                                                            (change)="getPunchSource()">
                                                            <ng-option *ngFor="let yData of listYears"
                                                                [value]="yData">{{yData}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-7">
                                                        <label for="" class="form-label">Month </label>
                                                        <ng-select style="width:9rem" placeholder="Year"
                                                            class="form-ngselect h-36 fs-14"
                                                            [(ngModel)]="punchSourceCurrentMonth"
                                                            (change)="getPunchSource()">
                                                            <ng-option *ngFor="let yData of months"
                                                                [value]="yData">{{yData}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                  <div *ngIf="punchSourceLoader" class="col-12"><ngx-skeleton-loader appearance="circle"
                      [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '100%','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="!punchSourceLoader && punchSourceData?.data?.length == 0" class="col-12  ">
                    <div class="flex-center gap-8 flex-column p-16 h-100 ">
                      <img src="assets\images\no-data\bg18.png" class="img-fluid nodata-sm">
                      <p class="fs-12 fw-600 text-uppercase text-light-400">NO DATA available</p>
                    </div>
                  </div>
                  <div *ngIf="!punchSourceLoader && punchSourceData?.data?.length > 0" class="col-12">
                    <app-doughnut-chart [radius]="45" [data]="punchSourceChartData" [color]="attendanceColor"
                      [labels]="punchSourceLabels" [total]="'Total'" [id]="0" [showRupee]="false" [from]="'myteam'"></app-doughnut-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- Team Updates -->
  <div class="section-sidebar py-26 px-16 ">
    <div class="row row-32">
      <div class="col-12">
        <div class="row row-16">
          <div class="col-12">
            <span class="fs-12 fw-600 text-light-500  text-uppercase">Team Updates</span>
          </div>
          <div class="col-12" *ngIf="teamUpdateLoader">
            <app-common-loader class="row row-16" [cardType]="'card16'"></app-common-loader>
          </div>
          <div class="col-md-6" *ngIf="!teamUpdateLoader">
            <div class="card card-c2 p-16 bg14 h-100  card-hover" (click)="joiningThisMonthSidePanelView()">
              <div class="row h-100">
                <div class="col-12">
                  <span class="fs-12 fw-600 text-uppercase">Joining this month</span>
                </div>
                <div *ngIf="teamUpdateData?.joiningmonthemployeesdata?.length == 0" class="col-12 ">
                  <div class="flex-center gap-8  py-16 h-100">
                    <img src="/assets/images/helper/helper4.png" alt="" srcset="">
                    <p class="mb-0 fs-12 lh-base">
                      No new joiners this month
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="teamUpdateData?.joiningmonthemployeesdata?.length > 0">
                  <div class="col-12 mt-8">
                    <div class="avatar-overlap avatar-overlap-left ">
                      <ng-container *ngFor="let join of teamUpdateData?.joiningmonthemployeesdata,let i = index">
                        <img class="avatar" *ngIf="i < 4 && join?.profile_image !=  '' && join?.profile_image !=  null"
                          src="{{join?.profile_image}}" [ngbPopover]="popContentSingle"
                          popoverClass="user-list single-user" triggers="hover" container="body">
                        <div class="avatar sq-32 {{join?.color_code}}"
                          *ngIf="i < 4 && (join?.profile_image ==  ''|| join?.profile_image ==  null)"
                          [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                          container="body">
                          <span>{{this.appservice.getFirstChar(join?.first_name+'
                            '+join?.last_name,2)}}</span>
                        </div>
                        <ng-template #popContentSingle>
                          <div class="user-details">
                            <span class="user-label">{{ join?.first_name +
                              " " +
                              ((join?.middle_name != null && join?.middle_name != '')
                              ? join?.middle_name
                              : "") +
                              " " +
                              join?.last_name}}</span>
                            <span class="user-code">EMP CODE: {{join?.employee_code}}
                            </span>
                          </div>
                        </ng-template>
                        <div *ngIf="i==4" class="avatar sq-32" [ngbPopover]="popContentMultilpe"
                          popoverClass="user-list multi-user" triggers="click" container="body" (click)="onPopoverClick($event)">
                          <span>+{{this.appservice.subEmp(teamUpdateData?.joiningmonthemployeesdata?.length - 1)}}</span>
                        </div>
                      </ng-container>
                      <ng-template #popContentMultilpe>
                        <ul>
                          <ng-container *ngFor="let joine of teamUpdateData?.joiningmonthemployeesdata,let i = index">
                            <ng-container *ngIf="i >= 4">
                              <li *ngIf="joine?.profile_image !='' && joine?.profile_image !=null">
                                <img class="user-avatar" src="{{joine?.profile_image}}">
                                <div class="user-details">
                                  <span class="user-label">{{ joine?.first_name +
                                    " " +
                                    ((joine?.middle_name != null &&
                                    joine?.middle_name
                                    != '')
                                    ? joine?.middle_name
                                    : "") +
                                    " " +
                                    joine?.last_name}}</span>
                                  <span class="user-code">EMP CODE :
                                    {{joine?.employee_code}}</span>
                                </div>
                              </li>
                              <li *ngIf="joine?.profile_image =='' || joine?.profile_image ==null">
                                <span
                                  class="user-avatar {{joine?.color_code}}">{{this.appservice.getFirstChar(joine?.first_name+'
                                  '+joine?.last_name,2)}}</span>
                                <div class="user-details">
                                  <span class="user-label">{{ joine?.first_name +
                                    " " +
                                    ((joine?.middle_name != null &&
                                    joine?.middle_name
                                    != '')
                                    ? joine?.middle_name
                                    : "") +
                                    " " +
                                    joine?.last_name}}</span>
                                  <span class="user-code">EMP CODE :
                                    {{joine?.employee_code}}</span>
                                </div>
                              </li>
                            </ng-container>
                          </ng-container>
                        </ul>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-12 mt-1">
                    <div class="fs-12 text-light-400 ">
                      {{teamUpdateData?.subheaderlist?.[0]?.['count']
                      == 0
                      ? teamUpdateData?.subheaderlist?.[0]?.['count']+' Employee' :
                      teamUpdateData?.subheaderlist?.[0]?.['count']+' Employees' }} </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="!teamUpdateLoader">
            <div class="card card-c2 p-16 bg-warning card-hover h-100" (click)="leaveingThisMonthSidePanelView()">
              <div class="row">
                <div class="col-12">
                  <span class="fs-12 fw-600 text-uppercase">Leaving this month</span>
                </div>
                <div *ngIf="teamUpdateData?.leavingmonthemployeesdata?.length == 0" class="col-12 ">
                  <div class="flex-center gap-8  py-16 ">
                    <img src="/assets/images/helper/helper5.png" alt="" srcset="">
                    <p class="mb-0 fs-12  lh-base">
                      No one is leaving this month
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="teamUpdateData?.leavingmonthemployeesdata?.length > 0">
                  <div class="col-12  mt-8">
                    <div class="avatar-overlap avatar-overlap-left">
                      <ng-container *ngFor="let leave of teamUpdateData?.leavingmonthemployeesdata,let i = index">
                        <img class="avatar"
                          *ngIf="i < 4 && leave?.profile_image !=  '' && leave?.profile_image !=  null"
                          src="{{leave?.profile_image}}" [ngbPopover]="popContentSingle"
                          popoverClass="user-list single-user" triggers="hover" container="body">
                        <div class="avatar sq-32 {{leave?.color_code}}"
                          *ngIf="i < 4 && (leave?.profile_image ==  ''|| leave?.profile_image ==  null)"
                          [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                          container="body">
                          <span>{{this.appservice.getFirstChar(leave?.first_name+'
                            '+leave?.last_name,2)}}</span>
                        </div>
                        <ng-template #popContentSingle>
                          <div class="user-details">
                            <span class="user-label">{{ leave?.first_name +
                              " " +
                              ((leave?.middle_name != null && leave?.middle_name != '')
                              ? leave?.middle_name
                              : "") +
                              " " +
                              leave?.last_name}}</span>
                            <span class="user-code">EMP CODE: {{leave?.employee_code}}
                            </span>
                          </div>
                        </ng-template>
                        <div *ngIf="i==4" class="avatar sq-32" [ngbPopover]="popContentMultilpe"
                          popoverClass="user-list multi-user" triggers="click" container="body" (click)="onPopoverClick($event)">
                          <span>+{{this.appservice.subEmp(teamUpdateData?.leavingmonthemployeesdata?.length - 1)}}</span>
                        </div>
                      </ng-container>
                      <ng-template #popContentMultilpe>
                        <ul>
                          <ng-container *ngFor="let leaving of teamUpdateData?.leavingmonthemployeesdata,let i = index">
                            <ng-container *ngIf="i >= 4">
                              <li *ngIf="leaving?.profile_image !='' && leaving?.profile_image !=null">
                                <img class="user-avatar" src="{{leaving?.profile_image}}">
                                <div class="user-details">
                                  <span class="user-label">{{ leaving?.first_name +
                                    " " +
                                    ((leaving?.middle_name != null &&
                                    leaving?.middle_name !=
                                    '')
                                    ? leaving?.middle_name
                                    : "") +
                                    " " +
                                    leaving?.last_name}}</span>
                                  <span class="user-code">EMP CODE :
                                    {{leaving?.employee_code}}</span>
                                </div>
                              </li>
                              <li *ngIf="leaving?.profile_image =='' || leaving?.profile_image ==null">
                                <span
                                  class="user-avatar {{leaving?.color_code}}">{{this.appservice.getFirstChar(leaving?.first_name+'
                                  '+leaving?.last_name,2)}}</span>
                                <div class="user-details">
                                  <span class="user-label">{{ leaving?.first_name +
                                    " " +
                                    ((leaving?.middle_name != null &&
                                    leaving?.middle_name !=
                                    '')
                                    ? leaving?.middle_name
                                    : "") +
                                    " " +
                                    leaving?.last_name}}</span>
                                  <span class="user-code">EMP CODE :
                                    {{leaving?.employee_code}}</span>
                                </div>
                              </li>
                            </ng-container>
                          </ng-container>
                        </ul>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-12  mt-1">
                    <div class="fs-12 text-light-400">
                      {{teamUpdateData?.subheaderlist?.[1]?.['count'] ==
                      0
                      ? teamUpdateData?.subheaderlist?.[1]?.['count']+' Employee' :
                      teamUpdateData?.subheaderlist?.[1]?.['count']+' Employees' }} </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- My Team -->
      <div class="col-12">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <span class="fs-12 fw-600 text-light-500 text-uppercase">My Team</span>
            <span (click)="hierarchy = true"
              class="fs-12 fw-600 text-uppercase link-primary1 ms-auto d-flex align-items-center gap-5"><i
                class="bi bi-diagram-2 fs-16"></i>Hierarchy</span>
          </div>
          <div class="col-12">
            <div class="card card-c2 bg-transparent">
              <div class="card-body p-0">
                <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab"
                  class="nav nav-custom sticky top-48 p-0 shadow-0 nav-justified bg-transparent">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink class="nav-link">Reporting
                      ({{myTeamData?.subheaderlist?.[0]?.['count']}})</a>
                    <ng-template ngbNavContent class="h-100">
                      <div *ngIf="!myTeamLoader && myTeamReportingData?.length == 0" class="flex-center gap-8 flex-column p-16 h-100 ">
                        <img src="assets\images\no-data\bg20.png" class="img-fluid nodata-sm">
                        <p class="fs-12 fw-600 text-uppercase text-light-400">NO DATA available</p>
                      </div>
                      <div *ngIf="myTeamLoader" class="p-16">
                        <app-c1-loader></app-c1-loader>
                      </div>
                      <ul class="p-0 m-0 d-flex li-hover flex-column h-100"
                        *ngIf="!myTeamLoader && myTeamReportingData?.length > 0">
                        <ng-container>
                          <li class="d-flex align-items-center px-16 py-8 gap-16" (click)="myTeamRouting(reporting?.id)"
                            *ngFor="let reporting of myTeamReportingData; let i = index">
                            <div *ngIf="reporting?.profile_image == '' || reporting?.profile_image == null"
                              class="avatar-circle sq-42 fs-14 {{reporting?.color_code}} avatar-border">
                              {{
                              this.appservice.getFirstChar(
                              reporting?.first_name + " " + reporting?.last_name,
                              2
                              )
                              }}</div>
                            <img *ngIf="
                                              reporting?.profile_image != '' &&
                                              reporting?.profile_image != null"
                              class="avatar-circle avatar-border sq-42" src="{{reporting?.profile_image}}">
                            <div class="d-flex flex-column">
                              <!-- (click)="myTeamRouting(reporting?.id)" -->
                              <span  class="fs-14 fw-500"
                                style="cursor: pointer;">{{
                                reporting?.first_name +
                                " " +
                                ((reporting?.middle_name != null &&
                                reporting?.middle_name != '')
                                ? reporting?.middle_name
                                : "") +
                                " " +
                                reporting?.last_name
                                }} </span>
                              <span class="fs-12 fw-500 text-light-400">{{reporting?.designation}}
                                | {{reporting?.employee_code}}</span>
                            </div>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink class="nav-link">Functional
                      ({{myTeamData?.subheaderlist?.[1]?.['count']}})</a>
                    <ng-template ngbNavContent>
                      <div *ngIf="!myTeamLoader && myTeamFunctionalData?.length == 0" class="flex-center gap-8 flex-column p-16 h-100 ">
                        <img src="assets\images\no-data\bg20.png" class="img-fluid nodata-sm">
                        <p class="fs-12 fw-600 text-uppercase text-light-400">NO DATA available</p>
                      </div>
                      <div *ngIf="myTeamLoader" class="p-16">
                        <app-c1-loader></app-c1-loader>
                      </div>
                      <ul class="p-0 m-0 d-flex li-hover flex-column "
                        *ngIf="!myTeamLoader && myTeamFunctionalData?.length > 0">
                        <ng-container>
                          <li class="d-flex align-items-center px-16 py-8  gap-16"
                            *ngFor="let functional of myTeamFunctionalData; let i = index">
                            <div *ngIf="functional?.profile_image == '' ||
                                                functional?.profile_image == null
                                              " class="avatar-circle sq-42 {{functional?.color_code}} avatar-border">{{
                              this.appservice.getFirstChar(
                              functional?.first_name + " " + functional?.last_name,
                              2
                              )
                              }}</div>
                            <img *ngIf="
                                                    functional?.profile_image != '' &&
                                                    functional?.profile_image != null"
                              class="avatar-circle avatar-border sq-42" src="{{functional?.profile_image}}">
                            <div class="d-flex flex-column">
                              <span class="fs-14 fw-500">{{
                                functional?.first_name +
                                " " +
                                ((functional?.middle_name != null &&
                                functional?.middle_name != '')
                                ? functional?.middle_name
                                : "") +
                                " " +
                                functional?.last_name
                                }} </span>
                              <span class="fs-12 fw-500 text-light-400">{{functional?.designation}}
                                | {{functional?.employee_code}}</span>
                            </div>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-template>
                  </li>
                </nav>
              </div>
              <div class="card-body p-0  overflow-scroll" style="height: 12.5rem;">
                <div [ngbNavOutlet]="nav" class="h-100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Employee Timeoff -->
      <div class="col-12">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <div class=" d-flex flex-column  ">
              <span class="fs-12 fw-600 text-light-500 text-uppercase">Employee Timeoff</span>
              <span class="fs-12 text-light-400 text-uppercase">{{(timeOffData?.fromdate == null ||
                timeOffData?.fromdate == '') ? '--' :
                this.appservice.dateFormatDisplay(timeOffData?.fromdate)}} - {{(timeOffData?.todate ==
                null || timeOffData?.todate == '') ? '--' :
                this.appservice.dateFormatDisplay(timeOffData?.todate)}}</span>
            </div>
            <ng-select class="form-ngselect fs-14 ms-auto h-36" placeholder="Days" style="width:8.25rem"
              [(ngModel)]="timeOffSelectedData" (change)="getTimeOff()" name="timeOffSelectedData">
              <ng-option *ngFor="let time of timeOffDropdown; let i = index" [value]="time"> {{time +'
                Days'}} </ng-option>
            </ng-select>
          </div>
          <div *ngIf="!timeOffLoader && timeOffData?.data?.length == 0" class="col-12 h-100">
            <div class="card card-c2 bg-transparent" style="height: 200px;">
              <div class="flex-center gap-8 flex-column p-16 h-100 ">
                <img src="assets\images\no-data\bg20.png" class="img-fluid nodata-sm">
                <p class="fs-12 fw-600 text-uppercase text-light-400">NO DATA available</p>
              </div>
            </div>
          </div>
          <app-c1-loader *ngIf="timeOffLoader"></app-c1-loader>
          <div *ngIf="!timeOffLoader && timeOffData?.data?.length > 0" class="col-12">
            <div class="card card-c2 bg-transparent">
              <div class="card-body p-0  overflow-scroll" style="height: 200px;">
                <ul class="p-0 m-0 d-flex flex-column li-hover ">
                  <li class="d-flex align-items-center px-16 py-8  gap-16"
                    *ngFor="let timeoff of timeOffData?.data; let i = index">
                    <div *ngIf="timeoff?.profile_image == '' || timeoff?.profile_image == null"
                      class="avatar-circle sq-42 {{timeoff?.color_code}} avatar-border">
                      {{this.appservice.getFirstChar(timeoff?.first_name + " " +
                      timeoff?.last_name,2)}}</div>
                    <img *ngIf="timeoff?.profile_image != '' && timeoff?.profile_image != null"
                      class="avatar-circle sq-42" src="{{timeoff?.profile_image}}" />
                    <div class="d-flex flex-column">
                      <span class="fs-14 fw-500">{{timeoff?.first_name +" " +
                        ((timeoff?.middle_name != null && timeoff?.middle_name != '') ?
                        timeoff?.middle_name : "") +" " +timeoff?.last_name}} </span>
                      <span class="fs-12 fw-500 text-light-400">{{timeoff?.designation}} |
                        {{timeoff?.employee_code}} </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Leaderboard side panel -->
<div *ngIf="permissions" class="side-panel" style="--sidepanel-width:27rem;"
  [class.side-pane-active]='leaderBoard === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Leaderboard</h5>
      <a class="toggle-panel" (click)="leaderBoard = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32 h-100">
        <div class="row row-16 ">
          <div class="col-12 d-flex align-items-center">
            <div class="vstack">
              <p class="fw-500 mb-0">Leaderboard</p>
              <p class="fs-10 mb-0 text-light-400 lh-1">{{leadboardFilterSeeAll}}</p>
            </div>

            <ng-select class="form-ngselect fs-14 ms-auto h-36" style="width:11rem" placeholder="Less Leaves"
              [(ngModel)]="leadboardFilterSeeAll" (change)="getLeaderboard(leadboardFilterSeeAll,'sideView')"
              name="leadboardFilterSeeAll">
              <ng-option *ngFor="let filter of leaderboardData?.filterlist; let i = index" [value]="filter"> {{filter}}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="!leaderboardLoaderSeeAll && leaderboardDataSeeAll?.length == 0" class="col-12 h-100">
            <div class="flex-center gap-8 flex-column p-16 h-100">
              <img src="/assets/images/helper/helper3.png" alt="" srcset="">
              <p class="mb-0 fs-12">
                No data to show
              </p>
            </div>
          </div>
          <app-c1-loader *ngIf="leaderboardLoaderSeeAll"></app-c1-loader>
          <ng-container *ngIf="!leaderboardLoaderSeeAll && leaderboardDataSeeAll?.length > 0">
            <div *ngFor="let li of leaderboardDataSeeAll?.[0]| keyvalue : originalOrder; let ind=index" class="col-12">
              <div class="card card-c2">
                <div class="card-body indicater4 py-8 fw-600 fs-14 " [ngClass]="{
                                    'cbg-warning indicater-warning': ind%4 === 0,
                                    'cbg-accent1 indicater-bg10' : ind%4 === 1 ,
                                    'cbg-accent2 indicater-bg1' : ind%4 === 2 ,
                                    'cbg-3 indicater-bg4' : ind%4 === 3
                                }">
                  {{li.key}} Rank </div>
                <div class="card-body vstack gap-18 overflow-auto" style="max-height:10rem">
                  <div class="hstack" *ngFor="let first of getList(li.key); let i = index">
                    <div *ngIf="(first?.profile_image == '' || first?.profile_image == null)"
                      class="avatar-border avatar-circle sq-42 fs-14 {{first?.color_code}}">{{
                      this.appservice.getFirstChar(first?.first_name
                      + "
                      " + first?.last_name,2)}}</div>
                    <img *ngIf="(first?.profile_image != '' && first?.profile_image != null)"
                      class="avatar-border avatar-circle sq-42 fs-14 " src="{{first?.profile_image}}">
                    <div class="overflow-hidden ms-16 d-flex flex-column gap-1">
                      <span class="fs-14 fw-500 ">{{first?.first_name +" " +
                        ((first?.middle_name != null && first?.middle_name != '') ?
                        first?.middle_name :
                        "") + " " + first?.last_name}}</span>
                      <span class="fs-12 fw-500 text-light-400 ">{{first?.designation}} |
                        {{first?.employee_code}} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- Organization hierarchy -->
<div *ngIf="permissions" class="side-panel" style="--sidepanel-width:56.25rem;"
  [class.side-pane-active]='hierarchy === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Hierarchy </h5>
      <a class="toggle-panel" (click)="hierarchy = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid px-24 pt-24 pb-0">
        <div class="row row-16 ">
          <div class="col-12 d-flex ">
            <p class="text-uppercase mb-0">Organization Hierarchy</p>
          </div>
        </div>
      </div>
      <app-organization-hierarchy *ngIf="hierarchy" [from]="'myteam'"></app-organization-hierarchy>
    </div>
  </form>
</div>
<!-- Side panel View -->
<ng-container *ngIf="sideViewPanel">
  <app-non-mapped-empp [showSidePanel]="sideViewPanel" [selectedList]="sideViewDataList"
    [selectedName]="sideViewHeading" (closePanel)="closePanel($event)"></app-non-mapped-empp>
</ng-container>
