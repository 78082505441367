import { Component, OnInit } from '@angular/core';
import { EmployeeDirectoryService } from 'src/app/@core/services/employee-directory.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-tabs-permissions',
  templateUrl: './tabs-permissions.component.html',
  styleUrls: ['./tabs-permissions.component.scss']
})
export class TabsPermissionsComponent implements OnInit {

  permissions : any;
  tabPermissionData:any;
  loader      = false;


  constructor(
    public edService:EmployeeDirectoryService,
    public appService: AppService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.getTabsPermission();
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  getTabsPermission(){
    this.loader = true;
    this.edService.getTabsPermissionList().subscribe((res:any)=>{
      this.tabPermissionData = res;
      this.loader            = false;
    })

  }
  savePermission(index:any){

    let data = {
      "employee_visible" : this.tabPermissionData[index].employee_visible,
      "manager_visible"  : this.tabPermissionData[index].manager_visible,

    }
    this.edService.updateTabsPermission(this.tabPermissionData[index].id,data).subscribe((res:any)=>{
      this.getTabsPermission();
    })
  }


}
