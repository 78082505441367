<form [formGroup]="notifyForm" autocomplete="off" *ngIf="permissions">
  <div class="container-fluid p-24">
    <div class="row row-16">
      <div class="col-lg-2 col-md-3">
        <div class="card card-c2 overflow-auto" style="height: calc(100vh - 6rem);">
        <app-side-menu-loader *ngIf="loader"></app-side-menu-loader>
         <ng-container *ngIf="!loader">
          <div class="card-body py-24 px-0 ">
            <div class="text-uppercase fs-12 fw-500 px-24 text-light-500 pb-8">NOTIFICATIONS</div>
            <ul class="navbar-nav nav-vertical nav ms-auto">
              <li class="nav-item" ngbDropdown [class.show]="getMainChildClass(i)" *ngFor="let menu of sideMenuArr; let i = index" (click)="onMenuClick(i)">
                <a class="nav-link" tabindex="0" ngbDropdownToggle [id]="i" role="button">
                  <span title="{{ menu.module }}">{{ menu.module }}</span>
                  <span>{{ menu.count }}</span>
                </a>
                <div ngbDropdownMenu aria-labelledby=`i` class="dropdown-menu" [class.show]="getMainChildClass(i)" *ngIf="menu?.children.length != 0">
                  <ng-container *ngFor="let sub of menu?.children">
                    <a ngbDropdownItem (click)="setMenu(sub?.id,sub?.type,i)" [class.active]="selectedMenu == sub?.type">
                      <span title="{{sub?.type}}" >{{ sub?.type }}</span>
                    </a>
                  </ng-container>
                </div>
              </li>
            </ul>
          </div>
         </ng-container>
        </div>
      </div>
      <div class="col-lg-10 col-md-9">
        <app-table-loader *ngIf="loaderMenu"></app-table-loader>
        <div class="card card-c2 p-16 overflow-auto" style="height: calc(100vh - 6rem);" *ngIf="!loaderMenu">
          <div class="row row-16">
            <div class="{{subMenuArr?.length == 0 ? '' : 'col '}}" style="max-width:13.5rem">
              <div class="card card-c2 overflow-auto bg29 " style="height: calc(100vh - 9rem);" *ngIf="subMenuArr?.length != 0">
                <div class="card-body py-24 px-0 ">
                  <div class="text-uppercase fs-12 fw-500 px-24 text-light-500 pb-8 text-trim" title="{{selectedMenu}}">{{selectedMenu}}</div>
                  <ul class="navbar-nav nav-vertical nav nav-inverse ms-auto">
                    <li class="nav-item" ngbDropdown *ngFor="let menu of subMenuArr; let i = index">
                      <a class="nav-link" tabindex="0" (click)="subSectionArray(menu?.type)" ngbDropdownToggle [id]="i" role="button" [class.active]="selectedSubMenu == menu?.type">
                        <span title="{{menu?.type}}">{{ menu?.type }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                </div>
            </div>
            <div class="{{subMenuArr?.length == 0 ? '' : 'col'}}">
              <div class="row row-24">
                <div class="col-12">
                  <p class="title mb-0">{{subMenuArr?.length == 0 ? selectedMenu : selectedSubMenu}}</p>
                </div>
                <div class="col-12">
                  <div class="card card-c2 overflow-hidden">
                    <div class="table-responsive" style="max-height: calc(100vh - 12.2rem);">
                      <table class="table  td-12 sticky-header th-12 table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="fw-600" scope="col">NOTIFICATION NAME</th>
                            <th class="fw-600" scope="col">PUSH NOTIFICATION</th>
                            <th class="fw-600" scope="col">EMAIL NOTIFICATION</th>
                            <th class="fw-600" scope="col" *ngIf="subMenuArr?.length == 0">Receiver</th>
                            <th class="fw-600" scope="col"> </th>
                          </tr>
                        </thead>
                        <tbody class="cell-16" formArrayName="menusList">
                          <ng-container *ngFor="let innerItem of notifyControls()?.controls; let i = index;"
                          [formGroupName]="i">
                          <ng-container *ngIf="conditionControlSatisfied(i,subMenuArr?.length != 0)" formArrayName="additionalControl">
                            <ng-container *ngFor="let innerItem of getAdditionalControls(i).controls; let j = index;"
                            [formGroupName]="j">
                              <tr *ngIf="subMenuControlSatisfied(subMenuArr?.length != 0,innerItem)">
                                <td>
                                  {{getAdditionalControls(i)?.at(j)?.get('menuName')?.value }} </td>
                                  <td class="form-cell">
                                    <div class="form-check form-switch">
                                      <input class="form-check-input" type="checkbox" formControlName="push_notify" (change)="toggleChange(i,j,'push_notify')">
                                    </div>
                                  </td>
                                  <td class="form-cell">
                                    <div class="form-check form-switch">
                                      <input class="form-check-input" type="checkbox" formControlName="email_notify" (change)="toggleChange(i,j,'email_notify')">
                                    </div>
                                  </td>
                                  <td *ngIf="subMenuArr?.length == 0">{{getAdditionalControls(i)?.at(j)?.get('receiver')?.value }}</td>
                                  <td>
                                    <span class="link-primary1 d-flex align-items-center"
                                      (click)="setConfigure(i,j)" *ngIf="configureBtnEnable(i,j)"
                                    >Configure</span>
                                  </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- <pre>Form Values: {{notifyForm.value | json}}</pre> -->
  </form>
  <div class="modal modal-alert {{showModal && !loader ? 'show':''}}" tabindex="-1" [formGroup]="configureForm">
    <div class="modal-dialog modal-dialog-centered " style="--bs-modal-width: 38rem;">
      <div class="modal-content">
        <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title">Configure Reciever</h4>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12">
              <label for="" class="form-label required">This notification will trigger to <i
                  class="bi bi-info-circle text-accent4 fs-12 tooltip-icon"
                  ngbPopover="Choose 'All employees' to notify everyone in the company, or 'custom' to notify specific employees or groups."
                  popoverClass="popover-default popover-info" container="body" triggers="hover"></i></label>
            </div>
            <div class="col-12 mt-8 form-row">
              <div class="form-check form-check-inline">
                <input name="config" type="radio" id="all" class="form-check-input" formControlName="config" [value]="false" (change)="setRequired(false)">
                <label for="all" class="form-check-label">All employees </label>
              </div>
              <div class="form-check form-check-inline">
                <input name="config" type="radio" id="custom" class="form-check-input" formControlName="config" [value]="true" (change)="setRequired(false)">
                <label for="custom" class="form-check-label">Custom</label>
              </div>
            </div>
            <ng-container *ngIf="f?.config?.value == true">
                <div class="col-12">
                  <label for="" class="form-label required">On basis of which criteria this notification will
                    trigger? <i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Choose the criteria for triggering notifications: 'Roles' option triggers notification to certain roles like HR, Reporting Manager or any other role created in the system. Organization unit triggers notification to certain set of employees categorized BU wise, Department wise etc. Email Id triggers notification to certain set of email ids specified by user."
                      popoverClass="popover-default popover-info" container="body" triggers="hover"></i></label>
                </div>
                <div class="col-12 mt-8 form-row">
                  <div class="form-check form-check-inline">
                    <input name="notify_trigger_type" type="radio" id="roles" class="form-check-input" formControlName="notify_trigger_type" [value]="false" (change)="setRequired(true);clearForm()">
                    <label for="roles" class="form-check-label">Roles </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input name="notify_trigger_type" type="radio" id="unit" class="form-check-input" formControlName="notify_trigger_type" [value]="true" (change)="setRequired(false);clearForm()">
                    <label for="unit" class="form-check-label">Organization Unit </label>
                  </div>
                </div>
                <ng-container *ngIf="f?.notify_trigger_type?.value == false">
                  <div class="col-12">
                    <label for="roles" class="form-label required">Roles </label>
                    <!-- <ng-select class="form-ngselect"
                    formControlName="role"
                    [ngClass]="{ 'is-invalid': (saveClicked || configureForm?.get('role')?.touched) && configureForm?.get('role')?.errors}"
                    name="role"  id="roles"
                    placeholder="{{messageService.selectplaceholddisplay('role')}}"  [clearable]="false">
                      <ng-option *ngFor="let rolea of rolesDropdownArr"  [value]="rolea?.id">{{rolea?.role_name}}</ng-option>
                    </ng-select> -->
                    <ng-select [ngClass]="{ 'is-invalid': (saveClicked || configureForm?.get('role')?.touched) && configureForm?.get('role')?.errors}" placeholder="{{this.messageService.selectplaceholddisplay('role')}}" formControlName="role" [items]="rolesDropdownArr" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="role_name" [closeOnSelect]="false" bindValue="id" class="multiselect"
                   >
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                          {{ item.role_name }}
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="configureForm.value.role?.length">
                              <span class="ng-value-label">{{configureForm.value.role?.length}} role selected.</span>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          {{ (item.role_name?.length>15)? (item.role_name | slice:0:15)+'..':(item.role_name)  }}
                      </ng-template>
                    </ng-select>
                    <div *ngIf="(saveClicked || configureForm?.get('role')?.touched) && configureForm?.get('role')?.errors" class="invalid-feedback">
                      <div *ngIf="configureForm?.get('role')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <ul class="selected-values">
                        <li class="ng-value" *ngFor="let item of configureForm.value.role, let m =index">
                            <ng-container *ngFor="let insideitem of rolesDropdownArr, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['role_name']?.length>15)? (insideitem['role_name'] | slice:0:15)+'..':(insideitem['role_name'])  }}</span>
                            </ng-container>
                            <span class="ng-value-icon right" aria-hidden="true" (click)="clear(m)"><i class="icon-close"></i></span>
                      </li>
                    </ul>
                  </div>
                </ng-container>
                <ng-container *ngIf="f?.notify_trigger_type?.value == true">
                    <div class="col-12">
                      <label for="" class="form-label required">Set of Employees to whom this notification should
                        trigger? <i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Notifications will be sent to all employees in the chosen group. Example: Assume today is Employee X's birthday, and they belong to the IT department in Company A. If you select the option 'Department', all employees in the IT department within Company A except Employee X will receive the birthday notification. Employees in the IT department in Company B will not receive the notification, nor will employees from any other department in Company A or Company B." popoverClass="popover-default popover-info"
                          container="body" triggers="hover"></i></label>
                    </div>
                    <div class="col-12 mt-8 form-row">
                      <div class="form-check form-check-inline">
                        <input name="organization_type" type="radio" id="company" class="form-check-input" formControlName="organization_type" value="Company">
                        <label for="company" class="form-check-label">Company</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input name="organization_type" type="radio" id="bus" class="form-check-input" formControlName="organization_type" value="Bussiness Unit">
                        <label for="bus" class="form-check-label">Business Unit</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input name="organization_type" type="radio" id="depart" class="form-check-input" formControlName="organization_type" value="Department">
                        <label for="depart" class="form-check-label">Department</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input name="organization_type" type="radio" id="branch" class="form-check-input" formControlName="organization_type" value="Branch">
                        <label for="branch" class="form-check-label">Branch</label>
                      </div>
                    </div>
                </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="showModal = false;resetConfig()"
            class="btn btn-outline-primary btn-sm text-uppercase">Cancel</button>
          <button type="button" class="btn btn-primary btn-sm text-uppercase" [disabled]="!configureForm.valid" (click)="saveConfigure()">SAVE</button>
        </div>
      </div>
    </div>
  </div>
