import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PageAccessService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

   API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  //  API for Page Access list
  pageAccessList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/page-access`, this.httpOption)
  }
  //  API for Employee list
  getempList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${status}&ordering=user__first_name`, this.httpOption);
  }
  //  API for Menu list
  getmenuList(status: any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/menu/?is_active=${status}&ordering=name`, this.httpOption);
  }
  createGroup(data: any) {
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-group`, data, this.httpOption);
  }
  getSubmenuList(){
    return this.http.get(`${this.API_PATH}organization/api/v1/page-access-menu-list/`,this.httpOption);
  }
  //  API for Page Access Create
  pageAccessCreate(body:any) {
    return this.http.post(`${this.API_PATH}organization/api/v1/page-access-create/`, body, this.httpOption)
  }

  pageAccessUpdate(body: any,id:number) {
    return this.http.put(`${this.API_PATH}organization/api/v1/page-access-update/${id}/`, body, this.httpOption)
  }

  pageAccessGet(limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/page-access-landing/?limit=${limit}&offset=${offset}&search=${search}&is_active=true`, this.httpOption)
  }

  pageAccessDropdown() {
    return this.http.get(`${this.API_PATH}organization/api/v1/page-access-landing/?is_active=true&ordering=name`, this.httpOption)
  }

  pageAccessDelete(id:number) {
    return this.http.post(`${this.API_PATH}organization/api/v1/page-access-delete/`, {id:id},this.httpOption)
  }

  pageAccessSingleItem(id:number) {
    return this.http.post(`${this.API_PATH}organization/api/v1/page-access-retrieve/`, {id:id}, this.httpOption)
  }

  pageAccessDefaultItem(){
    return this.http.get(`${this.API_PATH}organization/api/v1/page-access-user-default-retrieve/`, this.httpOption)

  }
}
