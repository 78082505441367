<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="loginForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
                <div class="row row-16 mb-16" [ngClass]="{'is-invalid':(submitted || f.date_of_login?.dirty || f.date_of_login?.touched) && f.date_of_login.errors}">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 mb-0 required">Date of login</p>
                    </div>


                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_login" type="radio" id="last_7_days" class="form-check-input"
                                formControlName="date_of_login" value="last_7_days"
                                (change)="radioBtnChangeFunctions($event,'date_of_login','last_7_days','sm_specific_month');setRequired(false)">
                            <label for="last_7_days" class="form-check-label">Last 7 days</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_login" type="radio" id="last_15_days" class="form-check-input"
                                formControlName="date_of_login" value="last_15_days"
                                (change)="radioBtnChangeFunctions($event,'date_of_login','last_15_days','sm_specific_month');setRequired(false)">
                            <label for="last_15_days" class="form-check-label">Last 15 days</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_login" type="radio" id="last" class="form-check-input"
                                formControlName="date_of_login" value="last_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_login','last_month','sm_specific_month');setRequired(false)">
                            <label for="last" class="form-check-label">Last month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_login" type="radio" id="this" class="form-check-input"
                                formControlName="date_of_login" value="this_month"
                                (change)="radioBtnChangeFunctions($event,'date_of_login','this_month','sm_specific_month');setRequired(false)">
                            <label for="this" class="form-check-label">Current month</label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="date_of_login" type="radio" id="specific" class="form-check-input "
                                formControlName="date_of_login" [value]="true"
                                (change)="radioBtnChangeFunctions($event,'date_of_login',true,'sm_specific_month');setRequired(true)">
                            <label for="specific" class="form-check-label">Specific period</label>
                        </div>
                    </div>
                    <ng-container *ngIf="loginForm.value.date_of_login==true">
                        <div class="input-group form-icon icon-right"
                        [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}">
                            <input (click)="datePicker1.open()" class="form-control" placeholder="From date" matInput
                                formControlName="from_date" [matDatepicker]="datePicker1" [ngClass]="{'is-invalid':(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors}"
                                [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                            <mat-datepicker  touchUi="false" #datePicker1>

                            </mat-datepicker>
                        </div>
                        <div *ngIf="(submitted || f?.from_date?.touched || f?.from_date?.dirty) &&  f?.from_date?.errors" class="invalid-feedback">
                          <div *ngIf="f?.from_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                      </div>
                      <div class="input-group form-icon icon-right"
                        [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}">
                            <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                                formControlName="to_date" [matDatepicker]="datePicker2" [ngClass]="{'is-invalid':(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors}"
                                [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                            <mat-datepicker  touchUi="false" #datePicker2>

                            </mat-datepicker>
                        </div>
                        <div *ngIf="(submitted || f?.to_date?.touched || f?.to_date?.dirty) &&  f?.to_date?.errors" class="invalid-feedback">
                          <div *ngIf="f?.to_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                          </div>
                      </div>
                    </ng-container>
                </div>
                <div *ngIf="(submitted || f.date_of_login?.dirty || f.date_of_login?.touched) && f.date_of_login.errors" class="invalid-feedback">
                  <div *ngIf="f.date_of_login.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitLogin()">
                Apply
            </button>
        </footer>
    </form>
</div>
