import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { PageAccessService } from '../../../../@core/services/page-access.service'
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-page-access',
  templateUrl: './page-access.component.html',
  styleUrls: ['./page-access.component.scss']
})
export class PageAccessComponent implements OnInit {
    showSidePanel = false;
    viewpageData:any = [];
  loader                        = true;
  pageaccessDetails: any        = [];
  searchlistdata:any            = [];
  groupsearch:string            = this.messageService.searchdisplay('group');
  activeToggle:any              = true;
  deleteToggle                  = false;
  dataAccessResId:any;
  nodata                        = false;
  loader1                       = false;
  selectedmenu:number           = 0;
  noChild:any                   = '';
  assignees:any                 = [];
  searchKeyword="";

  /********INFINITY SCROLL VARIABLES  ****/
  offset                        = 0;
  listlength                    = 0;
  direction                     = '';
  defaultlimit:any              = 20;
  pageaccessDetailsList         = [];
  infinityloader                = false;
  permissions:any = [];
  fulllistarray:any = [];
  deleteClicked = false
  isLoading = false
  searchData : Subject<void> = new Subject();

  constructor(
    private pageAccessService:PageAccessService,
    public router: Router,
    public breakpointObserver: BreakpointObserver,
    public messageService : MessageService,
    public appService:AppService
  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.groupList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.pageAccessService.pageAccessGet(this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  groupList() {
    this.loader = true;
    this.pageAccessService.pageAccessGet(this.defaultlimit,this.offset,this.searchKeyword)
    .subscribe((res: any) => {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    if(res.status == 200) {
      this.isLoading = false
      this.listlength = res.body.data.length;
      if(this.offset ==0){
        this.pageaccessDetails = [];
      }
      if (res.body.data.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.pageaccessDetailsList = res.body.data;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
    }
  }

  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; i++) {
      let flag =0;
      for(let j=0;j<this.pageaccessDetails.length;j++){
        if(this.pageaccessDetailsList[i]['id'] == this.pageaccessDetails[j]['id'] ){
          flag ++;
        }
      }
      if(flag == 0)
        this.pageaccessDetails.push(this.pageaccessDetailsList[i]);
        let len = this.pageaccessDetails.length;
        for (let i = 0; i < len; i++) {
          for (let u = 0; u < this.pageaccessDetails[i]['employees'].length; u++) {
            this.pageaccessDetails[i]['employees'][u].fullname  = "";
            if(this.pageaccessDetails[i]['employees'][u].first_name != null)
             this.pageaccessDetails[i]['employees'][u].fullname += this.pageaccessDetails[i]['employees'][u].first_name+" ";
            if(this.pageaccessDetails[i]['employees'][u].middle_name != null)
             this.pageaccessDetails[i]['employees'][u].fullname += this.pageaccessDetails[i]['employees'][u].middle_name+" ";
            if(this.pageaccessDetails[i]['employees'][u].last_name != null)
             this.pageaccessDetails[i]['employees'][u].fullname += this.pageaccessDetails[i]['employees'][u].last_name;
          }
        }
    }
  }
   // Search
   searchresults(data:any){
     this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.pageaccessDetails = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  // Filter
  filterItemList(value:any){
    this.loader = true;
    this.activeToggle = value;
    this.groupList();
    this.loader = false;
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.groupList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  editHeaderContent(id:any){
    this.router.navigate(['/page-access/setup-page-access/'+id]);
  }

  deleteDataAccess(daid:any){
    this.dataAccessResId  = daid;
    this.deleteToggle  = true;
  }

  deleteContent(dataAccessId:any){
    this.deleteClicked = true
    this.pageAccessService.pageAccessDelete(dataAccessId).subscribe((res: any) => {
      this.deleteClicked = false
      this.pageaccessDetails = [];
      this.offset = 0;
      this.groupList();
      this.deleteToggle = false;
    });
  }

  viewpage(id:any){
    if(this.permissions.v){
      this.showSidePanel = true;
      this.loader1       = true;
      this.pageAccessService.getSubmenuList()
      .subscribe((res: any) => {
        if(res.status == 200) {
          if (res.body.page_access.length > 0) {
            this.fulllistarray = res.body.page_access;
          }
          this.pageAccessService.pageAccessSingleItem(id).subscribe((res1: any) => {
            this.viewpageData = res1.body;
            var mainmenuidlist = this.viewpageData?.page_access;
            for(var p=0;p<mainmenuidlist.length;p++){
              var indexfull = this.setPermissionArray(this.fulllistarray,mainmenuidlist[p]['menu']);
              var findpermissionarray = this.fulllistarray[indexfull];
              this.viewpageData.page_access[p]['a'] = findpermissionarray?.a;
              this.viewpageData.page_access[p]['e'] = findpermissionarray?.e;
              this.viewpageData.page_access[p]['v'] = findpermissionarray?.v;
              this.viewpageData.page_access[p]['d'] = findpermissionarray?.d;
              if(this.fulllistarray[indexfull].children.length > 0){
                var submainmenuidlist = this.viewpageData?.page_access[p]?.children;
                for(var q=0;q<submainmenuidlist.length;q++){
                  var mainsubfulllistarray = this.fulllistarray[indexfull]?.children;
                  var indexfull1 = this.setPermissionArray(this.fulllistarray[indexfull]?.children,submainmenuidlist[q]['menu']);
                  var findpermissionarray = mainsubfulllistarray[indexfull1];
                  this.viewpageData.page_access[p].children[q]['a'] = findpermissionarray?.a;
                  this.viewpageData.page_access[p].children[q]['e'] = findpermissionarray?.e;
                  this.viewpageData.page_access[p].children[q]['v'] = findpermissionarray?.v;
                  this.viewpageData.page_access[p].children[q]['d'] = findpermissionarray?.d;
                  if(this.fulllistarray[indexfull].children[indexfull1]?.children?.length > 0){
                    var subsubmainmenuidlist = this.viewpageData?.page_access[p]?.children[q]['children'];
                    for(var r=0;r<subsubmainmenuidlist.length;r++){
                      var mainsubfulllistarray = this.fulllistarray[indexfull]?.children[indexfull1]?.children;
                      var indexfull2 = this.setPermissionArray(this.fulllistarray[indexfull]?.children[indexfull1].children,subsubmainmenuidlist[r]['menu']);
                      var findpermissionarray = mainsubfulllistarray[indexfull2];
                      this.viewpageData.page_access[p].children[q].children[r]['a'] = findpermissionarray?.a;
                      this.viewpageData.page_access[p].children[q].children[r]['e'] = findpermissionarray?.e;
                      this.viewpageData.page_access[p].children[q].children[r]['v'] = findpermissionarray?.v;
                      this.viewpageData.page_access[p].children[q].children[r]['d'] = findpermissionarray?.d;
                      if(this.fulllistarray[indexfull].children[indexfull1].children[indexfull2]?.children?.length > 0){
                        var subsubsubmainmenuidlist = this.viewpageData.page_access[p].children[q].children[r]['children'];
                        for(var s=0;s<subsubsubmainmenuidlist.length;s++){
                          var mainsubfulllistarray = this.fulllistarray[indexfull].children[indexfull1]?.children[indexfull2]?.children;
                          var indexfull3 = this.setPermissionArray(this.fulllistarray[indexfull]?.children[indexfull1]?.children[indexfull2]?.children,subsubsubmainmenuidlist[s]['menu']);
                          var findpermissionarray = mainsubfulllistarray[indexfull3];
                          this.viewpageData.page_access[p].children[q].children[r].children[s]['a'] = findpermissionarray?.a;
                          this.viewpageData.page_access[p].children[q].children[r].children[s]['e'] = findpermissionarray?.e;
                          this.viewpageData.page_access[p].children[q].children[r].children[s]['v'] = findpermissionarray?.v;
                          this.viewpageData.page_access[p].children[q].children[r].children[s]['d'] = findpermissionarray?.d;
                          if(this.fulllistarray[indexfull]?.children[indexfull1]?.children[indexfull2]?.children[indexfull3]?.children.length > 0){
                            var subsubsubsubmainmenuidlist = this.viewpageData.page_access[p]?.children[q]?.children[r]?.children[s]['children'];
                            for(var t=0;t<subsubsubsubmainmenuidlist.length;t++){
                              var mainsubfulllistarray = this.fulllistarray[indexfull]?.children[indexfull1]?.children[indexfull2]?.children[indexfull3].children;
                              var indexfull4 = this.setPermissionArray(this.fulllistarray[indexfull].children[indexfull1]?.children[indexfull2]?.children[indexfull3].children,subsubsubsubmainmenuidlist[r]['menu']);
                              var findpermissionarray = mainsubfulllistarray[indexfull4];
                              this.viewpageData.page_access[p].children[q].children[r].children[s].children[t]['a'] = findpermissionarray?.a;
                              this.viewpageData.page_access[p].children[q].children[r].children[s].children[t]['e'] = findpermissionarray?.e;
                              this.viewpageData.page_access[p].children[q].children[r].children[s].children[t]['v'] = findpermissionarray?.v;
                              this.viewpageData.page_access[p].children[q].children[r].children[s].children[t]['d'] = findpermissionarray?.d;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            if(res1.body.page_access.length > 0){
              this.setIndex(0,this.viewpageData.page_access[0])
            }
            this.loader1       = false;
          })
        }
      })
    }
  }
  setIndex(i: number,child:any) {
    this.selectedmenu = i;
    this.assignees = [];
    if(child.children.length == 0){
      this.noChild = 'No Data';
    }else{
      this.noChild = '';
      this.splitArray(child);
      this.assignees.push(child);
    }
  }
  setPermissionArray(fullmenuarray:any,menuid:any){
    var flag = -1;
    for(var m=0;m< fullmenuarray.length;m++){
        if(menuid == fullmenuarray[m]['menu']){
          flag = m;
        }
    }
    return flag
  }

  splitArray(menussplitarray:any){
    menussplitarray.nosubmenu = [];
    menussplitarray.submenu = [];
    for(let m=0;m< menussplitarray.children.length;m++){
      if(menussplitarray.children[m].children.length == 0){
        menussplitarray.nosubmenu.push(menussplitarray.children[m]);
      }
      else{
        menussplitarray.submenu.push(menussplitarray.children[m]);
        this.splitArray(menussplitarray.children[m]);
      }
    }
  }

  searchpageaccess(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.pageaccessDetails = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/page-access/setup-page-access/']);
  }
}
