
<div *ngIf="issalsumm" class="row row-16">
    <div class="col-12">
        <div class="card card-c2 overflow-hidden">
            <div class="table-responsive">
                <table class="table table-striped form-table  table-sm " >
                    <thead>
                        <tr >
                            <th class="fw-600" scope="col" >Component</th>
                            <th class="fw-600" scope="col" >Amount({{getPreMonth(this.month,this.year)}})</th>
                            <th class="fw-600" scope="col" >Amount({{this.month}})</th>
                            <th class="fw-600" scope="col" *ngIf="!fromDashboard">Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="filterIt().length<=0">
                            <td colspan="4">
                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                  </div>
                            </td>
                        </tr>
                        <tr *ngFor="let item of $any(salarysummaryList |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                            <td >{{item.name}} </td>
                            <td >{{item.last_month | currency:item.currency}}</td>
                            <td >{{item.current_month| currency:item.currency}}
                                <ng-container *ngIf="fromDashboard">
                                    <span *ngIf="item.difference>0" class="graph-increase text-success">{{item.difference}}</span>
                                    <span *ngIf="item.difference<0" class="graph-decrease text-danger ">{{item.difference}}</span>
                                </ng-container>
                            </td>
                            <td *ngIf="!fromDashboard"><span *ngIf="item.difference>0" class="graph-increase text-success">{{item.difference}}</span>
                                <span *ngIf="item.difference<0" class="graph-decrease text-danger ">{{item.difference}}</span>
                                <ng-container *ngIf="item.difference==0">{{item.difference}}</ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <div class="col-12">
        <ng-container *ngIf="this.appservice.filteredcount(salarysummaryList,searchString)>pageSize">
            <ngb-pagination class="d-flex justify-content-end"  [collectionSize]="this.appservice.filteredcount(salarysummaryList,searchString)" [(page)]="page"  [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
        </ng-container>
    </div>
</div>


<div class="row row-16" *ngIf="isempsalsumm">
    <div class="col-12">
        <div class="card card-c2 overflow-hidden">
            <div class="table-responsive">
                <table class="table table-striped form-table  table-sm " >
                    <thead>
                        <tr >
                            <th class="fw-600" scope="col" >Employee Code</th>
                            <th class="fw-600" scope="col" >Employee Name</th>
                            <th class="fw-600" scope="col" >Gross({{getPreMonth(this.month,this.year)}})</th>
                            <th class="fw-600" scope="col" >Gross({{this.month}})</th>
                            <th class="fw-600" scope="col" *ngIf="!fromDashboard">Gross(Difference)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="filterIt().length<=0">
                            <td colspan="5">
                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                  </div>
                            </td>
                        </tr>
                        <tr *ngFor="let item of $any(empsalarysummaryList |filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                            <td >{{item.employee_code}} </td>
                            <td >{{item.employee_name}} </td>
                            <td >{{item.previous_gross| currency:item.currency}} </td>
                            <td >{{item.current_gross| currency:item.currency}}
                                <ng-container *ngIf="fromDashboard">
                                <span *ngIf="item.difference_gross>0" class="graph-increase text-success">{{item.difference_gross}}</span>
                                <span *ngIf="item.difference_gross<0" class="graph-decrease text-danger ">{{item.difference_gross}}</span>
                               </ng-container>
                            </td>
                            <td *ngIf="!fromDashboard"><span *ngIf="item.difference_gross>0" class="graph-increase text-success">{{item.difference_gross}}</span>
                                <span *ngIf="item.difference_gross<0" class="graph-decrease text-danger ">{{item.difference_gross}}</span>
                                <ng-container *ngIf="item.difference_gross==0">{{item.difference_gross}}</ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <div class="col-12">
        <ng-container *ngIf="this.appservice.filteredcount(empsalarysummaryList,searchString)>pageSize">
            <ngb-pagination  class="d-flex justify-content-end"  [collectionSize]="this.appservice.filteredcount(empsalarysummaryList,searchString)" [(page)]="page"  [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
        </ng-container>
    </div>
</div>
