import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { Location} from '@angular/common';

@Component({
  selector: 'app-attendance-and-lop',
  templateUrl: './attendance-and-lop.component.html',
  styleUrls: ['./attendance-and-lop.component.scss']
})
export class AttendanceAndLopComponent implements OnInit {
  activeTab = 1;
  constructor(private _location: Location,public route:ActivatedRoute,public sds:SalaryDashboardService, private previousRouteService: PreviousRouteService, public router: Router
    ) { }
  year:number=0;
  month:any;
  company:any;
  tabAtt = true;
  tabLop = true
  loader = true
  tabvalue : any
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = params['company'];
        this.gettabdetails()
      }
    })
  }

  gettabdetails(){
    this.sds.tabdetails(this.company).subscribe((res: any) => {
      this.tabvalue = res.body.attendance_lop;
      if(res.body.attendance_lop == true){
        this.tabLop =true
        this.tabAtt =false
        this.activeTab = 2;

      } else {
        this.tabLop =false
        this.tabAtt =true
        this.activeTab = 4;
      }
      this.loader = false
    })
    // general_settings/api/v1/company-payroll-config-detail/?company_id=
  }
  backClicked() {
    let url  = this.previousRouteService.getPreviousUrl();
    if(url?.includes('/run-payroll'))
    this.router.navigate(['/run-payroll/'+this.year+'/'+this.month+'/'+this.company]);
    else
    this._location.back();
 }
}
