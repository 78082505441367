import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-investment-decleration-view',
  templateUrl: './investment-decleration-view.component.html',
  styleUrls: ['./investment-decleration-view.component.scss']
})

export class InvestmentDeclerationViewComponent implements OnInit {

  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  @Output() viewClose             = new EventEmitter();
  @Input()hra80ggSingleData:any;
  @Input()cahpt80CSingleData:any;
  @Input()otherSourceSingleData:any;
  @Input()diductionSingleData:any;
  @Input()investmentSingleData:any;
  @Input()investmentDeclerationView:any;
  @Input()requestId:any;
  @Input()requestName:any;
  @Input()viewHeader:any;
  @Input()cityName:any;
  @Input()loader1:any;
  currency:any


  constructor(
    public appservice:AppService,private datePipe:DatePipe
  ) { }

  ngOnInit(): void {
  }

  /********* GET FILE NAME FUNCTION ****************/
  getDocName(name:string){
    var docname : any  = name.split('_').pop();
    docname = docname.replace(/[&\/\\#,+()$~%'":*?<>{}0-9]/g, '', "");
    return docname;
  };

  

  /********* GET DATE DISPLAY FUNCTION ****************/
  dateFormatDisplay(date:any){
    return this.datePipe.transform(date, this.appservice.getdateformat())?.toString()
  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle      = true;
    this.fileName         = filename;
    this. currentDocName  = this.fileName.split('_').pop();
    this.currentDocName   = this.currentDocName.replace(/[&\/\\#,+()$~%'":*?<>{}0-9]/g, '');
    this.currentExtension = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'csv'){
      this.modalToggle    = false;
      this.alertToggle    = true;
      this.investalertMsg = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  /********* CLOSE FUNCTION ****************/
  closeFunction(){
    this.viewClose.emit(false);
  }

}
