import { Component, OnInit, AfterViewInit, Input} from '@angular/core';
import { OrganizationService } from 'src/app/@core/services/organization.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from '../../../message.global';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { treemapResquarify } from 'd3';
import { Router } from '@angular/router';
@Component({
  selector: 'app-organization-hierarchy',
  templateUrl: './organization-hierarchy.component.html',
  styleUrls: ['./organization-hierarchy.component.scss']
})
export class OrganizationHierarchyComponent implements OnInit {

  @Input()from:any
    loader              = false;
    orghierarchydata:any=[];
    empid           :any;
    serachid           :any;
    nodeexpanddata  :any=[];
    upperleveldata:any =[];
    nodata                     = false;
    searchkey         = this.messageService.searchdisplay('employee by name or code');
    emp:any[]         = [];
    searchKeyword     ="";
    itemsList:any[]   = [];
    searchlistdata:any = [] ;
    disablebtn        = false;
    expandtn          = true;
    managerid       :any;
    tempdata:any    =[];
    searchvar =false;
    superadminvar = false;
    fitBtnTxt:any = "Fit to screen"
    dnonecls:any;
    activecl:any;
    upclicked:any;
    click = true;
    constructor(
    public OrganizationService : OrganizationService,
    private authService:AuthService,
    public appservice:AppService,
    public messageService:MessageService,
    public employeeService:EmployeeService,
    public router: Router,
  ) { }
  ngOnInit(): void {
    this.empid        = this.authService.isEmployeeId;
    if(this.empid == '0'){
      this.superadminvar = true;
      this.dnonecls='d-none';
    }
    this.getorghierarchydatafn();
    this.getList();
  }
  ngAfterViewInit(): void {
   if(this.empid != '0'){
    document.getElementById("oc-"+this.empid)?.scrollIntoView();
    setTimeout(() => {
      var chart = document.getElementsByClassName('custom-chart')[0];
      let showMore = document.createElement('div');
      showMore.classList.add("chart-upperlevel");
      showMore.setAttribute('id','chart-upperlevel');
      let checkboxIcon = document.createElement('i')
      checkboxIcon.classList.add("icon-plus-circle");
      showMore?.append(checkboxIcon);
      chart?.append(showMore);
      var upperlevel = document.getElementById('chart-upperlevel');
      upperlevel?.addEventListener("click", this.viewUpperLevel.bind(this));
    }, 5000);
  }
    function viewUpperLevel() {
    }
    var zoomOut = document.getElementById('zoomOut');
    zoomOut?.addEventListener("click", function(){
      var zoomElement1 = document.getElementsByClassName('custom-chart')[0];
      zoomElement1.setAttribute('id','custom-chart');
      var zoomElement = document.getElementById('custom-chart');
      var widthScaled:any = 0;
      var widthOrginal:any = 0;
      var scale:any = 1;
      widthScaled = zoomElement?.getBoundingClientRect().width;
      widthOrginal = zoomElement?.offsetWidth;
      scale = (widthScaled / widthOrginal) ;
      scale-=0.1;
      if(zoomElement && scale > 0){
        zoomElement.style.transform = "scale("+scale+","+scale+")";
      }
    });
    var zoomIn = document.getElementById('zoomIn');
    zoomIn?.addEventListener("click", function(){
      var zoomElement1 = document.getElementsByClassName('custom-chart')[0];
      zoomElement1.setAttribute('id','custom-chart');
      var zoomElement = document.getElementById('custom-chart');
      var widthScaled:any = 0;
      var widthOrginal:any = 0;
      var scale:any = 1;
      widthScaled = zoomElement?.getBoundingClientRect().width;
      widthOrginal = zoomElement?.offsetWidth;
      scale = (widthScaled / widthOrginal) ;
      scale+=0.1;
      if(zoomElement ){
        zoomElement.style.transform = "scale("+scale+","+scale+")";
      }
    });
    var fittoscreen = document.getElementById('fittoscreen');
    fittoscreen?.addEventListener("click", function(){
      var zoomElement1 = document.getElementsByClassName('custom-chart')[0];
      zoomElement1.setAttribute('id','custom-chart');
      var zoomElement = document.getElementById('custom-chart');
      var widthElement:any = 0;
      var widthContainer:any = 0;
      var scale:any = 1;
      widthElement = zoomElement?.children[0].children[1]?.getBoundingClientRect().width;
      var mainContainer = document.getElementById('organization-chart');
      widthContainer = mainContainer?.getBoundingClientRect().width;
      if(widthElement > widthContainer){
        if(fittoscreen){
          fittoscreen.innerHTML =  "Switch to normal view";
        }
        scale = (widthContainer / widthElement );
        // scale = 1;
        if(zoomElement ){
          zoomElement.style.transform = "scale("+scale+","+scale+")";
          zoomElement.style.width = "100%";
          // var style = window.getComputedStyle(zoomElement);
          // var matrix = new WebKitCSSMatrix(style.webkitTransform);
          // zoomElement.style.transform = "translateX(" +  matrix.m41 + "px) translateX(" + matrix.m42 + ") scale("+scale+","+scale+")";
        }
      }
      else if(widthElement <= widthContainer){
        if(fittoscreen){
          fittoscreen.innerHTML =  "Fit to screen";
        }
        // scale = (widthElement / widthContainer);
        scale = 1;
        if(zoomElement ){
          zoomElement.style.transform = "scale("+scale+","+scale+")";
          zoomElement.style.width = "auto";
        }
      }
    });
  }
  viewUpperLevel() {
      this.disablebtn   = false;
      var upper = document.getElementById("chart-upperlevel");
      upper?.classList.add("d-none");
      this.upclicked = true;
      this.OrganizationService.getorghierarchydataup(this.managerid)
      .subscribe((res: any) => {
        if(Object.keys(res.data).length != 0){
        this.orghierarchydata = [];
        this.orghierarchydata = res.data;
        this.orghierarchydata.children = [this.tempdata];
          setTimeout(() => {
            if(this.orghierarchydata.id != this.empid){
            var uppercl2 = document.getElementById("id"+this.orghierarchydata.children[0].id);
            uppercl2?.classList.add("active");
                for(let i=0;i<this.orghierarchydata.children[0].children[0].children.length;i++){
                  var empclass = document.getElementById("id"+this.orghierarchydata.children[0].children[0].children[i].id);
                  empclass?.classList.add("active");
                }
            }else{
            }
          var uppercl = document.getElementById("id"+this.orghierarchydata.id);
          uppercl?.classList.add("active");
          var empclass = document.getElementById("id"+this.empid);
          empclass?.classList.add("active");
           }, 500);
          }
      })
    }
  getorghierarchydatafn(){
    this.expandtn = true;
    this.loader              = true;
    if(this.superadminvar == true){
      this.nodata = true;
      this.loader = false;
      this.getList();
    }else{
      this.OrganizationService.getorghierarchydata(this.empid)
      .subscribe((res: any) => {
        this.nodata = false;
        this.dnonecls='';
        this.orghierarchydata = res.data;

        if(this.from == 'myteam'){
          setTimeout(() => {
            document?.getElementById("fittoscreen")?.click();
          }, 150);
        }

        // this.focusEmp();
        this.tempdata = this.orghierarchydata;
        this.managerid= this.orghierarchydata.id;
        this.activeclassfn(res.data,this.empid);
        this.loader = false;
        this.getList();
      })
   }
  }
  collapsefn(){
    this.activecl = '';
    this.expandtn = true;

    if(this.searchvar==true){
      this.empid=this.serachid;
    }
     this.OrganizationService.getorghierarchydata(this.empid)
      .subscribe((res: any) => {
        this.orghierarchydata = res.data;
        if (this.empid != this.orghierarchydata.id){
          this.orghierarchydata.children.children = [];
          this.orghierarchydata.children[0].children = [];
        }else{
             this.orghierarchydata.children = [];
        }
         setTimeout(() => {
          var uppercl = document.getElementById("id"+this.orghierarchydata.id);
          if((this.empid != this.orghierarchydata.id) && (this.orghierarchydata.id != this.serachid)){
            uppercl?.classList.add("active");
          }else{
            uppercl?.classList.remove("active");
          }
         }, 500);
        this.empid        = this.authService.isEmployeeId;

      })
  }
​
expandallfn(){
​
    this.expandtn = false;
    this.disablebtn = false;

    if(this.searchvar==true){
      this.empid=this.serachid;
    }else if(this.upclicked == true){
      this.empid=this.managerid;
    }else{
    }
​
    let click:any= false
​
    let element = document.getElementById("oc-"+this.managerid)
    click = (element?.classList.contains('oc-is-collapsed'))
​
    this.OrganizationService.getorghierarchydataall(this.empid)
    .subscribe((res: any) => {
      this.orghierarchydata =[]
      this.orghierarchydata = res.data;
      setTimeout(() => {
        this.activecl = 'active';
      if(click){
      var displaycls:HTMLElement = document.getElementsByName("displaycls")[0]?.nextElementSibling as HTMLElement;
        if(displaycls){
          displaycls.click();
        }
      }
      }, 500);
      this.empid        = this.authService.isEmployeeId;
    })
  }
  focusEmp(){
    setTimeout(() => {
      document.getElementById("oc-"+this.empid)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      // this.loader = false;
    }, 500);
  }
  searchTree(element:any, matchingTitle:any){
    if(element.id == matchingTitle){
          element.children = this.nodeexpanddata.children;
          for(let i=0; i<this.orghierarchydata.length; i++){
              if(this.orghierarchydata[i]['id'] == element.id){
              this.orghierarchydata[i] = this.orghierarchydata;
              }
            }
          return element;
    }else if (element.children != null){
         var i;
         var result = null;
         for(i=0; result == null && i < element.children.length; i++){
              this.searchTree(element.children[i], matchingTitle);
          }
         return result;
    }
    return ;
  }
  expandnode(user:any){
    this.OrganizationService.getorghierarchydatadown(user.id)
    .subscribe((res: any) => {
      this.nodeexpanddata = res.data;
      var element = this.orghierarchydata;
      var result = this.searchTree(element, user.id);
    })
    setTimeout(() => {
      var upper:HTMLElement = document.getElementById("id"+user.id)?.nextElementSibling as HTMLElement;
      if((user.child > 0) && (user.children.length != 0)){
        upper.click();
      }
      upper?.previousElementSibling?.classList.toggle('active');
    }, 100);
    setTimeout(() => {
      var elements = document.querySelectorAll('.haschild.org-card');
      let flagexist: any;
      for (var i = 0; i < elements.length; i++) {
        if (!elements[i].classList.contains('active')) {
          flagexist = false;
          break;
        } else {
          flagexist = true;
        }
      }
      if (flagexist == true) {
        this.expandtn = false;
      }
      else {
        this.expandtn = true;
      }
    }, 1000);


  }

  // searchemp(searchKeyword:any){
  //   this.loader             = true;
  //   this.sLoading           = true;
  //   this.orghierarchydata         = [];
  //   this.searchKeyword      = searchKeyword;
  //   if(searchKeyword != ""){
  //     this.getorghierarchydatafn();
  //   }
  // }

  searchresults(event:any){
    this.activecl = '';
    this.nodata = false;
    this.searchvar=true;
    this.loader = true;
    this.disablebtn = true;
    this.dnonecls='';
    this.emp    = [];
    if(event == ""){
      this.searchKeyword  = "";
      this.getorghierarchydatafn();
    }else{
    //  this.employeeService.getsingleEmployee(event).subscribe((res:any)=>{
      // this.itemsList= res;
      this.serachid = event;
      this.OrganizationService.getorghierarchydata(event)
      .subscribe((res: any) => {
        this.orghierarchydata = res.data;
        this.disablebtn = true;
        this.loader = false;
        this.activeclassfn(res.data,this.serachid);
      })
    //  })
    }
  }
  activeclassfn(key1:any,key2:any){
    setTimeout(() => {
      if(key1.id != key2){
      var uppercl = document.getElementById("id"+key1.id);
      uppercl?.classList.add("active");
          // for(let i=0;i<key1.children[0].children.length;i++){
          //   var empclass = document.getElementById("id"+key1.children[0].children[i].id);
          //   empclass?.classList.add("active");
          // }
      }else{
        // for(let i=0;i<key1.children.length;i++){
        //   var empclass = document.getElementById("id"+key1.children[i].id);
        //   empclass?.classList.add("active");
        // }
      }
    var empclass = document.getElementById("id"+key2);
    empclass?.classList.add("active");
  }, 500);
  }
  getList(){
    this.OrganizationService.empDDList('active','user__first_name').subscribe((res: any) => {
      for(let i=0; i< res.length; i++){
        if(res[i]['first_name'] != null)
        res[i]['name'] = res[i]['first_name']+" ";
        if(res[i]['middle_name'] != null)
        res[i]['name'] += res[i]['middle_name']+" ";
        if(res[i]['last_name'] != null)
        res[i]['name']   += res[i]['last_name'];
        res[i]['name']   +=" ("+res[i]['employee_code']+")";
        res[i]['image']   = res[i]['profile_image'];
      }
      this.searchlistdata = res;
    });
  }
  onCleared($event:any){
    this.empid        = this.authService.isEmployeeId;
    this.disablebtn = false;
    this.loader = false;
    this.searchvar=false;
    if(this.empid  == '0'){
      this.dnonecls='d-none';
      this.orghierarchydata = [];
      this.nodata = true;
    }
  }
}
