<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step"
          [class]="{'success':myForm['controls'].details.valid || myForm['controls'].details.disabled }">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Attendance Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-ar-attendance-details [disabled]="disabled" [editflag]="editflag" [formGp]="detailsForm"
            [penaltyForm]="penaltyForm" (submitmethod)="submitMethod(2)"></app-ar-attendance-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step"
          [class]="{'success':(myForm['controls'].penalty.valid || myForm['controls'].penalty.disabled) && (iseditForm || tab2touched)}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Late Coming Penalty</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-ar-late-coming-penalty [detailsForm]="detailsForm" #lateComing [disabled]="disabled"
            [leaveTypes]="leaveTypes" [formGp]="penaltyForm"
            (submitmethod)="submitMethod(3)"></app-ar-late-coming-penalty>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="step"
          [class]="{'success':((myForm['controls'].compensatory.valid || myForm['controls'].compensatory.disabled)|| myForm['controls'].compensatory.disabled) && (iseditForm || tab3touched)}">
          <div class="count">3</div>
          <div class="content">
            <span>Step 3</span>
            <h6>Compensatory Off</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-ar-compensatory-off [disabled]="disabled" [formGp]="compensatoryForm" [overtimeStatus]="overtimeStatus"
            (submitmethod)="submitMethod(4)" [editId]="id"></app-ar-compensatory-off>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink class="step"
          [class]="{'success':((myForm['controls'].overtime.valid) || myForm['controls'].overtime.disabled ) && (iseditForm || tab4touched)}">
          <div class="count">4</div>
          <div class="content">
            <span>Step 4</span>
            <h6>Overtime</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-ar-overtime [disabled]="disabled" [formGp]="overtimeForm" (submitmethod)="submitMethod(5)"
            [compoOffStatus]="compoOffStatus" [editId]="id"></app-ar-overtime>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink class="step"
          [class]="{'success':((myForm['controls'].punch.valid ) || myForm['controls'].punch.disabled) && (iseditForm || tab5touched) }">
          <div class="count">5</div>
          <div class="content">
            <span>Step 5</span>
            <h6>Alert</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-ar-alert [disabled]="disabled" [formGp]="alertForm" (submitmethod)="submitMethod(6)"></app-ar-alert>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <a ngbNavLink class="step 6"
          [class]="{'success':myForm['controls'].attendance_rule_assignright.valid || myForm['controls'].attendance_rule_assignright.disabled }">
          <div class="count">6</div>
          <div class="content">
            <span>Step 6</span>
            <h6>Assign Rights</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-common-applicability [heading]="'Assign Rights'" [disabled]="disabled" [isEditForm]="editflag" [formGp]="assignForm" (submitMethod)="submitForm()"></app-common-applicability>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
    <!-- <pre>Form Values: {{myForm.value | json}}</pre> -->
  </main>
</div>
