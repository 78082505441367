import { Component, OnInit , Input,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent implements OnInit {

  @Input() infoToggle: any;
  @Input() infoMsg: any;
  @Output() modal= new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  close(){
    this.infoToggle = false
    this.modal.emit(this.infoToggle)
  }
}
