<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}} Advance Policy</h5>
    <button class="btn-reset text-light-600" routerLink="/advance-policy"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar">
      <ul ngbNav #nav="ngbNav" [activeId]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1" (click)="activeTab=1;">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].setting.valid }">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Advance Policy</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-advance-policy-add [formGp]="settingForm" [editflag]="editflag" [disabled]="disabled"
              [submitted]="iseditForm" (submitmethod)="submit(2)"></app-advance-policy-add>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" (click)="tabclick(2)">
          <a ngbNavLink class="step" [class]="{'success':myForm['controls'].applicable.valid }">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Applicability</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
              <app-common-applicability [heading]="'Applicability'" [disabled]="disabled" [isEditForm]="iseditForm" [formGp]="applicableForm" (emitApplicableList)="applicablelist($event)" (submitMethod)="submit(3)"></app-common-applicability>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" (click)="tabclick(3)">
          <a ngbNavLink class="step"
            [class]="{'success':myForm['controls'].applicable.valid && myForm['controls'].setting.valid && tabtouched }">
            <div class="count">3</div>
            <div class="content">
              <span>Step 3</span>
              <h6>Review</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-advance-policy-review [formGp]="settingForm" [applicableform]="applicablearredit"
              (submitmethod)="submitForm()"></app-advance-policy-review>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
