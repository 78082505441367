import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { EmpcodeValidator } from 'src/app/@shared/validators/empcode.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { spaceValidator } from 'src/app/@shared/validators/space.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs';
import { ExpensePolicyService } from 'src/app/@core/services/expense-policy.service';
import { LocationGroupService } from 'src/app/@core/services/location-group.service';
import { debounceTime } from 'rxjs/operators';
import { UnamepatternValidator } from '../../validators/unamepattern.validators';
@Component({
  selector: 'app-expense-category-add',
  templateUrl: './expense-category-add.component.html',
  styleUrls: ['./expense-category-add.component.scss']
})
export class ExpenseCategoryAddComponent implements OnInit {
  value: any;
  id: any;
  expensekey: any = [];
  submitted = false;
  loader = false;
  header = 'Add';
  @Input() dataId: any;
  @Input() editDetail: any;
  @Input() from: any;
  @Output() panelclose = new EventEmitter();
  @Output() detailout = new EventEmitter();
  private subjectKeyUp = new Subject<any>();
  myForm = this.formBuilder.group({
    id:0,
    name                            : ['', [Validators.required,UnamepatternValidator(), Validators.maxLength(100)]],   
    amount_limit_type               : ['no_limit', [Validators.required]],
    attachment_minimum_amount_limit : [null],
    claim_fequency_limit            : ['', [Validators.required,NumberpatternValidator(),Validators.maxLength(5)]],
    claim_limit_period              : [null, [Validators.required]],
    code_name                       : ['',[Validators.required,EmpcodeValidator(),Validators.maxLength(10)]],
    description                     : ['', [spaceValidator(),Validators.maxLength(500)]],
    expense_type                   : [false, [Validators.required]],
    is_attachment                  : [false, [Validators.required]],
    is_attachment_minimum_amount   : [false],
    is_description                 : [false, [Validators.required]],
    is_distance_limited            : [false],
    is_file_expense                : [false, [Validators.required]],
    is_reimbursable                : [false],
    limit                          : [null],
    limit_period                   : [null],
    location_group                 : [[]],
    rate_per_km                    : [null],
  })
    limit_periodlist:any =[
      {id:"Day",     name:"Day"},
      {id:"Week",    name:"Week"},
      {id:"Month",   name:"Month"},
      {id:"Year",    name:"Year"},
      {id:"Lifetime", name:"Lifetime"},    
  ];
  reimbursable                    : boolean = false;
  locationdropdown                : any=[];
  disabled                        = false;
  policykey                       = false;
  disable                         = false;
  constructor(
    public ExpensePolicyService: ExpensePolicyService,
    public formBuilder: FormBuilder,
    public appService: AppService,
    public messageService: MessageService,
    public existDataService: ExistDatasService,
    public LocationGroupService: LocationGroupService,
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d: any) => {
      this.existService(d);
    })
    this.LocationGroupService.getSearchList(true).subscribe((res: any) => { 
      this.locationdropdown = res; 
      this.selectAllForDropdownItems(this.locationdropdown);
    }); 
    if(this.from == 'policy'){
      this.policykey = true;
      this.myForm.get('name')?.disable();
      this.myForm.get('code_name')?.disable();
      this.myForm.get('expense_type')?.disable();
      this.myForm.get('description')?.disable();
    }else{
      this.policykey = false;
      this.myForm.get('name')?.enable();
      this.myForm.get('code_name')?.enable();
      this.myForm.get('expense_type')?.enable();
      this.myForm.get('description')?.enable();
     }
    if (this.dataId == 0) {
      // this.myForm.reset();
      this.header = 'Add';
    } else {
      this.header = 'Edit';
      this.loader = true;
      this.editfn();
    }
  }
  editfn(){
    this.ExpensePolicyService.getSinglecategoryData(this.dataId).subscribe((res: any) => {        
        let group:any= []
        if(res.location_group==null){
            group= [];
          }else{
            for(let i=0;i<res.location_group.length;i++){
              group.push(res.location_group[i].id);
            }
          }
        this.myForm.reset({
          id: res.id,
          name: res.name,
          amount_limit_type: res.amount_limit_type,
          attachment_minimum_amount_limit: res.attachment_minimum_amount_limit,
          claim_fequency_limit: parseInt(res.claim_fequency_limit),
          claim_limit_period: res.claim_limit_period,
          code_name: res.code_name,
          description: res.description,
          expense_type: res.expense_type,
          is_attachment: res.is_attachment,
          is_attachment_minimum_amount: res.is_attachment_minimum_amount,
          is_description: res.is_description,
          is_distance_limited: res.is_distance_limited,
          is_file_expense: res.is_file_expense,
          is_reimbursable: res.is_reimbursable,
          limit_period: res.limit_period,
          rate_per_km: res.rate_per_km,   
          location_group: group,   
          is_policy: this.policykey,   
          limit: res.limit,   
        });
        this.reimbursable = res.is_reimbursable;
      this.loader = false;
    })
  }
  
  onSubmit() {
    if (this.myForm.invalid) {
      return;
    }
    this.disable =true;
    let myform = {
      'name': this.myForm.value.name,
      'amount_limit_type': this.myForm.value.amount_limit_type,
      'attachment_minimum_amount_limit': this.myForm.value.attachment_minimum_amount_limit,
      'claim_fequency_limit': parseInt(this.myForm.value.claim_fequency_limit) ,
      'claim_limit_period': this.myForm.value.claim_limit_period,
      'code_name': this.myForm.value.code_name,
      'description': this.myForm.value.description,
      'expense_type': this.myForm.value.expense_type,
      'is_attachment': this.myForm.value.is_attachment,
      'is_attachment_minimum_amount': this.myForm.value.is_attachment_minimum_amount,
      'is_description': this.myForm.value.is_description,
      'is_distance_limited': this.myForm.value.is_distance_limited,
      'is_file_expense': this.myForm.value.is_file_expense,
      'is_reimbursable': this.reimbursable,
      'limit': this.myForm.value.limit,
      'limit_period': this.myForm.value.limit_period,
      'rate_per_km': this.myForm.value.rate_per_km,      
      'location_group': this.myForm.value.location_group,      
      'is_policy': this.policykey,   
    }
      if (this.dataId == 0) {
        this.ExpensePolicyService.createcategory(myform).subscribe((res: any) => {
          this.detailout.emit(true);
          this.closefn();
          this.myForm.reset();
        }, (err: any) => {
        });

      } else {
        this.ExpensePolicyService.updatecategory(this.dataId, myform).subscribe((res: any) => {
        if(this.from == 'policy'){
          this.detailout.emit(res);
        }else{
          this.detailout.emit(true);
        }
          this.closefn();
        }, (err: any) => {
        });
      }    
  }

  get f() { return this.myForm.controls; }
  
  existService(type:any){
    let value = ""

    if(type == "name")
      value =this.f.name.value
    else
      value =this.f.code_name.value

    if(this.id==0){
      this.value ={
        "page": "ExpenseCategory",
        "field": type,
        "value": value
      }
    }else{
       this.value ={
        "id": this.id,
        "page": "ExpenseCategory",
        "field": type,
        "value": value
      }
    }
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      if(res.body.data ==='invalid'&& type == "name"){
        this.f.name.setErrors({
          'exist' : true
        })
      } else if(res.body.data ==='invalid'&& type == "code_name"){
        this.f.code_name.setErrors({
          'exist' : true
        })
      }
    })
  }

  keyUp() {
    this.subjectKeyUp.next("name")
  }
  keyUpcode(){
    this.subjectKeyUp.next("code_name");
  }
  closefn() {
    this.editDetail = false;
    this.panelclose.emit(this.editDetail);
  }

  CheckedOrNot(value:any){
    this.reimbursable = value.target.checked;
  }

  setRequired(value: boolean,item:any) {
    if(value) {
        if(item=='amount'){
          this.myForm.get('amount_limit_type')?.setValidators([Validators.required]);  
          this.myForm.get('amount_limit_type')?.setValue('no_limit');  
          this.myForm.get('rate_per_km')?.clearValidators();      
          this.myForm.get('rate_per_km')?.setValue(null);  
          this.myForm.get('is_distance_limited')?.clearValidators();   
        }else if( item=='limit_for_all_location' || item=='is_distance_limited'){
          this.myForm.get('limit')?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(6)]);      
          this.myForm.get('limit_period')?.setValidators([Validators.required]); 
          this.myForm.get('location_group')?.clearValidators();      
          this.myForm.get('location_group')?.setValue([]);      
          this.myForm.get('limit_period')?.reset();
          this.myForm.get('limit')?.reset();
        }else if( item=='location_dependent_limit'){
          this.myForm.get('limit')?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(6)]);      
          this.myForm.get('location_group')?.setValidators([Validators.required]);      
          this.myForm.get('limit_period')?.setValidators([Validators.required]); 
          this.myForm.get('limit_period')?.reset();
          this.myForm.get('limit')?.reset();
        }else if(item=='mileage'){
          this.myForm.get('amount_limit_type')?.clearValidators();    
          this.myForm.get('amount_limit_type')?.setValue('no_limit');  
          this.myForm.get('limit')?.clearValidators();      
          this.myForm.get('limit_period')?.clearValidators();
          this.myForm.get('limit_period')?.reset();
          this.myForm.get('limit')?.reset();
          this.myForm.get('rate_per_km')?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(6)]);      
          this.myForm.get('is_distance_limited')?.setValidators([Validators.required]); 
        }else if( item=='no_limit'){
          this.myForm.get('limit')?.clearValidators();      
          this.myForm.get('limit_period')?.clearValidators(); 
          this.myForm.get('location_group')?.clearValidators();      
          this.myForm.get('limit_period')?.reset();
          this.myForm.get('limit')?.reset();
          this.myForm.get('location_group')?.setValue([]);      
        }else if( item=='is_attachment'){
            this.myForm.get('is_attachment_minimum_amount')?.setValidators([Validators.required]);   
        }else if( item=='is_attachment_minimum_amount'){
            this.myForm.get('attachment_minimum_amount_limit')?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(6)]);   
        }
    } else {
        if(item=='is_distance_limited'){
          this.myForm.get('limit')?.clearValidators();      
          this.myForm.get('limit_period')?.clearValidators(); 
          this.myForm.get('limit_period')?.reset();
          this.myForm.get('limit')?.reset();
        }else if( item=='is_attachment'){
          this.myForm.get('is_attachment_minimum_amount')?.clearValidators(); 
          this.myForm.get('attachment_minimum_amount_limit')?.clearValidators(); 
          this.myForm.get('attachment_minimum_amount_limit')?.reset();
        }else if( item=='is_attachment_minimum_amount'){
          this.myForm.get('attachment_minimum_amount_limit')?.clearValidators(); 
          this.myForm.get('attachment_minimum_amount_limit')?.reset();
        }
    }
    
    this.myForm.get('amount_limit_type')?.updateValueAndValidity();    
    this.myForm.get('limit')?.updateValueAndValidity();    
    this.myForm.get('location_group')?.updateValueAndValidity();    
    this.myForm.get('limit_period')?.updateValueAndValidity();    
    this.myForm.get('is_distance_limited')?.updateValueAndValidity();    
    this.myForm.get('rate_per_km')?.updateValueAndValidity();    
    this.myForm.get('is_attachment_minimum_amount')?.updateValueAndValidity();    
    this.myForm.get('attachment_minimum_amount_limit')?.updateValueAndValidity();    
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  clear(index:number){
    let daata = this.f.location_group.value;
    daata.splice(index, 1);
    this.myForm.patchValue({
      location_group: daata
    });
  }

}
