import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttendanceProcessDashboardService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  getDateRangeSlider(companyId:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/company-attendance-dateslider/${companyId}/`,this.httpOption)
  }

  getattendanceSummary(year:any,company:any,month:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/company-attendance-summary/${company}/?attendance_month=${month}&attendance_year=${year}`,this.httpOption)
  }

  companyDropDownData(){
    return this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption)
  }

  getattendancedetails(company:any,date:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/company-daily-attendance-details/${company}/?date=${date}`,this.httpOption)
  }

  getgraphdata(year:any,company:any,month:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/company-work-hour-summary/${company}/?attendance_month=${month}&attendance_year=${year}`,this.httpOption)
  }
  
}



