import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import {  AssetReportServiceService } from 'src/app/@core/services/asset-report-service.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';
import { AssetPolicyService } from 'src/app/@core/services/asset-policy.service';


@Component({
  selector: 'app-hardware-inventory-report',
  templateUrl: './hardware-inventory-report.component.html',
  styleUrls: ['./hardware-inventory-report.component.scss']
})
export class HardwareInventoryReportComponent implements OnInit {

  selectedYear            = new Date().getFullYear();
  year_placeholder        = 'Select year'
  month_placeholder       = 'Select month'
  initialValue  :any      = [];
  hasChange     :boolean  = false;
  hasChangeCount:any      = 0;
  dataFilter    :any      = []
  dataSource    :any      = [];
  reportName    :any      = '';
  fullData      :any      = []
  attendListOfMonth:any   = "listOfMonth";
  list:any                = [];
  selectedItem            = [];
  applyBtn : boolean      = false

  monthValue : any
  loaderlist : boolean     = false
  dropdownLoading : boolean = false
  categoryLoading : boolean = false
  subCatLoading   : boolean = false

  @Input() employeeDropdown      : any
  @Input() viewDetail            : any
  @Input() submitted             : any
  @Input() resDataFilter         : any
  @Input() disabled              : any
  @Input() callReport            : any
  @Input() salFilter             : any
  @Input() closeControl          : any
  @Input() checkListElements     : any
  @Input() pageNumber            : any
  @Input() lastPage              : any
  @Input()status_list            : any
  @Input()categoryDD             : any
  @Input() subCategoryDD         : any
  @Input() inventoryForm         : any
  @Input() applicable            : any
  @Input() Years                 : any
  @Input() ordering              : any

  @Output() callReports        = new EventEmitter();
  @Output() filterPanel        = new EventEmitter();
  @Output() resetFilter        = new EventEmitter();
  @Output() submitDone         = new EventEmitter();

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  status : any  = [
    {id:"In use",name:"In Use"},
    {id:"In Stock",name:"In Stock"},
    {id:"In Repair",name:"In Repair"},
    {id:"Missing",name:"Missing"},
    {id:"Retired",name:"Retired"},
    {id:"Damaged",name:"Damaged"},
];

 applicableList : any  = [
  { id: 1, name: 'BU' },
  { id: 2, name: 'Company' },
  { id: 3, name: 'Department' },
  { id: 4, name: 'Designation' },
  { id: 5, name: 'Employee' },
  { id: 6, name: 'Grade' },
];

  constructor(
    public messageService : MessageService,
    private reportService : ReportsService,
    public appService : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public assetReportService: AssetReportServiceService,
    public cd : ChangeDetectorRef,
    private ams:ApprovalMatrixService,
    private assetSettings : AssetSettingsService,
    private assetPolicyService : AssetPolicyService
  ) { }

  ngOnInit(): void {
    this.getDateFormat()
   this.selectAllForDropdownItems(this.status)
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes['viewDetail'] && changes['viewDetail'].currentValue) {
      this.getCategoryList()
      this.getSubCategoryList()
      this.getEmployeeList()
    }
  }

  get f()  { return this.inventoryForm.controls; }

  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  filterFormData(use:any){
    if(this.inventoryForm.controls.assigned_on.value == true){
      this.inventoryForm.get('assigned_on')?.setValue(this.inventoryForm.controls.specific_year.value+'-'+this.monthValue)
    }
    else{
      this.inventoryForm.get('assigned_on')?.setValue(this.inventoryForm.controls.assigned_on.value)
    }
    let asset_category        = this.inventoryForm.controls.asset_category.value==null ?JSON.stringify([]):JSON.stringify(this.inventoryForm.controls.asset_category.value);
    let asset_sub_category    = this.inventoryForm.controls.asset_sub_category.value==null?JSON.stringify([]):JSON.stringify(this.inventoryForm.controls.asset_sub_category.value);
    let status                = this.inventoryForm.controls.status.value==null?JSON.stringify([]):JSON.stringify(this.inventoryForm.controls.status.value);
    let assigned_to           = this.inventoryForm.controls.assigned_to.value==null?'':(this.inventoryForm.controls.assigned_to.value);
    let assigned_by           = this.inventoryForm.controls.assigned_by.value==null?'':(this.inventoryForm.controls.assigned_by.value);
    let assigned_on           = this.inventoryForm.controls.assigned_on.value==null?'':this.inventoryForm.controls.assigned_on.value;
    let managed_by            = ''

    if(this.inventoryForm.get('applicable')?.value!=null && this.inventoryForm.get('applicable')?.value!=''){

      let key                   = this.inventoryForm.get('applicable')?.value.toLowerCase()
      let assignRight:any       = {
        applicable  : this.inventoryForm.get('applicable')?.value
      }
      if(key=='BU')
      key = 'businessunit'
      assignRight['ids']           = this.inventoryForm.get('assignees')?.value
      managed_by                   = JSON.stringify(assignRight)
    }

    if(use=='filter'){
      return `asset_category=${asset_category}&asset_sub_category=${asset_sub_category}&status=${status}&assigned_to=${assigned_to}&assigned_by=${assigned_by}&assigned_on=${assigned_on}&managed_by=${managed_by}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'assigned_on' : assigned_on,'asset_category' : this.inventoryForm.controls.asset_category.value == null ? '' : this.inventoryForm.controls.asset_category.value, 'asset_sub_category' : this.inventoryForm.controls.asset_sub_category.value == null ? '' : this.inventoryForm.controls.asset_sub_category.value, 'assigned_to' : this.inventoryForm.controls.assigned_to.value == null ? '' : this.inventoryForm.controls.assigned_to.value,'assigned_by' : this.inventoryForm.controls.assigned_by.value == null ? '' :  this.inventoryForm.controls.assigned_by.value,'status':this.inventoryForm.controls.status.value == null ? '' : this.inventoryForm.controls.status.value,'managed_by':managed_by});
    }
  }

  getData() {
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'request_month' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  radioBtnChangeFunctions(e:any,contrlName:any,val:any,monthtype:any,yeartype:any){
    this.cd.detectChanges();
    if(e.target.value == 'on' ){
     this.inventoryForm?.get(contrlName)?.setValue(true);
    }
    else{
     this.inventoryForm?.get(contrlName)?.setValue(val);
     this.inventoryForm?.get(monthtype)?.setValue(null);
     this.inventoryForm?.get(yeartype)?.setValue(null);
    }
  }

  setFormControlsToEmptyString(formGroup:FormGroup) {
    for (const controlName of Object.keys(formGroup.controls)) {
      const control = formGroup.get(controlName);
      if (control?.value === null) {
        control?.setValue('');
      }
    }
  }

  attendancemonthChangeFn(event:any){
    var date        = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    this.monthValue = String(date.getMonth() + 1).padStart(2, '0');
  }

  clearcommonForm(control:any,index:any){
    let daata = this.inventoryForm.value[control];
    daata.splice(index, 1);
    this.inventoryForm.get(control)?.setValue(daata)
  }

  submitInventory(){
    this.submitted          = true;
    if(this.inventoryForm.invalid || (this.inventoryForm.get('applicable').value!=null && this.inventoryForm.get('applicable').value!='' && this.inventoryForm.get('assignees').value.length==0)){
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let formData = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : formData
    })
    this.viewDetail = false;
    this.applyBtn   = false
  }

  resetPage(val:any){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  resetFilterFunction(){
    this.setRequired(false)
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.resetForm()
    }
    this.callReports.emit({
      reportName : 'Hardware Inventory'
    })
  }

  resetForm(){
    this.inventoryForm.reset();
    this.inventoryForm.get('assigned_on')?.reset('')
    this.inventoryForm.get('assigned_on')?.setValue('this_month')
    this.inventoryForm.get('assignees').clearValidators();
    this.inventoryForm.get('assignees')?.updateValueAndValidity();
  }

  clear(value:any,index:number){
    let data = this.inventoryForm.value.assignees;
    data.splice(index, 1);
    this.inventoryForm.patchValue({
      assignees: data
    });
  }

  changeApplicable(init:any){
    this.loaderlist = true
    if(init != 1 && this.inventoryForm.get('applicable')?.value != null)
    this.inventoryForm.get('assignees')?.setValue([]);
    this.inventoryForm.get('assignees').setValidators([Validators.required])
    this.inventoryForm.get('assignees')?.updateValueAndValidity();
    let keyvalue = this.inventoryForm['controls'].applicable.value;
    if(this.inventoryForm['controls'].applicable.value == "Business Unit"){
      keyvalue = "BU";
    }
    this.assetSettings.applicableList(keyvalue).subscribe((res: any) => {
      this.list = [];
      if(this.inventoryForm['controls'].applicable.value == "Employee"){
        for(let key of res?.body){
          key.name = key.first_name+" ";
          if(key.middle_name !=null && key.middle_name!='')
            key.name +=key.middle_name+" ";
          if(key.last_name !=null)
            key.name +=key.last_name;
            key.name +=" ("+key.employee_code+")";
          this.list.push(key);
        }
      } else if(this.inventoryForm['controls'].applicable.value == "Company"){
        for(let key of res?.body){
          key.name = key.company_name;
          this.list.push(key);
        }
      }
      else
        this.list = res?.body;
        if (this.list.length != 0) {
          this.selectedItem = this.list[0].id;
          if(this.inventoryForm['controls'].applicable.value != "Employee"){
            this.selectAllForDropdownItems(this.list);
          }
        }
    this.loaderlist = false
    });
    if(this.inventoryForm.get('applicable')?.value == null){
      this.inventoryForm.get('assignees')?.clearValidators()
      this.inventoryForm.get('assignees')?.updateValueAndValidity()
    }
  }

  setRequired(val:any){
    var arr = ['specific_month','specific_year']
    if(val){
      arr.forEach((item:any)=>{
        this.inventoryForm.get(item)?.setValidators([Validators.required]);
        this.inventoryForm.get(item)?.updateValueAndValidity();
      })
    }else{
      arr.forEach((item:any)=>{
        this.inventoryForm.get(item)?.clearValidators()
        this.inventoryForm.get(item)?.updateValueAndValidity();
      })
    }
  }

  applyTagClose(){
    this.applyBtn = true
    if(this.closeControl=='managed_by'){
      this.inventoryForm.get('applicable').setValue(null)
      this.inventoryForm.get('assignees').setValue(null)
      this.inventoryForm.get('assignees').clearValidators();
      this.inventoryForm.get('assignees')?.updateValueAndValidity();
    }
    else{
      this.inventoryForm.get(this.closeControl).setValue(null)
    }
    this.submitInventory();
  }

  setFormControlsToNullString(formGroup:FormGroup) {
    for (const controlName of Object.keys(formGroup.controls)) {
      const control = formGroup.get(controlName);
      if (control?.value === '') {
        control?.setValue(null);
      }
    }
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }

     // Dropdowns
     getCategoryList(){
      this.categoryLoading = true
      this.assetPolicyService.assetCategoryDropDown(true).subscribe((res:any)=>{
        this.categoryDD = res;
        this.selectAllForDropdownItems(this.categoryDD);
        this.categoryLoading = false
      })
    }

    getSubCategoryList(){
      this.subCatLoading = true
      this.assetPolicyService.assetSubCategoryDropDown(true).subscribe((res: any) => {
        this.subCategoryDD  = res
        this.selectAllForDropdownItems(this.subCategoryDD);
        this.subCatLoading = false
      })
    }

    getEmployeeList(){
      this.dropdownLoading = true
      this.assetSettings.empDDList(true,'user__first_name')
      .subscribe((res: any) => {
        if(res.status == 200) {
          if (res.body?.length > 0) {
            for(let i=0;i<res.body?.length;i++){
              res.body[i]['name'] = res.body[i]['first_name'];
              if(res.body[i]['first_name'] != null)
              res.body[i]['name'] = res.body[i]['first_name']+" ";
              if(res.body[i]['middle_name'] != null)
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
              if(res.body[i]['last_name'] != null)
              res.body[i]['name']   += res.body[i]['last_name'];
            res.body[i]['fullname'] = res.body[i]['name'].slice()
              res.body[i]['name']   +=" ("+res.body[i]['employee_code']+")";
            }
            this.employeeDropdown = res.body;
          }
        }
      this.dropdownLoading = false
      })
    }
}
