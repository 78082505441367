import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-view-loader',
  templateUrl: './card-view-loader.component.html',
  styleUrls: ['./card-view-loader.component.css']
})
export class CardViewLoaderComponent implements OnInit {

  constructor() { }
  loader = true;

  ngOnInit() {
  }

}
