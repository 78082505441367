import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-lop',
  templateUrl: './lop.component.html',
  styleUrls: ['./lop.component.scss']
})
export class LopComponent implements OnInit {
  status = "Unprocessed"
  fileName: string    = '';
  fileSize: string    = '';
  searchString:any    = "";
  data:any            = [];
  inputData:any       = [];
  preparedData:any    = [];
  successData:any     = [];
  keyArray:any        = [];
  failedData:any      = [];
  permissions:any     = [];
  ws:any              = null;
  success             = false;
  deleteToggle        = false;
  downloadMsg         = false;
  invalidFormat       = false;
  deleteMsg           = "";
  successDataCount    = 0;
  page                = 1;
  pageSize            = 20;
  showSidePanel       = false;
  Isalert             = true;
  submiited           = false;
  empDD :any[]        = [];
  validatedData:any   = [];
  tempData:any        = [];
  tempSelect:any      = [];
  ErrorData:any       = [];
  exportData:any      = [];
  selectedData:any    = [];
  addData:any         = [];
  errorCount          = 0;
  completed           = false;
  confirmMsg          = false;
  saveData:any        = [];
  confirmMsgText      = "";
  saveComplete        = false;
  statusFlag          = false;
  alertToggle         = false;
  historyData:any     = [];
  isDelete            = false;
  deleteClicked       = false;
  from:any            = '';
  delArray :any       = [];
  empalertMsg         = "The selected employee(s) payroll has already been Processed/Freezed/Held/Published. Kindly undo the respective action to add/remove LOP";
  errorMsg = ''
  successMsg = ''
  body = '';
  rowData:any;
  year:any;
  month:any;
  company:any
  loader = true
  excelMonth : any;
  inputdata :any;
  isProgressStart = false
  chunkLen = 10
  percentage:any = 0;
  iscompleted = false;
  isDataUpload = 0;
  empArr:any = []

  constructor(
    public sds:SalaryDashboardService,
    private notificationService: NotificationService,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService : MessageService

    ) { }

  ngOnInit(): void {

    this.getPermission();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.getEmployeeList();
    this.historyList()
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/run-payroll');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  onFileChange(evt: any) {

    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];

        this.fileName = target.files[0].name;

        this.fileSize = this.appService.formatSizeUnits(target.files[0].size)
        this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }

  processData(){
    if(this.data.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.data[0];
      let Heading = ['Employee code','Employee name','Salary month','Salary Year', 'LOP Days', 'Comment']
      let success = Heading.every(function(val:any) {

        return headerRow.indexOf(val) !== -1;
      });

      if(success == false){
        this.deleteToggle = true;
        this.deleteMsg = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
      this.prepareUploadData();
    }
  }

  checkSuccessData(){
    if(this.successData.length==0)
      this.clearData()
  }

  toMonthName(monthNumber:any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

  prepareUploadData(){
    // this.validatedData = [];
    this.data.forEach((item:any, key:any) => {
      if(key != 0){
        let k = item[0];
        this.keyArray.push({k : key-1,v:k});

        if(isNaN(item[2])){
          this.excelMonth = item[2]
        }else{
          this.excelMonth = this.toMonthName(item[2])
        }

        if(this.month == this.excelMonth && this.year == item[3]){
          this.inputData.push({
            "index"     : key-1,
            "employee"  : item[0]?item[0]:"",
            "actual_lop": item[4]?item[4]:0,
            "comment"   : item[5]?item[5]:"",

          })
        }
      }
    });
    if(this.inputData.length <=0){
      this.deleteToggle = true;
      this.deleteMsg = "The uploaded file does not contain any data for "+this.month+" "+this.year;
      this.clearData();
    }

    // this.uploadData();
  }
  uploadData(){
    let uploadData:any = [];
    this.submiited     = true;
    this.inputData.forEach((item:any, key:any) => {

        uploadData.push({
          "employee"  : item.employee?item.employee:"",
          "actual_lop": item.actual_lop?item.actual_lop:0,
          "comment"   : item.comment?item.comment:"",
          "month"     : this.month,
          "lop_year"  : this.year,
        })
    });

    this.sds.verifyLOPData({ "company_id" : this.company,"data":uploadData}).subscribe((res: any) => {
      this.showSidePanel  = false;
      this.status="Unprocessed"

      for(let i=0;i<res.body.error_info.length;i++){
        this.validatedData.push(res.body.error_info[i]);
        this.validate('comment',this.validatedData.length-1)
      }
      for(let i=0;i<this.validatedData.length;i++){
        this.validatedData[i]
        this.selectedData.push(this.validatedData[i]['employee_id']);
      }

    });
  }

  getEmployeeList(){
    this.sds.employeeList(this.company ).subscribe((res: any) => {
        this.empDD = res.body;
        let len = this.empDD.length
        for (let i = 0; i < len; i++) {
          this.empDD[i]['fullname']  = "";
          if(this.empDD[i]['first_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
           if(this.empDD[i]['middle_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['middle_name']+" ";
          if(this.empDD[i]['last_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['last_name'];
           this.empDD[i]['fullname']   +=" ("+this.empDD[i]['employee_code']+")";


          }

        this.empArr=  this.empDD
    });
  }

  addEmployee(){
    let initialData:any  =
    {
      "employee": {
                    "data"    : "",
                    "is_valid": false,
                    "error"   : null
                  },
      "actual_lop": {
                      "data"    : 0,
                      "is_valid": false,
                      "error"   : null
                  },
      "comment": {
                    "data"    : "",
                    "is_valid": false,
                    "error"   : null
                  },
      "employee_id"   : null,
      "final_lop"     : 0,
      "adjust_lop"    : null,
      "employee_name" : '',
      "payroll_status": ''
    }
    this.addData.push(this.validatedData.length);

    this.validatedData.push(initialData);
    this.page = Math.ceil(this.validatedData.length / this.pageSize);
  }
  setEmpCode(data:any,index_withPage:any){

    let addempdata:any = [];
    // let index_withPage = this.pageSize*(this.page-1)+ i;
    let employee = this.empDD.find(item => item.id === data.id);
    let reset    = false;
    this.validatedData[index_withPage]['employee'].data      = employee?.employee_code;
    this.validatedData[index_withPage]['employee'].is_valid  = false;
    this.validatedData[index_withPage]['employee_name']      = employee.first_name+" "+(employee.middle_name!=null?employee.middle_name+" ":" ")+employee.last_name;
    this.validatedData[index_withPage]['employee_code']  = employee?.employee_code;
    this.validatedData[index_withPage]['actual_lop'].data    = 0;
    this.validatedData[index_withPage]['final_lop']          = 0;
    this.validatedData[index_withPage]['adjust_lop']         = null;
    this.validatedData[index_withPage]['comment'].data       = '';

    addempdata.push({
      "employee": this.validatedData[index_withPage]['employee'].data,
      "month": this.month,
      "lop_year": this.year,
      "actual_lop": this.validatedData[index_withPage]['actual_lop'].data,
      "comment": this.validatedData[index_withPage]['comment'].data,
    })
    this.sds.verifyLOPData ({ "company_id" : this.company,"data":addempdata}).subscribe((res: any) => {
        this.validatedData[index_withPage]['employee'].is_valid = res.body.error_info[0]['employee'].is_valid;
        this.validatedData[index_withPage]['employee'].error    = res.body.error_info[0]['employee'].error;
        this.validatedData[index_withPage]['payroll_status']    = res.body.error_info[0]['payroll_status'];
          })
    Object.entries(this.selectedData).forEach(([key, value]) => {
        if(key==index_withPage){

          this.selectedData[key] = data.id;
          reset = true;
        }
    })
    if(reset==false)
    this.selectedData.push(data.id);
    // this.updateEmpList()
  }
  updateEmpList(){
    const empArr:any = []
    this.empDD.forEach((item:any) => {
      if(!this.selectedData.includes(item?.id) ){
      empArr.push(item)
    }
    })
    this.empArr = empArr
  }
  deleteRow(index:any){

    if(this.status == 'Unprocessed'){

      this.validatedData.splice(index, 1);
      this.selectedData.splice(index, 1);
      this.updateEmpList()
      for(let i=0;i<this.addData;i++){
        if(this.addData[i]==index)
          this.addData.splice(i,1)
        else if(this.addData[i]>index)
          this.addData[i]--;
      }

      if(this.validatedData.length == this.addData.length){
        this.clearData()
      }
    } else if(this.historyData[index]?.payroll_status=='Unprocessed'){
      this.rowData = {
        "section": "lop",
        "delete_id_list": [this.historyData[index].id]
        }
      this.confirmRowDel('single');
      this.loader = false

    }
    else if(this.historyData[index]?.payroll_status!='' && this.historyData[index]?.payroll_status!=null){
      this.loader     = false;
      this.alertToggle = true;
    }

  }
  confirmRowDel(source:any){
    this.isDelete = true;
    this.from     = source;
  }
  deleteLOP(){
    this.deleteClicked  = false;
    if(this.from=='single'){
      this.deleteHistory(this.rowData)

    }
    else{
      let data = {
        "section": "lop",
        "delete_id_list": this.delArray
        }
      this.deleteHistory(data);

    }
  }
  validateData(field:any,index:any){
    // let index = this.pageSize*(this.page-1)+ i;
    this.validate(field,index)
  }

  validate(field:any,index:any){

    if(field=='comment'){
      let data = this.validatedData[index]['comment'].data;
      const unamePattern = /^(?! )+[A-Za-z0-9 @#&_+\-\;':"\\,\/]*[A-Za-z0-9@#&_+\-\;':"\\,\/]$/.test(this.validatedData[index]['comment'].data);
      // const unamePattern = true
      if(unamePattern){
        if(data.length>100){

          this.validatedData[index]['comment'].is_valid = true;
          this.validatedData[index]['comment'].error    = this.messageService.fieldlengthvalidation('word',100);

        }
         else
         this.validatedData[index]['comment'].is_valid = false;
      }
      else{
        this.validatedData[index]['comment'].is_valid = true;
        this.validatedData[index]['comment'].error    = this.messageService.validationDisplay('pattern');

      }

    }
    else if(field=='adjust_lop'){
      let lop = this.validatedData[index]['adjust_lop']
      const digitdecimalpattern = /^[0-9]\d*(\.[5])?$/.test(lop);
      if(digitdecimalpattern){
        if(lop<=31){

          this.validatedData[index]['final_lop']          = this.validatedData[index]['adjust_lop']
          this.validatedData[index]['adjust_lop_pattern'] = false;
        }
        else{
            this.validatedData[index]['adjust_lop_pattern'] = true;
            this.validatedData[index]['adjust_lop_msg']     = this.messageService.fieldlengthvalidation('days','31');

        }

      } else if(lop == ""){
        this.validatedData[index]['final_lop']          = this.validatedData[index]['actual_lop'].data
        this.validatedData[index]['adjust_lop_pattern'] = false;
        this.validatedData[index]['adjust_lop_msg']     = ""
      }
      else{

        this.validatedData[index]['adjust_lop_pattern'] = true;
        this.validatedData[index]['adjust_lop_msg']     = this.messageService.validationDisplay('pattern');

      }

    }

  }
  filterIt() {
    if(this.status=='Unprocessed'){
      return this.validatedData?.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey?.includes(tempSearch);
          }
        });
      });
    } else {
      return this.historyData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey?.includes(tempSearch);
          }
        });
      });
    }
  }
  filteredcount(){
    return this.filterIt().length;
  }
  saveLOPData(){
    this.isDataUpload = 1;
    this.iscompleted = false
    this.loader       = true
    this.saveComplete = true;
    this.percentage   = 0;

    if(this.saveData.length>0){
      this.isProgressStart = true
        this.failedData = []
        var l = this.saveData.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.successDataCount = 0
        this.processUpload(0,p);
    } else
    this.loader       = false
    this.alertToggle  = false;
}

processUpload(k:any,p:any){
    var l = this.saveData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.failedData       = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveData[i])
    }

    this.sds.submitLOPData({ company_id : this.company,"data":saveDataArray}).subscribe((res: any) => {
      this.body = x+' out of '+l+" data uploaded successfully"
        this.confirmMsg = false;
        if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
          res.body.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
        }
        // this.successDataCount += res.body.succesd_data_count;
        this.percentage +=((p/4)*3);
        if(x<l)
            this.processUpload(k+1,p);
        else{
          this.iscompleted = true
              this.isProgressStart = false
            this.successDataCount = this.saveData.length- this.failedData.length;
            if(this.failedData.length == 0){
                this.notificationService.handleSuccessNotification(this.successDataCount+" LOP data uploaded successfully.","Success");
                if(this.saveData.length ==this.validatedData.length )
                  this.CompleteUpload();
                else
                    this.bulkDelete()
            } else {
              this.exportData    = [];
              for (let i = 0; i < this.failedData.length; i++) {
                this.exportData.push({'Employee code':this.failedData[i]['data']['employee'], 'LOP Days':this.failedData[i]['data']['actual_lop'], 'Comment':this.failedData[i]['data']['comment'],'Error description':this.failedData[i]['error']});
              }
                this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
                this.errorMsg = this.failedData.length+' employee(s) have error data'
                this.downloadMsg = true;
            }
            this.historyList()
        }

      },
      (error:any)=>{
        this.isProgressStart = false
        this.notificationService.handleErrorNotification('Something went wrong','Error')
      }
      );
}
  // saveLOPData(){
  //   this.loader       = true
  //   this.saveComplete = true;


  //   this.sds.submitLOPData({ "company_id" : this.company,"data":this.saveData}).subscribe((res: any) => {

  //     this.failedData       = res.body.failed_data;
  //     this.confirmMsg       = false;
  //     this.successDataCount = this.saveData.length- this.failedData.length;
  //     if(this.failedData.length == 0){
  //         this.notificationService.handleSuccessNotification(this.successDataCount+" LOP data uploaded successfully.","Success");
  //         if(this.saveData.length ==this.validatedData.length )
  //         this.CompleteUpload();
  //         else
  //         this.bulkDelete()
  //     } else {
  //       this.downloadMsg = true;
  //     }
  //     this.historyList()
  //   })
  // }
  downloadError(){
    this.exportData    = [];
    for (let i = 0; i < this.failedData.length; i++) {
      this.exportData.push({'Employee code':this.failedData[i]['data']['employee'], 'LOP Days':this.failedData[i]['data']['actual_lop'], 'Comment':this.failedData[i]['data']['comment'],'Error description':this.failedData[i]['error']});
    }
    this.appService.exportExcel(this.exportData,'LOP Error');
    this.downloadMsg = false;
    this.bulkDelete();

  }
  CompleteUpload(){
    this.fileName      = '';
    this.data          = [];
    this.fileSize      = '';
    this.validatedData = [];
    this.selectedData   = [];
    this.addData       = [];
    this.submiited     = false;
    this.inputData     = [];
    this.successData   = [];
    this.keyArray      = [];
    this.preparedData  = [];
    this.exportData    = [];


  }
  clearData(){

    this.tempData      = this.validatedData;
    this.tempSelect    = this.selectedData;
    this.validatedData = [];
    this.selectedData   = [];
    this.data          = [];
    this.inputData     = [];
    this.successData   = [];
    this.keyArray      = [];
    this.preparedData  = [];
    this.submiited     = false;

    // for(let i=0;i<this.addData.length;i++){

    //   this.validatedData.push(this.tempData[this.addData[i]]);
    //   this.selectedData.push(this.tempSelect[this.addData[i]]);
    // }
    this.updateEmpList()
    this.page = 1;

    this.fileName      = '';
    this.fileSize      = '';

  }

  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item: { checked: any; }) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }

  bulkDelete(){
    this.loader = true
    this.delArray = [];
    if(this.status == 'Unprocessed'){
      this.validatedData.forEach((item:any, value:any) => {
        if(item?.checked == true)
        this.delArray.push(value)
      });
      var delArrayRev = this.delArray.reverse()
      delArrayRev.forEach((item:any, value:any) =>{
        this.deleteRow(item);
      })
      this.loader = false
    } else {
      this.historyData.forEach((item:any, value:any) => {
        if(item?.checked == true && item?.payroll_status=='Unprocessed')
          this.delArray.push(this.historyData[value].id)
        else if(item?.checked == true && item?.payroll_status!='Unprocessed')
          this.statusFlag = true;

      });
      if(this.statusFlag==true)
      {

        this.alertToggle = true;
        if(this.delArray.length==0)
        this.loader = false;
      }
      if(this.delArray.length){
        this.confirmRowDel('bulk');

      }
    }


  }

  historyList(){
    this.sds.attendanceHistory(this.year,this.month,this.company,"lop").subscribe((res:any)=>{
      this.historyData = res.body.data;
      this.loader = false
     });
  }
  deleteHistory(data:any){
    this.sds.deleteHistory(data).subscribe((res:any)=>{
      this.isDelete = false;
      this.historyList()
     });
  }

  countSelected(){
    var delArray:any = [];
    if(this.status == 'Unprocessed'){
      this.validatedData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
    } else {
      this.historyData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(this.historyData[value].id)
      });
    }
    return delArray.length
  }
  selectAll(){
    this.inputdata = this.appService.selectAll(this.validatedData)

  }
  unselectAll(){
    this.inputdata = this.appService.unselectAll(this.validatedData)

  }
  confirmation(){
    this.saveData     = [];
    this.saveComplete = false;
    for(let i=0;i<this.validatedData.length;i++) {
      if(this.validatedData[i]?.checked == true && (this.validatedData[i]?.payroll_status=='Unprocessed' || this.validatedData[i]?.payroll_status=='' || this.validatedData[i]?.payroll_status==null)){
        this.saveData.push({

        "employee"    : this.validatedData[i]['employee'].data,
        "month"       : this.month,
        "lop_year"    : this.year,
        "actual_lop"  : this.validatedData[i]['actual_lop'].data,
        "adjust_lop"  : this.validatedData[i].adjust_lop == ""?null:this.validatedData[i].adjust_lop,
        "final_lop"   : this.validatedData[i].final_lop,
        "comment"     : this.validatedData[i]['comment'].data

        })
      }
      else if(this.validatedData[i]?.checked == true && this.validatedData[i]?.payroll_status!='' && this.validatedData[i]?.payroll_status!=null && this.validatedData[i]?.payroll_status!='Unprocessed'){
        this.statusFlag = true;
      }
    }

    if(this.saveData.length>0){
      this.confirmMsgText ="Click the Save button, If you want to add the LOP for the selected employees.";
      this.confirmMsg     = true;
    }
    if(this.statusFlag==true)
      this.alertToggle = true;

  }
  closeInfo(bool:any){
    this.alertToggle = bool;
    this.loader      = false;

  }


}
