import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExistDatasService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };


  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  getSpecificName(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/unique-name-validation/`,body, this.httpOption)

  }
}
