<span class="d-flex align-items-center p-0 ">
  {{title}} :
  <img class="img-fluid rounded-circle mx-1 sq-24 "
    *ngIf="list[0].profile_image !=  null && list[0].profile_image !=  ''" src="{{list[0].profile_image}}">
  <span *ngIf="list[0].profile_image ==  null || list[0].profile_image ==  ''"
    class="avatar-sm sq-24 mx-1 rounded-circle {{list[0].color_code}}"><span>{{this.appservice.getFirstChar(list[0].first_name+"
      "+list[0].last_name,2)}}</span></span>
  {{ list[0].first_name+" "+(list[0].middle_name!=null?list[0].middle_name+"
  ":" "
  )+list[0].last_name+ " (" +
  list[0].employee_code + ")" }}

  <ng-container *ngIf="list?.length >1"> ... <span class="flex-center radius-24 bg-dark-400 px-2"
      [ngbPopover]="popContentMultilper" popoverClass="user-list multi-user" (click)="onPopoverClick($event)"
      triggers="click" container="body">+{{list?.length-1}}</span>
  </ng-container>
</span>
<ng-template #popContentMultilper>
  <ul>
    <ng-container *ngFor="let re of  list, let i = index">
      <ng-container *ngIf="i > 0">
        <li *ngIf="re?.profile_image !='' && re?.profile_image !=null">
          <img class="user-avatar" src="{{re?.profile_image}}">
          <div class="user-details">
            <span class="user-label">{{re?.first_name+'
              '+(re?.middle_name!=null?re?.middle_name:'')+'
              '+re?.last_name}}</span>
            <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
          </div>
        </li>
        <li *ngIf="(re?.profile_image =='' || re?.profile_image ==null )">
          <span class="user-avatar {{re?.color_code}}">{{this.appservice.getFirstChar(re?.first_name+'
            '+re?.last_name,2)}}</span>
          <div class="user-details">
            <span class="user-label">{{re?.first_name+'
              '+(re?.middle_name!=null?re?.middle_name:'')+'
              '+re?.last_name}}</span>
            <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
          </div>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</ng-template>