import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MyTeamAttendanceService } from 'src/app/@core/services/myteam-attendance.service';

@Component({
  selector: 'app-my-team-employee-details',
  templateUrl: './my-team-employee-details.component.html',
  styleUrls: ['./my-team-employee-details.component.scss']
})

export class MyTeamEmployeeDetailsComponent implements OnInit {

  activeTab          = 1;
  navTab             = 1;
  view               = 1 ;
  isChecked: boolean = true;
  activeEmployee: any;
  nodata = true
  loopcompleted = false
  constructor(private MyTeamAttendanceService: MyTeamAttendanceService,public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['myTeamEmpId'])) 
        this.activeEmployee = parseInt(params['myTeamEmpId']);
      this.MyTeamAttendanceService.empDDList(true, 'user__first_name').subscribe((res: any) => {
        for(let i=0; i< res?.data?.length; i++){
          if(res?.data[i].id == this.activeEmployee){
            this.nodata = false
          }
          if(i==res?.data.length-1)
            this.loopcompleted = true
        }
      });
    })
  }

}
