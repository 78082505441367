import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output,Inject, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
@Component({
  selector: 'app-preview-save',
  templateUrl: './preview-save.component.html',
  styleUrls: ['./preview-save.component.scss']
})
export class PreviewSaveComponent implements OnInit {
  name = 'Angular';
  page = 1;
  pageSize =10;
  @Input()headerData:any = [];
  row:any = []
  deleteToggle = false;
  @Input() data:any = []
  @Input() dataType: string = '';
  @Input() dataArranged:any[]= [];
  @Input() errorData:any[]= [];
  @Input() initialHeader:any[]= [];
  searchString:any = "";
  hasError:any = "";
  checkboxes: boolean[] = [];
  allchecked:boolean[] = [];
  phoneCode:any[]  = [
    { id: "91",value : "Indian", code:'IN'},
    { id: "1", value : "United States", code:'US'},
    { id: "44", value : "United Kingdom", code:'GB'},
    { id: "971", value : "United Arab Emirates", code:'AE' },
  ];
  gradeDD :any[]  = [];
  BranchDD :any[]  = [];
  DesignationDD :any[]  = [];
  DepDD  :any[]  = [];
  buDD  :any[]  = [];
  companyDD :any[]  = [];
  empDD :any[]  = [];
  cityDD :any[]  = [];
  country_list :any[]  = [];
  state_list :any[]  = [];
  deleteMessage = "";
  deleteCount = 0;
  var = 0;
  prefixkey = this.messageService.selectplaceholddisplay('Prefix');
  genderkey = this.messageService.selectplaceholddisplay('Gender type');
  bloodgrpkey = this.messageService.selectplaceholddisplay('Blood Group');
  natkey = this.messageService.selectplaceholddisplay('Nationality');
  emptypekey = this.messageService.selectplaceholddisplay('Employment type');
  codeKey = this.messageService.selectplaceholddisplay('country code');
  empstakey = this.messageService.selectplaceholddisplay('Employment status');
  skillkey = this.messageService.selectplaceholddisplay('Skill type');
  paymodekey = this.messageService.selectplaceholddisplay('Pay Mode');
  marstatuskey = this.messageService.selectplaceholddisplay('Marital Status');
  yesnokey = this.messageService.selectplaceholddisplay('Yes/No');
  relationkey = this.messageService.selectplaceholddisplay('Relation');
  @Input() errorCount = 0;
  isDelete = false;
  isSave = false;
  today = new Date();
  reasonForLeaving:any[] = []

  @Output() uploadData =  new EventEmitter();
  @Output() deleteRows =  new EventEmitter();
  @Output() validateData =  new EventEmitter();



  constructor(@Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,private sanitizer:DomSanitizer,private employeeService : EmployeeService,private notificationService: NotificationService,private changeDetectorRef: ChangeDetectorRef, public appservice:AppService,public messageService : MessageService, private SnapshotService:SnapshotService) {

  }
  @HostListener('scroll', ['$event'])
    scrollHandler(event : any | null) {
      // console.debug("Scroll Event");
    }
  ngOnInit(): void {
    // setTimeout(() => {
    //   this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    // }, 1000);
    this.matDateFormat.display.dateInput = 'DD-MM-YYYY';
    this.createCheckBoxArray();


      if(this.phoneCode.length == 0)
        this.getPhonecode();
      if(this.companyDD.length == 0)
        this.getCompanyList();
      if(this.gradeDD.length == 0)
        this.getGradeList();
      if(this.empDD.length == 0)
        this.getManagerList();
      if(this.BranchDD.length == 0)
        this.getBranchList();
      if(this.DesignationDD.length == 0)
        this.getDesignationList();
      if(this.DepDD.length == 0)
        this.getDepList();
      if(this.buDD.length == 0)
        this.getBUList();
      if(this.cityDD.length == 0)
        this.getCityList();
      if(this.country_list.length == 0)
        this.countryList();
      if(this.state_list.length == 0)
        this.permenentStateList();
      if(this.reasonForLeaving.length == 0 && this.dataType == "snapshot")
        this.getReasonList()


  }
  dateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date(new Date().getFullYear() + 100, 0, 1)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  dobFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  getClass(obj:any,prop:any){
    return prop+this.getVal(obj,'rowNum')
  }

  getVal(obj:any,prop:any){
    if((obj[prop] == "hasError" || obj[prop] == "hasErrorData") && prop != undefined){
      if((prop.toLowerCase() == 'date of marriage' ||prop.toLowerCase() == 'spouse name' || prop.toLowerCase() == 'spouse dob'
      //|| prop.toLowerCase().includes("children") || prop.toLowerCase().includes("is going to school")|| prop.toLowerCase().includes("is going to hostel") || prop.toLowerCase().includes("is dependant")
      ) && obj['Marital Status'] == 'Single'){
        return "";
      } else if(prop.toLowerCase().includes("date")
        || prop.toLowerCase().includes("year")
        || prop.toLowerCase().includes("dob")
        || prop.toLowerCase().includes("skill")
        || prop.toLowerCase().includes("gender")
        || prop.toLowerCase().includes("blood")
        || prop.toLowerCase().includes("nationality")
        || prop.toLowerCase().includes("employment type")
        || prop.toLowerCase().includes("employment status")
        || prop.toLowerCase().includes("phone code")
        || prop.toLowerCase().includes("country code")
        || (prop.toLowerCase().includes("company") && this.dataType != "health" && !prop.toLowerCase().includes("previous"))
        || (prop.toLowerCase().includes("grade") && this.dataType != "education")
        || prop.toLowerCase().includes("manager")
        || prop.toLowerCase().includes("branch") && this.dataType != "bank"
        || (prop.toLowerCase().includes("designation") && !prop.toLowerCase().includes("previous"))
        || prop.toLowerCase().includes("department")
        || prop.toLowerCase().includes("business unit")
        || prop.toLowerCase().includes("prefix")
        || prop.toLowerCase().includes("country")
        || prop.toLowerCase().includes("state")
        || prop.toLowerCase().includes("marital")
        || (prop.toLowerCase().includes("relation") && this.dataType != "personal")
        || (prop.toLowerCase().includes("salary")&& !prop.toLowerCase().includes("taxable"))
        || prop.toLowerCase().includes("is dependant")
        || prop.toLowerCase().includes("is going to school")
        || prop.toLowerCase().includes("is going to hostel")
        || prop.toLowerCase().includes("valid upto")
        || prop.toLowerCase().includes("city")
        || prop.toLowerCase().includes("reason for leaving")
        || prop.toLowerCase().includes("exclude from final settlement")){
        return "hasError";
      }
      else if(this.dataType != "snapshot" && (prop.toLowerCase().includes("first name") || prop.toLowerCase().includes("last name")) )
        return obj[prop];
      else
        return "hasErrorInput";
    }
    else if(typeof obj[prop] === 'object'){
      return this.dateFormatConvert(obj[prop]);
    }
    else if(prop.toLowerCase() == 'employee code' && typeof obj[prop] == 'string')
      return obj[prop].toUpperCase();
    else if (this.onlySpaces(obj[prop] ))
        return "hasErrorInput";
    else
      return obj[prop];

  }
   onlySpaces(str:any) {
    return /^\s*$/.test(str);
  }
  getClassName(ind:any,val:any,key=0){
    var error = this.getError(ind,val,key);
    if(error != null)
      return "is-invalid";
    else
      return "";
  }

  getError(ind:any,val:any,key=0){
    var errorkey = "";
    var errormsg:any = null;
    if(this.dataType != "family" )  {
      var keyt = val.split(' ').join('_');
      if(keyt.toLowerCase() == "date_of_joining"){
        keyt = "date_of_joined";
      } else if (keyt.toLowerCase() == "business_unit"){
        keyt = "businessunit";
      }else if (keyt== "Grade/CGPA"){
        keyt = "grade_cgpa";
      }else if(keyt == 'College/School')
        keyt = "college_school";
      else if(keyt == 'Tax_Deducted_by_previous_employer')
       keyt = "tax_deducted_by";
      else if(keyt == 'Account_holder_Name')
        keyt = "account_holdername";
      else if(keyt == "Employee_Code" && this.dataType != "snapshot")
          keyt = "employee";
      else if(keyt == 'Insuree_date_of_birth')
          keyt = "insuree_of_birth";
      else if(keyt == "Exempted_gratuity_amount")
        keyt = "exempted_gratuity";
      else if(keyt == "Exempted_leave_encashment_amount")
        keyt = "exempted_encashed_leave";
      else if(keyt == "PF_amount_deducted")
        keyt = "pf_amount";
      else if(keyt == "Professional_tax_amount_deducted")
        keyt = "pt_amount";
      else if(keyt == "Employer_NPS_deduction_80CCD(2)")
        keyt = "nps_deduction";
      else if(keyt == 'Mobile_number_Country_code'){
        keyt = 'phone_code'
      }
      if(this.errorData != undefined && this.errorData[ind]!= undefined && this.errorData[ind][keyt.toLowerCase()] != undefined)
        errormsg =  this.errorData[ind][keyt.toLowerCase()]!= null?this.errorData[ind][keyt.toLowerCase()][0]:null
    } else {
      if(key < 11){
        if(val == "Employee Code")
          keyt = "employee";
        else if(val == "Father's Name")
          keyt = "father_name";
        else if(val == "Father's DOB")
          keyt = "father_date_of_birth";
        else if(val == "Mother's Name")
          keyt = "mother_name";
        else if(val == "Mother's DOB")
          keyt = "mother_date_of_birth";
        else if(val == 'Marital Status')
          keyt = "marital_status";
        else if(val == 'Date of Marriage')
          keyt = "date_of_marriage";
        else if(val == 'Spouse Name')
          keyt = "spouse_name";
        else if(val == 'Spouse DOB')
          keyt = "spouse_date_of_birth";
        if(this.errorData != undefined && this.errorData[ind]!= undefined && this.errorData[ind][keyt] != undefined)
          errormsg= this.errorData[ind][keyt]!= null?this.errorData[ind][keyt][0]:this.errorData[ind][keyt];
        else
        errormsg= null
      } else {
        if(val.includes("Children Name")){
          keyt = "child_name";
          errorkey = "children";
        } else if(val.includes("Children DOB")){
          keyt = "child_date_of_birth";errorkey = "children";
        } else if(val.includes("Children Gender")){
          keyt = "child_gender";errorkey = "children";
        } else if(val.includes("Is dependant")){
          keyt = "is_dependent";errorkey = "children";
        } else if(val.includes("Is going to school")){
          keyt = "is_going_to_school";errorkey = "children";
        } else if(val.includes("Is going to hostel")){
          keyt = "is_going_to_hostel";errorkey = "children";
        } else  if(val.includes("Dependent Name")){
          keyt = "dependent_name";errorkey = "dependent_detail";
        } else if(val.includes("Dependent DOB")){
          keyt = "dependent_of_birth";errorkey = "dependent_detail";
        } else if(val.includes("Dependent Gender")){
          keyt = "dependent_gender";errorkey = "dependent_detail";
        } else if(val.includes("Dependent relationship")){
          keyt = "dependent_relation";errorkey = "dependent_detail";
        } else if(val.includes("Dependent remarks")){
          keyt = "dependent_remark";errorkey = "dependent_detail";
        }

        var index = val.slice(-1);
        if(this.errorData != undefined && this.errorData[ind] != undefined && this.errorData[ind][errorkey] != undefined && this.errorData[ind][errorkey][index] != undefined && this.errorData[ind][errorkey][index][keyt]!= undefined)
        errormsg= this.errorData[ind][errorkey][index][keyt]!= null?this.errorData[ind][errorkey][index][keyt][0]:this.errorData[ind][errorkey][index][keyt];
      }
    }
    if(errormsg == '"" is not a valid choice.' || errormsg == "This field may not be blank." || errormsg == "This field may not be null."){
      errormsg = "This field is required.";
    }
    return errormsg;
  }
  createCheckBoxArray(){
    this.checkboxes = new Array(this.data.length);
    this.checkboxes.fill(false);
  }


  saveData(){
    this.uploadData.emit();
  }


  // confirmErrorUpload(){
  //   this.deleteToggle = true;
  //   this.isDelete = false;
  //   this.isSave = true;
  //   this.deleteMessage=this.errorCount+' Error data found. Do you still want to proceed without corrections?';
  // }
  uploadDataWithError(){
    this.deleteToggle = false;
    this.uploadData.emit();
  }
  filterIt() {
    return this.dataArranged.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          const tempSearch2 = this.hasError.toLowerCase();
          return tempKey.includes(tempSearch) && tempKey.includes(tempSearch2);
        }
      });
    });
  }
  isChecked(i:any){
    return this.checkboxes[i];
  }
  allChecked(){
    var filteredArray = this.filterIt()
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var ret = true;

    if(filteredArray.length>0){
      if(end>filteredArray.length)
        end = filteredArray.length
    for (let i =begin; i < end; i++) {
      var k = filteredArray[i]['rowNum'];
      if(k != -1){
        if(this.checkboxes[k] == false)
        ret = false
      }
    }
  }
    return ret
  }

  toggleSelection(event:any, i:any) {
    this.checkboxes[i] = event.target.checked;
    this.checkallSelected();
  }
  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      var k = item['rowNum'];
      if(k != -1){
        this.checkboxes[k] = event.target.checked;
        this.checkallSelected();
      }
    })
  }
  checkallSelected(){
    this.deleteCount = this.checkboxes.filter((obj) => obj === true).length;
    // var begin = (this.page-1) * this.pageSize ;
    // var mainArray = this.checkboxes;
    // var subArray = mainArray.slice(begin, this.pageSize);
    // this.allchecked[this.page] = subArray.every(Boolean);
  }
  filteredcount(){
    return this.filterIt().length;
  }
  deleteSelected(){

    this.deleteRows.emit(this.checkboxes);
    this.checkboxes = [];
    this.createCheckBoxArray();
    this.checkallSelected();
    this.deleteToggle = false;
    this.changeDetectorRef.detectChanges();

  }
  getPhonecode(){
      this.employeeService.getCountryCode().subscribe((res:any)=>{
        this.phoneCode  = res;
      })
  }
  getCompanyList(){
    this.employeeService.getCompanyList().subscribe((res: any) => {
      this.companyDD = res;
    });
  }

  getBUList(){
    this.employeeService.getBuList().subscribe((res: any) => {
          this.buDD = res;
    });
  }

  getDepList(){
    this.employeeService.getDepartmentList().subscribe((res: any) => {
          this.DepDD = res;
    });
  }

  getDesignationList(){
    this.employeeService.getDesignationList().subscribe((res: any) => {
          this.DesignationDD = res;
    });
  }
  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
          this.BranchDD = res;
    });
  }

  getGradeList(){
    this.employeeService.getGradeList().subscribe((res: any) => {
          this.gradeDD = res;
    });
  }
  getManagerList(){
    this.employeeService.managerList().subscribe((res: any) => {
          this.empDD = res;
          let len = this.empDD.length
          for (let i = 0; i < len; i++) {
          this.empDD[i]['fullname']  = "";
          if(this.empDD[i]['first_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
          if(this.empDD[i]['middle_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['middle_name']+" ";
          if(this.empDD[i]['last_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['last_name'];
           this.empDD[i]['fullname']   +=" ("+this.empDD[i]['employee_code']+")";

          }
    });
  }

  getCityList(){
    this.employeeService.getCityList().subscribe((res: any) => {
      this.cityDD = res;
    });
  }
  getReasonList(){
    this.SnapshotService.getReason().subscribe((res: any) => {
      this.reasonForLeaving = res;
    });
  }
  countryList() {
    this.employeeService.getCountryList()
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.country_list = res;

      }
    })
  }
  permenentStateList() {
    this.employeeService.geStateList()
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.state_list = res;
      }
    })
  }
  errorThrown = false
  throwdeleteError(){
    this.notificationService.handleErrorNotification("Select rows to delete","Error");
    this.errorThrown = true
    setTimeout(() => {
      this.errorThrown = false
    }, 5000);
  }
  processDelete()  {
    if(this.deleteCount > 0){
      this.deleteToggle = true;
      this.isDelete = true;
      this.isSave = false;
      this.deleteMessage='Do you want to delete the rows selected?';
    }
    else
      if(this.errorThrown == false)
        this.throwdeleteError();
  }

  dateFormatConvert(date:any){
    return this.appservice.dateFormatDisplay(date);
  }




}
