<div class="side-panel-body" style="overflow-y: auto;">
  <div class="container-fluid p-32 ">
      <div class="col-12">
          <h5>DETAILS</h5>
      </div>
      <div class="card card-c2 p-32">
          <div class="row  row-16">

              <div class="col-12 d-flex ">
                  <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
              </div>
              <div class="col-12 mt-8">
                  <h5 class="mb-8">Expense Report Request Approval</h5>
                  <span class=" badge-custom {{data?.request_type_color_code}}">Expense Report</span>
              </div>
              <div class="col-12">
                <div class="card details-card px-24 pb-24 pt-16">
                  <div class="row row-16 ">
                    <div class="col-12 align-items-center d-flex">
                      <p class="title mb-8">{{requestitem?.expense_report_data?.name}}</p>
                    </div>
                    <div class="col-12">
                      <app-fnf-view-expense-report class="row row-16 " [requestitem]="requestitem?.expense_report_data" [receipts]="requestitem?.expense_report_data?.expense_receipts" [advances]="requestitem?.expense_report_data?.expense_advance" [from]="'request'" [currency]="currency"></app-fnf-view-expense-report>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container [formGroup]="form" *ngIf="!requestitem?.achieved_status">
                <div *ngIf="reqStatus == 'Pending'" class="col-12">
                    <textarea name="" id="" rows="3" placeholder="Comment here..." formControlName="comment"
                        class="form-control my-12 textarea-comment"
                        [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
                    <div *ngIf="f.comment.errors" class="invalid-feedback">
                        <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                        </div>
                    </div>
                </div>
                <div *ngIf="reqStatus == 'Pending'" class="col-12 d-flex gap-16">
                    <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                        (click)="confirm('Rejected')">Decline</button>
                    <button class=" btn btn-primary text-uppercase btn-sm fw-400"
                        (click)="confirm('Approved')">Confirm</button>
                </div>
            </ng-container>
          </div>
      </div>
  </div>
</div>
