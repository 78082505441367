<div class="config-layout">
  <header class="config-header">
    <h5 class="m-0">Expense {{from=='behalf'?' Of '+ requestFor:''}}</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class="config-sidebar">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success':expenseReportForm['controls'].reportlist.valid}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Report Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-expense-report-details [reportListData]="reportListData" [reportListLoader]="reportListLoader"
            [formGp]="reportlistForm" [currency]="currency" [reportSearchData]="reportSearchData"
            (searchemit)="searchemit($event)" (submitmethod)="submitOne()"
            (setAdvanceAndReceipt)="setAdvanceAndReceipt($event)" [from]="from" [empId]="empId"></app-expense-report-details>
        </ng-template>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step" [class]="{'success': receiptListData?.length > 0 && tab2touched}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Expense Receipts</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-expense-report-expense-receipt [receiptListData]="receiptListData"
            [receiptListLoader]="receiptListLoader" [currency]="currency"
            [unReportedReceiptList]="unReportedReceiptList"
            [unReportedReceiptListMainArray]="unReportedReceiptListMainArray" [formGp]="reportlistForm"
            (unReportReceiptData)="unReportReceiptData($event)"
            (submitmethod)="submitTwo()" [from]="from" [empId]="empId"></app-expense-report-expense-receipt>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="step" [class]="{'success': tab3touched}">
          <div class="count">3</div>
          <div class="content">
            <span>Step 3</span>
            <h6>Advance Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-expense-report-advance-details [advanceListData]="advanceListData"
            [advanceListLoader]="advanceListLoader" [currency]="currency" [applyAdvanceData]="applyAdvanceData"
            [formGp]="reportlistForm" (updatedadvancelist)="updatedadvancelist($event)"
            (submitmethod)="submitThree()"  [from]="from" [empId]="empId"></app-expense-report-advance-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink class="step" [class]="{'success': tab4touched}">
          <div class="count">4</div>
          <div class="content">
            <span>Step 4</span>
            <h6>Summary</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-expense-report-summary [formGp]="reportlistForm" [receiptListData]="receiptListData"
            [advanceListData]="advanceListData" [currency]="currency"
            (submitmethod)="finalSave()"></app-expense-report-summary>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class="config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
