import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent} from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  FILTERDATA:any;
  constructor(
    private http: HttpClient,
    public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(event.url != '/employee' && event.url != '/employee/employee-setup' &&  !event.url.includes('employee-hr-edit')){
          this.setFilterData(null)
        }
      }
    })
  }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  excelUpload(data:any) {
    // console.log("service:",data)
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-excel-upload/`,data, {
          reportProgress: true,
          responseType: 'json'
        });

  }

  download() {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-excel-download/`);
  }

  getDepartmentList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getDesignationList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getGradeList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getBranchList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  getCompanyList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
  }
  getBuList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }
  getBuListCompany(compid:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name&company_id=${compid}`, this.httpOption);
  }

  getCountryCode(){
    return this.http.get(`${this.API_PATH}organization/api/v1/telephonecode-list/`, this.httpOption)
  }
  managerList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name`, this.httpOption);
  }
  empDDList(isactive:any,ordering:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${isactive}&ordering=${ordering}`, this.httpOption);
  }



  employeeList(isactive:any,limit:any,offset:any,ordering:any,search:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-list/?is_active=${isactive}&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}`, this.httpOption);
  }
  getfilterdata(status:any,limit:any,offset:any,search:any,filterparam:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/employee-list/?limit=${limit}&is_active=true&offset=${offset}&search=${search}&${filterparam}`, this.httpOption)
  }
  getsingleEmployee(id:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-list/?emp_id=${id}`, this.httpOption);
  }

  searchResults(limit:any,offset:any,search:any,filterparam:any='',id:any){
    if(id == 0)
      return this.http.get(`${this.API_PATH}organization/api/v1/employee-list/?limit=${limit}&is_active=true&offset=${offset}&search=${search}&${filterparam}`, this.httpOption)
    else
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-list/?emp_id=${id}`, this.httpOption);
  }


  saveEmployee(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-create/`, body, this.httpOption)
  }
  employeeStatusList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-status-list/`, this.httpOption);
  }
  downloadSnapshotTemplate(existing:boolean,selectedDataTypes:any){
    if(existing == false)
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-excel-download/?import=${selectedDataTypes}`,  {responseType: 'blob' as 'blob'});
    else
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-excel-download/?existing=${existing}&import=${selectedDataTypes}`,  {responseType: 'blob' as 'blob'});
  }

  saveSnapShot(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-snapshot-upload/`, body, this.httpOption)
  }
  validateSnapShot(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-snapshot-validate/`, body, this.httpOption)
  }
  getCityList(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
  }

  downloadErrorFile(errorlist:any){
    return  this.http.post(`${this.API_PATH}organization/api/v1/employee-error-download/`,errorlist , {responseType: 'blob' as 'blob'});
  }

  saveHealth(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-health-upload/`, body, this.httpOption)
  }
  saveEducation(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-education-upload/`, body, this.httpOption)
  }
  savePreEmployer(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-prev-employer-upload/`, body, this.httpOption)
  }

  saveProfessional(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-professional-upload/`, body, this.httpOption)
  }

  savePersonal(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-personal-upload/`, body, this.httpOption)
  }

  saveBank(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-bank-upload/`, body, this.httpOption)
  }

  saveFamily(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-family-upload/`, body, this.httpOption)
  }
  validateFamily(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-family-validate/`, body, this.httpOption)
  }

  getEmpcode(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-code`, this.httpOption);
  }

  validateEducation(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-education-validate/`, body, this.httpOption)
  }
  validateProfessional(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-professional-validate/`, body, this.httpOption)
  }

  validatePreEmployer(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-prev-employer-validate/`, body, this.httpOption)
  }
  validatePersonal(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-personal-validate/`, body, this.httpOption)
  }

  validateHealth(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-health-validate/`, body, this.httpOption)
  }
  validateBank(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/employee-bank-validate/`, body, this.httpOption)
  }

  getCountryList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/country-list/`, this.httpOption);
  }

  geStateList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/region-list/`, this.httpOption);
  }

empDDfilterList(isactive:any,filterparam:any){
  return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=${isactive}&${filterparam}`, this.httpOption);
}
employeeStatusListfilter(data:any){
  return  this.http.get(`${this.API_PATH}organization/api/v1/employee-status-list/?${data}`, this.httpOption);
}
setFilterData(val:any){
  this.FILTERDATA = val
}

}
