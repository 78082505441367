<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row row-16 mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Attendance Rule</h3>
      <p class="text-helper mb-md-0">Add and pre-define a set of rules to handle employee's time and attendance
        efficiently.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end gap-16">
        <div class=" " style="width: 363px">
          <app-searchbar [searchlist]='ruleDropdown' [searchplaceholder]='searchString'
            (searchEmitter)="filterRule($event)" (keywordsearchEmitter)="searchattendance($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" routerLink="/attendance-rule/attendance-rule-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add">
          <i class="icon-plus"></i>Add ATTENDANCE RULE
        </button>
      </div>
    </div>
  </div>
  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <div class="col-xl-3 col-lg-4 col-md-6 " *ngFor="let item of items;let ind = index">
        <div class="card card-custom1 card-hover">
          <div class="card-body p-0 d-flex align-items-start">
            <div class="d-flex view-section" (click)="viewDetailPanel(item.id)">
              <div class="logo-img {{item.color_code}}">
                {{this.appService.getFirstChar(item.attendance_rule_name,2)}}</div>
              <div class="overflow-hidden ">
                <h6 class="mb-0 fw-500 text-trim tips">
                  {{item.attendance_rule_name}}
                </h6>
                <p class="mb-0 tips-helper">{{item.attendance_rule_name}}</p>
                <span
                  class="fs-10 text-uppercase fw-500 {{(item.is_active == true) ? 'text-success' : 'text-danger' }}">{{item.is_active
                  == true ? 'Active':'Deleted'}}</span>
              </div>
            </div>
            <div *ngIf="item.is_active==true && (permissions.e || permissions.d)" ngbDropdown
              class="dropdown-section d-inline-block ">
              <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-three-dots-vertical fs-16"></i>
              </button>
              <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                <button *ngIf="permissions.e" ngbDropdownItem (click)="editRule(item.id)">
                  <i class="icon-pencil-square me-1"></i> Edit
                </button>
                <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                  (click)="deleteAlert=true;deleteId=item.id">
                  <i class="icon-trash  me-1"></i> Delete
                </button>
              </div>
            </div>
          </div>
          <div class="card-body p-16 d-flex justify-content-between" (click)="viewDetailPanel(item.id)">
            <div class="text-light-400 fs-10  text-trim">APPLICABLE ON</div>
            <div class="text-400 fs-12 text-capitalize text-trim">{{item?.applicable_on}}</div>
          </div>
        </div>
      </div>
      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6" [cardType]="'card4'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->
  </ng-template>
  <div *ngIf="nodata && !loader;else all_data" class="row " style="height: calc(100vh - 12rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg46.png'" [noDataText]="'Attendance Guidelines!'"
      [noDataPara]="'Attendance rules are not set up yet. Click \'Add Attendance Rule\' to configure them.'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
  </div>
</div>
<div *ngIf="permissions" class="side-panel" style="--sidepanel-width: 700px"
  [class.side-pane-active]="viewDetail === true">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="vLoader"></app-view-loader>
        <ng-container *ngIf="!vLoader">
          <div class="row row-24  ">
            <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0">ATTENDANCE RULE</p>
              <a *ngIf="permissions.e && viewData.is_active
                        == true" routerLink="/attendance-rule/attendance-rule-setup/{{viewId}}"
                class="link-primary fs-14 d-flex align-items-center ms-auto">
                <i class="icon-pencil-square me-1"></i> Edit
              </a>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24">
                  <div class="col-12">
                    <span class="title">ATTENDANCE RULE DETAILS</span>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Attendance rule name</div>
                    <div class="detail-desc">{{viewData.attendance_rule_name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewData.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="viewData.is_active==false">DELETED</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Attendance rule description</div>
                    <div class="detail-desc">{{viewData?.attend_rule_desc}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">How do you want to calculate total working hours?
                    </div>
                    <div class="detail-desc">{{viewData?.calculate_total_work}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">How do you want to manage punch</div>
                    <div class="detail-desc">{{viewData?.manage_punch}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">How do you want to keep the timings?</div>
                    <div class="detail-desc">{{viewData?.keep_time}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Are week-offs treated as paid days?</div>
                    <div class="detail-desc">{{viewData?.weekoff_paid}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Attendance correction arrear’s upto?</div>
                    <div class="detail-desc">{{viewData?.arrear_upto}} {{viewData?.arrear_upto>1?"months":"month"}}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Attendance cycle</div>
                    <div class="detail-desc">{{viewData?.attendance_cycle}}</div>
                  </div>
                  <ng-container *ngIf="viewData?.attendance_cycle == 'Manual'">
                    <div class="col-12">
                      <div class="detail-title">How would you like to calculate the attendance?</div>
                      <div class="detail-desc">{{viewData?.attendance_calculate}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Start day</div>
                      <div class="detail-desc">{{viewData?.start_day}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">End day</div>
                      <div class="detail-desc">{{viewData?.end_day}}</div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <div class="detail-title">Mark [Absent - Weeklyoff - Absent] as [Absent - Absent
                      -
                      Absent]</div>
                    <div class="detail-desc">{{viewData?.mark_absent_weekoff}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Mark [Absent - Holiday - Absent] as [Absent - Absent -
                      Absent]</div>
                    <div class="detail-desc">{{viewData?.mark_absent_holiday}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24">
                  <div class="col-12">
                    <span class="title">LATE COMING PENALTY</span>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to enable late coming penalty?</div>
                    <div class="detail-desc">{{viewData?.late_coming_penalty}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Grace days given to come late as well as to go early
                    </div>
                    <div class="detail-desc">{{viewData?.grace_day_come_late_go_early}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Late coming and early going deduction is to be done
                      from
                    </div>
                    <div class="detail-desc">{{viewData?.late_come_go_early_deduction}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Deduction type</div>
                    <div class="detail-desc">{{viewData?.deduction_type}} </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Specify the priority to be deducted from the leave
                      balance
                    </div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1" *ngIf="viewData?.prior_deduct_attendancerule?.length">
                        <li class="ng-value" *ngFor="let rights of viewData.prior_deduct_attendancerule, let i=index;">
                          <div class="ng-value-label" title="{{rights.leave_type_name}}">
                            <span class="d-inline-flex align-items-center">
                              <span> {{rights.leave_type_name}}</span>
                            </span>
                          </div>
                        </li>
                      </div>
                      <div *ngIf="viewData?.prior_deduct_attendancerule?.length == 0">{{'-'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24">
                  <div class="col-12">
                    <span class="title">COMPENSATORY OFF</span>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to enable comp off?</div>
                    <div class="detail-desc">{{viewData?.enable_compoff}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Minimum working hour for full day compensatory off
                    </div>
                    <div class="detail-desc">{{viewData?.min_hour_full_day}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Minimum working hour for half day compensatory off
                    </div>
                    <div class="detail-desc">{{viewData?.min_hour_half_day}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want auto initiate extra working benefit</div>
                    <div class="detail-desc">{{viewData?.auto_initiate_extra_working_benefit}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set comp off configuration for weekoff</div>
                    <div class="detail-desc">{{viewData?.comp_off_weekoff}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set comp off configuration for holiday</div>
                    <div class="detail-desc">{{viewData?.comp_off_holiday}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set comp off configuration for national holiday</div>
                    <div class="detail-desc">{{viewData?.comp_off_nationalholiday}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set comp off configuration for workdays</div>
                    <div class="detail-desc">{{viewData?.comp_off_workdays}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Formula to calculate comp off eligibility during work
                      days
                    </div>
                    <div class="detail-desc">{{viewData?.comp_off_eligibility_fulldays}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set comp off configuration for half working day
                    </div>
                    <div class="detail-desc">{{viewData?.comp_off_halfday_work}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Formula to calculate comp off eligibility during half working day
                    </div>
                    <div class="detail-desc">{{viewData?.comp_off_eligibility_halfdays}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Comp off will expire</div>
                    <div class="detail-desc">{{viewData?.comp_off_expiry}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">After how many days</div>
                    <div class="detail-desc">{{viewData.comp_off_expiry == 'Yes' ?
                      viewData?.after_how_many_days+' days' : '-'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24">
                  <div class="col-12">
                    <span class="title">OVERTIME</span>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to enable overtime?</div>
                    <div class="detail-desc">{{viewData?.enable_overtime}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Minimum additional hours required to consider overtime in a day
                    </div>
                    <div class="detail-desc">{{viewData.min_hour_required_consider_overtime_day}}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set overtime for work days</div>
                    <div class="detail-desc">{{viewData?.set_overtime_workday}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Formula to calculate overtime eligibility during workdays</div>
                    <div class="detail-desc">{{viewData?.overtime_eligibility_fullday}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Maximum overtime hours allowed on working days</div>
                    <div class="detail-desc">{{viewData?.max_overtime_hour_allow_working_day}}</div>
                  </div>
                  <!-- <div class="col-lg-6">
                    <div class="detail-title">Rate for working days</div>
                    <div class="detail-desc">{{viewData?.rate_working_days}}</div>
                  </div> -->

                  <div class="col-md-12" *ngIf="viewData?.set_overtime_workday=='Yes'">
                    <div class="card details-card shadow-0 p-16 h-100">
                      <div class="row row-24">
                        <div class="col-12">
                          <span class="title">OVERTIME COMPENSATION CRITERIA </span>
                        </div>
                        <ng-container *ngFor="let week of workingDay">

                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Over time hours between</div>
                            <div class="detail-desc">
                              {{this.appService.formatTime(week.hours_from)}} to {{this.appService.formatTime(week.hours_to)}}</div>
                          </div>
                          <div class="  col-sm-6 col-12">
                            <div class="detail-title">Pay rate</div>
                            <div class="detail-desc">
                              {{week.rate}}</div>
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="detail-title">Do you want to set overtime for weekly off</div>
                    <div class="detail-desc">{{viewData?.set_overtime_weekoff}}</div>
                  </div>

                  <div class="col-12">
                    <div class="detail-title">Maximum overtime hours allowed on weekly off</div>
                    <div class="detail-desc">{{viewData?.max_overtime_hour_allow_weekoff}}</div>
                  </div>
                  <!-- <div class="col-lg-6">
                    <div class="detail-title">Rate for weekly off</div>
                    <div class="detail-desc">{{viewData?.rate_weekoff}}</div>
                  </div> -->

                  <div class="col-md-12" *ngIf="viewData?.set_overtime_weekoff=='Yes'">
                    <div class="card details-card shadow-0 p-16 h-100">
                      <div class="row row-24">
                        <div class="col-12">
                          <span class="title">OVERTIME COMPENSATION CRITERIA </span>
                        </div>
                        <ng-container *ngFor="let week of weekOff">

                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Over time hours between</div>
                            <div class="detail-desc">
                              {{this.appService.formatTime(week.hours_from)}} to {{this.appService.formatTime(week.hours_to)}}</div>
                          </div>
                          <div class="  col-sm-6 col-12">
                            <div class="detail-title">Pay rate</div>
                            <div class="detail-desc">
                              {{week.rate}}</div>
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set overtime for holiday</div>
                    <div class="detail-desc">{{viewData?.set_overtime_holiday}}</div>
                  </div>

                  <div class="col-12">
                    <div class="detail-title">Maximum overtime hours allowed on holiday</div>
                    <div class="detail-desc">{{viewData?.max_overtime_hour_allow_holiday}}</div>
                  </div>
                  <!-- <div class="col-lg-6">
                    <div class="detail-title">Rate for holiday</div>
                    <div class="detail-desc">{{viewData?.rate_holiday}}</div>
                  </div> -->
                  <div class="col-md-12" *ngIf="viewData?.set_overtime_holiday=='Yes'">
                    <div class="card details-card shadow-0 p-16 h-100">
                      <div class="row row-24">
                        <div class="col-12">
                          <span class="title">OVERTIME COMPENSATION CRITERIA </span>
                        </div>
                        <ng-container *ngFor="let week of holiday">

                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Over time hours between</div>
                            <div class="detail-desc">
                              {{this.appService.formatTime(week.hours_from)}} to {{this.appService.formatTime(week.hours_to)}}</div>
                          </div>
                          <div class="  col-sm-6 col-12">
                            <div class="detail-title">Pay rate</div>
                            <div class="detail-desc">
                              {{week.rate}}</div>
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set overtime for national holiday</div>
                    <div class="detail-desc">{{viewData?.set_overtime_nationalholiday}}</div>
                  </div>

                  <div class="col-12">
                    <div class="detail-title">Maximum overtime hours allowed on national holiday
                    </div>
                    <div class="detail-desc">{{viewData?.max_overtime_hour_allow_nationalholiday}}
                    </div>
                  </div>
                  <!-- <div class="col-lg-6">
                    <div class="detail-title">Rate for national holiday
                    </div>
                    <div class="detail-desc">{{viewData?.rate_nationalholiday}}
                    </div>
                  </div> -->

                  <div class="col-md-12" *ngIf="viewData?.set_overtime_nationalholiday=='Yes'">
                    <div class="card details-card shadow-0 p-16 h-100">
                      <div class="row row-24">
                        <div class="col-12">
                          <span class="title">OVERTIME COMPENSATION CRITERIA </span>
                        </div>
                        <ng-container *ngFor="let week of nationalHoliday">

                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Over time hours between</div>
                            <div class="detail-desc">
                              {{this.appService.formatTime(week.hours_from)}} to {{this.appService.formatTime(week.hours_to)}}</div>
                          </div>
                          <div class="  col-sm-6 col-12">
                            <div class="detail-title">Pay rate</div>
                            <div class="detail-desc">
                              {{week.rate}}</div>
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to set overtime for half working day
                    </div>
                    <div class="detail-desc">{{viewData?.set_overtime_halfday_work}}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Formula to calculate overtime during half working day
                    </div>
                    <div class="detail-desc">{{viewData?.overtime_eligibility_halfday}}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Maximum overtime hours allowed on half working day
                    </div>
                    <div class="detail-desc">{{viewData?.max_overtime_hour_allow_halfday_working}}
                    </div>
                  </div>
                  <!-- <div class="col-lg-6">
                    <div class="detail-title">Rate for half working day
                    </div>
                    <div class="detail-desc">{{viewData?.rate_halfday_working}}
                    </div>
                  </div> -->
                  <div class="col-md-12" *ngIf="viewData?.set_overtime_halfday_work=='Yes'">
                    <div class="card details-card shadow-0 p-16 h-100">
                      <div class="row row-24">
                        <div class="col-12">
                          <span class="title">OVERTIME COMPENSATION CRITERIA </span>
                        </div>
                        <ng-container *ngFor="let week of halfDay">

                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Over time hours between</div>
                            <div class="detail-desc">
                              {{this.appService.formatTime(week.hours_from)}} to {{this.appService.formatTime(week.hours_to)}}</div>
                          </div>
                          <div class="  col-sm-6 col-12">
                            <div class="detail-title">Pay rate</div>
                            <div class="detail-desc">
                              {{week.rate}}</div>
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24">
                  <div class="col-12">
                    <span class="title">ALERT</span>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to send absenteeism email alert</div>
                    <div class="detail-desc">{{viewData?.send_absent_email}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Trigger absenteeism alert when employee is
                      continuously
                      absent on</div>
                    <div class="detail-desc">{{viewData?.trigger_absent_continuously_absent}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Whom do you want to notify?</div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1" *ngIf="viewData?.whome_want_notify?.length">
                        <li class="ng-value" *ngFor="let rights of viewData.whome_want_notify, let i=index;">
                          <div class="ng-value-label" title="{{rights.role_name}}">
                            <span class="d-inline-flex align-items-center">
                              <span> {{rights.role_name}}</span>
                            </span>
                          </div>
                        </li>
                      </div>
                      <div *ngIf="viewData?.whome_want_notify?.length == 0">{{'-'}}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to send weekly attendance email alert?
                    </div>
                    <div class="detail-desc">{{viewData?.send_weekly_absent_email}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Mark CC to</div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1" *ngIf="viewData?.weekly_notify_cc?.length">
                        <li class="ng-value" *ngFor="let rights of viewData.weekly_notify_cc, let i=index;">
                          <div class="ng-value-label" title="{{rights.role_name}}">
                            <span class="d-inline-flex align-items-center">
                              <span> {{rights.role_name}}</span>
                            </span>
                          </div>
                        </li>
                      </div>
                      <div *ngIf="viewData?.weekly_notify_cc?.length == 0">{{'-'}}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to send monthly attendance email alert?
                    </div>
                    <div class="detail-desc">{{viewData?.send_monthly_absent_email}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Mark CC to</div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1" *ngIf="viewData?.monthly_notify_cc?.length">
                        <li class="ng-value" *ngFor="let rights of viewData.monthly_notify_cc, let i=index;">
                          <div class="ng-value-label" title="{{rights.role_name}}">
                            <span class="d-inline-flex align-items-center">
                              <span> {{rights.role_name}}</span>
                            </span>
                          </div>
                        </li>
                      </div>
                      <div *ngIf="viewData?.monthly_notify_cc?.length == 0">{{'-'}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24">
                  <div class="col-12">
                    <span class="title">RIGHTS</span>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Applicable to</div>
                    <div class="detail-desc">{{viewData.applicable}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">{{viewData.applicable}}(s) </div>
                    <div class="detail-desc">
                      <div class="selected-values gap-8 mt-1">
                        <ng-container *ngIf="viewData.applicable == 'Employee'">
                          <li class="ng-value m-0" *ngFor="let rights of viewData.applicableTo, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,2)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="viewData.applicable == 'Company'">
                          <li class="ng-value m-0" *ngFor="let rights of viewData.applicableTo, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="(viewData.applicable != 'Employee') && (viewData.applicable != 'Company')">
                          <div class="d-flex flex-wrap gap-8">
                            <ng-container *ngFor="let rights of viewData.applicableTo, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{
                                (rights?.length>25)?
                                (rights | slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{ deleteAlert == true ? 'show' : '' }}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="icon-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Attendance Rule?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">
          {{this.messageService.Deletemsgdisplay('Attendance Rule')}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm fw-500 text-uppercase"
          (click)="deleteAlert = false">
          Cancel
        </button>
        <button type="button" class="btn btn-danger btn-sm fw-500 text-uppercase" [disabled]="deleteClicked"
          (click)="deleteRule(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
