import { Component, OnInit, ViewChild } from '@angular/core';
import { CityService } from 'src/app/@core/services/city.service';
import { FormBuilder, Validators  } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { ActivatedRoute } from '@angular/router';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators'
import { CommonFilterComponent } from 'src/app/@shared/components/common-filter/common-filter.component';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {


  filterpanel = false;
  citystatus:any;
  cityid:any;
  cityname:any;
  country_name:any;
  cityView:any;
  state_name:any;
  showSidePanel1=false;
  cities:any[] = [];
  citiesDropdown:any[] = [];
  countries:any = [];
  states:any = [];
  selectedCountry:any = "";
  id = 0;
  showSidePanel =false;
  header = "Add";
  deleteClass = false;
  addEditBtn = "Save";
  submitted = false;
  deleteAlert = false;
  inputSearchClass = false;
  searchString:string =this.messageService.searchdisplay('city');
  status:any = true;
  nodata = false;
  CountrySelect = false;
  // isSuccess = false;
  isError = false;
  disabled = false;
  action= "";
  isValid = false;
  loader = true;
  loader1 = false;
  searchKeyword = ""
  deleteClicked = false
  countryval ="";
  stateval =""

  /********INFINITY SCROLL VARIABLES  ****/
  offset                  = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  cityDetailsList:any     = [];
  infinityloader          = false;
  cityForm = this.formBuilder.group({
    id: 0,
    name:  ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    country: ['', Validators.required],
    state: ['', Validators.required]
  });
  cityFilter = this.formBuilder.group({

    status:  [null],
    country: [""],
    state: [""]
  });

  listType = "active"
  permissions:any = [];


  validated : boolean = false
  cityUnit : any =[]
  routeComponent : any
  editflag :boolean = false
  value:any

  private subjectKeyUp = new Subject<any>();

  isLoading = false
  searchData : Subject<void> = new Subject();

  // unamePattern =  "^(?! )+[A-Za-z0-9 _@/#&+-\,\;\:\'\"]*(?<! )$";
  @ViewChild(SearchbarComponent) child:any;
  constructor(
    private cityService: CityService,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    public breakpointObserver: BreakpointObserver,
    public messageService : MessageService,
    public appService: AppService,
    public existDataService :  ExistDatasService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.getCountryList()
    this.getStateList()
    this.route.url.subscribe((res:any)=>{
      this.routeComponent = res[0].path
    })

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.listCity();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.cityService.cityList(this.status,this.defaultlimit,this.offset,this.searchKeyword,this.countryval,this.stateval)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }



  searchCity(searchKeyword:any){
    this.isLoading = true
    this.loader = true;
    this.offset            = 0;
    this.cities = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      // this.listCity();
      this.searchData.next()
    }
  }
  listCity(){
    this.loader = true;
    this.cityService.cityList(this.status,this.defaultlimit,this.offset,this.searchKeyword,this.countryval,this.stateval).subscribe((res: any) => {
      this.arrangeApiData(res);
    });
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.cityDetailsList= [];
      if(this.offset ==0){
        this.cities = [];
      }
      for(let key of res.results){
        key.class = this.randomClass(0,5);
        this.cityDetailsList.push(key);
      }
      this.listlength = res.results.length;
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.loader = false;
      this.nodata = this.cities.length==0?true:false;
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.listCity();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.cities.push(this.cityDetailsList[i]);
    }
  }

  addCityForm(){
    this.cityForm.reset();
    this.CountrySelect = false;
    this.getCountryList();
    this.showSidePanel = true;
    this.cityForm.reset({ id: 0});
    this.deleteClass = false;
    this.header = "Add";
    this.editflag = false
    this.addEditBtn = "Save";
    this.submitted = false;
    this.isError = false;
    this.action = "added";
    this.cityForm.enable();
    this.disabled = false;
    this.child.clearBar();
  }
  getCountryList(): void {
    this.cityService.countryList().subscribe((res: any) => {
      this.countries = res;
    });
  }


  countrySelectFun(){
    this.cityForm.get('state')?.reset();
    this.states = [];
    this.getStateList();
  }

  getStateList(): void {
    this.CountrySelect = true;
    this.cityService.stateList(this.cityForm.value.country).subscribe((res: any) => {
      this.states = res;

    });
  }
  editcity(city:any){
    this.showSidePanel1 =false;
    this.loader1 = true;
    this.editflag = true
    this.cityid = city.id;
    this.cityForm.reset({ id: city.id, name: city.name, 'country': Number(city.country), 'state': Number(city.state)});
    this.showSidePanel = true;
    this.loader = false;
    this.getCountryList();
    this.getStateList();
    this.header = "Edit";
    this.addEditBtn = "Save";
    if(city.is_active == true){
      this.deleteClass = true;
      this.cityForm.enable();
      this.disabled = false;
    } else {
      this.header = "View";
      this.disabled = true;
      this.deleteClass = true;
      this.cityForm.disable();
    }
    this.submitted = false;
    // this.isSuccess = false;
    this.isError = false;
    this.action = "updated";
    this.CountrySelect = true;
    this.loader1 = false;
  }

  saveCity(){
    this.submitted  = true;
    if(this.deleteAlert == true)
      return;
    if (this.cityForm.invalid) {
      // this.isError = true;
      return;
    }
    this.disabled =true
    this.id = this.cityForm.value.id;
    if(this.id == 0){
      this.cityService.saveCity({ 'name': this.cityForm.value.name, 'country': this.cityForm.value.country, 'state' : this.cityForm.value.state, 'is_active' : true }).subscribe((res: any) => {
        this.offset = 0;
        this.cities = [];
        this.listCity();
        this.showSidePanel = false;
      },(err: any) => {this.disabled = false});
    }else{
      this.cityService.updateCity(this.id, { 'name': this.cityForm.value.name, 'country': this.cityForm.value.country, 'state' : this.cityForm.value.state, 'is_active' : true }).subscribe((res: any) => {
        this.cities = this.cities.filter((item) => item.id !== res.id);
        this.showSidePanel = false;
        this.offset = 0;
        this.cities = [];
        this.listCity();
      },(err: any) => {this.disabled = false});
    }


  }
  deletecityfn(id:any){
    this.cityForm.value.id = id;
    this.deleteAlert = true;
  }
  deleteCity(){
    this.deleteClicked = true
    this.action = "deleted";
    this.cityService.deleteCity(this.cityForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.cities = this.cities.filter((item) => item.id !== res.id);
      this.offset = 0;
      this.cities = [];
      this.listCity();
      this.showSidePanel = false;
      this.deleteAlert = false;
      this.deleteClass = false;
      this.submitted  = true;
      this.deleteClicked = false
    },
    (err: any) => {
      this.deleteAlert = false
      this.showSidePanel = false
      this.deleteClicked = false
    });
  }
  hideSidePanel(){
    this.submitted  = false;
    setTimeout(()=>{
      this.showSidePanel = false;
    }, 3000);
  }
  filterCity(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.cities = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  randomClass(min:number, max:number) {
    var ints = Math.floor(Math.random() * (max - min + 1)) + min;
    return "bg"+ints;
  }
  get f() { return this.cityForm.controls; }
  viewcity(id:any){

    if(this.permissions.v){
      this.showSidePanel1 = true;
      this.loader1 = true;
      this.cityService.getCity(id).subscribe((res: any) => {
        this.cityView = res;
        this.cityname = res.name;
        this.cityid = res.id;
        this.state_name = res.state_name;
        this.country_name = res.country_name;
        this.citystatus =res.is_active;
        this.loader1 = false;
      })
    }
  }

   // Simplified function to use for validation
   existService(){
    if(!this.editflag){
      this.value ={
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.cityid,
        "page":this.routeComponent,
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.cityUnit = res.body

      this.loader = false

      if(this.cityUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }


  // For validation of keyup(name)
  keyUp(){
    this.subjectKeyUp.next(this.f.name.value)
  }

  filterDone(val:any){
    this.status = val.stat;
    this.countryval = val.country;
    this.stateval= val.state;
    this.offset = 0
    this.listCity()
    this.filterpanel = false;
  }

  panel(value:any){
    this.filterpanel = value;
  }

  resetFilter(){
    this.status = true;
    this.countryval = ''
    this.stateval = ''
    this.listCity()
  }
}
