<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="remoteLocationForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
                <div class="row row-16 mb-16">
                  <div class="col-12 form-row">
                      <p class="text-uppercase required fw-500 mb-8">Attendance Date</p>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="attendance_date" type="radio" id="attendance_date1" class="form-check-input  "
                              formControlName="attendance_date" value="yesterday"
                              (change)="radioBtnChangeFunctions($event,'attendance_date','yesterday','sm_specific_month','monthlyTDSForm')">
                          <label for="attendance_date1" class="form-check-label">Yesterday</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="attendance_date" type="radio" id="attendance_date2" class="form-check-input "
                              formControlName="attendance_date" value="today"
                              (change)="radioBtnChangeFunctions($event,'attendance_date','today','sm_specific_month','monthlyTDSForm')">
                          <label for="attendance_date2" class="form-check-label">Today</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="attendance_date" type="radio" id="attendance_date3" class="form-check-input "
                              formControlName="attendance_date" [value]="true"
                              (change)="radioBtnChangeFunctions($event,'attendance_date',true,'sm_specific_month','monthlyTDSForm')">
                          <label for="attendance_date3" class="form-check-label">Specific date</label>
                      </div>
                  </div>
                  <ng-container *ngIf="remoteLocationForm.value.attendance_date==true">
                      <div class="col-12 form-row" [ngClass]="{ 'is-invalid': (submitted || f.sm_specific_month.touched || f.sm_specific_month.dirty) &&  f.sm_specific_month.errors}">
                          <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.sm_specific_month.touched || f.sm_specific_month.dirty) &&  f.sm_specific_month.errors}">
                              <input (click)="datePicker1.open()" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.sm_specific_month.touched || f.sm_specific_month.dirty) &&  f.sm_specific_month.errors}"
                                  placeholder="{{this.messageService.selectplaceholddisplay('Date')}}" matInput
                                  formControlName="sm_specific_month" [matDatepicker]="datePicker1"
                                  [matDatepickerFilter]="salFilter">
                              <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                              <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                          </div>
                          <div *ngIf="(submitted || f.sm_specific_month.touched || f.sm_specific_month.dirty) && f.sm_specific_month.errors" class="invalid-feedback">
                            <div *ngIf="f.sm_specific_month.errors.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                        </div>
                      </div>
                  </ng-container>
                  <app-ou-filters #childOuFilter [ouFilterForm]="ouFilterForm" [reportingDD]="reportingDD" [viewDetail]="viewDetail" [from]="'location'"></app-ou-filters>
                </div>
            </div>
          </div>
          <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage('reset');resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage('apply');submitRemotePunches()">
                Apply
            </button>
        </footer>
        </form>
      </div>
