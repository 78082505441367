<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16 mb-16">
            <div class="col-12 d-flex">
                <p class="text-uppercase fw-500 mb-0">FINAL SETTLEMENT</p>
            </div>
            <div class="col-6">
                <label class="radio-group" for="btnradio1">
                    <input type="radio" class="radio-check" name="btnradio" id="btnradio1" autocomplete="off" checked
                        (change)="recoveryChangeFunction($event)">
                    <div class="radio-content details-card card p-16">
                        <div class="row row-24 ">
                            <div class="col-12 ">
                                <span class="title">Notice pay / recovery</span>
                            </div>

                            <div class="col-12" *ngIf="!errnoticeCompmsg && !errBothCompmsg">
                                <div
                                    class="fw-500 fs-10 text-uppercase {{noticePayAndClearenceData?.notice_id == null ? 'text-warning' : 'text-success'}}">
                                    {{noticePayAndClearenceData?.notice_id == null ? 'PENDING' : 'Reviewed'}} </div>
                            </div>
                            <div class="col-12 " *ngIf="errnoticeCompmsg || errBothCompmsg">
                                <div  class="text-danger fs-10">THIS COMPONENT IS NOT APPLICABLE</div>
                            </div>
                        </div>

                    </div>
                </label>
            </div>
            <div class="col-6">
                <label class="radio-group" for="btnradio2">
                    <input type="radio" class="radio-check" name="btnradio" id="btnradio2" autocomplete="off"
                        (change)="recoveryChangeFunction($event)">
                    <div class="radio-content details-card card p-16">
                        <div class="row row-24 ">
                            <div class="col-12 ">
                                <span class="title">CLEARANCE recovery</span>
                            </div>
                            <div class="col-12" *ngIf="!errclearenceCompmsg && !errBothCompmsg">
                                <div
                                    class="fw-500 fs-10 text-uppercase {{noticePayAndClearenceData?.clearance_id == null ? 'text-warning' : 'text-success'}} ">
                                    {{noticePayAndClearenceData?.clearance_id == null ? 'PENDING' : 'Reviewed'}}</div>
                            </div>
                            <div class="col-12 " *ngIf="errclearenceCompmsg || errBothCompmsg">
                                <div  class="text-danger fs-10">THIS COMPONENT IS NOT APPLICABLE</div>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
            <ng-container *ngIf="!errnoticeCompmsg && !errBothCompmsg">
                <ng-container *ngIf="recoveryName == 'notice'">
                    <div class="col-sm-12 form-row">
                        <div class="card card-c2 details-card p-24">
                            <div class="row row-16">
                                <div class="col-12 d-flex">
                                    <div class="title">DETAILs</div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="detail-title  ">{{formGp.value.notice_paytype == 'Notice pay' ? 'Excess notice days' : 'Notice short fall days'}}</div>
                                    <div class="detail-desc ">{{noticePayAndClearenceData?.no_of_days}} days</div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="detail-title ">{{formGp.value.notice_paytype == 'Notice pay' ? 'Notice pay amount' : 'Notice recovery amount'}}</div>
                                    <div class="detail-desc ">
                                        {{ noticePayAndClearenceData?.notice_id == null ? (noticePayAndClearenceData?.amount | currency :
                                          noticePayAndClearenceData?.currency) : (noticePayAndClearenceData?.payable_amount | currency :
                                        noticePayAndClearenceData?.currency)}}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-lg-6 form-row">
                        <label for="" class="form-label required">Notice pay / Recovery </label>

                        <app-master-select
                            [ngClass]="{ 'is-invalid': (submitted || f.notice_paytype.dirty || f.notice_paytype.touched) && f.notice_paytype.errors || f.notice_paytype.errors?.exist}"
                            [placeholder]="'Select notice pay / recovery'" id="notice_paytype" [submitted]="submitted"
                            [isrequired]="true" [selectedItem]="f.notice_paytype.value" formControlName="notice_paytype"
                            [type]="'notice_pay_recovery'"
                            (ngModelChange)="onNgModelChange(formGp.value.notice_paytype)" ></app-master-select>

                        <div *ngIf="(submitted || f.notice_paytype.dirty || f.notice_paytype.touched) && f.notice_paytype.errors || f.notice_paytype.errors?.exist"
                            class="invalid-feedback">
                            <div *ngIf="f.notice_paytype.errors?.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.notice_paytype.errors?.pattern">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                        </div>
                    </div>
                    <div class=" col-lg-6 form-row">

                        <label for="" class="form-label required">{{formGp.value.notice_paytype == 'Notice pay' ? 'Updated excess notice days' : 'Updated notice short fall day' }}</label>
                        <div class="form-icon icon-right"
                            [ngClass]="{ 'is-invalid': (submitted || f.no_of_days_edited.dirty || f.no_of_days_edited.touched) && f.no_of_days_edited.errors}">
                            <input type="text" class="form-control "
                                placeholder="{{this.messageService.placeholderdisp('notice days')}}"
                                formControlName="no_of_days_edited"
                                [ngClass]="{ 'is-invalid': (submitted || f.no_of_days_edited.dirty || f.no_of_days_edited.touched) && f.no_of_days_edited.errors}">
                            <span>days</span>
                        </div>
                        <div *ngIf="(submitted || f.no_of_days_edited.dirty || f.no_of_days_edited.touched) && f.no_of_days_edited.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.no_of_days_edited.errors?.required">{{this.messageService.validationDisplay('required')}}
                            </div>
                            <div *ngIf="f.no_of_days_edited.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div
                            *ngIf="(!f.no_of_days_edited.errors?.pattern) && f.no_of_days_edited.errors?.maxlength">
                            {{this.messageService.fieldlengthvalidation('num','5')}}</div>
                        </div>
                    </div>
                    <div class=" col-lg-6 form-row">

                        <label for="" class="form-label required">{{formGp.value.notice_paytype == 'Notice pay' ? 'Updated notice pay amount' : 'Updated notice recovery amount' }} </label>
                        <div class="form-icon icon-right"
                            [ngClass]="{ 'is-invalid': (submitted || f.total_amount.dirty || f.total_amount.touched) && f.total_amount.errors}">
                            <input type="text" class="form-control "
                                placeholder="{{this.messageService.placeholderdisp('notice amount')}}"
                                formControlName="total_amount"
                                [ngClass]="{ 'is-invalid': (submitted || f.total_amount.dirty || f.total_amount.touched) && f.total_amount.errors}">
                            <span>{{noticePayAndClearenceData?.currency}}</span>
                        </div>
                        <div *ngIf="(submitted || f.total_amount.dirty || f.total_amount.touched) && f.total_amount.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.total_amount.errors?.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.total_amount.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                            </div>
                            <div
                            *ngIf="(!f.total_amount.errors?.pattern) && f.total_amount.errors?.maxlength">
                            {{this.messageService.fieldlengthvalidation('num','10')}}</div>
                        </div>
                    </div>
                    <div class="col-12 d-flex">
                        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveClicked"
                            (click)="saveNoticeClearence()">SAVE</button>
                    </div>
                </ng-container>

            </ng-container>
            <ng-container *ngIf="!errclearenceCompmsg && !errBothCompmsg">
                <ng-container *ngIf="recoveryName == 'clearence'">
                <div class="col-sm-12 form-row">
                    <div class="card card-c2 details-card p-24">
                        <div class="row row-16">
                            <div class="col-12 d-flex">
                                <div class="title">DETAILS</div>
                            </div>
                            <div class="col-sm-6">
                                <div class="detail-title  ">Clearance pay component</div>
                                <div class="detail-desc ">{{noticePayAndClearenceData?.clearance_component}}</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class=" col-12 form-row">

                    <label for="" class="form-label required">Final amount for clearance recovery</label>
                    <div class="form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || f.clearance_amount.dirty || f.clearance_amount.touched) && f.clearance_amount.errors}">
                        <input type="text" class="form-control "
                            placeholder="{{this.messageService.placeholderdisp('Final amount for clearance recovery')}}"
                            formControlName="clearance_amount"
                            [ngClass]="{ 'is-invalid': (submitted || f.clearance_amount.dirty || f.clearance_amount.touched) && f.clearance_amount.errors}">
                        <span>{{noticePayAndClearenceData?.currency}}</span>
                    </div>
                    <div *ngIf="(submitted || f.clearance_amount.dirty || f.clearance_amount.touched) && f.clearance_amount.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.clearance_amount.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="f.clearance_amount.errors?.pattern">
                            {{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="(!f.clearance_amount.errors?.pattern) && f.clearance_amount.errors?.maxlength">
                            {{this.messageService.fieldlengthvalidation('num','6')}}</div>
                    </div>
                </div>
                <div class=" col-12">
                    <label for="" class="form-label ">Comments </label>
                    <textarea class="form-control" formControlName="clearance_comments" rows="4"
                        placeholder="Write comments here..."
                        [ngClass]="{ 'is-invalid': (f.clearance_comments.dirty || f.clearance_comments.touched) && (f.clearance_comments.errors) }"></textarea>

                    <div *ngIf="(f.clearance_comments.dirty || f.clearance_comments.touched) && f.clearance_comments.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.clearance_comments.errors?.maxlength">
                            {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                    </div>

                </div>
                <div class="col-12 d-flex">
                    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid "
                        (click)="saveNoticeClearence()">SAVE</button>
                </div>
            </ng-container>
        </ng-container>
        <div class="col-12"  *ngIf="(recoveryName == 'clearence' && (errBothCompmsg || errclearenceCompmsg)) || (recoveryName == 'notice' && (errBothCompmsg || errnoticeCompmsg)) ">
            <div class="flex-center gap-8 flex-column" style="height: calc(100vh - 24rem);">
                <img src="/assets/images/helper/helper3.png" alt="" srcset="">
                <p class="mb-0 fs-12">
                    This component is not applicable
                </p>
            </div>
        </div>
        </div>
    </div>

    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" (click)="submitRecovery()">NEXT</button>
    </footer>
</form>
