import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import { HolidayCalendarService } from 'src/app/@core/services/holiday-calendar.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-holiday-calender',
  templateUrl: './holiday-calender.component.html',
  styleUrls: ['./holiday-calender.component.scss']
})
export class HolidayCalenderComponent implements OnInit {
  searchKeyword = "";
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
  statusBtn: any = "All";
  @ViewChild(SearchbarComponent) child: any;
  status_filter: string = 'true';
  defaultlimit: any = 20;
  offset = 0;
  year: any;
  month: any = '03';
  listLength = 0;
  holiday_type: any = '';
  calendarHolidays: any = [];
  calendarMonthList: any;
  holidayCalDetailsList: any = [];
  holidays: any = [];
  holidaysearch: string = this.messageService.searchdisplay('Holiday');
  shift_exception: any;
  shift_exception_flag = false;
  holiday_exception_flag: any;
  holiday_exception: any;
  dayFlag = true;
  holidayFlag = false;
  monthLoader = true;
  listLoader = true;
  flag = 0;
  nodata  = false;
  sLoading = false;
  public prevChanged: any;
  public calendarOptions: CalendarOptions = this.initCalendar();
  constructor(
    private holidayCalenderService: HolidayCalendarService, public messageService: MessageService
  ) { }
  private initCalendar(): CalendarOptions {
    return {
      initialView: 'dayGridMonth',
      height: 'auto',
      stickyHeaderDates: false,
      headerToolbar: {
        start: 'prev',
        center: 'title',
        end: 'next'
      },
      events: [],
      eventContent: function (arg) {
        var dateString = moment(arg.event._instance?.range.start).format('YYYY-MM-DD');
        var dateStatus = arg.event.extendedProps.status;
        var dateName = arg.event.extendedProps.name;
        var dateTableCell = document.querySelector('[data-date ="' + dateString + '"]');
        console.log(dateTableCell);
        if (dateStatus == 'week-off') {
          dateTableCell?.classList.add('week-off');
          dateTableCell?.children[0].setAttribute("data-text", dateName);
        } else if (dateStatus == 'national-holiday') {
          dateTableCell?.classList.add('national-holiday', 'holiday-status');
          dateTableCell?.children[0].setAttribute("data-text", dateName);
        } else if (dateStatus == 'floating-holiday') {
          dateTableCell?.classList.add('floating-holiday', 'holiday-status');
          dateTableCell?.children[0].setAttribute("data-text", dateName);
        }

      },
    };
  }


  public ngOnInit(): void {
    this.year = moment().year();
    this.prevChanged = this.year;
    this.holidayCalendarMonthList(this.year);
    this.holidayCalendarList(this.year);
    setTimeout(() => {
      this.calendarOptions = {
        initialView: 'dayGridMonth',
        height: 'auto',
        stickyHeaderDates: false,
        headerToolbar: {
          start: 'prev',
          center: 'title',
          end: 'next'
        },
        events: this.calendarMonthList,
        eventContent: function (arg) {
          var dateString = moment(arg.event._instance?.range.start).format('YYYY-MM-DD');
          var dateStatus = arg.event.extendedProps.status;
          var dayName = arg.event.extendedProps.day_name != null ? ' (day ' + arg.event.extendedProps.day_name + ')' : '';
          var dateName = arg.event.extendedProps.name + dayName;
          var dateTableCell = document.querySelector('[data-date ="' + dateString + '"]');
          if (dateStatus == 'week-off') {
            dateTableCell?.classList.add('cell-status', 'status-weekoff');
            dateTableCell?.children[0].setAttribute("data-text", dateName);
          } else if (dateStatus == 'national-holiday') {
            dateTableCell?.classList.add('national-holiday', 'holiday-status');
            dateTableCell?.children[0].setAttribute("data-text", dateName);
          } else if (dateStatus == 'regular-holiday') {
            dateTableCell?.classList.add('regular-holiday', 'holiday-status');
            dateTableCell?.children[0].setAttribute("data-text", dateName);
          } else if (dateStatus == 'floating-holiday') {
            dateTableCell?.classList.add('floating-holiday', 'holiday-status');
            dateTableCell?.children[0].setAttribute("data-text", dateName);
          }
        },
      }
      this.monthLoader = false;
      this.listLoader = false;
      this.flag = 1;
    }, 2000);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (document.getElementsByClassName('fc-prev-button')[0] && document.getElementsByClassName('fc-next-button')[0]) {
        document.getElementsByClassName('fc-prev-button')[0].addEventListener('click', this.funcPrevNext.bind(this));
        document.getElementsByClassName('fc-next-button')[0].addEventListener('click', this.funcPrevNext.bind(this));
      }
    }, 2000);
    setTimeout(() => {
      this.CalenderListScroll()
      this.fixHeight()
      if (document.getElementsByClassName('fc-prev-button')[0] && document.getElementsByClassName('fc-next-button')[0]) {
        document.getElementsByClassName('fc-prev-button')[0].addEventListener('click', this.CalenderListScroll);
        document.getElementsByClassName('fc-next-button')[0].addEventListener('click', this.CalenderListScroll);
      }
    }, 2000);
  }

  public funcPrevNext() {
    let calendarApi = this.calendarComponent?.getApi().currentData;
    let yearChanged: any;
    let currentDate: any = calendarApi?.getCurrentData().currentDate;
    yearChanged = moment(currentDate, "DD/MM/YYYY").year();
    if (yearChanged != this.prevChanged) {
      this.year = yearChanged;
      this.holidayCalendarMonthList(yearChanged);
      this.holidayCalendarList(yearChanged);
      this.prevChanged = yearChanged;
    }
    this.monthLoader = false;
    this.listLoader = false;
  }

  listFilter(value: any) {
    this.holiday_type = value;
    this.holidayCalendarList(this.year);
    // this.child.clearBar(); Reimplement when leave policy and leave data models and apis are updated, SearchBarComponent not implemented at present
  }

  holidayCalendarList(year: any) {
    this.listLoader = true;
    this.nodata = false;
    this.holidayCalenderService.getHolidayCalendarList(year, this.holiday_type, this.searchKeyword).subscribe((res: any) => {
      if (res.status != 0) {
        if(res.data.length == 0){
          this.listLength = 0;
          this.nodata = true;
        }else{
          this.listLength = res.data.length;
          if (this.offset == 0) {
            this.calendarHolidays = [];
          }
          this.holidayCalDetailsList = res.data;
        }
        this.addItems(this.listLength);
        this.calendarHolidays = res.data;
      } else {
        this.listLength = 0;
        this.holidayCalDetailsList = [];
        this.addItems(this.listLength);
        this.calendarHolidays = [];
        this.nodata = true;
      }
      if (this.flag == 1) {
        this.listLoader = false;
        this.sLoading = false;
        this.CalenderListScroll();
        this.fixHeight();
      }

    })
    // this.holidayCalenderService.holidayTypeSearch(year, this.holiday_type).subscribe((res: any) => {
    //   for (let i = 0; i < res.length; i++) {
    //     if (res[i]['holiday_name'] != null)
    //       res[i]['name'] = res[i]['holiday_name'] + ' ' + (res[i]['day_name'] != null ? ('(day ' + res[i]['day_name'] + ')') : '');
    //   }
    //   this.holidays = res;
    // });
  }

  addItems(itemlist: any) {
    for (let i = 0; i < itemlist; ++i) {
      this.calendarHolidays.push(this.holidayCalDetailsList[i]);
    }
  }

  searchresults(data: any) {
    if (data == "") {
      this.searchKeyword = "";
      this.calendarHolidays = [];
      this.holidayCalendarList(this.year);
      this.dayFlag = true;
    }
    else {
      this.holidayCalenderService.getHolidayCalendarList(this.year, this.holiday_type, this.searchKeyword).subscribe((res: any) => {
        this.calendarHolidays = [];
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].holidays_list.length; j++) {
            if (res.data[i].holidays_list[j].id == data) {
              this.calendarHolidays.push({
                month: res.data[i].month,
                holidays_list: [
                  {
                    "fromdate": res.data[i].holidays_list[j].fromdate,
                    "day_name": res.data[i].holidays_list[j].day_name,
                    "id": res.data[i].holidays_list[j].id,
                    "holiday": res.data[i].holidays_list[j].holiday,
                    "national_holiday": res.data[i].holidays_list[j].national_holiday,
                    "floating": res.data[i].holidays_list[j].floating
                  }
                ]
              });
            }
          }
        }
        this.dayFlag = false;
      })
    }
  }

  public holidayCalendarMonthList(year: any) {
    this.monthLoader = true;
    this.holidayCalenderService.getHolidayCalendarMonthList(year).subscribe((res: any) => {
      if (res != undefined) {
        this.calendarMonthList = res.data;
        this.calendarComponent?.getApi().setOption('events', res.data);
        this.shift_exception = res.shift_exception;
        if (this.shift_exception == "" || this.shift_exception == null) {
          this.shift_exception_flag = false;
        }
        else {
          this.shift_exception_flag = true;
        }
        if (res.status == 0) {
          this.holiday_exception = res.holiday_exception;
          this.holiday_exception_flag = true;
        }
        else {
          this.holiday_exception_flag = false;
        }
      }
    })
  }

  getWeekDay(date: any) {
    const d = moment(date).day();
    if (d == 0)
      return 'Sun';
    else if (d == 1)
      return 'Mon';
    else if (d == 2)
      return 'Tue';
    else if (d == 3)
      return 'Wed';
    else if (d == 4)
      return 'Thu';
    else if (d == 5)
      return 'Fri';
    else if (d == 6)
      return 'Sat';
    else
      return null;
  }
  getDay(date: any) {
    const d = moment(date, 'YYYY/MM/DD');
    return d.format('D');
  }
  goToDate(date: any) {
    this.calendarComponent?.getApi()?.gotoDate(date);
    var dateTableCell = document.querySelector('[data-date ="' + date + '"]') as HTMLElement | null;
    dateTableCell?.classList.add('selected-day');
    setTimeout(() => {
      dateTableCell?.classList.remove('selected-day');
    }, 5000);
  }
  public CalenderListScroll() {
    var currentGridMonthName = document.getElementById('fc-dom-1')?.textContent
    let calendarListDiv = document.getElementsByClassName('calendar-list')[0]
    var allListMonth = document.getElementsByClassName('holiday-title') as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < allListMonth.length; i++) {
      if (allListMonth[i].childNodes[0].textContent == currentGridMonthName) {
        calendarListDiv.scrollTop = allListMonth[i].offsetTop - 24;
      }
    }
  }
  public fixHeight() {
    var gridHeight = document.getElementsByClassName('calendar-grid') as HTMLCollectionOf<HTMLElement>
    var listHeight = document.getElementsByClassName('calendar-list') as HTMLCollectionOf<HTMLElement>
    if (listHeight[0] != undefined && listHeight[0] != null)
      listHeight[0].style.maxHeight = gridHeight[0]?.offsetHeight + "px";
  }
  searchday(searchKeyword: any) {
    // this.loader = true;
    this.sLoading = true;
    this.offset = 0;
    this.calendarHolidays = [];
    this.searchKeyword = searchKeyword;
    if (searchKeyword != "") {
      this.holidayCalendarList(this.year);
    }
  }
}

