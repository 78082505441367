
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApprovalMatrixService {
  return = [];
  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  ApprovalMatrixList(isactive:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/approval-matrix-list/?is_active=${isactive}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }
  ApprovalMatrixDDList(isactive:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/approval-matrix-list/?is_active=${isactive}&ordering=name`, this.httpOption)
  }
  roleList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/role-dropdown-list/?is_active=true&ordering=role_name&self=true`, this.httpOption)
  }
  moduleList(){
    return this.http.get(`${this.API_PATH}organization/api/v1/module/?is_active=true&ordering=name`, this.httpOption);
  }
  applicableList(type:string){
    if(type == "BU"){
      return  this.http.get(`${this.API_PATH}organization/api/v1/bu-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    } else if(type == "Department")
      return  this.http.get(`${this.API_PATH}organization/api/v1/department-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Designation")
      return  this.http.get(`${this.API_PATH}organization/api/v1/designation-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Grade")
      return  this.http.get(`${this.API_PATH}organization/api/v1/grade-list-dropdown/?is_active=true&ordering=name`, this.httpOption);
    else if(type == "Company")
      return  this.http.get(`${this.API_PATH}organization/api/v1/company-list-dropdown/?is_active=true&ordering=company_name`, this.httpOption);
    else if(type == "Employee")
      return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name`, this.httpOption);
    else if(type == "Branch")
      return  this.http.get(`${this.API_PATH}organization/api/v1/branch-list-dropdown/?is_active=true&&ordering=name`, this.httpOption);
    else if(type == "City")
      return  this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?ordering=name&is_active=true`, this.httpOption);
    else
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list-all/?is_active=true&ordering=user__first_name`, this.httpOption);
  }

  workflowList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/request-type-dropdown/?is_active=true&ordering=name`, this.httpOption)
  }
  workflowListperModule(id:number,is_cancel:boolean) {
    if(is_cancel)
      return this.http.get(`${this.API_PATH}organization/api/v1/module-request-type-dropdown/?module=${id}&is_active=true&ordering=name&request_type__is_cancel=${is_cancel}`, this.httpOption)
    else
      return this.http.get(`${this.API_PATH}organization/api/v1/module-request-type-dropdown/?module=${id}&is_active=true&ordering=name`, this.httpOption)
  }

  saveApproval(body: any){
    return this.http.post(`${this.API_PATH}organization/api/v1/approval-matrix-create/`, body, this.httpOption)
  }

  getApproval(id: number){
    return this.http.get(`${this.API_PATH}organization/api/v1/approval-matrix-detail/${id}/`,  this.httpOption)
  }

  updateApproval(body: any,id:number){
    return this.http.put(`${this.API_PATH}organization/api/v1/approval-matrix-detail/${id}/`, body, this.httpOption)
  }

  deleteApproval(id:number,body: any,){
    return this.http.patch(`${this.API_PATH}organization/api/v1/approval-matrix-delete/${id}/`, body, this.httpOption)
  }
  getApprovalview(id:any){
    return this.http.get(`${this.API_PATH}organization/api/v1/approval-matrix-view/${id}/`,  this.httpOption)
  }
}
