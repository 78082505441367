<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="Employeedetails" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>

                <div class="row row-16 mb-16">
                    <div class="col-12 form-row ">
                        <label for="" class="form-label">Reporting manager</label>
                        <ng-select [readonly]="disabled"
                            placeholder="{{this.messageService.selectplaceholddisplay('Reporting manager')}}"
                            formControlName="reporting_manager" [items]="employeeDropdown" [multiple]="true"
                            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="empLoader">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> Select All
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!empLoader">
                                <div class="ng-value" *ngIf="Employeedetails.value.reporting_manager?.length">
                                    <span class="ng-value-label">{{Employeedetails.value.reporting_manager?.length}}
                                        reporting manager
                                        selected.</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                                        class="avatar-sm sq-24 img-fluid rounded-circle"
                                        *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                        src="{{item.profile_image}}">
                                    <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                        class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>
                                    <span class="mt-1 ms-1">{{ (item.name?.length>15)? (item.name |
                                        slice:0:30)+'..':(item.name) }}</span>
                                </div>
                            </ng-template>
                            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="empLoader">
                              <div class="loading-text">Loading...</div>
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values mt-16"
                            *ngIf="Employeedetails.value.reporting_manager?.length !=0 && !empLoader">
                            <li class="ng-value"
                                *ngFor="let item of Employeedetails.value.reporting_manager, let m =index">
                                <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                        <div class="d-inline-flex">
                                            <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                                *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                                src="{{insideitem.profile_image}}">
                                            <span
                                                *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                                class="avatar-sm sq-32 rounded-circle bg{{k%5}}">
                                                <span>
                                                    {{this.appservice.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                                            </span>
                                            <span class="mt-2 ms-1">
                                                {{insideitem.first_name+"
                                                "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                                ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                            </span>
                                        </div>
                                    </span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true"
                                    (click)="clearcommonForm('employeerm',m)"><i class="icon-close"></i></span>
                            </li>
                        </ul>
                    </div>

                    <div class="col-12 form-row ">
                        <label for="" class="form-label">Functional manager</label>
                        <ng-select [readonly]="disabled"
                            placeholder="{{this.messageService.selectplaceholddisplay('Functional manager')}}"
                            formControlName="functional_manager" [items]="employeeDropdown" [multiple]="true"
                            groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [loading]="empLoader">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> Select All
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!empLoader">
                                <div class="ng-value" *ngIf="Employeedetails.value.functional_manager?.length">
                                    <span
                                        class="ng-value-label">{{Employeedetails.value.functional_manager?.length}}
                                        functional manager
                                        selected.</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                                        [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                                        class="avatar-sm sq-24 img-fluid rounded-circle"
                                        *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                        src="{{item.profile_image}}">
                                    <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                        class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appservice.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>
                                    <span class="mt-1 ms-1">{{ (item.name?.length>15)? (item.name |
                                        slice:0:30)+'..':(item.name) }}</span>
                                </div>
                            </ng-template>
                            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="empLoader">
                              <div class="loading-text">Loading...</div>
                            </ng-template>
                        </ng-select>
                        <ul class="selected-values mt-16"
                            *ngIf="Employeedetails.value.functional_manager?.length !=0 && !empLoader">
                            <li class="ng-value"
                                *ngFor="let item of Employeedetails.value.functional_manager, let m =index">
                                <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                        <div class="d-inline-flex">
                                            <img class="avatar-sm sq-32 img-fluid rounded-circle"
                                                *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                                src="{{insideitem.profile_image}}">
                                            <span
                                                *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                                class="avatar-sm sq-32 rounded-circle bg{{k%5}}">
                                                <span>
                                                    {{this.appservice.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                                            </span>
                                            <span class="mt-2 ms-1">
                                                {{insideitem.first_name+"
                                                "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                                                ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                            </span>
                                        </div>
                                    </span>
                                </ng-container>
                                <span class="ng-value-icon right" aria-hidden="true"
                                    (click)="clearcommonForm('employeefm',m)"><i class="icon-close"></i></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- New end -->
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm"
                (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                (click)="resetPage();submitEmployeeDetails()">
                Apply
            </button>
        </footer>
    </form>
</div>
