import { Component, OnInit } from '@angular/core';
import { Input,Output,EventEmitter} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-publish-form16',
  templateUrl: './publish-form16.component.html',
  styleUrls: ['./publish-form16.component.scss']
})
export class PublishForm16Component implements OnInit {
  page              = 1;
  pageSize          = 20;
  searchString:any  = "";
  downloadMsg       = false;
  modalToggle       = false;
  loader1 : boolean = false
  currentExtension  = "pdf";
  currentDocName    = 'Form 16'
  saveClick         = false

  fileName:any;


  @Input() publishedData:any;
  @Input() loader:any;
  @Output() publishSaveForm   = new EventEmitter();

  constructor(public messageService : MessageService,public appservice:AppService) {

  }
  ngOnInit(): void {
    for(let i=0;i< this.publishedData.length;i++){

      this.publishedData[i]['name']  = "";
      if(this.publishedData[i]['first_name'] != null && this.publishedData[i]['first_name'] != undefined && this.publishedData[i]['first_name'] != "")
      this.publishedData[i]['name'] += this.publishedData[i]['first_name']+" ";
      if(this.publishedData[i]['middle_name'] != null && this.publishedData[i]['middle_name'] !=undefined &&  this.publishedData[i]['middle_name'] != "")
      this.publishedData[i]['name'] += this.publishedData[i]['middle_name']+" ";
      if(this.publishedData[i]['last_name'] != null &&  this.publishedData[i]['last_name'] != undefined && this.publishedData[i]['last_name'] != "")
      this.publishedData[i]['name'] += this.publishedData[i]['last_name'];


    }
  }
  viewDocument(filename:any){
    this.modalToggle      = true;
    this.fileName         = filename;

  }
  // downloadDoc(filename:any,docType:any){
  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(filename,docType+'.pdf');
  // }
  goNext(tab:number){
    this.saveClick = true

    this.publishSaveForm.emit();


  }


   // Loader
   showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

}
