import { Component, OnInit, ElementRef, ViewChildren, QueryList, AfterViewInit,ViewChild } from '@angular/core';
import { MyTeamAttendanceService } from 'src/app/@core/services/myteam-attendance.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FormBuilder } from '@angular/forms';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay,switchMap  } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-my-team-attendance',
  templateUrl: './my-team-attendance.component.html',
  styleUrls: ['./my-team-attendance.component.scss']
})
export class MyTeamAttendanceComponent implements OnInit {
  @ViewChildren('dateColumn', { read: ElementRef }) dateColumns!: QueryList<ElementRef>;
  @ViewChild(SearchbarComponent) child:any;
  searchData : Subject<void> = new Subject();
  empId: any = 0;
  activeTab = 1;
  notInYet = false;
  onLeaveToday = false;
  myTeamData: any = [];
  myTeamattendanceData: any = [];
  leaveListOfMonth: string = "listOfMonth";
  attendListOfMonth: string = "listOfMonth";
  leaveCurrentMonth: any = new Date().toLocaleString('default', { month: 'long' });
  leaveCurrentYear: any = new Date().getFullYear()
  limit = 20;
  offset = 0;
  page = 1;
  searchKeyword = "";
  myTeamLoader = false;
  timeOffLoader = false
  fromDate: any
  activeEmployee: any;
  activeYear: any;
  activeMonth: any;
  datePickDay: any = this.appservice.convertToDate('from', new Date());
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  defaultClass: any = {
    'Present': 'present',
    'Absent': 'absent',
    'Leave': 'leave',
    'Weekoff': 'weekoff',
    'Holiday': 'holiday'
  }
  displayedColumns: any = ['Employee']
  searchPlaceholder = this.messageService.searchdisplay('Employee');
  searchListData: any = []
  searchString: any
  lastPage: any
  timeoffLoader = false
  filterForm = this.fb.group({
    company: '',
    bu: '',
    branch: '',
    department: '',
    designation: '',
    grade: '',
    emp_status: '',
    emp_type: '',
    repomanager: '',
    funmanager: '',
    sortby: 'All',
  })

  filter = false;
  from = 'Employee';
  listYears: any = this.appservice.generateArrayOfYears();
  showSidePanel = false;
  permissions: any;
  constructor(
    public appservice: AppService,
    public messageService: MessageService,
    private MyTeamAttendanceService: MyTeamAttendanceService,
    public router: Router, public fb: FormBuilder,public datePipe:DatePipe
  ) { }
    res:any
  ngOnInit(): void {
    this.getPermission();
    this.fromDate = moment(this.datePickDay).startOf('week').format('YYYY-MM-DD')
    this.activeYear = (new Date()).getFullYear()
    this.activeMonth = this.months[(new Date()).getMonth()]
    this.getMyTeam();
    this.getattendancedata();
    this.getList();
    this.myTeamLoader = true;
    this.timeoffLoader = true;
    this.searchData.pipe(delay(1000),
   switchMap(() =>  this.MyTeamAttendanceService.getMyTeamAttendanceData(this.activeMonth,this.activeYear,this.limit,this.offset,this.searchKeyword,this.filterdata,this.empId)
  )).subscribe((result: any) => {
    this.myTeamattendanceData = result?.data;
        this.timeOffLoader = false;
        this.lastPage = result.total_employees
        this.arrangeData()
  });
  }
  arrangeData(){

    if (this.myTeamattendanceData?.length == 0)
        this.getNodataHeader()

      for (let i = 0; i < this.myTeamattendanceData?.length; i++) {

        for (let j = 0; j < this.myTeamattendanceData[i].attendance_detail.length; j++) {
          let atendanceData = this.myTeamattendanceData[i].attendance_detail[j];
          if (atendanceData?.status_firstHalf != null && atendanceData?.status_secondHalf != null) {

            if (atendanceData?.status_firstHalf == atendanceData?.status_secondHalf) {
              this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] = "status-" + this.defaultClass[atendanceData?.status_firstHalf];
            }
            else {
              this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] = "status-" + this.defaultClass[atendanceData?.status_firstHalf] + "-" + this.defaultClass[atendanceData?.status_secondHalf];

            }
          }
          else {
            //this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] = 'status-absent';
          }
          var today:any =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
          if(atendanceData?.date>today)
            this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] = '';
          if(atendanceData.onduty_status)
            this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] ="status-onduty"
          else if(atendanceData.wfh_status)
            this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] ="status-wfh"
          else if(atendanceData. leave_status && atendanceData?.date>today)
            this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] ="status-leave"
          else if(atendanceData.holiday)
            this.myTeamattendanceData[i].attendance_detail[j]['attendance_class'] ="status-holiday"
        }
      }
  }

  scrollToCurrentDayColumn() {
    const currentDayIndex = this.getCurrentDayIndex(); // Replace this with your logic to get the index of the current day
    // console.log(currentDayIndex)
    if (currentDayIndex !== -1 && this.dateColumns) {
      const currentDayColumn = this.dateColumns.toArray()[currentDayIndex];
      if (currentDayColumn) {
        currentDayColumn.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }
  }
  getCurrentDayIndex(): number {
    // Find the index of the th element with the class "current-day"
    const dateColumnElements = this.dateColumns.toArray();
    // console.log(this.dateColumns)
    for (let i = 0; i < dateColumnElements.length; i++) {
      const columnElement = dateColumnElements[i];
      if (columnElement.nativeElement.classList.contains('current-day')) {
        return i; // Found the index of the current day column
      }
    }
    return -1; // Return -1 if the current day column is not found
  }
  getCurrentDay(): number {
    const currentDate = new Date();
    return currentDate.getDate();
  }
  onPopoverClick(event: Event): void {
    // Prevent the click event from propagating to parent elements
    event.stopPropagation();
  }
  getMyTeam() {

    this.MyTeamAttendanceService.getMyTeam(this.leaveCurrentMonth, this.leaveCurrentYear).subscribe((res: any) => {
      if (res) {
        this.myTeamData = res?.data;
        this.myTeamLoader = false;
        this.timeoffLoader = false
      }
    })
  }
  getattendancedata() {
    this.timeOffLoader = true;
    this.MyTeamAttendanceService.getMyTeamAttendanceData(this.activeMonth, this.activeYear, this.limit, this.offset, this.searchKeyword, this.filterdata).subscribe((res: any) => {
      this.myTeamattendanceData = res?.data;

      this.lastPage = res.total_employees
      this.arrangeData()
      this.timeOffLoader = false;
        setTimeout(() => {
          this.scrollToCurrentDayColumn();
        }, 100);
    })
  }
  getNodataHeader() {

    let data = this.appservice.getDaysArray(this.activeYear, this.activeMonth)
    this.displayedColumns = []
    data.forEach((item: any) => {
      this.displayedColumns.push(item)
    })


  }
  getPageFromService(val: any) {
    this.offset = (val - 1) * this.limit
    this.getattendancedata()
  }
  monthChangeFunction() {
    this.timeoffLoader = true
    this.getMyTeam();
  }

  singleEmployeeview(id: any) {
    // this.router.navigateByUrl("/employee-attendance-view/"+id+"/"+this.activeYear+"/"+this.activeMonth)
    this.router.navigateByUrl("/my-team-attendance/employee-attendance-view/" + id + "/" + this.activeYear + "/" + this.activeMonth)

  }
  getList() {
    this.MyTeamAttendanceService.empDDList(true, 'user__first_name').subscribe((res: any) => {
      this.arrangeSearchData(res?.data);
    });
  }
  arrangeSearchData(res: any) {
    for (let i = 0; i < res.length; i++) {
      if (res[i]['first_name'] != null)
        res[i]['name'] = res[i]['first_name'] + " ";
      if (res[i]['middle_name'] != null)
        res[i]['name'] += res[i]['middle_name'] + " ";
      if (res[i]['last_name'] != null)
        res[i]['name'] += res[i]['last_name'];
      res[i]['name'] += " (" + res[i]['employee_code'] + ")";
      res[i]['image'] = res[i]['profile_image'];
    }
    this.searchListData = res;
  }
  searchResults(data: any) {
    this.timeOffLoader = true;
    if (data == '') {
      this.searchKeyword = ""
      this.offset = 0
      this.page = 1
      this.myTeamattendanceData = []
      this.empId  = 0
      // this.getattendancedata();
      this.searchData.next()
    } else {

      this.offset = 0
      this.page = 1
      this.empId = data
      this.searchData.next()
      // this.MyTeamAttendanceService.getSearchedData(this.activeMonth, this.activeYear, this.empId).subscribe((res: any) => {
      //   this.myTeamattendanceData = res.data
      //   this.searchString = res.data[0].name
      //   // this.loader         = false
      // })
    }
  }
  searchEmp(keyWord: any) {
    this.timeOffLoader = true;
    this.page =1;
    this.offset = 0
    this.empId  = 0
    this.myTeamattendanceData = []
    this.searchKeyword = keyWord
    if (this.searchKeyword != "") {
      // this.getattendancedata();
      this.searchData.next()
    }
  }

  filterdata: any = 'ordering=user__first_name'
  // Close Panel
  closefn(daata: any) {
    this.filter = daata;
  }
  filterclick(){
    if (this.searchKeyword != "" && (this.empId != 0 || this.empId != ""))
      this.child.clearBar()
    this.filter = true;
   }

  resultfilterfn(data: any) {
    this.filterdata = data
    this.offset = 0
    this.page = 1
    this.getattendancedata()
    this.searchData.next()
  }

  closePanelNot(val: any) {
    this.notInYet = val
  }

  closePanelLeave(val: any) {
    this.onLeaveToday = val
  }
  getDaysInaMonth(year: any, month: any) {
    var monthIndex = month - 1; // 0..11 instead of 1..12
    var names = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    var date = new Date(year, monthIndex, 1);
    var result = [];
    while (date.getMonth() == monthIndex) {
      result.push(date.getDate() + '-' + names[date.getDay()]);
      date.setDate(date.getDate() + 1);
    }
    return result;
  }
  closesidePanel(val:any){
    this.showSidePanel = val
  } 
  uploadeddata(event:any){
    this.showSidePanel = false;
    this.getattendancedata();

  }
  getPermission(){
    this.permissions = this.appservice.getPermissions('/my-team-attendance');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
