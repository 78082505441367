import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function NamefieldValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const namefieldPattern = /^(?! )+[A-Za-z ]*[A-Za-z]$/.test(value);

      return !namefieldPattern ? {pattern:true}: null;
  }
}

