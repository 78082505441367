<form class="" [formGroup]="formGrp" autoComplete="off">
  <div class="container-fluid p-32 form-container">
    <div class="row ">
      <p class="text-uppercase fw-500 mb-16">Edit details
        <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
            ngbPopover="Select the check in and check out times for the selected date"
            popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
      </p>
    </div>
    <div class="row row-16" formArrayName="dutyRequest">
      <div class=" col-12 form-row" *ngFor="let fm of assignForm().controls; let i = index">
        <div class="card card-c2 " [formGroupName]="i">
          <div class="card-body pt-16 px-16 pb-24">
            <p class="fs-14 fw-600">
              {{this.appServ.dateFormatDisplay(assignForm().controls[i].get('onduty_date')?.value)}}<i
                class="icon-trash float-end pointer" (click)="removeDetail(i)"></i></p>
            <hr class="hr-1 mb-16">
            <div class="row row-16">
              <div class="col-12">
                <div class="card details-card p-16">
                  <div class="title">CHECK-IN</div>
                  <div class="row row-16 mt-1">
                    <div class=" col-md-6 form-row">
                      <label for="checkin_date" class="form-label required">Check-in date</label>
                      <div class="form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('checkin_date')?.dirty || assignForm().controls[i].get('checkin_date')?.touched) &&  (assignForm().controls[i].get('checkin_date')?.errors || (assignForm().controls[i].errors?.checkin))}">
                        <input (click)="datePicker1.open()" id="checkin_date" formControlName="checkin_date"
                          (keydown)="disableDate()"
                          (dateInput)="!makeOutNull(i) && assignForm().controls[i].get('checkin_time')?.value != '' && assignForm().controls[i].get('checkout_time')?.value != '' && getDiff(i);isBeforeDates(i);checkValidation(i,'checkin_time');checkinShiftWindow(i)"
                          placeholder="{{this.msgServ.selectplaceholddisplay('date')}}" class="form-control"
                          [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('checkin_date')?.dirty || assignForm().controls[i].get('checkin_date')?.touched) &&  (assignForm().controls[i].get('checkin_date')?.errors || (assignForm().controls[i].errors?.checkin))}"
                          matInput [matDatepicker]="datePicker1"><i class="icon-calendar pointer"
                          (click)="datePicker1.open()"></i>
                        <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
                      </div>
                      <div
                        *ngIf="(submitted || assignForm().controls[i].get('checkin_date')?.dirty || assignForm().controls[i].get('checkin_date')?.touched) &&  (assignForm().controls[i].get('checkin_date')?.errors ||  (assignForm().controls[i].errors?.checkin))"
                        class="invalid-feedback">
                        <div *ngIf="assignForm().controls[i].get('checkin_date')?.errors?.required">
                          {{this.msgServ.validationDisplay('required')}}</div>

                        <div *ngIf="assignForm().controls[i].errors?.checkin">Please select a valid date
                        </div>
                      </div>
                    </div>
                    <div class=" col-md-6 form-row">
                      <label for="" class="form-label required">Check-in time</label>
                      <div class="form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('checkin_time')?.dirty || assignForm().controls[i].get('checkin_time')?.touched) &&  assignForm().controls[i].get('checkin_time')?.errors}">
                        <igx-time-picker formControlName="checkin_time"
                          placeholder="{{this.msgServ.descriptiontext('time')}}"
                          (ngModelChange)="!makeOutNull(i) && assignForm().controls[i].get('checkout_time')?.value !== '' && getDiff(i);checkValidation(i,'checkin_time');checkinShiftWindow(i);"
                          [inputFormat]="'HH:mm'"
                          [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('checkin_time')?.dirty || assignForm().controls[i].get('checkin_time')?.touched) &&  assignForm().controls[i].get('checkin_time')?.errors}"
                          class="form-control form-timepicker " (click)="picker1.open()" #picker1></igx-time-picker>
                      </div>
                      <div
                        *ngIf="(submitted || assignForm().controls[i].get('checkin_time')?.dirty || assignForm().controls[i].get('checkin_time')?.touched) && assignForm().controls[i].get('checkin_time')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="assignForm().controls[i].get('checkin_time')?.errors?.required">
                          {{this.msgServ.validationDisplay('required')}}</div>

                        <div *ngIf="assignForm().controls[i].get('checkin_time')?.errors?.pattern">
                          {{this.msgServ.validationDisplay('pattern')}}</div>

                        <div *ngIf="assignForm().controls[i].get('checkin_time')?.errors?.sametime">
                          {{this.msgServ.greaterThanMessage('check-in time', 'Check-out time')}}</div>

                        <div *ngIf="assignForm().controls[i].get('checkin_time')?.errors?.outoffshift">
                            {{this.msgServ.shiftwindowMessage('Check-in','Check-out')}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card details-card p-16">
                  <div class="title">CHECK-OUT</div>
                  <div class="row row-16 mt-1">
                    <div class=" col-md-6 form-row">
                      <label for="checkout_date" class="form-label required">Check-out date</label>
                      <div class="form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted|| assignForm().controls[i].get('checkout_date')?.dirty || assignForm().controls[i].get('checkout_date')?.touched) && (assignForm().controls[i].get('checkout_date')?.errors || (assignForm().controls[i].errors?.fromToDate1))}">
                        <input (click)="datePicker2.open()" id="checkout_date" formControlName="checkout_date"
                          (keydown)="disableDate()"
                          (dateInput)="!makeOutNull(i) && assignForm().controls[i].get('checkin_time')?.value !== '' && assignForm().controls[i].get('checkout_time')?.value !== '' && getDiff(i);checkValidation(i,'checkout_time');checkoutShiftWindow(i);"
                          placeholder="{{this.msgServ.selectplaceholddisplay('date')}}" class="form-control"
                          [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('checkout_date')?.dirty || assignForm().controls[i].get('checkout_date')?.touched) && (assignForm().controls[i].get('checkout_date')?.errors || (assignForm().controls[i].errors?.fromToDate1))}"
                          matInput [matDatepicker]="datePicker2"><i class="icon-calendar pointer"
                          (click)="datePicker2.open()"></i>
                        <mat-datepicker at-datepicker touchUi="false" #datePicker2></mat-datepicker>
                      </div>
                      <div
                        *ngIf="(submitted || assignForm().controls[i].get('checkout_date')?.dirty || assignForm().controls[i].get('checkout_date')?.touched) && (assignForm().controls[i].get('checkout_date')?.errors || (assignForm().controls[i].errors?.fromToDate1))"
                        class="invalid-feedback">
                        <div *ngIf="assignForm().controls[i].get('checkout_date')?.errors?.required">
                          {{this.msgServ.validationDisplay('required')}}</div>
                        <div *ngIf="assignForm().controls[i].get('checkout_date')?.errors?.pattern">
                          {{this.msgServ.validationDisplay('pattern')}}</div>
                        <div *ngIf="(!assignForm().controls[i].get('checkout_date')?.errors?.required) &&assignForm().controls[i].errors?.fromToDate1">Please select a valid date
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-row">
                      <label for="" class="form-label required">Check-out time</label>
                      <div class="form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('checkout_time')?.dirty || assignForm().controls[i].get('checkout_time')?.touched) &&  assignForm().controls[i].get('checkout_time')?.errors}">
                        <igx-time-picker #picker formControlName="checkout_time"
                          [minValue]="isSameDates(i) == true ? this.assignForm().controls[i].get('checkin_time')?.value : '00:00'"
                          (ngModelChange)="assignForm().controls[i].get('checkin_time')?.value !== '' && getDiff(i);checkValidation(i,'checkout_time');checkoutShiftWindow(i)"
                          placeholder="{{this.msgServ.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                          [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('checkout_time')?.dirty || assignForm().controls[i].get('checkout_time')?.touched) &&  assignForm().controls[i].get('checkout_time')?.errors}"
                          class="form-control form-timepicker" (click)="picker2.open()" #picker2></igx-time-picker>
                      </div>
                      <div
                        *ngIf="(submitted || assignForm().controls[i].get('checkout_time')?.dirty || assignForm().controls[i].get('checkout_time')?.touched) && assignForm().controls[i].get('checkout_time')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="assignForm().controls[i].get('checkout_time')?.errors?.required">
                          {{this.msgServ.validationDisplay('required')}}</div>

                        <div *ngIf="assignForm().controls[i].get('checkout_time')?.errors?.pattern">
                          {{this.msgServ.validationDisplay('pattern')}}</div>

                        <div *ngIf="assignForm().controls[i].get('checkout_time')?.errors?.sametime">
                          {{this.msgServ.greaterThanMessage('check-in time', 'Check-out time')}}</div>

                        <div *ngIf="assignForm().controls[i].get('checkout_time')?.errors?.outoffshift">
                            {{this.msgServ.shiftwindowMessage('Check-in','Check-out')}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" col-md-6 form-row">
                <label for="" class="form-label required">Total hours </label>
                <input [readonly]="true" [attr.disabled]="true" type="text" class="form-control" autocomplete="off"
                  placeholder="{{this.msgServ.descriptiontext('time')}}" formControlName="hours">
              </div>
              <div class="col-md-6 form-row">
                <label for="" class="form-label required">Shift </label>
                <input [readonly]="true" [attr.disabled]="true" type="text" class="form-control" autocomplete="off"
                  placeholder="{{this.msgServ.selectplaceholddisplay('shift')}}" formControlName="shift_name_code">
              </div>
              <div class=" col-md-12 form-row">
                <label for="" class="form-label required">Reason</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Enter the reason for on-duty request" popoverClass="popover-input popover-info"
                    placement="bottom" triggers="hover"></i></span>
                <textarea type="text" rows="3" class="form-control" autocomplete="off" id="reason"
                  placeholder="{{this.msgServ.placeholderdisp('description here...')}}" formControlName="reason"
                  [ngClass]="{ 'is-invalid': (submitted || assignForm().controls[i].get('reason')?.dirty || assignForm().controls[i].get('reason')?.touched) && assignForm().controls[i].get('reason')?.errors}"></textarea>
                <div
                  *ngIf="(submitted || assignForm().controls[i].get('reason')?.dirty || assignForm().controls[i].get('reason')?.touched) && assignForm().controls[i].get('reason')?.errors"
                  class="invalid-feedback">
                  <div *ngIf="assignForm().controls[i].get('reason')?.errors?.required">
                    {{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="assignForm().controls[i].get('reason')?.errors?.pattern">
                    {{this.msgServ.validationDisplay('pattern')}}</div>
                  <div
                    *ngIf="!assignForm().controls[i].get('reason')?.errors?.pattern && assignForm().controls[i].get('reason')?.errors?.maxlength">
                    {{this.msgServ.fieldlengthvalidation('word','500')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" *ngIf="disabled == false" [disabled]="!formGrp.valid || !canSave"
      (click)="setForm()">SAVE</button>
    <button class="btn btn-primary btn-sm ms-auto" *ngIf="disabled == true" (click)="setForm()">SAVE</button>
  </footer>
</form>
