
  <div class="row row-16">
      <div class="col-12 d-flex position-relative">
          <span class=" text-light-500 fs-12 text-nowrap">{{requestitem.created_display}}

      <i *ngIf="requestitem?.overtime?.is_system_generated" class="icon-message-system text-warning fs-20 ms-8"></i>
    </span>
    <button *ngIf="from!='behalf'" class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
              (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
              <i class="bi toggle-star"></i>
          </button>
      </div>
      <div class="col-12 mt-8 card-ribbon">
          <div class="w-100 d-flex align-items-center gap-8">
              <h5 class="mb-8">Overtime Approval</h5>
              <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
                  <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
                      *ngIf="requestitem?.approved_status == 'Pending'">Pending</span>
                  <span class="badge px-16 ms-auto fs-14 badge-success py-1"
                      *ngIf="requestitem?.approved_status == 'Approved'">Accepted</span>
                  <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
                      *ngIf="requestitem?.approved_status == 'Rejected'">Rejected</span>
                  <span class="text-light-400">|</span>
                  <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
              </ng-container>
              <!-- Invalid request -->
              <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
                  class="ribbon ribbon-right ribbon-danger  ribbon-sm"
                  style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
          </div>
          <span class=" badge-custom {{requestitem.request_type_color_code}} ">Overtime</span>
          <span
              *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
              class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
      </div>
      <ng-container *ngIf="from!='behalf'">

        <app-progressbar [requestitem]="requestitem"></app-progressbar>
        <div class="col-12">
            <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
            </p>
        </div>
      </ng-container>
      <div class="col-12">
          <div class="row row-8">
              <div class="col-12">
                  <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                      <div class="row row-24">
                          <div class="col-12">
                              <div class="d-flex">
                                  <span class="title">OVERTIME DETAILS</span>
                                  <span class="text-accent2 pointer ms-auto fs-12 fw-500"
                                      (click)="getOvertimeTrendDetails(currentYear)"><i
                                          class="bi bi-graph-up me-1"></i>Overtime
                                      Trend
                                  </span>
                              </div>
                          </div>
                          <div class="col-12">
                              <div class="card border-0 card-c5 month-active text-light-500">
                                  <div class="card-body radius-4 text-center {{requestitem.request_type_color_code}} flex-center flex-column text-light-600"
                                      style="width:7rem;">
                                      <div class=" fs-12 text-uppercase lh-1">{{requestitem?.overtime?.day_of_week}}
                                      </div>
                                      <div class="fw-500">{{requestitem?.overtime?.attendance_date | date : 'd'}}
                                          {{requestitem?.overtime?.attendance_date | date : 'MMM'}}</div>
                                  </div>
                                  <div class="card-body justify-content-center p-0 d-flex flex-column">
                                      <div class="card card-c2 border-0 px-24">
                                          <div class="card-body px-0 py-12">
                                              <div class="row row-16">
                                                  <div class="col-12">
                                                      <span class="fs-14 fw-500">Actual Shift Time</span>
                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-in</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.overtime?.actual_shift_details?.check_in)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-out</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.overtime?.actual_shift_details?.check_out)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Total hours</div>
                                                      <div class="detail-desc">
                                                          {{(requestitem?.overtime?.actual_shift_details?.total_hour
                                                          == '' ||
                                                          requestitem?.overtime?.actual_shift_details?.total_hour ==
                                                          null) ? '-' :
                                                          this.appservice.hhmmTimeFormat(requestitem?.overtime?.actual_shift_details?.total_hour)}}
                                                          Hours</div>

                                                  </div>
                                              </div>
                                          </div>
                                          <div class="card-body px-0 py-12">
                                              <div class="row row-16">
                                                  <div class="col-12">
                                                      <span class="fs-14 fw-500">Employee Check-in and Check-out
                                                          Time</span>
                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-in</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.overtime?.checkin_time)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Check-out</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.timeFormatDisplay(requestitem?.overtime?.checkout_time)}}
                                                      </div>

                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Total working hours</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.hhmmTimeFormat(requestitem?.overtime?.total_work_hours)}}
                                                          Hours</div>
                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Extra working hours</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.hhmmTimeFormat(requestitem?.overtime?.extra_hours)}}
                                                          Hours</div>
                                                  </div>
                                                  <div class="col-md-4">
                                                      <div class="detail-title">Final overtime hours</div>
                                                      <div class="detail-desc">
                                                          {{this.appservice.hhmmTimeFormat(requestitem?.overtime?.final_hours)}}
                                                          Hours
                                                          <span
                                                              *ngIf="(requestitem?.overtime?.edit_ot_hours == true && !requestitem?.achieved_status)"
                                                              class="link-primary1 text-nowrap fs-12 fw-500 ms-14"
                                                              (click)="editFinalOverTimeFunction('popup')"> <i
                                                                  class="icon-pencil-square me-1"></i> Edit</span>
                                                      </div>
                                                  </div>

                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-12">
          <form class="row row-16 ">
              <ng-container [formGroup]="form" *ngIf="(!requestitem?.achieved_status)">
                  <div class="col-12">
                      <textarea name="" id="" rows="3"
                          placeholder="{{this.messageService.descriptiontext('comment')}}" formControlName="comment"
                          [ngClass]="{ 'is-invalid': f.comment.errors}"
                          class="form-control textarea-comment"></textarea>
                      <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
                          {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                  </div>
                <div  class="col-12 d-flex gap-16">
                    <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
                        (click)="confirm('Rejected')">Decline</button>
                    <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
                        (click)="confirm('Approved')">Confirm</button>
                </div>
            </ng-container>


          </form>
      </div>
  </div>
<!-- </div> -->

<!-- Trend Graph Sidebar -->
<div *ngIf="timeOffTrend" class="side-panel" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='timeOffTrend === true'>
  <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5> Overtime trend</h5>
          <a class="toggle-panel" (click)="timeOffTrend = false"><i class="icon-close-lg"></i></a>
      </header>

      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-24">

                  <div class="col-12 ">
                      <div class="card card-c2 pb-24  px-24 pt-24">
                          <div class="row row-12">

                              <div class="col-12 d-flex">
                                  <div class="fs-24 me-auto">Overtime trend - {{selectedYear}} </div>

                                  <ng-select class="form-ngselect ms-auto fs-14" [clearable]="false"
                                      placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                                      (change)="getOvertimeTrendDetails($event)" [(ngModel)]="selectedYearDisp"
                                      [ngModelOptions]="{standalone: true}">
                                      <ng-option class="fs-12" *ngFor="let year of yearList"
                                          [value]="year.k">{{year.k}}</ng-option>
                                  </ng-select>
                              </div>
                              <div class="col-12" *ngIf="barchartLoader">

                                  <app-graph-loader></app-graph-loader>
                              </div>
                              <div *ngIf="!barchartLoader" class="col-12  ">

                                  <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
                                      [yText]="barChartYAxisLabel"></app-bar-chart>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </form>
</div>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
      <form class="side-panel-container" autocomplete="off">
          <header class="side-panel-head">
              <h5>Activities</h5>
              <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
          </header>
          <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
              [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [sendReminder]="sendReminder" [requestId]="requestitem?.request_data?.id" (closePanel)="viewstatusPanel=false"></app-view-status>
      </form>
  </div>
</ng-container>

<!-- Final overtime hours edit popup -->
<div class="modal  modal-alert {{editFinalOverTime == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16">
              <h4 class="modal-title">Final overtime hours</h4>
          </div>
          <ng-container [formGroup]="form1">
              <div class="modal-body">

                  <label for="" class="form-label required">Final overtime hours </label>
                  <div class="form-icon icon-right"
                      [ngClass]="{ 'is-invalid': (f1.final_overtime_hours.touched || f1.final_overtime_hours.dirty) &&  f1.final_overtime_hours.errors}">

                      <igx-time-picker formControlName="final_overtime_hours"
                          placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'"
                          [ngClass]="{ 'is-invalid': (f1.final_overtime_hours.touched || f1.final_overtime_hours.dirty) &&  f1.final_overtime_hours.errors}"
                          class="form-control form-timepicker " (click)="picker6.open()" #picker6></igx-time-picker>
                      <i *ngIf="f1.final_overtime_hours.value!='' && f1.final_overtime_hours.value != null" class="icon-close text-light-400"
                          (click)="clearTime(picker6);picker6.close()"></i>
                  </div>

                  <div *ngIf="(f1.final_overtime_hours.touched || f1.final_overtime_hours.dirty) &&  f1.final_overtime_hours.errors"
                      class="invalid-feedback">
                      <div *ngIf="f1.final_overtime_hours.errors.required">
                          {{this.messageService.validationDisplay('required')}}
                      </div>
                  </div>

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-outline-danger btn-sm text-uppercase"
                      (click)="editFinalOverTime = false">Cancel</button>
                  <button type="button" class="btn btn-primary btn-sm text-uppercase"
                      (click)="editFinalOverTimeFunction('confirm')" [disabled]="f1.final_overtime_hours.value == '' || f1.final_overtime_hours.value == null">Confirm</button>
              </div>
          </ng-container>
      </div>
  </div>
</div>
