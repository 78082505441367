<form action="" class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-8">Select visible menu</p>
                <p class="text-helper mb-24">This is the first level menu which will be available for the employees you
                    selected in STEP 1</p>
            </div>
        </div>
        <div class="row row-16" formArrayName="orders"  >
            <div class="col-12">
            <p class="text-uppercase fs-14 fw-500 mb-8">Menu Items</p>
            </div>
              <div class="col-12 form-check" *ngFor="let order of orderFormArray; let i = index" [formGroupName]="i" >

                <input id="{{i}}" [attr.disabled]="(order?.default == true)? true : null" class="form-check-input" type="checkbox" value="" formControlName="checkorders" (click)="checkboxSelect(order,$event)">
                <label class="form-check-label" for="{{i}}" >
                    {{order?.name}}
                </label>

            </div>
            <div *ngIf="formGp?.value?.menuarray?.length<=0">At least one order menu be selected.</div>
            <!-- test end -->
        </div>
    </div>
    <footer class="submit-footer">
        <ng-container *ngIf="disabled == false">
            <button  *ngIf="formGp?.value?.menuarray?.length<=0" class="btn btn-primary btn-sm ms-auto" [disabled]="true" (click)="validateVisibleOrders()">NEXT</button>
            <button  *ngIf="formGp?.value?.menuarray?.length>0" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateVisibleOrders()">NEXT</button>
        </ng-container>
        <ng-container *ngIf="disabled == true">
            <button class="btn btn-primary btn-sm ms-auto"  (click)="validateVisibleOrders()">NEXT</button>
        </ng-container>
    </footer>
</form>
