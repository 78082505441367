import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
@Component({
  selector: 'app-financial-year-pending-request',
  templateUrl: './financial-year-pending-request.component.html',
  styleUrls: ['./financial-year-pending-request.component.scss']
})
export class FinancialYearPendingRequestComponent implements OnInit {

  loader      = false;
  year:number = 0;
  month:any;
  company:any;
  fyData:any;

  constructor(
    public route:ActivatedRoute,
    public router: Router,
    public sdService:SalaryDashboardService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
      this.getFYReminder()
    })
  }
  getFYReminder(){
    this.loader = true;
    this.sdService.getFYReminders(this.year,this.month,this.company).subscribe((res:any)=>{
      this.fyData = res.body;
      this.loader = false;
    })
  }
  detailedFYView(count:any,type:any){
    if(count!=0){
      this.router.navigateByUrl("/pending-request-details/"+this.year+"/"+this.month+"/"+this.company+"/"+type)

    }

  }
}
