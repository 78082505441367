import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
import { ActivatedRoute, Router,Params } from '@angular/router';

@Component({
  selector: 'app-short-leave-request-setup',
  templateUrl: './short-leave-request-setup.component.html',
  styleUrls: ['./short-leave-request-setup.component.scss']
})
export class ShortLeaveRequestSetupComponent implements OnInit {

  // Initialize Var
  dateFormat          : any
  activeTab                     = 1 ;
  errorPass                     = "";
  invalidPass         : boolean = false;
  error               : boolean = false;
  alertToggle         : boolean = false;
  validationData      : any     = [];
  validationPortionData:any     = [];
  empId                         = 0;
  from:any
  requestFor:any = ''

  // Form Initialize
  shortForm           : FormGroup = this.fb.group({
    policyRequest     : this.fb.group({
      date            : [null, [Validators.required]],
      reason          : [null, [Validators.required, Validators.maxLength(100)]],
      document        : [''],
      short_leave_portion    : this.fb.array([])
    })
  })

  constructor(
    private invstServ : InvestmentDeclerationService,
    public appServ    : AppService,
    private fb        : FormBuilder,
    private authServ  : AuthService,
    private router    : Router,
    private activityService:ManageActivityService,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
       this.empId = params['id']
       this.from  = 'behalf'
       this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
        this.requestFor = "";
        if(res['first_name'] != null)
        this.requestFor+= res['first_name']+" ";
        if(res['middle_name'] != null)
        this.requestFor+= res['middle_name']+" ";
        if(res['last_name'] != null)
        this.requestFor+= res['last_name'];
        this.requestFor+=" ("+res['employee_code']+")"

      })
      }
      else
        this.empId = this.authServ.isEmployeeId

    })

  }

  // PolicyRequest Form
  get policyRequestForm(){
    return this.shortForm.get('policyRequest') as FormGroup;
  }

  // Setup Close
  backClicked() {
   this.invstServ.backClicked('/short-leave-setup');
  }

  // Nav Change
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
     if (this.shortForm['controls'].policyRequest.invalid) {
        changeEvent.preventDefault();
     }else if(this.error){
        if(this.errorPass != ''){
          this.invalidPass = true;
          this.alertToggle = true;
        }else{
          this.invalidPass = false;
          this.alertToggle = false;
        }
        changeEvent.preventDefault();
     }
   }
 }

  totalDuration : any
  dateMsg : any
  //  Step 1 Submit
  submitOne(data: any){
    this.totalDuration = data?.total
    this.dateMsg = data?.textMsg
    this.activeTab = 2;
  }

  // Hours to HH:mm:ss
  // decimalToHHMMSS(decimalDuration : any) {
  //   const hours = Math.floor(decimalDuration);
  //   const minutes = Math.floor((decimalDuration - hours) * 60);
  //   const seconds = Math.floor(((decimalDuration - hours) * 60 - minutes) * 60);
  //   const formattedHours = hours.toString().padStart(2, '0');
  //   const formattedMinutes = minutes.toString().padStart(2, '0');
  //   const formattedSeconds = seconds.toString().padStart(2, '0');
  //   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // }

  // Final Submit
  submitForm(data:any){
    let shortArr = this.policyRequestForm.get('short_leave_portion')?.value;
    // Below code is added because on conversion of date the original values
    // are getting changed and can be seen on the screen
    let shortArrCopy = JSON.parse(JSON.stringify(shortArr));
    shortArrCopy.forEach((item: any) => {
      item['from_time'] = moment(item['from_time']).format('HH:mm:ss');
      item['to_time'] = moment(item['to_time']).format('HH:mm:ss');
      item['duration'] = moment(item['duration'],'HH:mm').format('HH:mm:ss');
      this.totalDuration = item['duration']
    });

    let dataArray:any = {
      'employee'       : this.empId,
      'total_duration' : this.totalDuration,
      'date'           : moment(this.policyRequestForm.get('date')?.value).format('YYYY-MM-DD'),
      'reason'         : this.policyRequestForm.get('reason')?.value,
      'document'       : this.policyRequestForm.get('document')?.value,
      'short_leave_portion' : shortArrCopy
    }
    if(this.from=='behalf'){
      dataArray['employee_id'] = this.empId;
      delete dataArray['employee']
      this.activityService.shortLeaveRequest(dataArray).subscribe((res:any)=>{
        this.router.navigate(['manage-activities'])

      })
    }
    else{

      this.invstServ.createSHLRequest(dataArray).subscribe((res:any)=>{
        this.router.navigate(['request'])
      })
    }
  }

  // Validations got on Step 1
  validationError(data:any){
    this.error      = data?.error;
    this.errorPass  = data?.errorMsg;
  }

  // Validation Data
  validationResult(data:any){
    this.validationData = data.ValidationResult;
  }

  validationPortionResult(data:any){
    if(data?.error){
      this.validationPortionData = [];
      this.errorPass             = data?.validationPortionResult;
    }else{
      this.validationPortionData = data?.validationPortionResult;
      this.errorPass             = '';
    }
  }
}
