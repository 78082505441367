import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TemplateService } from 'src/app/@core/services/template.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';

@Component({
  selector: 'app-template-setup',
  templateUrl: './template-setup.component.html',
  styleUrls: ['./template-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateSetupComponent implements OnInit {
  activeTab = 1;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private template: TemplateService, private router: Router, public changeDetectorRef: ChangeDetectorRef) { }
  formvalue: any;
  disabled = true;
  Action = '';
  id: number = 0;
  dataacceessid: any = 0;
  //templatedetailform: any;
  empName: string = 'Employee Name'




  TemplatedetailsForm = this.fb.group({
    templateName: ['', [Validators.required,UnamepatternValidator()]],
    templateType: ['', Validators.required],
    subject: ['', Validators.required],
    description: [this.description(), Validators.required],
    tagname: ['', Validators.required]
  })

  ngOnInit(): void {
    this.empName = "Employee Name"
    this.route.params.subscribe((params: Params) => {
      this.Action = "Add";
      if (!isNaN(params['id'])) {
        this.Action = "Edit";
        this.id = +params['id'];
        this.dataacceessid = this.id;
        this.editForm();
      }
    })
    // console.log(this.id);
  }


  submitForm(event: any) {
    if (this.id == 0)
    {
      this.template.posttemplatedetails(event).subscribe((res: any) => {
        this.router.navigate(['template']);
      });
    } else {
      this.template.updateTemplateDetail(event, this.id).subscribe((res: any) => {
        this.router.navigate(['template']);
      },
        (error) => {

        });
    }
  }


  editForm() {
    this.template.getsingletemplatedetail(this.id).subscribe((res: any) => {
      this.TemplatedetailsForm.setValue({
        templateName: res.body.template_name,
        templateType: res.body.template_type,
        subject: res.body.subject,
        description: res.body.description,
        tagname: res.body.tag
      })

      // console.log(this.TemplatedetailsForm['controls'].templateType.value,'Setup-Form....');

      // this.changeDetectorRef.detectChanges();
    });


  }

  onPreviewClick(event: any) {
    this.activeTab = 2;
    this.formvalue = event;
    this.disabled = false;
  }

  description() {
    return `
    <p>Hi Employee Name,</p>
    <br>
    <p>Welcome to the Organization, We are glad to have you as part of our team.
    As a initiation we will be setting up series or meeting with our management for your better knowledge of
    organization and work culture.</p><br>
  <p>Looking forward for your greater efforts. </p><br>
<br>
  <span>Team Company Name</span> <br>`
  }

}
