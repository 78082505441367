<div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='filter === true'
  [formGroup]="filterForm">
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="closefn()"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid px-32 py-24 ">
        <ngb-accordion class="custom-accordion" #acc="ngbAccordion"
          activeIds="{{activeidcls + ',' + activeidcls1 + ',' + activeidcls2 + ',' + activeidcls3 + ',' + activeidcls4}}">
          <ngb-panel id="ngb-panel-0" *ngIf="from !='Map' && from !='advancehr'  && from !='expensehr'">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button" [class.collapsed]='collapsedclass1 === true'>Sort
                By</button>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row row-16">
                <div class="col-12">
                  <label for="name" class="form-label ">Sort By</label>
                  <ng-container *ngIf="from ==='send-new-user' ||from === 'salary' || from=='FandF' || from=='myteam' || from=='salary_unassigned' || from=='Employehrletter'">
                    <ng-select class="form-ngselect" formControlName="sortby" id="sortby" placeholder="Select filter">
                      <ng-option *ngFor="let sort_by of sort_by" [value]="sort_by">
                        {{sort_by}}
                      </ng-option>
                    </ng-select>
                  </ng-container>
                  <ng-container *ngIf="from == 'Employee'">
                    <ng-select class="form-ngselect" formControlName="sortby" id="sortby" placeholder="Select filter">
                      <ng-option *ngFor="let sort_by_employee of sort_by_employee" [value]="sort_by_employee">
                        {{sort_by_employee}}
                      </ng-option>
                    </ng-select>
                  </ng-container>
                  <ng-container *ngIf="from == 'Employee_directory'">
                    <ng-select class="form-ngselect" formControlName="sortby" id="sortby" placeholder="Select filter">
                      <ng-option *ngFor="let sort_by_employee_directory of sort_by_employee_directory" [value]="sort_by_employee_directory">
                        {{sort_by_employee_directory}}
                      </ng-option>
                    </ng-select>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        <ngb-panel id="ngb-panel-4" *ngIf="from=='advancehr' || from =='expensehr'">
          <ng-template ngbPanelHeader>
            <button ngbPanelToggle class="accordion-button" [class.collapsed]='collapsedclass5 === true'>{{from=='advancehr'?'Advance Dates':'Report Dates'}}</button>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row row-16 mb-16 ">
              <div class="col-12 form-row">
                  <label class="form-label ">Select date range</label>
              </div>      
              
              <div class="input-group form-icon icon-right">
                  <input (click)="datePicker1.open()" class="form-control" placeholder="Start date" matInput
                      formControlName="expense_date_from" [max]="f.expense_date_to.value" [matDatepicker]="datePicker1"
                      [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                  <mat-datepicker  touchUi="false" #datePicker1>
                  </mat-datepicker>
              </div>
              
              <div class="input-group form-icon icon-right">
                  <input (click)="datePicker2.open()" class="form-control" placeholder="To date" matInput
                      formControlName="expense_date_to" [min]="f.expense_date_from.value" [matDatepicker]="datePicker2"
                      [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker  touchUi="false" #datePicker2>
                  </mat-datepicker>
              </div>              

              <!-- <div class="form-icon icon-right vertical-datepicker">
                      <input class="form-control datepicker-vertical" type="text"
                          [locale]="{applyLabel: 'ok', format: dateFormat}" onkeydown="return false" opens="right"
                          ngxDaterangepickerMd [closeOnAutoApply]="true" [showCustomRangeLabel]="true"
                          [alwaysShowCalendars]="false" [linkedCalendars]="true" [showClearButton]="true"
                          placeholder="{{this.messageService.selectplaceholddisplay('Date')}}"
                          formControlName="expense_date">
                      <i class="icon-calendar pointer-event"></i>
              </div> -->
          </div>
          </ng-template>
        </ngb-panel>
          <ngb-panel id="ngb-panel-4" *ngIf="from=='FandF'">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button" [class.collapsed]='collapsedclass5 === true'>Exit Workflow
                Dates</button>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row row-16">
                <div class="col-12">
                  <label for="name" class="form-label ">Filter By</label>
                  <ng-select class="form-ngselect" formControlName="exit_date_type" id="exit_date_type"
                    placeholder="Select date">
                    <ng-option *ngFor="let exit_date_type of exit_date_type" [value]="exit_date_type.id">
                      {{exit_date_type.name}}
                    </ng-option>
                  </ng-select>
                </div>
                <ng-container *ngIf="f.exit_date_type.value">
                  <div class="col-12">
                    <label for="name" class="form-label ">From Date</label>
                    <div class="input-group form-icon icon-right">
                      <input (click)="datePicker1.open()" class="form-control"
                        placeholder="{{this.messageService.selectplaceholddisplay('From date')}}" matInput
                        formControlName="from_date" [matDatepicker]="datePicker1" (keydown)="disableDate()"><i
                        class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                      <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                    </div>
                  </div>
                  <div class="col-12">
                    <label for="name" class="form-label ">To Date</label>
                    <div class="input-group form-icon icon-right"
                      [ngClass]="{ 'is-invalid':f.to_date.touched && (filterForm.errors!=null)}">
                      <input [ngClass]="{ 'is-invalid':f.to_date.touched && (filterForm.errors!=null)}"
                        (click)="datePicker2.open()" class="form-control"
                        placeholder="{{this.messageService.selectplaceholddisplay('To date')}}" matInput
                        formControlName="to_date" [matDatepicker]="datePicker2" (keydown)="disableDate()"><i
                        class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                      <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                    </div>
                    <div *ngIf="f.to_date.touched && (filterForm.errors!=null)" class="invalid-feedback">
                      <div *ngIf="filterForm.errors!=null">{{this.messageService.greaterThanMessage('From Date','To
                        Date')}}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="ngb-panel-1">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button" [class.collapsed]='collapsedclass2 === true'>Organization
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row row-16">
                <div class="col-12 form-row">
                  <label for="" class="form-label ">Company </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Company')}}"
                    formControlName="company" [items]="companyDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="company_name"
                    [closeOnSelect]="false" bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.company.length ">
                        <span class="ng-value-label">{{filterForm.value.company.length }} Company
                          Selected</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" />
                        <img class="avatar-sm sq-24 img-fluid rounded-circle"
                          *ngIf="item.logo !=  null && item.logo !=  ''" src="{{item.logo}}">
                        <span *ngIf="item.logo ==  null || item.logo ==  ''"
                          class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appService.getFirstChar(item.company_name,1)}}</span></span>

                        <span class="">{{ (item.company_name.length>15)? (item.company_name |
                          slice:0:15)+'..':(item.company_name) }}</span>
                      </div>
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.company.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.company,let ki =index">
                      <ng-container *ngFor="let insideitem of companyDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          <div class="d-inline-flex align-items-center">
                            <img class=" img-fluid sq-24 rounded-circle"
                              *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                            <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''"
                              class="  sq-24 avatar-circle rounded-circle me-1 bg{{k%5}}">
                              <span>
                                {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                            </span>
                            <span class=""> {{ (insideitem['company_name'].length>25)? (insideitem['company_name'] |
                              slice:0:25)+'..':(insideitem['company_name']) }}</span>
                          </div>
                        </span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('company',ki)"
                        aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 form-row">
                  <label for="" class="form-label ">Business Unit</label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Business Unit')}}"
                    formControlName="bu" [items]="buDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ item.name }}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.bu.length">
                        <span class="ng-value-label">{{filterForm.value.bu.length}} Business Unit
                          Selected</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.bu?.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.bu,let ki =index">
                      <ng-container *ngFor="let insideitem of buDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('bu',ki)"
                        aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 form-row">
                  <label for="" class="form-label ">Department </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Department')}}"
                    formControlName="department" [items]="DepDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ item.name }}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.department.length ">
                        <span class="ng-value-label">{{filterForm.value.department.length}} Department
                          Selected</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.department.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.department,let ki =index">
                      <ng-container *ngFor="let insideitem of DepDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right"
                        (click)="clearcommonForm('department',ki)" aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 form-row">
                  <label for="" class="form-label">Designation</label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}"
                    formControlName="designation" [items]="DesignationDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ item.name }}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.designation.length">
                        <span class="ng-value-label">{{filterForm.value.designation.length}} Designation
                          Selected</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.designation.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.designation,let ki =index">
                      <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right"
                        (click)="clearcommonForm('designation',ki)" aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 form-row">
                  <label for="" class="form-label ">Branch </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}"
                    formControlName="branch" [items]="BranchDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ item.name }}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.branch.length ">
                        <span class="ng-value-label">{{filterForm.value.branch.length }} Branch
                          Selected.</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.branch.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.branch,let ki =index">
                      <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('branch',ki)"
                        aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 form-row">
                  <label for="" class="form-label ">Grade </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Grade')}}"
                    formControlName="grade" [items]="gradeDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.grade.length ">
                        <span class="ng-value-label">{{filterForm.value.grade.length }} Grade
                          Selected.</span>
                      </div>
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.grade.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.grade,let ki =index">
                      <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('grade',ki)"
                        aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="ngb-panel-2">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button"
                [class.collapsed]='collapsedclass3 === true'>Employment</button>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row row-16">
                <div class="col-12">
                  <label for="name" class="form-label ">Employment Type</label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employment Type')}}"
                    formControlName="emp_type" [items]="emptypeDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="name" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.emp_type.length ">
                        <span class="ng-value-label">{{filterForm.value.emp_type.length }} Employment type
                          Selected.</span>
                      </div>
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.emp_type.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.emp_type,let ki =index">
                      <ng-container *ngFor="let insideitem of emptypeDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['name']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('emp_type',ki)"
                        aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 form-row" *ngIf="from!='Employee_directory'">
                  <label for="" class="form-label ">Employment Status </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Employment Status')}}"
                    formControlName="emp_status" [items]="empstatusDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="name" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" class="me-8" />
                        {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.emp_status.length ">
                        <span class="ng-value-label">{{filterForm.value.emp_status.length }} Employment Status
                          Selected.</span>
                      </div>
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16" *ngIf="filterForm.value.emp_status.length != 0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.emp_status,let ki =index">
                      <ng-container *ngFor="let insideitem of empstatusDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['name']==item">
                          {{ (insideitem['name'].length>15)? (insideitem['name'] |
                          slice:0:15)+'..':(insideitem['name']) }}</span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right"
                        (click)="clearcommonForm('emp_status',ki)" aria-hidden="true">×</span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="ngb-panel-3" *ngIf="from !='Map' && from !='advancehr' && from !='expensehr' && from!='Employehrletter'">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button"
                [class.collapsed]='collapsedclass4 === true'>Manager</button>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row row-16">
                <div class="col-12 form-row" *ngIf="from!='myteam'">
                  <label for="" class="form-label">Reporting Manager</label>
                  <ng-select  [loading]="loader" placeholder="{{this.messageService.selectplaceholddisplay('Reporting Manager')}}"
                    formControlName="repomanager" [items]="managerDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.repomanager.length">
                        <span class="ng-value-label">{{filterForm.value.repomanager.length}}
                          Employee
                          selected.</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                          [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                          class="avatar-sm sq-24 img-fluid rounded-circle"
                          *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
                        <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                          class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>
                        <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name |
                          slice:0:30)+'..':(item.name) }}</span>
                      </div>
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values my-16" *ngIf="filterForm.value.repomanager.length !=0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.repomanager, let m =index">
                      <ng-container *ngFor="let insideitem of managerDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          <div class="d-inline-flex">
                            <img class="avatar-sm sq-32 img-fluid rounded-circle"
                              *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                              src="{{insideitem.profile_image}}">
                            <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                              class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                              <span>
                                {{this.appService.getFirstChar(insideitem.first_name+"
                                "+insideitem.last_name,2)}}</span>
                            </span>
                            <span class="mt-2 ms-1">
                              {{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                              ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                            </span>
                          </div>
                        </span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" aria-hidden="true"
                        (click)="clearcommonForm('repomanager',m)"><i class="icon-close"></i></span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 form-row">
                  <label for="name" class="form-label ">Functional Manager</label>
                  <ng-select  [loading]="loader" placeholder="{{this.messageService.selectplaceholddisplay('Functional Manager')}}"
                    formControlName="funmanager" [items]="funmanagerDD" [multiple]="true" groupBy="selectedAllGroup"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                    bindValue="id" class="multiselect">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                        [ngModelOptions]="{standalone: true}" /> Select All
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="filterForm.value.funmanager.length">
                        <span class="ng-value-label">{{filterForm.value.funmanager.length}}
                          Employee
                          selected.</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8" type="checkbox"
                          [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" /><img
                          class="avatar-sm sq-24 img-fluid rounded-circle"
                          *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
                        <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                          class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>
                        <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name |
                          slice:0:30)+'..':(item.name) }}</span>
                      </div>
                    </ng-template>
                  </ng-select>
                  <ul class="selected-values my-16" *ngIf="filterForm.value.funmanager.length !=0">
                    <li class="ng-value" *ngFor="let item of filterForm.value.funmanager, let m =index">
                      <ng-container *ngFor="let insideitem of funmanagerDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          <div class="d-inline-flex">
                            <img class="avatar-sm sq-32 img-fluid rounded-circle"
                              *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                              src="{{insideitem.profile_image}}">
                            <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                              class="avatar-sm sq-32 rounded-circle {{insideitem.color_code}}">
                              <span>
                                {{this.appService.getFirstChar(insideitem.first_name+"
                                "+insideitem.last_name,2)}}</span>
                            </span>
                            <span class="mt-2 ms-1">
                              {{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":"
                              ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                            </span>
                          </div>
                        </span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" aria-hidden="true"
                        (click)="clearcommonForm('funmanager',m)"><i class="icon-close"></i></span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </ngb-panel>

        </ngb-accordion>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetFilterFunction()">
        Reset
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="applyfilter();closefn();">
        Apply
      </button>
    </footer>
  </div>
</div>
