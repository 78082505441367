
<div class="side-panel-body" style="overflow-y: auto;">
  <div class="container-fluid p-32 ">
      <div class="col-12">
        <h5>DETAILS</h5>
      </div>
      <div class="card card-c2 p-32">
          <div class="row  row-16">

              <div class="col-12 d-flex ">
                  <span class=" text-light-500 fs-12 text-nowrap">{{data?.created_at}}</span>
              </div>
              <div class="col-12 mt-8">
                  <h5 class="mb-8">Reimbursement Request Approval</h5>
                  <span class=" badge-custom {{data?.request_type_color_code}}">Reimbursement Request</span>
              </div>

              <app-reimbursement-inbox-details  [iscancel]="data.is_cancel" [from]="'fnf'" [reimbursement]="reimbursement" [currency]="currency" (successEmitter)="refreshInbox($event)" [empId]="empId" [archieved_status]="archieved_status" [reqId]="data?.id" [reqStatus]="reqStatus"></app-reimbursement-inbox-details>

          </div>
      </div>
  </div>
</div>
