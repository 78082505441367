import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';

@Injectable({
   providedIn: 'root'
})
export class clarityService { 
   constructor(private title: Title, @Inject(DOCUMENT) private doc: any, private snapshotService: SnapshotService) {
   }
  
   createScriptForClarity() {
      const s = this.doc.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = this.clarityScript();

            this.doc.head.appendChild(s);
   }
   clarityScript() {
    var currentUserData = this.snapshotService.EMPDETAILS

    return `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    clarity("set", "company_name", "${currentUserData.company}");
    clarity("set", "department",  "${currentUserData.department}");
    clarity("set", "designation",  "${currentUserData.designation}");
    clarity("set", "employee_code",  "${currentUserData.employee_code}");
    clarity("set", "employment_status",  "${currentUserData.employment_status}");
    clarity("set", "first_name",  "${currentUserData.first_name}");
    clarity("set", "last_name",  "${currentUserData.last_name}");
    clarity("set", "middle_name",  "${currentUserData.middle_name}");
    clarity("set", "domain","${ window.location.origin}");
    })(window, document, "clarity", "script", "nk2s9r4oko");`;
  }

} 