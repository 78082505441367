import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EsichellansettingService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }

  getesigrouptypeList(){
    return this.http.get(`${this.API_PATH}payroll/api/v1/esi-groups/?is_active=1&ordering=name`, this.httpOption);
  }
  createesigroup(data:any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/esi-groups/`, data, this.httpOption)
  }
  getesigroupData(status:any,limit:any,offset:any,search:any,order:any,filterdata:any){
    if(filterdata != '' && filterdata != undefined){
    return this.http.get(`${this.API_PATH}payroll/api/v1/esi-groups/?limit=${limit}&offset=${offset}&search=${search}&ordering=${order}&${filterdata}`, this.httpOption);
    }else{
      return this.http.get(`${this.API_PATH}payroll/api/v1/esi-groups/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`, this.httpOption);
    }
  }
  getSearchList(status:any,filterdata:any){
    if(filterdata != '' && filterdata != undefined){
      return this.http.get(`${this.API_PATH}payroll/api/v1/esi-challan-group-dropdown/?ordering=name&${filterdata}`, this.httpOption);
    }else{
      return this.http.get(`${this.API_PATH}payroll/api/v1/esi-challan-group-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
    }

  }
  getSingleesigroupData(id:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/esi-group/${id}`, this.httpOption);
  }
 
  deleteesigroup(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}payroll/api/v1/esi-group/${id}/`,data, this.httpOption)
  }
  updateesigroup(id:number,data:any){
    return this.http.put(`${this.API_PATH}payroll/api/v1/esi-group/${id}/`,data, this.httpOption)
  }
}