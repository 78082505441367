import { Component, ViewChild, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { CalendarOptions, EventApi, FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import { MyAttendenceCalenderService } from 'src/app/@core/services/my-attendence-calender.service';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { MessageService } from 'src/app/message.global';
import { AttendanceDashboardService } from 'src/app/@core/services/attendance-dashboard.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';


@Component({
  selector: 'app-attendance-calendar',
  templateUrl: './attendance-calendar.component.html',
  styleUrls: ['./attendance-calendar.component.scss']
})
export class AttendanceCalendarComponent implements OnInit {
  public calendarOptions: CalendarOptions = this.initCalendar();
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @Input() activeMonth: any
  @Input() activeYear: any
  @Input() isEmployeeId: any
  @Input() options: any
  @Input() activateAction: any;
  @Input() from: any;
  @Input() fromoverride: any;
  @Output() gotoAction = new EventEmitter();
  @Output() addPunchAction = new EventEmitter();
  @Output() viewempmap = new EventEmitter();
  @Output() changeMonthYear = new EventEmitter();
  @Input() empDoj: any
  @Input() visibleRange:any
  viewProfileModal = false;
  viewProfileImage = false;
  attendanceDataArray: any = []
  clickedDate: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  today: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  loader: boolean = true
  count: number = 0

  addPunch = false;
  savePunch = false
  deleteToggle = false;
  deleteClick = false;
  punchId: any
  calData:any = []
  constructor(private attendenceCalenderService: MyAttendenceCalenderService, public appservice: AppService, private datePipe: DatePipe, public messageService: MessageService, public attendanceDashboardService: AttendanceDashboardService, private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.appservice.sidebarVisibilityChange.subscribe(value => {
      this.count = 1
      this.refreshCalendar(this.calData)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.count++;
    if (this.count == 1)
      this.loader = true
    this.getCalendarData()
  }
  private initCalendar(data: any = []): CalendarOptions {
    this.loader = false
    const monthsNums: any = { January: '01', February: '02', March: '03', April: '04', May: '05', June: '06', July: '07', August: '08', September: '09', October: '10', November: '11', December: '12' }
    let calendarApi = this.calendarComponent?.getApi();
    let mnth = monthsNums[this.activeMonth]
    let monthStart = this.activeYear + "-" + mnth + "-01"
    if(this.from=='HR')
      this.clickedDate = monthStart;
    return {
      initialDate: monthStart,
      initialView: 'dayGridMonth',
      height: 'auto',
      stickyHeaderDates: false,
      headerToolbar: {
        start: 'prev',
        center: 'title',
        end: 'next'
      },
      visibleRange:this.visibleRange,
      validRange:this.visibleRange,
      events: data,
      eventContent: function (arg) {

        let elements = document.querySelectorAll(".fc-day-disabled")
        elements.forEach(element => {
          element.classList.add('bgwhite')
        });
        // Get the date mentioned in events
        var dateString = moment(arg.event._instance?.range.start).format('YYYY-MM-DD')
        // Get status
        var dateStatus = arg.event.extendedProps.status
        // Get the cell from calender which is mentioned in event
        var dateTableCell = document.querySelector('[data-date ="' + dateString + '"]') as HTMLElement
        //Dont add to Prev and Next Month

        ['unknown', 'status-holiday', 'status-present', 'status-leave', 'status-weekoff', 'status-absent', 'status-present-leave', 'status-present-weekoff', 'status-present-absent', 'status-leave-present', 'status-leave-weekoff', 'status-leave-absent', 'status-weekoff-present', 'status-weekoff-leave', 'status-weekoff-absent', 'status-absent-present', 'status-absent-leave', 'status-absent-weekoff', 'status-shift-unassigned', 'status-upcoming', 'status-absent'].forEach(element => {
          dateTableCell?.classList.remove(element);
        });

        if (!dateTableCell?.classList.contains('fc-day-other')) {
          // Add class to table cell accoding to status
          dateTableCell?.classList.add('cell-status');
          dateTableCell?.classList.add(dateStatus);

          //To Set Needed data attributes so that can be accessed in dateClick()
          dateTableCell.dataset.checkin = arg.event.extendedProps.checkIn;
          dateTableCell.dataset.checkout = arg.event.extendedProps.checkOut;
          dateTableCell.dataset.workhour = arg.event.extendedProps.workHour;

          // If condition  to add "first-cell-div" only once
          if (!dateTableCell?.getElementsByClassName('first-cell-div')[0]) {
            let cellUpperDiv = document.createElement('div')
            cellUpperDiv.classList.add("first-cell-div")
            if (arg.event.extendedProps.late_coming_status) {
              let lateTag = document.createElement('div')
              lateTag.classList.add("tag-cell", "tag-warning")
              lateTag.innerHTML = 'Late'
              cellUpperDiv?.append(lateTag)
            } else if(arg.event.extendedProps.early_checkout_status){
              let lateTag = document.createElement('div')
              lateTag.classList.add("tag-cell","tag-warning")
              lateTag.innerHTML = 'Early'
              cellUpperDiv?.append(lateTag)
            }
            if (arg.event.extendedProps.type != "") {
              let typeIndicator = document.createElement('div')
              typeIndicator.classList.add("type-indicator")
              if (arg.event.extendedProps.type == "attendanceoverride") {
                typeIndicator.classList.add("type-override")
              }else if (arg.event.extendedProps.type == "regularized") {
                typeIndicator.classList.add("type-regularized")
              } else if (arg.event.extendedProps.type == "deduction") {
                typeIndicator.classList.add("type-deduction")
              } else if (arg.event.extendedProps.type == "punch") {
                typeIndicator.classList.add("type-punch")
              } else if (arg.event.extendedProps.type == "on-duty") {
                typeIndicator.classList.add("type-on-duty")
              } else if (arg.event.extendedProps.type == "WFH") {
                typeIndicator.classList.add("type-WFH")
              }
              cellUpperDiv?.append(typeIndicator)
            }
            if(arg.event.extendedProps.short_leave_status ){
              let typeIndicator1 = document.createElement('div')
              typeIndicator1.classList.add("type-indicator")
              typeIndicator1.classList.add("type-late")
              cellUpperDiv?.append(typeIndicator1)
            }
            dateTableCell?.prepend(cellUpperDiv)
          }
          else {
            let cellUpperDiv = dateTableCell?.getElementsByClassName('first-cell-div')[0]
            cellUpperDiv.innerHTML = '';
            if (arg.event.extendedProps.late_coming_status) {
              let lateTag = document.createElement('div')
              lateTag.classList.add("tag-cell", "tag-warning")
              lateTag.innerHTML = 'Late'
              cellUpperDiv?.append(lateTag)
            }else if(arg.event.extendedProps.early_checkout_status){
              let lateTag = document.createElement('div')
              lateTag.classList.add("tag-cell","tag-warning")
              lateTag.innerHTML = 'Early'
              cellUpperDiv?.append(lateTag)
            }
            if (arg.event.extendedProps.type != "") {
              let typeIndicator = document.createElement('div')
              typeIndicator.classList.add("type-indicator")
              if (arg.event.extendedProps.type == "attendanceoverride") {
                typeIndicator.classList.add("type-override")
              } else if (arg.event.extendedProps.type == "regularized") {
                typeIndicator.classList.add("type-regularized")
              } else if (arg.event.extendedProps.type == "deduction") {
                typeIndicator.classList.add("type-deduction")
              } else if (arg.event.extendedProps.type == "punch") {
                typeIndicator.classList.add("type-punch")
              } else if (arg.event.extendedProps.type == "on-duty") {
                typeIndicator.classList.add("type-on-duty")
              } else if (arg.event.extendedProps.type == "WFH") {
                typeIndicator.classList.add("type-WFH")
              }
              cellUpperDiv?.append(typeIndicator)

            }
            if(arg.event.extendedProps.short_leave_status ){
              let typeIndicator1 = document.createElement('div')
              typeIndicator1.classList.add("type-indicator")
              typeIndicator1.classList.add("type-late")
              cellUpperDiv?.append(typeIndicator1)
            }
            dateTableCell?.prepend(cellUpperDiv)
          }
        }
        let timeEvent = document.createElement('div')
        timeEvent.classList.add("time-event");
        // if (arg.event.extendedProps.status != '') {
        //Check-In Time
        let checkIn = document.createElement('span')
        checkIn.classList.add("start-time");
        if (arg.event.extendedProps.checkIn != "" &&
          arg.event.extendedProps.checkIn != undefined) {
          checkIn.innerHTML = arg.event.extendedProps.checkIn
        } else {
          checkIn.innerHTML = '--:--'
        }
        //Check-out time
        let checkOut = document.createElement('span')
        checkOut.classList.add("end-time");
        if (arg.event.extendedProps.checkOut != "" &&
          arg.event.extendedProps.checkOut != undefined) {
          checkOut.innerHTML = arg.event.extendedProps.checkOut
        } else {
          checkOut.innerHTML = '--:--'
        }
        //Total Work Hour
        let duration = document.createElement('span')
        if (arg.event.extendedProps.workHour != "" &&
          arg.event.extendedProps.workHour != undefined) {
          if (arg.event.extendedProps.overtime_status == false) {
              duration.classList.add("duration");
            duration.innerHTML = arg.event.extendedProps.workHour + " Hrs"
          }
          else {
              duration.classList.add("overtime");
              duration.innerHTML = arg.event.extendedProps.overTime+ " Hrs. OT"
          }
        } else {
            duration.classList.add("duration");
          duration.innerHTML = "-- Hrs"
        }
        // if(arg.event.extendedProps.short_leave_status ){
        //   let shl =  document.createElement('span')
        //   shl.classList.add("text-warning");
        //   shl.innerHTML = " (SHL)"
        //   duration.append(shl)
        // }
        timeEvent.append(checkIn, checkOut, duration);
        // }
        let arrayOfDomNodes = [timeEvent]
        return { domNodes: arrayOfDomNodes }
      },
      dateClick: this.handleDateClick.bind(this),
      datesSet: this.handleEvents.bind(this)


    };

  }

  handleEvents(event: EventApi | any): void {
    if (this.calendarComponent && this.calendarComponent.getApi()) {
      let calendarApi = this.calendarComponent.getApi();
      // this.appservice.getMonthName(calendarApi.view.currentStart.getMonth() + 1, 'long')
      if (this.activeMonth != moment(calendarApi.view.currentStart).format('MMMM')) {
        this.changeMonthYear.emit(calendarApi.view.currentStart);
      }
    }

  }

  afterCalendar(): void {
    //Make calender adjacent div same height
    var calendarHeight = Number(document.querySelector('#fullcalendar')?.clientHeight) - 160;
    let el: any = document.getElementById("dayDetails-body");
    const newLocal = 'px';
    if (el != undefined)
      el.style.maxHeight = calendarHeight + newLocal;
  }

  clickInfo:any
  handleDateClick(info: any) {
    this.clickedDate = info.dateStr
    this.clickInfo = info
    //Avoid selecting prev and next month days visiable on current month
    if (!info.dayEl.classList.contains('fc-day-other')) {
      //remove selected date if any
      if (document.getElementsByClassName("selected-day")[0]) {
        let selectedDate = document.getElementsByClassName("selected-day")[0];
        selectedDate.classList.remove('selected-day');
      }
      // if (info.dayEl.classList.contains('fc-day-other') != true) {
      info.dayEl.classList.add("selected-day");
      // }

    }
    // access member variables and functions using `this` keyword
    if (this.from == 'HR' ||this.from == 'team' ) {
      let data = [
        { 'clickedDate': this.clickedDate },
        { 'shiftFrom': this.attendanceDataArray[this.clickedDate]?.shift.from_time },
        { 'shiftTo': this.attendanceDataArray[this.clickedDate]?.shift.to_time },
        { 'shiftCode': this.attendanceDataArray[this.clickedDate]?.shift.shift_code },
        { 'FirstIn': this.attendanceDataArray[this.clickedDate]?.checkIn },
        { 'LastOut': this.attendanceDataArray[this.clickedDate]?.checkOut },
        { 'status_firstHalf': this.attendanceDataArray[this.clickedDate]?.status_firstHalf },
        { 'status_secondHalf': this.attendanceDataArray[this.clickedDate]?.status_secondHalf },
        { 'override_status': this.attendanceDataArray[this.clickedDate]?.override_status }
      ]
      this.addPunchAction.emit(data);
      // this.activateAddPunch();
    }
  }
  savePnch:boolean = false
  getCalendarData(savePnch = false) {
    this.savePnch =savePnch
    if (this.activeMonth != null && this.activeYear != null) {
      this.attendenceCalenderService.getCalendarData(this.isEmployeeId, this.activeMonth, this.activeYear,this.from).subscribe((res: any) => {
        this.loader = false
        this.calData = res?.ouput_data
        this.refreshCalendar(res?.ouput_data)
      })
    }
  }
  refreshCalendar(data: any) {
    const monthsNums: any = { January: '01', February: '02', March: '03', April: '04', May: '05', June: '06', July: '07', August: '08', September: '09', October: '10', November: '11', December: '12' }
    let calendarData: any = []

    data?.forEach((value: any, key: any) => {
      var status = "unknown"
      var type = ""
      // if(value.date > this.empDoj)
      //   status=""
      // else
      if (value.status_firstHalf == "Holiday" && value.status_secondHalf == "Holiday")
        status = "status-holiday"
      else if (value.status_firstHalf == "Present" && value.status_secondHalf == "Present")
        status = "status-present"
      else if (value.status_firstHalf == "Leave" && value.status_secondHalf == "Leave")
        status = "status-leave"
      else if (value.status_firstHalf == "Weekoff" && value.status_secondHalf == "Weekoff")
        status = "status-weekoff"
      else if (value.status_firstHalf == "Absent" && value.status_secondHalf == "Absent")
        status = "status-absent"

      else if (value.status_firstHalf == "Present" && value.status_secondHalf == "Leave")
        status = "status-present-leave"
      else if (value.status_firstHalf == "Present" && value.status_secondHalf == "Weekoff")
        status = "status-present-weekoff"
      else if (value.status_firstHalf == "Present" && value.status_secondHalf == "Absent")
        status = "status-present-absent"
      else if (value.status_firstHalf == "Present" && value.status_secondHalf == "Holiday")
        status = "status-present-holiday"

      else if (value.status_firstHalf == "Leave" && value.status_secondHalf == "Present")
        status = "status-leave-present"
      else if (value.status_firstHalf == "Leave" && value.status_secondHalf == "Weekoff")
        status = "status-leave-weekoff"
      else if (value.status_firstHalf == "Leave" && value.status_secondHalf == "Absent")
        status = "status-leave-absent"
      else if (value.status_firstHalf == "Leave" && value.status_secondHalf == "Holiday")
        status = "status-leave-holiday"

      else if (value.status_firstHalf == "Weekoff" && value.status_secondHalf == "Present")
        status = "status-weekoff-present"
      else if (value.status_firstHalf == "Weekoff" && value.status_secondHalf == "Leave")
        status = "status-weekoff-leave"
      else if (value.status_firstHalf == "Weekoff" && (value.status_secondHalf == "Absent" ))
        status = "status-weekoff-absent"
      else if (value.status_firstHalf == "Weekoff" && value.status_secondHalf == "Holiday")
        status = "status-weekoff-holiday"

      else if (value.status_firstHalf == "Absent" && value.status_secondHalf == "Present")
        status = "status-absent-present"
      else if (value.status_firstHalf == "Absent" && value.status_secondHalf == "Leave")
        status = "status-absent-leave"
      else if ((value.status_firstHalf == "Absent") && value.status_secondHalf == "Weekoff")
        status = "status-absent-weekoff"
      else if (value.status_firstHalf == "Absent" && value.status_secondHalf == "Holiday")
        status = "status-absent-holiday"


      else if (value.status_firstHalf == "Holiday" && value.status_secondHalf == "Present")
        status = "status-holiday-present"
      else if (value.status_firstHalf == "Holiday" && value.status_secondHalf == "Leave")
        status = "status-holiday-leave"
      else if (value.status_firstHalf == "Holiday" && value.status_secondHalf == "Weekoff")
        status = "status-holiday-weekoff"
      else if (value.status_firstHalf == "Holiday" && (value.status_secondHalf == "Absent"))
        status = "status-holiday-absent"

      else if (value.shift?.from_time == 0 && value.date >= this.empDoj)
        status = "status-shift-unassigned"
      else if (value.date > this.today && (value.status_secondHalf == "Weekoff" || value.status_firstHalf == "Weekoff"))
         status = "status-weekoff"
      else if (value.date > this.today)
        status = "status-upcoming"
      // else if (value.date < this.today && value.date >= this.empDoj)
      //   status = "status-absent"
      if(value.override_status)
        type = "attendanceoverride"
      else if (value.regularized_status)
        type = "regularized"
      else if (value.onduty_status)
        type = "on-duty"
      else if (value.wfh_status)
        type = "WFH"
      else if (value.is_deduction_applied)
        type = "deduction"
      else if (value.is_overrided)
        type = "punch"



      this.attendanceDataArray[value.date] = value
      this.attendanceDataArray[value.date].punchesArray = this.preparePunches(this.attendanceDataArray[value.date].punches)

      if (value.actualHour.toString().length == 7)
        value.actualHour = "0" + value.actualHour
      if (value.overTime.toString().length == 7)
        value.overTime = "0" + value.overTime
      calendarData.push({
        date: value.date,
        status: status,
        checkIn: value.checkIn != "None" && value.checkIn != undefined && this.appservice.isValidDateTime(value.date + " " + value.checkIn) ? this.datePipe.transform(value.date + " " + value.checkIn, this.appservice.gettimeformat())?.toString() : "",
        checkOut: value.checkOut != "None" && value.checkOut != undefined && this.appservice.isValidDateTime(value.date + " " + value.checkOut) ? this.datePipe.transform(value.date + " " + value.checkOut, this.appservice.gettimeformat())?.toString() : "",
        workHour: value.actualHour != "None" && value.actualHour != undefined && this.appservice.isValidDateTime(value.date + " " + value.actualHour) ? this.datePipe.transform(value.date + " " + value.actualHour, 'HH:mm') : "",
        type: type,
        late_coming_status: value.late_coming_status ? value.late_coming_status : false,
        early_checkout_status:value.early_checkout_status?value.early_checkout_status:false,
        overTime: value.overTime != "None" && value.overTime != undefined && this.appservice.isValidDateTime(value.date + " " + value.overTime) ? this.datePipe.transform(value.date + " " + value.overTime, 'HH:mm') : "",
        overtime_status: value.overtime_status,
        short_leave_status:value.short_leave_status,
      })


    })
    if (document.getElementsByClassName("selected-day")[0]) {
      let selectedDate = document.getElementsByClassName("selected-day")[0];
      selectedDate.classList.remove('selected-day');
    }

    if (this.count == 1) {
      this.calendarOptions = this.initCalendar(calendarData);
      this.afterCalendar()


    } else {
      let calendarApi = this.calendarComponent?.getApi();
      let mnth = monthsNums[this.activeMonth]
      let monthStart = this.activeYear + "-" + mnth + "-01"
      this.clickedDate = monthStart;
      calendarApi?.setOption('events', calendarData)
      calendarApi?.gotoDate(monthStart);
    }
    if(this.delPunch || this.savePnch){
      this.delPunch = this.savePnch = false
      this.handleDateClick(this.clickInfo)
      let calendarApi = this.calendarComponent?.getApi();
      let monthStartw = this.clickInfo.dateStr
      calendarApi?.select( monthStartw, [] )
    }

  }
  preparePunches(punches: any) {
    let accepted: any = []
    let pending: any = []
    punches?.forEach((val: any) => {
      val.remarktext = (val.remark != null || val.remark != '') && val.created_by_employee_code!=null? "On "+this.appservice.dateFormatDisplay(val.created_date)+" Added by: "+val.created_by__first_name+" ("+val.created_by_employee_code+") ":  (val.remark != null || val.remark != '') && val.created_by_employee_code==null? "On "+this.appservice.dateFormatDisplay(val.created_date)+" Added by: "+val.created_by__first_name:null
      if (val.is_approval_required == true && val.is_approval_status == 'Pending') {
        pending.push(val)
      } else {
        accepted.push(val)
      }
    });
    return { "accepted": accepted, "pending": pending }
  }

  deletePunchConfirm(id: any) {
    this.deleteToggle = true;
    this.punchId = id;
    this.deleteClick = false;

  }
  delPunch:boolean  = false
  deletePunchData() {
    this.delPunch = true
    this.deleteClick = true;
    this.attendanceDashboardService.deletePunch({ 'punch_data_id': this.punchId},this.fromoverride).subscribe((res: any) => {
      if (this.calendarComponent) {
        let calendarApi = this.calendarComponent.getApi();
        this.changeMonthYear.emit(calendarApi.view.currentStart);
      }

      this.deleteToggle = false;
      this.notificationService.handleSuccessNotification("Employee's punch data deleted successfully", "Success");
      this.deleteClick = false;
      this.getCalendarData();

    })
  }
  clearTime(timePicker: any) {
    timePicker.value = null;
    timePicker.close();
  }
  viewemployee(punchTime:any){
    let data ={'clickedDate': this.clickedDate,'punchData':punchTime}
    this.viewempmap.emit(data);
  }
}
