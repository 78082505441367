import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }


  getorgdata(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/organization-update/${id}/`, this.httpOption)
  }
  createorgdata(data:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/organization-create/`,data, this.httpOption)
  }
  getorgdataid(){
    return this.http.get(`${this.API_PATH}organization/api/v1/organization-create/`, this.httpOption)
  }
  updateorgdata(data:any,id:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/organization-update/${id}/`,data, this.httpOption)
  }
  updatelogindata(data:any,id:any) {
    return this.http.put(`${this.API_PATH}organization/api/v1/customize-login-save/${id}/`,data, this.httpOption)
  }
  getlogindata() {
    return this.http.get(`${this.API_PATH}organization/api/v1/customize-login-save/0/` , this.httpOption)
  }
  updatemaildata(data:any,id:any) {
    return this.http.put(`${this.API_PATH}general_settings/api/v1/email-config-save/${id}/`,data, this.httpOption)
  }
  getemaildata() {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/email-config-save/0/` , this.httpOption)
  }
  getloginpagedata() {
    return this.http.get(`${this.API_PATH}organization/api/v1/customize-login/` , this.httpOption)
  }
  getorghierarchydata(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/organization-hierarchy/?employee_id=${id}` , this.httpOption)
  }
  getorghierarchydatadown(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/organization-hierarchy/?employee_id=${id}&level=down` , this.httpOption)
  }
  getorghierarchydataup(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/organization-hierarchy/?employee_id=${id}&level=up` , this.httpOption)
  }
  getorghierarchydataall(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/organization-hierarchy/?employee_id=${id}&level=all&node_depth=4` , this.httpOption)
  }
  getorghierarchydatacollapse(id:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/organization-hierarchy/?employee_id=${id}&level=all&node_depth=1` , this.httpOption)
  }
  empDDList(isactive:any,ordering:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list-all/?ordering=${ordering}&is_active=true&employment_status=["Confirmed","Probation","Resigned"]`, this.httpOption);
  }
}
