<div class="side-panel side-pane-active" style="--sidepanel-width:50rem;"
  [class.side-pane-active]='viewSidePanel == true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Import Loan Details</h5>
      <button class="btn-reset text-light-600" (click)="closeSidePanel()">
        <i class="icon-close-lg fs-16"></i></button>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24">
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="row row-24">
                <div class="col-12">
                  <span class="title">Import Loan Details</span><br>
                </div>
                <div class="row" *ngIf="fileName">
                  <div class="col-md-6">
                    <div class="card card-c2  bg-secondary">
                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                          <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                          <span class="tips-helper ">{{fileName}}</span>
                        </div>
                        <i class="icon-trash  ms-auto flex-center link-danger" (click)="clearData()"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                  class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                  <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                    (change)="onFileChange($event)" id="upload" hidden>
                  Select File to Upload
                </label>
                <span class="invalid-feedback"
                  *ngIf="invalidFormat">{{this.messageServ.validateFileFormat('xlsx/csv')}}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="row row-16">
                <div class="col-12">
                  <span class="title">Instructions</span>
                </div>
                <div class="col-12 ">
                  <ol class="fs-14 ps-16 mb-0 li-24">
                    <li>Download the <a class="link-primary" href="assets/docs/sample-loan-details-import.xlsx"> <i
                          class="icon-download me-1"></i> Sample Excel template</a></li>
                    <li>Enter or update the loan details as per the format provided in the excel sheet.</li>
                    <li>Save the template.</li>
                    <li>To import the file, Click the "Import Loan Details" button.</li>
                    <li>Select the file to be imported.</li>
                    <li>Click on "Upload" button below.</li>
                    <li>The file should be in(.xls, .csv) format only.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
        (click)="uploadLoanData()">Upload</button>
    </footer>
  </div>
</div>
