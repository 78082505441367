import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-fields-permissions',
  templateUrl: './fields-permissions.component.html',
  styleUrls: ['./fields-permissions.component.scss']
})
export class FieldsPermissionsComponent implements OnInit {
  activeTab = 1;
  permissions : any;

  constructor(
    public appService: AppService

  ) { }

  ngOnInit(): void {
    this.getPermission();

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
