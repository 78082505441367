<div class="container-fluid p-24 ">
  <div class="row row-24">
    <ng-container *ngIf="!loader">
      <div class="col " style="max-width: 20.625rem;">
        <div class="card card-c2 p-0 sticky-profile-card">
          <div
            class="card-body p-16 position-relative overflow-hidden flex-column d-flex align-items-center text-center">
            <div class="profile-card-bg {{personalData.color_code}}"></div>
            <a *ngIf="routingUrl != '/my-team-dashboard'" class="text-white qr-link px-16 py-8 fw-500 fs-14"
              (click)="qrModal=true"> <i class="icon-qr-code me-1"></i>Scan QR</a>
            <label class="profile-img-wrapper mb-12 bg-white {{routingUrl == '/my-team-dashboard' ? 'pointer-event' : ''}}" (click)="addProfileModal=true">
              <img class="profile-img" *ngIf="((imageUrl!=null) &&  cropped)" [src]="cropped">
              <img class="profile-img" *ngIf="((imageUrl!=null) && !cropped)" [src]="imageUrl">
              <div class="profile-img" *ngIf="imageUrl ==  null">
                {{this.appservice.getFirstChar(personalData.user.first_name+" "+personalData.user.last_name,2)}}
              </div>
              <!-- <app-file-upload [crop]="true" [round]="false" [width]="504" (dataURL)="getURL($event)" [id]="inputId"
                [FileType]="'.png,.jpeg,.jpg'" (fileformat)="getfileformat($event)"
                (validsize)="getvalidsize($event)"></app-file-upload> -->
            </label>

            <div class="fs-20 fw-600 w-100 text-trim" *ngIf="personalData.user">
              {{personalData.user.first_name+" "+(personalData.middle_name!=null?personalData.middle_name:" ")+ "
              "+personalData.user.last_name}}
            </div>
            <div class="fs-14 fw-600 w-100 text-trim text-light-500 mb-12" *ngIf="personalData.designation">
              {{personalData.designation.name}}
            </div>
            <div class="fs-14 fw-600 w-100 text-trim text-light-400" *ngIf="personalData.employee_code">EMP CODE:
              {{personalData.employee_code}}</div>
            <div class="fs-14 fw-600 w-100 text-trim text-light-400" *ngIf="personalData.date_of_joined">DOJ:
              {{(personalData.date_of_joined!='' && personalData.date_of_joined!=null &&
              personalData.date_of_joined!=='None' &&
              personalData.date_of_joined!=='none')?this.dateFormatDisplay(this.personalData.date_of_joined):"-"}}</div>
          </div>
          <div class="card-body p-0">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class=" vetical-tab tab-scroll">
              <li *ngIf="getTabPermission('snapshot')" [ngbNavItem]="1">
                <a ngbNavLink class="tab-link ">Snapshot</a>
                <ng-template ngbNavContent>
                  <app-snapshot [employeeData]="personalData" [gradeDD]="gradeDD" [BranchDD]="BranchDD"
                    [DesignationDD]="DesignationDD" [DepDD]="DepDD" [buDD]="buDD" [companyDD]="companyDD"
                    [empDD]="empDD" [id]="id" [loginAs]="loginAs" (submitSnapshotData)="submitSnapshotData($event)"
                    [codeError]="codeError" [mailError]="mailError" [phnError]="phnError"
                    [showSidePanel1]="showSidePanel" [from]="from" [fieldPermission]="tabPermissionData?.snapshot"
                    (SnapshotData)="SnapshotData()"  [routingUrl]="routingUrl"
                    [addEditPermission]="addEditPermission"></app-snapshot>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('professional')" [ngbNavItem]="2">
                <a ngbNavLink class="tab-link">Professionals</a>
                <ng-template ngbNavContent>
                  <app-professionals [professionalData]="professionalData" [id]="id"
                    [is_employeeRequest]="is_employeeRequest" (createProfessional)="submitProfessionalData($event)"
                    (deleteProfessional)="deleteProfessionalSubmit($event)" (loadData)="getPersonalData()"
                    [loginAs]="loginAs" [routingUrl]="routingUrl" [from]="from"
                    [fieldPermission]="tabPermissionData?.professional"
                    [addEditPermission]="addEditPermission"></app-professionals>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('education')" [ngbNavItem]="3">
                <a ngbNavLink class="tab-link">Education</a>
                <ng-template ngbNavContent>
                  <app-education [educationData]="educationData" [id]="id" [is_employeeRequest]="is_employeeRequest"
                    (createEducationSubmit)="submitEducationalData($event)"
                    (deleteEducationSubmit)="deleteEducationalSubmit($event)" (loadData)="getPersonalData()"
                    [routingUrl]="routingUrl" [loginAs]="loginAs" [from]="from"
                    [fieldPermission]="tabPermissionData?.education"
                    [addEditPermission]="addEditPermission"></app-education>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('personal')" [ngbNavItem]="4">
                <a ngbNavLink class="tab-link">Personal</a>
                <ng-template ngbNavContent>
                  <app-personal [personalData]="personalDataArr" [city_list]="city_list"
                    [permanent_city]="permanent_city" [country_list]="country_list"
                    [current_state_list]="current_state_list" [permenent_state_list]="permenent_state_list" [id]="id"
                    (loadData)="getPersonalData()" [loginAs]="loginAs" [routingUrl]="routingUrl"
                    [is_employeeRequest]="is_employeeRequest" [from]="from"
                    [fieldPermission]="tabPermissionData?.personal"
                    [addEditPermission]="addEditPermission"></app-personal>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('family')" [ngbNavItem]="5">
                <a ngbNavLink class="tab-link">Family</a>
                <ng-template ngbNavContent>
                  <app-family [familyData]="familyData" [id]="id" [is_employeeRequest]="is_employeeRequest"
                    (loadData)="getPersonalData()" [loginAs]="loginAs" [routingUrl]="routingUrl" [from]="from"
                    [fieldPermission]="tabPermissionData?.family" (saveOrUpdateFamily)="saveOrUpdateFamily($event)"
                    [addEditPermission]="addEditPermission"></app-family>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('health_insurance')" [ngbNavItem]="6">
                <a ngbNavLink class="tab-link">Health Insurance</a>
                <ng-template ngbNavContent>
                  <app-health-insurance [insuranceData]="insuranceData" [id]="id"
                    [is_employeeRequest]="is_employeeRequest" (insuranceSubmit)="submitInsuranceData($event)"
                    (deleteInsuranceSubmit)="deleteInsuranceSubmit($event)" (loadData)="getPersonalData()"
                    [routingUrl]="routingUrl" [loginAs]="loginAs" [from]="from"
                    [fieldPermission]="tabPermissionData?.health_insurance"
                    [addEditPermission]="addEditPermission"></app-health-insurance>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('previous_employer_detail')" [ngbNavItem]="7">
                <a ngbNavLink class="tab-link">Previous Employer</a>
                <ng-template ngbNavContent>
                  <app-previous-employer [employerData]="employerData" [id]="id"
                    [is_employeeRequest]="is_employeeRequest" (employerSubmit)="submitEmployerData($event)"
                    (loadData)="getPersonalData()" [routingUrl]="routingUrl" [currency]="currency" [loginAs]="loginAs"
                    [from]="from" [fieldPermission]="tabPermissionData?.previous_employer_detail"
                    [addEditPermission]="addEditPermission"></app-previous-employer>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('bank_detail')" [ngbNavItem]="8">
                <a ngbNavLink class="tab-link">Bank Details</a>
                <ng-template ngbNavContent>
                  <app-bank-details [bankData]="bankData" [id]="id" [is_employeeRequest]="is_employeeRequest"
                    (submitmethod)="submitBankDetails($event)" (loadData)="getPersonalData()" [routingUrl]="routingUrl"
                    [loginAs]="loginAs" [from]="from" [fieldPermission]="tabPermissionData?.bank_detail"
                    [addEditPermission]="addEditPermission"></app-bank-details>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('employee_document')" [ngbNavItem]="9">
                <a ngbNavLink class="tab-link">Documents</a>
                <ng-template ngbNavContent>
                  <app-documents [id]="id" [loginAs]="loginAs"  [createdBy]="createdBy"
                    [is_employeeRequest]="is_employeeRequest"  [routingUrl]="routingUrl" [from]="from"
                    [fieldPermission]="tabPermissionData?.employee_document"
                    [addEditPermission]="addEditPermission"></app-documents>
                </ng-template>
              </li>
              <li *ngIf="getTabPermission('geo_locations')" [ngbNavItem]="10">
                <a ngbNavLink class="tab-link">Geo Locations</a>
                <ng-template ngbNavContent>
                  <app-profile-geo-location [id]="id" [routingUrl]="routingUrl" [loginAs]="loginAs"
                    [empLocationData]="empLocationData" [from1]="from" [fieldPermission]="tabPermissionData?.geo_locations"
                    [addEditPermission]="addEditPermission" (assignLocation)="assignLocation($event)"
                    (deleteLocation)="deleteLocation($event)"></app-profile-geo-location>
                </ng-template>
              </li>
            </ul>
          </div>
          <!-- <div class="stick-block ">
                    <div class="card p-0 shadow-0 radius-4 profile-card-border" > -->
          <!-- <div class="card-body p-0 position-relative" style="height: 17.125rem;">
                          <div class="position-absolute w-100 pe-16 pt-8 text-end" style="z-index: 1; ">
                            <a class="text-white fw-500 fs-14" (click)="qrModal=true"> <i class="bi bi-qr-code me-1"></i>Scan QR</a>

                          </div>
                            <div class="profile-card text-center px-16 ">
                              <label class="hoverable is-invalid" for="fileInput">
                                <img  class="img-fluid rounded-circle profile-pic " *ngIf="(( imageUrl!=null) && cropped)" [src]="cropped">

                                <img *ngIf="((imageUrl!=null) && !cropped)" class="img-fluid rounded-circle profile-pic "  [src]="imageUrl" alt="">
                                <div *ngIf="imageUrl ==  null" class="avatar-circle  profile-pic fs-25 bg-white avatar-border">{{this.appservice.getFirstChar(personalData.user.first_name+" "+personalData.user.last_name,2)}}</div>

                                <div class="hover-text"><i class="bi bi-pencil-fill fs-18"></i></div>
                                <div class="background"></div>
                                <app-file-upload [crop]="true" [round]="false" [width]="504" (dataURL)="getURL($event)" [id]="inputId" [FileType]="'.png,.jpeg,.jpg'" (fileformat)="getfileformat($event)" (validsize)="getvalidsize($event)"></app-file-upload>
                              </label>


                                <h5 class="fw-600 mb-8 text-trim-2" *ngIf="personalData.user">{{personalData.user.first_name+" "+(personalData.middle_name!=null?personalData.middle_name:" ")+ " "+personalData.user.last_name}}</h5>
                                <h6 class="mb-16 text-trim" *ngIf="personalData.designation">{{personalData.designation.name}}</h6>
                                <h6 class="mb-8 text-light-400" *ngIf="personalData.employee_code">EMP CODE: {{personalData.employee_code}}</h6>
                                <h6 class="mb-8 mb-24 text-light-400" *ngIf="personalData.date_of_joined">DOJ: {{(personalData.date_of_joined!='' && personalData.date_of_joined!=null && personalData.date_of_joined!=='None' && personalData.date_of_joined!=='none')?this.dateFormatDisplay(this.personalData.date_of_joined):"-"}}</h6>
                            </div>
                        </div> -->
          <!-- <div class="card-body px-0 ">

                        </div> -->
          <!-- </div>
                </div> -->
        </div>
      </div>
      <div class="col ">
        <!-- <router-outlet></router-outlet> -->
        <div [ngbNavOutlet]="nav"></div>
      </div>

    </ng-container>

    <app-profile-right-loader *ngIf="loader"></app-profile-right-loader>

  </div>
</div>

<!-- QR Modal -->
<div class="modal modal-custom  qr-modal {{qrModal== true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content radius-4">
      <div class="modal-header  px-24  gap-14">
        <h4 class="modal-title">QR code login</h4>
        <i (click)="close()" class="ms-auto bi b bi-x-lg text-light-500 cursor-pointer"></i>
      </div>
      <div class="modal-body p-24">
        <div class="card card-c2 d-flex align-items-center px-16" style="border-color:#80E0B4;">
          <qrcode #parent [qrdata]="qrdata" [width]="300" [errorCorrectionLevel]="'M'" [allowEmptyString]="true">
          </qrcode>
          <label class="text-light-500 fw-500 pb-32">Scan here</label>
        </div>
        <div class="fs-18 pt-24 fw-500">Sign in to SkizzleHR on mobile</div>
        <span class="text-light-400 fs-12">To access this workspace on your phone</span>
        <ol class="ol-type-1 li-12 mt-24 fs-12">
          <li>Open <span class="fw-500"> SkizzleHR </span> app on your mobile device.</li>
          <li>Select <span class="fw-500"> Login </span> with <span class="fw-500"> QR code </span> option.</li>
          <li>Enter <span class="fw-500"> password </span> after scanning the QR code.</li>
        </ol>

      </div>
    </div>
  </div>
</div>
<!-- <div class="modal modal-custom {{qrModal== true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-385">
    <div class="modal-content rounded-0">
      <div class="modal-header gap-14" style="border-bottom: 1px solid #E4E8EA;">
        <h4 class="modal-title">QR code login</h4>
        <i (click)="close()" class="ms-auto bi b bi-x-lg text-light-500 cursor-pointer"></i>
      </div>
      <div class="modal-body px-3">
        <div class="container">
          <div class="border text-center rounded" style="color: #80E0B4;">
            <qrcode #parent [qrdata]="qrdata" [width]="256" [errorCorrectionLevel]="'M'" class="pb-0"></qrcode>
            <label class="fs-16 fw-500 pt-0 pb-4" style="color:#566976;">Scan here</label>
          </div>
          <h6 class="fw-500 fs-18 mt-4 mb-0">Sign in to SkizzleHR on mobile</h6>
          <span class="fs-12 fw-400 mt-0" style="color: #7D92A1">To access this workspace on your phone</span>
          <div class="d-flex align-items-center mt-2">
            <div class="fs-14 fw-500 avatar-circle text-center sq-20 text-white" style="background-color: #80E0B4">1</div>
            <div class="fs-12 fw-400 ms-2" style="color: #324552;">Open <span class="fw-500">SkizzleHR</span> app on your mobile device.</div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="fs-14 fw-500 avatar-circle text-center sq-20 text-white" style="background-color: #80E0B4">2</div>
            <div class="fs-12 fw-400 ms-2" style="color: #324552;">Select <span class="fw-500">Login</span> with QR code option.</div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="fs-14 fw-500 avatar-circle text-center sq-20 text-white" style="background-color: #80E0B4">3</div>
            <div class="fs-12 fw-400 ms-2" style="color: #324552;">Enter <span class="fw-500">password</span> after scanning the QR code.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-profile-pic [addProfileModal]="addProfileModal" [loginAs]="loginAs" [empId]="id"
  [text]="imageUrl==null?'Add':'Edit'" [imageUrl]="imageUrl" (close)="addProfileModal=false"
  (pic)="updateImage($event)"></app-profile-pic>
