import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AttendanceProcessDashboardService } from 'src/app/@core/services/attendance-process-dashboard.service';import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-attendance-reminder',
  templateUrl: './attendance-reminder.component.html',
  styleUrls: ['./attendance-reminder.component.scss']
})
export class AttendanceReminderComponent implements OnInit {
  showSidePanel = false;
  selectedList:any = [];
  selectedName="";
  reminders:any = []
  company:any;
  infoClosed = false;
  year:any;
  month:any;
  months:any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }
  loader =true;
  constructor(public route:ActivatedRoute,private apds:AttendanceProcessDashboardService,public appService: AppService,) { }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })

    var dataArray = {
      "year": Number(this.year),
      "month": this.month
   };
    this.apds.getattendanceSummary( Number(this.year),this.company,this.months[this.month]).subscribe((res:any)=>{
      this.createRemiderArray( res.body?.data?.alert_reminders?.reminders_data)
    });
    
  }
  createRemiderArray(data:any){
    if(data.leave_pending_count != undefined){
      this.reminders.push({
        reminder:"Pending Leave Request",
        list:data?.leave_pending_emp_data,
        listcount:data.leave_pending_count,
        class:this.getRandomClass()

      })

    }
    if(data.on_duty_pending_count != undefined){
      this.reminders.push({
        reminder:"Pending On duty request",
        list:data?.on_duty_pending_emp_data,
        listcount:data.on_duty_pending_count,
        class:this.getRandomClass()
      })
    }
    if(data.regularization_pending_count != undefined){
      this.reminders.push({
        reminder:"Pending regularization request",
        list:data?.regularization_pending_emp_data,
        listcount:data.regularization_pending_count,
        class:this.getRandomClass()
      })
    }
    if(data.shift_not_mapped != undefined){
      this.reminders.push({
        reminder:"Employees shift not mapped",
        list:data?.shift_not_mapped_emp_data,
        listcount:data.shift_not_mapped,
        class:this.getRandomClass()
      })
    }
    if(data.wfh_pending_count != undefined){
      this.reminders.push({
        reminder:"Pending WFH request",
        list:data?.wfh_pending_emp_data,
        listcount:data.wfh_pending_count,
        class:this.getRandomClass()
      })
    }
    
    this.loader = false;   

  }

  getRandomClass(){
    const classes = ["highlight1", "highlight2", "highlight3", "highlight-primary"];
    const random = Math.floor(Math.random() * classes.length);
    return classes[random];
  }

  closePanel(val:any){
    this.showSidePanel = val
  }
}
