<form action="" class=""  [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container">
      <div class="row ">
        <p class="text-uppercase fw-500 ">Tax Details</p>

      </div>
      <div class="row  mb-24" >
        <div class="col-lg-6 form-row">
          <label for="tax_configuration" class="form-label required">Tax configuration </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="You can define whether the paycomponent is taxable, non taxable or partialy taxable. Most of the predefined components settings cannot be changed.
            Taxable: Whatever amount is paid it will be completely taxable, eg: Basic.
            Non taxable: All the deduction components are non taxable eg: Loan
            Partially taxable: There will be a non taxable limit for these paycomponents, if anything exceeds the limit the excess amount will become taxable eg: Child education allowance." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          <app-master-select [ngClass]="{ 'is-invalid': (taxSubmit || f.tax_configuration.touched) && f.tax_configuration.errors }" [placeholder]="taxConfigPlaceHolder" id="tax_configuration" [submitted]="taxSubmit" [isdisabled]="tax_config_disable" [selectedItem]="f.tax_configuration.value" formControlName="tax_configuration" [type]="taxConfig" [isrequired]="isrequired" (ngModelChange)="setTaxableLimit()"></app-master-select>
          <div *ngIf="(taxSubmit || f.tax_configuration.touched) && f.tax_configuration.errors" class="invalid-feedback">
            <div *ngIf="f.tax_configuration.errors.required">{{this.messageService.validationDisplay('required')}}</div>
        </div>
        </div>
      </div>
      <div class="row  mb-24 " *ngIf="istaxable_limit && activeLimit">
        <div class="col-lg-6 form-row">
            <label for="non_taxable_limit" class="form-label required">Non-taxable limit </label>
            <input type="text"  class="form-control" autocomplete="off" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('non-taxable limit')}}" formControlName="non_taxable_limit" [ngClass]="{ 'is-invalid': (submitted || f.non_taxable_limit.touched || f.non_taxable_limit.dirty) &&  (f.non_taxable_limit.errors )}">
            <div *ngIf="(submitted || f.non_taxable_limit.touched || f.non_taxable_limit.dirty) &&  (f.non_taxable_limit.errors)" class="invalid-feedback">

              <div *ngIf="f.non_taxable_limit.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="f.non_taxable_limit.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="!(f.non_taxable_limit.errors?.pattern) && f.non_taxable_limit.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>

            </div>
        </div>
      </div>
      <div class="row mb-24  row-8" *ngIf="!taxrestricted && iscomputation && formGp.getRawValue().tax_configuration!=='Non-Taxable'">
        <div class="col-12 form-row">
          <label for="" class="form-label required">Tax computation</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Tax on regular salary components is usually computed proportionally, system will calculate the projected tax for the financial year and it will equally be divided for remaining months. Tax for adhoc variables should be deducted in the pay month or the same month the variable get paid." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-lg-6 form-row">

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="tax_computation" id="flexRadioDefault1" [value]="true">
              <label class="form-check-label" for="flexRadioDefault1">
                Proportionally
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="tax_computation" id="flexRadioDefault2"  [value]="false">
              <label class="form-check-label" for="flexRadioDefault2">
                Pay month
              </label>
            </div>
        </div>
      </div>
      <div class="row  mb-24 " *ngIf="f.under_section_income.value">
        <div class="col-lg-6 form-row">
            <label for="under_section_income" class="form-label required">Map income under this section </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="specify to which section the income needs to be mapped." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <app-master-select [ngClass]="{ 'is-invalid': (sectionSubmit || f.under_section_income.touched) && f.under_section_income.errors }" [placeholder]="incomePlaceHolder" id="under_section_income" [submitted]="sectionSubmit" [selectedItem]="f.under_section_income.value" formControlName="under_section_income" [type]="incomeSection" [isrequired]="isrequired" (click)="sectionChange()" [isdisabled]='sectionIncomeDisable' ></app-master-select>
            <div *ngIf="(sectionSubmit || f.under_section_income.touched) && f.under_section_income.errors" class="invalid-feedback">
              <div *ngIf="f.under_section_income.errors.required">{{this.messageService.validationDisplay('required')}}</div>
          </div>
        </div>
      </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid && !formGp.disabled) || saveClicked" (click)="validateTax()">SAVE</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase" disabled (click)="validateTax()">SAVE</button>

  </footer>
</form>

