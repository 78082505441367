
<div class="container-fluid p-32 form-container"  [formGroup]="formGp">
    <div class="row ">
        <p class="text-uppercase fw-500">ALLOCATE WORKFLOW</p>
    </div>
    <div class="row row-16">
        <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">Select module </label>

            <ng-select formControlName="module" class="form-ngselect"  [ngClass]="{ 'is-invalid': (submitted || f.module.touched) &&  f.module.errors}" placeholder="{{this.messageService.selectplaceholddisplay('module')}}" (change)="getWorkflowList()">
                <ng-option *ngFor="let module of moduleDropdown" [value]="module.id">{{module.name}}</ng-option>
            </ng-select>
            <div *ngIf="(submitted || f.module.touched) && f.module.errors" class="invalid-feedback"><div *ngIf="f.module.errors.required">{{this.messageService.validationDisplay('required')}}
            </div></div>
        </div>
    </div>
    <div class="row row-16 pt-16">
        <div class="col-12">
            <label for="" class="form-label required">Flow Type</label>
        </div>
        <div class="col-lg-12  mt-0">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="radio" formControlName="is_cancel" [value]=false (change)="getWorkflowList()">
                    New
                </label>
            
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="radio"  formControlName="is_cancel" [value]=true (change)="getWorkflowList()">
                    Cancellation
                </label>
            </div>
        </div>
    </div>
    <div class="row row-16 pt-16">
        <div class=" col-lg-6 form-row">
            <label for="" class="form-label required">Work flow </label>
            <ng-select
                [ngClass]="{ 'is-invalid': (submitted || f.workflow.touched) &&  f.workflow.errors}"
                placeholder="{{this.messageService.selectplaceholddisplay('workflow')}}"
                formControlName="workflow"
                [items]="workflowDropdown"
                [multiple]="true"
                groupBy="selectedAllGroup"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                bindLabel="name"
                [closeOnSelect]="false"
                bindValue="id"
                class="multiselect"
                >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                    {{ item.name }}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="formGp.value.workflow ">
                        <span class="ng-value-label">{{formGp.value.workflow.length }} Work flow selected</span>
                    </div>
                </ng-template>
            </ng-select>
            <div *ngIf="(submitted || f.workflow.touched) && f.workflow.errors" class="invalid-feedback">
              <div *ngIf="f.workflow.errors.required">{{this.messageService.validationDisplay('required')}}
              </div>
            </div>
        </div>
        <div class="col-12">
            <ul class="selected-values" *ngIf="formGp.value.workflow">
                <li class="ng-value" *ngFor="let item of formGp.value.workflow,let ki =index">
                    <ng-container *ngFor="let insideitem of workflowDropdown, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                            {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                    </ng-container>
                    <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(ki)" aria-hidden="true">×</span>

                </li>
            </ul>

        </div>
        
    </div>
</div>
<footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateAllocateFlow()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateAllocateFlow()">NEXT</button>

</footer>
