import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import { RegularizationRequestService } from 'src/app/@core/services/regularization-request.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-regularization-select-date',
  templateUrl: './regularization-select-date.component.html',
  styleUrls: ['./regularization-select-date.component.scss']
})
export class RegularizationSelectDateComponent implements OnInit {

  @Input() formGrp : any
  @Input() data    : any

  @Output() submitmethod     = new EventEmitter();

  @Input()empId:any;
  // this.authService.isEmployeeId;
  year                       = new Date().getFullYear();
  month                      = new Date().getMonth();
  dayCount         : any     = '00'
  eligibility      : any
  alertToggle      : any
  alertToggleModal : any
  selectedDate     : any     = new Date()
  alertMsg         : any
  alertMsgModal    : any
  disabled         : boolean = true
  loader           : boolean = false
  alertRegularizationArr : any     = []
  get_eligible_days: any
  today:any =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  public calendarOptions: CalendarOptions = this.initCalendar();
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

  overTimeDateArray: any     = [];
  compOffDateArray: any      = [];

  constructor(
    private regularizationRequestService: RegularizationRequestService,
    private authService: AuthService,
    private fb : FormBuilder,
    private appService : AppService,
    private datePipe:DatePipe
  ) { }
  ngAfterViewInit(): void {
    setTag();
    //setTag is function to add class to parent element to show tag
    function setTag() {
      var allTagDays = document.querySelectorAll(".leave,.week-off,.holiday,.absent,.half-day");
      for (var i = 0; i < allTagDays.length; i++) {
        if (allTagDays[i].classList.contains('leave')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('leave')
        } else if (allTagDays[i].classList.contains('week-off')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('week-off')
        } else if (allTagDays[i].classList.contains('holiday')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('holiday')
        } else if (allTagDays[i].classList.contains('absent')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('absent')
        } else if (allTagDays[i].classList.contains('half-day')) {
          allTagDays[i].closest('.fc-daygrid-day')?.classList.add('half-day')
        }
      }
    }
    //To trigger the setTag() on next and prev month button
    var elements = document.querySelectorAll(".fc-next-button, .fc-prev-button");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", () => {
        setTag();
        const calendarApi: any = this.calendarComponent.getApi();
        const currentDate = calendarApi.view.currentStart;
        this.month = currentDate.getMonth();
        this.year = currentDate.getFullYear();
        this.selectedDate = currentDate
        this.getCalendarData();
        if(this.formGrp.length!=0){
          this.checkCell()
        }
        if(this.alertRegularizationArr.length!=0){
          this.checkInvalidDate()
        }
      });
    }
  }

  checkCell(){
    let formData = this.formGrp.getRawValue()
    if(formData.length)
    setTimeout(()=>{
      var element = document.getElementsByClassName('fc-day') as HTMLCollection
      for (var i = 0; i < element.length; i++) {
        var day = element[i].getAttribute('data-date')
        for (var j = 0; j < formData.length; j++) {
          if (day == formData[j].regulized_date) {
            element[i].classList.add('selected-day')
              break;
          }
        }
      }
    })
  }

  checkInvalidDate(){
    setTimeout(()=>{
      var element = document.getElementsByClassName('fc-day') as HTMLCollection
      for (var i = 0; i < element.length; i++) {
        var day = element[i].getAttribute('data-date')
        for (var j = 0; j < this.alertRegularizationArr.length; j++) {
          if (day == this.alertRegularizationArr[j]) {
            element[i].classList.add('selected-day')
              break;
          }
        }
      }
    })
  }

  public ngOnInit(): void {
    // // Initial API Call
    // this.getCalendarData();
    // HACK Rerender the calendar and correctly display it
    setTimeout(() => { this.calendarOptions.footerToolbar = false; }, 300)
    let count = this.formGrp.getRawValue().length
    if(count  < 10){
      this.dayCount = '0'+count
    }else{
      this.dayCount = count
    }
    // Eligibility
    this.checkEligibility(this.empId)
    if(this.formGrp.length!=0){
      this.checkCell()
    }
    if(this.alertRegularizationArr.length!=0){
      this.checkInvalidDate()
    }
    // Assigning the values added on step 2
    if(this.data.value.regularization_request.length!=0){
      let datas = this.data.value.regularization_request
      datas.forEach((item:any,index:any)=>{
        this.formGrp.at(index).patchValue({
          'regulized_date': item?.regulized_date,
          'regulized_checkin_date' : item?.regulized_checkin_date,
          'regulized_checkin_time':item?.regulized_checkin_time == "" ?  "":item?.regulized_checkin_time,
          'regulized_checkout_time': item?.regulized_checkout_time == "" ? "" : item?.regulized_checkout_time,
          'regulized_checkout_date' : item?.regulized_checkout_date,
          'hours': item?.hours == "" ? "" : item?.hours,
          'reason' : item?.reason,
          'shift_mapping': (item?.shift_mapping == "null" ? null:item?.shift_mapping),
          'shift_name' :  (item?.shift_name == "null" ? null:item?.shift_name),

          'checkin_date': item?.regulized_date,
          'checkout_date': this.nightShiftCheckoutDate(item?.regulized_date),

          'allowed_checkin_date'  : item?.allowed_checkin_date,
          'allowed_checkin_time'  : item?.allowed_checkin_time,
          'allowed_checkout_date' : item?.allowed_checkout_date,
          'allowed_checkout_time' : item?.allowed_checkout_time,
        })
      })
    }
  }

   // Eligibility
   checkEligibility(id:number){
    // this.loader = true
    this.regularizationRequestService.getCheckEligibility(id).subscribe((res:any)=>{
      this.eligibility = res.data
      // this.loader = false
      this.getCalendarData();
    })
  }


  private initCalendar(): CalendarOptions {
    return {
      initialView: 'dayGridMonth',
      height: 'auto',
      stickyHeaderDates: false,
      headerToolbar: {
        start: 'prev',
        center: 'title',
        end: 'next'
      },
      dateClick: this.handleDateClick.bind(this)
    };
  }

  handleDateClick(dateClickEvent:any) {
    if (dateClickEvent.dayEl.classList.contains('fc-day-other') != true && dateClickEvent.dayEl.dataset?.shift_mapping != "null") {
      dateClickEvent.dayEl.classList.toggle("selected-day");
    }
    let range = this.setDateRange(this.get_eligible_days,this.eligibility?.is_backdated_attend_regular_allowed,this.eligibility?.is_future_attend_regular_allowed)
    for(let i=0;i<range.length;i++){
      if(range[i] == dateClickEvent.dayEl.dataset.date){
        if(dateClickEvent.dayEl.classList.contains('selected-day')){

          // Pending request, Overtime and Comp off validations start
          if(dateClickEvent?.dayEl?.dataset?.req_pending_status == "true"){
            this.showValidation('Pending');
            dateClickEvent.dayEl.classList.remove('selected-day')
          }else if(dateClickEvent?.dayEl?.dataset?.overtime_processed == "true"){
            this.overTimeDateArray?.push((dateClickEvent?.dayEl?.dataset?.date));
            this.showValidation('Overtime');
          }else if(dateClickEvent?.dayEl?.dataset?.compoff_raised == "true"){
            this.compOffDateArray?.push(dateClickEvent?.dayEl?.dataset?.date);
            this.showValidation('CompOff');
          }else if(dateClickEvent?.dayEl?.dataset?.arrear_valid == "false"){
            this.showValidation('Arrear');
            dateClickEvent.dayEl.classList.remove('selected-day')
          }
          // End

          else if(dateClickEvent.dayEl.dataset.shift_mapping !== "null" && dateClickEvent.dayEl.dataset.shift_mapping !== undefined){
            this.formGrp.push(
              this.fb.group({
                'regulized_date': dateClickEvent.dayEl.dataset?.date,
                'shift_name': dateClickEvent.dayEl.dataset?.shift_name,
                'regulized_checkin_time': dateClickEvent.dayEl.dataset?.starttime == '' ? dateClickEvent.dayEl.dataset?.shift_start_time : dateClickEvent.dayEl.dataset?.starttime,
                'regulized_checkout_time': dateClickEvent.dayEl.dataset?.endtime == '' ? dateClickEvent.dayEl.dataset?.shift_end_time : dateClickEvent.dayEl.dataset?.endtime,
                'regulized_checkin_date' : (dateClickEvent.dayEl.dataset?.original_checkin_date == '' || dateClickEvent.dayEl.dataset?.original_checkin_date == null || dateClickEvent.dayEl.dataset?.original_checkin_date == 'null') ? dateClickEvent.dayEl.dataset?.date : dateClickEvent.dayEl.dataset?.original_checkin_date,

                'regulized_checkout_date' : ((dateClickEvent.dayEl.dataset?.original_checkout_date == '' || dateClickEvent.dayEl.dataset?.original_checkout_date == null || dateClickEvent.dayEl.dataset?.original_checkout_date == 'null') && (dateClickEvent.dayEl.dataset?.shift_start_time > dateClickEvent.dayEl.dataset?.shift_end_time ))? this.nightShiftCheckoutDate(dateClickEvent.dayEl.dataset?.date) : ((dateClickEvent.dayEl.dataset?.original_checkout_date == '' || dateClickEvent.dayEl.dataset?.original_checkout_date == null || dateClickEvent.dayEl.dataset?.original_checkout_date == 'null') && (dateClickEvent.dayEl.dataset?.shift_start_time < dateClickEvent.dayEl.dataset?.shift_end_time ))? dateClickEvent.dayEl.dataset?.date : dateClickEvent.dayEl.dataset?.original_checkout_date,

                'hours': this.toHoursAndMinutes(dateClickEvent.dayEl.dataset?.time),
                'status': dateClickEvent.dayEl.dataset?.status,
                'reason' : '',
                'shift_mapping': dateClickEvent.dayEl.dataset?.shift_mapping,
                'shift_name_code' : dateClickEvent.dayEl.dataset?.shift_name+' ('+dateClickEvent.dayEl.dataset?.shift_code+')',

                'checkin_date': dateClickEvent.dayEl.dataset?.date,
                'checkout_date': this.nightShiftCheckoutDate(dateClickEvent.dayEl.dataset?.date),

                'allowed_checkin_date': dateClickEvent.dayEl.dataset?.allowed_checkin_date,
                'allowed_checkin_time': dateClickEvent.dayEl.dataset?.allowed_checkin_time,
                'allowed_checkout_date': dateClickEvent.dayEl.dataset?.allowed_checkout_date,
                'allowed_checkout_time': dateClickEvent.dayEl.dataset?.allowed_checkout_time,
              })
            )
          }else{
            dateClickEvent.dayEl.classList.remove('selected-day')
          }
        }else{
          let spec_date = dateClickEvent.dayEl.getAttribute('data-date')
          if(this.formGrp.value.findIndex((item:any) => item.regulized_date === spec_date) >=0){
           this.formGrp.removeAt(this.formGrp.value.findIndex((item:any) => item.regulized_date === spec_date))
          }

           // Overtime and Compoff validation date removal
           if(this.overTimeDateArray?.length > 0 && dateClickEvent?.dayEl?.dataset?.overtime_processed == "true"){
             const index = this.overTimeDateArray?.indexOf(dateClickEvent?.dayEl?.dataset?.date);
             if (index > -1) {
               this.overTimeDateArray?.splice(index, 1);
             }
             this.showValidation('Overtime');
           }

           if(this.compOffDateArray?.length > 0 && dateClickEvent?.dayEl?.dataset?.compoff_raised == "true"){
             const index = this.compOffDateArray?.indexOf(dateClickEvent?.dayEl?.dataset?.date);
             if (index > -1) {
               this.compOffDateArray?.splice(index, 1);
             }
             this.showValidation('Compoff');

           }
          //  End

        }
      }else{
        if(range.every((item:any)=> (item == dateClickEvent.dayEl.dataset.date) == false)){
          if(dateClickEvent.dayEl.classList.contains('selected-day')){
            if(dateClickEvent.dayEl.dataset.shift_mapping !== "null"){
              // this.alertRegularizationArr.push(dateClickEvent.dayEl.dataset.date)
              this.validationFn(dateClickEvent.dayEl.dataset.date)
              dateClickEvent.dayEl.classList.remove('selected-day')
            }
          }
          // else{
          //   const index = this.alertRegularizationArr?.indexOf(dateClickEvent?.dayEl?.dataset?.date);
          //   if (index > -1) {
          //     this.alertRegularizationArr?.splice(index, 1);
          //   }
          //   if(this.alertRegularizationArr.length == 0){
          //     this.removeValidation()
          //   }
          //  this.validationFn(this.alertRegularizationArr)
          // }
        }
      }
    }

    let count = this.formGrp.getRawValue().length
    if(count  < 10){
      this.dayCount = '0'+count
    }else{
      this.dayCount = count
    }
    if(this.overTimeDateArray?.length == 0 && this.compOffDateArray?.length == 0){
      this.removeValidation()
    }
  }

  // Validation
  validationFn(item:any){
    let hasPreviousDay = false;
    let hasUpcomingDay = false;

    const eligibleDate = this.eligibility?.is_backdated_attend_regular_allowed == false ? moment().format('YYYY-MM-DD') : this.get_eligible_days;

      if(moment(item).isBefore(moment(eligibleDate))){
        hasPreviousDay = true
        if(!this.eligibility?.is_backdated_attend_regular_allowed){
          this.showValidation("regulariseNoBackDated")
        }else{
          this.showValidation('regulariseBackDated');
        }
      }else{
        if(moment(item).isAfter(moment())){
          hasUpcomingDay = true
          if(!this.eligibility?.is_future_attend_regular_allowed){
            this.showValidation('regulariseFuture');
          }
        }
      }
    if(hasUpcomingDay && hasPreviousDay){
      this.showValidation('BothPresent')
    }
  }

  getCalendarData() {
    var count = 0;
    this.regularizationRequestService.getCalendarData(this.empId, this.year, Number(this.month) + 1).subscribe((res: any) => {
      this.calendarOptions.events = res?.data;
      let max_days = this.eligibility.max_attend_regular_back_days

      // New Updations regarding backdated days start
      if(this.eligibility?.backdated_attend_regular_day_type == 'day'){
        this.get_eligible_days = moment(new Date()).subtract(max_days, 'days').format('YYYY-MM-DD');
      }else{
        this.get_eligible_days = this.nMonthsAgo(new Date(),max_days).toLocaleDateString('fr-CA');
      }
      // end

      // this.get_eligible_days = this.nMonthsAgo(new Date(),max_days).toLocaleDateString('fr-CA')
      let timeFormat = this.appService.gettimeformat()
      let range = this.setDateRange(this.get_eligible_days,this.eligibility?.is_backdated_attend_regular_allowed,this.eligibility?.is_future_attend_regular_allowed)
      let range_length = range.length
      let doj = this.eligibility?.date_of_joined

      this.calendarOptions.eventContent =  (arg)=> {
        count++;
        var dateString
        dateString = moment(arg.event._instance?.range.start).format('YYYY-MM-DD')

        // Get status
        var status_firstHalf  = arg.event.extendedProps.first_half_status;
        var status_secondHalf = arg.event.extendedProps.second_half_status;
        var holiday           = arg.event.extendedProps.holiday;

        var dateStatus = arg.event.extendedProps.status
        var shiftmapping = arg.event.extendedProps.shift_mapping
        // Get the cell from calender which is mentioned in event
        var dateTableCell = document.querySelector('[data-date ="' + dateString + '"]') as HTMLElement
        //Dont add to Prev and Next Month
        if (!dateTableCell?.classList.contains('fc-day-other')) {
          // Add class to table cell accoding to status
          dateTableCell?.classList.add('cell-status');
          if(moment(doj).isAfter(moment(dateString),'date')){
            dateTableCell?.classList.add('status-unknown');
           }else{
            if(shiftmapping == null)
              dateTableCell?.classList.add('status-shift-unassigned');
            else if(holiday)
              dateTableCell?.classList.add('status-holiday');
            else if(status_firstHalf == "Present" && status_secondHalf =="Present")
             dateTableCell?.classList.add('status-present');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-leave');
            else if(status_firstHalf == "Weekoff" && status_secondHalf == "Weekoff")
              dateTableCell?.classList.add('status-weekoff');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-absent');

            else if(status_firstHalf == "Present" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-present-leave');
            else if(status_firstHalf == "Present" && status_secondHalf =="Weekoff")
              dateTableCell?.classList.add('status-present-weekoff');
            else if(status_firstHalf == "Present" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-present-absent');
            else if(status_firstHalf == "Present" && status_secondHalf =="Holiday")
              dateTableCell?.classList.add('status-present-holiday');

            else if(status_firstHalf == "Leave" && status_secondHalf =="Present")
              dateTableCell?.classList.add('status-leave-present');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Weekoff")
              dateTableCell?.classList.add('status-leave-weekoff');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-leave-absent');
            else if(status_firstHalf == "Leave" && status_secondHalf =="Holiday")
              dateTableCell?.classList.add('status-leave-holiday');

            else if(status_firstHalf == "Weekoff" && status_secondHalf =="Present")
              dateTableCell?.classList.add('status-weekoff-present');
            else if(status_firstHalf == "Weekoff" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-weekoff-leave');
            else if(status_firstHalf == "Weekoff" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-weekoff-absent');
            else if(status_firstHalf == "Weekoff" && status_secondHalf =="Holiday")
              dateTableCell?.classList.add('status-weekoff-holiday');

            else if(status_firstHalf == "Absent" && status_secondHalf =="Present")
              dateTableCell?.classList.add('status-absent-present');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-absent-leave');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Weekoff")
              dateTableCell?.classList.add('status-absent-weekoff');
            else if(status_firstHalf == "Absent" && status_secondHalf =="Holiday")
              dateTableCell?.classList.add('status-absent-holiday');

            else if(status_firstHalf == "Holiday" && status_secondHalf =="Present")
              dateTableCell?.classList.add('status-holiday-present');
            else if(status_firstHalf == "Holiday" && status_secondHalf =="Leave")
              dateTableCell?.classList.add('status-holiday-leave');
            else if(status_firstHalf == "Holiday" && status_secondHalf =="Weekoff")
              dateTableCell?.classList.add('status-holiday-weekoff');
            else if(status_firstHalf == "Holiday" && status_secondHalf =="Absent")
              dateTableCell?.classList.add('status-holiday-absent');
           }

          //To Set Needed data attributes so that can be accessed in dateClick()
          dateTableCell.dataset.starttime = arg.event.extendedProps.starttime;
          dateTableCell.dataset.endtime = arg.event.extendedProps.endtime;
          dateTableCell.dataset.time = arg.event.extendedProps.time;
          dateTableCell.dataset.status = arg.event.extendedProps.status;
          dateTableCell.dataset.shift_mapping = arg.event.extendedProps.shift_mapping;
          dateTableCell.dataset.shift_name = arg.event.extendedProps.shift_name;
          dateTableCell.dataset.shift_code = arg.event.extendedProps.shift_code;
          dateTableCell.dataset.compoff_raised     = arg?.event?.extendedProps?.compoff_raised;
          dateTableCell.dataset.overtime_processed = arg?.event?.extendedProps?.overtime_processed;
          dateTableCell.dataset.original_checkin_date = arg?.event?.extendedProps?.original_checkin_date;
          dateTableCell.dataset.original_checkout_date = arg?.event?.extendedProps?.original_checkout_date;
          dateTableCell.dataset.req_pending_status = arg?.event?.extendedProps?.req_pending_status;
          dateTableCell.dataset.shift_start_time = arg?.event?.extendedProps?.shift_start_time;
          dateTableCell.dataset.shift_end_time   = arg?.event?.extendedProps?.shift_end_time;

          dateTableCell.dataset.allowed_checkin_date  = arg?.event?.extendedProps?.allowed_checkin_date;
          dateTableCell.dataset.allowed_checkin_time  = arg?.event?.extendedProps?.allowed_checkin_time;
          dateTableCell.dataset.allowed_checkout_date = arg?.event?.extendedProps?.allowed_checkout_date;
          dateTableCell.dataset.allowed_checkout_time = arg?.event?.extendedProps?.allowed_checkout_time;

          dateTableCell.dataset.arrear_valid = arg?.event?.extendedProps?.arrear_valid;

        }

        let timeEvent = document.createElement('div')
        timeEvent.classList.add("time-event");
        // if (arg.event.extendedProps.status != '') {
        //Check-In Time
        let startTime = document.createElement('span')
        startTime.classList.add("start-time");
        if (arg.event.extendedProps.starttime != "" &&
          arg.event.extendedProps.starttime != undefined) {
          startTime.innerHTML = moment(arg.event.extendedProps.starttime,'HH:mm:ss').format(timeFormat)
        } else {
          startTime.innerHTML = '--:--'
        }
        //Check-out time
        let endTime = document.createElement('span')
        endTime.classList.add("end-time");
        if (arg.event.extendedProps.endtime != "" &&
          arg.event.extendedProps.endtime != undefined) {
          endTime.innerHTML = moment(arg.event.extendedProps.endtime,'HH:mm:ss').format(timeFormat)
        } else {
          endTime.innerHTML = '--:--'
        }
        //Total Work Hour
        let duration = document.createElement('span')
        duration.classList.add("duration");

        if (arg.event.extendedProps.time != "" &&
        arg.event.extendedProps.time != undefined) {
          // let timeWihMin = moment.utc(Number(arg.event.extendedProps.time)*1000).format('k:mm')
            const durations = moment.duration(Number(arg.event.extendedProps.time), 'seconds');
            const hours = Math.floor(durations.asHours());
            const minutes = durations.minutes();
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            let timeWihMin = formattedHours + ':' + formattedMinutes;
           if(arg.event.extendedProps.overtime_status){
              //Add overtime class if its overtime data
              duration.classList.add("duration","text-warning");
              duration.innerHTML = timeWihMin + " Hrs. OT"
            }else{
              duration.innerHTML = timeWihMin + " Hrs"
            }
        } else {
          duration.classList.add("duration");
          duration.innerHTML = "-- Hrs"
        }
        //  No Shift Class
        // if(arg.event.extendedProps.shift_mapping == null){
        //   dateTableCell?.classList.add('status-shift-unassigned');
        // }
        //Icon for checkbox
        // let startDate = range[0]
        // let endDate = range[range_length-1]
        // if(moment(arg.event._instance?.range.start,'YYYY-MM-DD').isBetween(startDate,endDate)){
        //   let checkboxIcon = document.createElement('i')
        //   checkboxIcon.classList.add("bi", "checkbox-icon");
        //   if(arg.event.extendedProps.shift_mapping == null){
        //     checkboxIcon.setAttribute('readonly', 'true')
        //   }else{
        //     checkboxIcon.removeAttribute('readonly')
        //   }
        //   timeEvent.append(checkboxIcon)
        // }
        let checkboxIcon = document.createElement('i')
        checkboxIcon.classList.add("bi", "checkbox-icon");
        if(arg.event.extendedProps.shift_mapping == null){
          checkboxIcon.setAttribute('disabled', 'true')
        }else{
          checkboxIcon.removeAttribute('disabled')
        }
        timeEvent.append(startTime, endTime, duration, checkboxIcon);

        let arrayOfDomNodes = [timeEvent]
        return { domNodes: arrayOfDomNodes }
      }
    });
  }

  invalidArrayData(){
    const duplicates = [...new Set(this.alertRegularizationArr.filter((value:any, index:any, self:any) => self.indexOf(value) !== index))];
    return duplicates
  }

  showValidation(type:any){
    this.alertToggle = true;
    const start = moment(this.get_eligible_days);
    const end = moment();
    const diff = end.diff(start, "days")
    if(type == 'regulariseBackDated'){
      // this.alertMsg = "You cannot raise more than "+ diff +" backdated request. Please review your request";
      // this.alertMsgModal = "Backdated Attendance Regularization requests cannot be raised for days prior to "+ diff + " days."
      this.alertMsgModal = "Backdated Regularization request cannot be raised for the selected days as per the policy."
      this.alertToggleModal = true
    }else if(type == 'regulariseFuture'){
      // this.alertMsg = "You cannot raise on attendance regularization request for future dates."
      this.alertMsgModal = "Attendance Regularization request cannot be raised for future dates as per the policy."
      this.alertToggleModal = true
    }else if(type == 'regulariseNoBackDated'){
      // this.alertMsg = "You cannot raise backdated request."
      this.alertMsgModal = "Backdated Attendance Regularization request cannot be raised as per the  policy."
      this.alertToggleModal = true
    }else if(type == 'Overtime'){

        let dateArray = [];

        for(let i=0;i<this.overTimeDateArray?.length;i++){
          dateArray.push(this.appService.dateFormatDisplay(this.overTimeDateArray[i]));
        }

        // this.alertMsg = "Overtime has already been processed for the selected dates" +' ('+dateArray?.join(', ')+')'+ ". You cannot raise regularization request for these dates."

        this.alertMsg = "Attendance Regularization request cannot be raised as OT hours and attendance has already been processed for this dates" +' ('+dateArray?.join(', ')+')'+ ".";

      }else if(type == 'CompOff'){

        let dateArray = [];

        for(let i=0;i<this.compOffDateArray?.length;i++){
          dateArray.push(this.appService.dateFormatDisplay(this.compOffDateArray[i]));
        }

        this.alertMsg = "Attendance Regularization request cannot be raised, as comp-off for this dates" +' ('+dateArray?.join(', ')+')'+ " has already been availed."

        // Compensatory off credited against the selected dates" +' ('+dateArray?.join(', ')+')'+ " has already been availed. You cannot raise regularization request for the selected dates.";

      }else if(type == 'Pending'){
        this.alertMsgModal = "Attendance regularization request has already been raised for the selected date."
        this.alertToggleModal = true;
      }else if(type == 'Arrear'){
        this.alertMsgModal = "Attendance regularization request cannot be raised prior to the arrear month as per the policy."
        this.alertToggleModal = true;
      }
  }

  removeValidation(){
    this.alertToggle = false;
    this.alertMsg    = '';
    let alertCls = document.querySelector('.alert') as HTMLElement
    alertCls && alertCls.classList.remove('show')
  }

  /**
 * Takes two values and returns date months ago from current
 * @param a first input will be current date
 * @param b second input will be months
 * @returns date from today
 */
  nMonthsAgo(date:any, n:any) {
    const y = date.getFullYear() - Math.trunc(n / 12)
    const m = date.getMonth() - n % 12
    let d = date.getDate()
    if (d > 27) {
      const lastDateofMonth = new Date(y, m + 1, 0).getDate()
      d = Math.min(d, lastDateofMonth)
    }
    // return new Date(y, m, d)
    return new Date(y, m, 1)
  }

 // Modal close for Invalid Extension
 closed(bool:any){
   this.alertToggleModal = bool
  }

  submitResignationDetails(){
    this.submitmethod.emit();
  }

    /**
 * Takes one value and returns date range which is from eligible date to end of month
 * @param a input will be which date from today
 * @returns date range
 */
  setDateRange(day:any,back:any,future:any){
    let arr : any = []
    var dummyArr : any = []
    let eligibleDate = new Date(day)
    var currentDate = new Date();
    // let firstDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);
    let firstDayOfMonth = this.eligibility.backdated_attend_regular_day_type == 'day' ? eligibleDate : new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);
    // let lastDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);

    let lastDays = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);
    let lastDayOfMonth;

    if(moment(lastDays).format('YYYY-MM-DD') == moment(this.selectedDate).format('YYYY-MM-DD')){
      // If the current date is equal to the last day of the month
      lastDayOfMonth = moment().add(1,'days');
    }else{
      // Other case
      lastDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);
    }

    if(back && future){
      // Both are there
      // Checking if current month
      if(this.selectedDate.getMonth() == new Date().getMonth()){
        arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
      }
      // if selected less than current month
      else{
        let exactStart = Math.floor(moment(this.selectedDate).diff(moment(eligibleDate),'months',true))
        // If eligible date comes
        if(exactStart == -1){
          let lastDay = moment(lastDayOfMonth).add(2,'days')
          arr = this.getDaysArray(eligibleDate,lastDay)
        }
        // month till eligible dates
        else if(exactStart >-1){
          arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
        }
        // If it crosses eligible month
        else{
          let today = moment()
          arr = [today.format('YYYY-MM-DD')]
        }
      }
    }
    // If backdates is true and No future is enabled
    else if(back && !future){
      // If current month
      if(this.selectedDate.getMonth() == new Date().getMonth()){

        if(moment(firstDayOfMonth).format('YYYY-MM-DD') == moment(this.selectedDate).format('YYYY-MM-DD')){
          // If the current date is equal to the first day of the month
          let a =  moment(firstDayOfMonth).subtract(1,'day');
          arr   = this.getDaysArray(a,moment().subtract(1, 'day').toDate());
        }else{
          // Other case
          arr   = this.getDaysArray(firstDayOfMonth,moment().subtract(1, 'day').toDate())
        }

        // arr = this.getDaysArray(firstDayOfMonth,moment().subtract(1, 'day').toDate())
      }
      // If less than the current date
      else{
        let exactStart = Math.floor(moment(this.selectedDate).diff(moment(eligibleDate),'months',true))
        // If eligible date comes
        if(exactStart == -1){
          let lastDay = moment(lastDayOfMonth).add(2,'days')
          arr = this.getDaysArray(eligibleDate,lastDay)
        }
        // month till eligible dates
        else if(exactStart <= 4 && exactStart>-1){
          if(moment(this.selectedDate).isSameOrBefore(moment())){
            arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
          }
          else{
            let today = moment()
            arr = [today.format('YYYY-MM-DD')]
          }
        }
        // If it crosses eligible month
        else{
          let today = moment()
          arr = [today.format('YYYY-MM-DD')]
        }
      }
    }else if(!back && future){
       // Checking if current month
      if(this.selectedDate.getMonth() == new Date().getMonth()){
        let lastDate = moment(lastDayOfMonth).add(2,'days')
        arr = this.getDaysArray(new Date(),lastDate)
      // Greater dates than current
      }else{
        if(!(moment(this.selectedDate).isSameOrBefore(moment()))){
          arr = this.getDaysArray(firstDayOfMonth,lastDayOfMonth)
        }else{
          let today = moment()
            arr = [today.format('YYYY-MM-DD')]
        }
      }
      // If both are not given
    }else{
      // Show only current date because backdate and future are false
      let today = moment()
      arr = [today.format('YYYY-MM-DD')]
    }
    return arr;
  }

    /**
 * Takes two values and returns date range
 * @param a input will be start date
 * @param b input will be end date
 * @returns date range
 */

  getDaysArray = (start:any,end:any) =>{
    for(var arr = [],dt= new Date(start);dt<=new Date(end); dt.setDate(dt.getDate()+1)){
      arr.push(moment(new Date(dt)).format('YYYY-MM-DD'))
      if(dt.getDate()==new Date(end).getDate()){
        arr.push(moment(new Date(end)).add(1,'days').format('YYYY-MM-DD'))
      }
    }
    return arr;
  }

    /**
 * Takes one value and returns value in hours
 * @param a input will be month
 * @returns result in hours
 */
  toHoursAndMinutes(sec:any) {
    const totalMinutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    const hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    const formattedHours: string = (hours.toString().length <2) ? "0" + hours.toString() : hours.toString();
    const formattedMinutes: string = (minutes.toString().length <2) ? "0" + minutes.toString() : minutes.toString();
    return formattedHours+':'+formattedMinutes
  }

  nightShiftCheckoutDate(date:any){
    var day     = new Date(date);
    var nextDay = new Date(day);
    nextDay?.setDate(day.getDate() + 1);
    return nextDay;
  }
}
