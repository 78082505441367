import { Component, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
import { NewpasswordService } from '../../services/newpassword.service';
import { Validators,FormBuilder,ValidatorFn,ValidationErrors,AbstractControl } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { nonSpaceValidator } from 'src/app/@shared/validators/nonspace.validator';
import { AppService } from 'src/app/app.global';
import { LoginFormValidators } from 'src/app/@shared/validators/loginForm.validators';
import { OrganizationService } from '../../services/organization.service';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.scss']
})
export class NewpasswordComponent implements OnInit {
  CardVisible: boolean    = true
  public username: string = '';
  password : string       = ''
  confirmPassword : string = ''
  show_pass: boolean      = false;
  viewicon_password       = "icon-eye-slash";
  show_confirm: boolean   = false;
  viewicon_confirm        = "icon-eye-slash";
  inValidInput            = false;
  errorMsg                = "";
  submitted               = false;
  profileImage            = "";
  fullname                = "";
  currentlogodata:any     = [];
  loader                  = true;
  colourCode              = "";



  constructor(
    private newpasswordService: NewpasswordService,private formBuilder:FormBuilder,
    public messageService : MessageService,public appservice:AppService,
    public OrganizationService: OrganizationService,
    public authService: AuthService

  ) { }

  ngOnInit(): void {
    let empdata       = this.appservice.getEmpData();
    this.profileImage = empdata.profile_image;
    this.colourCode   = empdata.color_code;
    this.fullname     = empdata.first_name+" "+(empdata.middle_name!=null?empdata.middle_name:'')+" "+ empdata.last_name;
    this.getorgdata();

  }

  passwordForm   = this.formBuilder.group({

                    password        : ['',[Validators.required,Validators.minLength(8),LoginFormValidators.ValidateSpecialCharacter,LoginFormValidators.ValidateFormat,LoginFormValidators.ValidateLowerCase,LoginFormValidators.ValidateNumber,LoginFormValidators.ValidateUpperCase,nonSpaceValidator()]],
                    confirmPassword : ['',Validators.required] },
                    { validators: LoginFormValidators.ValidatePassword}
                  );

  resetPassword(){
    this.submitted = true;
    if(this.passwordForm.invalid)
    return
    this.newpasswordService.resetPassword({username: localStorage.getItem('user_id'), password: this.passwordForm.value.password})
    .subscribe((res: any) => {
      if (res.reset == '1') {

        this.CardVisible = false
      }
    },
    (error: any) => {

      this.inValidInput = true;
      this.errorMsg     = error.error.error;

    }
  )
  }
  getpassword(key:any) {
    if(key=='pass'){

      this.show_pass        = !this.show_pass;
      this.viewicon_password =this.show_pass==true?'icon-eye':'icon-eye-slash';
    }
    else{
      this.show_confirm         = !this.show_confirm;
      this.viewicon_confirm     = this.show_confirm==true?'icon-eye':'icon-eye-slash';
    }
  }
  get f(){
    return this.passwordForm.controls;
  }
  getorgdata(){

    this.OrganizationService.getloginpagedata()
    .subscribe((res: any) => {
     this.currentlogodata = res.success;
     this.loader          = false;

    })
  }
  logout(){
    this.appservice.resetPermissions()
    this.authService.doLogout()
  }
}
