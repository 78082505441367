import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CityService } from 'src/app/@core/services/city.service';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';

@Component({
  selector: 'app-confirmed-investment-inbox',
  templateUrl: './confirmed-investment-inbox.component.html',
  styleUrls: ['./confirmed-investment-inbox.component.scss']
})
export class ConfirmedInvestmentInboxComponent implements OnInit {
  viewHeader = '';
  cityName:any = '';
  investmentSingleData:any = [];
  hra80ggSingleData:any = [];
  cahpt80CSingleData:any = [];
  otherSourceSingleData:any = [];
  deductionSingleData:any = [];
  incomeLossSingleData:any  = [];
  taxData:any               = [];
  exeption_50:any = [];
  hra80gg:any;
  investmentcard1          = false;
  investmentcard            = false;
  hra80ggFlag:any           = false;
  loader1:any               = false;
  currency:any;

  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  sendReminder:any

  @Input() requestitem:any;
  @Input() from:any;
  @Output() investmentcardClose: EventEmitter<boolean> =  new EventEmitter();
  @Output() refreshEmitter: EventEmitter<boolean> =  new EventEmitter();


  constructor(
    private investmentService:InvestmentDeclerationService,
    private cityService:CityService,
    private inboxservice: InboxService,

  ) { }

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
  }


  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{
        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
              if(this.requestitem['id'] == id){
                this.requestitem[key] = value;
              }
          })
        }
      })
    }
  }

  getCity(cityid:any){
    this.cityService.getCity(cityid).subscribe((res: any) => {
     this.cityName = res.name;
    });
  }

  Inv_Detailspanel(req_obj: any){

    this.loader1 = true;
    this.investmentcard1 = true;
    var viewId = req_obj;
    this.viewHeader = this.from=='behalf'?this.requestitem['request_data']['request_type']['name']:this.requestitem?.request_type

    this.investmentService.investmentSingleData(viewId).subscribe((res: any = []) =>{
      this.investmentSingleData = res;
      this.hra80ggSingleData = res.hra80gg;
      this.cahpt80CSingleData = res.chapter4a80c;
      this.deductionSingleData = res.chapter4adeduction;
      this.otherSourceSingleData = res.income_from_othersource;
      this.incomeLossSingleData   = res.incomeloss_property;
      this.taxData                = JSON.parse(res.declared_datail);
      this.currency               = res?.currency;
      if(this.taxData?.excemption_declaration?.hasOwnProperty('hra'))
        this.hra80gg='hra';
      if(this.taxData?.excemption_declaration?.hasOwnProperty('80gg'))
        this.hra80gg='80gg';
      if(res.hra80gg?.length > 0){
        if(res.hra80gg[0]['select_one'] == true)
          this.hra80ggFlag = true;
        else
          this.hra80ggFlag = false;
      }

      if(this.deductionSingleData?.donate_80g?.length > 0){
        for(let i=0; i< this.deductionSingleData?.donate_80g?.length; i++){
          if(!this.deductionSingleData?.donate_80g[i]?.exemption){
            this.exeption_50.push(this.deductionSingleData?.donate_80g[i])
          }
        }
      }
      this.loader1           = false;

      if(this.hra80ggSingleData.city != '')
      this.getCity(this.hra80ggSingleData.city);
    });
    this.investmentcard1 = true;
  }

  closeSidePanel(val: boolean){
    this.investmentcard1=val;
    this.investmentcardClose.emit(val);
  }
  refreshInbox(val: any){
    this.refreshEmitter.emit(val);
  }

     /******** REQUEST VIEW STATUS  ****/
     requestViewStatus(){

      this.viewstatusPanel  = true
      this.billLoader       = true;

       this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
        this.viewStatusData     = res;
        this.billLoader         = false;
        this.viewStatusLevelCnt = res?.activity?.level_count;
        this.viewStatusmaxLevel = res?.activity?.maxlevel;
       });
      }


}
