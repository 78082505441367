import { Component, OnInit } from '@angular/core';
import { Validators,FormBuilder,ValidatorFn,ValidationErrors,AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { nonSpaceValidator } from 'src/app/@shared/validators/nonspace.validator';
import { LoginFormValidators } from 'src/app/@shared/validators/loginForm.validators';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  submitted               = false;
  userdata:any            = [];
  show_pass: boolean      = false;
  viewicon_password       = "icon-eye-slash";
  show_confirm: boolean   = false;
  viewicon_confirm        = "icon-eye-slash";
  show_current: boolean   = false;
  viewicon_current        = "icon-eye-slash";
  inValidInput:boolean    = false;
  errorMsg                = "";
  lastPassword:any        = 0;
  completed               = false;



 changePasswordForm   = this.formBuilder.group({

                            currentPass     : ['',[Validators.required]],
                            password        : ['',[Validators.required,LoginFormValidators.ValidateNumber,LoginFormValidators.ValidateUpperCase,LoginFormValidators.ValidateLowerCase,LoginFormValidators.ValidateSpecialCharacter,Validators.minLength(8),LoginFormValidators.ValidateFormat,nonSpaceValidator()]],
                            confirmPassword : ['',Validators.required] },
                            { validators: LoginFormValidators.ValidatePassword }
                        );
  constructor(private formBuilder:FormBuilder,public messageService : MessageService,private authService:AuthService,private router:Router,private appservice:AppService) { }

  ngOnInit(): void {
  }
  get f(){
    return this.changePasswordForm.controls;
  }
  changePassword(){
    this.inValidInput = false;
    this.lastPassword = 0;
    this.submitted    = true;
    this.completed    = true;
    if(this.changePasswordForm.invalid)
      return
    else{

        this.userdata = this.appservice.getEmpData();
        let newData   = {"username":this.userdata.id,"password":this.changePasswordForm.value.currentPass,"new_password":this.changePasswordForm.value.password}
        this.authService.resetPassword(newData).subscribe((res:any)=>{

          this.router.navigate(['home/']);

        },
        (err: any) => {

          if(err.error.new_password_error==1){
            this.lastPassword = 1;
          }
          else
            this.inValidInput = true;
          this.errorMsg     = err.error.error;
          this.completed    = false;

        }
        )

    }
  }

  getpassword(key:any) {
    if(key=='pass'){
      this.show_pass        = !this.show_pass;
      this.viewicon_password =this.show_pass==true?'icon-eye':'icon-eye-slash';
    }
    else if(key=='confirm'){
      this.show_confirm         = !this.show_confirm;
      this.viewicon_confirm     = this.show_confirm==true?'icon-eye':'icon-eye-slash';
    }
    else{
      this.show_current         = !this.show_current;
      this.viewicon_current     = this.show_current==true?'icon-eye':'icon-eye-slash';
    }
  }
}
