import { Component, OnInit, ViewChild,Output,EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup , Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-employee-assign',
  templateUrl: './employee-assign.component.html',
  styleUrls: ['./employee-assign.component.scss']
})
export class EmployeeAssignComponent implements OnInit {
  is_active: any = true;
  selected_flag = false;
  @ViewChild('ngSelectComponent') ngSelectComponent: any;
  groupForm: any;
  temp_list: any = [];
  submitted = false;
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public messageService : MessageService,
    public fb:FormBuilder,
    public route :  ActivatedRoute,
    public appService:AppService,
    private cd : ChangeDetectorRef
  ) { }


  @Input()formGp : FormGroup = this.fb.group({
    employee: [''],
  });
  @Input()employeeDropdown:any=[];
  @Input() disabled=false;
  @Input() editflag:any;

  @Output() submitmethod = new EventEmitter();

  ngOnInit(): void {
    // if (this.employeeDropdown.length != 0) {
    //   this.selectAllForDropdownItems(this.employeeDropdown);
    // }
    this.initiateErrorStatus()
  }

  validateConfigureFlow() {
    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  validated : boolean = false;

  clear(item:any,index:number){
    if(item == "employee"){
      let daata = this.formGp.value.employee;
      daata.splice(index, 1);
      this.formGp.patchValue({
        employee: daata
      });
    }
    this.initiateErrorStatus();
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }


  // Inactive Employee Setup
  ngChange(e:any){
    if(this.hasSpecificStatus(e)){
      this.formGp?.get('employee')?.setErrors({
        'relievedError' : true
      })
    }
  }

  hasSpecificStatus(employees:any){
    const statuses = ['Relieved','Settled','Terminated','Retired']
    return employees.some((employee:any)=> statuses.includes(employee.employment_status));
  }

  initiateErrorStatus(){
    console.log('1')
    if(this.f.employee.value != null && this.f.employee.value != ''){
      const filteredData = this.employeeDropdown.filter((item:any) => this.f.employee.value.includes(item.id))
      console.log('2')
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData)){
          this.formGp?.get('employee')?.setErrors({
            'relievedError' : true
          })
          this.formGp.get('employee')?.markAsTouched();
          this.cd.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.employeeDropdown.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }
}
