import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {
  error_count: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient,public router: Router,private websocket: WebSocket,private authService: AuthService,public appservice:AppService) { }

  API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public requestSocket(){
    // console.log("Url",this.WEBSOCKET_URL)
    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}hr/${this.appservice.getSubDomain()}_error_page/`);

    this.websocket.onopen = (event:any) => {

    }

    this.websocket.onmessage = (event:any) => {

      let data = JSON.parse(event.data);
      this.error_count.emit(data);
    }

    this.websocket.onclose = (event:any) => {

    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

          if(event.url !== '/error-log'){
            this.closeSocket();
          }
      }
    });

    if (this.websocket.readyState === WebSocket.OPEN) {

    }
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
      // this.websocket.onclose = (event:any) => {

      // }
      this.websocket.addEventListener('close', (event) => {

      });
    }
  }

  getAllErrorData() {
    return this.http.get(`${this.API_PATH}hr/api/v1/error-data-list/`, this.httpOption)
  }
  getAllErrorDatafilter(data:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/error-data-list/?${data}`, this.httpOption)
  }
  getFilterErrorData(key:any,status:boolean) {
    return this.http.get(`${this.API_PATH}hr/api/v1/error-data-list/?${key}=${status}`, this.httpOption)
  }
  getErrorAffetctedEmp(id:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/error-data-detail/${id}`, this.httpOption)

  }
}
