<form class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row row-16">
            <div class="col-12">
                <p class="text-uppercase fw-500 mb-0">SHORT LEAVE REQUEST </p>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Date </label>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.date.dirty || f.date.touched) && (f.date.errors) }">
                <input (click)="datePicker1.open()" class="form-control" placeholder="{{this.msgServ.selectplaceholddisplay('date')}}" matInput
                  formControlName="date" [matDatepicker]="datePicker1" (keydown)="disableDate()" [min]="minDate"
                  (dateChange)="dateChangeFunction($event)"
                  [ngClass]="{ 'is-invalid': (submitted || f.date.dirty || f.date.touched) && (f.date.errors) }"
                  [matDatepickerFilter]="fromDateFilter"><i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                <mat-datepicker [dateClass]="dateClass" touchUi="false" (opened)="appendTypeIndicator()" #datePicker1
                  (opened)="openCalendar('fromdate')" (monthSelected)="fromMonthSelection($event)">
                  </mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.date.dirty || f.date.touched) && f.date.errors"
                class="invalid-feedback">
                <div *ngIf="f.date.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-12" *ngIf="f.date.value != null && short_leave_portion().length !=0" formArrayName="short_leave_portion">
                <div class="card card-c2 p-24">
                    <div class="row row-16">
                        <!-- <div class="col-12 d-flex">
                            <div class="text-uppercase fs-14 fw-500">Total Duration</div>
                            <div class="ms-auto fs-14 fw-500">{{totalDuration}} Hours</div>
                        </div> -->
                        <ng-container *ngFor="let data of short_leave_portion().controls; let i=index;"
                        [formGroupName]="i">
                            <div class="col-4">
                                <label for="" class="form-label required">From time </label>
                            </div>
                            <div class="col">
                                <label for="" class="form-label required">To time </label>
                            </div>
                            <div class="col-12">
                              <div class="align-items-start d-flex flex-nowrap row row-16">
                                  <div class="col-4 form-row mt-0">
                                      <igx-time-picker formControlName="from_time"
                                      placeholder="Select time" (ngModelChange)="setDuration(i,'from_time')"
                                      [inputFormat]="'HH:mm'"
                                      [ngClass]="{ 'is-invalid': (submitted || short_leave_portion().controls[i].get('from_time')?.dirty || short_leave_portion().controls[i].get('from_time')?.touched) &&  short_leave_portion().controls[i].get('from_time')?.errors}"
                                      class="form-control form-timepicker " (click)="picker1.open()" #picker1></igx-time-picker>
                                      <div *ngIf="(submitted || short_leave_portion().controls[i].get('from_time')?.dirty || short_leave_portion().controls[i].get('from_time')?.touched) && short_leave_portion().controls[i].get('from_time')?.errors"
                                      class="invalid-feedback">
                                        <div *ngIf="short_leave_portion().controls[i].get('from_time')?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                                        <div *ngIf="short_leave_portion().controls[i].get('from_time')?.hasError('sameTime')">{{this.msgServ.lessMessage('To time','From time')}}</div>
                                        <div *ngIf="short_leave_portion().controls[i].get('from_time')?.hasError('outWindow')">{{this.msgServ.shiftwindowMessage('From time','To time')}}</div>
                                        <div *ngIf="short_leave_portion().controls[i].get('from_time')?.hasError('duplicateEntry')">Time range has already been selected</div>
                                      </div>
                                  </div>
                                  <div class="col-4 form-row mt-0">
                                      <igx-time-picker formControlName="to_time"
                                      placeholder="Select time" (ngModelChange)="setDuration(i,'to_time')"
                                      [inputFormat]="'HH:mm'" [minValue]="short_leave_portion().controls[i].get('from_time')?.value"
                                      [ngClass]="{ 'is-invalid': (submitted || short_leave_portion().controls[i].get('to_time')?.dirty || short_leave_portion().controls[i].get('to_time')?.touched) &&  short_leave_portion().controls[i].get('to_time')?.errors}"
                                      class="form-control form-timepicker " (click)="picker2.open()" #picker2></igx-time-picker>
                                      <div *ngIf="(submitted || short_leave_portion().controls[i].get('to_time')?.dirty || short_leave_portion().controls[i].get('to_time')?.touched) && short_leave_portion().controls[i].get('to_time')?.errors"
                                      class="invalid-feedback">
                                        <div *ngIf="short_leave_portion().controls[i].get('to_time')?.errors?.required">{{this.msgServ.validationDisplay('required')}}</div>
                                        <div *ngIf="short_leave_portion().controls[i].get('to_time')?.hasError('sameTime')">{{this.msgServ.greaterThanMessage('From time', 'To time')}}</div>
                                        <div *ngIf="short_leave_portion().controls[i].get('to_time')?.hasError('outWindow')">{{this.msgServ.shiftwindowMessage('From time','To time')}}</div>
                                        <div *ngIf="short_leave_portion().controls[i].get('from_time')?.hasError('duplicateEntry')">Time range has already been selected</div>
                                      </div>
                                  </div>
                                  <div class="col form-row mt-0">
                                      <div class="h-40 bg28 hstack radius-4 px-16 py-8">
                                          <span class="fs-12 fw-500 text-light-500">Duration</span>
                                          <span class="fs-14 fw-600 ms-auto text-accent2">{{short_leave_portion().controls[i].get('duration')?.value == 0 ? '0 Hrs': this.appServ.formatTime(short_leave_portion().controls[i].get('duration')?.value)}}</span>
                                      </div>
                                  </div>
                                  <a class="flex-center sq-40 text-danger mt-0" (click)="removeRow(i)" *ngIf="i!=0">
                                    <i class="icon-trash "></i>
                                </a>
                              </div>
                          </div>
                        </ng-container>
                        <!-- <div class="col-12" *ngIf="alertMsg == ''&& !this.addBtn">
                            <div class="link-primary1 fs-14 d-flex align-items-center" (click)="addLeaves();">
                                <i class="icon-plus-square-fill fs-18 me-8"></i>Add more
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="descript" class="form-label required">Reason </label>
                <textarea type="text" class="form-control " rows="4" placeholder="Enter reason here..." formControlName="reason" [ngClass]="{ 'is-invalid': (submitted || f.reason.dirty || f.reason.touched) && (f.reason.errors) }"></textarea>
                <div *ngIf="(submitted || f.reason.dirty || f.reason.touched) && f.reason.errors" class="invalid-feedback">
                  <div *ngIf="f.reason.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
                  <div *ngIf="f.reason.errors.maxlength">{{this.msgServ.fieldlengthvalidation('word','100')}}
                  </div>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Upload documents</label>
                <!-- <div class="card card-c2 px-16"> -->
                  <label class="upload-btn " *ngIf="formGp.value.document == ''"
                  [ngClass]="{ 'is-invalid': (fileError == true)  }">
                      <div class="upload-text">Upload documents <span class="text-primary">here
                        <app-file-upload [crop]="false" [round]="false" [id]="'document'" [width]="504" (dataURL)="getURL($event)"
                        (fullfile)="getFilename($event)" (loader)="getloader($event)" [FileType]="'.pdf,.doc'"
                        (fileformat)="getfileformat($event)" (validsize)="getvalidsize($event)"></app-file-upload></span>
                        <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC</div>
                      </div>
                  </label>
                  <div *ngIf="(fileError == true)" class="invalid-feedback">
                    <div>{{fileErrorMsg}}</div>
                  </div>
                  <div *ngIf="formGp.value.document !='' && formGp.value.document != null && !docLoader">
                    <div class="card card-c2  bg-secondary" style="width:16.375rem;">
                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                        <ng-container *ngIf="!docLoader">
                          <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                            <span class="tips text-uppercase  fw-700 text-trim">{{this.appServ.getDocName(formGp.value.document)}}</span>
                            <span class="tips-helper ">{{this.appServ.getDocName(formGp.value.document)}}</span>
                            <div class="d-flex gap-8">
                              <div class="link-primary" (click)="viewDocument(formGp.value.document)">
                                <i class="bi bi-eye me-1"></i>View
                              </div>
                              <div class="link-primary" (click)="downLoad(formGp.value.document)">
                                <i class="bi bi-download me-1"></i>Download
                              </div>
                            </div>
                          </div>
                          <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteFile($event)"></i>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->
                <div *ngIf="docLoader" class="card card-c2  bg-secondary" style="width:16.375rem;" >
                  <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                    <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                    <div class="  flex-center text-center"  *ngIf="docLoader">
                      <div class="fs-12 text-muted">Loading </div>
                      <div class="ms-4 dot-pulse"></div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid"
        (click)="validateLeaveDetails()">NEXT</button>
    </footer>
</form>

<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<app-info-popup *ngIf="alertToggle1" (modal)="alertToggle1 = false" [infoToggle]="alertToggle1" [infoMsg]="alertMsg"></app-info-popup>

