<header class="topbar">
  <div class="d-flex  align-items-center overflow-hidden">
    <div class="company-logo"> <img class="img-fluid" src="{{this.SnapshotService.getCompanyLogo()}}" /></div>
    <div class="company-name">{{this.SnapshotService.getCompanyname()}}</div>
  </div>
  <div class="d-flex ms-auto ">
    <ul class="header-menu">
      <li placement="left" ngbPopover="What's New" popoverClass="popover-type1" container="body" triggers="hover">
        <a (click)="openWhatsNew()">
          <i class="icon-megaphone3 fs-24"></i>
          <span *ngIf="whatsnew?.count>0 || (!whatsnew?.last_accessed_on && whatsnew?.data?.length>0)" class="badge-float badge-notifiction"></span>
        </a>
      </li>
      <li placement="left" ngbPopover="Find help" popoverClass="popover-type1" container="body" triggers="hover">
        <a href="{{kbUrl}}" target="_blank">
          <i class="icon-question-circle  fs-24"></i>
        </a>
      </li>
      <li placement="left" ngbPopover="Notification" popoverClass="popover-type1" container="body" triggers="hover">
        <a (click)="showSidePanel = true">
          <i class="icon-bell-2 fs-24"></i>
          <span *ngIf="bluetick>0" class="badge-float badge-notifiction"></span>
        </a>
      </li>
      <li placement="left" ngbPopover="Manage System" popoverClass="popover-type1" container="body" triggers="hover">
        <button #popOver="ngbPopover" class="btn-reset" type="button" [ngbPopover]="popContent" placement="bottom"
          popoverClass="profile-popover" container="main-container" (click)="showProfileCard = !showProfileCard;">
          <i class="icon-manage-system fs-24"></i>
        </button>
      </li>
    </ul>
  </div>
</header>
<div class="side-panel notification-panel" style="--sidepanel-width:28.5625rem;"
  [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Notification</h5>
      <a class="toggle-panel" (click)="readDisplayed();showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow: scroll;" infiniteScroll [infiniteScrollDistance]="2"
      [scrollWindow]="false" [infiniteScrollThrottle]="0" [fromRoot]="false" (scrolled)="onScrollDownNot()">
      <div class="flex-center d-flex flex-column " style="height: calc(100vh - 3.625rem);" *ngIf="nodata;else all_data">
        <img src="assets\images\bg\line-art2.svg" class="img-fluid">
        <p class="fs-14 fw-500 mt-20">No notifications yet</p>
        <span style="width:200px" class="fs-10 text-center">When you get notifications they’ll
          show up here</span>
      </div>
      <ng-template #all_data>
        <div class="container-fluid px-32 py-16">
          <div class="d-flex align-items-center gap-16 mb-8">
            <span (click)="markreadall()" class="link-primary1 fs-12 fw-500 ms-auto">Mark all read</span>
            <span (click)="clearall()" class="link-primary1 fs-12 fw-500">Clear all</span>
          </div>
          <div class="d-flex gap-8 flex-column">
            <ng-container *ngFor="let list of notifyList; let i = index">
              <div class="card card-c4 {{(list.read_status == false) ? 'notification-new' : ''}} card-hide show">
                <div class="card-body d-flex align-items-start p-16">
                  <div class="avatar-circle notification-icon sq-42 {{list.icon}} me-16">
                    <i class=""></i>
                  </div>
                  <div class="text-start me-auto">
                    <p class="fs-14 text-black mb-0" [innerHTML]="list.message"></p>
                    <span class=" fs-10 fw-600 text-accent1 ">{{list.created_at| dateAgo}}</span>
                  </div>
                  <a class="ms-auto text-light-600 ms-16 " (click)="hideCard($event, list.id) ">
                    <i class="icon-close-lg fs-10"></i>
                  </a>
                  <div></div>
                </div>
              </div>
            </ng-container>
            <div class="row row-16" *ngIf="loader">
              <div class="col-12 mt-25">
                <ng-container *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
                  <div class="mt-15">
                    <ngx-skeleton-loader appearance="line"
                      [theme]="{'background-color': '#EAEDEF','height': '74px','width': '100%' }"></ngx-skeleton-loader>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </form>
</div>
<ng-template #popContent>
  <div class="card card-c2 py-8 radius-8">
    <div class="card-body flex-column d-flex  px-0 py-8">
      <div class="text-uppercase fs-14 fw-600  px-16 py-8">MANAGE SYSTEM</div>
      <div class="d-flex gap-8 profile-link px-16 py-8 rounded-0" (click)="showtheme();">
        <div class="sq-24 avatar-circle text-accent2 bg8">
          <i class="icon-palette"></i>
        </div>
        <div class="fs-14">Color & Theme</div>
      </div>
      <div class="d-flex gap-8 profile-link px-16 py-8 rounded-0" routerLink="/change-password">
        <div class="sq-24 avatar-circle text-accent1 bg6">
          <i class="icon-gear-fill"></i>
        </div>
        <div class="fs-14">Change password</div>
      </div>
    </div>
    <div class="card-body px-0 py-8">
      <div class="d-flex gap-8 profile-link px-16 py-8 rounded-0"
        (click)="doLogout(); showProfileCard = !showProfileCard">
        <div class="sq-24 avatar-circle bg7 text10">
          <i class="icon-box-arrow-in-fill "></i>
        </div>
        <div class="fs-14">Logout</div>
      </div>
    </div>
  </div>
</ng-template>
<div class="side-panel side-pane-active" style="--sidepanel-width:28.5625rem;"
  [class.side-pane-active]='showWhatsNewPanel === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>What’s New </h5>
      <a class="toggle-panel" (click)="showWhatsNewPanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="flex-center d-flex flex-column " style="height: calc(100vh - 3.625rem);" *ngIf="!whatsnew.data ||whatsnew.data.length<=0">
        <img src="assets\images\no-data\bg16.png" class="img-fluid">
        <p class="fs-14 fw-500 mt-20">There are no details to show</p>
        <!-- <span style="width:200px" class="fs-10 text-center">When you get announcements they’ll
          show up here</span> -->
      </div>
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div  *ngFor="let list of whatsnew?.data; let i = index" class="col-12">
            <a  href="{{wnUrl}}" target="_blank" class="card card-c2 px-16 card-hover">
              <div class="card-body pb-12 px-0 hstack gap-8 align-items-baseline">
                <i *ngIf="list.post_date>whatsnew?.last_accessed_on || !whatsnew?.last_accessed_on" class="bi bi-circle-fill fs-10 text-accent2"></i>
                <span class="text-light-400 fs-10 fw-500 text-nowrap ">{{list.posted_date}}</span>

              </div>
              <div class="card-body pt-12 px-0">
                <p class="mb-0 fw-500 ">{{list.post_title}}</p>

                <p class="fs-12 mb-0 pt-8 text-light-500" [innerHTML]="cropText(list.post_excerpt, 125)"></p>
                <!-- <p class="fs-12 mb-0  pt-8 text-light-500  ">{{cropText(list.post_excerpt, 200)}}</p> -->
                <!-- <p class="fs-10 mb-0 position-relative pt-8 text-light-500 text-trim-3 text-trim3">{{list.post_excerpt}}<span  class="bg-white bottom-0 end-0 fw-500 link-primary1 position-absolute ps-1">Learn More</span></p> -->
                <div class="hstack gap-8  flex-wrap justify-content-start mt-12">
                  <span *ngFor="let tag of list.tags"
                    class="badge badge-{{tag.slug}} text-bg-secondary radius-4 fw-500  fs-10 py-1">{{tag.name}}</span>
                </div>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
