import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-leave-policy',
  templateUrl: './view-leave-policy.component.html',
  styleUrls: ['./view-leave-policy.component.scss']
})
export class ViewLeavePolicyComponent implements OnInit {

  @Input()viewListData:any;
  @Input()viewRightsArray:any;
  @Input()viewApplicable:any;
  @Input()leavePolicy:any;
  @Input()from:any;
  @Input()editPermission:any;
  @Input()viewloader:any
  @Input()leaveMapping:any;
  @Output() viewClose = new EventEmitter();
  @Output() editLeavepolicy = new EventEmitter();
  activeTab = 0;
  constructor(public appService: AppService) { }

  ngOnInit(): void {

  }

  /********* CLOSE FUNCTION ****************/
  closeFunction(){
    this.viewClose.emit(false);
  }

  editPolicy(id:any){
    this.editLeavepolicy.emit(id);
  }
  leaveClubbing(leave:any){
    let string:any =[];
    leave.forEach((item:any, key:any) => {
      string.push(item.leave_name);
    })
    return string.join(", ");
  }

}
