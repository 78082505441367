import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegularizationRequestService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getCalendarData(empId:any,year:any,month:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/attendance-regulariation-calender/${empId}/?year=${year}&month=${month}`, this.httpOption)
  }

  getCheckEligibility(empId:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/attendance-regularization-check-eligibility/${empId}/`, this.httpOption)
  }

  regularizationRequestCreate(body: any){
    return this.http.post(`${this.API_PATH}request/api/v1/attendance-regularization-create/`, body, this.httpOption)
  }

  getRegularizationView(request_id:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=Attendance Regularization&request_id=${request_id}`, this.httpOption)
  }

  viewStatusData(regId:any,id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${regId}&request_section=Attendance Regularization&specific_id=${id}`, this.httpOption)
  }
}
