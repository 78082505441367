import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-asset-policy',
  templateUrl: './asset-policy.component.html',
  styleUrls: ['./asset-policy.component.scss']
})
export class AssetPolicyComponent implements OnInit {

  menuData:any = [];
  menuList:any;

  constructor(
    public route:ActivatedRoute,
    private appService: AppService,public router: Router
  ) { }

  ngOnInit(): void {

    this.getPermission();
  }

  getPermission(){
    this.menuList = this.appService.getPermissions('/asset-policy');
    if(this.menuList == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    } else {
    this.createMenuList();
    }
  }

  createMenuList(){
    this.menuList.children.forEach((element: any) => {
      this.menuData.push({
        name:element.name,
        link:element.url
      })

    });
    this.reDirectTo();
  }

  reDirectTo(){
    if(this.router.url == '/asset-policy')
      this.router.navigate([this.menuData[0]?.link]);
  }

}
