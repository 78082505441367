import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskOwnerService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  taskOwnerList(status:any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/task-owner-list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }
  moduleDropDown() {
    return this.http.get(`${this.API_PATH}organization/api/v1/module-dropdown/?is_active=true&ordering=name`, this.httpOption)
  }
  roleDropDown() {
    return this.http.get(`${this.API_PATH}organization/api/v1/role-dropdown-list/?is_active=true&ordering=role_name`, this.httpOption)
  }
  processDropDown(id: number) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/module-process-dropdown/?is_active=true&module=${id}&ordering=process__name`, this.httpOption)
  }
  taskProcessDropDown(id: number, task_id: number) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/module-process-dropdown/?module=${id}&task_owner_id=${task_id}`, this.httpOption)
  }
  taskOwnerCreate(body: any) {
    return this.http.post(`${this.API_PATH}general_settings/api/v1/task-owner-create/`, body, this.httpOption)
  }
  taskOwnerUpdate(id: number, body:any) {
    return this.http.put(`${this.API_PATH}general_settings/api/v1/task-owner-detail/${id}/`, body, this.httpOption)
  }
  taskOwnerGet(id: number) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/task-owner-detail/${id}/`, this.httpOption)
  }
  taskOwnerDelete(id: number, body:any) {
    return this.http.patch(`${this.API_PATH}general_settings/api/v1/task-owner-detail/${id}/`, body, this.httpOption)
  }
  taskOwnerDropDown(status:any){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/task-owner-dropdown/?is_active=${status}&ordering=process__name`, this.httpOption)
  }
}
