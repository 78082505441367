<nav class="nav-custom nav sticky">
<ng-container *ngFor="let menu of menuList" >
  <a class="nav-link" routerLinkActive="active"  routerLink="{{menu.link}}" >{{menu.name}} </a>
</ng-container>
  <!-- <a class="nav-link" > BU </a>
  <a class="nav-link" > Department </a>
  <a class="nav-link" > Designation </a>
  <a class="nav-link" > Grade </a>
  <a class="nav-link" > City </a>
  <a class="nav-link" > Branch </a> -->
</nav>
