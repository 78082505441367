import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagementService {
  FILTERDATA:any;
  constructor(
    private http: HttpClient,
    public router: Router
  ) {this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      if(  !event.url.includes('document') || event.url.includes('document/category')){
        this.setFilterData(null);
      }
    }
  }) }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }
  categorySaveorUpdate(id: any, body:any){
    if(id=='')
    return this.http.post(`${this.API_PATH}document_repository/api/v1/document_repository_category_list/`, body, this.httpOption)
    else
    return this.http.put(`${this.API_PATH}document_repository/api/v1/document_repository_category_detail/${id}/`, body, this.httpOption)


  }


  CategoryList(status: any,limit:any,offset:any,search:any) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_category_list/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}`, this.httpOption)
  }

  getSingleCatgory(id: number) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_category_detail/${id}/`, this.httpOption)
  }


  categoryDelete(id: number) {
    return this.http.patch(`${this.API_PATH}document_repository/api/v1/document_repository_category_delete/${id}/`, this.httpOption)
  }

  categoryListData(filter:any = ''){
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_category_list/?is_active=${filter}&ordering=name`, this.httpOption)
  }
  saveOrUpdatePolicy(body:any,id:any){
    if(id==0)
    return this.http.post(`${this.API_PATH}document_repository/api/v1/document_repository_policy_create/`, body, this.httpOption)
    else
    return this.http.put(`${this.API_PATH}document_repository/api/v1/document_repository_policy_detail/${id}/`, body, this.httpOption)

  }
  getSinglePolicy(id: number) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_policy_detail/${id}/`, this.httpOption)
  }
  createVersion(body:any){
    return this.http.post(`${this.API_PATH}document_repository/api/v1/document_repository_policy_attachment_create/`, body, this.httpOption)

  }
  policyDelete(id: number) {
    return this.http.patch(`${this.API_PATH}document_repository/api/v1/document_repository_policy_delete/${id}/`, this.httpOption)
  }
  versionDelete(id: number) {
    return this.http.delete(`${this.API_PATH}document_repository/api/v1/document_repository_policy_attachment_delete/${id}`, this.httpOption)
  }
  getPolicyVersion(id:any){
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_policy_attachment_version_history/${id}`, this.httpOption)
  }
  getAckDetails(id:any){
    return this.http.get(`${this.API_PATH}document_repository/api/v1/employe_acknowledgement_count_list/${id}/`, this.httpOption)
  }

  employeePolicyList(limit:any,offset:any,search:any,id:any) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/employee_document_repository_landing_page/?limit=${limit}&offset=${offset}&search=${search}&id=${id}`, this.httpOption)
  }
  getSingleEmpPolicy(id: number) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/employee_document_repository_policy_details/${id}/`, this.httpOption)
  }
  employeePolicySearchList() {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/employee_document_repository_landing_page/?ordering=category_name`, this.httpOption)
  }
  getSinglePolicyEdit(id: number) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_policy_basic_detail/${id}`, this.httpOption)
  }
  getManageDocument(search:any) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_policy_list/?search=${search}`, this.httpOption)
  }
  getConsumedFileSize() {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document-repository-fetch-size/`, this.httpOption)
  }
  getPolicyDropdown(filterparam:any) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/document_repository_policy_dropdown/?${filterparam}`, this.httpOption)
  }
  updateAcknowledgement(id:any,body:any){
    return this.http.put(`${this.API_PATH}document_repository/api/v1/employee_get_acknowledgement/${id}/`, body, this.httpOption)

  }
  getSingleAckData(id: number) {
    return this.http.get(`${this.API_PATH}document_repository/api/v1/employee_get_acknowledgement/${id}`, this.httpOption)
  }

  setFilterData(val:any){
    this.FILTERDATA = val;
  }
}
