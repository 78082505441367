import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-financial-year-pending-request-view',
  templateUrl: './financial-year-pending-request-view.component.html',
  styleUrls: ['./financial-year-pending-request-view.component.scss']
})
export class FinancialYearPendingRequestViewComponent implements OnInit {

  year:number         = 0;
  loader              = false;
  searchString:any    = "";
  page                = 1;
  type:any            = "";
  pageSize            = 20;
  filterdata:any      = [];
  selectedList:any    = [];
  downloadMsg         = false;
  saveComplete        = false;

  month:any;
  company:any;
  fyData:any;
  tableHeader:any;
  inputdata :any


  constructor(
    public route:ActivatedRoute,
    public router: Router,
    public sdService:SalaryDashboardService,
    public messageService : MessageService,
    public appService:AppService,
    private notificationService: NotificationService,


  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {

      if( isNaN(params['request'])){
        this.type = params['request'];
      }

      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }

      this.getFYReminder()
    })
  }
  getFYReminder(){
    this.loader = true;
    this.sdService.getFYReminders(this.year,this.month,this.company).subscribe((res:any)=>{
      this.filterdata = [];
      if(this.type=='fbp'){

        this.fyData       = res.body?.fbp_pending_list[0]?.employees
        this.tableHeader  = 'Pending FBP declaration';
      }
      else if(this.type=='confirmed_investment'){
        this.fyData       = res.body?.confirmed_pending_list[0]?.employees
        this.tableHeader  = 'Pending confirmed investment declaration';

      }
      else{
        this.fyData       = res.body?.reimbursement_pending_list[0]?.employees
        this.tableHeader  = 'Pending reimbursement request';

      }

      for(let i=0;i<this.fyData.length;i++){
        var dict:any = {};
        this.fyData[i]['employee']['fullname']  = "";
        if(this.fyData[i]['employee']['first_name'] != null)
        this.fyData[i]['employee']['fullname'] += this.fyData[i]['employee']['first_name']+" ";
        if(this.fyData[i]['employee']['middle_name'] != null)
        this.fyData[i]['employee']['fullname'] += this.fyData[i]['employee']['middle_name']+" ";
        if(this.fyData[i]['employee']['last_name'] != null)
        this.fyData[i]['employee']['fullname'] += this.fyData[i]['employee']['last_name'];
        dict['fullname']      = this.fyData[i]['employee']['fullname'] ;
        dict['employee_code'] = this.fyData[i]['employee']['employee_code'] ;
        dict['request_type']  = this.fyData[i]['request_type']==true?'New':'Cancel';
        dict['id']            = this.fyData[i]['id']
        this.filterdata.push(dict);
      }
      this.loader = false;


    })
  }
  filterIt() {
    return this.filterdata.filter((obj: any) => {

      return Object.keys(obj).some((key) => {

        if (obj[key] !== null) {
          const tempKey     = obj[key].toString().toLowerCase();
          const tempSearch  = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }
  checkall(event:any){
    var newList  = this.filterIt();
    var begin    = (this.page-1) * this.pageSize ;
    var end      = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }
  filteredcount(){
    return this.filterIt().length;
  }
  checkallSelected(){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item:any) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  selectAll(){
    this.inputdata = this.appService.selectAll(this.filterdata)

  }
  unselectAll(){
    this.inputdata = this.appService.unselectAll(this.filterdata)

  }
  countSelected(){
    return this.filterdata.filter((obj:any) => obj.checked === true).length;
  }
  createSelectedLIst(){
    this.selectedList = [];

    this.filterdata.forEach((item:any, key:any) => {

      if(item.checked == true){

        this.selectedList.push({"request_id":item.id});
      }


    })
    if(this.selectedList.length > 0){
      this.downloadMsg = true;

    }

  }
  sendReminder(){
    this.saveComplete = true;
    this.sdService.sendFYReminder({"request_id":this.selectedList}).subscribe((res:any)=>{
      if(res.body.status==1){
        this.downloadMsg      = false
        this.saveComplete     = false;

        this.notificationService.handleSuccessNotification("Reminder send successfully","Success");
        this.getFYReminder();

      }
    })
  }


}
