<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Add New Trip</h5>
    <button class="btn-reset text-light-600" routerLink="/trips"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar">
    <ul ngbNav #nav="ngbNav" class="steps gap-24">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Trip Details </h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-trip-details></app-trip-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Trip Itinerary </h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-trip-itinerary></app-trip-itinerary>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="step">
          <div class="count">3</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Review </h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-trip-review></app-trip-review>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
