import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function longitudeValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      // const digitdecimalpattern = /^\\d+(\\.\\d{1,3})?$/.test(value);
      // const digitdecimalpattern = /^-?([1-9]?[1-9]|[1-9]0)\.{1}\d{1,6}/.test(value);
      const digitdecimalpattern = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,6})$/.test(value);

      return !digitdecimalpattern ? {pattern:true}: null;
  }
}
export function latitudeValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      // const digitdecimalpattern = /^\\d+(\\.\\d{1,3})?$/.test(value);
      // const digitdecimalpattern = /^-?([1-9]?[1-9]|[1-9]0)\.{1}\d{1,6}/.test(value);
      const digitdecimalpattern = /^-?([0-9]{1,2}|1[0-7][0-9]|90)(\.[0-9]{1,6})$/.test(value);

      return !digitdecimalpattern ? {pattern:true}: null;
  }
}

