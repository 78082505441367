<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
    <form class="side-panel-container" [formGroup]="investmentForm" autocomplete="off">
        <header class="side-panel-head">
            <h5>Filters</h5>
            <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
                <!-- New start -->
                <div class="row row-16 mb-16">
                    <div class="col-12 form-row">
                        <p class="text-uppercase fw-500 required mb-0">Financial year</p>
                    </div>
                    <div class="col-12 form-row">
                        <!-- <label for="" class="form-label ">Financial Year </label> -->
                        <ng-select class="form-ngselect " (change)="yearChange($event)"
                            placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                            formControlName="financial_year"
                            [ngClass]="{ 'is-invalid': (submitted || f.financial_year.dirty || f.financial_year.touched) && f.financial_year.errors}">
                            <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year"> {{year +'-'+
                                financialYearRange(year)}} </ng-option>
                        </ng-select>
                        <div *ngIf="(submitted || f.financial_year.dirty || f.financial_year.touched) && f.financial_year.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.financial_year.errors?.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                        </div>
                    </div>
                </div>
                <!-- New end -->
                <div class="row row-16 mb-16" [ngClass]="{ 'is-invalid': (submitted) &&  f.investment_type.errors}" formArrayName="investment_type">
                    <div class="col-12 form-row">
                        <p class="text-uppercase required fw-500 mb-0">Investment type
                        </p>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="investment_type" type="checkbox" id="investment_type1" class="form-check-input  "
                                [formControlName]=0 [value]="'proposed'">
                            <label for="investment_type1" class="form-check-label">Propose
                            </label>
                        </div>
                    </div>
                    <div class="col-12 form-row">
                        <div class="form-check">
                            <input name="investment_type" type="checkbox" id="investment_type2" class="form-check-input "
                                [formControlName]=1 [value]="'confirmed'">
                            <label for="investment_type2" class="form-check-label">Confirm</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="(submitted) && f.investment_type.errors" class="invalid-feedback">
                    <div *ngIf="f.investment_type.errors.required">
                        {{this.messageService.validationDisplay('required')}}</div>
                </div>
                <!-- New start -->
                <!-- New end -->

                <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
            </div>
        </div>
        <footer class="side-panel-footer">
            <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
                reset
            </button>
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitInvestment()">
                Apply
            </button>
        </footer>
    </form>
</div>
