import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {FormBuilder,FormGroup,FormArray, Validators, MaxLengthValidator } from '@angular/forms';
import * as moment from 'moment';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { maxTenDigitDecimal } from 'src/app/@shared/validators/maxTenDigitDecimal.validators';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';




@Component({
  selector: 'app-leave-policy-configure-leave-type',
  templateUrl: './leave-policy-configure-leave-type.component.html',
  styleUrls: ['./leave-policy-configure-leave-type.component.scss']
})

export class LeavePolicyConfigureLeaveTypeComponent implements OnInit {

  constructor(public messageService : MessageService,public appService: AppService,public existDataService : ExistDatasService,public fb : FormBuilder,public leavetypeService:leavetypeService) {
   }
  activeTab2 =1;
  showDetails =false;
  showConfigDetails = false
  leaveTypeName : any;
  leaveTypeCode : any;
  leaveTypeId = 0;
  leaveTypePlaceholder:string      = this.messageService.searchdisplay('leave type');
  leaveCreditArr :any = [];
  detailevIew = false;
  leaveTypesarr : any = [];
  searchKeyword = "";
  loader = false;
  @Input() activeTabConfig:any = [];
  @Input() mapLeaveType: any = [];
  @Input() leaveConfigForm: any;
  @Input() policyForm: any;
  @Input() editData: any;
  @Input() is_valid : any
  @Output() submitmethod = new EventEmitter();
  @Output() duplicateCheck = new EventEmitter();
  mapLeaveTypeList:any = []

  saveBtnClicked = false;
  activeIndex:any;
  tempMapdata:any;


currentIndex = 0;

  get leaveForm():FormArray {
    return this.leaveConfigForm.get('leaveform') as FormArray;
  }

  get creditdetailsForm():FormArray {
    return this.leaveConfigForm.get('creditdetailsform') as FormArray;
  }

  get leaveEligibiltyForm():FormArray {
    return this.leaveConfigForm.get('leaveEligibilty') as FormArray;
  }

  get leaveRequestLimitForm():FormArray {
    return this.leaveConfigForm.get('leaveRequestLimit') as FormArray;
  }

  get leaveRequestCriteriaForm():FormArray {
    return this.leaveConfigForm.get('leaveRequestcriteria') as FormArray;
  }

  ngOnInit(): void {
    this.loader = false;
    //  this.LeavemapFunction()
    this.activeIndex = this.mapLeaveType.length
    this.tempMapdata = this.mapLeaveType;
    this.mapLeaveTypeList =this.mapLeaveType.slice() 

 }

  // LeavemapFunction(){
  //   for(let i=0;i<this.mapLeaveType.length;i++){
  //     this.activeIndex = i;
  //   }
  // }

  AddLeaveConfig(name:any,code:any,id:any,index:any){
    this.leaveTypeName = name;
    this.leaveTypeCode = code;
    this.leaveTypeId   = id
    this.activeIndex   = index;
    this.showDetails   = false;

    setTimeout(() => {
      document?.getElementById("showdetails"+code)?.click();
    }, 100);

  }

leavemapping_creditrule(): FormArray {
  return this.creditdetailsForm.controls[this.currentIndex].get("leavemapping_creditrule") as FormArray
}
newCreditrule(): FormGroup {
  return this.fb.group({
    "effective_type"                : ['Days',[Validators.required]],
    "effective_from"                : ['',[Validators.required,NumberpatternValidator()]],
    "from_doj_doc"                  : [true,[Validators.required]],
    "do_want_to_credit_leave"       : ['Yearly',[Validators.required]],
    "no_of_leaves_credited"         : ['',[Validators.required,Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
    "which_dayof_month"             : [null,[Validators.required]],
    'leavecreditrule_firstmonth'    : this.fb.array([]),
    leavecreditrule_lastmonth       : this.fb.array([]),
    first_month_rule                : [false],
    leave_credit_timeofjoining      : ['Prorate based on credit rule start date',[Validators.required]],
    leave_credit_timeofexiting      : ['Prorate based on exit date of employee',[Validators.required]],
    last_month_rule                 : [false],
    grant_leave_prev_month          : [false],
    req_attend_calculate            : [true],
    prev_month_greater_than         : ['',[Validators.required]],
    criteria_not_met                : [true],
    roundoff_leave_granting         : [false],
    credit_lapse_enable             : [false],
    credit_lapse_cond               : [true],
    credit_lapse                    : ['',[Validators.required]],
    roundoff_option                 : [null],
    leave_opening_balance_enable    : [false],
    leave_opening_balance           : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
    carry_over_prev_creditrule      : [false],
    carry_over_limit                : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
    carry_forward_next_year         : [false],
    carry_forward_limit             : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
    max_carry_forward_status        : [false],
    max_carry_over_status           : [false]
  })
}
addcreditRule() {

  this.leavemapping_creditrule().push(this.newCreditrule());

}
addFstMonthRule(creditIndex:any){
  this.firstmonth(creditIndex).push(this.newfstMonthrule());
}
addLstMonthRule(creditIndex:any){
  this.lastmonth(creditIndex).push(this.newLstMonthrule());
}
firstmonth(creditId: number): FormArray {
  return this.leavemapping_creditrule()
    .at(creditId)
    .get('leavecreditrule_firstmonth') as FormArray;
}
lastmonth(creditId: number): FormArray {
  return this.leavemapping_creditrule()
    .at(creditId)
    .get('leavecreditrule_lastmonth') as FormArray;
}
newfstMonthrule(): FormGroup {
  return this.fb.group({
    "from_date"   : [null,[Validators.required]],
    "to_date"     : [null,[Validators.required]],
    "count_number": ['',[Validators.required,digitdecimalValidator()]],

  })
}

newLstMonthrule(): FormGroup {
  return this.fb.group({
    "from_date"   : [null,[Validators.required]],
    "to_date"     : [null,[Validators.required]],
    "count_number": ['',[Validators.required,digitdecimalValidator()]],

  })
}
  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if (this.leaveForm.controls[this.activeIndex].invalid ) {
        changeEvent.preventDefault();

      }
    }

    if (changeEvent.nextId === 3) {
      if (this.leaveForm.controls[this.activeIndex].invalid || this.creditdetailsForm.controls[this.activeIndex].invalid) {
        changeEvent.preventDefault();

      }
    }
    if (changeEvent.nextId === 4) {
      if (this.leaveForm.controls[this.activeIndex].invalid || this.creditdetailsForm.controls[this.activeIndex].invalid || this.leaveEligibiltyForm.controls[this.activeIndex].invalid) {
        changeEvent.preventDefault();

      }
    }
    if (changeEvent.nextId === 5) {
      if (this.leaveForm.controls[this.activeIndex].invalid || this.creditdetailsForm.controls[this.activeIndex].invalid || this.leaveEligibiltyForm.controls[this.activeIndex].invalid ||  this.leaveRequestCriteriaForm.controls[this.activeIndex].invalid) {
        changeEvent.preventDefault();

      }
    }
  }
  async configureFunction(){
    this.duplicateCheck.emit()
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if(this.is_valid){
        return
      }
      this.submitmethod.emit();
    } catch (error) {
      // console.error('Error:', error);
    }

}
unchangeActivetab(){

  if(this.activeTabConfig[this.activeIndex]['tab']==1)
  this.showDetails = false;
  else
  this.activeTabConfig[this.activeIndex]['tab'] = this.activeTabConfig[this.activeIndex]['tab'] - 1

}

changeActivetab(){


  switch(this.activeTabConfig[this.activeIndex]['tab']) {
    case 1: {
      this.activeTabConfig[this.activeIndex]['step1'] = true
      break;
    }
    case 2: {

      this.activeTabConfig[this.activeIndex]['step2'] = true
      break;

    }
    case 3: {

      this.activeTabConfig[this.activeIndex]['step3'] = true
      break;

    }
    case 4: {
      this.activeTabConfig[this.activeIndex]['step4'] = true
      break;

    }
    case 5: {
      this.activeTabConfig[this.activeIndex]['step5'] = true
      this.showDetails  = !this.showDetails;
      this.leaveTypeId  = 0;
      break;

    }


  }

  this.activeTabConfig[this.activeIndex]['tab'] = this.activeTabConfig[this.activeIndex]['tab']!=5?this.activeTabConfig[this.activeIndex]['tab'] + 1:this.activeTabConfig[this.activeIndex]['tab']


 }

checkFormValidity(){

switch(this.activeTabConfig[this.activeIndex]['tab']) {
   case 1: {
      return this.leaveForm.controls[this.activeIndex].invalid;

   }
   case 2: {
      return this.creditdetailsForm.controls[this.activeIndex].invalid;

   }
   case 3: {
      return this.leaveEligibiltyForm.controls[this.activeIndex].invalid;

   }
   case 4: {
      return this.leaveRequestCriteriaForm.controls[this.activeIndex].invalid;

   }
   case 5: {
     return this.leaveRequestLimitForm.controls[this.activeIndex].invalid;

   }
   default: {
      return true

   }

}
}
checkOverAllValidity(){
 let ret = false
  if(this.leaveConfigForm.invalid){
    ret = true
  }
  else{


    for(let i=0;i<this.creditdetailsForm.controls.length;i++){

      if(this.creditdetailsForm.controls[i].get('grant_leave')?.value==false &&  this.creditdetailsForm.controls[i].get('leavemapping_creditrule')?.value.length==0){

        ret = true
      }

    }
    for(let j=0;j<this.activeTabConfig.length;j++){

      if(this.activeTabConfig[j]['step1']==false || this.activeTabConfig[j]['step2']==false || this.activeTabConfig[j]['step3']==false || this.activeTabConfig[j]['step4']==false || this.activeTabConfig[j]['step5']==false){

        ret = true

      }
    }
  }
  return ret
}

checkSingleTypeValidator(){

  if(this.leaveConfigForm.valid){

    return true
  }
  else{

      if(this.creditdetailsForm.controls[this.activeIndex].get('leavemapping_creditrule')?.value.length>0)
        return true

  return
  }
}

searchLeaveType(searchKeyword:any){
  this.mapLeaveType = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.getLeavetypes();
    }

}

getLeavetypes(){
  this.loader  = true;
  this.mapLeaveType = []
  let apiData : any = []
  this.leavetypeService.getleavetypeList(true,20,0,this.searchKeyword).subscribe((res: any) => {
  if (res.results.length > 0) {
      apiData   = res.results;
      for(let i=0;i<res.results.length;i++){
        apiData[i]['name'] = res.results[i]['leave_name']
        this.mapLeaveType = apiData.filter((apiItem:any) => {
          return this.tempMapdata.find((mapItem:any) => mapItem.leaveId === apiItem.id);
        });
      }
  }
      this.loader           = false;
  });
}
searchresults(key:any){
  this.mapLeaveType     = [];
  let map_object        = this.tempMapdata.find((x:any) => x.leaveId == key);
  this.mapLeaveType.push(map_object);

}
onCleared(data:any){
  this.mapLeaveType     = [];
  this.mapLeaveType = this.tempMapdata;
}
}



