import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ShiftService } from 'src/app/@core/services/shift.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-shift-rotation',
  templateUrl: './shift-rotation.component.html',
  styleUrls: ['./shift-rotation.component.scss']
})
export class ShiftRotationComponent implements OnInit {
  viewDetail = false;
  status: any      = true;
  nodata                     = false;
  searchKeyword    = "";
  searchlistdata:any=[];
  loader           = false;
  shiftsearch:string =this.messageService.searchdisplay('Shift Rotation');
  statusBtn:any    = "Active";
  deleteId        = 0;
  deleteAlert     = false;
  deleteClicked         = false;
  shiftrot_data:any=[];
  loader1          = false;
  shiftapplicable:any=[];
  offset                 = 0;
  listlength             = 0;
  direction              = '';
  defaultlimit:any       = 20;
  shiftrotDetailsList:any   = [];
  shiftrotDetails:any      = [];
  infinityloader         = false;
  permissions:any       = [];
  appkey1:any             = [];
  assignees:any           = [];
  shiftfreq:any=[];
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;

  constructor(
    private shiftService: ShiftService,
    public appService: AppService,
    public breakpointObserver: BreakpointObserver,
    public formBuilder : FormBuilder,
    public router: Router,
    public messageService: MessageService,
    ) { }

  ngOnInit(): void {
    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.shiftrotatList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.shiftService.getshiftrotatList( this.status,'',this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  shiftrotatList() {
    this.loader = true;
    this.shiftService.getshiftrotatList( this.status,'',this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.listlength = res.results.length;
    if(this.offset ==0){
      this.shiftrotDetails = [];
    }
    if (res.results.length == 0) {
      this.nodata = this.offset == 0 ? true :false;
    }else {
      this.nodata               = false;
      this.shiftrotDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.shiftrotDetails.push(this.shiftrotDetailsList[i]);
    }
  }

  searchresults(data:any){
    this.loader = true;
    this.shiftrotDetails = [];
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  searchshift(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset           = 0;
    this.shiftrotDetails = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  statusfilter(active_status:any){
    this.status           = active_status;
    this.shiftrotDetails  = [];
    this.offset           = 0;
    this.child.clearBar();
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/shift/shift-rotation');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

 /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset         += this.defaultlimit;
    if(this.listlength){
      this.shiftrotatList();
      this.loader       = false;
      this.direction    = "down";
    }
  }

  confirmDelete(id:any){
    this.deleteClicked = true;
    this.shiftService.shiftrotatDelete(id).subscribe((res: any) => {
        this.deleteClicked = false;
        this.deleteAlert      = false;
        this.loader           = true;
        this.offset           = 0;
        this.shiftrotDetails = [];
        this.shiftrotatList();
    });
  }

  shiftedit(id:any){
    this.router.navigate(['/shift/shift-rotation-setup/'+id]);
  }

  viewShift(id:any){
    if(this.permissions.v){
      this.assignees=[];
      this.loader1 = true;
      this.viewDetail = true;
      this.shiftService.shiftrotatview(id).subscribe((res: any) => {
        this.shiftrot_data = res;
        this.shiftapplicable= this.shiftrot_data.shiftrotationassign[0];
        this.shiftfreq= this.shiftrot_data.shiftrotationconfigure[0];
        for(let key of res.shiftrotationassign){
          let appkey = key.applicable.toLowerCase();
          if(appkey == "bu"){
            appkey = "businessunit";
            key.applicable ="BU"
          }
          this.appkey1 = appkey.toString();
          if(appkey=="employee"){
            let empName = key[this.appkey1]['first_name']+(key[this.appkey1]['middle_name']!=null && key[this.appkey1]['middle_name']!=""?" " +key[this.appkey1]['middle_name']:"")+" "+key[this.appkey1]['last_name'] +" ("+key[this.appkey1]['employee_code']+")";
            let empImg  = key[this.appkey1]['profile_image'];
            let color_code  = key[this.appkey1]['color_code'];
            this.assignees.push({'name': empName,'img': empImg, 'color_code':color_code})
          }else if(appkey=="company"){
            let compName = key.company;
            let compImg  = key.company_logo;
            this.assignees.push({'name': compName,'img': compImg})
          }else{
          this.assignees.push(key[this.appkey1]);
          }
        }
        this.loader1 = false;
      })
    }
  }

  ordinal_suffix_of(i:number) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
  }

  // function for add in no data screen
  addNewdata(){
    this.router.navigate(['/shift/shift-rotation-setup']);
  }
}
