
<div class="d-flex mb-16">
    <p class="text-uppercase fw-500 mb-0">OVERTIME REQUEST</p>
</div>
<div class="row row-24 mb-24">
  <div class="col-12">
    <div class="card details-card shadow-0 px-24 py-16">
        <div class="row row-24">
            <div class="col-12 d-flex">
                <span class="title">Overtime details</span>
                <div class="d-flex align-items-center gap-8 ms-auto">
                  <span class="ribbon ribbon-right ribbon-danger" *ngIf="viewData?.status == 'Invalid'">Invalid</span>
                  <span *ngIf="viewData?.status != 'Invalid'" class=" fs-14 ms-auto px-2 fw-500 badge3" [ngClass]="{'badge-warning': viewData?.status === 'Pending','badge-success': viewData?.status === 'Accepted','badge-danger': viewData?.status === 'Rejected'}"  >
                    {{viewData?.status}}
                  </span>
                </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 p-16">
                <div class="row row-24">
                  <div class="col-12">
                    <span class="title">Actual shift time</span>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Check-in</div>
                    <div class="detail-desc">{{this.appservice.timeFormatDisplay(viewData?.actual_shift_details?.check_in)}}</div>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Check-out</div>
                    <div class="detail-desc">{{this.appservice.timeFormatDisplay(viewData?.actual_shift_details?.check_out)}}</div>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Total hours</div>
                    <div class="detail-desc">{{this.appservice.hhmmTimeFormat(viewData?.actual_shift_details?.total_hour)}} Hours</div>
                  </div>
                  <div class="col-12">
                    <span class="title">Employee Check-in and Check-out time</span>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Overtime date</div>
                    <div class="detail-desc">{{this.appservice.dateFormatDisplay(viewData?.overtime_date)+', '+viewData?.day_of_week}}</div>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Check-in</div>
                    <div class="detail-desc">{{this.appservice.timeFormatDisplay(viewData?.checkin_time)}}</div>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Check-out</div>
                    <div class="detail-desc">{{this.appservice.timeFormatDisplay(viewData?.checkout_time)}}</div>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Total working hours</div>
                    <div class="detail-desc">{{this.appservice.hhmmTimeFormat(viewData?.total_work_hours)}} Hours</div>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Extra working hours</div>
                    <div class="detail-desc">{{this.appservice.hhmmTimeFormat(viewData?.extra_hours)}} Hours</div>
                  </div>
                  <div class="col-md-4">
                    <div class="detail-title">Final overtime hours</div>
                    <div class="detail-desc">{{this.appservice.hhmmTimeFormat(viewData?.final_hours)}} Hours</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>
