import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { TaxDeductorService } from 'src/app/@core/services/tax-deductor.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { EmailinputValidator } from 'src/app/@shared/validators/emailinput.validators';
import { DesignationService } from 'src/app/@core/services/designation.service';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { PanfieldValidator } from 'src/app/@shared/validators/panfield.validators';
import { PinValidator } from 'src/app/@shared/validators/pinnum.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { PhonenoValidator } from 'src/app/@shared/validators/phoneno.validators';
import { CompanyService } from 'src/app/@core/services/company.service';
import { TanValidator } from 'src/app/@shared/validators/tan.validators';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-tax-deductor',
  templateUrl: './tax-deductor.component.html',
  styleUrls: ['./tax-deductor.component.scss']
})

export class TaxDeductorComponent implements OnInit {
  viewTaxDeductor = false
  showSidePanel = false;
  permissions:any  = [];
  loader = false;
  selectedYear :any;
  currentYear = new Date().getFullYear();
  company_id:any = null;
  offset = 0;
  listlength = 0;
  direction = '';
  defaultlimit:any = 20;
  taxDeductorList:any = [];
  infinityloader = false;
  items:any[] = [];
  nodata = false;
  searchlistdata = [];
  searchString = this.messageService.searchdisplay('company');
  searchKeyword = "";
  formSubmitted = false
  addressChecked = true;
  addressPChecked = true;
  DesignationDD:any  = [];
  BranchDD:any  = [];
  id=0;
  regionList:any = [];
  citylist2:any = [];
  citylist1:any = [];
  taxviewdata:any=[];
  statusBtn = "All"
  status = ""
  isLoading = false
  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) child:any;

  formGp:any  = this.formBuilder.group({
    id :0,
    include_nil_tds: [false, [Validators.required]],
    employee_zero_gross: [false, [Validators.required]],
    type_of_deductor: [null, [Validators.required]],
    payment_section_code: [null, [Validators.required]],

    address_deductor_last: [false, [Validators.required]],
    deductor_name: [{value: '', disabled: this.addressChecked}, [Validators.required,NamefieldValidator(),Validators.maxLength(100)]],
    tan_number: [{value: '', disabled: this.addressChecked}, [Validators.required,TanValidator()]],
    deductor_pan_number: [{value: '', disabled: this.addressChecked}, [Validators.required,PanfieldValidator()]],
    branch_name: [{value: '', disabled: this.addressChecked}, [Validators.required,NamefieldValidator(),Validators.maxLength(100)]],
    deductor_flat_number: [{value: '', disabled: this.addressChecked}, [Validators.required, Validators.maxLength(50),UnamepatternValidator]],
    deductor_name_of_building: [{value: '', disabled: this.addressChecked}, [Validators.maxLength(500),UnamepatternValidator()]],
    deductor_street_road: [{value: '', disabled: this.addressChecked},[Validators.maxLength(500),UnamepatternValidator()]],

    deductor_area_location: [{value: '', disabled: this.addressChecked}, [Validators.maxLength(500),UnamepatternValidator()]],
    deductor_pin: [{value: '', disabled: this.addressChecked}, [Validators.required,PinValidator()]],
    deductor_std_code: [{value: '', disabled: this.addressChecked}, [Validators.maxLength(5),NumberpatternValidator()]],
    deductor_phone_number: [{value: '', disabled: this.addressChecked}, [PhonenoValidator()]],
    deductor_email: [{value: '', disabled: this.addressChecked}, [Validators.required,EmailinputValidator()]],
    deductor_state: [{value: '', disabled: this.addressChecked}, [Validators.required]],
    deductor_city: [{value: '', disabled: this.addressChecked}, [Validators.required]],

    address_tds_last: [false, [Validators.required]],
    name: [{value: '', disabled: this.addressPChecked}, [Validators.required,NamefieldValidator(),Validators.maxLength(100)]],
    person_pan_number: [{value: '', disabled: this.addressPChecked}, [Validators.required,PanfieldValidator()]],
    person_flat_number: [{value: '', disabled: this.addressPChecked}, [Validators.required, Validators.maxLength(50),UnamepatternValidator]],
    person_name_of_building: [{value: '', disabled: this.addressPChecked}, [Validators.maxLength(500),UnamepatternValidator()]],
    person_street_road: [{value: '', disabled: this.addressPChecked}, [Validators.maxLength(500),UnamepatternValidator()]],
    person_area_location: [{value: '', disabled: this.addressPChecked}, [Validators.maxLength(500),UnamepatternValidator()]],
    person_pin: [{value: '', disabled: this.addressPChecked}, [Validators.required,PinValidator()]],
    person_std_code: [{value: '', disabled: this.addressPChecked}, [Validators.maxLength(5),NumberpatternValidator()]],
    person_phone_number: [{value: '', disabled: this.addressPChecked}, [PhonenoValidator()]],
    person_mobile_number: [{value: '', disabled: this.addressPChecked}, [Validators.required,PhonenoValidator()]],
    person_email: [{value: '', disabled: this.addressPChecked}, [Validators.required,EmailinputValidator()]],
    person_state: [{value: '', disabled: this.addressPChecked}, [Validators.required]],
    person_city: [{value: '', disabled: this.addressPChecked}, [Validators.required]],
    designation: [{value: '', disabled: this.addressPChecked}, [Validators.required]],
    company: 0,

  });

  constructor(
    private taxDeductorService:TaxDeductorService,
    public breakpointObserver: BreakpointObserver,
    private appService:AppService,
    private sds: SalaryDashboardService,
    public messageService: MessageService,
    private formBuilder : FormBuilder,
    private designationService:DesignationService,
    private employeeService:EmployeeService,
    private companyService: CompanyService
    ) { }

    get f() {return  this.formGp.controls; }

  ngOnInit(): void {
    this.getPermission();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.selectedYear = this.appService.getFinancialYear();
    this.showDataItems();
    this.regionList1()
    this. designationListData()
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.taxDeductorService.getTaxDeductorList(this.selectedYear,this.company_id,this.defaultlimit,this.offset,this.searchKeyword,this.status)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

  regionList1() {
      this.companyService.getRegionList(105)
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.regionList = res.body;
        }
      })
  }

  cityList() {
    if(this.formGp.getRawValue().deductor_state !=''){
    this.companyService.getCityList(this.formGp.getRawValue().deductor_state, 'true')
    .subscribe((res: any) => {
      if (res.length != 0) {
        this.citylist1 = res.body;
      }
    })
    }
  }

  cityList2() {
    if(this.formGp.getRawValue().person_state !=''){
      this.companyService.getCityList(this.formGp.getRawValue().person_state, 'true')
      .subscribe((res: any) => {
        if (res.length != 0) {
          this.citylist2 = res.body;
        }
      })
    }
  }

  showDataItems(){
    this.loader   = true;
    this.taxDeductorService.getTaxDeductorList(this.selectedYear,this.company_id,this.defaultlimit,this.offset,this.searchKeyword,this.status).subscribe((res: any) => {
      this.arrangeApiData(res)
    });
  }

  arrangeApiData(res:any){
    this.isLoading = false
    this.taxDeductorList = res.results
    this.listlength = res.results.length;
    if(this.offset == 0){
      this.items    = [];
      this.nodata = this.taxDeductorList.length==0?true:false;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.items.push(this.taxDeductorList[i]);
    }
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  searchresult(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword = '';
      this.company_id = null;
      this.searchData.next()
    } else {
      this.company_id = data;
      this.searchData.next()
    }
    this.offset = 0;
    this.items = [];
  }

  searchconfig(searchKeyword:any){
    this.loader = true;
    this.isLoading = true;
    this.offset            = 0;
    this.items = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }

  }

  yearChange(year:any){
    this.selectedYear = year;
    this.loader = true;
    this.showDataItems();
  }

  isChecked(isChecked:any){
    this.addressChecked = !isChecked;
    if (this.addressChecked) {
      this.formGp.get('deductor_name').disable();
      this.formGp.get('tan_number').disable();
      this.formGp.get('deductor_pan_number').disable();
      this.formGp.get('branch_name').disable();
      this.formGp.get('deductor_flat_number').disable();
      this.formGp.get('deductor_name_of_building').disable();
      this.formGp.get('deductor_street_road').disable();
      this.formGp.get('deductor_area_location').disable();
      this.formGp.get('deductor_pin').disable();
      this.formGp.get('deductor_std_code').disable();
      this.formGp.get('deductor_phone_number').disable();
      this.formGp.get('deductor_email').disable();
      this.formGp.get('deductor_state').disable();
      this.formGp.get('deductor_city').disable();
    } else {
      this.enableAddress();
    }
  }

  isPChecked(isChecked:any){
    this.addressPChecked = !isChecked;
    if (this.addressPChecked) {
      this.formGp.get('name').disable();
      this.formGp.get('person_pan_number').disable();
      this.formGp.get('person_flat_number').disable();
      this.formGp.get('person_name_of_building').disable();
      this.formGp.get('person_street_road').disable();
      this.formGp.get('person_area_location').disable();
      this.formGp.get('person_pin').disable();
      this.formGp.get('person_std_code').disable();
      this.formGp.get('person_phone_number').disable();
      this.formGp.get('person_mobile_number').disable();
      this.formGp.get('person_email').disable();
      this.formGp.get('person_state').disable();
      this.formGp.get('person_city').disable();
      this.formGp.get('designation').disable();
    } else {
      this.enableAddressp();
    }
  }
  enableAddressp(){
    this.formGp.get('name').enable();
    this.formGp.get('person_pan_number').enable();
    this.formGp.get('person_flat_number').enable();
    this.formGp.get('person_name_of_building').enable();
    this.formGp.get('person_street_road').enable();
    this.formGp.get('person_area_location').enable();
    this.formGp.get('person_pin').enable();
    this.formGp.get('person_std_code').enable();
    this.formGp.get('person_phone_number').enable();
    this.formGp.get('person_mobile_number').enable();
    this.formGp.get('person_email').enable();
    this.formGp.get('person_state').enable();
    this.formGp.get('person_city').enable();
    this.formGp.get('designation').enable();
  }

  enableAddress(){
    this.formGp.get('deductor_name').enable();
    this.formGp.get('tan_number').enable();
    this.formGp.get('deductor_pan_number').enable();
    this.formGp.get('branch_name').enable();
    this.formGp.get('deductor_flat_number').enable();
    this.formGp.get('deductor_name_of_building').enable();
    this.formGp.get('deductor_street_road').enable();
    this.formGp.get('deductor_area_location').enable();
    this.formGp.get('deductor_pin').enable();
    this.formGp.get('deductor_std_code').enable();
    this.formGp.get('deductor_phone_number').enable();
    this.formGp.get('deductor_email').enable();
    this.formGp.get('deductor_state').enable();
    this.formGp.get('deductor_city').enable();
  }

  designationListData(){
    this.designationService.designationListData(true).subscribe((res: any) => {
        this.DesignationDD = res;
    });
  }

  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
          this.BranchDD = res;
    });
  }

  saveForm(){
    this.formSubmitted = true;
    var dataArray = {
      "include_nil_tds":this.formGp.controls.include_nil_tds.value?this.formGp.controls.include_nil_tds.value: false,
      "employee_zero_gross": this.formGp.controls.employee_zero_gross.value?this.formGp.controls.employee_zero_gross.value:false,
      "type_of_deductor": this.formGp.controls.type_of_deductor.value?this.formGp.controls.type_of_deductor.value:null,
      "payment_section_code": this.formGp.controls.payment_section_code.value?this.formGp.controls.payment_section_code.value:null,
      "address_deductor_last": this.formGp.controls.address_deductor_last.value?this.formGp.controls.address_deductor_last.value:false,
      "deductor_name": this.formGp.controls.deductor_name.value?this.formGp.controls.deductor_name.value:null,
      "tan_number": this.formGp.controls.tan_number.value?this.formGp.controls.tan_number.value:null,
      "deductor_pan_number": this.formGp.controls.deductor_pan_number.value?this.formGp.controls.deductor_pan_number.value:null,
      "branch_name": this.formGp.controls.branch_name.value?this.formGp.controls.branch_name.value:null,
      "deductor_flat_number": this.formGp.controls.deductor_flat_number.value?this.formGp.controls.deductor_flat_number.value:null,
      "deductor_name_of_building": this.formGp.controls.deductor_name_of_building.value?this.formGp.controls.deductor_name_of_building.value:null,
      "deductor_street_road": this.formGp.controls.deductor_street_road.value?this.formGp.controls.deductor_street_road.value:null,
      "deductor_area_location": this.formGp.controls.deductor_area_location.value?this.formGp.controls.deductor_area_location.value:null,
      "deductor_pin": this.formGp.controls.deductor_pin.value?this.formGp.controls.deductor_pin.value:null,
      "deductor_std_code": this.formGp.controls.deductor_std_code.value?this.formGp.controls.deductor_std_code.value:null,
      "deductor_phone_number": this.formGp.controls.deductor_phone_number.value?this.formGp.controls.deductor_phone_number.value:null,
      "deductor_email": this.formGp.controls.deductor_email.value?this.formGp.controls.deductor_email.value:null,

      "address_tds_last": this.formGp.controls.address_tds_last.value?this.formGp.controls.address_tds_last.value:false,
      "name": this.formGp.controls.name.value?this.formGp.controls.name.value:null,
      "person_pan_number": this.formGp.controls.person_pan_number.value?this.formGp.controls.person_pan_number.value:null,

      "person_branch_name": this.formGp.controls.person_branch_name?.value?this.formGp.controls.person_branch_name?.value:null,

      "person_flat_number": this.formGp.controls.person_flat_number.value?this.formGp.controls.person_flat_number.value:null,
      "person_name_of_building": this.formGp.controls.person_name_of_building.value?this.formGp.controls.person_name_of_building.value:null,
      "person_street_road": this.formGp.controls.person_street_road.value?this.formGp.controls.person_street_road.value:null,
      "person_area_location": this.formGp.controls.person_area_location.value?this.formGp.controls.person_area_location.value:null,
      "person_pin": this.formGp.controls.person_pin.value?this.formGp.controls.person_pin.value:null,
      "person_std_code": this.formGp.controls.person_std_code.value?this.formGp.controls.person_std_code.value:null,
      "person_phone_number": this.formGp.controls.person_phone_number.value?this.formGp.controls.person_phone_number.value:null,
      "person_mobile_number": this.formGp.controls.person_mobile_number.value?this.formGp.controls.person_mobile_number.value:null,
      "person_email": this.formGp.controls.person_email.value?this.formGp.controls.person_email.value:null,
      "deductor_state": this.formGp.controls.deductor_state.value?this.formGp.controls.deductor_state.value:null,
      "deductor_city": this.formGp.controls.deductor_city.value?this.formGp.controls.deductor_city.value:null,
      "designation": this.formGp.controls.designation.value?this.formGp.controls.designation.value:null,
      "person_state": this.formGp.controls.person_state.value?this.formGp.controls.person_state.value:null,
      "person_city": this.formGp.controls.person_city.value?this.formGp.controls.person_city.value:null,

      "year": this.selectedYear,
      "status": true,
      "is_active": true,
      "company": this.formGp.controls.company.value?this.formGp.controls.company.value:null,
   };

    this.taxDeductorService.taxDeductorSave(dataArray,this.id).subscribe((res: any) => {
      this.showDataItems();
      this.showSidePanel =false;
    });
    this.formSubmitted = false
  }

  editForm(){
    this.taxDeductorService.getDeductorDetail(this.id).subscribe((res: any) => {
      this.setEditForm(res);
    },(error)=>{});
  }

  setEditForm(res:any){
    this.formSubmitted = false;
    this.formGp.reset(
      {
        id:this.id,
        include_nil_tds : res.include_nil_tds,
        employee_zero_gross : res.employee_zero_gross,
        type_of_deductor : res.type_of_deductor,
        payment_section_code : res.payment_section_code,
        address_deductor_last : res.address_deductor_last,
        deductor_name : res.deductor_name,
        tan_number : res.tan_number,
        deductor_pan_number : res.deductor_pan_number,
        branch_name : res.branch_name,
        deductor_flat_number : res.deductor_flat_number,
        deductor_name_of_building : res.deductor_name_of_building,
        deductor_street_road : res.deductor_street_road,
        deductor_area_location : res.deductor_area_location,
        deductor_pin : res.deductor_pin,
        deductor_std_code : res.deductor_std_code,
        deductor_phone_number : res.deductor_phone_number,
        deductor_email : res.deductor_email,
        address_tds_last : res.address_tds_last,
        name : res.name,
        person_pan_number : res.person_pan_number,
        person_branch_name : res.person_branch_name,
        person_flat_number : res.person_flat_number,
        person_name_of_building : res.person_name_of_building,
        person_street_road : res.person_street_road,
        person_area_location : res.person_area_location,
        person_pin : res.person_pin,
        person_std_code : res.person_std_code,
        person_phone_number : res.person_phone_number,
        person_mobile_number : res.person_mobile_number,
        person_email : res.person_email,
        year : res.year,
        status : res.status,
        is_active : res.is_active,
        company : res.company,
        deductor_state : res.deductor_state,
        deductor_city : res.deductor_city,
        designation : res.designation,
        person_state : res.person_state,
        person_city : res.person_city
      });
      if(this.formGp.getRawValue().deductor_state !='' && this.formGp.getRawValue().deductor_state != null)
        this.cityList()
      if(this.formGp.getRawValue().person_state !='' && this.formGp.getRawValue().person_state != null)
        this.cityList2();
      if(res.status == false){
        this.formGp.get('address_deductor_last').disable();
        this.formGp.get('address_tds_last').disable();
        this.enableAddressp()
        this.enableAddress()
      }
  }

  viewtaxdata(){
    this.taxDeductorService.getDeductorDetail(this.id).subscribe((res: any) => {
      this.taxviewdata =res;
    },(error)=>{});
  }

  listFilter(value:any){
    this.offset = 0
    this.status = value
    this.child.clearBar();
  }
}
