import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/@core/auth/auth.guard';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { InboxComponent } from '../inbox/inbox.component';
import { MySalaryStructureComponent } from '../profile/my-finance/my-salary-structure/my-salary-structure.component';
import { PayslipComponent } from '../profile/my-finance/payslip/payslip.component';
import { TaxslipComponent } from '../profile/my-finance/taxslip/taxslip.component';
import { MyReimbursementComponent } from '../profile/my-reimbursement/my-reimbursement.component';
import { RequestComponent } from '../request/request/request.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';


const routes: Routes = [LayoutService.childRoutes([
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component:  DashboardComponent},
  { path: 'dashboard', component: HomeComponent },
  { path: 'my-salary-structure', component: MySalaryStructureComponent },
  { path: 'request', component: RequestComponent },
  { path: 'payslip', component: PayslipComponent },
  { path: 'inbox', component: InboxComponent },
  { path: 'reimbursement', component: MyReimbursementComponent },
  { path: 'taxslip', component: TaxslipComponent },
])];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
