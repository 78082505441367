<div class="modal  modal-alert {{isProgressStart==true ? 'show': ''}}">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        
        <!-- <h4 class="modal-title">Preparing</h4> -->
        <h4 class="modal-title">{{heading}}</h4>

      </div>
      <div class="modal-body">
        <!-- <p class="mb-0">Preparing generation of Form 16 documents</p> -->
        <p class="mb-0 d-flex align-items-center">
          <i class="icon-arrow-repeat text-accent1 me-8" *ngIf="!iscompleted"></i>
          <i class="icon-check text-success me-8" *ngIf="iscompleted"></i>
          {{body}}</p>
        <div class="progress progress-type1 progress-pending mt-24 mb-8" *ngIf="!iscompleted">
          <div class="progress-bar" role="progressbar"  [style.width]="progress+'%'" aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">
          </div>
          <div class="progress-details " >
              <span class="me-auto text-success">{{processingText}}</span>
              <span class="flex-center">{{progress!=''?progress:100}}%</span>
          </div>
        </div>
        <div class="progress progress-type1 progress-complete mt-24 mb-8" *ngIf="iscompleted">
          <div class="progress-bar" role="progressbar" [style.width]="progress+'%'" aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">
          </div>
          <div class="progress-details " >
              <span class="me-auto  text-success">Done</span>
              
          </div>
        </div>
        
        <div class="d-flex flex-column align-items-center" *ngIf="timeLeft != ''">
          <span class="ms-auto fs-12 text-light-400 pb-16">{{timeLeft}} remaining</span>
          <button class=" px-5 fs-14 fw-600 rounded-0 btn btn-outline-primary text-uppercase" (click)="closePanel.emit();">Run In Background</button>
        </div>
        <div class="d-flex flex-column align-items-center" *ngIf="showClose && timeLeft==''">
          <button class=" px-5 fs-14 fw-600 rounded-0 btn btn-outline-primary text-uppercase" (click)="showClose=false;closePanel.emit();">Run In Background</button>
        </div>
      </div>

    </div>
  </div>
</div>
