import { Component, EventEmitter, OnInit, Output, Input, ChangeDetectorRef } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-loan-details-import',
  templateUrl: './loan-details-import.component.html',
  styleUrls: ['./loan-details-import.component.scss']
})
export class LoanDetailsImportComponent implements OnInit {

  @Input()  viewSidePanel : any
  @Output() closePanel = new EventEmitter()

  fileSize       : string    = '';
  fileName       : string    = '';
  invalidFormat              = false;
  deleteToggle               = false;
  deleteMsg                  = "";
  iscompleted                = false;
  isDataUpload               = 0;
  percentage     :any        = 0;
  isProgressStart            = false;
  successDataCount           = 0;
  downloadMsg                = false;
  invalid                    = false
  confirmMsg                 = false;
  exportData     :any        = [];
  excelData      :any        = [];
  importData     :any        = [];
  failedData     :any        = [];
  importTemp     :any        = [];
  errorMsg                   = '';
  successMsg                 = '';
  body                       = '';
  ws             :any        = null;
  saveDataArray  :any        = []
  chunkLen                   = 10;

  constructor(
    public appService         : AppService,
    private changeDetectorRef : ChangeDetectorRef,
    public messageServ        : MessageService
  ) { }

  ngOnInit(): void {
  }

  // File Change
  onFileChange(evt: any) {
    this.excelData  = [];
    this.importData = []
    this.iscompleted = false
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true });
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];
        this.fileName   = target.files[0].name;
        this.fileSize   = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData  = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
        this.changeDetectorRef.detectChanges();
      };
      reader.readAsBinaryString(target.files[0]);
    }else{
      this.invalidFormat = true;
    }
  }

  // Processing Data
  processData(){
    if(this.excelData.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.excelData[0];
      let Heading = ['Loan Type','Loan Amount','Number of EMIs','Interest rate','Interest Penalty on Payment shortfall rate','Loan Required date','Number of Remaining EMIs','Loan (Principal) Amount already paid','Interest already paid(This field is required only in case of reducible interest type)','EMI deduction Start Month', 'Loan Request Date', 'Loan Dispense Date']
      let success = Heading.every(function(val:any) {

        return headerRow.indexOf(val) !== -1;
      });
      this.invalid = headerRow.every((element:any) => Heading.includes(element));
      const hasDuplicates = new Set(headerRow).size !== headerRow.length;
      if(success == false || !this.invalid || hasDuplicates){
        this.deleteToggle = true;
        this.deleteMsg = this.messageServ.invalidExcelFormat();
        this.clearData();
      }
      else
       this.prepareUploadData()
    }
  }

  prepareUploadData(){

  }

  uploadLoanData(){

  }

  closeSidePanel(){
    this.viewSidePanel = false
    this.closePanel.emit(this.viewSidePanel)
  }

  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
  }
}
