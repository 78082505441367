import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/message.global';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder,FormGroup,Validators,FormArray } from '@angular/forms';
import { GeolocationService } from '../../../../@core/services/geo-location.service';
import { AppService } from 'src/app/app.global';
import { RoleService } from 'src/app/@core/services/role.service';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-assign-employee-location-setup',
  templateUrl: './assign-employee-location-setup.component.html',
  styleUrls: ['./assign-employee-location-setup.component.scss']
})
export class AssignEmployeeLocationSetupComponent implements OnInit {
  activeTab         =1;
  disabled          = false;
  res               :any;
  id: number = 0;
  editflag: boolean = false;
  loader     = false;
  title ="Add";
  myForm: FormGroup = this.fb.group({
    id: 0,
    employees: this.fb.group({
      employee: ['', [Validators.required]]
    }),
    locations: this.fb.group({
      geofencing_locations             : this.fb.array([])
    })

  });
  employeeDropdown:any=[];

  isEditForm = false;
  tab2touched = false
  locationdropdown:any
  constructor(private fb:FormBuilder,
    public messageService : MessageService,
    public GeolocationService : GeolocationService,
    public router: Router,
    public route:ActivatedRoute,
    public appservice:AppService,
    private rs      : RoleService,
    private notificationService:NotificationService
    ) { }

  ngOnInit(): void {
    this.getEmployeeList();
    this.getlocation()
  }
  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.myForm['controls'].employees.invalid) {
        changeEvent.preventDefault();
      }else{
        this.tab2touched = true
      }
    }
  }
  get employeesForm() {
    return this.myForm.get('employees') as FormGroup;
  }

  get locationsForm() {
    return this.myForm.get('locations') as FormGroup;
  }

  submitMethod(activeTab: any){
    this.activeTab = activeTab;
  }

  submitForm(){
    var dataArray = {
    "employee_ids":this.employeesForm.controls.employee?.value,
    "geofencing_locations":this.locationsForm.controls.geofencing_locations?.value,
   };

    if(this.id == 0){
      this.GeolocationService.EmpassignCreate(dataArray).subscribe((res: any) => {
        if(res.body.data_already_exists){
          this.notificationService.handleErrorNotification("Geo-location has already been assigned to this employee.","Error");
        }
        this.router.navigate(['geo-fencing/assign-geo-location']);
      })
    }
  }

  // locationarray() : FormArray {
  //   return this.locationsForm.get("geofencing_locations") as FormArray
  // }

  // newlocationarray(): FormGroup {
  //   return this.fb.group({
  //     "geofencing_location_id" : [null,Validators.required],
  //     "for_specific_days_only" : [null,Validators.required],
  //     "duration_of_days"       : [0,Validators.required],
  //   })
  // }

  // addlocation() {
  //   this.locationarray().push(this.newlocationarray());
  // }


  // Employee List
  getEmployeeList(){
    this.loader = true;
    this.rs.getempList(true).subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name']  = "";
            if(res.body[i]['first_name'] != null)
              res.body[i]['name'] += res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
              res.body[i]['name'] += res.body[i]['last_name'];
              res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";
          }
          this.employeeDropdown = res.body;
          this.loader = false;
        }
      }
    });
  }
  getlocation(){
    this.GeolocationService.locationListData(true).subscribe((res: any) => {
      this.locationdropdown =  res.body;
    });
  }
}
