import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import { FileUploadService } from 'src/app/@core/services/file-upload.service';
import { Form16Service } from 'src/app/@core/services/form16.service';
import { AppService } from 'src/app/app.global';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import * as moment from 'moment';


@Component({
  selector: 'app-upload-part-a',
  templateUrl: './upload-part-a.component.html',
  styleUrls: ['./upload-part-a.component.scss']
})
export class UploadPartAComponent implements OnInit {
  activeButton          = false;
  filecontent:any       = [];
  form16_A:any          = [];
  permissions:any       = [];
  invalid               = false;
  ZipFile:any = {};
  fileData:any;
  deleteMsg:any;
  loginId:any;
  fullName:any;

  @Output() saveForm16A         = new EventEmitter();
  @Output() Form16A_ZipFile     = new EventEmitter();
  @Output() Form16A_FileData    = new EventEmitter();
  @Output() deleteUploadedFile  = new EventEmitter();
  @Input() fileName:any;
  @Input() fileSize:any;
  @Input() progress:any;
  @Input() iscompleted:any;
  @Input() isProgressStart:any;
  @Input() deleteToggle:any;
  @Input() uploadedEmployee:any;
  @Input() deletepopup_A:any;
  @Input() financialyear:any;


    constructor(private upload: FileUploadService,private formService:Form16Service,private appService: AppService,private authService:AuthService,private snapshotService: SnapshotService) { }

  ngOnInit(): void {
  this.getPermission();
  this.loginId     = this.authService.isEmployeeId;
  this.getEmpBasicData();

  }
  getEmpBasicData() {
    if(this.loginId!=0){

      this.snapshotService.getEmployeeBasicData(this.loginId).subscribe((res: any) => {
        this.fullName = res.first_name +" " +(res.middle_name != null ? res.middle_name: "") +" " +res.last_name;

      })
    }
    else if(this.authService.isSuperUser()){

      this.fullName = "SkizzleHR";
    }
    else
      this.fullName  = "";

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/form-16');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  onChange(file:any){
    // let currentYear     = this.appService.getFinancialYear();
    let currentYear     = this.financialyear;
    let fileList        = file.target.files;
    if(fileList[0].name!=undefined && fileList[0].name!=null && fileList[0].name!=''){

      const extension     = fileList[0].name.split('.').pop();

      if(extension=='zip'){
        this.invalid       = false;
        this.fileName      = fileList[0].name;
        this.fileSize      = this.appService.formatSizeUnits(fileList[0].size);

        const reader       = new FileReader();
        this.activeButton  = true;
        reader.readAsDataURL(fileList[0]);

        reader.onload = event => {
          this.ZipFile    = {filename:this.fileName, zip_file:reader.result,year:currentYear,doc_type:'partA',file_size:this.fileSize};
          this.Form16A_FileData.emit(this.ZipFile)

        }
       this.FormatSizeUnitsAll(fileList[0].size);
      }
      else{
        this.invalid      = true;

      }
    }

  }
  FormatSizeUnitsAll(bytes:any){
    const current           = new Date();
    const Date_value        = moment(current).format('YYYY-MM-DD');
    const Time              = moment(current).format('HH:mm:ss');
    let convert_Time        = this.appService.timeFormatDisplay(Time);
    let convert_Date        = this.appService.dateFormatDisplay(Date_value);
    this.uploadedEmployee   = this.fullName+" ("+convert_Time+", "+convert_Date+")";
    this.fileData  = {'name':this.fileName,'size':this.fileSize,'updatedEmp':this.uploadedEmployee};
    this.Form16A_ZipFile.emit(this.fileData);

  }
  deleteFile(){

    if(this.deletepopup_A=="")
    this.deletepopup_A  ="you";
    this.deleteToggle   = true;

  }
  deletPartA(){
    this.deleteUploadedFile.emit("partA");
    this.deleteToggle = false;
  }
  submitfile(){
    this.saveForm16A.emit(this.ZipFile);

  }


}
