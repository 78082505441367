
  <div class="row row-16">
    <div class="col-12 d-flex gap-8  align-items-center">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
      <button class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
      (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8">Manage Asset </h5>
        <div class="d-flex align-items-center ms-auto gap-8">
          <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
          *ngIf="requestitem?.approved_status == 'Pending'">Pending</span>
          <span class="badge px-16 ms-auto fs-14 badge-success py-1"
          *ngIf="requestitem?.approved_status == 'Approved'">Accepted</span>
          <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
          *ngIf="requestitem?.approved_status == 'Rejected'">Rejected</span>

        </div>
      </div>
      <span class=" badge-custom {{requestitem?.request_type_color_code}}">Manage Asset </span>
      <span *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'" class="ribbon ribbon-right ribbon-danger" style="right: 0.5rem; bottom: 0">Overdue</span>
    </div>



    <div class="col-12">
     <p class="mb-0 fs-14" [innerHTML]="requestitem?.message"></p>
    </div>

    <div class="col-12">
      <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
        <div class="row row-16 ">
          <div class="col-12 d-flex ">
            <p class="title mb-8">Employee Details</p>
            <span class="ms-auto {{requestitem?.requests?.employee_type == 'NEW JOINEE' ? 'text-success' : requestitem?.requests?.employee_type == 'RELIEVING' ? 'text-danger' : '' }} fs-12 fw-500 text-uppercase">{{requestitem?.requests?.employee_type == 'NEW JOINEE' ? 'NEW JOINEE' :  requestitem?.requests?.employee_type == 'RELIEVING' ? 'relieving employee' : ''}}</span>
          </div>
          <div class="col-sm-3 ">
            <div class="detail-title">Employee name</div>
            <div class="detail-desc">
              <img *ngIf="(requestitem?.from_person !=null && (requestitem?.from_person?.profile_image != '' && requestitem?.from_person?.profile_image != null))"
                  class="user-img img-fluid rounded-circle sq-32rem"
                  src="{{requestitem?.from_person?.profile_image}}">
              <div *ngIf="(requestitem?.from_person !=null && (requestitem?.from_person?.profile_image == '' || requestitem?.from_person?.profile_image == null))"
                  class="avatar-circle  fs-14rem fw-500 {{requestitem?.from_person?.color_code}} sq-32rem rounded-circle">
                  <span>{{
                    this.appservice.getFirstChar(
                      requestitem?.from_person?.first_name +
                        "
                    " +
                    requestitem?.from_person?.last_name,
                      2
                    )
                  }}</span>
              </div>
              <div class="detail-desc p-2"
                  *ngIf="requestitem?.from_person !=null && requestitem?.from_person !='';else other_content">
                  {{(requestitem?.from_person?.first_name+"
                  "+(requestitem?.from_person?.middle_name
                  !=null?requestitem?.from_person?.middle_name:'')+"
                  "+requestitem?.from_person?.last_name)}}
              </div>
              <ng-template #other_content>
                  <div class="detail-desc p-2"> -
                  </div>
              </ng-template>
          </div>
          </div>
          <div class="col-sm-3 ">
            <div class="detail-title">EMP code</div>
            <div class="detail-desc">{{requestitem?.from_person?.employee_code}}</div>
          </div>
          <div *ngIf="requestitem?.requests?.employee_type == 'RELIEVING'" class="col-sm-3 ">
            <div class="detail-title">Relieving date</div>
            <div class="detail-desc">{{this.appservice.dateFormatDisplay(requestitem?.requests?.leaving_date)}}</div>
          </div>

          <div *ngIf="requestitem?.requests?.employee_type == 'NEW JOINEE'" class="col-sm-3 ">
            <div class="detail-title">Date of joining</div>
            <div class="detail-desc">{{this.appservice.dateFormatDisplay(requestitem?.requests?.doj
              )}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Asset New Section -->
    <ng-container *ngIf="requestitem?.requests?.new_assets_inbox?.length > 0">
      <div class="col-12">
        <div class="card details-card indicater1  shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16 ">
            <div class="col-12 align-items-center d-flex">
              <p class="title mb-8">New Asset Details</p>
              <span *ngIf="requestitem?.requests?.employee_type == 'NEW JOINEE' && !requestitem?.achieved_status" class="ms-auto link-primary fs-12 fw-500 text-uppercase" (click)="getAssets(requestitem?.from_person?.id,'','');specificId='';newOrOldEmployee='New';">Assign Asset</span>
            </div>
            <ng-container *ngFor="let request of requestitem?.requests?.new_assets_inbox, let ind = index">
              <div class="col-12">
                <div class="card details-card shadow-0 px-16">
                  <div class="card-body px-0">
                    <div class="row row-16">

                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Category</div>
                        <div class="detail-desc">{{request?.asset_request_product?.category_name}}</div>
                      </div>
                      <div class="col-sm-3 col-12">
                        <div class="detail-title">Subcategory</div>
                        <div class="detail-desc">{{request?.asset_request_product?.sub_category_name}}</div>
                      </div>

                      <div *ngIf="requestitem?.requests?.employee_type != 'NEW JOINEE'" class=" col-12">
                        <div class="detail-title">Reason</div>
                        <div class="detail-desc">{{request?.asset_request_product?.asset_reason}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body px-0">
                    <div class="row row-16">

                        <div *ngIf="request?.status == 'Assigned'" class="col-12 d-flex align-items-center ">
                          <div class="d-flex align-items-center gap-8 fw-500 fs-12">
                            <span class="badge px-16 badge-success py-1">{{request?.status}}</span>
                            <span class="text-light-400">|</span>
                            <span class="link-primary " (click)="viewInventory = true;currentId=request?.assigned_asset">View details</span>
                          </div>
                          <div *ngIf="!requestitem?.achieved_status && requestitem?.requests?.employee_type == ''" class="d-flex align-items-center ms-auto gap-8 fs-12 fw-500 ">
                            <span class="pointer text-uppercase text-warning" (click)="undoAsset(request?.id,'Undo','','','new')"><i
                                class="icon-arrow-return-left-square me-1"></i>Undo</span>
                          </div>

                          <div *ngIf="!requestitem?.achieved_status && requestitem?.requests?.employee_type == 'NEW JOINEE'" class="d-flex align-items-center ms-auto gap-8 fs-12 fw-500 ">
                            <span class="link-danger text-uppercase" (click)="alertOrDelete='Delete';alertToggle=true;specificId=request?.id"> <i class="icon-trash me-1"></i> delete</span>
                          </div>

                        </div>

                        <div *ngIf="request?.status == 'Pending' && !requestitem?.achieved_status" class="col-12 d-flex align-items-center ">
                            <div class="d-flex align-items-center ms-auto gap-8 fs-12 fw-500">
                              <span class="link-primary text-uppercase " (click)="getAssets(requestitem?.from_person?.id,request?.asset_request_product?.asset_category,request?.asset_request_product?.asset_sub_category);newOrOldEmployee='Old';specificId=request?.id;">Assign</span>
                            </div>
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End -->

    <!-- Asset Return Section -->
    <ng-container *ngIf="requestitem?.requests?.return_assets_inbox?.length > 0">
      <div class="col-12 align-items-center d-flex">
        <p class="title mb-8">Return Asset Details</p>
      </div>
      <ng-container *ngFor="let return of requestitem?.requests?.return_assets_inbox, let ind = index">
        <div class="col-12">
          <div class="card details-card shadow-0 px-16">
            <div class="card-body px-0">
              <div class="row row-16">
                <div class="col-sm-3 col-12">
                  <div class="detail-title">Asset name</div>
                  <div class="detail-desc">{{return?.asset_request_product?.product_name}}</div>
                </div>
                <div class="col-sm-3 col-12">
                  <div class="detail-title">Asset code</div>
                  <div class="detail-desc">{{return?.asset_request_product?.serial_number}}</div>
                </div>
                <div class="col-sm-3 col-12">
                  <div class="detail-title">Category</div>
                  <div class="detail-desc">{{return?.asset_request_product?.category_name}}</div>
                </div>
                <div class="col-sm-3 col-12">
                  <div class="detail-title">Subcategory</div>
                  <div class="detail-desc">{{return?.asset_request_product?.sub_category_name}}</div>
                </div>
              </div>
            </div>
            <div class="card-body px-0">
              <div class="row row-16">

                <div *ngIf="return?.status == 'Pending' && !requestitem?.achieved_status" class="col-12 d-flex align-items-center">
                  <div class="d-flex align-items-center ms-auto text-uppercase fw-500 fs-12 gap-8">
                    <span class="link-primary {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : '' }} " (click)="undoAsset(return?.id,'Unreturned','',ind,'return');addAssetArray(return?.id)">Unreturn</span>
                    <span class="text-light-400">|</span>
                    <span class="link-primary {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : '' }}" (click)="undoAsset(return?.id,'Returned','',ind,'return')">Return</span>
                  </div>
                </div>

                <ng-container *ngIf="(return?.status == 'Returned' || return?.status == 'Unreturned')" >
                <div class="col-12 d-flex align-items-center ">
                  <div class="d-flex align-items-center gap-8 fw-500 fs-12">
                    <span class="badge px-16  {{return?.status == 'Unreturned' ? 'badge-danger' : 'badge-success'}} py-1">{{return?.status}}</span>
                  </div>
                  <div *ngIf="!requestitem?.achieved_status" class="d-flex align-items-center ms-auto gap-8 fs-12 fw-500 ">
                    <span *ngIf="return?.status == 'Returned'" class=" text-uppercase text-warning {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : 'pointer' }}" (click)="undoAsset(return?.id,'Undo','',ind,'return')"> <i
                        class="icon-arrow-return-left-square me-1"></i>Undo</span>
                    <span *ngIf="return?.status == 'Unreturned'" class=" text-uppercase text-warning {{pointerEventArray?.[ind]?.['loader'+ind] == true ? 'pointer-event' : 'pointer' }}" (click)="undoAsset(return?.id,'Undo','',ind,'return');removeAssetArrays(return?.id)"> <i
                          class="icon-arrow-return-left-square me-1"></i>Undo</span>
                  </div>
                </div>
                <div *ngIf="return?.status == 'Unreturned' && requestitem?.achieved_status" class="col-sm-3 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc">{{return?.unreturn_status}}</div>
                </div>
                </ng-container>

                <div *ngIf="return?.status == 'Invalid'" class="col-12 d-flex align-items-center ">
                  <div class="d-flex align-items-center gap-8 fw-500 fs-12">
                    <span class="badge px-16 badge-danger1 py-1">{{return?.status}}</span>
                  </div>
                </div>

                <ng-container *ngIf="return?.status == 'Unreturned' && !requestitem?.achieved_status" [formGroup]="unReturnedForm">
                  <ng-container formArrayName="assetArray" *ngFor="let item of assetArrays().controls; let j=index; ">
                    <ng-container [formGroupName]="j">

                      <div *ngIf="return?.id == assetArrays().controls[j].get('id')?.value" class="col-12">
                        <label for="" class="form-label required">Status </label>
                        <ng-select (change)="undoAsset(return?.id,'Unreturned',assetArrays().controls[j].get('status')?.value,'','stat')" class="form-ngselect" formControlName="status" [ngClass]="{ 'is-invalid': (submitted || assetArrays().controls[j].get('status')?.dirty || assetArrays().controls[j].get('status')?.touched) &&  assetArrays().controls[j].get('status')?.errors}" name=""  id="status" placeholder="{{this.messageService.selectplaceholddisplay('status')}}">
                          <ng-option *ngFor="let item of unReturnedStatusArray" [value]="item">{{ item}}</ng-option>
                        </ng-select>
                        <div *ngIf="(submitted || assetArrays().controls[j].get('status')?.dirty || assetArrays().controls[j].get('status')?.touched) && assetArrays().controls[j].get('status')?.errors" class="invalid-feedback">
                            <div *ngIf="assetArrays().controls[j].get('status')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>


    <!-- End -->

    <!-- In the case of New employee -->
    <div *ngIf="(requestitem?.requests?.employee_type == 'NEW JOINEE') && (requestitem?.requests?.new_assets_inbox?.length == 0) && (requestitem?.requests?.return_assets_inbox?.length) == 0 && !requestitem?.achieved_status" class="col-12"><button class="btn btn-outline-primary fw-400 btn-sm " (click)="getAssets(requestitem?.from_person?.id,'','');specificId='';newOrOldEmployee='New';">Assign Asset</button></div>
    <!-- End -->

    <ng-container *ngIf="!requestitem?.achieved_status">

      <div class="col-12 d-flex gap-16  py-16  position-sticky bottom-0 bg-white">

        <div class="d-flex ms-auto gap-16">
          <button (click)="confirm('Rejected')" class="btn btn-outline-danger text-uppercase btn-sm fw-400">Decline</button>
          <button class="btn btn-primary text-uppercase btn-sm fw-400" (click)="confirm('Approved')">Submit</button>
        </div>
      </div>
    </ng-container>
  </div>
<!-- </div> -->

<!-- Assign Asset side panel -->
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='assignAssets === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Assets</h5>
      <a class="toggle-panel" (click)="closeAssignAsset()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex align-items-center">
            <p class="text-uppercase fw-500 mb-0">Assign Assets</p>
            <div class="ms-auto" style="width:22.6875rem;">
              <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey"
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchAsset($event)"></app-searchbar>
            </div>
          </div>

          <app-table-loader *ngIf="assignAssetsLoader"></app-table-loader>

          <ng-container *ngIf="!assignAssetsLoader">
            <div class="col-12">
              <div class="card card-c2">
                <div class="table-responsive radius-4">
                  <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table sticky-first-col sticky-last-col">
                    <thead>
                      <tr>
                        <th *ngIf="newOrOldEmployee == 'Old'" scope="col" class="form-cell">
                        </th>
                        <th *ngIf="newOrOldEmployee == 'New'" scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                          [checked]="checkAllSelected()">
                        </th>
                        <th class="fw-600 text-uppercase" scope="col">Serial no</th>
                        <th class="fw-600 text-uppercase" scope="col">Asset name</th>
                        <th class="fw-600 text-uppercase" scope="col">Asset code</th>
                        <th class="fw-600 text-uppercase" scope="col">category</th>
                        <th class="fw-600 text-uppercase" scope="col">subcategory</th>
                        <th class="fw-600 text-uppercase" scope="col">View</th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 ">

                      <ng-container *ngIf="assignAssetsData?.length==0">
                        <tr>
                          <td colspan="7">
                            <div class="card card-c2 flex-center p-16 bg-dark-500">
                              <span class="fw-500 fs-14">No data found</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>

                      <ng-container *ngFor="let asset of $any(assignAssetsData | slice: (page-1) * pageSize : page * pageSize),let ind = index">
                        <tr>
                          <td scope="col" class="form-cell">
                            <input *ngIf="newOrOldEmployee == 'Old'" name="asset_type" type="radio" id="Old" class="form-check-input" value="" (change)="assignAssetToExistingEmployee(asset?.id)">

                            <input *ngIf="newOrOldEmployee == 'New'" class="form-check-input m-0" type="checkbox" value="" [(ngModel)]="asset.checked">
                          </td>
                          <td>{{asset?.serial_number}}</td>
                          <td>{{asset?.name}}</td>
                          <td>{{asset?.code}}</td>
                          <td>{{asset?.category_name}}</td>
                          <td>{{asset?.sub_category_name}}</td>
                          <td><i class="icon-eye link-primary fs-18" (click)="viewInventory = true;currentId=asset?.id"></i></td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <ngb-pagination *ngIf="assignAssetsData?.length>pageSize" class="d-flex justify-content-end"
              [collectionSize]="assignAssetsData?.length" [(page)]="page" [maxSize]="3" [rotate]="true"
              [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
        </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="!assignAssetbuttonDiableFunction() || assignClicked" (click)="assignAssetToEmployee()">
        Assign
      </button>
    </footer>
  </div>
</div>
<!-- End -->

<!-- Inventory view details -->
<app-inventory-view *ngIf="viewInventory" [viewInventory]="viewInventory" [isEdit]="true" [id]="currentId" [hide]="false" [from]="from" (closeModel)="viewInventory=false"></app-inventory-view>
<!-- End -->

<!-- Alert for new asset case -->
<div
  class="modal  modal-alert  {{ alertToggle == true ? 'show' : '' }}"
  tabindex="-1"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle fs-24 text-danger"></i>
        <h4 class="modal-title">{{alertOrDelete == 'Alert' ? 'Alert' : 'Delete Asset?'}}</h4>
      </div>
      <div class="modal-body py-0">
        <p class="mb-0">{{ alertOrDelete == 'Alert' ? alertMsg : this.messageService.Deletemsgdisplay('Asset') }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="alertToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked" (click)="deleteClicked=true;finalActionFunction();">{{alertOrDelete == 'Alert' ? 'Confirm' : 'Delete'}}</button>
    </div>
    </div>
  </div>
</div>
<!-- End -->
