
<form [formGroup]="formGp" class="">
    <div class="container-fluid p-32 form-container">

        <div class="row ">
            <p  class="text-uppercase fw-500">Define Pay Group</p>
        </div>
        <div class="row row-16">
            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Pay group name</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="A pay group is a configuration where you can map specific pay components and templates (such as payslips and tax slips)  with a defined group of employees." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                    <input type="text" formControlName="pay_group_name" class="form-control" placeholder="{{this.messageService.placeholderdisp('group name')}}" [ngClass]="{ 'is-invalid': (f.pay_group_name.dirty || f.pay_group_name.touched) &&  f.pay_group_name.errors || f.pay_group_name.errors?.exist}" (keyup)="keyUp()"
                    [attr.disabled]="disabled? true : null">
                    <div *ngIf="(f.pay_group_name.dirty || f.pay_group_name.touched) && f.pay_group_name.errors || f.pay_group_name.errors?.exist" class="invalid-feedback">
                        <div *ngIf="f.pay_group_name.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                        <div *ngIf="f.pay_group_name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        <div *ngIf="!(f.pay_group_name.errors?.pattern) && f.pay_group_name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                        <div *ngIf="f.pay_group_name.hasError('exist')">{{this.messageService.validationDisplay(f.pay_group_name.value)}}</div>
                    </div>
                  </div>
            <div class=" col-lg-6 form-row">
              <label for="" class="form-label required">Payslip</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="The pay components you added and computed in the pay group will be reflected in your payslip or payslip, depending on which option you choose." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <!-- formControlName="pay_slip_id"
              name="pay_slip_id"  id="pay_slip_id" -->
              <ng-select [readonly]="disabled" class="form-ngselect"
              formControlName="pay_slip_id"
              name="pay_slip_id"  id="pay_slip_id"
              placeholder="{{this.messageService.selectplaceholddisplay('payslip')}}"
              [ngClass]="{ 'is-invalid': (f.pay_slip_id.touched) && f.pay_slip_id.errors }"
              (change)="setTemplatePreview($event,'salary')" >
                  <ng-option *ngFor="let li of paysliplist" [value]="li.id">{{li.template_name}}</ng-option>
              </ng-select>
              <span *ngIf="f.pay_slip_id.value" class="fs-12 gap-1 link-primary pt-1 fw-500 d-flex align-items-center"
             (click)="modalToggle=true;currentDocName=salPdfName;fileName=salPdfFile;">
             <i class="icon-eye me-1"></i>Preview template</span>
              <div *ngIf="(f.pay_slip_id.touched) && f.pay_slip_id.errors" class="invalid-feedback">
                  <div *ngIf="f.pay_slip_id.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class=" col-12 form-row">
              <label for="" class="form-label required">Description</label>
              <textarea [attr.disabled]="disabled? true : null" [ngClass]="{ 'is-invalid': (f.description.dirty || f.description.touched) &&  f.description.errors}" rows="4" type="text" formControlName="description" class=" form-control " name="" id="" placeholder="{{this.messageService.descriptiontext('desc')}}">
              </textarea>
              <div *ngIf="(f.description.dirty || f.description.touched) && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.description.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>

            </div>
            </div>
            <div class=" col-lg-6 form-row">
              <label for="" class="form-label required">Tax slip</label>

              <ng-select [readonly]="disabled" class="form-ngselect"
              formControlName="tax_slip_id"
              name="tax_slip_id"  id="tax_slip_id"
              placeholder="{{this.messageService.selectplaceholddisplay('tax slip')}}"
              [ngClass]="{ 'is-invalid': (f.tax_slip_id.touched) && f.tax_slip_id.errors }" (change)="setTemplatePreview($event,'tax')"
              >
                  <ng-option *ngFor="let li of taxSlip" [value]="li.id">{{li.template_name}}</ng-option>
              </ng-select>
              <span *ngIf="f.tax_slip_id.value" class="fs-12  gap-1  link-primary pt-1 fw-500 d-flex align-items-center" (click)="modalToggle=true;currentDocName=taxPdfName;fileName=taxPdfFile;"><i class="icon-eye me-1"></i>Preview template</span>
              <div *ngIf="(f.tax_slip_id.touched) && f.tax_slip_id.errors" class="invalid-feedback">
                  <div *ngIf="f.tax_slip_id.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class=" col-lg-6 form-row">
              <label for="appointment" class="form-label required">Appointment letter </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the appointment letter format from the drop-down." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <ng-select [readonly]="disabled" class="form-ngselect"
              formControlName="appointment_id"
              [ngClass]="{ 'is-invalid': (f.appointment_id.touched) && f.appointment_id.errors }"
              name="appointment"  id="appointment_id"
              placeholder="{{this.messageService.selectplaceholddisplay('Appointment letter')}}" (change)="setPreview($event,'appointment')">
                  <ng-option *ngFor="let li of appointmentlist" [value]="li?.id">{{li?.name}}</ng-option>
              </ng-select>
              <span *ngIf="f.appointment_id.value" class="fs-12 gap-1 link-primary pt-1 fw-500 d-flex align-items-center"
             (click)="modalToggle=true;currentDocName=appointmentName;fileName=appointmentFile;">
             <i class="icon-eye me-1"></i>Preview template</span>
              <div *ngIf="(f.appointment_id.touched) && f.appointment_id.errors" class="invalid-feedback">
                  <div *ngIf="f.appointment_id.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            </div>
            <div class=" col-lg-6 form-row mt-3">
              <label for="revision" class="form-label required">Salary revision letter </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the salary revision letter format from the drop-down." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              <ng-select [readonly]="disabled" class="form-ngselect"
              formControlName="revision_id"
              [ngClass]="{ 'is-invalid': (f.revision_id.touched) && f.revision_id.errors }"
              name="revision"  id="revision_id"
              placeholder="{{this.messageService.selectplaceholddisplay('Salary Revision letter')}}" (change)="setPreview($event,'revision')">
                  <ng-option *ngFor="let li of revisionlist" [value]="li?.id">{{li?.name}}</ng-option>
              </ng-select>
              <span *ngIf="f.revision_id.value" class="fs-12 gap-1 link-primary pt-1 fw-500 d-flex align-items-center"
              (click)="modalToggle=true;currentDocName=revisionName;fileName=revisionFile;">
              <i class="icon-eye me-1"></i>Preview template</span>
              <div *ngIf="(f.revision_id.touched) && f.revision_id.errors" class="invalid-feedback">
                  <div *ngIf="f.revision_id.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
        </div>
    </div>

    <footer class="submit-footer">
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validateConfigureFlow()">NEXT</button>
        <button *ngIf="disabled==false"class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid" (click)="validateConfigureFlow()">NEXT</button>
    </footer>
</form>

<!-- preview -->
<div class="modal  modal-pdfviewer modal-custom {{modalToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16">
              <h4 class="modal-title">{{currentDocName}}</h4>
              <i (click)="modalToggle = false" class="ms-auto bi b bi-x-lg text-light-500"></i>
          </div>
          <div class="modal-body">
            <div class="pdf-container">
              <pdf-viewer [src]="fileName" [original-size]="false"></pdf-viewer>
            </div>
          </div>
      </div>
  </div>
</div>
