import { Component, Input, OnInit, Output,  EventEmitter } from '@angular/core';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { FormBuilder,Validators } from '@angular/forms';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';

@Component({
  selector: 'app-view-investment-declaration',
  templateUrl: './view-investment-declaration.component.html',
  styleUrls: ['./view-investment-declaration.component.scss']
})
export class ViewInvestmentDeclarationComponent implements OnInit {

  @Input() viewHeader: any;
  @Input() investmentSingleData: any;
  @Input() hra80ggSingleData: any;
  @Input() cahpt80CSingleData: any;
  @Input() otherSourceSingleData: any;
  @Input() deductionSingleData: any;
  @Input() incomeLossSingleData: any;
  @Input() hra80ggFlag: any;
  @Input() exeption_50: any;
  @Input() investmentcard1: any;
  @Input() cityName: any;
  @Input() requestitem: any;
  @Input() from: any;
  @Input() declareStatus:any;
  @Input() taxData:any;
  @Input() hra80gg:any;
  @Input() loader1:any;
  @Input() currency:any;
  @Input() reqStatus:any;

  @Output() investmentcardClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshEmitter: EventEmitter<boolean> = new EventEmitter();

  closeSidePanel = false;
  modalToggle                 = false;
  currentDocName              = "";
  currentExtension            = "";
  allowedExtension            = "";
  fileName:any;
  alertToggle                 = false;
  investalertMsg:any          = '';
  currentYear: number         = new Date().getFullYear();
  nextYear: number            = (this.currentYear+1);

  // currency:any

  form = this.fb.group({
    read_status: [true,Validators.required],
    star: [false,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  constructor(
    private datePipe:DatePipe,
    public appservice:AppService,
    private inboxservice:InboxService,
    private fb: FormBuilder,
    public finalSettlementService: FinalSettlementService,

  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(){
    if(this.from == 'inbox'){
      this.form.setValue({
        read_status: this.requestitem?.read_status,
        approved_status: this.requestitem?.approved_status,
        star: this.requestitem?.star,
        comment: this.requestitem?.comment
      });
    }else if(this.from == 'fnf'){
      this.form.setValue({
        read_status: true,
        approved_status: '',
        star: false,
        comment: ''
      });
    }
  }



  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  dateFormatDisplay(date:any){
    var d = new Date();
    if(date == String(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate())){
      return 'TODAY';
    }else if(date == String(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+(d.getDate()-1))){
      return 'YESTERDAY';
    }else{
      return this.datePipe.transform(date, this.appservice.getdateformat())?.toString()
    }
  }

  close(){
    this.investmentcardClose.emit(this.closeSidePanel);
  }

  get f() {return  this.form.controls; }

  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }
    if(this.form['controls'].comment.valid){
      if(this.from == 'inbox'){
        this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
          if(res){
            this.close();
            this.refreshEmitter.emit(true);
          }
        })
      }else if(this.from == 'fnf'){
        this.finalSettlementService.updateInboxItem(this.requestitem,this.form.value).subscribe((res)=>{
          if(res){
            this.close();
            this.refreshEmitter.emit(false);
          }
        })
      }
      else if(this.from=='behalf'){
       let data = this.form.value
       data['request_id']      = this.requestitem?.request_data?.id
       this.refreshEmitter.emit(data);
       this.close();


      }


    }
  }

  // test start
  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  fieldName(data:any){
    let str;
    if(data == 'othersource_amount')
      str =  'Income from other sources';
    if(data == 'sb_account_amount')
      str =  'Interest earned through Saving Bank Account';
    if(data == 'source_80TTA_80TTB_amount')
      str =  '80TTA/ 80TTB';
    if(data == 'intrest_earned_amount')
      str =  'Interest earned from national savings certificate';
    return str;
  }

  chapter4aFieldName(data:any){
    let str;
    if(data == 'employee_contribute_pf_amount')
      str =  'Employee Contribution towards PF';
    if(data == 'ppf_amount')
      str =  'Contribution towards 15 Years PPF';
    if(data == 'POTD_amount')
      str =  'Five-year Post Office Time Deposit (POTD) Scheme';
    if(data == 'nsc_amount')
      str =  'Subscription to National Saving Scheme (NSC)';
    if(data == 'nps_amount')
      str =  'Deduction in respect of contribution to NPS (80 CCD(1))';
    if(data == 'ELSS_amount')
      str =  'Equity Linked Saving Schemes (Tax Saving Mutual Funds) ELSS';
    if(data == 'ULIP_amount')
      str =  'ULIP';
    if(data == 'interest_NSC_amount')
      str =  'Interest on National Saving Certificate';
    if(data == 'repay_house_amount')
      str =  'Repayment of Housing Principal';
    if(data == 'stampduty_amount')
      str =  'Stamp Duty paid for Purchase of House property';
    if(data == 'ssy_amount')
      str =  'Sukanya Samriddhi Yojana';
    if(data == 'nabard_rural_bond_amount')
      str =  'NABARD Rural Bonds';
    if(data == 'lic_amount')
      str =  'Life insurance premium';
    if(data == 'annuity_amount')
      str =  'Annuity plan of LIC or other insurer towards Pension Scheme (80 CCC)';
    if(data == 'contribution_vpf_amount')
      str =  'Contribution towards VPF';
    if(data == 'previous_employer_pf_amount')
      str =  'Previous Employer PF';
    if(data == 'tuition_fees')
      str =  'Children Education Tuition Fees';
    return str;
  }

  deductionFieldName(data:any){
    let str;
    if(data == 'health_insurance_selfspouse80d_amount')
      str =  '80D : Health insurance premium for Self / Spouse or Dependent Children';
    if(data == 'health_checkup_selfspouse80d_amount')
      str =  '80D : Preventive health checkup for Self / Spouse or Dependent Children';
    if(data == 'health_checkup_parents80d_amount')
      str =  '80D : Preventive health checkup for Dependent parents';
    if(data == 'medicalbill_senior80d_amount')
      str =  'Medical bills for super senior citizen';
    if(data == 'employee_contribution_amount')
      str =  '80CCD1(B): Employee’s additional contribution to NPS';
    if(data == 'tta_ttb_amount')
      str =  '80TTA: Exemption of interest from banks, post office, etc. (or) 80TTB: Exemption of interest from banks, post office, etc.';
    if(data == 'interest_education_amount')
      str =  '80E: Interest on education loan';
    if(data == 'interest_home_loan_amount80ee')
      str =  '80EE: Additional Interest on home loan for first-time homeowners borrowed between 1st April 2016 to 31st March 2017 ';
    if(data == 'interest_home_loan_amount80eea')
      str =  '80EEA: Additional Interest on home loan for first-time homeowners borrowed between 1st Apr 2019 to 31st March 2022';
    if(data == 'interest_electrical_vehicle_loan_amount')
      str =  '80EEB: Interest on electrical vehicle loan borrowed between 1st April 2019 to 31st March 2023';
    if(data == 'handicapped_dependent_amount')
      str =  '80 DD: Medical treatment for handicapped dependent';
    if(data == 'medical_expenditure_amount')
      str =  '80DDB: Medical Expenditure as per rule 11DD';
    if(data == 'self_suffering_amount')
      str =  '80U: Self-suffering from disability';
    if(data == 'donate_social_cause100_amount')
      str =  '80G: Deduction for donations towards social causes (100% exemption)';
    if(data == 'donate_social_cause50_amount')
      str =  '80G: Deduction for donations towards social causes (50% exemption)';
    if(data == 'royalty_patent_amount')
      str =  '80RRB: Deductions on Income by way of Royalty of a Patent';
    if(data == 'contribution_policalparties_amount')
      str =  '80GGC: Contribution by individuals to political parties';
    if(data == 'health_insurance_parents80d_amount')
      str =  '80D : Health insurance premium for Dependent parents';
    if(data == 'total_amount')
      str =  '80GGA: Deduction in respect of donations to certain funds, charitable institutions, scientific research, and rural development etc. ';
    if(data == 'employer_contribution_amount')
      str =  '80CCD(2): Employer’s contribution to NPS account, deducted from salary';
    return str;
  }

  stringToNumConversion(a:any){
    let num;
    num = Number(a);
    return num;
  }

  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close1(bool:any){
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool:any){
    this.alertToggle = bool
  }
}
