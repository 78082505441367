<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{title}}</h5>
    <button class="btn-reset text-light-600" routerLink="/configuration"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">

    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success':configForm.value.company.length>0}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Select Companies</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-configuration-select-company [configForm]="configForm" [formArray]="formArray"
              [companies]="companyList" (submitmethod)="submitMethod(2)" [editCompId]="editCompId"
              [checkboxes]="checkboxes" (activeCheckbox)="activeCheckbox($event)"></app-configuration-select-company>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step" [class]="{'success':configForm.valid && configForm.value.company.length>0}">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Configure Companies</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-configuration-config-company [configForm]="configForm" [selectedComp]="selectedComp"
              (submitmethod)="submitForm()" (clearComp)="clearComp($event)"
              [editCompId]="editCompId"></app-configuration-config-company>
          </ng-template>
        </li>

      </ul>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
    </main>
  </ng-container>
</div>
