import { Component, Input, OnInit ,Output,EventEmitter,ViewChild} from '@angular/core';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { TimeAttendenceSettingsService } from 'src/app/@core/services/time-attendence-settings.service';
import { MessageService } from 'src/app/message.global';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';




@Component({
  selector: 'app-tas-setup-configuration',
  templateUrl: './tas-setup-configuration.component.html',
  styleUrls: ['./tas-setup-configuration.component.scss']
})
export class TasSetupConfigurationComponent implements OnInit {
  saveBtnClicked     : boolean = false
  @Input() configForm: any;
  roleListarr : any = []
  @Output() submitmethod   = new EventEmitter();

  constructor(private fb: FormBuilder,
   public appService: AppService,public TAS: TimeAttendenceSettingsService,public messageService : MessageService,) { }

  ngOnInit(): void {
    this.roleList()

    this.addValidator('showup_notify_home','prior_days');
    this.addValidator('showup_notify_home','timeattendancesettings_role')
  }

  roleList(){
    this.TAS.timeAttendenceRoleList().subscribe((res: any) => {
      this.roleListarr = res;
      this.selectAllForDropdownItems(this.roleListarr);

    });
  }

   // Select All
   selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  clearcommonForm(item:any,index:any){
    if(item == "roles"){
      let daata = this.configForm.value.timeattendancesettings_role;
      daata.splice(index, 1);
      this.configForm.patchValue({
        timeattendancesettings_role: daata
      });
    }
  }


 get f() {return  this.configForm.controls; }

//  setRequired(type:any,val:any){
//   if(type && val=='prior_days'){
//     this.configForm.get('prior_days').setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(3)])
//     this.configForm.get('prior_days').updateValueAndValidity()
//   }
//   else if(!type && val=='is_notprior_days'){
//     this.configForm.get('prior_days').clearValidators()
//     this.configForm.get('prior_days').reset(null)
//   }

//  }
 addValidator(parent:any,child:any){

  if(this.configForm.get(parent)?.value){
    if(parent=='showup_notify_home')
     if(child== 'prior_days'){
      this.configForm.get(child).setValidators([Validators.required,NumberpatternValidator(),Validators.max(60)])
     }else{
      this.configForm.get(child).setValidators([Validators.required])
     }
    // else
    // this.configForm.get(child).setValidators([Validators.required,NumberpatternValidator(),Validators.max(10)])


  }
  else{
    this.configForm.get(child).clearValidators()
    this.configForm.get(child).reset(null)
  }
  this.configForm.get(child).updateValueAndValidity()

 }



 validateForm(){
    this.saveBtnClicked = true;
    if (this.configForm.invalid) {
      return;
    }
    this.submitmethod.emit();
  }



}
