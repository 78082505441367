import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { GeolocationService } from 'src/app/@core/services/geo-location.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-location-assign',
  templateUrl: './location-assign.component.html',
  styleUrls: ['./location-assign.component.scss']
})
export class LocationAssignComponent implements OnInit {

  constructor(
    public messageService : MessageService,
    public appservice:AppService,
    private fb:FormBuilder,
    private GeolocationService:GeolocationService,
    public notificationservice:NotificationService
    ) { }

  @Input()formGp : any;
  @Output() submitmethod        = new EventEmitter();
  @Output() validateBreak        = new EventEmitter();
  @Input()componentError:any;
  @Input()submitted:boolean=false;
  @Input()disabled = false;
  @Input()locationdropdown:any;
  selectedSubSecError : any = [];
  temparr:any=[];

  ngOnInit(): void {
    if(this.locationarray().length == 0){
      this.addmorelocation();
    }else{
      this.locselectfn();
    }
  }



  validateForm() {
    this.submitted = true;

    if (this.formGp.invalid ) {
      return;
    }

    this.submitmethod.emit();
  }


  get f() { return this.formGp.controls; }

  locationarray() : FormArray {
    return this.formGp.get("geofencing_locations") as FormArray
  }

  newlocationarray(): FormGroup {
    return this.fb.group({
      "geofencing_location_id" : [null,Validators.required],
      "for_specific_days_only" : [false,Validators.required],
      "duration_of_days"       : [0],
    })
  }

  addmorelocation() {
    this.locationarray().push(this.newlocationarray());
  }

  removelocationarray(i:number) {
    this.locationarray()?.removeAt(i);
  }

  setRequired(value: boolean,item:any,i:number) {
    if(value) {
      this.locationarray().controls[i].get(item)?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(10)])
      this.locationarray().controls[i].get('duration_of_days')?.setValue(null);
    } else {
      this.locationarray().controls[i].get(item)?.clearValidators();
      this.locationarray().controls[i].get('duration_of_days')?.setValue(0);

      // this.locationarray().clear();
    }
    this.locationarray().controls[i].get(item)?.updateValueAndValidity();
  }

  locselectfn(){
      this.temparr=[];
      this.locationarray().controls?.forEach((item:any)=> {
        this.temparr.push(item?.value.geofencing_location_id)
      })

      const findDuplicateVal = (arr:any) => arr.filter((item:any, index:any) => arr.indexOf(item) != index);

      if(findDuplicateVal(this.temparr).length==0){
        for(let j=0;j<this.locationarray().controls.length;j++){
          if(this.locationarray().controls[j].get('geofencing_location_id')?.errors?.sameid==true){
            this.locationarray().controls[j].get('geofencing_location_id')?.setErrors(null);
            this.disabled=false;
          }
        }
      }else{
        for(let i=0;i<findDuplicateVal(this.temparr).length;i++){
          for(let j=0;j<this.locationarray().controls.length;j++){
              if(this.locationarray().controls[j].get('geofencing_location_id')?.value==findDuplicateVal(this.temparr)[i]){
                this.locationarray().controls[j].get('geofencing_location_id')?.setErrors({'sameid': true});
                this.disabled=true;
              }else{
                if(this.locationarray().controls[j].get('geofencing_location_id')?.errors?.sameid==true){
                  this.locationarray().controls[j].get('geofencing_location_id')?.setErrors({'sameid': false});
                }
                this.disabled=false;
              }
          }
        }
      }

  }

 }

