import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { WeekoffService } from 'src/app/@core/services/weekoff.service';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';

@Component({
  selector: 'app-weekly-off-setup',
  templateUrl: './weekly-off-setup.component.html',
  styleUrls: ['./weekly-off-setup.component.scss']
})
export class WeeklyOffSetupComponent implements OnInit {

  activeTab              = 1;
  title                  = "Add Weekly Off";
  id             :number = 0
  disabled       :boolean= false
  tab2touched    :boolean= false
  editflag       :boolean= false



  weekOffForm  :FormGroup = this.fb.group({
    id       : 0,
    weekOff  : this.fb.group({
      weekPolicyName  : [null, [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      weekoffdata : this.fb.array([
        this.fb.group({
          week_number : 1,
          monday      : [null],
          tuesday     : [null],
          wednesday   : [null],
          thursday    : [null],
          friday      : [null],
          saturday    : [null],
          sunday      : [null]
        }),
        this.fb.group({
          week_number : 2,
          monday      : [null],
          tuesday     : [null],
          wednesday   : [null],
          thursday    : [null],
          friday      : [null],
          saturday    : [null],
          sunday      : [null]
        }),
        this.fb.group({
          week_number : 3,
          monday      : [null],
          tuesday     : [null],
          wednesday   : [null],
          thursday    : [null],
          friday      : [null],
          saturday    : [null],
          sunday      : [null]
        }),
        this.fb.group({
          week_number : 4,
          monday      : [null],
          tuesday     : [null],
          wednesday   : [null],
          thursday    : [null],
          friday      : [null],
          saturday    : [null],
          sunday      : [null]
        }),
        this.fb.group({
          week_number : 5,
          monday      : [null],
          tuesday     : [null],
          wednesday   : [null],
          thursday    : [null],
          friday      : [null],
          saturday    : [null],
          sunday      : [null]
        })
      ])
    }),
    weekoff_assign    : this.fb.group({
      applicableto    : [null, [Validators.required]],
      assignees       : [null, [Validators.required]]
    })
  })

  get weekOffMapForm(){
    return this.weekOffForm.get('weekOff') as FormGroup
  }

  get WeekOffAssignForm(){
    return this.weekOffForm.get('weekoff_assign') as FormGroup
  }

  constructor(
    public fb           : FormBuilder,
    public weekService  : WeekoffService,
    public router       : Router,
    public route        : ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.id = +params['id'];
        this.editForm();
      }
    })
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(this.weekOffForm['controls'].weekOff.invalid){
        changeEvent.preventDefault();
      }
    }
  }

  // Next Step
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
    if (activeTab === 2) {
      this.tab2touched =true
    }
  }

  // Submit Form
  submitForm(){
    var applicable = this.WeekOffAssignForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.WeekOffAssignForm.controls.assignees.value){
      arr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }
    let dataArray = {
      "weekoff_mapping" : this.weekOffMapForm.controls.weekoffdata.value,
      "weekoff_assign"  : arr,
      "policy_name"     : this.weekOffMapForm.controls.weekPolicyName.value
    }

    if(this.id == 0){
      this.weekService.createWeekOff(dataArray).subscribe((res:any)=>{
        this.router.navigate(['/weekly-off'])
      })
    }else{
      this.weekService.updateWeekOff(dataArray,this.id).subscribe((res:any)=>{
        this.router.navigate(['/weekly-off'])
      })
    }
  }

  // Edit
  editForm(){
    this.editflag = true
    this.weekService.getOneWeekfOff(this.id).subscribe((res:any)=>{
      this.title = "Edit Weekly Off"
      this.setEditForm(res)
    },(err:any)=>{
      this.router.navigate(['weekly-off'])
    })
  }

  setEditForm(res:any){
    let appkey ="";
    let assignees = [];
    let applicableTo = "";

    for(let key of res.body.weekoff_assign){
      appkey = key.applicable.toLowerCase();
      if(appkey == "bu")
        appkey = "businessunit";
      assignees.push(key[appkey+'_id']);
      applicableTo = key.applicable
    }

    if(res?.is_active == false){
      this.weekOffForm.disable();
      this.disabled = true
    }

    this.weekOffForm.reset({
      id             :  res?.id,
      weekOff        :  {
        weekPolicyName : res?.body.policy_name,
        weekoffdata: res?.body.weekoff_mapping_edit
      },
      weekoff_assign :  {
        applicableto    : applicableTo,
        assignees       : assignees
      }
    })
  }

}
