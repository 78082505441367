import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-right-loader',
  template: `
<div class="row row-16">
    
    <div class="col-lg-3 col-md-4 col-sm-5 col-12">
        <div class="stick-block ">
            <div class="card p-0 shadow-0" >
                <div class="card-body p-0" style="height: 292px;">
                    <div class=" text-center pt-25">
                        <ngx-skeleton-loader appearance="circle"  [theme]="{height: '96px',width: '96px','background-color': '#EAEDEF','margin-right':'16px','margin-bottom':'24px'}"></ngx-skeleton-loader>
                        <div class="text-center" *ngFor="let dummy of ' '.repeat(2).split(''), let x = index">
                            <ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="col-lg-9 col-md-8 col-sm-6 col-12">
        <div class="row row-16">
        <div class="col-12" style="margin-bottom:-13px">
            <div class="d-flex justify-content-between">
                <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '127px' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="col-12" *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
        <div class="card details-card shadow-0 px-24 py-16">
            <div class="row row-24 ">
                <div class="col-12 "><span>   <ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></span></div>
                <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                    <div class="detail-desc"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                    <div class="detail-desc"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                    <div class="detail-desc"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                </div>
                <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                    <div class="detail-desc"><ngx-skeleton-loader appearance="line" [theme]="{'background-color':'#EAEDEF','height': '19px','width': '140px','margin-bottom':'2px'}"></ngx-skeleton-loader></div>
                </div>
            </div>
        </div>
        </div>
        
    </div>
    </div>
    
</div>
  `,
  styles: [
  ]
})
export class ProfileRightLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
