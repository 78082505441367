import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-advance-policy-review',
  templateUrl: './advance-policy-review.component.html',
  styleUrls: ['./advance-policy-review.component.scss']
})
export class AdvancePolicyReviewComponent implements OnInit {
  @Input()applicableform:any  =[];
  @Output() submitmethod      = new EventEmitter();
  saveBtnClicked   = false;
  @Input()formGp   :any;
  assignees:any    =[];

  constructor(
    public appService:AppService
  ) { }

  ngOnInit(): void {
        let key = this.applicableform?.applicable;        
        this.assignees = this.applicableform[key];
        if(key == 'BU'){
          this.applicableform['applicable']='Business Unit';
        } 
  }

  validatefn(){
    this.saveBtnClicked =true;
    this.submitmethod.emit();
  }
}
