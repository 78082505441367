<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Prior Payroll</h5>
    <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i
        class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success':hasData==true }">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Prior payroll details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-prior-payroll-details (nextPage)="nextPage(2)" (stepper)="dataCheck($event)"></app-prior-payroll-details>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Summary</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-prior-payroll-summary (priorSave)="priorSave()" [uploadedMonth]="uploadedMonth"
            [pendingData]="pendingData"></app-prior-payroll-summary>
        </ng-template>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
