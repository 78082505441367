<ng-container *ngIf="permissions">

  <nav class="nav nav-custom sticky ">
    <a class="nav-link" [ngClass]="{'active': activeToggle == ''}" (click)="selectItemList('')"> All </a>
    <a class="nav-link " [ngClass]="{'active': activeToggle == 'not_initiated'}"
      (click)="selectItemList('not_initiated')"> Not initiated </a>
    <a class="nav-link " [ngClass]="{'active': activeToggle == 'pending'}" (click)="selectItemList('pending')"> Pending
    </a>
    <a class="nav-link" [ngClass]="{'active': activeToggle == 'completed'}" (click)="selectItemList('completed')">
      Completed </a>
  </nav>
  <div class="container-fluid p-24">
    <div class="row mb-16">
      <div class="col-md-4">
        <h3>Final Settlement</h3>
      </div>
      <div class="col-md-8">
        <div class="d-flex justify-content-end gap-16">
          <div style="max-width: 22.6875rem;">

            <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey"  [isLoading]="sLoading"
              (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"></app-searchbar>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
              id="dropdownBasic1" ngbDropdownToggle>
              <i class="icon-filter-left fs-13"></i>
            </button>
              <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
            (filterClose)="closefn($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
          </div>
        </div>
      </div>
    </div>
    <!-- Loader updations -->
    <div class="row row-16 mb-16" *ngIf="loader">
      <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <div *ngIf="nodata && !loader;else all_data"  class="row " style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg11.png'" [noDataText]="'Ready for Final Settlement?'"
        [noDataPara]="'It looks like final settlements haven’t been processed yet. It\'s time to handle those employee departures.'"
        [hasPermission]="false"> </app-nodata-view>
    </div>
    <ng-template #all_data>
      <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
        (scrolled)="onScrollDown()">

        <div class="row row-16 ">
          <ng-container *ngIf="!loader">
            <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let i of settlementList let ind = index">
              <div class="card card-c2 card-hover px-16" (click)="showFinalSettlement(i?.employee?.id,i?.display_status)">
                <div class="card-body pb-8 px-0">
                  <div class="d-flex gap-16">
                    <img *ngIf="i?.employee?.profile_image !=  null && i?.employee?.profile_image !=  ''" alt=""
                      class="avatar-circle avatar-border sq-45" src="{{i?.employee?.profile_image}}" />
                    <div *ngIf="i?.employee?.profile_image ==  '' || i?.employee?.profile_image ==  null"
                      class="avatar-circle avatar-border fs-14 fw-500  {{i?.employee?.color_code}}  sq-45">
                      {{this.appservice.getFirstChar(i?.employee?.first_name+' '+i?.employee?.last_name,2)}}</div>
                    <div class="vstack text-trim">
                      <p class="mb-0 fs-12 fw-500 lh-base text-trim"
                        title="{{i?.employee?.first_name+' '+(i?.employee?.middle_name!=null?i?.employee?.middle_name:'')+' '+i?.employee?.last_name}}">
                        {{i?.employee?.first_name+'
                        '+(i?.employee?.middle_name!=null?i?.employee?.middle_name:'')+' '+i?.employee?.last_name}}</p>
                      <p class="mb-0 fs-10 fw-500 lh-base text-trim text-light-500 hstack gap-4">
                        <span>{{i?.employee?.employee_code}}</span>|
                        <span>{{i?.employee?.designation}}</span>
                      </p>
                      <span class="text-uppercase fs-10 fw-500
                        {{(i?.display_status == 'Completed') ? 'text-success' :
                      (i?.display_status == 'Not Initiated' || i?.display_status == 'Rejected') ? 'text-danger':'text25'}} ">
                      {{i?.display_status}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-8 pb-12 d-flex fs-12">
                  <div class="me-8">
                    <div class="text-light-400">Resigned Date</div>
                    <div>{{this.appservice.dateFormatDisplay(i?.employee?.resigned_date)}}</div>
                  </div>
                  <div class="ms-auto text-end">
                    <div class="text-light-400">Leaving Date</div>
                    <div>{{this.appservice.dateFormatDisplay(i?.employee?.leaving_date)}}</div>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>

          <!-- Loader updations -->
          <ng-container *ngIf="infinityloader">
            <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
              *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
          </ng-container>

        </div>
      </div>
    </ng-template>

  </div>


  <ng-container *ngIf="showFinalSettlementView">
    <app-final-settelement-expansion [from]="'f&f'" [employeeId]="employeeId"
      [showFinalSettlementView]="showFinalSettlementView" (closeExpansion)="closeExpansion()"
      [permissions]="permissions" [currentStatus]="currentStatus">
    </app-final-settelement-expansion>
  </ng-container>
</ng-container>
