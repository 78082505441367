<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Settings</h3>
      <p class="text-helper mb-md-0">Define and configure the asset settings company-wise.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]="searchlistdata" [searchplaceholder]='searchString'
            (searchEmitter)="searchresult($event)" (keywordsearchEmitter)="searchconfig($event)" [isLoading]="isLoading">
          </app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions?.a"class="btn flex-shrink-0 btn-primary btn-icon btn-add" routerLink="/asset-settings/asset-settings-setup"><i
            class="icon-plus"></i>ADD CONFIGURATION</button>
      </div>
    </div>
  </div>
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <div *ngIf="nodata && !loader;else all_data"  class="row " style="height: calc(100vh - 15rem);">

    <app-nodata-view class="vstack" [noDataImg]="'bg10.png'" [noDataText]="'No configurations added yet'" [hasPermission]="permissions?.a"
      (addData)="addNewdata()"> </app-nodata-view>

  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngFor="let company of items,let i = index">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="detailedView(company.id)">
                <div *ngIf="company?.logo == '' || company?.logo == null" class="bg{{(i+1)%5}} sq-48 avatar-box me-16">
                  {{this.appService.getFirstChar(company.company_name,2)}}</div>
                <img *ngIf="company.logo != '' && company.logo != null" class="logo-img img-fluid"
                  src="{{company.logo}}">
                <!-- <div  class="logo-img bg{{i%5}}">SW</div> -->
                <div class="overflow-hidden me-auto">
                  <h6 class="mb-0 tips">{{company?.company_name}}</h6>
                  <span class="tips-helper">{{company?.company_name}}</span>
                  <span
                    class="fs-10 text-uppercase fw-500 {{(company?.is_active == true) ? 'text-success' : 'text-danger'}}">{{(company.is_active==
                    true) ? 'Active' : 'Deleted'}}
                  </span>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="permissions?.e && company?.is_active">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical "></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1" >
                  <button ngbDropdownItem routerLink="/asset-settings/asset-settings-setup/{{company.id}}">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <!-- <button class="text-danger" ngbDropdownItem>
                    <i class="icon-trash  me-1"></i> Delete
                  </button> -->
                </div>
              </div>
            </div>

          </div>
        </div>
      </ng-container>
    </div>
    </ng-template>

</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">
        <div class="row row-24 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">Settings</p>
            <a *ngIf="permissions?.e && viewData?.is_active" class="link-primary fs-14 ms-auto" routerLink="/asset-settings/asset-settings-setup/{{viewData?.id}}" ><i
                class="icon-pencil-square me-1"></i>
              Edit</a>
          </div>
        </div>
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">Company details </span></div>
                <div class=" col-sm-6 col-12">
                  <div class="detail-title">Company name</div>
                  <div class="detail-desc">{{viewData?.company_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc">
                    <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewData?.is_active">ACTIVE</span>
                    <span class="fs-12 fw-500 text-danger text-uppercase"
                      *ngIf="viewData?.is_active==false">DELETED</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">Asset </span></div>
                <div class="col-12">
                  <div class="detail-title">How many days after DOJ, the task to assign asset for new joinee should be triggered? </div>
                  <div class="detail-desc">{{viewData?.assets_given_after_days}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">How many days before relieving, the task to recover assets from the employee should be triggered? </div>
                  <div class="detail-desc">{{viewData?.assets_taken_before_days}}</div>
                </div>
                <!-- <div class="col-12">
                  <div class="detail-title">How many days before the warranty expiration date, the notification should be triggered to the task owner?</div>
                  <div class="detail-desc">{{viewData?.warranty_notification_days}}</div>
                </div> -->
              </div>
            </div>
          </div>

        </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>
