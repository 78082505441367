<div class="side-panel side-pane-active" style="--sidepanel-width:878px;"
  [class.side-pane-active]='bannerSidebar === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Union budget 2024-2025 </h5>
      <a class="toggle-panel" (click)="closefn()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <div class="card card-c2  radius-16 overflow-hidden">
              <div class="card-body bg-grad1   p-0 ">
                <div class="bg-pattern1 text-white px-24 py-32">
                  <p class="mb-16 ">
                    <span class="fs-20 fw-600">
                      Union budget 2024-2025 changes are effective from the September pay run.</span>
                  </p>
                  <p class="mb-0 fw-500 fs-14">Below are the key updates that will take effect starting with the <span class="fw-600"> September 2024 </span>pay run:</p>
                </div>
              </div>
              <div class="card-body bg-white p-24">
                <div class="row row-16">
                  <div class="col-12">
                    <ul class="ps-16 mb-0 li-24">
                      <li>
                        <p class="mb-16"><span class="fw-600">
                          Revised tax rates</span> for employees under the new tax regime.
                        </p>
                        <div class="card card-c2">
                          <div class="table-responsive radius-4">
                            <table class="table  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                              <thead>
                                <tr>
                                  <th class="fw-600 text-uppercase" scope="col">INCOME TAX SLAB FOR NEW REGIME</th>
                                  <th class="fw-600 text-uppercase" scope="col">INCOME TAX RATES</th>
                                </tr>
                              </thead>
                              <tbody class="cell-16 ">
                                <tr>
                                  <td>Up to ₹3 lakhs </td>
                                  <td>0% </td>
                                </tr>
                                <tr>
                                  <td> ₹3 lakhs to ₹7 lakhs</td>
                                  <td>5% </td>
                                </tr>
                                <tr>
                                  <td>₹7 lakhs to ₹10 lakhs </td>
                                  <td>10% </td>
                                </tr>
                                <tr>
                                  <td>₹10 lakhs to ₹12 lakhs </td>
                                  <td>15% </td>
                                </tr>
                                <tr>
                                  <td>₹12 lakhs to ₹15 lakhs </td>
                                  <td>20% </td>
                                </tr>
                                <tr>
                                  <td>Above ₹15 lakhs </td>
                                  <td>30% </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </li>
                      <li>The <span class="fw-600"> Standard Deduction</span> has been increased from <span
                          class="fw-600">₹50,000</span> to <span class="fw-600">₹75,000</span> under the New Tax Regime
                      </li>
                      <li>
                        Under Section 80CCD(2), the deduction limit for<span class="fw-600"> NPS Employer
                          Contributions</span> has been increased from<span class="fw-600"> 10% </span>to <span
                          class="fw-600">14%</span> of the salary (Basic+DA).
                      </li>
                    </ul>
                  </div>
                  <div class="col-12">
                    <div role="alert" class="alert alert-warning py-10 mb-0 pe-32 show">
                      <p class="mb-0 fs-14 ">These revised changes will take effect from the <span
                        class="fw-600">October</span> pay run if the <span class="fw-600">September
                      </span> pay run has already been processed.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
