<form class="">
  <div class="container-fluid p-32 form-container">
    <div class="row row-16">
      <div class="col-12">
        <p class="mb-0 text-uppercase fw-500">SUMMARY</p>
      </div>
      <div class="col-12">
        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16 ">
            <div class="col-12 align-items-center d-flex">
              <p class="title mb-8">LOAN request</p>
            </div>
            <div class="col-sm-4 col-md-3 col-12">
              <div class="detail-title">Loan type</div>
              <div class="detail-desc">{{formGp.value?.loan_type}}</div>
            </div>
            <div class="col-sm-4 col-md-3 col-12">
              <div class="detail-title">Interest type</div>
              <div class="detail-desc">{{loanTypeDetails?.interest_type}}</div>
            </div>
            <div class="col-sm-4 col-md-3 col-12">
              <div class="detail-title">Maximum amount</div>
              <div class="detail-desc">{{loanTypeDetails?.amount_limit | currency :  currency}}</div>
            </div>
            <div class="col-sm-4 col-md-3 col-12">
              <div class="detail-title">Maximum EMI</div>
              <div class="detail-desc">{{loanTypeDetails?.max_number_emis}}</div>
            </div>
            <div class="col-12">
              <div class="detail-title">Eligibility</div>
              <div class="detail-desc">{{loanTypeDetails?.loan_eligibility_days}} days from date of joining</div>
            </div>
            <div class="col-sm-4 col-md-6 col-12">
              <div class="detail-title">Interest penalty on payment shortfall rate%</div>
              <div class="detail-desc">{{loanTypeDetails?.interest_penality}}</div>
            </div>
            <div class="col-sm-4 col-md-3 col-12">
              <div class="detail-title">Loan amount</div>
              <div class="detail-desc">{{formGp.value?.loan_amount | currency : currency}}</div>
            </div>
            <div class="col-sm-4 col-md-3 col-12">
              <div class="detail-title">Number of EMI</div>
              <div class="detail-desc">{{formGp.value?.number_of_emi}}</div>
            </div>
            <div class="col-sm-4 col-md-3 col-12">
              <div class="detail-title">Loan required date</div>
              <div class="detail-desc">{{this.appServ.dateFormatDisplay(formGp.value?.required_date)}}</div>
            </div>
            <div class="col-12">
              <div class="detail-title">Remarks</div>
              <div class="detail-desc">{{formGp.value?.reason == null ? '-' : formGp.value?.reason}}</div>
            </div>
            <div class=" col-12">
              <div class="detail-title">Document </div>
              <div class="detail-desc" *ngIf="formGp.value?.document == ''">-</div>
              <div class="detail-desc" *ngIf="formGp.value?.document != ''">
                  <div class="card card-c2  bg-secondary">
                    <div class="p-12 bg-secondary d-flex align-item-center">
                      <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                      <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                        <span class="tips text-uppercase fs- fw-700 text-trim">{{this.appServ.getDocName(formGp.value?.document)}}</span>
                        <span class="tips-helper ">{{this.appServ.getDocName(formGp.value?.document)}}</span>
                        <div class="d-flex gap-8">
                          <div class="link-primary" (click)="viewDocument(formGp.value?.document)">
                            <i class="bi bi-eye me-1"></i>View
                          </div>
                          <div class="link-primary"
                            (click)="downLoad(formGp.value?.document)">
                            <i class="bi bi-download me-1"></i>Download
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto" [disabled]="saveClicked" (click)="validateLoanSummary()">Save</button>
  </footer>
</form>

<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="investalertMsg"></app-alert-toggle>
<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
  [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
