import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-view-short-leave',
  templateUrl: './view-short-leave.component.html',
  styleUrls: ['./view-short-leave.component.scss']
})
export class ViewShortLeaveComponent implements OnInit {

  // Input
  @Input() loader               : any;
  @Input() shortLeaveData       : any;
  @Input() requestId            : any;
  @Input() shortLeaveView       : any
  @Input() requestName          : any
  @Input() from                 : any
  @Input() title                : any


  // Ouput
  @Output() shortLeaveClose      = new EventEmitter()
  @Output() closeViewPage        = new EventEmitter();

  // Var Initialization
  viewstatusPanel      : boolean = false
  statusLoader         : boolean = false
  requestCancel        : boolean = false
  viewStatusData       : any
  viewStatusLevelCnt   : any
  viewStatusmaxLevel   : any
  emp_ID               : any
  cancelId             : any
  status               : any
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension     : any
  allowedExtension                = "";
  investalertMsg       : any      = '';
  alertToggle                     = false;
  fileName                        = ''

  constructor(
    public appServ    : AppService,
    private invServ   : InvestmentDeclerationService,
    private notifyServ: NotificationService
  ) { }

  ngOnInit(): void {
  }

  // View Status
  statusData(id:any){
    this.viewstatusPanel = true
    this.statusLoader = true
    this.invServ.viewStatusSHLData(this.requestId,id).subscribe((res:any)=>{
      this.viewStatusData = res
      this.statusLoader = false
      this.viewStatusLevelCnt = res?.activity?.level_count
      this.viewStatusmaxLevel = res?.activity?.maxlevel
    })
  }

  // DOC view
  viewDocument(filename:any){
    this.modalToggle      = true;
    this.fileName         = filename;
    this.currentDocName   = this.appServ.getDocName(filename);
    this.currentExtension = this.fileName.split('.').pop();
    if(this.currentExtension == 'doc' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'csv'){
      this.modalToggle    = false;
      this.alertToggle    = true;
      this.investalertMsg = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }else if(this.currentExtension == 'pdf'){
      this.modalToggle    = true;
      this.alertToggle    = false;
    }
  }

  close1(bool:any){
    this.modalToggle = bool;
  }

  // Cancellation
  cancelRequest(status:any,cancelStatus:any){
    if(cancelStatus){
      this.notifyServ.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else{
     this.requestCancel = true
    }
  }

  // Cancel Popup
  closeCancelModal(data:any){
    this.requestCancel = data?.show;
    if(data?.action == 'submit')
      this.closeViewPage.emit(data?.show);
  }

  // Main model popup
  closeFunction(){
    this.shortLeaveClose.emit(false);
  }

  // Download File
  downLoad(val:any){
    let url = this.appServ.getDocName(val).split('.')[0]
    this.appServ.downloadFile(val,url)
  }
  updateStatus(data:any){

    this.closeViewPage.emit(data);
    this.requestCancel = false
  }
}
