<ng-select class="multiselect" placeholder="{{this.messageService.selectplaceholddisplay(placeholders)}}" [items]="items" [multiple]="true"
[selectableGroup]="true" groupBy="selectedAllGroup" [selectableGroupAsModel]="false" bindLabel="bindLabelName" [readonly]="disabled" [closeOnSelect]="false" bindValue="id"  [ngModel]="selection" (change)="commonFn($event)">
  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>Select All
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngIf="selectedData.length !=0">
        <span class="ng-value-label">{{selectedData.length}} {{placeholders}} selected</span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="d-inline-flex align-items-center">
      <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }"/>
      <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="item.logo !=  null && item.logo !=  ''"src="{{item.logo}}">
      <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>
      <span class="mt-1 ms-1">{{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}</span>
    </div>
  </ng-template>
</ng-select>
<!-- Selected items listing -->
<ul class="selected-values my-16" *ngIf="selectedData?.length !=0">
  <li class="ng-value" *ngFor="let item of selectedData,let ki =index">
      <ng-container *ngFor="let insideitem of items, let k =index">
          <span class="ng-value-label" *ngIf="insideitem['id']==item['id']">
              <div class="d-inline-flex align-items-center">
                <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{k%5}} me-1" >
                    <span>
                    {{this.appService.getFirstChar(insideitem.name,1)}}</span>
                </span>
                <span class=""> {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }} </span>
             </div>
          </span>
      </ng-container>
      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(placeholders,ki,indexValue)" aria-hidden="true"><i class="icon-close"></i></span>

  </li>
</ul>
