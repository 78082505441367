import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-fnf-expense-report-request-approval',
  templateUrl: './fnf-expense-report-request-approval.component.html',
  styleUrls: ['./fnf-expense-report-request-approval.component.scss']
})
export class FnfExpenseReportRequestApprovalComponent implements OnInit {

  @Input() requestitem:any;
  @Input() data :any;
  @Input() empId :any;
  @Input() archieved_status :any;
  @Input() currency:any;

  @Input() payrollStatus:any;

  @Input() reqStatus:any;

  activeMenu                = "Expense";
  pageSize                  = 20;
  page                      = 1;

  viewReciept               = false;
  viewRecieptLoader         = false;
  viewRecieptData:any       = [];

  viewAttchments            = false;
  receiptId                 = 0;
  uploadedAttachments:any   = [];

  viewAdvance               = false;
  advanceid                 = 0;
  viewLoader                = false;
  viewdata:any              = [];

  modalToggle               = false;
  currentDocName            = "";
  currentExtension          = "";
  allowedExtension          = "";
  fileName:any;
  alertMsg:any              = '';
  alertToggle               = false;


  @Output() fnfApprovalViewClose: EventEmitter<boolean> =  new EventEmitter();

  @Output() unprocessWarningPopup =  new EventEmitter();

  constructor(
    public finalSettlementService: FinalSettlementService,
    private fb: FormBuilder,
    public messageService : MessageService,
    public appservice: AppService,
  ) { }

  form = this.fb.group({
    read_status     : [true,Validators.required],
    approved_status : [false,Validators.required],
    comment         : ['', Validators.maxLength(500)],
  })

  ngOnInit(): void {
  }

  get f() {return  this.form.controls; }


  confirm(status:any){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.unprocessWarningPopup.emit();
    }else{
      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }
      this.finalSettlementService.updateInboxItem(this.data?.id,this.form.value).subscribe((res)=>{
        if(res){
          this.refreshInbox(false);
          this.form.get('comment')?.reset('')

        }
      })
    }
  }

  refreshInbox(event: boolean){
    this.fnfApprovalViewClose.emit(event)
  }

}
