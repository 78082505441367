<form class="" [formGroup]="formGp">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">Assign report</p>
        </div>
        <div class="row row-16">
          <!-- NEW START -->
          <div class="col-md-12 form-row ">
            <label for="" class="form-label required">Assign reports by  </label>
          </div>
          <div class="col-md-12 form-row ">
            <div class="form-check form-check-inline">
                <input  name="assign_report_by"  type="radio" id="assign_ou"  class="form-check-input" [value]="true" (change)="priorityFunction($event)" [checked]="priorityFlag">
                <label  for="assign_ou" class="form-check-label">Company units</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Assign reports  based on organizational hierarchy."  popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
            </div>
            <div class="form-check form-check-inline">
                <input name="assign_report_by" type="radio" id="assign_employee" class="form-check-input " [value]="false" (change)="priorityFunction($event)" [checked]="!priorityFlag">
                <label for="assign_employee" class="form-check-label">Employees</label>
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-12 tooltip-icon" ngbPopover="Assign reports  based on specific employees."  popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></span>
            </div>
        </div>
          <!-- NEW END -->
          <ng-container *ngIf="(priorityFlag)">
            <div class="col-md-6 form-row">
                <label for="" class="form-label">Company  </label>
                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Company')}}" formControlName="company" [items]="companyDD" [multiple]="true" groupBy="selectedAllGroup"
                [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="company_name" [closeOnSelect]="false" bindValue="id"  class="form-ngselect multiselect" [ngClass]="{ 'is-invalid': (submitted || f.company.touched) && f.company.errors}" (change)="getBUListFunction()">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                  </ng-template>

                   <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="formGp.value.company?.length ">
                          <span class="ng-value-label">{{formGp.value.company.length }} company selected</span>
                      </div>
                    </ng-template>
                    <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index">
                    <div class="d-inline-flex align-items-center">
                      <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                      <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="item.logo !=  null && item.logo !=  ''" src="{{item.logo}}">
                     <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appservice.getFirstChar(item.company_name,1)}}</span></span>

                     <span class="">{{ (item.company_name.length>15)? (item.company_name | slice:0:15)+'..':(item.company_name)  }}</span>
                    </div>
                </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.company.touched) && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values mt-16" *ngIf="formGp.value.company?.length != 0">
                  <li class="ng-value" *ngFor="let item of formGp.value.company,let ki =index">
                      <ng-container *ngFor="let insideitem of companyDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                            <div class="d-inline-flex align-items-center">
                              <img class=" img-fluid rounded-circle sq-24"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null" src="{{insideitem.logo}}">
                              <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''" class="  sq-24 avatar-circle rounded-circle me-1 bg{{k%5}}" >
                                  <span>
                                  {{this.appservice.getFirstChar(insideitem.company_name,1)}}</span>
                              </span>
                              <span class=""> {{ (insideitem['company_name'].length>25)? (insideitem['company_name'] | slice:0:25)+'..':(insideitem['company_name'])  }}</span>
                           </div>
                          </span>

                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('company',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-md-6 form-row">
                <label for="" class="form-label">Business Unit  </label>
                <ng-select [readonly]="formGp.value.company?.length == 0 ? true : false" placeholder="{{this.messageService.selectplaceholddisplay('Business Unit')}}" formControlName="businessunit" [items]="buDD" [multiple]="true" groupBy="selectedAllGroup"
                [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="form-ngselect multiselect" [ngClass]="{ 'is-invalid': (submitted || f.businessunit.touched) && f.businessunit.errors}" >
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                      {{ item.name }}
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="formGp.value.businessunit?.length">
                          <span class="ng-value-label">{{formGp.value.businessunit?.length}} business unit selected</span>
                      </div>
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.businessunit.touched) && f.businessunit.errors" class="invalid-feedback">
                <div *ngIf="f.businessunit.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values mt-16" *ngIf="formGp.value.businessunit?.length != 0">
                  <li class="ng-value" *ngFor="let item of formGp.value.businessunit,let ki =index">
                      <ng-container *ngFor="let insideitem of buDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('businessunit',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-md-6 form-row">
                <label for="" class="form-label">Department  </label>
                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Department')}}" formControlName="department" [items]="DepDD" [multiple]="true" groupBy="selectedAllGroup"
                [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="form-ngselect multiselect" [ngClass]="{ 'is-invalid': (submitted || f.department.touched) && f.department.errors}" >
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                      {{ item.name }}
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                      <div class="ng-value" *ngIf="formGp.value.department?.length ">
                          <span class="ng-value-label">{{formGp.value.department?.length}} department selected</span>
                      </div>
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.department.touched) && f.department.errors" class="invalid-feedback">
                <div *ngIf="f.department.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values mt-16" *ngIf="formGp.value.department?.length != 0">
                  <li class="ng-value" *ngFor="let item of formGp.value.department,let ki =index">
                      <ng-container *ngFor="let insideitem of DepDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('department',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-md-6 form-row">
                <label for="" class="form-label">Designation</label>
                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}" formControlName="designation" [items]="DesignationDD" [multiple]="true" groupBy="selectedAllGroup"
                [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="form-ngselect multiselect" [ngClass]="{ 'is-invalid': (submitted || f.designation.touched) && f.designation.errors}" >
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                      {{ item.name }}
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                      <div class="ng-value" *ngIf="formGp.value.designation?.length">
                          <span class="ng-value-label">{{formGp.value.designation?.length}} designation selected</span>
                      </div>
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.designation.touched) && f.designation.errors" class="invalid-feedback">
                <div *ngIf="f.designation.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values mt-16" *ngIf="formGp.value.designation?.length != 0">
                  <li class="ng-value" *ngFor="let item of formGp.value.designation,let ki =index">
                      <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('designation',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-md-6 form-row">
                <label for="" class="form-label">Branch  </label>
                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}" formControlName="branch" [items]="BranchDD" [multiple]="true" groupBy="selectedAllGroup"
                [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="form-ngselect multiselect" [ngClass]="{ 'is-invalid': (submitted || f.branch.touched) && f.branch.errors}"  >
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        {{ item.name }}
                    </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                      <div class="ng-value" *ngIf="formGp.value.branch?.length ">
                          <span class="ng-value-label">{{formGp.value.branch?.length }}  branch selected</span>
                      </div>
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.branch.touched) && f.branch.errors" class="invalid-feedback">
                <div *ngIf="f.branch.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values mt-16" *ngIf="formGp.value.branch?.length != 0">
                  <li class="ng-value" *ngFor="let item of formGp.value.branch,let ki =index">
                      <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('branch',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-md-6 form-row">
                <label for="" class="form-label">Grade   </label>
                <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Grade')}}" formControlName="grade" [items]="gradeDD" [multiple]="true" groupBy="selectedAllGroup"
                [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="form-ngselect multiselect" [ngClass]="{ 'is-invalid': (submitted || f.grade.touched) && f.grade.errors}" >
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                      {{ item.name }}
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="formGp.value.grade?.length ">
                          <span class="ng-value-label">{{formGp.value.grade?.length }} grade selected.</span>
                      </div>
                  </ng-template>
                  <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index">
                      {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.grade.touched) && f.grade.errors" class="invalid-feedback">
                <div *ngIf="f.grade.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values mt-16" *ngIf="formGp.value.grade?.length != 0">
                  <li class="ng-value" *ngFor="let item of formGp.value.grade,let ki =index">
                      <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('grade',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
          </ng-container>
          <ng-container *ngIf="(!priorityFlag)">
            <div class="col-12 form-row">
                <label for="" class="form-label">Employees   </label>
                <ng-select
                [ngClass]="{ 'is-invalid': (submitted || f.employee.touched) && f.employee.errors}"
                [readonly]="disabled"
                placeholder="{{this.messageService.selectplaceholddisplay('Employees')}}"
                formControlName="employee"
                [items]="employeeDropdown"
                [multiple]="true"
                groupBy="selectedAllGroup"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                bindLabel="name"
                [closeOnSelect]="false"
                bindValue="id"
                class="form-ngselect multiselect"
                (click)="employeeClick()"
                >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                </ng-template>

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="formGp.value.employee?.length">
                        <span class="ng-value-label">{{formGp.value.employee?.length}} employee selected.</span>
                    </div>
                </ng-template>
                <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                  <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="item.profile_image !=  null && item.profile_image !=  ''" src="{{item.profile_image}}">
                 <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appservice.getFirstChar(item.name,1)}}</span></span>

                 <span class="">{{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}</span>
                </div>
              </ng-template>
            </ng-select>
            <div *ngIf="(submitted || f.employee.touched) && f.employee.errors" class="invalid-feedback">
              <div *ngIf="f.employee.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
            <ul class="selected-values mt-16" *ngIf="formGp.value.employee?.length !=0">
                <li class="ng-value" *ngFor="let item of formGp.value.employee, let m =index">
                    <ng-container *ngFor="let insideitem of employeeDropdown, let k =index">
                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    <div class="d-inline-flex align-items-center">
                        <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                            src="{{insideitem.profile_image}}">
                        <span *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''" class="avatar-sm sq-24 img-fluid rounded-circle me-8 {{insideitem.color_code}}" >
                            <span>
                            {{this.appservice.getFirstChar(insideitem.fullname,1)}}</span>
                        </span>
                        <!-- <span class="mt-2 ms-1"> {{ (insideitem['first_name'].length>15)? (insideitem['first_name'] | slice:0:15)+'..':(insideitem['first_name'])  }}</span> -->
                        <span class=""> {{ (insideitem['name']?.length>20)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                        <!-- <span class="">{{insideitem.first_name+" "+(insideitem.middle_name!=null?insideitem.middle_name+" ":" ")+insideitem.last_name }} </span><span> {{" ("+insideitem.employee_code+")"}}</span> -->
                     </div>
                     </span>
                    </ng-container>
                    <span *ngIf="disabled==false" class="ng-value-icon right" aria-hidden="true" (click)="clearcommonForm('employee',m)">×</span>
               </li>
            </ul>
            </div>
          </ng-container>
        </div>
    </div>
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="saveClicked || checkcommonFormValid()" (click)="validateRights()">SAVE</button>
        <!-- <button class="btn btn-primary btn-sm ms-auto" (click)="validateRights()">SAVE</button> -->
    </footer>
</form>

<!-- Alert for investment declaration start -->
<!-- <ng-container *ngIf="priorityFlag">
<div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-warning"></i>
        <h4 class="modal-title">Info</h4>
        <a class="ms-auto text-light-600"(click)="alertToggle = false && priorityFlag = false" ><i class="icon-close-lg fs-16"></i></a>

      </div>
      <div class="modal-body py-0">
        <p class="mb-0">Based on the priority, when employees are selected, other fields selected will be deselected and disabed. Are you sure you want to continue?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary text-uppercase btn-sm" (click)="infoOk()">Ok</button>
      </div>
    </div>
  </div>
</div>
</ng-container> -->
