import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder,  Validators } from '@angular/forms';
import { debounceTime,  delay,switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HrITDeclarationService } from 'src/app/@core/services/hr-ivestment-declaration.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-investment-declaration',
  templateUrl: './investment-declaration.component.html',
  styleUrls: ['./investment-declaration.component.scss']
})
export class InvestmentDeclarationComponent implements OnInit {
  investmentNotSubmitted = false;
  investmentSubmitted = false;
  offset                   = 0;
  listlength               = 0;
  direction                = '';
  defaultlimit:any         = 20;
  loader =false;
  searchlistdata:any       = [];
  infinityloader           = false;
  nodata                   = false;
  empDetailsList:any       = [];
  emplist:any              = [];
  searchKeyword            = "";
  searchplaceholder:string = this.messageService.searchdisplay('Employee');
  activeToggle:any         = 'proposed';
  loader1                  = false;
  filter                   = false;
  filterdata:any;
  filterForm                = this.formBuilder.group({
    company               : '',
    bu                    : '',
    department            : '',
    designation           : '',
    grade                 : '',
    emp_status            : '',
    emp_type              : '',
    branch                : '',
    repomanager           :'',
    funmanager            :'',
    sortby                : ['All'],
  }
);
@ViewChild(SearchbarComponent) child:any;
permissions:any     = [];
searchData : Subject<void> = new Subject();
empId               = 0;
itfulldata:any      = [];
sLoading            = false;
downloadlist:any    = [];
title               = 'Access all employees IT declarations and utilize bulk import for multiple entries, as well as manage single employee requests.';
currentFilterYear   = this.appService.getFinancialYear();
selectedYear :any   = this.appService.getFinancialYear();
maintileloader      = true; 
from                = 'ITHR';
resetcall           = false;
loadersidebar       = false;
viewemploy_id       :any;
exportData:any      = [];
submitnotsubmitkey  = false;
  constructor(
    public breakpointObserver: BreakpointObserver,
    public formBuilder: FormBuilder,
    public appService: AppService,
    public messageService : MessageService,
    public HrITDeclarationService : HrITDeclarationService,
    public route:ActivatedRoute,
    public router:Router
  ) { }

  ngOnInit(): void {
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.selectedYear = params['year'];
      }
      if( isNaN(params['emp_id'])){
        this.viewemploy_id = params['emp_id'];
      }
      if( !isNaN(params['activeToggle'])){
        this.activeToggle = parseInt(params['activeToggle']);
      }
    })
    this.getPermission();
    this.showDataItems();

    this.searchData.pipe(delay(1000),
      switchMap(() => this.HrITDeclarationService.empletterList('',this.defaultlimit,this.offset,this.searchKeyword,this.filterdata,this.empId,false,this.currentFilterYear,'')
    )).subscribe((res: any) => {
      this.showdatacommonfn(res);
      this.loader = false;
    });  

  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/hr-investment-declaration');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  showdatacommonfn(res:any){
    this.listlength = res?.body?.results?.length;
      if(this.offset == 0){
        this.emplist = [];
        this.empDetailsList=[];
      }
      if (res?.body?.results?.length == 0) {
        this.nodata = this.offset == 0 ? true :false;
      }else {
        this.nodata = false;
        this.empDetailsList = res?.body?.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      // this.loader = false;
  }

  showDataItems(){
    this.loader = true;
    let prop_confrimkey:any
    if(this.activeToggle=='confirmed'){
      prop_confrimkey = true;
    }else{
      prop_confrimkey = false;
    }
    this.HrITDeclarationService.empletterList('',this.defaultlimit,this.offset,this.searchKeyword,this.filterdata,this.empId,prop_confrimkey,this.currentFilterYear,'').subscribe((res: any)=> {
      this.itfulldata=res?.body;
      this.showdatacommonfn(res);
    })
  }

  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.emplist.push(this.empDetailsList[i]);
    }
    this.loader = false;
    this.maintileloader   = false;
  }

  searchresults(event:any){
    this.loader  = true;
    this.emplist = [];
    this.empId   = 0
    if(event == ""){
      this.searchKeyword  = "";
      this.offset     = 0;
      this.searchData.next()
    }else{
      this.empId = event
      this.searchData.next()
    }
  }

  searchemp(searchKeyword:any){
    this.loader  = true;
    this.offset  = 0;
    this.emplist = [];
    this.empId   = 0
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  viewemp(id:any){
    this.router.navigateByUrl("/view-investment-declaration/"+this.selectedYear+"/"+this.activeToggle+"/"+id)
    this.viewemploy_id =id;   
  }

  investmentlist(key:any){
    let submittype ='';
    if(key=='false'){
      submittype='exclude';
      this.submitnotsubmitkey =false;
    }else{
      submittype='submitted';
      this.submitnotsubmitkey =true;
    }
    let prop_confrimkey:any;
    if(this.activeToggle=='confirmed'){
      prop_confrimkey = true;
    }else{
      prop_confrimkey = false;
    }
    this.investmentSubmitted = true;    
    this.loadersidebar =true;
      this.HrITDeclarationService.empletterList(this.activeToggle,'','','',this.filterdata,'',prop_confrimkey,this.currentFilterYear,submittype).subscribe((res: any)=> {
        this.downloadlist = res?.body?.result;
        this.loadersidebar =false;
      })    
  }

  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  selectItemList(value:any){
    this.filterdata = '';
    // if(value=='proposed'){
    //   this.title='Pending Letters can be generated on behalf of the task owners in this section.';
    // }else{
    //   this.title='View and download the pending and generated letters in this section.';
    // }
      this.maintileloader   = true;
      this.loader           = true;
      this.activeToggle     = value;
      this.offset           = 0;
      this.emplist          = [];
      this.empDetailsList   = [];
      this.showDataItems();

    this.child.clearBar();
  }

  yearChange(value:any){
    this.maintileloader   = true;
    this.selectedYear       = Number(value);
    this.currentFilterYear  = value;
    this.showDataItems();
  }

  filterclick(){
    this.filter = true;
   }

   resultfilterfn(data:any){
    if(this.resetcall == true){
      this.filterdata = '';
    }else{
      this.filterdata = data;
    }
        this.offset = 0;
    this.child.clearBar();
    this.applyfilterfn();
  }

  closefn(daata:any){
    this.filter = daata;
   }

   applyfilterfn(){
    this.loader = true;
    this.nodata       = false;
    this.showDataItems();
    this.filter = false;
    this.resetcall = false;
   }

   resetCalledfn(ev:any){
    if(ev == true){
      this.resetcall = ev;
    }
   }

   generateTemplate(){
    let header:any=[]
    let key = '';
    if(this.submitnotsubmitkey==true){
       header = ['Emp Code','Emp Name','Company','Department','Last Submitted On','Status'];
       key = 'submitted';
    }else{
       header = ['Emp Code','Emp Name','Company','Department'];      
       key = ' not submitted';
    }
      
    let body   = this.downloadlist;
    let excelTemp      = [];
    this.exportData = [];

    for (let i = 0; i < body.length; i++) {
      var dict:any = {};
      for(let j=0;j<header.length;j++){
        if(header[j]=='Emp Name'){
            dict[header[j]] = body[i]['first_name'] +' '+ (body[i]['middle_name'])?(body[i]['middle_name']):'' +' '+ body[i]['last_name'];
        }
        else if(header[j]=='Emp Code')
        dict[header[j]] = body[i]['employee_code'];
        else if(header[j]=='Company')
        dict[header[j]] = body[i]['company'];
        else if(header[j]=='Department')
          dict[header[j]] = body[i]['department'];
        else if(header[j]=='Last Submitted On')
          dict[header[j]] = this.appService.dateFormatDisplay(body[i]['created_at']);
        else if(header[j]=='Status')
          dict[header[j]] = body[i]['status'];
      }
      excelTemp.push({
        dict
      })

    }
    for(let j=0;j<excelTemp.length;j++){
      this.exportData.push(excelTemp[j]['dict'])
    }
      this.appService.exportExcel(this.exportData,'Investment ' + key +' List');
  }


}
