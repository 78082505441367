<app-c1-loader *ngIf="loader"></app-c1-loader>

<div class=" config-layout" *ngIf="!loader">
    <header class=" config-header">
        <h5 class="m-0">Details</h5>
        <button class="btn-reset text-light-600" routerLink="/run-payroll/{{year}}/{{month}}/{{company}}"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <main class=" config-container">
        <form class="">
            <div class="container-fluid p-32 form-container">
                <div class="row row-16 mb-16">
                    <div class="col-12 ">
                        <span class="fw-500 text-uppercase">Pending requests</span>
                    </div>
                </div>
                <div class="row row-24 row-cols-xl-5">
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div style="min-height: 7.5rem;"
                            class="card card-c2 card-hover card-highlight highlight-primary"
                           (click)="detailedFYView(fyData?.reimbursement_pending_list[0]?.count,'reimbursement')">
                            <div class="card-body p-16 text-center">
                                <h6 class="fw-500  fs-20">{{fyData?.reimbursement_pending_list[0]?.count}}</h6>
                                <hr class="hr-1 mb-16 mt-16">
                                <div class="mb-0 fw-500 fs-12">Pending reimbursement request </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 ">
                        <div style="min-height: 7.5rem;" class="card card-c2 card-hover card-highlight highlight3"
                            (click)="detailedFYView(fyData?.confirmed_pending_list[0]?.count,'confirmed_investment')">
                            <div class="card-body p-16 text-center">
                                <h6 class="fw-500  fs-20">{{fyData?.confirmed_pending_list[0]?.count}}</h6>
                                <hr class="hr-1 mb-16 mt-16">
                                <div class="mb-0 fw-500 fs-12">Pending confirmed investment declaration</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div style="min-height: 7.5rem;" class="card card-c2 card-hover card-highlight highlight2"
                            (click)="detailedFYView(fyData?.fbp_pending_list[0]?.count,'fbp')">
                            <div class="card-body p-16 text-center">
                                <h6 class="fw-500  fs-20">{{fyData?.fbp_pending_list[0]?.count}}</h6>
                                <hr class="hr-1 mb-16 mt-16">
                                <div class="mb-0 fw-500 fs-12">Pending FBP declaration</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </main>
</div>

