import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-loader',
  template: `
  <p class="text-uppercase fw-500"><span></span> <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '127px' }"></ngx-skeleton-loader></p>
  <div class="row row-24 ">
    <div class="col-12 col-md-6 form-row "  *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
      <label for="name" class="form-label"><ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '13px','width': '127px' }"></ngx-skeleton-loader></label>
       <ngx-skeleton-loader appearance="line" [theme]="{'background-color': '#EAEDEF','height': '30px','width': '100%' }"></ngx-skeleton-loader>
    </div>
  </div>
  `,
  styles: [
  ]
})
export class EditLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
