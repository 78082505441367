import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  //  API for Page Access list
  gettemplatedetails(limit: any, offset: any,status:any,search:any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/template-list/?limit=${limit}&offset=${offset}&is_active=${status}&search=${search}`, this.httpOption)
  }

  posttemplatedetails(data: any) {
    return this.http.post(`${this.API_PATH}hr/api/v1/template-list/`, data, this.httpOption)
  }

  getsingletemplatedetail(id: any) {
    return this.http.get(`${this.API_PATH}hr/api/v1/template-detail/${id}/`, this.httpOption)
  }

  updateTemplateDetail(body: any, id: number) {
    return this.http.put(`${this.API_PATH}hr/api/v1/template-detail/${id}/`, body, this.httpOption)
  }

  deleteTemplateDetail(id: number, body: any,) {
    return this.http.patch(`${this.API_PATH}hr/api/v1/template-detail/${id}/`, body, this.httpOption)
  }

  getTemplateType() {
    return this.http.get(`${this.API_PATH}hr/api/templatetype-dropdown-list/`, this.httpOption);
  }

  getTagnameDetails(limit: any, offset: any,typeid:any) {
    return this.http.get(`${this.API_PATH}hr/api/templatetag-dropdown-list/?limit=${limit}&offset=${offset}&template_type=${typeid}`, this.httpOption)
  }
  templateDropDownList(limit: any, offset: any,status:any,ordering:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/template-dropdown/?limit=${limit}&offset=${offset}&is_active=${status}&ordering=${ordering}`, this.httpOption)
  }

  getTaxSlip(limit: any, offset:any, search: any){
    return this.http.get(`${this.API_PATH}hr/api/v1/template-landing/?limit=${limit}&offset=${offset}&search=${search}&template_type__name=TaxSlip`, this.httpOption)
  }
  getTaxTemplateDropdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/template-dropdown/?is_active=true&ordering=template_name&template_type__name=TaxSlip`, this.httpOption)
  }
  getsalarySlip(limit: any, offset:any, search: any){
    return this.http.get(`${this.API_PATH}hr/api/v1/template-landing/?limit=${limit}&offset=${offset}&search=${search}&template_type__name=SalarySlip`, this.httpOption)
  }
  getsalaryTemplateDropdown(){
    return this.http.get(`${this.API_PATH}hr/api/v1/template-dropdown/?template_type__name=SalarySlip&is_active=true&ordering=template_name`, this.httpOption)
  }

}
