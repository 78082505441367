import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function GstnumValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const GstnumPattern = /^[a-zA-Z0-9]*$/.test(value);

      return !GstnumPattern ? {pattern:true}: null;
  }
}

