import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';

@Component({
  selector: 'app-salary-revision-inbox',
  templateUrl: './salary-revision-inbox.component.html',
  styleUrls: ['./salary-revision-inbox.component.scss']
})
export class SalaryRevisionInboxComponent implements OnInit {

  salaryRevisionDetailsView = false;
 revId = 0
 sendReminder = false
 @Input() from: any;
 @Input() requestitem: any;
 @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  // View Status
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;

  constructor(
    private inboxservice: InboxService,
    private investmentService:InvestmentDeclerationService
  ) { }

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
  }

  ngOnChanges(){
    setTimeout(() => {
      if(this.requestitem.request_data.request_object != undefined){
        this.revId = this.requestitem['request_data']['request_object'];
      }
    }, 1000);
  }


  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{
        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
              if(this.requestitem['id'] == id){
                this.requestitem[key] = value;
              }
          })
        }
      })
    }
  }



  salaryRevision(){
    if(this.revId != 0)
      this.salaryRevisionDetailsView = true;
  }

  refreshInbox(val: boolean){
    this.salaryRevisionDetailsView = false;
    this.successEmitter.emit(val);
  }

  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
    }

}

