
    <div class="container-fluid p-32 form-container"  [formGroup]="formGp">
        <div class="row ">
            <p class="text-uppercase fw-500">SHIFT MAPPING <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Map the appropriate shift for which this attendance rule is applicable" popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span></p>
        </div>
        <div class="row row-16">
            <div class="col-lg-6  form-row">
                <label for="" class="form-label required">Map Shift </label>
                <!-- <ng-select class="form-ngselect" placeholder="Select shift"></ng-select> -->
                <ng-select
                    [ngClass]="{ 'is-invalid': (submitted || f.shift.touched|| f.shift.invalid) &&  f.shift.errors}"
                    placeholder="{{this.messageService.selectplaceholddisplay('Shift Mapping')}}"
                    formControlName="shift"
                    [items]="shiftList"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    bindLabel="shift_mapping_name"
                    [closeOnSelect]="false"
                    bindValue="id"
                    class="form-ngselect"
                    >
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index">
                        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        {{ item.shift_mapping_name }}
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="formGp.value.shift?.length ">
                            <span class="ng-value-label">{{formGp.value.shift?.length }} shifts selected</span>
                        </div>
                    </ng-template>
                </ng-select>
                <div *ngIf="{ 'is-invalid': (submitted || f.shift.touched|| f.shift.dirty) &&  f.shift.errors}" class="invalid-feedback">
                    <div *ngIf="f.shift.errors?.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <ul class="selected-values " *ngIf="formGp.value.shift">
                    <li class="ng-value" *ngFor="let item of formGp.value.shift,let ki =index">
                        <ng-container *ngFor="let insideitem of shiftList, let k =index">
                            <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                {{ (insideitem['shift_mapping_name']?.length>15)? (insideitem['shift_mapping_name'] | slice:0:15)+'..':(insideitem['shift_mapping_name'])  }}</span>

                        </ng-container>
                        <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clear(ki)" aria-hidden="true">×</span>

                    </li>
                </ul>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid "  (click)="validateForm()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
    </footer>
