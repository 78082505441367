import { Component, EventEmitter, Input, OnInit, Output, ViewChild,Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-leave-carry-forward',
  templateUrl: './leave-carry-forward.component.html',
  styleUrls: ['./leave-carry-forward.component.scss']
})
export class LeaveCarryForwardComponent implements OnInit {

  @Input() employeeDropdown      : any
  @Input() carryForwardForm      : any
  @Input() viewDetail            : any
  @Input() submitted             : any
  @Input() companyDD             : any
  @Input() buDD                  : any
  @Input() DesignationDD         : any
  @Input() BranchDD              : any
  @Input() DepDD                 : any
  @Input() gradeDD               : any
  @Input() resDataFilter         : any
  @Input() disabled              : any
  @Input() callReport            : any
  @Input() closeControl          : any
  @Input() checkListElements     : any
  @Input() leavePolicy           : any
  @Input() leaveType             : any
  @Input() emptypeDD             : any
  @Input() empstatusDD           : any
  @Input() salFilter             : any
  @Input() pageNumber : any
  @Input() lastPage :any
  @Input() ordering : any
  @Input() ouFilterForm: any

  @Output() callReports  = new EventEmitter();
  @Output() filterPanel  = new EventEmitter();
  @Output() resetFilter  = new EventEmitter();
  @Output() submitDone   = new EventEmitter();

  // Form
  get f()  { return this.carryForwardForm.controls; }

  constructor(
    public messageService : MessageService,
    private reportService : ReportsService,
    public appservice : AppService,
    public cd : ChangeDetectorRef,
    public leaveService : LeaveReportService,
  ) { }

  ngOnInit(): void {
  }

  applyBtn : boolean = false

  filterFormData(use:any){
    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let leave_policy = this.stringifyCont(this.ouFilterForm.controls.leave_policy.value)
    let leave_type = this.stringifyCont(this.ouFilterForm.controls.leave_type.value)
    let period      =  this.carryForwardForm.controls.period.value

    if(use == 'filter'){
      return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&branch=${branch}&grade=${grade}&employee_list=${employee_list}&employment_status=${employment_status}&employee_type=${employee_type}&leave_policy=${leave_policy}&leave_type=${leave_type}&period=${period}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'employment_status' : this.ouFilterForm.controls.employment_status?.value, 'employee_type' : this.ouFilterForm.controls.employee_type.value, 'branch' : this.ouFilterForm.controls.branch.value,'period': this.carryForwardForm.controls.period.value,'employee_list':this.ouFilterForm.controls.employee_list.value,'leave_policy':this.ouFilterForm.controls.leave_policy.value,'leave_type':this.ouFilterForm.controls.leave_type.value})
    }
  }

  submitCarryForwardReport(){
    this.submitted          = true;
    if (this.carryForwardForm.invalid) {
      return;
    }
    let offset = 0
    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')
    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    })
    this.viewDetail  = false;
    this.applyBtn = false
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  // Resetting the filters
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.carryForwardForm.get('period')?.reset(true);
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('leave_policy')?.reset('');
      this.ouFilterForm.get('leave_type')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');

    }
    this.callReports.emit({
      reportName : 'Leave Carry Forward Report'
    })
  }

  // Tag Close
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'employment_status'){
      this.empstatusDD = [];
    }else if(this.closeControl == 'employee_type'){
      this.emptypeDD = [];
    }

    this.ouFilterForm.get(this.closeControl)?.setValue('');
    this.submitCarryForwardReport();
  }

  resetPage(val:any){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData(){
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'selected_date' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }
}
