<div class="side-panel side-pane-active" style="--sidepanel-width:57.625rem;"
  [class.side-pane-active]='unreportedExpense === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Add Unreported Expense </h5>
      <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 ">UNREPORTED EXPENSE DETAILS</p>
          </div>

          <app-c1-loader *ngIf="loader"></app-c1-loader>
          <ng-container *ngIf="!loader">
          <div class="col-12">
            <div class="card card-c2 p-16">
              <div class="card card-c2">
                <div class="table-responsive radius-4">
                  <table class="table td-white-space  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
                    <thead>
                      <tr>
                        <th scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)" [checked]="checkallSelected()">
                        </th>
                        <th class="fw-600 text-uppercase" scope="col">RECEIPT NUMBER</th>
                        <th class="fw-600 text-uppercase" scope="col">Expense date</th>
                        <th class="fw-600 text-uppercase" scope="col">category</th>
                        <th class="fw-600 text-uppercase" scope="col">Amount</th>
                        <th class="fw-600 text-uppercase" scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 ">

                      <ng-container *ngIf="unReportedReceiptList?.length == 0">
                        <tr>
                          <td colspan="6">
                            <div class="card card-c2 flex-center p-16 bg-dark-500">
                              <span class="fw-500 fs-14">No data found</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>

                      <ng-container *ngFor='let receipt of $any(unReportedReceiptList|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize);let i=index;'>
                        <tr [class]="receipt?.status == 'Expired' ? 'td-alert' : ''">
                          <td scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox"
                              value="" [(ngModel)]="receipt.checked"></td>
                          <td>{{receipt?.number}}</td>
                          <td><span class="text-nowrap">{{this.appservice.dateFormatDisplay(receipt?.expense_date)}}</span></td>
                          <td>{{receipt?.category?.name}}</td>
                          <td>{{receipt?.amount | currency : receipt?.currency}}</td>
                          <td><span class="badge {{receipt?.status == 'Unreported' ? 'badge-warning' : 'badge-danger1'}} py-1 px-3">{{receipt?.status}}</span></td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngIf="this.appservice.filteredcount(unReportedReceiptList,searchString)>pageSize">
            <ngb-pagination class="d-flex justify-content-end"
              [collectionSize]="this.appservice.filteredcount(unReportedReceiptList,searchString)" [(page)]="page" [maxSize]="3"
              [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
          </div>

          </ng-container>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="countSelected()==0" (click)="addReceipt()">
        Add
      </button>
    </footer>
  </div>
</div>
