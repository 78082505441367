import { Component, OnInit } from '@angular/core';
import { EmployeeDirectoryService } from 'src/app/@core/services/employee-directory.service';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit {

activeTab =1;
permissionlist:any = []
data :any;
loader            = false;
selecteddata :any;
filtered :any
permissions : any;

  constructor(public employeedirectoryService:EmployeeDirectoryService,public appService: AppService,) { }

  ngOnInit(): void {

    this.getPermission();
    this.permisionget()
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  permisionget(){
    this.loader = true;
    this.employeedirectoryService.directoryPermissionlist().subscribe((res:any)=>{
      if (res.length != 0) {
        this.data = res;
        this.filtered = this.data.filter((datas:any) => datas.section)
      }
      this.loader = false;
       
    })
  }
  
  selectdata(item:any,event:any){
    let visible = event.target.checked
    this.employeedirectoryService.directoryPermissionUpdate(item,{'visible':visible}).subscribe((res:any)=>{
      this.permisionget()
      this.loader = false;
  })
  }

  removeUnderscoreAddUppercase(item:any){
    var i, frags = item.split('_');
    // for (i=0; i<=0; i++) {
      frags[0] = frags[0].charAt(0).toUpperCase() + frags[0].slice(1);
    // }
    return frags.join(' ');
     
  
  }

}
