import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-my-team-location',
  templateUrl: './my-team-location.component.html',
  styleUrls: ['./my-team-location.component.scss']
})
export class MyTeamLocationComponent implements OnInit {

  constructor(
    public appservice: AppService,
  ) { }

  ngOnInit(): void {
  }

}
