
    <div class="container-fluid p-32 form-container" [formGroup]="formGp">
        <div class="row ">
            <p class="text-uppercase fw-500">PUNCH DETAILS</p>
        </div>
        <div class="row row-16">
            <div class="col-lg-6 form-row">
                <label for="" class="form-label ">Minimum hours to mark full day present </label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.min_hour_mark_fullday_present.touched) &&  f.min_hour_mark_fullday_present.errors}">

                <igx-time-picker formControlName="min_hour_mark_fullday_present" class="form-control "  placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [ngClass]="{ 'is-invalid': (submitted || f.min_hour_mark_fullday_present.touched) &&  f.min_hour_mark_fullday_present.errors}" class="form-control form-timepicker " (click)="picker1.open()" #picker1></igx-time-picker>

                <i *ngIf="f.min_hour_mark_fullday_present.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker1);picker1.close()"></i></div>

                <div *ngIf="(submitted || f.min_hour_mark_fullday_present.touched) && f.min_hour_mark_fullday_present.errors" class="invalid-feedback">
                    <div *ngIf="f.min_hour_mark_fullday_present.errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                </div>
            </div>
            <div class="col-lg-6 form-row">
                <label for="" class="form-label ">Minimum hours to mark half day present </label>
                <div class="form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.min_hour_mark_halfday_present.touched) &&  f.min_hour_mark_halfday_present.errors}">

                <igx-time-picker formControlName="min_hour_mark_halfday_present" class="form-control "  placeholder="{{this.messageService.descriptiontext('time')}}" [inputFormat]="'HH:mm'" [maxValue]="f.min_hour_mark_fullday_present.value" [ngClass]="{ 'is-invalid': (submitted || f.min_hour_mark_halfday_present.touched || f.min_hour_mark_halfday_present.dirty) &&  f.min_hour_mark_halfday_present.errors}"  class="form-control form-timepicker " (click)="picker6.open()" #picker6></igx-time-picker>

                <i *ngIf="f.min_hour_mark_halfday_present.value!=null" class="icon-close text-light-400"  (click)="clearTime(picker6);picker6.close()"></i></div>

                <div *ngIf="(submitted || f.min_hour_mark_halfday_present.touched || f.min_hour_mark_halfday_present.dirty) && f.min_hour_mark_halfday_present.errors" class="invalid-feedback">
                    <div *ngIf="f.min_hour_mark_halfday_present.errors.required">{{this.messageService.validationDisplay('required')}}
                    </div>
                    <div *ngIf="f.min_hour_mark_halfday_present.errors.maxValue">Minimum hours to mark half day present should not exceed hours required for full day present</div>
                </div>
            </div>
        </div>
    </div>
    <footer class="submit-footer">
        <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid "  (click)="validateForm()">NEXT</button>
        <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto" (click)="validateForm()">NEXT</button>
    </footer>
