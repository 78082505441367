import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators ,FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ChallanSettingsPfService } from 'src/app/@core/services/challan-settings-pf.service';
import { AppService } from 'src/app/app.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { EsichellansettingService } from 'src/app/@core/services/esi-chellan-setting.service';
import { EmployerESInumValidator } from 'src/app/@shared/validators/esinum.validators';
import { companyPfNumberValidator } from 'src/app/@shared/validators/companypf.validators';
@Component({
  selector: 'app-challan-settings-setup',
  templateUrl: './challan-settings-setup.component.html',
  styleUrls: ['./challan-settings-setup.component.scss']
})
export class ChallanSettingsSetupComponent implements OnInit {

  activeTab             = 1;
  loader                = false;
  employeeDropdown:any  = [];
  isEmpDropdown         = false;
  from                  = '';
  id                    = 0;
  actiontitle           = 'Add'
  editFlag              = false
  disabled               = false

  constructor(
    private formBuilder:FormBuilder,
    public router: Router,
    public route:ActivatedRoute,
    public challanSettingsPfService: ChallanSettingsPfService,
    public EsichellansettingService: EsichellansettingService,
    public appservice:AppService,
  ) { }

  ngOnInit(): void {
    this.getEmployeeList();
    this.route.params.subscribe((params: Params) => {
        if((params['pf']=='pf')){
          this.from =  'pf';
          this.challanForm['controls'].define.get('number')?.setValidators([Validators.required,companyPfNumberValidator()])
          if(!isNaN(params['id'])){
            this.id = +params['id'];
            this.editForm();
          }
        }else if((params['pf']=='esi')){
          this.from =  'esi';
          this.challanForm['controls'].define.get('number')?.setValidators([Validators.required,EmployerESInumValidator()])
          if(!isNaN(params['id'])){
            this.id = +params['id'];
            this.editForm();
          }
        }
        this.challanForm['controls'].define.get('number')?.updateValueAndValidity()
    });
  }

  challanForm: FormGroup = this.formBuilder.group({

    define                       : this.formBuilder.group({
      name                       : ['',[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      responsible_persone        : [null,[Validators.required]],
      group_owner                : [null,[Validators.required]],
      number                     : [''],
      address                    : ['',[Validators.required,Validators.maxLength(500)]],
    }),
    applicable                   : this.formBuilder.group({
      applicableto               : [null, [Validators.required]],
      assignees                  : [null, [Validators.required]]
    })

  });

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.challanForm['controls'].define.invalid) {
        changeEvent.preventDefault();
      }
    }
  }

  get defineForm() {
    return this.challanForm.get('define') as FormGroup;
  }

  get applicableForm() {
    return this.challanForm.get('applicable') as FormGroup;
  }


  editForm(){
    if(this.from == 'pf'){
      this.challanSettingsPfService.challanPfView(this.id).subscribe((res: any) => {
        this.setEditForm(res);
        this.editFlag = true
        this.actiontitle ="Edit";
      },(error)=>{
        this.router.navigate(['challan-settings']);
      });
    }else{
      this.EsichellansettingService.getSingleesigroupData(this.id).subscribe((res: any) => {
        this.setEditForm(res);
        this.editFlag = true
        this.actiontitle ="Edit";
      },(error)=>{
        this.router.navigate(['challan-settings']);
      });
    }

  }

  setEditForm(res:any){
    if(res.is_active == false){
      this.challanForm.disable();
      this.disabled = true
    }

    let assignees = [];
    let appkey    = "";
    appkey        = res?.applicability?.applicable;

   for(let i=0;i<res.applicability[appkey]?.length;i++){
    assignees.push(res.applicability[appkey][i].id);
   }
   let pfesinumber:any;
   if(this.from == 'pf'){
     pfesinumber = res.pf_number;
   }else{
    pfesinumber = res.esi_number;
   }
    this.challanForm.reset(
      {
        id: res.id,
        define: {
          name                   : res.name,
          responsible_persone    : res.responsible_persone.id,
          group_owner            : res.group_owner.id,
          number                 : pfesinumber,
          address                : res.address,
        },
        applicable: {
          applicableto          : appkey == 'Business Unit' ? 'BU' : appkey,
          assignees             : assignees
        }
    });

    this.loader = false;
  }

  getEmployeeList(){
    this.challanSettingsPfService.getempList().subscribe((res: any) => {
        if (res?.length > 0) {
          for(let i=0;i<res?.length;i++){
            res[i]['name']  = "";
            if(res[i]['first_name'] != null)
              res[i]['name'] += res[i]['first_name']+" ";
            if(res[i]['middle_name'] != null && res[i]['middle_name'] != "")
              res[i]['name'] += res[i]['middle_name']+" ";
            if(res[i]['last_name'] != null)
              res[i]['name'] += res[i]['last_name'];
              res[i]['name'] += " ("+res[i]['employee_code']+")";
          }
          this.employeeDropdown = res;
        }

      this.isEmpDropdown= true;
    });
  }

  submitMethod(){
    this.activeTab = 2;
  }

  res:any;
  submitForm(){

    this.res           = this.applicableForm.controls.assignees.value;
    var applicable     = this.applicableForm.controls.applicableto.value;
    var applicableStr  = applicable;

    if(applicable == "BU"){
      applicableStr = "businessunit";
    }

    var arr:any                      = {};
    arr['applicable']                = applicable == 'BU' ? 'Business Unit' : applicable;
    arr[applicableStr.toLowerCase()] = [];
    for(let key of this.res){
      arr[applicableStr.toLowerCase()].push(key);
    }

    var dataArray:any;
    if(this.from == 'pf'){
      dataArray = {
        "name"                : this.defineForm.controls.name.value,
        "responsible_persone" : this.defineForm.controls.responsible_persone.value,
        "group_owner"         : this.defineForm.controls.group_owner.value,
        "pf_number"           : this.defineForm.controls.number.value,
        "address"             : this.defineForm.controls.address.value,
        "applicability"       : arr
      }

      if(this.id == 0){
      this.challanSettingsPfService.challanPfSave(dataArray).subscribe((res: any) => {
        this.router.navigate(['challan-settings']);
      });
      }else{
        this.challanSettingsPfService.challanPfUpdate(dataArray,Number(this.id)).subscribe((res: any) => {
          this.router.navigate(['challan-settings']);
        });
      }
    }else{
      dataArray = {
        "name"                : this.defineForm.controls.name.value,
        "responsible_persone" : this.defineForm.controls.responsible_persone.value,
        "group_owner"         : this.defineForm.controls.group_owner.value,
        "esi_number"          : this.defineForm.controls.number.value,
        "address"             : this.defineForm.controls.address.value,
        "applicability"       : arr
      }

      if(this.id == 0){
      this.EsichellansettingService.createesigroup(dataArray).subscribe((res: any) => {
        this.router.navigate(['challan-settings']);
      });
      }else{
        this.EsichellansettingService.updateesigroup(Number(this.id),dataArray).subscribe((res: any) => {
          this.router.navigate(['challan-settings']);
        });
      }
    }

  }

}
