<ly-img-cropper [withClass]="classes.cropper" [config]="myConfig" [(scale)]="scale" (minScale)="minScale = $event"
  (ready)="ready = true" (cleaned)="ready = false" (cropped)="onCropped($event)" (loaded)="onLoaded($event)"
  (error)="onError($event)">

</ly-img-cropper>

<div *ngIf="ready" [className]="classes.sliderContainer">
  <ly-slider [thumbVisible]="false" [min]="minScale" [max]="1" [(ngModel)]="scale" (input)="onSliderInput($event)"
    [step]="0.000001"></ly-slider>
</div>

<div class="justify-content-center gap-16 pb-24" container *ngIf="ready" [lyJustifyContent]="'justify-center'"
  ly-dialog-actions>
  <button class="btn btn-outline-primary btn-sm text-uppercase" (click)="close.emit()">CANCEL</button>
  <button class="btn btn-primary  btn-sm text-uppercase" (click)="cropper.crop()">Save</button>
</div>
<!-- <div *ngIf="croppedImage">
    <div [class]="classes.cropperResult">
      <img [src]="croppedImage">
    </div>
  </div> -->