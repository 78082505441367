import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StatutoryService } from 'src/app/@core/services/statutory.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { EmployerESInumValidator } from 'src/app/@shared/validators/esinum.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { yearPatternValidator } from 'src/app/@shared/validators/year.validators';
import { PfNumberValidator } from 'src/app/@shared/validators/pfnumber.validators';
import { companyPfNumberValidator } from 'src/app/@shared/validators/companypf.validators';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { AmountCompareValidator } from 'src/app/@shared/validators/amountComparison.validators';
import { alphaNumericValidatorWithoutControl } from 'src/app/@shared/validators/alphanumeric.validators';

@Component({
  selector: 'app-company-statutory',
  templateUrl: './company-statutory.component.html',
  styleUrls: ['./company-statutory.component.scss'],
  providers: [CurrencyPipe]
})
export class CompanyStatutoryComponent implements OnInit {

  constructor(
    private formBuilder : FormBuilder,
    private statutoryService :StatutoryService,
    public appService:AppService,
    private cdRef: ChangeDetectorRef,
    public messageService : MessageService,
    private route : ActivatedRoute,
    private currency: CurrencyPipe

  ) { }

  showSidePanel1                    = false;
  taxView:any                       = [];
  showSidePanelpf                   = false;
  pfview:any                        = [];
  showSidePanelesi                  = false;
  esiview:any                       = [];
  showSidePanellwf                  = false;
  lwfview:any                       = [];
  showSidePanelgratuity             = false;
  gratuityView:any                  = [];
  showSidePanelpt                   = false;
  proftaxView:any                   = [];
  taxSettings                       = false;
  providentFund                     = false;
  employeeStateInsurance            = false;
  labourWelfareFund                 = false;
  gratuity                          = false;
  professionalTax                   = false;
  taxSubmitted                      = false;
  esisubmitted                      = false;
  monthDropdownPlaceHolder          = 'Select month'
  yearDropdownPlaceHolder           = 'Select year'
  listOfMonth:string                = "listOfMonth";
  seletedYearValidation             = false
  alertShow                         = 'show'
  permissions:any                   = [];
  loader1                           = false;
  disabled                          =   true;
  employee_contri_rate_pf:any       = [];
  employer_contri_rate_pf:any       = [];
  employee_contri_rate_esi:any      = [];
  employer_contri_rate_esi:any      = [];
  employer_contri_rate_gratuity:any = [];
  esiPopOver                        = '';
  dropdownFlag                      = false;
  loader                            = true;
  labourWelfareFundSubmitted        = false;
  noData                            = false;
  statutoryConfigId :number         = 0 ;
  currentYear                       = new Date().getFullYear()
  selectedYear :any;
  companyStatuatoryId : any;
  isActive:any;
  isUnlock:any;
  emptypeDD = [
    {id:"Contractual",    name:"Contractual"},
    {id:"Consultant",    name:"Consultant"},
    {id:"Permanent",    name:"Permanent"},
    {id:"Trainee",    name:"Trainee"},
    {id:"Wages",    name:"Wages"},
  ];

  selectSidebar(flag: string,lockStatus:any) {
    this.taxSettings            = false;
    this.providentFund          = false;
    this.employeeStateInsurance = false;
    this.labourWelfareFund      = false;
    this.gratuity               = false;
    this.professionalTax        = false;
    this.isUnlock               = lockStatus
    if ('taxSettings' == flag) {

      // call function to get tax settinns if it exist
      this.loader1 = true;
      this.getTaxSettingsData()
    }
    if ('providentFund' == flag) {

      this.loader1 = true;
      this.getProvidentFundSettingData()
    }
    if ('employeeStateInsurance' == flag) {
      this.employeeStateInsurance = true;
      this.loader1 = true;
      // call function to get esi settings if it exist
      this.getEmpStateInsuranceSettingsData()
    }
    if ('labourWelfareFund' == flag) {

      // call function to get lwf settings if it exist
      this.loader1 = true;
      this.getLWFData()
    }
    if ('gratuity' == flag) {

      this.loader1 = true;
      this.getGratuitySettingData()
    }
    if ('professionalTax' == flag) {

      // call function to get professional tax settinns if it exist
      this.loader1 = true;
      this.getProfessioanlTaxSettingsData()
    }
  }

  statutory: any[] = [
    {
      name: 'Tax Settings',
      status: false,
      text :`Employee tax details like tax exemptions, investment declarations and TDs are set up and
              specified before processing the payroll, ensuring a seamless and proper tax processing.`,
      id: 'taxSettings',
    },
    {
      name: 'Provident Fund',
      status: false,
      text :`A provident fund is an investment fund that is jointly contributed by the employer and
               employee to serve as a long term savings. The PF is calculated as 12% from the employee basic salary.`,
      id: 'providentFund',
    },
    {
      name: 'Employee State Insurance',
      status: false,
      text :`ESI contribution are calculated on the salary paid. Employees get 0.75% deducted from their respective gross
              salaries, whereas the employers make an ESI contribution of 3.25% of the employee's gross pay towards ESI.`,
      id: 'employeeStateInsurance',
    },
    {
      name: 'Labour Welfare Fund',
      status: false,
      text :`The rate of contribution from an employee is 0.2% of the salary or any remuneration subject to a limit of Rs.25/-.
              The rate of contribution from an employer is twice the amount contributed by such employee.`,
      id: 'labourWelfareFund',
    },
    {
      name: 'Gratuity',
      status: false,
      text :`Gratuity is a part of salary that is received by an employee from the employer for the services offered by the employee
              to the company. Configuring the Gratuity settings identifies the employee eligibility for gratuity.`,
      id: 'gratuity',
    },
    {
      name: 'Professional Tax',
      status: false,
      text :`A professional tax is calculated on the basis of your salary and predetermined slabs. `,
      id: 'professionalTax',
    },
  ];


   // ********************************************* StatuatoryConfiguration ***************************************



   yearChange(value:any){
     this.selectedYear = value
     this.seletedYearValidation = this.selectedYear == undefined ? true : false
     if(this.seletedYearValidation)
       return
     this.statutoryService.getStatuatoryConfiguration(this.selectedYear,this.companyStatuatoryId).subscribe((res : any)=>{
      if(res.body.length!=0){
        this.noData = false;
        res = res.body[0]
        this.statutory[0]['status'] = res.tax_settings
        this.statutory[1]['status'] = res.provident_fund
        this.statutory[2]['status'] = res.ESI
        this.statutory[3]['status'] = res.labour_welfare_fund
        this.statutory[4]['status'] = res.gratuity
        this.statutory[5]['status'] = res.professional_tax
        this.statutoryConfigId = res.id
        if(res){
          this.loader = false;
        }
      }
      else{
        this.noData = true;
        this.loader = false;

      }
     })

   }

   // ****************************************************************************************************************
   taxForm  = this.formBuilder.group({
     id                                 : 0,
     child_education                    : ['',[Validators.required, NumberpatternValidator(),Validators.maxLength(2)]],
     max_tuition_fees                   : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
     max_hostle_fees                    : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
    //  max_net_tax                        : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
     tax_rebate_87A                     : ['',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
     newregima_tax_rebate_87A           : ['',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
     special_standard_deduction         : ['',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
     newregima_standard_deduction       : ['',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
     superannuation_exempt_limit        : ['',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
     leave_encashment_exempt_limit      : ['',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
     max_pro_investment_declare_request : ['',[Validators.required, NumberpatternValidator(),Validators.maxLength(10)]],
     max_con_investment_declare_request : ['',[Validators.required, NumberpatternValidator(),Validators.maxLength(10)]],
     from_month_tax_process_investment  : ['',[Validators.required,]],
     from_month_pay_reimburse_per_bill  : [,[Validators.required]],
     HRA_exempt_calculation             : [true,[Validators.required]],
     actual_rent_paid_limit             : ['',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
     education_cess_charge              : ['',[Validators.required,digitdecimalValidator(),Validators.max(100)]],
     TDS_higher_slab                    : [false,[Validators.required]],
     deposit_income_tax_of_salary       : [true,[Validators.required]],
     show_income_tax_zero_employee      : [true,[Validators.required]],
     lta_block_period_start             : ['',[Validators.required,yearPatternValidator(),Validators.maxLength(4)]],
     lta_block_period_end               : ['',[Validators.required,yearPatternValidator(),Validators.maxLength(4)]],
     deduct_flat_rate_tax : [false,[Validators.required]],
     tax_employment_type :[[]],
     tax_rate :[null],
    },
    { validators: AmountCompareValidator.minMax('lta_block_period_start', 'lta_block_period_end') }
    );

   LabourWelfareFundForm  = this.formBuilder.group({
     id :0,
     apply_labourwelfare_fund : [true,[Validators.required]],
     process_LWF_settlement   : [true,[Validators.required]],
     contribute_in_ctc        : [false]
   });

  ngOnInit(): void {

    this.getPermission();
    this.route.params.subscribe((params: any) => {
      this.selectedYear         = params['year'];
      this.companyStatuatoryId  = params['id'];
      this.isActive             = params['isActive'];
    })
    this.getGeneralDropdown();
    // this.yearListCreate()
    this.yearChange(this.selectedYear);
    setTimeout(() => {
      this.esiPopOver =  `ESI deductions will be made only if the employee's monthly salary is less than or equal to `+this.currency.transform('21000','INR')+`. If the employee gets a salary revision which increases their monthly salary above `+this.currency.transform('21000','INR')+`, they would have to continue making ESI contributions till the end of the contribution period in which the salary was revised (April- September or October-March).`;

    }, 1000);
    this.cdRef.detectChanges();
  }


  getPermission(){
    this.permissions = this.appService.getPermissions('/statutory');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 2000);
    }
  }

  getGeneralDropdown(){
    this.statutoryService.getGeneralDropdownList('employee_pf_contribution',this.selectedYear).subscribe((res:any) =>{
      if(res)
        this.employee_contri_rate_pf = res.body;
    })
    this.statutoryService.getGeneralDropdownList('employer_pf_contribution',this.selectedYear).subscribe((res:any) =>{
      if(res)
       this.employer_contri_rate_pf = res.body
    })
    this.statutoryService.getGeneralDropdownList('employee_esi_contribution',this.selectedYear).subscribe((res:any) =>{
      if(res)
        this.employee_contri_rate_esi = res.body[0];
        this.EmpStateInsuranceForm.get('employee_contribution')?.setValue(this.employee_contri_rate_esi?.name);
    })
    this.statutoryService.getGeneralDropdownList('employer_esi_contribution',this.selectedYear).subscribe((res:any) =>{
      if(res)
       this.employer_contri_rate_esi = res.body[0];
        this.EmpStateInsuranceForm.get('employer_contribution')?.setValue(this.employer_contri_rate_esi?.name);
    })
    this.statutoryService.getGeneralDropdownList('employer_gratuity_contribution',this.selectedYear).subscribe((res:any) =>{
      if(res)
        this.employer_contri_rate_gratuity = res.body[0];
    })
  }

  get f() { return this.taxForm.controls }
  get esi() { return this.EmpStateInsuranceForm.controls }
  get LWF() { return this.LabourWelfareFundForm.controls }

  // get teh data if the exist for the seleted year
  getTaxSettingsData(){
    this.taxSettings=false;
    this.showSidePanel1=false;
    if(this.selectedYear == "" || this.selectedYear == undefined){
      this.seletedYearValidation = true
      window.scroll(0,0);
      this.taxSettings = false;
      return
    }else{
      this.seletedYearValidation = false
    }
    this.statutoryService.getTaxSettings(this.statutoryConfigId).subscribe((res: any) => {

      this.taxForm.enable()
      this.loader1 = false;
      //reset form if the tax setting not configured for selected year
      if(this.isUnlock==false){
        this.taxSettings = true;
        this.alertShow = 'show'
        if(res.body.length!=0){
          this.taxForm.reset();

          Object.entries(res.body[0]).forEach(([key, value]) => {
            this.taxForm.get(key)?.setValue(value);

          })
        }
        else{
          this.loader1 = true;
          this.statutoryService.getDefaultStatutoryData('tax_settings',this.selectedYear).subscribe((res:any) =>{

           Object.entries(res.body).forEach(([key, value]) => {
             this.taxForm.get(key)?.setValue(value);

           })
          this.loader1 = false;

          })

        }

      }else{

        this.taxSettings=false;
        this.showSidePanel1=true;
        this.taxView = res.body[0];
      }
     })
  }
  taxSubmitClicked      = false
  pfSubmitClicked       = false
  esiSubmitClicked      = false
  lwfSubmitClicked      = false
  gratuitySubmitClicked = false
  ptSubmitClicked       = false
  taxFormSubmit(){
    this.taxSubmitted     = true
    this.taxSubmitClicked = true
    if(this.selectedYear == "" || this.selectedYear == undefined){
      this.seletedYearValidation = true
      window.scroll(0,0);
    }

    if(!this.taxForm.valid){
      this.taxSubmitClicked =false
      return
    }

    this.taxForm.get('max_tuition_fees')?.setErrors(null);
    this.taxForm.get('max_hostle_fees')?.setErrors(null);
    // this.taxForm.get('max_net_tax')?.setErrors(null);
    this.taxForm.get('tax_rebate_87A')?.setErrors(null);
    this.taxForm.get('newregima_tax_rebate_87A')?.setErrors(null);
    this.taxForm.get('special_standard_deduction')?.setErrors(null);
    this.taxForm.get('newregima_standard_deduction')?.setErrors(null);
    this.taxForm.get('superannuation_exempt_limit')?.setErrors(null);
    this.taxForm.get('leave_encashment_exempt_limit')?.setErrors(null);

    this.taxForm.get('max_pro_investment_declare_request')?.setErrors(null);
    this.taxForm.get('max_con_investment_declare_request')?.setErrors(null);

    this.taxForm.get('education_cess_charge')?.setErrors(null);
    this.taxForm.addControl('statuatory', new FormControl(this.statutoryConfigId, Validators.required));
    this.statutoryService.createTaxSettings(this.taxForm.value.id, this.taxForm.value).subscribe((res: any) => {

    this.statutory[0]['status'] = true
    this.taxSettings = false
    this.taxSubmitted = false
    this.taxSubmitClicked =false
    })
  }

  // *********************************************  GRATUITY   ***************************************
  gratuity_month:string = "gratuity_month";
  isrequired            = true
  gratuity_year:string = "gratuity_year";

  gratuity_formSubmitted = false
  gratuity_form = this.formBuilder.group({

    id                          : 0,
    apply_gratuity              : [true,[Validators.required]],
    tax_free_gratuity_limit     : ['', [Validators.required, digitdecimalValidator(), Validators.maxLength(10)]],
    calculate_gratuity_year     : [, [Validators.required]],
    calculate_gratuity_month    : [, [Validators.required]],
    employer_contribution       : ['', Validators.required],
    employer_contribution_rate  : [''],
    contribute_in_ctc           : [false],

  });

  get g_f() { return this.gratuity_form.controls }

  gratuity_formSubmit(){
    this.gratuity_formSubmitted = true
    this.gratuitySubmitClicked = true
    if(!this.gratuity_form.value.apply_gratuity){
      this.gratuity_form.patchValue({
        tax_free_gratuity_limit : 0.0,
        calculate_gratuity_month : 0,
        calculate_gratuity_year : 0
      })
    }

    this.gratuity_form.patchValue({
      year : this.selectedYear
    })

    if(!this.gratuity_form.valid){
      this.gratuitySubmitClicked = false
      return
    }

    if(this.gratuity_form.value.apply_gratuity && String(this.gratuity_form.value.tax_free_gratuity_limit).includes('.')){
      this.gratuity_form.patchValue({
        tax_free_gratuity_limit : parseFloat(this.gratuity_form.value.tax_free_gratuity_limit).toFixed(2),
      })
    }
    // hide max lenth error after convert to float
    this.gratuity_form.get('tax_free_gratuity_limit')?.setErrors(null);
    let gratuityFormArray = {
      id                          : 0,
      apply_gratuity              : this.gratuity_form.value.apply_gratuity,
      tax_free_gratuity_limit     : this.gratuity_form.value.tax_free_gratuity_limit,
      calculate_gratuity_year     : this.gratuity_form.value.calculate_gratuity_year,
      calculate_gratuity_month    : this.gratuity_form.value.calculate_gratuity_month,
      employer_contribution_rate  : this.employer_contri_rate_gratuity.id,
      contribute_in_ctc           : this.gratuity_form.value.contribute_in_ctc,
      statuatory                  : this.statutoryConfigId
    }

    // create or update API
    this.statutoryService.createGratuitySetting(this.gratuity_form.value.id,gratuityFormArray).subscribe((res: any) => {

      this.statutory[4]['status'] = true
      this.gratuity               = false
      this.gratuity_formSubmitted = false
      this.gratuitySubmitClicked  = false
    })
  }


  getGratuitySettingData(){
    this.gratuity=false;
    this.showSidePanelgratuity=false;
    if(this.selectedYear == "" || this.selectedYear == undefined){
      this.seletedYearValidation = true
      window.scroll(0,0);
      this.gratuity = false;
      return
    }else{
      this.seletedYearValidation = false
    }

    this.statutoryService.getGratuitySetting(this.statutoryConfigId).subscribe((res: any) => {
      this.gratuity_form.enable()
      this.loader1 = false;
      if(this.isUnlock==false){
        this.gratuity=true;
        this.alertShow = 'show'
        if(res.body.length!=0){

          Object.entries(res.body[0]).forEach(([key, value]) => {
           if(key=='employer_contribution_rate_name'){
              this.gratuity_form.get('employer_contribution')?.setValue(value);
            }
            else
            this.gratuity_form.get(key)?.setValue(value);
          })
        }
        else{
          this.loader1 = true;

          this.statutoryService.getDefaultStatutoryData('gratuity',this.selectedYear).subscribe((res:any) =>{

            Object.entries(res.body).forEach(([key, value]) => {
              if(key=='employer_contribution_rate'){
                this.gratuity_form.get('employer_contribution')?.setValue(value);
              }
              else
              this.gratuity_form.get(key)?.setValue(Number(value));

            })
          this.loader1 = false;

           })

        }
        this.gratuity_form.controls['employer_contribution'].disable();
      }else{

        this.gratuity=false;
        this.showSidePanelgratuity=true;
        this.gratuityView = res.body[0];
      }
    })
  }
  // *************************************************************************************************
  // *********************************************  PROFESSIONAL TAX SETTINGS ***************************************

  prof_tax_formSubmitted = false
  prof_tax_form = this.formBuilder.group({
    id                          : 0,
    apply_pt                    : [true,[Validators.required]],
    mention_certificate_number  : ['', [Validators.required, alphaNumericValidatorWithoutControl(), Validators.maxLength(25)]],
    mention_circle_number       : ['', [alphaNumericValidatorWithoutControl(), Validators.maxLength(25)]],
  });

  get p_tax_f() { return this.prof_tax_form.controls }

  prof_tax_formSubmit(){
    this.prof_tax_formSubmitted = true
    this.ptSubmitClicked        = true
    this.yearChange(this.selectedYear)

    if(!this.prof_tax_form.value.apply_pt){
      this.prof_tax_form.get('mention_certificate_number')?.setErrors(null);
      this.prof_tax_form.get('mention_circle_number')?.setErrors(null);
    }

    if(!this.prof_tax_form.valid){
      this.ptSubmitClicked = false
      return
    }
    let pfTaxFormArray = {
      id                          : 0,
      apply_pt                    : this.prof_tax_form.value.apply_pt,
      mention_certificate_number  : this.prof_tax_form.value.mention_certificate_number,
      mention_circle_number       : this.prof_tax_form.value.mention_circle_number,
      statuatory                  : this.statutoryConfigId,
    }
    // create or update API
    this.statutoryService.createProfessionalTaxSettings(this.prof_tax_form.value.id, pfTaxFormArray).subscribe((res: any) => {

      this.statutory[5]['status'] = true
      this.professionalTax        = false
      this.prof_tax_formSubmitted = false
      this.ptSubmitClicked        = false
    })
  }

  getProfessioanlTaxSettingsData(){
    this.professionalTax = false;
    this.showSidePanelpt=false;
    if(this.selectedYear == "" || this.selectedYear == undefined){
      this.seletedYearValidation = true
      window.scroll(0,0);
      this.professionalTax = false;
      return
    }else{
      this.seletedYearValidation = false
    }

    this.statutoryService.getProfessionalSettings(this.statutoryConfigId).subscribe((res: any) => {
      this.prof_tax_form.enable()
      this.loader1 = false;
      if(this.isUnlock==false){
        this.professionalTax = true;
        this.alertShow = 'show'
        if(res.body.length!=0){

          Object.entries(res.body[0]).forEach(([key, value]) => {
            this.prof_tax_form.get(key)?.setValue(value);
          })
        }
        else{
           this.prof_tax_form.reset({
              id                         : 0,
              apply_pt                   : true,
              mention_certificate_number : '',
              mention_circle_number      : '',
          })
        }
      }else{

        this.professionalTax  = false;
        this.showSidePanelpt  =true;
        this.proftaxView      = res.body[0];
      }
    })
  }
  // ****************************************************************************************************************
  EmpStateInsuranceForm  = this.formBuilder.group({
    id :0,
    apply_ESI                     : [true,[Validators.required]],
    company_ESI_number            : [''],
    wageceilling_ESI_contribution : ['21000',[Validators.required, digitdecimalValidator(),Validators.maxLength(10)]],
    employee_contribution         : ['', Validators.required],
    employer_contribution         : ['', Validators.required],
    employee_contribution_rate    : [''],
    employer_contribution_rate    :  [''],
    contribute_in_ctc             : [false],
    employer_roundoff             : ['Ceil'],
    employee_roundoff             : ['Ceil'],

  });

// get the esi data if the exist for the seleted year
getEmpStateInsuranceSettingsData(){
  this.employeeStateInsurance=false;
  this.showSidePanelesi=false;
  if(this.selectedYear == "" || this.selectedYear == undefined){
    this.seletedYearValidation = true
    this.employeeStateInsurance = false;
    window.scroll(0,0);
    return
  }else{
    this.seletedYearValidation = false
  }

  this.statutoryService.getEmpStateInsuranceSettings(this.statutoryConfigId).subscribe((res: any) => {
    this.EmpStateInsuranceForm.enable()
    this.loader1 = false;
     //reset form if the esi setting not configured for selected year
     if(this.isUnlock==false){
        this.employeeStateInsurance=true;
        this.alertShow = 'show'

      if(res.body.length!=0){

        Object.entries(res.body[0]).forEach(([key, value]) => {
          // if(key=='employee_contribution_rate_name'){
          //   this.EmpStateInsuranceForm.get('employee_contribution')?.setValue(value);
          // }
          // else if(key=='employer_contribution_rate_name'){
          //   this.EmpStateInsuranceForm.get('employer_contribution')?.setValue(value);
          // }
          // else
          if(key!='employee_contribution_rate_name' && key!='employer_contribution_rate_name')
          this.EmpStateInsuranceForm.get(key)?.setValue(value);
        })
      }
      else{

        this.statutoryService.getDefaultStatutoryData('esi',this.selectedYear).subscribe((res:any) =>{
          this.loader1 = true;

          Object.entries(res.body).forEach(([key, value]) => {
            // if(key=='employee_contribution_rate'){
            //   this.EmpStateInsuranceForm.get('employee_contribution')?.setValue(value);
            // }
            // else if(key=='employer_contribution_rate'){
            //   this.EmpStateInsuranceForm.get('employer_contribution')?.setValue(value);
            // }
            // else
            if(key!='employee_contribution_rate_name' && key!='employer_contribution_rate_name')
              this.EmpStateInsuranceForm.get(key)?.setValue(value);

          })
          this.loader1 = false;

         })

      }
      this.EmpStateInsuranceForm.controls['employee_contribution'].disable();
      this.EmpStateInsuranceForm.controls['employer_contribution'].disable();
    }else{
      // this.alertShow = ''
      //show values if the esi setting configured for selected year
      this.employeeStateInsurance=false;
      this.showSidePanelesi=true;
      res.body[0]['employee_contribution'] = this.employee_contri_rate_esi.name;
      res.body[0]['employer_contribution'] = this.employer_contri_rate_esi.name;
      this.esiview = res.body[0];

    }
  })
}
  EmpStateInsuranceFormSubmit(){
    this.esisubmitted = true
    this.esiSubmitClicked = true

    //this.EmpStateInsuranceForm.value.year = this.selectedYear

    if(this.selectedYear == "" || this.selectedYear == undefined){
      this.seletedYearValidation = true

    }

    if(!this.EmpStateInsuranceForm.value.apply_ESI){
      this.EmpStateInsuranceForm.get('company_ESI_number')?.setErrors(null);
      this.EmpStateInsuranceForm.patchValue({
        wageceilling_ESI_contribution : 0.0
      })
    }

    if(!this.EmpStateInsuranceForm.valid){
      this.esiSubmitClicked = false
      return
    }

    if(this.EmpStateInsuranceForm.value.apply_ESI && String(this.EmpStateInsuranceForm.value.wageceilling_ESI_contribution).includes('.')){
      this.EmpStateInsuranceForm.patchValue({
        wageceilling_ESI_contribution : parseFloat(this.EmpStateInsuranceForm.value.wageceilling_ESI_contribution).toFixed(2)
      })
    }
    // hide max lenth error after convert to float
    this.esi.wageceilling_ESI_contribution?.setErrors(null);
   let esiFormArray = {
    apply_ESI                           : this.EmpStateInsuranceForm.value.apply_ESI,
    company_ESI_number                  : this.EmpStateInsuranceForm.value.company_ESI_number,
    wageceilling_ESI_contribution       : this.EmpStateInsuranceForm.value.wageceilling_ESI_contribution,
    employee_contribution_rate          : this.employee_contri_rate_esi.id,
    employer_contribution_rate          : this.employer_contri_rate_esi.id,
    contribute_in_ctc                   : this.EmpStateInsuranceForm.value.contribute_in_ctc,
    statuatory                          : this.statutoryConfigId,
    employer_roundoff                   : this.EmpStateInsuranceForm.value.employer_roundoff,
    employee_roundoff                   : this.EmpStateInsuranceForm.value.employee_roundoff,

   }

    this.statutoryService.createEmpStateInsuranceSettings(this.EmpStateInsuranceForm.value.id, esiFormArray).subscribe((res: any) => {

      this.statutory[2]['status'] = true
      this.employeeStateInsurance = false
      this.esisubmitted = false
      this.esiSubmitClicked = false

    })
  }
  // get the LWF data if the exist for the seleted year
getLWFData(){
  this.labourWelfareFund=false;
  this.showSidePanellwf=false;
  if(this.selectedYear == "" || this.selectedYear == undefined){
    this.seletedYearValidation = true
    this.labourWelfareFund = false;
    return
  }else{
    this.seletedYearValidation = false
  }

  this.statutoryService.getLabourWelfareFund(this.statutoryConfigId).subscribe((res: any) => {
    this.LabourWelfareFundForm.enable()
    this.loader1 = false;
    this.LabourWelfareFundForm.reset({
      id                        : res.id,
      apply_labourwelfare_fund  : res.apply_labourwelfare_fund,
      process_LWF_settlement    : res.process_LWF_settlement,
      // prorate_LWF_perdays       : res.prorate_LWF_perdays,
      contribute_in_ctc         : res.contribute_in_ctc
    });
       //reset form if the esi setting not configured for selected year
       if(this.isUnlock==false){
        this.labourWelfareFund=true;
        this.labourWelfareFundSubmitted = false
        this.alertShow = 'show'

        if(res.body.length!=0){

          Object.entries(res.body[0]).forEach(([key, value]) => {
            this.LabourWelfareFundForm.get(key)?.setValue(value);
          })
        }
        else{
            this.LabourWelfareFundForm.reset({
              id                        :0,
              apply_labourwelfare_fund  : true,
              process_LWF_settlement    : true,
              // prorate_LWF_perdays       : true,
              contribute_in_ctc         : false
        })
        }
      }else{

        this.labourWelfareFund=false;
        this.showSidePanellwf=true;
        this.lwfview = res.body[0];
      }
  })
}
LabourWelfareFundFormSubmit(){
    this.labourWelfareFundSubmitted = true
    this.lwfSubmitClicked = true

    this.LabourWelfareFundForm.value.year = this.selectedYear

    if(this.selectedYear == "" || this.selectedYear == undefined){
      this.seletedYearValidation = true

    }
    if(!this.LabourWelfareFundForm.value.apply_labourwelfare_fund){
      this.LabourWelfareFundForm.get('process_LWF_settlement')?.setErrors(null);
      // this.LabourWelfareFundForm.get('prorate_LWF_perdays')?.setErrors(null);
    }

    if(!this.LabourWelfareFundForm.valid){
      this.lwfSubmitClicked = false
      return
    }
    let lwFormArray = {
     id                       : 0,
     apply_labourwelfare_fund : this.LabourWelfareFundForm.value.apply_labourwelfare_fund,
     process_LWF_settlement   : this.LabourWelfareFundForm.value.process_LWF_settlement,
    //  prorate_LWF_perdays      : this.LabourWelfareFundForm.value.prorate_LWF_perdays,
     contribute_in_ctc        : this.LabourWelfareFundForm.value.contribute_in_ctc,
     statuatory               : this.statutoryConfigId
    }

    this.statutoryService.createLabourWelfareFund(this.LabourWelfareFundForm.value.id, lwFormArray).subscribe((res: any) => {

      this.statutory[3]['status'] = true
      this.labourWelfareFund = false
      this.lwfSubmitClicked = false
      this.labourWelfareFundSubmitted = false
    })
  }

    // *********************************************   PROVIDENT FUND ***************************************
    pf_formSubmitted = false

    pf_form = this.formBuilder.group({
      id:0,
      statuatory                    : this.statutoryConfigId,
      apply_PF                      : [true,[Validators.required]],
      company_PF_number             : [''],
      deposit_PF_from_salary        : [true,[Validators.required]],
      show_employee_PF_registration : [true,[Validators.required]],
      show_employee_PF_challan_zero : [true,[Validators.required]],
      employee_contribution_rate    : new FormControl('',Validators.required),
      employer_contribution_rate    : ['',[Validators.required]],
      contribute_in_ctc             : [false],
      include_admin_charge          : [false],
      prorate_employer_pf           : [false],
      prorate_employee_pf           : [false],
      epf_roundoff : ['Nearest',[Validators.required]],
      eps_roundoff : ['Nearest',[Validators.required]],
      edli_roundoff : ['None',[Validators.required]],
      admin_roundoff : ['None',[Validators.required]],
    });
    get pf_f() { return this.pf_form.controls }

    pf_formSubmit(){
      this.pf_formSubmitted = true
      this.pfSubmitClicked = true

      if(!this.pf_form.value.apply_PF){
        this.pf_form.get('company_PF_number')?.setErrors(null);
        this.pf_form.get('employee_contribution_rate')?.setErrors(null);
        this.pf_form.get('employer_contribution_rate')?.setErrors(null);

      }


      const invalid = [];
      const controls = this.pf_form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
      }



      if(!this.pf_form.valid){
        this.pfSubmitClicked = false
        return
      }
let pfFormArray = {
      apply_PF                      : this.pf_form.value.apply_PF,
      company_PF_number             : this.pf_form.value.company_PF_number,
      deposit_PF_from_salary        : this.pf_form.value.deposit_PF_from_salary,
      show_employee_PF_registration : this.pf_form.value.show_employee_PF_registration,
      show_employee_PF_challan_zero : this.pf_form.value.show_employee_PF_challan_zero,
      employee_contribution_rate    : this.pf_form.value.employee_contribution_rate,
      employer_contribution_rate    : this.pf_form.value.employer_contribution_rate,
      contribute_in_ctc             : this.pf_form.value.contribute_in_ctc,
      include_admin_charge          : this.pf_form.value.include_admin_charge,
      prorate_employer_pf           : this.pf_form.value.prorate_employer_pf,
      prorate_employee_pf           : this.pf_form.value.prorate_employee_pf,
      epf_roundoff                  : this.pf_form.value.epf_roundoff,
      eps_roundoff                  : this.pf_form.value.eps_roundoff,
      edli_roundoff                 : this.pf_form.value.edli_roundoff,
      admin_roundoff                : this.pf_form.value.admin_roundoff,
      statuatory                    : this.statutoryConfigId
}
      // create or update API
      this.statutoryService.createProvidentFundSetting(this.pf_form.value.id, pfFormArray).subscribe((res: any) => {

        this.statutory[1]['status'] = true
        this.providentFund = false
        this.pf_formSubmitted = false
        this.pfSubmitClicked = false

      })
    }
    prorateSettings(){
      if(this.pf_form.value.prorate_employer_pf==false){
        this.pf_form.get('prorate_employee_pf')?.setValue(false)
      }
    }
    closeSidePanel(formName: any,flag: any=''){
      if(formName == 'pf_form'){
          this.providentFund = flag;
          this.pf_formSubmitted = false;
          this.pf_form.reset();
      }
    }
    getProvidentFundSettingData(){
      this.providentFund=false;
      this.showSidePanelpf=false;
      if(this.selectedYear == "" || this.selectedYear == undefined){
        this.seletedYearValidation = true
        window.scroll(0,0);
        this.providentFund = false;
        return
      }else{
        this.seletedYearValidation = false
      }

      this.statutoryService.getProvidentFundSetting(this.statutoryConfigId).subscribe((res: any) => {
        this.pf_form.enable()
        this.loader1 = false;
        if(this.isUnlock==false){
          this.providentFund=true;
          this.alertShow = 'show'
          this.pf_form.reset();

          if(res.body.length!=0){

            Object.entries(res.body[0]).forEach(([key, value]) => {
              this.pf_form.get(key)?.setValue(value);
            })
          }
          else{
            this.pf_form.reset
            ({
              id                            : 0,
              apply_PF                      : true,
              company_PF_number             : '',
              deposit_PF_from_salary        : true,
              show_employee_PF_registration : true,
              show_employee_PF_challan_zero : true,
              employee_contribution_rate    : null,
              employer_contribution_rate    : null,
              contribute_in_ctc             : false,
              include_admin_charge          : false,
              prorate_employer_pf           : false,
              prorate_employee_pf           : false,
              epf_roundoff : 'Nearest',
              eps_roundoff : 'Nearest',
              edli_roundoff : 'None',
              admin_roundoff : 'None',
            })
          }
        }else{

          this.providentFund=false;
          this.showSidePanelpf=true;
          this.pfview = res.body[0];
        }
      })
    }

    employerContribution(){

      if(this.pf_form.value.employer_contribution_rate == 13){
        this.pf_form.patchValue({
          employee_contribution_rate: 7,
        })
        this.dropdownFlag = true;
      }
      else{
        this.dropdownFlag = false;
      }
    }
    // *******************************************************************************************************/
    setConditionalValue(form:any){
      if(form=='pf_form'){
        this.pf_form.get('company_PF_number')?.setValue('');
        this.pf_form.get('deposit_PF_from_salary')?.setValue(true);
        this.pf_form.get('show_employee_PF_registration')?.setValue(true);
        this.pf_form.get('show_employee_PF_challan_zero')?.setValue(true);
        this.pf_form.get('employee_contribution_rate')?.setValue(null);
        this.pf_form.get('employer_contribution_rate')?.setValue(null);
        this.pf_form.get('contribute_in_ctc')?.setValue(false);
        this.pf_form.get('include_admin_charge')?.setValue(false);
        this.pf_form.get('prorate_employer_pf')?.setValue(false);
        this.pf_form.get('prorate_employee_pf')?.setValue(false);
        this.pf_form.get('epf_roundoff')?.setValue('Nearest');
        this.pf_form.get('eps_roundoff')?.setValue('Nearest');
        this.pf_form.get('edli_roundoff')?.setValue('None');
        this.pf_form.get('admin_roundoff')?.setValue('None');
        this.dropdownFlag = false;
      }
      else if(form=='esi'){
        this.EmpStateInsuranceForm.get('company_ESI_number')?.setValue('');
        this.EmpStateInsuranceForm.get('wageceilling_ESI_contribution')?.setValue('');
        this.EmpStateInsuranceForm.get('employee_contribution_rate')?.setValue('');
        this.EmpStateInsuranceForm.get('employer_contribution_rate')?.setValue('');
        this.EmpStateInsuranceForm.get('contribute_in_ctc')?.setValue(false);
        this.EmpStateInsuranceForm.get('employer_roundoff')?.setValue('Ceil');
        this.EmpStateInsuranceForm.get('employee_roundoff')?.setValue('Ceil');
      }
      else if(form=='gratuity'){
        this.gratuity_form.get('tax_free_gratuity_limit')?.setValue('');
        this.gratuity_form.get('calculate_gratuity_year')?.setValue(4);
        this.gratuity_form.get('calculate_gratuity_month')?.setValue(11);
        this.gratuity_form.get('employer_contribution_rate')?.setValue('');
        this.gratuity_form.get('contribute_in_ctc')?.setValue(false);

      }
      else if(form=='tax'){
        this.prof_tax_form.get('mention_certificate_number')?.setValue('');
        this.prof_tax_form.get('mention_circle_number')?.setValue('');

      }
      else if(form=='tax_settings'){
        this.taxForm.get('specify_days')?.setValue(60);

      }
      else if(form=='labour_welfare'){
        this.LabourWelfareFundForm.get('process_LWF_settlement')?.setValue(true);
        // this.LabourWelfareFundForm.get('prorate_LWF_perdays')?.setValue(true);
      }

    }

    // LTA block period auto calculation
    blockPeriodCalculation(control:any){
      if(control == 'lta_block_period_start'){
        let lta_block_period_start = this.taxForm.get('lta_block_period_start')?.value;
        let startYear: any = Number(lta_block_period_start) + 3;
        this.taxForm.get('lta_block_period_end')?.setValue(Number(startYear))
      }else{
        let lta_block_period_end = this.taxForm.get('lta_block_period_end')?.value;
        let endYear:any = Number(lta_block_period_end) - 3;
        this.taxForm.get('lta_block_period_start')?.setValue(Number(endYear))
      }
    }
    clearempType(index:number){
      let daata = this.taxForm.value.tax_employment_type;
      daata.splice(index, 1);
      this.taxForm.patchValue({
        tax_employment_type: daata
      });
    }

    setflatTax(val:boolean){
      if(val==true){
        this.taxForm.get('tax_employment_type')?.setValue(['Contractual','Consultant']);
        this.taxForm.get('tax_rate')?.setValue(10);
        this.taxForm.get('tax_rate')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(2)])
        this.taxForm.get('tax_employment_type')?.setValidators([Validators.required])
        this.taxForm.get('tax_rate')?.updateValueAndValidity()
        this.taxForm.get('tax_employment_type')?.updateValueAndValidity()
      } else {
        this.taxForm.get('tax_employment_type')?.setValue([]);
        this.taxForm.get('tax_rate')?.setValue(null);
        this.taxForm.get('tax_rate')?.clearValidators()
        this.taxForm.get('tax_employment_type')?.clearValidators()
        this.taxForm.get('tax_rate')?.updateValueAndValidity()
        this.taxForm.get('tax_employment_type')?.updateValueAndValidity()
      }
    }
}

