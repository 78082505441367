import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { LeaveBalanceHRService } from 'src/app/@core/services/leave-balance-hr.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-leave-balance-view',
  templateUrl: './leave-balance-view.component.html',
  styleUrls: ['./leave-balance-view.component.scss']
})

export class LeaveBalanceViewComponent implements OnInit {
  compensatoryLeave = false;
  activeTab = 0;
  editLeave: boolean = false;
  emp_ID: any
  empData: any
  leaveID: any
  leaveData: any = []
  leaveTypeData: any = []
  tableData: any = []
  filterData: any = []
  viewData: any = []
  editData: any = []
  filterResult: any = ''
  empLoader: boolean = false
  tableLoader: boolean = false
  viewLoader: boolean = false
  noDataFullScreen: boolean = false
  alertDiv: boolean = false
  noDataTable: boolean = false
  viewModal: boolean = false
  viewpanel: boolean = false
  submitted: boolean = false
  searchString: any = ''
  permissions: any
  compOffDropdown : any = []

  alertMsg:any = '';

  // ngselect
  year: any = new Date().getFullYear()
  currentYear: any = new Date().getFullYear()
  yearRange: any

  // Page
  page = 1
  pageSize = 20

  @Input() from: any;

  constructor(
    private route: ActivatedRoute,
    private leaveServ: LeaveBalanceHRService,
    public appService: AppService,
    public msgServ: MessageService,
    private fb: FormBuilder,
    public notificationService: NotificationService,
    public router: Router,
    private investmentService: InvestmentDeclerationService,
    private cd : ChangeDetectorRef
  ) { }

  // Form
  editForm        !: FormGroup;

  ngOnInit(): void {
    // Taking Employee ID
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['empId'])) {
        this.emp_ID = +params['empId'];
        this.mainAPIData()
      } else if (!isNaN(params['myTeamEmpId'])) {
        this.emp_ID = +params['myTeamEmpId'];
        this.mainAPIData()
      }
    })
    // Permission
    this.getPermission()
    // Year Range
    this.yearRange = this.generateArrayOfYears()
    // Form Group for EDIT
    this.editForm = this.fb.group({
      employee: this.emp_ID,
      count: ['', [Validators.required, Validators.min(0.01), Validators.max(200), digitdecimalValidator()]],
      transaction: [true, [Validators.required]],
      remarks: [null,[Validators.maxLength(100)]],
      comp_off_add : [null],
      comp_off_deduct : [null],
      leave_type : [true]
    })

    this.editForm.get('transaction')?.valueChanges.subscribe(value => {
      this.setValidator();
    });
  }

  // Form
  get f() { return this.editForm.controls; }

  // Year Change
  changeYear(val:any){
    this.leaveTypeData = []
    this.activeTab = 0
    this.searchString = ''
    this.mainAPIData()
    // this.getDataWithLeaveID(this.leaveID)
  }

  /**
 * Returns array of previous years
 * @returns sum of a and b
 */
  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 3
    var years = []

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  // Main API
  mainAPIData() {
    this.empLoader = this.tableLoader = true
    this.leaveServ.getEditHistoryView(this.emp_ID, this.year,this.from).subscribe((res: any) => {
      this.leaveData = res?.body?.data
      this.filterData = res?.body?.status_list
      this.empData = res?.body?.employee_data
      this.leaveTypeData = res?.body?.leavetypedata
      this.empLoader = false
      let yearList = res?.body?.year_list
      if (this.leaveData.length !== 0) {
        this.noDataFullScreen = false
        this.alertDiv = false
        this.leaveID = this.leaveData[0]?.leavetype_id
        this.getDataWithLeaveID(this.leaveID)
      }
      else if(this.leaveData?.length == 0 &&  res?.body?.attend_remove_compoff && yearList.includes(this.year)){
        this.noDataFullScreen = true;
        this.alertDiv = true;
        this.alertMsg = 'There are no leave types to display as Comp Off leave, included in your leave policy, is not enabled in the attendance settings.';
      }
      else {
        this.noDataFullScreen = true
        this.alertDiv = true
        this.alertMsg = 'The leave policy has not been assigned for the selected employee for current leave cycle.';
      }
    })
  }

  extractAndConvertDate(reason:any) {
    let dateRegex = /(\d{4}-\d{2}-\d{2})/g;
    let matches = reason?.match(dateRegex);
    if (matches && matches.length > 0) {
        for (let i = 0; i < matches.length; i++) {
            let extractedDate = matches[i];
            let convertedDate = this.appService.dateFormatDisplay(extractedDate)
            reason = reason.replace(extractedDate, convertedDate);
        }
        return reason;
    } else {
      if(this.isEmptyOrWhitespace(reason)){
        reason = null
      }
        return reason;
    }
  }

  isEmptyOrWhitespace(input:any) {
    return /^\s*$/.test(input);
  }
  yearList : any = []
  // Data with leave ID
  getDataWithLeaveID(id: any) {
    this.tableLoader = true
    id == undefined ? id = '' : id
    this.leaveServ.getDataWithLeaveID(this.emp_ID, id, this.year, this.filterResult,this.from).subscribe((res: any) => {
      this.filterData = res?.body?.status_list
      this.yearList = res?.body?.year_list
      this.minDate = this.checkValidComp()?.start
      this.maxDate = this.checkValidComp()?.end
      if (res.body.data.length != 0) {
        for (let i = 0; i < res.body.data[0].leavehistory.length; i++) {
          res.body.data[0].leavehistory[i]['transaction_date'] = res?.body?.data[0].leavehistory[i]['date']
          res.body.data[0].leavehistory[i]['transaction_type'] = res?.body?.data[0].leavehistory[i]['type']
          res.body.data[0].leavehistory[i]['remark'] = this.extractAndConvertDate(res.body.data[0].leavehistory[i]['reason'])
          if (res.body.data[0].leavehistory[i]['added_used'].includes('+')) {
            res.body.data[0].leavehistory[i]['transaction_mode'] = true
          } else {
            res.body.data[0].leavehistory[i]['transaction_mode'] = false
          }
          res.body.data[0].leavehistory[i]['leave_count'] = res.body.data[0].leavehistory[i]['added_used']
          res.body.data[0].leavehistory[i]['from_date'] = res.body.data[0].leavehistory[i]['from_date'] == '--' ? '' : res.body.data[0].leavehistory[i]['from_date']
          res.body.data[0].leavehistory[i]['to_date'] = res.body.data[0].leavehistory[i]['to_date'] == '--' ? '' : res.body.data[0].leavehistory[i]['to_date']
        }
        this.tableData = res?.body?.data[0].leavehistory
        this.editData = res?.body?.data[0]
        this.leaveData = res?.body?.data
        if(this.editData?.leave_name == 'Compensatory off'){
          this.investmentService.getCompOffList(this.emp_ID,this.from).subscribe((res: any) => {
            this.compOffDropdown = res;
            this.compOffDropdownSetup();
          })
        }
      } else {
        this.tableData = []
        this.leaveData = []
        this.compOffDropdown = []
      }
      this.leaveID = id
      this.noDataTable = this.tableData?.length == 0 ? true : false
      this.tableLoader = false
    })
  }

  minDate : any
  maxDate : any
  // Leave Type Click
  onNavChange(data: any) {
    this.leaveID = data
    this.filterResult = ''
    this.searchString = ''
    this.getDataWithLeaveID(this.leaveID)
    // this.year = new Date().getFullYear()
  }

  checkValidComp(){
    if (this.yearList.length === 0) {
      return { start: '', end: '' };
    }
    const startOfFirstYear = moment(`${this.yearList[0]}-01-01`).format('YYYY-MM-DD');
    const endOfLastYear = moment().subtract(1,'day').format('YYYY-MM-DD')
    return { start: startOfFirstYear, end: endOfLastYear };
  }

  // Filter
  filterFn(val: any) {
    this.filterResult = val
    this.getDataWithLeaveID(this.leaveID)
    this.searchString = ''
  }

  // Get Data from tableData
  filterIt() {
    return this.tableData.filter((obj: any) => {
      return Object.keys(obj).some((key) => {
        if (obj[key] !== null) {
          const tempKey = obj[key].toString().toLowerCase();
          const tempSearch = this.searchString.toLowerCase();
          return tempKey.includes(tempSearch);
        }
      });
    });
  }

  notanumber(val: any) {
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }

  setPayloadData(){
    let data :any = []
    if(this.editForm.get('transaction')?.value){
      data.push( { 'date' : moment(this.editForm.get('comp_off_add')?.value).format('YYYY-MM-DD'), 'count' : this.editForm.get('leave_type')?.value ? 1 : 0.5})
    }else{
      const collection = this.editForm.get('comp_off_deduct')?.value
      collection.forEach((date:any) => {
        let splittedVal = date.split(',')
        data.push({ 'date' : splittedVal[0], 'count': splittedVal[1] });
      });
    }
    return data
  }

  // Save Data
  saveData() {
    this.submitted = true
    let dataArray = {
      'employee': this.editForm.get('employee')?.value,
      'leave_type' : this.leaveID,
      'count' : this.editForm.get('count')?.value,
      'transaction' : this.editForm.get('transaction')?.value,
      'remarks' : this.editForm.get('remarks')?.value,
      'compodates' : !this.type ? [] : this.setPayloadData()
    }
    this.leaveServ.createManuallyLeave(dataArray).subscribe((res: any) => {
      this.editLeave = false
      let message = this.editForm.get('transaction')?.value == true ? 'Leave added successfully.' : 'Leave deducted successfully.'
      this.notificationService.handleSuccessNotification(message, "Success")
      this.getDataWithLeaveID(this.leaveID)
      this.formReset()
      this.submitted = false
    },error =>{
      this.notificationService.handleErrorNotification(error?.error.data,'Error')
      this.submitted = false
    })
  }

  // FormReset
  formReset() {
    this.editForm.get('count')?.reset('')
    this.editForm.get('remarks')?.reset('')
    this.editForm.get('comp_off_add')?.reset(null)
    this.editForm.get('comp_off_deduct')?.reset(null)
    this.editForm.get('leave_type')?.reset(true);
    this.editForm.get('transaction')?.reset(true);
    this.submitted = false
  }

  // Permission
  getPermission() {
    let routingUrl: any;
    if (this.from == 'myteam')
      routingUrl = '/my-team-dashboard';
    else
      routingUrl = '/leave-balance';
    this.permissions = this.appService.getPermissions(routingUrl);
    if (this.permissions == undefined) {
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  // function for add in no data screen

  addNewdata() {
    this.router.navigate(['/leave-rule/leave-policy']);
  }

  // View
  viewComp(id: any) {
    this.viewLoader = this.viewpanel = true
    this.leaveServ.leavesingleview(id).subscribe((res: any) => {
      res.body['from_date_day'] = res.body['from_date_name']
      res.body['to_date_day'] = res.body['to_date_name']
      res.body['full_day_dates'] = res.body['full_day_portion_dates']
      res.body['half_day_dates'] = res.body['half_day_portion_dates']
      this.viewData = res.body
      this.viewLoader = false
    })
  }

  leaveViewClose(data: any) {
    this.viewpanel = data;
  }

  dojFilter(d:any){
    let currentDate = new Date();
    let startYear = currentDate.getFullYear() - 2;
    let endYear = currentDate.getFullYear() + 1;
    let dateRange = [new Date(startYear, 0, 1), new Date(endYear, 11, 31)];
    return d >= dateRange[0] && d <= dateRange[1];
  }

  clearData() {
    this.editForm.get('count')?.reset('')
    this.editForm.get('remarks')?.reset('')
    this.editForm.get('comp_off_add')?.reset(null)
    this.editForm.get('comp_off_deduct')?.reset(null)
    this.editForm.get('leave_type')?.reset(true);
    this.cd.detectChanges();
    this.setValidator()
  }

  type : boolean = false
  editScreen(type:any){
    this.editLeave = true
    this.type = type == 'Compensatory off' ? true : false
    this.setValidator()
  }

  setValidator(){
    if(this.type){
      if(this.editForm.get('transaction')?.value === true){
        this.editForm.get('comp_off_deduct')?.clearValidators()
        this.editForm.get('comp_off_add')?.setValidators([Validators.required])
      }else{
        this.editForm.get('comp_off_add')?.clearValidators()
        this.editForm.get('comp_off_deduct')?.setValidators([Validators.required])
      }
      this.editForm.get('count')?.clearValidators()
    }else{
      this.editForm.get('comp_off_deduct')?.clearValidators()
      this.editForm.get('comp_off_add')?.clearValidators()
      this.editForm.get('count')?.setValidators([Validators.required,Validators.min(0.01), Validators.max(200), digitdecimalValidator()])
    }
    this.editForm.get('comp_off_add')?.updateValueAndValidity()
    this.editForm.get('comp_off_deduct')?.updateValueAndValidity()
    this.editForm.get('count')?.updateValueAndValidity()
  }

  compOffDropdownSetup() {
    if (this.compOffDropdown?.length > 0) {
      for (let i = 0; i < this.compOffDropdown?.length; i++) {
        let attendance_date = this.appService.dateFormatDisplay(this.compOffDropdown?.[i]?.attendance_date);
        let attendance_day = this.compOffDropdown?.[i]?.eligible_compoff == 'Full Day' ? '1 day' : '0.5 day';
        let attendance_count = this.compOffDropdown?.[i]?.eligible_compoff == 'Full Day' ? 1 : 0.5;
        let compOff_Date = attendance_date + ' - ' + attendance_day;
        this.compOffDropdown[i] = { "attendance_date": this.compOffDropdown?.[i]?.attendance_date+','+attendance_count, "eligible_compoff": this.compOffDropdown?.[i]?.eligible_compoff, 'compOff_date': compOff_Date, 'compOff_day': attendance_day, 'compOff_count': attendance_count }
      }
      this.compOffDropdown = this.compOffDropdown.sort((a:any,b:any)=>{
        let first = a.attendance_date.slice(0,10)
        let second = b.attendance_date.slice(0,10)
        return new Date(first).valueOf() - new Date(second).valueOf();
      });
      this.selectAllForDropdownItems(this.compOffDropdown)
    }
  }

  clear(val:any){
    let data = this.editForm.value.comp_off_deduct;
    data.splice(val, 1);
    this.editForm.patchValue({
      comp_off_deduct: data
    });
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

}
