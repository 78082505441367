import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-location-remote',
  templateUrl: './location-remote.component.html',
  styleUrls: ['./location-remote.component.scss']
})
export class LocationRemoteComponent implements OnInit {


  @Input() submitted             : any
  @Input() companyDD             : any
  @Input() buDD                  : any
  @Input() DesignationDD         : any
  @Input() BranchDD              : any
  @Input() DepDD                 : any
  @Input() gradeDD               : any
  @Input() empstatusDD           : any
  @Input() emptypeDD             : any
  // Above needs to be cleared
  @Input() employeeDropdown      : any
  @Input() remoteLocationForm    : any
  @Input() ouFilterForm          : any
  @Input() viewDetail            : any
  @Input() resDataFilter         : any
  @Input() disabled              : any
  @Input() callReport            : any
  @Input() salFilter             : any
  @Input() closeControl          : any
  @Input() checkListElements     : any
  @Input() leavePolicy           : any
  @Input() leaveType             : any
  @Input() pageNumber            : any
  @Input() lastPage              : any
  @Input() reportingDD           : any
  @Input() ordering              : any

  @Output() callReports  = new EventEmitter();
  @Output() filterPanel  = new EventEmitter();
  @Output() resetFilter  = new EventEmitter();
  @Output() submitDone = new EventEmitter();

  @ViewChild('scrollElement') scrollElement!: ElementRef;

  get f()  { return this.remoteLocationForm.controls; }

  constructor(
    public messageService : MessageService,
    private reportService : ReportsService,
    public appservice : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public attendanceReportService:AttendanceReportsService,
    public cd : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getDateFormat()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewDetail'] && changes['viewDetail'].currentValue) {
      this.getEmployeeList()
    }
  }

  getEmployeeList() {
    this.attendanceReportService.getempList(true)
      .subscribe((res: any) => {
        if (res?.length > 0) {
          this.reportingDD = res.map((employee: any) => {
            let fullname = '';
            if (employee.first_name) fullname += employee.first_name + ' ';
            if (employee.middle_name) fullname += employee.middle_name + ' ';
            if (employee.last_name) fullname += employee.last_name;
            const name = `${fullname.trim()} (${employee.employee_code})`;
            return { ...employee, fullname: fullname.trim(), name };
          });
        }
      });
  }


  setFormControlsToEmptyString(formGroup:FormGroup) {
    for (const controlName of Object.keys(formGroup.controls)) {
      const control = formGroup.get(controlName);

      if (control?.value === null) {
        control?.setValue('');
      }
    }
  }

  filterFormData(use:any){
    this.setFormControlsToEmptyString(this.ouFilterForm)
    this.remoteLocationForm.controls.attendance_date.value == true ? this.remoteLocationForm.get('attendance_date')?.setValue(this.appservice.dateFormatConvert(this.remoteLocationForm.controls.sm_specific_month.value)) : this.remoteLocationForm.get('attendance_date')?.setValue(this.remoteLocationForm.controls.attendance_date.value);
    let company     = this.ouFilterForm.controls.company.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.company.value);
    let bu          = this.ouFilterForm.controls.bu.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.bu.value);
    let dept        = this.ouFilterForm.controls.department.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.department.value);
    let desig        = this.ouFilterForm.controls.designation.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.designation.value);
    let branch      = this.ouFilterForm.controls.branch.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.branch.value);
    let employee_list = this.ouFilterForm.controls.employee_list.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.ouFilterForm.controls.grade.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.grade.value);
    let employment_status    = this.ouFilterForm.controls.employment_status.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.ouFilterForm.controls.employee_type.value==''?JSON.stringify([]):JSON.stringify(this.ouFilterForm.controls.employee_type.value);
    let attendance_month    = this.remoteLocationForm.controls.attendance_date.value == null ? '' : this.remoteLocationForm.controls.attendance_date.value;
    let reporting_manager = this.ouFilterForm.controls.reporting_manager.value == null ? '' : this.ouFilterForm.controls.reporting_manager.value
    if(use == 'filter'){
      return `company=${company}&bu=${bu}&department=${dept}&designation=${desig}&branch=${branch}&grade=${grade}&employee_list=${employee_list}&emp_status=${employment_status}&emp_type=${employee_type}&attendance_date=${attendance_month}&reporting_manager=${reporting_manager}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'attendance_date' : this.remoteLocationForm.controls.attendance_date.value == null ? '' : this.remoteLocationForm.controls.attendance_date.value,'company' : this.ouFilterForm.controls.company.value, 'bu' : this.ouFilterForm.controls.bu.value, 'department' : this.ouFilterForm.controls.department.value,'grade' : this.ouFilterForm.controls.grade.value, 'emp_status' : this.ouFilterForm.controls.employment_status.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value, 'branch' : this.ouFilterForm.controls.branch.value, 'employee_list' : this.ouFilterForm.controls.employee_list.value,'reporting_manager':this.ouFilterForm.controls.reporting_manager.value,'designation': this.ouFilterForm.controls.designation.value})
    }
  }

  applyBtn : boolean = false

  submitRemotePunches(){
    this.submitted          = true;
    if (this.remoteLocationForm.invalid) {
      setTimeout(()=>{
        this.scrollToFirstChild()
      },400)
      return;
    }

    let offset = 0

    this.setFormControlsToEmptyString(this.ouFilterForm)

    let limit = 20
    offset = this.applyBtn == true ? 0 : this.reportService.calculateOffset(this.pageNumber)
    let filterForm = this.filterFormData('filter')

    this.submitDone.emit({
      limit : limit,
      offset : offset,
      filterForm : filterForm
    })
    this.viewDetail           = false;
    this.applyBtn = false
  }

  getData() {
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => key !== 'attendance_date' && this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length-1 ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

   // Select All
   selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  radioBtnChangeFunctions(e:any,contrlName:any,val:any,type:any,form:any){
    if(e.target.value == 'on' ){
      this.remoteLocationForm?.get(contrlName)?.setValue(true);
     }
     else{
      this.remoteLocationForm?.get(contrlName)?.setValue(val);
      this.remoteLocationForm?.get(type)?.setValue('');
     }

     if(val == true){
      this.remoteLocationForm.get('sm_specific_month')?.setValidators([Validators.required])
     }else{
      this.remoteLocationForm.get('sm_specific_month')?.clearValidators()
     }
     this.remoteLocationForm.get('sm_specific_month')?.updateValueAndValidity()
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  // Resetting the filters
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.ouFilterForm.get('reporting_manager')?.reset(null);
      this.remoteLocationForm.get('attendance_date')?.reset('today')
    }
    this.callReports.emit({
      reportName : 'Remote Punch Locations'
    })
  }

  // Tag Close
  applyTagClose1(){
    this.applyBtn = true
    if(this.closeControl == 'reporting_manager'){
      this.ouFilterForm.get(this.closeControl)?.setValue(null);
    }else if(this.closeControl == 'company'){
      this.ouFilterForm.get('company')?.setValue('')
      this.ouFilterForm.get('bu')?.setValue('')
    }else{
      this.ouFilterForm.get(this.closeControl)?.setValue('');
    }
    this.submitRemotePunches();
  }

  disable(){
    return false
  }

  // Date Format
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  resetPage(val:any){
    this.applyBtn = true
    this.reportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  scrollToFirstChild() {
    const scrollEl = this.scrollElement.nativeElement;
    const firstElement = scrollEl.querySelector('.is-invalid');
    if (firstElement) {
      const scrollPosition = firstElement.offsetTop - scrollEl.offsetTop;
      scrollEl.scrollTop = scrollPosition;
    }
  }
}
