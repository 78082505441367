<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="loader==false && permissions">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h5 class="mb-0">Professionals</h5>
      <ng-container *ngIf="addEditPermission">
        <a class="link-primary fs-14 d-flex align-items-center" *ngIf="professionalData.length!=0 && permissions?.e"
          (click)="editProfession();"><i class="icon-pencil-square me-8"></i> Edit</a>
      </ng-container>
    </div>
  </div>
  <div class="col-12" *ngIf="professionalData.length!=0">
    <div class="card details-card shadow-0 p-24">
      <div class="row row-24 " *ngFor="let prof of professionalData let i = index;let last = last">
        <div class=" col-12 d-flex align-items-center">
          <div *ngIf="getFieldPermission('previous_company')" class="avatar-box me-16">
            {{getFirstChar(prof.previous_company ,2)}}</div>
          <div class="">
            <h6 *ngIf="getFieldPermission('previous_company')" class="text-break mb-0 b">{{prof.previous_company}}</h6>
            <span class="fs-10 fw-500 text-light-500 text-uppercase">
              <ng-container *ngIf="getFieldPermission('from_date')">
                {{((prof.from_date!='' && prof.from_date!=null &&
                prof.from_date!=='None' &&
                prof.from_date!=='none')?this.appservice.dateFormatDisplay(prof.from_date):'-')}} -
              </ng-container>
              <ng-container *ngIf="getFieldPermission('to_date')">
                {{((prof.to_date!=''
                && prof.to_date!=null && prof.to_date!=='None' &&
                prof.to_date!=='none')?this.appservice.dateFormatDisplay(prof.to_date):'-')}}
              </ng-container>
            </span>

          </div>
        </div>
        <div *ngIf="getFieldPermission('previous_designation')" class="col-12">
          <div class="detail-title">Previous designation</div>
          <div class="detail-desc">{{prof.previous_designation}}</div>
        </div>
        <div *ngIf="getFieldPermission('job_description')" class="col-12">
          <div class="detail-title">Job description</div>
          <div class="detail-desc">{{prof.job_description!=""?prof.job_description:'-'}}</div>
        </div>
        <div class="col-12 ">
          <hr class="">
        </div>
        <div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- showing when no professional information available -->
<div class="row row-16" *ngIf="professionalData.length==0">
  <div class="col-12">
    <div class="row row-24 py-16">
      <div class="col-12 ">
        <div class="card details-card shadow-0 px-24 py-16">
          <p class="title mb-24">Professionals</p>
          <div *ngIf="addEditPermission" class="col-12 d-flex">
            <a *ngIf="permissions?.a" class="btn btn-outline-primary px-64" (click)="addNewProf()">Add Professional
              Data</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->
<!-- Slider edit start-->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanelProf === true'>
  <form class="side-panel-container" [formGroup]="professionalForm" autocomplete="off">
    <header class="side-panel-head">
      <h5>{{heading}} Professional Details</h5>
      <a class="toggle-panel" (click)="showSidePanelProf = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 ">
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0 fs-16">PROFESSIONALS INFORMATION</p>
          </div>
          <ng-container formArrayName="professional" *ngFor="let prof of professional().controls; let i = index">
            <ng-container [formGroupName]="i">
              <div class="card card-c2">
                <div class="card-body p-16 d-flex">
                  <p class="fs-14 fw-500 mb-0">Professionals Information {{i+1}}</p>
                  <a class="ms-auto"><i class="icon-trash pointer text-dark" *ngIf="i!=0"
                      (click)="deleteProfession(i,professional().controls[i].get('id')?.value)"></i> </a>
                </div>
                <div class="card-body px-16 py-24">
                  <div class="row row-16">
                    <input type="hidden" class="form-control" name="" id="prof_id" placeholder="" formControlName="id">
                    <input type="hidden" class="form-control" name="" id="employee_id" placeholder=""
                      formControlName="employee">
                    <div class="col-sm-6 form-row ">
                      <label for="previous_company" class="form-label required">Previous company</label>
                      <input type="text" class="form-control" name="" id="previous_company"
                        placeholder="Enter previous company" formControlName="previous_company"
                        [ngClass]="{ 'is-invalid': (submitted || professional().controls[i].get('previous_company')?.touched || professional().controls[i].get('previous_company')?.dirty) && professional().controls[i].get('previous_company')?.errors}">
                      <div
                        *ngIf="(submitted || professional().controls[i].get('previous_company')?.touched || professional().controls[i].get('previous_company')?.dirty) && professional().controls[i].get('previous_company')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="professional().controls[i].get('previous_company')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="professional().controls[i].get('previous_company')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                        <div
                          *ngIf="!(professional().controls[i].get('previous_company')?.errors?.pattern) && professional().controls[i].get('previous_company')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="previous_designation" class="form-label required ">Previous designation</label>
                      <input type="text" class="form-control" name="previous_designation" id="previous_designation"
                        placeholder="Enter previous designation" formControlName="previous_designation"
                        [ngClass]="{ 'is-invalid': (submitted || professional().controls[i].get('previous_designation')?.touched || professional().controls[i].get('previous_designation')?.dirty) && professional().controls[i].get('previous_designation')?.errors}">
                      <div
                        *ngIf="(submitted || professional().controls[i].get('previous_designation')?.touched || professional().controls[i].get('previous_designation')?.dirty) && professional().controls[i].get('previous_designation')?.errors"
                        class="invalid-feedback">
                        <div *ngIf="professional().controls[i].get('previous_designation')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="professional().controls[i].get('previous_designation')?.errors?.pattern">
                          {{this.messageService.validationDisplay('pattern')}}</div>
                        <div
                          *ngIf="!(professional().controls[i].get('previous_designation')?.errors?.pattern) && professional().controls[i].get('previous_designation')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                      </div>
                    </div>

                    <div class="col-sm-6 form-row ">
                      <label for="from_date" class="form-label required ">From date </label>
                      <div class="input-group form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || professional().controls[i].get('from_date')?.touched) && professional().controls[i].get('from_date')?.errors}">
                        <input (click)="datePicker.open()" class="form-control"
                          placeholder="{{this.messageService.selectplaceholddisplay('from date')}}" matInput
                          formControlName="from_date" [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker"
                          (keydown)="disableDate()"
                          [ngClass]="{ 'is-invalid': (submitted || professional().controls[i].get('from_date')?.touched) && professional().controls[i].get('from_date')?.errors}"><i
                          class="icon-calendar pointer" (click)="datePicker.open()"></i>
                        <mat-datepicker touchUi="false" #datePicker></mat-datepicker>
                      </div>
                      <div
                        *ngIf="(submitted || professional().controls[i].get('from_date')?.touched) && professional().controls[i].get('from_date')?.errors?.required"
                        class="invalid-feedback">
                        <div
                          *ngIf="(submitted || professional().controls[i].get('from_date')?.touched) && professional().controls[i].get('from_date')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6 form-row ">
                      <label for="to_date" class="form-label required ">To date</label>

                      <div class="input-group form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted || professional().controls[i].get('to_date')?.touched || professional().controls[i].errors!=null) && (professional().controls[i].get('to_date')?.errors || (professional().controls[i].errors!=null))}">
                        <input (click)="datePicker1.open()" class="form-control"
                          placeholder="{{this.messageService.selectplaceholddisplay('to date')}}" matInput
                          formControlName="to_date" [matDatepickerFilter]="dateFilter" [matDatepicker]="datePicker1"
                          (keydown)="disableDate()"
                          [ngClass]="{ 'is-invalid': (submitted || professional().controls[i].get('to_date')?.touched || professional().controls[i].errors!=null) && (professional().controls[i].get('to_date')?.errors || (professional().controls[i].errors!=null))}"><i
                          class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                        <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                      </div>
                      <div
                        *ngIf="(submitted || professional().controls[i].get('to_date')?.touched || professional().controls[i].errors!=null) && (professional().controls[i].get('to_date')?.errors?.required || professional().controls[i].errors!=null)"
                        class="invalid-feedback">
                        <div
                          *ngIf="(submitted || professional().controls[i].get('to_date')?.touched) && professional().controls[i].get('to_date')?.errors?.required">
                          {{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="professional().controls[i].errors!=null">To date should be greater than from date.
                        </div>
                      </div>
                    </div>

                    <div class="col-12 form-row ">
                      <label for="job_description" class="form-label">Job description </label>
                      <textarea rows="4" type="text" class="form-control"
                        placeholder="{{this.messageService.placeholderdisp('Job Description')}}" name=""
                        id="job_description" formControlName="job_description"
                        [ngClass]="{ 'is-invalid': (submitted || professional().controls[i].get('job_description')?.dirty) &&  professional().controls[i].get('job_description')?.errors}">

                       </textarea>
                      <div
                        *ngIf="(submitted || professional().controls[i].get('job_description')?.dirty) && professional().controls[i].get('job_description')?.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="professional().controls[i].get('job_description')?.errors?.maxlength">
                          {{this.messageService.fieldlengthvalidation('word','500')}}</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="col-12">
            <div class="card card-c2 p-16 flex-row">
              <p class="fs-14 fw-500 mb-0">New Section</p>
              <span class="ms-auto link-primary1 fs-14" (click)="addproffesional()">
                <i class="icon-plus-square-fill me-8"></i>Add Professional Information
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer gap-32">
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit"
        (click)="(isDateConvert=true);onSubmit()" [disabled]="!professionalForm.valid">Save</button>
    </footer>
  </form>
</div>

<!--Delete confirmation box for child-->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Professional Information?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('professional information')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteToggle = false">Cancel</button>

        <button type="button" class="btn btn-danger btn-sm text-uppercase"
          (click)="deleteProfessionConfirm()">Delete</button>
      </div>
    </div>
  </div>
</div>

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- InfoPopup end -->